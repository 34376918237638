import PLDashboard from "./Dashboard";

export const PLRoutes = [
  {
    path: "/pl",
    layout: "/user",
    name: "Profit and Loss Report",
    icon: "pe-7s-note2",
    application: "Pricing",
    component: null,
    children: [
      {
        path: "/user/pl/report",
        component: PLDashboard,
        exact: true,
        application: "Pricing",
      },
      {
        path: "/user/pl/report/category/:category",
        component: PLDashboard,
        exact: true,
        application: "Pricing",
      },
    ],
  },
];
