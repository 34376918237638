import React, { useState, useMemo } from "react";
import useColumnSelector from "hooks/useColumnSelector";
import ReactTable from "react-table-6";
import buildMetricColumn from "views/merchandise/columns/buildMetricColumn";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { getURLPrefix } from "utils/getUrlPrefix";
import TrellisLink from "components/core/basic/TrellisLink";
import NoImage from "assets/images/utility/no_image.png";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import useTableSettings from "hooks/useTableSettings";
import FilterWidget from "views/merchandise/tables/FilterWidget";
import { useSelector } from "react-redux";
import { extendAllEntityMetricOptions } from "views/merchandise/tables/metricUtils";

const URL_PREFIX = getURLPrefix();

const CategoriesTable = ({
  marketPlace,
  tableRef,
  tableData,
  searchFilter,
  setSearchFilter,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [colToggle] = useState();
  const { user } = useSelector((state) => state);

  const TABLE_ID = "productSEOCategoriesTable";
  const {
    customFilters,
    setCustomFilters,
    formatFilterValue,
    applyCustomFilters,
  } = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const {
    savedTableSettings: savedTablePageSize,
    updateTableSettings: setSavedTablePageSize,
  } = useTableSettings(TABLE_ID, "tablePageSize", 5);

  const {
    savedTableSettings: savedTableSortMethod,
    updateTableSettings: setSavedTableSortMethod,
  } = useTableSettings(TABLE_ID, "tableSorting", []);

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      const titleColumn = {
        id: "category",
        Header: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div style={{ position: "relative" }}>Category</div>
          </div>
        ),
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center", border: "none" },
        accessor: "category_name",
        grouping: "configuration",
        Filter: () => {},
        filterMethod: () => {},
        Cell: (props) => {
          const [hasError, setHasError] = useState(false);
          const { category_id } = props.original;
          const image = hasError
            ? NoImage
            : `${URL_PREFIX}/api/productimage/?category=${category_id}`;

          return (
            <TrellisLink
              to={`/user/content/quality/category/${category_id}`}
              style={{
                textDecoration: "none",
                fontWeight: "500",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  onError={(e) => {
                    setHasError(true);
                  }}
                  style={{
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    flexShrink: 0,
                    width: 50,
                    height: 50,
                    marginRight: "10px",
                    background: `url(${image}) no-repeat center / contain`,
                  }}
                  draggable="false"
                />
                <div className={"fs-standard text-underline-hover"}>
                  <strong className={"text-underline-hover"}>
                    {props?.value}
                  </strong>
                </div>
              </div>
            </TrellisLink>
          );
        },
        width: 400,
      };

      let columns = [
        titleColumn,
        buildMetricColumn(
          marketPlace.marketPlace,
          tableData,
          "sales",
          "sales_percent"
        ),
        buildMetricColumn(
          marketPlace.marketPlace,
          tableData,
          "units",
          "units_percent"
        ),
        buildMetricColumn(
          marketPlace.marketPlace,
          tableData,
          "seo_score",
          "seo_score_percent"
        ),
        buildMetricColumn(
          marketPlace.marketPlace,
          tableData,
          "buyability_score",
          "buyability_score_percent"
        ),
        buildMetricColumn(
          marketPlace.marketPlace,
          tableData,
          "ctr",
          "ctr_delta"
        ),
        buildMetricColumn(
          marketPlace.marketPlace,
          tableData,
          "conversion_rate",
          "conversion_rate_delta"
        ),
      ];

      columns = extendAllEntityMetricOptions(
        "category",
        tableData,
        customFilters,
        columns,
        marketPlace,
        user
      );

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle, tableData, marketPlace.marketPlace]
  );

  const filteredData = useMemo(() => {
    return applyCustomFilters(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, customFilters]);

  return (
    <ReactTable
      ref={tableRef}
      data={filteredData}
      defaultPageSize={savedTablePageSize || 10}
      minRows={5}
      className={`pricing-table ${TABLE_ID}`}
      PaginationComponent={ReactTablePagination}
      getPaginationProps={() => ({
        updateSearch: setSearchFilter,
        showFilters: showFilters,
        setShowFilters: setShowFilters,
        clearFilters: () => {
          setSearchFilter("");
        },
        hideFilters: false,
        clearSearchTermFilters: () => setSearchFilter(""),
        searchTerm: searchFilter,
        table: "plan-products",
        tableId: TABLE_ID,
        style: { marginBottom: "2rem" },
        carouselLayout: false,
        disableSearchFocus: true,
        filterWidget: true,
        hideFilterToggleIcon: true,
        tableEntityType: "category",
        children: (
          <div className="d-flex justify-content-start w-100 h-100 align-items-center px-2">
            <FilterWidget
              customFilters={customFilters}
              setCustomFilters={setCustomFilters}
              formatFilterValue={formatFilterValue}
              tableRef={tableRef}
              excludedFilterColumns={["category_name"]}
              tableId={TABLE_ID}
            />
          </div>
        ),
      })}
      filterable={showFilters}
      onSortedChange={(newSorted) => {
        setSavedTableSortMethod(newSorted);
      }}
      onPageSizeChange={(pageSize) => {
        setSavedTablePageSize(pageSize);
      }}
      columns={columns}
      NoDataComponent={() => null}
      showPaginationTop
      showPaginationBottom={false}
      columnSelectorProps={columnSelectorProps}
      defaultSorted={
        savedTableSortMethod || [
          {
            id: "seo_score",
            desc: true,
          },
        ]
      }
    />
  );
};

export default CategoriesTable;
