import TrellisTrak from "layouts/TrellisTrak";
import Creatives from "layouts/Creatives";
import Refer from "views/Refer";
import InviteUser from "views/InviteUser";
import AssetManagement from "views/AssetManagement";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";
import PaymentDetails from "components/custom/onboarding/subscription/PaymentDetails";
import AmazonAdvertising from "components/custom/onboarding/AmazonAdvertising";
import AmazonSummary from "components/custom/onboarding/AmazonSummary";
import Billing from "views/Billing";
import Apps from "views/Apps";
import GooglePreferences from "views/integrations/GooglePreferences";
import Preferences from "views/Preferences";
import VideoEditor from "views/VideoEditor";

import { ReportRoutes } from "modules/perf_reports/routes";
import { QualityRoutes } from "modules/product_quality/routes";
import { PricingV2Routes } from "modules/pricingv2/routes";
import { PLRoutes } from "modules/pandl/routes";
import { LiveRoutes } from "modules/live/routes";
import { SellerPerformanceRoutes } from "modules/seller_performance/routes";
import { SEOPerformanceRoutes } from "modules/seo_performance/routes";

import OnboardingSpApi from "components/custom/onboarding/OnboardingSpApi";
import PurchaseBehaviorReport from "views/PurchaseBehaviorReport";
import NotificationsCenter from "views/NotificationsCenter";
import AnalyticsView from "views/AnalyticsView";
import PlansList from "views/merchandise/PlansList";
import LaunchDashboard from "views/merchandise/LaunchDashboard";
import Plan from "views/merchandise/Plan";
import MerchSettings from "views/merchandise/Settings";
import Search from "../views/Search";
import TrellisExtension from "views/chrome/TrellisExtension";
import NewLaunch from "views/merchandise/NewLaunch";
import LaunchDetails from "views/merchandise/LaunchDetails";
import MerchDashboard from "views/merchandise/Dashboard";
import PromotionsReport from "views/merchandise/PromotionsReport";
import MerchProduct from "views/merchandise/Product";
import AdvertisingDashboard from "views/AdvertisingDashboard";
import AdvertisementDetails from "views/advertising/AdvertisementDetails";
import ApplicationsMenu from "views/ApplicationsMenu";
import AdvertisingOverview from "views/AdvertisingOverview";
import AgencyDashboard from "views/merchandise/AgencyDashboard";
import ProgramList from "views/programs/ProgramList";
import ProgramCreate from "views/programs/ProgramCreate";
import ProgramEdit from "views/programs/ProgramEdit";
import AllCustomersDashboard from "views/merchandise/AllCustomersDashboard";
import { OnboardingRoutes } from "views/onboarding/routes/routes";
import VerifyEmail from "views/onboarding/components/VerifyEmail";
import WalmartSummary from "components/custom/onboarding/WalmartSummary";
import OrgAdvertisingConfiguration from "views/advertising/OrgAdvertisingConfiguration";
import AgencyManagementView from "views/AgencyManagementView";

var routes = [
  {
    path: "/dashboard",
    layout: "/user",
    name: "Advertising",
    component: MerchDashboard,
    application: "Merchandising",
    exact: true,
  },
  {
    path: "/agency/dashboard",
    layout: "/user",
    name: "Advertising",
    component: AgencyDashboard,
    application: "Merchandising",
    exact: true,
  },
  {
    path: "/staff/dashboard",
    layout: "/user",
    name: "All Customers",
    component: AllCustomersDashboard,
    application: "Merchandising",
    exact: true,
  },
  {
    path: "/advertising/dashboard",
    layout: "/user",
    name: "Advertising",
    component: AdvertisingDashboard,
    application: "Advertising",
    exact: true,
  },
  {
    path: "/advertising/dashboard/category/:id",
    layout: "/user",
    name: "Advertising Category Overview",
    component: AdvertisingOverview,
    application: "Advertising",
    exact: true,
  },
  {
    path: "/advertising/dashboard/ads/:id",
    layout: "/user",
    name: "Advertisement Details",
    component: AdvertisementDetails,
    application: "Advertising",
    exact: true,
  },
  {
    path: "/advertising/settings",
    layout: "/user",
    name: "Advertisement Settingss",
    component: OrgAdvertisingConfiguration,
    application: "Advertising",
    exact: true,
  },
  {
    path: "/chromeaccess",
    layout: "/user",
    name: "Trellis Extension",
    redirect: true,
    component: TrellisExtension,
    application: "Admin",
  },
  {
    path: "/categorymanagement",
    layout: "/user",
    name: "Products & Categories",
    icon: "pe-7s-keypad",
    component: Search,
    application: "Product",
  },
  {
    path: "/creatives",
    layout: "/user",
    name: "Videos & Assets",
    icon: "pe-7s-film",
    component: Creatives,
    application: "Product",
  },
  {
    redirect: true,
    path: "/trak",
    layout: "/user",
    name: "Trellis Trak",
    icon: "pe-7s-graph1",
    component: TrellisTrak,
    application: "Advertising",
  },
  {
    redirect: true,
    path: "/plans",
    layout: "/onboard",
    name: "Payment Discovery",
    icon: "pe-7s-light",
    component: PaymentDiscovery,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/plans",
    layout: "/user",
    name: "Payment Discovery",
    icon: "pe-7s-light",
    component: PaymentDiscovery,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/payment",
    layout: "/onboard",
    name: "Payment Details",
    icon: "pe-7s-light",
    component: PaymentDetails,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/payment",
    layout: "/user",
    name: "Payment Details",
    icon: "pe-7s-light",
    component: PaymentDetails,
    application: "Admin",
  },
  {
    path: "/applications",
    layout: "/user",
    name: "Manage Applications",
    component: ApplicationsMenu,
    application: "Admin",
  },
  {
    path: "/agencyusers",
    layout: "/user",
    name: "Manage Agency Users",
    component: AgencyManagementView,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/amzadv",
    layout: "/onboard",
    name: "Amazon Advertising",
    icon: "pe-7s-light",
    component: AmazonAdvertising,
    application: "Advertising",
  },
  {
    redirect: true,
    path: "/amzadv",
    layout: "/user",
    name: "Amazon Advertising",
    icon: "pe-7s-light",
    component: AmazonAdvertising,
    application: "Advertising",
  },
  {
    redirect: true,
    path: "/amzspapi",
    layout: "/onboard",
    name: "Amazon SP API Select",
    icon: "pe-7s-light",
    component: OnboardingSpApi,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/amzsummary",
    layout: "/onboard",
    name: "Amazon Summary",
    icon: "pe-7s-light",
    component: AmazonSummary,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/amzsummary",
    layout: "/user",
    name: "Amazon Summary",
    icon: "pe-7s-light",
    component: AmazonSummary,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/walmartsummary",
    layout: "/user",
    name: "Amazon Summary",
    icon: "pe-7s-light",
    component: WalmartSummary,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/apps",
    layout: "/user",
    name: "Apps",
    icon: "pe-7s-mail",
    component: Apps,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/googleshopping",
    layout: "/user",
    name: "Apps",
    icon: "pe-7s-mail",
    component: GooglePreferences,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/inviteuser",
    layout: "/user",
    name: "Invite",
    icon: "pe-7s-mail",
    component: InviteUser,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/assetmanagement",
    layout: "/user",
    name: "Asset Management",
    icon: "pe-7s-photo",
    component: AssetManagement,
    application: "Product",
  },
  {
    redirect: true,
    path: "/billing",
    layout: "/user",
    name: "Billing",
    icon: "pe-7s-cash",
    component: Billing,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/videoeditor",
    layout: "/user",
    name: "VideoEditor",
    icon: "pe-7s-graph",
    component: VideoEditor,
    application: "Product",
  },
  {
    redirect: true,
    path: "/preferences",
    layout: "/user",
    name: "Preferences",
    icon: "pe-7s-cash",
    component: Preferences,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/refer",
    layout: "/user",
    name: "Refer",
    icon: "pe-7s-gift",
    component: Refer,
    application: "Admin",
  },
  {
    path: "/purchasebehavior",
    layout: "/user",
    name: "Purchase Behavior",
    icon: "pe-7s-cart",
    component: PurchaseBehaviorReport,
    application: "Reports",
  },
  {
    path: "/searchanalytics",
    layout: "/user",
    name: "Search Analytics",
    icon: "pe-7s-graph",
    component: AnalyticsView,
    application: "Placement",
  },
  {
    path: "/searchanalytics",
    layout: "/user",
    name: "Search Analytics",
    icon: "pe-7s-graph",
    component: AnalyticsView,
    application: "Search Analytics",
  },
  {
    path: "/merchandising",
    layout: "/user",
    name: "Merchandising Plans",
    icon: "pe-7s-note2",
    application: "Merchandising",
    component: null,
    children: [
      {
        path: "/user/merchandising/dashboard",
        component: MerchDashboard,
        exact: true,
        application: "Merchandising",
      },
      {
        path: "/user/merchandising/product/:product",
        component: MerchProduct,
        exact: true,
        application: "Merchandising",
      },
      {
        path: "/user/merchandising/category/:category/",
        component: MerchDashboard,
        exact: true,
        application: "Merchandising",
      },
      {
        path: "/user/merchandising/planning/settings",
        component: MerchSettings,
        exact: true,
        application: "Merchandising",
      },
      {
        path: "/user/merchandising/planning/plans/:id",
        component: Plan,
        application: "Merchandising",
      },
      {
        path: "/user/merchandising/planning",
        exact: true,
        index: true,
        component: PlansList,
        application: "Merchandising",
      },
      {
        path: "/user/merchandising/promotions",
        component: PromotionsReport,
        exact: true,
        application: "Promotions",
      },
    ],
  },
  // TODO: Below will likely become part of merchandising children above
  {
    path: "/productlaunch",
    layout: "/user",
    name: "New Product Launches",
    icon: "pe-7s-note2",
    application: "Product",
    component: null,
    children: [
      {
        path: "/user/productlaunch/launches/:id",
        component: LaunchDetails,
        application: "Product",
      },
      {
        path: "/user/productlaunch/new",
        exact: true,
        index: true,
        component: NewLaunch,
        application: "Product",
      },
      {
        path: "/user/productlaunch",
        exact: true,
        index: true,
        component: LaunchDashboard,
        application: "Product",
      },
    ],
  },
  {
    redirect: true,
    path: "/notifications",
    layout: "/user",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: NotificationsCenter,
    application: "Admin",
  },
  {
    redirect: true,
    path: "/product",
    layout: "/user",
    name: "Product Details",
    icon: "pe-7s-portfolio",
    application: "Merchandising",
  },
  {
    path: "/programs",
    layout: "/user",
    name: "Rules",
    icon: "pe-7s-note2",
    component: null,
    application: "Pricing",
    children: [
      {
        path: "/user/programs/edit/:id",
        component: ProgramEdit,
        application: "Pricing",
      },
      {
        path: "/user/programs/create",
        component: ProgramCreate,
        application: "Pricing",
      },
      {
        path: "/user/programs",
        exact: true,
        index: true,
        component: ProgramList,
        application: "Pricing",
      },
    ],
  },
  {
    path: "/advertising/programs",
    layout: "/user",
    name: "Rules",
    icon: "pe-7s-note2",
    component: null,
    application: "Advertising",
    children: [
      {
        path: "/user/advertising/programs/edit/:id",
        component: ProgramEdit,
        application: "Advertising",
      },
      {
        path: "/user/advertising/programs/create",
        component: ProgramCreate,
        application: "Advertising",
      },
      {
        path: "/user/advertising/programs",
        exact: true,
        index: true,
        component: ProgramList,
        application: "Advertising",
      },
    ],
  },
  {
    path: "/verify",
    layout: "/user",
    name: "Advertising",
    component: VerifyEmail,
    application: "Admin",
    exact: true,
  },
  {
    redirect: true,
    path: "/",
    layout: "/user",
    name: "redirect",
    icon: "pe-7s-graph",
    component: MerchDashboard,
    application: "Merchandising",
  },
];

let allRoutes = [];
allRoutes = allRoutes.concat(ReportRoutes);
allRoutes = allRoutes.concat(QualityRoutes);
allRoutes = allRoutes.concat(PricingV2Routes);
allRoutes = allRoutes.concat(PLRoutes);
allRoutes = allRoutes.concat(SellerPerformanceRoutes);
allRoutes = allRoutes.concat(OnboardingRoutes);
allRoutes = allRoutes.concat(LiveRoutes);
allRoutes = allRoutes.concat(SEOPerformanceRoutes);
allRoutes = allRoutes.concat(routes);

export default allRoutes;
