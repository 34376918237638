import React, { useRef, useMemo } from "react";
import "assets/css/dashboard_table.css";
import "assets/css/pricing_table_overrides.css";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";

const useStyles = (theme) => ({
  preferenceContainer: {
    padding: theme.spacing(2),
  },
  preferenceTitle: {
    margin: "0px 0px",
    paddingLeft: theme.spacing(2),
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2, 2),
      textAlign: "center",
    },
  },
  preferenceDescription: {
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2),
      textAlign: "center",
      margin: "auto",
    },
  },
});

const AdDetails = ({ optData, marketPlace }) => {
  const TABLE_ID = "priceAdDetailsTable";
  const tableRef = useRef();

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const tableData = useMemo(() => {
    let sourceData =
      optData?.pricing_data?.filter((r) => r.num_days_at_price > 0) ?? [];
    let extendedData = sourceData?.map((row) => {
      const {
        impressions,
        num_days_at_price,
        clicks,
        conversions,
        sales,
        cost,
      } = row;

      const calculatedFields = {
        impressions_per_day: num_days_at_price
          ? impressions / num_days_at_price ?? "-"
          : "-",
        clicks_per_day: num_days_at_price
          ? clicks / num_days_at_price ?? "-"
          : "-",
        conversions_per_day: num_days_at_price
          ? conversions / num_days_at_price ?? "-"
          : "-",
        ctr_calculated: impressions ? clicks / impressions ?? "-" : "-",
        cr_calculated: clicks ? conversions / clicks ?? "-" : "-",
        sales_per_day: num_days_at_price
          ? sales / num_days_at_price ?? "-"
          : "-",
        cost_per_day: num_days_at_price ? cost / num_days_at_price ?? "-" : "-",
        acos_calculated: sales ? cost / sales ?? "-" : "-",
      };

      return { ...row, ...calculatedFields };
    });

    return extendedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optData]);

  return (
    <>
      <BulkActionsTable
        tableId={TABLE_ID}
        tableRef={tableRef}
        filterWidget={true}
        hideFilterToggleIcon={true}
        {...filterProps}
        columnSpecs={[
          {
            key: "product_price",
            compareKey: null,
            options: {
              className: "d-flex text-start align-items-center ps-3",
              metric: {
                id: "price",
                format: "currency",
                accessorId: "price",
                accessor: (d) => {
                  return d?.price;
                },
                name: "Price",
                width: "100px",
              },
            },
          },
          {
            key: "num_days_at_price",
            compareKey: null,
            options: {
              metric: {
                id: "num_days_at_price",
                format: "number",
                accessor: (d) => {
                  return d?.num_days_at_price;
                },
                name: "Days at Price",
              },
            },
          },

          {
            key: "avg_daily_sales",
            compareKey: null,
            options: {
              metric: {
                id: "avg_daily_sales",
                format: "number",
                accessor: (d) => {
                  return d?.avg_daily_sales;
                },
                name: "Daily Demand",
                decimalPlaces: 2,
              },
            },
          },
          {
            key: "impressions_per_day",
            compareKey: null,
            options: {
              metric: {
                id: "impressions_per_day",
                format: "number",
                accessor: (d) => {
                  return d?.impressions_per_day;
                },
                name: "Imp/Day",
                decimalPlaces: 0,
              },
            },
          },
          {
            key: "clicks_per_day",
            compareKey: null,
            options: {
              metric: {
                id: "clicks_per_day",
                format: "number",
                accessor: (d) => {
                  return d?.clicks_per_day;
                },
                name: "Clicks/Day",
                decimalPlaces: 0,
              },
            },
          },
          {
            key: "conversions_per_day",
            compareKey: null,
            options: {
              metric: {
                id: "conversions_per_day",
                format: "number",
                accessor: (d) => {
                  return d?.conversions_per_day;
                },
                name: "Conv/Day",
                decimalPlaces: 2,
              },
            },
          },
          {
            key: "ctr_calculated",
            compareKey: null,
            options: {
              metric: {
                id: "ctr_calculated",
                format: "percent",
                accessor: (d) => {
                  return d?.ctr_calculated;
                },
                name: "CTR",
                decimalPlaces: 2,
              },
            },
          },
          {
            key: "cr_calculated",
            compareKey: null,
            options: {
              metric: {
                id: "cr_calculated",
                format: "percent",

                accessor: (d) => {
                  return d?.cr_calculated;
                },
                name: "CR",
                decimalPlaces: 2,
              },
            },
          },
          {
            key: "sales_per_day",
            compareKey: null,
            options: {
              metric: {
                id: "sales_per_day",
                format: "currency",
                accessor: (d) => {
                  return d?.sales_per_day;
                },
                name: "Sales/Day",
              },
            },
          },
          {
            key: "cost_per_day",
            compareKey: null,
            options: {
              metric: {
                id: "cost_per_day",
                format: "currency",
                accessor: (d) => {
                  return d?.cost_per_day;
                },
                name: "Cost/Day",
              },
            },
          },
          {
            key: "acos_calculated",
            compareKey: null,
            options: {
              metric: {
                id: "acos_calculated",
                format: "percent",
                accessor: (d) => {
                  return d?.acos_calculated;
                },
                name: "ACOS",
                decimalPlaces: 2,
              },
            },
          },
        ]}
        data={tableData}
        className="pricing-table"
        selectionKey="price"
        groupMetrics={false}
        isExpandable={false}
        isSelectable={false}
        hideSearchFilter={true}
        defaultPageSize={10}
        tableRowDescription={"Price"}
      />
    </>
  );
};

AdDetails.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(AdDetails))
);
