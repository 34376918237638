import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import getMetricSpec from "views/merchandise/metrics";

const SEOChart = ({ seoData }) => {
  const seoChartOptions = useMemo(() => {
    if (!seoData) {
      return null;
    }
    const categories = ["D (0-25)", "C (26-50)", "B (51-75)", "A (76-100)"];
    const listings = seoData.map((item) => item.seo_count).splice(0, 4);
    const ctr = seoData.map((item) => item.ctr * 100).splice(0, 4);
    return {
      chart: {
        type: "column",
        height: 260,
      },
      legend: { enabled: false },
      title: {
        text: null,
      },
      xAxis: {
        categories: categories,
        crosshair: true,
        title: {
          text: null,
        },
      },
      yAxis: [
        {
          min: 0,
          title: {
            text: "Click Through Rate",
          },
        },
        {
          min: 0,
          title: {
            text: "Number of Listings",
          },
          opposite: true,
        },
      ],
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormatter: function (x) {
          return `<tr><td style="padding-right: 10px;">${
            this.series.name
          }: </td><td style="color:${this.color}">&nbsp;<b> ${this.y.toFixed(
            2
          )}${this.series.name === "CTR" ? "%" : ""}</b></td></tr>`;
        },
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "# Listings by Score Range",
          data: listings,
          color: getMetricSpec("USA", "seo_score").color,
          yAxis: 1,
        },
        {
          type: "spline",
          name: "CTR",
          data: ctr,
          color: getMetricSpec("USA", "ctr").color,
        },
      ],
    };
  }, [seoData]);

  if (!seoChartOptions) {
    return null;
  }

  return <HighchartsReact highcharts={Highcharts} options={seoChartOptions} />;
};

export default SEOChart;
