import "assets/css/trellis-styles.css";
import EnterpriseImage from "assets/images/info/subscription_plans/Enterprise_plan_image.png";
import EntrepeneurImage from "assets/images/info/subscription_plans/Entrepreneur_plan_image.png";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import axios from "axios";
import { AuthButton } from "components/core/basic/Button";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import getURLPrefix from "utils/getUrlPrefix";
import { SUBSCRIPTION_PLANS_UPPERCASE } from "utils/onboardingConstants";
import * as Sentry from "@sentry/react";
import api from "utils/api";
import checkModule from "utils/checkModule";
import { captureException } from "@sentry/react";
import { Grid, Hidden, withStyles } from "@material-ui/core";
import withMediaQuery from "hocs/withMediaQuery";
import { selectModules } from "redux/selectors/modules";
import CustomHref from "components/core/blocks/CustomHref";
import { formatCurrency } from "utils/formatNumber";
import { setSubscriptionTiers } from "redux/actions/userActions";
import store from "redux/store";
import SubscriptionCard from "../onboarding/subscription/SubscriptionCard";
import Switch from "react-bootstrap-switch";
import Loading from "components/core/blocks/Loading";

const subscriptionPlans = SUBSCRIPTION_PLANS_UPPERCASE;

let URL_PREFIX = getURLPrefix();
const ANNUAL_DISCOUNT_PERCENTAGE = 10;

const useStyles = (theme) => ({
  corporateCardRadio: {
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2rem",
    },
  },
  corporateCardImage: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      paddingLeft: "1rem",
    },
  },
  corporateCardBody: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-45px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-25px",
    },
  },
  corporateCardHeader: {
    textAlign: "center",
    color: "#D91266",
    fontSize: "16px",
    fontWeight: 500,
    margin: "2rem 0 2rem -8rem",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start",
    marginLeft: "-4rem",
  },
  mobileCardStyles: {
    flexDirection: "column",
    "@media (max-width:1270px)": {
      flexDirection: "row",
    },
  },
});

class SubscriptionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subList: [],
      subName: "",
      signUpDate: "",
      subSuccessful: false,
      reactivateActive: false,
      error: {},
      changePlanModal: this.props.location?.state?.showModal ? true : false,
      currentTier: "",
      currentPricingTier: "",
      currentAnalyticsTier: "",
      upgradedSubscriptionPlanName: "",
      upgradedPricingSubscriptionPlanName: "",
      upgradedAnalyticsSubscriptionPlanName: "",
      subscriptionPlan: null,
      pricingSubscriptionPlan: null,
      analyticsSubscriptionPlan: null,
      subscriptionPlanName: "",
      pricingSubscriptionPlanName: "",
      analyticsSubscriptionPlanName: "",
      payAmount: "",
      payPlan: "",
      pricingPayPlan: "",
      analyticsPayPlan: "",
      payFrequency: "",
      upgradedPayPlan: "",
      upgradedPricingPayPlan: "",
      upgradedAnalyticsPayPlan: "",
      monthlyChecked: true,
      numOfShelves: this.props.location?.state?.numOfShelves
        ? this.props.location?.state?.numOfShelves
        : this.props.currentNumOfShelves,
      currentNumOfShelves: this.props.location?.state?.numOfShelves
        ? this.props.location?.state?.numOfShelves
        : this.props.currentNumOfShelves,
      monthlyShelfFee: checkModule(this.props.modules, "SEARCH_ANALYTICS")
        ? this.props.monthlyShelfFee
        : 0,
      includedShelves: 0,
      numOfActiveShelves: 0,
      upgradeTarget: "advertising",
    };
  }

  hideBillingPeriodSwitch =
    (this.props.user.tier?.indexOf("MONTHLY") > -1 &&
      this.props.user.pricing_tier !== "unknown") ||
    (this.props.user.pricing_tier?.indexOf("MONTHLY") > -1 &&
      this.props.user.tier !== "unknown") ||
    (this.props.user.tier?.indexOf("ANNUAL") > -1 &&
      this.props.user.pricing_tier !== "unknown") ||
    (this.props.user.pricing_tier?.indexOf("ANNUAL") > -1 &&
      this.props.user.tier !== "unknown");

  fetchActiveShelfCount = async () => {
    try {
      const res = await api.get("/api/shelves");
      this.setState({ numOfActiveShelves: res.data.count });
    } catch (e) {
      captureException(e);
    }
  };

  componentDidMount() {
    if (
      this.props.amz_onboard.org_status === "trial_active" ||
      this.props.amz_onboard.org_status === "trial_expired" ||
      this.props.amz_onboard.org_status === "pre_trial"
    ) {
      // do nothing
    } else {
      this.getSubscriptionList();
      this.getSubscriptionName();
      this.getSignUpDate();

      if (checkModule(this.props.modules, "SEARCH_ANALYTICS")) {
        this.fetchActiveShelfCount();
      }
    }
  }

  componentDidUpdate() {
    // Update current shelf subscription data in case not ready prior to initial render
    if (
      (checkModule(this.props.modules, "SEARCH_ANALYTICS") &&
        this.state.currentNumOfShelves !== this.props.currentNumOfShelves) ||
      this.state.monthlyShelfFee !== this.props.monthlyShelfFee
    ) {
      this.setState({
        currentNumOfShelves: this.props.currentNumOfShelves,
        numOfShelves: this.props.currentNumOfShelves,
        monthlyShelfFee: this.props.monthlyShelfFee,
      });
    }
  }

  getSignUpDate = () => {
    axios
      .get(URL_PREFIX + "/api/billing/plan")
      .then((res) => {
        this.setState({ signUpDate: res.data.sign_up_date });
      })
      .catch((error) => {
        if (error?.response?.status !== 400) {
          Sentry.captureException(error);
        }
      });
  };

  getDisplayName = (tier) => {
    let display =
      SUBSCRIPTION_PLANS_UPPERCASE[tier.split("__")?.[0]]?.displayName;

    return display ?? "Custom";
  };

  onCardClick = (targetTier, targetSubscriptionItem) => {
    if (targetSubscriptionItem === "advertising") {
      this.setState(
        {
          upgradedSubscriptionPlanName: targetTier,
        },
        () => {
          this.planChanged();
        }
      );
    }
    if (targetSubscriptionItem === "pricing") {
      this.setState(
        {
          upgradedPricingSubscriptionPlanName: targetTier,
        },
        () => {
          this.planChanged();
        }
      );
    }
    if (targetSubscriptionItem === "search-analytics") {
      this.setState(
        {
          upgradedAnalyticsSubscriptionPlanName: targetTier,
        },
        () => {
          this.planChanged();
        }
      );
    }
    if (targetSubscriptionItem === "content") {
      this.setState(
        {
          upgradedContentSubscriptionPlanName: targetTier,
        },
        () => {
          this.planChanged();
        }
      );
    }
  };

  getSubscriptionName = () => {
    axios.get(URL_PREFIX + "/api/aboutyourcompany").then((res) => {
      if (
        res.data.tier.startsWith("PRO") ||
        res.data.tier.startsWith("CORE_PRO")
      ) {
        this.setState(
          {
            subName: "Professional",
            currentTier: res.data.tier,
            currentPricingTier: res.data.pricing_tier,
            currentAnalyticsTier: res.data.analytics_tier,
            currentContentTier: res.data.content_tier,
            includedShelves: checkModule(this.props.modules, "SEARCH_ANALYTICS")
              ? 3
              : 0,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.tier.startsWith("ENTER")) {
        this.setState(
          {
            subName: "Enterprise",
            currentTier: res.data.tier,
            currentPricingTier: res.data.pricing_tier,
            currentAnalyticsTier: res.data.analytics_tier,
            currentContentTier: res.data.content_tier,
            includedShelves: checkModule(this.props.modules, "SEARCH_ANALYTICS")
              ? 3
              : 0,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (
        res.data.tier.startsWith("ENTREPREN") ||
        res.data.tier.startsWith("CORE_ENTRE")
      ) {
        this.setState(
          {
            subName: "Entrepreneur",
            currentTier: res.data.tier,
            currentPricingTier: res.data.pricing_tier,
            currentAnalyticsTier: res.data.analytics_tier,
            currentContentTier: res.data.content_tier,
            includedShelves: 0,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.tier.startsWith("CORE_GROWTH")) {
        this.setState(
          {
            subName: "Growth",
            currentTier: res.data.tier,
            currentPricingTier: res.data.pricing_tier,
            currentAnalyticsTier: res.data.analytics_tier,
            currentContentTier: res.data.content_tier,
            includedShelves: 0,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.tier.startsWith("BUSINESS")) {
        this.setState(
          {
            subName: "Business",
            currentTier: res.data.tier,
            currentPricingTier: res.data.pricing_tier,
            currentAnalyticsTier: res.data.analytics_tier,
            currentContentTier: res.data.content_tier,
            includedShelves: checkModule(this.props.modules, "SEARCH_ANALYTICS")
              ? 2
              : 0,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.tier.startsWith("CORE_CUSTOM")) {
        this.setState(
          {
            subName: "Custom",
            currentTier: res.data.tier,
            currentPricingTier: res.data.pricing_tier,
            currentAnalyticsTier: res.data.analytics_tier,
            currentContentTier: res.data.content_tier,
            includedShelves: 0,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.tier.startsWith("CORPORATE")) {
        this.setState(
          {
            subName: "Corporate",
            currentTier: res.data.tier,
            currentPricingTier: res.data.pricing_tier,
            currentAnalyticsTier: res.data.analytics_tier,
            currentContentTier: res.data.content_tier,
            includedShelves: 0,
          },
          () => {
            this.extractSubscription();
          }
        );
      }

      // Get pricing subscription name
      if (res.data.pricing_tier.startsWith("BUSINESS")) {
        this.setState(
          {
            pricingSubName: "Starter",
            currentPricingTier: res.data.pricing_tier,
            upgradedPricingSubscriptionPlanName: res.data.pricing_tier,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.pricing_tier.startsWith("ENTER")) {
        this.setState(
          {
            pricingSubName: "Enterprise",
            currentPricingTier: res.data.pricing_tier,
            upgradedPricingSubscriptionPlanName: res.data.pricing_tier,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.pricing_tier.startsWith("ENTREPREN")) {
        this.setState(
          {
            pricingSubName: "Entrepreneur",
            currentPricingTier: res.data.pricing_tier,
            upgradedPricingSubscriptionPlanName: res.data.pricing_tier,
          },
          () => {
            this.extractSubscription();
          }
        );
      }

      // Get analytics subscription name
      if (res.data.analytics_tier.startsWith("FREE")) {
        this.setState(
          {
            analyticsSubName: "Free",
            currentAnalyticsTier: res.data.analytics_tier,
            upgradedAnalyticsSubscriptionPlanName: res.data.analytics_tier,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.analytics_tier.startsWith("ENTER")) {
        this.setState(
          {
            analyticsSubName: "Enterprise",
            currentAnalyticsTier: res.data.analytics_tier,
            upgradedAnalyticsSubscriptionPlanName: res.data.analytics_tier,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.analytics_tier.startsWith("ENTREPREN")) {
        this.setState(
          {
            analyticsSubName: "Entrepreneur",
            currentAnalyticsTier: res.data.analytics_tier,
            upgradedAnalyticsSubscriptionPlanName: res.data.analytics_tier,
          },
          () => {
            this.extractSubscription();
          }
        );
      } else if (res.data.analytics_tier.startsWith("PRO")) {
        this.setState(
          {
            analyticsSubName: "Professional",
            currentAnalyticsTier: res.data.analytics_tier,
            upgradedAnalyticsSubscriptionPlanName: res.data.analytics_tier,
          },
          () => {
            this.extractSubscription();
          }
        );
      }
    });
  };

  extractSubscription() {
    const { currentTier, currentPricingTier } = this.state;
    let plan = currentTier.split("__");
    let pricingPlan = currentPricingTier?.split("__");
    if (plan[1] === "ANNUAL") {
      if (this.state.upgradeTarget === "pricing") {
        this.setState({
          pricingSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          pricingSubscriptionPlanName: pricingPlan[0],
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          payFrequency: pricingPlan[1],
          payAmount: subscriptionPlans[pricingPlan[0]]?.annual?.["pay"],
          payPlan: subscriptionPlans[plan[0]]?.annual?.["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.annual?.["plan"],
          upgradedPricingSubscriptionPlanName: pricingPlan[0]?.annual?.["plan"],
          monthlyChecked: false,
        });
      } else if (this.state.upgradeTarget === "search-analytics") {
        this.setState({
          analyticsSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          analyticsSubscriptionPlanName: pricingPlan[0],
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          payFrequency: pricingPlan[1],
          payAmount: subscriptionPlans[pricingPlan[0]]?.annual?.["pay"],
          payPlan: subscriptionPlans[plan[0]]?.annual?.["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.annual?.["plan"],
          upgradedAnalyticsSubscriptionPlanName:
            pricingPlan[0]?.annual?.["plan"],
          monthlyChecked: false,
        });
      } else if (this.state.upgradeTarget === "content") {
        this.setState({
          analyticsSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          analyticsSubscriptionPlanName: pricingPlan[0],
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          payFrequency: pricingPlan[1],
          payAmount: subscriptionPlans[pricingPlan[0]]?.annual?.["pay"],
          payPlan: subscriptionPlans[plan[0]]?.annual?.["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.annual?.["plan"],
          upgradedContentSubscriptionPlanName: pricingPlan[0]?.annual?.["plan"],
          monthlyChecked: false,
        });
      } else {
        this.setState({
          pricingSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          pricingSubscriptionPlanName: pricingPlan[0],
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          payFrequency: plan[1],
          payAmount: subscriptionPlans[plan[0]]?.annual?.["pay"],
          payPlan: subscriptionPlans[plan[0]]?.annual?.["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.annual?.["plan"],
          upgradedSubscriptionPlanName: plan[0],
          monthlyChecked: false,
        });
      }
    } else if (plan[1] === "MONTHLY") {
      if (this.state.upgradeTarget === "pricing") {
        this.setState({
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          pricingSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          pricingSubscriptionPlanName: pricingPlan[0],
          payFrequency: pricingPlan[1],
          payAmount: subscriptionPlans[pricingPlan[0]]?.monthly["pay"],
          payPlan: subscriptionPlans[plan[0]]?.monthly["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.monthly["plan"],
          upgradedPricingSubscriptionPlanName: pricingPlan[0],
          monthlyChecked: true,
        });
      } else if (this.state.upgradeTarget === "search-analytics") {
        this.setState({
          analyticsSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          analyticsSubscriptionPlanName: pricingPlan[0],
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          payFrequency: pricingPlan[1],
          payAmount: subscriptionPlans[pricingPlan[0]]?.annual?.["pay"],
          payPlan: subscriptionPlans[plan[0]]?.annual?.["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.annual?.["plan"],
          upgradedAnalyticsSubscriptionPlanName: pricingPlan[0],
          monthlyChecked: true,
        });
      } else if (this.state.upgradeTarget === "content") {
        this.setState({
          analyticsSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          analyticsSubscriptionPlanName: pricingPlan[0],
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          payFrequency: pricingPlan[1],
          payAmount: subscriptionPlans[pricingPlan[0]]?.annual?.["pay"],
          payPlan: subscriptionPlans[plan[0]]?.annual?.["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.annual?.["plan"],
          upgradedContentSubscriptionPlanName: pricingPlan[0],
          monthlyChecked: true,
        });
      } else {
        this.setState({
          pricingSubscriptionPlan: subscriptionPlans[pricingPlan[0]],
          pricingSubscriptionPlanName: pricingPlan[0],
          subscriptionPlan: subscriptionPlans[plan[0]],
          subscriptionPlanName: plan[0],
          payFrequency: plan[1],
          payAmount: subscriptionPlans[plan[0]]?.monthly["pay"],
          payPlan: subscriptionPlans[plan[0]]?.monthly["plan"],
          pricingPayPlan: subscriptionPlans[pricingPlan[0]]?.monthly["plan"],
          upgradedSubscriptionPlanName: plan[0],
          monthlyChecked: true,
        });
      }
    }
  }

  planChanged() {
    this.setState({
      subscriptionPlan:
        subscriptionPlans[this.state.upgradedSubscriptionPlanName],
      pricingSubscriptionPlan:
        subscriptionPlans[this.state.upgradedPricingSubscriptionPlanName],
      analyticsSubscriptionPlan:
        subscriptionPlans[this.state.upgradedAnalyticsSubscriptionPlanName],
      payAmount: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedSubscriptionPlanName]?.monthly[
            "pay"
          ]
        : subscriptionPlans[this.state.upgradedSubscriptionPlanName]?.annual[
            "pay"
          ],
      upgradedPayPlan: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedSubscriptionPlanName]?.monthly[
            "plan"
          ]
        : subscriptionPlans[this.state.upgradedSubscriptionPlanName]?.annual[
            "plan"
          ],
      upgradedPricingPayPlan: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedPricingSubscriptionPlanName]
            ?.monthly["plan"]
        : subscriptionPlans[this.state.upgradedPricingSubscriptionPlanName]
            ?.annual["plan"],
      upgradedPricingSubscriptionPlan: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedPricingSubscriptionPlanName]
            ?.monthly["plan"]
        : subscriptionPlans[this.state.upgradedPricingSubscriptionPlanName]
            ?.annual["plan"],
      //
      upgradedAnalyticsPayPlan: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedAnalyticsSubscriptionPlanName]
            ?.monthly["plan"]
        : subscriptionPlans[this.state.upgradedAnalyticsSubscriptionPlanName]
            ?.annual["plan"],
      upgradedAnalyticsSubscriptionPlan: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedAnalyticsSubscriptionPlanName]
            ?.monthly["plan"]
        : subscriptionPlans[this.state.upgradedAnalyticsSubscriptionPlanName]
            ?.annual["plan"],
      upgradedContentPayPlan: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedContentSubscriptionPlanName]
            ?.monthly["plan"]
        : subscriptionPlans[this.state.upgradedContentSubscriptionPlanName]
            ?.annual["plan"],
      upgradedContentSubscriptionPlan: this.state.monthlyChecked
        ? subscriptionPlans[this.state.upgradedContentSubscriptionPlanName]
            ?.monthly["plan"]
        : subscriptionPlans[this.state.upgradedContentSubscriptionPlanName]
            ?.annual["plan"],
    });

    if (checkModule(this.props.modules, "SEARCH_ANALYTICS"))
      if (this.state.upgradedSubscriptionPlanName === "ENTREPRENEUR") {
        // Reflect changes in selected subscription plan in digital shelf pricing
        this.setState({ includedShelves: 0 });
      } else if (this.state.upgradedSubscriptionPlanName === "BUSINESS") {
        this.setState({ includedShelves: 2 });
      } else if (this.state.upgradedSubscriptionPlanName === "PROFESSIONAL") {
        this.setState({ includedShelves: 3 });
      } else if (this.state.upgradedSubscriptionPlanName === "ENTERPRISE") {
        this.setState({ includedShelves: 3 });
      } else if (this.state.upgradedSubscriptionPlanName === "CORPORATE") {
        this.setState({ includedShelves: 0 });
      }

    // Ensure lowest number of shelves for subscription type is accounted for when subscription changes
    if (this.state.includedShelves > this.state.numOfShelves) {
      this.setState({ numOfShelves: this.state.includedShelves });
    }
  }

  async processAnalyticsSubscription(updateTierSubscription) {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    let numOfBillableShelves =
      this.state.numOfShelves - this.state.includedShelves;
    let request_data = {
      shelf_count: numOfBillableShelves,
    };

    axios
      .post(URL_PREFIX + "/api/billing/digitalshelf", request_data, {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.getShelfPrice();
          this.setState({ changePlanModal: false });
        }
      });
  }

  async upgradeSubscription() {
    let updateTierSubscription = true;

    if (
      this.state.numOfShelves !== this.state.currentNumOfShelves &&
      checkModule(this.props.modules, "SEARCH_ANALYTICS")
    ) {
      this.processAnalyticsSubscription(updateTierSubscription);
    }

    if (updateTierSubscription) {
      let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
      let requestData = {};

      if (this.state.upgradeTarget === "advertising") {
        requestData = {
          tier: this.state.upgradedPayPlan ?? "unknown",
          pricing_tier: this.props.user.pricing_tier ?? "unknown",
          analytics_tier: this.props.user.analytics_tier ?? "unknown",
          content_tier: this.props.user.content_tier ?? "unknown",
          pb_tier: this.props.user.pb_tier ?? "unknown",
          skipped: false,
        };
      }

      if (this.state.upgradeTarget === "pricing") {
        requestData = {
          tier: this.props.user.tier ?? "unknown",
          pricing_tier: this.state.upgradedPricingPayPlan ?? "unknown",
          analytics_tier: this.props.user.analytics_tier ?? "unknown",
          content_tier: this.props.user.content_tier ?? "unknown",
          pb_tier: this.props.user.pb_tier ?? "unknown",
          skipped: false,
        };
      }

      if (this.state.upgradeTarget === "search-analytics") {
        requestData = {
          tier: this.props.user.tier ?? "unknown",
          pricing_tier: this.props.user.pricing_tier ?? "unknown",
          analytics_tier: this.state.upgradedAnalyticsPayPlan ?? "unknown",
          content_tier: this.props.user.content_tier ?? "unknown",
          pb_tier: this.props.user.pb_tier ?? "unknown",
          skipped: false,
        };
      }

      if (this.state.upgradeTarget === "content") {
        requestData = {
          tier: this.props.user.tier ?? "unknown",
          pricing_tier: this.props.user.pricing_tier ?? "unknown",
          analytics_tier: this.props.user.content_tier ?? "unknown",
          content_tier: this.state.upgradedContentPayPlan ?? "unknown",
          pb_tier: this.props.user.pb_tier ?? "unknown",
          skipped: false,
        };
      }

      this.setState({ loading: true });
      axios
        .put(URL_PREFIX + "/api/tierselection", requestData, {
          params: {},
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          if (response.status === 204) {
            axios
              .put(URL_PREFIX + "/api/billing/subscription", {
                params: {
                  update_type: this.state.upgradeTarget,
                },
                headers: { Authorization: AuthStr },
              })
              .then((response) => {
                this.setState({ changePlanModal: false });
              });

            store.dispatch(setSubscriptionTiers(requestData));

            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }
  }

  getSubscriptionList = () => {
    axios.get(URL_PREFIX + "/api/billing/subscription").then((res) => {
      this.setState({ subList: res.data.subscriptions.data });
    });
  };

  handleReactivateButton = () => {
    this.setState({ reactivateActive: true });
    const AuthHeader = "Bearer ".concat(this.props.auth.tokens.access_token);

    axios
      .post(
        URL_PREFIX + "/api/billing/subscription",
        {
          initialSubscriptionType: "advertising",
        },
        {
          params: {},
          headers: { Authorization: AuthHeader },
        }
      )
      .then((result) => {
        // The card had an error when trying to attach it to a customer.
        if (result.error) {
          this.setState({
            error: {
              title: "Card Declined",
              message: "Payment failed. Please try adding a new active card.",
            },
            reactivateActive: false,
          });
        }
        return result;
      })
      .then((result) => {
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        if (result.data.status === "User already subscribed.") {
          this.setState({
            error: {
              title: "User Already Subscribed",
              message: "Your organization is already subscribed.",
            },
          });
          throw new Error("User already subscribed.");
        }

        if (result.data.status === "Subscription in retry state.") {
          localStorage.setItem("latestInvoiceId", result.data.invoice.id);
          localStorage.setItem(
            "latestInvoicePaymentIntentStatus",
            result.data.invoice.payment_intent.status
          );

          this.retryInvoiceWithNewPaymentMethod();

          return {
            subscription: "break",
          };
        }

        if (
          result.data.subscription.status === "active" ||
          result.data.subscription.status === "trialing"
        ) {
          this.setState({ subSuccessful: true });
          window.location.reload();
        }

        return {
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      // .then(this.handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(this.handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .catch((err) => {
        this.setState({
          error: {
            title: "Please try again",
            message: "Something went wrong while updating subscription status.",
          },
          reactivateActive: false,
        });
      });
  };

  handleRequiresPaymentMethod = ({ subscription }) => {
    if (!(subscription === "break")) {
      var tempSubscription = subscription.data.subscription;
      if (
        tempSubscription.latest_invoice.payment_intent !== null &&
        tempSubscription.latest_invoice.payment_intent.status !== null
      ) {
        if (
          tempSubscription.latest_invoice.payment_intent.status ===
          "requires_payment_method"
        ) {
          localStorage.setItem(
            "latestInvoiceId",
            tempSubscription.latest_invoice.id
          );
          localStorage.setItem(
            "latestInvoicePaymentIntentStatus",
            tempSubscription.latest_invoice.payment_intent.status
          );
          this.setState({
            error: {
              title: "Payment Failed",
              message: "Please try a new payment method.",
            },
            reactivateActive: false,
          });
        }
      }
    }
  };

  retryInvoiceWithNewPaymentMethod() {
    var paymentInfo = {
      invoiceId: localStorage.getItem("latestInvoiceId"),
    };

    localStorage.removeItem("latestInvoiceId");
    localStorage.removeItem("latestInvoicePaymentIntentStatus");

    const AuthHeader = "Bearer ".concat(this.props.auth.tokens.access_token);

    return axios
      .post(URL_PREFIX + "/api/billing/retrysubscription", paymentInfo, {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((result) => {
        if (result.error) {
          this.setState({
            error: {
              title: "Improper Card Info",
              message: "Please try a new payment method.",
            },
          });
          throw result;
        }
        return result;
      })
      .then((result) => {
        if (result.data.status === "User already subscribed.") {
          this.setState({
            error: {
              title: "User Already Subscribed",
              message: "Your organization is already subscribed",
            },
          });
          throw new Error("User already subscribed.");
        }

        if (
          result.data.subscription.status === "active" ||
          result.data.subscription.status === "trialing"
        ) {
          this.setState({ subSuccessful: true });
          window.location.reload();
        }

        return {
          subscription: result,
        };
      })
      .then(this.handleRequiresPaymentMethod)
      .catch((err) => {
        this.setState({
          error: {
            title: "Improper Card Info",
            message: "Please try a new payment method.",
          },
          reactivateActive: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    withMediaQuery((theme) => theme.breakpoints.down("sm"), {
      defaultMatches: true,
    });

    return (
      <div
        className="card "
        style={{ paddingBottom: "15px", fontSize: "13px" }}
      >
        <Row
          className="text-center pt-4 px-2"
          style={{
            marginBottom: "0rem",
            marginLeft: "0px",
            marginRight: "0px",
          }}
        >
          <p style={{ marginTop: "1rem", fontSize: "20px", fontWeight: 600 }}>
            Plan Details
          </p>
        </Row>
        {this.state.subList.length !== 0 &&
        this.props.amz_onboard.org_status !== "disconnected" &&
        !this.props.amz_onboard.remainder_days ? (
          <>
            <Row
              style={{
                overflowY: "auto",
                marginLeft: "0px",
                marginRight: "0px",
              }}
              className="fs-standard"
            >
              <Row
                className="d-flex justify-content-center"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                {this.props.user.tier && this.props.user.tier !== "unknown" && (
                  <Col
                    xs={12}
                    md={10}
                    className="text-center"
                    style={{ marginTop: "1rem" }}
                  >
                    You are currently registered for the{" "}
                    <b>{this.getDisplayName(this.props.user.tier)}</b>{" "}
                    Advertising subscription, which began on{" "}
                    <b>{this.state.signUpDate}</b>.
                  </Col>
                )}
                {this.props.user.pricing_tier &&
                  this.props.user.pricing_tier !== "unknown" && (
                    <Col
                      md={10}
                      mdOffset={1}
                      className="text-center"
                      style={{ marginTop: "1rem" }}
                    >
                      You are currently registered for the{" "}
                      <b>
                        {this.getDisplayName(this.props.user.pricing_tier)}{" "}
                      </b>{" "}
                      Dynamic Pricing subscription.
                    </Col>
                  )}
                {this.props.user.analytics_tier &&
                  this.props.user.analytics_tier !== "unknown" && (
                    <Col
                      md={10}
                      mdOffset={1}
                      className="text-center"
                      style={{ marginTop: "1rem" }}
                    >
                      You are currently registered for the{" "}
                      <b>
                        {this.getDisplayName(this.props.user.analytics_tier)}{" "}
                      </b>{" "}
                      Search Analytics subscription.
                    </Col>
                  )}
                {this.props.user.content_tier &&
                  this.props.user.content_tier !== "unknown" && (
                    <Col
                      md={10}
                      mdOffset={1}
                      className="text-center"
                      style={{ marginTop: "1rem" }}
                    >
                      You are currently registered for the <b>Standard</b>{" "}
                      Content subscription.
                    </Col>
                  )}
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <Col
                  md={8}
                  mdOffset={2}
                  className="text-center"
                  style={{
                    textTransform: "capitalize",
                    marginBottom: "2rem",
                  }}
                >
                  <Row style={{ marginTop: "1rem" }}>
                    <Col className="d-flex justify-content-center">
                      <strong className="pr-2">Payment Frequency:</strong>
                      {this.state.subList[0].items.data[0].plan.interval}ly
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1rem" }}>
                    <Col className="d-flex justify-content-center">
                      <strong className="pr-2">You Pay: </strong>
                      {formatCurrency(
                        this.state.subList[0].items.data.reduce(
                          (prev, subItem) =>
                            prev +
                            (subItem.plan.amount * subItem.quantity ?? 1) / 100,
                          0
                        ),
                        // Defaulting to USA to align with Stripe currency (always USDs)
                        "USA",
                        true
                      )}
                    </Col>
                  </Row>
                  {checkModule(this.props.modules, "SEARCH_ANALYTICS") && (
                    <Row style={{ marginTop: "1rem" }}>
                      <Col className="d-flex justify-content-center">
                        <strong className="pr-2">Shelf count: </strong>
                        {this.state.numOfActiveShelves}/
                        {this.state.currentNumOfShelves}
                      </Col>
                    </Row>
                  )}

                  <Row
                    className="d-flex justify-content-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <AuthButton
                      title="Change Plan"
                      buttonStyle="adplan_button"
                      onClick={(e) => {
                        this.setState({ changePlanModal: true });
                      }}
                      style={{ width: "85%", maxWidth: "85%" }}
                    />
                  </Row>
                </Col>
              </Row>
            </Row>
          </>
        ) : null}
        {this.props.amz_onboard.org_status === "disconnected" ||
        this.props.amz_onboard.remainder_days !== null ||
        this.props.subscriptionCancelled ? (
          <Row
            style={{
              height: "225px",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              className="text-center"
              style={{ marginTop: "2rem" }}
            >
              Your subscription is currently <b>cancelled</b>.
              {this.props.amz_onboard.remainder_days !== null ? (
                <span>
                  {" "}
                  You have {this.props.amz_onboard.remainder_days} paid days
                  left.{" "}
                </span>
              ) : null}
            </Col>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              className="text-center"
              style={{ marginBottom: "4rem" }}
            >
              {!this.state.reactivateActive ? (
                <AuthButton
                  title="Reactivate with Trellis"
                  buttonStyle="adplan_button"
                  disabled={false}
                  onClick={(e) => this.handleReactivateButton()}
                  style={{ width: "70%", maxWidth: "70%" }}
                />
              ) : (
                <img
                  src={logo}
                  alt="Loading..."
                  className="rotate"
                  width="80"
                  height="auto"
                />
              )}
            </Col>
          </Row>
        ) : null}
        {this.props.amz_onboard.org_status === "payment_required" ? (
          <Row>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              className="text-center"
              style={{ marginBottom: "2rem" }}
            >
              <b> Your account requires a new payment method.</b>
            </Col>
          </Row>
        ) : null}
        {this.state.error.title !== undefined ? (
          <SweetAlert
            title={this.state.error.title}
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={(e) => this.setState({ error: {} })}
          >
            {this.state.error.message}
          </SweetAlert>
        ) : null}
        <div style={{ width: "100%" }}>
          <Modal
            show={this.state.changePlanModal}
            onHide={() => this.setState({ changePlanModal: false })}
            dialogClassName={this.props.mediaQuery ? "modal-100w" : "modal-70w"}
            className={classes.subscriptionModal}
            centered="true"
          >
            <Modal.Body style={{ minHeight: "70vh" }}>
              {this.state.loading ? (
                <Row>
                  <Loading height={"70vh"} fullPage={false} />;
                </Row>
              ) : (
                <div style={{ padding: "1rem 2rem" }}>
                  <Row>
                    <span
                      onClick={() => this.setState({ changePlanModal: false })}
                      style={{
                        fontSize: "26px",
                        color: "lightgray",
                        fontWeight: "100",
                        float: "right",
                        cursor: "pointer",
                        textAlign: "end",
                      }}
                    >
                      &#10005;
                    </span>
                  </Row>
                  <Row>
                    <div className="header" style={{ marginBottom: "2rem" }}>
                      <h4
                        className="title text-center"
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                          fontSize: "20px",
                          color: "#403E3D",
                          fontWeight: "700",
                        }}
                      >
                        Upgrade{" "}
                        {this.state.upgradeTarget === "advertising"
                          ? "Advertising"
                          : this.state.upgradeTarget === "search-analytics"
                          ? "Search Analytics"
                          : this.state.upgradeTarget === "content"
                          ? "Content"
                          : "Dynamic Pricing"}{" "}
                        Subscription Plan
                        <hr />
                      </h4>

                      <Col
                        xs={12}
                        className="mt-5"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        <div
                          style={{ padding: "0 2rem", textAlign: "center" }}
                          hidden={this.state.upgradeTarget === "advertising"}
                        >
                          <CustomHref
                            text={
                              <span
                                onClick={(e) => {
                                  this.setState({
                                    upgradeTarget: "advertising",
                                  });
                                }}
                              >
                                View Main Subscription
                                <br />
                                Upgrade Options
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ padding: "0 2rem", textAlign: "center" }}
                          hidden={this.state.upgradeTarget === "pricing"}
                        >
                          <CustomHref
                            text={
                              <span
                                onClick={(e) => {
                                  this.setState({
                                    upgradeTarget: "pricing",
                                  });
                                }}
                              >
                                View Dynamic Pricing
                                <br />
                                Upgrade Options
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ padding: "0 2rem", textAlign: "center" }}
                          hidden={
                            this.state.upgradeTarget === "search-analytics"
                          }
                        >
                          <CustomHref
                            text={
                              <span
                                onClick={(e) => {
                                  this.setState({
                                    upgradeTarget: "search-analytics",
                                  });
                                }}
                              >
                                View Analytics
                                <br />
                                Upgrade Options
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ padding: "0 2rem", textAlign: "center" }}
                          hidden={this.state.upgradeTarget === "content"}
                        >
                          <CustomHref
                            text={
                              <span
                                onClick={(e) => {
                                  this.setState({
                                    upgradeTarget: "content",
                                  });
                                }}
                              >
                                View Content
                                <br />
                                Upgrade Options
                              </span>
                            }
                          />
                        </div>
                      </Col>
                    </div>
                  </Row>

                  {this.state.changePlanModal ? (
                    <>
                      {this.state.upgradeTarget === "advertising" ? (
                        <>
                          <Grid
                            container
                            className="text-center"
                            style={{
                              maxHeight: "50vh",
                              overflowY: "auto",
                              position: "relative",
                              paddingBottom: "30px",
                            }}
                          >
                            <Grid
                              container
                              item
                              spacing={2}
                              xs={11}
                              style={{
                                margin: "2rem auto 0",
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                }}
                                className={classes.mobileCardStyles}
                              >
                                <SubscriptionCard
                                  cardImage={EntrepeneurImage}
                                  planId={"CORE_ENTREPRENEUR"}
                                  planName={"Entrepreneur"}
                                  monthlySubscriptionPrice={"$299"}
                                  annualSubscriptionPrice={"$239"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={
                                    <>
                                      <span>Placement Advertising</span>
                                      <br />
                                      <span>Product Content</span>
                                    </>
                                  }
                                  selectedSubscription={
                                    this.state.upgradedSubscriptionPlanName
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={() => {
                                    this.onCardClick(
                                      "CORE_ENTREPRENEUR",
                                      "advertising"
                                    );
                                  }}
                                  annualSales={"Up to $2M"}
                                  hidden={
                                    this.state.currentTier &&
                                    !this.state.currentTier
                                      ?.split("__")?.[0]
                                      ?.includes("CORE_ENTREPRENEUR")
                                  }
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                  core={true}
                                />
                                <SubscriptionCard
                                  cardImage={EnterpriseImage}
                                  planId={"CORE_GROWTH"}
                                  planName={"Growth"}
                                  monthlySubscriptionPrice={"$999"}
                                  annualSubscriptionPrice={"$799"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={
                                    <>
                                      <span>Placement Advertising</span>
                                      <br />
                                      <span>Product Content</span>
                                    </>
                                  }
                                  selectedSubscription={
                                    this.state.upgradedSubscriptionPlanName
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={(planName) => {
                                    this.onCardClick(
                                      "CORE_GROWTH",
                                      "advertising"
                                    );
                                  }}
                                  annualSales={"$2M to $10M"}
                                  hidden={[
                                    "CORE_PROFESSIONAL",
                                    "CORE_CUSTOM",
                                  ].includes(
                                    this.state.currentTier.split("__")?.[0]
                                  )}
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                  core={true}
                                />
                                <SubscriptionCard
                                  cardImage={EnterpriseImage}
                                  planId={"CORE_PROFESSIONAL"}
                                  planName={"Professional"}
                                  monthlySubscriptionPrice={"$1499"}
                                  annualSubscriptionPrice={"$1199"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={
                                    <>
                                      <span>Placement Advertising</span>
                                      <br />
                                      <span>Product Content</span>
                                      <br />
                                      <span>Dynamic Pricing</span>
                                      <br />
                                      <span>Promotions & Deals</span>
                                    </>
                                  }
                                  selectedSubscription={
                                    this.state.upgradedSubscriptionPlanName
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={(planName) => {
                                    this.onCardClick(
                                      "CORE_PROFESSIONAL",
                                      "advertising"
                                    );
                                  }}
                                  annualSales={"$2M to $10M"}
                                  hidden={["CORE_CUSTOM"].includes(
                                    this.state.currentTier.split("__")?.[0]
                                  )}
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                />
                                <SubscriptionCard
                                  cardImage={EnterpriseImage}
                                  planId={"CORE_CUSTOM"}
                                  planName={"Custom"}
                                  monthlySubscriptionPrice={"TBD"}
                                  annualSubscriptionPrice={"TBD"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={
                                    <>
                                      <span>Placement Advertising</span>
                                      <br />
                                      <span>Product Content</span>
                                      <br />
                                      <span>Dynamic Pricing</span>
                                      <br />
                                      <span>Promotions & Deals</span>
                                    </>
                                  }
                                  selectedSubscription={
                                    this.state.upgradedSubscriptionPlanName
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={(planName) => {
                                    this.onCardClick(
                                      "CORE_CUSTOM",
                                      "advertising"
                                    );
                                  }}
                                  annualSales={"Above $10M"}
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                  core={true}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : this.state.upgradeTarget === "search-analytics" ? (
                        <>
                          <Grid
                            container
                            className="text-center"
                            style={{
                              maxHeight: "50vh",
                              overflowY: "auto",
                              position: "relative",
                              paddingBottom: "30px",
                            }}
                          >
                            <Grid
                              container
                              item
                              spacing={2}
                              xs={11}
                              style={{
                                margin: "2rem auto 0",
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                }}
                                className={classes.mobileCardStyles}
                              >
                                <SubscriptionCard
                                  planId={"FREE_ANALYTICS"}
                                  planName={"Free"}
                                  monthlySubscriptionPrice={"FREE"}
                                  annualSubscriptionPrice={"FREE"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={<>2 active shelves</>}
                                  selectedSubscription={
                                    this.state.upgradedAnalyticsSubscriptionPlanName.split(
                                      "__"
                                    )[0]
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={() => {
                                    this.onCardClick(
                                      "FREE_ANALYTICS",
                                      "search-analytics"
                                    );
                                  }}
                                  annualSales={"Below $120k"}
                                  hidden={
                                    !this.state.currentAnalyticsTier?.includes(
                                      "FREE"
                                    )
                                  }
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                />
                                <SubscriptionCard
                                  planId={"ENTREPRENEUR_ANALYTICS"}
                                  planName={"Essentials"}
                                  monthlySubscriptionPrice={"$49"}
                                  annualSubscriptionPrice={"$44"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={<>5 active shelves</>}
                                  selectedSubscription={
                                    this.state.upgradedAnalyticsSubscriptionPlanName.split(
                                      "__"
                                    )[0]
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={(planName) => {
                                    this.onCardClick(
                                      "ENTREPRENEUR_ANALYTICS",
                                      "search-analytics"
                                    );
                                  }}
                                  annualSales={"Up to $120k"}
                                  hidden={
                                    this.state.currentAnalyticsTier?.includes(
                                      "ENTERPRISE"
                                    ) ||
                                    this.state.currentAnalyticsTier?.includes(
                                      "PROFESSIONAL"
                                    )
                                  }
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                />
                                <SubscriptionCard
                                  planId={"PROFESSIONAL_ANALYTICS"}
                                  planName={"Premium"}
                                  monthlySubscriptionPrice={"$99"}
                                  annualSubscriptionPrice={"$89"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={<>15 active shelves</>}
                                  selectedSubscription={
                                    this.state.upgradedAnalyticsSubscriptionPlanName.split(
                                      "__"
                                    )[0]
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={(planName) => {
                                    this.onCardClick(
                                      "PROFESSIONAL_ANALYTICS",
                                      "search-analytics"
                                    );
                                  }}
                                  annualSales={"$360k to $1M"}
                                  hidden={this.state.currentAnalyticsTier?.includes(
                                    "ENTERPRISE"
                                  )}
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                />

                                <SubscriptionCard
                                  planId={"ENTERPRISE_ANALYTICS"}
                                  planName={"Enterprise"}
                                  monthlySubscriptionPrice={"$199"}
                                  annualSubscriptionPrice={"$179"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={<>40 active shelves</>}
                                  selectedSubscription={
                                    this.state.upgradedAnalyticsSubscriptionPlanName.split(
                                      "__"
                                    )[0]
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={(planName) => {
                                    this.onCardClick(
                                      "ENTERPRISE_ANALYTICS",
                                      "search-analytics"
                                    );
                                  }}
                                  annualSales={"$1M and above"}
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : this.state.upgradeTarget === "content" ? (
                        <>
                          <Grid
                            container
                            className="text-center"
                            style={{
                              maxHeight: "50vh",
                              overflowY: "auto",
                              position: "relative",
                              paddingBottom: "30px",
                            }}
                          >
                            <Grid
                              container
                              item
                              spacing={2}
                              xs={11}
                              style={{
                                margin: "2rem auto 0",
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                }}
                                className={classes.mobileCardStyles}
                              >
                                <SubscriptionCard
                                  planId={"STANDARD_CONTENT"}
                                  planName={"Standard"}
                                  monthlySubscriptionPrice={"$49.99"}
                                  annualSubscriptionPrice={"$44.99"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={<>All Products</>}
                                  selectedSubscription={
                                    this.state.upgradedContentSubscriptionPlanName?.split(
                                      "__"
                                    )[0]
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={() => {
                                    this.onCardClick(
                                      "STANDARD_CONTENT",
                                      "content"
                                    );
                                  }}
                                  annualSales={""}
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          container
                          className="text-center"
                          style={{
                            maxHeight: "95vh",
                            overflowY: "auto",
                            paddingBottom: "30px",
                          }}
                        >
                          <Grid
                            container
                            item
                            spacing={2}
                            xs={11}
                            style={{
                              margin: "2rem auto 0",
                            }}
                          >
                            <Grid
                              container
                              style={{
                                display: "flex",
                              }}
                              className={classes.mobileCardStyles}
                            >
                              <SubscriptionCard
                                cardImage={EnterpriseImage}
                                planId={"BUSINESS_PRICING"}
                                planName={"Starter"}
                                monthlySubscriptionPrice={"$299"}
                                annualSubscriptionPrice={"$269"}
                                monthlyChecked={this.state.monthlyChecked}
                                subscriptionFeatures={<>40 Optimized Prices</>}
                                selectedSubscription={
                                  this.state.upgradedPricingSubscriptionPlanName?.split(
                                    "__"
                                  )[0]
                                }
                                monthlySubscriptionPeriod={
                                  this.state.monthlyChecked
                                }
                                onCardClick={(planName) => {
                                  this.onCardClick(
                                    "BUSINESS_PRICING",
                                    "pricing"
                                  );
                                }}
                                annualSales={"$120k to $360k"}
                                hidden={
                                  this.state.currentPricingTier?.includes(
                                    "ENTERPRISE"
                                  ) ||
                                  this.state.currentPricingTier?.includes(
                                    "CORPORATE"
                                  )
                                }
                                annualDiscountPercentage={
                                  ANNUAL_DISCOUNT_PERCENTAGE
                                }
                              />
                              <SubscriptionCard
                                planId={"PROFESSIONAL_PRICING"}
                                planName={"Premium"}
                                monthlySubscriptionPrice={"$499"}
                                annualSubscriptionPrice={"$449"}
                                monthlyChecked={this.state.monthlyChecked}
                                subscriptionFeatures={<>100 Optimized Prices</>}
                                selectedSubscription={
                                  this.state.upgradedPricingSubscriptionPlanName?.split(
                                    "__"
                                  )[0]
                                }
                                monthlySubscriptionPeriod={
                                  this.state.monthlyChecked
                                }
                                onCardClick={() => {
                                  this.onCardClick(
                                    "PROFESSIONAL_PRICING",
                                    "pricing"
                                  );
                                }}
                                annualSales={"$360k to $1M"}
                                hidden={
                                  this.state.currentPricingTier?.includes(
                                    "ENTERPRISE"
                                  ) ||
                                  this.state.currentPricingTier?.includes(
                                    "CORPORATE"
                                  )
                                }
                                annualDiscountPercentage={
                                  ANNUAL_DISCOUNT_PERCENTAGE
                                }
                              />
                              <SubscriptionCard
                                cardImage={EnterpriseImage}
                                planId={"ENTERPRISE_PRICING"}
                                planName={"Enterprise"}
                                monthlySubscriptionPrice={"$999"}
                                annualSubscriptionPrice={"$899"}
                                monthlyChecked={this.state.monthlyChecked}
                                subscriptionFeatures={
                                  <>Unlimited Optimized Prices</>
                                }
                                selectedSubscription={
                                  this.state.upgradedPricingSubscriptionPlanName?.split(
                                    "__"
                                  )[0]
                                }
                                monthlySubscriptionPeriod={
                                  this.state.monthlyChecked
                                }
                                onCardClick={(planName) => {
                                  this.onCardClick(
                                    "ENTERPRISE_PRICING",
                                    "pricing"
                                  );
                                }}
                                annualSales={"$1M to $3M"}
                                annualDiscountPercentage={
                                  ANNUAL_DISCOUNT_PERCENTAGE
                                }
                                hidden={this.state.currentPricingTier?.includes(
                                  "CORPORATE"
                                )}
                              />
                              {checkModule(
                                this.props.modules,
                                "CUSTOM_TIER"
                              ) && (
                                <SubscriptionCard
                                  planId={"CORPORATE_PRICING"}
                                  planName={"Custom"}
                                  monthlySubscriptionPrice={"TBD"}
                                  annualSubscriptionPrice={"TBD"}
                                  monthlyChecked={this.state.monthlyChecked}
                                  subscriptionFeatures={
                                    <p
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      Features to be determined.
                                    </p>
                                  }
                                  selectedSubscription={
                                    this.state.upgradedPricingSubscriptionPlanName?.split(
                                      "__"
                                    )[0]
                                  }
                                  monthlySubscriptionPeriod={
                                    this.state.monthlyChecked
                                  }
                                  onCardClick={(e, planName) => {
                                    this.onCardClick(
                                      "CORPORATE_PRICING",
                                      "pricing"
                                    );
                                  }}
                                  annualDiscountPercentage={
                                    ANNUAL_DISCOUNT_PERCENTAGE
                                  }
                                  annualSales={"Above $3M"}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container style={{ marginTop: "4rem" }}>
                        <Hidden smDown>
                          <Grid item xs={2} />
                        </Hidden>
                        {/* Below is messy, but accounts for the need for Stripe items to have same payment cadence */}
                        {!(
                          this.props.user.tier?.indexOf("MONTHLY") > -1 &&
                          this.props.user.pricing_tier !== "unknown"
                        ) ||
                          (this.props.user.pricing_tier?.indexOf("MONTHLY") >
                            -1 &&
                            this.props.user.tier !== "unknown") ||
                          (this.props.user.tier?.indexOf("ANNUAL") > -1 &&
                            this.props.user.pricing_tier !== "unknown") ||
                          (this.props.user.pricing_tier?.indexOf("ANNUAL") >
                            -1 &&
                            this.props.user.tier !== "unknown" && (
                              <Grid
                                container
                                item
                                xs={12}
                                md={8}
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <span
                                  style={{
                                    paddingLeft: "10rem",
                                    paddingRight: "2rem",
                                  }}
                                >
                                  {this.state.monthlyChecked
                                    ? "Monthly"
                                    : "Annual"}
                                </span>

                                <Switch
                                  onText=""
                                  offText=""
                                  value={!this.state.monthlyChecked}
                                  onColor="#FFFFFF"
                                  disabled={this.hideBillingPeriodSwitch}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        monthlyChecked:
                                          !this.state.monthlyChecked,
                                      },
                                      () => {
                                        if (this.state.monthlyChecked) {
                                          this.setState({
                                            payAmount:
                                              this.state.subscriptionPlan
                                                ?.monthly["pay"],
                                            upgradedPayPlan:
                                              this.state.subscriptionPlan
                                                ?.monthly["plan"],
                                            upgradedPricingPayPlan:
                                              this.state.pricingSubscriptionPlan
                                                ?.monthly["plan"],
                                            upgradedAnalyticsPayPlan:
                                              this.state
                                                .analyticsSubscriptionPlan
                                                ?.monthly["plan"],
                                            upgradedContentPayPlan:
                                              this.state.contentSubscriptionPlan
                                                ?.monthly["plan"],
                                          });
                                        } else {
                                          this.setState({
                                            payAmount:
                                              this.state.subscriptionPlan
                                                ?.annual["pay"],
                                            upgradedPayPlan:
                                              this.state.subscriptionPlan
                                                ?.annual["plan"],
                                            upgradedPricingPayPlan:
                                              this.state.pricingSubscriptionPlan
                                                ?.annual["plan"],
                                            upgradedAnalyticsPayPlan:
                                              this.state
                                                .analyticsSubscriptionPlan
                                                ?.annual["plan"],
                                            upgradedContentPayPlan:
                                              this.state.contentSubscriptionPlan
                                                ?.annual["plan"],
                                          });
                                        }
                                      }
                                    );
                                  }}
                                />
                              </Grid>
                            ))}
                      </Grid>
                      <Grid container>
                        <Grid
                          container
                          style={{
                            marginTop: "6rem",
                            marginBottom: "4rem",
                            justifyContent: "center",
                          }}
                        >
                          <AuthButton
                            title="Change Plan"
                            disabled={
                              (this.state.upgradeTarget === "advertising" &&
                                this.state.subscriptionPlanName ===
                                  this.state.upgradedSubscriptionPlanName) ||
                              (this.state.upgradeTarget === "pricing" &&
                                this.state.pricingSubscriptionPlanName ===
                                  this.state
                                    .upgradedPricingSubscriptionPlanName) ||
                              (this.state.upgradeTarget ===
                                "search-analytics" &&
                                !this.state
                                  .upgradedAnalyticsSubscriptionPlanName) ||
                              (this.state.upgradeTarget === "content" &&
                                !this.state.upgradedContentSubscriptionPlanName)
                            }
                            buttonStyle="adplan_button"
                            onClick={(e) => {
                              this.upgradeSubscription();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

SubscriptionPlan.propTypes = {
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  monthlyShelfFee: PropTypes.number.isRequired,
  currentNumOfShelves: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
  amz_onboard: state.amz_onboard,
  user: state.user,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(
  withRouter(
    withStyles(useStyles)(withMediaQuery("(max-width:740px)")(SubscriptionPlan))
  )
);
