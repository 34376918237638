import React, { createContext, useContext } from "react";

const programContext = createContext({
  size: "lg",
  showHeaderFields: true,
  engine: "pricing",
});

export const useProgramContext = () => {
  const context = useContext(programContext);

  const pathname = window.location.pathname;

  if (
    pathname.includes("advertising/dashboard") &&
    context.engine !== "bidding"
  ) {
    context.engine = "bidding";
  }

  if (pathname.includes("pricing") && context.engine !== "pricing") {
    context.engine = "pricing";
  }

  return context;
};

export const ProgramContextProvider = ({
  size = "lg",
  showHeaderFields = true,
  children,
  engine,
}) => {
  const value = {
    size,
    showHeaderFields,
    engine,
  };

  return (
    <programContext.Provider value={value}>{children}</programContext.Provider>
  );
};
