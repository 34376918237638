import { withStyles, Grid } from "@material-ui/core";
import React from "react";
import Select from "react-select";
import getQueryValue from "utils/getQueryValue";

const useStyles = (theme) => ({
  dropdownFilter: {
    [theme.breakpoints.down("xs")]: {
      width: "70%",
      margin: "1rem 0",
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:400px)"]: {
      width: "100%",
    },
  },
});

const SearchFilter = ({
  categoryData,
  selectedCategory,
  setSelectedCategory,
  selectedNotificationType,
  setSelectedNotificationType,
  filterType,
  classes,
}) => {
  const defaultOptions =
    filterType === "categories"
      ? [
          {
            label: "All Categories",
            value: "all",
          },
        ]
      : [
          {
            label: "All Notifications",
            value: "all",
          },
          {
            label: "Out of Stock",
            value: "AD_PRODUCT_OUT_OF_STOCK",
          },
          {
            label: "Low Stock",
            value: "AD_PRODUCT_LOW_OF_STOCK",
          },
          {
            label: "Ineligible",
            value: "AD_PRODUCT_INELIGIBLE",
          },
          {
            label: "New ASINs and Keywords",
            value: "AD_NEW_KWS_ASINS",
          },
          {
            label: "Advertisement Budget Exceeded",
            value: "AD_PRODUCT_EXCEEDS_ACOS_SPEND",
          },
        ];
  let options =
    filterType === "categories"
      ? defaultOptions.concat(
          categoryData?.map((c) => ({
            label: c.leaf_category_name,
            value: c.id,
          })) ?? []
        )
      : defaultOptions;

  // Preset selected category if user in dashboard view for initial selection
  let defaultId = getQueryValue("categoryId");

  return (
    <Grid container item sm={true} style={{ marginRight: "3rem" }}>
      <Grid item xs={12} className={classes.dropdownFilter}>
        <Select
          options={options}
          value={
            filterType === "categories"
              ? options.find((o) =>
                  selectedCategory
                    ? o.value == selectedCategory
                    : o.value == defaultId
                ) || options[0]
              : options.find((o) => o.value === selectedNotificationType) ||
                options[0]
          }
          onChange={(o) =>
            filterType === "categories"
              ? setSelectedCategory(o.value)
              : setSelectedNotificationType(o.value)
          }
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(SearchFilter);
