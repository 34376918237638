import React from "react";
import { Col, Row } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import CustomTag from "./CustomTag";

const BulkTagsForm = ({
  filledTags,
  setFilledTags,
  selected,
  suggestedTags = [],
  actionType = "add",
  taggedItemDescription = "",
}) => {
  return (
    <Row className="pb-5">
      <form>
        <p className="pb-4 fs-4">
          Please enter the custom tags you would like to{" "}
          {actionType === "remove" ? "remove from" : "add to"} the selected{" "}
          {taggedItemDescription}
          {selected?.length > 1 ? "s" : ""} using the field below.
        </p>
        {suggestedTags?.filter((tag) => !filledTags.includes(tag) && tag)
          ?.length ? (
          <p
            className="d-flex justify-content-center align-items-center gap-3 flex-wrap px-5"
            style={{ fontSize: "15px" }}
          >
            Suggested Tags:{" "}
            {suggestedTags
              ?.filter((tag) => !filledTags?.includes(tag) && tag)
              ?.map((tag) => (
                <CustomTag
                  onClick={() => setFilledTags([...filledTags, tag])}
                  tag={tag}
                  key={tag}
                  actionType={"add"}
                  showActionIcon={true}
                />
              ))}
          </p>
        ) : (
          <></>
        )}

        <Row className="pt-3 d-flex justify-content-center">
          <Col xs={12} md={6} className="px-5">
            <TagsInput
              value={filledTags ?? []}
              onChange={setFilledTags}
              addOnBlur={true}
              tagProps={{
                className: "react-tagsinput-tag tag-fill tag-azure",
              }}
              inputProps={{
                placeholder: `Add ${taggedItemDescription} tags...`,
              }}
            />
          </Col>
        </Row>
      </form>
    </Row>
  );
};

export default BulkTagsForm;
