import { useMutation, useQuery } from "react-query";
import api from "utils/api";
import getURLPrefix from "utils/getUrlPrefix";
import setAuthToken from "utils/setAuthToken";
import Cryptr from "cryptr";
import { CLIENT_SIDE_ENCRYPTION_KEY } from "utils/gvSecrets";
import { useStore } from "react-redux";
import { logoutUser, setTokens } from "redux/actions/authActions";

const API_URL = getURLPrefix();

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

let queue = [];
let isRefreshing = false;

class AuthException {
  constructor(error, message) {
    this.message = message;
    this.sourceError = error;
    this.name = "AuthException";
  }
}

const refreshTokenQueue = () => {
  return new Promise((resolve, reject) => {
    if (isRefreshing) {
      queue.push({ resolve, reject });
    } else {
      isRefreshing = true;
      return refreshAuthToken()
        .then(() => {
          isRefreshing = false;
          resolve();
          for (const queued of queue) {
            queued.resolve();
          }
          queue = [];
        })
        .catch((error) => {
          isRefreshing = false;
          reject(error);
          for (const queued of queue) {
            queued.reject();
          }
          queue = [];
        });
    }
  });
};

const getAuthToken = () => {
  const tokens = JSON.parse(localStorage.Tokens);
  return tokens;
};

const setToken = (token) => {
  localStorage.setItem("Tokens", JSON.stringify(token));
  setAuthToken(token.access_token);
};

const clearToken = () => {
  localStorage.removeItem("Tokens");
  setAuthToken(false);
};

const refreshAuthToken = async () => {
  const oAuthTokens = getAuthToken();
  const cryptr = new Cryptr(CLIENT_SIDE_ENCRYPTION_KEY);
  const refresh_token = cryptr.decrypt(oAuthTokens.refresh_token);
  console.log("refreshing token");
  let authData = {
    client_id: "kAMsAH8EhqYRjZjCZ1DVgMIPWo8yy8yMrXmwC1er",
    client_secret:
      "iPvD1CSP7NcZPaOpGb43I0vBojVWuE1yKWd5YfPJJ2I8glZvo0DKvHKumeStWmw86sqdeRGnIh6xEV8k0lybcw4DywDbw4tc1d8O4srHJXL7k5nQyHxWNGym3xxMf98e",
    grant_type: "refresh_token",
    refresh_token,
  };
  const authResp = await fetch(`${API_URL}/auth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(authData),
  });
  if (authResp.ok) {
    const oAuthTokens = await authResp.json();
    setToken({
      access_token: oAuthTokens.access_token,
      refresh_token: cryptr.encrypt(oAuthTokens.refresh_token),
    });
  } else {
    clearToken();
    throw new AuthException(authResp, "failed to refresh token");
  }
};

const apiGet = async (endpoint, params, store, retries = 0) => {
  try {
    const resp = await api.get(endpoint, params);
    return resp;
  } catch (error) {
    if (retries < 3) {
      if (error?.response?.status === 401) {
        try {
          await refreshTokenQueue();
          // Update Redux with new tokens for legacy code
          const tokens = getAuthToken();
          store.dispatch(setTokens(tokens));
        } catch (error) {
          if (error?.name === "AuthException") {
            // logout user if refresh token fails
            store.dispatch(logoutUser());
          }
          throw error;
        }
      } else {
        await sleep(500);
      }
      return await apiGet(endpoint, params, store, retries + 1);
    }
    throw error;
  }
};

const useFetch = (queryKey, endpoint, params, options = null) => {
  const store = useStore();
  return useQuery(queryKey, () => apiGet(endpoint, params, store), options);
};

const useCreate = (endpoint, params, options = null) =>
  useMutation((body) => api.post(endpoint, body, params), options);

const useUpdate = (endpoint, params, options = null) =>
  useMutation((body) => api.patch(endpoint, body, params), options);

export { useFetch, useCreate, useUpdate, apiGet, refreshAuthToken };
