const checkTierAccess = (tier, application, org_status) => {
  tier = tier.split("__")?.[0];

  let eligibleOrgStatus = ["active", "trial_active", "demo_org"].includes(
    org_status
  );

  if (application === "advertising") {
    return (
      [
        "CORE_ENTREPRENEUR",
        "CORE_GROWTH",
        "CORE_PROFESSIONAL",
        "CORE_CUSTOM",
      ].includes(tier) && eligibleOrgStatus
    );
  }

  if (application === "content") {
    return (
      [
        "CORE_ENTREPRENEUR",
        "CORE_GROWTH",
        "CORE_PROFESSIONAL",
        "CORE_CUSTOM",
      ].includes(tier) && eligibleOrgStatus
    );
  }

  if (application === "pricing") {
    return (
      ["CORE_PROFESSIONAL", "CORE_CUSTOM"].includes(tier) && eligibleOrgStatus
    );
  }

  if (application === "promotions") {
    return (
      ["CORE_PROFESSIONAL", "CORE_CUSTOM"].includes(tier) && eligibleOrgStatus
    );
  }
};

export default checkTierAccess;
