import React from "react";
import moment from "moment";
import Status from "components/core/blocks/Status";

const PLAN_STATUSES = {
  planning: {
    label: "Planning",
    color: "#a78dc2",
    subLabel: ({ due }) => `due ${moment(due).fromNow()}`,
  },
  planned: {
    label: "Planned",
    color: "#a78dc2",
    subLabel: ({ start }) => `starts ${moment(start).fromNow()}`,
  },
  executing: {
    label: "Active",
    color: "#0fc398",
    subLabel: ({ end }) => `ends ${moment(end).fromNow()}`,
  },
  finished: {
    label: "Complete",
    color: "#ff7bac",
    subLabel: ({ end }) => `finished on ${moment(end).format("MMM Do")}`,
  },
  // Product Launch States
  preparing: {
    label: "Preparing",
    color: "#a78dc2",
    subLabel: (dates) => {
      let difference = moment(dates.start)
        .endOf("day")
        .diff(moment().startOf("day"), "days");

      return `${difference} day${difference > 1 ? "s" : ""} until launch`;
    },
  },
  introduced: {
    label: "Introduced",
    color: "#0fc398",
    subLabel: (dates) =>
      `${moment(dates.end).diff(moment(), "days")} days remaining`,
  },
  launched: {
    label: "Launched",
    color: "#ff7bac",
    subLabel: (dates) => `Launch complete`,
  },
};

const PlanStatus = ({ status, due, start, end, ...props }) => {
  const { label, color, subLabel } =
    PLAN_STATUSES?.[status] ?? PLAN_STATUSES.planning;
  return (
    <Status
      color={color}
      label={label}
      subLabel={subLabel({ due, start, end })}
      {...props}
    />
  );
};

export default PlanStatus;
