import { useMemo, useEffect } from "react";
import { useFetch } from "./api";
import { useQueryClient } from "react-query";

const useIsSPAPIConnected = (user, channel) => {
  const queryClient = useQueryClient();

  const { data: onboardingData } = useFetch(
    ["onboarding", user.organization_id],
    "/api/onboardingsummary/",
    {},
    {
      select: (d) => d.data,
      enabled: !!user.organization_id && channel !== "walmart",
    }
  );

  const isSPAPIConnected = useMemo(() => {
    return (
      onboardingData &&
      (onboardingData?.amazon_mws_seller_id ||
        onboardingData?.eu_amazon_mws_seller_id ||
        onboardingData?.fe_amazon_mws_seller_id)
    );
  }, [onboardingData]);

  useEffect(() => {
    if (isSPAPIConnected) return;
    const handle = setInterval(() => {
      void queryClient.invalidateQueries("onboarding");
    }, 1000);
    return () => clearInterval(handle);
  }, [isSPAPIConnected, queryClient]);

  return isSPAPIConnected;
};

export default useIsSPAPIConnected;
