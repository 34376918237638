import React, { useState } from "react";
import { DropdownButton, Form, InputGroup } from "react-bootstrap";

// Track cases where percent values are in decimal format (i.e. returned from API as .75 to represent 75)
const PERCENT_METRICS_IN_DECIMAL_FORMAT = [
  "acos",
  "acos_same_sku",
  "total_acos",
  "margin",
  "ctr",
  "conversion_rate",
  "org_conv_rate",
  "margin_wo_adspend",
  "acos_calculated",
  "ctr_calculated",
  "cr_calculated",
];

const FilterColumnSelector = ({
  columnOptions = [],
  customFilters = [],
  setCustomFilters,
  additionalFilterOptions = [],
  excludedFilterColumns = [],
}) => {
  const [search, setSearch] = useState("");

  return (
    <DropdownButton
      renderMenuOnMount={true}
      flip={false}
      onMouseLeave={() => setSearch("")}
      className="table-filter-widget dropdown fs-standard noarrow dropdown mx-0"
      title={
        <div className="dropdown-title">
          <i className="fa fa-plus pe-3 text-muted" />
          Add filter
        </div>
      }
    >
      <div className="px-3 py-2">
        <InputGroup className="mb-3 text-start">
          <InputGroup.Text
            style={{
              borderTopLeftRadius: "45px",
              borderBottomLeftRadius: "45px",
            }}
          >
            <i className="fa fa-search" />
          </InputGroup.Text>
          <Form.Control
            placeholder={"Filter By..."}
            aria-label="Search Column"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            style={{
              borderTopRightRadius: "45px",
              borderBottomRightRadius: "45px",
              borderLeft: "1px solid #E3E3E3",
              borderColor: "#E3E3E3",
              textAlign: "start",
            }}
          />
        </InputGroup>
      </div>
      <div
        style={{
          width: "300px",
          maxHeight: "500px",
          overflowY: "auto",
        }}
      >
        {[
          ...additionalFilterOptions,
          ...columnOptions.filter(
            (c) => ![...excludedFilterColumns, "isExpanded"]?.includes(c.id)
          ),
        ]
          ?.filter(
            (option) =>
              option.format &&
              !customFilters
                .map((selectedFilter) => selectedFilter.id)
                ?.includes(option.id)
          )
          ?.filter((option) => {
            if (search) {
              return (
                `${option.Header} ${option.id}`
                  .toLowerCase()
                  ?.indexOf(search.toLowerCase()) > -1
              );
            }

            return true;
          })
          ?.map((option) => (
            <div
              key={option.id}
              className="d-flex flex-column py-2 column-option"
              onClick={(e) => {
                e.preventDefault();
                setCustomFilters([
                  ...customFilters,
                  {
                    id: option.id,
                    label: option.Header ?? option.label,
                    format: option.filterType ?? option.format,
                    operator: option.override ? "=" : ">",
                    value: option.defaultValue ?? null,
                    overrideAccessorId: option.accessorId ?? option.id,
                    onValue: option.onValue,
                    offValue: option.offValue,
                    selectOptions: option.selectOptions ?? [],
                    override: option.override ?? false,

                    filterFunction: option.filterFunction ?? null,
                    percentAsDecimal:
                      option.format === "percent" &&
                      PERCENT_METRICS_IN_DECIMAL_FORMAT.includes(option.id),
                  },
                ]);
              }}
            >
              <span className="px-4">{option.Header ?? option.label}</span>
            </div>
          ))}
      </div>
    </DropdownButton>
  );
};

export default FilterColumnSelector;
