import React from "react";
import { DropdownButton } from "react-bootstrap";

const dimensionOptions = [
  {
    id: 0,
    label: "All",
    value: null,
  },
  {
    id: 1,
    label: "400px or more",
    value: 400,
  },
  {
    id: 2,
    label: "1500px or more",
    value: 1500,
  },
  {
    id: 3,
    label: "3000px or more",
    value: 3000,
  },
];

const DimensionFilter = ({ assetDimensionFilter, setAssetDimensionFilter }) => {
  return (
    <DropdownButton
      id="DimensionSelector"
      title={
        <span>
          {assetDimensionFilter.width
            ? `${assetDimensionFilter.width}+`
            : "All"}
          {assetDimensionFilter.height ? (
            <> &#215; {assetDimensionFilter.height}+</>
          ) : !assetDimensionFilter.width ? (
            ""
          ) : (
            <> &#215; All</>
          )}
        </span>
      }
      bsPrefix="col-selector"
    >
      <div key={"all-dimensions"} role="menuitem" className="py-1">
        <label
          style={{
            marginLeft: "1rem",
            marginTop: "0.5rem",
            fontWeight: "normal",
            display: "flex",
            whiteSpace: "nowrap",
          }}
        >
          <input
            type="checkbox"
            style={{ height: "unset", marginRight: "1rem" }}
            value={null}
            onChange={(e) => {
              e.stopPropagation();
              setAssetDimensionFilter((prev) => ({
                width: null,
                height: null,
              }));
            }}
            checked={
              !assetDimensionFilter?.width && !assetDimensionFilter?.height
            }
          />
          All Dimensions
        </label>
      </div>
      <div>Width</div>
      {dimensionOptions.map(({ id, label, checked, value }) => (
        <div key={id} role="menuitem" className="py-1">
          <label
            style={{
              marginLeft: "1rem",
              marginTop: "0.5rem",
              fontWeight: "normal",
              display: "flex",
              whiteSpace: "nowrap",
            }}
          >
            <input
              type="checkbox"
              style={{ height: "unset", marginRight: "1rem" }}
              value={value}
              onChange={(e) => {
                e.stopPropagation();

                const { value } = e.target;
                if (value === assetDimensionFilter.width) {
                  setAssetDimensionFilter((prev) => ({
                    ...prev,
                    width: null,
                  }));
                } else {
                  setAssetDimensionFilter((prev) => ({
                    ...prev,
                    width: value,
                  }));
                }
              }}
              checked={
                value?.toString() === assetDimensionFilter.width?.toString()
              }
            />
            {label}
          </label>
        </div>
      ))}
      <div>Height</div>
      {dimensionOptions.map(({ id, label, checked, value }) => (
        <div key={id} role="menuitem" className="py-1">
          <label
            style={{
              marginLeft: "1rem",
              marginTop: "0.5rem",
              fontWeight: "normal",
              display: "flex",
              whiteSpace: "nowrap",
            }}
          >
            <input
              type="checkbox"
              style={{ height: "unset", marginRight: "1rem" }}
              value={value}
              onChange={(e) => {
                e.stopPropagation();
                const { value } = e.target;

                if (value === assetDimensionFilter.height) {
                  setAssetDimensionFilter((prev) => ({
                    ...prev,
                    height: null,
                  }));
                } else {
                  setAssetDimensionFilter((prev) => ({
                    ...prev,
                    height: value,
                  }));
                }
              }}
              checked={
                value?.toString() === assetDimensionFilter.height?.toString()
              }
            />
            {label}
          </label>
        </div>
      ))}
    </DropdownButton>
  );
};

export default DimensionFilter;
