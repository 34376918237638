import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "hooks/api";
import FormGroupTile from "../basic/FormGroupTile";

const CategorySelector = ({ selectedCategory, setSelectedCategory }) => {
  const {
    marketPlace,
    channel: { currentChannel: channel },
  } = useSelector((state) => state);

  const { data: categoryData } = useFetch(
    ["categories", marketPlace.marketPlace, channel],
    "/api/gvads/categories",
    {
      marketplace: marketPlace.marketPlace,
      channel: channel,
    },
    {
      select: (res) => res.data,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      default: [],
    }
  );

  const catOptions = useMemo(() => {
    return (
      categoryData?.map((d) => ({
        value: d.id,
        label: `${d.leaf_category_name} (Media Plans: ${d.ad_count})`,
      })) ?? []
    );
  }, [categoryData]);

  const selectedOption = useMemo(() => {
    return catOptions?.find((cat) => cat.value === selectedCategory);
  }, [catOptions, selectedCategory]);

  return (
    <FormGroupTile
      type="select"
      multi={false}
      label={"Categories"}
      options={catOptions}
      defaultValue={selectedOption}
      handleChildFormElement={(_, v) => {
        setSelectedCategory(v?.value ?? null)
      }}
      isClearable={true}
    />
  );
};

export default CategorySelector;
