import { useSelector } from "react-redux";
import { useFetch } from "./api";
import ThemeLine from "components/custom/merchandise/ThemeLine";
import React from "react";

const useCategories = (categoryId, dashboard = "advertising") => {
  const state = useSelector((state) => state);

  const {
    marketPlace,
    channel: { currentChannel: channel },
  } = state;

  const { data: categoryData } = useFetch(
    ["categories", marketPlace.marketPlace, channel],
    "/api/gvads/categories",
    {
      marketplace: marketPlace.marketPlace,
      channel: channel,
    },
    {
      select: (res) => res.data,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      default: [],
    }
  );

  let currentCategoryData = categoryData?.find(
    (category) => category?.id === parseInt(categoryId)
  );

  let dashboardRoute;

  if (dashboard === "advertising") {
    dashboardRoute = "advertising/dashboard/";
  }

  if (dashboard === "pricing") {
    dashboardRoute = "_pricing/";
  }

  if (dashboard === "plreport") {
    dashboardRoute = "pl/report/";
  }

  if (dashboard === "content") {
    dashboardRoute = "content/quality/";
  }

  if (dashboard === "merchandising") {
    dashboardRoute = "merchandising/";
  }
  return {
    currentCategoryDetails:
      {
        ...currentCategoryData,
        name: currentCategoryData?.leaf_category_name,
        href: `/user/${dashboardRoute}category/${currentCategoryData?.id}`,
      } ?? {},
    categories: categoryData?.map((category) => {
      return {
        ...category,
        name: category.leaf_category_name,
        altDisplayMarkup: (
          <div>
            <span
              style={{
                maxWidth: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight:
                  category.leaf_category_name ===
                  currentCategoryData?.leaf_category_name
                    ? 600
                    : "inherit",
                fontSize: "1.5rem",
              }}
            >
              {category.leaf_category_name}
            </span>
            <ThemeLine
              additionalStyles={{
                fontSize: "10px",
                fontWeight:
                  category.leaf_category_name ===
                  currentCategoryData?.leaf_category_name
                    ? 500
                    : "inherit",
                color: "#2e0054",
              }}
            >
              {category.ad_count} Media Plan{category.ad_count === 1 ? "" : "s"}
              , {category.product_count} Product
              {category.product_count === 1 ? "" : "s"}
            </ThemeLine>
          </div>
        ),
        href: `/user/${dashboardRoute}category/${category.id}`,
      };
    }),
  };
};

export default useCategories;
