import { Box, Button, Grid, IconButton, withStyles } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React, { Component } from "react";
import "assets/css/authentication_pages.css";
import { HOMEPAGE } from "utils/redirectConstants";
import HubspotCalendarModal from "components/core/blocks/HubspotCalendarModal";

const useStyles = (theme) => ({
  innerPanel: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
  },
  button: {
    width: "50%",
    fontSize: "1.25rem",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  heading: {
    fontSize: theme.typography.h2.fontSize,
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.h1.fontSize,
    },
  },
  darkHeading: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.grey[800],
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.8rem",
    },
  },
  subheading: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.h2.fontSize,
    },
  },
  bullets: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.only("md")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  checks: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.grey[800],
    wrap: "no-wrap",
    [theme.breakpoints.only("md")]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  pinkCheck: {
    color: "#c43066 !important",
  },
  footertext: {
    marginTop: "5%",
    fontSize: theme.typography.h5.fontSize,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  altFooterText: {
    marginTop: "5%",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.h4.fontSize,
      marginTop: "0",
    },
  },
  input: {
    fontSize: theme.typography.h3.fontSize,
  },
  inputlabel: {
    fontSize: theme.typography.h4.fontSize,
  },
  logo: {
    marginLeft: "-5%",
    maxWidth: "50%",
  },
  learnmore: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  logoDivider: {
    width: "50%",
    border: `1px solid ${theme.palette.grey[300]} `,
    background: "#ddd",
    margin: "2rem auto",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginTop: "40px",
    },
  },
  logoContainerText: {
    textAlign: "center",
    fontSize: theme.typography.h4.fontSize,
    marginTop: "2rem",
  },
  scrollarrow: {
    "& svg": {
      fontSize: 36,
      "& path": {
        animation: "$pulse 1.5s infinite",
      },
    },
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.9)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
    },
    "100%": {
      transform: "scale(0.9)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
  },
});

class TrellisPanelSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHubspotModal: false,
    };
  }

  render() {
    const { classes, handleScrollClick } = this.props;
    let messaging;
    if (this.props.alt === 4) {
      // Set messaging content
      messaging = (
        <Box>
          <Box
            className={classes.darkHeading}
            //color="primary.main"
            fontWeight="fontWeightBold"
            textAlign={{ xs: "center", md: "left" }}
            mt={{ xs: 0, md: 3 }}
            whiteSpace="nowrap"
          >
            Steps away from your Trellis experience:
          </Box>
          <Box
            className={classes.checks}
            fontWeight="fontWeightMedium"
            fontStyle="italic"
            mt={4}
            lineHeight={1.7}
            height="100%"
            textAlign={{ xs: "center", md: "left" }}
          >
            <ul style={{ listStyleType: "none", whiteSpace: "nowrap" }}>
              <li>1. Complete your company information.</li>
              <li>2. Sign on with your Amazon account.</li>
              <li>3. Connect your Amazon Advertising Account.</li>
              <li>4. Pick your plan from Billing.</li>
            </ul>

            <hr className={classes.logoDivider} />
            <p className={classes.logoContainerText}>Trusted By </p>
            <Box
              width="100%"
              alignSelf="center"
              className="logo_container"
              display="flex"
              flexWrap="nowrap"
              justifyContent={{ xs: "center", md: "space-around" }}
              alignItems="center"
              flexDirection="row"
              mt={{ sm: 3, lg: 3 }}
              mb={{ sm: 3, lg: 6 }}
            >
              <div className="logo"></div>
              <div className="logo"></div>
              <div className="logo"></div>
            </Box>
          </Box>
        </Box>
      );
    } else if (this.props.alt === 0) {
      messaging = (
        <>
          <Box
            className={classes.heading}
            color="primary.contrastText"
            fontWeight="fontWeightBold"
            pt={12}
          >
            {" "}
            Try Trellis for free
          </Box>
          <Box
            className={classes.subheading}
            color="primary.contrastText"
            fontWeight="fontWeightBold"
            mt={2}
          >
            Your 14-day free trial includes
          </Box>
          <Box
            className={classes.bullets}
            color="primary.contrastText"
            fontWeight="fontWeightMedium"
            fontStyle="italic"
            mt={1}
            lineHeight={1.7}
          >
            <ul>
              <li>Instant access to the Trellis platform</li>
              <li>
                Step-by-step instructions for onboarding your Amazon account
              </li>
              <li>Free campaign audit tool for existing ad campaigns</li>
              <li>Create & launch an Amazon campaign</li>
            </ul>
          </Box>
          <Box
            className={classes.subheading}
            color="primary.contrastText"
            fontWeight="fontWeightBold"
            mt={3}
          >
            No risk, no commitment
          </Box>
          <Box
            className={classes.bullets}
            color="primary.contrastText"
            fontWeight="fontWeightMedium"
            fontStyle="italic"
            mt={1}
            lineHeight={1.7}
          >
            <ul>
              <li>No credit card required trial</li>
              <li>Input your budget & goals - we do the rest</li>
              <li>Cancel anytime if you’re not satisfied</li>
            </ul>
          </Box>
        </>
      );
    } else {
      messaging = (
        <>
          <Box
            className={classes.heading}
            color="secondary.light"
            fontWeight="fontWeightBold"
            mt={20}
          >
            {" "}
            {this.props.alt === 2
              ? "Focus your time on growing your business"
              : "Start Automating Your Amazon PPC Campaigns"}
          </Box>
          {this.props.alt === 1 || this.props.alt === 2 ? (
            <>
              <Box
                className={classes.subheading}
                color="primary.contrastText"
                fontWeight="fontWeightBold"
                mt={5}
              >
                Sign-up today and get access to:
              </Box>
              <Box
                className={classes.bullets}
                color="primary.contrastText"
                fontWeight="fontWeightMedium"
                fontStyle="italic"
                mt={3}
                pl={6}
                lineHeight={1.7}
              >
                <ul>
                  <li>Step-by-step onboarding</li>
                  <li>Launch an ad in minutes</li>
                  <li>
                    Track performance with a simple and intuitive interface
                  </li>
                </ul>
              </Box>
            </>
          ) : (
            <>
              <Box
                className={classes.bullets}
                color="primary.contrastText"
                fontWeight="fontWeightMedium"
                fontStyle="italic"
                mt={8}
                pl={6}
                lineHeight={1.7}
                width="95%"
              >
                "Amazon advertising takes a full-time person to figure it all
                out. Trellis took the complexity off our hands entirely."
              </Box>
              <Box
                className={classes.bullets}
                color="secondary.main"
                fontWeight="fontWeightMedium"
                mt={3}
                mb={5}
                style={{ textAlign: "right" }}
              >
                ~ Nancy Blair, Owner, Moonjar
              </Box>
            </>
          )}
        </>
      );
    }

    return (
      <Grid container className={classes.rightPanel} wrap="nowrap">
        {this.props.alt === 4 ? (
          <>
            <Grid item xs={1} md={5} lg={4}></Grid>
            <Grid item xs={9} sm={10} md={12}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                wrap="nowrap"
                className={classes.innerPanel}
              >
                {messaging}

                <Box
                  className={`${classes.altFootertext} fs-standard`}
                  margin="none"
                  color="primary"
                  fontWeight="fontWeightBold"
                  textAlign="center"
                >
                  Not ready to get started? <br />
                  <Button
                    className={classes.learnmore}
                    color="secondary"
                    onClick={(e) => this.setState({ showHubspotModal: true })}
                  >
                    Book a Demo
                  </Button>
                </Box>
                <Box display={{ xs: "block", md: "none" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    style={{ width: "100%" }}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        style={{ minWidth: 0 }}
                      >
                        <Grid item>
                          <Box color="primary.contrastText" mt={4}>
                            Continue
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box>
                            <IconButton
                              className={classes.scrollarrow}
                              color="secondary"
                              onClick={handleScrollClick}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3} sm={2} />
            <Grid item xs={9} sm={9}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                wrap="nowrap"
                className={classes.innerPanel}
              >
                {messaging}
                <Box
                  className={`${classes.footertext} fs-standard`}
                  color="primary.contrastText"
                  fontWeight="fontWeightBold"
                  ml={2}
                >
                  Not ready to get started?
                  <Button
                    className={classes.learnmore}
                    color="secondary"
                    href={HOMEPAGE}
                    target="_blank"
                  >
                    Learn More
                  </Button>
                </Box>
                <Box display={{ xs: "block", md: "none" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    style={{ width: "100%" }}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        style={{ minWidth: 0 }}
                      >
                        <Grid item>
                          <Box color="primary.contrastText" mt={4}>
                            Continue
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box>
                            <IconButton
                              className={classes.scrollarrow}
                              color="secondary"
                              onClick={handleScrollClick}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
        <HubspotCalendarModal
          open={this.state.showHubspotModal}
          onClose={(e) => this.setState({ showHubspotModal: false })}
        />
      </Grid>
    );
  }
}

export default withStyles(useStyles)(TrellisPanelSignUp);
