import { useFetch } from "hooks/api";
import useColumnSelector from "hooks/useColumnSelector";
import React, { useMemo, useState } from "react";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import TrComponent from "components/custom/merchandise/TrComponent";
import { Card, Col, Row } from "react-bootstrap";
import { PrimaryButton } from "components/core/basic/Button";
import moment from "moment";
import Loading from "components/core/blocks/Loading";

const ExpandedContent = ({ row, onApply }) => {
  const revision = row.original?.revision ?? {};
  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <Card className="fs-5 mb-0 p-5">
        <Card.Body>
          <Row>
            <Col xs={12}>
              <h5>{revision?.title}</h5>
              <ul>
                {revision?.feature_bullets?.map((bullet, i) => (
                  <li key={i}>{bullet}</li>
                ))}
              </ul>
              <h6 className="mt-4">Keywords</h6>
              <div>{revision?.generic_keywords}</div>
              <h6 className="mt-4">Description</h6>
              <div
                dangerouslySetInnerHTML={{
                  __html: revision?.description ?? "",
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col style={{ textAlign: "right" }}>
              <PrimaryButton onClick={() => onApply(revision)} nowrap>
                Re-Apply
              </PrimaryButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

const makeExpanderCell = (accessor, setExpanded) => {
  return ({ row, value }) => {
    const id = accessor(row?._original);
    return (
      <ExpanderCell
        row={row}
        value={id}
        isExpanded={value}
        setIsExpanded={setExpanded}
      />
    );
  };
};

const TemplateHistory = ({ templateId, onApply }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [expanded, setExpanded] = useState({});

  const { data, isLoading } = useFetch(
    ["published_templates", templateId],
    "/merchandise/published_templates",
    {
      content_template: templateId,
      ordering: "-created_on",
    },
    {
      enabled: !!templateId,
      select: ({ data }) => data.results,
    }
  );
  const tableData = useMemo(() => {
    if (!data) return [];
    return data.map((row) => ({
      ...row,
      isExpanded: row.id === expanded ? row.id : null,
      created_on: moment(row.created_on),
      created_by_name: `${row.created_by?.first_name} ${row.created_by?.last_name}`,
    }));
  }, [data, expanded]);
  const [columns, columnSelectorProps] = useColumnSelector(() => {
    return [
      {
        id: "expander",
        Header: "",
        accessor: "isExpanded",
        width: 50,
        Cell: makeExpanderCell((row) => row.id, setExpanded),
      },
      {
        id: "created_on",
        Header: "Created",
        accessor: "created_on",
        Cell: ({ value }) => value?.format("MMM D 'YY h:mm a") ?? "-",
      },
      { id: "created_by", Header: "Created By", accessor: "created_by_name" },
      { id: "status", Header: "Status", accessor: "verified_status" },
      { id: "placeholder", Header: "", accessor: null },
    ];
  }, []);

  if (isLoading) return <Loading height={"300px"} fullPage={false} />;
  return (
    <ReactTable
      data={tableData}
      defaultPageSize={10}
      minRows={5}
      className="publish-history-table"
      TrComponent={TrComponent}
      getTrProps={(table, row) => {
        let expandedChildren = <ExpandedContent row={row} onApply={onApply} />;
        return { row, expandedChildren };
      }}
      PaginationComponent={ReactTablePagination}
      getPaginationProps={() => ({
        updateSearch: setSearchFilter,
        showFilters: showFilters,
        setShowFilters: setShowFilters,
        clearFilters: () => {
          setSearchFilter("");
        },
        hideFilters: false,
        clearSearchTermFilters: () => setSearchFilter(""),
        searchTerm: searchFilter,
        table: "template-history",
        style: { marginBottom: "2rem" },
        carouselLayout: false,
        disableSearchFocus: true,
      })}
      filterable={showFilters}
      columns={columns}
      NoDataComponent={() => null}
      showPaginationTop
      showPaginationBottom={false}
      columnSelectorProps={columnSelectorProps}
      defaultSorted={[
        {
          id: "created_on",
          desc: true,
        },
      ]}
    />
  );
};

export default TemplateHistory;
