import React, { useState, useEffect } from "react";
import { getHashValue } from "utils/getQueryValue";
import { Row, Col, Nav, Container as Grid } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/core/blocks/Loading";
import { getURLPrefix } from "utils/getUrlPrefix";

import axios from "axios";
import TermDensityTable from "./components/TermDensityTable";
import BrandsTable from "./components/BrandsTable";
import SponsoredBrandTable from "./components/SponsoredBrandTable";
import PriceChart from "./components/PriceChart";
import StarRatingChart from "./components/StarRatingChart";
import BSRChart from "./components/BSRChart";
import NumRatingsChart from "./components/NumRatingsChart";
import ProductBox from "./components/ProductBox";
import useDebounce from "hooks/useDebounce";
import SearchBox from "./components/SearchBox";

import { selectModules } from "redux/selectors/modules";

let URL_PREFIX = getURLPrefix();

const Explore = ({ marketPlace, user, channel, modules }) => {
  let hashValue = getHashValue("q", null);

  const [searchTerm, setSearchTerm] = useState(
    hashValue
      ? hashValue.toLocaleLowerCase().replace(new RegExp("%20", "g"), " ")
      : null
  );
  const [isLoading, setIsLoading] = useState(hashValue !== null ? true : false);
  const [data, setData] = useState(null);
  const [orgASINs, setOrgASINs] = useState([]);
  const [popularTerms, setPopluarTerms] = useState([]);
  const [popularBrands, setPopularBrands] = useState([]);
  const [sponsoredBrands, setSponsoredBrands] = useState([]);
  const [sponsoredVideos, setSponsoredVideos] = useState([]);
  const [priceBuckets, setPriceBuckets] = useState(null);
  const [ratingsBucket, setRatingsBucket] = useState(null);
  const [numRatingsBucket, setNumRatingsBucket] = useState(null);
  const [bsrBucket, setBSRBucket] = useState(null);
  const [competitiveASINs, setCompetitiveASINs] = useState([]);
  const [rankInfo, setRankInfo] = useState({});
  const [primaryTab, setPrimaryTab] = useState("term_density");
  const [secondaryTab, setSecondaryTab] = useState("prices");
  const [targetedASINs, setTargetedASINs] = useState({});
  const [isError, setError] = useState(false);

  let searchingText = "Building Search Result";

  const debounceSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (debounceSearchTerm && isLoading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchTerm, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      fetchTargetedASINs();
      fetchCompetitiveASINs();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const interval = setInterval(() => changeSearchingText(), 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const changeSearchingText = () => {
    if (isLoading) {
      if (searchingText === "Building Search Result") {
        searchingText = "Still a few more seconds";
      } else if (searchingText === "Still a few more seconds") {
        searchingText = "Almost there";
      } else if (searchingText === "Almost there") {
        searchingText = "hmm... we're still working on it.";
      }
    }
  };

  const fetchData = async () => {
    if (!searchTerm || searchTerm.lnegth === 0) {
      return;
    }
    setIsLoading(true);
    searchingText = "Building Search Result";
    try {
      const result = await axios(
        `${URL_PREFIX}/api/explorer/search?q=${searchTerm}&marketplace=${marketPlace.marketPlace}`
      );
      setData(result.data.results);
      setOrgASINs(result.data.org_asins);
      setPopluarTerms(result.data.popular_keywords);
      setPopularBrands(result.data.brands);
      setSponsoredBrands(result.data.sb);
      setSponsoredVideos(result.data.sv);
      setPriceBuckets(result.data.price_buckets);
      setRatingsBucket(result.data.rating_buckets);
      setNumRatingsBucket(result.data.total_rating_buckets);
      setBSRBucket(result.data.rank_buckets);
      setRankInfo(result.data.rank_info);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  };

  const fetchTargetedASINs = async () => {
    const result = await axios(
      `${URL_PREFIX}/api/explorer/target_asins?marketplace=${marketPlace.marketPlace}`
    );
    setTargetedASINs(result.data.target_asin);
  };

  const fetchCompetitiveASINs = async () => {
    const result = await axios(`${URL_PREFIX}/api/competitors/`);
    const casins = [];
    result.data.results.forEach((product) => {
      casins.push(product.product_ref);
    });

    setCompetitiveASINs(casins);
  };

  const searchProduct = (term) => {
    setSearchTerm(term);
    setIsLoading(true);
  };

  if (["USA", "UK", "CA"].indexOf(marketPlace.marketPlace) === -1) {
    return (
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid>
          <Row>
            <Col xs={12} className="pt-5">
              <div className="text-center fs-3">
                <p>
                  The keyword explorer is available for the following
                  marketplaces: USA, UK, CA.
                </p>
                <p>
                  If you are trying to use the keyword explorer for another
                  marketplace, please contact us at{" "}
                  <a href="mailto:support@gotrellis.com" alt="Support Email">
                    support@gotrellis.com
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  if (isLoading && debounceSearchTerm) {
    return <Loading text={searchingText} />;
  }

  return (
    <div className="main-content" style={{ backgroundColor: "#FFF" }}>
      <Grid fluid>
        <SearchBox searchProduct={searchProduct} user={user} />

        {!isLoading && isError && (
          <Row>
            <Col xs={12} className="pt-5">
              <div className="text-center fs-3">
                An Error Occured While Performing Your Search Query
              </div>
            </Col>
          </Row>
        )}

        {!isLoading && !isError && data && (
          <Row>
            <Col xs={12} className="pt-5">
              <Row>
                <Col xs={6}>
                  <Nav
                    variant="underline"
                    onSelect={(selectedKey) => {
                      setPrimaryTab(selectedKey);
                    }}
                    activeKey={primaryTab}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="term_density"
                        className="fs-4"
                        style={{ color: "#000" }}
                      >
                        Term Density
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="popular_brands"
                        className="fs-4"
                        style={{ color: "#000" }}
                      >
                        Popular Brands
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="sponsor_brands"
                        className="fs-4"
                        style={{ color: "#000", width: "200px" }}
                      >
                        Sponsored Brands
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  {primaryTab === "term_density" && (
                    <TermDensityTable
                      popularTerms={popularTerms}
                      complete
                      marketPlace={marketPlace}
                    />
                  )}

                  {primaryTab === "popular_brands" && (
                    <BrandsTable
                      popularBrands={popularBrands}
                      complete
                      marketPlace={marketPlace}
                    />
                  )}

                  {primaryTab === "sponsor_brands" && (
                    <SponsoredBrandTable
                      sponsoredBrands={sponsoredBrands}
                      sponsoredVideos={sponsoredVideos}
                    />
                  )}
                </Col>
                <Col xs={6}>
                  <Nav
                    variant="underline"
                    onSelect={(selectedKey) => {
                      setSecondaryTab(selectedKey);
                    }}
                    activeKey={secondaryTab}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="prices"
                        className="fs-4"
                        style={{ color: "#000" }}
                      >
                        Prices
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="star_ratings"
                        className="fs-4"
                        style={{ color: "#000" }}
                      >
                        Star Ratings
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="num_ratings"
                        className="fs-4"
                        style={{ color: "#000" }}
                      >
                        # Ratings
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="bsr_rank"
                        className="fs-4"
                        style={{ color: "#000" }}
                      >
                        BSR Rank
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <StarRatingChart
                    ratingsBucket={ratingsBucket}
                    secondaryTab={secondaryTab}
                  />

                  <PriceChart
                    priceBuckets={priceBuckets}
                    marketPlace={marketPlace}
                    secondaryTab={secondaryTab}
                  />

                  <NumRatingsChart
                    numRatingsBucket={numRatingsBucket}
                    secondaryTab={secondaryTab}
                  />

                  <BSRChart bsrBucket={bsrBucket} secondaryTab={secondaryTab} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="pt-5">
                  <Row>
                    <Col xs={3}>
                      <h5>Search Results</h5>
                    </Col>
                    <Col
                      xs={9}
                      className="text-end"
                      style={{ fontSize: "12px" }}
                    ></Col>
                  </Row>
                </Col>
                {data.map((item, index) => {
                  return (
                    <Col xs={3} className="p-2" key={`productbox${index}`}>
                      <ProductBox
                        item={item}
                        marketPlace={marketPlace}
                        orgASINs={orgASINs}
                        rankInfo={rankInfo}
                        targetedASINs={targetedASINs}
                        competitiveASINs={competitiveASINs}
                        debounceSearchTerm={debounceSearchTerm}
                        setIsLoading={setIsLoading}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
};

Explore.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  user: state.user,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(Explore);
