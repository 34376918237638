import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ProgramsForm from "../../ProgramsForm";
import CreateFooterActions from "../../CreateFooterActions";
import EditFooterActions from "../../EditFooterActions";
import { PROGRAM_DEFAULTS } from "../../constants";

const CUSTOM_DEFAULT_VALUES = {
  rules: [
    {
      scope: {
        operator: "after",
        values: { days: "7" },
      },
      condition: "all",
      conditions: [
        {
          // eslint-disable-next-line no-template-curly-in-string
          option: JSON.stringify([">", "${units}"]),
          values: [10],
        },
      ],
      actions: [
        {
          function: "INCREASE_PRICE",
          operator: "adjust_fixed",
          values: [10],
        },
      ],
    },
  ],
};

const CustomCreateProgram = ({ onSuccess, onCancel, pricingPlanId }) => {
  const { handleSubmit, ...form } = useForm({
    shouldUnregister: false,
    defaultValues: PROGRAM_DEFAULTS["bidding"],
  });

  const onInvalid = (errors) => console.log("errors", errors);
  return (
    <Row>
      <Col xs={12}>
        <ProgramsForm {...form} />
        <CreateFooterActions
          form={form}
          handleSubmit={handleSubmit}
          makeBody={(name, start_date, end_date, spec) => ({
            name,
            start_date,
            end_date,
            template_type: "CUSTOM",
            ui_state: { template_type: "CUSTOM" },
            spec,
          })}
          onSuccess={onSuccess}
          onCancel={onCancel}
          onInvalid={onInvalid}
          pricingPlanId={pricingPlanId}
        />
      </Col>
    </Row>
  );
};

const CustomEditProgram = ({ id, defaultValues, onSuccess, onCancel }) => {
  const { handleSubmit, ...form } = useForm({
    shouldUnregister: false,
    defaultValues: { ...defaultValues, ...defaultValues.spec },
  });
  const onInvalid = (errors) => console.log("errors", errors);
  return (
    <Row>
      <Col xs={12}>
        <ProgramsForm {...form} />
        <EditFooterActions
          id={id}
          form={form}
          handleSubmit={handleSubmit}
          makeBody={(name, start_date, end_date, spec) => ({
            name,
            start_date,
            end_date,
            spec,
          })}
          onSuccess={onSuccess}
          onCancel={onCancel}
          onInvalid={onInvalid}
        />
      </Col>
    </Row>
  );
};

export { CUSTOM_DEFAULT_VALUES, ProgramsForm };
