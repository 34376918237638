import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import useSelectedMetrics from "components/custom/merchandise/hooks/useSelectedMetrics";
import MultiMetricChart from "components/custom/merchandise/MultiMetricChart";
import Loading from "components/core/blocks/Loading";
import { buildMetric } from "views/merchandise/metrics";
import DetailedMetric from "components/core/blocks/DetailedMetric";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import acosOrRoas from "utils/acosOrRoas";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils/formatNumber";
import ExternalProductLink from "../merchandise/ExternalProductLink";
import generateLink from "utils/generateLink";

const ProductExpander = ({
  row,
  channel,
  overrideFilters = {},
  highlightDate = null,
}) => {
  const { marketPlace } = useSelector((state) => state);
  const { preferences } = useMerchandisePreferences();
  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  let {
    id: productId,
    product_title: productTitle,
    asin,
    sku,
    annotation_type: annotationType,
  } = row._original?.product ?? {};

  const { product } = row._original ?? {};

  const { group, start, end, preStart, preEnd } = useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    group_by: "product_id",
    product_id: productId,
    channel: channel,
    ...overrideFilters,
  };

  const { data, isLoading } = useFetch(
    [
      "product_report",
      start,
      end,
      preStart,
      preEnd,
      group,
      productId,
      annotationType,
    ],
    `/api/data_report/product_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!productId,
    }
  );

  const { selectedMetrics, toggleMetric } = useSelectedMetrics(
    ["sales", "ad_spend", "page_views"],
    "productAnnotationExpander"
  );

  const metrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "price", "price_delta"),
      buildMetric(marketPlace, data, "bsr", "bsr_percent", {
        metric: {
          totalOverrideAccessor: "last_bsr_rank",
        },
      }),
      buildMetric(marketPlace, data, "page_views", "page_views_percent"),
      buildMetric(marketPlace, data, "sales", "sales_percent"),
      buildMetric(marketPlace, data, "units", "units_percent"),
      buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
      buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
      acosroas(
        buildMetric(marketPlace, data, "acos", "acos_delta"),
        buildMetric(marketPlace, data, "roas", "roas_delta")
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marketPlace, data]
  );

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  const dateFilterWithoutToday =
    data?.date?.filter((d) => moment().format("YYYY-MM-DD") !== d.start_date) ??
    [];

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <div className="bg-white">
      <Row className="pt-3">
        <Col xs={12} md={6} className="pb-4 px-5">
          <h3
            style={{
              fontSize: 22,
            }}
          >
            {productTitle}
          </h3>

          <span className="fs-standard">
            {asin} ({sku}) &nbsp;
            <ExternalProductLink asin={asin} />
          </span>
        </Col>
        <Col xs={12}>
          <MultiMetricChart
            metrics={metrics}
            selectedMetrics={selectedMetrics}
            data={dateFilterWithoutToday}
            group={group}
            handleLegendChange={handleLegendChange}
            datesFormatted={true}
            showAnnotations={false}
            highlightDate={highlightDate}
          />
        </Col>
        <Col xs={12}>
          <Row className="pt-4">
            <Col xs={12} md={6} lg={2} className={"pr-2"}>
              <div className="">
                <div>
                  <DetailedMetric
                    center
                    size={"small"}
                    id={"product_price"}
                    name={"Product Price"}
                    value={data?.products?.[0].product_price}
                    formatter={(v) =>
                      formatCurrency(v, marketPlace.marketPlace, true)
                    }
                    showTarget={false}
                  />
                </div>
              </div>
            </Col>
            {metrics?.slice(1)?.map((m, i) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  lg={2}
                  key={m.id}
                  className={`mb-4 ${i % 2 === 0 ? "pl-2" : "pr-2"}`}
                >
                  <div className="">
                    <div>
                      <DetailedMetric
                        center
                        size={"small"}
                        id={m.id}
                        toolTip={m.toolTip}
                        name={m.name}
                        value={m.overrideValue ?? m.actual}
                        target={m.target}
                        formatter={m.formatter}
                        targetFormatter={m?.targetFormatter}
                        showTarget={false}
                        targetLabel={m?.targetLabel}
                        targetColor={
                          m?.targetColor ??
                          (m.target > 0 ? "#007000" : "#d2222d")
                        }
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="fs-standard pb-5">
            <Col xs={12} className="text-center">
              {generateLink(
                "listing",
                product,
                null,
                "both",
                "inherit",
                false,
                "View Listing"
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ProductExpander;
