import React, { useState, useMemo, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";

import Table from "views/merchandise/tables/BulkActionsTable";
import { snakeCaseToSentenceCase } from "utils/formatText";
import {
  PRODUCT_AD_ENABLED,
  PRODUCT_AD_PAUSED,
} from "components/core/blocks/AdPlan/constants";
import { useSelector } from "react-redux";
import NotificationSystem from "react-notification-system";
import acosOrRoas from "utils/acosOrRoas";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import AdvertisementProductExpander from "views/advertising/AdvertisementProductExpander";
import { useMediaQuery } from "@react-hook/media-query";
import { sendToastNotification } from "utils/sendToastNotification";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import {
  INVENTORY_STATE_FILTER,
  PRODUCT_AD_STATUS_FILTER,
  SALES_STATE_FILTER,
  buildCustomTagsFilter,
} from "views/advertising/productLifecycleFilters";

const ProductAdsTable = ({
  marketPlace,
  isSelectable = true,
  isLoading,
  adProductsStats,
  titleCol,
  selectionKey = "product_id",
  adId = null,
  productId = null,
  noDataText = "No Products Found",
  productDetailsAdTable = false,
  isPricing = true,
  tableEntityType = null,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedAds, setSelectedAds] = useState([]);

  const { preferences } = useMerchandisePreferences();

  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  const channel = useSelector((state) => state?.channel);

  const queryClient = useQueryClient();
  const smallViewportWidthTable = useMediaQuery("(max-width:1550px)");

  const TABLE_ID = "advertisementProductsTable";
  const tableRef = useRef();
  const notificationRef = useRef();

  const updateProductStatus = useMutation(
    async (updateData) => {
      return await api.put(`/api/gvads/advproduct/`, updateData);
    },
    {
      onSuccess: (res) => {
        sendToastNotification(
          notificationRef,
          "success",
          "Product Ad Status Successfully Updated"
        );
        setSelectedProducts([]);
        queryClient.invalidateQueries();
      },
      onError: (res) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Product Ad Status"
        );
      },
    }
  );

  const bulkUpdateProductAdStatus = useMutation(
    async (body) => {
      let reqData = body.data ?? {};

      return await api.put(`/api/gvads/productid/`, reqData);
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          "Product Ad Status Successfully Updated"
        );
        setSelectedAds([]);
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Product Ad Status"
        );
        setSelectedAds([]);
        queryClient.invalidateQueries();
      },
    }
  );

  // Handles many products being assigned one product ad status
  const updateProductState = (newState, productId, adId, bulkAction) => {
    let state;
    let productIds;

    if (newState === "paused") {
      state = PRODUCT_AD_PAUSED;
    }

    if (newState === "enabled") {
      state = PRODUCT_AD_ENABLED;
    }

    if (bulkAction) {
      productIds = selectedProducts;
    }

    if (productId) {
      productIds = [productId];
    }

    if (productId && productDetailsAdTable && bulkAction) {
      const productData = {
        product_ids: [productId],
        ad_ids: selectedAds,
        status: state === 1 ? "enabled" : "paused",
      };

      bulkUpdateProductAdStatus.mutate({
        data: productData,
      });
      return;
    }

    updateProductStatus.mutate({
      custom_api_request: "pause_product_ad",
      id: adId,
      products: productIds,
      state: state,
    });
  };

  const formatServingStatus = (status) => {
    if (!status) {
      return "Pending";
    }

    if (status === "AD_STATUS_LIVE") {
      return "Serving";
    }

    return <>{snakeCaseToSentenceCase(status)}</>;
  };

  const filteredProducts = useMemo(() => {
    return adProductsStats?.product_ads ?? [];
  }, [adProductsStats]);

  const customTags = useMemo(() => {
    return (
      adProductsStats?.product_ads?.map((product) => product.tags)?.flat() ?? []
    );
  }, [adProductsStats]);

  const ADDITIONAL_FILTERS = [
    INVENTORY_STATE_FILTER,
    SALES_STATE_FILTER,
    PRODUCT_AD_STATUS_FILTER,
    buildCustomTagsFilter(customTags),
  ];

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const bulkActionOptions = [
    {
      label: `Pause Product Advertisement${
        selectedProducts?.length > 1 ? "s" : ""
      }`,
      action: () => updateProductState("paused", productId, adId, true),
      confirmationMessage: (
        <p>
          Are you sure you want to pause the{" "}
          {selectedProducts?.length > 1 ? selectedProducts?.length : ""}{" "}
          selected product{selectedProducts?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: `Enable Product Advertisement${
        selectedProducts?.length > 1 ? "s" : ""
      }`,
      action: () => updateProductState("enabled", productId, adId, true),
      confirmationMessage: (
        <p>Are you sure you want to enable the selected products?</p>
      ),
    },
  ];

  if (isPricing) {
    bulkActionOptions.push({
      label: `Assign Media Plan to Pricing Rules`,
      action: () => {
        console.log("Assign Media Plan to Pricing Rules");
      },
    });
  }
  return (
    <div>
      <NotificationSystem ref={notificationRef} />
      <Table
        tableEntityType={tableEntityType}
        tableId={TABLE_ID}
        tableRef={tableRef}
        titleCol={titleCol}
        noDataText={noDataText}
        columnSpecs={[
          {
            key: "user_status",
            compareKey: null,
            options: {
              editCell: true,
              hideFilter: false,
              toggleAction: (row, value) => {
                const { product_id: productId, ad_id } = row;
                updateProductState(value, productId, ad_id || adId, false);
              },
              filterType: "select",
              selectOptions: [
                {
                  value: "enabled",
                  label: "Active",
                },
                {
                  value: "paused",
                  label: "Inactive",
                },
              ],

              format: "toggle",
              onValue: "enabled",
              offValue: "paused",
              className: "justify-content-end",
              columnTitle: "Active",
            },
          },
          {
            key: "serving_status",
            compareKey: null,

            options: {
              width: smallViewportWidthTable ? 100 : 190,
              formatter: (v) => formatServingStatus(v),

              filterType: "select",
            },
          },
          acosroas(
            {
              key: "acos",
              compareKey: "acos_delta",
              options: {
                isNegative: true,
              },
            },
            { key: "roas", compareKey: "roas_delta" }
          ),

          acosroas(
            {
              key: "acos_same_sku",
              compareKey: "acos_same_sku_delta",
              options: {
                isNegative: true,
                checked: false,
              },
            },
            {
              key: "roas_same_sku",
              compareKey: "roas_same_sku_delta",
              options: { checked: false },
            }
          ),
          {
            key: "ad_sales",
            compareKey: "ad_sales_percent",
          },
          {
            key: "ad_sales_same_sku",
            compareKey: "ad_sales_same_sku_percent",
            options: { checked: false },
          },
          { key: "ad_spend", compareKey: "ad_spend_percent" },
          { key: "impressions", compareKey: "impressions_percent" },
          { key: "clicks", compareKey: "clicks_percent" },
          { key: "ctr", compareKey: "ctr_delta", options: { checked: false } },
          {
            key: "conversion_rate",
            compareKey: "conversion_rate_delta",
            options: { checked: false },
          },
          {
            key: "orders",
            compareKey: "orders_percent",
            options: { checked: false },
          },
          {
            key: "cpc",
            compareKey: "cpc_delta",
            options: { checked: false, isNegative: true },
          },
        ]}
        data={filteredProducts}
        selected={productId ? selectedAds : selectedProducts}
        setSelected={productId ? setSelectedAds : setSelectedProducts}
        selectionKey={selectionKey}
        bulkActionOptions={
          isSelectable && (adId || productId) ? bulkActionOptions : []
        }
        getSearchCriteria={(row) => {
          const { product_title, name, ad_name, asin, sku, id, tags } = row;

          return `${name} ${ad_name} ${product_title} ${asin} ${sku} ${id} ${tags?.join(
            " "
          )}`;
        }}
        tableRowDescription={"Products"}
        defaultSorted={[{ id: "ad_sales", desc: true }]}
        minRows={5}
        isLoading={isLoading}
        isSelectable={isSelectable}
        filterWidget={true}
        {...filterProps}
        additionalFilterOptions={ADDITIONAL_FILTERS}
        excludedFilterColumns={["serving_status"]}
        hideFilterToggleIcon={true}
        hideResetFiltersIcon={false}
        isExpandable={true}
        ExpandedContent={({ row }) => {
          return (
            <>
              <AdvertisementProductExpander
                row={row.row}
                marketPlace={marketPlace}
                channel={channel?.currentChannel}
                adId={row.original.gvadvertisement_id}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default ProductAdsTable;
