import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { useFetch } from "hooks/api";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { ALGORITHM_OPTIONS } from "./constants";
import { selectModules } from "redux/selectors/modules";
import { useSelector } from "react-redux";
import checkModule from "utils/checkModule";
import { AuthButton } from "components/core/basic/Button";
import { Link, useHistory } from "react-router-dom";

const useStyles = (theme) => ({
  subtitle: {
    fontWeight: 560,
    fontSize: "1.6rem",
    color: "#73706E",
  },
  inputContainer: {
    padding: theme.spacing(0, 1, 1, 0),
  },
  explanationText: {
    margin: theme.spacing(1, 0),
    color: "#6C757D",
    fontSize: "1.3rem",
    fontWeight: 500,
    maxWidth: "90%",
  },
  reviewExplanationText: {
    maxWidth: "100%",
  },
});

const AdPlanBiddingRules = ({
  state,
  onChange,
  classes = {},
  theme = {},
  review,
  skipToRulesForm,
  fieldsToValidate,
  errors = {},
  hideDescription = false,
}) => {
  const reduxState = useSelector((state) => state);
  const modules = selectModules(reduxState);
  const history = useHistory();

  const { data: biddingProgramData } = useFetch(
    ["programs", "bidding"],
    "/programs",
    { engine: "bidding" },
    {
      enabled: checkModule(modules, "BIDDING_RULES"),
      select: (data) => {
        return data.data?.results;
      },
    }
  );

  const bidProgramOptions =
    biddingProgramData?.map((program) => {
      return { value: program.id, label: program.name };
    }) ?? [];

  if (state.biddingProgram && typeof state.biddingProgram === "object") {
    bidProgramOptions.unshift({
      value: state.biddingProgram?.name,
      label: state.biddingProgram?.name,
    });
  }

  return (
    <Grid item container xs={12} style={{ margin: "3rem auto" }}>
      <Grid item xs={12}>
        <h3 className={classes.subtitle}>
          Rules Program{" "}
          {state.algorithm !== ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value
            ? "(Optional)"
            : ""}
          :
        </h3>
      </Grid>
      <Grid
        xs={12}
        md={hideDescription ? 12 : 5}
        className={classes.inputContainer}
        style={{
          paddingRight: hideDescription ? 0 : theme.spacing(5),
          paddingTop: theme.spacing(1),
        }}
      >
        <FormGroupTile
          type="select"
          stateName={"biddingProgram"}
          handleChildFormElement={(key, value) => {
            if (value) {
              onChange({
                [key]: value.value,
                createNewProgram: false,
              });
            } else {
              onChange({
                [key]: null,
                createNewProgram: false,
              });
            }
          }}
          isClearable={true}
          disabled={false}
          defaultValue={bidProgramOptions.find((a) =>
            state.biddingProgram && typeof state.biddingProgram === "object"
              ? a.value === state.biddingProgram.name
              : a.value === state.biddingProgram
          )}
          options={bidProgramOptions}
        />
        {state.id ? (
          <div className="d-flex pt-5 align-items-center">
            {state.biddingProgram && (
              <>
                <div>
                  <AuthButton
                    title={"Edit Program"}
                    buttonStyle="adplan_button fs-6"
                    onClick={(e) => {
                      history.push(
                        `/user/advertising/programs/edit/${state.biddingProgram}`
                      );
                    }}
                    style={{ transform: "none", boxShadow: "none" }}
                  />
                </div>
                <p className="px-5 my-auto">Or</p>
              </>
            )}
            <div>
              <Link to="/user/advertising/programs/create">
                <AuthButton
                  title={"Create New Program"}
                  buttonStyle="adplan_button fs-6"
                  style={{ transform: "none", boxShadow: "none" }}
                />
              </Link>
            </div>
          </div>
        ) : (
          <>
            <p className="py-3 d-block">Or</p>
            <div>
              <AuthButton
                title={"Create New Rules"}
                buttonStyle="adplan_button fs-6"
                onClick={(e) => {
                  skipToRulesForm();
                  onChange({ createNewProgram: true, biddingProgram: null });
                }}
              />
            </div>
          </>
        )}
      </Grid>
      {!hideDescription && (
        <Grid item xs={12} md={7}>
          <p
            className={`${classes.explanationText} ${
              review && classes.reviewExplanationText
            }`}
          >
            Choose from predefined templates, rules programs you've previously
            defined, or select 'Custom' to create a new rules program tailored
            to this media plan.
          </p>{" "}
        </Grid>
      )}
      <Grid item xs={12}>
        {(errors.existingBiddingProgram &&
          fieldsToValidate?.includes("existingBiddingProgram")) ||
        (state.algorithm === ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value &&
          !state.biddingProgram) ? (
          <small className="text-danger d-block pt-3">
            {errors.existingBiddingProgram}
          </small>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(AdPlanBiddingRules);
