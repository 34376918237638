import { SET_ERRORS, CLEAR_ERRORS } from "../types";

// setErrors action creator
export const setErrors = (err) => {
  return {
    type: SET_ERRORS,
    payload: err,
  };
};

// clearErrors action creator
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
    payload: null,
  };
};
