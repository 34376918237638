import React, { useMemo, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { useQueryClient } from "react-query";
import api from "utils/api";

const FIELD_MAP = {
  "Minimum Price": "min_price",
  "Maximum Price": "max_price",
  "Landed Cost": "landed_cost",
  "Shipping Cost": "shipping_cost",
  "Fixed Price": "price_override",
  "Clear Fixed Price": "price_override",
};

export const sendUpdates = async (
  identifierType,
  values,
  marketPlace,
  queryClient
) => {
  const data = {
    identifier_type: identifierType,
    values: values,
    market_place: marketPlace.marketPlace,
  };

  await api.post("/product/pricing/update/", data);
  const invalidateMap = {
    product_id: "product_report",
    plan_id: "plan_report",
  };
  if (invalidateMap?.[identifierType]) {
    queryClient.invalidateQueries([
      invalidateMap[identifierType],
      marketPlace.marketPlace,
    ]);
  }
};

export const SetValue = ({
  showSetValue,
  setShowSetValue,
  valueType,
  selectedProducts,
  productById,
  marketPlace,
  setSelectedProducts,
}) => {
  const queryClient = useQueryClient();
  const [showConfirm, setShowConfirm] = useState(false);
  const [_updateValue, setUpdateValue] = useState(null);

  const updateValue = useMemo(() => {
    if (FIELD_MAP[valueType] === undefined) {
      return null;
    }
    if (_updateValue !== null) return _updateValue;
    const xs = selectedProducts.map((id) => {
      const p = productById[id];
      const field = FIELD_MAP[valueType];
      if (valueType === "Fixed Price") return p[field] || p["product_price"];
      return p[field];
    });
    return Math.max(...xs);
  }, [selectedProducts, valueType, _updateValue, productById]);

  if (FIELD_MAP[valueType] === undefined) {
    return null;
  }

  return (
    <Modal show={showSetValue} size="lg">
      <Modal.Header className="py-0 px-5">
        <Row style={{ width: "100%" }}>
          <Col md={6}>
            <div className="fs-3 my-4 text-start">
              {valueType === "Clear Fixed Price"
                ? valueType
                : `Set ${valueType}`}
            </div>
          </Col>
          <Col md={6} className="text-end"></Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="py-2">
          <Col md={{ span: 8, offset: 2 }}>
            <div className="fs-3 my-4 text-center">
              <div>The following SKUs will be updated</div>
              <small>
                {selectedProducts.slice(0, 5).map((id, i) => (
                  <span key={i} className="pe-3">
                    {productById[id].sku}
                  </span>
                ))}
                {selectedProducts.length > 5 && (
                  <span> plus {selectedProducts.length - 5} more</span>
                )}
              </small>
            </div>

            {!showConfirm &&
              (valueType === "Clear Fixed Price" ? (
                <Row className="fs-4 text-start pt-3">
                  <Col className="pt-3 text-center" xs={12}>
                    Are you sure you want to clear the fixed price?
                  </Col>
                </Row>
              ) : (
                <Row className="fs-4 text-start pt-3">
                  <Col className="pt-3">Enter the {valueType}:</Col>
                  <Col>
                    <FormGroupTile
                      type="formcontrol"
                      formControlType={"number"}
                      prefix={
                        <div
                          className="fs-3"
                          style={{ position: "absolute", top: "-2px" }}
                        >
                          {
                            MARKETPLACE_CURRENCY_DENOMINATION[
                              marketPlace.marketPlace
                            ]
                          }
                        </div>
                      }
                      prefixSide={"left"}
                      handleChildFormElement={(key, value) => {
                        try {
                          value = parseFloat(value);
                        } catch (e) {
                          value = 0;
                        }
                        setUpdateValue(value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setShowConfirm(true);
                        }
                      }}
                      placeholder={`Enter your ${valueType}`}
                      defaultValue={updateValue}
                    />
                  </Col>
                </Row>
              ))}
            {showConfirm && (
              <>
                <Row>
                  <Col className="pt-3 text-center">
                    <div className="fs-4 py-2">
                      You are about to set the {valueType} to{" "}
                      {
                        MARKETPLACE_CURRENCY_DENOMINATION[
                          marketPlace.marketPlace
                        ]
                      }
                      {updateValue.toFixed(2)}.
                    </div>

                    <div className="fs-4 py-2">
                      Are you sure you want to continue?
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row style={{ width: "100%" }}>
          <Col xs={3}>&nbsp;</Col>
          <Col xs={3} className="mx-auto text-center">
            <LogoutButton
              key="CloseBtn"
              title={`Close`}
              onClick={() => {
                setUpdateValue(null);
                setShowSetValue(false);
              }}
            />
          </Col>
          <Col xs={3} className="mx-auto text-center">
            <LogoutButton
              key="SaveBtn"
              title={
                valueType === "Clear Fixed Price"
                  ? valueType
                  : `Set ${valueType}`
              }
              onClick={() => {
                if (!showConfirm && valueType !== "Clear Fixed Price") {
                  setShowConfirm(true);
                } else {
                  const fieldMap = FIELD_MAP[valueType];
                  const values = {};
                  for (let i = 0; i < selectedProducts.length; i++) {
                    const val =
                      valueType === "Clear Fixed Price" ? null : updateValue;
                    values[selectedProducts[i]] = [];
                    values[selectedProducts[i]].push([fieldMap, val]);
                  }

                  sendUpdates("product_id", values, marketPlace, queryClient);
                  setShowSetValue(false);
                  setSelectedProducts([]);
                  setShowConfirm(false);
                }
              }}
            />
          </Col>
          <Col xs={3}>&nbsp;</Col>
        </Row>
      </Modal.Footer>
      )
    </Modal>
  );
};

export const setPlanValue = async (plan_id, status) => {
  await api.post("/pricing/togglestatus", {
    pricing_plan_id: plan_id,
    status: status,
  });
};
