import React from "react";
import TrellisLink from "components/core/basic/TrellisLink";

const generateLink = (
  type = "advertisement",
  details = {},
  handleClick = null,
  productIdentifier = "asin",
  styles = {},
  openInNewTab = false,
  customText = null
) => {
  return (
    <TrellisLink
      key={`${type}Link${type === "listing" ? details?.ad_id : details?.id}`}
      to={
        type === "listing"
          ? `/user/merchandising/product/${details.id}`
          : `/user/advertising/dashboard/ads/${details.id}`
      }
      target={openInNewTab ? "_blank" : ""}
      rel={openInNewTab ? "noopener noreferrer" : ""}
      onClick={() => handleClick && handleClick()}
      style={
        styles === "inherit"
          ? {}
          : {
              color: "#d91266",
              textAlign: "center",
              float: "center",
              textDecoration: "none",
              fontWeight: "500",
              fontSize: "1.5rem",
              cursor: "pointer",
              ...styles,
            }
      }
    >
      {customText
        ? customText
        : type === "listing"
        ? productIdentifier === "both"
          ? `${details?.asin} (${details.sku})`
          : productIdentifier === "sku"
          ? details?.sku
          : details?.asin
        : details?.name}
    </TrellisLink>
  );
};

export default generateLink;
