import { Box, Button, Divider, Grid, withStyles } from "@material-ui/core";
import bgImage from "assets/images/background/Rocket-10.png";
import bgImageAlternate from "assets/images/background/arrow_bg.png";
import axios from "axios";
import ModalAlert from "components/core/basic/ModalAlert";
import Cryptr from "cryptr";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import {
  logoutUser,
  setCurrentUser,
  setTokens,
} from "redux/actions/authActions";
import { setChannel } from "redux/actions/channel";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { setMarketPlace } from "redux/actions/marketPlace";
import clearCookies from "utils/clearCookies";
import {
  CONST_SPAPI_SRC_ONBOARDING_FOR_URI_EXTERNAL,
  getAPIKeys,
  getURLPrefix,
} from "utils/getUrlPrefix";
import {
  AMAZON_AUTH_APP_ID,
  CLIENT_SIDE_ENCRYPTION_KEY,
} from "utils/gvSecrets";
import setAuthToken from "utils/setAuthToken";
import SocialButton from "utils/SocialButton";
import userMarketPlace from "utils/userMarketPlace";
import LoadingProgress from "./LoadingProgress";
import SignUpForm from "./SignUpForm";
import TrellisPanel from "./TrellisPanel";
import TrellisPanelSignUp from "./TrellisPanelSignUp";
import SignupLayout from "views/onboarding/layout/SignupLayout";

const cryptr = new Cryptr(CLIENT_SIDE_ENCRYPTION_KEY);

const URL_PREFIX = getURLPrefix();
const apiKeys = getAPIKeys();

const CLIENT_ID = apiKeys["API_AUTH_CLIENT_ID"];
const CLIENT_SECRET = apiKeys["API_AUTH_SECRET"];

const USE_NEW_SIGNUP_PATH = true;

const useStyles = (theme) => ({
  basePanel: {
    background: "white",
    flexGrow: 1,
    // border: "1px solid black",
    height: "100vh",
  },
  leftHomePanel: {
    [theme.breakpoints.up("md")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 2,
      height: "80%",
    },
    [theme.breakpoints.only("xs")]: {
      order: 2,
      height: "80%",
    },
  },
  rightHomePanel: {
    backgroundImage:
      "linear-gradient(to bottom left, " +
      theme.palette.primary.dark +
      ", " +
      theme.palette.primary.main +
      ")",
    // order: 1,
    [theme.breakpoints.up("md")]: {
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 1,
      height: "20%",
    },
    [theme.breakpoints.only("xs")]: {
      height: "20%",
    },
  },
  leftSignUpPanel: {
    [theme.breakpoints.up("md")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      // order: 2,
      paddingTop: "5%",
      height: "100%",
    },
  },
  rightSignUpPanel: {
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      // order: 1,
      height: "0%",
    },
    backgroundImage: "url(" + bgImage + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "scroll",
  },
  rightSignUpPanelAlt: {
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      // order: 1,
      height: "0%",
    },
    backgroundImage: "url(" + bgImageAlternate + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "scroll",
  },
  leftInvitePanel: {
    [theme.breakpoints.up("md")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      order: 2,
      height: "80%",
    },
  },
  rightInvitePanel: {
    backgroundImage:
      "linear-gradient(to bottom left, " +
      theme.palette.primary.dark +
      ", " +
      theme.palette.primary.main +
      ")",
    // order: 1,
    [theme.breakpoints.up("md")]: {
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      order: 1,
      height: "20%",
    },
  },
  signingrid: {
    // borderBottom: "3px solid " + theme.palette.grey[500],
    height: "50%",
    [theme.breakpoints.only("xs")]: {
      height: "40%",
    },
  },
  signupgrid: {
    height: "50%",
    [theme.breakpoints.only("xs")]: {
      height: "60%",
    },
  },
  button: {
    width: "50%",
    fontSize: "1.25rem",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    // backgroundColor: "#d91366",
    color: "white",
  },
  signupLink: {
    color: "#d91366",
  },
  heading: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h2.fontSize,
    },
    textAlign: "center",
    fontWeight: "bold",
  },
  loadingIndicatorText: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.h2.fontSize,
    },
    textAlign: "center",
    fontWeight: "bold",
  },
});

class SingleSignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      companyName: "",
      country: "",
      contactNumber: "",
      loginFailure: false,
      loginInProgress: false,
      registrationInProgress: false,
      signUpPage: false,
      signUpAlt: 0,
      inviteSignUpPage: false,
      inviteToken: "",
      queryParams: "",
      termsAgreement: false,
      hearAboutUs: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (this.props.auth.isAuthenticated) {
      // handle redirect from SP-API Auth
      if (window.location.href.includes("spapi_oauth_code")) {
        const queryParams = new URLSearchParams(window.location.search);
        const spapi_oauth_code = queryParams.get("spapi_oauth_code");
        const selling_partner_id = queryParams.get("selling_partner_id");
        let stateEncoded = queryParams.get("state");
        let decodedState = Buffer.from(stateEncoded, "base64").toString(
          "utf-8"
        );
        let stateJson = JSON.parse(decodedState);
        const region_encoded = stateJson["region"];
        const spapisrc_encoded = stateJson["src"];
        let spapi_request_state = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          region_encoded: region_encoded,
        };

        let usePathname =
          spapisrc_encoded === CONST_SPAPI_SRC_ONBOARDING_FOR_URI_EXTERNAL
            ? "/onboard/amzspapi"
            : "/user/amzsummary";

        this.props.history.push({
          pathname: usePathname,
          search: "?edit_sp_api=true",
          state: spapi_request_state,
        });
      } else {
        this.props.history.push("/onboard");
      }
    }

    // Set extension user flag in localStorage for GA tracking
    if (this.props.history.location.search.includes("source=extension")) {
      localStorage.extensionSignup = new Date().toISOString().split("T")[0];
    }

    this.setState({
      queryParams: this.props.location.search,
    });
    if (window.location.href.includes("/login")) {
      this.props.history.push("/");
      setTimeout(() => {
        document.getElementById("amazonLogin").click();
      }, 3000);
    } else if (window.location.href.includes("/signup")) {
      if (window.location.href.includes("/signup_a962")) {
        this.setState({ signUpAlt: 1 });
      } else if (window.location.href.includes("/signup_5f3b")) {
        this.setState({ signUpAlt: 2 });
      } else if (window.location.href.includes("/signup_0f08")) {
        this.setState({ signUpAlt: 3 });
      } else {
        this.setState({ signUpAlt: 4 });
      }

      if (this.state.signUpAlt === 4) {
        // Add new signup panel SEO
        const documentTitle = document.head.querySelector("title");
        documentTitle.innerText = "Sign Up For Trellis";

        const descriptionMeta = document.createElement("meta");
        descriptionMeta.setAttribute("name", "description");
        descriptionMeta.setAttribute(
          "content",
          "Automate your Amazon ads with Trellis. Increase your sales while saving time and money."
        );

        document.head.appendChild(descriptionMeta);
      }

      if (this.props.history.location.search.includes("onboard=v2")) {
        this.props.history.push("/?onboard=v2");
      } else {
        this.props.history.push("/");
      }
      this.setState({ signUpPage: true });
    } else if (
      window.location.href.includes("/referral/") ||
      window.location.href.includes("/affiliate")
    ) {
      this.setState({ signUpPage: true });
      this.setState({ signUpAlt: 0 });
    } else if (window.location.href.includes("/invite/")) {
      var inviteToken = window.location.href.split("/invite/")[1];
      this.setState({ inviteToken: inviteToken, inviteSignUpPage: true });
    }
  }

  componentDidUpdate() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/onboard");
    }

    if (this.state.signUpAlt === 4) {
      const documentTitle = document.head.querySelector("title");
      documentTitle.innerText = "Sign Up For Trellis";

      const descriptionMeta = document.createElement("meta");
      descriptionMeta.setAttribute("name", "description");
      descriptionMeta.setAttribute(
        "content",
        "Automate your Amazon ads with Trellis. Increase your sales while saving time and money."
      );

      document.head.appendChild(descriptionMeta);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/onboard");
    }
  }

  handleSocialLogin = (user) => {
    this.setState({ loginInProgress: true }, () => {
      this.socLoginUser(user);
    });
  };

  handleAmazonSignup = (user) => {
    this.setState(
      { loginInProgress: true, registrationInProgress: true },
      () => {
        // if (this.props.match.params.invitetoken !== undefined) {
        //   this.inviteSignup(user);
        // } else {
        this.registerOrg(user);
        // }
      }
    );
  };

  handleInviteSignup = (user) => {
    this.setState(
      { loginInProgress: true, registrationInProgress: true },
      () => {
        this.inviteSignup(user);
      }
    );
  };

  handleSocialLoginFailure = (err) => {
    this.setState(
      {
        loginFailure: true,
        loginInProgress: false,
        registrationInProgress: false,
      },
      () => {
        this.setState({ loginFailure: false });
      }
    );
  };

  inviteSignup(user) {
    var userInfo = {
      grant_type: "convert_token",
      backend: "amazon",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      token: user.token.accessToken,
    };
    var invUserInfo = {
      custom_api_request: "invite_user_verification",
      user_email: user.profile.email,
    };
    var newTenantInfo = {
      custom_api_request: "invite_user_profile_creation",
      user_email: user.profile.email,
    };
    axios
      .post(URL_PREFIX + "/api/authentication/", invUserInfo)
      .then((res) => {
        if (res.data === "Invited") {
          axios
            .post(URL_PREFIX + "/auth/convert-token", userInfo)
            .then((res) => {
              let access_token = res.data.access_token;
              let encrypted_refresh_token = cryptr.encrypt(
                res.data.refresh_token
              );
              refreshAmzOnboardStatus(
                this.props.updateAmzOnboardingStatus,
                access_token,
                this.props.setErrors
              );
              axios
                .post(URL_PREFIX + "/api/authentication/", newTenantInfo)
                .then((res) => {
                  let tokens = Object.assign(
                    { access_token: access_token },
                    { refresh_token: encrypted_refresh_token }
                  );
                  localStorage.setItem("Tokens", JSON.stringify(tokens));
                  localStorage.setItem(
                    "TokenTime",
                    (Date.now() + 60 * 60 * 1000).toString()
                  );
                  setAuthToken(access_token);
                  this.props.setCurrentUser(res.data);
                  this.props.setTokens(tokens);
                  this.props.setChannel(res.data.channel.toLowerCase());
                  localStorage.setItem(
                    "channel",
                    res.data.channel.toLowerCase()
                  );
                  // this.props.setMarketPlace("CA");
                  // localStorage.setItem("Marketplace", "CA");
                  userMarketPlace(access_token);
                })
                .catch((err) => {
                  this.props.setErrors(err);
                  this.setState({ inviteSignUpPage: false });
                  this.handleSocialLoginFailure(err);
                });
            })
            .catch((err) => {
              this.props.setErrors(err);
              this.setState({ loginFailure: true });
              this.handleSocialLoginFailure(err);
            });
        } else if (res.data === "Invitation timeout") {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message:
              "Invitation has timed out. Please have your organization administrator send a new invitation",
          };
          this.props.setErrors(usererr);
          this.setState({
            email: "",
            companyName: "",
            country: "",
            loginFailure: false,
            loginInProgress: false,
            registrationInProgress: false,
          });
        } else {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message:
              "No invite found, please login if already registered or create a new organization",
          };
          this.props.setErrors(usererr);
          this.setState({ inviteSignUpPage: false });
          this.handleSocialLoginFailure(usererr);
        }
      })
      .catch((err) => {
        this.props.setErrors(err);
        this.setState({ inviteSignUpPage: false });
        this.handleSocialLoginFailure(err);
      });
  }

  socLoginUser(user) {
    var userInfo = {
      grant_type: "convert_token",
      backend: "amazon",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      token: user.token.accessToken,
    };

    var uloginInfo = {
      custom_api_request: "user_login_verification",
      user_email: user.profile.email,
    };

    var tenantInfo = {
      custom_api_request: "login_user_info",
      user_email: user.profile.email,
    };

    axios
      .post(URL_PREFIX + "/api/authentication/", uloginInfo)
      .then((res) => {
        if (res.data === "User Available") {
          axios
            .post(URL_PREFIX + "/auth/convert-token", userInfo)
            .then((res) => {
              let access_token = res.data.access_token;
              let encrypted_refresh_token = cryptr.encrypt(
                res.data.refresh_token
              );
              refreshAmzOnboardStatus(
                this.props.updateAmzOnboardingStatus,
                access_token,
                this.props.setErrors
              );
              axios
                .post(URL_PREFIX + "/api/authentication/", tenantInfo)
                .then((res) => {
                  setAuthToken(access_token);
                  let tokens = Object.assign(
                    { access_token: access_token },
                    { refresh_token: encrypted_refresh_token }
                  );
                  localStorage.setItem("Tokens", JSON.stringify(tokens));
                  localStorage.setItem(
                    "TokenTime",
                    (Date.now() + 60 * 60 * 1000).toString()
                  );
                  var mfq = window._mfq || [];
                  mfq.push(["setVariable", "USER", res.data.full_user_name]);
                  this.props.setCurrentUser(res.data);
                  this.props.setTokens(tokens);
                  this.props.setChannel(res.data.channel.toLowerCase());
                  localStorage.setItem(
                    "channel",
                    res.data.channel.toLowerCase()
                  );
                  userMarketPlace(access_token, () => {
                    if (res.data.permissions === "reports") {
                      window.location.href = "/user/report/advertisement";
                    }
                  });
                })
                .catch((err) => {
                  this.props.setErrors(err);
                  this.handleSocialLoginFailure(err);
                });
            })
            .catch((err) => {
              this.props.setErrors(err);
              this.handleSocialLoginFailure(err);
            });
        } else {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message:
              "User not found. Did you mean to sign up or sign in with username and password?",
          };
          this.props.setErrors(usererr);
          this.handleSocialLoginFailure(usererr);
        }
      })
      .catch((err) => {
        this.props.setErrors(err);
        this.handleSocialLoginFailure(err);
      });
  }

  async registerOrgV2() {
    const signUpFormData = {
      user_email: null,
      organization_name: this.state.companyName.trim(),
      referral_token: "",
      affiliate_token: "",
      country: this.state.country,
      specified_user_email: this.state.email,
      contact_number: this.state.contactNumber,
      hear_about_us: this.state.hearAboutUs,
      terms_agreement: this.state.termsAgreement,
    };

    this.props.history.push({
      pathname: "/onboarding/v2/signin",
      state: { signUpFormData: signUpFormData },
    });
  }

  async registerOrg(user) {
    let onboardVersion = new URLSearchParams(this.props.location.search).get(
      "onboard"
    );

    if (USE_NEW_SIGNUP_PATH) {
      onboardVersion = "v2";
    }

    if (onboardVersion === "v2") {
      await this.registerOrgV2();
      return;
    }
    var userInfo = {
      grant_type: "convert_token",
      backend: "amazon",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      token: user.token.accessToken,
    };
    var referral_token = "";
    var affiliate_token = "";
    if (this.props.match.params.referraltoken !== undefined) {
      referral_token = this.props.match.params.referraltoken;
    }
    if (this.props.match.params.affiliatetoken !== undefined) {
      affiliate_token = this.props.match.params.affiliatetoken;
    }
    var organizationUserInfo = {
      custom_api_request: "organization_registration_verification",
      referral_token: referral_token,
      affiliate_token: affiliate_token,
      user_email: user.profile.email,
    };
    var orgInfo = {
      user_email: user.profile.email,
      organization_name: this.state.companyName.trim(),
      referral_token: referral_token,
      affiliate_token: affiliate_token,
      country: this.state.country,
      specified_user_email: this.state.email,
      contact_number: this.state.contactNumber,
      hear_about_us: this.state.hearAboutUs,
      terms_agreement: this.state.termsAgreement,
      bypass_onboarding: null,
    };
    axios
      .post(URL_PREFIX + "/api/authentication/", organizationUserInfo)
      .then((res) => {
        if (res.data === "Proceed for Registration") {
          axios
            .post(URL_PREFIX + "/auth/convert-token", userInfo)
            .then((res) => {
              let access_token = res.data.access_token;
              let encrypted_refresh_token = cryptr.encrypt(
                res.data.refresh_token
              );
              this.props.setMarketPlace("CA");
              localStorage.setItem("Marketplace", "CA");

              axios
                .post(
                  URL_PREFIX +
                    "/api/registerorganization/" +
                    this.state.queryParams,
                  orgInfo
                )
                .then((res) => {
                  let tokens = Object.assign(
                    { access_token: access_token },
                    { refresh_token: encrypted_refresh_token }
                  );
                  localStorage.setItem("Tokens", JSON.stringify(tokens));
                  localStorage.setItem(
                    "TokenTime",
                    (Date.now() + 60 * 60 * 1000).toString()
                  );
                  var mfq = window._mfq || [];
                  mfq.push([
                    "setVariable",
                    "NEW_SIGNUP",
                    res.data.full_user_name,
                  ]);
                  mfq.push(["setVariable", "USER", res.data.full_user_name]);
                  setAuthToken(access_token);
                  this.props.setCurrentUser(res.data);
                  this.props.setTokens(tokens);
                  this.props.setChannel("amazon");
                  localStorage.setItem("channel", "amazon");

                  refreshAmzOnboardStatus(
                    this.props.updateAmzOnboardingStatus,
                    access_token,
                    this.props.setErrors
                  );
                })
                .catch((err) => {
                  this.props.setErrors(err);
                  this.handleSocialLoginFailure(err);
                });
            })
            .catch((err) => {
              this.props.setErrors(err);
              this.handleSocialLoginFailure(err);
            });
        } else if (res.data === "Invalid Referral Token") {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message: "Invalid token",
          };
          this.props.setErrors(usererr);
          this.handleSocialLoginFailure(usererr);
        } else if (res.data === "Affiliate link not valid") {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message: "Invalid affiliate link",
          };
          this.props.setErrors(usererr);
          this.handleSocialLoginFailure(usererr);
        } else {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message:
              "Already registered, please try invite signup to join an organization or try logging in",
          };
          this.props.setErrors(usererr);
          this.handleSocialLoginFailure(usererr);
        }
      })
      .catch((err) => {
        if (err.response.data === "Unable to find referral.") {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message: "Invalid referral token. Please contact your referer.",
          };
          this.props.setErrors(usererr);
          this.handleSocialLoginFailure(err);
        } else if (err.response.data === "Unable to find affiliate.") {
          sessionStorage.clear();
          localStorage.clear();
          clearCookies();
          const usererr = {
            message:
              "Invalid affiliate link. Please contact your Trellis affiliate.",
          };
          this.props.setErrors(usererr);
          this.handleSocialLoginFailure(err);
        } else {
          this.props.setErrors(err);
          this.handleSocialLoginFailure(err);
        }
      });
  }
  // passed to child components that need to edit state. ex: SignUpForm
  onStateChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleScrollClick = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  render() {
    const { classes } = this.props;

    const { bypass_onboarding } = this.props.user ?? {};
    const {
      amazon_spapi_access,
      eu_amazon_spapi_access,
      fe_amazon_spapi_access,
    } = this.props.amz_onboard ?? {};
    if (
      bypass_onboarding &&
      [
        amazon_spapi_access,
        eu_amazon_spapi_access,
        fe_amazon_spapi_access,
      ].includes("in_progress")
    ) {
      return <SignupLayout placeholderLayout={true} />;
    }

    const onboardVersion = new URLSearchParams(this.props.location.search).get(
      "onboard"
    );

    return (
      <>
        {!this.state.loginFailure ? (
          this.state.loginInProgress ? (
            <Grid
              container
              className={classes.basePanel}
              justifyContent="flex-start"
            >
              {" "}
              <Grid item xs={12} md={6} className={classes.leftHomePanel}>
                <LoadingProgress
                  textStyle={classes.loadingIndicatorText}
                  registrationInProgress={this.state.registrationInProgress}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.rightHomePanel}>
                <TrellisPanel />
              </Grid>
            </Grid>
          ) : this.state.signUpPage ? (
            <Grid
              container
              className={classes.basePanel}
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                item
                xs={12}
                md={6}
                className={classes.leftSignUpPanel}
                style={{ order: 2 }}
              >
                <SignUpForm
                  alt={this.state.signUpAlt}
                  onStateChange={this.onStateChange}
                  handleAmazonSignup={this.handleAmazonSignup}
                  handleSocialLoginFailure={this.handleSocialLoginFailure}
                  handleSocialLogin={this.handleSocialLogin}
                  onboardVersion={
                    onboardVersion === "v2" || USE_NEW_SIGNUP_PATH ? "v2" : null
                  }
                />
              </Grid>
              {this.state.signUpAlt === 4 ? (
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  className={classes.rightSignUpPanelAlt}
                  style={{
                    order: 1,
                    alignContent: "center",
                  }}
                  justifyContent="center"
                  alignContent="flex-start"
                >
                  <TrellisPanelSignUp
                    // alt={this.state.signUpAlt}
                    alt={4}
                    handleScrollClick={this.handleScrollClick}
                    style={{ height: "100%" }}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.rightSignUpPanel}
                  style={{ order: 1 }}
                  justifyContent="center"
                  alignContent="flex-start"
                >
                  <TrellisPanelSignUp
                    alt={this.state.signUpAlt}
                    handleScrollClick={this.handleScrollClick}
                    style={{ height: "100%" }}
                  />
                </Grid>
              )}
            </Grid>
          ) : !this.state.inviteSignUpPage ? (
            <Grid
              container
              className={classes.basePanel}
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid item xs={12} md={6} className={classes.leftHomePanel}>
                <Grid container className={classes.leftHomePanel} wrap="nowrap">
                  <Grid item xs={1} md={3} />
                  <Grid item xs={10} md={6} style={{ height: "100%" }}>
                    <Grid
                      className={classes.signingrid}
                      container
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Box
                        width={1}
                        height="55%"
                        display={{ xs: "none", md: "block" }}
                      ></Box>
                      <Box width={1} height="45%">
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          wrap="nowrap"
                          style={{ height: "100%" }}
                        >
                          <Box className={classes.heading}>
                            Sign In To Trellis
                          </Box>
                          <Box
                            width={1}
                            display="flex"
                            justifyContent="center"
                            mt={2}
                          >
                            <SocialButton
                              provider="amazon"
                              appId={AMAZON_AUTH_APP_ID}
                              onLoginSuccess={this.handleSocialLogin}
                              onLoginFailure={this.handleSocialLoginFailure}
                              buttonId="amazonLogin"
                              className={classes.button}
                            >
                              Sign In with Amazon
                            </SocialButton>
                          </Box>
                          <Box
                            width={1}
                            display="flex"
                            justifyContent="center"
                            mt={2}
                            className="fs-standard"
                          >
                            <a
                              style={{
                                color: "#d91266",
                                textDecoration: "underline",
                              }}
                              href={"/signin"}
                            >
                              {" "}
                              Sign in with username instead{" "}
                            </a>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                    <Divider
                      style={{
                        height: "3px",
                        width: "100%",
                        position: "inherit !important",
                      }}
                    />
                    <Grid
                      className={classes.signupgrid}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Box textAlign="center" height="15%" />
                      <Box className={classes.heading}>Get Started Today</Box>
                      <Box
                        textAlign="center"
                        fontSize="h5.fontSize"
                        lineHeight={2}
                        mt={1}
                      >
                        Experience the Trellis Self Driving PPC Solution.
                        <br />
                        It's Effortless, Efficient, and Immediate
                      </Box>
                      <Box
                        mt={3}
                        width={1}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={(e) =>
                            this.setState({ signUpPage: true, signUpAlt: 4 })
                          }
                        >
                          Sign Up
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} md={3}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.rightHomePanel}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  wrap="nowrap"
                  style={{ height: "100%" }}
                >
                  <TrellisPanel />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              className={classes.basePanel}
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                xs={12}
                md={6}
                className={classes.leftInvitePanel}
                wrap="nowrap"
              >
                <Grid container xs={1} md={3} />
                <Grid container xs={10} md={6} style={{ height: "100%" }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    // wrap="nowrap"
                    style={{ height: "100%" }}
                    className="wrap"
                  >
                    <Grid>
                      <Box
                        width={1}
                        display="flex"
                        justifyContent="center"
                        className={classes.heading}
                        mt={2}
                      >
                        You have been invited to join Trellis!
                      </Box>

                      <Box
                        width={1}
                        display="flex"
                        justifyContent="center"
                        mt={2}
                        // height="60%"
                      >
                        <SocialButton
                          provider="amazon"
                          appId={AMAZON_AUTH_APP_ID}
                          onLoginSuccess={this.handleInviteSignup}
                          onLoginFailure={this.handleSocialLoginFailure}
                          buttonId="amazonLogin"
                          className={classes.button}
                        >
                          Sign Up
                        </SocialButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={1} md={3}></Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.rightInvitePanel}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  wrap="nowrap"
                  style={{ height: "100%" }}
                >
                  <TrellisPanel />
                </Grid>
              </Grid>
            </Grid>
          )
        ) : null}
        {this.props.errors.length > 0 &&
          ModalAlert({
            title:
              "title" in this.props.errors[0]
                ? this.props.errors[0].title
                : "error",
            msg: this.props.errors[0].message,
            onClick: () => {
              // If the user doesn't have an account, the hard reload resets Amazon OA so user doesn't get blank screen in onboarding sign-in step
              let reload = false;

              if (
                this.props.errors?.[0]?.message?.includes("User not found.")
              ) {
                reload = true;
              }

              this.props.clearErrors();

              if (reload) {
                window.location.reload();
              }
            },
          })}
      </>
    );
  }
}

SingleSignIn.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  setMarketPlace: PropTypes.func.isRequired,
  setTokens: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setChannel: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  amz_onboard: state.amz_onboard,
  errors: state.errors,
});

const mapDispatchToProps = {
  setCurrentUser,
  updateAmzOnboardingStatus,
  setMarketPlace,
  setTokens,
  logoutUser,
  setErrors,
  clearErrors,
  setChannel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(SingleSignIn)));
