import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Container as Grid,
  FormControl,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { useFetch } from "hooks/api";
import Card from "components/core/basic/Card.jsx";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import axios from "axios";
import getURLPrefix from "utils/getUrlPrefix";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";
import { trellisPalette } from "components/custom/analytics/palettes";
import { LogoutButton } from "components/core/basic/Button.jsx";
import ImportButton from "components/custom/import/ImportButton";

let URL_PREFIX = getURLPrefix();

const useStyles = (theme) => ({
  preferenceContainer: {
    padding: theme.spacing(2),
  },
  preferenceTitle: {
    margin: "0px 0px",
    paddingLeft: theme.spacing(2),
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2, 2),
      textAlign: "center",
    },
  },
  preferenceDescription: {
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2),
      textAlign: "center",
      margin: "auto",
    },
  },
});

const Settings = ({
  channel,
  marketPlace,
  modules,
  classes,
  handleNotification,
  user,
  amz_onboard,
}) => {
  const [settings, setSettings] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);

  const baseQuery = {};
  const { isLoading } = useFetch(
    ["pricing/settings", marketPlace, channel, modules],
    "/pricing/settings",
    { ...baseQuery },
    {
      default: [],
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (res.data.results) {
          setSettings(res.data.results[0]);
        }
      },
    }
  );

  const saveSettings = () => {
    setDisableSave(true);
    axios
      .put(`${URL_PREFIX}/pricing/settings/${settings.id}/`, settings)
      .then((res) => {
        handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          `Pricing settings have been saved`
        );
        setDisableSave(false);
      });
  };

  if (
    ((!user.pricing_tier || user.pricing_tier === "unknown") &&
      !checkModule(modules, "PRICE_OPTIMIZATION") &&
      !checkTierAccess(user.tier, "pricing", amz_onboard.org_status)) ||
    user.pricing_tier === "MANUALLY_DISABLED"
  ) {
    return <Redirect to="/user/pricing" />;
  }

  if (isLoading) {
    return (
      <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Col
          md={12}
          style={{
            backgroundColor: "#ffffff",
            height: "100vh",
            paddingLeft: "0px",
            paddingRight: "0px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Row
            style={{
              position: "absolute",
              left: "50%",
              top: "40%",
              transform: "translate(-50%, -50%)",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            <Col md={12}>
              <div className="text_above_button ">
                Loading Settings
                <br />
                <img
                  src={logo}
                  alt=""
                  className="rotate"
                  width="80"
                  height="auto"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  if (!isLoading) {
    return (
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid>
          <Row>
            <Col xs={12} className={`text-center pb-5`}>
              <div
                style={{
                  fontSize: "26px",
                  color: "#403E3D",
                  fontWeight: "700",
                }}
              >
                Dynamic Pricing - Configuration
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={{ span: 10, offset: 2 }}>
              <Row>
                <Col xs={8}></Col>
                <Col xs={2}>
                  <ImportButton
                    defaultType={`pricing_bulk_upload`}
                    importModalOpen={importModalOpen}
                    setImportModalOpen={setImportModalOpen}
                  />
                </Col>
                <Col xs={2}></Col>
              </Row>

              <Card
                style={{ width: "100%" }}
                content={
                  <Row>
                    <Col className={classes.preferenceContainer}>
                      <Row className="pt-5">
                        <Col xs={8}>
                          <h5 className={classes.preferenceTitle}>
                            Look Back Days
                          </h5>
                          <div className={classes.preferenceDescription}>
                            How many historical days does the pricing algorithm
                            use to make decisions.
                          </div>
                        </Col>
                        <Col xs={4}>
                          <FormControl
                            defaultValue={settings.days_back}
                            type="number"
                            style={{ textAlign: "center" }}
                            onChange={(e) => {
                              const s = { ...settings };
                              s.days_back = parseInt(e.target.value);
                              setSettings(s);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5">
                        <Col xs={8}>
                          <h5 className={classes.preferenceTitle}>
                            Sales Threshold
                          </h5>
                          <div className={classes.preferenceDescription}>
                            How many units sold does the price to have in order
                            to be considered valid by Dynamic Pricing.
                          </div>
                        </Col>
                        <Col xs={4}>
                          <FormControl
                            defaultValue={settings.sales_threshold}
                            type="number"
                            style={{ textAlign: "center" }}
                            onChange={(e) => {
                              const s = { ...settings };
                              s.sales_threshold = parseInt(e.target.value);
                              setSettings(s);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5">
                        <Col xs={8}>
                          <h5 className={classes.preferenceTitle}>
                            Days with price
                          </h5>
                          <div className={classes.preferenceDescription}>
                            How many days does a price need to have been offered
                            in order to be considered valid by Dynamic Pricing.
                          </div>
                        </Col>
                        <Col xs={4}>
                          <FormControl
                            defaultValue={settings.days_with_price}
                            type="number"
                            style={{ textAlign: "center" }}
                            onChange={(e) => {
                              const s = { ...settings };
                              s.days_with_price = parseInt(e.target.value);
                              setSettings(s);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5">
                        <Col xs={8}>
                          <h5 className={classes.preferenceTitle}>
                            Charm Pricing Style
                          </h5>
                          <div className={classes.preferenceDescription}>
                            The digits you want to end your price with. i.e.
                            7.99, 129.95
                          </div>
                        </Col>
                        <Col xs={4} className="text-end text-center">
                          <Form.Select
                            placeholder="select"
                            value={settings.end_digits}
                            style={{
                              textAlign: "center",
                              borderRadius: "25px",
                              fontSize: "14px",
                              padding: "1rem 3rem",
                              color: trellisPalette[14],
                            }}
                            onChange={(e) => {
                              const s = { ...settings };
                              s.end_digits = e.target.value;
                              setSettings(s);
                            }}
                          >
                            <option value="0.99">0.99</option>
                            <option value="0.98">0.98</option>
                            <option value="0.97">0.97</option>
                            <option value="0.96">0.96</option>
                            <option value="0.95">0.95</option>
                          </Form.Select>
                        </Col>
                      </Row>

                      <Row className="pt-5">
                        <Col xs={8}>
                          <h5 className={classes.preferenceTitle}>
                            Which Prices do you want to change
                          </h5>
                          <div className={classes.preferenceDescription}>
                            Do you want Trellis to change the Your Price and the
                            Sales Price?
                          </div>
                        </Col>
                        <Col xs={4} className="text-end text-center">
                          <Form.Select
                            placeholder="select"
                            value={settings.price_to_send}
                            style={{
                              textAlign: "center",
                              borderRadius: "25px",
                              fontSize: "14px",
                              padding: "1rem 3rem",
                              color: trellisPalette[14],
                            }}
                            onChange={(e) => {
                              const s = { ...settings };
                              s.price_to_send = e.target.value;
                              setSettings(s);
                            }}
                          >
                            <option value="0">Both Prices</option>
                            <option value="1">Your Price Only</option>
                            <option value="2">Sale Price Only</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row className="pt-5">
                        <Col xs={8}>
                          <h5 className={classes.preferenceTitle}>
                            Enable B2B Price Changes
                          </h5>
                          <div className={classes.preferenceDescription}>
                            Would you like to adjust your B2B pricing in
                            response to an increases or decreases?
                          </div>
                        </Col>
                        <Col xs={4} className="text-end text-center">
                          <Form.Select
                            placeholder="select"
                            value={settings.b2b_price}
                            style={{
                              textAlign: "center",
                              borderRadius: "25px",
                              fontSize: "14px",
                              padding: "1rem 3rem",
                              color: trellisPalette[14],
                            }}
                            onChange={(e) => {
                              const s = { ...settings };
                              s.b2b_price = parseInt(e.target.value);
                              setSettings(s);
                            }}
                          >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      {settings.b2b_price === 1 && (
                        <Row className="pt-5">
                          <Col xs={8}>
                            <h5 className={classes.preferenceTitle}>
                              B2B Price Reduction (%)
                            </h5>
                            <div className={classes.preferenceDescription}>
                              Percentage reduction do you wish to apply to your
                              B2B price
                            </div>
                          </Col>
                          <Col xs={4}>
                            <FormControl
                              defaultValue={
                                settings.b2b_price_modifier * -1 * 100
                              }
                              type="number"
                              style={{ textAlign: "center" }}
                              onChange={(e) => {
                                const s = { ...settings };
                                s.b2b_price_modifier = parseFloat(
                                  (e.target.value / 100) * -1
                                );
                                setSettings(s);
                              }}
                            />
                          </Col>
                        </Row>
                      )}

                      <Row className="pt-3">
                        <Col className="text-center">
                          <LogoutButton
                            key="CloseBtn"
                            title={`Save`}
                            disabled={disableSave}
                            onClick={() => saveSettings()}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
};

Settings.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
  user: state.user,
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(Settings))
);
