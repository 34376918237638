import React, { useState, useEffect } from "react";
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import { formatNumber } from "utils/formatNumber";
import axios from "axios";
import { getURLPrefix } from "utils/getUrlPrefix";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { EstUnits } from "./Tooltips";
import { Link } from "react-router-dom";

let URL_PREFIX = getURLPrefix();

const SideMenuExplorer = ({ quickTerm }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingQuickSearch, setLoadingQuickSearch] = useState(false);
  const [popularTerms, setPopularTerms] = useState(null);

  const fetchQuickSearch = async (keyword) => {
    setLoadingQuickSearch(true);
    const response = await axios.get(
      `${URL_PREFIX}/api/explorer/quick_search?q=${keyword}`
    );

    if (response.data) {
      setLoadingQuickSearch(false);

      const data = response.data;
      data.popular_keywords = data.popular_keywords.sort(function (a, b) {
        return b[1].estimated_units - a[1].estimated_units;
      });

      setPopularTerms(response.data.popular_keywords);
    }
  };

  useEffect(() => {
    if (quickTerm !== null && quickTerm.length > 0) {
      setLoadingQuickSearch(true);
      setSearchTerm(quickTerm);
      fetchQuickSearch(quickTerm);
    }
  }, [quickTerm]);

  return (
    <>
      <Row className="pt-4 pb-2">
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text
              style={{
                borderTopLeftRadius: "45px",
                borderBottomLeftRadius: "45px",
              }}
            >
              <i className="fa fa-search" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Enter a search term idea"
              aria-label="Search Term"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  setLoadingQuickSearch(true);
                  setSearchTerm(e.target.value);
                  fetchQuickSearch(e.target.value);
                }
              }}
              defaultValue={searchTerm}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col className="fs-4 px-3 pb-4">
          {!loadingQuickSearch && popularTerms !== null && (
            <Row>
              <Col>
                <strong>Term</strong>
              </Col>
              <Col className="text-end">
                <strong>Est. Units</strong>
                <EstUnits
                  searchTerm={searchTerm}
                  units={popularTerms[0][1]["estimated_units"]}
                  term={popularTerms[0][0]}
                />
              </Col>
            </Row>
          )}

          <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
            {!loadingQuickSearch && popularTerms !== null && (
              <p className="fs-5 pt-3 pb-2">
                Expand your search with the&nbsp;
                <Link
                  to={`/user/content/explore#q=${searchTerm}`}
                  className={`dropdown-menu-seo`}
                >
                  keyword explorer
                </Link>
              </p>
            )}
            {popularTerms !== null &&
              popularTerms.map((term, index) => {
                return (
                  <Row
                    key={index}
                    className="px-4 py-2"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#eee" : "#fff",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <Col xs={8}>{term[0]}</Col>
                    <Col xs={4} className="text-end">
                      {formatNumber(term[1]["estimated_units"])}
                    </Col>
                  </Row>
                );
              })}

            {!loadingQuickSearch && popularTerms === null && (
              <Row className="px-4 py-2">
                <Col>
                  <p className="fs-5 pt-3">
                    Start the explorer by giving us a search term to work with.
                    We'll give you some ideas.
                  </p>
                </Col>
              </Row>
            )}

            {loadingQuickSearch && (
              <Row className="px-4 py-2">
                <Col>
                  <p className="fs-3 pt-3 text-center">
                    <img
                      src={logo}
                      alt=""
                      className="rotate"
                      width="30"
                      height="auto"
                    />
                    <span className="ps-3">Compiling Results</span>
                  </p>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SideMenuExplorer;
