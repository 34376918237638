import React, { useState } from "react";
import CenteredModal from "components/core/basic/CenteredModal";
import Checkbox from "components/core/basic/CheckBox";
import { Row, Col, Badge } from "react-bootstrap";
import { snakeCaseToSentenceCase } from "utils/formatText";
import AssetOverflowTooltip from "./AssetOverflowTooltip";
import { CREATIVE_VALIDATIONS } from "../AdPlan/constants";
import { AD_TYPES } from "../AdPlan/constants";
import ToolTips from "utils/toolTips";

const AssetCard = ({
  asset,
  onAssetSelection,
  isVideo,
  setSelectedAsset,
  isSelected,
  allowExpandedModal,
  adType,
  assetTypeFilter,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showExpandedIcon, setShowExpandedIcon] = useState(false);

  const {
    asset_id,
    name,
    url_default,
    tags,
    moderation_request_status,
    file_meta_data,
    approved_programs,
    failed_specifications,
  } = asset;

  const { width, height, sizeInBytes } = file_meta_data;
  const validations = CREATIVE_VALIDATIONS[adType];

  const getEligibleStatus = () => {
    let assetValidations = [];

    if (["LIFESTYLE_IMAGE", "PRODUCT_IMAGE"].includes(assetTypeFilter)) {
      assetValidations = validations?.lifestyleImage;
    }
    if (["LOGO"].includes(assetTypeFilter)) {
      assetValidations = validations?.logo;
    }
    let { minHeight, minWidth, maxFileSize } = assetValidations ?? {};

    if (width < minWidth || height < minHeight) {
      return [false, "Invalid asset dimensions"];
    }

    if (sizeInBytes > maxFileSize) {
      return [false, "Maximum allowed file size exceeded"];
    }

    // For video assets, check approved programs sent back from amazon
    if (isVideo) {
      if (adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value) {
        return [
          approved_programs?.includes("SPONSORED_BRANDS_VIDEO"),
          failed_specifications
            ?.filter(
              (reason) => reason.specProgramName === "SPONSORED_BRANDS_VIDEO"
            )
            ?.map((reason) => reason.specifications)
            ?.flat()
            ?.map((spec) => spec.stringId),
        ];
      }

      if (adType === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
        return [
          approved_programs?.includes("SPONSORED_DISPLAY_VIDEO"),
          failed_specifications
            ?.filter(
              (reason) => reason.specProgramName === "SPONSORED_DISPLAY_VIDEO"
            )
            ?.map((reason) => reason.specifications)
            ?.flat()
            ?.map((spec) => spec.stringId),
        ];
      }
    }
    return [true, null];
  };

  const [isEligibleAsset, ineligibilityReasons] = getEligibleStatus();

  return (
    <>
      <Col xs={12} md={6} lg={3} className="p-2 asset-card">
        <div className="rounded-3 p-3 pb-5">
          <div
            onMouseEnter={() => {
              if (allowExpandedModal) {
                setShowExpandedIcon(true);
              }
            }}
            onMouseLeave={() => {
              if (allowExpandedModal) {
                setShowExpandedIcon(false);
              }
            }}
          >
            {isVideo ? (
              <video controls className="w-100 asset-card-img">
                <source src={url_default} type="video/mp4" />
              </video>
            ) : (
              <img
                src={url_default}
                alt={name}
                className="w-100 asset-card-img"
              />
            )}
            {showExpandedIcon && (
              <span
                onClick={() => {
                  setShowModal(true);
                }}
                className="zoom-icon"
              >
                <i className="fa fa-expand" />
              </span>
            )}
          </div>

          <Row className="d-flex justify-content-between align-items-center">
            <Col xs={12} sm={8} className="mt-3 text-start clamp-single-line">
              <AssetOverflowTooltip
                text={name}
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </Col>
            <Col xs={12} sm={4} className="text-end asset-card-checkbox">
              <Checkbox
                inline
                number={`assetId-${asset_id}`}
                label=""
                checked={isSelected}
                disabled={!isEligibleAsset}
                onClick={(e) => {
                  onAssetSelection(asset);
                }}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center px-3">
            {moderation_request_status &&
              moderation_request_status?.toLowerCase() !== "skipped" && (
                <Badge
                  className="badge bg-trellis-purple w-auto m-1"
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  {snakeCaseToSentenceCase(moderation_request_status)}
                </Badge>
              )}
            {tags.map((tag) => (
              <Badge
                className="badge bg-trellis-fuchsia w-auto m-1"
                style={{
                  borderRadius: "5px",
                }}
              >
                {snakeCaseToSentenceCase(tag)}
              </Badge>
            ))}
            {!isEligibleAsset && (
              <>
                <Badge
                  className="badge bg-trellis-purple w-auto m-1"
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  Ineligible
                </Badge>{" "}
                {ineligibilityReasons && (
                  <ToolTips
                    toolTip={
                      typeof ineligibilityReasons === "string"
                        ? ineligibilityReasons
                        : `${ineligibilityReasons?.join(", ")}.`
                    }
                    position="top"
                    id={"ASSET_INELIGIBILITY_REASON"}
                    iconContainerClasses="d-flex w-auto text-decoration-none px-1"
                  />
                )}
              </>
            )}
          </Row>
        </div>
      </Col>
      <CenteredModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        title={isVideo ? null : name}
        style={{ marginTop: "2rem" }}
        assetModal={true}
      >
        <Row
          className="d-flex justify-content-center align-content-center"
          style={{ height: isVideo ? "85vh" : "78vh" }}
        >
          <Col xs={12} className="h-100">
            {isVideo ? (
              <>
                <h4
                  className="title text-center mt-0"
                  style={{
                    marginBottom: "50px",
                    fontSize: "24px",
                    color: "#403E3D",
                    fontWeight: "700",
                  }}
                >
                  {name}
                </h4>
                <video controls className="w-100">
                  <source src={url_default} type="video/mp4" />
                </video>
              </>
            ) : (
              <img
                src={url_default}
                alt={name}
                className="w-100 h-100"
                style={{ objectFit: "contain" }}
              />
            )}
          </Col>
        </Row>
      </CenteredModal>
    </>
  );
};

export default AssetCard;
