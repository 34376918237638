import React from "react";
import { Row, Col } from "react-bootstrap";
import { useFetch } from "hooks/api";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import moment from "moment";
import ChartMiddleware from "components/custom/growth/ChartMiddleware";

const PlanProductChart = ({
  planId,
  marketPlace,
  plan: { execution_start, execution_end, state },
}) => {
  const EMPTY_ARRAY = [];

  const formatChartDataDates = () => {
    if (chartData?.data) {
      chartData.data.forEach((d) => {
        d.mDate = moment(d.start_date);
      });
      return chartData?.data?.map((d) => d.start_date);
    }
    return [];
  };

  const baseQuery = {
    marketplace: marketPlace.marketPlace,
    start: moment(execution_start).format("YYYY-MM-DD"),
    end: moment(execution_end).format("YYYY-MM-DD"),
  };

  const { isLoading, data: chartData } = useFetch(
    ["merchandise/plan_product_data", marketPlace],
    `/merchandise/plan_product_data/${planId}`,
    { ...baseQuery },
    {
      default: [],
    }
  );

  const chartOptions = {
    title: {
      text: null,
    },
    // This is blank to overwrite default options for performance chart
    plotOptions: {},
    xAxis: {
      categories: formatChartDataDates(),
      labels: {
        step: 2,
      },
    },
    yAxis: [
      {
        title: "Product Performance",
      },
    ],
    tooltip: {
      shared: true,
      pointFormat: "{series.name}: <b>${point.y:,.2f}</b><br/>",
    },
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          {isLoading ? (
            <div
              style={{
                minHeight: "430px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="Loading..."
                className={`rotate`}
                width="80"
              />
            </div>
          ) : (
            <ChartMiddleware
              data={chartData?.data ?? EMPTY_ARRAY}
              options={{
                options: chartOptions,
                series: [
                  "Units Ordered",
                  "Total Sales",
                  "Ad Spend",
                  "Ad Sales",
                ],
              }}
              yLabels={false}
              height={350}
              context={"merch-planning"}
              marketPlace={marketPlace.marketPlace}
              rollup={{ rollup: "daily" }}
              isChartDataLoading={isLoading}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PlanProductChart;
