import React, { useMemo, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { useSelector } from "react-redux";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import { buildDateColumn } from "views/merchandise/columns/buildDateColumn";

const UsersTable = ({
  tableData,
  agencyOptions,
  columnsToRender,
  isLoading,
  additionalColumns = [],
  bulkActionOptions = [],
  selectedUsers = [],
  setSelectedUsers = () => {},
  isSelectable = false,
  defaultSorted,
  additionalFilters,
}) => {
  const { marketPlace } = useSelector((state) => state);
  const tableRef = useRef();
  const TABLE_ID = "trellisAmazonProfilesTable";

  const columnOptions = [
    buildTextColumn("username", "Username"),
    buildTextColumn("email", "Email"),
    buildTextColumn("agencies", "Agencies", {
      formatter: (v) => v.map((agency) => agency.name)?.join(", "),
    }),
    buildTextColumn("is_staff", "Staff", {
      formatter: (v) => (v ? "Yes" : "No"),
    }),
    buildDateColumn("last_login", "Last Login"),
    ...additionalColumns,
  ];

  const columnSpecs = columnsToRender.map((renderColumnKey) =>
    columnOptions.find((option) => option.key === renderColumnKey)
  );

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);
  const agencyFilter = useMemo(() => {
    if (!columnsToRender.includes("agencies")) {
      return {};
    }
    return {
      label: "Agencies",
      id: "agencies",
      format: "select",
      selectOptions: [
        {
          value: "all",
          label: "All Users",
        },
        ...agencyOptions.flat(),
      ],
      override: true,
      defaultValue: "all",
      operator: "custom",
      filterFunction: (user, filterValue) => {
        if (filterValue === "all") {
          return true;
        }

        if (!user?.agencies) {
          return false;
        }

        if (
          user.agencies.some(
            (associatedAgency) => associatedAgency.id === filterValue
          )
        ) {
          return true;
        }
        return false;
      },
    };
  }, [agencyOptions, columnsToRender]);

  return (
    <Row className="mx-0 w-100">
      <Col xs={12} className="mx-auto px-0">
        <BulkActionsTable
          marketPlace={marketPlace}
          tableRef={tableRef}
          tableId={TABLE_ID}
          titleCol={{
            id: "name",
            isStatic: true,
            Header: "Name",
            accessor: "name",
            style: { width: "450px" },
            width: 300,
            Cell: ({ value, row }) => {
              const { first_name, last_name } = row._original;

              return (
                <>
                  <div className="d-flex align-items-center overflow-ellipsis">
                    {first_name} {last_name}
                  </div>
                </>
              );
            },
            filterMethod: (filter, row) => {
              return;
            },
            Filter: ({ filter, onChange }) => null,
          }}
          columnSpecs={columnSpecs}
          data={tableData}
          bulkActionOptions={bulkActionOptions}
          filterWidget={true}
          hideFilterToggleIcon={true}
          isSelectable={isSelectable}
          isExpandable={false}
          selected={selectedUsers}
          setSelected={setSelectedUsers}
          selectionKey={"id"}
          defaultSorted={defaultSorted || [{ id: "id", desc: false }]}
          {...filterProps}
          additionalFilterOptions={[agencyFilter]}
          excludedFilterColumns={["agencies", "is_staff"]}
          getSearchCriteria={(row) => {
            const { first_name, last_name, username, email } = row;
            return `${first_name} ${last_name} ${email} ${username}`.toLowerCase();
          }}
          tableRowDescription={"User"}
          minRows={0}
          isLoading={isLoading}
          defaultPageSize={5}
          additionalFilters={additionalFilters}
        />
      </Col>
    </Row>
  );
};

export default UsersTable;
