import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Box, Button, ThemeProvider } from "@material-ui/core";
import ImportDialog from "./ImportDialog";
import { themeLargerFont } from "../../../assets/themes/TrellisTheme";

const ImportButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [importType, setImportType] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const channelType = "channel";

  const reset = useCallback(() => {
    setIsOpen(false);
    setImportType(null);
    setSelectedOrg(null);
    props.setImportModalOpen && props.setImportModalOpen(false);
    props.setImportType && props.setImportType(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.defaultType && importType !== props.defaultType) {
    setIsOpen(props.defaultIsOpen);
    setImportType(props.defaultType);
  }

  return (
    <ThemeProvider theme={themeLargerFont}>
      <Box mb={3}>
        <Button
          color={"primary"}
          onClick={() => {
            const org = {
              id: props.user.organization_id,
            };
            setImportType(null);
            setIsOpen(true);
            setSelectedOrg(org);
            setImportType(channelType);
            props.setImportModalOpen && props.setImportModalOpen(true);
          }}
          variant={"contained"}
          fullWidth
        >
          Import Products
        </Button>

        {importType === "channel" && !selectedOrg ? (
          <ImportDialog
            isOpen={props.importModalOpen ?? true}
            onClose={reset}
            importType={importType}
            selectedOrg={selectedOrg}
          />
        ) : importType === "master" || selectedOrg ? (
          <ImportDialog
            isOpen={props.importModalOpen ?? true}
            onClose={reset}
            importType={importType}
            selectedOrg={selectedOrg}
          />
        ) : importType === "pricing_bulk_upload" || selectedOrg ? (
          <ImportDialog
            isOpen={props.importModalOpen ?? true}
            onClose={reset}
            importType={importType}
            selectedOrg={selectedOrg}
          />
        ) : null}
      </Box>
    </ThemeProvider>
  );
};

ImportButton.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
  channel: state.channel,
});

export default connect(mapStateToProps)(ImportButton);
