/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import CustomHref from "components/core/blocks/CustomHref";
import { HOMEPAGE } from "utils/redirectConstants";

class Footer extends Component {
  render() {
    return (
      <footer
        className={
          "footer" +
          (this.props.transparent !== undefined ? " footer-transparent" : "")
        }
        style={{ minHeight: "75px" }}
      >
        <div
          className={
            "container" + (this.props.fluid !== undefined ? "-fluid" : "")
          }
        >
          <p className="copyright pull-right">
            &copy; {1900 + new Date().getYear()}{" "}
            <a href={HOMEPAGE} target="_blank">
              Trellis Corporation
            </a>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
