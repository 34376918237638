import React from "react";

const Status = ({
  label,
  subLabel,
  color,
  showLabel = false,
  position = "end",
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: position,
      }}
    >
      <div
        style={{
          backgroundColor: color,
          borderRadius: "100%",
          width: "20px",
          height: "20px",
          paddingTop: "20px",
        }}
      ></div>
      {showLabel && (
        <div style={{ marginLeft: "1rem" }} className="text-start">
          <h6>{label}</h6>
          {subLabel && <small>{subLabel}</small>}
        </div>
      )}
    </div>
  );
};

export default Status;
