const ORG_TYPE_OPTIONS = [
  {
    value: "seller",
    label: "Seller",
  },
  {
    value: "vendor",
    label: "Vendor",
  },
];

const CHANNEL_OPTIONS = [
  {
    value: "amazon",
    label: "Amazon",
  },
  {
    value: "walmart",
    label: "Walmart",
  },
];

export const buildOrgMarketplacesFilter = (orgMarketplaceFilterOptions) => {
  return {
    label: "Marketplace",
    id: "market_place",
    format: "select",
    selectOptions: [
      {
        value: "all",
        label: "All Marketplaces",
      },
      ...orgMarketplaceFilterOptions,
    ],
    override: true,
    defaultValue: "all",
    operator: "custom",
    filterFunction: (org, filterValue) => {
      if (filterValue !== "all") {
        return org?.market_place === filterValue;
      }
      return true;
    },
  };
};

export const ORG_CHANNEL_FILTER = {
  label: "Channel",
  id: "org_type",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Channels",
    },
    ...CHANNEL_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (org, filterValue) => {
    if (filterValue !== "all") {
      return org?.org_type.toLowerCase() === filterValue.toLowerCase();
    }
    return true;
  },
};

export const ORG_TYPE_FILTER = {
  label: "Type",
  id: "profile_type",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Types",
    },
    ...ORG_TYPE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (org, filterValue) => {
    if (filterValue !== "all") {
      return org?.profile_type === filterValue;
    }
    return true;
  },
};
