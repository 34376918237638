import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { useFetch } from "hooks/api";
import Card from "components/core/basic/Card";
import { LogoutButton } from "components/core/basic/Button.jsx";
import axios from "axios";
import FileSaver from "file-saver";
import getURLPrefix from "utils/getUrlPrefix";
import AnalyticsStatsPanel from "components/custom/analytics/AnalyticsStatsPanel";
import AnalyticsOverviewChart from "components/custom/analytics/AnalyticsOverviewChart";

import ProductsCVTablePagination from "components/custom/growth/ProductsCVTablePagination";

import DatePicker from "react-datepicker";

import ReactTable from "react-table-6";

let URL_PREFIX = getURLPrefix();

const AnalyticsOverview = (props) => {
  const now = new Date();
  now.setDate(now.getDate() - 1);
  const ago = new Date();
  ago.setDate(ago.getDate() - 15);

  const [startDate, setStartDate] = useState(ago);
  const [endDate, setEndDate] = useState(now);
  const [filtered, setFiltered] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [filterState, setFilterState] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const onShelfClick = (row) => {
    props.shelfRedirect(row._original.id);
    props.marketplaceRedirect(row._original.marketplace);
  };

  // Why is this constantly sending requests?
  const { error: shelfOverviewError, data: shelfOverview } = useFetch(
    ["shelf_stats_overview", startDate.toString(), endDate.toString()],
    "/api/shelf_stats/",
    {
      stats_by: "shelf",
      end: endDate,
      start: startDate,
    }
  );

  const handleExport = async () => {
    let url = URL_PREFIX + "/api/shelf_stats/";

    let params = {
      end: endDate,
      start: startDate,
      report: true,
      stats_by: "shelf",
    };

    const response = await axios({
      method: "GET",
      url: url,
      responseType: "blob",
      params: params,
    });

    let blob = new Blob([response.data], {
      type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    FileSaver.saveAs(blob, "report.xlsx");
  };

  const processTableData = (data) => {
    let formattedData = [];

    for (const [key, value] of Object.entries(data)) {
      formattedData.push({
        id: key,
        name: value.page_1?.name || value.above_fold?.name,
        marketplace: value.page_1?.marketplace || value.above_fold?.marketplace,
        page_1_sos: value.page_1?.share_of_shelf || 0,
        page_1_sos_org: value.page_1?.org_share_of_shelf || 0,
        page_1_sov: value.page_1?.share_of_voice || 0,
        above_fold_sos: value.above_fold?.share_of_shelf || 0,
        above_fold_sos_org: value.above_fold?.org_share_of_shelf || 0,
        above_fold_sov: value.above_fold?.share_of_voice || 0,
        average_position: value.page_1?.position_avg || 0,
      });
    }
    if (searchFilter.length > 0) {
      return formattedData.filter(
        (row) =>
          row.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 ||
          row.marketplace.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
      );
    }

    return formattedData;
  };

  const cleanedData = useMemo(() => {
    if (shelfOverview?.data) {
      return processTableData(shelfOverview.data);
    }
    return [];
  }, [shelfOverview, searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatAsPercent = (num) => {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 100);
  };

  // TODO: Right align numbers
  const columns = [
    {
      Header: "Market Place",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "marketplace",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 100,
    },
    {
      Header: "Shelf",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "name",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: ({ value, row }) => (
        <span
          onClick={() => onShelfClick(row)}
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            textDecoration: "underline",
            color: "rgb(217, 18, 102)",
            cursor: "pointer",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      Header: "SOS",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "page_1_sos",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: (row) => {
        return formatAsPercent(row.value);
      },
    },
    {
      Header: "SOS Organic",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "page_1_sos_org",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: (row) => {
        return formatAsPercent(row.value);
      },
    },
    {
      Header: "SOV",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "page_1_sov",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: (row) => {
        return formatAsPercent(row.value);
      },
    },
    {
      Header: "Above the Fold SOS",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "above_fold_sos",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: (row) => {
        return formatAsPercent(row.value);
      },
    },
    {
      Header: "Above the Fold SOS Organic",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "above_fold_sos_org",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: (row) => {
        return formatAsPercent(row.value);
      },
    },
    {
      Header: "Above the Fold SOV",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "above_fold_sov",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: (row) => {
        return formatAsPercent(row.value);
      },
    },
    {
      Header: "Average Position",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      accessor: "average_position",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
      Cell: (row) => {
        return Math.round(row.value);
      },
    },
  ];

  const clearSearchTermFilters = () => {
    let currentFilters = filtered;
    let updatedFilters = currentFilters.filter(
      (filter) => filter.id !== "keyword"
    );

    setFiltered(updatedFilters);
  };

  return (
    <div>
      <Grid
        container
        style={{
          marginBottom: "-2rem",
          padding: "1rem 3rem",
        }}
      >
        <Grid
          container
          lg={10}
          style={{
            textAlign: "right",
            justifyContent: "flex-end",
            margin: "auto",
          }}
        >
          <span
            style={{
              paddingRight: "1rem",
              alignItems: "center",
              display: "inline-flex",
              fontSize: "14px",
            }}
          >
            Start Date:{" "}
          </span>
          <span style={{ paddingRight: "1rem" }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </span>
          <span
            style={{
              paddingRight: "1rem",
              alignItems: "center",
              display: "inline-flex",
              fontSize: "14px",
            }}
          >
            End Date:{" "}
          </span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </Grid>

        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <AnalyticsStatsPanel endDate={endDate} startDate={startDate} />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ margin: "auto", marginBottom: "16rem" }}
            lg={11}
          >
            <AnalyticsOverviewChart startDate={startDate} endDate={endDate} />
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          lg={10}
          style={{
            textAlign: "right",
            justifyContent: "flex-end",
            margin: "auto",
            marginBottom: "1rem",
          }}
        >
          <LogoutButton
            nowrap="true"
            // style={{ marginTop: "1rem" }}
            fontSize="12px"
            title={
              <span>
                <i className="fa fa-file-excel-o" /> Download Report
              </span>
            }
            onClick={handleExport}
          />
        </Grid>
        <Grid container item xs={12} lg={10} style={{ margin: "auto" }}>
          <Card
            content={
              <ReactTable
                data={cleanedData}
                getTheadFilterProps={(props) => {
                  if (clearFilters) {
                    props.filtered.forEach((filter, index) => {
                      filter.value = "all";
                    });
                    setClearFilters(false);
                  }
                  return { style: { display: "flex" } };
                }}
                columns={columns}
                defaultPageSize={20}
                // Below is needed to eliminate the no data message that covers table headers if no data to render
                NoDataComponent={() => null}
                minRows={0}
                showPaginationTop={true}
                showPaginationBottom={false}
                className="-striped -highlight"
                PaginationComponent={(props) => {
                  return (
                    <ProductsCVTablePagination
                      {...props}
                      updateSearch={setSearchFilter}
                      clearFilters={() => {
                        setFiltered([]);
                        setClearFilters(true);
                      }}
                      clearSearchTermFilters={clearSearchTermFilters}
                      searchTerm={searchFilter}
                      setFilterState={setFilterState}
                      filteredState={filtered}
                      table={"purchases"}
                      setShowFilters={setShowFilters}
                      showFilters={showFilters}
                      style={{ marginBottom: "2rem" }}
                    />
                  );
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

AnalyticsOverview.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
  user: state.user,
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AnalyticsOverview));
