import { LoginSocialAmazon } from "reactjs-social-login";
import { AMAZON_AUTH_APP_ID } from "utils/gvSecrets";

export const SocialButton = ({
  children,
  handleSocialAuthCreation,
  ...rest
}) => {
  return (
    <LoginSocialAmazon
      client_id={AMAZON_AUTH_APP_ID}
      redirect_uri={window.location.href}
      onResolve={({ provider, data }) => {
        handleSocialAuthCreation(data);
      }}
      onReject={(err) => {}}
    >
      <button {...rest}>{children}</button>
    </LoginSocialAmazon>
  );
};

