import { Grid } from "@material-ui/core";
import FormGroupTile from "components/core/basic/FormGroupTile";
import React from "react";

const SponsoredProductCampaignModifiers = ({
  state,
  onChange,
  classes,
  errors,
  fieldsToValidate,
  review,
  theme,
  bidStrategyOptions,
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        marginBottom: theme.spacing(0),
      }}
    >
      <Grid item xs={12}>
        <h3 className={classes.subtitle}>Amazon Options:</h3>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={5}
        className={classes.inputContainer}
        style={{
          paddingTop: theme.spacing(1),
        }}
      >
        <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
          <label
            style={{
              color: "#73706E",
              fontWeight: "600",
              paddingBottom: ".7rem",
            }}
          >
            Bid Strategy
          </label>
          <FormGroupTile
            type="select"
            stateName={"bidStrategy"}
            handleChildFormElement={(key, value) =>
              onChange({ [key]: value.value })
            }
            disabled={false}
            defaultValue={bidStrategyOptions.find(
              (strategy) => strategy.value === state.bidStrategy
            )}
            options={bidStrategyOptions}
          />
        </Grid>
        <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
          <label
            style={{
              color: "#73706E",
              fontWeight: "600",
              paddingBottom: ".7rem",
            }}
          >
            Top of Search
          </label>
          <FormGroupTile
            type="formcontrol"
            formControlType={"number"}
            prefix={"%"}
            prefixSide={"right"}
            stateName={"topOfSearchBidMultiplier"}
            handleChildFormElement={(key, value) => onChange({ [key]: value })}
            defaultValue={state.topOfSearchBidMultiplier}
          />
        </Grid>

        <Grid item xs={12} lg={6} style={{ padding: "2rem" }}>
          <label
            style={{
              color: "#73706E",
              fontWeight: "600",
              paddingBottom: ".7rem",
            }}
          >
            Product Pages
          </label>
          <FormGroupTile
            type="formcontrol"
            formControlType={"number"}
            prefix={"%"}
            prefixSide={"right"}
            stateName={"productPageMultiplier"}
            handleChildFormElement={(key, value) => {
              onChange({ [key]: value });
            }}
            defaultValue={state.productPageMultiplier}
          />
        </Grid>
        <Grid item xs={12} lg={6} style={{ padding: "2rem" }}>
          <label
            style={{
              color: "#73706E",
              fontWeight: "600",
              paddingBottom: ".7rem",
            }}
          >
            Rest of the Search
          </label>
          <FormGroupTile
            type="formcontrol"
            formControlType={"number"}
            prefix={"%"}
            prefixSide={"right"}
            stateName={"restOfSearchMultiplier"}
            handleChildFormElement={(key, value) => {
              onChange({ [key]: value });
            }}
            defaultValue={state.restOfSearchMultiplier}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: "0 2rem" }}>
          {errors.topOfSearchBidMultiplier &&
          fieldsToValidate?.includes("topOfSearchBidMultiplier") ? (
            <small className="text-danger" style={{ paddingTop: "1rem" }}>
              {errors.topOfSearchBidMultiplier}
            </small>
          ) : errors.productPageMultiplier &&
            fieldsToValidate?.includes("productPageMultiplier") ? (
            <small className="text-danger" style={{ paddingTop: "1rem" }}>
              {errors.productPageMultiplier}
            </small>
          ) : (
            errors.restOfSearchMultiplier &&
            fieldsToValidate?.includes("restOfSearchMultiplier") && (
              <small className="text-danger" style={{ paddingTop: "1rem" }}>
                {errors.restOfSearchMultiplier}
              </small>
            )
          )}
        </Grid>
      </Grid>
      <Grid xs={12} md={7}>
        <p
          className={`${classes.explanationText} ${
            review && classes.reviewExplanationText
          }`}
          style={{ paddingTop: "2rem" }}
        >
          Let Amazon automatically bid more when doing so will bump your product
          to a relevant product page's top spot.
        </p>
      </Grid>
    </Grid>
  );
};
export default SponsoredProductCampaignModifiers;
