import React from "react";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";

import { useSelector } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import { useMediaQuery } from "@react-hook/media-query";
import ProductAdsTable from "./ProductAdsTable";
import { AdvertisementCell } from "views/AdvertisementsTable";

const ProductAds = ({ productId, marketPlace, isPricing = false }) => {
  const { group, period, compare, date, start, end, preStart, preEnd } =
    useDates();
  const state = useSelector((state) => state);
  const { channel } = state;
  const modules = selectModules(state);

  const smallViewportWidthTable = useMediaQuery("(max-width:1550px)");

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    product_id: productId,
    group_by: "advertisement_id",
    channel: channel?.currentChannel ?? "amazon",
  };

  const { data: adProductsStats, isLoading } = useFetch(
    [
      "productAdStatsEmpty",
      productId,
      marketPlace,
      group,
      start,
      end,
      preStart,
      preEnd,
    ],
    "/api/data_report/product_ad_report",
    filters,
    {
      select: (data) => {
        let product_ads = data?.data?.product_ads;
        if (product_ads) {
          product_ads = product_ads.map((d) => ({
            ...d,
            ad_id: d.gvadvertisement_id,
            ad_name: d.gvadvertisement__name,
            ad_type: d.gvadvertisement__ad_type,
            ad_goal: d.gvadvertisement__ad_goal,
            moderation_status: d.gvadvertisement__moderation_status,
            ad_status: d.gvadvertisement__ad_status,
            state: d.gvadvertisement__state,
          }));
          return { product_ads };
        }
        return product_ads;
      },
      // This endpoint can be expensive depending on the rollup period, caching for 1hr currently
      staleTime: 5 * 60 * 1000,
      refetchOnMount: false,
    }
  );

  return (
    <ProductAdsTable
      tableEntityType={"productAd"}
      marketPlace={marketPlace}
      adProductsStats={adProductsStats}
      isLoading={isLoading}
      isSelectable={true}
      productId={productId}
      selectionKey="ad_id"
      noDataText="No advertisements found for this product"
      productDetailsAdTable={true}
      isPricing={isPricing}
      titleCol={{
        id: "advertisement",
        isStatic: true,
        Header: "Advertisement",
        accessor: "ad_name",
        style: { width: smallViewportWidthTable ? "300px" : "600px" },
        width: smallViewportWidthTable ? 300 : 600,
        Cell: ({ value, row }) => {
          return (
            <AdvertisementCell
              value={value}
              row={row}
              original={{
                ...row._original,
                id: row._original?.product_id,
              }}
              modules={modules}
            />
          );
        },
        filterMethod: (filter, row) => {
          return;
        },
        Filter: ({ filter, onChange }) => null,
      }}
    />
  );
};

export default ProductAds;
