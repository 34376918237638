import React from "react";
import { Row, Col } from "react-bootstrap";

const Dates = ({ saveDate, publishDate }) => {
  return (
    <>
      {saveDate && (
        <Row className="fs-4">
          <Col xs={12}>
            <div className="fs-4" style={{ fontWeight: "bold" }}>
              Saved Date
            </div>
          </Col>
          <Col xs={12} className="pb-2">
            {saveDate}
          </Col>
        </Row>
      )}

      {publishDate && (
        <Row className="fs-4">
          <Col xs={12}>
            <div className="fs-4" style={{ fontWeight: "bold " }}>
              Publish Date
            </div>
          </Col>
          <Col xs={12} className="pb-2">
            {publishDate}
          </Col>
        </Row>
      )}
    </>
  );
};

export default Dates;
