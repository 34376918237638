import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { useQueryClient } from "react-query";
import api from "utils/api";

const PricingPlanChoice = ({
  plans,
  setShowPlanChoice,
  showPlanChoice,
  productById,
  selectedProducts,
  setSelectedProducts,
  marketPlace,
}) => {
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [plan, setPlan] = useState(null);
  const [showAdding, setShowAdding] = useState(false);

  const queryClient = useQueryClient();

  const addProductsToPlan = async (plan) => {
    if (!plan) {
      return;
    }

    setShowAdding(true);
    const products = await getProducts(plan.id);
    let productList = products.map((product) => product.product.id);
    productList = productList.concat(selectedProducts);

    const rules = plan.competitor_rules;
    let competitorValue = null;
    let competitorUnit = null;
    let competitorAsin = null;
    let competitorAction = null;

    if (rules.length > 0) {
      competitorValue = rules[0].value;
      competitorUnit = rules[0].unit;
      competitorAsin = rules[0].asin;
      competitorAction = rules[0].action;
    }

    const payload = {
      plan_id: plan.id,
      name: plan.plan_name,
      plan_type: plan.plan_type,
      target_bsr: plan.target_bsr,
      min_price: plan.min_price,
      max_price: plan.max_price,
      plan_mode: plan.configured_slider_value,
      react_buy_box: false,
      market_place: marketPlace.marketPlace,
      num_parents: 1,
      product_list: productList.join(","),
      category_id: plan.category,
      profiling: plan.price_profiling,
      competitor_value: competitorValue,
      competitor_unit: competitorUnit,
      competitor_action: competitorAction,
      competitor_asin: competitorAsin,
    };

    await api.post("/pricing/pricingplan", { plan_options: payload });

    queryClient.invalidateQueries("product_report");
    setSelectedProducts([]);

    document.location.reload();
  };

  const getProducts = async (planId) => {
    const { data } = await api.get(
      `/pricing/pricing_plan_products?pricing_plan_id=${planId}`
    );

    return data;
  };
  const getPlan = async (planId) => {
    const { data } = await api.get(`/pricing/pricingplans/${planId}/`);
    return data;
  };

  useEffect(() => {
    setSelectedPlans([]);
    setPlan(null);
    setShowAdding(false);
  }, [showPlanChoice]);

  useEffect(() => {
    if (selectedPlans.length === 1) {
      getPlan(selectedPlans[0]).then((plan) => {
        setPlan(plan);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans]);

  return (
    <Modal show={showPlanChoice} size="lg">
      <Modal.Header className="py-0 px-5">
        <Row style={{ width: "100%" }}>
          <Col md={6}>
            <div className="fs-3 my-4 text-start">
              Add Product to Existing Plan
            </div>
          </Col>
          <Col md={6} className="text-end"></Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="py-2">
          {showAdding && (
            <Col md={{ span: 8, offset: 2 }}>
              <div className=" my-4 text-center fs-4 fw-bold">
                <div>The products are being added to: {plan.plan_name}</div>
              </div>
            </Col>
          )}
          {!showAdding && (
            <Col md={{ span: 8, offset: 2 }}>
              <div className="fs-3 my-4 text-center">
                <div>Choose the Pricing Plan to add these products into</div>
                <small>
                  {selectedProducts.slice(0, 5).map((id, i) => (
                    <span key={i} className="pe-3">
                      {productById[id].sku}
                    </span>
                  ))}
                  {selectedProducts.length > 5 && (
                    <span> plus {selectedProducts.length - 5} more</span>
                  )}
                </small>
              </div>

              <Row className="fs-4 text-start pt-1 pb-3">
                <Col>
                  <Row className="pb-2">
                    <Col xs={1}>&nbsp;</Col>
                    <Col>
                      <span className="fw-bold">Plan Name</span>
                    </Col>
                  </Row>
                  {plans?.map((plan, i) => (
                    <Row className="pb-2" key={i}>
                      <Col xs={1}>
                        <input
                          type="checkbox"
                          checked={selectedPlans.includes(plan.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedPlans([plan.id]);
                            } else {
                              setSelectedPlans(
                                selectedPlans.filter((id) => id !== plan.id)
                              );
                            }
                          }}
                        />
                      </Col>
                      <Col>{plan.plan_name}</Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row style={{ width: "100%" }}>
          <Col xs={3}>&nbsp;</Col>
          <Col xs={3} className="mx-auto text-center">
            <LogoutButton
              key="CloseBtn"
              title={`Close`}
              onClick={() => {
                setShowPlanChoice(false);
              }}
            />
          </Col>
          <Col xs={3} className="mx-auto text-center">
            <LogoutButton
              key="SaveBtn"
              title={"Add to Plan"}
              onClick={() => {
                addProductsToPlan(plan);
              }}
              disabled={
                selectedPlans.length === 0 ||
                !plan ||
                plan.id !== selectedPlans[0] ||
                showAdding
              }
            />
          </Col>
          <Col xs={3}>&nbsp;</Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default PricingPlanChoice;
