import { useMemo } from "react";
import { useLocation, useHistory } from "react-router";

const useQueryParams = (name, defaultState = null) => {
  const location = useLocation();
  const history = useHistory();
  return useMemo(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const value = params.get(name) || defaultState;
    const setValue = (newValue) => {
      if (newValue === null || newValue === defaultState) {
        params.delete(name);
      } else {
        params.set(name, newValue);
      }
      history.replace({
        pathname: location.pathname,
        search: `?${params.toString()}`,
      });
    };
    return [value, setValue];
  }, [name, defaultState, location, history]);
};

export default useQueryParams;
