import React, { useState } from "react";
import jmespath from "jmespath";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import { trellisPalette } from "components/custom/analytics/palettes";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import HiddenToolTip from "utils/hiddenToolTips";
import { getURLPrefix } from "utils/getUrlPrefix";
import NoImage from "assets/images/utility/no_image.png";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import { Link } from "react-router-dom";
import generateLink from "utils/generateLink";
import moment from "moment";
import ExternalProductLink from "./ExternalProductLink";

let URL_PREFIX = getURLPrefix();

const MediaPlanCell = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <div>{props?.value}</div>
    </div>
  );
};

const ProductCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const productId = props?.original?.product_id;
  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?product=${productId}`;
  return (
    <div style={{ display: "flex" }}>
      <div
        onError={(e) => {
          setHasError(true);
        }}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div>
        {props?.value}
        <br />
        <i style={{ fontWeight: "light", color: "#666", fontSize: "1rem" }}>
          {props?.original.asin} ({props?.original.sku}){" "}
          <ExternalProductLink asin={props?.original.asin} />
        </i>
      </div>
    </div>
  );
};

const CategoryCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const categoryId = props?.original?.category_id;
  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?category=${categoryId}`;
  return (
    <div style={{ display: "flex" }}>
      <div
        onError={(e) => {
          setHasError(true);
        }}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div>{props?.value}</div>
    </div>
  );
};

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const tableLink = (link, linkText) => {
  if (typeof link === "object") {
    const {
      type,
      details,
      handleClick,
      productIdentifier,
      styles,
      openInNewTab,
      customText,
    } = link ?? {};

    return (
      <>
        {generateLink(
          type,
          details,
          handleClick,
          productIdentifier,
          styles,
          openInNewTab,
          customText
        )}
      </>
    );
  }

  if (typeof link === "string") {
    return <Link to={link}>{linkText}</Link>;
  }
  return;
};

// TODO remove the need for a categoryId prop once we have removed the old routing system
const makeColumn =
  (data, marketPlace, mobileStyle, date, setIsExpanded, categoryId) =>
  (header, key, type, options) => {
    const defaultAccessor = (p) => jmespath.search(p, key);
    const accessor = options?.accessor ?? defaultAccessor;
    const defaultFormat = ({ value }) => value;
    let fmt =
      options?.formatter ??
      {
        expander: ({ row, value }) => (
          <ExpanderCell
            row={row}
            value={row?._original?.product_id}
            isExpanded={value}
            setIsExpanded={setIsExpanded}
          />
        ),
        currency: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
        number: ({ value }) => formatNumber(value, {}, 1),
        percent: ({ value }) => formatPercent(value),
        date: ({ value }) =>
          value == null ? "" : moment(value).format("MMM Do, YY"),
        category: CategoryCell,
        product: ProductCell,
        advertisement: MediaPlanCell,
        percentDiff: ({ value }) => (
          <span
            style={{
              color: value > 0 ? trellisPalette[12] : trellisPalette[17],
            }}
          >
            {formatPercent(value, 0, true)}
          </span>
        ),
      }?.[type] ??
      defaultFormat;
    const parser =
      {
        currency: parseFormattedCurrency,
        number: parseFormattedNumber,
        percent: parseFormattedPercentage,
      }?.[type] ?? defaultFormat;

    let width;
    if (mobileStyle) {
      width = options?.mobileWidth;
    } else {
      width = options?.width;
    }

    return {
      Header: header,
      id: key,
      accessor,
      maxWidth: options?.maxWidth,
      minWidth: options?.minWidth,
      width,
      height: 30,
      className: options?.className ?? "text-end",
      headerClassName: options?.className ?? "text-end",
      Cell: (props) => {
        if (options?.showDot ?? true) {
          const percentKey = `${key}_percent`;
          const percent = props.original?.[percentKey] ?? null;
          const deltaKey = `${key}_delta`;
          const deltaValue = props.original?.[deltaKey];
          const isNegative = options?.isNegative ?? false;
          const noColor = options?.noColor ?? false;
          let link = options?.link ?? null;
          let linkText = options?.linkText ?? null;
          let color = "#666";
          if (!noColor && percent > 0) {
            color = isNegative ? trellisPalette[17] : trellisPalette[12];
          }
          if (!noColor && percent < 0) {
            color = isNegative ? trellisPalette[12] : trellisPalette[17];
          }

          if (options?.linkTextAccessor) {
            linkText = props.original?.[options?.linkTextAccessor];
          }

          if (options?.linkIdAccessor && typeof link === "string") {
            link = `${link}${props.original[options?.linkIdAccessor]}`;
          }

          return (
            <div>
              <HiddenToolTip
                toolTip={deltaValue > 0 ? "+" : "" + fmt({ value: deltaValue })}
                position="top"
              />
              {link
                ? tableLink(
                    // Handle location prop/state based links with util
                    typeof link === "object"
                      ? {
                          ...link,
                          details: {
                            ...props.original,
                            category: categoryId,
                            // Below is a bit confusing, but the name stored in props is actually a string on the ad id, needed to send in location state
                            id: Number.parseInt(props.original.name),
                          },
                          customText:
                            link.customText ??
                            props.original?.[link.textAccessor] ??
                            null,
                        }
                      : // Simple URL link
                        link,
                    linkText
                  )
                : fmt({ ...props, date })}

              <br />
              <span
                style={{
                  color,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                {percent === null ? "" : formatPercent(percent, 0, true)}
              </span>
            </div>
          );
        }
        return fmt({ ...props, date });
      },

      style: { height: "60px" },
      filterMethod: (filter, row) => {
        const value = accessor(row._original);
        return filterRanges(filter, value, parser, marketPlace.marketPlace);
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(filter, onChange, key, "range", type, {
          ranges: generateRanges(
            data ?? [],
            5,
            key,
            type,
            marketPlace.marketPlace
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

export { makeColumn, col };
