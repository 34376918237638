import React from "react";
import { Row, Col } from "react-bootstrap";
import DialogueCard from "./DialogueCard";
import { useHistory } from "react-router-dom";
import SkipToDashboardLink from "./SkipToDashboardLink";
import { useDispatch } from "react-redux";
import { setChannel } from "redux/actions/channel";

const CHANNEL_OPTIONS = [
  {
    value: "amazon",
    title: "Amazon",
    description: null,
  },
  {
    value: "walmart",
    title: "Walmart",
    description: null,
  },
];

const Channel = ({ onboardingData, setOnboardingData }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Row className="my-5">
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        <h2 className="mt-5 mb-1 fw-600">Connect To Your Marketplaces</h2>
        <p className="fs-4">
          Connect the channels you want to optimize. You will be able to add
          more after you onboard.
        </p>
        <h4 className="fw-500">Select Your Channels</h4>
      </Col>
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        {CHANNEL_OPTIONS.map((option) => (
          <DialogueCard
            contents={option}
            justify="center"
            additionalClasses="my-5"
            onClick={(value) => {
              setOnboardingData({ ...onboardingData, channel: value });
              dispatch(setChannel(value.toLowerCase()));
              localStorage.channel = value.toLowerCase();

              if (value === "walmart") {
                history.push(`/onboarding/v2/sellertype`);
                return;
              }

              history.push(`/onboarding/v2/connect?channel=${value}`);
            }}
          />
        ))}
        <SkipToDashboardLink />
      </Col>
    </Row>
  );
};

export default Channel;
