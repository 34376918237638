import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown, SplitButton } from "react-bootstrap";
import { formatCurrency } from "utils/formatNumber";
import HiddenToolTip from "utils/hiddenToolTips";
import { trellisPalette } from "components/custom/analytics/palettes";
import ListingDetails from "../ListingDetails";
import AddCompetitor from "./AddCompetitor";

const ProductBox = ({
  item,
  orgASINs,
  rankInfo,
  targetedASINs,
  competitiveASINs,
  marketPlace,
  setIsLoading,
  debounceSearchTerm,
}) => {
  const [thisItem, setThisItem] = useState(null);
  const [showContentDetails, setShowContentDetails] = useState(false);
  const [showAddCompetitor, setShowAddCompetitor] = useState(false);

  useEffect(() => {
    if (item) {
      setThisItem(item);
    }
  }, [item]);

  if (!thisItem) {
    return null;
  }

  const getTitle = (title) => {
    if (!debounceSearchTerm) {
      return title;
    }

    const truncTitle = title.substring(0, 80);

    const qMono = debounceSearchTerm.split(" ");
    const titleMonoLower = truncTitle.toLowerCase().split(" ");
    const titleMono = truncTitle.split(" ");
    const markedTitle = [];
    for (let i = 0; i < titleMonoLower.length; i++) {
      const tml = titleMonoLower[i].replace(
        new RegExp("[^a-zA-Z0-9 ]", "g"),
        ""
      );
      if (qMono.indexOf(tml) > -1) {
        markedTitle.push(
          <span style={{ color: trellisPalette[0], fontWeight: 600 }} key={i}>
            {titleMono[i]}{" "}
          </span>
        );
      } else {
        markedTitle.push(
          <span style={{ opacity: 0.85 }} key={i}>
            {titleMono[i]}{" "}
          </span>
        );
      }
    }

    return markedTitle;
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "520px",
          padding: "10px",
          borderRadius: "5px",
          border:
            orgASINs.indexOf(thisItem.asin) > -1
              ? "#6e548a 1px solid"
              : "lightgrey 1px solid",
          fontSize: "14px",
          position: "relative",
        }}
      >
        <div
          className="text-center pt-3"
          style={{
            position: "absolute",
            bottom: "15px",
            width: "100%",
          }}
        >
          <SplitButton
            title={`More Details`}
            onClick={() => {
              setShowContentDetails(true);
            }}
            bsPrefix="dropdown-menu-details"
          >
            <Dropdown.Item
              eventKey="1"
              onClick={() => setShowContentDetails(true)}
            >
              Estimate Sales
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => {
                setShowAddCompetitor(true);
              }}
              disabled={
                competitiveASINs.indexOf(thisItem.asin) > -1 ? true : false
              }
            >
              Add as a Competitor
            </Dropdown.Item>
          </SplitButton>
        </div>
        <a
          href={thisItem.link}
          alt={thisItem.asin}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={thisItem.image}
            alt={thisItem.title}
            style={{
              maxWidth: "100%",
              maxHeight: "300px",
              minHeight: "300px",
              display: "flex",
            }}
          />
        </a>

        <div
          style={{
            marginTop: "10px",
            fontSize: "16px",
            textAlign: "left",
            maxHeight: "80px",
            minHeight: "60px",
          }}
        >
          <a
            href={thisItem.link}
            alt={thisItem.asin}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            {getTitle(thisItem.title)}
            {thisItem.title.length >= 80 && "..."}
          </a>
        </div>

        <Row>
          <Col xs={5}>
            <span style={{ fontSize: "18px", fontWeight: "600" }}>
              {thisItem.price
                ? formatCurrency(thisItem.price.value, marketPlace.marketPlace)
                : "N/A"}
            </span>

            {thisItem && thisItem.prices && thisItem.prices.length > 1 && (
              <span className="text-decoration-line-through ps-3 text-muted">
                {formatCurrency(
                  thisItem.prices[1].value,
                  marketPlace.marketPlace
                )}
              </span>
            )}
          </Col>
          {thisItem.coupon && (
            <Col className="float-right" xs={1}>
              <HiddenToolTip
                toolTip={`Coupon: ${thisItem.coupon.badge_text}`}
                position={"top"}
                id={`coupon-tt`}
              />
              <span className="ps-2">
                <i
                  className="fa fa-tag"
                  style={{ color: trellisPalette[10] }}
                ></i>
              </span>
            </Col>
          )}

          {thisItem.sponsored && (
            <Col className="float-right" xs={1}>
              <HiddenToolTip
                toolTip={`Sponsored Product`}
                position={"top"}
                id={`sponsor-tt`}
              />
              <span className="ps-2">
                <i
                  className="fa fa-money"
                  style={{ color: trellisPalette[10] }}
                ></i>
              </span>
            </Col>
          )}
          {targetedASINs[thisItem.asin] && (
            <Col className="float-right" xs={1}>
              <HiddenToolTip
                toolTip={`ASIN Targeted by ${
                  targetedASINs[thisItem.asin]["name"]
                }`}
                position={"top"}
                id={`competitive-tt`}
              />
              <span className="ps-2">
                <i
                  className="fa fa-bullseye"
                  style={{ color: trellisPalette[10] }}
                ></i>
              </span>
            </Col>
          )}
          {competitiveASINs.indexOf(thisItem.asin) > -1 && (
            <Col className="float-right" xs={1}>
              <HiddenToolTip
                toolTip={`ASIN competitive`}
                position={"top"}
                id={`sponsor-tt`}
              />
              <span className="ps-2">
                <i
                  className="fa fa-shopping-cart"
                  style={{ color: trellisPalette[10] }}
                ></i>
              </span>
            </Col>
          )}
        </Row>

        <Row className="pb-2">
          <Col xs={12}>
            <span
              style={{
                fontWeight: "600",
              }}
            >
              {rankInfo[thisItem.asin]
                ? rankInfo[thisItem.asin]["brand"]
                : "n/a"}
            </span>
            <span className="text-muted">
              {" - "}
              {thisItem.asin}
            </span>
          </Col>
        </Row>

        {rankInfo[thisItem.asin] && (
          <Row>
            <Col xs={6} className="text-left">
              {rankInfo[thisItem.asin] && (
                <>
                  <strong>
                    {rankInfo[thisItem.asin]["displayGroupRanks"]["title"]}
                  </strong>
                  <div>
                    #{rankInfo[thisItem.asin]["displayGroupRanks"]["rank"]}
                  </div>
                </>
              )}
            </Col>
            <Col xs={6} className="text-left">
              {rankInfo[thisItem.asin] && (
                <>
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>
                      {rankInfo[thisItem.asin]["classificationRanks"]["title"]}
                    </strong>
                  </div>
                  <div>
                    #{rankInfo[thisItem.asin]["classificationRanks"]["rank"]}
                  </div>
                </>
              )}
            </Col>
          </Row>
        )}
        <Row className="pb-2 mb-5">
          <Col xs={6} className="text-left">
            {!thisItem.rating && <strong>Not Rated</strong>}
            {thisItem.rating &&
              [1, 2, 3, 4, 5].map((star, ir) => {
                return (
                  <span
                    className="fa fa-star"
                    key={ir}
                    style={{
                      color:
                        star <= parseInt(thisItem.rating) ? "orange" : "#ccc",
                    }}
                  ></span>
                );
              })}
            {thisItem.rating && (
              <span>
                (<strong>{thisItem.rating}</strong>){" "}
                <strong>{thisItem.ratings_total}</strong>
                &nbsp;ratings
              </span>
            )}
          </Col>
          <Col xs={6} className="text-left">
            {rankInfo[thisItem.asin] && (
              <>
                <strong>Estimated Sales</strong>
                <div>
                  {formatCurrency(
                    rankInfo[thisItem.asin]["estimated_sales"],
                    marketPlace.marketPlace
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
      <ListingDetails
        productID={null}
        item={thisItem}
        marketPlace={marketPlace.marketPlace}
        setShowContentDetails={setShowContentDetails}
        showContentDetails={showContentDetails}
      />

      <AddCompetitor
        asin={thisItem ? thisItem.asin : null}
        showAddCompetitor={showAddCompetitor}
        setShowAddCompetitor={setShowAddCompetitor}
        marketplace={marketPlace.marketPlace}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default ProductBox;
