import React, { useState, useMemo } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { useFetch } from "hooks/api";
import moment from "moment";
import { SEARCH_AMAZON_LINKS } from "utils/marketplaceConstants";
import { HiExternalLink } from "react-icons/hi";
import { formatNumber, formatCurrency } from "utils/formatNumber";
import AddKeywordModal from "modules/seo_performance/AddKeywordModal";
import { LogoutButton } from "components/core/basic/Button";
import { useDates } from "dates/useDates";
import { trellisPalette } from "components/custom/analytics/palettes";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import TrComponent from "components/custom/merchandise/TrComponent";
import useColumnSelector from "hooks/useColumnSelector";
import ToolTips from "utils/toolTips";
import Loading from "components/core/blocks/Loading";

const ASINTable = ({ marketPlace, asin, showAddKWButon, planId }) => {
  const [showAddKWModal, setShowAddKWModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [filters, setFilters] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);

  const { group, start, end, preStart, preEnd } = useDates();

  const queryFilters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date:
      start !== undefined
        ? start.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    end_date:
      end !== undefined
        ? end.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    pre_start_date:
      preStart !== undefined
        ? preStart.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    pre_end_date:
      preEnd !== undefined
        ? preEnd.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    empty: true,
    group_by: "product_id",
    plan_id: planId,
  };

  const { data: productData, isLoading: productLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      planId,
      queryFilters.group_by,
    ],
    "/api/data_report/product_report",
    queryFilters,
    {
      select: (d) => d.data,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!planId,
    }
  );

  const asinList = useMemo(() => {
    if (asin) return [asin];

    if (productLoading) return [];

    const asinList = [];
    for (let i = 0; i < productData?.products?.length; i++) {
      const product = productData?.products[i];
      asinList.push(product.asin);
    }
    return asinList;
  }, [asin, productData, productLoading]);

  const { isLoading, data, isSuccess } = useFetch(
    ["asin_table", marketPlace.marketPlace, asinList],
    "/merchandise/organic_keywords",
    { asin: asinList.join(","), marketplace: marketPlace.marketPlace },
    {
      default: [],
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      select: (res) => res.data,
      enabled: asinList.length > 0,
    }
  );

  const tableData = useMemo(() => {
    if (!data) return [];

    const tableData = [];

    for (let i = 0; i < data?.results?.length; i++) {
      const res = data?.results[i];
      const meta = res.meta;
      const competitors = meta.competitors;
      let minPrice = null;
      let maxPrice = null;
      for (let j = 0; j < competitors?.length; j++) {
        const competitor = competitors[j];
        const price = competitor.price;
        if (price === null || price === undefined) {
          continue;
        }
        if (minPrice === null || price < minPrice) {
          minPrice = price;
        }
        if (maxPrice === null || price > maxPrice) {
          maxPrice = price;
        }
      }
      res.min_price = minPrice;
      res.max_price = maxPrice;
      tableData.push(res);
    }

    // sort by updated_on
    tableData.sort((a, b) => {
      return moment(b.updated_on).diff(moment(a.updated_on));
    });

    return tableData;
  }, [data]);

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let columns = [
        {
          id: "keyword",
          isStatic: true,
          Header: "Search Term",
          accessor: "keyword",
          style: { width: "300px" },
          Cell({ value, row }) {
            return (
              <>
                <div>
                  {value?.keyword_text}
                  &nbsp;
                  <a
                    href={
                      SEARCH_AMAZON_LINKS?.[marketPlace.marketPlace] +
                      value?.keyword_text
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View on Amazon"
                  >
                    <HiExternalLink />
                  </a>
                </div>
                {row._original?.keyword?.branded && (
                  <div>
                    <Badge
                      className={`badge bg-trellis-purple text-end`}
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      Branded
                    </Badge>
                  </div>
                )}
              </>
            );
          },
        },
        {
          id: "rank",
          isStatic: false,
          Header: "Position",
          accessor: "rank",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value + 1, {}, 0)}</div>
          ),
        },
        {
          id: "updated_on",
          isStatic: false,
          Header: "Last Found",
          accessor: "updated_on",
          checked: true,
          Cell: ({ value }) => <Age value={value} />,
        },
        {
          id: "min_price",
          isStatic: false,
          Header: "Min Price",
          accessor: "min_price",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">
              {value ? formatCurrency(value, marketPlace.marketPlace) : "-"}
            </div>
          ),
        },
        {
          id: "max_price",
          isStatic: false,
          Header: "Max Price",
          accessor: "max_price",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">
              {value ? formatCurrency(value, marketPlace.marketPlace) : "-"}
            </div>
          ),
        },
        {
          id: "num_results",
          isStatic: false,
          Header: "Est. Search Results",
          accessor: "num_results",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value)}</div>
          ),
        },
      ];

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData]
  );

  const createPriceBucketsWithoutOutliers = (prices) => {
    const quartile = (arr, q) => {
      const sorted = arr.slice().sort((a, b) => a - b);
      const pos = (sorted.length - 1) * q;
      const base = Math.floor(pos);
      const rest = pos - base;
      if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
      } else {
        return sorted[base];
      }
    };

    const q1 = quartile(prices, 0.25);
    const q3 = quartile(prices, 0.75);
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    // Filter out outliers
    const filteredPrices = prices.filter(
      (price) => price >= lowerBound && price <= upperBound
    );

    // Proceed with bucket creation
    const minPrice = Math.min(...filteredPrices);
    const maxPrice = Math.max(...filteredPrices);
    const range = maxPrice - minPrice;
    const bucketSize = range / 5;
    let buckets = Array.from({ length: 5 }, () => ({ name: "", y: 0 }));

    filteredPrices.forEach((price) => {
      let bucketIndex = Math.min(
        Math.floor((price - minPrice) / bucketSize),
        4
      ); // Ensure max value falls in last bucket
      buckets[bucketIndex].y++;
    });

    for (let i = 0; i < buckets.length; i++) {
      let lowerBound = minPrice + i * bucketSize;
      let upperBound = i < 4 ? lowerBound + bucketSize : maxPrice;
      buckets[i].name = `${lowerBound.toFixed(2)} - ${upperBound.toFixed(2)}`;
      buckets[i].color = trellisPalette[i];
    }

    return buckets;
  };

  const pricePieOptions = useMemo(() => {
    if (!data) return [];

    const prices = [];
    for (let i = 0; i < data?.results?.length; i++) {
      const res = data?.results[i];
      const meta = res.meta;
      const competitors = meta.competitors;
      for (let j = 0; j < competitors?.length; j++) {
        const competitor = competitors[j];
        const price = competitor.price;
        if (price === null || price === undefined) {
          continue;
        }
        prices.push(price);
      }
    }

    if (prices.length === 0) {
      return null;
    }

    const series = createPriceBucketsWithoutOutliers(prices);
    return {
      chart: {
        type: "pie",
        style: {
          fontSize: "16px",
        },
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br>{point.percentage:.1f}%",
            distance: 20,
          },
        },
      },
      series: [
        {
          data: series,
        },
      ],
    };
  }, [data]);

  const Age = ({ value }) => {
    if (value === null) {
      return <div className="text-center">-</div>;
    }
    const diff = moment().diff(moment(value), "days");

    if (diff === 0) {
      return <div className="text-center">Today</div>;
    }
    if (diff === 1) {
      return <div className="text-center">Yesterday</div>;
    }
    return <div className="text-center">{diff} days</div>;
  };

  const columnOrder = columns.map((col) => col.id);

  return (
    <Row className="pl-2">
      <Col xs={12} className="pt-3">
        {(productLoading || isLoading) && (
          <Loading height={"300px"} fullPage={false} />
        )}

        {!isLoading && isSuccess && (
          <>
            {showAddKWButon && (
              <Row>
                <Col className="text-end pb-2">
                  <LogoutButton
                    title={"Add Keyword"}
                    onClick={() => {
                      setShowAddKWModal(true);
                    }}
                    className="logout_button"
                    style={{ marginLeft: "5px" }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              {pricePieOptions && (
                <Col xs={4}>
                  <Row>
                    <Col xs={12} className="px-3 py-3">
                      <div className="fs-4 fw-bold text-center">
                        Market Pricing Breakdown{" "}
                        <ToolTips
                          toolTip="Displayed below is the distribution of prices for
                              all listings of the search terms that surfaced
                              this ASIN on Amazon. The chart is divided into 5
                              price buckets, each representing a range of
                              prices.
                              *** Outliers have been removed ***"
                          position={"top"}
                          id={"priceCharttooltip"}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="px-3 py-3">
                      <div className="fs-4"></div>
                    </Col>
                  </Row>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={pricePieOptions}
                  />
                </Col>
              )}
              <Col xs={pricePieOptions ? 8 : 12}>
                <Row>
                  <Col xs={12} className="px-3 py-3">
                    <div className="fs-4">
                      Displayed below are the search terms that surfaced this
                      ASIN on Amazon. The accompanying rank indicates the ASIN's
                      position within each search result.
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  PaginationComponent={ReactTablePagination}
                  getPaginationProps={() => ({
                    updateSearch: setSearchFilter,
                    clearFilters: () => {
                      setFilters([]);
                      setSearchFilter("");
                      setClearFilters(true);
                    },
                    clearSearchTermFilters: () => setSearchFilter(""),
                    searchTerm: searchFilter,
                    setFilterState: setFilters,
                    filteredState: filters,
                    table: "seo-search-terms-table",
                    setShowFilters: setShowFilters,
                    showFilters: showFilters,
                    style: { marginBottom: "2rem" },
                    carouselLayout: false,
                    disableSearchFocus: true,
                    hideFilters: true,
                  })}
                  getTheadFilterProps={(props) => {
                    if (clearFilters) {
                      props.filtered.forEach((filter, index) => {
                        filter.value = "all";
                      });
                      setClearFilters(false);
                    }
                    return {
                      style: { display: "flex", textAlign: "center" },
                    };
                  }}
                  TrComponent={TrComponent}
                  showPaginationTop
                  showPaginationBottom={false}
                  columns={columns}
                  data={tableData}
                  defaultPageSize={20}
                  defaultSorted={[{ id: "num_results", desc: true }]}
                  className="seo-search-terms-table"
                  filterable={showFilters}
                  state={columnOrder}
                  columnSelectorProps={columnSelectorProps}
                />
              </Col>
            </Row>
            <AddKeywordModal
              showAddKWModal={showAddKWModal}
              setShowAddKWModal={setShowAddKWModal}
              marketPlace={marketPlace}
              asin={asin}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default ASINTable;
