import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";

import rootReducer from "./reducers";
import { getModules, setModules } from "utils/modules";

const overrideModules = getModules();
const initialState = {
  overrideModules,
};
const middleware = [thunk];
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware), sentryReduxEnhancer)
);
store.subscribe(() => {
  // Persist override modules in localStorage
  const state = store.getState();
  const modules = state.overrideModules;
  setModules(modules);
});

export default store;
