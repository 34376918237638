import api from "./api";
import store from "redux/store";
import { setMarketPlace } from "redux/actions/marketPlace";

export const handleDefaultMarketplace = async () => {
  // Get new list of available marketplaces
  const {
    data: { amazon_seller_marketplaces: availableMarketplaces },
  } = await api.get("/api/sellerinfo/");

  // Below will check is user has a preference stored for the org they are switching to
  if (availableMarketplaces) {
    const res = await api.get("/api/userprofile/");
    const orgId = res.data.organization_id;
    const currentMarketplace = localStorage.Marketplace;
    const defaultMarketplace = res.data.default_marketplace[orgId];

    // Set to new orgs stored default marketplace if current marketplace no longer relevant
    if (
      availableMarketplaces.includes(defaultMarketplace) &&
      !availableMarketplaces?.includes(currentMarketplace)
    ) {
      localStorage.setItem("Marketplace", defaultMarketplace);
      store.dispatch(setMarketPlace(defaultMarketplace));
    }

    // Fallback in case no stored default is to first available choice
    if (
      !defaultMarketplace &&
      !availableMarketplaces?.includes(currentMarketplace)
    ) {
      localStorage.setItem("Marketplace", availableMarketplaces[0]);
      store.dispatch(setMarketPlace(availableMarketplaces[0]));
    }
  }

  window.location.reload();
};

export const handleProfileChangeRedirects = (history) => {
  // Below eliminates redirects on every profile change if number in hostname (localhost, etc.)
  const route = window.location.href.split(window.location.origin)[1];
  // Check if an entity id exists in URL, if so, return to merch dashboard on profile change
  if (/\d/.test(route)) {
    history.push("/user/merchandising/dashboard");
  }
};
