import React from "react";
import { Row, Col } from "react-bootstrap";
import DialogueCard from "./DialogueCard";
import { useHistory } from "react-router-dom";
import SkipToDashboardLink from "./SkipToDashboardLink";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/authActions";

const ORG_TYPE_OPTIONS = [
  {
    value: "vendor",
    title: "First-Party (1P) Supplier",
    description: null,
  },
  {
    value: "seller",
    title: "Third-Party (3P) Seller",
    description: null,
  },
];

const WalmartSellerType = ({ onboardingData, setOnboardingData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  return (
    <Row className="my-5">
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        <h2 className="mt-5 mb-1 fw-600">Connect To Your Marketplaces</h2>
        <p className="fs-4">
          Connect the channels you want to optimize. You will be able to add
          more after you onboard.
        </p>
        <h4 className="fw-500">Select Your Seller Type</h4>
      </Col>
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        {ORG_TYPE_OPTIONS.map((option) => (
          <DialogueCard
            contents={option}
            justify="center"
            additionalClasses="my-5"
            onClick={(value) => {
              setOnboardingData({
                ...onboardingData,
                walmartSellerType: value,
              });
              setTimeout(
                dispatch(setCurrentUser({ ...user, organization_type: value }))
              );
              history.push(`/onboarding/v2/connect?channel=walmart`);
            }}
          />
        ))}
        <SkipToDashboardLink />
      </Col>
    </Row>
  );
};

export default WalmartSellerType;
