import React, { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { formatPercent } from "utils/formatNumber";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { GOAL_COLORS } from "./utils";
import { STATUS } from "components/core/blocks/AdPlan/constants";
import { useDates } from "dates/useDates";
import moment from "moment";

const AdSpendGoalPieCharts = ({ adData, fontFamily, roasPreference }) => {
  const { start, end } = useDates();
  const goalType = roasPreference ? "ROAS" : "ACOS";
  const numOfDays = moment(end).diff(moment(start), "days") + 1;

  const assessGoalMetrics = useCallback(
    (data, goalAccessor, statsAccessor, metThresholdPercent = 0) => {
      return data?.reduce(
        (acc, entity) => {
          let metThresholdValue =
            entity[goalAccessor] * (metThresholdPercent / 100);
          let metricValue = entity[statsAccessor];
          let goalValue = entity[goalAccessor];

          if (["target_acos", "max_acos"].includes(goalAccessor)) {
            goalValue /= 100;
          }

          if (["monthly_budget"].includes(goalAccessor)) {
            // Get budget for however many days in selected period
            goalValue = (goalValue / 30) * numOfDays;
          }

          let metGoalUpperLimit = goalValue + metThresholdValue;
          let metGoalLowerLimit = goalValue - metThresholdValue;
          if (
            metricValue >= metGoalLowerLimit &&
            metricValue <= metGoalUpperLimit
          )
            return { ...acc, metGoal: acc.metGoal + 1 };
          if (metricValue > metGoalUpperLimit) {
            return { ...acc, aboveGoal: acc.aboveGoal + 1 };
          }
          if (metricValue < metGoalLowerLimit) {
            return { ...acc, belowGoal: acc.belowGoal + 1 };
          }
          return acc;
        },
        { metGoal: 0, belowGoal: 0, aboveGoal: 0 }
      );
    },
    [numOfDays]
  );

  const targetAcosData = useMemo(() => {
    return (
      Object.entries(
        assessGoalMetrics(
          adData?.filter(
            (ad) =>
              ad.ad_status === STATUS.ACTIVE.value &&
              ad.acos &&
              !ad.is_composite
          ),
          roasPreference ? "target_roas" : "target_acos",
          roasPreference ? "roas" : "acos",
          roasPreference ? 10 : 0.1
        )
      )
        ?.map((goalStatus, index) => {
          const labelMap = {
            metGoal: `Met ${goalType} Goal`,
            aboveGoal: `Above Target ${goalType}`,
            belowGoal: `Below Target ${goalType}`,
          };
          const [name, value] = goalStatus;
          return { name: labelMap[name], color: GOAL_COLORS[index], y: value };
        })
        ?.filter((type) => type.y) ?? []
    );
  }, [adData, assessGoalMetrics, goalType, roasPreference]);

  const maxAcosData = useMemo(() => {
    return (
      Object.entries(
        assessGoalMetrics(
          adData?.filter(
            (ad) =>
              ad.ad_status === STATUS.ACTIVE.value &&
              ad.acos &&
              !ad.is_composite
          ),
          roasPreference ? "min_roas" : "max_acos",
          roasPreference ? "roas" : "acos",
          0.0
        )
      )
        ?.map((goalStatus, index) => {
          const labelMap = {
            metGoal: `Met ${goalType} Goal`,
            aboveGoal:
              goalType === "ROAS"
                ? `Above Min ${goalType}`
                : `Above Max ${goalType}`,
            belowGoal:
              goalType === "ROAS"
                ? `Below Min ${goalType}`
                : `Below Max ${goalType}`,
          };
          const [name, value] = goalStatus;
          return { name: labelMap[name], color: GOAL_COLORS[index], y: value };
        })
        ?.filter((type) => type.y) ?? []
    );
  }, [adData, assessGoalMetrics, goalType, roasPreference]);

  const getPieChartOptions = (
    title,
    series = [],
    total = null,
    overrideOptions = {}
  ) => {
    return {
      chart: {
        type: "pie",
        style: {
          fontFamily: fontFamily ?? "Poppins",
          fontWeight: 300,
          fontSize: "15px",
        },
      },
      title: {
        text: title,
        style: {
          fontWeight: 500,
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          if (total) {
            return (
              "<span><strong>" +
              this.point.name +
              ":</strong><br />" +
              this.point.y +
              "<strong>" +
              ` (${formatPercent(this.point.y / total, 1)})` +
              "</strong></span>"
            );
          }

          return (
            "<span><strong>" +
            this.point.name +
            ":</strong><br />" +
            this.point.y +
            "</span>"
          );
        },
      },
      plotOptions: {
        series: {
          enableMouseTracking: false,
          states: {
            inactive: {
              opacity: 1,
            },
          },
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          allowPointSelect: false,
          cursor: "none",
          dataLabels: {
            enabled: true,
            useHTML: true,
            formatter: function () {
              return (
                `<span style="font-size: 1.2em; font-weight: 600; opacity: 0.8">${this.point.name}</span><br>` +
                `<span style="opacity: 0.6; margin: auto; display: block; text-align: center; width: 100%;">${
                  this.point.y
                } - ${formatPercent(this.point.y / total, 1)}</span>`
              );
            },
          },
        },
      },
      series: series,
      ...overrideOptions,
    };
  };

  const targetAcosChartOptions = getPieChartOptions(
    `Target ${goalType} vs. Actual ${goalType}`,
    [
      {
        name: goalType,
        colorByPoint: true,
        data: targetAcosData ?? [],
      },
    ],
    adData?.filter(
      (ad) =>
        ad.ad_status === STATUS.ACTIVE.value && ad.acos && !ad.is_composite
    )?.length
  );

  const maxAcosChartOptions = getPieChartOptions(
    roasPreference ? "Min ROAS vs. Actual ROAS" : "Max ACOS vs. Actual ACOS",
    [
      {
        name: "ACOS",
        colorByPoint: true,
        data: maxAcosData ?? [],
      },
    ],
    adData?.filter(
      (ad) =>
        ad.ad_status === STATUS.ACTIVE.value && ad.acos && !ad.is_composite
    )?.length
  );

  return (
    <>
      <Row>
        <Col xs={6}>
          <HighchartsReact
            highcharts={Highcharts}
            options={maxAcosChartOptions}
          />
        </Col>
        <Col xs={6}>
          <HighchartsReact
            highcharts={Highcharts}
            options={targetAcosChartOptions}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdSpendGoalPieCharts;
