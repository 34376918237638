import React, { useRef, useState, useCallback } from "react";
import ToolTips from "utils/hiddenToolTips";
import useOnScreen from "hooks/useOnScreen";
import ExternalProductLink from "components/custom/merchandise/ExternalProductLink";

const KeywordCell = (props) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const innerCellRef = useRef(null);

  const isVisible = useOnScreen(innerCellRef);

  const cellRef = useCallback(
    (node) => {
      if (node !== null) {
        setIsTruncated(
          node.parentElement.scrollWidth > node.parentElement.clientWidth
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.value, props.columnWidth, isVisible]
  );

  const isAsin = !!props.value
    ?.toString()
    ?.toLowerCase()
    ?.match(/b0[a-z0-9]{8}/i);
  // if its an asin then we need strip additional characters
  const asin = isAsin
    ? props.value
        .toString()
        .toLowerCase()
        .match(/b0[a-z0-9]{8}/i)[0]
    : null;

  return (
    <span
      ref={cellRef}
      style={{
        cursor: "pointer",
      }}
      data-tip={props.value}
      data-for={props.value}
    >
      <span ref={innerCellRef} style={props.style}>
        {isTruncated ? (
          <>
            <ToolTips
              id={props.value}
              position={props.tooltipPosition ?? "left"}
            />
            {isAsin ? (
              <>
                {props.value.toUpperCase()}{" "}
                <ExternalProductLink asin={props.value.toUpperCase()} />
              </>
            ) : (
              props.value
            )}
          </>
        ) : isAsin ? (
          <>
            {asin.toUpperCase()}{" "}
            <ExternalProductLink asin={asin.toUpperCase()} />
          </>
        ) : (
          props.value
        )}
      </span>
    </span>
  );
};

export default KeywordCell;
