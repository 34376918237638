import React, { useEffect, useState } from "react";
import { trellisPalette } from "components/custom/analytics/palettes";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const StarRatingChart = ({ ratingsBucket, secondaryTab }) => {
  const [ratingsChartOptions, setRatingsChartOptions] = useState(null);

  useEffect(() => {
    if (secondaryTab === "star_ratings" && !ratingsChartOptions) {
      createRatingChart(ratingsBucket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingsBucket, secondaryTab]);

  const createRatingChart = (ratings) => {
    const categories = [];
    const ratingList = [];
    for (let i = 0; i < ratings.length; i++) {
      categories.push(
        `${ratings[i].min.toFixed(2)} - ${ratings[i].max.toFixed(2)}`
      );
      ratingList.push(ratings[i].count);
    }
    const options = {
      chart: {
        type: "column",
        height: 360,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: categories,
        crosshair: true,
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number of Listings",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0">&nbsp;<b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Ratings",
          data: ratingList,
          color: trellisPalette[3],
        },
      ],
    };

    setRatingsChartOptions(options);
  };

  if (!ratingsChartOptions || secondaryTab !== "star_ratings") {
    return null;
  }

  return (
    <div
      style={{
        padding: "20px 10px 20px 10px",
        border: "1px solid #ccc",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopRightRadius: "5px",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={ratingsChartOptions} />
    </div>
  );
};

export default StarRatingChart;
