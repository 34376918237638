import React, { Component } from "react";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_checked: props.isChecked ? true : false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({ is_checked: !this.state.is_checked });
  }
  render() {
    const { isChecked, number, label, inline, disablePointerEvents, ...rest } =
      this.props;
    let classes =
      inline !== undefined ? "checkbox checkbox-inline" : "checkbox";

    if (this.props.className) {
      classes = this.props.className;
    }
    return (
      <div className={`${classes} ${this.props.containerClasses} fw-normal`}>
        <h6
          className="title fw-normal"
          style={{
            textTransform: "None",
            color: this.props.color ? this.props.color : "#73706E",
            fontWeight: "700",
            pointerEvents: disablePointerEvents ? "none" : "inherit",
          }}
        >
          <input
            id={number}
            type="checkbox"
            onChange={this.handleClick}
            checked={this.state.is_checked}
            {...rest}
            style={{ cursor: disablePointerEvents ? "not-allowed" : "inherit" }}
          />
          <label
            htmlFor={number}
            style={{
              cursor: disablePointerEvents ? "not-allowed" : "inherit",
              color: disablePointerEvents ? "#AAA" : "inherit",
            }}
          >
            {label}
          </label>
        </h6>
      </div>
    );
  }
}

export default Checkbox;
