import {
  SET_CURRENT_USER,
  UNSET_CURRENT_USER,
  UPDATE_ALLOW_MWS_ORDERS,
  UPDATE_PRODUCT_IDENTIFIER,
  DEFAULT_MARKETPLACE,
  UPDATE_USER_PREFERENCES,
  UPDATE_SUBSCRIPTION_TIER,
  UPDATE_BYPASS_ONBOARDING,
} from "../types";
import isEmpty from "is-empty";

const initialState = {
  user_full_name: "",
  organization_admin: "",
  organization_name: "",
  organization_id: "",
  organization_type: "",
  organization_created_date: "",
  icp_status: "",
  current_profile: "",
  agreementStatus: "",
  tier: "",
  pricing_tier: "",
  analytics_tier: "",
  content_tier: "",
  promotions_tier: "",
  pb_tier: "",
  brand_registered: "",
  allowMwsOrders: false,
  productIdentifier: "",
  preferences: {
    acos_or_roas: "acos",
    all_notifications: false,
    merchandise_dashboard: {},
  },
  modules: [],
  is_staff: false,
  user_id: "",
  landed_cost_per: null,
  shipping_cost_per: null,
  tos_acceptance_date: null,
  total_sales_start_date: null,
  ad_count: 0,
  bypass_onboarding: false,
  email_verified_on: null,
  customer_type: null,
  annual_amazon_sales: null,
  total_monthly_sales: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER: {
      let newstate = initialState;
      if (!isEmpty(action.payload)) {
        newstate = {
          ...state,
          user_full_name:
            action.payload.full_user_name !== undefined
              ? action.payload.full_user_name
              : state.user_full_name,
          current_profile:
            action.payload.current_profile !== undefined
              ? action.payload.current_profile
              : state.current_profile,
          organization_admin:
            action.payload.is_organization_admin !== undefined
              ? action.payload.is_organization_admin
              : state.organization_admin,
          organization_type:
            action.payload.organization_type !== undefined
              ? action.payload.organization_type
              : state.organization_type,
          organization_name:
            action.payload.organization_name !== undefined
              ? action.payload.organization_name
              : state.organization_name,
          organization_id:
            action.payload.organization_id !== undefined
              ? action.payload.organization_id
              : state.organization_id,
          organization_created_date:
            action.payload.organization_created_date !== undefined
              ? action.payload.organization_created_date
              : state.organization_created_date,
          icp_status:
            action.payload.icp_status !== undefined
              ? action.payload.icp_status
              : state.icp_status,
          agreementStatus:
            action.payload.latest_tos_agreement_status !== undefined
              ? action.payload.latest_tos_agreement_status
              : state.agreementStatus,
          tier:
            action.payload.tier !== undefined
              ? action.payload.tier
              : state.tier,
          pricing_tier:
            action.payload.pricing_tier !== undefined
              ? action.payload.pricing_tier
              : state.pricing_tier,
          analytics_tier:
            action.payload.analytics_tier !== undefined
              ? action.payload.analytics_tier
              : state.analytics_tier,
          content_tier:
            action.payload.content_tier !== undefined
              ? action.payload.content_tier
              : state.content_tier,
          promotions_tier:
            action.payload.promotions_tier !== undefined
              ? action.payload.promotions_tier
              : state.promotions_tier,
          pb_tier:
            action.payload.pb_tier !== undefined
              ? action.payload.pb_tier
              : state.pb_tier,
          brand_registered:
            action.payload.brand_registered !== undefined
              ? action.payload.brand_registered
              : state.brand_registered,
          allowMwsOrders:
            action.payload.allow_mws_orders !== undefined
              ? action.payload.allow_mws_orders
              : state.allowMwsOrders,
          productIdentifier:
            action.payload.product_identifier !== undefined
              ? action.payload.product_identifier
              : state.productIdentifier,
          modules:
            action.payload.modules !== undefined
              ? action.payload.modules
              : state.modules,
          permissions:
            action.payload.permissions !== undefined
              ? action.payload.permissions
              : state.permissions,
          preferences: action.payload.preferences
            ? action.payload.preferences
            : {
                acos_or_roas: "acos",
                all_notifications: false,
                merchandise_dashboard: {},
              },
          defaultMarketplace:
            action.payload.default_marketplace !== undefined
              ? action.payload.default_marketplace
              : state.defaultMarketplace,
          landedCostPer:
            action.payload.landed_cost_per !== undefined
              ? action.payload.landed_cost_per
              : state.landedCostPer,
          shippingCostPer:
            action.payload.shipping_cost_per !== undefined
              ? action.payload.shipping_cost_per
              : state.shippingCostPer,
          is_staff:
            action.payload.is_staff !== undefined
              ? action.payload.is_staff
              : state.is_staff,
          user_id:
            action.payload.user_id !== undefined
              ? action.payload.user_id
              : state.user_id,
          tos_acceptance_date:
            action.payload.tos_acceptance_date !== undefined
              ? action.payload.tos_acceptance_date
              : state.tos_acceptance_date,
          total_sales_start_date:
            action.payload.total_sales_start_date !== undefined
              ? action.payload.total_sales_start_date
              : state.total_sales_start_date,
          ad_count:
            action.payload.ad_count !== undefined
              ? action.payload.ad_count
              : state.ad_count,
          bypass_onboarding:
            action.payload.bypass_onboarding !== undefined
              ? action.payload.bypass_onboarding
              : state.bypass_onboarding,
          email_verified_on:
            action.payload.email_verified_on !== undefined
              ? action.payload.email_verified_on
              : state.email_verified_on,
          customer_type:
            action.payload.customer_type !== undefined
              ? action.payload.customer_type
              : state.customer_type,
          annual_amazon_sales:
            action.payload.annual_amazon_sales !== undefined
              ? action.payload.annual_amazon_sales
              : state.annual_amazon_sales,
          total_monthly_sales:
            action.payload.total_monthly_sales !== undefined
              ? action.payload.total_monthly_sales
              : state.total_monthly_sales,
        };
      }

      return newstate;
    }

    case UNSET_CURRENT_USER:
      return initialState;

    case UPDATE_ALLOW_MWS_ORDERS:
      if (!isEmpty(action.payload)) {
        return {
          ...state,
          allowMwsOrders: action.payload,
        };
      }
      return state;
    case UPDATE_PRODUCT_IDENTIFIER:
      if (!isEmpty(action.payload)) {
        return {
          ...state,
          productIdentifier: action.payload,
        };
      }
      return state;
    case DEFAULT_MARKETPLACE:
      return {
        ...state,
        defaultMarketPlace: action.payload,
      };
    case UPDATE_USER_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      };
    case UPDATE_SUBSCRIPTION_TIER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_BYPASS_ONBOARDING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
