import { AD_TYPES } from "components/core/blocks/AdPlan/constants";

export const getCampaignsByAdType = (
  adType,
  previousCampaigns = {},
  withAuto
) => {
  const AD_TYPE_CAMPAIGN_MAP = {
    [AD_TYPES.SPONSORED_PRODUCT.value]: {
      manual_name: null,
    },
    [AD_TYPES.SPONSORED_PRODUCT_AUTO.value]: {
      auto_name: null,
    },
    [AD_TYPES.SPONSORED_BRAND.value]: {
      manual_name: null,
    },
    [AD_TYPES.SPONSORED_BRAND_VIDEO.value]: {
      manual_name: null,
    },
    [AD_TYPES.SPONSORED_DISPLAY_V2.value]: {
      contextual_name: null,
      audience_name: null,
    },
  };

  const applicableCampaigns = AD_TYPE_CAMPAIGN_MAP[adType];

  if (
    withAuto &&
    [
      AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
      AD_TYPES.SPONSORED_PRODUCT.value,
    ].includes(adType)
  ) {
    applicableCampaigns["auto_name"] = null;
  }

  // If ad type, goal, or with_auto param changes relevent campaign names, maintain previously assigned names
  for (const campaignName in previousCampaigns) {
    if (Object.keys(applicableCampaigns).includes(campaignName)) {
      applicableCampaigns[campaignName] = previousCampaigns[campaignName];
    }
  }

  return applicableCampaigns;
};
