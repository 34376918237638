import axios from "axios";
import getURLPrefix from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

const VideoEditorProjectState = {
  PROJ_STATE_NONE: -1,
  PROJ_STATE_SELECT_NEW_TEMPLATE: 0,
  PROJ_STATE_ENTER_PROJECT_NAME: 1,
  PROJ_STATE_EDITING: 2,
  PROJ_STATE_PREPARE_EDITING: 3,
  PROJ_STATE_PUBLISHED: 4,
  PROJ_STATE_INTERRUPT: 5,
  PROJ_STATE_REENTER_PROJECT_NAME: 6,
};

async function uploadAsset(assetName, file, type, cb) {
  const data = new FormData();
  data.append("name", assetName);
  data.append("type", type);
  let assetId = "";
  axios
    .post(URL_PREFIX + "/creatives/media/assets?upload=true", data)
    .then((res) => {
      assetId = res.data.asset_id;
      var assetSrcUrl = res.data.url;
      if (assetId && assetSrcUrl) {
        var instance = axios.create();
        delete instance.defaults.headers.common["Authorization"];
        // delete instance.defaults.headers.common['Content-Type'];
        instance
          .put(assetSrcUrl, file, {
            onUploadProgress: (ProgressEvent) => {},
            headers: {
              "Content-Type": "",
            },
          })
          .then((res) => {
            if (cb) {
              cb(assetId);
            }
          })
          .catch((error) => {
            assetId = ""; // set it to blank to indicate failure
            console.log(
              "Put to video URL failed to url:",
              assetSrcUrl,
              " And error:",
              error
            );
            // return("");
            if (cb) {
              cb("");
            }
          });
      } else {
        if (cb) {
          cb("");
        }
      }
    })
    .catch((error) => {
      console.log("POST to media/video failed with error:", error);
      assetId = ""; // set it to blank to indicate failure
      // this.setState({ showProgress: false });
      // return("");
      if (cb) {
        cb("");
      }
    });
}

async function getAssetSrcUrl(assetId, cb) {
  let assetSrcUrl = "";
  await axios
    .get(URL_PREFIX + "/creatives/media/assets?id=" + assetId)
    .then((res) => {
      assetSrcUrl = res.data["url"];
    })
    .catch(() => {
      console.log("Error, unable to fetch asset");
    });

  if (cb) {
    cb(assetSrcUrl);
  }
}

function createUserConfig(
  marketPlace = "",
  projectName = "",
  projectDescription = "",
  templateName = "",
  cb = null
) {
  let useProjectName = projectName;
  let useProjectDescription = projectDescription;
  let useTemplateName = templateName;
  let useMarketPlace = marketPlace;
  const data = new FormData();
  data.append("project_name", useProjectName);
  data.append("project_description", useProjectDescription);
  data.append("market_place", useMarketPlace);

  axios
    .post(
      URL_PREFIX + "/creatives/media/video?template_name=" + useTemplateName,
      data
    )
    .then((res) => {
      if (cb) {
        cb(true, res.data.id, res.data.project_name, res.data.config);
      }
    })
    .catch((e) => {
      if (cb) {
        cb(false);
      }
    });
}

function getAllVideoProjects(marketPlace, cb) {
  getVideoProjects(marketPlace, null, cb);
}

function updateProjectLinks(id, cb) {
  getVideoProjects(null, id, cb);
}

function getVideoProjects(marketPlace, id, cb) {
  let baseUrl = "/creatives/media/video?";
  let queryStr = "all=true&unfiltered=true&market_place=" + marketPlace;
  if (id) {
    queryStr = "id=" + id;
  }

  axios
    .get(URL_PREFIX + baseUrl + queryStr)
    .then((res) => {
      let useData = res.data;
      if (id) {
        useData = [res.data];
      }

      if (cb) {
        cb(useData);
      }
    })
    .catch((error) => {
      console.log("Get projects failed with error:", error);
      if (cb) {
        cb(null);
      }
    });
}

export {
  getAllVideoProjects,
  uploadAsset,
  getAssetSrcUrl,
  createUserConfig,
  VideoEditorProjectState,
};
