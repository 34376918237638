import { useSelector } from "react-redux";
import { useFetch } from "./api";
import moment from "moment";

const useAds = (categoryId = null, adId = null, overrideFilters = null) => {
  const state = useSelector((state) => state);

  const {
    marketPlace,
    channel: { currentChannel: channel },
  } = state;

  const fiftyDaysInTheFuture = moment().add(50, "days").format("YYYY-MM-DD");

  let filters = {
    start_date: fiftyDaysInTheFuture,
    end_date: fiftyDaysInTheFuture,
    pre_start_date: null,
    pre_end_date: null,
    empty: true,
  };

  if (categoryId) {
    filters["category"] = parseInt(categoryId);
  }

  if (overrideFilters) {
    filters = { ...filters, ...overrideFilters };
  }

  const { data: adData, isLoading: isAdDataLoading } = useFetch(
    ["ad_report", marketPlace.marketPlace, channel, categoryId],
    `/api/data_report/ad_report`,
    {
      mp: marketPlace.marketPlace,
      channel: channel,
      ...filters,
    },
    {
      select: (d) => d.data?.adplans,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      default: [],
    }
  );

  return {
    currentAdDetails: adData?.find((ad) => ad?.id === parseInt(adId)) ?? {},
    ads: adData?.map((ad) => ({
      ...ad,
      name: ad.name,
      href: `/user/advertising/dashboard/ads/${ad.id}`,
      isLoading: isAdDataLoading,
    })),
  };
};

export default useAds;
