import { AuthButton, LogoutButton } from "components/core/basic/Button.jsx";
import SellerChannel from "components/custom/navbar/SellerChannel";
import SellerMarketplace from "components/custom/navbar/SellerMarketplace";
import SellerAccount from "components/custom/navbar/SellerAccount2";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { logoutUser } from "redux/actions/authActions";
import { useMediaQuery } from "@react-hook/media-query";
import "../assets/css/mobile_nav.css";
import { useNotifications } from "notifications/useNotifications";
import { trellisPalette } from "components/custom/analytics/palettes";
import NotificationDropdown from "./NotificationDropdown";
import getQueryValue from "utils/getQueryValue";
import { selectModules } from "redux/selectors/modules";
import Search from "components/custom/navbar/Search";
import { trackMixpanelEvent } from "hooks/useMixpanelTracking";

const NavigationBar = (props) => {
  const matches = useMediaQuery("only screen and (min-width: 992px)");
  const mobileNotifications = useMediaQuery(
    "only screen and (max-width: 650px)"
  );
  const location = useLocation();
  const [showContent, setShowContent] = useState(matches);
  const [showNotifications, setShowNotifications] = useState(false);

  // Close notification dropdown on url update
  useEffect(() => {
    setShowNotifications(false);
  }, [location]);

  // function for responsive that hides/shows the sidebar
  const mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle("nav-open");
    setShowContent(!showContent);
  };

  const onLogoutClick = (e) => {
    e.preventDefault();
    trackMixpanelEvent("Click", { name: "Logout", element: "button" });
    props.logoutUser();
  };

  // Base notification count on current category, if user is in dashboard category view, otherwise get all notifications
  const [notifications, currentCategory] = useNotifications(
    getQueryValue("categoryId")
  );
  let unseenNotificationCount = notifications?.filter(
    (n) => !n.dismissed_on && !n.clicked_on && !n.seen_on
  )?.length;

  return (
    <Navbar
      className={`py-1 px-3 ${
        props.navbar ? "navbar-fixed navbar-default" : ""
      } navbar-default`}
      expand="lg"
    >
      <Container fluid>
        {/* <Navbar fluid className="navbar-fixed"> */}
        <Navbar.Toggle
          onClick={mobileSidebarToggle}
          aria-controls="basic-navbar-nav"
          style={{ position: "absolute", right: "30px" }}
        />
        {/* Here we import the links that appear in navbar */}
        {matches ? (
          <Navbar.Collapse>
            <Container fluid>
              <Row
                style={{
                  alignItems: "center",
                }}
              >
                <Col
                  style={{ margin: "0px", flexGrow: "auto", minWidth: "30%" }}
                >
                  <Nav style={{ display: "flex" }}>
                    <SellerChannel />
                    <SellerAccount />
                    <SellerMarketplace />
                  </Nav>
                </Col>
                <Col
                  style={{
                    textAlign: "center",
                    flexGrow: "1",
                  }}
                >
                  {props.channel.currentChannel !== "google" && matches ? (
                    <div
                      style={{
                        maxWidth: "450px",
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <Search handleNotification={props.handleNotification} />
                    </div>
                  ) : null}
                </Col>
                <Col style={{ margin: "0px", height: "75px", flexGrow: "1" }}>
                  <div className="wrap" style={{ float: "right" }}>
                    {props.notificationsEnabled && (
                      <div
                        onClick={() => {
                          setShowNotifications(!showNotifications);
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "1rem",
                          border: `2px solid ${trellisPalette[6]}`,
                          borderRadius: "99999999999px",
                          color: trellisPalette[6],
                        }}
                      >
                        <i className="fa fa-bell" />
                        <span>
                          {unseenNotificationCount > 0 &&
                            unseenNotificationCount}
                        </span>
                      </div>
                    )}
                    <AuthButton
                      className="wrap text_button beamerButton"
                      title="What's New"
                      style={{ marginRight: "20px" }}
                    />
                    <LogoutButton title="Logout" onClick={onLogoutClick} />
                  </div>
                </Col>
              </Row>
            </Container>
          </Navbar.Collapse>
        ) : (
          <div
            className="wrap"
            style={
              matches
                ? {
                    float: "right",
                    marginRight: "2rem",
                    marginTop: "1rem",
                  }
                : {
                    marginRight: "80px",
                    marginTop: "1rem",
                    marginLeft: "auto",
                    marginBottom: "10px",
                  }
            }
          >
            {props.notificationsEnabled && (
              <div
                onClick={() => {
                  setShowNotifications(!showNotifications);
                }}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  border: `2px solid ${trellisPalette[6]}`,
                  borderRadius: "99999999999px",
                  color: trellisPalette[6],
                }}
              >
                <i className="fa fa-bell" />
                <span>
                  {unseenNotificationCount > 0 && unseenNotificationCount}
                </span>
              </div>
            )}
          </div>
        )}
        {showNotifications && (
          <NotificationDropdown
            notifications={notifications?.filter(
              (n) => !n.dismissed_on && !n.clicked_on
            )}
            marketPlace={props.marketPlace.marketPlace}
            setShowNotifications={setShowNotifications}
            currentCategory={currentCategory}
            mobileNotifications={mobileNotifications}
          />
        )}
      </Container>
    </Navbar>
  );
};

NavigationBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  channel: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  notificationsEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
  notificationsEnabled:
    state.user.preferences?.all_notifications ||
    // NOTE: Line below will need to be removed once default value is added to db for all users
    // Currently used for missing all_notifications in preferences column
    typeof state.user.preferences?.all_notifications === "undefined" ||
    state.user.preferences?.ad_product_out_of_stock ||
    state.user.preferences?.ad_product_low_in_stock ||
    state.user.preferences?.product_ineligibility ||
    state.user.preferences?.product_exceed_spend,
});

const mapDispatchToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
