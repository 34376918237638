import axios from "axios";

import { setMarketPlace } from "redux/actions/marketPlace";
import store from "redux/store";
import { getURLPrefix } from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

function userMarketPlace(accessToken, callback = null, useFallback = false) {
  let AuthStr = "Bearer ".concat(accessToken);

  axios
    .get(URL_PREFIX + "/api/sellerinfo/", {
      params: {},
      headers: { Authorization: AuthStr },
    })
    .then((res) => {
      if (res.status === 204) {
        localStorage.setItem("Marketplace", "CA");
        store.dispatch(setMarketPlace("CA"));
      }

      // Fallback generated from priority of ad count, product count, then first available marketplace
      let fallbackDefaultMarketplace = res.data.fallback_default_marketplace;

      if (!localStorage.Marketplace) {
        fetchDefaultMarketPlace(
          accessToken,
          fallbackDefaultMarketplace,
          useFallback
        );
      }

      store.dispatch(setMarketPlace(localStorage.Marketplace));

      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      localStorage.setItem("Marketplace", "USA");
      store.dispatch(setMarketPlace("USA"));
    });

  return true;
}

const fetchDefaultMarketPlace = (
  accessToken,
  fallbackDefaultMarketplace = null,
  useFallback = false
) => {
  let AuthStr = "Bearer ".concat(accessToken);

  axios
    .get(URL_PREFIX + "/api/userprofile/", {
      params: {},
      headers: { Authorization: AuthStr },
    })
    .then((res) => {
      let orgId = res.data.organization_id;
      let defaultMarketplace = res.data.default_marketplace[orgId];

      if (defaultMarketplace && !useFallback) {
        // Set marketplace to default stored in user profiles table
        localStorage.setItem("Marketplace", defaultMarketplace);
        store.dispatch(setMarketPlace(defaultMarketplace));
      }

      if (
        (fallbackDefaultMarketplace && useFallback) ||
        (fallbackDefaultMarketplace && !defaultMarketplace)
      ) {
        // Set marketplace to fallback default generated in serializer
        localStorage.setItem("Marketplace", fallbackDefaultMarketplace);
        store.dispatch(setMarketPlace(fallbackDefaultMarketplace));
      }
    })
    .catch((error) => {
      // Return USA if no default found in user profiles table and one could not be generated from serializer function
      localStorage.setItem("Marketplace", "USA");
      store.dispatch(setMarketPlace("USA"));
    });
};

export default userMarketPlace;
