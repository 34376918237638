import React, { useState, useMemo, useEffect } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  NavDropdown,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Controller, get, useWatch } from "react-hook-form";
import ToolTips from "utils/hiddenToolTips";
import { listFormatter } from "utils/listFormatter";
import {
  NON_METRIC_FIELD_ACCESSORS,
  RELATIVE_METRIC_FIELD_ACCESSORS,
  OPERATOR_OPTIONS,
} from "./constants";

const Label = ({ toolTip, children, bsClassName = "mb-3" }) => {
  return (
    <Form.Label className={bsClassName}>
      <h6
        className={`title fw-bold fs-4`}
        style={{
          textTransform: "none",
        }}
      >
        {children}
        {toolTip && (
          <ToolTips
            toolTip={this.props.toolTip}
            position="top"
            id={Object.keys(this.props.toolTip)[0]}
          />
        )}
      </h6>
    </Form.Label>
  );
};

// Binds rhf and internal state
const MetricCompareSelectField = ({
  fieldName,
  control,
  errors,
  metricOptions,
  selectedOption,
}) => {
  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => {
          return (
            <MetricCompareSelect {...field} metricOptions={metricOptions} />
          );
        }}
      />
    </>
  );
};

const MetricCompareSelect = ({ value, onChange, metricOptions = [] }) => {
  const [operator, metric] = useMemo(() => {
    return value ? JSON.parse(value) : [null, null];
  }, [value]);

  const metricName = metric.replace(/(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9]+$)/g, "");

  const onMetricChange = (newMetric) => {
    // Below makes sure operator applies to newMetric, otherwise choose first applicable operator
    const supportedOperators = metricOptions?.find(
      (o) => o.value === newMetric
    )?.supportedOperators;

    if (!supportedOperators?.map((o) => o.value)?.includes(operator)) {
      onChange(JSON.stringify([supportedOperators[0]?.value, newMetric]));
      return;
    }

    onChange(JSON.stringify([operator, newMetric]));
  };

  const onOperatorChange = (newOperator) => {
    onChange(JSON.stringify([newOperator, metric]));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pluralBlacklist = ["acos", "roas"];

  let linkingVerb = useMemo(() => {
    return metricName[metricName.length - 1] === "s" &&
      !pluralBlacklist.includes(metricName)
      ? "are"
      : "is";
  }, [metricName, pluralBlacklist]);

  return (
    <div className="d-flex">
      {/*  Metric */}
      <NavDropdown
        bsPrefix="inline-dropdown"
        title={
          metricOptions.find((o) => o.value === metric)?.activeLabel ??
          metricOptions.find((o) => o.value === metric)?.label ??
          "Select Metric"
        }
      >
        <div style={{ maxHeight: "250px" }}>
          {metricOptions?.map((option) => (
            <NavDropdown.Item
              key={option.value}
              onClick={() => {
                const { value } = option;
                onMetricChange(value);
              }}
            >
              {option.label}
            </NavDropdown.Item>
          ))}
        </div>
      </NavDropdown>

      {!NON_METRIC_FIELD_ACCESSORS.includes(metricName) && (
        <>
          <span className={"px-3"}>{linkingVerb}</span>
          <NavDropdown
            bsPrefix="inline-dropdown"
            title={
              OPERATOR_OPTIONS["number"]?.find((o) => o.value === operator)
                ?.label
            }
          >
            <div style={{ maxHeight: "250px" }}>
              {OPERATOR_OPTIONS["number"]?.map((option) => (
                <NavDropdown.Item
                  key={option.value}
                  onClick={() => {
                    const { value } = option;
                    onOperatorChange(value);
                  }}
                >
                  {option.label}
                </NavDropdown.Item>
              ))}
            </div>
          </NavDropdown>
        </>
      )}
    </div>
  );
};

const InputField = ({
  label,
  toolTip,
  placeholder,
  fieldName,
  control,
  errors,
  rules,
  disabled,
  align = "left",
  shouldUnregister = false,
}) => {
  const error = get(errors, fieldName);
  return (
    <Form.Group>
      {label && <Label toolTip={toolTip}>{label}</Label>}
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({ field }) => {
          return (
            <>
              <Form.Control
                isInvalid={error}
                placeholder={placeholder}
                disabled={disabled}
                style={{ textAlign: align, minWidth: "50px" }}
                {...field}
              />
            </>
          );
        }}
      />
      {error && error?.message && (
        <span className="text-danger" style={{ marginLeft: "1rem" }}>
          {error?.message}
        </span>
      )}
    </Form.Group>
  );
};

const InlineInputField = ({ width = "8rem", ...props }) => (
  <div style={{ maxWidth: width, position: "relative" }}>
    <InputField {...props} align="center" />
  </div>
);

const InlineInputSelectComboField = ({
  metricCompareValueOptions,
  control,
  errors,
  rules,
  fieldName,
}) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <InlineInputComboSelect
            {...field}
            control={control}
            errors={errors}
            fieldName={fieldName}
            metricCompareValueOptions={metricCompareValueOptions}
          />
        );
      }}
    />
  );
};

const InlineInputComboSelect = ({
  value,
  onChange,
  defaultMetricValue = 10,
  metricCompareValueOptions,
  ...props
}) => {
  const [compareOption, setCompareOption] = useState(
    parseFloat(value) ? "value" : value ?? "value"
  );

  useMemo(() => {
    if (compareOption !== "value") {
      onChange(compareOption);
    }

    if (compareOption === "value" && !parseFloat(value)) {
      onChange(defaultMetricValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareOption]);

  return (
    <div className={`d-flex align-items-center rules-engine-combo-select`}>
      {compareOption === "value" ? (
        <InlineInputField {...props} align="center" />
      ) : (
        <>
          <Form.Control
            value={
              metricCompareValueOptions?.find((o) => o.value === compareOption)
                ?.label
            }
            style={{
              width: "auto",
              textAlign: "center",
              position: "relative",
            }}
            disabled={true}
          />
        </>
      )}

      <NavDropdown style={{ marginRight: "-10px" }}>
        {metricCompareValueOptions?.map((option) => {
          const { label, value } = option;
          return (
            <div className="text-dark-purple">
              <NavDropdown.Item
                key={value}
                id={value}
                onClick={(e) => setCompareOption(value)}
              >
                {label}
              </NavDropdown.Item>
            </div>
          );
        })}
      </NavDropdown>
    </div>
  );
};

const InlineSelect = ({
  options,
  label,
  toolTip,
  fieldName,
  control,
  rules,
  disabled,
  title = null,
  groups = null,
  searchable = false,
  additionalClasses = null,
}) => {
  const [search, setSearch] = useState("");

  const optionsByGroup = useMemo(() => {
    if (!groups) return options;

    const grouped = options.reduce((acc, option) => {
      if (!acc[option.group]) {
        acc[option.group] = [];
      }
      acc[option.group].push(option);
      return acc;
    }, {});
    const items = [];
    for (const group of groups) {
      items.push({ header: group.label });
      const groupOpts = grouped[group.value];

      items.push(...groupOpts);
    }
    return items;
  }, [options, groups]);

  return (
    <Form.Group>
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field }) => {
          const selectedOption =
            options.find((option) => option.value === field.value) ||
            options[0];
          return (
            <NavDropdown
              bsPrefix="inline-dropdown"
              title={title || selectedOption?.label}
              disabled={disabled}
              className={additionalClasses}
            >
              {searchable && (
                <div className="px-3 py-2">
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      style={{
                        borderTopLeftRadius: "45px",
                        borderBottomLeftRadius: "45px",
                      }}
                    >
                      <i className="fa fa-search" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder={"Search..."}
                      aria-label="Search Term"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      value={search}
                    />
                  </InputGroup>
                </div>
              )}

              <div style={{ maxHeight: "250px" }}>
                {optionsByGroup
                  ?.filter((option) => {
                    if (search) {
                      return (
                        `${option.group} ${option.label} ${option.Header}`
                          ?.toLowerCase()
                          ?.indexOf(search.toLowerCase()) > -1
                      );
                    }

                    return true;
                  })
                  .map((option) =>
                    option.header ? (
                      <NavDropdown.Header key={option.header}>
                        <span className="fs-standard">{option.header}</span>
                      </NavDropdown.Header>
                    ) : (
                      <NavDropdown.Item
                        key={option.value}
                        onClick={() => {
                          field.onChange(option.value);
                        }}
                      >
                        {option.label}
                      </NavDropdown.Item>
                    )
                  )}
              </div>
            </NavDropdown>
          );
        }}
      />
    </Form.Group>
  );
};

// Multi-select

const InlineMultiSelect = ({
  options = [],
  label,
  toolTip,
  fieldName,
  control,
  rules,
  disabled,
  title = null,
  additionalClasses = "ms-0",
  description,
}) => {
  return (
    <Form.Group className="ms-0">
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field }) => {
          const selectedOptions = options.filter((option) =>
            field.value?.includes(option.value)
          );

          const onToggleMultiSelectValue = ({ id, checked }) => {
            let currentSelections =
              typeof field.value === "string" ? [field.value] : field.value;
            let updatedSelections = currentSelections
              ? [...currentSelections]
              : [];

            if (currentSelections?.includes(id) && checked === false) {
              updatedSelections = updatedSelections.filter((o) => o !== id);
            }

            if (!currentSelections?.includes(id) && checked) {
              updatedSelections.push(id);
            }

            field.onChange(updatedSelections);
          };

          return (
            <DropdownButton
              renderMenuOnMount={true}
              flip={false}
              bsPrefix="col-selector inline-dropdown"
              title={
                <span className="text-dark-purple text-decoration-underline">
                  {selectedOptions.length
                    ? listFormatter.format(
                        selectedOptions?.map((o) => o?.label)
                      )
                    : `Select ${description}`}
                </span>
              }
              disabled={disabled}
              className={additionalClasses}
            >
              {options.map(({ id, label, value }, i) => {
                const checked = field.value?.includes(value);
                return (
                  <React.Fragment key={`${id}-${i}`}>
                    {id === "seperator" && <Dropdown.Divider />}
                    {id !== "seperator" && (
                      <div key={id} role="menuitem" className="py-1">
                        <label
                          style={{
                            marginLeft: "1rem",
                            marginTop: "0.5rem",
                            fontWeight: "normal",
                            display: "flex",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ height: "unset", marginRight: "1rem" }}
                            onChange={(e) => {
                              e.stopPropagation();

                              onToggleMultiSelectValue({
                                id: id,
                                checked: e.target.checked,
                              });
                            }}
                            checked={checked}
                          />
                          {label}
                        </label>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </DropdownButton>
          );
        }}
      />
    </Form.Group>
  );
};

const DateField = ({
  label,
  toolTip,
  placeholder,
  fieldName,
  errors,
  control,
  rules,
  disabled,
}) => {
  const error = get(errors, fieldName);
  return (
    <Form.Group>
      {label && <Label toolTip={toolTip}>{label}</Label>}
      <div>
        <Controller
          name={fieldName}
          control={control}
          rules={rules}
          render={({ field }) => {
            const date = field.value && new Date(Date.parse(field.value));
            return (
              <ReactDatePicker
                className={`form-control w-100 ${error && "is-invalid"}`}
                placeholderText={placeholder}
                disabled={disabled}
                selected={date}
                onChange={(date) =>
                  date
                    ? field.onChange(date.toISOString())
                    : field.onChange(null)
                }
              />
            );
          }}
        />
        {error && (
          <span className="text-danger" style={{ marginLeft: "2rem" }}>
            <br />
            {error?.message}
          </span>
        )}
      </div>
    </Form.Group>
  );
};

// return lists of components to render out for condition based on selectedOption
const ConditionValues = ({
  fieldName,
  control,
  engine = "pricing",
  conditionOptions,
  selectedOption,
  accessor,
}) => {
  const [operator] = selectedOption ? JSON.parse(selectedOption) : [null, null];
  const metricName =
    accessor?.replace(/(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9]+$)/g, "") ?? "";

  if (!operator) {
    return null;
  }

  if (
    accessor.includes("target_match_types") ||
    accessor.includes("keyword_match_types")
  ) {
    return (
      <>
        <span className="px-3">with</span>
        <span className="px-3">
          <InlineMultiSelect
            description={"Match Types"}
            fieldName={`${fieldName}.${accessor}.0`}
            control={control}
            rules={{ required: true }}
            options={
              conditionOptions?.find((o) => o.value === accessor)?.selectOptions
            }
          />
        </span>
        <span className="px-3">match types</span>
      </>
    );
  }

  if (
    RELATIVE_METRIC_FIELD_ACCESSORS.map(
      (relativeField) => relativeField?.accessor
    )?.some((metric) => accessor.includes(metric))
  ) {
    const compareValueOptions =
      RELATIVE_METRIC_FIELD_ACCESSORS?.find(
        (metric) => metric.accessor === metricName
      )?.options ?? [];

    if (["between"].includes(operator)) {
      return (
        <>
          <InlineInputSelectComboField
            fieldName={`${fieldName}.values.0`}
            control={control}
            rules={{ required: true }}
            placeholder={null}
            metricCompareValueOptions={[
              { value: "value", label: "Value" },
              ...compareValueOptions,
            ]}
          />
          <span className="px-3">and</span>
          <InlineInputSelectComboField
            fieldName={`${fieldName}.values.1`}
            control={control}
            rules={{ required: true }}
            placeholder={null}
            metricCompareValueOptions={[
              { value: "value", label: "Value" },
              ...compareValueOptions,
            ]}
          />
        </>
      );
    }

    return (
      <InlineInputSelectComboField
        fieldName={`${fieldName}.values.0`}
        control={control}
        rules={{ required: true }}
        placeholder={null}
        metricCompareValueOptions={[
          { value: "value", label: "Value" },
          ...compareValueOptions,
        ]}
      />
    );
  }

  if ([">", "<", ">=", "<=", "="].includes(operator)) {
    return (
      <>
        <InlineInputField
          fieldName={`${fieldName}.values.0`}
          control={control}
          rules={{ required: true }}
          placeholder={null}
        />
      </>
    );
  }

  if (["between"].includes(operator)) {
    return (
      <>
        <InlineInputField
          fieldName={`${fieldName}.values.0`}
          control={control}
          rules={{ required: true }}
          placeholder={null}
        />
        <span className="px-3">and</span>
        <InlineInputField
          fieldName={`${fieldName}.values.1`}
          control={control}
          rules={{ required: true }}
          placeholder={null}
        />
      </>
    );
  }

  return null;
};

const ConditionScope = ({ fieldName, control, conditionOptions }) => {
  const days = useWatch({
    name: `${fieldName}.scope.values.days`,
    control,
  });

  return (
    <>
      <span className={"px-3"}>over</span>
      <InlineInputField
        fieldName={`${fieldName}.scope.values.days`}
        control={control}
        rules={{ required: true }}
        placeholder={null}
        shouldUnregister
      />
      <span className={"px-3"}>{days > 1 ? "days" : "day"}</span>
    </>
  );
};

export {
  Label,
  InputField,
  DateField,
  InlineSelect,
  InlineInputField,
  MetricCompareSelectField,
  ConditionValues,
  ConditionScope,
};
