import React from "react";
import { Container as Grid, Row, Col } from "react-bootstrap";
import logo from "assets/images/logo/Trellis_Logomark_color.png";

const Loading = ({ text, height, fullPage = true }) => {
  return (
    <div
      // NOTE: If full page, .main-content creates container that takes up entire dashboard inner dimensions (full viewport minus height of navbar and width of sidebar)
      // Otherwise, if fullPage prop is false, relative position will center spinner relative to parent container dimensions
      className={fullPage ? "main-content" : "position-relative"}
      style={{
        backgroundColor: "#FFF",
        padding: "0px",
      }}
    >
      <Grid fluid>
        <Row style={{ margin: "0px" }}>
          <Col
            md={12}
            style={{
              backgroundColor: "#ffffff",
              height: height || "65vh",
              paddingLeft: "0px",
              paddingRight: "0px",
              overflow: "hidden",
            }}
          >
            <Row
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Col md={12}>
                <div className={fullPage ? "text_above_button" : ""}>
                  {text}
                  <br />
                  <img
                    src={logo}
                    alt=""
                    className="rotate"
                    width="80"
                    height="auto"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Loading;
