import api from "utils/api";
import { useMutation } from "react-query";
import { useProgramContext } from "./ProgramContext";

const useEditProgram = ({ id, programType, onSuccess, onError }) => {
  const { engine } = useProgramContext();
  const editProgram = useMutation(
    async (data) => {
      const { name, start_date, end_date, ...templateFields } = data;
      let ui_state = templateFields;
      let spec = {};
      if (programType === "CUSTOM") {
        ui_state = {};
        spec = templateFields;
      }
      const body = {
        name,
        start_date,
        end_date,
        ui_state,
        spec,
        engine,
      };
      await api.patch(`/programs/${id}/`, {
        ...body,
        status: "scheduled",
      });
    },
    {
      onMutate: () => {},
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        if (onError) onError(error);
        console.log(error);
      },
    }
  );
  return editProgram;
};

export default useEditProgram;
