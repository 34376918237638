import React from "react";
import ToolTips from "utils/toolTips";
import TotalSalesVendorWarning from "./TotalSalesVendorWarning";

const SummaryMetric = ({
  id,
  name,
  value,
  formatter,
  target,
  showTarget = false,
  lower = false,
  targetLabel = "vs. target",
  targetColor = null,
  legendColor = null,
  targetFormatter = null,
  mobileStyles,
  additionalMobileStyles = {},
  size = "normal",
  selected = null,
  toolTip = null,
  textAlign = "right",
  orgType,
  channel,
}) => {
  const defaultFormatter = (v) => v;
  const format = formatter || defaultFormatter;
  const formatTarget = targetFormatter || format;
  const cmp = (a, b) => (lower ? a <= b : a >= b);
  let color = cmp(value, target) ? "#007000" : "#d2222d";
  if (targetColor) {
    color = targetColor;
  }
  let selectedStyles = {};
  if (selected) {
    selectedStyles = { boxShadow: `0 0 14px ${legendColor}` };
  } else if (selected === false) {
    selectedStyles = {
      backgroundColor: `${legendColor}33`,
    };
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: textAlign === "center" ? "center" : "flex-end",
      }}
    >
      <div
        className={
          mobileStyles || textAlign === "center" ? "text-center" : "text-end"
        }
        style={{
          ...(mobileStyles
            ? { ...additionalMobileStyles }
            : { marginRight: textAlign === "center" ? 0 : "10px" }),
        }}
      >
        <h6
          style={{ height: "34px", fontWeight: selected ? 800 : 600 }}
          className={`${
            textAlign === "center" ? "text-center" : ""
          } fs-standard`}
        >
          {name === "Sales" && (
            <TotalSalesVendorWarning orgType={orgType} channel={channel} />
          )}
          {name}
          {toolTip && <ToolTips id={id} toolTip={toolTip} />}
        </h6>

        <h3
          style={{
            fontWeight: 600,
            fontSize: mobileStyles || size === "small" ? "2rem" : "4rem",
            marginBottom: "0.2rem",
            marginTop: "0.2rem",
          }}
        >
          {format(value)}
        </h3>
        <div style={{ minHeight: "20px" }}>
          <small>
            {showTarget ? (
              <>
                {targetLabel}{" "}
                <span style={{ color, fontWeight: "bold" }}>
                  {formatTarget(target)}
                </span>
              </>
            ) : (
              " "
            )}
          </small>
        </div>
      </div>
      <div
        style={{
          transition: "all 0.30s ease-in-out",
          marginLeft: "1rem",
          backgroundColor: legendColor,
          borderColor: legendColor,
          borderSize: "1px",
          width: "6px",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          display: legendColor ? "block" : "none",
          ...selectedStyles,
        }}
      ></div>
    </div>
  );
};

export default SummaryMetric;
