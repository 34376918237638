import React from "react";
import AnnotationsTable from "components/custom/growth/AnnotationsTable";
import { useFetch } from "hooks/api";
import { useSelector } from "react-redux";
import { useDates } from "dates/useDates";

const ChangeLog = ({ annotationEntityId, annotationEntityType }) => {
  const { group, start, end } = useDates();
  const { channel, marketPlace } = useSelector((state) => state);

  const { data: annotationData, isLoading: isAnnotationDataLoading } = useFetch(
    [
      "annotations",
      start,
      end,
      channel,
      annotationEntityId,
      marketPlace.marketPlace,
    ],
    "/api/annotations/",
    {
      groupby: group,
      ts_after: `${start.format("YYYY-MM-DD")}T00:00:00`,
      ts_before: `${end.format("YYYY-MM-DD")}T23:59:59`,
      marketplace: marketPlace.marketPlace,
      channel:
        channel.currentChannel === "amazon" ? "AMZ" : channel.currentChannel,
      [annotationEntityType]: annotationEntityId,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!annotationEntityId,
    }
  );

  return (
    <AnnotationsTable
      data={annotationData?.data ?? []}
      isLoading={isAnnotationDataLoading}
      marketPlace={marketPlace.marketPlace}
    />
  );
};

export default ChangeLog;
