import { useContext } from "react";
import { LegendContext } from "./LegendContext";

export const useLegend = (chart) => {
  const { legends, handleSetLegends } = useContext(LegendContext);

  const setLegend = (legendOptions) => {
    handleSetLegends({ ...legends, [chart]: legendOptions });
  };

  return [legends[chart], setLegend];
};
