import React, { useMemo, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { formatCurrency } from "utils/formatNumber";
import NoImage from "assets/images/utility/no_image.png";
import FormGroupTile from "components/core/basic/FormGroupTile";
import ReactTable from "react-table-6";
import ProductChart from "../category/listings/ProductChart";
import useDefaultState from "hooks/useDefaultState";
import useColumnSelector from "hooks/useColumnSelector";
import {
  INVENTORY_STATES,
  SALE_STATES,
} from "components/core/blocks/AdPlan/constants";
import Select from "react-select";
import ReactTablePagination from "../growth/ProductsCVTablePagination";
import tableColumns from "./TableColumns";
import isSeller from "./util";
import TrComponent from "./TrComponent";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import Accordion from "components/core/basic/Accordion";
import moment from "moment";
import { INVENTORY_STATE_OPTIONS, SALE_STATE_OPTIONS } from "./constants";

const SubValue = ({ name, children, labelSize = 6, valueSize }) => (
  <Row style={{ marginBottom: "1rem" }}>
    <Col xs={labelSize}>
      <strong>{name}:</strong>
    </Col>
    <Col xs={valueSize ?? 12 - labelSize}>{children}</Col>
  </Row>
);

const mapProductStates = (states) => {
  return states.map((state) => ({
    label: state.label,
    value: state.value,
  }));
};

const ExpandedContent = ({
  className,
  children,
  style,
  row,
  plan,
  colToggle,
}) => {
  let attributes = [];
  const queryClient = useQueryClient();

  const [product, marketPlace] = useMemo(() => {
    const product = row?.original?.product ?? {};
    return [product, { marketPlace: product?.market_place ?? "USA" }];
  }, [row]);

  const updateProductState = useMutation(
    async (data) => {
      return api.patch(`/merchandise/gvproducts/${data.id}/`, data);
    },
    {
      onError: async (error, variables) => {
        console.log("Error updating product.", error, variables);
      },
      onSuccess: async () => {
        queryClient.invalidateQueries("plans");
      },
    }
  );

  const deleteProduct = useMutation(
    async (id) => {
      return api.delete(`/merchandise/plan_org_products/${id}/`);
    },
    {
      onError: async (error, variables) => {
        console.log("Error deleting product.", error, variables);
      },
      onSuccess: async () => {
        queryClient.invalidateQueries("plans");
        queryClient.invalidateQueries("plan_stats");
      },
    }
  );

  const fmt = (value) =>
    value ? formatCurrency(value, marketPlace.marketPlace) : "N/A";
  const fmtPct = (value) => (value ? `${Math.round(value * 100)}%` : "N/A");

  if (isSeller(plan)) {
    attributes = [
      { name: "Retail Price", value: fmt(product?.product_price) },
      {
        name: "Landed COGS",
        value: fmt(product.landed_cogs),
      },
      { name: "Organic Rank", value: product?.bsr_rank || "N/A" },
      /*
      { name: "Margin", value: fmtPct(product?.merchandise_details?.margin) },
      {
        name: "Min Margin",
        value: fmtPct(product?.merchandise_details?.min_margin),
      },
      {
        name: "Max Discount",
        value: fmtPct(product?.merchandise_details?.max_discount),
      },
      */
    ];
  } else {
    attributes = [
      { name: "Retail Price", value: fmt(product?.product_price) },
      {
        name: "Landed Cost",
        value: fmt(product?.master_product?.landed_price),
      },
      { name: "Net Price", value: fmt(product?.net_price) },
      { name: "Gross Price", value: fmt(product?.gross_price) },
      { name: "Organic Rank", value: product?.bsr_rank || "N/A" },
      /*
      { name: "Margin", value: fmtPct(product?.merchandise_details?.margin) },
      {
        name: "Min Margin",
        value: fmtPct(product?.merchandise_details?.min_margin),
      },
      {
        name: "Max Discount",
        value: fmtPct(product?.merchandise_details?.max_discount),
      },
      */
    ];
  }

  const actualVsTarget = [
    {
      name: "Sales",
      actual: fmt(row.original.actual_total_sales),
      target: fmt(row.original.target_sales),
    },
    {
      name: "Cost",
      actual: fmt(row.original.actual_total_cost),
      target: fmt(row.original.target_cost),
    },
    {
      name: "Units",
      actual: row.original.actual_total_units,
      target: row.original.target_units,
    },
    {
      name: "Ad Spend",
      actual: fmt(row.original.actual_ad_spend),
      target: fmt(row.original.target_ad_spend),
    },
    {
      name: "Margin",
      actual: fmtPct(row.original.actual_margin),
      target: fmtPct(row.original.target_margin),
    },
    {
      name: "Net Margin",
      actual: fmtPct(row.original.actual_net_margin),
      target: fmtPct(row.original.target_net_margin),
    },
  ];

  const inventoryStates = mapProductStates(INVENTORY_STATE_OPTIONS);
  let inventoryStatus = INVENTORY_STATE_OPTIONS.find(
    (item) => item.value === product.inventory_state
  );
  const saleStates = mapProductStates(SALE_STATE_OPTIONS);
  let saleStatus = SALE_STATE_OPTIONS.find(
    (item) => item.value === product.sales_state
  );

  return (
    <>
      {row && row.original.isExpanded && (
        <div style={{ backgroundColor: "#efefef", padding: "2rem" }}>
          <Row>
            <Col xs={6}>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} style={{ display: "flex" }}>
                  <div
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = NoImage;
                    }}
                    style={{
                      marginRight: "1rem",
                      userSelect: "none",
                      WebkitUserSelect: "none",
                      width: 64,
                      height: 64,
                      borderRadius: 8,
                      border: "1px solid #eee",
                      background: `url(${product.product_image_url}) no-repeat center / contain`,
                    }}
                    draggable="false"
                  />
                  <div style={{ marginTop: "1rem" }} className="fs-standard">
                    {product.product_title.replace("SIMPLIHOME ", "")}
                    <br />
                    <small>{product.sku}</small>
                  </div>
                </Col>
              </Row>
              <Accordion title={"Details"} initialExpanded marginTop={0}>
                <Row>
                  <Col xs={6} className="fs-standard">
                    <Row>
                      <Col xs={12}>
                        {attributes.map(({ name, value }) => (
                          <Row>
                            <Col xs={12} key={name}>
                              <SubValue name={name}>
                                <div className="text-end">{value}</div>
                              </SubValue>
                            </Col>
                          </Row>
                        ))}
                        <Row>
                          <Col xs={4}>
                            <SubValue
                              name={"Inventory State"}
                              labelSize={12}
                              valueSize={12}
                            />
                          </Col>
                          <Col xs={8}>
                            <Select
                              value={inventoryStatus}
                              options={inventoryStates}
                              onChange={(item) => {
                                updateProductState.mutate({
                                  inventory_state: item.value,
                                  id: product.id,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <SubValue
                              name={"Sales State"}
                              labelSize={12}
                              valueSize={12}
                            />
                          </Col>
                          <Col xs={8}>
                            <Select
                              value={saleStatus}
                              options={saleStates}
                              onChange={(item) => {
                                updateProductState.mutate({
                                  sales_state: item.value,
                                  id: product.id,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {colToggle === "review" && (
                    <Col xs={6} className="px-4">
                      <Row>
                        <Col
                          xs={12}
                          className="card"
                          style={{ padding: "1rem" }}
                        >
                          <ReactTable
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            columns={[
                              {
                                id: "metric",
                                Header: "",
                                headerClassName: "wordwrap",
                                headerStyle: {
                                  textAlign: "center",
                                  padding: "1.5rem",
                                },
                                style: {
                                  textAlign: "center",
                                  padding: "1.5rem",
                                  fontWeight: 500,
                                },
                                accessor: "name",
                                filterMethod: (filter, row) => true,
                                Filter: ({ filter, onChange }) => null,
                              },
                              {
                                id: "target",
                                Header: "Target",
                                headerClassName: "wordwrap",
                                headerStyle: {
                                  textAlign: "center",
                                  padding: "1.5rem",
                                },
                                style: {
                                  textAlign: "center",
                                  padding: "1.5rem",
                                  fontWeight: 500,
                                },
                                accessor: "target",
                                filterMethod: (filter, row) => true,
                                Filter: ({ filter, onChange }) => null,
                              },
                              {
                                id: "actual",
                                Header: "Actual",
                                headerClassName: "wordwrap",
                                headerStyle: {
                                  textAlign: "center",
                                  padding: "1.5rem",
                                },
                                style: {
                                  textAlign: "center",
                                  padding: "1.5rem",
                                  fontWeight: 500,
                                },
                                accessor: "actual",
                                filterMethod: (filter, row) => true,
                                Filter: ({ filter, onChange }) => null,
                              },
                            ]}
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.05)",
                              borderRadius: "15px",
                            }}
                            data={actualVsTarget}
                            minRows={0}
                            className="-striped -highlight"
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Accordion>
              <Accordion
                title={"Promotions"}
                initialExpanded={row.original.metadata?.promotions?.length > 0}
                marginTop="1rem"
              >
                <Row>
                  {!row.original.metadata?.promotions?.length && (
                    <span>No Promotions</span>
                  )}
                  {row.original.metadata?.promotions?.map(
                    ({ id, name, start, end }) => (
                      <Col key={id} xs={12}>
                        <strong>{name}</strong>
                        <br />
                        {moment(start).format("MMM Do")} to{" "}
                        {moment(end).format("MMM Do")}
                      </Col>
                    )
                  )}
                </Row>
              </Accordion>
              {row.original.metadata?.warnings?.length > 0 && (
                <Accordion
                  title={"Warnings"}
                  initialExpanded={row.original.metadata?.warnings?.length > 0}
                  marginTop="1rem"
                >
                  <Row>
                    {row.original.metadata?.warnings?.map((w) => (
                      <Col key={w} xs={12}>
                        {w}
                      </Col>
                    ))}
                  </Row>
                </Accordion>
              )}
              <Row style={{ marginTop: "2rem" }}>
                <Col style={{ marginLeft: "2rem" }}>
                  <ConfirmationModal
                    title="Remove Product from Plan"
                    modalButtonText="Remove"
                    confirmButtonText="Remove"
                    confirmationMessage="Are you sure you want to remove this product from the plan?"
                    buttonStyle="logout_button"
                    onConfirm={() => deleteProduct.mutate(row.original.id)}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <ProductChart
                  pInfo={product}
                  orgId={product.org}
                  showStatsTile={false}
                  context={"merchandiseListing"}
                  series={[
                    "Units Ordered",
                    "Total Sales",
                    "Ad Sales",
                    "Ad Spend",
                    "BSR",
                    "Product Price",
                  ]}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

const ProductsTable = ({
  plan,
  selectedOrgPlan,
  marketPlace,
  setSelectedOrgPlan,
  orgPlansOptions,
  categories,
  onCategoryChange,
  inventoryState,
  setInventoryState,
  salesState,
  setSalesState,
}) => {
  const [productOrgPlans, setProductOrgPlans] = useState({});
  const [colToggle, setColToggle] = useDefaultState(
    ["planning", "planned"].find((s) => s === plan.state)
      ? "planning"
      : "review"
  );
  const queryClient = useQueryClient();
  const productPlanUpdateHandlerRef = useRef(null);
  const productOrgPlanUpdateHandlerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [filters, setFilters] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const updateProductOrgPlan = useMutation(
    async ({ id, ...changes }) => {
      return await api.patch(`/merchandise/plan_org_products/${id}/`, changes);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["plans", "" + plan.id]);
        queryClient.invalidateQueries(["plans_stats", "" + plan.id]);
      },
    }
  );

  const inventoryOptions = [
    { value: "ALL", label: "All Statuses", description: "All" },
    INVENTORY_STATES.LOW_STOCK,
    INVENTORY_STATES.OUT_OF_STOCK,
    INVENTORY_STATES.OVERSTOCK,
  ];

  const salesOptions = [
    SALE_STATES.BEST_SELLER,
    SALE_STATES.CLEARANCE,
    SALE_STATES.NEW_PRODUCT,
    SALE_STATES.NORMAL,
  ];

  const productOptions = inventoryOptions.concat(salesOptions);

  const onOrgPlanProductChange = (id, fields) => {
    if (productOrgPlanUpdateHandlerRef.current) {
      clearTimeout(productPlanUpdateHandlerRef.current);
    }
    setProductOrgPlans((productOrgPlans) => {
      const oldFields = productOrgPlans[id] ?? {};
      const changes = { ...oldFields, ...fields };
      productOrgPlanUpdateHandlerRef.current = setTimeout(() => {
        updateProductOrgPlan.mutate({ id, ...changes });
      }, 500);
      return { ...productOrgPlans, [id]: changes };
    });
  };

  const handleCategoryChange = (val) => {
    setCategoryFilter(val);
    if (onCategoryChange) {
      onCategoryChange(val);
    }
  };

  const data = useMemo(() => {
    return plan?.org_plans
      .reduce((a, p) => {
        for (const orgPlanProduct of p.plan_org_products) {
          a.push({
            ...orgPlanProduct,
            channelState: productOrgPlans?.[orgPlanProduct.id],
            isExpanded: orgPlanProduct.id === isExpanded,
          });
        }
        return a;
      }, [])
      .sort((a, b) => a.id - b.id)
      .filter((p) => selectedOrgPlan === null || p.org_plan === selectedOrgPlan)
      .filter((p) => {
        if (searchFilter) {
          const title = `${p.product.sku} ${p.product.product_title} ${
            p.product.asin
          } ${p.product.tags?.join(" ")}`.toLowerCase();
          return title.indexOf(searchFilter.toLowerCase()) >= 0;
        }
        return true;
      })
      .filter((p) => {
        if (categoryFilter) {
          return categoryFilter.includes(p.product?.product_category);
        }
        return true;
      })
      .filter((p) => {
        if (inventoryState) {
          return inventoryState.includes(p.product?.inventory_state);
        }
        return true;
      })
      .filter((p) => {
        if (salesState) {
          return salesState.includes(p.product?.sales_state);
        }
        return true;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    plan,
    selectedOrgPlan,
    productOrgPlans,
    isExpanded,
    searchFilter,
    categoryFilter,
    inventoryState,
    salesState,
  ]);

  // // This seems to be printing a lot. It may be okay but im unsure of desired behaviour.
  const [columns, columnSelectorProps] = useColumnSelector(
    () =>
      tableColumns(
        isExpanded,
        setIsExpanded,
        marketPlace,
        colToggle,
        data,
        onOrgPlanProductChange,
        plan
      ),
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      data,
      isExpanded,
      colToggle,
    ]
  );
  const columnOrder = columns.map((col) => col.id);
  const categoryOptions = [{ label: "All Categories", value: null }].concat(
    categories?.map((category) => ({
      label: category.name,
      value: category.orgs.map((org_category) => org_category.category_id),
    })) ?? []
  );

  return (
    <>
      <Row style={{ marginBottom: "1rem" }}>
        <Col xs="2">
          <FormGroupTile
            type="select"
            multi={false}
            options={categoryOptions}
            defaultValue={categoryOptions.find(
              (opt) => opt.value === categoryFilter
            )}
            stateName={"category"}
            handleChildFormElement={(key, value) =>
              handleCategoryChange(value.value)
            }
          />
        </Col>
        <Col xs="2">
          <FormGroupTile
            type="select"
            multi={false}
            stateName={"selectedOrgPlan"}
            handleChildFormElement={(key, value) =>
              setSelectedOrgPlan(value.value)
            }
            defaultValue={orgPlansOptions.find(
              (opt) => opt.value === selectedOrgPlan
            )}
            options={orgPlansOptions}
          />
        </Col>
        <Col xs="2">
          <FormGroupTile
            type="select"
            multi={false}
            stateName={"salesState"}
            handleChildFormElement={(key, value) => {
              const hasSalesVal = salesOptions.find(
                (st) => st.value === value.value
              );
              if (value.value === "ALL") {
                setSalesState(null);
                setInventoryState(null);
              } else if (hasSalesVal) {
                setSalesState(value.value);
                setInventoryState(null);
              } else {
                setSalesState(null);
                setInventoryState(value.value);
              }
            }}
            defaultValue={
              productOptions.find(
                (o) => o.value === (salesState || inventoryState)
              ) ?? productOptions[0]
            }
            options={productOptions}
          />
        </Col>
        <Col xs="3"></Col>
        <Col xs="3" className="d-flex justify-content-end align-items-center">
          <ButtonGroup>
            <Button
              onClick={() => setColToggle("planning")}
              variant={colToggle === "planning" ? `contained` : null}
              color={colToggle === "planning" ? `secondary` : null}
            >
              Planning
            </Button>
            <Button
              onClick={() => setColToggle("review")}
              variant={colToggle === "review" ? `contained` : null}
              color={colToggle === "review" ? `secondary` : null}
            >
              Review
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <ReactTable
        PaginationComponent={ReactTablePagination}
        getPaginationProps={() => ({
          updateSearch: setSearchFilter,
          clearFilters: () => {
            setFilters([]);
            setSearchFilter("");
            setClearFilters(true);
          },
          clearSearchTermFilters: () => setSearchFilter(""),
          searchTerm: searchFilter,
          setFilterState: setFilters,
          filteredState: filters,
          table: "plan-products",
          setShowFilters: setShowFilters,
          showFilters: showFilters,
          style: { marginBottom: "2rem" },
          columnSelectorProps: columnSelectorProps,
          carouselLayout: false,
          disableSearchFocus: true,
        })}
        getTrProps={(table, row) => {
          let style = {};
          if (row && colToggle === "review") {
            // if we're less than a 3rd through the plan don't bother to highlight.
            const diff = row.original.progress_units;
            if (diff !== null) {
              if (diff > 0.95) {
                style = { backgroundColor: "#90EE9021" };
              }
              if (diff <= 0.8) {
                style = { backgroundColor: "#F0808021" };
              }
            }
          }
          let expandedChildren = (
            <ExpandedContent
              style={style}
              row={row}
              plan={plan}
              colToggle={colToggle}
            />
          );

          return { row, style, expandedChildren };
        }}
        getTheadFilterProps={(props) => {
          if (clearFilters) {
            props.filtered.forEach((filter, index) => {
              filter.value = "all";
            });
            setClearFilters(false);
          }
          return { style: { display: "flex" } };
        }}
        TrComponent={TrComponent}
        showPaginationTop
        showPaginationBottom={false}
        columns={columns}
        data={data}
        defaultPageSize={10}
        className="-highlight"
        filterable={showFilters}
        state={columnOrder}
      />
    </>
  );
};

export default ProductsTable;
