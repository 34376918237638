import React, { useState } from "react";
import { Row, Col, Table, Nav, Modal, Badge } from "react-bootstrap";
import ListinQuality from "modules/product_quality/components/ListingQuality";
import Dates from "modules/product_quality/components/Dates";
import { trellisPalette } from "components/custom/analytics/palettes";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { Link } from "react-router-dom";
import { TooltipBuyability, TooltipSearchability } from "./Tooltips";

const SideMenuTop = ({ scores, variations, images, saveDate, publishDate }) => {
  const [secondaryKey, setSecondaryKey] = useState("score_details");
  const [showIssuesDetails, setShowIssuesDetails] = useState(false);

  const getAttributeNames = (attributes) => {
    return attributes.map((attribute, index) => {
      return (
        <Badge key={index} style={{ marginRight: "5px" }} bg={`tr-secondary`}>
          {attribute}
        </Badge>
      );
    });
  };

  return (
    <>
      <div className="card px-4 py-4 mb-4">
        <Nav
          variant="underline"
          onSelect={(selectedKey) => {
            setSecondaryKey(selectedKey);
          }}
          activeKey={secondaryKey}
        >
          <Nav.Item style={{ width: "33%" }}>
            <Nav.Link
              eventKey="score_details"
              className="fs-4"
              style={{ color: "#000" }}
            >
              Score Details
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ width: "33%" }}>
            <Nav.Link
              eventKey="images"
              className="fs-4"
              style={{ color: "#000" }}
            >
              Images
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ width: "33%" }}>
            <Nav.Link
              eventKey="variations"
              className="fs-4"
              style={{ color: "#000" }}
            >
              Variations
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div
          className="pt-3"
          style={{
            maxHeight: "600px",
            overflow: "hidden",
            overflowY: "scroll",
            paddingTop: "2px",
            paddingBottom: "10px",
          }}
        >
          {secondaryKey === "score_details" && (
            <>
              <Row>
                <Col>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Details</th>
                        <th className="text-end">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Searchability
                          <TooltipSearchability />
                        </td>
                        <td className="text-end">
                          {scores.seo_score.toFixed(0)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Buyability
                          <TooltipBuyability />
                        </td>
                        <td className="text-end">
                          {scores.buyability_score.toFixed(0)}
                        </td>
                      </tr>

                      <tr>
                        <td>Issues</td>
                        <td className="text-end">
                          <button
                            style={{
                              color: trellisPalette[0],
                              fontWeight: 600,
                              border: 0,
                              background: "rgba(0,0,0,0)",
                            }}
                            onClick={() => setShowIssuesDetails(true)}
                          >
                            {scores.issues.length}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col className="pt-5">
                  <Dates saveDate={saveDate} publishDate={publishDate} />
                </Col>
              </Row>
              <Row>
                <Col className="pt-5">
                  <ListinQuality contentDetails={scores.content_details} />
                </Col>
              </Row>
            </>
          )}
          {secondaryKey === "variations" &&
            variations &&
            variations.length > 0 && (
              <Row>
                <Col>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ASIN</th>
                        <th className="text-end">Searchability</th>
                        <th className="text-end">Buyability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {variations.map((v, iv) => (
                        <tr key={iv}>
                          <td>
                            <Link to={`/user/content/seo?id=${v.product.id}`}>
                              {v.product.asin}
                            </Link>
                          </td>
                          <td className="text-end">{v.seo_score.toFixed(0)}</td>
                          <td className="text-end">
                            {v.buyability_score.toFixed(0)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}

          {secondaryKey === "variations" && !variations && (
            <Row>
              <Col className="text-center">No varations found</Col>
            </Row>
          )}

          {secondaryKey === "images" && images && images["thumbnail"] && (
            <>
              <Row>
                {Object.keys(images["thumbnail"]).map((img, ii) => (
                  <Col xs={4} key={ii} className="text-center mb-2">
                    <img
                      src={images["thumbnail"][img]}
                      alt={`Product Variation ${ii}`}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </div>
      <Modal
        show={showIssuesDetails}
        onHide={() => setShowIssuesDetails(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-lg"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            Content Issues
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              {scores && (
                <Table hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Issue</th>
                      <th style={{ textAlign: "left" }}>Severity</th>
                      <th style={{ textAlign: "left" }}>
                        Amazon
                        <br />
                        Code
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scores?.issues?.map((issue, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {issue.message}
                            {issue.attributeNames && (
                              <div style={{ marginTop: "10px" }}>
                                {getAttributeNames(issue.attributeNames)}
                              </div>
                            )}
                          </td>
                          <td>{issue.severity}</td>
                          <td>{issue.code}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="mx-auto py-4">
            <Col className="text-center">
              <LogoutButton
                fontSize="12px"
                key="RefreshBtn"
                title={`Close`}
                onClick={() => setShowIssuesDetails(false)}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SideMenuTop;
