import React, { useEffect, useState, useRef } from "react";
import { FormGroup, FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  setOverrideModule,
  unsetOverrideModule,
} from "redux/actions/overrideModuleActions";
import store from "redux/store";

const SearchBar2 = ({
  handleSearchQuery,
  defaultQueryValue = "",
  pathname,
  resultModalSearchbar = false,
}) => {
  const [query, setQuery] = useState(defaultQueryValue);

  const searchBarRef = useRef();

  const state = useSelector((state) => state);

  const { user } = state;

  useEffect(() => {
    if (!resultModalSearchbar) {
      setQuery("");
    }
  }, [pathname, resultModalSearchbar]);

  const onSubmitSearch = (e) => {
    handleSearchQuery(query);
  };

  return (
    <FormGroup
      style={{ width: "100%", maxWidth: "100%" }}
      className="navbar-search"
    >
      <InputGroup>
        <FormControl
          type="text"
          placeholder="Search..."
          style={{ margin: "0px", paddingLeft: "25px" }}
          value={query}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (query?.indexOf("set-module:") === 0) {
                const module = query.split("set-module:")[1].trim();
                store.dispatch(setOverrideModule(user.organization_id, module));
                handleSearchQuery("");
                setQuery("");
              } else if (query?.indexOf("unset-module:") === 0) {
                const module = query.split("set-module:")[1].trim();
                store.dispatch(
                  unsetOverrideModule(user.organization_id, module)
                );
                handleSearchQuery("");
                setQuery("");
              } else {
                onSubmitSearch();
              }
            }
          }}
          ref={searchBarRef}
          onChange={(e) => setQuery(e.target.value)}
        />

        <button
          style={{
            outline: "none",
            background: "#FFF",
            color: "#BBB",
            width: "35px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #e3e3e3",
            borderLeft: "none",
            cursor: query?.length ? "pointer" : "text",
          }}
          onClick={(e) => {
            e.preventDefault();
            setQuery("");
            if (searchBarRef.current) {
              searchBarRef.current.focus();
            }
          }}
          className="fs-standard pl-3"
        >
          <i
            className="fa fa-close me-3 fw-light"
            style={{
              fontWeight: 400,
              display: query?.length ? "inherit" : "none",
            }}
          />
        </button>
        <button
          style={{
            border: "1px solid #e3e3e3",
            borderLeft: "1px solid #e3e3e3",
            borderTopRightRadius: "25px",
            borderBottomRightRadius: "25px",
            outline: "none",
            background: "#FFF",
            color: "#BBB",
            width: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onSubmitSearch}
          className="fs-standard pl-3"
        >
          <i className="fa fa-search me-3" />
        </button>
      </InputGroup>
    </FormGroup>
  );
};

export default SearchBar2;
