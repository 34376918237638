function checkOnboardRedirect(amz_onboard, currentUrl = "") {
  let redirect = null;
  if (amz_onboard.loaded) {
    if (
      amz_onboard.org_status === "" ||
      amz_onboard.org_status === "onboarding"
    ) {
      if (
        (amz_onboard.org_status === "" ||
          amz_onboard.amazon_advertising_access === "pending" ||
          amz_onboard.amazon_advertising_connecting === true) &&
        currentUrl !== "/onboard/amzspapi"
      ) {
        redirect = "/onboard/amzadv";
      } else if (
        amz_onboard.amazon_spapi_access === "pending" &&
        amz_onboard.eu_amazon_spapi_access === "pending" &&
        amz_onboard.fe_amazon_spapi_access === "pending"
      ) {
        redirect = "/onboard/amzspapi";
      } else if (
        amz_onboard.amazon_spapi_access === "in_progress" ||
        amz_onboard.eu_amazon_spapi_access === "in_progress" ||
        amz_onboard.fe_amazon_spapi_access === "in_progress"
      ) {
        redirect = "/onboard/amzspapi";
      }
    }
  }

  return redirect;
}

export default checkOnboardRedirect;
