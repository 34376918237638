import React from "react";
import { useState, useMemo, useEffect, useRef } from "react";
import { Col, Row, Form } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import FormGroupTile from "components/core/basic/FormGroupTile";
import AssetCard from "./AssetCard";
import toggleArrayEntry from "utils/toggleArrayEntry";
import { useFetch } from "hooks/api";
import usePagination from "hooks/usePagination";
import AssetLabel from "./AssetLabel";
import Pagination from "./Pagination";
import SortIcon from "./SortIcon";
import "./asset_selector.css";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { ToolTips, TOGGLE_ADVANCED_FILTERS } from "utils/hiddenToolTips";
import Checkbox from "components/core/basic/CheckBox";
import useDebounce from "hooks/useDebounce";
import DimensionFilter from "./DimensionFilter";
import Switch from "react-bootstrap-switch";
import { AD_TYPES, CREATIVE_VALIDATIONS } from "../AdPlan/constants";
import CenteredModal from "components/core/basic/CenteredModal";
import AssetCropper from "./AssetCropper";
import { AuthButton } from "components/core/basic/Button";
import AssetSummary from "./AssetSummary";
import CreativePreview from "./CreativePreview";
import ReactCrop from "react-image-crop";
import { UpdateButton } from "components/core/blocks/AdPlan/buttons";

import Accordion from "components/core/basic/Accordion";

const ControlLabel = Form.Label;

const AssetSelector = ({
  marketPlace,
  state,
  products,
  onChange,
  handleAssetSelection,
  selected,
  selections = [],
  errors,
  fieldsToValidate,
  defaultPageSize = 5,
  multiSelect = false,
  review,
  allowExpandedView = true,
  hidden,
  customImageOptional,
  // Below prop takes in array of allowed asset type labels for controlling asset type filter options
  allowedAssetTypes = null,
  initialTypeFilter,
  hidePreviewField,
  onUpdate,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [sortBy, setSortBy] = useState("alphaAscending");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [assetCount, setAssetCount] = useState(0);
  const [assetType, setAssetType] = useState(
    initialTypeFilter === "VIDEO" ? "video" : "image"
  );

  const [eligibleAssetsFilter, setEligibleAssetsFilter] = useState(false);

  const [assetTypeFilter, setAssetTypeFilter] = useState(initialTypeFilter);
  const [assetSizeFilter, setAssetSizeFilter] = useState(null);
  const [assetDimensionFilter, setAssetDimensionFilter] = useState({
    width: null,
    height: null,
  });
  const [moderationStatusFilter, setModerationStatusFilter] = useState(null);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [searchByTags, setSearchByTags] = useState(false);
  const [cursorToken, setCursorToken] = useState(null);

  const [showCropDialogue, setShowCropDialogue] = useState(false);
  const [squareCropCoordinates, setSquareCropCoordinates] = useState({});
  const [rectangleCropCoordinates, setRectangleCropCoordinates] = useState({});
  const [cropType, setCropType] = useState("square");
  const [showCreativePreview, setShowCreativePreview] = useState(false);

  const renderTagsFilter = true;
  const renderAdvancedFilters = true;

  const assetContainerRef = useRef(null);

  const debouncedSearchFilter = useDebounce(searchFilter, 300);

  const { data, isLoading: isAssetDataLoading } = useFetch(
    [
      "assetSearch",
      currentPage,
      assetTypeFilter,
      debouncedSearchFilter,
      assetSizeFilter,
      moderationStatusFilter,
      assetDimensionFilter.height,
      assetDimensionFilter.width,
      sortBy,
      pageSize,
      searchByTags,
      cursorToken,
      marketPlace,
      state.adType,
    ],
    "/assets/",
    {
      type: assetTypeFilter === "VIDEO" ? assetTypeFilter : "IMAGE",
      subtype: assetTypeFilter === "VIDEO" ? null : assetTypeFilter,
      name: !searchByTags ? debouncedSearchFilter : null,
      min_file_size:
        typeof assetSizeFilter === "object" &&
        Number.parseInt(assetSizeFilter?.[0])
          ? Number.parseInt(assetSizeFilter?.[0])
          : null,
      max_file_size:
        typeof assetSizeFilter === "object" &&
        Number.parseInt(assetSizeFilter?.[1])
          ? Number.parseInt(assetSizeFilter?.[1])
          : null,
      min_height: assetDimensionFilter.height
        ? Number.parseInt(assetDimensionFilter.height)
        : null,
      min_width: assetDimensionFilter.width
        ? Number.parseInt(assetDimensionFilter.width)
        : null,
      moderation_request_status:
        moderationStatusFilter !== "all" ? moderationStatusFilter : null,
      tags: searchByTags ? searchFilter : null,
      page_size: pageSize,
      cursor: cursorToken,
      marketplace: marketPlace?.marketPlace,
    },
    {
      onSuccess: (data) => {
        setAssetCount(data?.count ?? 0);
      },
      select: (res) => res.data ?? [],
      default: [],
    }
  );

  let assetData = data?.results ?? [];

  let assetTypeFilterOptions = [
    {
      value: "LIFESTYLE_IMAGE",
      label: "Lifestyle Image",
      formattedName: "lifestyleImage",
    },
    {
      value: "PRODUCT_IMAGE",
      label: "Product Image",
      formattedName: "lifestyleImage",
    },
    { value: "LOGO", label: "Brand Logo", formattedName: "logo" },
    { value: "VIDEO", label: "Video", formattedName: "video" },
  ];

  // Check for existing ad, video must stay video, lifestyle image must stay lifestyle image
  if (state.id && state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
    if (state.videoDetails?.asset_id || state.video) {
      assetTypeFilterOptions = [
        { value: "LOGO", label: "Brand Logo", formattedName: "logo" },
        { value: "VIDEO", label: "Video", formattedName: "video" },
      ];
    }

    if (state.lifestyleImageDetails?.asset_id) {
      assetTypeFilterOptions = [
        {
          value: "LIFESTYLE_IMAGE",
          label: "Lifestyle Image",
          formattedName: "lifestyleImage",
        },
        {
          value: "PRODUCT_IMAGE",
          label: "Product Image",
          formattedName: "lifestyleImage",
        },
        { value: "LOGO", label: "Brand Logo", formattedName: "logo" },
      ];
    }
  }

  if (allowedAssetTypes) {
    assetTypeFilterOptions = assetTypeFilterOptions.filter((type) =>
      allowedAssetTypes.some((allowedType) => allowedType === type.label)
    );
  }

  const lifeStyleImageSizeOptions = [
    { value: [0, 5000000], label: "All" },
    { value: [0, 1000000], label: "0MB - 1MB" },
    { value: [1000000, 2000000], label: "1MB - 2MB" },
    { value: [2000000, 3000000], label: "2MB - 3MB" },
    { value: [3000000, 4000000], label: "3MB - 4MB" },
    { value: [4000000, 5000000], label: "4MB - 5MB" },
  ];
  const logoImageSizeOptions = [
    { value: [0, 1000000], label: "All" },
    { value: [0, 200000], label: "0kb - 200kb" },
    { value: [200000, 400000], label: "200kb - 400kb" },
    { value: [400000, 600000], label: "400kb - 600kb" },
    { value: [600000, 800000], label: "600kb - 800kb" },
    { value: [800000, 1000000], label: "800kb - 1MB" },
  ];

  const videoSizeOptions = [
    { value: [0, 50000000], label: "All" },
    { value: [0, 10000000], label: "0MB - 10MB" },
    { value: [10000000, 20000000], label: "10MB - 20MB" },
    { value: [20000000, 30000000], label: "20MB - 30MB" },
    { value: [30000000, 40000000], label: "30MB - 40MB" },
    { value: [40000000, 50000000], label: "40MB - 50MB" },
  ];

  const moderationStatusOptions = [
    { value: "all", label: "All" },
    { value: "COMPLETED", label: "Completed" },
    { value: "SKIPPED", label: "Skipped" },
  ];

  const filteredAssets = useMemo(() => {
    let sortedAssets = assetData;
    if (sortBy === "alphaAscending") {
      sortedAssets = assetData?.sort((a, b) =>
        a.name?.toLowerCase() < b.name?.toLowerCase()
          ? -1
          : a.name?.toLowerCase() > b.name?.toLowerCase()
          ? 1
          : 0
      );
    }

    if (sortBy === "alphaDescending") {
      sortedAssets = assetData?.sort((a, b) =>
        a.name?.toLowerCase() > b.name?.toLowerCase()
          ? -1
          : a.name?.toLowerCase() < b.name?.toLowerCase()
          ? 1
          : 0
      );
    }

    if (assetTypeFilter === "VIDEO" && eligibleAssetsFilter) {
      sortedAssets = sortedAssets.filter((asset) => {
        const { approved_programs } = asset;

        if (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value) {
          return approved_programs.includes("SPONSORED_BRANDS_VIDEO");
        }

        if (state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
          return approved_programs.includes("SPONSORED_DISPLAY_VIDEO");
        }

        return true;
      });
    }

    return sortedAssets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    sortBy,
    assetData,
    pageSize,
    searchByTags,
    assetType,
    eligibleAssetsFilter,
  ]);

  const onAssetSelection = (asset) => {
    let formattedAssetType =
      assetType === "video"
        ? assetTypeFilter === "LOGO"
          ? "logo"
          : "video"
        : assetTypeFilterOptions?.find(
            (filter) => filter.value === assetTypeFilter
          )?.formattedName;

    if (multiSelect) {
      let updatedSelections = toggleArrayEntry(asset?.asset_id, selections);
      let updatedSelectionIds = updatedSelections.filter((asset) => asset);
      handleAssetSelection(
        updatedSelectionIds,
        updatedSelections,
        formattedAssetType
      );
      return;
    }

    if (!asset) {
      if (state.videoDetails) {
        handleAssetSelection(null, null, "video");
        return;
      }
      handleAssetSelection(null, null, "lifestyleImage");
      return;
    }

    handleAssetSelection(asset.asset_id, asset, formattedAssetType);

    if (
      state.lifestyleImageDetails?.asset_id !== asset.asset_id &&
      state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
      ["LIFESTYLE_IMAGE", "PRODUCT_IMAGE"].includes(assetTypeFilter)
    ) {
      setShowCropDialogue(true);
    }
  };

  const assetOptions = useMemo(() => {
    let options = state.lifestyleImageDetails
      ? [state.lifestyleImageDetails]?.map((asset) => {
          return {
            value: asset.asset_id,
            url: asset.name,
            label: <AssetLabel asset={asset} />,
          };
        })
      : state.videoDetails
      ? [state.videoDetails]?.map((asset) => {
          return {
            value: asset.asset_id,
            url: asset.name,
            label: <AssetLabel asset={asset} />,
          };
        })
      : [];

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, state.lifestyleImageDetails]);

  const {
    pageData,
    next,
    previous,
    skipToFirstPage,
    skipToLastPage,
    skipToPage,
    lastPage,
  } = usePagination(filteredAssets, pageSize);

  useEffect(() => {
    setCurrentPage(1);
    skipToFirstPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assetTypeFilter,
    sortBy,
    searchFilter,
    assetSizeFilter,
    assetDimensionFilter,
    pageSize,
    searchByTags,
  ]);

  useEffect(() => {
    if (
      !showAdvancedFilters &&
      (assetSizeFilter || moderationStatusFilter) &&
      !eligibleAssetsFilter
    ) {
      setAssetSizeFilter(null);
      setModerationStatusFilter(null);
    }
  }, [
    showAdvancedFilters,
    assetSizeFilter,
    moderationStatusFilter,
    eligibleAssetsFilter,
  ]);

  useEffect(() => {
    if (assetType === "video" && assetTypeFilter !== "video") {
      setAssetTypeFilter("VIDEO");
      return;
    }
    if (assetType === "image") {
      setAssetTypeFilter(initialTypeFilter ?? "LIFESTYLE_IMAGE");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetType]);

  useEffect(() => {
    if (state.assetSelectionType) {
      let updatedTypeFilter = assetTypeFilterOptions.find(
        (type) => type.formattedName === state.assetSelectionType
      )?.value;

      setAssetTypeFilter(updatedTypeFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.assetSelectionType]);

  // Clear cropping coordinates when lifestyle image changes
  useEffect(() => {
    if (
      (selected !== state.lifestyleImageDetails?.asset_id || !selected) &&
      ["LIFESTYLE_IMAGE", "PRODUCT_IMAGE"].includes(assetTypeFilter) &&
      state.selectedType === "lifestyleImageDetails"
    ) {
      onChange({
        sdSquareImageCroppingCoordinates: {},
        sdRectangleImageCroppingCoordinates: {},
        sdSquareCroppedArea: null,
        sdRectangleCroppedArea: null,
      });
      setSquareCropCoordinates({});
      setRectangleCropCoordinates({});
      setCropType("square");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    const validations = CREATIVE_VALIDATIONS[state.adType];

    if (eligibleAssetsFilter) {
      let assetValidations = [];
      if (["LIFESTYLE_IMAGE", "PRODUCT_IMAGE"].includes(assetTypeFilter)) {
        assetValidations = validations.lifestyleImage;
      }
      if (["LOGO"].includes(assetTypeFilter)) {
        assetValidations = validations.logo;
      }
      let { minHeight, minWidth, maxFileSize } = assetValidations;

      setAssetSizeFilter([0, parseInt(maxFileSize)]);
      setAssetDimensionFilter((prev) => ({
        width: minWidth,
        height: minHeight,
      }));
    } else {
      // Clear filters to show all assets
      setAssetSizeFilter(null);
      setAssetDimensionFilter((prev) => ({
        width: null,
        height: null,
      }));
    }
  }, [eligibleAssetsFilter, assetTypeFilter, state.adType]);

  if (hidden) {
    return null;
  }

  // Below makes sure asset options are always visible after users scrolled down in form container
  assetContainerRef.current &&
    !isAssetDataLoading &&
    (currentPage > 1 || debouncedSearchFilter) &&
    assetContainerRef.current.scrollIntoView();

  return (
    <div>
      <Row style={{ marginTop: "2rem", marginBottom: "1rem" }}>
        <Col md={6} className="pr-5">
          <div style={{ textAlign: "left", marginBottom: "1rem" }}>
            <ControlLabel>
              <h6
                className="title fs-4"
                style={{
                  color: "#73706E",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                Image Search
              </h6>
            </ControlLabel>
          </div>
          <div
            style={{ position: "relative", marginTop: "auto" }}
            className="d-flex w-100"
          >
            <SearchIcon
              style={{
                position: "absolute",
                left: "10px",
                top: "8px",
                fontSize: "2rem",
              }}
            />
            <input
              type="text"
              className="searchInput mr-3 w-100"
              style={{
                textAlign: "left",
                padding: "10px 10px 10px 35px",
                border: "1px solid #E3E3E3",
              }}
              placeholder={`Search for assets by ${
                searchByTags ? "tags" : "name"
              }`}
              key={1223}
              value={searchFilter}
              ref={(input) => {
                if (input && input.value.length > 0) {
                  input.focus();
                }
              }}
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
            />
            {renderTagsFilter && (
              <div className="d-block pl-4" style={{ marginTop: "-6px" }}>
                <Checkbox
                  inline
                  number={`search-by-tags-enabled-checkbox`}
                  label="Tags"
                  checked={searchByTags}
                  onClick={(e) => {
                    setSearchByTags(!searchByTags);
                  }}
                />
              </div>
            )}
          </div>
        </Col>
        <Col xs={12} md={6} className="pl-5">
          {!hidePreviewField && (
            <FormGroupTile
              type="select"
              stateName={"logo"}
              handleChildFormElement={(key, value) => {
                if (value?.label?.props?.asset) {
                  onAssetSelection(value.label.props.asset);
                } else {
                  onAssetSelection(null);
                }
              }}
              selectClasses="lightly-disabled"
              labelClassName="w-100"
              customLabelStyles={{ textAlign: "left", marginBottom: ".6rem" }}
              label={
                <div className="d-flex fs-4">
                  <h6
                    className="title fs-4 pr-5 pt-2"
                    style={{
                      color: "#73706E",
                      textTransform: "none",
                      fontWeight: "700",
                    }}
                  >
                    {state.videoDetails ||
                    (!state.lifestyleImageDetails && assetType === "video")
                      ? "Video"
                      : `Lifestyle Image ${
                          customImageOptional ? "(optional)" : ""
                        }`}
                  </h6>
                  {state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value && (
                    <div className="text-nowrap">
                      <span
                        className="fs-6 pr-3 my-auto font-weight-bold"
                        style={{ color: "#73706E" }}
                      >
                        Images
                      </span>
                      <Switch
                        onText=""
                        offText=""
                        value={assetType === "video"}
                        onColor="#FFFFFF"
                        onChange={(e) =>
                          setAssetType(
                            assetType === "video" ? "image" : "video"
                          )
                        }
                        style={{ marginBottom: "none" }}
                        wrapperClass="assetTypeSwitch"
                      />
                      <span
                        className="fs-6 pl-3 my-auto font-weight-bold"
                        style={{ color: "#73706E" }}
                      >
                        Videos
                      </span>
                    </div>
                  )}
                </div>
              }
              isClearable={true}
              isSearchable={false}
              defaultValue={
                multiSelect
                  ? selections.some((selectedAssetId) =>
                      assetOptions?.find(
                        (asset) => asset.value === selectedAssetId
                      )
                    )?.[0]
                  : assetOptions?.[0]
              }
              // Below two props used to display read-only field without disabled styles, while still allowing clear and select events
              components={{
                Menu: () => null,
                MenuList: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              placeholder={<></>}
              options={assetOptions}
              multiSelect={multiSelect}
              onClick={(e) => {
                onChange({
                  assetSelectionType:
                    assetType === "video" ? "video" : "lifestyleImage",
                });
              }}
            />
          )}
          {errors.lifestyleImage &&
            fieldsToValidate?.includes("lifestyleImage") && (
              <>
                <div className="pt-4">
                  <small className="text-danger">{errors.lifestyleImage}</small>
                </div>
              </>
            )}
          <div
            style={{
              visibility:
                state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                  ? "hidden"
                  : "inherit",
            }}
            className={`d-flex ${
              (state.sdSquareImageCroppingCoordinates?.height ||
                state.sdSquareImageCroppingCoordinates?.width) &&
              state.lifestyleImageDetails
                ? "justify-content-between"
                : "justify-content-end"
            } pt-4 pr-3`}
          >
            {(state.sdSquareImageCroppingCoordinates?.height ||
              state.sdSquareImageCroppingCoordinates?.width) &&
              state.lifestyleImageDetails && (
                <div className="d-flex justify-content-end align-items-center">
                  <AuthButton
                    title={"Edit Cropping"}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // setCropCoordinates({});
                      setShowCropDialogue(true);
                    }}
                    style={{ fontSize: "1rem", transform: "none" }}
                    buttonStyle={"adplan_button"}
                  />
                </div>
              )}
            <div className="d-flex align-items-center">
              <span
                className="fs-6 pr-3 my-auto font-weight-bold"
                style={{ color: "#73706E" }}
              >
                All Assets
              </span>

              <Switch
                onText=""
                offText=""
                value={eligibleAssetsFilter}
                onColor="#FFFFFF"
                onChange={(e) => {
                  setEligibleAssetsFilter(!eligibleAssetsFilter);
                }}
                style={{ marginBottom: "none" }}
                wrapperClass="assetTypeSwitch"
              />
              <span
                className="fs-6 pl-3 my-auto font-weight-bold"
                style={{ color: "#73706E" }}
              >
                Eligible
              </span>
            </div>
          </div>
        </Col>
      </Row>
      {(state.lifestyleImageDetails ||
        state.logoDetails ||
        state.videoDetails) && (
        <Row>
          <Accordion
            title={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  marginRight: ".5rem",
                  color: "#152748",
                  textTransform: "none",
                }}
              >
                View Selected Assets
              </span>
            }
            marginTop="0"
          >
            <Row>
              <AssetSummary
                assetId={state.logoDetails?.asset_id}
                assetDescriptor={"Brand Logo"}
                hidden={!state.logoDetails?.asset_id}
                assetType={state.logoDetails?.type}
                summaryContainerClasses={"pr-5 mt-1"}
              />
              <AssetSummary
                assetId={state.lifestyleImageDetails?.asset_id}
                assetDescriptor={"Lifestyle Image"}
                hidden={
                  !state.lifestyleImageDetails?.asset_id ||
                  state.sdRectangleCroppedArea
                }
                assetType={state.lifestyleImageDetails?.type}
                summaryContainerClasses={
                  state.logoDetails ? "pl-5 mt-5" : "pr-5 mt-1"
                }
              />
              {state.sdRectangleCroppedArea && (
                <Col
                  xs={12}
                  lg={6}
                  className={`${
                    state.logoDetails?.asset_id ? "pl-5" : "pr-5"
                  } mt-1 col-lg-6 col-12"`}
                >
                  <h6
                    className="title mb-4 fs-4"
                    style={{
                      color: "#73706E",
                      fontWeight: "700",
                      textTransform: "none",
                    }}
                  >
                    Lifestyle Image (Cropped)
                  </h6>
                  <ReactCrop crop={state.sdRectangleCroppedArea} zoom={1}>
                    <img
                      src={state.lifestyleImageDetails?.url_default}
                      alt={"Preview of cropped asset."}
                      crossorigin="anonymous"
                    />
                  </ReactCrop>
                </Col>
              )}
              <AssetSummary
                assetId={state.videoDetails?.asset_id}
                assetDescriptor={"Brand Video"}
                assetType={"VIDEO"}
                hidden={!state.videoDetails?.asset_id}
                columnSpan={6}
                summaryContainerClasses={
                  state.logoDetails ? "pl-5 mt-5" : "pr-5 mt-1"
                }
              />

              {state.sdSquareCroppedArea && (
                <Col
                  xs={12}
                  lg={6}
                  className={`${
                    state.logoDetails?.asset_id &&
                    (state.lifestyleImageDetails?.asset_id ||
                      state.lifestyleImageDetails?.asset_id)
                      ? "pr-5 pt-5"
                      : "pl-5"
                  } mt-1 col-lg-6 col-12"`}
                >
                  <h6
                    className="title mb-4 fs-4"
                    style={{
                      color: "#73706E",
                      fontWeight: "700",
                      textTransform: "none",
                    }}
                  >
                    Lifestyle Image (Square)
                  </h6>
                  <ReactCrop
                    crop={state.sdSquareCroppedArea}
                    zoom={1}
                    aspect={1}
                  >
                    <img
                      src={state.lifestyleImageDetails?.url_default}
                      alt={"Preview of cropped asset."}
                      crossorigin="anonymous"
                    />
                  </ReactCrop>
                </Col>
              )}
            </Row>
          </Accordion>
        </Row>
      )}
      {state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value && (
        <Accordion
          title={
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                marginRight: ".5rem",
                color: "#152748",
                textTransform: "none",
              }}
            >
              Creative Preview
            </span>
          }
          marginTop="2rem"
          onExpanded={(expanded) => {
            setShowCreativePreview(expanded);
          }}
        >
          <Row>
            <CreativePreview
              state={state}
              // Creative preview endpoint doesn't return HTML consistently with more than 1 ASIN, for now just include first selected
              asin={
                products
                  ?.filter((p) => state.product?.includes(p.value))
                  ?.map((p) => p.asin)?.[0] ?? ""
              }
              marketPlace={marketPlace?.marketPlace}
              showCreativePreview={showCreativePreview}
              accordionLayout={true}
            />
          </Row>
        </Accordion>
      )}

      <div ref={assetContainerRef} />
      <Row
        style={{
          marginTop: "1rem",
          marginBottom: "2rem",
          display: showCropDialogue ? "none" : "inherit",
        }}
        className="d-flex justify-content-between"
      >
        <Col xs={12} md={4} className="d-flex align-items-center">
          <span
            style={{
              fontSize: "16px",
              fontWeight: 500,
              marginRight: ".5rem",
            }}
          >
            Search Results
          </span>
          <div>
            <SortIcon sortBy={sortBy} setSortBy={setSortBy} />
          </div>
          {renderAdvancedFilters && (
            <button
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
              style={{
                backgroundColor: "#FFF",
                borderRadius: "100%",
                border: "none",
                width: "25px",
                height: "25px",
                position: "relative",
              }}
            >
              <ToolTips toolTip={TOGGLE_ADVANCED_FILTERS} position={"top"} />
              <i className="fa fa-filter" />
            </button>
          )}
        </Col>
        <Col
          xs={12}
          md={4}
          className="d-flex align-items-center justify-content-center"
        >
          <>
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                margin: "auto 1rem auto 0",
              }}
            >
              Asset Type
            </p>
            <select
              onChange={(e) => setAssetTypeFilter(e.target.value)}
              value={assetTypeFilter}
              className="p-2 border rounded fs-5"
            >
              {assetTypeFilterOptions.map((type, index) => (
                <option key={index} value={type?.value}>
                  {type?.label}
                </option>
              ))}
            </select>
          </>
        </Col>
        <Col
          xs={12}
          md={4}
          className="d-flex align-items-center justify-content-end"
        >
          <Pagination
            key={"asset-pagination"}
            currentPage={currentPage}
            lastPage={
              // lastPage
              Math.ceil(assetCount / pageSize)
            }
            next={() => {
              next();
              if (currentPage < Math.ceil(assetCount / pageSize)) {
                setCurrentPage(currentPage + 1);
                setCursorToken(
                  data?.next
                    ?.split("cursor=")?.[1]
                    ?.split("&")?.[0]
                    // Below decodes escape characters present in token, prevents double encoding that was breaking pagination (%3D was be further encoded to %253D)
                    ?.replaceAll("%3D", "=")
                    ?.replaceAll("%253", "=")
                );
              }
            }}
            previous={() => {
              previous();
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
                setCursorToken(
                  data?.previous
                    ?.split("cursor=")?.[1]
                    ?.split("&")?.[0]
                    // Below decodes escape characters present in token, prevents double encoding that was breaking pagination (%3D was be further encoded to %253D)
                    ?.replaceAll("%3D", "=")
                    ?.replaceAll("%253", "=")
                );
              }
            }}
            showSkipOptions={false}
            skipToFirstPage={() => {
              skipToFirstPage();
              setCurrentPage(1);
            }}
            skipToLastPage={() => {
              skipToLastPage();
              setCurrentPage(lastPage);
            }}
            skipToPage={skipToPage}
            pageSizeOptions={[4, 8, 12, 16, 20, 24, 28, 32]}
            pageSize={pageSize}
            setPageSize={setPageSize}
            allowShowAll={false}
          />
        </Col>
      </Row>

      {showAdvancedFilters && (
        <Row>
          <Col
            xs={12}
            md={4}
            className="d-flex align-items-center justify-content-start"
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                margin: "auto 1rem auto 0",
              }}
            >
              Asset Size
            </p>
            <select
              onChange={(e) => setAssetSizeFilter(e.target.value.split(","))}
              value={assetSizeFilter}
              className="p-2 border rounded fs-5"
            >
              {assetTypeFilter === "VIDEO" &&
                videoSizeOptions.map((type, index) => (
                  <option key={index} value={type?.value}>
                    {type?.label}
                  </option>
                ))}
              {(assetTypeFilter === "LIFESTYLE_IMAGE" ||
                assetTypeFilter === "PRODUCT_IMAGE") &&
                lifeStyleImageSizeOptions.map((type, index) => (
                  <option key={index} value={type?.value}>
                    {type?.label}
                  </option>
                ))}
              {assetTypeFilter === "LOGO" &&
                logoImageSizeOptions.map((type, index) => (
                  <option key={index} value={type?.value}>
                    {type?.label}
                  </option>
                ))}
            </select>
          </Col>
          <Col
            xs={12}
            md={4}
            className="d-flex align-items-center justify-content-center"
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                margin: "auto 1rem auto 0",
              }}
            >
              Dimensions
            </p>
            <div style={{ minWidth: "140px" }} className="d-flex">
              <DimensionFilter
                assetDimensionFilter={assetDimensionFilter}
                setAssetDimensionFilter={setAssetDimensionFilter}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={4}
            className="d-flex align-items-center justify-content-end"
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                margin: "auto 1rem auto 0",
              }}
            >
              Moderation Status
            </p>
            <select
              onChange={(e) => setModerationStatusFilter(e.target.value)}
              value={moderationStatusFilter}
              className="p-2 border rounded fs-5"
            >
              {moderationStatusOptions.map((type, index) => (
                <option key={index} value={type?.value}>
                  {type?.label}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      )}
      {/* Asset card container */}
      <Row>
        {isAssetDataLoading ? (
          <Row>
            <Col
              xs={12}
              className="d-flex justify-content-center align-items-center assets-loading-container"
            >
              <img src={logo} alt="Loading..." className="rotate" width="64" />
            </Col>
          </Row>
        ) : (
          <>
            {pageData?.length ? (
              pageData?.map((asset) => (
                <AssetCard
                  key={asset.id}
                  onAssetSelection={onAssetSelection}
                  isSelected={
                    multiSelect
                      ? selections.some(
                          (selectedAssetId) =>
                            selectedAssetId === asset.asset_id
                        )
                      : selected === asset.asset_id
                  }
                  selectedAsset={assetData?.find(
                    (asset) => asset.id === selected
                  )}
                  asset={asset}
                  isVideo={assetTypeFilter === "VIDEO"}
                  allowExpandedModal={allowExpandedView}
                  adType={state.adType}
                  assetTypeFilter={assetTypeFilter}
                />
              ))
            ) : (
              <Row>
                <p
                  className="text-center"
                  style={{
                    color: "#73706E",
                    fontWeight: "700",
                    marginTop: "100px",
                  }}
                >
                  No {assetType === "video" ? "videos" : "images"} found
                </p>
              </Row>
            )}
          </>
        )}
      </Row>
      <CenteredModal
        show={showCropDialogue}
        closeModal={() => setShowCropDialogue(false)}
        title={"Lifestyle Image Cropping"}
        style={{ marginTop: "2rem" }}
        assetModal={true}
      >
        <Row
          className="d-flex justify-content-center align-content-center"
          style={{ height: "78vh" }}
        >
          <Col xs={12} className="h-100">
            <AssetCropper
              assetDetails={state.lifestyleImageDetails}
              // Note coordinates are calculated relative to natural asset dimensions, cropped area based on rendered dimensions for editing
              updateCropDimensions={(coordinates, croppedArea) => {
                if (cropType === "square") {
                  if (coordinates["width"] !== coordinates["height"]) {
                    coordinates["width"] = coordinates["height"];
                  }
                  setSquareCropCoordinates(coordinates);

                  onChange({
                    sdSquareImageCroppingCoordinates: coordinates,
                    sdSquareCroppedArea: croppedArea,
                  });
                }
                if (cropType === "rectangle") {
                  setRectangleCropCoordinates(coordinates);
                  onChange({
                    sdRectangleImageCroppingCoordinates: coordinates,
                    sdRectangleCroppedArea: croppedArea,
                  });
                }
              }}
              // Eventually below should handle all SB/SD types that we want to support cropping for
              validations={
                CREATIVE_VALIDATIONS?.[state.adType]?.[
                  cropType === "square" ? "squareImage" : "lifestyleImage"
                ] ?? {}
              }
              onChange={onChange}
              cropType={cropType}
              setCropType={setCropType}
              initialCrop={
                cropType === "square"
                  ? state.sdSquareCroppedArea
                  : cropType === "rectangle"
                  ? state.sdRectangleCroppedArea
                  : { x: 0, y: 0, aspect: 1 }
              }
            />
            <Row>
              <Col
                xs={12}
                className="pt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AuthButton
                  title={"Cancel"}
                  onClick={() => {
                    onAssetSelection(null);
                    setShowCropDialogue(false);
                  }}
                  style={{ marginRight: "2rem" }}
                  buttonStyle={"adplan_button"}
                />
                <AuthButton
                  onClick={() => {
                    setShowCropDialogue(false);
                  }}
                  // TODO: consts for SD valdiations specifically
                  disabled={
                    ((squareCropCoordinates?.height < 628 ||
                      squareCropCoordinates?.width < 628 ||
                      !squareCropCoordinates?.height) &&
                      state.lifestyleImageDetails?.file_meta_data?.height !==
                        state.lifestyleImageDetails?.file_meta_data?.width) ||
                    rectangleCropCoordinates?.height < 628 ||
                    rectangleCropCoordinates?.width < 1200
                  }
                  title={"Confirm Cropping"}
                  buttonStyle={"adplan_button"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CenteredModal>
      {state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value && state.id && (
        <UpdateButton
          text={"Update Creatives"}
          onClick={onUpdate}
          disabled={
            errors?.lifestyleImage ||
            errors?.video ||
            errors?.logo ||
            errors?.headline
          }
        />
      )}
    </div>
  );
};

export default AssetSelector;
