import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { useSelector } from "react-redux";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";
import getObjectValues from "utils/getObjectValues";
import { groupByProfileName } from "./AmazonProfileSummary";

const AgencyManagementTable = ({
  tableData = [],
  columnsToRender,
  isLoading,
  additionalColumns = [],
  bulkActionOptions = [],
  isSelectable = false,
  defaultSorted,
}) => {
  const { marketPlace } = useSelector((state) => state);
  const tableRef = useRef();

  const columnOptions = [
    buildTextColumn("acl", "Accounts", {
      formatter: (v) => v.join(", "),
    }),
    {
      key: "profiles",
      compareKey: null,
      options: {
        className: "text-start justify-content-start px-5 h-100",
        cellContainerClasses: "text-start justify-content-start",
        metric: {
          id: "orgs",
          format: "text",
          accessor: (d) => d,
          name: "Organizations & Profiles",
        },
        formatter: (row) => {
          const profiles = groupByProfileName(
            row.account_managers
              ?.map((am) => am.global_amazon_profile_ids)
              .flat()
          );

          return (
            <ul className="my-2">
              {profiles
                ?.sort((a, b) => {
                  return b?.accountInfo?.name - a?.accountInfo?.name;
                })
                .map((profile) => {
                  const {
                    accountInfo: { name, type },
                    marketPlaces,
                  } = profile;
                  return (
                    <li className="py-1">
                      {name} ({type}): {marketPlaces.join(", ")}
                    </li>
                  );
                })}{" "}
            </ul>
          );
        },
        hideFilter: true,
      },
    },
    ...additionalColumns,
  ];

  const columnSpecs = columnsToRender.map((renderColumnKey) =>
    columnOptions.find((option) => option.key === renderColumnKey)
  );

  return (
    <Row className="mx-0 w-100">
      <Col xs={12} className="mx-auto px-0">
        <BulkActionsTable
          marketPlace={marketPlace}
          tableRef={tableRef}
          tableId={"agencyManagementTable"}
          titleCol={{
            id: "name",
            isStatic: true,
            Header: "Name",
            accessor: "name",
            style: { width: "450px" },
            width: 300,
            Cell: ({ value, row }) => {
              return (
                <>
                  <div className="d-flex align-items-start overflow-ellipsis">
                    {value}
                  </div>
                </>
              );
            },
            filterMethod: (filter, row) => {
              return;
            },
            Filter: ({ filter, onChange }) => null,
          }}
          columnSpecs={columnSpecs}
          data={tableData}
          bulkActionOptions={bulkActionOptions}
          hideFilterToggleIcon={true}
          isSelectable={isSelectable}
          isExpandable={false}
          selectionKey={"id"}
          defaultSorted={defaultSorted || [{ id: "id", desc: true }]}
          getSearchCriteria={(row) => {
            return `${getObjectValues(row)}`;
          }}
          tableRowDescription={"Agency"}
          minRows={0}
          isLoading={isLoading}
          defaultPageSize={5}
        />
      </Col>
    </Row>
  );
};

export default AgencyManagementTable;
