import logo from "assets/images/logo/Trellis_Logomark_color.png";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import routes from "routes/routes";
import AdminNavbarLinks from "./NavigationBar";
import ProductOptions from "views/SidebarFragments/ProductOptions";
import MerchantSidebar from "./SidebarFragments/MerchandisingSidebar";
import { PermissionsFlag } from "components/core/basic/Flag";
import MobileNavbarTiles from "components/custom/navbar/MobileNavbarTiles";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { logoutUser } from "redux/actions/authActions";
import { HOMEPAGE } from "utils/redirectConstants";
import { selectModules } from "redux/selectors/modules";
import { withStyles } from "@material-ui/core";
import { selectApplications } from "redux/selectors/applications";
import DatesProvider from "dates/DatesProvider";
import withDashboardTheme from "hocs/withDashboardTheme";

const useStyles = (theme) => ({
  sidebar: {
    // Scrollbar overrides - avoid vertical scrollbars
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FFF",
    },
  },
});

var ps;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(routes),
      openAvatar: false,
      openPerformance: false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
      openPreferencesModal: false,
      channel: props.channel.currentChannel,
    };
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  handleNavLinkClick(e, navItemName) {
    if (this.navItemIsDisabled(navItemName)) {
      e.preventDefault();
    }
  }

  navItemIsDisabled(navItemName) {
    return (
      this.props.disableSidebarItems &&
      this.props.disableSidebarItems.includes(navItemName)
    );
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }

      // Handle application views
      if (
        prop.application &&
        !this.props.applications.find((a) => a === prop.application)
      ) {
        return null;
      }

      let required_perm = "";
      let current_perm = "";
      let required_channel = "";
      let current_channel = "";

      if (
        prop.name === "Dashboard" &&
        this.props.user.permissions === "reports"
      ) {
        required_perm = "admin";
        current_perm = this.props.user.permissions;
      }

      if (
        prop.name === "Products & Categories" &&
        this.props.user.permissions === "reports"
      ) {
        required_perm = "admin";
        current_perm = this.props.user.permissions;
      }

      if (
        prop.name === "Videos & Assets" &&
        this.props.user.permissions === "reports"
      ) {
        required_perm = "admin";
        current_perm = this.props.user.permissions;
      }

      if (prop.name === "Trellis Trak") {
        if (this.props.user.permissions === "reports") {
          required_perm = "admin";
          current_perm = this.props.user.permissions;
        }

        required_channel = "amazon";
        current_channel = this.props.channel.currentChannel;
      }

      if (prop.name === "Videos & Assets") {
        required_channel = "amazon";
        current_channel = this.props.channel.currentChannel;
      }

      if (
        prop.name === "Search Analytics" &&
        this.props.user.permissions === "reports"
      ) {
        required_perm = "admin";
        current_perm = this.props.user.permissions;
      }

      if (
        prop.name === "Purchase Behavior" &&
        this.props.user.permissions === "reports"
      ) {
        required_perm = "admin";
        current_perm = this.props.user.permissions;
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <PermissionsFlag
            role={current_channel}
            roles={required_channel}
            key={key}
          >
            <PermissionsFlag
              role={current_perm}
              roles={required_perm}
              key={key}
            >
              <li
                className={
                  this.getCollapseInitialState(prop.views) ? "active" : ""
                }
              >
                <a
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(st);
                  }}
                >
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b
                      className={
                        this.state[prop.state] ? "caret rotate-180" : "caret"
                      }
                    />
                  </p>
                </a>
                <Collapse in={this.state[prop.state]}>
                  <ul className="nav">{this.createLinks(prop.views)}</ul>
                </Collapse>
              </li>
            </PermissionsFlag>
          </PermissionsFlag>
        );
      }
      return (
        <PermissionsFlag
          role={current_channel}
          roles={required_channel}
          key={key}
        >
          <PermissionsFlag role={current_perm} roles={required_perm} key={key}>
            <li className={this.activeRoute(prop.layout + prop.path)}>
              <NavLink
                key={prop.name}
                to={prop.layout + prop.path}
                className="nav-link"
                activeClassName="active"
                style={{
                  marginBottom: "3rem",
                  cursor: this.navItemIsDisabled(prop.name)
                    ? "not-allowed"
                    : "pointer",
                }}
                onClick={(e) => this.handleNavLinkClick(e, prop.name)}
              >
                {prop.icon ? (
                  <>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )}
              </NavLink>
            </li>
          </PermissionsFlag>
        </PermissionsFlag>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }

  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    // TODO: Below shows open sidebar by default as requested by marketing
    // I think it looks odd - revisit and get more opinions
    // if (
    //   this.props.user.tier === "unknown" &&
    //   this.props.user.pricing_tier === "unknown"
    // ) {
    //   document.querySelector(".sidebar").classList.add("sidebar-initial-state");
    // }
  }

  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { classes } = this.props;

    const urlParams = new URLSearchParams(window.location.search);

    return (
      <DatesProvider
        period={urlParams.get("period")}
        group={urlParams.get("group")}
        compare={urlParams.get("compare")}
        start={urlParams.get("start")}
        end={urlParams.get("end")}
      >
        <React.Fragment>
          <div className="sidebar" data-color="gv-light">
            <div
              className="logo d-flex border-bottom"
              style={{
                zIndex: 10000,
                backgroundColor: "#F5F3F6",
              }}
            >
              <div
                className={`text-center ${
                  this.state.width <= 992 ? "me-auto" : "mx-auto"
                }`}
              >
                <a
                  href={HOMEPAGE}
                  className="simple-text logo-mini"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="logo-img">
                    <img src={logo} alt="G" />
                  </div>
                </a>
                <a
                  href={HOMEPAGE}
                  className="simple-text logo-normal"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "120px" }}
                >
                  <div
                    style={{
                      textTransform: "lowercase",
                      fontSize: "24px",
                      fontWeight: "bolder",
                      color: "#2e0054",
                      marginRight: "20px",
                      paddingTop: ".7rem",
                    }}
                  >
                    trellis
                  </div>
                </a>
              </div>
              {this.state.width <= 992 && (
                <div
                  style={{
                    zIndex: 11000,
                    position: "absolute",
                    right: "100px",
                    top: "22px",
                  }}
                >
                  <LogoutButton title="Logout" onClick={this.onLogoutClick} />
                  <span
                    onClick={() =>
                      document.documentElement.classList.toggle("nav-open")
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50px",
                      height: "50px",
                      color: "#2e0054",
                      position: "fixed",
                      top: "15px",
                      right: "25px",
                      cursor: "pointer",
                      fontSize: "2rem",
                      fontWeight: 700,
                    }}
                  >
                    &#10005;
                  </span>
                </div>
              )}
            </div>

            <div
              className={`sidebar-wrapper ${classes.sidebar}`}
              ref="sidebarWrapper"
              style={{
                background: "#F5F3F6",
              }}
            >
              <ProductOptions
                user={this.props.user}
                channel={this.props.channel.currentChannel}
              />

              <ul className="nav">
                {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
                {this.state.width <= 992 ? (
                  <>
                    <AdminNavbarLinks />
                    {/* Show beamer in the sidebar when space become's too crammed */}
                    {this.state.width <= 720 ? (
                      <li
                        className="beamerButton nav-link"
                        activeClassName="active"
                        style={{
                          marginBottom: "3rem",
                          marginTop: "1rem",
                          background: "transparent",
                          color: "#2e0054",
                          paddingLeft: "2.5rem",
                          fontWeight: "500",
                          position: "relative",
                        }}
                      >
                        <i className="pe-7s-bell" />
                        <p className="text-dark-purple">What's new</p>
                      </li>
                    ) : null}
                    <MobileNavbarTiles />
                  </>
                ) : null}
                {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
                {/* {this.createLinks(routes)} */}
                <MerchantSidebar
                  modules={this.props.modules}
                  applications={this.props.applications}
                  permissions={this.props.user.permissions}
                  user={this.props.user}
                />
                {/* {this.props.applications.find((a) => a === "Advertising") && (
                <ReportSidebar modules={this.props.modules} />
              )} */}
              </ul>
            </div>
          </div>
        </React.Fragment>
      </DatesProvider>
    );
  }
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  amz_onboard: state.amz_onboard,
  channel: state.channel,
  modules: selectModules(state),
  applications: selectApplications(state),
});

const mapDispatchToProps = {
  logoutUser,
};

export default withDashboardTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(withStyles(useStyles)(Sidebar)))
);
