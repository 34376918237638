import { useFetch } from "hooks/api";
import useColumnSelector from "hooks/useColumnSelector";
import React, { useMemo, useState } from "react";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import TrComponent from "components/custom/merchandise/TrComponent";
import { Card, Col, Row } from "react-bootstrap";
import Loading from "components/core/blocks/Loading";

const ExpandedContent = ({ row }) => {
  const productId = row?.original?.id;
  const templateContent = row?.original?.templateContent;
  const variations = row?.original?.variations;
  const { data: catalog, isLoading } = useFetch(
    ["catalog", productId],
    `/merchandise/catalog`,
    { product_id: productId },
    {
      enabled: !!productId,
      select: (data) => data?.data?.catalog || {},
    }
  );

  const hasTemplateContent = !!templateContent;
  const fieldsToUpdate = Object.entries(templateContent ?? {})
    .filter(([key, value]) => !!value)
    .map(([key, value]) => key);

  const shouldBeMuted = (field) => {
    if (!hasTemplateContent) return false;
    if (!fieldsToUpdate.includes(field)) return true;
  };

  // iterates through variations and searches for each key in the content string, and replaces it with the variation value
  const replaceVariations = (content) => {
    let newContent = content;
    Object.entries(variations).forEach(([key, value]) => {
      newContent = newContent.replace(`{${key}}`, value);
    });
    return newContent;
  };

  const newTemplateContent = {
    title: replaceVariations(templateContent?.title),
    description: replaceVariations(templateContent?.description),
    generic_keywords: replaceVariations(templateContent?.generic_keywords),
    feature_bullets_0: replaceVariations(templateContent?.feature_bullets_0),
    feature_bullets_1: replaceVariations(templateContent?.feature_bullets_1),
    feature_bullets_2: replaceVariations(templateContent?.feature_bullets_2),
    feature_bullets_3: replaceVariations(templateContent?.feature_bullets_3),
    feature_bullets_4: replaceVariations(templateContent?.feature_bullets_4),
  };

  const product = {
    title:
      newTemplateContent?.title ??
      catalog?.attributes?.item_name?.[0]?.value ??
      "",
    description:
      newTemplateContent?.description ??
      catalog?.attributes?.product_description?.[0]?.value ??
      "",
    generic_keywords:
      newTemplateContent?.generic_keywords ??
      catalog?.attributes?.generic_keywords?.[0]?.value ??
      "",
    feature_bullets: [0, 1, 2, 3, 4].map(
      (i) =>
        newTemplateContent?.[`feature_bullets_${i}`] ??
        catalog?.attributes?.bullet_point?.[i]?.value ??
        ""
    ),
  };

  const productEmpty = Object.values(product).every((v) => !v);

  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <Card className="fs-5 mb-0 p-5">
        {isLoading ? (
          <Loading height={"300px"} fullPage={false} />
        ) : (
          <Card.Body>
            <Row>
              <Col xs={12}>
                {!productEmpty && (
                  <>
                    <h5 className={shouldBeMuted("title") ? "text-muted" : ""}>
                      {product?.title}
                    </h5>
                    <ul>
                      {product?.feature_bullets?.map((bullet, i) => (
                        <li
                          className={
                            shouldBeMuted(`feature_bullets_${i}`)
                              ? "text-muted"
                              : ""
                          }
                          key={i}
                        >
                          {bullet}
                        </li>
                      ))}
                    </ul>
                    <h6 className="mt-4">Keywords</h6>
                    <div
                      className={
                        shouldBeMuted("generic_keywords") ? "text-muted" : ""
                      }
                    >
                      {product?.generic_keywords}
                    </div>
                    <h6
                      className={
                        shouldBeMuted("description") ? "text-muted" : ""
                      }
                    >
                      Description
                    </h6>
                    <div
                      style={
                        shouldBeMuted("description") ? { opacity: 0.5 } : {}
                      }
                      dangerouslySetInnerHTML={{
                        __html: product?.description ?? "",
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

const makeExpanderCell = (accessor, setExpanded) => {
  return ({ row, value }) => {
    const id = accessor(row?._original);
    return (
      <ExpanderCell
        row={row}
        value={id}
        isExpanded={value}
        setIsExpanded={setExpanded}
      />
    );
  };
};

const ProductsPreview = ({ products }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [expanded, setExpanded] = useState({});
  const variations = products?.[0]?.variations;

  const tableData = useMemo(() => {
    if (!products) return [];
    return products.map((row) => ({
      ...row,
      isExpanded: row.id === expanded ? row.id : null,
      //   created_on: moment(row.created_on),
      //   created_by_name: `${row.created_by?.first_name} ${row.created_by?.last_name}`,
    }));
  }, [products, expanded]);

  const variationColumns = Object.keys(variations ?? {}).map((key) => ({
    id: key,
    Header: key,
    accessor: (row) => row.variations?.[key],
  }));

  const [columns, columnSelectorProps] = useColumnSelector(() => {
    return [
      {
        id: "expander",
        Header: "",
        accessor: "isExpanded",
        width: 50,
        Cell: makeExpanderCell((row) => row.id, setExpanded),
      },
      {
        id: "product_title",
        Header: "Product Title",
        accessor: "product_title",
      },
      { id: "sku", Header: "SKU", accessor: "sku" },
      { id: "asin", Header: "ASIN", accessor: "asin" },
      ...variationColumns,
      { id: "placeholder", Header: "", accessor: null },
    ];
  }, []);
  return (
    <ReactTable
      data={tableData}
      defaultPageSize={10}
      minRows={5}
      className="products-preview-table"
      TrComponent={TrComponent}
      getTrProps={(table, row) => {
        let expandedChildren = <ExpandedContent row={row} />;
        return { row, expandedChildren };
      }}
      PaginationComponent={ReactTablePagination}
      getPaginationProps={() => ({
        updateSearch: setSearchFilter,
        showFilters: showFilters,
        setShowFilters: setShowFilters,
        clearFilters: () => {
          setSearchFilter("");
        },
        hideFilters: false,
        clearSearchTermFilters: () => setSearchFilter(""),
        searchTerm: searchFilter,
        table: "products-preview",
        style: { marginBottom: "2rem" },
        carouselLayout: false,
        disableSearchFocus: true,
      })}
      filterable={showFilters}
      columns={columns}
      NoDataComponent={() => null}
      showPaginationTop
      showPaginationBottom={false}
      columnSelectorProps={columnSelectorProps}
      defaultSorted={[
        {
          id: "created_on",
          desc: true,
        },
      ]}
    />
  );
};

export default ProductsPreview;
