import Loading from "components/core/blocks/Loading";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import React, { useMemo, useState } from "react";
import { Row, Col, Nav, Badge } from "react-bootstrap";
import { Card } from "react-bootstrap/esm";
import { buildMetric } from "views/merchandise/metrics";
import ProductImage from "../category/listings/ProductImage";
import Metric from "components/core/blocks/SummaryMetric";
import ReactTable from "react-table-6";
import ReactTablePagination from "../growth/ProductsCVTablePagination";
import { makeColumn, col } from "./columns";
import useColumnSelector from "hooks/useColumnSelector";
import { Link } from "react-router-dom";
import MultiMetricChart from "./MultiMetricChart";
import useSelectedMetrics from "./hooks/useSelectedMetrics";
import {
  INVENTORY_STATES,
  SALE_STATES,
} from "components/core/blocks/AdPlan/constants";
import moment from "moment";
import checkApplication from "utils/checkApplication";
import { ADVERTISING, CONTENT, DEALS, DYNAMIC_PRICING } from "./constants";
import generateLink from "utils/generateLink";
import ListingQuality from "modules/product_quality/components/ListingQuality";
import { getBadgeVariant } from "modules/product_quality/utils";
import ConversionLegend from "modules/product_quality/components/ConversionLegend";
import ToolTips from "utils/toolTips";

const ContentSection = ({
  productId,
  marketPlace,
  fullPageLayout = false,
}) => {
  const { loading, data } = useFetch(
    ["productQuality", productId],
    `/merchandise/quality/${productId}`,
    {},
    {
      select: (data) => data?.data,
    }
  );
  const metrics = useMemo(
    () => [
      buildMetric(
        marketPlace,
        { total: data },
        "seo_score",
        "seo_score_percent",
        {
          metric: { accessor: (d) => d?.seo_score },
        }
      ),
      buildMetric(
        marketPlace,
        { total: data },
        "buyability_score",
        "buyability_score_percent",
        {
          metric: { accessor: (d) => d?.buyability_score },
        }
      ),
      buildMetric(
        marketPlace,
        { total: data },
        "conversion_rate",
        "conversion_rate_delta",
        {
          metric: { accessor: (d) => d?.cr },
        }
      ),
      buildMetric(marketPlace, { total: data }, "ctr", "ctr_delta", {
        metric: { accessor: (d) => d?.ctr },
      }),
    ],
    [marketPlace, data]
  );
  const terms = useMemo(() => {
    const seoKeywords = data?.seo_keywords?.keywords;
    const seoSearchTerms = data?.seo_keywords?.search_terms;
    if (seoKeywords && seoSearchTerms) {
      [seoKeywords, seoSearchTerms].forEach((data) => {
        for (let i = 0; i < data.length; i++) {
          const kw = data[i]["keyword_text"];
          const kwData = data[i];
          kwData.cr = 0;
          kwData.ctr = 0;
          kwData.acos = 0;

          if (kw.length <= 1) {
            continue;
          }

          if (kwData.clicks > 0) {
            kwData.cr = kwData.conversions / kwData.clicks;
          }
          if (kwData.impressions > 0) {
            kwData.ctr = kwData.clicks / kwData.impressions;
          }
          if (kwData.sales > 0) {
            kwData.acos = kwData.cost / kwData.sales;
          }

          if (!kwData.cr) {
            kwData.cr = 0;
          }
        }
      });

      seoKeywords.sort(function (a, b) {
        return b.cr - a.cr;
      });

      seoSearchTerms.sort(function (a, b) {
        return b.cr - a.cr;
      });
      return {
        seoKeywords,
        seoSearchTerms,
      };
    }
    return {};
  }, [data]);

  if (loading) {
    return <Loading height={"300px"} fullPage={false} />;
  }
  return (
    <>
      <Row>
        <Col xs={3} className="pt-5 pl-4">
          <Row>
            <Col xs={12}>
              {data?.content_details && (
                <ListingQuality contentDetails={data?.content_details} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={5} className="pt-5">
          <Row>
            <Col xs={12}>
              <div className="fs-4 pb-2" style={{ fontWeight: "bold" }}>
                Top Converting Keywords
                <ToolTips id={"top-converting-keywords"} toolTip={""}>
                  The top converting keywords for this product.
                  <br />
                  <ConversionLegend xs={6} />
                </ToolTips>
              </div>
              <div
                style={{
                  maxHeight: "600px",
                  overflow: "hidden",
                  overflowY: "scroll",
                  paddingTop: "2px",
                  paddingBottom: "10px",
                }}
              >
                {terms?.seoKeywords &&
                  terms?.seoKeywords?.slice(0, 50)?.map((k, ik) => (
                    <Badge
                      bg={getBadgeVariant(k)}
                      className="mx-1 my-1 fs-5 badge-keyword"
                    >
                      {k.keyword_text}
                    </Badge>
                  ))}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="pt-5">
          <Row>
            {metrics.map((m) => (
              <Col key={m.id} xs={6} style={{ marginBottom: "3rem" }}>
                <Metric
                  id={m.id}
                  toolTip={m.toolTip}
                  name={m.name}
                  value={m.actual}
                  formatter={m.formatter}
                  legendColor={m.color}
                  size="small"
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          className={`text-center ${fullPageLayout ? "fs-standard" : ""}`}
        >
          <Link to={`/user/content/seo?id=${productId}`}>
            View Product Content
          </Link>
        </Col>
      </Row>
    </>
  );
};

const DealsSection = ({ productId, marketPlace, data, categoryId }) => {
  const { selectedMetrics, toggleMetric } = useSelectedMetrics(["promo_sales"]);
  const { date, start, end, preStart, preEnd, group } = useDates();

  const filters = {
    market_place: marketPlace.marketPlace,
    group_by: group,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
    pre_start: preStart.format("YYYY-MM-DD"),
    pre_end: preEnd.format("YYYY-MM-DD"),
    product_id: productId,
  };
  const { data: productDealStats, isLoading: isProductDealStatsLoading } =
    useFetch(
      [
        "productDealStats",
        productId,
        marketPlace,
        group,
        start,
        end,
        preStart,
        preEnd,
      ],
      "/merchandise/promos_report/",
      filters,
      {
        select: (data) => data?.data,
      }
    );

  const metrics = useMemo(
    () => [
      buildMetric(
        marketPlace,
        productDealStats,
        "promo_sales",
        "promo_sales_percent"
      ),
      buildMetric(
        marketPlace,
        productDealStats,
        "promo_units",
        "promo_units_percent"
      ),
      buildMetric(marketPlace, productDealStats, "rods", "rods_delta"),
      buildMetric(
        marketPlace,
        productDealStats,
        "promo_cost",
        "promo_cost_percent"
      ),
    ],
    [marketPlace, productDealStats]
  );

  const columnSpecs = useMemo(() => {
    return [
      col(
        "Promotion",
        "promotion",
        "promotion_report_product.promotion_report.promotion_name"
      ),
      col("Status", "text", "status"),
      col("Type", "text", "promotionType"),
      col(
        "Start",
        "date",
        "promotion_report_product.promotion_report.start_datetime"
      ),
      col("Deal Price", "currency", "deal_price"),
      col(
        "End",
        "date",
        "promotion_report_product.promotion_report.end_datetime"
      ),
      col("Promo Sales", "currency", "product_revenue", {
        compareKey: "sales_est",
        compareType: "est.",
      }),
      col("Promo Units", "number", "product_units_sold", {
        compareKey: "units_est",
        compareType: "est.",
      }),
      col("Total Discounts", "currency", "cost"),
      col("Glance Views", "number", "product_glance_views"),
      col("Conversion Rate", "percent", "conversion"),
    ];
  }, []);

  const promos = useMemo(() => {
    return (
      productDealStats?.promos?.map((p) => {
        const promotionType =
          {
            BEST_DEAL: "Best Deal",
            LIGHTNING_DEAL: "Lightning Deal",
          }?.[p?.promotion_report_product?.promotion_report?.promotion_type] ??
          "Unknown";
        let status = p?.promotion_report_product?.promotion_report?.status;
        const start =
          p?.promotion_report_product?.promotion_report?.start_datetime;
        const end = p?.promotion_report_product?.promotion_report?.end_datetime;
        if (status === "APPROVED") {
          if (
            moment(start).isBefore(moment()) &&
            moment(end).isAfter(moment())
          ) {
            status = "In Progress";
          } else if (moment(end).isBefore(moment())) {
            status = "Completed";
          } else {
            status = "Approved";
          }
        } else {
          status = status === "CANCELED" ? "Canceled" : status?.toLowerCase;
        }
        return {
          ...p,
          status,
          promotionType,
        };
      }) ?? []
    );
  }, [productDealStats]);

  const createColumn = makeColumn(
    productDealStats,
    marketPlace,
    false,
    date,
    false,
    categoryId
  );
  const [columns] = useColumnSelector(
    () =>
      columnSpecs.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [columnSpecs]
  );
  const columnOrder = columns.map((col) => col.id);

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  if (isProductDealStatsLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      <Row>
        <Col xs={8}>
          {productDealStats?.dates && (
            <MultiMetricChart
              metrics={metrics}
              selectedMetrics={selectedMetrics}
              data={productDealStats.dates}
              group={group}
              handleLegendChange={handleLegendChange}
            />
          )}
        </Col>
        <Col xs={4} className="pt-5">
          <Row>
            {metrics.map((m) => (
              <Col
                key={m.id}
                xs={6}
                style={{ marginBottom: "3rem" }}
                onClick={() => toggleMetric(m.id)}
              >
                <Metric
                  id={m.id}
                  toolTip={m.toolTip}
                  name={m.name}
                  value={m.actual}
                  target={m.target}
                  formatter={m.formatter}
                  targetFormatter={m.targetFormatter}
                  showTarget
                  targetColor={m.target > 0 ? "#007000" : "#d2222d"}
                  legendColor={m.color}
                  targetLabel={"vs. Previous"}
                  size="small"
                  selected={selectedMetrics.includes(m.id)}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {promos?.length > 0 && (
            <ReactTable
              showPaginationBottom={false}
              showPaginationTop
              PaginationComponent={ReactTablePagination}
              columns={columns}
              data={promos ?? []}
              defaultPageSize={promos?.length ?? 0}
              className="-highlight"
              filterable={false}
              state={columnOrder}
              getPaginationProps={() => ({
                hideSearch: true,
                hideFilters: true,
                table: "plan-products",
                style: { marginBottom: "2rem" },
                carouselLayout: false,
                disableSearchFocus: true,
              })}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

const AdvertisingSection = ({
  productId,
  marketPlace,
  data,
  productDetails,
  fullPageLayout = false,
  channel,
  categoryId,
}) => {
  const { selectedMetrics, toggleMetric } = useSelectedMetrics(["ad_sales"]);
  const { date, start, end, preStart, preEnd, group } = useDates();

  const filters = {
    channel,
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    product_id: productId,
  };
  const { data: productAdStats, isLoading: isProductAdDataLoading } = useFetch(
    [
      "productAdStats",
      productId,
      marketPlace,
      group,
      start,
      end,
      preStart,
      preEnd,
    ],
    "/api/data_report/product_ad_report",
    filters,
    {
      select: (data) => data?.data,
    }
  );

  const metrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
      buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
      buildMetric(marketPlace, data, "clicks", "clicks_percent"),
      buildMetric(marketPlace, data, "roas", "roas_delta"),
    ],
    [marketPlace, data]
  );

  const columnSpecs = useMemo(() => {
    return [
      col("Media Plan", "advertisement", "advertisement_name", {
        className: "text-start",
        link: {
          type: "advertisement",
          handleClick: null,
          productIdentifier: null,
          styles: "inherit",
          openInNewTab: false,
          customText: null,
          textAccessor: "advertisement_name",
        },
      }),
      col("Ad Sales", "currency", "ad_sales"),
      col("Ad Spend", "currency", "cost", { noColor: true }),
      col("Ad Units", "number", "conversion", { noColor: true }),
      col("Clicks", "number", "clicks", { noColor: true }),
      col("ROAS", "number", "roas"),
    ];
  }, []);

  const createColumn = makeColumn(
    productAdStats,
    marketPlace,
    false,
    date,
    false,
    categoryId
  );
  const [columns] = useColumnSelector(
    () =>
      columnSpecs.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [columnSpecs]
  );
  const columnOrder = columns.map((col) => col.id);

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  if (isProductAdDataLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      <Row>
        <Col xs={8}>
          {data?.date && (
            <MultiMetricChart
              metrics={metrics}
              selectedMetrics={selectedMetrics}
              data={data.date}
              group={group}
              handleLegendChange={handleLegendChange}
              datesFormatted={true}
            />
          )}
        </Col>
        <Col xs={4} className="pt-5">
          <Row>
            {metrics.map((m) => (
              <Col
                key={m.id}
                xs={6}
                style={{ marginBottom: "3rem" }}
                onClick={() => toggleMetric(m.id)}
              >
                <Metric
                  id={m.id}
                  toolTip={m.toolTip}
                  name={m.name}
                  value={m.actual}
                  target={m.target}
                  formatter={m.formatter}
                  targetFormatter={m.targetFormatter}
                  showTarget
                  targetColor={m.target > 0 ? "#007000" : "#d2222d"}
                  legendColor={m.color}
                  targetLabel={"vs. Previous"}
                  size="small"
                  selected={selectedMetrics.includes(m.id)}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {productAdStats?.product_ads?.length > 0 && (
            <ReactTable
              showPaginationBottom={false}
              showPaginationTop
              PaginationComponent={ReactTablePagination}
              columns={columns}
              data={productAdStats?.product_ads ?? []}
              defaultPageSize={productAdStats?.product_ads?.length ?? 0}
              className="-highlight"
              filterable={false}
              state={columnOrder}
              getPaginationProps={() => ({
                hideSearch: true,
                hideFilters: true,
                table: "plan-products",
                style: { marginBottom: "2rem" },
                carouselLayout: false,
                disableSearchFocus: true,
              })}
            />
          )}
        </Col>
      </Row>
      {!fullPageLayout && (
        <Row>
          <Col xs={12} className="text-center">
            {generateLink(
              "listing",
              productDetails,
              null,
              "both",
              "inherit",
              false,
              "View Listing"
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

const OverviewSection = ({ marketPlace, data, isLoading }) => {
  const { selectedMetrics, toggleMetric } = useSelectedMetrics(["sales"]);
  const { group } = useDates();

  const metrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "sales", "sales_percent"),
      buildMetric(marketPlace, data, "last_bsr_rank", "last_bsr_rank_delta"),
      buildMetric(marketPlace, data, "units", "units_percent"),
      buildMetric(marketPlace, data, "margin", "margin_delta"),
    ],
    [marketPlace, data]
  );

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <Row>
      <Col xs={8}>
        {data?.date && (
          <MultiMetricChart
            metrics={metrics}
            selectedMetrics={selectedMetrics}
            data={data.date}
            group={group}
            handleLegendChange={handleLegendChange}
          />
        )}
      </Col>
      <Col xs={4} className="pt-5">
        <Row>
          {metrics.map((m) => (
            <Col
              key={m.id}
              xs={6}
              style={{ marginBottom: "3rem" }}
              onClick={() => toggleMetric(m.id)}
            >
              <Metric
                id={m.id}
                toolTip={m.toolTip}
                name={m.name}
                value={m.actual}
                target={m.target}
                formatter={m.formatter}
                targetFormatter={m.targetFormatter}
                showTarget
                targetColor={m.target > 0 ? "#007000" : "#d2222d"}
                legendColor={m.color}
                targetLabel={"vs. Previous"}
                size="small"
                selected={selectedMetrics.includes(m.id)}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

const PricingSection = ({ productId, marketPlace, data, isLoading }) => {
  const { selectedMetrics, toggleMetric } = useSelectedMetrics(["profit"]);
  const { group } = useDates();
  const { data: pricingProduct, isLoading: isPricingProductDataLoading } =
    useFetch(
      ["product_pricing", productId],
      `/pricing/products/${productId}/`,
      {},
      {
        select: (d) => d?.data,
      }
    );

  const metrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "profit", "profit_percent"),
      buildMetric(marketPlace, data, "price", "price_delta"),
      buildMetric(marketPlace, data, "units", "units_percent"),
      buildMetric(marketPlace, data, "bsr", "bsr_percent", {
        metric: {
          totalOverrideAccessor: "last_bsr_rank",
        },
      }),
    ],
    [marketPlace, data]
  );

  const { confidence, enroll_date, sales_volume_control_slider } =
    pricingProduct ?? {};

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  if (isLoading || isPricingProductDataLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      {(confidence || enroll_date) && (
        <Row className="pt-4">
          <Col
            xs={6}
            md={{ span: 2, offset: 6 }}
            className="d-flex align-items-center justify-content-end"
          >
            <>
              <h6
                style={{ fontWeight: 600 }}
                className="fs-standard text-uppercase pt-1 pr-3"
              >
                Strategy
              </h6>
              <Badge
                className={`badge bg-trellis-${
                  enroll_date ? "purple" : "ligthgrey"
                } text-end`}
                style={{
                  borderRadius: "5px",
                }}
              >
                {enroll_date &&
                  sales_volume_control_slider >= 0 &&
                  sales_volume_control_slider <= 0 && <span>Balanced</span>}
                {enroll_date && sales_volume_control_slider < 0 && (
                  <span>Volume</span>
                )}
                {enroll_date && sales_volume_control_slider > 0 && (
                  <span>Margin</span>
                )}
                {!enroll_date && <span>n/a</span>}
              </Badge>
            </>
          </Col>
          <Col
            xs={6}
            md={{ span: 2 }}
            className="d-flex align-items-center justify-content-end"
          >
            <>
              <h6
                style={{ fontWeight: 600 }}
                className="fs-standard text-uppercase pt-1 pr-3"
              >
                Status
              </h6>
              <Badge
                className={`badge bg-trellis-${
                  enroll_date ? "green" : "ligthgrey"
                } text-end`}
                style={{
                  borderRadius: "5px",
                }}
              >
                {enroll_date && <span>Enrolled</span>}
                {!enroll_date && <span>Not Enrolled</span>}
              </Badge>
            </>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={8}>
          <MultiMetricChart
            metrics={metrics}
            selectedMetrics={selectedMetrics}
            data={data.date}
            group={group}
            handleLegendChange={handleLegendChange}
          />
        </Col>
        <Col xs={4} className="pt-5">
          <Row>
            {metrics.map((m) => (
              <Col
                key={m.id}
                xs={6}
                style={{ marginBottom: "3rem" }}
                onClick={() => {
                  toggleMetric(m.id);
                }}
              >
                <Metric
                  id={m.id}
                  toolTip={m.toolTip}
                  name={m.name}
                  value={m.overrideValue ?? m.actual}
                  target={m.target}
                  formatter={m.formatter}
                  targetFormatter={m.targetFormatter}
                  showTarget
                  targetColor={m.target > 0 ? "#007000" : "#d2222d"}
                  legendColor={m.color}
                  targetLabel={"vs. Previous"}
                  size="small"
                  selected={selectedMetrics.includes(m.id)}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {/* <Row>
        <Col xs={12} className="text-center">
          <Link to={`/user/product?id=${productId}`}>View Product Pricing</Link>
        </Col>
      </Row> */}
    </>
  );
};

const ProductCard = ({
  productId,
  marketPlace,
  channel,
  user,
  additionalTabs,
  initialTab,
  hideTabNav = false,
}) => {
  const { start, end, preStart, preEnd, group } = useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    channel,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "product_id",
  };
  const { isLoading, data: product } = useFetch(
    ["products", productId, group, start, end, preStart, preEnd],
    `/api/gvads/productid/${productId}`,
    {},
    { select: (res) => res.data }
  );

  const { data, isLoading: isOverviewDataLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      productId,
    ],
    `/api/data_report/product_report?product_id=${productId}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const categoryId = data?.products[0]?.category_id;

  const salesStateArray = [
    SALE_STATES.BEST_SELLER,
    SALE_STATES.CLEARANCE,
    SALE_STATES.NEW_PRODUCT,
  ];
  const inventoryStateArray = [
    INVENTORY_STATES.OVERSTOCK,
    INVENTORY_STATES.LOW_STOCK,
    INVENTORY_STATES.OUT_OF_STOCK,
  ];
  const salesLabel = salesStateArray.find(
    (s) => s.value === product?.sales_state
  )?.label;
  const inventoryBadgeText = inventoryStateArray.find(
    (s) => s.value === product?.stock_status
  )?.label;
  const [tab, setTab] = useState(initialTab ?? "overview");
  return (
    <>
      {isLoading ? (
        <Loading height={"300px"} fullPage={false} />
      ) : (
        <Card className="fs-standard mb-0">
          <Card.Body>
            <Row>
              <Col xs={12}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "120px", paddingRight: "20px" }}>
                    <ProductImage
                      height={100}
                      width={100}
                      id={productId}
                      style={{ borderRadius: 15 }}
                    />
                  </div>
                  <div>
                    <Row>
                      <Col xs={12}>
                        <h3
                          style={{
                            marginBottom: "0.2rem",
                            marginTop: "0",
                            fontSize: 22,
                          }}
                        >
                          {product?.product_title}
                        </h3>
                      </Col>
                      <Col xs={12}>
                        {product?.asin} ({product?.sku})
                      </Col>
                      <Col xs={12}>
                        {inventoryBadgeText && (
                          <Badge
                            className="trellis-badge bg-trellis-purple"
                            style={{
                              marginRight: "0.5rem",
                              backgroundColor: "#c43066",
                            }}
                            pill
                          >
                            {inventoryBadgeText}
                          </Badge>
                        )}
                        {salesLabel && (
                          <Badge
                            className="trellis-badge bg-trellis-fuchsia"
                            pill
                          >
                            {salesLabel}
                          </Badge>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            {!hideTabNav && (
              <Row>
                <Col xs={12} className="mt-5 ph-4 mb-3 border-bottom">
                  <Nav
                    variant="underline"
                    onSelect={(k) => {
                      return setTab(k);
                    }}
                    activeKey={tab}
                  >
                    {additionalTabs?.map((additionalTab) => (
                      <Nav.Item>
                        <Nav.Link eventKey={additionalTab.title?.toLowerCase()}>
                          {additionalTab.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                    <Nav.Item>
                      <Nav.Link eventKey="overview">Overview</Nav.Link>
                    </Nav.Item>
                    {checkApplication(user, ADVERTISING) && (
                      <Nav.Item>
                        <Nav.Link eventKey="advertising">Advertising</Nav.Link>
                      </Nav.Item>
                    )}
                    {checkApplication(user, DEALS) && (
                      <Nav.Item>
                        <Nav.Link eventKey="deals">Deals</Nav.Link>
                      </Nav.Item>
                    )}
                    {checkApplication(user, DYNAMIC_PRICING) && (
                      <Nav.Item>
                        <Nav.Link eventKey="pricing">Dynamic Pricing</Nav.Link>
                      </Nav.Item>
                    )}
                    {checkApplication(user, CONTENT) && (
                      <Nav.Item>
                        <Nav.Link eventKey="content">Content</Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                {additionalTabs?.map((additionalTab) => (
                  <>
                    {tab === additionalTab.title?.toLowerCase() &&
                      additionalTab.content}
                  </>
                ))}
                {tab === "overview" && (
                  <OverviewSection
                    marketPlace={marketPlace.marketPlace}
                    channel={channel}
                    data={data}
                    isLoading={isOverviewDataLoading}
                  />
                )}
                {tab === "advertising" && (
                  <AdvertisingSection
                    channel={channel}
                    productId={productId}
                    marketPlace={marketPlace.marketPlace}
                    data={data}
                    productDetails={product}
                    categoryId={categoryId}
                  />
                )}
                {tab === "deals" && (
                  <DealsSection
                    channel={channel}
                    productId={productId}
                    marketPlace={marketPlace.marketPlace}
                    data={data}
                    categoryId={categoryId}
                  />
                )}
                {tab === "pricing" && (
                  <PricingSection
                    channel={channel}
                    productId={productId}
                    marketPlace={marketPlace.marketPlace}
                    data={data}
                    isLoading={isOverviewDataLoading}
                  />
                )}
                {tab === "content" && (
                  <ContentSection
                    productId={productId}
                    marketPlace={marketPlace.marketPlace}
                    data={data}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ProductCard;
