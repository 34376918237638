import axios from "axios";
import NavFilterTile from "components/core/blocks/NavFilterTile";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getURLPrefix } from "utils/getUrlPrefix";
import { PermissionsFlag } from "components/core/basic/Flag";
import store from "redux/store";
import { setMarketPlace } from "redux/actions/marketPlace";

let URL_PREFIX = getURLPrefix();

class SellerAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChannel: this.props.channel.currentChannel,
      availableProfiles: [],
    };
  }

  componentDidMount() {
    this.fetchUserProfiles();
  }

  componentDidUpdate() {
    if (this.props.channel.currentChannel !== this.state.currentChannel) {
      this.setState({ currentChannel: this.props.channel.currentChannel });
      this.fetchUserProfiles();
    }
  }

  fetchUserProfiles() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/accounts/", {
        params: {
          channel: this.props.channel.currentChannel,
        },
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        this.setState({
          availableProfiles: this.formatProfiles(res.data),
        });
      });
  }

  formatProfiles(data) {
    var display = "";
    var formattedData = {};
    if ("available_profiles" in data) {
      for (var account of data.available_profiles) {
        if (account.length > 21) {
          display = account.substring(0, 18) + "...";
        } else {
          display = account;
        }

        formattedData[display] = {};
        formattedData[display][account] = false;
      }
    } else {
      if (Object.keys(data["seller"]).length > 0) {
        formattedData["Sellers"] = { header: true, style: true };
        for (var key in data["seller"]) {
          display = key;

          formattedData[display] = {};
          formattedData[display][key] = false;
        }
      }
      if (Object.keys(data["vendor"]).length > 0) {
        formattedData["Vendors"] = { header: true, style: true };
        for (key in data["vendor"]) {
          display = key;

          formattedData[display + " (V)"] = {};
          formattedData[display + " (V)"][key + " (V)"] = false;
        }
      }
    }
    return formattedData;
  }

  onProfileClick = (value) => {
    var data = {
      account: value,
    };
    if (value.includes(" (V)")) {
      data["vendor"] = true;
      value = value.replace(" (V)", "");
      data["account"] = value;
    }

    axios.put(URL_PREFIX + "/api/accounts/", data).then((res) => {
      this.updateAvailableMarketplaces();
    });
  };

  updateAvailableMarketplaces = () => {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/sellerinfo/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.data.amazon_seller_marketplaces) {
          let availableMarketplaces = res.data.amazon_seller_marketplaces;

          this.setState({
            availableMarketplaces: availableMarketplaces,
          });

          // Get default marketplace for new org, rather than default to first available marketplace is array
          axios
            .get(URL_PREFIX + "/api/userprofile/", {
              params: {},
              headers: { Authorization: AuthStr },
            })
            .then((res) => {
              let orgId = res.data.organization_id;
              let defaultMarketplace = res.data.default_marketplace[orgId];

              // Set to new orgs stored default marketplace if current stored marketplace no longer valid
              if (availableMarketplaces.includes(defaultMarketplace)) {
                localStorage.setItem("Marketplace", defaultMarketplace);
                store.dispatch(setMarketPlace(defaultMarketplace));
              } else {
                // Fallback in case no stored default is to first available choice
                localStorage.setItem("Marketplace", availableMarketplaces[0]);
                store.dispatch(setMarketPlace(availableMarketplaces[0]));
              }

              window.location.reload();
            });
        } else {
          window.location.reload();
        }
      });
  };

  render() {
    return (
      <PermissionsFlag role="admin" roles={this.props.user.permissions}>
        <NavFilterTile
          defaultValue={this.props.user.current_profile}
          handleChildComponent={this.onProfileClick}
          data={this.state.availableProfiles}
          fontSize="14px"
          mobileSidebarItem={this.props.mobileSidebarItem}
        />
      </PermissionsFlag>
    );
  }
}

SellerAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  user: state.user,
  channel: state.channel,
});

export default connect(mapStateToProps)(SellerAccount);
