import React from "react";
import { AuthButton } from "components/core/basic/Button";
import { trellisPalette } from "components/custom/analytics/palettes";
import "assets/css/media_plan_animations.css";

export const UpdateButton = ({
  state,
  onClick,
  disabled,
  text = null,
  buttonStyles = {},
  noWrap = false,
}) => (
  <AuthButton
    className={noWrap ? "logout_button" : "wrap logout_button"}
    onClick={onClick}
    title={text || "Save"}
    disabled={disabled}
    style={{ transform: "none", boxShadow: "none", ...buttonStyles }}
  />
);

export const CancelButton = ({
  state,
  onClick,
  disabled,
  text = null,
  noWrap = false,
}) => (
  <AuthButton
    className={noWrap ? "secondary_button" : "wrap secondary_button"}
    onClick={onClick}
    title={text || "Cancel"}
    disabled={disabled}
    style={{ transform: "none", boxShadow: "none" }}
  />
);

export const WizardUpdateButton = ({
  state,
  onClick,
  disabled,
  text = null,
  readyToProceed,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={readyToProceed ? "proceedAnimation" : null}
    style={{
      transform: "none",
      boxShadow: "none",
      width: "100%",
      padding: "2rem",
      background: readyToProceed ? trellisPalette[0] : "#CCC",
      display: "flex",
      flexDirection: "column",
      border: "none",
      color: "#FFF",
      fontSize: "2rem",
      fontWeight: 600,
    }}
  >
    <small style={{ fontSize: "1.3rem", fontWeight: 500 }}>Next Step</small>
    {text || "Save"}
  </button>
);

export const WizardCancelButton = ({
  state,
  onClick,
  disabled,
  text = null,
  color,
  children,
  inlineModal = false,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    style={{
      transform: "none",
      boxShadow: "none",
      width: "100%",
      padding: "2rem",
      background: color ?? "#ABB5BE",
      display: "flex",
      flexDirection: "column",
      border: "none",
      color: "#FFF",
      fontSize: "2rem",
      fontWeight: 600,
    }}
  >
    {children}

    {text !== "Cancel" && !children && (
      <small style={{ fontSize: "1.3rem", fontWeight: 500 }}>
        Previous Step
      </small>
    )}
    <span>{text ? text : inlineModal ? "" : "Cancel"}</span>
  </button>
);
