import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import TEMPLATES from "./templates";
import { useProgramContext } from "./ProgramContext";

const SelectTemplate = ({ isStaff, onSelect }) => {
  const { engine } = useProgramContext();

  return (
    <>
      <Row className="mb-4">
        <Col xs={8}>Select A Template or Create custom rules</Col>
      </Row>
      {TEMPLATES.filter((t) => (t?.enabled ?? true) || isStaff)
        ?.filter((template) => template.engine === engine)
        .map((template) => (
          <Row>
            <Col xs={8}>
              <Card
                style={{
                  cursor: "pointer",
                  padding: "1rem 3rem",
                }}
                className="fs-standard"
                onClick={() => onSelect(template.id)}
              >
                <h5 className="mb-1">{template.name}</h5>
                <small>
                  <i>{template.description}</i>
                </small>
              </Card>
            </Col>
          </Row>
        ))}
    </>
  );
};

export default SelectTemplate;
