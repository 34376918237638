import React, { useState, useMemo } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { formatNumber, formatCurrency } from "utils/formatNumber";
import TrComponent from "components/custom/merchandise/TrComponent";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import useColumnSelector from "hooks/useColumnSelector";
import Loading from "components/core/blocks/Loading";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { useFetch } from "hooks/api";
import { Link } from "react-router-dom";
import { ProductImageURL } from "components/custom/category/listings/ProductImage";
import ExternalProductLink from "components/custom/merchandise/ExternalProductLink";
import ASINTable from "components/custom/oranicSearch/asinTable";
import useDebounce from "hooks/useDebounce";

const ProductsTable = ({ marketPlace }) => {
  const [isExpanded, setIsExpanded] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [filters, setFilters] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const colToggle = useState();

  const debouncedSearch = useDebounce(searchFilter, 1500);

  const { isLoading, data, isSuccess } = useFetch(
    ["organic_products", marketPlace],
    "/merchandise/seo/products_by_org",
    {
      marketplace: marketPlace,
    },
    {
      default: [],
      select: (res) => res.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const products = useMemo(() => {
    if (!data) {
      return [];
    }

    const products = [];
    const values = Object.values(data);
    for (let i = 0; i < values.length; i++) {
      const product = { ...values[i] };

      if (
        debouncedSearch &&
        !product.product_title
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase())
      ) {
        continue;
      }

      product.isExpanded = isExpanded === product.id;
      products.push(product);
    }

    // sort products by rank
    products.sort((a, b) => {
      if (a.rank < b.rank) {
        return -1;
      }
      if (a.rank > b.rank) {
        return 1;
      }
      return 0;
    });

    return products;
  }, [data, isExpanded, debouncedSearch]);

  const ExpandedContent = ({ className, children, style, row }) => {
    return (
      <Card>
        <Card.Body>
          <ASINTable marketPlace={marketPlace} asin={row.original.asin} />
        </Card.Body>
      </Card>
    );
  };

  const [columns, columnSelectorProps] = useColumnSelector(() => {
    const tableColumns = [
      {
        id: "expander",
        Header: "",
        isStatic: true,
        width: 50,
        style: { width: "50px" },
        filterable: false,
        Cell: ({ row }) => {
          return (
            <>
              <ExpanderCell
                row={row}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
            </>
          );
        },
      },
      {
        id: "product_title",
        isStatic: true,
        Header: "Product",
        accessor: "product_title",
        style: { width: "300px" },
        Cell({ value, row }) {
          const original = row._original;
          return (
            <div style={{ display: "flex" }}>
              <div style={{ width: "65px", paddingRight: "15px" }}>
                <ProductImageURL
                  height={50}
                  width={50}
                  url={original?.image_url}
                  style={{ borderRadius: 15 }}
                />
              </div>
              <div>
                <Row>
                  <Col xs={12}>
                    <span className="fs-4">
                      <Link
                        to={`/user/merchandising/product/${original?.id}`}
                        target="_blank"
                      >
                        {value}
                      </Link>
                    </span>
                  </Col>
                  <Col xs={12}>
                    <i
                      className="pr-33 fs-5"
                      style={{
                        fontWeight: "light",
                        color: "#665",
                      }}
                    >
                      <strong>ASIN: </strong>&nbsp;
                      {original?.asin}&nbsp;
                      <ExternalProductLink
                        asin={original?.asin.toUpperCase()}
                      />
                      <span className="ps-3">
                        <strong>Price: </strong>&nbsp;
                        {formatCurrency(
                          original?.price,
                          marketPlace.marketPlace
                        )}
                      </span>
                    </i>
                  </Col>
                </Row>
              </div>
            </div>
          );
        },
      },
      {
        id: "rank",
        isStatic: true,
        Header: "Avg. Positions",
        accessor: "rank",
        Cell: ({ value }) => (
          <div className="text-center">{formatNumber(value, {}, 0)}</div>
        ),
      },
      {
        id: "searchability",
        isStatic: false,
        checked: false,
        Header: "Searchability",
        accessor: "seo_score",
        Cell: ({ value }) => (
          <div className="text-center">{formatNumber(value, {}, 2)}</div>
        ),
      },
      {
        id: "buyability",
        isStatic: false,
        checked: false,
        Header: "Buyability",
        accessor: "buyability_score",
        Cell: ({ value }) => (
          <div className="text-center">{formatNumber(value, {}, 2)}</div>
        ),
      },
      {
        id: "num_keywords",
        isStatic: true,
        Header: "# of Keywords",
        accessor: (value) => {
          return value?.keywords?.length;
        },
        Cell: ({ value }) => (
          <div className="text-center">{formatNumber(value, {}, 0)}</div>
        ),
      },
      {
        id: "keywords",
        isStatic: true,
        Header: "Search Terms",
        accessor: "keywords",
        Cell: ({ value }) => {
          return (
            <div style={{ display: "flex" }}>
              {value.map((keyword, index) => {
                return (
                  <div key={index} className="text-start ps-2">
                    {`${keyword}${index !== value.length - 1 ? ", " : ""}`}
                  </div>
                );
              })}
            </div>
          );
        },
      },
    ];

    return tableColumns;
  }, [colToggle, data, isExpanded, marketPlace, useDebounce]);

  const columnOrder = columns.map((col) => col.id);

  if (isLoading) {
    return (
      <div className="text-center">
        <Loading fullPage={false} height={300} />
      </div>
    );
  }

  if (!isLoading && isSuccess) {
    return (
      <ReactTable
        PaginationComponent={ReactTablePagination}
        getPaginationProps={() => ({
          updateSearch: setSearchFilter,
          clearFilters: () => {
            setFilters([]);
            setSearchFilter("");
            setClearFilters(true);
          },
          clearSearchTermFilters: () => setSearchFilter(""),
          searchTerm: searchFilter,
          setFilterState: setFilters,
          filteredState: filters,
          table: "seo-search-terms",
          setShowFilters: setShowFilters,
          showFilters: showFilters,
          style: { marginBottom: "2rem" },

          carouselLayout: false,
          disableSearchFocus: true,
        })}
        getTrProps={(table, row) => {
          let style = {};

          let expandedChildren = <ExpandedContent style={style} row={row} />;

          return { row, style, expandedChildren };
        }}
        getTheadFilterProps={(props) => {
          if (clearFilters) {
            props.filtered.forEach((filter, index) => {
              filter.value = "all";
            });
            setClearFilters(false);
          }
          return {
            style: { display: "flex", textAlign: "center" },
          };
        }}
        TrComponent={TrComponent}
        showPaginationTop
        showPaginationBottom={false}
        columns={columns}
        data={products}
        defaultPageSize={50}
        className="seo-products-table"
        filterable={showFilters}
        state={columnOrder}
        columnSelectorProps={columnSelectorProps}
      />
    );
  }
};

export default ProductsTable;
