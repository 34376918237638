import React from "react";
import { Col, Row } from "react-bootstrap";
import { Sparklines, SparklinesBars, SparklinesLine } from "react-sparklines";
import DetailedMetric from "./DetailedMetric";
import TrellisLink from "../basic/TrellisLink";

const Title = ({ icon, title, byline }) => (
  <>
    <div style={{ fontSize: "20px" }} className="d-flex align-items-start">
      {icon && (
        <div
          style={{
            paddingRight: "10px",
            display: "inline-block",
            backgroundImage: `url("${icon}")`,
            width: "60px",
            height: "50px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      )}
      <div style={{ width: `calc(100%  ${icon ? "- 60px" : ""})` }}>
        <div className="text-truncate">{title}</div>
        <div style={{ color: "#666", fontSize: "12px" }}>{byline}</div>
      </div>
    </div>
  </>
);

const DetailedMetricRow = ({ title, byline, icon, data, metrics, to }) => {
  return (
    <Col xs={12} lg={12} className="py-4">
      <Row>
        <Col xs={12} lg={3} className="">
          {to ? (
            <TrellisLink to={to} className="text-decoration-none">
              <Title icon={icon} title={title} byline={byline} />
            </TrellisLink>
          ) : (
            <Title icon={icon} title={title} byline={byline} />
          )}
        </Col>
        <Col xs={12} md={4} lg={2} className="mb-md-0 mb-4">
          {metrics?.[0] && (
            <Sparklines
              width={100}
              height={30}
              data={data.map((d) => metrics[0].accessor(d) ?? 0)}
            >
              {metrics[0].chartType === "bar" ? (
                <SparklinesBars color={`${metrics[0].color}a0`} />
              ) : (
                <SparklinesLine color={`${metrics[0].color}a0`} />
              )}
            </Sparklines>
          )}
        </Col>
        <Col xs={12} md={8} lg={7}>
          <Row>
            {metrics.map((m, i) => (
              <Col
                xs={6}
                lg={3}
                key={m.id}
                className={`mb-4 ${i % 2 === 0 ? "pl-2" : "pr-2"}`}
              >
                <div className="">
                  <div>
                    <DetailedMetric
                      size={"small"}
                      id={m.id}
                      toolTip={m.toolTip}
                      name={m.name}
                      value={m.overrideValue ?? m.actual}
                      target={m.target}
                      targetLabel={m.targetLabel}
                      formatter={m.formatter}
                      targetFormatter={m?.targetFormatter}
                      showTarget={!!m.target}
                      targetColor={
                        m?.targetColor ?? (m.target > 0 ? "#007000" : "#d2222d")
                      }
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default DetailedMetricRow;
