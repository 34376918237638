import {
  AD_TYPES,
  SPONSORED_DISPLAY_CREATIVES_STEP,
  WALMART_CREATIVES_STEP,
  BIDDING_RULES_SUBSTEP,
} from "./constants";

export const assessWalmartCreativesStep = (channel, state, steps) => {
  if (channel !== "walmart") {
    return;
  }

  if (
    [
      AD_TYPES.SPONSORED_BRAND_WALMART.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
    ].includes(state.adType) &&
    steps[WALMART_CREATIVES_STEP.placementIndex]?.title !==
      WALMART_CREATIVES_STEP.title
  ) {
    // Push new walmart SB step and validations after product selection, before targeting
    steps.splice(
      WALMART_CREATIVES_STEP.placementIndex,
      0,
      WALMART_CREATIVES_STEP
    );
  }
  if (
    state.adType &&
    ![
      AD_TYPES.SPONSORED_BRAND_WALMART.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
    ].includes(state.adType) &&
    steps[WALMART_CREATIVES_STEP.placementIndex]?.title ===
      WALMART_CREATIVES_STEP.title
  ) {
    // Remove walmart creatives step if user switches ad type after extra step is added
    steps.splice(WALMART_CREATIVES_STEP.placementIndex, 1);
  }
};

export const assessSDCreativesStep = (channel, state, steps) => {
  if (channel !== "amazon") {
    return;
  }
  if (
    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
    steps[SPONSORED_DISPLAY_CREATIVES_STEP.placementIndex]?.title !==
      SPONSORED_DISPLAY_CREATIVES_STEP.title
  ) {
    // Push new walmart SB step and validations after product selection, before targeting
    steps.splice(
      SPONSORED_DISPLAY_CREATIVES_STEP.placementIndex,
      0,
      SPONSORED_DISPLAY_CREATIVES_STEP
    );
  }

  if (
    state.adType &&
    state.adType !== AD_TYPES.SPONSORED_DISPLAY_V2.value &&
    steps[SPONSORED_DISPLAY_CREATIVES_STEP.placementIndex]?.title ===
      SPONSORED_DISPLAY_CREATIVES_STEP.title
  ) {
    // Remove walmart creatives step if user switches ad type after extra step is added
    steps.splice(SPONSORED_DISPLAY_CREATIVES_STEP.placementIndex, 1);
  }
};

export const assessBiddingRulesSubstep = (channel, state, steps) => {
  if (channel !== "amazon") {
    return;
  }

  const targetingStepIndex = steps.findIndex(
    (step) => step.title === "Targeting"
  );

  if (
    state.createNewProgram &&
    steps[targetingStepIndex]?.substeps.length < 3
  ) {
    steps[targetingStepIndex].substeps.push(BIDDING_RULES_SUBSTEP);
  } else if (
    !state.createNewProgram &&
    steps[targetingStepIndex]?.substeps.length > 2
  ) {
    steps[targetingStepIndex].substeps.pop();
  }
};

// Handles cases where an extra parent step or substep are added or removed out of normal form flow based on add type
// or extra steps being required to support the new bidding programs form
export const getExemptNavPaths = (state, step, substep) => {
  if (
    state.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value ||
    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
  ) {
    return step === 4 && substep === 1;
  }

  if (state.storeSpotlight && state.page?.value) {
    return step === 2 && substep === 1;
  }

  if (state.biddingProgram) {
    return false;
  }

  return step === 3 && substep === 1;
};
