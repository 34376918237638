import { useLocation } from "react-router-dom";
import allRoutes from "routes/routes";

/* 
  Color Scheme by 4Ps
  Product Content: #301B53
  Placement: #E079A7
  Pricing: #D9336C
  Promotion: #852B68
*/

const DEFAULT_SIDEBAR_COLOR = "#2e0054";
export const PLACEMENT_SIDEBAR_COLOR = "#E079A7";
const PRODUCT_CONTENT_SIDEBAR_COLOR = "#301B53";
const PRICING_SIDEBAR_COLOR = "#D9336C";
const PROMOTIONS_SIDEBAR_COLOR = "#852B68";

export const SIDEBAR_COLORS = {
  advertising: PLACEMENT_SIDEBAR_COLOR,
  merchandising: DEFAULT_SIDEBAR_COLOR,
  pricing: PRICING_SIDEBAR_COLOR,
  growth: DEFAULT_SIDEBAR_COLOR,
  admin: DEFAULT_SIDEBAR_COLOR,
  reports: DEFAULT_SIDEBAR_COLOR,
  placement: PLACEMENT_SIDEBAR_COLOR,
  promotions: PROMOTIONS_SIDEBAR_COLOR,
  main: DEFAULT_SIDEBAR_COLOR,
  product: PRODUCT_CONTENT_SIDEBAR_COLOR,
};

const getDashboardSectionByRoute = (pathname) => {
  const routeMap = {};

  for (const route of allRoutes) {
    if (routeMap[route.application]) {
      routeMap[route.application].push(route.path);
    } else {
      routeMap[route.application] = [route.path];
    }
  }

  if (pathname.includes("user")) {
    pathname = pathname.split("/user")?.[1];
  }

  if (
    routeMap["Promotions"]?.includes(pathname) ||
    pathname.includes("promotions")
  ) {
    return "promotions";
  }

  if (
    routeMap["Pricing"]?.includes(pathname) ||
    pathname.includes("/_pricing") ||
    pathname.includes("/pricing")
  ) {
    return "pricing";
  }

  if (routeMap["Product"]?.includes(pathname)) {
    return "product";
  }

  if (
    routeMap["Placement"]?.includes(pathname) ||
    routeMap["Advertising"]?.includes(pathname) ||
    pathname.includes("advertising")
  ) {
    return "placement";
  }

  if (routeMap["Reports"]?.includes(pathname)) {
    return "reports";
  }

  if (routeMap["Staff"]?.includes(pathname)) {
    return "staff";
  }

  if (routeMap["Merchandising"]?.includes(pathname)) {
    return "merchandising";
  }

  if (routeMap["Admin"]?.includes(pathname)) {
    return "admin";
  }

  return "main";
};

const useDashboardTheme = () => {
  const { pathname } = useLocation();
  const currentDashboardSection = getDashboardSectionByRoute(pathname);
  return {
    sidebarColor: SIDEBAR_COLORS[currentDashboardSection],
    section: currentDashboardSection,
  };
};

export default useDashboardTheme;
