export const GET_ERRORS = "GET_ERRORS";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const USER_LOADING = "USER_LOADING";
export const AUTHENTICATION_DATA = "AUTHENTICATION_DATA";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UNSET_CURRENT_USER = "UNSET_CURRENT_USER";
export const UPDATE_ALLOW_MWS_ORDERS = "UPDATE_ALLOW_MWS_ORDERS";
export const UPDATE_PRODUCT_IDENTIFIER = "UPDATE_PRODUCT_IDENTIFIER";
export const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES";
export const UPDATE_SUBSCRIPTION_TIER = "UPDATE_SUBSCRIPTION_TIER";
export const UPDATE_BYPASS_ONBOARDING = "UPDATE_BYPASS_ONBOARDING";

export const AMZ_ONBOARD = "AMZ_ONBOARD";
export const AMZ_CONNECT = "AMZ_CONNECT";
export const MARKETPLACE = "MARKETPLACE";
export const DEFAULT_MARKETPLACE = "DEFAULT_MARKETPLACE";
export const PROFILE = "PROFILE";

export const WALMART_ONBOARD = "WALMART_ONBOARD";

export const CHANNEL = "CHANNEL";
export const SET_OVERRIDE_MODULE = "SET_MODULE";
export const UNSET_OVERRIDE_MODULE = "UNSET_MODULE";
