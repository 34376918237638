import React from "react";
import ShelfForm from "./ShelfForm";
import { Grid } from "@material-ui/core";

const ShelfDetails = ({
  data,
  selectedShelf,
  brandData,
  categoryId,
  updateShelfData,
  numOfShelfCredits,
  numOfShelves,
  fullLayout,
  setSelectedCategory,
  marketplace,
}) => {
  const selectedShelfData = data.find((shelf) => shelf.id === selectedShelf);
  const shelfStatus = selectedShelfData?.stats?.status;

  return selectedShelf !== null ? (
    <>
      {shelfStatus === "in_progress" && (
        <Grid
          container
          item
          xs={12}
          style={{
            justifyContent: "center",
            padding: "2rem 100px",
            textAlign: "center",
          }}
        >
          <p style={{ fontWeight: "500", color: "#575757", fontSize: "18px" }}>
            <i className="fa fa-info-circle" /> Your shelf is currently being
            created. Please allow up to 24 hours for shelf data to be populated.
          </p>
        </Grid>
      )}
      {selectedShelfData && (
        <ShelfForm
          data={selectedShelfData}
          key={selectedShelfData?.id}
          brandData={brandData}
          categoryId={categoryId ?? selectedShelfData?.category}
          updateShelfData={updateShelfData}
        />
      )}
    </>
  ) : (
    <div style={{ minHeight: "70vh" }}>
      <ShelfForm
        create={true}
        data={data}
        brandData={brandData}
        categoryId={categoryId}
        updateShelfData={updateShelfData}
        numOfShelfCredits={numOfShelfCredits}
        numOfShelves={numOfShelves}
        fullLayout={fullLayout}
        setSelectedCategory={setSelectedCategory}
        marketplace={marketplace}
      />
    </div>
  );
};

export default ShelfDetails;
