import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import { buildMetric } from "views/merchandise/metrics";
import DetailedMetricRow from "components/core/blocks/DetailedMetricRow";
import PromotionsIcon from "assets/images/icons/promotion.svg";
import ThemeLine from "../ThemeLine";

const PromotionsCard = ({ marketPlace, category = null }) => {
  const { start, end, group, preStart, preEnd } = useDates();
  const filters = {
    market_place: marketPlace.marketPlace,
    group_by: group,
    category: category,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
    pre_start: preStart.format("YYYY-MM-DD"),
    pre_end: preEnd.format("YYYY-MM-DD"),
  };
  if (category) filters.category = category;
  const { data: promoReportData, isFetching } = useFetch(
    ["promosReport", start, end, preStart, preEnd, group, category],
    `/merchandise/promos_report/`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const metrics = useMemo(() => {
    return [
      buildMetric(
        marketPlace.marketPlace,
        promoReportData,
        "promo_sales",
        "promo_sales_percent"
      ),
      buildMetric(
        marketPlace.marketPlace,
        promoReportData,
        "promo_cost",
        "promo_cost_percent"
      ),
      buildMetric(
        marketPlace.marketPlace,
        promoReportData,
        "promo_units",
        "promo_units_percent"
      ),
      buildMetric(
        marketPlace.marketPlace,
        promoReportData,
        "promo_conversion_rate",
        "promo_conversion_rate_delta"
      ),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoReportData]);
  return (
    <DetailedMetricRow
      small
      title={"Deals"}
      to={`/user/merchandising/promotions`}
      byline={
        <>
          <ThemeLine>Promotion</ThemeLine>
          Measure the impact of your deals
          {isFetching && <i className="fa fa-spinner fa-spin"></i>}
        </>
      }
      data={promoReportData?.dates ?? []}
      metrics={metrics}
      loading={isFetching}
      group={group}
      icon={PromotionsIcon}
    />
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(PromotionsCard);
