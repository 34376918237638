import React from "react";

const TrendCell = ({ current, previous, children }) => {
  if (current === null) {
    return "-";
  }
  const trend = current - previous;
  return (
    <div>
      {children} (<span>{trend > 0 ? "+" : trend < 0 ? "" : ""}</span>
      {trend})
    </div>
  );
};

export default TrendCell;
