import Color from "color";

const lightenColor = (color, percent) => {
  return Color(color)
    .lighten(percent - 1)
    .hex()
    .toString();
};

const saturateColor = (hex, percent) => {
  return Color(hex)
    .blacken(percent - 1)
    .hex()
    .toString();
};

export { lightenColor, saturateColor };
