import { useState, useMemo } from "react";

const useLifecycleFilter = (productData) => {
  const [salesStateFilter, setSalesStateFilter] = useState({
    value: "all",
    label: "All Sales States",
  });
  const [inventoryStateFilter, setInventoryStateFilter] = useState({
    value: "all",
    label: "All Inventory States",
  });
  const [variantFilter, setVariantFilter] = useState({
    value: "all",
    label: "All Variants",
  });
  const variants = useMemo(() => {
    const variants = {};
    productData.forEach((product) => {
      let variant = product?.variations;
      variant = variant ? variant : {};
      Object.entries(variant || {}).forEach(([key, value]) => {
        if (!variants?.[key]) {
          variants[key] = new Set();
        }
        variants[key].add(value);
      });
    });
    Object.entries(variants).forEach(([key, value]) => {
      variants[key] = Array.from(value).sort();
    });
    return variants;
  }, [productData]);

  const filteredData = useMemo(() => {
    return productData
      .filter((p) => {
        if (inventoryStateFilter.value !== "all") {
          if (inventoryStateFilter.value === "OVERSTOCK") {
            return p?.inventory_state === "OVERSTOCK";
          }
          return (
            p?.stock_status === inventoryStateFilter.value ||
            (p?.stock_status === "IN_STOCK_SCARCE" &&
              inventoryStateFilter.value === "LOW_STOCK")
          );
        }
        return true;
      })
      .filter((p) => {
        if (salesStateFilter.value !== "all") {
          return p?.sales_state === salesStateFilter.value;
        }
        return true;
      })
      .filter((p) => {
        if (variantFilter.value !== "all") {
          let variant = p?.variations;
          variant = variant ? variant : {};
          const keys = Object.entries(variant || {}).map(([key, value]) => {
            return `${key} - ${value}`;
          });
          return keys.find((k) => k === variantFilter.value);
        }
        return true;
      });
  }, [productData, salesStateFilter, inventoryStateFilter, variantFilter]);

  return {
    filteredData,
    salesStateFilter,
    setSalesStateFilter,
    inventoryStateFilter,
    setInventoryStateFilter,
    variantFilter,
    setVariantFilter,
    variants,
    lifecycleFilterProps: {
      salesStateFilter,
      setSalesStateFilter,
      inventoryStateFilter,
      setInventoryStateFilter,
      variantFilter,
      setVariantFilter,
      variants,
    },
  };
};

export default useLifecycleFilter;
