import React, { useState, useEffect } from "react";
import { getHashValue } from "utils/getQueryValue";
import CenteredModal from "components/core/basic/CenteredModal";
import ShelfForm from "./ShelfForm";
import { AuthButton } from "components/core/basic/Button";

const ShelfModal = (props) => {
  const autoShow = getHashValue("new_shelf", "false");
  const autoKeyword = getHashValue("keyword");
  const [show, setShow] = useState(false);
  const [create, setCreate] = useState(true);

  useEffect(() => {
    if (
      autoShow.toLowerCase() === "true" &&
      props.numOfShelfCredits > 0 &&
      props.numOfShelves > 0
    ) {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.numOfShelfCredits, props.numOfShelves]);

  return (
    <>
      <CenteredModal
        show={show}
        closeModal={() => setShow(false)}
        title={
          props.numOfShelves >= props.numOfShelfCredits
            ? "Subscription Limit Reached"
            : "Create a New Shelf"
        }
        dialogClassName={
          props.numOfShelves >= props.numOfShelfCredits
            ? "modal-60w"
            : "modal-90w"
        }
        content={
          <>
            {props.loading ? (
              "Loading..."
            ) : (
              <ShelfForm
                create={create}
                closeModal={() => {
                  setShow(false);
                }}
                data={props.data}
                brandData={props.brandData}
                categoryId={props.categoryId}
                updateShelfData={props.updateShelfData}
                numOfShelfCredits={props.numOfShelfCredits}
                numOfShelves={props.numOfShelves}
                fullLayout={props.fullLayout}
                modalForm={true}
                setSelectedCategory={props.setSelectedCategory}
                marketplace={props.marketplace}
                autoKeyword={autoKeyword}
              />
            )}{" "}
          </>
        }
      />
      <AuthButton
        onClick={() => {
          setCreate(true);
          setShow(true);
        }}
        title="Create a New Shelf"
        buttonStyle="adplan_button"
      />
    </>
  );
};

export default ShelfModal;
