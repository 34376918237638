const MODULES_KEY = "trellis-modules";

export const getModules = () => {
  const moduleStr = localStorage.getItem(MODULES_KEY);
  if (moduleStr) {
    return JSON.parse(moduleStr);
  }
  return {};
};

export const setModules = (modules) => {
  return localStorage.setItem(MODULES_KEY, JSON.stringify(modules));
};
