import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";

const RadialChart = ({
  plan,
  actual,
  target,
  isActual,
  totalLabel = null,
  options,
  seriesOptions,
}) => {
  const chartOptions = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            offsetY: -15,
          },
          value: {
            show: true,
            fontSize: "36px",
          },
        },
      },
    },
    animations: {
      enabled: true,
      speed: 800,
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: seriesOptions?.map((s) => s.name),
    colors: seriesOptions?.map((s) => s.color),
  };
  const chartSeries = seriesOptions?.map((s) =>
    Number.isFinite(Math.round((actual[s.accessor] / target[s.accessor]) * 100))
      ? Math.round((actual[s.accessor] / target[s.accessor]) * 100)
      : 0
  );
  if (plan) {
    const endDate = isActual ? plan.execution_end : plan.execution_start;
    const daysRemaining = moment(endDate).diff(moment(), "days");
    chartOptions["plotOptions"]["radialBar"]["dataLabels"]["total"] =
      totalLabel ?? {
        label: "Days Left",
        show: true,
        formatter: () => daysRemaining,
        color: "#000",
      };
  }
  return <Chart options={chartOptions} type="radialBar" series={chartSeries} />;
};

export default RadialChart;
