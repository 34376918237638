import FormGroupTile from "components/core/basic/FormGroupTile";
import { useFetch } from "hooks/api";
import React, { useMemo, useState } from "react";
import useDebounce from "hooks/useDebounce";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { Spinner } from "react-bootstrap";
import checkModule from "utils/checkModule";

const CampaignNameField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  channel,
  adId,
  campaignType,
  campaignName,
  handleCampaignNameChange,
  modules,
  marketPlace,
  labelClassName,
  overrideLabelText,
  overridePlaceholderText,
  key,
  customLabelStyles = null,
  formControlClasses = "",
  review,
}) => {
  const labelText = overrideLabelText
    ? overrideLabelText
    : campaignType === "auto_name"
    ? "Harvesting Campaign Name (Auto Campaign)"
    : snakeCaseToSentenceCase(campaignType?.split("_")?.join("_campaign_"));

  const [error, setError] = useState("");

  const debouncedCampaignName = useDebounce(campaignName, 500);

  const { isLoading: isCampaignDataLoading } = useFetch(
    ["amazonCampagin", debouncedCampaignName, campaignType],
    "/api/channel_campaigns",
    {
      q: debouncedCampaignName,
      marketplace: marketPlace,
    },
    {
      default: [],
      enabled:
        !!debouncedCampaignName &&
        checkModule(modules, "CAMPAIGN_NAMES") &&
        !state.id &&
        !error,
      select: (res) => res.data,
      onSuccess: (res) => {
        if (res?.length) {
          onChange({
            duplicateCampaignNames: [
              ...state.duplicateCampaignNames,
              campaignType,
            ],
            validatingCampaignNames: state.validatingCampaignNames.filter(
              (name) => name !== campaignType
            ),
          });

          return;
        }
        onChange({
          validatingCampaignNames: state.validatingCampaignNames.filter(
            (name) => name !== campaignType
          ),
          duplicateCampaignNames: state.duplicateCampaignNames.filter(
            (name) => name !== campaignType
          ),
        });
      },
      onError: () => {
        if (!error) {
          setError("Error validating campaign name.");
        }
      },
    }
  );

  useMemo(() => {
    // If name field is cleared while validation pending, remove from list of running validation requests
    if (!debouncedCampaignName) {
      onChange({
        validatingCampaignNames: state.validatingCampaignNames?.filter(
          (name) => name !== campaignType
        ),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCampaignName]);

  if (
    isCampaignDataLoading &&
    !state.validatingCampaignNames?.includes(campaignType)
  ) {
    onChange({
      validatingCampaignNames: [
        ...(state.validatingCampaignNames ?? []),
        campaignType,
      ],
    });
  }

  return (
    <div
      style={{
        maxWidth: state.id ? "50%" : "70%",
        paddingLeft: "2rem",
        paddingBottom: "1rem",
      }}
    >
      <FormGroupTile
        formControlClass={`text-start ${formControlClasses}`}
        type="formcontrol"
        label={`${labelText}`}
        formControlType={"text"}
        stateName={campaignType}
        handleChildFormElement={(key, value) => {
          if (error) {
            setError("");
          }
          handleCampaignNameChange(key, value);
        }}
        labelClassName={labelClassName}
        disabled={state.id || review}
        placeholder={
          overridePlaceholderText ? overridePlaceholderText : `${labelText}`
        }
        defaultValue={campaignName}
        style={{ border: "1px solid #BBB" }}
        customLabelStyles={customLabelStyles}
        key={key}
      />

      {error ? (
        <small className="text-danger pt-3 d-block">{error}</small>
      ) : state.validatingCampaignNames?.includes(campaignType) &&
        campaignName ? (
        <div className="d-flex align-items-center py-3">
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
            className="me-3"
          />
          <small className="text-info">
            Validating campaign name. Please wait.
          </small>
        </div>
      ) : state.duplicateCampaignNames?.includes(campaignType) ? (
        <small className="text-danger pt-3 d-block">
          {errors.duplicateCampaignNames}
        </small>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CampaignNameField;
