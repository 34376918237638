import React, { useState, useMemo, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useFetch } from "hooks/api";
import { Badge, Col, Container as Grid, Row } from "react-bootstrap";

import { connect } from "react-redux";
import Accordion from "components/core/basic/Accordion";
import { useLocation, useHistory } from "react-router-dom";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import Loading from "components/core/blocks/Loading";
import { selectModules } from "redux/selectors/modules";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import TrellisLink from "components/core/basic/TrellisLink";
import getMetricSpec from "./metrics";
import BulkActionsTable from "./tables/BulkActionsTable";
import OrgPerformanceExpander from "./OrgPerformanceExpander";
import { STAFF_DASHBOARD_BREADCRUMB_SPEC } from "utils/dashboardLinks";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import { snakeCaseToSentenceCase } from "utils/formatText";
import Flags from "country-flag-icons/react/3x2";
import MarketplaceStatsFilter from "./MarketplaceStatsFilter";
import api from "utils/api";
import { marketplaceDict } from "components/custom/analytics/ShelfForm";
import { buildMultiCurrencyMetricCol } from "./columns/buildMultiCurrencyMetricCol";
import DownloadReport from "modules/perf_reports/DownloadReport";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import {
  ORG_CHANNEL_FILTER,
  ORG_TYPE_FILTER,
  buildOrgMarketplacesFilter,
} from "views/advertising/orgLifecycleFilters";

import { handleDefaultMarketplace } from "utils/handleDefaultMarketplace";

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);

  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  return metric;
};

const OrgCell = ({ orgData }) => {
  const history = useHistory();

  let {
    org_name,
    profile_type,
    amazon_profile_name,
    market_place: flagNationCode,
    org_id,
  } = orgData;

  if (flagNationCode === "USA") {
    flagNationCode = "US";
  }

  if (flagNationCode === "UK") {
    flagNationCode = "GB";
  }
  const Flag = Flags[flagNationCode];

  return (
    <TrellisLink
      id="categoryPage"
      to={"#"}
      onClick={async () => {
        await api.put("/api/manage/userprofile/", { org: org_id });
        await handleDefaultMarketplace();
        history.push("/user/merchandising/dashboard");
        /*
        TODO: Below is bad, but fixing how we store channel and marketplace in state will open a can of worms
        The above dispatches and localStorage updates make everything functional with the right marketplace filters,
        but the seller marketplace and channel dropdowns will need an update to trigger a refetch without any type 
        of hard reload once localStorage is updated
        */
        return window.location.reload();
      }}
      style={{
        textDecoration: "none",
        fontWeight: "500",
        fontSize: "1.5rem",
        cursor: "pointer",
      }}
    >
      <div className={"fs-standard text-underline-hover"}>
        <strong className={"text-underline-hover"}>{org_name}</strong>
        <Flag style={{ width: "18px" }} className="ms-3" />
      </div>
      <i
        className="pr-3"
        style={{
          fontWeight: "light",
          color: "#666",
          fontSize: "1rem",
        }}
      >
        {amazon_profile_name}
      </i>
      <Badge
        className={`badge bg-trellis-${
          profile_type === "seller" ? "fuchsia" : "mauve"
        } fs-6`}
        pill
        style={{
          borderRadius: "5px",
        }}
      >
        {snakeCaseToSentenceCase(profile_type)}
      </Badge>
    </TrellisLink>
  );
};

const Card = ({
  title,
  start,
  end,
  data,
  isLoading,
  metrics,
  initialSelectedMetric,
  children,
  channel,
  marketPlace,
  user,
  selectedMarketplace,
  setSelectedMarketplace,
  statMarketplaceOptions,
  isMarketplaceSummaryDataLoading,
}) => {
  const { group } = useDates();

  return (
    <>
      <MetricsCard
        id={"agencyDashboard"}
        mobileStyle
        title={title}
        data={data?.date ?? []}
        initialSelectedMetric={initialSelectedMetric}
        metrics={metrics}
        group={group}
        showMetricsSelector={true}
        loading={isMarketplaceSummaryDataLoading}
        showLoadingSpinner={true}
        byline={
          <>
            {start.format("MMMM Do YYYY")}
            {" - "}
            {end.format("MMMM Do YYYY")}
            {isMarketplaceSummaryDataLoading && (
              <i className="fa fa-spinner fa-spin"></i>
            )}
          </>
        }
        statusCols={6}
        inlineStatus={
          <>
            <MarketplaceStatsFilter
              selectedMarketplace={selectedMarketplace}
              setSelectedMarketplace={setSelectedMarketplace}
              statMarketplaceOptions={statMarketplaceOptions}
            />
          </>
        }
      >
        <Row>{children}</Row>
      </MetricsCard>
    </>
  );
};

const Dashboard = ({ marketPlace, user, category, modules, channel }) => {
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(
    marketPlace.marketPlace
  );

  const mobileStyle = useMediaQuery("(max-width:600px)");
  const { group, date, start, end, preStart, preEnd } = useDates();
  const { preferences } = useMerchandisePreferences();

  const filters = {
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    group_by: "org_id",
    all_active_orgs: true,
  };

  const {
    data: marketplaceSummaryData,
    isLoading: isMarketplaceSummaryDataLoading,
  } = useFetch(
    [
      "marketplaceSummaryData",
      selectedMarketplace,
      start,
      end,
      preStart,
      preEnd,
      group,
    ],
    "/api/data_report/org_report",
    { ...filters, mp: selectedMarketplace },
    {
      select: (d) => d.data,
      refetchOnWindowFocus: false,
      default: [],
    }
  );

  const {
    data: tableData,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useFetch(
    ["allOrgsData", start, end, preStart, preEnd, group],
    "/api/data_report/org_report",
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      default: [],
    }
  );

  const statMarketplaceOptions = tableData?.marketplace_total
    ?.sort((a, b) => b.total_sales - a.total_sales)
    ?.map((m) => m.name);

  const allMarketplaceOptions =
    [
      ...new Set(
        statMarketplaceOptions?.concat(
          tableData.orgs.map((o) => o.market_place)
        )
      ),
    ] ?? [];

  const acosOrRoas = useMemo(
    () => preferences?.acos_or_roas ?? "acos",
    [preferences]
  );

  const roasMetrics = [
    buildMetric(
      { marketPlace: selectedMarketplace },
      marketplaceSummaryData,
      "troas",
      "troas_delta",
      {
        metric: {
          decimalPlaces: 1,
        },
      }
    ),
    buildMetric(
      { marketPlace: selectedMarketplace },
      marketplaceSummaryData,
      "roas",
      "roas_delta",
      {
        metric: {
          decimalPlaces: 1,
        },
      }
    ),
  ];
  const acosMetrics = [
    buildMetric(
      { marketPlace: selectedMarketplace },
      marketplaceSummaryData,
      "total_acos",
      "total_acos_delta",
      {
        metric: {
          decimalPlaces: 1,
        },
      }
    ),
    buildMetric(
      { marketPlace: selectedMarketplace },
      marketplaceSummaryData,
      "acos",
      "acos_delta",
      {
        metric: {
          decimalPlaces: 1,
        },
      }
    ),
  ];

  const { overviewMetrics } = useMemo(() => {
    return {
      overviewMetrics: {
        columns: [],
        metrics: [
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "sales",
            "sales_percent"
          ),
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "units",
            "units_percent"
          ),
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "margin",
            "margin_delta"
          ),
          ...(acosOrRoas === "acos" ? acosMetrics : roasMetrics),
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "ad_sales",
            "ad_sales_percent"
          ),
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "ad_spend",
            "ad_spend_percent"
          ),
          ...(acosOrRoas === "acos" ? roasMetrics : acosMetrics),
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "roms",
            "roms_delta"
          ),
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "cost",
            "cost_percent"
          ),
          buildMetric(
            { marketPlace: selectedMarketplace },
            marketplaceSummaryData,
            "promo_cost",
            "promo_cost_percent"
          ),
        ],
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceSummaryData, user, selectedMarketplace]);

  const filteredTableData = useMemo(() => {
    return tableData?.orgs?.map((org) => ({
      ...org,
      expanderKey: `${org.org_id}-${org.market_place}`,
    }));
  }, [tableData]);

  const breadCrumbItems = [STAFF_DASHBOARD_BREADCRUMB_SPEC];

  const TABLE_ID = "agencyDashboardTable";
  const tableRef = useRef();

  const ORG_MARKETPLACE_FILTER = buildOrgMarketplacesFilter(
    allMarketplaceOptions.map((marketPlace) => ({
      value: marketPlace,
      label: marketplaceDict[marketPlace],
    }))
  );
  const ADDITIONAL_FILTERS = [
    ORG_MARKETPLACE_FILTER,
    ORG_TYPE_FILTER,
    ORG_CHANNEL_FILTER,
  ];
  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);
  const acosCols = [
    {
      key: "total_acos",
      compareKey: "total_acos_delta",
      options: {
        isNegative: true,
        checked: acosOrRoas === "acos",
      },
    },
    {
      key: "acos",
      compareKey: "acos_delta",
      options: {
        isNegative: true,
        checked: acosOrRoas === "acos",
      },
    },
  ];
  const roasCols = [
    {
      key: "troas",
      compareKey: "troas_delta",
      options: {
        checked: acosOrRoas === "roas",
      },
    },
    {
      key: "roas",
      compareKey: "roas_delta",
      options: {
        checked: acosOrRoas === "roas",
      },
    },
  ];

  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {isLoading && <Loading />}
          {isError && <p>Error Loading Dashboard</p>}
          {isSuccess && (
            <>
              <Row style={{ marginBottom: "1rem" }}>
                <Col xs={12}>
                  <Card
                    title={"Overview"}
                    marketPlace={marketPlace}
                    mobileStyle={mobileStyle}
                    start={start}
                    end={end}
                    data={marketplaceSummaryData}
                    isLoading={isFetching}
                    metrics={overviewMetrics.metrics}
                    initialSelectedMetric={["ad_sales", "ad_spend", "roas"]}
                    filters={filters}
                    date={date}
                    channel={channel}
                    user={user}
                    selectedMarketplace={selectedMarketplace}
                    setSelectedMarketplace={setSelectedMarketplace}
                    statMarketplaceOptions={statMarketplaceOptions}
                    isMarketplaceSummaryDataLoading={
                      isMarketplaceSummaryDataLoading
                    }
                  >
                    <Col xs={12}>
                      <Accordion
                        title={"Brands by Marketplace"}
                        initialExpanded={true}
                        marginTop={"1rem"}
                      >
                        <Row>
                          <Col xs={12}>
                            <BulkActionsTable
                              tableId={TABLE_ID}
                              tableRef={tableRef}
                              defaultSorted={[]}
                              marketPlace={marketPlace}
                              mobileStyle={mobileStyle}
                              selected={selectedOrg}
                              setSelected={setSelectedOrg}
                              titleCol={{
                                id: "org_name",
                                isStatic: true,
                                Header: "Brand",
                                accessor: "org_name",
                                style: {
                                  width: "350px",
                                },
                                width: 350,
                                Cell: ({ value, row }) => {
                                  return <OrgCell orgData={row._original} />;
                                },
                                filterMethod: (filter, row) => {
                                  return;
                                },
                                Filter: ({ filter, onChange }) => null,
                              }}
                              ExpandedContent={({ row }) => {
                                return (
                                  <OrgPerformanceExpander
                                    row={row.row}
                                    marketPlace={marketPlace}
                                    channel={channel?.currentChannel}
                                    allOrgs={true}
                                  />
                                );
                              }}
                              columnSpecs={[
                                buildMultiCurrencyMetricCol(
                                  "sales",
                                  "sales_percent"
                                ),
                                {
                                  key: "units",
                                  compareKey: "units_percent",
                                },
                                {
                                  key: "margin",
                                  compareKey: "margin_delta",
                                },
                                ...(acosOrRoas === "acos"
                                  ? acosCols
                                  : roasCols),

                                buildMultiCurrencyMetricCol(
                                  "ad_sales",
                                  "ad_sales_percent"
                                ),
                                buildMultiCurrencyMetricCol(
                                  "ad_spend",
                                  "ad_spend_percent"
                                ),
                                buildMultiCurrencyMetricCol(
                                  "promo_cost",
                                  "promo_cost_percent",
                                  { noColor: true, checked: false }
                                ),
                                {
                                  key: "roms",
                                  compareKey: "roms_delta",
                                },
                                ...(acosOrRoas === "acos"
                                  ? roasCols
                                  : acosCols),
                                buildMultiCurrencyMetricCol(
                                  "cost",
                                  "cost_percent",
                                  { noColor: true, checked: false }
                                ),
                              ]}
                              selectionKey={"expanderKey"}
                              data={filteredTableData}
                              date={date}
                              category={category}
                              user={user}
                              isExpandable={true}
                              isSelectable={false}
                              isLoading={isLoading}
                              filterWidget={true}
                              {...filterProps}
                              additionalFilterOptions={ADDITIONAL_FILTERS}
                              excludedFilterColumns={["org_name"]}
                              hideFilterToggleIcon={true}
                              hideResetFiltersIcon={false}
                              getSearchCriteria={(row) => {
                                const {
                                  org_name,
                                  org_id,
                                  market_place,
                                  amazon_profile_name,
                                } = row;

                                return `${org_name} ${org_id} ${market_place} ${amazon_profile_name}`;
                              }}
                            />
                          </Col>
                          <Col xs={12} className="text-right">
                            <DownloadReport
                              url_path={"org_report"}
                              filters={{ ...filters, empty: false }}
                              report_dl={"ads"}
                            />
                          </Col>
                        </Row>
                      </Accordion>
                    </Col>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

const AllCustomersDashboard = ({ user, marketPlace, modules, channel }) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        modules={modules}
        channel={channel}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
  modules: selectModules(state),
  channel: state.channel.currentChannel,
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(AllCustomersDashboard);
