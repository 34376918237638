import PropTypes from "prop-types";
import React, { useEffect, useState, Fragment } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "assets/css/dashboard_table.css";
import Card from "components/core/basic/Card";
import ReactTable from "react-table-6";
import "assets/css/dashboard_table.css";
import ProductsCVTablePagination from "./ProductsCVTablePagination";
import { formatNumber, parseFormattedNumber } from "utils/formatNumber";
import { generateRanges } from "utils/rangeDropdownFilter";
import ProductImage from "components/custom/category/listings/ProductImage";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";

const useStyles = (theme) => ({
  switchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 1rem",
    margin: "-1rem 2rem 1rem 0",
  },
  switchLabel: {
    color: "#797979",
    marginLeft: "2rem",
    textTransform: "uppercase",
  },
});

const ProductsCVTable = ({
  pbData,
  marketPlace,
  user,
  setSelectedProduct,
  getTimeDescription,
}) => {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [filterState, setFilterState] = useState([]);

  const [productCvRanges, setProductCvRanges] = useState([]);
  const [brandCvRanges, setBrandCvRanges] = useState([]);
  const [repeatCustomerRanges, setRepeatCustomerRanges] = useState([]);
  const [repeatSalesRanges, setRepeatSalesRanges] = useState([]);
  const [repeatBrandSalesRanges, setRepeatBrandSalesRanges] = useState([]);
  const [repeatBrandCustomerRanges, setRepeatBrandCustomerRanges] = useState(
    []
  );
  const [purchaseFrequencyRanges, setPurchaseFrequencyRanges] = useState([]);
  const [haloRankRanges, setHaloRankRanges] = useState([]);

  useEffect(() => {
    setTableData([]);
    applyFilters();
  }, [pbData, searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  // Re-apply filters when change in selected category detected
  useEffect(() => {
    if (pbData?.length > 0) {
      getDropdownOptions();
    }

    applyFilters();
  }, [pbData]); // eslint-disable-line react-hooks/exhaustive-deps

  const applyFilters = () => {
    if (searchFilter.length > 0) {
      setFilteredData(
        pbData.filter(
          (row) =>
            // TODO: Decide on what search filter will look for below
            row.product.asin.toLowerCase().indexOf(searchFilter.toLowerCase()) >
              -1 ||
            row.product.sku.toLowerCase().indexOf(searchFilter.toLowerCase()) >
              -1 ||
            row.product.product_title
              .toLowerCase()
              .indexOf(searchFilter.toLowerCase()) > -1
        )
      );
    } else {
      setFilteredData(null);
    }
  };

  // Define range filters for all columns
  const getDropdownOptions = () => {
    setProductCvRanges(generateRanges(pbData, 5, "cv"));
    setBrandCvRanges(generateRanges(pbData, 5, "brand_cv"));
    setRepeatCustomerRanges(
      generateRanges(pbData, 5, "total_customers_repeat")
    );
    setRepeatSalesRanges(generateRanges(pbData, 5, "total_sales_repeat"));
    setRepeatBrandSalesRanges(generateRanges(pbData, 5, "total_brand_sales"));
    setRepeatBrandCustomerRanges(
      generateRanges(pbData, 5, "total_brand_customers_repeat")
    );
    setPurchaseFrequencyRanges(generateRanges(pbData, 5, "purchase_freq"));
    setHaloRankRanges(
      generateRanges(pbData, 5, "total_brand_sales_repeat_rank")
    );
  };

  const clearSearchTermFilters = () => {
    let currentFilters = filtered;
    let updatedFilters = currentFilters.filter(
      (filter) => filter.id !== "keyword"
    );

    setFiltered(updatedFilters);
  };

  const columns = [
    {
      Header: "First Purchase",
      headerClassName: "wordwrap",
      accessor: "product.asin",
      headerStyle: {
        textAlign: "left",
        position: "sticky",
        height: "44px",
        padding: "1.3rem",
        display: "flex",
        alignContent: "center",
        zIndex: 2,
        opacity: 1,
        background: "#FFF",
        width: user.productIdentifier === "sku" ? "300px" : "190px",
      },
      style: {
        position: "sticky",
      },
      className: "cv-tr",
      width: user.productIdentifier === "sku" ? 300 : 190,
      Cell: (props) => {
        const product = props.row._original.product;

        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div>
                <ProductImage
                  divId={"productImage" + product.id}
                  id={product.id}
                  width="40px"
                  height="40px"
                />
              </div>
              <span
                key={product.asin}
                style={{
                  marginLeft: "1.5rem",
                  fontSize: "15px",
                  color: "#d91266",
                  textAlign: "center",
                  float: "center",
                  textDecoration: "underline",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={(e) => setSelectedProduct(product)}
              >
                {user.productIdentifier === "sku" && product.sku
                  ? product.sku
                  : product.asin}
              </span>
              {/* </Link> */}
            </div>
          </Fragment>
        );
      },
      Filter: () => null,
    },
    {
      Header: "Customer Value",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "cv",
      Cell: (props) =>
        props.value > 0
          ? `${formatNumber(
              props.value,
              {
                currency: {
                  marketPlace: marketPlace.marketPlace,
                },
              },
              2
            )}`
          : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }

        if (
          row.cv.toFixed(1) >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.cv.toFixed(1) <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {productCvRanges &&
            productCvRanges.map((range, index) => (
              <option key={index} value={range}>
                {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(
                  range.split(
                    ` - ${
                      MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                    }`
                  )[0]
                )}{" "}
                - {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(range.split(` - `)[1])}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Brand Customer Value",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "brand_cv",
      Cell: (props) => {
        return props.value > 0
          ? `${formatNumber(
              props.value,
              {
                currency: {
                  marketPlace: marketPlace.marketPlace,
                },
              },
              2
            )}`
          : "";
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.brand_cv >= parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.brand_cv <= parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {brandCvRanges &&
            brandCvRanges.map((range, index) => (
              <option key={index} value={range}>
                {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(
                  range.split(
                    ` - ${
                      MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                    }`
                  )[0]
                )}{" "}
                - {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(range.split(` - `)[1])}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Repeat Customers",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "total_customers_repeat",
      Cell: (props) =>
        props.value > 0 ? formatNumber(props.value, null, 1) : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.total_customers_repeat >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.total_customers_repeat <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {repeatCustomerRanges &&
            repeatCustomerRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Repeat Sales",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "total_sales_repeat",
      Cell: (props) =>
        props.value > 0
          ? formatNumber(props.value, {
              currency: { marketPlace: marketPlace.marketPlace },
            })
          : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.total_sales_repeat >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.total_sales_repeat <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {repeatSalesRanges &&
            repeatSalesRanges.map((range, index) => (
              <option key={index} value={range}>
                {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(
                  range.split(
                    ` - ${
                      MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                    }`
                  )[0]
                )}{" "}
                - {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(range.split(` - `)[1])}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Brand Repeat Customers",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "total_brand_customers_repeat",
      Cell: (props) =>
        props.value > 0 ? formatNumber(props.value, null, 1) : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.total_brand_customers_repeat >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.total_brand_customers_repeat <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {repeatBrandCustomerRanges &&
            repeatBrandCustomerRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Brand Repeat Sales",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "total_brand_sales_repeat",
      Cell: (props) =>
        props.value > 0
          ? formatNumber(props.value, {
              currency: { marketPlace: marketPlace.marketPlace },
            })
          : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.total_brand_sales_repeat >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.total_brand_sales_repeat <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {repeatBrandSalesRanges &&
            repeatBrandSalesRanges.map((range, index) => (
              <option key={index} value={range}>
                {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(
                  range.split(
                    ` - ${
                      MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                    }`
                  )[0]
                )}{" "}
                - {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(range.split(` - `)[1])}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Halo Rank",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "total_brand_sales_repeat_rank",
      sortMethod: (a, b) => {
        if (a === 0) return 1;
        if (b === 0) return -1;
        return a - b;
      },
      Cell: (props) =>
        props.value > 0 ? formatNumber(props.value, null, 1) : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.total_brand_sales_repeat_rank >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.total_brand_sales_repeat_rank <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {haloRankRanges &&
            haloRankRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Purchase Frequency",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "purchase_freq",
      sortMethod: (a, b) => {
        if (a === 0) return 1;
        if (b === 0) return -1;
        return a - b;
      },
      Cell: (props) =>
        Math.round(props.value) > 0 ? `${getTimeDescription(props.value)}` : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          Math.round(row.purchase_freq) >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          Math.round(row.purchase_freq) <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {purchaseFrequencyRanges &&
            purchaseFrequencyRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
  ];

  return (
    <>
      <Grid style={{ marginTop: "-3rem" }}>
        <Grid container style={{ marginTop: "-2rem" }}>
          <Grid item xs={12}>
            <Card
              content={
                <ReactTable
                  data={filteredData ? filteredData : pbData}
                  PaginationComponent={(props) => {
                    return (
                      <ProductsCVTablePagination
                        {...props}
                        updateSearch={setSearchFilter}
                        clearFilters={() => {
                          setFiltered([]);
                          setClearFilters(true);
                        }}
                        clearSearchTermFilters={clearSearchTermFilters}
                        searchTerm={searchFilter}
                        setFilterState={setFilterState}
                        filteredState={filtered}
                        table={"purchases"}
                        setShowFilters={setShowFilters}
                        showFilters={showFilters}
                        style={{ marginBottom: "2rem" }}
                      />
                    );
                  }}
                  getTheadFilterProps={(props) => {
                    if (clearFilters) {
                      props.filtered.forEach((filter, index) => {
                        filter.value = "all";
                      });
                      setClearFilters(false);
                    }

                    return { style: { display: "flex" } };
                  }}
                  filterable={showFilters}
                  columns={columns}
                  defaultSorted={[
                    {
                      id: "total_brand_sales_repeat_rank",
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  getTbodyProps={(state, rowInfo, column) => {
                    return {
                      style: {
                        overflow: "visible",
                      },
                    };
                  }}
                  NoDataComponent={() => null}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ProductsCVTable.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(ProductsCVTable))
);
