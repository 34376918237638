import React, { useMemo } from "react";
import { PAGE_HEIGHT, PAGE_WIDTH, combineYoYData } from "./utils";
import acosOrRoas from "utils/acosOrRoas";
import { Row, Col } from "react-bootstrap";
import Table from "./Table";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { getAdTypeName } from "utils/getAdTypeName";

const MediaPlanTrends = ({
  orgName = "",
  data,
  group,
  roasPreference = false,
  marketPlace,
  channel,
}) => {
  const { adPlanData, adPlanYoYData } = data;

  const adsSortedByAdSalesDelta = useMemo(() => {
    if (adPlanData) {
      return (
        adPlanData
          ?.map((adPlanData, index) => {
            return combineYoYData(adPlanData, adPlanYoYData?.[index]);
          })
          ?.filter((ad) => ad.ad_sales_delta)
          ?.sort((a, b) => b.ad_sales_delta - a.ad_sales_delta) ?? []
      );
    }
    return [];
  }, [adPlanData, adPlanYoYData]);

  const topTrendingPlans = useMemo(() => {
    return (
      adsSortedByAdSalesDelta
        ?.filter((ad) => ad.ad_sales_delta > 0)
        ?.slice(0, 8) ?? [adsSortedByAdSalesDelta] ??
      []
    );
  }, [adsSortedByAdSalesDelta]);

  const downTrendingPlans = useMemo(() => {
    return adsSortedByAdSalesDelta
      ?.filter((ad) => ad.ad_sales_delta < 0)
      ?.slice(-8)
      ?.reverse();
  }, [adsSortedByAdSalesDelta]);

  const adsTableColumns = useMemo(
    () => [
      {
        accessor: "ad_name",
        Header: "Advertisement",
        customCell: (advertisement) => {
          const { ad_name } = advertisement;

          return (
            <div>
              <span className={"w-100 truncate"}>{ad_name}</span>
            </div>
          );
        },
        customCompareCell: (advertisement) => {
          const { ad_type } = advertisement;

          return (
            <div className="fs-6 pb-1">
              <span className="ps-2">{getAdTypeName(ad_type, channel)}</span>
            </div>
          );
        },
      },
      {
        Header: "Ad Sales",
        accessor: "ad_sales",
        compareMetricId: "ad_sales_percent",
        yoyCompareMetricId: "ad_sales_percent_yoy",
      },
      {
        Header: "Ad Spend",
        accessor: "ad_spend",
        compareMetricId: "cost_percent",
        yoyCompareMetricId: "cost_percent_yoy",
      },
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "total_acos",

          compareMetricId: "total_acos_delta",
          yoyCompareMetricId: "total_acos_delta_yoy",
          Header: "Total ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "troas",
          compareMetricId: "total_roas_percent",
          yoyCompareMetricId: "total_roas_percent_yoy",
          Header: "Total ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "acos",

          compareMetricId: "acos_delta",
          yoyCompareMetricId: "acos_delta_yoy",
          Header: "ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "roas",
          compareMetricId: "roas_delta",
          yoyCompareMetricId: "roas_delta_yoy",
          Header: "ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
      {
        Header: "Clicks",
        accessor: "clicks",
        compareMetricId: "clicks_percent",
        yoyCompareMetricId: "clicks_percent_yoy",
      },
      {
        Header: "Impressions",
        accessor: "impressions",
        compareMetricId: "impressions_percent",
        yoyCompareMetricId: "impressions_percent_yoy",
      },
      {
        Header: "Conversions",
        accessor: "orders",
        compareMetricId: "conversion_percent",
        yoyCompareMetricId: "conversion_percent_yoy",
      },
    ],
    [roasPreference, channel]
  );

  return (
    <div
      className={`box box-common pt-5`}
      style={{
        maxWidth: PAGE_WIDTH,
        maxHeight: PAGE_HEIGHT,
        overflowY: "hidden",
      }}
    >
      <img src={logo} className="watermark" alt="Trellis logo" />
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"Top trending media plans *"}
            titleColWidth={30}
            data={topTrendingPlans}
            columns={adsTableColumns}
            showPrevPeriodTrendRow={true}
            marketPlace={marketPlace}
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"Media plans with declining sales trends *"}
            titleColWidth={30}
            data={downTrendingPlans}
            columns={adsTableColumns}
            showPrevPeriodTrendRow={true}
            marketPlace={marketPlace}
          />
        </Col>
      </Row>
      <span className="table-annotation">
        * Sorted by greatest change in raw dollar amount of ad sales descending
      </span>
    </div>
  );
};

export default MediaPlanTrends;
