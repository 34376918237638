import React, { useMemo } from "react";
import {
  MetricCompareSelectField,
  ConditionValues,
  ConditionScope,
} from "components/custom/programs/fields";
import { Form } from "react-bootstrap";
import { useWatch } from "react-hook-form";
import makeConditionOptions from "./makeConditionOptions";
import { useProgramContext } from "./ProgramContext";

/*
Conditions

Every-time i sell N units
Every N days
After Jan 1st 2021
Before Jan 1st
Between Jan 1st and Jan 31st
When N total units is sold
When N day average units sold is less than M
When N day average units sold is more than M

*/

const ConditionForm = ({ fieldName, control, formState, scope, divider }) => {
  const { engine } = useProgramContext();

  const selectedCondition = useWatch({
    name: `${fieldName}.option`,
    control,
  });

  let conditionOptions = useMemo(() => {
    const options = makeConditionOptions(fieldName, scope, engine, {
      selectedCondition: selectedCondition,
    });

    return options;
  }, [fieldName, scope, engine, selectedCondition]);

  const [operator, accessor] = selectedCondition
    ? JSON.parse(selectedCondition)
    : [null, null];

  const { scopeText, showScope } =
    conditionOptions?.find((o) => o.value === accessor) ?? {};

  return (
    <>
      <Form.Group>
        <div
          className={`d-flex align-items-center fs-standard flex-wrap ${
            divider ? "border-top pt-3" : ""
          }`}
        >
          {/* Metric select starts */}
          <MetricCompareSelectField
            fieldName={`${fieldName}.option`}
            control={control}
            rules={{ required: true }}
            metricOptions={conditionOptions}
          />
          <ConditionValues
            control={control}
            fieldName={fieldName}
            selectedOption={selectedCondition}
            conditionOptions={conditionOptions}
            scopeText={scopeText}
            accessor={accessor}
          />
          {showScope && (
            <ConditionScope
              control={control}
              fieldName={fieldName}
              selectedOption={selectedCondition}
              conditionOptions={conditionOptions}
            />
          )}
        </div>
      </Form.Group>
    </>
  );
};

export default ConditionForm;
