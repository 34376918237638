import {
  INVENTORY_STATES,
  SALE_STATES,
} from "components/core/blocks/AdPlan/constants";

export const INVENTORY_STATE_OPTIONS = [
  INVENTORY_STATES.IN_STOCK,
  INVENTORY_STATES.LOW_STOCK,
  INVENTORY_STATES.OUT_OF_STOCK,
  INVENTORY_STATES.OVERSTOCK,
];

export const SALE_STATE_OPTIONS = [
  SALE_STATES.BEST_SELLER,
  SALE_STATES.CLEARANCE,
  SALE_STATES.NEW_PRODUCT,
  SALE_STATES.NORMAL,
  SALE_STATES.INACTIVE,
];

export const ADVERTISING = "ADVERTISING";
export const DYNAMIC_PRICING = "DYNAMIC_PRICING";
export const SEARCH_ANALYTICS = "SEARCH_ANALYTICS";
export const NEW_PRODUCT_LAUNCH = "NEW_PRODUCT_LAUNCH";
export const DEALS = "DEALS";
export const CONTENT = "CONTENT";
export const PURCHASE_BEHAVIOR = "PURCHASE_BEHAVIOR";
