import moment from "moment";
import { METRIC_GROUPING_LOOKUP } from "../tables/metricsByEntity";

export const buildDateColumn = (key, colTitle, options = {}) => {
  return {
    key,
    grouping: METRIC_GROUPING_LOOKUP[key],
    compareKey: null,
    options: {
      metric: {
        id: key,
        format: "date",
        accessor: (d) => d[key],
        options: {
          className: "d-flex align-items-center",
          cellContainerClasses: "p-3 align-items-center",
        },
        name: colTitle,
      },
      formatter: (v) => {
        if (!v) return "-";
        return moment(v).format("ll");
      },
      hideFilter: true,
      ...options,
    },
  };
};
