import React, { useState } from "react";
import {
  StepTitle,
  AdStatusField,
  AgreeToArchiveField,
  CategoryField,
} from "./fields";
import { ExpandMore } from "@material-ui/icons";
import { IconButton, Grid, withStyles } from "@material-ui/core";
import AdPlanModal from "./AdPlanModal";
import { GOALS, AD_TYPES } from "./constants";
import WalmartSBFields from "./WalmartSBFields";
import { Row, Col } from "react-bootstrap";
import AssetSummary from "../AssetSelector/AssetSummary";
import AdPlanModerationWarnings from "./AdPlanModerationWarnings";
import CreativePreview from "../AssetSelector/CreativePreview";
import ReactCrop from "react-image-crop";
import { useFetch } from "hooks/api";
import { getAdTypeName } from "utils/getAdTypeName";
import FormGroupTile from "components/core/basic/FormGroupTile";
const useStyles = (theme) => ({
  summaryContainer: {
    padding: theme.spacing(0, 4),
  },
  stepTitle: {
    color: "#484848",
    cursor: "pointer",
  },
  reviewField: {
    fontSize: "1.5rem",
    padding: theme.spacing(1),
  },
  reviewLabel: {
    width: "110px",
    color: "#73706E",
    fontWeight: 700,
  },
  reviewValue: {
    paddingLeft: theme.spacing(1),
    fontWeight: 300,
    color: "#73706E",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
  },
  stateContainer: {
    margin: theme.spacing(2, 1),
  },
});

const creativeCheck = (adPlan) => {
  return (
    // Check for present of any option field for SD, otherwise always display creative for any type of SB media plan
    adPlan.creativeAsset ||
    adPlan.brandLogoAssetID ||
    adPlan.logo ||
    adPlan.headline ||
    [
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
    ].includes(adPlan.adType)
  );
};

const AdPlanSummary = ({
  setEditStep,
  setEditSubstep,
  setStep,
  adTypeFields,
  sbFields,
  targetingFields,
  state,
  onChange,
  onCreate,
  onParentUpdate,
  marketPlace,
  errors,
  fieldsToValidate,
  adId,
  products,
  isChild,
  user,
  channel,
  categories,
  classes,
  agreeToArchive,
  setAgreeToArchive,
  apiStatus,
  acosGoalsField,
  productFields,
  creativeProductFields,
  adPlanChildrenSummaryField,
  videoField,
  creativesAssetSelectionFields,
  storeSpotlightFields,
  review,
  isModal,
  fullPage,
  hideStatusField,
  modules,
}) => {
  const [showSetupFields, setShowSetupFields] = useState(true);
  const [showBrandSetupFields, setShowBrandSetupFields] = useState(true);
  const [showCreativeProductFields, setShowCreativeProductFields] =
    useState(true);
  const [showProductFields, setShowProductFields] = useState(true);
  const [showTargetingFields, setShowTargetingFields] = useState(true);

  const nonTrellisCampaignFlag = state.goal === GOALS.IMPORTED_ADS.value;

  const { data: categoryData } = useFetch(
    ["categories", state.categoryId],
    `/api/v2/categories/${state.categoryId}`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: !!state.categoryId,
    }
  );

  const getGoalName = (value) => {
    let goalArray = [];
    for (const goal in GOALS) {
      goalArray.push(GOALS[goal]);
    }

    return goalArray.find((g) => g.value === value)?.label ?? "";
  };

  return (
    <>
      <div className={classes.summaryContainer}>
        <h2 style={{ fontWeight: 700, marginTop: 0, fontSize: "24px" }}>
          Review: {state.name}
        </h2>
        <StepTitle
          children={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={classes.stepTitle}>1. Setup </span>
                {adId || (!state.id && state.hasParent) ? null : (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setEditStep(0);
                      setEditSubstep(null);
                      setStep(0);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="fa fa-pencil fa-sm"
                      style={{ fontSize: "16px", cursor: "pointer" }}
                    />
                  </IconButton>
                )}
              </div>
              <div>
                <ExpandMore
                  style={{
                    transform: showSetupFields
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    fontSize: 32,
                    marginBottom: "-1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowSetupFields(!showSetupFields)}
                />
              </div>
            </div>
          }
        />
        {showSetupFields && (
          <>
            <Grid>
              <Grid container className={classes.reviewField}>
                <span className={classes.reviewLabel}>Title: </span>
                <span className={classes.reviewValue}>{state.name}</span>
              </Grid>
              {!state.id && (
                <Grid container className={classes.reviewField}>
                  <span className={classes.reviewLabel}>Category: </span>
                  <span className={classes.reviewValue}>
                    {categories?.find((c) => c.value === state.categoryId)
                      ?.label ?? categoryData?.name}
                  </span>
                </Grid>
              )}
              <Grid container className={classes.reviewField}>
                <span className={classes.reviewLabel}>Strategy: </span>
                <span className={classes.reviewValue}>
                  {getGoalName(state.goal)}
                </span>
              </Grid>
              <Grid>
                <Grid container className={classes.reviewField}>
                  <span className={classes.reviewLabel}>Ad Type: </span>
                  <span className={classes.reviewValue}>
                    {getAdTypeName(state.adType, channel)}
                  </span>
                </Grid>
              </Grid>
              {state.id && (
                <Grid
                  container
                  className={`${classes.reviewField} d-flex align-items-center`}
                >
                  <span className={classes.reviewLabel}>Category: </span>
                  <CategoryField
                    categories={categories}
                    state={state}
                    dashboard={true}
                    onChange={onChange}
                    errors={errors}
                    fieldsToValidate={fieldsToValidate}
                    review={review}
                    channel={channel}
                    marketPlace={marketPlace}
                    existingAdCategorySelector={true}
                  />
                </Grid>
              )}
              <Grid container className={classes.reviewField}>
                <Grid item xs={12} lg={fullPage ? 9 : 12}>
                  {acosGoalsField}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                lg={fullPage ? 9 : 12}
                className={classes.stateContainer}
              >
                <AdStatusField
                  state={state}
                  onChange={onChange}
                  marketPlace={marketPlace}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  isChild={isChild}
                  user={user}
                  channel={channel}
                />
              </Grid>
              <Grid item xs={12} className={classes.stateContainer}>
                <AgreeToArchiveField
                  state={state}
                  agreeToArchive={agreeToArchive}
                  setAgreeToArchive={setAgreeToArchive}
                  user={user}
                  apiStatus={apiStatus}
                  adId={adId}
                />
              </Grid>
            </Grid>
          </>
        )}

        {/* COMPOSITE SUMMARY */}
        {state.canHaveChildren && (
          <>
            <StepTitle
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span className={classes.stepTitle}>2. Tactics </span>
                  </div>
                </div>
              }
            />
            <div>{adPlanChildrenSummaryField}</div>
          </>
        )}
        {[
          AD_TYPES.SPONSORED_BRAND.value,
          AD_TYPES.SPONSORED_BRAND_VIDEO.value,
        ].includes(state.adType) && (
          <>
            <StepTitle
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={classes.stepTitle}>2. Brand Setup </span>
                    {adId ? null : (
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          setEditStep(0);
                          setEditSubstep(null);
                          setStep(0);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="fa fa-pencil fa-sm"
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        />
                      </IconButton>
                    )}
                  </div>
                  <div>
                    <ExpandMore
                      style={{
                        transform: showBrandSetupFields
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        fontSize: 32,
                        marginBottom: "-1rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowBrandSetupFields(!showBrandSetupFields)
                      }
                    />
                  </div>
                </div>
              }
            />

            {showBrandSetupFields && (
              <>
                {adId &&
                  state.policyViolations?.length > 0 &&
                  channel !== "walmart" && (
                    <AdPlanModerationWarnings
                      violations={state.policyViolations}
                    />
                  )}
                {sbFields}
                {state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                  state.page?.value && (
                    <Grid style={{ padding: "2rem 0" }}>{videoField}</Grid>
                  )}
              </>
            )}
          </>
        )}
        {(!state.storeLandingPage ||
          state.storeSpotlight ||
          !state.page?.label ||
          (state.newProductCollection && !state.page?.label)) &&
          !nonTrellisCampaignFlag && (
            <>
              <StepTitle
                overrideStyles={review ? { marginBottom: 0 } : {}}
                children={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        className={`${classes.stepTitle} ${
                          state.adType ===
                            AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                          !state.logo &&
                          !state.id
                            ? "mt-4"
                            : ""
                        }`}
                      >
                        {state.canHaveChildren ||
                        [
                          AD_TYPES.SPONSORED_BRAND.value,
                          AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                        ].includes(state.adType)
                          ? "3"
                          : "2"}
                        .{" "}
                        {state.storeSpotlight
                          ? "Store Spotlight Subpages"
                          : state.newLandingPage ||
                            state.newProductCollection ||
                            (state.adType ===
                              AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                              !state.page?.value &&
                              state.newLandingPage)
                          ? "Landing Page Products"
                          : state.storeLandingPage ||
                            state.adType ===
                              AD_TYPES.SPONSORED_BRAND_VIDEO.value
                          ? "Creative Products"
                          : "Ad Products"}
                      </span>

                      {(!state.id && state.hasParent) ||
                      state.adType === AD_TYPES.SPONSORED_BRAND.value ||
                      state.adType ===
                        AD_TYPES.SPONSORED_BRAND_VIDEO.value ? null : adId ? (
                        <AdPlanModal
                          adId={state.id}
                          categoryId={state.categoryId}
                          categories={categories}
                          roas={false}
                          dashboard={false}
                          onCreate={onCreate}
                          onParentUpdate={onParentUpdate}
                          initialAdPlan={state}
                          inlineModal={true}
                          editStep={2}
                          inlineModalText={
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                setEditStep(3);
                                setEditSubstep(0);
                                setStep(3);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className="fa fa-pencil fa-sm"
                                style={{ fontSize: "16px", cursor: "pointer" }}
                              />
                            </IconButton>
                          }
                        />
                      ) : (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            setEditStep(2);
                            setEditSubstep(null);
                            setStep(2);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fa fa-pencil fa-sm"
                            style={{ fontSize: "16px", cursor: "pointer" }}
                          />
                        </IconButton>
                      )}
                    </div>
                    <div>
                      <ExpandMore
                        style={{
                          transform: showProductFields
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          fontSize: 32,
                          marginBottom: "-1rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowProductFields(!showProductFields)}
                      />
                    </div>
                  </div>
                }
              />
              {showProductFields && (
                <>
                  <Grid style={{ paddingTop: "1rem" }}>
                    <Grid container className={classes.reviewField}>
                      {state.storeSpotlight
                        ? storeSpotlightFields
                        : productFields}
                    </Grid>
                  </Grid>
                  {[...adTypeFields[0].slice(1)]}
                </>
              )}
            </>
          )}
        <>
          {((state.adType === AD_TYPES.SPONSORED_BRAND.value &&
            (!state.storeSpotlight || state.newLandingPage)) ||
            (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
              state.logo)) && (
            <>
              <StepTitle
                overrideStyles={review ? { marginBottom: 0 } : {}}
                children={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className={classes.stepTitle}>
                        {state.adType ===
                          (AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                            !state.logo) || state.page?.value
                          ? "3"
                          : "4"}
                        .{" "}
                        {state.storeSpotlight && state.newLandingPage
                          ? "Landing Page Products"
                          : "Creative Products"}
                      </span>

                      {(!state.id && state.hasParent) ||
                      state.adType ===
                        AD_TYPES.SPONSORED_BRAND.value ? null : adId ? (
                        <AdPlanModal
                          adId={state.id}
                          categoryId={state.categoryId}
                          categories={categories}
                          roas={false}
                          dashboard={false}
                          onCreate={onCreate}
                          onParentUpdate={onParentUpdate}
                          initialAdPlan={state}
                          inlineModal={true}
                          editStep={2}
                          inlineModalText={
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                setEditStep(3);
                                setEditSubstep(0);
                                setStep(3);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className="fa fa-pencil fa-sm"
                                style={{
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          }
                        />
                      ) : (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            setEditStep(2);
                            setEditSubstep(null);
                            setStep(2);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fa fa-pencil fa-sm"
                            style={{ fontSize: "16px", cursor: "pointer" }}
                          />
                        </IconButton>
                      )}
                    </div>
                    <div>
                      <ExpandMore
                        style={{
                          transform: showCreativeProductFields
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          fontSize: 32,
                          marginBottom: "-1rem",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setShowCreativeProductFields(
                            !showCreativeProductFields
                          )
                        }
                      />
                    </div>
                  </div>
                }
              />
              {showCreativeProductFields && (
                <>
                  <Grid style={{ paddingTop: "1rem" }}>
                    <Grid container className={classes.reviewField}>
                      {state.storeSpotlight && state.newLandingPage
                        ? productFields
                        : creativeProductFields}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
          {([
            AD_TYPES.SPONSORED_BRAND_WALMART.value,
            AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
          ].includes(state.adType) ||
            (state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
              creativeCheck(state))) && (
            <>
              <StepTitle
                children={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "3rem",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className={classes.stepTitle}>3. Creatives </span>
                      {adId &&
                      !state.adType ===
                        AD_TYPES.SPONSORED_DISPLAY_V2.value ? null : (
                        <AdPlanModal
                          adId={state.id}
                          categoryId={state.categoryId}
                          categories={categories}
                          roas={false}
                          dashboard={false}
                          onCreate={onCreate}
                          initialAdPlan={state}
                          inlineModal={true}
                          editStep={3}
                          inlineModalText={
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                setEditStep(3);
                                setEditSubstep(null);
                                setStep(3);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className="fa fa-pencil fa-sm"
                                style={{
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          }
                        />
                      )}
                    </div>
                    <div>
                      <ExpandMore
                        style={{
                          transform: showBrandSetupFields
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          fontSize: 32,
                          marginBottom: "-1rem",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setShowBrandSetupFields(!showBrandSetupFields)
                        }
                      />
                    </div>
                  </div>
                }
              />
              {showBrandSetupFields &&
                [
                  AD_TYPES.SPONSORED_BRAND_WALMART.value,
                  AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                ].includes(state.adType) && (
                  <Row style={{ marginBottom: "1rem" }}>
                    <Col xs={12}>
                      <WalmartSBFields
                        state={state}
                        errors={errors}
                        channel={channel}
                        onChange={onChange}
                        adId={adId}
                        review={review}
                        isModal={isModal}
                        fieldsToValidate={fieldsToValidate}
                      />
                    </Col>
                  </Row>
                )}
              {creativeCheck(state) && showBrandSetupFields && (
                <>
                  {adId &&
                    state.policyViolations?.length > 0 &&
                    channel !== "walmart" && (
                      <AdPlanModerationWarnings
                        violations={state.policyViolations}
                      />
                    )}
                  {creativesAssetSelectionFields}
                  <Row>
                    <>
                      {typeof state.creativeModerationStatus === "string" &&
                        !["APPROVED", "PENDING_REVIEW", "FAILURE"]?.includes(
                          state.creativeModerationStatus
                        ) && (
                          <small className="text-danger">
                            {state.creativeModerationStatus}
                          </small>
                        )}
                    </>
                    <AssetSummary
                      assetId={
                        state.videoDetails?.asset_id ??
                        state.lifestyleImageDetails?.asset_id ??
                        state.video
                      }
                      assetDescriptor={
                        state.lifestyleImageDetails?.type === "VIDEO" ||
                        state.videoDetails?.type === "VIDEO"
                          ? "Video Creative"
                          : "Lifestyle Image"
                      }
                      assetType={
                        state.videoDetails?.type ??
                        state.lifestyleImageDetails?.type
                      }
                      hidden={
                        (!state.videoDetails && !state.lifestyleImageDetails) ||
                        state.sdRectangleCroppedArea?.height
                      }
                      summaryContainerClasses={` ${
                        state.videoDetails?.type === "VIDEO"
                          ? "pr-5 my-0"
                          : "pr-5 my-5"
                      }`}
                    />
                    {state.sdRectangleCroppedArea?.height && (
                      <Col
                        xs={12}
                        lg={6}
                        className={`${"pr-5 pt-5"} mt-1 col-lg-6 col-12"`}
                      >
                        <h6
                          className="title mb-4 fs-4"
                          style={{
                            color: "#73706E",
                            fontWeight: "700",
                            textTransform: "none",
                          }}
                        >
                          Lifestyle Image (Cropped)
                        </h6>
                        {state.lifestyleImageDetails?.name && (
                          <div className="mb-4">
                            <FormGroupTile
                              type="formcontrol"
                              defaultValue={state.lifestyleImageDetails?.name}
                              disabled={true}
                            />
                          </div>
                        )}
                        <ReactCrop crop={state.sdRectangleCroppedArea} zoom={1}>
                          <img
                            src={state.lifestyleImageDetails?.url_default}
                            alt={"Preview of cropped asset."}
                            crossorigin="anonymous"
                          />
                        </ReactCrop>
                      </Col>
                    )}
                    {state.sdSquareCroppedArea?.height && (
                      <Col
                        xs={12}
                        lg={6}
                        className={`pl-5 pt-5 mt-1 col-lg-6 col-12"`}
                      >
                        <h6
                          className="title mb-4 fs-4"
                          style={{
                            color: "#73706E",
                            fontWeight: "700",
                            textTransform: "none",
                          }}
                        >
                          Lifestyle Image (Square)
                        </h6>
                        {state.lifestyleImageDetails?.name && (
                          <div className="mb-4">
                            <FormGroupTile
                              type="formcontrol"
                              defaultValue={state.lifestyleImageDetails?.name}
                              disabled={true}
                            />
                          </div>
                        )}
                        <ReactCrop
                          crop={state.sdSquareCroppedArea}
                          zoom={1}
                          aspect={1}
                        >
                          <img
                            src={state.lifestyleImageDetails?.url_default}
                            alt={"Preview of cropped asset."}
                            crossorigin="anonymous"
                            style={{ maxHeight: "300px", objectFit: "contain" }}
                          />
                        </ReactCrop>
                      </Col>
                    )}

                    <AssetSummary
                      assetId={state.logoDetails?.asset_id ?? state.logo}
                      assetDescriptor={"Brand Logo"}
                      assetType={state.logoDetails?.type}
                      hidden={!state.logoDetails && !state.logo}
                      summaryContainerClasses={
                        (!state.videoDetails && !state.lifestyleImageDetails) ||
                        (state.lifestyleImageDetails &&
                          state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value)
                          ? "pr-5 my-5"
                          : "pl-5 my-5"
                      }
                    />
                  </Row>
                  {state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                    !isModal && (
                      <Row className="mb-4">
                        <CreativePreview
                          state={state}
                          // Creative preview endpoint doesn't return HTML consistently with more than 1 ASIN, for now just include first selected
                          asin={
                            products
                              ?.filter((p) => state.product?.includes(p.value))
                              ?.map((p) => p.asin)?.[0] ?? ""
                          }
                          marketPlace={marketPlace?.marketPlace}
                          showCreativePreview={true}
                          accordionLayout={false}
                        />
                      </Row>
                    )}
                </>
              )}
            </>
          )}
          <StepTitle
            children={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className={classes.stepTitle}>
                    {nonTrellisCampaignFlag
                      ? "2"
                      : state.canHaveChildren ||
                        [
                          AD_TYPES.SPONSORED_BRAND.value,
                          AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                          AD_TYPES.SPONSORED_BRAND_WALMART.value,
                          AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                        ].includes(state.adType) ||
                        (state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                          creativeCheck(state))
                      ? !state.page?.label &&
                        (state.adType !==
                          AD_TYPES.SPONSORED_BRAND_VIDEO.value ||
                          (state.adType ===
                            AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                            state.logo))
                        ? [
                            AD_TYPES.SPONSORED_DISPLAY_V2.value,
                            AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                            AD_TYPES.SPONSORED_BRAND_WALMART.value,
                            AD_TYPES.CUSTOM.value,
                          ]?.includes(state.adType) ||
                          (state.storeSpotlight && !state.newLandingPage)
                          ? "4"
                          : "5"
                        : "4"
                      : "3"}
                    . Targeting{" "}
                  </span>

                  {!state.id && state.hasParent ? null : adId ? (
                    <AdPlanModal
                      adId={state.id}
                      categoryId={state.categoryId}
                      categories={categories}
                      roas={false}
                      dashboard={false}
                      onCreate={onCreate}
                      onParentUpdate={onParentUpdate}
                      initialAdPlan={state}
                      inlineModal={true}
                      editStep={
                        state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
                          ? 4
                          : state.storeSpotlight && !state.newLandingPage
                          ? 2
                          : 3
                      }
                      inlineModalText={
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            let partialEditStep =
                              state.adType ===
                              AD_TYPES.SPONSORED_DISPLAY_V2.value
                                ? 4
                                : state.storeSpotlight && !state.newLandingPage
                                ? 2
                                : 3;
                            setEditStep(partialEditStep);
                            setEditSubstep(0);
                            setStep(partialEditStep);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fa fa-pencil fa-sm"
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      }
                    />
                  ) : (
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        let partialEditStep =
                          state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
                            ? 4
                            : 3;
                        setEditStep(partialEditStep);
                        setEditSubstep(0);
                        setStep(partialEditStep);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fa fa-pencil fa-sm"
                        style={{ fontSize: "16px", cursor: "pointer" }}
                      />
                    </IconButton>
                  )}
                </div>
                <div>
                  <ExpandMore
                    style={{
                      transform: showTargetingFields
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      fontSize: 32,
                      marginBottom: "-1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowTargetingFields(!showTargetingFields)}
                  />
                </div>
              </div>
            }
          />
          {showTargetingFields && [...targetingFields]}
        </>
      </div>
    </>
  );
};

export default withStyles(useStyles)(AdPlanSummary);
