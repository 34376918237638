import React from "react";
import {
  ToolTips,
  SORT_ALPHABETICALLY_ASCENDING,
  SORT_ALPHABETICALLY_DESCENDING,
} from "utils/hiddenToolTips";

const SortIcon = ({ sortBy, setSortBy }) => {
  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          sortBy === "alphaAscending"
            ? setSortBy("alphaDescending")
            : setSortBy("alphaAscending");
        }}
        className="btn-round btn-icon"
      >
        <ToolTips
          toolTip={
            sortBy === "alphaDescending"
              ? SORT_ALPHABETICALLY_ASCENDING
              : SORT_ALPHABETICALLY_DESCENDING
          }
          position={"top"}
        />
        <i
          className={`fa fa-solid fa-sort-${
            sortBy === "alphaAscending" ? "up" : "down"
          }`}
        ></i>
      </button>
    </div>
  );
};

export default SortIcon;
