import axios from "axios";
import Cryptr from "cryptr";
import { setTokens } from "redux/actions/authActions";
import store from "redux/store";
import { CLIENT_SIDE_ENCRYPTION_KEY } from "utils/gvSecrets";

const cryptr = new Cryptr(CLIENT_SIDE_ENCRYPTION_KEY);

export const setAuthTokens = (accessToken, refreshToken) => {
  let encrypted_refresh_token = cryptr.encrypt(refreshToken);
  let tokens = Object.assign(
    { access_token: accessToken },
    { refresh_token: encrypted_refresh_token }
  );
  localStorage.setItem("TokenTime", (Date.now() + 60 * 60 * 1000).toString());
  localStorage.setItem("Tokens", JSON.stringify(tokens));
  if (accessToken) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    store.dispatch(setTokens(tokens));
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("Tokens");
  }
  return tokens;
};

export const getAuthTokens = () => {
  if (localStorage.Tokens) {
    const tokens = JSON.parse(localStorage.Tokens);
    tokens.refresh_token = cryptr.decrypt(tokens.refresh_token);
    return tokens;
  }
  return {};
};
