import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import api from "utils/api";
import { selectModules } from "redux/selectors/modules";
import AnalyticsStats from "components/custom/statsPanels/AnalyticsStats";

class AnalyticsStatsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      atf_sos_average: 0,
      atf_sov_average: 0,
      atf_sos_organic_average: 0,
      sos_average: 0,
      sov_average: 0,
      sos_organic_average: 0,
      position_average: 0,
      marketPlace: this.props.marketPlace.marketPlace,
      channel: this.props.channel.currentChannel,
      statCardColumns: "true",
      loading: true,
      endDate: this.props.endDate,
      startDate: this.props.startDate,
    };
  }

  componentDidMount() {
    this.getAnalyticsStats();
  }

  componentDidUpdate() {
    if (this.state.marketPlace !== this.props.marketPlace.marketPlace) {
      this.getAnalyticsStats();

      this.setState({ marketPlace: this.props.marketPlace.marketPlace });
    } else if (this.state.endDate !== this.props.endDate) {
      this.setState({ endDate: this.props.endDate });
      this.getAnalyticsStats();
    } else if (this.state.startDate !== this.props.startDate) {
      this.setState({ startDate: this.props.startDate });
      this.getAnalyticsStats();
    }
  }

  async getAnalyticsStats() {
    let params = {
      summary: true,
      stats_by: "shelf",
    };

    if (this.props.endDate !== null) {
      params.end = this.props.endDate;
    }

    if (this.props.startDate !== null) {
      params.start = this.props.startDate;
    }

    // the loading state will cause the cards to dissappear while waiting for response. IMO it would look better if the cards just had "-" until we get res data
    const res = await api.get("/api/shelf_stats/", params);
    if (Object.keys(res.data).length === 0) {
      this.setState({ loading: false });
    } else {
      this.setState({
        loading: false,
        atf_sos_average: res.data.atf_sos_average,
        atf_sos_organic_average: res.data.atf_sos_organic_average,
        atf_sov_average: res.data.atf_sov_average,
        sos_average: res.data.sos_average,
        sos_organic_average: res.data.sos_organic_average,
        sov_average: res.data.sov_average,
        position_average: res.data.position_average,
      });
    }
  }

  render() {
    return <Panel channel={this.state.channel} state={this.state} />;
  }
}

const Panel = ({ channel, state }) => {
  if (state.loading) {
    return null;
  }

  return <AnalyticsStats state={state} />;
};

AnalyticsStatsPanel.propTypes = {
  auth: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
  channel: state.channel,
});

export default connect(mapStateToProps)(withRouter(AnalyticsStatsPanel));
