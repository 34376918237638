import { SET_OVERRIDE_MODULE, UNSET_OVERRIDE_MODULE } from "../types";

const initialState = {};

export default function (state = initialState, action) {
  const orgId = action.payload?.orgId;
  if (!orgId) {
    return state;
  }
  let orgState;
  switch (action.type) {
    case SET_OVERRIDE_MODULE:
      state = { ...state };
      state[orgId] = state[orgId] || [];
      orgState = state[orgId];
      return { ...state, [orgId]: [...orgState, action.payload.module] };
    case UNSET_OVERRIDE_MODULE:
      state = { ...state };
      state[orgId] = state[orgId] || [];
      orgState = state[orgId];
      return {
        ...state,
        [orgId]: orgState.filter((m) => m.name !== action.payload.name),
      };
    default:
      return state;
  }
}
