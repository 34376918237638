import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

const ColumnSelector = ({ columnOptions, onColumnChange }) => {
  return (
    <DropdownButton
      renderMenuOnMount={true}
      flip={false}
      title={<i style={{ fontSize: "2rem" }} className="fa fa-gear"></i>}
      bsPrefix="col-selector"
    >
      {columnOptions.map(({ id, label, checked }, i) => (
        <React.Fragment key={`${id}-${i}`}>
          {id === "seperator" && <Dropdown.Divider />}
          {id !== "seperator" && (
            <div key={id} role="menuitem" className="py-1">
              <label
                style={{
                  marginLeft: "1rem",
                  marginTop: "0.5rem",
                  fontWeight: "normal",
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                <input
                  type="checkbox"
                  style={{ height: "unset", marginRight: "1rem" }}
                  onChange={(e) => {
                    e.stopPropagation();
                    onColumnChange({ [id]: e.target.checked });
                  }}
                  checked={checked}
                />
                {label}
              </label>
            </div>
          )}
        </React.Fragment>
      ))}
    </DropdownButton>
  );
};

export default ColumnSelector;
