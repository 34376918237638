import React, { useState, useEffect, useRef } from "react";
import FilterColumnSelector from "./FilterColumnSelector";
import { Badge } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import FilterForm from "./FilterForm";
import useOnScreen from "hooks/useOnScreen";

const FilterWidget = ({
  tableRef,
  customFilters,
  setCustomFilters,
  formatFilterValue,
  additionalFilterOptions = [],
  excludedFilterColumns,
  initialFilters,
  tableId,
}) => {
  const [showForm, setShowForm] = useState(null);
  const widgetVisiblityRef = useRef();

  const columnOptions = tableRef?.current?.props?.columns?.map((col) => {
    const { Header, format, id, onValue, offValue, accessorId } = col;

    return {
      Header,
      format,
      id,
      onValue,
      offValue,
      accessorId,
    };
  });

  useEffect(() => {
    // Event listeners for auto-showing new filter form on creation
    const filterBadges = [
      ...document.querySelectorAll(`.${tableId} .filter-badge`),
    ];
    const lastBadge = filterBadges[filterBadges.length - 1];
    const lastFilter = customFilters[customFilters.length - 1];

    if (
      (lastFilter?.value && !["all"]?.includes(lastFilter.value)) ||
      customFilters[customFilters.length - 1]?.min ||
      customFilters[customFilters.length - 1]?.max ||
      (customFilters?.length === initialFilters?.length &&
        customFilters[customFilters?.length - 1].id ===
          initialFilters[initialFilters?.length - 1].id)
    ) {
      return;
    }

    if (lastBadge) {
      lastBadge.click();
    }
  }, [customFilters.length, customFilters, initialFilters, tableId]);

  // Below resolves issues where tables hidden within closed accordion doesn't reliably create a new table ref
  // in time to load column filter options
  useOnScreen(widgetVisiblityRef);

  return (
    <div className="d-flex flex-wrap" ref={widgetVisiblityRef}>
      {customFilters?.map((filter, index) => {
        const { id, label, format, operator, value, min, max, selectOptions } =
          filter;
        return (
          <>
            <Badge
              bsClass="badge"
              className="filter-badge bg-light border border-muted text-muted m-2 py-2 px-4 fs-5 p-relative"
              pill
              onClick={(e) => setShowForm(index)}
              style={{ position: "relative", cursor: "pointer" }}
            >
              {label}
              {operator === "-" ? (
                <span className="ps-1 fs-6">
                  {formatFilterValue(min, format) ?? ""}-
                  {formatFilterValue(max, format) ?? ""}
                </span>
              ) : (
                <>
                  <span className="ps-1 fs-6">{operator ?? ""} </span>
                  <span className="fs-6">
                    {typeof formatFilterValue(value, format, selectOptions) ===
                    "object" ? (
                      <>
                        <span>
                          {formatFilterValue(value, format, selectOptions)?.[0]}
                          {formatFilterValue(value, format, selectOptions)
                            ?.length > 1 && (
                            <>
                              {" "}
                              +
                              {formatFilterValue(value, format, selectOptions)
                                ?.length - 1}
                            </>
                          )}
                        </span>
                      </>
                    ) : (
                      formatFilterValue(value, format, selectOptions) ?? ""
                    )}
                  </span>
                </>
              )}
              <FaChevronDown className="ps-3 fs-2" />

              <FilterForm
                showForm={showForm === index}
                setShowForm={setShowForm}
                id={id}
                index={index}
                filter={filter}
                setFilter={(filter) => {
                  const updatedFilters = customFilters.map(
                    (filterOption, filterIndex) => {
                      if (filterIndex === index) {
                        return filter;
                      }
                      return filterOption;
                    }
                  );
                  setCustomFilters(updatedFilters);
                }}
                removeFilter={() => {
                  const updatedFilters = customFilters.filter(
                    (f, filterIndex) => filterIndex !== index
                  );
                  setCustomFilters(updatedFilters);
                }}
                formatFilterValue={formatFilterValue}
              />
            </Badge>
          </>
        );
      })}

      <div className="d-flex">
        <FilterColumnSelector
          columnOptions={columnOptions}
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
          additionalFilterOptions={additionalFilterOptions}
          excludedFilterColumns={excludedFilterColumns}
        />
        {customFilters.length ? (
          <div
            className="dropdown-title fs-standard ms-4 d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => setCustomFilters([])}
          >
            <i className="fa fa-trash pe-3 text-muted" />
            Clear filter{customFilters.length > 1 ? "s" : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FilterWidget;
