import React from "react";
import { LogoutButton } from "components/core/basic/Button";
import { useState } from "react";
import CenteredModal from "components/core/basic/CenteredModal";
import { Row, Col, Form } from "react-bootstrap";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { AuthButton } from "components/core/basic/Button";
import { withStyles, Slider } from "@material-ui/core";
import withAdPlan from "./withAdPlan";
import { ALGORITHM_OPTIONS } from "./constants";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import ToolTips from "utils/toolTips";
import AdPlanBiddingRules from "./AdPlanBiddingRules";
import checkModule from "utils/checkModule";
import { useSelector } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import theme from "assets/themes/TrellisTheme";

const AlgoSlider = withStyles({
  root: {
    color: "#ddd",
    opacity: "0.7",
  },
  thumb: {
    height: 22,
    width: 22,
    border: "1px solid #C53066",
    "&:focus,&:hover,&$active": {
      boxShadow: "none",
      border: "1px solid #C53066",
    },
  },
  active: {
    color: "#62407f",
  },
  track: {
    height: 10,
    borderRadius: 10,
    color: "#C53066",
  },
  rail: {
    height: 10,
    borderRadius: 24,
    opacity: 1,
  },
  mark: {
    color: "#FFF",
    zIndex: 1000,
    height: 10,
    marginLeft: 3,
    marginTop: 0,
  },
})(Slider);

const NTMFields = ({ onChange, adPlan, roas }) => {
  const reduxState = useSelector((state) => state);
  const modules = selectModules(reduxState);

  const targetACOS = adPlan?.targetACOS;
  const maxACOS = adPlan?.maxACOS;
  const salesVolumeControl = adPlan?.salesVolumeControl || 4;
  const algorithm = adPlan?.algorithm;

  let algorithmOptions = [
    ALGORITHM_OPTIONS.ROAS_BOOST,
    ALGORITHM_OPTIONS.GROWTH_MAX,
  ];

  if (checkModule(modules, "BIDDING_RULES")) {
    algorithmOptions.push(ALGORITHM_OPTIONS.RULES_ENGINE_ONLY);
  }

  return (
    <>
      <Row>
        <p className="text-muted fs-standard pb-3">
          Selecting your preferred optimization algorithm and sales volume
          control will allow the Trellis system to calculate bid-previews.
          Please note that bids will not be pushed until Ad Management is
          enabled.
        </p>
      </Row>
      <Row>
        <FormGroupTile
          type="select"
          stateName={"algorithm"}
          label={"Optimization:"}
          handleChildFormElement={(key, value) => {
            if (value.value === ALGORITHM_OPTIONS.ROAS_BOOST.value) {
              onChange({ [key]: value.value, crSelectorEnabled: true });
            } else {
              onChange({
                [key]: value.value,
                crSelectorEnabled: false,
              });
            }
          }}
          disabled={false}
          defaultValue={algorithmOptions.find((a) => a.value === algorithm)}
          options={algorithmOptions}
        />
      </Row>

      {checkModule(modules, "BIDDING_RULES") &&
        adPlan.algorithm === ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value && (
          <Row>
            <AdPlanBiddingRules
              state={adPlan}
              onChange={onChange}
              theme={theme}
              errors={
                adPlan.algorithm ===
                  ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value &&
                !adPlan.biddingProgram
                  ? {
                      existingBiddingProgram:
                        "A bidding program is required. Please select an existing program or create a new one.",
                    }
                  : {}
              }
              hideDescription={true}
            />
          </Row>
        )}

      <Row className="mt-5">
        <Col>
          <Form.Label
            style={{
              color: "#73706E",
              fontWeight: "700",
              textTransform: "none",
            }}
          >
            Sales Volume Control:
          </Form.Label>
          <AlgoSlider
            aria-labelledby="discrete-slider-always"
            step={1}
            min={0}
            max={9}
            ThumbComponent={"div"}
            marks={[
              { value: 0, label: "Increase Margin" },
              { value: 1, label: "Optimal Profit" },
              { value: 2 },
              { value: 3 },
              { value: 4 },
              { value: 5 },
              { value: 6 },
              { value: 7 },
              { value: 8 },
              { value: 9, label: "Max Volume" },
            ]}
            defaultValue={salesVolumeControl}
            valueLabelDisplay="off"
            onChange={(e, value) => {
              onChange({
                salesVolumeControl: value,
              });
            }}
            size="medium"
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} md={6} lg={4}>
          <FormGroupTile
            type="formcontrol"
            formControlType={"number"}
            stateName={"targetACOS"}
            handleChildFormElement={(key, value) => {
              onChange({ [key]: value });
            }}
            label={`Target ${roas ? "ROAS" : "ACOS"}:`}
            defaultValue={targetACOS}
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroupTile
            type="formcontrol"
            formControlType={"number"}
            stateName={"maxACOS"}
            handleChildFormElement={(key, value) => onChange({ [key]: value })}
            label={roas ? "Min ROAS" : "Max ACOS"}
            defaultValue={maxACOS}
          />
        </Col>
      </Row>
    </>
  );
};

const NTMStatusWidget = ({ onUpdate, adPlan, onChange, products, roas }) => {
  const [showModal, setShowModal] = useState(false);
  const [autoAcceptBids, setAutoAcceptBids] = useState(false);

  let adPlanStatus = "notEnrolled";
  if (adPlan?.nonTrellisEnrolledAd === undefined) {
    adPlanStatus = "hideWidget";
  }
  if (adPlan?.nonTrellisEnrolledAd) {
    adPlanStatus = "enrolled";
  }
  if (adPlan?.nonTrellisEnrolledAdAcceptBids) {
    adPlanStatus = "bidsAccepted";
  }

  const acceptBids = () => {
    onChange({
      nonTrellisEnrolledAdAcceptBids: true,
      nonTrellisEnrolledAd: true,
    });
    const newAdPlan = {
      ...adPlan,
      nonTrellisEnrolledAdAcceptBids: true,
      nonTrellisEnrolledAd: true,
    };
    onUpdate(newAdPlan, products);
  };
  const enrollAdPlan = () => {
    onChange({ nonTrellisEnrolledAd: true });
    const newAdPlan = { ...adPlan, nonTrellisEnrolledAd: true };
    onUpdate(newAdPlan, products);
  };

  if (adPlanStatus === "hideWidget") {
    return <></>;
  }

  return (
    <div className="border rounded-3 p-4 mb-3 text-center">
      {adPlanStatus === "notEnrolled" && (
        <>
          {showModal && (
            <CenteredModal
              show={showModal}
              closeModal={() => setShowModal(false)}
              title={"Import Keywords and Targets"}
              dialogClassName={"modal-50w"}
              style={{
                marginTop: "2rem",
              }}
            >
              <NTMFields onChange={onChange} adPlan={adPlan} roas={roas} />
              <Row>
                <Col xs={12} className="mt-5">
                  <Form.Group className="mb-3 mt-3">
                    <Form.Check
                      type="checkbox"
                      label={
                        <>
                          Enable Ad Management{" "}
                          <ToolTips
                            toolTip={
                              "Selecting this option will push budgets and bids to Amazon"
                            }
                            position="top"
                            id={"adManagementNTMTooltip"}
                          />
                        </>
                      }
                      checked={autoAcceptBids}
                      onChange={(e) => {
                        setAutoAcceptBids(e.target.checked);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="mt-5"
                >
                  <AuthButton
                    title={"Cancel"}
                    onClick={() => setShowModal(false)}
                    style={{ marginRight: "2rem" }}
                    buttonStyle={"adplan_button"}
                  />
                  <AuthButton
                    onClick={() => {
                      if (autoAcceptBids) {
                        acceptBids();
                      } else {
                        enrollAdPlan();
                      }
                      setShowModal(false);
                    }}
                    buttonStyle={"adplan_button"}
                    title={"Confirm"}
                    disabled={
                      adPlan.algorithm ===
                        ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value &&
                      !adPlan.biddingProgram
                    }
                  />
                </Col>
              </Row>
            </CenteredModal>
          )}
          <h5>This is a Non-Trellis Managed Campaign</h5>
          <p className="lead mx-auto w-50 text-center">
            You can import keywords and targets into Trellis by clicking the
            button below. Once they are imported you can enroll this campaign in
            Trellis to have it automatically managed by our AI.
          </p>
          <LogoutButton
            title={"Import Keywords and Targets"}
            onClick={() => {
              setShowModal(true);
            }}
            className="logout_button mt-5 wrap"
            overrideStyles={{ transform: "none" }}
          />
        </>
      )}
      {adPlanStatus === "enrolled" && (
        <>
          <h5>This is a Non Trellis Managed Campaign</h5>
          <p className="lead mx-auto w-50 text-center">
            You have imported keywords and targets for this campaign. To have
            Trellis automatically manage your bids and campaign budget, please
            accept all bids by clicking the Manage Ad button below. Once
            accepted, values in the Bid Preview column will be automatically
            applied.
          </p>
          <br />
          <br />

          <ConfirmationModal
            title={"Manage Ad"}
            modalButtonText={"Manage Ad"}
            modelButtonStyle={{ transform: "none" }}
            confirmButtonText={"Confirm"}
            confirmationMessage={
              <p style={{ fontSize: "1.7rem" }}>
                Are you sure you want to enroll this campaign and automatically
                accept all bids?
              </p>
            }
            buttonStyle="logout_button"
            onConfirm={() => {
              acceptBids();
            }}
          />
        </>
      )}
      {adPlanStatus === "bidsAccepted" && (
        <span className="lead">
          Trellis is automatically managing your bids. You can change your
          settings in the "Advanced Options" section under the Media Plan tab.
        </span>
      )}
    </div>
  );
};

export default withAdPlan(NTMStatusWidget);
