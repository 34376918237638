import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { useSelector } from "react-redux";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";

const AmazonProfilesTable = ({
  tableData,
  columnsToRender,
  isLoading,
  additionalColumns = [],
  titleColWidth = null,
  defaultSorted,
  isSelectable = false,
  selectedProfiles = [],
  setSelectedProfiles = () => {},
  bulkActionOptions = [],
  defaultPageSize = 10,
  showIds = false,
}) => {
  const { marketPlace } = useSelector((state) => state);
  const tableRef = useRef();

  const columnOptions = [
    buildTextColumn("name", "Organization Name"),
    buildTextColumn("marketplaces", "Profile Marketplaces", {
      formatter: (v) => {
        return v.join(", ");
      },
    }),
    buildTextColumn("profile_type", "Profile Type", {
      formatter: (v) => {
        return snakeCaseToSentenceCase(v);
      },
    }),
    ...additionalColumns,
  ];

  if (showIds) {
    columnOptions.unshift(buildTextColumn("id", "Org ID"));
  }

  const columnSpecs = columnsToRender.map((renderColumnKey) =>
    columnOptions.find((option) => option.key === renderColumnKey)
  );

  return (
    <Row className="mx-0 w-100">
      <Col xs={12} className="mx-auto px-0">
        <BulkActionsTable
          marketPlace={marketPlace}
          tableRef={tableRef}
          tableId={"trellisAmazonProfilesTable"}
          titleCol={{
            id: "name",
            isStatic: true,
            Header: "Profile Name",
            accessor: "profile_name",
            style: { width: "450px" },
            width: titleColWidth ? titleColWidth : 450,
            Cell: ({ value, row }) => {
              const { profile_name } = row._original;

              return (
                <>
                  <div className="d-flex align-items-center overflow-ellipsis">
                    {profile_name}
                  </div>
                </>
              );
            },
            filterMethod: (filter, row) => {
              return;
            },
            Filter: ({ filter, onChange }) => null,
          }}
          columnSpecs={columnSpecs}
          data={tableData}
          bulkActionOptions={bulkActionOptions}
          hideFilterToggleIcon={true}
          isSelectable={isSelectable}
          isExpandable={false}
          selected={selectedProfiles}
          setSelected={setSelectedProfiles}
          selectionKey={"id"}
          defaultSorted={defaultSorted || [{ id: "name", desc: false }]}
          defaultPageSize={defaultPageSize}
          getSearchCriteria={(row) => {
            const {
              id,
              name,
              profile_name,
              marketplaces,
              profile_type,
              account_manager,
            } = row;
            return `${id} ${name} ${profile_name} ${marketplaces} ${profile_type} ${account_manager}`;
          }}
          tableRowDescription={"Amazon Profile"}
          minRows={0}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
};

export default AmazonProfilesTable;
