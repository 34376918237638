import "assets/css/progress_bar.css";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";

class ProgressBar extends Component {
  render() {
    var leftCount = parseFloat(this.props.leftCount);
    var rightCount = parseFloat(this.props.rightCount);
    var total = parseFloat(leftCount) + parseFloat(rightCount);
    var innerBarPercentage = Math.round((leftCount / total) * 100);
    var type = this.props.type !== undefined ? this.props.type : null;
    var barTextFontSize = this.props.barTextFontSize
      ? this.props.barTextFontSize
      : "16px";
    return (
      <>
        <div
          className="outer_bar wrap"
          style={{
            height: this.props.height ? this.props.height : "34px",
            borderRadius: 8,
          }}
        >
          {parseInt(rightCount) !== 0 ? (
            <div
              className="inner_bar"
              style={{
                width: innerBarPercentage.toString() + "%",
                borderRadius: 8,
              }}
            />
          ) : parseInt(leftCount) === 0 && parseInt(rightCount) === 0 ? (
            <div
              className="inner_bar"
              style={{ width: "50%", borderRadius: 8 }}
            />
          ) : (
            <div
              className="inner_bar"
              style={{
                width: innerBarPercentage.toString() + "%",
                borderRadius: 8,
              }}
            />
          )}
          {this.props.centerSubtitle !== undefined ? (
            <div
              className="bar_text"
              style={{ borderRadius: 8, fontSize: barTextFontSize }}
            >
              {this.props.centerSubtitle}
            </div>
          ) : null}
        </div>
        {this.props.leftSubtitle && this.props.rightSubtitle && (
          <Row style={{ margin: "0px", marginTop: "10px" }}>
            <Col md={6} className="bar_left_subtitle">
              <span>{this.props.leftSubtitle.toString()}: </span>
              {type === "currency" ? (
                <span>
                  {MARKETPLACE_CURRENCY_DENOMINATION[
                    this.props.marketPlace.marketPlace
                  ] + parseFloat(leftCount.toFixed(2)).toLocaleString()}
                </span>
              ) : (
                <span>{parseFloat(leftCount.toFixed(2)).toLocaleString()}</span>
              )}
            </Col>
            <Col md={6} className="bar_right_subtitle">
              <span>{this.props.rightSubtitle.toString()}: </span>
              {type === "currency" ? (
                <span>
                  {MARKETPLACE_CURRENCY_DENOMINATION[
                    this.props.marketPlace.marketPlace
                  ] + parseFloat(rightCount.toFixed(2)).toLocaleString()}
                </span>
              ) : (
                <span>
                  {parseFloat(rightCount.toFixed(2)).toLocaleString()}
                </span>
              )}
            </Col>
          </Row>
        )}
      </>
    );
  }
}

ProgressBar.propTypes = {
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(withRouter(ProgressBar));
