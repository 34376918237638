import { useState, useEffect, useContext, useMemo } from "react";
import {
  AD_TYPES,
  STATUS,
  TARGET_TYPES,
  WALMART_TOTAL_BUDGET_DEFAULT,
  WALMART_SELLER_MANUAL_CAMPAIGN_MIN_BUDGET,
  WALMART_SELLER_AUTO_CAMPAIGN_MIN_BUDGET,
  WALMART_VENDOR_MANUAL_CAMPAIGN_MIN_BUDGET,
  WALMART_VENDOR_AUTO_CAMPAIGN_MIN_BUDGET,
  SD_CAMPAIGN_TYPE_OPTIONS,
  SB_BID_STRATEGY_OPTIONS,
  BID_STRATEGY_OPTIONS,
  GOALS,
  SB_CAMPAIGN_GOAL_OPTIONS,
  ALGORITHM_OPTIONS,
} from "./constants";
import { captureException } from "@sentry/react";
import { AdPlanContext } from "./AdPlanContext";
import api from "utils/api";
import { useFetch } from "hooks/api";
import { formatCurrency } from "utils/formatNumber";

const EMPTY_ARRAY = [];

const useAdPlanState = (
  initialAdPlan = {},
  marketPlace,
  categoryId = null,
  adId = null,
  roas,
  organization_type,
  shouldLog = false
) => {
  initialAdPlan = {
    name: "",
    canHaveChildren: false,
    hasParent: false,
    componentTemplate: undefined,
    children: [],
    categoryId,
    targetACOS: roas ? 5 : 20,
    maxACOS: roas ? 2.5 : 40,
    goal: undefined,
    dailyBudget: marketPlace === "IN" ? 1500 : 20,
    totalBudget: WALMART_TOTAL_BUDGET_DEFAULT,
    filledTags: [],
    filledNegativeKeywords: [],
    filledAsins: [],
    filledNegativeAsins: [],
    filledTargetCategories: [],
    filledProductKeys: [],
    filledProductBrands: [],
    filledCompetitorBrands: [],
    filteredAttributes: {
      color: [],
      size: [],
      collection: [],
      flavor: [],
      material: [],
      other: [],
    },
    product: [],
    landingPageProduct: [],
    storePageProduct: [],
    creativeProduct: [],
    adType: null,
    type: "single",
    advStatus: STATUS.ACTIVE.value,
    brand: "",
    creativeBrandName: "",
    store: "",
    page: "",
    logo: "",
    channel: localStorage.channel === "amazon" ? "AMZ" : localStorage.channel,
    allocation: 30,
    headline: "",
    newLandingPage: false,
    newProductCollection: false,
    discoverKeywords: true,
    scheduledActions: [],
    googleAdditionalFields: {
      enableLocal: false,
    },
    inventoryFilterBrand: "",
    targetType:
      localStorage.channel === "google" ? TARGET_TYPES.FUNNEL.value : null,
    minBid: null,
    maxBid: null,
    asinHarvestingEnabled: false,
    keywordHarvestingEnabled: false,
    keywordDiscoveryEnabled: false,
    autoCampaignEnabled: false,
    topOfSearchBidMultiplier: 50,
    productPageMultiplier: 0,
    restOfSearchMultiplier: 0,
    bidStrategy: 0,
    manualCampaignBudget: 70,
    autoCampaignBudget: 30,
    renderAdvancedOptions: undefined,
    notifications: {
      maxAcos: 30,
      maxSpend: 10,
    },
    policyViolations: [],
    // Sponsored Display V2 Fields
    sdBidType: null,
    sdCampaignType: null,
    lookbackPeriod: [],
    viewsSimilar: false,
    viewsExact: false,
    purchasesSimilar: false,
    purchasesExact: false,
    sdDynamicSimilar: false,
    salesVolumeControl: 2,
    // New SB Multipliers
    homePlacementBidMultiplier: 0,
    pagePlacementBidMultiplier: 0,
    otherPlacementBidMultiplier: 0,
    shopperSegmentBidMultiplier: 0,
    amazonSBBidOptimization: false,
    sbCampaignGoal: SB_CAMPAIGN_GOAL_OPTIONS.PAGE_VISIT.value,
    // Negation Settings
    autoNegationEnabled: false,
    maxAcosNegationThreshold: null,
    cumulativeNegationThreshold: null,
    // Walmart Options - TODO: Consider breaking the growing list of ad control fields into channel-specific objects, as well as a generic object of shared ad control params
    // Placement Bid Multipliers
    buyBoxPlacementBidMultiplier: 0,
    searchInGridPlacementBidMultiplier: 0,
    stockUpPlacementBidMultiplier: 0,
    homePagePlacementBidMultiplier: 0,
    // Platform Bid Multipliers
    desktopPlatformBidMultiplier: 0,
    mobilePlatformBidMultiplier: 0,
    appPlatformBidMultiplier: 0,
    // Placement Toggles
    searchCarouselCampaignPlacement: true,
    itemCarouselCampaignPlacement: true,
    buyBoxCarouselCampaignPlacement: true,
    walmartSuggestedKeywordsEnabled: false,
    // Walmart SB Creative Fields
    walmartBrandName: "",
    walmartHeadline: "",
    walmartClickURL: "",
    walmartImage: "",
    // Walmart options end
    campaignNames: {},
    campaignIds: {},
    validatingCampaignNames: [],
    duplicateCampaignNames: [],
    manualHarvestingEnabled: false,
    harvestingClicks: 0,
    harvestingConversions: 3,
    harvestingDays: 10,
    harvestingViewImpressions: 0,
    biddingProgram: null,
    tags: [],
    ...initialAdPlan,
  };
  const [adPlan, setAdPlan] = useState(initialAdPlan);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  //const [products, setProducts] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [savedVideos, setSavedVideos] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [logoOptions, setLogoOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const [agreeToArchive, setAgreeToArchive] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);

  const { setSavedChildPlans } = useContext(AdPlanContext);

  let productRequestParams = {
    parent: true,
    marketplace: marketPlace,
  };

  if (
    ![
      AD_TYPES.SPONSORED_BRAND_WALMART.value,
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_V2.value,
    ].includes(adPlan.adType)
  ) {
    productRequestParams["category_id"] = adPlan?.category ?? categoryId;
  }

  const { data: productData } = useFetch(
    [
      "media-plan-products",
      marketPlace,
      adPlan?.category?.toString() ?? categoryId?.toString(),
      adPlan?.adType,
    ],
    "/api/gvads/productid/",
    productRequestParams,
    {
      enabled: !!(adId ? adPlan?.category : true),
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000,
      keepPreviousData: adId ? true : false,
    }
  );

  const products = useMemo(() => {
    if (productData?.data) {
      const products = [];
      for (const product of productData.data) {
        const { asin, sku, id, product_title } = product;

        let rendered_sku = `${sku}`;
        if (asin) {
          rendered_sku = `${rendered_sku} (${asin})`;
        }
        products.push({
          isDisabled: product.is_parent,
          value: id,
          sku: rendered_sku,
          asin,
          product_title,
        });
        if (product.is_parent) {
          for (const child of product.children) {
            const { asin, sku, id, product_title } = child;
            products.push({
              value: id,
              sku: `${sku} (${asin})`,
              asin,
              product_title,
            });
          }
        }
      }
      return products;
    }
    return EMPTY_ARRAY;
  }, [productData]);

  const fetchAdPlan = async (adplanId) => {
    const adPlanData = await api.get("/api/gvads/advproduct/", {
      advertisement: adplanId,
    });

    setApiStatus(adPlanData.data.state);
    const categoryId = adPlanData.data.category;
    const brandOptions = await fetchBrands();

    const asinsToIds = (asins) => {
      return asins
        ?.map(
          (asin) =>
            products?.find(
              (p) =>
                p.asin === asin &&
                adPlanData?.data?.products?.map((p) => p.id)?.includes(p.value)
            )?.value
        )
        ?.filter((p) => p);
    };
    const brand = brandOptions?.find(
      (b) => b.value === adPlanData.data.brand_entity
    );

    const useNewCreativeFormat = [
      "_SB_PC_",
      "_SB_SS_",
      "_SB_BV_",
      "_SB_V_",
    ].includes(adPlanData.data.creative?.brand_ad_type);

    if (adPlanData.data.creative?.brand_ad_type === "_SB_PC_") {
      adPlanData.data.ad_type = AD_TYPES.SPONSORED_BRAND.value;
    }

    if (
      ["_SB_BV_", "_SB_V_"].includes(adPlanData.data.creative?.brand_ad_type)
    ) {
      adPlanData.data.ad_type = AD_TYPES.SPONSORED_BRAND_VIDEO.value;
    }

    const formatCategoryTargetData = (targetCategories) => {
      const formattedTargetCategoryData = targetCategories?.map(
        (targetDetails) => {
          if (typeof targetDetails === "number") {
            targetDetails = { id: targetDetails, savedToServer: true };

            return targetDetails;
          }

          if (targetDetails.asin_price_between) {
            targetDetails = {
              asin_price_greater_than:
                targetDetails.asin_price_between.split("-")?.[0],
              asin_price_less_than:
                targetDetails.asin_price_between?.split("-")?.[1],
              ...targetDetails,
            };
            delete targetDetails.asin_price_between;
          }

          if (targetDetails.asin_review_rating_between) {
            targetDetails = {
              asin_review_rating_greater_than:
                targetDetails.asin_review_rating_between?.split("-")?.[0],
              asin_review_rating_less_than:
                targetDetails.asin_review_rating_between?.split("-")?.[1],
              ...targetDetails,
            };
            delete targetDetails.asin_review_between;
          }

          if (targetDetails.brand === "string") {
            targetDetails.brand = {
              label: targetDetails.brand_name,
              value: targetDetails.brand,
            };
          }

          targetDetails.savedToServer = true;

          return targetDetails;
        }
      );

      return formattedTargetCategoryData;
    };

    const adPlan = {
      categoryId,
      name: adPlanData.data.name,
      product: adPlanData.data.products?.map((p) => p.id) ?? [],
      adType: adPlanData.data.ad_type,
      goal: adPlanData.data.ad_goal,
      targetACOS: roas
        ? +((1 / adPlanData.data.target_acos) * 100).toFixed(2)
        : adPlanData.data.target_acos,
      maxACOS: roas
        ? +((1 / adPlanData.data.max_acos) * 100).toFixed(2)
        : adPlanData.data.max_acos,
      dailyBudget: adPlanData.data.daily_budget,
      totalBudget: adPlanData.data.total_budget,
      advStatus: adPlanData.data.state,
      // TODO: Below is confusing, we also have moderation status being stored, but the UI for SB checks
      // for ad status and state combo, but in the line above and throughout UI, what is ad_state in API is referred to as ad_status
      advModerationStatus: adPlanData.data.ad_status,
      creativeModerationStatus:
        typeof adPlanData.data?.moderation_status === "string" &&
        adPlanData.data?.ad_type === AD_TYPES.SPONSORED_DISPLAY_V2.value
          ? adPlanData.data?.moderation_status
          : null,
      channel: adPlanData.data.channel,
      filledTags: adPlanData.data.themed_kw_list ?? [],
      filledAsins: adPlanData.data.themed_asin_list ?? [],
      filledNegativeAsins: adPlanData.data.negative_asin_list ?? [],
      filledNegativeKeywords: adPlanData.data.negative_kw_list,
      filledTargetCategories: formatCategoryTargetData(
        adPlanData.data.user_category_id_list
      ),
      originalFilledTargetCategories:
        adPlanData.data.user_category_id_list ?? [],
      allocation: adPlanData.data.budget_allocation,
      canHaveChildren: adPlanData.data.is_composite,
      headline: useNewCreativeFormat
        ? adPlanData.data.creative?.properties?.[0]?.creative?.headline
        : adPlanData.data.creative?.headline ??
          adPlanData.data.creative?.properties?.headline ??
          "",
      scheduledActions: adPlanData.data?.scheduled_actions?.filter(
        (action) => action.status !== "cancelled"
      ),
      type: adPlanData.data.type,
      children:
        adPlanData.data.children?.map((c) => ({
          id: c.id,
          name: c.name,
          allocation: c.budget_allocation,
          advStatus: c.ad_status,
          targetACOS: c.target_acos,
          maxACOS: c.max_acos,
          adType: c.ad_type,
        })) ?? [],
      id: adPlanData.data.id,
      parent: adPlanData.data.parent,
      hasParent: !!adPlanData.data.parent,
      video: useNewCreativeFormat
        ? adPlanData.data.creative?.properties?.[0]?.creative.videoAssetIds?.[0]
        : adPlanData.data.video_id ??
          adPlanData.data.creative?.videoAssetIds?.[0],
      brand,
      creativeBrandName: useNewCreativeFormat
        ? adPlanData.data.creative?.properties?.[0]?.creative?.brandName
        : brand?.label,
      logo: useNewCreativeFormat
        ? adPlanData.data.creative?.properties?.[0]?.creative?.brandLogoAssetID
        : adPlanData.data.creative?.brandLogoAssetID ??
          adPlanData.data.creative?.properties?.brandLogo?.assetId ??
          null,
      creativeProduct: useNewCreativeFormat
        ? asinsToIds(adPlanData.data.creative?.properties?.[0]?.creative?.asins)
        : asinsToIds(adPlanData.data.creative?.asins ?? []),
      landingPageProduct: useNewCreativeFormat
        ? asinsToIds(
            adPlanData.data.creative?.properties?.[0]?.landingPage?.asins
          )
        : asinsToIds(adPlanData.data.landing_page?.asins ?? []),
      storePageProduct: useNewCreativeFormat
        ? asinsToIds(adPlanData.data.creative?.properties?.[0]?.creative?.asins)
        : asinsToIds(adPlanData.data.creative?.asins ?? []),
      newLandingPage: useNewCreativeFormat
        ? !!adPlanData.data.creative?.properties?.[0]?.landingPage?.asins
            ?.length
        : !!adPlanData.data.landing_page?.asins?.length,
      storeLandingPage: useNewCreativeFormat
        ? !!adPlanData.data.creative?.properties?.[0]?.landingPage?.url
        : !!adPlanData.data.landing_page?.url,
      newProductCollection: false,
      nonTrellisEnrolledAd: adPlanData.data.non_trellis_enrolled_ad,
      nonTrellisEnrolledAdAcceptBids:
        adPlanData.data.non_trellis_enrolled_ad_accept_bids,
      nonTrellisEnrolledAdAcceptBidsStartDate:
        adPlanData.data.non_trellis_enrolled_ad_accept_bids_start_date,
      filledProductKeys: [],
      filledProductBrands: [],
      filledCompetitorBrands: [],
      googleAdditionalFields: {
        enableLocal: adPlanData.data.google_fields?.enableLocal,
      },
      inventoryFilterBrand:
        adPlanData.data.google_fields?.inventoryFilter?.brand,
      filteredAttributes: {
        color: [],
        size: [],
        collection: [],
        flavor: [],
        material: [],
        other: [],
      },
      targetType: adPlanData.data?.targeting_type,
      minBid: adPlanData.data?.ad_control_fields?.min_bid ?? null,
      maxBid: adPlanData.data?.ad_control_fields?.max_bid ?? null,
      asinHarvestingEnabled:
        adPlanData.data?.ad_control_fields?.asin_harvesting ?? false,
      crSelectorEnabled:
        adPlanData.data?.ad_control_fields?.cr_selector ?? false,
      keywordHarvestingEnabled:
        adPlanData.data?.ad_control_fields?.keyword_harvesting ?? false,
      autoCampaignEnabled:
        adPlanData.data?.ad_control_fields?.with_auto ?? false,
      autoCampaignEnabledFromServer:
        adPlanData.data?.ad_control_fields?.with_auto ?? false,
      keywordDiscoveryEnabled: adPlanData.data?.discover_keywords ?? false,
      trellisTrakEnabled:
        adPlanData.data?.ad_control_fields?.historical_keywords ?? false,
      algorithm: adPlanData.data?.ad_control_fields?.bid_algo,
      manualCampaignBudget:
        adPlanData.data?.ad_control_fields?.discovery_budget_alloc * 100 ?? 0,
      autoCampaignBudget:
        adPlanData.data?.ad_control_fields?.auto_budget_alloc * 100 ?? 0,
      bidStrategy:
        adPlanData.data?.ad_control_fields?.bid_strategy ?? "legacyForSales",
      autoNegationEnabled:
        adPlanData.data?.ad_control_fields?.auto_negation ?? false,
      maxAcosNegationThreshold:
        adPlanData.data?.ad_control_fields?.target_acos_negation_threshold *
          100 ?? null,
      cumulativeNegationThreshold:
        adPlanData.data?.ad_control_fields?.cumm_threshold * 100 ?? null,
      notifications: {
        maxAcos: adPlanData.data?.notification_settings?.max_acos ?? 30,
        maxSpend: adPlanData.data?.notification_settings?.max_spend ?? 10,
        productAlertEnabled:
          adPlanData.data?.notification_settings?.product_alert ?? false,
      },
      salesVolumeControl:
        adPlanData.data?.ad_control_fields?.sales_volume_control_slider,
      broadMatchEnabled:
        adPlanData.data?.ad_control_fields?.match_types?.includes("broad"),
      initialBroadMatchEnabled:
        adPlanData.data?.ad_control_fields?.match_types?.includes("broad"),
      phraseMatchEnabled:
        adPlanData.data?.ad_control_fields?.match_types?.includes("phrase"),
      initialPhraseMatchEnabled:
        adPlanData.data?.ad_control_fields?.match_types?.includes("phrase"),
      exactMatchEnabled:
        adPlanData.data?.ad_control_fields?.match_types?.includes("exact"),
      initialExactMatchEnabled:
        adPlanData.data?.ad_control_fields?.match_types?.includes("exact"),
      negativePhraseMatchEnabled:
        adPlanData.data?.ad_control_fields?.negative_match_types?.includes(
          "phrase"
        ),
      initialNegativePhraseMatchEnabled:
        adPlanData.data?.ad_control_fields?.negative_match_types?.includes(
          "phrase"
        ),
      negativeExactMatchEnabled:
        adPlanData.data?.ad_control_fields?.negative_match_types?.includes(
          "exact"
        ),
      initialNegativeExactMatchEnabled:
        adPlanData.data?.ad_control_fields?.negative_match_types?.includes(
          "exact"
        ),
      broadMatchRegular:
        adPlanData.data?.ad_control_fields?.broad_match_regular,
      initialBroadMatchRegular:
        adPlanData.data?.ad_control_fields?.broad_match_regular,
      broadMatchModifier:
        adPlanData.data?.ad_control_fields?.broad_match_modifier,
      initialBroadMatchModifier:
        adPlanData.data?.ad_control_fields?.broad_match_modifier,
      weekPartingMethod:
        adPlanData.data?.ad_control_fields?.week_parting_method,
      weekPartingMultipliers:
        adPlanData.data?.ad_control_fields?.week_parting_multipliers,
      dayPartingMethod: adPlanData.data?.ad_control_fields?.day_parting_method,
      dayPartingMultipliers:
        adPlanData.data?.ad_control_fields?.day_parting_multipliers,
      policyViolations: adPlanData.data?.policy_violations ?? [],
      // Process stored values for existing plans => will need to test when get view updated
      sdCampaignType: adPlanData.data?.ad_control_fields?.campaign_type,
      lookbackPeriod:
        adPlanData.data?.ad_control_fields?.campaign_type ===
        SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value
          ? adPlanData.data?.ad_control_fields?.lookback
          : [],
      sdBidType: adPlanData.data?.ad_control_fields?.cost_type,
      viewsSimilar:
        adPlanData.data?.ad_control_fields?.campaign_type ===
          SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value &&
        adPlanData.data?.ad_control_fields?.targets?.includes("views_similar"),
      viewsExact:
        adPlanData.data?.ad_control_fields?.campaign_type ===
          SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value &&
        adPlanData.data?.ad_control_fields?.targets?.includes("views_exact"),
      purchasesSimilar:
        adPlanData.data?.ad_control_fields?.campaign_type ===
          SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value &&
        adPlanData.data?.ad_control_fields?.targets?.includes(
          "purchases_similar"
        ),
      purchasesExact:
        adPlanData.data?.ad_control_fields?.campaign_type ===
          SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value &&
        adPlanData.data?.ad_control_fields?.targets?.includes(
          "purchases_exact"
        ),
      sdDynamicSimilar:
        adPlanData.data?.ad_control_fields?.campaign_type ===
          SD_CAMPAIGN_TYPE_OPTIONS.CONTEXTUAL.value &&
        adPlanData.data?.ad_control_fields?.targets?.includes(
          "contextual_dynamic"
        ),
      sdAmazonSuggestedCategories:
        adPlanData.data?.amz_recommended_catergory ||
        adPlanData.data?.amazon_category_id_list?.length > 0
          ? true
          : false,
      creativeAsset: useNewCreativeFormat
        ? adPlanData.data.creative?.properties?.[0]?.creative
            ?.customImageAssetId
        : adPlanData.data?.creative?.properties?.rectCustomImage?.assetId ||
          adPlanData.data?.creative?.properties?.squareCustomImage?.assetId ||
          adPlanData.data?.creative?.customImageAssetId ||
          adPlanData.data?.creative?.properties?.video?.assetId,
      sdSquareImageCroppingCoordinates:
        adPlanData.data?.creative?.properties?.squareCustomImage
          ?.croppingCoordinates ?? {},
      sdRectangleImageCroppingCoordinates:
        adPlanData.data?.creative?.properties?.rectCustomImage
          ?.croppingCoordinates ?? {},
      sdRectangleCroppedArea:
        adPlanData.data?.creative_display_metadata?.rectCustomImage
          ?.croppedArea ?? {},
      sdSquareCroppedArea:
        adPlanData.data?.creative_display_metadata?.squareCustomImage
          ?.croppedArea ?? {},

      // Walmart sales control
      buyBoxPlacementBidMultiplier:
        adPlanData.data?.ad_control_fields?.placement_bid_multipliers?.[
          "Buy-Box"
        ] ?? 0,
      searchInGridPlacementBidMultiplier:
        adPlanData.data?.ad_control_fields?.placement_bid_multipliers?.[
          "Search Ingrid"
        ] ?? 0,
      stockUpPlacementBidMultiplier:
        adPlanData.data?.ad_control_fields?.placement_bid_multipliers?.[
          "Stock Up"
        ] ?? 0,
      homePagePlacementBidMultiplier:
        adPlanData.data?.ad_control_fields?.placement_bid_multipliers?.[
          "Home Page"
        ] ?? 0,
      // Platform Bid Multipliers
      desktopPlatformBidMultiplier:
        adPlanData.data?.ad_control_fields?.device_bid_multipliers?.Desktop ??
        0,
      mobilePlatformBidMultiplier:
        adPlanData.data?.ad_control_fields?.device_bid_multipliers?.Mobile ?? 0,
      appPlatformBidMultiplier:
        adPlanData.data?.ad_control_fields?.device_bid_multipliers?.App ?? 0,
      // Placement Toggles
      searchCarouselCampaignPlacement:
        adPlanData.data?.ad_control_fields?.campaign_placements?.[
          "Search Carousel"
        ],
      itemCarouselCampaignPlacement:
        adPlanData.data?.ad_control_fields?.campaign_placements?.[
          "Item Carousel"
        ],
      buyBoxCarouselCampaignPlacement:
        adPlanData.data?.ad_control_fields?.campaign_placements?.[
          "Item Buybox"
        ],
      walmartSuggestedKeywordsEnabled:
        adPlanData.data?.ad_control_fields?.suggested_keywords,
      walmartHeadline: adPlanData.data?.creative?.headline,
      walmartBrandName: adPlanData.data?.creative?.brand_name,
      walmartAssetId: adPlanData.data?.creative?.asset_id,
      walmartCaptionAssetId: adPlanData.data?.creative?.caption_asset_id,
      walmartClickURL: adPlanData.data?.creative?.click_url,
      amazonSBBidOptimization: adPlanData.data?.bid_optimization ?? false,
      shopperSegmentBidMultiplier:
        adPlanData.data?.ad_control_fields
          ?.bid_adjustments_by_shopper_segment?.[0]?.percentage ?? 0,

      campaignNames: {},
      campaignIds: {},
      // New manual harvesting settings
      manualHarvestingEnabled:
        adPlanData.data?.ad_control_fields?.harvesting_setting_override,
      harvestingClicks: adPlanData.data?.ad_control_fields?.harvesting_clicks,
      harvestingConversions:
        adPlanData.data?.ad_control_fields?.harvesting_conversions,
      harvestingDays: adPlanData.data?.ad_control_fields?.harvesting_days,
      harvestingViewImpressions:
        adPlanData.data?.ad_control_fields?.harvesting_view_impressions,
      sbCampaignGoal:
        adPlanData.data?.ad_control_fields?.campaign_goal ??
        SB_CAMPAIGN_GOAL_OPTIONS.PAGE_VISIT.value,
      biddingProgram: adPlanData.data?.ad_control_fields?.program,
      tags: adPlanData.data?.tags,
    };

    const {
      manual_name,
      auto_name,
      contextual_name,
      audience_name,
      walmart_manual_campaign_name,
      walmart_auto_campaign_name,
      manual_campaign_id,
      auto_campaign_id,
      contextual_campaign_id,
      audience_campaign_id,
      walmart_manual_campaign_id,
      walmart_auto_campaign_id,
    } = adPlanData.data?.ad_control_fields;

    if (manual_name) {
      adPlan.campaignNames.manual_name = manual_name;
      adPlan.campaignIds.manual_id = manual_campaign_id;
    }
    if (auto_name) {
      adPlan.campaignNames.auto_name = auto_name;
      adPlan.campaignIds.auto_id = auto_campaign_id;
    }
    if (contextual_name) {
      adPlan.campaignNames.contextual_name = contextual_name;
      adPlan.campaignIds.contextual_id = contextual_campaign_id;
    }
    if (audience_name) {
      adPlan.campaignNames.audience_name = audience_name;
      adPlan.campaignIds.audience_id = audience_campaign_id;
    }

    if (walmart_manual_campaign_name) {
      adPlan.campaignNames.walmart_manual_campaign_name =
        walmart_manual_campaign_name;
      adPlan.campaignIds.walmart_manual_campaign_id =
        walmart_manual_campaign_id;
    }

    if (walmart_auto_campaign_name) {
      adPlan.campaignNames.walmart_auto_campaign_name =
        walmart_auto_campaign_name;
      adPlan.campaignIds.walmart_auto_campaign_id = walmart_auto_campaign_id;
    }

    if (
      [
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_BRAND_V2.value,
        AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      ].includes(adPlanData.data.ad_type)
    ) {
      adPlan.homePlacementBidMultiplier =
        adPlanData.data?.ad_control_fields?.bid_adjustments_by_placement?.[0]
          ?.percentage ?? 0;
      adPlan.pagePlacementBidMultiplier =
        adPlanData.data?.ad_control_fields?.bid_adjustments_by_placement?.[1]
          ?.percentage ?? 0;
      adPlan.otherPlacementBidMultiplier =
        adPlanData.data?.ad_control_fields?.bid_adjustments_by_placement?.[2]
          ?.percentage ?? 0;
    }

    if ([AD_TYPES.SPONSORED_PRODUCT.value].includes(adPlanData.data.ad_type)) {
      adPlan.topOfSearchBidMultiplier =
        adPlanData.data.ad_control_fields.bid_adjustments_by_placement?.find(
          (adjustment) =>
            ["PLACEMENT_TOP"].includes(adjustment.placement) ||
            ["placementTop"].includes(adjustment.predicate)
        )?.percentage ?? 0;
      adPlan.productPageMultiplier =
        adPlanData.data.ad_control_fields.bid_adjustments_by_placement?.find(
          (adjustment) =>
            ["PLACEMENT_PRODUCT_PAGE"].includes(adjustment.placement) ||
            ["placementProductPage"].includes(adjustment.predicate)
        )?.percentage ?? 0;
      adPlan.restOfSearchMultiplier =
        adPlanData.data.ad_control_fields.bid_adjustments_by_placement?.find(
          (adjustment) =>
            ["PLACEMENT_REST_OF_SEARCH"].includes(adjustment.placement) ||
            ["placementRestOfSearch"].includes(adjustment.predicate)
        )?.percentage ?? 0;
    }

    // SB New target options
    if (adPlanData.data?.attributes !== null) {
      adPlan.filledProductKeys = adPlanData.data?.attributes?.product_types;
      adPlan.filledProductBrands = adPlanData.data?.attributes?.brands;
      adPlan.filledCompetitorBrands = adPlanData.data?.attributes?.competitors;
      adPlan.filteredAttributes = adPlanData.data?.attributes?.attributes;
    }

    if (adPlanData.data?.ad_control_fields?.campaign_type === "_SB_PC_") {
      adPlan.adType = AD_TYPES.SPONSORED_BRAND.value;
      adPlan.sbV2 = true;
      adPlan.newProductCollection = true;
    } else if (
      adPlanData.data?.ad_control_fields?.campaign_type === "_SB_SS_"
    ) {
      adPlan.adType = AD_TYPES.SPONSORED_BRAND.value;
      adPlan.sbV2 = true;
      adPlan.storeSpotlight = true;
      adPlan.storeSpotlightSubpages =
        adPlanData.data.creative?.properties?.[0]?.creative?.subpages;
    } else if (
      ["_SB_BV_", "_SB_V_"].includes(
        adPlanData.data?.ad_control_fields?.campaign_type
      )
    ) {
      adPlan.adType = AD_TYPES.SPONSORED_BRAND_VIDEO.value;
      adPlan.sbV2 = true;
    }

    if (adPlan.children?.length > 0) {
      let processedChildPlans =
        adPlanData.data.children?.map((c) => ({
          id: c.id,
          name: c.name,
          allocation: c.budget_allocation,
          advStatus: c.ad_status,
          targetACOS: c.target_acos,
          maxACOS: c.max_acos,
          adType: c.ad_type,
        })) ?? [];
      setSavedChildPlans(processedChildPlans);
    }

    const { storeOptions, storesData } = await fetchStoreData(
      brand?.value ?? "",
      categoryId
    );
    const store =
      storeOptions?.find((s) => s.label === adPlanData.data.store_name) || {};
    adPlan.store = store;
    if (store) {
      const pageOptions = fetchStorePagesData(store.label, storesData);
      adPlan.page =
        pageOptions.find(
          (p) => p.value === adPlanData.data.landing_page?.url
        ) ??
        pageOptions.find(
          (p) =>
            p.value ===
            adPlanData.data.creative?.properties?.[0]?.landingPage?.url
        ) ??
        {};
    }

    setAdPlan(adPlan);
  };

  const fetchBrands = async () => {
    try {
      const res = await api.get("/brands/", {
        marketplace: marketPlace,
      });
      if (res.data) {
        const brandOptions = res.data.map((b) => ({
          value: b.brand_entity_id,
          label: b.brand_registry_name,
        }));
        setBrandOptions(brandOptions);
        return brandOptions;
      }
      return [];
    } catch (e) {
      captureException(e);
    }
  };

  const fetchSavedVideos = async () => {
    try {
      const res = await api.get("/creatives/media/video", {
        all: true,
        market_place: marketPlace,
      });
      if (res.data) {
        setSavedVideos(
          res.data.map((v) => ({
            value: v["id"],
            label: v["name"] ? v["name"] : v["project_name"],
          }))
        );
      }
    } catch (e) {
      captureException(e);
    }
  };

  const fetchStoreData = async (brandEntityId, categoryId) => {
    try {
      if (!brandEntityId) {
        return { storeOptions: [], storesData: [] };
      }
      const res = await api.get("/stores/", {
        entityid: brandEntityId,
        categoryid: categoryId,
      });
      if (res.data) {
        const { brand_assets: brandAssetsData, store_pages: storesData } =
          res.data;
        setStoresData(storesData);
        const storeOptions = storesData.map((store, i) => ({
          value: i,
          label: store.store_name,
        }));
        setStoreOptions(storeOptions);
        setLogoOptions(
          brandAssetsData.map((asset) => ({
            value: asset.asset_id,
            label: asset.name,
            url: asset.url,
          }))
        );

        if (adPlan.store?.label) {
          fetchStorePagesData(adPlan.store?.label, storesData);
        }
        return { storeOptions, storesData };
      }
      return { storeOptions: [], storesData: [] };
    } catch (e) {
      captureException(e);
    }
  };

  const fetchStorePagesData = (storeName, storesData) => {
    const pages =
      storesData.find((s) => s.store_name === storeName)?.store_pages ?? [];
    const pageOptions = pages.map((page) => ({
      value: page.url,
      label: page.name,
      products: page.products.map((p) => p.id),
    }));
    setPageOptions(pageOptions);
    if (adPlan.storeSpotlight && !adId) {
      let homePage = pageOptions.find((page) => page.label === "Home");
      onChange({ page: { value: homePage?.value, label: homePage?.label } });
    }

    return pageOptions;
  };

  useFetch(
    ["lifestyleAssetDetails", adPlan.creativeAsset, adPlan.id],
    "/assets/",
    {
      asset_id: adPlan.creativeAsset,
    },
    {
      onSuccess: (res) => {
        if (res.type === "VIDEO") {
          onChange({ videoDetails: res });
        } else {
          onChange({ lifestyleImageDetails: res });
        }
      },
      select: (res) => res.data?.results?.[0],
      default: [],
      enabled:
        !!adPlan.creativeAsset &&
        !!adPlan.id &&
        (adPlan.selectedType === "lifestyleImageDetails" ||
          !adPlan.selectedType),
    }
  );

  useFetch(
    ["logoAssetDetails", adPlan.logo, adPlan.id],
    "/assets/",
    {
      asset_id: adPlan.logo,
    },
    {
      onSuccess: (res) => {
        onChange({ logoDetails: res });
      },
      select: (res) => res.data?.results?.[0],
      default: [],
      enabled: !!adPlan.logo && !!adPlan.id,
    }
  );

  const onChange = (partialAdPlan) => {
    setAdPlan({ ...adPlan, ...partialAdPlan });
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan, agreeToArchive]);

  useEffect(() => {
    if (
      adPlan.adType !== AD_TYPES.SPONSORED_DISPLAY_V2.value &&
      adPlan.targetType === "N/A"
    ) {
      onChange({ targetType: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.adType]);

  useEffect(() => {
    if (adPlan.adType === AD_TYPES.GOOGLE_STORE_AD.value) {
      setAdPlan({ ...adPlan, product: products.map((p) => p.value) });
    }
    if (adPlan.adType !== AD_TYPES.CUSTOM.value && !adPlan.hasParent) {
      setSavedChildPlans([]);
      if (!adPlan.canHaveChildren) {
        onChange({ children: [] });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.adType, products]);

  useEffect(() => {
    (async () => {
      if (products?.length) {
        setLoading(true);
        await fetchBrands();
        await fetchSavedVideos();
        if (categoryId) {
          await fetchBrands(categoryId);
        }
        if (adId) {
          await fetchAdPlan(adId);
        }
        setLoading(false);
      } else {
        if (adId) {
          await fetchAdPlan(adId);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adId, products, categoryId]);

  useEffect(() => {
    // Clear products when category changes - fix issue where switching categories after product selection allows ads with products from multiple categories
    if (!adPlan.id && !adPlan.hasParent) {
      setAdPlan({
        ...adPlan,
        product: [],
        landingPageProduct: [],
        creativeProduct: [],
        storePageProduct: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.categoryId, adPlan.adType, adPlan?.page?.value]);

  useEffect(() => {
    if (
      [
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_BRAND_V2.value,
        AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      ].includes(adPlan.adType)
    ) {
      fetchStoreData(adPlan.brand?.value ?? "", "all");
      return;
    }
    if (adPlan.categoryId) {
      fetchStoreData(adPlan.brand?.value ?? "", adPlan.categoryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.brand, adPlan.categoryId]);

  useEffect(() => {
    fetchStorePagesData(adPlan.store?.label, storesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.store]);

  useEffect(() => {
    if (
      adPlan.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO.value ||
      adPlan.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
    ) {
      onChange({ targetType: TARGET_TYPES.AUTOMATIC.value });
    }

    if (adPlan.adType === AD_TYPES.SPONSORED_PRODUCT && adPlan.hasParent) {
      onChange({ targetType: TARGET_TYPES.MANUAL.value });
    }

    // Always set SB and SBV plans to have keyword discovery enabled and auto campaign disabled
    if (
      adPlan.adType === AD_TYPES.SPONSORED_BRAND.value ||
      adPlan.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
    ) {
      onChange({ keywordHarvestingEnabled: true, autoCampaignEnabled: false });
    } else {
      if (!adPlan.id && (adPlan.newLandingPage || adPlan.storeSpotlight)) {
        onChange({ newLandingPage: false, storeSpotlight: false });
      }
    }

    if (
      (adPlan.adType === AD_TYPES.SPONSORED_BRAND.value &&
        (adPlan.newProductCollection || adPlan.storeSpotlight)) ||
      (adPlan.adType === AD_TYPES.SPONSORED_BRAND.value && adPlan.sbV2) ||
      (adPlan.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value && !adId)
    ) {
      if (
        ![
          SB_BID_STRATEGY_OPTIONS.MAXIMIZE_IMMEDIATE_SALES.value,
          SB_BID_STRATEGY_OPTIONS.MAXIMIZE_NEW_TO_BRAND_CUSTOMERS.value,
        ].includes(adPlan.bidStrategy) &&
        !adId
      ) {
        onChange({
          bidStrategy: SB_BID_STRATEGY_OPTIONS.MAXIMIZE_IMMEDIATE_SALES.value,
        });
      }
      if (
        adPlan.storeSpotlight &&
        (!adPlan.storeSpotlightSubpages ||
          adPlan.storeSpotlightSubpages?.length < 3)
      ) {
        onChange({ storeSpotlightSubpages: [{}, {}, {}] });
      }

      if (!adPlan.storeSpotlight && adPlan.storeSpotlightSubpages) {
        onChange({ storeSpotlightSubpages: [] });
      }
    } else {
      // If moving from SB to any other type retroactively, restore defaults
      if (
        ![
          BID_STRATEGY_OPTIONS.DOWN_ONLY.value,
          BID_STRATEGY_OPTIONS.UP_DOWN.value,
          BID_STRATEGY_OPTIONS.FIXED.value,
        ].includes(adPlan.bidStrategy) &&
        !adId
      ) {
        if (
          [
            AD_TYPES.SPONSORED_BRAND.value,
            AD_TYPES.SPONSORED_BRAND_VIDEO.value,
          ].includes(adPlan.adType) &&
          !adPlan.id
        ) {
          return;
        }

        onChange({
          bidStrategy: BID_STRATEGY_OPTIONS.DOWN_ONLY.value,
          storeSpotlight: false,
          storeSpotlightSubpages: [],
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adPlan.adType,
    adPlan.newProductCollection,
    adPlan.newLandingPage,
    adPlan.storeLandingPage,
    adPlan.storeSpotlight,
  ]);

  useEffect(() => {
    if (
      (adPlan.targetType === TARGET_TYPES.FUNNEL.value ||
        adPlan.targetType === TARGET_TYPES.MANUAL.value) &&
      !adId
    ) {
      onChange({ keywordDiscoveryEnabled: false });
    }

    if (adPlan.targetType === TARGET_TYPES.AUTOMATIC.value && !adId) {
      onChange({ keywordDiscoveryEnabled: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.targetType]);

  useEffect(() => {
    if (
      (AD_TYPES.SPONSORED_DISPLAY_V2.value === adPlan.adType && !adPlan.id) ||
      (adPlan.selectedType === "lifestyleImageDetails" && adPlan.id)
    ) {
      onChange({ sdSquareCroppedArea: null, sdRectangleCroppedArea: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.lifestyleImageDetails?.asset_id]);

  useEffect(() => {
    if (adId || adPlan?.storeSpotlight) {
      return;
    }
    const filteredCreativeProducts = adPlan?.creativeProduct?.filter(
      (productId) => adPlan?.landingPageProduct.includes(productId)
    );

    onChange({ creativeProduct: filteredCreativeProducts });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlan.landingPageProduct]);

  const validate = () => {
    const errors = {};
    let isValid = true;

    const check = (condition, key, msg) => {
      if (condition) {
        isValid = false;
        errors[key] = msg;
      }
    };

    let planToValidate = adPlan;

    check(
      !planToValidate.hasParent && !planToValidate.categoryId,
      "categoryId",
      "Category is required"
    );
    check(planToValidate.name?.length < 1, "name", "Name is required");
    check(
      !planToValidate.name?.match(
        /^([\]\w&+'".Á®ÉÓÜóüÄáŒÖßúäöÛéÀÂâÿŸàèÙçœæÔÆñíÇêëûÈÏéÊïÍîËôÚÎùÑąĄćĆęĘłŁńŃśŚźŹżŻåÅ|!#%()*,./:;<=>?@_`{}~$¿¡«»ĳĲ\s\-\\/-[\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF\s+]*)$/g
      ),
      "name",
      "Invalid name"
    );
    check(
      !planToValidate.hasParent && planToValidate.dailyBudget === "",
      "dailyBudget",
      "Daily budget is required"
    );
    check(
      planToValidate.channel !== "walmart" &&
        !planToValidate.hasParent &&
        planToValidate.dailyBudget < 1,
      "dailyBudget",

      `Daily budget must be at least ${formatCurrency(
        1,
        marketPlace?.marketPlace ?? "USA",
        true
      )}`
    );

    if (planToValidate.channel === "walmart") {
      let minBudgetRequirement =
        organization_type === "vendor"
          ? [
              AD_TYPES.SPONSORED_PRODUCT_WALMART.value,
              AD_TYPES.SPONSORED_PRODUCT.value,
            ].includes(planToValidate?.adType) &&
            planToValidate.goal !== GOALS.SOV.value &&
            planToValidate.autoCampaignEnabled
            ? WALMART_VENDOR_AUTO_CAMPAIGN_MIN_BUDGET
            : WALMART_VENDOR_MANUAL_CAMPAIGN_MIN_BUDGET
          : [
              AD_TYPES.SPONSORED_PRODUCT_WALMART.value,
              AD_TYPES.SPONSORED_PRODUCT.value,
            ].includes(planToValidate?.adType) &&
            planToValidate.goal !== GOALS.SOV.value &&
            planToValidate.autoCampaignEnabled
          ? WALMART_SELLER_AUTO_CAMPAIGN_MIN_BUDGET
          : WALMART_SELLER_MANUAL_CAMPAIGN_MIN_BUDGET;
      check(
        planToValidate.dailyBudget < minBudgetRequirement,
        "dailyBudget",
        `Daily budget must be at least ${formatCurrency(
          minBudgetRequirement,
          marketPlace?.marketPlace ?? "USA",
          true
        )}`
      );

      if (
        [AD_TYPES.SPONSORED_BRAND_WALMART.value].includes(planToValidate.adType)
      ) {
        check(
          planToValidate?.product?.length < 2 ||
            planToValidate?.product?.length > 10,
          "product",
          "Please select between 2 and 10 products"
        );
        check(
          planToValidate.walmartBrandName?.length < 1 ||
            !planToValidate.walmartBrandName,
          "walmartBrandName",
          "A brand name is required"
        );
        check(
          planToValidate.walmartBrandName?.length > 35,
          "walmartBrandName",
          "Brand name must be 35 characters in length or less"
        );
        check(
          planToValidate.walmartHeadline?.length < 1 ||
            !planToValidate.walmartHeadline,
          "walmartHeadline",
          "A headline is required"
        );
        check(
          planToValidate.walmartHeadline?.length > 45,
          "walmartHeadline",
          "Headline must be 45 characters in length or less"
        );
        check(
          planToValidate.walmartHeadline?.length &&
            // Below triggers validation message on presence of any special chars
            !/^(\w\s?)+$/g.test(planToValidate.walmartHeadline),
          "walmartHeadline",
          "The headline may not contain any special characters"
        );
        check(
          !planToValidate.id &&
            planToValidate.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value &&
            (planToValidate.walmartImage?.length < 1 ||
              !planToValidate.walmartImage),
          "walmartImage",
          "An image is required"
        );
        check(
          planToValidate.walmartClickURL?.length < 1 ||
            !planToValidate.walmartClickURL,
          "walmartClickURL",
          "A click URL is required"
        );
        check(
          planToValidate.walmartClickURL &&
            planToValidate.walmartClickURL?.indexOf("walmart.com") === -1,
          "walmartClickURL",
          'The click URL must include "walmart.com"'
        );
        // Vendors do not need a hidden retailer id facet
        check(
          planToValidate?.walmartClickURL &&
            !/^(?:https?:\/\/)?(?:www\.)?walmart\.com\/(?:(?:search?|browse\/|shelf\/)[^?#&]+(?:%7C%7C[^?#&]+)*(?!typeahead=)[^?#&]*)/.test(
              planToValidate?.walmartClickURL
            ) &&
            !/^https:\/\/www\.walmart\.com\/brand\/([a-zA-Z0-9_-]+)\/(\d+)(\?.*)?$/.test(
              planToValidate?.walmartClickURL
            ),
          "walmartClickURL",
          "Invalid click URL"
        );
      }

      if (
        [AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value].includes(
          planToValidate.adType
        )
      ) {
        check(
          !planToValidate.id &&
            planToValidate.adType ===
              AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value &&
            (planToValidate.walmartVideo?.length < 1 ||
              !planToValidate.walmartVideo),
          "walmartImage",
          "A video is required"
        );
        check(
          planToValidate?.product?.length < 1 ||
            planToValidate?.product?.length > 10,
          "product",
          "Please select between 1 and 10 products"
        );
      }
    }

    check(
      planToValidate.hasParent && planToValidate.allocation === "",
      "allocation",
      "Daily budget allocation is required"
    );
    check(
      !planToValidate.canHaveChildren && planToValidate.targetACOS === "",
      "targetACOS",
      `Target ${roas ? "ROAS" : "ACOS"} is required`
    );
    check(
      !planToValidate.canHaveChildren && planToValidate.maxACOS === "",
      "maxACOS",
      `Max ${roas ? "ROAS" : "ACOS"} is required`
    );
    check(
      !planToValidate.canHaveChildren && !planToValidate.adType,
      "adType",
      "An Ad Type is required"
    );
    check(
      !planToValidate.canHaveChildren && !planToValidate.goal,
      "goal",
      "A Goal is required"
    );
    check(
      !planToValidate.canHaveChildren && !planToValidate.targetType,
      "targetType",
      "A target type is required"
    );
    check(
      planToValidate.canHaveChildren && planToValidate.children.length <= 0,
      "children",
      "Component media plans are required"
    );
    check(
      planToValidate.advStatus === STATUS.ARCHIVE.value && !agreeToArchive,
      "agreeToArchive",
      "You must agree before you can save"
    );

    if (planToValidate.targetType === TARGET_TYPES.FUNNEL.value) {
      if (!planToValidate.canHaveChildren) {
        check(
          planToValidate.filledProductKeys?.length < 1,
          "filledProductKeys",
          "At least one product type is required"
        );
      }
      // Validate at least one home brand and one competitor brand added for segmented
      if (planToValidate.canHaveChildren && !planToValidate.id) {
        check(
          planToValidate.filledProductBrands?.length < 1,
          "filledProductBrands",
          "At least one product brand is required"
        );
        check(
          planToValidate.filledCompetitorBrands?.length < 1,
          "filledCompetitorBrands",
          "At least one competitor brand is required"
        );
        // Check for collision between home brand and competitor brands
        check(
          planToValidate.filledProductBrands?.some((brand) =>
            planToValidate.filledCompetitorBrands?.includes(brand)
          ),
          "brandTargetCollision",
          "The same brand was detected in both home and competitor brand fields."
        );
      }
    }

    if (
      planToValidate.adType === AD_TYPES.SPONSORED_PRODUCT.value ||
      (planToValidate.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
        !planToValidate.storeLandingPage) ||
      planToValidate.adType === AD_TYPES.SPONSORED_DISPLAY.value ||
      planToValidate.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value ||
      planToValidate.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO.value ||
      planToValidate.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
    ) {
      check(
        planToValidate?.product?.length <= 0,
        "product",
        "Products are required"
      );
    }

    if (
      planToValidate.channel === "AMZ" &&
      [AD_TYPES.SPONSORED_PRODUCT.value].includes(planToValidate.adType)
    ) {
      check(
        isNaN(parseFloat(planToValidate.topOfSearchBidMultiplier)) ||
          planToValidate.topOfSearchBidMultiplier < 0 ||
          planToValidate.topOfSearchBidMultiplier > 900,
        "topOfSearchBidMultiplier",
        "The top-of-search multiplier must be a value between 0 and 900"
      );
      check(
        isNaN(parseFloat(planToValidate.productPageMultiplier)) ||
          planToValidate.productPageMultiplier < 0 ||
          planToValidate.productPageMultiplier > 900,
        "productPageMultiplier",
        "The product page multiplier must be a value between 0 and 900"
      );
      check(
        isNaN(parseFloat(planToValidate.restOfSearchMultiplier)) ||
          planToValidate.restOfSearchMultiplier < 0 ||
          planToValidate.restOfSearchMultiplier > 900,
        "restOfSearchMultiplier",
        "The rest-of-search multiplier must be a value between 0 and 900"
      );
    }

    if (
      (planToValidate.channel === "AMZ" &&
        !planToValidate.id &&
        planToValidate.campaignNames) ||
      planToValidate.children
    ) {
      check(
        planToValidate?.duplicateCampaignNames?.length,
        "duplicateCampaignNames",
        "Duplicate campaign names found. Please enter a different name."
      );
      // Below checks that no shared custom campaign names are the same while filtering out empty fields
      check(
        [
          ...new Set(
            Object.values(planToValidate?.campaignNames)?.filter((name) => name)
          ),
        ].length !==
          Object.values(planToValidate?.campaignNames)?.filter((name) => name)
            ?.length || planToValidate?.validatingCampaignNames?.length,
        "duplicateCampaignNames",
        "Duplicate campaign names found. Please enter a different name."
      );
      check(
        Object.values(planToValidate.campaignNames)?.some(
          (name) => name?.length > 128
        ),
        "campaignNames",
        "Campaign names cannot be more than 128 characters in length."
      );
      // Segmented campaign name checks
      if (planToValidate?.canHaveChildren && !planToValidate?.id) {
        check(
          [
            ...new Set(
              planToValidate?.children
                .map((c) => c.campaignNames)
                .map((c) => Object.values(c ?? {})?.[0])
                ?.filter((name) => name)
            ),
          ].length !==
            planToValidate.children
              .map((c) => c.campaignNames)
              .map((c) => Object.values(c ?? {})?.[0])
              ?.filter((name) => name)?.length ||
            planToValidate?.validatingCampaignNames?.length,
          "duplicateSegmentedCampaignNames",
          "Campaign names cannot be more than 128 characters in length."
        );
        check(
          planToValidate.children
            .map((c) => c.campaignNames)
            .map((c) => Object.values(c ?? {})?.[0])
            ?.some((name) => name?.length > 128),
          "campaignNames",
          "Campaign names cannot be more than 128 characters in length."
        );
      }
    }

    if (
      planToValidate.adType === AD_TYPES.SPONSORED_BRAND.value &&
      !planToValidate?.page?.label
    ) {
      check(
        !planToValidate.keywordDiscoveryEnabled &&
          planToValidate.filledTags.length <= 0,
        "filledTags",
        "Select discover keywords or enter your keywords"
      );
      check(
        planToValidate.landingPageProduct.length < 3 && !adId,
        "landingPageProduct",
        "Please select at least 3 products for the new landing page"
      );
      check(
        planToValidate.landingPageProduct.length > 100 && !adId,
        "landingPageProduct",
        `No more than 100 products may be selected for the new landing page. Please remove ${
          planToValidate.landingPageProduct.length - 100
        } products.`
      );
    }

    if (
      [
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_DISPLAY_V2.value,
      ].includes(planToValidate.adType)
    ) {
      check(
        planToValidate.headline?.length >= 50,
        "headline",
        `Headlines cannot be more than 50 characters. Please shorten your headline by ${
          planToValidate.headline?.length - 50
        } character${planToValidate.headline?.length - 50 > 1 && "s"}`
      );
    }

    // Lifestyle image and logo creative validations
    if (
      [
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_BRAND_VIDEO.value,
        AD_TYPES.SPONSORED_DISPLAY_V2.value,
      ]?.includes(planToValidate.adType)
    ) {
      // Logo validations: 1MB or less, 400 x 400 or above for SB, or 600 x 100 or above for SD, PNG, JPEG or JPG
      if (planToValidate.logoDetails) {
        check(
          planToValidate.logoDetails?.file_meta_data?.sizeInBytes > 1000000,
          "logo",
          "The selected logo must be less than 1MB in size."
        );
        check(
          [
            AD_TYPES.SPONSORED_BRAND.value,
            AD_TYPES.SPONSORED_BRAND_VIDEO.value,
          ]?.includes(planToValidate.adType) &&
            planToValidate.logoDetails?.file_meta_data?.height < 400,
          "logo",
          "The selected logo must be at least 400px in height."
        );
        check(
          [
            AD_TYPES.SPONSORED_BRAND.value,
            AD_TYPES.SPONSORED_BRAND_VIDEO.value,
          ]?.includes(planToValidate.adType) &&
            planToValidate.logoDetails?.file_meta_data?.width < 400,
          "logo",
          "The selected logo must be at least 400px wide."
        );
        // Require custom asset for all new product collection SB ads
        check(
          [
            AD_TYPES.SPONSORED_BRAND.value,
            AD_TYPES.SPONSORED_BRAND_V2.value,
          ]?.includes(planToValidate.adType) &&
            !planToValidate.lifestyleImageDetails?.name &&
            planToValidate.newProductCollection &&
            !planToValidate.id,
          "lifestyleImage",
          "A custom image is required."
        );
        check(
          [AD_TYPES.SPONSORED_DISPLAY_V2.value]?.includes(
            planToValidate.adType
          ) && planToValidate.logoDetails?.file_meta_data?.height < 100,
          "logo",
          "The selected logo must be at least 100px in height."
        );
        check(
          [AD_TYPES.SPONSORED_DISPLAY_V2.value]?.includes(
            planToValidate.adType
          ) && planToValidate.logoDetails?.file_meta_data?.width < 600,
          "logo",
          "The selected logo must be at least 600px wide."
        );
        check(
          !["JPEG", "JPG", "PNG"].includes(
            planToValidate.logoDetails?.file_meta_data?.extension
          ),
          "logo",
          "The selected logo must be a PNG or JPEG file."
        );
      }
      // Lifestyle image validations: 5MB or less, 1200 x 628 or above, PNG, JPEG or JPG
      if (planToValidate.lifestyleImageDetails) {
        check(
          planToValidate.lifestyleImageDetails?.file_meta_data?.sizeInBytes >
            5000000,
          "lifestyleImage",
          "The selected asset cannot be greater than 5MB in size."
        );
        check(
          planToValidate.lifestyleImageDetails?.file_meta_data?.height < 628,
          "lifestyleImage",
          "The selected image must be at least 628px in height."
        );
        check(
          planToValidate.lifestyleImageDetails?.file_meta_data?.width < 1200,
          "lifestyleImage",
          "The selected image must be at least 1200px wide."
        );
        check(
          !["JPEG", "JPG", "PNG"].includes(
            planToValidate.lifestyleImageDetails?.file_meta_data?.extension
          ),
          "lifestyleImage",
          "The selected asset must be a PNG or JPEG file."
        );
      }
    }

    if (planToValidate.adType === AD_TYPES.SPONSORED_BRAND.value) {
      check(
        planToValidate.headline?.length <= 1,
        "headline",
        "Headline is required"
      );

      check(
        (planToValidate.logo === "" || !planToValidate.logo) &&
          !planToValidate.logoDetails,
        "logo",
        "Logo is required"
      );
      check(
        !planToValidate.newLandingPage &&
          !planToValidate.storeLandingPage &&
          !planToValidate.newProductCollection &&
          !planToValidate.storeSpotlight,
        "landingPage",
        "Landing page type is required"
      );
      if (!(organization_type === "vendor" && brandOptions.length === 0)) {
        check(planToValidate.brand === "", "brand", "Brand is required");
      }
      check(
        planToValidate.creativeBrandName &&
          planToValidate.creativeBrandName.length > 30,
        "creativeBrandName",
        "The brand name for display in the creative must be 30 or less characters long"
      );
      if (
        planToValidate.storeLandingPage ||
        planToValidate.newLandingPage ||
        planToValidate.newProductCollection
      ) {
        check(
          (planToValidate.creativeProduct?.length < 1 ||
            planToValidate.creativeProduct?.length > 3) &&
            !adId,
          "creativeProduct",
          `Please select 1 to 3 products. ${
            planToValidate.creativeProduct?.length > 3
              ? `Remove ${planToValidate.creativeProduct?.length - 3} product${
                  planToValidate.creativeProduct.length - 3 > 1 ? "s" : ""
                }`
              : ""
          }`
        );
      }
      check(
        planToValidate.homePlacementBidMultiplier < -99 ||
          planToValidate.homePlacementBidMultiplier > 900,
        "homePlacementBidMultiplier",
        "Placement multipliers must be a value between -99 and 900"
      );
      check(
        planToValidate.pagePlacementBidMultiplier < -99 ||
          planToValidate.pagePlacementBidMultiplier > 900,
        "pagePlacementBidMultiplier",
        "Placement multipliers must be a value between -99 and 900"
      );
      check(
        planToValidate.otherPlacementBidMultiplier < -99 ||
          planToValidate.otherPlacementBidMultiplier > 900,
        "otherPlacementBidMultiplier",
        "Placement multipliers must be a value between -99 and 900"
      );
      check(
        planToValidate.shopperSegmentBidMultiplier < 0 ||
          planToValidate.shopperSegmentBidMultiplier > 900,
        "shopperSegmentBidMultiplier",
        "The shopper segment multiplier must be a value between 0 and 900"
      );

      if (planToValidate.storeSpotlight) {
        check(!planToValidate.store?.label, "store", "A store is required");

        check(
          planToValidate.storeSpotlightSubpages?.filter(
            (subpage) => !subpage?.pageTitle
          )?.length,
          "storeSpotlightSubpages",
          "A page is required for all 3 store subpages"
        );
        check(
          !planToValidate.storeSpotlightSubpages?.filter(
            (subpage) => !subpage?.pageTitle
          )?.length &&
            planToValidate.storeSpotlightSubpages?.filter(
              (subpage) => !subpage?.asin
            )?.length,
          "storeSpotlightSubpages",
          "An ASIN is required for all 3 store subpages"
        );
      }
    }
    if (planToValidate.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value) {
      if (!(organization_type === "vendor" && brandOptions.length === 0)) {
        check(planToValidate.brand === "", "brand", "Brand is required");
      }
      check(
        planToValidate.creativeBrandName &&
          planToValidate.creativeBrandName.length > 30,
        "creativeBrandName",
        "The brand name for display in the creative must be 30 or less characters long"
      );
      check(
        !planToValidate.video && !planToValidate.videoDetails,
        "video",
        "Video is required"
      );
      check(
        planToValidate.product.length > 1,
        "product",
        `You have exceed the maximum number of products for a video.\nRemove ${
          planToValidate.product.length - 1
        } product${planToValidate.product?.length - 1 > 1 ? "s" : ""}.`
      );
      if (planToValidate.newLandingPage) {
        check(
          planToValidate.creativeProduct.length > 1,
          "product",
          `You have exceed the maximum number of products for a video creative.\nRemove ${
            planToValidate.product.length - 1
          } product${planToValidate.product?.length - 1 > 1 ? "s" : ""}.`
        );
      }
      if (planToValidate.storeLandingPage) {
        check(
          planToValidate.creativeProduct.length <= 0 &&
            planToValidate.product.length <= 0,
          "product",
          "A product is required"
        );
      } else {
        check(
          planToValidate.product.length <= 0,
          "product",
          "A product is required"
        );
      }
      // Video validations: duration 6-45s, max size 50MB
      // Don't re-validate after creation to avoid issues with legacy videos
      if (planToValidate.videoDetails && !planToValidate.id) {
        check(
          planToValidate.videoDetails?.file_meta_data?.sizeInBytes > 500000000,
          "video",
          "The selected video cannot be greater than 500MB in size."
        );
        check(
          planToValidate.videoDetails?.file_meta_data?.durationInSeconds < 6,
          "video",
          "The selected video must be at least 6s in duration."
        );
        check(
          planToValidate.videoDetails?.file_meta_data?.durationInSeconds > 45,
          "video",
          "The selected video cannot be more than 45s in duration."
        );
      }
    }

    // TODO: Verify validation for manual targeting fields
    if (
      planToValidate.targetType === TARGET_TYPES.MANUAL.value &&
      !planToValidate.hasParent &&
      planToValidate.adType !== AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
    ) {
      check(
        planToValidate.filledTags?.length === 0 &&
          planToValidate.filledAsins?.length === 0 &&
          planToValidate.filledTargetCategories?.length === 0,
        "filledTagsOrAsins",
        planToValidate.channel === "walmart"
          ? "Keywords are required"
          : "Keywords, categories or ASINs are required"
      );

      if (planToValidate.filledAsins?.length > 0) {
        check(
          planToValidate.filledAsins
            ?.map((x) => !x.startsWith("B0"))
            .find((r) => r),
          "filledAsins",
          "Invalid ASIN"
        );
      }
    }
    if (
      planToValidate.targetType === TARGET_TYPES.MANUAL.value &&
      !planToValidate.hasParent
    ) {
      if (planToValidate.filledNegativeAsins?.length > 0) {
        check(
          planToValidate.filledNegativeAsins
            ?.map((x) => !x.startsWith("B0"))
            .find((r) => r),
          "filledNegativeAsins",
          "Invalid negative ASIN"
        );
      }
    }

    if (planToValidate.filledTags?.length > 0) {
      check(
        planToValidate.filledTags?.some((kw) => kw.length > 80),
        "filledTags",
        "Invalid keyword. Please ensure keywords are no more than 80 characters in length."
      );
    }

    if (planToValidate.filledTags?.length > 0) {
      check(
        planToValidate.filledTags?.some(
          (kw) => kw[0].includes(".") || kw.substr(kw.length - 1)?.includes(".")
        ),
        "filledTags",
        "Invalid keyword. Please ensure keywords don't start or end with a period (.)."
      );
    }

    if (planToValidate.filledTags?.length > 0) {
      check(
        planToValidate.filledTags?.some(
          (kw) => kw[0].includes("-") || kw.substr(kw.length - 1)?.includes("-")
        ),
        "filledTags",
        "Invalid keyword. Please ensure keywords don't start or end with a hyphen (-)."
      );
    }

    if (planToValidate.filledTags?.length > 0) {
      check(
        planToValidate.filledTags?.some((kw) =>
          kw.substr(kw.length - 1)?.includes("+")
        ),
        "filledTags",
        "Invalid keyword. Please ensure keywords don't end with an addition sign (+)."
      );
    }

    if (
      planToValidate.filledTags?.length > 0 &&
      !["AMZ"].includes(planToValidate.channel)
    ) {
      check(
        planToValidate.filledTags?.some((kw) => kw[0].includes("+")),
        "filledTags",
        "Invalid keyword. Please ensure keywords don't start with an addition sign (+)."
      );
    }

    if (planToValidate.filledTags?.length > 0) {
      check(
        planToValidate.filledTags
          ?.map(
            (x) =>
              !x.match(
                /^([\]\w&+'".Á®ÉÓÜóüÄáŒÖßúäöÛéÀÂâÿŸàèÙçœæÔÆñíÇêëûÈÏéÊïÍîËôÚÎùÑąĄćĆęĘłŁńŃśŚźŹżŻåÅ¿¡«»ĳĲ\s\-\\/-[\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF\s+]*)$/g
              ) ||
              x.indexOf("$") > -1 ||
              x.indexOf("_") > -1
          )
          .find((r) => r),
        "filledTags",
        "Invalid keyword. Please ensure keywords contain only letters and/or numbers."
      );
    }

    if (planToValidate.filledNegativeKeywords?.length > 0) {
      check(
        planToValidate.filledNegativeKeywords
          ?.map(
            (x) =>
              !x.match(
                /^([\]\w&+'".Á®ÉÓÜóüÄáŒÖßúäöÛéÀÂâÿŸàèÙçœæÔÆñíÇêëûÈÏéÊïÍîËôÚÎùÑąĄćĆęĘłŁńŃśŚźŹżŻåÅ¿¡«»ĳĲ\s\-\\/-[\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF\s+]*)$/g
              ) ||
              x.indexOf("$") > -1 ||
              x.indexOf("_") > -1
          )
          .find((r) => r),
        "filledNegativeKeywords",
        "Invalid keyword. Please ensure keywords contain only letters and/or numbers."
      );
    }

    if (planToValidate.filledNegativeKeywords?.length > 0) {
      check(
        planToValidate.filledNegativeKeywords?.some((kw) => kw.length > 80),
        "filledNegativeKeywords",
        "Invalid keyword. Please ensure keywords are no more than 80 characters in length."
      );
    }

    if (planToValidate.filledNegativeKeywords?.length > 0) {
      check(
        planToValidate.filledNegativeKeywords?.some(
          (kw) => kw[0].includes(".") || kw.substr(kw.length - 1)?.includes(".")
        ),
        "filledNegativeKeywords",
        "Invalid keyword. Please ensure keywords don't start or end with a period (.)."
      );
    }

    if (planToValidate.filledNegativeKeywords?.length > 0) {
      check(
        planToValidate.filledNegativeKeywords?.some(
          (kw) => kw[0].includes("-") || kw.substr(kw.length - 1)?.includes("-")
        ),
        "filledNegativeKeywords",
        "Invalid keyword. Please ensure keywords don't start or end with a hyphen (-)."
      );
    }

    if (planToValidate.filledNegativeKeywords?.length > 0) {
      check(
        planToValidate.filledNegativeKeywords?.some(
          (kw) => kw[0].includes("+") || kw.substr(kw.length - 1)?.includes("+")
        ),
        "filledNegativeKeywords",
        "Invalid keyword. Please ensure keywords don't start or end with an addition sign (+)."
      );
    }

    if (planToValidate.hasParent && planToValidate.id) {
      check(
        (planToValidate?.parentDailyBudget / 100.0) *
          parseFloat(planToValidate?.allocation) <
          1,
        "allocation",
        `A minimum daily budget of ${formatCurrency(
          1,
          marketPlace.marketPlace
        )} is required.`
      );
    }

    // SD V2 Validations
    if (planToValidate.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
      check(
        !planToValidate.sdBidType,
        "sdBidType",
        "Please select a bid type for this campaign."
      );

      check(
        !planToValidate.sdCampaignType,
        "sdCampaignType",
        "Please select a campaign type for this campaign."
      );
      check(
        planToValidate.sdCampaignType ===
          SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value &&
          !planToValidate.lookbackPeriod.length,
        "sdLookbackPeriod",
        "Please select at least one lookback period option."
      );
      check(
        planToValidate.sdCampaignType ===
          SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value &&
          !planToValidate.viewsSimilar &&
          !planToValidate.viewsExact &&
          !planToValidate.purchasesSimilar &&
          !planToValidate.purchasesExact &&
          !planToValidate.sdAmazonSuggestedCategories &&
          !planToValidate.filledTargetCategories?.length,
        "sdAudienceTargets",
        "Please select at least one audience targeting option."
      );
      check(
        planToValidate.sdCampaignType ===
          SD_CAMPAIGN_TYPE_OPTIONS.CONTEXTUAL.value &&
          !planToValidate.sdDynamicSimilar &&
          !planToValidate.sdAmazonSuggestedCategories &&
          !planToValidate.filledAsins?.length &&
          !planToValidate.filledTargetCategories?.length,
        "sdContextualTargets",
        "Please select at least one contextual targeting option."
      );
    }
    // Validations for custom bidding rules - only check if program exists or is being created, all other validations happen internally within rules form components
    check(
      planToValidate.algorithm === ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value &&
        !planToValidate.biddingProgram &&
        !planToValidate.createNewProgram,
      "existingBiddingProgram",
      "A bidding program is required. Please select an existing program or create a new one."
    );
    check(
      planToValidate.algorithm === ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value &&
        !planToValidate.biddingProgram &&
        planToValidate.createNewProgram,
      "newBiddingProgram",
      "A bidding program is required. Please select an existing program or create a new one."
    );

    setErrors(errors);

    setIsValid(isValid);
    return isValid;
  };

  const updateChild = (child, idx = null) => {
    if (idx == null) {
      idx = adPlan.children.length;
    }
    const children = [
      ...adPlan.children.slice(0, idx),
      child,
      ...adPlan.children.slice(idx + 1),
    ];
    onChange({ children });
  };

  const removeChild = (idx) => {
    onChange({ children: adPlan.children.filter((c, i) => i !== idx) });
  };

  const clear = () => {
    setAdPlan(initialAdPlan);
  };

  return {
    adPlan,
    isValid,
    errors,
    onChange,
    clear,
    validate,
    updateChild,
    removeChild,
    products,
    brandOptions,
    savedVideos,
    logoOptions,
    storeOptions,
    pageOptions,
    loading,
    agreeToArchive,
    setAgreeToArchive,
    apiStatus,
  };
};

export default useAdPlanState;
