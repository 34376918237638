import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import ProductListing from "../components/custom/search/ProductListing";
import CategoryFilter from "../components/custom/search/CategorySelector";
import ImportButton from "../components/custom/import/ImportButton";
import { themeLargerFont } from "../assets/themes/TrellisTheme";
import { selectModules } from "../redux/selectors/modules";

const useStyles = makeStyles((theme) => ({
  // Someone has set the font-size on "html" to just 10px, which makes
  // all the MUI components obscenely small.
  fontFix: {
    fontSize: "1.4rem",
  },
  container: {
    marginTop: "14px",
    marginBottom: "14px",
  },
  searchFields: {
    marginTop: "14px",
    marginBottom: "8px",
  },
  matchOnFields: {
    marginTop: "14px",
    marginBottom: "8px",
  },
  legend: {
    marginBottom: "4px",
  },
  fullWidth: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  spinnerContainer: {
    width: "100%",
    textAlign: "center",
  },
  listingTable: {
    "& .MuiTableCell-root": {
      fontSize: "1.2rem",
    },
  },
  verticalAlign: {
    display: "flex",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

/**
 * This view implements a product search page that supports basic querying
 * and filtering.
 */
const Search = ({ modules }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const classes = useStyles();

  return (
    <ThemeProvider theme={themeLargerFont}>
      <Container className={classes.container}>
        <Grid
          container
          spacing={3}
          style={{ maxWidth: "1256px", margin: "auto" }}
        >
          {/* Sidebar */}
          <Grid item xs={12} sm={3}>
            <ImportButton />
            <CategoryFilter
              currentCategory={selectedCategory}
              onCategorySelected={setSelectedCategory}
              classes={classes}
            />
          </Grid>
          {/* Main Content */}
          <Grid item xs={12} sm={9}>
            <ProductListing
              currentCategory={selectedCategory}
              classes={classes}
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

Search.propTypes = {};

const mapStateToProps = (state) => {
  return {
    modules: selectModules(state),
  };
};

export default connect(mapStateToProps)(withRouter(Search));
