import React from "react";
import { Row, Col } from "react-bootstrap";

const AdPlanModerationWarnings = ({ violations }) => {
  // Handle validation errors that are sometimes saved as stringified object to only show message to user
  if (violations.includes(`"message":`)) {
    violations = violations?.split(`"message":"`)[1].split(`"}`)[0];
  }

  return (
    <Row>
      <Col xs={12} lg={8} className="pr-5">
        <p
          style={{
            color: "#73706E",
            fontWeight: "700",
            textTransform: "none",
            marginRight: "auto",
            cursor: "pointer",
          }}
          className="fs-standard"
          hidden={
            typeof violations === "string" &&
            violations?.indexOf("awaiting") > -1
          }
        >
          Moderation failed due to the following violation
          {violations.length > 1 ? "s" : ""}:
        </p>
        {typeof violations === "object" ? (
          violations.map((violation) => (
            <div className="pb-4">
              <small className="text-danger">{violation}</small>
            </div>
          ))
        ) : typeof violations === "string" ? (
          <div className="pb-4">
            <small
              className={`text-${
                typeof violations === "string" &&
                violations?.indexOf("awaiting") > -1
                  ? "warning"
                  : "danger"
              }`}
            >
              {violations}
            </small>
          </div>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default AdPlanModerationWarnings;
