import Checkbox from "components/core/basic/CheckBox";
import FormGroupTile from "components/core/basic/FormGroupTile";
import React, { useState, useMemo } from "react";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import ToolTips, { WALMART_AD_THEME_TAGS_TOOLTIP } from "utils/toolTips";
import AdPlanChoiceCard from "./AdPlanChoiceCard";

import {
  AD_THEME_TAGS_TOOLTIP,
  AD_THEME_ASINS_TOOLTIP,
  AD_NEGATIVE_KEYWORD_TAGS_TOOLTIP,
  MAX_ACOS_TOOLTIP,
  MAX_ROAS_TOOLTIP,
  TARGET_ACOS_TOOLTIP,
  TARGET_ROAS_TOOLTIP,
  DAILY_BUDGET_TOOLTIP,
  LANDING_PAGE_TOOLTIP,
  PAUSE_ADV_TOOLTIP,
  AD_NEGATIVE_ASINS_TOOLTIP,
  VIDEO_AD_TYPE_TOOLTIP,
} from "utils/toolTips";
import Tags from "../Tags";
import { Col, Row, FormLabel, Form } from "react-bootstrap";
import {
  AD_TYPES,
  VIDEO_AD_TYPES,
  GOALS,
  STATUS,
  TARGET_TYPES,
  WALMART_SB_MIN_BID_DEFAULT,
  SD_CAMPAIGN_TYPE_OPTIONS,
  SB_BID_STRATEGY_OPTIONS,
  MODERATION_STATUSES,
} from "./constants";
import ProductImage from "components/custom/category/listings/ProductImage";
import { PlainButton } from "assets/themes/Buttons";
import { toCurrency } from "utils/marketplaceConstants";
import { trellisPalette } from "components/custom/analytics/palettes";
import DetailedProductSelector from "../ProductSelector/DetailedProductSelector";
import { useFetch } from "hooks/api";
import AssetLabel from "../AssetSelector/AssetLabel";
import CustomHref from "../CustomHref";
import { Link } from "react-router-dom";
import { getCampaignsByAdType } from "utils/getCampaignsByAdType";

const ControlLabel = Form.Label;

export const FieldWrapper = ({ children, displayError, styles = {} }) => (
  <div style={{ marginBottom: displayError ? "0.5em" : "0", ...styles }}>
    {children}
  </div>
);

export const StepTitle = ({ children, overrideStyles }) => (
  <div style={{ textAlign: "left", marginBottom: "2rem", ...overrideStyles }}>
    <h6
      className="title"
      style={{
        textTransform: "none",
        color: "#484848",
        fontSize: "20px",
        fontWeight: "700",
      }}
    >
      {children}
    </h6>
    <hr />
  </div>
);

export const NameField = ({
  state,
  onChange,
  onReview,
  errors,
  adId,
  fieldsToValidate,
}) => (
  <FieldWrapper
    displayError={
      Object.keys(errors)?.includes("name") &&
      fieldsToValidate?.includes("name")
    }
  >
    <div style={{ maxWidth: "70%" }}>
      <FormGroupTile
        formControlClass="text-start"
        type="formcontrol"
        label={undefined}
        formControlType={"text"}
        stateName={"name"}
        handleChildFormElement={(key, value) => onChange({ [key]: value })}
        placeholder={"Plan Name"}
        defaultValue={state.name}
        disabled={!!adId || !!state.id}
        autoFocus={true}
        style={{ border: "1px solid #BBB" }}
      />
      {errors.name && fieldsToValidate?.includes("name") && (
        <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
          {errors.name}
        </small>
      )}
    </div>
  </FieldWrapper>
);

export const CategoryField = ({
  categories = [],
  marketPlace,
  channel,
  dashboard = false,
  state = {},
  errors,
  onChange,
  fieldsToValidate,
  inline,
  existingAdCategorySelector,
  review,
}) => {
  const [search, setSearch] = useState(null);

  const { data: categoryData } = useFetch(
    "categories",
    "/api/gvads/categories",
    {
      marketplace: marketPlace.marketPlace,
      channel: channel === "amazon" ? "AMZ" : channel,
    },
    {
      default: [],
      select: (res) => res.data,
    }
  );

  let categoryOptions = useMemo(
    () =>
      categoryData
        ?.sort((a, b) => (a.leaf_category_name > b.leaf_category_name ? 1 : -1))
        ?.filter((c) =>
          search ? c.leaf_category_name?.toLowerCase().indexOf(search) > -1 : c
        )
        ?.map((category) => ({
          label: (
            <>
              {category.leaf_category_name}{" "}
              <small>({category.product_count} Products)</small>
            </>
          ),
          value: category.id,
        })),
    [search, categoryData]
  );

  if (
    (((state.newLandingPage || state.newProductCollection) &&
      state.adType === AD_TYPES.SPONSORED_BRAND.value) ||
      state.id) &&
    !existingAdCategorySelector
  ) {
    return null;
  }

  return (
    <div
      style={{
        maxWidth: existingAdCategorySelector ? "350px" : "90%",
        width: existingAdCategorySelector ? "350px" : "inherit",
      }}
    >
      {!inline && !existingAdCategorySelector && (
        <span
          style={{
            fontSize: "20px",
            fontWeight: 500,
            margin: "1rem 0 0",
            paddingBottom: ".5rem",
          }}
        >
          Filter by Product Category
        </span>
      )}
      <FieldWrapper
        displayError={
          Object.keys(errors).includes("categoryId") &&
          fieldsToValidate?.includes("categoryId")
        }
        styles={{
          marginTop: state.id || review ? 0 : "1rem",
        }}
      >
        <FormGroupTile
          type="select"
          search={search}
          setSearch={setSearch}
          multi={false}
          stateName={"categoryId"}
          handleChildFormElement={(key, value) => {
            // In update panel, changing category removes all ad products from rendering in selector
            if (state.id) {
              onChange({ updatedCategoryId: value.value });
            } else {
              onChange({ categoryId: value.value });
            }
          }}
          label={undefined}
          defaultValue={
            state.updatedCategoryId
              ? categoryOptions?.find(
                  (c) => c.value === state.updatedCategoryId
                )
              : categoryOptions?.find(
                  (c) => c.value === parseInt(state.categoryId)
                )
          }
          options={
            categoryData?.length
              ? categoryOptions
              : categories?.sort((a, b) => (a.label > b.label ? 1 : -1))
          }
          filterOption={() =>
            categoryData?.length
              ? categoryOptions
              : categories?.sort((a, b) => (a.label > b.label ? 1 : -1))
          }
          disabled={!dashboard}
        />
        {errors.categoryId && fieldsToValidate?.includes("categoryId") && (
          <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
            {errors.categoryId}
          </small>
        )}
      </FieldWrapper>
    </div>
  );
};

export const AcosGoalsField = ({
  state,
  onChange,
  marketPlace,
  adId,
  isChild = false,
  errors,
  parentDailyBudget,
  roas,
  fieldsToValidate,
  hideLabels,
  childIndex,
  savedChildPlans,
  setSavedChildPlans,
  allowChildEdit,
  isModal,
  modules,
}) => {
  if (state.canHaveChildren) {
    return null;
  }

  return (
    <>
      <Row
        style={{
          paddingTop: hideLabels ? "0rem" : "1.5rem",
        }}
      >
        <Col xs={12} lg={4} className="px-3">
          <FormGroupTile
            type="formcontrol"
            label={
              hideLabels ? undefined : roas ? "Target ROAS" : "Target ACOS"
            }
            formControlType={"number"}
            prefix={roas ? undefined : "%"}
            prefixSide={"right"}
            toolTip={
              hideLabels
                ? undefined
                : roas
                ? TARGET_ROAS_TOOLTIP
                : TARGET_ACOS_TOOLTIP
            }
            handleChildFormElement={(key, value) => {
              if (childIndex !== undefined) {
                let tempChildren = savedChildPlans;
                tempChildren[childIndex][key] = value;
                setSavedChildPlans(tempChildren);
                onChange({ children: tempChildren });
                return;
              }
              onChange({ [key]: value });
            }}
            stateName={"targetACOS"}
            placeholder={`Enter Target ${roas ? "Roas" : "AcoS"}`}
            defaultValue={state.targetACOS}
            disabled={state.id && isChild && !allowChildEdit}
          />
          {((errors.targetACOS && fieldsToValidate?.includes("targetACOS")) ||
            (errors.targetACOS && adId)) && (
            <small className="text-danger" style={{ paddingLeft: "2rem" }}>
              {errors.targetACOS}
            </small>
          )}
        </Col>
        <Col xs={12} lg={4} className="px-3">
          <FormGroupTile
            type="formcontrol"
            label={hideLabels ? undefined : roas ? "Min ROAS" : "Max ACOS"}
            prefix={roas ? undefined : "%"}
            prefixSide={"right"}
            formControlType={"number"}
            toolTip={
              hideLabels
                ? undefined
                : roas
                ? MAX_ROAS_TOOLTIP
                : MAX_ACOS_TOOLTIP
            }
            stateName={"maxACOS"}
            handleChildFormElement={(key, value) => {
              if (childIndex !== undefined) {
                let tempChildren = savedChildPlans;
                tempChildren[childIndex][key] = value;
                setSavedChildPlans(tempChildren);
                onChange({ children: tempChildren });
                return;
              }
              onChange({ [key]: value });
            }}
            placeholder={`Enter Max ${roas ? "ROAS" : "ACoS"}`}
            defaultValue={state.maxACOS}
            disabled={state.id && isChild && !allowChildEdit}
          />
          {((errors.maxACOS && fieldsToValidate?.includes("maxACOS")) ||
            (errors.maxACOS && adId)) && (
            <small className="text-danger" style={{ paddingLeft: "2rem" }}>
              {errors.maxACOS}
            </small>
          )}
        </Col>
        <Col xs={12} lg={4} className="px-3">
          {isChild ? (
            <>
              <FormGroupTile
                type="formcontrol"
                label={hideLabels ? undefined : "Budget Allocation (%)"}
                formControlType={"number"}
                prefix={"%"}
                prefixSide={"right"}
                stateName={"allocation"}
                handleChildFormElement={(key, value) => {
                  if (childIndex !== undefined) {
                    let tempChildren = savedChildPlans;
                    tempChildren[childIndex][key] = value;
                    setSavedChildPlans(tempChildren);
                    onChange({ children: tempChildren });
                    return;
                  }
                  onChange({
                    [key]: value,
                    parentDailyBudget,
                  });
                }}
                placeholder={"Allocation (%)"}
                defaultValue={state.allocation}
                disabled={state.id && isChild && !allowChildEdit}
              />
              {parentDailyBudget && (
                <div
                  className="text-center mt-3"
                  style={{ marginRight: "18px" }}
                >
                  {toCurrency(
                    (parentDailyBudget / 100.0) * state.allocation,
                    marketPlace.marketPlace
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <FormGroupTile
                type="formcontrol"
                label={"Daily Budget"}
                formControlType={"number"}
                prefix={
                  MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace] ??
                  "$"
                }
                toolTip={DAILY_BUDGET_TOOLTIP}
                stateName={"dailyBudget"}
                handleChildFormElement={(key, value) => {
                  onChange({ [key]: value });
                }}
                placeholder={"Daily Budget"}
                defaultValue={state.dailyBudget}
              />
              {errors.dailyBudget && (
                <p
                  className="text-danger pt-2"
                  style={{ marginLeft: "2rem", fontSize: "12px" }}
                >
                  {errors.dailyBudget}
                </p>
              )}
            </>
          )}
        </Col>
      </Row>
      {!allowChildEdit && isChild && (
        <Row>
          <Col xs={12} className="pt-3">
            <p style={{ fontSize: "1.4rem" }}>
              To edit these fields, please{" "}
              <Link
                to={`/user/advertising/dashboard/ads/${state.parent}`}
                style={{
                  color: "#d91266",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                visit the summary table
              </Link>
            </p>
          </Col>
        </Row>
      )}
      {errors.allocation && (
        <h6 className="text-danger">{errors.allocation}</h6>
      )}
    </>
  );
};

export const CompositeBudgetStatusField = ({ state, onChange }) => {
  if (!state.canHaveChildren || !state.id) {
    return null;
  }
  const stateOptions = [STATUS.PAUSE, STATUS.ACTIVE, STATUS.ARCHIVE];
  return (
    <FieldWrapper>
      <Row>
        <Col xs={12} lg={4}>
          <FormGroupTile
            type="select"
            stateName={"advStatus"}
            toolTip={PAUSE_ADV_TOOLTIP}
            handleChildFormElement={(key, value) =>
              onChange({ [key]: value.value })
            }
            label={"Advertising State"}
            defaultValue={stateOptions.find((o) => o.value === state.advStatus)}
            options={stateOptions}
          />
        </Col>
      </Row>
    </FieldWrapper>
  );
};

export const TargetingField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  channel,
  isSummaryCard,
  review,
}) => {
  let targetingOptions =
    channel === "walmart"
      ? state.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
        ? [TARGET_TYPES.AUTOMATIC]
        : [
            AD_TYPES.SPONSORED_BRAND_WALMART.value,
            AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
          ].includes(state.adType)
        ? [TARGET_TYPES.MANUAL]
        : [
            TARGET_TYPES.MANUAL,
            {
              ...TARGET_TYPES.FUNNEL,
              description:
                "Provide descriptions of your product attributes and automatically generate a list of keyword targets.",
            },
          ]
      : [TARGET_TYPES.FUNNEL, TARGET_TYPES.MANUAL, TARGET_TYPES.AUTOMATIC];

  if (
    [
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      AD_TYPES.SPONSORED_BRAND.value,
    ].includes(state.adType) &&
    !state.id
  ) {
    targetingOptions = [TARGET_TYPES.MANUAL, TARGET_TYPES.FUNNEL];
  }

  const summaryCardContent = targetingOptions.find(
    (choice) => choice.value === state.targetType
  );

  const targetingTypeTBD =
    state.adType !== AD_TYPES.SPONSORED_DISPLAY_V2.value &&
    (state.targetType === "N/A" || !state.targetType);

  if (
    (!targetingTypeTBD && !isSummaryCard) ||
    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
  ) {
    return null;
  }

  return (
    <FieldWrapper>
      <Row style={{ marginTop: "2rem" }}>
        {/* For now, we are assuming all Google ads will have funnel targeting type, hide dropdown selector */}
        {channel !== "google" && (
          <>
            <Col xs={12}>
              {!isSummaryCard && (
                <>
                  <h2
                    style={{ fontWeight: 500, marginTop: 0, fontSize: "20px" }}
                  >
                    Targeting Strategy
                  </h2>
                  {errors.targetType &&
                    fieldsToValidate?.includes("targetType") && (
                      <small className="text-danger">{errors.targetType}</small>
                    )}
                </>
              )}
              {isSummaryCard && !targetingTypeTBD ? (
                <AdPlanChoiceCard
                  id={`new-mediaplan-targeting-summary-card`}
                  state={state}
                  label={summaryCardContent?.label}
                  value={summaryCardContent}
                  description={summaryCardContent?.description}
                  childElements={null}
                  isSelected={false}
                  editElement={
                    ((![
                      AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
                      AD_TYPES.SPONSORED_DISPLAY_V2.value,
                      AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
                      AD_TYPES.SPONSORED_BRAND_WALMART.value,
                      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                      AD_TYPES.SPONSORED_PRODUCT_GENERIC.value,
                      AD_TYPES.SPONSORED_DISPLAY.value,
                      AD_TYPES.CUSTOM.value,
                    ].includes(state.adType) &&
                      !(
                        state.adType === AD_TYPES.SPONSORED_PRODUCT.value &&
                        state.hasParent
                      )) ||
                      (![
                        AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
                        AD_TYPES.SPONSORED_BRAND_WALMART.value,
                        AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                      ].includes(state.adType) &&
                        state.channel === "walmart")) &&
                    !review &&
                    !state.id ? (
                      <span
                        onClick={() => onChange({ targetType: null })}
                        style={{
                          textTransform: "uppercase",
                          fontSize: "12px",
                          color: "#333",
                        }}
                      >
                        Change Target Type <i className="fa fa-pencil" />
                      </span>
                    ) : null
                  }
                  isSummaryCard={isSummaryCard}
                />
              ) : (
                targetingOptions.map((type, index) => {
                  return (
                    <AdPlanChoiceCard
                      id={`new-mediaplan-targeting-${index}`}
                      state={state}
                      label={type.label}
                      value={type}
                      description={type.description}
                      childElements={null}
                      isSelected={type.value === state.targetType}
                      style={{ background: "rgb(206, 212, 218)" }}
                      onChange={(value) => {
                        onChange({ targetType: value.value });
                      }}
                    />
                  );
                })
              )}
            </Col>
          </>
        )}
      </Row>
    </FieldWrapper>
  );
};

export const TagsField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  review,
  channel,
}) => {
  if (
    state.adType !== AD_TYPES.SPONSORED_PRODUCT.value &&
    state.adType !== AD_TYPES.SPONSORED_BRAND.value &&
    state.adType !== AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
    state.adType !== AD_TYPES.SPONSORED_PRODUCT_AUTO.value &&
    state.adType !== AD_TYPES.CUSTOM.value &&
    channel !== "walmart"
  ) {
    return null;
  }

  if (
    state.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO.value ||
    state.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
  ) {
    return null;
  }

  return (
    <FieldWrapper>
      <div
        style={{ padding: review && state.filledTags?.length < 1 ? 0 : "2rem" }}
      >
        <Tags
          name={
            <h6
              className="title"
              style={{
                color: "#73706E",
                fontWeight: "700",
                textTransform: "none",
                margin: "1rem 0",
              }}
            >
              Your Additional Keywords
              {state.targetType === TARGET_TYPES.AUTOMATIC.value
                ? " (Optional)"
                : null}
              <ToolTips
                toolTip={
                  channel === "walmart"
                    ? WALMART_AD_THEME_TAGS_TOOLTIP
                    : AD_THEME_TAGS_TOOLTIP
                }
                position="top"
                id={"AD_THEME_TAGS_TOOLTIP"}
              />
            </h6>
          }
          filledTags={state.filledTags}
          saveFilledTags={(filledTags) => onChange({ filledTags })}
          placeholder={review ? "" : "Keywords"}
          reviewDisplay={review}
          disabled={review}
        />
        {errors.filledTags && (
          <small className="text-danger">{errors.filledTags}</small>
        )}
        {channel === "walmart" &&
          errors.filledTagsOrAsins &&
          fieldsToValidate?.includes("filledTagsOrAsins") && (
            <small className="text-danger">{errors.filledTagsOrAsins}</small>
          )}
      </div>
    </FieldWrapper>
  );
};

export const AsinsField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  review,
  channel,
}) => {
  if (
    ![AD_TYPES.SPONSORED_DISPLAY_V2.value, AD_TYPES.CUSTOM.value].includes(
      state.adType
    ) &&
    (state.adType !== AD_TYPES.SPONSORED_PRODUCT.value || channel === "walmart")
  ) {
    return null;
  }

  if (
    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
    state.sdCampaignType !== SD_CAMPAIGN_TYPE_OPTIONS.CONTEXTUAL.value
  ) {
    return null;
  }

  if ([channel, state.channel]?.includes("walmart")) {
    return null;
  }

  return (
    <FieldWrapper>
      <div
        style={{
          padding: review && state.filledAsins?.length < 1 ? 0 : "2rem",
        }}
      >
        <Tags
          name={
            <h6
              className="title"
              style={{
                color: "#73706E",
                fontWeight: "700",
                textTransform: "none",
                marginBottom: "1rem",
              }}
            >
              Your Additional ASINs
              {state.targetType === TARGET_TYPES.AUTOMATIC.value ||
              state.targetType === TARGET_TYPES.FUNNEL.value
                ? " (Optional)"
                : null}
              <ToolTips
                toolTip={AD_THEME_ASINS_TOOLTIP}
                position="top"
                id={"AD_THEME_ASINS_TOOLTIP"}
              />
            </h6>
          }
          filledTags={state.filledAsins ?? []}
          saveFilledTags={(filledAsins) =>
            onChange({ filledAsins: filledAsins?.map((a) => a.toUpperCase()) })
          }
          placeholder={review ? "" : "ASINs"}
          reviewDisplay={review}
          disabled={review}
        />

        {errors.filledAsins && fieldsToValidate?.includes("filledAsins") && (
          <small className="text-danger">
            {errors.filledAsins && errors.filledAsins}
          </small>
        )}
        {errors.filledTagsOrAsins &&
          fieldsToValidate?.includes("filledTagsOrAsins") && (
            <small className="text-danger">{errors.filledTagsOrAsins}</small>
          )}
      </div>
    </FieldWrapper>
  );
};

export const NegativeKeywordsField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  review,
}) => {
  if (
    ![
      AD_TYPES.SPONSORED_PRODUCT.value,
      AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
    ].includes(state.adType)
  ) {
    return null;
  }

  return (
    <FieldWrapper>
      <div>
        <Tags
          name={
            <h3
              className="title"
              style={{
                fontWeight: 560,
                fontSize: "1.6rem",
                color: "#73706E",
              }}
            >
              Your Negative Keywords
              {state.targetType === TARGET_TYPES.AUTOMATIC.value
                ? " (Optional)"
                : null}
              <ToolTips
                toolTip={AD_NEGATIVE_KEYWORD_TAGS_TOOLTIP}
                position="top"
                id={"AD_THEME_TAGS_TOOLTIP"}
              />
            </h3>
          }
          filledTags={state.filledNegativeKeywords}
          saveFilledTags={(filledNegativeKeywords) =>
            onChange({ filledNegativeKeywords })
          }
          placeholder={review ? "" : "Keywords"}
          reviewDisplay={review}
          disabled={review}
        />
        {errors.filledNegativeKeywords && (
          <small className="text-danger">{errors.filledNegativeKeywords}</small>
        )}
      </div>
    </FieldWrapper>
  );
};

export const NegativeAsinsField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  review,
  channel,
}) => {
  if (
    (state.adType !== AD_TYPES.SPONSORED_PRODUCT.value &&
      state.adType !== AD_TYPES.SPONSORED_PRODUCT_AUTO.value) ||
    channel !== "amazon"
  ) {
    return null;
  }

  return (
    <FieldWrapper>
      <div className="mt-5">
        <Tags
          name={
            <h3
              className="title"
              style={{
                fontWeight: 560,
                fontSize: "1.6rem",
                color: "#73706E",
              }}
            >
              Your Negative ASINs
              {state.targetType === TARGET_TYPES.AUTOMATIC.value
                ? " (Optional)"
                : null}
              <ToolTips
                toolTip={AD_NEGATIVE_ASINS_TOOLTIP}
                position="top"
                id={"AD_NEGATIVE_ASINS_TOOLTIP"}
              />
            </h3>
          }
          filledTags={state.filledNegativeAsins}
          saveFilledTags={(filledNegativeAsins) =>
            onChange({ filledNegativeAsins })
          }
          placeholder={review ? "" : "Negative ASINs"}
          reviewDisplay={review}
          disabled={review}
        />
        {errors.filledNegativeAsins && (
          <small className="text-danger">{errors.filledNegativeAsins}</small>
        )}
      </div>
    </FieldWrapper>
  );
};

export const AdStatusField = ({
  state,
  onChange,
  user,
  channel,
  marketPlace,
  isModal,
}) => {
  if (state.canHaveChildren) {
    return null;
  }

  const stateOptions =
    channel === "walmart"
      ? [STATUS.PAUSE, STATUS.ACTIVE]
      : [
          STATUS.PAUSE,
          STATUS.ACTIVE,
          {
            ...STATUS.ARCHIVE,
            isDisabled: state.advStatus === STATUS.ACTIVE.value,
          },
        ];

  return (
    <FieldWrapper
      displayError={
        state.advStatus === STATUS.ARCHIVE.value && user.organization_admin
      }
    >
      <Row>
        <Col xs={12} lg={4} className="px-3">
          <FormGroupTile
            type="select"
            stateName={"advStatus"}
            toolTip={PAUSE_ADV_TOOLTIP}
            handleChildFormElement={(key, value) =>
              onChange({ [key]: value.value })
            }
            label={"Advertising State"}
            defaultValue={stateOptions.find((o) => o.value === state.advStatus)}
            options={stateOptions}
          />
        </Col>
      </Row>
    </FieldWrapper>
  );
};

export const AdTypeField = ({
  state,
  onChange,
  adId,
  marketPlace,
  isChild,
  user,
  channel,
  errors,
  fieldsToValidate,
  storeOptions,
  brandOptions,
  pageOptions,
  isSummaryCard,
  setEditStep,
  review,
  categories,
  dashboard,
  hidden,
  modules,
}) => {
  if (state.canHaveChildren || hidden) {
    return null;
  }

  const WALMART_SP_DAILY_BUDGET_DEFAULT =
    user.organization_type === "vendor" ? 126 : 26;
  const WALMART_SINGLE_CAMPAIGN_DAILY_BUDGET_DEFAULT =
    user.organization_type === "vendor" ? 50 : 10;

  const adTypes = {
    amazon: isChild
      ? [
          AD_TYPES.SPONSORED_PRODUCT,
          AD_TYPES.SPONSORED_PRODUCT_AUTO,
          AD_TYPES.SPONSORED_PRODUCT_GENERIC,
        ]
      : user.organization_type === "vendor" || user.brand_registered !== "False"
      ? [
          AD_TYPES.SPONSORED_PRODUCT,
          AD_TYPES.SPONSORED_BRAND,
          AD_TYPES.SPONSORED_BRAND_VIDEO,
          AD_TYPES.SPONSORED_PRODUCT_AUTO,
          AD_TYPES.SPONSORED_DISPLAY_V2,
        ]
      : [
          AD_TYPES.SPONSORED_PRODUCT,
          AD_TYPES.SPONSORED_BRAND,
          AD_TYPES.SPONSORED_BRAND_VIDEO,
          AD_TYPES.SPONSORED_PRODUCT_AUTO,
        ],
    google: [
      AD_TYPES.GOOGLE_SHOPPING_AD,
      AD_TYPES.GOOGLE_SHOPPING_AD_LOW_COHESIVE,
      AD_TYPES.GOOGLE_STORE_AD,
    ],
    walmart: [
      {
        ...AD_TYPES.SPONSORED_PRODUCT,
        description:
          "Display individual product listings on Walmart product pages and related shopping results.",
      },
      AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART,
      AD_TYPES.SPONSORED_BRAND_WALMART,
      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART,
    ],
  };

  const adTypeOptions = [GOALS.SOV.value].includes(state.goal)
    ? {
        amazon: [AD_TYPES.SPONSORED_PRODUCT],
        walmart: [AD_TYPES.SPONSORED_PRODUCT],
      }
    : adTypes;

  const summaryCardContent = adTypeOptions[channel]?.find(
    (choice) => choice.value === state.adType
  );

  return (
    <FieldWrapper>
      <Row style={{ maxWidth: "90%" }}>
        <Col xs={12}>
          {isSummaryCard && summaryCardContent ? (
            <AdPlanChoiceCard
              id={`new-mediaplan-type-summary-card`}
              state={state}
              label={summaryCardContent.label}
              value={summaryCardContent}
              description={summaryCardContent.description}
              childElements={null}
              editElement={
                <span
                  onClick={() => setEditStep(1)}
                  style={{
                    textTransform: "uppercase",
                    fontSize: "12px",
                    color: "#333",
                  }}
                >
                  Edit Ad Type <i className="fa fa-pencil" />
                </span>
              }
              isSelected={false}
              isSummaryCard={isSummaryCard}
            />
          ) : (
            adTypeOptions[channel]?.map((type, index) => {
              return (
                <AdPlanChoiceCard
                  id={`new-mediaplan-type-${index}`}
                  state={state}
                  label={type.label}
                  value={type}
                  description={type.description}
                  disabled={type.value === AD_TYPES.SPONSORED_DISPLAY.value}
                  overrideStyles={
                    (type.value === AD_TYPES.SPONSORED_BRAND.value &&
                      channel === "walmart") ||
                    type.value === AD_TYPES.SPONSORED_DISPLAY.value
                      ? {
                          backgroundColor: "#F8F8F8",
                          pointerEvents: "none",
                          color: "#999",
                        }
                      : {}
                  }
                  childElements={
                    channel === "walmart" && type.value === state.adType ? (
                      <AcosGoalsField
                        state={state}
                        onChange={onChange}
                        user={user}
                        marketPlace={marketPlace}
                        errors={errors}
                        isChild={isChild}
                        adId={adId}
                        roas={user.preferences.acos_or_roas === "roas"}
                        channel={channel}
                        fieldsToValidate={fieldsToValidate}
                        review={review}
                        modules={modules}
                      />
                    ) : type.value === state.adType &&
                      type.value === AD_TYPES.SPONSORED_BRAND.value ? (
                      [
                        <LandingPageDetailsField
                          state={state}
                          onChange={onChange}
                          errors={errors}
                          fieldsToValidate={fieldsToValidate}
                          adId={adId}
                          storeOptions={storeOptions}
                          brandOptions={brandOptions}
                          pageOptions={pageOptions}
                          review={review}
                          categories={categories}
                          dashboard={dashboard}
                        />,
                        <LandingPageField
                          state={state}
                          onChange={onChange}
                          errors={errors}
                          fieldsToValidate={fieldsToValidate}
                          adId={adId}
                          storeOptions={storeOptions}
                          brandOptions={brandOptions}
                          pageOptions={pageOptions}
                        />,
                      ]
                    ) : type.value === state.adType &&
                      type.value === AD_TYPES.SPONSORED_BRAND_VIDEO.value ? (
                      [
                        <LandingPageDetailsField
                          state={state}
                          onChange={onChange}
                          errors={errors}
                          fieldsToValidate={fieldsToValidate}
                          adId={adId}
                          storeOptions={storeOptions}
                          brandOptions={brandOptions}
                          pageOptions={pageOptions}
                          review={review}
                          categories={categories}
                          dashboard={dashboard}
                        />,
                        <VideoAdTypeField
                          state={state}
                          onChange={onChange}
                          errors={errors}
                          fieldsToValidate={fieldsToValidate}
                          adId={adId}
                          storeOptions={storeOptions}
                          brandOptions={brandOptions}
                          pageOptions={pageOptions}
                          review={review}
                          categories={categories}
                          dashboard={dashboard}
                        />,
                      ]
                    ) : null
                  }
                  isSelected={type.value === state.adType}
                  onChange={(value) => {
                    if (
                      (value.value === AD_TYPES.SPONSORED_PRODUCT_AUTO.value &&
                        state.hasParent) ||
                      (value.value === AD_TYPES.SPONSORED_DISPLAY.value &&
                        !state.hasParent)
                    ) {
                      onChange({
                        adType: value.value,
                        targetType: TARGET_TYPES.AUTOMATIC.value,
                        campaignNames: getCampaignsByAdType(
                          value.value,
                          state.campaignNames,
                          state.autoCampaignEnabled,
                          state.name
                        ),
                      });
                      if (value.value === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
                        onChange({
                          asinHarvestingEnabled: true,
                          campaignNames: getCampaignsByAdType(
                            value.value,
                            state.campaignNames,
                            state.autoCampaignEnabled,
                            state.name
                          ),
                        });
                      }
                      return;
                    }
                    if (
                      value.value === AD_TYPES.SPONSORED_PRODUCT.value &&
                      state.hasParent
                    ) {
                      onChange({
                        adType: value.value,
                        targetType: TARGET_TYPES.MANUAL.value,
                        campaignNames: getCampaignsByAdType(
                          value.value,
                          state.campaignNames,
                          state.autoCampaignEnabled,
                          state.name
                        ),
                      });
                      return;
                    }

                    if (channel === "walmart") {
                      if (
                        [
                          AD_TYPES.SPONSORED_PRODUCT_WALMART.value,
                          AD_TYPES.SPONSORED_PRODUCT.value,
                        ].includes(value.value) &&
                        state.goal !== GOALS.SOV.value
                      ) {
                        onChange({
                          dailyBudget: WALMART_SP_DAILY_BUDGET_DEFAULT,
                          targetType: TARGET_TYPES.MANUAL.value,
                          adType: value.value,
                          minBid: null,
                        });
                        return;
                      }
                      if (
                        [
                          AD_TYPES.SPONSORED_BRAND_WALMART.value,
                          AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                          AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
                        ].includes(value.value)
                      ) {
                        onChange({
                          adType: value.value,
                          minBid:
                            value.value ===
                            AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
                              ? null
                              : WALMART_SB_MIN_BID_DEFAULT,
                          dailyBudget:
                            WALMART_SINGLE_CAMPAIGN_DAILY_BUDGET_DEFAULT,
                          targetType:
                            value.value ===
                            AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
                              ? TARGET_TYPES.AUTOMATIC.value
                              : TARGET_TYPES.MANUAL.value,
                        });
                        return;
                      }
                    }
                    if (value.value === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
                      onChange({
                        adType: value.value,
                        targetType: AD_TYPES.SPONSORED_DISPLAY_V2.targetType,
                        campaignNames: getCampaignsByAdType(
                          value.value,
                          state.campaignNames,
                          state.autoCampaignEnabled,
                          state.name
                        ),
                      });
                      return;
                    }
                    if (
                      [
                        AD_TYPES.SPONSORED_BRAND.value,
                        AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                      ].includes(value.value) &&
                      state.targetType !== TARGET_TYPES.FUNNEL.value
                    ) {
                      onChange({
                        adType: value.value,
                        targetType: TARGET_TYPES.FUNNEL.value,
                        campaignNames: getCampaignsByAdType(
                          value.value,
                          state.campaignNames,
                          state.autoCampaignEnabled,
                          state.name
                        ),
                      });
                      return;
                    }

                    if (
                      (value.value === AD_TYPES.SPONSORED_BRAND.value ||
                        value.value === AD_TYPES.SPONSORED_BRAND_VIDEO.value) &&
                      !state.id
                    ) {
                      onChange({
                        bidStrategy:
                          SB_BID_STRATEGY_OPTIONS.MAXIMIZE_IMMEDIATE_SALES
                            .value,
                        adType: value.value,
                        campaignNames: getCampaignsByAdType(
                          value.value,
                          state.campaignNames,
                          state.autoCampaignEnabled,
                          state.name
                        ),
                      });
                      return;
                    }

                    if (value.value !== AD_TYPES.SPONSORED_BRAND.value) {
                      onChange({
                        storeSpotlight: false,
                        storeSpotlightSubpages: [],
                        campaignNames: getCampaignsByAdType(
                          value.value,
                          state.campaignNames,
                          state.autoCampaignEnabled,
                          state.name
                        ),
                      });
                    }

                    if (
                      value.value !== AD_TYPES.SPONSORED_PRODUCT_AUTO.value &&
                      state.autoNegationEnabled
                    ) {
                      onChange({
                        maxAcosNegationThreshold: null,
                        cumulativeNegationThreshold: null,
                        autoNegationEnabled: false,
                        adType: value.value,
                        campaignNames: getCampaignsByAdType(
                          value.value,
                          state.campaignNames,
                          state.autoCampaignEnabled,
                          state.name
                        ),
                      });
                      return;
                    }

                    onChange({
                      adType: value.value,
                      campaignNames: getCampaignsByAdType(
                        value.value,
                        state.campaignNames,
                        state.autoCampaignEnabled,
                        state.name
                      ),
                    });
                  }}
                />
              );
            })
          )}
          {user.organization_type === "seller" &&
            user.brand_registered === "False" &&
            channel === "amazon" && (
              <div
                style={{
                  padding: "0 100px",
                }}
              >
                <div />
                <p
                  style={{
                    color: "#73706E",
                  }}
                  className="fs-standard w-75"
                >
                  To enable Sponsored Display, please ensure you have at least
                  one brand registered with Amazon.
                </p>
              </div>
            )}
          {errors.adType && fieldsToValidate?.includes("adType") && (
            <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
              {errors.adType}
            </small>
          )}
        </Col>
      </Row>
    </FieldWrapper>
  );
};

export const GoogleFields = ({ state, onChange, adId, channel }) => {
  if (channel !== "google") {
    return null;
  }

  return (
    <FieldWrapper>
      <Row>
        <Col xs={12} lg={4}>
          <Checkbox
            inline
            number={`${adId || "create"}-eli`}
            label="enable local inventory"
            checked={state.googleAdditionalFields.enableLocal}
            disabled={false}
            onClick={(e) => {
              onChange({
                googleAdditionalFields: {
                  ...state.googleAdditionalFields,
                  enableLocal: !state.googleAdditionalFields.enableLocal,
                },
              });
            }}
          />
          <FormGroupTile
            formControlClass="text-start"
            type="formcontrol"
            label={"Brand inventory filter"}
            formControlType={"text"}
            stateName={"inventoryFilterBrand"}
            handleChildFormElement={(key, value) => onChange({ [key]: value })}
            placeholder={"brand"}
            defaultValue={state.inventoryFilterBrand}
            disabled={false}
          />
        </Col>
      </Row>
    </FieldWrapper>
  );
};

const ProductLabel = ({ product }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "50px auto auto",
        alignContent: "center",
        borderColor: "#FF0000",
      }}
    >
      <div style={{ maxWidth: "50px", display: "flex", alignItems: "center" }}>
        <ProductImage id={product.value} width="50px" height="50px" />{" "}
      </div>
      <div style={{ padding: "10px" }}>
        <span style={{ marginLeft: "1.5rem", marginBottom: "-.25rem" }}>
          {product.sku}
        </span>
        <span
          style={{
            marginLeft: "1.5rem",
            marginTop: "-.05rem",
            fontSize: "1.1rem",
            display: "block",
            textTransform: "none",
            whiteSpace: "pre-line",
          }}
        >
          {product.product_title}
        </span>
      </div>
    </div>
  );
};

const BrandLogoLabel = ({ logo }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "35px auto",
        alignContent: "center",
        borderColor: "#FF0000",
      }}
    >
      <div style={{ maxWidth: "35px", display: "flex", alignItems: "center" }}>
        <img
          src={logo.url}
          alt={""}
          style={{ height: "30px", width: "30px" }}
        />
      </div>
      <div style={{ padding: "10px" }}>
        <span style={{ marginLeft: "1.5rem", marginBottom: "-.25rem" }}>
          {logo.label}
        </span>
      </div>
    </div>
  );
};

export const ProductsField = ({
  state,
  onChange,
  adId,
  products,
  user,
  channel,
  errors,
  fieldsToValidate,
  review,
  isModal,
}) => {
  if (
    ![
      AD_TYPES.SPONSORED_PRODUCT.value,
      AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
      AD_TYPES.SPONSORED_PRODUCT_GENERIC.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      AD_TYPES.SPONSORED_DISPLAY.value,
      AD_TYPES.SPONSORED_DISPLAY_V2.value,
      AD_TYPES.GOOGLE_SHOPPING_AD.value,
      AD_TYPES.GOOGLE_SHOPPING_AD_LOW_COHESIVE.value,
    ].includes(state.adType) &&
    !state.canHaveChildren &&
    state.channel !== "walmart"
  ) {
    return null;
  }
  if (
    state.adType === AD_TYPES.SPONSORED_BRAND.value ||
    (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
      state.storeLandingPage)
  ) {
    return null;
  }

  const onProductsChange = (key, value) => {
    onChange({
      [key]: value.filter((p) => !p.isDisabled).map((p) => p.value),
    });
  };

  const productOptions = products.map((p) => ({
    ...p,
    label: <ProductLabel product={p} />,
  }));

  return (
    <div style={{ width: adId ? "100%" : "inherit" }}>
      <FieldWrapper>
        <Row style={{ marginTop: review ? 0 : "2rem" }}>
          <Col md={12}>
            <ProductSelector
              allOption={{
                value: "*",
                label: "Select All",
                sku: "",
                asin: "",
              }}
              allowSelectAll={
                [
                  AD_TYPES.SPONSORED_PRODUCT.value,
                  AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
                  AD_TYPES.SPONSORED_PRODUCT_GENERIC.value,
                  AD_TYPES.SPONSORED_DISPLAY.value,
                  AD_TYPES.SPONSORED_BRAND.value,
                  AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                  AD_TYPES.GOOGLE_SHOPPING_AD.value,
                  AD_TYPES.GOOGLE_SHOPPING_AD_LOW_COHESIVE.value,
                ].includes(state.adType) || state.canHaveChildren
              }
              multi={true}
              contained={true}
              state={state}
              stateName={"product"}
              handleChildFormElement={onProductsChange}
              label={"Select at least 1 Product to Advertise"}
              disabled={
                adId === undefined
                  ? false
                  : state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
              }
              defaultValue={productOptions.filter(
                (p) => state.product?.find((id) => id === p.value) ?? []
              )}
              productOptions={productOptions}
              user={user}
              channel={channel}
              modalTitle={
                state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                  ? "Select Creative Products"
                  : "Select Media Plan Products"
              }
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              review={review}
              isModal={isModal}
            />
          </Col>
        </Row>
      </FieldWrapper>
    </div>
  );
};

const LandingPageField = ({
  state,
  onChange,
  adId,
  storeOptions,
  errors,
  fieldsToValidate,
}) => {
  if (state.adType !== AD_TYPES.SPONSORED_BRAND.value) {
    return null;
  }

  return (
    <>
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col xs={12}>
          <div style={{ marginBottom: "2rem" }}>
            <FormLabel>
              <h6
                className="title"
                style={{
                  color: "#73706E",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                Campaign Landing Page
                <ToolTips toolTip={LANDING_PAGE_TOOLTIP} position="top" />
              </h6>
            </FormLabel>
          </div>
          <Row style={{ display: "flex" }}>
            <Col xs={6} className="pr-3">
              <Checkbox
                inline
                number={`${adId || "create"}-newproduct`}
                label="Product Collection"
                checked={state.newProductCollection}
                disabled={adId !== undefined || state.id !== undefined}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange({
                    newProductCollection: !state.newProductCollection,
                    storeLandingPage: !state.newProductCollection,
                    bidStrategy:
                      SB_BID_STRATEGY_OPTIONS.MAXIMIZE_IMMEDIATE_SALES.value,
                    newLandingPage: false,
                    storeSpotlight: false,
                    headline: "",
                    selectedProducts: [],
                    selectedAsins: [],
                    selectedStorePageProducts: [],
                    selectedStorePageAsins: [],
                    selectedLandingPageProducts: [],
                    selectedLandingPageAsins: [],
                    selectedCreativeProducts: [],
                    selectedCreativeAsins: [],
                    store: "",
                    page: "",
                    logo: "",
                    landingPageProduct: [],
                    storePageProduct: [],
                    creativeProduct: [],
                  });
                }}
              />
            </Col>
            <Col xs={6} className="pr-3">
              <Checkbox
                inline
                number={`${adId || "create"}-storespotlight`}
                label="Store Spotlight"
                checked={state.storeSpotlight}
                disabled={adId !== undefined || state.id !== undefined}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange({
                    storeSpotlight: !state.storeSpotlight,
                    bidStrategy:
                      SB_BID_STRATEGY_OPTIONS.MAXIMIZE_IMMEDIATE_SALES.value,
                    lifestyleImage: null,
                    lifestyleImageDetails: null,
                    newProductCollection: false,
                    newLandingPage: false,
                    storeLandingPage: false,
                    headline: "",
                    selectedProducts: [],
                    selectedAsins: [],
                    selectedStorePageProducts: [],
                    selectedStorePageAsins: [],
                    selectedLandingPageProducts: [],
                    selectedLandingPageAsins: [],
                    selectedCreativeProducts: [],
                    selectedCreativeAsins: [],
                    store: "",
                    page: "",
                    logo: "",
                    landingPageProduct: [],
                    storePageProduct: [],
                    creativeProduct: [],
                  });
                }}
              />
            </Col>
          </Row>
          {errors.landingPage && fieldsToValidate?.includes("landingPage") && (
            <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
              {errors.landingPage}
            </small>
          )}
        </Col>
      </Row>
    </>
  );
};

const VideoAdTypeField = ({
  state,
  onChange,
  adId,
  storeOptions,
  errors,
  fieldsToValidate,
  categories,
  dashboard,
  review,
}) => {
  if (state.adType !== AD_TYPES.SPONSORED_BRAND_VIDEO.value) {
    return null;
  }

  return (
    <>
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col xs={12}>
          <div style={{ marginBottom: "2rem" }}>
            <FormLabel>
              <h6
                className="title"
                style={{
                  color: "#73706E",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                Video Ad Type
                <ToolTips toolTip={VIDEO_AD_TYPE_TOOLTIP} position="top" />
              </h6>
            </FormLabel>
          </div>
          <Row>
            {[VIDEO_AD_TYPES.VIDEO, VIDEO_AD_TYPES.BRAND_VIDEO].map(
              (videoAdType) => {
                return (
                  <Col xs={12} lg={4}>
                    <Checkbox
                      inline
                      number={`${adId || "create"}-videoadtype-${
                        videoAdType.value
                      }`}
                      label={videoAdType.label}
                      data-value={videoAdType.value}
                      checked={state.videoAdType === videoAdType.value}
                      onClick={(e) => {
                        e.stopPropagation();

                        onChange({
                          videoAdType: videoAdType.value,
                          bidStrategy:
                            SB_BID_STRATEGY_OPTIONS.MAXIMIZE_IMMEDIATE_SALES
                              .value,
                          storeLandingPage:
                            videoAdType.value ===
                            VIDEO_AD_TYPES.BRAND_VIDEO.value,
                        });
                      }}
                    />
                  </Col>
                );
              }
            )}
          </Row>
          {errors.landingPage && fieldsToValidate?.includes("landingPage") && (
            <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
              {errors.videoAdType}
            </small>
          )}
        </Col>
      </Row>
      {state.storeLandingPage && (
        <Row>
          <Col xs={12} lg={6} className="pb-3">
            <FormGroupTile
              type="select"
              multi={false}
              stateName={"categoryId"}
              handleChildFormElement={(key, value) =>
                onChange({ categoryId: value.value })
              }
              label={"Select a Category"}
              defaultValue={categories.find(
                (c) => c.value === state.categoryId
              )}
              options={categories}
              disabled={!dashboard || review}
            />
            {errors.categoryId && fieldsToValidate?.includes("categoryId") && (
              <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
                {errors.categoryId}
              </small>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export const LandingPageDetailsField = ({
  state,
  onChange,
  adId,
  review,
  user,
  categories,
  brandOptions,
  errors,
  fieldsToValidate,
  dashboard,
  isModal,
}) => {
  const reviewState = adId || state.id || review;

  if (
    state.adType !== AD_TYPES.SPONSORED_BRAND.value &&
    !(
      state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
      state.headline &&
      reviewState
    ) &&
    !state.id
  ) {
    return null;
  }

  return (
    <Row style={{ marginTop: "2rem", marginBottom: "0" }}>
      {!(user?.organization_type === "vendor" && brandOptions?.length === 0) ? (
        <>
          <Col xs={6} className="pr-5">
            <FormGroupTile
              type={reviewState ? "formcontrol" : "select"}
              stateName={"brand"}
              handleChildFormElement={(key, value) => {
                onChange({ [key]: value, creativeBrandName: value?.label });
              }}
              label={reviewState ? "Brand" : "Select a Brand"}
              labelClassName={"fs-4"}
              disabled={reviewState}
              defaultValue={reviewState ? state.brand?.label : state.brand}
              options={brandOptions}
              selectClasses={reviewState ? "lightly-disabled" : null}
            />
            {errors.brand && fieldsToValidate?.includes("brand") && (
              <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
                {errors.brand}
              </small>
            )}
          </Col>
          {!reviewState ? (
            <Col xs={6} className="pl-5">
              <FormGroupTile
                type="select"
                multi={false}
                stateName={"categoryId"}
                handleChildFormElement={(key, value) =>
                  onChange({ categoryId: value.value })
                }
                label={"Select a Category"}
                defaultValue={categories.find(
                  (c) => parseInt(c.value) === parseInt(state.categoryId)
                )}
                options={categories}
                disabled={!dashboard || review}
              />
              {errors.categoryId &&
                fieldsToValidate?.includes("categoryId") && (
                  <small
                    className="text-danger"
                    style={{ paddingLeft: ".8rem" }}
                  >
                    {errors.categoryId}
                  </small>
                )}
            </Col>
          ) : (
            <>
              {(state.adType === AD_TYPES.SPONSORED_BRAND.value ||
                (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                  state.headline)) && (
                <Col xs={6} className="pl-5">
                  <FormGroupTile
                    type="formcontrol"
                    label={"Ad Headline"}
                    labelClassName={"fs-4"}
                    formControlType={"string"}
                    stateName={"headline"}
                    handleChildFormElement={(key, value) =>
                      onChange({ [key]: value })
                    }
                    disabled={
                      (reviewState && state.adType) ||
                      (state.id &&
                        isModal &&
                        state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                        state.advModerationStatus ===
                          MODERATION_STATUSES.PENDING.value)
                    }
                    placeholder={"Headline"}
                    defaultValue={state.headline}
                  />
                </Col>
              )}
            </>
          )}
        </>
      ) : null}
    </Row>
  );
};

export const StoreLandingPageFields = ({
  state,
  onChange,
  review,
  adId,
  storeOptions,
  pageOptions,
  brandOptions,
  errors,
  fieldsToValidate,
  reviewChildren,
}) => {
  if (
    ![
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
    ].includes(state.adType) ||
    (!state.storeLandingPage && !state.storeSpotlight)
  ) {
    return null;
  }

  const reviewState = adId || state.id || review;

  if (
    reviewState &&
    state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
    state.landingPageProduct?.length
  ) {
    return null;
  }
  return (
    <>
      <Row
        style={{ marginBottom: "1rem" }}
        className={
          reviewState && state.headline
            ? [
                AD_TYPES.SPONSORED_BRAND.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO.value,
              ].includes(state.adType) &&
              reviewState &&
              !state.store?.label
              ? "my-0"
              : "my-5"
            : reviewState
            ? "mt-5"
            : "pr-3"
        }
      >
        {state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
          !(reviewState && state.headline) && (
            <Col md={6} className={reviewState ? "pr-5 mb-5" : "pr-5"}>
              <FormGroupTile
                type="select"
                stateName={"brand"}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: value, creativeBrandName: value?.label })
                }
                label={reviewState ? "Brand" : "Choose a Brand"}
                disabled={reviewState}
                defaultValue={state.brand}
                options={brandOptions}
                selectClasses={reviewState ? "lightly-disabled" : null}
                components={
                  reviewState
                    ? {
                        Menu: () => null,
                        MenuList: () => null,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }
                    : {}
                }
              />
              {errors.brand && fieldsToValidate?.includes("brand") && (
                <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
                  {errors.brand}
                </small>
              )}
            </Col>
          )}
        <Col
          md={6}
          className={
            state.storeSpotlight
              ? `pr-5 ${reviewState ? "mt-3" : "mt-3"}`
              : state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                state.storeLandingPage &&
                !(reviewState && state.headline)
              ? "pl-5"
              : "pr-5"
          }
          hidden={
            [
              AD_TYPES.SPONSORED_BRAND.value,
              AD_TYPES.SPONSORED_BRAND_VIDEO.value,
            ].includes(state.adType) &&
            reviewState &&
            !state.store?.label
          }
        >
          <FormGroupTile
            type={reviewState ? "formcontrol" : "select"}
            stateName={"store"}
            handleChildFormElement={(key, value) => onChange({ [key]: value })}
            isClearable={
              state.newProductCollection ||
              state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
            }
            label={
              reviewState
                ? "Store"
                : `Choose a Store ${
                    state.newProductCollection ||
                    state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                      ? "(optional)"
                      : ""
                  }`
            }
            labelClassName={"fs-4"}
            disabled={reviewState}
            defaultValue={reviewState ? state.store?.label : state.store}
            options={storeOptions}
          />
          {errors.store && fieldsToValidate?.includes("store") && (
            <div className="mt-3 pl-1">
              <small className="text-danger">{errors.store}</small>
            </div>
          )}
        </Col>

        <Col
          md={6}
          className={
            state.storeSpotlight
              ? `pl-5 ${reviewState ? "mt-3" : "mt-3"}`
              : state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                state.storeLandingPage &&
                !(reviewState && state.headline)
              ? reviewState
                ? "pr-5"
                : "pr-5 mt-5"
              : "pl-5"
          }
          hidden={
            ([
              AD_TYPES.SPONSORED_BRAND.value,
              AD_TYPES.SPONSORED_BRAND_VIDEO.value,
            ].includes(state.adType) &&
              reviewState &&
              !state.page?.label) ||
            state.storeSpotlight
          }
        >
          <FormGroupTile
            type={reviewState ? "formcontrol" : "select"}
            stateName={"page"}
            handleChildFormElement={(key, value) => onChange({ [key]: value })}
            isClearable={
              state.newProductCollection ||
              state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
            }
            label={
              reviewState
                ? "Store Page"
                : state.storeSpotlight
                ? "Choose a Landing Page"
                : `Choose a Page ${
                    state.newProductCollection ||
                    state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                      ? "(optional)"
                      : ""
                  }`
            }
            labelClassName={"fs-4"}
            disabled={reviewState}
            defaultValue={reviewState ? state.page?.label : state.page}
            options={pageOptions}
          />
        </Col>
        {reviewChildren && reviewChildren}
      </Row>
    </>
  );
};

export const VideoField = ({
  state,
  onChange,
  errors,
  review,
  fieldsToValidate,
  savedVideos,
  videoOptions,
  brandOptions,
  user,
  adId,
}) => {
  if (
    state.adType !== AD_TYPES.SPONSORED_BRAND_VIDEO.value ||
    state.storeLandingPage
  ) {
    return null;
  }
  const reviewState = adId || state.id || review;

  let videoCreativeOptions = state.videoDetails
    ? [state.videoDetails]?.map((asset) => {
        return {
          value: asset.id,
          url: asset.url,
          label: <AssetLabel asset={asset} />,
        };
      })
    : [];

  return (
    <>
      <div>
        {!adId && (
          <Row>
            {!(
              user?.organization_type === "vendor" && brandOptions.length === 0
            ) ? (
              <Col md={6} className={reviewState ? "pr-5 mb-5" : "pr-5"}>
                <FormGroupTile
                  type="select"
                  stateName={"brand"}
                  handleChildFormElement={(key, value) =>
                    onChange({ [key]: value, creativeBrandName: value?.label })
                  }
                  label={reviewState ? "Brand" : "Choose a Brand"}
                  disabled={reviewState}
                  defaultValue={state.brand}
                  options={brandOptions}
                  selectClasses={reviewState ? "lightly-disabled" : null}
                  components={
                    reviewState
                      ? {
                          Menu: () => null,
                          MenuList: () => null,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }
                      : {}
                  }
                />
                {errors.brand && fieldsToValidate?.includes("brand") && (
                  <small
                    className="text-danger"
                    style={{ paddingLeft: ".8rem" }}
                  >
                    {errors.brand}
                  </small>
                )}
              </Col>
            ) : null}
            <Col
              md={
                !(
                  user?.organization_type === "vendor" &&
                  brandOptions.length === 0
                )
                  ? 6
                  : 12
              }
              className={adId ? "pl-0 mb-5" : "pl-5"}
              hidden={reviewState}
            >
              <FormGroupTile
                type="select"
                stateName={"video"}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: value.value })
                }
                label={
                  reviewState ? "Video Creative" : "Choose An Existing Video"
                }
                disabled={true}
                defaultValue={videoCreativeOptions?.[0]}
                isClearable={!reviewState}
                options={savedVideos}
                selectClasses={"lightly-disabled"}
                components={{
                  Menu: () => null,
                  MenuList: () => null,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
              {errors.video && fieldsToValidate?.includes("video") && (
                <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
                  {errors.video}
                </small>
              )}
            </Col>
            {state.brand && (
              <Col md={6} className="pr-5 pt-4">
                <FormGroupTile
                  type="formcontrol"
                  label={`Creative Brand Name`}
                  labelClassName={"fs-5"}
                  formControlType={"string"}
                  stateName={"creativeBrandName"}
                  handleChildFormElement={(key, value) =>
                    onChange({ [key]: value })
                  }
                  disabled={reviewState}
                  placeholder={"Brand Display Name"}
                  defaultValue={state.creativeBrandName}
                />
                <div style={{ textAlign: "left" }} className="pt-3">
                  {errors.creativeBrandName &&
                    fieldsToValidate?.includes("creativeBrandName") && (
                      <small
                        className="text-danger d-block"
                        style={{ paddingLeft: ".8rem" }}
                      >
                        {errors.creativeBrandName}
                      </small>
                    )}
                </div>
              </Col>
            )}
          </Row>
        )}
      </div>
    </>
  );
};

export const CreativeField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  adId,
  logoOptions,
  optional,
  creativesAssetSelection,
  review,
  isModal,
}) => {
  const showCreative =
    (!adId &&
      (state.adType === AD_TYPES.SPONSORED_BRAND.value ||
        (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
          state.storeLandingPage))) ||
    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value;

  if (!showCreative) {
    return null;
  }

  const reviewState = review || state.id || adId;

  const brandLogoOptions = logoOptions?.map((l) => {
    return {
      ...l,
      label: <BrandLogoLabel logo={l} />,
    };
  });

  if (adId && creativesAssetSelection && !state.headline && !state.logo) {
    return null;
  }
  return (
    <>
      <div style={{ textAlign: "left" }}>
        {errors.creativeAssets &&
          fieldsToValidate?.includes("creativeAssets") && (
            <small className="text-danger">{errors.creativeAssets}</small>
          )}
      </div>
      <div>
        <Row
          style={{ marginTop: "2rem" }}
          hidden={reviewState && !state.headline}
        >
          <Col md={6} className="pr-5">
            <FormGroupTile
              type="formcontrol"
              label={`Ad Headline ${optional ? "(optional)" : ""}`}
              labelClassName={"fs-4"}
              formControlType={"string"}
              stateName={"headline"}
              handleChildFormElement={(key, value) =>
                onChange({ [key]: value })
              }
              disabled={
                (reviewState &&
                  state.adType !== AD_TYPES.SPONSORED_DISPLAY_V2.value) ||
                (state.id &&
                  state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                  state.advModerationStatus ===
                    MODERATION_STATUSES.PENDING.value)
              }
              placeholder={"Headline"}
              defaultValue={state.headline}
            />
            <div style={{ textAlign: "left" }} className="pt-3">
              {errors.headline && fieldsToValidate?.includes("headline") && (
                <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
                  {errors.headline}
                </small>
              )}
            </div>
          </Col>
          <Col md={6} className="pl-5">
            {(!reviewState ||
              (state.id &&
                state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                state.advModerationStatus !==
                  MODERATION_STATUSES.PENDING.value &&
                isModal)) && (
              <FormGroupTile
                type="select"
                stateName={"logo"}
                handleChildFormElement={(key, value) => {
                  if (creativesAssetSelection && !value) {
                    onChange({
                      [key + "Details"]: null,
                      [key]: null,
                      creativeAsset: null,
                    });
                    return;
                  }
                  onChange({ [key]: value?.value });
                }}
                disabled={
                  (state.id !== undefined || adId) &&
                  state.advModerationStatus ===
                    MODERATION_STATUSES.PENDING.value
                }
                label={`Brand Logo ${
                  ![
                    AD_TYPES.SPONSORED_BRAND.value,
                    AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                  ].includes(state.adType)
                    ? "(optional)"
                    : ""
                }`}
                labelClassName={"fs-4"}
                selectClasses="lightly-disabled"
                defaultValue={brandLogoOptions?.[0]}
                options={brandLogoOptions}
                isClearable={creativesAssetSelection && !review}
                isSearchable={!creativesAssetSelection}
                // Below two props used to display read-only field without placeholder or dropdown caret buttin
                components={
                  creativesAssetSelection
                    ? {
                        Menu: () => null,
                        MenuList: () => null,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }
                    : {}
                }
                placeholder={creativesAssetSelection ? <></> : "Select..."}
                onClick={(e) => {
                  onChange({ assetSelectionType: "logo" });
                }}
              />
            )}
            {errors.logo && fieldsToValidate?.includes("logo") && (
              <div className="pt-4">
                <small className="text-danger">{errors.logo}</small>
              </div>
            )}
          </Col>
          <Col md={6} className="pr-5 pt-3">
            <FormGroupTile
              type="formcontrol"
              label={`Creative Brand Name`}
              labelClassName={"fs-4"}
              formControlType={"string"}
              stateName={"creativeBrandName"}
              handleChildFormElement={(key, value) =>
                onChange({ [key]: value })
              }
              disabled={reviewState}
              placeholder={"Brand Display Name"}
              defaultValue={state.creativeBrandName}
            />
            <div style={{ textAlign: "left" }} className="pt-3">
              {errors.creativeBrandName &&
                fieldsToValidate?.includes("creativeBrandName") && (
                  <small
                    className="text-danger d-block"
                    style={{ paddingLeft: ".8rem" }}
                  >
                    {errors.creativeBrandName}
                  </small>
                )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const CreativeProductsField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  adId,
  logoOptions,
  storePageProducts,
  user,
  review,
  isModal,
}) => {
  if (
    (![
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
    ].includes(state.adType) ||
      !state.storeLandingPage) &&
    !(
      state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
      state.logo &&
      !state.store?.label
    )
  ) {
    return null;
  }

  if (
    state.newProductCollection ||
    (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
      state.storeLandingPage)
  ) {
    return null;
  }

  const productOptions = storePageProducts
    .filter((p) => state.page?.products?.find((id) => id === p.value))
    .map((p) => ({
      ...p,
      label: <ProductLabel product={p} />,
    }));

  const creativeAsins = Array.from(
    new Set(
      productOptions
        .filter((p) => state.creativeProduct.find((c) => p.value === c))
        .map((p) => p.asin)
    )
  );

  return (
    <div style={{ width: adId ? "100%" : "inherit" }}>
      <Row style={{ marginTop: review ? 0 : "2rem" }}>
        <Col md={12}>
          <ProductSelector
            state={state}
            stateName={"creativeProduct"}
            allOption={{
              value: "*",
              label: "Select All",
              sku: "",
              asin: "",
            }}
            productOptions={productOptions}
            onChange={onChange}
            adId={adId}
            defaultValue={productOptions.filter((p) =>
              state.creativeProduct.find((id) => id === p.value)
            )}
            handleChildFormElement={(key, value) =>
              onChange({ [key]: value.map((p) => p.value) })
            }
            disabled={adId !== undefined || state.id !== undefined}
            user={user}
            modalTitle={"Select Creative Products"}
            errors={errors}
            fieldsToValidate={fieldsToValidate}
            review={review}
            isModal={isModal}
            isCreativeProductSelector={true}
          />
          {!state.id && creativeAsins.length < state.creativeProduct.length && (
            <>
              <small
                className="text-danger fw-normal"
                style={{ marginLeft: ".8rem", textTransform: "none" }}
              >
                You have selected duplicate asins to be displayed in the
                Creative.
              </small>
              <br />
              <small
                className="text-danger fw-normal"
                style={{ marginLeft: ".8rem", textTransform: "none" }}
              >
                Remove all duplicate asins.
              </small>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export const LandingPageProductsField = ({
  state,
  onChange,
  landingPageProducts,
  adId,
  user,
  errors,
  fieldsToValidate,
  review,
  isModal,
}) => {
  if (
    ![
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
    ].includes(state.adType) ||
    (!state.newLandingPage &&
      !state.newProductCollection &&
      !state.storeSpotlight)
  ) {
    if (
      (!state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
        !state.storeLandingPage) ||
      (state.adType === AD_TYPES.SPONSORED_BRAND.value &&
        (!state.newLandingPage ||
          (state.newProductCollection && state.page?.label))) ||
      (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
        !state.storeLandingPage) ||
      ![
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      ].includes(state.adType)
    ) {
      return null;
    }
  }

  if (
    ((state.adType === AD_TYPES.SPONSORED_BRAND.value &&
      state.newProductCollection &&
      state.page?.label) ||
      state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value) &&
    state.page
  ) {
    return null;
  }

  const onProductsChange = (key, value) => {
    onChange({
      landingPageProduct: value
        .filter((p) => !p.isDisabled)
        .map((p) => p.value),
    });
  };

  const productOptions = landingPageProducts.map((p) => ({
    ...p,
    label: <ProductLabel product={p} />,
  }));

  return (
    <div style={{ width: adId ? "100%" : "inherit" }}>
      <div>
        <Row style={{ marginTop: review ? 0 : "2rem" }}>
          <Col md={12}>
            <ProductSelector
              multi={true}
              state={state}
              stateName={"landingPageProduct"}
              allOption={{
                value: "*",
                label: "Select All",
                sku: "",
                asin: "",
              }}
              contained={true}
              handleChildFormElement={onProductsChange}
              label={"Select at least 3 products for the new landing page"}
              disabled={adId !== undefined || state.id !== undefined}
              defaultValue={productOptions.filter((p) =>
                state.landingPageProduct.find((id) => id === p.value)
              )}
              productOptions={productOptions}
              user={user}
              modalTitle={"Select Landing Page Products"}
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              isLandingPageProductSelector={true}
              review={review}
              isModal={isModal}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ProductSelector = ({
  state,
  errors,
  fieldsToValidate,
  handleChildFormElement,
  isCreativeProductSelector,
  isLandingPageProductSelector,
  isSubpageProductSelector,
  productOptions,
  modalTitle,
  review,
  isModal,
  user,
  preselectedProducts,
  channel,
  hideTagsInput = false,
}) => {
  const [inventoryStateFilter, setInventoryStateFilter] = useState({
    value: "all",
    label: "All Inventory States",
  });
  const [salesStateFilter, setSalesStateFilter] = useState({
    value: "all",
    label: "All Sales States",
  });
  const [walmartPrimaryVariantFilter, setWalmartPrimaryVariantFilter] =
    useState(
      [
        AD_TYPES.SPONSORED_BRAND_WALMART.value,
        AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
      ].includes(state.adType)
    );

  return (
    <>
      {errors?.product && fieldsToValidate?.includes("product") && (
        <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
          {errors?.product}
        </small>
      )}
      {errors?.landingPageProduct &&
        fieldsToValidate?.includes("landingPageProduct") &&
        isLandingPageProductSelector && (
          <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
            {errors?.landingPageProduct}
          </small>
        )}
      {errors?.creativeProduct &&
        fieldsToValidate?.includes("creativeProduct") &&
        isCreativeProductSelector && (
          <small className="text-danger" style={{ paddingLeft: ".8rem" }}>
            {errors?.creativeProduct}
          </small>
        )}
      <DetailedProductSelector
        selectorId={"mediaPlan"}
        selectedCategory={state.categoryId}
        state={state}
        hideTagsInput={hideTagsInput}
        isCreativeProductSelector={isCreativeProductSelector}
        isLandingPageProductSelector={isLandingPageProductSelector}
        isSubpageProductSelector={isSubpageProductSelector}
        singleProductSelection={isSubpageProductSelector}
        modalTitle={modalTitle}
        renderInModal={false}
        review={review}
        isModal={isModal}
        getPreselectedProducts={() => {
          let selectedProducts = preselectedProducts
            ? preselectedProducts
            : isCreativeProductSelector
            ? state.creativeProduct ?? []
            : (state.adType === AD_TYPES.SPONSORED_BRAND.value &&
                (state.newLandingPage ||
                  state.newProductCollection ||
                  state.storeSpotlight)) ||
              (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                state.storeLandingPage)
            ? state.landingPageProduct ?? []
            : state.adType === AD_TYPES.SPONSORED_BRAND.value &&
              state.storeLandingPage
            ? state.storePageProduct ?? []
            : state.product ?? [];

          return selectedProducts ?? [];
        }}
        mediaPlanSelector={true}
        updateSelectedProducts={() => {
          let updatedProductSelections = isCreativeProductSelector
            ? state.creativeProduct
            : (state.adType === AD_TYPES.SPONSORED_BRAND.value &&
                (state.newLandingPage ||
                  state.newProductCollection ||
                  state.storeSpotlight)) ||
              (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                state.storeLandingPage)
            ? state.landingPageProduct
            : state.product;

          return updatedProductSelections;
        }}
        updateSelectedProductDependencies={[
          state.product,
          state.landingPageProduct,
          state.creativeProduct,
        ]}
        fetchAllProducts={[
          AD_TYPES.SPONSORED_BRAND_WALMART.value,
          AD_TYPES.SPONSORED_BRAND.value,
          AD_TYPES.SPONSORED_BRAND_V2.value,
        ].includes(state.adType)}
        processTableData={(filteredData, productData, selectedProducts) => {
          let processedTableData = filteredData
            ? filteredData
            : isCreativeProductSelector
            ? productData
              ? productData.filter((d) => {
                  if (state.id && !isModal) {
                    return state.creativeProduct?.find((id) => d.id === id);
                  }
                  if (
                    state.storeLandingPage ||
                    state.storeSpotlight ||
                    (state.newProductCollection && state.page?.value)
                  ) {
                    if (review) {
                      return state.creativeProduct?.find((id) => d.id === id);
                    }
                    return productOptions.find((p) => p.value === d.id);
                  }
                  return state.landingPageProduct?.find((id) => d.id === id);
                })
              : []
            : productData
            ? review
              ? productData
                  ?.map((p) => (p.is_parent ? p.children : p))
                  ?.flat()
                  ?.filter((p) => selectedProducts?.includes(p.id))
              : productData?.map((p) => (p.is_parent ? p.children : p)).flat()
            : [];

          return processedTableData;
        }}
        reprocessDataDependencies={[
          inventoryStateFilter.value,
          state.id,
          state.creativeProduct,
          state.landingPageProduct,
          state.storeLandingPage,
          state.newProductCollection,
          state.page,
          productOptions,
          walmartPrimaryVariantFilter,
        ]}
        disableSelectAllCheckbox={(selectedProducts, productData) => {
          return false;
        }}
        disableRowCheckbox={(props) => {
          let disabled =
            (isModal && review) ||
            (state.id &&
              (isCreativeProductSelector
                ? state.creativeProduct.some(
                    (p) => p === props.row._original.id
                  )
                : isLandingPageProductSelector
                ? state.landingPageProduct.some(
                    (p) => p === props.row._original.id
                  )
                : state.product.some((p) => p === props.row._original.id)));
          return disabled;
        }}
        hideSelectAll={isSubpageProductSelector}
        assessChecked={(
          selectedProducts,
          productData,
          filteredData,
          assessSelectAllCheck = false
        ) => {
          if (isSubpageProductSelector) {
            return false;
          }

          if (assessSelectAllCheck) {
            // For creatives, check if all from subset of ad products are selected
            let selectAllChecked = isCreativeProductSelector
              ? selectedProducts?.length === state.landingPageProduct?.length
              : filteredData
              ? filteredData?.every((p) => selectedProducts?.includes(p.id))
              : selectedProducts?.length >=
                productData?.map((p) => (p.is_parent ? p.children : p)).flat()
                  ?.length;

            return selectAllChecked;
          }
        }}
        hideClearAll={state.id || review || isSubpageProductSelector}
        saveProductSelections={(selectedProducts) => {
          let key = isCreativeProductSelector
            ? "creativeProduct"
            : isLandingPageProductSelector
            ? "landingPageProduct"
            : "product";

          handleChildFormElement(
            key,
            selectedProducts?.map((p) => ({
              value: p,
            }))
          );
        }}
        handleProductSelection={(
          selectedProducts,
          productData,
          filteredData,
          setSelectedProducts,
          searchFilter,
          resolvedTableData,
          filtered
        ) => {
          // Handle different types of select all/remove all based on filtered state
          if (isCreativeProductSelector) {
            if (selectedProducts?.length === state.landingPageProduct?.length) {
              setSelectedProducts([]);
            } else {
              setSelectedProducts(state.landingPageProduct ?? []);
            }
          } else if (filtered.length > 0) {
            let combined = [
              ...selectedProducts,
              ...resolvedTableData?.map((row) => row._original?.id),
            ];

            // Remove visible products if all filtered are currently selected
            if (
              resolvedTableData
                ?.map((row) => row._original?.id)
                ?.every((id) => selectedProducts?.includes(id))
            ) {
              setSelectedProducts(
                selectedProducts.filter(
                  (id) =>
                    !resolvedTableData
                      ?.map((row) => row._original?.id)
                      ?.find((row) => row === id) ?? []
                )
              );
            } else {
              setSelectedProducts([...new Set(combined)] ?? []);
            }
          } else if (
            searchFilter.length > 0 ||
            inventoryStateFilter?.value !== "all"
          ) {
            let combined = [
              ...selectedProducts,
              ...filteredData.map((p) => p.id),
            ];

            if (
              filteredData
                ?.map((row) => row?.id)
                ?.every((id) => selectedProducts?.includes(id))
            ) {
              setSelectedProducts(
                selectedProducts.filter(
                  (id) =>
                    !filteredData
                      ?.map((row) => row?.id)
                      ?.find((row) => row === id) ?? []
                )
              );
            } else {
              setSelectedProducts([...new Set(combined)]);
            }
          } else {
            selectedProducts?.length ===
            productData?.map((p) => (p.is_parent ? p.children : p)).flat()
              ?.length
              ? state.id
                ? setSelectedProducts(state.product)
                : setSelectedProducts([])
              : setSelectedProducts(
                  productData
                    ?.map((p) => (p.is_parent ? p.children : p))
                    .flat()
                    ?.map((p) => p.id) ?? []
                );
          }
          return;
        }}
        onCancel={(setSelectedProducts) =>
          setSelectedProducts(
            isCreativeProductSelector
              ? state.creativeProduct ?? []
              : state.adType === AD_TYPES.SPONSORED_BRAND.value &&
                (state.newLandingPage || state.newProductCollection)
              ? state.landingPageProduct ?? []
              : state.product ?? []
          )
        }
        renderTable={
          !(
            isCreativeProductSelector &&
            !state.id &&
            state.landingPageProduct.length < 1 &&
            (state.newLandingPage || state.newProductCollection)
          ) ||
          (state.newProductCollection && state.page) ||
          (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
            state.page) ||
          isSubpageProductSelector
        }
        tableSubtitle={
          review
            ? null
            : isCreativeProductSelector
            ? state.id
              ? "Creative Products"
              : "Select Creative Products"
            : isLandingPageProductSelector
            ? state.id
              ? "Landing Page Products"
              : "Select Landing Page Products"
            : state.id
            ? "Selected Products"
            : "Select Products to Advertise"
        }
        columnsToRender={
          user?.allowMwsOrders
            ? [
                "select",
                "title",
                "totalSales",
                "adSales",
                "adSpend",
                "acos",
                "totalAcos",
              ]
            : [
                "select",
                "title",
                "adSales",
                "adSpend",
                "acos",
                "clicks",
                "impressions",
              ]
        }
        applySearchFilters={(setFilteredData, productData, searchFilter) => {
          if (isSubpageProductSelector) {
            setFilteredData(
              productData
                ?.map((p) => (p.is_parent ? p.children : p))
                ?.flat()
                ?.filter((p) =>
                  productOptions.map((o) => o.value)?.includes(p.id)
                )
                ?.filter(
                  (row) =>
                    row?.asin
                      ?.toLowerCase()
                      .indexOf(searchFilter.toLowerCase()) > -1 ||
                    row?.sku
                      ?.toLowerCase()
                      .indexOf(searchFilter.toLowerCase()) > -1 ||
                    row?.product_title
                      ?.toLowerCase()
                      .indexOf(searchFilter.toLowerCase()) > -1
                )
                ?.filter((p) => {
                  if (inventoryStateFilter.value !== "all") {
                    return (
                      p?.stock_status === inventoryStateFilter.value ||
                      (p?.stock_status === "IN_STOCK_SCARCE" &&
                        inventoryStateFilter.value === "LOW_STOCK")
                    );
                  }
                  return true;
                })
                ?.filter((p) => {
                  if (salesStateFilter.value !== "all") {
                    return p?.sales_state === salesStateFilter.value;
                  }
                  return true;
                })
            );
            return;
          }
          if (isCreativeProductSelector) {
            if (state.page?.label) {
              setFilteredData(
                productData
                  ?.map((p) => (p.is_parent ? p.children : p))
                  ?.flat()
                  ?.filter(
                    (row) =>
                      (state.page.products?.find((id) => row.id === id) &&
                        row?.asin
                          ?.toLowerCase()
                          .indexOf(searchFilter.toLowerCase()) > -1) ||
                      (state.page.products?.find((id) => row.id === id) &&
                        row?.sku
                          ?.toLowerCase()
                          .indexOf(searchFilter.toLowerCase()) > -1) ||
                      (state.page.products?.find((id) => row.id === id) &&
                        row?.product_title
                          ?.toLowerCase()
                          .indexOf(searchFilter.toLowerCase()) > -1)
                  )
                  ?.filter((p) => {
                    if (inventoryStateFilter.value !== "all") {
                      return (
                        p?.stock_status === inventoryStateFilter.value ||
                        (p?.stock_status === "IN_STOCK_SCARCE" &&
                          inventoryStateFilter.value === "LOW_STOCK")
                      );
                    }
                    return true;
                  })
                  ?.filter((p) => {
                    if (salesStateFilter.value !== "all") {
                      return p?.sales_state === salesStateFilter.value;
                    }
                    return true;
                  })
              );
              return;
            }
            setFilteredData(
              productData
                ?.map((p) => (p.is_parent ? p.children : p))
                ?.flat()
                ?.filter(
                  (row) =>
                    (state.landingPageProduct?.find((id) => row.id === id) &&
                      row?.asin
                        ?.toLowerCase()
                        .indexOf(searchFilter.toLowerCase()) > -1) ||
                    (state.landingPageProduct?.find((id) => row.id === id) &&
                      row?.sku
                        ?.toLowerCase()
                        .indexOf(searchFilter.toLowerCase()) > -1) ||
                    (state.landingPageProduct?.find((id) => row.id === id) &&
                      row?.product_title
                        ?.toLowerCase()
                        .indexOf(searchFilter.toLowerCase()) > -1)
                )
                ?.filter((p) => {
                  if (inventoryStateFilter.value !== "all") {
                    return (
                      p?.stock_status === inventoryStateFilter.value ||
                      (p?.stock_status === "IN_STOCK_SCARCE" &&
                        inventoryStateFilter.value === "LOW_STOCK")
                    );
                  }
                  return true;
                })
                ?.filter((p) => {
                  if (salesStateFilter.value !== "all") {
                    return p?.sales_state === salesStateFilter.value;
                  }
                  return true;
                })
            );
            return;
          }
          setFilteredData(
            productData
              ?.map((p) => (p.is_parent ? p.children : p))
              ?.flat()
              ?.filter(
                (row) =>
                  row?.asin?.toLowerCase().indexOf(searchFilter.toLowerCase()) >
                    -1 ||
                  row?.sku?.toLowerCase().indexOf(searchFilter.toLowerCase()) >
                    -1 ||
                  row?.product_title
                    ?.toLowerCase()
                    .indexOf(searchFilter.toLowerCase()) > -1
              )
              ?.filter((p) => {
                if (inventoryStateFilter.value !== "all") {
                  return (
                    p?.stock_status === inventoryStateFilter.value ||
                    (p?.stock_status === "IN_STOCK_SCARCE" &&
                      inventoryStateFilter.value === "LOW_STOCK")
                  );
                }
                return true;
              })
              ?.filter((p) => {
                if (salesStateFilter.value !== "all") {
                  return p?.sales_state === salesStateFilter.value;
                }
                return true;
              })
              ?.filter((p) => {
                if (walmartPrimaryVariantFilter) {
                  return p?.primary_variant;
                }
                return true;
              })
          );
          return;
        }}
        inventoryStateFilter={inventoryStateFilter}
        setInventoryStateFilter={setInventoryStateFilter}
        salesStateFilter={salesStateFilter}
        setSalesStateFilter={setSalesStateFilter}
        walmartPrimaryVariantFilter={walmartPrimaryVariantFilter}
        setWalmartPrimaryVariantFilter={setWalmartPrimaryVariantFilter}
        disablePrimaryVariantFilter={[
          AD_TYPES.SPONSORED_BRAND_WALMART.value,
          AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
        ].includes(state.adType)}
      />
    </>
  );
};

export const Creative2ProductsField = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  creativeProducts,
  storePageProducts,
  adId,
  review,
  isModal,
}) => {
  const showCreative =
    (state.adType === AD_TYPES.SPONSORED_BRAND.value &&
      (state.newLandingPage || state.newProductCollection)) ||
    (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
      state.storeLandingPage);
  if (!showCreative || state.storeSpotlight) {
    return null;
  }

  const productOptions = state.storeSpotlight
    ? storePageProducts
        ?.filter((p) => p)
        ?.map((p) => {
          return {
            ...p,
            label: <ProductLabel product={p} />,
          };
        })
    : (state.newProductCollection ||
        state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value) &&
      state.page
    ? storePageProducts
        ?.filter((p) => state.page?.products?.find((id) => id === p.value))
        ?.map((p) => ({
          ...p,
          label: <ProductLabel product={p} />,
        }))
    : creativeProducts
        ?.filter((p) => state.landingPageProduct.find((id) => id === p.value))
        ?.map((p) => ({
          ...p,
          label: <ProductLabel product={p} />,
        }));
  const creativeAsins = state.storeSpotlight
    ? productOptions
    : Array.from(
        new Set(
          productOptions
            ?.filter((p) => state.creativeProduct.find((id) => id === p.value))
            ?.map((p) => p.asin)
        )
      );

  return (
    <div style={{ width: adId ? "100%" : "inherit" }}>
      <Row style={{ marginTop: review ? 0 : "2rem" }}>
        <Col md={12}>
          <ProductSelector
            multi={true}
            state={state}
            stateName={"creativeProduct"}
            allOption={{
              value: "*",
              label: "Select All",
              sku: "",
              asin: "",
            }}
            handleChildFormElement={(key, value) => {
              onChange({
                creativeProduct: value.map((p) => p.value),
              });
            }}
            label={"Select at least 1 Product to be Displayed"}
            disabled={adId !== undefined || state.id !== undefined}
            defaultValue={productOptions?.filter((p) => {
              return state.creativeProduct?.find((id) => id === p.value);
            })}
            productOptions={productOptions}
            isCreativeProductSelector={true}
            modalTitle={"Select Creative Products"}
            contained={true}
            errors={errors}
            fieldsToValidate={fieldsToValidate}
            review={review}
            isModal={isModal}
            // Below hides bulk tags input if new SB landing page being created to avoid overwriting subset logic where creative products must be part of new landing page product collection
            hideTagsInput={state.adType === AD_TYPES.SPONSORED_BRAND.value}
          />
          {!state.id && (
            <div style={{ textAlign: "left" }}>
              <br />
              <h6 className="text-danger">
                {creativeAsins?.length &&
                state.creativeProduct?.length &&
                creativeAsins.length < state.creativeProduct.length ? (
                  <>
                    <small
                      className="text-danger fw-normal"
                      style={{ marginLeft: ".8rem", textTransform: "none" }}
                    >
                      You have selected duplicate asins to be displayed in the
                      Creative.
                    </small>
                    <br />
                    <small
                      className="text-danger fw-normal"
                      style={{ marginLeft: ".8rem", textTransform: "none" }}
                    >
                      Remove all duplicate asins.
                    </small>
                  </>
                ) : null}
              </h6>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

const StoreSubpageProductsField = ({
  state,
  onChange,
  errors,
  products,
  fieldsToValidate,
  creativeProducts,
  storePageProducts,
  adId,
  review,
  isModal,
  storeSpotlightSelector,
  storeSpotlightSubpageIndex,
  activeSubpageIndex,
  setActiveSubpageIndex,
  pageOptions,
  preselectedProduct,
}) => {
  let pageUrl = state.storeSpotlightSubpages[storeSpotlightSubpageIndex]?.url;

  const productOptions = useMemo(
    () => {
      let productSubset = storePageProducts
        ?.filter((p) => {
          let pageDetails = pageOptions.find(
            (page) =>
              page.value ===
              state.storeSpotlightSubpages[storeSpotlightSubpageIndex]?.url
          );

          return pageDetails.products?.includes(p?.value);
        })
        // Make sure ASIN isn't already selected for other store subpage
        ?.filter((p) => {
          return !state.storeSpotlightSubpages
            .map((page) => page.asin)
            ?.includes(p.asin);
        })
        ?.map((p) => {
          return {
            ...p,
            label: <ProductLabel product={p} />,
          };
        });
      return productSubset;
      // Update product options when store page changes
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pageUrl,
      pageOptions,
      activeSubpageIndex,
      state.storeSpotlightSubpages,
      storePageProducts,
      storeSpotlightSubpageIndex,
    ]
  );

  return (
    <div style={{ width: adId ? "100%" : "inherit" }}>
      <Row style={{ marginTop: review ? 0 : "2rem" }}>
        <Col
          md={12}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {productOptions.length ? (
            <ProductSelector
              multi={true}
              state={state}
              stateName={"creativeProduct"}
              preselectedProducts={
                preselectedProduct ? [preselectedProduct] : []
              }
              isSubpageProductSelector={true}
              handleChildFormElement={(key, value) => {
                if (!value.length) {
                  return;
                }
                let updatedSubpage =
                  state.storeSpotlightSubpages[storeSpotlightSubpageIndex];
                let originalProduct = updatedSubpage.asin;
                updatedSubpage.asin =
                  products.find(
                    (p) => p.value === value?.[value.length - 1]?.value
                  )?.asin ?? "";
                updatedSubpage.productId = value?.[value.length - 1]?.value;
                let updatedSubpages = state.storeSpotlightSubpages;
                updatedSubpages[storeSpotlightSubpageIndex] = updatedSubpage;
                onChange({ storeSpotlightSubpages: updatedSubpages });

                if (
                  updatedSubpage.asin &&
                  originalProduct !== updatedSubpage.asin
                ) {
                  setActiveSubpageIndex(null);
                }
              }}
              label={"Select at least 1 Product to be Displayed"}
              disabled={adId !== undefined || state.id !== undefined}
              defaultValue={productOptions?.filter((p) => {
                return [];
              })}
              productOptions={productOptions}
              isCreativeProductSelector={true}
              modalTitle={"Select a Creative Product"}
              contained={true}
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              review={review}
              isModal={isModal}
              hideTagsInput={true}
            />
          ) : (
            <div className="d-flex justify-content-center">
              <p className="fs-5 pl-1">
                No products found in this category for the selected landing page
              </p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export const AgreeToArchiveField = ({
  state,
  apiStatus,
  agreeToArchive,
  setAgreeToArchive,
  user,
  adId,
}) => {
  if (
    state.advStatus === STATUS.ARCHIVE.value &&
    user.organization_admin &&
    apiStatus !== 0
  ) {
    return (
      <Row
        key="RowPausedAdvMessage"
        className="text-start"
        style={{ marginBottom: "1rem", marginLeft: ".5rem" }}
      >
        Only a paused advertisement can be archived.
      </Row>
    );
  }

  if (state.advStatus === STATUS.ARCHIVE.value && user.organization_admin) {
    return (
      <Row
        key="RowArchiveCheckbox"
        className="text-start"
        style={{ marginBottom: "1rem", marginLeft: ".5rem" }}
      >
        <Checkbox
          inline
          number={`${adId || "create"}-archive`}
          label="I am aware that this action cannot be undone."
          checked={agreeToArchive}
          disabled={false}
          onClick={(e) => {
            setAgreeToArchive(!agreeToArchive);
          }}
          style={{
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />
      </Row>
    );
  }
  return null;
};

export const AdvancedTargettingButton = ({
  show,
  onChange,
  skipToAdvancedMenu,
  fieldsToValidate,
  errors,
  disabled,
}) => {
  if (show === false) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "2rem",
      }}
    >
      <p
        style={{
          textAlign: "center",
          color: "#6C757D",
          fontSize: "1.5rem",
          fontWeight: 500,
          paddingTop: "1rem",
          margin: "1rem auto 2rem",
          maxWidth: "90%",
        }}
      >
        We pre-selected some of your settings based on your media plan and
        targeting types. If you want to make changes, click the link below. Only
        recommended for experienced users.
      </p>
      <PlainButton
        onClick={(e) => {
          if (disabled) {
            return;
          }

          onChange({
            renderAdvancedOptions: true,
          });
          skipToAdvancedMenu();
        }}
        style={{
          margin: "auto",
          color: disabled ? "#CCC" : trellisPalette[0],
          fontSize: "1.4rem",
          marginBottom: "4rem",
          textTransform: "none",
          textDecoration: "underline",
          letterSpacing: "inherit",
        }}
      >
        Advanced Settings
      </PlainButton>
    </div>
  );
};

const StoreSpotlightSubpageField = ({
  state,
  onChange,
  review,
  adId,
  storeOptions,
  pageOptions,
  brandOptions,
  errors,
  fieldsToValidate,
  subpageIndex,
  storePageProducts,
  products,
  activeSubpageIndex,
  setActiveSubpageIndex,
}) => {
  if (!state.storeSpotlight) {
    return null;
  }

  const reviewState = adId || state.id || review;

  const product = products.find(
    (p) => p.asin === state.storeSpotlightSubpages[subpageIndex]?.asin ?? {}
  );

  let availablePageOptions = pageOptions.filter(
    (page) =>
      !state.storeSpotlightSubpages
        .map((subpage) => subpage?.url)
        ?.includes(page.value) && page.label !== "Home"
  );

  return (
    <>
      <Row style={{ marginBottom: "1rem" }} className={"my-3"}>
        <Col
          md={6}
          className={"pr-5"}
          hidden={!state.storeSpotlight}
          onFocus={() => {
            if (!activeSubpageIndex === subpageIndex) {
              setActiveSubpageIndex(subpageIndex);
            }
          }}
        >
          <FormGroupTile
            type={reviewState ? "formcontrol" : "select"}
            stateName={"page"}
            handleChildFormElement={(key, value) => {
              let updatedSubpages = state.storeSpotlightSubpages;
              updatedSubpages[subpageIndex].url = value.value;
              updatedSubpages[subpageIndex].pageTitle = value.label;

              onChange({ storeSpotlightSubpages: updatedSubpages });

              if (subpageIndex !== activeSubpageIndex) {
                setTimeout(() => {
                  setActiveSubpageIndex(subpageIndex);
                });
              }
            }}
            label={
              reviewState ||
              state.storeSpotlightSubpages[subpageIndex]?.pageTitle
                ? `Store Page ${reviewState ? `#${subpageIndex + 1}` : ""}`
                : `Choose a Page`
            }
            labelClassName={"fs-4"}
            disabled={reviewState}
            defaultValue={
              reviewState
                ? state.storeSpotlightSubpages[subpageIndex].pageTitle
                : {
                    value: state.storeSpotlightSubpages[subpageIndex].url,
                    label: state.storeSpotlightSubpages[subpageIndex].pageTitle,
                  }
            }
            options={availablePageOptions}
            allowEventBubbling={true}
          />
        </Col>
        <Col
          md={6}
          className={"pr-5"}
          hidden={
            !state.storeSpotlightSubpages[subpageIndex]?.asin &&
            activeSubpageIndex !== subpageIndex
          }
        >
          {product && (
            <>
              <ControlLabel>
                <h6
                  style={{
                    color: "rgb(115, 112, 110)",
                    fontWeight: 700,
                    textTransform: "none",
                    fontSize: "15px",
                  }}
                >
                  Selected ASIN
                </h6>
              </ControlLabel>
              <ProductLabel
                product={products.find(
                  (p) =>
                    p.asin ===
                      state.storeSpotlightSubpages[subpageIndex]?.asin ?? {}
                )}
              />
            </>
          )}
        </Col>

        {state.storeSpotlightSubpages[subpageIndex].pageTitle &&
          activeSubpageIndex === subpageIndex && (
            <>
              <StoreSubpageProductsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                products={products}
                storePageProducts={storePageProducts ?? []}
                pageOptions={pageOptions}
                review={review}
                isModal={false}
                storeSpotlightSelector={true}
                storeSpotlightSubpageIndex={subpageIndex}
                activeSubpageIndex={activeSubpageIndex}
                setActiveSubpageIndex={setActiveSubpageIndex}
                preselectedProduct={
                  state.storeSpotlightSubpages[subpageIndex]?.productId
                }
              />
            </>
          )}
      </Row>
    </>
  );
};

export const StoreSpotlightSubpageDialogue = ({
  state,
  onChange,
  review,
  adId,
  products,
  storeOptions,
  pageOptions,
  brandOptions,
  errors,
  fieldsToValidate,
  user,
}) => {
  const [activeSubpageIndex, setActiveSubpageIndex] = useState(null);

  if (!state.storeSpotlight) {
    return null;
  }

  const reviewState = adId || state.id || review;

  return (
    <>
      <Row
        style={{ marginBottom: "1rem" }}
        className={reviewState ? "mt-0" : "my-5"}
      >
        <h2
          style={{
            fontWeight: 700,
            marginTop: 0,
            fontSize: "24px",
          }}
          hidden={reviewState}
        >
          Store Spotlight Subpages
        </h2>
        {state.store ? (
          <>
            <p
              style={{
                fontSize: "16px",
                maxWidth: "90%",
                marginBottom: ".5rem",
              }}
              translate="yes"
              hidden={review}
            >
              Setup 3 store subpages below to showcase your Amazon Store.
            </p>
            <div className="mb-3">
              {errors.storeSpotlightSubpages &&
                fieldsToValidate?.includes("storeSpotlightSubpages") && (
                  <small className="text-danger">
                    {errors.storeSpotlightSubpages}
                  </small>
                )}
            </div>

            {state.storeSpotlightSubpages?.map((subpage, index) => (
              <div
                onClick={(e) => {
                  setActiveSubpageIndex(index);
                }}
              >
                <div className="d-flex">
                  <h3
                    className="pr-5"
                    style={{ fontWeight: 500, marginTop: 0, fontSize: "16px" }}
                    hidden={reviewState}
                  >
                    Store Page #{index + 1}{" "}
                  </h3>{" "}
                  {!reviewState && (
                    <CustomHref
                      text={
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setActiveSubpageIndex(index);
                          }}
                        >
                          Edit
                        </span>
                      }
                    />
                  )}
                </div>
                <StoreSpotlightSubpageField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  storePageProducts={products}
                  review={review}
                  subpageIndex={index}
                  pageOptions={pageOptions}
                  products={products}
                  activeSubpageIndex={activeSubpageIndex}
                  setActiveSubpageIndex={setActiveSubpageIndex}
                />
                <hr className="mx-3 my-4" />
              </div>
            ))}
          </>
        ) : (
          <>
            <p
              style={{
                fontSize: "16px",
                maxWidth: "90%",
                marginBottom: "2rem",
              }}
              translate="yes"
            >
              Choose a store to start building out subpages.
            </p>
          </>
        )}
      </Row>
    </>
  );
};
