import React from "react";
import moment from "moment";
import { AdvertisementExpander } from "views/AdvertisementsTable";
import { useSelector } from "react-redux";
import { useDates } from "dates/useDates";
import ProductExpander from "./ProductExpander";

const AnnotationExpander = ({ row }) => {
  const { group } = useDates();
  const { channel, marketPlace } = useSelector((state) => state);
  const {
    name: adName,
    id: adId,
    ad_type: adType,
  } = row?.original?.advertisement ?? {};
  const { product_title: productTitle, id: productId } =
    row?.original?.product ?? {};

  const { annotation_type: annotationType } = row?.original ?? {};

  const annotationDate = moment(row.row._original.date);
  const daysSinceAnnotation = moment().diff(annotationDate, "days");
  const chartStartDate = annotationType?.includes("CREATED")
    ? moment(annotationDate).format("YYYY-MM-DD")
    : moment(annotationDate)
        .subtract(daysSinceAnnotation, "days")
        .format("YYYY-MM-DD");

  const getHighlightDate = (date) => {
    switch (group) {
      case "month":
        return moment(date).startOf("month");
      case "week":
        // Offset so week begins on Sunday to align with datamart
        return moment(date).startOf("week").add(1, "d");
      case "day":
      default:
        return moment(date);
    }
  };

  if (productId) {
    return (
      <ProductExpander
        row={{
          ...row.row,
          _original: {
            product_title: productTitle,
            product_id: productId,
            ...row.row._original,
          },
        }}
        marketPlace={marketPlace}
        channel={channel.currentChannel}
        overrideFilters={{
          start_date: chartStartDate,
          pre_start_date: null,
          pre_end_date: null,
        }}
        highlightDate={getHighlightDate(annotationDate)}
      />
    );
  }

  return (
    <AdvertisementExpander
      row={{
        ...row.row,
        _original: {
          name: adName,
          ad_id: adId,
          ad_type: adType,
          ...row.row._original,
        },
      }}
      marketPlace={marketPlace}
      channel={channel.currentChannel}
      overrideFilters={{
        start_date: chartStartDate,
        pre_start_date: null,
        pre_end_date: null,
      }}
      highlightDate={getHighlightDate(annotationDate)}
    />
  );
};

export default AnnotationExpander;
