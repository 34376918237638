import { useState } from "react";

const useLocalStorage = (key, defaultValue = null) => {
  const [value, setValue] = useState(() => {
    try {
      let parsedValue = JSON.parse(localStorage.getItem(key));

      if (!parsedValue) {
        return defaultValue;
      }

      return parsedValue;
    } catch (error) {
      return defaultValue;
    }
  });

  const handleSetValue = (updatedValue) => {
    localStorage.setItem(key, JSON.stringify(updatedValue));
    setValue(updatedValue);
  };

  return { value, setValue: handleSetValue };
};

export default useLocalStorage;
