import React, { useState } from "react";
import jmespath from "jmespath";
import { useFetch } from "hooks/api";
import { connect } from "react-redux";
import { Container as Grid, Row, Col, Nav, Badge } from "react-bootstrap";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import { useMemo } from "react";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import { trellisPalette } from "components/custom/analytics/palettes";
import { getURLPrefix } from "utils/getUrlPrefix";
import ReactTable from "react-table-6";
import useColumnSelector from "hooks/useColumnSelector";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import DownloadReport from "modules/perf_reports/DownloadReport";
import Loading from "components/core/blocks/Loading";
import DetailedMetric from "components/core/blocks/DetailedMetric";
import { selectModules } from "redux/selectors/modules";
import { buildMetric } from "./metrics";
import { AuthButton } from "components/core/basic/Button";
import NoImage from "assets/images/utility/no_image.png";
import { AGENCY_DASHBOARD_BREADCRUMB_SPEC } from "utils/dashboardLinks";
import checkModule from "utils/checkModule";

let URL_PREFIX = getURLPrefix();

const LAUNCH_STATES = {
  PREPARING: {
    id: "1",
    value: "PREPARING",
    name: "Preparing",
    color: "#A78DC2",
  },
  INTRODUCED: {
    id: "2",
    value: "INTRODUCED",
    name: "Introduced",
    color: "#0FC398",
  },
  LAUNCHED: {
    id: "3",
    value: "LAUNCHED",
    name: "Launched",
    color: "red",
  },
};

const LaunchCell = (props) => {
  const { products, id } = props.original;
  const image = `${URL_PREFIX}/api/productimage/?product=${products[0]}`;
  return (
    <div className="d-flex align-items-center">
      <div
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain, url(${NoImage}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <Link
        to={`/user/productlaunch/launches/${id}`}
        style={{
          color: trellisPalette[13],
          textAlign: "center",
          float: "center",
          textDecoration: "underline",
          fontWeight: 500,
          fontSize: "1.5rem",
          cursor: "pointer",
        }}
      >
        {props.value}
      </Link>
    </div>
  );
};

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const makeColumn =
  (data, marketPlace, mobileStyle, date) => (header, key, type, options) => {
    const { hideFilter } = options ?? {};

    const defaultAccessor = (p) => jmespath.search(p, key);
    const accessor = options?.accessor ?? defaultAccessor;
    const defaultFormat = ({ value }) => value;
    const fmt =
      options?.formatter ??
      {
        currency: ({ value }) =>
          value == null
            ? ""
            : formatCurrency(
                value,
                marketPlace.marketPlace,
                options?.clipDecimalPlaces ?? true
              ),
        number: ({ value }) =>
          value == null ? "" : formatNumber(value, {}, 1),
        percent: ({ value }) => (value == null ? "" : formatPercent(value)),
        date: ({ value }) =>
          value == null ? "" : moment(value).format("MMM Do, YY"),
        launch: LaunchCell,
        percentDiff: ({ value }) => (
          <span
            style={{
              color: value > 0 ? trellisPalette[12] : trellisPalette[17],
            }}
          >
            {value == null ? "" : formatPercent(value, 0, true)}
          </span>
        ),
      }?.[type] ??
      defaultFormat;
    const parser =
      {
        currency: parseFormattedCurrency,
        number: parseFormattedNumber,
        percent: parseFormattedPercentage,
      }?.[type] ?? defaultFormat;

    // Handle percent filter formatting
    if (type === "percent") {
      data = data.map((row) => {
        Object.keys(row).forEach((propertyKey) => {
          if (propertyKey === key) {
            row["formatted_" + propertyKey] = row[propertyKey] * 100;
          }
        });

        return row;
      });
    }

    return {
      Header: header,
      id: key,
      accessor,
      width: options?.width ?? mobileStyle ? options?.mobileWidth ?? 100 : 125,
      height: 30,
      className: options?.className ?? "text-end",
      headerClassName: options?.className ?? "text-end",
      Cell: (props) => {
        if (options?.showDot ?? true) {
          const compareKey = options?.compareKey;
          if (compareKey) {
            const compareValue = jmespath.search(props.original, compareKey);
            return (
              <div>
                {fmt({ ...props, date })}
                <br />
                <span
                  style={{
                    color: "#666",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  {compareValue === null ? (
                    ""
                  ) : (
                    <>
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                        }}
                      >
                        {options.compareType}{" "}
                      </span>
                      {fmt({
                        ...props,
                        value: compareValue,
                        date,
                      })}
                    </>
                  )}
                </span>
              </div>
            );
          }
        }

        return fmt({ ...props, date });
      },

      style: { height: "60px" },
      filterMethod: (filter, row) => {
        const formattedPercentAccessor = (p) => p[`formatted_${key}`];
        const value =
          type === "percent"
            ? formattedPercentAccessor(row._original)
            : accessor(row._original);
        return filterRanges(filter, value, parser, marketPlace.marketPlace);
      },
      Filter: ({ filter, onChange }) => {
        if (hideFilter) {
          return null;
        }

        return generateFilterMarkup(filter, onChange, key, "range", type, {
          ranges: generateRanges(
            data ?? [],
            5,
            type === "percent" ? `formatted_${key}` : key,
            "number",
            marketPlace.marketPlace,
            true
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

const Table = ({ stats, marketPlace, mobileStyle, columnSpecs, date, tab }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const data = useMemo(() => {
    return stats
      ?.filter((launch) => {
        if (searchFilter) {
          const searchable = `${launch?.name}`;
          return (
            searchable.toLowerCase()?.indexOf(searchFilter.toLowerCase()) > -1
          );
        }
        return true;
      })
      ?.map((d) => {
        return {
          ...d,
          goal_metrics: {
            ...d.goal_metrics,
            acos: d.goal_metrics.acos / 100,
            margin: d.goal_metrics.margin / 100,
          },
        };
      });
  }, [searchFilter, stats]);

  const createColumn = makeColumn(data, marketPlace, mobileStyle, date);
  const [columns, columnSelectorProps] = useColumnSelector(
    () =>
      columnSpecs.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [columnSpecs, stats, mobileStyle]
  );
  const columnOrder = columns.map((col) => col.id);
  return (
    <ReactTable
      showPaginationBottom={false}
      showPaginationTop
      PaginationComponent={ReactTablePagination}
      columns={columns}
      data={data}
      minRows={0}
      defaultPageSize={10}
      className="-highlight"
      filterable={showFilters}
      state={columnOrder}
      getTheadFilterProps={(props) => {
        if (clearFilters) {
          props.filtered.forEach((filter, index) => {
            filter.value = "all";
          });
          setClearFilters(false);
        }
        return { style: { display: "flex" } };
      }}
      getPaginationProps={() => ({
        setShowFilters: setShowFilters,
        updateSearch: setSearchFilter,
        clearFilters: () => {
          setSearchFilter("");
          setClearFilters(true);
        },
        hideFilters: false,
        showFilters: showFilters,
        clearSearchTermFilters: () => setSearchFilter(""),
        searchTerm: searchFilter,
        table: "plan-products",
        style: { marginBottom: "2rem" },
        carouselLayout: false,
        disableSearchFocus: true,
        columnSelectorProps: columnSelectorProps,
      })}
    />
  );
};

const LaunchList = ({ marketPlace, modules }) => {
  const { date, start, end, preStart, preEnd, group, compare } = useDates();
  const [tab, setTab] = useState(LAUNCH_STATES.INTRODUCED.value);

  // http://localhost:8000/merchandise/product_launch
  const { data: launchData } = useFetch(
    ["product_launch_summary"],
    `/merchandise/product_launch`,
    {},
    {
      default: [],
      select: (d) => d.data?.results ?? [],
    }
  );

  const targetFormatter = (v) =>
    formatCurrency(v, marketPlace.marketPlace, false, false, true);

  const launchStateData = useMemo(() => {
    return launchData?.filter(
      (launch) => launch.state.toLowerCase() === tab.toLowerCase()
    );
  }, [launchData, tab]);

  // Calculate summary stat data
  const preparingLaunchesRequiringActionCount = launchData?.filter(
    (l) =>
      l?.stats?.action_required &&
      l?.state?.toLowerCase() === LAUNCH_STATES.PREPARING.value.toLowerCase()
  )?.length;
  const activeLaunchesRequiringActionCount = launchData?.filter(
    (l) =>
      l?.stats?.action_required &&
      l?.state?.toLowerCase() === LAUNCH_STATES.INTRODUCED.value.toLowerCase()
  )?.length;
  const completedLaunchesRequiringActionCount = launchData?.filter(
    (l) =>
      l?.stats?.action_required &&
      l?.state?.toLowerCase() === LAUNCH_STATES.LAUNCHED.value.toLowerCase()
  )?.length;
  const bestsellerCount = launchData?.reduce(
    (prev, current) => prev + current?.stats?.bestseller_count,
    0
  );
  const bestsellerRevenue = launchData?.reduce(
    (prev, current) => prev + current?.stats?.bestseller_sales,
    0
  );
  const clearanceCount = launchData?.reduce(
    (prev, current) => prev + current?.stats?.clearance_count,
    0
  );
  const metGoalsCount = launchData?.reduce((prev, current) => {
    if (
      current.state.toUpperCase() === LAUNCH_STATES.LAUNCHED.value &&
      (current.stats.acos * 100 < current.goal_metrics.acos ||
        !current.goal_metrics.acos) &&
      (current.stats.margin > current.goal_metrics.margin ||
        !current.goal_metrics.margin) &&
      (current.stats.rating >= current.goal_metrics.min_rating ||
        !current.goal_metrics.min_rating)
    ) {
      return prev + 1;
    } else {
      return prev;
    }
  }, 0);
  const clearanceRevenue = launchData?.reduce(
    (prev, current) => prev + current?.stats?.clearance_sales,
    0
  );
  const inProgressRevenue = launchData?.reduce((prev, current) => {
    if (current.state.toUpperCase() === LAUNCH_STATES.INTRODUCED.value) {
      return prev + current?.stats?.total_sales;
    }
    return prev;
  }, 0);
  const metGoalsRevenue = launchData?.reduce((prev, current) => {
    if (
      current.state.toUpperCase() === LAUNCH_STATES.LAUNCHED.value &&
      (current.stats.acos * 100 < current.goal_metrics.acos ||
        !current.goal_metrics.acos) &&
      (current.stats.margin > current.goal_metrics.margin ||
        !current.goal_metrics.margin) &&
      (current.stats.rating >= current.goal_metrics.min_rating ||
        !current.goal_metrics.min_rating)
    ) {
      return prev + current?.stats?.total_sales;
    } else {
      return prev;
    }
  }, 0);

  const readyToLaunchCount = launchData?.[0]?.stats?.ready_to_launch_count;

  const {
    data: productReportData,
    isLoading,
    isError,
  } = useFetch(
    [
      "productReport",
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      compare,
      group,
    ],
    `/api/data_report/product_report`,
    {
      mp: marketPlace.marketPlace,
      date_group: group,
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      pre_start_date: preStart.format("YYYY-MM-DD"),
      pre_end_date: preEnd.format("YYYY-MM-DD"),
      filters: "npi_enrolled",
    },
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );
  const breadCrumbItems = [
    { name: "New Product Launch", href: "/user/productlaunch" },
  ];

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  const [
    metrics,
    launchStateMetrics,
    preparingLaunchColumns,
    launchTableColumns,
  ] = useMemo(() => {
    if (productReportData) {
      return [
        [
          buildMetric(marketPlace, productReportData, "sales", "sales_percent"),
          buildMetric(
            marketPlace,
            productReportData,
            "ad_sales",
            "ad_sales_percent"
          ),
          buildMetric(
            marketPlace,
            productReportData,
            "ad_spend",
            "ad_spend_percent"
          ),
          buildMetric(marketPlace, productReportData, "units", "units_percent"),
          buildMetric(
            marketPlace.marketPlace,
            productReportData,
            "rating",
            "rating_percent"
          ),
          buildMetric(
            marketPlace.marketPlace,
            productReportData,
            "ratings_total",
            "ratings_total_percent"
          ),
          buildMetric(marketPlace, productReportData, "bsr", "bsr_percent", {
            metric: {
              totalOverrideAccessor: "last_bsr_rank",
            },
          }),
          buildMetric(
            marketPlace,
            productReportData,
            "page_views",
            "page_views"
          ),
          buildMetric(
            marketPlace.marketPlace,
            productReportData,
            "margin",
            "margin_delta"
          ),
          buildMetric(marketPlace, productReportData, "roas", "roas_delta"),
        ],
        [
          {
            id: "ready_to_launch_products",
            name: (
              <>
                Products Ready <br />
                To Be Launched
              </>
            ),
            color: trellisPalette[6],
            clickable: false,
            format: "number",
            // toolTip: "The number of products ready to be launched",
            actual: readyToLaunchCount,
          },

          {
            id: "in_progress",
            name: "In Progress",
            color: trellisPalette[2],
            clickable: false,
            format: "number",
            toolTip: "The number of active product launches",
            actual: launchData?.filter(
              (l) => l.state.toUpperCase() === LAUNCH_STATES.INTRODUCED.value
            )?.length,
            target: inProgressRevenue,
            targetFormatter: targetFormatter,
            targetLabel: "earned ",
          },

          {
            id: "met_goals",
            name: "Met Goals",
            color: trellisPalette[14],
            clickable: false,
            format: "number",
            // toolTip: "The number of launches that met goals",
            actual: metGoalsCount,
            target: metGoalsRevenue,
            targetFormatter: targetFormatter,
            targetLabel: "earned ",
          },
          {
            id: "best_sellers",
            name: "Best Seller Products",
            color: trellisPalette[15],
            clickable: false,
            format: "number",
            // toolTip: "The number of best sellers that have been launched",
            actual: bestsellerCount,
            target: bestsellerRevenue,
            targetFormatter: targetFormatter,
            targetLabel: "earned ",
          },
          {
            id: "clearance_products",
            name: "Clearance Products",
            color: trellisPalette[4],
            clickable: false,
            format: "number",
            // toolTip: "The number of clearance products that have been launched",
            actual: clearanceCount,
            target: clearanceRevenue,
            targetFormatter: targetFormatter,
            targetLabel: "earned ",
          },
        ],
        // PREP STATE
        [
          col("Launch", "launch", "name", {
            className: "text-start font-weight-bold",
            width: 350,
            mobileWidth: 250,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
            isStatic: true,
          }),
          col("Start Date", "date", "start_date", { hideFilter: true }),
          col("End Date", "date", "end_date", { hideFilter: true }),
          col("Target Sales", "currency", "exit_criteria.sales"),
          col("Target Units", "number", "exit_criteria.units"),
          col("Target # of Ratings", "number", "exit_criteria.num_of_ratings"),
          col("Target ACOS", "percent", "goal_metrics.acos", {
            divideBy100: true,
          }),
          col("Target Margin", "percent", "goal_metrics.margin", {
            divideBy100: true,
          }),
          col("Target Min Rating", "number", "goal_metrics.min_rating"),
        ],
        // ACTIVE AND LAUNCHED STATE COLS
        [
          col("Launch", "launch", "name", {
            className: "text-start font-weight-bold",
            width: 350,
            mobileWidth: 250,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
            isStatic: true,
          }),
          col("Start Date", "date", "start_date", { hideFilter: true }),
          col("End Date", "date", "end_date", { hideFilter: true }),
          col("Sales", "currency", "stats.sales"),
          col("Ad Spend", "currency", "stats.cost", { noColor: true }),
          col("Units", "number", "stats.units"),
          col("Average Rating", "number", "stats.rating"),
          col("# of Ratings", "number", "stats.ratings_count"),
        ],
      ];
    }
    return [[], [], [], [], [], []];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productReportData]);

  return (
    <Grid fluid className="py-3" style={{ minHeight: "calc(100vh - 150px)" }}>
      <Row style={{ marginTop: "-10px", marginBottom: "1rem" }}>
        <Col xs={12}>
          <BreadcrumbDates items={breadCrumbItems} />
        </Col>
        {productReportData && productReportData.has_ext_data && (
          <Col
            xs={12}
            className="text-center fs-standard"
            style={{
              padding: "2rem",
              backgroundColor: "#eee",
              position: "relative",
            }}
          >
            <span>
              <a href="https://chrome.google.com/webstore/category/extensions">
                Click here to download the <b>Trellis Chrome Extension</b>
              </a>
            </span>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          {isLoading && <Loading />}
          {isError && <p>Error Loading Dashboard</p>}
          {productReportData && (
            <>
              <MetricsCard
                title={"New Product Launch"}
                data={productReportData.date ?? []}
                metrics={metrics}
                showMetricsSelector={true}
                initialSelectedMetric={["sales", "units", "rating"]}
                byline={
                  <>
                    {start.format("MMMM Do YYYY")}
                    {" - "}
                    {end.format("MMMM Do YYYY")}
                    {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                  </>
                }
                isLoading={isLoading}
              >
                <Row>
                  <Col xs={12} md={8} lg={10} xl={11}>
                    <Row>
                      {launchStateMetrics?.map((m, i) => (
                        <Col
                          xs={12}
                          md={6}
                          lg={2}
                          key={m.id}
                          className={`mb-4 ${i % 2 === 0 ? "pl-2" : "pr-2"}`}
                        >
                          <div>
                            <div>
                              <DetailedMetric
                                center
                                size={"small"}
                                id={m.id}
                                toolTip={m.toolTip}
                                name={m.name}
                                value={m.overrideValue ?? m.actual}
                                target={m.target}
                                formatter={m.formatter}
                                targetFormatter={m?.targetFormatter}
                                showTarget={false}
                                targetLabel={m?.targetLabel}
                                targetColor={
                                  m?.targetColor ??
                                  (m.target > 0 ? "#007000" : "#d2222d")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    lg={2}
                    xl={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingLeft: "1rem",
                    }}
                  >
                    <Link to={`/user/productlaunch/new`}>
                      <AuthButton
                        title="Launch Product"
                        buttonStyle="adplan_button"
                      />
                    </Link>
                  </Col>
                  <Col
                    xs={12}
                    className="mt-1 ph-5 mb-5 border-bottom fs-standard"
                  >
                    <Nav
                      variant="underline"
                      onSelect={(k) => {
                        return setTab(k);
                      }}
                      activeKey={tab}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey={LAUNCH_STATES.PREPARING.value}>
                          Planning{" "}
                          {preparingLaunchesRequiringActionCount ? (
                            <Badge
                              bsClass="badge px-2"
                              className={`badge-primary`}
                              style={{ marginLeft: ".5rem" }}
                              pill
                            >
                              {preparingLaunchesRequiringActionCount}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={LAUNCH_STATES.INTRODUCED.value}>
                          Active{" "}
                          {activeLaunchesRequiringActionCount ? (
                            <Badge
                              bsClass="badge px-2"
                              className={`badge-primary`}
                              style={{ marginLeft: ".5rem" }}
                              pill
                            >
                              {activeLaunchesRequiringActionCount}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={LAUNCH_STATES.LAUNCHED.value}>
                          Completed{" "}
                          {completedLaunchesRequiringActionCount ? (
                            <Badge
                              bsClass="badge px-2"
                              className={`badge-primary`}
                              style={{ marginLeft: ".5rem" }}
                              pill
                            >
                              {completedLaunchesRequiringActionCount}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Table
                      stats={launchStateData}
                      marketPlace={marketPlace}
                      columnSpecs={
                        tab === LAUNCH_STATES.PREPARING.value
                          ? preparingLaunchColumns
                          : launchTableColumns
                      }
                      date={date}
                      tab={tab}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="py-5">
                    <DownloadReport
                      url_path={"product_report"}
                      filters={{
                        mp: marketPlace.marketPlace,
                        date_group: group,
                        start_date: start.format("YYYY-MM-DD"),
                        end_date: end.format("YYYY-MM-DD"),
                        filters: "npi_enrolled",
                      }}
                      report_dl={"product"}
                    />
                  </Col>
                </Row>
              </MetricsCard>
            </>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

const LaunchDashboard = ({
  user,
  marketPlace,
  amz_onboard,
  channel,
  modules,
}) => {
  const { category = null } = useParams();
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <LaunchList
        user={user}
        marketPlace={marketPlace}
        category={category}
        modules={modules}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  amz_onboard: state.amz_onboard,
  channel: state.channel,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(LaunchDashboard);
