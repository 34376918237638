import React, { useEffect, useMemo, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getBadgeVariant } from "./utils";

const highlightContent = (content, highlightPhrases) => {
  let newContent = content;
  const matches = [];
  const sortedPhrases = highlightPhrases.sort(
    (a, b) => b.keyword_text?.length - a.keyword_text?.length
  );
  for (const { keyword_text, ...rest } of sortedPhrases) {
    //if (keyword_text === "style") continue;
    const color = getBadgeVariant(rest);
    if (color === "tr-secondary") continue;
    const regex = new RegExp(`(?![^<]*>|[^<>]*</)(${keyword_text})`, "gi");
    if (newContent.match(regex)) {
      matches.push({ keyword_text, color, ...rest });
      newContent = newContent.replace(
        regex,
        `<span style="display: inline-block;" class="ul-${color}">$1</span>`
      );
    }
  }
  return { newContent, matches };
};

const ContentEditor = ({
  field,
  fieldIndex,
  richText = false,
  content,
  onContentChange,
  setContent,
  highlightPhrases,
  maxLength,
  minLength,
  variations,
  recommendedContent,
  children,
  readOnly,
}) => {
  const [showEditor, setShowEditor] = useState(false);
  const [showRecommended, setShowRecommended] = useState(false);
  const handleChange = (value) => {
    setContent(field, value, fieldIndex);
    if (onContentChange) onContentChange(field, value);
    return;
  };

  useEffect(() => {
    if (!recommendedContent) setShowRecommended(true);
  }, [recommendedContent]);

  const [highlightedContent, matches, highlightedRecommendedContent] =
    useMemo(() => {
      const matches = [];
      let newContent = content;
      if (variations) {
        for (const [key, value] of Object.entries(variations)) {
          const regex = new RegExp(`(\\{${key}\\})`, "gi");
          if (newContent.match(regex)) {
            newContent = newContent.replace(
              regex,
              `<span style="display: inline-block;" class="bg-tr-success">${value}</span>`
            );
          }
        }
      }
      if (highlightPhrases && newContent) {
        let hl = highlightContent(newContent, highlightPhrases);
        newContent = hl.newContent;
        matches.push(...hl.matches);
      }
      let newRecommendedContent = recommendedContent;
      if (highlightPhrases && recommendedContent) {
        let hl = highlightContent(recommendedContent, highlightPhrases);
        newRecommendedContent = hl.newContent;
      }
      return [newContent, matches, newRecommendedContent];
    }, [content, highlightPhrases, variations, recommendedContent]);

  const editIconStyle = readOnly
    ? {
        cursor: "not-allowed",
      }
    : {
        cursor: "pointer",
        color: "#000",
      };

  return (
    <div style={{ opacity: readOnly ? "0.5" : "1" }}>
      <div
        style={{
          border: "1px solid #ccc",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          padding: "5px 15px 15px 15px",
          boxShadow: "2px 2px 3px #aaa",
          fontSize: "16px",
        }}
      >
        <div className="d-flex">
          <div
            style={{
              flex: "0.95",
              padding: "5px",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: highlightedContent }} />
          </div>
          <div
            style={{ flex: "0.05", textAlign: "right" }}
            className="fs-5 pb-2 pt-3 d-flex justify-content-end"
          >
            <FaPencilAlt
              style={editIconStyle}
              onClick={() => {
                if (!readOnly) setShowEditor(!showEditor);
              }}
            />
            {children}
          </div>
        </div>
        {recommendedContent && showRecommended && (
          <div
            className="mt-3"
            style={{
              padding: "10px",
              backgroundColor: "#eee",
              borderRadius: "7px",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: highlightedRecommendedContent,
              }}
              style={{ fontStyle: "italic" }}
            />
            <div
              className="text-right mt-3"
              style={{ textAlign: "right", fontSize: "1.2rem" }}
            >
              <span
                style={{
                  marginBottom: "1rem",
                  color: "#D91266",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
                onClick={() => {
                  setShowRecommended(false);
                }}
              >
                Clear
              </span>
              <span style={{ color: "#ccc" }}>{" | "}</span>
              <span
                style={{
                  marginBottom: "1rem",
                  color: "#D91266",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
                onClick={() => {
                  handleChange(recommendedContent);
                  setShowRecommended(false);
                }}
              >
                Apply
              </span>
            </div>
          </div>
        )}
        <div className="mt-3">
          {showEditor &&
            !readOnly &&
            (richText ? (
              <ReactQuill
                value={content}
                onChange={handleChange}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                  history: {
                    delay: 2000,
                    maxStack: 500,
                    userOnly: true,
                  },
                }}
                placeholder=""
              />
            ) : (
              <textarea
                wrap="soft"
                rows="3"
                type="text"
                value={content}
                onChange={(e) => handleChange(e.target.value)}
                style={{
                  fontSize: "16px",
                  width: "100%",
                  border: "1px solid #ccc",
                  padding: "5px",
                  color: "#152748",
                }}
              />
            ))}
        </div>
      </div>
      <div
        style={{
          borderLeft: "1px solid #ccc",
          borderRight: "1px solid #ccc",
          borderBottom: "1px solid #ccc",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          padding: "10px 15px 10px 15px",
          backgroundColor: "#eee",
          boxShadow: "2px 2px 3px #aaa",
        }}
      >
        <Row>
          <Col xs={12} className="fs-5 d-flex justify-content-between">
            {content && (
              <>
                <div>
                  <span className="ms-3 fw-bold">
                    words and phrases with conversions:
                    <Badge bg={"tr-success"} className="mx-1 ms-2 my-1 fs-6">
                      {matches.filter((c) => c.color === "tr-success").length}
                    </Badge>
                    <Badge bg={"tr-info"} className="mx-1 my-1 fs-6">
                      {matches.filter((c) => c.color === "tr-info").length}
                    </Badge>
                    <Badge bg={"tr-warning"} className="mx-1 my-1 fs-6">
                      {matches.filter((c) => c.color === "tr-warning").length}
                    </Badge>
                  </span>
                  <span className="ms-3 fw-bold">
                    total words:{" "}
                    <Badge
                      bg={"dark"}
                      className="mx-1 ms-2 my-1 fs-6"
                      style={{ color: "#fff" }}
                    >
                      {content.split(" ").length}
                    </Badge>
                  </span>
                </div>
                <div>
                  <span
                    className={`ms-3 fw-bold ${
                      content.length > maxLength || content.length < minLength
                        ? `text-tr-danger`
                        : `text-tr-success`
                    }`}
                  >
                    characters:
                    <Badge
                      bg={
                        content.length > maxLength || content.length < minLength
                          ? `danger`
                          : `success`
                      }
                      className="mx-1 ms-2 my-1 fs-6"
                    >
                      {content.length}
                    </Badge>
                  </span>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContentEditor;
