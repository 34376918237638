import React, { useState, useRef } from "react";
import jmespath from "jmespath";
import { useFetch } from "hooks/api";
import { connect } from "react-redux";
import { Container as Grid, Row, Col, Nav } from "react-bootstrap";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import { useLocation, useParams } from "react-router";
import moment from "moment";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import { useMemo } from "react";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import {
  metricColors,
  trellisPalette,
} from "components/custom/analytics/palettes";
import { getURLPrefix } from "utils/getUrlPrefix";
import NoImage from "assets/images/utility/no_image.png";
import ReactTable from "react-table-6";
import useColumnSelector from "hooks/useColumnSelector";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import DownloadReport from "modules/perf_reports/DownloadReport";
import Loading from "components/core/blocks/Loading";
import DetailedMetric from "components/core/blocks/DetailedMetric";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";
import { selectModules } from "redux/selectors/modules";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import useTableSettings from "hooks/useTableSettings";
import FilterWidget from "./tables/FilterWidget";
import {
  PROMOTION_STATUS_FILTER,
  PROMOTION_TYPE_FILTER,
} from "views/advertising/promotionReportFilters";
import { METRIC_GROUPING_LOOKUP } from "./tables/metricsByEntity";
import getMetricSpec from "./metrics";

let URL_PREFIX = getURLPrefix();

const ProductCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const productId = props?.value?.id;
  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?product=${productId}`;
  return (
    <div style={{ display: "flex" }}>
      <div
        onError={(e) => {
          setHasError(true);
        }}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div>
        {props?.value?.product_title}
        <br />
        <i style={{ fontWeight: "light", color: "#666", fontSize: "1rem" }}>
          {props?.value?.asin} ({props?.value?.sku})
        </i>
      </div>
    </div>
  );
};

const CategoryCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const categoryId = props?.original?.category_id;
  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?category=${categoryId}`;
  return (
    <div style={{ display: "flex" }}>
      <div
        onError={(e) => {
          setHasError(true);
        }}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div>{props?.value}</div>
    </div>
  );
};

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const makeColumn =
  (data, marketPlace, mobileStyle, date) => (header, key, type, options) => {
    const { hideFilter } = options ?? {};

    const defaultAccessor = (p) => jmespath.search(p, key);
    const accessor = options?.accessor ?? defaultAccessor;
    const defaultFormat = ({ value }) => value;
    const fmt =
      options?.formatter ??
      {
        currency: ({ value }) =>
          value == null
            ? ""
            : formatCurrency(
                value,
                marketPlace.marketPlace,
                options?.clipDecimalPlaces ?? true
              ),
        number: ({ value }) =>
          value == null ? "" : formatNumber(value, {}, 1),
        percent: ({ value }) => (value == null ? "" : formatPercent(value)),
        date: ({ value }) =>
          value == null ? "" : moment(value).format("MMM Do, YY"),
        category: CategoryCell,
        product: ProductCell,
        percentDiff: ({ value }) => (
          <span
            style={{
              color: value > 0 ? trellisPalette[12] : trellisPalette[17],
            }}
          >
            {value == null ? "" : formatPercent(value, 0, true)}
          </span>
        ),
      }?.[type] ??
      defaultFormat;
    const parser =
      {
        currency: parseFormattedCurrency,
        number: parseFormattedNumber,
        percent: parseFormattedPercentage,
      }?.[type] ?? defaultFormat;

    // Handle tooltips to support metric selection menu descriptions
    let { toolTip } = getMetricSpec(marketPlace, key);
    if (!toolTip) {
      toolTip = options?.toolTip;
    }

    // Handle percent filter formatting
    if (type === "percent") {
      data = data.map((row) => {
        Object.keys(row).forEach((propertyKey) => {
          if (propertyKey === key) {
            row["formatted_" + propertyKey] = row[propertyKey] * 100;
          }
        });

        return row;
      });
    }

    return {
      Header: header,
      id: key,
      accessor,
      width: options?.width ?? mobileStyle ? options?.mobileWidth ?? 100 : 125,
      height: 30,
      grouping: METRIC_GROUPING_LOOKUP[key],
      toolTip,
      format: type,
      className: options?.className ?? "text-end",
      headerClassName: options?.className ?? "text-end",
      Cell: (props) => {
        if (options?.showDot ?? true) {
          const compareKey = options?.compareKey;
          if (compareKey) {
            const compareValue = jmespath.search(props.original, compareKey);
            return (
              <div>
                {fmt({ ...props, date })}
                <br />
                <span
                  style={{
                    color: "#666",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  {compareValue === null ? (
                    ""
                  ) : (
                    <>
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                        }}
                      >
                        {options.compareType}{" "}
                      </span>
                      {fmt({
                        ...props,
                        value: compareValue,
                        date,
                      })}
                    </>
                  )}
                </span>
              </div>
            );
          }
        }

        return fmt({ ...props, date });
      },

      style: { height: "60px" },
      filterMethod: (filter, row) => {
        const formattedPercentAccessor = (p) => p[`formatted_${key}`];
        const value =
          type === "percent"
            ? formattedPercentAccessor(row._original)
            : accessor(row._original);
        return filterRanges(filter, value, parser, marketPlace.marketPlace);
      },
      Filter: ({ filter, onChange }) => {
        if (hideFilter) {
          return null;
        }

        return generateFilterMarkup(filter, onChange, key, "range", type, {
          ranges: generateRanges(
            data ?? [],
            5,
            type === "percent" ? `formatted_${key}` : key,
            "number",
            marketPlace.marketPlace,
            true
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

const Table = ({
  stats,
  marketPlace,
  mobileStyle,
  columnSpecs,
  date,
  showPromotionFilters = false,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const TABLE_ID = "promotionsReportTable";
  const tableRef = useRef();

  const {
    customFilters,
    setCustomFilters,
    formatFilterValue,
    applyCustomFilters,
  } = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const data = useMemo(() => {
    return applyCustomFilters(
      stats
        ?.map((p) => {
          const promotionType =
            {
              BEST_DEAL: "Best Deal",
              LIGHTNING_DEAL: "Lightning Deal",
              PRICE_DISCOUNT: "Price Discount",
            }?.[
              p?.promotion_report_product?.promotion_report?.promotion_type
            ] ?? "Unknown";
          let status = p?.promotion_report_product?.promotion_report?.status;
          const start =
            p?.promotion_report_product?.promotion_report?.start_datetime;
          const end =
            p?.promotion_report_product?.promotion_report?.end_datetime;
          if (status === "APPROVED") {
            if (
              moment(start).isBefore(moment()) &&
              moment(end).isAfter(moment())
            ) {
              status = "In Progress";
            } else if (moment(end).isBefore(moment())) {
              status = "Completed";
            } else {
              status = "Approved";
            }
          } else {
            status = status === "CANCELED" ? "Canceled" : status?.toLowerCase;
          }
          return {
            ...p,
            status,
            promotionType,
          };
        })
        ?.filter((p) => {
          if (searchFilter) {
            const searchable = `${p?.category_name} ${p?.product?.product_title} ${p?.product?.asin} ${p?.product?.sku}`;
            return (
              searchable.toLowerCase()?.indexOf(searchFilter.toLowerCase()) > -1
            );
          }
          return true;
        }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats, searchFilter, customFilters]);

  const {
    savedTableSettings: savedTablePageSize,
    updateTableSettings: setSavedTablePageSize,
  } = useTableSettings(TABLE_ID, "tablePageSize", 5);

  const {
    savedTableSettings: savedTableSortMethod,
    updateTableSettings: setSavedTableSortMethod,
  } = useTableSettings(TABLE_ID, "tableSorting", []);

  const createColumn = makeColumn(data, marketPlace, mobileStyle, date);
  const [columns, columnSelectorProps] = useColumnSelector(
    () =>
      columnSpecs.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [columnSpecs, stats, mobileStyle]
  );
  const columnOrder = columns.map((col) => col.id);
  return (
    <ReactTable
      ref={tableRef}
      showPaginationBottom={false}
      showPaginationTop
      PaginationComponent={ReactTablePagination}
      columns={columns}
      data={data}
      defaultPageSize={savedTablePageSize || 10}
      defaultSorted={savedTableSortMethod || []}
      className={`-highlight ${TABLE_ID}`}
      filterable={showFilters}
      state={columnOrder}
      onSortedChange={(newSorted) => {
        setSavedTableSortMethod(newSorted);
      }}
      onPageSizeChange={(pageSize) => {
        setSavedTablePageSize(pageSize);
      }}
      getTheadFilterProps={(props) => {
        if (clearFilters) {
          props.filtered.forEach((filter, index) => {
            filter.value = "all";
          });
          setClearFilters(false);
        }
        return { style: { display: "flex" } };
      }}
      getPaginationProps={() => ({
        setShowFilters: setShowFilters,
        updateSearch: setSearchFilter,
        clearFilters: () => {
          setSearchFilter("");
          setCustomFilters([]);
          setClearFilters(true);
        },
        hideFilters: false,
        showFilters: showFilters,
        clearSearchTermFilters: () => setSearchFilter(""),
        searchTerm: searchFilter,
        table: "plan-products",
        style: { marginBottom: "2rem" },
        carouselLayout: false,
        disableSearchFocus: true,
        columnSelectorProps: columnSelectorProps,
        filterWidget: true,
        hideFilterToggleIcon: true,
        tableEntityType: "promotion",
        children: (
          <div className="d-flex justify-content-start w-100 h-100 align-items-center px-2">
            <FilterWidget
              customFilters={customFilters}
              setCustomFilters={setCustomFilters}
              formatFilterValue={formatFilterValue}
              tableRef={tableRef}
              additionalFilterOptions={
                showPromotionFilters
                  ? [PROMOTION_TYPE_FILTER, PROMOTION_STATUS_FILTER]
                  : ""
              }
              excludedFilterColumns={
                showPromotionFilters
                  ? ["product", "promotionType", "status"]
                  : ["category_name"]
              }
              tableId={TABLE_ID}
            />
          </div>
        ),
      })}
    />
  );
};

const PromotionsReport = ({ marketPlace, modules }) => {
  const { date, start, end, group } = useDates();
  const [tab, setTab] = useState("inprogress");
  const {
    data: promoReportData,
    isLoading,
    isError,
  } = useFetch(
    ["promosReport", start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")],
    `/merchandise/promos_report/`,
    {
      group_by: group,
      market_place: marketPlace.marketPlace,
      start: start.format("YYYY-MM-DD"),
      end: end.format("YYYY-MM-DD"),
    },
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const breadCrumbItems = [
    {
      name: "Deals Dashboard",
      href: "/user/merchandising/promotions",
      dropdownLinkOptions: DASHBOARD_LINKS,
      searchable: false,
      unsorted: true,
      dropdownSubtitle: "Dashboards",
    },
  ];

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  const currencyFormatter = (v) =>
    formatCurrency(v, marketPlace.marketPlace, false, false, true);

  const [
    metrics,
    inprogressMetrics,
    upcomingMetrics,
    catColumns,
    promoColumns,
    upcomingColumns,
  ] = useMemo(() => {
    if (promoReportData) {
      const total = promoReportData.total;
      const inprogress = promoReportData.summary.inprogress;
      const upcoming = promoReportData.summary.upcoming;
      return [
        [
          {
            id: "promo_sales",
            name: "Deals Sales",
            color: metricColors.sales[2],
            accessor: (d) => d.promo_sales,
            actual: total.promo_sales,
            target: total.sales_est,
            targetLabel: "est.",
            formatter: currencyFormatter,
            targetColor: "#666",
            targetFormatter: currencyFormatter,
            axis: 1,
          },
          {
            id: "promo_units",
            name: "Deals Units",
            color: metricColors.units[1],
            accessor: (d) => d.promo_units,
            actual: total.promo_units,
            target: total.units_est,
            targetLabel: "est.",
            formatter: (v) => formatNumber(v, { notation: "compact" }, 0),
            targetColor: "#666",
            targetFormatter: (v) => formatNumber(v, {}, 0),
            axis: 0,
            chartType: "column",
          },
          {
            id: "promo_cost",
            name: "Total Discounts",
            color: metricColors.cost[2],
            accessor: (d) => d.promo_cost,
            actual: total.promo_cost,
            target: null,
            formatter: currencyFormatter,
            axis: 1,
          },
          {
            id: "promo_glance_views",
            name: "Glance Views",
            color: trellisPalette[1],
            accessor: (d) => d.glance_views,
            actual: total.glance_views,
            target: null,
            formatter: (v) => formatNumber(v, { notation: "compact" }, 0),
            axis: 3,
          },
          {
            id: "promo_conv_rate",
            name: "Deals Conv. Rate",
            color: trellisPalette[2],
            accessor: (d) => d.conv,
            actual: total.conv,
            target: null,
            formatter: (v) => formatPercent(v, 2),
            axis: 4,
          },
        ],
        [
          {
            id: "promo_sales",
            name: "Deals Sales",
            color: metricColors.sales[2],
            accessor: (d) => d.promo_sales,
            actual: inprogress.product_revenue,
            target: inprogress.sales_est,
            targetLabel: "est.",
            formatter: currencyFormatter,
            targetColor: "#666",
            targetFormatter: currencyFormatter,
            axis: 1,
          },
          {
            id: "promo_units",
            name: "Deals Units",
            color: metricColors.units[1],
            accessor: (d) => d.promo_units,
            actual: inprogress.product_units_sold,
            target: inprogress.units_est,
            targetLabel: "est.",
            formatter: (v) => formatNumber(v, { notation: "compact" }, 0),
            targetColor: "#666",
            targetFormatter: (v) => formatNumber(v, {}, 0),
            axis: 0,
            chartType: "column",
          },
        ],
        [
          {
            id: "sales_est",
            name: "Sales Est",
            actual: upcoming.sales_est,
            formatter: currencyFormatter,
          },
          {
            id: "units_est",
            name: "Units Est",
            accessor: (d) => d.promo_units,
            actual: upcoming.units_est,
            formatter: (v) => formatNumber(v, { notation: "compact" }, 0),
          },
        ],
        [
          col("Category", "category", "category_name", {
            className: "text-start font-weight-bold",
            width: 300,
            mobileWidth: 200,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
          }),
          col("Promo Sales", "currency", "promo_sales", {
            compareKey: "sales_est",
            compareType: "est.",
            toolTip: "Total sales from promotion",
          }),
          col("Promo Units", "number", "promo_units", {
            compareKey: "units_est",
            compareType: "est.",
            toolTip: "Total units from promotion",
          }),
          col("Promo Cost", "currency", "promo_cost", {
            toolTip: "Total cost from promotion",
          }),
        ],
        [
          col("Product", "product", "product", {
            className: "text-start font-weight-bold",
            width: 300,
            mobileWidth: 200,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
            toolTip: "The promoted product",
          }),
          col("Status", "text", "status", {
            hideFilter: true,
            toolTip: "The current status of the promotion",
          }),
          col("Type", "text", "promotionType", {
            hideFilter: true,
            toolTip: "The type of promotion",
          }),
          col(
            "Start",
            "date",
            "promotion_report_product.promotion_report.start_datetime",
            {
              hideFilter: true,
              toolTip: "The start date of the promotion",
            }
          ),
          col(
            "End",
            "date",
            "promotion_report_product.promotion_report.end_datetime",
            {
              hideFilter: true,
              toolTip: "The end date of the promotion",
            }
          ),
          col("Deal Price", "currency", "deal_price", {
            clipDecimalPlaces: false,
            toolTip: "The reduced price for the duration of the promotion",
          }),
          col("Promo Sales", "currency", "product_revenue", {
            compareKey: "sales_est",
            compareType: "est.",
            toolTip: "Total sales from promotion",
          }),
          col("Promo Units", "number", "product_units_sold", {
            compareKey: "units_est",
            compareType: "est.",
            toolTip: "Total units from promotion",
          }),
          col("Total Discounts", "currency", "cost", {
            toolTip: "Total cost of the promotion",
          }),
          col("Glance Views", "number", "product_glance_views", {
            toolTip: "Total number of glance views from promotion",
          }),
          col("Conversion Rate", "percent", "conversion", {
            toolTip: "The percentage of clicks that result in a sale",
          }),
        ],
        [
          col("Product", "product", "product", {
            className: "text-start font-weight-bold",
            width: 300,
            mobileWidth: 200,
            showDot: false,
            fixedLeft: true,
            toolTip: "The promoted product",
          }),
          col("Status", "text", "status", {
            toolTip: "The current status of the promotion",
          }),
          col("Type", "text", "promotionType", {
            toolTip: "The type of promotion",
          }),
          col(
            "Start",
            "date",
            "promotion_report_product.promotion_report.start_datetime",
            {
              toolTip: "The start date of the promotion",
            }
          ),
          col(
            "End",
            "date",
            "promotion_report_product.promotion_report.end_datetime",
            {
              toolTip: "The end date of the promotion",
            }
          ),
          col("Deal Price", "currency", "deal_price", {
            clipDecimalPlaces: false,
            toolTip: "The reduced price for the duration of the promotion",
          }),
          col("Sales Est.", "currency", "sales_est", {
            toolTip: "Estimated sales from promotion",
          }),
          col("Units Est", "number", "units_est", {
            toolTip: "Estimated units from promotion",
          }),
          col("Cost Est", "currency", "cogs_est", {
            toolTip: "Estimated cost of running the promotion",
          }),
        ],
      ];
    }
    return [[], [], [], [], [], []];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoReportData]);

  return (
    <Grid fluid className="py-3" style={{ minHeight: "calc(100vh - 150px)" }}>
      <Row style={{ marginTop: "-10px", marginBottom: "1rem" }}>
        <Col xs={12}>
          <BreadcrumbDates items={breadCrumbItems} hideCompare />
        </Col>
        {promoReportData && promoReportData.has_ext_data && (
          <Col
            xs={12}
            className="text-center fs-standard"
            style={{
              padding: "2rem",
              backgroundColor: "#eee",
              position: "relative",
            }}
          >
            <span>
              <a href="https://chrome.google.com/webstore/category/extensions">
                Click here to download the <b>Trellis Chrome Extension</b>
              </a>
            </span>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          {isLoading && <Loading />}
          {isError && <p>Error Loading Dashboard</p>}
          {promoReportData && (
            <>
              <MetricsCard
                title={"Deals"}
                data={promoReportData.dates ?? []}
                metrics={metrics}
                initialSelectedMetric={[
                  "promo_sales",
                  "promo_units",
                  "promo_cost",
                ]}
                byline={
                  <>
                    {start.format("MMMM Do YYYY")}
                    {" - "}
                    {end.format("MMMM Do YYYY")}
                    {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                  </>
                }
              >
                <Row>
                  <Col
                    xs={12}
                    className="mt-5 ph-5 mb-5 border-bottom fs-standard"
                  >
                    <Nav
                      variant="underline"
                      onSelect={(k) => {
                        return setTab(k);
                      }}
                      activeKey={tab}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="inprogress">In Progress</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="completed">
                          Past Promotions
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="categories">Categories</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                {tab === "upcoming" && (
                  <Row>
                    <Col xs={6}>
                      <Row>
                        {upcomingMetrics.map((m, i) => (
                          <Col
                            xs={3}
                            key={m.id}
                            className={`mb-4 ${i % 2 === 0 ? "pl-2" : "pr-2"}`}
                          >
                            <div className="">
                              <div>
                                <DetailedMetric
                                  center
                                  size={"small"}
                                  id={m.id}
                                  toolTip={m.toolTip}
                                  name={m.name}
                                  value={m.overrideValue ?? m.actual}
                                  target={m.target}
                                  formatter={m.formatter}
                                  targetFormatter={m?.targetFormatter}
                                  showTarget={!!m.target}
                                  targetColor={
                                    m?.targetColor ??
                                    (m.target > 0 ? "#007000" : "#d2222d")
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Table
                        stats={promoReportData?.upcoming_promos}
                        marketPlace={marketPlace}
                        columnSpecs={upcomingColumns}
                        date={date}
                        showPromotionFilters
                      />
                    </Col>
                  </Row>
                )}
                {tab === "inprogress" && (
                  <Row>
                    <Col xs={6}>
                      <Row>
                        {inprogressMetrics.map((m, i) => (
                          <Col
                            xs={3}
                            key={m.id}
                            className={`mb-4 ${i % 2 === 0 ? "pl-2" : "pr-2"}`}
                          >
                            <div className="">
                              <div>
                                <DetailedMetric
                                  center
                                  size={"small"}
                                  id={m.id}
                                  toolTip={m.toolTip}
                                  name={m.name}
                                  value={m.overrideValue ?? m.actual}
                                  target={m.target}
                                  formatter={m.formatter}
                                  targetFormatter={m?.targetFormatter}
                                  showTarget={!!m.target}
                                  targetColor={
                                    m?.targetColor ??
                                    (m.target > 0 ? "#007000" : "#d2222d")
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Table
                        stats={promoReportData?.inprogress_promos}
                        marketPlace={marketPlace}
                        columnSpecs={promoColumns}
                        date={date}
                        showPromotionFilters
                      />
                    </Col>
                  </Row>
                )}
                {tab === "completed" && (
                  <Row>
                    <Col xs={12}>
                      <Table
                        stats={promoReportData?.promos}
                        marketPlace={marketPlace}
                        columnSpecs={promoColumns}
                        date={date}
                        showPromotionFilters
                      />
                    </Col>
                  </Row>
                )}
                {tab === "categories" && (
                  <Row>
                    <Col xs={12}>
                      <Table
                        stats={promoReportData?.categories}
                        marketPlace={marketPlace}
                        columnSpecs={catColumns}
                        date={date}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} className="text-end">
                    <DownloadReport
                      url_path={"promos_report/"}
                      filters={{
                        market_place: marketPlace.marketPlace,
                        start: start.format("YYYY-MM-DD"),
                        end: end.format("YYYY-MM-DD"),
                      }}
                      endpoint="/merchandise/"
                    />
                  </Col>
                </Row>
              </MetricsCard>
            </>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

const ReportsDashboard = ({
  user,
  marketPlace,
  amz_onboard,
  channel,
  modules,
}) => {
  const { category = null } = useParams();
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const PROMO_SUBSCRIPTION =
    ((user.promotions_tier && !["unknown"].includes(user.promotions_tier)) ||
      ((checkModule(modules, "PROMOTIONS") ||
        checkTierAccess(user.tier, "promotions", amz_onboard.org_status)) &&
        (amz_onboard.org_status === "active" ||
          amz_onboard.org_status === "trial_active" ||
          amz_onboard.org_status === "demo_org" ||
          channel.currentChannel === "walmart"))) &&
    !["MANUALLY_DISABLED"].includes(user.promotions_tier);

  if (!PROMO_SUBSCRIPTION) {
    return (
      <PaymentDiscovery
        discoveryType={"advertising"}
        dashboardType="advertising"
      />
    );
  }
  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <PromotionsReport
        user={user}
        marketPlace={marketPlace}
        category={category}
        modules={modules}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  amz_onboard: state.amz_onboard,
  channel: state.channel,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(ReportsDashboard);
