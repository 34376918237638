import { toTitleCase } from "utils/formatText";
import { formatCurrency } from "./formatNumber";

const formatAmazonAutoTarget = (autoTargetType) => {
  let formattedAutoTargetOptions = {
    asinSubstituteRelated: "substitutes",
    asinAccessoryRelated: "complements",
    queryBroadRelMatches: "loose match",
    queryHighRelMatches: "close match",
    similarProduct: "similarProduct",
  };

  return formattedAutoTargetOptions[autoTargetType];
};

export const formatTargetText = (targetText, options) => {
  // Revisit below to refactor. Handling new target response formats for 'text' is getting
  // complex for Walmart and SD v2 with many possible combos (strings, array of objects, single object, etc.)

  if (options?.name || options?.brand_name) {
    let formattedString = "";

    if (options.asin_prime_eligible) {
      formattedString += "Prime Eligible ";
    }
    if (options.brand_name) {
      formattedString += `${options.brand_name} within `;
    }

    if (options.name) {
      formattedString += `${options.name} `;
    }

    if (!options.name) {
      formattedString += `${options.id} `;
    }

    if (options.asin_review_rating_between) {
      formattedString += `${options.asin_review_rating_between} rating `;
    }

    if (options.asin_review_rating_less_than) {
      formattedString += `${options.asin_review_rating_less_than}- rating `;
    }

    if (options.asin_review_rating_greater_than) {
      formattedString += `${options.asin_review_rating_greater_than}+ rating `;
    }

    if (options.asin_price_between) {
      formattedString += ` Priced ${formatCurrency(
        options.asin_price_between,
        options.marketPlace
      )}`;
    }

    if (options.asin_price_less_than) {
      formattedString += ` Priced ${formatCurrency(
        options.asin_price_less_than,
        options.marketPlace
      )}-`;
    }

    if (options.asin_price_greater_than) {
      formattedString += ` Priced ${formatCurrency(
        options.asin_price_greater_than,
        options.marketPlace
      )}+`;
    }

    return formattedString;
  }

  return typeof targetText === "object"
    ? typeof targetText?.value === "object" && targetText?.value?.length > 1
      ? toTitleCase(
          `${targetText?.type} ${targetText?.value?.[0]?.type
            ?.replace(/([A-Z]+)/g, " $1")
            ?.replace(/([A-Z][a-z])/g, " $1")} ${
            targetText?.value?.[0]?.value
              ? `${targetText?.value?.[0]?.value}`
              : ""
          }, ${targetText?.value?.[1]?.value} day ${
            targetText?.value?.[1]?.type
          }`
        ).replace("Asin", "ASIN")
      : // Below handles expanded category targets with expression params
      typeof targetText === "object" && targetText?.length > 1
      ? targetText?.[0]?.value
      : typeof targetText === "object" && targetText?.length === 1
      ? [
          "asinSubstituteRelated",
          "asinAccessoryRelated",
          "queryBroadRelMatches",
          "queryHighRelMatches",
        ]?.includes(targetText?.[0]?.type)
        ? `${targetText?.[0]?.type}`
        : targetText?.[0]?.value
      : [
          "asinSubstituteRelated",
          "asinAccessoryRelated",
          "queryBroadRelMatches",
          "queryHighRelMatches",
          "similarProduct",
        ]?.includes(targetText?.type)
      ? `${formatAmazonAutoTarget(targetText?.type)}`
      : typeof targetText?.value === "object" && targetText?.value?.length === 1
      ? targetText?.value?.[0]?.value
      : targetText?.value
    : targetText;
};
