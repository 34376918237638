import React, { useEffect, useRef } from "react";

const BeamerInAppNotifications = ({ user, children }) => {
  const beamerRef = useRef(null);
  useEffect(() => {
    // Below ignores beamer locally to avoid occasional crashes due to beamerRef being null
    if (
      window.location?.hostname?.includes("localhost") ||
      window.location?.hostname?.includes("127.0.0.1")
    ) {
      return;
    }

    if (!beamerRef.current) {
      beamerRef.current = window.Beamer;
      beamerRef.current.init();
    }
    if (user.user_id && beamerRef.current && !user.is_staff) {
      beamerRef.current.update({ user_id: user.user_id });
    }
    return () => {
      beamerRef.current.destroy();
      beamerRef.current = null;
    };
  }, [user]);
  return <>{children}</>;
};

export default BeamerInAppNotifications;
