import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Section = ({
  name,
  Icon,
  ActiveIcon,
  children,
  activeSection,
  activeColor,
  isActiveSection,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const childRef = useRef(null);

  const invertTextWhenActive = true;

  useEffect(() => {
    if (childRef.current) {
      // Check if any of the children are active and set open section
      const hasActiveChildren = childRef.current.querySelector("li.active");
      setIsOpen(!!hasActiveChildren);
    }
  }, [childRef]);
  return (
    <li
      className={`user ${
        name.toLowerCase() === activeSection ? "active-section" : ""
      }`}
      style={{
        marginBottom: "2rem",
        marginTop: 0,
        paddingBottom: activeSection ? "1rem" : 0,
        borderBottom: "none",
        width: "100%",
        textDecoration: "none",
        borderRadius: "10px",
      }}
    >
      <NavLink
        to="#"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        style={{
          background: isActiveSection ? activeColor : "inherit",
        }}
      >
        {invertTextWhenActive && ActiveIcon && isActiveSection ? (
          <ActiveIcon />
        ) : (
          Icon && <Icon />
        )}
        <p
          style={{
            color: isActiveSection && invertTextWhenActive ? "#FFF" : "#2e0054",
            // color: "#2e0054",
          }}
        >
          {name}
          <span
            className={`caret ${isOpen && `rotate-180`} dropdown-toggle`}
          ></span>
        </p>
      </NavLink>

      <Collapse in={isOpen}>
        <ul className="nav" ref={childRef}>
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

export default Section;
