import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const ModalAlert = ({ title = "error", msg, onClick }) => (
  <SweetAlert
    title={title}
    style={{ display: "block", marginTop: "-100px" }}
    onConfirm={onClick}
  >
    {" "}
    <span style={{ fontSize: "16px" }}>{msg}</span>
  </SweetAlert>
);

export default ModalAlert;
