import React, { useState } from "react";
import { AuthButton } from "components/core/basic/Button";
import CenteredModal from "components/core/basic/CenteredModal";
import { Row, Col } from "react-bootstrap";

const ConfirmationModal = ({
  title,
  confirmationMessage,
  onConfirm,
  confirmButtonText,
  modalButtonText,
  loading,
  loadingModalButtonText,
  buttonStyle = "adplan_button",
  disableConfirmButton = false,
  dialogClassName,
  overrideButtonContainerStyles = {},
  modelButtonStyle = { marginRight: "2rem" },
  modelButtonClass = null,
  disabled = false,
  inlineModal = false,
  onCancel = null,
  inlineButtonContainerStyles = null,
  defaultShowState = false,
  cancelButtonStyles = {},
}) => {
  const [showModal, setShowModal] = useState(defaultShowState ?? false);

  const modalButtonTextToDisplay = loading
    ? loadingModalButtonText
    : modalButtonText;

  return (
    <>
      {showModal && (
        <CenteredModal
          show={showModal}
          closeModal={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowModal(false);
            if (typeof onCancel === "function") {
              onCancel();
            }
          }}
          title={title ? title : ""}
          dialogClassName={dialogClassName ? dialogClassName : "modal-60w"}
          style={{
            marginTop: "2rem",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px 10px",
              textAlign: "center",
            }}
          >
            <Col xs={12} style={{ margin: "0 20px" }}>
              {confirmationMessage}
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                ...overrideButtonContainerStyles,
              }}
            >
              <AuthButton
                title={"Cancel"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowModal(false);
                  if (typeof onCancel === "function") {
                    onCancel();
                  }
                }}
                style={{ marginRight: "2rem", ...cancelButtonStyles }}
                buttonStyle={buttonStyle}
              />
              <AuthButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onConfirm();
                  setShowModal(false);
                }}
                title={confirmButtonText}
                buttonStyle={buttonStyle}
                disabled={disableConfirmButton}
              />
            </Col>
          </Row>
        </CenteredModal>
      )}

      {!inlineModal ? (
        <AuthButton
          title={modalButtonTextToDisplay}
          buttonStyle={modelButtonClass ? modelButtonClass : buttonStyle}
          onClick={() => setShowModal(!showModal)}
          style={modelButtonStyle}
          disabled={disabled || loading}
        />
      ) : (
        <div
          style={
            inlineButtonContainerStyles
              ? inlineButtonContainerStyles
              : { width: "100%", height: "100%", textAlign: "left" }
          }
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowModal(true);
          }}
        >
          {modalButtonTextToDisplay}
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
