import React from "react";
import { useMemo } from "react";
import moment from "moment";
import ChartMiddleware from "../growth/ChartMiddleware";
import { useLocation, useParams } from "react-router";

const MultiMetricChart = ({
  data,
  metrics,
  size,
  mobileStyles,
  selectedMetrics,
  noData = "No Data available",
  loading = false,
  showAnnotations = true,
  group = "day",
  handleLegendChange,
  datesFormatted = false,
  selectedCategory = null,
  selectedAd = null,
  selectedProduct = null,
  highlightDate = null,
}) => {
  const height = size === "small" || mobileStyles ? 215 : 315;
  const { category: categoryParam = null } = useParams();
  const { search } = useLocation();
  const category = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return (
      categoryParam || urlParams.get("categoryId") || selectedCategory || "all"
    );
  }, [categoryParam, search, selectedCategory]);
  const [hasData, chartOptions, series] = useMemo(() => {
    const dates = data?.map((d) =>
      moment(d.start_date).format(
        group === "month" ? "MMM YYYY" : "MMM DD YYYY"
      )
    );

    const series = metrics
      .map(
        ({ id, color, name, accessor, axis = 0, chartType = "area" }, idx) => {
          let chartData = data?.map((d) => accessor(d) ?? null);
          chartData =
            chartData?.filter((d) => d)?.length === 0 ? [] : chartData;
          const additionalOptions = {};
          if (chartType === "area") {
            additionalOptions["fillOpacity"] = 0.1;
          }
          if (chartType === "column") {
            additionalOptions["opacity"] = 0.8;
          }
          return {
            custom: { id },
            legendIndex: idx,
            data: chartData,
            color,
            name,
            type: chartType,
            yAxis: axis,
            visible: selectedMetrics.includes(id),
            ...additionalOptions,
          };
        }
      )
      .sort((a, b) => a.yAxis - b.yAxis);
    const hasData = series.some((s) => s.data.length > 0);

    return [
      hasData,
      {
        title: {
          text: null,
        },
        chart: {
          height: 150,
        },
        xAxis: {
          categories: dates,
          labels: {
            enabled: true, //!(size === "small" || mobileStyles),
            customFormatter: function (value) {
              return moment(value).format(
                group === "month" ? "MMM 'YY" : "MMM D"
              );
            },
          },
          tickInterval:
            dates.length <= 12 ? 1 : size === "small" || mobileStyles ? 4 : 2,
          plotLines: highlightDate
            ? [
                {
                  color: "rgba(238, 84, 140, 0.5)",
                  fillOpacity: 0.5,
                  value: dates.indexOf(
                    moment(highlightDate).format(
                      group === "month" ? "MMM YYYY" : "MMM DD YYYY"
                    )
                  ),
                  width: 10,
                },
              ]
            : [],
        },
        yAxis: Array.from(Array(50)).map(() => ({
          title: {
            text: "",
          },
          visible: false,
          maxPadding: 0,
        })),
        legend: {},
        tooltip: {
          shared: true,
          formatter: function () {
            let points = this.points;

            let markup = points.length
              ? '<span style="font-size: 10px; font-weight: bold; margin-left: 1rem">' +
                points[0].key +
                "</span><br/>"
              : "";

            for (let index = 0; index < points.length; index++) {
              let point = points[index];

              // Get formatter => find metric with this point.series.name, get corresponding formatter from props
              let fullMetric = metrics.find(
                (m) => m.name === point?.series?.name
              );

              const { formatter } = fullMetric ?? {};

              if (point.series.name !== "annotationAxis") {
                markup +=
                  '<span style="color:' +
                  point.series.color +
                  '">\u25CF</span> ' +
                  point.series.name +
                  ": " +
                  formatter(point.y) +
                  "<br/>";
              }
            }

            return markup;
          },
        },
      },
      series,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, metrics, selectedMetrics, mobileStyles, height, size]);
  const showOverlay = !hasData || loading;

  const options = useMemo(() => {
    return { options: chartOptions, series: series };
  }, [chartOptions, series]);

  return (
    <div style={{ position: "relative" }}>
      {showOverlay && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            width: "100%",
            fontWeight: 600,
            color: "#666",
            fontSize: "20px",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            {loading ? <span className="fa fa-spinner fa-spin" /> : noData}
          </div>
        </div>
      )}
      <div className="">
        <ChartMiddleware
          data={hasData}
          options={options}
          context={"multi-metric"}
          // TODO: Consider removing category check below and evaluating showAnnotations at a higher levels to avoid always needing to pass down category id
          showAnnotations={showAnnotations}
          rollup={{
            rollup:
              group === "day"
                ? "daily"
                : group === "week"
                ? "weekly"
                : "monthly",
          }}
          selectedCategory={category}
          selectedAd={selectedAd}
          selectedProduct={selectedProduct}
          height={height}
          handleLegendChange={handleLegendChange}
          datesFormatted={datesFormatted}
        />
      </div>
    </div>
  );
};

export default MultiMetricChart;
