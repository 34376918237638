import axios from "axios";
import NavFilterTileMUI from "components/core/blocks/NavFilterTileMUI";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setMarketPlace } from "redux/actions/marketPlace";
import { getURLPrefix } from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

class SellerRegionMUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableRegions: [],
      currentRegion: this.props.sellerRegion,
      regionDropdownData: null,
      regionUpdated: false,
    };
  }

  componentDidMount() {
    this.fetchUserMarketplaces();
  }

  componentDidUpdate() {
    if (this.state.currentRegion !== this.props.sellerRegion) {
      this.setState({ currentRegion: this.props.sellerRegion });
    }
  }

  fetchUserMarketplaces() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/sellerinfo/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.data.account_region) {
          this.setState({ availableRegions: res.data.account_region }, () => {
            var data = {
              "North America": { NA: this.regionStatus("CA") },
              Europe: { EU: this.regionStatus("EU") },
              "Far East": { FE: this.regionStatus("FE") },
            };
            this.setState({ marketplaceDropdownData: data });
          });
        }
      });
  }

  onRegionClick = (id) => {
    this.props.assessAvailableMarketplaces(id);
  };

  regionStatus(id) {
    var status = true;
    if (this.state.availableRegions.length > 0) {
      for (var i = 0; i < this.state.availableRegions.length; i++) {
        if (this.state.availableRegions[i] === id) {
          status = false;
          break;
        }
      }
    }
    return status;
  }

  selectedRegion(regionKey) {
    if (regionKey === "NA") {
      return "North America";
    } else if (regionKey === "EU") {
      return "Europe";
    } else if (regionKey === "FE") {
      return "Far East";
    } else {
      return regionKey;
    }
  }

  render() {
    var data = [
      {
        "": [
          { "North America": { NA: this.regionStatus("NA") } },
          { Europe: { EU: this.regionStatus("EU") } },
          { "Far East": { FE: this.regionStatus("FE") } },
        ],
      },
    ];

    return (
      <NavFilterTileMUI
        handleChildComponent={this.onRegionClick}
        data={data}
        marketPlaceUpdated={this.state.regionUpdated}
        defaultValue={
          localStorage.EditAmzMwsRegion
            ? this.selectedRegion(localStorage.EditAmzMwsRegion.toUpperCase())
            : "Seller Region"
        }
        region={this.state.sellerRegion}
        isRegionSelector={true}
      ></NavFilterTileMUI>
    );
  }
}

SellerRegionMUI.propTypes = {
  setMarketPlace: PropTypes.func.isRequired,
  marketPlace: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  setMarketPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerRegionMUI);
