import ReactGA from "react-ga4";
import { GA_TRACKING_ID } from "utils/gvSecrets";
import { getIsLocalhost } from "utils/isLocalHost";
import useGaTracking from "hooks/useGaTracking";

const GoogleAnalytics = () => {
  useGaTracking();

  ReactGA.initialize(GA_TRACKING_ID, {
    gaOptions: {
      debug_mode: getIsLocalhost(),
    },
    gtagOptions: {
      debug_mode: getIsLocalhost(),
    },
  });

  return null;
};

export default GoogleAnalytics;
