import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, withStyles } from "@material-ui/core";
import React, { useRef, useEffect } from "react";
import { bin } from "d3-array";
import ReactEcharts from "echarts-for-react";
import { trellisPalette } from "./palettes";
import { formatNumber } from "utils/formatNumber";
import FilterBar from "./FilterBar";

const useStyles = (theme) => ({});

const PricingView = ({
  shelfStatsTotalData,
  homeBrand,
  shelfData,
  marketPlace,
  selectedShelf,
  selectedSearchId,
  filterSearchId,
  selectedRollupType,
  filterRollupType,
  setSelectedDateRange,
  dateRange,
  isLoading,
}) => {
  const containerRef = useRef();
  const echartRef = useRef();
  useEffect(() => {
    const echartInstance = echartRef.current?.getEchartsInstance();
    if (!echartInstance) return;
    if (shelfStatsTotalData) {
      const prices = shelfStatsTotalData.reduce(
        (a, d) =>
          a.concat(
            d.prices.map((price) => ({
              brand: d.brand,
              price,
            }))
          ),
        []
      );
      const bins = bin()
        .value((d) => d.price)
        .thresholds(9)(prices);
      const fmtPrice = (p) =>
        formatNumber(p, {
          currency: { marketPlace: marketPlace.marketPlace },
        });
      const options = {
        legend: {
          show: true,
          bottom: "bottom",
        },
        xAxis: {
          type: "category",
          data: bins.map((b) => `${fmtPrice(b.x0)} - ${fmtPrice(b.x1)}`),
        },
        title: {
          show: false,
          textStyle: {
            color: "#d4587f",
            fontSize: 22,
            fontFamily: "Poppins",
            fontWeight: "400",
          },
          text: "Best Seller Ranks",
          left: "center",
          top: "center",
        },
        grid: {
          top: 60,
          bottom: 60,
          left: "2.5%",
          right: "2.5%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#152748",
            },
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
              title: { zoom: "Zoom In", back: "Zoom Out" },
            },
            restore: { show: true, title: "Reset" },
          },
        },
        yAxis: {
          show: false,
          type: "value",
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "All Brands",
            data: bins.map(
              (b) =>
                b.filter(
                  (d) => d?.brand?.toLowerCase() !== homeBrand?.toLowerCase()
                ).length
            ),
            type: "bar",
            color: trellisPalette[2],
            stack: "Prices",
          },
          {
            name: "Home Brand",
            data: bins.map((b) => ({
              itemStyle: {
                backgroundColor: "#a90000",
              },
              value: b.filter(
                (d) => d?.brand?.toLowerCase() === homeBrand?.toLowerCase()
              ).length,
            })),
            type: "bar",
            color: trellisPalette[0],
            stack: "Prices",
          },
        ],
      };
      echartInstance.clear();
      echartInstance.setOption(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelfStatsTotalData, filterSearchId, filterRollupType, homeBrand]);
  return (
    <Grid style={{ marginTop: "-3rem" }}>
      <div ref={containerRef}>
        <FilterBar
          shelfData={shelfData}
          filterSearchId={filterSearchId}
          selectedShelf={selectedShelf}
          selectedSearchId={selectedSearchId}
          selectedRollupType={selectedRollupType}
          filterRollupType={filterRollupType}
          setSelectedDateRange={setSelectedDateRange}
          dateRange={dateRange}
          isLoading={isLoading}
        />
        <Grid
          container
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: "rgb(115, 112, 110)",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Price Distribution
          </span>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            padding: "1rem",
          }}
        >
          <ReactEcharts
            option={{}}
            ref={echartRef}
            style={{
              height: "470px",
              width: "100%",
            }}
          />
        </Grid>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(PricingView))
);
