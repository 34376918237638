import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
  Col,
  Container as Grid,
  Row,
  Table,
  Badge,
  Card,
} from "react-bootstrap";
import { useFetch } from "hooks/api";
import Loading from "components/core/blocks/Loading";
import moment from "moment";
import { formatNumber, formatPercent } from "utils/formatNumber";

const SellerPerformance = ({ user, marketPlace, channel }) => {
  const { isSuccess, data, isLoading } = useFetch(
    ["seller_performance", marketPlace.marketPlace],
    `/merchandise/seller_performance?marketplace=${marketPlace.marketPlace}`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const getValue = (value) => {
    if (value.rate !== undefined && value.rate !== null) {
      return formatPercent(value.rate);
    }
    if (value.defectsCount !== undefined && value.defectsCount !== null) {
      return formatNumber(value.defectsCount);
    }
    return "n/a";
  };

  const getTitle = (key) => {
    let title = key.substr(0, 1).toUpperCase() + key.substr(1);
    return title.match(/[A-Z][a-z]+/g).join(" ");
  };

  const accountHealtRating = useMemo(() => {
    if (!data) {
      return null;
    }

    const rating = data.find((d) => d.key === "accountHealthRating");

    if (!rating) {
      return null;
    }

    return rating.value;
  }, [data]);

  const getCondition = (condition) => {
    if (condition === "LESS_THAN") {
      return "<";
    }
    if (condition === "LESS_THAN_OR_EQUAL_TO") {
      return "<=";
    }
    if (condition === "EQUALS") {
      return "=";
    }
    if (condition === "GREATER_THAN_OR_EQUAL_TO") {
      return ">=";
    }
    if (condition === "GREATER_THAN") {
      return ">";
    }
    if (condition === "BETWEEN") {
      return "between";
    }
    if (condition === "NOT_BETWEEN") {
      return "not between";
    }
    if (condition === "IN") {
      return "in";
    }
    if (condition === "NOT_IN") {
      return "not in";
    }
    if (condition === "CONTAINS") {
      return "contains";
    }
    if (condition === "DOES_NOT_CONTAIN") {
      return "does not contain";
    }

    return condition;
  };

  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col md={12} className="py-5 px-5 mb-4">
          {isLoading && <Loading />}

          {isSuccess && (
            <>
              <Card>
                <Card.Title>
                  <Row>
                    <Col className="px-5">
                      <h4>Seller Performance Metrics</h4>
                    </Col>
                  </Row>
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col md={12} className="px-5 pb-5">
                      <span className="fs-4 fw-bold">
                        Overall Account Health
                      </span>
                      {accountHealtRating && (
                        <>
                          <Row className="fs-4 pt-3">
                            <Col col={3}>Health Reporting From</Col>
                            <Col col={3}>Health Reporting To</Col>
                            <Col col={3}>Health Status</Col>
                            <Col col={3}>Health Score</Col>
                          </Row>
                          <Row className="fs-4 pt-1">
                            <Col col={3}>
                              {moment(
                                accountHealtRating.reportingDateRange?.reportingDateFrom.substr(
                                  0,
                                  10
                                )
                              ).format("MMMM D, YYYY")}
                            </Col>
                            <Col col={3}>
                              {moment(
                                accountHealtRating.reportingDateRange?.reportingDateTo.substr(
                                  0,
                                  10
                                )
                              ).format("MMMM D, YYYY")}
                            </Col>
                            <Col col={3}>
                              <Badge
                                className={`badge ${
                                  accountHealtRating.ahrStatus === "GOOD" ||
                                  accountHealtRating.ahrStatus === "GREAT"
                                    ? "bg-trellis-mauve"
                                    : "bg-trellis-fuchsia"
                                } me-2`}
                                pill
                              >
                                {accountHealtRating.ahrStatus}
                              </Badge>
                            </Col>
                            <Col col={3}>
                              {formatNumber(accountHealtRating.ahrScore)}
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-start">Key</th>
                    <th className="text-start">Start Date</th>
                    <th className="text-start">End Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-end">Target</th>
                    <th className="text-end">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d) => {
                    if (!d.value.reportingDateRange) {
                      return null;
                    }
                    if (d.key === "accountHealthRating") {
                      return null;
                    }
                    return (
                      <tr key={getTitle(d.key)}>
                        <td className="text-start">{getTitle(d.key)}</td>
                        <td className="text-start">
                          {moment(
                            d.value.reportingDateRange?.reportingDateFrom.substr(
                              0,
                              10
                            )
                          ).format("MMMM D, YYYY")}
                        </td>
                        <td className="text-start">
                          {moment(
                            d.value.reportingDateRange?.reportingDateTo.substr(
                              0,
                              10
                            )
                          ).format("MMMM D, YYYY")}
                        </td>
                        {typeof d.value === "object" && d.value.length && (
                          <>
                            <td className="text-center">
                              List
                              <Badge
                                className={`badge ${
                                  d.value[0]?.status === "GOOD"
                                    ? "bg-trellis-mauve"
                                    : "bg-trellis-fuchsia"
                                } me-2`}
                                pill
                              >
                                {d.value[0].status}
                              </Badge>
                            </td>
                            <td className="text-end">
                              {d.key.indexOf("Rate") > -1
                                ? formatPercent(d.value[0].targetValue, 2)
                                : formatNumber(d.value[0].targetValue)}
                            </td>
                            <td className="text-end">{getValue(d.value[0])}</td>
                          </>
                        )}
                        {typeof d.value === "object" && !d.value.length && (
                          <>
                            <td className="text-center">
                              <Badge
                                className={`badge me-2 ${
                                  d.value?.status === "GOOD"
                                    ? "bg-trellis-mauve"
                                    : "bg-trellis-fuchsia"
                                }`}
                                pill
                              >
                                {d.value.status}
                              </Badge>
                            </td>
                            <td className="text-end">
                              <span className="pe-3 fw-bold">
                                {getCondition(d.value.targetCondition)}
                              </span>
                              {d.key.indexOf("Rate") > -1
                                ? formatPercent(d.value.targetValue, 2)
                                : formatNumber(d.value.targetValue)}
                            </td>
                            <td className="text-end">{getValue(d.value)}</td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  channel: state.channel.currentChannel,
});

export default connect(mapStateToProps)(SellerPerformance);
