import { AuthButton } from "components/core/basic/Button";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import AssetManagement from "views/AssetManagement";

class Creatives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      marketPlace: this.props.marketPlace.marketPlace,
      redirectClick: null,
      selectedButton: localStorage.getItem("videoEdit")
        ? "videoEditor"
        : "assetManagement",
      saveVideoEdit: null,
    };
  }

  componentWillUnmount() {
    let saveVideoEdit = localStorage.getItem("videoEdit");
    if (saveVideoEdit) {
      this.setState({ saveVideoEdit: saveVideoEdit }, () =>
        localStorage.removeItem("videoEdit")
      );
    }
  }

  buttonStyle(activePage) {
    if (this.state.selectedButton === activePage) {
      return "buttonActive";
    } else {
      return "button";
    }
  }

  selectedComponent(page) {
    this.setState({ selectedButton: page });
  }

  render() {
    return (
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Row style={{ marginTop: "0rem" }}>
          <Col md={12}>
            <div className="wrap">
              <AuthButton
                title="Asset Management"
                buttonStyle={this.buttonStyle("assetManagement")}
                onClick={(e) => {
                  let saveVideoEdit = localStorage.getItem("videoEdit");
                  if (saveVideoEdit) {
                    this.setState({ saveVideoEdit: saveVideoEdit }, () =>
                      localStorage.removeItem("videoEdit")
                    );
                  }
                  this.selectedComponent("assetManagement");
                }}
                style={{
                  fontSize: "12px",
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
              />
              <AuthButton
                title="Video Editor"
                buttonStyle={this.buttonStyle("videoEditor")}
                onClick={(e) => {
                  if (this.state.saveVideoEdit) {
                    localStorage.setItem("videoEdit", this.state.saveVideoEdit);
                  }
                  this.selectedComponent("videoEditor");
                }}
                style={{ fontSize: "12px" }}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "0rem", padding: "1rem 2rem" }}>
          <AssetManagement
            {...this.props}
            showAssetManagement={
              this.state.selectedButton === "assetManagement"
            }
            showVideoEditor={this.state.selectedButton === "videoEditor"}
            switchToVideoEditor={() => {
              this.selectedComponent("videoEditor");
            }}
            switchToAssetManagement={() => {
              this.selectedComponent("assetManagement");
            }}
          />
        </Row>
      </div>
    );
  }
}

Creatives.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  errors: state.errors,
  amz_onboard: state.amz_onboard,
  marketPlace: state.marketPlace,
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Creatives));
