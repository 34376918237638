import React from "react";
import { withStyles, Slider } from "@material-ui/core";

const OptimizationSlider = withStyles({
  root: {
    color: "#ddd",
    opacity: "0.7",
  },
  thumb: {
    height: 22,
    width: 22,
    border: "1px solid #C53066",
    "&:focus,&:hover,&$active": {
      boxShadow: "none",
      border: "1px solid #C53066",
    },
  },
  active: {
    color: "#62407f",
  },
  track: {
    height: 10,
    borderRadius: 10,
    color: "#C53066",
  },
  rail: {
    height: 10,
    borderRadius: 24,
    opacity: 1,
  },
  mark: {
    color: "#FFF",
    zIndex: 1000,
    height: 10,
    marginLeft: 3,
    marginTop: 0,
  },
  markLabel: {
    fontSize: "11px",
    whiteSpace: "pre",
  },
})(Slider);

const PlanSlider = ({ planMode, setPlanMode, disabled = false }) => {
  return (
    <OptimizationSlider
      key={"optimization-slider"}
      aria-labelledby="discrete-slider-always"
      step={1}
      min={0 - 2}
      max={2}
      ThumbComponent={"div"}
      marks={[
        { value: -2, label: "Maximize\nVolume" },
        { value: -1, label: "Boost\nVolume" },
        { value: 0, label: "Balanced\nStrategy" },
        { value: 1, label: "Boost\nMargin" },
        { value: 2, label: "Maximize\nMargin" },
      ]}
      value={planMode}
      valueLabelDisplay="off"
      onChange={(e, value) => {
        if (value < -2) {
          value = -2;
        } else if (value > 2) {
          value = 2;
        }
        setPlanMode(value);
      }}
      size="medium"
      disabled={disabled}
    />
  );
};

export default PlanSlider;
