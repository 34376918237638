import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatNumber } from "utils/formatNumber";
import getMetricSpec from "views/merchandise/metrics";

export const TooltipSearchability = () => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-disabled" bsPrefix="tr-popover">
          {getMetricSpec("", "seo_score").toolTip}
        </Tooltip>
      }
    >
      <i className="fa fa-info-circle"></i>
    </OverlayTrigger>
  );
};

export const TooltipBuyability = () => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-disabled" bsPrefix="tr-popover">
          {getMetricSpec("", "buyability_score").toolTip}
        </Tooltip>
      }
    >
      <i className="fa fa-info-circle"></i>
    </OverlayTrigger>
  );
};

export const EstUnits = ({ term, units, searchTerm }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-disabled" bsPrefix="tr-popover">
          These are the estimated amount of units sold for product that contain
          the <strong>Term</strong> in the search result of Amazon.
          <div className="mt-3">
            For example: All products that contain the term{" "}
            <strong>
              <em>{term}</em>
            </strong>{" "}
            in their title when a customer searches for{" "}
            <strong>
              <em>{searchTerm}</em>
            </strong>{" "}
            on Amazon, sell approximately{" "}
            <strong>
              <em>{formatNumber(units)}</em>
            </strong>{" "}
            units per month.
          </div>
        </Tooltip>
      }
    >
      <i className="fa fa-info-circle"></i>
    </OverlayTrigger>
  );
};
