import React from "react";
import ToolTips from "utils/toolTips";

const DetailedMetric = ({
  id,
  name,
  value,
  formatter,
  target,
  showTarget = false,
  lower = false,
  showTargetLabel,
  targetLabel = "vs. target",
  targetColor = null,
  legendColor = null,
  targetFormatter = null,
  mobileStyles,
  additionalMobileStyles = {},
  size = "normal",
  selected = null,
  toolTip = null,
  center = false,
}) => {
  const defaultFormatter = (v) => v;
  const format = formatter || defaultFormatter;
  const formatTarget = targetFormatter || format;
  const cmp = (a, b) => (lower ? a <= b : a >= b);
  let color = cmp(value, target) ? "#007000" : "#d2222d";
  if (targetColor) {
    color = targetColor;
  }
  return (
    <div style={{}}>
      <div className={`text-center ${center ? "" : "text-md-end"}`}>
        <span style={{ fontWeight: 500, color: "#666", fontSize: "1.5rem" }}>
          {name}
          {toolTip && <ToolTips id={id} toolTip={toolTip} />}
        </span>
      </div>
      <div
        className={`text-center ${center ? "" : "text-md-end"}`}
        style={{
          ...(mobileStyles ? { ...additionalMobileStyles } : {}),
        }}
      >
        <h3
          style={{
            fontWeight: "600",
            fontSize: "2rem",
            marginBottom: "0.2rem",
            marginTop: "0.2rem",
          }}
        >
          {format(value)}
        </h3>
        <div style={{ minHeight: "20px" }}>
          <small>
            {showTarget ? (
              <>
                {showTargetLabel && <>{targetLabel} </>}
                <span style={{ color, fontWeight: "bold" }}>
                  {formatTarget(target)}
                </span>
              </>
            ) : (
              " "
            )}
          </small>
        </div>
      </div>
    </div>
  );
};

export default DetailedMetric;
