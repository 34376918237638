import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

const BulkActionButton = ({
  selected = [],
  options = [],
  hidden,
  onCancelModal,
}) => {
  if (hidden) {
    return null;
  }

  return (
    <DropdownButton
      id={`dropdown-split-variants`}
      title={`Bulk Actions  (${selected?.length} selected)`}
      className={`tr-split-button${
        selected?.length === 0 ? "-disabled" : ""
      } bulk-actions tr-dropdown-button`}
      bsPrefix={`tr-sp`}
      style={{ fontSize: "10px", transform: "none" }}
      variant={""}
      disabled={selected?.length === 0}
    >
      {options
        ?.filter((option) => !option.hidden)
        ?.map((option, i) => {
          const {
            label,
            action,
            confirmationMessage,
            actionFormFields,
            disableConfirmButton,
            divider,
            disabled,
          } = option;

          if (confirmationMessage) {
            return (
              <ConfirmationModal
                key={i}
                title={"Confirm Bulk Action"}
                onCancel={onCancelModal}
                onConfirm={() => action(selected)}
                inlineModal={true}
                confirmButtonText={"Confirm"}
                disableConfirmButton={disableConfirmButton}
                confirmationMessage={
                  <>
                    {actionFormFields}
                    {confirmationMessage}
                  </>
                }
                modalButtonText={
                  <Dropdown.Item eventKey={i} disabled={disabled}>
                    {label}
                  </Dropdown.Item>
                }
              />
            );
          }

          if (divider) {
            return <Dropdown.Divider />;
          }
          return (
            <Dropdown.Item
              key={i}
              eventKey={i}
              onClick={() => action(selected)}
              disabled={disabled === true ? true : false}
            >
              {label}
            </Dropdown.Item>
          );
        })}
    </DropdownButton>
  );
};

export default BulkActionButton;
