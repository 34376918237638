import React, { useState, useMemo, useContext, useRef } from "react";
import { AdPlanContext } from "components/core/blocks/AdPlan/AdPlanContext";
import { useMediaQuery } from "@react-hook/media-query";
import { useFetch } from "hooks/api";
import { Col, Container as Grid, Row } from "react-bootstrap";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import { connect } from "react-redux";
import { trellisPalette } from "components/custom/analytics/palettes";
import Accordion from "components/core/basic/Accordion";
import useColumnSelector from "hooks/useColumnSelector";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { getURLPrefix } from "utils/getUrlPrefix";
import NoImage from "assets/images/utility/no_image.png";
import { useParams, useLocation } from "react-router-dom";
import DownloadReport from "modules/perf_reports/DownloadReport";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import TrComponent from "components/custom/merchandise/TrComponent";
import Loading from "components/core/blocks/Loading";
import getMetricSpec from "./merchandise/metrics";
import { selectModules } from "redux/selectors/modules";
import AdPlanModal from "components/core/blocks/AdPlan/AdPlanModal";
import AdPlanBulkCreate from "components/core/blocks/AdPlan/AdPlanBulkCreate";
import { snakeCaseToSentenceCase } from "utils/formatText";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import AdvertisingWelcomePanel from "./AdvertisingWelcomePanel";
import { AuthButton } from "components/core/basic/Button";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";
import InsightsTable from "./advertising/InsightsTable";
import checkModule from "utils/checkModule";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import TrellisLink from "components/core/basic/TrellisLink";
import AdvertisementsTable from "./AdvertisementsTable";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import NotificationSystem from "react-notification-system";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import FilterWidget from "./merchandise/tables/FilterWidget";
import useTableSettings from "hooks/useTableSettings";
import AdTypePieCharts from "./advertising/AdTypePieCharts";
import {
  extendAllEntityMetricOptions,
  LEGACY_METRIC_IDS_MAP,
} from "./merchandise/tables/metricUtils";
import { METRIC_GROUPING_LOOKUP } from "./merchandise/tables/metricsByEntity";

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  return metric;
};

let URL_PREFIX = getURLPrefix();

const getDashboardTo = (category = null) => {
  return category
    ? `/user/advertising/dashboard/category/${category}`
    : `/user/advertising/dashboard`;
};

const DashboardLink = ({ children, category, additionalClasses = "" }) => {
  const to = getDashboardTo(category);

  return (
    <TrellisLink
      id="categoryPage"
      to={to}
      className={additionalClasses}
      style={{
        textDecoration: "none",
        fontWeight: "500",
        fontSize: "1.5rem",
        cursor: "pointer",
      }}
    >
      {children}
    </TrellisLink>
  );
};

const CategoryCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const categoryId = props?.original?.category_id;
  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?category=${categoryId}`;
  return (
    <DashboardLink
      category={categoryId}
      categoryDetails={props?.original}
      date={props.date}
    >
      <div style={{ display: "flex" }}>
        <div
          onError={(e) => {
            setHasError(true);
          }}
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            flexShrink: 0,
            width: 50,
            height: 50,
            marginRight: "10px",
            background: `url(${image}) no-repeat center / contain`,
          }}
          draggable="false"
        />
        <div className={"fs-standard text-underline-hover"}>
          <strong className={"text-underline-hover"}>{props?.value}</strong>
        </div>
      </div>
    </DashboardLink>
  );
};

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const makeColumn =
  (data, marketPlace, mobileStyle, date, setIsExpanded) =>
  (header, key, type, options) => {
    const { hideFilter } = options ?? {};

    const defaultAccessor = (p) => p[key];
    const accessor = options?.accessor ?? defaultAccessor;
    const defaultFormat = ({ value }) => value;
    const fmt =
      options?.formatter ??
      {
        expander: ({ row, value }) => (
          <ExpanderCell
            row={row}
            // TODO: Make expander value more versatile,
            value={row?._original?.product_id}
            isExpanded={value}
            setIsExpanded={setIsExpanded}
          />
        ),
        currency: ({ value }) =>
          formatCurrency(
            value,
            marketPlace.marketPlace,
            options?.clipDecimalPlaces ?? true
          ),
        number: ({ value }) =>
          formatNumber(value, {}, options?.decimalPlaces ?? 1),
        percent: ({ value }) =>
          formatPercent(value, options?.decimalPlaces ?? 1),
        category: CategoryCell,
        percentDiff: ({ value }) => (
          <span
            style={{
              color: value > 0 ? trellisPalette[12] : trellisPalette[17],
            }}
          >
            {formatPercent(value, 0, true)}
          </span>
        ),
      }?.[type] ??
      defaultFormat;
    const parser =
      {
        currency: parseFormattedCurrency,
        number: parseFormattedNumber,
        percent: parseFormattedPercentage,
      }?.[type] ?? defaultFormat;

    let width;
    if (mobileStyle) {
      width = options?.mobileWidth;
    } else {
      width = options?.width;
    }

    // Handle tooltips to support metric selection menu descriptions
    let { toolTip } = getMetricSpec(marketPlace, key);
    if (!toolTip) {
      let alternateMetricSpec = getMetricSpec(
        marketPlace,
        LEGACY_METRIC_IDS_MAP[key]
      );
      toolTip = alternateMetricSpec?.toolTip;
    }

    // Handle percent filter formatting
    if (type === "percent") {
      data = data.map((row) => {
        Object.keys(row).forEach((propertyKey) => {
          if (propertyKey === key) {
            row["formatted_" + propertyKey] = row[propertyKey] * 100;
          }
        });

        return row;
      });
    }

    return {
      Header: header,
      id: key,
      accessor,
      checked: options?.checked ?? true,
      maxWidth: options?.maxWidth,
      minWidth: options?.minWidth,
      isStatic: options?.isStatic,
      width,
      height: 30,
      grouping: METRIC_GROUPING_LOOKUP[key],
      format: type,
      toolTip,
      className: options?.className ?? "text-end",
      headerClassName: options?.className ?? "text-end",
      Cell: (props) => {
        if (options?.showDot ?? true) {
          let percentKey = `${key}_percent`;
          if (type === "percent") {
            percentKey = `${key}_delta`;
          }
          const percent = props.original?.[percentKey] ?? null;

          const isNegative = options?.isNegative ?? false;
          const noColor = options?.noColor ?? false;
          let color = "#666";
          if (!noColor && percent > 0) {
            color = isNegative ? trellisPalette[17] : trellisPalette[12];
          }
          if (!noColor && percent < 0) {
            color = isNegative ? trellisPalette[12] : trellisPalette[17];
          }
          return (
            <div>
              {fmt({ ...props, date })}
              <br />
              <span
                style={{
                  color,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                {percent === null
                  ? ""
                  : type === "percent"
                  ? formatPercent(percent, 1, true)
                  : formatPercent(percent, 0, true)}
              </span>
            </div>
          );
        }
        return fmt({ ...props, date });
      },

      style: { height: "60px" },
      filterMethod: (filter, row) => {
        const formattedPercentAccessor = (p) => p[`formatted_${key}`];
        const value =
          type === "percent"
            ? formattedPercentAccessor(row._original)
            : accessor(row._original);
        return filterRanges(filter, value, parser, marketPlace.marketPlace);
      },
      Filter: ({ filter, onChange }) => {
        if (hideFilter) {
          return null;
        }

        return generateFilterMarkup(filter, onChange, key, "range", type, {
          ranges: generateRanges(
            data ?? [],
            5,
            type === "percent" ? `formatted_${key}` : key,
            "number",
            marketPlace.marketPlace
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

const Table = ({
  stats,
  marketPlace,
  mobileStyle,
  columnSpecs,
  date,
  ExpandedContent = "",
  user,
  isLoading = false,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [isExpanded, setIsExpanded] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [colToggle] = useState();

  const TABLE_ID = "advertisingDashboard";
  const tableRef = useRef();

  const {
    customFilters,
    setCustomFilters,
    formatFilterValue,
    applyCustomFilters,
  } = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const {
    savedTableSettings: savedTablePageSize,
    updateTableSettings: setSavedTablePageSize,
  } = useTableSettings(TABLE_ID, "tablePageSize", 5);

  const {
    savedTableSettings: savedTableSortMethod,
    updateTableSettings: setSavedTableSortMethod,
  } = useTableSettings(TABLE_ID, "tableSorting", []);

  const data = useMemo(
    () => {
      return applyCustomFilters(
        stats
          ?.map((p) => (p.product_id === isExpanded ? { ...p, isExpanded } : p))
          ?.filter((p) => {
            if (searchFilter) {
              const searchable = `${p.category_name} ${p.name}`;
              return (
                searchable.toLowerCase()?.indexOf(searchFilter.toLowerCase()) >
                -1
              );
            }
            return true;
          }) ?? []
      );
    },
    // Below linting disabled to take clearFilters in to recalcalculate data on filter reset
    // Without it, filter specs from react-table must be stored at this level and manually cleared on each reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stats, searchFilter, clearFilters, isExpanded, customFilters]
  );

  const createColumn = makeColumn(
    data,
    marketPlace,
    mobileStyle,
    date,
    setIsExpanded
  );
  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let originalColumns = columnSpecs.map((c) =>
        createColumn(c.header, c.key, c.type, c.options)
      );
      return extendAllEntityMetricOptions(
        "category",
        data,
        customFilters,
        originalColumns,
        marketPlace.marketPlace,
        user
      );
    },
    [columnSpecs, stats, mobileStyle, colToggle],
    TABLE_ID
  );
  const columnOrder = columns.map((col) => col.id);

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      <ReactTable
        ref={tableRef}
        showPaginationBottom={false}
        showPaginationTop
        PaginationComponent={ReactTablePagination}
        columns={columns}
        data={data}
        defaultPageSize={savedTablePageSize || 10}
        minRows={5}
        className={`-highlight ${TABLE_ID}`}
        filterable={showFilters}
        state={columnOrder}
        onSortedChange={(newSorted) => {
          setSavedTableSortMethod(newSorted);
        }}
        onPageSizeChange={(pageSize) => {
          setSavedTablePageSize(pageSize);
        }}
        TrComponent={TrComponent}
        defaultSorted={
          savedTableSortMethod || [{ id: "ad_spend", desc: false }]
        }
        getTrProps={(table, row) => {
          let expandedChildren = (
            <ExpandedContent marketPlace={marketPlace} row={row} user={user} />
          );
          return { row, expandedChildren };
        }}
        getTheadFilterProps={(props) => {
          if (clearFilters) {
            props.filtered.forEach((filter, index) => {
              filter.value = "all";
            });
            setClearFilters(false);
          }
          return { style: { display: "flex" } };
        }}
        getPaginationProps={() => ({
          updateSearch: setSearchFilter,
          clearFilters: () => {
            setSearchFilter("");
            setClearFilters(true);
          },
          hideFilters: false,
          showFilters: showFilters,
          setShowFilters: setShowFilters,
          clearSearchTermFilters: () => setSearchFilter(""),
          searchTerm: searchFilter,
          table: "plan-products",
          tableId: TABLE_ID,
          style: { marginBottom: "2rem" },
          carouselLayout: false,
          disableSearchFocus: true,
          filterControlsWidth: "70%",
          columnSelectorProps: columnSelectorProps,
          filterWidget: true,
          hideFilterToggleIcon: true,
          tableEntityType: "category",
          children: (
            <div className="d-flex justify-content-start w-100 h-100 align-items-center px-2">
              <FilterWidget
                customFilters={customFilters}
                setCustomFilters={setCustomFilters}
                formatFilterValue={formatFilterValue}
                tableRef={tableRef}
                excludedFilterColumns={["category_name"]}
                tableId={TABLE_ID}
              />
            </div>
          ),
        })}
      />
    </>
  );
};

const Card = ({
  title,
  start,
  end,
  data,
  isLoading,
  metrics,
  initialSelectedMetric,
  children,
  channel,
  marketPlace,
  user,
}) => {
  const { group } = useDates();

  const { activeAdCount } = useContext(AdPlanContext);
  const initialAdCreated = user.ad_count || activeAdCount;

  const { data: categoriesData } = useFetch(
    ["categories", marketPlace.marketPlace, channel],
    "/api/gvads/categories/",
    { marketplace: marketPlace.marketPlace, channel: channel },
    {
      select: (res) => res.data,
    }
  );

  return (
    <>
      <MetricsCard
        id={"advertisingDashboard"}
        mobileStyle
        title={initialAdCreated ? title : null}
        data={data?.date}
        initialSelectedMetric={initialSelectedMetric}
        metrics={metrics}
        group={group}
        showAnnotations={true}
        isLoading={isLoading}
        showMetricsSelector={true}
        defaultStateContent={!initialAdCreated && <AdvertisingWelcomePanel />}
        hideMetrics={!initialAdCreated}
        byline={
          initialAdCreated ? (
            <>
              {start.format("MMMM Do YYYY")}
              {" - "}
              {end.format("MMMM Do YYYY")}
              {isLoading && <i className="fa fa-spinner fa-spin"></i>}
            </>
          ) : null
        }
      >
        <Row>
          <Col xs={12} lg={12} className={!initialAdCreated ? "mb-5" : ""}>
            <div
              className={
                initialAdCreated ? "text-end mb-3" : "text-center mb-3"
              }
            >
              <AdPlanModal
                dashboard={true}
                categories={categoriesData}
                inlineModal={!initialAdCreated}
                inlineModalText={
                  <AuthButton
                    title="Create Now"
                    buttonStyle="adplan_button px-5 py-4 fs-3 mb-5"
                    style={{ transform: "none" }}
                  />
                }
              />
              <AdPlanBulkCreate marketPlace={marketPlace} channel={channel} />
            </div>
          </Col>
          {children}
        </Row>
      </MetricsCard>
    </>
  );
};

const Dashboard = ({ marketPlace, user, category, modules, channel }) => {
  const mobileStyle = useMediaQuery("(max-width:600px)");
  const { group, date, start, end, preStart, preEnd } = useDates();

  const notificationRef = useRef();
  const { preferences } = useMerchandisePreferences();

  const smallViewportWidthTable = useMediaQuery("(max-width:1550px)");

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    channel: channel,
    empty: true,
  };

  let [key, endpoint, group_by] = [
    ["category_report"],
    "category_report",
    "category_id",
  ];
  if (category) {
    key = ["product_report", category];
    endpoint = "product_report";
    group_by = "product_id";
    filters["category"] = category;
  }
  filters["group_by"] = group_by;

  const { isSuccess, data, isError, isLoading, isFetching } = useFetch(
    [...key, marketPlace.marketPlace, start, end, preStart, preEnd, group],
    `/api/data_report/${endpoint}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { overviewMetrics } = useMemo(() => {
    const acosOrRoas = user?.preferences?.acos_or_roas ?? "acos";
    const roasMetrics = [
      buildMetric(marketPlace, data, "roas", "roas_delta"),
      buildMetric(marketPlace, data, "troas", "troas_delta"),
    ];
    const acosMetrics = [
      buildMetric(marketPlace, data, "acos", "acos_delta"),
      buildMetric(marketPlace, data, "total_acos", "total_acos_delta"),
    ];
    const titleCol = [
      col("Category", "category", "category_name", {
        className: "text-left font-weight-bold",
        width: 350,
        mobileWidth: 250,
        showDot: false,
        fixedLeft: true,
        hideFilter: true,
        isStatic: true,
      }),
    ];
    return {
      overviewMetrics: {
        columns: [
          ...titleCol,
          col("Sales", "currency", "total_sales"),
          col("Ad Sales", "currency", "ad_sales"),
          col("Ad Spend", "currency", "cost", { noColor: true }),
          col("ROAS", "number", "roas", {
            checked: "roas" === acosOrRoas,
          }),
          col("Total ROAS", "number", "total_roas", {
            checked: "roas" === acosOrRoas,
          }),
          col("ACOS", "percent", "acos", {
            checked: "acos" === acosOrRoas,
            isNegative: true,
          }),
          col("Total ACOS", "percent", "total_acos", {
            checked: "acos" === acosOrRoas,
            isNegative: true,
          }),
          col("Clicks", "number", "clicks"),
          col("Impressions", "number", "impressions"),
          col("CTR", "percent", "click_thru_rate", {
            checked: false,
            decimalPlaces: 2,
          }),
          col("Conversions", "number", "conversion", {
            checked: false,
          }),
          col("Conversion Rate", "percent", "conv_rate", {
            checked: false,
            decimalPlaces: 1,
          }),
          col("CPC", "currency", "cost_per_click", {
            checked: false,
            noColor: true,
            clipDecimalPlaces: false,
          }),
        ],
        metrics: [
          buildMetric(marketPlace, data, "sales", "sales_percent"),
          buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
          buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
          ...(acosOrRoas === "acos" ? acosMetrics : roasMetrics),
          ...(acosOrRoas === "acos" ? roasMetrics : acosMetrics),
          buildMetric(marketPlace, data, "clicks", "clicks_percent"),
          buildMetric(marketPlace, data, "impressions", "impressions_percent"),
          buildMetric(marketPlace, data, "ctr", "ctr_delta"),
          buildMetric(marketPlace, data, "orders", "orders_percent"),
          buildMetric(marketPlace, data, "cpc", "cpc_delta"),
          buildMetric(
            marketPlace,
            data,
            "conversion_rate",
            "conversion_rate_delta"
          ),
        ],
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user]);

  const breadCrumbItems =
    channel === "walmart" && user.organization_type === "vendor"
      ? [
          {
            name: "Advertising Dashboard",
            href: getDashboardTo(),
          },
        ]
      : [
          {
            name: "Advertising Dashboard",
            href: getDashboardTo(),
            unsorted: true,
            dropdownLinkOptions: DASHBOARD_LINKS,
            dropdownSubtitle: "Dashboards",
          },
        ];

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  const { data: adData, isLoading: isAdDataLoading } = useFetch(
    ["ad_report", marketPlace.marketPlace, start, end, preStart, preEnd, group],
    `/api/data_report/ad_report`,

    {
      ...filters,
      group_by: "ad_id",
    },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      default: [],
    }
  );

  const adPlans = useMemo(() => {
    return (
      adData?.adplans.map((adplan) => ({
        ...adplan,
        id: adplan.ad_id,
      })) ?? []
    );
  }, [adData]);

  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {isLoading && <Loading />}
          {isError && <p>Error Loading Dashboard</p>}
          {isSuccess && (
            <>
              <Row style={{ marginBottom: "1rem" }}>
                <Col xs={12}>
                  <Card
                    title={`${snakeCaseToSentenceCase(channel)} Advertising`}
                    category={category}
                    marketPlace={marketPlace}
                    mobileStyle={mobileStyle}
                    start={start}
                    end={end}
                    data={data}
                    isLoading={isFetching}
                    metrics={overviewMetrics.metrics}
                    initialSelectedMetric={["ad_sales", "ad_spend", "roas"]}
                    filters={filters}
                    date={date}
                    channel={channel}
                    user={user}
                  >
                    {checkModule(modules, "MEDIA_PLAN_INSIGHTS") ? (
                      <div className="pb-1">
                        <Accordion title={"Insights"}>
                          <Row>
                            <Col xs={12}>
                              <InsightsTable
                                marketPlace={marketPlace.marketPlace}
                                channel={channel}
                                modules={modules}
                              />
                            </Col>
                          </Row>
                        </Accordion>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Col xs={12}>
                      <Accordion
                        title={"Categories"}
                        initialExpanded={true}
                        marginTop={"1rem"}
                      >
                        <Row>
                          <Col xs={12}>
                            <Table
                              stats={data?.categories}
                              marketPlace={marketPlace}
                              mobileStyle={mobileStyle}
                              columnSpecs={overviewMetrics.columns}
                              date={date}
                              category={category}
                              user={user}
                              isLoading={isLoading}
                            />
                          </Col>
                          {!isLoading && (
                            <Col xs={12} className="text-right">
                              <DownloadReport
                                url_path={"category_report"}
                                filters={{ ...filters, empty: false }}
                                report_dl={"category"}
                              />
                            </Col>
                          )}
                        </Row>
                      </Accordion>
                    </Col>
                    <Col xs={12}>
                      <Accordion title={"Ad Types"}>
                        <Row>
                          <Col xs={12}>
                            <AdTypePieCharts />
                          </Col>
                        </Row>
                      </Accordion>
                    </Col>
                    <Col xs={12}>
                      <Accordion
                        title={"Advertisements"}
                        initialExpanded={false}
                      >
                        <Row>
                          <Col xs={12}>
                            <NotificationSystem ref={notificationRef} />
                            <AdvertisementsTable
                              type={"advertisement"}
                              states={[]}
                              stats={adPlans}
                              marketPlace={marketPlace}
                              channel={channel}
                              mobileStyle={mobileStyle}
                              columnSpecs={overviewMetrics.columns}
                              user={user}
                              isLoading={isAdDataLoading}
                              notificationRef={notificationRef}
                              modules={modules}
                              preferences={preferences}
                              smallViewportWidthTable={smallViewportWidthTable}
                            />
                          </Col>
                          <Col xs={12} className="text-right">
                            <DownloadReport
                              url_path={"ad_report"}
                              filters={{
                                ...filters,
                                group_by: "ad_id",
                                empty: false,
                              }}
                              report_dl={"ads"}
                            />
                          </Col>
                        </Row>
                      </Accordion>
                    </Col>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

const AdvertisingDashboard = ({
  user,
  marketPlace,
  modules,
  location,
  channel,
  amz_onboard,
}) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { category = null } = useParams();

  const ADVERTISING_SUBSCRIPTION =
    user.tier &&
    !["unknown", "MANUALLY_DISABLED"].includes(user.tier) &&
    (amz_onboard.org_status === "active" ||
      amz_onboard.org_status === "trial_active" ||
      amz_onboard.org_status === "demo_org" ||
      channel === "walmart");

  if (!ADVERTISING_SUBSCRIPTION) {
    return (
      <PaymentDiscovery
        discoveryType={"advertising"}
        dashboardType="advertising"
      />
    );
  }

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        category={category}
        modules={modules}
        channel={channel}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
  modules: selectModules(state),
  channel: state.channel.currentChannel,
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(AdvertisingDashboard);
