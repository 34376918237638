import React, { useState, useMemo, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useFetch } from "hooks/api";
import api from "utils/api/index";
import Card from "components/core/basic/Card.jsx";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import OrgSwitcher from "components/core/blocks/OrgSwitcher";
import CustomHref from "components/core/blocks/CustomHref";
import checkApplication from "utils/checkApplication";
import {
  ADVERTISING,
  SEARCH_ANALYTICS,
  DYNAMIC_PRICING,
  CONTENT,
  DEALS,
  PURCHASE_BEHAVIOR,
} from "components/custom/merchandise/constants";
import checkTierAccess from "utils/checkTierAccess";
import checkModule from "utils/checkModule";
import { selectModules } from "redux/selectors/modules";
import { sendToastNotification } from "utils/sendToastNotification";

import NotificationSystem from "react-notification-system";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { setSubscriptionTiers } from "redux/actions/userActions";

import store from "redux/store";
import { setErrors } from "redux/actions/errorActions";
import { AMZ_ONBOARD } from "redux/types";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import ToolTips from "utils/toolTips";
import { snakeCaseToSentenceCase, toTitleCase } from "utils/formatText";
import moment from "moment";
import { setCurrentUser } from "redux/actions/authActions";
import UserActivityTable from "components/custom/staff/UserActivityTable";

const ORG_STATUS_OPTIONS = {
  ACTIVE: {
    label: "Active",
    value: "active",
    description:
      "This status is reserved for organizations that are paying customers with a credit card setup on Stripe, or an agency organization with a custom billing arrangement.",
  },
  TRIAL_ACTIVE: {
    label: "Active Trial",
    value: "trial_active",
    description: [
      "This status is reserved for organizations that are offered a free trial by sales or CS, but have not provided their credit card info.",
      "To have the org go through the billing workflow, they can input their card in the Billing tab, or CS can manually end the trial to show paywalls on any previously unlocked dashboards.",
    ],
  },
  PRE_TRIAL: {
    label: "Inactive",
    value: "pre_trial",
    description:
      "This status is the default state for any newly onboarded orgs, and flags an org as non-paying with no dashboard access. ",
  },
  DISCONNECTED: {
    label: "Disconnected",
    value: "disconnected",
    description:
      "This status is reserved for organizations that previously had a subscription but have since cancelled their membership. Their subscription can be reactivated through the Billing screen.",
  },
};

const ApplicationsMenu = () => {
  const queryClient = useQueryClient();

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const { user, amz_onboard, auth, marketPlace } = state;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modules = selectModules(state) ?? [];

  const notificationRef = useRef();

  refreshAmzOnboardStatus(
    updateAmzOnboardingStatus,
    auth.tokens.access_token,
    setErrors
  );

  let savedDashboardAccessConfig = useMemo(() => {
    return {
      advertising:
        (checkApplication(user, ADVERTISING) ||
          checkTierAccess(user.tier, "advertising", amz_onboard.org_status)) &&
        user.tier !== "MANUALLY_DISABLED",
      analytics:
        checkApplication(user, SEARCH_ANALYTICS) &&
        user.analytics_tier !== "MANUALLY_DISABLED",
      pricing:
        (checkApplication(user, DYNAMIC_PRICING) ||
          checkModule(modules, "PRICE_OPTIMIZATION") ||
          checkTierAccess(user.tier, "pricing", amz_onboard.org_status)) &&
        user.pricing_tier !== "MANUALLY_DISABLED",
      promotions:
        (checkApplication(user, DEALS) ||
          checkTierAccess(user.tier, "promotions", amz_onboard.org_status)) &&
        user.promotions_tier !== "MANUALLY_DISABLED",
      content:
        (checkApplication(user, CONTENT) ||
          checkTierAccess(user.tier, "content", amz_onboard.org_status)) &&
        user.content_tier !== "MANUALLY_DISABLED",
      purchaseBehavior:
        (checkApplication(user, PURCHASE_BEHAVIOR) ||
          checkModule(modules, "GROWTH")) &&
        user.pb_tier !== "MANUALLY_DISABLED",
    };
  }, [user, amz_onboard, modules]);

  const savedModulesConfig = useMemo(() => {
    return {
      agencyDashboard: checkModule(modules, "AGENCY_DASHBOARD"),
      ntmCampaigns: checkModule(modules, "NON_TRELLIS_MANAGED_ADS"),
      customCampaignNames: checkModule(modules, "CAMPAIGN_NAMES"),
    };
  }, [modules]);

  const [customAccess, setCustomAccess] = useState(false);
  const [selectedOrgStatus, setSelectedOrgStatus] = useState(
    Object.values(ORG_STATUS_OPTIONS)?.find(
      (o) => o.value === amz_onboard.org_status
    )
  );

  const [advertisingAccess, setAdvertisingAccess] = useState(
    savedDashboardAccessConfig["advertising"]
  );
  const [analyticsAccess, setAnalyticsAccess] = useState(
    savedDashboardAccessConfig["analytics"]
  );
  const [pricingAccess, setPricingAccess] = useState(
    savedDashboardAccessConfig["pricing"]
  );
  const [promoAccess, setPromoAccess] = useState(
    savedDashboardAccessConfig["promotions"]
  );
  const [contentAccess, setContentAccess] = useState(
    savedDashboardAccessConfig["content"]
  );
  const [purchaseBehaviorAccess, setPurchaseBehaviorAccess] = useState(
    savedDashboardAccessConfig["purchaseBehavior"]
  );

  const [agencyDashboardAccess, setAgencyDashboardAccess] = useState(
    savedModulesConfig["agencyDashboard"]
  );
  const [ntmAccess, setNtmAccess] = useState(
    savedModulesConfig["ntmCampaigns"]
  );
  const [customCampaignNameAccess, setCustomCampaignNameAccess] = useState(
    savedModulesConfig["customCampaignNames"]
  );

  const { organization_id: orgId, organization_name: orgName } = user;

  const { data: allOrgsData } = useFetch(
    "manage-orgs",
    "/api/manage/organizations/",
    { page_size: 5000 },
    {
      select: (d) => d.data.results,
    }
  );

  const { data: associatedOrgsData } = useFetch(
    "groupedOrgs",
    "/api/organizations/",
    { page_size: 5000 },
    {
      select: (d) => d.data,
    }
  );

  const { data: availableMarketplaces } = useFetch(
    "orgMarketplaces",
    `/api/organizations/${user.organization_id}/marketplaces`,
    {},
    {
      select: (res) =>
        Object.keys(res.data.markets)
          ?.filter((mp) => mp && mp !== "null")
          ?.join(", "),
      default: {},
    }
  );

  const handleSuccess = (res) => {
    // Trigger redux refresh of user profile data
    queryClient.invalidateQueries();
    sendToastNotification(
      notificationRef,
      "success",
      "Organization Privileges Successfully Updated"
    );

    refreshAmzOnboardStatus(
      updateAmzOnboardingStatus,
      auth.tokens.access_token,
      setErrors
    );

    store.dispatch(setSubscriptionTiers(res.data));
    setCustomAccess(false);
  };

  const handleError = () => {
    sendToastNotification(
      notificationRef,
      "warning",
      "Failed to Update Organization Privileges"
    );
  };

  // /api/applications/
  const updateHandler = useMutation(
    async (updateData) => {
      return await api.put(`/api/application/controller/`, updateData);
    },
    {
      onSuccess: (res) => {
        handleSuccess(res);
      },
      onError: (res) => {
        handleError();
      },
    }
  );

  // /api/applications/
  const createModuleHandler = useMutation(
    async (moduleData) => {
      return await api.post(`/api/module/controller/`, moduleData);
    },
    {
      onSuccess: (res) => {
        handleSuccess(res);
        dispatch(setCurrentUser({ ...user, modules: res.data }));
        window.location.reload();
      },
      onError: (res) => {
        handleError();
      },
    }
  );

  const deleteModuleHandler = useMutation(
    async (data) => {
      const res = await api.remove(`/api/module/controller/${data.moduleId}/`);
      return res;
    },
    {
      onSuccess: (res, variables) => {
        handleSuccess(res);
        window.location.reload();
      },
      onError: (res) => {
        handleError();
      },
    }
  );

  // /api/applications/orgstatus
  const updateStatusHandler = useMutation(
    async (updateData) => {
      return await api.put(`/api/application/orgstatus/`, updateData);
    },
    {
      onSuccess: (res) => {
        // Trigger redux refresh of user profile data
        queryClient.invalidateQueries();
        sendToastNotification(
          notificationRef,
          "success",
          "Organization Status Successfully Updated"
        );

        refreshAmzOnboardStatus(
          updateAmzOnboardingStatus,
          auth.tokens.access_token,
          setErrors
        );

        store.dispatch({
          type: AMZ_ONBOARD,
          payload: { ...amz_onboard, ...res.data },
        });

        setSelectedOrgStatus(
          Object.values(ORG_STATUS_OPTIONS)?.find(
            (o) => o.value === res.data.org_status
          )
        );
      },
      onError: (res) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Organization Status"
        );
      },
    }
  );

  const resetApplicationChanges = () => {
    setAdvertisingAccess(savedDashboardAccessConfig["advertising"]);
    setAnalyticsAccess(savedDashboardAccessConfig["analytics"]);
    setPricingAccess(savedDashboardAccessConfig["pricing"]);
    setPromoAccess(savedDashboardAccessConfig["promotions"]);
    setContentAccess(savedDashboardAccessConfig["content"]);
    setPurchaseBehaviorAccess(savedDashboardAccessConfig["purchaseBehavior"]);
    setAgencyDashboardAccess(savedModulesConfig["agencyDashboard"]);
    setNtmAccess(savedModulesConfig["ntmCampaigns"]);
    setCustomCampaignNameAccess(savedModulesConfig["customCampaignNames"]);
    setCustomAccess(false);
  };

  const updateDashboardAccess = () => {
    let updateData = {
      org_id: parseInt(orgId),
    };

    if (savedDashboardAccessConfig.advertising !== advertisingAccess) {
      updateData.advertising_tier = advertisingAccess
        ? "MANUALLY_ENABLED"
        : "MANUALLY_DISABLED";
    }

    if (savedDashboardAccessConfig.analytics !== analyticsAccess) {
      updateData.analytics_tier = analyticsAccess
        ? "MANUALLY_ENABLED"
        : "MANUALLY_DISABLED";
    }

    if (savedDashboardAccessConfig.pricing !== pricingAccess) {
      updateData.pricing_tier = pricingAccess
        ? "MANUALLY_ENABLED"
        : "MANUALLY_DISABLED";
    }

    if (savedDashboardAccessConfig.promotions !== promoAccess) {
      updateData.promotions_tier = promoAccess
        ? "MANUALLY_ENABLED"
        : "MANUALLY_DISABLED";
    }

    if (savedDashboardAccessConfig.content !== contentAccess) {
      updateData.content_tier = contentAccess
        ? "MANUALLY_ENABLED"
        : "MANUALLY_DISABLED";
    }

    if (
      savedDashboardAccessConfig.purchaseBehavior !== purchaseBehaviorAccess
    ) {
      updateData.pb_tier = purchaseBehaviorAccess
        ? "MANUALLY_ENABLED"
        : "MANUALLY_DISABLED";
    }

    if (Object.keys(updateData).filter((key) => key !== "org_id").length) {
      updateHandler.mutate(updateData);
    }
  };

  const deleteModule = (moduleName) => {
    const moduleId = user.modules?.find(
      (module) => module.name === moduleName
    )?.id;

    if (moduleId) {
      deleteModuleHandler.mutate({
        moduleId,
        moduleName: moduleName,
      });
    }
  };

  const updateOrgModules = () => {
    if (savedModulesConfig.agencyDashboard !== agencyDashboardAccess) {
      if (agencyDashboardAccess) {
        createModuleHandler.mutate({ module_name: "AGENCY_DASHBOARD" });
      } else {
        deleteModule("AGENCY_DASHBOARD");
      }
    }
    if (savedModulesConfig.ntmCampaigns !== ntmAccess) {
      if (ntmAccess) {
        createModuleHandler.mutate({ module_name: "NON_TRELLIS_MANAGED_ADS" });
      } else {
        deleteModule("NON_TRELLIS_MANAGED_ADS");
      }
    }
    if (savedModulesConfig.customCampaignNames !== customCampaignNameAccess) {
      if (customCampaignNameAccess) {
        createModuleHandler.mutate({ module_name: "CAMPAIGN_NAMES" });
      } else {
        deleteModule("CAMPAIGN_NAMES");
      }
    }
  };

  const showOrgData = false;

  const formatSPRegions = () => {
    let regions = [];
    if (amz_onboard.amazon_spapi_access === "connected") {
      regions.push("North America");
    }

    if (amz_onboard.eu_amazon_spapi_access === "connected") {
      regions.push("Europe");
    }

    if (amz_onboard.fe_amazon_spapi_access === "connected") {
      regions.push("Far East");
    }

    return regions.join(", ");
  };

  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <div className="container mx-auto">
        <div className="text-center">
          <h4 className="fw-bold">Manage Applications</h4>
          <div>
            <Row className="pb-5">
              <Col xs={11} className="mx-auto justify-content-center pb-5">
                <Row className="d-flex align-items-center mx-auto pb-4">
                  <Col xs={12} className="text-center m-0 px-4">
                    <p className="fs-3 pt-3 d-flex justify-content-center">
                      Managing dashboard access for {orgName} ({orgId}){"  "}{" "}
                      <OrgSwitcher
                        user={user}
                        overrideModalButton={
                          <div className="pl-3">
                            <CustomHref text={"Change"} />
                          </div>
                        }
                        refetchOnOrgChange={true}
                      />
                    </p>
                  </Col>
                </Row>

                {/* Summary Card Starts */}
                <div className="py-2">
                  <Card
                    style={{ width: "100%" }}
                    content={
                      <>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">
                              Organization Type:{" "}
                            </label>
                            <span className="fs-3">
                              {toTitleCase(user.organization_type)}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">
                              Amazon Profile Name:{" "}
                            </label>
                            <span className="fs-3">{user.current_profile}</span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">
                              Marketplaces:{" "}
                            </label>
                            <span className="fs-3">
                              {availableMarketplaces}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">SP-API: </label>
                            <span className="fs-3">
                              {[
                                amz_onboard.amazon_spapi_access,
                                amz_onboard.eu_amazon_spapi_access,
                                amz_onboard.fe_amazon_spapi_access,
                              ]?.some((region) => region === "connected")
                                ? "Connected - "
                                : "Not Connected"}
                              {formatSPRegions()}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">
                              Amazon Advertising:{" "}
                            </label>
                            <span className="fs-3">
                              {amz_onboard.amazon_advertising_access ===
                              "connected"
                                ? "Connected"
                                : "Not Connected"}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">
                              Total Sales Start Date:{" "}
                            </label>
                            <span className="fs-3">
                              {user.total_sales_start_date[
                                marketPlace.marketPlace
                              ]
                                ? moment(
                                    user.total_sales_start_date[
                                      marketPlace.marketPlace
                                    ]
                                  ).format("MMMM D, YYYY")
                                : "N/A"}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">
                              Sign Up Date:{" "}
                            </label>
                            <span className="fs-3">
                              {moment(user.organization_created_date).format(
                                "MMMM D, YYYY"
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">ICP Status: </label>
                            <span className="fs-3">
                              {user.icp_status ?? "N/A"}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex flex-column"
                          >
                            <label className="fs-3 fw-bold">
                              Main Subscription Tier:{" "}
                            </label>
                            <span className="fs-3">
                              {snakeCaseToSentenceCase(
                                user.tier.replace("__", "_")
                              )}
                            </span>
                          </Col>
                        </Row>
                      </>
                    }
                  />
                </div>
                {/* Summary Card Ends */}
                <div className="py-2">
                  <Card
                    style={{ width: "100%" }}
                    content={
                      <>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex"
                          >
                            <label className="fs-2 fw-bold">Org Status: </label>
                            <span className="fs-2 px-4">
                              {selectedOrgStatus?.label}
                              <ToolTips
                                id={selectedOrgStatus?.value}
                                toolTip={
                                  typeof selectedOrgStatus?.description ===
                                  "object"
                                    ? selectedOrgStatus?.description?.join(" ")
                                    : selectedOrgStatus?.description
                                }
                              />
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex justify-content-center"
                          >
                            {amz_onboard.org_status === "pre_trial" ? (
                              <ConfirmationModal
                                title={`Confirm New Free Trial for ${user.current_profile}`}
                                confirmationMessage={
                                  <>
                                    {ORG_STATUS_OPTIONS.TRIAL_ACTIVE.description.map(
                                      (paragraph) => (
                                        <div className="my-4 fs-4 mx-auto w-75">
                                          {paragraph
                                            .replace(
                                              "This status is",
                                              "You are updating the organization status to a status"
                                            )
                                            .replace("and", "which")}
                                        </div>
                                      )
                                    )}
                                  </>
                                }
                                confirmButtonText={`Confirm`}
                                modalButtonText={"Start Trial"}
                                onConfirm={(e) => {
                                  updateStatusHandler.mutate({
                                    status:
                                      ORG_STATUS_OPTIONS.TRIAL_ACTIVE.value,
                                  });
                                }}
                                modelButtonStyle={{ transform: "none" }}
                              />
                            ) : amz_onboard.org_status === "trial_active" ? (
                              <ConfirmationModal
                                title={`Confirm End of Free Trial for ${user.current_profile}`}
                                confirmationMessage={
                                  <>
                                    <div className="my-4 fs-4 mx-auto w-75">
                                      {ORG_STATUS_OPTIONS.PRE_TRIAL.description
                                        .replace(
                                          "This status is",
                                          "You are updating the organization status to"
                                        )
                                        .replace("and", "which")}
                                    </div>
                                  </>
                                }
                                confirmButtonText={`Confirm`}
                                modalButtonText={"End Trial"}
                                onConfirm={(e) => {
                                  updateStatusHandler.mutate({
                                    status: ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  });
                                }}
                                modelButtonStyle={{ transform: "none" }}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            className="text-start m-0 p-4 d-flex justify-content-end"
                          >
                            {[
                              ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                              ORG_STATUS_OPTIONS.DISCONNECTED.value,
                            ].includes(amz_onboard.org_status) ? (
                              <ConfirmationModal
                                title={`Confirm Update of ${user.current_profile} to an Active Agency Org`}
                                confirmationMessage={
                                  <>
                                    <div className="my-4 fs-4 mx-auto w-75">
                                      {ORG_STATUS_OPTIONS.ACTIVE.description}
                                    </div>
                                  </>
                                }
                                confirmButtonText={`Confirm`}
                                modalButtonText={"Activate Agency Org"}
                                onConfirm={(e) => {
                                  updateStatusHandler.mutate({
                                    status: ORG_STATUS_OPTIONS.ACTIVE.value,
                                  });
                                }}
                                modelButtonStyle={{ transform: "none" }}
                              />
                            ) : (
                              <button
                                className="adplan_button"
                                style={{
                                  transform: "none",
                                }}
                                onClick={() =>
                                  !customAccess
                                    ? setCustomAccess(true)
                                    : resetApplicationChanges()
                                }
                              >
                                {!customAccess ? "Customize Access" : "Reset"}
                              </button>
                            )}
                          </Col>
                        </Row>
                      </>
                    }
                  />
                </div>
                {showOrgData ? (
                  <div className="py-2">
                    <Card
                      style={{ width: "100%" }}
                      content={
                        <>
                          <Row className="mx-auto p-3">
                            <Col xs={12} sm={6} className="text-start m-0 p-4">
                              <h5 className="fs-2 fw-bold">Associated Orgs </h5>
                              <p>
                                {associatedOrgsData?.map((org) => (
                                  <div key={org.id} className="fs-5">
                                    <p>
                                      <label className="fw-bold pr-4 w-25">
                                        Org Name:
                                      </label>
                                      {org.name}
                                    </p>
                                    <p>
                                      <label className="fw-bold pr-4 w-25">
                                        Profile Name:
                                      </label>
                                      {org.profile_name}
                                    </p>
                                    <hr />
                                  </div>
                                ))}
                              </p>
                            </Col>
                            <Col xs={12} sm={6} className="text-start m-0 p-4">
                              <h5 className="fs-2 fw-bold">
                                Other Trellis Orgs{" "}
                              </h5>
                              <p>
                                {allOrgsData
                                  ?.filter(
                                    (org) =>
                                      !associatedOrgsData
                                        ?.map((o) => o.id)
                                        ?.includes(org.id)
                                  )
                                  .map((org) => (
                                    <div key={org.id} className="fs-5">
                                      <p>
                                        <label className="fw-bold pr-4 w-25">
                                          Org Name:
                                        </label>
                                        {org.name}
                                      </p>
                                      <p>
                                        <label className="fw-bold pr-4 w-25">
                                          Profile Name:
                                        </label>
                                        {org.profile_name}
                                      </p>
                                      <hr />
                                    </div>
                                  ))}
                              </p>
                            </Col>
                          </Row>
                        </>
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* Application Controller Card */}
                <div className="py-2">
                  <Card
                    style={{ width: "100%" }}
                    content={
                      <>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          {![
                            ORG_STATUS_OPTIONS.ACTIVE.value,
                            ORG_STATUS_OPTIONS.TRIAL_ACTIVE.value,
                          ].includes(amz_onboard.org_status) && (
                            <Col xs={12} className="text-start m-0 p-1">
                              <p className="fs-4 w-75 mx-auto text-center pt-2">
                                To customize dashboard applications for{" "}
                                {user.current_profile} their org status must be
                                either active or trial active.
                              </p>
                            </Col>
                          )}
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              All Applications
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={
                                [
                                  ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  ORG_STATUS_OPTIONS.DISCONNECTED.value,
                                ].includes(amz_onboard.org_status)
                                  ? false
                                  : advertisingAccess &&
                                    pricingAccess &&
                                    promoAccess &&
                                    contentAccess &&
                                    analyticsAccess &&
                                    purchaseBehaviorAccess
                              }
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                const allEnabled =
                                  advertisingAccess &&
                                  pricingAccess &&
                                  promoAccess &&
                                  contentAccess &&
                                  analyticsAccess &&
                                  purchaseBehaviorAccess;

                                setAdvertisingAccess(!allEnabled);
                                setPricingAccess(!allEnabled);
                                setPromoAccess(!allEnabled);
                                setContentAccess(!allEnabled);
                                setAnalyticsAccess(!allEnabled);
                                setPurchaseBehaviorAccess(!allEnabled);
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <hr />
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              Placement Advertising
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={
                                [
                                  ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  ORG_STATUS_OPTIONS.DISCONNECTED.value,
                                ].includes(amz_onboard.org_status)
                                  ? false
                                  : advertisingAccess
                              }
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setAdvertisingAccess(!advertisingAccess);
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">Pricing</label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={
                                [
                                  ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  ORG_STATUS_OPTIONS.DISCONNECTED.value,
                                ].includes(amz_onboard.org_status)
                                  ? false
                                  : pricingAccess
                              }
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setPricingAccess(!pricingAccess);
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">Promotions</label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={
                                [
                                  ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  ORG_STATUS_OPTIONS.DISCONNECTED.value,
                                ].includes(amz_onboard.org_status)
                                  ? false
                                  : promoAccess
                              }
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setPromoAccess(!promoAccess);
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              Product Content
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={
                                [
                                  ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  ORG_STATUS_OPTIONS.DISCONNECTED.value,
                                ].includes(amz_onboard.org_status)
                                  ? false
                                  : contentAccess
                              }
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setContentAccess(!contentAccess);
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              Placement Search Analytics
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={
                                [
                                  ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  ORG_STATUS_OPTIONS.DISCONNECTED.value,
                                ].includes(amz_onboard.org_status)
                                  ? false
                                  : analyticsAccess
                              }
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setAnalyticsAccess(!analyticsAccess);
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              Purchase Behavior
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={
                                [
                                  ORG_STATUS_OPTIONS.PRE_TRIAL.value,
                                  ORG_STATUS_OPTIONS.DISCONNECTED.value,
                                ].includes(amz_onboard.org_status)
                                  ? false
                                  : purchaseBehaviorAccess
                              }
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setPurchaseBehaviorAccess(
                                  !purchaseBehaviorAccess
                                );
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center mx-auto p-3">
                          <div>
                            <ConfirmationModal
                              title={`Confirm Updates to Org Applications`}
                              confirmationMessage={`Are you sure you want to apply to changes to ${user.current_profile}'s application access?`}
                              confirmButtonText={`Confirm`}
                              modalButtonText={"Save"}
                              disabled={!customAccess}
                              onConfirm={(e) => {
                                updateDashboardAccess();
                              }}
                              modelButtonStyle={{ transform: "none" }}
                            />
                          </div>
                        </Row>
                      </>
                    }
                  />
                </div>
                {/* Module Controller Card */}
                <div className="py-2">
                  <Card
                    style={{ width: "100%" }}
                    content={
                      <>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">Modules</label>
                          </Col>
                        </Row>
                        <hr />
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              Agency Dashboard
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={agencyDashboardAccess}
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setAgencyDashboardAccess(
                                  !agencyDashboardAccess
                                );
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              Non-Trellis Managed Campaigns
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={ntmAccess}
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setNtmAccess(!ntmAccess);
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center mx-auto p-3">
                          <Col xs={12} sm={10} className="text-start m-0 p-4">
                            <label className="fs-2 fw-bold">
                              Custom Campaign Names
                            </label>
                          </Col>
                          <Col xs={12} sm={2} className="text-end">
                            <Switch
                              onText=""
                              offText=""
                              value={customCampaignNameAccess}
                              onColor="#FFFFFF"
                              onChange={(e) => {
                                setCustomCampaignNameAccess(
                                  !customCampaignNameAccess
                                );
                              }}
                              disabled={!customAccess}
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center mx-auto p-3">
                          <div>
                            <ConfirmationModal
                              title={`Confirm Updates to Org Applications`}
                              confirmationMessage={`Are you sure you want to apply to changes to ${user.current_profile}'s application access?`}
                              confirmButtonText={`Confirm`}
                              modalButtonText={"Save"}
                              disabled={!customAccess}
                              onConfirm={(e) => {
                                updateOrgModules();
                              }}
                              modelButtonStyle={{ transform: "none" }}
                            />
                          </div>
                        </Row>
                      </>
                    }
                  />
                </div>
                <div className="py-2">
                  <UserActivityTable />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationsMenu;
