import React, { useState, useMemo, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useFetch } from "hooks/api";
import {
  Col,
  Container as Grid,
  Row,
  Badge,
  NavDropdown,
  Card,
} from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import store from "redux/store";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import { connect } from "react-redux";
import { trellisPalette } from "components/custom/analytics/palettes";
import Accordion from "components/core/basic/Accordion";
import useColumnSelector from "hooks/useColumnSelector";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { getURLPrefix } from "utils/getUrlPrefix";
import NoImage from "assets/images/utility/no_image.png";
import { useLocation, useParams } from "react-router-dom";
import DownloadReport from "modules/perf_reports/DownloadReport";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import moment from "moment";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import PromotionsCard from "components/custom/merchandise/cards/PromotionsCard";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import TrComponent from "components/custom/merchandise/TrComponent";
import ProductCard from "components/custom/merchandise/ProductCard";
import PricingCard from "components/custom/merchandise/cards/PricingCard";
import getMetricSpec from "./metrics";
import {
  INVENTORY_STATES,
  SALE_STATES,
} from "components/core/blocks/AdPlan/constants";
import NPICard from "components/custom/merchandise/cards/NPICard";
import {
  ADVERTISING,
  CONTENT,
  DEALS,
  DYNAMIC_PRICING,
  NEW_PRODUCT_LAUNCH,
} from "components/custom/merchandise/constants";
import checkApplication from "utils/checkApplication";
import Loading from "components/core/blocks/Loading";
import ContentCard from "components/custom/merchandise/cards/ContentCard";
import { setMerchandiseDashboard } from "redux/actions/userActions";
import { useMutation } from "react-query";
import api from "utils/api";
import EmptyState from "components/custom/merchandise/EmptyState";
import ToolTips from "utils/hiddenToolTips";
import { FaRegCircle } from "react-icons/fa";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import DetailedMetricRow from "components/core/blocks/DetailedMetricRow";
import AdvertisingIcon from "assets/images/icons/placement.svg";
import ThemeLine from "components/custom/merchandise/ThemeLine";
import { selectModules } from "redux/selectors/modules";
import { isValidDataframeDate, toTitleCase } from "utils/formatText";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import useCategories from "hooks/useCategories";
import TrellisLink from "components/core/basic/TrellisLink";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import checkModule from "utils/checkModule";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import useTableSettings from "hooks/useTableSettings";
import FilterWidget from "./tables/FilterWidget";
import {
  INVENTORY_STATE_FILTER,
  SALES_STATE_FILTER,
  PRODUCT_WITH_ADS_FILTER,
  buildCustomTagsFilter,
} from "views/advertising/productLifecycleFilters";
import VerifyEmailModal from "views/onboarding/components/VerifyEmailModal";
import useIsSPAPIConnected from "hooks/useIsSPAPIConnected";
import ExternalProductLink from "components/custom/merchandise/ExternalProductLink";
import AnnotationsTable from "components/custom/growth/AnnotationsTable";
import SalesMapUSA from "components/custom/maps/usa";
import TitleLink from "components/core/basic/TitleLink";
import { Redirect } from "react-router-dom";

import {
  LEGACY_METRIC_IDS_MAP,
  extendAllEntityMetricOptions,
} from "views/merchandise/tables/metricUtils";
import { METRIC_GROUPING_LOOKUP } from "./tables/metricsByEntity";

const CARDS = [
  { id: "advertising", name: "Advertising", app: ADVERTISING },
  { id: "dynamic_pricing", name: "Dynamic Pricing", app: DYNAMIC_PRICING },
  { id: "deals", name: "Deals", app: DEALS },
  { id: "content", name: "Content", app: CONTENT },
  { id: "npi", name: "New Product Introductions", app: NEW_PRODUCT_LAUNCH },
];

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  return metric;
};

let URL_PREFIX = getURLPrefix();

// TODO factor this out into a utils file
const DASHBOARD_TYPES = {
  PRODUCT: "product",
  CATEGORY: "category",
  ROOT: "root",
};

const getDashboardTo = ({ resourceId, type }) => {
  let to = `/user/merchandising/dashboard`;
  if (resourceId) {
    switch (type) {
      case DASHBOARD_TYPES.CATEGORY:
        to = `/user/merchandising/category/${resourceId}`;
        break;
      case DASHBOARD_TYPES.PRODUCT:
        to = `/user/merchandising/product/${resourceId}`;
        break;
      default:
        to = `/user/merchandising/dashboard`;
    }
  }
  return to;
};

const DashboardLink = ({ children, category, product }) => {
  const to = getDashboardTo({
    resourceId: category || product,
    type: category ? DASHBOARD_TYPES.CATEGORY : DASHBOARD_TYPES.PRODUCT,
  });

  if (product) {
    return (
      <TitleLink
        to={to}
        style={{
          textDecoration: "none",
          fontWeight: "500",
          fontSize: "1.5rem",
          cursor: "pointer",
        }}
        title={children}
      />
    );
  }
  return (
    <TrellisLink
      to={to}
      style={{
        textDecoration: "none",
        fontWeight: "500",
        fontSize: "1.5rem",
        cursor: "pointer",
      }}
    >
      {children}
    </TrellisLink>
  );
};

const ExpandedContent = ({ marketPlace, row, user, channel }) => {
  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <ProductCard
        marketPlace={marketPlace}
        productId={row?.original?.product_id}
        user={user}
        channel={channel}
      />
    </div>
  );
};
export const ProductCell = (props) => {
  const [hasError, setHasError] = useState(false);

  let {
    product_id: productId,
    sales_state: salesState,
    stock_status: inventoryState,
    inventory_state,
    price_override_end: priceOverrideEnd,
    pp_enroll_date: enrollDate,
    variation_plan_id: variationPlanId,
    pp_status: ppStatus,
    cellContext,
    tags,
    // buy_box: buyBox,
  } = props?.original;

  // TODO: Clarify how we want to handle stock status vs. inventory state
  // Right now, user-selected inventory state takes precedence in filters, stock status from Amazon a fallback
  if (inventoryState === "IN_STOCK") {
    inventoryState = props.original?.stock_status;
  }

  if (inventoryState === "IN_STOCK_SCARCE") {
    inventoryState = "LOW_STOCK";
  }
  if (inventory_state === "OVERSTOCK") {
    inventoryState = "OVERSTOCK";
  }

  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?product=${productId}`;

  const Wrapper = ({ children }) => {
    return (
      <DashboardLink product={productId} date={props.date}>
        {children}
      </DashboardLink>
    );
  };

  return (
    <div style={{ display: "flex", fontWeight: "500", fontSize: "1.5rem" }}>
      <div
        onError={(e) => {
          setHasError(true);
        }}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div className="overflow-ellipsis d-flex flex-column">
        <Wrapper>{props.value}</Wrapper>
        <div>
          <i
            className="pr-3"
            style={{
              fontWeight: "light",
              color: "#666",
              fontSize: "1rem",
            }}
          >
            {props?.original.asin} ({props?.original.sku})
          </i>
          <ExternalProductLink asin={props?.original.asin} />
          {(inventoryState !== "IN_STOCK" || props.showInventoryBadge) && (
            <Badge
              className="badge bg-trellis-purple"
              pill
              style={{
                borderRadius: "5px",
                marginLeft: "1rem",
              }}
            >
              {INVENTORY_STATES[inventoryState]?.label}
            </Badge>
          )}
          {salesState !== "NORMAL" && (
            <Badge
              className="badge bg-trellis-fuchsia"
              pill
              style={{
                borderRadius: "5px",
                marginLeft: "1rem",
              }}
            >
              {SALE_STATES[salesState]?.label}
            </Badge>
          )}
          {/* {!buyBox && (
            <Badge
              className={"badge bg-trellis-yellow"}
              pill
              style={{
                borderRadius: "5px",
                marginLeft: "1rem",
              }}
            >
              Buy Box Lost
            </Badge>
          )} */}
          {isValidDataframeDate(enrollDate) && cellContext !== "advertising" ? (
            <Badge
              className={`badge ${
                variationPlanId ? "bg-trellis-mauve" : "bg-trellis-green"
              }`}
              pill
              style={{
                borderRadius: "5px",
                marginLeft: "1rem",
              }}
            >
              Enrolled
            </Badge>
          ) : (
            ""
          )}
          {isValidDataframeDate(priceOverrideEnd) &&
          cellContext !== "advertising" ? (
            <Badge
              className="badge bg-trellis-purple"
              pill
              style={{
                borderRadius: "5px",
                marginLeft: "1rem",
              }}
            >
              Fixed Price
            </Badge>
          ) : (
            ""
          )}
          {ppStatus &&
          ppStatus !== "enabled" &&
          cellContext !== "advertising" ? (
            <Badge
              className="badge bg-trellis-yellow"
              pill
              style={{
                borderRadius: "5px",
                marginLeft: "1rem",
              }}
            >
              {toTitleCase(ppStatus)}
            </Badge>
          ) : (
            ""
          )}
          {tags?.map((tag, index) => (
            <Badge
              bsClass="badge"
              className="bg-trellis-mauve"
              style={{
                marginLeft: "1rem",
                cursor: "pointer",
              }}
              pill
              key={index}
            >
              {toTitleCase(tag)}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

const CategoryCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const categoryId = props?.original?.category_id;
  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?category=${categoryId}`;
  return (
    <DashboardLink category={categoryId} date={props.date}>
      <div style={{ display: "flex" }}>
        <div
          onError={(e) => {
            setHasError(true);
          }}
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            flexShrink: 0,
            width: 50,
            height: 50,
            marginRight: "10px",
            background: `url(${image}) no-repeat center / contain`,
          }}
          draggable="false"
        />
        <div>{props?.value}</div>
      </div>
    </DashboardLink>
  );
};

const TrendCell = (props) => {
  const COLORS = [
    trellisPalette[17], // -2 red
    trellisPalette[16], // -1 yellow
    "white", // 0 white
    trellisPalette[12] + "80", // 1 light green
    trellisPalette[12], // 2 green
  ];
  let color = "white";
  if (props.value) {
    color = COLORS?.[props.value + 2] ?? "white";
  }
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      {props?.original?.trends?.length > 0 && (
        <ToolTips
          position="top"
          id={`trends-${props?.original?.category_id ?? ""}${
            props?.original?.product_id ?? ""
          }`}
          toolTip={props?.original?.trends?.join(", ")}
        >
          <>
            {props?.original?.trends.map((t) => (
              <>
                {t}
                <br />
              </>
            ))}
          </>
        </ToolTips>
      )}
      {color === "white" ? (
        <FaRegCircle style={{ color: "#aaa" }} />
      ) : (
        <i className="fa fa-circle" style={{ color }}></i>
      )}
    </div>
  );
};

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const makeColumn =
  (data, marketPlace, mobileStyle, date, setIsExpanded, modules) =>
  (header, key, type, options) => {
    const { hideFilter } = options ?? {};

    const defaultAccessor = (p) => p[key];
    const accessor = options?.accessor ?? defaultAccessor;
    const defaultFormat = ({ value }) => value;
    const fmt =
      options?.formatter ??
      {
        expander: ({ row, value }) => (
          <ExpanderCell
            row={row}
            // TODO: Make expander value more versatile,
            value={row?._original?.product_id}
            isExpanded={value}
            setIsExpanded={setIsExpanded}
          />
        ),
        currency: ({ value }) =>
          formatCurrency(
            value,
            marketPlace.marketPlace,
            options?.clipDecimalPlaces ?? true
          ),
        number: ({ value }) => formatNumber(value, {}, 1),
        percent: ({ value }) => formatPercent(value, 1),
        category: CategoryCell,
        product: ProductCell,
        trends: TrendCell,
        percentDiff: ({ value }) => (
          <span
            style={{
              color: value > 0 ? trellisPalette[12] : trellisPalette[17],
            }}
          >
            {formatPercent(value, 1, true)}
          </span>
        ),
      }?.[type] ??
      defaultFormat;
    const parser =
      {
        currency: parseFormattedCurrency,
        number: parseFormattedNumber,
        percent: parseFormattedPercentage,
      }?.[type] ?? defaultFormat;

    let width;
    if (mobileStyle) {
      width = options?.mobileWidth;
    } else {
      width = options?.width;
    }

    // Handle tooltips to support metric selection menu descriptions
    let { toolTip } = getMetricSpec(marketPlace, key);
    if (!toolTip) {
      let alternateMetricSpec = getMetricSpec(
        marketPlace,
        LEGACY_METRIC_IDS_MAP[key]
      );
      toolTip = alternateMetricSpec?.toolTip;
    }

    // Handle percent filter formatting
    if (type === "percent") {
      data = data.map((row) => {
        Object.keys(row).forEach((propertyKey) => {
          if (propertyKey === key) {
            row["formatted_" + propertyKey] = row[propertyKey] * 100;
          }
        });

        return row;
      });
    }

    return {
      Header: header,
      id: key,
      accessor,
      checked: options?.checked ?? true,
      maxWidth: options?.maxWidth,
      minWidth: options?.minWidth,
      isStatic: options?.isStatic,
      width,
      grouping: METRIC_GROUPING_LOOKUP[key],
      height: 30,
      format: type,
      toolTip,
      className: options?.className ?? "text-end",
      headerClassName: options?.className ?? "text-end",
      Cell: (props) => {
        if (options?.showDot ?? true) {
          const percentKey = `${key}_percent`;
          const percent = props.original?.[percentKey] ?? null;
          const deltaKey = `${key}_delta`;
          const deltaValue = props.original?.[deltaKey];
          const isNegative = options?.isNegative ?? false;
          const noColor = options?.noColor ?? false;
          let color = "#666";
          if (!noColor && percent > 0) {
            color = isNegative ? trellisPalette[17] : trellisPalette[12];
          }
          if (!noColor && percent < 0) {
            color = isNegative ? trellisPalette[12] : trellisPalette[17];
          }
          return (
            <div>
              {fmt({ ...props, date, modules })}
              <br />
              <span
                style={{
                  color,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                {!options?.hideCompare &&
                  (options?.delta
                    ? (deltaValue > 0 ? "+" : "") + fmt({ value: deltaValue })
                    : percent === null
                    ? ""
                    : formatPercent(percent, 1, true))}
              </span>
            </div>
          );
        }
        return fmt({ ...props, date, modules });
      },

      style: { height: "60px" },
      filterMethod: (filter, row) => {
        const formattedPercentAccessor = (p) => p[`formatted_${key}`];
        const value =
          type === "percent"
            ? formattedPercentAccessor(row._original)
            : accessor(row._original);
        return filterRanges(filter, value, parser, marketPlace.marketPlace);
      },
      Filter: ({ filter, onChange }) => {
        if (hideFilter) {
          return null;
        }

        return generateFilterMarkup(filter, onChange, key, "range", type, {
          ranges: generateRanges(
            data ?? [],
            5,
            type === "percent" ? `formatted_${key}` : key,
            "number",
            marketPlace.marketPlace
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

const Table = ({
  stats,
  marketPlace,
  mobileStyle,
  category,
  columnSpecs,
  date,
  ExpandedContent,
  user,
  modules,
  channel,
  isLoading,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [isExpanded, setIsExpanded] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [colToggle] = useState();

  const TABLE_ID = category ? "merchProductsTable" : "merchCategoriesTable";
  const tableRef = useRef();

  const {
    customFilters,
    setCustomFilters,
    formatFilterValue,
    applyCustomFilters,
  } = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const {
    savedTableSettings: savedTablePageSize,
    updateTableSettings: setSavedTablePageSize,
  } = useTableSettings(TABLE_ID, "tablePageSize", 5);

  const {
    savedTableSettings: savedTableSortMethod,
    updateTableSettings: setSavedTableSortMethod,
  } = useTableSettings(TABLE_ID, "tableSorting", []);

  const customTags = useMemo(() => {
    return stats?.map((product) => product.tags)?.flat() ?? [];
  }, [stats]);

  const data = useMemo(
    () => {
      return applyCustomFilters(
        stats
          ?.map((p) => (p.product_id === isExpanded ? { ...p, isExpanded } : p))
          ?.filter((p) => {
            if (searchFilter) {
              const searchable = category
                ? `${p.product_title} ${p.asin} ${p.sku} ${p.tags?.join(" ")}`
                : `${p.category_name} ${p.name}`;
              return (
                searchable.toLowerCase()?.indexOf(searchFilter.toLowerCase()) >
                -1
              );
            }
            return true;
          }) ?? []
      );
    },
    // Below linting disabled to take clearFilters in to recalcalculate data on filter reset
    // Without it, filter specs from react-table must be stored at this level and manually cleared on each reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stats, searchFilter, isExpanded, customFilters]
  );

  const createColumn = makeColumn(
    data,
    marketPlace,
    mobileStyle,
    date,
    setIsExpanded,
    modules
  );
  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let originalColumns = columnSpecs.map((c) =>
        createColumn(c.header, c.key, c.type, c.options)
      );
      return extendAllEntityMetricOptions(
        category ? "product" : "category",
        data,
        customFilters,
        originalColumns,
        marketPlace.marketPlace,
        user
      );
    },
    [columnSpecs, stats, mobileStyle, colToggle],
    TABLE_ID
  );

  const columnOrder = columns.map((col) => col.id);

  if (isLoading) {
    return <Loading height={"500px"} fullPage={false} />;
  }

  return (
    <>
      <ReactTable
        ref={tableRef}
        showPaginationBottom={false}
        showPaginationTop
        PaginationComponent={ReactTablePagination}
        columns={columns}
        data={data}
        defaultPageSize={savedTablePageSize || 10}
        minRows={5}
        className={`-highlight ${TABLE_ID}`}
        defaultSorted={savedTableSortMethod || [{ id: "sales", desc: false }]}
        filterable={showFilters}
        state={columnOrder}
        onSortedChange={(newSorted) => {
          setSavedTableSortMethod(newSorted);
        }}
        onPageSizeChange={(pageSize) => {
          setSavedTablePageSize(pageSize);
        }}
        TrComponent={TrComponent}
        getTrProps={(table, row) => {
          let expandedChildren = (
            <ExpandedContent
              marketPlace={marketPlace}
              row={row}
              user={user}
              channel={channel}
            />
          );
          return { row, expandedChildren };
        }}
        getTheadFilterProps={(props) => {
          return { style: { display: "flex" } };
        }}
        getPaginationProps={() => ({
          updateSearch: setSearchFilter,
          clearFilters: () => {
            setSearchFilter("");
          },
          hideFilters: false,
          showFilters: showFilters,
          setShowFilters: setShowFilters,
          clearSearchTermFilters: () => setSearchFilter(""),
          searchTerm: searchFilter,
          table: "plan-products",
          tableId: TABLE_ID,
          tableEntityType: category ? "product" : "category",
          style: { marginBottom: "1rem" },
          carouselLayout: false,
          disableSearchFocus: true,
          filterControlsWidth: "70%",
          columnSelectorProps: columnSelectorProps,
          filterWidget: true,
          hideFilterToggleIcon: true,
          children: (
            <div className="d-flex justify-content-start w-100 h-100 align-items-center px-2">
              <FilterWidget
                customFilters={customFilters}
                setCustomFilters={setCustomFilters}
                formatFilterValue={formatFilterValue}
                tableRef={tableRef}
                additionalFilterOptions={
                  category
                    ? [
                        INVENTORY_STATE_FILTER,
                        SALES_STATE_FILTER,
                        PRODUCT_WITH_ADS_FILTER,
                        buildCustomTagsFilter(customTags),
                      ]
                    : []
                }
                excludedFilterColumns={
                  category ? ["product_title"] : ["category_name"]
                }
                tableId={TABLE_ID}
              />
            </div>
          ),
        })}
      />
    </>
  );
};

const Dashboard = ({ marketPlace, user, category, modules, channel }) => {
  const mobileStyle = useMediaQuery("(max-width:600px)");
  const { group, date, start, end, preStart, preEnd } = useDates();
  const { preferences } = useMerchandisePreferences();
  const [annotationsTab, setAnnotationsTab] = useState("advertisement");
  const hasTop500Module = checkModule(modules, "TOP500");

  const { category: categoryId } = useParams();
  let { currentCategoryDetails: categoryDetails, categories: categoryData } =
    useCategories(categoryId, "merchandising");

  const filters = {
    mp: marketPlace.marketPlace,
    channel,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    group_by: "category_id",
  };

  let [key, endpoint, group_by] = [
    ["category_report"],
    "category_report",
    "category_id",
  ];
  if (category) {
    key = ["product_report", category];
    endpoint = "product_report";
    group_by = "product_id";
    filters["category"] = category;
  }
  const tableFilters = { ...filters, group_by };
  const downloadFilters = {
    ...tableFilters,
    empty: false,
    pre_start_date: null,
    pre_end_date: null,
  };

  const { isSuccess, data, isError, isLoading, isFetching } = useFetch(
    [
      "category_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      group,
      category,
    ],
    `/api/data_report/category_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const topProductsDownloadFilters = {
    ...tableFilters,
    group_by: "product_id",
    top_n: 500,
  };

  const { data: topProductsData, isLoading: topProductsDataLoading } = useFetch(
    [
      "top_products",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      group,
    ],
    `/api/data_report/product_report`,
    { ...topProductsDownloadFilters },
    {
      select: (d) => d.data?.products ?? [],
      keepPreviousData: true,
      enabled: hasTop500Module,
    }
  );

  const { data: tableData, isLoading: isTableDataLoading } = useFetch(
    [...key, marketPlace.marketPlace, start, end, preStart, preEnd, group],
    `/api/data_report/${endpoint}`,
    tableFilters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data: annotationData, isLoading: isAnnotationDataLoading } = useFetch(
    ["annotations", start, end, channel, category, marketPlace.marketPlace],
    "/api/annotations/",
    {
      groupby: group,
      ts_after: `${filters["start_date"]}T00:00:00`,
      ts_before: `${filters["end_date"]}T23:59:59`,
      marketplace: marketPlace.marketPlace,
      channel: channel === "amazon" ? "AMZ" : channel,
      category: category ?? null,
    },
    // ts_before and ts_after need values from categories to contain start and end date within query string
    {
      keepPreviousData: true,
      // Below is to avoid refetch if user switches tabs/windows while in the middle of bulk editing
      refetchOnWindowFocus: false,
    }
  );

  const acosOrRoas = useMemo(
    () => preferences?.acos_or_roas ?? "acos",
    [preferences]
  );

  const annotationCounts = useMemo(() => {
    const data =
      annotationData?.data?.map((date) => date.annotations)?.flat() ?? [];

    return data?.reduce(
      (acc, annotation) => {
        if (annotation.product) {
          acc.productCount++;
        }
        if (annotation.advertisement) {
          acc.advertisementCount++;
        }
        return acc;
      },
      { productCount: 0, advertisementCount: 0 }
    );
  }, [annotationData]);

  const { overviewMetrics, adMetrics, contentMetrics, topNProductsMetrics } =
    useMemo(() => {
      const titleCol = category
        ? [
            col("", "expander", "isExpanded", {
              width: 60,
              hideFilter: true,
              isStatic: true,
            }),
            col("Product", "product", "product_title", {
              className: "text-left font-weight-bold w-auto",
              width: 400,
              mobileWidth: 250,
              showDot: false,
              fixedLeft: true,
              hideFilter: true,
              isStatic: true,
            }),
          ]
        : [
            col("Category", "category", "category_name", {
              className: "text-left font-weight-bold",
              width: 350,
              mobileWidth: 250,
              showDot: false,
              fixedLeft: true,
              hideFilter: true,
              isStatic: true,
            }),
          ];
      const additionalColumns = category
        ? [
            col("Ad Sales Same SKU", "currency", "ad_sales_same_sku", {
              checked: false,
            }),
            col("ROAS Same SKU", "number", "roas_same_sku", {
              checked: false,
            }),
            col("ACOS Same SKU", "percent", "acos_same_sku", {
              checked: false,
            }),
            col("BSR", "number", "last_bsr_rank", {
              checked: false,
              accessor: "last_bsr_rank",
              isNegative: true,
              delta: true,
            }),
            col("Page Views", "number", "page_views", {
              checked: true,
            }),
            col("Conversion Rate", "percent", "conv_rate", {
              checked: true,
            }),
            col("Current Inventory FBA", "number", "product_quantity_fba", {
              checked: false,
              hideCompare: true,
            }),
            col("Current Inventory MFA", "number", "product_quantity", {
              checked: false,
              hideCompare: true,
            }),
          ]
        : [];

      const roasMetrics = [
        buildMetric(marketPlace, data, "troas", "troas_delta", {
          metric: {
            decimalPlaces: 1,
          },
        }),
        buildMetric(marketPlace, data, "roas", "roas_delta", {
          metric: {
            decimalPlaces: 1,
          },
        }),
      ];
      const acosMetrics = [
        buildMetric(marketPlace, data, "total_acos", "total_acos_delta", {
          metric: {
            decimalPlaces: 1,
          },
        }),
        buildMetric(marketPlace, data, "acos", "acos_delta", {
          metric: {
            decimalPlaces: 1,
          },
        }),
      ];

      const acosCol = [
        col("ACOS", "percent", "acos", {
          noColor: true,
          delta: true,
          checked: false,
        }),
        col("Total ACOS", "percent", "total_acos", {
          noColor: true,
          delta: true,
          checked: false,
        }),
      ];

      const roasCol = [
        col("ROAS", "number", "roas", {
          noColor: true,
          delta: true,
          checked: false,
        }),
        col("Total ROAS", "number", "total_roas", {
          noColor: true,
          delta: true,
          checked: false,
        }),
      ];

      return {
        topNProductsMetrics: {
          columns: [
            col("", "expander", "isExpanded", {
              width: 60,
              hideFilter: true,
              isStatic: true,
            }),
            col("Product", "product", "product_title", {
              className: "text-left font-weight-bold w-auto",
              width: 400,
              mobileWidth: 250,
              showDot: false,
              fixedLeft: true,
              hideFilter: true,
              isStatic: true,
            }),
            col("Sales", "currency", "total_sales"),
            col("Units", "number", "total_units"),
            col("Margin", "percent", "margin", {
              delta: true,
            }),
            ...(acosOrRoas === "acos" ? acosCol : roasCol),
            col("Ad Sales", "currency", "ad_sales", { checked: false }),
            //col("Deal Sales", "currency", "promo_sales", { checked: false }),
            col("Ad Spend", "currency", "cost", {
              noColor: true,
              checked: false,
            }),
            ...(acosOrRoas === "acos" ? roasCol : acosCol),
            col("Discounts", "currency", "promo_cost", {
              noColor: true,
              checked: false,
            }),
            col("Cost", "currency", "total_cost", { noColor: true }),
            col("ROMS", "number", "roms", {
              noColor: true,
              delta: true,
            }),
            ...additionalColumns,
            col("Trends", "trends", "trends_score", {
              className: "text-center",
              checked: false,
            }),
          ],
          metrics: [
            buildMetric(marketPlace, data, "sales", "sales_percent"),
            buildMetric(marketPlace, data, "units", "units_percent"),
            buildMetric(marketPlace, data, "margin", "margin_delta"),
            ...(acosOrRoas === "acos" ? acosMetrics : roasMetrics),
            buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
            buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
            ...(acosOrRoas === "acos" ? roasMetrics : acosMetrics),
            buildMetric(marketPlace, data, "roms", "roms_delta"),
            buildMetric(marketPlace, data, "cost", "cost_percent"),
            buildMetric(marketPlace, data, "promo_cost", "promo_cost_percent"),
            buildMetric(marketPlace, data, "ctr", "ctr_delta"),
            buildMetric(marketPlace, data, "orders", "orders_percent"),
            buildMetric(marketPlace, data, "cpc", "cpc_delta"),
            buildMetric(
              marketPlace,
              data,
              "conversion_rate",
              "conversion_rate_delta"
            ),
          ],
        },
        overviewMetrics: {
          columns: [
            ...titleCol,
            col("Sales", "currency", "total_sales"),
            col("Units", "number", "total_units"),
            col("Margin", "percent", "margin", {
              delta: true,
            }),
            ...(acosOrRoas === "acos" ? acosCol : roasCol),
            col("Ad Sales", "currency", "ad_sales", { checked: false }),
            //col("Deal Sales", "currency", "promo_sales", { checked: false }),
            col("Ad Spend", "currency", "cost", {
              noColor: true,
              checked: false,
            }),
            ...(acosOrRoas === "acos" ? roasCol : acosCol),
            col("Discounts", "currency", "promo_cost", {
              noColor: true,
              checked: false,
            }),
            col("Cost", "currency", "total_cost", { noColor: true }),
            col("ROMS", "number", "roms", {
              noColor: true,
              delta: true,
            }),
            ...additionalColumns,
            col("Trends", "trends", "trends_score", {
              className: "text-center",
              checked: false,
            }),
          ],
          metrics: [
            buildMetric(marketPlace, data, "sales", "sales_percent"),
            buildMetric(marketPlace, data, "units", "units_percent"),
            buildMetric(marketPlace, data, "margin", "margin_delta"),
            ...(acosOrRoas === "acos" ? acosMetrics : roasMetrics),
            buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
            buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
            ...(acosOrRoas === "acos" ? roasMetrics : acosMetrics),
            buildMetric(marketPlace, data, "roms", "roms_delta"),
            buildMetric(marketPlace, data, "cost", "cost_percent"),
            buildMetric(marketPlace, data, "promo_cost", "promo_cost_percent"),
            buildMetric(marketPlace, data, "ctr", "ctr_delta"),
            buildMetric(marketPlace, data, "orders", "orders_percent"),
            buildMetric(marketPlace, data, "cpc", "cpc_delta"),
            buildMetric(
              marketPlace,
              data,
              "conversion_rate",
              "conversion_rate_delta"
            ),
          ],
        },
        adMetrics: {
          metrics: [
            buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
            buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
            buildMetric(marketPlace, data, "roas", "roas_delta", {
              metric: {
                decimalPlaces: 1,
              },
            }),
            buildMetric(marketPlace, data, "clicks", "clicks_percent"),
          ],
        },
        contentMetrics: {
          metrics: [
            buildMetric(marketPlace, data, "seo_score", "seo_score_percent"),
            buildMetric(marketPlace, data, "ctr", "ctr_delta"),
            buildMetric(
              marketPlace,
              data,
              "buyability_score",
              "buyability_score_percent"
            ),
            buildMetric(
              marketPlace,
              data,
              "conversion_rate",
              "conversion_rate_delta"
            ),
          ],
        },
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, acosOrRoas, marketPlace, group]);

  const breadCrumbItems = [
    {
      name: "Merchandising Dashboard",
      href: getDashboardTo({
        resourceId: null,
        type: DASHBOARD_TYPES.ROOT,
      }),
      dropdownLinkOptions: DASHBOARD_LINKS,
      searchable: false,
      unsorted: true,
      dropdownSubtitle: "Dashboards",
    },
  ];
  if (category) {
    breadCrumbItems.push({
      name: categoryDetails?.leaf_category_name,
      href: categoryDetails.href,
      dropdownLinkOptions: categoryData,
      searchable: true,
      searchPlaceholderText: "Search categories...",
      dropdownSubtitle: "Categories",
    });
  }

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  const cards = useMemo(() => {
    const settings = user.preferences?.merchandise_dashboard ?? {};
    return CARDS.map((c) => ({
      ...c,
      enabled: settings?.[c.id] ?? true,
    })).filter((c) => checkApplication(user, c.app));
  }, [user]);

  const updateMerchandisePreferences = useMutation(async ({ id, enabled }) => {
    const settings = user.preferences?.merchandise_dashboard ?? {};
    const merchandise_dashboard = { ...settings, [id]: enabled };
    await api.put("/api/org_permissions/", { merchandise_dashboard });
    store.dispatch(
      setMerchandiseDashboard(merchandise_dashboard, user.preferences)
    );
  });

  const isSPAPIConnected = useIsSPAPIConnected(user, channel);

  const showEmpty = useMemo(() => {
    const totalSalesStart =
      user.total_sales_start_date?.[marketPlace.marketPlace];
    if (totalSalesStart) {
      if (moment(totalSalesStart).isBefore(moment().subtract(2, "days"))) {
        return false;
      } else {
        return "COLLECTING_DATA";
      }
    }
    if (isSPAPIConnected) {
      return "COLLECTING_DATA";
    }
    return "NO_DATA";
  }, [user, marketPlace.marketPlace, isSPAPIConnected]);

  if (user.organization_type === "vendor" && channel === "walmart") {
    return <Redirect to="/user/advertising/dashboard" />;
  }

  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      {showEmpty ? (
        <EmptyState type={showEmpty} modules={modules} />
      ) : (
        <>
          <Row>
            <Col xs={12} className="mb-4">
              <BreadcrumbDates items={breadCrumbItems}>
                <NavDropdown
                  id="BreadcrumbSettings"
                  title={<i className="fa fa-cog" />}
                  className="fs-standard noarrow"
                >
                  {cards.map((c) => (
                    <div role="menuitem" className="py-1" key={c.id}>
                      <label
                        style={{
                          marginLeft: "1rem",
                          marginTop: "0.5rem",
                          fontWeight: "normal",
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: "unset", marginRight: "1rem" }}
                          onChange={(e) => {
                            e.stopPropagation();
                            updateMerchandisePreferences.mutate({
                              id: c.id,
                              enabled: !c.enabled,
                            });
                          }}
                          checked={c.enabled}
                        />
                        {c.name}
                      </label>
                    </div>
                  ))}
                </NavDropdown>
              </BreadcrumbDates>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="px-3 mb-4">
              {isLoading && <Loading />}
              {isError && <p>Error Loading Dashboard</p>}
              {isSuccess && (
                <>
                  <Row>
                    <Col xs={12} className="mb-4">
                      <MetricsCard
                        title={"Overview"}
                        byline={
                          <>
                            {start.format("MMMM Do YYYY")}
                            {" - "}
                            {end.format("MMMM Do YYYY")}
                            {isFetching && (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </>
                        }
                        metrics={overviewMetrics.metrics}
                        data={data?.date}
                        initialSelectedMetric={[
                          "sales",
                          "units",
                          acosOrRoas ? "tacos" : "troas",
                          "margin",
                        ]}
                        loading={isFetching}
                        showAnnotations={true}
                        group={group}
                        showMetricsSelector
                        id={
                          category
                            ? "merchProductsTable"
                            : "merchCategoriesTable"
                        }
                      >
                        <Col xs={12}>
                          <Accordion
                            title={category ? "Products" : "Categories"}
                          >
                            <Row>
                              <Col xs={12}>
                                <Table
                                  stats={
                                    category
                                      ? tableData?.products
                                      : tableData?.categories
                                  }
                                  isLoading={isTableDataLoading}
                                  ExpandedContent={ExpandedContent}
                                  marketPlace={marketPlace}
                                  mobileStyle={mobileStyle}
                                  columnSpecs={overviewMetrics.columns}
                                  date={date}
                                  category={category}
                                  user={user}
                                  modules={modules}
                                  channel={channel}
                                />
                              </Col>
                              <Col xs={12} className="text-right">
                                <DownloadReport
                                  url_path={
                                    category
                                      ? "product_report"
                                      : "category_report"
                                  }
                                  filters={downloadFilters}
                                  report_dl={category ? "product" : "category"}
                                />
                              </Col>
                            </Row>
                          </Accordion>
                          {!category && hasTop500Module && (
                            <Accordion title={"Top 500 Products"}>
                              <Row>
                                <Col xs={12}>
                                  <Table
                                    stats={topProductsData}
                                    isLoading={topProductsDataLoading}
                                    ExpandedContent={ExpandedContent}
                                    marketPlace={marketPlace}
                                    mobileStyle={mobileStyle}
                                    columnSpecs={topNProductsMetrics.columns}
                                    date={date}
                                    user={user}
                                    modules={modules}
                                    channel={channel}
                                  />
                                </Col>
                                <Col xs={12} className="text-right">
                                  <DownloadReport
                                    url_path={"product_report"}
                                    filters={topProductsDownloadFilters}
                                    report_dl={"product"}
                                  />
                                </Col>
                              </Row>
                            </Accordion>
                          )}
                          <Accordion title={"Impact Analysis"}>
                            <Row>
                              <Col
                                xs={12}
                                className="fs-standard col-12 justify-content-end d-flex pe-5"
                              >
                                <ButtonGroup>
                                  {[
                                    {
                                      value: "advertisement",
                                      label: (
                                        <>
                                          Media Plans{" "}
                                          {annotationCounts[
                                            "advertisementCount"
                                          ] ? (
                                            <Badge
                                              bsClass="badge px-2"
                                              className={
                                                annotationsTab ===
                                                "advertisement"
                                                  ? "bade bg-white text-fuchsia"
                                                  : `badge bg-trellis-fuchsia text-white`
                                              }
                                              style={{ marginLeft: ".5rem" }}
                                              pill
                                            >
                                              {
                                                annotationCounts[
                                                  "advertisementCount"
                                                ]
                                              }
                                            </Badge>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ),
                                    },

                                    {
                                      value: "product",
                                      label: (
                                        <>
                                          Products{" "}
                                          {annotationCounts["productCount"] ? (
                                            <Badge
                                              bsClass="badge px-2"
                                              className={
                                                annotationsTab === "product"
                                                  ? "badge bg-white text-fuchsia"
                                                  : "badge bg-trellis-fuchsia text-white"
                                              }
                                              style={{ marginLeft: ".5rem" }}
                                              pill
                                            >
                                              {annotationCounts["productCount"]}
                                            </Badge>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ),
                                    },
                                  ]?.map((group) => {
                                    const { value, label } = group;
                                    return (
                                      <Button
                                        onClick={() => setAnnotationsTab(value)}
                                        className="fs-6"
                                        variant={
                                          annotationsTab === value
                                            ? "contained"
                                            : null
                                        }
                                        color={
                                          annotationsTab === value
                                            ? "secondary"
                                            : null
                                        }
                                      >
                                        {label}
                                      </Button>
                                    );
                                  })}
                                </ButtonGroup>
                              </Col>
                              <Col xs={12}>
                                <AnnotationsTable
                                  data={annotationData?.data ?? []}
                                  isLoading={isAnnotationDataLoading}
                                  marketPlace={marketPlace.marketPlace}
                                  annotationType={annotationsTab}
                                />
                              </Col>
                            </Row>
                          </Accordion>
                          {checkModule(modules, "MAP") &&
                            marketPlace.marketPlace === "USA" &&
                            channel === "amazon" && (
                              <Accordion title={"Sales By State"}>
                                <Row>
                                  <Col xs={12}>
                                    <SalesMapUSA
                                      start_date={start.format("YYYY-MM-DD")}
                                      end_date={end.format("YYYY-MM-DD")}
                                      marketPlace={marketPlace}
                                      categoryId={category}
                                    />
                                  </Col>
                                </Row>
                              </Accordion>
                            )}
                        </Col>
                      </MetricsCard>
                    </Col>
                    <Col xs={12} className="">
                      <Card className="p-3">
                        <Card.Body>
                          <Row>
                            {cards.find((c) => c.app === ADVERTISING)
                              ?.enabled && (
                              <DetailedMetricRow
                                title={"Advertising"}
                                byline={
                                  <>
                                    <ThemeLine>Placement</ThemeLine>
                                    Create and manage media plans
                                  </>
                                }
                                metrics={adMetrics.metrics}
                                data={data?.date}
                                icon={AdvertisingIcon}
                                to={
                                  category
                                    ? `/user/advertising/dashboard/category/${category}`
                                    : "/user/advertising/dashboard"
                                }
                              />
                            )}
                            {cards.find((c) => c.app === DEALS)?.enabled && (
                              <>
                                <Col className="border-top" xs={12}></Col>
                                <PromotionsCard
                                  marketPlace={marketPlace}
                                  category={category}
                                />
                              </>
                            )}
                            {cards.find((c) => c.app === DYNAMIC_PRICING)
                              ?.enabled && (
                              <>
                                <Col className="border-top" xs={12}></Col>
                                <PricingCard
                                  marketPlace={marketPlace}
                                  category={category}
                                />
                              </>
                            )}
                            {cards.find((c) => c.app === CONTENT)?.enabled && (
                              <>
                                <Col className="border-top" xs={12}></Col>
                                <ContentCard
                                  metrics={contentMetrics.metrics}
                                  data={data?.date}
                                  marketPlace={marketPlace}
                                  category={category}
                                />
                              </>
                            )}
                            {cards.find((c) => c.app === NEW_PRODUCT_LAUNCH)
                              ?.enabled && (
                              <>
                                <Col className="border-top" xs={12}></Col>
                                <NPICard
                                  marketPlace={marketPlace}
                                  category={category}
                                />
                              </>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};

const MerchDashboard = ({ user, marketPlace, modules, channel }) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { category = null } = useParams();
  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <VerifyEmailModal />
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        channel={channel}
        category={category}
        modules={modules}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
  channel: state.channel.currentChannel,
});

export default connect(mapStateToProps)(MerchDashboard);
