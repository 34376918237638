import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withStyles } from "@material-ui/core";
import AnnotationLabel from "./AnnotationLabel";
import { trellisPalette } from "components/custom/analytics/palettes";
import { useFetch } from "hooks/api";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { selectModules } from "redux/selectors/modules";
import moment from "moment";
import { useLegend } from "../../../legend/useLegend";
import { formatNumber } from "utils/formatNumber";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import withMediaQuery from "hocs/withMediaQuery";
import { useDates } from "dates/useDates";
import { Col, Row } from "react-bootstrap";

// Tweak highcharts default settings
Highcharts.AST.allowedAttributes.push("data-value");
Highcharts.seriesTypes.scatter.prototype.noSharedTooltip = false;

let defaultChartHeight = 400;

const useStyles = (theme) => ({
  chartContainer: {
    display: "block",
    position: "relative",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginLeft: "52px",
    marginRight: "52px",
    marginTop: "-50px",
    marginBottom: "50px",
    height: "10px",
    position: "relative",
  },
  dropdownText: {
    color: "#403E3D",
    fontWeight: 600,
  },
  inlineButton: {
    background: trellisPalette[10],
    border: "none",
    borderRadius: "10000000000px",
    color: "#FFF",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    position: "absolute",
    right: "10px",
    top: `${defaultChartHeight - 65}px`,
    fontSize: "1.3rem",
    zIndex: 99999999999,
  },
});

const AnnotationChartTemplate = ({
  classes,
  options,
  rollup,
  selectedProduct,
  selectedCategory,
  selectedAd,
  height,
  yLabels,
  marketPlace,
  context,
  updateAnnotationData,
  showAnnotations,
  user: { tier },
  isChartDataLoading = false,
  mediaQuery,
  labelSpan,
  handleLegendChange,
}) => {
  const [chart, setChart] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [annotationData, setAnnotationData] = useState([]);

  // By default, annotations assumed depending on subscription tier, unless explicity disabled in showAnnotations prop (i.e. the organization summary chart)
  const annotationsEnabled = showAnnotations !== false;

  const [legendPreferences, setLegend] = useLegend(context);
  let { group } = useDates() ?? rollup.rollup;
  const chartRef = useRef();

  if (!group) {
    if (rollup.rollup === "monthly") {
      group = "month";
    } else if (rollup.rollup === "weekly") {
      group = "week";
    } else {
      group = "day";
    }
  }
  useEffect(() => {
    // Set selected legend items based on status passed down from legend context
    if (chartRef?.current?.chart?.series && legendPreferences) {
      chartRef.current.chart.series.forEach((s) => {
        s.visible =
          legendPreferences.find((legendItem) => legendItem.name === s.name)
            ?.visible ?? false;
      });

      chartRef.current.chart.legend.allItems.forEach((i) => {
        i.userOptions.visible =
          legendPreferences.find((legendItem) => legendItem.name === i.name)
            ?.visible ?? false;
      });
    }

    chartRef.current?.chart && chartRef.current.chart.redraw();
  }, [legendPreferences]);

  const { series, yAxis, xAxis, title, plotOptions } = options;

  const marketplace = marketPlace.marketPlace;

  const baseQuery = { marketplace };

  let ts_after =
    group === "month"
      ? `${moment(options?.xAxis?.categories[0])
          .startOf("M")
          .format("YYYY-MM-DD")}T00:00:00`
      : `${moment(options?.xAxis?.categories[0]).format(
          "YYYY-MM-DD"
        )}T00:00:00`;
  let ts_before =
    group === "month"
      ? `${moment(
          options?.xAxis?.categories[options.xAxis.categories.length - 1]
        )
          .endOf("M")
          .format("YYYY-MM-DD")}T23:59:59`
      : `${moment(
          options?.xAxis?.categories[options.xAxis.categories.length - 1]
        ).format("YYYY-MM-DD")}T23:59:59`;

  if (selectedCategory !== "all") {
    baseQuery["category"] = selectedCategory;
  }

  if (selectedProduct) {
    baseQuery["product"] = selectedProduct;
  }

  if (selectedAd) {
    baseQuery["advertisement"] = selectedAd;
  }

  if (context === "purchase-behavior") {
    baseQuery["force_start_dates"] = false;
  }

  const { data, isLoading } = useFetch(
    [
      "annotations",
      ts_after,
      ts_before,
      group,
      selectedProduct,
      selectedCategory,
      selectedAd,
      annotationsEnabled,
    ],
    "/api/annotations/",
    {
      groupby: group,
      ts_after,
      ts_before,
      ...baseQuery,
    },
    // ts_before and ts_after need values from categories to contain start and end date within query string
    {
      enabled: !!options?.xAxis?.categories[0],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
    }
  );

  useEffect(() => {
    setSelectedDate(null);
  }, [selectedProduct, selectedAd]);

  useEffect(() => {
    if (!annotationsEnabled) {
      setAnnotationData([]);
      if (context === "category") {
        updateAnnotationData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationsEnabled]);

  useEffect(() => {
    if (
      isLoading &&
      isLoading !== annotationData &&
      annotationsEnabled &&
      context === "category"
    ) {
      updateAnnotationData({ isLoading: isLoading });
    }
    if (data && annotationsEnabled) {
      setAnnotationData(
        data.data.filter((annotation) => {
          return options?.xAxis?.categories.find((c) =>
            moment(c)?.isSame(moment(annotation.date), group)
          );
        })
      );
    }

    // Below passes updated annotation data for rendering in category overview
    if (data && annotationsEnabled && context === "category") {
      updateAnnotationData(
        data?.data.filter((date) => date.annotations?.length > 0).reverse()
      );
    }

    // Clear annotations if chart data is empty - no rollup stats to display
    if (!data && annotationData.length > 0) {
      setAnnotationData([]);
      if (context === "category") {
        updateAnnotationData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, annotationsEnabled]);

  const afterChartCreated = (chart) => {
    setChart(chart);
  };

  useEffect(() => {
    if (chart) {
      chart.getMargins();
      renderLabels();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart]);

  const renderLabels = () => {
    const customLabels = [];

    if (chart && chart.xAxis?.[0]) {
      // Add custom label associated with each tick on xAxis (date)
      Highcharts.objectEach(chart.axes[0].ticks, (tick, index) => {
        if (annotationData[tick.pos]) {
          customLabels.push(
            <AnnotationLabel
              data={annotationData[tick.pos]}
              date={annotationData[tick.pos].date}
              selectedProduct={selectedProduct}
              selectedAd={selectedAd}
              selectedDate={selectedDate}
              selectedCategory={selectedCategory}
              setSelectedDate={setSelectedDate}
              addAnnotation={addAnnotation}
              editAnnotation={editAnnotation}
              removeAnnotation={removeAnnotation}
              key={index}
              tick={tick?.label}
              rollup={rollup}
              context={context}
            />
          );
        }
      });
    }
    return customLabels;
  };

  const chartOptions = {
    title,
    chart: {
      zoomType: "x",
      marginLeft: context === "multi-metric" ? 15 : 50,
      marginRight: context === "multi-metric" ? 15 : 50,
      style: {
        fontFamily: "Poppins",
        fontSize: "15px",
        cursor: "zoom-in",
      },
      height: height,
    },
    plotOptions: plotOptions
      ? plotOptions
      : {
          series: {
            pointPadding: 0,
            groupPadding: 0.1,
            borderWidth: 0,
            shadow: false,
            events: {
              legendItemClick: (e) => {
                if (handleLegendChange) {
                  return handleLegendChange(e);
                }
                const legendOptions = chartRef?.current?.chart?.series?.map(
                  (s) => ({ name: s.name, visible: s.visible })
                );

                chartRef.current.chart.isDirtyLegend = true;

                legendOptions.find(
                  (item) => item.name === e.target.legendItem.textStr
                ).visible = !legendOptions.find(
                  (item) => item.name === e.target.legendItem.textStr
                ).visible;

                setLegend(legendOptions);
                chartRef.current.chart.redraw();
              },
              render: (e) => {
                if (
                  chartRef.current &&
                  !chartOptions.legend.enabled === false
                ) {
                  chartRef.current.chart.legend.render();
                }
              },
            },
          },
        },
    tooltip: {
      shared: true,
      formatter: function () {
        let hasFormatter = false;
        let points = this.points;
        let markup = "";
        if (options?.tooltip?.formatter) {
          hasFormatter = true;
          markup = options.tooltip.formatter.bind(this)();
        }
        if (!hasFormatter) {
          markup = points.length
            ? '<span style="font-size: 10px; font-weight: bold; margin-left: 1rem">' +
              points[0].key +
              "</span><br/>"
            : "";
        }

        for (let index = 0; index < points.length; index++) {
          let point = points[index];
          if (point.series.name !== "annotationAxis") {
            if (!hasFormatter) {
              markup +=
                '<span style="color:' +
                point.series.color +
                '">\u25CF</span> ' +
                point.series.name +
                ": " +
                (point.series.name.indexOf("Sales") > -1 ||
                point.series.name.indexOf("Spend") > -1 ||
                point.series.name.indexOf("Product Price") > -1
                  ? formatNumber(point.y, {
                      currency: { marketPlace: marketPlace.marketPlace },
                    })
                  : formatNumber(
                      point.series.name.indexOf("Margin") > -1
                        ? +(point.y * 100).toFixed(2)
                        : point.series.name.indexOf("Units Goal") > -1 ||
                          point.series.name.indexOf("Ratings Goal") > -1
                        ? Math.round(point.y)
                        : point.y
                    )) +
                (point.series.name.indexOf("ACOS") > -1 ||
                point.series.name.indexOf("Margin") > -1
                  ? "%"
                  : "") +
                "<br/>";
            }
          } else {
            markup +=
              '<span style="color:' +
              point.series.color +
              '">\u25CF</span> # of Annotations: ' +
              annotationData.find((a) =>
                moment(a.date).isSame(moment(points[0].key), group)
              )?.annotations?.length +
              "<br/>";

            // Show count of annotations and guide user to click on dots to view and interact with them
            markup +=
              "<br/><span>Click on the pink dot<br/>below to view or create<br/>annotated events</span>";
          }
        }

        return markup;
      },
    },
    xAxis: [
      {
        crosshair: true,
        categories: xAxis?.categories ?? [],
        plotLines: xAxis?.plotLines ?? [],
        labels: {
          step: mediaQuery
            ? 0
            : labelSpan
            ? labelSpan
            : group === "day"
            ? context === "purchase-behavior"
              ? 4
              : context === "multi-metric"
              ? xAxis?.categories?.length > 90
                ? 0
                : xAxis?.categories?.length > 60
                ? 10
                : xAxis?.categories?.length > 30
                ? 5
                : 2
              : context === "price-optimization" || context === "merch-planning"
              ? 3
              : context === "npl"
              ? 5
              : // Setting step to 0 allows highcharts to take care of step automatically
              context === "analytics-overview" || context === "nested-product"
              ? 0
              : 9
            : group === "week" &&
              (context === "purchase-behavior" || context === "multi-metric")
            ? 2
            : 1,
          formatter: function () {
            // Different date formatting in purchase behavior
            if (context === "purchase-behavior") {
              switch (rollup.rollup) {
                case "monthly":
                case "month":
                  return moment(this.value).format("MMM YY");
                case "weekly":
                case "week":
                  return moment(this.value).format("MMM DD");
                case "daily":
                case "day":
                  return moment(this.value).format("MMM DD");
                default:
                  break;
              }
            }
            // Below allows custom label formtter where xAxis labels can be displayed differently that series category
            // Note: categories need to be synced between series and annotation axis
            if (options?.xAxis?.labels?.customFormatter) {
              return options.xAxis.labels.customFormatter(this.value);
            }

            return this.value;
          },
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1400,
          },
          chartOptions: {
            xAxis: {
              labels: {
                step:
                  group === "day"
                    ? context === "purchase-behavior" ||
                      context === "multi-metric"
                      ? 2
                      : 11
                    : 1,
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 750,
          },
          chartOptions: {
            xAxis: {
              labels: {
                step: group === "day" ? 14 : 2,
              },
            },
          },
        },
      ],
    },
    yAxis: Array.from(Array(50)).map(() => ({
      title: yAxis?.title ?? "",
      min: 0,
      labels: {
        enabled: yLabels,
      },
    })),
    legend: {
      itemStyle: {
        fontWeight: "bold",
      },
      itemHiddenStyle: {
        "text-decoration": "none",
        color: "#bbb",
      },
      ...(options?.legend
        ? options.legend
        : {
            verticalAlign: "top",
          }),
    },
    series: [
      {
        name: "annotationAxis",
        id: "annotationAxis",
        type: "scatter",
        tooltip: {
          shared: true,
        },
        showInLegend: false,
        data: annotationData.map((a) => ({
          y: 0,
          color:
            a.annotations?.length > 0 || a.date === selectedDate
              ? trellisPalette[1]
              : "none",
          marker: {
            states: {
              hover: {
                fillColor:
                  // Don't render annotation dots on hover if in read-only context and there are not annotations to render
                  (group === "day" &&
                    context !== "category" &&
                    (selectedProduct || selectedAd || selectedCategory) &&
                    (selectedCategory !== "all" || selectedProduct)) ||
                  a.annotations?.length > 0
                    ? trellisPalette[1]
                    : "none",
              },
            },
          },
        })),
        events: {
          hover: (e) => {
            if (
              group === "day" &&
              context !== "category" &&
              (selectedProduct || selectedAd) &&
              selectedCategory !== "all"
            ) {
              e.target.style.cursor = "pointer";
            }
          },
          click: (e) => {
            e.preventDefault();

            // Don't render blank labels unless in context that allows create and update interactions
            if (
              (group === "day" && context !== "category") ||
              annotationData.find((a) => {
                return a.date === moment(e.point.category).format("YYYY-MM-DD");
              })?.annotations.length > 0
            ) {
              if (
                moment(e.point.category).format("YYYY-MM-DD") === selectedDate
              ) {
                setSelectedDate(null);
              } else {
                setSelectedDate(moment(e.point.category).format("YYYY-MM-DD"));
              }
            }
          },
        },
        cursor: "pointer",
        yAxis: 1,
        step: "right",
        visible: true,
        color: trellisPalette[0],
        zIndex: 9999999,
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 6,
        },
      },
      ...series,
    ],
  };

  // Annotation CRUD Requests
  const queryClient = useQueryClient();

  const createAnnotation = useMutation(
    async (newAnnotation) => {
      return await api.post("/api/annotations/", newAnnotation);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("annotations");
      },
    }
  );

  const updateAnnotation = useMutation(
    async (updatedAnnotation) => {
      const { id, marketplace, description, ts } = updatedAnnotation;
      return await api.put(`/api/annotations/${id}/`, {
        category: selectedCategory,
        marketplace,
        description,
        ts,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("annotations");
      },
    }
  );

  const deleteAnnotation = useMutation(
    async (id) => {
      return await api.remove(`/api/annotations/${id}/`);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("annotations");
      },
    }
  );

  const addAnnotation = (formData) => {
    const { annotation } = formData;

    const newAnnotationData = {
      category: selectedCategory,
      marketplace: marketPlace.marketPlace,
      description: annotation,
      ts: `${selectedDate}T00:00:00Z`,
    };

    if (selectedProduct) {
      newAnnotationData["product"] = selectedProduct;
    }

    if (selectedAd) {
      newAnnotationData["advertisement"] = selectedAd;
    }
    createAnnotation.mutate(newAnnotationData);
  };

  const editAnnotation = (currentAnnotation) => {
    updateAnnotation.mutate(currentAnnotation);
  };

  const removeAnnotation = (id) => {
    deleteAnnotation.mutate(id);
  };

  return (
    <Row className="w-100">
      <Col xs={12} className={classes.chartContainer}>
        {isChartDataLoading ? (
          <div
            style={{
              minHeight: "430px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={logo} alt="Loading..." className={`rotate`} width="80" />
          </div>
        ) : (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              options={{ ...chartOptions }}
              ref={chartRef}
              callback={afterChartCreated}
              oneToOne={true}
              allowChartUpdate={true}
              key={1}
            />
            {annotationsEnabled && (
              <div className={classes.labelContainer}>{renderLabels()}</div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

AnnotationChartTemplate.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
});

export default withMediaQuery("(max-width:600px)")(
  connect(mapStateToProps)(withStyles(useStyles)(AnnotationChartTemplate))
);
