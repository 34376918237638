import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import getURLPrefix from "utils/getUrlPrefix";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";

import Card from "components/core/basic/Card";
import ShopifySetup from "./integrations/ShopifySetup";
import GoogleSetupV2 from "./integrations/GoogleSetupV2";
import WalmartMarketplaceSetup from "./integrations/WalmartMarketplaceSetup";

import "assets/css/pricing.css";
import WalmartAdvertisingSetup from "./integrations/WalmartAdvertisingSetup";

const Apps = () => {
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [showShopifySetup, setShowShopifySetup] = useState(false);
  const [showGoogleSetup, setShowGoogleSetup] = useState(false);
  const [showWMMPSetup, setShowWMMPSetup] = useState(false);
  const [showWalmartAdvertiserSetup, setShowWalmartAdvertiserSetup] =
    useState(false);

  const history = useHistory();

  const getStatus = useCallback(
    (key) => {
      if (!status.hasOwnProperty(key)) {
        return "not_connected";
      }

      return status[key].status;
    },
    [status]
  );

  const useFetchInterval = (delay) => {
    const [doFetch, setDoFetch] = useState(true);

    useEffect(() => {
      const handler = setInterval(() => {
        fetchStatus();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [delay, setDoFetch]);
    return doFetch;
  };

  const routeChange = (path) => {
    history.push(path);
  };

  const fetchStatus = useCallback(async () => {
    let URL_PREFIX = getURLPrefix();
    axios.get(URL_PREFIX + "/api/apps/status", {}).then((res) => {
      setStatus(res.data);
      setLoading(false);
    });
  }, []);

  const getStatusText = (status) => {
    switch (status) {
      case "not_connected":
        return "Not Connected";
      case "connected":
        return "Connected";
      case "loading":
        return "Loading...";
      case "not_a_google_org":
        return "Select a google organization to see preferences.";
      default:
        return "Waiting Approval";
    }
  };

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  // Fetch App Status every 30 seconds
  useFetchInterval(30000);

  return (
    <div className="container">
      <Row>
        <Col className={"s8 offset-s2 text-center"}>
          <h4>
            <b>Trellis Connect Apps</b>
          </h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card
            content={
              !loading ? (
                <Row style={{ margin: "20px" }}>
                  <Col xs={12}>
                    <p className="text-center" style={{ marginBottom: "50px" }}>
                      On the Trellis Connect Apps screen you can enable various
                      apps to unlock new features.
                    </p>
                    <Row style={{ paddingBottom: "15px" }}>
                      <Col xs={6}>
                        <strong>App</strong>
                      </Col>
                      <Col xs={3} className={"text-center"}>
                        <strong>Connect</strong>
                      </Col>
                      <Col xs={3}>&nbsp;</Col>
                    </Row>
                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginBottom: "10px",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      <Col xs={6} style={{ paddingTop: "10px" }}>
                        Amazon Advertising
                      </Col>
                      <Col
                        xs={3}
                        className={"text-center"}
                        style={{ paddingTop: "10px" }}
                      >
                        {getStatusText(getStatus("amazon"))}
                      </Col>
                      <Col xs={3} className={"text-center"}>
                        <Button
                          onClick={() => routeChange("/user/preferences")}
                        >
                          <strong>Preferences</strong>
                        </Button>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginBottom: "10px",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      <Col xs={6} style={{ paddingTop: "10px" }}>
                        Amazon Connect
                      </Col>
                      <Col
                        xs={3}
                        className={"text-center"}
                        style={{ paddingTop: "10px" }}
                      >
                        {getStatusText(getStatus("amazon_mws"))}
                      </Col>
                      <Col xs={3} className={"text-center"}>
                        {getStatus("amazon_mws") === "connected" ? (
                          <Button
                            onClick={() => routeChange("/user/amzsummary")}
                          >
                            <strong>Preferences</strong>
                          </Button>
                        ) : (
                          <Button
                            onClick={() => routeChange("/user/amzsummary")}
                          >
                            <strong>Connect</strong>
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginBottom: "10px",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      <Col xs={6} style={{ paddingTop: "10px" }}>
                        Google Shopping
                      </Col>
                      <Col
                        xs={3}
                        className={"text-center"}
                        style={{ paddingTop: "10px" }}
                      >
                        {getStatusText(getStatus("google"))}
                      </Col>
                      <Col xs={3} className={"text-center"}>
                        {showGoogleSetup ? (
                          // We want to reset the state of this entirely when it closes, hence the conditional.
                          <GoogleSetupV2
                            open={showGoogleSetup}
                            onClose={() => {
                              fetchStatus();
                              setShowGoogleSetup(false);
                            }}
                          />
                        ) : null}
                        <Button onClick={() => setShowGoogleSetup(true)}>
                          <strong>Setup</strong>
                        </Button>
                        {getStatus("google") === "connected" ? (
                          <Button
                            onClick={() => routeChange("/user/googleshopping")}
                          >
                            <strong>Preferences</strong>
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginBottom: "10px",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      <Col xs={6} style={{ paddingTop: "10px" }}>
                        Walmart Advertising
                      </Col>
                      <Col
                        xs={3}
                        className={"text-center"}
                        style={{ paddingTop: "10px" }}
                      >
                        {getStatusText(getStatus("wm-advertising"))}
                      </Col>
                      <Col xs={3} className={"text-center"}>
                        {showWalmartAdvertiserSetup ? (
                          <WalmartAdvertisingSetup
                            open={showWalmartAdvertiserSetup}
                            onClose={() => {
                              fetchStatus();
                              setShowWalmartAdvertiserSetup(false);
                            }}
                          />
                        ) : null}
                        <Button
                          onClick={() => setShowWalmartAdvertiserSetup(true)}
                        >
                          <strong>Setup</strong>
                        </Button>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginBottom: "10px",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      <Col xs={6} style={{ paddingTop: "10px" }}>
                        Walmart Marketplace
                      </Col>
                      <Col
                        xs={3}
                        className={"text-center"}
                        style={{ paddingTop: "10px" }}
                      >
                        {getStatusText(getStatus("wm-mp"))}
                      </Col>
                      <Col xs={3} className={"text-center"}>
                        {showWMMPSetup ? (
                          // We want to reset the state of this entirely when it closes, hence the conditional.
                          <WalmartMarketplaceSetup
                            open={showWMMPSetup}
                            onClose={() => {
                              fetchStatus();
                              setShowWMMPSetup(false);
                            }}
                          />
                        ) : null}
                        <Button onClick={() => setShowWMMPSetup(true)}>
                          <strong>Setup</strong>
                        </Button>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Col xs={6} style={{ paddingTop: "10px" }}>
                        Shopify
                        <small style={{ display: "block" }}>
                          Connect your Shopify store to track conversions from
                          advertising channels.
                        </small>
                      </Col>
                      <Col
                        xs={3}
                        className={"text-center"}
                        style={{ paddingTop: "10px" }}
                      >
                        {getStatusText(getStatus("shopify"))}
                      </Col>
                      <Col xs={3} className={"text-center"}>
                        {getStatus("shopify") === "connected" ? (
                          <strong>Connected</strong>
                        ) : (
                          <>
                            <ShopifySetup
                              oauthUrl={status["shopify"].oauth_url}
                              is_visible={showShopifySetup}
                              onHide={() => setShowShopifySetup(false)}
                            />
                            <Button onClick={() => setShowShopifySetup(true)}>
                              <strong>Connect</strong>
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null
            }
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(withRouter(Apps));
