import React, { useState, useRef } from "react";
import { Box, Grid, TextField, Button, withStyles } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getAPIKeys } from "utils/getUrlPrefix";
import Cryptr from "cryptr";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CLIENT_SIDE_ENCRYPTION_KEY } from "utils/gvSecrets";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { setChannel } from "redux/actions/channel";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import setAuthToken from "utils/setAuthToken";
import {
  logoutUser,
  setCurrentUser,
  setTokens,
} from "redux/actions/authActions";
import userMarketPlace from "utils/userMarketPlace";
import clearCookies from "utils/clearCookies";
import { setMarketPlace } from "redux/actions/marketPlace";
import api from "utils/api";

const useStyles = (theme) => ({
  basePanel: {
    height: "100%",
    backgroundColor: "white",
    justifyContent: "flex-start",
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
      justifyContent: "center",
    },
  },
  scrollColumn: {
    // display: "flex",
    position: "relative",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
  },
  formColumn: {
    justifyContent: "center",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: "0%",
    top: "50%",
    width: "100%",
    transform: "translateY(-50%)",
    overflow: "hidden",
  },
  button: {
    width: "50%",
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    backgroundColor: "#d91366",
  },
  signupLink: {
    color: "#d91366",
    textDecoration: "underline",
  },
  welcomeText: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: "bold",
    color: theme.palette.grey[800],
    textAlign: "center",
  },
  input: {
    fontSize: theme.typography.h4.fontSize,
  },
  inputlabel: {
    fontSize: theme.typography.h4.fontSize,
  },
  logo: {
    marginLeft: "-5%",
    maxWidth: "50%",
  },
});

function PassForm(props) {
  const { classes } = props;
  const cryptr = new Cryptr(CLIENT_SIDE_ENCRYPTION_KEY);

  const apiKeys = getAPIKeys();
  const CLIENT_ID = apiKeys["API_AUTH_CLIENT_ID"];
  const CLIENT_SECRET = apiKeys["API_AUTH_SECRET"];

  const usernameRef = useRef();
  const passwordRef = useRef();
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoginError(false);

    let isError = false;
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    if (username.trim().length === 0) {
      setUsernameError(true);
      isError = true;
    }
    if (password.trim().length === 0) {
      setPasswordError(true);
      isError = true;
    }

    if (isError) {
      return;
    }

    setUsernameError(false);
    setPasswordError(false);
    setIsLoginSuccess(true);

    const payload = {
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: username,
      password: password,
    };
    api
      .post("/auth/token", payload)
      .then((res) => {
        let access_token = res.data.access_token;
        let encrypted_refresh_token = cryptr.encrypt(res.data.refresh_token);

        refreshAmzOnboardStatus(
          updateAmzOnboardingStatus,
          access_token,
          props.setErrors
        );

        const newTenantInfo = {
          custom_api_request: "login_user_info",
          user_email: username,
        };

        api.post("/api/authentication/", newTenantInfo).then((res) => {
          let tokens = Object.assign(
            { access_token: access_token },
            { refresh_token: encrypted_refresh_token }
          );
          const channel = res.data.channel?.toLowerCase() ?? "amazon";
          localStorage.setItem("Tokens", JSON.stringify(tokens));
          localStorage.setItem(
            "TokenTime",
            (Date.now() + 60 * 60 * 1000).toString()
          );
          setAuthToken(access_token);
          setCurrentUser(res.data);
          setTokens(tokens);
          setChannel(channel);
          localStorage.setItem("channel", channel);
          userMarketPlace(access_token, () => {
            if (res.data.permissions === "reports") {
              window.location.href = "/user/report/advertisement";
            } else {
              window.location.href = "/user/dashboard";
            }
          });
        });
      })
      .catch((err) => {
        sessionStorage.clear();
        localStorage.clear();
        logoutUser();
        clearCookies();
        props.setErrors(err);
        setIsLoginError(true);
        setIsLoginSuccess(false);
      });
  };

  return (
    <Grid container className={classes.basePanel} direction="row" wrap="nowrap">
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={10} md={8}>
        <PerfectScrollbar className={classes.scrollColumn}>
          <form onSubmit={handleLogin} autoComplete="on">
            <Box className={classes.formColumn}>
              <Box className={classes.welcomeText}>Welcome to Trellis</Box>
              <Box>
                <TextField
                  inputRef={usernameRef}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                    autoComplete: "email",
                    id: "email",
                  }}
                  InputLabelProps={{
                    className: classes.inputlabel,
                    htmlFor: "email",
                  }}
                  label="Email"
                  name="email"
                  error={usernameError}
                />
              </Box>
              <Box mt={2} width={1}>
                <TextField
                  inputRef={passwordRef}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                    autoComplete: "password",
                    id: "password",
                  }}
                  InputLabelProps={{
                    className: classes.inputlabel,
                    htmlFor: "password",
                  }}
                  label={"Password"}
                  type="password"
                  name="password"
                  error={passwordError}
                />
              </Box>
              {isLoginError && (
                <Box mt={4} width={1}>
                  <span className={`${classes.signupLink} fs-standard`}>
                    The username or password did not match an existing user. Try
                    to login again.
                  </span>
                </Box>
              )}

              <Box mt={5} width={1}>
                <Grid container justifyContent="center">
                  <Button
                    className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge ${classes.button}`}
                    type="submit"
                    disabled={isLoginSuccess}
                  >
                    Login
                  </Button>
                </Grid>
              </Box>
              <Box mt={5} width={1} className="fs-standard">
                <Grid container justifyContent="center">
                  <span style={{ paddingRight: "10px" }}>
                    Not a member yet?
                  </span>{" "}
                  <a className={classes.signupLink} href="/signup">
                    Signup
                  </a>
                </Grid>
              </Box>
              <Box mt={2} width={1} className="fs-standard">
                <Grid container justifyContent="center">
                  <a className={classes.signupLink} href="signin/resetpassword">
                    Forgot your password?
                  </a>
                </Grid>
              </Box>
            </Box>
          </form>
        </PerfectScrollbar>
      </Grid>
      <Grid item xs={1} md={2}></Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  setCurrentUser,
  updateAmzOnboardingStatus,
  setMarketPlace,
  setTokens,
  logoutUser,
  setErrors,
  clearErrors,
  setChannel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PassForm)));
