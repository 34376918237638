import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  FormLabel as ControlLabel,
  Table,
} from "react-bootstrap";

import Card from "components/core/basic/Card";
import Button from "components/core/basic/Button";
import Carousel from "components/core/basic/Carousel";
import getURLPrefix from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

class InviteUser extends React.Component {
  constructor() {
    super();
    this.state = {
      invite_email: "",
      inviteList: [],
      type_email: "",
      type_emailError: null,
      errors: [],
      chosenTab: "amazon",
      roles: [],
      permissions: {
        role: "Admin",
      },
    };
  }

  componentDidMount() {
    if (!this.props.user.organization_admin) {
      this.props.history.push("/");
    }
    this.refreshInviteList();
  }

  componentDidUpdate() {
    if (!this.props.user.organization_admin) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  refreshInviteList = () => {
    const AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/invite/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        this.setState({
          inviteList: res.data.invites,
          roles: res.data.roles,
        });
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmitAmazon = (e) => {
    this.onSubmit(e, "social_amazon");
  };

  handleSubmitUsernamePassword = (e) => {
    this.onSubmit(e, "username_password");
  };

  handlePermissionsChange = (e) => {};

  onSubmit = (e, type) => {
    e.preventDefault();
    if (this.state.type_emailError != null) {
      return;
    }
    if (this.state.type_email.trim().length === 0) {
      this.setState({
        type_emailError: (
          <small className="text-danger">
            Email is required and format should be <i>example@trellis.io</i>.
          </small>
        ),
      });
      return;
    }
    const AuthHeader = "Bearer ".concat(this.props.auth.tokens.access_token);
    if (type === "social_amazon") {
      type = "social_amazon";
    } else {
      type = "username_password";
    }

    let permissions = {};
    if (this.state.permissions.role === "Admin") {
      permissions = { reports: true, dashboard: true };
    } else {
      permissions = { reports: true, dashboard: false };
    }

    var inviteUserData = {
      custom_api_request: "invite_user",
      invite_email: this.state.type_email,
      login_type: type,
      user_permissions: permissions,
    };
    axios
      .post(URL_PREFIX + "/api/invite/", inviteUserData, {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((res) => {
        if (res.data === "User already Invited") {
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "User already invited."
          );
        } else if (res.data === "User already Available") {
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "User already available."
          );
        } else if (res.data === "User already Referred") {
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "User already referred."
          );
        } else {
          this.props.handleNotification(
            "tr",
            "success",
            "pe-7s-check",
            "Invitation sent!"
          );
          this.setState({ type_email: "" });
          this.refreshInviteList();
        }
      });
  };

  handleInviteDelete = (inviteItem) => {
    var delete_invite = {
      custom_api_request: "delete_invite",
      invite_email: inviteItem.invited_email,
    };
    axios
      .post(URL_PREFIX + "/api/invite/", delete_invite)
      .then((res) => {
        this.refreshInviteList();
        this.props.handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Invitation deleted!"
        );
      })
      .catch((err) => {
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to delete invitation, please retry."
        );
      });
  };

  handleResendInvite = (inviteItem) => {
    var resend_invite = {
      custom_api_request: "resend_invite",
      invite_email: inviteItem.invited_email,
    };
    axios
      .post(URL_PREFIX + "/api/invite/", resend_invite)
      .then((res) => {
        this.refreshInviteList();
        this.props.handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Invitation re-sent!"
        );
      })
      .catch((err) => {
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Invitation resend failed, please retry."
        );
      });
  };

  render() {
    let carouselData = [
      [
        "Login with Amazon",
        <InviteForm
          typeEmail={this.state.type_email}
          emailError={this.state.type_emailError}
          inviteSubmit={this.handleSubmitAmazon}
          roles={this.state.roles}
          permissionsChange={(event) => {
            this.setState({
              permissions: { role: event.target.value },
            });
          }}
          typePermissions={this.state.permissions}
          title={
            "The email below should match with the email associated with the Amazon account of the invited."
          }
          emailChange={(event) => {
            this.setState({
              type_email: event.target.value,
            });
            var emailRex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            emailRex.test(event.target.value) === false
              ? this.setState({
                  type_emailError: (
                    <small className="text-danger">
                      Email is required and format should be{" "}
                      <i>example@trellis.io</i>.
                    </small>
                  ),
                })
              : this.setState({ type_emailError: null });
          }}
        />,
      ],
      [
        "Username and Password",
        <InviteForm
          typeEmail={this.state.type_email}
          emailError={this.state.type_emailError}
          typePermissions={this.state.permissions}
          roles={this.state.roles}
          permissionsChange={(event) => {
            this.setState({
              permissions: { role: event.target.value },
            });
          }}
          title={"The email below will be used as the invitee's username."}
          inviteSubmit={this.handleSubmitUsernamePassword}
          emailChange={(event) => {
            this.setState({
              type_email: event.target.value,
            });
            var emailRex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            emailRex.test(event.target.value) === false
              ? this.setState({
                  type_emailError: (
                    <small className="text-danger">
                      Email is required and format should be{" "}
                      <i>example@trellis.io</i>.
                    </small>
                  ),
                })
              : this.setState({ type_emailError: null });
          }}
        />,
      ],
      // Layout hack to avoid massive nav tabs
      ["", ""],
    ];

    return (
      <div className="container">
        <div className="row">
          <div className="col s8 offset-s2">
            <div
              className="col s12 text-center"
              style={{ paddingLeft: "11.250px" }}
            >
              <h4 className="fw-bold">
                Invite users to join your organization
              </h4>
            </div>
            <Col xs={12} md={10} className="mx-auto">
              <Carousel width="30%" data={carouselData} />
            </Col>
          </div>
        </div>

        <div className="row">
          <Col xs={12} md={10} className="mx-auto">
            <Card
              tableFullWidth
              width={"100%"}
              content={
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Invited Email</th>
                      <th>Status</th>
                      <th>Role</th>
                      <th>Resend Invite</th>
                      <th className="text-end">Delete Invite</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.inviteList.map((inviteItem, index) => {
                      if (!inviteItem.activated) {
                        return (
                          <tr key={inviteItem.id}>
                            <td className="text-center fs-standard">
                              {index + 1}
                            </td>
                            <td className="text-center fs-standard">
                              {inviteItem.invited_email}
                            </td>
                            {inviteItem.expired ? (
                              <td className="text-center fs-standard">
                                {" "}
                                Expired{" "}
                              </td>
                            ) : (
                              <td className="text-center fs-standard">
                                {" "}
                                Pending{" "}
                              </td>
                            )}
                            <td className="text-center fs-standard">
                              {inviteItem.role === "Admin"
                                ? "Admin"
                                : "Dashboard"}
                            </td>
                            <td className="text-center fs-standard">
                              <button
                                onClick={() =>
                                  this.handleResendInvite(inviteItem)
                                }
                                className="btn waves-effect waves-light hoverable blue accent-4"
                              >
                                <i className="fa fa-paper-plane" />
                              </button>
                            </td>
                            <td className="text-center fs-standard">
                              <button
                                onClick={() =>
                                  this.handleInviteDelete(inviteItem)
                                }
                                className="btn waves-effect waves-light hoverable red accent-4"
                              >
                                <i className="fa fa-user-times" />
                              </button>
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={inviteItem.id}>
                            <td className="text-center fs-standard">
                              {index + 1}
                            </td>
                            <td className="text-center fs-standard">
                              {inviteItem.invited_email}
                            </td>
                            <td className="text-center fs-standard">
                              Accepted
                            </td>
                            <td className="text-center fs-standard">
                              {" "}
                              {inviteItem.role === "Admin"
                                ? "Admin"
                                : "Dashboard"}{" "}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              }
            />
          </Col>
        </div>
      </div>
    );
  }
}

const InviteForm = ({
  emailChange,
  typeEmail,
  emailError,
  inviteSubmit,
  title,
  typePermissions,
  permissionsChange,
  roles,
}) => {
  return (
    <Form horizontal>
      <div style={{ paddingBottom: "3rem" }}>
        <h4 className="title text-center">{title}</h4>
      </div>

      <div>
        <FormGroup
          controlId="formHorizontalEmail"
          className="d-flex my-4 mx-auto col-sm-6"
        >
          <Col
            componentClass={ControlLabel}
            sm={2}
            className="ml-auto d-flex align-items-center fs-4"
          >
            Email
          </Col>
          <Col>
            <FormControl
              type="email"
              name="type_email"
              value={typeEmail}
              onChange={emailChange}
              className="w-100"
            />
            {emailError}
          </Col>
        </FormGroup>
        <div className="text-center fs-standard">
          {" "}
          The invited has 14 days to accept the invitation.{" "}
        </div>
      </div>

      <div className="text-center" style={{ paddingTop: "3rem" }}>
        <Button
          className="text-center"
          fill
          bsStyle="info"
          onClick={inviteSubmit}
        >
          Invite User
        </Button>
      </div>
      {/* <Card
        title={

        }
        content={

        }
        ftTextCenter
        legend={
          <Button
            className="text-center"
            fill
            bsStyle="info"
            onClick={inviteSubmit}
          >
            Invite User
          </Button>
        }
      /> */}
    </Form>
  );
};

InviteUser.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(InviteUser));
