import React, { Component } from "react";

class CustomHref extends Component {
  render() {
    var type = this.props.type === undefined ? "redirect" : this.props.type;
    var color = this.props.color === undefined ? "#d91266" : this.props.color;
    var fontWeight =
      this.props.fontWeight === undefined ? "bold" : this.props.fontWeight;
    var textDecoration =
      this.props.textDecoration === undefined
        ? "underline"
        : this.props.textDecoration;
    return (
      <>
        {this.props.link === undefined ? (
          <span
            style={{
              color: color,
              textDecoration: textDecoration,
              fontWeight: fontWeight,
              cursor: "pointer",
            }}
          >
            {this.props.text}
          </span>
        ) : (
          <>
            {type === "redirect" ? (
              <a
                style={{
                  color: color,
                  textDecoration: textDecoration,
                  fontWeight: fontWeight,
                  cursor: "pointer",
                }}
                href={this.props.link}
              >
                {this.props.text}
              </a>
            ) : type === "target" ? (
              <a
                style={{
                  color: color,
                  textDecoration: textDecoration,
                  fontWeight: fontWeight,
                }}
                href={this.props.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.text}
              </a>
            ) : null}
          </>
        )}
      </>
    );
  }
}

export default CustomHref;
