import Loading from "components/core/blocks/Loading";
import { useFetch } from "hooks/api";
import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import api from "utils/api";
import { useQueryClient } from "react-query";
import { AuthButton } from "components/core/basic/Button";
import AddCompetitor from "../AddCompetitor";
import Switch from "react-bootstrap-switch";
import { formatCurrency } from "utils/formatNumber";
import { PRODUCT_AMAZON_LINKS } from "utils/marketplaceConstants";
import ListingDetails from "modules/keyword_explorer/ListingDetails";
import { cut } from "utils/formatText";
import { levenshteinDistance } from "utils/math";

const CompetitorsSection = ({
  isPricingProductDataLoading,
  productId,
  marketPlace,
  product,
}) => {
  const asin = product?.asin;
  const brandName = product?.amazon_brand;
  const title = product?.product_title;
  const productPrice = product?.product_price;

  const [thisItem, setThisItem] = useState(null);
  const [showContentDetails, setShowContentDetails] = useState(false);
  const [topRank, setTopRank] = useState([]);
  const [competitorList, setCompetitorList] = useState(null);
  const [viewPotential, setViewPotential] = useState(false);
  const [competitorAction, setCompetitorAction] = useState(false);
  const [showAddCompetitor, setShowAddCompetitor] = useState(false);

  console.log(brandName);

  const key = ["ProductCompetitors", productId];
  const queryClient = useQueryClient();

  const { data: competitors } = useFetch(
    key,
    "/api/competitors",
    {
      marketplace: marketPlace.marketPlace,
      product: productId,
    },
    { enabled: !!productId }
  );

  const { data: allCompetitors, isLoading: isAllCompetitorsLoading } = useFetch(
    ["AllCompetitors", productId],
    "/merchandise/organic_keywords/",
    {
      marketplace: marketPlace.marketPlace,
      asin: asin,
    },
    { enabled: !!productId }
  );

  useEffect(() => {
    if (isAllCompetitorsLoading || !allCompetitors) {
      return;
    }

    let competitors = {};

    for (let i = 0; i < allCompetitors?.data?.results?.length; i++) {
      const r = allCompetitors?.data?.results[i];
      const comps = r?.meta?.competitors;
      if (comps) {
        for (let j = 0; j < comps.length; j++) {
          const c = comps[j];

          if (c.brand === brandName) {
            continue;
          }
          const price = c.price;
          if (!price) {
            continue;
          }
          if (competitors[c.asin] === undefined) {
            const distance =
              levenshteinDistance(title, c.title) +
              Math.abs(price - productPrice) * 2;
            competitors[c.asin] = {
              asin: c.asin,
              brand: c.brand,
              title: c.title,
              price: price,
              distance: distance,
              sales_rank: {},
            };
          }
          competitors[c.asin].sales_rank[r.title] = c?.bsr?.class_rank;
        }
      }
    }

    let competitorList = Object.values(competitors);
    competitorList = competitorList.sort((a, b) => {
      return a.distance - b.distance;
    });

    setTopRank(topRank);
    setCompetitorList(competitorList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCompetitors]);

  const createCompetitor = async (productId, url) => {
    setCompetitorAction(true);
    const res = await api.post("/api/competitors/", {
      product: productId,
      marketplace: marketPlace.marketPlace,
      url,
    });
    if (res.status === 201) {
      queryClient.invalidateQueries(key);
      setViewPotential(false);
      setCompetitorAction(false);
      setShowAddCompetitor(false);
    }
  };

  const deleteCompetitor = async (id) => {
    setCompetitorAction(true);

    const res = await api.remove(`/api/competitors/${id}/`, {});

    if (res.status === 204) {
      queryClient.invalidateQueries(key);
      setCompetitorAction(false);
    }
  };

  if (
    (competitorAction || viewPotential) &&
    competitorList === null &&
    isAllCompetitorsLoading
  ) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  const CompetitorButtons = () => {
    return (
      <>
        <Row className="pb-2">
          <Col xs={6} className="text-start">
            <Switch
              onText={``}
              offText={``}
              value={viewPotential}
              onColor="#FFFFFF"
              onChange={(e) => {
                setViewPotential(!viewPotential);
              }}
            />

            <span className="pl-3">
              {!viewPotential ? "View" : "Hide"} Potential Competitors
            </span>
          </Col>
          <Col xs={6} className="text-end">
            <AuthButton
              onClick={() => {
                setShowAddCompetitor(true);
              }}
              title={`Add Competitor`}
              buttonStyle={`logout_button`}
            />
          </Col>
        </Row>
      </>
    );
  };

  const getBestSellerRank = (c) => {
    if (c?.history?.length === 0) {
      return "n/a";
    }

    let rank = 0;
    for (let i = 0; i < c.history.length; i++) {
      const h = c.history[i];
      if (h.bestseller_rank && h.bestseller_rank.length > 0) {
        for (let j = 0; j < h.bestseller_rank.length; j++) {
          const b = h.bestseller_rank[j];
          rank = b.rank;
        }
      }
    }

    if (rank === 0) {
      return "n/a";
    }

    return rank;
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <CompetitorButtons />

          {!viewPotential &&
            competitors &&
            competitors.data.results.length === 0 && (
              <>
                <Row className="py-5">
                  <Col xs={12}>
                    <div className="text-center">
                      <p>You have not yet added a competitor</p>
                    </div>
                  </Col>
                </Row>
              </>
            )}

          {!viewPotential &&
            competitors &&
            competitors.data.results.length > 0 && (
              <Row className="pb-3">
                <Col xs={12}>
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "350px", overflowY: "scroll" }}
                  >
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th className="text-start">ASIN</th>
                          <th className="text-start">Brand</th>
                          <th className="text-start">Title</th>
                          <th className="text-end">Price</th>
                          <th className="text-center">BSR</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {competitors.data.results.map((c, index) => (
                          <tr key={index}>
                            <td>
                              <img
                                src={c.image_url}
                                alt={c.title}
                                style={{ width: "50px" }}
                              />
                            </td>
                            <td className="text-start">{c.product_ref}</td>
                            <td className="text-start">
                              {c?.history?.length > 0 && (
                                <span>
                                  {c.history[c.history.length - 1].brand}
                                </span>
                              )}
                            </td>
                            <td className="text-start">{cut(c.title, 75)}</td>
                            <td className="text-end">
                              {c?.history?.length > 0 && (
                                <span>
                                  {formatCurrency(
                                    c.history[c.history.length - 1].price,
                                    marketPlace.marketPlace
                                  )}
                                </span>
                              )}
                            </td>
                            <td className="text-center">
                              {c?.history?.length > 0 && (
                                <span>{getBestSellerRank(c)}</span>
                              )}
                            </td>
                            <td className="text-end">
                              <select
                                onChange={(e) => {
                                  if (e.target.value === "select") {
                                    return;
                                  } else if (e.target.value === "details") {
                                    const item = {
                                      asin: c.product_ref,
                                      price:
                                        c.history[c.history.length - 1].price,
                                    };
                                    setThisItem(item);
                                    setShowContentDetails(true);
                                  } else if (e.target.value === "compare") {
                                    return;
                                  } else if (e.target.value === "remove") {
                                    deleteCompetitor(c.id);
                                  }
                                }}
                                style={{
                                  maxWidth: "100px",
                                  padding: "3px",
                                  border: `"1px solid #ccc"`,
                                  borderRadius: "5px",
                                }}
                              >
                                <option value="select">Select</option>
                                <option value="details">View Details</option>
                                <option value="remove">
                                  Remove as Competitor
                                </option>
                              </select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}

          {viewPotential &&
            competitorList !== null &&
            competitorList.length === 0 && (
              <>
                <Row>
                  <Col xs={12}>
                    <div className="text-center py-5">
                      <p>
                        We could not find any potential competitors that matches
                        this product
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          {viewPotential &&
            competitorList !== null &&
            competitorList.length > 0 && (
              <Row>
                <Col xs={12}>
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "350px", overflowY: "scroll" }}
                  >
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th className="text-start">Brand</th>
                          <th className="text-start">Title</th>
                          <th className="text-end">Price</th>
                          <th className="text-center">{topRank}</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {competitorList.map((c) => (
                          <tr key={c.asin}>
                            <td className="text-start">
                              <a
                                href={`${
                                  PRODUCT_AMAZON_LINKS[marketPlace.marketPlace]
                                }${c.asin}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {c.asin}
                              </a>
                            </td>
                            <td className="text-start">{c.brand}</td>
                            <td className="text-start">{cut(c.title, 75)}</td>
                            <td className="text-end">
                              {formatCurrency(c.price, marketPlace.marketPlace)}
                            </td>
                            <td className="text-center">{c[topRank]}</td>
                            <th>
                              <select
                                onChange={(e) => {
                                  if (e.target.value === "select") {
                                    return;
                                  } else if (e.target.value === "details") {
                                    setThisItem(c);
                                    setShowContentDetails(true);
                                  } else if (e.target.value === "compare") {
                                    return;
                                  } else if (e.target.value === "add") {
                                    const url = `${
                                      PRODUCT_AMAZON_LINKS[
                                        marketPlace.marketPlace
                                      ]
                                    }${c.asin}`;
                                    createCompetitor(productId, url);
                                  }
                                }}
                                style={{
                                  maxWidth: "100px",
                                  padding: "3px",
                                  border: `"1px solid #ccc"`,
                                  borderRadius: "5px",
                                }}
                              >
                                <option value="select">Select</option>
                                <option
                                  value="details"
                                  disabled={
                                    c.price === undefined || c.price === "N/A"
                                  }
                                >
                                  View Details
                                </option>
                                <option value="add">Add as Competitor</option>
                              </select>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
        </Col>
      </Row>

      <AddCompetitor
        productId={productId}
        createCompetitor={createCompetitor}
        setShowAddCompetitor={setShowAddCompetitor}
        showAddCompetitor={showAddCompetitor}
      />

      <ListingDetails
        productID={null}
        item={thisItem}
        marketPlace={marketPlace.marketPlace}
        setShowContentDetails={setShowContentDetails}
        showContentDetails={showContentDetails}
      />
    </>
  );
};

export default CompetitorsSection;
