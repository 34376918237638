const PROMOTION_TYPES = ["Best Deal", "Lightning Deal", "Price Discount"];

const PROMOTION_STATUS = ["Approved", "In Progress", "Completed", "Canceled"];

export const PROMOTION_STATUS_FILTER = {
  label: "Status",
  id: "status",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Statuses",
    },
    ...PROMOTION_STATUS.map((status) => ({ label: status, value: status })),
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (promo, filterValue) => {
    if (filterValue !== "all") {
      return promo.status === filterValue;
    }
    return true;
  },
};

export const PROMOTION_TYPE_FILTER = {
  label: "Type",
  id: "promotionType",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Types",
    },
    ...PROMOTION_TYPES.map((type) => ({ label: type, value: type })),
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (promo, filterValue) => {
    if (filterValue !== "all") {
      return promo.promotionType === filterValue;
    }
    return true;
  },
};
