import React from "react";
import ReactTooltip from "react-tooltip";
import { withStyles } from "@material-ui/core";

export const TOTAL_ACOS_TOOLTIP =
  "The ratio of advertisement costs versus total advertisement and organic sales";
export const TOTAL_SALES_TOOLTIP =
  "The total of advertisement and organic sales";
export const AD_SALES_TOOLTIP =
  "The total amount of sales on advertised products that resulted from a click on a sponsored ad.";
export const AD_SPEND_TOOLTIP = "The total amount spent on PPC advertising.";
export const ACOS_TOOLTIP =
  "Total cost of PPC advertising as a percentage of total sales. It is a measure of advertising effectiveness.";
export const SOS_TOOLTIP =
  "Average of your brands' page one share of shelf across all shelves.";
export const SOS_ORGANIC_TOOLTIP =
  "Average of your brands' page one organic share of shelf across all shelves.";
export const SOV_TOOLTIP =
  "Average of your brands' page one share of voice across all shelves.";
export const ATF_SOS_TOOLTIP =
  "Average of your brands' above the fold share of shelf across all shelves.";
export const ATF_SOS_ORGANIC_TOOLTIP =
  "Average of your brands' above the fold organic share of shelf across all shelves.";
export const ATF_SOV_TOOLTIP =
  "Average of your brands' above the fold share of voice across all shelves.";
export const AVERAGE_POSITION_TOOLTIP =
  "Average of your brands' products' search result position across all shelves.";
export const TARGET_ACOS_TOOLTIP =
  "ACoS is a metric that measures how much you spend on an advertisement for every dollar of revenue you make. A target ACoS is the percentage where you hit your target profit margin, and your ad is bringing in additional revenue.";
export const TARGET_ROAS_TOOLTIP =
  "Short term ad spend as a rating of sales to enable successful campaign launch.";
export const MAX_ACOS_TOOLTIP =
  "ACoS is a metric that measures how much you spend on an advertisement for every dollar of revenue your make. The max ACoS is the maximum amount of money you want to spend on an advertisement per dollar of revenue made.";
export const MAX_ROAS_TOOLTIP =
  "Short term ad spend as a rating of sales to enable successful campaign launch.";
export const DAILY_BUDGET_TOOLTIP =
  "The daily maximum amount you want to spend on advertising for this plan.";
export const PAUSE_ADV_TOOLTIP =
  "Enable or Pause advertising of the current product.";
export const LANDING_PAGE_TOOLTIP =
  "This landing page will elevate your sponsored brand advertisement by showcasing additional products and information.";
export const VIDEO_AD_TYPE_TOOLTIP =
  "The type of video advertisement your creative asset will support";
export const AD_THEME_TAGS_TOOLTIP =
  "If you want your targeting plan to include specific keywords, add them here. You can add them anytime.";
export const WALMART_AD_THEME_TAGS_TOOLTIP =
  "If you want your targeting plan to include specific keywords and item IDs, add them here. You can add them anytime.";
export const AD_NEGATIVE_KEYWORD_TAGS_TOOLTIP =
  "If you want your targeting plan to negate specific keywords, add them here. You can add them anytime.";
export const AD_THEME_ASINS_TOOLTIP = "Add themed ASINs to your media plan";
export const AD_NEGATIVE_ASINS_TOOLTIP =
  "If you want your targeting plan to negate specific ASINs, add them here. You can add them anytime.";
export const CV_TOOLTIP = "The average amount a customer spends in 12 months";
export const PRODUCT_CV_TOOLTIP =
  "The average amount a customer spends in 12 months on this product";
export const BRAND_CV_TOOLTIP =
  "The average amount a customer spends in 12 months on this brand";
export const CAC_TOOLTIP = "The average cost to acquire a new customer";
export const ROAS_TOOLTIP =
  "Return on advertisement spending. It is a measure of advertising effectiveness.";
export const TOTAL_ROAS_TOOLTIP =
  "Overall total return on advertisement spending. It is a measure of advertising effectiveness.";
export const TOTAL_NTB_CUSTOMERS_TOOLTIP =
  "The total number of new to brand customers your organization serves";
export const TOTAL_REPEAT_CUSTOMERS_TOOLTIP =
  "The total number of repeat customers your organization serves";
export const FREQUENCY_TOOLTIP =
  "The average amount of time between repeat puchases";
export const REPEAT_TOOLTIP =
  "The total number of repeat customers who purchased this product more than once";
export const REPEAT_BRAND_CUSTOMERS_TOOLTIP =
  "The total number of repeat customers who purchased more than one brand products";
export const REPEAT_BRAND_SALES_TOOLTIP =
  "The total amount of repeat brand sales";
export const REPEAT_SALES_TOOLTIP = "The total amount of repeat sales";
export const HALO_RANK_TOOLTIP =
  "A customer's favoritism toward a line of products based on past positive experiences";
export const AD_PRODUCT_DESCRIBES_TOOLTIP =
  "Tell us the keywords that describe your product best";
export const AD_BRAND_TOOLTIP =
  "Tell us the brands that you are promoting in your media plan";
export const AD_COMPETITOR_BRAND_TOOLTIP =
  "Tell us the brands you are competing against in your media plan";
export const AD_ATTRIBUTES_TOOLTIP =
  "Give us a list of the attributes that describe your products";

const useStyles = () => ({
  tooltip: {
    maxWidth: "500px",
    lineHeight: "1.8rem",
  },
});

class ToolTips extends React.Component {
  render() {
    const { classes, icon, color } = this.props;
    return (
      <>
        <a
          style={{ color: color || "#7f7f7f" }}
          data-for={this.props.id}
          data-tip={this.props.toolTip}
          className={this.props.iconContainerClasses}
        >
          {" "}
          <i className={icon || "fa fa-info-circle"} />{" "}
        </a>
        <div
          style={{
            fontSize: "20px",
            textTransform: "none",
            letterSpacing: "1.0px",
            fontWeight: 500,
            textAlign: "left",
          }}
        >
          <ReactTooltip
            id={this.props.id}
            place={this.props.position}
            type="info"
            effect="solid"
            textColor="#fff"
            backgroundColor="#2E0054"
            className={classes.tooltip}
          >
            {this.props.children}
          </ReactTooltip>
        </div>
      </>
    );
  }
}

export default withStyles(useStyles)(ToolTips);
