import React, { useMemo, useState } from "react";

import DatesProvider from "dates/DatesProvider";
import { connect } from "react-redux";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import { useParams, useLocation } from "react-router-dom";
import Loading from "components/core/blocks/Loading";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import { Container, Row, Col, Nav, Card } from "react-bootstrap";
import { buildMetric } from "views/merchandise/metrics";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import NoImage from "assets/images/utility/no_image.png";
import { getURLPrefix } from "utils/getUrlPrefix";
import { PricingRulesNav } from "./utils";
import { DemandChartsSection } from "./cards/DemandTrend";
import ConfigurationSection from "./cards/Configuration";
import RelationshipSection from "./cards/Relationships";
import SliderReductionSection from "./cards/SliderReduction";
import ProductSection from "./cards/Products";
import PriceHistorySection from "./cards/PriceHistory";
import CancelRateChart from "./charts/CancelRateChart";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import useCategories from "hooks/useCategories";
import usePricingPlans from "hooks/usePricingPlans";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import useQueryParams from "hooks/useQueryParams";
import InlinePrograms from "components/custom/programs/InlinePrograms";
import ProgramLog from "components/custom/programs/ProgramLog";
import PlanHeader from "./components/PlanHeader";
import ASINTable from "components/custom/oranicSearch/asinTable";
import ProfitChart from "./charts/ProfitChart";
import ConfirmationSection from "./cards/Confirmation";
import AdvertisingSection from "./cards/Advertising";

let URL_PREFIX = getURLPrefix();

// TODO factor this out into a utils file
const DASHBOARD_TYPES = {
  CATEGORY: "category",
  PLAN: "plan",
  ROOT: "root",
};

const getDashboardTo = ({ resourceId, type }) => {
  let to;

  switch (type) {
    case DASHBOARD_TYPES.CATEGORY:
      to = `/user/_pricing/category/${resourceId}`;
      break;
    case DASHBOARD_TYPES.PLAN:
      to = `/user/_pricing/plan/${resourceId}`;
      break;
    default:
      to = `/user/_pricing/dashboard`;
  }

  return to;
};

const PriceSection = ({
  pricingPlan,
  marketPlace,
  isPlanLoading,
  isLoading,
  data,
  start,
  end,
  group,
}) => {
  const [tab, setTab] = useState("price_history");

  return (
    <Row>
      <Col xs={2} style={{ borderRight: "1px solid #ccc" }}>
        <div className="pt-2"></div>
        <Nav
          variant="underline"
          onSelect={(k) => {
            return setTab(k);
          }}
          activeKey={tab}
          className="flex-column text-start"
        >
          <Nav.Link eventKey="price_history" className="text-start">
            Price History
          </Nav.Link>
          <Nav.Link eventKey="ad_affect" className="text-start">
            Advertising Effectiveness
          </Nav.Link>
          <Nav.Link eventKey="price-changes" className="text-start">
            Price Changes
          </Nav.Link>
          <Nav.Link eventKey="cancel_rate" className="text-start">
            Cancel Rate
          </Nav.Link>
        </Nav>
      </Col>
      <Col xs={10}>
        {tab === "price_history" && (
          <>
            <Card>
              <DemandChartsSection
                pricingProduct={pricingPlan}
                marketPlace={marketPlace}
                product={null}
                isPricingProductDataLoading={isPlanLoading}
                data={data}
              />
            </Card>
            <hr />
            <PriceHistorySection
              pricingProduct={pricingPlan}
              marketPlace={marketPlace}
              isPricingProductDataLoading={isPlanLoading}
              isLoading={false}
            />
          </>
        )}
        {tab === "ad_affect" && (
          <PriceHistorySection
            pricingProduct={pricingPlan}
            marketPlace={marketPlace}
            isPricingProductDataLoading={isPlanLoading}
            isLoading={false}
            advertising={true}
          />
        )}

        {tab === "cancel_rate" && (
          <CancelRateChart
            pricingPlan={pricingPlan}
            marketPlace={marketPlace}
            start={start}
            end={end}
            group={group}
          />
        )}

        {tab === "price-changes" && (
          <ConfirmationSection
            pricingPlan={pricingPlan}
            marketPlace={marketPlace}
          />
        )}
      </Col>
    </Row>
  );
};

const PlanCard = ({
  planId,
  marketPlace,
  user,
  additionalTabs,
  initialTab,
  data,
  isLoading,
  pricingPlan,
  isPlanLoading,
  group,
  modules,
  start,
  end,
}) => {
  const [tab, setTab] = useQueryParams("tab", initialTab ?? "products");
  const [hasError, setHasError] = useState(false);

  const overviewMetrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "sales", "sales_percent"),
      buildMetric(marketPlace, data, "units", "units_percent"),
      // buildMetric(marketPlace, data, "total_order_count", null),
      buildMetric(marketPlace, data, "bsr", "bsr_percent", {
        metric: {
          totalOverrideAccessor: "last_bsr_rank",
        },
      }),
      buildMetric(marketPlace, data, "page_views", "page_views_percent"),
      buildMetric(marketPlace, data, "price", "price_delta"),

      buildMetric(marketPlace, data, "margin", "margin_delta"),
      buildMetric(
        marketPlace,
        data,
        "margin_wo_adspend",
        "margin_wo_adspend_delta"
      ),

      buildMetric(marketPlace, data, "cost", "cost_percent"),
      buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
      buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
      buildMetric(marketPlace, data, "acos", "acos_delta"),
      buildMetric(marketPlace, data, "roas", "roas_delta"),
      buildMetric(marketPlace, data, "total_acos", "total_acos_delta"),
      buildMetric(marketPlace, data, "troas", "troas_delta"),
      buildMetric(marketPlace, data, "roms", "roms_delta"),

      buildMetric(marketPlace, data, "rating", "rating_percent"),

      buildMetric(marketPlace, data, "boosted_profit", null),
      buildMetric(marketPlace, data, "profit", "profit_percent"),
      buildMetric(
        marketPlace,
        data,
        "profit_wo_adspend",
        "profit_wo_adspend_percent"
      ),

      buildMetric(
        marketPlace,
        data,
        "profit_per_page_view",
        "profit_per_page_view_percent"
      ),
      buildMetric(
        marketPlace,
        data,
        "profit_per_page_view_wo_adspend",
        "profit_per_page_view_wo_adspend_percent"
      ),
    ],
    [marketPlace, data]
  );

  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?pricingplan=${planId}`;
  const planName = data?.plan_id?.[0]?.plan_name;

  const SummaryHeader = (
    <div style={{ display: "flex" }}>
      <div style={{ width: "120px", paddingRight: "20px" }}>
        <div
          onError={(e) => {
            setHasError(true);
          }}
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            flexShrink: 0,
            width: 50,
            height: 50,
            marginRight: "10px",
            background: `url(${image}) no-repeat center / contain`,
          }}
          draggable="false"
        />
      </div>
      <div>
        <Row>
          <Col xs={12}>
            <h3
              style={{
                marginBottom: "0.2rem",
                marginTop: "0",
                fontSize: 22,
              }}
            >
              {planName}
            </h3>
          </Col>
          <Col xs={12} className="text-center">
            {pricingPlan && (
              <PlanHeader
                pricingPlan={pricingPlan}
                user={user}
                marketPlace={marketPlace}
              />
            )}
          </Col>
          <Col xs={12}></Col>
        </Row>
      </div>
    </div>
  );

  return (
    <>
      {isLoading || isPlanLoading ? (
        <Loading height={"300px"} fullPage={false} />
      ) : (
        <Row>
          <Col xs={12} className="mb-4 fs-standard">
            <MetricsCard
              initialSelectedMetric={["sales", "units"]}
              titleElement={SummaryHeader}
              metrics={overviewMetrics}
              data={data?.date}
              loading={isLoading}
              group={group}
              showMetricsSelector
              id={"productDashboard"}
            >
              <Row>
                <Col xs={12} className="mt-5 ph-4 mb-3 border-bottom">
                  <Nav
                    variant="underline"
                    onSelect={(k) => {
                      return setTab(k);
                    }}
                    activeKey={tab}
                  >
                    {additionalTabs?.map((additionalTab) => (
                      <Nav.Item>
                        <Nav.Link eventKey={additionalTab.title?.toLowerCase()}>
                          {additionalTab.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}

                    <Nav.Item>
                      <Nav.Link eventKey="products">Products</Nav.Link>
                    </Nav.Item>
                    {pricingPlan &&
                      pricingPlan.plan_type !== "same_strategy" && (
                        <>
                          <Nav.Item>
                            <Nav.Link eventKey="price_history">Prices</Nav.Link>
                          </Nav.Item>
                          {/* {checkModule(modules, "PRICE_DEBUG") && (
                            <Nav.Item>
                              <Nav.Link eventKey="debug_data">
                                Debug Data
                              </Nav.Link>
                            </Nav.Item>
                          )} */}
                        </>
                      )}
                    <Nav.Item>
                      <Nav.Link eventKey="configuration">
                        Configuration
                      </Nav.Link>
                    </Nav.Item>
                    {checkModule(modules, "PRICE_ADS") && (
                      <Nav.Item>
                        <Nav.Link eventKey="advertising">
                          Advertisements
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {pricingPlan &&
                      pricingPlan.plan_type !== "same_strategy" && (
                        <Nav.Item>
                          <Nav.Link eventKey="relationships">
                            Relationships
                          </Nav.Link>
                        </Nav.Item>
                      )}

                    {pricingPlan.price_profiling && (
                      <Nav.Item>
                        <Nav.Link eventKey="profiling">Profiling</Nav.Link>
                      </Nav.Item>
                    )}

                    <Nav.Item>
                      <Nav.Link eventKey="organic-search">
                        Organic Search (Beta)
                      </Nav.Link>
                    </Nav.Item>

                    <PricingRulesNav
                      pricingPlan={pricingPlan}
                      hasModule={checkModule(modules, "PRICE_DEBUG")}
                    />
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {additionalTabs?.map((additionalTab) => (
                    <>
                      {tab === additionalTab.title?.toLowerCase() &&
                        additionalTab.content}
                    </>
                  ))}
                  {tab === "products" && (
                    <ProductSection
                      marketPlace={marketPlace}
                      user={user}
                      pricingPlan={pricingPlan}
                    />
                  )}
                  {tab === "relationships" && (
                    <RelationshipSection
                      pricingPlan={pricingPlan}
                      marketPlace={marketPlace}
                      hasVariation={checkModule(modules, "PRICE_VARIATION")}
                    />
                  )}

                  {tab === "configuration" && (
                    <ConfigurationSection
                      pricingPlan={pricingPlan}
                      marketPlace={marketPlace}
                      hasVariation={checkModule(modules, "PRICE_VARIATION")}
                      modules={modules}
                    />
                  )}
                  {tab === "advertising" && (
                    <AdvertisingSection
                      pricingPlan={pricingPlan}
                      marketPlace={marketPlace}
                      start={start}
                      end={end}
                      group={group}
                      pricingPlanReport={data}
                    />
                  )}
                  {tab === "price_history" && (
                    <>
                      <PriceSection
                        pricingPlan={pricingPlan}
                        marketPlace={marketPlace}
                        isPlanLoading={isPlanLoading}
                        isLoading={false}
                        data={data}
                        start={start}
                        end={end}
                        group={group}
                      />
                    </>
                  )}

                  {tab === "rules_engine" && (
                    <>
                      <InlinePrograms pricingPlan={pricingPlan} />

                      <ProgramLog
                        pricingPlanId={pricingPlan.id}
                        marketPlace={marketPlace}
                        title={"Dynamic Pricing"}
                      />
                    </>
                  )}

                  {tab === "debug_data" && (
                    <>
                      <SliderReductionSection pricingPlan={pricingPlan} />
                      <ProfitChart pricingPlan={pricingPlan} />
                    </>
                  )}

                  {tab === "organic-search" && (
                    <ASINTable
                      marketPlace={marketPlace}
                      planId={planId}
                      showAddKWButon={false}
                    />
                  )}
                </Col>
              </Row>
            </MetricsCard>
          </Col>
        </Row>
      )}
    </>
  );
};

const Plan = ({ planId, marketPlace, user, channel, modules }) => {
  const { start, end, preStart, preEnd, group } = useDates();

  // Data fetching

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "plan_id",
    empty: true,
  };

  const { data, isLoading } = useFetch(
    [
      "pricingplan_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      planId,
    ],
    `/api/data_report/pricingplan_report?plan_id=${planId}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data: pricingPlan, isLoading: isPlanLoading } = useFetch(
    ["pricing_plans", planId],
    `/pricing/pricingplans/${planId}/`,
    {},
    {
      select: (d) => d?.data,
      keepPreviousData: true,
      enabled: !!planId,
    }
  );

  const categoryId = pricingPlan?.category;

  let { currentCategoryDetails: categoryDetails, categories: categoryData } =
    useCategories(categoryId, "pricing");

  let { currentPlanDetails, plans: categoryPlans } = usePricingPlans(
    categoryId,
    planId
  );

  // Breadcrumbs
  const breadCrumbItems = useMemo(() => {
    return [
      {
        name: "Pricing Dashboard",
        href: getDashboardTo({
          resourceId: null,
          type: DASHBOARD_TYPES.ROOT,
        }),
        dropdownLinkOptions: DASHBOARD_LINKS,
        unsorted: true,
        searchable: false,
        dropdownSubtitle: "Dashboards",
      },
      {
        name: categoryDetails?.leaf_category_name,
        href: categoryDetails.href,
        dropdownLinkOptions: categoryData,
        searchable: true,
        searchPlaceholderText: "Search categories...",
        dropdownSubtitle: "Categories",
      },
      {
        name: currentPlanDetails?.plan_name,
        href: currentPlanDetails?.href,
        dropdownLinkOptions: categoryPlans,
        searchable: true,
        searchPlaceholderText: "Search pricing plans...",
        dropdownSubtitle: "Pricing Plans",
      },
    ];
  }, [categoryDetails, categoryData, categoryPlans, currentPlanDetails]);

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  return (
    <Container
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-4 px-3">
          <PlanCard
            planId={planId}
            marketPlace={marketPlace}
            user={user}
            channel={channel}
            data={data}
            isLoading={isLoading}
            group={group}
            pricingPlan={pricingPlan}
            isPlanLoading={isPlanLoading}
            modules={modules}
            start={start}
            end={end}
          />
        </Col>
      </Row>
    </Container>
  );
};

// Wrapper which handles URL param date changes and provides a date context
const PricingPlanPage = ({ user, marketPlace, channel, modules }) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { plan: planId = null } = useParams();

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Plan
        planId={planId}
        marketPlace={marketPlace}
        user={user}
        channel={channel}
        modules={modules}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(PricingPlanPage);
