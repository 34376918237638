import React from "react";
import { Row, Col } from "react-bootstrap";

const ListingQuality = ({ contentDetails }) => {
  return (
    <>
      <div className="fs-4 pb-2" style={{ fontWeight: "bold  " }}>
        Listing Quality Details
      </div>

      <Row className="fs-4">
        <Col xs={12} className="pb-2">
          <i
            className={`fa ${
              contentDetails.title_info.title_length === 100
                ? "fa-check-circle text-success"
                : "fa-circle text-danger"
            } me-2`}
          ></i>
          Title between 140 and 200 chars
        </Col>
        <Col xs={12} className="pb-2">
          <i
            className={`fa ${
              contentDetails.title_info.special_chars === 100
                ? "fa-check-circle text-success"
                : "fa-circle text-danger"
            } me-2`}
          ></i>
          No Special Characters
        </Col>
        <Col xs={12} className="pb-2">
          <i
            className={`fa ${
              contentDetails.bullet_info.bullet_count === 100
                ? "fa-check-circle text-success"
                : "fa-circle text-danger"
            } me-2`}
          ></i>
          5 or more bullets
        </Col>
        <Col xs={12} className="pb-2">
          <i
            className={`fa ${
              contentDetails.bullet_info.bullet_length === 100
                ? "fa-check-circle text-success"
                : "fa-circle text-danger"
            } me-2`}
          ></i>
          Bullet between 150 and 250 chars
        </Col>
        <Col xs={12} className="pb-2">
          <i
            className={`fa  ${
              contentDetails.image_info.image_count === 100
                ? "fa-check-circle text-success"
                : "fa-circle text-danger"
            } me-2`}
          ></i>
          6 or more images
        </Col>
      </Row>
    </>
  );
};

export default ListingQuality;
