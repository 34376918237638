import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import store from "redux/store";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { selectModules } from "redux/selectors/modules";

// Assets
import discoveryPanelImage from "assets/images/background/Onboard.png";
import trellislogo from "assets/images/logo/Trellis_Logo_white.png";
import bgImage from "assets/images/background/purple_gradient.png";
import status from "assets/images/status/onboarding_progressbar_2.png";
import chromeStoreLogo from "assets/images/logo/chrome_store.png";

// Components
import CustomHref from "components/core/blocks/CustomHref";
import { Col, Row } from "react-bootstrap";
import { Grid, Hidden, withStyles } from "@material-ui/core";
import SubscriptionCard from "./SubscriptionCard";
import Switch from "react-bootstrap-switch";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";

// Utils
import getURLPrefix from "utils/getUrlPrefix";
import { SUBSCRIPTION_PLANS_UPPERCASE } from "utils/onboardingConstants";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";

import { setSubscriptionTiers } from "redux/actions/userActions";
import { trellisPalette } from "components/custom/analytics/palettes";
import HubspotCalendarModal from "components/core/blocks/HubspotCalendarModal";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { useMediaQuery } from "@react-hook/media-query";

let URL_PREFIX = getURLPrefix();

const subscriptionPlans = SUBSCRIPTION_PLANS_UPPERCASE;

const useStyles = (theme) => ({
  subscriptionCardContainer: {
    backgroundColor: "#ffffff",
    height: "auto",
    paddingLeft: "0px",
    paddingRight: "0px",
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
    },
  },
  exploreHeader: {
    textAlign: "left",
    color: "#393939",
    fontSize: "26px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  chooseText: {
    textAlign: "left",
    color: "#393939",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "2rem",
    marginBottom: "2rem",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  subscriptionCard: {
    borderRadius: "5px",
    marginRight: "15px",
    marginBottom: "15px",
    [theme.breakpoints.down("md")]: {
      marginRight: "5px",
    },
  },
  rightImageContainer: {
    backgroundImage: "url(" + bgImage + ")",
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    marginBottom: "100px",
  },
  bottomArrows: {
    textAlign: "right",
    top: "-100px",
    right: "25px",
  },
  // TODO: Work this into global theme class for resue
  hideScrollbarsMobile: {
    [theme.breakpoints.down("md")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-webkit-scrollbar-track": {
        display: "none",
      },
      "&::-webkit-scrollbar-thumb": {
        display: "none",
      },
    },
  },
});

const BlogCard = ({ title, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <div
      style={{
        width: "80%",
        padding: "1.5rem",
        background: "#FFF",
        margin: "2rem auto",
        borderRadius: ".4rem",
        fontSize: "20px",
        fontWeight: 500,
        textDecoration: "underline",
        textAlign: "center",
      }}
    >
      {title}
    </div>
  </a>
);

const PaymentDiscovery = ({
  auth,
  history,
  user,
  classes,
  discoveryType,
  dashboardType,
  amz_onboard,
  modules,
}) => {
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [subscriptionPlanName, setSubscriptionPlanName] = useState("");
  const [payPlan, setPayPlan] = useState("");
  const [skipButton, setSkipButton] = useState(false);
  const [userLayout, setUserLayout] = useState(false);
  const [monthlyChecked, setMonthlyChecked] = useState(true);
  const [showHubspotModal, setShowHubspotModal] = useState(false);
  const [promoCode, setPromoCode] = useState("");

  const mobileStyle = useMediaQuery("(max-width:1660px)");
  const cardContainerRowLayout = useMediaQuery("(max-width:1660px)");

  const hideBillingPeriodSwitch =
    // TODO: Revisit below logic = subscription items must have same cadence => do we want a dialogue to upgrade both?
    (user.tier?.indexOf("MONTHLY") > -1 &&
      user.pricing_tier === "unknown" &&
      amz_onboard?.payment_discovery !== "pending") ||
    (user.pricing_tier?.indexOf("MONTHLY") > -1 &&
      user.tier === "unknown" &&
      amz_onboard?.payment_discovery !== "pending") ||
    (user.tier?.indexOf("ANNUAL") > -1 && user.pricing_tier === "unknown") ||
    (user.pricing_tier?.indexOf("ANNUAL") > -1 && user.tier === "unknown") ||
    (user.analytics_tier === "unknown" &&
      (user.tier !== "unknown" || user.pricing_tier !== "unknown")) ||
    amz_onboard.org_status === "active";

  useEffect(() => {
    refreshAmzOnboardStatus(
      updateAmzOnboardingStatus,
      auth.tokens.access_token,
      setErrors
    );

    getSubscriptionPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.tokens.access_token]);

  useEffect(() => {
    if (window.location.href.includes("user") && !userLayout) {
      setUserLayout(true);
    }
  }, [userLayout]);

  useEffect(() => {
    if (
      (user.tier?.indexOf("ANNUAL") > -1 && user.pricing_tier === "unknown") ||
      (user.pricing_tier?.indexOf("ANNUAL") > -1 &&
        user.tier === "unknown" &&
        monthlyChecked)
    ) {
      setMonthlyChecked(false);
    }
  }, [user, monthlyChecked]);

  useEffect(() => {
    if (monthlyChecked && subscriptionPlan) {
      setPayPlan(subscriptionPlan?.monthly["plan"]);
    } else {
      setPayPlan(subscriptionPlan?.annual["plan"]);
    }
  }, [monthlyChecked, subscriptionPlan]);

  const getSubscriptionPlan = () => {
    const AuthHeader = "Bearer ".concat(auth.tokens.access_token);

    axios
      .get(URL_PREFIX + "/api/aboutyourcompany", {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((result) => {
        if (result.data.tier !== "") {
          var plan = result.data.tier.split("__");

          setSubscriptionPlan(subscriptionPlans[plan[0]]);
          setSubscriptionPlanName(plan[0]);

          if (plan[1] === "ANNUAL") {
            setPayPlan(subscriptionPlans[plan[0]].annual["plan"]);
            setMonthlyChecked(false);
          } else if (plan[1] === "MONTHLY") {
            setPayPlan(subscriptionPlans[plan[0]].monthly["plan"]);
            setMonthlyChecked(true);
          }
        } else {
          setSubscriptionPlanName("ENTREPRENEUR");
          planChanged();
        }
      })
      .catch((err) => {
        setErrors({
          error: {
            title:
              "Sorry, something went wrong while fetching your subscription plan",
            message: "Please try refreshing/reloading the page.",
          },
        });
      });
  };

  useEffect(() => {
    planChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionPlanName]);

  const planChanged = () => {
    if (subscriptionPlans[subscriptionPlanName]) {
      if (monthlyChecked) {
        setSubscriptionPlan(subscriptionPlans[subscriptionPlanName]);
        setPayPlan(subscriptionPlans[subscriptionPlanName].monthly["plan"]);
      } else {
        setSubscriptionPlan(subscriptionPlans[subscriptionPlanName]);
        setPayPlan(subscriptionPlans[subscriptionPlanName].annual["plan"]);
      }
    }
  };

  const saveSubPlan = () => {
    let AuthStr = "Bearer ".concat(auth.tokens.access_token);

    let requestData;

    if (discoveryType === "advertising") {
      requestData = {
        tier: payPlan ?? "unknown",
        pricing_tier: user.pricing_tier ?? "unknown",
        analytics_tier: user.analytics_tier ?? "unknown",
        content_tier: user.content_tier ?? "unknown",
        pb_tier: user.pb_tier ?? "unknown",
        skipped: skipButton,
        promo_code: promoCode,
      };
    }

    if (discoveryType === "pricing") {
      requestData = {
        tier: user.tier ?? "unknown",
        pricing_tier: payPlan ?? "unknown",
        analytics_tier: user.analytics_tier ?? "unknown",
        content_tier: user.content_tier ?? "unknown",
        pb_tier: user.pb_tier ?? "unknown",
        skipped: skipButton,
        promo_code: promoCode,
      };
    }

    if (discoveryType === "search-analytics") {
      requestData = {
        tier: user.tier ?? "unknown",
        pricing_tier: user.pricing_tier ?? "unknown",
        analytics_tier: payPlan ?? "unknown",
        content_tier: user.content_tier ?? "unknown",
        pb_tier: user.pb_tier ?? "unknown",
        skipped: skipButton,
        promo_code: promoCode,
      };
    }

    if (discoveryType === "content") {
      requestData = {
        tier: user.tier ?? "unknown",
        pricing_tier: user.pricing_tier ?? "unknown",
        analytics_tier: user.analytics_tier ?? "unknown",
        content_tier: payPlan ?? "unknown",
        pb_tier: user.pb_tier ?? "unknown",
        skipped: skipButton,
        promo_code: promoCode,
      };
    }

    if (discoveryType === "purchase-behavior") {
      requestData = {
        tier: user.tier ?? "unknown",
        pricing_tier: user.pricing_tier ?? "unknown",
        analytics_tier: user.analytics_tier ?? "unknown",
        content_tier: user.content_tier ?? "unknown",
        pb_tier: payPlan ?? "unknown",
        skipped: skipButton,
        promo_code: promoCode,
      };
    }

    requestData["application"] = discoveryType;

    if (
      discoveryType === "pricing" ||
      checkTierAccess(payPlan, "pricing", "active")
    ) {
      // Create a new pricing org as soon as pricing tier selected or core tier that includes pricing
      axios.post(URL_PREFIX + "/pricing/createpricingorg", requestData, {
        params: {},
        headers: { Authorization: AuthStr },
      });
    }

    axios
      .put(URL_PREFIX + "/api/tierselection", requestData, {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        if (response.status === 204) {
          refreshAmzOnboardStatus(
            updateAmzOnboardingStatus,
            auth.tokens.access_token,
            setErrors
          );

          store.dispatch(setSubscriptionTiers(requestData));

          if (
            amz_onboard?.org_status === "active" ||
            amz_onboard.org_status === "trial_active"
          ) {
            if (discoveryType === "pricing") {
              history.push({
                pathname: "/user/pricing",
                state: {
                  firstVisit: true,
                },
              });
            } else if (discoveryType === "search-analytics") {
              history.push("/user/searchanalytics");
            } else if (discoveryType === "content") {
              history.push("/user/content/quality/org");
            } else if (discoveryType === "purchase-behavior") {
              history.push("/user/purchasebehavior");
            } else {
              history.push("/user/dashboard");
            }
          } else {
            history.push({
              pathname: "/user/payment",
              state: {
                discoveryType: discoveryType,
                planDetails: SUBSCRIPTION_PLANS_UPPERCASE[subscriptionPlanName],
                monthlyChecked: monthlyChecked,
                promoCode: promoCode,
              },
            });
          }
        } else if (response.status === 200) {
          history.push({
            pathname: "/user/payment",
            state: {
              discoveryType: discoveryType,
              planDetails: SUBSCRIPTION_PLANS_UPPERCASE[subscriptionPlanName],
              monthlyChecked: monthlyChecked,
              promoCode: promoCode,
            },
          });
        }
      });
  };

  const onCardClick = (e, planId) => {
    // Stop event bubbling beyond card
    e.stopPropagation();
    e.preventDefault();

    setSubscriptionPlanName(planId);
    planChanged();
  };

  const ANNUAL_DISCOUNT_PERCENTAGE = 10;

  if (!discoveryType) {
    discoveryType = "advertising";
  }

  return (
    <>
      <Grid
        container
        style={{ marginLeft: "0px", marginRight: "0px" }}
        translate="no"
      >
        <Grid
          item
          xs={12}
          lg={mobileStyle ? 12 : 8}
          className={`${classes.subscriptionCardContainer} ${classes.hideScrollbarsMobile}`}
        >
          <Grid container>
            <Grid item xs={12}>
              <>
                {userLayout ? null : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", marginTop: "2rem" }}
                    >
                      <img
                        src={status}
                        alt="Status"
                        width="640px"
                        height="80px"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container>
                  <Grid item xs={12} md={9} style={{ margin: "auto" }}>
                    <div
                      style={{
                        marginTop:
                          // Below calculated margin to account for difference between main layouts in pricing and advertising dashboards
                          discoveryType === "pricing"
                            ? "calc(3rem + 30px)"
                            : "3rem",
                        marginLeft: "10px",
                      }}
                      className={classes.exploreHeader}
                    >
                      Explore all features of our merchandising platform!
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    container
                    item
                    xs={12}
                    md={9}
                    style={{ margin: "auto" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={7}
                      className={classes.chooseText}
                    ></Grid>
                    <Hidden mdUp>
                      <Grid item xs={1} />
                    </Hidden>
                    <Grid item xs={10} md={5} style={{ marginTop: "2rem" }}>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"text"}
                        handleChildFormElement={(key, value) => {
                          setPromoCode(value);
                        }}
                        placeholder={`Have a promo code? Enter it here.`}
                        defaultValue={promoCode}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: useMediaQuery("(max-width:650px)")
                        ? "column"
                        : "row",
                    }}
                  >
                    {!hideBillingPeriodSwitch && (
                      <>
                        <p
                          className={classes.chooseText}
                          hidden={discoveryType !== "advertising"}
                        >
                          Save 20% on an annual subscription.
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: "10rem",
                              paddingRight: "2rem",
                            }}
                            className="fs-standard"
                          >
                            {monthlyChecked ? "Monthly" : "Annual"}
                          </span>
                          <Switch
                            onText=""
                            offText=""
                            value={!monthlyChecked}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              setMonthlyChecked(!monthlyChecked);
                            }}
                            disabled={hideBillingPeriodSwitch}
                          />
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>

                {/* New Subscription Tier Cards - Main Subscription */}
                {discoveryType === "advertising" ? (
                  <>
                    <Grid container className="text-center">
                      <Grid
                        container
                        item
                        spacing={2}
                        xs={11}
                        md={9}
                        style={{
                          margin: "2rem auto 0 ",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: cardContainerRowLayout
                              ? "row"
                              : "column",
                          }}
                        >
                          <SubscriptionCard
                            planId={"Extension"}
                            planName={"Chrome Extension"}
                            monthlySubscriptionPrice={"Free"}
                            annualSubscriptionPrice={"Free"}
                            subscriptionFeatures={
                              <div className="d-flex gap-5 align-items-center">
                                <div>
                                  <span>
                                    Free Google Chrome Seller Extension
                                  </span>
                                  <br />
                                </div>
                                <div>
                                  <a
                                    href="https://chromewebstore.google.com/detail/trellis-seller-extension/mfnnikclhbphfbkojdfoeccbfpjcbbdl?utm_source=portal&utm_medium=web&utm_campaign=trellis_banner"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      fontWeight: "bold",
                                      color: "white",
                                    }}
                                  >
                                    <img
                                      src={chromeStoreLogo}
                                      alt="Google Chrome Store"
                                      style={{
                                        height: "40px",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </a>
                                </div>
                              </div>
                            }
                            onCardClick={(e, planName) => {}}
                            core={true}
                          />
                          <SubscriptionCard
                            planId={"CORE_CUSTOM"}
                            planName={"Custom"}
                            monthlySubscriptionPrice={"TBD"}
                            annualSubscriptionPrice={"TBD"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={
                              <>
                                <span>Placement Advertising</span>
                                <br />
                                <span>Product Content</span>
                                <br />
                                <span>Dynamic Pricing</span>
                                <br />
                                <span>Promotions & Deals</span>
                              </>
                            }
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            core={true}
                          />
                          {/* )} */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : discoveryType === "purchase-behavior" ? (
                  <>
                    <Grid container className="text-center">
                      <Grid
                        container
                        item
                        spacing={2}
                        xs={11}
                        md={9}
                        style={{
                          margin: "2rem auto 0 ",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: cardContainerRowLayout
                              ? "row"
                              : "column",
                          }}
                        >
                          <SubscriptionCard
                            planId={"STANDARD_PURCHASE_BEHAVIOR"}
                            planName={"Standard"}
                            monthlySubscriptionPrice={"$49.99"}
                            annualSubscriptionPrice={"$44.99"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>All Products</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={``}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : discoveryType === "content" ? (
                  <>
                    <Grid container className="text-center">
                      <Grid
                        container
                        item
                        spacing={2}
                        xs={11}
                        md={9}
                        style={{
                          margin: "2rem auto 0 ",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: cardContainerRowLayout
                              ? "row"
                              : "column",
                          }}
                        >
                          <SubscriptionCard
                            planId={"STANDARD_CONTENT"}
                            planName={"Standard"}
                            monthlySubscriptionPrice={"$49.99"}
                            annualSubscriptionPrice={"$44.99"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>All Products</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={``}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : discoveryType === "search-analytics" ? (
                  <>
                    <Grid container className="text-center">
                      <Grid
                        container
                        item
                        spacing={2}
                        xs={11}
                        md={9}
                        style={{
                          margin: "2rem auto 0 ",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: cardContainerRowLayout
                              ? "row"
                              : "column",
                          }}
                        >
                          <SubscriptionCard
                            planId={"FREE_ANALYTICS"}
                            planName={"Free"}
                            monthlySubscriptionPrice={"FREE"}
                            annualSubscriptionPrice={"FREE"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>2 active shelves</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={"Below $120k"}
                          />
                          <SubscriptionCard
                            planId={"ENTREPRENEUR_ANALYTICS"}
                            planName={"Essentials"}
                            monthlySubscriptionPrice={"$49"}
                            annualSubscriptionPrice={"$44"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>5 active shelves</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={"Up to $120k "}
                          />
                          <SubscriptionCard
                            planId={"PROFESSIONAL_ANALYTICS"}
                            planName={"Premium"}
                            monthlySubscriptionPrice={"$99"}
                            annualSubscriptionPrice={"$89"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>15 active shelves</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={"$360k to $1M"}
                          />
                          <SubscriptionCard
                            planId={"ENTERPRISE_ANALYTICS"}
                            planName={"Enterprise"}
                            monthlySubscriptionPrice={"$199"}
                            annualSubscriptionPrice={"$179"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>40 active shelves</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={"$1M and above"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container className="text-center">
                      <Grid
                        container
                        item
                        spacing={2}
                        xs={11}
                        md={9}
                        style={{
                          margin: "2rem auto 0",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: cardContainerRowLayout
                              ? "row"
                              : "column",
                          }}
                        >
                          <SubscriptionCard
                            planId={"BUSINESS_PRICING"}
                            planName={"Starter"}
                            monthlySubscriptionPrice={"$299"}
                            annualSubscriptionPrice={"$269"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>40 Optimized Prices</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={"$120k to $360k"}
                          />
                          <SubscriptionCard
                            planId={"PROFESSIONAL_PRICING"}
                            planName={"Premium"}
                            monthlySubscriptionPrice={"$499"}
                            annualSubscriptionPrice={"$449"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={<>100 Optimized Prices</>}
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={"$360k to $1M"}
                          />
                          <SubscriptionCard
                            planId={"ENTERPRISE_PRICING"}
                            planName={"Enterprise"}
                            monthlySubscriptionPrice={"$999"}
                            annualSubscriptionPrice={"$899"}
                            monthlyChecked={monthlyChecked}
                            subscriptionFeatures={
                              <>Unlimited Optimized Prices</>
                            }
                            selectedSubscription={subscriptionPlanName}
                            monthlySubscriptionPeriod={monthlyChecked}
                            onCardClick={(e, planName) => {
                              onCardClick(e, planName);
                            }}
                            annualDiscountPercentage={
                              ANNUAL_DISCOUNT_PERCENTAGE
                            }
                            annualSales={"$1M to $3M"}
                          />
                          {checkModule(modules, "CUSTOM_TIER") && (
                            <SubscriptionCard
                              planId={"CORPORATE_PRICING"}
                              planName={"Custom"}
                              monthlySubscriptionPrice={"TBD"}
                              annualSubscriptionPrice={"TBD"}
                              monthlyChecked={monthlyChecked}
                              subscriptionFeatures={
                                <p
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Features to be determined.
                                </p>
                              }
                              selectedSubscription={subscriptionPlanName}
                              monthlySubscriptionPeriod={monthlyChecked}
                              onCardClick={(e, planName) => {
                                onCardClick(e, planName);
                              }}
                              annualDiscountPercentage={
                                ANNUAL_DISCOUNT_PERCENTAGE
                              }
                              annualSales={"Above $3M"}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Row style={{ marginTop: "2rem" }}>
                  <Col xs={12} md={9} className="mx-auto">
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                        className="text-center px-3 px-md-0 py-5 py-md-0 text-md-start"
                      >
                        Want to find out more about our managed service
                        offerings, advanced analytics features, or are you
                        having trouble choosing a plan?
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className={
                          "d-flex justify-content-center align-items-center"
                        }
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        <span
                          style={{
                            color: trellisPalette[13],
                            cursor: "pointer",
                          }}
                          onClick={() => setShowHubspotModal(true)}
                        >
                          Click here to talk to Customer Success.
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: "4rem" }}>
                  <Col md={4} mdOffset={4} className="text-center mx-auto mb-5">
                    <ConfirmationModal
                      title={
                        amz_onboard.org_status === "active"
                          ? "Confirm Subscription Upgrade"
                          : "Confirm Subscription"
                      }
                      modalButtonText={
                        userLayout ? "Update New Subscription" : "Subscribe"
                      }
                      confirmButtonText={"Confirm"}
                      confirmationMessage={
                        <p style={{ fontSize: "1.7rem" }}>
                          Are you sure you want to
                          {amz_onboard.org_status === "active"
                            ? " upgrade to"
                            : " sign up for"}{" "}
                          the {subscriptionPlan?.displayName}{" "}
                          {discoveryType === "search-analytics"
                            ? "Search Analytics"
                            : discoveryType === "content"
                            ? "Product Content"
                            : discoveryType === "pricing"
                            ? "Dynamic Pricing"
                            : "Advertising"}{" "}
                          tier?
                        </p>
                      }
                      buttonStyle="adplan_button"
                      disabled={!subscriptionPlan}
                      onConfirm={() => saveSubPlan()}
                      modelButtonStyle={{
                        width: "100%",
                        maxWidth: "100%",
                        marginBottom:
                          discoveryType === "pricing" ||
                          discoveryType === "search-analytics"
                            ? "30px"
                            : "inherit",
                      }}
                    />
                  </Col>
                </Row>

                {!userLayout ? (
                  <Row style={{ marginTop: "2rem" }}>
                    <Col md={8} mdOffset={2} className="text-center">
                      <span
                        style={{ fontSize: "18px", float: "center" }}
                        onClick={(e) => {
                          setSkipButton(true, () => saveSubPlan());
                        }}
                      >
                        <CustomHref text="Skip for now and start free trial" />
                      </span>
                    </Col>
                  </Row>
                ) : null}
              </>
            </Grid>
          </Grid>
        </Grid>
        {!mobileStyle && (
          <Hidden smDown>
            <Col
              md={4}
              style={{
                backgroundColor: "#2E0054",
                height: "auto",
                overflow: "hidden",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div md={12} className={classes.rightImageContainer}>
                <Row
                  style={{
                    marginTop:
                      discoveryType === "advertising" ? "50px" : "80px",
                  }}
                >
                  <Col md={12} style={{}} translate="yes">
                    <div
                      style={{
                        margin: "auto",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={discoveryPanelImage}
                        width="390px"
                        alt="A lightbulb with an arrow."
                      />
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#ffffff",
                        fontSize: "32px",
                        fontWeight: 800,
                        margin: "1rem auto 2rem",
                        lineHeight: 1.1,
                      }}
                    >
                      {dashboardType === "advertising" ? (
                        user.tier !== "unknown" ||
                        user.pricing_tier !== "unknown" ? (
                          <>
                            Sustainable growth
                            <br />
                            starts here
                          </>
                        ) : (
                          <>
                            What is Advertising
                            <br />
                            Automation?
                          </>
                        )
                      ) : dashboardType === "search-analytics" ? (
                        <>What is Search Analytics?</>
                      ) : dashboardType === "content" ? (
                        <>What is Product Content?</>
                      ) : user.tier !== "unknown" ||
                        user.pricing_tier !== "unknown" ? (
                        <>
                          Price products
                          <br />
                          with confidence
                        </>
                      ) : (
                        <>What is Dynamic Pricing?</>
                      )}
                    </div>
                    {user.tier !== "unknown" ||
                    user.pricing_tier !== "unknown" ? (
                      <div
                        style={{
                          textAlign: "center",
                          color: "#ffffff",
                          fontSize: "20px",
                          fontWeight: 500,
                          marginTop: "1rem",
                          padding: "2rem 6rem",
                        }}
                      >
                        {dashboardType === "advertising" ? (
                          <>
                            Our proprietary algorithm auto bids on each keyword
                            using advanced analytics considering consumption
                            trends and competitive analysis.
                          </>
                        ) : dashboardType === "search-analytics" ? (
                          <>
                            Digital Shelf analytics for eCommerce that
                            transforms the search keyword data into profitable
                            insights. <br />
                            <br />
                            Our platform displays dynamic charts about how your
                            brand ranks and performs against the competition.
                          </>
                        ) : dashboardType === "content" ? (
                          <>
                            Content scores your products using best practices
                            and traffic and conversion data. <br />
                            <br />
                            Our platform displays dynamic charts about how your
                            brand ranks and performs against the competition.
                          </>
                        ) : (
                          <>
                            Once you build your price profile, our algorithms
                            will suggest the winning prices set by your goal;
                            maximize sales volume or profit margin. <br />
                            <br />
                            You can also enroll your products for auto-pricing
                            daily.
                          </>
                        )}
                      </div>
                    ) : dashboardType === "pricing" ? (
                      <>
                        <BlogCard
                          title={"Ultimate Intro to Dynamic Pricing"}
                          link="https://gotrellis.com/blog/ultimate-intro-to-dynamic-pricing/"
                        />
                        <BlogCard
                          title={
                            "Is your Product’s Price Too High, Too Low, or Just Right?"
                          }
                          link="https://gotrellis.com/blog/goldilocks-pricing/"
                        />
                        <BlogCard
                          title={"The Subtle Art of Pricing Amid Inflation"}
                          link="https://gotrellis.com/blog/pricing-amid-inflation/"
                        />
                      </>
                    ) : (
                      <>
                        <BlogCard
                          title={
                            "Trellis vs Manual Amazon Ad Campaigns: How to Save Time and Money?"
                          }
                          link="https://gotrellis.com/blog/trellis-manual-amazon-campaign/"
                        />
                        <BlogCard
                          title={"Case Study: +147% sales using PPC Automation"}
                          link="https://gotrellis.com/blog/case-study-ppc-automation/"
                        />
                        <BlogCard
                          title={
                            "Case Study: World Products Saves 1,650 Hours Using Trellis"
                          }
                          link="https://gotrellis.com/blog/case-study-world-products-agency/"
                        />
                      </>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xs={12}
                    style={{
                      textAlign: "center",
                      top: "50px",
                    }}
                  >
                    <img
                      src={trellislogo}
                      alt="Trellis Logo"
                      width="170px"
                      height="50px"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Hidden>
        )}
        {/* )} */}
      </Grid>
      <HubspotCalendarModal
        open={showHubspotModal}
        onClose={() => {
          setShowHubspotModal(false);
        }}
      />
    </>
  );
};

PaymentDiscovery.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  amz_onboard: state.amz_onboard,
  user: state.user,
  modules: selectModules(state),
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PaymentDiscovery)));
