import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { formatCurrency, formatPercent } from "utils/formatNumber";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const AdTypePieCharts = ({ adTypeData, fontFamily }) => {
  const { marketPlace } = useSelector((state) => state);

  const adSpendData = useMemo(() => {
    return (
      adTypeData
        ?.map((type) => {
          return { name: type.name, color: type.color, y: type.ad_spend };
        })
        ?.filter((type) => type.y) ?? []
    );
  }, [adTypeData]);

  const adSalesData = useMemo(() => {
    return (
      adTypeData
        ?.map((type) => {
          return { name: type.name, color: type.color, y: type.ad_sales };
        })
        ?.filter((type) => type.y) ?? []
    );
  }, [adTypeData]);

  const adSalesTotal = useMemo(() => {
    return adSalesData?.reduce((acc, current) => {
      return acc + current.y;
    }, 0);
  }, [adSalesData]);

  const adSpendTotal = useMemo(() => {
    return adSpendData?.reduce((acc, current) => {
      return acc + current.y;
    }, 0);
  }, [adSpendData]);

  const getPieChartOptions = (
    title,
    series = [],
    total = null,
    overrideOptions = {}
  ) => {
    return {
      chart: {
        type: "pie",
        style: {
          fontFamily: fontFamily ?? "Poppins",
          fontWeight: 300,
          fontSize: "15px",
        },
      },
      title: {
        text: title,
        style: {
          fontWeight: 500,
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          if (total) {
            return (
              "<span><strong>" +
              this.point.name +
              ":</strong><br />" +
              formatCurrency(this.point.y, marketPlace.marketPlace) +
              "<strong>" +
              ` (${formatPercent(this.point.y / total, 1)})` +
              "</strong></span>"
            );
          }

          return (
            "<span><strong>" +
            this.point.name +
            ":</strong><br />" +
            formatCurrency(this.point.y, marketPlace.marketPlace) +
            "</span>"
          );
        },
      },
      plotOptions: {
        series: {
          enableMouseTracking: false,
          states: {
            inactive: {
              opacity: 1,
            },
          },
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          allowPointSelect: false,
          cursor: "none",
          dataLabels: {
            enabled: true,
            useHTML: true,
            formatter: function () {
              return (
                `<span style="font-size: 1.2em; font-weight: 600; opacity: 0.8">${this.point.name}</span><br>` +
                `<span style="opacity: 0.6; margin: auto; display: block; text-align: center; width: 100%;">${formatCurrency(
                  this.point.y,
                  marketPlace.marketPlace
                )} - ${formatPercent(this.point.y / total, 1)}</span>`
              );
            },
          },
        },
      },
      series: series,
      ...overrideOptions,
    };
  };

  const adSalesChartOptions = getPieChartOptions(
    "Ad Sales by Ad Type",
    [
      {
        name: "Ad Sales",
        colorByPoint: true,
        data: adSalesData ?? [],
      },
    ],
    adSalesTotal
  );

  const adSpendChartOptions = getPieChartOptions(
    "Ad Spend by Ad Type",
    [
      {
        name: "Ad Spend",
        colorByPoint: true,
        data: adSpendData ?? [],
      },
    ],
    adSpendTotal
  );

  return (
    <Row>
      <Col xs={6}>
        <HighchartsReact
          highcharts={Highcharts}
          options={adSalesChartOptions}
        />
      </Col>

      <Col xs={6}>
        <HighchartsReact
          highcharts={Highcharts}
          options={adSpendChartOptions}
        />
      </Col>
    </Row>
  );
};

export default AdTypePieCharts;
