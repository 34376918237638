import React from "react";
import { Row, Col } from "react-bootstrap";
import DialogueCard from "./DialogueCard";
import { useHistory } from "react-router-dom";

const Summary = () => {
  const history = useHistory();

  return (
    <Row className="my-5">
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        <h2 className="mt-5 mb-1 fw-600">Thanks</h2>
        <p className="fs-4">We have started loading and analyzing your data</p>
      </Col>
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        <DialogueCard
          contents={{
            value: "amazon-profiles",
            title: "Continue to Dashboard",
          }}
          justify="center"
          additionalClasses="my-5"
          onClick={async () => {
            history.push("/user/dashboard");
          }}
        />
      </Col>
    </Row>
  );
};

export default Summary;
