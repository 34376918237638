import React from "react";

const ThemeLine = ({ children, additionalStyles = {} }) => (
  <div style={{ marginTop: "-5px" }}>
    <span
      style={{
        fontStyle: "italic",
        fontWeight: 600,
        color: "#D91266",
        ...additionalStyles,
      }}
    >
      {children}
    </span>
  </div>
);

export default ThemeLine;
