import { Grid, Box } from "@material-ui/core";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import React from "react";

function LoadingProgress(props) {
  const { registrationInProgress } = props;
  return (
    <Grid container className="wrap">
      <Grid item md={12}>
        <Box className={props.textStyle}>
          {registrationInProgress
            ? "Creating your Trellis Account "
            : "Preparing your Trellis Dashboard "}
          &nbsp;{" "}
          <img
            src={logo}
            alt="Trellis"
            className="rotate"
            width="80"
            height="auto"
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoadingProgress;
