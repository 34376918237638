import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Table, Modal, Button, Form } from "react-bootstrap";
import Checkbox from "components/core/basic/CheckBox";
import FormGroupTile from "components/core/basic/FormGroupTile";
import FormGroupLabel from "components/core/basic/FormGroupLabel";
import { LogoutButton } from "components/core/basic/Button.jsx";
import {
  MARKETPLACE_CURRENCY_DENOMINATION,
  PRODUCT_AMAZON_LINKS,
} from "utils/marketplaceConstants";
import api from "utils/api";
import { useQuery } from "react-query";
import { useFetch } from "hooks/api";
import { cut } from "utils/formatText";
import { formatCurrency, formatNumber } from "utils/formatNumber";
import { trellisPalette } from "components/custom/analytics/palettes";
import PlanSlider from "./components/PricingSlider";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { SLIDER_HELP } from "./components/SliderHelp";
import { standardDeviation, average } from "utils/math";
import moment from "moment";
import { useForm } from "react-hook-form";
import InlineProgramForm from "components/custom/programs/InlineProgramForm";
import { TbPencilCog } from "react-icons/tb";

const PricingPlanForm = ({
  showEnroll,
  setShowEnroll,
  selectedProducts,
  planOptions,
  marketPlace,
  numParents,
  category,
  productById,
  modules,
}) => {
  const globalRollback = true;
  const history = useHistory();

  const actionTypeOptions = [
    { label: `Match their price`, value: "match" },
    { label: `Beat their price`, value: "decrease" },
    { label: `Premium over their price`, value: "increase" },
  ];

  let strategyOptions = [
    { label: `Dynamic Pricing`, value: "dynamic" },
    {
      label: "Margin Profiling",
      value: "margin_profiling",
      programTemplate: "MARGIN_PROFILING",
    },
    {
      label: `Best Seller Rank Optimization`,
      value: "bsr",
    },
    {
      label: `Competitor Price Matching`,
      value: "competitor",
    },
    {
      label: `Clearance Pricing`,
      value: "clearance",
      programTemplate: "CLEARANCE",
    },
    {
      label: `Custom Rules`,
      value: "rules_engine",
      programTemplate: "CUSTOM",
    },
  ];

  const [step, setStep] = useState("enroll");
  const [targetBSR, setTargetBSR] = useState(0);
  const [currentBSR, setCurrentBSR] = useState(0);
  const [minPrice, setMinPrice] = useState(planOptions.minPrice);
  const [maxPrice, setMaxPrice] = useState(planOptions.maxPrice);
  const [profiling, setProfiling] = useState(false);
  const [planMode, setPlanMode] = useState(0);
  const [planStrategy, setPlanStrategy] = useState(strategyOptions[0]);
  const [disableProfiling, setDisableProfiling] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});
  const [amazonCategory, setAmazonCategory] = useState("");

  const [competitorList, setCompetitorList] = useState([]);
  const [chosenCompetitor, setChosenCompetitor] = useState(null);

  const [competitorAction, setCompetitorAction] = useState(
    actionTypeOptions[0]
  );
  const [competitorValue, setCompetitorValue] = useState(null);
  const [competitorUnit, setCompetitorUnit] = useState("percent");
  const [createManyPlans, setCreateManyPlans] = useState(false);
  const [overrideMargin, setOverrideMargin] = useState(false);
  const [overrideMarginValue, setOverrideMarginValue] = useState(null);
  const [overrideVolume, setOverrideVolume] = useState(false);
  const [overrideVolumeValue, setOverrideVolumeValue] = useState(null);
  const [overrideSupply, setOverrideSupply] = useState(false);
  const [overrideSupplyValue, setOverrideSupplyValue] = useState(null);

  const queryClient = useQueryClient();

  const programForm = useForm({});

  const steps = useMemo(() => {
    if (planOptions?.uniqueASINs?.length === 1) {
      setStep("enroll");
      return ["enroll", "confirm", "done"];
    } else {
      setStep("group");
      return ["group", "enroll", "confirm", "done"];
    }
  }, [planOptions]);

  const nextStep = () => {
    const index = steps.indexOf(step);
    if (index < steps.length - 1) {
      setStep(steps[index + 1]);
    }
  };

  const prevStep = () => {
    const index = steps.indexOf(step);
    if (index > 0) {
      setStep(steps[index - 1]);
    }
  };

  const confirmPlan = async () => {
    const planType = planStrategy;
    const payload = {
      name: "",
      plan_type: planType.value,
      target_bsr: targetBSR,
      min_price: minPrice,
      max_price: maxPrice,
      plan_mode: planMode,
      react_buy_box: false,
      market_place: marketPlace.marketPlace,
      num_parents: planOptions.uniqueParents,
      product_list: selectedProducts.join(","),
      category_id: category,
      profiling: profiling,
      competitor_value: competitorValue,
      competitor_unit: competitorUnit,
      competitor_action: competitorAction.value,
      competitor_asin: chosenCompetitor,
      global_rollback: globalRollback,
      is_multiple_plans: createManyPlans,
      override_margin: overrideMargin,
      override_margin_value: overrideMarginValue,
      override_volume: overrideVolume,
      override_volume_value: overrideVolumeValue,
      override_supply: overrideSupply,
      override_supply_value: overrideSupplyValue,
    };

    if (planType?.programTemplate) {
      payload["program"] = {
        template_type: planType.programTemplate,
        ui_state: programForm.getValues(),
      };
    }

    setStep("done");
    const res = await api.post("/pricing/pricingplan", {
      plan_options: payload,
    });

    queryClient.invalidateQueries("planData");
    queryClient.invalidateQueries("pricing_plan_report_dashboard");
    queryClient.invalidateQueries("product_report");

    if (planStrategy.value === "rules_engine") {
      history.push(`/user/_pricing/plan/${res.data.plan_id}?tab=rules_engine`);
    }
  };

  const enrollPlan = async () => {
    let errors = {};

    if (!disableMinMax) {
      if (minPrice === null || minPrice === "" || parseFloat(minPrice) <= 0) {
        errors.minPrice = "Please enter a minimum price";
      }
      if (maxPrice === null || maxPrice === "" || parseFloat(maxPrice) <= 0) {
        errors.maxPrice = "Please enter a maximum price";
      }
      if (
        minPrice &&
        maxPrice &&
        parseFloat(minPrice) >= parseFloat(maxPrice)
      ) {
        errors.maxPrice = "Maximum price should be greater than minimum price";
      }
    }

    if (planStrategy.value === "bsr") {
      if (
        targetBSR === null ||
        targetBSR === "" ||
        parseFloat(targetBSR) <= 0
      ) {
        errors.targetBSR = "Please enter valid target BSR";
      }
    }

    if (planStrategy.value === "competitor") {
      if (competitorAction.value !== "match") {
        if (
          competitorValue === null ||
          competitorValue === "" ||
          parseFloat(competitorValue) <= 0
        ) {
          errors.competitorValue = "Please enter valid value";
        }
      }

      if (chosenCompetitor === null) {
        errors.chosenCompetitor = "Please select competitor";
      }
    }
    let isProgramValid = true;
    if (planStrategy?.programTemplate) {
      isProgramValid = await programForm.trigger();
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0 || !isProgramValid) {
      return;
    }

    setStep("confirm");
  };

  const end = moment(moment().format("YYYY-MM-DD")).subtract(1, "days");
  const start = moment(end.format("YYYY-MM-DD")).subtract(30, "days");
  const preEnd = start.clone().subtract(1, "days");
  const preStart = moment(preEnd.format("YYYY-MM-DD")).subtract(30, "days");

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: "day",
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "product_id",
  };

  const { data: productData, isLoading: productIsLoading } = useFetch(
    ["product", marketPlace.marketPlace, selectedProducts],
    `/api/data_report/product_report?filters=product_ids:${selectedProducts.join(
      ","
    )}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data, isLoading } = useQuery(
    ["pricingData", selectedProducts, showEnroll],
    async () => {
      const res = await api.get(
        `/pricing/pricingproducts?ids=${selectedProducts.join(",")}`
      );
      return res.data;
    }
  );

  const { data: competitors, loading: loadingCompetitors } = useFetch(
    "competitors",
    "/api/competitors",
    {
      marketplace: marketPlace.marketPlace,
      product: selectedProducts[0],
    },
    { enabled: selectedProducts.length > 0 }
  );

  const asinList = useMemo(() => {
    if (competitors?.data?.results) {
      return competitors?.data?.results?.map((c) => c.product_ref);
    }
    return [];
  }, [competitors]);

  useEffect(() => {
    if (asinList && asinList.length > 0 && !loadingCompetitors) {
      const getPrices = async () => {
        const res = await api.post("/merchandise/competitive_price", {
          params: {
            asinList: asinList.join(","),
            marketplace: marketPlace.marketPlace,
          },
        });

        const prices = res.data.prices;
        const cl = [...competitors.data.results];
        for (let i = 0; i < prices.length; i++) {
          for (let j = 0; j < cl.length; j++) {
            if (cl[j].product_ref === prices[i].asin) {
              cl[j].price = prices[i].price;
              break;
            }
          }
        }

        setCompetitorList(cl);
      };

      getPrices();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asinList]);

  const resetPlan = () => {
    setTargetBSR(null);
    setMinPrice(null);
    setMaxPrice(null);
    setPlanMode(0);
    setValidationErrors({});
    setAmazonCategory("");
    setStep(steps[0]);
    setProfiling(false);
    setCompetitorAction(actionTypeOptions[0]);
    setPlanStrategy(strategyOptions[0]);
    setDisableProfiling(false);
    setCreateManyPlans(false);
  };

  useEffect(() => {
    resetPlan();

    setMinPrice(planOptions.minPrice);
    setMaxPrice(planOptions.maxPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planOptions, selectedProducts]);

  const getBSR = async (productId) => {
    const res = await api.get(`/pricing/bsr?product_id=${productId}`);
    setCurrentBSR(res.data?.ranks);
    setTargetBSR(res.data?.ranks);
    return res.data?.ranks;
  };

  useEffect(() => {
    if (productData?.products?.length > 0) {
      getBSR(productData?.products[0]?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  const showPricingStrategy = useMemo(() => {
    if (isLoading) {
      return false;
    }

    if (disableProfiling) {
      return false;
    }

    if (loadingCompetitors) {
      return false;
    }

    if (planStrategy.value !== "dynamic") {
      return false;
    }

    if (overrideMargin || overrideVolume || overrideSupply) {
      return true;
    }

    return true;
  }, [
    isLoading,
    disableProfiling,
    loadingCompetitors,
    planStrategy,
    overrideMargin,
    overrideVolume,
    overrideSupply,
  ]);

  const { showEstimates, disableMinMax } = useMemo(() => {
    if (productIsLoading || isLoading) {
      return { showEstimates: false, disableMinMax: true };
    }

    if (selectedProducts.length === 1) {
      return { showEstimates: true, disableMinMax: false };
    }

    if (createManyPlans) {
      return { showEstimates: false, disableMinMax: true };
    }

    return { showEstimates: false, disableMinMax: false };
  }, [productIsLoading, isLoading, createManyPlans, selectedProducts]);

  const { expectedData, targetMargin, targetVolume } = useMemo(() => {
    let targetMargin = 0;
    let targetVolume = 0;

    const ex = {
      floor_units: 0,
      ceiling_units: 0,
      floor_sales: 0,
      ceiling_sales: 0,
      floor_price: 0,
      ceiling_price: 0,

      profit: 0,
      margin: 0,
      turnover: 0,
    };

    if (productIsLoading || isLoading || data?.length === 0) {
      return {
        expectedData: ex,
        targetMargin: targetMargin,
        targetVolume: targetVolume,
      };
    }

    const setMargin = (margin) => {
      if (!overrideMargin) {
        targetMargin = margin;
      }
    };

    let categoryName = data[0].product.amazon_category;
    let prices = [];
    let history = [];
    let thirtyDayUnits = 0;
    let totalCogs = 0;
    let totalRevenue = 0;

    for (let i = 0; i < productData.date.length; i++) {
      history.push(productData.date[i].total_units);
      totalCogs += productData.date[i].cogs;
      totalRevenue += productData.date[i].total_sales;
    }

    let threshold = parseFloat(
      (standardDeviation(history) + average(history)).toFixed(0)
    );
    for (let i = 0; i < history.length; i++) {
      if (history[i] > threshold) {
        thirtyDayUnits += parseInt((history[i] / 2).toFixed(0));
      } else {
        thirtyDayUnits += history[i];
      }
    }

    let unitsMod = thirtyDayUnits;
    let price = average(prices);
    let priceMod = average(prices);

    const originalMargin = totalRevenue > 0 ? totalCogs / totalRevenue : 0;
    if (planMode === -2) {
      priceMod = parseFloat((price - price * 0.05).toFixed(2));
      unitsMod = parseInt(thirtyDayUnits * 1.2);
      ex.floor_units = thirtyDayUnits;
      ex.ceiling_units = unitsMod;
      setMargin(originalMargin - 0.05);
    } else if (planMode === -1) {
      unitsMod = parseInt(thirtyDayUnits * 1.1);
      priceMod = parseFloat((price - price * 0.03).toFixed(2));
      ex.floor_units = thirtyDayUnits;
      ex.ceiling_units = unitsMod;
      setMargin(originalMargin - 0.03);
    } else if (planMode === 1) {
      unitsMod = parseInt(thirtyDayUnits * 0.9);
      priceMod = parseFloat((price + price * 0.03).toFixed(2));
      ex.floor_units = unitsMod;
      ex.ceiling_units = thirtyDayUnits;
      setMargin(originalMargin + 0.03);
    } else if (planMode === 2) {
      unitsMod = parseInt(thirtyDayUnits * 0.8);
      priceMod = parseFloat((price + price * 0.05).toFixed(2));
      ex.floor_units = unitsMod;
      ex.ceiling_units = thirtyDayUnits;
      setMargin(originalMargin + 0.05);
    } else {
      priceMod = parseFloat((price + price * 0.01).toFixed(2));
      ex.floor_units = thirtyDayUnits;
      ex.ceiling_units = thirtyDayUnits;
      setMargin(originalMargin);
    }
    ex.floor_sales = ex.floor_units * Math.min(priceMod, price);
    ex.floor_price = Math.min(priceMod, price);
    ex.ceiling_sales = ex.ceiling_units * Math.max(priceMod, price);
    ex.ceiling_price = Math.max(priceMod, price);
    setAmazonCategory(categoryName);

    const tgtVolume = (ex.ceiling_units + ex.floor_units) / 2;
    if (!overrideVolume) {
      targetVolume = tgtVolume;
    }

    return {
      expectedData: ex,
      targetMargin: targetMargin,
      targetVolume: targetVolume,
    };
  }, [
    productIsLoading,
    isLoading,
    data,
    planMode,
    productData,
    overrideMargin,
    overrideVolume,
  ]);

  const disableEnroll = useMemo(() => {
    if (productIsLoading || isLoading || data?.length === 0) {
      return true;
    }

    return false;
  }, [productIsLoading, isLoading, data]);

  return (
    <Modal show={showEnroll} size="xl">
      <Modal.Header className="py-0 px-5">
        <div className="fs-3 my-4 text-start fw-bold">
          Create New Pricing Plan&nbsp;-&nbsp;
          {createManyPlans ? " Multiple Plans" : " Single Plan"}
        </div>
      </Modal.Header>
      <Modal.Body>
        {step === "group" && (
          <Row className="fs-4 text-left py-3 px-3">
            <Col xs={12} className="fw-bold">
              How would you like to group your products?
            </Col>
            <Col xs={12} className="fs-4 pt-2 pb-5">
              If you've chosen similar ASINs from the products table and want to
              price them identically, you can consolidate them under one pricing
              plan. This is useful, for example, if you have the same product in
              different colors but want to maintain the same price for all
              variants.
              <br />
              <br />
              If your products are distinct or you prefer different prices for
              variations, opt for the multiple plan option.
            </Col>
            <Col xs={12} className="text-start fs-4 pb-3 pt-3">
              <Form.Check
                type="radio"
                name="createManyPlans"
                label={
                  <span>
                    <span className="fw-bold">Single Plan</span>: &nbsp;A Single
                    Pricing Plan which includes all selected ASINs
                  </span>
                }
                checked={!createManyPlans}
                onChange={() => {
                  setCreateManyPlans(false);
                }}
              />
            </Col>
            <Col xs={12} className="text-start fs-4">
              <Form.Check
                type="radio"
                name="createManyPlans"
                label={
                  <span>
                    <span className="fw-bold">Multiple Plans</span>: &nbsp;A
                    Pricing Plan for each chosen ASIN
                  </span>
                }
                checked={createManyPlans}
                onChange={() => {
                  setCreateManyPlans(true);
                }}
              />
            </Col>
          </Row>
        )}
        {step === "done" && (
          <Row className="fs-3 text-left py-3 px-3">
            <Col>
              <Row>
                <Col className="pt-2 pb-4 text-center fs-4 fw-bold">
                  Your pricing plan is currently being set up. This process may
                  take a few moments to finish.
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === "confirm" && (
          <>
            <Row className="fs-3 text-left py-3 px-3">
              <Col>
                <div>
                  <span className="fw-bold">Products</span>
                </div>
              </Col>
            </Row>
            <Row className="fs-4 text-left py-2 px-5">
              {step === "confirm" && (
                <Row>
                  <Col className="pt-2 pb-4 text-center fs-4 fw-bold">
                    You are about to enroll the selected SKUs into Dynamic
                    Pricing. Please confirm that you want to proceed with this
                    action.
                  </Col>
                </Row>
              )}
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th className="text-start">SKU</th>
                    <th className="text-start">Title</th>
                    <th className="text-end">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.map((c) => (
                    <tr key={c}>
                      <td className="text-center">
                        <img
                          src={productById[c].product_image_url}
                          alt={productById[c].product_title}
                          style={{ width: "35px", height: "35px" }}
                        />
                      </td>
                      <td className="text-start">
                        <a
                          href={`${
                            PRODUCT_AMAZON_LINKS[marketPlace.marketPlace]
                          }${productById[c].asin}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {productById[c].sku}
                        </a>
                      </td>
                      <td className="text-start">
                        {cut(productById[c].product_title, 75)}
                      </td>
                      <td className="text-end">
                        {formatCurrency(
                          productById[c].product_price,
                          marketPlace.marketPlace
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Row>
                <Col className="text-center py-3">
                  {step === "confirm" && (
                    <Button
                      style={{
                        border: 0,
                        padding: 0,
                        margin: 0,
                        backgroundColor: "#fff",
                        boxShadow: "none",
                        borderRadius: "0px",
                        color: trellisPalette[0],
                      }}
                      className="fs-3"
                      onClick={() => prevStep()}
                    >
                      &lt;&lt; Back
                    </Button>
                  )}
                </Col>
              </Row>
            </Row>
          </>
        )}
        {step === "enroll" && (
          <>
            <Row className="fs-4 text-left py-3 px-3">
              <Col>
                <div>
                  <span className="fw-bold">Plan Setup</span>
                </div>
              </Col>
            </Row>

            <>
              {/* Plan Name */}
              <Row className="fs-4 text-left py-3 px-5">
                <Col xs={7} className="ps-3">
                  <Row>
                    {/*  Min Price */}
                    <Col xs={6} className="ps-3">
                      <span
                        style={{
                          color: "#000",
                        }}
                      >
                        Mininum Price
                      </span>
                      <Row className="pt-2">
                        <Col xs={12}>
                          <FormGroupTile
                            formControlClass="text-center"
                            type="formcontrol"
                            formControlType={"numeric"}
                            prefixSide={"left"}
                            handleChildFormElement={(key, value) => {
                              setMinPrice(value);
                            }}
                            prefix={
                              <div
                                className="fs-3"
                                style={{ position: "absolute", top: "-18px" }}
                              >
                                {
                                  MARKETPLACE_CURRENCY_DENOMINATION[
                                    marketPlace.marketPlace
                                  ]
                                }
                              </div>
                            }
                            placeholder={`Minimum Price`}
                            defaultValue={disableMinMax ? "" : minPrice}
                            disabled={disableMinMax}
                            style={{
                              border: validationErrors["minPrice"]
                                ? "1px solid red"
                                : null,
                            }}
                          />

                          {validationErrors["minPrice"] && (
                            <Row>
                              <Col xs={12}>
                                <span style={{ color: "red" }}>
                                  <small>{validationErrors["minPrice"]}</small>
                                </span>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {/*  Max Price */}
                    <Col xs={6} className="ps-3">
                      <span
                        style={{
                          color: "#000",
                        }}
                      >
                        Maximum Price
                      </span>
                      <Row className="pt-2">
                        <Col xs={12}>
                          <FormGroupTile
                            formControlClass="text-center"
                            type="formcontrol"
                            formControlType={"numeric"}
                            prefixSide={"left"}
                            handleChildFormElement={(key, value) => {
                              setMaxPrice(value);
                            }}
                            prefix={
                              <div
                                className="fs-3"
                                style={{ position: "absolute", top: "-18px" }}
                              >
                                {
                                  MARKETPLACE_CURRENCY_DENOMINATION[
                                    marketPlace.marketPlace
                                  ]
                                }
                              </div>
                            }
                            placeholder={`Maximum Price`}
                            defaultValue={disableMinMax ? "" : maxPrice}
                            disabled={disableMinMax}
                            style={{
                              border: validationErrors["maxPrice"]
                                ? "1px solid red"
                                : null,
                            }}
                          />
                          {validationErrors["maxPrice"] && (
                            <Row>
                              <Col xs={12}>
                                <span style={{ color: "red" }}>
                                  <small>{validationErrors["maxPrice"]}</small>
                                </span>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} className="pt-3">
                      {createManyPlans && (
                        <small>
                          The product settings will determine the range of
                          minimum and maximum prices to be used.
                        </small>
                      )}
                      {!createManyPlans && (
                        <small>
                          Verify the minimum and maximum prices to be applied in
                          your pricing strategy.
                        </small>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={5} className="ps-3">
                  <span style={{ color: "#000" }}>Plan Options:</span>
                  <Row>
                    <Col>
                      <FormGroupTile
                        type="select"
                        multi={false}
                        stateName={"strategyType"}
                        handleChildFormElement={(key, value) => {
                          setPlanStrategy(value);
                        }}
                        defaultValue={planStrategy}
                        options={strategyOptions}
                        disabled={disableEnroll}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={5} className="ps-3"></Col>
              </Row>

              {planStrategy.value === "competitor" && (
                <>
                  <Row
                    className="fs-4 text-left pt-5 pb-3 px-3"
                    style={{
                      borderTop: "1px solid #ccc",
                    }}
                  >
                    <Col>
                      <div className="fw-bold">Competitor Tracking</div>
                    </Col>
                    <Col>
                      {validationErrors["chosenCompetitor"] && (
                        <div style={{ color: "red" }}>
                          <small>{validationErrors["chosenCompetitor"]}</small>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="fs-4 text-center pb-1 px-5">
                    <Col>
                      {loadingCompetitors && (
                        <div className="text-center">
                          <span className="fw-bold pb-3">
                            Competitors are loading...
                          </span>
                        </div>
                      )}
                      {competitorList.length === 0 && (
                        <div className="text-center">
                          <span className="fw-bold pb-3">
                            There are no competitors for this product
                          </span>
                        </div>
                      )}
                      {competitorList.length > 0 && (
                        <Table>
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>&nbsp;</th>
                              <th className="text-start">ASIN</th>
                              <th className="text-start">Product Title</th>
                              <th className="text-center">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {competitors?.data?.results?.map(
                              (competitor, index) => {
                                if (
                                  !chosenCompetitor ||
                                  chosenCompetitor === competitor.product_ref
                                ) {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={
                                            chosenCompetitor ===
                                            competitor.product_ref
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setChosenCompetitor(
                                                competitor.product_ref
                                              );
                                            } else {
                                              setChosenCompetitor(null);
                                            }
                                          }}
                                        />
                                      </td>
                                      <td className="text-start">
                                        <a
                                          href={`${
                                            PRODUCT_AMAZON_LINKS[
                                              marketPlace.marketPlace
                                            ]
                                          }${competitor.product_ref}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {competitor.product_ref}
                                        </a>
                                      </td>
                                      <td className="text-start">
                                        {cut(competitor.title, 60)}
                                      </td>
                                      <td className="text-end">
                                        {formatCurrency(
                                          competitor.price,
                                          marketPlace.marketPlace
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                                return null;
                              }
                            )}
                          </tbody>
                        </Table>
                      )}

                      {chosenCompetitor && (
                        <Row
                          className="pb-3 pt-3 mt-3"
                          style={{
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          <Col xs={4} className="text-start">
                            <FormGroupTile
                              label={"I want my price to"}
                              type="select"
                              multi={false}
                              stateName={"actionType"}
                              handleChildFormElement={(key, value) => {
                                setCompetitorAction(value);
                              }}
                              defaultValue={competitorAction}
                              options={actionTypeOptions}
                            />
                          </Col>
                          <Col xs={4} className="text-center">
                            <FormGroupTile
                              label={`by this ${
                                competitorUnit === "percent"
                                  ? "percentage"
                                  : "amount"
                              } ${
                                competitorAction.value === "decrease"
                                  ? " or less"
                                  : " or more"
                              }`}
                              formControlClass="text-center"
                              type="formcontrol"
                              formControlType={"number"}
                              prefixSide={"left"}
                              disabled={competitorAction.value === "match"}
                              defaultValue={
                                competitorValue
                                  ? formatNumber(competitorValue, null, 0)
                                  : 0
                              }
                              handleChildFormElement={(key, value) => {
                                setCompetitorValue(value);
                              }}
                            />

                            {validationErrors["competitorValue"] && (
                              <span style={{ color: "red" }}>
                                <small>
                                  {validationErrors["competitorValue"]}
                                </small>
                              </span>
                            )}
                          </Col>
                          <Col xs={4}>
                            <FormGroupLabel
                              label={"Action Unit"}
                              titleCase={true}
                            />
                            <Row className="pb-2">
                              <Col xs={3}></Col>
                              <Col xs={6}>
                                <div className="d-flex py-3 align-items-center justify-content-between">
                                  <Checkbox
                                    inline
                                    number={`adPlanBulkActionUnit-percent`}
                                    label="%"
                                    checked={competitorUnit === "percent"}
                                    onClick={() => {
                                      setCompetitorUnit("percent");
                                    }}
                                    disabled={
                                      competitorAction.value === "match"
                                    }
                                  />

                                  <Checkbox
                                    inline
                                    number={`adPlanBulkActionUnit-currency`}
                                    label="$"
                                    checked={competitorUnit === "currency"}
                                    onClick={() => {
                                      setCompetitorUnit("currency");
                                    }}
                                    disabled={
                                      competitorAction.value === "match"
                                    }
                                  />
                                </div>
                              </Col>
                              <Col xs={3}></Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </>
              )}

              {showPricingStrategy && (
                <>
                  <Row
                    className="pt-5 mt-3 fs-4 px-3"
                    style={{ borderTop: "1px solid #ccc" }}
                  >
                    <Col xs={4}>
                      <span className="fw-bold">Pricing Strategy</span>
                    </Col>
                    <Col xs={8} className="text-center">
                      {createManyPlans && (
                        <div
                          style={{
                            backgroundColor: trellisPalette[0],
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                          className="p-2 fw-bold"
                        >
                          NOTE: Each Pricing Plan will follow a the same
                          configuration strategy.
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="fs-4 text-left pb-1 px-3">
                    <Col xs={3}>
                      <Row className="pt-3 mt-3 fs-4 px-3">
                        <Col xs={12} className="text-start">
                          <span
                            style={{
                              color: "#000",
                            }}
                          >
                            Target Volume (Monthly)
                            <span
                              className="ps-2"
                              style={{
                                cursor: !overrideMargin ? "pointer" : "inherit",
                                color: trellisPalette[0],
                              }}
                              onClick={() => {
                                if (overrideMargin) {
                                  return false;
                                }
                                setOverrideVolume(!overrideVolume);
                                setPlanMode(0);
                                if (overrideVolume) {
                                  setOverrideVolumeValue(null);
                                } else {
                                  setOverrideVolumeValue(targetVolume);
                                }
                              }}
                            >
                              <TbPencilCog />
                            </span>
                          </span>
                          <Row className="pt-2 pb-4">
                            <Col xs={12}>
                              {overrideVolume && (
                                <>
                                  <FormGroupTile
                                    formControlClass="text-center"
                                    type="formcontrol"
                                    formControlType={"numeric"}
                                    prefixSide={"left"}
                                    handleChildFormElement={(key, value) => {
                                      setOverrideVolumeValue(value);
                                    }}
                                    placeholder={`Target Volume`}
                                    defaultValue={
                                      overrideVolume
                                        ? overrideVolumeValue
                                        : ((targetVolume / 7) * 30).toFixed(0)
                                    }
                                    disabled={false}
                                    style={{
                                      border: validationErrors["targetVolume"]
                                        ? "1px solid red"
                                        : null,
                                    }}
                                  />

                                  {validationErrors["targetVolume"] && (
                                    <Row>
                                      <Col xs={12}>
                                        <span style={{ color: "red" }}>
                                          <small>
                                            {validationErrors["targetVolume"]}
                                          </small>
                                        </span>
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              )}
                              {!overrideVolume && (
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <div
                                      style={{
                                        backgroundColor: "#eee",
                                        border: "1px solid #e3e3e3",
                                        borderRadius: "45px",
                                        color: "#850066",
                                        padding: "8px 12px",
                                        height: "40px",
                                        boxshadow: "none",
                                      }}
                                    >
                                      -
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="text-start">
                          <span
                            style={{
                              color: "#000",
                            }}
                          >
                            Target Margin (%)
                            <span
                              className="ps-2"
                              style={{
                                cursor: !overrideVolume ? "pointer" : "inherit",
                                color: trellisPalette[0],
                              }}
                              onClick={() => {
                                if (overrideVolume) {
                                  return false;
                                }
                                setOverrideMargin(!overrideMargin);
                                setPlanMode(0);
                                if (overrideMargin) {
                                  setOverrideMarginValue(null);
                                } else {
                                  setOverrideMarginValue(
                                    (targetMargin * 100).toFixed(0)
                                  );
                                }
                              }}
                            >
                              <TbPencilCog />
                            </span>
                          </span>
                          <Row className="pt-2 pb-4">
                            <Col xs={12}>
                              {overrideMargin && (
                                <>
                                  <FormGroupTile
                                    formControlClass="text-center"
                                    type="formcontrol"
                                    formControlType={"numeric"}
                                    prefixSide={"left"}
                                    handleChildFormElement={(key, value) => {
                                      setOverrideMarginValue(parseFloat(value));
                                    }}
                                    placeholder={`Target Margin`}
                                    defaultValue={
                                      overrideMargin
                                        ? overrideMarginValue
                                        : (targetMargin * 100).toFixed(2)
                                    }
                                    disabled={false}
                                    style={{
                                      border: validationErrors["targetMargin"]
                                        ? "1px solid red"
                                        : null,
                                    }}
                                  />

                                  {validationErrors["targetMargin"] && (
                                    <Row>
                                      <Col xs={12}>
                                        <span style={{ color: "red" }}>
                                          <small>
                                            {validationErrors["targetMargin"]}
                                          </small>
                                        </span>
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              )}

                              {!overrideMargin && (
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <div
                                      style={{
                                        backgroundColor: "#eee",
                                        border: "1px solid #e3e3e3",
                                        borderRadius: "45px",
                                        color: "#850066",
                                        padding: "8px 12px",
                                        height: "40px",
                                        boxshadow: "none",
                                      }}
                                    >
                                      -
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="text-center">
                          <span
                            style={{
                              color: "#000",
                            }}
                          >
                            {/* Supply Target Optimizer */}
                            {/* Stock Preservation Days Limit */}
                            Low Inv. Threshold (Days)
                            <span
                              className="ps-2"
                              style={{
                                cursor: "pointer",
                                color: trellisPalette[0],
                              }}
                              onClick={() => {
                                setOverrideSupply(!overrideSupply);
                                setPlanMode(0);
                              }}
                            >
                              <TbPencilCog />
                            </span>
                          </span>
                          <Row className="pt-2 pb-4">
                            <Col xs={12}>
                              {overrideSupply && (
                                <>
                                  <FormGroupTile
                                    formControlClass="text-center"
                                    type="formcontrol"
                                    formControlType={"numeric"}
                                    prefixSide={"left"}
                                    handleChildFormElement={(key, value) => {
                                      if (value) {
                                        setOverrideSupplyValue(
                                          parseFloat(value)
                                        );
                                      } else {
                                        setOverrideSupplyValue(0);
                                      }
                                    }}
                                    placeholder={`Supply Target`}
                                    defaultValue={
                                      overrideSupplyValue > 0
                                        ? overrideSupplyValue.toFixed(0)
                                        : ""
                                    }
                                    disabled={false}
                                    style={{
                                      border: validationErrors["supply"]
                                        ? "1px solid red"
                                        : null,
                                    }}
                                  />

                                  {validationErrors["supply"] && (
                                    <Row>
                                      <Col xs={12}>
                                        <span style={{ color: "red" }}>
                                          <small>
                                            {validationErrors["supply"]}
                                          </small>
                                        </span>
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              )}

                              {!overrideSupply && (
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <div
                                      style={{
                                        backgroundColor: "#eee",
                                        border: "1px solid #e3e3e3",
                                        borderRadius: "45px",
                                        color: "#850066",
                                        padding: "8px 12px",
                                        height: "40px",
                                        boxshadow: "none",
                                      }}
                                    >
                                      {formatNumber(
                                        overrideSupplyValue,
                                        null,
                                        0
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={9}>
                      {(overrideMargin || overrideVolume) && (
                        <Row className="fs-5 text-center pb-1 px-3">
                          <Col xs={12} className="text-start fs-5">
                            <div
                              style={{
                                padding: "2rem",
                                border: "1px solid rgb(238, 238, 238)",
                                borderRadius: "6px",
                                backgroundColor: "rgb(248, 248, 248)",
                                marginTop: "10px",
                                minHeight: "145px",
                              }}
                            >
                              <div>
                                Dynamic Pricing adjusts prices to align with
                                your specified target volume or margin goals.
                                It's important to understand that setting an
                                overly ambitious target volume can impact your
                                profit margins. However, a beneficial side
                                effect might include improvements in your
                                organic ranking and Best Seller Rank.
                              </div>
                              <div className="pt-3">
                                Conversely, pushing for a significantly higher
                                Target Margin could potentially harm your
                                organic ranking and Best Seller Rank. To
                                mitigate this risk while aiming to enhance your
                                Target Margin, we suggest a cautious approach of
                                increasing it by only 1% to 5%.
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {overrideSupply && (
                        <>
                          <Row className="fs-5 text-center pb-1 px-3">
                            <Col xs={12} className="text-start fs-5">
                              <div
                                style={{
                                  padding: "2rem",
                                  border: "1px solid rgb(238, 238, 238)",
                                  borderRadius: "6px",
                                  backgroundColor: "rgb(248, 248, 248)",
                                  marginTop: "10px",
                                }}
                              >
                                <div>
                                  When the Days of Supply drops below your
                                  desired number of days, the dynamic pricing
                                  engine will automatically increase prices to
                                  manage inventory levels. Once the Days of
                                  Supply has recovered, prices will adjust back
                                  to the optimal or 'right' price to ensure
                                  competitiveness and profitability
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {!overrideMargin &&
                        !overrideVolume &&
                        !overrideSupply && (
                          <>
                            <Row className="fs-5 text-center pb-1 pb-3 mt-3">
                              <Col xs={12} className="text-start fs-5">
                                <div>
                                  Utilize the strategy slider to tailor your
                                  pricing model.
                                </div>
                                <Row>
                                  {showEstimates && (
                                    <Col xs={5}>
                                      <div
                                        style={{
                                          padding: "2rem",
                                          border:
                                            "1px solid rgb(238, 238, 238)",
                                          borderRadius: "6px",
                                          backgroundColor: "rgb(248, 248, 248)",
                                          marginTop: "10px",
                                          minHeight: "145px",
                                        }}
                                      >
                                        <div className="fs-5 fw-bold pb-2">
                                          Estimates
                                        </div>
                                        <Row>
                                          <Col xs={3}>Volume:</Col>
                                          <Col xs={9}>
                                            {formatNumber(
                                              expectedData.floor_units
                                            )}

                                            {planMode !== 0 && (
                                              <span>
                                                {" - "}
                                                {formatNumber(
                                                  expectedData.ceiling_units
                                                )}
                                              </span>
                                            )}
                                          </Col>
                                          <Col xs={3}>Price:</Col>
                                          <Col xs={9}>
                                            {formatCurrency(
                                              expectedData.floor_price,
                                              marketPlace.marketPlace
                                            )}
                                            {" - "}
                                            {formatCurrency(
                                              expectedData.ceiling_price,
                                              marketPlace.marketPlace
                                            )}
                                          </Col>
                                          <Col xs={3}>Sales:</Col>
                                          <Col xs={9}>
                                            {formatCurrency(
                                              expectedData.floor_sales,
                                              marketPlace.marketPlace
                                            )}
                                            {" - "}
                                            {formatCurrency(
                                              expectedData.ceiling_sales,
                                              marketPlace.marketPlace
                                            )}
                                          </Col>

                                          <Col xs={12} className="pt-2">
                                            <small>
                                              <em>
                                                ** Based on historical 30 day
                                                sales data.
                                              </em>
                                            </small>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  )}
                                  <Col xs={showEstimates ? 7 : 12}>
                                    <div
                                      style={{
                                        padding: "2rem",
                                        border: "1px solid rgb(238, 238, 238)",
                                        borderRadius: "6px",
                                        backgroundColor: "rgb(248, 248, 248)",
                                        marginTop: "10px",
                                        minHeight: showEstimates
                                          ? "145px"
                                          : "123px",
                                      }}
                                    >
                                      {SLIDER_HELP[planMode + 2].description}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="fs-4 text-left pt-3 pb-5 px-5">
                              <Col className="col-md-5ths">
                                <PlanSlider
                                  planMode={planMode}
                                  setPlanMode={setPlanMode}
                                  disabled={overrideMargin || overrideVolume}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                    </Col>
                  </Row>
                </>
              )}

              {planStrategy.value === "bsr" && (
                <>
                  <Row
                    className="fs-4 text-left pt-5 pb-3 px-3"
                    style={{
                      borderTop: "1px solid #ccc",
                    }}
                  >
                    <Col>
                      <div className="fw-bold">Best Seller Strategy</div>
                    </Col>
                  </Row>
                  <Row className="fs-4 text-center py-3 px-5">
                    <Col xs={4}>
                      <div className="py-3">Category</div>
                      <FormGroupTile
                        formControlClass="text-center"
                        type="formcontrol"
                        formControlType={"center"}
                        prefixSide={"left"}
                        disabled={true}
                        defaultValue={amazonCategory}
                      />
                    </Col>
                    <Col xs={4}>
                      <div className="py-3">Current BSR</div>
                      <FormGroupTile
                        formControlClass="text-center"
                        type="formcontrol"
                        formControlType={"center"}
                        prefixSide={"left"}
                        disabled={true}
                        defaultValue={
                          currentBSR ? formatNumber(currentBSR, null, 0) : 0
                        }
                      />
                    </Col>
                    <Col xs={4}>
                      <div className="py-3">Target BSR</div>
                      <FormGroupTile
                        formControlClass="text-center"
                        type="formcontrol"
                        formControlType={"center"}
                        prefixSide={"left"}
                        handleChildFormElement={(key, value) => {
                          setTargetBSR(value);
                        }}
                        placeholder={`Target BSR`}
                        defaultValue={
                          targetBSR ? formatNumber(targetBSR, null, 0) : 0
                        }
                        style={{
                          border: validationErrors["targetBSR"]
                            ? "1px solid red"
                            : null,
                        }}
                        disabled={targetBSR === null}
                      />

                      {validationErrors["targetBSR"] && (
                        <Row>
                          <Col xs={12}>
                            <span style={{ color: "red" }}>
                              <small>{validationErrors["targetBSR"]}</small>
                            </span>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {!!planStrategy?.programTemplate &&
                planStrategy?.programTemplate !== "CUSTOM" && (
                  <Row
                    className="fs-4 text-left pt-4 pb-3 px-3"
                    style={{
                      borderTop: "1px solid #ccc",
                    }}
                  >
                    <InlineProgramForm
                      size="sm"
                      form={programForm}
                      showHeaderFields={false}
                      programTemplate={planStrategy.programTemplate}
                    />
                  </Row>
                )}

              {planStrategy?.programTemplate === "CUSTOM" && (
                <Row
                  className="fs-4 text-left pt-4 pb-3 px-3"
                  style={{
                    borderTop: "1px solid #ccc",
                  }}
                >
                  <Col className="py-5 text-center">
                    <div className="fs-4 fw-bold">
                      Custom Rules will need to be created once the Pricing Plan
                      has completed enrollment.
                    </div>
                  </Col>
                </Row>
              )}
              {steps.indexOf(step) > 0 && (
                <Row>
                  <Col className="text-center py-3">
                    <Button
                      style={{
                        border: 0,
                        padding: 0,
                        margin: 0,
                        backgroundColor: "#fff",
                        boxShadow: "none",
                        borderRadius: "0px",
                        color: trellisPalette[0],
                      }}
                      className="fs-3"
                      onClick={() => prevStep()}
                    >
                      &lt;&lt; Back
                    </Button>
                  </Col>
                </Row>
              )}
              {isLoading && (
                <Row className="fs-4 text-center py-3 px-5">
                  <Col>
                    <div className="p-4 mx-4 alert" style={{ color: "#000" }}>
                      Pricing data for your selected products is being fetched.
                    </div>
                  </Col>
                </Row>
              )}
            </>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row style={{ width: "100%" }} className="py-3">
          <Col xs={3}>&nbsp;</Col>
          <Col xs={step === "done" ? 6 : 3} className="mx-auto text-cetner">
            <LogoutButton
              key="CloseBtn"
              title={`Close`}
              onClick={() => setShowEnroll(false)}
            />
          </Col>

          {step !== "done" && (
            <Col xs={3} className="mx-auto text-center">
              {step === "group" && (
                <LogoutButton
                  key="NextBtn"
                  title={`Next`}
                  onClick={() => nextStep()}
                  disabled={false}
                />
              )}
              {step === "enroll" && (
                <LogoutButton
                  key="SaveBtn"
                  title={`Enroll Product`}
                  disabled={
                    disableEnroll ||
                    (planStrategy.value === "competitor" &&
                      competitorList.length === 0)
                  }
                  onClick={() => enrollPlan()}
                />
              )}
              {step === "confirm" && (
                <LogoutButton
                  key="SaveBtn"
                  title={`Confirm Enrollment`}
                  onClick={() => confirmPlan()}
                  disabled={false}
                />
              )}
            </Col>
          )}
          <Col xs={3}>&nbsp;</Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default PricingPlanForm;
