import React, { useState } from "react";
import { InputGroup, Row, Col } from "react-bootstrap";
import { FaGear } from "react-icons/fa6";
import { trellisPalette } from "components/custom/analytics/palettes";
import FormGroupTile from "../basic/FormGroupTile";
import CenteredModal from "../basic/CenteredModal";
import { toTitleCase } from "utils/formatText";
import { ENTITY_LABEL_MAP } from "views/merchandise/tables/metricsByEntity";
import { groupBy } from "utils/groupBy";
import { PrimaryButton } from "../basic/Button";
import { useMediaQuery } from "@react-hook/media-query";

const metricSelectFilterOptions = [
  { value: "all", label: "All Metrics" },
  { value: "merchandising", label: "Merchandising Metrics" },
  { value: "advertising", label: "Advertising Metrics" },
  { value: "calculated", label: "Calculated Metrics" },
  { value: "configuration", label: "Configuration Metrics" },
];

const AdvancedColumnSelector = ({
  tableId,
  tableEntityType = "product",
  columnOptions,
  onMetricChange,
  groupMetrics = true,
}) => {
  const [search, setSearch] = useState("");
  const [metricSelectFilter, setMetricSelectFilter] = useState(
    metricSelectFilterOptions[0]
  );
  const [showModal, setShowModal] = useState(false);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);

  const smallViewportWidth = useMediaQuery("(max-width:1750px)");

  const metricsByGrouping = groupBy(columnOptions, ({ grouping }) =>
    groupMetrics ? grouping : undefined
  );

  return (
    <>
      <FaGear
        className="fs-3 mt-1 me-3 p-relative"
        onClick={() => setShowModal(!showModal)}
        style={{ cursor: "pointer" }}
      />
      {showModal && (
        <CenteredModal
          show={showModal}
          closeModal={() => setShowModal(false)}
          title={`Select ${
            ENTITY_LABEL_MAP[tableEntityType] ?? ""
          } Table Metrics`}
          dialogClassName={smallViewportWidth ? "modal-90w" : "modal-60w"}
        >
          <Row className="border-bottom mx-2 pb-2">
            <Col xs={12} md={5}>
              <InputGroup className="mb-3 text-start px-3">
                <input
                  type="text"
                  className="searchInput w-100"
                  style={{
                    textAlign: "left",
                    padding: "10px 15px",
                    border: "1px solid rgb(204, 204, 204)",
                    borderRadius: "4px",
                    color: "rgb(21, 39, 72)",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                />
              </InputGroup>
            </Col>
            <Col xs={12} md={5}>
              <FormGroupTile
                className={"px-3"}
                type="select"
                multi={false}
                isClearable={false}
                selectClasses={"filter-widget-select"}
                closeOnSelect={true}
                handleChildFormElement={(key, value) => {
                  setMetricSelectFilter(value);
                }}
                defaultValue={metricSelectFilter}
                options={metricSelectFilterOptions}
              />
            </Col>
            <Col
              xs={12}
              md={2}
              className="d-flex align-items-center pb-3 justify-content-center"
            >
              <PrimaryButton
                nowrap={true}
                noShadow={true}
                onClick={() => {
                  setShowResetConfirmation(true);
                }}
                style={{ transform: "none" }}
              >
                Reset
              </PrimaryButton>
            </Col>
          </Row>

          <Row>
            {showResetConfirmation ? (
              <Col xs={12} style={{ height: "60vh", overflowY: "auto" }}>
                <p className="text-center w-75 mx-auto pt-5 pb-2">
                  Are you sure you want to reset this table's column preferences
                  to the default configuration? All custom selections will be
                  removed.
                </p>
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center gap-5 pb-3"
                  >
                    <PrimaryButton
                      noShadow={true}
                      nowrap={true}
                      onClick={() => {
                        setShowResetConfirmation(false);
                      }}
                      style={{ transform: "none" }}
                    >
                      Cancel
                    </PrimaryButton>
                    <PrimaryButton
                      noShadow={true}
                      nowrap={true}
                      onClick={() => {
                        const updatedColumnOptions = JSON.parse(
                          localStorage.columnOptions
                        );

                        delete updatedColumnOptions[tableId];

                        localStorage.setItem(
                          "columnOptions",
                          JSON.stringify(updatedColumnOptions)
                        );
                        onMetricChange(updatedColumnOptions, true);
                        setShowResetConfirmation(false);
                      }}
                      style={{ transform: "none" }}
                    >
                      Confirm
                    </PrimaryButton>
                  </Col>
                </Row>
              </Col>
            ) : (
              <>
                <Col
                  xs={12}
                  lg={8}
                  style={{ height: "60vh", overflowY: "auto" }}
                  className="border-end"
                >
                  <p>
                    {Object.entries(metricsByGrouping)
                      ?.map((group) => ({
                        title: group[0],
                        metrics: group[1]?.filter(
                          (metric) => metric.id !== "seperator"
                        ),
                      }))
                      ?.filter((option) => {
                        if (metricSelectFilter.value !== "all") {
                          return option.title === metricSelectFilter?.value;
                        }
                        return true;
                      })
                      ?.map((group, i) => {
                        const { title, metrics: columnOptions } = group;

                        return (
                          <Row>
                            <Col
                              xs={12}
                              className={`py-3 ${
                                i !==
                                Object.values(metricsByGrouping)?.filter(
                                  (metrics) => metrics?.length
                                )?.length -
                                  1
                                  ? "border-bottom"
                                  : ""
                              }`}
                            >
                              {title.toLowerCase() !== "undefined" && (
                                <h4 className="px-3 fs-4 py-0 my-0 fw-bold">
                                  {toTitleCase(title)}
                                </h4>
                              )}

                              <div className="menu">
                                {columnOptions
                                  ?.filter((option) => {
                                    if (search) {
                                      return (
                                        `${title} ${option.id} ${option.label} ${option.toolTip}`
                                          ?.toLowerCase()
                                          ?.includes(search.toLowerCase()) &&
                                        option.id !== "seperator"
                                      );
                                    }

                                    return option.id !== "seperator";
                                  })
                                  ?.sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  )
                                  ?.map((metric, i) => {
                                    const { id, label, checked, toolTip } =
                                      metric;

                                    return (
                                      <div
                                        key={`${id}-${i}`}
                                        className="column-option fs-4"
                                      >
                                        <div
                                          key={id}
                                          role="menuitem"
                                          className="py-1"
                                        >
                                          <label
                                            style={{
                                              marginLeft: "1rem",
                                              fontWeight: "normal",
                                            }}
                                            className="d-flex"
                                          >
                                            <div className="pt-2">
                                              <input
                                                type="checkbox"
                                                style={{
                                                  height: "unset",
                                                  marginRight: "1rem",
                                                  accentColor:
                                                    trellisPalette[13],
                                                  cursor: "pointer",
                                                }}
                                                className="d-block"
                                                onChange={(e) => {
                                                  e.stopPropagation();
                                                  onMetricChange({
                                                    [id]: e.target.checked,
                                                  });
                                                }}
                                                checked={checked}
                                              />
                                            </div>
                                            <div>
                                              <span style={{ fontWeight: 500 }}>
                                                {label.charAt(0).toUpperCase() +
                                                  label.slice(1)}
                                              </span>
                                              {toolTip ? (
                                                <p className="text-muted fs-5 my-0">
                                                  {toolTip}
                                                </p>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </Col>

                            {/* END */}
                          </Row>
                        );
                      })}
                  </p>
                </Col>
                <Col xs={12} lg={4} className="py-3">
                  <h4 className="px-3 fs-4 py-0 my-0 fw-bold">
                    Selected Metrics
                  </h4>
                  <div className="menu">
                    {columnOptions
                      ?.filter(
                        (metric) => metric.checked && metric.id !== "seperator"
                      )
                      ?.map((metric, i) => {
                        const { id, checked, label } = metric;
                        return (
                          <div
                            key={`${id}-${i}-selected`}
                            className="column-option fs-4"
                          >
                            <div key={id} role="menuitem" className="py-1">
                              <label
                                style={{
                                  marginLeft: "1rem",
                                  fontWeight: "normal",
                                }}
                                className="d-flex"
                              >
                                <input
                                  type="checkbox"
                                  style={{
                                    height: "unset",
                                    marginRight: "1rem",
                                    accentColor: trellisPalette[13],
                                    cursor: "pointer",
                                  }}
                                  className="d-block"
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    onMetricChange({
                                      [id]: e.target.checked,
                                    });
                                  }}
                                  checked={checked}
                                />

                                <span style={{ fontWeight: 500 }}>
                                  {label.charAt(0).toUpperCase() +
                                    label.slice(1)}
                                </span>
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-center border-top pt-4">
              <PrimaryButton
                onClick={() => setShowModal(false)}
                style={{ transform: "none", marginBottom: "-1.5rem" }}
                noShadow={true}
              >
                Close
              </PrimaryButton>
            </Col>
          </Row>
        </CenteredModal>
      )}
    </>
  );
};

export default AdvancedColumnSelector;
