import { trellisPalette } from "components/custom/analytics/palettes";
import React from "react";

const SelectionCell = ({
  row,
  value,
  valueAccessor = "id",
  selected,
  setSelected,
  selectionKey = null,
}) => {
  if (!value) {
    value = valueAccessor ? row._original[valueAccessor] : row._original.id;
  }

  const onCheckChange = (checked) => {
    const value = row._original[selectionKey];
    if (checked) {
      setSelected([...selected, value]);
    } else {
      setSelected(selected.filter((entry) => entry !== value));
    }
  };

  return (
    <div
      style={{
        cursor: "pointer",
      }}
      className="d-flex mt-2"
    >
      <div
        style={{
          paddingLeft: "2px",
        }}
      >
        <input
          type="checkbox"
          checked={selected && selected?.includes(row._original[selectionKey])}
          onChange={(e) => {
            onCheckChange(e.target.checked);
          }}
          style={{ accentColor: trellisPalette[2], cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default SelectionCell;
