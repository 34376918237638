import { useState, useEffect } from "react";
import React from "react";
import { FieldWrapper } from "./fields";
import Tags from "../Tags";
import ToolTips, { AD_COMPETITOR_BRAND_TOOLTIP } from "utils/toolTips";
import {
  AD_PRODUCT_DESCRIBES_TOOLTIP,
  AD_BRAND_TOOLTIP,
  AD_ATTRIBUTES_TOOLTIP,
} from "utils/toolTips";
import { Row, Col, Modal, Alert } from "react-bootstrap";

import { CancelButton } from "./buttons";
import { LinkButton } from "../../basic/Button";
import api from "utils/api";
import useDebounce from "../../../../hooks/useDebounce";
import TextField from "@material-ui/core/TextField";
import { AD_TYPES } from "./constants";
import { Button, ButtonGroup } from "@material-ui/core";

const AttributeSelector = ({
  state,
  errors,
  fieldsToValidate,
  channel,
  onChange,
  funnelTargeting,
  review,
  isModal,
}) => {
  const attributeDropDown = [
    { value: "color", label: "Color" },
    { value: "size", label: "Size" },
    { value: "collection", label: "Collection" },
    { value: "material", label: "Material" },
    { value: "flavor", label: "Flavor" },
    { value: "other", label: "Other" },
  ];

  const [showFields, setShowFields] = useState(false);
  const [discoveryKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");
  const debounceSearch = useDebounce(keywordSearch, 1000);
  const [selectedAttribute, setSelectedAttribute] = useState(
    attributeDropDown[0]
  );
  const [attributeData, setAttributeData] = useState({
    color: [],
    size: [],
    collection: [],
    flavor: [],
    material: [],
    other: [],
  });
  const [productTypes, setProductType] = useState([]);
  const [brands, setBrands] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [adId, setAdId] = useState(null);

  // Modal State
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const addProductKeyword = (kw) => {
    const productKeys = state.filledProductKeys;
    productKeys.push(kw);

    onChange({ filledProductKeys: productKeys });
  };

  const changeAttribute = (attribute) => {
    setSelectedAttribute(attribute);
  };

  const getAttributes = async (product_ids) => {
    if (channel === "google") {
      const { data } = await api.get("/google/product/attributes", {
        product_ids: product_ids.join(","),
      });
      if (!data || !funnelTargeting) return;

      const dataFromServer = { ...data };
      const dataLocal = {
        product_types: state.filledProductKeys,
        brands: state.filledProductBrands,
        competitorBrands: state.filledCompetitorBrands,
        attributes: state.filteredAttributes,
      };

      const brands = [
        ...new Set([...dataFromServer.brands, ...dataLocal.brands]),
      ];
      const competitorBrands = [
        ...new Set([
          ...dataFromServer.competitorBrands,
          ...dataLocal.competitorBrands,
        ]),
      ];
      const productTypes = [
        ...new Set([
          ...dataFromServer.product_types,
          ...dataLocal.product_types,
        ]),
      ];
      const attributes = {};

      Object.keys(dataFromServer.attributes).forEach((key) => {
        const svrAttr = dataFromServer.attributes[key];
        const lclAttr = dataLocal.attributes[key];
        const values = [...new Set([...svrAttr, ...lclAttr])];
        attributes[key] = values;
      });

      const partialState = {
        filledProductKeys: productTypes,
        filledProductBrands: brands,
        filledCompetitorBrands: competitorBrands,
        filteredAttributes: attributes,
      };

      setAttributeData(attributes);
      setProductType(productTypes);
      setBrands(brands);

      onChange(partialState);
      return;
    }
    // Handle attributes in Amazon
    let partialLocalState = {
      filledProductKeys: state.filledProductKeys,
      filledProductBrands: state.filledProductBrands,
      filledCompetitorBrands: state.filledCompetitorBrands,
      filteredAttributes: state.filteredAttributes,
    };

    setAttributeData(state.filteredAttributes);
    setProductType(state.filledProductKeys);
    setBrands(state.filledProductBrands);

    onChange(partialLocalState);
  };

  const checkAttributeInPrdType = (attributes, productTypes) => {
    let ptMono = [];
    productTypes.forEach((p) => {
      ptMono = ptMono.concat(p.split(" "));
    });
    ptMono = [...new Set(ptMono)];

    for (let i = 0; i < Object.keys(attributes).length; i++) {
      const k = Object.keys(attributes)[i];
      let values = [];
      attributes[k].forEach(
        (v) => (values = values.concat(v.toLowerCase().split(" ")))
      );
      values = [...new Set(values)];

      values.forEach((v) => {});

      const both = [...new Set([...ptMono, ...values])];
      if (both.length < values.length + ptMono.length) {
        return false;
      }
    }

    return true;
  };

  const changeProductType = (pt) => {
    let pTypes = [];
    pt.forEach((x) => pTypes.push(x.toLowerCase()));
    pTypes = [...new Set(pTypes)];

    setProductType(pTypes);
    onChange({ filledProductKeys: pTypes });

    if (!checkAttributeInPrdType(attributeData, pTypes)) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const changeBrand = (brd) => {
    let Brds = [];
    brd.forEach((x) => Brds.push(x.toLowerCase()));
    Brds = [...new Set(Brds)];

    const partialState = {
      filledProductBrands: Brds,
    };

    setBrands(Brds);
    onChange(partialState);

    if (!checkAttributeInPrdType(attributeData, Brds)) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const changeCompetitorBrand = (brandList) => {
    let competitorBrands = [];
    brandList.forEach((brand) => competitorBrands.push(brand.toLowerCase()));
    competitorBrands = [...new Set(competitorBrands)];

    const partialState = {
      filledCompetitorBrands: competitorBrands,
    };

    onChange(partialState);
  };

  const changeAttributeData = (attr, v) => {
    const attrData = { ...attributeData };
    attrData[attr] = v;

    setAttributeData(attrData);
    onChange({ filteredAttributes: attrData });

    if (
      !checkAttributeInPrdType(attrData, productTypes) ||
      !checkAttributeInPrdType(attrData, brands)
    ) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  useEffect(() => {
    if (
      ((state.product && state.product.length > 0) ||
        state.creativeProduct?.length ||
        state.landingPageProduct?.length ||
        state.storeSpotlight) &&
      ((channel === "google" &&
        state.adType !== AD_TYPES.GOOGLE_STORE_AD.value) ||
        funnelTargeting)
    ) {
      setShowFields(true);

      getAttributes(state.product);
    } else {
      setShowFields(false);
    }

    setAdId(state.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.product, funnelTargeting]);

  useEffect(() => {
    if (!discoveryKeywords) {
      return;
    }

    if (keywordSearch.length === 0) {
      setFilteredKeywords(discoveryKeywords);
    }

    setFilteredKeywords(
      discoveryKeywords.filter((kw) => kw.indexOf(keywordSearch) > -1)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  if (
    !funnelTargeting &&
    (channel !== "google" || state.adType === AD_TYPES.GOOGLE_STORE_AD.value)
  ) {
    return <div></div>;
  }
  if (!showFields) return <div></div>;

  const getCompetitorBrands = () => {
    return (
      <Col xs={12} lg={6} style={{ padding: "1rem .7rem " }}>
        <FieldWrapper style={{ marginBottom: "1rem" }}>
          <Tags
            name={
              <h6
                className="title"
                style={{
                  color: "#73706E",
                  fontWeight: "700",
                  margin: "1rem 0",
                  textTransform: "none",
                }}
              >
                Competitor Brands
                <ToolTips
                  toolTip={AD_COMPETITOR_BRAND_TOOLTIP}
                  position="top"
                  id={"AD_COMPETITOR_BRAND_TOOLTIP"}
                />
              </h6>
            }
            filledTags={state.filledCompetitorBrands}
            saveFilledTags={(b) => changeCompetitorBrand(b)}
            placeholder={``}
            disabled={(adId || review) && !isModal}
            reviewDisplay={review}
          ></Tags>
          <Row hidden={review}>
            <div
              style={{
                paddingTop: "1rem",
                fontSize: "12px",
                paddingLeft: "1.6rem",
              }}
            >
              <span>e.g. Nike, Apple, Dell</span>
            </div>
            {errors.filledCompetitorBrands &&
              fieldsToValidate?.includes("filledCompetitorBrands") && (
                <small className="text-danger pt-2 pl-4">
                  {errors.filledCompetitorBrands &&
                    errors.filledCompetitorBrands}
                </small>
              )}
            {errors.brandTargetCollision &&
              fieldsToValidate?.includes("brandTargetCollision") && (
                <small className="text-danger pt-2 pl-4">
                  {errors.brandTargetCollision && errors.brandTargetCollision}
                </small>
              )}
          </Row>
        </FieldWrapper>
      </Col>
    );
  };

  const getProductTypes = () => {
    return (
      <Col
        xs={12}
        lg={state.canHaveChildren ? 12 : 6}
        hidden={review && state.filledProductKeys?.length === 0}
        style={{ paddingTop: "1rem" }}
      >
        <FieldWrapper style={{ marginBottom: "1rem" }}>
          <Tags
            name={
              <h6
                className="title"
                style={{
                  color: "#73706E",
                  fontWeight: "700",
                  margin: "1rem 0",
                  textTransform: "none",
                }}
              >
                Product Type
                <ToolTips
                  toolTip={AD_PRODUCT_DESCRIBES_TOOLTIP}
                  position="top"
                  id={"AD_PRODUCT_DESCRIBES_TOOLTIP"}
                />
              </h6>
            }
            filledTags={state.filledProductKeys}
            saveFilledTags={(pt) => changeProductType(pt)}
            placeholder={``}
            disabled={(adId || review) && !isModal}
            reviewDisplay={review}
          ></Tags>
          <Row hidden={review}>
            <div style={{ paddingTop: "1rem", paddingLeft: "1.6rem" }}>
              <span style={{ fontSize: "12px" }}>
                e.g. Coffee Cup, Coffee Mug
              </span>
            </div>
          </Row>
          {errors.filledProductKeys &&
            fieldsToValidate?.includes("filledProductKeys") && (
              <small className="text-danger pt-3">
                {errors.filledProductKeys && errors.filledProductKeys}
              </small>
            )}
        </FieldWrapper>
      </Col>
    );
  };

  const getAttributeTitle = () => {
    return (
      <Row>
        <Col
          xs={12}
          style={{
            padding: review
              ? "2rem .7rem 1rem"
              : state.canHaveChildren
              ? "0 .8rem"
              : "0 2rem",
            marginTop: "-1rem",
          }}
          hidden={!review}
        >
          <h6
            className="title"
            style={{
              color: "#73706E",
              fontWeight: "700",
              padding: "2rem 0 1rem",
              textTransform: "none",
            }}
            hidden={Object.keys(attributeData).every(
              (key) => attributeData[key]?.length === 0
            )}
          >
            Product Attributes
            <ToolTips
              toolTip={AD_ATTRIBUTES_TOOLTIP}
              position="top"
              id={"AD_ATTRIBUTES_TOOLTIP"}
            />
          </h6>
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            {attributeDropDown.map((attribute) => {
              if (
                !attributeData[attribute.value] ||
                attributeData[attribute.value]?.length < 1
              ) {
                return null;
              }
              return (
                <>
                  <div style={{ flexDirection: "column", display: "flex" }}>
                    <span
                      style={{
                        fontSize: "1.35rem",
                        marginBottom: "0.5rem",
                        fontWeight: "600",
                        color: "#73706E",
                      }}
                    >
                      {attribute.label}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "1rem",
                      }}
                    >
                      {attributeData[attribute.value].map((subAttr) => (
                        <div
                          className="fs-standard"
                          style={{
                            background: "#ddd",
                            borderRadius: "2px",
                            border: "1px solid #777",
                            color: "#777",
                            margin: "3px 5px 5px 0",
                            padding: "2px 12px",
                          }}
                        >
                          {subAttr}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Col>
        <Col
          xs={12}
          style={{ padding: "2rem", marginTop: "1rem" }}
          hidden={review}
        >
          <ButtonGroup>
            {attributeDropDown.map((attribute) => (
              <Button
                onClick={() => {
                  changeAttribute(attribute.value);
                  setSelectedAttribute(attribute);
                }}
                variant={
                  selectedAttribute.value === attribute.value
                    ? `contained`
                    : null
                }
                color={
                  selectedAttribute.value === attribute.value
                    ? `secondary`
                    : null
                }
                style={{ fontSize: "1.1rem" }}
              >
                {attribute.label}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
        <Col
          xs={12}
          style={{ padding: "0 2rem", marginTop: "-1rem" }}
          hidden={review}
        >
          <Tags
            name={""}
            filledTags={attributeData[selectedAttribute.value]}
            saveFilledTags={(v) =>
              changeAttributeData(selectedAttribute.value, v)
            }
            placeholder={``}
            disabled={(adId || review) && !isModal}
            reviewDisplay={review}
          ></Tags>
          <div style={{ padding: "1rem 0 2rem" }} hidden={review}>
            {selectedAttribute.value !== "other" && (
              <span style={{ fontSize: "12px" }}>
                Give us a list of your {selectedAttribute.label.toLowerCase()}
              </span>
            )}
            {selectedAttribute.value === "other" && (
              <span style={{ fontSize: "12px" }}>
                Tell us about your generic attributes
              </span>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div style={{ padding: "0 2rem" }}>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              {showWarning && (
                <Col xs={12} md={12}>
                  <Alert
                    variant="warning"
                    style={{
                      backgroundColor: "#c43066",
                      color: "#ffffff",
                      fontWeight: 400,
                    }}
                  >
                    <p style={{ color: "#fff" }}>
                      <strong>Warning!</strong>
                    </p>
                    <p style={{ color: "#fff" }}>
                      It looks like the keywords you are using to describe your
                      product or your brand names contain one or more attribute
                      name. We recommend that you describe your product using
                      generic terms to generate better results.
                    </p>
                  </Alert>
                </Col>
              )}
              {state.canHaveChildren
                ? getCompetitorBrands()
                : getProductTypes()}
              <Col xs={12} lg={6} style={{ padding: "1rem 4.3rem 0 2rem" }}>
                <FieldWrapper style={{ marginBottom: "1rem" }}>
                  <Tags
                    name={
                      <h6
                        className="title"
                        style={{
                          color: "#73706E",
                          fontWeight: "700",
                          margin: "1rem 0",
                          textTransform: "none",
                        }}
                      >
                        {state.canHaveChildren
                          ? "Home Brand"
                          : "Related Brands"}
                        <ToolTips
                          toolTip={AD_BRAND_TOOLTIP}
                          position="top"
                          id={"AD_BRAND_TOOLTIP"}
                        />
                      </h6>
                    }
                    filledTags={state.filledProductBrands}
                    saveFilledTags={(b) => changeBrand(b)}
                    placeholder={``}
                    disabled={(adId || review) && !isModal}
                    reviewDisplay={review}
                  ></Tags>
                  <Row hidden={review}>
                    <div
                      style={{
                        paddingTop: "1rem",
                        fontSize: "12px",
                        paddingLeft: "1.6rem",
                      }}
                    >
                      <span>e.g. Polo, Thrive, xRacer</span>
                    </div>
                    {errors.filledProductBrands &&
                      fieldsToValidate?.includes("filledProductBrands") && (
                        <small className="text-danger pt-2 pl-4">
                          {errors.filledProductBrands &&
                            errors.filledProductBrands}
                        </small>
                      )}
                  </Row>
                </FieldWrapper>
              </Col>
            </Row>
          </Col>
          {state.canHaveChildren && getProductTypes()}
        </Row>
        {getAttributeTitle()}
      </div>
      <Modal show={show} onHide={handleClose} style={{ marginTop: "10vh" }}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={8}>Choose one or multiple keywords</Col>
            <Col xs={4} className="text-end" style={{ padding: "10px" }}>
              <TextField
                id="keyword-search"
                onChange={(e) => setKeywordSearch(e.target.value)}
                placeholder="Filter Keywords"
                style={{ width: "200px" }}
              />
            </Col>
          </Row>
          <div
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Row>
              {filteredKeywords.map((kw, i) => (
                <Col xs={4} key={i} style={{ paddingTop: "10px" }}>
                  <LinkButton onClick={() => addProductKeyword(kw)}>
                    <span>{kw}</span>
                  </LinkButton>
                </Col>
              ))}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={handleClose}>Close</CancelButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttributeSelector;
