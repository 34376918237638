import { PrimaryButton, SecondaryButton } from "components/core/basic/Button";
import SummaryCard from "components/core/blocks/SummaryCard";
import {
  ProgramContextProvider,
  useProgramContext,
} from "components/custom/programs/ProgramContext";
import ProgramsForm from "components/custom/programs/ProgramsForm";
import { PROGRAM_DEFAULTS } from "components/custom/programs/constants";
import useQueryParams from "hooks/useQueryParams";
import React from "react";
import { Col, Container as Grid, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import api from "utils/api";
import { toTitleCase } from "utils/formatText";

const ProgramCreate = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { engine } = useProgramContext();
  console.log("engine context", engine);

  // Update default actions and debug undefined for scope
  const { handleSubmit, ...form } = useForm({
    shouldUnregister: false,
    /* prettier-ignore */
    defaultValues: PROGRAM_DEFAULTS[engine],
  });

  const createProgram = useMutation(
    async (data) => {
      const { name, start_date, end_date, ...spec } = data;
      await api.post("/programs/", {
        name,
        start_date,
        end_date,
        spec,
        status: "scheduled",
        engine: engine ?? "pricing",
      });
    },
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries("programs");

        if (["bidding", "budget"].includes(engine)) {
          history.goBack();
          return;
        }
        history.push("/user/programs");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const onInvalid = (errors) => {
    console.log("errors", errors);
  };

  return (
    <Grid fluid style={{ padding: "3rem", minHeight: "calc(100vh - 150px)" }}>
      <SummaryCard
        mobileStyle
        title={`New ${toTitleCase(engine ?? "Pricing")} Rules`}
      >
        <Row>
          <Col xs={12} lg={8} className="m-auto">
            <ProgramsForm {...form} />
            <Row className="mt-4">
              <Col md={12} className="d-flex justify-content-end">
                <div style={{ marginRight: "2rem" }}>
                  <SecondaryButton
                    nowrap
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                </div>
                <div>
                  <PrimaryButton
                    disabled={
                      createProgram.isLoading || form.formState.isSubmitting
                    }
                    onClick={handleSubmit(createProgram.mutate, onInvalid)}
                    nowrap
                  >
                    Create
                  </PrimaryButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </SummaryCard>
    </Grid>
  );
};

const ProgramCreateView = () => {
  const [engine] = useQueryParams("engine", "pricing");
  console.log("engine param", engine);
  return (
    <ProgramContextProvider engine={engine}>
      <ProgramCreate />
    </ProgramContextProvider>
  );
};

export default ProgramCreateView;
