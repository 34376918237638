const PERIODS = [
  {
    id: "last7",
    name: "Last 7 days",
    periods: 7,
    group: "day",
  },
  {
    id: "last14",
    name: "Last 14 days",
    periods: 14,
    group: "day",
  },
  {
    id: "last30",
    name: "Last 30 days",
    periods: 30,
    group: "day",
  },
  {
    id: "month",
    name: "Month to Date",
    group: "day",
  },
  {
    id: "last12Weeks",
    name: "Last 12 weeks",
    periods: 12,
    group: "week",
  },
  {
    id: "last26Weeks",
    name: "Last 26 weeks",
    periods: 26,
    group: "week",
  },
  {
    id: "last6Months",
    name: "Last 6 months",
    periods: 6,
    group: "month",
  },
  {
    id: "last12Months",
    name: "Last 12 months",
    periods: 12,
    group: "month",
  },
  { id: "custom", name: "Custom" },
];

export const COMPARES = [
  {
    id: "mom",
    name: "Previous Period",
  },
  {
    id: "yoy",
    name: "Previous Year",
  },
];

export const GROUPS = [
  {
    id: "day",
    name: "Day",
    periods: PERIODS.filter((p) => p.group === "day" || p.id === "custom"),
  },
  {
    id: "week",
    name: "Week",
    periods: PERIODS.filter((p) => p.group === "week" || p.id === "custom"),
  },
  {
    id: "month",
    name: "Month",
    periods: PERIODS.filter((p) => p.group === "month" || p.id === "custom"),
  },
];

export const DEFAULTS = {
  period: PERIODS[2].id, // last30
  compare: COMPARES[0].id, // mom
  group: GROUPS[0].id, // day
};
