import React, { Component } from "react";
import ToolTips from "utils/toolTips";

class trakGroupTile extends Component {
  render() {
    var statsTextColor = this.props.selected === true ? "#ffffff" : "#73706E";
    var bgColor = this.props.selected === true ? "#62407F" : null;
    var tileShadow =
      this.props.display !== false
        ? "0px 0px 20px rgba(0, 0, 0, 0.1)"
        : "0px 0px 2px rgba(0, 0, 0, 0.1)";
    return (
      <div
        style={{
          backgroundColor: bgColor,
          boxShadow: tileShadow,
          marginTop: "1rem",
          marginBottom: "1rem",
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          borderBottomLeftRadius: "25px",
          borderBottomRightRadius: "25px",
        }}
      >
        <div
          className="header"
          style={{
            borderBottom: "1px solid #eeeeee",
            backgroundColor: bgColor,
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          <h5
            className="title"
            style={{
              paddingTop: "1rem",
              paddingBottom: "2rem",
              textAlign: "center",
              color: statsTextColor,
              fontWeight: "500",
            }}
          >
            {this.props.statsText === "bid optimization quad" ? (
              <div>
                <span>High CR - Low CTR</span>
                <ToolTips
                  toolTip={
                    "Keyword Opportunities Based of Data Mining of Customer Search Term Queries"
                  }
                  position="top"
                  id={"harvestInfo"}
                />
              </div>
            ) : null}
            {this.props.statsText === "OPTIMIZE" ? (
              <div>
                <span>High CR - High CTR</span>
                <ToolTips
                  toolTip={
                    "Bid Adjustment Opportunities for High Performing Keywords"
                  }
                  position="top"
                  id={"optimizeInfo"}
                />
              </div>
            ) : null}
            {this.props.statsText === "DISCOVER" ? (
              <div>
                <span>Low CR - Low CTR</span>
                <ToolTips
                  toolTip={
                    "Keyword Ideas Based on Trellis Keyword Discovery Alogrithm"
                  }
                  position="top"
                  id={"discoverInfo"}
                />
              </div>
            ) : null}
            {this.props.statsText === "SAVE" ? (
              <div>
                <span>Low CR - High CTR</span>
                <ToolTips
                  toolTip={
                    "Bid Optimization Opportunities on Low Converting and High Click Through Keywords"
                  }
                  position="top"
                  id={"saveInfo"}
                />
              </div>
            ) : null}
            {this.props.statsText === "BID OPTIMIZATION" ? (
              <div>
                <span>
                  Bid Optimization <br /> Opportunities
                </span>
              </div>
            ) : null}
            {this.props.statsText === "NEW KEYWORDS" ? (
              <div>
                <span>
                  New Keyword <br /> Opportunities
                </span>
              </div>
            ) : null}
          </h5>
        </div>
        <div
          className="header"
          style={{
            backgroundColor: "#ffffff",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
          }}
        >
          <h2
            className="title"
            style={{
              paddingTop: "1rem",
              paddingBottom: "2rem",
              textAlign: "center",
              color: "#403E3D",
              fontWeight: "600",
            }}
          >
            {this.props.statsValue}
            <span
              style={{ fontWeight: "700", fontSize: "10px", color: "#73706E" }}
            >
              {" "}
              keywords
            </span>
          </h2>
        </div>
      </div>
    );
  }
}

export default trakGroupTile;
