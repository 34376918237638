import React, { useState, useMemo } from "react";
import { useMutation } from "react-query";
import { Row, Col } from "react-bootstrap";
import api from "utils/api";
import { AD_TYPES } from "../AdPlan/constants";
import logo from "assets/images/logo/Trellis_Logomark_color.png";

const CreativePreview = ({
  state,
  marketPlace,
  asin,
  showCreativePreview,
  accordionLayout,
}) => {
  const [html, setHtml] = useState("");
  const [previewSizeFilter, setPreviewSizeFilter] = useState({
    width: 970,
    height: 250,
  });
  const [isMobile, setIsMobile] = useState(false);

  const previewSizeOptions = [
    // Tuples of [width, height, isMobile]
    { value: [970, 250, false], label: "970 x 250" },
    { value: [245, 250, false], label: "245 x 250" },
    { value: [650, 130, false], label: "650 x 130" },
    { value: [414, 125, true], label: "414 x 125" },
    { value: [320, 50, false], label: "320 x 50" },
    { value: [160, 600, false], label: "160 x 600" },
    { value: [300, 250, false], label: "300 x 250" },
    { value: [728, 90, false], label: "728 x 90" },
    { value: [300, 600, false], label: "300 x 600" },
    { value: [980, 55, false], label: "980 x 55" },
  ];

  const formatCreativePreviewBody = () => {
    let creativeBody = {
      marketplace: marketPlace,
      creative_preview: {
        creative: {},
        previewConfiguration: {
          size: {
            width: parseInt(previewSizeFilter?.width) ?? 970,
            height: parseInt(previewSizeFilter?.height) ?? 250,
          },
          products: [
            {
              asin: asin,
            },
          ],
          isMobile: isMobile,
          isOnAmazon: true,
        },
      },
    };

    let creativeSetup = { properties: {} };
    // Creative type
    if (state.videoDetails?.asset_id) {
      creativeSetup["creativeType"] = "VIDEO";
    }

    if (state.lifestyleImageDetails?.asset_id) {
      creativeSetup["creativeType"] = "IMAGE";
    }

    // Headline
    if (state.headline) {
      creativeSetup["properties"]["headline"] = state.headline;
    }

    // Logo
    if (state.logoDetails?.asset_id) {
      creativeSetup["properties"]["brandLogo"] = {
        assetId: state?.logoDetails?.asset_id,
        assetVersion: state?.logoDetails?.version,
      };
    }

    // Lifestyle image
    if (state.lifestyleImageDetails?.asset_id) {
      // Rectangle lifestyle image
      creativeSetup["properties"]["rectCustomImage"] = {
        assetId: state?.lifestyleImageDetails?.asset_id,
        assetVersion: state?.lifestyleImageDetails?.version,
        croppingCoordinates: state.sdRectangleImageCroppingCoordinates,
      };
      // Square lifestyle image
      creativeSetup["properties"]["squareCustomImage"] = {
        assetId: state?.lifestyleImageDetails?.asset_id,
        assetVersion: state?.lifestyleImageDetails?.version,
        croppingCoordinates: state.sdSquareImageCroppingCoordinates,
      };
    }

    creativeBody["creative_preview"]["creative"] = creativeSetup;
    return creativeBody;
  };

  const getCreativePreviewMarkup = useMutation(
    async (productData) => {
      return await api.post("/gvads/creativespreview/", productData);
    },
    {
      enabled: state?.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value,
      default: [],
      onSuccess: (res) => {
        setHtml(res.data);
      },
    }
  );

  useMemo(() => {
    if (!state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
      return;
    }
    getCreativePreviewMarkup.mutate(formatCreativePreviewBody());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.lifestyleImageDetails?.asset_id,
    state.logoDetails?.asset_id,
    state.videoDetails?.asset_id,
    state.headline,
    state.sdSquareImageCroppingCoordinates,
    state.sdRectangleImageCroppingCoordinates,
    asin,
    previewSizeFilter,
  ]);

  return (
    <Row>
      <Col
        xs={12}
        className={`d-flex align-items-center ${
          accordionLayout ? "justify-content-end" : "justify-content-between"
        } pb-4`}
      >
        {!accordionLayout && (
          <h6
            className="title fs-4"
            style={{
              color: "#73706E",
              fontWeight: "700",
              textTransform: "none",
            }}
          >
            Creative Preview
          </h6>
        )}
        <div className="d-flex">
          <p
            style={{
              fontSize: "14px",
              fontWeight: 500,
              margin: "auto 1rem auto 0",
            }}
          >
            Preview Size
          </p>
          <select
            onChange={(e) => {
              setPreviewSizeFilter({
                width: e.target.value?.split(",")?.[0],
                height: e.target.value?.split(",")?.[1],
              });
              setIsMobile(e.target.value?.split(",")?.[2]);
            }}
            value={previewSizeOptions?.find(
              (o) =>
                o.value?.[1] === previewSizeFilter?.height &&
                o.value?.[0] === previewSizeFilter?.width
            )}
            className="p-2 border rounded fs-5"
            style={{ marginRight: "-10px" }}
          >
            {previewSizeOptions.map((type, index) => (
              <option key={index} value={type?.value}>
                {type?.label}
              </option>
            ))}
          </select>
        </div>
      </Col>
      <Col xs={12}>
        <div className="d-flex justify-content-center">
          {getCreativePreviewMarkup.isLoading && showCreativePreview ? (
            <div className="pb-5">
              <img
                src={logo}
                alt="Loading..."
                className={"rotate"}
                width="80"
              />
            </div>
          ) : (
            <iframe
              srcDoc={html}
              width={parseInt(previewSizeFilter?.width) ?? 970}
              height={parseInt(previewSizeFilter?.height) ?? 250}
              title="Sponsored Display Creatives Preview"
            ></iframe>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default CreativePreview;
