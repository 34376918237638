import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col } from "react-bootstrap";
import { formatCurrency } from "utils/formatNumber";
import PriceTag from "assets/images/icons/price-tag.png";

import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);

const CurveCharts = ({
  optData,
  marketPlace,
  height,
  fromExplorer,
  fullCurves = true,
  price = null,
}) => {
  const [demandCurveOptions, setDemandCurveOptions] = useState(null);
  const [profitCurveOptions, setProfitCurveOptions] = useState(null);

  if (height === undefined) {
    height = 300;
  }

  const getDemandCurveOptions = (pricingData, sliderValue) => {
    const cats = [];
    let maxDemandValue = null;
    const monthlyDemandValues = {};
    let minPrice = null;
    let maxPrice = null;
    const realDemand = [];
    const spLineDemand = [];
    const priceSeries = [];
    let currentPrice = price;
    let currentPriceY = 0;
    let currentPriceX = 0;

    if (!currentPrice) {
      currentPrice = optData.best_price;
    }

    pricingData.forEach((el, i) => {
      if (el.num_days_at_price > 0) {
        if (minPrice === null || el.price < minPrice) {
          minPrice = el.price;
        }
        if (maxPrice === null || el.price > maxPrice) {
          maxPrice = el.price;
        }
      }
    });

    pricingData.forEach((el, i) => {
      if (el.modeled_profit) {
        cats.push(formatCurrency(el.price, marketPlace));

        if (el.price === currentPrice) {
          currentPriceY = el.avg_monthly_sales;
          currentPriceX = i;
        } else if (el.price > currentPrice && currentPriceY === 0) {
          currentPriceY = el.avg_monthly_sales;
          currentPriceX = i;
        }

        if (el.num_days_at_price > 0) {
          realDemand.push({
            x: i,
            y: el.avg_monthly_sales,
            z: el.monthly_profit,
            name: formatCurrency(el.price, marketPlace),
            price: el.price,
          });

          spLineDemand.push({
            x: i,
            y: el.modeled_demand * 30,
            name: formatCurrency(el.price, marketPlace),
            price: el.price,
          });
        }
      }
    });

    if (currentPriceX > 0 && currentPriceY > 0) {
      priceSeries.push([currentPriceX, currentPriceY]);
    }

    const options = {
      chart: {
        height: 300,
        type: "column",
        zoomType: "x",
        style: {
          fontSize: "14px",
        },
      },
      title: {
        text: null,
      },
      yAxis: [
        {
          title: {
            text: "Monthly Units",
          },
          min: 0,
          minimum: 0,
        },
      ],
      xAxis: {
        title: {
          text: "Price",
        },
        labels: {
          enabled: true,
        },
        categories: cats,
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
      },
      series: [
        {
          name: "Actual Units Sold",
          data: realDemand,
          type: "bubble",
          color: "#c43066",
          opacity: 0.6,
          showInLegend: false,
        },
        {
          name: "Modeled Demand",
          data: spLineDemand,
          type: "spline",
          color: "#6e548a",
          opacity: 0.6,
          showInLegend: false,
        },
      ],
      tooltip: {
        formatter: function () {
          try {
            if (monthlyDemandValues[this.y] !== undefined) {
              if (this.y === maxDemandValue) {
                return `Recommended Price: ${this.x}
                <br/><br/>Monthly Volume: ${(
                  monthlyDemandValues[this.y].modeled_demand * 30
                ).toFixed(0)}`;
              } else {
                return `Price: ${this.x}<br/><br/>Monthly Volume: ${(
                  monthlyDemandValues[this.y].modeled_demand * 30
                ).toFixed(0)}`;
              }
            } else {
              return `Price: ${
                this.x
              }<br/><br/>Approx. Monthly Sales: ${this.y.toFixed(0)}`;
            }
          } catch {
            return "";
          }
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
              maxHeight: 200,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };

    options.series.push({
      name: "Current Price",
      data: priceSeries,
      type: "scatter",
      color: "#6e548a",
      opacity: 1,
      showInLegend: false,
      marker: {
        symbol: `url(${PriceTag})`,
        radius: 5,
        color: "#6e548a",
        opacity: 1,
      },
    });

    return options;
  };

  const getProfitCurveOptions = (pricingData, sliderValue) => {
    const cats = [];
    let minPrice = null;
    let maxPrice = null;
    const priceSeries = [];
    const spLineDemand = [];
    let currentPrice = price;
    let currentPriceY = 0;
    let currentPriceX = 0;

    if (!currentPrice) {
      currentPrice = optData.best_price;
    }

    pricingData.forEach((el, i) => {
      if (el.num_days_at_price > 0) {
        if (minPrice === null || el.price < minPrice) {
          minPrice = el.price;
        }
        if (maxPrice === null || el.price > maxPrice) {
          maxPrice = el.price;
        }
      }
    });

    const realDemand = [];
    pricingData.forEach((el, i) => {
      if (el.modeled_profit) {
        cats.push(formatCurrency(el.price, marketPlace));
        if (el.num_days_at_price > 0) {
          if (el.price === currentPrice) {
            currentPriceY = el.modeled_profit * 30;
            currentPriceX = i;
          } else if (el.price > currentPrice && currentPriceY === 0) {
            currentPriceY = el.modeled_profit * 30;
            currentPriceX = i;
          }
          realDemand.push({
            x: i,
            y: el.monthly_profit,
            z: el.avg_monthly_sales,
            name: formatCurrency(el.price, marketPlace),
          });
          spLineDemand.push({
            x: i,
            y: el.modeled_profit * 30,
            name: formatCurrency(el.price, marketPlace),
          });
        }
      }
    });

    priceSeries.push([currentPriceX, currentPriceY]);

    const options = {
      chart: {
        height: 300,
        type: "column",
        zoomType: "x",
        style: {
          fontSize: "14px",
        },
      },
      title: {
        text: null,
      },
      yAxis: [
        {
          title: {
            text: "Monthly Profit",
          },
        },
      ],
      xAxis: {
        title: {
          text: "Price",
        },
        labels: {
          enabled: true,
        },
        categories: cats,
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
      },
      series: [
        {
          name: "Actual Margin $",
          data: realDemand,
          type: "bubble",
          color: "#c43066",
          opacity: 0.5,
          showInLegend: false,
        },
        {
          name: "Modeled Margin $",
          data: spLineDemand,
          type: "spline",
          color: "#6e548a",
          opacity: 0.5,
          showInLegend: false,
        },
      ],
      tooltip: {
        formatter: function () {
          const price = this.x;
          const profit = formatCurrency(this.y, marketPlace);

          return `Price: ${price}<br/><br/>Monthly Profit: ${profit}`;
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
              maxHeight: 200,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };

    options.series.push({
      name: "Current Price",
      data: priceSeries,
      type: "scatter",
      color: "#6e548a",
      opacity: 1,
      showInLegend: false,
      marker: {
        symbol: `url(${PriceTag})`,
        radius: 5,
        color: "#6e548a",
        opacity: 1,
      },
    });

    return options;
  };

  useEffect(() => {
    if (!optData.order_history) {
      return null;
    }

    let pricingData = optData.pricing_data;

    setDemandCurveOptions(
      getDemandCurveOptions(pricingData, optData.configured_slider_value)
    );
    setProfitCurveOptions(
      getProfitCurveOptions(pricingData, optData.configured_slider_value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optData, price]);

  if (!optData.order_history) {
    return null;
  }

  return (
    <Row className={`pt-4 pb-4`}>
      {!fromExplorer && (
        <Col xs={12} md={12} lg={fromExplorer ? 12 : 6}>
          <h6 className={`text-center`}>Demand Elasticity</h6>
          <HighchartsReact
            highcharts={Highcharts}
            options={demandCurveOptions}
          />
        </Col>
      )}
      <Col xs={12} md={12} lg={fromExplorer ? 12 : 6}>
        <h6 className={`text-center`}>Profit Sensitivity</h6>
        <HighchartsReact highcharts={Highcharts} options={profitCurveOptions} />
      </Col>
    </Row>
  );
};

export default CurveCharts;
