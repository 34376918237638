import axios from "axios";
import SingleSignIn from "components/custom/auth/SingleSignIn";
import InfoLayout from "layouts/Instructions";
import AdminLayout from "layouts/Main";
import OnboardLayout from "layouts/Onboard";
import PassSignIn from "components/custom/auth/PassSignIn";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  // refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import {
  logoutUser,
  setCurrentUser,
  setTokens,
} from "redux/actions/authActions";
import { setErrors } from "redux/actions/errorActions";
import { setMarketPlace } from "redux/actions/marketPlace";
import store from "redux/store";
import { USER_LOADING } from "redux/types";
import getURLPrefix from "utils/getUrlPrefix";
import setAuthToken from "utils/setAuthToken";
import { setChannel } from "redux/actions/channel";
import { ThemeProvider } from "@material-ui/core";
import trellisTheme from "assets/themes/TrellisTheme.js";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import ErrorComponent from "layouts/Error";
import signUplogo from "assets/images/logo/Trellis_Logo.png";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LegendProvider } from "legend/LegendProvider";
import NotificationProvider from "notifications/NotificationProvider";
import AdPlanProvider from "components/core/blocks/AdPlan/AdPlanProvider";
import Shopify from "./views/Shopify";
import GoogleAnalytics from "components/custom/GoogleAnalytics";
import "assets/css/theme_overrides.css";
import "react-datepicker/dist/react-datepicker.css";
import SignupLayout from "views/onboarding/layout/SignupLayout";
import MixpanelAnalytics from "components/custom/MixpanelAnalytics";
import { refreshAuthToken } from "hooks/api";
import PDFView from "views/PDFView";

const history = createBrowserHistory();

Sentry.init({
  dsn: import.meta.env.PROD
    ? "https://9514d9f62a744b1cbc89b3e83bfd672d@o944803.ingest.sentry.io/5893305"
    : import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration(),
  ],
  // only sample 10% of transactions for performance metrics
  tracesSamplerRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: ["Request failed with status code 401"],
});

let URL_PREFIX = getURLPrefix();

const queryClient = new QueryClient();

const REFRESH_AUTH_TOKEN_FLAG = false;

// Check for token to keep user logged in
if (localStorage.Tokens) {
  store.dispatch({ type: USER_LOADING, payload: true });
  const tokens = JSON.parse(localStorage.Tokens);

  store.dispatch(setTokens(tokens));
  (async () => {
    try {
      if (
        !window.location.href.includes("onboard") &&
        !window.location.href.includes("spapi") &&
        !window.location.href.includes("sp_api") &&
        REFRESH_AUTH_TOKEN_FLAG
      ) {
        await refreshAuthToken();
      }
    } catch (error) {
      // continue on and let the user profile call fail
    }

    const tokens = JSON.parse(localStorage.Tokens);
    const AuthStr = "Bearer ".concat(tokens.access_token);

    store.dispatch(setTokens(tokens));

    axios
      .get(URL_PREFIX + "/api/userprofile/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        let defaultMarketplace = "USA";
        const orgId = res.data?.organization_id;
        if (orgId) {
          defaultMarketplace = res.data?.default_marketplace?.[orgId] || "USA";
        }
        const marketPlace =
          localStorage.Marketplace && localStorage.Marketplace !== "undefined"
            ? localStorage.Marketplace
            : defaultMarketplace;
        const amzOnboardingStatus = JSON.parse(localStorage.AmzOnboard ?? "{}");
        const channel = localStorage.channel;

        store.dispatch(setCurrentUser(res.data));
        // Assign HubSpot email
        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          "identify",
          {
            email: res.data.email,
          },
        ]);

        if (
          marketPlace === "CA" ||
          marketPlace === "USA" ||
          marketPlace === "MX" ||
          marketPlace === "UK" ||
          marketPlace === "NL" ||
          marketPlace === "ES" ||
          marketPlace === "IT" ||
          marketPlace === "FR" ||
          marketPlace === "BR" ||
          marketPlace === "IN" ||
          marketPlace === "AE" ||
          marketPlace === "DE" ||
          marketPlace === "JP" ||
          marketPlace === "SG" ||
          marketPlace === "AU" ||
          marketPlace === "SE" ||
          marketPlace === "PL" ||
          marketPlace === "BE" ||
          marketPlace === "SA"
        ) {
          localStorage.Marketplace = marketPlace;
          store.dispatch(setMarketPlace(marketPlace));
        } else {
          localStorage.Marketplace = "USA";
          store.dispatch(setMarketPlace("USA"));
        }

        if (
          channel === "amazon" ||
          channel === "google" ||
          channel === "walmart"
        ) {
          store.dispatch(setChannel(channel));
        } else {
          throw new Error(`Invalid channel ${channel}.`);
        }

        store.dispatch(updateAmzOnboardingStatus(amzOnboardingStatus));

        setAuthToken(tokens.access_token);

        store.dispatch({ type: USER_LOADING, payload: false });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Unauthorized, redirect to login
        } else {
          // Unexpected error send to sentry
          Sentry.captureException(err);
        }
        const usererr = {
          message: "Your session has expired. Please login again.",
        };
        store.dispatch(setErrors(usererr));
        if (window.location.href.split("/user")[1] !== undefined) {
          if (
            window.location.href.split("/user")[1].indexOf("/billing") === 0
          ) {
            let redirect = Object.assign(
              { page: "billing" },
              { time: Date.now() + 60 * 60 * 1000 }
            );
            localStorage.setItem("redirectTo", JSON.stringify(redirect));
          } else if (
            window.location.href.split("/user")[1].indexOf("/trak") === 0
          ) {
            let redirect = Object.assign(
              { page: "trak" },
              { time: Date.now() + 60 * 60 * 1000 }
            );
            localStorage.setItem("redirectTo", JSON.stringify(redirect));
          }
        }
        store.dispatch(logoutUser());
      });
  })();
}

const App = () => {
  if (false) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center" style={{ margin: "4em" }}>
            <img
              className=""
              style={{ width: "50%" }}
              src={signUplogo}
              alt="Trellis"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <h1 style={{ fontSize: "2em" }}>
              The portal is currently down for maintenance
            </h1>
            <p>
              If you require immediate assistance please reach out to{" "}
              <a href="mailto:support@gotrellis.com">support</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Provider store={store}>
      <ThemeProvider theme={trellisTheme}>
        <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
          <QueryClientProvider client={queryClient}>
            <NotificationProvider>
              <LegendProvider>
                <AdPlanProvider>
                  <BrowserRouter history={history}>
                    <Switch>
                      <Route
                        path="/pdf"
                        render={(props) => <PDFView {...props} />}
                      />
                      <Route
                        path="/user"
                        render={(props) => <AdminLayout {...props} />}
                      />
                      <Route
                        path="/onboard"
                        render={(props) => <OnboardLayout {...props} />}
                      />
                      <Route
                        path="/onboarding/v2/"
                        render={(props) => <SignupLayout {...props} />}
                      />
                      <Route
                        path="/info"
                        render={(props) => <InfoLayout {...props} />}
                      />
                      <Route
                        path="/referral/:referraltoken"
                        render={(props) => <SingleSignIn {...props} />}
                      />
                      <Route
                        path="/affiliate/:affiliatetoken"
                        render={(props) => <SingleSignIn {...props} />}
                      />
                      <Route
                        path="/signin"
                        render={(props) => <PassSignIn {...props} />}
                      />
                      <Route
                        path={"/shopify"}
                        render={(props) => <Shopify {...props} />}
                      />
                      <Route
                        path="/"
                        render={(props) => <SingleSignIn {...props} />}
                      />
                    </Switch>
                    <GoogleAnalytics />
                    <MixpanelAnalytics />
                  </BrowserRouter>
                  <ReactQueryDevtools initialIsOpen={false} />
                </AdPlanProvider>
              </LegendProvider>
            </NotificationProvider>{" "}
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </Provider>
  );
};

export default Sentry.withProfiler(App);
