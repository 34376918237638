import React, { Component } from "react";
import { FormGroup, FormControl, Form } from "react-bootstrap";
import Select from "react-select";
import { AD_ASIN_SKU_TAGLIST_TOOLTIP, ToolTips } from "utils/hiddenToolTips";
import DetailedProductSelector from "../blocks/ProductSelector/DetailedProductSelector";
import Tags from "../blocks/Tags";

const ControlLabel = Form.Label;

class FormGroupTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
      search: "",
    };
  }

  handleChange = (value) => {
    if (
      this.props.stateName === "maxACOS" ||
      this.props.stateName === "targetACOS" ||
      this.props.decimalFormat
    ) {
      if (
        /^([0-9]+([.][0-9]{0,2})?|[.][0-9]{1,2})$/.test(value) ||
        value === ""
      ) {
        this.props.handleChildFormElement(this.props.stateName, value);
      }
    } else if (
      this.props.stateName === "dailyBudget" ||
      this.props.numberInput
    ) {
      if (/^[0-9]*$/.test(value) || value === "") {
        this.props.handleChildFormElement(this.props.stateName, value);
      }
    } else if (this.props.allowSelectAll) {
      if (
        value !== undefined &&
        value.length > 0 &&
        value[value.length - 1].value === this.props.allOption.value
      ) {
        // Filtered search select all behavior - add all options that match search, then clear search value
        if (this.props.search?.length > 0) {
          let filteredSelectAllOptions = this.props.options.filter(
            (o) =>
              (o.sku?.toLowerCase().indexOf(this.props.search.toLowerCase()) >
                -1 ||
                o?.product_title
                  .toLowerCase()
                  .indexOf(this.props.search.toLowerCase()) > -1 ||
                this.props.selectedOptions?.indexOf(o.value) > -1) &&
              !o.isDisabled
          );

          this.props.handleChildFormElement(
            this.props.stateName,
            filteredSelectAllOptions
          );

          this.props.setSearch("");
        } else {
          this.props.handleChildFormElement(
            this.props.stateName,
            this.props.options
          );
        }
      } else {
        this.props.handleChildFormElement(this.props.stateName, value);
      }
    } else {
      this.props.handleChildFormElement(this.props.stateName, value);
    }
  };

  render() {
    var formControlClass =
      this.props.formControlClass === undefined
        ? "text-center"
        : this.props.formControlClass;
    var multi = this.props.multi === undefined ? false : this.props.multi;
    var closeOnSelect = this.props.closeOnSelect
      ? this.props.closeOnSelect
      : this.props.multi
      ? false
      : true;
    var optionsWithAllOption = this.props.allowSelectAll
      ? [this.props.allOption].concat(this.props.options)
      : null;
    const sBrowser = navigator.userAgent;
    const defaultPrefixStyles = {
      position: "absolute",
      top: this.props.style ? "26px" : "10px",
      [this.props.prefixSide ?? "left"]:
        this.props.prefixSide === "right" ? "30px" : "20px",
    };

    const firefoxPrefixStyles = {
      position: "absolute",
      bottom: "-25px",
      right: "-30px",
      zIndex: 999,
    };

    return (
      <FormGroup>
        {this.props.label === undefined ? null : (
          <div
            style={
              this.props.isProductSelector
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    textAlign: "left",
                    marginBottom: "2rem",
                  }
                : this.props.customLabelStyles
                ? this.props.customLabelStyles
                : { textAlign: "left", marginBottom: "1rem" }
            }
          >
            <ControlLabel>
              {typeof this.props.label === "string" && (
                <h6
                  className={`title ${
                    this.props.labelClassName ? this.props.labelClassName : ""
                  }`}
                  style={{
                    color: "#73706E",
                    fontWeight: "700",
                    textTransform: "none",
                  }}
                >
                  {this.props.label}
                  {this.props.toolTip === undefined ? null : (
                    <ToolTips
                      toolTip={this.props.toolTip}
                      position="top"
                      id={Object.keys(this.props.toolTip)[0]}
                    />
                  )}
                </h6>
              )}
              {typeof this.props.label !== "string" && <>{this.props.label}</>}
            </ControlLabel>
            {this.props.isProductSelector && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "2rem" }}>
                  <DetailedProductSelector {...this.props.selectorProps} />
                </div>
                <Tags
                  name={
                    <h6
                      className="title"
                      style={{ color: "#73706E", fontWeight: "700" }}
                    >
                      Add products by ASIN or SKU
                    </h6>
                  }
                  filledTags={
                    this.props.options
                      ?.filter((o) => {
                        if (this.props.stateName === "landingPageProduct") {
                          return (
                            this.props.selectorProps?.state?.landingPageProduct?.indexOf(
                              o.value
                            ) > -1
                          );
                        }

                        if (this.props.stateName === "creativeProduct") {
                          return (
                            this.props.selectorProps?.state?.creativeProduct?.indexOf(
                              o.value
                            ) > -1
                          );
                        }

                        return (
                          this.props.selectorProps?.state?.product?.indexOf(
                            o.value
                          ) > -1
                        );
                      })
                      ?.map((v) =>
                        this.props.user?.productIdentifier === "sku"
                          ? v.sku.split(" ")?.[0]
                          : v.asin
                      ) ?? []
                  }
                  saveFilledTags={(pt) => this.props.saveFilledTags(pt)}
                  placeholder={``}
                  hideTagsField={true}
                  user={this.props.user}
                  toolTip={AD_ASIN_SKU_TAGLIST_TOOLTIP}
                  toolTipId={"AD_ASIN_SKU_TAGLIST_TOOLTIP"}
                  hiddenTooltip={true}
                  toolTipPosition={"left"}
                ></Tags>
              </div>
            )}
          </div>
        )}
        {this.props.type === "select" && this.props.allowSelectAll ? (
          <Select
            isSearchable={true}
            closeMenuOnSelect={closeOnSelect}
            name={this.props.label}
            id={this.props.label}
            defaultValue={this.props.defaultValue}
            value={this.props.defaultValue}
            options={optionsWithAllOption}
            filterOption={this.props.filterOption}
            inputValue={this.props.search}
            onInputChange={(value, action) => {
              if (action.action === "input-change") {
                this.props.setSearch(value);
              }
              if (action.action === "input-blur") {
                this.props.setSearch("");
              }
            }}
            openMenuOnFocus={true}
            openMenuOnClick={true}
            classNamePrefix="select-input"
            isDisabled={this.props.disabled}
            maxMenuHeight={600}
            styles={{
              multiValue: (base) => ({
                ...base,
                backgroundColor: this.props.disabled ? "#eeeeee" : "#ffffff",
                color: "black",
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: isDisabled ? "SlateGray" : "black",
                  cursor: isDisabled ? "not-allowed" : "default",
                  fontWeight: isDisabled ? 600 : "default",
                };
              },
              control: (base, state) => ({
                ...base,
                "min-height": this.props.contained ? "100px" : "auto",
                "max-height": this.props.contained ? "300px" : "auto",
                "overflow-y": this.props.contained ? "auto" : "inherit",
              }),
            }}
            isMulti={multi}
            inputOption={this.props.searchText}
            onChange={(e) => {
              this.handleChange(e);

              // Clear current search if no more matching listings found
              if (
                this.props.options?.filter(
                  (o) =>
                    (o.sku
                      ?.toLowerCase()
                      .indexOf(this.props.search.toLowerCase()) > -1 ||
                      o?.product_title
                        .toLowerCase()
                        .indexOf(this.props.search.toLowerCase()) > -1) &&
                    !e?.some((v) => v?.value === o?.value) &&
                    !o.isDisabled
                )?.length < 1
              ) {
                this.props.setSearch("");
              }
            }}
          />
        ) : this.props.type === "select" ? (
          <span
            onClick={(e) => {
              this.props.onClick && this.props.onClick(e);
              !this.props.allowEventBubbling && e.stopPropagation();
            }}
          >
            <Select
              isSearchable={this.props.isSearchable ?? true}
              closeMenuOnSelect={closeOnSelect}
              name={this.props.label}
              id={this.props.label}
              defaultValue={this.props.defaultValue}
              value={this.props.defaultValue}
              options={this.props.options}
              filterOption={this.props.filterOption}
              isDisabled={this.props.disabled}
              maxMenuHeight={600}
              inputValue={this.props.search ?? this.state.search}
              openMenuOnFocus={true}
              openMenuOnClick={true}
              components={this.props.components ?? {}}
              isClearable={this.props.isClearable ?? false}
              isLoading={this.props.isLoading}
              loadingMessage={() => <i className="fa fa-spinner" />}
              placeholder={this.props.placeholder ?? "Select..."}
              onInputChange={(value, action) => {
                if (action.action === "input-change" && this.props.setSearch) {
                  this.props.setSearch(value);
                }
                if (action.action === "input-blur" && this.props.setSearch) {
                  this.props.setSearch("");
                }
                if (!this.props.setSearch) {
                  this.setState({ search: value });
                }
              }}
              classNamePrefix={`select-input`}
              styles={{
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: this.props.disabled ? "#eeeeee" : "#ffffff",
                  color: "black",
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isDisabled ? "red" : "black",
                    cursor: isDisabled ? "not-allowed" : "default",
                    fontWeight: isDisabled ? 600 : "default",
                  };
                },
                input: (provided) => ({
                  ...provided,
                  input: {
                    opacity: "1 !important",
                  },
                }),
                control: (base) => ({
                  ...base,
                  boxShadow: "none",
                }),
              }}
              classPrefixName="containedMultiSelect"
              className={this.props.selectClasses}
              isMulti={multi}
              onChange={(e) => {
                this.handleChange(e);
                this.props.setSearch && this.props.setSearch("");
              }}
            />
          </span>
        ) : this.props.type === "formcontrol" && this.props.prefix ? (
          <span
            style={{ position: "relative" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span
              style={
                sBrowser.indexOf("Firefox") > -1
                  ? firefoxPrefixStyles
                  : defaultPrefixStyles
              }
              className="fs-standard"
            >
              {this.props.prefix}
            </span>
            <FormControl
              className={formControlClass}
              type={this.props.formControlType}
              placeholder={this.props.placeholder}
              value={this.props.defaultValue}
              name={this.props.stateName}
              id={this.props.stateName}
              disabled={this.props.disabled}
              min="0"
              onKeyDown={(e) => {
                if (this.props.onKeyDown) {
                  this.props.onKeyDown(e);
                }
              }}
              onKeyUp={(e) => {
                if (this.props.onKeyUp) {
                  this.props.onKeyUp(e);
                }
              }}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                // Min attribute above only works for arrows, below prevents users from entering negative numbers
                if (
                  this.props.formControlType === "number" &&
                  e.target.value.indexOf("-") > -1
                ) {
                  this.handleChange("");
                  return;
                }
                this.handleChange(e.target.value);
              }}
              // Prevent scrollbar changes to input value
              onWheel={(e) => e.target.blur()}
              style={this.props.style ?? {}}
            />
          </span>
        ) : this.props.type === "formcontrol" ? (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <FormControl
              className={formControlClass}
              type={this.props.formControlType}
              placeholder={this.props.placeholder}
              value={this.props.defaultValue}
              name={this.props.stateName}
              id={this.props.stateName}
              disabled={this.props.disabled}
              min="0"
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                // Min attribute above only works for arrows, below prevents users from entering negative numbers
                if (
                  this.props.formControlType === "number" &&
                  e.target.value.indexOf("-") > -1
                ) {
                  this.handleChange("");
                  return;
                }
                this.handleChange(e.target.value);
              }}
              // Prevent scrollbar changes to input value
              onWheel={(e) => e.target.blur()}
              autoFocus={this.props.autoFocus ?? false}
              style={this.props.style ?? {}}
            />
          </span>
        ) : null}
      </FormGroup>
    );
  }
}

export default FormGroupTile;
