import React, { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import getMetricSpec from "views/merchandise/metrics";
import { STEPS_BY_GROUP } from "./utils";

const Chart = ({
  title = "",
  data,
  options = {},
  metrics = [],
  type = "spline",
  yAxisLabels = [],
  fontSize = "10px",
  group = "day",
  height = 400,
  marketPlace,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseOptions = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
      space: [10, 10, 10, 10],
      style: {
        fontFamily: "Quicksand",
      },
      height: height,
    },
    title: {
      text: title,
    },
    legend: {
      itemStyle: {
        fontSize: fontSize,
      },
    },
    subtitle: {
      text: null,
    },
    label: {
      step: STEPS_BY_GROUP[group] ?? 5,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },

      series: {
        enableMouseTracking: false,
        marker: {
          symbol: "circle",
          radius: 3,
        },
      },
    },
  };

  const chartOptions = useMemo(() => {
    const dates = data?.map((d) => moment(d.start_date).format("MMM DD YYYY"));

    const metricDefs = metrics?.map((m) => {
      const def = {
        ...getMetricSpec(marketPlace ?? "USA", m.id),
        seriesColor: m.color,
        chartType: m.type ?? type,
      };

      return def;
    });

    const series = metricDefs?.map((metric, idx) => {
      const {
        id,
        color,
        name,
        accessor,
        chartType = "spline",
        seriesColor = null,
        format,
      } = metric;

      let chartData = data?.map((d) => accessor(d) ?? null);

      if (format === "percent") {
        chartData = chartData.map((entry) => entry * 100);
      }
      chartData = chartData?.filter((d) => d)?.length === 0 ? [] : chartData;
      const additionalOptions = {};
      if (chartType === "area") {
        additionalOptions["fillOpacity"] = 0.1;
      }
      if (chartType === "column") {
        additionalOptions["opacity"] = 0.8;
      }
      return {
        custom: { id },
        legendIndex: idx,
        data: chartData,
        color: seriesColor ?? color,
        name,
        type: chartType,
        yAxis: 0,
        ...additionalOptions,
      };
    });
    return {
      ...baseOptions,
      xAxis: {
        categories: dates,
        labels: {
          step: STEPS_BY_GROUP[group] ?? 5,
          style: {
            fontSize: fontSize,
          },
        },
      },
      yAxis: Array.from(Array(50)).map((_, index) => ({
        title: {
          text: yAxisLabels[index] ?? "",
          style: {
            fontSize: fontSize,
          },
        },
        visible: true,
        opposite: false,
        labels: {
          enabled: true,
          style: {
            fontSize: fontSize,
          },
        },
      })),
      series: series,
    };
  }, [
    data,
    baseOptions,
    fontSize,
    group,
    marketPlace,
    metrics,
    type,
    yAxisLabels,
  ]);

  return (
    <div className="box-stats-section" style={{ marginTop: "25px" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default Chart;
