// import axios from "axios";
import "assets/css/dashboard_table.css";
import Card from "components/core/basic/Card";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table-6";
import "assets/css/dashboard_table.css";
import DataTablePagination from "./DataTablePagination";
import KeywordCell from "../category/advertisements/KeywordCell";
// import Switch from "react-bootstrap-switch";
import { scaleLinear } from "d3-scale";
import { formatNumber, parseFormattedNumber } from "utils/formatNumber";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import FilterBar from "./FilterBar";

const useStyles = (theme) => ({
  switchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 1rem",
    margin: "-1rem 2rem 1rem 0",
  },
  switchLabel: {
    color: "#797979",
    marginLeft: "2rem",
    textTransform: "uppercase",
  },
});

const DataTable = ({
  shelfStatsData,
  marketPlace,
  classes,
  selectedShelfSearchCount,
  shelfData,
  selectedShelf,
  selectedSearchId,
  filterSearchId,
  selectedRollupType,
  filterRollupType,
  homeBrand,
  setSelectedDateRange,
  dateRange,
  isLoading,
  shelfStatsTotalData,
  settings,
}) => {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [showTrendView, setShowTrendView] = useState(false);
  const [filtered, setFiltered] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [filterState, setFilterState] = useState([]);

  const [productCountRanges, setProductCountRanges] = useState([]);
  const [avgPositionRanges, setAvgPositionRanges] = useState([]);
  const [searchCountRanges, setSearchCountRanges] = useState([]);
  const [sosRanges, setSosRanges] = useState([]);
  const [priceRangeRanges, setPriceRangeRanges] = useState([]);
  const [avgRatingRanges, setAvgRatingRanges] = useState([]);
  const [avgNumRatingsRanges, setAvgNumRatingsRanges] = useState([]);
  const [numSponsListingRanges, setNumSponsListingRanges] = useState([]);
  const [avgSponsListingRankRanges, setAvgSponsListingRankRanges] = useState(
    []
  );
  const [numSponsSearchRanges, setNumSponsSearchRanges] = useState([]);
  const [amazonchoiceRanges, setAmazonchoiceRanges] = useState([]);
  const [sovRanges, setSovRanges] = useState([]);
  const shelf = shelfData.find((s) => s.id === selectedShelf) || {};
  const numberOfSearches = shelf?.searches?.length ?? 0;

  useEffect(() => {
    if (showTrendView) {
      // TODO: Retrieve processed trend values here in future iteration
      setTableData([]);
    } else {
      setTableData(shelfStatsTotalData);
    }

    if (shelfStatsData.length > 0) {
      getDropdownOptions();
    }

    applyFilters();
  }, [shelfStatsTotalData, showTrendView, searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  // Re-apply filters when change in selected shelf detected
  useEffect(() => {
    applyFilters();
  }, [tableData]); // eslint-disable-line react-hooks/exhaustive-deps

  const applyFilters = () => {
    if (searchFilter.length > 0) {
      setFilteredData(
        tableData.filter(
          (row) =>
            row.brand.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
        )
      );
    } else {
      setFilteredData(null);
    }
  };
  const generateRanges = (
    data,
    numOfRanges,
    targetProperty,
    format = "number"
  ) => {
    if (!data || data.length === 0) {
      return [];
    }

    const rangeSourceData = data
      .map((row) => row[targetProperty])
      .sort((a, b) => a - b);

    // Process ranges from 0 to upper threshold of sorted data
    let x = scaleLinear().domain([
      0,
      rangeSourceData[rangeSourceData.length - 1],
    ]);

    const ticks = x.ticks(numOfRanges);
    const ceiling = rangeSourceData[rangeSourceData.length - 1];

    // Return ranges in specified format, if present
    const ranges = ticks.map((tickFormat, index) => {
      if (format === "percentage") {
        return index < numOfRanges
          ? `${tickFormat}% - ${ticks[index + 1]}%`
          : `${tickFormat}% - ${ceiling?.toFixed(1)}%`;
      } else if (format === "currency") {
        return index < numOfRanges
          ? `$${tickFormat} - $${ticks[index + 1]}`
          : `$${tickFormat} - $${ceiling?.toFixed(1)}`;
      } else if (format === "rounded") {
        return index < numOfRanges
          ? `${tickFormat.toFixed(1)} - ${ticks[index + 1]?.toFixed(1)}`
          : `${tickFormat.toFixed(1)} - ${ceiling?.toFixed(1)}`;
      } else {
        return index < numOfRanges
          ? `${tickFormat} - ${ticks[index + 1]}`
          : `${tickFormat} - ${Math.ceil(ceiling)}`;
      }
    });
    if (ranges.length === 0) {
      return [];
    }

    // If last range contains undefined, swap out with ceiling value
    if (ranges[ranges.length - 1].indexOf("undefined") > -1) {
      ranges[ranges.length - 1] = `${
        ranges[ranges.length - 1].split(" - ")[0]
      } - ${Math.ceil(ceiling)}${format === "percentage" ? "%" : ""}`;
    }
    if (ranges.length === 1) {
      return ranges;
    }

    //Remove last range element if redundant (covering ranges already handled earlier)
    if (
      ranges[ranges.length - 1].split(" - ")[1] ===
        ranges[ranges.length - 2].split(" - ")[1] ||
      Number.parseFloat(
        ranges[ranges.length - 1].split(" - ")[1].split("%")[0]
      ) ===
        Number.parseFloat(
          ranges[ranges.length - 2].split(" - ")[1].split("%")[0]
        )
    ) {
      ranges.pop();
    }

    return ranges;
  };

  // Define range filters for all columns
  const getDropdownOptions = () => {
    setProductCountRanges(
      generateRanges(shelfStatsTotalData, 5, "product_cnt")
    );
    setAvgPositionRanges(
      generateRanges(shelfStatsTotalData, 5, "position_avg")
    );
    setSearchCountRanges(generateRanges(shelfStatsTotalData, 3, "search_cnt"));
    setSosRanges(
      generateRanges(shelfStatsTotalData, 5, "share_of_shelf", "percentage")
    );
    setPriceRangeRanges(
      generateRanges(shelfStatsTotalData, 5, "price_avg", "currency")
    );
    setSovRanges(
      generateRanges(shelfStatsTotalData, 5, "share_of_voice", "percentage")
    );
    setAvgRatingRanges([[1], [2], [3], [4], [5]]);
    setAvgNumRatingsRanges(
      generateRanges(shelfStatsTotalData, 5, "ratings_total_avg")
    );
    setNumSponsListingRanges(
      generateRanges(shelfStatsTotalData, 5, "sp_products", "rounded")
    );
    setAvgSponsListingRankRanges(
      generateRanges(shelfStatsTotalData, 5, "sp_position_avg")
    );
    setNumSponsSearchRanges(
      generateRanges(shelfStatsTotalData, 3, "sp_search_cnt")
    );
    setAmazonchoiceRanges(
      generateRanges(shelfStatsTotalData, 3, "amazon_choice_cnt")
    );
  };

  const clearSearchTermFilters = () => {
    let currentFilters = filtered;
    let updatedFilters = currentFilters.filter(
      (filter) => filter.id !== "keyword"
    );

    setFiltered(updatedFilters);
  };
  const columns = [
    {
      Header: "Brands",
      headerClassName: "wordwrap",
      accessor: "brand",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      Cell: (props) => {
        let style = {};
        if (props.value?.toLowerCase() === homeBrand?.toLowerCase()) {
          style = { fontWeight: "bold" };
        }
        return (
          <KeywordCell
            value={props.value}
            columnWidth={props.width}
            style={style}
          />
        );
      },
      Filter: () => null,
    },
    {
      Header: "Number of Products",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "product_cnt",
      Cell: (props) =>
        props.value > 0
          ? `${formatNumber(props.value, { rounded: "up" }, 0)}`
          : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }

        if (
          row.product_cnt.toFixed(1) >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.product_cnt.toFixed(1) <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {productCountRanges &&
            productCountRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Average Position",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "position_avg",
      Cell: (props) => {
        return props.value > 0 ? <>{formatNumber(props.value, null, 0)}</> : "";
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.position_avg >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.position_avg <= parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {avgPositionRanges &&
            avgPositionRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Ranking Keywords",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "search_cnt",
      Cell: (props) => {
        return props.value > 0 ? (
          <>
            {formatNumber(props.value, { rounded: "up" }, 0)}/{numberOfSearches}
          </>
        ) : (
          ""
        );
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.search_cnt >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.search_cnt <= parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {searchCountRanges &&
            searchCountRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Share of Shelf",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "share_of_shelf",
      Cell: (props) =>
        props.value > 0 ? `${formatNumber(props.value, null, 1)}%` : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.share_of_shelf >=
            parseFormattedNumber(filter.value.split("% - ")[0]) &&
          row.share_of_shelf <=
            parseFormattedNumber(filter.value.split("% - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {sosRanges &&
            sosRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Price Range",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "price_avg",
      Cell: (props) => {
        return props.value > 0
          ? props.original.price_min === props.original.price_max
            ? `${
                formatNumber(props.original.price_min, {
                  currency: {
                    marketPlace: marketPlace.marketPlace,
                  },
                }).split(".")[0]
              }`
            : `${
                formatNumber(props.original.price_min, {
                  currency: {
                    marketPlace: marketPlace.marketPlace,
                  },
                }).split(".")[0]
              } - ${
                formatNumber(props.original.price_max, {
                  currency: {
                    marketPlace: marketPlace.marketPlace,
                  },
                }).split(".")[0]
              }`
          : "";
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        const [start, end] = filter.value
          .split(" - $")
          .map((v, i) => parseFormattedNumber(i > 0 ? v : v.substring(1)));
        if (
          (row._original.price_min >= start &&
            row._original.price_min <= end) ||
          (row._original.price_max >= start && row._original.price_max <= end)
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {priceRangeRanges &&
            priceRangeRanges.map((range, index) => (
              <option key={index} value={range}>
                {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(
                  range
                    .split(
                      ` - ${
                        MARKETPLACE_CURRENCY_DENOMINATION[
                          marketPlace.marketPlace
                        ]
                      }`
                    )[0]
                    .substring(1),
                  null,
                  0
                )}{" "}
                - {MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]}
                {formatNumber(range.split(" - $")[1], null, 0)}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Average Rating",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "rating_avg",
      Cell: (props) =>
        props.value > 0 ? formatNumber(props.value, null, 1) : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          parseFormattedNumber(row.rating_avg) >=
            parseFormattedNumber(filter.value) &&
          parseFormattedNumber(row.rating_avg) <
            parseFormattedNumber(filter.value) + 1
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {avgRatingRanges &&
            avgRatingRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Average Number of Ratings",
      headerClassName: "wordwrap",
      headerStyle: {
        textAlign: "center",
      },
      style: { textAlign: "center" },
      accessor: "ratings_total_avg",
      Cell: (props) =>
        props.value > 0 ? formatNumber(props.value, null, 0) : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.ratings_total_avg >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.ratings_total_avg <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {avgNumRatingsRanges &&
            avgNumRatingsRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Amazon Choice",
      headerClassName: "wordwrap",
      headerStyle: {
        textAlign: "center",
      },
      style: { textAlign: "center" },
      accessor: "amazon_choice_cnt",
      Cell: (props) =>
        props.value > 0 ? formatNumber(props.value, null, 0) : "",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (
          row.amazon_choice_cnt >=
            parseFormattedNumber(filter.value.split(" - ")[0]) &&
          row.amazon_choice_cnt <=
            parseFormattedNumber(filter.value.split(" - ")[1])
        ) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {amazonchoiceRanges &&
            amazonchoiceRanges.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
        </select>
      ),
    },
  ];

  if (settings?.share_of_voice) {
    const sponsoredColumns = [
      {
        Header: "Number of Sponsored Listings",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "sp_products",
        Cell: (props) =>
          props.value > 0
            ? `${formatNumber(props.value, { rounded: "up" }, 0)}`
            : "",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (
            row.sp_products >=
              parseFormattedNumber(filter.value.split(" - ")[0]) &&
            row.sp_products <=
              parseFormattedNumber(filter.value.split(" - ")[1])
          ) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(e) => onChange(e.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {numSponsListingRanges &&
              numSponsListingRanges.map((range, index) => (
                <option key={index} value={range}>
                  {range}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: "Average Sponsored Position",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "sp_position_avg",
        Cell: (props) => {
          return props.value > 0 ? (
            <>{formatNumber(props.value, null, 0)}</>
          ) : (
            ""
          );
        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (
            row.sp_position_avg >=
              parseFormattedNumber(filter.value.split(" - ")[0]) &&
            row.sp_position_avg <=
              parseFormattedNumber(filter.value.split(" - ")[1])
          ) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(e) => onChange(e.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {avgSponsListingRankRanges &&
              avgSponsListingRankRanges.map((range, index) => (
                <option key={index} value={range}>
                  {range}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: "Ranking Sponsored Keywords",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "sp_search_cnt",
        Cell: (props) =>
          props.value > 0
            ? `
                            ${formatNumber(
                              props.value,
                              { rounded: "up" },
                              0
                            )}/${numberOfSearches}
                        `
            : "",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (
            row.sp_search_cnt >=
              parseFormattedNumber(filter.value.split(" - ")[0]) &&
            row.sp_search_cnt <=
              parseFormattedNumber(filter.value.split(" - ")[1])
          ) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(e) => onChange(e.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {numSponsSearchRanges &&
              numSponsSearchRanges.map((range, index) => (
                <option key={index} value={range}>
                  {range}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: "Share of Voice",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "share_of_voice",
        Cell: (props) =>
          props.value > 0 ? `${formatNumber(props.value, null, 1)}%` : "",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (
            row.share_of_voice >=
              parseFormattedNumber(filter.value.split("% - ")[0]) &&
            row.share_of_voice <=
              parseFormattedNumber(filter.value.split("% - ")[1])
          ) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(e) => onChange(e.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {sovRanges &&
              sovRanges.map((range, index) => (
                <option key={index} value={range}>
                  {range}
                </option>
              ))}
          </select>
        ),
      },
    ];
    for (const col of sponsoredColumns) {
      columns.push(col);
    }
  }

  return (
    <>
      <Grid style={{ marginTop: "-3rem" }}>
        <FilterBar
          shelfData={shelfData}
          filterSearchId={filterSearchId}
          selectedShelf={selectedShelf}
          selectedSearchId={selectedSearchId}
          selectedRollupType={selectedRollupType}
          filterRollupType={filterRollupType}
          setSelectedDateRange={setSelectedDateRange}
          dateRange={dateRange}
          isLoading={isLoading}
        />
        <Grid container style={{ marginTop: "-2rem" }}>
          <Grid item xs={12}>
            {/* <Grid item xs={12} className={classes.switchContainer}>
                <Switch
                  onText=""
                  offText=""
                  value={showTrendView}
                  onColor="#FFFFFF"
                  onChange={() => setShowTrendView(!showTrendView)}
                />
                <span className={classes.switchLabel}>
                  {showTrendView ? "Show Delta View" : "Show Trend View"}
                </span>
              </Grid> */}
            <Card
              additionalCardClasses={"data-table"}
              content={
                <ReactTable
                  data={filteredData ? filteredData : tableData}
                  PaginationComponent={(props) => {
                    return (
                      <DataTablePagination
                        {...props}
                        updateSearch={setSearchFilter}
                        clearFilters={() => {
                          setFiltered([]);
                          setClearFilters(true);
                        }}
                        clearSearchTermFilters={clearSearchTermFilters}
                        searchTerm={searchFilter}
                        setFilterState={setFilterState}
                        filteredState={filtered}
                        table={"analytics"}
                        setShowFilters={setShowFilters}
                        showFilters={showFilters}
                      />
                    );
                  }}
                  getTheadFilterProps={(props) => {
                    if (clearFilters) {
                      props.filtered.forEach((filter, index) => {
                        filter.value = "all";
                      });
                      setClearFilters(false);
                    }

                    return { style: { display: "flex" } };
                  }}
                  filterable={showFilters}
                  columns={columns}
                  defaultSorted={[
                    {
                      id: "share_of_shelf",
                      desc: true,
                    },
                  ]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

DataTable.propTypes = {
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(DataTable))
);
