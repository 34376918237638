import React, { useRef, useState } from "react";
import { useFetch } from "hooks/api";
import useColumnSelector from "hooks/useColumnSelector";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import Loading from "components/core/blocks/Loading";
import moment from "moment";
import getURLPrefix from "utils/getUrlPrefix";
import axios from "axios";
import { HiDownload } from "react-icons/hi";
import FileSaver from "file-saver";

const API_URL = getURLPrefix();

const B2BFeed = ({ marketPlace }) => {
  const [colToggle] = useState();

  const { data, isLoading } = useFetch(
    ["B2BFeed", marketPlace.marketPlace],
    `/pricing/b2bfeedstatus/`,
    {
      marketplace: marketPlace.marketPlace,
    },
    {
      default: [],
      refetchOnWindowFocus: false,
      select: (d) => d.data,
    }
  );

  const handleDownload = async (id, title) => {
    let url = `${API_URL}/api/exports/${id}/download/`;
    const response = await axios({
      method: "GET",
      url: url,
      responseType: "blob",
    });

    let blob = new Blob([response.data], {
      type: "text/xml;charset=utf-8",
    });

    FileSaver.saveAs(blob, `${title}.xml`);
  };

  const tableRef = useRef();

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let columns = [
        {
          id: "Feed ID",
          label: "Feed ID",
          Header: (props) => (
            <span style={{ position: "relative" }}>Feed ID</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "left" },
          style: { textAlign: "left" },
          accessor: "id",
        },
        {
          id: "Status",
          label: "Status",
          Header: (props) => (
            <span style={{ position: "relative" }}>Status</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "left" },
          style: { textAlign: "left" },
          accessor: "status",
        },
        {
          id: "Created",
          label: "Created",
          Header: (props) => (
            <span style={{ position: "relative" }}>Created</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "left" },
          style: { textAlign: "left" },
          accessor: "created_on",
          Cell: (props) => {
            return <div>{moment(props.value).format("MMM DD YYYY")}</div>;
          },
        },
        {
          id: "Processed",
          label: "Processed",
          Header: (props) => (
            <span style={{ position: "relative" }}>Created</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "result",
          Cell: (props) => {
            return (
              <div>
                {
                  props.value?.Message?.ProcessingReport?.ProcessingSummary
                    ?.MessagesProcessed
                }
              </div>
            );
          },
        },
        {
          id: "Success",
          label: "Success",
          Header: (props) => (
            <span style={{ position: "relative" }}>Successful</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "result",
          Cell: (props) => {
            return (
              <div>
                {
                  props.value?.Message?.ProcessingReport?.ProcessingSummary
                    ?.MessagesSuccessful
                }
              </div>
            );
          },
        },
        {
          id: "Error",
          label: "Error",
          Header: (props) => (
            <span style={{ position: "relative" }}>Error</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "result",
          Cell: (props) => {
            return (
              <div>
                {
                  props.value?.Message?.ProcessingReport?.ProcessingSummary
                    ?.MessagesWithError
                }
              </div>
            );
          },
        },
        {
          id: "Warning",
          label: "Warning",
          Header: (props) => (
            <span style={{ position: "relative" }}>Warning</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "result",
          Cell: (props) => {
            return (
              <div>
                {
                  props.value?.Message?.ProcessingReport?.ProcessingSummary
                    ?.MessagesWithWarning
                }
              </div>
            );
          },
        },
        {
          id: "Download",
          label: "Download",
          Header: (props) => (
            <span style={{ position: "relative" }}>Download</span>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "export",
          Cell: (props) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDownload(props.value.id, `b2b_feed_${props.value.id}`);
                }}
              >
                <HiDownload />
              </span>
            );
          },
        },
      ];

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle]
  );
  if (isLoading) return <Loading fullPage={false} />;

  return (
    <ReactTable
      ref={tableRef}
      data={data.results}
      className="b2b-table"
      PaginationComponent={(props) => {
        return (
          <ReactTablePagination
            {...props}
            table={"b2b-table"}
            showFilters={false}
            style={{ marginBottom: "2rem" }}
            carouselLayout={true}
            hideFilters={true}
            columnSelectorProps={columnSelectorProps}
            hideSearch={false}
          />
        );
      }}
      columns={columns}
      defaultPageSize={20}
      NoDataComponent={() => null}
      minRows={0}
      showPaginationTop
      showPaginationBottom={false}
      filterable={false}
    />
  );
};

export default B2BFeed;
