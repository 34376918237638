import { trellisPalette } from "components/custom/analytics/palettes";
import React from "react";
import { FaCheck } from "react-icons/fa";

const DialogueCard = ({
  contents,
  justify = "start",
  additionalClasses = null,
  onClick = () => {},
  onDisabledClick = () => {},
  url = "#",
  showSuccessIcon,
  showSuccessIconPlaceholder = false,
  prerequisiteField = null,
  disabled,
  showChildren,
  index,
  trackIndex = false,
}) => {
  const { title, description, value, openUrlInNewTab, children } = contents;

  const cardInnerContents = (
    <>
      <div
        className={`d-flex align-items-center justify-content-${justify} ${
          disabled ? "" : "bg-light"
        } border py-4 px-4 ${
          showChildren ? "mt-4 mb-0" : "my-4"
        } w-100 ${additionalClasses}`}
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          backgroundColor: disabled ? "#EEE" : "inherit",
        }}
        onClick={(e) => {
          if (disabled) {
            onDisabledClick(value);
            return;
          }

          if (children && trackIndex) {
            onClick(index);
            return;
          }
          onClick(value);
        }}
      >
        <span className="fs-4">
          {title ? (
            <strong className={description ? "pe-4" : ""}>{title}</strong>
          ) : (
            <></>
          )}
          {description}
        </span>
      </div>

      {children && showChildren ? children : <></>}
    </>
  );

  return (
    <>
      {prerequisiteField && (
        <div className="d-flex">
          <>{prerequisiteField}</>
        </div>
      )}
      {disabled ? (
        cardInnerContents
      ) : (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={url}
          target={openUrlInNewTab ? "_blank" : "_self"}
          className="text-decoration-none w-100 no-highlight"
        >
          {cardInnerContents}
        </a>
      )}
      {showSuccessIcon && (
        <FaCheck
          className="fw-bold"
          width="50px"
          height="30px"
          style={{
            width: "65px",
            height: "30px",
            padding: "4px",
            color: trellisPalette[12],
          }}
        />
      )}
      {showSuccessIconPlaceholder && (
        <div style={{ width: "65px", height: "30px" }} />
      )}
    </>
  );
};

export default DialogueCard;
