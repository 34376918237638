import React, { useState, useMemo } from "react";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import useSelectedMetrics from "components/custom/merchandise/hooks/useSelectedMetrics";
import MultiMetricChart from "components/custom/merchandise/MultiMetricChart";
import Loading from "components/core/blocks/Loading";
import { buildMetric } from "views/merchandise/metrics";
import DetailedMetric from "components/core/blocks/DetailedMetric";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import acosOrRoas from "utils/acosOrRoas";
import { formatCurrency } from "utils/formatNumber";
import moment from "moment";
import checkModule from "utils/checkModule";
import { Row, Col, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import BiddingProgramLog from "components/custom/programs/bidding/BiddingProgramLog";

const TargetDetails = ({
  row,
  targetType,
  marketPlace = { marketPlace: "USA" },
  channel = "walmart",
  formatTargetText,
}) => {
  const [tab, setTab] = useState("overview");
  const { preferences } = useMerchandisePreferences();
  const reduxState = useSelector((state) => state);
  const modules = selectModules(reduxState);

  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  let { text, target_id, match_type } = row._original ?? {};

  const { group, period, compare, date, start, end, preStart, preEnd } =
    useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    target_id: target_id,
    target_type: targetType,
    kw_report: false,
    channel: channel,
  };

  const { data: targetData, isLoading } = useFetch(
    [
      "keyword_report",
      filters.start_date,
      filters.end_date,
      filters.pre_start_date,
      filters.pre_end_date,
      group,
      target_id,
    ],
    `/api/data_report/keyword_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!target_id,
      staleTime: 2 * 60 * 1000,
    }
  );

  const { data: targetBidData } = useFetch(
    ["target_details", target_id],
    "/target_details",
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!target_id,
      staleTime: 2 * 60 * 1000,
    }
  );

  const { selectedMetrics, toggleMetric } = useSelectedMetrics([
    "ad_sales",
    "clicks",
    "daily_bid",
    "impressions",
  ]);

  const metrics = useMemo(
    () => [
      buildMetric(marketPlace, targetData, "clicks", "clicks_percent"),
      buildMetric(marketPlace, targetData, "ad_spend", "ad_spend_percent"),
      buildMetric(marketPlace, targetData, "orders", "orders_percent"),
      buildMetric(marketPlace, targetData, "daily_bid", null),
      buildMetric(
        marketPlace,
        targetData,
        "impressions",
        "impressions_percent"
      ),
      buildMetric(marketPlace, targetData, "ad_sales", "ad_sales_percent"),
      acosroas(
        buildMetric(marketPlace, targetData, "acos", "acos_delta"),
        buildMetric(marketPlace, targetData, "roas", "roas_delta")
      ),
      buildMetric(marketPlace, targetData, "ctr", "ctr_delta"),
      buildMetric(
        marketPlace,
        targetData,
        "conversion_rate",
        "conversion_rate_delta"
      ),
      buildMetric(marketPlace, targetData, "cpc", "cpc_delta"),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marketPlace, targetData]
  );

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  const dateFilterWithoutToday =
    targetData?.date?.filter(
      (d) => moment().format("YYYY-MM-DD") !== d.start_date
    ) ?? [];

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <div className="bg-white">
      <Row className="pt-3">
        <Col xs={12}>
          <h3
            className="pb-4 px-5"
            style={{
              fontSize: 22,
            }}
          >
            {formatTargetText(text)} - {match_type}
          </h3>
        </Col>
        {checkModule(modules, "BIDDING_RULES") && (
          <Row>
            <Col xs={12} className="mt-5 ph-4 mb-3 border-bottom">
              <Nav
                variant="underline"
                onSelect={(k) => {
                  return setTab(k);
                }}
                activeKey={tab}
              >
                <Nav.Item>
                  <Nav.Link eventKey="overview">Overview</Nav.Link>
                </Nav.Item>
                {checkModule(modules, "BIDDING_RULES") && (
                  <Nav.Item>
                    <Nav.Link eventKey="logs">Program Logs</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Col>
          </Row>
        )}

        {tab === "overview" && (
          <>
            <Col xs={12}>
              <MultiMetricChart
                metrics={metrics}
                selectedMetrics={selectedMetrics}
                data={dateFilterWithoutToday}
                group={group}
                handleLegendChange={handleLegendChange}
                datesFormatted={true}
                showAnnotations={false}
              />
            </Col>
            <Col xs={12}>
              <Row className="pt-4">
                {metrics
                  ?.filter(
                    (m) =>
                      !["daily_bid", "ctr", "conversion_rate", "cpc"].includes(
                        m.id
                      )
                  )
                  ?.map((m, i) => {
                    return (
                      <Col
                        xs={12}
                        md={6}
                        lg={2}
                        key={m.id}
                        className={`mb-4 ${i % 2 === 0 ? "pl-2" : "pr-2"}`}
                      >
                        <div className="">
                          <div>
                            <DetailedMetric
                              center
                              size={"small"}
                              id={m.id}
                              toolTip={m.toolTip}
                              name={m.name}
                              value={m.overrideValue ?? m.actual}
                              target={m.target}
                              formatter={m.formatter}
                              targetFormatter={m?.targetFormatter}
                              showTarget={false}
                              targetLabel={m?.targetLabel}
                              targetColor={
                                m?.targetColor ??
                                (m.target > 0 ? "#007000" : "#d2222d")
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                {targetBidData?.manual_bid && (
                  <Col xs={12} md={6} lg={2} className={`mb-4 pr-2`}>
                    <div className="">
                      <div>
                        <DetailedMetric
                          center
                          size={"small"}
                          id={"manual_bid"}
                          name={"Fixed Bid"}
                          value={targetBidData?.manual_bid}
                          formatter={(v) =>
                            formatCurrency(
                              v,
                              marketPlace.marketPlace,
                              false,
                              false,
                              true
                            )
                          }
                          showTarget={false}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {targetBidData?.floor_bid && (
                  <Col xs={12} md={6} lg={2} className={`mb-4 pr-2`}>
                    <div>
                      <div>
                        <DetailedMetric
                          center
                          size={"small"}
                          id={"floor_bid"}
                          name={"Minimum Bid"}
                          value={targetBidData?.floor_bid}
                          formatter={(v) =>
                            formatCurrency(
                              v,
                              marketPlace.marketPlace,
                              false,
                              false,
                              true
                            )
                          }
                          showTarget={false}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {targetBidData?.ceil_bid && (
                  <Col xs={12} md={6} lg={2} className={`mb-4 pr-2`}>
                    <div>
                      <div>
                        <DetailedMetric
                          center
                          size={"small"}
                          id={"ceil_bid"}
                          name={"Maximum Bid"}
                          value={targetBidData?.ceil_bid}
                          formatter={(v) =>
                            formatCurrency(
                              v,
                              marketPlace.marketPlace,
                              false,
                              false,
                              true
                            )
                          }
                          showTarget={false}
                        />
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </>
        )}
        {tab === "logs" && (
          <Col xs={12} style={{ minHeight: "250px" }} className="px-5">
            <Row>
              {/* targetId => adv_keyword or adv_target */}
              <BiddingProgramLog
                targetId={target_id}
                targetType={targetType}
                marketPlace={marketPlace}
              />
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TargetDetails;
