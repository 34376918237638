import useLocalStorage from "./useLocalStorage";

const useTableSettings = (tableId, settingKey, defaultSettings) => {
  const { value: savedTableSettings, setValue: setSavedTableSettings } =
    useLocalStorage("tableSettings", {}) ?? {};

  const updateTableSettings = (updatedValue) => {
    let recentSettings = {};

    // Because we have different instances of the useTableSettings hook, below makes sure values other than
    // than current instances settingKey are up-to-date to avoid overwriting changes
    try {
      recentSettings = JSON.parse(localStorage.tableSettings) ?? {};
    } catch (error) {
      recentSettings = {};
    }

    setSavedTableSettings({
      ...recentSettings,
      [tableId]: {
        ...recentSettings[tableId],
        [settingKey]: updatedValue,
      },
    });
  };

  return {
    savedTableSettings:
      savedTableSettings?.[tableId]?.[settingKey] ?? defaultSettings,
    updateTableSettings,
  };
};

export default useTableSettings;
