import logo from "assets/images/logo/Trellis_Logo.png";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser, setCurrentUser } from "redux/actions/authActions";
import { getURLPrefix } from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

class TermsOfService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreement: "",
      tosVersion: "",
      lastUpdated: "",
      docCreatedTime: "",
      shortForm: true,
      justTerms: this.props.justTerms !== undefined ? true : false,
    };
  }

  componentDidMount() {
    this.fetchAgreement();
  }

  goToTop() {
    setTimeout(function () {
      document.getElementById("topOfDoc").scrollIntoView();
    }, 50);
  }

  fetchAgreement() {
    var AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/documents/?type=tos", {
        params: {},
        headers:
          this.props.justTerms === "showTerms"
            ? {}
            : { Authorization: AuthStr },
      })
      .then((res) => {
        var docLastUpdated =
          "Thank you for using Trellis. Please read and acknowledge our updated terms of service as of " +
          moment(res.data.created_datetime).format("dddd, MMMM Do, YYYY") +
          ".";
        this.setState(
          {
            agreement: res.data.html_text,
            tosVersion: res.data.version,
            lastUpdated: docLastUpdated,
            docCreatedTime: res.data.created_datetime,
          },
          () => {
            if (this.props.justTerms === "showCancellation") {
              setTimeout(function () {
                document.querySelector("#suspension").scrollIntoView();
              }, 50);
            }
          }
        );
      });
  }

  onAgreeing() {
    var AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    let data = {
      tos_version: this.state.tosVersion,
    };
    let reduxData = {
      latest_tos_agreement_status: "agreed",
    };
    axios
      .put(URL_PREFIX + "/api/userprofile/", data, {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.data === "Updated") {
          this.props.setCurrentUser(reduxData);
        }
      })
      .catch((err) => {});
  }

  render() {
    return (
      <SweetAlert
        title={
          <img src={logo} alt="Trellis" width="194" height="56" id="topOfDoc" />
        }
        style={{
          display: "block",
          overflowY: "auto",
          margin: "auto",
          width: this.state.shortForm && !this.state.justTerms ? "60%" : "80%",
          maxHeight: "80%",
          left: this.state.shortForm && !this.state.justTerms ? "20%" : "10%",
          top: this.state.shortForm && !this.state.justTerms ? "30%" : "10%",
          right: this.state.shortForm && !this.state.justTerms ? "20%" : "10%",
          bottom: this.state.shortForm && !this.state.justTerms ? "30%" : "10%",
        }}
        onConfirm={(e) => {
          this.state.justTerms ? this.props.closeTerms() : this.onAgreeing();
        }}
        confirmBtnText={this.state.justTerms ? "Close" : "I Agree"}
        onCancel={(e) => {
          this.state.justTerms
            ? this.props.closeTerms()
            : this.props.logoutUser();
        }}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="info"
        cancelBtnText="Cancel"
        showCancel={this.state.justTerms ? false : true}
        closeOnClickOutside={this.state.justTerms ? true : false}
      >
        {this.state.shortForm && !this.state.justTerms ? (
          <div>
            <Row>
              <Col
                md={10}
                mdOffset={1}
                style={{
                  textAlign: "center",
                  color: "#393939",
                  fontSize: "34px",
                  fontWeight: 700,
                  marginTop: "3rem",
                }}
              >
                Thank you for using Trellis!
              </Col>
            </Row>
            <Row>
              <Col
                md={10}
                mdOffset={1}
                style={{
                  textAlign: "center",
                  color: "#393939",
                  fontSize: "20px",
                  fontWeight: 500,
                  marginTop: "2rem",
                  marginBottom: "3rem",
                }}
              >
                Please read and acknowledge our updated{" "}
                <span
                  style={{
                    color: "#D91266",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    this.setState({ shortForm: false }, () => {
                      this.goToTop();
                    });
                  }}
                >
                  terms of service
                </span>{" "}
                as of{" "}
                {moment(this.state.docCreatedTime).format(
                  "dddd, MMMM Do, YYYY"
                )}
                .
              </Col>
            </Row>
          </div>
        ) : (
          <>
            <p
              style={{
                textAlign: "left",
                fontStyle: "italic",
                fontSize: "15px",
                color: "red",
              }}
              className="text-center"
            >
              {this.state.lastUpdated}
            </p>
            <div> {ReactHtmlParser(this.state.agreement)}</div>
          </>
        )}
      </SweetAlert>
    );
  }
}

TermsOfService.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  setCurrentUser,
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TermsOfService));
