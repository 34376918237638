import React, { useState } from "react";
import { useFetch } from "hooks/api";
import { useParams } from "react-router-dom";
import ProductsTable from "components/custom/merchandise/ProductsTable";
import { Container as Grid, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AuthButton } from "components/core/basic/Button";
import api from "utils/api";
import { useMutation, useQueryClient } from "react-query";
import PlanSummary from "components/custom/merchandise/PlanSummary";
import DetailedProductSelector from "components/core/blocks/ProductSelector/DetailedProductSelector";
import TrellisBreadcrumb from "components/core/blocks/Breadcrumb";

const Plan = ({ marketPlace, user }) => {
  const { id } = useParams();
  const plan = useFetch(["plans", id], `/merchandise/plans/${id}/`);
  const [category, setCategory] = useState(null);
  const statsQuery = {
    market_place: marketPlace.marketPlace,
    plan: id,
  };
  if (category) {
    statsQuery["category"] = category;
  }
  const { data: statsData } = useFetch(
    ["plans_stats", id, marketPlace.marketPlace, category],
    "/merchandise/plan_stats",
    statsQuery,
    {
      select: (d) => d.data,
    }
  );
  const { data: categories } = useFetch(
    ["categories", id],
    `/merchandise/category_data/${id}/`,
    {},
    {
      select: (d) => d.data,
    }
  );

  const queryClient = useQueryClient();
  const createOrgProducts = useMutation(
    async (org_plan) => {
      let res = await api.post("/merchandise/plan_org_products/", org_plan);
      return res;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("plans");
        if (res.status === 201) {
          return console.log("res from new new product req => ", res);
        }
      },
    }
  );

  const [selectedOrgPlan, setSelectedOrgPlan] = useState(null);

  const orgPlansOptions =
    plan.data?.data?.org_plans?.map(({ org, ...orgPlan }) => ({
      value: orgPlan.id,
      label: `${org.org_type.charAt(0) + org.org_type.toLowerCase().slice(1)}`,
    })) ?? [];
  orgPlansOptions.push({ value: null, label: "All Channels" });

  const selectedCategoryName = categories?.find((c) =>
    c.orgs.find((oc) => category?.includes(oc.category_id))
  )?.name;

  const [inventoryState, setInventoryState] = useState(null);

  const [salesState, setSalesState] = useState(null);

  return (
    <>
      <TrellisBreadcrumb
        items={[
          { name: "Promotions Planning", href: "/user/merchandising/planning" },
          {
            name: plan?.data?.data?.title,
            href: `/user/merchandising/planning/plans/${plan?.data?.data?.id}`,
          },
        ]}
      />
      <Grid fluid style={{ padding: "3rem", minHeight: "calc(100vh - 150px)" }}>
        <Row>
          <Col xs={12}>
            {plan.isLoading && <p>Loading...</p>}
            {plan.isError && <p>Error Loading Plan</p>}
            {plan.isSuccess && (
              <>
                <Row style={{ marginBottom: "3rem" }}>
                  <Col xs={12}>
                    <PlanSummary
                      plan={plan.data.data}
                      stats={statsData}
                      planId={id}
                      marketPlace={marketPlace}
                      category={selectedCategoryName}
                    />
                  </Col>
                </Row>
                {/* New product selector */}
                <Row style={{ marginBottom: "3rem" }}>
                  <Col xs={12} lg={9}>
                    <DetailedProductSelector
                      selectorId="merch-planning"
                      saveProductSelections={async (products) => {
                        if (products?.length > 0) {
                          for (const product of products) {
                            const org_plan_id = plan.data.data.org_plans.find(
                              (org_plan) => org_plan.org_id === product.org_id
                            ).id;
                            await createOrgProducts.mutate({
                              plan_id: plan.data.data.id,
                              product_id: product,
                              org_plan_id,
                            });
                          }
                        }
                      }}
                      isCreativeProductSelector={false}
                      isLandingPageProductSelector={false}
                      review={false}
                      isModal={true}
                      processTableData={(filteredData, productData) => {
                        return filteredData
                          ? filteredData
                          : productData?.data
                              ?.map((p) => (p.is_parent ? p.children : p))
                              .flat() ?? [];
                      }}
                      mediaPlanSelector={false}
                      assessChecked={(
                        selectedProducts,
                        productData,
                        filteredData,
                        assessSelectAllCheck = false
                      ) => {
                        if (assessSelectAllCheck) {
                          return (
                            selectedProducts?.length >=
                            productData?.data
                              ?.map((p) => (p.is_parent ? p.children : p))
                              .flat()?.length
                          );
                        }
                      }}
                      getPreselectedProducts={() => {
                        let planProducts =
                          plan.data.data.org_plans[0].plan_org_products?.map(
                            (p) => p.product.id
                          );
                        return planProducts;
                      }}
                      orgPlansOptions={
                        plan.data?.data?.org_plans?.map(
                          ({ org, ...orgPlan }) => {
                            return {
                              value: org.id,
                              label: `${
                                org.org_type.charAt(0) +
                                org.org_type.toLowerCase().slice(1)
                              } - ${org.name}`,
                            };
                          }
                        ) ?? []
                      }
                      selectedOrgPlan={selectedOrgPlan}
                      columnsToRender={[
                        "select",
                        "title",
                        "inventoryState",
                        "salesState",
                        "margin",
                        "minMargin",
                        "maxDiscount",
                        "monthlyUnitsSold",
                        "inventoryCount",
                        "netPrice",
                        { id: "grossPrice", checked: false },
                        { id: "adSales", checked: false },
                        { id: "adSpend", checked: false },
                        { id: "acos", checked: false },
                      ]}
                      additionalQueryParams={{ merchandise: true }}
                      user={user}
                      disableSelectAllCheckbox={(
                        selectedProducts,
                        productData
                      ) => selectedProducts.length >= productData?.data?.length}
                      disableRowCheckbox={(props) => {
                        let disabled =
                          plan.data.data.org_plans[0].plan_org_products?.some(
                            (p) => p.product.id === props.row._original.id
                          );
                        return disabled;
                      }}
                      hideClearAll={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <ProductsTable
                      plan={plan.data.data}
                      selectedOrgPlan={selectedOrgPlan}
                      marketPlace={marketPlace}
                      orgPlansOptions={orgPlansOptions}
                      setSelectedOrgPlan={setSelectedOrgPlan}
                      categories={categories}
                      onCategoryChange={setCategory}
                      inventoryState={inventoryState}
                      setInventoryState={setInventoryState}
                      salesState={salesState}
                      setSalesState={setSalesState}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <AuthButton title="Simulate" className="logout_button" />{" "}
                    <AuthButton title="Recommend" className="logout_button" />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(Plan);
