import React from "react";
import ReactTooltip from "react-tooltip";

export const NEXT_PAGE_TOOLTIP = "Next page";
export const PREVIOUS_PAGE_TOOLTIP = "Previous page";
export const TOGGLE_FILTERS_TOOLTIP = "Show/hide table filters";
export const RESET_FILTERS_TOOLTIP = "Reset table filters";
export const TOGGLE_ADVANCED_FILTERS = "Toggle advanced filters";
export const SORT_ALPHABETICALLY_ASCENDING = "Sort alphabetically ascending";
export const SORT_ALPHABETICALLY_DESCENDING = "Sort alphabetically descending";
export const RESET_PB_CHART_TOOLTIP =
  "Reset chart to display category-level data";
export const AD_ASIN_SKU_TAGLIST_TOOLTIP =
  "Add products to media plan by copying a list of ASINs or SKUs";
export const AD_ITEM_ID_SKU_TAGLIST_TOOLTIP =
  "Add products to media plan by copying a list of item IDs or SKUs";

export const ToolTips = (props) => (
  <>
    <a
      style={{
        color: "#7f7f7f",
        opacity: "0",
        zIndex: 10000,
        position: "absolute",
        margin: props.margin ? props.margin : null,
        width: props.width ? props.width : "inherit",
      }}
      data-for={props.id}
      data-tip={props.toolTip}
    >
      {" "}
      <i className="fa" />
      {"     "}
    </a>
    <div
      style={{
        fontSize: "20px",
        textTransform: "none",
        letterSpacing: "1.0px",
        fontWeight: 500,
      }}
    >
      <ReactTooltip
        id={props.id}
        place={props.position}
        type="info"
        effect="solid"
        textColor="#fff"
        backgroundColor="#2E0054"
        className="tooltip-message"
        clickable={true}
        globalEventOff="click"
      >
        {props.children}
      </ReactTooltip>
    </div>
  </>
);

export default ToolTips;
