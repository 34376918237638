const getQueryValue = (key) => {
  let query = window.location.search.substring(1);
  let keys = query.split("&");
  for (let i = 0; i < keys.length; i++) {
    let pair = keys[i].split("=");
    if (pair[0] === key) {
      return pair[1];
    }
  }
  return "all";
};

export const getHashValue = (key, defaultValue) => {
  let query = window.location.hash.substring(1);
  let keys = query.split("&");
  for (let i = 0; i < keys.length; i++) {
    let pair = keys[i].split("=");
    if (pair[0] === key) {
      return pair[1];
    }
  }
  return defaultValue;
};

export default getQueryValue;
