import React, { useState, useMemo } from "react";
import { useFetch } from "hooks/api";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col, Badge } from "react-bootstrap";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import DetailedMetric from "components/core/blocks/DetailedMetric";
import { LAUNCH_STATES } from "components/custom/merchandise/launch/constants";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import { SALE_STATES } from "components/core/blocks/AdPlan/constants";
import HiddenToolTip from "utils/hiddenToolTips";
import PlanStatus from "../../components/custom/merchandise/PlanStatus";
import LaunchPlanningDialogue from "components/custom/merchandise/launch/LaunchPlanningDialogue";
import LaunchEditModal from "components/custom/merchandise/launch/LaunchEditModal";
import api from "utils/api";
import { useMutation, useQueryClient } from "react-query";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import { trellisPalette } from "components/custom/analytics/palettes";

import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import withMediaQuery from "hocs/withMediaQuery";
import ToolTips from "utils/toolTips";
import Accordion from "components/core/basic/Accordion";
import Loading from "components/core/blocks/Loading";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import useDebounce from "hooks/useDebounce";
import { buildMetric } from "./metrics";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import TrComponent from "components/custom/merchandise/TrComponent";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import DownloadReport from "modules/perf_reports/DownloadReport";

import useColumnSelector from "hooks/useColumnSelector";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import ProductCell from "../../components/custom/merchandise/cells/ProductCell";
import { snakeCaseToSentenceCase } from "utils/formatText";
import CogsEditModal from "./CogsEditModal";
import LaunchRetrospectiveDialogue from "components/custom/merchandise/launch/LaunchRetrospectiveDialogue";
import ProductCard from "components/custom/merchandise/ProductCard";

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const makeColumn =
  (
    data,
    marketPlace,
    mobileStyle,
    date,
    setIsExpanded,
    launchData,
    additionalBadges,
    selectedProduct,
    setSelectedProduct
  ) =>
  (header, key, type, options) => {
    const { hideFilter } = options ?? {};

    const defaultAccessor = (p) => p[key];
    const accessor = options?.accessor ?? defaultAccessor;
    const defaultFormat = ({ value }) => value;
    const fmt =
      options?.formatter ??
      {
        expander: ({ row, value }) => (
          <>
            <ExpanderCell
              row={row}
              valueAccessor={"product_id"}
              isExpanded={value}
              setIsExpanded={setIsExpanded}
            />
          </>
        ),
        currency: ({ value }) =>
          formatCurrency(
            value,
            marketPlace.marketPlace,
            options?.clipDecimalPlaces ?? true
          ),
        number: ({ value }) =>
          formatNumber(value, {}, options?.decimalPlaces ?? 1),
        percent: ({ value }) =>
          formatPercent(value, options?.decimalPlaces ?? 1),
        cogs: ({ row, value }) => {
          const { setProductEditModalDetails, setShowProductEditModal } =
            options?.cellFunctions;
          return (
            <>
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setProductEditModalDetails(row._original);
                  setShowProductEditModal(true);
                }}
              >
                {formatCurrency(
                  row._original.landed_cost + row._original.shipping_cost,
                  marketPlace.marketPlace
                )}
                <i className="fa fa-pencil pl-3 text-muted"></i>
              </div>
            </>
          );
        },
        stock: ({ row, value }) => {
          let stockStatus = row?._original?.stock_status
            ? row?._original?.stock_status
            : "IN_STOCK";
          return <>{snakeCaseToSentenceCase(stockStatus)}</>;
        },
        product: ({ row, value }) => {
          if (!value) {
            return row?._original?.sku;
          }
          return (
            <ProductCell
              value={value}
              row={row}
              product={{ ...row._original, id: row._original?.product_id }}
              additionalBadges={
                row._original?.vine_program_enrolled ? (
                  <Badge
                    bsClass="badge"
                    className="badge-secondary"
                    style={{ marginLeft: "0.5rem", backgroundColor: "#c43066" }}
                    pill
                  >
                    Vine
                  </Badge>
                ) : null
              }
              salesStateArray={[
                SALE_STATES.BEST_SELLER,
                SALE_STATES.NORMAL,
                SALE_STATES.CLEARANCE,
                SALE_STATES.NEW_PRODUCT,
              ]}
              showSalesLabelBadge={
                row?._original?.sales_state?.toUpperCase() !==
                  SALE_STATES.NEW_PRODUCT.value &&
                launchData?.state?.toUpperCase() ===
                  LAUNCH_STATES.LAUNCHED.value
              }
              selectedProduct={
                launchData?.products?.length > 1 ? selectedProduct : null
              }
              setSelectedProduct={
                launchData?.products?.length > 1 ? setSelectedProduct : null
              }
              wrapProductTitle={true}
            />
          );
        },
        percentDiff: ({ value }) => (
          <span
            style={{
              color: value > 0 ? trellisPalette[12] : trellisPalette[17],
            }}
          >
            {formatPercent(value, 0, true)}
          </span>
        ),
      }?.[type] ??
      defaultFormat;
    const parser =
      {
        currency: parseFormattedCurrency,
        number: parseFormattedNumber,
        percent: parseFormattedPercentage,
      }?.[type] ?? defaultFormat;

    let width;
    if (mobileStyle) {
      width = options?.mobileWidth;
    } else {
      width = options?.width;
    }

    // Handle percent filter formatting
    if (type === "percent") {
      data = data.map((row) => {
        Object.keys(row).forEach((propertyKey) => {
          if (propertyKey === key) {
            row["formatted_" + propertyKey] = row[propertyKey] * 100;
          }
        });

        return row;
      });
    }

    return {
      Header: header,
      id: key,
      accessor,
      checked: options?.checked ?? true,
      maxWidth: options?.maxWidth,
      minWidth: options?.minWidth,
      isStatic: options?.isStatic,
      width,
      height: 30,
      className: options?.className ?? "text-end",
      headerClassName: options?.className ?? "text-end",
      Cell: (props) => {
        if (options?.showDot ?? true) {
          const percentKey = `${key}_percent`;
          const percent = props.original?.[percentKey] ?? null;
          const isNegative = options?.isNegative ?? false;
          let cellClasses = options?.cellClasses ?? "";
          const compareValue = options?.compareValue;
          const staticCompareValue = options?.staticCompareValue;
          let color = "#666";

          let launchDuration = moment(launchData.end_date).diff(
            moment(launchData.start_date),
            "days"
          );
          let daysIntoLaunch = moment().diff(
            moment(launchData.start_date),
            "days"
          );
          let progressPercent = daysIntoLaunch / launchDuration;
          let currentTarget = staticCompareValue
            ? compareValue
            : (compareValue * progressPercent) / launchData?.products?.length;

          if (compareValue && props.value > currentTarget) {
            color = isNegative ? trellisPalette[17] : trellisPalette[12];
          }
          if (compareValue && props.value < currentTarget) {
            color = isNegative ? trellisPalette[12] : trellisPalette[17];
          }
          let totalRow =
            props.original.sku === "Launch Goal" ||
            props.original.sku === "Launch Total";

          if (totalRow) {
            cellClasses = "py-4";
          }
          if (totalRow && percentKey === "isExpanded_percent") {
            return <></>;
          }
          return (
            <div className={cellClasses}>
              {fmt({ ...props, date })}

              <span
                style={{
                  color,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                {percent === null ? "" : formatPercent(percent, 0, true)}
                {/* {compareValue && props.original.sku !== "Launch Goal" ? (
                  <> ({formatPercent(props.value / compareValue)})</>
                ) : null} */}
              </span>
              {compareValue && props.original.sku !== "Launch Goal" && (
                <>
                  <HiddenToolTip
                    toolTip={
                      staticCompareValue
                        ? "Showing listing performance trend vs. launch goals"
                        : "Showing listing performance trend vs. launch goals based on how far into the launch divided by the number of launch products."
                    }
                    position="top"
                  />
                  <span
                    style={{
                      color,
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {compareValue
                      ? staticCompareValue
                        ? formatPercent(props.value / compareValue, 0, true)
                        : totalRow
                        ? formatPercent(props.value / compareValue, 0, true)
                        : formatPercent(
                            (props.value / compareValue) *
                              launchData?.products?.length,
                            0,
                            true
                          )
                      : ""}
                  </span>
                </>
              )}
            </div>
          );
        }
        return fmt({ ...props, date });
      },

      style: {},
      filterMethod: (filter, row) => {
        const formattedPercentAccessor = (p) => p[`formatted_${key}`];
        const value =
          type === "percent"
            ? formattedPercentAccessor(row._original)
            : accessor(row._original);
        return filterRanges(filter, value, parser, marketPlace.marketPlace);
      },
      Filter: ({ filter, onChange }) => {
        if (hideFilter) {
          return null;
        }

        return generateFilterMarkup(filter, onChange, key, "range", type, {
          ranges: generateRanges(
            data ?? [],
            5,
            type === "percent" ? `formatted_${key}` : key,
            "number",
            marketPlace.marketPlace
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

const Table = ({
  stats,
  marketPlace,
  mobileStyle,
  columnSpecs = [],
  date,
  ExpandedContent,
  user,
  isLoading = false,
  additionalBadges,
  launchData,
  selectedProduct,
  setSelectedProduct,
  onUpdateStatus,
  expandedRow,
  setExpandedRow,
  preparingState,
  hidePagination,
  noHeader,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [isExpanded, setIsExpanded] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [colToggle] = useState();

  let debouncedSearchFilter = useDebounce(searchFilter, 275);

  const data = useMemo(
    () => {
      return (
        stats
          ?.map((p) => (p.product_id === isExpanded ? { ...p, isExpanded } : p))
          ?.filter((p) => {
            if (debouncedSearchFilter) {
              const searchable = `${p.category_name} ${p.product_title} ${p.sku} ${p.asin} `;
              return (
                searchable
                  .toLowerCase()
                  ?.indexOf(debouncedSearchFilter.toLowerCase()) > -1
              );
            }
            return true;
          }) ?? []
      );
    },
    // Below linting disabled to take clearFilters in to recalcalculate data on filter reset
    // Without it, filter specs from react-table must be stored at this level and manually cleared on each reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stats, debouncedSearchFilter, clearFilters, isExpanded]
  );

  const createColumn = makeColumn(
    data,
    marketPlace,
    mobileStyle,
    date,
    setIsExpanded,
    launchData,
    additionalBadges,
    selectedProduct,
    setSelectedProduct,
    expandedRow,
    setExpandedRow
  );

  const [columns, columnSelectorProps] = useColumnSelector(
    () =>
      columnSpecs.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [columnSpecs, stats, mobileStyle, colToggle],
    "advertisingDashboard"
  );
  const columnOrder = columns.map((col) => col.id);

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }
  const HiddenTheadComponent = (props) => null;

  return (
    <>
      <ReactTable
        showPaginationBottom={false}
        showPaginationTop={!hidePagination}
        PaginationComponent={ReactTablePagination}
        columns={columns}
        data={data}
        defaultPageSize={10}
        minRows={0}
        className="-highlight"
        filterable={showFilters}
        sortable={!hidePagination}
        state={columnOrder}
        TrComponent={TrComponent}
        TheadComponent={noHeader && HiddenTheadComponent}
        defaultSorted={[{ id: "ad_spend", desc: false }]}
        getTrProps={(table, row) => {
          let style = {
            border: "none",
            padding: "1rem 0",
            maxWidth: "100%",
          };
          let expandedChildren = (
            <>
              <ProductCard
                marketPlace={{ marketPlace: marketPlace }}
                productId={row?.original?.product_id}
                user={user}
                initialTab={
                  launchData?.state?.toLowerCase() ===
                  LAUNCH_STATES.LAUNCHED.value.toLowerCase()
                    ? "launch"
                    : "overview"
                }
                additionalTabs={
                  preparingState ||
                  launchData?.state?.toUpperCase() ===
                    LAUNCH_STATES.INTRODUCED.value
                    ? []
                    : [
                        {
                          title: "Launch",
                          content: (
                            <LaunchRetrospectiveDialogue
                              productStats={row?.original ?? {}}
                              productDetails={row?.original ?? {}}
                              launchData={launchData}
                              productId={row?.original?.product_id}
                              productState={row?.original?.sales_state}
                              onUpdateStatus={onUpdateStatus}
                              marketPlace={marketPlace}
                            />
                          ),
                        },
                      ]
                }
              />
            </>
          );
          return { row, style, expandedChildren };
        }}
        getTheadFilterProps={(props) => {
          if (clearFilters) {
            props.filtered.forEach((filter, index) => {
              filter.value = "all";
            });
            setClearFilters(false);
          }
          return { style: { display: "flex" } };
        }}
        getPaginationProps={() => ({
          updateSearch: setSearchFilter,
          clearFilters: () => {
            setSearchFilter("");
            setClearFilters(true);
          },
          hideFilters: false,
          showFilters: showFilters,
          setShowFilters: setShowFilters,
          clearSearchTermFilters: () => setSearchFilter(""),
          searchTerm: searchFilter,
          table: "plan-products",
          style: { marginBottom: "2rem" },
          carouselLayout: false,
          disableSearchFocus: true,
          filterControlsWidth: "70%",
          columnSelectorProps: columnSelectorProps,
        })}
      />
    </>
  );
};

const LaunchDetails = ({
  user,
  marketPlace: { marketPlace },
  location,
  mediaQuery,
  modules,
  amz_onboard,
}) => {
  const [renderChecklist, setRenderChecklist] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [productEditModalDetails, setProductEditModalDetails] = useState();
  const [showProductEditModal, setShowProductEditModal] = useState(false);

  const { date, group } = useDates();
  const { search } = useLocation();
  const { id } = useParams();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const queryClient = useQueryClient();

  const baseQuery = { marketplace: marketPlace };

  // http://localhost:8000/merchandise/product_launch/1
  const { data: launchData, isLoading: launchDataLoading } = useFetch(
    ["launch", marketPlace, id],
    `/merchandise/product_launch/${id}`,
    {},
    {
      enabled: !!id,
      select: (d) => d.data,
    }
  );

  // http://localhost:8000/merchandise/product_launch_product_data/1
  const { data: launchProductData } = useFetch(
    ["launch_products", marketPlace, id],
    `/merchandise/product_launch_product_data/${id}`,
    { ...baseQuery },
    {
      enabled: !!id,
      select: (d) => d.data,
    }
  );

  let startDate = urlParams.get("start") ?? launchData?.start_date.slice(0, 10);

  if (
    launchData?.state?.toUpperCase() === LAUNCH_STATES.PREPARING.value &&
    !urlParams.get("start")
  ) {
    startDate = moment().subtract(30, "day").format("YYYY-MM-DD");
  }

  let launchStatsParams = {
    filters: `product_ids: ${launchData?.products?.join(", ")}`,
    mp: marketPlace,
    date_group: urlParams.get("group"),
    group_by: "product_id",
    start_date: startDate,
    end_date: launchData?.end_date.slice(0, 10),
    empty: true,
  };

  // http://localhost:8000/api/gvads/advproduct/?plp=77
  const { data: launchProductAds } = useFetch(
    ["launch_product_ads", marketPlace, id],
    `/api/gvads/advproduct/`,
    { plp: id },
    {
      enabled: !!id,
      select: (d) => d.data,
      default: [],
    }
  );

  const { data: launchPromoPlans } = useFetch(
    ["launch_product_promo_plans", marketPlace, id],
    `/merchandise/plans/`,
    { plp: id },
    {
      enabled: !!id,
      select: (d) => d.data?.results,
      default: [],
    }
  );

  // http://localhost:8000/api/data_report/product_report?filters=product_ids: 48889, 48888
  const { data: launchProductStats, isLoading } = useFetch(
    [
      "launch_product_stats",
      marketPlace,
      id,
      launchStatsParams,
      group,
      urlParams,
    ],
    `/api/data_report/product_report`,
    { ...launchStatsParams },
    {
      enabled: !!launchData?.products?.length,
      select: (d) => d.data,
    }
  );

  const { data: categoriesData } = useFetch(
    "categories",
    "/api/gvads/categories/",
    { marketplace: marketPlace, channel: "amazon" },
    {}
  );

  const updateProductState = useMutation(
    async (data) => {
      return api.patch(`/merchandise/gvproducts/${data.id}/`, data);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries("plans");
      },
    }
  );

  const onUpdateStatus = (updateData) => {
    updateProductState.mutate({
      sales_state: updateData.sales_state,
      id: updateData.id,
    });

    // invalidateQueries below declares all cached queries as stale when left empty, needed for product sales state changes
    queryClient.invalidateQueries();
  };

  const onUpdateAllStatuses = () => {
    launchData.products.forEach((product) => {
      onUpdateStatus({
        id: product,
        sales_state: launchProductData?.find((p) => p.id === product)
          ?.recommended_sales_state,
      });
    });
  };

  const statsToRender = useMemo(() => {
    const statsToInclude = ["bsr", "cost", "ad_sales", "page_views"];
    const { goal_metrics: goals, exit_criteria: exitCriteria } =
      launchData ?? {};
    if (exitCriteria?.sales) {
      statsToInclude.push("sales");
    }

    if (exitCriteria?.units) {
      statsToInclude.push("units");
    }

    if (goals?.acos) {
      statsToInclude.push("roas");
    }

    if (exitCriteria?.num_of_ratings) {
      statsToInclude.push("ratings_total");
    }

    if (goals?.min_rating) {
      statsToInclude.push("rating");
    }

    if (goals?.margin) {
      statsToInclude.push("margin");
    }

    return { stats: statsToInclude };
  }, [launchData]);

  const preparingState =
    launchData?.state?.toUpperCase() === LAUNCH_STATES.PREPARING.value;

  // Below memo controls whether or not to render checklist
  // Currently showing right after launch or when details visited without any associated ads or promo plans
  let showChecklist = useMemo(() => {
    return (
      preparingState &&
      (location?.state?.showChecklist ||
        !launchProductAds?.length ||
        !launchPromoPlans?.length)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchProductAds, location?.state?.showChecklist, launchData, id]);

  const [
    launchMetrics,
    launchProductColumns,
    preparingColumns,
    scorecardColumns,
  ] = useMemo(() => {
    if (launchProductStats) {
      return [
        [
          buildMetric(
            marketPlace,
            launchProductStats,
            "sales",
            "sales_percent",
            preparingState
              ? {
                  override: true,
                  overrideValue: launchData?.exit_criteria?.sales,
                  overrideLabel: "Target Sales",
                }
              : {
                  overrideTarget: true,
                  overrideTargetLabel: "vs. Goal",
                  overrideTargetValue: launchData?.exit_criteria?.sales,
                  overrideTargetFormatter: (v) =>
                    formatCurrency(v, marketPlace, false, false, true),
                }
          ),
          buildMetric(
            marketPlace,
            launchProductStats,
            "ad_sales",
            "ad_sales_percent"
          ),
          buildMetric(marketPlace, launchProductStats, "cost", "cost_percent", {
            overrideTarget: true,
            overrideTargetLabel: "vs. Goal",
            overrideTargetValue: launchData?.advertising_budget,
            overrideTargetLower: true,
            overrideTargetFormatter: (v) =>
              formatCurrency(v, marketPlace, false, false, true),
          }),
          buildMetric(
            marketPlace,
            launchProductStats,
            "units",
            "units_percent",
            preparingState
              ? {
                  override: true,
                  overrideValue: launchData?.exit_criteria?.units,
                  overrideLabel: "Target Units",
                }
              : {
                  overrideTarget: true,
                  overrideTargetLabel: "vs. Goal",
                  overrideTargetValue: launchData?.exit_criteria?.units,
                  overrideTargetFormatter: (v) => formatNumber(v),
                }
          ),
          buildMetric(
            marketPlace.marketPlace,
            launchProductStats,
            "rating",
            "rating_percent",
            {
              override: preparingState,
              overrideValue: launchData?.goal_metrics?.min_rating,
              overrideLabel: "Target Rating",
              overrideTarget: !preparingState,
              overrideTargetLabel: "vs. Goal",
              overrideTargetValue: launchData?.goal_metrics?.min_rating,
              overrideTargetFormatter: (v) => formatNumber(v, {}, 1),
              metric: {
                decimalPlaces: 1,
                name: "Rating",
                toolTip:
                  "The average rating across all products enrolled in New Product Introduction",
                totalOverrideAccessor: preparingState ? null : "last_rating",
              },
            }
          ),
          buildMetric(
            marketPlace.marketPlace,
            launchProductStats,
            "ratings_total",
            "ratings_total_percent",
            {
              override: preparingState,
              overrideValue: launchData?.exit_criteria?.num_of_ratings,
              overrideLabel: "Target # of Ratings",
              overrideTarget: !preparingState,
              overrideTargetLabel: "vs. Goal",
              overrideTargetValue: launchData?.exit_criteria?.num_of_ratings,
              overrideTargetFormatter: (v) => formatNumber(v, {}, 0),
              metric: {
                name: "# of Ratings",
                toolTip:
                  "The number of ratings for all products enrolled in New Product Introduction",
                totalOverrideAccessor: preparingState
                  ? null
                  : "last_ratings_total",
              },
            }
          ),
          buildMetric(marketPlace, launchProductStats, "bsr", "bsr_percent", {
            metric: {
              totalOverrideAccessor: "last_bsr_rank",
            },
          }),
          buildMetric(
            marketPlace,
            launchProductStats,
            "page_views",
            "page_views",
            {
              overrideTarget: !preparingState,
              overrideTargetLabel: null,
              overrideTargetValue: null,
            }
          ),
          buildMetric(
            marketPlace.marketPlace,
            launchProductStats,
            "margin",
            "margin_delta",
            preparingState
              ? {
                  override: true,
                  overrideValue: launchData?.goal_metrics?.margin / 100,
                  overrideLabel: "Target Margin",
                }
              : {
                  overrideTarget: true,
                  overrideTargetLabel: "vs. Goal",
                  overrideTargetValue: launchData?.goal_metrics?.margin / 100,
                }
          ),
          buildMetric(
            marketPlace,
            launchProductStats,
            "roas",
            "roas_delta",
            preparingState
              ? {
                  override: true,
                  overrideValue: 1 / launchData?.goal_metrics?.acos,
                  overrideLabel: "Target ROAS",
                }
              : {
                  overrideTarget: true,
                  overrideTargetLabel: "vs. Goal",
                  overrideTargetValue:
                    1 / (launchData?.goal_metrics?.acos / 100),
                  overrideTargetFormatter: (v) => formatNumber(v, {}, 1),
                }
          ),
        ]
          .filter((m) =>
            preparingState
              ? [
                  "sales",
                  "units",
                  "ratings_total",
                  "rating",
                  "margin",
                  "roas",
                ]?.includes(m.id)
              : m
          )
          ?.filter((m) => {
            return statsToRender?.stats?.includes(m.id);
          }),
        [
          col("", "expander", "isExpanded", {
            width: 60,
            hideFilter: true,
            isStatic: true,
          }),
          col("Product", "product", "product_title", {
            className: "text-left font-weight-bold",
            width: 300,
            mobileWidth: 200,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
            isStatic: true,
          }),

          col("COGs", "cogs", "cogs", {
            cellFunctions: {
              productEditModalDetails,
              setProductEditModalDetails,
              showProductEditModal,
              setShowProductEditModal,
            },
            cellClasses: 'd-flex justify-content-end align-items-center"',
          }),
          col("Price", "currency", "product_price"),
          col("Stock", "stock", "stock_status"),
          col("Total Sales", "currency", "total_sales"),
          col("Ad Sales", "currency", "ad_sales"),
          col("Ad Spend", "currency", "cost"),
          col("Units", "number", "total_units"),
          col("Profit", "currency", "profit"),
          col("Margin", "percent", "margin"),
          col("# of Ratings", "number", "last_ratings_total"),
          col("Rating", "number", "last_rating"),
          col("Page Views", "number", "page_views"),
          col("Impressions", "number", "impressions"),
          col("Clicks", "number", "clicks"),
          col("BSR", "number", "last_bsr_rank"),
          col("ROAS", "number", "roas"),
        ],
        [
          col("", "expander", "isExpanded", {
            width: 40,
            hideFilter: true,
            isStatic: true,
          }),
          col("Product", "product", "product_title", {
            className: "text-left font-weight-bold",
            width: 750,
            mobileWidth: 200,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
            isStatic: true,
          }),
          col("COGs", "cogs", "cogs", {
            cellFunctions: {
              productEditModalDetails,
              setProductEditModalDetails,
              showProductEditModal,
              setShowProductEditModal,
            },
            width: 150,
            cellClasses: 'd-flex justify-content-end align-items-center"',
          }),
          col("Price", "currency", "product_price", { width: 150 }),
          col("Stock", "stock", "stock_status", { width: 150 }),
        ],
        [
          col("", "expander", "isExpanded", {
            width: 60,
            hideFilter: true,
            isStatic: true,
          }),
          col("Product", "product", "product_title", {
            className: "text-left font-weight-bold",
            width: 300,
            mobileWidth: 200,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
            isStatic: true,
          }),
          // col("Product", "text", "sku"),
          col("Total Sales", "currency", "total_sales", {
            compareValue: launchData.exit_criteria?.sales,
          }),
          col("Units", "number", "total_units", {
            compareValue: launchData.exit_criteria?.units,
          }),
          col("# of Ratings", "number", "last_ratings_total", {
            compareValue: launchData?.exit_criteria?.num_of_ratings,
          }),
          col("Rating", "number", "last_rating", {
            compareValue: launchData?.goal_metrics?.min_rating,
            staticCompareValue: true,
          }),
          col("ROAS", "number", "roas", {
            compareValue:
              1 / (parseFloat(launchData?.goal_metrics?.acos) / 100),
            staticCompareValue: true,
          }),
          col("Margin", "percent", "margin", {
            compareValue: launchData.goal_metrics?.margin / 100,
            staticCompareValue: true,
          }),
        ],
      ];
    }
    return [[], [], [], [], [], []];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchProductStats]);

  return (
    <DatesProvider
      period={urlParams.get("period") ?? "custom"}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={startDate}
      end={launchData?.end_date.slice(0, 10)}
    >
      <BreadcrumbDates
        items={[
          {
            name: "New Product Launch",
            href: `/user/productlaunch?start=${moment()
              .subtract(30, "days")
              ?.format("YYYY-MM-DD")}`,
          },
          {
            name: launchData?.name,
            href: `/user/productlaunch/launches/${launchData?.id}`,
          },
        ]}
        groupByOnly={!preparingState}
        hideRange={preparingState}
        hideCompare={true}
      />
      {isLoading || !launchData?.state ? (
        <Loading />
      ) : (
        <Row className="px-5">
          <>
            <MetricsCard
              id={"productLaunchDetails"}
              mobileStyle
              title={launchData?.name}
              byline={
                <>
                  {moment(launchData?.start_date).format("MMMM Do")} to{" "}
                  {moment(launchData?.end_date).format("MMMM Do")}
                </>
              }
              defaultStateContent={preparingState ? <></> : null}
              selectedCategory={launchData.category}
              isLoading={isLoading || launchDataLoading}
              status={
                <div
                  style={{
                    display: "flex",
                    justifyContent: mediaQuery ? "center" : "space-between",
                    textAlign: mediaQuery ? "center" : "inherit",
                    marginTop: mediaQuery ? "30px" : "inherit",
                  }}
                >
                  {launchData?.state?.toUpperCase() ===
                    LAUNCH_STATES.INTRODUCED.value && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "-1rem 3rem 0 auto",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "2.6rem",
                          color: "#403E3D",
                          fontWeight: 600,
                        }}
                      >
                        {(
                          (moment().diff(
                            moment(launchData?.start_date),
                            "days"
                          ) /
                            moment(launchData?.end_date).diff(
                              moment(launchData?.start_date),
                              "days"
                            )) *
                          100
                        ).toFixed(0) ?? 0}
                        %
                      </span>
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "#403E3D",
                          fontWeight: 600,
                          textTransform: "uppercase",
                          marginTop: "-.7rem",
                        }}
                      >
                        Complete
                      </span>
                    </div>
                  )}
                  <PlanStatus
                    status={launchData?.state?.toLowerCase()}
                    showLabel
                    due={launchData?.start_date}
                    end={launchData?.end_date}
                    start={launchData?.start_date}
                  />
                </div>
              }
              showMetricsSelector={true}
              defaultTargetColor={true}
              data={launchProductStats.date ?? []}
              initialSelectedMetric={
                preparingState
                  ? [
                      "total_sales",
                      "units",
                      "ratings_total",
                      "rating",
                      "margin",
                      "roas",
                    ]
                  : [
                      "total_sales",
                      "ad_sales",
                      "cost",
                      "units",
                      "number_of_ratings",
                      "rating",
                      "page_views",
                      "bsr",
                      "margin",
                      "acos",
                    ]
              }
              metrics={launchMetrics}
              group={urlParams.get("group") ?? "day"}
            >
              <Row style={{ paddingBottom: "150px" }}>
                <Row>
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: mediaQuery ? "center" : "flex-end",
                    }}
                    className="mt-3"
                  >
                    {launchData?.state?.toUpperCase() ===
                      LAUNCH_STATES.LAUNCHED.value &&
                      launchProductData?.some(
                        (p) => p.sales_state === "NEW_PRODUCT"
                      ) && (
                        <ConfirmationModal
                          title={"Confirm Launch Completion"}
                          onConfirm={onUpdateAllStatuses}
                          modalButtonText={"Close Launch"}
                          confirmButtonText={"Close Launch"}
                          confirmationMessage={
                            <>
                              <p>
                                Are you sure you want to mark this launch
                                complete?
                              </p>
                              <p>
                                After confirming, all products will be
                                automatically classified based on Trellis’
                                recommendations if they have not yet been
                                reviewed.
                              </p>
                            </>
                          }
                        />
                      )}

                    <LaunchEditModal launchData={launchData} id={id} />
                  </Col>
                </Row>
                {preparingState && (
                  <Row>
                    <Accordion
                      title={
                        <>
                          Next Steps
                          <ToolTips
                            toolTip={
                              "View recommended steps to make your launch as successful as possible"
                            }
                            id={"45"}
                            position={"top"}
                          />
                        </>
                      }
                      initialExpanded={true}
                    >
                      <Row>
                        <Col xs={12}>
                          <LaunchPlanningDialogue
                            launchData={launchData ?? {}}
                            launchProductAds={launchProductAds}
                            launchProductPromos={launchPromoPlans}
                            categories={categoriesData?.data ?? []}
                            marketPlace={marketPlace}
                            renderChecklist={renderChecklist}
                            setRenderChecklist={setRenderChecklist}
                            showChecklist={showChecklist}
                            modules={modules}
                            user={user}
                            amz_onboard={amz_onboard}
                          />
                        </Col>
                      </Row>
                    </Accordion>
                  </Row>
                )}
                {/* Launch Product Scorecard */}
                {!preparingState && (
                  <Accordion
                    title={
                      <>
                        Scorecard
                        <ToolTips
                          toolTip={
                            "Compare individual product performance against goals and exit criteria"
                          }
                          id={"45"}
                          position={"top"}
                        />
                      </>
                    }
                    initialExpanded={true}
                    height={"100%"}
                  >
                    <Row className="pt-3">
                      <Col xs={12}>
                        <Row>
                          {launchMetrics
                            ?.filter((m) =>
                              [
                                "sales",
                                "units",
                                "rating",
                                "ratings_total",
                                "margin",
                                "roas",
                              ]?.includes(m.id)
                            )
                            ?.map((m, i) => {
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={2}
                                  key={m.id}
                                  className={`mb-4 ${
                                    i % 2 === 0 ? "pl-2" : "pr-2"
                                  }`}
                                >
                                  <div className="">
                                    <div>
                                      <DetailedMetric
                                        center
                                        size={"small"}
                                        id={m.id}
                                        toolTip={m.toolTip}
                                        name={m.name}
                                        value={m.overrideValue ?? m.actual}
                                        target={m.target}
                                        formatter={m.formatter}
                                        targetFormatter={m?.targetFormatter}
                                        showTarget={true}
                                        showTargetLabel
                                        targetLabel={"vs. Goal"}
                                        targetColor={
                                          m.overrideValue
                                            ? m.overrideValue >
                                              parseFloat(m.target)
                                              ? "#007000"
                                              : "#d2222d"
                                            : m.actual > parseFloat(m.target)
                                            ? "#007000"
                                            : "#d2222d"
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-5">
                        <Table
                          stats={[
                            ...launchProductStats?.products?.map((p) => ({
                              ...p,
                              id: parseInt(p.product_id),
                            })),
                          ]}
                          launchData={launchData ?? []}
                          selectedProduct={selectedProduct}
                          setSelectedProduct={setSelectedProduct}
                          expandedRow={expandedRow}
                          setExpandedRow={setExpandedRow}
                          preparingState={preparingState}
                          marketPlace={marketPlace}
                          columnSpecs={scorecardColumns}
                          date={date}
                          user={user}
                          isLoading={isLoading}
                          onUpdateStatus={onUpdateStatus}
                        />
                        {/* <Table
                          stats={[
                            {
                              sku: "Launch Total",
                              roas: launchProductStats?.total?.roas,
                              margin: launchProductStats?.total?.margin,
                              total_sales:
                                launchProductStats?.total?.total_sales,
                              total_units:
                                launchProductStats?.total?.total_units,
                              last_rating:
                                launchProductStats?.total?.last_rating,
                              last_ratings_total:
                                launchProductStats?.total?.last_ratings_total,
                            },
                            {
                              sku: "Launch Goal",
                              roas:
                                1 /
                                (parseFloat(launchData?.goal_metrics?.acos) /
                                  100),
                              margin: launchData.goal_metrics?.margin / 100,
                              total_sales: launchData.exit_criteria?.sales,
                              total_units: launchData.exit_criteria?.units,
                              last_rating: launchData?.goal_metrics?.min_rating,
                              last_ratings_total:
                                launchData?.exit_criteria?.num_of_ratings,
                            },
                          ]}
                          launchData={launchData ?? []}
                          selectedProduct={selectedProduct}
                          setSelectedProduct={setSelectedProduct}
                          expandedRow={expandedRow}
                          setExpandedRow={setExpandedRow}
                          preparingState={preparingState}
                          marketPlace={marketPlace}
                          columnSpecs={scorecardColumns}
                          date={date}
                          user={user}
                          isLoading={isLoading}
                          filterable={false}
                          sortable={false}
                          onUpdateStatus={onUpdateStatus}
                          hidePagination={true}
                          noHeader={true}
                        /> */}
                      </Col>
                    </Row>
                  </Accordion>
                )}
                {/* LAUNCH PRODUCT DETAILS TABLE */}
                <Accordion
                  title={
                    <>
                      Products In Launch{" "}
                      <ToolTips
                        toolTip={
                          "Select a product to show individual product performance in the chart above"
                        }
                        id={"44"}
                        position={"top"}
                      />
                    </>
                  }
                  initialExpanded={preparingState}
                  height={"100%"}
                >
                  <Row>
                    <Col xs={12} className="mb-5">
                      {showProductEditModal && (
                        <CogsEditModal
                          showProductEditModal={showProductEditModal}
                          setShowProductEditModal={setShowProductEditModal}
                          productEditModalDetails={productEditModalDetails}
                          setProductEditModalDetails={
                            setProductEditModalDetails
                          }
                          marketPlace={marketPlace}
                        />
                      )}
                      <Table
                        stats={
                          launchProductStats?.products?.map((p) => ({
                            ...p,
                            id: parseInt(p.product_id),
                          })) ?? []
                        }
                        launchData={launchData ?? []}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        expandedRow={expandedRow}
                        setExpandedRow={setExpandedRow}
                        preparingState={preparingState}
                        marketPlace={marketPlace}
                        columnSpecs={
                          preparingState
                            ? preparingColumns
                            : launchProductColumns
                        }
                        date={date}
                        user={user}
                        isLoading={isLoading}
                        onUpdateStatus={onUpdateStatus}
                      />
                    </Col>
                  </Row>
                </Accordion>

                {!isLoading && (
                  <Col xs={12} className="text-right py-3">
                    <DownloadReport
                      url_path={"product_report"}
                      filters={launchStatsParams}
                      report_dl={"product"}
                    />
                  </Col>
                )}
              </Row>
            </MetricsCard>
          </>
        </Row>
      )}
    </DatesProvider>
  );
};

LaunchDetails.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  channel: state.channel,
  modules: selectModules(state),
  amz_onboard: state.amz_onboard,
});

export default withMediaQuery("(max-width:600px)")(
  connect(mapStateToProps)(LaunchDetails)
);
