import React, { useMemo, useState } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import CreateProgram from "../CreateProgram";
import EditProgram from "../EditProgram";
import { useFetch } from "hooks/api";
import ListPrograms from "../ListPrograms";
import { PrimaryButton } from "components/core/basic/Button";
import api from "utils/api";

const useActiveProgram = (defaultValue) => {
  const [activeProgram, setActiveProgram] = useState(defaultValue);
  return useMemo(() => {
    return [activeProgram || defaultValue, setActiveProgram];
  }, [activeProgram, defaultValue]);
};

const InlineBiddingPrograms = ({
  adPlanParams,
  defaultCreateProgram,
  modalDisplay,
  onCancel,
  hidden = false,
  parentError,
  onActivate,
  deferProgramCreation = false,
}) => {
  const [currentProgram, setCurrentProgram] = useState(null);
  const [createProgram, setCreateProgram] = useState(
    defaultCreateProgram ?? false
  );
  const [isFormDirty, setIsFormDirty] = useState(false);
  const queryClient = useQueryClient();

  const { id: adPlanId, bidding_program: biddingProgram } = adPlanParams;

  const [activeProgram, setActiveProgram] = useActiveProgram(biddingProgram);

  const activateProgram = useMutation(
    async (programId) => {
      await api.post(`/advertising/bidding/programs/activate`, {
        program_id: programId === "unset" ? null : programId,
        ad_plan_id: adPlanId,
      });
    },
    {
      onMutate: (id) => {
        setActiveProgram(id);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["programs"]);
        setActiveProgram(null);
      },
      onError: (error) => {
        setActiveProgram(null);
      },
    }
  );
  const { data, isLoading } = useFetch(
    ["programs"],
    "/programs/",
    {
      engine: "bidding",
    },
    { select: (data) => data?.data?.results ?? [] }
  );
  const programsData = useMemo(() => {
    return (
      data?.map((program) => ({
        ...program,
        active: activeProgram === program.id ? "active" : "inactive",
      })) ?? []
    );
  }, [activeProgram, data]);

  if (hidden) {
    return null;
  }

  return (
    <>
      {!modalDisplay && (
        <Row>
          <Col xs={12} className="fs-standard">
            <Breadcrumb>
              {(createProgram || currentProgram) && (
                <Breadcrumb.Item
                  onClick={() => {
                    setCreateProgram(false);
                    setCurrentProgram(null);
                  }}
                >
                  Bidding Rules
                </Breadcrumb.Item>
              )}
              {createProgram && (
                <Breadcrumb.Item active>Create</Breadcrumb.Item>
              )}
              {currentProgram && <Breadcrumb.Item active>Edit</Breadcrumb.Item>}
            </Breadcrumb>
          </Col>
        </Row>
      )}
      <Row>
        {parentError && !isFormDirty ? (
          <Col xs={12}>
            <small className="text-danger d-block pb-3">{parentError}</small>
          </Col>
        ) : (
          <></>
        )}

        <Col xs={12}>
          {createProgram && (
            <CreateProgram
              engine={"bidding"}
              adPlanId={adPlanId}
              deferProgramCreation={deferProgramCreation}
              onSuccess={(res) => {
                if (onActivate && typeof onActivate === "function") {
                  onActivate(res);
                }

                setCreateProgram(false);
                setCurrentProgram(null);
                queryClient.invalidateQueries({ queryKey: ["programs"] });
              }}
              onSubmit={(data, programType) => {
                if (onActivate && typeof onActivate === "function") {
                  const { name, start_date, end_date, ...templateFields } =
                    data;
                  let ui_state = templateFields;
                  let spec = {};
                  if (programType === "CUSTOM") {
                    ui_state = {};
                    spec = templateFields;
                  }
                  const body = {
                    name,
                    start_date,
                    end_date,
                    template_type: programType,
                    ui_state,
                    spec,
                    engine: "bidding",
                  };
                  onActivate(body);
                }
              }}
              onCancel={() => {
                if (modalDisplay) {
                  setCurrentProgram(null);
                } else {
                  setCreateProgram(false);
                  setCurrentProgram(null);
                }
                typeof onCancel === "function" && onCancel();
              }}
              overrideSize={modalDisplay ? "xs" : null}
              onFormChange={() => {
                if (!isFormDirty) {
                  setIsFormDirty(true);
                }
              }}
            />
          )}
          {currentProgram && (
            <EditProgram
              programId={currentProgram}
              onSuccess={() => {
                setCreateProgram(false);
                setCurrentProgram(null);
                queryClient.invalidateQueries({ queryKey: ["programs"] });
              }}
              onCancel={() => {
                setCreateProgram(false);
                setCurrentProgram(false);
              }}
            />
          )}
          {!createProgram && !currentProgram && (
            <>
              <Row>
                <Col xs={12}>
                  <ListPrograms
                    isLoading={isLoading}
                    programs={programsData}
                    onClickProgram={(id) => {
                      setCurrentProgram(id);
                      setCreateProgram(false);
                    }}
                    onToggleProgram={(id, value) => {
                      console.log(
                        "onToggleProgram",
                        id,
                        value,
                        activateProgram.isLoading
                      );
                      if (activateProgram.isLoading) return;
                      if (value === "active") {
                        activateProgram.mutate(id);
                      } else {
                        activateProgram.mutate("unset");
                      }
                    }}
                    title={"Bidding Rules"}
                    engine={"bidding"}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} className="d-flex justify-content-start">
                  <PrimaryButton nowrap onClick={() => setCreateProgram(true)}>
                    Create
                  </PrimaryButton>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default InlineBiddingPrograms;
