import api from "utils/api";
import { useMutation } from "react-query";
import { useProgramContext } from "./ProgramContext";

const useCreateProgram = ({
  programType,
  pricingPlanId,
  onSuccess,
  onError,
}) => {
  const { engine } = useProgramContext();

  const createProgram = useMutation(
    async (data) => {
      const { name, start_date, end_date, ...templateFields } = data;
      let ui_state = templateFields;
      let spec = {};
      if (programType === "CUSTOM") {
        ui_state = {};
        spec = templateFields;
      }
      const body = {
        name,
        start_date,
        end_date,
        template_type: programType,
        ui_state,
        spec,
        engine,
      };
      if (pricingPlanId) body["pricing_plan"] = pricingPlanId;
      const res = await api.post("/programs/", {
        ...body,
        status: "paused",
      });

      return res;
    },
    {
      onMutate: () => {},
      onSuccess: (res) => {
        if (onSuccess) onSuccess(res);
      },
      onError: (error) => {
        if (onError) onError(error);
        console.log(error);
      },
    }
  );
  return createProgram;
};

export default useCreateProgram;
