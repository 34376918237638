//Refresh Token Encrption Key
export const CLIENT_SIDE_ENCRYPTION_KEY = "4GbAiXVSX2oUphifOaLXWqsI1tC6a0w0";

// PLEASE DO NOT CHANGE THIS ....
export const PRODUCTION_GV_API_AUTH_CLIENT_ID =
  "kAMsAH8EhqYRjZjCZ1DVgMIPWo8yy8yMrXmwC1er";
export const PRODUCTION_GV_API_AUTH_CLIENT_SECRET =
  "iPvD1CSP7NcZPaOpGb43I0vBojVWuE1yKWd5YfPJJ2I8glZvo0DKvHKumeStWmw86sqdeRGnIh6xEV8k0lybcw4DywDbw4tc1d8O4srHJXL7k5nQyHxWNGym3xxMf98e";
export const AMAZON_AUTH_APP_ID =
  "amzn1.application-oa2-client.e55dea0bf73848d79acfb899d75dd25d";
export const AMAZON_SP_API_AUTH_APP_ID =
  "amzn1.sellerapps.app.792ce870-119f-4f00-bbd8-e7cc55bbff91";

export const AMAZON_ADV_API_CLIENT_ID =
  "amzn1.application-oa2-client.e55dea0bf73848d79acfb899d75dd25d";
export const PROD_STRIPE_PUBLIC_KEY =
  "pk_live_51FyIXVHpYSKrtmP4g8coeyMLa3EbwWERIvE3cnzhxBlAb4R3WVA9vIackJuhm8wLxGgrfITH12liOBlFFbtfrZAh00Y125L1Wz";
export const PROD_GOOGLE_API_CLIENT_ID =
  "51549406265-28nfmu0dclucskifte9mp0snl2rts345.apps.googleusercontent.com";
export const MIXPANEL_TOKEN = "d2205790d9f7986c82f71bcd6a54e379";
export const GA_TRACKING_ID = "G-449H68DF5N";
// TODO: Need to register for clientId below for Walmart Marketplace oAuth and add client ID and secrets below
export const WALMART_API_CLIENT_ID = "TBD";
// PLEASE DO NOT CHANGE THIS ENDS ....

// Change this to support local login
export const GV_API_AUTH_CLIENT_ID = "kAMsAH8EhqYRjZjCZ1DVgMIPWo8yy8yMrXmwC1er";
export const GV_API_AUTH_CLIENT_SECRET =
  "iPvD1CSP7NcZPaOpGb43I0vBojVWuE1yKWd5YfPJJ2I8glZvo0DKvHKumeStWmw86sqdeRGnIh6xEV8k0lybcw4DywDbw4tc1d8O4srHJXL7k5nQyHxWNGym3xxMf98e";
export const DEV_STRIPE_PUBLIC_KEY =
  "pk_test_867HXSjwbIJbHXgsWnGSrGSB00kYVpEfzQ";
