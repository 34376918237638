import { Grid } from "@material-ui/core";
import React from "react";
import Select from "react-select";

const NavFilter = ({
  cardNavOptions,
  selectedCardView,
  setSelectedCardView,
}) => {
  const options = cardNavOptions?.map(
    (o) =>
      ({
        label: o.label,
        value: o.value,
      } ?? [])
  );
  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        md={8}
        style={{ fontSize: "1.5rem", fontWeight: 500, color: "#403E3D" }}
      >
        <Select
          options={options}
          value={
            options.find((o) => o.value === selectedCardView.value) ||
            options[0]
          }
          onChange={(o) => setSelectedCardView(o)}
        />
      </Grid>
    </Grid>
  );
};

export default NavFilter;
