// Add element if it's not in array, otherwise if it is, remove it
const toggleArrayEntry = (value, arr) => {
  if (!arr.includes(value)) {
    arr.push(value);
  } else {
    arr.splice(arr.indexOf(value), 1);
  }

  return arr;
};

export default toggleArrayEntry;
