import { useFetch } from "hooks/api";
import React from "react";
import { Col } from "react-bootstrap";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { Player, ControlBar, ReplayControl, BigPlayButton } from "video-react";

const AssetSummary = ({
  assetId,
  // Description of the assets purpose/context within the dashboard, not of individual asset content (i.e. Lifestyle Image, Video Creative, etc.)
  assetDescriptor,
  assetType,
  // Below for override if unique endpoint to fetch from (neither assets or uploaded videos)
  assetURL = null,
  // Any child props for rendering beneath main asset display
  childReviewFields,
  hidden,
  summaryContainerClasses,
  columnSpan = 6,
}) => {
  const { data: selectedAsset, isLoading: isAssetLoading } = useFetch(
    ["assetSearch", assetId],
    "/assets/",
    {
      asset_id: assetId,
    },
    {
      select: (res) => res.data?.results?.[0],
      default: [],
      enabled: !!assetId,
    }
  );

  const { data: videoData, isLoading: isVideoLoading } = useFetch(
    ["trellisVideo", assetId],
    "/creatives/media/video",
    {
      id: assetId,
    },
    {
      default: [],
      // Only use in videos created on the Trellis platform
      enabled: !!assetId && !isNaN(parseInt(assetId)),
      select: (res) => res.data,
    }
  );

  if (hidden) {
    return null;
  }

  return (
    <>
      <Col
        xs={12}
        lg={columnSpan}
        className={`${summaryContainerClasses ? summaryContainerClasses : ""}`}
      >
        <h6
          className="title mb-4 fs-4"
          style={{
            color: "#73706E",
            fontWeight: "700",
            textTransform: "none",
          }}
        >
          {assetDescriptor}
        </h6>

        {isAssetLoading && isVideoLoading && !selectedAsset?.url_default ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "30px",
            }}
          >
            <img
              src={logo}
              alt=""
              className="rotate"
              width="80"
              height="auto"
            />
          </div>
        ) : (
          <>
            {assetType === "VIDEO" ? (
              <>
                <div
                  className={`mb-5 ${
                    columnSpan === 12 ? "w-50 pr-4" : "w-100"
                  }`}
                >
                  <FormGroupTile
                    type="formcontrol"
                    defaultValue={selectedAsset?.name}
                    disabled={true}
                  />
                </div>
                <Player
                  className="border"
                  src={assetURL ?? videoData?.url ?? selectedAsset?.url_default}
                >
                  <ControlBar autoHide={false}>
                    <ReplayControl seconds={10} />
                  </ControlBar>
                  <BigPlayButton position="center" />
                </Player>
              </>
            ) : (
              <>
                <div
                  className={`mb-5 ${
                    columnSpan === 12 ? "w-50 pr-4" : "w-100"
                  }`}
                >
                  <FormGroupTile
                    type="formcontrol"
                    defaultValue={selectedAsset?.name}
                    disabled={true}
                  />
                </div>
                <img
                  className="w-100"
                  src={assetURL ? assetURL : selectedAsset?.url_default}
                  alt={`${selectedAsset?.name}`}
                  style={{ maxHeight: "300px", objectFit: "contain" }}
                />
              </>
            )}
          </>
        )}
      </Col>
      {typeof childReviewFields === "object" &&
        childReviewFields?.map((field) => (
          <Col xs={12} lg={6}>
            {field}
          </Col>
        ))}
    </>
  );
};

export default AssetSummary;
