import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMediaQuery } from "@react-hook/media-query";

const TotalSalesVendorWarning = ({ orgType, channel }) => {
  const toolTipIconMinWidth = useMediaQuery("(min-width:1000px)");
  const toolTipIconMaxWidth = useMediaQuery("(max-width:1500px)");

  if (channel?.currentChannel !== "amazon") {
    return <></>;
  }

  return (
    <>
      {orgType === "vendor" ? (
        <OverlayTrigger
          overlay={
            toolTipIconMaxWidth && toolTipIconMinWidth ? (
              <Tooltip
                placement="top"
                id="top"
                className="bg-purple text-white"
              >
                <span className="text-center mx-auto fs-5">
                  Warning: Total sales stats may be delayed by 3-7 days for
                  vendors.
                </span>
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          {toolTipIconMaxWidth && toolTipIconMinWidth ? (
            <i className="fa fa-warning fa-sm text-muted me-1" />
          ) : (
            <div
              style={{
                position: "absolute",
                left: "15px",
                width: "35%",
                fontSize: "10px",
                textAlign: "initial",
              }}
            >
              <p
                style={{
                  fontSize: "10px",
                  textTransform: "none",
                }}
              >
                <i className="fa fa-warning fa-sm text-muted" />
                Total sales stats may be delayed by 3-7 days for vendors
              </p>
            </div>
          )}
        </OverlayTrigger>
      ) : (
        <></>
      )}
    </>
  );
};

export default TotalSalesVendorWarning;
