import { useState } from "react";

const useNav = (keys) => {
  const [currentKey, setCurrentKey] = useState(keys[0]);
  const onSelect = (selectedKey) => setCurrentKey(selectedKey);
  const props = {
    onSelect,
    activeKey: currentKey,
  };
  return [currentKey, props];
};

export default useNav;
