import {
  AD_CREATION_FAILED_STATUS,
  MODERATION_STATUSES,
  STATUS,
} from "components/core/blocks/AdPlan/constants";

export const displayAdStatus = (state, adStatus) => {
  let statusOptions = [STATUS.PAUSE, STATUS.ACTIVE, STATUS.ARCHIVE];

  let statusDescription =
    statusOptions.find((o) => o.value === state)?.label ?? "";

  if (statusDescription !== "Active") {
    statusDescription += "d";
  }

  // Display pending and failed moderation over user-defined states
  if (
    [
      MODERATION_STATUSES.FAILED.value,
      MODERATION_STATUSES.PENDING.value,
    ].includes(adStatus)
  ) {
    statusDescription = Object.values(MODERATION_STATUSES)?.find(
      (status) => status.value === adStatus
    )?.label;
  }

  if (adStatus === AD_CREATION_FAILED_STATUS) {
    return "Failed";
  }

  return statusDescription;
};
