import React, { useState, useEffect, useMemo } from "react";
import { Typography, withStyles, Grid } from "@material-ui/core";
import { useFetch } from "hooks/api";
import { NavDropdown } from "react-bootstrap";
import { getHashValue } from "utils/getQueryValue";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Analytics from "./Analytics";
import AnalyticsOverview from "./AnalyticsOverview";
import { AuthButton } from "components/core/basic/Button";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";

const useStyles = (theme) => ({
  container: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4),
    justifyContent: "center",
  },
  menuOptions: {
    justifyContent: "center",
    textAlign: "center",
  },
  navContainerButton: {
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      margin: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      margin: "1rem",
    },
  },
  buttonRow: {
    padding: theme.spacing(2, 4),
    justifyContent: "center",
  },
  main: {
    textAlign: "left",
  },
  header: {
    textAlign: "center",
  },
  chart: {
    maxHeight: "190px",
    marginBottom: "100px",
  },
  table: {},
  mainHeader: {
    fontSize: "26px",
    color: "#403E3D",
    fontWeight: "700",
    margin: "auto",
  },
  statTile: {
    maxHeight: "170px",
    margin: 0,
    whiteSpace: "nowrap",
  },
  tableHeader: {
    fontSize: "1.65rem",
    color: "#403E3D",
    fontWeight: "700",
    margin: "1rem auto",
  },
  statValues: {
    fontSize: "2.5rem",
    fontWeight: 400,
    color: "#403E3D",
    padding: "auto",
  },
  metrics: {
    justifyContent: "center",
    textAlign: "center",
    margin: "auto 20px",
  },
  dropdownText: {
    color: "#403E3D",
    fontWeight: 600,
  },
});

const marketplaceDict = {
  CA: "Canada",
  USA: "United States",
  MX: "Mexico",
  UK: "United Kingdom",
  NL: "Netherlands",
  ES: "Spain",
  IT: "Italy",
  FR: "France",
  IN: "India",
  AE: "UAE",
  SA: "Saudi Arabia",
  DE: "Germany",
  JP: "Japan",
  SG: "Singapore",
  AU: "Australia",
  SE: "Sweden",
  PL: "Poland",
  BE: "Belgium",
};

const AnalyticsView = ({ classes, marketPlace, user }) => {
  const defaultMarketplace = marketPlace.marketPlace;

  const autoShow = getHashValue("new_shelf", "false");
  const [selectedMenu, setSelectedMenu] = useState(
    autoShow.toLowerCase() === "true" ? "shelves" : "overview"
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedMarketplace, setSelectedMarketplace] =
    useState(defaultMarketplace);
  const [shelfRedirect, setShelfRedirect] = useState(null);
  const channel = "amazon";

  const { isLoading: isCategoryDataLoading, data: categoryData } = useFetch(
    ["categories", selectedMarketplace, channel],
    "/api/gvads/categories",
    {
      marketplace: selectedMarketplace,
      channel,
    }
  );
  const { isLoading: isMarketplaceDataLoading, data: marketplaceData } =
    useFetch(["marketplace", channel], "/api/organizations/marketplaces");

  const initiateShelfRedirect = (id) => {
    setShelfRedirect(parseInt(id));
    setSelectedMenu("shelves");
    // Then set marketplace
  };

  const formattedMarketplace = useMemo(() => {
    if (marketplaceData?.data?.markets) {
      return Object.entries(marketplaceData?.data?.markets).map(
        (key, index) => {
          return {
            value: marketplaceDict[key[0]],
            id: key[0],
          };
        }
      );
    }
    return [];
  }, [marketplaceData]);

  const buttonStyle = (menu) => {
    if (selectedMenu === menu) {
      return "buttonActive";
    } else {
      return "button";
    }
  };

  useEffect(() => {
    if (categoryData) {
      if (
        !categoryData?.data?.find((c) => parseInt(c.id) === selectedCategory)
      ) {
        setSelectedCategory(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarketplace]);

  return !user.analytics_tier ||
    ["unknown", "MANUALLY_DISABLED"].includes(user.analytics_tier) ? (
    <div style={{ marginTop: "30px" }}>
      <PaymentDiscovery discoveryType={"search-analytics"} />
    </div>
  ) : (
    <>
      <Grid container xs={12} className={classes.container}>
        <Grid item xs={12} className={classes.header}>
          <Typography
            align="center"
            variant="h3"
            className={classes.mainHeader}
          >
            Search Analytics
          </Typography>
        </Grid>

        <Grid container className={classes.buttonRow}>
          <AuthButton
            title="Overview"
            onClick={(e) => setSelectedMenu("overview")}
            className={`${classes.navContainerButton} ${buttonStyle(
              "overview"
            )}`}
          />

          <AuthButton
            title="shelves"
            onClick={(e) => setSelectedMenu("shelves")}
            className={`${classes.navContainerButton} ${buttonStyle(
              "shelves"
            )}`}
          />
        </Grid>

        {selectedMenu === "shelves" ? (
          <Grid item container xs={12} spacing={4} className={classes.main}>
            <Grid
              item
              container
              xs={12}
              className={classes.metrics}
              spacing={4}
              direction="row"
            >
              <Grid
                container
                item
                xs={12}
                md={4}
                lg={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <NavDropdown
                    id="Marketplace"
                    title={
                      <span className={classes.dropdownText}>
                        {(formattedMarketplace &&
                          formattedMarketplace.find(
                            (c) => c.id === selectedMarketplace
                          )?.value) ||
                          "Loading..."}
                      </span>
                    }
                    style={{
                      margin: "1rem 0",
                      color: "#73706E",
                      fontSize: "18px",
                    }}
                  >
                    {formattedMarketplace?.map((item) => {
                      return (
                        <NavDropdown.Item
                          key={item.id}
                          id={item.id}
                          className="my-1"
                          onClick={(e) => {
                            setSelectedMarketplace(item.id);
                          }}
                        >
                          {item.value}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={4}
                lg={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <NavDropdown
                    id="Category"
                    title={
                      <span className={classes.dropdownText}>
                        {(categoryData?.data &&
                          categoryData.data.find(
                            (c) => parseInt(c.id) === selectedCategory
                          )?.leaf_category_name) ||
                          "All Categories"}
                      </span>
                    }
                    style={{
                      margin: "1rem 0",
                      color: "#73706E",
                      fontSize: "18px",
                    }}
                  >
                    <NavDropdown.Item
                      key="all"
                      id="all"
                      className="my-2"
                      onSelect={(e) => {
                        setSelectedCategory(null);
                      }}
                    >
                      All Categories
                    </NavDropdown.Item>
                    {categoryData?.data?.map((item) => {
                      return (
                        <NavDropdown.Item
                          key={item.id}
                          id={item.id}
                          className="my-2"
                          onClick={(e) => {
                            setSelectedCategory(item.id);
                          }}
                        >
                          {item.leaf_category_name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} spacing={3}>
              <Analytics
                categoryId={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                fullLayout={true}
                shelfRedirect={shelfRedirect}
                clearRedirect={setShelfRedirect}
                marketplace={selectedMarketplace}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} spacing={3}>
            <AnalyticsOverview
              fullLayout={true}
              shelfRedirect={initiateShelfRedirect}
              marketplaceRedirect={setSelectedMarketplace}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

AnalyticsView.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(useStyles)(AnalyticsView));
