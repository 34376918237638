import { AD_TYPES, ALGORITHM_OPTIONS, GOALS } from "./constants";

const defaultTemplate = {
  name: "",
  errors: [],
  canHaveChildren: false,
  hasParent: true,
  children: [],
  targetACOS: 20,
  maxACOS: 40,
  goal: GOALS.DEMAND_GENERATION.value,
  dailyBudget: 20,
  filledTags: [],
  filledAsins: [],
  filledProductKeys: [],
  filledProductBrands: [],
  filteredAttributes: {
    color: [],
    size: [],
    collection: [],
    flavor: [],
    material: [],
    other: [],
  },
  filledTargetCategories: [],
  landingPageProduct: [],
  storePageProduct: [],
  creativeProduct: [],
  adType: undefined,
  type: "single",
  advStatus: 1,
  brand: "",
  store: "",
  page: "",
  logo: "",
  channel: "AMZ",
  headline: "",
  newLandingPage: false,
  discoverKeywords: false,
  googleAdditionalFields: {
    enableLocal: false,
  },
  inventoryFilterBrand: "",
  targetType: "automatic",
  minBid: null,
  maxBid: null,
  asinHarvestingEnabled: true,
  crSelectorEnabled: true,
  keywordHarvestingEnabled: true,
  keywordDiscoveryEnabled: false,
  topOfSearchBidMultiplier: 10,
  productPageMultiplier: 0,
  restOfSearchMultiplier: 0,

  bidStrategy: "legacyForSales",
  renderAdvancedOptions: undefined,
  notifications: {
    maxAcos: 30,
    maxSpend: 10,
  },
  salesVolumeControl: 5,
  algorithm: ALGORITHM_OPTIONS.ROAS_BOOST.value,
  trellisTrakEnabled: true,
  broadMatchEnabled: true,
  phraseMatchEnabled: true,
  exactMatchEnabled: true,
  negativePhraseMatchEnabled: true,
  negativeExactMatchEnabled: true,
  autoNegationEnabled: false,
  maxAcosNegationThreshold: null,
  cumulativeNegationThreshold: null,
  campaignNames: {
    manual_name: null,
  },
};

const getGenericPlanTemplate = (categoryId, products, parentName = null) => ({
  ...defaultTemplate,
  name: parentName ? `${parentName} - Generic` : "Generic",
  adType: AD_TYPES.SPONSORED_PRODUCT.value,
  targetType: "manual",
  allocation: 50,
  categoryId: categoryId,
  product: products ?? [],
  campaignNames: {
    manual_name_generic: null,
  },
});

const getCompetitivePlanTemplate = (
  categoryId,
  products,
  parentName = null
) => ({
  ...defaultTemplate,
  name: parentName ? `${parentName} - Competitor` : "Competitor",
  adType: AD_TYPES.SPONSORED_PRODUCT.value,
  targetType: "manual",
  allocation: 20,
  categoryId: categoryId,
  product: products ?? [],
  campaignNames: {
    manual_name_competitive: null,
  },
});

const getBrandedPlanTemplate = (categoryId, products, parentName = null) => ({
  ...defaultTemplate,
  name: parentName ? `${parentName} - Branded` : "Branded",
  adType: AD_TYPES.SPONSORED_PRODUCT.value,
  targetType: "manual",
  allocation: 20,
  categoryId: categoryId,
  product: products ?? [],
  campaignNames: {
    manual_name_branded: null,
  },
});

const getAutoPlanTemplate = (categoryId, products, parentName = null) => ({
  ...defaultTemplate,
  name: parentName ? `${parentName} - Harvesting` : "Harvesting",
  adType: AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
  targetType: "automatic",
  allocation: 10,
  categoryId: categoryId,
  product: products ?? [],
  campaignNames: {
    auto_name: null,
  },
});

export const getCombinedTemplates = (
  categoryId,
  products,
  parentName = null
) => [
  getAutoPlanTemplate(categoryId, products, parentName),
  getGenericPlanTemplate(categoryId, products, parentName),
  getCompetitivePlanTemplate(categoryId, products, parentName),
  getBrandedPlanTemplate(categoryId, products, parentName),
];
