import React from "react";
import { InlineSelect } from "components/custom/programs/fields";
import { Form, Col, Row } from "react-bootstrap";
import { useFieldArray, useWatch } from "react-hook-form";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import ConditionForm from "./ConditionForm";
import ActionForm from "./ActionForm";
import ScopeForm from "./ScopeForm";
import moment from "moment";
import { useProgramContext } from "./ProgramContext";
import { DEFAULT_CONDITIONS } from "./constants";
import { boldKeywords, summarizeProgram } from "./summarizeProgram";

const RuleForm = ({
  fieldName,
  control,
  formState,
  setValue,
  renderErrors,
}) => {
  const conditionOptions = [
    { value: "all", label: "all" },
    { value: "any", label: "any" },
  ];
  const { size, engine } = useProgramContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${fieldName}.conditions`,
  });

  const {
    fields: actions,
    append: appendAction,
    remove: removeAction,
  } = useFieldArray({
    control,
    name: `${fieldName}.actions`,
  });

  const scope = useWatch({
    control,
    name: `${fieldName}.scope`,
  });

  const conditionLogicalOperator = useWatch({
    control,
    name: `${fieldName}.condition`,
  });

  const conditionLogs = useWatch({
    control,
    name: `${fieldName}.conditions`,
  });

  const actionLogs = useWatch({
    control,
    name: `${fieldName}.actions`,
  });

  let summaryTextPosition = "bottom";

  let summaryText =
    engine === "bidding"
      ? summarizeProgram(
          scope,
          conditionLogs,
          conditionLogicalOperator,
          actionLogs
        )
      : "";

  const fmtDate = (date) => {
    return date ? moment(Date.parse(date)).format("MMM D 'YY") : null;
  };

  return (
    <>
      <Row className="mb-5 mx-3">
        <Col xs={size === "sm" ? 12 : 8}>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <div className="d-flex align-items-center fs-standard">
                  <strong>If</strong>
                  <InlineSelect
                    options={conditionOptions}
                    fieldName={`${fieldName}.condition`}
                    control={control}
                    rules={{ required: true }}
                  />
                  <strong>of the following:</strong>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 11, offset: 1 }}>
              {fields.map((item, index) => (
                <Row key={item.id}>
                  <Col
                    xs={12}
                    className="d-flex align-items-center mb-2 justify-content-between pr-3"
                  >
                    <ConditionForm
                      key={item.id}
                      fieldName={`${fieldName}.conditions.${index}`}
                      control={control}
                      formState={formState}
                      scope={scope}
                      divider={index}
                      setValue={setValue}
                    />
                    <span
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={() => remove(index)}
                    >
                      <GrSubtractCircle />
                    </span>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col xs={12} className="d-flex justify-content-end pr-3">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => append(DEFAULT_CONDITIONS[engine])}
                  >
                    <GrAddCircle />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-3 ">
            <Col xs={12} className="fs-standard">
              <strong>Then perform the following:</strong>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 11, offset: 1 }}>
              {actions.map((item, index) => (
                <Row key={item.id}>
                  <Col
                    xs={12}
                    className="d-flex align-items-center mb-2 justify-content-between pr-3"
                  >
                    <ActionForm
                      key={item.id}
                      fieldName={`${fieldName}.actions.${index}`}
                      control={control}
                      formState={formState}
                    />
                    <span
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={() => removeAction(index)}
                    >
                      <GrSubtractCircle />
                    </span>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col xs={12} className="d-flex justify-content-end pr-3">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      appendAction({
                        function: "INCREASE_PRICE",
                        operator: "adjust_fixed",
                        values: [10],
                      })
                    }
                  >
                    <GrAddCircle />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          xs={size === "sm" ? 12 : 4}
          className={size === "sm" ? "mt-3" : ""}
          style={{
            padding: "2rem",
            border: "1px solid #eee",
            borderRadius: "6px",
            backgroundColor: "#f8f8f8",
          }}
        >
          <Row>
            <Col xs={12} className="fs-standard mb-3">
              <strong>Evaluation Period</strong>
              <br />
              <small>
                Specify the timing for {engine === "bidding" ? "bid" : "price"}{" "}
                adjustments
              </small>
            </Col>
            <Col xs={12}>
              <ScopeForm
                fieldName={`${fieldName}.scope`}
                control={control}
                formState={formState}
              />
            </Col>
            <Col xs={12} className="fs-standard mt-3">
              {scope && scope.operator === "between" && (
                <span>
                  Check the conditions between{" "}
                  <b>{fmtDate(scope.values.start_date) || "start date"}</b> and{" "}
                  <b>{fmtDate(scope.values.end_date) || "end date"}</b>.
                </span>
              )}
              {scope && scope.operator === "before" && (
                <span>
                  Check the conditions within <b>{scope.values.days}</b> or less
                  days since the last action.
                </span>
              )}
              {scope && scope.operator === "after" && (
                <span>
                  Check the conditions <b>{scope.values.days}</b> or more days
                  after the last action.
                </span>
              )}{" "}
              {summaryTextPosition === "scope" ? (
                boldKeywords(summaryText)
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
        {engine === "bidding" ? (
          <Col xs={12} className="py-3 px-0">
            <p className="info-text w-100 fs-standard mt-2 p-3">
              {boldKeywords(summaryText)}
            </p>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};

export default RuleForm;
