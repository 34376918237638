import React from "react";
import { NotificationContext } from "./NotificationContext";
import { useFetch } from "hooks/api";
import { connect } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import axios from "axios";

const NotificationProvider = ({ children, auth: { isAuthenticated } }) => {
  const { data: notifications } = useFetch(
    ["notifications"],
    "/api/notifications/",
    {
      dismissed: false,
    },
    {
      enabled:
        isAuthenticated && !!axios.defaults.headers.common?.["Authorization"],
      select: (data) => data?.data,
    }
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(NotificationProvider);
