export const provisionArray = (arr, numberOfEntries) => {
  let provisionedArray = [];

  for (let i = 0; i < arr.length; i++) {
    if (provisionedArray?.length < 1) {
      provisionedArray.push([arr[i]]);
    } else if (
      provisionedArray[provisionedArray?.length - 1]?.length < numberOfEntries
    ) {
      provisionedArray[provisionedArray?.length - 1].push(arr[i]);
    } else {
      provisionedArray.push([arr[i]]);
    }
  }

  return provisionedArray;
};
