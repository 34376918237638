import store from "redux/store";
import api from "utils/api";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { setMerchandiseDashboard } from "redux/actions/userActions";

const useMerchandisePreferences = () => {
  const { settings, preferences, showNewAdvertisingDashboard, showSwitcher } =
    useSelector((state) => {
      const preferences = state.user.preferences;
      const settings = preferences?.merchandise_dashboard ?? {};
      return {
        settings,
        preferences,
        showNewAdvertisingDashboard: true,
        showSwitcher: false,
      };
    });

  const updateMerchandisePreferences = useMutation(async (newPreferences) => {
    const merchandise_dashboard = { ...settings, ...newPreferences };
    store.dispatch(setMerchandiseDashboard(merchandise_dashboard, preferences));
    await api.put("/api/org_permissions/", { merchandise_dashboard });
  });

  const updateMerchandiseCards = async ({ id, enabled }) => {
    return updateMerchandisePreferences.mutate({ [id]: enabled });
  };

  const toggleNewAdvertisingDashboard = async () => {
    return updateMerchandisePreferences.mutate({
      showNewAdvertisingDashboard: !showNewAdvertisingDashboard,
    });
  };

  const toggleSwitcher = async () => {
    return updateMerchandisePreferences.mutate({
      showSwitcher: !showSwitcher,
    });
  };

  return {
    preferences,
    updateMerchandiseCards,
    toggleNewAdvertisingDashboard,
    merchandisePreferences: settings,
    showNewAdvertisingDashboard,
    toggleSwitcher,
    showSwitcher,
  };
};

export default useMerchandisePreferences;
