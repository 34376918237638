import React, { useState } from "react";
import { LegendContext } from "./LegendContext";

export const LegendProvider = ({ children }) => {
  const [legends, setLegends] = useState(() => {
    // Check if options in localStorage, otherwise use default settings
    const legendSettings = JSON.parse(
      localStorage.getItem("legendOptions")
    )?.legends;

    return legendSettings
      ? legendSettings
      : {
          advertisements: [
            { name: "Impressions", visible: true },
            { name: "Clicks", visible: false },
            { name: "Ad Sales", visible: true },
            { name: "Ad Spend", visible: false },
            { name: "ACOS", visible: false },
            { name: "annotationAxis", visible: true },
            { name: "Page Views", visible: false },
            { name: "Add To Cart", visible: false },
            { name: "Begin Checkout", visible: false },
          ],
          summary: [
            { name: "Impressions", visible: true },
            { name: "Clicks", visible: false },
            { name: "Ad Sales", visible: true },
            { name: "Total Sales", visible: false },
            { name: "Ad Spend", visible: false },
            { name: "ACOS", visible: false },
            { name: "Total ACOS", visible: false },
            { name: "annotationAxis", visible: true },
            { name: "Page Views", visible: false },
            { name: "Add To Cart", visible: false },
            { name: "Begin Checkout", visible: false },
          ],
          category: [
            { name: "Impressions", visible: true },
            { name: "Clicks", visible: false },
            { name: "Ad Sales", visible: true },
            { name: "Total Sales", visible: false },
            { name: "Ad Spend", visible: false },
            { name: "ACOS", visible: false },
            { name: "Total ACOS", visible: false },
            { name: "annotationAxis", visible: true },
            { name: "Page Views", visible: false },
            { name: "Add To Cart", visible: false },
            { name: "Begin Checkout", visible: false },
          ],
          listing: [
            { name: "Impressions", visible: true },
            { name: "Clicks", visible: false },
            { name: "Ad Sales", visible: true },
            { name: "Total Sales", visible: false },
            { name: "Ad Spend", visible: false },
            { name: "ACOS", visible: false },
            { name: "Total ACOS", visible: false },
            { name: "BSR", visible: false },
            { name: "annotationAxis", visible: true },
            { name: "Page Views", visible: false },
            { name: "Add To Cart", visible: false },
            { name: "Begin Checkout", visible: false },
          ],
          merchandiseListing: [
            { name: "Ad Sales", visible: false },
            { name: "Total Sales", visible: true },
            { name: "Ad Spend", visible: false },
            { name: "BSR", visible: true },
            { name: "annotationAxis", visible: true },
            { name: "Product Price", visible: true },
            { name: "Units Ordered", visible: true },
          ],
        };
  });

  const handleSetLegends = (legends) => {
    setLegends(legends);

    localStorage.setItem(
      "legendOptions",
      JSON.stringify({
        legends,
      })
    );
  };

  return (
    <LegendContext.Provider value={{ legends, setLegends, handleSetLegends }}>
      {children}
    </LegendContext.Provider>
  );
};
