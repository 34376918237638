import { Box, Grid, withStyles } from "@material-ui/core";
import bottomArrows from "assets/images/background/bottom_arrows.png";
import topArrows from "assets/images/background/top_arrows.png";
import trellislogo from "assets/images/logo/Trellis_Logo_white.png";
import React, { Component } from "react";

const useStyles = (theme) => ({
  topimagegrid: {
    minWidth: 0,
    minHeight: 0,
    overflow: "hidden",
    maxHeight: "0%",
    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
    },
  },
  logoimagegrid: {
    maxHeight: "80%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0%",
    [theme.breakpoints.up("md")]: {
      marginBottom: "-10",
    },
  },
  bottomimagegrid: {
    minWidth: 0,
    minHeight: 0,
    overflow: "hidden",
    maxHeight: "0%",
    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
    },
  },
  logo: {
    maxWidth: "80%",
    maxHeight: "80%",
  },
  toparrows: {
    minWidth: 0,
    minHeight: 0,
    overflow: "hidden",
    maxHeight: "100%",
    marginLeft: "9%",
    marginTop: "3%",
  },
  bottomarrows: {
    minWidth: 0,
    minHeight: 0,
    maxHeight: "100%",
    maxWidth: "100%",
  },
});

class TrellisPanel extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        wrap="nowrap"
        style={{ height: "100%" }}
        className={classes.rightPanel}
      >
        <Grid item className={classes.topimagegrid}>
          <img className={classes.toparrows} src={topArrows} alt="top arrows" />
        </Grid>
        <Grid
          container
          className={classes.logoimagegrid}
          justifyContent="center"
        >
          <img className={classes.logo} src={trellislogo} alt="Trellis" />
        </Grid>
        <Grid container className={classes.bottomimagegrid}>
          <Grid item xs={6} />
          <Grid item xs={6} className={classes.bottomimagegrid}>
            <Box className={classes.bottomimagegrid} overflow="hidden">
              <img
                className={classes.bottomarrows}
                src={bottomArrows}
                alt="bottom arrows"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(TrellisPanel);
