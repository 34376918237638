import Loading from "components/core/blocks/Loading";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import React, { useState, useMemo } from "react";
import { Row, Col, Nav, Badge } from "react-bootstrap";
import { Card } from "react-bootstrap/esm";
import ProductImage from "components/custom/category/listings/ProductImage";
import {
  INVENTORY_STATES,
  SALE_STATES,
} from "components/core/blocks/AdPlan/constants";
import PriceHistorySection from "./cards/PriceHistory";
import ImpactSection from "./cards/Impact";
import { DemandChartsSection } from "./cards/DemandTrend";
import Overview from "./cards/Overview";
import CompetitorsSection from "./cards/Competitors";
import ConfirmationSection from "./cards/Confirmation";
import PriceProfilingSection from "./cards/Profiling";
import moment from "moment";
import { Link } from "react-router-dom";

const ProductCard = ({
  productId,
  marketPlace,
  user,
  additionalTabs,
  initialTab,
}) => {
  const { start, end, preStart, preEnd, group } = useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "product_id",
  };
  const { isLoading, data: product } = useFetch(
    ["products", productId, group, start, end, preStart, preEnd],
    `/api/gvads/productid/${productId}`,
    {},
    { select: (res) => res.data, enabled: true, keepPreviousData: true }
  );

  const { data, isLoading: isOverviewDataLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      productId,
    ],
    `/api/data_report/product_report?product_id=${productId}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: true,
    }
  );
  const salesStateArray = [
    SALE_STATES.BEST_SELLER,
    SALE_STATES.CLEARANCE,
    SALE_STATES.NEW_PRODUCT,
  ];
  const inventoryStateArray = [
    INVENTORY_STATES.OVERSTOCK,
    INVENTORY_STATES.LOW_STOCK,
    INVENTORY_STATES.OUT_OF_STOCK,
  ];
  const salesLabel = salesStateArray.find(
    (s) => s.value === product?.sales_state
  )?.label;
  const inventoryBadgeText = inventoryStateArray.find(
    (s) => s.value === product?.stock_status
  )?.label;
  const [tab, setTab] = useState(initialTab ?? "overview");

  const { data: pricingProduct, isLoading: isPricingProductDataLoading } =
    useFetch(
      ["product_pricing", productId],
      `/pricing/products/${productId}/`,
      { get_primary: "false" },
      {
        select: (d) => d?.data,
        keepPreviousData: true,
      }
    );

  const pricingInfo = useMemo(() => {
    const info = {
      confidence: null,
      enroll_date: null,
      curve_confidence: null,
      enroll_eligibility: null,
      configured_slider_value: null,
      price_profiling: null,
      price_override_end: null,
      price_override: null,
    };

    if (pricingProduct) {
      info.confidence = pricingProduct.confidence;
      info.enroll_date = pricingProduct.enroll_date;
      info.curve_confidence = pricingProduct.confidence;
      info.enroll_eligibility = pricingProduct.enroll_eligibility;
      info.configured_slider_value = pricingProduct.configured_slider_value;
      info.price_profiling = pricingProduct.price_profiling;
      info.price_override_end = pricingProduct.price_override_end;
      info.price_override = pricingProduct.price_override;
      info.plan_id = pricingProduct.plan_id;
    }

    return info;
  }, [pricingProduct]);

  return (
    <>
      {isLoading ||
      isPricingProductDataLoading ||
      isOverviewDataLoading ||
      !product ? (
        <Loading height={"300px"} fullPage={false} />
      ) : (
        <Card className="fs-standard mb-0">
          <Card.Body>
            <Row>
              <Col xs={12} xl={8}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "120px", paddingRight: "20px" }}>
                    <ProductImage
                      height={100}
                      width={100}
                      id={productId}
                      style={{ borderRadius: 15 }}
                    />
                  </div>
                  <div>
                    <Row>
                      <Col xs={12}>
                        <h3
                          style={{
                            marginBottom: "0.2rem",
                            marginTop: "0",
                            fontSize: 22,
                          }}
                        >
                          {product?.product_title}
                        </h3>
                      </Col>
                      <Col xs={12}>
                        {product.asin} - {product.sku}
                        &nbsp;-&nbsp;
                        <Badge
                          className={`badge bg-trellis-${
                            pricingInfo.enroll_date ? "green" : "lightgrey"
                          } text-end`}
                          style={{
                            borderRadius: "5px",
                          }}
                        >
                          {pricingInfo.enroll_date && <span>Enrolled</span>}
                          {!pricingInfo.enroll_date &&
                            !pricingInfo.price_override_end && (
                              <span>Not Enrolled</span>
                            )}
                        </Badge>
                        {pricingInfo.price_override_end && (
                          <Badge
                            className={`badge bg-trellis-purple text-end ms-3`}
                            style={{
                              borderRadius: "5px",
                            }}
                          >
                            Fixed Price
                          </Badge>
                        )}
                      </Col>
                      <Col xs={12}>
                        {inventoryBadgeText && (
                          <Badge
                            className="badge bg-trellis-purple"
                            style={{
                              marginRight: "0.5rem",
                              backgroundColor: "#c43066",
                            }}
                            pill
                          >
                            {inventoryBadgeText}
                          </Badge>
                        )}
                        {salesLabel && (
                          <Badge
                            bsClass="badge"
                            className="bg-trellis-fuchsia"
                            pill
                          >
                            {salesLabel}
                          </Badge>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col xs={12} xl={4} className="text-center">
                {(pricingInfo.confidence || pricingInfo.enroll_date) && (
                  <Row>
                    <Col>
                      <h6
                        style={{ fontWeight: 600 }}
                        className="fs-standard text-uppercase pt-1 pr-3"
                      >
                        Enroll Date
                      </h6>
                      {pricingInfo.enroll_date &&
                      pricingInfo.enroll_date !== 0 ? (
                        <span>
                          {moment(pricingInfo.enroll_date).format("MM/DD/YYYY")}
                        </span>
                      ) : (
                        <span>n/a</span>
                      )}
                    </Col>
                    <Col>
                      <>
                        <span>&nbsp;</span>
                      </>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mt-5 ph-4 mb-3 border-bottom">
                <Nav
                  variant="underline"
                  onSelect={(k) => {
                    return setTab(k);
                  }}
                  activeKey={tab}
                >
                  {additionalTabs?.map((additionalTab) => (
                    <Nav.Item>
                      <Nav.Link eventKey={additionalTab.title?.toLowerCase()}>
                        {additionalTab.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="demand_charts">Demand Charts</Nav.Link>
                  </Nav.Item> */}

                  <Nav.Item>
                    <Nav.Link eventKey="price_history">Price History</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ad_affect">
                      Advertising Effectiveness
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="competitors">Competitors</Nav.Link>
                  </Nav.Item>

                  {pricingInfo.enroll_date && (
                    <>
                      <Nav.Item>
                        <Nav.Link eventKey="impact">Impact</Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="confirmation">
                          Price Changes
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  )}

                  {pricingInfo.price_profiling && pricingInfo.enroll_date && (
                    <Nav.Item>
                      <Nav.Link eventKey="price_profiling">
                        Profiling Schedule
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {additionalTabs?.map((additionalTab) => (
                  <>
                    {tab === additionalTab.title?.toLowerCase() &&
                      additionalTab.content}
                  </>
                ))}
                {tab === "overview" && (
                  <Overview
                    pricingProduct={pricingProduct}
                    isPricingProductDataLoading={isPricingProductDataLoading}
                    marketPlace={marketPlace}
                    data={data}
                    isLoading={isOverviewDataLoading}
                  />
                )}
                {tab === "price_history" && (
                  <>
                    <Card>
                      <DemandChartsSection
                        pricingProduct={pricingProduct}
                        marketPlace={marketPlace}
                        product={product}
                        isPricingProductDataLoading={
                          isPricingProductDataLoading
                        }
                        data={data}
                      />
                    </Card>
                    <hr />
                    <PriceHistorySection
                      pricingProduct={pricingProduct}
                      marketPlace={marketPlace}
                      isPricingProductDataLoading={isPricingProductDataLoading}
                      isLoading={false}
                    />
                  </>
                )}
                {tab === "ad_affect" && (
                  <PriceHistorySection
                    pricingProduct={pricingProduct}
                    marketPlace={marketPlace}
                    isPricingProductDataLoading={isPricingProductDataLoading}
                    isLoading={isOverviewDataLoading}
                    advertising={true}
                  />
                )}
                {tab === "impact" && (
                  <ImpactSection
                    pricingProduct={pricingProduct}
                    marketPlace={marketPlace}
                  />
                )}
                {tab === "confirmation" && (
                  <ConfirmationSection
                    pricingProduct={pricingProduct}
                    marketPlace={marketPlace}
                  />
                )}
                {tab === "competitors" && (
                  <CompetitorsSection
                    pricingProduct={pricingProduct}
                    productId={productId}
                    marketPlace={marketPlace}
                    product={product}
                    isPricingProductDataLoading={isPricingProductDataLoading}
                  />
                )}
                {tab === "price_profiling" && (
                  <PriceProfilingSection
                    pricingProduct={pricingProduct}
                    marketPlace={marketPlace}
                  />
                )}
              </Col>
            </Row>
            {pricingInfo?.plan_id && (
              <Row>
                <Col xs={12} className="text-center py-2">
                  <Link to={`/user/_pricing/plan/${pricingInfo.plan_id}`}>
                    View Pricing Plan
                  </Link>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ProductCard;
