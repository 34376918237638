import { metrics as pricingMetrics } from "modules/pricingv2/metrics";
import { metrics as merchandisingMetrics } from "../metrics";
import {
  COMPARE_METRIC_LOOKUP,
  CONTENT_METRICS,
  METRIC_COLUMNS_BY_ENTITY,
  METRIC_GROUPING_LOOKUP,
  PRICING_METRICS,
  PROMO_METRICS,
} from "./metricsByEntity";
import checkApplication from "utils/checkApplication";
import {
  CONTENT,
  DEALS,
  DYNAMIC_PRICING,
} from "components/custom/merchandise/constants";
import buildMetricColumn from "../columns/buildMetricColumn";

export const extendAllEntityMetricOptions = (
  tableEntityType,
  data,
  customFilters,
  specifiedColumns,
  marketPlace,
  user
) => {
  const allMetrics = [...merchandisingMetrics, ...pricingMetrics];

  let metricsUniqueByKey = [
    ...new Map(
      allMetrics
        ?.filter((metric) => {
          return ![
            ...specifiedColumns?.map((spec) => spec.id)?.filter((spec) => spec),
          ]?.includes(metric.id);
        })
        ?.map((metric) => [metric["id"], metric])
    ).values(),
  ];

  const entityMetrics = METRIC_COLUMNS_BY_ENTITY[tableEntityType];

  metricsUniqueByKey = metricsUniqueByKey.filter((m) =>
    entityMetrics.includes(m.id)
  );

  if (!checkApplication(user, DYNAMIC_PRICING)) {
    metricsUniqueByKey = metricsUniqueByKey.filter(
      (m) => !PRICING_METRICS?.includes(m.id)
    );
  }

  if (!checkApplication(user, CONTENT)) {
    metricsUniqueByKey = metricsUniqueByKey.filter(
      (m) => !CONTENT_METRICS?.includes(m.id)
    );
  }

  if (!checkApplication(user, DEALS)) {
    metricsUniqueByKey = metricsUniqueByKey.filter(
      (m) => !PROMO_METRICS?.includes(m.id)
    );
  }
  for (const metric of metricsUniqueByKey) {
    const extraMetricSpec = buildMetricColumn(
      marketPlace,
      data,
      metric.id,
      COMPARE_METRIC_LOOKUP[metric.id] ?? null,
      { checked: false },
      customFilters
    );

    extraMetricSpec.grouping = METRIC_GROUPING_LOOKUP[metric.id];
    // Below is to filter out duplicate metric names and descriptions that come up from legacy tables
    // For example, when cost and ad spend are used as keys, they have the same label, accessor, and tooltip, but different ids passed in
    if (
      !specifiedColumns.some((col) => col.Header === extraMetricSpec.Header)
    ) {
      specifiedColumns.push(extraMetricSpec);
    }
  }

  return specifiedColumns;
};

// Take legacy key/id and get global metric option
// Once we convert legacy ad and merch dashboard tables this can be removed
export const LEGACY_METRIC_IDS_MAP = {
  total_sales: "sales",
  total_cost: "cost",
  total_units: "units",
  total_roas: "troas",
  conv_rate: "conversion_rate",
  conversion: "orders",
  click_thru_rate: "ctr",
  cost_per_click: "cpc",
};
