import React, { useState, useMemo, useRef } from "react";
import ToolTips from "utils/hiddenToolTips";
import { FaRegCircle } from "react-icons/fa";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import FilterWidget from "views/merchandise/tables/FilterWidget";
import TrComponent from "components/custom/merchandise/TrComponent";
import useColumnSelector from "hooks/useColumnSelector";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { getURLPrefix } from "utils/getUrlPrefix";
import NoImage from "assets/images/utility/no_image.png";
import { trellisPalette } from "components/custom/analytics/palettes";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import TrellisLink from "components/core/basic/TrellisLink";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  LEGACY_METRIC_IDS_MAP,
  extendAllEntityMetricOptions,
} from "views/merchandise/tables/metricUtils";
import getMetricSpec from "../metrics";
import { METRIC_GROUPING_LOOKUP } from "views/merchandise/tables/metricsByEntity";

let URL_PREFIX = getURLPrefix();

const CategoryTable = ({
  stats,
  marketPlace,
  mobileStyle,
  date,
  columnSpecs,
  isv2 = false,
}) => {
  const location = useLocation();
  const { user } = useSelector((state) => state);

  const [searchFilter, setSearchFilter] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [colToggle] = useState();

  const TABLE_ID = "categoryPricingTable";
  const tableRef = useRef();
  const {
    customFilters,
    setCustomFilters,
    formatFilterValue,
    applyCustomFilters,
  } = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const CategoryCell = (props) => {
    const [hasError, setHasError] = useState(false);
    const categoryId = props?.original?.category_id;
    const image = hasError
      ? NoImage
      : `${URL_PREFIX}/api/productimage/?category=${categoryId}`;

    return (
      <DashboardLink category={categoryId} date={props.date}>
        <div style={{ display: "flex" }}>
          <div
            onError={(e) => {
              setHasError(true);
            }}
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              flexShrink: 0,
              width: 50,
              height: 50,
              marginRight: "10px",
              background: `url(${image}) no-repeat center / contain`,
            }}
            draggable="false"
          />
          <div>{props?.value}</div>
        </div>
      </DashboardLink>
    );
  };

  const TrendCell = (props) => {
    const COLORS = [
      trellisPalette[17], // -2 red
      trellisPalette[16], // -1 yellow
      "white", // 0 white
      trellisPalette[12] + "80", // 1 light green
      trellisPalette[12], // 2 green
    ];
    let color = "white";
    if (props.value) {
      color = COLORS?.[props.value + 2] ?? "white";
    }
    return (
      <div style={{ position: "relative", textAlign: "center" }}>
        {props?.original?.trends?.length > 0 && (
          <ToolTips
            position="top"
            id={`trends-${props?.original?.category_id ?? ""}${
              props?.original?.product_id ?? ""
            }`}
            toolTip={props?.original?.trends?.join(", ")}
          >
            <>
              {props?.original?.trends.map((t) => (
                <>
                  {t}
                  <br />
                </>
              ))}
            </>
          </ToolTips>
        )}
        {color === "white" ? (
          <FaRegCircle style={{ color: "#aaa" }} />
        ) : (
          <i className="fa fa-circle" style={{ color }}></i>
        )}
      </div>
    );
  };

  const getDashboardTo = (category = null) => {
    const searchParams = new URLSearchParams(location.search);
    const bucket = searchParams.get("bucket");
    let url = `/user/_pricing/dashboard`;

    if (category) {
      url = `/user/_pricing/category/${category}`;
    }
    if (bucket) {
      url += `?bucket=${bucket}`;
    }

    return url;
  };

  const DashboardLink = ({ children, category }) => {
    const to = getDashboardTo(category);

    return (
      <TrellisLink
        to={to}
        style={{
          textDecoration: "none",
          fontWeight: "500",
          fontSize: "1.5rem",
          cursor: "pointer",
        }}
      >
        {children}
      </TrellisLink>
    );
  };

  const makeColumn =
    (data, marketPlace, mobileStyle, date) => (header, key, type, options) => {
      const { hideFilter } = options ?? {};

      const defaultAccessor = (p) => p[key];
      const accessor = options?.accessor ?? defaultAccessor;
      const defaultFormat = ({ value }) => value;

      const fmt =
        options?.formatter ??
        {
          currency: ({ value, overrideValue }) =>
            formatCurrency(
              overrideValue ? overrideValue : value,
              marketPlace.marketPlace,
              options?.clipDecimalPlaces ?? true
            ),
          number: ({ value }) => formatNumber(value, {}, 1),
          percent: ({ value }) => formatPercent(value, 1),
          category: CategoryCell,
          trends: TrendCell,
          percentDiff: ({ value }) => (
            <span
              style={{
                color: value > 0 ? trellisPalette[12] : trellisPalette[17],
              }}
            >
              {formatPercent(value, 1, true)}
            </span>
          ),
        }?.[type] ??
        defaultFormat;

      const parser =
        {
          currency: parseFormattedCurrency,
          number: parseFormattedNumber,
          percent: parseFormattedPercentage,
        }?.[type] ?? defaultFormat;

      let width;
      if (mobileStyle) {
        width = options?.mobileWidth;
      } else {
        width = options?.width;
      }

      // Handle tooltips to support metric selection menu descriptions
      let { toolTip } = options;

      if (!toolTip) {
        let spec = getMetricSpec(marketPlace, key);
        toolTip = spec.toolTip;
      }

      if (!toolTip) {
        let alternateMetricSpec = getMetricSpec(
          marketPlace,
          LEGACY_METRIC_IDS_MAP[key]
        );
        toolTip = alternateMetricSpec?.toolTip;
      }

      // Handle percent filter formatting
      if (type === "percent") {
        data = data.map((row) => {
          Object.keys(row).forEach((propertyKey) => {
            if (propertyKey === key) {
              row["formatted_" + propertyKey] = row[propertyKey] * 100;
            }
          });

          return row;
        });
      }

      let c = (props) => {
        if (options?.showDot ?? true) {
          const percentKey = `${key}_percent`;
          const percent = props.original?.[percentKey] ?? null;
          const deltaKey = `${key}_delta`;
          const deltaValue = props.original?.[deltaKey];
          const isNegative = options?.isNegative ?? false;
          const noColor = options?.noColor ?? false;
          const params = options?.params ?? {};

          let color = "#666";
          if (!noColor && percent > 0) {
            color = isNegative ? trellisPalette[17] : trellisPalette[12];
          }
          if (!noColor && percent < 0) {
            color = isNegative ? trellisPalette[12] : trellisPalette[17];
          }

          return (
            <div>
              {fmt({ ...props, date, key, params })}
              <br />
              {options?.showBlank ? (
                <span>-</span>
              ) : (
                <span
                  style={{
                    color,
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  {!options?.hideCompare &&
                    (options?.delta
                      ? (deltaValue > 0 ? "+" : "") + fmt({ value: deltaValue })
                      : percent === null
                      ? ""
                      : formatPercent(percent, 1, true))}
                </span>
              )}
            </div>
          );
        }
        return fmt({ ...props, date });
      };

      return {
        Header: header,
        id: key,
        accessor,
        checked: options?.checked ?? true,
        maxWidth: options?.maxWidth,
        minWidth: options?.minWidth,
        isStatic: options?.isStatic,
        width,
        height: 30,
        grouping: METRIC_GROUPING_LOOKUP[key],
        format: type,
        toolTip,
        className: options?.className ?? "text-end",
        headerClassName: options?.className ?? "text-end",
        Cell: c,
        style: { height: "60px" },
        filterMethod: (filter, row) => {
          const formattedPercentAccessor = (p) => p[`formatted_${key}`];
          const value =
            type === "percent"
              ? formattedPercentAccessor(row._original)
              : accessor(row._original);
          return filterRanges(filter, value, parser, marketPlace.marketPlace);
        },
        Filter: ({ filter, onChange }) => {
          if (hideFilter) {
            return null;
          }

          return generateFilterMarkup(filter, onChange, key, "range", type, {
            ranges: generateRanges(
              data ?? [],
              5,
              type === "percent" ? `formatted_${key}` : key,
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          });
        },
      };
    };

  // TODO: filter data at higher level, pass as prop to table
  const data = useMemo(
    () => {
      return applyCustomFilters(
        stats?.filter((p) => {
          if (searchFilter) {
            const searchable = `${p.category_name} ${p.name}`;
            return (
              searchable.toLowerCase()?.indexOf(searchFilter.toLowerCase()) > -1
            );
          }
          return true;
        })
      );
    },
    // Below linting disabled to take clearFilters in to recalcalculate data on filter reset
    // Without it, filter specs from react-table must be stored at this level and manually cleared on each reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stats, searchFilter, clearFilters, customFilters]
  );

  const createColumn = makeColumn(data, marketPlace, mobileStyle, date);

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      if (!columnSpecs) {
        return [];
      }
      let originalColumns = columnSpecs?.map((c) => {
        return createColumn(c.header, c.key, c.type, c.options);
      });
      return extendAllEntityMetricOptions(
        "category",
        stats,
        customFilters,
        originalColumns,
        marketPlace.marketPlace,
        user
      );
    },
    [columnSpecs, stats, mobileStyle, colToggle],
    TABLE_ID
  );
  const columnOrder = columns.map((col) => col.id);

  return (
    <>
      <ReactTable
        ref={tableRef}
        showPaginationBottom={false}
        showPaginationTop
        PaginationComponent={ReactTablePagination}
        columns={columns}
        searchWidth={"250px"}
        data={data}
        defaultPageSize={10}
        minRows={5}
        className={`-highlight ${TABLE_ID}`}
        defaultSorted={[{ id: "sales", desc: false }]}
        filterable={showFilters}
        state={columnOrder}
        TrComponent={TrComponent}
        showSelectRows={true}
        getTheadFilterProps={(props) => {
          if (clearFilters) {
            props.filtered.forEach((filter, index) => {
              filter.value = "all";
            });
            setClearFilters(false);
          }
          return { style: { display: "flex" } };
        }}
        getPaginationProps={() => ({
          updateSearch: setSearchFilter,
          clearFilters: () => {
            setSearchFilter("");
            setClearFilters(true);
          },
          hideFilters: false,
          showFilters: showFilters,
          setShowFilters: setShowFilters,
          clearSearchTermFilters: () => setSearchFilter(""),
          searchTerm: searchFilter,
          table: "plan-categories",
          style: { marginBottom: "2rem" },
          carouselLayout: false,
          disableSearchFocus: true,
          filterControlsWidth: "70%",
          columnSelectorProps: columnSelectorProps,
          filterWidget: true,
          hideFilterToggleIcon: true,
          tableEntityType: "category",
          children: (
            <div className="d-flex justify-content-start w-100 h-100 align-items-center px-2">
              <FilterWidget
                customFilters={customFilters}
                setCustomFilters={setCustomFilters}
                formatFilterValue={formatFilterValue}
                tableRef={tableRef}
                excludedFilterColumns={["category_name", "trends_score"]}
                tableId={TABLE_ID}
              />
            </div>
          ),
        })}
      />
    </>
  );
};

export default CategoryTable;
