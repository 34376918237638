import { WALMART_ONBOARD } from "../types";

const initialState = {
  partner_access_state: "",
  advertiser_id_provided: false,
  marketplace_credentials_provided: false,
  seller_type: "",
  seller_id: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WALMART_ONBOARD:
      return {
        ...state,
        partner_access_state: action.payload.partner_access_state,
        advertiser_id_provided: action.payload.advertiser_id_provided,
        marketplace_credentials_provided:
          action.payload.marketplace_credentials_provided,
        seller_type: action.payload.seller_type,
        seller_id: action.payload.marketplace_seller_id,
      };
    default:
      return state;
  }
}
