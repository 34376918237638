import { AuthButton } from "components/core/basic/Button";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import Scorecard from "views/Scorecard";
import Insights from "views/Insights";
import axios from "axios";
import getURLPrefix from "utils/getUrlPrefix";
let URL_PREFIX = getURLPrefix();

class TrellisTrak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketPlace: this.props.marketPlace.marketPlace,
      categories: null,
      currentCampaign: null,
      currentAdgroup: null,
      redirectClick: null,
      selectedButton: "scorecard",
    };
    this.setSelectedAdgroup = this.setSelectedAdgroup.bind(this);
  }

  buttonStyle(activePage) {
    if (this.state.selectedButton === activePage) {
      return "buttonActive";
    } else {
      return "button";
    }
  }

  componentDidMount() {
    this.fetchCategories();
  }

  componentDidUpdate() {
    if (this.state.marketPlace !== this.props.marketPlace.marketPlace) {
      this.fetchCategories();
      this.setState({
        marketPlace: this.props.marketPlace.marketPlace,
        currentAdgroup: null,
        currentCampaign: null,
      });
    }
  }

  selectedComponent(page) {
    this.setState({ selectedButton: page });
  }

  setSelectedAdgroup(campaign_name, adgroup_name) {
    this.setState({
      currentCampaign: campaign_name,
      currentAdgroup: adgroup_name,
      selectedButton: "insights",
    });
  }

  fetchCategories() {
    axios
      .get(
        URL_PREFIX +
          "/api/gvads/categories/?marketplace=" +
          this.props.marketPlace.marketPlace
      )
      .then((res) => {
        this.setState({ categories: res.data });
      });
  }

  render() {
    return (
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Row style={{ paddingTop: "-1rem", paddingBottom: "3rem" }}>
          <div
            className="wrap"
            style={{
              paddingTop: "0.5rem",
              letterSpacing: "1px",
              textTransform: "uppercase",
              fontSize: "24px",
              color: "#403E3D",
              fontWeight: "700",
            }}
          >
            Your 60 Day Sponsored Ads Audit
          </div>
        </Row>
        <Row style={{ marginTop: "0rem" }}>
          <Col md={12}>
            <div className="wrap">
              <AuthButton
                title="Campaign Analysis"
                buttonStyle={this.buttonStyle("scorecard")}
                onClick={(e) => this.selectedComponent("scorecard")}
                style={{
                  fontSize: "12px",
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
              />
              <AuthButton
                title="AdGroup Keyword Analysis"
                buttonStyle={this.buttonStyle("insights")}
                onClick={(e) => this.selectedComponent("insights")}
                style={{ fontSize: "12px" }}
              />
            </div>
          </Col>
        </Row>

        {this.state.selectedButton === "scorecard" ? (
          <Scorecard setSelectedAdgroup={this.setSelectedAdgroup} />
        ) : this.state.selectedButton === "insights" ? (
          <Insights
            currentCampaign={this.state.currentCampaign}
            currentAdgroup={this.state.currentAdgroup}
          />
        ) : null}
      </div>
    );
  }
}

TrellisTrak.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  amz_onboard: state.amz_onboard,
  marketPlace: state.marketPlace,
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TrellisTrak));
