import React, { useEffect, useState } from "react";
import { trellisPalette } from "components/custom/analytics/palettes";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const BSRChart = ({ bsrBucket, secondaryTab }) => {
  const [bsrChartOptions, setBSRChartOptions] = useState(null);

  useEffect(() => {
    if (secondaryTab === "bsr_rank" && !bsrChartOptions) {
      createBSRChart(bsrBucket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsrBucket, secondaryTab]);

  const createBSRChart = (BSRRatings) => {
    const categories = [];
    const ratingList = [];
    for (let i = 0; i < BSRRatings.length; i++) {
      categories.push(
        `${BSRRatings[i].min.toFixed(0)} - ${BSRRatings[i].max.toFixed(0)}`
      );
      ratingList.push(BSRRatings[i].count);
    }
    const options = {
      chart: {
        type: "column",
        height: 360,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: categories,
        crosshair: true,
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number of Listings",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0">&nbsp;<b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Number of BSR Ratings",
          data: ratingList,
          color: trellisPalette[3],
        },
      ],
    };

    setBSRChartOptions(options);
  };

  if (!bsrChartOptions || secondaryTab !== "bsr_rank") {
    return null;
  }

  return (
    <div
      style={{
        padding: "20px 10px 20px 10px",
        border: "1px solid #ccc",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopRightRadius: "5px",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={bsrChartOptions} />
    </div>
  );
};

export default BSRChart;
