import React from "react";
import { Col } from "react-bootstrap";
import { DateField, InputField } from "./fields";
import { useProgramContext } from "./ProgramContext";

const HeaderFields = ({ control, formState }) => {
  const { showHeaderFields, engine } = useProgramContext();
  if (!showHeaderFields) return null;
  return (
    <>
      <Col xs={6}>
        <InputField
          label="Name"
          placeholder="Name"
          fieldName="name"
          errors={formState.errors}
          control={control}
          rules={{ required: { value: true, message: "Name is required" } }}
        />
      </Col>
      <Col xs={3} className="d-flex flex-column align-items-end">
        <DateField
          label="Start Date"
          placeholder="Starting on..."
          fieldName="start_date"
          errors={formState.errors}
          control={control}
          rules={{
            required: { value: true, message: "Start date is required" },
          }}
        />
      </Col>
      <Col xs={3} className="d-flex flex-column align-items-end">
        <DateField
          label={`End Date${engine === "bidding" ? " (optional)" : ""}`}
          placeholder="Ending on..."
          fieldName="end_date"
          errors={formState.errors}
          control={control}
          rules={{
            required: {
              value: engine === "pricing",
              message: "End date is required",
            },
          }}
        />
      </Col>
    </>
  );
};

export default HeaderFields;
