import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/styles";
import { ToolTips } from "utils/hiddenToolTips";
import { trellisPalette } from "../analytics/palettes";
import { Link } from "react-router-dom";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { formatNumber } from "utils/formatNumber";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import useOnScreen from "hooks/useOnScreen";
import { useDates } from "dates/useDates";
import { useParams, useLocation } from "react-router-dom";

const useStyles = (theme) => ({
  labelContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  labelContents: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem 2rem 2rem",
    border: "1px solid #CCC",
    borderRadius: "1rem",
    margin: "-100px auto 1rem",
    width: "300px",
    zIndex: 1000,
    background: "#FFF",
    position: "absolute",
    top: "100px",
  },
  labelBody: {
    maxHeight: "300px",
    overflowY: "auto",
    marginRight: "-10px",
  },
  labelHeader: {
    display: "flex",
    justifyContent: "center",
  },
  labelStats: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    listStyle: "none",
    fontSize: "1.2rem",
    justifyContent: "center",
    margin: ".7rem auto",
    flexDirection: "column",
  },
  labelStat: {
    textAlign: "center",
  },
  inlineButton: {
    border: "none",
    borderRadius: "10000000000px",
    color: "#888",
    background: "#FFF",
    cursor: "pointer",
    fontSize: "1.6rem",
  },
  actionIcons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  editButton: {
    paddingRight: ".5rem",
  },
  closeLabelButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    width: "20px",
    height: "20px",
  },
  resetButton: {
    background: trellisPalette[10],
    color: "#FFF",
    fontSize: "1.1rem",
    marginTop: ".9rem",
    position: "absolute",
    right: "8px",
    padding: ".25rem",
  },
  annotationItem: {
    display: "grid",
    gridTemplateColumns: ".7fr 7fr 2fr",
    alignItems: "center",
    padding: ".5em 0 2rem",
  },
  annotationItemBottom: {
    gridSpan: "1 / span 5",
    margin: ".5rem 2rem",
    padding: ".7rem 0",
  },
  annotationItemImage: {
    height: "40px",
    width: "40px",
    objectFit: "cover",
    marginLeft: "40px",
    marginTop: ".5rem",
  },
  link: {
    marginLeft: "2rem",
    color: trellisPalette[10],
    fontWeight: 500,
    cursor: "pointer",
  },
  annotationFormLabel: {
    textAlign: "left",
    padding: "1rem .5rem",
    fontWeight: 500,
    fontSize: "14px",
    maxWidth: "100%",
  },
  inlineDate: {
    color: "#555",
    fontSize: "1rem",
    fontWeight: 600,
    width: "100%",
    padding: ".5rem",
    gridColumn: "1 / span 5",
  },
  confirmContainer: {
    gridColumn: "1 / span 5",
    fontSize: "1.2rem",
    padding: ".5rem 1rem",
  },
  confirmMessage: {
    textAlign: "center",
    padding: "1.5rem 0",
    color: trellisPalette[0],
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
  confirmButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "-10px",
  },
  confirmButton: {
    padding: ".6rem 1.6rem",
    color: "#FFF",
    background: trellisPalette[0],
    border: `1.5px solid ${trellisPalette[0]}`,
    borderRadius: "1rem",
    margin: "0 auto",
    fontSize: ".9rem",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1.4px",
  },
  annotationDescription: {
    fontSize: "1.2rem",
    fontWeight: 500,
    margin: "auto 0",
    lineHeight: "1.15",
    maxWidth: "240px",
    paddingLeft: ".5rem",
  },
  annotationLower: {
    gridColumn: "1 / span 5",
    paddingTop: ".8rem",
  },
  annotationForm: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  addAnnotationButton: {
    background: "#D91C60",
    color: "#FFF",
    border: "none",
    borderRadius: ".75rem",
    fontSize: "1.3rem",
    letterSpacing: "1.1px",
    fontWeight: 700,
    padding: ".5rem 2rem",
    width: "100%",
    margin: "auto",
  },
  annotationDescriptionInput: {
    marginBottom: "1rem",
    height: "50px",
    border: "1px solid #CCC",
    resize: "vertical",
    padding: "1rem",
    fontSize: "1.1rem",
  },
  annotationButton: {
    height: "35px",
    width: "35px",
    marginTop: "auto",
    padding: ".7rem",
    background: "#D91C60",
    color: "#FFF",
    borderRadius: "100000000000px",
    border: "none",
  },
  createdByInitials: {
    background: trellisPalette[6],
    height: "25px",
    width: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    padding: ".5rem",
    borderRadius: "900000000000px",
    fontSize: "1.35rem",
    fontWeight: 600,
    marginRight: ".5rem",
  },
  trellisLogo: {
    height: "25px",
    width: "25px",
  },
  impactData: {
    fontSize: "1.2rem",
    marginLeft: "1.5rem",
    paddingTop: ".5rem",
    whiteSpace: "no-wrap",
  },
  labelLink: {
    color: "#D91266",
    textAlign: "center",
    float: "center",
    fontWeight: "500",
    cursor: "pointer",
  },
});

const AnnotationLabel = ({
  date,
  selectedDate,
  setSelectedDate,
  selectedProduct,
  selectedCategory,
  data,
  addAnnotation,
  editAnnotation,
  removeAnnotation,
  classes,
  user,
  context,
  marketPlace,
}) => {
  const [newAnnotation, setNewAnnotation] = useState({
    createdBy: user.user_full_name,
    date,
  });
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [showControls, setShowControls] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [overflowMargin, setOverflowMargin] = useState(0);
  const [display, setDisplay] = useState("hidden");
  const labelRef = useRef();
  const isVisible = useOnScreen(labelRef);
  const { group } = useDates();

  const { id, product, plan } = useParams();
  const { pathname } = useLocation();

  // Below tracks if on an ad or product level, context not carried through MultiMetricChart, so this should properly
  // decide whether to show custom annotations form or not
  const showCustomAnnotationForm =
    (id || product || plan) && !pathname.includes("category");

  // This hook centers each annotation label based on the chart tick it corresponds to
  const useLabelWidth = (ref) => {
    const [width, setWidth] = useState(1);

    useEffect(() => {
      if (ref.current && isVisible) {
        const position = ref.current?.getBoundingClientRect();

        // Handle potential overflow when label opened - 50px to account for padding in most views containing charts
        const vw = parseFloat(window.innerWidth);
        const overflow = vw - 300 / 2 - position.x - 50;

        if (overflow < 0) {
          setOverflowMargin(Math.abs(overflow));
        } else {
          setOverflowMargin(0);
        }

        setWidth(position.width);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, group, data, isVisible]);

    return width;
  };

  const clearForm = () => {
    setNewAnnotation({
      annotation: "",
      createdBy: user.user_full_name,
      date,
    });
  };

  const formatMetaDetails = (meta) => {
    return Object.keys(meta).map((m, i) => (
      <li className={classes.impactData} key={i}>
        {snakeCaseToSentenceCase(m)}
        {meta[m].change > 0 ? " increased" : " decreased"} by{" "}
        {m === "daily_budget" || m === "product_price"
          ? formatNumber(Math.abs(meta[m].change), {
              currency: {
                marketPlace: marketPlace.marketPlace,
              },
            })
          : formatNumber(Math.abs(meta[m].change), {}, 2)}
        {m === "max_acos" && "%"} from{" "}
        {m === "daily_budget" || m === "product_price"
          ? formatNumber(Math.abs(meta[m].previous), {
              currency: {
                marketPlace: marketPlace.marketPlace,
              },
            })
          : formatNumber(Math.abs(meta[m].previous), {}, 2)}
        {m === "max_acos" && "%"} to{" "}
        {m === "daily_budget" || m === "product_price"
          ? formatNumber(Math.abs(meta[m].current), {
              currency: {
                marketPlace: marketPlace.marketPlace,
              },
            })
          : formatNumber(Math.abs(meta[m].current), {}, 2)}
        {m === "max_acos" && "%"}
      </li>
    ));
  };

  // This is needed to set display back to flex after label is closed, otherwise the label axis becomes increasingly misaligned with chart ticks
  useEffect(() => {
    if (selectedDate === date) {
      setDisplay("block");
    } else {
      setDisplay("flex");
    }
  }, [selectedDate, date]);

  const labelWidth = useLabelWidth(labelRef);

  return (
    <Fragment>
      <div
        ref={labelRef}
        className={classes.labelContainer}
        style={{ display: display }}
      >
        <span />
        {selectedDate === date && (
          <div
            className={classes.labelContents}
            key={date}
            style={{
              marginLeft: `-${(300 - labelWidth) / 2 + overflowMargin}px`,
            }}
          >
            <div className={classes.labelHeader}>
              <h6 style={{ textAlign: "center", fontSize: "14px" }}>
                {moment(date, ["MM-DD-YYYY", "MMM-YY"]).format(
                  "MMMM DD, YYYY"
                ) === "Invalid date"
                  ? date
                  : group === "month" || group === "monthly"
                  ? moment(date, ["YYYY-MM-DD"]).format("MMMM YYYY")
                  : moment(date, [
                      "YYYY-MM-DD",
                      "MMM-DD-YYYY",
                      "MMM-DD",
                    ]).format("MMMM DD, YYYY")}
              </h6>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedAnnotation(null);
                  setSelectedDate(null);
                  setDisplay("flex");
                }}
                className={`${classes.closeLabelButton} ${classes.inlineButton}`}
              >
                <i className="fa fa-close fa-sm" />
              </button>
            </div>
            <div className={classes.labelBody}>
              {data?.annotations?.map((annotation, index) => (
                <li
                  className={classes.annotationItem}
                  key={index}
                  onMouseOver={() => setShowControls(index)}
                  onMouseLeave={() => setShowControls(false)}
                  style={{
                    display:
                      showDeleteConfirmation &&
                      annotation?.id !== selectedAnnotation?.id
                        ? "none"
                        : "grid",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      alignSelf: "flex-start",
                    }}
                  >
                    <ToolTips
                      toolTip={
                        annotation?.created_by?.first_name
                          ? `${annotation.created_by?.first_name} ${
                              annotation.created_by?.last_name
                            } ${
                              annotation.created_by?.is_staff
                                ? "(Trellis Staff)"
                                : ""
                            }`
                          : "Automatically generated by Trellis"
                      }
                      position={"top"}
                    />
                    <span className={classes.createdByInitials}>
                      {annotation.created_by?.first_name ? (
                        annotation.created_by?.first_name.split("")[0]
                      ) : (
                        <img
                          className={classes.trellisLogo}
                          src={logo}
                          alt={"Trellis logo"}
                        />
                      )}
                    </span>
                  </div>
                  <div>
                    {group !== "day" && (
                      <span className={classes.inlineDate}>
                        {moment(
                          annotation.ts.substring(0, 10),
                          "YYYY-MM-DD"
                        ).format("MMMM DD, YYYY")}
                      </span>
                    )}
                    {annotation?.annotation_type === "AD_CREATED" ? (
                      <p className={classes.annotationDescription}>
                        {/* SET TAB WITHIN CATEGORIES */}
                        <Link
                          className={classes.labelLink}
                          to={{
                            pathname: `/user/advertising/dashboard/ads/${annotation?.advertisement?.id}`,
                          }}
                        >
                          {annotation?.advertisement?.name}
                        </Link>{" "}
                        Created
                      </p>
                    ) : annotation?.annotation_type === "AD_UPDATED" ? (
                      <>
                        <p className={classes.annotationDescription}>
                          <Link
                            className={classes.labelLink}
                            to={{
                              pathname: `/user/advertising/dashboard/ads/${annotation?.advertisement?.id}`,
                            }}
                          >
                            {annotation?.advertisement?.name}
                          </Link>{" "}
                          Updated
                        </p>
                        <ul>{formatMetaDetails(annotation.meta)}</ul>
                      </>
                    ) : annotation?.annotation_type ===
                      "PRODUCT_PRICE_CHANGE" ? (
                      <>
                        <p className={classes.annotationDescription}>
                          <Link
                            className={classes.labelLink}
                            key={"productLink" + annotation?.product?.id}
                            to={{
                              pathname: `/user/merchandising/product/${annotation?.product?.id}`,
                            }}
                          >
                            {annotation?.product.asin}
                          </Link>{" "}
                          Updated
                        </p>
                        <ul>{formatMetaDetails(annotation.meta)}</ul>
                      </>
                    ) : annotation?.annotation_type ===
                      "PRODUCT_CONTENT_CHANGE" ? (
                      <>
                        <p className={classes.annotationDescription}>
                          <Link
                            className={classes.labelLink}
                            key={"productQualityLink" + annotation?.product?.id}
                            to={`/user/content/seo?id=${annotation?.product?.id}`}
                          >
                            {annotation?.product.asin} (
                            {annotation?.product?.sku})
                          </Link>{" "}
                          Content Updated
                        </p>
                      </>
                    ) : annotation?.annotation_type === "PROMO_STARTED" ? (
                      <>
                        <p className={classes.annotationDescription}>
                          <Link
                            className={classes.labelLink}
                            key={"productLink" + annotation?.product?.id}
                            to={{
                              pathname: `/user/merchandising/product/${annotation?.product?.id}`,
                            }}
                          >
                            {annotation?.product.asin}
                          </Link>{" "}
                          Promotion Started
                        </p>
                      </>
                    ) : annotation?.annotation_type === "PROMO_ENDED" ? (
                      <>
                        <p className={classes.annotationDescription}>
                          <Link
                            className={classes.labelLink}
                            key={"productLink" + annotation?.product?.id}
                            to={{
                              pathname: `/user/merchandising/product/${annotation?.product?.id}`,
                            }}
                          >
                            {annotation?.product.asin}
                          </Link>{" "}
                          Promotion Ended
                        </p>
                      </>
                    ) : annotation?.annotation_type === "CUSTOM" &&
                      annotation.product ? (
                      <p className={classes.annotationDescription}>
                        <Link
                          className={classes.labelLink}
                          key={"productLink" + annotation?.product?.id}
                          to={{
                            pathname: `/user/merchandising/product/${annotation?.product?.id}`,
                          }}
                        >
                          {annotation?.product.asin}
                        </Link>{" "}
                        {annotation.description}
                      </p>
                    ) : annotation?.annotation_type === "CUSTOM" &&
                      annotation.advertisement ? (
                      <p className={classes.annotationDescription}>
                        <Link
                          className={classes.labelLink}
                          key={
                            "advertisementLink" + annotation?.advertisement?.id
                          }
                          to={{
                            pathname: `/user/advertising/dashboard/ads/${annotation?.advertisement?.id}`,
                          }}
                        >
                          {annotation?.advertisement?.name}
                        </Link>{" "}
                        {annotation.description}
                      </p>
                    ) : (
                      <p className={classes.annotationDescription}>
                        {annotation.description}
                      </p>
                    )}{" "}
                  </div>
                  {group === "day" && (
                    <div
                      className={classes.actionIcons}
                      style={{
                        visibility:
                          showControls === index ? "visible" : "hidden",
                      }}
                    >
                      {annotation.annotation_type === "CUSTOM" && (
                        <button
                          onClick={(e) => {
                            setSelectedAnnotation(annotation);
                          }}
                          className={`${classes.inlineButton} ${classes.editButton}`}
                        >
                          <i className="fa fa-pencil" />
                        </button>
                      )}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedAnnotation(annotation);
                          setShowDeleteConfirmation(true);
                        }}
                        className={`${classes.inlineButton}`}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  )}

                  {showDeleteConfirmation &&
                    selectedAnnotation?.id === annotation.id && (
                      <div className={classes.confirmContainer}>
                        <p className={classes.confirmMessage}>
                          Are you sure you want to permanently delete this
                          annotation?
                        </p>
                        <div className={classes.confirmButtons}>
                          <button
                            onClick={() => {
                              setShowDeleteConfirmation(false);
                              setSelectedAnnotation(null);
                              clearForm();
                            }}
                            className={classes.confirmButton}
                            style={{
                              color: trellisPalette[0],
                              background: "#fff",
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => {
                              removeAnnotation(selectedAnnotation?.id);
                              setShowDeleteConfirmation(false);
                              setSelectedDate(null);
                              setSelectedAnnotation(null);
                              clearForm();
                            }}
                            className={classes.confirmButton}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}

                  <div className={classes.annotationLower}>
                    {((context === "purchase-behavior" &&
                      selectedProduct &&
                      annotation?.product?.product_image_url) ||
                      (!selectedProduct &&
                        annotation?.product?.product_image_url)) && (
                      <>
                        <img
                          src={annotation?.product?.product_image_url}
                          alt={`${annotation?.product?.title}`}
                          className={classes.annotationItemImage}
                        />
                        {annotation.annotation_type !== "CUSTOM" && (
                          <Link
                            key={"productLink" + annotation?.product?.id}
                            to={{
                              pathname: `/user/merchandising/product/${annotation?.product?.id}`,
                            }}
                          >
                            <span className={classes.link}>
                              {annotation?.product?.asin}
                            </span>
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </li>
              ))}
            </div>

            {group === "day" &&
              showCustomAnnotationForm &&
              !showDeleteConfirmation && (
                <form className={classes.annotationForm}>
                  <label
                    htmlFor="annotation"
                    className={classes.annotationFormLabel}
                  >
                    Annotation Description
                  </label>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      selectedAnnotation && setSelectedAnnotation(null);

                      setNewAnnotation({
                        annotation: "",
                        createdBy: user.user_full_name,
                        date,
                      });
                    }}
                    className={`${classes.inlineButton} ${classes.resetButton}`}
                    type="button"
                  >
                    <i className="fa fa-undo fa-sm" />
                  </button>

                  <textarea
                    type="text"
                    name="annotation"
                    value={
                      selectedAnnotation && !showDeleteConfirmation
                        ? selectedAnnotation?.description
                        : newAnnotation.annotation
                    }
                    onChange={(e) => {
                      selectedAnnotation
                        ? setSelectedAnnotation({
                            ...selectedAnnotation,
                            description: e.target.value,
                          })
                        : setNewAnnotation({
                            ...newAnnotation,
                            annotation: e.target.value,
                          });
                    }}
                    className={classes.annotationDescriptionInput}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (selectedAnnotation) {
                        editAnnotation(selectedAnnotation);
                        setSelectedAnnotation(null);
                        clearForm();
                      } else {
                        if (newAnnotation?.annotation?.trim().length > 0) {
                          addAnnotation(newAnnotation);
                          clearForm();
                        }
                      }
                    }}
                    className={classes.addAnnotationButton}
                  >
                    {selectedAnnotation ? "Update" : "Add"}
                  </button>
                </form>
              )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

AnnotationLabel.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(useStyles)(AnnotationLabel));
