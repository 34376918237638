import { generateNonce } from "./generateNonce";
import {
  AMAZON_ADV_API_CLIENT_ID,
  DEV_STRIPE_PUBLIC_KEY,
  GV_API_AUTH_CLIENT_ID,
  GV_API_AUTH_CLIENT_SECRET,
  PRODUCTION_GV_API_AUTH_CLIENT_ID,
  PRODUCTION_GV_API_AUTH_CLIENT_SECRET,
  PROD_STRIPE_PUBLIC_KEY,
  AMAZON_SP_API_AUTH_APP_ID,
  WALMART_API_CLIENT_ID,
} from "./gvSecrets";

let LOCAL_URL_PREFIX = "http://localhost:8000";
let URL_PREFIX = "https://api.growthvectors.io";

let LOCAL_APP_URL_PREFIX = "https://localhost:3000";
let APP_URL_PREFIX = "https://app.gotrellis.com";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function getURLPrefix() {
  if (isLocalhost) {
    return LOCAL_URL_PREFIX;
  } else {
    return URL_PREFIX;
  }
}

function getAPPURL() {
  if (isLocalhost) {
    return LOCAL_APP_URL_PREFIX;
  } else {
    return APP_URL_PREFIX;
  }
}

export function getAPIKeys() {
  if (isLocalhost) {
    return {
      API_AUTH_CLIENT_ID: GV_API_AUTH_CLIENT_ID,
      API_AUTH_SECRET: GV_API_AUTH_CLIENT_SECRET,
    };
  } else {
    return {
      API_AUTH_CLIENT_ID: PRODUCTION_GV_API_AUTH_CLIENT_ID,
      API_AUTH_SECRET: PRODUCTION_GV_API_AUTH_CLIENT_SECRET,
    };
  }
}

export function getAmazonAdvClientURL() {
  let redirect_uri = getAPPURL();
  return (
    "https://www.amazon.com/ap/oa?client_id=" +
    AMAZON_ADV_API_CLIENT_ID +
    "&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri=" +
    redirect_uri +
    "/onboard/amzadv"
  );
}

/*
  TODO: Notes about clientType param if we want to support marketplace auth beyond the USA
	Type of client by marketplace locations. (1) For the US marketplace: clientType=seller;
  (2) Other marketplace locations: clientType=seller-{market} where {market} field can be ca or mx.
  ca: Canada;
  mx: Mexico
*/

export const getWalmartClientURL = (marketPlace = null) => {
  const redirectUri = `${getAPPURL()}/onboarding/v2/connect?channel=walmart`;
  const clientType = marketPlace ? marketPlace.toLowerCase() : "seller";
  const nonce = generateNonce(10);

  return `https://login.account.wal-mart.com/authorize?responseType=code&clientId=${WALMART_API_CLIENT_ID}&redirectUri=${redirectUri}&nonce=${nonce}&state=A0YFFJJQMD&clientType=${clientType}`;
};

export const CONST_SPAPI_NA_REGION_FOR_URI_EXTERNAL =
  "32c8012na78d434b9e005c76d9d3506a";
export const CONST_SPAPI_EU_REGION_FOR_URI_EXTERNAL =
  "4d09cd92991446d588d1e3b2c5eu9ba3";
export const CONST_SPAPI_FE_REGION_FOR_URI_EXTERNAL =
  "1d9f09093f8d49ba8f394142fed9223f";
export const CONST_SPAPI_SRC_ONBOARDING_FOR_URI_EXTERNAL =
  "ede51dd983bf483586oa3d3ed2d66efa";
const CONST_SPAPI_SRC_SUMMARY_FOR_URI_EXTERNAL =
  "297a230e25af4316asu37b80dmm49553";

export const CONST_SPAPI_URI_EXTERNAL_REGION_DECODE = {
  [CONST_SPAPI_NA_REGION_FOR_URI_EXTERNAL]: "NA",
  [CONST_SPAPI_EU_REGION_FOR_URI_EXTERNAL]: "EU",
  [CONST_SPAPI_FE_REGION_FOR_URI_EXTERNAL]: "FE",
};

const CONST_SPAPI_URI_EXTERNAL_REGION_ENCODE = {
  NA: CONST_SPAPI_NA_REGION_FOR_URI_EXTERNAL,
  EU: CONST_SPAPI_EU_REGION_FOR_URI_EXTERNAL,
  FE: CONST_SPAPI_FE_REGION_FOR_URI_EXTERNAL,
};
export function getAmazonSPAPIClientURL(
  is_seller,
  marketplace,
  region,
  spapiSrc
) {
  let redirect_uri = getAPPURL();
  let useSellerCentral = "";
  switch (marketplace) {
    case "US":
    case "USA":
      useSellerCentral = is_seller
        ? US_AMAZON_SELLER_CENTRAL_URL
        : US_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "CA":
      useSellerCentral = is_seller
        ? CA_AMAZON_SELLER_CENTRAL_URL
        : CA_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "MX":
      useSellerCentral = is_seller
        ? MX_AMAZON_SELLER_CENTRAL_URL
        : MX_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "UK":
      useSellerCentral = is_seller
        ? UK_AMAZON_SELLER_CENTRAL_URL
        : UK_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "FR":
      useSellerCentral = is_seller
        ? FR_AMAZON_SELLER_CENTRAL_URL
        : FR_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "DE":
    case "GR":
      useSellerCentral = is_seller
        ? GR_AMAZON_SELLER_CENTRAL_URL
        : GR_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "IT":
      useSellerCentral = is_seller
        ? IT_AMAZON_SELLER_CENTRAL_URL
        : IT_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "NL":
      useSellerCentral = is_seller
        ? NL_AMAZON_SELLER_CENTRAL_URL
        : NL_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "SE":
      useSellerCentral = is_seller
        ? SE_AMAZON_SELLER_CENTRAL_URL
        : SE_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "PL":
      useSellerCentral = is_seller
        ? PL_AMAZON_SELLER_CENTRAL_URL
        : PL_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "BR":
      useSellerCentral = is_seller
        ? BR_AMAZON_SELLER_CENTRAL_URL
        : BR_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "BE":
      useSellerCentral = is_seller
        ? BE_AMAZON_SELLER_CENTRAL_URL
        : BE_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "ES":
      useSellerCentral = is_seller
        ? ES_AMAZON_SELLER_CENTRAL_URL
        : ES_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "IN":
      useSellerCentral = is_seller
        ? IN_AMAZON_SELLER_CENTRAL_URL
        : IN_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "AE":
      useSellerCentral = is_seller
        ? AE_AMAZON_SELLER_CENTRAL_URL
        : AE_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "SA":
      useSellerCentral = is_seller
        ? SA_AMAZON_SELLER_CENTRAL_URL
        : SA_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "SG":
      useSellerCentral = is_seller
        ? SG_AMAZON_SELLER_CENTRAL_URL
        : SG_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "AU":
      useSellerCentral = is_seller
        ? AU_AMAZON_SELLER_CENTRAL_URL
        : AU_AMAZON_VENDOR_CENTRAL_URL;
      break;
    case "JP":
      useSellerCentral = is_seller
        ? JP_AMAZON_SELLER_CENTRAL_URL
        : JP_AMAZON_VENDOR_CENTRAL_URL;
      break;
    default:
      break;
  }
  let useEncodedRegion = CONST_SPAPI_URI_EXTERNAL_REGION_ENCODE[region];
  let useEncodedSpapiSrc = "";
  if (spapiSrc === "onboarding") {
    useEncodedSpapiSrc = CONST_SPAPI_SRC_ONBOARDING_FOR_URI_EXTERNAL;
  } else if (spapiSrc === "summ") {
    useEncodedSpapiSrc = CONST_SPAPI_SRC_SUMMARY_FOR_URI_EXTERNAL;
  }

  let encodedState = Buffer.from(
    JSON.stringify({ src: useEncodedSpapiSrc, region: useEncodedRegion })
  ).toString("base64");
  return (
    useSellerCentral +
    "/apps/authorize/consent?application_id=" +
    AMAZON_SP_API_AUTH_APP_ID +
    "&state=" +
    encodedState +
    "&redirect_uri=" +
    redirect_uri
  );
}

const NA_AMAZON_MWS_ONBOARD_URL =
  "https://sellercentral.amazon.com/gp/mws/registration/register.html?appDevMWSAccountId=0709-1592-6409&applicationName=Trellis Corporation";

const EU_AMAZON_MWS_ONBOARD_URL =
  "https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?appDevMWSAccountId=2560-9623-8347&applicationName=Trellis Corporation";

const FE_AMAZON_MWS_ONBOARD_URL =
  "https://sellercentral.amazon.com.au/gp/mws/registration/register.html?appDevMWSAccountId=2560-9623-8347&applicationName=Trellis Corporation";

// NA
const US_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.com";
const US_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.com";

const CA_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.ca";
const CA_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.ca";

const MX_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.com.mx";
const MX_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.com.mx";

const BR_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.com.br";
const BR_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.com.br";

// EU
const UK_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral-europe.amazon.com";
const UK_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.co.uk";

const FR_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral-europe.amazon.com";
const FR_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.fr";

const GR_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral-europe.amazon.com";
const GR_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.de";

const IT_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral-europe.amazon.com";
const IT_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.it";

const ES_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral-europe.amazon.com";
const ES_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.es";

const BE_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral-europe.amazon.com";
const BE_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.com.be";

const NL_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.nl";
const NL_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.nl";

const SE_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.se";
const SE_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.se";

const PL_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.pl";
const PL_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.pl";

const IN_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.in";
const IN_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.in";

const AE_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.ae";
const AE_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.me";

const SA_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.sa";
const SA_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.me";

// FE
const SG_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.sg";
const SG_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.com.sg";

const AU_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.com.au";
const AU_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.com.au";

const JP_AMAZON_SELLER_CENTRAL_URL = "https://sellercentral.amazon.co.jp";
const JP_AMAZON_VENDOR_CENTRAL_URL = "https://vendorcentral.amazon.co.jp";

export function getStripePublicKey() {
  if (isLocalhost) {
    return DEV_STRIPE_PUBLIC_KEY;
  } else {
    return PROD_STRIPE_PUBLIC_KEY;
  }
}

export default getURLPrefix;
