import React, { useState, useMemo, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import moment from "moment";
import { useFetch } from "hooks/api";
import { formatCurrency } from "utils/formatNumber";
import BulkAdPlanActionForm from "./BulkAdPlanActionForm";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";

import { useMutation, useQueryClient } from "react-query";
import NotificationSystem from "react-notification-system";
import api from "utils/api/index";
import { sendToastNotification } from "utils/sendToastNotification";
import { MONTHLY_BUDGET_MULTIPLIER } from "components/core/blocks/AdPlan/constants";
import { ToolTips } from "utils/hiddenToolTips";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { snakeCaseToSentenceCase } from "utils/formatText";
import Loading from "components/core/blocks/Loading";
import { trellisPalette } from "components/custom/analytics/palettes";

export const formatScheduledAction = (
  adId,
  startDate,
  endDate,
  actionUnit,
  actionValue,
  actionType,
  channel
) => {
  return {
    channel: channel === "walmart" ? "walmart" : "AMZ",
    status: "scheduled",
    start_date: `${startDate} 00:00:00.000000`,
    end_date: `${endDate} 00:00:00.000000`,
    gvadvertisements_set: [adId],
    fields: [
      {
        field_name: "daily_budget",
        change_type:
          actionUnit === "percent" ? "percentage_change" : "flat_change",
        change_value:
          actionType === "decrease"
            ? actionUnit === "percent"
              ? 1 + parseFloat(actionValue * -1) / 100
              : parseFloat(actionValue * -1)
            : actionUnit === "percent"
            ? 1 + parseFloat(actionValue) / 100
            : parseFloat(actionValue),
      },
      {
        field_name: "monthly_budget",
        change_type:
          actionUnit === "percent" ? "percentage_change" : "flat_change",
        change_value:
          actionUnit === "percent"
            ? actionType === "decrease"
              ? 1 + parseFloat(actionValue * -1) / 100
              : 1 + parseFloat(actionValue) / 100
            : actionType === "decrease"
            ? parseFloat(actionValue * MONTHLY_BUDGET_MULTIPLIER * -1)
            : parseFloat(actionValue) * MONTHLY_BUDGET_MULTIPLIER,
      },
    ],
  };
};
const ScheduledBudgetSummary = ({
  state,
  marketPlace: { marketPlace },
  adId,
  channel,
  jumpToTab,
}) => {
  const [selectedAction, setSelectedAction] = useState([]);
  const [budgetFormData, setBudgetFormData] = useState({
    actionUnit: "percent",
  });

  const notificationRef = useRef();
  const tableRef = useRef();
  const formModalRef = useRef();
  const cancelModalRef = useRef();

  const queryClient = useQueryClient();

  const { actionType, actionValue, actionUnit, startDate, endDate } =
    budgetFormData;

  // Fetch scheduled actions data
  const { data: scheduledActions, isLoading } = useFetch(
    ["scheduledActions", adId],
    `/scheduledactions/`,
    {
      advertisement: adId,
      channel: channel === "walmart" ? "walmart" : "AMZ",
    },
    {
      select: (res) => res.data?.results ?? [],
    }
  );

  const deleteHandler = useMutation(
    async (data) => {
      const { id } = data;
      await api.remove(`/api/scheduledactions/${id}/`);
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          "Successfully Cancelled Budget Changes"
        );

        setBudgetFormData({ actionUnit: "percent" });
        setSelectedAction([]);
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Remove Scheduled Budget Changes"
        );
      },
    }
  );

  const createHandler = useMutation(
    async (body) => {
      const reqData = body.data ?? {};
      return await api.post("/api/scheduledactions/", reqData);
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          "Updating Media Plan Budget"
        );

        setBudgetFormData({ actionUnit: "percent" });
        setSelectedAction([]);
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Media Plan Budget"
        );
      },
    }
  );

  const updateHandler = useMutation(
    async (body) => {
      const reqData = body.data ?? {};
      return await api.put(`/api/scheduledactions/${reqData.id}/`, reqData);
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          "Updating Media Plan Budget"
        );

        setBudgetFormData({ actionUnit: "percent" });
        setSelectedAction([]);
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Media Plan Budget"
        );
      },
    }
  );

  const processScheduledBudgetUpdate = async (status = "scheduled") => {
    const overlappingAction = scheduledActions.some((sa) => {
      if (
        (moment(startDate).isBetween(
          sa.start_date.slice(0, 10),
          sa.end_date.slice(0, 10),
          undefined,
          "[]"
        ) ||
          moment(endDate).isBetween(
            sa.start_date.slice(0, 10),
            sa.end_date.slice(0, 10),
            undefined,
            "[]"
          )) &&
        // Only consider overlap if a budget update already exists, allow scheduled bid boost alongside media plan budget update
        sa.gvadvertisements_set.length
      ) {
        return true;
      }
      return false;
    });

    // Only apply overlap check in create mode
    if (overlappingAction && !selectedAction?.length) {
      sendToastNotification(
        notificationRef,
        "warning",
        `Failed to create scheduled update for ${state.ad_name}. The selected date range collides with a previously scheduled action.`
      );
      return;
    }

    const updateData = formatScheduledAction(
      state.id,
      startDate,
      endDate,
      actionUnit,
      actionValue,
      actionType,
      channel
    );

    if (budgetFormData?.id) {
      updateData["id"] = budgetFormData?.id;
    }

    if (budgetFormData?.id) {
      updateHandler.mutate({
        data: updateData,
      });
    } else {
      createHandler.mutate({
        data: updateData,
      });
    }
  };

  const tableData = useMemo(() => {
    if (!scheduledActions?.length) {
      return [];
    }

    const data =
      Object.values(
        scheduledActions?.reduce((acc, a) => {
          // Below avoids grouping overlapping ad scheduled actions together, even if date ranges and change values match
          if (a?.gvadvertisements_set?.length) {
            acc[
              `${a.start_date}-${a.end_date}-${a.fields[0].change_value}-${a.id}`
            ] =
              acc[
                `${a.start_date}-${a.end_date}-${a.fields[0].change_value}-${a.id}`
              ] || [];
            acc[
              `${a.start_date}-${a.end_date}-${a.fields[0].change_value}-${a.id}`
            ].push(a);
            return acc;
          }

          // Below groups keyword and target actions together if change types/values and date ranges all match
          acc[`${a.start_date}-${a.end_date}-${a.fields[0].change_value}`] =
            acc[`${a.start_date}-${a.end_date}-${a.fields[0].change_value}`] ||
            [];
          acc[`${a.start_date}-${a.end_date}-${a.fields[0].change_value}`].push(
            a
          );
          return acc;
        }, {})
      )
        ?.map((a) => {
          return {
            ...a[0],
            entityCount: a.length,
            id: a.map((groupedAction) => groupedAction.id),
          };
        })
        ?.sort((a, b) => new Date(b.start_date) - new Date(a.start_date)) ?? [];

    return data;
  }, [scheduledActions]);

  if (isLoading) {
    return <Loading fullPage={false} height={"500px"} />;
  }

  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <Row>
        <Col xs={12}>
          {scheduledActions?.length === 0 ? (
            <p className="text-center p-3">
              There are no changes currently scheduled for this media plan.
            </p>
          ) : (
            <></>
          )}
          <div
            className={`d-flex ${
              scheduledActions?.length
                ? "justify-content-end"
                : "justify-content-center"
            }`}
          >
            <ConfirmationModal
              title={"Schedule Budget Update"}
              onCancel={() => setBudgetFormData({ actionUnit: "percent" })}
              onConfirm={() => processScheduledBudgetUpdate("scheduled")}
              inlineModal={true}
              inlineButtonContainerStyles={{ marginBottom: "auto" }}
              confirmButtonText={"Confirm"}
              disableConfirmButton={
                !parseFloat(actionValue) ||
                !actionType ||
                !actionUnit ||
                startDate > endDate ||
                !startDate ||
                !endDate
              }
              confirmationMessage={
                <>
                  <BulkAdPlanActionForm
                    formData={budgetFormData}
                    setFormData={setBudgetFormData}
                    actionTarget={"Daily Budget"}
                    schedulingDialogue={true}
                    disabledFields={
                      budgetFormData?.status === "active"
                        ? [
                            "actionType",
                            "actionValue",
                            "actionUnit",
                            "startDate",
                          ]
                        : []
                    }
                  />

                  <p className="fs-4 py-2 w-75 mx-auto">
                    {!parseFloat(actionValue) ||
                    !actionType ||
                    !actionUnit ||
                    !endDate ||
                    !startDate
                      ? "Please fill out all required fields."
                      : startDate > endDate
                      ? "Invalid date range. Start date must come before end date."
                      : `Are you sure you want to ${actionType} the daily budget by ${
                          actionUnit === "percent"
                            ? `${Math.round(Math.abs(actionValue))}%`
                            : formatCurrency(Math.abs(actionValue), marketPlace)
                        } from ${formatCurrency(
                          state.dailyBudget,
                          marketPlace
                        )} to ${formatCurrency(
                          Math.abs(
                            actionUnit === "percent"
                              ? actionType === "increase"
                                ? state.dailyBudget * (actionValue / 100) +
                                  state.dailyBudget
                                : state.dailyBudget *
                                  ((100 - actionValue) / 100)
                              : actionType === "increase"
                              ? parseFloat(state.dailyBudget) +
                                parseFloat(actionValue)
                              : parseFloat(state.dailyBudget) -
                                parseFloat(actionValue)
                          ),
                          marketPlace
                        )} a day for the selected period (${
                          (moment(endDate).diff(startDate, "days") + 1) * 24
                        } hours)?`}
                  </p>
                </>
              }
              modalButtonText={
                // Below would ideally be an AuthButton instance, but trouble binding refs properly to share modal form outside of ReactTable
                <button
                  ref={formModalRef}
                  className={"adplan_button fs-6"}
                  style={{
                    fontSize: "12px",
                    transform: "none",
                    marginTop: "1rem",
                  }}
                >
                  Schedule Budget Change
                </button>
              }
            />
            {scheduledActions?.length ? (
              <ConfirmationModal
                title={"Cancel Schedule Budget Update"}
                onCancel={() => setBudgetFormData({ actionUnit: "percent" })}
                onConfirm={() => {
                  if (typeof selectedAction === "object") {
                    for (let i = 0; i < selectedAction?.length; i++) {
                      deleteHandler.mutate({
                        id: selectedAction[i],
                        isLast: i === selectedAction?.length - 1,
                      });
                    }
                    return;
                  }

                  deleteHandler.mutate({ id: selectedAction, isLast: true });
                }}
                inlineModal={true}
                inlineButtonContainerStyles={{ marginBottom: "auto" }}
                confirmButtonText={"Confirm"}
                confirmationMessage={
                  <>
                    <p className="fs-4 py-2">
                      Are you sure you'd like to cancel the scheduled budget
                      update?
                    </p>
                  </>
                }
                modalButtonText={
                  <div
                    style={{ color: "#73706E", cursor: "pointer" }}
                    className="mx-2"
                    ref={cancelModalRef}
                  >
                    <Container>
                      <ToolTips
                        toolTip={"Cancel Scheduled Action"}
                        position={"top"}
                      />

                      <i
                        className="fa fa-calendar-times-o fa-sm px-4 d-flex justify-content-center"
                        style={{
                          fontSize: "16px",
                          cursor: "pointer",
                          opacity: ".8",
                          position: "relative",
                          visibility: "hidden",
                        }}
                      />
                    </Container>
                  </div>
                }
              />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <NotificationSystem ref={notificationRef} />
        {scheduledActions?.length ? (
          <BulkActionsTable
            marketPlace={marketPlace}
            tableRef={tableRef}
            tableId={"insightsTable"}
            titleCol={{
              id: "date",
              isStatic: true,
              Header: "Scheduled Change Period",
              accessor: "schedule",
              style: { width: "450px" },
              width: 300,
              Cell: ({ value, row }) => {
                const { start_date, end_date } = row._original;

                return (
                  <>
                    <div className="d-flex align-items-center overflow-ellipsis">
                      {moment(start_date).utc().format("MMMM D, YYYY")} to{" "}
                      {moment(end_date).utc().format("MMMM D, YYYY")}
                    </div>
                  </>
                );
              },
              filterMethod: (filter, row) => {
                return;
              },
              Filter: ({ filter, onChange }) => null,
            }}
            columnSpecs={[
              {
                key: "status",
                compareKey: null,
                options: {
                  metric: {
                    id: "status",
                    format: "text",
                    accessor: (d) => d.status,
                    options: {
                      headerClassName: "text-start justify-content-start",
                      className: "text-start d-flex align-items-center",
                      cellContainerClasses:
                        "text-start justify-content-start py-3 align-items-center",
                      width: 100,
                    },
                    name: "Status",
                  },
                  formatter: (v) => {
                    return snakeCaseToSentenceCase(v);
                  },
                  hideFilter: true,
                },
              },
              {
                key: "change_type",
                compareKey: null,
                options: {
                  metric: {
                    id: "change_type",
                    format: "text",
                    accessor: (d) => d,

                    options: {
                      headerClassName: "text-start justify-content-start",
                      className: "text-start",
                      cellContainerClasses:
                        "d-flex text-start justify-content-start h-100 align-items-center",
                      width: 750,
                    },
                    name: "Change Details",
                  },
                  formatter: (d) => {
                    let savedScheduledChange = d?.fields[0];

                    const type = [
                      "timebound_per_change",
                      "timebound_flat_change",
                      "manual_bid",
                    ].includes(savedScheduledChange?.field_name)
                      ? "bidBoost"
                      : "budgetChange";

                    if (type === "bidBoost") {
                      return (
                        <>
                          The bid for {d?.entityCount}{" "}
                          {d.gvadvertisedkeyword_set?.length > 0 ||
                          d.walmartadgroupkeyword_set?.length > 0
                            ? `keyword${d?.entityCount > 1 ? "s" : ""}`
                            : `target${d?.entityCount > 1 ? "s" : ""}`}{" "}
                          will be boosted by{" "}
                          {savedScheduledChange?.field_name ===
                          "timebound_per_change"
                            ? `${parseInt(
                                Math.abs(
                                  savedScheduledChange?.change_value * 100 - 100
                                )
                              )}%`
                            : formatCurrency(
                                Math.abs(savedScheduledChange?.change_value),
                                marketPlace
                              )}{" "}
                          during this period.
                          <span
                            onClick={() =>
                              jumpToTab(
                                d.gvadvertisedkeyword_set?.length > 0 ||
                                  d.walmartadgroupkeyword_set?.length > 0
                                  ? "keywords"
                                  : "targets"
                              )
                            }
                            className="mx-3"
                            style={{
                              color: trellisPalette[10],
                              fontWeight: 600,
                              cursor: "pointer",
                            }}
                          >
                            View
                          </span>
                        </>
                      );
                    }

                    if (d.status === "active") {
                      return `The advertising budget is currently ${
                        savedScheduledChange?.change_type ===
                        "percentage_change"
                          ? savedScheduledChange?.change_value >= 1
                            ? "increased"
                            : "decreased"
                          : savedScheduledChange?.change_value >= 0
                          ? "increased"
                          : "decreased"
                      } by ${
                        savedScheduledChange?.change_type ===
                        "percentage_change"
                          ? `${Math.round(
                              Math.abs(
                                savedScheduledChange?.change_value * 100 - 100
                              )
                            )}%`
                          : formatCurrency(
                              Math.abs(savedScheduledChange?.change_value),
                              marketPlace
                            )
                      } and set to ${formatCurrency(
                        state.dailyBudget,
                        marketPlace
                      )} a day (${formatCurrency(
                        state.dailyBudget * 30,
                        marketPlace
                      )} a month).`;
                    }

                    return `The advertising budget will be ${
                      savedScheduledChange?.change_type === "percentage_change"
                        ? savedScheduledChange?.change_value >= 1
                          ? "increased"
                          : "decreased"
                        : savedScheduledChange?.change_value >= 0
                        ? "increased"
                        : "decreased"
                    } by ${
                      savedScheduledChange?.change_type === "percentage_change"
                        ? `${Math.round(
                            Math.abs(
                              savedScheduledChange?.change_value * 100 - 100
                            )
                          )}%`
                        : formatCurrency(
                            Math.abs(savedScheduledChange?.change_value),
                            marketPlace
                          )
                    } and will be set to ${formatCurrency(
                      Math.abs(
                        savedScheduledChange?.change_type ===
                          "percentage_change"
                          ? state.dailyBudget *
                              savedScheduledChange?.change_value
                          : parseInt(state.dailyBudget) +
                              savedScheduledChange?.change_value
                      ),
                      marketPlace
                    )} a day (${formatCurrency(
                      savedScheduledChange?.change_type === "percentage_change"
                        ? parseInt(state.dailyBudget) *
                            MONTHLY_BUDGET_MULTIPLIER *
                            savedScheduledChange?.change_value
                        : parseInt(state.dailyBudget) *
                            MONTHLY_BUDGET_MULTIPLIER +
                            d.fields?.find(
                              (f) => f.field_name === "monthly_budget"
                            )?.change_value,
                      marketPlace
                    )} a month).`;
                  },
                  hideFilter: true,
                },
              },

              {
                key: "change_type",
                compareKey: null,
                options: {
                  metric: {
                    id: "change_type",
                    format: "text",
                    accessor: (d) => d,
                    options: {
                      headerClassName: "text-start justify-content-start",
                      className: "text-start d-flex align-items-center",
                      cellContainerClasses:
                        "text-start justify-content-start align-items-center",
                      width: 100,
                    },
                    name: "Actions",
                  },
                  formatter: (d) => {
                    const scheduledAction = scheduledActions?.find(
                      (a) => a.id === d.id || d.id.includes(a.id)
                    );

                    return (
                      <div
                        className="d-flex"
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <div
                          style={{
                            color: "#73706E",
                            cursor: "pointer",
                            visibility: scheduledAction?.fields?.find((f) =>
                              [
                                "timebound_per_change",
                                "timebound_flat_change",
                                "manual_bid",
                              ].includes(f.field_name)
                            )
                              ? "hidden"
                              : "inherit",
                          }}
                          className="mx-2"
                          onClick={() => {
                            const changeValue = scheduledAction?.fields?.find(
                              (f) => f.field_name === "daily_budget"
                            )?.change_value;
                            const changeUnit = scheduledAction?.fields?.find(
                              (f) => f.field_name === "daily_budget"
                            )?.change_type;

                            // Populate form data edit values here
                            setBudgetFormData({
                              id: scheduledAction?.id,
                              status: scheduledAction?.status,
                              actionType:
                                changeUnit === "percentage_change"
                                  ? changeValue >= 1
                                    ? "increase"
                                    : "decrease"
                                  : changeValue >= 0
                                  ? "increase"
                                  : "decrease",
                              actionValue:
                                changeUnit === "percentage_change"
                                  ? Math.abs(changeValue * 100) - 100
                                  : Math.abs(changeValue),
                              actionUnit:
                                changeUnit === "flat_change"
                                  ? "currency"
                                  : "percent",
                              startDate: moment(scheduledAction?.start_date)
                                .utc()
                                .format("YYYY-MM-DD"),
                              endDate: moment(scheduledAction?.end_date)
                                .utc()
                                .format("YYYY-MM-DD"),
                            });
                            formModalRef.current.click();
                            setSelectedAction(d.id);
                          }}
                        >
                          <ToolTips
                            toolTip={"Update Scheduled Action"}
                            position={"top"}
                          />

                          <i
                            className="fa fa-pencil fa-sm px-4 d-flex justify-content-center"
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                              opacity: ".8",
                              position: "relative",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            color: "#73706E",
                            cursor: "pointer",
                          }}
                          className="mx-2"
                          onClick={() => {
                            setSelectedAction(d.id);
                            cancelModalRef.current.click();
                          }}
                        >
                          <ToolTips
                            toolTip={"Cancel Scheduled Action"}
                            position={"top"}
                          />

                          <i
                            className="fa fa-calendar-times-o fa-sm px-4 d-flex justify-content-center"
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                              opacity: ".8",
                              position: "relative",
                            }}
                          />
                        </div>
                      </div>
                    );
                  },
                  hideFilter: true,
                },
              },

              {
                key: "created_by",
                compareKey: null,
                options: {
                  metric: {
                    id: "change_type",
                    format: "text",
                    accessor: (d) => d,
                    options: {
                      headerClassName: "text-start justify-content-start",
                      className: "text-start d-flex align-items-center",
                      cellContainerClasses:
                        "text-start justify-content-start p-3 align-items-center",
                      width: 200,
                    },
                    name: "Created By",
                  },
                  formatter: (data) => {
                    const { first_name, last_name, is_staff } = data.created_by;
                    return (
                      <>
                        {first_name} {last_name}{" "}
                        {is_staff ? " (Trellis Staff)" : ""}
                      </>
                    );
                  },
                  hideFilter: true,
                },
              },
            ]}
            data={tableData}
            bulkActionOptions={[]}
            hideFilterToggleIcon={true}
            isSelectable={false}
            isExpandable={false}
            selected={selectedAction}
            setSelected={setSelectedAction}
            selectionKey={"id"}
            hidePagination={true}
            getSearchCriteria={(row) => {
              const {
                advertisement: { name, id },
              } = row;
              return `${(name, id)}`;
            }}
            tableRowDescription={"Scheduled Actions"}
            minRows={0}
          />
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};

export default ScheduledBudgetSummary;
