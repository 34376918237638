import { AuthButton } from "components/core/basic/Button";
import CenteredModal from "components/core/basic/CenteredModal";
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { LAUNCH_STATES } from "./constants";
import ProductLaunchForm from "./ProductLaunchForm";

const LaunchEditModal = ({ launchData, id }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <AuthButton
        title={
          launchData?.state?.toLowerCase() ===
          LAUNCH_STATES.LAUNCHED.value.toLowerCase()
            ? "Extend Launch"
            : "Edit Launch"
        }
        buttonStyle="adplan_button"
        onClick={() => {
          setShowModal(!showModal);
        }}
      />

      <CenteredModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        title={`${
          launchData?.state?.toLowerCase() ===
          LAUNCH_STATES.LAUNCHED.value.toLowerCase()
            ? "Extend"
            : "Update"
        } Product Launch`}
        dialogClassName={
          launchData?.state?.toLowerCase() ===
          LAUNCH_STATES.LAUNCHED.value.toLowerCase()
            ? "modal-60w"
            : "modal-70w"
        }
        style={{ marginTop: "2rem" }}
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col
            xs={12}
            style={{
              margin:
                launchData?.state?.toLowerCase() ===
                LAUNCH_STATES.LAUNCHED.value.toLowerCase()
                  ? "0 35px"
                  : "0 80px",
            }}
          >
            <ProductLaunchForm
              editForm={true}
              launchData={launchData}
              id={id}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </Col>
        </Row>
      </CenteredModal>
    </>
  );
};

export default LaunchEditModal;
