import React from "react";
import { useFetch } from "hooks/api";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useDates } from "dates/useDates";
import { formatCurrency, formatPercent } from "utils/formatNumber";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { trellisPalette } from "components/custom/analytics/palettes";
import moment from "moment";
import { getAdTypeName } from "utils/getAdTypeName";
import { AD_TYPES } from "components/core/blocks/AdPlan/constants";
import Loading from "components/core/blocks/Loading";

const AdTypePieCharts = ({ fontFamily, setChartDataLoaded = () => {} }) => {
  const { marketPlace, channel } = useSelector((state) => state);
  const { start, end } = useDates();
  const { id: categoryId } = useParams();

  const adTypeColorMap = {
    // Amazon Ad Types
    [AD_TYPES.SPONSORED_PRODUCT.value]: trellisPalette[3],
    [AD_TYPES.SPONSORED_PRODUCT_AUTO.value]: trellisPalette[2],
    [AD_TYPES.SPONSORED_BRAND.value]: trellisPalette[1],
    [AD_TYPES.SPONSORED_BRAND_V2.value]: trellisPalette[1],
    [AD_TYPES.SPONSORED_BRAND_VIDEO.value]: trellisPalette[14],
    [AD_TYPES.SPONSORED_DISPLAY.value]: trellisPalette[5],
    [AD_TYPES.SPONSORED_DISPLAY_V2.value]: trellisPalette[5],
    // Walmart Ad Types
    [AD_TYPES.SPONSORED_PRODUCT_WALMART.value]: trellisPalette[3],
    [AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value]: trellisPalette[2],
    [AD_TYPES.SPONSORED_BRAND_WALMART.value]: trellisPalette[1],
    [AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value]: trellisPalette[14],
  };

  const startDate = moment(start).format("YYYY-MM-DD");
  const endDate = moment(end).format("YYYY-MM-DD");

  const { data: adTypeData, isLoading: isAdTypeDataLoading } = useFetch(
    ["adTypeData", startDate, endDate, categoryId],
    "/api/data_report_v2/advertisements/stats/",
    {
      dimensions: "ad_type",
      filters: categoryId
        ? `category_id:${parseInt(
            categoryId
          )},date__gte:${startDate},date__lte:${endDate}`
        : `date__gte:${startDate},date__lte:${endDate}`,
    },
    {
      default: [],
      select: (res) => {
        const data =
          res?.data?.reduce((acc, current) => {
            const adTypeColor = adTypeColorMap[current.ad_type];
            const name = getAdTypeName(
              current.ad_type,
              channel.currentChannel ?? "amazon"
            );
            const data = acc[name] ?? {
              name: name,
              color: adTypeColor,
              ad_sales: 0,
              ad_spend: 0,
            };
            data["ad_sales"] += current.ad_sales;
            data["ad_spend"] += current.ad_spend;
            acc[name] = data;
            return acc;
          }, {}) ?? {};
        return Object.values(data);
      },
    }
  );

  const adSpendData =
    adTypeData
      ?.map((type) => {
        return { name: type.name, color: type.color, y: type.ad_spend };
      })
      ?.filter((type) => type.y) ?? [];

  const adSalesData =
    adTypeData
      ?.map((type) => {
        return { name: type.name, color: type.color, y: type.ad_sales };
      })
      ?.filter((type) => type.y) ?? [];

  const adSalesTotal = adSalesData?.reduce((acc, current) => {
    return acc + current.y;
  }, 0);

  const adSpendTotal = adSpendData?.reduce((acc, current) => {
    return acc + current.y;
  }, 0);

  const getPieChartOptions = (
    title,
    series = [],
    total = null,
    overrideOptions = {}
  ) => {
    return {
      chart: {
        type: "pie",
        style: {
          fontFamily: fontFamily ?? "Poppins",
          fontWeight: 300,
          fontSize: "15px",
        },
      },
      title: {
        text: title,
        style: {
          fontWeight: 500,
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          if (total) {
            return (
              "<span><strong>" +
              this.point.name +
              ":</strong><br />" +
              formatCurrency(this.point.y, marketPlace.marketPlace) +
              "<strong>" +
              ` (${formatPercent(this.point.y / total, 1)})` +
              "</strong></span>"
            );
          }

          return (
            "<span><strong>" +
            this.point.name +
            ":</strong><br />" +
            formatCurrency(this.point.y, marketPlace.marketPlace) +
            "</span>"
          );
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            useHTML: true,
            formatter: function () {
              return (
                `<span style="font-size: 1.2em; font-weight: 600; opacity: 0.8">${this.point.name}</span><br>` +
                `<span style="opacity: 0.6; margin: auto; display: block; text-align: center; width: 100%;">${formatCurrency(
                  this.point.y,
                  marketPlace.marketPlace
                )} - ${formatPercent(this.point.y / total, 1)}</span>`
              );
            },
          },
        },
      },
      series: series,
      ...overrideOptions,
    };
  };

  const adSalesChartOptions = getPieChartOptions(
    "Ad Sales by Ad Type",
    [
      {
        name: "Ad Sales",
        colorByPoint: true,
        data: adSalesData ?? [],
      },
    ],
    adSalesTotal
  );

  const adSpendChartOptions = getPieChartOptions(
    "Ad Spend by Ad Type",
    [
      {
        name: "Ad Spend",
        colorByPoint: true,
        data: adSpendData ?? [],
      },
    ],
    adSpendTotal
  );

  if (isAdTypeDataLoading) {
    return <Loading fullPage={false} height={`300px`} />;
  }

  return (
    <Row>
      <Col xs={12} lg={6}>
        <HighchartsReact
          highcharts={Highcharts}
          options={adSalesChartOptions}
        />
      </Col>

      <Col xs={12} lg={6}>
        <HighchartsReact
          highcharts={Highcharts}
          options={adSpendChartOptions}
        />
      </Col>
    </Row>
  );
};

export default AdTypePieCharts;
