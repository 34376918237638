import moment from "moment";
import React, { useMemo } from "react";
import { GROUPS, DEFAULTS } from "./constants";
import { DatesContext } from "./DatesContext";
import selectDates from "./utils";
import { useHistory } from "react-router-dom";

const DatesProvider = ({
  children,
  group,
  period,
  compare,
  start: startProp,
  end: endProp,
}) => {
  const { start, end, preStart, preEnd, g, p, c } = useMemo(() => {
    let refDate = moment();
    if (endProp) {
      refDate = moment(endProp);
    }
    const g = group || DEFAULTS.group;
    // If the group is day, set the period to last30
    const p =
      period ||
      (g === DEFAULTS.group
        ? DEFAULTS.period
        : GROUPS.find((group) => (group.id = g)).periods[0]?.id);
    const c = compare || DEFAULTS.compare;
    return {
      g,
      p,
      c,
      ...selectDates({
        group: g,
        period: p,
        compare: c,
        start: startProp,
        end: refDate,
      }),
    };
  }, [period, compare, group, startProp, endProp]);

  const onGroupChange = (group, start, end, compare) => {
    group = GROUPS.find((g) => g.id === group);
    let period = group.periods[0].id;
    // If the group is day, set the period to last30
    if (group.id === DEFAULTS.group) {
      period = DEFAULTS.period;
    }
    onDateChange(start, end, group.id, period, compare);
  };

  const history = useHistory();
  const location = history.location;
  const pathname = location.pathname;
  const params = location.search;

  const onDateChange = (start, end, group, period, compare) => {
    const newParams = new URLSearchParams(params);

    if (group && group !== DEFAULTS.group) {
      newParams.set("group", group);
    } else {
      newParams.delete("group");
    }
    if (period) {
      newParams.set("period", period);
    } else {
      newParams.delete("period");
    }
    if (compare && compare !== DEFAULTS.compare) {
      newParams.set("compare", compare);
    } else {
      newParams.delete("compare");
    }
    if (period === "custom") {
      if (start) newParams.set("start", moment(start).format("YYYY-MM-DD"));
      if (end) newParams.set("end", moment(end).format("YYYY-MM-DD"));
    } else {
      newParams.delete("start");
      newParams.delete("end");
    }

    const url = `${pathname}?${newParams.toString()}`;
    history.push(url);
  };

  return (
    <DatesContext.Provider
      value={{
        start,
        end,
        preStart,
        preEnd,
        period: p,
        compare: c,
        group: g,
        setGroup: onGroupChange,
        setDate: onDateChange,
      }}
    >
      {children}
    </DatesContext.Provider>
  );
};

export default DatesProvider;
