import React from "react";
import api from "utils/api";
import { useMutation } from "react-query";
import { PrimaryButton } from "assets/themes/Buttons";

// eslint-disable-next-line no-unused-vars
const DownloadPDF = ({ url }) => {
  const downloadPDF = useMutation(async () => {
    const resp = await api.get("/api/pdf/", {
      url: url,
    });
    const downloadUrl = await resp.data.url;
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = "report.pdf";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  });

  return (
    <>
      <PrimaryButton
        disabled={downloadPDF.isLoading}
        onClick={downloadPDF.mutate}
      >
        {downloadPDF.isLoading ? "Downloading..." : "Download PDF"}
      </PrimaryButton>
    </>
  );
};

const DownloadPDFv2 = ({ url }) => {
  return (
    <PrimaryButton
      onClick={() => {
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.target = "_blank";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }}
    >
      Download PDF
    </PrimaryButton>
  );
};

export default DownloadPDFv2;
