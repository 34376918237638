import React, { useState, useMemo, useEffect } from "react";
import { Col, Row, Nav, Table, Card, Badge } from "react-bootstrap";
import {
  formatNumber,
  formatCurrency,
  formatPercent,
} from "utils/formatNumber";
import { useFetch } from "hooks/api";
import moment from "moment";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { SEARCH_AMAZON_LINKS } from "utils/marketplaceConstants";
import { HiExternalLink } from "react-icons/hi";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import Loading from "components/core/blocks/Loading";
import { ProductImageURL } from "components/custom/category/listings/ProductImage";
import ExternalProductLink from "components/custom/merchandise/ExternalProductLink";
import TrComponent from "components/custom/merchandise/TrComponent";
import useColumnSelector from "hooks/useColumnSelector";
import { Link } from "react-router-dom";
import api from "utils/api";
import useDebounce from "hooks/useDebounce";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FaChartLine } from "react-icons/fa6";

const SearchTermTable = ({
  isLoading,
  isSuccess,
  data,
  marketPlace,
  orgKWData,
  filterOrgKWData,
}) => {
  const [isExpanded, setIsExpanded] = useState(null);
  const [contentData, setContentData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [filters, setFilters] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const colToggle = useState();

  const debouncedSearch = useDebounce(searchFilter, 1500);

  const Age = ({ value }) => {
    if (value === null) {
      return <div className="text-center">-</div>;
    }
    const diff = moment().diff(moment(value), "days");

    if (diff === 0) {
      return <div className="text-center">Today</div>;
    }
    if (diff === 1) {
      return <div className="text-center">Yesterday</div>;
    }
    return <div className="text-center">{diff} days</div>;
  };

  const ExpandedContent = ({ className, children, style, row }) => {
    const [tab, setTab] = useState("products");
    const [selectedASIN, setSelectedASIN] = useState(null);

    const { isLoading, data, isSuccess } = useFetch(
      ["asin_table", marketPlace.marketPlace, row.original.id],
      "/merchandise/seo/products_by_keyword",
      {
        keyword_text: row.original.keyword_text,
        marketplace: marketPlace.marketPlace,
      },
      {
        default: [],
        select: (res) => res.data,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const {
      isLoading: isHistoryLoading,
      data: historyData,
      isSuccess: isHistorySuccess,
    } = useFetch(
      ["keyword_history", marketPlace.marketPlace, row.original.id],
      "/merchandise/seo/keyword_history",
      {
        id: row.original.id,
        marketplace: marketPlace.marketPlace,
      },
      {
        default: [],
        select: (res) => res.data,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const {
      isLoading: isCompLoading,
      data: competitorData,
      isSuccess: isCompSuccess,
    } = useFetch(
      ["competitors_table", marketPlace.marketPlace, row.original.id],
      "/merchandise/seo/competitors",
      {
        keyword_text: row.original.keyword_text,
        marketplace: marketPlace.marketPlace,
      },
      {
        default: [],
        select: (res) => res.data,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const {
      isLoading: isAdsLoading,
      data: adsData,
      isSuccess: isAdsSuccess,
    } = useFetch(
      ["advertisements", marketPlace.marketPlace, row.original.id],
      "/merchandise/seo/advertisements",
      {
        keyword_text: row.original.keyword_text,
        marketplace: marketPlace.marketPlace,
      },
      {
        default: [],
        select: (res) => res.data,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const TrProduct = ({ keyword }) => {
      if (keyword?.product_title === undefined) {
        return null;
      }

      if (keyword?.product?.price === 0) {
        return null;
      }

      if (keyword?.product_title.length === 0) {
        return null;
      }

      return (
        <tr id={keyword.id}>
          <td style={{ maxWidth: "300px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "65px", paddingRight: "15px" }}>
                <ProductImageURL
                  height={50}
                  width={50}
                  url={keyword?.image_url}
                  style={{ borderRadius: 15 }}
                />
              </div>
              <div>
                <Row>
                  <Col xs={12}>
                    <span className="fs-4">
                      <Link
                        to={`/user/merchandising/product/${keyword?.id}`}
                        target="_blank"
                      >
                        {keyword?.product_title}
                      </Link>
                    </span>
                  </Col>
                  <Col xs={12}>
                    <i
                      className="pr-33 fs-5"
                      style={{
                        fontWeight: "light",
                        color: "#665",
                      }}
                    >
                      <strong>ASIN: </strong>&nbsp;
                      {keyword?.asin}&nbsp;
                      <ExternalProductLink asin={keyword?.asin.toUpperCase()} />
                      <span className="ps-3">
                        <strong>Price: </strong>&nbsp;
                        {formatCurrency(
                          keyword?.price,
                          marketPlace.marketPlace
                        )}
                      </span>
                    </i>
                  </Col>
                </Row>
              </div>
            </div>
          </td>
          <td className="text-center">
            {keyword?.rank}&nbsp;
            <span
              className="ps-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedASIN(keyword?.asin);
                setTab("historical_rank");
              }}
            >
              <FaChartLine />
            </span>
          </td>
          <td className="text-center">{keyword?.bsr}</td>
          <td className="text-center">
            {formatCurrency(keyword?.price, marketPlace.marketPlace)}
          </td>
          <td className="text-center">
            <Age value={keyword?.updated_on} />
          </td>
        </tr>
      );
    };

    const TrCompetitor = ({ c }) => {
      return (
        <tr>
          <td>
            <div style={{ display: "flex" }}>
              <div style={{ width: "65px", paddingRight: "15px" }}>
                <ProductImageURL
                  height={50}
                  width={50}
                  url={c?.image_url}
                  style={{ borderRadius: 15 }}
                />
              </div>
              <div>
                <Row>
                  <Col xs={12}>
                    <span className="fs-4">{c?.product_title}</span>
                  </Col>
                  <Col xs={12}>
                    <i
                      className="pr-33 fs-5"
                      style={{
                        fontWeight: "light",
                        color: "#665",
                      }}
                    >
                      <strong>ASIN: </strong>&nbsp;
                      {c?.asin}&nbsp;
                      <ExternalProductLink asin={c?.asin.toUpperCase()} />
                    </i>
                  </Col>
                </Row>
              </div>
            </div>
          </td>
          <td>{c.bsr_data?.class_rank_title}</td>
          <td>{c.brand}</td>
          <td className="text-center">
            {formatNumber(c.bsr_data?.class_rank)}
          </td>
        </tr>
      );
    };

    const TrAd = ({ ad }) => {
      return (
        <tr>
          <td>
            <Link
              to={`/user/advertising/dashboard/ads/${ad?.ad_id}`}
              target="_blank"
            >
              {ad?.ad_name}
            </Link>
          </td>
          <td className="text-center">{formatNumber(ad?.impressions)}</td>
          <td className="text-center">{formatNumber(ad?.clicks)}</td>
          <td className="text-center">
            {formatCurrency(ad?.cost, marketPlace.marketPlace)}
          </td>
          <td className="text-center">
            {formatCurrency(ad?.attributed_sales_7d, marketPlace.marketPlace)}
          </td>
          <td className="text-center">
            {formatNumber(ad?.attributed_conversions_7d)}
          </td>
          <td className="text-center">
            {ad?.attributed_sales_7d > 0
              ? formatPercent(ad?.cost / ad?.attributed_sales_7d)
              : formatPercent(0)}
          </td>
        </tr>
      );
    };

    const historicalOptions = useMemo(() => {
      if (!isHistorySuccess) {
        return {};
      }

      const series = [
        historyData.series[0],
        historyData.series[1],
        historyData.series[2],
      ];

      for (let i = 0; i < historyData.series.length; i++) {
        const s = historyData.series[i];
        if (s.name === selectedASIN) {
          series.push(s);
        }
      }

      return {
        title: {
          text: null,
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        xAxis: {
          categories: historyData.categories,
        },

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        series: series,

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      };
    }, [isHistorySuccess, historyData, selectedASIN]);

    const { productData } = useMemo(() => {
      if (!data) {
        return { productData: [] };
      }

      const productData = Object.values(data);

      // sort dataValues by rank ascending
      productData.sort((a, b) => {
        return a.rank - b.rank;
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
      return { productData };
    }, [data]);

    return (
      <div
        style={{ minHeight: "300px", maxHeight: "600px", overflowY: "auto" }}
      >
        <Card className="fs-standard mb-0">
          <Card.Body>
            <Row>
              <Col xs={2} style={{ borderRight: "1px solid #ccc" }}>
                <div className="pt-2"></div>
                <Nav
                  variant="underline"
                  onSelect={(k) => {
                    return setTab(k);
                  }}
                  activeKey={tab}
                  className="flex-column text-start"
                >
                  <Nav.Link eventKey="products" className="text-start">
                    Products
                  </Nav.Link>
                  <Nav.Link eventKey="competitors" className="text-start">
                    Competitors
                  </Nav.Link>
                  <Nav.Link eventKey="ads" className="text-start">
                    Advertisements
                  </Nav.Link>
                  <Nav.Link eventKey="historical_rank" className="text-start">
                    Historical Rank
                  </Nav.Link>
                </Nav>
              </Col>
              <Col xs={10}>
                {isLoading && tab === "products" && (
                  <div className="text-center">
                    <Loading height={`300px`} fullPage={false} />
                  </div>
                )}

                {isCompLoading && tab === "competitors" && (
                  <div className="text-center">
                    <Loading height={`300px`} fullPage={false} />
                  </div>
                )}

                {isAdsLoading && tab === "ads" && (
                  <div className="text-center">
                    <Loading height={`300px`} fullPage={false} />
                  </div>
                )}

                {isHistoryLoading && tab === "historical_rank" && (
                  <div className="text-center">
                    <Loading height={`300px`} fullPage={false} />
                  </div>
                )}

                {!isLoading && isSuccess && tab === "products" && (
                  <>
                    <Row className="pb-3">
                      <Col>
                        Displayed below is a list of your products that were
                        found while searching for this keyword.
                      </Col>
                    </Row>
                    <Table size="sm" borderless hover>
                      <thead>
                        <tr>
                          <th className="text-start">Product</th>
                          <th className="text-center">Position</th>
                          <th className="text-center">BSR</th>
                          <th className="text-center">Price</th>
                          <th className="text-center">Last Found</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productData.map((kw) => (
                          <TrProduct keyword={kw} id={kw.id} />
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}

                {!isAdsLoading && isAdsSuccess && tab === "ads" && (
                  <>
                    <Row className="pb-3">
                      <Col>
                        Displayed below is a list of advertisements that are
                        associated with this keyword.
                      </Col>
                    </Row>
                    <Table size="sm" borderless hover>
                      <thead>
                        <tr>
                          <th className="text-start">Advertisement</th>
                          <th className="text-center">Impressions</th>
                          <th className="text-center">Clicks</th>
                          <th className="text-center">Cost</th>
                          <th className="text-center">Ad Sales</th>
                          <th className="text-center">Conversions</th>
                          <th className="text-center">ACOS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adsData?.map((ad) => (
                          <TrAd ad={ad} key={ad.ad_id} />
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}

                {!isCompLoading && isCompSuccess && tab === "competitors" && (
                  <>
                    <Row className="pb-3">
                      <Col>
                        Displayed below is a list of your competitors products
                        that were found while searching for this keyword.
                      </Col>
                    </Row>
                    <Table size="sm" borderless hover>
                      <thead>
                        <tr>
                          <th className="text-start">Product</th>
                          <th className="text-start">Category</th>
                          <th className="text-start">Brand</th>
                          <th className="text-center">BSR</th>
                        </tr>
                      </thead>
                      <tbody>
                        {competitorData?.map((c) => (
                          <TrCompetitor c={c} key={c.id} />
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}

                {!isHistoryLoading &&
                  isHistorySuccess &&
                  tab === "historical_rank" && (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={historicalOptions}
                    />
                  )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  };

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let columns = [
        {
          id: "expander",
          Header: "",
          isStatic: true,
          width: 50,
          style: { width: "50px" },
          filterable: false,
          Cell: ({ row }) => {
            return (
              <>
                <ExpanderCell
                  row={row}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              </>
            );
          },
        },
        {
          id: "keyword_text",
          isStatic: true,
          Header: "Search Term",
          accessor: "keyword_text",
          style: { width: "300px" },
          Cell({ value, row }) {
            return (
              <>
                <div>
                  {value}
                  &nbsp;
                  <a
                    href={
                      SEARCH_AMAZON_LINKS?.[marketPlace.marketPlace] + value
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View on Amazon"
                  >
                    <HiExternalLink />
                  </a>
                </div>
                {row._original.branded && (
                  <div>
                    <Badge
                      className={`badge bg-trellis-purple text-end`}
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      Branded
                    </Badge>
                  </div>
                )}
              </>
            );
          },
        },
        {
          id: "best_rank",
          isStatic: false,
          Header: "Best Position",
          accessor: "best_rank",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value, {}, 0)}</div>
          ),
        },
        {
          id: "avg_rank",
          isStatic: false,
          Header: "Avg. Positions",
          accessor: "avg_rank",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value, {}, 0)}</div>
          ),
        },
        {
          id: "products",
          isStatic: false,
          Header: "Found Products",
          accessor: "products",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{value.length}</div>
          ),
        },
        {
          id: "num_results",
          isStatic: false,
          Header: "Search Results",
          accessor: "num_results",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value, {}, 0)}</div>
          ),
        },
        {
          id: "paid_search",
          isStatic: false,
          Header: "Paid Visibility",
          accessor: "paid_search",
          checked: true,
          Cell: ({ value, row }) => {
            let label = "n/a";
            let labelColor = "lightgrey";
            if (value === 3) {
              label = "High";
              labelColor = "green";
            } else if (value === 2) {
              label = "Elevated";
              labelColor = "mauve";
            } else if (value === 1) {
              label = "Moderate";
              labelColor = "yellow";
            }
            return (
              <div className="text-center">
                <Badge
                  className={`badge bg-trellis-${labelColor} fs-5`}
                  pill
                  style={{
                    borderRadius: "5px",
                    marginLeft: "1rem",
                    paddingTop: "5px",
                  }}
                >
                  {label}
                </Badge>
              </div>
            );
          },
        },
        // {
        //   id: "impressions",
        //   isStatic: false,
        //   Header: "Est. Impressions",
        //   accessor: (value) => {
        //     return data?.impressions?.[value.keyword_text]
        //       ? data?.impressions?.[value.keyword_text].impressions
        //       : 0;
        //   },
        //   checked: false,
        //   Cell: ({ value }) => (
        //     <div className="text-center">{formatNumber(value)}</div>
        //   ),
        // },
        {
          id: "searchability",
          isStatic: false,
          Header: "Searchability",
          accessor: "keyword_text",
          checked: false,
          Cell: ({ value }) => (
            <div className="text-center">
              {formatNumber(contentData?.[value]?.seo_score)}
            </div>
          ),
        },
        {
          id: "buyability",
          isStatic: false,
          Header: "Buyability",
          accessor: "keyword_text",
          checked: false,
          Cell: ({ value }) => (
            <div className="text-center">
              {formatNumber(contentData?.[value]?.buyability_score)}
            </div>
          ),
        },
        {
          id: "source",
          isStatic: false,
          Header: "Source",
          accessor: "source",
          checked: true,
          Cell: ({ value }) => <div className="text-center">{value}</div>,
        },
        {
          id: "created_on",
          isStatic: false,
          Header: "Last Found",
          accessor: "created_on",
          checked: false,
          Cell: ({ value }) => {
            return <Age value={value} />;
          },
        },
      ];
      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle, data, marketPlace.marketPlace, isExpanded]
  );

  const { searchTerms } = useMemo(() => {
    const searchTerms = [];
    if (!isSuccess || isLoading) {
      return { searchTerms };
    }

    const allOrgKW = orgKWData.results.map(
      (okoKW) => okoKW.organic_keyword.keyword_text
    );

    const orgKWAdded = [];
    for (let i = 0; i < data.keywords.length; i++) {
      let pushSearchTerm = true;

      if (filterOrgKWData) {
        pushSearchTerm = false;
        for (let j = 0; j < orgKWData.results.length; j++) {
          const okoKW = orgKWData.results[j].organic_keyword;
          if (okoKW.keyword_text === data.keywords[i].keyword_text) {
            orgKWAdded.push(okoKW.keyword_text);
            pushSearchTerm = true;
            break;
          }
        }
      }

      if (pushSearchTerm) {
        if (
          debouncedSearch &&
          !data.keywords[i].keyword_text
            .toLowerCase()
            .includes(debouncedSearch.toLowerCase())
        ) {
          continue;
        }
        const row = { ...data.keywords[i] };
        if (allOrgKW.includes(row.keyword_text)) {
          row.source = "manual";
        }
        row.isExpanded = isExpanded === row.id;
        searchTerms.push(row);
      }
    }

    if (filterOrgKWData) {
      for (let j = 0; j < orgKWData.results.length; j++) {
        const okoKW = orgKWData.results[j].organic_keyword;
        if (!orgKWAdded.includes(okoKW.keyword_text)) {
          searchTerms.push({
            id: okoKW.keyword_text,
            keyword_text: okoKW.keyword_text,
            count: 0,
            avg_rank: 0,
            created_on: null,
            num_results: 0,
            source: "manual",
            products: [],
          });
        }
      }
    }

    return { searchTerms };
  }, [
    data,
    isLoading,
    isSuccess,
    isExpanded,
    filterOrgKWData,
    orgKWData,
    debouncedSearch,
  ]);

  const { qualityData } = useMemo(() => {
    const qualityData = [];
    if (!isSuccess || isLoading) {
      return { qualityData };
    }
    if (!searchTerms) {
      return { qualityData };
    }

    for (let i = 0; i < searchTerms.length; i++) {
      const q = {
        keyword_text: searchTerms[i].keyword_text,
        products: [],
      };
      for (let j = 0; j < searchTerms[i].products.length; j++) {
        const p = searchTerms[i].products[j].product_id;
        q.products.push(p);
      }
      qualityData.push(q);
    }

    return { qualityData };
  }, [searchTerms, isLoading, isSuccess]);

  const fetchQualityData = async () => {
    const res = await api.post("/merchandise/seo/content_scores", {
      keyword_data: qualityData,
    });

    setContentData(res.data);
  };

  useEffect(() => {
    if (qualityData.length > 0) {
      fetchQualityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityData]);

  const columnOrder = columns.map((col) => col.id);

  if (isLoading) {
    return (
      <div className="text-center">
        <Loading fullPage={false} height={300} />
      </div>
    );
  }

  if (!isLoading && isSuccess && data?.keywords?.length === 0) {
    return (
      <>
        <div className="text-center fs-4 px-5 pt-5 pb-3 mx-5">
          No search terms were found for this marketplace. Search terms will be
          discovered once your products and campaigns have been indexed by
          Trellis.
        </div>

        <div className="text-center fs-4 px-5 py-3">
          You can add search terms manually by clicking the "Add Search Terms"
          button.
        </div>
      </>
    );
  }

  if (!isLoading && isSuccess) {
    return (
      <>
        <div className="fs-4 py-4">
          Displayed below are the search terms we queried on Amazon, along with
          the count of your products discovered, the total results returned.
        </div>
        <ReactTable
          PaginationComponent={ReactTablePagination}
          getPaginationProps={() => ({
            updateSearch: setSearchFilter,
            clearFilters: () => {
              setFilters([]);
              setSearchFilter("");
              setClearFilters(true);
            },
            clearSearchTermFilters: () => setSearchFilter(""),
            searchTerm: searchFilter,
            setFilterState: setFilters,
            filteredState: filters,
            table: "seo-search-terms-table",
            setShowFilters: setShowFilters,
            showFilters: showFilters,
            style: { marginBottom: "2rem" },
            carouselLayout: false,
            disableSearchFocus: true,
          })}
          getTrProps={(table, row) => {
            let style = {};
            let expandedChildren = <ExpandedContent style={style} row={row} />;

            return { row, style, expandedChildren };
          }}
          getTheadFilterProps={(props) => {
            if (clearFilters) {
              props.filtered.forEach((filter, index) => {
                filter.value = "all";
              });
              setClearFilters(false);
            }
            return {
              style: { display: "flex", textAlign: "center" },
            };
          }}
          TrComponent={TrComponent}
          showPaginationTop
          showPaginationBottom={false}
          columns={columns}
          data={searchTerms}
          defaultPageSize={50}
          defaultSorted={[{ id: "num_results", desc: true }]}
          className="seo-search-terms-table"
          filterable={showFilters}
          state={columnOrder}
          columnSelectorProps={columnSelectorProps}
        />
      </>
    );
  }
};

export default SearchTermTable;
