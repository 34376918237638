import React from "react";
import { useFetch } from "hooks/api";
import { Link } from "react-router-dom";
import CreatePlanModal from "components/custom/merchandise/CreatePlanModal";
import { Col, Container as Grid, Row } from "react-bootstrap";
import moment from "moment";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import { useMemo } from "react";
import PlanStatus from "components/custom/merchandise/PlanStatus";
import { connect } from "react-redux";
import SummaryCard from "components/core/blocks/SummaryCard";
import Metric from "components/core/blocks/SummaryMetric";
import { trellisPalette } from "components/custom/analytics/palettes";
import RadialChart from "components/custom/merchandise/RadialChart";
import Accordion from "components/core/basic/Accordion";
import SummaryTable from "components/custom/merchandise/SummaryTable";
import PlansTable from "components/custom/merchandise/PlansTable";

const Empty = ({ children }) => (
  <Col xs={12}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "15rem",
        backgroundColor: "#f5f5f5",
        borderRadius: "1rem",
        color: "#666",
        fontWeight: "700",
      }}
      className="text-center"
    >
      <div>{children}</div>
    </div>
  </Col>
);

const PlanSummary = ({ plan }) => {
  const isActual = ["finished", "executing"].find((s) => s === plan.state);

  const currencyFormatter = (v) =>
    formatCurrency(v, plan.market_place, false, false, true);

  let sales, cost, units, roms;
  if (isActual) {
    sales = plan.stats?.actual_total_sales ?? 0;
    cost = plan.stats?.actual_total_cost ?? 0;
    units = plan.stats?.actual_total_units ?? 0;
    roms = plan.stats?.roms ?? 0;
  } else {
    sales = plan.stats?.target_sales ?? 0;
    cost = plan.stats?.target_total_cost ?? 0;
    units = plan.stats?.target_total_units ?? 0;
    roms = plan.stats?.target_roms ?? 0;
  }
  return (
    <>
      <div className="card" style={{ padding: "2rem" }}>
        <Link
          key={plan.id}
          className="text-decoration-none"
          to={`/user/merchandising/planning/plans/${plan.id}`}
        >
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12}>
                  <div
                    className="header"
                    style={{
                      maxWidth: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "25px",
                        top: "20px",
                        display: "flex",
                      }}
                    >
                      <div style={{ paddingTop: "20px" }}>
                        <PlanStatus status={plan.state} />
                      </div>
                    </div>
                    <h4 style={{ marginLeft: "20px", marginTop: 0 }}>
                      {plan.title}
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  style={{ marginLeft: "40px" }}
                  className="fs-standard"
                >
                  Planning due {moment(plan.planning_due).fromNow()}
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  style={{ marginLeft: "40px" }}
                  className="fs-standard"
                >
                  Executing{" "}
                  <strong>
                    {moment(plan.execution_start).format("MMM Do")} -
                    {moment(plan.execution_end).format("MMM Do")}
                  </strong>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <div className="content">
                <Row className="text-center" style={{ marginTop: "2rem" }}>
                  <Col xs={12}>
                    <h6 style={{ marginBottom: "-1rem" }}>
                      {isActual ? "Actual" : "Projected"}
                    </h6>
                  </Col>
                  <Col xs={3}>
                    <Row className="text-center">
                      <Col xs={12}>
                        <h4
                          style={{ marginBottom: "0.5rem", fontWeight: "500" }}
                        >
                          {currencyFormatter(sales ?? 0)}
                        </h4>
                      </Col>
                      <Col xs={12} className="fs-standard">
                        Sales
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="text-center">
                      <Col xs={12}>
                        <h4
                          style={{ marginBottom: "0.5rem", fontWeight: "500" }}
                        >
                          {currencyFormatter(cost ?? 0)}
                        </h4>
                      </Col>
                      <Col xs={12} className="fs-standard">
                        Costs
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="text-center">
                      <Col xs={12}>
                        <h4
                          style={{ marginBottom: "0.5rem", fontWeight: "500" }}
                        >
                          {formatNumber(units ?? 0, {}, 0)}
                        </h4>
                      </Col>
                      <Col xs={12} className="fs-standard">
                        Units
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="text-center">
                      <Col xs={12}>
                        <h4
                          style={{ marginBottom: "0.5rem", fontWeight: "500" }}
                        >
                          {formatNumber(roms ?? 0, {}, 0)}
                        </h4>
                      </Col>
                      <Col xs={12} className="fs-standard">
                        ROMS
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Link>
      </div>
    </>
  );
};

const PlansList = ({ marketPlace }) => {
  const {
    isLoading,
    isError,
    isSuccess,
    data: plansData,
  } = useFetch(["plans", marketPlace.marketPlace], "/merchandise/plans", {
    market_place: marketPlace.marketPlace,
  });
  const { isSuccess: statsSuccess, data: statsData } = useFetch(
    ["plans_stats", marketPlace.marketPlace],
    "/merchandise/plan_stats",
    {
      market_place: marketPlace.marketPlace,
    },
    {
      select: (d) => d.data,
    }
  );
  const plansByState = useMemo(() => {
    if (!plansData) {
      return {};
    }
    const plans = plansData.data.results.sort(
      (a, b) => new Date(b.created_on) - new Date(a.created_on)
    );
    return {
      planning: plans.filter((plan) =>
        ["planning", "planned"].find((s) => s === plan.state)
      ),
      executing: plans.filter((plan) =>
        ["executing"].find((s) => s === plan.state)
      ),
      finished: plans.filter((plan) =>
        ["finished"].find((s) => s === plan.state)
      ),
    };
  }, [plansData]);

  const currencyFormatter = (v) =>
    formatCurrency(v, marketPlace.marketPlace, false, false, true);

  const actual = {
    sales: statsData?.active?.actual_total_sales ?? 0,
    cost: statsData?.active?.actual_total_cost ?? 0,
    margin: statsData?.active?.actual_margin ?? 0,
    net_margin: statsData?.active?.actual_net_margin ?? 0,
    rops: statsData?.active?.rops ?? 0,
    roms: statsData?.active?.roms ?? 0,
    troas: statsData?.active?.troas ?? 0,
    roas: statsData?.active?.roas ?? 0,
    units: statsData?.active?.actual_total_units ?? 0,
  };
  const target = {
    sales: statsData?.active?.target_sales ?? 0,
    cost: statsData?.active?.target_total_cost ?? 0,
    margin: statsData?.active?.target_margin ?? 0,
    net_margin: statsData?.active?.target_net_margin ?? 0,
    rops: statsData?.active?.target_rops ?? 0,
    roms: statsData?.active?.target_roms ?? 0,
    troas: statsData?.active?.target_troas ?? 0,
    roas: statsData?.active?.target_roas ?? 0,
    units: statsData?.active?.target_total_units ?? 0,
  };

  return (
    <Grid fluid style={{ padding: "3rem", minHeight: "calc(100vh - 150px)" }}>
      <Row>
        <Col xs={12}>
          <Row style={{ marginBottom: "3rem" }}>
            <Col xs={12} lg={10}>
              <h3>Merchandising Plans</h3>
            </Col>
            <Col xs={12} lg={2} className={"d-flex justify-content-end"}>
              <Row>
                <Col xs={12} className={"d-flex justify-content-end"}>
                  <Link
                    to={{ pathname: "/user/merchandising/planning/settings" }}
                    style={{ fontSize: "2rem", color: "#999" }}
                  >
                    <i className="fa fa-cog" />
                  </Link>
                </Col>
                <Col xs={12}>
                  <CreatePlanModal />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          {isLoading && <p>Loading...</p>}
          {isError && <p>Error Loading Plans</p>}
          {isSuccess && (
            <>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12}>
                  {statsSuccess && (
                    <SummaryCard title={"Active"}>
                      <Row>
                        <Col xs={3}>
                          <RadialChart
                            plan={plansByState.executing?.[0]}
                            actual={actual}
                            target={target}
                            isActual={true}
                            seriesOptions={[
                              {
                                name: "Sales",
                                accessor: "sales",
                                color: trellisPalette[2],
                              },
                              {
                                name: "Cost",
                                accessor: "cost",
                                color: trellisPalette[13],
                              },
                              {
                                name: "Units",
                                accessor: "units",
                                color: trellisPalette[14],
                              },
                            ]}
                          />
                        </Col>
                        <Col xs={8}>
                          <Row>
                            <Col xs={3}>
                              <Metric
                                name="Sales"
                                value={statsData?.active?.actual_total_sales}
                                target={statsData?.active?.target_sales}
                                formatter={currencyFormatter}
                                showTarget
                                legendColor={trellisPalette[2]}
                              />
                            </Col>
                            <Col xs={3}>
                              <Metric
                                name="Cost"
                                value={statsData?.active?.actual_total_cost}
                                target={statsData?.active?.target_total_cost}
                                showTarget
                                lower
                                formatter={currencyFormatter}
                                legendColor={trellisPalette[13]}
                              />
                            </Col>
                            <Col xs={3}>
                              <Metric
                                name="Units"
                                value={statsData?.active?.actual_total_units}
                                target={statsData?.active?.target_total_units}
                                showTarget
                                lower
                                formatter={(v) => formatNumber(v, null, 0)}
                                legendColor={trellisPalette[14]}
                              />
                            </Col>
                            <Col xs={3}>
                              <Metric
                                name="Margin"
                                value={statsData?.active?.actual_margin}
                                target={statsData?.active?.target_margin}
                                showTarget
                                formatter={formatPercent}
                              />
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "4rem" }}>
                            <Col xs={3}>
                              <Metric
                                name="ROMS"
                                value={statsData?.active?.roms}
                                target={statsData?.active?.target_roms}
                                formatter={(v) => formatNumber(v, null, 0)}
                                showTarget
                              />
                            </Col>
                            <Col xs={3}>
                              <Metric
                                name="ROPS"
                                value={statsData?.active?.rops}
                                target={statsData?.active?.target_rops}
                                formatter={(v) => formatNumber(v, null, 0)}
                                showTarget
                              />
                            </Col>
                            <Col xs={3}>
                              <Metric
                                name="ROAS"
                                value={statsData?.active?.roas}
                                target={statsData?.active?.target_roas}
                                formatter={(v) => formatNumber(v, null, 0)}
                                showTarget
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Accordion title={"Details"}>
                        <SummaryTable
                          isActual={true}
                          stats={statsData?.active?.state}
                          marketPlace={marketPlace}
                        />
                      </Accordion>
                    </SummaryCard>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "3rem" }}>
                {plansByState.executing.length > 2 && (
                  <PlansTable
                    marketPlace={marketPlace}
                    isActual={true}
                    plans={plansByState.executing}
                  />
                )}
                {plansByState.executing.length <= 2 &&
                  plansByState.executing.map((plan) => (
                    <Col xs={12} key={plan.id}>
                      <PlanSummary plan={plan} />
                    </Col>
                  ))}
                {plansByState.executing.length === 0 && (
                  <Empty>No Plans are currently executing</Empty>
                )}
              </Row>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} className="fs-standard">
                  <strong>Planning</strong>
                </Col>
              </Row>
              <Row style={{ marginBottom: "3rem" }}>
                {plansByState.planning.length > 2 && (
                  <PlansTable
                    marketPlace={marketPlace}
                    isActual={false}
                    plans={plansByState.planning}
                  />
                )}
                {plansByState.planning.length <= 2 &&
                  plansByState.planning.map((plan) => (
                    <Col xs={12} key={plan.id}>
                      <PlanSummary plan={plan} />
                    </Col>
                  ))}
                {plansByState.planning.length === 0 && (
                  <Empty>
                    <CreatePlanModal />
                  </Empty>
                )}
              </Row>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} className="fs-standard">
                  <strong>Complete</strong>
                </Col>
              </Row>
              <Row style={{ marginBottom: "3rem" }}>
                {plansByState.finished.length > 2 && (
                  <PlansTable
                    marketPlace={marketPlace}
                    isActual={true}
                    plans={plansByState.finished}
                  />
                )}
                {plansByState.finished.length <= 2 &&
                  plansByState.finished.map((plan) => (
                    <Col xs={12} key={plan.id}>
                      <PlanSummary plan={plan} />
                    </Col>
                  ))}
                {plansByState.finished.length === 0 && (
                  <Empty>No Completed Plans</Empty>
                )}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(PlansList);
