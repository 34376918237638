import React from "react";
import {
  ToolTips,
  PREVIOUS_PAGE_TOOLTIP,
  NEXT_PAGE_TOOLTIP,
} from "utils/hiddenToolTips";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";

const Pagination = ({
  currentPage,
  lastPage,
  next,
  previous,
  skipToFirstPage,
  skipToLastPage,
  skipToPage,
  showSkipOptions = true,
  pageSizeOptions = [5, 10, 15, 20, 25],
  pageSize,
  setPageSize,
  allowShowAll = true,
}) => {
  return (
    <div className="pagination-controls">
      {showSkipOptions && (
        <button
          style={{
            background: "#fff",
            color: "#000",
            padding: "1rem",
            position: "relative",
          }}
          className="btn-round m-0"
          onClick={() => {
            skipToFirstPage();
          }}
          disabled={currentPage === lastPage}
        >
          <ToolTips toolTip={NEXT_PAGE_TOOLTIP} position={"top"} />
          <FirstPageIcon style={{ fontSize: "24px", fontWeight: "bold" }} />
        </button>
      )}
      <button
        style={{
          background: "#fff",
          color: "#000",
          fontSize: "2rem",
          padding: "1rem",
          position: "relative",
        }}
        className="btn-round m-0"
        onClick={() => {
          previous();
        }}
        disabled={currentPage === 1}
      >
        <ToolTips toolTip={PREVIOUS_PAGE_TOOLTIP} position={"top"} />
        <NavigateBeforeIcon style={{ fontSize: "24px", fontWeight: "bold" }} />
      </button>
      <div className="-center mr-3">
        <span className="-pageInfo">
          {currentPage} / {lastPage ? lastPage : 1}
        </span>
      </div>
      <button
        style={{
          background: "#fff",
          color: "#000",
          padding: "1rem",
          position: "relative",
        }}
        className="btn-round m-0"
        onClick={() => {
          next();
        }}
        disabled={currentPage === lastPage}
      >
        {" "}
        <ToolTips toolTip={NEXT_PAGE_TOOLTIP} position={"top"} />
        <NavigateNextIcon style={{ fontSize: "24px", fontWeight: "bold" }} />
      </button>
      {showSkipOptions && (
        <button
          style={{
            background: "#fff",
            color: "#000",
            padding: "1rem",
            position: "relative",
          }}
          className="btn-round m-0"
          onClick={() => {
            skipToLastPage();
          }}
          disabled={currentPage === lastPage}
        >
          <ToolTips toolTip={NEXT_PAGE_TOOLTIP} position={"top"} />
          <LastPageIcon style={{ fontSize: "24px", fontWeight: "bold" }} />
        </button>
      )}
      <select
        onChange={(e) => setPageSize(e.target.value)}
        value={pageSize}
        className="p-2 border rounded fs-5"
      >
        {allowShowAll && <option value="all">Show All</option>}
        {pageSizeOptions.map((size, index) => (
          <option key={index} value={size}>
            {size} per page
          </option>
        ))}
      </select>
    </div>
  );
};

export default Pagination;
