import React from "react";
import { withRouter } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import axios from "axios";
import { connect } from "react-redux";
import { Row, Col, NavDropdown } from "react-bootstrap";
import logo from "assets/images/logo/Trellis_Logomark_color.png";

import PropTypes from "prop-types";

import getURLPrefix from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

const timeSeriesFilter = [
  { id: "1", rollup: "daily", name: "Daily" },
  { id: "2", rollup: "weekly", name: "Weekly" },
  { id: "3", rollup: "monthly", name: "Monthly" },
];

class CampaignChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      perfOptions: {},
      isLoading: false,
      pieOptions: {},
      campaign_name: this.props.campaign_name,
      timeSeriesData: {},
      rollup: timeSeriesFilter[1],
      selectedRollUp: timeSeriesFilter[1],
      marketplace: this.props.marketPlace.marketPlace,
    };
  }

  populateTimeSeriesData(performanceOptions, timeSeriesData, rollup) {
    var dates = [];
    var impressions = [];
    var clicks = [];
    var adSales = [];
    var adSpend = [];
    var acos = [];
    if (Object.keys(timeSeriesData).length > 0) {
      if (rollup === "daily") {
        for (var x = 0; x < timeSeriesData["daily"].length; x++) {
          dates.push(timeSeriesData["daily"][x].start_date);
          impressions.push(timeSeriesData["daily"][x].daily_impressions);
          clicks.push(timeSeriesData["daily"][x].daily_clicks);
          adSales.push(timeSeriesData["daily"][x].daily_sales.toFixed(2));
          adSpend.push(timeSeriesData["daily"][x].daily_cost.toFixed(2));
          acos.push((timeSeriesData["daily"][x].daily_acos * 100).toFixed(2));
        }
      } else if (rollup === "weekly") {
        for (var y = 0; y < timeSeriesData["weekly"].length; y++) {
          dates.push(timeSeriesData["weekly"][y].start_week);
          impressions.push(timeSeriesData["weekly"][y].weekly_impressions);
          clicks.push(timeSeriesData["weekly"][y].weekly_clicks);
          adSales.push(timeSeriesData["weekly"][y].weekly_sales.toFixed(2));
          adSpend.push(timeSeriesData["weekly"][y].weekly_cost.toFixed(2));
          acos.push((timeSeriesData["weekly"][y].weekly_acos * 100).toFixed(2));
        }
      } else if (rollup === "monthly") {
        for (var z = 0; z < timeSeriesData["monthly"].length; z++) {
          dates.push(timeSeriesData["monthly"][z].start_month);
          impressions.push(timeSeriesData["monthly"][z].monthly_impressions);
          clicks.push(timeSeriesData["monthly"][z].monthly_clicks);
          adSales.push(timeSeriesData["monthly"][z].monthly_sales.toFixed(2));
          adSpend.push(timeSeriesData["monthly"][z].monthly_cost.toFixed(2));
          acos.push(
            (timeSeriesData["monthly"][z].monthly_acos * 100).toFixed(2)
          );
        }
      }
    }
    performanceOptions["xAxis"]["data"] = dates;
    performanceOptions["series"][0]["data"] = impressions;
    performanceOptions["series"][1]["data"] = clicks;
    performanceOptions["series"][2]["data"] = adSales;
    performanceOptions["series"][3]["data"] = adSpend;
    performanceOptions["series"][4]["data"] = acos;
    return performanceOptions;
  }

  populatePieChart(performanceOptionsPieChart, stats) {
    var legend = [];
    var salesRate = [];
    for (var y = 0; y < stats.length; y++) {
      legend.push(stats[y].keyword_text);
      salesRate.push({
        value: stats[y].sales.toFixed(2),
        name: stats[y].keyword_text,
      });
    }
    performanceOptionsPieChart["legend"]["data"] = legend;
    performanceOptionsPieChart["series"][0]["data"] = salesRate;
    return performanceOptionsPieChart;
  }

  componentDidMount() {
    // var performanceOptionsPieChart = {
    //   tooltip: {
    //     trigger: 'item',
    //     formatter: '{a} <br/> {b} : {c} ({d}%)'
    //   },
    //   legend: {
    //     type: 'scroll',
    //     orient: 'horizontal',
    //     top: 20,
    //     data: []
    //   },
    //   series: [
    //     {
    //       name: 'ad sales',
    //       type: 'pie',
    //       radius: '55%',
    //       center: ['50%', '60%'],
    //       data: [
    //       ],
    //       emphasis: {
    //         itemStyle: {
    //           shadowBlur: 10,
    //           shadowOffsetX: 0,
    //           shadowColor: 'rgba(0, 0, 0, 0.5)'
    //         }
    //       },
    //       itemStyle: {
    //         normal: {
    //           label: {
    //             show: false
    //           },
    //           labelLine: {
    //             show: false
    //           }
    //         }
    //       }
    //     }
    //   ], color: ['#24054c', '#563776', '#c43066', '#e172af', '#a78dc2', '#d5c1e0', '#f2d5e5', '#cdcdcd', '#888888']
    // }
    var performanceOptions = {
      grid: {
        top: 60,
        bottom: 60,
        left: "2.5%",
        right: "2.5%",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#152748",
          },
        },
      },
      toolbox: {
        show: false,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: { zoom: "Zoom In", back: "Zoom Out" },
          },
          restore: { show: true, title: "Reset" },
        },
      },
      legend: {
        data: ["Impressions", "Clicks", "AdSales", "AdSpend", "ACOS"],
        selected: {
          Impressions: true,
          Clicks: false,
          AdSales: true,
          AdSpend: false,
          ACOS: true,
        },
      },

      xAxis: {
        type: "category",
        boundaryGap: true,
        data: [],
      },
      yAxis: [
        {
          show: false,
          type: "value",
          splitLine: {
            show: false,
          },
        },
        {
          show: false,
          type: "value",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Impressions",
          data: [],
          type: "bar",
          itemStyle: {
            color: "#a78dc2",
            opacity: 0.8,
          },
          yAxisIndex: 0,
        },
        {
          name: "Clicks",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#d5c1e0",
        },
        {
          name: "AdSales",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#c43066",
        },
        {
          name: "AdSpend",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#e172af",
        },
        {
          name: "ACOS",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#563776",
        },
      ],
    };
    var that_ref = this;
    axios
      .all([
        axios.get(
          URL_PREFIX +
            "/api/trellistrak/?type=campaign_stats&campaign_name=" +
            encodeURIComponent(this.state.campaign_name)
        ),
      ])
      .then(
        axios.spread(function (campaign_res) {
          var timeSeriesData = campaign_res.data;
          // var stats = keyword_res.data
          var populatedPerformanceOptions = that_ref.populateTimeSeriesData(
            performanceOptions,
            timeSeriesData,
            that_ref.state.rollup.rollup
          );
          // var populatedPieChartOptions = that_ref.populatePieChart(performanceOptionsPieChart, stats)
          that_ref.setState({
            timeSeriesData: timeSeriesData,
            perfOptions: populatedPerformanceOptions,
            // pieOptions: populatedPieChartOptions,
          });
        })
      );
  }

  componentDidUpdate() {
    var performanceOptions = {
      grid: {
        top: 60,
        bottom: 60,
        left: "2.5%",
        right: "2.5%",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#152748",
          },
        },
      },
      toolbox: {
        show: false,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: { zoom: "Zoom In", back: "Zoom Out" },
          },
          restore: { show: true, title: "Reset" },
        },
      },
      legend: {
        data: ["Impressions", "Clicks", "AdSales", "AdSpend", "ACOS"],
        selected: {
          Impressions: true,
          Clicks: false,
          AdSales: true,
          AdSpend: false,
          ACOS: true,
        },
      },

      xAxis: {
        type: "category",
        boundaryGap: true,
        data: [],
      },
      yAxis: [
        {
          show: false,
          type: "value",
          splitLine: {
            show: false,
          },
        },
        {
          show: false,
          type: "value",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Impressions",
          data: [],
          type: "bar",
          itemStyle: {
            color: "#a78dc2",
            opacity: 0.8,
          },
          yAxisIndex: 0,
        },
        {
          name: "Clicks",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#d5c1e0",
        },
        {
          name: "AdSales",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#c43066",
        },
        {
          name: "AdSpend",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#e172af",
        },
        {
          name: "ACOS",
          data: [],
          type: "line",
          yAxisIndex: 1,
          smooth: false,
          areaStyle: {
            opacity: 0.05,
          },
          color: "#563776",
        },
      ],
    };
    var that_ref = this;

    if (this.state.campaign_name !== this.props.campaign_name) {
      if (this.state.isLoading !== this.props.loading) {
        this.setState({ isLoading: true });
      }
      axios
        .all([
          axios.get(
            URL_PREFIX +
              "/api/trellistrak/?type=campaign_stats&campaign_name=" +
              encodeURIComponent(this.props.campaign_name)
          ),
        ])
        .then(
          axios.spread(function (campaign_res) {
            var timeSeriesData = campaign_res.data;
            // var stats = keyword_res.data
            var populatedPerformanceOptions = that_ref.populateTimeSeriesData(
              performanceOptions,
              timeSeriesData,
              that_ref.state.rollup.rollup
            );
            // var populatedPieChartOptions = that_ref.populatePieChart(performanceOptionsPieChart, stats)
            that_ref.setState({
              timeSeriesData: timeSeriesData,
              perfOptions: populatedPerformanceOptions,
              // pieOptions: populatedPieChartOptions,
              campaign_name: that_ref.props.campaign_name,
              isLoading: false,
            });
          })
        );
    }
    if (this.state.rollup !== this.state.selectedRollUp) {
      var populatedPerformanceOptions = this.populateTimeSeriesData(
        performanceOptions,
        this.state.timeSeriesData,
        this.state.selectedRollUp.rollup
      );
      this.setState({
        perfOptions: populatedPerformanceOptions,
        rollup: this.state.selectedRollUp,
      });
    }
  }

  handleDropDownSelection(item) {
    this.setState({ selectedRollUp: item });
  }

  render() {
    return (
      <div>
        <Row>
          {this.state.isLoading ? (
            <div className="wrap" style={{ padding: "10rem 0rem" }}>
              <img
                src={logo}
                alt="Loading..."
                className="rotate"
                width="80"
                height="auto"
              />
            </div>
          ) : (
            <>
              <Col md={12}>
                <div
                  style={{
                    marginTop: "5rem",
                    marginBottom: "2rem",
                    padding: "1rem 3rem",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    <NavDropdown
                      id="CampaignChartDropdownValue"
                      title={this.state.rollup.name}
                      style={{
                        margin: "1rem 0m",
                        color: "#403E3D",
                        textTransform: "uppercase",
                        fontSize: "14px",
                      }}
                    >
                      {timeSeriesFilter.map((item, index) => {
                        return (
                          <NavDropdown.Item
                            key={"dropdownValueKey" + index}
                            id={item.id}
                            onClick={(e) => this.handleDropDownSelection(item)}
                            style={{ textTransform: "none" }}
                          >
                            {item.name}
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                    <span
                      style={{
                        paddingLeft: "1rem",
                        textTransform: "uppercase",
                        fontSize: "14px",
                        color: "#403E3D",
                        fontWeight: "400",
                      }}
                    >
                      Campaign performance for{" "}
                      <span style={{ fontWeight: "700" }}>
                        {" "}
                        {this.props.campaign_name}
                      </span>{" "}
                    </span>
                  </div>
                  <hr />
                </div>
                <ReactEcharts option={this.state.perfOptions} />
              </Col>
              {/* <Col md={12}>
            <div style={{ marginTop: "0.65rem", padding: "1rem 3rem" }}>
              <div style={{ marginTop: "4.25rem", textAlign: "left", fontWeight: "400" }}>
              <span style={{ textTransform: "uppercase", fontSize: "15px", color: '#403E3D', fontWeight: 'bold' }}>Top Performing Keywords for <span style={{fontWeight: "400"}}> {this.props.campaign_name}</span> </span>
              </div>
            </div>
            <ReactEcharts option={this.state.pieOptions} />
          </Col> */}
            </>
          )}
        </Row>
      </div>
    );
  }
}

CampaignChart.propTypes = {
  auth: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(withRouter(CampaignChart));
