import useColumnSelector from "hooks/useColumnSelector";
import React, { useMemo } from "react";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import ReactTable from "react-table-6";
import { Link } from "react-router-dom";
import moment from "moment";
import { Col } from "react-bootstrap";
import ReactTablePagination from "../growth/ProductsCVTablePagination";

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const formatDate = (val) => moment(val).format("MMM Do");

const makeColumn = (data, marketPlace) => (header, key, type, options) => {
  const defaultAccessor = (p) => p[key];
  const accessor = options?.accessor ?? defaultAccessor;
  const defaultFormat = ({ value }) => value;
  const fmt =
    options?.formatter ??
    {
      currency: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      number: ({ value }) => formatNumber(value, {}, 1),
      percent: ({ value }) => formatPercent(value),
      date: ({ value }) => formatDate(value),
    }?.[type] ??
    defaultFormat;
  const parser =
    {
      currency: parseFormattedCurrency,
      number: parseFormattedNumber,
      percent: parseFormattedPercentage,
    }?.[type] ?? defaultFormat;
  return {
    Header: header,
    id: key,
    accessor,
    width: options?.width ?? 100,
    className: options?.className ?? "text-center",
    headerClassName: options?.className ?? "text-center",
    Cell: fmt,
    filterMethod: (filter, row) => {
      const value = accessor(row._original);
      return filterRanges(filter, value, parser, marketPlace.marketPlace);
    },
    Filter: ({ filter, onChange }) => {
      return generateFilterMarkup(filter, onChange, key, "range", type, {
        ranges: generateRanges(
          data ?? [],
          5,
          key,
          type,
          marketPlace.marketPlace
        ),
        marketPlace: marketPlace.marketPlace,
      });
    },
  };
};

const COLUMNS = [
  col("Title", "text", "title", {
    className: "text-start font-weight-bold",
    width: 200,
    formatter: ({ value, row }) => (
      <Link
        key={row._original.id}
        to={`/user/merchandising/planning/plans/${row._original.id}`}
        className="text-decoration-none"
      >
        {value}
      </Link>
    ),
  }),
  col("Start", "date", "execution_start", {}),
  col("End", "date", "execution_end", {}),
  col("Sales", "currency", "sales"),
  col("Cost", "currency", "cost"),
  col("Units", "number", "units"),
  col("ROMS", "number", "roms"),
];

const PlansTable = ({ isActual, plans, marketPlace }) => {
  const data = useMemo(() => {
    if (!plans) return [];
    return plans.map((s) =>
      isActual
        ? {
            ...s,
            sales: s.stats.actual_total_sales ?? 0,
            cost: s.stats.actual_total_cost ?? 0,
            units: s.stats.actual_total_units ?? 0,
            roms: s.stats.roms ?? 0,
            promo_sales: s.stats.actual_promo_sales ?? 0,
            promo_cost: s.stats.actual_promo_cost ?? 0,
            promo_units: s.stats.actual_promo_units ?? 0,
            rops: s.stats.rops ?? 0,
            ad_sales: s.stats.actual_ad_sales ?? 0,
            ad_cost: s.stats.actual_ad_spend ?? 0,
            ad_units: s.stats.actual_ad_units ?? 0,
            roas: s.stats.roas ?? 0,
            margin: s.stats.actual_margin ?? 0,
          }
        : {
            ...s,
            title: s.title,
            sales: s.stats.target_total_sales ?? 0,
            cost: s.stats.target_total_cost ?? 0,
            units: s.stats.target_total_units ?? 0,
            roms: s.stats.target_roms ?? 0,
            promo_sales: s.stats.target_promo_sales ?? 0,
            promo_cost: s.stats.target_promo_cost ?? 0,
            promo_units: s.stats.target_promo_units ?? 0,
            rops: s.stats.rops ?? 0,
            ad_sales: s.stats.target_ad_sales ?? 0,
            ad_cost: s.stats.target_ad_spend ?? 0,
            ad_units: s.stats.target_ad_units ?? 0,
            roas: s.stats.target_roas ?? 0,
            margin: s.stats.target_margin ?? 0,
          }
    );
  }, [plans, isActual]);
  const createColumn = makeColumn(data, marketPlace);
  const [columns] = useColumnSelector(
    () => COLUMNS.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [COLUMNS, plans]
  );
  const columnOrder = columns.map((col) => col.id);
  return (
    <Col xs={12}>
      <div className="card" style={{ padding: "2rem" }}>
        <ReactTable
          PaginationComponent={ReactTablePagination}
          getPaginationProps={() => ({
            hideSearch: true,
            table: "plans",
            hideFilters: true,
            style: { marginBottom: "2rem" },
            disableSearchFocus: true,
          })}
          showPaginationTop
          showPaginationBottom={false}
          columns={columns}
          data={data}
          defaultPageSize={5}
          className="-highlight"
          filterable={false}
          state={columnOrder}
        />
      </div>
    </Col>
  );
};

export default PlansTable;
