import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, Table, Form } from "react-bootstrap";
import { useFetch } from "hooks/api";
import { LogoutButton } from "components/core/basic/Button.jsx";
import AdSelection from "modules/keyword_explorer/components/AdSelection";
import { AD_TYPES } from "components/core/blocks/AdPlan/constants";
import Loading from "components/core/blocks/Loading";
import { formatCurrency } from "utils/formatNumber";
import api from "utils/api";
import { Link } from "react-router-dom";
import { IoIosAlert } from "react-icons/io";
import { FaCircle, FaRegCircle } from "react-icons/fa6";
import { trellisPalette } from "components/custom/analytics/palettes";
import ReactTooltip from "react-tooltip";
import Switch from "react-bootstrap-switch";
import { SEGMENT_TEXT } from "modules/pricingv2/utils";

const AdvertisingSection = ({
  pricingPlan,
  marketPlace,
  start,
  end,
  group,
  pricingPlanReport,
}) => {
  const [showAdSelection, setShowAdSelection] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [activePlans, setActivePlans] = useState({});
  const [actions, setActions] = useState({});
  const [showSave, setShowSave] = useState(false);

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: "day",
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: start.format("YYYY-MM-DD"),
    pre_end_date: end.format("YYYY-MM-DD"),
    empty: true,
    group_by: "product_id",
    plan_id: pricingPlan.id,
    include: "pricing",
  };

  const { data, isLoading } = useFetch(
    ["product_report", marketPlace.marketPlace, refresh],
    "/pricing/advertisements",
    {
      pricing_plan_id: pricingPlan.id,
    },
    {
      select: (d) => d.data?.results,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (d) => {},
    }
  );

  const { data: productData, isLoading: productIsLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      start,
      end,
      pricingPlan.id,
      "day",
    ],
    "/api/data_report/product_report",
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (d) => {},
    }
  );

  const saveAction = async () => {
    await api.post("/pricing/advertisement/update/", {
      pricng_plan_id: pricingPlan.id,
      actions: actions,
      paused: activePlans,
    });

    setShowSave(false);
  };

  const asinList = useMemo(() => {
    if (!productData || productIsLoading) {
      return [];
    }

    const asins = [];
    for (let i = 0; i < productData.products.length; i++) {
      asins.push(productData.products[i].asin);
    }
    return asins;
  }, [productData, productIsLoading]);

  const setSelectedMediaPlan = async (mediaPlanInfo) => {
    const payload = {
      pricing_plan: pricingPlan.id,
      advertisement: mediaPlanInfo.id,
    };
    await api.post("/pricing/advertisements/", payload);
    setRefresh(refresh + 1);
  };

  const bucketStatus = useMemo(() => {
    return pricingPlanReport?.plan_id[0]?.bucket;
  }, [pricingPlanReport]);

  const AdTypeLabel = ({ adType }) => {
    const keys = Object.keys(AD_TYPES);
    for (let i = 0; i < keys.length; i++) {
      const at = AD_TYPES[keys[i]];
      if (at.value === adType) {
        return <span>{at.label}</span>;
      }
    }

    return <span>n/a</span>;
  };

  const SegmentCell = ({ segment, border, segmentKey }) => {
    const actualSegment = SEGMENT_TEXT[bucketStatus];
    let color = "#777";

    if (segment === "Overstock") {
      if (pricingPlan?.days_of_supply >= 75) {
        color = trellisPalette[2];
      }
    } else if (segment === "Low Inventory") {
      if (pricingPlan?.days_of_supply <= 30) {
        color = trellisPalette[2];
      } else if (
        pricingPlan?.target_supply &&
        pricingPlan?.target_supply <= pricingPlan?.days_of_supply
      ) {
        color = trellisPalette[2];
      }
    } else {
      if (actualSegment === segment) {
        color = trellisPalette[2];
      }
    }

    return (
      <>
        <td>
          <span className="pe-2 ps-2">
            {color !== "#777" ? (
              <FaCircle style={{ color: color }} />
            ) : (
              <FaRegCircle style={{ color: color }} />
            )}
          </span>
          {segment}
        </td>
        <td>
          <Form.Select
            size="lg"
            onChange={(e) => {
              const act = { ...actions };
              act[segmentKey] = e.target.value;
              setActions(act);
              setShowSave(true);
            }}
          >
            <option
              value="no_action"
              selected={actions[segmentKey] === "no_action"}
            >
              Maintain Plan Budget
            </option>
            <option
              value="increase"
              selected={actions[segmentKey] === "increase"}
            >
              Increase Budget
            </option>
            <option
              value="decrease"
              selected={actions[segmentKey] === "decrease"}
            >
              Decrease Budget
            </option>
            <option value="pause" selected={actions[segmentKey] === "pause"}>
              Pause Media Plan
            </option>
          </Form.Select>
        </td>
        <td style={{ borderRight: border ? "1px solid #ccc" : null }}>
          &nbsp;
        </td>
      </>
    );
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const mediaPlans = {};
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      mediaPlans[d.id] = d.is_paused;
    }

    const actions = {
      profit_potential: "no_action",
      sales_pressure: "no_action",
      right_price: "no_action",
      out_of_stock: "no_action",
      low_inventory: "no_action",
      overstock: "no_action",
    };

    if (data.length > 0) {
      const keys = Object.keys(data[0].actions);
      for (let i = 0; i < keys.length; i++) {
        actions[keys[i]] = data[0].actions[keys[i]];
      }
    }

    setActions(actions);
    setActivePlans(mediaPlans);
  }, [data]);

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      <Row>
        <Col xs={12} className="text-end">
          {showSave && (
            <span className="pe-4">
              <LogoutButton
                key="SaveBtn"
                title={`Save`}
                nowrap
                onClick={() => saveAction()}
              />
            </span>
          )}
          <LogoutButton
            key="AddMediaPlanBtn"
            title={`Add Advertisement`}
            nowrap
            onClick={() => setShowAdSelection(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {isLoading ||
            (data?.length === 0 && (
              <div className="text-center my-5 fs-4">
                No Media Plans Have Been Selected
              </div>
            ))}

          {!isLoading && data?.length > 0 && (
            <Row>
              <Col xs={12}>
                <span className="fs-3 fw-600">Selected Media Plans</span>
              </Col>
              <Col xs={12}>
                <div
                  style={{
                    padding: "2rem",
                    border: "1px solid rgb(238, 238, 238)",
                    borderRadius: "6px",
                    backgroundColor: "rgb(248, 248, 248)",
                    marginTop: "10px",
                  }}
                >
                  Selected media plans are now integrated with your pricing
                  strategy, enabling you to monitor their impact on pricing
                  dynamically. Budget adjustments will occur automatically in
                  response to product performance and inventory levels.
                </div>

                <Table>
                  <thead>
                    <tr>
                      <th className="text-start">Media Plan</th>
                      <th>&nbsp;</th>
                      <th>Plan Type</th>
                      <th># Products</th>
                      <th>Monthly Budget</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((mediaPlan, idx) => (
                      <tr key={idx}>
                        <td className="text-start">
                          <Link
                            to={`/user/advertising/dashboard/ads/${
                              mediaPlan.advertisement.id
                            }?starrt=${start.format(
                              "YYYY-MM-DD"
                            )}&end=${end.format("YYYY-MM-DD")}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {mediaPlan.advertisement.name}
                          </Link>
                        </td>
                        <td>
                          <Switch
                            onText={``}
                            offText={``}
                            value={!activePlans[mediaPlan.id]}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              const plans = { ...activePlans };
                              plans[mediaPlan.id] = !plans[mediaPlan.id];
                              setActivePlans(plans);
                              setShowSave(true);
                            }}
                          />
                        </td>
                        <td className="text-center">
                          <AdTypeLabel
                            adType={mediaPlan.advertisement.ad_type}
                          />
                        </td>
                        <td className="text-center">
                          {mediaPlan?.advertisement?.products?.length}

                          {mediaPlan?.advertisement?.products?.length > 1 && (
                            <>
                              <a
                                style={{ color: trellisPalette[17] }}
                                data-for={`ad-${mediaPlan.advertisement.id}`}
                                data-tip={
                                  "This advertisement features multiple products. Please be aware that any adjustments will alter the overall budget for the ad."
                                }
                                href="#"
                              >
                                <span
                                  className="ps-3 fs-3"
                                  style={{ color: trellisPalette[17] }}
                                >
                                  <IoIosAlert />
                                </span>
                              </a>
                              <ReactTooltip
                                id={`ad-${mediaPlan.advertisement.id}`}
                                place={"top"}
                                type="info"
                                effect="solid"
                                textColor="#fff"
                                backgroundColor="#2E0054"
                              ></ReactTooltip>
                            </>
                          )}
                        </td>
                        <td className="text-center">
                          {formatCurrency(
                            mediaPlan.advertisement.monthly_budget,
                            marketPlace.marketPlace,
                            true,
                            false,
                            false
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} className="pt-5">
                <span className="fs-3 fw-600">Actions</span>
              </Col>
              <Col xs={12}>
                <Table>
                  <thead>
                    <tr>
                      <th className="text-start">Segment</th>
                      <th className="text-start">Action</th>
                      <th>&nbsp;</th>
                      <th className="text-start">Inventory</th>
                      <th className="text-start">Action</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <SegmentCell
                        segment="Right Price"
                        border
                        segmentKey="right_price"
                      />
                      <SegmentCell
                        segment="Out of Stock"
                        segmentKey="out_of_stock"
                      />
                    </tr>
                    <tr>
                      <SegmentCell
                        segment="Sales Pressure"
                        border
                        segmentKey="sales_pressure"
                      />
                      <SegmentCell
                        segment="Low Inventory"
                        segmentKey="low_inventory"
                      />
                    </tr>
                    <tr>
                      <SegmentCell
                        segment="Profit Potential"
                        border
                        segmentKey="profit_potential"
                      />
                      <SegmentCell segment="Overstock" segmentKey="overstock" />
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <AdSelection
        asinList={asinList}
        ad={null}
        showAdSelection={showAdSelection}
        setShowAdSelection={setShowAdSelection}
        marketplace={marketPlace.marketPlace}
        setMediaPlan={setSelectedMediaPlan}
      />
    </>
  );
};

export default AdvertisingSection;
