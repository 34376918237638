import React, { useState } from "react";
import { Row, Col, Container as Grid, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Breadcrumb from "components/core/blocks/Breadcrumb";
import ConfirmationSection from "modules/pricingv2/cards/Confirmation";
import B2BFeed from "./cards/B2BFeed";
import moment from "moment";

const useStyles = (theme) => ({});

const PriceChanges = ({ marketPlace, user }) => {
  const [tab, setTab] = useState("price_increases");

  const breadCrumbItems = [
    { name: "Price Log", href: "/user/_pricing/changes" },
  ];

  const now = moment();

  return (
    <>
      <Row style={{ marginBottom: "1rem" }}>
        <Col xs={12}>
          <Breadcrumb items={breadCrumbItems} />
        </Col>
      </Row>
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid style={{ minHeight: "calc(100vh - 150px)" }}>
          <Nav
            variant="underline"
            onSelect={(k) => {
              return setTab(k);
            }}
            activeKey={tab}
          >
            <Nav.Item>
              <Nav.Link eventKey="price_increases">
                <span className="fs-4">Price Uplift</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="price_decreases">
                <span className="fs-4">Price Reduction</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="b2b_changes">
                <span className="fs-4">B2B Price Changes</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {tab === "price_increases" && (
            <Row>
              <Col xs={12} className="pt-3">
                <ConfirmationSection
                  limit={100}
                  marketPlace={marketPlace}
                  date={now}
                  user={user}
                  priceDiff={"INCREASE"}
                  usePricingPlan={true}
                />
              </Col>
            </Row>
          )}

          {tab === "price_decreases" && (
            <Row>
              <Col xs={12} className="pt-3">
                <ConfirmationSection
                  limit={100}
                  marketPlace={marketPlace}
                  date={now}
                  user={user}
                  priceDiff={"DECREASE"}
                  usePricingPlan={true}
                />
              </Col>
            </Row>
          )}

          {tab === "b2b_changes" && (
            <Row>
              <Col xs={12} className="pt-3">
                <B2BFeed marketPlace={marketPlace} />
              </Col>
            </Row>
          )}
        </Grid>
      </div>
    </>
  );
};

PriceChanges.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(PriceChanges))
);
