import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Col, Container as Grid, Row, Card } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { useFetch } from "hooks/api";
import useInterval from "hooks/useInterval";
import moment from "moment";
import {
  formatCurrency,
  formatPercent,
  formatNumber,
} from "utils/formatNumber";
import Metric from "components/core/blocks/SummaryMetric";
import { ProductImageURL } from "components/custom/category/listings/ProductImage";
import ExternalProductLink from "components/custom/merchandise/ExternalProductLink";
import { AD_TYPES } from "components/core/blocks/AdPlan/constants";
import "assets/css/live.css";

const LiveDashboard = ({ user, marketPlace, channel }) => {
  let [count, setCount] = useState(0);
  let [refreshCount, setRefreshCount] = useState(0);
  const [transition, setTransition] = useState("");

  const dt = moment().format("YYYY-MM-DD");
  const yt = moment().subtract(1, "days").format("YYYY-MM-DD");

  const currencyFormatter = (value) => {
    return formatCurrency(value, marketPlace.marketPlace);
  };

  const {
    isSuccess: adSucceess,
    data: adData,
    isLoading: adIsLoading,
  } = useFetch(
    ["ad_report", marketPlace.marketPlace, count],
    `/merchandise/live_advertisements`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const {
    isSuccess: kwSucceess,
    data: kwData,
    isLoading: kwIsLoading,
  } = useFetch(
    ["keywore_report", marketPlace.marketPlace, count],
    `/merchandise/live_keywords`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data: adSpendData, isLoading: adSpendIsLoading } = useFetch(
    ["ad_spend_report", marketPlace.marketPlace, count],
    `/merchandise/live_ad_spend_sales`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { isSuccess, data, isLoading } = useFetch(
    ["category_report", marketPlace.marketPlace, count],
    `/merchandise/live_orders`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  let filters = {
    start_date: dt,
    end_date: dt,
    pre_start_date: yt,
    pre_end_date: yt,
    empty: true,
  };

  const { data: orgData } = useFetch(
    ["org_report", marketPlace.marketPlace, channel, count],
    `/api/data_report/category_report`,
    {
      mp: marketPlace.marketPlace,
      channel: channel,
      ...filters,
    },
    {
      select: (d) => d.data,
      default: [],
    }
  );

  const { acos, tacos } = useMemo(() => {
    if (!orgData || !adSpendData) {
      return {
        acos: 0,
        tacos: 0,
      };
    }

    let acos = 0;
    let tacos = 0;

    if (adSpendData?.attributed_sales_7d > 0) {
      acos = adSpendData?.cost / adSpendData?.attributed_sales_7d;

      if (orgData?.total?.total_sales > 0) {
        tacos = adSpendData?.cost / orgData?.total?.total_sales;
      }
    }

    return {
      acos,
      tacos,
    };
  }, [orgData, adSpendData]);

  useInterval(() => {
    // Your custom logic here
    setCount(count + 1);
  }, 60000);

  useInterval(() => {
    if (!isLoading && isSuccess) {
      setRefreshCount(refreshCount + 1);
    }
  }, 1000);

  useEffect(() => {
    setTransition("fade-out");
    setTimeout(() => {
      setTransition("fade-in");
    }, 500); // 500ms for fade-out animation duration
    setRefreshCount(0);
  }, [data]);

  if (user?.organization_type !== "seller") {
    return <Redirect to={`/user/merchandising/dashboard`} />;
  }

  if (marketPlace.marketPlace !== "USA") {
    return <Redirect to={`/user/merchandising/dashboard`} />;
  }

  if (channel !== "amazon") {
    return <Redirect to={`/user/merchandising/dashboard`} />;
  }

  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col xs={12} className="px-5 py-5">
          <Row>
            <Col xs={6} xl={2}>
              <Card className="mr-4 p-4 mb-3">
                <Metric
                  size={"small"}
                  id={"numUnits"}
                  toolTip={"The number of units that have been sold today"}
                  name={"Units Sold"}
                  showTarget={true}
                  value={
                    <span className={transition}>
                      {data?.quantity_ordered
                        ? formatNumber(data?.quantity_ordered)
                        : "-"}
                    </span>
                  }
                  target={
                    <span>
                      {orgData?.total?.total_units_prev
                        ? formatNumber(orgData?.total?.total_units_prev)
                        : "-"}
                    </span>
                  }
                  targetLabel="prev. day"
                />
              </Card>
            </Col>
            <Col xs={6} xl={2}>
              <Card className="mr-4 p-4 mb-3">
                <Metric
                  size={"small"}
                  id={"itemValue"}
                  toolTip={"The total sales value of the units sold today"}
                  name={"Total Sales"}
                  showTarget={true}
                  value={
                    <span className={transition}>
                      {data?.order_item_value
                        ? currencyFormatter(data?.order_item_value)
                        : "-"}
                    </span>
                  }
                  target={
                    <span>
                      {orgData?.total?.total_units_prev
                        ? currencyFormatter(orgData?.total?.total_sales_prev)
                        : "-"}
                    </span>
                  }
                  targetLabel="prev. day"
                />
              </Card>
            </Col>

            <Col xs={6} xl={2}>
              <Card className="mr-4 p-4 mb-3">
                <Metric
                  size={"small"}
                  id={"adSpend"}
                  toolTip={"The total advertising spend value for today"}
                  name={"Ad Spend"}
                  showTarget={true}
                  value={
                    <span>
                      {!adSpendIsLoading && adSpendData?.cost
                        ? currencyFormatter(adSpendData?.cost)
                        : "-"}
                    </span>
                  }
                  target={
                    <span>
                      {orgData?.total?.cost_prev
                        ? currencyFormatter(orgData?.total?.cost_prev)
                        : "-"}
                    </span>
                  }
                  targetLabel="prev. day"
                />
              </Card>
            </Col>

            <Col xs={6} xl={2}>
              <Card className="mr-4 p-4 mb-3">
                <Metric
                  size={"small"}
                  id={"adSales"}
                  toolTip={
                    "The total advertising sales value of the units sold today"
                  }
                  name={"Ad Sales"}
                  showTarget={true}
                  value={
                    <span>
                      {!adSpendIsLoading && adSpendData?.attributed_sales_7d
                        ? currencyFormatter(adSpendData?.attributed_sales_7d)
                        : "-"}
                    </span>
                  }
                  target={
                    <span>
                      {orgData?.total?.ad_sales_prev
                        ? currencyFormatter(orgData?.total?.ad_sales_prev)
                        : "-"}
                    </span>
                  }
                  targetLabel="prev. day"
                />
              </Card>
            </Col>

            <Col xs={6} xl={2}>
              <Card className="mr-4 p-4 mb-3">
                <Metric
                  size={"small"}
                  id={"acos"}
                  toolTip={
                    "The advertising sales value of the units sold today"
                  }
                  name={"ACOS"}
                  showTarget={true}
                  value={<span>{acos ? formatPercent(acos) : "-"}</span>}
                  target={
                    <span>
                      {orgData?.total?.acos_prev
                        ? formatPercent(orgData?.total?.acos_prev)
                        : "-"}
                    </span>
                  }
                  targetLabel="prev. day"
                />
              </Card>
            </Col>

            <Col xs={6} xl={2}>
              <Card className="mr-4 p-4 mb-3">
                <Metric
                  size={"small"}
                  id={"tacos"}
                  toolTip={
                    "The total advertising sales value of the units sold today"
                  }
                  name={"TACOS"}
                  showTarget={true}
                  value={<span>{tacos ? formatPercent(tacos) : "-"}</span>}
                  target={
                    <span>
                      {orgData?.total?.total_acos_prev
                        ? formatPercent(orgData?.total?.total_acos_prev)
                        : "-"}
                    </span>
                  }
                  targetLabel="prev. day"
                />
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12} xxl={6} className="pb-3">
              <Card className="mr-4 p-4 mb-3" style={{ height: "100%" }}>
                <Card.Title>
                  <h6
                    className=" fs-standard text-center"
                    style={{ fontWeight: 600 }}
                  >
                    Last 5 Product Sold
                  </h6>
                </Card.Title>

                {isLoading && (
                  <div className="text-center">
                    <i className="fa fa-spinner fa-spin fa-2x"></i>
                  </div>
                )}

                {!isLoading && (
                  <div className={transition}>
                    {data?.products.map((product, i) => (
                      <div key={i}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "3rem",
                          }}
                          className="py-3"
                        >
                          <div style={{ float: "left" }}>
                            <ProductImageURL
                              url={product.product_image_url}
                              width="50px"
                              height="50px"
                            />
                          </div>{" "}
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              height: "49px",
                              marginLeft: "10px",
                            }}
                          >
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "100%",
                                marginRight: "25px",
                              }}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  fontWeight: 500,
                                  fontSize: "1.5rem",
                                  cursor: "pointer",
                                }}
                                to={`/user/merchandising/product/${product.id}`}
                                target="_blank"
                              >
                                {product.product_title}
                              </Link>
                            </div>
                            <Row>
                              <Col>
                                <small>
                                  {product.sku} - {product.asin}{" "}
                                  <ExternalProductLink
                                    asin={product.asin.toUpperCase()}
                                  />
                                </small>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            </Col>
            <Col xs={12} xxl={6} className="pb-3">
              <Card className="mr-4 p-4 mb-3" style={{ height: "100%" }}>
                <Card.Title>
                  <h6
                    className=" fs-standard text-center"
                    style={{ fontWeight: 600 }}
                  >
                    Top 5 Products Sold Today
                  </h6>
                </Card.Title>

                {isLoading && (
                  <div className="text-center">
                    <i className="fa fa-spinner fa-spin fa-2x"></i>
                  </div>
                )}

                {!isLoading && (
                  <div className={transition}>
                    {data?.top_products.map((product, i) => (
                      <div key={i}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "3rem",
                          }}
                          className="py-3"
                        >
                          <div style={{ float: "left" }}>
                            <ProductImageURL
                              url={product.product_image_url}
                              width="50px"
                              height="50px"
                            />
                          </div>{" "}
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              height: "49px",
                              marginLeft: "10px",
                            }}
                          >
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "100%",
                                marginRight: "25px",
                              }}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  fontWeight: 500,
                                  fontSize: "1.5rem",
                                  cursor: "pointer",
                                }}
                                to={`/user/merchandising/product/${product.id}`}
                                target="_blank"
                              >
                                {product.product_title}
                              </Link>
                            </div>
                            <Row>
                              <Col xs={12}>
                                <small>
                                  {product.sku} - {product.asin}{" "}
                                  <ExternalProductLink
                                    asin={product.asin.toUpperCase()}
                                  />
                                  -{"   "}
                                  Units Sold: {data?.top_units_sold[product.id]}
                                </small>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            </Col>
            <Col xs={12} xxl={6}>
              <Card className="mr-4 p-4 mb-3" style={{ height: "100%" }}>
                <Card.Title>
                  <h6
                    className=" fs-standard text-center"
                    style={{ fontWeight: 600 }}
                  >
                    Popular Keywords
                  </h6>
                </Card.Title>
                <Row>
                  <Col xs={12}>
                    {kwIsLoading && (
                      <div className="text-center">
                        <i className="fa fa-spinner fa-spin fa-2x"></i>
                      </div>
                    )}

                    {kwSucceess &&
                      !kwIsLoading &&
                      kwData.slice(0, 5).map((kw) => (
                        <div key={kw.keyword_id} className={transition}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "3rem",
                            }}
                            className="py-3"
                          >
                            <div style={{ float: "left" }}>&nbsp;</div>
                            <div style={{ float: "left", width: "60%" }}>
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "100%",
                                  marginRight: "25px",
                                }}
                              >
                                <span className="fs-4">{kw?.keyword_text}</span>
                              </div>
                              <div className="pt-1" style={{ float: "none" }}>
                                <small
                                  className="text-nowrap pr-3"
                                  style={{
                                    fontWeight: "light",
                                    color: "#666",
                                    fontSize: "1rem",
                                  }}
                                >
                                  <i>{kw.match_type}</i>
                                </small>
                              </div>
                            </div>
                            <div
                              style={{ float: "right", width: "40%" }}
                              className="fs-4"
                            >
                              <div style={{ float: "left" }}>
                                <span className="pr-1">
                                  <small>Spend:</small>
                                </span>
                                {currencyFormatter(kw?.cost)}
                              </div>
                              <div style={{ float: "right" }} className="ps-3">
                                <span className="pr-1">
                                  <small>Sales:</small>
                                </span>
                                {currencyFormatter(kw?.attributed_sales_7d)}
                              </div>

                              <div
                                style={{
                                  float: "right",
                                  width: "100%",
                                  textAlign: "right",
                                }}
                                className="fs-5"
                              >
                                <span className="pr-1">
                                  <small>ACOS:</small>
                                </span>
                                {kw?.attributed_sales_7d > 0
                                  ? formatPercent(
                                      kw?.cost / kw?.attributed_sales_7d
                                    )
                                  : formatPercent(0)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12} xxl={6}>
              <Card className="mr-4 p-4 mb-3" style={{ height: "100%" }}>
                <Card.Title>
                  <h6
                    className=" fs-standard text-center"
                    style={{ fontWeight: 600 }}
                  >
                    Top Media Plans
                  </h6>
                </Card.Title>
                <Row>
                  <Col xs={12}>
                    {adIsLoading && (
                      <div className="text-center">
                        <i className="fa fa-spinner fa-spin fa-2x"></i>
                      </div>
                    )}
                    {adSucceess &&
                      !adIsLoading &&
                      adData.map((ad) => (
                        <div key={ad.ad_id} className={transition}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "3rem",
                            }}
                            className="py-3"
                          >
                            <div style={{ float: "left" }}>&nbsp;</div>
                            <div style={{ float: "left", width: "60%" }}>
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "100%",
                                  marginRight: "25px",
                                }}
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    fontWeight: 500,
                                    fontSize: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                  to={`/user/advertising/dashboard/ads/${ad?.ad_id}?tab=details`}
                                  target="_blank"
                                >
                                  {ad?.ad_name}
                                </Link>
                              </div>
                              <div className="pt-1" style={{ float: "none" }}>
                                <small
                                  className="text-nowrap pr-3"
                                  style={{
                                    fontWeight: "light",
                                    color: "#666",
                                    fontSize: "1rem",
                                  }}
                                >
                                  <i>
                                    {
                                      Object.values(AD_TYPES).find(
                                        (adTypeId) =>
                                          ad?.ad_type === adTypeId.value
                                      )?.label
                                    }
                                  </i>
                                </small>
                              </div>
                            </div>
                            <div
                              style={{ float: "right", width: "40%" }}
                              className="fs-4"
                            >
                              <div style={{ float: "left" }}>
                                <span className="pr-1">
                                  <small>Spend:</small>
                                </span>
                                {currencyFormatter(ad?.spend)}
                              </div>
                              <div style={{ float: "right" }} className="ps-3">
                                <span className="pr-1">
                                  <small>Sales:</small>
                                </span>
                                {currencyFormatter(ad?.sales)}
                              </div>

                              <div
                                style={{
                                  float: "right",
                                  width: "100%",
                                  textAlign: "right",
                                }}
                                className="fs-5"
                              >
                                <span className="pr-1">
                                  <small>ACOS:</small>
                                </span>
                                {ad?.sales > 0
                                  ? formatPercent(ad?.spend / ad?.sales)
                                  : formatPercent(0)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={12} className="px-5 py-2 text-center">
          <span className="fs-small">
            Refreshing in {60 - (refreshCount % 60)} seconds
          </span>
        </Col>
      </Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  channel: state.channel.currentChannel,
});

export default connect(mapStateToProps)(LiveDashboard);
