import React from "react";
import getMetricSpec from "views/merchandise/metrics";
import { getTrendColor } from "./utils";

const StatTile = ({
  label,
  metricId,
  compareMetricId,
  yoyCompareMetricId,
  data,
  showPreviousPeriod = true,
  showYoy = true,
  firstInGroup = false,
  marketPlace,
  overrideValue = null,
}) => {
  const metricSpec = getMetricSpec(marketPlace, metricId);
  const prevPeriodMetricSpec = showPreviousPeriod
    ? getMetricSpec(marketPlace ?? "USA", compareMetricId)
    : null;

  const value = overrideValue
    ? overrideValue
    : typeof metricSpec.accessor === "function"
    ? metricSpec.accessor(data)
    : null;
  const prevPeriodValue = !showPreviousPeriod
    ? null
    : typeof prevPeriodMetricSpec.accessor === "function"
    ? prevPeriodMetricSpec.accessor(data)
    : null;
  const yoyCompareValue = showYoy ? data[yoyCompareMetricId] : null;

  return (
    <div className="text-center pt-5">
      <div className={`box-stats-title`}>{label}</div>
      <div className={`box-stats-label`}>
        {overrideValue
          ? overrideValue
          : metricSpec.formatter && typeof metricSpec.formatter === "function"
          ? metricSpec.formatter(value)
          : value}
      </div>
      {showPreviousPeriod && (
        <div
          className={`box-stats-compare-label`}
          style={{
            color: getTrendColor(prevPeriodValue, yoyCompareMetricId),
          }}
        >
          {firstInGroup && (
            <span className="metric-compare-label" style={{ color: "#152748" }}>
              vs. Previous Period
            </span>
          )}
          {prevPeriodMetricSpec.formatter &&
          typeof prevPeriodMetricSpec.formatter === "function"
            ? prevPeriodMetricSpec.formatter(prevPeriodValue)
            : prevPeriodValue}
        </div>
      )}
      {showYoy && (
        <div
          className={`box-stats-compare-label`}
          style={{
            color: getTrendColor(yoyCompareValue, metricId),
          }}
        >
          {firstInGroup && (
            <span className="metric-compare-label" style={{ color: "#152748" }}>
              Year Over Year
            </span>
          )}
          {prevPeriodMetricSpec.formatter &&
          typeof prevPeriodMetricSpec.formatter === "function"
            ? prevPeriodMetricSpec.formatter(yoyCompareValue)
            : yoyCompareValue}
        </div>
      )}
    </div>
  );
};

export default StatTile;
