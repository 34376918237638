import React, { useState } from "react";
import { Col, Row, Nav } from "react-bootstrap";
import AdPlanPanel from "components/core/blocks/AdPlan/AdPlanPanel";
import AdPlanNotificationMenu from "components/core/blocks/AdPlan/AdPlanNotificationMenu";
import ScheduledBudgetSummary from "./ScheduledBudgetSummary";
import Seasonality from "./Seasonality";
import AdPlanServingHoursMenu from "components/core/blocks/AdPlan/AdPlanServingHoursMenu";
import InsightsTable from "./InsightsTable";
import DayPartingChart from "./DayPartingChart";
import ChangeLog from "./ChangeLog";

const SettingsSection = ({
  sections,
  categoryId,
  marketPlace,
  adPlanData,
  keywordData,
  targetData,
  adPlanParams,
  channel,
  user,
  categoryDetails,
  preferences,
  modules,
}) => {
  const [settingsSectionTab, setSettingsSectionTab] = useState("mediaPlan");

  const renderSettingsContent = (tab) => {
    switch (tab) {
      case "mediaPlan":
        let categories = [
          {
            label: categoryDetails?.leaf_category_name,
            value: categoryDetails?.id,
          },
        ];
        return (
          <Row>
            <Col xs={12} lg={11}>
              <AdPlanPanel
                review={true}
                adId={adPlanData?.id}
                categoryId={adPlanData?.category}
                categories={categories}
                roas={preferences.acos_or_roas === "roas" ? true : false}
              />
            </Col>
          </Row>
        );

      case "seasonality":
        return (
          <Seasonality
            marketPlace={marketPlace.marketPlace}
            adId={adPlanData?.id}
            channel={channel}
            adPlanData={adPlanData}
            adPlanParams={adPlanParams}
            categoryId={categoryId}
          />
        );
      case "scheduledChanges":
        return (
          <ScheduledBudgetSummary
            state={adPlanParams}
            adId={adPlanParams?.id}
            marketPlace={marketPlace}
            channel={channel}
            jumpToTab={(value) => setSettingsSectionTab(value)}
          />
        );
      case "servingHours":
        return (
          <AdPlanServingHoursMenu
            adId={adPlanData?.id}
            marketPlace={marketPlace}
            organizationType={user.organization_type}
            adType={adPlanData?.ad_type}
            adPlanData={adPlanData}
          />
        );
      case "day_parting":
        return (
          <DayPartingChart
            advertisement={adPlanData}
            categoryId={adPlanData?.category}
            adPlanParams={adPlanParams}
          />
        );
      case "insights":
        return (
          <InsightsTable
            marketPlace={marketPlace.marketPlace}
            adId={adPlanData?.id}
            channel={channel}
            adPlanData={adPlanData}
            keywordData={keywordData?.keywords}
            targetData={targetData?.keywords}
            modules={modules}
          />
        );
      case "notifications":
        return (
          <AdPlanNotificationMenu
            adId={adPlanData?.id}
            marketPlace={marketPlace}
            organizationType={user.organization_type}
            onCancel={() => {
              setSettingsSectionTab("mediaPlan");
            }}
          />
        );
      case "changeLog":
        return (
          <ChangeLog
            annotationEntityId={parseInt(adPlanParams?.id)}
            annotationEntityType={"advertisement"}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Row className="fs-standard">
      <Col xs={12} md={2} style={{ borderRight: "1px solid #ccc" }}>
        <div className="pt-2"></div>
        <Nav
          variant="underline"
          onSelect={(tabId) => {
            return setSettingsSectionTab(tabId);
          }}
          activeKey={settingsSectionTab}
          className="flex-column text-xs-center text-md-start"
        >
          {sections?.map((section) => {
            const { key, label } = section;
            return (
              <Nav.Link
                eventKey={key}
                key={key}
                className="text-xs-center text-md-start py-2"
              >
                {label}
              </Nav.Link>
            );
          })}
        </Nav>
      </Col>
      <Col xs={12} md={10} className="p-3">
        {renderSettingsContent(settingsSectionTab)}
      </Col>
    </Row>
  );
};

export default SettingsSection;
