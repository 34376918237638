import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { getURLPrefix } from "utils/getUrlPrefix";
import { Link } from "react-router-dom";
import Loading from "components/core/blocks/Loading";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { trellisPalette } from "components/custom/analytics/palettes";

let URL_PREFIX = getURLPrefix();

const ShelfSelection = ({
  keyword,
  showShelfSelection,
  setShowShelfSelection,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selection, setSelection] = useState(null);
  const [disabledList, setDisabledList] = useState([]);

  const fetchShelves = async () => {
    let url = `${URL_PREFIX}/api/shelves/`;
    const res = await axios.get(url, {});

    const results = res.data.results;
    if (results) {
      const dsList = [];

      results.forEach((item) => {
        item.searches.forEach((search) => {
          if (search.value === keyword) {
            dsList.push(item.id);
            return;
          }
        });
      });

      setDisabledList(dsList);
      setData(results);
      setLoading(false);
    }
  };

  const addToShelf = async () => {
    let shelf = null;
    data.forEach((item) => {
      if (item.id === selection) {
        shelf = item;
      }
    });

    if (shelf) {
      let url = `${URL_PREFIX}/api/shelves/${shelf.id}/`;

      shelf.searches.push({
        display: keyword,
        search_type: "SEARCH",
        value: keyword,
      });

      await axios.put(url, shelf);
      hideModal();
    }
  };

  useEffect(() => {
    if (keyword && showShelfSelection) {
      fetchShelves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, showShelfSelection]);

  const hideModal = () => {
    setShowShelfSelection(false);
  };

  // /user/searchanalytics#new_shelf=true&keyword=test123

  return (
    <Modal show={showShelfSelection} onHide={() => hideModal()} size="xm">
      <Modal.Header closeButton className="py-0 px-5">
        <div className="fs-3 my-4 text-center">
          Add <strong>{keyword}</strong> to a shelf
        </div>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loading text="Loading Shelves" />}

        {!loading && (
          <>
            <Row>
              <Col className="py-3 px-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-start">Shelf</th>
                      <th>Add</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((shelf) => (
                      <tr>
                        <td className="text-start">{shelf.name}</td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            disabled={disabledList.includes(shelf.id)}
                            checked={selection === shelf.id}
                            onClick={() => setSelection(shelf.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="py-2">
              <Col className="text-center fs-5">
                <Link
                  to={`/user/searchanalytics#new_shelf=true&keyword=${keyword}`}
                  style={{
                    color: trellisPalette[0],
                    fontWeight: 600,
                    border: 0,
                    background: "rgba(0,0,0,0)",
                  }}
                >
                  Create New Shelf
                </Link>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="py-4" style={{ width: "100%" }}>
          <Col xs={6} className="mx-auto text-start">
            <LogoutButton
              key="RefreshBtn"
              title={`Close`}
              onClick={() => hideModal()}
            />
          </Col>

          <Col xs={6} className="mx-auto text-end">
            <LogoutButton
              fontSize="12px"
              key="AddBtn"
              title={`Add`}
              onClick={() => addToShelf()}
              disabled={!selection}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ShelfSelection;
