import React, { useState, useRef } from "react";
import { Box, Grid, TextField, Button, withStyles } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { setChannel } from "redux/actions/channel";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import {
  logoutUser,
  setCurrentUser,
  setTokens,
} from "redux/actions/authActions";
import { setMarketPlace } from "redux/actions/marketPlace";
import api from "utils/api";
import { captureException } from "@sentry/react";

const useStyles = (theme) => ({
  basePanel: {
    height: "100%",
    backgroundColor: "white",
    justifyContent: "flex-start",
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
      justifyContent: "center",
    },
  },
  scrollColumn: {
    // display: "flex",
    position: "relative",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
  },
  formColumn: {
    justifyContent: "center",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: "0%",
    top: "50%",
    width: "100%",
    transform: "translateY(-50%)",
    overflow: "hidden",
  },
  button: {
    width: "50%",
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    backgroundColor: "#d91366",
  },
  signupLink: {
    color: "#d91366",
    textDecoration: "underline",
  },
  welcomeText: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: "bold",
    color: theme.palette.grey[800],
    textAlign: "center",
  },
  input: {
    fontSize: theme.typography.h4.fontSize,
  },
  inputlabel: {
    fontSize: theme.typography.h4.fontSize,
  },
  logo: {
    marginLeft: "-5%",
    maxWidth: "50%",
  },
});

function ForgetPassForm(props) {
  const { classes } = props;

  const emailRef = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSent, setEmailSent] = useState("");

  const handleResetPasswordSubmit = async (event) => {
    event.preventDefault();
    const email = emailRef.current.value;

    if (email.trim().length === 0) {
      setErrorMessage("Email cannot be empty");
      return;
    }

    try {
      await api.post("/reset-password/", { email });
      setEmailSent("Reset Password link sent to email");
    } catch (error) {
      captureException(error);
    }
  };

  return (
    <Grid container className={classes.basePanel} direction="row" wrap="nowrap">
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={10} md={8}>
        <PerfectScrollbar className={classes.scrollColumn}>
          <form autoComplete="on">
            <Box className={classes.formColumn}>
              <Box className={classes.welcomeText}>Forgot your password? </Box>
              <Box mt={2} width={1} className="fs-standard">
                Enter your email and you'll receive instructions to reset your
                password.
              </Box>
              <Box>
                <TextField
                  inputRef={emailRef}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                    autoComplete: "email",
                    id: "email",
                  }}
                  InputLabelProps={{
                    className: classes.inputlabel,
                    htmlFor: "email",
                  }}
                  label="Email"
                  name="email"
                />
              </Box>
              <Box mt={2} width={1}>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </Box>
              <Box mt={2} width={1}>
                {emailSent && <p style={{ color: "red" }}>{emailSent}</p>}
              </Box>
              <Box mt={5} width={1}>
                <Grid container justifyContent="center">
                  <Button
                    className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge ${classes.button}`}
                    type="submit"
                    onClick={handleResetPasswordSubmit}
                  >
                    Send Reset Password Link
                  </Button>
                </Grid>
              </Box>
            </Box>
          </form>
        </PerfectScrollbar>
      </Grid>
      <Grid item xs={1} md={2}></Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  setCurrentUser,
  updateAmzOnboardingStatus,
  setMarketPlace,
  setTokens,
  logoutUser,
  setErrors,
  clearErrors,
  setChannel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ForgetPassForm)));
