import React from "react";
import { withStyles, Button } from "@material-ui/core";

export const PrimaryButton = withStyles({
  root: {
    fontSize: "1.2rem",
    color: "#FFFF",
    letterSpacing: "2.5px",
    padding: ".8rem 2rem",
    borderRadius: "12px",
    textTransform: "uppercase",
    fontWeight: 700,
    "&:disabled": {
      backgroundColor: "#EF94B4",
      color: "#FFF",
    },
  },
})((props) => <Button variant="contained" color="secondary" {...props} />);

export const SecondaryButton = withStyles({
  root: {
    fontSize: "1.2rem",
    color: "#FFFF",
    letterSpacing: "2.5px",
    padding: ".8rem 2rem",
    borderRadius: "12px",
    textTransform: "uppercase",
    fontWeight: 700,
  },
})((props) => <Button variant="contained" color="primary" {...props} />);

export const PlainButton = withStyles({
  root: {
    fontSize: "1.2rem",
    color: "#333",
    letterSpacing: "2.5px",
    padding: 0,
    borderRadius: "12px",
    textTransform: "uppercase",
    fontWeight: 700,
    background: "#FFF",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FFF",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#FFF",
      boxShadow: "none",
    },
  },
})((props) => <Button variant="contained" disableRipple {...props} />);
