import moment from "moment";
import { useMemo } from "react";
import { useFetch } from "./api";

const useKeywordReport = (keys, adId, filters) => {
  const dt = moment().add(50, "days").format("YYYY-MM-DD");
  const { data: keywordMeta, isLoading: isKeywordMetaLoading } = useFetch(
    ["keyword_report_meta", adId],
    `/api/data_report/keyword_report`,
    {
      ...filters,
      start_date: dt,
      end_data: dt,
      pre_start_date: null,
      pre_end_date: null,
      kw_report: true,
      target_type: "keyword",
    },
    {
      select: (d) => d.data,
      enabled: !!adId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
    }
  );

  const { data: keywordData, isLoading: isKeywordDataLoading } = useFetch(
    [...keys, "keyword_report", "keyword"],
    `/api/data_report/keyword_report`,
    { ...filters, empty: false, kw_report: false, target_type: "keyword" },
    {
      select: (d) => d.data,
      enabled: !!adId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
    }
  );

  const data = useMemo(() => {
    const data = {};
    if (keywordData) {
      for (const row of keywordData.keywords) {
        data[row.name] = row;
      }
    }
    if (keywordMeta) {
      return keywordMeta.keywords.map((meta) => ({
        ...data?.[meta.id],
        ...meta,
      }));
    }
  }, [keywordData, keywordMeta]);

  return {
    keywordData: { keywords: data },
    isKeywordLoading: isKeywordMetaLoading,
    isKeywordStatsLoading: isKeywordDataLoading,
  };
};

export default useKeywordReport;
