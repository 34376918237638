import React from "react";
import { Col, Row, Form, Card } from "react-bootstrap";
import HeaderFields from "../../HeaderFields";
import { InlineInputField, Label } from "../../fields";
import TEMPLATES from "..";

const MarginProfilingForm = ({ control, formState }) => {
  return (
    <Row>
      <HeaderFields control={control} formState={formState} />
      <Col xs={12} className="mt-4">
        <Label>Margin Profiling Rules</Label>
      </Col>
      <Col xs={12}>
        <Card className="p-4">
          <Row>
            <Col xs={8}>
              <Row>
                <Col xs={12} className="mb-3">
                  <Form.Group>
                    <div className="d-flex align-items-center fs-standard">
                      <span className="text-nowrap">
                        Set a initial price of $
                      </span>
                      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <InlineInputField
                          fieldName={"initial.price"}
                          control={control}
                          rules={{ required: true }}
                          errors={formState.errors}
                          placeholder={"..."}
                        />
                      </span>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group>
                    <div className="d-flex align-items-center fs-standard">
                      <span className="text-nowrap">Every time i sell</span>
                      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <InlineInputField
                          fieldName={"increase.units"}
                          control={control}
                          rules={{ required: true }}
                          errors={formState.errors}
                          placeholder={"..."}
                        />
                      </span>
                      <span className="text-nowrap">or more units after</span>
                      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <InlineInputField
                          fieldName={"increase.days"}
                          control={control}
                          rules={{ required: true }}
                          errors={formState.errors}
                          placeholder={"..."}
                        />
                      </span>
                      <span className="text-nowrap">
                        days, increase my price
                      </span>
                      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <InlineInputField
                          fieldName={"increase.percent"}
                          control={control}
                          rules={{ required: true }}
                          errors={formState.errors}
                          placeholder={"..."}
                        />
                      </span>
                      <span className="text-nowrap">%</span>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group>
                    <div className="d-flex align-items-center fs-standard">
                      <span className="text-nowrap">If I have sold</span>
                      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <InlineInputField
                          fieldName={"lower.units"}
                          control={control}
                          rules={{ required: true }}
                          placeholder={"..."}
                          errors={formState.errors}
                        />
                      </span>
                      <span className="text-nowrap">or less units after</span>
                      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <InlineInputField
                          fieldName={"lower.days"}
                          control={control}
                          rules={{ required: true }}
                          placeholder={"..."}
                          errors={formState.errors}
                        />
                      </span>
                      <span className="text-nowrap">
                        days, lower the price by
                      </span>
                      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <InlineInputField
                          fieldName={"lower.percent"}
                          control={control}
                          rules={{
                            required: true,
                            valueAsNumber: true,
                            validate: (value) => value > 0,
                          }}
                          placeholder={"..."}
                          errors={formState.errors}
                        />
                      </span>
                      <span className="text-nowrap">%</span>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <div
                className="fs-standard"
                style={{
                  padding: "2rem",
                  border: "1px solid #eee",
                  borderRadius: "6px",
                  backgroundColor: "#f8f8f8",
                  height: "100%",
                }}
              >
                {
                  TEMPLATES.find(
                    (template) => template.id === "MARGIN_PROFILING"
                  ).description
                }
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export { MarginProfilingForm };
