import { MARKETPLACE_CURRENCY_CODE } from "./marketplaceConstants";

export const formatNumber = (number, options = null, decimalPlaces = null) => {
  if (!options) {
    options = {};
  }
  if (number == null || isNaN(parseFloat(number))) {
    return options?.default ?? "-";
  }

  number = parseFloat(number);

  if (options?.rounded) {
    if (options.rounded === "up") {
      number = Math.ceil(parseFloat(number));
    } else {
      number = Math.round(parseFloat(number));
    }
  }

  if (decimalPlaces) {
    number = parseFloat(number).toFixed(decimalPlaces);
  }

  if (decimalPlaces === 0 || number % 1 === 0) {
    number = parseFloat(number).toFixed(0);
  }

  if (options?.currency) {
    return formatCurrency(
      parseFormattedNumber(number),
      options?.currency?.marketPlace
    );
  }

  let addK = false;
  if (options.compact && number > 1000) {
    number = Math.round(number / 1000, 0);
    addK = true;
  }

  let formattedNumber = new Intl.NumberFormat().format(number, null, options);
  if (number > 0 && options.addPlus) {
    formattedNumber = `+${formattedNumber}`;
  }
  if (options.compact && addK) {
    formattedNumber = `${formattedNumber}K`;
  }

  return formattedNumber;
};

export const parseFormattedNumber = (number) => {
  if (typeof number === "number") {
    return number;
  }
  const parsedNumber = parseFloat(number.replace(/,/g, ""));

  return parsedNumber;
};

export const parseFormattedPercentage = (number) => {
  const parsedNumber = parseFloat(number.replace(/%,/g, ""));

  return parsedNumber;
};

export const parseFormattedCurrency = (number) => {
  const parsedCurrency = parseInt(number);

  return parsedCurrency;
};

export const formatCurrency = (
  number,
  marketPlace,
  clipDecimalPlaces = false,
  addPlus = false,
  compact = false
) => {
  const options = {
    style: "currency",
    currency: MARKETPLACE_CURRENCY_CODE[marketPlace] ?? "USD",
    maximumFractionDigits: clipDecimalPlaces ? 0 : 2,
    minimumFractionDigits: clipDecimalPlaces ? 0 : 2,
    currencyDisplay: "narrowSymbol",
  };
  if (compact) {
    options["notation"] = "compact";
  }
  let formattedCurrency = new Intl.NumberFormat(
    navigator.languages,
    options
  ).format(number);

  if (number > 0 && addPlus) {
    formattedCurrency = `+${formattedCurrency}`;
  }

  if (formattedCurrency.includes("NaN")) {
    return "-";
  }

  return formattedCurrency;
};

export const formatPercent = (number, decimalPlaces = 0, addPlus) => {
  let percent = new Intl.NumberFormat(navigator.language, {
    style: "percent",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(number);

  if (Number.isNaN(number)) {
    return "-";
  }

  if (number > 0 && addPlus) {
    return `+${percent}`;
  }

  if (percent.includes("NaN")) {
    return "-";
  }

  return percent;
};
