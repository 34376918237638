import React from "react";

import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  logoutUser,
  setCurrentUser,
  setTokens,
} from "redux/actions/authActions";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { setChannel } from "redux/actions/channel";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { setMarketPlace } from "redux/actions/marketPlace";
import bgImage from "assets/images/background/Rocket-10.png";
import PassForm from "./PassForm";
import InviteForm from "./InviteForm";
import ForgetPassForm from "./ForgetPassForm";
import ResetPassword from "./ResetPassword";

const useStyles = (theme) => ({
  basePanel: {
    background: "white",
    flexGrow: 1,
    // border: "1px solid black",
    height: "100vh",
  },
  leftSignUpPanel: {
    [theme.breakpoints.up("md")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      // order: 2,
      paddingTop: "5%",
      height: "100%",
    },
  },
  rightSignUpPanel: {
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      order: 2,
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      order: 1,
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      // order: 1,
      height: "0%",
    },
    backgroundImage: "url(" + bgImage + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "scroll",
  },
});

const PassSignIn = (props) => {
  const { classes } = props;

  let formType = "signup";
  let inviteToken = null;
  let resetID = null;
  let resetToken = null;

  if (props.location.search.indexOf("?token=") > -1) {
    formType = "invitation";
    inviteToken = props.location.search.replace("?token=", "");
  } else if (props.location.search.indexOf("?user_token=") > -1) {
    formType = "new_password";
    inviteToken = props.location.search.replace("?user_token=", "");
  } else if (props.location.pathname.indexOf("/resetpassword") > -1) {
    formType = "reset_password";
  }

  return (
    <Grid
      container
      className={classes.basePanel}
      justifyContent="flex-start"
      alignContent="flex-start"
    >
      <Grid
        item
        xs={12}
        md={4}
        className={classes.leftSignUpPanel}
        style={{ order: 2 }}
      >
        {formType === "signup" && <PassForm />}
        {formType === "invitation" && <InviteForm inviteToken={inviteToken} />}
        {formType === "reset_password" && <ForgetPassForm />}
        {formType === "new_password" && (
          <ResetPassword resetToken={resetToken} resetId={resetID} />
        )}
      </Grid>
      <Grid item xs={12} md={4} style={{ order: 1 }}>
        &nbsp;
      </Grid>
      <Grid item xs={12} md={4} style={{ order: 3 }}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  setCurrentUser,
  updateAmzOnboardingStatus,
  setMarketPlace,
  setTokens,
  logoutUser,
  setErrors,
  clearErrors,
  setChannel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PassSignIn)));
