import React from "react";
import { Grid } from "@material-ui/core";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import ToolTips from "utils/toolTips";

const ListingStatTiles = ({ data, marketPlace, layout }) => {
  const formatStats = (value, format) => {
    let formattedValue;

    if (format === "currency") {
      if (value) {
        formattedValue =
          MARKETPLACE_CURRENCY_DENOMINATION[marketPlace] +
          parseFloat(value.toFixed(2)).toLocaleString();
      } else {
        formattedValue = "-";
      }
    } else if (format === "percentage") {
      if (value) {
        formattedValue =
          parseFloat((value * 100).toFixed(0)).toLocaleString() + "%";
      } else {
        formattedValue = "-";
      }
    } else if (format === "number") {
      if (value) {
        formattedValue = value.toLocaleString();
      } else {
        formattedValue = "-";
      }
    }

    return formattedValue;
  };

  return (
    <div>
      <Grid
        container
        style={{
          fontSize: "1.8rem",
          color: "#73706E",
          margin: "0px",
          padding: "0px",
          marginTop: "2rem",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        {data.map((tileData, index) => {
          const { metric, value, format, toolTip, toolTipId } = tileData;

          return (
            <Grid
              item
              xs={6}
              md={4}
              lg={layout.lg}
              key={index}
              style={{ margin: "1rem auto", padding: "1.5rem" }}
            >
              <div
                style={{
                  float: "center",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                {metric}:{" "}
                <span style={{ fontWeight: "700", whiteSpace: "nowrap" }}>
                  {formatStats(value, format)}
                  {toolTip && (
                    <ToolTips toolTip={toolTip} position="top" id={toolTipId} />
                  )}
                </span>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ListingStatTiles;
