import React, { useMemo, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import getMetricSpec, { metrics } from "views/merchandise/metrics";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { Col, Row, Form } from "react-bootstrap";
import { useFetch } from "hooks/api";
import Switch from "react-bootstrap-switch";
import { useSelector } from "react-redux";
import moment from "moment";
import { Label } from "reactstrap";
import { PrimaryButton } from "components/core/basic/Button";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { sendToastNotification } from "utils/sendToastNotification";
import useDefaultState from "hooks/useDefaultState";
import { MARKETPLACE_TIMEZONE } from "utils/marketplaceConstants";
import { useDates } from "dates/useDates";

const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const HOURS = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm",
];

const HourChart = ({ data, metrics, initialSelection }) => {
  const options = useMemo(() => {
    const series = metrics.map((metric) => {
      const {
        id,
        color,
        name,
        accessor,
        chartType = "area",
        formatter,
        axis = 0,
      } = metric;
      let chartData = data?.map((d) => accessor(d) ?? null);
      chartData = chartData?.filter((d) => d)?.length === 0 ? [] : chartData;
      const additionalOptions = {};
      if (chartType === "area") {
        additionalOptions["fillOpacity"] = 0.1;
      }
      if (chartType === "column") {
        additionalOptions["opacity"] = 0.8;
      }
      const visible = initialSelection.includes(id);
      return {
        custom: { id },
        data: chartData,
        color,
        visible,
        name,
        yAxis: axis,
        type: chartType,
        tooltip: {
          formatter: function () {
            return (
              '<span style="color:' +
              this.series.color +
              '">\u25CF</span> ' +
              this.series.name +
              ": " +
              formatter(this.y) +
              "<br/>"
            );
          },
        },
        ...additionalOptions,
      };
    });
    return {
      title: { text: "Hourly Trends" },
      chart: {
        marginLeft: 90,
        marginRight: 10,
        style: {
          "font-family": "Poppins",
          "font-size": "15px",
        },
        height: 460,
      },
      legend: {
        itemStyle: {
          fontWeight: "bold",
        },
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let points = this.points;

          let markup = points.length
            ? '<span style="font-size: 10px; font-weight: bold; margin-left: 1rem">' +
              points[0].key +
              "</span><br/>"
            : "";

          for (let index = 0; index < points.length; index++) {
            let point = points[index];

            // Get formatter => find metric with this point.series.name, get corresponding formatter from props
            let fullMetric = metrics.find(
              (m) => m.name === point?.series?.name
            );

            const { formatter } = fullMetric ?? {};

            markup +=
              '<span style="color:' +
              point.series.color +
              '">\u25CF</span> ' +
              point.series.name +
              ": " +
              formatter(point.y) +
              "<br/>";
          }

          return markup;
        },
      },
      yAxis: Array.from(Array(50)).map(() => ({
        title: {
          text: "",
        },
        visible: false,
        maxPadding: 0,
      })),
      xAxis: {
        categories: HOURS,
      },
      series,
    };
  }, [data, metrics, initialSelection]);
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const HeatMap = ({
  data,
  metric,
  adPlanParams,
  updateMultipliers,
  children,
}) => {
  const [selectedPoints, setSelectedPoints] = useState({});
  const [bidValue, setBidValue] = useState(1);
  const [isEnabled, setIsEnabled] = useDefaultState(
    adPlanParams.day_parting_method === 1
  );
  const defaultBidModifiers = useMemo(() => ({}), []);
  const [bidModifiers, setBidModifiers] = useDefaultState(
    adPlanParams?.day_parting_multipliers || defaultBidModifiers
  );
  const isDirty = useMemo(() => {
    return (
      isEnabled !== (adPlanParams.day_parting_method === 1) ||
      JSON.stringify(bidModifiers) !==
        JSON.stringify(adPlanParams?.day_parting_multipliers || {})
    );
  }, [isEnabled, bidModifiers, adPlanParams]);
  const chart = useRef(null);

  const options = useMemo(() => {
    const { id, color, name, accessor, formatter } = metric;
    const days = Array.from(Array(7)).map((_, i) => i);
    const hours = Array.from(Array(24)).map((_, i) => i);
    let chartData = days.flatMap((d) => hours.map((h) => [h, d, 0]));

    if (data?.length > 0) {
      data.forEach((d) => {
        const [hour, day, value] = d;
        chartData[day * 24 + hour][2] = accessor(value) ?? 0;
      });
    }
    const series = [
      {
        custom: { id },
        data: chartData,
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (!isEnabled) return "";
            const { x, y } = this.point;
            const bidModifier = bidModifiers?.[`${x}-${y}`] ?? 1;
            return `${bidModifier}x`;
          },
        },
        name,
      },
    ];
    return {
      title: { text: "Weekday - Hourly Trends" },
      chart: {
        type: "heatmap",
        style: {
          "font-family": "Poppins",
          "font-size": "15px",
        },
        plotBorderWidth: 0,
        height: 460,
      },
      legend: {
        itemStyle: {
          fontWeight: "bold",
        },
      },
      plotOptions: {
        series: {
          borderRadius: 5,
          pointPadding: 2,
          borderColor: "white",
          borderWidth: 1,
          cursor: "pointer",
          allowPointSelect: true,
          marker: {
            states: {
              select: {
                color: "",
                lineColor: color,
                lineWidth: 2,
              },
              hover: {
                lineColor: color,
                lineWidth: 2,
              },
            },
          },
          point: {
            events: {
              select: function (e) {
                const point = `${e.target.x}-${e.target.y}`;
                setSelectedPoints((selectedPoints) => {
                  return { ...selectedPoints, [point]: true };
                });
                return true;
              },
              unselect: function (e) {
                const point = `${e.target.x}-${e.target.y}`;
                setSelectedPoints((selectedPoints) => {
                  return { ...selectedPoints, [point]: false };
                });
                return true;
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          const { x: hour, y: day, value } = this.point;
          const bidModifier = bidModifiers?.[`${hour}-${day}`] ?? 1;
          const bidModifierTooltip = isEnabled
            ? ` (Bid Modifier ${bidModifier}x)`
            : "";
          return `${this.point.series.name}: ${formatter(value)}, ${
            HOURS[hour]
          } on ${WEEKDAYS[day]}s${bidModifierTooltip}`;
        },
      },
      colorAxis: {
        minColor: "#FFFFFF",
        maxColor: color,
      },
      yAxis: {
        categories: WEEKDAYS,
        title: null,
        // reversed: true,
        gridLineColor: "white",
      },
      xAxis: {
        categories: HOURS,
        gridLineColor: "white",
      },
      series,
    };
  }, [data, metric, bidModifiers, isEnabled, setSelectedPoints]);

  const hasSelected = useMemo(() => {
    return Object.values(selectedPoints).some((v) => v);
  }, [selectedPoints]);

  return (
    <div
      className="fs-standard"
      style={{
        position: "relative",
      }}
    >
      <Row>
        <Col xs={12} lg={6} className="d-flex align-items-end">
          <div className="pr-3">
            <Switch
              onText={``}
              offText={``}
              onColor="#FFFFFF"
              value={isEnabled}
              onChange={() => {
                setIsEnabled(!isEnabled);
              }}
            />
            <span className="pl-3">Day Parting</span>
          </div>
          {isDirty && !hasSelected && (
            <div className="d-flex pl-2">
              <PrimaryButton
                overrideStyles={{ marginRight: "0.5rem" }}
                disabled={updateMultipliers.isLoading}
                onClick={() => {
                  updateMultipliers.mutate({
                    day_parting_method: isEnabled ? 1 : 0,
                    day_parting_multipliers: bidModifiers,
                  });
                }}
              >
                Save
              </PrimaryButton>
              <PrimaryButton
                disabled={updateMultipliers.isLoading}
                onClick={() => {
                  setIsEnabled(adPlanParams.day_parting_method === 1);
                  setBidModifiers(adPlanParams?.day_parting_multipliers ?? {});
                }}
              >
                Reset
              </PrimaryButton>
            </div>
          )}
          {isEnabled && hasSelected && (
            <>
              <div>
                <Label>Bid Modifier</Label>
                <Form.Control
                  type="text"
                  value={bidValue}
                  onChange={(e) => {
                    setBidValue(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex pl-2">
                <PrimaryButton
                  overrideStyles={{ marginRight: "0.5rem" }}
                  onClick={() => {
                    const updates = chart.current.chart
                      .getSelectedPoints()
                      .reduce((acc, point) => {
                        const { x, y } = point;
                        acc[`${x}-${y}`] = bidValue;
                        return acc;
                      }, {});
                    setBidModifiers({
                      ...bidModifiers,
                      ...updates,
                    });
                    chart.current.chart
                      .getSelectedPoints()
                      .forEach((p) => p.select(false, false));
                    setSelectedPoints({});
                  }}
                >
                  Apply
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => {
                    chart.current.chart
                      .getSelectedPoints()
                      .forEach((p) => p.select(false, false));
                    setSelectedPoints({});
                  }}
                >
                  Cancel
                </PrimaryButton>
              </div>
            </>
          )}
        </Col>
        <Col
          xs={12}
          lg={6}
          className="d-flex justify-content-end align-items-end"
        >
          <div style={{ width: "20rem" }}>{children}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="">
          <small style={{ display: "inline-block" }}>
            {isEnabled && (
              <i>
                Select cells on the chart to change the bid multiplier. Use the
                'shift' key to select multiple cells.
              </i>
            )}
          </small>
        </Col>
      </Row>
      <HighchartsReact ref={chart} highcharts={Highcharts} options={options} />
    </div>
  );
};

const DayPartingChart = ({ advertisement, categoryId }) => {
  const { start: _start, end: _end } = useDates();
  const [start, end, dateError] = useMemo(() => {
    if (_start && _end) {
      // limit the date range to at most 30 days in the past
      const nowDiff = moment().diff(_start, "days");
      if (nowDiff > 30) {
        return [moment().subtract(30, "days"), moment(), true];
      }

      // limit the date range to 30 days
      const diff = _end.diff(_start, "days");
      if (diff > 30) {
        return [_end.clone().subtract(30, "days"), _end, true];
      }
      return [_start, _end, false];
    }
    return [null, null, false];
  }, [_start, _end]);
  const { data: adPlanData } = useFetch(
    ["media-plan", advertisement.id],
    "/api/gvads/advproduct/",
    {
      advertisement: advertisement.id,
    },
    {
      select: (d) => d.data,
      enabled: !!advertisement?.id,
    }
  );
  const adPlanParams = useMemo(() => {
    return adPlanData?.ad_control_fields ?? {};
  }, [adPlanData]);
  const { marketPlace } = useSelector((state) => state);
  const [isFilterAdvertisement, setIsFilterAdvertisement] = useState(true);
  const filter = useMemo(() => {
    let filters = `advertisement_id:${advertisement.id}`;
    if (!isFilterAdvertisement) {
      filters = `category_id:${categoryId}`;
    }
    filters = `${filters},date__gte:${start.format(
      "YYYY-MM-DD"
    )},date__lte:${end.format("YYYY-MM-DD")}`;
    return { filters };
  }, [advertisement.id, categoryId, isFilterAdvertisement, start, end]);
  const { data: dayhourData } = useFetch(
    ["campaignseasonality", "hour,weekday", filter],
    `/api/data_report_v2/campaignstatshourly/stats/?dimensions=hour,weekday`,
    filter,
    {
      select: (d) => d.data,
      enabled: !!advertisement?.id && !!start && !!end,
    }
  );
  const notificationRef = useRef();
  const { data: hourData } = useFetch(
    ["campaignseasonality", "hour", filter],
    `/api/data_report_v2/campaignstatshourly/stats/?dimensions=hour`,
    filter,
    {
      select: (d) => d.data,
      enabled: !!advertisement?.id && !!start && !!end,
    }
  );
  const queryClient = useQueryClient();

  const updateMultipliers = useMutation(
    async (fields) => {
      const body = {
        id: advertisement.id,
        custom_api_request: "update_advertisement",
        state: advertisement.state,
        ad_control_fields: fields,
      };
      return await api.put("/api/gvads/advproduct/", body);
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          "Updating Media Plan"
        );
        queryClient.invalidateQueries(["media-plan", advertisement.id]);
      },
      onError: () => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update Media Plan`
        );
      },
    }
  );

  const [selectedMetric, setSelectedMetric] = useState("ad_sales");
  const metricOptions = metrics
    .filter((m) =>
      [
        "conversion_rate",
        "ctr",
        "impressions",
        "clicks",
        "ad_sales",
        "acos",
        "roas",
        "cpc",
        "ad_spend",
      ].includes(m.id)
    )
    .map((m) => ({ value: m.id, label: m.name }));
  const [startDate, endDate] = useMemo(() => {
    return (
      hourData?.reduce(
        ([startDate, endDate], d) => {
          let start = startDate || d.start_date;
          start = start < d.start_date ? start : d.start_date;
          let end = endDate || d.end_date;
          end = end > d.end_date ? end : d.end_date;
          return [start, end];
        },
        [null, null]
      ) ?? []
    );
  }, [hourData]);

  const metric = useMemo(() => {
    return getMetricSpec(marketPlace.marketPlace, selectedMetric);
  }, [selectedMetric, marketPlace]);

  const chartMetrics = useMemo(() => {
    return metricOptions.map(({ value }) =>
      getMetricSpec(marketPlace.marketPlace, value)
    );
  }, [metricOptions, marketPlace]);

  const data = useMemo(() => {
    return (
      dayhourData?.map((d) => {
        return [d.hour, d.weekday - 1, d];
      }) ?? []
    );
  }, [dayhourData]);

  const hourlyData = useMemo(() => {
    // average data by hour
    return hourData?.sort((a, b) => a["hour"] - b["hour"]) ?? [];
  }, [hourData]);

  return (
    <Row>
      <Col xs={12} className="fs-standard d-flex justify-content-end">
        <div>
          <Switch
            onText={``}
            offText={``}
            onColor="#FFFFFF"
            value={!isFilterAdvertisement}
            onChange={() => setIsFilterAdvertisement(!isFilterAdvertisement)}
          />
          <span className="pl-3">Category</span>
        </div>
      </Col>
      <Col xs={12} className="fs-standard d-flex justify-content-end">
        <div style={{ textAlign: "right" }}>
          Period: {moment(startDate).format("MMM D 'YY")} -
          {moment(endDate).format("MMM D 'YY")}
          <br />
          {dateError && (
            <span style={{ color: "red" }}>
              {dateError ? "Hourly trends are limited to the last 30 days" : ""}
              <br />
            </span>
          )}
          Timezone: {MARKETPLACE_TIMEZONE?.[marketPlace.marketPlace] ?? "PST"}
        </div>
      </Col>
      <Col xs={12} className="fs-standard">
        <HourChart
          data={hourlyData}
          metrics={chartMetrics}
          initialSelection={["ad_sales", "ad_spend", "acos"]}
        />
      </Col>
      <Col xs={12} className="fs-standard">
        <HeatMap
          data={data}
          metric={metric}
          adPlanParams={adPlanParams}
          updateMultipliers={updateMultipliers}
        >
          <Label>Metric</Label>
          <FormGroupTile
            type="select"
            multi={false}
            handleChildFormElement={(key, value) => {
              setSelectedMetric(value.value);
            }}
            defaultValue={metricOptions.find((m) => m.value === selectedMetric)}
            options={metricOptions}
          />
        </HeatMap>
      </Col>
    </Row>
  );
};

export default DayPartingChart;
