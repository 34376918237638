import React, { useState, useMemo, useRef } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AD_TYPES, GOALS } from "components/core/blocks/AdPlan/constants";
import Loading from "components/core/blocks/Loading";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import acosOrRoas from "utils/acosOrRoas";
import AdvertisementExpander from "./advertising/AdvertisementExpander";
import ToolTips from "utils/toolTips";
import NoImage from "assets/images/utility/no_image.png";
import moment from "moment";
import { getDashboardTo } from "./AdvertisingOverview";
import getURLPrefix from "utils/getUrlPrefix";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api/index";
import BulkAdPlanActionForm from "./advertising/BulkAdPlanActionForm";
import { sendToastNotification } from "utils/sendToastNotification";
import { CategoryField } from "components/core/blocks/AdPlan/fields";
import { STATUS } from "components/core/blocks/AdPlan/constants";
import { formatCurrency } from "utils/formatNumber";

import useCustomTableFilters from "hooks/useCustomTableFilters";
import {
  AD_STATUS_FILTER,
  AD_TYPE_FILTER,
  AD_SOURCE_FILTER,
  NTM_STATUS_FILTER,
  SCHEDULED_BUDGET_CHANGES_FILTER,
} from "./advertising/adLifecycleFilters";
import { buildDateColumn } from "./merchandise/columns/buildDateColumn";
import { formatScheduledAction } from "./advertising/ScheduledBudgetSummary";
import { getAdTypeName } from "utils/getAdTypeName";
import { buildCustomTagsFilter } from "./advertising/productLifecycleFilters";
import BulkTagsForm from "components/core/blocks/BulkTagsForm";
import TitleLink from "components/core/basic/TitleLink";

let URL_PREFIX = getURLPrefix();

const updateValues = (
  initialValue,
  changeValue,
  changeUnit,
  changeDirection,
  targetUnits
) => {
  initialValue = parseFloat(initialValue);
  changeValue = parseFloat(changeValue);
  if (changeDirection === "increase") {
    // Handle case where percent added to percent metric (i.e. acos goals)
    if (changeUnit === "currency" || changeUnit === targetUnits) {
      return initialValue + changeValue;
    }
    if (changeUnit === "percent") {
      return initialValue * (1 + changeValue / 100);
    }
  }

  if (changeDirection === "decrease") {
    // Handle case where percent added to percent metric (i.e. acos goals)
    if (changeUnit === "currency" || changeUnit === targetUnits) {
      return initialValue - changeValue;
    }

    if (changeUnit === "percent") {
      return initialValue * (1 - changeValue / 100);
    }
  }

  if (changeDirection === "specify") {
    return changeValue;
  }
};

const displayAdStatus = (
  status_id,
  state_id,
  moderation_status = null,
  ad_type = null
) => {
  const success = "pe-7s-check";
  const progress = "pe-7s-more";
  const failed = "pe-7s-attention";
  const paused = "pe-7s-close-circle";
  if (
    ad_type === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
    typeof moderation_status === "string"
  ) {
    return "creative_failed";
  } else if (status_id === 1) {
    return [success, "Success", "#25B50E"];
  } else if (status_id === 10) {
    return [failed, "Failed", "#CF1E10"];
  } else if (status_id === 2) {
    return [progress, "Pending", "#eb8c07"];
  } else if (status_id === 3 || (status_id === 11 && state_id === 0)) {
    return [paused, "Paused", "#2b00ff"];
  } else if (status_id === 11) {
    return [success, "Approved", "#25B50E"];
  } else if (status_id === 22) {
    return [progress, "In Progress", "#eb8c07"];
  } else if (status_id === 20) {
    return [failed, "Rejected"];
  }
};

const AdvertisementCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const adId = props?.original?.ad_id;
  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?advertisement=${adId}`;

  const [statusIcon, statusText, statusColor] = displayAdStatus(
    props?.original?.ad_status,
    props?.original?.state,
    props?.original?.moderation_status,
    props?.original?.ad_type
  );

  let NTMBadge = "hidden";

  if (props?.original?.ad_goal === GOALS.IMPORTED_ADS.value)
    NTMBadge = "non-managed";
  if (props?.original?.non_trellis_enrolled_ad) NTMBadge = "enrolled";
  if (props?.original?.non_trellis_enrolled_ad_accept_bids)
    NTMBadge = "accept_bids";

  const { scheduled_actions: scheduledActions, tags } = props.original ?? {};

  return (
    <div style={{ display: "flex", fontWeight: "500", fontSize: "1.5rem" }}>
      <div
        onError={(e) => {
          setHasError(true);
        }}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div
        className="overflow-ellipsis pt-2 title-link"
        style={{ textAlign: "left" }}
      >
        <TitleLink
          to={getDashboardTo({
            type: "advertisement",
            id: adId,
          })}
          style={{
            textDecoration: "none",
            fontWeight: "500",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
          title={props?.original?.ad_name}
        />
        <div className="d-flex align-items-center">
          <small
            className="text-nowrap pr-3"
            style={{
              fontWeight: "light",
              color: "#666",
              fontSize: "1rem",
            }}
          >
            <i>
              {props?.original?.ad_type ===
                AD_TYPES.SPONSORED_PRODUCT_WALMART.value &&
              localStorage.channel === "walmart"
                ? AD_TYPES.SPONSORED_PRODUCT_WALMART.label
                : props.original?.ad_type ===
                    AD_TYPES.SPONSORED_BRAND_V2.value &&
                  ["_SB_BV_", "_SB_V_"].includes(
                    props?.original?.creative?.brand_ad_type
                  )
                ? AD_TYPES.SPONSORED_BRAND_VIDEO.label
                : props.original?.ad_type ===
                    AD_TYPES.SPONSORED_BRAND_V2.value &&
                  !props?.original?.parent
                ? AD_TYPES.SPONSORED_BRAND_V2.label
                : Object.values(AD_TYPES).find(
                    (adTypeId) => props?.original?.ad_type === adTypeId.value
                  )?.label}
            </i>
            <span
              style={{
                display: "inline-block",
                fontWeight: "bold",
                marginLeft: "1rem",
                fontSize: "18px",
                verticalAlign: "middle",
              }}
            >
              <ToolTips
                position="top"
                id={`adStatus-${adId ?? ""}`}
                toolTip={statusText}
                icon={statusIcon}
                color={statusColor}
              />
            </span>{" "}
          </small>
          {NTMBadge === "non-managed" && (
            <>
              <Badge bsClass="badge" className="bg-trellis-purple me-2" pill>
                Non Managed
              </Badge>
            </>
          )}
          {NTMBadge === "enrolled" && (
            <>
              <Badge bsClass="badge" className="bg-trellis-fuchsia me-2" pill>
                NTM - Enrolled
              </Badge>
            </>
          )}
          {NTMBadge === "accept_bids" && (
            <>
              <Badge bsClass="badge" className="bg-trellis-green me-2" pill>
                NTM - Bids Accepted
              </Badge>
            </>
          )}
          {props.original.campaign_status === "CAMPAIGN_OUT_OF_BUDGET" ? (
            <Badge
              className={`badge bg-trellis-purple me-2`}
              pill
              style={{
                borderRadius: "5px",
              }}
            >
              <span className="fs-6 ">Out of Budget </span>
            </Badge>
          ) : (
            <></>
          )}

          {props.original.start_date || props.original.end_date ? (
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip
                    placement="top"
                    className="in"
                    id="tooltip-top"
                    bsPrefix="tr-popover-alt bg-purple"
                  >
                    <span className="text-center mx-auto fs-5">
                      Manual budget change scheduled <br />
                      {moment(props.original.start_date).format(
                        "MMMM D"
                      )} - {moment(props.original.end_date).format("MMMM D")}
                    </span>
                  </Tooltip>
                }
              >
                <Badge
                  className={`badge bg-trellis-mauve me-2`}
                  pill
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <span className="fs-6 ">Scheduled Bids </span>
                </Badge>
              </OverlayTrigger>
            </>
          ) : (
            <></>
          )}

          {props.seasonalCategory ? (
            <>
              <Badge bsClass="badge" className="bg-trellis-fuchsia" pill>
                Seasonality
              </Badge>
            </>
          ) : (
            <></>
          )}
        </div>
        {scheduledActions?.length ? (
          scheduledActions.map((scheduledAction, index) => (
            <Badge
              key={`scheduledActionAd${props?.original?.id}-${index}`}
              className={`badge bg-trellis-purple me-3`}
              pill
              style={{
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <span className="fs-6 ">
                {scheduledAction?.scheduled_action_fields[0]?.change_type ===
                "percentage_change"
                  ? `${
                      scheduledAction?.scheduled_action_fields[0]
                        ?.change_value > 1
                        ? "+"
                        : ""
                    }${parseInt(
                      scheduledAction?.scheduled_action_fields[0]
                        ?.change_value *
                        100 -
                        100
                    )}%`
                  : formatCurrency(
                      scheduledAction?.scheduled_action_fields[0]?.change_value,
                      props.marketPlace,
                      false,
                      true
                    )}{" "}
                Budget Update:{" "}
                {moment(scheduledAction?.scheduled_action_start_date)
                  .utc()
                  .format("MMM D")}
                -
                {moment(scheduledAction?.scheduled_action_end_date)
                  .utc()
                  .format("MMM D")}{" "}
              </span>
            </Badge>
          ))
        ) : (
          <></>
        )}
        {tags?.map((tag, index) => (
          <Badge
            bsClass="badge"
            className="bg-trellis-mauve me-3"
            style={{
              cursor: "pointer",
            }}
            pill
            key={`${props?.original?.id}-customTags-${index}`}
          >
            {tag}
          </Badge>
        ))}
      </div>
    </div>
  );
};

const AdvertisementsTable = ({
  type,
  stats,
  marketPlace,
  channel,
  modules = [],
  preferences,
  smallViewportWidthTable,
  categoryDetails = {},
  categories,
  isLoading,
  notificationRef,
}) => {
  const [selectedAds, setSelectedAds] = useState([]);
  const [destinationCategoryId, setDestinationCategoryId] = useState(null);
  const [bulkActionFormData, setBulkActionFormData] = useState({
    actionUnit: "percent",
  });
  const [filledTags, setFilledTags] = useState([]);

  const TABLE_ID = "advertisementsTable";
  const tableRef = useRef();
  const queryClient = useQueryClient();

  let resolvedTableData = useMemo(
    () => {
      return tableRef.current?.getResolvedState()?.sortedData;
    },
    // Below intentional to keep resolved table data up-to-date when higher level filters applied
    // With deps, react-table only resolves state for internal filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableRef.current]
  );

  const updateHandler = useMutation(
    async (body) => {
      let reqData = body.data ?? {};
      return await api.put("/api/gvads/advproduct/", reqData);
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          `Updating Media Plan${
            selectedAds?.length > 1 ||
            resolvedTableData
              ?.map((row) => row?._original)
              ?.filter((d) => d?.updated)?.length > 1
              ? "s"
              : ""
          }`
        );

        setSelectedAds([]);
        setBulkActionFormData({ actionUnit: "percent" });
        queryClient.invalidateQueries();
        return data;
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update Media Plan ${variables.data.name}`
        );
      },
    }
  );

  const scheduleHandler = useMutation(
    async (body) => {
      const reqData = body.data ?? {};
      if (body.throwError) {
        throw new Error(body.throwError);
      }
      return await api.post("/api/scheduledactions/", reqData);
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          `Successfully Scheduled Budget Update${
            selectedAds?.length > 1 ? "s" : ""
          }`
        );

        setSelectedAds([]);
        setBulkActionFormData({ actionUnit: "percent" });
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          variables.throwError
            ? variables.throwError
            : `Failed to Schedule Budget Update${
                selectedAds?.length > 1 ? "s" : ""
              }`
        );
        setSelectedAds([]);
        queryClient.invalidateQueries();
      },
    }
  );

  const deleteHandler = useMutation(
    async (id) => {
      await api.remove(`/api/scheduledactions/${id}/`);
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          "Successfully Cancelled Budget Changes"
        );
        setBulkActionFormData({ actionUnit: "percent" });
        setSelectedAds([]);
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Cancel Scheduled Budget Changes"
        );
      },
    }
  );

  const scheduleBudgetUpdates = async () => {
    for (let i = 0; i < selectedAds?.length; i++) {
      const { actionType, actionValue, actionUnit, startDate, endDate } =
        bulkActionFormData;

      const adToUpdate = stats.find((ad) => ad.id === selectedAds[i]);

      // Check if scheduled action already exists that overlaps, if so return early and tell user
      const overlappingAction = adToUpdate?.scheduled_actions.some((sa) => {
        if (
          moment(startDate).isBetween(
            sa.scheduled_action_start_date.slice(0, 10),
            sa.scheduled_action_end_date.slice(0, 10),
            undefined,
            "[]"
          ) ||
          moment(endDate).isBetween(
            sa.scheduled_action_start_date.slice(0, 10),
            sa.scheduled_action_end_date.slice(0, 10),
            undefined,
            "[]"
          )
        ) {
          return true;
        }
        return false;
      });

      if (overlappingAction) {
        if (i === selectedAds?.length - 1) {
          // Make sure updates shown for all successfully added actions as tags, even if overlap prevents last action from being created
          scheduleHandler.mutate({
            data: null,
            isLast: true,
            throwError: `Failed to create scheduled update for ${adToUpdate?.ad_name}. The selected date range collides with a previously scheduled action.`,
          });
        } else {
          sendToastNotification(
            notificationRef,
            "warning",
            `Failed to create scheduled update for ${adToUpdate?.ad_name}. The selected date range collides with a previously scheduled action.`
          );
        }
        continue;
      }

      const updateData = formatScheduledAction(
        selectedAds[i],
        startDate,
        endDate,
        actionUnit,
        actionValue,
        actionType,
        channel
      );

      scheduleHandler.mutate({
        data: updateData,
        isLast: i === selectedAds?.length - 1,
      });
    }
    setSelectedAds([]);
  };

  const updateTags = useMutation(
    async (updateData) => {
      const { ad_id, tags } = updateData;
      return await api.patch(`/ads/${ad_id}/`, { tags });
    },
    {
      onSuccess: (res, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          `Media Plan Tags Successfully Updated`
        );

        setSelectedAds([]);
        setFilledTags([]);
        queryClient.invalidateQueries();
      },
      onError: (res, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update Media Plan Tags`
        );
        setSelectedAds([]);
        setFilledTags([]);
        queryClient.invalidateQueries();
      },
    }
  );

  const updatePlanTags = (type = "add") => {
    for (let i = 0; i < selectedAds.length; i++) {
      const ad = data?.find((ad) => ad.id === selectedAds[i]);
      const updatedTags =
        type === "remove"
          ? ad?.tags?.filter((tag) => !filledTags?.includes(tag) ?? [])
          : [...(ad?.tags ?? []), ...filledTags];

      updateTags.mutate({
        ad_id: ad.id,
        tags: [...new Set(updatedTags)],
        isLast: i === selectedAds?.length - 1,
      });
    }
  };

  const updatePlanCategories = async () => {
    for (let i = 0; i < selectedAds?.length; i++) {
      let adPlanData = stats.find((ad) => ad.ad_id === selectedAds[i]);

      let updateData = {
        id: selectedAds[i],
        state: adPlanData.state,
        custom_api_request: "update_advertisement",
        name: adPlanData.name,
        category: parseInt(destinationCategoryId),
      };

      updateHandler.mutate({
        data: updateData,
        isLast: i === selectedAds?.length - 1,
      });
    }
  };

  const updatePlanState = async (newState) => {
    for (let i = 0; i < selectedAds?.length; i++) {
      let adPlanData = stats.find((ad) => ad.ad_id === selectedAds[i]);

      let state;

      if (newState === "paused") {
        state = STATUS.PAUSE.value;
      }
      if (newState === "active") {
        state = STATUS.ACTIVE.value;
      }

      let updateData = {
        id: selectedAds[i],
        state: state,
        custom_api_request: "update_advertisement",
        name: adPlanData.name,
      };

      updateHandler.mutate({
        data: updateData,
        isLast: i === selectedAds?.length - 1,
      });
    }
  };

  const updatePlanGoals = async (
    bulkAction = false,
    bulkActionTarget = null,
    changes = {}
  ) => {
    const editData = stats
      ?.filter((row) =>
        Object.keys(changes)
          ?.map((id) => parseInt(id))
          ?.includes(row.id)
      )
      ?.map((row) => {
        return { ...row, ...changes[row.id] };
      });

    if (bulkAction) {
      let adsToBulkUpdate = selectedAds.map((adId) =>
        stats?.find((ad) => ad.id === adId)
      );

      for (let i = 0; i < adsToBulkUpdate.length; i++) {
        let { actionType, actionValue, actionUnit } = bulkActionFormData;
        let adData = adsToBulkUpdate[i];

        const { max_acos, target_acos } = adData;

        let originalACOSValue;
        let currentROAS;

        let updateData = {
          id: adData.id,
          state: adData.state,
          custom_api_request: "update_advertisement",
          name: adData.name,
          max_acos: adData.max_acos,
          target_acos: adData.target_acos,
          daily_budget: adData?.daily_budget,
        };

        // Below should be revisited. The conversion got tricky within the loop due to the flipped around change directions with bulk actions
        // Ideally, we could add support for ROAS updates to the advertisement update view
        let resetActionTargetValue;

        if (bulkActionTarget === "min_roas") {
          resetActionTargetValue = "min_roas";
          // Revise to max_acos as target, convert values
          bulkActionTarget = "max_acos";
          if (actionType === "specify") {
            actionValue = (1 / parseFloat(actionValue)) * 100;
          }

          if (["increase", "decrease"]?.includes(actionType)) {
            originalACOSValue = adData.max_acos / 100;
            currentROAS = 1 / originalACOSValue;

            let newValue = (1 / (currentROAS + parseFloat(actionValue))) * 100;
            actionValue = max_acos - newValue;
            actionType = actionType === "decrease" ? "increase" : "decrease";
          }

          actionUnit = "percent";
        }

        if (bulkActionTarget === "target_roas") {
          resetActionTargetValue = "target_roas";
          // Revise to max_acos as target, convert values
          bulkActionTarget = "target_acos";

          if (actionType === "specify") {
            actionValue = (1 / parseFloat(actionValue)) * 100;
          }
          if (["increase", "decrease"]?.includes(actionType)) {
            originalACOSValue = adData.target_acos / 100;
            currentROAS = 1 / originalACOSValue;

            let newValue = (1 / (currentROAS + parseFloat(actionValue))) * 100;

            actionValue = target_acos - newValue;
            actionType = actionType === "decrease" ? "increase" : "decrease";
          }
          actionUnit = "percent";
        }

        updateData[bulkActionTarget] = updateValues(
          adData[bulkActionTarget],
          actionValue,
          actionUnit,
          actionType,
          bulkActionTarget.includes("acos") ? "percent" : "currency"
        );

        if (resetActionTargetValue) {
          bulkActionTarget = resetActionTargetValue;
        }
        updateHandler.mutate({
          data: updateData,
          isLast: i === selectedAds.length - 1,
        });
      }
      return;
    }

    for (let i = 0; i < editData.length; i++) {
      let adPlanData = stats?.find((ad) => ad.ad_id === editData[i].id);

      let updateData = {
        id: editData[i]?.id,
        state: editData[i].state,
        custom_api_request: "update_advertisement",
        name: adPlanData.name,
        max_acos: editData[i]?.max_acos,
        target_acos: editData[i]?.target_acos,
        daily_budget: editData[i]?.daily_budget,
      };

      updateHandler.mutate({
        data: updateData,
        isLast: i === editData.length - 1,
      });
    }
  };

  const data = useMemo(() => {
    const data =
      stats?.map((p) => {
        p.target_roas = +((1 / p["target_acos"]) * 100).toFixed(2);
        p.min_roas = +((1 / p["max_acos"]) * 100).toFixed(2);

        return { ...p };
      }) ?? [];

    return data;
  }, [stats]);

  const customTags = useMemo(() => {
    return [...new Set(data?.map((ad) => ad.tags)?.flat())] ?? [];
  }, [data]);

  const bulkActionOptions = [
    {
      label: `Pause Media Plan${selectedAds?.length > 1 ? "s" : ""}`,
      action: () => updatePlanState("paused"),
      confirmationMessage: (
        <p className="fs-4 py-2">
          Are you sure you want to pause the selected media plans?
        </p>
      ),
    },
    {
      label: `Enable Media Plan${selectedAds?.length > 1 ? "s" : ""}`,
      action: () => updatePlanState("active"),
      confirmationMessage: (
        <p className="fs-4 py-2">
          Are you sure you want to enable the selected media plans?
        </p>
      ),
    },
    acosOrRoas(
      preferences,
      {
        label: "Update Target ACOS",
        action: () => updatePlanGoals(true, "target_acos"),
        confirmationMessage: (
          <p className="fs-4 py-2">
            Are you sure you want to update the target ACOS for the selected
            media plans?
          </p>
        ),
        actionFormFields: (
          <BulkAdPlanActionForm
            formData={bulkActionFormData}
            setFormData={setBulkActionFormData}
            actionTarget={"Target ACOS"}
            hideCurrencyOption={true}
            forcePercentOption={true}
          />
        ),
        // hidden: categoryDetails?.name && categoryDetails?.name === "Unmanaged",
      },
      {
        label: "Update Target ROAS",
        action: () => updatePlanGoals(true, "target_roas"),
        confirmationMessage: (
          <p className="fs-4 py-2">
            Are you sure you want to update the target ROAS for the selected
            media plans?
          </p>
        ),
        actionFormFields: (
          <BulkAdPlanActionForm
            formData={bulkActionFormData}
            setFormData={setBulkActionFormData}
            actionTarget={"Target ROAS"}
            hideCurrencyOption={true}
            hidePercentOption={true}
          />
        ),
        // hidden: categoryDetails?.name && categoryDetails?.name === "Unmanaged",
      }
    ),
    acosOrRoas(
      preferences,
      {
        label: "Update Max ACOS",
        action: () => updatePlanGoals(true, "max_acos"),
        confirmationMessage: (
          <p className="fs-4 py-2">
            Are you sure you want to update the maximum ACOS for the selected
            media plans?
          </p>
        ),
        actionFormFields: (
          <BulkAdPlanActionForm
            formData={bulkActionFormData}
            setFormData={setBulkActionFormData}
            actionTarget={"Max ACOS"}
            hideCurrencyOption={true}
            forcePercentOption={true}
          />
        ),
        // hidden: categoryDetails?.name && categoryDetails?.name === "Unmanaged",
      },
      {
        label: "Update Min ROAS",
        action: () => updatePlanGoals(true, "min_roas"),
        confirmationMessage: (
          <p className="fs-4 py-2">
            Are you sure you want to update the maximum ROAS for the selected
            media plans?
          </p>
        ),
        actionFormFields: (
          <BulkAdPlanActionForm
            formData={bulkActionFormData}
            setFormData={setBulkActionFormData}
            actionTarget={"Min ROAS"}
            hideCurrencyOption={true}
            hidePercentOption={true}
          />
        ),
        // hidden: categoryDetails?.name && categoryDetails?.name === "Unmanaged",
      }
    ),
    {
      label: "Update Budget",
      action: () => updatePlanGoals(true, "daily_budget"),
      confirmationMessage: (
        <p className="fs-4 py-2">
          Are you sure you want to update the daily budget for the selected
          media plans?
        </p>
      ),
      actionFormFields: (
        <BulkAdPlanActionForm
          formData={bulkActionFormData}
          setFormData={setBulkActionFormData}
          actionTarget={"Daily Budget"}
          hidePercentOption={bulkActionFormData?.actionType === "specify"}
        />
      ),
    },
    {
      label: "Schedule Budget Updates",
      action: () => scheduleBudgetUpdates(),
      confirmationMessage: (
        <p className="fs-4 py-2">
          {!parseFloat(bulkActionFormData?.actionValue) ||
          !bulkActionFormData?.actionType ||
          !bulkActionFormData?.actionUnit ||
          !bulkActionFormData?.startDate ||
          !bulkActionFormData?.endDate
            ? "Please fill out all required fields."
            : `Are you sure you want to ${
                bulkActionFormData?.actionType
              } the daily budget by ${
                bulkActionFormData?.actionUnit === "percent"
                  ? `${Math.round(Math.abs(bulkActionFormData?.actionValue))}%`
                  : formatCurrency(
                      Math.abs(bulkActionFormData?.actionValue),
                      marketPlace
                    )
              } for the selected media plans from ${moment(
                bulkActionFormData?.startDate
              ).format("MMMM D")} to ${moment(
                bulkActionFormData?.endDate
              ).format("MMMM D")}  (${
                (moment(bulkActionFormData?.endDate).diff(
                  bulkActionFormData?.startDate,
                  "days"
                ) +
                  1) *
                24
              } hours)?`}
        </p>
      ),

      actionFormFields: (
        <BulkAdPlanActionForm
          formData={bulkActionFormData}
          setFormData={setBulkActionFormData}
          actionTarget={"Daily Budget"}
          schedulingDialogue={true}
        />
      ),
      disableConfirmButton:
        !parseFloat(bulkActionFormData?.actionValue) ||
        !bulkActionFormData?.actionType ||
        !bulkActionFormData?.actionUnit ||
        !bulkActionFormData?.startDate ||
        !bulkActionFormData?.endDate,
    },
    {
      label: `Update Plan ${
        selectedAds?.length > 1 ? "Categories" : "Category"
      }`,
      action: () => updatePlanCategories(),
      confirmationMessage: (
        <p className="fs-4 py-2">
          Are you sure you want to update the category for the{" "}
          {selectedAds?.length > 1
            ? `${selectedAds?.length} selected media plans`
            : "selected media plan"}
          ?
        </p>
      ),
      actionFormFields: (
        <div className="pb-5">
          <p className="pb-4 fs-4">
            Please specify the destination category you would like to move the
            selected media plan{selectedAds?.length > 1 ? "s" : ""} to.
          </p>
          <div className="d-flex justify-content-center">
            <CategoryField
              categories={categories}
              dashboard={true}
              onChange={(value) => {
                setDestinationCategoryId(value.categoryId);
              }}
              state={{
                updatedCategoryId: destinationCategoryId
                  ? destinationCategoryId
                  : categoryDetails?.id,
              }}
              errors={{}}
              channel={channel}
              marketPlace={marketPlace}
              existingAdCategorySelector={true}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Add Media Plan Tags",
      action: () => {
        updatePlanTags();
      },
      actionFormFields: (
        <BulkTagsForm
          filledTags={filledTags}
          setFilledTags={setFilledTags}
          selected={selectedAds}
          suggestedTags={customTags}
          taggedItemDescription={"media plan"}
          actionType={"add"}
        />
      ),
      confirmationMessage: (
        <p>
          Are you sure you want to add{" "}
          {filledTags?.length > 1 ? "these tags" : "this tag"} from the selected
          media plan
          {selectedAds?.length > 1 ? "s" : ""}?
        </p>
      ),
      disableConfirmButton: !filledTags.length,
    },
    {
      label: "Remove Media Plan Tags",
      action: () => {
        updatePlanTags("remove");
      },
      actionFormFields: (
        <BulkTagsForm
          filledTags={filledTags}
          setFilledTags={setFilledTags}
          selected={selectedAds}
          suggestedTags={customTags}
          taggedItemDescription={"media plan"}
          actionType={"remove"}
        />
      ),
      confirmationMessage: (
        <p>
          Are you sure you want to remove{" "}
          {filledTags?.length > 1 ? "these tags" : "this tag"} from the selected
          media plan
          {selectedAds?.length > 1 ? "s" : ""}?
        </p>
      ),
      disableConfirmButton: !filledTags.length,
    },
  ];

  const ADDITIONAL_FILTERS = [
    AD_TYPE_FILTER,
    AD_STATUS_FILTER,
    AD_SOURCE_FILTER,
    NTM_STATUS_FILTER,
    SCHEDULED_BUDGET_CHANGES_FILTER,
    buildCustomTagsFilter(customTags),
  ];

  const INITIAL_FILTERS = [{ ...AD_STATUS_FILTER, value: [1] }];

  const filterProps = useCustomTableFilters(
    marketPlace.marketPlace,
    TABLE_ID,
    INITIAL_FILTERS
  );

  if (updateHandler.isLoading) {
    return (
      <div className="mt-5">
        <Loading />
      </div>
    );
  }

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      <BulkActionsTable
        tableRef={tableRef}
        tableId={TABLE_ID}
        tableEntityType={"mediaPlan"}
        titleCol={{
          id: "advertisement",
          isStatic: true,
          Header: "Advertisement",
          accessor: "name",
          style: { width: smallViewportWidthTable ? "250px" : "350px" },
          width: smallViewportWidthTable ? 250 : 350,
          Cell: ({ value, row }) => {
            return (
              <AdvertisementCell
                value={value}
                row={row}
                original={{
                  ...row._original,
                  id: row._original?.product_id,
                }}
                seasonalCategory={categoryDetails?.seasonalCategory}
                modules={modules}
              />
            );
          },
          filterMethod: (filter, row) => {
            return;
          },
          Filter: ({ filter, onChange }) => null,
        }}
        columnSpecs={[
          {
            key: "state",
            compareKey: null,

            options: {
              editCell: true,
              hideFilter: false,
              filterType: "select",
              selectOptions: [
                {
                  value: 1,
                  label: "Active",
                },
                {
                  value: 0,
                  label: "Inactive",
                },
              ],
              format: "toggle",
              onValue: 1,
              offValue: 0,
              width: 150,
              className: "d-flex justify-content-end",
              columnTitle: "Plan Status",
            },
          },
          {
            key: "daily_budget",
            compareKey: null,
            options: {
              editCell: true,
              minValue: 1,
              columnTitle: "Daily Budget",
              className: "text-end",
            },
          },
          acosOrRoas(
            preferences,
            {
              key: "target_acos",
              compareKey: null,
              options: {
                editCell: true,
                minValue: 0,
                columnTitle: "Target ACOS",
                className: "text-end",
                format: "percent",
                renderPlaceholderCellContent: (row) => {
                  const nonManaged =
                    row._original?.ad_goal === GOALS.IMPORTED_ADS.value;
                  const enrolled = row._original?.non_trellis_enrolled_ad;
                  // Dont show data for this field if ad is non-managed and not enrolled
                  if (nonManaged && !enrolled) {
                    return true;
                  }
                  return false;
                },
              },
            },
            {
              key: "target_roas",
              compareKey: null,
              options: {
                editCell: true,
                minValue: 0,
                columnTitle: "Target ROAS",
                className: "text-end",
                format: "number",
                renderPlaceholderCellContent: (row) => {
                  const nonManaged =
                    row._original?.ad_goal === GOALS.IMPORTED_ADS.value;
                  const enrolled = row._original?.non_trellis_enrolled_ad;
                  // Dont show data for this field if ad is non-managed and not enrolled
                  if (nonManaged && !enrolled) {
                    return true;
                  }
                  return false;
                },
              },
            }
          ),
          acosOrRoas(
            preferences,
            {
              key: "max_acos",
              compareKey: null,
              options: {
                editCell: true,
                minValue: 0,
                columnTitle: "Max ACOS",
                className: "text-end",
                format: "percent",
                renderPlaceholderCellContent: (row) => {
                  const nonManaged =
                    row._original?.ad_goal === GOALS.IMPORTED_ADS.value;
                  const enrolled = row._original?.non_trellis_enrolled_ad;
                  // Dont show data for this field if ad is non-managed and not enrolled
                  if (nonManaged && !enrolled) {
                    return true;
                  }
                  return false;
                },
              },
            },
            {
              key: "min_roas",
              compareKey: null,
              options: {
                editCell: true,
                minValue: 0,
                columnTitle: "Min ROAS",
                className: "text-end",
                format: "number",
                renderPlaceholderCellContent: (row) => {
                  const nonManaged =
                    row._original?.ad_goal === GOALS.IMPORTED_ADS.value;
                  const enrolled = row._original?.non_trellis_enrolled_ad;
                  // Dont show data for this field if ad is non-managed and not enrolled
                  if (nonManaged && !enrolled) {
                    return true;
                  }
                  return false;
                },
              },
            }
          ),
          { key: "ad_sales", compareKey: "ad_sales_percent" },
          { key: "ad_spend", compareKey: "ad_spend_percent" },
          {
            key: "cost",
            compareKey: "cost_percent",
            options: {
              noColor: true,
              checked: false,
            },
          },
          {
            key: "roas",
            compareKey: "roas_delta",
            options: {
              checked: acosOrRoas(preferences, false, true),
            },
          },
          {
            key: "acos",
            compareKey: "acos_delta",
            options: {
              checked: acosOrRoas(preferences, true, false),
              isNegative: true,
            },
          },
          { key: "clicks", compareKey: "clicks_percent" },
          { key: "impressions", compareKey: "impressions_percent" },
          {
            key: "ctr",
            compareKey: "ctr_delta",
            options: {
              checked: false,
              decimalPlaces: 2,
            },
          },
          {
            key: "orders",
            compareKey: "orders_percent",
            options: {
              checked: false,
            },
          },
          {
            key: "conversion_rate",
            compareKey: "conversion_rate_delta",
            options: {
              checked: false,
              decimalPlaces: 1,
            },
          },
          {
            key: "cpc",
            compareKey: "cpc_delta",
            options: {
              checked: false,
              noColor: true,
              clipDecimalPlaces: false,
            },
          },
          {
            key: "serving_hours",
            compareKey: "serving_hours_delta",
            options: {
              checked: false,
            },
          },
          buildDateColumn("created_datetime", "Created On"),
        ]}
        minRows={0}
        defaultPageSize={10}
        data={data}
        selected={selectedAds}
        setSelected={setSelectedAds}
        selectionKey="ad_id"
        bulkActionOptions={bulkActionOptions}
        getSearchCriteria={(row) => {
          const { name, ad_name, id, ad_type, tags } = row;
          const adType = getAdTypeName(ad_type);
          return `${name} ${ad_name} ${id} ${adType} ${tags?.join(" ")}`;
        }}
        additionalFilterOptions={ADDITIONAL_FILTERS}
        excludedFilterColumns={["state"]}
        defaultSorted={[{ id: "ad_sales", desc: true }]}
        isLoading={isLoading}
        isSelectable={true}
        isExpandable={true}
        filterWidget={true}
        hideFilterToggleIcon={true}
        hideResetFiltersIcon={false}
        {...filterProps}
        initialFilters={INITIAL_FILTERS}
        handleBulkChanges={(changes) => updatePlanGoals(false, null, changes)}
        ExpandedContent={({ row }) => {
          return (
            <AdvertisementExpander
              row={row.row}
              marketPlace={marketPlace}
              channel={channel}
            />
          );
        }}
      />
    </>
  );
};

export { AdvertisementCell, AdvertisementExpander };

export default AdvertisementsTable;
