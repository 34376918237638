import { snakeCaseToSentenceCase } from "utils/formatText";

const KEYWORD_MATCH_TYPE_OPTIONS = [
  { value: "phrase", label: "Phrase" },
  { value: "broad", label: "Broad" },
  { value: "exact", label: "Exact" },
  { value: "negativePhrase", label: "Negative Phrase" },
  { value: "negativeExact", label: "Negative Exact" },
];
const TARGET_MATCH_TYPE_OPTIONS = [
  { value: "auto", label: "Auto" },
  { value: "product", label: "Product" },
  { value: "category", label: "Category" },
  { value: "dynamic", label: "Dynamic" },
  { value: "expression", label: "Expression" },
];

const SEARCH_TERM_REPORT_MATCH_TYPE_OPTIONS = [
  { value: "phrase", label: "Phrase" },
  { value: "broad", label: "Broad" },
  { value: "exact", label: "Exact" },
];

const getManualBidFilterOptions = (tableId) => {
  return [
    {
      value: "withManualBids",
      label: `${
        tableId === "keyword" ? "Keywords" : "Targets"
      } with Fixed Bids`,
    },
    {
      value: "withSuppressedBudget",
      label: `${
        tableId === "keyword" ? "Keywords" : "Targets"
      } with Constrained Bids`,
    },
    {
      value: "withScheduledActions",
      label: `${
        tableId === "keyword" ? "Keywords" : "Targets"
      } with Scheduled Bid Boost`,
    },
  ];
};

export const getTargetBidTypeFilter = (tableId) => {
  const BID_TYPE_OPTIONS = getManualBidFilterOptions(tableId);

  return {
    label: "Bid Type",
    id: "bid_type",
    format: "select",
    selectOptions: [
      {
        label: `All ${snakeCaseToSentenceCase(tableId)}s`,
        value: "all",
        clearable: false,
      },
      ...BID_TYPE_OPTIONS,
    ],
    override: true,
    defaultValue: "all",
    operator: "custom",
    filterFunction: (target, filterValue) => {
      if (filterValue !== "all") {
        if (filterValue === "withManualBids") {
          return target["manual_bid"] > 0;
        }
        if (filterValue === "withSuppressedBudget") {
          return target["budget_suppression"];
        }
        if (filterValue === "withScheduledActions") {
          return target["scheduled_actions"]?.length;
        }
      }
      return true;
    },
  };
};

export const BID_PREVIEW_WARNING_FILTER = {
  label: "Bid Preview",
  id: "bid_warning",
  format: "select",
  selectOptions: [
    {
      label: "All",
      value: "all",
      clearable: false,
    },
    {
      label: "Bids with warning",
      value: "bids_with_warning",
      clearable: true,
    },
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (target, filterValue) => {
    const highBid = target.preview_bid > target.target_bid * 1.5;
    const lowBid = target.preview_bid < target.target_bid * 0.5;
    if (filterValue === "bids_with_warning") {
      return lowBid || highBid;
    }
    return true;
  },
};

export const KEYWORD_MATCH_TYPE_FILTER = {
  label: "Match Type",
  id: "match_type",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Match Types",
    },
    ...KEYWORD_MATCH_TYPE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (keyword, filterValue) => {
    if (filterValue !== "all") {
      return keyword.match_type === filterValue;
    }
    return true;
  },
};

export const SEARCH_TERM_REPORT_MATCH_TYPE_FILTER = {
  label: "Match Type",
  id: "match_type",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Match Types",
    },
    ...SEARCH_TERM_REPORT_MATCH_TYPE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (keyword, filterValue) => {
    if (filterValue !== "all") {
      return keyword.match_type.toLowerCase() === filterValue.toLowerCase();
    }
    return true;
  },
};

export const TARGET_MATCH_TYPE_FILTER = {
  label: "Match Type",
  id: "match_type",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Match Types",
    },
    ...TARGET_MATCH_TYPE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (keyword, filterValue) => {
    if (filterValue !== "all") {
      return keyword.match_type.toLowerCase() === filterValue.toLowerCase();
    }
    return true;
  },
};

// Target Source
export const getTargetSourceFilter = (channel) => {
  return {
    label: "Source",
    id: "target_source",
    format: "select",
    selectOptions:
      channel === "walmart"
        ? [
            {
              value: "all",
              label: "All Sources",
            },
            { value: "user", label: "User" },
            { value: "system", label: "System" },
            { value: "GV_DISCOVERY", label: "Initial" },
            { value: "harvested", label: "Harvesting" },
          ]
        : [
            {
              value: "all",
              label: "All Sources",
            },
            { value: "GV_DISCOVERY", label: "Initial" },
            { value: "harvested", label: "Harvesting" },
          ],
    override: true,
    defaultValue: "all",
    operator: "custom",
    filterFunction: (target, filterValue) => {
      if (filterValue !== "all") {
        return target.target_source.toLowerCase() === filterValue.toLowerCase();
      }
      return true;
    },
  };
};

// Target State
export const TARGET_STATE_FILTER = {
  label: "State",
  id: "target_state",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All States",
    },
    {
      value: "enabled",
      label: "Enabled",
    },
    {
      value: "paused",
      label: "Paused",
    },
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (target, filterValue) => {
    if (filterValue !== "all") {
      return target.target_state.toLowerCase() === filterValue.toLowerCase();
    }
    return true;
  },
};
