import moment from "moment";
import { GROUPS, COMPARES, DEFAULTS } from "./constants";

const selectDatesDay = ({ period, compare, start, end }) => {
  const endOfDay = end.clone();
  if (period.id === "month") {
    start = end.clone().startOf("month");
  } else {
    start =
      period.id === "custom" && start
        ? start
        : end.clone().subtract(period.periods - 1, "days");
  }
  let preStart, preEnd;
  if (compare.id === "mom") {
    preEnd = start.clone().subtract(1, "days");
    preStart = preEnd.clone().subtract(end.diff(start, "days"), "days");
  } else {
    // YOY
    preStart = start.clone().subtract(1, "year");
    preEnd = end.clone().subtract(1, "year");
  }
  return { start, end: endOfDay, preStart, preEnd };
};

const selectDatesWeek = ({ period, compare, start, end }) => {
  const endOfWeek = end.clone().endOf("iweek");
  start =
    period.id === "custom" && start
      ? start
      : end
          .clone()
          .subtract(period.periods - 1, "weeks")
          .startOf("iweek");
  let preStart, preEnd;
  if (compare.id === "mom") {
    preEnd = start.clone().subtract(1, "days");
    preStart = preEnd
      .clone()
      .subtract(end.diff(start, "weeks"), "weeks")
      .startOf("iweek");
  } else {
    // YOY
    preEnd = end.clone().subtract(1, "year").endOf("iweek");
    preStart = start.clone().subtract(1, "year").startOf("iweek");
  }
  return { start, end: endOfWeek, preStart, preEnd };
};

const selectDatesMonth = ({ period, compare, start, end }) => {
  const endOfMonth = end.clone().endOf("month");
  start =
    period.id === "custom" && start
      ? start
      : end
          .clone()
          .subtract(period.periods - 1, "month")
          .startOf("month");
  let preStart, preEnd;
  if (compare.id === "mom") {
    preEnd = start.clone().subtract(1, "days");
    preStart = preEnd
      .clone()
      .subtract(end.diff(start, "months"), "months")
      .startOf("month");
  } else {
    // YOY
    preEnd = end.clone().subtract(1, "year").endOf("month");
    preStart = start.clone().subtract(1, "year").startOf("month");
  }
  return { start, end: endOfMonth, preStart, preEnd };
};

const selectDates = ({ group, period, compare, start, end }) => {
  start = start ? moment(start) : null;
  group = GROUPS.find((g) => g.id === group);
  period = group.periods.find((p) => p.id === period);
  compare = COMPARES.find((c) => c.id === compare);
  if (group.id === "day") {
    return selectDatesDay({ period, compare, start, end });
  }
  if (group.id === "week") {
    return selectDatesWeek({ period, compare, start, end });
  }
  if (group.id === "month") {
    return selectDatesMonth({ period, compare, start, end });
  }
};

export const generateUrl = (to, { group, period, compare, start, end }) => {
  const [url, paramString] = to?.split("?") ?? ["", ""];
  const params = new URLSearchParams(paramString || "");

  if (group && !params.has("group") && group !== DEFAULTS.group)
    params.append("group", group);
  if (period && !params.has("period") && period !== DEFAULTS.period)
    params.append("period", period);
  if (compare && !params.has("compare") && compare !== DEFAULTS.compare)
    params.append("compare", compare);
  if (period === "custom") {
    if (start && !params.has("start"))
      params.append("start", start?.format("YYYY-MM-DD"));
    if (end && !params.has("end"))
      params.append("end", end?.format("YYYY-MM-DD"));
  }

  return `${url}?${params.toString()}`;
};

export default selectDates;
