import React from "react";
import { trellisPalette } from "../analytics/palettes";
import AnnotationChartTemplate from "./AnnotationChartTemplate";

const ChartMiddleware = ({
  options: { options, series },
  rollup,
  data,
  height,
  selectedProduct,
  selectedCategory,
  selectedAd,
  marketPlace,
  yLabels,
  context,
  setAnnotationData,
  showAnnotations,
  isChartDataLoading = false,
  datesFormatted,
  labelSpan = null,
  handleLegendChange,
}) => {
  // Core performance metrics
  const metrics = [
    {
      name: "Impressions",
      id: "Impressions",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_impressions"
          : rollup?.rollup === "weekly"
          ? "weekly_impressions"
          : "monthly_impressions",
      type: "column",
      color: "#A78DC2",
      yAxis: 0,
    },
    {
      name: "Units",
      id: "Units",
      data: [],
      accessor: "units",
      type: "column",
      color: "#A78DC2",
      yAxis: 0,
    },
    {
      name: "Clicks",
      id: "Clicks",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_clicks"
          : rollup?.rollup === "weekly"
          ? "weekly_clicks"
          : "monthly_clicks",
      type: "line",
      color: "#D5C1E0",
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 2,
    },
    {
      name: "Ad Sales",
      id: "Ad Sales",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_attributed_sales_7d"
          : rollup?.rollup === "weekly"
          ? "weekly_attributed_sales_7d"
          : "monthly_attributed_sales_7d",
      type: "area",
      color: "#C43066",
      fillOpacity: 0.1,
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 3,
    },
    {
      name: "Total Sales",
      id: "Total Sales",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_total_sales"
          : rollup?.rollup === "weekly"
          ? "weekly_total_sales"
          : "monthly_total_sales",
      type: "area",
      color: "#E172AF",
      fillOpacity: 0.1,
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 3,
    },
    {
      name: "Ad Spend",
      id: "Ad Spend",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_cost"
          : rollup?.rollup === "weekly"
          ? "weekly_cost"
          : "monthly_cost",
      type: "area",
      color: "#E172AF",
      fillOpacity: 0.1,
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 3,
    },
    {
      name: "ACOS",
      id: "ACOS",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_attributed_acos_7d"
          : rollup?.rollup === "weekly"
          ? "weekly_attributed_acos_7d"
          : "monthly_attributed_acos_7d",
      type: "area",
      color: "#563776",
      fillOpacity: 0.1,
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 4,
    },
    {
      name: "Total ACOS",
      id: "Total ACOS",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_total_acos"
          : rollup?.rollup === "weekly"
          ? "weekly_total_acos"
          : "monthly_total_acos",
      type: "area",
      color: "#E172AF",
      fillOpacity: 0.1,
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 4,
    },
    {
      name: "ROAS",
      id: "ROAS",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_attributed_roas_7d"
          : rollup?.rollup === "weekly"
          ? "weekly_attributed_roas_7d"
          : "monthly_attributed_roas_7d",
      type: "area",
      color: "#563776",
      fillOpacity: 0.1,
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 4,
    },
    {
      name: "Total ROAS",
      id: "Total ROAS",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_total_roas"
          : rollup?.rollup === "weekly"
          ? "weekly_total_roas"
          : "monthly_total_roas",
      type: "area",
      color: "#E172AF",
      fillOpacity: 0.1,
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 4,
    },
    {
      name: "BSR",
      id: "BSR",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_bsr_rank"
          : rollup?.rollup === "weekly"
          ? "weekly_avg_bsr_rank"
          : "monthly_avg_bsr_rank",
      type: "line",
      color: "#563776",
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 5,
    },
    {
      name: "Page Views",
      id: "Page Views",
      data: [],
      accessor: "page_view",
      type: "line",
      color: "#563776",
      marker: {
        symbol: "circle",
        fillColor: "#563776",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 2,
    },
    {
      name: "Add To Cart",
      id: "Add To Cart",
      data: [],
      accessor: "add_to_cart",
      type: "line",
      color: "#E172AF",
      marker: {
        symbol: "circle",
        fillColor: "#E172AF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 2,
    },
    {
      name: "Begin Checkout",
      id: "Begin Checkout",
      data: [],
      accessor: "begin_checkout",
      type: "line",
      color: "#E172AF",
      marker: {
        symbol: "circle",
        fillColor: "#E172AF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 2,
    },
    {
      name: "Price",
      id: "Price",
      data: [],
      accessor: "price",
      type: "line",
      color: "#E172AF",
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 3,
    },
    {
      name: "Product Price",
      id: "Product Price",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_product_price"
          : rollup?.rollup === "weekly"
          ? "weekly_avg_product_price"
          : "monthly_avg_product_price",
      type: "line",
      color: trellisPalette[0],
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 6,
    },
    {
      name: "Units Ordered",
      id: "Units Ordered",
      data: [],
      accessor:
        rollup?.rollup === "daily"
          ? "daily_total_units_ordered"
          : rollup?.rollup === "weekly"
          ? "weekly_total_units_ordered"
          : "monthly_total_units_ordered",
      type: "column",
      color: trellisPalette[11],
      marker: {
        symbol: "circle",
        fillColor: "#FFF",
        lineWidth: 1,
        radius: 2,
        lineColor: null,
      },
      yAxis: 0,
    },
  ];

  const chartOptions = {
    ...options,
    ...{
      series: series.map((s) => metrics.find((m) => m.name === s) ?? s),
    },
  };

  return (
    <AnnotationChartTemplate
      options={chartOptions}
      rollup={rollup}
      selectedProduct={selectedProduct}
      selectedCategory={selectedCategory}
      selectedAd={selectedAd}
      height={height}
      yLabels={yLabels}
      context={context}
      updateAnnotationData={setAnnotationData}
      showAnnotations={showAnnotations}
      isChartDataLoading={isChartDataLoading}
      labelSpan={labelSpan}
      handleLegendChange={handleLegendChange}
    />
  );
};

export default ChartMiddleware;
