import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Alert } from "react-bootstrap";
import { SecondaryButton } from "../../components/core/basic/Button";
import FormGroupTile from "components/core/basic/FormGroupTile";
import api from "utils/api";
import CustomHref from "components/core/blocks/CustomHref";
import Card from "components/core/basic/Card";
import ReactTable from "react-table-6";

const GooglePreferences = () => {
  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState(null);
  const [currentActions, setCurrentActions] = useState(null);
  const [weightValue, setWeightValue] = useState(1.0);
  const [saving, setSaving] = useState(false);

  const [conversionSnippets, setConversionSnippets] = useState([]);

  const [clearFilters, setClearFilters] = useState(false);

  const fetchActions = async () => {
    const { data = null } = await api.get("/google/funnel_action");
    const actions = {};

    Object.keys(data.conversion_actions).forEach((k) => {
      actions[k] = [];

      data.conversion_actions[k].forEach((v) => {
        actions[k].push({
          value: v,
          label: v,
        });
      });
    });

    setActions(actions);
    setCurrentActions(data.current_actions);
    setLoading(false);

    let val = 0;
    Object.keys(data.current_actions).forEach((x) => {
      val += parseFloat(data.current_actions[x].weight);
    });

    setWeightValue(val);
  };

  const fetchConversionSnippets = async () => {
    const { data = null } = await api.get("/google/snippets");
    var conversionSnippets = [];
    conversionSnippets = data;

    // Object.keys(data.conversion_actions).forEach((k) => {
    //   data.conversion_actions[k].forEach((v) => {
    //     actions[k].push({
    //       value: v,
    //       label: v,
    //     });
    //   });
    // });

    setConversionSnippets(conversionSnippets);
  };

  const setCurrentActionLabel = (action, value) => {
    const ca = { ...currentActions };
    ca[action].label = value.value;
    setCurrentActions(ca);
  };

  const setCurrentActionWeight = (action, value) => {
    const ca = { ...currentActions };
    ca[action].weight = value;
    setCurrentActions(ca);

    let val = 0;
    Object.keys(ca).forEach((x) => {
      val += parseFloat(ca[x].weight);
    });

    setWeightValue(val);
  };

  const saveFunnelActions = async () => {
    const params = {};

    if (weightValue === 1.0) {
      setSaving(true);
      await api.post("/google/funnel_action", currentActions, params);
      setSaving(false);
    }
  };

  const refreshProducts = async () => {
    await api.post("/google/refresh");
  };

  const columns = [
    {
      Header: "Name",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "leftg" },
      accessor: "name",
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
    },
    {
      Header: "Category",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "category",
      Cell: (props) => {
        return props.original.category;
      },
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
    },
    {
      Header: "Conversion Snippet",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "conversion_snippets",
      Cell: (props) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(props.original.conversion_snippets)
              );
            }}
          >
            <u>Copy!</u>
          </div>
        );
      },
      filterMethod: (filter, row) => {},
      Filter: ({ filter, onChange }) => null,
      width: 180,
    },
  ];

  // On load get the current user's enable GSuite account(s).
  // This is currently hardcoded to just get the first Google Auth account returned.
  useEffect(() => {
    fetchActions();
    fetchConversionSnippets();
  }, []);

  return (
    <>
      <div className="container">
        <Row>
          <Col className={"s8 offset-s2 text-center"}>
            <h4>
              <b>Google Shopping Preferences</b>
            </h4>

            <Row>
              <Col className="text-start">
                <h6>Account Actions</h6>
              </Col>
              <Col className="text-start">
                <span
                  style={{ fontSize: "14px", fontWeight: 500 }}
                  onClick={(e) => refreshProducts()}
                >
                  <CustomHref text={"Refresh Products"} />
                </span>
              </Col>
            </Row>

            <Row>
              <Col className="text-start">
                <h6>Conversion Actions</h6>
                <p>
                  <small>
                    Please let us know which Google Conversion you want to use
                    to track each step of the purchase funnel.
                  </small>
                </p>
                {weightValue !== 1.0 && (
                  <Alert variant={`danger`} className={"text-center"}>
                    <div className={`pt-2 pb-2`}>
                      The weights of your Conversion Actions must be equal to{" "}
                      <strong>1.0</strong>.<br />
                      <br />
                      The current weight sum up to {weightValue.toFixed(2)}.
                    </div>
                  </Alert>
                )}
                {saving && (
                  <Alert variant={`danger`} className={"text-center"}>
                    <div className={`pt-2 pb-2`}>
                      Your Conversion Action mappings are being saved
                    </div>
                  </Alert>
                )}
                {!loading && (
                  <>
                    <Row style={{ borderBottom: "1px solid #ccc" }}>
                      <Col xs={5}>
                        <strong>Funnel Stage</strong>
                      </Col>
                      <Col xs={5}>
                        <strong>Conversion Action</strong>
                      </Col>
                      <Col xs={2}>
                        <strong>Weight</strong>
                      </Col>
                    </Row>
                    <Row className="pt-3">
                      <Col xs={5} className="pt-3">
                        Page View
                      </Col>
                      <Col xs={5}>
                        <FormGroupTile
                          type="select"
                          multi={false}
                          stateName={"pageView"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionLabel(
                              "ConversionActionCategory.PAGE_VIEW",
                              value
                            )
                          }
                          defaultValue={actions[
                            "ConversionActionCategory.PAGE_VIEW"
                          ].find(
                            (c) =>
                              c.value ===
                              currentActions[
                                "ConversionActionCategory.PAGE_VIEW"
                              ].label
                          )}
                          options={
                            actions["ConversionActionCategory.PAGE_VIEW"]
                          }
                        />
                      </Col>
                      <Col xs={2}>
                        <FormGroupTile
                          type="formcontrol"
                          formControlType={"text"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionWeight(
                              "ConversionActionCategory.PAGE_VIEW",
                              value
                            )
                          }
                          stateName={"pageViewWeight"}
                          placeholder={`0.025`}
                          defaultValue={
                            currentActions["ConversionActionCategory.PAGE_VIEW"]
                              .weight
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="pt-3">
                      <Col xs={5} className="pt-3">
                        Add To Cart
                      </Col>
                      <Col xs={5}>
                        <FormGroupTile
                          type="select"
                          multi={false}
                          stateName={"addToCart"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionLabel(
                              "ConversionActionCategory.ADD_TO_CART",
                              value
                            )
                          }
                          defaultValue={actions[
                            "ConversionActionCategory.ADD_TO_CART"
                          ].find(
                            (c) =>
                              c.value ===
                              currentActions[
                                "ConversionActionCategory.ADD_TO_CART"
                              ].label
                          )}
                          options={
                            actions["ConversionActionCategory.ADD_TO_CART"]
                          }
                        />
                      </Col>
                      <Col xs={2}>
                        <FormGroupTile
                          type="formcontrol"
                          formControlType={"text"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionWeight(
                              "ConversionActionCategory.ADD_TO_CART",
                              value
                            )
                          }
                          stateName={"addToCartWeight"}
                          placeholder={`0.1725`}
                          defaultValue={
                            currentActions[
                              "ConversionActionCategory.ADD_TO_CART"
                            ].weight
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="pt-3">
                      <Col xs={5} className="pt-3">
                        Begin Checkout
                      </Col>
                      <Col xs={5}>
                        <FormGroupTile
                          type="select"
                          multi={false}
                          stateName={"beginCheckout"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionLabel(
                              "ConversionActionCategory.BEGIN_CHECKOUT",
                              value
                            )
                          }
                          defaultValue={actions[
                            "ConversionActionCategory.BEGIN_CHECKOUT"
                          ].find(
                            (c) =>
                              c.value ===
                              currentActions[
                                "ConversionActionCategory.BEGIN_CHECKOUT"
                              ].label
                          )}
                          options={
                            actions["ConversionActionCategory.BEGIN_CHECKOUT"]
                          }
                        />
                      </Col>
                      <Col xs={2}>
                        <FormGroupTile
                          type="formcontrol"
                          formControlType={"text"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionWeight(
                              "ConversionActionCategory.BEGIN_CHECKOUT",
                              value
                            )
                          }
                          stateName={"beginCheckoutWeight"}
                          placeholder={`0.3`}
                          defaultValue={
                            currentActions[
                              "ConversionActionCategory.BEGIN_CHECKOUT"
                            ].weight
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="pt-3">
                      <Col xs={5} className="pt-3">
                        Purchase
                      </Col>
                      <Col xs={5}>
                        <FormGroupTile
                          type="select"
                          multi={false}
                          stateName={"purchase"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionLabel(
                              "ConversionActionCategory.PURCHASE",
                              value
                            )
                          }
                          defaultValue={actions[
                            "ConversionActionCategory.PURCHASE"
                          ].find(
                            (c) =>
                              c.value ===
                              currentActions[
                                "ConversionActionCategory.PURCHASE"
                              ].label
                          )}
                          options={actions["ConversionActionCategory.PURCHASE"]}
                        />
                      </Col>
                      <Col xs={2}>
                        <FormGroupTile
                          type="formcontrol"
                          formControlType={"text"}
                          handleChildFormElement={(key, value) =>
                            setCurrentActionWeight(
                              "ConversionActionCategory.PURCHASE",
                              value
                            )
                          }
                          stateName={"purchaseWeight"}
                          placeholder={`0.5`}
                          defaultValue={
                            currentActions["ConversionActionCategory.PURCHASE"]
                              .weight
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="pt-3 pb-3">
                      <Col xs={12} className="text-end">
                        <SecondaryButton
                          title={`Save`}
                          onClick={() => saveFunnelActions()}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="text-start">
                <h6>Conversion Snippets</h6>
                <Card
                  content={
                    <ReactTable
                      data={conversionSnippets}
                      getTheadFilterProps={(props) => {
                        if (clearFilters) {
                          props.filtered.forEach((filter, index) => {
                            filter.value = "all";
                          });
                          setClearFilters(false);
                        }
                        return { style: { display: "flex" } };
                      }}
                      columns={columns}
                      defaultPageSize={5}
                      // Below is needed to eliminate the no data message that covers table headers if no data to render
                      NoDataComponent={() => null}
                      minRows={0}
                      showPaginationTop={false}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(withRouter(GooglePreferences));
