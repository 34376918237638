import Button from "@material-ui/core/Button";
import React, { Component } from "react";

export class LoginWithAmazonButton extends Component {
  render() {
    // const {theme} = this.props;
    return (
      <Button
        variant="contained"
        color="secondary"
        // backgroundColor="#d91366"
        size="large"
        {...this.props}
      />
    );
  }
}

export class LoginWithAmazonLink extends Component {
  render() {
    return <button className="login_here" {...this.props} />;
  }
}
