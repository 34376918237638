import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import getMetricSpec from "views/merchandise/metrics";
import DetailedMetricRow from "components/core/blocks/DetailedMetricRow";
import WorkflowIcon from "assets/images/icons/workflow.svg";
import ThemeLine from "../ThemeLine";

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  metric.targetLabel = "vs. Previous";
  return metric;
};

const NPICard = ({ marketPlace, category = null }) => {
  const { start, end, preStart, preEnd, group } = useDates();
  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    filters: "npi_enrolled",
  };
  if (category) filters.category = category;

  const { data, isLoading } = useFetch(
    [
      "productReport",
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      "npi_enrolled",
      category,
      group,
    ],
    `/api/data_report/product_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const metrics = useMemo(() => {
    return [
      buildMetric(marketPlace.marketPlace, data, "sales", "sales_percent", {
        metric: {
          name: "Enrolled Sales",
          toolTip:
            "Total Sales from products enrolled in New Product Introduction",
        },
      }),
      buildMetric(marketPlace.marketPlace, data, "units", "units_percent"),
      buildMetric(marketPlace.marketPlace, data, "rating", "rating_percent", {
        metric: {
          decimalPlaces: 1,
          name: "Rating",
          toolTip:
            "The average rating across all products enrolled in New Product Introduction",
          totalOverrideAccessor: "last_rating",
        },
      }),
      buildMetric(
        marketPlace.marketPlace,
        data,
        "ratings_total",
        "ratings_total_percent",
        {
          metric: {
            name: "# of Ratings",
            toolTip:
              "The number of ratings for all products enrolled in New Product Introduction",
            totalOverrideAccessor: "last_ratings_total",
          },
        }
      ),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <DetailedMetricRow
      small
      title={"New Product Launch"}
      to={`/user/productlaunch`}
      byline={
        <>
          <ThemeLine>Workflows</ThemeLine>
          Create new product launches and track their performance
          {isLoading && <i className="fa fa-spinner fa-spin"></i>}
        </>
      }
      data={data?.date ?? []}
      metrics={metrics}
      group={group}
      icon={WorkflowIcon}
    />
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(NPICard);
