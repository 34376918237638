import { combineReducers } from "redux";

import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import amzOnboardReducer from "./amzOnboardReducer";
import userReducer from "./userReducer";
import marketPlace from "./marketPlace";
import channel from "./channel";
import overrideModulesReducer from "./overrideModulesReducer";
import walmartOnboardReducer from "./walmartOnboardReducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  amz_onboard: amzOnboardReducer,
  walmart_onboard: walmartOnboardReducer,
  marketPlace: marketPlace,
  channel,
  errors: errorReducer,
  overrideModules: overrideModulesReducer,
});
