import React, { Component } from "react";
import { Carousel as Carou } from "react-bootstrap";
import { LogoutButton } from "components/core/basic/Button.jsx";
import {
  Player,
  // PosterImage,
  ControlBar,
  ReplayControl,
  BigPlayButton,
} from "video-react";
import axios from "axios";
import { Collapse, FormControl, Col, Row } from "react-bootstrap";
import { createUserConfig } from "utils/assetsUtils";
import getURLPrefix from "utils/getUrlPrefix";

const DEFAULT_CAROUSEL_VIDEO_WIDTH = 350;
let URL_PREFIX = getURLPrefix();

export default class TemplateSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templates: [],
      templateName: "",
      projectName: "",
      projectDescription: "",
      selectTemplate: true,
      enterProjectDetails: false,
      customSubmit: false,
      customSubmitted: false,
      marketPlace: this.props.marketPlace,
      projectNameErrorMessage: "",
    };
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates = () => {
    axios.get(URL_PREFIX + "/creatives/media/templates").then((res) => {
      this.setState({ templates: res.data });
    });
  };

  submitCustomVideoRequest() {
    axios
      .post(URL_PREFIX + "/creatives/media/video?custom=true")
      .then((res) => {
        this.props.handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Your custom video request was submitted.",
          "short"
        );
      })
      .catch((error) => {
        console.log("Submit custom request failed with error:", error);
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to request custom video. Please try again in few minutes. If issue continues please contact Trellis.",
          "short"
        );
      });
  }

  render() {
    return (
      <Row>
        <div hidden={!this.state.selectTemplate || this.state.customSubmit}>
          <Carou
            indicators={false}
            activeIndex={this.state.carouselIndex}
            nextIcon={
              <div className="" style={{ fontSize: "50px" }}>
                <a href="/#">
                  <i
                    class="bi bi-arrow-right-circle-fill"
                    style={{ fontSize: "50px" }}
                  />
                </a>
              </div>
            }
            prevIcon={
              <div className="" style={{ fontSize: "50px" }}>
                <a href="/#">
                  <i
                    class="bi bi-arrow-left-circle-fill"
                    style={{ fontSize: "50px" }}
                  />
                </a>
              </div>
            }
            onSelect={(selectedIdx, e) => {
              this.setState({ carouselIndex: selectedIdx });
            }}
          >
            {this.state.templates &&
              this.state.templates.length > 0 &&
              this.state.templates.map((t, i) => {
                let len = this.state.templates.length;
                let useMax = len;
                if (useMax < 2) {
                  useMax = 2;
                }
                var list = [];
                let y = 0;
                for (let x = 0; x < useMax / 2; x++) {
                  let innerList = [];
                  for (let r = 0; r < 2; r++) {
                    let useTempl;
                    if (y >= this.state.templates.length) {
                      y = 0;
                    }
                    useTempl = this.state.templates[y];
                    y++;
                    innerList.push(useTempl);
                  }
                  list.push(innerList);
                }

                return list.map((templ, i) => {
                  return (
                    <Carou.Item>
                      <div className="wrap">
                        <div style={{ marginRight: "2.5%" }}>
                          <Player
                            poster={templ[0].config.thumbnailSrc}
                            playsInline
                            fluid={false}
                            width={DEFAULT_CAROUSEL_VIDEO_WIDTH}
                          >
                            <source
                              src={templ[0].config.videoSrc}
                              type="video/mp4"
                            />
                            <ControlBar autoHide={false}>
                              <ReplayControl seconds={10} />
                            </ControlBar>
                            <BigPlayButton position="center" />
                          </Player>
                          <Row style={{ marginTop: "5px" }}>
                            <Col md={6} style={{ paddingTop: "4%" }}>
                              <p style={{ textTransform: "capitalize" }}>
                                <strong>
                                  {templ[0].name.replace("_", " ")}
                                </strong>
                              </p>
                            </Col>
                            <Col md={6} style={{ paddingTop: "2%" }}>
                              <LogoutButton
                                className={
                                  templ[0].config.type &&
                                  templ[0].config.type === "custom"
                                    ? "secondary_button"
                                    : "logout_button"
                                }
                                style={{ float: "right" }}
                                fontSize="14px"
                                title={
                                  templ[0].config.type &&
                                  templ[0].config.type === "custom" ? (
                                    <span>
                                      <i className="fa fa-check" /> Select
                                      Custom{" "}
                                    </span>
                                  ) : (
                                    <span>
                                      <i className="fa fa-file-video-o" />{" "}
                                      Select Template{" "}
                                    </span>
                                  )
                                }
                                onClick={() => {
                                  if (
                                    templ[0].config.type &&
                                    templ[0].config.type === "custom"
                                  ) {
                                    this.setState({
                                      customSubmit: true,
                                      selectTemplate: false,
                                    });
                                  } else {
                                    this.setState({
                                      templateName: templ[0].name,
                                      selectTemplate: false,
                                      enterProjectDetails: true,
                                    });
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{ marginLeft: "2.5%" }}>
                          <Player
                            poster={templ[1].config.thumbnailSrc}
                            playsInline
                            fluid={false}
                            width={DEFAULT_CAROUSEL_VIDEO_WIDTH}
                          >
                            <source
                              src={templ[1].config.videoSrc}
                              type="video/mp4"
                            />
                            <ControlBar autoHide={false}>
                              <ReplayControl seconds={10} />
                            </ControlBar>
                            <BigPlayButton position="center" />
                          </Player>
                          <Row style={{ marginTop: "5px" }}>
                            <Col md={6} style={{ paddingTop: "4%" }}>
                              <p style={{ textTransform: "capitalize" }}>
                                <strong>
                                  {templ[1].name.replace("_", " ")}
                                </strong>
                              </p>
                            </Col>
                            <Col md={6} style={{ paddingTop: "2%" }}>
                              <LogoutButton
                                className={
                                  templ[1].config.type &&
                                  templ[1].config.type === "custom"
                                    ? "secondary_button"
                                    : "logout_button"
                                }
                                style={{ float: "right" }}
                                fontSize="14px"
                                title={
                                  templ[1].config.type &&
                                  templ[1].config.type === "custom" ? (
                                    <span>
                                      <i className="fa fa-check" /> Select
                                      Custom{" "}
                                    </span>
                                  ) : (
                                    <span>
                                      <i className="fa fa-file-video-o" />{" "}
                                      Select Template{" "}
                                    </span>
                                  )
                                }
                                onClick={() => {
                                  if (
                                    templ[1].config.type &&
                                    templ[1].config.type === "custom"
                                  ) {
                                    this.setState({
                                      customSubmit: true,
                                    });
                                  } else {
                                    this.setState({
                                      templateName: templ[1].name,
                                      enterProjectDetails: true,
                                    });
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Carou.Item>
                  );
                });
              })}
          </Carou>
        </div>
        <div hidden={!this.state.enterProjectDetails}>
          <div style={{ paddingLeft: "2%" }}>
            <Row>
              <Col
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div>
                  <h6>Project name:</h6>
                </div>
              </Col>
              <Col md={5}>
                <FormControl
                  key={"enter_project_name"}
                  componentClass="input"
                  rows="1"
                  placeholder={"Enter Project Name"}
                  style={{ resize: "none", borderRadius: 22 }}
                  bsClass="form-control"
                  value={this.state.projectName}
                  onChange={(e) => {
                    this.setState({
                      projectNameErrorMessage: "",
                      projectName: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "4%" }}>
              <div className="wrap">
                <LogoutButton
                  fontSize="10px"
                  title={
                    <span>
                      <i className="fa fa-file-video-o" /> Start Editing{" "}
                    </span>
                  }
                  onClick={() => {
                    let pname = this.state.projectName.trim();
                    if (pname === "") {
                      this.setState({
                        projectNameErrorMessage: "Please enter a Project Name",
                        projectName: pname,
                      });
                      return;
                    } else if (
                      this.props.projectsList.indexOf(this.state.projectName) >
                      -1
                    ) {
                      this.setState({
                        projectNameErrorMessage:
                          "Please enter a unique Project Name",
                      });
                      return;
                    }
                    this.setState(
                      {
                        projectName: pname,
                      },
                      () => {
                        createUserConfig(
                          this.state.marketPlace,
                          this.state.projectName,
                          this.state.projectDescription,
                          this.state.templateName,
                          (success, id, projectName, config) => {
                            if (success) {
                              this.props.editProject(
                                id,
                                this.state.projectName,
                                this.state.projectDescription,
                                config
                              );
                            }
                          }
                        );
                      }
                    );
                  }}
                />
              </div>
              <Collapse in={this.state.projectNameErrorMessage !== ""}>
                <div
                  className="wrap"
                  style={{
                    textAlign: "center",
                    marginTop: "1%",
                    fontSize: "16",
                    cursor: "pointer",
                    color: "#d4587f",
                  }}
                >
                  <p
                    style={{
                      clear: "both",
                      color: "red",
                    }}
                  >
                    {this.state.projectNameErrorMessage}
                  </p>
                </div>
              </Collapse>
              <div
                className="wrap"
                style={{
                  marginTop: "1%",
                }}
              >
                <div
                  style={{
                    marginTop: "1%",
                    fontSize: "14px",
                    cursor: "pointer",
                    color: "#d4587f",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    this.setState({
                      selectTemplate: true,
                      enterProjectDetails: false,
                      customSubmit: false,
                    });
                  }}
                >
                  Change Template
                </div>
              </div>
            </Row>
          </div>
        </div>
        <div hidden={!this.state.customSubmit}>
          <div style={{ paddingLeft: "2%", marginTop: "-3em" }}>
            <Row>
              <div className="wrap">
                <div style={{ textAlign: "center" }}>
                  <h4>
                    Let our creative team build a custom video ad unique to your
                    brand.
                  </h4>
                  <h6>
                    Our creatives team will contact you in 48 hours to discuss
                    your video request.
                  </h6>
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: "4%" }}>
              <div className="wrap">
                <LogoutButton
                  fontSize="10px"
                  title={
                    <span>
                      <i className="fa fa-paper-plane" /> Submit Request{" "}
                    </span>
                  }
                  onClick={() => {
                    if (this.props.close) {
                      this.submitCustomVideoRequest();
                      this.props.close();
                    }
                  }}
                />
              </div>

              <div
                className="wrap"
                style={{
                  marginTop: "1%",
                }}
              >
                <div
                  style={{
                    marginTop: "1%",
                    fontSize: "16",
                    cursor: "pointer",
                    color: "#d4587f",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    this.setState({
                      selectTemplate: true,
                      enterProjectDetails: false,
                      customSubmit: false,
                    });
                  }}
                >
                  Cancel
                </div>
              </div>
            </Row>
          </div>
        </div>
        <div hidden={!this.state.customSubmitted}>
          <div>
            <Row>
              <div
                className="wrap"
                style={{
                  textAlign: "center",
                  marginTop: "1%",
                  fontSize: "16",
                  cursor: "pointer",
                  color: "#d4587f",
                }}
              >
                <p
                  style={{
                    clear: "both",
                  }}
                >
                  Our creatives team will contact you in 5-10 business days to
                  discuss your video request.
                </p>
              </div>
            </Row>
            <Row style={{ marginTop: "4%" }}>
              <div className="wrap">
                <LogoutButton
                  fontSize="10px"
                  title={
                    <span>
                      <i className="fa fa-remove" /> Close{" "}
                    </span>
                  }
                  onClick={() => {
                    if (this.props.close) {
                      this.props.close();
                    }
                  }}
                />
              </div>
            </Row>
          </div>
        </div>
      </Row>
    );
  }
}
