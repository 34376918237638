import React from "react";
import {
  WizardCancelButton,
  WizardUpdateButton,
  UpdateButton,
} from "./buttons";
import { withStyles, Grid } from "@material-ui/core";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = (theme) => ({
  formButtonContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    justifyContent: "space-around",
    width: "100%",
    ["@media (max-width: 600px)"]: {
      position: "relative",
      display: "flex",
    },
  },
  reviewPanelButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const FormWizardPagination = ({
  onPrevious,
  onPreviousText,
  onNext,
  onNextText,
  onExit,
  classes,
  hidePreviousButtonVisibility,
  hideNextButtonVisibility,
  isPanelReview,
  readyToProceed,
  disableUpdateButton,
  hidePagination = false,
  isModal,
  hideCancelButton = false,
}) => {
  if (hidePagination) {
    return null;
  }
  return isPanelReview ? (
    <Grid item className={classes.reviewPanelButtonContainer}>
      <UpdateButton
        text={onNextText}
        onClick={() => onNext()}
        disabled={disableUpdateButton}
      />
    </Grid>
  ) : (
    <Grid item className={classes.formButtonContainer}>
      {!hidePreviousButtonVisibility && (
        <WizardCancelButton
          onClick={() => {
            onPrevious();
          }}
          text={onPreviousText}
        />
      )}

      {!hideNextButtonVisibility && (
        <WizardUpdateButton
          text={onNextText}
          onClick={() => {
            onNext();
          }}
          readyToProceed={readyToProceed}
        />
      )}
      {!isModal && !hideCancelButton && (
        <WizardCancelButton
          color={"#62407F"}
          inlineModal={true}
          children={
            <ConfirmationModal
              title={`Are you sure you want to exit?`}
              confirmationMessage={
                <>
                  <div className="fs-standard">
                    All form progress will be removed.
                  </div>
                </>
              }
              inlineModal={true}
              confirmButtonText={`Exit`}
              modalButtonText={"Cancel"}
              onConfirm={(e) => {
                onExit(e);
              }}
            />
          }
        />
      )}
    </Grid>
  );
};

export default withStyles(useStyles)(FormWizardPagination);
