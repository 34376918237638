import React from "react";
import { useSelector } from "react-redux";
import { useFetch } from "./api";
import moment from "moment";

const useProducts = (
  dashboard,
  categoryId = null,
  productId = null,
  overrideFilters = null
) => {
  const state = useSelector((state) => state);

  const {
    marketPlace,
    channel: { currentChannel: channel },
  } = state;

  const fiftyDaysInTheFuture = moment().add(50, "days").format("YYYY-MM-DD");

  let filters = {
    start_date: fiftyDaysInTheFuture,
    end_date: fiftyDaysInTheFuture,
    pre_start_date: null,
    pre_end_date: null,
    empty: true,
    group_by: "product_id",
    category: categoryId,
  };

  if (categoryId) {
    filters["category"] = parseInt(categoryId);
  }

  if (overrideFilters) {
    filters = { ...filters, ...overrideFilters };
  }

  const { data: productData, isLoading: isProductDataLoading } = useFetch(
    ["product_report", marketPlace.marketPlace, channel, categoryId],
    `/api/data_report/product_report`,
    {
      mp: marketPlace.marketPlace,
      channel: channel,
      ...filters,
    },
    {
      select: (d) => d.data.products,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      default: [],
    }
  );

  const getProductHref = (id) => {
    if (dashboard === "content") {
      return `/user/content/seo?id=${id}`;
    }

    return `/user/merchandising/product/${id}`;
  };

  const currentProduct =
    productData?.find((product) => product?.id === parseInt(productId)) ?? {};

  return {
    currentProductDetails: {
      ...currentProduct,
      href: getProductHref(currentProduct?.id),
    },
    products: productData?.map((product) => ({
      ...product,
      name: `${product.product_title} ${product.asin} (${product.sku})`,
      altDisplayMarkup: (
        <div>
          <span
            style={{
              maxWidth: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "500",
              fontSize: "1.5rem",
            }}
          >
            {product.product_title}
          </span>
          <i
            style={{
              display: "block",
              fontWeight: "light",
              color: "#2e0054",
              fontSize: "1rem",
            }}
          >
            {product.asin} ({product.sku})
          </i>
        </div>
      ),
      href: getProductHref(product?.id),
      isLoading: isProductDataLoading,
    })),
  };
};

export default useProducts;
