import React from "react";
import {
  AD_TYPES,
  TARGET_TYPES,
  ALGORITHM_OPTIONS,
  BID_STRATEGY_OPTIONS,
  GOALS,
  WALMART_MAX_BID_DEFAULT,
} from "./constants";
import { Grid } from "@material-ui/core";
import AdPlanChoiceCard from "./AdPlanChoiceCard";
import { getCampaignsByAdType } from "utils/getCampaignsByAdType";
import { Col } from "react-bootstrap";
import Tags from "../Tags";

const AdPlanGoalMenu = ({
  state,
  onChange,
  setIsComposite,
  marketPlace,
  roas,
  acosGoalsField,
  nameField,
  errors,
  fieldsToValidate,
  user,
  channel,
  modules,
}) => {
  const AMAZON_DAILY_BUDGET_DEFAULT = marketPlace === "IN" ? 1500 : 20;
  const WALMART_DAILY_BUDGET_DEFAULT =
    user.organization_type === "vendor" ? 126 : 26;
  const WALMART_SOV_DAILY_BUDGET_DEFAULT =
    user.organization_type === "vendor" ? 50 : 10;

  const suggestedTags = [
    "Awareness",
    "Interest",
    "Consideration",
    "Intent",
    "Evaluation",
    "Purchase",
  ];

  const onGoalChange = (goal, isComposite = false) => {
    // Don't reset defaults if user clicks around within selected choice card
    if (goal.value === state.goal) {
      return;
    }

    switch (goal.value) {
      case GOALS.DEMAND_GENERATION.value:
        setIsComposite(false);
        onChange({
          goal: goal.value,
          targetACOS: roas ? 5 : 20,
          maxACOS: roas ? 2.5 : 40,
          dailyBudget:
            channel === "walmart"
              ? WALMART_DAILY_BUDGET_DEFAULT
              : AMAZON_DAILY_BUDGET_DEFAULT,
          canHaveChildren: false,
          adType: undefined,
          algorithm: ALGORITHM_OPTIONS.ROAS_BOOST.value,
          keywordDiscoveryEnabled: false,
          trellisTrakEnabled: true,
          keywordHarvestingEnabled: true,
          autoCampaignEnabled: true,
          asinHarvestingEnabled: true,
          crSelectorEnabled: true,
          broadMatchEnabled: true,
          phraseMatchEnabled: true,
          exactMatchEnabled: true,
          negativePhraseMatchEnabled: false,
          negativeExactMatchEnabled: false,
          broadMatchRegular: false,
          broadMatchModifier: true,
          topOfSearchBidMultiplier: 0,
          bidStrategy: BID_STRATEGY_OPTIONS.DOWN_ONLY.value,
          maxBid: channel === "walmart" ? WALMART_MAX_BID_DEFAULT : null,
          targetType: TARGET_TYPES.FUNNEL.value,
          salesVolumeControl: 5,
        });
        break;
      case GOALS.PRODUCT_LAUNCH.value:
        setIsComposite(false);
        onChange({
          goal: goal.value,
          targetACOS: roas ? 3.4 : 30,
          maxACOS: roas ? 1.7 : 60,
          dailyBudget:
            channel === "walmart"
              ? WALMART_DAILY_BUDGET_DEFAULT
              : AMAZON_DAILY_BUDGET_DEFAULT,
          canHaveChildren: false,
          adType: undefined,
          algorithm: ALGORITHM_OPTIONS.PRODUCT_LAUNCH.value,
          keywordDiscoveryEnabled: true,
          trellisTrakEnabled: true,
          keywordHarvestingEnabled: true,
          autoCampaignEnabled: true,
          asinHarvestingEnabled: true,
          crSelectorEnabled: true,
          broadMatchEnabled: true,
          phraseMatchEnabled: true,
          exactMatchEnabled: true,
          negativePhraseMatchEnabled: false,
          negativeExactMatchEnabled: false,
          broadMatchRegular: false,
          broadMatchModifier: true,
          topOfSearchBidMultiplier: 0,
          bidStrategy: BID_STRATEGY_OPTIONS.DOWN_ONLY.value,
          maxBid: channel === "walmart" ? WALMART_MAX_BID_DEFAULT : null,
          targetType:
            channel === "walmart"
              ? TARGET_TYPES.MANUAL.value
              : TARGET_TYPES.AUTOMATIC.value,
        });
        break;
      case GOALS.OPTIMIZE_ACOS.value:
        setIsComposite(false);
        onChange({
          goal: goal.value,
          targetACOS: roas ? 10 : 10,
          maxACOS: roas ? 4 : 25,
          dailyBudget:
            channel === "walmart"
              ? WALMART_DAILY_BUDGET_DEFAULT
              : AMAZON_DAILY_BUDGET_DEFAULT,
          canHaveChildren: false,
          adType: undefined,
          algorithm: ALGORITHM_OPTIONS.ROAS_BOOST.value,
          keywordDiscoveryEnabled: false,
          trellisTrakEnabled: true,
          keywordHarvestingEnabled: false,
          autoCampaignEnabled: false,
          campaignNames: {},
          asinHarvestingEnabled: false,
          crSelectorEnabled: true,
          broadMatchEnabled: true,
          phraseMatchEnabled: true,
          exactMatchEnabled: true,
          negativePhraseMatchEnabled: false,
          negativeExactMatchEnabled: false,
          broadMatchRegular: false,
          broadMatchModifier: true,
          topOfSearchBidMultiplier: 0,
          bidStrategy: BID_STRATEGY_OPTIONS.DOWN_ONLY.value,
          maxBid: channel === "walmart" ? WALMART_MAX_BID_DEFAULT : null,
          targetType: TARGET_TYPES.MANUAL.value,
          salesVolumeControl: 2,
        });
        break;
      case GOALS.SOV.value:
        setIsComposite(false);
        onChange({
          goal: goal.value,
          adType: AD_TYPES.SPONSORED_PRODUCT.value,
          canHaveChildren: false,
          targetACOS: roas ? 5 : 20,
          maxACOS: roas ? 2.5 : 40,
          dailyBudget:
            channel === "walmart"
              ? WALMART_SOV_DAILY_BUDGET_DEFAULT
              : AMAZON_DAILY_BUDGET_DEFAULT,
          algorithm: ALGORITHM_OPTIONS.ROAS_BOOST.value,
          keywordDiscoveryEnabled: false,
          trellisTrakEnabled: false,
          keywordHarvestingEnabled: false,
          autoCampaignEnabled: false,
          // Since SOV goal preselects ad type to SP, also account for default campaign types here
          campaignNames: getCampaignsByAdType(
            AD_TYPES.SPONSORED_PRODUCT.value,
            state.campaignNames,
            false,
            state.name
          ),
          asinHarvestingEnabled: false,
          crSelectorEnabled: true,
          broadMatchEnabled: true,
          phraseMatchEnabled: true,
          exactMatchEnabled: true,
          negativePhraseMatchEnabled: false,
          negativeExactMatchEnabled: false,
          broadMatchRegular: false,
          broadMatchModifier: true,
          topOfSearchBidMultiplier: 0,
          bidStrategy: BID_STRATEGY_OPTIONS.DOWN_ONLY.value,
          targetType: TARGET_TYPES.MANUAL.value,
        });
        break;
      case GOALS.CUSTOM.value:
        setIsComposite(true);
        onChange({
          goal: goal.value,
          adType: AD_TYPES.CUSTOM.value,
          canHaveChildren: true,
          targetACOS: roas ? 5 : 20,
          maxACOS: roas ? 2.5 : 40,
          targetType: TARGET_TYPES.FUNNEL.value,
          dailyBudget:
            channel === "walmart"
              ? WALMART_DAILY_BUDGET_DEFAULT
              : AMAZON_DAILY_BUDGET_DEFAULT,
          algorithm: ALGORITHM_OPTIONS.ROAS_BOOST.value,
          trellisTrakEnabled: true,
          keywordDiscoveryEnabled: false,
          keywordHarvestingEnabled: true,
          discoverKeywords: false,
          autoCampaignEnabled: false,
          asinHarvestingEnabled: true,
          crSelectorEnabled: true,
          broadMatchEnabled: true,
          phraseMatchEnabled: true,
          exactMatchEnabled: true,
          negativePhraseMatchEnabled: false,
          negativeExactMatchEnabled: false,
          broadMatchRegular: false,
          broadMatchModifier: true,
          topOfSearchBidMultiplier: 0,
          bidStrategy: BID_STRATEGY_OPTIONS.DOWN_ONLY.value,
        });
        break;
      default:
        break;
    }
  };

  let goalOptions = {
    DEMAND_GENERATION: GOALS.DEMAND_GENERATION,
    PRODUCT_LAUNCH: GOALS.PRODUCT_LAUNCH,
    OPTIMIZE_ACOS: GOALS.OPTIMIZE_ACOS,
    SOV: GOALS.SOV,
    CUSTOM: GOALS.CUSTOM,
  };

  if (channel === "walmart") {
    goalOptions = {
      DEMAND_GENERATION: GOALS.DEMAND_GENERATION,
      PRODUCT_LAUNCH: GOALS.PRODUCT_LAUNCH,
      OPTIMIZE_ACOS: GOALS.OPTIMIZE_ACOS,
      SOV: GOALS.SOV,
    };
  }

  return (
    <Grid
      style={{
        margin: "0 auto 20px",
        maxWidth: "100%",
      }}
    >
      <h2 style={{ fontWeight: 700, marginTop: 0, fontSize: "24px" }}>
        Create your Media Plan
      </h2>
      <p style={{ fontSize: "16px", maxWidth: "90%" }} translate="yes">
        To get started, add a plan name and choose a strategy that best fits
        your goals. Contact the customer success team if you want a second
        opinion.
      </p>

      <div>
        <span
          style={{
            fontSize: "20px",
            padding: "1rem 0",
            display: "block",
            fontWeight: 500,
          }}
        >
          Name Your Plan
        </span>
        {nameField}
      </div>
      <Col xs={12} style={{ maxWidth: "90%" }} className="pt-5 pb-3">
        <Tags
          name={
            <div className="d-flex">
              <span
                style={{
                  fontSize: "20px",
                  padding: "1rem 0",
                  display: "block",
                  fontWeight: 500,
                }}
              >
                Tags (optional)
              </span>
            </div>
          }
          filledTags={state.tags}
          saveFilledTags={(tags) => onChange({ tags })}
          placeholder={"Add tags here..."}
          hideEditButton={true}
          reviewDisplay={false}
          suggestedTags={suggestedTags}
          showSuggestedTags={true}
        />
      </Col>
      <div style={{ maxWidth: "90%" }}>
        <span
          style={{
            fontSize: "20px",
            padding: "1rem 0",
            display: "block",
            fontWeight: 500,
          }}
        >
          Choose a Strategy
        </span>
        {errors.adType && fieldsToValidate?.includes("goal") && (
          <small
            className="text-danger"
            style={{ paddingLeft: ".8rem", display: "block" }}
          >
            {errors.goal}
          </small>
        )}
        {Object.keys(goalOptions).map((goal, index) => {
          if (
            GOALS[goal].value === GOALS.CUSTOM.value &&
            channel !== "amazon"
          ) {
            return null;
          }
          if (state.hasParent && GOALS[goal].value === GOALS.CUSTOM.value) {
            return null;
          } else {
            return (
              <AdPlanChoiceCard
                id={`new-mediaplan-goal-${index}`}
                state={state}
                label={GOALS[goal].label}
                value={GOALS[goal]}
                description={GOALS[goal].description}
                childElements={
                  GOALS[goal].value === state.goal && channel !== "walmart"
                    ? acosGoalsField
                    : null
                }
                isSelected={GOALS[goal].value === state.goal}
                onChange={onGoalChange}
              />
            );
          }
        })}
      </div>
    </Grid>
  );
};

export default AdPlanGoalMenu;
