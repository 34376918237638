import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import ContentEditor from "../ContentEditor2";

const BulletPointEditor = ({
  bulletPoints,
  onContentChange,
  setContent,
  minLength,
  maxLength,
  highlightPhrases,
  recommendedContent,
}) => {
  const bullets = useMemo(() => {
    return [0, 1, 2, 3, 4].map((i) => bulletPoints?.[i] ?? "");
  }, [bulletPoints]);

  return (
    <>
      {bullets?.map((fb, i) => (
        <Row key={i} className="mb-5">
          <Col xs={12}>
            <ContentEditor
              field={`featureBullets.${i}`}
              content={fb}
              minLength={minLength}
              maxLength={maxLength}
              onContentChange={onContentChange}
              setContent={setContent}
              highlightPhrases={highlightPhrases}
              recommendedContent={recommendedContent?.[i]}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default BulletPointEditor;
