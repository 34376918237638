import checkApplication from "utils/checkApplication";
import checkModule from "./checkModule";
import { AD_TYPES, GOALS } from "components/core/blocks/AdPlan/constants";

let AD_DETAILS_TAB_OPTIONS = {
  PRODUCTS: { key: "products", label: "Products", group: "Products" },
  MEDIA_PLAN: { key: "mediaPlan", label: "Media Plan", group: "Configuration" },
  KEYWORDS: { key: "keywords", label: "Keywords", group: "Keywords" },
  TARGETS: { key: "targets", label: "Targets", group: "Targets" },
  NEGATIVE_KEYWORDS: {
    key: "negative-keywords",
    label: "Negative Keywords",
    group: "Negative Keywords",
  },
  SERVING_HOURS: {
    key: "servingHours",
    label: "Serving Hours",
    group: "Configuration",
  },
  NOTIFICATIONS: {
    key: "notifications",
    label: "Notifications",
    group: "Configuration",
  },
  PLACEMENTS: { key: "placements", label: "Placements", group: "Placements" },
  AUTO_ADS: { key: "autoAds", label: "Auto Ads", group: "Auto Ads" },
  INSIGHTS: { key: "insights", label: "Insights", group: "Insights" },
  SEASONALITY: {
    key: "seasonality",
    label: "Weekday Parting",
    group: "Configuration",
  },
  SCHEDULED_CHANGES: {
    key: "scheduledChanges",
    label: "Scheduled Updates",
    group: "Configuration",
  },
  SEARCH_TERM_REPORT: {
    key: "searchTermReport",
    label: "Search Term Report",
    group: "Search Term Report",
  },
  DAY_PARTING: {
    key: "day_parting",
    label: "Day Parting",
    group: "Configuration",
  },
  CHANGE_LOG: { key: "changeLog", label: "History", group: "Configuration" },
};

let LISTING_DETAILS_TAB_OPTIONS = {
  OVERVIEW: { key: "details", label: "Details" },
  ADVERTISING: { key: "advertising", label: "Advertising" },
  DEALS: { key: "deals", label: "Deals" },
  PRICING: { key: "pricing", label: "Pricing" },
  CONTENT: { key: "content", label: "Content" },
};

// Util to get applicable tabs for advertisement details based on media plan type and enabled modules
export const getTabsFromAdType = (
  adType,
  channel,
  adGoal,
  modules,
  isSegmented = false,
  isUnmanagedCategory = false
) => {
  let tabsToRender = [];

  const {
    PRODUCTS,
    MEDIA_PLAN,
    KEYWORDS,
    TARGETS,
    NOTIFICATIONS,
    AUTO_ADS,
    SERVING_HOURS,
    INSIGHTS,
    SEASONALITY,
    SCHEDULED_CHANGES,
    SEARCH_TERM_REPORT,
    CHANGE_LOG,
    DAY_PARTING,
  } = AD_DETAILS_TAB_OPTIONS;

  if (adGoal === GOALS.IMPORTED_ADS.value) {
    tabsToRender = [PRODUCTS, KEYWORDS, TARGETS, MEDIA_PLAN, SERVING_HOURS];

    if (
      [
        AD_TYPES.SPONSORED_PRODUCT.value,
        AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_BRAND_V2.value,
        AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      ].includes(adType)
    ) {
      tabsToRender.splice(-1, 0, SEARCH_TERM_REPORT);
    }

    if (
      [
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_BRAND_V2.value,
      ].includes(adType)
    ) {
      tabsToRender.splice(0, 1);
    }

    if (!isUnmanagedCategory) {
      tabsToRender.push(SEASONALITY);
      tabsToRender.push(DAY_PARTING);
    }

    return tabsToRender;
  }
  if (channel === "amazon") {
    switch (adType) {
      case AD_TYPES.SPONSORED_PRODUCT.value:
      case AD_TYPES.SPONSORED_PRODUCT_AUTO.value:
        tabsToRender = [PRODUCTS, KEYWORDS, TARGETS, MEDIA_PLAN, NOTIFICATIONS];
        break;
      case AD_TYPES.CUSTOM.value:
        tabsToRender = [PRODUCTS, MEDIA_PLAN];
        break;
      case AD_TYPES.SPONSORED_BRAND.value:
      case AD_TYPES.SPONSORED_BRAND_V2.value:
      case AD_TYPES.SPONSORED_BRAND_VIDEO.value:
        tabsToRender = [KEYWORDS, TARGETS, MEDIA_PLAN, NOTIFICATIONS];
        break;
      case AD_TYPES.SPONSORED_DISPLAY.value:
      case AD_TYPES.SPONSORED_DISPLAY_V2.value:
        tabsToRender = [PRODUCTS, TARGETS, MEDIA_PLAN, NOTIFICATIONS];
        break;
      default:
        tabsToRender = [];
        break;
    }
  }

  if (channel === "walmart") {
    if ([AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value].includes(adType)) {
      tabsToRender = [PRODUCTS, KEYWORDS, MEDIA_PLAN, NOTIFICATIONS];
    } else {
      tabsToRender = [PRODUCTS, KEYWORDS, AUTO_ADS, MEDIA_PLAN, NOTIFICATIONS];
    }
  }

  const insightsModuleEnabled = checkModule(modules, "MEDIA_PLAN_INSIGHTS");

  if (
    [
      AD_TYPES.SPONSORED_PRODUCT.value,
      AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_V2.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      AD_TYPES.SPONSORED_PRODUCT_WALMART.value,
      AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
      AD_TYPES.SPONSORED_BRAND_WALMART.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
    ].includes(adType) &&
    // Components with parent have number set in segmented flag
    isSegmented !== true
  ) {
    tabsToRender.push(SEARCH_TERM_REPORT);
  }

  if (insightsModuleEnabled) {
    tabsToRender.push(INSIGHTS);
  }

  if (channel === "amazon") {
    tabsToRender.push(SEASONALITY);
    tabsToRender.push(DAY_PARTING);
  }

  if (!isSegmented) {
    tabsToRender.push(SCHEDULED_CHANGES);
  }

  // Below address shared ad type value between Walmart Sponsored Product and Segmented parent ad values
  if (
    (adType !== AD_TYPES.CUSTOM.value && adGoal !== GOALS.IMPORTED_ADS.value) ||
    channel === "walmart"
  ) {
    tabsToRender.push(CHANGE_LOG);
  }

  if (
    !(
      adType === AD_TYPES.CUSTOM.value &&
      channel === "amazon" &&
      adGoal !== GOALS.IMPORTED_ADS.value
    )
  ) {
    let spliceOffset = insightsModuleEnabled ? -3 : -2;

    tabsToRender.splice(spliceOffset, 0, SERVING_HOURS);
  }

  return tabsToRender;
};
