export const DASHBOARD_LINKS =
  localStorage.channel === "walmart"
    ? [
        {
          name: "Merchandising Dashboard",
          href: "/user/merchandising/dashboard",
          subTitle: "Overview",
        },
        {
          name: "Advertising Dashboard",
          href: "/user/advertising/dashboard",
          subTitle: "Placement",
        },
      ]
    : [
        {
          name: "Merchandising Dashboard",
          href: "/user/merchandising/dashboard",
          subTitle: "Overview",
        },
        {
          name: "Advertising Dashboard",
          href: "/user/advertising/dashboard",
          subTitle: "Placement",
        },
        {
          name: "Pricing Dashboard",
          href: "/user/_pricing/dashboard",
          subTitle: "Pricing",
        },
        {
          name: "Deals Dashboard",
          href: "/user/merchandising/promotions",
          subTitle: "Promotions",
        },
        {
          name: "Content Dashboard",
          href: "/user/content/quality/org",
          subTitle: "Product",
        },
        // {
        //   name: "Profit and Loss Report",
        //   href: "/user/pl/report",
        //   subTitle: "Report",
        // },
      ];

export const REPORT_LINKS = [
  { name: "Keyword Explorer", href: "/user/content/explore" },
  { name: "Purchase Behavior", href: "/user/purchasebehavior" },
  { name: "Product Report", href: "/user/report/product" },
  { name: "Advertisement Report", href: "/user/report/advertisement" },
];

export const AGENCY_DASHBOARD_BREADCRUMB_SPEC = {
  name: "Agency Dashboard",
  href: `/user/agency/dashboard`,
  // TO REVISIT: If the agency dashboard becomes the top-level overview for all orgs,
  // it might be nice to add all profiles in the dropdown here to toggle between orgs
};

export const STAFF_DASHBOARD_BREADCRUMB_SPEC = {
  name: "All Customers Dashboard",
  href: `/user/staff/dashboard`,
};

export const ORG_SUMMARY_REPORT_BREADCRUMB_SPEC = {
  name: "Organization Summary Report",
  href: "/user/report/organization/summary",
};
