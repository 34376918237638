import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFetch } from "hooks/api";
import Loading from "components/core/blocks/Loading";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import TrComponent from "components/custom/merchandise/TrComponent";
import useColumnSelector from "hooks/useColumnSelector";
import { formatNumber } from "utils/formatNumber";
import { SEARCH_AMAZON_LINKS } from "utils/marketplaceConstants";
import { HiExternalLink } from "react-icons/hi";
import useDebounce from "hooks/useDebounce";

const Insights = ({ marketPlace }) => {
  const [isExpanded, setIsExpanded] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [filters, setFilters] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const colToggle = useState();

  const debouncedSearch = useDebounce(searchFilter, 1500);

  const { isLoading, data, isSuccess } = useFetch(
    ["brand_insights", marketPlace],
    "/merchandise/seo/insights",
    {
      marketplace: marketPlace,
    },
    {
      default: [],
      select: (res) => res.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const ExpandedContent = ({ className, children, style, row }) => {
    return (
      <Row>
        <Col xs={12}>
          <div
            style={{
              backgroundColor: "#FFF",
              borderRadius: "5px",
              padding: "10px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            <Row className="fs-4">
              <div className="fw-bold">
                Search terms associated with the brand
              </div>
              {row.original.keywords.map((keyword) => (
                <Col xs={4} className="pl-4 pt-2">
                  {keyword}
                  &nbsp;
                  <a
                    href={
                      SEARCH_AMAZON_LINKS?.[marketPlace.marketPlace] + keyword
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View on Amazon"
                  >
                    <HiExternalLink />
                  </a>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  const brands = useMemo(() => {
    if (!data) {
      return [];
    }

    const brands = [];

    for (let i = 0; i < data.brands.length; i++) {
      const brand = { ...data.brands[i] };
      if (
        debouncedSearch &&
        !brand.brand_name.toLowerCase().includes(debouncedSearch.toLowerCase())
      ) {
        continue;
      }

      brand.id = brand.brand_name;
      brand.isExpanded = isExpanded === brand.id;
      brands.push(brand);
    }

    return brands;
  }, [data, isExpanded, debouncedSearch]);

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let columns = [
        {
          id: "expander",
          Header: "",
          isStatic: true,
          width: 50,
          style: { width: "50px" },
          filterable: false,
          Cell: ({ row }) => {
            return (
              <>
                <ExpanderCell
                  row={row}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              </>
            );
          },
        },
        {
          id: "brand_name",
          isStatic: true,
          Header: "Brand Name",
          accessor: "brand_name",
          style: { width: "300px" },
          Cell({ value, row }) {
            return <div>{value}</div>;
          },
        },
        {
          id: "avg_rank",
          isStatic: false,
          Header: "Avg Position",
          accessor: "avg_rank",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value, {}, 0)}</div>
          ),
        },
        {
          id: "num_products",
          isStatic: false,
          Header: "Found Products",
          accessor: "count",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value, {}, 0)}</div>
          ),
        },
        {
          id: "num_keywords",
          isStatic: false,
          Header: "# of Keywords",
          accessor: "keywords",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">
              {formatNumber(value.length, {}, 0)}
            </div>
          ),
        },
        {
          id: "avg_bsr",
          isStatic: false,
          Header: "Avg BSR",
          accessor: "avg_bsr",
          checked: true,
          Cell: ({ value }) => (
            <div className="text-center">{formatNumber(value, {}, 0)}</div>
          ),
        },
      ];
      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle, brands, marketPlace.marketPlace, isExpanded]
  );

  const columnOrder = columns.map((col) => col.id);

  if (isLoading || !isSuccess) {
    return (
      <Row>
        <Col>
          <div className="text-center">
            <Loading height={`300px`} fullPage={false} />
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col xs={12} className="pt-3">
        <div className="fs-3 fw-bold">Brand Affinity</div>
        <div className="fs-4 py-4">
          Brand Affinity is a measure of how often a brand appears in search
          results. The higher the brand affinity, the more often the brand shows
          up in search results for all search terms.
        </div>
      </Col>
      <Col>
        <ReactTable
          PaginationComponent={ReactTablePagination}
          getPaginationProps={() => ({
            updateSearch: setSearchFilter,
            clearFilters: () => {
              setFilters([]);
              setSearchFilter("");
              setClearFilters(true);
            },
            clearSearchTermFilters: () => setSearchFilter(""),
            searchTerm: searchFilter,
            setFilterState: setFilters,
            filteredState: filters,
            table: "seo-search-terms-table",
            setShowFilters: setShowFilters,
            showFilters: showFilters,
            style: { marginBottom: "2rem" },
            carouselLayout: false,
            disableSearchFocus: true,
          })}
          getTrProps={(table, row) => {
            let style = {};

            let expandedChildren = <ExpandedContent style={style} row={row} />;

            return { row, style, expandedChildren };
          }}
          getTheadFilterProps={(props) => {
            if (clearFilters) {
              props.filtered.forEach((filter, index) => {
                filter.value = "all";
              });
              setClearFilters(false);
            }
            return {
              style: { display: "flex", textAlign: "center" },
            };
          }}
          TrComponent={TrComponent}
          showPaginationTop
          showPaginationBottom={false}
          columns={columns}
          data={brands}
          defaultPageSize={50}
          className="seo-search-terms-table"
          filterable={showFilters}
          state={columnOrder}
          columnSelectorProps={columnSelectorProps}
        />
      </Col>
    </Row>
  );
};

export default Insights;
