import React, { useState, useEffect, useMemo } from "react";
import { useFetch } from "hooks/api";
import getQueryValue from "utils/getQueryValue";
import {
  Row,
  Col,
  Table,
  Dropdown,
  DropdownButton,
  Badge,
  Container as Grid,
  Alert,
  Nav,
} from "react-bootstrap";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/core/blocks/Loading";
import { getURLPrefix } from "utils/getUrlPrefix";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import axios from "axios";
import { getBadgeVariant } from "./utils";
import ContentEditor2 from "./ContentEditor2";
import { LogoutButton, PrimaryButton } from "components/core/basic/Button.jsx";
import ConversionLegend from "./components/ConversionLegend";
import SideMenuTop from "./components/SideMenuTop";
import SideMenuBottom from "./components/SideMenuBottom";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import api from "utils/api";
import moment from "moment";
import BulletPointEditor from "./components/BulletPointEditor";
import { useMutation } from "react-query";
import { selectModules } from "redux/selectors/modules";
import useMinLoading from "hooks/useMinLoading";
import SteppedProgressBar from "components/custom/SteppedProgressBar";
import PublishHistory from "./components/PublishHistory";
import useNav from "hooks/useNav";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import useCategories from "hooks/useCategories";
import useProducts from "hooks/useProducts";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import checkModule from "utils/checkModule";

let URL_PREFIX = getURLPrefix();

const ProductSEO = ({ marketPlace, user, channel, modules }) => {
  const productId = getQueryValue("id");

  const [title, setTitle] = useState(null);
  const [featureBullets, setFeatureBullets] = useState([]);
  const [genericKeywords, setGenericKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [keyword, setKeyword] = useState(null);
  const [searchTerms, setSearchTerms] = useState(null);
  const [saveDate, setSaveDate] = useState(null);
  const [publishDate, setPublishDate] = useState(null);
  const [loadingSearchTerms, setLoadingSearchTerms] = useState(false);
  const [editorMode, setEditorMode] = useState("edit");
  const [showTitleHelp, setShowTitleHelp] = useState(false);
  const [showBulletHelp, setShowBulletHelp] = useState(false);
  const [variations, setVariations] = useState([]);
  const [contentChanged, setContentChanged] = useState(false);
  const [quickTerm, setQuickTerm] = useState(null);
  const [error, setError] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [currentNav, navProps] = useNav(["content", "publish_history"]);

  const generateContent = useMutation(
    async () => {
      return await api.get(`/merchandise/generate_content/`, {
        product_id: productId,
        kw: keyword,
      });
    },
    {
      onMutate: () => {
        setRecommendations(null);
      },
      onSuccess: (data) => {
        setRecommendations(data.data?.completion);
      },
    }
  );
  const { loading: loadingGeneratedContent, step } = useMinLoading(
    generateContent.isLoading,
    4,
    5000
  );

  let scores = null;
  let product = null;
  let seoKeywords = null;
  let seoSearchTerms = null;
  let hiddenKeywords = null;

  const { loading, data } = useFetch(
    ["productQuality", productId],
    `/merchandise/quality/${productId}`,
    {},
    {}
  );

  scores = data?.data;
  product = data?.data?.product;
  seoKeywords = data?.data?.seo_keywords?.keywords;
  seoSearchTerms = data?.data?.seo_keywords?.search_terms;
  hiddenKeywords = data?.data?.hidden_keywords;

  useEffect(() => {
    let isSaved = false;
    if (product) {
      if (scores.save_date !== null) {
        setSaveDate(
          moment(scores.save_date).format("dddd, MMMM Do, YYYY h:mm a")
        );

        isSaved = true;
        setTitle(scores.revision.title);
        setDescription(scores.revision.description);
        setFeatureBullets(
          scores.revision.feature_bullets.map((b) => b.replace("&nbsp;", " "))
        );
        setGenericKeywords(scores.revision.generic_keywords);
      }
      if (scores.publish_date !== null) {
        setPublishDate(
          moment(scores.publish_date).format("dddd, MMMM Do, YYYY h:mm a")
        );
      }
      getCatalog(isSaved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const allContentElements = useMemo(() => {
    const cleanDescription = description?.replace(/<[^>]+>/g, "");
    const bullets =
      featureBullets?.reduce((acc, bullet, index) => {
        acc[`featureBullet${index + 1}`] = bullet;
        return acc;
      }, {}) ?? {};
    return {
      title,
      description: cleanDescription,
      genericKeywords,
      ...bullets,
    };
  }, [featureBullets, title, description, genericKeywords]);

  if (seoKeywords && seoSearchTerms) {
    [seoKeywords, seoSearchTerms].forEach((data) => {
      for (let i = 0; i < data.length; i++) {
        const kw = data[i]["keyword_text"];
        const kwData = data[i];
        kwData.cr = 0;
        kwData.ctr = 0;
        kwData.acos = 0;

        if (kw.length <= 1) {
          continue;
        }

        if (kwData.clicks > 0) {
          kwData.cr = kwData.conversions / kwData.clicks;
        }
        if (kwData.impressions > 0) {
          kwData.ctr = kwData.clicks / kwData.impressions;
        }
        if (kwData.sales > 0) {
          kwData.acos = kwData.cost / kwData.sales;
        }

        if (!kwData.cr) {
          kwData.cr = 0;
        }
      }
    });

    seoKeywords.sort(function (a, b) {
      return b.cr - a.cr;
    });

    seoSearchTerms.sort(function (a, b) {
      return b.cr - a.cr;
    });
  }

  const category = product?.product_category ?? "";

  const getSearchTerms = async (monogram, product_id) => {
    if (loadingSearchTerms) {
      return;
    }
    // setQuickSearch(null);
    setEditorMode("metrics");
    setKeyword(monogram);
    setLoadingSearchTerms(true);

    const response = await axios.get(
      `${URL_PREFIX}/merchandise/search_terms?monogram=${monogram["keyword_text"]}&product_id=${product_id}`
    );

    const sts = [];
    for (let i = 0; i < response.data.search_terms.length; i++) {
      const st = response.data.search_terms[i];

      st.ctr = 0;
      st.cr = 0;
      st.acos = 0;

      if (st.impressions > 0) {
        st.ctr = st.clicks / st.impressions;
      }
      if (st.clicks > 0) {
        st.cr = st.conversions / st.clicks;
      }
      if (st.cost > 0 && st.sales > 0) {
        st.acos = st.cost / st.sales;
      }
      sts.push(st);
    }
    sts.sort(function (a, b) {
      return b.impressions - a.impressions;
    });

    setSearchTerms(sts);
    setLoadingSearchTerms(false);
  };

  const getCatalog = async (isSaved) => {
    const response = await axios.get(
      `${URL_PREFIX}/merchandise/catalog?product_id=${product.id}`
    );

    if (response?.data?.catalog) {
      const catalog = response.data.catalog;

      const images = {
        thumbnail: {},
        main: {},
      };

      if (catalog?.images) {
        catalog.images[0]["images"].forEach((image) => {
          if (
            images["thumbnail"][image.variant] === undefined &&
            image.width <= 100
          ) {
            images["thumbnail"][image.variant] = image.link;
          }
          if (
            images["main"][image.variant] === undefined &&
            image.width >= 1000
          ) {
            images["main"][image.variant] = image.link;
          }
        });
      }
      setImages(images);

      let parentASIN = null;
      if (catalog?.relationships[0]?.relationships[0]?.parentAsins[0]) {
        parentASIN = catalog.relationships[0].relationships[0].parentAsins[0];
        getVariations(parentASIN);
      }

      if (!isSaved) {
        setTitle(catalog.attributes.item_name?.[0]?.["value"]);
        if (catalog?.attributes?.bullet_point) {
          const bullets = catalog.attributes.bullet_point;
          const bulletValues = [];
          [0, 1, 2, 3, 4].forEach((i) => {
            if (!bullets[i]) {
              bulletValues[i] = "";
            } else {
              bulletValues.push(bullets[i]["value"]);
            }
          });

          setFeatureBullets(bulletValues.map((b) => b.replace("&nbsp;", " ")));
        }

        if (catalog?.attributes.generic_keyword) {
          if (catalog.attributes.generic_keyword.length > 0) {
            setGenericKeywords(catalog.attributes.generic_keyword[0].value);
          }
        }
        if (catalog?.attributes.product_description) {
          if (catalog.attributes.product_description.length > 0) {
            setDescription(catalog.attributes.product_description[0].value);
          }
        }
      }
    }
  };

  const getVariations = async (parentASIN) => {
    const response = await axios.get(
      `${URL_PREFIX}/merchandise/quality/variations?product_id=${product.id}&parent_asin=${parentASIN}`
    );
    if (response.data) {
      setVariations(response.data);
    }
  };

  const onContentChange = (field, content) => {
    setContentChanged(true);
  };

  const saveProduct = async () => {
    const revision = {
      title: title,
      description: description,
      feature_bullets: featureBullets.filter((b) => b !== ""),
      generic_keywords: genericKeywords,
    };
    scores.revision = revision;
    scores.save_date = new Date();
    scores.publish_date = null;
    setContentChanged(false);

    const res = await api.put(`/merchandise/quality/${productId}/`, scores);

    if (res.status === 200) {
      scores.save_date = res.data.save_date;
      setSaveDate(
        moment(res.data.save_date).format("dddd, MMMM Do, YYYY h:mm a")
      );
    }
  };

  const publishProduct = async () => {
    const revision = {
      title: title,
      description: description,
      feature_bullets: featureBullets.filter((b) => b !== ""),
      generic_keywords: genericKeywords,
    };
    scores.revision = revision;
    scores.publish = true;
    setContentChanged(false);

    let res;
    try {
      res = await api.put(`/merchandise/quality/${productId}/`, scores);
    } catch (err) {
      setError(err.response.data);
      return;
    }

    if (res.status === 200) {
      scores.save_date = null;
      scores.publish_date = res.data.publish_date;
      setPublishDate(
        moment(res.data.publish_date).format("dddd, MMMM Do, YYYY h:mm a")
      );
      setSaveDate(null);
    }
  };

  const resetProduct = async () => {
    scores.revision = null;
    scores.reset = true;
    setContentChanged(false);
    setError(null);

    const res = await api.put(`/merchandise/quality/${productId}/`, scores);

    if (res.status === 200) {
      setSaveDate(null);
      getCatalog();
    }
  };

  const onRemoveBullet = (index) => {
    const bullets = [...featureBullets];
    bullets.splice(index, 1);
    setFeatureBullets(bullets);
  };

  const setContent = (field, content) => {
    if (field.indexOf("featureBullets") > -1) {
      const index = parseInt(field.split(".")[1]);
      // Add Feature Bullet
      if (index > featureBullets.length - 1) {
        const bullets = [...featureBullets];
        bullets.push(content);
        setFeatureBullets(bullets);
        return;
      }
      const bullets = [...featureBullets];
      bullets[index] = content;
      setFeatureBullets(bullets);
      return;
    }

    switch (field) {
      case "title":
        setTitle(content);
        break;
      case "description":
        setDescription(content);
        break;
      case "genericKeywords":
        setGenericKeywords(content);
        break;
      default:
        break;
    }
  };

  let { currentCategoryDetails: categoryDetails, categories: categoryNavData } =
    useCategories(category, "content");

  let { currentProductDetails, products: productNavData } = useProducts(
    "content",
    category,
    productId
  );

  const breadCrumbItems = [
    {
      name: "Content Dashboard",
      href: "/user/content/quality/org",
      dropdownLinkOptions: DASHBOARD_LINKS,
      unsorted: true,
      searchable: false,
      dropdownSubtitle: "Dashboards",
    },
    {
      name: categoryDetails?.leaf_category_name,
      href: categoryDetails.href,
      dropdownLinkOptions: categoryNavData,
      searchable: true,
      searchPlaceholderText: "Search categories...",
      dropdownSubtitle: "Categories",
    },
    {
      name: currentProductDetails?.product_title,
      href: currentProductDetails?.href,
      dropdownLinkOptions: productNavData,
      searchable: true,
      searchPlaceholderText: "Search products...",
      dropdownSubtitle: "Products",
    },
  ];

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  if (loading || !product) {
    return <Loading text={"Loading Product Data"} />;
  }

  return (
    <>
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems} hideDateSelector={true} />
        </Col>
      </Row>
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid>
          <Row>
            <Col xs={12} className="pt-4">
              <Row>
                <Col xl={3} lg={4}>
                  <SideMenuTop
                    scores={scores}
                    images={images}
                    variations={variations}
                    saveDate={saveDate}
                    publishDate={publishDate}
                  />

                  <SideMenuBottom
                    getSearchTerms={getSearchTerms}
                    product={product}
                    seoKeywords={seoKeywords}
                    seoSearchTerms={seoSearchTerms}
                    quickTerm={quickTerm}
                    allContentElements={allContentElements}
                    initialHiddenKeywords={hiddenKeywords}
                  />
                </Col>

                <Col xl={9} lg={8} className="px-4">
                  <div className="card fs-standard">
                    {editorMode === "edit" && (
                      <>
                        <Row>
                          <Col xs={12} className="px-5 pt-5 pb-5">
                            {product && (
                              <>
                                <Row>
                                  <Col className="text-start">
                                    <h4
                                      style={{
                                        marginTop: "5px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {product.asin} / {product.sku}
                                    </h4>
                                  </Col>
                                  <Col className="text-end" xs={4}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <div className="pe-4">
                                        <PrimaryButton
                                          onClick={generateContent.mutate}
                                          disabled={loadingGeneratedContent}
                                        >
                                          Recommend (Beta)
                                        </PrimaryButton>
                                      </div>
                                      <div className="pe-4">
                                        {saveDate === null ? (
                                          <PrimaryButton
                                            onClick={() => {
                                              setContentChanged(false);
                                              getCatalog();
                                            }}
                                            disabled={!contentChanged}
                                          >
                                            Reset
                                          </PrimaryButton>
                                        ) : (
                                          <ConfirmationModal
                                            title={`Reset Content`}
                                            confirmationMessage={`You are about to reset the content for this product. This will remove all changes made to the content since the last save. Are you sure you want to continue?`}
                                            onConfirm={() => {
                                              resetProduct();
                                            }}
                                            confirmButtonText={`Reset`}
                                            modalButtonText={`Reset`}
                                            disabled={saveDate === null}
                                            buttonStyle={`logout_button`}
                                            modelButtonStyle={{ margin: 0 }}
                                          />
                                        )}
                                      </div>
                                      <div className="pe-4">
                                        <LogoutButton
                                          title={`Save`}
                                          disabled={!contentChanged}
                                          onClick={() => {
                                            saveProduct();
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <ConfirmationModal
                                          title={`Publish Content`}
                                          confirmationMessage={
                                            <>
                                              <div>
                                                You are about to publish the
                                                content for this product. This
                                                will make the content live on
                                                the listing page of Amazon.
                                              </div>
                                              <div className="pt-3">
                                                <strong>
                                                  NOTE: It may take up to 24
                                                  hours for the changes to
                                                  reflect on your Amazon Listing
                                                  page
                                                </strong>
                                              </div>
                                              <div className="pt-3">
                                                Are you sure you want to
                                                continue?
                                              </div>
                                            </>
                                          }
                                          onConfirm={() => {
                                            publishProduct();
                                          }}
                                          confirmButtonText={`Publish`}
                                          modalButtonText={`Publish`}
                                          disabled={!saveDate}
                                          buttonStyle={`logout_button`}
                                          modelButtonStyle={{ margin: 0 }}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} className="mt-3 mb-3">
                                    <SteppedProgressBar
                                      currentStep={step}
                                      steps={[
                                        "Analyzing keywords",
                                        "Finding similar products",
                                        "Generating content",
                                        "Finishing up",
                                      ]}
                                      isLoading={loadingGeneratedContent}
                                    />
                                    {error && (
                                      <Alert
                                        dismissible
                                        onClose={() => setError(null)}
                                        variant="danger"
                                        style={{
                                          backgroundColor: "#f8d7da",
                                          borderColor: "#f5c6cb",
                                          color: "#152748",
                                        }}
                                      >
                                        <h4 style={{ marginTop: 0 }}>
                                          <i
                                            className="fa fa-exclamation-triangle"
                                            style={{ marginRight: "15px" }}
                                          ></i>
                                          Error publishing content
                                        </h4>
                                        {error?.status === "INVALID" ? (
                                          <ul>
                                            {error.issues.map((issue) => (
                                              <li key={issue.code}>
                                                {issue.message}
                                              </li>
                                            ))}
                                          </ul>
                                        ) : (
                                          <p>
                                            An error occurred. Please try again
                                            and contact support if the error
                                            persists
                                          </p>
                                        )}
                                      </Alert>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}
                            <Row>
                              <Col className="border-bottom">
                                <Nav
                                  variant="underline"
                                  defaultActiveKey="content"
                                  {...navProps}
                                >
                                  <Nav.Item>
                                    <Nav.Link eventKey="content">
                                      Content
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="history">
                                      History
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {currentNav === "history" && (
                          <Row>
                            <Col xs={12} className="px-5 pb-5">
                              <PublishHistory
                                productQualityId={data?.data?.id}
                                onApply={(revision) => {
                                  setTitle(revision.title);
                                  setDescription(revision.description);
                                  setFeatureBullets(revision.feature_bullets);
                                  setGenericKeywords(revision.generic_keywords);
                                  setContentChanged(true);
                                }}
                              />
                            </Col>
                          </Row>
                        )}
                        {currentNav === "content" && (
                          <>
                            <Row>
                              <Col xs={12} className="px-5 pb-5">
                                <Row className="pt-4">
                                  <Col xs={7}>
                                    <h4 style={{ marginTop: 0 }}>
                                      Product Title&nbsp;
                                      <button
                                        className="fs-3"
                                        style={{
                                          border: 0,
                                          backgroundColor: "#fff",
                                        }}
                                        onClick={() =>
                                          setShowTitleHelp(!showTitleHelp)
                                        }
                                      >
                                        <i className="fa fa-info-circle"></i>
                                      </button>
                                    </h4>
                                  </Col>
                                  <Col className="pe-4" xs={5}>
                                    <ConversionLegend />
                                  </Col>
                                </Row>

                                {showTitleHelp && (
                                  <div
                                    style={{
                                      border: "1px solid #9d7eb8",
                                      backgroundColor: "#e0b7d7",
                                      padding: "10px",
                                      marginBottom: "5px",
                                      borderRadius: "5px",
                                    }}
                                    className="fs-standard"
                                  >
                                    <ul>
                                      <li>
                                        Title should not contain any special
                                        characters
                                      </li>
                                      <li>Avoid using any emojis</li>
                                      <li>
                                        Title length should be between 140 and
                                        200 characters
                                      </li>
                                      <li>
                                        It's best to have as many converting
                                        words or phrases as possible
                                      </li>
                                      <li>
                                        Do not promote your items in your title
                                        (i.e. Free Shipping, For a limited time
                                        only)
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                <ContentEditor2
                                  field={"title"}
                                  content={title}
                                  minLength={140}
                                  maxLength={200}
                                  onContentChange={onContentChange}
                                  setContent={setContent}
                                  highlightPhrases={[
                                    ...seoKeywords,
                                    ...seoSearchTerms,
                                  ]}
                                  recommendedContent={recommendations?.title}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} className="px-5 pb-5">
                                <Row className="pt-4">
                                  <Col>
                                    <h4 style={{ marginTop: 0 }}>
                                      Bullet Points&nbsp;
                                      <button
                                        className="fs-3"
                                        style={{
                                          border: 0,
                                          backgroundColor: "#fff",
                                        }}
                                        onClick={() =>
                                          setShowBulletHelp(!showBulletHelp)
                                        }
                                      >
                                        <i className="fa fa-info-circle"></i>
                                      </button>
                                    </h4>
                                  </Col>
                                  <Col className="pe-4"></Col>
                                </Row>
                                {showBulletHelp && (
                                  <div
                                    style={{
                                      border: "1px solid #9d7eb8",
                                      backgroundColor: "#e0b7d7",
                                      padding: "10px",
                                      marginBottom: "5px",
                                      borderRadius: "5px",
                                    }}
                                    className="fs-standard"
                                  >
                                    <ul>
                                      <li>Avoid using ALL CAPS</li>
                                      <li>
                                        Start each bullet point with a capital
                                        letter
                                      </li>
                                      <li>
                                        Use 5 bullet points to maximize number
                                        of keywords included in your list.
                                      </li>
                                      <li>
                                        Using 150+ characters in each your
                                        bullets will help you to maximize the
                                        number of keywords included in your
                                        list.
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                <BulletPointEditor
                                  bulletPoints={featureBullets}
                                  minLength={150}
                                  maxLength={250}
                                  onContentChange={onContentChange}
                                  setContent={setContent}
                                  highlightPhrases={[
                                    ...seoKeywords,
                                    ...seoSearchTerms,
                                  ]}
                                  onRemoveBullet={onRemoveBullet}
                                  recommendedContent={
                                    recommendations?.bullet_points
                                  }
                                />
                                <Row className="pt-4">
                                  <Col>
                                    <h4 style={{ marginTop: 0 }}>Keywords</h4>
                                  </Col>
                                </Row>
                                <ContentEditor2
                                  content={genericKeywords}
                                  seoKeywords={seoKeywords}
                                  onContentChange={onContentChange}
                                  setContent={setContent}
                                  highlightPhrases={[
                                    ...seoKeywords,
                                    ...seoSearchTerms,
                                  ]}
                                  minLength={140}
                                  maxLength={1000}
                                  field={"genericKeywords"}
                                />
                                <Row className="pt-4 mt-5">
                                  <Col>
                                    <h4 style={{ marginTop: 0 }}>
                                      Description
                                    </h4>
                                  </Col>
                                </Row>
                                <ContentEditor2
                                  richText
                                  content={description}
                                  highlightPhrases={[
                                    ...seoKeywords,
                                    ...seoSearchTerms,
                                  ]}
                                  minLength={140}
                                  maxLength={1000}
                                  onContentChange={onContentChange}
                                  field={"description"}
                                  setContent={setContent}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}

                    {editorMode === "metrics" && (
                      <>
                        <Row>
                          <Col xs={12} className="px-5 pt-3 text-end">
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() => {
                                setEditorMode("edit");
                                // setQuickSearch(null);
                              }}
                              aria-label="Close"
                              style={{ fontSize: "20px" }}
                            ></button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="px-5 pt-5 pb-5">
                            <h4 style={{ marginTop: 0 }}>
                              Keyword Performance{" "}
                              {keyword && (
                                <Badge bg={getBadgeVariant(keyword)}>
                                  {keyword["keyword_text"]}
                                </Badge>
                              )}
                            </h4>
                            {!keyword && (
                              <Row className="fs-standard">
                                <Col className="text-center">
                                  Select a keyword to view performance
                                </Col>
                              </Row>
                            )}
                            {keyword && (
                              <Row className="fs-standard">
                                <Col xs={12}>
                                  <Table striped bordered hover>
                                    <thead>
                                      <tr>
                                        <th className="text-end">
                                          <strong>Impressions</strong>
                                        </th>
                                        <th className="text-end">
                                          <strong>Clicks</strong>
                                        </th>
                                        <th className="text-end">
                                          <strong>CTR</strong>
                                        </th>
                                        <th className="text-end">
                                          <strong>Conversion</strong>s
                                        </th>
                                        <th className="text-end">
                                          <strong>CR</strong>
                                        </th>
                                        <th className="text-end">
                                          <strong>Sales</strong>
                                        </th>
                                        <th className="text-end">
                                          <strong>ACoS</strong>
                                        </th>
                                        <th className="text-end">
                                          <strong>Cost</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="text-end">
                                          {keyword.impressions}
                                        </td>
                                        <td className="text-end">
                                          {keyword.clicks}
                                        </td>
                                        <td className="text-end">
                                          {(keyword.ctr * 100).toFixed(2)}%
                                        </td>
                                        <td className="text-end">
                                          {keyword.conversions}
                                        </td>
                                        <td className="text-end">
                                          {(keyword.cr * 100).toFixed(2)}%
                                        </td>
                                        <td className="text-end">
                                          {formatCurrency(
                                            keyword.sales,
                                            marketPlace.marketPlace
                                          )}
                                        </td>
                                        <td className="text-end">
                                          {(keyword.acos * 100).toFixed(2)}%
                                        </td>
                                        <td className="text-end">
                                          {formatCurrency(
                                            keyword.cost,
                                            marketPlace.marketPlace
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="px-5 pt-2 pb-5">
                            <h4 style={{ marginTop: 0 }}>
                              Associated Search Terms
                            </h4>
                            {!searchTerms && !loadingSearchTerms && (
                              <Row className="fs-standard">
                                <Col className="text-center">
                                  Select a keyword to view the search terms
                                </Col>
                              </Row>
                            )}
                            {loadingSearchTerms && (
                              <Row className="fs-standard">
                                <Col className="text-center">
                                  Finding search terms...
                                </Col>
                              </Row>
                            )}

                            {!loadingSearchTerms && searchTerms && (
                              <>
                                <p>
                                  Below are the search terms, that contains the
                                  keyword{" "}
                                  <Badge bg={getBadgeVariant(keyword)}>
                                    {keyword["keyword_text"]}
                                  </Badge>
                                  , which have generated sales on the
                                  advertisements that contain this product (
                                  <strong>
                                    {product.sku} / {product.asin}
                                  </strong>
                                  ).
                                </p>
                                <div
                                  style={{
                                    maxHeight: "500px",
                                    minHeight: "200px",
                                    overflowY: "auto",
                                  }}
                                >
                                  <Table striped hover bordered>
                                    <thead>
                                      <tr>
                                        <th style={{ textAlign: "left" }}>
                                          <strong>Search Term</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>Impressions</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>Clicks</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>CTR</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>CONVERSIONS</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>CR</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>Sales</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>Cost</strong>
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          <strong>ACOS</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {searchTerms.map((term, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <DropdownButton
                                                title={term.keyword_text}
                                                bsPrefix="dropdown-menu-seo"
                                              >
                                                <Dropdown.Item
                                                  href={`/user/content/explore#q=${term.keyword_text}`}
                                                >
                                                  View Keyword Explorer
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    setQuickTerm(
                                                      term.keyword_text
                                                    )
                                                  }
                                                >
                                                  Quick Explorer
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">
                                                  Add keyword to Media Plan
                                                </Dropdown.Item>
                                              </DropdownButton>
                                            </td>
                                            <td className="text-end">
                                              {formatNumber(term.impressions)}
                                            </td>
                                            <td className="text-end">
                                              {formatNumber(term.clicks)}
                                            </td>
                                            <td className="text-end">
                                              {formatPercent(term.ctr, 2)}%
                                            </td>
                                            <td className="text-end">
                                              {formatNumber(term.conversions)}
                                            </td>
                                            <td className="text-end">
                                              {formatPercent(term.cr, 2)}%
                                            </td>
                                            <td className="text-end">
                                              {formatCurrency(
                                                term.sales,
                                                marketPlace.marketPlace
                                              )}
                                            </td>
                                            <td className="text-end">
                                              {formatCurrency(
                                                term.cost,
                                                marketPlace.marketPlace
                                              )}
                                            </td>
                                            <td className="text-end">
                                              {formatPercent(term.acos, 2)}%
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
};

ProductSEO.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  user: state.user,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(ProductSEO);
