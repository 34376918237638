import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import { getURLPrefix } from "utils/getUrlPrefix";
import { LogoutButton } from "components/core/basic/Button.jsx";
import FormGroupTile from "components/core/basic/FormGroupTile";

let URL_PREFIX = getURLPrefix();

const AdSelection = ({
  asin,
  showAddCompetitor,
  setShowAddCompetitor,
  marketplace,
  setIsLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [noFind, setNoFind] = useState(false);
  const [product, setProduct] = useState(null);
  const [adding, setAdding] = useState(false);

  const searchProducts = async () => {
    let url = `${URL_PREFIX}/api/gvads/productid/?marketplace=${marketplace}&asins=${searchTerm}`;
    const resASIN = await axios.get(url);

    url = `${URL_PREFIX}/api/gvads/productid/?marketplace=${marketplace}&skus=${searchTerm}`;
    const resSKUS = await axios.get(url);

    if (resASIN.data.length === 0 && resSKUS.data.length === 0) {
      setNoFind(true);
    } else {
      setNoFind(false);
    }

    if (resASIN.data.length > 0) {
      setProduct(resASIN.data[0]);
    } else if (resSKUS.data.length > 0) {
      setProduct(resSKUS.data[0]);
    }
  };

  const addCompetitor = async () => {
    setAdding(true);
    let url = `${URL_PREFIX}/api/competitors/`;
    await axios.post(url, {
      marketplace: marketplace,
      product: product.id,
      url: `https://www.amazon.com/dp/${asin}`,
    });

    setAdding(false);
    setIsLoading(true);
  };

  useEffect(() => {
    setSearchTerm(null);
    setProduct(null);
    setNoFind(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asin, showAddCompetitor]);

  const hideModal = () => {
    setShowAddCompetitor(false);
  };

  return (
    <Modal show={showAddCompetitor} onHide={() => hideModal()} size="xm">
      <Modal.Header closeButton={!adding} className="py-0 px-5">
        <div className="fs-3 my-4 text-center">
          Add <strong>{asin}</strong> as a competitor
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div className="fs-4 pb-4">
              Enter <em>your</em> ASIN or SKU that this asin is competing with
            </div>
            <div className="fs-4 pb-4">
              <FormGroupTile
                type="formcontrol"
                prefix={<i className="fa fa-search" />}
                prefixSide={"left"}
                formControlType={"text"}
                handleChildFormElement={(key, value) => {
                  setSearchTerm(value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    searchProducts();
                  }
                }}
                placeholder={`Enter Your ASIN or SKU`}
                defaultValue={searchTerm}
              />
            </div>
            {noFind && (
              <div className="fs-4 py-4 text-danger text-center">
                No products found matching your query
              </div>
            )}

            {adding && product && (
              <div className="fs-4 py-4 text-center">
                The competitive product is being added. This may take a few
                seconds.
              </div>
            )}

            {!adding && product && (
              <div className="fs-4 pb-4 text-center">
                <div className="fs-4 pb-2 text-center">
                  <img
                    src={product.product_image_url}
                    alt={product.product_title}
                    style={{ width: "100px" }}
                  />
                </div>
                <div className="fs-4 py-2 text-center">
                  {product.product_title}
                </div>
                <Row>
                  <Col xs={6} className="mx-auto text-center">
                    <strong>ASIN</strong>: {product.asin}
                  </Col>
                  <Col xs={6} className="mx-auto text-center">
                    <strong>SKU</strong>: {product.sku}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="py-4" style={{ width: "100%" }}>
          <Col xs={6} className="mx-auto text-start">
            <LogoutButton
              key="RefreshBtn"
              title={`Close`}
              onClick={() => hideModal()}
              disabled={adding}
            />
          </Col>

          <Col xs={6} className="mx-auto text-end">
            {product && (
              <LogoutButton
                fontSize="12px"
                key="AddBtn"
                title={`Add`}
                onClick={() => addCompetitor()}
                disabled={adding}
              />
            )}

            {!product && (
              <LogoutButton
                fontSize="12px"
                key="SearchBtn"
                title={`Search`}
                onClick={() => searchProducts()}
              />
            )}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AdSelection;
