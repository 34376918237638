import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import api from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/authActions";
import Loading from "components/core/blocks/Loading";

const VerifyEmail = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const verifyEmailToken = useMutation({
    mutationFn: async (token) => {
      return await api.post("/api/verify_email/", { token });
    },
    onSuccess: async (res, variables) => {
      dispatch(
        setCurrentUser({ ...user, email_verified_on: new Date().toISOString() })
      );
      history.push("/user/dashboard?verify=true");
    },
    onError: (error) => {
      history.push("/user/dashboard?verify=false");
    },
  });

  if (location.search.indexOf("user_token=") > -1) {
    const userToken = new URLSearchParams(location.search).get("user_token");
    verifyEmailToken.mutate(userToken);
  } else {
    history.push("/user/dashboard");
  }

  return <Loading />;
};

export default VerifyEmail;
