import React from "react";
import Checkbox from "components/core/basic/CheckBox";
import AdPlanModal from "components/core/blocks/AdPlan/AdPlanModal";
import { AD_TYPES, GOALS } from "components/core/blocks/AdPlan/constants";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { UpdateButton } from "components/core/blocks/AdPlan/buttons";
import { formatNumber } from "utils/formatNumber";
import { Link } from "react-router-dom";
import AdvertisingIcon from "assets/images/icons/placement.svg";
import PricingIcon from "assets/images/icons/pricing.svg";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";

const LaunchTask = ({
  task: { title, description, cta, icon },
  completed,
  hideIcon = false,
  small,
  last,
}) => (
  <Row>
    <Col xs={12} md={12}>
      <div
        className={small ? "" : "card"}
        style={{
          borderBottom: small && !last ? "1px solid #CCC" : "none",
          marginBottom: (small && last) || !small ? "2rem" : "inherit",
        }}
      >
        <div
          className="card-body"
          style={{ padding: small ? "1rem" : "2rem 4rem" }}
        >
          <Row style={{ display: "flex", paddingBottom: "2rem" }}>
            <Col xs={8}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ pointerEvents: "none", marginTop: "-30px" }}>
                  <Checkbox checked={completed} />
                </span>

                <h3
                  style={{
                    margin: "2rem 0",
                    fontSize: "2.5rem",
                    color: "#403E3D",
                    fontWeight: "700",
                    paddingLeft: "1rem",
                  }}
                >
                  {title}
                </h3>
              </div>
              <Row style={{ paddingLeft: 0 }}>
                {!hideIcon && (
                  <Col xs={1}>
                    <img
                      src={icon}
                      alt={"An icon with a rocket ship launching into space."}
                      style={{
                        height: "80px",
                        width: "80px",
                      }}
                    />
                  </Col>
                )}
                <Col
                  xs={11}
                  style={{ paddingLeft: "2rem" }}
                  className="d-flex flex-column justify-content-center"
                >
                  {description}
                </Col>
              </Row>
            </Col>
            <Col
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "1rem",
              }}
            >
              {cta}
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  </Row>
);

const LaunchPlanningDialogue = ({
  launchData,
  categories,
  modules,
  user,
  amz_onboard,
}) => {
  let nextSteps = [];

  nextSteps.push({
    title: "Placement Advertising",
    description: (
      <>
        <p style={{ color: "#73706E" }}>
          Start by creating an advertisement to promote your products.
        </p>
        <p style={{ color: "#73706E" }}>
          We've pre-populated most fields with the goals you outlined earlier,
          but you can also tweak fields based on your needs.
        </p>
      </>
    ),
    cta: (
      <div style={{ minWidth: "200px" }}>
        <AdPlanModal
          categories={categories}
          initialAdPlan={{
            name: `${launchData?.name} Media Plan`,
            adType: AD_TYPES.SPONSORED_PRODUCT.value,
            product: launchData?.products ?? [],
            goal: GOALS.PRODUCT_LAUNCH.value,
            targetACOS: launchData?.goal_metrics?.acos,
            maxACOS: launchData?.goal_metrics?.acos + 10,
            dailyBudget: formatNumber(
              launchData?.advertising_budget /
                moment(launchData?.end_date).diff(
                  moment(launchData?.start_date),
                  "days"
                ),
              {},
              2
            ),
            categoryId: launchData?.category,
          }}
          altModalButton={
            <UpdateButton
              text={"Create Media Plan"}
              buttonStyles={{ fontSize: "14px" }}
            />
          }
        />
      </div>
    ),
    icon: AdvertisingIcon,
  });

  if (
    (checkModule(modules, "PRICING") ||
      !checkTierAccess(user.tier, "pricing", amz_onboard.org_status) ||
      (user.pricing_tier && user.pricing_tier !== "unknown")) &&
    user.pricing_tier !== "MANUALLY_DISABLED"
  ) {
    nextSteps.push({
      title: "Pricing",
      description: (
        <>
          <p style={{ color: "#73706E" }}>
            Boost your products popularity by optimizing price.
          </p>
          <p style={{ color: "#73706E" }}>
            By tracking and understanding your users' behavior, Trellis can
            optimize for sales growth, profit margins, or net profit.
          </p>
        </>
      ),
      cta: (
        <div style={{ minWidth: "200px" }}>
          <Link
            to={`/user/pricing/enrolled#buyableOnly=false`}
            style={{ textDecoration: "none" }}
          >
            <UpdateButton
              text={"View Pricing"}
              buttonStyles={{ fontSize: "14px" }}
            />
          </Link>
        </div>
      ),
      icon: PricingIcon,
    });
  }

  return (
    <Col xs={12}>
      {/* {nextSteps.length > 0 && ( */}
      <Row className="pl-5">
        {nextSteps.map((task) => (
          <LaunchTask task={task} completed={false} />
        ))}
      </Row>
      {/* )} */}
    </Col>
  );
};

export default LaunchPlanningDialogue;
