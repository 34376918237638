import { useState, useEffect } from "react";

const useScrollableData = (data, pageNumber, pageSize) => {
  const [renderData, setRenderData] = useState([]);
  const [prevPage, setPrevPage] = useState(0);

  useEffect(() => {
    if (data && data?.length > 0) {
      setRenderData(data?.slice(0, pageSize ?? []));
    }
  }, [data, pageSize]);

  // Reset if data passed in changes
  useEffect(() => {
    setRenderData([]);
  }, [data]);

  useEffect(() => {
    if (pageNumber !== prevPage) {
      setPrevPage((prevPrevPage) => prevPrevPage + 1);

      if (data?.length > 0) {
        setRenderData((prevRenderData) => {
          return [...data?.slice(0, pageNumber * pageSize + pageSize)];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, data, pageSize]);

  return renderData;
};

export default useScrollableData;
