import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { LogoutButton } from "components/core/basic/Button";
import { LinkButton } from "components/core/basic/Button";
import api from "utils/api";

const AddKeywordModal = ({
  showAddKWModal,
  setShowAddKWModal,
  marketPlace,
  asin,
}) => {
  const [adding, setAdding] = useState(false);
  const [addDone, setAddDone] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [suggestAction, setSuggestAction] = useState(false);

  useEffect(() => {
    setAdding(false);
    setAddDone(false);
    setKeywords("");
  }, [showAddKWModal]);

  const postKeywords = async () => {
    const res = await api.post(
      `/merchandise/seo/add_keywords?market_place=${marketPlace.marketPlace}`,
      {
        keywords: keywords.split("\n"),
      }
    );

    if (res.status === 200) {
      //   setShowAddKWModal(false);
      setAdding(false);
      setAddDone(true);
    }
  };

  const retrieveSuggestions = async () => {
    setSuggestAction(true);

    const res = await api.get(
      `/merchandise/seo/suggested_keywords?market_place=${marketPlace.marketPlace}&asin=${asin}`
    );

    const keywords = res.data.tokens.map((kw) => kw);
    setKeywords(keywords.slice(0, 10).join("\n"));

    setSuggestAction(false);
  };

  const addKeywords = () => {
    setAdding(true);
    postKeywords();
  };

  const hideModal = () => {
    setShowAddKWModal(false);
  };
  return (
    <Modal show={showAddKWModal} onHide={() => hideModal()} size="xm">
      <Modal.Header closeButton={!adding} className="py-0 px-5">
        <div className="fs-3 my-4 text-center">Add Keywords</div>
      </Modal.Header>
      <Modal.Body>
        {!addDone && (
          <>
            <Row>
              <Col>
                <div className="fs-4 pb-4 text-center">
                  Enter the keywords you wish to add, <em>one per line</em>.
                </div>

                <div>
                  <textarea
                    className="form-control text-start fs-4"
                    id="txtKeywords"
                    style={{
                      height: "200px",
                      width: "100%",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                    disabled={suggestAction}
                    onChange={(e) => {
                      setKeywords(e.target.value);
                    }}
                    defaultValue={keywords}
                  ></textarea>
                </div>
              </Col>
            </Row>
            {asin && (
              <Row className="fs-5 pt-2 text-center">
                <Col>
                  <LinkButton
                    onClick={() => {
                      retrieveSuggestions();
                    }}
                    disabled={suggestAction}
                  >
                    Retrieve suggested keywords
                  </LinkButton>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="text-center">
                <div className="fs-5 py-4">
                  <em>
                    <strong>Note:</strong> Keywords must be 1-100 characters
                    long and contain only letters, numbers, spaces.
                  </em>
                </div>
              </Col>
            </Row>
          </>
        )}
        {addDone && (
          <Row>
            <Col>
              <div className="fs-4 py-4 text-center">
                Keywords added successfully.
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="py-4" style={{ width: "100%" }}>
          <Col xs={6} className="mx-auto text-start">
            <LogoutButton
              key="RefreshBtn"
              title={`Close`}
              onClick={() => hideModal()}
              disabled={adding}
            />
          </Col>

          <Col xs={6} className="mx-auto text-end">
            <LogoutButton
              fontSize="12px"
              key="AddBtn"
              title={`Add`}
              onClick={() => {
                addKeywords();
              }}
              disabled={adding || addDone || suggestAction}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AddKeywordModal;
