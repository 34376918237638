import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Modal, Table, Badge } from "react-bootstrap";
import { useFetch } from "hooks/api";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import Loading from "components/core/blocks/Loading";
import useColumnSelector from "hooks/useColumnSelector";
import { formatCurrency, formatNumber } from "utils/formatNumber";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { FaPlusCircle, FaEdit, FaMinusCircle } from "react-icons/fa";
import FormGroupTile from "components/core/basic/FormGroupTile";
import Checkbox from "components/core/basic/CheckBox";
import axios from "axios";
import getURLPrefix from "utils/getUrlPrefix";
import { useQueryClient } from "react-query";

let URL_PREFIX = getURLPrefix();

const RelationshipSection = ({ pricingPlan, marketPlace, hasVariation }) => {
  const actionTypeOptions = [
    { label: "Not Set", value: "not_set" },
    { label: `Equal`, value: "match" },
    { label: `Less than`, value: "decrease" },
    { label: `Greater than`, value: "increase" },
  ];

  const queryClient = useQueryClient();

  const [filteredData, setFilteredData] = useState(null);
  const [search, setSearch] = useState("");
  const [colToggle] = useState();
  const [showAddRelationship, setShowAddRelationship] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState(null);
  const [parentPlan, setParentPlan] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [relatedMinAction, setRelatedMinAction] = useState(
    actionTypeOptions[0]
  );
  const [relatedMinValue, setRelatedMinValue] = useState(0);
  const [relatedMinUnit, setRelatedMinUnit] = useState("percent");
  const [newMinPrice, setNewMinPrice] = useState(null);

  const [relatedMaxAction, setRelatedMaxAction] = useState(
    actionTypeOptions[0]
  );
  const [relatedMaxValue, setRelatedMaxValue] = useState(0);
  const [relatedMaxUnit, setRelatedMaxUnit] = useState("percent");
  const [newMaxPrice, setNewMaxPrice] = useState(null);

  const [relatedMode, setRelatedMode] = useState("new");
  const [savingRelationship, setSavingRelationship] = useState(false);

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const tableRef = useRef();

  const { data, isLoading } = useFetch(
    ["pricingPlanRelationships", marketPlace.marketPlace],
    `/pricing/pricingplan/relationships?pricing_plan_id=${pricingPlan.id}`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data: planNames, isLoading: planIsLoading } = useFetch(
    ["pricingPlanNames", marketPlace.marketPlace],
    `/pricing/pricingplan/names`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const hideModal = () => {
    setFilteredPlans([]);
    setParentPlan(null);
    setSelectedPlan(null);

    setRelatedMinAction(actionTypeOptions[0]);
    setRelatedMinValue(null);
    setRelatedMinUnit("percent");
    setNewMinPrice(null);

    setRelatedMaxAction(actionTypeOptions[0]);
    setRelatedMaxValue(null);
    setRelatedMaxUnit("percent");
    setNewMaxPrice(null);

    setRelatedMode("new");
    setSavingRelationship(false);
    setShowAddRelationship(false);
  };

  const hideRemoveModal = () => {
    setSelectedPlan(null);
    setSavingRelationship(false);
    setShowRemoveModal(false);
  };

  const sendRelated = () => {
    if (!selectedPlan) return;

    let rule = {};

    setSavingRelationship(true);

    if (relatedMinAction.value !== "not_set") {
      rule.min_price = {
        unit: relatedMinUnit,
        value: parseFloat(relatedMinValue),
        action: relatedMinAction.value,
      };
    }
    if (relatedMaxAction.value !== "not_set") {
      rule.max_price = {
        unit: relatedMaxUnit,
        value: parseFloat(relatedMaxValue),
        action: relatedMaxAction.value,
      };
    }

    let data = {
      rule: rule,
      parent_plan_id: parentPlan.id,
      related_plan_id: selectedPlan.id,
    };

    axios
      .post(`${URL_PREFIX}/pricing/pricingplan/relationships/set`, data)
      .then((res) => {
        hideModal();
        queryClient.invalidateQueries("pricingPlanRelationships");
      });
  };

  const sendRemoveRelationship = () => {
    if (!selectedPlan) return;

    setSavingRelationship(true);

    let data = {
      related_plan_id: selectedPlan.id,
    };

    axios
      .post(`${URL_PREFIX}/pricing/pricingplan/relationships/remove`, data)
      .then((res) => {
        hideRemoveModal();
        queryClient.invalidateQueries("pricingPlanRelationships");
      });
  };

  const getPricingPlan = (id) => {
    if (!data) return null;

    let plan = data.find((d) => {
      return d.id === id;
    });
    return plan;
  };

  const getPrice = (plan, price, isMin) => {
    if (!plan) return null;

    let rule = null;
    if (plan.relationship_rules.min_price && isMin) {
      rule = plan.relationship_rules.min_price;
    } else if (plan.relationship_rules.max_price && !isMin) {
      rule = plan.relationship_rules.max_price;
    }
    if (!rule) {
      return price;
    }

    if (rule.unit === "percent") {
      return (price * rule.value) / 100;
    }
    return price + rule.value;
  };

  const calcNewPrice = () => {
    if (relatedMinAction.value === "match") {
      setNewMinPrice(parentPlan.best_price);
    } else if (relatedMinAction.value === "decrease") {
      if (relatedMinUnit === "percent") {
        setNewMinPrice(
          (parentPlan.best_price * (parseFloat(relatedMinValue) * -1)) / 100
        );
      } else {
        setNewMinPrice(parentPlan.best_price - parseFloat(relatedMinValue));
      }
    } else if (relatedMinAction.value === "increase") {
      if (relatedMinUnit === "percent") {
        setNewMinPrice(
          (parentPlan.best_price * parseFloat(relatedMinValue)) / 100
        );
      } else {
        setNewMinPrice(parentPlan.best_price + parseFloat(relatedMinValue));
      }
    }

    if (relatedMaxAction.value === "match") {
      setNewMaxPrice(parentPlan.best_price);
    } else if (relatedMaxAction.value === "decrease") {
      if (relatedMaxUnit === "percent") {
        setNewMaxPrice(
          (parentPlan.best_price * (parseFloat(relatedMaxValue) * -1)) / 100
        );
      } else {
        setNewMaxPrice(parentPlan.best_price - parseFloat(relatedMaxValue));
      }
    } else if (relatedMaxAction.value === "increase") {
      if (relatedMaxUnit === "percent") {
        setNewMaxPrice(
          (parentPlan.best_price * parseFloat(relatedMaxValue)) / 100
        );
      } else {
        setNewMaxPrice(parentPlan.best_price + parseFloat(relatedMaxValue));
      }
    }
  };

  useEffect(() => {
    if (parentPlan) {
      calcNewPrice();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parentPlan,
    selectedPlan,
    relatedMinAction,
    relatedMinUnit,
    relatedMinValue,
  ]);

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let columns = [
        {
          id: "PricingPlan",
          label: "Pricing Plan",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span style={{ position: "relative" }}>Pricing Plan</span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "left" },
          style: { textAlign: "left" },
          accessor: "plan_name",
          Cell: (props) => {
            return (
              <a
                href={`/user/_pricing/plan/${props.original.id}`}
                rel="noopener noreferrer"
              >
                {props.value} ({props.original.id})
              </a>
            );
          },
        },
        {
          id: "Type",
          label: "Type",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ position: "relative" }}></p>
              <span style={{ position: "relative", paddingRight: "1rem" }}>
                Type
              </span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "id",
          Cell: (props) => {
            return "Pricing Plan";
          },
        },
        {
          id: "Price",
          label: "Price",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ position: "relative" }}></p>
              <span style={{ position: "relative", paddingRight: "1rem" }}>
                Price
              </span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "best_price",
          Cell: (props) => {
            return formatCurrency(props.original.best_price, marketPlace);
          },
        },
        {
          id: "MinPrice",
          label: "MinPrice",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ position: "relative" }}></p>
              <span style={{ position: "relative", paddingRight: "1rem" }}>
                Minimum Price
              </span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "relationship",
          Cell: (props) => {
            if (props.original.relationship) {
              if (props.original.relationship_rules.min_price) {
                let rule = "";
                if (
                  props.original.relationship_rules.min_price.action ===
                  "increase"
                ) {
                  rule += "Increase by ";
                } else if (
                  props.original.relationship_rules.min_price.action ===
                  "decrease"
                ) {
                  rule += "Decrease by ";
                }
                if (
                  props.original.relationship_rules.min_price.unit === "percent"
                ) {
                  rule += `${props.original.relationship_rules.min_price.value.toFixed(
                    2
                  )}%`;
                } else {
                  rule += `${formatCurrency(
                    props.original.relationship_rules.min_price.value,
                    marketPlace
                  )}`;
                }

                const p = getPricingPlan(props.original.relationship);
                return (
                  <>
                    <div>
                      {formatCurrency(
                        getPrice(props.original, p.best_price, true),
                        marketPlace
                      )}
                    </div>
                    <small>
                      <span>{formatCurrency(p.best_price, marketPlace)}</span>{" "}
                      <span>{rule}</span>
                    </small>
                  </>
                );
              }
              return `${formatCurrency(props.original.min_price, marketPlace)}`;
            } else {
              return formatCurrency(props.original.min_price, marketPlace);
            }
          },
        },
        {
          id: "MaxPrice",
          label: "MaxPrice",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ position: "relative" }}></p>
              <span style={{ position: "relative", paddingRight: "1rem" }}>
                Maximum Price
              </span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "relationship",
          Cell: (props) => {
            if (props.original.relationship) {
              if (props.original.relationship_rules.max_price) {
                let rule = "";
                if (
                  props.original.relationship_rules.max_price.action ===
                  "increase"
                ) {
                  rule += "Increase by ";
                } else if (
                  props.original.relationship_rules.max_price.action ===
                  "decrease"
                ) {
                  rule += "Decrease by ";
                }
                if (
                  props.original.relationship_rules.max_price.unit === "percent"
                ) {
                  rule += `${props.original.relationship_rules.max_price.value.toFixed(
                    2
                  )}%`;
                } else {
                  rule += `${formatCurrency(
                    props.original.relationship_rules.max_price.value,
                    marketPlace
                  )}`;
                }

                const p = getPricingPlan(props.original.relationship);
                return (
                  <>
                    <div>
                      {formatCurrency(
                        getPrice(props.original, p.best_price, false),
                        marketPlace
                      )}
                    </div>
                    <small>
                      <span>{formatCurrency(p.best_price, marketPlace)}</span>{" "}
                      <span>{rule}</span>
                    </small>
                  </>
                );
              }
              return `${formatCurrency(props.original.max_price, marketPlace)}`;
            } else {
              return formatCurrency(props.original.max_price, marketPlace);
            }
          },
        },
        {
          id: "Strategy",
          label: "Strategy",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ position: "relative" }}></p>
              <span style={{ position: "relative", paddingRight: "1rem" }}>
                Strategy
              </span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "relationship",
          Cell: (props) => {
            if (
              props.original.enroll_date &&
              props.original.configured_slider_value >= 0 &&
              props.original.configured_slider_value <= 0
            ) {
              return (
                <Badge
                  className={`badge bg-trellis-purple text-end`}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <span>Balanced</span>
                </Badge>
              );
            } else if (
              props.original.enroll_date &&
              props.original.configured_slider_value < 0
            ) {
              return (
                <Badge
                  className={`badge bg-trellis-purple text-end`}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <span>Volume</span>
                </Badge>
              );
            } else if (
              props.original.enroll_date &&
              props.original.configured_slider_value > 0
            ) {
              return (
                <Badge
                  className={`badge bg-trellis-purple text-end`}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <span>Margin</span>
                </Badge>
              );
            }
            return <span>n/a</span>;
          },
        },
        {
          id: "Actions",
          label: "Actions",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ position: "relative" }}></p>
              <span style={{ position: "relative", paddingRight: "1rem" }}>
                &nbsp;
              </span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "relationship",
          Cell: (props) => {
            const elements = [];
            if (true) {
              elements.push(
                <span className="px-2">
                  <button
                    style={{ border: "0px", backgroundColor: "#fff" }}
                    onClick={() => {
                      setNewMinPrice(props.original.best_price);
                      setNewMaxPrice(props.original.best_price);
                      setParentPlan(props.original);
                      setShowAddRelationship(true);
                    }}
                  >
                    <FaPlusCircle />
                  </button>
                </span>
              );
            }

            if (props.index !== 0) {
              elements.push(
                <span className="px-2">
                  <button
                    style={{ border: "0px", backgroundColor: "#fff" }}
                    onClick={() => {
                      setParentPlan(data[props.index - 1]);
                      setSelectedPlan(props.original);
                      setRelatedMode("edit");

                      const rules = props.original.relationship_rules;
                      if (rules.min_price) {
                        if (rules.min_price.action === "increase") {
                          setRelatedMinAction(actionTypeOptions[3]);
                        } else if (rules.min_price.action === "decrease") {
                          setRelatedMinAction(actionTypeOptions[2]);
                        } else if (rules.min_price.action === "match") {
                          setRelatedMinAction(actionTypeOptions[1]);
                        } else {
                          setRelatedMinAction(actionTypeOptions[0]);
                        }
                        setRelatedMinValue(rules.min_price.value);
                        setRelatedMinUnit(rules.min_price.unit);
                      }
                      if (rules.max_price) {
                        if (rules.max_price.action === "increase") {
                          setRelatedMaxAction(actionTypeOptions[3]);
                        } else if (rules.max_price.action === "decrease") {
                          setRelatedMaxAction(actionTypeOptions[2]);
                        } else if (rules.max_price.action === "match") {
                          setRelatedMaxAction(actionTypeOptions[1]);
                        } else {
                          setRelatedMaxAction(actionTypeOptions[0]);
                        }
                        setRelatedMaxValue(rules.max_price.value);
                        setRelatedMaxUnit(rules.max_price.unit);
                      }
                      setShowAddRelationship(true);
                    }}
                  >
                    <FaEdit />
                  </button>
                </span>
              );

              elements.push(
                <span className="px-2">
                  <button
                    style={{ border: "0px", backgroundColor: "#fff" }}
                    onClick={() => {
                      setSelectedPlan(props.original);
                      setShowRemoveModal(true);
                    }}
                  >
                    <FaMinusCircle />
                  </button>
                </span>
              );
            }

            return elements;
          },
        },
      ];

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle]
  );

  if (isLoading || planIsLoading)
    return <Loading fullPage={false} height={`300px`} />;

  return (
    <>
      <Row className="p-3">
        <Col xs={12} className="text-start">
          <ReactTable
            ref={tableRef}
            data={filteredData ? filteredData : data}
            className="pricing-table"
            PaginationComponent={(props) => {
              return (
                <ReactTablePagination
                  {...props}
                  table={"pricing-table"}
                  showFilters={false}
                  style={{ marginBottom: "2rem" }}
                  carouselLayout={true}
                  hideFilters={true}
                  updateSearch={setSearch}
                  clearSearchTermFilters={() => setSearch("")}
                  searchTerm={search}
                  columnSelectorProps={columnSelectorProps}
                  hideSearch={true}
                />
              );
            }}
            columns={columns}
            defaultPageSize={20}
            // Below is needed to eliminate the no data message that covers table headers if no data to render
            NoDataComponent={() => null}
            minRows={0}
            showPaginationTop
            showPaginationBottom={false}
            filterable={false}
          />
        </Col>
      </Row>

      <Modal show={showRemoveModal} onHide={() => hideRemoveModal()} size="lg">
        <Modal.Header closeButton={true} className="py-0 px-5">
          <div className="fs-3 my-4 text-center">Remove Relationships</div>
        </Modal.Header>
        <Modal.Body>
          {!savingRelationship && (
            <Row className="py-3 px-4">
              <Col>
                <p className="fs-4">
                  Removing this relationship will also eliminate all dependent
                  relationships.
                </p>
                <p className="fs-4">Would you like to proceed?</p>
              </Col>
            </Row>
          )}

          {savingRelationship && (
            <Row>
              <Col>
                <Loading fullPage={false} height={`200px`} />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row className="py-4" style={{ width: "100%" }}>
            <Col xs={6} className="mx-auto text-start">
              <LogoutButton
                key="RefreshBtn"
                title={`Close`}
                onClick={() => hideRemoveModal()}
              />
            </Col>
            <Col xs={6} className="mx-auto text-start">
              <LogoutButton
                key="RefreshBtn"
                title={`Remove Relationship`}
                onClick={() => sendRemoveRelationship()}
                disabled={false}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddRelationship} onHide={() => hideModal()} size="lg">
        <Modal.Header closeButton={true} className="py-0 px-5">
          <div className="fs-3 my-4 text-center">Add a New Relationship</div>
        </Modal.Header>
        <Modal.Body>
          {!savingRelationship && relatedMode === "new" && (
            <Row>
              <Col xs={2}>&nbsp;</Col>
              <Col xs={8}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for a pricing plan"
                  onChange={(e) => {
                    setSelectedPlan(null);
                    let val = e.target.value.toLocaleLowerCase();
                    if (val === "") {
                      setFilteredPlans([]);
                    } else {
                      let filtered = planNames.filter((d) => {
                        return d.plan_name.toLowerCase().includes(val);
                      });
                      setFilteredPlans(filtered);
                    }
                  }}
                />
              </Col>
              <Col xs={2}>&nbsp;</Col>
            </Row>
          )}
          {!savingRelationship && filteredPlans && filteredPlans.length > 0 && (
            <Row>
              <Col>
                <Table striped bordered hover condensed>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Plan Name</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  {filteredPlans.length > 10 && (
                    <tbody>
                      <tr>
                        <td colspan="2">
                          Too many results, please refine your search
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {filteredPlans.length <= 10 && (
                    <tbody>
                      {filteredPlans &&
                        filteredPlans.map((d) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                {d.plan_name} ({d.id})
                              </td>
                              <td style={{ width: "100px" }}>
                                <LogoutButton
                                  key="RefreshBtn"
                                  title={`Select`}
                                  onClick={() => {
                                    setSelectedPlan(d);
                                    setFilteredPlans([]);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </Col>
            </Row>
          )}

          {!savingRelationship && selectedPlan && (
            <>
              <Row className="py-5 px-4">
                <Col>
                  <span className="fs-4 fw-bold">Selected Plan</span>
                  <Table striped bordered hover condensed>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>Rel</th>
                        <th style={{ textAlign: "left" }}>Plan Name</th>
                        <th style={{ textAlign: "center" }}>Current Price</th>
                        <th style={{ textAlign: "center" }}>Min Price</th>
                        <th style={{ textAlign: "center" }}>Max Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parentPlan && (
                        <tr>
                          <td style={{ textAlign: "left" }}>Parent</td>
                          <td style={{ textAlign: "left" }}>
                            {parentPlan.plan_name} ({parentPlan.id})
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatCurrency(parentPlan.best_price, marketPlace)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatCurrency(parentPlan.min_price, marketPlace)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatCurrency(parentPlan.max_price, marketPlace)}
                          </td>
                        </tr>
                      )}
                      {selectedPlan && (
                        <tr>
                          <td style={{ textAlign: "left" }}>Selected</td>
                          <td style={{ textAlign: "left" }}>
                            {selectedPlan.plan_name} ({selectedPlan.id})
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatCurrency(
                              selectedPlan.best_price,
                              marketPlace
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatCurrency(
                              selectedPlan.min_price,
                              marketPlace
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatCurrency(
                              selectedPlan.max_price,
                              marketPlace
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="px-4">
                <Col xs={12} className="text-start">
                  <span className="fs-4 fw-bold">Relationship Rules</span>
                  <Table striped bordered hover condensed>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>Relationship</th>
                        <th style={{ textAlign: "left" }}>
                          I want my price to be
                        </th>
                        <th style={{ textAlign: "left" }}>
                          {`by this ${
                            relatedMinUnit === "percent"
                              ? "percentage"
                              : "amount"
                          } ${
                            relatedMinAction.value === "decrease"
                              ? " or less"
                              : " or more"
                          }`}
                        </th>
                        <th style={{ textAlign: "left" }}>Action Unit</th>
                        <th style={{ textAlign: "left" }}>New</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>Minimum Price</td>
                        <td style={{ textAlign: "left", width: "200px" }}>
                          <FormGroupTile
                            type="select"
                            multi={false}
                            stateName={"actionType"}
                            handleChildFormElement={(key, value) => {
                              setRelatedMinAction(value);
                            }}
                            defaultValue={relatedMinAction}
                            options={actionTypeOptions}
                          />
                        </td>
                        <td style={{ textAlign: "left", width: "200px" }}>
                          <FormGroupTile
                            formControlClass="text-center"
                            type="formcontrol"
                            formControlType={"number"}
                            prefixSide={"left"}
                            disabled={relatedMinAction.value === "match"}
                            defaultValue={
                              relatedMinValue
                                ? formatNumber(relatedMinValue, null, 2)
                                : 0
                            }
                            handleChildFormElement={(key, value) => {
                              setRelatedMinValue(value);
                            }}
                          />
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Row>
                            <Col xs={6} className="text-center">
                              <Checkbox
                                className={`text-center`}
                                number={`adPlanBulkActionUnit-percent`}
                                label="%"
                                checked={relatedMinUnit === "percent"}
                                onClick={() => {
                                  setRelatedMinUnit("percent");
                                }}
                                disabled={relatedMinAction.value === "match"}
                              />
                            </Col>
                            <Col xs={6} className="text-center">
                              <Checkbox
                                className={`text-center`}
                                number={`adPlanBulkActionUnit-currency`}
                                label="$"
                                checked={relatedMinUnit === "currency"}
                                onClick={() => {
                                  setRelatedMinUnit("currency");
                                }}
                                disabled={relatedMinAction.value === "match"}
                              />
                            </Col>
                          </Row>
                        </td>
                        <td>
                          {relatedMinAction &&
                          relatedMinAction.value !== "not_set" ? (
                            <span>
                              {formatCurrency(newMinPrice, marketPlace)}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>Maximum Price</td>
                        <td style={{ textAlign: "left", width: "200px" }}>
                          <FormGroupTile
                            type="select"
                            multi={false}
                            stateName={"actionType"}
                            handleChildFormElement={(key, value) => {
                              setRelatedMaxAction(value);
                            }}
                            defaultValue={relatedMaxAction}
                            options={actionTypeOptions}
                          />
                        </td>
                        <td style={{ textAlign: "left", width: "200px" }}>
                          <FormGroupTile
                            formControlClass="text-center"
                            type="formcontrol"
                            formControlType={"number"}
                            prefixSide={"left"}
                            disabled={relatedMaxAction.value === "match"}
                            defaultValue={
                              relatedMaxValue
                                ? formatNumber(relatedMaxValue, null, 2)
                                : 0
                            }
                            handleChildFormElement={(key, value) => {
                              setRelatedMaxValue(value);
                            }}
                          />
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Row>
                            <Col xs={6} className="text-center">
                              <Checkbox
                                className={`text-center`}
                                number={`adPlanBulkActionUnit-percent`}
                                label="%"
                                checked={relatedMaxUnit === "percent"}
                                onClick={() => {
                                  setRelatedMaxUnit("percent");
                                }}
                                disabled={relatedMaxAction.value === "match"}
                              />
                            </Col>
                            <Col xs={6} className="text-center">
                              <Checkbox
                                className={`text-center`}
                                number={`adPlanBulkActionUnit-currency`}
                                label="$"
                                checked={relatedMaxUnit === "currency"}
                                onClick={() => {
                                  setRelatedMaxUnit("currency");
                                }}
                                disabled={relatedMinAction.value === "match"}
                              />
                            </Col>
                          </Row>
                        </td>
                        <td>
                          {relatedMaxAction &&
                          relatedMaxAction.value !== "not_set" ? (
                            <span>
                              {formatCurrency(newMaxPrice, marketPlace)}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}

          {savingRelationship && (
            <Row>
              <Col>
                <Loading fullPage={false} height={`200px`} />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row className="py-4" style={{ width: "100%" }}>
            <Col xs={6} className="mx-auto text-start">
              <LogoutButton
                key="RefreshBtn"
                title={`Close`}
                onClick={() => hideModal()}
                //   disabled={adding}
              />
            </Col>
            <Col xs={6} className="mx-auto text-start">
              <LogoutButton
                key="RefreshBtn"
                title={`Save Relationship`}
                onClick={() => sendRelated()}
                disabled={selectedPlan === null || savingRelationship}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RelationshipSection;
