import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import FormGroupTile from "components/core/basic/FormGroupTile";
import api from "utils/api";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { setCurrentUser } from "redux/actions/authActions";
import { setChannel } from "redux/actions/channel";
import { setMarketPlace } from "redux/actions/marketPlace";
import { useDispatch } from "react-redux";

const AdditionalWalmartOrgModal = () => {
  const [newOrgName, setNewOrgName] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const createNewWalmartOrg = useMutation(
    async () => {
      return await api.post("/walmart/auth/onboarding", {
        action: "add_new_walmart_profile",
        new_org_name: newOrgName,
      });
    },
    {
      default: [],
      onSuccess: async (creationRes) => {
        // Change user profile to point towards new walmart org to finish onboarding
        const org_id = creationRes.data.org_id;
        const res = await api.put("/api/organizations/", { id: org_id });

        // Update redux and localStorage
        localStorage.setItem("Marketplace", "USA");
        localStorage.setItem("channel", "walmart");

        dispatch(
          setCurrentUser({
            current_profile: res.data.profile_name,
          })
        );
        dispatch(setMarketPlace("USA"));
        dispatch(setChannel("walmart"));

        // Redirect to seller type dialogue in onboarding flow
        history.push("/onboarding/v2/sellertype");
      },
    }
  );

  return (
    <ConfirmationModal
      title={"Add an Additional Walmart Organization"}
      modalButtonText={"Add New Account"}
      confirmButtonText={"Confirm"}
      confirmationMessage={
        <>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="px-5">
              <FormGroupTile
                type="formcontrol"
                stateName={"newOrgName"}
                formControlType={"text"}
                handleChildFormElement={(key, value) => {
                  setNewOrgName(value);
                }}
                label={"New Organization Name"}
                placeholder={"Enter the organization name"}
                defaultValue={newOrgName}
              />
            </Col>
          </Row>
        </>
      }
      buttonStyle="adplan_button"
      modelButtonStyle={{ marginRight: 0, transform: "none" }}
      onConfirm={() => {
        createNewWalmartOrg.mutate();
      }}
    />
  );
};

export default AdditionalWalmartOrgModal;
