import React, { useState, useMemo, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useFetch } from "hooks/api";

import { useMutation, useQueryClient } from "react-query";
import NotificationSystem from "react-notification-system";
import api from "utils/api/index";
import { sendToastNotification } from "utils/sendToastNotification";
import { AuthButton } from "components/core/basic/Button";

import AmazonProfilesTable from "./AmazonProfilesTable";
import { useSelector } from "react-redux";

export const groupByProfileName = (data) => {
  if (!data) {
    return [];
  }

  return (
    Object.values(
      data.reduce((acc, row) => {
        if (acc[row.accountInfo.name]) {
          return {
            ...acc,
            [row.accountInfo.name]: {
              ...acc[row.accountInfo.name],
              marketPlaces: [
                ...acc[row.accountInfo.name].marketPlaces,
                row.countryCode,
              ],
            },
          };
        }
        return {
          ...acc,
          [row.accountInfo.name]: { ...row, marketPlaces: [row.countryCode] },
        };
      }, {})
    ) ?? []
  );
};

const formatRawProfiles = (rawProfiles) => {
  return rawProfiles?.map((profile) => ({
    profile_name: profile.accountInfo.name,
    profile_type: profile.accountInfo.type,
    marketplaces: profile.marketPlaces,
  }));
};

const AmazonProfileSummary = () => {
  const [fetchRemoteProfiles, setFetchRemoteProfiles] = useState(false);

  const state = useSelector((state) => state);
  const { user } = state;

  const notificationRef = useRef();
  const queryClient = useQueryClient();

  const { data: trellisProfilesData, trellisProfilesLoading } = useFetch(
    "trellisProfiles",
    "/api/organizations/",
    { page_size: 5000 },
    {
      select: (d) => d.data?.filter((profile) => profile.org_type === "AMAZON"),
      default: [],
    }
  );

  const { data: remoteProfilesData, isLoading: remoteProfilesLoading } =
    useFetch(
      "remoteProfiles",
      "/api/organizations/",
      { page_size: 5000, remote_profiles: fetchRemoteProfiles },
      {
        select: (d) => d.data,
        enabled: !!fetchRemoteProfiles,
        default: [],
      }
    );

  const formattedRemoteProfilesData = useMemo(() => {
    const rawProfiles = groupByProfileName(remoteProfilesData) ?? [];
    return formatRawProfiles(rawProfiles);
  }, [remoteProfilesData]);

  const refreshProfiles = useMutation(
    async () => {
      return await api.post("/api/manage/refresh_profiles/", {
        org: parseInt(user.organization_id),
      });
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          `Successfully Refreshed Amazon Profiles`
        );
        // Below is hacky, but locally celery task took a few seconds to run before new orgs/profiles created
        setTimeout(() => {
          queryClient.invalidateQueries("trellisProfiles");
        }, 5000);
      },
      onError: () => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Error Refreshing Amazon Profiles`
        );
      },
    }
  );
  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <Row className="text-start mx-0 w-100" style={{ color: "#393939" }}>
        <Col xs={12} lg={9} className="px-0">
          <h2 style={{ fontSize: "28px", fontWeight: 600 }}>
            Trellis Profile Summary
          </h2>
          <p className="fs-3 mb-5">
            Below is a summary of all Amazon profiles currently connected to
            your Trellis account.
          </p>
        </Col>

        <Col
          xs={12}
          lg={3}
          className="d-flex justify-content-end align-items-center px-0"
        >
          <AuthButton
            title={"Refresh Profiles"}
            buttonStyle="adplan_button"
            disabled={refreshProfiles.isLoading}
            onClick={(e) => {
              refreshProfiles.mutate();
            }}
            style={{ transform: "none" }}
          />
        </Col>
      </Row>
      <AmazonProfilesTable
        tableData={trellisProfilesData}
        columnsToRender={["name", "marketplaces", "profile_type"]}
        isLoading={trellisProfilesLoading}
      />
      <Row className="text-start mx-0 w-100" style={{ color: "#393939" }}>
        <Col xs={12} lg={9} className="px-0">
          <h2 style={{ fontSize: "28px", fontWeight: 600 }}>
            Amazon Profile Summary
          </h2>
          <p className="fs-3 mb-5">
            Click the button to the right to see all profiles from Amazon
            associated with your Amazon Advertising Account.
          </p>
        </Col>
        <Col
          xs={12}
          lg={3}
          className="d-flex justify-content-end align-items-center px-0"
        >
          <AuthButton
            title={
              remoteProfilesLoading
                ? "Loading Amazon Profiles"
                : "Check Amazon Profiles"
            }
            buttonStyle="adplan_button"
            disabled={remoteProfilesLoading}
            onClick={(e) => {
              setFetchRemoteProfiles(!fetchRemoteProfiles);
            }}
            style={{ transform: "none" }}
          />
        </Col>
      </Row>
      {fetchRemoteProfiles && (
        <>
          <AmazonProfilesTable
            tableData={formattedRemoteProfilesData}
            columnsToRender={["marketplaces", "profile_type"]}
            isLoading={remoteProfilesLoading}
          />
          <p className="fs-2 mt-5 mb-2 w-100" style={{ fontWeight: 600 }}>
            Not seeing your profile from Amazon?
          </p>
          <p className="fs-3 mb-5 w-75">
            At least one campaign has to have been made in the marketplace for
            Amazon to share the profile. Additionally, the Amazon account
            connected to the Trellis platform must have "View & Edit"
            permissions to "Campaign Manager"
          </p>
        </>
      )}
    </>
  );
};

export default AmazonProfileSummary;
