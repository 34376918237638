import React, { useEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import StatTile from "./StatTile";
import Chart from "./Chart";
import Table from "./Table";
import acosOrRoas from "utils/acosOrRoas";
import { useDates } from "dates/useDates";
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  SERIES_PALETTE,
  combineYoYData,
  getCurrencyDescription,
} from "./utils";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import moment from "moment";
const EMPTY_ARRAY = [];

const Overview = ({
  orgName = "",
  data,
  group,
  roasPreference = false,
  marketPlace,
}) => {
  const { start, end } = useDates();
  const { categoryData, categoryYoYData: yoyData } = data;

  const formattedStartDate = moment(start).format("MMMM D, YYYY");
  const formattedEndDate = end.format("MMMM D, YYYY");

  const topCategoryTableColumns = useMemo(
    () => [
      {
        accessor: "category_name",
        Header: "Category",
      },
      {
        accessor: "sales",
        Header: "Sales",
        compareMetricId: "total_sales_percent",
        yoyCompareMetricId: "total_sales_percent_yoy",
      },
      {
        Header: "Ad Sales",
        accessor: "ad_sales",
        compareMetricId: "ad_sales_percent",
        yoyCompareMetricId: "ad_sales_percent_yoy",
      },
      {
        Header: "Ad Spend",
        accessor: "ad_spend",
        compareMetricId: "cost_percent",
        yoyCompareMetricId: "cost_percent_yoy",
      },
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "total_acos",

          compareMetricId: "total_acos_delta",
          yoyCompareMetricId: "total_acos_delta_yoy",
          Header: "Total ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "troas",
          compareMetricId: "total_roas_percent",
          yoyCompareMetricId: "total_roas_percent_yoy",
          Header: "Total ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "acos",

          compareMetricId: "acos_delta",
          yoyCompareMetricId: "acos_delta_yoy",
          Header: "ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "roas",
          compareMetricId: "roas_delta",
          yoyCompareMetricId: "roas_delta_yoy",
          Header: "ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
    ],
    [roasPreference]
  );

  useEffect(() => {}, [roasPreference]);

  const overviewStatTileData = useMemo(() => {
    if (categoryData.data.total) {
      return combineYoYData(categoryData.data.total, yoyData.data.total);
    }

    return EMPTY_ARRAY;
  }, [categoryData, yoyData]);

  const tableData = useMemo(() => {
    if (categoryData.data.categories) {
      return categoryData.data.categories
        ?.slice(0, 5)
        ?.map((categoryData, index) => {
          return combineYoYData(categoryData, yoyData.data.categories?.[index]);
        });
    }
    return EMPTY_ARRAY;
  }, [categoryData, yoyData]);

  const currencyMetricChartData = useMemo(() => {
    return categoryData?.data?.date;
  }, [categoryData]);

  return (
    <div
      className={`box box-common`}
      style={{
        maxWidth: PAGE_WIDTH,
        maxHeight: PAGE_HEIGHT,
        overflowY: "hidden",
      }}
    >
      <img src={logo} className="watermark" alt="Trellis logo" />
      <div className="common-quadrant-title" style={{ paddingBottom: "10px" }}>
        {orgName} Performance Summary
      </div>
      <Row>
        <Col xs={6} className="fs-3" style={{ padding: "20px 65px" }}>
          {formattedStartDate} to {formattedEndDate}
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={3}>
              <StatTile
                label={"Sales"}
                metricId="sales"
                compareMetricId={"sales_percent"}
                yoyCompareMetricId={"total_sales_percent_yoy"}
                data={overviewStatTileData}
                firstInGroup={true}
                marketPlace={marketPlace}
              />
            </Col>

            <Col xs={3}>
              <StatTile
                label={"Ad Sales"}
                metricId="ad_sales"
                compareMetricId={"ad_sales_percent"}
                yoyCompareMetricId={"ad_sales_percent_yoy"}
                data={overviewStatTileData}
                marketPlace={marketPlace}
              />
            </Col>

            <Col xs={3}>
              <StatTile
                label={"Ad Spend"}
                metricId="ad_spend"
                compareMetricId={"ad_spend_percent"}
                yoyCompareMetricId={"cost_percent_yoy"}
                data={overviewStatTileData}
                marketPlace={marketPlace}
              />
            </Col>

            <Col xs={3}>
              {acosOrRoas(
                { acos_or_roas: roasPreference ? "roas" : "acos" },
                <StatTile
                  label={"TACOS"}
                  metricId="total_acos"
                  compareMetricId={"total_acos_delta"}
                  yoyCompareMetricId={"total_acos_delta_yoy"}
                  data={overviewStatTileData}
                  marketPlace={marketPlace}
                />,
                <StatTile
                  label={"TROAS"}
                  metricId="troas"
                  compareMetricId={"troas_delta"}
                  yoyCompareMetricId={"total_roas_delta_yoy"}
                  data={overviewStatTileData}
                  marketPlace={marketPlace}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={4} className="fs-3" style={{ paddingLeft: "65px" }}>
          <Chart
            data={currencyMetricChartData}
            metrics={[{ id: "sales", color: SERIES_PALETTE[0] }]}
            yAxisLabels={[
              `${getCurrencyDescription(marketPlace.marketPlace)} (${
                MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
              })`,
            ]}
            group={group}
            height={275}
            type="area"
            marketPlace={marketPlace}
          />
        </Col>
        <Col xs={4} className="fs-3" style={{ padding: "auto 30px" }}>
          <Chart
            data={currencyMetricChartData}
            metrics={[
              { id: "ad_spend", color: SERIES_PALETTE[2] },
              { id: "ad_sales", color: SERIES_PALETTE[4] },
            ]}
            yAxisLabels={[
              `${getCurrencyDescription(marketPlace.marketPlace)} (${
                MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
              })`,
            ]}
            group={group}
            height={275}
            type="area"
            marketPlace={marketPlace}
          />
        </Col>
        <Col xs={4} style={{ paddingRight: "65px" }}>
          <Chart
            data={currencyMetricChartData}
            metrics={[
              {
                id: acosOrRoas(
                  { acos_or_roas: roasPreference ? "roas" : "acos" },
                  "acos",
                  "roas"
                ),
                color: SERIES_PALETTE[2],
              },
              {
                id: acosOrRoas(
                  { acos_or_roas: roasPreference ? "roas" : "acos" },
                  "total_acos",
                  "troas"
                ),
                color: SERIES_PALETTE[0],
              },
            ]}
            yAxisLabels={roasPreference ? ["ROAS"] : ["Percent (%)"]}
            group={group}
            height={275}
            type="area"
            marketPlace={marketPlace}
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"Top 5 categories"}
            data={tableData}
            columns={topCategoryTableColumns}
            showPrevPeriodTrendRow={true}
            showYoyTrendRow={true}
            marketPlace={marketPlace}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
