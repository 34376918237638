import CenteredModal from "components/core/basic/CenteredModal";
import React, { useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import AdPlanForm from "./AdPlanForm";
import useAdPlanState from "./useAdPlanState";
import withAdPlan from "./withAdPlan";
import SplitButton from "components/core/blocks/AdPlan/SplitButton";
import AdPlanBulkCreate from "./AdPlanBulkCreate";
import { AuthButton } from "components/core/basic/Button";
import { trellisPalette } from "../../../custom/analytics/palettes";
import AdPlanError from "./AdPlanError";
import { AdPlanContext } from "./AdPlanContext";

const AdPlanModal = (props) => {
  const initialAdPlan = props.initialAdPlan || {};
  const [show, setShow] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);

  const { channel } = useSelector((state) => state);

  const {
    adPlan,
    errors,
    isValid,
    onChange,
    clear,
    updateChild,
    removeChild,
    products,
    brandOptions,
    savedVideos,
    storeOptions,
    logoOptions,
    pageOptions,
    agreeToArchive,
    setAgreeToArchive,
    apiStatus,
  } = useAdPlanState(
    initialAdPlan,
    props.marketPlace.marketPlace,
    props.categoryId,
    props.adId ?? null,
    props?.user?.preferences?.acos_or_roas === "roas",
    props.user.organization_type
  );
  const [currentChild, setCurrentChild] = useState(null);
  const { resetAdPlanContext } = useContext(AdPlanContext);

  useEffect(() => {
    clear();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categoryId]);

  return (
    <>
      <CenteredModal
        show={show === "Create Media Plan"}
        closeModal={(e) => {
          setShow(false);
          clear();
          resetAdPlanContext();
        }}
        adPlanModal={true}
        content={
          props.loading ? (
            "Loading..."
          ) : (
            <div style={{ width: "100%", overflowX: "clip" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  position: "relative",
                  transition: "left 0.4s linear",
                  left: currentChild ? "-100%" : "0",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: currentChild ? "1px" : "100%",
                    overflowY: currentChild ? "clip" : null,
                  }}
                >
                  <AdPlanForm
                    onChange={onChange}
                    state={adPlan}
                    errors={{ ...props.errors, ...errors }}
                    isValid={isValid}
                    onShowChild={(i, child) => setCurrentChild({ i, child })}
                    onRemoveChild={(i) => removeChild(i)}
                    categories={props.categories}
                    products={products}
                    savedVideos={savedVideos}
                    brandOptions={brandOptions}
                    storeOptions={storeOptions}
                    logoOptions={logoOptions}
                    pageOptions={pageOptions}
                    dashboard={props.dashboard}
                    user={props.user}
                    marketPlace={props.marketPlace.marketPlace}
                    channel={localStorage.channel}
                    agreeToArchive={agreeToArchive}
                    setAgreeToArchive={setAgreeToArchive}
                    apiStatus={apiStatus}
                    clearError={props.clearError}
                    parentDailyBudget={props.parentDailyBudget}
                    isModal={true}
                    closeModal={() => {
                      clear();
                      setShow(false);
                    }}
                    inlineModal={props.inlineModal}
                    onUpdate={props.onUpdate}
                    onCreate={props.onCreate}
                    clearForm={clear}
                    addingNewChild={adPlan.id && adPlan.canHaveChildren}
                    onParentUpdate={props.onParentUpdate}
                    updateChild={updateChild}
                    panelEditStep={props.editStep}
                  />
                </div>
              </div>
            </div>
          )
        }
      />

      {props.dashboard && !props.isVideoShortcut && !props.inlineModal ? (
        <>
          <AdPlanBulkCreate
            show={show === "Bulk Upload"}
            marketPlace={props.marketPlace.marketPlace}
            onClose={() => {
              setShow(false);
              clear();
              resetAdPlanContext();
            }}
            channel={channel.currentChannel}
          />
          <SplitButton
            style={{ float: "right" }}
            onClick={(option) =>
              props.onboardStatus === "pre_trial" ||
              props.onboardStatus === "trial_expired"
                ? setSubscriptionError({ adPlanError: "No subscription" })
                : setShow(option)
            }
            title="Create a New Media Plan"
            buttonStyle="adplan_button text-nowrap"
          />
        </>
      ) : props.inlineModal ? (
        <span
          onClick={() =>
            props.onboardStatus === "pre_trial" ||
            props.onboardStatus === "trial_expired"
              ? setSubscriptionError({ adPlanError: "No subscription" })
              : setShow("Create Media Plan")
          }
          style={{
            color: trellisPalette[0],
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {props.inlineModalText}
        </span>
      ) : props.altModalButton ? (
        <span
          onClick={(e) => {
            props.onboardStatus === "pre_trial" ||
            props.onboardStatus === "trial_expired"
              ? setSubscriptionError({
                  adPlanError: "No subscription",
                })
              : setShow("Create Media Plan");
          }}
        >
          {props.altModalButton}
        </span>
      ) : (
        <AuthButton
          onClick={() =>
            props.onboardStatus === "pre_trial" ||
            props.onboardStatus === "trial_expired"
              ? setSubscriptionError({
                  adPlanError: "No subscription",
                })
              : setShow("Create Media Plan")
          }
          title="Create a New Media Plan"
          buttonStyle="adplan_button"
        />
      )}
      <AdPlanError
        errors={subscriptionError}
        clearError={() => setSubscriptionError(null)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  onboardStatus: state.amz_onboard.org_status,
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(withAdPlan(AdPlanModal));
