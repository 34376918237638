import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import Loading from "components/core/blocks/Loading";
import CurveCharts from "modules/pricingv2/charts/CurveCharts";
import RangeCharts from "modules/pricingv2/cards/RangeCharts";

export const DemandChartsSection = ({
  pricingProduct,
  isPricingProductDataLoading,
  marketPlace,
  isLoading,
  product,
}) => {
  let daysWithPrice = pricingProduct?.pricing_org?.days_with_price;
  if (daysWithPrice === undefined) {
    daysWithPrice = 1;
  }
  const needsProfiling =
    pricingProduct?.pricing_data?.filter(
      (r) => r.num_days_at_price !== 0 && r.num_days_at_price >= daysWithPrice
    ).length < 3;

  const dataStatus = useMemo(() => {
    const status = {
      notEnoughData: false,
      generation: false,
      invalidStrategy: false,
    };

    if (pricingProduct) {
      if (!pricingProduct?.pricing_data) {
        status["generation"] = true;
        return status;
      }

      if (pricingProduct.pricing_data.length > 1) {
        let modelledSum = 0;

        for (let i = 0; i < pricingProduct.pricing_data.length; i++) {
          modelledSum += pricingProduct.pricing_data[i].modeled_demand;
        }

        if (modelledSum === 0) {
          status["notEnoughData"] = true;
          return status;
        }
      }

      return status;
    }

    return status;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingProduct]);

  if (isLoading || isPricingProductDataLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  if (needsProfiling) {
    return (
      <Row>
        <Col className="text-center">
          <div className="fs-4 py-5">
            Not enough prices to generate demand curve.
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {!dataStatus.notEnoughData && !dataStatus.generation && (
        <>
          <Row>
            <Col xs={12}>
              <CurveCharts
                optData={pricingProduct}
                markrtPlace={marketPlace}
                fullCurves
                price={
                  product ? product.product_price : pricingProduct.best_price
                }
              />
            </Col>
          </Row>
        </>
      )}

      {dataStatus.notEnoughData && (
        <Row className="text-center py-5">
          <Col xs={12}>
            <p className="fs-4">
              <strong>
                Not enough price changes over the last 365 days to show the
                pricing the demand trend
              </strong>
            </p>
          </Col>
        </Row>
      )}

      {dataStatus.generation && (
        <Row className="text-center py-5">
          <Col xs={12}>
            <p className="fs-4">
              <strong>
                The pricing trend is being generated. Please check back in a few
                hours.
              </strong>
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

