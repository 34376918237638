import moment from "moment";
import {
  KEYWORD_MATCH_TYPE_OPTIONS,
  OPERATOR_OPTIONS,
  TARGET_MATCH_TYPE_OPTIONS,
} from "./constants";

const scopeText = (scope) => {
  const fmtDate = (date) => {
    if (!date) return null;
    return moment(Date.parse(date)).format("MMM D 'YY");
  };

  if (!scope) return null;
  if (scope.operator === "between") {
    return `between ${
      fmtDate(scope.values.start_date) || "start date"
    } and ${fmtDate(scope.values.end_date || "end date")}`;
  }
  if (scope.operator === "before") {
    return `within ${scope.values.days} days since the last action`;
  }
  if (scope.operator === "after") {
    return `after ${scope.values.days} or more days since the last action`;
  }
};

const makeConditionOptions = (
  fieldName,
  scope,
  engine = "pricing",
  options = {}
) => {
  const makeTargetConditionOption = (
    accessor,
    label,
    format = "multi-select"
  ) => {
    let matchTypeOptions = [];

    if (accessor === "keyword_match_types") {
      matchTypeOptions = KEYWORD_MATCH_TYPE_OPTIONS;
    }

    if (accessor === "target_match_types") {
      matchTypeOptions = TARGET_MATCH_TYPE_OPTIONS;
    }

    return {
      group: accessor,
      value: `\${${accessor}}`,
      label: label[0].toUpperCase() + label.slice(1),
      activeLabel: label[0].toUpperCase() + label.slice(1),
      format: "multi-select",
      selectOptions: matchTypeOptions,
      supportedOperators: OPERATOR_OPTIONS[format],
    };
  };

  const makeBiddingConditionOption = (
    group,
    accessor,
    label,
    additionalLabel,
    format = "number",
    showScope = true
  ) => {
    const formattedLabel = additionalLabel
      ? `${label} ${additionalLabel}`
      : label[0].toUpperCase() + label.slice(1);

    // List out available operators => default to number

    // Returns list of operators based on accessor
    return {
      group: group,
      supportedOperators: OPERATOR_OPTIONS[format],
      value: `\${${accessor}}`,
      label: formattedLabel,
      scopeText: scopeText(scope),
      showScope,
    };
  };

  const optionsByEngine = {
    pricing: [
      {
        value: `\${units}`,
        label: "Units",
        group: "units",
        scopeText: scopeText(scope),
        supportedOperators: OPERATOR_OPTIONS["number"],
      },
      {
        group: "bsr",
        value: `\${bsr}`,
        label: "Average BSR",
        scopeText: scopeText(scope),
        supportedOperators: OPERATOR_OPTIONS["number"],
      },
    ],
    bidding: [
      makeTargetConditionOption("target_match_types", "targets"),
      makeTargetConditionOption("keyword_match_types", "keywords"),
      makeBiddingConditionOption(
        "conversions",
        "conversions",
        "conversions",
      ),
      makeBiddingConditionOption("acos", "acos", "ACOS"),
      makeBiddingConditionOption("roas", "roas", "ROAS"),
      makeBiddingConditionOption("ad_spend", "ad_spend", "ad spend"),
      makeBiddingConditionOption(
        "conv_rate",
        "conv_rate",
        "conversion rate",
      ),
      makeBiddingConditionOption(
        "ctr",
        "click_thru_rate",
        "click-through rate",
      ),
      makeBiddingConditionOption("clicks", "clicks", "clicks"),
      makeBiddingConditionOption("bid", "bid", "bid"),
      makeBiddingConditionOption(
        "impressions",
        "impressions",
        "impressions",
      ),
    ],
    budget: [
      makeBiddingConditionOption(
        "conversions",
        "conversions",
        "conversions",
      ),
      makeBiddingConditionOption("acos", "acos", "ACOS"),
      makeBiddingConditionOption("roas", "roas", "ROAS"),
      makeBiddingConditionOption("ad_spend", "ad_spend", "ad spend"),
      makeBiddingConditionOption(
        "conv_rate",
        "conv_rate",
        "conversion rate",
      ),
      makeBiddingConditionOption(
        "ctr",
        "click_thru_rate",
        "click-through rate",
      ),
      makeBiddingConditionOption("clicks", "clicks", "clicks"),
      makeBiddingConditionOption(
        "serving_hours",
        "serving_hours",
        "Serving Hours",
      ),
    ],
  };

  return optionsByEngine[engine];
};

export default makeConditionOptions;
