import { withStyles, Grid } from "@material-ui/core";
import { useFetch } from "hooks/api";
import React, { useState, useMemo } from "react";
import { NavDropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { trellisPalette } from "components/custom/analytics/palettes";
import ChartMiddleware from "components/custom/growth/ChartMiddleware";

const useStyles = (theme) => ({
  container: {
    margin: "auto",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4),
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  main: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  header: {
    textAlign: "center",
  },
  chart: {
    maxHeight: "190px",
    marginBottom: "100px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  table: {
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  cvTableContainer: {
    marginTop: "100px",
    minHeight: "400px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  mainHeader: {
    fontSize: "26px",
    color: "#403E3D",
    fontWeight: "700",
    margin: "auto",
  },
  statTile: {
    maxHeight: "170px",
    margin: 0,
    whiteSpace: "nowrap",
  },
  tableHeader: {
    fontSize: "1.65rem",
    color: "#403E3D",
    fontWeight: "700",
    margin: "1rem auto",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  statValues: {
    fontSize: "2.5rem",
    fontWeight: 400,
    color: "#403E3D",
    padding: "auto",
  },
  metrics: {
    justifyContent: "center",
    textAlign: "center",
    margin: "auto 20px",
  },
  dropdownContainer: {
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
    },
  },
  dropdownText: {
    color: "#403E3D",
    fontWeight: 600,
  },
  buttonGroupContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(2),
    alignItems: "flex-start",
    marginBottom: "auto",
  },
});

const AnalyticsOverviewChart = (props) => {
  const [rollup, setRollup] = useState({
    id: "1",
    rollup: "daily",
    name: "Daily",
  });

  const { data: overviewData, isLoading: shelfStatsLoading } = useFetch(
    [
      "shelf_timeline",
      props.endDate.toString(),
      props.startDate.toString(),
      rollup,
    ],
    "/api/shelf_stats",
    {
      stats_by: "time_series",
      end: props.endDate,
      start: props.startDate,
      period: rollup.rollup,
    },
    {
      enabled: !!props.endDate && !!props.startDate,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let chartData = overviewData?.data ?? [];

  const atfData = useMemo(() => {
    return chartData?.filter((item) => {
      if (item.rollup_type === "above_fold") {
        let newRow = item;
        item.mDate = moment(item.date);
        return newRow;
      }
      return false;
    });
  }, [chartData]);

  chartData = chartData?.filter((item) => {
    if (item.rollup_type === "page_1") {
      let newRow = item;
      item.mDate = moment(item.date);
      return newRow;
    }
    return false;
  });
  let chartDates = [];
  if (rollup.rollup === "monthly") {
    chartDates = chartData?.map((d) =>
      d.mDate?.endOf("M").format("YYYY-MM-DD")
    );
  } else if (rollup.rollup === "weekly") {
    chartDates = chartData?.map((d) =>
      d.mDate?.endOf("W").format("YYYY-MM-DD")
    );
  } else if (rollup.rollup === "daily") {
    chartDates = chartData?.map((d) => d.mDate?.format("YYYY-MM-DD"));
  }

  const chartOptions = {
    legend: {
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },
    title: {
      text: null,
    },
    // This is blank to overwrite default options for performance chart
    plotOptions: {},
    xAxis: {
      categories: chartDates.slice(Math.max(chartData?.length - 30, 0)),
      labels: {
        step: rollup?.rollup === "daily" ? 5 : 1,
      },
    },
    yAxis: [
      {
        title: {
          text: "Share",
        },
        labels: {
          format: "{value}%",
        },
      },
      {
        title: {
          text: "Position",
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      pointFormat: "{series.name}: <b>{point.y}</b><br/>",
    },
  };

  const timeSeriesFilter = [
    { id: "1", rollup: "daily", name: "Daily" },
    { id: "1", rollup: "weekly", name: "Weekly" },
    { id: "1", rollup: "monthly", name: "Monthly" },
  ];

  return (
    <Grid container lg={12} spacing={4} className={props.classes.container}>
      <Grid
        item
        container
        xs={12}
        className={props.classes.metrics}
        spacing={4}
        direction="row"
        style={{ justifyContent: "flex-end", marginRight: "5rem" }}
      >
        <NavDropdown
          id="ProductChartPeriod"
          title={
            <span className={props.classes.dropdownText}>{rollup.name}</span>
          }
          style={{
            color: "#73706E",
            fontSize: "18px",
          }}
        >
          {timeSeriesFilter.map((item, index) => {
            return (
              <NavDropdown.Item
                key={"productChart" + index}
                id={item.id}
                onClick={(e) => setRollup(item)}
              >
                {item.name}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      </Grid>

      <Grid
        item
        container
        xs={12}
        className={props.classes.chart}
        // spacing={3}
      >
        <ChartMiddleware
          options={{
            options: chartOptions,
            series: [
              {
                color: trellisPalette[3],
                type: "column",
                name: "Average Position",
                data: chartData
                  .map((d) => d.position_avg)
                  ?.slice(Math.max(chartData?.length - 30, 0)),
                yAxis: 1,
              },
              {
                color: trellisPalette[0],
                type: "line",
                name: `SOS`,
                data: chartData
                  .map((d) => d.share_of_shelf)
                  ?.slice(Math.max(chartData?.length - 30, 0)),
                yAxis: 0,
              },
              {
                color: trellisPalette[0],
                type: "line",
                name: `SOS Organic`,
                data: chartData
                  .map((d) => d.org_share_of_shelf)
                  ?.slice(Math.max(chartData?.length - 30, 0)),
                yAxis: 0,
              },
              {
                color: trellisPalette[2],
                type: "line",
                name: "SOV",
                data: chartData
                  .map((d) => d.share_of_voice)
                  ?.slice(Math.max(chartData?.length - 30, 0)),
                yAxis: 0,
              },
              {
                color: trellisPalette[1],
                type: "line",
                name: `ATF SOS`,
                data: atfData
                  .map((d) => d.share_of_shelf)
                  ?.slice(Math.max(chartData?.length - 30, 0)),
                yAxis: 0,
              },
              {
                color: trellisPalette[1],
                type: "line",
                name: `ATF SOS Organic`,
                data: atfData
                  .map((d) => d.org_share_of_shelf)
                  ?.slice(Math.max(chartData?.length - 30, 0)),
                yAxis: 0,
              },
              {
                color: trellisPalette[4],
                type: "line",
                name: "ATF SOV",
                data: atfData
                  .map((d) => d.share_of_voice)
                  ?.slice(Math.max(chartData?.length - 30, 0)),
                yAxis: 0,
              },
            ],
          }}
          rollup={rollup}
          yLabels={true}
          height={400}
          context={"analytics-overview"}
          isChartDataLoading={shelfStatsLoading}
        />
      </Grid>
    </Grid>
  );
};

AnalyticsOverviewChart.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(
  withStyles(useStyles)(AnalyticsOverviewChart)
);
