import React, { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { trellisPalette } from "components/custom/analytics/palettes";
import { Row, Col } from "react-bootstrap";
import { useFetch } from "hooks/api";
import moment from "moment";
import { formatPercent } from "utils/formatNumber";

import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);

const CancelRateChart = ({
  pricingPlan,
  marketPlace,
  height,
  start,
  end,
  group,
}) => {
  const filters = {
    start_date: moment(start).format("YYYY-MM-DD"),
    end_date: moment(end).format("YYYY-MM-DD"),
    group: group,
    pricing_plan_id: pricingPlan?.id,
  };
  const { data, isLoading } = useFetch(
    ["cancel_rate", marketPlace.marketPlace, start, end, group],
    `/pricing/cancelrate`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { cancelRateOptions, cancelRate } = useMemo(() => {
    if (!data) {
      return {};
    }

    const shipped = [];
    const cancelled = [];
    const pending = [];
    const sub_save = [];

    let i = 0;
    let minShipped = null;
    let minCancelled = null;
    let minPending = null;

    let numCancelledOrders = 0;
    let numOrders = 0;

    data.forEach((d) => {
      const dt = Date.UTC(
        parseInt(d.date.substring(0, 4)),
        parseInt(d.date.substring(5, 7)),
        parseInt(d.date.substring(8, 10))
      );

      if (i === 0) {
        minShipped = dt;
        minCancelled = dt;
        minPending = dt;
      }
      shipped.push([Date.parse(d.date), d.Shipped]);
      cancelled.push([Date.parse(d.date), d.Cancelled]);
      pending.push([Date.parse(d.date), d.Pending]);
      sub_save.push([Date.parse(d.date), d.sub_save]);

      numCancelledOrders += d.Cancelled;
      numOrders += d.Shipped + d.Cancelled + d.Pending;

      i++;
    });

    let groupModifier = 7;

    let cancelRate = 0;
    if (numOrders > 0) {
      cancelRate = numCancelledOrders / numOrders;
    }

    return {
      cancelRate: cancelRate.toFixed(2),
      cancelRateOptions: {
        chart: {
          type: "column",
          style: {
            fontSize: "14px",
          },
        },
        title: {
          text: null,
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            day: "%e. %b",
          },
          title: {
            text: "Date",
          },
        },
        yAxis: [
          {
            min: 0,
            title: {
              text: "Number of Orders",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color:
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color,
              },
            },
          },
          {
            min: 0,
            opposite: true,
            title: {
              text: "Price",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color:
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color,
              },
            },
          },
        ],
        tooltip: {
          headerFormat: "<b>{point.x:%e. %b}</b><br/>",
          pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Shipped Orders",
            color: trellisPalette[2],
            data: shipped,
            pointStart: minShipped,
            pointInterval: 24 * 3600 * 1000 * groupModifier, // one day
          },
          {
            name: "Cancelled Orders",
            color: trellisPalette[13],
            data: cancelled,
            pointStart: minCancelled,
            pointInterval: 24 * 3600 * 1000 * groupModifier, // one day
          },
          {
            name: "Pending Orders",
            color: trellisPalette[14],
            data: pending,
            pointStart: minPending,
            pointInterval: 24 * 3600 * 1000 * groupModifier, // one day
          },
          {
            name: "Subscribe - Save Orders",
            type: "spline",
            color: trellisPalette[0],
            yAxis: 1,
            data: sub_save,
            pointStart: minPending,
            pointInterval: 24 * 3600 * 1000 * groupModifier, // one day
          },
        ],
      },
    };
  }, [data]);

  return (
    <Row>
      <Col>
        {!isLoading && (
          <>
            <Row>
              <Col className={"mt-3 mx-3"}>
                <h5>Cancel Rate: {formatPercent(cancelRate)}</h5>
              </Col>
            </Row>

            <HighchartsReact
              highcharts={Highcharts}
              options={cancelRateOptions}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default CancelRateChart;
