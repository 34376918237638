import React from "react";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";

import { ProductCell } from "views/merchandise/Dashboard";
import { GOALS } from "components/core/blocks/AdPlan/constants";
import { useSelector } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import { useMediaQuery } from "@react-hook/media-query";
import ProductAdsTable from "./ProductAdsTable";

const AdvertisementProducts = ({ adPlanData, marketPlace }) => {
  const { group, period, compare, date, start, end, preStart, preEnd } =
    useDates();
  const state = useSelector((state) => state);
  const { channel } = state;
  const modules = selectModules(state);

  const smallViewportWidthTable = useMediaQuery("(max-width:1550px)");

  const { ad_id: adId } = adPlanData ?? {};

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    ad: adId,
    group_by: "product_id",
    channel: channel?.currentChannel ?? "amazon",
  };

  const { data: adProductsStats, isLoading } = useFetch(
    [
      "adProductStats",
      adId,
      marketPlace,
      group,
      filters.start_date,
      filters.end_date,
      filters.pre_start_date,
      filters.pre_end_date,
    ],
    "/api/data_report/product_ad_report",
    filters,
    {
      select: (data) => data?.data,
      // This endpoint can be expensive depending on the rollup period, caching for 1hr currently
      staleTime: 5 * 60 * 1000,
      refetchOnMount: false,
    }
  );

  return (
    <ProductAdsTable
      tableEntityType={"productAd"}
      marketPlace={marketPlace}
      isSelectable={adPlanData?.ad_goal !== GOALS.IMPORTED_ADS.value}
      adProductsStats={adProductsStats}
      isLoading={isLoading}
      adId={adId}
      titleCol={{
        id: "product_title",
        isStatic: true,
        Header: "Product",
        accessor: "product_title",
        style: { width: smallViewportWidthTable ? "300px" : "600px" },
        width: smallViewportWidthTable ? 300 : 600,
        Cell: ({ value, row }) => {
          return (
            <ProductCell
              value={value}
              row={row}
              original={{ ...row._original, id: row._original?.product_id }}
              showAsin={true}
              modules={modules}
              showInventoryBadge={true}
            />
          );
        },
        filterMethod: (filter, row) => {
          return;
        },
        Filter: ({ filter, onChange }) => null,
      }}
    />
  );
};

export default AdvertisementProducts;
