import React from "react";
import { Row, Col, Badge } from "react-bootstrap";

const ConversionLegend = ({ xs = 4 }) => {
  return (
    <Row className="mt-2">
      <Col xs={xs}>
        <Badge bg={"tr-success"} className="mx-1 my-1 fs-5">
          High Conversions
        </Badge>
      </Col>
      <Col xs={xs}>
        <Badge bg={"tr-info"} className="mx-1 my-1 fs-5">
          Mid Conversions
        </Badge>
      </Col>
      <Col xs={xs}>
        <Badge bg={"tr-warning"} className="mx-1 my-1 fs-5">
          Low Conversions
        </Badge>
      </Col>
    </Row>
  );
};

export default ConversionLegend;
