import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { style } from "assets/scripts/NotificationStyles";
import TermsOfService from "components/custom/instructions/TermsOfService";
// this is used to create scrollbars on windows devices like the ones from apple devices
import "perfect-scrollbar/css/perfect-scrollbar.css";
import PropTypes from "prop-types";
import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { logoutUser } from "redux/actions/authActions";
import { setErrors } from "redux/actions/errorActions";
import store from "redux/store";
import routes from "routes/routes";
import Footer from "../views/Footer";
import NavBar from "../views/NavigationBar";
import Sidebar from "../views/Sidebar";
import { setChannel } from "redux/actions/channel";
import checkOnboardRedirect from "components/custom/onboarding/OnboardUtils";
import { createRef } from "react";
import BeamerInAppNotifications from "components/custom/beamer";
import { selectApplications } from "redux/selectors/applications";
import ChromeAccessToken from "components/custom/ChromeAccessToken";
import SignupLayout from "views/onboarding/layout/SignupLayout";
import Banner from "components/custom/Banner";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
      mini: true,
      mwsOrSummary: "summary",
      disableSidebarItems: [],
      proceedToDashboard: true,
    };

    this.onMwsAck = this.onMwsAck.bind(this);
    this.onMwsEdit = this.onMwsEdit.bind(this);
    this.onMwsGoBack = this.onMwsGoBack.bind(this);
    this.onProceedToDashboard = this.onProceedToDashboard.bind(this);

    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);

    this.notificationSystem = createRef();
    this.mainPanel = createRef();

    this.handleNotificationAction = this.handleNotificationAction.bind(this);
    this.setDisableSidebarItem = this.setDisableSidebarItem.bind(this);
  }

  _onIdle(e) {
    store.dispatch(logoutUser());
    const err = {
      title: "",
      message: "Your session has expired. Please login again.",
    };
    store.dispatch(setErrors(err));
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      localStorage.removeItem("redirectTo");
      if (window.location.href.split("/user")[1].indexOf("/billing") === 0) {
        let redirect = Object.assign(
          { page: "billing" },
          { time: Date.now() + 60 * 60 * 1000 }
        );
        localStorage.setItem("redirectTo", JSON.stringify(redirect));
      } else if (
        window.location.href.split("/user")[1].indexOf("/trak") === 0
      ) {
        let redirect = Object.assign(
          { page: "trak" },
          { time: Date.now() + 60 * 60 * 1000 }
        );
        localStorage.setItem("redirectTo", JSON.stringify(redirect));
      }
      this.props.history.push("/");
    }

    // check if we need to redirect to onboarding
    let redirect = checkOnboardRedirect(this.props.amz_onboard);

    // The only redirect used in onboarding v2 is for the SP-API screen from legacy onboarding, which is used to process
    // the SP-API tokens from a registered URL, then redirects back to the new flow
    const { bypass_onboarding } = this.props.user;
    const {
      amazon_advertising_connecting,
      amazon_spapi_access,
      eu_amazon_spapi_access,
      fe_amazon_spapi_access,
    } = this.props.amz_onboard;

    if (redirect !== null && !bypass_onboarding) {
      this.props.history.push(redirect);
    }

    if (
      bypass_onboarding &&
      redirect === "/onboard/amzspapi" &&
      [
        amazon_spapi_access,
        eu_amazon_spapi_access,
        fe_amazon_spapi_access,
      ].includes("in_progress")
    ) {
      this.props.history.push(redirect);
      return;
    }

    // In new sign up flow, don't go back to amzadv route, instead return to Amazon API auth screen
    if (
      (bypass_onboarding &&
        redirect === "/onboard/amzadv" &&
        this.props.amz_onboard.amazon_advertising_access !== "pending") ||
      (bypass_onboarding && amazon_advertising_connecting === true)
    ) {
      this.props.history.push("/onboarding/v2/connect");
    }

    document.body.classList.add("sidebar-mini");

    // Checking for case of MWS listings upload in progress, renders OnboardProgress.jsx view if so, should only apply with MWS onboarding
    if (
      this.props.amz_onboard.listings_progress === "in_progress" ||
      this.props.amz_onboard.category_progress === "in_progress" ||
      this.props.amz_onboard.stock_progress === "in_progress" ||
      this.props.amz_onboard.org_status === "onboarding"
    ) {
      this.setState({ proceedToDashboard: false });
    }
  }

  componentDidUpdate(e) {
    if (!this.props.isAuthenticated) {
      this.props.history.push("/");
    }

    const { bypass_onboarding, customer_type, total_monthly_sales } =
      this.props.user;

    // Below handles cases where users sign up and create a login method, but havn't gone through the rest of the onboarding flow
    if (
      bypass_onboarding &&
      (!customer_type || (customer_type !== "other" && !total_monthly_sales))
    ) {
      this.props.history.push("/onboarding/v2/about/type");
    }

    let onboardRedirectTo = localStorage.getItem("onboardRedirectTo");
    if (
      onboardRedirectTo &&
      onboardRedirectTo.includes("/categorymanagement")
    ) {
      localStorage.removeItem("onboardRedirectTo");
      this.props.history.push("/user/categorymanagement");
    }
    // Redirect to dashboard/initial adplan view when categories are generated through metadata API
    if (onboardRedirectTo && onboardRedirectTo.includes("/dashboard")) {
      localStorage.removeItem("onboardRedirectTo");
      this.props.history.push("/user/dashboard");
    }

    if (
      !(
        this.props.authLoading ||
        this.props.marketPlace.marketPlace === "None" ||
        (this.props.amz_onboard.listings_progress !== "complete" &&
          this.props.amz_onboard.listings_progress !== "not applicable") ||
        (this.props.amz_onboard.category_progress !== "complete" &&
          this.props.amz_onboard.category_progress !== "not applicable") ||
        (this.props.amz_onboard.stock_progress !== "complete" &&
          this.props.amz_onboard.stock_progress !== "not applicable")
      )
    ) {
      if (e.history.action === "PUSH") {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if (this.mainPanel.current) {
          this.mainPanel.current.scrollTop = 0;
        }
      }
      if (
        window.innerWidth < 993 &&
        e.history.action === "PUSH" &&
        document.documentElement.className.indexOf("nav-open") !== -1
      ) {
        document.documentElement.classList.toggle("nav-open");
      }
    }
  }

  onMwsAck() {
    // advance to summary screen
    this.setState({ mwsOrSummary: "summary" });
  }

  onMwsEdit() {
    // go back to MWS edit screen
    this.setState({ mwsOrSummary: "mws" });
  }

  onMwsGoBack() {
    // go back to MWS Choice screen
    this.setState({ mwsOrSummary: "start" });
  }

  onProceedToDashboard() {
    // continue from onboarding progress to user dashboard
    this.setState({ proceedToDashboard: true });
    this.props.history.push("/user/dashboard");
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  // There are four levels - success, warning, error, info
  // autodismissType: There are four levels - default, long, medium
  handleNotificationAction = (
    position,
    level,
    icon,
    text,
    autoDismissType = "default"
  ) => {
    if (this.notificationSystem.current) {
      this.notificationSystem.current.addNotification({
        title: <span data-notify="icon" className={icon} />,
        message: (
          <div>
            {" "}
            <b>{text}</b>{" "}
          </div>
        ),
        level: level,
        position: position,
        dismissible: "button",
        autoDismiss:
          autoDismissType === "long"
            ? 100
            : autoDismissType === "medium"
            ? 60
            : 5,
      });
    }
  };

  getRoutes = (routes) => {
    return routes
      .filter(
        (r) =>
          !(
            r.application &&
            !this.props.applications.find((a) => a === r.application)
          )
      )
      .map((prop, key) => {
        if (prop.collapse) {
          return this.getRoutes(prop.views);
        }
        if (prop.layout === "/user") {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              exact={prop.exact}
              render={(routeProps) =>
                prop.component ? (
                  <prop.component
                    {...routeProps}
                    layout={prop.layout}
                    handleNotification={this.handleNotificationAction}
                    setDisableSidebarItem={this.setDisableSidebarItem}
                    onMwsEdit={this.onMwsEdit}
                    onMwsAck={this.onMwsAck}
                    onMwsGoBack={this.onMwsGoBack}
                  />
                ) : null
              }
            >
              {prop?.children?.map((child) => (
                <Route
                  key={child.path}
                  index={child.index}
                  path={child.path}
                  exact={child.exact}
                  render={(routeProps) => (
                    <child.component
                      {...routeProps}
                      layout={prop.layout}
                      handleNotification={this.handleNotificationAction}
                      setDisableSidebarItem={this.setDisableSidebarItem}
                      onMwsEdit={this.onMwsEdit}
                      onMwsAck={this.onMwsAck}
                      onMwsGoBack={this.onMwsGoBack}
                    />
                  )}
                />
              ))}
            </Route>
          );
        } else {
          return null;
        }
      });
  };

  setDisableSidebarItem = (navItemName, disable) => {
    let items = [];
    items = items.concat(this.state.disableSidebarItems);
    if (disable && !items.includes(navItemName)) {
      items.push(navItemName);
    } else if (!disable && items.includes(navItemName)) {
      items = items.filter((item) => item === !navItemName);
    }
    this.setState({ disableSidebarItems: items });
  };

  redirectUser = () => {
    if (
      this.props.applications.find((a) => a === "Search Analytics") &&
      !this.props.applications.find((a) => a === "Advertising")
    ) {
      return "/user/searchanalytics";
    } else if (this.props.user.permissions === "reports") {
      return "/user/report/advertisement";
    }

    return "/user/dashboard";
  };

  render() {
    const { bypass_onboarding } = this.props.user ?? {};
    const {
      amazon_spapi_access,
      eu_amazon_spapi_access,
      fe_amazon_spapi_access,
    } = this.props.amz_onboard ?? {};

    if (
      bypass_onboarding &&
      [
        amazon_spapi_access,
        eu_amazon_spapi_access,
        fe_amazon_spapi_access,
      ].includes("in_progress")
    ) {
      return <SignupLayout placeholderLayout={true} />;
    }

    return (
      <>
        {this.props.authLoading ||
        this.props.marketPlace.marketPlace === "None" ? (
          <div className="wrap" style={{ height: "100vh" }}>
            <img
              src={logo}
              alt="Loading..."
              className="rotate"
              width="80"
              height="auto"
            />
          </div>
        ) : (
          <div className="wrapper">
            {/* functions */}
            <NotificationSystem ref={this.notificationSystem} style={style} />
            <BeamerInAppNotifications user={this.props.user} />
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              element={document}
              onIdle={this.onIdle}
              debounce={250}
              timeout={6 * 60 * 60 * 1000}
            />
            {/* SIDEBAR */}
            <Sidebar
              {...this.props}
              handleNotification={this.handleNotificationAction}
              disableSidebarItems={this.state.disableSidebarItems}
            />
            <div className="main-panel" ref={this.mainPanel}>
              {/* NAVBAR */}
              <NavBar
                {...this.props}
                handleMiniClick={this.handleMiniClick}
                navbar={this.state.navbar}
                handleNotification={this.handleNotificationAction}
                setDisableSidebarItem={this.setDisableSidebarItem}
              />
              <Banner />
              <ChromeAccessToken />

              {/* CONTENT PANEL */}
              <Switch>
                {this.getRoutes(routes)}
                <Redirect to={this.redirectUser()} />
              </Switch>

              {/* FOOTER */}
              <Footer fluid />
            </div>
            {this.props.user.agreementStatus === "not_agreed" ? (
              <TermsOfService
                handleNotification={this.handleNotificationAction}
              />
            ) : null}
          </div>
        )}
      </>
    );
  }
}

Main.propTypes = {
  setChannel: PropTypes.func.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth?.isAuthenticated,
  authLoading: state.auth?.loading,
  amz_onboard: state.amz_onboard,
  marketPlace: state.marketPlace,
  user: state.user,
  channel: state.channel,
  applications: selectApplications(state),
});

const mapDispatchToProps = {
  setChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
