import { useFetch } from "./api";

const useSearchTermReport = (
  query,
  start,
  end,
  limit,
  dimensions,
  options = {}
) => {
  if (!dimensions) {
    dimensions = "query";
  }
  let filters = `date__gte:${start},date__lte:${end}`;
  if (query) {
    if (dimensions === "query") {
      filters = `${filters},query__icontains:${query}`;
    } else {
      filters = `${filters},keyword_text__icontains:${query}`;
    }
  }

  let queryKeys = ["searchTermReport", start, end, query, limit, dimensions];

  if (options.additionalFilters) {
    let additionalFiltersString = Object.entries(options.additionalFilters)
      .map((filter) => `${filter[0]}:${filter[1]}`)
      .join(",");
    filters = `${filters},${additionalFiltersString}`;
  }

  if (options.additionalQueryKeys) {
    if (typeof options.additionalQueryKeys === "object") {
      queryKeys = [...queryKeys, ...options.additionalQueryKeys];
    } else {
      queryKeys.push(options.additionalQueryKeys);
    }
  }
  if(options?.category) {
    queryKeys.push(options.category);
    filters = `${filters},category_id:${options.category}`;
  }

  return useFetch(
    queryKeys,
    `/api/data_report_v2/searchtermreport/stats/`,
    {
      dimensions,
      filters,
      sort: options.sort ? options.sort : "-impressions",
      limit: limit,
    },
    {
      default: [],
      enabled: !!start && !!end,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      select: (res) => res.data,
    }
  );
};

export default useSearchTermReport;
