import React, { Component } from "react";

const WHEEL_SIZE = 155;

const wheelStyle = {
  marginTop: WHEEL_SIZE * 0.8 + "px",
  marginLeft: WHEEL_SIZE + "px",
  padding: "0",
  // position: "absolute",
  top: "90%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: WHEEL_SIZE + "px",
  width: WHEEL_SIZE + "px",
  backgroundColor: "#f8f8f8",
  border: "2px solid #f8f8f8",
  borderRadius: "120px",
};

const cardStyle = {
  margin: "0",
  padding: "0",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  height: "50px",
  width: "50px",
  // backgroundColor: props.color ? props.color : "blue",
  borderRadius: "25px",
};

export default class Wheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radius: 80, //110,
      cards: [],
      theta: 0.0,
    };
    this.temp_theta = 0.0;
  }

  __shouldComponentUpdate(nextProps, nextState) {
    return nextProps.selectedIndex !== this.props.selectedIndex;
  }

  componentDidUpdate() {
    // this.buildWheel();
  }

  componentDidMount() {
    // this.buildWheel();
  }

  get_coords(radian_interval, radius) {
    return {
      x: Math.cos(radian_interval) * radius,
      y: Math.sin(radian_interval) * radius,
    };
  }

  render() {
    let useList = this.props.imageList
      ? this.props.imageList
      : this.props.colorList;

    let useWheelWidth = this.wheel ? this.wheel.style.width : wheelStyle.width;
    let useWheelHeight = this.wheel
      ? this.wheel.style.height
      : wheelStyle.height;

    let radian_divisor = useList.length / 2;

    // let center_of_wheel = {
    //             x: parseFloat(this.wheel.style.width) / 2,
    //             y: parseFloat(this.wheel.style.height) / 2,
    // }
    let yOffset = this.props.yOffset ? this.props.yOffset : 0;
    let xOffset = this.props.xOffset ? this.props.xOffset : 0;
    let center_of_wheel = {
      x: parseFloat(useWheelWidth + xOffset) / 2,
      y: parseFloat(useWheelHeight + yOffset) / 2,
    };

    return (
      <div
        id="wheel_id"
        onWheel={this.handle_scroll}
        ref={(ref_id) => (this.wheel = ref_id)}
        style={wheelStyle}
      >
        {useList.map((item, i) => {
          let radian_interval = (Math.PI / radian_divisor) * i;
          let coord = this.get_coords(radian_interval, this.state.radius);
          //   let highlight = this.props.imageList ? this.props.imageList[i] : "";
          let highlight = this.props.selectedIndex === i ? "yellow" : "black";
          return (
            <div
              key={"div_card_wheel_" + i}
              onClick={() => {
                this.props.onClick(i);
                // this.forceUpdate();
              }}
              style={{ cursor: "pointer" }}
            >
              <div
                key={"div_cardwheel_" + i}
                style={{
                  ...cardStyle,
                  left: `${center_of_wheel.x + coord.x}px`,
                  top: `${center_of_wheel.y - coord.y}px`,
                  // backgroundColor: props.color ? props.color : "blue",
                  backgroundColor: this.props.colorList
                    ? this.props.colorList[i]
                    : "blue",
                  // boxShadow:"10px 10px 8px " + props.highlightColor,
                  boxShadow: "10px 10px 8px " + highlight,
                }}
              >
                {this.props.imageList && this.props.imageList[i] && (
                  <img
                    // key={"img_cardwheel_" + props.no}
                    key={"img_cardwheel_" + i}
                    // src={props.imageSrc}
                    src={this.props.imageList[i]}
                    // src={`https://picsum.photos/${200 + parseInt(props.no)}`}
                    // boxShadow:"10px 10px 8px " + props.highlightColor,
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      boxShadow:
                        "10px 10px 8px " + this.props.imageList
                          ? this.props.imageList[i]
                          : null,
                    }}
                    alt="pic"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
