import TrellisLink from "components/core/basic/TrellisLink";
import React from "react";
import { useSelector } from "react-redux";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import { snakeCaseToSentenceCase } from "utils/formatText";
import getURLPrefix from "utils/getUrlPrefix";

const URL_PREFIX = getURLPrefix();

const AdvertisementTitleCell = ({ action, advertisement, children = null }) => {
  const image = `${URL_PREFIX}/api/productimage/?advertisement=${advertisement.id}`;

  return (
    <div className="d-flex">
      <div
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div className="overflow-ellipsis" style={{ textAlign: "left" }}>
        <TrellisLink
          to={`/user/advertising/dashboard/ads/${advertisement.id}`}
          style={{
            textDecoration: "none",
            fontWeight: "500",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          {advertisement.name}
        </TrellisLink>{" "}
        {action}
        {children}
      </div>
    </div>
  );
};

const ProductTitleCell = ({ action, product, children = null }) => {
  if (!product) return <>{children}</>;
  const image = `${URL_PREFIX}/api/productimage/?product=${product?.id}`;

  return (
    <div className="d-flex">
      <div
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          flexShrink: 0,
          width: 50,
          height: 50,
          marginRight: "10px",
          background: `url(${image}) no-repeat center / contain`,
        }}
        draggable="false"
      />
      <div className="overflow-ellipsis" style={{ textAlign: "left" }}>
        <TrellisLink
          to={`/user/merchandising/product/${product?.id}`}
          style={{
            textDecoration: "none",
            fontWeight: "500",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          {product?.product_title}
        </TrellisLink>{" "}
        {action}
        {children}
      </div>
    </div>
  );
};

const CustomTitleCell = ({ row, children }) => {
  const { advertisement, product, description } = row;

  return (
    <div className="d-flex">
      <div className="overflow-ellipsis" style={{ textAlign: "left" }}>
        {advertisement ? (
          <AdvertisementTitleCell
            advertisement={advertisement}
            children={children}
          />
        ) : product ? (
          <ProductTitleCell product={product} children={children} />
        ) : (
          <span
            style={{
              textDecoration: "none",
              fontWeight: "500",
              fontSize: "1.5rem",
            }}
          >
            {description}
          </span>
        )}
      </div>
    </div>
  );
};

export const listMetaUpdates = (
  meta,
  marketPlace = "USA",
  textOnly = false
) => {
  const formattingMap = {
    max_acos: "percent",
    target_acos: "percent",
    daily_budget: "currency",
  };

  if (textOnly) {
    return Object.keys(meta)?.map((key) => {
      const { change, current, previous } = meta[key];
      const formatter = (v, key) => {
        const format = formattingMap[key];
        switch (format) {
          case "percent":
            return formatPercent(v / 100);
          case "currency":
            return formatCurrency(v, marketPlace.marketPlace);
          case "number":
          default:
            return formatNumber(v);
        }
      };

      return `${snakeCaseToSentenceCase(key).toLowerCase()} ${
        change > 0 ? "increased" : "decreased"
      } by ${formatter(Math.abs(change), key)} from ${formatter(
        previous,
        key
      )} to ${formatter(current, key)}`;
    });
  }

  return (
    <>
      <ul className="fs-5">
        {Object.keys(meta)?.map((key) => {
          const { change, current, previous } = meta[key];
          const formatter = (v, key) => {
            const format = formattingMap[key];
            switch (format) {
              case "percent":
                return formatPercent(v / 100);
              case "currency":
                return formatCurrency(v, marketPlace.marketPlace);
              case "number":
              default:
                return formatNumber(v);
            }
          };

          return (
            <li key={key}>
              {snakeCaseToSentenceCase(key)}{" "}
              {change > 0 ? "increased" : "decreased"} by{" "}
              {formatter(Math.abs(change), key)} from {formatter(previous, key)}{" "}
              to {formatter(current, key)}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const AnnotationCell = ({ row }) => {
  const { marketPlace } = useSelector((state) => state);

  const { annotation_type, meta, advertisement, product, description } = row;

  const renderContentByType = (type) => {
    switch (type) {
      case "AD_CREATED":
        return (
          <AdvertisementTitleCell
            action={"created"}
            advertisement={advertisement}
          />
        );
      case "AD_UPDATED":
        return (
          <>
            <AdvertisementTitleCell
              action={"updated"}
              advertisement={advertisement}
              children={listMetaUpdates(meta, marketPlace)}
            />
          </>
        );
      case "PRODUCT_CONTENT_CHANGE":
        return (
          <ProductTitleCell product={product}>
            <br />
            Content Updated
          </ProductTitleCell>
        );
      case "PROMO_STARTED":
        return (
          <>
            <ProductTitleCell product={product}>
              <br />
              Promotion Started
            </ProductTitleCell>
          </>
        );
      case "PROMO_ENDED":
        return (
          <>
            <ProductTitleCell product={product}>
              <br />
              Promotion Ended
            </ProductTitleCell>
          </>
        );
      case "CUSTOM":
        return (
          <>
            <CustomTitleCell row={row}>
              <br />
              {description}
            </CustomTitleCell>
          </>
        );
      default:
        return (
          <div>
            <h4>Other</h4>
          </div>
        );
    }
  };

  return <>{renderContentByType(annotation_type)}</>;
};

export default AnnotationCell;
