import React, { useMemo, useRef } from "react";
import SummaryCard from "components/core/blocks/SummaryCard";
import { DateField, Label } from "components/custom/programs/fields";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { PrimaryButton } from "components/core/basic/Button";
import { useFetch } from "hooks/api";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { useSelector } from "react-redux";
import { buildLookupColumn } from "views/merchandise/columns/buildLookupColumn";
import { buildDateColumn } from "views/merchandise/columns/buildDateColumn";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";
import { HiDownload } from "react-icons/hi";
import getURLPrefix from "utils/getUrlPrefix";
import axios from "axios";
import FileSaver from "file-saver";
import { FaSpinner } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import moment from "moment";
import Select from "react-select";

const API_URL = getURLPrefix();

const Exports = () => {
  const { organization_type: profileType } = useSelector(
    (state) => state?.user
  );
  const reportOptions = useMemo(() => {
    const options = [
      {
        value: "product_report",
        label: "Products Report",
      },
      {
        value: "ad_report",
        label: "Media Plans Report",
      },
    ];
    if (profileType === "vendor") {
      options.push({
        value: "vendor_retail_price_report",
        label: "Vendor Retail Price Report",
      });
    }
    return options;
  }, [profileType]);
  const { data: exports, isLoading } = useFetch(
    ["exports"],
    "/exports/",
    { ordering: "-created_on" },
    {
      refetchInterval: (data) => {
        const results = data?.data?.results ?? [];
        const isPending = results.find((r) => r.status === 0 || r.status === 1);
        return isPending ? 5 * 1000 : 30 * 1000;
      },
      select: (data) => data?.data?.results ?? [],
    }
  );
  const { marketPlace } = useSelector((state) => state);
  const tableRef = useRef();
  const { handleSubmit, formState, control } = useForm({
    defaultValues: {
      start_date: moment().subtract(30, "days").toDate(),
      end_date: moment().toDate(),
      report: "product_report",
    },
  });
  const queryClient = useQueryClient();

  const makeReport = useMutation(
    async (data) => {
      const reportType = data.report;
      if (reportType === "vendor_retail_price_report") {
        return await api.post(`/api/custom_reports/`, {
          mp: marketPlace.marketPlace,
          report_type: reportType,
          params: {
            start_date: moment(data.start_date).format("YYYY-MM-DD"),
            end_date: moment(data.end_date).format("YYYY-MM-DD"),
          },
        });
      }
      return await api.get(`/api/data_report/${reportType}`, {
        xls: true,
        task: true,
        empty: true,
        mp: marketPlace.marketPlace,
        start_date: moment(data.start_date).format("YYYY-MM-DD"),
        end_date: moment(data.end_date).format("YYYY-MM-DD"),
      });
    },
    {
      onSuccess: async (data) => {
        return queryClient.invalidateQueries(["exports"]);
      },
    }
  );

  const handleDownload = async (id, title) => {
    let url = `${API_URL}/api/exports/${id}/download/`;
    const response = await axios({
      method: "GET",
      url: url,
      responseType: "blob",
    });

    let blob = new Blob([response.data], {
      type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    FileSaver.saveAs(blob, `${title}.xlsx`);
  };

  const columnSpecs = [
    buildLookupColumn("status", "Status", {
      0: "Pending",
      1: "Running",
      2: "Ready",
      3: "Failed",
      4: "Cancelled",
    }),
    buildTextColumn("progress", "Progress"),
    buildDateColumn("created_on", "Created On"),
    {
      key: "download",
      compareKey: null,
      options: {
        className: "text-start justify-content-start px-5 h-100",
        cellContainerClasses: "text-start justify-content-start",
        metric: {
          id: "download",
          format: "text",
          accessor: (d) => d,
          name: "Download",
        },
        hideFilter: true,
        width: 250,
        formatter: (v) => {
          const { id, name, status } = v;
          if (status === 1 || status === 0)
            return <FaSpinner className="fa-spin" />;
          if (status === 2) {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDownload(id, name);
                }}
              >
                <HiDownload />
              </span>
            );
          }
          return <span>-</span>;
        },
      },
    },
  ];
  return (
    <Container
      style={{
        background: "#fefefe",
        minHeight: "calc(100vh - 150px)",
      }}
    >
      <Row>
        <Col xs={12} className="mt-4">
          <SummaryCard title="Exports" mobileStyle>
            <Row>
              <Col xs={12} lg={8} xl={6}>
                <Row>
                  <Col xs={12} className="mb-4">
                    <Form.Group>
                      <Label>Reports</Label>
                      <div className="fs-standard">
                        <Controller
                          name="report"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                isSearchable={false}
                                closeMenuOnSelect={true}
                                name="Report"
                                id="report"
                                options={reportOptions}
                                value={reportOptions.find(
                                  (option) => option.value === field.value
                                )}
                                onChange={(option) => {
                                  field.onChange(option.value);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <DateField
                      label="Start Date"
                      placeholder="Starting on..."
                      fieldName="start_date"
                      errors={formState.errors}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Start date is required",
                        },
                      }}
                    />
                  </Col>
                  <Col xs={6}>
                    <DateField
                      label="End Date"
                      placeholder="Ending on..."
                      fieldName="end_date"
                      errors={formState.errors}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "End date is required",
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="mt-3 d-flex justify-content-end">
                <PrimaryButton nowrap onClick={handleSubmit(makeReport.mutate)}>
                  Generate Export
                </PrimaryButton>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <BulkActionsTable
                  noDataText="Create an export to get started"
                  marketPlace={marketPlace}
                  tableRef={tableRef}
                  tableId={"productReportTable"}
                  titleCol={{
                    id: "name",
                    Header: "Title",
                    accessor: "name",
                    style: { width: "450px" },
                    width: 450,
                  }}
                  columnSpecs={columnSpecs}
                  data={exports || []}
                  hideFilterToggleIcon={true}
                  isSelectable={false}
                  isExpandable={false}
                  defaultSorted={[{ id: "created_on", desc: false }]}
                  getSearchCriteria={(row) => {
                    const { name } = row;
                    return `${name}`;
                  }}
                  tableRowDescription={"Product Reports"}
                  minRows={10}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </SummaryCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Exports;
