import React from "react";
import { Checkbox } from "@material-ui/core";
import ProductImage from "components/custom/category/listings/ProductImage";
import {
  formatNumber,
  formatPercent,
  parseFormattedNumber,
} from "utils/formatNumber";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { generateFilterMarkup, filterRanges } from "utils/rangeDropdownFilter";
import "assets/css/dashboard_table.css";
import ToolTips from "utils/toolTips";
import "assets/css/dashboard_table.css";
import { Badge } from "react-bootstrap";
import { getDropdownOptions } from "./getFilters";
import moment from "moment";
import { INVENTORY_STATES } from "components/core/blocks/AdPlan/constants";
import { Link } from "react-router-dom";
import store from "redux/store";

export const getColumns = (
  searchFilter = "",
  filtered = [],
  disableSelectAllCheckbox,
  selectedProducts,
  setSelectedProducts,
  productData,
  filteredData,
  resolvedTableData,
  assessChecked,
  handleProductSelection,
  disableRowCheckbox,
  user,
  marketPlace,
  totalAdSalesRanges,
  columnsToRender,
  colToggle,
  mediaPlanSelector,
  review,
  initialProducts = [],
  hideClearAll,
  hideSelectAll,
  selectorId,
  singleProductSelection,
  inventoryStateFilter,
  tableRef
) => {
  let ranges = getDropdownOptions(productData) ?? {};

  const {
    channel: { currentChannel: channel },
  } = store.getState() ?? {};

  let primaryColumns = [
    {
      id: "select",
      isStatic: true,
      Header: (props) => {
        if (hideSelectAll) {
          return null;
        }
        return (
          <>
            <div style={{ position: "static" }}>
              <ToolTips
                toolTip={`Select all${
                  searchFilter.length > 0 || filtered.length > 0
                    ? " visible"
                    : ""
                } products`}
                position={"top"}
              />
            </div>
            <Checkbox
              disabled={disableSelectAllCheckbox(selectedProducts, productData)}
              checked={assessChecked(
                selectedProducts,
                productData,
                filteredData,
                true
              )}
              onChange={(e) => {
                e.preventDefault();
                let resolvedTableData =
                  tableRef.current?.getResolvedState()?.sortedData;
                let allSelected = resolvedTableData
                  ?.map((r) => r._original?.id)
                  ?.every((id) => selectedProducts?.includes(id));

                if (allSelected) {
                  let updatedIds = selectedProducts?.filter(
                    (id) =>
                      !resolvedTableData
                        .map((r) => r._original?.id)
                        ?.includes(id)
                  );

                  setSelectedProducts(updatedIds);
                } else {
                  let newIds = resolvedTableData
                    ?.map((r) => r._original?.id)
                    ?.filter((id) => !selectedProducts?.includes(id));

                  setSelectedProducts([...selectedProducts, ...newIds]);
                }
              }}
            />
          </>
        );
      },
      headerStyle: { textAlign: "center", maxWidth: "50px" },
      style: { textAlign: "center", border: "none", maxWidth: "50px" },
      accessor: "asin",
      Filter: ({ filter, onChange }) => null,
      filterMethod: (filter, row) => {},
      Cell: (props) => {
        if (!props.row._original.is_parent) {
          return (
            <Checkbox
              checked={selectedProducts?.some(
                (p) => p === props.row._original.id
              )}
              // Disabled deselection of products for existing ads, only allow products to be added or paused
              disabled={
                mediaPlanSelector
                  ? hideClearAll &&
                    initialProducts.some((p) => p === props.row._original.id)
                  : disableRowCheckbox(props)
              }
              onChange={(e) => {
                if (
                  selectedProducts?.some((p) => p === props.row._original.id)
                ) {
                  setSelectedProducts(
                    selectedProducts?.filter(
                      (p) => p !== props.row._original.id
                    ) ?? []
                  );
                } else {
                  setSelectedProducts(
                    selectedProducts?.length > 0
                      ? [...selectedProducts, props.row._original.id]
                      : [props.row._original.id]
                  );
                }
              }}
            />
          );
        }
        return null;
      },
      width: 50,
      sortable: false,
      resizable: false,
    },
    {
      id: "title",
      Header: "Title",
      isStatic: true,
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center", border: "none" },
      accessor: "asin",
      width: selectorId === "npl" ? 380 : 300,
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(filter, onChange, "asin", "select", null, [
          { value: "all", label: "Show All" },
          { value: "selected", label: "Selected" },
          { value: "unselected", label: "Unselected" },
        ]),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (row._original.is_parent) {
          return false;
        }
        if (filter.value === "selected") {
          return selectedProducts?.some(
            (productId) => productId === row._original.id
          );
        }
        if (filter.value === "unselected") {
          if (!selectedProducts || selectedProducts.length === 0) {
            return true;
          }
          return selectedProducts?.every(
            (productId) => productId !== row._original.id
          );
        }
      },
      Cell: (props) => {
        let {
          id,
          product_title,
          stock_status: inventoryState,
          primary_variant: primaryVariant,
          parent__asin: parentItemId,
          asin,
          sku,
          buy_box: buyBox,
          eligibility_status: eligibilityStatus,
        } = props.row._original;

        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ float: "left" }}>
                <ProductImage
                  divId={"parentProductImage" + id}
                  id={id}
                  width="50px"
                  height="50px"
                />
              </div>{" "}
              <div style={{ float: "left" }}>
                <Link
                  to={`/user/merchandising/product/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <>
                    <div
                      key={"parentProductAsin" + id}
                      style={{
                        marginLeft: "1.5rem",
                        fontSize: "15px",
                        color: "#d91266",
                        textAlign: "center",
                        textDecoration: "underline",
                        fontWeight: "500",
                        float: "left",
                      }}
                    >
                      {selectorId === "npl"
                        ? `${asin} (${sku})`
                        : user.productIdentifier === "sku"
                        ? sku
                        : asin}
                    </div>

                    <div
                      style={{
                        float: "left",
                        marginLeft: "1.5rem",
                        fontSize: "1.25rem",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      {product_title}
                    </div>
                  </>
                </Link>
              </div>
            </div>
            <div
              className={`d-flex ${
                inventoryState !== "IN_STOCK" ||
                (!buyBox && channel !== "walmart")
                  ? "pl-4"
                  : ""
              }`}
              style={{ marginLeft: "50px" }}
            >
              {inventoryState !== "IN_STOCK" && (
                <Badge
                  className="badge bg-trellis-purple me-3"
                  pill
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  {INVENTORY_STATES[inventoryState]?.label}
                </Badge>
              )}
              {eligibilityStatus !== "ELIGIBLE" && (
                <Badge
                  className="badge bg-trellis-yellow me-3"
                  pill
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  {snakeCaseToSentenceCase(eligibilityStatus)}
                </Badge>
              )}
              {channel === "walmart" && (
                <>
                  {primaryVariant ? (
                    <Badge
                      className="badge bg-trellis-fuchsia"
                      pill
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      Primary Variant
                    </Badge>
                  ) : parentItemId ? (
                    <Badge
                      className="badge bg-trellis-mauve"
                      pill
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      Parent ID: {parentItemId}
                    </Badge>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {/* {!buyBox && (
                <Badge
                  className={"badge bg-trellis-yellow ms-3"}
                  pill
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  Buy Box Lost
                </Badge>
              )} */}
            </div>
          </>
        );
      },
    },
    {
      id: "totalSales",
      Header: "Total Sales",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "total_sales",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { total_sales } = props.row._original ?? {};

        if (!product.children) {
          return formatNumber(total_sales, {
            currency: { marketPlace: marketPlace.marketPlace },
          });
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original?.total_sales,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "total_sales",
          "range",
          "currency",
          {
            ranges: ranges?.totalAdSalesRanges ?? [],
            marketPlace: marketPlace.marketPlace,
          }
        ),
    },
    {
      id: "adSales",
      Header: "Ad Sales",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "ad_sales",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { ad_sales: sales } = props.row._original ?? {};

        if (!product.children) {
          return formatNumber(sales, {
            currency: { marketPlace: marketPlace.marketPlace },
          });
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original?.ad_sales,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "ad_sales",
          "range",
          "currency",
          {
            ranges: ranges.adSalesRanges ?? [],
            marketPlace: marketPlace.marketPlace,
          }
        ),
    },
    {
      id: "adSpend",
      Header: "Ad Spend",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "cost",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { cost } = props.row._original ?? {};

        if (!product.children) {
          return formatNumber(cost, {
            currency: { marketPlace: marketPlace.marketPlace },
          });
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(filter, row._original?.cost, parseFormattedNumber, {
          parentProduct: row._original.is_parent,
        });
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(filter, onChange, "cost", "range", "currency", {
          ranges: ranges?.adSpendRanges ?? [],
          marketPlace: marketPlace.marketPlace,
        }),
    },
    // ACOS/ROAS Stats
    {
      id: "acos",
      Header: user?.preferences?.acos_or_roas === "roas" ? "Roas" : "Acos",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "acos",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { cost, ad_sales: sales } = props.row._original ?? {};

        if (!product.children) {
          return (
            user?.preferences?.acos_or_roas === "roas"
              ? props.value > 0
                ? +(1 / (cost / sales)).toFixed(2).toString()
                : "-"
              : cost > 0 && sales > 0
              ? ((cost / sales) * 100).toFixed(0) + "%"
              : "-"
          ).toString();
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return user.preferences.acos_or_roas === "acos"
          ? filterRanges(
              filter,
              row._original?.acos * 100,
              parseFormattedNumber,
              { parentProduct: row._original.is_parent }
            )
          : filterRanges(filter, row._original?.roas, parseFormattedNumber, {
              parentProduct: row._original.is_parent,
            });
      },
      Filter: ({ filter, onChange }) =>
        user?.preferences?.acos_or_roas === "acos"
          ? // ACOS
            generateFilterMarkup(
              filter,
              onChange,
              "ad_sales",
              "range",
              "percent",
              {
                ranges: ranges?.acosRanges ?? [],
              }
            )
          : // ROAS
            generateFilterMarkup(
              filter,
              onChange,
              "ad_sales",
              "range",
              "number",
              {
                ranges: ranges?.roasRanges ?? [],
              }
            ),
    },
    {
      id: "totalAcos",
      Header:
        user?.preferences?.acos_or_roas === "roas"
          ? "Total ROAS"
          : "Total ACOS",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "total_acos",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { total_acos, total_roas } = props.row._original ?? {};

        if (!product.children) {
          return (
            user?.preferences?.acos_or_roas === "roas"
              ? total_roas > 0
                ? +total_roas.toFixed(2).toString()
                : 0
              : total_acos > 0
              ? (total_acos * 100).toFixed(0) + "%"
              : "-"
          ).toString();
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return user.preferences.acos_or_roas === "acos"
          ? filterRanges(
              filter,
              row._original?.total_acos * 100,
              parseFormattedNumber,
              { parentProduct: row._original.is_parent }
            )
          : filterRanges(
              filter,
              row._original?.total_roas,
              parseFormattedNumber,
              { parentProduct: row._original.is_parent }
            );
      },
      Filter: ({ filter, onChange }) =>
        user.preferences.acos_or_roas === "acos"
          ? // ACOS
            generateFilterMarkup(
              filter,
              onChange,
              "total_acos",
              "range",
              "percent",
              {
                ranges: ranges?.totalAcosRanges ?? [],
              }
            )
          : // ROAS
            generateFilterMarkup(
              filter,
              onChange,
              "total_roas",
              "range",
              "number",
              {
                ranges: ranges?.totalRoasRanges ?? [],
              }
            ),
    },
    // InventoryState
    {
      id: "inventoryState",
      Header: "Inventory State",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "inventory_state",
      width: 90,
      Cell: (props) => {
        let { inventory_state } = props.original;
        return (
          <div>
            <p>
              <Badge className="badge badge-primary" pill>
                {snakeCaseToSentenceCase(inventory_state)}
              </Badge>
            </p>
          </div>
        );
      },
      filterMethod: (filter, row) => {
        const { inventory_state } = row._original;

        return filter.value === "all" || inventory_state === filter.value;
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(filter, onChange, "asin", "select", null, [
          { value: "all", label: "Show All" },
          { value: "OVERSTOCK", label: "Overstock" },
          { value: "LOW_STOCK", label: "Low Stock" },
          { value: "OUT_OF_STOCK", label: "Out of Stock" },
        ]),
    },
    // Sales State
    {
      id: "salesState",
      Header: "Sales State",
      headerClassName: "wordwrap",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "sales_state",
      width: 90,
      Cell: (props) => {
        let { sales_state } = props.original;
        return (
          <div>
            <p>
              <Badge className="badge-primary" pill>
                {snakeCaseToSentenceCase(sales_state)}
              </Badge>
            </p>
          </div>
        );
      },
      filterMethod: (filter, row) => {
        const { sales_state } = row._original;

        return filter.value === "all" || sales_state === filter.value;
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(filter, onChange, "asin", "select", null, [
          { value: "all", label: "Show All" },
          { value: "BEST_SELLER", label: "Best Seller" },
          { value: "CLEARANCE", label: "Clearance" },
          { value: "NEW_PRODUCT", label: "New Product" },
        ]),
    },
    {
      id: "maxDiscount",
      Header: "Max Discount",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "merchandise_details.max_discount",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { max_discount } = props.row._original?.merchandise_details ?? {};

        if (!product.children) {
          return max_discount > 0 ? `${formatPercent(max_discount)}` : "-";
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.merchandise_details?.max_discount * 100,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "merchandise_details?.max_discount",
          "range",
          "percent",
          {
            ranges: ranges?.maxDiscountRanges ?? [],
          }
        ),
    },
    {
      id: "margin",
      Header: "Margin",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "merchandise_details.margin",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { margin } = props.row._original?.merchandise_details ?? {};

        if (!product.children) {
          return margin > 0 ? `${formatPercent(margin)}` : "-";
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.merchandise_details?.margin * 100,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "merchandise_details.margin",
          "range",
          "percent",
          {
            ranges: ranges?.marginRanges ?? [],
          }
        ),
    },
    {
      id: "minMargin",
      Header: "Min Margin",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "merchandise_details.min_margin",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { min_margin } = props.row._original?.merchandise_details ?? {};

        if (!product.children) {
          return min_margin > 0 ? `${formatPercent(min_margin)}` : "-";
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.merchandise_details?.min_margin * 100,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "merchandise_details?.min_margin",
          "range",
          "percent",
          {
            ranges: ranges?.minMarginRanges ?? [],
          }
        ),
    },
    // Net price
    {
      id: "netPrice",
      Header: "Net Price",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "net_price",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { net_price } = props.row._original ?? {};

        if (!product.children) {
          return formatNumber(net_price, {
            currency: { marketPlace: marketPlace.marketPlace },
          });
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original?.net_price,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "net_price",
          "range",
          "currency",
          {
            ranges: ranges?.netPriceRanges ?? [],
            marketPlace: marketPlace.marketPlace,
          }
        ),
    },
    // Gross price
    {
      id: "grossPrice",
      Header: "Gross Price",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "gross_price",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { gross_price } = props.row._original ?? {};

        if (!product.children) {
          return formatNumber(gross_price, {
            currency: { marketPlace: marketPlace.marketPlace },
          });
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original?.gross_price,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "gross_price",
          "range",
          "currency",
          {
            ranges: ranges?.grossPriceRanges ?? [],
            marketPlace: marketPlace.marketPlace,
          }
        ),
    },
    // Inventory count
    {
      id: "inventoryCount",
      Header: "Inventory",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "master_product.inventory",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { inventory } = props.row._original?.master_product ?? {};

        if (!product.children) {
          return formatNumber(inventory);
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.master_product?.inventory,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "master_product.inventory",
          "range",
          "number",
          {
            ranges: ranges?.inventoryRanges ?? [],
          }
        ),
    },
    // Monthly units sold
    {
      id: "monthlyUnitsSold",
      Header: "Monthly Units Sold",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "monthly_total_units_ordered",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { monthly_total_units_ordered } =
          props.row._original?.rollup_stats ?? {};

        if (!product.children) {
          return formatNumber(monthly_total_units_ordered, {});
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.rollup_stats?.monthly_total_units_ordered,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "monthly_total_units_ordered",
          "range",
          "number",
          {
            ranges: ranges?.monthlyUnitsSoldRanges ?? [],
          }
        ),
    },
    // Clicks
    {
      id: "clicks",
      Header: "Clicks",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "clicks",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { clicks } = props.row._original ?? {};

        if (!product.children) {
          return formatNumber(clicks);
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original?.clicks,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(filter, onChange, "clicks", "range", "number", {
          ranges: ranges?.clickRanges ?? [],
          marketPlace: marketPlace.marketPlace,
        }),
    },
    // Impressions
    {
      id: "impressions",
      Header: "Impressions",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      accessor: "impressions",
      width: 90,
      Cell: (props) => {
        const product = props.row._original;
        const { impressions } = props.row._original ?? {};

        if (!product.children) {
          return formatNumber(impressions);
        }
        return null;
      },
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original?.impressions,
          parseFormattedNumber,
          { parentProduct: row._original.is_parent }
        );
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(
          filter,
          onChange,
          "impressions",
          "range",
          "number",
          {
            ranges: ranges?.impressionRanges ?? [],
            marketPlace: marketPlace.marketPlace,
          }
        ),
    },
    {
      id: "createdOn",
      Header: "Created On",
      isStatic: true,
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center", border: "none" },
      accessor: "created_on",
      width: 90,
      Filter: ({ filter, onChange }) => null,
      filterMethod: (filter, row) => {},
      Cell: (props) => {
        const { created_on } = props.row._original;
        return <>{moment(created_on).format("MMM DD YYYY")}</>;
      },
      sortMethod: (a, b) => {
        a = new Date(a).getTime();
        b = new Date(b).getTime();
        return b > a ? 1 : -1;
      },
    },
  ];

  // Override behavior: accepts string id for common column, allows common column with hidden default state as object with id and checked set to false,
  // override with entire columns definition if id not found
  let returnColumns = columnsToRender.map((col) =>
    typeof col === "object"
      ? { ...primaryColumns.find((p) => p?.id === col.id), ...col }
      : primaryColumns.find((p) => p?.id === col)
  );

  // Remove checkbox if in review/summary mode
  if (mediaPlanSelector && review) {
    returnColumns.splice(0, 1);
  }
  return returnColumns;
};
