import React from "react";
import { formatCurrency, formatPercent } from "./formatNumber";
import { scaleLinear } from "d3-scale";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";

const getNestedAccessors = (row, targetProperty) => {
  let result = row;
  if (row) {
    for (const key of targetProperty.split(".")) {
      result = result[key];
    }
    return result;
  }
  return row;
};

export const generateRanges = (
  data,
  numOfRanges,
  targetProperty,
  format = "number",
  marketplace = "USA",
  allowNegatives = false
) => {
  if (data?.length > 0) {
    const rangeSourceData = data
      .map((row) => getNestedAccessors(row, targetProperty))
      .filter((d) => d !== null && d !== undefined)
      .sort((a, b) => a - b);

    // Avoid decimals in ranges if upper bound is small and whole numbers are desired for formatting
    if (
      format === "number" &&
      rangeSourceData[rangeSourceData.length - 1] < numOfRanges
    ) {
      rangeSourceData.push(numOfRanges);
    }

    // Process ranges from 0 to upper threshold of sorted data
    let x = scaleLinear().domain([
      rangeSourceData?.[0] < 0 ? rangeSourceData[0] : 0,
      rangeSourceData[rangeSourceData.length - 1],
    ]);

    const ticks = x.ticks(numOfRanges);
    const ceiling = rangeSourceData[rangeSourceData.length - 1];

    // TODO: Revisit below behavior and look for d3 scaleTick params that force lowest value to be part of ticks (not obvious in docs)
    // d3 sometimes clips domain lower threshold at 0 if negative numbers a small portion
    // of overall scale. Below ensures all negative values are included in filter ranges if clipped
    if (rangeSourceData[0] < 0 && ticks[0] >= 0) {
      ticks.unshift(Math.floor(rangeSourceData[0]));
    }

    // Return ranges in specified format, if present
    const ranges = ticks.map((tickFormat, index) => {
      // ticks.length is more reliable for covering full range than numOfRanges, as d3 sometimes produces
      // less results than the argument value if they come out cleaner/more even
      if (format === "percentage") {
        return index < ticks.length - 1
          ? `${tickFormat * 100}% - ${ticks[index + 1] * 100}%`
          : `${tickFormat * 100}% - ${
              ceiling || ceiling === 0 ? Math.ceil(ceiling * 100) : null
            }%`;
      } else if (format === "currency") {
        return index < ticks.length - 1
          ? `${MARKETPLACE_CURRENCY_DENOMINATION[marketplace]}${tickFormat} - ${
              MARKETPLACE_CURRENCY_DENOMINATION[marketplace]
            }${ticks[index + 1]}`
          : `${MARKETPLACE_CURRENCY_DENOMINATION[marketplace]}${tickFormat} - ${
              MARKETPLACE_CURRENCY_DENOMINATION[marketplace]
            }${ceiling ? Math.ceil(ceiling) : ""}`;
      } else if (format === "cents") {
        return index < ticks.length - 1
          ? `${
              MARKETPLACE_CURRENCY_DENOMINATION[marketplace]
            }${tickFormat?.toFixed(2)} - ${
              MARKETPLACE_CURRENCY_DENOMINATION[marketplace]
            }${ticks[index + 1]?.toFixed(2)}`
          : `${
              MARKETPLACE_CURRENCY_DENOMINATION[marketplace]
            }${tickFormat?.toFixed(2)} - ${
              MARKETPLACE_CURRENCY_DENOMINATION[marketplace]
            }${ceiling?.toFixed(2)}`;
      } else if (format === "rounded") {
        return index < ticks.length - 1
          ? `${tickFormat.toFixed(1)} - ${ticks[index + 1]?.toFixed(1)}`
          : `${tickFormat.toFixed(1)} - ${ceiling?.toFixed(1)}`;
      } else {
        return index < ticks.length - 1
          ? `${tickFormat} - ${ticks[index + 1]}`
          : `${tickFormat} - ${Math.ceil(ceiling)}`;
      }
    });

    // If last range contains undefined, swap out with ceiling value
    if (
      ranges[ranges.length - 1] &&
      (ranges[ranges.length - 1].indexOf("undefined") > -1 ||
        ranges[ranges.length - 1].indexOf("NaN") > -1)
    ) {
      ranges[ranges.length - 1] = `${
        ranges[ranges.length - 1].split(" - ")[0]
      } - ${
        format === "currency" || format === "cents"
          ? `${MARKETPLACE_CURRENCY_DENOMINATION[marketplace]}`
          : ""
      }${
        format === "cents" ? Math.ceil(ceiling).toFixed(2) : Math.ceil(ceiling)
      }${format === "percentage" ? "%" : ""}`;
    }

    //Remove last range element if redundant (covering ranges already handled earlier)
    if (
      ranges[ranges.length - 1]?.split(" - ")[1] ===
        ranges[ranges.length - 2]?.split(" - ")[1] ||
      Number.parseFloat(
        ranges[ranges.length - 1]?.split(" - ")[1]?.split("%")[0]
      ) ===
        Number.parseFloat(
          ranges[ranges.length - 2]?.split(" - ")[1]?.split("%")[0]
        )
    ) {
      ranges.pop();
    }

    return ranges;
  }

  return [];
};

export const generateFilterMarkup = (
  filter,
  onChange,
  accessor,
  type,
  rangeFormat,
  options = {
    decimalPlaces: 0,
  },
  filterStyles = {}
) => {
  switch (type) {
    case "select":
      return (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{
            width: filterStyles?.width ? filterStyles?.width : "100%",
            alignSelf: "flex-start",
          }}
          value={filter ? filter.value : "all"}
        >
          {options.map((o) => (
            <option value={o.value} key={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      );
    case "range":
      const { ranges } = options;
      return (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {ranges &&
            ranges?.map((range, index) => {
              let formattedRange = range;
              let lowerBound = parseFloat(range.split(" - ")[0]);
              let upperBound = parseFloat(range.split(" - ")[1]);

              if (rangeFormat === "currency") {
                formattedRange = `${formatCurrency(
                  lowerBound,
                  options.marketPlace,
                  true
                )} - ${formatCurrency(upperBound, options.marketPlace, true)}`;
              }

              if (rangeFormat === "percent") {
                formattedRange = `${formatPercent(
                  lowerBound / 100
                )} - ${formatPercent(upperBound / 100)}`;
              }

              return (
                <option key={index} value={range}>
                  {formattedRange}
                </option>
              );
            })}
        </select>
      );
    default:
      return;
  }
};

export const filterRanges = (
  filter,
  accessor,
  parseFunction,
  options = null
) => {
  // Don't include parent products in filter results
  if (options?.parentProduct) {
    return false;
  }

  if (filter.value === "all") {
    return true;
  }
  const [start, end] = filter.value
    .split(" - ")
    .map((v) => parseFunction(v, options));
  if (accessor >= start && accessor <= end) {
    return true;
  }
};
