import React, { useState, useMemo, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useFetch } from "hooks/api";
import { Col, Container as Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Accordion from "components/core/basic/Accordion";
import { useLocation, useParams } from "react-router-dom";
import DownloadReport from "modules/perf_reports/DownloadReport";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";

import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import Loading from "components/core/blocks/Loading";
import getMetricSpec from "./merchandise/metrics";
import { selectModules } from "redux/selectors/modules";
import AdPlanModal from "components/core/blocks/AdPlan/AdPlanModal";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import AdPlanBulkCreate from "components/core/blocks/AdPlan/AdPlanBulkCreate";
import {
  AD_CREATION_FAILED_STATUS,
  MODERATION_STATUSES,
  STATUS,
} from "components/core/blocks/AdPlan/constants";
import withAdPlan from "components/core/blocks/AdPlan/withAdPlan";
import NotificationSystem from "react-notification-system";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";

import useCategories from "hooks/useCategories";
import InsightsTable from "./advertising/InsightsTable";
import checkModule from "utils/checkModule";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import CategoryProducts from "./advertising/CategoryProducts";
import { formatCurrency } from "utils/formatNumber";
import DetailedMetric from "components/core/blocks/DetailedMetric";
import AdvertisementsTable from "./AdvertisementsTable";
import AdTypePieCharts from "./advertising/AdTypePieCharts";
import AnnotationsTable from "components/custom/growth/AnnotationsTable";

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  return metric;
};

export const getDashboardTo = ({ type, id }) => {
  let to = ``;

  const query = {};

  if (!id) {
    to = `/user/advertising/dashboard`;
  } else {
    if (type === "category") {
      to = `/user/advertising/dashboard/category/${id}`;
    }

    if (type === "advertisement") {
      to = `/user/advertising/dashboard/ads/${id}`;
      query.tab = "details";
    }

    if (type === "listing") {
      to = `/user/advertising/dashboard/listings/${id}`;
      query.tab = "details";
    }
  }

  const params = new URLSearchParams(query);
  if (params.toString()) {
    to = `${to}?${params.toString()}`;
  }

  return to;
};

const Dashboard = ({
  marketPlace,
  user,
  modules,
  categoryId,
  categoryDetails,
  categories,
  channel,
  categoryData,
}) => {
  const [seasonalCategory, setSeasonalCategory] = useState(false);

  const [renderSave, setRenderSave] = useState(false);

  const notificationRef = useRef();
  const { preferences } = useMerchandisePreferences();

  const mobileStyle = useMediaQuery("(max-width:600px)");
  const smallViewportWidthTable = useMediaQuery("(max-width:1550px)");

  const { group, start, end, preStart, preEnd } = useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    channel: channel,
    category: categoryId,
    group_by: "ad_id",
    empty: true,
  };

  const { isSuccess, data, isError, isLoading, isFetching } = useFetch(
    [
      "category_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      group,
      categoryId,
    ],
    `/api/data_report/category_report`,
    { ...filters, group_by: "category_id" },
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data: categorySummaryData, isLoading: isAdDataLoading } = useFetch(
    [
      "ad_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      group,
      categoryId,
    ],
    `/api/data_report/ad_report`,
    { ...filters },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      default: [],
    }
  );

  const { data: annotationData, isLoading: isAnnotationDataLoading } = useFetch(
    ["annotations", start, end, channel, categoryId, marketPlace.marketPlace],
    "/api/annotations/",
    {
      groupby: group,
      ts_after: `${filters["start_date"]}T00:00:00`,
      ts_before: `${filters["end_date"]}T23:59:59`,
      category: categoryId,
      marketplace: marketPlace.marketPlace,
      channel: channel === "amazon" ? "AMZ" : channel,
    },
    // ts_before and ts_after need values from categories to contain start and end date within query string
    {
      keepPreviousData: true,
      // Below is to avoid refetch if user switches tabs/windows while in the middle of bulk editing
      refetchOnWindowFocus: false,
      enabled: !!categoryId,
    }
  );

  useFetch(
    ["seasonality", categoryId],
    `/api/seasonality`,
    { category: parseInt(categoryId) },
    {
      select: (res) => res.data?.results ?? [],
      enabled: !!categoryId,
      onSuccess: (res) => {
        try {
          if (
            res?.[0]?.weekly_seasons &&
            JSON.parse(res?.[0]?.weekly_seasons)?.some(
              (day) => day.multiplier !== 1
            ) &&
            !seasonalCategory
          ) {
            setSeasonalCategory(true);
          }
        } catch (error) {
          console.log("Error parsing weekday parting data at category level.");
        }
      },
    }
  );

  const { overviewMetrics } = useMemo(() => {
    const acosOrRoas = user?.preferences?.acos_or_roas ?? "acos";
    const roasMetrics = [
      buildMetric(marketPlace, data, "roas", "roas_delta"),
      buildMetric(marketPlace, data, "troas", "troas_delta"),
    ];
    const acosMetrics = [
      buildMetric(marketPlace, data, "acos", "acos_delta"),
      buildMetric(marketPlace, data, "total_acos", "total_acos_delta"),
    ];
    return {
      overviewMetrics: {
        metrics: [
          buildMetric(marketPlace, data, "sales", "sales_percent"),
          buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
          buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
          ...(acosOrRoas === "acos" ? acosMetrics : roasMetrics),
          ...(acosOrRoas === "acos" ? roasMetrics : acosMetrics),
          buildMetric(marketPlace, data, "clicks", "clicks_percent"),
          buildMetric(marketPlace, data, "impressions", "impressions_percent"),
          buildMetric(marketPlace, data, "orders", "orders_percent"),
          buildMetric(marketPlace, data, "cpc", "cpc_delta"),
          buildMetric(
            marketPlace,
            data,
            "conversion_rate",
            "conversion_rate_delta"
          ),
          buildMetric(marketPlace, data, "ctr", "ctr_delta"),
        ],
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, categorySummaryData, user]);

  const breadCrumbItems = [
    {
      name: "Advertising Dashboard",
      href: getDashboardTo({
        type: "category",
        id: null,
      }),
      dropdownLinkOptions: DASHBOARD_LINKS,
      searchable: false,
      unsorted: true,
      dropdownSubtitle: "Dashboards",
    },
  ];

  if (categoryId) {
    breadCrumbItems.push({
      name: categoryDetails?.leaf_category_name,
      href: categoryDetails.href,
      dropdownLinkOptions: categoryData,
      searchable: true,
      searchPlaceholderText: "Search categories...",
      dropdownSubtitle: "Categories",
    });
  }

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  const adPlans = useMemo(() => {
    return (
      categorySummaryData?.adplans.map((adplan) => ({
        ...adplan,
        category: categoryId,
        id: adplan.ad_id,
      })) ?? []
    );
  }, [categorySummaryData, categoryId]);

  return (
    <>
      <NotificationSystem ref={notificationRef} />

      <Grid
        fluid
        style={{
          background: "#fefefe",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        <Row>
          <Col xs={12} className="mb-4">
            <BreadcrumbDates items={breadCrumbItems} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {isLoading && <Loading />}
            {isError && <p>Error Loading Dashboard</p>}
            {isSuccess && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col xs={12}>
                    <MetricsCard
                      id={"advertingOverview"}
                      title={categoryDetails?.leaf_category_name}
                      mobileStyle={mobileStyle}
                      data={data?.date}
                      isLoading={isFetching}
                      metrics={overviewMetrics.metrics}
                      showMetricsSelector
                      initialSelectedMetric={["ad_sales", "ad_spend", "roas"]}
                      group={group}
                      showAnnotations={true}
                      selectedCategory={categoryId}
                      byline={
                        <>
                          {start.format("MMMM Do YYYY")}
                          {" - "}
                          {end.format("MMMM Do YYYY")}
                          {isLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                        </>
                      }
                    >
                      <Row>
                        <Col xs={12} lg={6} className="text-start d-flex">
                          <div className="px-5">
                            <DetailedMetric
                              center
                              size={"small"}
                              id={"budget"}
                              name={"Monthly Budget"}
                              value={categorySummaryData?.adplans?.reduce(
                                (acc, adplan) => {
                                  if (
                                    ([STATUS.ACTIVE.value].includes(
                                      adplan.state
                                    ) ||
                                      adplan.ad_status ===
                                        MODERATION_STATUSES.PENDING.value) &&
                                    adplan.ad_status !==
                                      AD_CREATION_FAILED_STATUS
                                  ) {
                                    return acc + adplan.monthly_budget;
                                  }
                                  return acc;
                                },
                                0
                              )}
                              formatter={(v) =>
                                formatCurrency(v, marketPlace.marketPlace, true)
                              }
                              showTarget={false}
                            />
                          </div>
                          <div className="px-5">
                            <DetailedMetric
                              center
                              size={"small"}
                              id={"budget"}
                              name={"Daily Budget"}
                              value={categorySummaryData?.adplans?.reduce(
                                (acc, adplan) => {
                                  if (
                                    ([STATUS.ACTIVE.value].includes(
                                      adplan.state
                                    ) ||
                                      adplan.ad_status ===
                                        MODERATION_STATUSES.PENDING.value) &&
                                    adplan.ad_status !==
                                      AD_CREATION_FAILED_STATUS
                                  ) {
                                    return acc + adplan.daily_budget;
                                  }
                                  return acc;
                                },
                                0
                              )}
                              formatter={(v) =>
                                formatCurrency(v, marketPlace.marketPlace, true)
                              }
                              showTarget={false}
                            />
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          lg={6}
                          className="d-flex justify-content-end align-items center mx-auto"
                        >
                          <p className="d-flex justify-content-end align-items center my-auto">
                            <AdPlanModal
                              dashboard={false}
                              categories={categoryData}
                              categoryId={categoryId}
                            />
                            <AdPlanBulkCreate
                              categoryId={categoryId}
                              marketPlace={marketPlace}
                              channel={channel}
                            />
                          </p>
                        </Col>
                      </Row>
                      {checkModule(modules, "MEDIA_PLAN_INSIGHTS") ? (
                        <div className="pb-1">
                          <Accordion title={"Insights"}>
                            <Row>
                              <Col xs={12}>
                                <InsightsTable
                                  marketPlace={marketPlace.marketPlace}
                                  categoryId={categoryId}
                                  channel={channel}
                                  modules={modules}
                                />
                              </Col>
                            </Row>
                          </Accordion>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Col xs={12}>
                        <Accordion title={"Ad Types"}>
                          <Row>
                            <Col xs={12}>
                              <AdTypePieCharts />
                            </Col>
                          </Row>
                        </Accordion>
                      </Col>

                      <Col xs={12}>
                        <Accordion
                          title={"Advertisements"}
                          initialExpanded={true}
                        >
                          <Row>
                            <Col xs={12}>
                              <AdvertisementsTable
                                type={"advertisement"}
                                states={[]}
                                stats={adPlans}
                                marketPlace={marketPlace}
                                channel={channel}
                                mobileStyle={mobileStyle}
                                columnSpecs={overviewMetrics.columns}
                                category={categoryId}
                                user={user}
                                isLoading={isAdDataLoading}
                                setRenderSave={setRenderSave}
                                notificationRef={notificationRef}
                                modules={modules}
                                preferences={preferences}
                                renderSave={renderSave}
                                smallViewportWidthTable={
                                  smallViewportWidthTable
                                }
                                categoryDetails={{
                                  ...categoryDetails,
                                  seasonalCategory: seasonalCategory,
                                }}
                                categories={categories}
                              />
                            </Col>

                            <Col xs={12} className="text-right">
                              <DownloadReport
                                url_path={"ad_report"}
                                filters={{ ...filters, empty: false }}
                                report_dl={"ads"}
                              />
                            </Col>
                          </Row>
                        </Accordion>
                        <Accordion title={"Products"}>
                          <CategoryProducts
                            categoryId={categoryId}
                            marketPlace={marketPlace}
                            user={user}
                            smallViewportWidthTable={smallViewportWidthTable}
                            channel={channel}
                          />
                        </Accordion>

                        <Accordion title={"Impact Analysis"}>
                          <Row>
                            <Col xs={12}>
                              <AnnotationsTable
                                data={annotationData?.data ?? []}
                                isLoading={isAnnotationDataLoading}
                                marketPlace={marketPlace.marketPlace}
                                annotationType={"advertisement"}
                              />
                            </Col>
                          </Row>
                        </Accordion>
                      </Col>
                    </MetricsCard>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
};

const AdvertisingOverview = ({
  user,
  marketPlace,
  modules,
  channel,
  onUpdate,
}) => {
  const { id: categoryId } = useParams();
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  let { currentCategoryDetails: categoryDetails, categories: categoryData } =
    useCategories(categoryId);

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        categoryId={categoryId}
        categoryDetails={categoryDetails}
        categoryData={categoryData}
        modules={modules}
        channel={channel}
        onUpdate={onUpdate}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
  modules: selectModules(state),
  channel: state.channel.currentChannel,
});

export default connect(mapStateToProps)(withAdPlan(AdvertisingOverview));
