import React, { useState } from "react";
import { Container as Grid, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Card from "components/core/basic/Card.jsx";
import { AuthButton } from "components/core/basic/Button";
import api from "utils/api";
import { useMutation, useQueryClient } from "react-query";
import { useFetch } from "hooks/api";
import FormGroupTile from "components/core/basic/FormGroupTile";
import Checkbox from "components/core/basic/CheckBox";
import useDefaultState from "hooks/useDefaultState";

const PRODUCT_STATES = [
  ["LOW_STOCK", "Low Stock"],
  ["IN_STOCK", "In Stock"],
  ["OUT_OF_STOCK", "Out of Stock"],
  ["OVERSTOCK", "Over Stock"],
  ["BEST_SELLER", "Best Seller"],
  ["CLEARANCE", "Clearance"],
  ["NORMAL", "Growth"],
  ["NEW_PRODUCT", "New Product"],
];

const Settings = ({ marketPlace }) => {
  const settings = useFetch(["merchandise-settings"], `/merchandise/settings/`);
  const orgs = useFetch(["organizations"], "/api/organizations/");
  const [changes, setChanges] = useState({});
  const [orgChanges, setOrgChanges] = useDefaultState({});
  const queryClient = useQueryClient();
  const updateSettings = useMutation(
    async ({ id, changes }) => {
      let res = await api.patch(`/merchandise/settings/${id}/`, changes);
      return res;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["merchandise-settings"]);
        setChanges({});
        setOrgChanges({});
      },
    }
  );
  return (
    <Grid fluid style={{ padding: "3rem", minHeight: "calc(100vh - 150px)" }}>
      <Row>
        <Col xs={12} className="text-center pb-5">
          <div
            style={{
              fontSize: "26px",
              color: "#403E3D",
              fontWeight: "700",
            }}
          >
            Merchandising - Settings
          </div>
        </Col>
      </Row>
      <Row className="mx-auto">
        <Col xs={{ span: 10, offset: 1 }}>
          <Card additionalCardClasses="mx-auto">
            <div style={{ width: "100%", padding: "2rem" }}>
              {settings.isLoading && <p>Loading...</p>}
              {settings.isError && <p>Error Loading Settings</p>}
              {settings.isSuccess && (
                <>
                  <Row>
                    <Col xs={12}>
                      <h5>Enabled Channels</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {orgs.data?.data?.map((org) => (
                        <div className="pt-2" key={org.id}>
                          <Checkbox
                            number={`settings_org_id_${org.id}`}
                            label={`${org.org_type} ${org.profile_name} (${org.profile_type})`}
                            checked={
                              orgChanges?.[org.id] ??
                              (settings?.data?.data?.enabled_orgs.find(
                                (oid) => oid === org.id
                              ) ||
                                false)
                            }
                            disabled={false}
                            onClick={(e) => {
                              setOrgChanges({
                                ...orgChanges,
                                [org.id]: e.target.checked,
                              });
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    <Col xs={12}>
                      <h5>Minimum Margins %</h5>
                    </Col>
                  </Row>
                  <Row>
                    {PRODUCT_STATES.map(([key, label]) => (
                      <Col
                        key={key}
                        xs={6}
                        style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
                        className="py-3"
                      >
                        <FormGroupTile
                          type="formcontrol"
                          label={label}
                          formControlType={"text"}
                          stateName={key}
                          handleChildFormElement={(key, val) => {
                            if (val) {
                              val = parseFloat(val);
                            } else {
                              val = "";
                            }
                            setChanges({ ...changes, [key]: val });
                          }}
                          placeholder={""}
                          defaultValue={
                            changes?.[key] ??
                            settings?.data?.data?.product_states_margins?.[
                              key
                            ] ??
                            ""
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row style={{ marginTop: "3rem" }}>
                    <Col xs={6} className="text-end pr-4">
                      <AuthButton
                        className="logout_button"
                        title="Cancel"
                        disabled={
                          Object.keys(changes).length === 0 &&
                          Object.keys(orgChanges).length === 0
                        }
                        onClick={() => {
                          setChanges({});
                          setOrgChanges({});
                        }}
                      />
                    </Col>
                    <Col xs={6} className="text-start pl-4">
                      <AuthButton
                        className="logout_button"
                        title="Update"
                        onClick={() => {
                          const enabled_orgs = orgs?.data?.data
                            .filter((org) => {
                              return (
                                orgChanges?.[org.id] ??
                                (settings.data?.data?.enabled_orgs.find(
                                  (oid) => oid === org.id
                                ) ||
                                  false)
                              );
                            })
                            .map((o) => o.id);

                          updateSettings.mutate({
                            id: settings.data?.data?.id,
                            changes: {
                              enabled_orgs,
                              product_states_margins: {
                                ...settings.data?.data?.product_states_margins,
                                ...changes,
                              },
                            },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(Settings);
