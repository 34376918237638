import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core";

const useStyles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    fontSize: "27px",
    width: "47%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  inputLabel: {
    fontFamily: "Poppins",
    fontSize: "12px",
    dataShrink: true,
    marginLeft: "1rem",
    color: "rgb(200, 200, 200)",
    fontWeight: "700",
    "&:after": {
      fontSize: "12px",
    },
  },
  select: {
    fontSize: "20px",
    "&:before": {
      borderBottom: "3px solid #C8C8C8",
      paddingLeft: "3px",
    },
    "&:after": {
      borderBottom: "3px solid #C8C8C8",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderBottom: "3px solid #C8C8C8",
    },
  },
  optgroup: {
    fontSize: "20px",
  },
  optionLabel: {
    fontSize: "10px",
  },
});

class NavFilterTileMUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue:
        this.props.defaultValue === undefined
          ? Object.keys(this.props.data)[0]
          : this.props.defaultValue,
    };
  }

  componentDidUpdate() {
    if (
      this.props.defaultValue &&
      this.state.selectedValue !== this.props.defaultValue
    ) {
      this.setState({ selectedValue: this.props.defaultValue });
    }
  }

  onNavClick = (key, id) => {
    if (this.props.isRegionSelector) {
      localStorage.removeItem("EditAmzMwsRegion");
    }
    this.setState({ selectedValue: key });
    this.props.handleChildComponent(id);
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <FormControl key="form_control_mktplce" className={classes.formControl}>
          <InputLabel
            key="input_label_mktplce"
            className={classes.inputLabel}
            htmlFor="gruped-native-select"
          >
            {this.props.defaultValue &&
            !this.props.marketPlaceUpdated &&
            localStorage.marketPlaceSelected
              ? this.props.defaultValue
              : this.props.isRegionSelector
              ? localStorage.EditAmzMwsRegion
                ? this.props.defaultValue
                : "Seller Region"
              : "Marketplaces"}
          </InputLabel>
          <Select
            key="select_mktplce"
            className={classes.select}
            native
            disabled={
              this.props.isRegionSelector && localStorage.EditAmzMwsRegion
            }
            id="gruped-native-select"
            onChange={(e) => {
              let k = e.target.value.split(":")[0];
              let id = e.target.value.split(":")[1];
              this.onNavClick(k, id);
            }}
          >
            {this.props.data.map((value) => {
              return (
                <>
                  <optgroup
                    key={"optgrp_mktplce_" + Object.keys(value)[0]}
                    label={Object.keys(value)[0]}
                  >
                    {Object.values(value)[0].map((val) => {
                      let k = Object.keys(val)[0];
                      let id = Object.keys(val[k])[0];
                      return (
                        <option
                          key={"optgrp_mktplce" + k}
                          className={classes.optionLabel}
                          label={k}
                          value={k + ":" + id}
                          disabled={
                            Object.values(val[k])[0] || this.props.region === ""
                          }
                        ></option>
                      );
                    })}
                  </optgroup>
                </>
              );
            })}
          </Select>
        </FormControl>
      </>
    );
  }
}

export default withStyles(useStyles)(NavFilterTileMUI);
