import { useDispatch } from "react-redux";
import { useFetch } from "./api";
import { useQueryClient } from "react-query";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";

const useOrgReduxState = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  useFetch(
    "orgOnboardingStatus",
    "/amzonboarding",
    {},
    {
      onSuccess: (res) => {
        localStorage.setItem("AmzOnboard", JSON.stringify(res.data));
        dispatch(updateAmzOnboardingStatus(res.data));
      },
      // Cache for five minutes unless invalidated on org change
      staleTime: 5 * 5 * 1000,
      refetchOnMount: false,
    }
  );

  // This is an alternate to refreshAmzOnboardingStatus that's used hundreds of times throughout the app
  // It's a bit less verbose and safer to use since it won't overwrite Amazon Advertising credentials if accidentally passed in
  const updateOrgReduxState = () => {
    queryClient.invalidateQueries("orgOnboardingStatus");
  };

  return { updateOrgReduxState };
};

export default useOrgReduxState;
