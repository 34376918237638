import React, { useState, useEffect, useRef } from "react";
import { withStyles, Grid } from "@material-ui/core";
import NotificationItem from "components/custom/notifications/NotificationItem";
import { Link } from "react-router-dom";
import "../assets/css/mobile_nav.css";
import SearchFilter from "components/custom/notifications/SearchFilter";
import { AuthButton } from "components/core/basic/Button";
import { useNotifications } from "../../src/notifications/useNotifications";
import { useFetch } from "hooks/api";
import InfiniteScrollWrapper from "./InfiniteScrollWrapper";
import { trellisPalette } from "components/custom/analytics/palettes";
import theme from "assets/themes/TrellisTheme";
import { notificationTypes } from "notifications/notificationConstants";

const useStyles = (theme) => ({
  triangle: {
    position: "absolute",
    top: "70px",
    right: "249px",
    bottom: "-10px",
    width: 0,
    height: 0,
    borderLeft: "35px solid transparent",
    borderRight: "35px solid transparent",
    borderBottom: "35px solid #DDD",
    ["@media (max-width:989px)"]: {
      right: "87px",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      right: 0,
      left: 0,
      borderLeft: "20px solid transparent",
      borderRight: "20px solid transparent",
      borderBottom: "20px solid #FFF",
      transform: "translate(-20px, 2px)",
      zIndex: 9999999,
      ["@media (max-width:650px)"]: {
        zIndex: 0,
      },
    },
  },
  dropdownContainer: {
    position: "absolute",
    right: "10px",
    top: "90px",
    border: "1px solid #CCC",
    borderRadius: "2rem",
    padding: theme.spacing(2, 0, 4),
    background: "#FFF",
    minHeight: "320px",
    width: "400px",
    height: "calc(90vh - 80px)",
    overflowX: "clip",
    zIndex: 2000,
    [theme.breakpoints.down("")]: {
      top: "88px",
    },
  },
  mobileDropdownContainer: {
    width: "96vw",
    right: "2px",
    left: "2px",
    top: 0,
    height: "100vh",
  },
  dropdownControls: {
    padding: theme.spacing(2, 4),
    borderBottom: "1px solid #EFEFEF",
  },
  dropdownBody: {
    overflowY: "auto",
    maxHeight: "calc(90vh - 380px)",
    padding: theme.spacing(0, 4, 2),
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(90vh - 360px)",
      // Below styles will remove scrollbar from dropdown body on mobile
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-webkit-scrollbar-track": {
        display: "none",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#FFF",
      },
    },
  },
  mobileDropdownBody: {
    maxHeight: "calc(100vh - 330px)",
  },
  dropdownFooter: {
    borderTop: "1px solid #EFEFEF",
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
    },
  },
  mobileDropdownFooter: {
    paddingTop: theme.spacing(2),
  },
  header: {
    fontSize: "2rem",
    color: "#403E3D",
    fontWeight: "700",
    margin: theme.spacing(2, "auto"),
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    border: "none",
    background: "inherit",
  },
  mobileCloseButton: {
    right: theme.spacing(4),
  },
  noNotificationsMessage: {
    textAlign: "center",
    padding: theme.spacing(3, 2),
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "0.35rem",
      maxHeight: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: trellisPalette[3],
    },
  },
});

const NotificationDropdown = ({
  setShowNotifications,
  classes,
  marketPlace,
  currentCategory,
  mobileNotifications,
}) => {
  const [selectedNotificationType, setSelectedNotificationType] =
    useState("all");
  const [selectedCategory, setSelectedCategory] = useState(currentCategory);
  const [notificationData] = useNotifications(
    selectedCategory ?? currentCategory
  );

  // Detect blur event that will close dropdown when user clicks away
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleDropdownBlur);
    return () => {
      document.removeEventListener("click", handleDropdownBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdownBlur = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      // Below is needed to avoid blur being triggered when select changes, seems to be issues detecting react-select components as children of container
      e.target.id.indexOf("react-select") === -1 &&
      ![...e.target?.classList]?.includes("MuiButton-label") &&
      ![...e.target?.classList]?.includes("notification-button")
    ) {
      setShowNotifications(false);
    }
  };

  // Clear selected category when new category selected in dashboard
  useEffect(() => {
    setSelectedCategory(null);
  }, [currentCategory]);

  const channel = "amazon";
  const marketplace = marketPlace;

  const { data: categoryData } = useFetch(
    ["categories", "marketplace", "channel"],
    "/api/gvads/categories",
    {
      marketplace,
      channel,
    }
  );

  return (
    <div ref={dropdownRef}>
      <div className={classes.triangle} />
      <div
        className={`${mobileNotifications && classes.mobileDropdownContainer} ${
          classes.dropdownContainer
        }`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setShowNotifications(false);
          }}
          className={`${classes.closeButton} ${
            mobileNotifications && classes.mobileCloseButton
          }`}
        >
          <i
            className={`fa fa-close ${mobileNotifications ? "fa-lg" : "fa-sm"}`}
          />
        </button>
        <h3 className={classes.header}>Notifications</h3>
        <Grid className={classes.dropdownControls}>
          <Grid style={{ marginBottom: theme.spacing(2) }}>
            <SearchFilter
              filterType="categories"
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              categoryData={categoryData?.data ?? []}
              dropdownCategoryFilter={true}
            />
          </Grid>

          {notificationData?.length > 0 && (
            <Grid style={{ marginBottom: theme.spacing(2) }}>
              <SearchFilter
                filterType="notificationType"
                selectedNotificationType={selectedNotificationType}
                setSelectedNotificationType={setSelectedNotificationType}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          className={`${mobileNotifications && classes.mobileDropdownBody} ${
            classes.dropdownBody
          } ${classes.scrollBar}`}
        >
          <InfiniteScrollWrapper
            Component={(props) => (
              <NotificationItem dropdownLayout={true} {...props} />
            )}
            data={
              selectedNotificationType === "all"
                ? notificationData
                    ?.filter((n) => !n.dismissed_on && !n.clicked_on)
                    ?.sort(
                      (a, b) => new Date(b.created_on) - new Date(a.created_on)
                    )
                : notificationData
                    .filter(
                      (n) =>
                        n.notification_type === selectedNotificationType &&
                        !n.dismissed_on &&
                        !n.clicked_on
                    )
                    .sort(
                      (a, b) => new Date(b.created_on) - new Date(a.created_on)
                    )
            }
            dropdownLayout={true}
          />
          {(notificationData?.every((n) => n.clicked_on || n.dismissed_on) ||
            notificationData?.length < 1) && (
            <p className={classes.noNotificationsMessage}>
              There are currently no active notifications
              {selectedCategory !== "all" && " for the selected category"}.
            </p>
          )}
          {selectedNotificationType !== "all" &&
            notificationData.filter(
              (n) =>
                n.notification_type === selectedNotificationType &&
                !n.dismissed_on &&
                !n.clicked_on
            )?.length < 1 && (
              <p className={classes.noNotificationsMessage}>
                There are currently no active{" "}
                {notificationTypes
                  .find((t) => t.value === selectedNotificationType)
                  ?.label?.toLowerCase()}{" "}
                notifications{" "}
                {selectedCategory !== "all" && "for the selected category"}.
              </p>
            )}
        </Grid>
        <Grid
          className={`${mobileNotifications && classes.mobileDropdownFooter} ${
            classes.dropdownFooter
          }`}
        >
          <Link to="/user/notifications">
            <AuthButton
              className="wrap buttonActive"
              style={{ transform: "none" }}
              onClick={() => {
                setShowNotifications(false);
              }}
              title={"View All"}
            />
          </Link>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(useStyles)(NotificationDropdown);
