import React from "react";
import { Row, Col } from "react-bootstrap";
import DialogueCard from "./DialogueCard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import api from "utils/api";
import { setCurrentUser } from "redux/actions/authActions";
import { trackMixpanelEvent } from "hooks/useMixpanelTracking";

const USER_TYPE_OPTIONS = [
  {
    value: "seller",
    title: "Seller",
    description: "I sell on Amazon Marketplace",
  },
  {
    value: "brand",
    title: "Brand",
    description: "I sell on Amazon, Walmart, and other ecommerce platforms",
  },
  {
    value: "agency",
    title: "Agency",
    description: "I work with multiple sellers and brands",
  },
  {
    value: "aggregator",
    title: "Aggregator",
    description: "I acquire brands who sell on Amazon",
  },
  {
    value: "consultant",
    title: "Consultant",
    description: "I work as a consultant to support multiple brands on Amazon",
  },
  {
    value: "other",
    title: "",
    description: "I haven't started selling on Amazon",
  },
];

const USER_MONTHLY_SALES_OPTIONS = [
  {
    value: "0-50000",
    title: "Less than $50k",
    description: null,
  },
  {
    value: "50000-250000",
    title: "$50K - $250K",
    description: null,
  },
  {
    value: "250000-1000000",
    title: "$250K - $1M",
    description: null,
  },
  {
    value: "1000000-25000000",
    title: "$1M - $25M",
    description: null,
  },
  {
    value: "25000000+",
    title: "$25M or more",
    description: null,
  },
];

const AboutNewUser = ({ dialogue, onboardingData, setOnboardingData }) => {
  const history = useHistory();
  const { user, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  const userFirstName = user.user_full_name.split(" ")?.[0];

  const prompt =
    dialogue === "customer_type"
      ? "How would you identify your business?"
      : "Typically, what are your monthly sales?";

  const dialogueOptions =
    dialogue === "customer_type"
      ? USER_TYPE_OPTIONS
      : USER_MONTHLY_SALES_OPTIONS;

  const updateOnboardData = useMutation({
    mutationFn: async (updateData) => {
      return await api.patch("/api/onboard_data/", updateData);
    },
    onSuccess: async (res, variables) => {
      // Keep redux user state up to date with fields updated in backend
      dispatch(setCurrentUser({ ...user, ...variables }));
      history.push(
        dialogue === "customer_type"
          ? "/onboarding/v2/about/sales"
          : "/onboarding/v2/channel"
      );
    },
    onError: (error) => {
      // If user loses tokens, send back to sign in page
      if (error.response.status === 401) {
        history.push("/");
      }
    },
  });

  // Takes monthly sales estimate and multiplies ranges or estimated min monthly sales by 12 months
  const getAnnualSalesEstimate = (value) => {
    if (value.includes("-")) {
      return value
        .split("-")
        .map((n) => parseInt(n) * 12)
        ?.join("-");
    }

    if (value.includes("+")) {
      return (value.split("+")[0] * 12).toString() + "+";
    }

    return "N/A";
  };

  if (!auth?.isAuthenticated) {
    history.push("/");
  }

  return (
    <Row className="my-5 w-100">
      <Col xs={12} lg={{ offset: 2, span: 8 }} className="text-start">
        <h2 className="mt-5 mb-1 fw-600">Welcome {userFirstName}!</h2>
        <p className="fs-4">
          We have a few questions to ensure that you get the most out of Trellis
        </p>
        <h4 className="fw-500">{prompt}</h4>
      </Col>
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        {dialogueOptions.map((option) => (
          <DialogueCard
            key={option.value}
            contents={option}
            justify={dialogue === "customer_type" ? "start" : "center"}
            onClick={(value) => {
              const updateData = { [dialogue]: value };

              if (dialogue === "total_monthly_sales") {
                updateData["total_monthly_sales_currency"] = "USD";
                updateData["annual_amazon_sales"] =
                  getAnnualSalesEstimate(value);
              }

              trackMixpanelEvent("Click", {
                name: option.title,
                element: "card",
                prompt,
                ...updateData,
              });

              setOnboardingData({ ...onboardingData, ...updateData });

              updateOnboardData.mutate(updateData);
            }}
          />
        ))}
      </Col>
    </Row>
  );
};

export default AboutNewUser;
