import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import { metricColors } from "components/custom/analytics/palettes";

const IMPRESSIONS_AXIS = 0;
const CLICK_AXIS = 1;
const UNITS_AXIS = 2;
const SALES_AXIS = 4;
const PERCENT_AXIS = 5;
const ROI_AXIS = 6;
const BSR_AXIS = 7;
const PRICE_AXIS = 8;
const RATINGS_AXIS = 9;
const RATINGS_TOTAL_AXIS = 10;
const SCORE_AXIS = 11;

export const metrics = [
  {
    id: "sales",
    name: "Sales",
    color: metricColors.sales[0],
    accessor: (d) => d?.total_sales,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total sales",
  },
  {
    id: "sales_percent",
    name: "Sales %",
    color: metricColors.sales[0],
    accessor: (d) => d?.total_sales_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "cost",
    name: "Cost",
    color: metricColors.cost[0],
    accessor: (d) => d?.total_cost,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total costs, including ad spend and discounts",
  },
  {
    id: "cost_percent",
    name: "Cost %",
    color: metricColors.cost[0],
    accessor: (d) => d?.total_cost_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "roms",
    name: "ROMS",
    color: metricColors.roms[0],
    accessor: (d) => d?.roms,
    format: "number",
    chartType: "line",
    axis: ROI_AXIS,
    toolTip:
      "The return on merchandising spend. The total sales divided by the combined ad spend and discounts from deals",
  },
  {
    id: "roms_delta",
    name: "ROMS %",
    color: metricColors.roms[0],
    accessor: (d) => d?.roms_delta,
    format: "number",
    incremental: true,
    chartType: "line",
    axis: ROI_AXIS,
  },
  {
    id: "units",
    name: "Units",
    color: metricColors.units[0],
    accessor: (d) => d?.total_units,
    format: "number",
    axis: UNITS_AXIS,
    chartType: "column",
    toolTip: "The total units sold",
  },
  {
    id: "units_percent",
    name: "Units %",
    color: metricColors.units[0],
    accessor: (d) => d?.total_units_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "margin",
    name: "Margin",
    color: metricColors.margin[0],
    accessor: (d) => d?.margin,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The percentage of profit after all costs",
  },
  {
    id: "margin_delta",
    name: "Margin %",
    color: metricColors.margin[0],
    accessor: (d) => d?.margin_delta,
    format: "percent",
    incremental: true,
    chartType: "line",
    axis: PERCENT_AXIS,
  },
  {
    id: "ad_sales",
    name: "Ad Sales",
    color: metricColors.sales[4],
    accessor: (d) => d?.ad_sales,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total sales from ads",
  },
  {
    id: "ad_sales_percent",
    name: "Ad Sales %",
    color: metricColors.sales[4],
    accessor: (d) => d?.ad_sales_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "ad_spend",
    name: "Ad Spend",
    color: metricColors.cost[1],
    accessor: (d) => d?.cost,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total ad spend",
  },
  {
    id: "ad_spend_percent",
    name: "Ad Spend %",
    color: metricColors.cost[1],
    accessor: (d) => d?.cost_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "clicks",
    name: "Clicks",
    color: metricColors.metric1[0],
    accessor: (d) => d?.clicks,
    chartType: "column",
    format: "number",
    axis: CLICK_AXIS,
    toolTip: "The total clicks on ads",
  },
  {
    id: "clicks_percent",
    name: "Clicks %",
    color: metricColors.metric1[0],
    accessor: (d) => d?.clicks_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "troas",
    name: "TROAS",
    color: metricColors.roms[3],
    accessor: (d) => d?.total_roas,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    toolTip: "The return on ad spend against total sales",
  },
  {
    id: "troas_delta",
    name: "TROAS Change",
    color: metricColors.roms[3],
    accessor: (d) => d?.total_roas_delta,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    incremental: true,
  },
  {
    id: "roas",
    name: "ROAS",
    color: metricColors.roms[1],
    accessor: (d) => d?.roas,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    toolTip: "The return on ad spend",
  },
  {
    id: "roas_delta",
    name: "ROAS Change",
    color: metricColors.roms[1],
    accessor: (d) => d?.roas_delta,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    incremental: true,
  },
  {
    id: "total_acos",
    name: "Total ACOS",
    color: metricColors.acos[1],
    accessor: (d) => d?.total_acos,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The return on ad spend",
    lower: true,
  },
  {
    id: "total_acos_delta",
    name: "Total ACOS Change",
    color: metricColors.acos[1],
    accessor: (d) => d?.total_acos_delta,
    format: "percent",
    decimalPlaces: 1,
    chartType: "line",
    axis: PERCENT_AXIS,
    incremental: true,
    lower: true,
  },
  {
    id: "acos",
    name: "ACOS",
    color: metricColors.acos[3],
    accessor: (d) => d?.acos,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The return on ad spend",
    lower: true,
  },
  {
    id: "acos_delta",
    name: "ACOS Change",
    color: metricColors.acos[3],
    accessor: (d) => d?.acos_delta,
    format: "percent",
    decimalPlaces: 1,
    chartType: "line",
    axis: PERCENT_AXIS,
    incremental: true,
    lower: true,
  },
  {
    id: "profit",
    name: "Profit",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total profit after all costs",
  },
  {
    id: "profit_percent",
    name: "Profit %",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "price",
    name: "Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.product_price,
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    toolTip: "The price of the product",
  },
  {
    id: "price_delta",
    name: "Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.product_price_delta,
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    incremental: true,
  },
  {
    id: "price_percent",
    name: "Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.product_price_percent,
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    incremental: true,
  },
  {
    id: "bsr",
    name: "Best Seller Rank",
    toolTip: "Sales rank, the lower is better",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank,
    totalOverrideAccessor: (d) => d?.last_bsr_rank,
    format: "number",
    chartType: "line",
    axis: BSR_AXIS,
  },
  {
    id: "bsr_percent",
    name: "Best Seller Rank %",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "promo_sales",
    toolTip: "Total Sales from Deals",
    name: "Deals Sales",
    color: metricColors.sales[2],
    accessor: (d) => d?.promo_sales,
    format: "currency",
    axis: SALES_AXIS,
  },
  {
    id: "promo_sales_percent",
    toolTip: "Total Sales from Deals percentage",
    name: "Deals Sales %",
    color: metricColors.sales[2],
    accessor: (d) => d?.promo_sales_percent,
    axis: PERCENT_AXIS,
    format: "percent",
    chartType: "line",
    incremental: true,
  },
  {
    id: "promo_units",
    toolTip: "Total Units sold from Deals",
    name: "Deals Units",
    color: metricColors.units[1],
    accessor: (d) => d?.promo_units,
    format: "number",
    axis: UNITS_AXIS,
  },
  {
    id: "promo_units_percent",
    toolTip: "Total Units sold from Deals percentage",
    name: "Deals Units %",
    color: metricColors.units[1],
    accessor: (d) => d?.promo_units_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    chartType: "line",
    incremental: true,
  },
  {
    id: "rods",
    toolTip: "Return on deal spend",
    name: "RODS",
    color: metricColors.roms[2],
    accessor: (d) => d?.rods,
    axis: ROI_AXIS,
    format: "number",
    chartType: "line",
  },
  {
    id: "rods_delta",
    toolTip: "Change in Return on deal spend",
    name: "RODS Change",
    color: metricColors.roms[2],
    accessor: (d) => d?.rods_delta,
    format: "number",
    axis: ROI_AXIS,
    incremental: true,
  },
  {
    id: "promo_conversion_rate",
    toolTip: "The percentage of glance views that results in a deal sale",
    name: "Deals Conv. Rate",
    color: metricColors.metric1[2],
    accessor: (d) => d?.conv,
    axis: PERCENT_AXIS,
    format: "percent",
    chartType: "line",
  },
  {
    id: "promo_conversion_rate_delta",
    toolTip: "",
    name: "Deals Conv. Rate Change",
    color: metricColors.metric1[2],
    accessor: (d) => d?.conv_delta,
    axis: PERCENT_AXIS,
    format: "percent",
    chartType: "line",
  },
  {
    id: "promo_cost",
    toolTip: "Total amount of discounts applied",
    name: "Total Discounts",
    color: metricColors.cost[2],
    accessor: (d) => d?.promo_cost,
    format: "currency",
    axis: SALES_AXIS,
  },
  {
    id: "promo_cost_percent",
    name: "Total Discounts %",
    color: metricColors.cost[2],
    accessor: (d) => d?.promo_cost_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "bsr",
    name: "Best Seller Rank",
    toolTip: "Sales rank, the lower is better",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank,
    totalOverrideAccessor: (d) => d?.last_bsr_rank,
    format: "number",
    chartType: "line",
    axis: BSR_AXIS,
  },
  {
    id: "last_bsr_rank",
    name: "Last Best Seller Rank",
    toolTip: "The most recent sales rank, the lower is better",
    color: metricColors.metric3[0],
    accessor: (d) => d?.last_bsr_rank,
    format: "number",
    chartType: "line",
    axis: BSR_AXIS,
  },
  {
    id: "bsr_percent",
    name: "Best Seller Rank %",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "rating",
    name: "Rating",
    toolTip: "Average rating across all enrolled products",
    color: metricColors.metric4[0],
    accessor: (d) => d?.rating,
    totalOverrideAccessor: (d) => d?.last_rating,
    format: "number",
    chartType: "line",
    axis: RATINGS_AXIS,
  },
  {
    id: "last_rating",
    name: "Last Rating",
    toolTip: "The most recent average rating across all enrolled products",
    color: metricColors.metric4[0],
    accessor: (d) => d?.last_rating,
    format: "number",
    chartType: "line",
    axis: RATINGS_AXIS,
  },
  {
    id: "rating_percent",
    name: "Ratings %",
    color: metricColors.metric4[0],
    accessor: (d) => d?.last_rating_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "ratings_total",
    name: "# of Ratings",
    toolTip: "Number of ratings across all enrolled products",
    color: metricColors.metric4[0],
    accessor: (d) => d?.ratings_total,
    totalOverrideAccessor: (d) => d?.last_ratings_total,
    format: "number",
    chartType: "line",
    axis: RATINGS_TOTAL_AXIS,
  },
  {
    id: "last_ratings_total",
    name: "Last # of Ratings",
    toolTip: "The most recent number of ratings across all enrolled products",
    color: metricColors.metric4[0],
    accessor: (d) => d?.last_ratings_total,
    format: "number",
    chartType: "line",
    axis: RATINGS_TOTAL_AXIS,
  },
  {
    id: "ratings_total_percent",
    name: "# of Ratings %",
    color: metricColors.metric4[0],
    accessor: (d) => d?.ratings_total_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "seo_score",
    name: "Searchability Score",
    color: metricColors.sales[4],
    accessor: (d) => d?.seo_score_avg,
    format: "number",
    axis: SCORE_AXIS,
    decimalPlaces: 0,
    toolTip:
      "This score is a reflection of how your product will rank organically on Amazon search results and the chance of getting clicks. We take into account the title, image, price, rating and more. This score is out of 100.",
  },
  {
    id: "seo_score_percent",
    name: "Searchability %",
    color: metricColors.sales[4],
    accessor: (d) => d?.seo_score_avg_percent,
    format: "percent",
    incremental: true,
    chartType: "line",
    axis: PERCENT_AXIS,
  },
  {
    id: "buyability_score",
    name: "Buyability Score",
    color: metricColors.metric1[4],
    decimalPlaces: 0,
    accessor: (d) => d?.buyability_score_avg,
    format: "number",
    axis: SCORE_AXIS,
    toolTip:
      "This determines how likely your product is to convert when a customer is viewing your listing. We take into account the quality of your bullets points, images, ratings, reviews, and more. This score is out of 100.",
  },
  {
    id: "buyability_score_percent",
    name: "Buyability Score %",
    color: metricColors.metric1[4],
    accessor: (d) => d?.buyability_score_avg_percent,
    format: "percent",
    incremental: true,
    chartType: "line",
    axis: PERCENT_AXIS,
  },
  {
    id: "conversion_rate",
    name: "Conv. Rate",
    color: metricColors.metric2[4],
    accessor: (d) => d?.cr_avg,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The percentage of page views that result in a sale.",
    decimalPlaces: 2,
  },
  {
    id: "conversion_rate_delta",
    name: "Conv. Rate Change",
    color: metricColors.metric2[4],
    accessor: (d) => d?.cr_avg_delta,
    format: "percent",
    chartType: "line",
    incremental: true,
    axis: PERCENT_AXIS,
    decimalPlaces: 1,
  },
  {
    id: "ctr",
    name: "CTR",
    color: metricColors.metric3[4],
    accessor: (d) => d?.ctr_avg,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The percentage of impressions that result in a click.",
    decimalPlaces: 2,
  },
  {
    id: "ctr_delta",
    name: "CTR Change",
    color: metricColors.metric3[4],
    accessor: (d) => d?.ctr_avg_delta,
    format: "percent",
    chartType: "line",
    incremental: true,
    axis: PERCENT_AXIS,
    decimalPlaces: 2,
  },
  {
    id: "impressions",
    name: "Impressions",
    color: metricColors.metric3[0],
    accessor: (d) => d?.impressions,
    format: "number",
    chartType: "column",
    axis: IMPRESSIONS_AXIS,
    toolTip: "Advertising impressions",
  },
  {
    id: "impressions_percent",
    name: "Impressions %",
    color: metricColors.metric3[0],
    accessor: (d) => d?.impressions_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
];

const getFormatter = (marketPlace, format, metric, options) => {
  const defaultFormatter = (v) => (v == null ? "-" : v);
  const decimalPlaces = metric?.decimalPlaces ?? 1;
  let formatter =
    {
      currency: (v) =>
        v == null
          ? "-"
          : formatCurrency(v, marketPlace.marketPlace, false, false, true),
      number: (v) => (v == null ? "-" : formatNumber(v, {}, decimalPlaces)),
      percent: (v) => (v == null ? "-" : formatPercent(v, decimalPlaces)),
    }?.[format] ?? defaultFormatter;

  if (options?.incremental ?? metric?.incremental) {
    return (v) => (v > 0 ? "+" : "") + formatter(v);
  }
  return formatter;
};

const getMetricSpec = (marketPlace, id, options) => {
  const metric = metrics.find((m) => m.id === id);
  const formatter = getFormatter(
    marketPlace,
    metric?.format ?? "number",
    metric,
    options
  );
  return {
    ...metric,
    formatter,
    ...options,
  };
};

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  return metric;
};

export default getMetricSpec;
