import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useGaTracking = () => {
  const { user } = useSelector((state) => state);
  const location = useLocation();

  useEffect(() => {
    if (window.ga) {
      if (user) {
        window.ga("set", "dimension13", user.is_staff);
        window.ga("set", "dimension14", user.user_id);
      }
      window.ga("set", "page", location.pathname + location.search);
      window.ga("send", "pageview");
    }
  }, [location.pathname, location.search, user]);
};

export default useGaTracking;
