import { useState, useMemo, useEffect } from "react";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import {
  DEFAULT_TABLE_FILTERS,
  FILTER_IDS_BLACKLIST,
  LIFECYCLE_FILTER_OPTIONS,
} from "views/merchandise/tables/filters";
import { getTargetBidTypeFilter } from "views/advertising/targetLifecycleFilters";
import useTableSettings from "./useTableSettings";
import moment from "moment";

const useCustomTableFilters = (marketPlace = "USA", tableId) => {
  const {
    savedTableSettings: savedTableFilters,
    updateTableSettings: setSavedTableFilters,
  } = useTableSettings(tableId, "tableFilters", []);

  let filterSettings = useMemo(() => {
    let filters = savedTableFilters ?? DEFAULT_TABLE_FILTERS?.[tableId] ?? [];

    return filters
      ?.filter((filterOption) => {
        return !FILTER_IDS_BLACKLIST.includes(filterOption.id);
      })
      ?.map((filterOption) => {
        if (filterOption.override) {
          const matchedLifecycleOption = [
            ...LIFECYCLE_FILTER_OPTIONS,
            getTargetBidTypeFilter(tableId),
          ].find((option) => option.id === filterOption.id);

          filterOption.filterFunction = matchedLifecycleOption.filterFunction;
          return filterOption;
        }
        return filterOption;
      });
  }, [tableId, savedTableFilters]);

  const [customFilters, setCustomFilters] = useState(filterSettings);

  useEffect(() => {
    const updatedTableFilters = customFilters?.filter(
      (filterOption) => !FILTER_IDS_BLACKLIST.includes(filterOption.id)
    );

    setSavedTableFilters(updatedTableFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFilters]);

  const formatFilterValue = (value, format, selectOptions = []) => {
    // If input can't be parsed into number format, return blank string to avoid NaNs from rendering
    if (["currency", "number", "percent"].includes(format)) {
      try {
        parseFloat(value);
      } catch {
        return "";
      }
    }

    switch (format) {
      case "select":
        return typeof value === "object"
          ? selectOptions
              ?.filter((o) => value?.includes(o.value))
              ?.map((selected) => selected.label)
          : selectOptions.find((option) => option.value === value)?.label;
      case "currency":
        return formatCurrency(value, marketPlace, false, false, true);
      case "number":
        return formatNumber(value);
      case "percent":
        return formatPercent(value / 100);
      case "text":
      default:
        return value;
    }
  };

  const compare = (
    rowValue,
    filterValue,
    operator,
    format = "number",
    options = {}
  ) => {
    // Toggle comparison options
    if (format === "toggle") {
      switch (operator) {
        case "on":
          return rowValue === options.onValue;
        case "off":
          return rowValue === options.offValue;
        case "all":
        default:
          return true;
      }
    }

    // Numeric comparison options
    let { percentAsDecimal, min, max } = options;

    if (["currency", "percent", "number"].includes(format)) {
      rowValue = parseFloat(rowValue);
      filterValue = parseFloat(filterValue);

      // Below is an option that will normalize percentages that are returned in different formats from the API
      // (i.e. 1 === 100%, vs 100 === 100%)
      if (percentAsDecimal) {
        filterValue /= 100;
        min /= 100;
        max /= 100;
      }

      switch (operator) {
        case "=":
          return rowValue === filterValue;
        case ">":
          return rowValue > filterValue;
        case "<":
          return rowValue < filterValue || !rowValue;
        case "≥":
          return rowValue >= filterValue;
        case "≤":
          return rowValue <= filterValue || !rowValue;
        // Below handles range thresholds
        case "-":
          return (
            parseFloat(rowValue) >= parseFloat(min) &&
            parseFloat(rowValue) <= parseFloat(max)
          );
        default:
          console.error("Invalid operation in custom filters.");
          return true;
      }
    }

    if (format === "date") {
      switch (operator) {
        case "-":
          return (
            moment(rowValue).isSameOrAfter(min, "d") &&
            moment(rowValue).isSameOrBefore(max, "d")
          );
        case "=":
          return moment(rowValue).isSame(filterValue, "d");
        case "<":
          return moment(rowValue).isBefore(filterValue, "d");
        case ">=":
          return moment(rowValue).isSameOrAfter(filterValue, "d");
        case ">":
          return moment(rowValue).isAfter(filterValue, "d");
        case "<=":
          return moment(rowValue).isSameOrBefore(filterValue, "d");
        default:
          return true;
      }
    }

    // Case-insensitive text comparison options (default/fallback)
    rowValue =
      typeof rowValue === "object"
        ? Object.values(rowValue)
            ?.map((v) => v)
            ?.join("")
            ?.toLowerCase()
        : rowValue?.toLowerCase();
    filterValue = filterValue?.toLowerCase();

    switch (operator) {
      case "=":
        return rowValue === filterValue;
      case "≠":
        return rowValue !== filterValue;
      case "^":
        return rowValue?.startsWith(filterValue);
      case "$":
        return rowValue?.endsWith(filterValue);
      case "⊃":
      default:
        return rowValue?.includes(filterValue);
    }
  };

  const applyCustomFilters = (data) => {
    // Purge incomplete filter options without needed values to match or missing operator
    const applicableFilters = customFilters?.filter((filter) => {
      return (
        filter.id &&
        filter.operator &&
        (filter.value ||
          (filter.min && filter.max) ||
          filter.format === "toggle" ||
          filter.override)
      );
    });

    if (!applicableFilters.length) {
      return data;
    }
    return data?.filter((row) => {
      for (
        let filterIndex = 0;
        filterIndex < applicableFilters.length;
        filterIndex++
      ) {
        const {
          id,
          value,
          operator,
          format,
          min,
          max,
          onValue,
          offValue,
          override,
          filterFunction,
          percentAsDecimal,
          overrideAccessorId,
        } = applicableFilters[filterIndex];

        const rowAccessor = overrideAccessorId ?? id;

        const sharedFiltersByColumnId = applicableFilters.filter(
          (f) => f.id === applicableFilters[filterIndex].id
        );

        // Handle overrides - use custom filter function to return any rows that match criteria
        if (override) {
          // Multi-select values and support for multiple column filter criteria (not currently supported in markup)
          if (
            sharedFiltersByColumnId?.length > 1 ||
            typeof value === "object"
          ) {
            const valuesList = sharedFiltersByColumnId.map((f) => f.value);

            let match = false;
            let matchValues = valuesList.flat();

            for (
              let matchValueIndex = 0;
              matchValueIndex < matchValues.length;
              matchValueIndex++
            ) {
              if (filterFunction(row, matchValues[matchValueIndex])) {
                match = true;
              }
            }
            if (!match) {
              return false;
            }
          } else {
            if (!filterFunction(row, value)) {
              return false;
            }
          }
        } else {
          if (
            !compare(row[rowAccessor], value, operator, format, {
              min,
              max,
              onValue,
              offValue,
              percentAsDecimal,
            })
          ) {
            return false;
          }
        }
      }
      return true;
    });
  };

  return {
    customFilters,
    setCustomFilters,
    formatFilterValue,
    applyCustomFilters,
  };
};

export default useCustomTableFilters;
