import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Modal, Nav } from "react-bootstrap";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { getURLPrefix } from "utils/getUrlPrefix";
import Loading from "components/core/blocks/Loading";
import { formatCurrency } from "utils/formatNumber";
import PricingCharts from "modules/pricingv2/Charts";
import CurveCharts from "modules/pricingv2/charts/CurveCharts";
import ListingQuality from "modules/product_quality/components/ListingQuality";

let URL_PREFIX = getURLPrefix();

const ListingDetails = ({
  productID,
  item,
  marketPlace,
  setShowContentDetails,
  showContentDetails,
}) => {
  const [contentDetails, setContentDetails] = useState(null);
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [optData, setOptData] = useState(null);
  const [sales, setSales] = useState(null);
  const [activeTab, setActiveTab] = useState("demand_history");
  const [priceData, setPriceData] = useState({
    current: null,
    min: null,
    max: null,
    optimal: null,
  });

  const fetchDetails = async () => {
    let price = 0.0;
    if (item.price) {
      if (item.price.value) {
        price = item.price.value;
      } else if (item.price) {
        price = item.price;
      }
    }

    let url = `${URL_PREFIX}/api/explorer/product?asin=${item.asin}&marketplace=${marketPlace}&price=${price}`;
    const { data } = await axios.get(url, {});

    if (data) {
      setContentDetails(data.content_details);
      setListing(data.listing);
      setSales(data.estimated_sales);
      setLoading(false);
    }
  };

  const fetchOrderHistory = async () => {
    let url = `${URL_PREFIX}/api/explorer/order_history?asin=${item.asin}&marketplace=${marketPlace}`;
    const { data } = await axios.get(url, {});

    if (data) {
      setOptData(data);
      getPricingData(data);
    }
  };

  useEffect(() => {
    if (item && marketPlace && showContentDetails) {
      fetchDetails();
      fetchOrderHistory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, productID, showContentDetails]);

  const hideModal = () => {
    setShowContentDetails(false);
    setContentDetails(null);
    setListing(null);
    setLoading(true);
    setOptData(null);
    setSales(0);
  };

  const getPricingData = (data) => {
    const priceData = {
      current: null,
      min: null,
      max: null,
      optimal: null,
    };

    if (data) {
      priceData.optimal = data.best_price;
      priceData.current = data.current_price;
      Object.keys(data.order_history).forEach((key, i) => {
        const item = data.order_history[key];
        if (priceData.min === null || item.price < priceData.min) {
          priceData.min = item.price;
        }
        if (priceData.max === null || item.price > priceData.max) {
          priceData.max = item.price;
        }
      });

      setPriceData(priceData);
    }
  };

  const getSpecValue = (value) => {
    if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    } else {
      return value;
    }
  };

  return (
    <Modal show={showContentDetails} onHide={() => hideModal()} size="xl">
      <Modal.Header closeButton className="py-0 px-5">
        {listing && (
          <>
            <Modal.Title
              id="contained-modal-title-lg"
              style={{
                whiteSpace: "break-spaces",
                maxWidth: "100%",
              }}
            >
              {listing.summaries[0].itemName}
            </Modal.Title>
          </>
        )}
      </Modal.Header>
      <Modal.Body>
        {loading && <Loading text={"Loading Product Details"} />}

        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          {listing && (
            <Row
              className="pt-1 pb-3 mb-5"
              style={{ fontSize: "14px", borderBottom: "1px solid #ccc" }}
            >
              <Col xs={4} className="text-center">
                <strong>ASIN</strong>
                <div>{item.asin}</div>
              </Col>
              <Col xs={4} className="text-center">
                <strong>30 Day Sales</strong>
                <div>{formatCurrency(sales, marketPlace)}</div>
              </Col>
              <Col xs={4} className="text-center">
                <strong>Rating</strong>
                <div>
                  {item.rating &&
                    [1, 2, 3, 4, 5].map((star, ir) => {
                      return (
                        <span
                          className="fa fa-star"
                          key={ir}
                          style={{
                            color:
                              star <= parseInt(item.rating) ? "orange" : "#ccc",
                          }}
                        ></span>
                      );
                    })}
                  {item.rating && (
                    <span style={{ marginLeft: "5px" }}>
                      (<strong>{item.rating}</strong>){" "}
                      <strong>{item.ratings_total}</strong>
                      &nbsp;ratings
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={3} className="text-center"></Col>
            </Row>
          )}
          {contentDetails && (
            <>
              <Row>
                <Col xs={8} className="pl-3 pr-3">
                  {!optData && (
                    <div
                      style={{
                        height: "270px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      Loading Pricing Chart
                    </div>
                  )}

                  {optData && (
                    <>
                      <Nav
                        variant="underline"
                        onSelect={(selectedKey) => {
                          setActiveTab(selectedKey);
                        }}
                        activeKey={activeTab}
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="demand_history"
                            className="fs-4"
                            style={{ color: "#000" }}
                          >
                            Demand History
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="price_elasticity"
                            className="fs-4"
                            style={{ color: "#000" }}
                          >
                            Price Elasticity
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      {activeTab === "demand_history" && (
                        <PricingCharts
                          optData={optData}
                          marketPlace={marketPlace}
                          height={270}
                          fromExplorer={true}
                        />
                      )}

                      {activeTab === "price_elasticity" && (
                        <>
                          {optData &&
                          optData.pricing_data &&
                          optData.pricing_data.length < 10 ? (
                            <Row>
                              <Col className="p-5 text-center fs-3">
                                There are not enough historical prices changes
                                to display the price elasticity.
                              </Col>
                            </Row>
                          ) : (
                            <CurveCharts
                              optData={optData}
                              marketPlace={marketPlace}
                              height={270}
                              fromExplorer={true}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
                <Col xs={4} className="ps-5 pe-5">
                  <div className="fs-4 pb-2" style={{ fontWeight: "bold" }}>
                    Pricing
                  </div>
                  <Row className="pb-4 fs-4">
                    <Col xs={6}>
                      <div className="pb-2">Current Price</div>
                    </Col>
                    <Col xs={6} className={`text-end`}>
                      {priceData["current"]
                        ? formatCurrency(priceData["current"], marketPlace)
                        : "N/A"}
                    </Col>
                    <Col xs={6}>
                      <div className="pb-2">Lowest Price</div>
                    </Col>
                    <Col xs={6} className={`text-end`}>
                      {priceData["min"]
                        ? formatCurrency(priceData["min"], marketPlace)
                        : "N/A"}
                    </Col>
                    <Col xs={6}>
                      <div className="pb-2">Highest Price</div>
                    </Col>
                    <Col xs={6} className={`text-end`}>
                      {priceData["max"]
                        ? formatCurrency(priceData["max"], marketPlace)
                        : "N/A"}
                    </Col>
                    <Col xs={6}>
                      <div className="pb-2">Optimal Price</div>
                    </Col>
                    <Col xs={6} className={`text-end`}>
                      {priceData["optimal"]
                        ? formatCurrency(priceData["optimal"], marketPlace)
                        : "N/A"}
                    </Col>
                  </Row>

                  {contentDetails && (
                    <ListingQuality contentDetails={contentDetails} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="ps-5 pe-5">
                  <h5>Technical Detail</h5>
                  <Row
                    className="pb-2 pt-2"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {listing &&
                      Object.keys(listing.attributes).map((spec_name, is) => {
                        const spec = listing.attributes[spec_name];

                        if (spec.length < 1 || spec.length > 1) {
                          return null;
                        }

                        if (!spec[0].value || spec[0].value.length > 30) {
                          return null;
                        }

                        return (
                          <React.Fragment key={is}>
                            <Col xs={2} className="pt-2">
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "200px",
                                  margin: 0,
                                }}
                              >
                                <strong>
                                  {spec_name
                                    .replace(/_/g, " ")
                                    .replace(/\w\S*/g, function (txt) {
                                      return (
                                        txt.charAt(0).toUpperCase() +
                                        txt.substr(1).toLowerCase()
                                      );
                                    })}
                                </strong>
                              </div>
                            </Col>
                            <Col xs={4}>{getSpecValue(spec[0].value)}</Col>
                          </React.Fragment>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="mx-auto py-4">
          <Col className="text-center">
            <LogoutButton
              fontSize="12px"
              key="RefreshBtn"
              title={`Close`}
              onClick={() => hideModal()}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ListingDetails;
