import React, { useState, useMemo, useRef, useEffect } from "react";
import { useFetch } from "hooks/api";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import Loading from "components/core/blocks/Loading";
import { selectModules } from "redux/selectors/modules";
import NotificationSystem from "react-notification-system";
import {
  ORG_SUMMARY_REPORT_BREADCRUMB_SPEC,
  REPORT_LINKS,
} from "utils/dashboardLinks";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsSkipBackwardCircleFill,
  BsSkipForwardCircleFill,
  BsCloudDownloadFill,
  BsEye,
} from "react-icons/bs";
import { Space } from "react-zoomable-ui";
import "../assets/css/summary_report_styles.css";

import moment from "moment";
import Checkbox from "components/core/basic/CheckBox";
import { FaGears } from "react-icons/fa6";
import CenteredModal from "components/core/basic/CenteredModal";
import FormGroupTile from "components/core/basic/FormGroupTile";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import {
  PAGE_HEIGHT,
  PAGE_INDEXES,
  PAGE_WIDTH,
  adTypeColorMap,
  filterNTMAdsNotEnrolled,
} from "components/custom/summaryReport/utils";
import checkModule from "utils/checkModule";
import { getAdTypeName } from "utils/getAdTypeName";
import useSearchTermReport from "hooks/useSearchTermReport";
import FormGroupLabel from "components/core/basic/FormGroupLabel";
import Switch from "react-bootstrap-switch";
import TopTargets from "components/custom/summaryReport/Targets";
import TopKeywords from "components/custom/summaryReport/TopKeywords";
import MediaPlanTrends from "components/custom/summaryReport/MediaPlanTrends";
import NewMediaPlans from "components/custom/summaryReport/NewMediaPlans";
import MediaPlanSummary from "components/custom/summaryReport/MediaPlanSummary";
import Overview from "components/custom/summaryReport/Overview";
import ProductTrends from "components/custom/summaryReport/ProductTrends";
import MediaPlanGoals from "components/custom/summaryReport/MediaPlanGoals";
import TopProducts from "components/custom/summaryReport/TopProducts";
import NewKeywords from "components/custom/summaryReport/NewKeywords";

const scaleDown = false;

const OptionalZoomWrapper = ({ children, zoom = false }) => {
  if (zoom) {
    return (
      <div
        style={{
          height: "70vh",
          position: "relative",
          margin: "auto",
        }}
      >
        <Space
          onCreate={(vp) => {
            vp.setBounds({ x: [0, 5000], y: [0, 5000] });
          }}
        >
          {children}
        </Space>
      </div>
    );
  }

  return <>{children}</>;
};

const Dashboard = ({ onFinishedLoading, isPDFView = false }) => {
  const [page, setPage] = useState(1);
  const [download, setDownload] = useState(false);
  const [reportContentsZoomable, setReportContentsZoomable] = useState(false);
  const [useRoasMetrics, setUseRoasMetrics] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [customOrganizationName, setCustomOrganizationName] = useState("");
  const [pagesToHide, setPagesToHide] = useState([]);
  const [autoSelectPages, setAutoSelectPages] = useState(false);
  const [minAutoSelectRows, setMinAutoSelectRows] = useState(5);
  const [includeNTMAds, setIncludeNTMAds] = useState(false);

  const { preferences } = useMerchandisePreferences();

  const notificationRef = useRef();
  const reduxState = useSelector((state) => state);
  const {
    marketPlace,
    channel: { currentChannel: channel },
    user,
    auth,
  } = reduxState;

  const modules = selectModules(reduxState);
  const { pathname, search } = useLocation();
  const orgId = user.organization_id;
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const pdfMode = pathname.includes("print") || pathname.includes("pdf");

  if (urlParams.get("orgName")) {
    setCustomOrganizationName(urlParams.get("orgName"));
    urlParams.delete("orgName");
  }

  if (urlParams.get("roas")) {
    setUseRoasMetrics(true);
    urlParams.delete("roas");
  }

  if (urlParams.has("pagesToHide")) {
    let urlPagesToHide = urlParams
      .get("pagesToHide")
      ?.split(",")
      ?.map((page) => parseInt(page));
    setPagesToHide(urlPagesToHide);
    urlParams.delete("pagesToHide");
  }

  const queryOptions = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
    enabled:
      ["amazon", "walmart"].includes(channel) &&
      auth?.loading === false &&
      auth?.tokens
        ? true
        : false,
  };

  let { start, end, preStart, preEnd } = useDates();

  const numberOfDays = moment(end).diff(moment(start), "d");

  let group = "day";

  if (numberOfDays > 45) {
    group = "week";
  }

  if (numberOfDays > 100) {
    group = "month";
  }

  const yoyPreStartDate = start
    .clone()
    .subtract(1, "year")
    .format("YYYY-MM-DD");
  const yoyPreEndDate = end.clone().subtract(1, "year").format("YYYY-MM-DD");

  const breadCrumbItems = [
    {
      name: "Organization Summary Report",
      href: "/user/report/organization/summary",
      dropdownLinkOptions: REPORT_LINKS,
      searchable: false,
      dropdownSubtitle: "Reports",
    },
  ];

  if (
    checkModule(modules, "ORG_SUMMARY_REPORT") &&
    !breadCrumbItems[0].dropdownLinkOptions?.find(
      (o) => o.name === ORG_SUMMARY_REPORT_BREADCRUMB_SPEC.name
    )
  ) {
    breadCrumbItems[0].dropdownLinkOptions.unshift(
      ORG_SUMMARY_REPORT_BREADCRUMB_SPEC
    );
  }

  const baseFilters = {
    mp: marketPlace.marketPlace,
    channel,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
  };

  const baseQueryKeys = useMemo(() => {
    return [
      marketPlace.marketPlace,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      preStart.format("YYYY-MM-DD"),
      preEnd.format("YYYY-MM-DD"),
      group,
      channel,
      orgId,
    ];
  }, [
    start,
    end,
    preStart,
    preEnd,
    channel,
    group,
    marketPlace.marketPlace,
    orgId,
  ]);

  const {
    data: categoryData,
    isLoading: isCategoryDataLoading,
    isError: isCategoryDataError,
  } = useFetch(
    ["category_report", ...baseQueryKeys],
    `/api/data_report/category_report`,
    { ...baseFilters, group_by: "category_id" },
    {
      ...queryOptions,
    }
  );

  const {
    data: categoryYoYData,
    isLoading: isCategoryYoYDataLoading,
    isError: isCategoryYoYDataError,
  } = useFetch(
    ["category_report", ...baseQueryKeys, yoyPreStartDate, yoyPreEndDate],
    `/api/data_report/category_report`,
    {
      ...baseFilters,
      group_by: "category_id",
      pre_start_date: yoyPreStartDate,
      pre_end_date: yoyPreEndDate,
    },
    { ...queryOptions }
  );

  const { data: annotationData, isLoading: isAnnotationDataLoading } = useFetch(
    [
      "annotations",

      marketPlace.marketPlace,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      group,
      channel,
      orgId,
    ],
    "/api/annotations/",
    {
      groupby: group,
      ts_after: `${baseFilters.start_date}T00:00:00`,
      ts_before: `${baseFilters.end_date}T23:59:59`,
      marketplace: marketPlace.marketPlace,
      channel: channel === "amazon" ? "AMZ" : channel,
      category: null,
    },
    { ...queryOptions }
  );

  const { data: productData, isLoading: isProductDataLoading } = useFetch(
    ["top_products", ...baseQueryKeys],
    `/api/data_report/product_report`,
    { ...baseFilters, group_by: "product_id" },
    {
      select: (d) => d.data ?? [],
      ...queryOptions,
    }
  );

  const { data: productYoYData, isLoading: isProductYoYDataLoading } = useFetch(
    ["top_products", ...baseQueryKeys],
    `/api/data_report/product_report`,
    {
      ...baseFilters,
      group_by: "product_id",
      pre_start_date: yoyPreStartDate,
      pre_end_date: yoyPreEndDate,
    },
    {
      ...queryOptions,
    }
  );

  const { data: adData, isLoading: isAdDataLoading } = useFetch(
    ["ad_report", ...baseQueryKeys],
    `/api/data_report/ad_report`,
    {
      ...baseFilters,
      group_by: "ad_id",
    },
    {
      ...queryOptions,
      select: (res) => {
        return res.data;
      },
    }
  );

  const {
    data: adYoYData,
    isLoading: isAdsYoYDataLoading,
    isError: isAdsYoYDataError,
  } = useFetch(
    ["ad_report", ...baseQueryKeys, yoyPreStartDate, yoyPreEndDate],
    `/api/data_report/ad_report`,
    {
      ...baseFilters,
      group_by: "ad_id",
      pre_start_date: yoyPreStartDate,
      pre_end_date: yoyPreEndDate,
    },
    {
      ...queryOptions,
      select: (res) => {
        return res.data;
      },
    }
  );

  const adPlanData = useMemo(() => {
    if (includeNTMAds) {
      return adData?.adplans?.map((ad) => {
        ad.target_roas = +((1 / ad["target_acos"]) * 100).toFixed(2);
        ad.min_roas = +((1 / ad["max_acos"]) * 100).toFixed(2);

        return { ...ad };
      });
    }
    return filterNTMAdsNotEnrolled(adData?.adplans)?.map((ad) => {
      ad.target_roas = +((1 / ad["target_acos"]) * 100).toFixed(2);
      ad.min_roas = +((1 / ad["max_acos"]) * 100).toFixed(2);

      return { ...ad };
    });
  }, [adData, includeNTMAds]);

  const adPlanYoYData = useMemo(() => {
    if (includeNTMAds) {
      return adYoYData?.adplans;
    }
    return filterNTMAdsNotEnrolled(adYoYData?.adplans);
  }, [adYoYData, includeNTMAds]);

  const { data: allKeywordData, isLoading: isAllKeywordDataLoading } =
    useSearchTermReport(
      "",
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      null,
      "match_type,keyword_text",
      {
        sort: "-ad_sales",
        additionalQueryKeys: "allKeywords",
      }
    );

  const {
    data: previousPeriodKeywordData,
    isLoading: isPreviousPeriodKeywordDataLoading,
  } = useSearchTermReport(
    "",
    preStart.format("YYYY-MM-DD"),
    preEnd.format("YYYY-MM-DD"),
    null,
    "match_type,keyword_text",
    {
      sort: "-ad_sales",
      additionalQueryKeys: "allKeywordsPrevious",
    }
  );

  const {
    data: newlyHarvestedKeywordData,
    isLoading: isNewlyHarvestedKeywordDataLoading,
  } = useSearchTermReport(
    "",
    start.format("YYYY-MM-DD"),
    end.format("YYYY-MM-DD"),
    null,

    "match_type,keyword_text",
    {
      additionalFilters: {
        keyword_source: "harvested",
        keyword_creation__gte: start.format("YYYY-MM-DD"),
        keyword_creation__lte: end.format("YYYY-MM-DD"),
      },
      additionalQueryKeys: "newlyHarvestedKeywords",
    }
  );

  const keywordData = useMemo(() => {
    const previousMap =
      previousPeriodKeywordData?.reduce((acc, row) => {
        let key = `${row.keyword_text}-$${row.match_type}`;
        acc[key] = row;
        return acc;
      }, {}) ?? {};

    const combinedData =
      allKeywordData?.map((row) => {
        const key = `${row.keyword_text}-$${row.match_type}`;
        row["key"] = key;
        const prevRow = previousMap?.[key];
        const compareFn = (key, row, prevRow) => {
          return {
            [`${key}_delta`]: row[key] - prevRow[key],
            [`${key}_percent`]:
              prevRow[key] !== 0
                ? (row[key] - prevRow[key]) / prevRow[key]
                : null,
            [`${key}_prev`]: prevRow[key],
          };
        };

        if (prevRow) {
          return {
            ...row,
            ...compareFn("impressions", row, prevRow),
            ...compareFn("clicks", row, prevRow),
            ...compareFn("ad_spend", row, prevRow),
            ...compareFn("cost", row, prevRow),
            ...compareFn("ad_sales", row, prevRow),
            ...compareFn("acos", row, prevRow),
            ...compareFn("roas", row, prevRow),
            ...compareFn("click_thru_rate", row, prevRow),
            ...compareFn("cost_per_click", row, prevRow),
            ...compareFn("conv_rate", row, prevRow),
            ...compareFn("conversion", row, prevRow),
          };
        }
        return row;
      }) ?? [];

    return combinedData;
  }, [previousPeriodKeywordData, allKeywordData]);

  const topTargetsData = useMemo(() => {
    const filteredTargetData =
      keywordData
        ?.filter(
          (target) =>
            ![
              "broad",
              "exact",
              "phrase",
              "targeting_expression_predefined",
              "theme",
            ].some((match_type) => match_type === target.match_type)
        )
        ?.sort((a, b) => b.ad_sales - a.ad_sales)
        ?.slice(0, 18) ?? [];

    return filteredTargetData;
  }, [keywordData]);

  const categoryTargetIds = useMemo(() => {
    return topTargetsData
      ?.filter((target) => target.keyword_text?.includes("category"))
      ?.map((target) => target.keyword_text?.split('"')?.[1])
      ?.join(",");
  }, [topTargetsData]);

  const { data: categoryTargetData, isLoading: isCategoryTargetDataLoading } =
    useFetch(
      ["categoryTargetDetails", categoryTargetIds],
      "/api/category/search",
      {
        category_ids: categoryTargetIds,
        marketplace: marketPlace.marketPlace,
      },
      {
        default: [],
        enabled: !!categoryTargetIds,
        select: (res) =>
          res.data.results?.reduce((acc, category) => {
            const { id, path, name } = category;
            return {
              ...acc,
              [id]: { path, name },
            };
          }, {}),
      }
    );

  const { data: adTypeData, isLoading: isAdTypeDataLoading } = useFetch(
    ["adTypeData", baseFilters.start_date, baseFilters.end_date],
    "/api/data_report_v2/advertisements/stats/",
    {
      dimensions: "ad_type",
      filters: `date__gte:${baseFilters.start_date},date__lte:${baseFilters.end_date}`,
    },
    {
      default: [],
      select: (res) => {
        const data =
          res?.data?.reduce((acc, current) => {
            const adTypeColor = adTypeColorMap[current.ad_type];
            const name = getAdTypeName(
              current.ad_type,
              channel.currentChannel ?? "amazon"
            );
            const data = acc[name] ?? {
              name: name,
              color: adTypeColor,
              ad_sales: 0,
              ad_spend: 0,
            };
            data["ad_sales"] += current.ad_sales;
            data["ad_spend"] += current.ad_spend;
            acc[name] = data;
            return acc;
          }, {}) ?? {};
        return Object.values(data);
      },
    }
  );

  const isLoading =
    isCategoryDataLoading ||
    isCategoryYoYDataLoading ||
    isAnnotationDataLoading ||
    isProductDataLoading ||
    isAdDataLoading ||
    isProductYoYDataLoading ||
    isAdsYoYDataLoading ||
    isAdTypeDataLoading ||
    isNewlyHarvestedKeywordDataLoading ||
    isAllKeywordDataLoading ||
    isPreviousPeriodKeywordDataLoading ||
    isCategoryTargetDataLoading;
  const isError =
    isCategoryDataError || isCategoryYoYDataError || isAdsYoYDataError;

  useEffect(() => {
    // Below timer is to give highcharts a bit of time to render the charts once data changes
    if (isLoading === false) {
      setTimeout(() => {
        onFinishedLoading(true);
      }, 4000);
    }
  }, [isLoading, onFinishedLoading]);

  const sharedPageProps = {
    orgName: customOrganizationName
      ? customOrganizationName
      : user.organization_name,
    group: group,
    roasPreference: useRoasMetrics || preferences.acos_or_roas === "roas",
    pdfMode: pdfMode,
    marketPlace: marketPlace,
    data: {
      categoryData,
      categoryYoYData,
      annotationData,
      productData,
      productYoYData,
      adData,
      adPlanData,
      adYoYData,
      adPlanYoYData,
      adTypeData,
      keywordData,
      topTargetsData,
      categoryTargetData,
      newlyHarvestedKeywordData,
    },
    channel: channel,
    includeNTMAds: includeNTMAds,
  };

  const PAGES = [
    {
      description: "Performance Summary and Top 5 Categories",
      component: <Overview {...sharedPageProps} />,
    },
    {
      description: "Most Impactful Media Plan Updates",
      component: <MediaPlanSummary {...sharedPageProps} />,
    },
    {
      description: "New Media Plans",
      component: <NewMediaPlans {...sharedPageProps} />,
    },
    {
      description: "Media Plan Trends",
      component: <MediaPlanTrends {...sharedPageProps} />,
    },
    {
      description: "New Keywords",
      component: <NewKeywords {...sharedPageProps} />,
    },
    {
      description: "Top Performing Keywords",
      component: <TopKeywords {...sharedPageProps} />,
    },
    {
      description: "Top Performing Targets",
      component: <TopTargets {...sharedPageProps} />,
    },
    {
      description: "Media Plan ACOS and Budget Targets vs. Performance",
      component: <MediaPlanGoals {...sharedPageProps} />,
    },
    {
      description: "Product Trends",
      component: <ProductTrends {...sharedPageProps} />,
    },
    {
      description: "Top Performing Products",
      component: <TopProducts {...sharedPageProps} />,
    },
  ];

  return (
    <>
      <NotificationSystem ref={notificationRef} />

      <div
        style={{
          background: "#fefefe",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        {!pdfMode && (
          <Row>
            <Col xs={12} className="mb-4">
              <BreadcrumbDates
                items={breadCrumbItems}
                hideGroupBy={true}
                hideCompare={true}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            {isLoading && <Loading />}
            {isError && <p>Error Loading Dashboard</p>}
            {!isLoading && !isError && (
              <>
                {showSettingsModal && (
                  <CenteredModal
                    show={showSettingsModal}
                    closeModal={() => setShowSettingsModal(false)}
                    title={"Report Settings"}
                    dialogClassName={"modal-50w"}
                    style={{
                      marginTop: "2rem",
                    }}
                  >
                    <Row>
                      <Col xs={12} lg={8}>
                        <FormGroupTile
                          type="formcontrol"
                          label={"Organization Name"}
                          formControlType={"string"}
                          stateName={"organizationName"}
                          labelClassName={"fs-standard"}
                          handleChildFormElement={(key, value) =>
                            setCustomOrganizationName(value)
                          }
                          placeholder={"Organization Name"}
                          defaultValue={customOrganizationName}
                        />
                      </Col>
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-column justify-content-end pb-2 gap-3"
                      >
                        <Checkbox
                          inline
                          number={`zoomContainer`}
                          label={<span>Allow Zoom and Drag</span>}
                          checked={reportContentsZoomable}
                          disabled={false}
                          onClick={(e) => {
                            setReportContentsZoomable(!reportContentsZoomable);
                          }}
                        />
                        <Checkbox
                          inline
                          number={`roas`}
                          label={<span>Use ROAS Metrics</span>}
                          checked={useRoasMetrics}
                          disabled={false}
                          onClick={(e) => {
                            setUseRoasMetrics(!useRoasMetrics);
                          }}
                        />
                        <Checkbox
                          inline
                          number={`includeNTM`}
                          label={<span>Include NTM Ads If Available</span>}
                          checked={includeNTMAds}
                          disabled={false}
                          onClick={(e) => {
                            setIncludeNTMAds(!includeNTMAds);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="py-5">
                      <Col xs={12}>
                        <FormGroupLabel label={"Customize Report Pages"} />
                        <div>
                          {PAGES.map((page, index) => (
                            <div
                              key={index}
                              className="py-3 d-flex align-items-center gap-3"
                            >
                              <Checkbox
                                key={index}
                                inline
                                number={`page${index + 1}`}
                                label={
                                  <span>
                                    Page {index + 1}: {page.description}{" "}
                                  </span>
                                }
                                checked={!pagesToHide.includes(index)}
                                disabled={false}
                                onChange={(e) => {
                                  if (pagesToHide.includes(index)) {
                                    let updatedPagesToHide = pagesToHide.filter(
                                      (p) => p !== index
                                    );
                                    setPagesToHide(updatedPagesToHide);
                                  } else {
                                    let updatedPagesToHide = [
                                      ...pagesToHide,
                                      index,
                                    ];

                                    setPagesToHide(updatedPagesToHide);
                                  }
                                }}
                              />

                              <BsEye
                                className="mb-2 fs-4"
                                style={{ cursor: "pointer", color: "#AAA" }}
                                onClick={() => {
                                  // Account for hidden pages that come before page to jump to
                                  const pageWithOffset =
                                    index -
                                    pagesToHide
                                      ?.sort()
                                      ?.filter((page) => page <= index)?.length;
                                  setPage(pageWithOffset + 1);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="d-flex gap-5 align-items-center pt-3">
                          <FormGroupLabel
                            className={"pt-2"}
                            label={"Auto-Select Pages Based on Available Data"}
                          />
                          <Switch
                            onText=""
                            offText=""
                            value={autoSelectPages}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              let updateAutoSelectOption = !autoSelectPages;
                              if (updateAutoSelectOption) {
                                const pagesWithoutSufficientData = [];

                                if (
                                  adPlanData?.filter((ad) => {
                                    return moment(
                                      ad.created_datetime.slice(0, 10)
                                    ).isSameOrAfter(
                                      moment(start.format("YYYY-MM-DD"))
                                    );
                                  })?.length < minAutoSelectRows
                                ) {
                                  pagesWithoutSufficientData.push(
                                    PAGE_INDEXES.NEW_MEDIA_PLANS_PAGE_INDEX
                                  );
                                }

                                if (
                                  adPlanData?.filter((ad) => ad.ad_sales_delta)
                                    ?.length < minAutoSelectRows
                                ) {
                                  pagesWithoutSufficientData.push(
                                    PAGE_INDEXES.MEDIA_PLAN_TRENDS_PAGE_INDEX
                                  );
                                }

                                if (
                                  newlyHarvestedKeywordData?.length <
                                  minAutoSelectRows
                                ) {
                                  pagesWithoutSufficientData.push(
                                    PAGE_INDEXES.NEW_KEYWORDS_PAGE_INDEX
                                  );
                                }

                                if (keywordData?.length < minAutoSelectRows) {
                                  pagesWithoutSufficientData.push(
                                    PAGE_INDEXES.TOP_KEYWORDS_PAGE_INDEX
                                  );
                                }

                                if (
                                  productData?.products?.filter(
                                    (product) => product.total_sales_delta
                                  )?.length < minAutoSelectRows
                                ) {
                                  pagesWithoutSufficientData.push(
                                    PAGE_INDEXES.PRODUCT_TRENDS_PAGE_INDEX
                                  );
                                }

                                if (
                                  productData?.products?.length <
                                  minAutoSelectRows
                                ) {
                                  pagesWithoutSufficientData.push(
                                    PAGE_INDEXES.TOP_PRODUCTS_PAGE_INDEX
                                  );
                                }

                                if (!adPlanData.some((ad) => ad.cost)) {
                                  pagesWithoutSufficientData.push(
                                    PAGE_INDEXES.MEDIA_PLAN_TARGETS_PAGE_INDEX
                                  );
                                }
                                setPagesToHide(pagesWithoutSufficientData);

                                const visiblePageCount =
                                  PAGES.length -
                                  pagesWithoutSufficientData?.length;

                                if (page > visiblePageCount) {
                                  setPage(visiblePageCount);
                                }
                              } else {
                                setPage(page + pagesToHide?.length);
                                setPagesToHide([]);
                              }
                              setAutoSelectPages(updateAutoSelectOption);
                            }}
                          />
                          <FormGroupLabel
                            className={"pt-2"}
                            label={"Minimum Rows"}
                          />
                          <FormGroupTile
                            type="formcontrol"
                            formControlType={"number"}
                            labelClassName={"fs-standard"}
                            handleChildFormElement={(key, value) =>
                              setMinAutoSelectRows(parseInt(value))
                            }
                            placeholder={""}
                            defaultValue={minAutoSelectRows}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CenteredModal>
                )}

                <div
                  className="summary-report-container"
                  style={pdfMode ? { paddingBottom: 0 } : {}}
                >
                  <OptionalZoomWrapper zoom={reportContentsZoomable}>
                    <>
                      <Row>
                        {isPDFView ? (
                          <>
                            {PAGES?.filter(
                              (_, index) => !pagesToHide.includes(index)
                            )?.map((p) => (
                              <Col
                                xs={12}
                                lg={10}
                                className={`summary-report-contents ${
                                  scaleDown ? "scaled-down mt-5" : ""
                                }`}
                                style={{
                                  maxWidth: PAGE_WIDTH,
                                  maxHeight: PAGE_HEIGHT,
                                  overflowY: "hidden",
                                }}
                              >
                                {p.component}
                              </Col>
                            ))}
                          </>
                        ) : (
                          <Col
                            xs={12}
                            lg={10}
                            className={`summary-report-contents ${
                              scaleDown ? "scaled-down mt-5" : ""
                            }`}
                            style={{
                              maxWidth: PAGE_WIDTH,
                              maxHeight: PAGE_HEIGHT,
                              overflowY: "hidden",
                            }}
                          >
                            {
                              PAGES?.filter(
                                (_, index) => !pagesToHide.includes(index)
                              )?.[page - 1]?.component
                            }
                          </Col>
                        )}
                      </Row>
                      {!pdfMode && (
                        <Row className="pb-5">
                          <div className="controls-row">
                            <div />
                            <div className="pagination">
                              <div className="prev">
                                <button
                                  onClick={() => setPage(1)}
                                  disabled={page === 1}
                                >
                                  <BsSkipBackwardCircleFill />
                                </button>
                                <button
                                  className="ms-3"
                                  onClick={() => setPage(page - 1)}
                                  disabled={page === 1}
                                >
                                  <BsFillArrowLeftCircleFill />
                                </button>
                              </div>
                              <div className="next">
                                <button
                                  onClick={() => setPage(page + 1)}
                                  disabled={
                                    page ===
                                    PAGES?.filter(
                                      (_, index) => !pagesToHide.includes(index)
                                    )?.length
                                  }
                                >
                                  <BsFillArrowRightCircleFill />
                                </button>
                                <button
                                  className="ms-3"
                                  onClick={() =>
                                    setPage(
                                      PAGES?.filter(
                                        (_, index) =>
                                          !pagesToHide.includes(index)
                                      )?.length
                                    )
                                  }
                                  disabled={
                                    page ===
                                    PAGES?.filter(
                                      (_, index) => !pagesToHide.includes(index)
                                    )?.length
                                  }
                                >
                                  <BsSkipForwardCircleFill />
                                </button>
                              </div>
                            </div>
                            <div className="download-button-container">
                              <button
                                onClick={() => {
                                  setDownload(true);
                                  const anchor = document.createElement("a");
                                  let printURL = `${pathname?.replace(
                                    "user",
                                    "pdf"
                                  )}${
                                    search ? `${search}&` : "?"
                                  }print=true&group=${group}`;
                                  if (
                                    useRoasMetrics ||
                                    preferences.acos_or_roas === "roas"
                                  ) {
                                    printURL += "&roas=true";
                                  }
                                  if (customOrganizationName) {
                                    printURL += `&orgName=${customOrganizationName}`;
                                  }
                                  if (pagesToHide?.length) {
                                    printURL += `&pagesToHide=${pagesToHide.join(
                                      ","
                                    )}`;
                                  }
                                  anchor.href = printURL;
                                  anchor.target = "_blank";
                                  document.body.appendChild(anchor);
                                  anchor.click();
                                  document.body.removeChild(anchor);
                                  setTimeout(() => {
                                    setDownload(false);
                                  }, 5000);
                                }}
                                disabled={download}
                                className={download ? "disabled" : ""}
                              >
                                <BsCloudDownloadFill />
                              </button>

                              <button
                                onClick={() => setShowSettingsModal(true)}
                                className="settings-button ms-5"
                              >
                                <FaGears />
                              </button>
                            </div>
                          </div>
                        </Row>
                      )}
                    </>
                  </OptionalZoomWrapper>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const OrgReport = ({ onFinishedLoading = () => {}, isPDFView = false }) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard onFinishedLoading={onFinishedLoading} isPDFView={isPDFView} />
    </DatesProvider>
  );
};

export default OrgReport;
