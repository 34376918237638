import React from "react";
import { Badge } from "react-bootstrap";
import { getPlanType } from "modules/pricingv2/utils";
import moment from "moment";
import { formatCurrency } from "utils/formatNumber";

const PlanHeader = ({ pricingPlan, user, marketPlace }) => {
  return (
    <div style={{ display: "flex" }}>
      <>
        {getPlanType(pricingPlan) === "Dynamic Price" && (
          <div className="pe-5">
            <h6
              style={{ fontWeight: 600 }}
              className="fs-standard text-uppercase pt-1 pr-3"
            >
              Pricing Strategy
            </h6>

            <Badge
              className={`badge bg-trellis-${
                pricingPlan.enroll_date ? "purple" : "lightgrey"
              } text-end`}
              style={{
                borderRadius: "5px",
              }}
            >
              {pricingPlan.enroll_date &&
                pricingPlan.configured_slider_value >= 0 &&
                pricingPlan.configured_slider_value <= 0 && (
                  <span>Balanced</span>
                )}
              {pricingPlan.enroll_date &&
                pricingPlan.configured_slider_value < 0 && <span>Volume</span>}
              {pricingPlan.enroll_date &&
                pricingPlan.configured_slider_value > 0 && <span>Margin</span>}
              {!pricingPlan.enroll_date && <span>n/a</span>}
            </Badge>
          </div>
        )}
      </>
      <div className="pe-5">
        <h6
          style={{ fontWeight: 600 }}
          className="fs-standard text-uppercase pt-1"
        >
          Plan Type
        </h6>
        <Badge
          className={`badge bg-trellis-lightgrey text-end`}
          style={{
            borderRadius: "5px",
          }}
        >
          <span>{getPlanType(pricingPlan)}</span>
        </Badge>
      </div>
      <div className="pe-5">
        <h6
          style={{ fontWeight: 600 }}
          className="fs-standard text-uppercase pt-1"
        >
          Enroll Date
        </h6>
        <Badge
          className={`badge bg-trellis-lightgrey text-end`}
          style={{
            borderRadius: "5px",
          }}
        >
          <span>{moment(pricingPlan.enroll_date).format("MM/DD/YYYY")}</span>
        </Badge>
      </div>
      {pricingPlan?.best_price && (
        <div className="pe-5 text-center">
          <h6
            style={{ fontWeight: 600 }}
            className="fs-standard text-uppercase pt-1 pr-3"
          >
            Price
          </h6>

          <Badge
            className={`badge bg-trellis-lightgrey text-end`}
            style={{
              borderRadius: "5px",
            }}
          >
            {formatCurrency(pricingPlan.best_price, marketPlace.marketPlace)}
          </Badge>
        </div>
      )}

      {pricingPlan?.best_price_date && (
        <div className="pe-5 text-center">
          <h6
            style={{ fontWeight: 600 }}
            className="fs-standard text-uppercase pt-1 pr-3"
          >
            Optimized On
          </h6>

          <Badge
            className={`badge bg-trellis-lightgrey text-end`}
            style={{
              borderRadius: "5px",
            }}
          >
            {moment(pricingPlan.best_price_date).format("MM/DD/YYYY")}
          </Badge>
        </div>
      )}
    </div>
  );
};

export default PlanHeader;
