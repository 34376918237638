import LiveDashboard from "./LiveDashboard";

export const LiveRoutes = [
  {
    path: "/live",
    layout: "/user",
    name: "Live Report",
    icon: "pe-7s-note2",
    application: "Pricing",
    component: null,
    children: [
      {
        path: "/user/live/report",
        component: LiveDashboard,
        exact: true,
        application: "Pricing",
      },
    ],
  },
];
