import { createRoot } from "react-dom/client";

import App from "./App";

import "i18n";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";
import "assets/css/font.css";
import "assets/css/trellis-styles.css";
import "assets/css/sidebar-styles.css";

const root = createRoot(document.getElementById("root")!);

root.render(<App />);
