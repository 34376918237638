import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CenteredModal from "components/core/basic/CenteredModal";
import {
  CancelButton,
  UpdateButton,
} from "components/core/blocks/AdPlan/buttons";
import { useFetch } from "hooks/api";
import React from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import FormGroupTile from "components/core/basic/FormGroupTile";
import FormGroupLabel from "components/core/basic/FormGroupLabel";
import moment from "moment";
import Checkbox from "components/core/basic/CheckBox";
import { useMemo } from "react";
import { AuthButton } from "components/core/basic/Button";
import { useEffect } from "react";
import { connect } from "react-redux";

const CreatePlanModal = ({
  plan,
  marketPlace,
  buttonStyles,
  buttonText,
  onCreate,
  planName,
}) => {
  const settings = useFetch(["merchandise-settings"], `/merchandise/settings/`);
  const orgsRequest = useFetch(["organizations"], "/api/organizations/");
  const orgs = useMemo(() => {
    if (settings?.data && orgsRequest?.data) {
      return orgsRequest?.data?.data?.filter((org) =>
        settings?.data?.data.enabled_orgs.find((oid) => oid === org.id)
      );
    }
    return [];
  }, [settings, orgsRequest]);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(planName ?? "");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [due, setDue] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const queryClient = useQueryClient();
  const createPlan = useMutation(
    async (plan) => {
      return await api.post("/merchandise/plans/", plan);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("plans");
        if (res.status === 201) {
          setTitle("");
          setAccounts([]);
          setShow(false);
        }
        if (typeof onCreate === "function") {
          onCreate(res.data);
        }
      },
    }
  );

  const updatePlan = useMutation(
    async (planToUpdate) => {
      return await api.patch(`/merchandise/plans/${plan.id}/`, planToUpdate);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("plans");
        if (res.status === 200) {
          setShow(false);
        }
      },
    }
  );

  const onCheckOrg = (org) => {
    if (accounts.find((a) => a.id === org.id)) {
      setAccounts(accounts.filter((a) => a.id !== org.id));
    } else {
      setAccounts([...accounts, org]);
    }
  };

  const onCreatePlan = () => {
    createPlan.mutate({
      title,
      org_plans: accounts.map((a) => ({ org_id: a.id })),
      planning_due: moment(due).format("YYYY-MM-DD"),
      execution_start: moment(start).format("YYYY-MM-DD"),
      execution_end: moment(end).format("YYYY-MM-DD"),
      market_place: marketPlace.marketPlace,
    });
  };

  const onUpdatePlan = () => {
    updatePlan.mutate({
      title,
      planning_due: moment(due).format("YYYY-MM-DD"),
      execution_start: moment(start).format("YYYY-MM-DD"),
      execution_end: moment(end).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    if (plan) {
      setTitle(plan.title);
      setStart(new Date(plan.execution_start));
      setEnd(new Date(plan.execution_end));
      setDue(new Date(plan.planning_due));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan?.id, show]);

  const canUpdate = due && start && end && title;
  const canCreate = due && start && end && title && accounts.length > 0;

  return (
    <>
      {(() => {
        if (plan) {
          return (
            <AuthButton
              title={"Update Plan"}
              onClick={() => setShow(true)}
              style={{ width: "auto" }}
              buttonStyle={"text_button"}
              disabled={!canUpdate}
            />
          );
        }
        return (
          <UpdateButton
            text={buttonText ? buttonText : "Create New Plan"}
            buttonStyles={buttonStyles}
            style={{ width: "auto" }}
            onClick={() => setShow(true)}
          />
        );
      })()}

      <CenteredModal
        show={show}
        closeModal={() => setShow(false)}
        title={plan ? "Update Existing Plan" : "Create a new Plan"}
        dialogClassName="modal-60w"
      >
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12} style={{ marginBottom: "2rem" }}>
                <FormGroupTile
                  type="formcontrol"
                  label={"Title"}
                  formControlType={"text"}
                  stateName={"title"}
                  handleChildFormElement={(key, value) => setTitle(value)}
                  placeholder={"Enter a plan title"}
                  defaultValue={title}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "2rem" }}>
                <div style={{ marginBottom: "2rem" }}>
                  <FormGroupLabel label={"Planning Due"} />
                </div>
                <DatePicker
                  placeholderText={"Due date"}
                  onChange={(d) => {
                    setDue(d);
                  }}
                  selected={due}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "2rem" }}>
                <div style={{ marginBottom: "2rem" }}>
                  <FormGroupLabel label={"Executing"} />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <DatePicker
                    placeholderText={"Start"}
                    onChange={(d) => {
                      setStart(d);
                    }}
                    selected={start}
                  />
                  <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>
                    to
                  </div>
                  <DatePicker
                    placeholderText={"End"}
                    onChange={(d) => {
                      setEnd(d);
                    }}
                    selected={end}
                  />
                </div>
              </Col>
              <Col xs={12} style={{ marginBottom: "2rem" }} hidden={plan}>
                <FormGroupLabel label={"Channels"} />
                {orgs.map((org) => (
                  <Checkbox
                    number={`create_plan_org_id_${org.id}`}
                    label={`${org.org_type} ${org.profile_name} (${org.profile_type})`}
                    checked={accounts.find((a) => a.id === org.id)}
                    disabled={plan ? true : false}
                    onClick={(e) => onCheckOrg(org)}
                  />
                ))}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CancelButton text={"Cancel"} onClick={() => setShow(false)} />
                <UpdateButton
                  text={plan ? "Update" : "Create"}
                  onClick={plan ? onUpdatePlan : onCreatePlan}
                  disabled={plan ? !canUpdate : !canCreate}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CenteredModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(CreatePlanModal);
