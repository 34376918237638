import React, { useContext, useEffect } from "react";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import { withStyles, Grid } from "@material-ui/core";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { toCurrency } from "utils/marketplaceConstants";
import { AdPlanContext } from "./AdPlanContext";
import "assets/css/dashboard_table.css";
import { DAILY_BUDGET_TOOLTIP } from "utils/toolTips";
import { AcosGoalsField } from "./fields";
import { getCombinedTemplates } from "./componentTemplates";
import { trellisPalette } from "components/custom/analytics/palettes";
import { Link } from "react-router-dom";
import { getAdTypeName } from "utils/getAdTypeName";
import channel from "redux/reducers/channel";
import CampaignNameField from "./fields/CampaignNameField";
import checkModule from "utils/checkModule";

const useStyles = (theme) => ({
  formContainer: {
    paddingRight: theme.spacing(8),
  },
});


const AdPlanChildren = ({
  state,
  children,
  onSelect,
  adId,
  marketPlace,
  errors,
  categoryId,
  inlineModal,
  user,
  onChange,
  fieldsToValidate,
  review,
  onShowChild,
  modules,
}) => {
  const { savedChildPlans, setSavedChildPlans } = useContext(AdPlanContext);

  // Populate children on initial render
  useEffect(() => {
    if (!state.children?.length && !state.id) {
      let componentMediaPlans = getCombinedTemplates(
        categoryId,
        state.product,
        state.name
      );
      onChange({
        children: [...componentMediaPlans],
      });

      setSavedChildPlans([...componentMediaPlans]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  // Ensure new children are saved
  if (children?.length > state.children?.length) {
    onChange({ children: children });
  }

  let totalAllocation =
    children
      ?.filter(
        (c) => c.advStatus !== 4 && c.advStatus !== 3 && c.advStatus !== 10
      )
      ?.reduce(
        (runningTotal, current) =>
          runningTotal + parseFloat(current.allocation),
        0
      ) ?? 0;

  return (
    <>
      <Grid container>
        <>
          {!adId && !review && (
            <Grid item xs={12} style={{ maxWidth: "90%" }}>
              <h2 style={{ fontWeight: 700, marginTop: 0, fontSize: "24px" }}>
                {review ? "Review" : "Build"} your Tactics
              </h2>

              {!review && (
                <p style={{ fontSize: "16px" }}>
                  Choose a template and set targeting parameters to build
                  tactics that work for you.
                </p>
              )}
            </Grid>
          )}
          <Grid container>
            <Grid item xs={12} md={6} lg={4} style={{ padding: "1rem 2rem" }}>
              <FormGroupTile
                type="formcontrol"
                label={"Daily Budget"}
                formControlType={"number"}
                toolTip={DAILY_BUDGET_TOOLTIP}
                stateName={"dailyBudget"}
                prefix={"$"}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: value })
                }
                placeholder={
                  "Enter Daily Budget (" +
                  MARKETPLACE_CURRENCY_DENOMINATION[marketPlace] +
                  ")"
                }
                defaultValue={state.dailyBudget}
              />
              {errors.dailyBudget &&
                fieldsToValidate?.includes("dailyBudget") && (
                  <small
                    className="text-danger"
                    style={{ paddingLeft: "2rem" }}
                  >
                    {errors.dailyBudget}
                  </small>
                )}
            </Grid>
          </Grid>
        </>
      </Grid>
      <div style={{ maxWidth: "90%" }}>
        <span
          style={{
            fontSize: "20px",
            padding: "1rem 0",
            display: "block",
            fontWeight: 500,
          }}
        >
          Component Settings
        </span>
        <p style={{ fontSize: "16px", marginBottom: "20px" }}>
          Adjust components to customize your targeting options for some or all
          of your selected products.
        </p>
      </div>
      <div style={{ paddingRight: "2rem" }}>
        {errors.campaignNames &&
          fieldsToValidate?.includes("campaignNames") && (
            <small className="text-danger d-block pb-4">
              {errors.campaignNames}
            </small>
          )}
        {errors.duplicateSegmentedCampaignNames &&
          !state.validatingCampaignNames?.length &&
          fieldsToValidate?.includes("duplicateSegmentedCampaignNames") && (
            <small className="text-danger d-block pb-4">
              {errors.duplicateSegmentedCampaignNames}
            </small>
          )}

        <>
          <div style={{ marginBottom: "2em" }}>
            {/* Component Summary */}
            {children?.map((child, i) => {
              if (child.advStatus === 4) {
                return null;
              }

              const [campaignType, campaignName] =
                Object.entries(child?.campaignNames ?? {})?.[0] ?? [];

              const handleCampaignNameChange = (type, value) => {
                const updatedCampaignName = {
                  [type]: value,
                };

                const updatedChildren = children?.map((child, index) =>
                  index === i
                    ? { ...child, campaignNames: updatedCampaignName }
                    : child
                );

                setSavedChildPlans(updatedChildren);
                onChange({ children: updatedChildren });
              };

              return (
                <>
                  {i === 0 && (
                    <div
                      key={i}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "170px 1fr 410px 0",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px 16px 5px 16px",
                        border: "1px solid #e3e3e3",
                        borderTop: i === 0 ? "1px solid #e3e3e3" : "none",
                      }}
                    >
                      <div>
                        <h6
                          style={{
                            paddingLeft: "20px",
                            textTransform: "none",
                            display: "flex",
                            alignItems: "center",
                            color: "#73706E",
                          }}
                        >
                          Tactic
                        </h6>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: "#73706E",
                        }}
                      >
                        <h6
                          style={{
                            padding: "0 1rem",
                            textTransform: "none",
                            display: "flex",
                            alignItems: "center",
                            color: "#73706E",
                          }}
                        >
                          Ad Type
                        </h6>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: "#73706E",
                        }}
                      >
                        <h6
                          style={{
                            padding: "0 1rem",
                            textTransform: "none",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            color: "#73706E",
                          }}
                        >
                          Target ACOS
                        </h6>
                        <h6
                          style={{
                            padding: "0 1rem",
                            textTransform: "none",
                            textAlign: "center",
                            color: "#73706E",
                          }}
                        >
                          Max ACOS
                        </h6>
                        <h6
                          style={{
                            padding: "0 1rem",
                            textTransform: "none",
                            textAlign: "center",
                            width: "155px",
                            color: "#73706E",
                          }}
                        >
                          Budget
                          <br />
                          Allocation (%)
                        </h6>
                      </div>
                    </div>
                  )}

                  <div
                    key={i}
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        review && !adId
                          ? "160px 1fr 440px 0"
                          : "170px 1fr 410px 0",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: review ? "24px 16px" : "15px 16px ",
                      border: "1px solid #e3e3e3",
                      borderTop: i === 0 ? "1px solid #e3e3e3" : "none",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      {state.id ? (
                        <Link
                          to={`/user/advertising/dashboard/ads/${child.id}`}
                          style={{
                            color: trellisPalette[0],
                            textDecoration: "none",
                            fontWeight: 600,
                          }}
                          className="fs-standard"
                        >
                          {child.name.split(` - `)?.[
                            child.name.split(" - ")?.length - 1
                          ] ?? child.name}
                        </Link>
                      ) : (
                        <span
                          onClick={() => {
                            if (!adId || !child.id) {
                              return;
                            }

                            !inlineModal && onSelect(i, child);
                            onShowChild && onShowChild(i, child);
                          }}
                          style={{
                            color: adId ? trellisPalette[0] : "#333",
                            cursor: adId ? "pointer" : "inherit",
                            fontWeight: 600,
                          }}
                        >
                          <span
                            style={{
                              visibility: inlineModal ? "hidden" : "inherit",
                            }}
                            className="fs-standard"
                          >
                            {child.name.split(` - `)?.[
                              child.name.split(" - ")?.length - 1
                            ] ?? child.name}
                          </span>
                        </span>
                      )}
                    </div>
                    {/* Ad type field */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                      className="fs-standard"
                    >
                      <div>
                        {getAdTypeName(child.adType, channel)}
                        {(state.dailyBudget / 100.0) * child.allocation < 1 &&
                          child.advStatus !== 10 &&
                          child.advStatus !== 3 && (
                            <div
                              style={{
                                marginRight: "2rem",
                                textAlign: "left",
                              }}
                            >
                              <i className="fa fa-warning" /> Budget allocation
                              is less than{" "}
                              {toCurrency(1, marketPlace.marketPlace)}.
                            </div>
                          )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {review && !adId && (
                        <>
                          {" "}
                          <div style={{ width: "200px", textAlign: "center" }}>
                            {child.targetACOS}%
                          </div>
                          <div style={{ width: "200px", textAlign: "center" }}>
                            {child.maxACOS}%
                          </div>
                        </>
                      )}
                      <div
                        style={{
                          marginLeft: review && !adId ? "inherit" : "auto",
                          marginRight: review && !adId ? "-3.5rem" : "inherit",
                          width: review && !adId ? "200px" : "inherit",
                        }}
                      >
                        {child.advStatus === 10 ? (
                          <> Ad Failed</>
                        ) : child.advStatus === 3 ? (
                          <>Ad Paused</>
                        ) : review && !adId ? (
                          <div
                            style={{
                              textAlign: review && !adId ? "center" : "inherit",
                            }}
                            className="fs-standard"
                          >
                            {child.allocation}%
                            {state.dailyBudget
                              ? ` (${toCurrency(
                                  (state.dailyBudget / 100.0) *
                                    child.allocation,
                                  marketPlace.marketPlace
                                )})`
                              : ""}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <AcosGoalsField
                                state={child}
                                onChange={onChange}
                                user={user}
                                marketPlace={marketPlace}
                                errors={errors}
                                isChild={true}
                                adId={adId}
                                roas={false}
                                channel={"amazon"}
                                fieldsToValidate={fieldsToValidate}
                                review={review}
                                hideLabels={true}
                                childIndex={i}
                                savedChildPlans={savedChildPlans}
                                setSavedChildPlans={setSavedChildPlans}
                                allowChildEdit={true}
                                modules={modules}
                              />
                            </div>
                            <div
                              style={{
                                dispay: "flex",
                                justifyContent: "center",
                                marginLeft: "2rem",
                              }}
                            >
                              {toCurrency(
                                parseFloat(
                                  (state.dailyBudget / 100) * child.allocation
                                ),
                                marketPlace.marketPlace
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Below logic is to only show campaign name inputs if module enabled during creation
                    in components table, then only show if a names been entered in the review step */}
                    {checkModule(modules, "CAMPAIGN_NAMES") &&
                      (!review || (review && !state.id && campaignName)) && (
                        <div style={{ gridColumn: "1 / -1" }}>
                          <CampaignNameField
                            campaignType={campaignType}
                            campaignName={campaignName}
                            state={state}
                            onChange={onChange}
                            errors={errors}
                            fieldsToValidate={fieldsToValidate}
                            channel={channel}
                            adId={adId}
                            handleCampaignNameChange={handleCampaignNameChange}
                            modules={modules}
                            key={`${campaignType}-${i}`}
                            marketPlace={marketPlace.marketPlace}
                            labelClassName={"inherit-text-styles"}
                            overrideLabelText={
                              review
                                ? "Campaign Name"
                                : "Campaign Name (optional)"
                            }
                            overridePlaceholderText="Campaign Name"
                            index={i}
                            review={review}
                            customLabelStyles={
                              review ? { marginTop: "1rem" } : {}
                            }
                            formControlClasses="border"
                          />
                        </div>
                      )}
                  </div>
                </>
              );
            })}
            {/* Total Row */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 16px",
                border: "1px solid #e3e3e3",
                borderTop: "none",
              }}
            >
              <div style={{ paddingLeft: "2rem" }} className="fs-standard">
                <strong>Total Allocation:</strong>
              </div>
              <div style={{ padding: "0 2rem" }} className="fs-standard">
                {totalAllocation < 100 ? (
                  <>
                    <i className="fa fa-warning" /> Only {totalAllocation}% of
                    this media plan's budget is currently allocated.
                  </>
                ) : totalAllocation > 100 ? (
                  <>
                    <i className="fa fa-warning" /> Budget allocation exceeds
                    100%.
                  </>
                ) : null}
              </div>{" "}
              <div
                style={{
                  marginLeft: "auto",
                }}
                className="fs-standard"
              >
                {totalAllocation}%{" "}
                <strong>
                  (
                  {toCurrency(
                    parseFloat((state.dailyBudget / 100) * totalAllocation),
                    marketPlace.marketPlace
                  )}
                  )
                </strong>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {errors.children && (
                <h6 className="text-danger" style={{ margin: "1rem" }}>
                  {errors.children}
                </h6>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default withStyles(useStyles)(AdPlanChildren);
