import { useSelector } from "react-redux";
import { useFetch } from "./api";
import moment from "moment";

const usePricingPlans = (
  categoryId = null,
  planId = null,
  overrideFilters = null
) => {
  const state = useSelector((state) => state);

  const {
    marketPlace,
    channel: { currentChannel: channel },
  } = state;

  const fiftyDaysInTheFuture = moment().add(50, "days").format("YYYY-MM-DD");

  let filters = {
    start_date: fiftyDaysInTheFuture,
    end_date: fiftyDaysInTheFuture,
    pre_start_date: null,
    pre_end_date: null,
    empty: true,
  };

  if (categoryId) {
    filters["group_by"] = "plan_id";
    filters["category_id"] = parseInt(categoryId);
    filters["category"] = parseInt(categoryId);
  } else {
    filters["group_by"] = "category_id";
  }

  if (overrideFilters) {
    filters = { ...filters, ...overrideFilters };
  }

  const { data: planData, isLoading: isPlanDataLoading } = useFetch(
    ["ad_report", marketPlace.marketPlace, channel, categoryId],
    `/api/data_report/pricingplan_report`,
    {
      mp: marketPlace.marketPlace,
      channel: channel,
      ...filters,
    },
    {
      select: (d) => d.data?.plan_id,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      default: [],
    }
  );

  return {
    currentPlanDetails:
      planData?.find((plan) => plan?.id === parseInt(planId)) ?? {},
    plans: planData?.map((plan) => ({
      ...plan,
      name: plan.plan_name,
      href: `/user/_pricing/plan/${plan.id}`,
      isLoading: isPlanDataLoading,
    })),
  };
};

export default usePricingPlans;
