import React from "react";
import { Badge } from "react-bootstrap";
import ProductImage from "components/custom/category/listings/ProductImage";
import {
  INVENTORY_STATES,
  SALE_STATES,
} from "components/core/blocks/AdPlan/constants";

const ProductCell = ({
  product,
  showSalesLabelBadge,
  showInventoryLabelBadge = false,
  // Below allows for override to show more or less sales state badge options
  salesStateArray = [
    SALE_STATES.BEST_SELLER,
    SALE_STATES.CLEARANCE,
    SALE_STATES.NEW_PRODUCT,
  ],
  inventoryStateArray = [
    INVENTORY_STATES.OVERSTOCK,
    INVENTORY_STATES.LOW_STOCK,
    INVENTORY_STATES.OUT_OF_STOCK,
  ],
  additionalBadges = null,
  setSelectedProduct = null,
  editBadge,
  showAsin,
}) => {
  const { id, sku, asin, sales_state, product_title, inventory_state } =
    product ?? {};
  const salesLabel = salesStateArray.find(
    (s) => s.value === sales_state
  )?.label;
  const inventoryBadgeText = inventoryStateArray.find(
    (s) => s.value === inventory_state
  )?.label;

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: "3rem",
        }}
      >
        <div style={{ float: "left" }}>
          <ProductImage
            divId={"parentProductImage" + id}
            id={id}
            width="50px"
            height="50px"
          />
        </div>{" "}
        <div style={{ float: "left", width: "100%" }}>
          <div key={"parentProductAsin" + id}>
            <span
              style={{
                marginLeft: "1.5rem",
                fontSize: "15px",
                textAlign: "center",
                fontWeight: "500",
                cursor: "pointer",
                float: "left",
                color:
                  typeof setSelectedProduct === "function"
                    ? "#d91266"
                    : "inherit",
                textDecoration:
                  typeof setSelectedProduct === "function"
                    ? "underline"
                    : "none",
              }}
              onClick={() => setSelectedProduct && setSelectedProduct(id)}
            >
              {showAsin ? asin : sku} {editBadge}
            </span>
          </div>
          <div
            style={{
              float: "left",
              marginLeft: "1.5rem",
              fontSize: "1.25rem",
              width: "100%",
              textAlign: "left",
              whiteSpace: "break-spaces",
              fontWeight: 400,
              height: "100%",
            }}
          >
            {product_title}
          </div>
          {((showSalesLabelBadge && salesLabel) ||
            (showInventoryLabelBadge && inventoryBadgeText) ||
            additionalBadges) && (
            <div
              style={{
                float: "left",
                marginLeft: "1.5rem",
                fontSize: "1.25rem",
                width: "100%",
                textAlign: "left",
                paddingTop: "1rem",
              }}
            >
              {showSalesLabelBadge && salesLabel && (
                <Badge bsClass="badge" className="badge-primary" pill>
                  {salesLabel}
                </Badge>
              )}
              {showInventoryLabelBadge && inventoryBadgeText && (
                <Badge
                  bsClass="badge"
                  className="badge-primary"
                  style={{ marginLeft: "0.5rem", backgroundColor: "#c43066" }}
                  pill
                >
                  {inventoryBadgeText}
                </Badge>
              )}
              {additionalBadges}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductCell;
