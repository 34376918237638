import React, { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { connect } from "react-redux";
import axios from "axios";
import getURLPrefix from "../utils/getUrlPrefix";
import { makeStyles } from "@material-ui/core/styles";

const urlPrefix = getURLPrefix();

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      height: "100%",
      width: "100%",
      padding: 40,
    },
    inner: {
      margin: "0 auto",
      textAlign: "center",
    },
  };
});

/**
 * Handles application installation when a user is coming from the Shopify
 * side.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ShopifyInstaller = (props) => {
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);

  const classes = useStyles();

  if (params.has("shop") && params.has("hmac")) {
    axios
      .get(`${urlPrefix}/shopify/install`, {
        params: params,
        headers: {
          Authorization: `Bearer ${props.auth.tokens.access_token}`,
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          setLoading(false);
        } else {
          window.location = res.data.url;
        }
      });
  }

  if (loading) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.inner}>
          <div>
            <img src={logo} alt="Loading..." className="rotate" width="80" />
          </div>
          <div>
            <p>Please wait...</p>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to={{ pathname: "/" }} />;
  }
};

/**
 * Shopify base router.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Shopify = (props) => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/install`}>
        <ShopifyInstaller {...props} />
      </Route>
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Shopify);
