import React from "react";
import { Link } from "react-router-dom";
import { useDates } from "dates/useDates";
import { generateUrl } from "dates/utils";

const TrellisLink = ({ to, children, ...rest }) => {
  const dates = useDates();
  const url = generateUrl(to, dates);

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
};

export default TrellisLink;
