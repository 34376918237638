import React from "react";
import { Col, Row } from "react-bootstrap";

const SummaryCard = ({
  title,
  byline,
  status,
  children,
  statusCols,
  mobileStyle,
  hideUpperRow = false,
  additionalStatusClasses = null,
  additionalTitleRowClasses = null,
  rawTitleStyle = false,
}) => (
  <div className="card" style={{ marginBottom: 0 }}>
    <div
      className="card-body"
      style={{ padding: mobileStyle ? "2rem" : "3rem" }}
    >
      {!hideUpperRow && (
        <Row
          style={
            additionalTitleRowClasses
              ? additionalTitleRowClasses
              : { marginBottom: "3rem" }
          }
        >
          <Col xs={statusCols ? 12 - statusCols : 10}>
            {!rawTitleStyle ? (
              <h3
                style={{
                  marginTop: "0rem",
                  marginBottom: "0.2rem",
                }}
              >
                {title}
              </h3>
            ) : (
              title
            )}
            {byline && <small>{byline}</small>}
          </Col>
          <Col
            xs={statusCols ? statusCols : 2}
            className={additionalStatusClasses ?? "text-end"}
          >
            {status}
          </Col>
        </Row>
      )}
      {children}
    </div>
  </div>
);

export default SummaryCard;
