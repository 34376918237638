import React from "react";
import useDashboardTheme from "hooks/useDashboardTheme";

const withDashboardTheme = (Component) => (props) => {
  const { breadcrumbColor, sidebarColor, section } = useDashboardTheme();

  return (
    <Component
      breadcrumbColor={breadcrumbColor}
      sidebarColor={sidebarColor}
      section={section}
    />
  );
};

export default withDashboardTheme;
