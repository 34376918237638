import React, { useState, useRef } from "react";
import { Box, Grid, TextField, Button, withStyles } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { setChannel } from "redux/actions/channel";
import { clearErrors, setErrors } from "redux/actions/errorActions";

import {
  logoutUser,
  setCurrentUser,
  setTokens,
} from "redux/actions/authActions";
import { setMarketPlace } from "redux/actions/marketPlace";
import api from "utils/api";
import { useHistory } from "react-router-dom";

const useStyles = (theme) => ({
  basePanel: {
    height: "100%",
    backgroundColor: "white",
    justifyContent: "flex-start",
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
      justifyContent: "center",
    },
  },
  scrollColumn: {
    // display: "flex",
    position: "relative",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
  },
  formColumn: {
    justifyContent: "center",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: "0%",
    top: "50%",
    width: "100%",
    transform: "translateY(-50%)",
    overflow: "hidden",
  },
  button: {
    width: "50%",
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    backgroundColor: "#d91366",
  },
  signupLink: {
    color: "#d91366",
    textDecoration: "underline",
  },
  welcomeText: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: "bold",
    color: theme.palette.grey[800],
    textAlign: "center",
  },
  input: {
    fontSize: theme.typography.h4.fontSize,
    width: "100%",
  },
  inputlabel: {
    fontSize: theme.typography.h4.fontSize,
  },
  logo: {
    marginLeft: "-5%",
    maxWidth: "50%",
  },
});

const ResetPassword = (props) => {
  const { classes } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const handleRequestPassword = async (e) => {
    e.preventDefault();
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if (password !== confirmPassword) {
      setErrorMessage("Password and confirm password do not match.");
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("user_token");
    const newPassword = password;
    try {
      await api.post("/new-password/", {
        token,
        newPassword,
      });
      history.push("/signin");
    } catch (error) {
      console.error("Error in new password");
      setErrorMessage("Token is invalid");
    }
  };

  return (
    <Grid container className={classes.basePanel} direction="row" wrap="nowrap">
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={10} md={8}>
        <PerfectScrollbar className={classes.scrollColumn}>
          <form>
            <Box className={classes.formColumn}>
              <Box className={classes.welcomeText}>Reset your password</Box>
              <Box mt={2} width={1} className="fs-standard">
                Enter your new password below.
              </Box>
              <Box mt={4}>
                <TextField
                  inputRef={passwordRef}
                  className={`${classes.input} mb-3`}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                  required
                />
              </Box>
              <Box mt={2}>
                <TextField
                  inputRef={confirmPasswordRef}
                  className={classes.input}
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
              </Box>
              <Box mt={2} width={1}>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </Box>
              <Box mt={5} width={1}>
                <Grid container justifyContent="center">
                  <Button
                    className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge ${classes.button}`}
                    type="submit"
                    onClick={handleRequestPassword}
                  >
                    Reset Password
                  </Button>
                </Grid>
              </Box>
            </Box>
          </form>
        </PerfectScrollbar>
      </Grid>
      <Grid item xs={1} md={2}></Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  setCurrentUser,
  updateAmzOnboardingStatus,
  setMarketPlace,
  setTokens,
  logoutUser,
  setErrors,
  clearErrors,
  setChannel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ResetPassword)));
