import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import store from "redux/store";
import { MIXPANEL_TOKEN } from "utils/gvSecrets";

mixpanel.init(MIXPANEL_TOKEN);

const isLocalhost =
  window.location?.hostname?.includes("localhost") ||
  window.location?.hostname?.includes("127.0.0.1");

export const useMixpanelTracking = () => {
  const { user } = useSelector((state) => state);
  const location = useLocation();

  useEffect(() => {
    if (isLocalhost) {
      return;
    }

    mixpanel.track("Page View", {
      name: user.user_full_name,
      staff: user.is_staff,
      url: location.pathname + location.search,
    });
  }, [location.pathname, location.search, user]);
};

export const trackMixpanelEvent = (type, additionalParams = {}) => {
  const { user } = store.getState() ?? {};

  if (isLocalhost) {
    return;
  }

  mixpanel.track(type, {
    user_name: user.user_full_name ?? "New User",
    staff: user.is_staff,
    organization_name: user.organization_name,
    organization_id: user.organization_id,
    ...additionalParams,
  });
};
