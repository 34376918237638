import { useMemo, useState } from "react";

const setColumns = (key, columns) => {
  let options = localStorage.getItem("columnOptions");
  try {
    options = JSON.parse(options);
  } catch (e) {
    options = {};
  }
  return localStorage.setItem(
    "columnOptions",
    JSON.stringify({ ...options, [key]: columns })
  );
};

const getColumns = (key) => {
  const options = localStorage.getItem("columnOptions");
  if (options) {
    try {
      return JSON.parse(options)?.[key] ?? {};
    } catch (e) {
      return {};
    }
  }
  return {};
};

const useColumnSelector = (columnsFn, deps, key) => {
  const [selectedColumns, setSelectedColumns] = useState(() => {
    return key ? getColumns(key) : {};
  });
  return useMemo(() => {
    let columns = columnsFn();
    const columnOptions = columns
      .filter((c) => !c?.isStatic)
      .map(({ id, Header: label, checked = true, toolTip, grouping }) => ({
        id: id,
        label: typeof label === "string" ? label : id,
        checked: selectedColumns?.[id] ?? checked,
        toolTip,
        grouping,
      }));
    columns = columns.filter(
      (c) => c.isStatic || columnOptions.find((o) => o.id === c.id)?.checked
    );
    const onColumnChange = (changes, overwriteSelectedColumns = false) => {
      const newColumns = overwriteSelectedColumns
        ? { ...changes }
        : { ...selectedColumns, ...changes };
      if (key) {
        setColumns(key, newColumns);
      }
      setSelectedColumns(newColumns);
    };
    return [columns, { columnOptions, onColumnChange }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsFn, selectedColumns, ...deps]);
};

export default useColumnSelector;
