import "assets/css/trellis-styles.css";
import api from "utils/api";
import CenteredModal from "components/core/basic/CenteredModal";
import React, { useMemo, useState } from "react";
import withAdPlan from "./withAdPlan";
import { CSVReader } from "react-papaparse";
import {
  LogoutButton,
  SecondaryButton,
} from "components/core/basic/Button.jsx";
import { captureException } from "@sentry/react";
import { Row, Table, Col } from "react-bootstrap";
import {
  GOALS,
  AD_TYPES,
  STATUS,
  TARGET_TYPES,
  ALGORITHM_OPTIONS,
  BID_STRATEGY_OPTIONS,
} from "./constants";
import { useFetch } from "hooks/api";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";

const Label = ({ children, style }) => (
  <div
    className="mt-3 mb-2"
    style={{ fontWeight: "bold", fontSize: "14px", ...style }}
  >
    <span className="text-nowrap">{children}</span>
  </div>
);

const Value = ({ children }) => (
  <div className="mb-2 fs-standard">
    <span className="text-nowrap">{children}</span>
  </div>
);

//no rwos -> no data
const AdPlanBulkCreate = (props) => {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState("");
  const [page, setPage] = useState("upload");
  const [selectedRow, setSelectedRow] = useState(0);

  const { data: categories } = useFetch(
    ["categories", props.marketPlace?.marketPlace, props?.channel],
    "/api/gvads/categories/",
    { marketplace: props?.marketPlace.marketPlace, channel: props?.channel },
    {
      select: (res) => res.data,
      enabled: props.show,
    }
  );

  const handleClose = () => {
    props.onClose();
    setRows([]);
    setError("");
    setPage("upload");
  };

  const handleOnRemoveFile = () => {
    setRows([]);
    setError("");
  };

  const handleSaveAdPlans = async () => {
    for (let row of rows) {
      const adPlan = {
        ...row,
        goal: row.adGoal.value,
        adType: row.adType.value,
        advStatus: STATUS.ACTIVE.value,
        product: row.product.map((p) => p.value),
      };

      const success = await props.onCreate(adPlan, row.product, false);
      if (!success) {
        return;
      }
    }
    setPage("upload");
    handleClose();
  };

  const handleOnDrop = async (data) => {
    // onDrop meaining on drag and drop file in
    var processedData = [];
    const newErrors = [];
    //find header row to map the column indexes
    const headers_col_map = {
      name: "name",
      "ad strategy": "adGoal",
      "target acos": "targetAcos",
      "max acos": "maxAcos",
      "daily budget": "dailyBudget",
      "ad type": "adType",
      category: "category",
      "products: asins (separate multiple products by new line)":
        "productASINSKU",
      "target type": "targetType",
      algorithm: "algorithm",
      "bid strategy": "bidStrategy",
      "auto campaign enabled": "autoCampaignEnabled",
      "keyword harvesting enabled": "keywordHarvestingEnabled",
      "keyword discovery enabled": "keywordDiscoveryEnabled",
      "asin harvesting enabled": "asinHarvestingEnabled",
      "trellis trak enabled": "trellisTrakEnabled",
      "min bid": "minBid",
      "max bid": "maxBid",
      "top of search": "topOfSearchBidMultiplier",
      "rest of search": "restOfSearchMultiplier",
      "product bid multiplier": "productPageMultiplier",
      "target keywords": "filledTags",
      "target asins": "filledAsins",
      "product type": "filledProductKeys",
      brands: "filledProductBrands",
      attributes: "attributes",
      "negative keywords": "filledNegativeKeywords",
      "negative asins": "filledNegativeAsins",
      "match types": "matchTypes",
    };
    const col_map = data[0].data.reduce((acc, h, idx) => {
      const header = h.trim().toLowerCase();
      if (Object.keys(headers_col_map).includes(header)) {
        acc[headers_col_map[header]] = idx;
      }
      return acc;
    }, {});

    //check if we found our column headers
    const headers = data[0].data.map((h) => h.toLowerCase().trim());

    const missing_cols = Object.keys(headers_col_map).filter(
      (col) => !headers.includes(col)
    );
    if (missing_cols.length > 0) {
      newErrors.push(
        "Row 1: has incorrect header(s). Missing columns: " +
          missing_cols.join(", ")
      );
      setError(newErrors);
    } else {
      let row_idx = 1;
      const num_columns = 8;

      //process the rows
      for (row_idx; row_idx < data.length; row_idx++) {
        var row = data[row_idx];
        if (row.data.length <= 0) {
          continue;
        } else if (row.data.length < num_columns - 2) {
          newErrors.push("Row " + (row_idx + 1) + ": has missing values");
        }
        const name = row.data[col_map["name"]].trim();
        const adGoal = row.data[col_map["adGoal"]].trim().toLowerCase();
        let filledTags = row.data[col_map["filledTags"]].trim();
        const targetACOS = row.data[col_map["targetAcos"]].trim();
        const maxACOS = row.data[col_map["maxAcos"]].trim();
        const dailyBudget = row.data[col_map["dailyBudget"]].trim();
        const adType = row.data[col_map["adType"]].trim().toLowerCase();
        const category = row.data[col_map["category"]].trim();
        const productASINSKU = row.data[col_map["productASINSKU"]].trim();
        const targetType = row.data[col_map["targetType"]]
          ?.trim()
          ?.toLowerCase();
        let matchTypes = row.data[col_map["matchTypes"]].trim()?.toLowerCase();
        const algorithm = row.data[col_map["algorithm"]].trim();
        const keywordHarvestingEnabled =
          row.data[col_map["keywordHarvestingEnabled"]].trim().toLowerCase() ===
          "true";
        const keywordDiscoveryEnabled =
          row.data[col_map["keywordDiscoveryEnabled"]].trim().toLowerCase() ===
          "true";
        const asinHarvestingEnabled =
          row.data[col_map["asinHarvestingEnabled"]].trim().toLowerCase() ===
          "true";
        const trellisTrakEnabled =
          row.data[col_map["trellisTrakEnabled"]].trim().toLowerCase() ===
          "true";
        const minBid = row.data[col_map["minBid"]].trim();
        const maxBid = row.data[col_map["maxBid"]].trim();
        const topOfSearchBidMultiplier =
          row.data[col_map["topOfSearchBidMultiplier"]].trim();
        const restOfSearchMultiplier =
          row.data[col_map["restOfSearchMultiplier"]].trim();
        const productPageMultiplier =
          row.data[col_map["productPageMultiplier"]].trim();
        const bidStrategy = row.data[col_map["bidStrategy"]].trim();
        let filledAsins =
          row.data[col_map["filledAsins"]] === undefined
            ? null
            : row.data[col_map["filledAsins"]]?.trim();
        let filledNegativeKeywords =
          row.data[col_map["filledNegativeKeywords"]] === undefined
            ? null
            : row.data[col_map["filledNegativeKeywords"]]?.trim();

        let filledNegativeAsins =
          row.data[col_map["filledNegativeAsins"]] === undefined
            ? null
            : row.data[col_map["filledNegativeAsins"]]?.trim();
        matchTypes =
          row.data[col_map["matchTypes"]] === undefined
            ? null
            : row.data[col_map["matchTypes"]]?.trim();
        let broadMatchEnabled = false;
        let phraseMatchEnabled = false;
        let exactMatchEnabled = false;
        let filledProductBrands = [];
        let filledProductKeys = [];
        let filteredAttributes = [];

        const channel = "AMZ";
        const matchAdGoal = Object.values(GOALS).find(
          (type) => type.label.toLowerCase() === adGoal
        );
        if (!matchAdGoal) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Ad Goal must be one of demand generation, product launch, optimize acos profitability or themed ad"
          );
        }
        filledTags = filledTags?.split("\n") ?? [];
        filledAsins = filledAsins?.split("\n") ?? [];
        filledNegativeKeywords = filledNegativeKeywords?.split("\n") ?? [];
        filledNegativeAsins = filledNegativeAsins?.split("\n") ?? [];

        // Match types
        matchTypes = matchTypes?.toLowerCase()?.split("\n") ?? [];
        if (matchTypes.includes("broad")) {
          broadMatchEnabled = true;
        }
        if (matchTypes.includes("phrase")) {
          phraseMatchEnabled = true;
        }
        if (matchTypes.includes("exact")) {
          exactMatchEnabled = true;
        }

        var regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
        if (!regex.test(targetACOS) || targetACOS <= 0) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Target ACOS must be a number greater than 0"
          );
        }
        if (!regex.test(maxACOS) || maxACOS <= targetACOS) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Max ACOS must be a number greater than Target ACOS"
          );
        }
        if (!regex.test(dailyBudget) || dailyBudget < 1) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Daily Budget must be a number greater than 1"
          );
        }
        if (
          !regex.test(topOfSearchBidMultiplier) ||
          topOfSearchBidMultiplier > 900 ||
          topOfSearchBidMultiplier < 0
        ) {
          newErrors.push(
            "Row " + (row_idx + 1) + ": Top of search must be between 0 and 900"
          );
        }
        if (
          !regex.test(restOfSearchMultiplier) ||
          restOfSearchMultiplier > 900 ||
          restOfSearchMultiplier < 0
        ) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Rest of search must be between 0 and 900"
          );
        }
        if (
          !regex.test(productPageMultiplier) ||
          productPageMultiplier > 900 ||
          productPageMultiplier < 0
        ) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Product bid multiplier must be between 0 and 100"
          );
        }

        let matchAdType = [
          AD_TYPES.SPONSORED_PRODUCT,
          AD_TYPES.SPONSORED_PRODUCT_AUTO,
        ].find((type) => type.label.toLowerCase() === adType);
        if (!matchAdType) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Ad Type must be sponsored product or sponsored product - auto"
          );
        }

        let algorithmType = [
          ALGORITHM_OPTIONS.PRODUCT_LAUNCH,
          ALGORITHM_OPTIONS.GROWTH_MAX,
          ALGORITHM_OPTIONS.ROAS_BOOST,
        ].find(
          (option) =>
            option.value.toLowerCase() === algorithm.toLowerCase().trim()
        );
        if (!algorithmType) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Algorithm must be " +
              Object.values(ALGORITHM_OPTIONS)
                .map((option) => option.label.toLowerCase())
                .join(", ")
          );
        }
        let bidStrategyType = [
          BID_STRATEGY_OPTIONS.DOWN_ONLY,
          BID_STRATEGY_OPTIONS.FIXED,
          BID_STRATEGY_OPTIONS.UP_DOWN,
        ].find(
          (strategy) =>
            strategy.value.toLowerCase() === bidStrategy.toLowerCase()
        );
        if (!bidStrategyType) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Bid strategy must be up / down, fixed, or down only"
          );
        }

        let matchAdTarget = [
          TARGET_TYPES.AUTOMATIC,
          TARGET_TYPES.MANUAL,
          TARGET_TYPES.FUNNEL,
        ].find(
          (target) => target.value.toLowerCase() === targetType.toLowerCase()
        );
        if (!matchAdTarget) {
          newErrors.push(
            "Row " +
              (row_idx + 1) +
              ": Ad Target must be automatic or manual or funnel"
          );
        }

        const cleanKw = (val) =>
          val
            ? val
                .split("\n")
                .map((kw) => kw.trim())
                .filter((kw) => !!kw) ?? []
            : [];

        const funnelKeywords = {};
        if (matchAdTarget.value === TARGET_TYPES.FUNNEL.value) {
          filledProductBrands = cleanKw(
            row.data[col_map["filledProductBrands"]]
          );
          filledProductKeys = cleanKw(row.data[col_map["filledProductKeys"]]);
          filteredAttributes = {
            other: cleanKw(row.data[col_map["attributes"]]),
          };
        }

        const productASINSKUArray = productASINSKU.split("\n");
        const productArray = [];
        const printedProductArray = [];
        const matchCategory = categories.find(
          (cat) =>
            cat?.leaf_category_name?.toLowerCase() === category?.toLowerCase()
        );
        if (matchCategory) {
          const productData = await fetchProducts(matchCategory.id);
          for (const productASINSKU of productASINSKUArray) {
            const productASIN = productASINSKU.slice(0, 10);
            const products = productData.filter((p) => p.asin === productASIN);
            if (!products.length) {
              newErrors.push(
                "Row " +
                  (row_idx + 1) +
                  ": Entered product is invalid or does not belong to category"
              );
            } else {
              productArray.push(...products);
              printedProductArray.push(productASIN);
            }
          }
        } else {
          newErrors.push("Row " + (row_idx + 1) + ": Invalid Category name");
        }

        if (name == null || name.length > 200 || name.length < 1) {
          newErrors.push("Row " + (row_idx + 1) + ": Invalid name length");
        }

        processedData.push({
          name,
          adGoal: matchAdGoal,
          filledTags,
          targetACOS,
          maxACOS,
          dailyBudget,
          adType: matchAdType,
          category: matchCategory?.id,
          categoryName: matchCategory?.leaf_category_name,
          categoryId: matchCategory?.id,
          productASINSKU,
          product: productArray,
          printedProductArray,
          targetType,
          matchAdTarget,
          algorithm,
          keywordHarvestingEnabled,
          keywordDiscoveryEnabled,
          asinHarvestingEnabled,
          trellisTrakEnabled,
          minBid,
          maxBid,
          topOfSearchBidMultiplier,
          restOfSearchMultiplier,
          productPageMultiplier,
          bidStrategy,
          bidStrategyType,
          channel,
          filledAsins,
          funnelKeywords,
          filledNegativeKeywords,
          filledNegativeAsins,
          broadMatchEnabled,
          exactMatchEnabled,
          phraseMatchEnabled,
          filledProductBrands,
          filledProductKeys,
          filteredAttributes,
        });
      }

      setRows(processedData);
      setError(newErrors);
      setPage("preview");
    }
  };

  const fetchProducts = async (categoryId) => {
    try {
      const res = await api.get("/api/gvads/productid/", {
        parent: true,
        marketplace: props.marketPlace.marketPlace,
        category_id: categoryId,
      });
      if (res.data) {
        const products = [];
        for (const product of res.data) {
          const { asin, sku, id } = product;
          products.push({
            isDisabled: product.is_parent,
            value: id,
            sku: `${sku}`,
            asin,
          });
          if (product.is_parent) {
            for (const child of product.children) {
              const { asin, sku, id } = child;
              products.push({
                value: id,
                sku: `${sku} (${asin})`,
                asin,
              });
            }
          }
        }
        return products;
      }
    } catch (e) {
      captureException(e);
      return [];
    }
  };
  const rowItem = useMemo(() => {
    if (rows.length > 0) {
      return rows[selectedRow];
    }
    return {};
  }, [rows, selectedRow]);
  return (
    <>
      <CenteredModal
        show={props.show}
        closeModal={handleClose}
        title={error.length > 0 ? "Error" : "Import Media Plans"}
        content={
          error.length > 0 ? (
            <>
              <hr />
              <Row>
                <Table responsive style={{ width: "100%" }}>
                  <tbody>
                    <td className="text-center">
                      {error.map((e) => (
                        <p
                          key={e}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          {e}
                        </p>
                      ))}
                    </td>
                  </tbody>
                </Table>
              </Row>
              <Row className="wrap" style={{ marginBottom: "2rem" }}>
                <LogoutButton
                  style={{ margin: "1rem" }}
                  fontSize="12px"
                  title={"Close"}
                  onClick={handleClose}
                />
              </Row>
            </>
          ) : page === "upload" ? (
            <>
              <Row style={{ content: "center" }}>
                <CSVReader
                  onDrop={handleOnDrop}
                  addRemoveButton
                  removeButtonColor="#659cef"
                  onRemoveFile={handleOnRemoveFile}
                  style={{
                    dropArea: {
                      margin: "1rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderColor: "pink",
                      borderRadius: 20,
                      backgroundColor: "rgba(162, 176, 175, 0.1)",
                      height: 75,
                    },
                  }}
                >
                  <span style={{ fontSize: 15 }}>
                    Drop CSV file here or click to bulk upload.
                  </span>
                </CSVReader>
                <br></br>
              </Row>
              <Row className="text-center">
                <a
                  href="/template.xlsx"
                  download
                  className="logout_button"
                  fontSize="12px"
                >
                  <span style={{ color: "white" }}>
                    <i className="fa fa-file-excel-o" /> Download Template
                  </span>
                </a>
              </Row>
            </>
          ) : (
            <>
              <hr />
              <Row>
                <Col xs={12} style={{ overflow: "auto" }}>
                  <Row>
                    <Col xs={12}>
                      <Label
                        style={{ fontSize: "1.8rem", textAlign: "center" }}
                      >
                        Preview ({selectedRow + 1} of {rows.length})
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Label>Name:</Label>
                      <Value>{rowItem.name}</Value>
                    </Col>
                    <Col xs={4}>
                      <Label>Category:</Label>
                      <Value>{rowItem.categoryName}</Value>
                    </Col>
                    <Col xs={4}>
                      <Label>Ad Strategy:</Label>
                      <Value>{rowItem.adGoal?.label}</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Label>Target ACOS:</Label>
                      <Value>{rowItem.targetACOS}</Value>
                    </Col>
                    <Col xs={4}>
                      <Label>Max ACOS:</Label>
                      <Value>{rowItem.maxACOS}</Value>
                    </Col>
                    <Col xs={4}>
                      <Label>Daily Budget:</Label>
                      <Value>{rowItem.dailyBudget}</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>Ad Type</Label>
                      <Value>{rowItem.adType?.label}</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>Products:</Label>
                    </Col>
                    {rowItem.product.map((prod) => (
                      <Col xs={4} key={prod.id}>
                        {prod.asin}/{prod.sku}
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>Target Type:</Label>
                      <Value>{rowItem.matchAdTarget?.label}</Value>
                    </Col>
                  </Row>
                  {rowItem.matchAdTarget.value ===
                    TARGET_TYPES.FUNNEL.value && (
                    <>
                      <Row>
                        <Col xs={4}>
                          <Label>Product Type:</Label>
                          <Value>{rowItem.filledProductKeys?.join(",")}</Value>
                        </Col>
                        <Col xs={4}>
                          <Label>Brands:</Label>
                          <Value>
                            {rowItem.filledProductBrands?.join(",")}
                          </Value>
                        </Col>
                        <Col xs={4}>
                          <Label>Other Attributes:</Label>
                          <Value>
                            {rowItem.filteredAttributes?.other?.join(",")}
                          </Value>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col xs={12}>
                      <Label>Targeted Keywords:</Label>
                      <Value>{rowItem.filledTags.join(",")}</Value>
                    </Col>
                    <Col xs={12}>
                      <Label>Targeted ASINs:</Label>
                      <Value>{rowItem.filledAsins.join(",")}</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label style={{ fontSize: "1.6rem" }}>
                        Advanced Options
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>Algorithm:</Label>
                      <Value>{rowItem.algorithm}</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Label>Keyword Harvesting:</Label>
                      <Value>
                        {rowItem.keywordHarvestingEnabled ? "Yes" : "No"}
                      </Value>
                    </Col>
                    <Col xs={6}>
                      <Label>Keyword Discovery:</Label>
                      <Value>
                        {rowItem.keywordDiscoveryEnabled ? "Yes" : "No"}
                      </Value>
                    </Col>
                    <Col>
                      <Label>ASIN Harvesting:</Label>
                      <Value>
                        {rowItem.asinHarvestingEnabled ? "Yes" : "No"}
                      </Value>
                    </Col>
                    <Col>
                      <Label>Trellis Trak:</Label>
                      <Value>{rowItem.trellisTrakEnabled ? "Yes" : "No"}</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Label>Min Bid:</Label>
                      <Value>{rowItem.minBid}</Value>
                    </Col>
                    <Col xs={6}>
                      <Label>Max Bid:</Label>
                      <Value>{rowItem.maxBid}</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Label>Top of Search Bid Multiplier:</Label>
                      <Value>{rowItem.topOfSearchBidMultiplier}%</Value>
                    </Col>
                    <Col xs={6}>
                      <Label>Bid Strategy:</Label>
                      <Value>{rowItem.bidStrategyType.label}</Value>
                    </Col>
                    <Col xs={6}>
                      <Label>Rest of Search Bid Multiplier:</Label>
                      <Value>{rowItem.restOfSearchMultiplier}%</Value>
                    </Col>
                    <Col xs={6}>
                      <Label>Product Bid Multiplier:</Label>
                      <Value>{rowItem.productPageMultiplier}%</Value>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>Negative Keywords:</Label>
                      <Value>{rowItem.filledNegativeKeywords.join(",")}</Value>
                    </Col>
                    <Col xs={12}>
                      <Label>Negative ASINs:</Label>
                      <Value>{rowItem.filledNegativeAsins.join(",")}</Value>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  className="text-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <SecondaryButton
                    onClick={() => setSelectedRow(selectedRow - 1)}
                    disabled={selectedRow === 0}
                  >
                    <HiOutlineChevronLeft />
                  </SecondaryButton>
                  <SecondaryButton
                    onClick={() => setSelectedRow(selectedRow + 1)}
                    disabled={selectedRow === rows.length - 1}
                  >
                    <HiOutlineChevronRight />
                  </SecondaryButton>
                </Col>
              </Row>
              <hr />
              <div
                className="d-flex justify-content-center"
                style={{ marginBottom: "2rem" }}
              >
                <LogoutButton
                  style={{ margin: "1rem" }}
                  fontSize="12px"
                  title={"Cancel"}
                  onClick={handleClose}
                />
                <LogoutButton
                  style={{ margin: "1rem", marginLeft: "5rem" }}
                  fontSize="12px"
                  title={"Save"}
                  onClick={handleSaveAdPlans}
                />
              </div>
              <Row>{Object.values(props.errors)}</Row>
            </>
          )
        }
      />
    </>
  );
};

export default withAdPlan(AdPlanBulkCreate);
