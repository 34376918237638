import React, { useMemo } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { useFetch } from "hooks/api";
import { formatCurrency } from "utils/formatNumber";
import { formatTargetText } from "utils/formatTargetText";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import moment from "moment";
import Loading from "components/core/blocks/Loading";
import { trellisPalette } from "components/custom/analytics/palettes";

const InsightDetails = ({
  row,
  marketPlace,
  adId,
  updateAdBudget,
  channel,
}) => {
  let filters = {
    insights: true,
    budget_suppression: true,
    advertisement: parseInt(adId),
  };
  const { data: targetInsightsData, isLoading: isInsightDataLoading } =
    useFetch(["targetInsights", adId], `/target_details`, filters, {
      select: (res) => res.data?.insights ?? [],
    });

  const dt = moment().add(50, "days").format("YYYY-MM-DD");

  let reportMetaFilters = {
    mp: marketPlace,
    date_group: "day",
    channel: channel,
    empty: true,
    start_date: dt,
    end_data: dt,
    pre_start_date: null,
    pre_end_date: null,
    kw_report: false,

    ad: parseInt(adId),
  };

  // Keyword data
  const { data: keywordData, isLoading: isKeywordDataLoading } = useFetch(
    ["keyword_report", "keyword", adId],
    `/api/data_report/keyword_report`,
    {
      ...reportMetaFilters,
      target_type: "keyword",
    },
    {
      select: (d) => d.data.keywords,
    }
  );

  // Target table data
  const { data: targetData, isLoading: isTargetDataLoading } = useFetch(
    ["keyword_report", "target", adId],
    `/api/data_report/keyword_report`,
    {
      ...reportMetaFilters,
      target_type: "target",
    },
    {
      select: (d) => d.data.keywords,
    }
  );

  // Placements table data
  const { data: adItemData, isLoading: isAdItemDataLoading } = useFetch(
    ["keyword_report", "adItems", adId],
    `/api/data_report/keyword_report`,
    { ...reportMetaFilters, target_type: "target" },
    {
      select: (d) => d.data.keywords,
      enabled: channel === "walmart",
    }
  );

  const { suppressedKeywords, suppressedTargets, suppressedWalmartAdItems } =
    useMemo(() => {
      return {
        suppressedKeywords: targetInsightsData
          ?.filter((insight) => insight.adv_keyword || insight.adv_wmadgkw)
          ?.map((insight) => {
            let keywordDetails = keywordData?.find((kw) => {
              if (channel === "walmart") {
                return kw.id === insight.adv_wmadgkw;
              }
              return kw.id === insight.adv_keyword;
            });

            return { ...insight, ...keywordDetails };
          }),
        suppressedTargets: targetInsightsData
          ?.filter((insight) => insight.adv_target)
          ?.map((insight) => {
            let targetDetails = targetData?.find(
              (target) => target.id === insight.adv_target
            );
            return { ...insight, ...targetDetails };
          }),
        suppressedWalmartAdItems: targetInsightsData
          ?.filter((insight) => insight.adv_wmaditem)
          ?.map((insight) => {
            let adItemDetails = adItemData?.find(
              (target) => target.id === insight.adv_wmaditem
            );
            return { ...insight, ...adItemDetails };
          }),
      };
    }, [targetInsightsData, keywordData, targetData, adItemData, channel]);

  let increaseAmount =
    row._original.optimal_budget - row._original.advertisement.monthly_budget;

  const getBadgeColor = (matchType) => {
    let colorsByMatchType = {
      exact: "bg-trellis-purple",
      broad: "bg-trellis-mauve",
      phrase: "bg-trellis-fuchsia",
      auto: "bg-trellis-fuchsia",
      product: "bg-trellis-mauve",
      category: "bg-trellis-purple",
      dynamic: "bg-light-pink",
      expression: "bg-trellis-green",
    };

    return colorsByMatchType[matchType];
  };

  return (
    <div className="bg-white p-5">
      <Row>
        <h4 style={{ fontWeight: 500 }}>Details</h4>

        {isInsightDataLoading ||
        isKeywordDataLoading ||
        isTargetDataLoading ||
        isAdItemDataLoading ? (
          <Loading height={"100px"} fullPage={false} />
        ) : (
          <>
            <Col xs={12}>
              <Row>
                {suppressedKeywords?.length ? (
                  <Col
                    xs={12}
                    lg={
                      !suppressedWalmartAdItems?.length &&
                      !suppressedTargets?.length
                        ? 12
                        : 6
                    }
                    className="pr-5"
                  >
                    <>
                      <h5 style={{ fontWeight: 500 }}>Suppressed Keywords</h5>
                      <div>
                        <Row className="pb-4">
                          <Col
                            xs={12}
                            className="fs-standard"
                            style={{ fontWeight: 500, color: "#333" }}
                          >
                            {suppressedKeywords.some(
                              (kw) => kw.match_type === "broad"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[3],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Broad Match
                              </>
                            )}
                            {suppressedKeywords.some(
                              (kw) => kw.match_type === "exact"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[7],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Exact Match
                              </>
                            )}
                            {suppressedKeywords.some(
                              (kw) => kw.match_type === "phrase"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[13],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Phrase Match
                              </>
                            )}
                          </Col>
                        </Row>
                        {suppressedKeywords
                          ?.sort((a, b) => {
                            return formatTargetText(a.text).localeCompare(
                              formatTargetText(b.text)
                            );
                          })
                          ?.sort((a, b) => {
                            return a.match_type.localeCompare(b.match_type);
                          })
                          .map((kw) => (
                            <Badge
                              bsClass="badge"
                              className={`${getBadgeColor(
                                kw.match_type
                              )} m-1 fs-5`}
                              pill
                            >
                              {formatTargetText(kw.text)}
                            </Badge>
                          ))}
                      </div>
                    </>
                  </Col>
                ) : (
                  ""
                )}

                {suppressedTargets?.length ? (
                  <Col
                    xs={12}
                    lg={!suppressedKeywords?.length ? 12 : 6}
                    className={suppressedKeywords?.length ? "pl-5" : "pr-5"}
                  >
                    <>
                      <h5 style={{ fontWeight: 500 }}>Suppressed Targets</h5>
                      <div>
                        <Row className="pb-4">
                          <Col
                            xs={12}
                            className="fs-standard"
                            style={{ fontWeight: 500, color: "#333" }}
                          >
                            {suppressedTargets.some(
                              (target) => target.match_type === "auto"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[13],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Auto Target
                              </>
                            )}
                            {suppressedTargets.some(
                              (target) => target.match_type === "category"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[7],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Category Target
                              </>
                            )}
                            {suppressedTargets.some(
                              (target) => target.match_type === "dynamic"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[8],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Dynamic Target
                              </>
                            )}
                            {suppressedTargets.some(
                              (target) => target.match_type === "expression"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[12],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Expression Target
                              </>
                            )}
                            {suppressedTargets.some(
                              (target) => target.match_type === "product"
                            ) && (
                              <>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: trellisPalette[3],
                                    fontWeight: "500",
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <i className="fa fa-circle"></i>
                                </span>{" "}
                                Product Target
                              </>
                            )}
                          </Col>
                        </Row>
                        {suppressedTargets
                          ?.sort((a, b) => {
                            return formatTargetText(a.text).localeCompare(
                              formatTargetText(b.text)
                            );
                          })
                          ?.sort((a, b) => {
                            return a.match_type.localeCompare(b.match_type);
                          })
                          ?.map((target) => (
                            <Badge
                              bsClass="badge"
                              className={`${getBadgeColor(
                                target.match_type
                              )} m-1 fs-5`}
                              pill
                            >
                              {formatTargetText(target.text)}
                            </Badge>
                          ))}
                      </div>
                    </>
                  </Col>
                ) : (
                  ""
                )}

                {suppressedWalmartAdItems?.length ? (
                  <Col
                    xs={12}
                    lg={!suppressedKeywords?.length ? 12 : 6}
                    className={suppressedKeywords?.length ? "pl-5" : "pr-5"}
                  >
                    <>
                      <h5 style={{ fontWeight: 500 }}>
                        Suppressed Auto Ad Items
                      </h5>
                      <div>
                        {suppressedWalmartAdItems
                          ?.sort((a, b) => {
                            return formatTargetText(a.text).localeCompare(
                              formatTargetText(b.text)
                            );
                          })
                          ?.sort((a, b) => {
                            return a.match_type.localeCompare(b.match_type);
                          })
                          ?.map((target) => (
                            <Badge
                              bsClass="badge"
                              className={`${getBadgeColor(
                                target.match_type
                              )} m-1 fs-5`}
                              pill
                            >
                              {formatTargetText(target.text)}
                            </Badge>
                          ))}
                      </div>
                    </>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
          </>
        )}
      </Row>
      {increaseAmount > 1 && (
        <Row className="mt-5">
          <h4 style={{ fontWeight: 500 }}>Recommendation</h4>

          <Col xs={12} className="d-flex align-items-center">
            <p className="pr-5 mb-0">
              Increase monthly budget by{" "}
              {formatCurrency(increaseAmount, marketPlace, false, false, false)}
            </p>
            <ConfirmationModal
              title={"Confirm Budget Update"}
              confirmationMessage={
                <>
                  <div className="fs-standard">
                    Are you sure you want to increase the monthly advertising
                    budget by {formatCurrency(increaseAmount, marketPlace)}?
                  </div>
                </>
              }
              confirmButtonText={"Confirm"}
              modalButtonText={"Apply"}
              onConfirm={(e) => {
                updateAdBudget(
                  adId,
                  row._original.optimal_budget,
                  row._original.advertisement
                );
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InsightDetails;
