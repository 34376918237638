import { Modal } from "react-bootstrap";
import React, { Component } from "react";
import withMediaQuery from "hocs/withMediaQuery";

class CenteredModal extends Component {
  render() {
    var dialogClassName =
      this.props.adPlanModal || this.props.assetModal
        ? "modal-75w"
        : this.props.dialogClassName === undefined
        ? "modal-90w"
        : this.props.dialogClassName;
    var titleFontSize =
      this.props.titleFontSize === undefined
        ? "24px"
        : this.props.titleFontSize;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.closeModal}
        dialogClassName={dialogClassName}
        backdrop="static"
        keyboard={true}
        style={{
          padding: this.props.adPlanModal ? "0" : "inherit",
          minWidth: this.props.adPlanModal ? "80%" : "inherit",
          maxHeight: this.props.adPlanModal ? "95vh" : "inherit",
        }}
        centered
        className={this.props.modalBodyClasses ?? ""}
      >
        <Modal.Body
          style={{
            height: this.props.searchModal ? "97vh" : "inherit",
            overflowY: this.props.searchModal ? "scroll" : "inherit",
          }}
        >
          <div
            style={{
              padding: this.props.mediaQuery
                ? "1rem"
                : this.props.adPlanModal
                ? "1rem 3rem 0 0"
                : "1rem 3rem",
            }}
          >
            <span
              onClick={this.props.closeModal}
              style={{
                fontSize: "26px",
                color: "lightgray",
                fontWeight: "100",
                float: "right",
                cursor: "pointer",
                textAlign: "end",
              }}
            >
              &#10005;
            </span>
            <div
              className="header"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              <h4
                className="title text-center"
                style={{
                  marginBottom: "2rem",
                  fontSize: titleFontSize,
                  color: "#403E3D",
                  fontWeight: "700",
                }}
              >
                {this.props.title}
              </h4>
            </div>
            <div
              style={{
                marginTop: this.props.adPlanModal ? 0 : "1rem",
                marginBottom: this.props.adPlanModal ? 0 : "1rem",
              }}
              className="fs-standard"
            >
              {this.props.content || this.props.children}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withMediaQuery("(max-width:500px)")(CenteredModal);
