import { LinkButton } from "components/core/basic/Button";
import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Nav, OverlayTrigger, Badge, Popover } from "react-bootstrap";
import { useMutation } from "react-query";
import api from "utils/api";
import { formatNumber, formatPercent } from "utils/formatNumber";
import { getBadgeVariant } from "../utils";
import SideMenuExplorer from "./SideMenuExplorer";

const SideMenuBottom = ({
  getSearchTerms,
  product = {},
  seoKeywords,
  seoSearchTerms,
  quickTerm,
  allContentElements,
  initialHiddenKeywords,
}) => {
  const [activeKey, setActiveKey] = useState("words");
  const [keywordList, setKeywordList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const [hiddenKeywordState, setHiddenKeywordState] = useState();
  const hiddenKeywords = useMemo(() => {
    return hiddenKeywordState || initialHiddenKeywords || [];
  }, [hiddenKeywordState, initialHiddenKeywords]);

  const updateHiddenKeywords = useMutation(async (hiddenKeywords) => {
    const res = await api.put(`/merchandise/quality/${product.id}/`, {
      update_hidden_keywords: hiddenKeywords,
    });
    if (res.status === 200) {
      const newHiddenKeywords = res.data?.["hidden_keywords"] || [];
      setHiddenKeywordState(newHiddenKeywords);
    } else {
      setHiddenKeywordState(null);
    }
  });

  useEffect(() => {
    if (seoKeywords && seoSearchTerms) {
      if (activeKey === "words") {
        setKeywordList(seoKeywords);
      } else {
        setKeywordList(seoSearchTerms);
      }
    }
  }, [seoKeywords, seoSearchTerms, activeKey]);

  useEffect(() => {
    let terms = [];
    if (allContentElements) {
      Object.keys(allContentElements).forEach((key) => {
        const element = allContentElements[key];
        if (element) {
          terms.push(element.toLowerCase());
        }
      });
    }

    terms = [...new Set(terms)];
    setTermList(terms);
  }, [allContentElements]);

  useEffect(() => {
    if (quickTerm !== null && quickTerm.length > 0) {
      setActiveKey("explorer");
    }
  }, [quickTerm]);

  const popover = (k) => {
    return (
      <Popover>
        <Popover.Header>
          <div className="fs-4">
            <strong>Keyword Metrics</strong>
          </div>
        </Popover.Header>
        <Popover.Body>
          <Row className="fs-5 pt-2" style={{ minWidth: "200px" }}>
            <Col xs={6}>
              <strong>Impressions</strong>:
            </Col>
            <Col xs={6} className="text-end">
              {formatNumber(k.impressions, {}, 2)}
            </Col>
          </Row>
          <Row className="fs-5 pt-2" style={{ minWidth: "200px" }}>
            <Col xs={5}>
              <strong>Clicks</strong>:
            </Col>
            <Col xs={7} className="text-end">
              {formatNumber(k.clicks, {}, 2)}
            </Col>
          </Row>
          <Row className="fs-5 pt-2" style={{ minWidth: "200px" }}>
            <Col xs={5}>
              <strong>CTR</strong>:
            </Col>
            <Col xs={7} className="text-end">
              {formatPercent(k.ctr)}
            </Col>
          </Row>
          <Row className="fs-5 pt-2" style={{ minWidth: "200px" }}>
            <Col xs={5}>
              <strong>CR</strong>:
            </Col>
            <Col xs={7} className="text-end">
              {formatPercent(k.cr)}
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <div className="card px-3 pt-3 mb-4">
      <Nav
        variant="underline"
        onSelect={(selectedKey) => {
          setActiveKey(selectedKey);
        }}
        activeKey={activeKey}
      >
        <Nav.Item style={{ width: "33%" }}>
          <Nav.Link eventKey="words" className="fs-4" style={{ color: "#000" }}>
            Words
          </Nav.Link>
        </Nav.Item>
        <Nav.Item style={{ width: "33%" }}>
          <Nav.Link
            eventKey="searchTerms"
            className="fs-4"
            style={{ color: "#000" }}
          >
            Phrases
          </Nav.Link>
        </Nav.Item>
        <Nav.Item style={{ width: "33%" }}>
          <Nav.Link
            eventKey="explorer"
            className="fs-4"
            style={{ color: "#000" }}
          >
            Explorer
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {(activeKey === "words" || activeKey === "searchTerms") && (
        <>
          <p className="fs-5 pt-3">
            Click on a word or phrase to view the conversion metrics. Crossed
            out terms are already being used in the product's content.
          </p>
          <div
            style={{
              maxHeight: "600px",
              overflow: "hidden",
              overflowY: "scroll",
              paddingTop: "2px",
              paddingBottom: "10px",
            }}
          >
            {keywordList &&
              keywordList
                .filter((k) =>
                  showHidden
                    ? hiddenKeywords?.find((h) => h === k.keyword_text)
                    : !hiddenKeywords?.find((h) => h === k.keyword_text)
                )
                .map((k, ik) => (
                  <OverlayTrigger
                    placement="right"
                    overlay={popover(k)}
                    key={ik}
                  >
                    <Badge
                      bg={getBadgeVariant(k)}
                      className="mx-1 my-1 fs-5 badge-keyword"
                      style={
                        termList.find(
                          (term) => term.indexOf(k.keyword_text) > -1
                        )
                          ? {
                              textDecoration: "line-through",
                              textDecorationColor: "#000",
                            }
                          : {}
                      }
                      onClick={() => getSearchTerms(k, product.id)}
                    >
                      {k.keyword_text}
                      <i
                        style={{ opacity: 0.65, cursor: "pointer" }}
                        className={`fa ${
                          hiddenKeywords?.find((h) => h === k.keyword_text)
                            ? "fa-eye-slash"
                            : "fa-eye"
                        }`}
                        onClick={(e) => {
                          const prev = hiddenKeywords || [];
                          let newKws;
                          if (prev?.indexOf(k.keyword_text) > -1) {
                            newKws = prev.filter((p) => p !== k.keyword_text);
                          } else {
                            newKws = [...prev, k.keyword_text];
                          }
                          updateHiddenKeywords.mutate(newKws);
                          setHiddenKeywordState(newKws);
                          e.stopPropagation();
                        }}
                      ></i>
                    </Badge>
                  </OverlayTrigger>
                ))}
          </div>
          <div className="text-center mb-2">
            <LinkButton onClick={() => setShowHidden(!showHidden)}>
              {showHidden ? "Hide" : "Show"} Hidden
            </LinkButton>
          </div>
        </>
      )}
      {activeKey === "explorer" && <SideMenuExplorer quickTerm={quickTerm} />}
    </div>
  );
};

export default SideMenuBottom;
