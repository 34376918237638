import ProductReport from "./ProductReport";
import AdvertisingReport from "./AdvertisingReport";
import Exports from "./Exports";
import SearchTermReport from "views/SearchTermReport";
import OrgReport from "views/OrgReport";

export const ReportRoutes = [
  {
    redirect: true,
    path: "/report/advertisement",
    layout: "/user",
    name: "Keyword Report",
    icon: "pe-7s-news-paper",
    component: AdvertisingReport,
    application: "Reports",
  },
  {
    redirect: true,
    path: "/report/product",
    layout: "/user",
    name: "Product Report",
    icon: "pe-7s-news-paper",
    component: ProductReport,
    application: "Reports",
  },
  {
    redirect: true,
    path: "/report/search_term_report",
    layout: "/user",
    name: "Search Term Report",
    icon: "pe-7s-news-paper",
    component: SearchTermReport,
    application: "Reports",
  },
  {
    redirect: true,
    path: "/report/export",
    layout: "/user",
    name: "Exports",
    icon: "pe-7s-news-paper",
    component: Exports,
    application: "Reports",
  },
  {
    redirect: true,
    path: "/report/organization/summary",
    layout: "/user",
    name: "Organization Summary Report",
    icon: "pe-7s-news-paper",
    component: OrgReport,
    application: "Reports",
  },
  {
    redirect: true,
    path: "/report/organization/summary",
    layout: "/pdf",
    name: "Organization Summary Report",
    icon: "pe-7s-news-paper",
    component: OrgReport,
    application: "Reports",
  },
];
