import React, { useState, useRef, useEffect } from "react";
import getQueryValue from "utils/getQueryValue";
import { useFetch } from "hooks/api";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectModules } from "redux/selectors/modules";
import Loading from "components/core/blocks/Loading";
import { Row, Col, Container as Grid } from "react-bootstrap";

import SummaryCard from "components/core/blocks/SummaryCard";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import useColumnSelector from "hooks/useColumnSelector";
import Accordion from "components/core/basic/Accordion";
import Metric from "components/core/blocks/SummaryMetric";
import { formatNumber, formatPercent } from "utils/formatNumber";
import { trellisPalette } from "components/custom/analytics/palettes";
import TrellisBreadcrumb from "components/core/blocks/Breadcrumb";
import SEOChart from "./components/SEOScoreChart";
import BuyabilityChart from "./components/BuyabilityScoreChart";
import ReactTable from "react-table-6";
import NoImage from "assets/images/utility/no_image.png";
import { Link } from "react-router-dom";
import { getURLPrefix } from "utils/getUrlPrefix";
import { TooltipBuyability, TooltipSearchability } from "./components/Tooltips";

const ProductQuality = ({ marketPlace, channel, modules, user }) => {
  const categoryId = getQueryValue("category_id");
  let URL_PREFIX = getURLPrefix();

  const [colToggle] = useState();
  const [filteredData, setFilteredData] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const tableRef = useRef();

  const { isLoading, data: categoryData } = useFetch(
    ["productQualityOrgs", categoryId],
    `/merchandise/quality_rollup/?rollup_type=category&category_id=${categoryId}`,
    {
      page: 1,
      limit: 100,
      market_place: marketPlace.marketPlace,
      ordering: "-seo_score",
    },
    {}
  );

  const { isLoading: productLoading, data: productData } = useFetch(
    ["productQualityProducts", categoryId],
    `/merchandise/quality?category_id=${categoryId}`,
    {
      page: 1,
      limit: 100,
      market_place: marketPlace.marketPlace,
      ordering: "-seo_score",
    },
    {}
  );

  const category = categoryData?.data?.results[0];
  const products = productData?.data?.results;

  if (products) {
    products.sort((a, b) => b.seo_score - a.seo_score);
  }

  useEffect(() => {
    if (searchFilter) {
      const data = products.filter((product) => {
        if (
          product.product.product_title
            .toLowerCase()
            .includes(searchFilter.toLowerCase())
        ) {
          return true;
        } else if (
          product.product.sku.toLowerCase().includes(searchFilter.toLowerCase())
        ) {
          return true;
        } else if (
          product.product.asin
            .toLowerCase()
            .includes(searchFilter.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setFilteredData(data);
    } else {
      setFilteredData(null);
    }
  }, [category, searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let columns = [
        {
          id: "Title",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <div style={{ position: "relative" }}>Product</div>
            </div>
          ),
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center", border: "none" },
          accessor: "product",
          Filter: () => {},
          filterMethod: () => {},
          Cell: (props) => {
            const [hasError, setHasError] = useState(false);
            const image = hasError
              ? NoImage
              : `${URL_PREFIX}/api/productimage/?product=${props.value.id}`;

            return (
              <Link to={`/user/content/seo?id=${props.value.id}`}>
                <div style={{ display: "flex", textAlign: "left" }}>
                  <div
                    onError={(e) => {
                      setHasError(true);
                    }}
                    style={{
                      userSelect: "none",
                      WebkitUserSelect: "none",
                      flexShrink: 0,
                      width: 50,
                      height: 50,
                      marginRight: "10px",
                      background: `url(${image}) no-repeat center / contain`,
                    }}
                    draggable="false"
                  />
                  <Row>
                    <Col>
                      <strong>{props.value.product_title}</strong>
                      <br />
                      <i
                        style={{
                          color: "rgb(102, 102, 102)",
                          fontSize: "1rem",
                        }}
                      >
                        <strong>
                          {props.value.asin} ({props.value.sku})
                        </strong>
                      </i>
                    </Col>
                  </Row>
                </div>
              </Link>
            );
          },
          width: 400,
        },
        {
          id: "SEOScore",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div style={{ position: "relative" }}>Searchability Score</div>
            </div>
          ),
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center", border: "none" },
          accessor: "seo_score",
          Filter: () => {},
          filterMethod: () => {},
          Cell: (props) => {
            return formatNumber(props.value, {}, 0);
          },
        },
        {
          id: "BuyabilityScore",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div style={{ position: "relative" }}>Buyability</div>
            </div>
          ),
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center", border: "none" },
          accessor: "buyability_score",
          Filter: () => {},
          filterMethod: () => {},
          Cell: (props) => {
            return formatNumber(props.value, {}, 0);
          },
        },
        {
          id: "CTR",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div style={{ position: "relative" }}>Click-Through Rate</div>
            </div>
          ),
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center", border: "none" },
          accessor: "ctr",
          Filter: () => {},
          filterMethod: () => {},
          Cell: (props) => {
            return formatPercent(props.value, 2);
          },
        },
        {
          id: "ConversionRate",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div style={{ position: "relative" }}>Conversion Rate</div>
            </div>
          ),
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center", border: "none" },
          accessor: "cr",
          Filter: () => {},
          filterMethod: () => {},
          Cell: (props) => {
            return formatPercent(props.value, 2);
          },
        },
      ];
      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle]
  );

  if (isLoading) {
    return <Loading text={"Loading Category SEO Dashboard"} />;
  }

  return (
    <>
      <TrellisBreadcrumb
        items={[
          { name: "Content", href: "/user/content/quality/org" },
          {
            name: category ? category.category.leaf_category_name : "Category",
            href: `/user/content/quality/product?category_id=${categoryId}`,
          },
        ]}
      />
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid>
          <SummaryCard
            title={`${
              category ? category?.category?.leaf_category_name : "Category"
            } SEO Dashboard`}
          >
            <Row>
              <Col xs={12} md={6}>
                <div className="text-center">
                  <h4>
                    Searchability <TooltipSearchability />
                  </h4>
                </div>
                <SEOChart seoData={category?.buckets} />
                <Row>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={5}>
                    <Metric
                      name="Click-Through Rate"
                      value={`${formatPercent(category?.ctr, 2)}`}
                      showTarget={false}
                      legendColor={trellisPalette[1]}
                    />
                  </Col>
                  <Col xs={12} md={5}>
                    <Metric
                      name="Searchability Score"
                      value={`${formatNumber(category?.seo_score, null, 0)}`}
                      showTarget={false}
                      legendColor={trellisPalette[14]}
                    />
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <div className="text-center">
                  <h4>
                    Buyability Score <TooltipBuyability />
                  </h4>
                </div>
                <BuyabilityChart buyabilityData={category?.buckets} />
                <Row>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={5}>
                    <Metric
                      name="Conversion Rate"
                      value={`${formatPercent(category.cr, 2)}`}
                      showTarget={false}
                      legendColor={trellisPalette[1]}
                    />
                  </Col>
                  <Col xs={12} md={5}>
                    <Metric
                      name="Buyability Score"
                      value={`${formatNumber(
                        category.buyability_score,
                        null,
                        0
                      )}`}
                      showTarget={false}
                      legendColor={trellisPalette[2]}
                    />
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
              </Col>
            </Row>
            {!productLoading && products && (
              <Row className="pt-5 fs-standard">
                <Col xs={12} className="text-end">
                  <Accordion title="Products" initialExpanded>
                    <ReactTable
                      ref={tableRef}
                      data={filteredData ? filteredData : products}
                      className="pricing-table"
                      PaginationComponent={ReactTablePagination}
                      getPaginationProps={() => ({
                        updateSearch: setSearchFilter,
                        clearFilters: () => {
                          setSearchFilter("");
                        },
                        hideFilters: true,
                        clearSearchTermFilters: () => setSearchFilter(""),
                        searchTerm: searchFilter,
                        table: "plan-products",
                        style: { marginBottom: "2rem" },
                        carouselLayout: false,
                        disableSearchFocus: true,
                      })}
                      filterable={false}
                      columns={columns}
                      defaultPageSize={20}
                      // Below is needed to eliminate the no data message that covers table headers if no data to render
                      NoDataComponent={() => null}
                      minRows={0}
                      showPaginationTop
                      showPaginationBottom={false}
                      columnSelectorProps={columnSelectorProps}
                      defaultSorted={[
                        {
                          id: "SEOScore",
                          desc: true,
                        },
                      ]}
                    />
                  </Accordion>
                </Col>
              </Row>
            )}
          </SummaryCard>
        </Grid>
      </div>
    </>
  );
};

ProductQuality.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
  user: state.user,
});

export default connect(mapStateToProps)(ProductQuality);
