import React from "react";
import { FieldWrapper } from "../fields";
import CampaignNameField from "./CampaignNameField";
import checkModule from "utils/checkModule";
import { AD_TYPES } from "../constants";

const CampaignNames = ({
  state,
  onChange,
  errors,
  fieldsToValidate,
  channel,
  adId,
  review,
  modules,
  marketPlace,
}) => {
  if (
    !checkModule(modules, "CAMPAIGN_NAMES") ||
    (state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
      !state.sdCampaignType) ||
    state.canHaveChildren ||
    (review && !state.id) ||
    channel !== "amazon"
  ) {
    return null;
  }
  const { campaignNames } = state;

  const handleCampaignNameChange = (type, value) => {
    const updatedCampaignNames = { ...campaignNames, [type]: value };
    onChange({ campaignNames: updatedCampaignNames });
  };

  return (
    <FieldWrapper>
      <h2
        style={{
          fontWeight: 500,
          fontSize: "20px",
          marginTop: "1rem",
          marginLeft: "2rem",
        }}
      >
        Campaign Names
      </h2>
      {!state.id ? (
        <p
          style={{
            fontSize: "16px",
            marginBottom: "2rem",
            marginLeft: "2rem",
            maxWidth: "90%",
          }}
          translate="yes"
        >
          You can customize your campaign names using the fields below.
        </p>
      ) : (
        <></>
      )}
      {errors.campaignNames && fieldsToValidate?.includes("campaignNames") && (
        <small
          className="text-danger d-block pb-3"
          style={{ paddingLeft: "2rem" }}
        >
          {errors.campaignNames}
        </small>
      )}
      {errors.duplicateCampaignNames &&
        !state.validatingCampaignNames?.length &&
        fieldsToValidate?.includes("duplicateCampaignNames") && (
          <small
            className="text-danger d-block pb-3"
            style={{ paddingLeft: "2rem" }}
          >
            {errors.duplicateCampaignNames}
          </small>
        )}
      {typeof campaignNames &&
        Object.keys(campaignNames)?.map((campaignType) => (
          <CampaignNameField
            campaignType={campaignType}
            campaignName={campaignNames[campaignType]}
            state={state}
            onChange={onChange}
            errors={errors}
            fieldsToValidate={fieldsToValidate}
            channel={channel}
            adId={adId}
            handleCampaignNameChange={handleCampaignNameChange}
            modules={modules}
            key={`${campaignType}`}
            marketPlace={marketPlace.marketPlace}
          />
        ))}
    </FieldWrapper>
  );
};

export default CampaignNames;
