import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import StatTile from "./StatTile";
import Table from "./Table";
import { useDates } from "dates/useDates";
import { PAGE_HEIGHT, PAGE_WIDTH, getTrendColor } from "./utils";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import moment from "moment";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import {
  AD_CREATION_FAILED_STATUS,
  MODERATION_STATUSES,
  STATUS,
} from "components/core/blocks/AdPlan/constants";
import { listFormatter } from "utils/listFormatter";
import { listMetaUpdates } from "../growth/AnnotationCell";
import AdTypePieCharts from "./AdTypePieCharts";

const MediaPlanSummary = ({
  orgName = "",
  data,
  group,
  roasPreference = false,
  marketPlace,
}) => {
  const { start, end } = useDates();
  const { categoryData, adPlanData, annotationData, adTypeData } = data;

  const newAdCount = useMemo(() => {
    if (adPlanData) {
      return (
        adPlanData?.filter((ad) => {
          return moment(ad.created_datetime.slice(0, 10)).isSameOrAfter(
            moment(start.format("YYYY-MM-DD"))
          );
        })?.length ?? 0
      );
    }
    return [];
  }, [adPlanData, start]);

  const monthlyBudget = useMemo(() => {
    if (adPlanData) {
      return (
        adPlanData?.reduce((acc, ad) => {
          if (
            ([STATUS.ACTIVE.value].includes(ad.state) ||
              ad.ad_status === MODERATION_STATUSES.PENDING.value) &&
            ad.ad_status !== AD_CREATION_FAILED_STATUS
          ) {
            return acc + ad.monthly_budget;
          }
          return acc;
        }, 0) ?? 0
      );
    }
    return [];
  }, [adPlanData]);

  const adSpendPercentOfBudget = useMemo(() => {
    const numberOfDays = moment(end).diff(moment(start), "days") + 1;

    if (categoryData.data.total) {
      const adSpend = categoryData?.data?.total?.cost ?? 0;
      const dailyBudget = monthlyBudget / 30;
      const periodBudget = dailyBudget * numberOfDays;

      return adSpend / periodBudget;
    }

    return 0;
  }, [categoryData, monthlyBudget, start, end]);

  const activeMediaPlanCount = useMemo(() => {
    if (adPlanData) {
      return (
        adPlanData?.reduce((acc, ad) => {
          if (ad.ad_status === STATUS.ACTIVE.value) {
            return acc + 1;
          }
          return acc;
        }, 0) ?? 0
      );
    }
    return [];
  }, [adPlanData]);

  const annotationTableColumns = useMemo(
    () => [
      {
        accessor: "description",
        Header: "Action",
        customCell: (annotation) => {
          const {
            advertisement: { name },
          } = annotation;
          return (
            <div>
              <span className="truncate">{name}</span>
            </div>
          );
        },
        customCompareCell: (annotation) => {
          const { annotation_type, description, meta } = annotation;
          return (
            <div style={{ fontWeight: 300 }} className="ms-2">
              {annotation_type.includes("UPDATED") && (
                <>
                  {listFormatter
                    ?.format(listMetaUpdates(meta, marketPlace, true))
                    ?.replace("acos", "ACOS")
                    ?.replace("roas", "ROAS")}
                </>
              )}
              {annotation_type.includes("CUSTOM") && description && (
                <>: {description}</>
              )}
              {annotation_type.includes("CREATED") && <> created</>}
            </div>
          );
        },
      },
      {
        accessor: "date",
        Header: "Date",
        customCell: (annotation) => {
          const { ts: date } = annotation;
          return <div>{moment(date).format("MMMM D, YYYY")}</div>;
        },
        customCompareCell: () => {
          return <></>;
        },
      },
      {
        accessor: "daily_attributed_sales_7d.after",
        Header: "Daily Sales",
        compareMetricId: "daily_attributed_sales_7d.diff",
        customCell: (annotation) => {
          const { daily_attributed_sales_7d } =
            annotation?.stats?.advertisement ?? {};

          return (
            <span>
              {formatCurrency(
                daily_attributed_sales_7d?.after,
                marketPlace.marketPlace,
                false,
                false
              )}
            </span>
          );
        },
        customCompareCell: (annotation) => {
          const { daily_attributed_sales_7d } =
            annotation?.stats?.advertisement ?? {};

          return (
            <span
              style={{
                color: getTrendColor(
                  daily_attributed_sales_7d?.after_diff,
                  "daily_attributed_sales_7d"
                ),
              }}
            >
              {formatCurrency(
                daily_attributed_sales_7d?.after_diff,
                marketPlace.marketPlace,
                false,
                true
              )}
            </span>
          );
        },
      },
      {
        accessor: "daily_cost.after",
        Header: "Daily Cost",
        compareMetricId: "daily_cost.diff",
        customCell: (annotation) => {
          const { daily_cost } = annotation?.stats?.advertisement ?? {};

          return (
            <span>
              {formatCurrency(
                daily_cost?.after,
                marketPlace.marketPlace,
                false,
                false
              )}
            </span>
          );
        },
        customCompareCell: (annotation) => {
          const { daily_cost } = annotation?.stats?.advertisement ?? {};

          return (
            <span
              style={{
                color: getTrendColor(daily_cost?.after_diff, "daily_cost"),
              }}
            >
              {formatCurrency(
                daily_cost?.after_diff,
                marketPlace.marketPlace,
                false,
                true
              )}
            </span>
          );
        },
      },
      {
        accessor: "daily_impressions.after",
        Header: "Daily Impressions",
        compareMetricId: "daily_impressions.diff",
        customCell: (annotation) => {
          const { daily_impressions } = annotation?.stats?.advertisement ?? {};

          return <span>{formatNumber(daily_impressions?.after, {}, 0)}</span>;
        },
        customCompareCell: (annotation) => {
          const { daily_impressions } = annotation?.stats?.advertisement ?? {};

          return (
            <span
              style={{
                color: getTrendColor(
                  daily_impressions?.after_diff,
                  "daily_impressions"
                ),
              }}
            >
              {formatNumber(
                daily_impressions?.after_diff,
                { addPlus: true },
                0
              )}
            </span>
          );
        },
      },
      {
        accessor: "daily_clicks.after",
        Header: "Daily Clicks",
        compareMetricId: "daily_clicks.diff",
        customCell: (annotation) => {
          const { daily_clicks } = annotation?.stats?.advertisement ?? {};

          return (
            <span>
              {formatNumber(
                daily_clicks?.after,
                {
                  addPlus: true,
                },
                0
              )}
            </span>
          );
        },
        customCompareCell: (annotation) => {
          const { daily_clicks } = annotation?.stats?.advertisement ?? {};

          return (
            <span
              style={{
                color: getTrendColor(daily_clicks?.after_diff, "daily_clicks"),
              }}
            >
              {formatNumber(daily_clicks?.after_diff, { addPlus: true }, 0)}
            </span>
          );
        },
      },
      {
        accessor: "daily_cr.after",
        Header: "Daily Conv. Rate",
        compareMetricId: "daily_cr.diff",
        customCell: (annotation) => {
          const { daily_cr } = annotation?.stats?.advertisement ?? {};

          return <span>{formatPercent(daily_cr?.after, 2, false)}</span>;
        },
        customCompareCell: (annotation) => {
          const { daily_cr } = annotation?.stats?.advertisement ?? {};

          return (
            <span
              style={{
                color: getTrendColor(daily_cr?.after_diff, "daily_cr"),
              }}
            >
              {formatPercent(daily_cr?.after_diff, 2, true)}
            </span>
          );
        },
      },
    ],
    [marketPlace]
  );

  const formattedAnnotationsTableData = useMemo(() => {
    // Group by annotation type
    const adAnnotationData =
      annotationData?.data
        ?.map((a) => a.annotations)
        ?.flat()
        ?.map((a) => ({ ...a, date: a.ts.slice(0, 7) }))
        ?.filter((ann) => {
          return ann["advertisement"];
        })
        ?.reduce((acc, annotation) => {
          const key = annotation?.annotation_type;

          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(annotation);

          return acc;
        }, {}) ?? [];

    return adAnnotationData;
  }, [annotationData]);

  return (
    <div
      className={`box box-common`}
      style={{
        maxWidth: PAGE_WIDTH,
        maxHeight: PAGE_HEIGHT,
        overflowY: "hidden",
      }}
    >
      <img src={logo} className="watermark" alt="Trellis logo" />

      <Row>
        <Col xs={12}>
          <Row style={{ paddingTop: "20px" }} className="px-5">
            <Col>
              <StatTile
                label={"Active Media Plans"}
                metricId={null}
                overrideValue={activeMediaPlanCount}
                showPreviousPeriod={false}
                showYoy={false}
              />
            </Col>
            <Col>
              <StatTile
                label={"New Media Plans"}
                metricId={null}
                overrideValue={formatNumber(newAdCount)}
                showPreviousPeriod={false}
                showYoy={false}
              />
            </Col>
            <Col>
              <StatTile
                label={"Monthly Budget"}
                metricId={null}
                overrideValue={formatCurrency(
                  monthlyBudget,
                  marketPlace.marketPlace,
                  true
                )}
                showPreviousPeriod={false}
                showYoy={false}
              />
            </Col>
            <Col>
              <StatTile
                label={"Ad Spend % of Budget"}
                metricId={null}
                overrideValue={formatPercent(adSpendPercentOfBudget, 2, false)}
                showPreviousPeriod={false}
                showYoy={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} className="fs-3" style={{ paddingLeft: "65px" }}>
          <AdTypePieCharts adTypeData={adTypeData} fontFamily={"Quicksand"} />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"Most impactful media plan updates *"}
            titleColWidth={35}
            data={
              formattedAnnotationsTableData["AD_UPDATED"]
                ?.sort((a, b) => {
                  if (
                    !b.stats?.advertisement?.daily_attributed_sales_7d
                      ?.after_diff
                  ) {
                    return -1;
                  }
                  let returnSortValue = Math.abs(
                    b.stats?.advertisement?.daily_attributed_sales_7d
                      ?.after_diff
                  );
                  Math.abs(
                    a.stats?.advertisement?.daily_attributed_sales_7d
                      ?.after_diff
                  );

                  return returnSortValue;
                })
                ?.slice(0, 7) ?? []
            }
            columns={annotationTableColumns}
            showPrevPeriodTrendRow={true}
            marketPlace={marketPlace.marketPlace}
            trendRowDescription=""
            noDataMessage={
              "No media plan updates were made during the selected period."
            }
          />
        </Col>
      </Row>
      <span className="table-annotation">
        * Sorted by greatest trend change in ad sales following media plan
        update
      </span>
    </div>
  );
};

export default MediaPlanSummary;
