import React, { useRef } from "react";
import { useFetch } from "hooks/api";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";
import ToolTips from "utils/toolTips";

const WalmartProfilesTable = () => {
  const { data: walmartProfiles, isLoading: isProfilesDataLoading } = useFetch(
    ["allWalmartProfileAdsInfo"],
    `/walmart/auth/onboarding`,
    {
      all_profiles: true,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (res) => {
        return res.data?.map((org) => {
          const { id, name, profile_name, profile_type, marketplaces } =
            org?.org ?? {};

          return {
            ...org,
            id,
            name,
            profile_name,
            profile_type,
            marketplaces,
          };
        });
      },
    }
  );

  const tableRef = useRef();

  return (
    <BulkActionsTable
      isLoading={isProfilesDataLoading}
      tableRef={tableRef}
      tableId={"walmartProfilesTable"}
      titleCol={{
        id: "profile_name",
        isStatic: true,
        Header: "Profile",
        accessor: "profile_name",
        width: 450,
      }}
      columnSpecs={[
        buildTextColumn("profile_type", "Profile Type", {
          formatter: (v) => {
            return v === "seller" ? "3P Seller" : "1P Supplier";
          },
        }),
        buildTextColumn("advertiser_id_provided", "Advertising API Connected", {
          formatter: (v) => {
            return v ? "Yes" : "No";
          },
        }),
        {
          key: "marketplace_credentials_provided",
          compareKey: null,
          options: {
            className: "text-start justify-content-start px-5 h-100",
            cellContainerClasses: "text-start justify-content-start",
            metric: {
              id: "marketplace_credentials_provided",
              format: "text",
              accessor: (d) => d,
              name: "Marketplace API Connected",
            },
            formatter: (row) => {
              const {
                profile_type,
                marketplace_credentials_provided,
                profile_name,
              } = row;

              return (
                <>
                  {profile_type === "vendor"
                    ? "N/A"
                    : marketplace_credentials_provided
                    ? "Yes"
                    : "No"}
                  {profile_type === "vendor" ? (
                    <ToolTips
                      toolTip={
                        "The Walmart Marketplace API currently only supports 3P sellers"
                      }
                      position="top"
                      id={profile_name}
                    />
                  ) : (
                    <></>
                  )}
                </>
              );
            },
          },
        },
        buildTextColumn("marketplaces", "Marketplaces", {
          formatter: (v) => {
            return v.join(", ");
          },
        }),
      ]}
      data={walmartProfiles ?? []}
      bulkActionOptions={[]}
      isExpandable={false}
      hideFilterToggleIcon={true}
      isSelectable={false}
      selected={[]}
      setSelected={() => {}}
      selectionKey={"id"}
      getSearchCriteria={(row) => {
        const { profile_name, type } = row;
        return `${profile_name} ${type}`;
      }}
      tableRowDescription={"Profiles"}
      minRows={0}
    />
  );
};

export default WalmartProfilesTable;
