import React from "react";
import EditCell from "components/custom/merchandise/cells/CommonEditCell";
import { generateFilterMarkup } from "./buildMetricColumn";
import { METRIC_GROUPING_LOOKUP } from "../tables/metricsByEntity";

const filterRanges = (filter, value) => {
  if (filter.value === "all") {
    return true;
  }
  const [start, end] = JSON.parse(filter.value);
  return value >= start && value < end;
};

const buildEditColumn = (title, marketPlace, id, options) => {
  const {
    hideFilter,
    filterType,
    selectOptions,
    width,
    minWidth,
    maxWidth,
    isStatic,
    checked,
    format,
    onValue,
    offValue,
    toolTip,
  } = options ?? {};

  return {
    Header: title,
    id,
    accessor: id,
    checked: checked ?? true,
    maxWidth,
    minWidth,
    isStatic,
    grouping: METRIC_GROUPING_LOOKUP[id],
    width,
    format,
    height: 30,
    onValue,
    offValue,
    toolTip,
    className: options?.className ?? "text-end d-flex align-items-center",
    headerClassName: options?.className ?? "text-end",
    Cell: (props) => {
      const { row, column } = props;
      return (
        <EditCell
          row={row}
          value={
            options?.overrideKey &&
            row?._original?.[options?.overrideKey] &&
            !row?._original?.updated
              ? row?._original?.[options?.overrideKey]
              : row?._original?.[column.id]
          }
          column={column}
          options={options}
          marketPlace={marketPlace}
          tabIndex={options?.tabIndex}
        />
      );
    },

    style: { height: "60px" },
    filterMethod: (filter, row) => {
      const value = row._original?.[id];

      if (filterType === "select") {
        if (filter.value === "all") {
          return true;
        }

        // * Below is a bit odd, somewhere in processing if the filter value of null is passed in, the value gets overwritten by the option label
        if (filter.value === "null") {
          return value === null;
        }

        // Below to hand numeric values getting converted to strings during processing
        return value == filter.value;
      }

      return filterRanges(filter, value);
    },
    Filter: ({ filter, onChange }) => {
      if (hideFilter) {
        return null;
      }

      if (filterType === "select") {
        return generateFilterMarkup(filter, onChange, null, null, "select", {
          selectOptions: [
            { value: "all", label: "Show All" },
            ...selectOptions,
          ],
        });
      }
    },
  };
};

export default buildEditColumn;
