export const SUBSCRIPTION_PLANS_UPPERCASE = {
  // New core subscriptions start
  CORE_ENTREPRENEUR: {
    displayName: "Entrepreneur",
    monthly: { pay: 299, plan: "CORE_ENTREPRENEUR__MONTHLY" },
    annual: { pay: 2870.4, save: 717.6, plan: "CORE_ENTREPRENEUR__ANNUAL" },
    features: ["Placement Advertising", "Product Content"],
    annualSales: "Up to $2M",
  },
  CORE_GROWTH: {
    displayName: "Growth",
    monthly: { pay: 999, plan: "CORE_GROWTH__MONTHLY" },
    annual: { pay: 9590.4, save: 2397.6, plan: "CORE_GROWTH__ANNUAL" },
    features: ["Placement Advertising", "Product Content"],
    annualSales: "$2M to $10M",
  },
  CORE_PROFESSIONAL: {
    displayName: "Professional",
    monthly: { pay: 1499, plan: "CORE_PROFESSIONAL__MONTHLY" },
    annual: { pay: 14390.4, save: 3597.6, plan: "CORE_PROFESSIONAL__ANNUAL" },
    features: [
      "Placement Advertising",
      "Product Content",
      "Dynamic Pricing",
      "Promotions & Deals",
    ],
    annualSales: "$2M to $10M",
  },
  CORE_CUSTOM: {
    displayName: "Custom",
    monthly: { pay: 0, plan: "CORE_CUSTOM__MONTHLY" },
    annual: { pay: 0, save: 0, plan: "CORE_CUSTOM__ANNUAL" },
    features: [
      "Placement Advertising",
      "Product Content",
      "Dynamic Pricing",
      "Promotions & Deals",
    ],
    annualSales: null,
  },
  // New core subscriptions end
  ENTREPRENEUR: {
    displayName: "Essentials",
    monthly: { pay: 199, plan: "ENTREPRENEUR__MONTHLY" },
    annual: { pay: 2149.2, save: 238.8, plan: "ENTREPRENEUR__ANNUAL" },
    features: "6 active advertisements",
    annualSales: "Up to $120k",
  },
  BUSINESS: {
    displayName: "Starter",
    monthly: { pay: 299, plan: "BUSINESS__MONTHLY" },
    annual: { pay: 3229.2, save: 358.8, plan: "BUSINESS__ANNUAL" },
    features: "15 active advertisements",
    annualSales: "$120k to $360k",
  },
  PROFESSIONAL: {
    displayName: "Premium",
    monthly: { pay: 499, plan: "PROFESSIONAL__MONTHLY" },
    annual: { pay: 5389.2, save: 598.8, plan: "PROFESSIONAL__ANNUAL" },
    features: "30 active advertisements",
    annualSales: "$360k to $1M",
  },
  ENTERPRISE: {
    displayName: "Enterprise",
    monthly: { pay: 999, plan: "ENTERPRISE__MONTHLY" },
    annual: { pay: 10789.2, save: 1198.8, plan: "ENTERPRISE__ANNUAL" },
    features: "Unlimited active advertisements",
    annualSales: "$1M to $3M",
  },
  CORPORATE: {
    displayName: "Custom",
    monthly: { pay: 0, plan: "CORPORATE__MONTHLY" },
    annual: { pay: 0, save: 0, plan: "CORPORATE__ANNUAL" },
    features: "Features to be determined",
    annualSales: "Above $3M",
  },
  // Pricing plans
  ENTREPRENEUR_PRICING: {
    displayName: "Essentials",
    monthly: { pay: 199, plan: "ENTREPRENEUR_PRICING__MONTHLY" },
    annual: { pay: 2149.2, save: 238.8, plan: "ENTREPRENEUR_PRICING__ANNUAL" },
    features: "20 Optimized Prices",
    annualSales: "$120k to $360k",
  },
  BUSINESS_PRICING: {
    displayName: "Starter",
    monthly: { pay: 299, plan: "BUSINESS_PRICING__MONTHLY" },
    annual: { pay: 3229.2, save: 358.8, plan: "BUSINESS_PRICING__ANNUAL" },
    features: "40 Optimized Prices",
    annualSales: "$120k to $360k",
  },
  PROFESSIONAL_PRICING: {
    displayName: "Premium",
    monthly: { pay: 499, plan: "PROFESSIONAL_PRICING__MONTHLY" },
    annual: { pay: 5389.2, save: 598.8, plan: "PROFESSIONAL_PRICING__ANNUAL" },
    features: "100 Optimized Prices",
    annualSales: " $360k to $1M",
  },
  ENTERPRISE_PRICING: {
    displayName: "Enterprise",
    monthly: { pay: 999, plan: "ENTERPRISE_PRICING__MONTHLY" },
    annual: { pay: 10789.2, save: 1198.8, plan: "ENTERPRISE_PRICING__ANNUAL" },
    features: "Unlimited Optimized Prices",
    annualSales: "$1M to $3M",
  },
  CORPORATE_PRICING: {
    displayName: "Custom",
    monthly: { pay: 0, plan: "CORPORATE_PRICING__MONTHLY" },
    annual: { pay: 0, save: 1198.8, plan: "CORPORATE_PRICING__ANNUAL" },
    features: "Features to be determined.",
    annualSales: "Above $3M",
  },
  // Search analytics plans
  FREE_ANALYTICS: {
    displayName: "Free",
    monthly: { pay: 0, plan: "FREE_ANALYTICS__MONTHLY" },
    annual: { pay: 0, save: 0, plan: "FREE_ANALYTICS__ANNUAL" },
    features: "2 active shelves",
    annualSales: "Less than $120k",
  },
  ENTREPRENEUR_ANALYTICS: {
    displayName: "Essentials",
    monthly: { pay: 49, plan: "ENTREPRENEUR_ANALYTICS__MONTHLY" },
    annual: { pay: 529.2, save: 58.8, plan: "ENTREPRENEUR_ANALYTICS__ANNUAL" },
    features: "5 active shelves",
    annualSales: "Up to $120k",
  },
  PROFESSIONAL_ANALYTICS: {
    displayName: "Premium",
    monthly: { pay: 99, plan: "PROFESSIONAL_ANALYTICS__MONTHLY" },
    annual: {
      pay: 1069.2,
      save: 118.8,
      plan: "PROFESSIONAL_ANALYTICS__ANNUAL",
    },
    features: "15 active shelves",
    annualSales: " $360k to $1M",
  },
  ENTERPRISE_ANALYTICS: {
    displayName: "Enterprise",
    monthly: { pay: 199, plan: "ENTERPRISE_ANALYTICS__MONTHLY" },
    annual: {
      pay: 2149.2,
      save: 238.8,
      plan: "ENTERPRISE_ANALYTICS__ANNUAL",
    },
    features: "40 active shelves",
    annualSales: "$1M to $3M",
  },
  // Content
  STANDARD_CONTENT: {
    displayName: "Product Content",
    monthly: { pay: 49.99, plan: "STANDARD_CONTENT__MONTHLY" },
    annual: {
      pay: 538.88,
      save: 60.0,
      plan: "STANDARD_CONTENT__ANNUAL",
    },
    features: "All Products",
    annualSales: "n/a",
  },
  // Purchase Behavior
  STANDARD_PURCHASE_BEHAVIOR: {
    displayName: "Purchase Behavior",
    monthly: { pay: 49.99, plan: "STANDARD_PURCHASE_BEHAVIOR__MONTHLY" },
    annual: {
      pay: 538.88,
      save: 60.0,
      plan: "STANDARD_PURCHASE_BEHAVIOR__ANNUAL",
    },
    features: "All Products",
    annualSales: "n/a",
  },
};
