import { GET_ERRORS, SET_ERRORS, CLEAR_ERRORS } from "../types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      let newstate = [...state];
      newstate.push(action.payload);
      return newstate;
    case CLEAR_ERRORS:
      return [];
    case GET_ERRORS:
      return action.payload; //remove this when refactoring is completed.
    default:
      return state;
  }
}
