import React, { useState, useMemo, useEffect, useRef } from "react";
import { Row, Col, Table } from "react-bootstrap";
import Checkbox from "components/core/basic/CheckBox";
import FormGroupTile from "components/core/basic/FormGroupTile";
import FormGroupLabel from "components/core/basic/FormGroupLabel";
import {
  MARKETPLACE_CURRENCY_DENOMINATION,
  PRODUCT_AMAZON_LINKS,
} from "utils/marketplaceConstants";
import PlanSlider from "../components/PricingSlider";
import { useFetch } from "hooks/api";
import Loading from "components/core/blocks/Loading";
import { LogoutButton } from "components/core/basic/Button.jsx";
import { formatCurrency, formatNumber } from "utils/formatNumber";
import api from "utils/api";
import { cut } from "utils/formatText";
import { sendToastNotification } from "utils/sendToastNotification";
import NotificationSystem from "react-notification-system";
import { useQueryClient } from "react-query";
import { SLIDER_HELP } from "../components/SliderHelp";
import moment from "moment";
import { average } from "utils/math";
import { TbPencilCog } from "react-icons/tb";
import { trellisPalette } from "components/custom/analytics/palettes";

const ConfigurationSection = ({ pricingPlan, marketPlace, modules }) => {
  const notificationRef = useRef(true);

  const actionTypeOptions = [
    { label: `Match their price`, value: "match" },
    { label: `Beat their price`, value: "decrease" },
    { label: `Premium over their price`, value: "increase" },
  ];
  let strategyOptions = [
    { label: `Dynamic Pricing`, value: "dynamic" },
    {
      label: `Best Seller Rank Optimization`,
      value: "bsr",
      isDisabled: false,
    },
    {
      label: `Competitor Price Matching`,
      value: "competitor",
      isDisabled: false,
    },
    {
      label: `Rules Engine`,
      value: "rules_engine",
      isDisabled: false,
    },
  ];

  const getPlanMode = (planMode) => {
    if (planMode < -2) {
      return -2;
    } else if (planMode > 2) {
      return 2;
    }

    return planMode;
  };

  const [planName, setPlanName] = useState(pricingPlan?.plan_name || "");
  const [validationErrors, setValidationErrors] = useState({});
  const [minPrice, setMinPrice] = useState(pricingPlan?.min_price);
  const [maxPrice, setMaxPrice] = useState(pricingPlan?.max_price);
  const [overrideVolume, setOverrideVolume] = useState(
    pricingPlan?.override_units
  );
  const [overrideMargin, setOverrideMargin] = useState(
    pricingPlan?.override_margin
  );
  const [overrideVolumeValue, setOverrideVolumeValue] = useState(
    parseInt(pricingPlan?.target_units * 30)
  );
  const [overrideMarginValue, setOverrideMarginValue] = useState(
    pricingPlan?.target_margin * 100
  );
  const [overrideSupply, setOverrideSupply] = useState(
    pricingPlan?.override_supply
  );
  const [overrideSupplyValue, setOverrideSupplyValue] = useState(
    pricingPlan?.target_supply
  );
  const [targetBSR, setTargetBSR] = useState(pricingPlan?.target_bsr || 1);
  const [currentBSR, setCurrentBSR] = useState(pricingPlan?.target_bsr || 1);
  const [productList, setProductList] = useState([]);

  const [planMode, setPlanMode] = useState(
    getPlanMode(pricingPlan?.configured_slider_value)
  );
  const [planStrategy, setPlanStrategy] = useState(strategyOptions[0]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [competitorList, setCompetitorList] = useState([]);
  const [chosenCompetitor, setChosenCompetitor] = useState(null);
  const [competitorAction, setCompetitorAction] = useState(
    actionTypeOptions[0]
  );
  const [competitorValue, setCompetitorValue] = useState(null);
  const [competitorUnit, setCompetitorUnit] = useState("percent");

  const end = moment(moment().format("YYYY-MM-DD")).subtract(1, "days");
  const start = moment(end.format("YYYY-MM-DD")).subtract(30, "days");
  const preEnd = start.clone().subtract(1, "days");
  const preStart = moment(preEnd.format("YYYY-MM-DD")).subtract(30, "days");
  const filters = {
    mp: marketPlace.marketPlace,
    date_group: "day",
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "product_id",
  };

  const { data: productData, isLoading: productIsLoading } = useFetch(
    ["product", marketPlace.marketPlace, productList],
    `/api/data_report/product_report?filters=product_ids:${productList.join(
      ","
    )}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data, isLoading } = useFetch(
    ["pricingPlanProducts", marketPlace.marketPlace],
    `/pricing/pricing_plan_products?pricing_plan_id=${pricingPlan.id}`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { expectedData, disableEnroll, amazonCategory } = useMemo(() => {
    const ex = {
      floor_units: 0,
      ceiling_units: 0,
      floor_sales: 0,
      ceiling_sales: 0,
      floor_price: 0,
      ceiling_price: 0,

      profit: 0,
      margin: 0,
      turnover: 0,
    };

    if (productIsLoading || productList.length === 0) {
      return {
        expectedData: ex,
        disableEnroll: true,
        amazonCategory: "",
        targetMargin: 0,
        targetVolume: 0,
      };
    }
    let categoryName = data[0].product.amazon_category;
    let bsr = "";
    let prices = [];
    let history = [];

    for (let i = 0; i < productData.date.length; i++) {
      history.push(productData.date[i].total_units);
    }
    for (let i = 0; i < productData.products.length; i++) {
      prices.push(productData.products[i].product_price);
      if (bsr === "" && productData.products[i].bsr_rank) {
        bsr = parseInt(productData.products[i].bsr_rank);
      }
    }

    let thirtyDayUnits = parseInt(pricingPlan?.target_units * 30);
    let unitsMod = thirtyDayUnits;

    let price = average(prices);
    let priceMod = average(prices);

    if (planMode === -2) {
      priceMod = parseFloat((price - price * 0.05).toFixed(2));
      unitsMod = parseInt(thirtyDayUnits * 1.2);
      ex.floor_units = thirtyDayUnits;
      ex.ceiling_units = unitsMod;
    } else if (planMode === -1) {
      unitsMod = parseInt(thirtyDayUnits * 1.1);
      priceMod = parseFloat((price - price * 0.03).toFixed(2));
      ex.floor_units = thirtyDayUnits;
      ex.ceiling_units = unitsMod;
    } else if (planMode === 1) {
      unitsMod = parseInt(thirtyDayUnits * 0.9);
      priceMod = parseFloat((price + price * 0.03).toFixed(2));
      ex.floor_units = unitsMod;
      ex.ceiling_units = thirtyDayUnits;
    } else if (planMode === 2) {
      unitsMod = parseInt(thirtyDayUnits * 0.8);
      priceMod = parseFloat((price + price * 0.05).toFixed(2));
      ex.floor_units = unitsMod;
      ex.ceiling_units = thirtyDayUnits;
    } else {
      priceMod = parseFloat((price + price * 0.01).toFixed(2));
      ex.floor_units = thirtyDayUnits;
      ex.ceiling_units = thirtyDayUnits;
    }

    ex.floor_sales = ex.floor_units * Math.min(priceMod, price);
    ex.floor_price = Math.min(priceMod, price);
    ex.ceiling_sales = ex.ceiling_units * Math.max(priceMod, price);
    ex.ceiling_price = Math.max(priceMod, price);

    return {
      expectedData: ex,
      disableEnroll: false,
      amazonCategory: categoryName,
      overrideMargin,
      overrideVolume,
    };
  }, [
    productList,
    productIsLoading,
    productData,
    planMode,
    pricingPlan,
    overrideMargin,
    overrideVolume,
    data,
  ]);

  useEffect(() => {
    if (!isLoading) {
      const pl = [];
      for (let i = 0; i < data?.length; i++) {
        const element = data[i];
        pl.push(element.product.id);
      }
      setProductList(pl);

      if (pl.length === 1) {
        strategyOptions[1].isDisabled = true;
      }

      if (pricingPlan?.current_program?.template_type === "BSR") {
        setTargetBSR(pricingPlan.target_bsr);
        setPlanStrategy(strategyOptions[1]);
      } else if (pricingPlan?.plan_type === "competitor") {
        setChosenCompetitor(pricingPlan?.competitor_rules[0]?.asin);
        setCompetitorValue(pricingPlan?.competitor_rules[0]?.value);
        setCompetitorUnit(pricingPlan?.competitor_rules[0]?.unit);
        if (pricingPlan?.competitor_rules[0]?.action === "match") {
          setCompetitorAction(actionTypeOptions[0]);
        } else if (pricingPlan?.competitor_rules[0]?.action === "decrease") {
          setCompetitorAction(actionTypeOptions[1]);
        } else if (pricingPlan?.competitor_rules[0]?.action === "increase") {
          setCompetitorAction(actionTypeOptions[2]);
        }
        setPlanStrategy(strategyOptions[2]);
      } else {
        if (pricingPlan?.is_auto) {
          setPlanStrategy(strategyOptions[0]);
        } else {
          setPlanStrategy(strategyOptions[3]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, planMode, isLoading]);

  const getBSR = async (productId) => {
    const res = await api.get(`/pricing/bsr?product_id=${productId}`);

    setCurrentBSR(res.data?.ranks);
    return res.data?.ranks;
  };

  useEffect(() => {
    if (productList.length > 0) {
      getBSR(productList[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  const { data: competitors, loading: loadingCompetitors } = useFetch(
    "competitors",
    "/api/competitors",
    {
      marketplace: marketPlace.marketPlace,
      product: productList[0],
    },
    { enabled: productList.length > 0 }
  );

  const asinList = useMemo(() => {
    if (competitors?.data?.results) {
      return competitors?.data?.results?.map((c) => c.product_ref);
    }

    strategyOptions[2].isDisabled = true;

    return [];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitors]);

  useEffect(() => {
    if (asinList && asinList.length > 0 && !loadingCompetitors) {
      const getPrices = async () => {
        const res = await api.post("/merchandise/competitive_price", {
          params: {
            asinList: asinList.join(","),
            marketplace: marketPlace.marketPlace,
          },
        });

        const prices = res.data.prices;
        const cl = [...competitors.data.results];
        for (let i = 0; i < prices.length; i++) {
          for (let j = 0; j < cl.length; j++) {
            if (cl[j].product_ref === prices[i].asin) {
              cl[j].price = prices[i].price;
              break;
            }
          }
        }

        setCompetitorList(cl);
      };

      getPrices();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asinList]);

  const queryClient = useQueryClient();

  const updatePlan = async () => {
    let errors = {};
    if (planName.length === 0) {
      errors.planName = "Please enter plan name";
    }
    if (minPrice === null || minPrice === "" || parseFloat(minPrice) <= 0) {
      errors.minPrice = "Please enter valid minimum price";
    }
    if (maxPrice === null || maxPrice === "" || parseFloat(maxPrice) <= 0) {
      errors.maxPrice = "Please enter valid maximum price";
    }
    if (minPrice && maxPrice && parseFloat(minPrice) === parseFloat(maxPrice)) {
      errors.maxPrice = "Maximum price should be greater than minimum price";
    }
    if (planStrategy.value === "bsr") {
      if (
        targetBSR === null ||
        targetBSR === "" ||
        parseFloat(targetBSR) <= 0
      ) {
        errors.targetBSR = "Please enter valid target BSR";
      }
    }

    if (planStrategy.value === "competitor") {
      if (competitorAction.value !== "match") {
        if (
          competitorValue === null ||
          competitorValue === "" ||
          parseFloat(competitorValue) <= 0
        ) {
          errors.competitorValue = "Please enter valid value";
        }
      }

      if (chosenCompetitor === null) {
        errors.chosenCompetitor = "Please select competitor";
      }
    }

    if (
      overrideVolume &&
      (!overrideVolumeValue || parseFloat(overrideVolumeValue) <= 0)
    ) {
      errors.targetVolume = "Please enter valid target volume";
    }
    if (
      overrideMargin &&
      (!overrideMarginValue || parseFloat(overrideMarginValue) <= 0)
    ) {
      errors.targetMargin = "Please enter valid target margin";
    }
    if (
      overrideSupply &&
      (!overrideSupplyValue || parseFloat(overrideSupplyValue) <= 0)
    ) {
      errors.supply = "Please enter valid supply target";
    }

    if (Object.keys(errors).length === 0) {
      let planType = null;
      if (planStrategy.value === "competitor") {
        planType = "competitor";
      } else if (planStrategy.value === "bsr") {
        planType = "bsr";
      } else {
        planType = "rules_engine";
      }

      const payload = {
        plan_id: pricingPlan.id,
        name: planName,
        plan_type: planType,
        target_bsr: targetBSR,
        min_price: minPrice,
        max_price: maxPrice,
        plan_mode: planMode,
        react_buy_box: false,
        market_place: marketPlace.marketPlace,
        num_parents: 1,
        product_list: productList.join(","),
        category_id: pricingPlan.category,
        profiling: false,
        competitor_value: competitorValue,
        competitor_unit: competitorUnit,
        competitor_action: competitorAction.value,
        competitor_asin: chosenCompetitor,
        global_rollback: true,
        override_volume_value: overrideVolumeValue,
        override_volume: overrideVolume,
        override_margin_value:
          overrideMarginValue > 1
            ? overrideMarginValue / 100
            : overrideMarginValue,
        override_margin: overrideMargin,
        override_supply: overrideSupply,
        override_supply_value: overrideSupplyValue,
      };

      setIsUpdating(true);
      sendToastNotification(
        notificationRef,
        "success",
        <Row>
          <Col>
            <div className="pb-3">
              The Pricing Plan was successfully updated.
            </div>
            <div>
              Any price changes will be reflected in approximately 15 minutes.
            </div>
          </Col>
        </Row>
      );
      await api.post("/pricing/pricingplan", { plan_options: payload });
      queryClient.invalidateQueries("pricing_plans");
      setIsUpdating(false);
    }

    setValidationErrors(errors);
  };

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  const updateButtonStatus = () => {
    if (disableEnroll) {
      return true;
    }
    if (productList === 0) {
      return true;
    }
    if (isUpdating) {
      return true;
    }
    if (currentBSR === null) {
      strategyOptions[1].isDisabled = true;
    }
    if (asinList.length === 0 && planStrategy.value === "competitor") {
      return true;
    }

    return false;
  };

  const showPricingStrategy = () => {
    if (isLoading) {
      return false;
    }

    if (loadingCompetitors) {
      return false;
    }

    if (planStrategy.value === "competitor" && competitorList.length === 0) {
      return false;
    }

    if (planStrategy.value === "rules_engine" && !pricingPlan.is_auto) {
      return false;
    }

    if (overrideMargin || overrideVolume) {
      return false;
    }

    return true;
  };

  const showPricingStrategyOverride = () => {
    if (isLoading) {
      return false;
    }

    if (loadingCompetitors) {
      return false;
    }

    if (planStrategy.value === "competitor" && competitorList.length === 0) {
      return false;
    }

    if (planStrategy.value === "rules_engine" && !pricingPlan.is_auto) {
      return false;
    }

    if (overrideMargin || overrideVolume) {
      return true;
    }

    return false;
  };

  const showPricingSupplyOverride = () => {
    if (isLoading) {
      return false;
    }

    if (loadingCompetitors) {
      return false;
    }

    if (planStrategy.value === "competitor" && competitorList.length === 0) {
      return false;
    }

    if (planStrategy.value === "rules_engine" && !pricingPlan.is_auto) {
      return false;
    }

    if (overrideSupply) {
      return true;
    }

    return false;
  };

  return (
    <Row className="p-3">
      <NotificationSystem ref={notificationRef} />
      <Col xs={12} className="pb-5 text-start">
        <Row className="fs-4 text-left pb-3 px-5">
          <Col xs={4} className="ps-3">
            <span style={{ color: "#000" }}>Plan Name:</span>
            <Row className="pt-2 pb-4">
              <Col xs={12}>
                <FormGroupTile
                  formControlClass="text-left"
                  type="formcontrol"
                  formControlType={"text"}
                  prefixSide={"left"}
                  handleChildFormElement={(key, value) => {
                    setPlanName(value);
                  }}
                  placeholder={`Plan Name`}
                  defaultValue={planName}
                  style={{
                    border: validationErrors["planName"]
                      ? "1px solid red"
                      : null,
                  }}
                />
                {validationErrors["planName"] && (
                  <span style={{ color: "red" }}>
                    <small>{validationErrors["planName"]}</small>
                  </span>
                )}
              </Col>
            </Row>
          </Col>

          <Col xs={4} className="ps-3">
            <span style={{ color: "#000" }}>Plan Options:</span>
            <Row className="pb-4">
              <Col>
                <FormGroupTile
                  type="select"
                  multi={false}
                  stateName={"strategyType"}
                  handleChildFormElement={(key, value) => {
                    setPlanStrategy(value);
                  }}
                  defaultValue={planStrategy}
                  options={strategyOptions}
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="fs-4 text-left py-3 px-5">
          <Col xs={12} className="ps-3">
            <Row>
              {/*  Min Price */}
              <Col xs={3} className="ps-3">
                <span
                  style={{
                    color: "#000",
                  }}
                >
                  Mininum Price
                </span>
                <Row className="pt-2 pb-4">
                  <Col xs={12}>
                    <FormGroupTile
                      formControlClass="text-center"
                      type="formcontrol"
                      formControlType={"numeric"}
                      prefixSide={"left"}
                      handleChildFormElement={(key, value) => {
                        setMinPrice(value);
                      }}
                      prefix={
                        <div
                          className="fs-3"
                          style={{ position: "absolute", top: "-18px" }}
                        >
                          {
                            MARKETPLACE_CURRENCY_DENOMINATION[
                              marketPlace.marketPlace
                            ]
                          }
                        </div>
                      }
                      placeholder={`Minimum Price`}
                      defaultValue={
                        pricingPlan.plan_type === "same_strategy"
                          ? null
                          : minPrice
                      }
                      disabled={pricingPlan.plan_type === "same_strategy"}
                      style={{
                        border:
                          validationErrors["minPrice"] &&
                          pricingPlan.plan_type !== "same_strategy"
                            ? "1px solid red"
                            : null,
                      }}
                    />

                    {validationErrors["minPrice"] &&
                      pricingPlan.plan_type !== "same_strategy" && (
                        <Row>
                          <Col xs={12}>
                            <span style={{ color: "red" }}>
                              <small>{validationErrors["minPrice"]}</small>
                            </span>
                          </Col>
                        </Row>
                      )}
                  </Col>
                </Row>
              </Col>

              {/*  Max Price */}
              <Col xs={3} className="ps-3">
                <span
                  style={{
                    color: "#000",
                  }}
                >
                  Maximum Price
                </span>
                <Row className="pt-2 pb-4">
                  <Col xs={12}>
                    <FormGroupTile
                      formControlClass="text-center"
                      type="formcontrol"
                      formControlType={"numeric"}
                      prefixSide={"left"}
                      handleChildFormElement={(key, value) => {
                        setMaxPrice(value);
                      }}
                      prefix={
                        <div
                          className="fs-3"
                          style={{ position: "absolute", top: "-18px" }}
                        >
                          {
                            MARKETPLACE_CURRENCY_DENOMINATION[
                              marketPlace.marketPlace
                            ]
                          }
                        </div>
                      }
                      placeholder={`Maximum Price`}
                      defaultValue={
                        pricingPlan.plan_type === "same_strategy"
                          ? null
                          : maxPrice
                      }
                      disabled={pricingPlan.plan_type === "same_strategy"}
                      style={{
                        border:
                          validationErrors["maxPrice"] &&
                          pricingPlan.plan_type !== "same_strategy"
                            ? "1px solid red"
                            : null,
                      }}
                    />
                    {validationErrors["maxPrice"] &&
                      pricingPlan.plan_type !== "same_strategy" && (
                        <Row>
                          <Col xs={12} className="text-start">
                            <span style={{ color: "red" }}>
                              <small>{validationErrors["maxPrice"]}</small>
                            </span>
                          </Col>
                        </Row>
                      )}
                  </Col>
                </Row>
              </Col>

              {/* Days of Supply */}
              <Col xs={3} className="ps-3">
                <span
                  style={{
                    color: "#000",
                  }}
                >
                  Days To Inventory Depletion
                </span>
                <Row className="pt-2 pb-4 text-center">
                  <Col xs={12}>
                    <div
                      style={{
                        backgroundColor: "#eee",
                        border: "1px solid #e3e3e3",
                        borderRadius: "45px",
                        color: "#850066",
                        padding: "8px 12px",
                        height: "40px",
                        boxshadow: "none",
                      }}
                    >
                      {formatNumber(pricingPlan?.days_of_supply, null, 0)}
                    </div>
                  </Col>
                </Row>
              </Col>

              {/* Low Inv. Threshold */}
              <Col xs={3} className="ps-3">
                <span
                  style={{
                    color: "#000",
                  }}
                >
                  {/* Supply Target Optimizer */}
                  {/* Stock Preservation Days Limit */}
                  Low Inv. Threshold (Days)
                  <span
                    className="ps-2"
                    style={{
                      cursor: "pointer",
                      color: trellisPalette[0],
                    }}
                    onClick={() => {
                      setOverrideSupply(!overrideSupply);
                      setPlanMode(pricingPlan?.configured_slider_value);
                    }}
                  >
                    <TbPencilCog />
                  </span>
                </span>
                <Row className="pt-2 pb-4">
                  <Col xs={12}>
                    {overrideSupply && (
                      <>
                        <FormGroupTile
                          formControlClass="text-center"
                          type="formcontrol"
                          formControlType={"numeric"}
                          prefixSide={"left"}
                          handleChildFormElement={(key, value) => {
                            if (value) {
                              setOverrideSupplyValue(parseFloat(value));
                            } else {
                              setOverrideSupplyValue(0);
                            }
                          }}
                          placeholder={`Supply Target`}
                          defaultValue={
                            overrideSupplyValue > 0
                              ? overrideSupplyValue.toFixed(0)
                              : ""
                          }
                          disabled={false}
                          style={{
                            border: validationErrors["supply"]
                              ? "1px solid red"
                              : null,
                          }}
                        />

                        {validationErrors["supply"] && (
                          <Row>
                            <Col xs={12}>
                              <span style={{ color: "red" }}>
                                <small>{validationErrors["supply"]}</small>
                              </span>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}

                    {!overrideSupply && (
                      <Row>
                        <Col xs={12} className="text-center">
                          <div
                            style={{
                              backgroundColor: "#eee",
                              border: "1px solid #e3e3e3",
                              borderRadius: "45px",
                              color: "#850066",
                              padding: "8px 12px",
                              height: "40px",
                              boxshadow: "none",
                            }}
                          >
                            -
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          className="fs-4 text-left py-3 px-5"
          style={{
            borderTop: "1px solid #ccc",
          }}
        >
          <Row className="fs-4 text-left py-3 px-3">
            <Col>
              <span className="fw-bold">Pricing Strategy</span>
            </Col>
          </Row>

          {planStrategy?.value === "bsr" && (
            <Row className="fs-5 text-center pb-1 px-3">
              <Col xs={4}>
                <div className="py-3">Category</div>
                <div>
                  <FormGroupTile
                    formControlClass="text-center"
                    type="formcontrol"
                    formControlType={"center"}
                    prefixSide={"left"}
                    placeholder={`Amazon Category`}
                    defaultValue={amazonCategory}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div className="py-3">Current BSR</div>
                <div>
                  <FormGroupTile
                    formControlClass="text-center"
                    type="formcontrol"
                    formControlType={"center"}
                    prefixSide={"left"}
                    placeholder={`Current BSR`}
                    defaultValue={
                      currentBSR == null
                        ? `Loading...`
                        : currentBSR
                        ? formatNumber(currentBSR, null, 0)
                        : 0
                    }
                    disabled={true}
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div className="py-3">Target BSR</div>
                <FormGroupTile
                  formControlClass="text-center"
                  type="formcontrol"
                  formControlType={"center"}
                  prefixSide={"left"}
                  handleChildFormElement={(key, value) => {
                    setTargetBSR(value);
                  }}
                  placeholder={`Target BSR`}
                  defaultValue={
                    targetBSR ? formatNumber(targetBSR, null, 0) : 0
                  }
                  style={{
                    border: validationErrors["targetBSR"]
                      ? "1px solid red"
                      : null,
                  }}
                />

                {validationErrors["targetBSR"] && (
                  <Row>
                    <Col xs={12}>
                      <span style={{ color: "red" }}>
                        <small>{validationErrors["targetBSR"]}</small>
                      </span>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}

          {planStrategy?.value === "competitor" && (
            <>
              <Row
                className="fs-4 text-left pt-5 pb-3 px-3"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Col>
                  <div className="fw-bold">Competitor Tracking</div>
                </Col>
                <Col>
                  {validationErrors["chosenCompetitor"] && (
                    <div style={{ color: "red" }}>
                      <small>{validationErrors["chosenCompetitor"]}</small>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="fs-4 text-center pb-1 px-5">
                <Col>
                  {asinList.length > 0 && competitorList.length === 0 && (
                    <div className="text-center">
                      <span className="fw-bold pb-3">
                        Competitors are loading
                      </span>
                    </div>
                  )}
                  {asinList.length === 0 && (
                    <div className="text-center">
                      <span className="fw-bold pb-3">
                        You have no competitors to price match
                      </span>
                    </div>
                  )}
                  {competitorList.length > 0 && (
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th className="text-start">SKU</th>
                          <th className="text-start">Title</th>
                          <th className="text-end">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {competitors?.data?.results?.map(
                          (competitor, index) => {
                            if (
                              !chosenCompetitor ||
                              chosenCompetitor === competitor.product_ref
                            ) {
                              return (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={
                                        chosenCompetitor ===
                                        competitor.product_ref
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setChosenCompetitor(
                                            competitor.product_ref
                                          );
                                        } else {
                                          setChosenCompetitor(null);
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-start">
                                    <a
                                      href={`${
                                        PRODUCT_AMAZON_LINKS[
                                          marketPlace.marketPlace
                                        ]
                                      }${competitor.product_ref}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {competitor.product_ref}
                                    </a>
                                  </td>
                                  <td className="text-start">
                                    {cut(competitor.title, 60)}
                                  </td>
                                  <td className="text-end">
                                    {formatCurrency(
                                      competitor.price,
                                      marketPlace.marketPlace
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                            return null;
                          }
                        )}
                      </tbody>
                    </Table>
                  )}

                  {chosenCompetitor && (
                    <Row
                      className="pb-3 pt-3 mt-3"
                      style={{
                        borderTop: "1px solid #ccc",
                      }}
                    >
                      <Col xs={4} className="text-start">
                        <FormGroupTile
                          label={"I want my price to"}
                          type="select"
                          multi={false}
                          stateName={"actionType"}
                          handleChildFormElement={(key, value) => {
                            setCompetitorAction(value);
                          }}
                          defaultValue={competitorAction}
                          options={actionTypeOptions}
                        />
                      </Col>
                      <Col xs={4} className="text-center">
                        <FormGroupTile
                          label={`by this ${
                            competitorUnit === "percent"
                              ? "percentage"
                              : "amount"
                          } ${
                            competitorAction.value === "decrease"
                              ? " or less"
                              : " or more"
                          }`}
                          formControlClass="text-center"
                          type="formcontrol"
                          formControlType={"number"}
                          prefixSide={"left"}
                          disabled={competitorAction.value === "match"}
                          defaultValue={competitorValue ? competitorValue : 0}
                          handleChildFormElement={(key, value) => {
                            setCompetitorValue(value);
                          }}
                        />

                        {validationErrors["competitorValue"] && (
                          <span style={{ color: "red" }}>
                            <small>{validationErrors["competitorValue"]}</small>
                          </span>
                        )}
                      </Col>
                      <Col xs={4}>
                        <FormGroupLabel
                          label={"Action Unit"}
                          titleCase={true}
                        />
                        <Row className="pb-2">
                          <Col xs={3}></Col>
                          <Col xs={6}>
                            <div className="d-flex py-3 align-items-center justify-content-between">
                              <Checkbox
                                inline
                                number={`adPlanBulkActionUnit-percent`}
                                label="%"
                                checked={competitorUnit === "percent"}
                                onClick={() => {
                                  setCompetitorUnit("percent");
                                }}
                                disabled={competitorAction.value === "match"}
                              />

                              <Checkbox
                                inline
                                number={`adPlanBulkActionUnit-currency`}
                                label="$"
                                checked={competitorUnit === "currency"}
                                onClick={() => {
                                  setCompetitorUnit("currency");
                                }}
                                disabled={competitorAction.value === "match"}
                              />
                            </div>
                          </Col>
                          <Col xs={3}></Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}

          {planStrategy?.value === "dynamic" && (
            <Row>
              <Col xs={3}>
                {pricingPlan.plan_type !== "same_strategy" && (
                  <Row>
                    <Col xs={12} className="ps-3">
                      <span
                        style={{
                          color: "#000",
                        }}
                      >
                        Target Volume (Monthly)
                        <span
                          className="ps-2"
                          style={{
                            cursor: !overrideMargin ? "pointer" : "inherit",
                            color: trellisPalette[0],
                          }}
                          onClick={() => {
                            if (overrideMargin) {
                              return false;
                            }
                            setOverrideVolume(!overrideVolume);
                            setPlanMode(pricingPlan?.configured_slider_value);
                          }}
                        >
                          <TbPencilCog />
                        </span>
                      </span>
                      <Row className="pt-2 pb-4">
                        <Col xs={12}>
                          {overrideVolume && (
                            <>
                              <FormGroupTile
                                formControlClass="text-center"
                                type="formcontrol"
                                formControlType={"numeric"}
                                prefixSide={"left"}
                                handleChildFormElement={(key, value) => {
                                  const val = parseInt(value);
                                  if (val) {
                                    const originalUnits =
                                      pricingPlan?.target_units * 30;

                                    if (val < originalUnits) {
                                      setPlanMode(2);
                                    } else if (val > originalUnits) {
                                      setPlanMode(-2);
                                    } else {
                                      setPlanMode(0);
                                    }
                                    setOverrideVolumeValue(val);
                                  }
                                }}
                                placeholder={`Target Volume`}
                                defaultValue={overrideVolumeValue}
                                disabled={false}
                                style={{
                                  border: validationErrors["targetVolume"]
                                    ? "1px solid red"
                                    : null,
                                }}
                              />

                              {validationErrors["targetVolume"] && (
                                <Row>
                                  <Col xs={12}>
                                    <span style={{ color: "red" }}>
                                      <small>
                                        {validationErrors["targetVolume"]}
                                      </small>
                                    </span>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                          {!overrideVolume && (
                            <Row>
                              <Col xs={12} className="text-center">
                                <div
                                  style={{
                                    backgroundColor: "#eee",
                                    border: "1px solid #e3e3e3",
                                    borderRadius: "45px",
                                    color: "#850066",
                                    padding: "8px 12px",
                                    height: "40px",
                                    boxshadow: "none",
                                  }}
                                >
                                  {/* {formatNumber(targetVolume, null, 0)} */}-
                                </div>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} className="ps-3">
                      <span
                        style={{
                          color: "#000",
                        }}
                      >
                        Target Margin (%)
                        <span
                          className="ps-2"
                          style={{
                            cursor: !overrideVolume ? "pointer" : "inherit",
                            color: trellisPalette[0],
                          }}
                          onClick={() => {
                            if (overrideVolume) {
                              return false;
                            }
                            setOverrideMargin(!overrideMargin);
                            setPlanMode(pricingPlan?.configured_slider_value);
                          }}
                        >
                          <TbPencilCog />
                        </span>
                      </span>
                      <Row className="pt-2 pb-4">
                        <Col xs={12}>
                          {overrideMargin && (
                            <>
                              <FormGroupTile
                                formControlClass="text-center"
                                type="formcontrol"
                                formControlType={"numeric"}
                                prefixSide={"left"}
                                handleChildFormElement={(key, value) => {
                                  const val = parseFloat(value);
                                  if (val) {
                                    const originalMargin =
                                      pricingPlan?.target_margin;
                                    if (val < originalMargin) {
                                      setPlanMode(-2);
                                    } else if (val > originalMargin) {
                                      setPlanMode(2);
                                    } else {
                                      setPlanMode(0);
                                    }
                                    setOverrideMarginValue(val);
                                  } else {
                                    setOverrideMarginValue(0);
                                  }
                                }}
                                placeholder={`Target Margin`}
                                defaultValue={overrideMarginValue.toFixed(0)}
                                disabled={false}
                                style={{
                                  border: validationErrors["targetMargin"]
                                    ? "1px solid red"
                                    : null,
                                }}
                              />

                              {validationErrors["targetMargin"] && (
                                <Row>
                                  <Col xs={12}>
                                    <span style={{ color: "red" }}>
                                      <small>
                                        {validationErrors["targetMargin"]}
                                      </small>
                                    </span>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}

                          {!overrideMargin && (
                            <Row>
                              <Col xs={12} className="text-center">
                                <div
                                  style={{
                                    backgroundColor: "#eee",
                                    border: "1px solid #e3e3e3",
                                    borderRadius: "45px",
                                    color: "#850066",
                                    padding: "8px 12px",
                                    height: "40px",
                                    boxshadow: "none",
                                  }}
                                >
                                  -
                                </div>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} className="text-start"></Col>
                  </Row>
                )}
              </Col>
              <Col xs={9}>
                <Row className="fs-4 text-left py-3 px-5">
                  <Col xs={12}>
                    {showPricingSupplyOverride() && (
                      <>
                        <Row className="fs-5 text-center pb-1 px-3">
                          <Col xs={12} className="text-start fs-5">
                            <div
                              style={{
                                padding: "2rem",
                                border: "1px solid rgb(238, 238, 238)",
                                borderRadius: "6px",
                                backgroundColor: "rgb(248, 248, 248)",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div>
                                When the Days of Supply drops below your desired
                                number of days, the dynamic pricing engine will
                                automatically increase prices to manage
                                inventory levels. Once the Days of Supply has
                                recovered, prices will adjust back to the
                                optimal or 'right' price to ensure
                                competitiveness and profitability
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {showPricingStrategyOverride() && (
                      <>
                        <Row className="fs-5 text-center pb-1 px-3">
                          <Col xs={12} className="text-start fs-5">
                            <div
                              style={{
                                padding: "2rem",
                                border: "1px solid rgb(238, 238, 238)",
                                borderRadius: "6px",
                                backgroundColor: "rgb(248, 248, 248)",
                                marginTop: "10px",
                              }}
                            >
                              <div>
                                Dynamic Pricing adjusts prices to align with
                                your specified target volume or margin goals.
                                It's important to understand that setting an
                                overly ambitious target volume can impact your
                                profit margins. However, a beneficial side
                                effect might include improvements in your
                                organic ranking and Best Seller Rank.
                              </div>
                              <div className="pt-3">
                                Conversely, pushing for a significantly higher
                                Target Margin could potentially harm your
                                organic ranking and Best Seller Rank. To
                                mitigate this risk while aiming to enhance your
                                Target Margin, we suggest a cautious approach of
                                increasing it by only 1% to 5%.
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {showPricingStrategy() && (
                      <>
                        <Row className="fs-5 text-center pb-1 px-3">
                          <Col xs={12} className="text-start fs-5">
                            <div>
                              Utilize the strategy slider to tailor your pricing
                              model.
                            </div>
                            <Row>
                              <Col xs={12}>
                                <div
                                  style={{
                                    padding: "2rem",
                                    border: "1px solid rgb(238, 238, 238)",
                                    borderRadius: "6px",
                                    backgroundColor: "rgb(248, 248, 248)",
                                    marginTop: "10px",
                                    minHeight: "145px",
                                  }}
                                >
                                  <div className="fs-5 fw-bold pb-2">
                                    Monthly Estimates
                                  </div>

                                  {(productIsLoading ||
                                    productData?.products?.length === 0) && (
                                    <Row className="py-5">
                                      <Col xs={12} className="text-center">
                                        Estimates are loading...
                                      </Col>
                                    </Row>
                                  )}

                                  {!productIsLoading &&
                                    productData?.products?.length > 0 && (
                                      <Row>
                                        <Col xs={3}>Volume:</Col>
                                        <Col xs={9}>
                                          {formatNumber(
                                            expectedData.floor_units
                                          )}

                                          {planMode !== 0 && (
                                            <span>
                                              {" - "}
                                              {formatNumber(
                                                expectedData.ceiling_units
                                              )}
                                            </span>
                                          )}
                                        </Col>
                                        <Col xs={3}>Price:</Col>
                                        <Col xs={9}>
                                          {formatCurrency(
                                            expectedData.floor_price,
                                            marketPlace.marketPlace
                                          )}
                                          {" - "}
                                          {formatCurrency(
                                            expectedData.ceiling_price,
                                            marketPlace.marketPlace
                                          )}
                                        </Col>
                                        <Col xs={3}>Sales:</Col>
                                        <Col xs={9}>
                                          {formatCurrency(
                                            expectedData.floor_sales,
                                            marketPlace.marketPlace
                                          )}
                                          {" - "}
                                          {formatCurrency(
                                            expectedData.ceiling_sales,
                                            marketPlace.marketPlace
                                          )}
                                        </Col>

                                        <Col xs={12} className="pt-2">
                                          <small>
                                            <em>
                                              ** Based on historical 30 day
                                              sales data.
                                            </em>
                                          </small>
                                        </Col>
                                      </Row>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12}>
                                <div
                                  style={{
                                    padding: "2rem",
                                    border: "1px solid rgb(238, 238, 238)",
                                    borderRadius: "6px",
                                    backgroundColor: "rgb(248, 248, 248)",
                                    marginTop: "10px",
                                  }}
                                >
                                  {SLIDER_HELP[planMode + 2].description}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="fs-4 text-left pt-3 pb-5 px-5">
                          <Col xs={12}>
                            <PlanSlider
                              planMode={planMode}
                              setPlanMode={setPlanMode}
                              disabled={overrideMargin || overrideVolume}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <LogoutButton
              key="CloseBtn"
              title={`Update`}
              onClick={() => updatePlan()}
              disabled={updateButtonStatus()}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ConfigurationSection;
