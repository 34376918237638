import React from "react";
import { Badge } from "react-bootstrap";
import EditCell from "./cells/EditCell";
import TrendCell from "./cells/TrendCell";
import ProductCell from "./cells/ProductCell";
import ExpanderCell from "./cells/ExpanderCell";
import isSeller from "./util";
import { trellisPalette } from "../analytics/palettes";

import {
  formatCurrency,
  parseFormattedNumber,
  parseFormattedCurrency,
  parseFormattedPercentage,
  formatPercent,
  formatNumber,
} from "utils/formatNumber";
import {
  generateFilterMarkup,
  generateRanges,
  filterRanges,
} from "utils/rangeDropdownFilter";

const makeCurrencyColumn =
  (data, marketPlace) =>
  (Header, id, width = 80, className = "text-end", accessor = null) => {
    const defaultAccessor = (p) => p[id];
    accessor = accessor || defaultAccessor;
    return {
      Header,
      id,
      accessor,
      width,
      className,
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      filterMethod: (filter, row) => {
        const value = accessor(row._original);
        return filterRanges(
          filter,
          value,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(filter, onChange, id, "range", "currency", {
          ranges: generateRanges(
            data ?? [],
            5,
            id,
            "number",
            marketPlace.marketPlace
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

const makeNumberColumn =
  (data, marketPlace) =>
  (Header, id, width = 80, className = "text-end", accessor = null) => {
    const defaultAccessor = (p) => p[id];
    accessor = accessor || defaultAccessor;
    return {
      Header,
      id,
      accessor,
      width,
      className,
      Cell: ({ value }) => formatNumber(value, {}, 1),
      filterMethod: (filter, row) => {
        const value = accessor(row._original);
        return filterRanges(
          filter,
          value,
          parseFormattedNumber,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(filter, onChange, id, "range", "number", {
          ranges: generateRanges(
            data ?? [],
            5,
            id,
            "number",
            marketPlace.marketPlace
          ),
          marketPlace: marketPlace.marketPlace,
        });
      },
    };
  };

const makePercentColumn = (data, marketPlace) => (Header, id, options) => {
  let {
    checked = true,
    accessor = null,
    width = 80,
    className = "text-end",
  } = options || {};
  const defaultAccessor = (p) => p[id];
  accessor = accessor || defaultAccessor;
  return {
    Header,
    id,
    accessor,
    width,
    className,
    checked,
    Cell: ({ value }) => {
      if (options?.dots) {
        return (
          <>
            <span>{formatPercent(value, 1)} </span>
            <span
              class="fa fa-circle"
              style={{ color: options.dots(value) }}
            ></span>
          </>
        );
      }
      return formatPercent(value, 1);
    },
    filterMethod: (filter, row) => {
      return filterRanges(
        filter,
        accessor(row._original) * 100,
        parseFormattedPercentage,
        marketPlace.marketPlace
      );
    },
    Filter: ({ filter, onChange }) => {
      const ranges = generateRanges(
        data.map((d) => ({ ...d, [id]: d[id] * 100 })) ?? [],
        5,
        id,
        "number",
        marketPlace.marketPlace,
        true
      );
      return generateFilterMarkup(filter, onChange, id, "range", "percent", {
        ranges,
      });
    },
  };
};

const tableColumns = (
  isExpanded,
  setIsExpanded,
  marketPlace,
  colToggle,
  data,
  onOrgPlanProductChange,
  plan
) => {
  const CurrencyColumn = makeCurrencyColumn(data, marketPlace);
  const NumberColumn = makeNumberColumn(data, marketPlace);
  const PercentColumn = makePercentColumn(data, marketPlace);
  let columns = [];
  let allColumns = [
    {
      id: "expander",
      Header: "",
      isStatic: true,
      width: 50,
      style: { width: "50px" },
      filterable: false,
      Cell: ({ row }) => (
        <ExpanderCell
          row={row}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      ),
    },
    {
      id: "product_title",
      isStatic: true,
      Header: "Product",
      accessor: "product.product_title",
      style: { width: "300px" },
      Cell: ({ value, row }) => (
        <ProductCell
          value={value}
          row={row}
          product={row._original.product}
          showSalesLabelBadge={true}
          showInventoryLabelBadge={true}
        />
      ),
      filterMethod: (filter, row) => {
        const { sales_state } = row._original.product;
        return filter.value === "all" || sales_state === filter.value;
      },
      Filter: ({ filter, onChange }) =>
        generateFilterMarkup(filter, onChange, "asin", "select", null, [
          { value: "all", label: "Show All" },
          { value: "BEST_SELLER", label: "Best Seller" },
          { value: "CLEARANCE", label: "Clearance" },
          { value: "NEW_PRODUCT", label: "New Product" },
        ]),
    },
    {
      id: "inventory",
      Header: "Inventory",
      accessor: "product.product_quantity",
      className: "text-end",
      width: 80,
      checked: false,
    },
    {
      id: "inventory_fba",
      Header: "FBA Inventory",
      accessor: "product.product_quantity_fba",
      className: "text-end",
      width: 80,
      checked: false,
    },
    {
      id: "tags",
      Header: "Tags",
      accessor: "product.master_product.tags",
      className: "text-center",
      width: 150,
      checked: false,
      Cell: ({ value }) => (
        <>
          {value?.map((v) => {
            return (
              <>
                <Badge className="badge-primary" pill>
                  {v.name}
                </Badge>
                <br />
              </>
            );
          })}
        </>
      ),
    },
    {
      Header: "Channel Id",
      id: "channel_id",
      accessor: (p) => p?.product?.asin,
      width: 150,
      Cell: ({ value, row }) => (
        <>
          {value}
          <br />
          <small>{value.indexOf("B") === 0 ? "Amazon" : "Target"}</small>
        </>
      ),
    },
    {
      Header: "Promo",
      id: "channel_promo",
      accessor: (p) => p?.name,
      Cell: EditCell,
      disabled: colToggle === "review",
      width: 250,
      stateKeyFn: (row) => row._original?.channelState?.name,
      onChange: (row, name) =>
        onOrgPlanProductChange(row._original.id, { name }),
      filterMethod: (filter, row) => {
        return String(row[filter.id].toLowerCase()).includes(
          filter.value.toLowerCase()
        );
      },
    },
    {
      Header: "Channel Target",
      id: "channel_target",
      accessor: (p) => p?.target_units,
      Cell: EditCell,
      disabled: colToggle === "review",
      width: 80,
      textAlign: "right",
      className: "text-end",
      stateKeyFn: (row) => row._original?.channelState?.target_units,
      onChange: (row, target_units) =>
        onOrgPlanProductChange(row._original.id, { target_units }),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.target_units,
          parseFormattedNumber
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "target_units",
          "range",
          "number",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "target_units",
              "number",
              marketPlace.marketPlace
            ),
          }
        );
      },
    },
    {
      Header: "Discount %",
      id: "channel_promo_cost",
      accessor: (p) => p?.percent_cost,
      Cell: EditCell,
      disabled: colToggle === "review",
      width: 80,
      textAlign: "right",
      className: "text-end",
      suffix: "%",
      stateKeyFn: (row) => row._original?.channelState?.percent_cost,
      onChange: (row, percent_cost) =>
        onOrgPlanProductChange(row._original.id, {
          percent_cost: percent_cost,
        }),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.percent_cost,
          parseFormattedPercentage,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "percent_cost",
          "range",
          "percent",
          {
            ranges: generateRanges(data ?? [], 5, "percent_cost", "number"),
          }
        );
      },
    },
    {
      Header: "Fixed Cost",
      id: "channel_promo_fixed_cost",
      accessor: (p) => p?.fixed_cost,
      Cell: EditCell,
      disabled: colToggle === "review",
      width: 80,
      textAlign: "right",
      className: "text-end",
      prefix: "$",
      checked: false,
      stateKeyFn: (row) => row._original?.channelState?.fixed_cost,
      onChange: (row, fixed_cost) =>
        onOrgPlanProductChange(row._original.id, {
          fixed_cost: fixed_cost,
        }),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.fixed_cost,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "fixed_cost",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "fixed_cost",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Target Ad Spend",
      id: "target_ad_spend",
      accessor: (p) => p?.target_ad_spend,
      Cell: EditCell,
      disabled: colToggle === "review",
      width: 80,
      textAlign: "right",
      className: "text-end",
      prefix: "$",
      stateKeyFn: (row) => row._original?.channelState?.target_ad_spend,
      onChange: (row, target_ad_spend) =>
        onOrgPlanProductChange(row._original.id, {
          target_ad_spend,
        }),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.target_ad_spend,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "target_ad_spend",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "target_ad_spend",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Net price",
      id: "net_price",
      accessor: (p) => p?.product.net_price,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      checked: false,
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.product.net_price,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "product.net_price",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Net Price with Promo",
      id: "target_net_price",
      accessor: (p) => p?.target_net_price,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      checked: false,
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.target_net_price,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "target_net_price",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "target_net_price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Gross Price",
      id: "gross_price",
      accessor: (p) => p?.product.gross_price,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      checked: false,
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.product.gross_price,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "product.gross_price",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "product.gross_price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Gross Price with Promo",
      id: "target_gross_price",
      accessor: (p) => p?.target_gross_price,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      checked: false,
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.target_gross_price,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "target_gross_price",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "target_gross_price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Margin",
      id: "channel_margin",
      accessor: (p) => p?.margin,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => formatPercent(value),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.margin * 100,
          parseFormattedPercentage,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "margin",
          "range",
          "percent",
          {
            ranges: generateRanges(
              data.map((d) => ({
                ...d,
                margin: d.margin * 100,
              })) ?? [],
              5,
              "margin",
              "number",
              marketPlace.marketPlace,
              true
            ),
          }
        );
      },
    },
    {
      Header: "Target Margin",
      id: "channel_target_margin",
      accessor: (p) => p?.target_margin,
      width: 100,
      className: "text-end",
      Cell: ({ value }) => formatPercent(value),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.target_margin * 100,
          parseFormattedPercentage,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "target_margin",
          "range",
          "percent",
          {
            ranges: generateRanges(
              data.map((d) => ({
                ...d,
                target_margin: d.target_margin * 100,
              })) ?? [],
              5,
              "target_margin",
              "number",
              marketPlace.marketPlace,
              true
            ),
          }
        );
      },
    },
    {
      Header: "Target Sales",
      id: "target_sales",
      accessor: (p) => p?.target_sales,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.target_sales,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "target_sales",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    CurrencyColumn("Target Promo Cost", "target_cost"),
    PercentColumn("Margin", "actual_margin", { checked: false }),
    PercentColumn("Net Margin", "actual_net_margin", { checked: false }),
    CurrencyColumn("Total Sales", "actual_total_sales"),
    CurrencyColumn("Total Cost", "actual_total_cost"),
    NumberColumn("Total Units", "actual_total_units"),
    CurrencyColumn("Promo Sales", "actual_promo_sales"),
    CurrencyColumn("Promo Cost", "actual_promo_cost"),
    NumberColumn("Promo Units", "actual_promo_units"),
    CurrencyColumn("Ad Sales", "actual_ad_sales"),
    CurrencyColumn("Ad Cost", "actual_ad_spend"),
    NumberColumn("Ad Units", "actual_ad_units"),
    NumberColumn("ROMS", "actual_roms"),
    NumberColumn("ROPS", "actual_rops"),
    NumberColumn("ROAS", "actual_roas"),
    NumberColumn("Glance Views", "actual_glance_views"),
    PercentColumn("Conv. Rate", "actual_conversion_rate"),
    PercentColumn("% of Target", "progress_units", {
      dots: (value) => {
        if (value !== null) {
          if (value > 0.95) {
            return trellisPalette[12];
          }
          if (value <= 0.8) {
            return trellisPalette[17];
          }
        }
        return "white";
      },
    }),
    {
      Header: "Issues",
      id: "warnings",
      accessor: (p) => p?.metadata?.warnings?.length,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => value > 0 && <i className="fa fa-warning"></i>,
      filterMethod: (filter, row) => {
        const warnings = row._original?.metadata?.warnings?.length;
        if (filter.value === "warnings") return warnings > 0;
        return true;
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "metadata.warnings",
          "select",
          "text",
          [
            { value: "all", label: "Show all" },
            { value: "warnings", label: "Warnings" },
          ]
        );
      },
    },

    {
      Header: "Price",
      id: "channel_price",
      accessor: (p) => p?.price,
      width: 80,
      className: "text-end",
      Cell: ({ value }) =>
        "channel" + formatCurrency(value, marketPlace.marketPlace),
      checked: false,
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.price,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "price",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Price",
      id: "channel_total_price",
      accessor: (p) => p?.total_price,
      width: 80,
      className: "text-end",
      Cell: ({ value }) =>
        "total" + formatCurrency(value, marketPlace.marketPlace),
      checked: false,
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.total_price,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "total_price",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "total_price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Target Price",
      id: "channel_target_price",
      accessor: (p) => p?.target_price,
      width: 80,
      className: "text-end",
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      checked: false,
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.target_price,
          parseFormattedCurrency,
          marketPlace.marketPlace
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "target_price",
          "range",
          "currency",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "target_price",
              "number",
              marketPlace.marketPlace
            ),
            marketPlace: marketPlace.marketPlace,
          }
        );
      },
    },
    {
      Header: "Monthly Units",
      id: "start_monthly_units",
      accessor: (p) => p.start_monthly_units,
      width: 80,
      className: "text-end",
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.start_monthly_units,
          parseFormattedNumber
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "start_monthly_units",
          "range",
          "number",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "start_monthly_units",
              "number",
              marketPlace.marketPlace
            ),
          }
        );
      },
    },
    {
      Header: "Monthly Units",
      id: "monthly_units",
      accessor: (p) => p.monthly_units,
      width: 80,
      className: "text-end",
      Cell: ({ value, row }) => (
        <TrendCell current={value} previous={row._original.start_monthly_units}>
          {value}
        </TrendCell>
      ),
      filterMethod: (filter, row) => {
        return filterRanges(
          filter,
          row._original.monthly_units,
          parseFormattedNumber
        );
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "monthly_units",
          "range",
          "number",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "monthly_units",
              "number",
              marketPlace.marketPlace
            ),
          }
        );
      },
    },
    {
      Header: "Price",
      id: "price",
      accessor: (p) => p.price,
      width: 80,
      className: "text-center",
      Cell: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      filterMethod: (filter, row) => {
        return filterRanges(filter, row._original.price, parseFormattedNumber);
      },
      Filter: ({ filter, onChange }) => {
        return generateFilterMarkup(
          filter,
          onChange,
          "price",
          "range",
          "number",
          {
            ranges: generateRanges(
              data ?? [],
              5,
              "price",
              "number",
              marketPlace.marketPlace
            ),
          }
        );
      },
    },
  ];

  let planningIds = [];
  let reviewIds = [];

  if (!isSeller(plan)) {
    planningIds = [
      "expander",
      "product_title",
      "channel_id",
      "inventory",
      "inventory_fba",
      "channel_promo_cost",
      "channel_promo_fixed_cost",
      "target_cost",
      "target_ad_spend",
      "net_price",
      "target_net_price",
      "gross_price",
      "target_gross_price",
      "channel_target",
      "start_monthly_units",
      "target_sales",
      "channel_target_margin",
      "channel_margin",
      "channel_price",
      "channel_target_price",
      "tags",
    ];
    reviewIds = [
      "expander",
      "product_title",
      "channel_id",
      "inventory",
      "inventory_fba",
      "actual_total_sales",
      "actual_total_cost",
      "actual_total_units",
      "actual_roms",
      "actual_promo_sales",
      "actual_promo_cost",
      "actual_promo_units",
      "actual_rops",
      "actual_ad_sales",
      "actual_ad_spend",
      "actual_ad_units",
      "actual_roas",
      "actual_margin",
      "actual_net_margin",
      "actual_glance_views",
      "actual_conversion_rate",
      "progress_units",
      "warnings",
    ];
  } else {
    planningIds = [
      "expander",
      "product_title",
      "channel_id",
      "inventory",
      "inventory_fba",
      "channel_promo",
      "channel_promo_cost",
      "channel_promo_fixed_cost",
      "channel_target",
      "start_monthly_units",
      "price",
      "channel_target_price",
      "channel_target_margin",
      "channel_margin",
      "target_sales",
    ];
    reviewIds = [
      "expander",
      "product_title",
      "channel_id",
      "inventory",
      "inventory_fba",
      "actual_total_sales",
      "actual_total_cost",
      "actual_total_units",
      "actual_promo_sales",
      "actual_promo_cost",
      "actual_promo_units",
      "actual_ad_sales",
      "actual_ad_spend",
      "actual_ad_units",
      "actual_margin",
      "progress_units",
      "warnings",
      "expander",
    ];
  }

  let columnMap = new Map();
  for (const column of allColumns) {
    columnMap.set(column.id, column);
  }
  if (colToggle === "planning") {
    for (const column of planningIds) {
      columns.push(columnMap.get(column));
    }
  }
  if (colToggle === "review") {
    for (const column of reviewIds) {
      columns.push(columnMap.get(column));
    }
  }
  return columns;
};

export default tableColumns;
