import React, { useState } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";

const WalmartMarketplaceForm = ({
  onSubmit = () => {},
  errorMessage,
  setErrorMessage,
  onboardingData,
}) => {
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const queryClient = useQueryClient();

  const submitWalmartMarketplaceCredentials = useMutation(
    async (credentials) => {
      return await api.post("/walmart/auth/marketplace", {
        onboarding: true,
        ...credentials,
      });
    },
    {
      onSuccess: async (res, variables) => {
        queryClient.invalidateQueries();
        // Below will trigger onError if validation fails in Walmart ads client request
        await api.get("/walmart/auth/advertising/verify");

        setErrorMessage("");
        onSubmit();
      },
      onError: (res, variables) => {
        setErrorMessage(
          "Invalid advertiser ID. Please verify that Trellis has been registered as an advertising partner and that the provided ID is correct."
        );
      },
    }
  );

  const handleFormSubmit = async (e) => {
    submitWalmartMarketplaceCredentials.mutate({
      client_id: clientId,
      client_secret: clientSecret,
    });
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()} noValidate>
      <Row>
        <Col xs={12} className="p-0 px-3 my-4">
          <p className="fs-standard">
            Please Enter Your Walmart Marketplace Client ID
          </p>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="clientId"
              value={clientId}
              placeholder="Client ID"
              className="py-4 px-5"
              onChange={(e) => {
                setClientId(e.target.value);
                setErrorMessage("");
              }}
            />
          </InputGroup>
        </Col>
        <Col xs={12} className="p-0 px-3 my-4">
          <p className="fs-standard">
            Please Enter Your Walmart Marketplace Client Secret
          </p>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="clientSecret"
              value={clientSecret}
              placeholder="Client Secret"
              className="py-4 px-5"
              onChange={(e) => {
                setClientSecret(e.target.value);
                setErrorMessage("");
              }}
            />
          </InputGroup>
        </Col>
        <p className="fs-standard text-center pb-3">
          <a
            href="https://developer.walmart.com/doc/us/us-mp/us-mp-auth/"
            target="_blank"
            rel="noopener noreferrer"
            className="fw-500 text-pink text-decoration-none"
          >
            Click here
          </a>{" "}
          for detailed instructions. Please ensure that full access permissions
          are granted for both Item Management and Order Management.
        </p>
      </Row>
      <Row>
        <button
          className="adplan_button w-50 mx-auto"
          onClick={(e) => handleFormSubmit(e)}
          disabled={
            !clientId ||
            !clientSecret ||
            submitWalmartMarketplaceCredentials.isLoading ||
            errorMessage
          }
        >
          Submit
        </button>
      </Row>
    </Form>
  );
};

export default WalmartMarketplaceForm;
