import axios from "axios";
import NavFilterTile from "components/core/blocks/NavFilterTile";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setMarketPlace } from "redux/actions/marketPlace";
import { setDefaultMarketPlace } from "redux/actions/userActions";
import { getURLPrefix } from "utils/getUrlPrefix";
import Flags from "country-flag-icons/react/3x2";
import api from "utils/api";
let URL_PREFIX = getURLPrefix();

class SellerMarketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableMarketplaces: [],
      currentMarketplace: this.props.marketPlace.marketPlace,
      marketplaceDropdownData: null,
    };
  }

  componentDidMount() {
    this.fetchUserMarketplaces();
  }

  componentDidUpdate() {
    if (this.state.currentMarketplace !== this.props.marketPlace.marketPlace) {
      this.setState({ currentMarketplace: this.props.marketPlace.marketPlace });
    }
  }

  fetchUserMarketplaces() {
    api
      .get(`/api/organizations/${this.props.user.organization_id}/marketplaces`)
      .then((res) => {
        this.setState(
          { availableMarketplaces: Object.keys(res.data.markets) },
          () => {
            var data = {
              Canada: { CA: this.marketPlaceStatus("CA") },
              "United States": { USA: this.marketPlaceStatus("USA") },
              Mexico: { MX: this.marketPlaceStatus("MX") },
              "United Kingdom": { UK: this.marketPlaceStatus("UK") },
              Germany: { DE: this.marketPlaceStatus("DE") },
              Netherlands: { NL: this.marketPlaceStatus("NL") },
              Spain: { ES: this.marketPlaceStatus("ES") },
              UAE: { AE: this.marketPlaceStatus("AE") },
              "Saudi Arabia": { SA: this.marketPlaceStatus("SA") },
              France: { FR: this.marketPlaceStatus("FR") },
              Sweden: { SE: this.marketPlaceStatus("SE") },
              Brazil: { BR: this.marketPlaceStatus("BR") },
              Poland: { PL: this.marketPlaceStatus("PL") },
              Belgium: { BE: this.marketPlaceStatus("BE") },
              Italy: { IT: this.marketPlaceStatus("IT") },
              India: { IN: this.marketPlaceStatus("IN") },
              Australia: { AU: this.marketPlaceStatus("AU") },
              Singapore: { SG: this.marketPlaceStatus("SG") },
              Japan: { JP: this.marketPlaceStatus("JP") },
            };
            this.setState({ marketplaceDropdownData: data });
          }
        );
      });
  }

  onMarketplaceClick = (id) => {
    this.setState({ currentMarketplace: id });
    this.props.setMarketPlace(id);
    localStorage.setItem("Marketplace", id);
    this.updateDefaultMarketPlace(id);
  };

  updateDefaultMarketPlace = (id) => {
    setDefaultMarketPlace(id);

    const AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    const orgId = this.props.user.organization_id;

    const currentDefaults = this.props.user.defaultMarketplace;
    currentDefaults[orgId] = id;

    let data = {
      default_marketplace: currentDefaults,
    };

    axios
      .put(URL_PREFIX + "/api/userprofile/", data, {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .catch((err) => {
        console.table(err);
      });
  };

  marketPlaceStatus(id) {
    var status = true;
    if (this.state.availableMarketplaces.length > 0) {
      for (var i = 0; i < this.state.availableMarketplaces.length; i++) {
        if (this.state.availableMarketplaces[i] === id) {
          status = false;
          break;
        }
      }
    }
    return status;
  }

  selectedMarketPlace(marketPlaceKey) {
    if (marketPlaceKey === "CA") {
      return "Canada";
    } else if (marketPlaceKey === "USA") {
      return "United States";
    } else if (marketPlaceKey === "MX") {
      return "Mexico";
    } else if (marketPlaceKey === "BR") {
      return "Brazil";
    } else if (marketPlaceKey === "UK") {
      return "United Kingdom";
    } else if (marketPlaceKey === "DE") {
      return "Germany";
    } else if (marketPlaceKey === "AE") {
      return "UAE";
    } else if (marketPlaceKey === "SA") {
      return "Saudi Arabia";
    } else if (marketPlaceKey === "AU") {
      return "Australia";
    } else if (marketPlaceKey === "ES") {
      return "Spain";
    } else if (marketPlaceKey === "IT") {
      return "Italy";
    } else if (marketPlaceKey === "FR") {
      return "France";
    } else if (marketPlaceKey === "NL") {
      return "Netherlands";
    } else if (marketPlaceKey === "SE") {
      return "Sweden";
    } else if (marketPlaceKey === "SE") {
      return "Poland";
    } else if (marketPlaceKey === "BE") {
      return "Belgium";
    } else if (marketPlaceKey === "IN") {
      return "India";
    } else if (marketPlaceKey === "JP") {
      return "Japan";
    } else if (marketPlaceKey === "SG") {
      return "Singapore";
    } else {
      return marketPlaceKey;
    }
  }

  render() {
    var data = {
      "North America": { NA: true, style: true },
      Canada: {
        CA: this.marketPlaceStatus("CA"),
        icon: <Flags.CA className="flag-icon" />,
      },
      "United States": {
        USA: this.marketPlaceStatus("USA"),
        icon: <Flags.US className="flag-icon" />,
      },
      Mexico: {
        MX: this.marketPlaceStatus("MX"),
        icon: <Flags.MX className="flag-icon" />,
      },
      Brazil: {
        BR: this.marketPlaceStatus("BR"),
        icon: <Flags.BR className="flag-icon" />,
      },
      Europe: { EU: true, style: true },
      "United Kingdom": {
        UK: this.marketPlaceStatus("UK"),
        icon: <Flags.GB className="flag-icon" />,
      },
      Germany: {
        DE: this.marketPlaceStatus("DE"),
        icon: <Flags.DE className="flag-icon" />,
      },
      Netherlands: {
        NL: this.marketPlaceStatus("NL"),
        icon: <Flags.NL className="flag-icon" />,
      },
      Spain: {
        ES: this.marketPlaceStatus("ES"),
        icon: <Flags.ES className="flag-icon" />,
      },
      Sweden: {
        SE: this.marketPlaceStatus("SE"),
        icon: <Flags.SE className="flag-icon" />,
      },
      Poland: {
        PL: this.marketPlaceStatus("PL"),
        icon: <Flags.PL className="flag-icon" />,
      },
      Belgium: {
        BE: this.marketPlaceStatus("BE"),
        icon: <Flags.BE className="flag-icon" />,
      },
      UAE: {
        AE: this.marketPlaceStatus("AE"),
        icon: <Flags.AE className="flag-icon" />,
      },
      "Saudi Arabia": {
        SA: this.marketPlaceStatus("SA"),
        icon: <Flags.SA className="flag-icon" />,
      },
      France: {
        FR: this.marketPlaceStatus("FR"),
        icon: <Flags.FR className="flag-icon" />,
      },
      Italy: {
        IT: this.marketPlaceStatus("IT"),
        icon: <Flags.IT className="flag-icon" />,
      },
      India: {
        IN: this.marketPlaceStatus("IN"),
        icon: <Flags.IN className="flag-icon" />,
      },
      "Far East": { FE: true, style: true },
      Australia: {
        AU: this.marketPlaceStatus("AU"),
        icon: <Flags.AU className="flag-icon" />,
      },
      Japan: {
        JP: this.marketPlaceStatus("JP"),
        icon: <Flags.JP className="flag-icon" />,
      },
      Singapore: {
        SG: this.marketPlaceStatus("SG"),
        icon: <Flags.SG className="flag-icon" />,
      },
    };

    return (
      <NavFilterTile
        defaultValue={this.selectedMarketPlace(this.state.currentMarketplace)}
        handleChildComponent={this.onMarketplaceClick}
        data={data}
        fontSize="14px"
        mobileSidebarItem={this.props.mobileSidebarItem}
      />
    );
  }
}

SellerMarketplace.propTypes = {
  setMarketPlace: PropTypes.func.isRequired,
  marketPlace: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  setMarketPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerMarketplace);
