import { Typography, withStyles, Grid, Hidden } from "@material-ui/core";
import { useFetch } from "hooks/api";
import React, { useState, useEffect } from "react";
import { NavDropdown, InputGroup, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { trellisPalette } from "components/custom/analytics/palettes";
import { toCurrency } from "utils/marketplaceConstants";
import GVStatsCard from "components/core/blocks/SingleStatsTile";
import ProductsCVTable from "components/custom/growth/ProductsCVTable";
import ProductCard from "components/custom/growth/ProductCard";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  CV_TOOLTIP,
  TOTAL_NTB_CUSTOMERS_TOOLTIP,
  TOTAL_REPEAT_CUSTOMERS_TOOLTIP,
} from "utils/toolTips";
import { ToolTips, RESET_PB_CHART_TOOLTIP } from "utils/hiddenToolTips";
import { formatNumber } from "utils/formatNumber";
import getTimeDescription from "utils/getTimeDescription";
import ChartMiddleware from "components/custom/growth/ChartMiddleware";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useMutation } from "react-query";
import api from "utils/api";
import FileSaver from "file-saver";
import { PrimaryButton } from "components/core/basic/Button";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import TrellisLink from "components/core/basic/TrellisLink";
import ThemeLine from "components/custom/merchandise/ThemeLine";

const useStyles = (theme) => ({
  container: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4),
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  main: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  header: {
    textAlign: "center",
  },
  chart: {
    maxHeight: "190px",
    marginBottom: "100px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  table: {
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  cvTableContainer: {
    marginTop: "100px",
    minHeight: "400px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  mainHeader: {
    fontSize: "26px",
    color: "#403E3D",
    fontWeight: "700",
    margin: "auto",
  },
  statTile: {
    maxHeight: "170px",
    margin: 0,
    whiteSpace: "nowrap",
  },
  tableHeader: {
    fontSize: "1.65rem",
    color: "#403E3D",
    fontWeight: "700",
    margin: "1rem auto",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  statValues: {
    fontSize: "2.5rem",
    fontWeight: 400,
    color: "#403E3D",
    padding: "auto",
  },
  metrics: {
    justifyContent: "center",
    textAlign: "center",
    margin: "auto 20px",
  },
  dropdownContainer: {
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
    },
  },
  dropdownText: {
    color: "#403E3D",
    fontWeight: 600,
  },
  buttonGroupContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(2),
    alignItems: "flex-start",
    marginBottom: "auto",
  },
});

const PurchaseBehaviorReport = ({ classes, marketPlace, user, modules }) => {
  const [pbProductData, setPbProductData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductPbData, setSelectedProductPbData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showCategoryData, setShowCategoryData] = useState(true);
  const { channel } = useSelector((state) => state);
  const [rollup, setRollup] = useState({
    id: "1",
    rollup: "weekly",
    name: "Weekly",
  });
  const [metric, setMetric] = useState({
    id: "1",
    value: "customers",
    name: "Customers",
  });
  const [categorySearch, setCategorySearch] = useState("");

  const marketplace = marketPlace.marketPlace;
  const market_place = marketPlace.marketPlace;
  const groupby = "month";
  const baseQuery = { market_place };
  if (selectedCategory !== "all") {
    baseQuery["category"] = selectedCategory;
  }
  const orderStatsDimension = selectedCategory === "all" ? "org" : "category";
  const { data: response } = useFetch(
    [
      "order_stats",
      groupby,
      marketPlace,
      selectedCategory,
      orderStatsDimension,
    ],
    "/api/order_stats",
    { groupby, dimension: orderStatsDimension, ...baseQuery },
    { enabled: !!selectedCategory }
  );

  const key = [
    "order_stats",
    rollup.rollup,
    marketPlace,
    selectedCategory,
    orderStatsDimension,
  ];

  const query = {
    groupby:
      rollup.rollup === "daily"
        ? "day"
        : rollup.rollup === "weekly"
        ? "week"
        : "month",
    dimension: orderStatsDimension,
    ...baseQuery,
  };

  let startDate;
  switch (rollup.rollup) {
    case "daily":
      startDate = moment().subtract(2, "month").format("YYYY-MM-DD");
      break;
    case "weekly":
      startDate = moment().subtract(6, "month").format("YYYY-MM-DD");
      break;
    default:
      break;
  }
  if (startDate) {
    query["date__gte"] = startDate;
    key.push(startDate);
  }

  const { data: rollupResponse } = useFetch(key, "/api/order_stats", query, {
    enabled: !!selectedCategory,
  });

  const dimension = "product";
  const { isLoading: isProductDataLoading, data: productData } = useFetch(
    ["order_stats", dimension, market_place, selectedCategory],
    "/api/order_stats",
    {
      dimension,
      market_place,
      groupby,
      ...baseQuery,
    },
    {
      enabled: !!selectedCategory,
    }
  );

  const downloadReport = useMutation(async () => {
    const response = await api.get(
      "/api/order_stats",
      {
        excel: true,
        dimension,
        market_place,
        groupby,
        ...baseQuery,
      },
      { responseType: "blob" }
    );
    let blob = new Blob([response.data], {
      type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    FileSaver.saveAs(blob, "report.xlsx");
  });

  const { isLoading: isCategoryDataLoading, data: categoryData } = useFetch(
    ["categories", marketplace, channel.currentChannel],
    "/api/gvads/categories",
    {
      marketplace,
      channel: channel.currentChannel,
    }
  );

  let productStatsQuery = {
    groupby:
      rollup.rollup === "daily"
        ? "day"
        : rollup.rollup === "weekly"
        ? "week"
        : "month",
  };

  if (selectedProduct) {
    productStatsQuery["product"] = selectedProduct?.id;
  }

  const { data: productChartData } = useFetch(
    [
      "order_stats",
      "product",
      market_place,
      selectedProduct?.id,
      rollup.rollup,
    ],
    "/api/order_stats",
    {
      dimension,
      market_place,
      ...productStatsQuery,
    },
    { enabled: !!selectedProduct }
  );

  const data = response?.data ?? [];
  let chartData =
    selectedProduct && !showCategoryData
      ? productChartData?.data ?? []
      : rollupResponse?.data ?? [];
  data.forEach((d) => {
    d.mDate = moment(d.date);
  });

  chartData.forEach((d) => {
    d.mDate = moment(d.date);
  });

  const today = moment.max(data.map((d) => d.mDate));
  const recent = data.find((d) => d.mDate === today);

  useEffect(() => {
    if (productData?.data !== pbProductData) {
      setPbProductData(
        productData?.data.filter((d) => d.date === recent?.date)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, productData, selectedCategory, recent]);

  let chartDates = [];
  if (rollup.rollup === "monthly") {
    chartDates = chartData?.map((d) =>
      d.mDate?.endOf("M").format("YYYY-MM-DD")
    );
  } else if (rollup.rollup === "weekly") {
    chartDates = chartData?.map((d) =>
      d.mDate?.endOf("W").format("YYYY-MM-DD")
    );
  } else if (rollup.rollup === "daily") {
    chartDates = chartData?.map((d) => d.mDate?.format("YYYY-MM-DD"));
  }

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProductPbData(
        pbProductData?.find(
          (pd) =>
            pd.date === recent?.date && pd.product.id === selectedProduct.id
        )
      );
    }
    setShowCategoryData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market_place, selectedProduct, recent]);

  const chartOptions = {
    title: {
      text: null,
    },
    // This is blank to overwrite default options for performance chart
    plotOptions: {},
    xAxis: {
      categories: chartDates?.slice(Math.max(chartData.length - 30, 0)),
      labels: {
        step: rollup?.rollup === "daily" ? 5 : 1,
      },
    },
    yAxis: [
      {
        title: "Number of Customers",
      },
    ],
    tooltip: {
      shared: true,
      pointFormat:
        metric.value === "sales"
          ? "{series.name}: <b>${point.y:,.2f}</b><br/>"
          : "{series.name}: <b>{point.y}</b><br/>",
    },
  };

  const timeSeriesFilter = [
    { id: "1", rollup: "daily", name: "Daily" },
    { id: "1", rollup: "weekly", name: "Weekly" },
    { id: "1", rollup: "monthly", name: "Monthly" },
  ];

  return (!user.pb_tier ||
    ["unknown", "MANUALLY_DISABLED"].includes(user.pb_tier)) &&
    !checkModule(modules, "GROWTH") ? (
    <div style={{ marginTop: "30px" }}>
      <PaymentDiscovery discoveryType={"purchase-behavior"} />
    </div>
  ) : (
    <Grid container xs={12} spacing={4} className={classes.container}>
      <Grid item container xs={12} className={classes.header}>
        <Typography align="center" variant="h3" className={classes.mainHeader}>
          Purchase Behavior
        </Typography>
      </Grid>
      <Grid item container xs={12} lg={8} spacing={4} className={classes.main}>
        <Grid
          item
          container
          xs={12}
          className={classes.metrics}
          spacing={4}
          direction="row"
        >
          <Grid
            container
            item
            xs={12}
            lg={2}
            style={{
              display: "flex",
            }}
          >
            <div className={classes.dropdownContainer}>
              <NavDropdown
                renderMenuOnMount={true}
                onSelect={(categoryId) => {
                  if (categoryId === "all") {
                    setSelectedCategory("all");
                    return;
                  }
                  setSelectedCategory(parseInt(categoryId));
                  setSelectedProduct(null);
                  setCategorySearch("");
                }}
                className="fs-standard noarrow dropdown mx-0"
                id="DateBreadcrumbComparison"
                autoClose={true}
                value={selectedCategory}
                title={
                  <div style={{ lineHeight: 1.2, whiteSpace: "initial" }}>
                    {selectedCategory === "all" ? (
                      <span
                        className={classes.dropdownText}
                        style={{ fontSize: "18px" }}
                      >
                        All Categories
                      </span>
                    ) : (
                      <span
                        className={classes.dropdownText}
                        style={{ fontSize: "18px" }}
                      >
                        {categoryData?.data &&
                          categoryData.data.find(
                            (c) => parseInt(c.id) === selectedCategory
                          )?.leaf_category_name}
                      </span>
                    )}
                  </div>
                }
              >
                <div className="px-3 py-2">
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      style={{
                        borderTopLeftRadius: "45px",
                        borderBottomLeftRadius: "45px",
                      }}
                    >
                      <i className="fa fa-search" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder={"Search..."}
                      aria-label="Search Term"
                      onChange={(e) => {
                        setCategorySearch(e.target.value);
                      }}
                      defaultValue={categorySearch}
                      value={categorySearch}
                    />
                  </InputGroup>
                </div>

                <div
                  style={{
                    width: "350px",
                    maxHeight: "500px",
                    overflowY: "auto",
                  }}
                >
                  <NavDropdown.Item eventKey="all">
                    <TrellisLink
                      className={`text-decoration-none px-3`}
                      key={"allCategories"}
                      style={{
                        fontWeight:
                          selectedCategory === "all" ? 600 : "inherit",
                      }}
                    >
                      All Categories
                    </TrellisLink>
                  </NavDropdown.Item>
                  {categoryData?.data
                    ?.filter((category) => {
                      if (categorySearch) {
                        return (
                          category.leaf_category_name
                            ?.toLowerCase()
                            ?.indexOf(categorySearch.toLowerCase()) > -1
                        );
                      }

                      return true;
                    })
                    ?.sort((a, b) => {
                      return a.leaf_category_name?.toLowerCase() <
                        b.leaf_category_name?.toLowerCase()
                        ? -1
                        : a.leaf_category_name?.toLowerCase() >
                          b.leaf_category_name?.toLowerCase()
                        ? 1
                        : 0;
                    })
                    ?.map((category) => (
                      <NavDropdown.Item
                        eventKey={category.id}
                        className="d-flex"
                      >
                        <TrellisLink
                          className={`text-decoration-none px-3`}
                          key={category.id}
                          style={{
                            fontWeight:
                              category.id === selectedCategory
                                ? 600
                                : "inherit",
                          }}
                        >
                          {category.leaf_category_name}
                          <ThemeLine
                            additionalStyles={{
                              fontSize: "10px",
                              fontWeight:
                                category.id === selectedCategory ? 600 : 500,
                              color: "#2e0054",
                            }}
                          >
                            {category.ad_count} Media Plans,{" "}
                            {category.product_count} Products
                          </ThemeLine>
                        </TrellisLink>
                      </NavDropdown.Item>
                    ))}
                </div>
              </NavDropdown>
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={10}
            spacing={3}
            style={{ paddingLeft: "5rem" }}
          >
            <Grid item xs={12} sm={4} className={`${classes.statTile} py-0`}>
              <GVStatsCard
                valueAlign="center"
                statsText={`Repeat ${
                  metric.value === "sales" ? "Sales" : "Customers"
                }`}
                statsValue={
                  <span className={classes.statValues}>
                    {recent
                      ? metric.value === "sales"
                        ? formatNumber(recent.total_sales_repeat, {
                            currency: {
                              marketPlace: marketPlace.marketPlace,
                            },
                          })
                        : recent.total_customers_repeat
                      : "-"}
                  </span>
                }
                tooltipText={TOTAL_REPEAT_CUSTOMERS_TOOLTIP}
                padding={"0 2.5rem"}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.statTile} py-0`}>
              <GVStatsCard
                valueAlign="center"
                statsText={`NTB ${
                  metric.value === "sales" ? "Sales" : "Customers"
                }`}
                statsValue={
                  <span className={classes.statValues}>
                    {recent
                      ? metric.value === "sales"
                        ? formatNumber(recent.total_sales_ntb, {
                            currency: {
                              marketPlace: marketPlace.marketPlace,
                            },
                          })
                        : formatNumber(recent.total_customers_ntb)
                      : "-"}
                  </span>
                }
                tooltipText={TOTAL_NTB_CUSTOMERS_TOOLTIP}
                padding={"0 2.5rem"}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.statTile} py-0`}>
              <GVStatsCard
                valueAlign="center"
                statsText={"Customer Value"}
                statsValue={
                  <span className={classes.statValues}>
                    {recent
                      ? toCurrency(recent.cv, marketPlace.marketPlace)
                      : "-"}
                  </span>
                }
                tooltipText={CV_TOOLTIP}
                padding={"0 2.5rem"}
              />
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid
              container
              item
              xs={12}
              style={{ justifyContent: "space-between" }}
              className="mt-4"
            >
              <Grid
                item
                xs={6}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "1rem",
                }}
              >
                <NavDropdown
                  id="performancePeriod"
                  title={
                    <span className={classes.dropdownText}>{rollup.name}</span>
                  }
                  style={{
                    color: "#73706E",
                    fontSize: "18px",
                  }}
                >
                  {timeSeriesFilter.map((item, index) => {
                    return (
                      <NavDropdown.Item
                        key={"productChart" + index}
                        id={item.id}
                        onClick={(e) => setRollup(item)}
                      >
                        {item.name}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={4}
                style={{ justifyContent: "flex-end" }}
              >
                <span
                  style={{ margin: "auto 1rem auto auto", fontSize: "14px" }}
                >
                  Displaying data for
                  <br />
                  <span style={{ color: trellisPalette[0], fontWeight: 500 }}>
                    {showCategoryData && selectedCategory === "all"
                      ? "All Categories"
                      : selectedProduct && !showCategoryData
                      ? user.productIdentifier === "sku"
                        ? selectedProduct.sku
                        : selectedProduct.asin
                      : categoryData?.data?.find(
                          (c) => parseInt(c.id) === selectedCategory
                        )?.leaf_category_name ?? "All Categories"}
                  </span>
                </span>

                <button
                  className="btn-reset-filters "
                  style={{
                    background: "#fff",
                    color: "#000",
                    fontSize: "2rem",
                    padding: "1rem",
                    position: "relative",
                    marginRight: "auto",
                    alignItems: "flex-end",
                    visibility:
                      !showCategoryData && selectedProduct
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={() => {
                    setShowCategoryData(true);
                  }}
                >
                  <ToolTips toolTip={RESET_PB_CHART_TOOLTIP} position={"top"} />
                  <RotateLeftIcon
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginLeft: "auto",
                    }}
                  />
                </button>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={4}
                className={classes.buttonGroupContainer}
              >
                <ButtonGroup style={{ margin: "auto 0", maxHeight: "3rem" }}>
                  <Button
                    variant={metric.value === "customers" ? `contained` : null}
                    color={metric.value === "customers" ? `secondary` : null}
                    onClick={() =>
                      setMetric({
                        id: "1",
                        value: "customers",
                        name: "Customers",
                      })
                    }
                  >
                    Customers
                  </Button>
                  <Button
                    variant={metric.value === "sales" ? `contained` : null}
                    color={metric.value === "sales" ? `secondary` : null}
                    onClick={() =>
                      setMetric({ id: "1", value: "sales", name: "Sales" })
                    }
                  >
                    Sales
                  </Button>
                </ButtonGroup>{" "}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Hidden xsDown>
          <Grid item container xs={12} className={classes.chart} spacing={3}>
            <Grid item xs={12}>
              <ChartMiddleware
                options={{
                  options: chartOptions,
                  series: [
                    {
                      color: trellisPalette[0],
                      type: "column",
                      name: `${rollup.name} Repeat`,
                      data:
                        metric.value === "sales"
                          ? chartData
                              .map((d) => d.sales_repeat)
                              ?.slice(Math.max(chartData.length - 30, 0))
                          : chartData
                              .map((d) => d.customers_repeat)
                              ?.slice(Math.max(chartData.length - 30, 0)),
                    },
                    {
                      color: trellisPalette[2],
                      type: "column",
                      name: "New To Brand",
                      data:
                        metric.value === "sales"
                          ? chartData
                              .map((d) => d.sales_ntb)
                              ?.slice(Math.max(chartData.length - 30, 0))
                          : chartData
                              .map((d) => d.customers_ntb)
                              ?.slice(Math.max(chartData.length - 30, 0)),
                    },
                  ],
                }}
                rollup={rollup}
                yLabels={true}
                height={388}
                context={"purchase-behavior"}
                selectedCategory={selectedCategory}
                selectedProduct={
                  showCategoryData ? null : selectedProduct?.id ?? null
                }
                isChartDataLoading={
                  isProductDataLoading || isCategoryDataLoading
                }
              />
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          container
          xs={12}
          className={`${classes.table} product-cv-table`}
          spacing={{ xs: 0, sm: 3 }}
        >
          <Grid item xs={12} className={classes.cvTableContainer}>
            <h3
              className={classes.tableHeader}
              style={{ margin: "5rem 2rem", fontSize: "2.2rem" }}
            >
              Customer Value by Product
            </h3>
            <ProductsCVTable
              pbData={pbProductData}
              setSelectedProduct={setSelectedProduct}
              getTimeDescription={getTimeDescription}
            />
          </Grid>
          <Grid item xs={12} className="text-end">
            <PrimaryButton
              nowrap
              disabled={downloadReport.loading}
              onClick={() => downloadReport.mutate()}
            >
              Download
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      <ProductCard
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        pbData={pbProductData}
        selectedCategory={selectedCategory === "all" ? null : selectedCategory}
        selectedProductPbData={selectedProductPbData}
        marketPlace={marketPlace}
        categories={categoryData?.data ?? []}
      />
    </Grid>
  );
};

PurchaseBehaviorReport.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(
  withStyles(useStyles)(PurchaseBehaviorReport)
);
