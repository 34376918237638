import React, { useMemo } from "react";
import { PAGE_HEIGHT, PAGE_WIDTH, getTrendColor } from "./utils";
import acosOrRoas from "utils/acosOrRoas";
import { Row, Col } from "react-bootstrap";
import Table from "./Table";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { formatCurrency, formatPercent } from "utils/formatNumber";

const TopKeywords = ({
  orgName = "",
  data,
  group,
  roasPreference = false,
  marketPlace,
  channel,
}) => {
  const { keywordData } = data;

  const targetsTableColumns = useMemo(
    () => [
      {
        accessor: "keyword_text",
        Header: "Keyword",
        customCell: (keyword) => {
          const { keyword_text } = keyword;

          return (
            <div>
              <span className={"w-100 truncate"}>{keyword_text}</span>
            </div>
          );
        },
        customCompareCell: (keyword) => {
          const { match_type } = keyword;

          return (
            <div className="fs-6 pb-1">
              <span className="ps-2">{match_type}</span>
            </div>
          );
        },
      },
      {
        Header: "Ad Sales",
        accessor: "ad_sales",
        compareMetricId: "ad_sales_percent",
        yoyCompareMetricId: "ad_sales_percent_yoy",
      },
      {
        Header: "Ad Spend",
        accessor: "ad_spend",
        compareMetricId: "cost_percent",
        yoyCompareMetricId: "cost_percent_yoy",
      },
      {
        accessor: "cost_per_click",
        compareMetricId: "cost_per_click_percentage",
        Header: "Cost Per Click",
        customCell: (row) => {
          const { cost_per_click } = row ?? {};

          return (
            <span>
              {formatCurrency(
                cost_per_click,
                marketPlace.marketPlace,
                false,
                false
              )}
            </span>
          );
        },
        customCompareCell: (row) => {
          const { cost_per_click_percent } = row ?? {};

          return (
            <span
              style={{
                color: getTrendColor(
                  cost_per_click_percent,
                  "cost_per_click_percent"
                ),
              }}
            >
              {formatPercent(cost_per_click_percent, 2, true)}
            </span>
          );
        },
      },

      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "acos",

          compareMetricId: "acos_delta",
          yoyCompareMetricId: "acos_delta_yoy",
          Header: "ACOS",
          options: {
            isNegative: true,
          },
        },
        {
          accessor: "roas",
          compareMetricId: "roas_delta",
          yoyCompareMetricId: "roas_delta_yoy",
          Header: "ROAS",
          options: {
            isNegative: true,
          },
        }
      ),
      {
        Header: "Clicks",
        accessor: "clicks",
        compareMetricId: "clicks_percent",
        yoyCompareMetricId: "clicks_percent_yoy",
      },
      {
        Header: "Impressions",
        accessor: "impressions",
        compareMetricId: "impressions_percent",
        yoyCompareMetricId: "impressions_percent_yoy",
      },
      {
        Header: "Conversions",
        accessor: "orders",
        compareMetricId: "conversion_percent",
        yoyCompareMetricId: "conversion_percent_yoy",
      },
    ],
    [roasPreference, marketPlace]
  );

  return (
    <div
      className={`box box-common pt-5`}
      style={{
        maxWidth: PAGE_WIDTH,
        maxHeight: PAGE_HEIGHT,
        overflowY: "hidden",
      }}
    >
      <img src={logo} className="watermark" alt="Trellis logo" />

      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"Top performing keywords *"}
            titleColWidth={30}
            data={
              keywordData
                ?.filter((keyword) =>
                  ["broad", "exact", "phrase"].includes(keyword.match_type)
                )
                ?.slice(0, 18) ?? []
            }
            columns={targetsTableColumns}
            showPrevPeriodTrendRow={true}
            marketPlace={marketPlace}
          />
        </Col>
      </Row>
      <span className="table-annotation">* Sorted by ad sales descending</span>
    </div>
  );
};

export default TopKeywords;
