import moment from "moment";
import React, { Fragment, useState, useEffect } from "react";
import { useFetch } from "hooks/api";
import { useQueries } from "react-query";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, Grid } from "@material-ui/core";
import ProductImage from "components/custom/category/listings/ProductImage";
import ReactTable from "react-table-6";
import { toCurrency } from "utils/marketplaceConstants";
import GVStatsCard from "components/core/blocks/SingleStatsTile";
import {
  PRODUCT_CV_TOOLTIP,
  BRAND_CV_TOOLTIP,
  REPEAT_TOOLTIP,
  REPEAT_BRAND_SALES_TOOLTIP,
  REPEAT_BRAND_CUSTOMERS_TOOLTIP,
  REPEAT_SALES_TOOLTIP,
  FREQUENCY_TOOLTIP,
  ACOS_TOOLTIP,
  TOTAL_ACOS_TOOLTIP,
  AD_SPEND_TOOLTIP,
  AD_SALES_TOOLTIP,
  TOTAL_SALES_TOOLTIP,
  HALO_RANK_TOOLTIP,
} from "utils/toolTips";
import getTimeDescription from "utils/getTimeDescription";
import NavFilter from "./NavFilter";
import AdPlanModal from "components/core/blocks/AdPlan/AdPlanModal";
import Carousel from "react-material-ui-carousel";
import { formatNumber } from "utils/formatNumber";
import { snakeCaseToSentenceCase } from "utils/formatText";
import api from "utils/api";
import generateLink from "utils/generateLink";

const useStyles = (theme) => ({
  productCard: {
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4, 3, 3),
    boxShadow: "0 4px 20px 15px rgba(68, 68, 68, .1)",
    borderRadius: "20px",
    marginLeft: "auto",
    height: "1200px",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: theme.spacing(4),
    },
  },
  emptyProductCard: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: "3rem",
  },
  productCardImage: {
    height: "80px",
    width: "80px",
    objectFit: "cover",
  },
  productCardTitle: {
    fontSize: "15px",
    color: "#403E3D",
    textAlign: "center",
    fontWeight: 500,
    padding: "0 3rem",
    lineHeight: 1.5,
    maxWidth: "90%",
    margin: "2rem auto 1rem",
  },
  selectedProductLink: {
    fontWeight: 500,
    margin: "1rem",
    "&:hover": {
      color: "#d91266",
    },
  },
  statValues: {
    fontSize: "2.5rem",
    fontWeight: 400,
    color: "#403E3D",
    padding: "auto",
  },
  followupProductCell: {
    display: "flex",
    height: "40px",
    alignItems: "center",
  },
  tableHeader: {
    fontSize: "1.65rem",
    color: "#403E3D",
    fontWeight: "700",
    margin: "1rem auto",
  },
  noFollowupProductMessage: {
    fontSize: "1.6rem",
  },
  noAdvertisementsMessage: {
    fontSize: "1.8rem",
    fontWeight: "600",
    padding: "2rem",
    color: "#403E3D",
    opacity: ".8",
    margin: "60px auto 40px",
  },
  advertisementTable: {
    marginBottom: "40px",
  },
  centeredCell: {
    display: "flex",
    justifyContent: "center",
  },
  statTile: {
    height: "150px",
  },
  carousel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const ProductCard = ({
  classes,
  selectedProduct,
  setSelectedProduct,
  selectedCategory,
  categories,
  user: { productIdentifier, preferences },
  selectedProductPbData,
  marketPlace,
  pbData,
}) => {
  const [followupProducts, setFollowupProducts] = useState([]);
  const [followupPurchaseMax, setFollowupPurchaseMax] = useState(0);
  const [selectedCardView, setSelectedCardView] = useState({
    label: "Purchase Behavior",
    value: 0,
  });
  const [adIds, setAdIds] = useState([]);

  const marketplace = marketPlace.marketPlace;
  const channel = "amazon";

  const end = moment();
  const start = end.clone().subtract(1, "year");

  const { isLoading: isFollowupProductDataLoading, data: productDetails } =
    useFetch(
      ["followup_products", marketplace],
      "/api/gvads/productid/?parent=true",
      {
        marketplace,
      }
    );

  const { isLoading: isProductAdDataLoading, data: productAdDetails } =
    useFetch(
      ["productAds", selectedProduct?.id, marketplace],
      "/api/gvads/advproduct",
      { product: selectedProduct?.id, marketplace },
      { enabled: !!selectedProduct }
    );

  const filters = {
    mp: marketPlace.marketPlace,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    group_by: "product_id",
    product_id: selectedProduct?.id,
    channel: channel?.currentChannel ?? "amazon",
  };

  const { data: productStats } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      selectedProduct?.id,
    ],
    `/api/data_report/product_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: !!selectedProduct?.id,
    }
  );

  // Get product-level ad stats for selected product
  const productAdStats = useQueries(
    adIds?.map((id) => ({
      queryKey: ["gvAdStats", id, marketplace],
      queryFn: async () => {
        return await api.get("/api/gvads/stats", {
          advertisement: id,
          marketplace,
          timeseries: false,
          channel,
        });
      },
    })) ?? [],
    {
      enabled: !!adIds,
    }
  );

  const productLevelAdStats = productAdStats.map((stats) => stats.data?.data);

  useEffect(() => {
    const followupProductsArray = [];
    const followupProductValues = [];
    const products = selectedProductPbData?.products;

    for (const property in selectedProductPbData?.products) {
      if (parseInt(property) !== selectedProduct?.id) {
        followupProductsArray.push({
          id: property,
          count: products[property],
        });
        followupProductValues.push(products[property]);
      }
    }

    setFollowupPurchaseMax(Math.max(...followupProductValues));
    setFollowupProducts(followupProductsArray);
  }, [selectedProductPbData, selectedProduct]);

  useEffect(() => {
    if (productAdDetails?.data?.length > 0) {
      setAdIds(productAdDetails?.data?.map((a) => a.id));
    } else {
      setAdIds([]);
    }
  }, [productAdDetails, productStats]);

  const cardNavOptions = [
    { label: "Purchase Behavior", value: 0 },
    { label: "Advertisements", value: 1 },
  ];

  return (
    <Grid container item xs={12} sm={8} lg={4} spacing={4}>
      <Grid className={classes.productCard}>
        {selectedProduct === null ? (
          <div className={classes.emptyProductCard}>
            <h3
              style={{
                fontSize: "2.2rem",
                fontWeight: 600,
                lineHeight: 1.5,
              }}
            >
              Select a Product to View Purchase Behaviour Details
            </h3>
          </div>
        ) : (
          <Grid style={{ textAlign: "center" }} item xs={12}>
            <img
              src={selectedProduct?.product_image_url}
              alt={`${selectedProduct?.product_title}`}
              className={classes.productCardImage}
            />
            <h4 className={classes.productCardTitle}>
              {selectedProduct?.product_title}
            </h4>
            {generateLink(
              "listing",
              productDetails?.data?.find(
                (p) => p.id === parseInt(selectedProduct?.id)
              ),
              null,
              productIdentifier,
              {
                fontWeight: 500,
                margin: "1rem",
                color: "#d91266",
                textDecoration: "underline",
              }
            )}
            <p>
              {selectedProduct?.product_price &&
                toCurrency(
                  selectedProduct.product_price,
                  marketPlace.marketPlace
                )}
              {selectedProduct?.stock_status &&
                selectedProduct?.stock_status !== "IN_STOCK" &&
                ` - ${snakeCaseToSentenceCase(selectedProduct.stock_status)}`}
            </p>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <NavFilter
                cardNavOptions={cardNavOptions}
                selectedCardView={selectedCardView}
                setSelectedCardView={setSelectedCardView}
              />
            </Grid>
            {/* Advertisement Stats */}
            {selectedCardView.label === "Advertisements" && !productLevelAdStats
              ? null
              : selectedCardView.label === "Advertisements" && (
                  <Carousel autoPlay={false} className={classes.carousel}>
                    <Grid container style={{ marginBottom: "-50px" }}>
                      {/* Carousel container for advertisements tab (pg 1)*/}
                      <Grid item xs={6} className={classes.statTile}>
                        <GVStatsCard
                          disableWrap
                          border="none"
                          valueAlign="center"
                          statsText={"Total Sales"}
                          statsValue={
                            <span className={classes.statValues}>
                              {productStats?.total?.total_sales > 0
                                ? toCurrency(
                                    productStats?.total?.total_sales,
                                    marketPlace.marketPlace
                                  )
                                : "-"}
                            </span>
                          }
                          tooltipText={TOTAL_SALES_TOOLTIP}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.statTile}>
                        <GVStatsCard
                          disableWrap
                          border="none"
                          valueAlign="center"
                          statsText={"Ad Sales"}
                          statsValue={
                            <span className={classes.statValues}>
                              {productStats?.total?.ad_sales
                                ? toCurrency(
                                    productStats?.total?.ad_sales,
                                    marketPlace.marketPlace
                                  )
                                : "-"}
                            </span>
                          }
                          tooltipText={AD_SALES_TOOLTIP}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "-25px" }}
                        className={classes.statTile}
                      >
                        <GVStatsCard
                          disableWrap
                          border="none"
                          valueAlign="center"
                          statsText={
                            preferences.acos_or_roas === "roas"
                              ? "Total ROAS"
                              : "Total ACOS"
                          }
                          statsValue={
                            <span className={classes.statValues}>
                              {preferences.acos_or_roas === "roas"
                                ? (+productStats?.total?.total_roas.toFixed(
                                    2
                                  )).toLocaleString()
                                : parseFloat(
                                    (
                                      productStats?.total?.total_acos * 100
                                    ).toFixed(0)
                                  ).toLocaleString() + "%"}
                            </span>
                          }
                          tooltipText={TOTAL_ACOS_TOOLTIP}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "-25px" }}
                        className={classes.statTile}
                      >
                        <GVStatsCard
                          disableWrap
                          border="none"
                          valueAlign="center"
                          statsText={
                            preferences.acos_or_roas === "roas"
                              ? "ROAS"
                              : "ACOS"
                          }
                          statsValue={
                            <span className={classes.statValues}>
                              {preferences.acos_or_roas === "roas"
                                ? (+productStats?.total?.roas.toFixed(
                                    2
                                  )).toLocaleString()
                                : parseFloat(
                                    (productStats?.total?.acos * 100).toFixed(0)
                                  ).toLocaleString() + "%"}
                            </span>
                          }
                          tooltipText={ACOS_TOOLTIP}
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "-50px" }}>
                      {/* Carousel container for advertisements tab (pg 2) */}
                      <Grid item xs={6} className={classes.statTile}>
                        <GVStatsCard
                          disableWrap
                          border="none"
                          valueAlign="center"
                          statsText={"Ad Spend"}
                          statsValue={
                            <span className={classes.statValues}>
                              {productStats?.total?.cost
                                ? toCurrency(
                                    productStats?.total?.cost,
                                    marketPlace.marketPlace
                                  )
                                : "-"}
                            </span>
                          }
                          tooltipText={AD_SPEND_TOOLTIP}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.statTile}>
                        <GVStatsCard
                          disableWrap
                          border="none"
                          valueAlign="center"
                          statsText={"Clicks"}
                          statsValue={
                            <span className={classes.statValues}>
                              {productStats?.total?.clicks > 0
                                ? formatNumber(productStats?.total?.clicks)
                                : "-"}
                            </span>
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "-25px" }}
                        className={classes.statTile}
                      >
                        <GVStatsCard
                          disableWrap
                          border="none"
                          valueAlign="center"
                          statsText={"Impressions"}
                          statsValue={
                            <span className={classes.statValues}>
                              {productStats?.total?.impressions > 0
                                ? formatNumber(productStats?.total?.impressions)
                                : "-"}
                            </span>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Carousel>
                )}
            {/* Purchase Behavior Stats */}
            {selectedCardView.label === "Purchase Behavior" && (
              <Carousel autoPlay={false} className={classes.carousel}>
                <Grid container style={{ marginBottom: "-50px" }}>
                  {/* Carousel container for purchase behavior tab (pg 1) */}
                  <Grid item xs={6} className={classes.statTile}>
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Repeat Brand Sales"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.total_brand_sales_repeat
                            ? toCurrency(
                                selectedProductPbData?.total_brand_sales_repeat,
                                marketPlace.marketPlace
                              )
                            : "-"}
                        </span>
                      }
                      tooltipText={REPEAT_BRAND_SALES_TOOLTIP}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.statTile}>
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Repeat Sales"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.total_sales_repeat
                            ? toCurrency(
                                selectedProductPbData?.total_sales_repeat,
                                marketPlace.marketPlace
                              )
                            : "-"}
                        </span>
                      }
                      tooltipText={REPEAT_SALES_TOOLTIP}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: "-25px" }}
                    className={classes.statTile}
                  >
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Brand CV"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.brand_cv
                            ? toCurrency(
                                selectedProductPbData?.brand_cv,
                                marketPlace.marketPlace
                              )
                            : "-"}
                        </span>
                      }
                      tooltipText={BRAND_CV_TOOLTIP}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: "-25px" }}
                    className={classes.statTile}
                  >
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Product CV"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.cv
                            ? toCurrency(
                                selectedProductPbData.cv,
                                marketPlace.marketPlace
                              )
                            : "-"}
                        </span>
                      }
                      tooltipText={PRODUCT_CV_TOOLTIP}
                    />
                  </Grid>
                </Grid>
                {/* Carousel container for purchase behavior tab (pg 2) */}
                <Grid container style={{ marginBottom: "-50px" }}>
                  <Grid item xs={6} className={classes.statTile}>
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Repeat Brand Customers"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.total_brand_customers_repeat
                            ? selectedProductPbData.total_brand_customers_repeat.toFixed(
                                0
                              )
                            : "-"}
                        </span>
                      }
                      tooltipText={REPEAT_BRAND_CUSTOMERS_TOOLTIP}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.statTile}>
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Repeat Customers"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.total_customers_repeat
                            ? selectedProductPbData.total_customers_repeat.toFixed(
                                0
                              )
                            : "-"}
                        </span>
                      }
                      tooltipText={REPEAT_TOOLTIP}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: "-25px" }}
                    className={classes.statTile}
                  >
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Frequency"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.purchase_freq > 0
                            ? `${getTimeDescription(
                                selectedProductPbData.purchase_freq
                              )}`
                            : ""}
                        </span>
                      }
                      tooltipText={FREQUENCY_TOOLTIP}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: "-25px" }}
                    className={classes.statTile}
                  >
                    <GVStatsCard
                      disableWrap
                      border="none"
                      valueAlign="center"
                      statsText={"Halo Rank"}
                      statsValue={
                        <span className={classes.statValues}>
                          {selectedProductPbData?.total_brand_sales_repeat_rank
                            ? selectedProductPbData.total_brand_sales_repeat_rank.toFixed(
                                0
                              )
                            : "-"}
                        </span>
                      }
                      tooltipText={HALO_RANK_TOOLTIP}
                    />
                  </Grid>
                </Grid>
              </Carousel>
            )}
            {selectedCardView.label === "Purchase Behavior" && (
              <Grid item xs={12} style={{ padding: "1rem" }}>
                <h3 className={classes.tableHeader}>
                  Products Bought After a Purchase
                </h3>
                {followupProducts.length < 1 ? (
                  <span className={classes.noFollowupProductMessage}>
                    There are no followup products to display
                  </span>
                ) : (
                  <ReactTable
                    data={followupProducts}
                    columns={[
                      {
                        Header: "Product",
                        accessor: "id",
                        headerStyle: {
                          textAlign: "center",
                        },
                        Cell: (props) => {
                          return (
                            <div className={classes.followupProductCell}>
                              <ProductImage
                                divId={"productImage" + props.value}
                                id={props.value}
                                width="25px"
                                height="25px"
                              />
                              <span
                                style={{
                                  marginLeft: "1.5rem",
                                  color: "#d91266",
                                  textAlign: "center",
                                  float: "center",
                                  textDecoration: "underline",
                                  fontWeight: "500",
                                  cursor: "pointer",
                                  fontSize:
                                    productIdentifier === "sku"
                                      ? "1.1rem"
                                      : "1.5rem",
                                }}
                                onClick={() => {
                                  setSelectedProduct(
                                    pbData.find(
                                      (p) =>
                                        parseInt(p.product?.id) ===
                                        parseInt(props.value)
                                    )?.product
                                  );
                                }}
                              >
                                {
                                  productDetails?.data?.find(
                                    (p) => p.id === parseInt(props.value)
                                  )?.asin
                                }
                              </span>
                            </div>
                          );
                        },
                        width: productIdentifier === "sku" ? 220 : 200,
                      },
                      {
                        Header: "# of Follow-Up Purchases",
                        accessor: "count",
                        headerStyle: {
                          textAlign: "center",
                        },
                        Cell: (props) => {
                          return (
                            <div
                              className={classes.followupProductCell}
                              style={{ padding: "1rem" }}
                            >
                              <div
                                style={{
                                  width: "75%",
                                  height: "2rem",
                                }}
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    width: `${
                                      (parseInt(props.value) /
                                        followupPurchaseMax) *
                                      100
                                    }%`,
                                    backgroundColor: "#B9A4CE",
                                  }}
                                />
                              </div>
                              <span
                                style={{
                                  margin: "auto",
                                  color: "#000",
                                  fontWeight: 500,
                                }}
                              >
                                {props.value}
                              </span>
                            </div>
                          );
                        },
                      },
                    ]}
                    showPagination={false}
                    sortable={false}
                    pageSize={
                      followupProducts.length < 5 ? followupProducts.length : 5
                    }
                    minRows={0}
                    defaultSorted={[
                      {
                        id: "count",
                        desc: true,
                      },
                    ]}
                    className="-striped -highlight"
                  />
                )}
              </Grid>
            )}
            {selectedCardView.label === "Advertisements" && (
              <Grid item xs={12} style={{ padding: "1rem" }}>
                {!productLevelAdStats ? (
                  <div className={classes.noAdvertisementsContainer}>
                    <p className={classes.noAdvertisementsMessage}>
                      This product is not associated with any advertisements.
                      Please click below to create your first media plan with
                      this product or to add it to an existing media plan.
                    </p>
                    <AdPlanModal
                      dashboard={!selectedCategory}
                      categoryId={selectedCategory}
                      categories={categories}
                      roas={preferences.acos_or_roas === "roas"}
                      isVideoShortcut={true}
                    />
                  </div>
                ) : (
                  <Fragment>
                    <h3 className={classes.tableHeader}>
                      Advertisements Overview
                    </h3>
                    <ReactTable
                      data={
                        productAdDetails?.data?.length > 0
                          ? productAdDetails?.data?.map((adD) => ({
                              advertisement: adD,
                              stats: productLevelAdStats.find(
                                (ad) => adD.id === ad?.advertisement?.id
                              ),
                            }))
                          : []
                      }
                      className={`-striped -highlight ${classes.advertisementTable}`}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "advertisement.name",
                          headerStyle: {
                            textAlign: "center",
                          },
                          width: 160,
                          Cell: (props) => {
                            return (
                              <div className={classes.followupProductCell}>
                                {generateLink(
                                  "advertisement",
                                  props.row._original.advertisement,
                                  null,
                                  productIdentifier,
                                  {
                                    fontSize: "1.1rem",
                                  }
                                )}
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Ad Spend",
                          accessor: "cost",
                          width: 75,
                          Cell: (props) => {
                            return (
                              <div
                                className={`${classes.followupProductCell} ${classes.centeredCell}`}
                              >
                                {props.row._original?.stats?.cost
                                  ? formatNumber(
                                      props.row._original?.stats?.cost,
                                      {
                                        currency: {
                                          marketPlace: marketPlace.marketPlace,
                                        },
                                      }
                                    )
                                  : ""}
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Ad Sales",
                          accessor: "stats.count",
                          headerStyle: {
                            textAlign: "center",
                          },
                          width: 75,
                          Cell: (props) => {
                            return (
                              <div
                                className={`${classes.followupProductCell} ${classes.centeredCell}`}
                              >
                                {props.row._original.stats?.attributed_sales_7d
                                  ? formatNumber(
                                      props.row._original.stats
                                        ?.attributed_sales_7d,
                                      {
                                        currency: {
                                          marketPlace: marketPlace.marketPlace,
                                        },
                                      }
                                    )
                                  : ""}
                              </div>
                            );
                          },
                        },
                        {
                          Header:
                            preferences.acos_or_roas === "roas"
                              ? "ROAS"
                              : "ACOS",
                          headerStyle: {
                            textAlign: "center",
                          },
                          accessor:
                            preferences.acos_or_roas === "roas"
                              ? "attributed_roas_7d"
                              : "attributed_acos_7d",
                          width: 75,
                          Cell: (props) => {
                            return (
                              <div
                                className={`${classes.followupProductCell} ${classes.centeredCell}`}
                              >
                                {preferences.acos_or_roas === "roas"
                                  ? props.row._original.stats
                                      ?.attributed_roas_7d
                                    ? +props.row._original.stats?.attributed_roas_7d?.toFixed(
                                        2
                                      )
                                    : ""
                                  : props.row._original.stats
                                      ?.attributed_acos_7d
                                  ? +(
                                      props.row._original.stats
                                        ?.attributed_acos_7d * 100
                                    ).toFixed(2) + "%"
                                  : ""}
                              </div>
                            );
                          },
                        },
                      ]}
                      showPagination={false}
                      sortable={false}
                      pageSize={
                        followupProducts.length < 5
                          ? followupProducts.length
                          : 5
                      }
                      // This is needed to eliminate the no data message that covers table headers in case of no associated product ads
                      NoDataComponent={() => null}
                      minRows={0}
                      defaultSorted={[
                        {
                          id: "count",
                          desc: true,
                        },
                      ]}
                    />
                    <AdPlanModal
                      dashboard={!selectedCategory}
                      categoryId={selectedCategory}
                      categories={categories}
                      roas={preferences.acos_or_roas === "roas"}
                      isVideoShortcut={true}
                    />
                  </Fragment>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ProductCard.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(useStyles)(ProductCard));
