import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Grid, withStyles } from "@material-ui/core";
import Card from "components/core/basic/Card.jsx";
import { SecondaryButton } from "assets/themes/Buttons";
import getURLPrefix from "utils/getUrlPrefix";
import { setAllowMwsOrders } from "redux/actions/userActions";
import store from "redux/store";
import {
  setProductIndentifier,
  setPerformanceMetric,
} from "redux/actions/userActions";
import { selectModules } from "redux/selectors/modules";
import NotificationPreferences from "./NotificationPreferences";

let URL_PREFIX = getURLPrefix();

const useStyles = (theme) => ({
  preferenceContainer: {
    marginTop: theme.spacing(5),
  },
  preferenceTitle: {
    margin: "0px 0px",
    paddingLeft: theme.spacing(2),
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2, 2),
      textAlign: "center",
    },
  },
  preferenceDescriptionContainer: {
    margin: "0px 0px",
    height: "40px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  preferenceDescription: {
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2),
      textAlign: "center",
      margin: "auto",
    },
  },
  checkboxContainer: {
    height: "40px",
    display: "flex",
    justifyContent: "center",
  },
  switchContainer: {
    height: "40px",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  checkbox: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  checkboxLabel: {
    textTransform: "none",
    color: "#73706E",
    fontWeight: "700",
  },
});

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAllowMwsOrders: false,
      originalSelectedAllowMwsOrders: false,
      asin: true,
      sku: false,
      productIdentifier: "asin",
      originalProductIdentifier: "asin",
      acos: true,
      roas: false,
      performanceMetric: "acos",
      originalPerformanceMetric: "acos",
      message: "",
      errorMessage: "",
      saveRedirect: false,
    };
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    axios
      .get(URL_PREFIX + "/api/org_permissions/")
      .then((res) => {
        if (res.data) {
          if (res.data && "allow_mws_orders" in res.data) {
            var selectedAllowMwsOrders = res.data["allow_mws_orders"];
            this.setState({
              selectedAllowMwsOrders: selectedAllowMwsOrders,
              originalSelectedAllowMwsOrders: selectedAllowMwsOrders,
            });
          }
          if (res.data && "product_identifier" in res.data) {
            var productIdentifier = res.data["product_identifier"];
            var asin = false;
            var sku = false;
            if (productIdentifier === "asin") {
              asin = true;
            }
            if (productIdentifier === "sku") {
              sku = true;
            }

            this.setState({
              productIdentifier: productIdentifier,
              originalProductIdentifier: productIdentifier,
              asin: asin,
              sku: sku,
            });
          }
          if (res.data && "preferences" in res.data) {
            let performanceMetric = res.data["preferences"]["acos_or_roas"];
            let acos = false;
            let roas = false;

            if (performanceMetric === "acos") {
              acos = true;
            }
            if (performanceMetric === "roas") {
              roas = true;
            }

            this.setState({
              performanceMetric: performanceMetric,
              originalPerformanceMetric: performanceMetric,
              acos: acos,
              roas: roas,
            });
          }
        } else {
          this.setState({
            message: "",
            errorMessage: "Failed loading permissions!",
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: "",
          errorMessage: "Failed loading permissions!",
        });
      });
  }

  async onSave() {
    this.setState({ message: "Saving permissions...", errorMessage: "" });
    if (
      this.state.originalSelectedAllowMwsOrders !==
        this.state.selectedAllowMwsOrders ||
      this.state.originalProductIdentifier !== this.state.productIdentifier ||
      this.state.originalPerformanceMetric !== this.state.performanceMetric
    ) {
      const data = {
        allow_mws_orders: this.state.selectedAllowMwsOrders,
        product_identifier: this.state.productIdentifier,
        acos_or_roas: this.state.performanceMetric,
      };

      await axios
        .put(URL_PREFIX + "/api/org_permissions/", data)
        .then((res) => {
          if (
            this.state.originalSelectedAllowMwsOrders !==
            this.state.selectedAllowMwsOrders
          ) {
            store.dispatch(
              setAllowMwsOrders(this.state.selectedAllowMwsOrders)
            );
          }

          if (
            this.state.originalProductIdentifier !==
            this.state.productIdentifier
          ) {
            store.dispatch(setProductIndentifier(this.state.productIdentifier));
          }

          if (
            this.state.originalPerformanceMetric !==
            this.state.performanceMetric
          ) {
            store.dispatch(
              setPerformanceMetric(
                this.state.performanceMetric,
                this.props.user.preferences
              )
            );
          }

          this.props.handleNotification(
            "tr",
            "success",
            "pe-7s-check",
            "Saved your preferences."
          );
          this.setState(
            {
              message: "Saved preferences",
              errorMessage: "",
              saveRedirect: true,
            },
            () =>
              setTimeout(() => {
                this.props.user.permissions === "reports"
                  ? this.props.history.push("/user/report/advertisement")
                  : this.props.history.push("/user/dashboard");
              }, 2000)
          );
        })
        .catch((err) => {
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "Failed to save your preferences, please retry."
          );
          this.setState({
            message: "",
            errorMessage: "Failed saving user preferences!",
          });
        });
    } else {
      // No need to save anything, redirect to dashboard
      this.props.user.permissions === "reports"
        ? this.props.history.push("/user/report/advertisement")
        : this.props.history.push("/user/dashboard");
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="container">
          <div>
            <div>
              <div className="text-center">
                <h4 className="fw-bold">Preferences</h4>
              </div>
              <Grid container item xs={12} lg={10} className="mx-auto">
                <Card
                  style={{ width: "100%" }}
                  content={
                    <div style={{ width: "100%" }}>
                      <div>
                        <Grid container className={classes.preferenceContainer}>
                          <Grid item xs={12}>
                            <h5 className={classes.preferenceTitle}>
                              Products
                            </h5>
                          </Grid>
                        </Grid>
                        {this.props.channel?.currentChannel !== "walmart" && (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              className={classes.preferenceDescriptionContainer}
                            >
                              <div className={classes.preferenceDescription}>
                                Select your preferred product identifier
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={2}
                              className={classes.checkboxContainer}
                            >
                              <div
                                className={`checkbox checkbox-inline ${classes.checkbox}`}
                              >
                                <h5
                                  className={`title ${classes.checkboxLabel}`}
                                >
                                  <input
                                    id={40}
                                    type="checkbox"
                                    onClick={(e) => {
                                      this.setState({
                                        sku: true,
                                        asin: false,
                                        productIdentifier: "sku",
                                      });
                                    }}
                                    checked={this.state.sku}
                                  />
                                  <label htmlFor={40}>{"SKU"}</label>
                                </h5>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={2}
                              className={classes.checkboxContainer}
                            >
                              <div
                                className={`checkbox checkbox-inline ${classes.checkbox}`}
                              >
                                <h5
                                  className={`title ${classes.checkboxLabel}`}
                                >
                                  <input
                                    id={41}
                                    type="checkbox"
                                    onClick={(e) => {
                                      this.setState({
                                        asin: true,
                                        sku: false,
                                        productIdentifier: "asin",
                                      });
                                    }}
                                    checked={this.state.asin}
                                  />
                                  <label htmlFor={41}>{"ASIN"}</label>
                                </h5>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container className={classes.preferenceContainer}>
                          <Grid item xs={12}>
                            <h5 className={classes.preferenceTitle}>
                              Performance Metric
                            </h5>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            className={classes.preferenceDescriptionContainer}
                          >
                            <div className={classes.preferenceDescription}>
                              Select your preferred performance metric
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={2}
                            className={classes.checkboxContainer}
                          >
                            <div
                              className={`checkbox checkbox-inline ${classes.checkbox}`}
                            >
                              <h5 className={`title ${classes.checkboxLabel}`}>
                                <input
                                  id={42}
                                  type="checkbox"
                                  onClick={(e) => {
                                    this.setState({
                                      acos: true,
                                      roas: false,
                                      performanceMetric: "acos",
                                    });
                                  }}
                                  checked={this.state.acos}
                                />
                                <label htmlFor={42}>{"ACOS"}</label>
                              </h5>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={2}
                            className={classes.checkboxContainer}
                          >
                            <div
                              className={`checkbox checkbox-inline ${classes.checkbox}`}
                            >
                              <h5 className={`title ${classes.checkboxLabel}`}>
                                <input
                                  id={43}
                                  type="checkbox"
                                  onClick={(e) => {
                                    this.setState({
                                      acos: false,
                                      roas: true,
                                      performanceMetric: "roas",
                                    });
                                  }}
                                  checked={this.state.roas}
                                />
                                <label htmlFor={43}>{"ROAS"}</label>
                              </h5>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  }
                  ftTextCenter
                  legend={
                    <SecondaryButton
                      onClick={(e) => this.onSave()}
                      style={{ margin: "2rem auto" }}
                    >
                      Save
                    </SecondaryButton>
                  }
                />
              </Grid>
            </div>
          </div>
        </div>

        <NotificationPreferences
          handleNotification={this.props.handleNotification}
        />
      </>
    );
  }
}

Preferences.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  modules: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  errors: state.errors,
  modules: selectModules(state),
  channel: state.channel,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(Preferences))
);
