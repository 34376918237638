export const SLIDER_HELP = {
  0: {
    title: "Maximize Volume",
    description:
      "Dynamic Pricing aims to Maximize sales volume up to 20%. This is achieved by reducing the price, provided that there are no seasonal factors negatively impacting volume. Page views and advertising spend needs to be sustained to historical levels for dynamic pricing to be effective. For those looking to boost volume without sacrificing margin, the Balance Strategy is the recommended option.",
  },
  1: {
    title: "Boost Volume",
    description:
      "Dynamic Pricing aims to boost sales volume up to 10%. This is achieved by reducing the price, provided that there are no seasonal factors negatively impacting volume. Page views and advertising spend needs to be sustained to historical levels for dynamic pricing to be effective. For those looking to boost volume without sacrificing margin, the Balance Strategy is the recommended option.",
  },
  2: {
    title: "Balanced Strategy",
    description:
      "The Balance Strategy is designed to optimize profit margins per unit, while keeping sales volume steady across your product's seasonal cycle. It employs Dynamic Pricing to identify the most profitable pricing point for your product during its peak season.",
  },
  3: {
    title: "Boost Margin",
    description:
      "Dynamic Pricing aims to secure up to an additional 3% in profit margins by increasing prices, which may result in a decrease in sales volume. Seasonal trends can lead to downward price adjustments, affecting margins. For those looking to boost margins without sacrificing volume, the Balance Strategy is the recommended option.",
  },
  4: {
    title: "Maximize Margin",
    description:
      "Dynamic Pricing targets achieving an increase of up to 5% in profit margins through strategic price adjustments, which may result in a decrease in sales volume. Seasonal trends can lead to downward price adjustments, affecting margins. For those looking to boost margins without sacrificing volume, the Balance Strategy is the recommended option.",
  },
};
