import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, useTheme, Grid } from "@material-ui/core";
import moment from "moment";
import useOnScreen from "hooks/useOnScreen";
import { useFetch } from "hooks/api";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import withMediaQuery from "hocs/withMediaQuery";
import { PrimaryButton, PlainButton } from "../../../assets/themes/Buttons";
import generateLink from "utils/generateLink";
import ToolTips from "utils/toolTips";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { trellisPalette } from "../analytics/palettes";

const useStyles = (theme) => ({
  notification: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 3),
    width: "100%",
    margin: theme.spacing(2, "auto"),
    border: "none",
    position: "relative",
  },
  notificationImage: {
    width: "65px",
    height: "65px",
    objectFit: "cover",
  },
  icon: {
    fontSize: theme.typography.notificationIcon.fontSize,
  },
  categoryName: {
    fontSize: theme.typography.notificationTitle.fontSize,
    fontWeight: 500,
    wordBreak: "break-word",
  },
  notificationType: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 500,
    padding: theme.spacing(0, 1, 2, 0),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  notificationTitle: {
    display: "grid",
    flexDirection: "column",
    alignContent: "center",
    gridTemplateRows: "1fr auto",
    justifyContent: "flex-start",
    margin: "auto",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  notificationDetails: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: "1.7rem",
  },
  notificationLink: {
    color: "#d91266",
    textAlign: "center",
    float: "center",
    textDecoration: "none",
    fontWeight: "500",
    fontSize: theme.typography.h5.fontSize,
    cursor: "pointer",
  },
  highPotentialDescription: {
    marginTop: theme.spacing(1),
    fontSize: "1.4rem",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    border: "none",
    background: "inherit",
  },
  timestamp: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  suggestedAction: {
    fontSize: "1.3rem",
  },
  actionForm: {
    display: "flex",
    maxWidth: "250px",
    padding: theme.spacing("auto", 2),
  },
  actionInput: {
    marginRight: theme.spacing(5),
    maxWidth: "65px",
    borderRadius: "1rem",
    border: "1px solid #CCC",
    padding: theme.spacing(1),
    position: "relative",
  },
  inputPercentPlaceholder: {
    position: "absolute",
    marginLeft: theme.spacing(4),
    marginTop: "9px",
  },
  centeredMobileText: {
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(1, 0),
  },
  notificationButton: {
    transform: "none",
    maxHeight: theme.spacing(8),
  },
  detailsButtonContainer: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  actionSubtitle: {
    textTransform: "none",
    fontSize: "1.4rem",
  },
  lowStockDetails: {
    marginTop: theme.spacing(4),
  },
  detailSubtitle: {
    fontWeight: 500,
    fontSize: "1.7rem",
  },
  detailList: {
    width: "90%",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (min-width:500px)"]: {
      width: "95%",
    },
  },
  detailListItem: {
    padding: theme.spacing(1, 0),
    fontSize: "1.4rem",
    maxWidth: "90%",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (min-width:500px)"]: {
      width: "100%",
    },
  },
  lowStockListingIcon: {
    color: "#555",
    paddingTop: ".7rem",
  },
  outOfStockListingIcon: {
    color: "#555",
    paddingTop: ".6rem",
  },
  newIcon: {
    height: "85%",
    maxHeight: "35px",
    width: "45px",
    marginLeft: theme.spacing(-1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  moreButtonContainer: {
    position: "absolute",
    left: "16%",
    right: 0,
    width: "100%",
  },
  confirmContainer: {
    gridColumn: "1 / span 5",
    fontSize: "1.2rem",
    padding: theme.spacing(1, 0),
  },
  confirmMessage: {
    padding: theme.spacing(1, 0),
    color: trellisPalette[0],
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
  confirmButtons: {
    width: "100%",
    marginTop: "-10px",
  },
  confirmButton: {
    padding: theme.spacing(1, 3),
    color: "#FFF",
    background: trellisPalette[0],
    border: `1.5px solid ${trellisPalette[0]}`,
    borderRadius: "1rem",
    fontSize: ".9rem",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1.4px",
  },
});

const NotificationItem = ({
  data: {
    id,
    notification_type,
    action_type,
    target_type,
    target_id,
    details,
    created_on,
    updated_on,
    clicked_on,
    dismissed_on,
    seen_on,
  },
  classes,
  dropdownLayout,
  mediaQuery,
  hideDropdown,
  user: {
    productIdentifier,
    preferences: { acos_or_roas },
  },
}) => {
  const [showExpandedDetails, setShowExpandedDetails] = useState(false);
  const [expandedPage, setExpandedPage] = useState(1);
  const [actionPercentage, setActionPercentage] = useState(
    action_type === "DECREASE_AD_BUDGET" ? -10 : 10
  );
  const [showActionConfirmation, setShowActionConfirmation] = useState(false);

  const EXPANDED_PAGE_SIZE = 10;

  const notificationRef = useRef();
  const seenRef = useRef(seen_on);

  const isVisible = useOnScreen(notificationRef);
  const queryClient = useQueryClient();

  let baseQuery = {};

  if (target_type === "advertisement" || target_type === "product_ad") {
    baseQuery["advertisement"] = target_id;
  }

  if (notification_type === "AD_PRODUCT_EXCEEDS_ACOS_SPEND") {
    baseQuery["advertisement"] = details?.data?.[0]?.[0]?.advertisement_id;
  }
  // Get ad details - needed for notification images
  const { data: adData } = useFetch(
    ["advertisementDetails", target_id],
    "/api/gvads/advproduct",
    {
      ...baseQuery,
    },
    {
      enabled: !!baseQuery["advertisement"],
    }
  );

  const allProductsBackInStock = useMemo(() => {
    let productDetails = Object.values(details?.data?.[0]);
    if (productDetails.every((p) => p.stock_status === "IN_STOCK")) {
      return true;
    }
    return false;
  }, [details]);

  const allProductsBackToEligible = useMemo(() => {
    let productDetails = Object.values(details?.data?.[0]);
    if (productDetails.every((p) => p.eligibility_status === "ELIGIBLE")) {
      return true;
    }
    return false;
  }, [details]);
  // This is used to make sure default action values are accurate - sometimes delay in props after dismissing other notifications is causing incorrect defaults
  useEffect(() => {
    if (action_type === "DECREASE_AD_BUDGET") {
      setActionPercentage(-10);
    } else {
      setActionPercentage(10);
    }
  }, [action_type]);

  const updateNotification = useMutation(
    async (updatedNotificationData) => {
      return await api.put(`/api/notifications/`, updatedNotificationData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("notifications");
      },
    }
  );

  const editNotification = (id, action, actionValue = null) => {
    let notificationData = {
      notification_id: id,
      notification_update_type: action,
    };

    if (action === "clicked" && actionValue) {
      notificationData["notification_action_data"] = Math.abs(actionValue);
    }

    updateNotification.mutate(notificationData);
  };

  const updateSeen = useCallback(() => {
    if (!seen_on && isVisible) {
      editNotification(id, "seen");
      seenRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seen_on, isVisible, id]);

  // NOTE TO DEVS: Comment out when testing with prod - no access to update api_notificationuser table
  if (seenRef.current !== true && isVisible) {
    updateSeen();
  }

  const getNotificationTime = () => {
    const minutesAgo = moment().diff(moment(created_on), "minutes");
    return moment.duration(minutesAgo * -1, "minutes").humanize(true);
  };

  const renderNotificationDetails = () => {
    switch (notification_type) {
      case "AD_PRODUCT_LOW_OF_STOCK":
        return (
          <Fragment>
            <p
              className={`${classes.notificationDetails} ${
                mediaQuery ? classes.centeredMobileText : ""
              }`}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "left",
                marginLeft: dropdownLayout || mediaQuery ? 0 : theme.spacing(4),
              }}
            >
              {generateLink(
                target_type,
                adData?.data,
                hideDropdown,
                productIdentifier,
                { fontSize: "1.7rem" }
              )}{" "}
              <>
                {" "}
                {target_type.toLowerCase()}{" "}
                {allProductsBackInStock ? "had" : "has"}{" "}
                {Object.values(details[0])?.length} product
                {Object.values(details[0])?.length > 1 && "s"} low in stock
              </>
            </p>
            <div
              className={`${
                mediaQuery || dropdownLayout
                  ? classes.detailsButtonContainer
                  : ""
              }`}
              style={{
                display: "flex",
                alignContent: "center",
                margin: theme.spacing(
                  2,
                  2,
                  2,
                  mediaQuery || dropdownLayout ? 2 : 4
                ),
              }}
            >
              <PlainButton
                onClick={(e) => {
                  e.preventDefault();
                  if (showExpandedDetails) {
                    setExpandedPage(1);
                  }
                  setShowExpandedDetails(!showExpandedDetails);
                }}
              >{`${
                showExpandedDetails ? "Hide" : "Show"
              } Details`}</PlainButton>
            </div>
          </Fragment>
        );
      case "AD_PRODUCT_OUT_OF_STOCK":
      case "AD_PRODUCT_INELIGIBLE":
        return (
          <Fragment>
            <p
              className={`${classes.notificationDetails} ${
                mediaQuery ? classes.centeredMobileText : ""
              }`}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "left",
              }}
            >
              {generateLink(
                target_type,
                adData?.data,
                hideDropdown,
                productIdentifier,
                { fontSize: "1.7rem" }
              )}{" "}
              {notification_type === "AD_PRODUCT_OUT_OF_STOCK" && (
                <>
                  {target_type.toLowerCase()}{" "}
                  {allProductsBackInStock ? "had" : "has"}{" "}
                  {Object.values(details[0])?.length} product
                  {Object.values(details[0])?.length > 1 && "s"} out of stock
                </>
              )}
              {notification_type === "AD_PRODUCT_INELIGIBLE" && (
                <>
                  {target_type.toLowerCase()}{" "}
                  {allProductsBackToEligible ? "had" : "has"}{" "}
                  {Object.values(details[0])?.length} ineligible product
                  {Object.values(details[0])?.length > 1 && "s"}
                </>
              )}
            </p>
            <div
              className={`${
                mediaQuery || dropdownLayout
                  ? classes.detailsButtonContainer
                  : ""
              }`}
              style={{
                display: "flex",
                alignContent: "center",
                marginTop: theme.spacing(2),
              }}
            >
              <PlainButton
                onClick={() => {
                  if (showExpandedDetails) {
                    setExpandedPage(1);
                  }
                  setShowExpandedDetails(!showExpandedDetails);
                }}
              >
                {`${showExpandedDetails ? "Hide" : "Show"} Details`}
              </PlainButton>
            </div>
          </Fragment>
        );
      case "AD_NEW_KWS_ASINS":
        return (
          <Fragment>
            <p
              className={`${classes.notificationDetails} ${
                mediaQuery ? classes.centeredMobileText : ""
              }`}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "left",
              }}
            >
              {generateLink(
                target_type,
                adData?.data,
                hideDropdown,
                productIdentifier,
                { fontSize: "1.7rem" }
              )}{" "}
              {target_type.toLowerCase()} has{" "}
              {details[0]["asins"]?.length === 1
                ? "a new ASIN "
                : details[0]["asins"]?.length > 1
                ? "new ASINs "
                : null}
              {details[0]["kws"]?.length === 1 &&
              details[0]["asins"]?.length > 0
                ? "and a new keyword"
                : details[0]["kws"]?.length > 1 &&
                  details[0]["asins"]?.length === 0
                ? "new keywords"
                : details[0]["asins"]?.length > 0
                ? "and new keywords"
                : details[0]["asins"]?.length === 0 &&
                  details[0]["kws"]?.length === 1
                ? "a new keyword"
                : ""}
            </p>
            <div
              className={`${
                mediaQuery || dropdownLayout
                  ? classes.detailsButtonContainer
                  : ""
              }`}
              style={{
                display: "flex",
                alignContent: "center",
                marginTop: theme.spacing(2),
              }}
            >
              <PlainButton
                onClick={() => {
                  if (showExpandedDetails) {
                    setExpandedPage(1);
                  }
                  setShowExpandedDetails(!showExpandedDetails);
                }}
              >
                {`${showExpandedDetails ? "Hide" : "Show"} Details`}
              </PlainButton>
            </div>
          </Fragment>
        );
      case "AD_PRODUCT_EXCEEDS_ACOS_SPEND":
        return (
          <Fragment>
            <p
              className={`${classes.notificationDetails} ${
                mediaQuery ? classes.centeredMobileText : ""
              }`}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "left",
                marginLeft: dropdownLayout || mediaQuery ? 0 : theme.spacing(4),
              }}
            >
              <>
                Product advertisement for {details?.[0]?.[0]?.asin} in{" "}
                {generateLink(
                  "advertisement",
                  adData?.data ?? {},
                  hideDropdown,
                  "asin",
                  { fontSize: "1.7rem" },
                  false,
                  adData?.data?.name
                )}{" "}
                exceeds ACOS or ad spend limit.
              </>
            </p>
          </Fragment>
        );
      default:
        return;
    }
  };

  const renderActionDialogue = () => {
    switch (action_type) {
      case "DECREASE_AD_BUDGET":
        return (
          <Fragment>
            <h6
              className={classes.actionSubtitle}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "inherit",
              }}
            >
              Recommended Action:
            </h6>
            {/* TODO: Make amount dynamic from API */}
            <p
              className={classes.suggestedAction}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "inherit",
              }}
            >
              Decrease budget by 10%
            </p>
            <form className={classes.actionForm}>
              {showActionConfirmation ? (
                <div className={classes.confirmContainer}>
                  <p
                    className={classes.confirmMessage}
                    style={{
                      textAlign:
                        dropdownLayout || mediaQuery ? "center" : "left",
                    }}
                  >
                    Are you sure you want to lower the advertisement budget by{" "}
                    {Math.abs(actionPercentage)}%?
                  </p>
                  <div
                    className={classes.confirmButtons}
                    style={{
                      display: dropdownLayout ? "flex" : "inherit",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowActionConfirmation(false);
                      }}
                      className={`${classes.confirmButton} notification-button`}
                      style={{
                        color: trellisPalette[0],
                        background: "#fff",
                        margin:
                          dropdownLayout || mediaQuery
                            ? "none"
                            : theme.spacing(0, 2, 0, 0),
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        editNotification(id, "clicked", actionPercentage);
                        setShowActionConfirmation(false);
                      }}
                      className={`${classes.confirmButton} notification-button`}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <input
                    id="action-input"
                    className={`decrease-input ${classes.actionInput} `}
                    type="number"
                    inputMode="numeric"
                    min={-100}
                    max={0}
                    value={actionPercentage.toString()}
                    onChange={(e) => {
                      if (e.target.value < actionPercentage) {
                        setActionPercentage((prevPercent) => prevPercent - 1);
                      } else {
                        setActionPercentage((prevPercent) => prevPercent + 1);
                      }
                    }}
                  />
                  <span className={classes.inputPercentPlaceholder}>%</span>
                  <PrimaryButton
                    onClick={(e) => {
                      e.preventDefault();
                      setShowActionConfirmation(true);
                    }}
                    disabled={dismissed_on || clicked_on}
                    className={"notification-button"}
                  >
                    Apply
                  </PrimaryButton>
                </>
              )}
            </form>
          </Fragment>
        );
      case "PAUSE_PRODUCT_AD":
        return (
          <Fragment>
            <h6
              className={classes.actionSubtitle}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "inherit",
              }}
            >
              Recommended Action:
            </h6>
            {/* TODO: Make amount dynamic from API */}
            <p
              className={classes.suggestedAction}
              style={{
                textAlign: dropdownLayout || mediaQuery ? "center" : "inherit",
              }}
            >
              Pause product advertisement
            </p>
            <form className={classes.actionForm}>
              {showActionConfirmation ? (
                <div className={classes.confirmContainer}>
                  <p
                    className={classes.confirmMessage}
                    style={{
                      textAlign:
                        dropdownLayout || mediaQuery ? "center" : "left",
                    }}
                  >
                    Are you sure you want to pause this advertisement?
                  </p>
                  <div
                    className={classes.confirmButtons}
                    style={{
                      display: dropdownLayout ? "flex" : "inherit",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowActionConfirmation(false);
                      }}
                      className={`${classes.confirmButton} notification-button`}
                      style={{
                        color: trellisPalette[0],
                        background: "#fff",
                        margin:
                          dropdownLayout || mediaQuery
                            ? "none"
                            : theme.spacing(0, 2, 0, 0),
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        editNotification(id, "clicked");
                        setShowActionConfirmation(false);
                      }}
                      className={`${classes.confirmButton} notification-button`}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <PrimaryButton
                    onClick={(e) => {
                      e.preventDefault();
                      setShowActionConfirmation(true);
                    }}
                    disabled={dismissed_on || clicked_on}
                    className={"notification-button"}
                    style={{
                      margin: mediaQuery || dropdownLayout ? "auto" : "inherit",
                    }}
                  >
                    Apply
                  </PrimaryButton>
                </>
              )}
            </form>
          </Fragment>
        );
      default:
        return;
    }
  };

  const renderEligibilityDetails = (ineligibleDays, backToEligible = false) => {
    if (backToEligible) {
      return (
        <Fragment>
          <div className={`fa-li ${classes.outOfStockListingIcon}`}>
            <i className="fa fa-solid fa-check-square"></i>
          </div>{" "}
          Eligible for advertising, previously ineligible.
        </Fragment>
      );
    }

    if (ineligibleDays) {
      return (
        <Fragment>
          <div className={`fa-li ${classes.outOfStockListingIcon}`}>
            <i className="fa fa-close"></i>
          </div>{" "}
          {ineligibleDays > 30
            ? "Ineligible for advertising for more than a month."
            : `Ineligible for advertising for ${ineligibleDays} day${
                ineligibleDays > 1 ? "s" : ""
              }.`}
        </Fragment>
      );
    }
  };

  const renderStockStatusDetails = (
    outOfStockDays,
    lowStockDays,
    lowInStock = false,
    backInStock = false
  ) => {
    if (backInStock) {
      return (
        <Fragment>
          <div className={`fa-li ${classes.outOfStockListingIcon}`}>
            <i className="fa fa-solid fa-check-square"></i>
          </div>{" "}
          Back in stock, previously out of stock.
        </Fragment>
      );
    }

    if (lowInStock) {
      return (
        <Fragment>
          <div className={`fa-li ${classes.lowStockListingIcon}`}>
            <i className="fa fa-warning"></i>
          </div>{" "}
          {lowStockDays > 30
            ? "Low in stock for more than a month"
            : `Low in stock for ${lowStockDays} day${
                lowStockDays !== 1 ? "s" : ""
              }`}
          {notification_type === "AD_PRODUCT_OUT_OF_STOCK" &&
            ", previously out of stock"}
          .
        </Fragment>
      );
    }

    if (outOfStockDays) {
      return (
        <Fragment>
          <div className={`fa-li ${classes.outOfStockListingIcon}`}>
            <i className="fa fa-close"></i>
          </div>{" "}
          {outOfStockDays > 30
            ? "Out of stock for more than a month."
            : `Out of stock for ${outOfStockDays} day${
                outOfStockDays > 1 ? "s" : ""
              }.`}
        </Fragment>
      );
    }
  };

  const renderAsinKeywordDetails = (asins, keywords) => {
    return (
      <Grid container style={{ marginLeft: theme.spacing(4) }}>
        {asins.length > 0 && (
          <Grid item xs={12} md={dropdownLayout || mediaQuery ? 12 : 6}>
            <h4 className={classes.detailSubtitle}>
              New Target ASIN{asins.length > 1 && "s"}{" "}
              <ToolTips
                toolTip={
                  "A list of competitor ASINs targeting similar keywords"
                }
                position="top"
                id={"harvestedAsinsTooltip"}
              />
            </h4>

            <ul className="fa-ul">
              {asins.map(
                (asin, index) =>
                  index < expandedPage * EXPANDED_PAGE_SIZE && (
                    <li
                      className={`${classes.detailListItem}`}
                      key={`asin${index}`}
                    >
                      <div className={`fa-li ${classes.newIcon}`}>
                        <i className="fa fa-solid fa-plus"></i>
                      </div>{" "}
                      {asin}
                    </li>
                  )
              )}
            </ul>
          </Grid>
        )}
        {keywords.length > 0 && (
          <Grid item xs={12} md={dropdownLayout || mediaQuery ? 12 : 6}>
            <h4 className={classes.detailSubtitle}>
              New Keyword{keywords.length > 1 && "s"}
              <ToolTips
                toolTip={
                  "A list of harvested keywords relevant to your advertisement"
                }
                position="top"
                id={"harvestedKeywordsTooltip"}
              />
            </h4>
            <ul className="fa-ul">
              {keywords.map(
                (keyword, index) =>
                  index < expandedPage * EXPANDED_PAGE_SIZE && (
                    <li
                      className={`${classes.detailListItem}`}
                      key={`kw${index}`}
                    >
                      <div className={`fa-li ${classes.newIcon}`}>
                        <i className="fa fa-solid fa-plus"></i>
                      </div>{" "}
                      {keyword}
                    </li>
                  )
              )}
            </ul>
          </Grid>
        )}
        {(keywords?.length > EXPANDED_PAGE_SIZE * expandedPage ||
          asins?.length > EXPANDED_PAGE_SIZE * expandedPage) && (
          <Grid
            container
            item
            xs={11}
            className={classes.moreButtonContainer}
            style={{
              bottom: dropdownLayout ? theme.spacing(6) : theme.spacing(3),
            }}
          >
            <PlainButton
              onClick={(e) => {
                e.preventDefault();
                setExpandedPage(expandedPage + 1);
              }}
            >
              Show More
            </PlainButton>
            {(((keywords?.length > EXPANDED_PAGE_SIZE * 2 ||
              asins?.length > EXPANDED_PAGE_SIZE * 2) &&
              expandedPage > 2) ||
              dropdownLayout) && (
              <PlainButton
                onClick={() => {
                  setExpandedPage(1);
                  setShowExpandedDetails(false);
                }}
                style={{
                  marginLeft: theme.spacing(4),
                  display: dropdownLayout ? "block" : "inherit",
                }}
              >
                Hide Details
              </PlainButton>
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  details = details?.data ?? [];
  const theme = useTheme();

  return (
    <Grid
      container
      item
      xs={12}
      className={`${classes.notification} card`}
      ref={notificationRef}
      style={{
        flexDirection: dropdownLayout || mediaQuery ? "column" : "row",
        padding:
          dropdownLayout || mediaQuery
            ? theme.spacing(2, 2, 6)
            : theme.spacing(3, 4),
      }}
    >
      <Grid
        item
        container
        xs={dropdownLayout ? 11 : mediaQuery ? 12 : action_type ? 6 : 12}
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {!dropdownLayout && !mediaQuery && (
          <Grid item xs={1}>
            <img
              src={
                notification_type === "AD_PRODUCT_EXCEEDS_ACOS_SPEND" || true
                  ? details?.[0]?.[0]?.product_image_url
                  : adData?.data?.products?.find((p) =>
                      notification_type === "AD_NEW_KWS_ASINS"
                        ? p?.asin?.toLowerCase() ===
                          details?.[0]?.["asins"]?.[0]?.toLowerCase()
                        : p?.asin === Object.keys(details?.[0])?.[0]
                    )?.product_image_url ??
                    adData?.data?.products[0]?.product_image_url ??
                    logo
              }
              alt={`${
                adData?.data?.products?.find(
                  (p) => p?.asin === Object.keys(details?.[0])?.[0]
                )?.product_title ?? adData?.data?.products[0].product_title
              }`}
              className={`${classes.notificationImage} ${
                notification_type === "AD_PRODUCT_EXCEEDS_ACOS_SPEND"
                  ? details?.[0]?.[0]?.product_image_url
                  : !adData?.data?.products?.find((p) =>
                      notification_type === "AD_NEW_KWS_ASINS"
                        ? p?.asin?.toLowerCase() ===
                          details[0]["asins"][0]?.toLowerCase()
                        : p?.asin === Object.keys(details?.[0])?.[0]
                    )?.product_image_url &&
                    !adData?.data?.products[0]?.product_image_url &&
                    "rotate"
              }`}
            />
          </Grid>
        )}
        <Grid
          item
          xs={dropdownLayout || mediaQuery ? 10 : 8}
          className={`${classes.notificationTitle} ${
            mediaQuery || dropdownLayout ? classes.centeredMobileText : ""
          }`}
          style={{
            margin: dropdownLayout || mediaQuery ? "auto" : "auto 4rem",
          }}
        >
          {renderNotificationDetails(notification_type)}
        </Grid>
      </Grid>
      <Grid item xs={dropdownLayout || mediaQuery ? 10 : action_type ? 5 : 0}>
        {action_type && renderActionDialogue()}
      </Grid>

      {showExpandedDetails && (
        <Grid
          container
          style={{
            padding:
              dropdownLayout || mediaQuery
                ? theme.spacing(1, 2, 2)
                : theme.spacing(1),
          }}
        >
          <Grid item xs={dropdownLayout || mediaQuery ? 0 : 1} />
          <Grid item xs={dropdownLayout || mediaQuery ? 12 : 10}>
            {notification_type === "AD_NEW_KWS_ASINS" ? (
              renderAsinKeywordDetails(details[0]["asins"], details[0]["kws"])
            ) : (
              <>
                <Grid container>
                  <ul
                    className={`${classes.detailList} fa-ul`}
                    style={{
                      paddingLeft:
                        dropdownLayout || mediaQuery ? 0 : theme.spacing(3),
                    }}
                  >
                    {Object.values(details[0]).map((productDetails, index) => {
                      const {
                        prod_asin,
                        out_of_stock_days,
                        low_stock_days,
                        stock_status,
                        ineligible_days,
                        eligibility_status,
                      } = productDetails;
                      const productLinkDetails = adData?.data?.products?.find(
                        (p) => p.asin === prod_asin
                      );
                      if (index < expandedPage * EXPANDED_PAGE_SIZE) {
                        return (
                          <li
                            className={`${classes.detailListItem}`}
                            key={index}
                          >
                            {generateLink(
                              "listing",
                              productLinkDetails,
                              hideDropdown,
                              productIdentifier,
                              { fontSize: "1.4rem" }
                            )}
                            :{" "}
                            {notification_type === "AD_PRODUCT_OUT_OF_STOCK" ||
                            notification_type === "AD_PRODUCT_LOW_OF_STOCK"
                              ? renderStockStatusDetails(
                                  out_of_stock_days,
                                  low_stock_days,
                                  stock_status === "IN_STOCK_SCARCE" ||
                                    low_stock_days === 0,
                                  stock_status === "IN_STOCK" ||
                                    out_of_stock_days === 0
                                )
                              : notification_type === "AD_PRODUCT_INELIGIBLE"
                              ? renderEligibilityDetails(
                                  ineligible_days,
                                  eligibility_status === "ELIGIBLE"
                                )
                              : null}
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </Grid>
                {Object.values(details[0])?.length >
                  EXPANDED_PAGE_SIZE * expandedPage && (
                  <Grid
                    container
                    item
                    xs={11}
                    className={classes.moreButtonContainer}
                    style={{
                      bottom: dropdownLayout
                        ? theme.spacing(6)
                        : theme.spacing(3),
                    }}
                  >
                    <PlainButton
                      onClick={(e) => {
                        e.preventDefault();
                        setExpandedPage(expandedPage + 1);
                      }}
                    >
                      Show More
                    </PlainButton>
                    {((Object.values(details[0])?.length >
                      EXPANDED_PAGE_SIZE * 2 &&
                      expandedPage > 2) ||
                      dropdownLayout) && (
                      <PlainButton
                        onClick={() => {
                          setExpandedPage(1);
                          setShowExpandedDetails(false);
                        }}
                        style={{
                          marginLeft: theme.spacing(4),
                          display: dropdownLayout ? "block" : "inherit",
                        }}
                      >
                        Hide Details
                      </PlainButton>
                    )}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={1}>
        {!dismissed_on && !clicked_on && (
          <button
            onClick={(e) => {
              e.preventDefault();
              editNotification(id, "dismiss");
            }}
            className={`${classes.closeButton}`}
          >
            <i className="fa fa-close fa-sm" />
          </button>
        )}
        <span
          className={classes.timestamp}
          style={{
            fontSize: dropdownLayout || mediaQuery ? "1.2rem" : "inherit",
          }}
        >
          {getNotificationTime(updated_on)}
        </span>
      </Grid>
    </Grid>
  );
};

NotificationItem.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  editNotification: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  selectedNotificationType: PropTypes.string.isRequired,
  hideDropdown: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(
  withStyles(useStyles)(withMediaQuery("(max-width:700px)")(NotificationItem))
);
