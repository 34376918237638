export const buildTextColumn = (key, colTitle, options = {}) => {
  return {
    key,
    compareKey: null,
    options: {
      metric: {
        id: key,
        format: "text",
        accessor: (d) => d[key],
        options: {
          className: "d-flex align-items-center",
          cellContainerClasses: "py-3 align-items-center",
        },
        name: colTitle,
      },
      formatter: (v) => v,
      hideFilter: true,
      ...options,
    },
  };
};
