import React, { useState, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useFetch } from "hooks/api";
import api from "utils/api/index";
import { Row, Col } from "react-bootstrap";
import Accordion from "components/core/basic/Accordion";
import { sendToastNotification } from "utils/sendToastNotification";
import NotificationSystem from "react-notification-system";
import { buildTextColumn } from "./merchandise/columns/buildTextColumn";
import UsersTable from "components/custom/onboarding/UsersTable";
import FormGroupTile from "components/core/basic/FormGroupTile";
import AgencyManagementTable from "components/custom/onboarding/AgencyManagementTable";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import AccountManagersTable from "components/custom/onboarding/AccountManagersTable";

const AgencyManagementView = () => {
  const [selectedAccountManagers, setSelectedAccountManagers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [formData, setFormData] = useState({});

  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state);
  const notificationRef = useRef();

  const { data: agencyData, isLoading: isAgencyDataLoading } = useFetch(
    "trellisAgencies",
    "/api/manage/agency/",
    {},
    {
      select: (d) => d?.data?.results ?? [],
      default: [],
      enabled: user.is_staff,
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000,
    }
  );
  const { data: trellisProfilesData, isLoading: trellisProfilesLoading } =
    useFetch(
      "trellisPrimaryProfiles",
      "/api/organizations/",
      {
        page_size: 5000,
        primary_only: true,
      },
      {
        select: (d) => d.data,
        default: [],
        enabled: user.is_staff,
        refetchOnMount: false,
        staleTime: 5 * 60 * 1000,
      }
    );

  const { data: userData, isLoading: isUserDataLoading } = useFetch(
    "trellisUsers",
    "/api/all_users/",
    { page_size: 5000 },
    {
      select: (d) => d.data,
      default: [],
      enabled: user.is_staff,
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const agencyOptions =
    agencyData?.map((agency) => {
      const { name, id } = agency;
      return { label: name, value: id };
    }) ?? [];

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const createNewAgency = useMutation(
    async (body) => {
      const reqData = {
        name: formData.agencyName,
        account_managers: selectedAccountManagers,
      };
      return await api.post("/api/manage/agency/", reqData);
    },
    {
      onSuccess: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "success",
          `Successfully Created New Agency`
        );

        setSelectedAccountManagers([]);
        setFormData({});
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to create agency`
        );
      },
    }
  );

  const addOrgsToExistingAgency = useMutation(
    async (body) => {
      const reqData = {
        agency_id: parseInt(formData.agency),
        account_managers: selectedAccountManagers,
        action: "add_agency_account_managers",
      };
      return await api.put("/api/manage/agency/", reqData);
    },
    {
      onSuccess: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "success",
          `Successfully Added Agency Profile${
            selectedAccountManagers?.length > 1 ? "s" : ""
          }`
        );

        setSelectedAccountManagers([]);
        setFormData({});
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Add Profile${
            selectedAccountManagers?.length > 1 ? "s" : ""
          }`
        );
      },
    }
  );

  const addAgencyUsers = useMutation(
    async (body) => {
      const reqData = {
        agency_id: parseInt(formData.agency),
        agency_users: selectedUsers,
        action: "add_agency_users",
      };
      return await api.put("/api/manage/agency/", reqData);
    },
    {
      onSuccess: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "success",
          `Successfully Added Agency User${
            selectedUsers?.length > 1 ? "s" : ""
          }`
        );

        setSelectedUsers([]);
        setFormData({});
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Add Agency User${
            selectedAccountManagers?.length > 1 ? "s" : ""
          }`
        );
      },
    }
  );

  if (!user.is_staff) {
    return <Redirect to="/user/dashboard" />;
  }

  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <div
        className="container mx-auto pb-5"
        style={{ minHeight: "calc(100vh - 75px)" }}
      >
        <div className="text-center">
          <h4 className="fw-bold">Manage Agency User Access</h4>

          <Row>
            <Accordion title={"Agencies"} initialExpanded={true}>
              <AgencyManagementTable
                tableData={agencyData}
                columnsToRender={["profiles"]}
                isLoading={isAgencyDataLoading}
              />
            </Accordion>
          </Row>
          <Row>
            <Accordion title={"Account Managers"} initialExpanded={true}>
              <AccountManagersTable
                tableData={trellisProfilesData}
                columnsToRender={[
                  "id",
                  "profile_name",
                  "marketplaces",
                  "profile_type",
                  "account_manager",
                ]}
                titleColWidth={300}
                isLoading={trellisProfilesLoading}
                isSelectable={true}
                selectedAccountManagers={selectedAccountManagers}
                setSelectedAccountManagers={setSelectedAccountManagers}
                showIds={true}
                additionalColumns={[
                  buildTextColumn("account_manager", "Account Manager"),
                ]}
                defaultSorted={[{ id: "account_manager", desc: false }]}
                defaultPageSize={5}
                bulkActionOptions={[
                  {
                    label: "Group Into New Agency",
                    action: () => {
                      createNewAgency.mutate();
                    },
                    disableConfirmButton:
                      !formData?.agencyName ||
                      agencyOptions
                        ?.map((agency) => agency.label)
                        ?.includes(formData?.agencyName),
                    actionFormFields: (
                      <Row className="pb-5">
                        <form>
                          <p className="pb-4 fs-4">
                            Please enter a name for the newly created agency.
                          </p>
                          <Row className="pt-3 d-flex justify-content-center">
                            <Col xs={12} md={8} className="px-5">
                              <FormGroupTile
                                label={"Agency Name"}
                                type="formcontrol"
                                stateName={"agencyName"}
                                handleChildFormElement={(key, value) => {
                                  handleChange(key, value);
                                }}
                                placeholder={"Enter new agency's name"}
                                defaultValue={formData?.agencyName}
                              />
                            </Col>
                          </Row>
                        </form>
                      </Row>
                    ),
                    confirmationMessage: (
                      <p className="fs-4 py-2">
                        Are you sure you want to upsert the selected profiles
                        into a new agency?
                      </p>
                    ),
                  },
                  {
                    label: "Add to Existing Agency",
                    action: () => {
                      addOrgsToExistingAgency.mutate();
                    },
                    actionFormFields: (
                      <Row className="pb-5">
                        <form>
                          <p className="pb-4 fs-4">
                            Please select the agency you would like to add the
                            selected organization
                            {selectedUsers?.length > 1 ? "s" : ""} to.
                          </p>
                          <Row className="pt-3 d-flex justify-content-center">
                            <Col xs={12} md={6} className="px-5 text-start">
                              <FormGroupTile
                                label={"Agency"}
                                type="select"
                                multi={false}
                                stateName={"agency"}
                                handleChildFormElement={(key, value) => {
                                  handleChange(key, value.value);
                                }}
                                defaultValue={agencyOptions.find(
                                  (o) => o.value === formData?.agency
                                )}
                                options={agencyOptions}
                              />
                            </Col>
                          </Row>
                        </form>
                      </Row>
                    ),
                    confirmationMessage: (
                      <p className="fs-4 py-2">
                        Are you sure you want to add the selected profiles into
                        an existing agency (show agency name here)?
                      </p>
                    ),
                  },
                ]}
              />
            </Accordion>
          </Row>

          <Row>
            <Accordion title={"Users"}>
              <UsersTable
                tableData={userData}
                columnsToRender={["username", "email", "agencies", "is_staff"]}
                agencyOptions={agencyOptions}
                isLoading={isUserDataLoading}
                isSelectable={true}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                bulkActionOptions={[
                  {
                    label: "Sync Agency Access",
                    action: () => {
                      addAgencyUsers.mutate();
                    },
                    actionFormFields: (
                      <Row className="pb-5">
                        <form>
                          <p className="pb-4 fs-4">
                            Please select the agency you would like to add the
                            selected user
                            {selectedUsers?.length > 1 ? "s" : ""} to.
                          </p>
                          <Row className="pt-3 d-flex justify-content-center">
                            <Col xs={12} md={6} className="px-5">
                              <FormGroupTile
                                label={"Agency"}
                                type="select"
                                multi={false}
                                stateName={"agency"}
                                handleChildFormElement={(key, value) => {
                                  handleChange(key, value.value);
                                }}
                                defaultValue={agencyOptions.find(
                                  (o) => o.value === formData?.agency
                                )}
                                options={agencyOptions}
                              />
                            </Col>
                          </Row>
                        </form>
                      </Row>
                    ),
                    disableConfirmButton: !formData?.agency,
                    confirmationMessage: (
                      <p className="fs-4 py-2">
                        Are you sure you want to modify the selected user's
                        access?
                      </p>
                    ),
                  },
                ]}
              />
            </Accordion>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AgencyManagementView;
