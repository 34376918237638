import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { LogoutButton } from "components/core/basic/Button.jsx";
import api from "utils/api";
import { useQueryClient } from "react-query";

const PauseProducts = ({
  setShowPauseProducts,
  showPauseProducts,
  selectedProducts,
  productById,
  marketPlace,
  setSelectedProducts,
}) => {
  const [showPausing, setShowPausing] = useState(false);
  const queryClient = useQueryClient();

  const pauseProduct = async () => {
    let product_ids = selectedProducts.join(",");

    setShowPausing(true);

    await api.post("/pricing/pricing_plan_products/remove", {
      product_ids: product_ids,
      marketplace: marketPlace,
    });
    queryClient.invalidateQueries("product_report");
    setSelectedProducts([]);

    document.location.reload();
  };

  useEffect(() => {
    setShowPausing(false);
  }, [showPauseProducts]);

  return (
    <Modal show={showPauseProducts} size="lg">
      <Modal.Header className="py-0 px-5">
        <Row style={{ width: "100%" }}>
          <Col md={6}>
            <div className="fs-3 my-4 text-start">
              Pause Products from Pricing Plans
            </div>
          </Col>
          <Col md={6} className="text-end"></Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="py-4">
          {showPausing && (
            <Col md={{ span: 8, offset: 2 }}>
              <div className=" my-4 text-center fs-4 fw-bold">
                <div>The products will be paused</div>
              </div>
            </Col>
          )}

          {!showPausing && (
            <Col md={{ span: 8, offset: 2 }}>
              <div className="fs-4 py-1">
                <strong>
                  You will pausing these products from its active pricing plan.
                </strong>
              </div>
              <div className="fs-3 my-1 text-center">
                <small>
                  {selectedProducts.slice(0, 5).map((id, i) => (
                    <span key={i} className="pe-3">
                      {productById[id].sku}
                    </span>
                  ))}
                  {selectedProducts.length > 5 && (
                    <span> plus {selectedProducts.length - 5} more</span>
                  )}
                </small>
              </div>
              <div className="fs-4 pt-3 pb-2">Do you want to continue?</div>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row style={{ width: "100%" }}>
          <Col xs={3} className="mx-auto text-cetner">
            &nbsp;
          </Col>
          <Col xs={3} className="mx-auto text-cetner">
            <LogoutButton
              key="CloseBtn"
              title={`Close`}
              onClick={() => setShowPauseProducts(false)}
            />
          </Col>
          <Col xs={3} className="mx-auto text-cetner">
            <LogoutButton
              key="CloseBtn"
              title={`Yes, Pause Product`}
              onClick={() => pauseProduct()}
              disabled={showPausing}
            />
          </Col>
          <Col xs={3} className="mx-auto text-cetner">
            &nbsp;
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default PauseProducts;
