import React, { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useFetch } from "hooks/api";

const ProfitChart = ({ pricingPlan }) => {
  const { data, isLoading, isFetching } = useFetch(
    ["profitMetrics", pricingPlan.id],
    `/pricing/profit`,
    {
      pricing_plan_id: pricingPlan.id,
    },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: true,
      onSuccess: () => {},
    }
  );

  const chartOptions = useMemo(() => {
    if (data) {
      const categories = [];

      const dp = data[0];
      const prev = data[1];

      const profitData = [];
      const prevProfitData = [];
      const priceData = [];
      const previPriceData = [];
      let totalProfit = 0;
      let prevTotalProfit = 0;
      for (let i = 0; i < dp.length; i++) {
        categories.push(dp[i].start_date);
        const profit = dp[i].total_sales - dp[i].cogs;
        totalProfit += profit;
        profitData.push(totalProfit);
        priceData.push(dp[i].product_price);

        prevTotalProfit +=
          prev["product_price"] * prev["demand"] -
          prev["cogs"] * prev["demand"];
        prevProfitData.push(prevTotalProfit);
        previPriceData.push(prev["product_price"]);
      }

      return {
        title: {
          text: "Profit Chart",
        },
        xAxis: {
          categories: categories,
        },
        yAxis: [
          {
            title: {
              text: "Margin $",
            },
          },
          {
            title: {
              text: "Price",
            },
            opposite: true,
          },
        ],
        series: [
          {
            name: "Margin $",
            data: profitData,
            type: "area",
          },
          {
            name: "Previous - Margin $",
            data: prevProfitData,
            type: "area",
          },
          {
            name: "Price",
            data: priceData,
            type: "spline",
            yAxis: 1,
            visible: false,
          },
          {
            name: "Previous - Price",
            data: previPriceData,
            type: "spline",
            yAxis: 1,
            visible: false,
          },
        ],
      };
    }
  }, [data]);

  if (isLoading || isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default ProfitChart;
