import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  useMediaQuery,
  FormHelperText,
} from "@material-ui/core";
import signUplogo from "assets/images/logo/Trellis_Logo.png";
import React, { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { AMAZON_AUTH_APP_ID } from "utils/gvSecrets";
import SocialButton from "utils/SocialButton";
import TermsOfService from "components/custom/instructions/TermsOfService";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-phone-number-input/style.css";

const useStyles = (theme) => ({
  basePanel: {
    height: "100%",
    backgroundColor: "white",
    justifyContent: "flex-start",
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
      justifyContent: "center",
    },
  },
  scrollColumn: {
    // display: "flex",
    position: "relative",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
  },
  formColumn: {
    justifyContent: "center",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: "0%",
    top: "50%",
    transform: "translateY(-50%)",
    overflow: "hidden",
  },
  button: {
    width: "50%",
    fontSize: "1.25rem",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    backgroundColor: "#d91366",
  },
  heading: {
    fontSize: theme.typography.h2.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h1.fontSize,
    },
  },
  subheading: {
    fontSize: theme.typography.h4.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h2.fontSize,
    },
  },
  bullets: {
    fontSize: theme.typography.h4.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  footertext: {
    marginTop: "5%",
    fontSize: theme.typography.h5.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  welcomeText: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: "bold",
    color: theme.palette.grey[800],
    textAlign: "center",
  },
  input: {
    fontSize: theme.typography.h4.fontSize,
  },
  inputlabel: {
    fontSize: theme.typography.h4.fontSize,
  },
  logo: {
    marginLeft: "-5%",
    maxWidth: "50%",
  },
  learnmore: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  scrollarrow: {
    "& svg": {
      fontSize: 36,
      "& path": {
        animation: "$pulse 1.5s infinite",
      },
    },
  },
  hearAboutUs: {
    width: "100%",
    textAlign: "left",
    color: "rgba(0, 0, 0, 0.54)",
  },
  hearAboutUsLabel: {
    width: "100%",
    fontSize: "17px",
    textAlign: "left",
    color: "rgba(0, 0, 0, 0.54)",
  },
  hearAboutUsSelect: {
    fontSize: theme.typography.h4.fontSize,
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.9)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
    },
    "100%": {
      transform: "scale(0.9)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
  },
});

function SignUpForm(props) {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [companyName, setCompany] = useState("");
  const [validCompanyName, setValidCompanyName] = useState(true);
  const [country, setCountry] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [validContactNumber, setValidContactNumber] = useState(true);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hearAboutUs, setHearAboutUs] = useState("");
  const [socialButtonKey, setSocialButtonKey] = useState(0);

  const disableButton =
    email === "" ||
    !validEmail ||
    companyName === "" ||
    !validCompanyName ||
    contactNumber === "" ||
    !validContactNumber ||
    !agreeTerms ||
    !hearAboutUs;
  function handleEmail(e) {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRex.test(e.target.value) === false) {
      setEmail(e.target.value);
      setValidEmail(false);
    } else {
      setEmail(e.target.value);
      setValidEmail(true);
    }
    props.onStateChange("email", e.target.value);
  }

  function handleCountry(val) {
    setCountry(val);
    props.onStateChange("country", country);
  }

  function handleCompanyName(e) {
    e.preventDefault();

    var varCompanyName = e.target.value;

    var companyNameRex = /^.*[a-zA-Z0-9]+.*$/;
    if (
      companyNameRex.test(varCompanyName) === false ||
      varCompanyName.includes("@")
    ) {
      setCompany(varCompanyName);
      setValidCompanyName(false);
    } else {
      setCompany(varCompanyName);
      setValidCompanyName(true);
    }
    props.onStateChange("companyName", varCompanyName);
  }

  function handleContactNumber(value) {
    setContactNumber(value);
    setValidContactNumber(isPossiblePhoneNumber(`${value}`));
    props.onStateChange("contactNumber", value);
  }

  const handleHearAboutUsChange = (event) => {
    setHearAboutUs(event.target.value);
    props.onStateChange("hearAboutUs", event.target.value);
  };

  const handleAgreeTermsChange = (event) => {
    setAgreeTerms(event.target.checked);
    props.onStateChange("termsAgreement", event.target.checked);
  };

  const { classes } = props;
  const matches = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  return (
    <Grid container className={classes.basePanel} direction="row" wrap="nowrap">
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={10} md={8}>
        <PerfectScrollbar className={classes.scrollColumn}>
          <Box className={classes.formColumn}>
            {props.alt === 0 ? (
              <Box className={classes.welcomeText}>Welcome to</Box>
            ) : null}
            <Grid container justifyContent="center">
              <img className={classes.logo} src={signUplogo} alt="Trellis" />
            </Grid>
            {props.alt !== 0 && props.alt !== 4 ? (
              <>
                <Box
                  pt={5}
                  width="75%"
                  style={{ margin: "0 auto" }}
                  fontSize="h3.fontSize"
                >
                  Prepare for lift-off with a
                  <Box fontWeight="fontWeightMedium" display="inline">
                    {" "}
                    14-day free trial
                  </Box>
                </Box>
                <Box textAlign="center" fontSize="h4.fontSize">
                  No credit card required
                </Box>
              </>
            ) : null}

            {props.alt === 4 ? (
              <Box textAlign="center" fontSize="h4.fontSize" mt={4}>
                The beginning of your Trellis experience, starts now.
              </Box>
            ) : null}

            {props.alt === 0 && matches ? (
              <Box
                width={1}
                display="flex"
                justifyContent="center"
                textAlign="center"
                fontSize="h6.fontSize"
                mt={2}
              >
                Sign up for our free 14 day trial. No credit card required.
              </Box>
            ) : null}
            <Box
              textAlign="left"
              fontWeight="fontWeightBold"
              fontSize="h3.fontSize"
              mt={4}
            >
              Company Information
            </Box>
            <Box mt={2} width={1}>
              <TextField
                fullWidth
                value={companyName}
                onChange={handleCompanyName}
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.inputlabel }}
                label={false ? "Company Name" : "Business Name"}
                error={!validCompanyName}
              />
            </Box>
            <Box mt={props.alt === 0 ? { xs: 2, sm: 10 } : 2} width={1}>
              <TextField
                fullWidth
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.inputlabel }}
                onChange={handleEmail}
                value={email}
                label="Email"
                error={!validEmail}
              />
            </Box>
            {false ? (
              <Box mt={4} width={1}>
                <CountryDropdown
                  value={country}
                  valueType="short"
                  className="country_selector"
                  defaultOptionLabel="Country"
                  onChange={handleCountry}
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                />{" "}
              </Box>
            ) : null}
            <Box mt={2} width={1}>
              <PhoneInput
                className="phone_number_input"
                style={{
                  borderColor: !validContactNumber ? "#FF0000" : "grey",
                  width: "100%",
                  display: "table-cell",
                  verticalAlign: "center",
                  marginBottom: !validContactNumber ? "0px" : "20px",
                }}
                international
                withCountryCallingCode
                placeholder="Contact Number"
                value={contactNumber}
                onChange={handleContactNumber}
              />
              {!validContactNumber && (
                <FormHelperText style={{ fontSize: "10px" }}>
                  Please include your country code
                </FormHelperText>
              )}
            </Box>
            <Box mt={1} width={1}>
              <FormControl className={classes.hearAboutUs}>
                <InputLabel className={classes.hearAboutUsLabel}>
                  How did you hear about us?
                </InputLabel>
                <Select
                  className={classes.hearAboutUsSelect}
                  value={hearAboutUs}
                  onChange={handleHearAboutUsChange}
                >
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value=""
                  ></MenuItem>
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value="Facebook"
                  >
                    Facebook
                  </MenuItem>
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value="Google"
                  >
                    Google
                  </MenuItem>
                  <MenuItem className={classes.hearAboutUsSelect} value="Bing">
                    {" "}
                    Bing
                  </MenuItem>
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value="Reddit"
                  >
                    Reddit
                  </MenuItem>
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value="WhatsApp"
                  >
                    WhatsApp
                  </MenuItem>
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value="Referral"
                  >
                    Referral
                  </MenuItem>
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value="YouTube"
                  >
                    YouTube
                  </MenuItem>
                  <MenuItem
                    className={classes.hearAboutUsSelect}
                    value="White Label Expo"
                  >
                    White Label Expo
                  </MenuItem>
                  <MenuItem className={classes.hearAboutUsSelect} value="Other">
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              mt={props.alt === 0 ? { xs: 6, sm: 8 } : 6}
              className="fs-standard"
            >
              <Checkbox
                checked={agreeTerms}
                onChange={(e) => {
                  handleAgreeTermsChange(e);
                }}
              />
              I agree to the{" "}
              <Link
                color="secondary"
                component="button"
                onClick={() => {
                  setShowTerms(true);
                }}
              >
                {" "}
                Terms of Use{" "}
              </Link>{" "}
              and
              <Link
                color="secondary"
                href="https://gotrellis.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Privacy Policy{" "}
              </Link>
            </Box>
            <Box mt={3} width={1}>
              <Grid container justifyContent="center" key={socialButtonKey}>
                {props.onboardVersion === "v2" ? (
                  <button
                    className="adplan_button w-50"
                    style={{
                      transform: "none",
                      textTransform: "none",
                      letterSpacing: "normal",
                    }}
                    disabled={disableButton}
                    onClick={props.handleAmazonSignup}
                  >
                    Sign Up
                  </button>
                ) : (
                  <SocialButton
                    provider="amazon"
                    appId={AMAZON_AUTH_APP_ID}
                    onLoginSuccess={props.handleAmazonSignup}
                    onLoginFailure={() => {
                      setSocialButtonKey(socialButtonKey + 1);
                    }}
                    className={classes.button}
                    disabled={disableButton}
                  >
                    {"Sign Up"}
                  </SocialButton>
                )}
              </Grid>
            </Box>
            <Box mt={3} textAlign="center" className="fs-standard">
              Already a member?{" "}
              <SocialButton
                provider="amazon"
                appId={AMAZON_AUTH_APP_ID}
                onLoginSuccess={props.handleSocialLogin}
                onLoginFailure={props.handleSocialLoginFailure}
                buttonId="amazonLogin"
                type="link"
              >
                <span className="fw-bold">Sign In</span>
              </SocialButton>
            </Box>
          </Box>
        </PerfectScrollbar>
      </Grid>
      <Grid item xs={1} md={2}></Grid>
      {showTerms ? (
        <TermsOfService
          justTerms="showTerms"
          closeTerms={() => {
            setShowTerms(false);
          }}
        />
      ) : null}
    </Grid>
  );
}

export default withStyles(useStyles)(SignUpForm);
