import DatesProvider from "dates/DatesProvider";
import { connect } from "react-redux";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import { useParams, useLocation } from "react-router-dom";
import api from "utils/api";
import { useMutation, useQueryClient } from "react-query";
import Loading from "components/core/blocks/Loading";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import NotificationSystem from "react-notification-system";
import React, { useMemo, useState, useRef } from "react";
import { Container, Row, Col, Nav, Badge, Table, Card } from "react-bootstrap";
import { buildMetric } from "views/merchandise/metrics";
import { ProductImageURL } from "components/custom/category/listings/ProductImage";
import Metric from "components/core/blocks/SummaryMetric";
import ReactTable from "react-table-6";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { makeColumn, col } from "components/custom/merchandise/columns";
import useColumnSelector from "hooks/useColumnSelector";
import { Link } from "react-router-dom";
import MultiMetricChart from "components/custom/merchandise/MultiMetricChart";
import useSelectedMetrics from "components/custom/merchandise/hooks/useSelectedMetrics";
import { INVENTORY_STATES } from "components/core/blocks/AdPlan/constants";
import Tags from "components/core/blocks/Tags";
import moment from "moment";
import checkApplication from "utils/checkApplication";
import {
  ADVERTISING,
  CONTENT,
  DEALS,
  DYNAMIC_PRICING,
  SALE_STATE_OPTIONS,
} from "components/custom/merchandise/constants";
import ListingQuality from "modules/product_quality/components/ListingQuality";
import { getBadgeVariant } from "modules/product_quality/utils";
import ConversionLegend from "modules/product_quality/components/ConversionLegend";
import ToolTips from "utils/toolTips";
import { selectModules } from "redux/selectors/modules";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import useCategories from "hooks/useCategories";
import useProducts from "hooks/useProducts";

import { sendToastNotification } from "utils/sendToastNotification";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import { snakeCaseToSentenceCase, toTitleCase } from "utils/formatText";
import checkModule from "utils/checkModule";
import PriceHistorySection from "modules/pricingv2/cards/PriceHistory";
import CompetitorsSection from "modules/pricingv2/cards/Competitors";
import ConfirmationSection from "modules/pricingv2/cards/Confirmation";
import { DemandChartsSection } from "modules/pricingv2/cards/DemandTrend";
import ProductAds from "components/custom/category/advertisements/ProductsAds";
import { formatCurrency, formatPercent } from "utils/formatNumber";
import PricingPlanCard from "modules/pricingv2/PricingPlanCard";
import { cut } from "utils/formatText";
import ExternalProductLink from "components/custom/merchandise/ExternalProductLink";
import SalesMapUSA from "components/custom/maps/usa";
import ASINTable from "components/custom/oranicSearch/asinTable";
import CustomTag from "components/core/blocks/CustomTag";

// TODO factor this out into a utils file
const DASHBOARD_TYPES = {
  PRODUCT: "product",
  CATEGORY: "category",
  ROOT: "root",
};

const getDashboardTo = ({ resourceId, type }) => {
  let to;
  switch (type) {
    case DASHBOARD_TYPES.CATEGORY:
      to = `/user/merchandising/category/${resourceId}`;
      break;
    case DASHBOARD_TYPES.PRODUCT:
      to = `/user/merchandising/product/${resourceId}`;
      break;
    default:
      to = `/user/merchandising/dashboard`;
  }
  return to;
};

const ProductDetails = ({
  user,
  marketPlace,
  channel,
  product,
  isProductLoading,
  start,
  end,
  modules,
}) => {
  const [tab, setTab] = useState("general");

  const getVariations = () => {
    if (!product?.variations) {
      return <span>n/a</span>;
    }

    const keys = Object.keys(product?.variations);
    if (keys.length === 0) {
      return <span>n/a</span>;
    }

    const variations = [];
    for (let i = 0; i < keys.length; i++) {
      const variation = (
        <span className="pe-3" key={keys[i]}>
          {toTitleCase(keys[i])}: {product?.variations[keys[i]]}
        </span>
      );
      variations.push(variation);
    }

    return variations;
  };

  return (
    <Row>
      <Col xs={2} style={{ borderRight: "1px solid #ccc" }}>
        <div className="pt-2"></div>
        <Nav
          variant="underline"
          onSelect={(k) => {
            return setTab(k);
          }}
          activeKey={tab}
          className="flex-column text-start"
        >
          <Nav.Link eventKey="general" className="text-start">
            Details
          </Nav.Link>
          <Nav.Link eventKey="cost-fees" className="text-start">
            Cost & Fees
          </Nav.Link>
          {checkModule(modules, "ORGANIC") &&
            marketPlace.marketPlace === "USA" &&
            channel.currentChannel === "amazon" && (
              <Nav.Link eventKey="organic-search" className="text-start">
                Organic Search Terms
              </Nav.Link>
            )}
          {checkModule(modules, "MAP") &&
            marketPlace.marketPlace === "USA" &&
            channel.currentChannel === "amazon" && (
              <Nav.Link eventKey="map" className="text-start">
                Sales By State
              </Nav.Link>
            )}
        </Nav>
      </Col>
      <Col xs={10}>
        <div className="pt-2">
          {tab === "general" && (
            <Row>
              <Col xs={8}>
                <Table borderless hover>
                  <tbody>
                    <tr>
                      <th>Title</th>
                      <td>{product?.product_title}</td>
                    </tr>
                    <tr>
                      <th>ASIN</th>
                      <td>
                        {product?.asin}{" "}
                        <ExternalProductLink asin={product?.asin} />
                      </td>
                    </tr>
                    <tr>
                      <th>SKU</th>
                      <td>{product?.sku}</td>
                    </tr>
                    <tr>
                      <th style={{ whiteSpace: "nowrap" }}>Inventory Qty</th>
                      <td>
                        {product?.product_quantity ||
                          product?.product_quantity_fba}
                      </td>
                    </tr>
                    <tr>
                      <th>BSR</th>
                      <td>{product?.bsr_rank}</td>
                    </tr>
                    <tr>
                      <th>Variations</th>
                      <td>{getVariations()}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          {tab === "cost-fees" && (
            <Row>
              <Col xs={6}>
                <Table borderless hover>
                  <tbody>
                    <tr>
                      <th>Fulfillment Channel</th>
                      <td>
                        {product?.fulfillment_channel === "AFN" ? "FBA" : "FBM"}
                      </td>
                    </tr>
                    <tr>
                      <th>Landed Cost</th>
                      <td>
                        {formatCurrency(product?.landed_cost, marketPlace)}
                      </td>
                    </tr>
                    {product?.fulfillment_channel !== "AFN" && (
                      <tr>
                        <th>Shipping Cost</th>
                        <td>
                          {formatCurrency(product?.shipping_cost, marketPlace)}
                        </td>
                      </tr>
                    )}
                    {product?.fulfillment_channel === "AFN" && (
                      <tr>
                        <th>Amazon Fulfillment Fees</th>
                        <td>
                          {formatCurrency(
                            product?.fulfillment_fee,
                            marketPlace
                          )}
                        </td>
                      </tr>
                    )}
                    <tr style={{ borderBottom: "1px solid #ccc" }}>
                      <th>Amazon Referral Fees</th>
                      <td>
                        {formatCurrency(
                          product?.channel_fees * product?.product_price,
                          marketPlace
                        )}

                        <span className="ps-3">
                          <small>
                            ({formatPercent(product?.channel_fees, 2)})
                          </small>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Product Price</th>
                      <td>
                        {formatCurrency(product?.product_price, marketPlace)}
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderBottom: "2px solid #ccc",
                      }}
                    >
                      <th>Fees + COGS</th>
                      <td>
                        {formatCurrency(
                          product?.channel_fees * product?.product_price +
                            product?.fulfillment_fee +
                            product?.shipping_cost +
                            product?.landed_cost,
                          marketPlace
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Net Profit</th>
                      <td>
                        {formatCurrency(
                          product?.product_price -
                            (product?.channel_fees * product?.product_price +
                              product?.fulfillment_fee +
                              product?.shipping_cost +
                              product?.landed_cost),
                          marketPlace
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          {tab === "map" && (
            <Row>
              <Col xs={12}>
                <SalesMapUSA
                  start_date={start.format("YYYY-MM-DD")}
                  end_date={end.format("YYYY-MM-DD")}
                  marketPlace={marketPlace}
                  productId={product?.id}
                />
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

const ContentSection = ({ productId, marketPlace }) => {
  const { loading, data } = useFetch(
    ["productQuality", productId],
    `/merchandise/quality/${productId}`,
    {},
    {
      select: (data) => data?.data,
    }
  );
  const metrics = useMemo(
    () => [
      buildMetric(
        marketPlace,
        { total: data },
        "seo_score",
        "seo_score_percent",
        {
          metric: { accessor: (d) => d?.seo_score },
        }
      ),
      buildMetric(
        marketPlace,
        { total: data },
        "buyability_score",
        "buyability_score_percent",
        {
          metric: { accessor: (d) => d?.buyability_score },
        }
      ),
      buildMetric(
        marketPlace,
        { total: data },
        "conversion_rate",
        "conversion_rate_delta",
        {
          metric: { accessor: (d) => d?.cr },
        }
      ),
      buildMetric(marketPlace, { total: data }, "ctr", "ctr_delta", {
        metric: { accessor: (d) => d?.ctr },
      }),
    ],
    [marketPlace, data]
  );
  const terms = useMemo(() => {
    const seoKeywords = data?.seo_keywords?.keywords;
    const seoSearchTerms = data?.seo_keywords?.search_terms;
    if (seoKeywords && seoSearchTerms) {
      [seoKeywords, seoSearchTerms].forEach((data) => {
        for (let i = 0; i < data.length; i++) {
          const kw = data[i]["keyword_text"];
          const kwData = data[i];
          kwData.cr = 0;
          kwData.ctr = 0;
          kwData.acos = 0;

          if (kw.length <= 1) {
            continue;
          }

          if (kwData.clicks > 0) {
            kwData.cr = kwData.conversions / kwData.clicks;
          }
          if (kwData.impressions > 0) {
            kwData.ctr = kwData.clicks / kwData.impressions;
          }
          if (kwData.sales > 0) {
            kwData.acos = kwData.cost / kwData.sales;
          }

          if (!kwData.cr) {
            kwData.cr = 0;
          }
        }
      });

      seoKeywords.sort(function (a, b) {
        return b.cr - a.cr;
      });

      seoSearchTerms.sort(function (a, b) {
        return b.cr - a.cr;
      });
      return {
        seoKeywords,
        seoSearchTerms,
      };
    }
    return {};
  }, [data]);

  if (loading) {
    return <Loading height={"300px"} fullPage={false} />;
  }
  return (
    <>
      <Row>
        <Col xs={3} className="pt-5 pl-4">
          <Row>
            <Col xs={12}>
              {data?.content_details && (
                <ListingQuality contentDetails={data?.content_details} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={5} className="pt-5">
          <Row>
            <Col xs={12}>
              <div className="fs-4 pb-2" style={{ fontWeight: "bold" }}>
                Top Converting Keywords
                <ToolTips id={"top-converting-keywords"} toolTip={""}>
                  The top converting keywords for this product.
                  <br />
                  <ConversionLegend xs={6} />
                </ToolTips>
              </div>
              <div
                style={{
                  maxHeight: "600px",
                  overflow: "hidden",
                  overflowY: "scroll",
                  paddingTop: "2px",
                  paddingBottom: "10px",
                }}
              >
                {terms?.seoKeywords &&
                  terms?.seoKeywords?.slice(0, 50)?.map((k, ik) => (
                    <Badge
                      bg={getBadgeVariant(k)}
                      className="mx-1 my-1 fs-5 badge-keyword"
                    >
                      {k.keyword_text}
                    </Badge>
                  ))}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="pt-5">
          <Row>
            {metrics.map((m) => (
              <Col key={m.id} xs={6} style={{ marginBottom: "3rem" }}>
                <Metric
                  id={m.id}
                  toolTip={m.toolTip}
                  name={m.name}
                  value={m.actual}
                  formatter={m.formatter}
                  legendColor={m.color}
                  size="small"
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center">
          <Link to={`/user/content/seo?id=${productId}`}>
            View Product Content
          </Link>
        </Col>
      </Row>
    </>
  );
};

const DealsSection = ({ productId, marketPlace, data, categoryId }) => {
  const { selectedMetrics, toggleMetric } = useSelectedMetrics(["promo_sales"]);
  const { date, start, end, preStart, preEnd, group } = useDates();

  const filters = {
    market_place: marketPlace.marketPlace,
    group_by: group,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
    pre_start: preStart.format("YYYY-MM-DD"),
    pre_end: preEnd.format("YYYY-MM-DD"),
    product_id: productId,
  };
  const { data: productDealStats, isLoading: isProductDealStatsLoading } =
    useFetch(
      [
        "productDealStats",
        productId,
        marketPlace,
        group,
        start,
        end,
        preStart,
        preEnd,
      ],
      "/merchandise/promos_report/",
      filters,
      {
        select: (data) => data?.data,
      }
    );

  const metrics = useMemo(
    () => [
      buildMetric(
        marketPlace,
        productDealStats,
        "promo_sales",
        "promo_sales_percent"
      ),
      buildMetric(
        marketPlace,
        productDealStats,
        "promo_units",
        "promo_units_percent"
      ),
      buildMetric(marketPlace, productDealStats, "rods", "rods_delta"),
      buildMetric(
        marketPlace,
        productDealStats,
        "promo_cost",
        "promo_cost_percent"
      ),
    ],
    [marketPlace, productDealStats]
  );

  const columnSpecs = useMemo(() => {
    return [
      col(
        "Promotion",
        "promotion",
        "promotion_report_product.promotion_report.promotion_name"
      ),
      col("Status", "text", "status"),
      col("Type", "text", "promotionType"),
      col(
        "Start",
        "date",
        "promotion_report_product.promotion_report.start_datetime"
      ),
      col("Deal Price", "currency", "deal_price"),
      col(
        "End",
        "date",
        "promotion_report_product.promotion_report.end_datetime"
      ),
      col("Promo Sales", "currency", "product_revenue", {
        compareKey: "sales_est",
        compareType: "est.",
      }),
      col("Promo Units", "number", "product_units_sold", {
        compareKey: "units_est",
        compareType: "est.",
      }),
      col("Total Discounts", "currency", "cost"),
      col("Glance Views", "number", "product_glance_views"),
      col("Conversion Rate", "percent", "conversion"),
    ];
  }, []);

  const promos = useMemo(() => {
    return (
      productDealStats?.promos?.map((p) => {
        const promotionType =
          {
            BEST_DEAL: "Best Deal",
            LIGHTNING_DEAL: "Lightning Deal",
          }?.[p?.promotion_report_product?.promotion_report?.promotion_type] ??
          "Unknown";
        let status = p?.promotion_report_product?.promotion_report?.status;
        const start =
          p?.promotion_report_product?.promotion_report?.start_datetime;
        const end = p?.promotion_report_product?.promotion_report?.end_datetime;
        if (status === "APPROVED") {
          if (
            moment(start).isBefore(moment()) &&
            moment(end).isAfter(moment())
          ) {
            status = "In Progress";
          } else if (moment(end).isBefore(moment())) {
            status = "Completed";
          } else {
            status = "Approved";
          }
        } else {
          status = status === "CANCELED" ? "Canceled" : status?.toLowerCase;
        }
        return {
          ...p,
          status,
          promotionType,
        };
      }) ?? []
    );
  }, [productDealStats]);

  const createColumn = makeColumn(
    productDealStats,
    marketPlace,
    false,
    date,
    false,
    categoryId
  );
  const [columns] = useColumnSelector(
    () =>
      columnSpecs.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [columnSpecs]
  );
  const columnOrder = columns.map((col) => col.id);

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  if (isProductDealStatsLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      <Row>
        <Col xs={8}>
          {productDealStats?.dates && (
            <MultiMetricChart
              metrics={metrics}
              selectedMetrics={selectedMetrics}
              data={productDealStats.dates}
              group={group}
              handleLegendChange={handleLegendChange}
            />
          )}
        </Col>
        <Col xs={4} className="pt-5">
          <Row>
            {metrics.map((m) => (
              <Col
                key={m.id}
                xs={6}
                style={{ marginBottom: "3rem" }}
                onClick={() => toggleMetric(m.id)}
              >
                <Metric
                  id={m.id}
                  toolTip={m.toolTip}
                  name={m.name}
                  value={m.actual}
                  target={m.target}
                  formatter={m.formatter}
                  targetFormatter={m.targetFormatter}
                  showTarget
                  targetColor={m.target > 0 ? "#007000" : "#d2222d"}
                  legendColor={m.color}
                  targetLabel={"vs. Previous"}
                  size="small"
                  selected={selectedMetrics.includes(m.id)}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {promos?.length > 0 && (
            <ReactTable
              showPaginationBottom={false}
              showPaginationTop
              PaginationComponent={ReactTablePagination}
              columns={columns}
              data={promos ?? []}
              defaultPageSize={promos?.length ?? 0}
              className="-highlight"
              filterable={false}
              state={columnOrder}
              getPaginationProps={() => ({
                hideSearch: true,
                hideFilters: true,
                table: "plan-products",
                style: { marginBottom: "2rem" },
                carouselLayout: false,
                disableSearchFocus: true,
              })}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

const AdvertisingSection = ({ productId, marketPlace }) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <ProductAds productId={productId} marketPlace={marketPlace} />
        </Col>
      </Row>
    </>
  );
};

const PricingSection = ({
  productId,
  marketPlace,
  data,
  isLoading,
  product,
  isOverviewDataLoading,
  modules,
  pricingInfo,
  user,
}) => {
  const [tab, setTab] = useState("price_history");

  const { data: pricingProduct, isLoading: isPricingProductDataLoading } =
    useFetch(
      ["product_pricing", productId],
      `/pricing/products/${productId}/`,
      { get_primary: "false" },
      {
        select: (d) => d?.data,
        keepPreviousData: true,
      }
    );

  return (
    <Row>
      <Col xs={2} style={{ borderRight: "1px solid #ccc" }}>
        <div className="pt-2"></div>
        <Nav
          variant="underline"
          onSelect={(k) => {
            return setTab(k);
          }}
          activeKey={tab}
          className="flex-column text-start"
        >
          <Nav.Link eventKey="price_history" className="text-start">
            Price History
          </Nav.Link>
          <Nav.Link eventKey="ad_affect" className="text-start">
            Advertising Effectiveness
          </Nav.Link>
          <Nav.Link eventKey="competitors" className="text-start">
            Competitors
          </Nav.Link>

          {pricingProduct?.plan_id &&
            (user.is_staff || pricingProduct.visible) && (
              <>
                <Nav.Link eventKey="confirmation" className="text-start">
                  Price Changes
                </Nav.Link>
                <Nav.Link
                  eventKey="pricing_plan"
                  className="text-start"
                  to={`/user/_pricing/plan/${pricingProduct?.plan_id}`}
                >
                  View Pricing Plan
                </Nav.Link>
              </>
            )}
        </Nav>
      </Col>
      <Col xs={10} className="p-3">
        {true && (
          <Row className="pb-3 mb-3" style={{ borderBottom: "1px solid #ccc" }}>
            <Col>
              <div className="pe-5 text-center">
                <h6
                  style={{ fontWeight: 600 }}
                  className="fs-standard text-uppercase pt-1 text-nowrap"
                >
                  Pricing
                </h6>
                <Badge
                  className={`badge bg-trellis-green`}
                  pill
                  style={{
                    borderRadius: "5px",
                    marginLeft: "1rem",
                  }}
                >
                  {pricingInfo.enrollDate ? "Enrolled" : "Not Enrolled"}
                </Badge>
              </div>
            </Col>
            <Col>
              <div className="pe-5 text-center">
                <h6
                  style={{ fontWeight: 600 }}
                  className="fs-standard text-uppercase pt-1 text-nowrap"
                >
                  Enroll Date
                </h6>
                <Badge
                  className={`badge bg-trellis-lightgrey text-end`}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <span>
                    {pricingInfo.enrollDate
                      ? moment(pricingInfo.enrollDate).format("MM/DD/YYYY")
                      : "N/A"}
                  </span>
                </Badge>
              </div>
            </Col>

            <Col>
              <div className="pe-5 text-center">
                <h6
                  style={{ fontWeight: 600 }}
                  className="fs-standard text-uppercase pt-1 text-nowrap"
                >
                  Fixed Price
                </h6>
                <Badge
                  className="badge bg-trellis-purple"
                  pill
                  style={{
                    borderRadius: "5px",
                    marginLeft: "1rem",
                  }}
                >
                  {pricingInfo.priceOverrideEnd
                    ? formatCurrency(
                        pricingInfo.priceOverride,
                        marketPlace.marketPlace
                      )
                    : "No"}
                </Badge>
              </div>
            </Col>

            {pricingInfo?.bestPriceDate && (
              <Col>
                <div className="pe-5 text-center">
                  <h6
                    style={{ fontWeight: 600 }}
                    className="fs-standard text-uppercase pt-1 pr-3 text-nowrap"
                  >
                    Optimized On
                  </h6>

                  <Badge
                    className={`badge bg-trellis-lightgrey text-end`}
                    style={{
                      borderRadius: "5px",
                    }}
                  >
                    {pricingInfo.enrollDate
                      ? moment(pricingInfo?.bestPriceDate).format("MM/DD/YYYY")
                      : "N/A"}
                  </Badge>
                </div>
              </Col>
            )}
            {pricingInfo?.minPrice && (
              <Col>
                <div className="pe-5 text-center">
                  <h6
                    style={{ fontWeight: 600 }}
                    className="fs-standard text-uppercase pt-1 pr-3 text-nowrap"
                  >
                    Minimum $
                  </h6>

                  <Badge
                    className={`badge bg-trellis-lightgrey text-end`}
                    style={{
                      borderRadius: "5px",
                    }}
                  >
                    {formatCurrency(
                      pricingInfo?.minPrice,
                      marketPlace.marketPlace
                    )}
                  </Badge>
                </div>
              </Col>
            )}
            {pricingInfo?.maxPrice && (
              <Col>
                <div className="pe-5 text-center">
                  <h6
                    style={{ fontWeight: 600 }}
                    className="fs-standard text-uppercase pt-1 pr-3 text-nowrap"
                  >
                    Maximum $
                  </h6>

                  <Badge
                    className={`badge bg-trellis-lightgrey text-end`}
                    style={{
                      borderRadius: "5px",
                    }}
                  >
                    {formatCurrency(
                      pricingInfo?.maxPrice,
                      marketPlace.marketPlace
                    )}
                  </Badge>
                </div>
              </Col>
            )}
          </Row>
        )}
        {tab === "pricing_plan" && (
          <>
            <PricingPlanCard
              marketPlace={marketPlace}
              planId={pricingProduct?.plan_id}
              user={user}
              hasBorder={false}
            />
            <Row className="pt-3 text-center">
              <Col>
                <Link to={`/user/_pricing/plan/${pricingProduct?.plan_id}`}>
                  View Pricing Plan
                </Link>
              </Col>
            </Row>
          </>
        )}
        {tab === "demand_charts" && (
          <DemandChartsSection
            pricingProduct={pricingProduct}
            marketPlace={marketPlace}
            product={product}
            isPricingProductDataLoading={isPricingProductDataLoading}
            data={data}
          />
        )}
        {tab === "price_history" && (
          <>
            <Card>
              <DemandChartsSection
                pricingProduct={pricingProduct}
                marketPlace={marketPlace}
                product={product}
                isPricingProductDataLoading={isPricingProductDataLoading}
                data={data}
              />
            </Card>
            <hr />
            <PriceHistorySection
              pricingProduct={pricingProduct}
              marketPlace={marketPlace}
              isPricingProductDataLoading={isPricingProductDataLoading}
              isLoading={false}
            />
          </>
        )}
        {tab === "ad_affect" && (
          <PriceHistorySection
            pricingProduct={pricingProduct}
            marketPlace={marketPlace}
            isPricingProductDataLoading={isPricingProductDataLoading}
            isLoading={isOverviewDataLoading}
            advertising={true}
          />
        )}
        {tab === "confirmation" && (
          <ConfirmationSection
            pricingProduct={pricingProduct}
            marketPlace={marketPlace}
          />
        )}
        {tab === "competitors" && (
          <CompetitorsSection
            pricingProduct={pricingProduct}
            productId={productId}
            product={product}
            marketPlace={marketPlace}
            isPricingProductDataLoading={isPricingProductDataLoading}
          />
        )}
      </Col>
    </Row>
  );
};

const ProductCard = ({
  productId,
  marketPlace,
  channel,
  user,
  additionalTabs,
  initialTab,
  modules,
  data,
  isOverviewDataLoading,
}) => {
  const [salesState, setSalesState] = useState(null);

  const { start, end, preStart, preEnd, group } = useDates();

  const { data: product, isLoading: isProductLoading } = useFetch(
    ["products", productId, group, start, end, preStart, preEnd],
    `/api/gvads/productid/${productId}`,
    {},
    {
      select: (res) => res.data,
      onSuccess: (res) => {
        if (!salesState) {
          setSalesState(res.sales_state);
        }
      },
    }
  );

  const { pricingInfo } = useMemo(() => {
    const pricingInfo = {
      minPrice: null,
      maxPrice: null,
      enrollDate: null,
      status: null,
      priceOverride: null,
      priceOverrideEnd: null,
      productPrice: null,
      bestPriceDate: null,
      amazonStatus: null,
    };

    if (data) {
      pricingInfo.minPrice = data?.products[0]?.min_price;
      pricingInfo.maxPrice = data?.products[0]?.max_price;
      pricingInfo.enrollDate = data?.products[0]?.enroll_date;
      pricingInfo.status = data?.products[0]?.pp_status;
      pricingInfo.priceOverride = data?.products[0]?.price_override;
      pricingInfo.priceOverrideEnd = data?.products[0]?.price_override_end;
      pricingInfo.productPrice = data?.products[0]?.product_price;
      pricingInfo.bestPriceDate = data?.products[0]?.best_price_date;
      pricingInfo.amazonStatus = data?.products[0]?.amazon_status;
    }

    return { pricingInfo };
  }, [data]);

  const categoryId = data?.products[0]?.category_id;

  const isLoading = isProductLoading || isOverviewDataLoading;

  const overviewMetrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "sales", "sales_percent"),
      buildMetric(marketPlace, data, "units", "units_percent"),
      // buildMetric(marketPlace, data, "total_order_count", null),
      buildMetric(marketPlace, data, "bsr", "bsr_percent", {
        metric: {
          totalOverrideAccessor: "last_bsr_rank",
        },
      }),
      buildMetric(marketPlace, data, "page_views", "page_views_percent"),
      buildMetric(marketPlace, data, "price", "price_delta"),

      buildMetric(marketPlace, data, "margin", "margin_delta"),
      buildMetric(
        marketPlace,
        data,
        "margin_wo_adspend",
        "margin_wo_adspend_delta"
      ),

      buildMetric(marketPlace, data, "cost", "cost_percent"),
      buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
      buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
      buildMetric(marketPlace, data, "acos", "acos_delta"),
      buildMetric(marketPlace, data, "roas", "roas_delta"),
      buildMetric(marketPlace, data, "total_acos", "total_acos_delta"),
      buildMetric(marketPlace, data, "troas", "troas_delta"),
      buildMetric(marketPlace, data, "roms", "roms_delta"),

      buildMetric(marketPlace, data, "rating", "rating_percent"),

      buildMetric(marketPlace, data, "boosted_profit", null),
      buildMetric(marketPlace, data, "profit", "profit_percent"),
      buildMetric(
        marketPlace,
        data,
        "profit_wo_adspend",
        "profit_wo_adspend_percent"
      ),

      buildMetric(
        marketPlace,
        data,
        "profit_per_page_view",
        "profit_per_page_view_percent"
      ),
      buildMetric(
        marketPlace,
        data,
        "profit_per_page_view_wo_adspend",
        "profit_per_page_view_wo_adspend_percent"
      ),
    ],
    [marketPlace, data]
  );

  const eligibilityStatusMap = {
    ELIGIBLE: "bg-trellis-green ",
    INELIGIBLE: "bg-trellis-yellow",
    ELIGIBLE_WITH_WARNING: "bg-trellis-yellow ",
  };

  const salesStateArray = SALE_STATE_OPTIONS;
  const inventoryStateArray = [
    INVENTORY_STATES.OVERSTOCK,
    INVENTORY_STATES.LOW_STOCK,
    INVENTORY_STATES.OUT_OF_STOCK,
    INVENTORY_STATES.IN_STOCK,
    INVENTORY_STATES.IN_STOCK_SCARCE,
  ];
  const salesLabel = salesStateArray.find(
    (s) => s.value === product?.sales_state
  )?.label;
  const inventoryBadgeText = inventoryStateArray.find(
    (s) => s.value === product?.stock_status
  )?.label;
  const [tab, setTab] = useState(initialTab ?? "details");

  const notificationRef = useRef();
  const queryClient = useQueryClient();

  const updateTags = useMutation(
    async (updatedTags) => {
      return await api.patch(`/api/gvads/productid/${productId}`, {
        tags: updatedTags,
      });
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          `Product Tags Successfully Updated`
        );

        queryClient.invalidateQueries("products");
      },
      onError: () => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update Product Tags`
        );
      },
    }
  );

  const SummaryHeader = (
    <div style={{ display: "flex" }}>
      <div style={{ width: "120px", paddingRight: "20px" }}>
        <ProductImageURL
          height={100}
          width={100}
          url={product?.product_image_url}
          style={{ borderRadius: 15 }}
        />
      </div>
      <div>
        <Row>
          <Col xs={12}>
            <h3
              style={{
                marginBottom: "0.2rem",
                marginTop: "0",
                fontSize: 22,
              }}
            >
              {product?.product_title}
            </h3>
          </Col>
          <Col xs={12}>
            {product?.asin} ({product?.sku}){" "}
            <ExternalProductLink asin={product?.asin} />
          </Col>
          <Col xs={12}>
            <Row className="pt-3">
              <Col>
                <div className="pe-5 text-center">
                  <h6
                    style={{ fontWeight: 600 }}
                    className="fs-standard text-uppercase pt-1 text-nowrap"
                  >
                    Inventory
                  </h6>
                  <Badge
                    className="badge bg-trellis-purple"
                    style={{
                      marginRight: "0.5rem",
                      backgroundColor: "#c43066",
                    }}
                    pill
                  >
                    {inventoryBadgeText ? inventoryBadgeText : "unknown"}
                  </Badge>
                </div>
              </Col>
              {salesLabel && (
                <Col>
                  <div className="pe-5 text-center">
                    <h6
                      style={{ fontWeight: 600 }}
                      className="fs-standard text-uppercase pt-1 text-nowrap"
                    >
                      Life Cycle
                    </h6>
                    <Badge className="badge bg-trellis-fuchsia" pill>
                      {salesLabel}
                    </Badge>
                  </div>
                </Col>
              )}
              {pricingInfo && (
                <Col>
                  <div className="pe-5 text-center">
                    <h6
                      style={{ fontWeight: 600 }}
                      className="fs-standard text-uppercase pt-1 text-nowrap"
                    >
                      Listing Status
                    </h6>
                    <Badge className="badge bg-trellis-fuchsia" pill>
                      {pricingInfo.amazonStatus ?? "unknown"}
                    </Badge>
                  </div>
                </Col>
              )}
              {pricingInfo?.productPrice ? (
                <Col>
                  <div className="pe-5 text-center">
                    <h6
                      style={{ fontWeight: 600 }}
                      className="fs-standard text-uppercase pt-1 pr-3 text-nowrap"
                    >
                      Price
                    </h6>

                    <Badge
                      className={`badge bg-trellis-lightgrey text-end`}
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      {formatCurrency(
                        pricingInfo.productPrice,
                        marketPlace.marketPlace
                      )}
                    </Badge>
                  </div>
                </Col>
              ) : (
                <></>
              )}
              {product?.eligibility_status && (
                <Col>
                  <div className="pe-5 text-center">
                    <h6
                      style={{ fontWeight: 600 }}
                      className="fs-standard text-uppercase pt-1 pr-3 text-nowrap"
                    >
                      Eligibility Status
                    </h6>

                    <Badge
                      className={`badge ${
                        eligibilityStatusMap[
                          product?.eligibility_status ?? "ELIGIBLE"
                        ]
                      } rounded-pill text-end`}
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      {snakeCaseToSentenceCase(product?.eligibility_status)}
                    </Badge>
                  </div>
                </Col>
              )}

              <Col>
                <div className="pe-5 text-center">
                  <Tags
                    filledTags={product?.tags ?? []}
                    saveFilledTags={async (filledTags) =>
                      updateTags.mutate([...new Set(filledTags)])
                    }
                    name={`Add Product Tags`}
                    hideTagsField={true}
                    hideEditButton={true}
                    altButton={
                      <span className="d-flex align-items-center">
                        <h6
                          style={{ fontWeight: 600 }}
                          className="fs-standard text-uppercase pt-1 pr-2 text-nowrap"
                        >
                          Tags
                        </h6>
                        <i
                          style={{
                            fontSize: "15px",
                            opacity: ".8",
                            cursor: "pointer",
                          }}
                          className="fa fa-plus"
                        />
                      </span>
                    }
                    altButtonContainerClasses={"mx-auto"}
                  />

                  <div className="d-flex pt-1 justify-content-center gap-2">
                    {product?.tags?.map((tag, index) => (
                      <CustomTag
                        tag={tag}
                        key={tag}
                        onUpdate={(tag) => {
                          updateTags.mutate(
                            product?.tags?.filter(
                              (tagToDelete) =>
                                tag.toLowerCase() !== tagToDelete.toLowerCase()
                            )
                          );
                        }}
                      />
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <Loading height={"300px"} fullPage={false} />
      ) : (
        <Row>
          <NotificationSystem ref={notificationRef} />;
          <Col xs={12} className="mb-4 fs-standard">
            <MetricsCard
              initialSelectedMetric={["sales", "units", "roms"]}
              titleElement={SummaryHeader}
              metrics={overviewMetrics}
              data={data?.date}
              loading={isOverviewDataLoading}
              group={group}
              showMetricsSelector
              id={"productDashboard"}
              showAnnotations={true}
              selectedProduct={productId}
            >
              <Row>
                <Col xs={12} className="mt-5 ph-4 mb-3 border-bottom">
                  <Nav
                    variant="underline"
                    onSelect={(k) => {
                      return setTab(k);
                    }}
                    activeKey={tab}
                  >
                    {additionalTabs?.map((additionalTab) => (
                      <Nav.Item>
                        <Nav.Link eventKey={additionalTab.title?.toLowerCase()}>
                          {additionalTab.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                    <Nav.Item>
                      <Nav.Link eventKey="details">Product Details</Nav.Link>
                    </Nav.Item>
                    {checkApplication(user, ADVERTISING) && (
                      <Nav.Item>
                        <Nav.Link eventKey="advertising">Advertising</Nav.Link>
                      </Nav.Item>
                    )}
                    {checkApplication(user, DEALS) && (
                      <Nav.Item>
                        <Nav.Link eventKey="deals">Deals</Nav.Link>
                      </Nav.Item>
                    )}
                    {checkApplication(user, DYNAMIC_PRICING) && (
                      <>
                        <Nav.Item>
                          <Nav.Link eventKey="pricing">
                            Dynamic Pricing
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    )}
                    {checkApplication(user, CONTENT) && (
                      <Nav.Item>
                        <Nav.Link eventKey="content">Content</Nav.Link>
                      </Nav.Item>
                    )}

                    <Nav.Item>
                      <Nav.Link eventKey="organic-search">
                        Organic Search (Beta)
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {additionalTabs?.map((additionalTab) => (
                    <>
                      {tab === additionalTab.title?.toLowerCase() &&
                        additionalTab.content}
                    </>
                  ))}
                  {tab === "advertising" && (
                    <AdvertisingSection
                      productId={productId}
                      marketPlace={marketPlace}
                    />
                  )}
                  {tab === "deals" && (
                    <DealsSection
                      productId={productId}
                      marketPlace={marketPlace}
                      channel={channel}
                      data={data}
                      categoryId={categoryId}
                    />
                  )}
                  {tab === "pricing" && (
                    <PricingSection
                      productId={productId}
                      marketPlace={marketPlace}
                      channel={channel}
                      data={data}
                      isLoading={isOverviewDataLoading}
                      product={product}
                      modules={modules}
                      pricingInfo={pricingInfo}
                      user={user}
                    />
                  )}
                  {tab === "content" && (
                    <ContentSection
                      productId={productId}
                      marketPlace={marketPlace}
                      channel={channel}
                      data={data}
                    />
                  )}
                  {tab === "details" && (
                    <ProductDetails
                      user={user}
                      product={product}
                      marketPlace={marketPlace}
                      channel={channel}
                      start={start}
                      end={end}
                      modules={modules}
                    />
                  )}

                  {tab === "organic-search" && (
                    <ASINTable
                      marketPlace={marketPlace}
                      asin={product?.asin}
                      showAddKWButon={true}
                    />
                  )}
                </Col>
              </Row>
            </MetricsCard>
          </Col>
        </Row>
      )}
    </>
  );
};

// Wrapper which fetches data, generates a breadcrumb trail
// and adds the BreadcrumbDates component
const Product = ({ marketPlace, channel, user, productId, modules }) => {
  // Pulls dates from provided context
  const { start, end, preStart, preEnd, group } = useDates();

  // Data fetching

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "product_id",
    product_id: productId,
    channel: channel?.currentChannel ?? "amazon",
  };

  const { data, isLoading: isOverviewDataLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      productId,
    ],
    `/api/data_report/product_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: !!productId,
    }
  );

  const categoryId =
    data?.products[0]?.category_id ?? data?.products[0]?.product_category;

  // Breadcrumbs
  let { currentCategoryDetails: categoryDetails, categories: categoryNavData } =
    useCategories(categoryId, "merchandising");

  let { currentProductDetails, products: productNavData } = useProducts(
    "merchandising",
    categoryId,
    productId
  );

  const breadCrumbItems = [
    {
      name: "Merchandising Dashboard",
      href: getDashboardTo({
        resourceId: null,
        type: DASHBOARD_TYPES.ROOT,
      }),

      dropdownLinkOptions: DASHBOARD_LINKS,
      searchable: false,
      unsorted: true,
      dropdownSubtitle: "Dashboards",
    },
    {
      name: categoryDetails?.leaf_category_name,
      href: categoryDetails.href,
      dropdownLinkOptions: categoryNavData,
      searchable: true,
      searchPlaceholderText: "Search categories...",
      dropdownSubtitle: "Categories",
    },
    {
      name: cut(currentProductDetails?.product_title, 50),
      href: currentProductDetails?.href,
      dropdownLinkOptions: productNavData,
      searchable: true,
      searchPlaceholderText: "Search products...",
      dropdownSubtitle: "Products",
    },
  ];

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  return (
    <Container
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-4 px-3">
          <ProductCard
            productId={productId}
            marketPlace={marketPlace}
            user={user}
            channel={channel}
            modules={modules}
            data={data}
            isOverviewDataLoading={isOverviewDataLoading}
          />
        </Col>
      </Row>
    </Container>
  );
};

// Wrapper which handles URL param date changes and provides a date context
const MerchProduct = ({ user, marketPlace, modules, channel }) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { product: productId = null } = useParams();

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Product
        productId={productId}
        marketPlace={marketPlace}
        channel={channel}
        user={user}
        modules={modules}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(MerchProduct);
