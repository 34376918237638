import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import pricingLogo from "assets/images/merchandise/pricing-logo.svg";
import advertisingLogo from "assets/images/merchandise/advertising-logo.svg";
import contentLogo from "assets/images/merchandise/content-logo.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CTA = ({ title, icon, children, to, toText }) => {
  return (
    <Card className="mx-4">
      <Card.Body>
        <Card.Img
          variant="top"
          src={icon}
          style={{
            padding: "1rem",
            margin: "auto",
            width: "180px",
            height: "180px",
            display: "block",
          }}
        />
        <Card.Title className="text-center">
          <h3 className="text-strong">{title}</h3>
        </Card.Title>
        <div style={{ height: "200px" }} className="px-3">
          {children}
        </div>
        <div className="text-center m-5">
          <Link to={to} className="logout_button text-decoration-none">
            {toText}
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

const EmptyState = ({ type, modules }) => {
  const {
    channel: { currentChannel: channel },
  } = useSelector((state) => state);

  return (
    <Row className="fs-standard mx-5">
      <Col xs={12} className="text-center">
        <h3>Welcome to Trellis</h3>
      </Col>
      {type === "NO_DATA" && (
        <Col xs={12} className={"text-center"}>
          Please connect to your marketplace to get started.
          <div className="mt-5">
            {channel === "walmart" ? (
              <Link
                to="/user/walmartsummary"
                className="logout_button text-decoration-none"
              >
                Walmart Connect
              </Link>
            ) : (
              <Link
                to="/user/amzsummary"
                className="logout_button text-decoration-none"
              >
                Amazon Connect
              </Link>
            )}
          </div>
        </Col>
      )}
      {type === "COLLECTING_DATA" && (
        <Col xs={12} className={"text-center"}>
          We're collecting data and building your dashboard.
          <br />
          In the meantime explore the Trellis platform:
        </Col>
      )}
      {channel === "walmart" ? (
        <>
          <Row className="mt-5">
            <Col xs={12} lg={{ span: 4, offset: 4 }}>
              <CTA
                title={"Advertising"}
                to={"/user/advertising/dashboard"}
                toText={"Create a media plan"}
                icon={advertisingLogo}
              >
                <p>
                  Trellis’ bidding algorithm dramatically reduces wasted ad
                  spend by:
                </p>
                <ul>
                  <li>
                    Harvesting and implementing keywords across the shoppers
                    funnel
                  </li>
                  <li>Adjusting bids daily, and</li>
                  <li>Reallocating budgets.</li>
                </ul>
              </CTA>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Col xs={0} md={0} xxl={1} />
          <Col xs={12} md={12} xxl={10}>
            <Row className="mt-5">
              <Col xs={12} lg={4}>
                <CTA
                  title={"Advertising"}
                  to={"/user/advertising/dashboard"}
                  toText={"Create a media plan"}
                  icon={advertisingLogo}
                >
                  <p>
                    Trellis’ bidding algorithm dramatically reduces wasted ad
                    spend by:
                  </p>
                  <ul>
                    <li>
                      Harvesting and implementing keywords across the shoppers
                      funnel
                    </li>
                    <li>Adjusting bids daily, and</li>
                    <li>Reallocating budgets.</li>
                  </ul>
                </CTA>
              </Col>
              <Col xs={12} lg={4}>
                <CTA
                  title={"Dynamic Pricing"}
                  to={"/user/_pricing/dashboard"}
                  toText={"Enroll Products"}
                  icon={pricingLogo}
                >
                  <p>
                    By tracking and understanding your users' behavior, our tool
                    can optimize for:
                  </p>
                  <ul>
                    <li>Sales growth</li>
                    <li>Profit margins, or</li>
                    <li>Net profit.</li>
                  </ul>
                </CTA>
              </Col>
              <Col xs={12} lg={4}>
                <CTA
                  title={"Content"}
                  to={"/user/content/quality/org"}
                  toText={"View Content"}
                  icon={contentLogo}
                >
                  <p>
                    We provide content suggestions by analyzing your titles, A+
                    Content, images, price, and more to improve your:
                  </p>
                  <ul>
                    <li>Search Engine Optimization (SEO)</li>
                    <li>The appeal of your listing, and</li>
                    <li>Your listing's effectiveness at converting users.</li>
                  </ul>
                </CTA>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={0} xxl={1} />
        </>
      )}
    </Row>
  );
};

export default EmptyState;
