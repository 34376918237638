import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Card from "components/core/basic/Card.jsx";
import { useMutation, useQuery } from "react-query";
import api from "utils/api";
import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthButton } from "components/core/basic/Button";
import moment from "moment";

const TrellisExtension = () => {
  const [token, setToken] = useState();
  const [counter, setCounter] = useState(0);

  const { data, isLoading, isFetching } = useQuery(
    ["chromeaccess", counter],
    async () => {
      const res = await api.get(`/api/chrometoken/${token?.id}`);

      return res.data;
    },
    {
      enabled: !!token,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const history = useHistory();

  const createToken = useMutation({
    mutationFn: async () => {
      return await api.post("/api/chrometoken/");
    },
    onSuccess: (res) => {
      setToken(res.data);
      history.replace(`#${res.data.id}`);
    },
  });

  useEffect(() => {
    createToken.mutate();
    return setInterval(() => {
      setCounter((c) => {
        return c + 1;
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateNewToken = () => {
    console.log("GENERATE_NEW_TOKEN");
    createToken.mutate();
  };

  return (
    <>
      <div
        className="container"
        style={{ padding: "3rem", minHeight: "calc(100vh - 150px)" }}
      >
        <Row>
          <Col className={"s8 offset-s2 text-center"}>
            <h4>
              <b>Trellis Chrome Extension</b>
            </h4>
            <Card
              content={
                <Grid item container alignItems="center">
                  <Grid item md={5}>
                    <h6
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        display: "inline-flex",
                        marginRight: "2rem",
                      }}
                    >
                      Access Code:
                    </h6>
                    <span
                      id="chromeCode"
                      style={{
                        fontSize: "24px",
                        fontWeight: 300,
                        display: "inline-flex",
                      }}
                    >
                      {token?.token}
                    </span>
                  </Grid>
                  <Grid item md={5} style={{ textAlign: "left" }}>
                    <h6
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        display: "inline-flex",
                        marginRight: "2rem",
                      }}
                    >
                      Expires:
                    </h6>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 300,
                        display: "inline-flex",
                      }}
                    >
                      {moment(data?.expiry_date).fromNow()}
                    </span>
                  </Grid>
                  <Grid item md={2} style={{ textAlign: "left" }}>
                    <h6 style={{ fontSize: "18px", fontWeight: 700 }}>
                      {(!isLoading || !isFetching) && data?.used_on
                        ? "Success"
                        : "Valid"}
                    </h6>
                  </Grid>
                </Grid>
              }
            />
            <AuthButton
              title="Generate New Token"
              className="logout_button"
              onClick={generateNewToken}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(TrellisExtension);
