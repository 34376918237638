import { PrimaryButton, SecondaryButton } from "components/core/basic/Button";
import Loading from "components/core/blocks/Loading";
import SummaryCard from "components/core/blocks/SummaryCard";
import {
  ProgramContextProvider,
  useProgramContext,
} from "components/custom/programs/ProgramContext";
import ProgramsForm from "components/custom/programs/ProgramsForm";
import { useFetch } from "hooks/api";
import React from "react";
import { Col, Container as Grid, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import api from "utils/api";
import { toTitleCase } from "utils/formatText";

const ProgramEdit = ({ id, defaultValues }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { engine } = useProgramContext();

  const { handleSubmit, ...form } = useForm({
    shouldUnregister: false,
    defaultValues,
  });
  const updateProgram = useMutation(
    async (data) => {
      const { name, start_date, end_date, ...spec } = data;
      await api.patch(`/programs/${id}/`, {
        id,
        name,
        start_date,
        end_date,
        spec,
        status: "scheduled",
      });
    },
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries("programs");

        if (["bidding", "budget"].includes(engine)) {
          history.goBack();
          return;
        }

        history.push("/user/programs");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const onInvalid = (errors) => console.log("errors", errors);

  return (
    <Grid fluid style={{ padding: "3rem", minHeight: "calc(100vh - 150px)" }}>
      <SummaryCard mobileStyle title={`Edit ${toTitleCase(engine)} Rules`}>
        <Row>
          <Col xs={12} lg={8} className="m-auto">
            <ProgramsForm {...form} />
            <Row className="mt-4">
              <Col md={12} className="d-flex justify-content-end">
                <div style={{ marginRight: "2rem" }}>
                  <SecondaryButton
                    nowrap
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                </div>
                <div>
                  <PrimaryButton
                    disabled={
                      updateProgram.isLoading || form.formState.isSubmitting
                    }
                    onClick={handleSubmit(updateProgram.mutate, onInvalid)}
                    nowrap
                  >
                    Update
                  </PrimaryButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </SummaryCard>
    </Grid>
  );
};

const Wrapper = () => {
  const { id: programId } = useParams();
  const { data, loading } = useFetch(
    ["programs", programId],
    `/programs/${programId}/`,
    {},
    {
      select: (data) => data.data,
      staleTime: Infinity,
    }
  );
  if (data && !loading) {
    const { id, name, start_date, end_date, spec, engine } = data;

    return (
      <ProgramContextProvider engine={engine}>
        <ProgramEdit
          id={id}
          defaultValues={{
            name,
            start_date,
            end_date,
            ...spec,
          }}
        />
      </ProgramContextProvider>
    );
  }
  return <Loading />;
};

export default Wrapper;
