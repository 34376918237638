import React, { useState } from "react";
import api from "utils/api";
import { getAPIKeys } from "utils/getUrlPrefix";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import Form from "react-bootstrap/Form";
import { Row, Col, InputGroup } from "react-bootstrap";
import { SocialButton } from "./SocialButton";
import { setCurrentUser, setTokens } from "redux/actions/authActions";
import { useDispatch } from "react-redux";
import setAuthToken from "utils/setAuthToken";
import { setMarketPlace } from "redux/actions/marketPlace";
import { setChannel } from "redux/actions/channel";
import { setBypassOnboarding } from "redux/actions/userActions";
import { trackMixpanelEvent } from "hooks/useMixpanelTracking";
import { trackGaEvent } from "utils/trackGaEvent";

const apiKeys = getAPIKeys();

const CLIENT_ID = apiKeys["API_AUTH_CLIENT_ID"];
const CLIENT_SECRET = apiKeys["API_AUTH_SECRET"];

const SignInMethod = ({ registerFormData }) => {
  const [credentialError, setCredentialError] = useState({});
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formError, setFormError] = useState({});

  const history = useHistory();
  const dispatch = useDispatch();

  const { email, password, firstName, lastName } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    formError[name] = null;

    setCredentialError({});
    setFormData({ ...formData, [name]: value });
  };

  const handleSocialAuthCreation = async (res) => {
    const { email, access_token } = res;
    const appAuthRes = await api.post(`/api/authentication/`, {
      custom_api_request: "organization_registration_verification",
      user_email: email,
    });

    if (appAuthRes.data === "User Exists") {
      setCredentialError({
        ...credentialError,
        amazonOAuth:
          "A user with the provided credentials already exists. Please login to Amazon with a different account or sign up with a username and password.",
      });
      setFormError({});
      // Below clears browser cookie that leads to bad state for oAuth
      // Without it, retries are not supported if an error comes from Trellis due to users with the same email already existing
      document.cookie =
        "amazon_Login_state_cache=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      return;
    }

    if (
      ["Invite Available", "Proceed for Registration"].includes(appAuthRes.data)
    ) {
      const newUserRes = await api.post("/auth/convert-token", {
        grant_type: "convert_token",
        backend: "amazon",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        token: access_token,
      });

      const tokens = {
        refresh_token: newUserRes.data.refresh_token,
        access_token: newUserRes.data.access_token,
      };
      await createOrg(tokens, email);
    }
  };

  const createOrg = async (tokens, socialAuthEmail = null) => {
    const signUpFormData = {
      ...registerFormData,
      user_email: socialAuthEmail ?? email,
      bypass_onboarding: new Date().toISOString().slice(0, 10),
    };

    await api.post("/api/registerorganization/", signUpFormData);
    const { access_token: accessToken } = tokens;

    localStorage.setItem("Tokens", JSON.stringify(tokens));
    localStorage.setItem("TokenTime", (Date.now() + 60 * 60 * 1000).toString());

    const authRes = await api.post("/api/authentication/", {
      custom_api_request: "login_user_info",
      user_email: socialAuthEmail ?? email,
    });

    setAuthToken(accessToken);
    dispatch(setMarketPlace("USA"));
    dispatch(setChannel("amazon"));
    dispatch(setCurrentUser(authRes.data));
    dispatch(setTokens(tokens));
    dispatch(setBypassOnboarding(true));

    localStorage.setItem("Marketplace", "USA");
    localStorage.setItem("channel", "amazon");

    trackMixpanelEvent("Click", {
      name: "Sign Up",
      element: "button",
      extension_user: localStorage.extensionSignup ? true : false,
      ...registerFormData,
    });
    trackGaEvent(
      localStorage.extensionSignup
        ? "Extension User Sign Up"
        : "App User Sign Up",
      "Signup"
    );

    history.push("/onboarding/v2/about/type");
  };
  const createUserAccount = useMutation({
    mutationFn: async () => {
      return await api.post("/api/signup/", {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
      });
    },
    onSuccess: async (res) => {
      const loginRes = await api.post("/auth/token", {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: "password",
        username: email,
        password: password,
      });

      if (loginRes.status === 200) {
        const { access_token, refresh_token } = loginRes.data;
        await createOrg({ access_token, refresh_token });
      }
    },
    onError: (res) => {
      const errors = res.response.data;

      if (errors.email) {
        setFormError({ ...formError, email: errors.email[0] });
      }

      if (errors.password) {
        setFormError({ ...formError, password: errors.password[0] });
      }
    },
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!firstName) {
      setFormError({ ...formError, firstName: "First name is required" });
      return;
    }

    if (!lastName) {
      setFormError({ ...formError, lastName: "Last name is required" });
      return;
    }

    if (!email) {
      setFormError({ ...formError, email: "An email is required" });
      return;
    }

    if (!password) {
      setFormError({ ...formError, password: "A password is required" });
      return;
    }

    createUserAccount.mutate();
  };

  if (!Object.keys(registerFormData ?? {})?.length) {
    history.push("/");
  }

  return (
    <>
      <Row className="my-5">
        <Col xs={12} lg={{ offset: 2, span: 8 }} className="text-start">
          <h2 className="mt-5 mb-1 fw-600">Welcome to Trellis</h2>
          <p className="fs-4">Merchandising Platform</p>
          <h4 className="fw-500">Choose a sign in method</h4>
        </Col>
      </Row>
      <Row className="my-5">
        {" "}
        <Col xs={12} lg={{ offset: 2, span: 8 }}>
          <SocialButton
            handleSocialAuthCreation={handleSocialAuthCreation}
            handleSocialAuthError={(e) => {
              setCredentialError({
                ...credentialError,
                amazonOAuth:
                  "An error occurred logging into Amazon. Please try again.",
              });
            }}
            className="btn-amazon-sign-in adplan_button w-100 mb-5"
          >
            Sign Up With Amazon
          </SocialButton>
          {Object.entries(credentialError).length ? (
            <p className="mt-2 ps-3 fs-5 invalid-feedback d-block text-center">
              {Object.values(credentialError).map((error) => error)}{" "}
            </p>
          ) : (
            <></>
          )}
          <div className="text-center mx-auto fs-1 pt-3 pb-5 fw-500">Or</div>

          <Form onSubmit={(e) => e.preventDefault()} noValidate>
            <Row>
              <Col xs={12} lg={6} className="p-0 px-3 mt-4 my-0">
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                    className="py-4 px-5"
                    onChange={handleChange}
                    isInvalid={formError.firstName}
                  />

                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-2 ps-3 fs-5"
                  >
                    {formError.firstName}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Col xs={12} lg={6} className="p-0 px-3 mt-4 my-0">
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={lastName}
                    placeholder="Last Name"
                    className="py-4 px-5"
                    onChange={handleChange}
                    isInvalid={formError.lastName}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-2 ps-3 fs-5"
                  >
                    {formError.lastName}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Row>

            <InputGroup hasValidation className="my-5">
              <Form.Control
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                className="py-4 px-5"
                onChange={handleChange}
                isInvalid={formError.email}
              />

              <Form.Control.Feedback
                type="invalid"
                className="mt-2 ps-3 fs-5 invalid-feedback d-block"
              >
                {formError.email === "This field must be unique."
                  ? "A user with the provided credentials already exists. Please enter a unique email."
                  : formError.email}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup hasValidation className="my-5">
              <Form.Control
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                className="py-4 px-5"
                onChange={handleChange}
                isInvalid={formError.password}
              />

              <Form.Control.Feedback type="invalid" className="mt-2 ps-3 fs-5">
                {formError.password}
              </Form.Control.Feedback>
            </InputGroup>
            <button
              className="adplan_button w-100"
              onClick={(e) => handleFormSubmit(e)}
              disabled={createUserAccount.isLoading}
            >
              Sign Up
            </button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default SignInMethod;
