import { useEffect, useMemo, useState } from "react";

const useMinLoading = (isLoading, minSteps, interval) => {
  const [loading, setLoading] = useState();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && count > minSteps) {
      setLoading(false);
      setCount(0);
    }
  }, [isLoading, count, minSteps]);

  useEffect(() => {
    if (loading) {
      const handle = setInterval(() => setCount((prev) => prev + 1), interval);
      return () => clearInterval(handle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, interval]);

  const step = useMemo(() => {
    return Math.min(minSteps, count);
  }, [minSteps, count]);

  return { loading, step };
};

export default useMinLoading;
