import React, { useEffect, useState, useRef } from "react";
import { withStyles, Grid, IconButton } from "@material-ui/core";
import { trellisPalette } from "components/custom/analytics/palettes";
import useWizard from "./useWizard";
import FormWizardPagination from "./FormWizardPagination";
import "assets/css/media_plan.css";
import withMediaQuery from "hocs/withMediaQuery";

const useStyles = (theme) => ({
  stepItem: {
    display: "grid",
    padding: theme.spacing(2, 0),
    "& $completed": {
      color: "lightgreen",
    },
    "& $active": {
      background: "#62407F",
      color: "#FFF",
    },
    "@media (max-width:1279px)": {
      width: "25%",
      justifyContent: "center",
    },
  },
  iconContainer: {
    transform: "scale(2.4)",
    background: "#FFF",
    marginRight: theme.spacing(2),
    marginBottom: "auto",
    "@media (max-width:1279px)": {
      transform: "scale(2)",
      marginRight: "1.5rem",
      paddingRight: 0,
    },
  },
  stepIcon: {
    marginRight: ".5rem",
    fontWeight: 500,
  },
  activeIcon: {
    backgroundColor: trellisPalette[0],
    borderRadius: "100%",
  },
  activeStep: {
    color: "#62407FE",
  },
  wizardStepContainer: {
    boxSizing: "border-box",
    borderRight: "1px solid #DDD",
    marginRight: theme.spacing(4),
    position: "relative",
    minHeight: "630px",
    maxHeight: "80vh",
    maxWidth: "240px",
    "@media (max-width:1279px)": {
      minHeight: "0",
      borderRight: 0,
      maxWidth: "100%",
      margin: "auto",
    },
  },
  fullPageWizardStepContainer: {
    maxHeight: "none",
    marginTop: "-106px",
    marginBottom: "5px",
  },
  wizardStepContainerInner: {
    listStyleType: "none",
    "@media (max-width:1279px)": {
      display: "flex",
      justifyContent: "space-around",
    },
    "@media (max-width:600px)": {
      flexDirection: "column",
    },
  },
  steppers: {
    "@media (max-width:1279px)": {
      alignItems: "flex-start",
    },
  },
  parentStep: {
    fontSize: "16px",
    fontWeight: 500,
    "@media (max-width:1279px)": {
      fontSize: "16px",
    },
  },
  substeps: {
    listStyleType: "none",
    paddingLeft: ".5rem",
  },
  substep: {
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    "@media (max-width:1279px)": {
      fontSize: "14px",
    },
  },
  formContainer: {
    position: "relative",
    height: "80vh",
    overflowY: "auto",
    overflowX: "clip",
    paddingBottom: "50px",
    paddingRight: "1.5rem",
    "@media (max-width:1279px)": {
      maxWidth: "100%",
      height: "auto",
    },
  },
  scrollBar: {
    "&::-webkit-scrollbar-track": {
      // display: "none",
      marginBottom: "2rem",
      backgroundColor: "rgb(206, 212, 218)",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar": {
      width: "0.45rem",
      maxHeight: "12px",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: trellisPalette[3],
      width: "0.45rem",
      borderRadius: "2px",
    },
  },
});

const FormWizard = ({
  compositeProgress,
  classes,
  updateStep,
  editStep,
  setEditStep,
  editSubstep,
  errors,
  isValid,
  isModal,
  closeModal,
  state = {},
  products,
  onCreate,
  onUpdate,
  onCancel,
  showCancelButton,
  exitButton,
  onExit,
  hidePreviousButtonVisibility,
  clearForm,
  fieldsToValidate,
  setFieldsToValidate,
  setReadyForStep,
  formSteps,
  assessFormSteps,
  formToRender,
  formStepDependencies,
  completeText,
  isChild,
  saveChild,
  onChildSave,
  childStep,
  setChildStep,
  savedChildPlans,
  setChildPlan,
  reset,
  autoExpandOn,
  autoExpandStep,
  formProgressRefs,
  mediaQuery,
  wizardContext,
  getExemptNavPaths = () => {},
}) => {
  let {
    onNext,
    onPrevious,
    step,
    setStep,
    steps,
    substep,
    setSubstep,
    highestStepVisited,
    highestSubstepVisited,
  } = useWizard(formSteps, assessFormSteps, formStepDependencies);

  steps = steps.filter((step) => !step.isHidden);
  // Below tracks validations for current step/substep, needed to trigger success animation
  const [prospectiveFieldsToValidate, setProspectiveFieldsToValidate] =
    useState([]);
  const [scrollDetected, setScrollDetected] = useState(false);
  const formContainerRef = useRef();

  const onSubmit = async () => {
    if (isValid) {
      if (state.id) {
        await onUpdate(state, products);
        if (state.canHaveChildren) {
          // onParentUpdate();
        }

        isModal && closeModal();
      } else {
        await onCreate(state, products);
        clearForm && clearForm();
        isModal && closeModal();
      }

      reset();
    }
  };

  const onScroll = () => {
    if (formContainerRef.current) {
      const { scrollTop } = formContainerRef.current;
      if (scrollTop) {
        setScrollDetected(true);
      } else {
        setScrollDetected(false);
      }
    }
  };

  useEffect(() => {
    if (editStep !== null && !getExemptNavPaths(state, editStep, editSubstep)) {
      updateStep(editStep, editSubstep ?? 0);
      setStep(editStep);
    } else {
      updateStep(step, substep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    step,
    substep,
    updateStep,
    editStep,
    editSubstep,
    setStep,
    getExemptNavPaths,
  ]);

  useEffect(() => {
    // Below decides when to render next step in form once all validations passed
    // TODO: decide on behavior when user goes back and makes previous step fields invalid
    let readyToProceed =
      prospectiveFieldsToValidate?.length > 0 &&
      !Object?.keys(errors).some((error) =>
        prospectiveFieldsToValidate?.includes(error)
      );

    if (
      (readyToProceed && autoExpandOn) ||
      (step && autoExpandOn && autoExpandStep < step)
    ) {
      updateStep(autoExpandStep);

      if (step !== autoExpandStep) {
        setFieldsToValidate((prevValidations) => [
          ...prevValidations,
          ...steps[autoExpandStep]?.validations,
        ]);
      }

      setStep(autoExpandStep);

      setReadyForStep && setReadyForStep(readyToProceed ? step + 1 : step);
    } else {
      setReadyForStep &&
        autoExpandOn &&
        setReadyForStep(readyToProceed ? step + 1 : step);
      readyToProceed && autoExpandOn && updateStep(step + 1);
    }
  }, [
    prospectiveFieldsToValidate,
    errors,
    setReadyForStep,
    step,
    setStep,
    updateStep,
    autoExpandOn,
    autoExpandStep,
    fieldsToValidate,
    setFieldsToValidate,
    steps,
    substep,
  ]);

  useEffect(() => {
    // Override Bootstrap's modal-body default padding without impacting all other modals
    if (document.querySelector(".modal-body")) {
      document.querySelector(".modal-body").style.paddingLeft = "0";
      document.querySelector(".modal-body").style.paddingBottom = "0";
      document.querySelector(".modal-body").style.paddingRight = "0";
    }
  }, [steps]);

  // Below is for checking validations without displaying error message to trigger next button animation/highlight
  useEffect(() => {
    setProspectiveFieldsToValidate(
      steps[step]?.substeps?.length > 0
        ? steps[step]?.substeps[substep]?.validations
        : steps[step]?.validations
    );
  }, [step, steps, substep, childStep]);

  return (
    <>
      <Grid container translate="no">
        {(!state.hasParent ||
          (state.hasParent && state.id) ||
          state.newChild) && (
          <Grid
            xs={12}
            lg
            className={`${classes.wizardStepContainer} ${
              autoExpandOn && classes.fullPageWizardStepContainer
            }`}
          >
            <ul className={`${classes.wizardStepContainerInner} p-0`}>
              {steps
                ?.filter((step) => !step.isHidden)
                ?.map((label, parentIndex) => (
                  <li
                    style={{
                      backgroundColor:
                        parentIndex === step ? "#62407F" : "inherit",
                      padding: "1rem",
                    }}
                  >
                    <span
                      className={classes.parentStep}
                      style={{
                        cursor:
                          parentIndex <= highestStepVisited ||
                          (autoExpandOn && parentIndex <= autoExpandStep)
                            ? "pointer"
                            : "inherit",
                        color: parentIndex === step ? "#FFF" : "#999",
                        paddingLeft: parentIndex > step ? "2.3rem" : "0",
                      }}
                      onClick={() => {
                        if (autoExpandOn && parentIndex <= autoExpandStep) {
                          if (formProgressRefs[parentIndex].current) {
                            formProgressRefs[
                              parentIndex
                            ].current.scrollIntoView({
                              behavior: "smooth",
                              block: "nearest",
                            });
                          }
                          return;
                        }

                        if (parentIndex <= highestStepVisited) {
                          updateStep(parentIndex, null);
                          setStep(parentIndex);

                          setSubstep(0);
                        }
                      }}
                    >
                      {parentIndex < step && (
                        <i className={`fa fa-check ${classes.stepIcon}`} />
                      )}
                      {parentIndex === step && (
                        <i
                          className={`fa fa-solid fa-caret-right ${classes.stepIcon}`}
                        />
                      )}
                      {parentIndex + 1}. {label?.title}
                    </span>
                    {label?.substeps && parentIndex === step && (
                      <>
                        <ul className={classes.substeps}>
                          {label?.substeps?.map((sub, index) => {
                            if (index === 0) {
                              return null;
                            }

                            return (
                              <li
                                data-att={index}
                                className={classes.substep}
                                style={{
                                  color: parentIndex === step ? "#FFF" : "#999",
                                  cursor:
                                    parentIndex <= highestStepVisited &&
                                    index <= highestSubstepVisited
                                      ? "pointer"
                                      : "inherit",
                                  paddingLeft: 0,
                                }}
                                onClick={() => {
                                  if (autoExpandOn) return;

                                  if (
                                    parentIndex <= highestStepVisited &&
                                    index <= highestSubstepVisited
                                  ) {
                                    setStep(parentIndex);
                                    updateStep(parentIndex);
                                    setSubstep(index);
                                  }
                                }}
                              >
                                {index < childStep + 1 || index < substep ? (
                                  <i
                                    className={`fa fa-check ${classes.stepIcon}`}
                                  />
                                ) : index === childStep + 1 ||
                                  (index === substep && !childStep) ? (
                                  <i
                                    className={`fa fa-solid fa-caret-right ${classes.stepIcon}`}
                                  ></i>
                                ) : null}
                                {sub.title}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </li>
                ))}
            </ul>
            <FormWizardPagination
              readyToProceed={
                !Object?.keys(errors).some((error) =>
                  prospectiveFieldsToValidate?.includes(error)
                )
              }
              isModal={isModal}
              onPrevious={() => {
                if (autoExpandOn && step) {
                  if (formProgressRefs[step - 1].current) {
                    formProgressRefs[step - 1].current.scrollIntoView({
                      behavior: "smooth",
                      block: "nearest",
                    });
                  }
                }

                if (showCancelButton && step === 0) {
                  onCancel();
                }

                if (step > 0) {
                  onPrevious();
                } else {
                  onCancel();
                }
              }}
              onPreviousText={
                step === 0
                  ? "Cancel"
                  : childStep === 1
                  ? `${step}. ${steps[step - 1]?.title}`
                  : steps[step]?.substeps?.[substep - 1]
                  ? `${step + 1}${String.fromCharCode(
                      substep - 1 + 65
                    ).toLowerCase()}.  ${
                      steps[step]?.substeps?.[substep - 1]?.title
                    }`
                  : `${step}. ${steps[step - 1]?.title}`
                  ? `${step}. ${steps[step - 1]?.title}`
                  : "Cancel"
              }
              hidePreviousButtonVisibility={
                (step === 0 && !showCancelButton) ||
                hidePreviousButtonVisibility
              }
              onNext={async () => {
                if (autoExpandOn && step < steps.length - 1) {
                  if (formProgressRefs[step + 1].current) {
                    formProgressRefs[step + 1].current.scrollIntoView({
                      behavior: "smooth",
                      block: "nearest",
                    });
                  }
                }

                if (editStep !== null) {
                  setStep(editStep);
                  setSubstep(null);
                  setEditStep(null);
                }

                if (step === steps.filter((s) => !s.isHidden)?.length - 1) {
                  onSubmit();
                } else {
                  setFieldsToValidate(
                    steps[step]?.substeps?.length > 0
                      ? steps[step]?.substeps[substep]?.validations
                      : steps[step]?.validations
                  );

                  if (
                    !Object?.keys(errors).some((error) =>
                      steps[step]?.substeps?.length > 0
                        ? steps[step]?.substeps[substep]?.validations?.includes(
                            error
                          )
                        : steps[step]?.validations?.includes(error)
                    )
                  ) {
                    if (saveChild) {
                      updateStep(2, 0);
                      setStep(2);
                      setSubstep(0);
                      return;
                    }

                    if (
                      !state.newLandingPage &&
                      state.storeSpotlight &&
                      step === 2 &&
                      substep === 0 &&
                      state.renderAdvancedOptions
                    ) {
                      updateStep(2, 1);
                      setStep(2);
                      setSubstep(1);
                      return;
                    }

                    onNext();
                  }
                }
              }}
              onNextText={
                saveChild
                  ? "Save"
                  : step === steps.length - 1
                  ? completeText
                  : steps[step]?.substeps?.[substep + 1]
                  ? `${step + 1}${String.fromCharCode(
                      substep + 1 + 65
                    ).toLowerCase()}. ${
                      steps[step]?.substeps?.[substep + 1]?.title
                    }`
                  : `${step + 2}. ${steps[step + 1]?.title}`
              }
              hideButtonVisibility={isChild}
              isChild={isChild}
              showCancelButton={showCancelButton}
              onCancel={onCancel}
              hidePagination={autoExpandOn && mediaQuery}
              exitButton={exitButton}
              onExit={onExit}
              hideCancelButton={wizardContext === "media-plan"}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md
          className={`${classes.formContainer} ${classes.scrollBar}`}
          ref={formContainerRef}
          onScroll={onScroll}
          style={{
            boxShadow:
              scrollDetected && autoExpandOn
                ? "inset 0px 4px 3px -2px rgba(50, 50, 50, 0.04)"
                : "none",
          }}
        >
          {autoExpandOn &&
            !Object?.keys(errors).some((error) =>
              prospectiveFieldsToValidate?.includes(error)
            ) &&
            !mediaQuery && (
              <IconButton
                style={{
                  left: "370px",
                  bottom: "90px",
                  position: "fixed",
                  padding: "1rem",
                  borderRadius: "100%",
                  background: trellisPalette[0],
                  color: "#FFF",
                  animationDuration: "1.6s",
                  animationIterationCount: "infinite",
                  transformOrigin: "bottom",
                }}
                className={"nextIconAnimation"}
                onClick={() => {
                  if (
                    step + 1 < steps.length &&
                    formProgressRefs[step + 1].current
                  ) {
                    formProgressRefs[step + 1].current.scrollIntoView({
                      behavior: "smooth",
                      block: "nearest",
                    });
                  }

                  if (step + 1 === steps.length) {
                    document.querySelector("#submit-button") &&
                      document.querySelector("#submit-button").scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                      });
                  }
                }}
              >
                <i
                  className={`fa ${
                    step + 1 < steps.length
                      ? "fa-chevron-down"
                      : "fa-chevron-down"
                  }`}
                />
              </IconButton>
            )}
          {formToRender}
        </Grid>
      </Grid>
    </>
  );
};

export default withMediaQuery("(max-width:600px)")(
  withStyles(useStyles)(FormWizard)
);
