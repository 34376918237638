import React from "react";
import ReactTooltip from "react-tooltip";

const AssetOverflowTooltip = ({ text, onClick }) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#333",
        }}
        data-for={text}
        data-tip={text}
      >
        {text}
      </div>
      {text.length > 12 && (
        <div style={{ fontWeight: "500" }}>
          <ReactTooltip
            id={text}
            place={"top"}
            type="info"
            effect="solid"
            textColor="#fff"
            backgroundColor="#2E0054"
            cursor="pointer"
          />
        </div>
      )}
    </div>
  );
};

export default AssetOverflowTooltip;
