export const snakeCaseToSentenceCase = (text) => {
  if (!text) {
    return "";
  }
  let formattedText = text
    .split("_")
    .map((w) => w.toLowerCase())
    .map((w) => {
      let l1 = w.split("")[0].toUpperCase();
      let remaining = w.split("").slice(1);
      return [...l1, ...remaining].join("");
    })
    .join(" ");

  if (formattedText.indexOf("Acos") > -1) {
    formattedText = formattedText.replace("Acos", "ACOS");
  }

  return formattedText;
};

export const cut = (text, maxLength) => {
  if (!text) {
    return text;
  }

  if (text.length < maxLength) {
    return text;
  }

  let len = 0;
  for (let i = 0; i < text.length; i++) {
    const char = text.substring(i, i + 1);
    len++;

    if (len < maxLength) {
      continue;
    }

    if (len >= maxLength && char === " ") {
      return `${text.substring(0, len - 1)}...`;
    }
  }

  return text;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isValidDataframeDate = (d) => {
  if (d === undefined) {
    return false;
  }

  if (d === null) {
    return false;
  }

  if (d === 0) {
    return false;
  }

  if (typeof d !== "string") {
    return false;
  }
  if (d.length !== 10) {
    return false;
  }

  if (isNaN(parseInt(d.substring(0, 4)))) {
    return false;
  }
  if (isNaN(parseInt(d.substring(8, 10)))) {
    return false;
  }
  if (isNaN(parseInt(d.substring(5, 7)))) {
    return false;
  }

  return true;
};
