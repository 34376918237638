import React, { useState, useMemo } from "react";
import { Row, Col, Container as Grid, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { useFetch } from "hooks/api";
import ProductsTable from "components/core/blocks/ProductsTable";
import ProductCard from "modules/pricingv2/ProductCard";
import { useMutation, useQueryClient } from "react-query";
import Loading from "components/core/blocks/Loading";
import { formatNumber, formatPercent } from "utils/formatNumber";
import { SetValue, sendUpdates } from "./components/SetValue";
import { useLocation, useParams } from "react-router-dom";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import { selectModules } from "redux/selectors/modules";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import { BuyBoxContext } from "./context";
import DownloadReport from "modules/perf_reports/DownloadReport";
import Metric from "components/core/blocks/SummaryMetric";

const useStyles = (theme) => ({});

export const BuyBoxProvider = ({ children, marketPlace }) => {
  const [lostBuyBox, setLostBuyBox] = useState([]);

  const { isLoading } = useFetch(
    ["buybox_stats", marketPlace.marketPlace],
    `/pricing/buybox/stats`,
    {
      market_place: marketPlace.marketPlace,
    },
    {
      default: [],
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setLostBuyBox(res?.data?.data?.not_buy_box);
      },
    }
  );

  return (
    <BuyBoxContext.Provider value={{ lostBuyBox, isLoading }}>
      {children}
    </BuyBoxContext.Provider>
  );
};

const Dashboard = ({ marketPlace, user, category, modules }) => {
  const [invalidateProductsTable, setInvalidateProductsTable] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [valueType, setValueType] = useState("min_price");
  const [showSetValue, setShowSetValue] = useState(false);
  const queryClient = useQueryClient();

  const breadCrumbItems = [{ name: "BuyBox", href: "/user/_pricing/buybox" }];

  const { group, date, start, end, preStart, preEnd } = useDates();

  let filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    group_by: "product_id",
    channel: "amazon",
    filters: "not_buybox",
    dt: date ? date.format("YYYY-MM-DD") : null,
  };

  const { data: productData, isLoading } = useFetch(
    ["product_report", marketPlace.marketPlace],
    `/api/data_report/product_report`,
    { ...filters },
    {
      default: [],
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (invalidateProductsTable) {
          invalidateProductsTable[0]();
          setInvalidateProductsTable(null);
        }
      },
    }
  );

  const { data: buyboxData, isLoading: buyboxLoading } = useFetch(
    ["buybox_stats", marketPlace.marketPlace],
    `/pricing/buybox/stats`,
    {
      market_place: marketPlace.marketPlace,
    },
    {
      default: [],
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (invalidateProductsTable) {
          invalidateProductsTable[0]();
          setInvalidateProductsTable(null);
        }
      },
    }
  );

  const productById = useMemo(() => {
    const ids = {};
    if (productData?.data?.products) {
      for (const product of productData?.data?.products) {
        ids[product.id] = product;
      }
    }
    return ids;
  }, [productData]);

  const onSelection = (selectedProducts) => {};

  const ExpandedContent = ({ marketPlace, row, user }) => {
    return (
      <div style={{ backgroundColor: "#efefef" }} className="text-start">
        <ProductCard
          marketPlace={marketPlace}
          productId={row?.original?.product_id}
          sku={row?.original?.sku}
          user={user}
        />

        {/* This is the content for something that's not there  */}
      </div>
    );
  };

  const updateProducts = useMutation(async (updates) => {
    await sendUpdates("product_id", updates, marketPlace, queryClient);
  });

  return (
    <>
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems}>
            <span></span>
          </BreadcrumbDates>
        </Col>
      </Row>
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid style={{ minHeight: "calc(100vh - 150px)" }}>
          <Row>
            <Col xs={12}>
              {(isLoading || buyboxLoading) && <Loading />}

              {!isLoading && (
                <>
                  <Row style={{ width: "100%" }}>
                    <Col xs={12} xl={4} className={`text-center`}>
                      <Card className="mr-4 p-4 mb-3">
                        <Metric
                          size={"small"}
                          id={"numActiveProducts"}
                          toolTip={
                            "The number of active listings in your catalog"
                          }
                          name={"Active Listings"}
                          showTarget={false}
                          value={buyboxData?.data?.data.active}
                          formatter={formatNumber}
                        />
                      </Card>
                    </Col>
                    <Col xs={12} xl={4} className={`text-center`}>
                      <Card className="mr-4 p-4 mb-3">
                        <Metric
                          size={"small"}
                          id={"numLostBuyBox"}
                          toolTip={
                            "The number of listings thave lost the buybox"
                          }
                          name={"Lost BuyBox"}
                          showTarget={false}
                          value={productData?.data?.products.length}
                          formatter={formatNumber}
                        />
                      </Card>
                    </Col>
                    <Col xs={12} xl={4} className={`text-center`}>
                      <Card className="mr-4 p-4 mb-3">
                        <Metric
                          size={"small"}
                          id={"buyboxPercentage"}
                          toolTip={"Your buy box percentage"}
                          name={"Buy Box Percentage"}
                          showTarget={false}
                          value={
                            buyboxData?.data?.data.count > 0
                              ? buyboxData?.data?.data.buy_box /
                                buyboxData?.data?.data.count
                              : 0
                          }
                          formatter={formatPercent}
                        />
                      </Card>
                    </Col>
                  </Row>

                  <ProductsTable
                    onBulkChange={async (changes, clearChanges) => {
                      const updates = {};
                      Object.entries(changes).forEach(([id, changes]) => {
                        updates[id] = Object.entries(changes)
                          .filter(([key]) =>
                            [
                              "max_price",
                              "min_price",
                              "shipping_cost",
                              "landed_cost",
                            ].includes(key)
                          )
                          .map(([key, value]) => [key, parseFloat(value)]);
                      });
                      setInvalidateProductsTable([clearChanges]);
                      updateProducts.mutate(updates);
                    }}
                    initialSelectedProducts={selectedProducts}
                    marketPlace={marketPlace}
                    productData={productData?.data?.products}
                    ExpandedContent={ExpandedContent}
                    isSaving={updateProducts.isLoading}
                    onSelection={onSelection}
                    hideCompareValue={true}
                    bulkActionOptions={[
                      ...[
                        "Fixed Price",
                        "Clear Fixed Price",
                        "Minimum Price",
                        "Maximum Price",
                        "Shipping Cost",
                        "Landed Cost",
                      ].map((label) => ({
                        label:
                          label === "Clear Fixed Price"
                            ? label
                            : `Set ${label}`,
                        action: (selected) => {
                          setSelectedProducts(selected);
                          setValueType(label);
                          setShowSetValue(true);
                        },
                      })),
                    ]}
                  />

                  <SetValue
                    showSetValue={showSetValue}
                    setShowSetValue={setShowSetValue}
                    valueType={valueType}
                    selectedProducts={selectedProducts}
                    productById={productById}
                    marketPlace={marketPlace}
                    setSelectedProducts={setSelectedProducts}
                  />
                </>
              )}
              {/* {!isLoading && (
                <>
                  <Card
                    content={
                      <Row>
                        <Col className={`px-3 pb-5`}>
                          <div className="text-start">
                            <h3 style={{ marginBottom: "10px" }}>
                              BuyBox Health
                            </h3>

                            <Row>
                              <Col className={`text-center`}>
                                <h4 style={{ marginTop: "10px" }}>
                                  # of Products
                                </h4>
                                <span className={`fs-2 fw-bold`}>
                                  {formatNumber(buyboxData?.data?.data.count)}
                                </span>
                              </Col>
                              <Col className={`text-center`}>
                                <h4 style={{ marginTop: "10px" }}>
                                  # of Active Products
                                </h4>
                                <span className={`fs-2 fw-bold`}>
                                  {formatNumber(buyboxData?.data?.data.active)}
                                </span>
                              </Col>
                              <Col className={`text-center`}>
                                <h4 style={{ marginTop: "10px" }}>
                                  BuyBox Percentage
                                </h4>
                                <span className={`fs-2 fw-bold`}>
                                  {buyboxData?.data?.data.count > 0
                                    ? formatPercent(
                                        buyboxData?.data?.data.buy_box /
                                          buyboxData?.data?.data.count
                                      )
                                    : formatPercent(0)}
                                </span>
                              </Col>
                              <Col className={`text-center`}>
                                <h4 style={{ marginTop: "10px" }}>
                                  Lost BuyBox
                                </h4>
                                <span className={`fs-2 fw-bold`}>
                                  {formatNumber(
                                    buyboxData?.data?.data?.not_buy_box
                                  )}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    }
                  />

                  
                </>
              )} */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-end mb-4 mt-4">
              <DownloadReport
                url_path={"product_report"}
                filters={{ ...filters }}
                report_dl={"product"}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
};

const BuyBoxReport = ({
  user,
  marketPlace,
  modules,
  location,
  amz_onboard,
}) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { category = null } = useParams();
  const DYNAMIC_PRICING_SUBSCRIPTION =
    (((user.pricing_tier && user.pricing_tier !== "unknown") ||
      location?.state?.firstVisit ||
      location?.state?.onboardingComplete) &&
      (amz_onboard.org_status === "active" ||
        amz_onboard.org_status === "trial_active" ||
        amz_onboard.org_status === "demo_org")) ||
    checkModule(modules, "PRICE_OPTIMIZATION") ||
    checkTierAccess(user.tier, "pricing", amz_onboard.org_status);

  if (!DYNAMIC_PRICING_SUBSCRIPTION) {
    return (
      <PaymentDiscovery discoveryType={"advertising"} dashboardType="pricing" />
    );
  }

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        category={category}
        modules={modules}
      />
    </DatesProvider>
  );
};

BuyBoxReport.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(BuyBoxReport))
);
