import "assets/css/payment_styles.css";
import axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import { Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getURLPrefix } from "utils/getUrlPrefix";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";

let URL_PREFIX = getURLPrefix();

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subList: "",
      invoiceList: "",
      upcomingInvoice: "",
    };
  }

  componentDidMount() {
    if (
      this.props.amz_onboard.org_status === "trial_active" ||
      this.props.amz_onboard.org_status === "trial_expired" ||
      this.props.amz_onboard.org_status === "pre_trial"
    ) {
      // do nothing
    } else {
      this.getInvoiceList();
    }
  }

  getInvoiceList = () => {
    axios.get(URL_PREFIX + "/api/billing/invoices").then((res) => {
      if (res.data.past_invoices) {
        this.setState({ invoiceList: res.data.past_invoices.data });
      }
      if (res.data.upcoming_invoice) {
        this.setState({ upcomingInvoice: res.data.upcoming_invoice });
      }
    });
  };

  timeFormatter = (unixTime) => {
    var convertedTime = new Date(unixTime * 1000);
    // +1 because getUTCMonth() returns a 0 indexed month
    return (
      convertedTime.getUTCMonth() +
      1 +
      "/" +
      convertedTime.getUTCDate() +
      "/" +
      convertedTime.getUTCFullYear()
    );
  };

  subscriptionFormatter = (invoice) => {
    for (var i = 0; i < invoice.lines.data.length; i++) {
      if (invoice.lines.data[i].description !== null) {
        if (invoice.lines.data[i].description.includes("ETP")) {
          return "Essentials";
        } else if (invoice.lines.data[i].description.includes("PRO")) {
          return "Premium";
        } else if (invoice.lines.data[i].description.includes("BUS")) {
          return "Starter";
        } else if (invoice.lines.data[i].description.includes("ENT")) {
          return "Enterprise";
        } else if (invoice.lines.data[i].description.includes("COR")) {
          return "Custom";
        }
      }
    }

    var stripeDescription = invoice.lines.data[0];
    if (stripeDescription.description !== null) {
      if (stripeDescription.description.includes("ETP")) {
        return "Entrepreneur";
      } else if (stripeDescription.description.includes("PRO")) {
        return "Professional";
      } else if (stripeDescription.description.includes("BUS")) {
        return "Business";
      } else if (stripeDescription.description.includes("ENT")) {
        return "Enterprise";
      }
    }
  };

  lineItemFormatter = (invoice) => {
    for (var i = 0; i < invoice.lines.data.length; i++) {
      if (invoice.lines.data[i].plan !== null) {
        return invoice.lines.data[i].plan.interval.concat("ly");
      }
    }
  };

  render() {
    return (
      <div
        className="card pb-5"
        style={{ paddingBottom: "7px", marginRight: "2rem" }}
      >
        <Row
          className="text-center"
          style={{
            marginBottom: "1rem",
            marginLeft: "0px",
            marginRight: "0px",
          }}
        >
          <p style={{ marginTop: "2rem", fontSize: "20px", fontWeight: 600 }}>
            Invoice
          </p>
        </Row>
        <Row
          className="justify-content-center px-4"
          style={{ height: "225px", overflowY: "auto" }}
        >
          <div className="invoice-table panel">
            <Table responsive>
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ textTransform: "none" }}>
                    Invoice Date
                  </th>
                  <th className="text-center" style={{ textTransform: "none" }}>
                    Subscription
                  </th>
                  <th className="text-center" style={{ textTransform: "none" }}>
                    Renewal
                  </th>
                  <th className="text-center" style={{ textTransform: "none" }}>
                    Total
                  </th>
                  <th className="text-center" style={{ textTransform: "none" }}>
                    View Invoice
                  </th>
                </tr>
              </thead>
              <tbody style={{ overflow: "auto" }}>
                {this.state.upcomingInvoice !== "" ? (
                  <tr>
                    <td className="text-center">
                      {this.timeFormatter(
                        this.state.upcomingInvoice.period_end
                      )}
                    </td>
                    <td className="text-center">
                      {this.subscriptionFormatter(this.state.upcomingInvoice)}
                    </td>
                    <td
                      className="text-center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.lineItemFormatter(this.state.upcomingInvoice)}
                    </td>
                    <td className="text-center" style={{ whiteSpace: "pre" }}>
                      ${this.state.upcomingInvoice.total / 100} to be paid on{" "}
                      {"\n"}
                      {this.timeFormatter(
                        this.state.upcomingInvoice.period_end
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}> Upcoming</td>
                  </tr>
                ) : null}
                {this.state.invoiceList !== ""
                  ? this.state.invoiceList.map((invoiceItem, index) => {
                      return (
                        <tr key={invoiceItem.id}>
                          <td className="text-center">
                            {this.timeFormatter(invoiceItem.period_start)}
                          </td>
                          <td className="text-center">
                            {this.subscriptionFormatter(invoiceItem)}
                          </td>
                          <td
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {this.lineItemFormatter(invoiceItem)}
                          </td>
                          <td
                            className="text-center"
                            style={{ whiteSpace: "pre" }}
                          >
                            ${invoiceItem.total / 100} to be paid on {"\n"}
                            {this.timeFormatter(invoiceItem.period_end)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <u>
                              {" "}
                              <a href={invoiceItem.invoice_pdf}>
                                {" "}
                                View Full Invoice
                              </a>
                            </u>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Row>
      </div>
    );
  }
}

Invoice.propTypes = {
  auth: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
  user: state.user,
  amz_onboard: state.amz_onboard,
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Invoice));
