import React, { useState, useEffect } from "react";
import { getHashValue } from "utils/getQueryValue";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { trellisPalette } from "components/custom/analytics/palettes";
import ShelfSelection from "./SheflSelection";
import { Link } from "react-router-dom";
import api from "utils/api";

import logo from "assets/images/logo/Trellis_Logomark_color.png";

const SearchBox = ({ searchProduct, user }) => {
  let hashValue = getHashValue("q", null);

  const [searchTerm, setSearchTerm] = useState(
    hashValue
      ? hashValue.toLocaleLowerCase().replace(new RegExp("%20", "g"), " ")
      : null
  );
  const [showShelfSelection, setShowShelfSelection] = useState(false);
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const history = useHistory();

  const fetchRelatedKeywords = async () => {
    const res = await api.get("/api/explorer/related_terms", {});
    setRelatedKeywords(res.data);
  };

  useEffect(() => {
    if (relatedKeywords.length === 0) {
      fetchRelatedKeywords();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProduct]);

  return (
    <>
      {searchTerm === null && (
        <Row>
          <Col xs={12} className="pt-5 pb-5">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="pe-3">
                <img src={logo} alt="Trellis Logo" width="30" height="auto" />
              </div>
              <div>
                <span className="fs-1 fw-bold">Keyword Explorer</span>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {user.allowMwsOrders && (
        <>
          <Row>
            <Col xs={4}></Col>
            <Col xs={4}>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{
                    borderTopLeftRadius: "45px",
                    borderBottomLeftRadius: "45px",
                  }}
                >
                  <i className="fa fa-search" />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Enter Your Search Term"
                  aria-label="Search Term"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      setSearchTerm(e.target.value);
                      history.push({ path: null, hash: `q=${e.target.value}` });
                      searchProduct(e.target.value);
                    }
                  }}
                  defaultValue={searchTerm}
                />
              </InputGroup>
              {searchTerm && (
                <Row>
                  <Col xs={12} className="text-end">
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <button
                        style={{
                          color: trellisPalette[0],
                          fontWeight: 600,
                          border: 0,
                          background: "rgba(0,0,0,0)",
                        }}
                        onClick={() => {
                          setShowShelfSelection(true);
                        }}
                      >
                        Add to Search Analytics
                      </button>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={4}></Col>
          </Row>

          <Row>
            <Col xs={3}></Col>
            <Col xs={6}>
              {!searchTerm && relatedKeywords.length > 0 && (
                <Row>
                  <Col xs={12} className="text-center pt-3 fs-5">
                    <div>
                      <div style={{ color: "gray" }} className="pb-3">
                        Keywords Ideas:
                      </div>
                      <Row>
                        {relatedKeywords.slice(0, 21).map((keyword, index) => (
                          <Col
                            xs={4}
                            key={index}
                            className="ps-2 pe-2 pt-1 pb-1"
                          >
                            <button
                              style={{
                                color: trellisPalette[0],
                                fontWeight: 600,
                                border: 0,
                                background: "rgba(0,0,0,0)",
                              }}
                              onClick={() => {
                                setSearchTerm(keyword);
                                history.push({
                                  path: null,
                                  hash: `q=${keyword}`,
                                });
                                searchProduct(keyword);
                              }}
                            >
                              {keyword}
                            </button>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={3}></Col>
          </Row>
        </>
      )}

      {!user.allowMwsOrders && (
        <div className="fs-4 text-center">
          In order to use this feature, you must grant&nbsp;
          <Link to="/user/amzsummary">authorization</Link>
          &nbsp;to the SP API.
        </div>
      )}

      <ShelfSelection
        keyword={searchTerm}
        showShelfSelection={showShelfSelection}
        setShowShelfSelection={setShowShelfSelection}
      />
    </>
  );
};

export default SearchBox;
