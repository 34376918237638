import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FormGroupTile from "components/core/basic/FormGroupTile";
import Checkbox from "components/core/basic/CheckBox";
import FormGroupLabel from "components/core/basic/FormGroupLabel";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import "assets/css/form-inputs.css";
import { useSelector } from "react-redux";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";

const BulkAdPlanActionForm = ({
  formData,
  setFormData,
  actionTarget,
  hidePercentOption,
  hideCurrencyOption,
  hideActionTypeOptions,
  forcePercentOption,
  schedulingDialogue,
  renderDateFields,
  disabledFields = [],
}) => {
  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const state = useSelector((state) => state);

  const {
    marketPlace: { marketPlace },
  } = state;

  useEffect(() => {
    if (forcePercentOption) {
      setFormData({ ...formData, actionUnit: "percent" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcePercentOption]);

  const actionTypeOptions = [
    { label: `Specify ${actionTarget}`, value: "specify" },
    { label: `Increase ${actionTarget}`, value: "increase" },
    { label: `Decrease ${actionTarget}`, value: "decrease" },
  ];

  const { actionType, actionValue, actionUnit, startDate, endDate } = formData;

  return (
    <Row className="pb-5">
      <form>
        <Row className="d-flex justify-content-center align-items-center">
          <p className="pb-4 fs-4 w-75">
            Please specify changes to your {actionTarget.toLowerCase()}{" "}
            {schedulingDialogue || renderDateFields ? "" : "goal"} using the
            fields below.{" "}
          </p>
          {!hideActionTypeOptions && (
            <Col xs={12} md={6} lg={4} className="px-5">
              <FormGroupTile
                label={"Action Type"}
                type="select"
                multi={false}
                stateName={"actionType"}
                handleChildFormElement={(key, value) => {
                  handleChange(key, value.value);
                }}
                defaultValue={actionTypeOptions.find(
                  (o) => o.value === actionType
                )}
                options={
                  schedulingDialogue
                    ? actionTypeOptions.filter((o) => o.value !== "specify")
                    : actionTypeOptions
                }
                disabled={disabledFields?.includes("actionType")}
              />
            </Col>
          )}
          <Col xs={12} md={6} lg={4} className="px-5">
            <FormGroupTile
              type="formcontrol"
              stateName={"actionValue"}
              formControlType={"number"}
              handleChildFormElement={handleChange}
              label={"Amount"}
              placeholder={"Enter amount"}
              defaultValue={
                actionUnit === "percent"
                  ? isNaN(parseFloat(actionValue))
                    ? null
                    : Math.round(actionValue)
                  : actionValue
              }
              disabled={disabledFields?.includes("actionValue")}
            />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={hideActionTypeOptions ? 4 : 3}
            className="px-5 text-start"
          >
            {!(hideCurrencyOption && hidePercentOption) && (
              <FormGroupLabel label={"Change Unit"} titleCase={true} />
            )}
            <div
              className={`d-flex py-3 align-items-center ${
                hideActionTypeOptions
                  ? "justify-content-start"
                  : "justify-content-between"
              }`}
            >
              {!hideCurrencyOption && !forcePercentOption && (
                <Checkbox
                  inline
                  number={`adPlanBulkActionUnit-currency`}
                  label={
                    MARKETPLACE_CURRENCY_DENOMINATION[marketPlace] ??
                    MARKETPLACE_CURRENCY_DENOMINATION["USA"]
                  }
                  checked={actionUnit === "currency"}
                  disabled={disabledFields?.includes("actionUnit")}
                  onClick={() => {
                    handleChange("actionUnit", "currency");
                  }}
                  containerClasses={hideActionTypeOptions ? "me-5" : ""}
                />
              )}
              {!hidePercentOption && (
                <Checkbox
                  inline
                  number={`adPlanBulkActionUnit-percent`}
                  label="%"
                  checked={actionUnit === "percent"}
                  disabled={
                    forcePercentOption || disabledFields?.includes("actionUnit")
                  }
                  onClick={() => {
                    handleChange("actionUnit", "percent");
                  }}
                />
              )}
            </div>
          </Col>
          {(schedulingDialogue || renderDateFields) && (
            <Row className="d-flex justify-content-center">
              <Col
                xs={12}
                md={6}
                lg={4}
                className="d-flex flex-column align-items-start px-5 mt-5"
              >
                <FormGroupLabel
                  label={`Start Date`}
                  titleCase={true}
                  toolTip={
                    "Changes will occur at the beginning of the specified day."
                  }
                />
                <div className="w-100">
                  <ReactDatePicker
                    selected={startDate ? moment(startDate).toDate() : null}
                    onChange={(d) => {
                      moment(d);
                      handleChange("startDate", moment(d).format("YYYY-MM-DD"));
                    }}
                    popperPlacement="bottom"
                    minDate={moment().toDate()}
                    disabled={disabledFields?.includes("startDate")}
                  />
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={4}
                className="d-flex flex-column align-items-start px-5 mt-5"
              >
                <FormGroupLabel
                  label={`End Date`}
                  titleCase={true}
                  toolTip={
                    "Changes will occur at the end of the specified day."
                  }
                />
                <div className="w-100">
                  <ReactDatePicker
                    selected={endDate ? moment(endDate).toDate() : null}
                    onChange={(d) => {
                      handleChange("endDate", moment(d).format("YYYY-MM-DD"));
                    }}
                    popperPlacement="bottom"
                    minDate={moment(startDate).toDate()}
                  />
                </div>
              </Col>{" "}
              {!hideActionTypeOptions && (
                <Col xs={12} md={6} lg={3} className="px-5 text-start" />
              )}
            </Row>
          )}
        </Row>
      </form>
    </Row>
  );
};

export default BulkAdPlanActionForm;
