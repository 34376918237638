import React, { useState, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useFetch } from "hooks/api";
import api from "utils/api/index";
import { getURLPrefix } from "utils/getUrlPrefix";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import InsightDetails from "./InsightDetails";
import NotificationSystem from "react-notification-system";
import { formatCurrency } from "utils/formatNumber";
import { trellisPalette } from "components/custom/analytics/palettes";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import moment from "moment";
import checkModule from "utils/checkModule";
import { sendToastNotification } from "utils/sendToastNotification";

const InsightsTable = ({
  marketPlace,
  channel,
  categoryId,
  adId,
  modules,
  nestedTable = false,
  targetData,
  keywordData,
}) => {
  const [selectedInsights, setSelectedInsights] = useState([]);

  const queryClient = useQueryClient();

  const tableRef = useRef();
  const notificationRef = useRef();

  let filters = { insights: true, budget_suppression: true };
  let targetId;

  if (categoryId) {
    filters["category"] = categoryId;
    targetId = categoryId;
  }

  if (adId) {
    filters["advertisement"] = adId;
    targetId = adId;
  }

  const { data: adInsightsData, isLoading: isAdInsightsDataLoading } = useFetch(
    ["adInsights", targetId],
    `/api/insights/ads`,
    filters,
    {
      select: (res) => res.data?.results ?? [],
      enabled: !nestedTable && checkModule(modules, "MEDIA_PLAN_INSIGHTS"),
    }
  );

  const updateHandler = useMutation(
    async (body) => {
      return await api.put("/api/gvads/advproduct/", body);
    },
    {
      onSuccess: (data) => {
        sendToastNotification(
          notificationRef,
          "success",
          "Updating Media Plan"
        );

        queryClient.invalidateQueries();
        return data;
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Media Plan"
        );
      },
    }
  );

  const updateAdBudget = (adId, newMonthlyBudget, adDetails) => {
    let updatedDailyBudget = Math.round(newMonthlyBudget / 30);

    let updateData = {
      id: adId,
      state: adDetails?.state,
      daily_budget: updatedDailyBudget,
      custom_api_request: "update_advertisement",
    };

    updateHandler.mutate(updateData);
  };

  let URL_PREFIX = getURLPrefix();

  if (adInsightsData?.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items center p-5">
        <h5 className="fw-bold text-muted">No insights found</h5>
      </div>
    );
  }
  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <BulkActionsTable
        marketPlace={marketPlace}
        isLoading={isAdInsightsDataLoading}
        tableRef={tableRef}
        tableId={"insightsTable"}
        titleCol={{
          id: "insight",
          isStatic: true,
          Header: "Insight",
          accessor: "insight",
          style: { width: "450px" },
          width: 450,
          Cell: ({ value, row }) => {
            const {
              budget_suppression,
              optimal_budget,
              advertisement: { name, id, daily_budget },
            } = row._original ?? {};

            let recommendedDailyBudget = Math.round(optimal_budget / 30);

            const image = `${URL_PREFIX}/api/productimage/?advertisement=${id}`;

            return (
              <>
                <div className="d-flex align-items-center overflow-ellipsis">
                  {adId ? (
                    <div className={"fs-standard overflow-ellipsis"}>
                      <strong>
                        {name} -{" "}
                        {budget_suppression ? "Budget Suppression" : ""}
                      </strong>
                      {daily_budget >= recommendedDailyBudget && (
                        <div>
                          <Badge
                            bsClass="badge"
                            className="bg-trellis-purple m-1 fs-6"
                            pill
                          >
                            Recommendation Applied
                          </Badge>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          flexShrink: 0,
                          width: 50,
                          height: 50,
                          marginRight: "10px",
                          background: `url(${image}) no-repeat center / contain`,
                        }}
                        draggable="false"
                      />
                      <div>
                        <Link to={`/user/advertising/dashboard/ads/${id}`}>
                          <div
                            className={
                              "fs-standard text-underline-hover overflow-ellipsis"
                            }
                          >
                            <strong className={"text-underline-hover"}>
                              {name} -{" "}
                              {budget_suppression ? "Budget Suppression" : ""}
                            </strong>
                          </div>{" "}
                          {daily_budget >= recommendedDailyBudget && (
                            <Badge
                              bsClass="badge"
                              className="bg-trellis-purple m-1 mt-2 fs-6"
                              pill
                            >
                              Recommendation Applied
                            </Badge>
                          )}
                        </Link>{" "}
                      </div>
                    </>
                  )}
                </div>
              </>
            );
          },
          filterMethod: (filter, row) => {
            return;
          },
          Filter: ({ filter, onChange }) => null,
        }}
        columnSpecs={[
          {
            key: "date",
            compareKey: null,
            options: {
              metric: {
                id: "start_date",
                format: "text",
                accessor: (d) => d?.date,
                name: "Since",
              },
              formatter: (v) => moment(v).format("YYYY-MM-DD") ?? "",
              hideFilter: true,
            },
          },
          {
            key: "details",
            compareKey: null,
            options: {
              className: "text-start justify-content-start px-5 h-100",
              cellContainerClasses: "text-start justify-content-start",
              metric: {
                id: "details",
                format: "text",
                accessor: (d) => d,
                name: "Details",
              },
              formatter: (v) => {
                const {
                  upside_sales,
                  optimal_budget,
                  advertisement: { monthly_budget },
                } = v;

                let budgetIncreaseAmount = optimal_budget - monthly_budget;

                return (
                  (
                    <div className="text-start">
                      <span>
                        Potential Upside Sales:{" "}
                        <span
                          style={{
                            color: upside_sales
                              ? trellisPalette[12]
                              : trellisPalette[17],

                            fontWeight: "bold",
                          }}
                        >
                          {formatCurrency(
                            upside_sales,
                            marketPlace,
                            true,
                            true
                          )}
                        </span>
                      </span>
                      <br />
                      {budgetIncreaseAmount > 1 ? (
                        <span>
                          Recommended Budget Upgrade:{" "}
                          <span
                            style={{
                              color: budgetIncreaseAmount
                                ? trellisPalette[12]
                                : trellisPalette[17],

                              fontWeight: "bold",
                            }}
                          >
                            {formatCurrency(
                              budgetIncreaseAmount,
                              marketPlace,
                              true,
                              true
                            )}
                          </span>
                        </span>
                      ) : (
                        <span>
                          Recommended Optimal Budget:{" "}
                          <span
                            style={{
                              color: trellisPalette[12],
                              fontWeight: "bold",
                            }}
                          >
                            {formatCurrency(
                              optimal_budget,
                              marketPlace,
                              true,
                              false
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                  ) ?? ""
                );
              },
              hideFilter: true,
              width: 1000,
            },
          },
        ]}
        data={adInsightsData ?? []}
        bulkActionOptions={[]}
        isExpandable={!nestedTable}
        hideFilterToggleIcon={true}
        isSelectable={false}
        selected={selectedInsights}
        setSelected={setSelectedInsights}
        selectionKey={"id"}
        getSearchCriteria={(row) => {
          const {
            advertisement: { name, id },
          } = row;
          return `${(name, id)}`;
        }}
        tableRowDescription={"Insights"}
        minRows={0}
        ExpandedContent={({ row }) => {
          return (
            <>
              <InsightDetails
                row={row.row}
                marketPlace={marketPlace}
                adId={row.original.advertisement.id}
                channel={channel}
                nestedTable={true}
                keywordData={keywordData}
                targetData={targetData}
                updateAdBudget={updateAdBudget}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default InsightsTable;
