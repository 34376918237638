import React, { useState, useRef, useMemo } from "react";
import { useFetch } from "hooks/api";
import Loading from "components/core/blocks/Loading";
import Accordion from "components/core/basic/Accordion";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import useColumnSelector from "hooks/useColumnSelector";
import ReactTable from "react-table-6";
import { Link } from "react-router-dom";
import moment from "moment";
import useLifecycleFilter from "hooks/useLifecycleFilter";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import useTableSettings from "hooks/useTableSettings";
import FilterWidget from "views/merchandise/tables/FilterWidget";

const TemplatesSection = ({ marketPlace }) => {
  const tableRef = useRef();

  const [searchFilter, setSearchFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const { data, isLoading } = useFetch(
    ["content_templates"],
    `/merchandise/content_templates/`,
    {},
    {
      select: (d) => d.data?.results || {},
    }
  );

  const [columns, columnSelectorProps] = useColumnSelector(() => {
    return [
      {
        id: "name",
        accessor: "name",
        Header: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            Name
          </div>
        ),
        style: { textAlign: "left" },
        grouping: "configuration",
        toolTip: "The name of the template",
        Cell: ({ value, original }) => (
          <Link
            to={{
              pathname: `/user/content/templates/${original.id}`,
            }}
            className="text-decoration-none"
          >
            <strong>{value}</strong>
          </Link>
        ),
        width: 250,
      },
      {
        id: "status",
        Header: "Status",
        accessor: "revision",
        grouping: "configuration",
        toolTip: "The status the template",
        Cell: ({ value }) => (!!value ? "Draft" : "-"),
      },
      {
        id: "created_by",
        Header: "Created by",
        accessor: "created_by",
        format: "text",
        grouping: "configuration",
        toolTip: "The creator of the template",
        Cell: ({ value: { first_name, last_name } }) =>
          `${first_name} ${last_name}`,
      },
      {
        id: "created_on",
        Header: "Created",
        accessor: "created_on",
        grouping: "configuration",
        toolTip: "The date the template was created",
        Cell: ({ value }) => moment(value).format("MMM D 'YY h:mm a") ?? "-",
      },
      {
        id: "updated_on",
        Header: "Updated",
        accessor: "updated_on",
        grouping: "configuration",
        toolTip: "The date the template was last updated",
        Cell: ({ value }) => moment(value).format("MMM D 'YY h:mm a") ?? "-",
      },
    ];
  }, []);

  const TABLE_ID = "productSEOTemplatesTable";
  const {
    customFilters,
    setCustomFilters,
    formatFilterValue,
    applyCustomFilters,
  } = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const {
    savedTableSettings: savedTablePageSize,
    updateTableSettings: setSavedTablePageSize,
  } = useTableSettings(TABLE_ID, "tablePageSize", 5);

  const {
    savedTableSettings: savedTableSortMethod,
    updateTableSettings: setSavedTableSortMethod,
  } = useTableSettings(TABLE_ID, "tableSorting", []);

  const searchFilteredData = useMemo(() => {
    return applyCustomFilters(data || [])
      .filter(({ name }) => {
        if (searchFilter) {
          if (name.toLowerCase().includes(searchFilter.toLowerCase())) {
            return true;
          }
          return false;
        }
        return true;
      })
      .sort((a, b) => b.seo_score_avg - a.seo_score_avg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchFilter, customFilters]);
  const { filteredData: tableData } = useLifecycleFilter(searchFilteredData);

  return (
    <Accordion title="Templates" initialExpanded>
      {isLoading ? (
        <Loading />
      ) : (
        <ReactTable
          ref={tableRef}
          data={tableData}
          defaultPageSize={savedTablePageSize || 10}
          minRows={5}
          className={`pricing-table ${TABLE_ID}`}
          PaginationComponent={ReactTablePagination}
          getPaginationProps={() => ({
            updateSearch: setSearchFilter,
            showFilters: showFilters,
            setShowFilters: setShowFilters,
            clearFilters: () => {
              setSearchFilter("");
            },
            hideFilters: false,
            clearSearchTermFilters: () => setSearchFilter(""),
            searchTerm: searchFilter,
            table: "plan-products",
            style: { marginBottom: "2rem" },
            carouselLayout: false,
            disableSearchFocus: true,
            filterWidget: true,
            hideFilterToggleIcon: true,
            children: (
              <div className="d-flex justify-content-start w-100 h-100 align-items-center px-2">
                <FilterWidget
                  customFilters={customFilters}
                  setCustomFilters={setCustomFilters}
                  formatFilterValue={formatFilterValue}
                  tableRef={tableRef}
                  excludedFilterColumns={["name"]}
                  tableId={TABLE_ID}
                />
              </div>
            ),
          })}
          filterable={showFilters}
          onSortedChange={(newSorted) => {
            setSavedTableSortMethod(newSorted);
          }}
          onPageSizeChange={(pageSize) => {
            setSavedTablePageSize(pageSize);
          }}
          columns={columns}
          NoDataComponent={() => null}
          showPaginationTop
          showPaginationBottom={false}
          columnSelectorProps={columnSelectorProps}
          defaultSorted={
            savedTableSortMethod || [
              {
                id: "updated_on",
                desc: true,
              },
            ]
          }
        />
      )}
    </Accordion>
  );
};

export default TemplatesSection;
