import React, { useState } from "react";
import axios from "axios";
import FileSaver from "file-saver";
import { LogoutButton } from "components/core/basic/Button.jsx";
import getURLPrefix from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

const DownloadReport = ({
  url_path,
  filters,
  report_dl = "",
  endpoint = "/api/data_report/",
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const [buttonText, setButtonText] = useState("Download Report");

  const handleExport = async () => {
    let url = URL_PREFIX + `${endpoint}${url_path}`;

    let params = JSON.parse(JSON.stringify(filters));
    params["xls"] = "true";
    params["report_dl"] = report_dl;

    let queryString = "";
    for (const [key, value] of Object.entries(params)) {
      if (value === null) {
        continue;
      }
      if (queryString.length === 0) {
        queryString += `${key}=${value}`;
      } else {
        queryString += `&${key}=${value}`;
      }
    }
    url = `${url}?${queryString}`;

    setDisableButton(true);
    setButtonText("Downloading...");

    const response = await axios({
      method: "GET",
      url: url,
      responseType: "blob",
    });

    setDisableButton(false);
    setButtonText("Download Report");

    let blob = new Blob([response.data], {
      type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    FileSaver.saveAs(blob, "report.xlsx");
  };

  return (
    <LogoutButton
      nowrap="true"
      style={{ marginTop: "1rem" }}
      fontSize="12px"
      disabled={disableButton}
      title={
        <span>
          <i className="fa fa-file-excel-o" /> {buttonText}
        </span>
      }
      onClick={handleExport}
    />
  );
};

export default DownloadReport;
