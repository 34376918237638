import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import React, { Component } from "react";

class HubspotCalendarModal extends Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        // open={this.state.openCalendarModal}
        // onClose={this.handleCloseCalendarModal}
        aria-labelledby="book-calendar"
        aria-describedby="book-calendar-meeting"
      >
        <DialogContent>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid container alignItems="center" justifyContent="center">
              <iframe
                title="Hubstop Calendar"
                height="575"
                width="380"
                style={{ padding: "2rem 2rem", border: "1px solid #eeeeee" }}
                className="meetings-iframe-container"
                src="https://meetings.hubspot.com/sriteja/discovery-call?embed=true"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              // classes={classes.button}
              color="secondary"
              // color= {classes.palette.secondary.main}
              style={{
                fontSize: "14px",
                borderRadius: "22px",
                width: 200,
                margin: "1em",
              }}
              //   onClick={() => this.handleCloseCalendarModal()}
              onClick={() => this.props.onClose()}
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export default HubspotCalendarModal;
