import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
import moment from "moment";
import SidebarLink from "views/SidebarFragments/SidebarLink";
import { selectModules } from "redux/selectors/modules";
import { ConfirmationContext } from "modules/pricingv2/context";
import { ConfirmationProvider } from "modules/pricingv2/cards/Confirmation";
import { BuyBoxContext } from "modules/pricingv2/context";
import { BuyBoxProvider } from "modules/pricingv2/BuyBoxReport";
import { RiRefreshLine } from "react-icons/ri";
import { FaCcAmazonPay } from "react-icons/fa";
import checkModule from "utils/checkModule";

const PricingSidebar = ({
  small = false,
  user,
  additionalStyles,
  modules,
  marketPlace,
}) => {
  additionalStyles = additionalStyles || { marginBottom: "3rem" };
  const isActive = (routeName) => {
    return document.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  let now = moment();
  let tosDate = moment(user?.tos_acceptance_date?.slice(0, 10));
  let daysSinceSignup = tosDate ? now.diff(tosDate, "days") : 100;

  let endpoint = "/user/_pricing/dashboard";

  return (
    <>
      <SidebarLink
        isActive={
          isActive(endpoint) ||
          isActive("/user/_pricing/category") ||
          isActive("/user/_pricing/plan")
        }
        to={`${endpoint}`}
        icon={<i className="pe-7s-cash" />}
        small={small}
        section={"pricing"}
      >
        Dynamic Pricing{" "}
        {user.tier === "unknown" &&
          user.pricing_tier === "unknown" &&
          daysSinceSignup < 3 && (
            <Badge
              className={`badge badge-${
                isActive(endpoint) ? "secondary" : "primary"
              }`}
              pill
              style={{ marginLeft: "1rem" }}
            >
              NEW
            </Badge>
          )}
      </SidebarLink>
      <SidebarLink
        isActive={isActive(`/user/_pricing/configuration`)}
        to={`/user/_pricing/configuration`}
        icon={<i className="fa fa-cog" />}
        small={small}
        section={"pricing"}
      >
        Configuration
      </SidebarLink>

      {true && (
        <SidebarLink
          isActive={isActive(`/user/_pricing/changes`)}
          to={`/user/_pricing/changes`}
          icon={
            <span className="fs-3">
              <RiRefreshLine style={{ color: "#2e0054" }} />
            </span>
          }
          small={small}
          section={"pricing"}
        >
          Price Logs
          <ConfirmationProvider
            marketPlace={marketPlace}
            date={now}
            count={true}
            limit={100}
          >
            <ConfirmationContext.Consumer>
              {({ confirmations, isLoading }) => {
                return (
                  <Badge
                    className={`badge badge-secondary`}
                    pill
                    style={{ marginLeft: "1rem" }}
                  >
                    {isLoading
                      ? "..."
                      : confirmations.length >= 20
                      ? "20+"
                      : confirmations.length}
                  </Badge>
                );
              }}
            </ConfirmationContext.Consumer>
          </ConfirmationProvider>
        </SidebarLink>
      )}

      {(checkModule(modules, "BUYBOX") || user?.is_staff) && (
        <SidebarLink
          isActive={isActive(`/user/_pricing/buybox`)}
          to={`/user/_pricing/buybox`}
          icon={
            <span className="fs-3">
              <FaCcAmazonPay style={{ color: "#2e0054" }} />
            </span>
          }
          small={small}
          section={"pricing"}
        >
          Buy Box Health
          <BuyBoxProvider marketPlace={marketPlace}>
            <BuyBoxContext.Consumer>
              {({ lostBuyBox, isLoading }) => {
                return (
                  <Badge
                    className={`badge badge-secondary`}
                    pill
                    style={{ marginLeft: "1rem" }}
                  >
                    {isLoading ? "..." : lostBuyBox >= 20 ? "20+" : lostBuyBox}
                  </Badge>
                );
              }}
            </BuyBoxContext.Consumer>
          </BuyBoxProvider>
        </SidebarLink>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    channel: state.channel.currentChannel,
    user: state.user,
    modules: selectModules(state),
    marketPlace: state.marketPlace,
  };
};

export default connect(mapStateToProps)(withRouter(PricingSidebar));
