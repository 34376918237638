import React, { useState } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import api from "utils/api";

const WalmartAdvertisingForm = ({
  onSubmit = () => {},
  errorMessage,
  setErrorMessage,
  onboardingData,
}) => {
  const [advertiserId, setAdvertiserId] = useState("");

  const queryClient = useQueryClient();
  const { amz_onboard, user } = useSelector((state) => state);

  const submitWalmartAdvertiserId = useMutation(
    async (advertiserId) => {
      return await api.post("/walmart/auth/advertising", {
        advertiser_id: advertiserId,
        specified_org_name: user.organization_name,
        standalone: amz_onboard.org_status === "onboarding",
        profile_type: onboardingData.walmartSellerType,
        onboarding: true,
        created_by: parseInt(user.user_id),
        secondary_channel_onboarding:
          localStorage.secondaryChannel === "walmart",
      });
    },
    {
      onSuccess: async (res, variables) => {
        queryClient.invalidateQueries();
        // Below will trigger onError if validation fails in Walmart ads client request
        await api.get("/walmart/auth/advertising/verify");

        setErrorMessage("");
        onSubmit();
      },
      onError: async (res, variables) => {
        setErrorMessage(
          "Invalid advertiser ID. Please verify that Trellis has been registered as an advertising partner and that the provided ID is correct. If you have recently allowed Trellis partner access, note that it may take up to 15 minutes for API access to process."
        );
      },
    }
  );

  const handleFormSubmit = async (e) => {
    submitWalmartAdvertiserId.mutate(parseInt(advertiserId));
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()} noValidate>
      <Row>
        <Col xs={12} className="p-0 px-3 my-4">
          <p className="fs-standard">Please Enter Your Advertiser ID</p>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="advertiserId"
              value={advertiserId}
              placeholder="Advertiser ID"
              className="py-4 px-5"
              onChange={(e) => {
                setAdvertiserId(e.target.value);
                setErrorMessage("");
              }}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <button
          className="adplan_button w-50 mx-auto"
          onClick={(e) => handleFormSubmit(e)}
          disabled={
            !advertiserId || submitWalmartAdvertiserId.isLoading || errorMessage
          }
        >
          Submit
        </button>
      </Row>
    </Form>
  );
};

export default WalmartAdvertisingForm;
