import api from "utils/api";
import React, { useState, useRef, useContext } from "react";
import NotificationSystem from "react-notification-system";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  AD_TYPES,
  ALGORITHM_OPTIONS,
  SB_CAMPAIGN_GOAL_OPTIONS,
  SD_CAMPAIGN_TYPE_OPTIONS,
} from "./constants";
import { useMutation, useQueryClient } from "react-query";
import { AdPlanContext } from "./AdPlanContext";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";

const withAdPlan =
  (Component) =>
  ({ categories, onCreate, ...props }) => {
    const notificationRef = useRef();
    const [errors, setErrors] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const { savedChildPlans, setRefetchOnUpdate } = useContext(AdPlanContext);

    const queryClient = useQueryClient();
    const reduxState = useSelector((state) => state);
    const modules = selectModules(reduxState);

    const toAdPlanData = (adPlan, products) => {
      // Ensure adPlan data present before trying to process in case of new composite component being created
      if (!adPlan) {
        return;
      }

      const formatMatchTypes = () => {
        let matchTypeArray = [];
        if (adPlan.broadMatchEnabled) {
          matchTypeArray.push("broad");
        }

        if (adPlan.phraseMatchEnabled) {
          matchTypeArray.push("phrase");
        }

        if (adPlan.exactMatchEnabled) {
          matchTypeArray.push("exact");
        }

        return matchTypeArray;
      };

      const formatTargetCategories = (targetCategories) => {
        const formattedTargetCategoryData = targetCategories?.map(
          (targetDetails) => {
            if (
              targetDetails.asin_price_greater_than &&
              targetDetails.asin_price_less_than
            ) {
              targetDetails = {
                asin_price_between: `${targetDetails.asin_price_greater_than}-${targetDetails.asin_price_less_than}`,
                ...targetDetails,
              };
              delete targetDetails.asin_price_greater_than;
              delete targetDetails.asin_price_less_than;
            }

            if (
              targetDetails.asin_review_rating_greater_than &&
              targetDetails.asin_review_rating_less_than
            ) {
              targetDetails = {
                asin_review_rating_between: `${targetDetails.asin_review_rating_greater_than}-${targetDetails.asin_review_rating_less_than}`,
                ...targetDetails,
              };
              delete targetDetails.asin_review_rating_greater_than;
              delete targetDetails.asin_review_rating_less_than;
            }

            if (typeof targetDetails.brand === "object") {
              targetDetails.brand_name = targetDetails.brand.label;
              targetDetails.brand = targetDetails.brand.value;
            }

            delete targetDetails.savedToServer;

            return targetDetails;
          }
        );

        return formattedTargetCategoryData;
      };

      const formatNegativeMatchTypes = () => {
        let negativeMatchTypeArray = [];
        if (adPlan.negativePhraseMatchEnabled) {
          negativeMatchTypeArray.push("phrase");
        }

        if (adPlan.negativeExactMatchEnabled) {
          negativeMatchTypeArray.push("exact");
        }

        return negativeMatchTypeArray;
      };

      const formatSDTargetTypes = () => {
        let sdTargetTypesArray = [];

        if (adPlan.sdCampaignType === SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value) {
          if (adPlan.viewsSimilar) {
            sdTargetTypesArray.push("views_similar");
          }
          if (adPlan.viewsExact) {
            sdTargetTypesArray.push("views_exact");
          }
          if (adPlan.purchasesSimilar) {
            sdTargetTypesArray.push("purchases_similar");
          }
          if (adPlan.purchasesExact) {
            sdTargetTypesArray.push("purchases_exact");
          }
        }

        if (
          adPlan.sdCampaignType === SD_CAMPAIGN_TYPE_OPTIONS.CONTEXTUAL.value
        ) {
          if (adPlan.sdDynamicSimilar) {
            sdTargetTypesArray.push("contextual_dynamic");
          }
        }

        return sdTargetTypesArray;
      };

      const data = {
        id: props.adId ?? adPlan.id,
        products: adPlan.product,
        category: adPlan.categoryId,
        name: adPlan.name,
        market_place: props.marketPlace.marketPlace,
        ad_type:
          adPlan.channel === "walmart"
            ? adPlan.adType === AD_TYPES.SPONSORED_PRODUCT.value
              ? AD_TYPES.SPONSORED_PRODUCT_WALMART.value
              : adPlan.adType
            : adPlan.adType,
        targeting_type:
          adPlan.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
            ? adPlan.sdCampaignType
            : adPlan.targetType,
        ad_control_fields: {
          min_bid: Number.parseFloat(adPlan.minBid),
          max_bid: Number.parseFloat(adPlan.maxBid),
          asin_harvesting: adPlan.asinHarvestingEnabled,
          cr_selector: adPlan.crSelectorEnabled,
          auto_negation: adPlan.autoNegationEnabled,
          target_acos_negation_threshold:
            adPlan.maxAcosNegationThreshold / 100 ?? null,
          cumm_threshold: adPlan.cumulativeNegationThreshold / 100 ?? null,
          keyword_harvesting: adPlan.keywordHarvestingEnabled,
          with_auto: adPlan.autoCampaignEnabled,
          broad_match_regular: true,
          broad_match_modifier: true,
          historical_keywords: adPlan.canHaveChildren
            ? true
            : adPlan.trellisTrakEnabled,
          bid_algo: adPlan.algorithm,
          top_of_search: adPlan.topOfSearchBidMultiplier
            ? adPlan.topOfSearchBidMultiplier
            : 0,
          discovery_budget_alloc: adPlan.manualCampaignBudget / 100 ?? 0,
          auto_budget_alloc: adPlan.autoCampaignBudget / 100 ?? 0,
          bid_strategy: adPlan.bidStrategy,
          sales_volume_control_slider: adPlan.salesVolumeControl,
          match_types: formatMatchTypes(),
          negative_match_types: formatNegativeMatchTypes(),
          lookback:
            adPlan.sdCampaignType === SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value
              ? adPlan.lookbackPeriod
              : [],
          tactic_type: adPlan.sdCampaignType,
          cost_type: adPlan.sdBidType,
          targets: formatSDTargetTypes(),

          campaign_placements:
            adPlan.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
              ? null
              : {
                  "Search Carousel": adPlan.searchCarouselCampaignPlacement,
                  "Item Carousel": adPlan.itemCarouselCampaignPlacement,
                  "Item Buybox": adPlan.buyBoxCarouselCampaignPlacement,
                },
          placement_bid_multipliers: {
            "Buy-Box": Number.parseFloat(adPlan.buyBoxPlacementBidMultiplier),
            "Search Ingrid": Number.parseFloat(
              adPlan.searchInGridPlacementBidMultiplier
            ),
            "Stock Up": Number.parseFloat(adPlan.stockUpPlacementBidMultiplier),
            "Home Page": Number.parseFloat(
              adPlan.homePagePlacementBidMultiplier
            ),
          },
          device_bid_multipliers: {
            Desktop: Number.parseFloat(adPlan.desktopPlatformBidMultiplier),
            Mobile: Number.parseFloat(adPlan.mobilePlatformBidMultiplier),
            App: Number.parseFloat(adPlan.appPlatformBidMultiplier),
          },
          suggested_keywords: adPlan.walmartSuggestedKeywordsEnabled,
          harvesting_setting_override: adPlan.manualHarvestingEnabled,
          harvesting_clicks: isNaN(Number.parseFloat(adPlan.harvestingClicks))
            ? null
            : Number.parseFloat(adPlan.harvestingClicks),
          harvesting_conversions: isNaN(
            Number.parseFloat(adPlan.harvestingConversions)
          )
            ? null
            : Number.parseFloat(adPlan.harvestingConversions),
          harvesting_days: isNaN(Number.parseFloat(adPlan.harvestingDays))
            ? null
            : Number.parseFloat(adPlan.harvestingDays),
          harvesting_view_impressions: isNaN(
            Number.parseFloat(adPlan.harvestingViewImpressions)
          )
            ? null
            : Number.parseFloat(adPlan.harvestingViewImpressions),
        },
        notification_settings: {
          max_acos: Number.parseInt(adPlan.notifications?.maxAcos ?? null),
          max_spend: Number.parseFloat(adPlan.notifications?.maxSpend ?? null),
          product_alert: adPlan.notifications?.productAlertEnabled ?? false,
        },

        user_category_id_list: [],
        daily_budget: adPlan.dailyBudget,
        channel: adPlan.channel,
        state: adPlan.advStatus,
        is_composite: adPlan.canHaveChildren,
        attributes: {
          product_types: adPlan.filledProductKeys,
          brands: adPlan.filledProductBrands,
          attributes: adPlan.filteredAttributes,
        },
        non_trellis_enrolled_ad_accept_bids:
          adPlan.nonTrellisEnrolledAdAcceptBids,
        non_trellis_enrolled_ad: adPlan.nonTrellisEnrolledAd,
        non_trellis_enrolled_ad_accept_bids_start_date:
          adPlan.nonTrellisEnrolledAdAcceptBidsStartDate,
        tags: adPlan.tags ?? [],
        google_fields:
          localStorage.channel === "google"
            ? {
                enableLocal: adPlan.googleAdditionalFields.enableLocal,
                inventoryFilter: {
                  brand: adPlan.inventoryFilterBrand,
                },
              }
            : {},
      };
      data.themed_kw_list = adPlan?.filledTags;
      data.themed_asin_list = adPlan?.filledAsins?.map((asin) =>
        asin.toUpperCase()
      );
      data.discover_keywords = adPlan.keywordDiscoveryEnabled;

      if (!adPlan.canHaveChildren) {
        data.negative_kw_list = adPlan.filledNegativeKeywords;
        data.negative_asin_list = adPlan?.filledNegativeAsins?.map((asin) =>
          asin.toUpperCase()
        );

        if (
          [
            AD_TYPES.SPONSORED_PRODUCT.value,
            AD_TYPES.SPONSORED_DISPLAY_V2.value,
            AD_TYPES.SPONSORED_BRAND.value,
            AD_TYPES.SPONSORED_BRAND_V2.value,
          ].includes(adPlan.adType) &&
          adPlan.channel !== "walmart" &&
          !adPlan.canHaveChildren
        ) {
          data.user_category_id_list =
            formatTargetCategories(adPlan.filledTargetCategories) ?? [];
        }

        data.parent = adPlan.parent;
        data.target_acos =
          props.user.preferences.acos_or_roas === "roas"
            ? ((1 / adPlan.targetACOS) * 100).toFixed(2)
            : adPlan.targetACOS;
        data.max_acos =
          props.user.preferences.acos_or_roas === "roas"
            ? ((1 / adPlan.maxACOS) * 100).toFixed(2)
            : adPlan.maxACOS;
        data.ad_goal = adPlan.goal;
      }
      if (adPlan.parent) {
        data.budget_allocation = adPlan.allocation;
      }
      if (adPlan.canHaveChildren || adPlan.parent) {
        data.attributes["competitors"] = adPlan.filledCompetitorBrands;
      }

      if (
        [
          AD_TYPES.SPONSORED_BRAND.value,
          AD_TYPES.SPONSORED_BRAND_WALMART.value,
          AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
        ].includes(adPlan.adType)
      ) {
        data.brand_entity =
          props.user.organization_type !== "vendor"
            ? adPlan.brand?.value
            : adPlan.brand?.value !== null
            ? adPlan.brand?.value
            : null;
        if (adPlan.storeLandingPage) {
          data.products = adPlan.creativeProduct;
          data.landing_page = {
            url: adPlan.page?.value,
          };
        }
        if (adPlan.newLandingPage || adPlan.newProductCollection) {
          data.products = adPlan.landingPageProduct;
          data.landing_page = {
            asins: products
              .filter((p) =>
                adPlan.landingPageProduct.find((id) => id === p.value)
              )
              .map((p) => p.asin),
          };
        }

        if (adPlan.newProductCollection && adPlan.page?.label) {
          data.products = adPlan.creativeProduct;
        }

        if (adPlan.channel === "walmart") {
          if (adPlan.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value) {
            data.creative = {
              caption_asset_id: adPlan.walmartCaptionAssetId,
              asset_id: adPlan.walmartAssetId,
            };
          }
          if (adPlan.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value) {
            data.creative = {
              headline: adPlan.walmartHeadline,
              brand_name: adPlan.walmartBrandName,
              asset_id: adPlan.walmartAssetId,
              click_url: adPlan.walmartClickURL,
            };
          }
        } else {
          data.creative = {
            brandName: adPlan.creativeBrandName
              ? adPlan.creativeBrandName
              : adPlan.brand.label !== null
              ? adPlan.brand.label
              : "Now",
            brandLogoAssetID: adPlan.logo
              ? adPlan.logo
              : adPlan.logoDetails?.asset_id ?? [],
            headline: adPlan.headline,
            asins: products
              .filter((p) =>
                adPlan.creativeProduct?.find((id) => p.value === id)
              )
              .map((p) => p.asin),
            shouldOptimizeAsins: false,
          };
          data.store_name = adPlan.store.label;
          data.page_name = adPlan.page.label;
        }
      }

      // Sponsored Brand Video data formatting for backend
      if (adPlan.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value) {
        // All new ads use SB as the type on the backend despite being video (very confusing? can we not just align them for clarity to both be SBV?)
        if (!data.id || adPlan.sbV2) {
          data.ad_type = AD_TYPES.SPONSORED_BRAND_V2.value;
        }

        data.brand_entity =
          props.user.organization_type !== "vendor"
            ? adPlan.brand?.value
            : adPlan.brand?.value !== null
            ? adPlan.brand?.value
            : null;

        // Core creatives for both video ad types
        data.creative = {
          properties: [
            {
              name: "trellis_brand_ad",
              state: "ENABLED",
              adGroupId: "adGroup1",
              creative: {},
            },
          ],
        };
        data.creative_display_metadata = {};

        // Regular video ad
        if (!adPlan.storeLandingPage) {
          data.creative.properties[0].creative = {
            asins: products
              .filter((p) => adPlan.product.find((id) => p.value === id))
              .map((p) => p.asin),
            videoAssetIds: [adPlan.video],
          };
          data.creative.brand_ad_type = "_SB_V_";
          data.ad_control_fields.tactic_type = "_SB_V_";
        }

        // Video ad with landing page option
        if (adPlan.storeLandingPage) {
          data.products = adPlan.creativeProduct;

          data.creative.properties[0].landingPage = {
            url: adPlan.page?.value,
            pageType: "STORE",
            asins: products
              .filter((p) =>
                adPlan.landingPageProduct.find((id) => p.value === id)
              )
              .map((p) => p.asin),
          };

          data.creative.brand_ad_type = "_SB_BV_";
          data.creative.properties[0].creative = {
            brandName: adPlan.creativeBrandName
              ? adPlan.creativeBrandName
              : adPlan.brand?.label,
            videoAssetIds: [adPlan.video],
            brandLogoAssetID: adPlan.logoDetails?.asset_id,
            headline: adPlan.headline,
            asins: products
              .filter((p) =>
                adPlan.creativeProduct.find((id) => p.value === id)
              )
              .map((p) => p.asin),
          };
          data.ad_control_fields.tactic_type = "_SB_BV_";
          data.store_name = adPlan.store.label;
          data.page_name = adPlan.page.label;
        }
      }

      // Process SB Creatives
      if (adPlan.adType === AD_TYPES.SPONSORED_BRAND.value) {
        if (adPlan.lifestyleImageDetails) {
          data.creative.customImageAssetId =
            adPlan.lifestyleImageDetails?.asset_id;
        }

        if (adPlan.logoDetails) {
          data.creative.brandLogoAssetID = adPlan.logoDetails?.asset_id;
        }

        // Product collection and store spotlight formatting
        if (adPlan.newProductCollection || adPlan.storeSpotlight) {
          data.ad_type = AD_TYPES.SPONSORED_BRAND_V2.value;
          data.creative = {
            properties: [
              {
                name: "trellis_brand_ad",
                state: "ENABLED",
                adGroupId: "adGroup1",
                creative: {
                  brandLogoAssetID: adPlan.logoDetails?.asset_id,

                  headline: adPlan.headline,
                  brandName: adPlan.creativeBrandName
                    ? adPlan.creativeBrandName
                    : adPlan.brand.label !== null
                    ? adPlan.brand.label
                    : "Now",
                },
                landingPage: {
                  pageType: "STORE",
                  url: adPlan.page?.value,
                  asins: products
                    .filter((p) =>
                      adPlan.landingPageProduct.find((id) => id === p.value)
                    )
                    .map((p) => p.asin),
                },
              },
            ],
            brand_ad_type: adPlan.storeSpotlight
              ? "_SB_SS_"
              : adPlan.newProductCollection
              ? "_SB_PC_"
              : "",
          };

          if (adPlan.newProductCollection) {
            data.creative.properties[0].creative.customImageAssetId =
              adPlan.lifestyleImageDetails?.asset_id;
            data.creative.properties[0].creative.asins = products
              .filter((p) =>
                adPlan.creativeProduct.find((id) => p.value === id)
              )
              .map((p) => p.asin);
          }

          if (adPlan.storeSpotlight) {
            data.creative.properties[0].creative.subpages =
              adPlan.storeSpotlightSubpages.map((subpage) => ({
                url: subpage.url,
                asin: subpage.asin,
                pageTitle: subpage.pageTitle,
              }));
            data.products = adPlan.storeSpotlightSubpages
              ?.map((subpage) => subpage.asin)
              ?.map((asin) => products?.find((p) => p.asin === asin)?.value)
              ?.filter((p) => p);
          }
          data.ad_control_fields.tactic_type = adPlan.newProductCollection
            ? "_SB_PC_"
            : adPlan.storeSpotlight
            ? "_SB_SS_"
            : "";
        }
      }

      // Sponsored Brand V2 targeting
      if (data.ad_type === AD_TYPES.SPONSORED_BRAND_V2.value) {
        data.ad_control_fields.bid_optimization =
          adPlan.amazonSBBidOptimization;
        data.ad_control_fields.bid_adjustments_by_shopper_segment = [
          {
            shopperSegment: "NEW_TO_BRAND_PURCHASE",
            percentage: parseInt(adPlan.shopperSegmentBidMultiplier)
              ? parseInt(adPlan.shopperSegmentBidMultiplier)
              : 0,
          },
        ];
        data.ad_control_fields.bid_adjustments_by_placement = [
          {
            placement: "HOME",
            percentage: parseInt(adPlan.homePlacementBidMultiplier)
              ? parseInt(adPlan.homePlacementBidMultiplier)
              : 0,
          },
          {
            placement: "DETAIL_PAGE",
            percentage: parseInt(adPlan.pagePlacementBidMultiplier)
              ? parseInt(adPlan.pagePlacementBidMultiplier)
              : 0,
          },
          {
            placement: "OTHER",
            percentage: parseInt(adPlan.otherPlacementBidMultiplier)
              ? parseInt(adPlan.otherPlacementBidMultiplier)
              : 0,
          },
        ];

        data.ad_control_fields.campaign_goal =
          adPlan.sbCampaignGoal ?? SB_CAMPAIGN_GOAL_OPTIONS.PAGE_VISIT.value;
      }
      // Sponsored Display Creatives
      if (adPlan.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value) {
        // Only include creative data if at least one creatives field filled out
        if (
          adPlan.headline ||
          adPlan.lifestyleImageDetails ||
          adPlan.logoDetails ||
          adPlan.videoDetails
        ) {
          data.creative = {
            adGroupId: "adGroup1",
            creativeType: "IMAGE",
            properties: {},
          };
          data.creative_display_metadata = {
            squareCustomImage: {},
            rectCustomImage: {},
          };
        }

        if (adPlan.headline) {
          data["creative"]["properties"]["headline"] = adPlan.headline;
        }

        if (adPlan.lifestyleImageDetails) {
          data["creative"]["properties"]["rectCustomImage"] = {
            assetId: adPlan.lifestyleImageDetails?.asset_id,
            assetVersion: adPlan.lifestyleImageDetails?.version,
          };
          if (adPlan.sdRectangleImageCroppingCoordinates?.height) {
            data["creative"]["properties"]["rectCustomImage"][
              "croppingCoordinates"
            ] = adPlan.sdRectangleImageCroppingCoordinates;
            data["creative_display_metadata"]["rectCustomImage"][
              "croppedArea"
            ] = adPlan.sdRectangleCroppedArea ?? {};
          }
          data["creative"]["properties"]["squareCustomImage"] = {
            assetId: adPlan.lifestyleImageDetails?.asset_id,
            assetVersion: adPlan.lifestyleImageDetails?.version,
          };
          if (adPlan.sdSquareImageCroppingCoordinates?.height) {
            data["creative"]["properties"]["squareCustomImage"][
              "croppingCoordinates"
            ] = adPlan.sdSquareImageCroppingCoordinates;
            data["creative_display_metadata"]["squareCustomImage"][
              "croppedArea"
            ] = adPlan.sdSquareCroppedArea ?? {};
          }
        }

        if (adPlan.logoDetails) {
          data["creative"]["properties"]["brandLogo"] = {
            assetId: adPlan.logoDetails?.asset_id,
            assetVersion: adPlan.logoDetails?.version,
          };
        }

        if (adPlan.videoDetails) {
          data["creative"]["properties"]["video"] = {
            assetId: adPlan.videoDetails?.asset_id,
            assetVersion: adPlan.videoDetails?.version,
          };
          data["creative"]["creativeType"] = "VIDEO";
        }
      }

      // Process SP placement multipliers
      if (
        data.channel === "AMZ" &&
        [AD_TYPES.SPONSORED_PRODUCT.value].includes(adPlan.adType)
      ) {
        data.ad_control_fields.bid_adjustments_by_placement = [
          {
            placement: "PLACEMENT_TOP",
            percentage: parseInt(adPlan.topOfSearchBidMultiplier)
              ? parseInt(adPlan.topOfSearchBidMultiplier)
              : 0,
          },
          {
            placement: "PLACEMENT_PRODUCT_PAGE",
            percentage: parseInt(adPlan.productPageMultiplier)
              ? parseInt(adPlan.productPageMultiplier)
              : 0,
          },
          {
            placement: "PLACEMENT_REST_OF_SEARCH",
            percentage: parseInt(adPlan.restOfSearchMultiplier)
              ? parseInt(adPlan.restOfSearchMultiplier)
              : 0,
          },
        ];
      }

      if (adPlan.updatedCategoryId && adPlan.id) {
        data.category = parseInt(adPlan.updatedCategoryId);
      }

      // Include campaign names in initial ad creation request only, fallback to default name in case any are left empty
      if (!adPlan.id && data.channel === "AMZ") {
        for (const campaignType in adPlan.campaignNames) {
          // Remove campaign name suffixes for segmented components used to create unique validation keys
          if (adPlan.hasParent) {
            const campaignTypeWithoutSuffix = `${
              campaignType.split("_")[0]
            }_name`;
            data.ad_control_fields[campaignTypeWithoutSuffix] =
              adPlan.campaignNames[campaignType] ?? null;
          } else {
            data.ad_control_fields[campaignType] =
              adPlan.campaignNames[campaignType] ?? null;
          }
        }
      }
      // Handle case where auto added after creation, need auto_name to create campaign mapping in the backend
      if (
        adPlan.id &&
        data.channel === "AMZ" &&
        adPlan.autoCampaignEnabled !== adPlan.autoCampaignEnabledFromServer &&
        adPlan.autoCampaignEnabled
      ) {
        data.ad_control_fields["auto_name"] = null;
      }

      if (
        data.channel === "AMZ" &&
        adPlan.broadMatchEnabled &&
        [
          AD_TYPES.SPONSORED_PRODUCT.value,
          AD_TYPES.SPONSORED_BRAND.value,
          AD_TYPES.SPONSORED_BRAND_V2.value,
          AD_TYPES.SPONSORED_BRAND_VIDEO.value,
        ].includes(adPlan.adType)
      ) {
        data.ad_control_fields.broad_match_regular = adPlan.broadMatchRegular;
        data.ad_control_fields.broad_match_modifier = adPlan.broadMatchModifier;
      }

      if (checkModule(modules, "BIDDING_RULES")) {
        if (adPlan.algorithm === ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value) {
          data.ad_control_fields.program = adPlan.biddingProgram;
        } else {
          data.ad_control_fields.program = null;
        }
      }

      return data;
    };

    const handleUpdate = async (adPlan, products) => {
      if (isProcessing) {
        return;
      }
      const children = savedChildPlans ?? [];
      const childData = children?.map((c) => ({
        id: c.id,
        name: c.name,
        budget_allocation: parseInt(c.allocation),
        target_acos: parseInt(c.targetACOS),
        max_acos: parseInt(c.maxACOS),
      }));
      const data = toAdPlanData(adPlan, products);
      data.custom_api_request = "update_advertisement";
      data.child_data = childData;
      updateHandler.mutate(data);

      const parent = adPlan.id;

      for (const child of children) {
        if (!child.id) {
          const childAdplan = {
            ...child,
            parent,
            categoryId: adPlan.categoryId,
          };
          await handleCreate(childAdplan, false);
        }
      }
    };

    const handleCreate = async (adPlan, products, notify = true) => {
      if (isProcessing) {
        return;
      }
      setErrors({});
      const data = toAdPlanData(adPlan, products);

      let mutateRes = await createHandler.mutateAsync(
        { data, notify },
        {
          onSuccess: async (res) => {
            if (adPlan.canHaveChildren && adPlan.children?.length > 0) {
              const parent = res.data.advertisement_id;

              const parentName = adPlan.name;

              let children = adPlan.children;

              for (let i = 0; i < children.length; i++) {
                const splitName = children[i].name?.split(" - ");
                const childTacticName = splitName[splitName?.length - 1];
                const formattedName = `${parentName} - ${childTacticName}`;

                const childAdplan = {
                  ...children[i],
                  parent,
                  name: formattedName,
                  categoryId: adPlan.categoryId,
                };
                await handleCreate(childAdplan, products, false);
              }
            }
          },
        }
      );
      if (mutateRes.status === 201) {
        queryClient.invalidateQueries();
        return true;
      }
    };

    const clearError = (key) => {
      setErrors({ [key]: "" });
    };

    const createHandler = useMutation(
      async (body) => {
        return await api.post("/api/gvads/advproduct/", body.data);
      },
      {
        onMutate: () => {
          setIsProcessing(true);
        },
        onSuccess: (response, options) => {
          if (options.notify) {
            setSuccess(true);
            notificationRef.current.addNotification({
              title: (
                <span>
                  <span data-notify="icon" className="pe-7s-check" /> Created
                </span>
              ),
              message: (
                <div>
                  {" "}
                  <b>Activating Media Plan</b>{" "}
                </div>
              ),
              level: "success",
              position: "tr",
              dismissible: "button",
            });
            if (onCreate) {
              onCreate();
            }
          }

          setRefetchOnUpdate(true);
          return response;
        },
        onSettled: () => {
          setIsProcessing(false);
        },
        onError: (error) => {
          setIsProcessing(false);
          if (error.response?.status === 401) {
            setErrors({ adPlanError: error.response.data.error });
          }
        },
      }
    );

    const updateHandler = useMutation(
      async (body) => {
        return await api.put("/api/gvads/advproduct/", body);
      },
      {
        onMutate: () => {
          setIsProcessing(true);
        },
        onSuccess: (data) => {
          setSuccess(true);
          queryClient.invalidateQueries();

          notificationRef.current.addNotification({
            title: (
              <span>
                <span data-notify="icon" className="pe-7s-check" /> Updated
              </span>
            ),
            message: (
              <div>
                {" "}
                <b>Updating Media Plan</b>{" "}
              </div>
            ),
            level: "success",
            position: "tr",
            dismissible: "button",
          });

          setRefetchOnUpdate(true);
          return data;
        },
        onSettled: () => {
          setIsProcessing(false);
        },
        onError: () => {
          setIsProcessing(false);
          notificationRef.current.addNotification({
            title: (
              <span>
                <span data-notify="icon" className="pe-7s-attention" /> Error
              </span>
            ),
            message: (
              <div>
                {" "}
                <b>Failed to Update Media Plan</b>{" "}
              </div>
            ),
            level: "warning",
            position: "tr",
            dismissible: "button",
          });
        },
      }
    );

    return (
      <>
        <NotificationSystem ref={notificationRef} />
        <Component
          errors={errors}
          clearError={clearError}
          success={success}
          isProcessing={isProcessing}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          categories={
            categories?.map((cat) => ({
              value: cat.id,
              label: cat.leaf_category_name,
            })) ?? []
          }
          {...props}
        />
      </>
    );
  };

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
  user: state.user,
});

export default (Component) =>
  connect(mapStateToProps)(withRouter(withAdPlan(Component)));
