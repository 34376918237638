import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import getURLPrefix from "utils/getUrlPrefix";
import GVPlainStatsCard from "components/core/blocks/SingleStatsTile";
import ListingStatTiles from "components/core/blocks/ListingStatTiles";
import {
  TOTAL_SALES_TOOLTIP,
  TOTAL_ACOS_TOOLTIP,
  AD_SALES_TOOLTIP,
  AD_SPEND_TOOLTIP,
  ACOS_TOOLTIP,
  CAC_TOOLTIP,
  CV_TOOLTIP,
  REPEAT_BRAND_SALES_TOOLTIP,
  ROAS_TOOLTIP,
  TOTAL_ROAS_TOOLTIP,
} from "utils/toolTips";
import ProductImage from "components/custom/category/listings/ProductImage";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import api from "utils/api";
import { selectModules } from "redux/selectors/modules";
import ChartMiddleware from "../../growth/ChartMiddleware";
import { NavDropdown } from "react-bootstrap";

let URL_PREFIX = getURLPrefix();

const timeSeriesFilter = [
  { id: "1", rollup: "daily", name: "Daily" },
  { id: "1", rollup: "weekly", name: "Weekly" },
  { id: "1", rollup: "monthly", name: "Monthly" },
];

class ProductChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRollupFilter: true,
      perfOptions: {},
      productData: {},
      pInfo: this.props.pInfo,
      isOrganic: this.props.isOrganic,
      categoryId: this.props.categoryId,
      parentInfo: this.props.pInfo,
      rollup: timeSeriesFilter[0],
      selectedRollUp: timeSeriesFilter[0],
      marketPlace: this.props.marketPlace.marketPlace,
      allowMwsOrders: this.props.user.allowMwsOrders,
      productStatData: [],
      chartData: [],
      preferredPerformanceMetric: this.props.user.preferences.acos_or_roas,
      merchPlanningModuleEnabled: this.props.modules.find(
        (module) => module.name === "MERCHANDISE_PLANS"
      ),
      growthModuleEnabled: this.props.modules.find(
        (module) => module.name === "GROWTH"
      ),
      statCardColumns: this.props.modules.find(
        (module) => module.name === "GROWTH"
      )
        ? 3
        : "true",
    };
  }

  componentDidMount() {
    if (this.props.pInfo === undefined) {
      this.getOverallStats();
      this.renderStatData();

      this.getProductsGraphData(this.state.rollup);
    } else {
      if (
        "children" in this.props.pInfo &&
        this.props.pInfo["children"].length > 0
      ) {
        this.setState({ pInfo: this.props.pInfo["children"][0] });
        this.getProductStats(this.props.pInfo["children"][0]["id"]);

        this.getProductGraphData(
          this.props.pInfo["children"][0]["id"],
          this.state.rollup
        );
      } else {
        this.getProductStats(this.props.pInfo["id"]);
        this.getProductGraphData(this.props.pInfo["id"], this.state.rollup);
      }
    }
  }

  componentDidUpdate() {
    if (this.state.parentInfo !== this.props.pInfo) {
      if (this.props.pInfo !== undefined) {
        if (
          "children" in this.props.pInfo &&
          this.props.pInfo["children"].length > 0
        ) {
          this.setState(
            {
              pInfo: this.props.pInfo["children"][0],
              parentInfo: this.props.pInfo,
            },
            () => {
              this.getProductStats(this.props.pInfo["children"][0]["id"]);
              this.getProductGraphData(
                this.props.pInfo["children"][0]["id"],
                this.state.rollup
              );
            }
          );
        } else {
          this.setState({
            pInfo: this.props.pInfo,
            parentInfo: this.props.pInfo,
          });
          this.getProductStats(this.props.pInfo["id"]);
          this.getProductGraphData(this.props.pInfo["id"], this.state.rollup);
        }
      } else {
        this.setState({ pInfo: undefined, parentInfo: undefined });
        this.getOverallStats();
        this.getProductsGraphData(this.state.rollup);
      }
    }
    if (this.state.rollup !== this.state.selectedRollUp) {
      if (this.props.pInfo !== undefined) {
        if ("children" in this.state.pInfo) {
          this.getProductGraphData(
            this.state.pInfo["children"][0]["id"],
            this.state.selectedRollUp
          );
        } else {
          this.getProductGraphData(
            this.state.pInfo["id"],
            this.state.selectedRollUp
          );
        }
      } else {
        this.getProductsGraphData(this.state.selectedRollUp);
      }
      this.setState({ rollup: this.state.selectedRollUp });
    }
    if (this.state.categoryId !== this.props.categoryId) {
      this.setState({ categoryId: this.props.categoryId }, () => {
        this.getOverallStats();
        this.getProductsGraphData(this.state.rollup);
      });
    }
    if (this.state.allowMwsOrders !== this.props.user.allowMwsOrders) {
      this.setState({ allowMwsOrders: this.props.user.allowMwsOrders });
    }
  }

  async getProductStats(id) {
    let url =
      URL_PREFIX +
      "/api/gvads/stats/?marketplace=" +
      this.props.marketPlace.marketPlace +
      "&product=" +
      id +
      "&timeseries=false";
    if (this.props.orgId) {
      url = url + `&org=${this.props.orgId}`;
    }
    axios.get(url).then((res) => {
      if (res.data === "NO STATS - " + this.props.marketPlace.marketPlace) {
        this.setState({ productData: {} }, () => {
          this.renderStatData();
        });
      } else {
        this.setState({ productData: res.data }, () => {
          if (this.state.growthModuleEnabled) {
            this.fetchGrowthData();
          } else {
            this.renderStatData();
          }
        });
      }
    });
  }

  async fetchGrowthData(overview = false) {
    let updatedProductData = null;
    if (overview) {
      const res = await api.get("/order_stats/?dimension=category");

      if (res.data) {
        const latestOrderData = res.data.findLast(
          (d) => d.category.id === this.state.categoryId
        );

        updatedProductData = {
          ...this.state.productData,
          customerValue: latestOrderData?.cv,
          repeatBrandSales: latestOrderData?.total_brand_sales_repeat
            ? latestOrderData.total_brand_sales_repeat
            : 0,
          customerAcquisitionCost: latestOrderData?.cac
            ? latestOrderData.cac
            : 0,
        };

        this.setState(
          {
            productData: updatedProductData,
          },
          () => {
            this.renderStatData();
          }
        );
      }
    } else {
      if (this.state.pInfo.product_category) {
        let res = await api.get(
          `/order_stats/?groupby=day&dimension=product&category=${this.state.pInfo.product_category}&product=${this.state.pInfo.id}`
        );
        if (res.data) {
          const latestOrderData = res.data[res.data.length - 1];
          updatedProductData = {
            ...this.state.productData,
            customerValue: latestOrderData?.cv,
            repeatBrandSales: latestOrderData?.total_brand_sales_repeat
              ? latestOrderData.total_brand_sales_repeat
              : 0,
            customerAcquisitionCost: latestOrderData?.cac
              ? latestOrderData.cac
              : 0,
          };
        }
        this.setState(
          {
            productData: updatedProductData,
          },
          () => {
            this.renderStatData();
          }
        );
      }
    }
  }

  renderStatData() {
    let data = [];

    if (this.state.allowMwsOrders) {
      // MWS Stats
      data.push(
        {
          metric: "Total Sales",
          value: this.state.productData.total_sales,
          format: "currency",
          toolTip: TOTAL_SALES_TOOLTIP,
          toolTipId: "TOTAL_SALES_TOOLTIP",
        },
        {
          metric:
            this.state.preferredPerformanceMetric === "roas"
              ? "Total ROAS"
              : "Total ACOS",
          value:
            this.state.preferredPerformanceMetric === "roas"
              ? +this.state.productData.total_roas?.toFixed(2)
              : this.state.productData.total_acos,
          format:
            this.state.preferredPerformanceMetric === "roas"
              ? "number"
              : "percentage",
          toolTip:
            this.state.preferredPerformanceMetric === "roas"
              ? TOTAL_ROAS_TOOLTIP
              : TOTAL_ACOS_TOOLTIP,
          toolTipId:
            this.state.preferredPerformanceMetric === "roas"
              ? "TOTAL_ROAS_TOOLTIP"
              : "TOTAL_ACOS_TOOLTIP",
        },
        {
          metric: "Ad Sales",
          value: this.state.productData.attributed_sales_7d,
          format: "currency",
          toolTip: AD_SALES_TOOLTIP,
          toolTipId: "PERF_AD_SALES_TOOLTIP",
        },
        {
          metric:
            this.state.preferredPerformanceMetric === "roas" ? "ROAS" : "ACOS",
          value:
            this.state.preferredPerformanceMetric === "roas"
              ? +this.state.productData.attributed_roas_7d?.toFixed(2)
              : this.state.productData.attributed_acos_7d,
          format:
            this.state.preferredPerformanceMetric === "roas"
              ? "number"
              : "percentage",
          toolTip:
            this.state.preferredPerformanceMetric === "roas"
              ? ROAS_TOOLTIP
              : ACOS_TOOLTIP,
          toolTipId:
            this.state.preferredPerformanceMetric === "roas"
              ? "PERF_ROAS_TOOLTIP"
              : "PERF_ACOS_TOOLTIP",
        },
        {
          metric: "Ad Spend",
          value: this.state.productData.cost,
          format: "currency",
          toolTip: AD_SPEND_TOOLTIP,
          toolTipId: "PERF_AD_SPEND_TOOLTIP",
        }
      );
    } else {
      // Non-MWS Stats
      data.push(
        {
          metric:
            this.state.preferredPerformanceMetric === "roas" ? "ROAS" : "ACOS",
          value:
            this.state.preferredPerformanceMetric === "roas"
              ? +this.state.productData.attributed_roas_7d?.toFixed(2)
              : this.state.productData.attributed_acos_7d,
          format:
            this.state.preferredPerformanceMetric === "roas"
              ? "number"
              : "percentage",
          toolTip:
            this.state.preferredPerformanceMetric === "roas"
              ? ROAS_TOOLTIP
              : ACOS_TOOLTIP,
          toolTipId:
            this.state.preferredPerformanceMetric === "roas"
              ? "PERF_ROAS_TOOLTIP"
              : "PERF_ACOS_TOOLTIP",
        },
        {
          metric: "Ad Sales",
          value: this.state.productData.attributed_sales_7d,
          format: "currency",
          toolTip: AD_SALES_TOOLTIP,
          toolTipId: "PERF_AD_SALES_TOOLTIP",
        },
        {
          metric: "Ad Spend",
          value: this.state.productData.cost,
          format: "currency",
          toolTip: AD_SPEND_TOOLTIP,
          toolTipId: "PERF_AD_SPEND_TOOLTIP",
        },
        {
          metric: "Clicks",
          value: this.state.productData.clicks,
          format: "number",
        },
        {
          metric: "Impressions",
          value: this.state.productData.impressions,
          format: "number",
        }
      );
    }

    // Add growth module stats
    if (this.state.growthModuleEnabled) {
      data.push(
        {
          metric: "CAC",
          value: this.state.productData.customerAcquisitionCost
            ? this.state.productData.customerAcquisitionCost
            : 0,
          format: "currency",
          toolTip: CAC_TOOLTIP,
          toolTipId: "SUMMARY_CAC_TOOLTIP",
        },
        {
          metric: "Customer Value",
          value: this.state.productData.customerValue
            ? this.state.productData.customerValue
            : 0,
          format: "currency",
          toolTip: CV_TOOLTIP,
          toolTipId: "SUMMARY_CV_TOOLTIP",
        },
        {
          metric: "Repeat Brand Sales",
          value: this.state.productData.repeatBrandSales
            ? this.state.productData.repeatBrandSales
            : 0,
          format: "currency",
          toolTip: REPEAT_BRAND_SALES_TOOLTIP,
          toolTipId: "SUMMARY_REPEAT_BRAND_SALES_TOOLTIP",
        }
      );
    }

    this.setState({ productStatData: data });
  }

  getProductGraphData(id, rollup) {
    axios
      .get(
        URL_PREFIX +
          "/api/gvads/stats/?marketplace=" +
          this.props.marketPlace.marketPlace +
          "&product=" +
          id +
          "&timeseries=True&rollup=" +
          rollup.rollup +
          "&channel=" +
          this.props.channel.currentChannel
      )
      .then((res) => {
        this.setState({
          chartData: res.data,
        });
      });
  }

  async getOverallStats() {
    axios
      .get(
        URL_PREFIX +
          "/api/gvads/stats/?marketplace=" +
          this.props.marketPlace.marketPlace +
          "&category=" +
          this.state.categoryId +
          "&timeseries=False&channel=" +
          this.props.channel.currentChannel
      )
      .then((res) => {
        this.setState({ productData: res.data }, () => {
          if (this.state.growthModuleEnabled) {
            this.fetchGrowthData(true);
          } else {
            this.renderStatData();
          }
        });
      });
  }

  getProductsGraphData(rollup) {
    axios
      .get(
        URL_PREFIX +
          "/api/gvads/stats/?marketplace=" +
          this.props.marketPlace.marketPlace +
          "&timeseries=True&category=" +
          this.state.categoryId +
          "&rollup=" +
          rollup.rollup +
          "&channel=" +
          this.props.channel.currentChannel
      )
      .then((res) => {
        this.setState({
          chartData: res.data,
        });
      });
  }

  displayDynamicStats(type, text, value, tooltipValue, tooltipId) {
    var formattedValue = null;
    if (type === "currency") {
      if (value !== undefined) {
        formattedValue =
          MARKETPLACE_CURRENCY_DENOMINATION[
            this.props.marketPlace.marketPlace
          ] + parseFloat(value.toFixed(2)).toLocaleString();
      } else {
        formattedValue = "-";
      }
    } else if (type === "percentage") {
      if (value !== undefined) {
        formattedValue =
          parseFloat((value * 100).toFixed(0)).toLocaleString() + "%";
      } else {
        formattedValue = "-";
      }
    } else if (type === "number") {
      if (value !== undefined) {
        formattedValue = value.toLocaleString();
      } else {
        formattedValue = "-";
      }
    }

    return (
      <GVPlainStatsCard
        valueAlign="center"
        statsText={text}
        statsValue={formattedValue}
        tooltipText={tooltipValue}
        id={tooltipId}
      />
    );
  }

  handleDropDownSelection(item) {
    this.setState({ pInfo: item }, () => {
      this.getProductStats(this.state.pInfo["id"], this.state.rollup);
      this.getProductGraphData(this.state.pInfo["id"], this.state.rollup);
      this.renderStatData();
    });
  }

  handleRollUpDropDownSelection(item) {
    this.setState({ selectedRollUp: item });
  }

  chartOptions = {
    xAxis: {
      categories: [],
    },
  };

  getSeries() {
    const customSeries = this.props.series ?? [];

    const amazonMWSeries = [
      "Impressions",
      "Clicks",
      "Ad Sales",
      "Total Sales",
      "Ad Spend",
      this.props.user.preferences.acos_or_roas === "roas" ? "ROAS" : "ACOS",
      this.props.user.preferences.acos_or_roas === "roas"
        ? "Total ROAS"
        : "Total ACOS",
      "BSR",
    ];
    const amazonNoMWSSeries = [
      "Impressions",
      "Clicks",
      "Ad Sales",
      "Ad Spend",
      "BSR",
      this.props.user.preferences.acos_or_roas === "roas" ? "ROAS" : "ACOS",
    ];
    const googleSeries = [
      "Impressions",
      "Clicks",
      "Ad Sales",
      "Ad Spend",
      "Page Views",
      "Add To Cart",
      "Begin Checkout",
    ];
    const organicSeries = ["BSR"];
    const walmartSeries = [
      "Impressions",
      "Clicks",
      "Ad Sales",
      "Ad Spend",
      "BSR",
      this.props.user.preferences.acos_or_roas === "roas" ? "ROAS" : "ACOS",
    ];

    const getAmazonSeries = () => {
      if (this.state.isOrganic) {
        return organicSeries;
      }
      if (this.state.allowMwsOrders) {
        return amazonMWSeries;
      }

      return amazonNoMWSSeries;
    };

    const channelSeries = {
      amazon: getAmazonSeries(),
      google: googleSeries,
      walmart: walmartSeries,
    };

    if (this.props.series) {
      return customSeries;
    }

    return channelSeries[this.props.channel.currentChannel];
  }

  render() {
    return (
      <div>
        {this.props.pInfo !== undefined && "children" in this.props.pInfo ? (
          <Grid
            container
            className="text-center"
            style={{ padding: "1rem 3rem" }}
          >
            <div
              className="fs-standard"
              style={{
                paddingRight: "1rem",
                display: "flex",
                alignContent: "center",
                height: "100%",
                fontWeight: "bold",
              }}
            >
              Select a variant to view it's performance
            </div>
            <NavDropdown
              id="ProductVariantList"
              title={this.state.pInfo.asin}
              style={{ margin: "1rem 0m", fontSize: "16px" }}
            >
              {this.props.pInfo.children.map((item, index) => {
                return (
                  <NavDropdown.Item
                    key={index}
                    id={item.id}
                    onClick={(e) => this.handleDropDownSelection(item)}
                  >
                    <>
                      <ProductImage id={item.id} width="40px" height="40px" />{" "}
                      <span style={{ marginLeft: "1.5rem" }}>{item.asin}</span>
                    </>
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
            <div
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              className="fs-standard"
            >
              {this.state.pInfo["product_title"]}
            </div>
          </Grid>
        ) : null}
        {(this.state.isOrganic || this.props.showStatsTile) && (
          <ListingStatTiles
            layout={{ xs: 6, md: 4, lg: this.state.statCardColumns }}
            data={this.state.productStatData}
            marketPlace={this.props.marketPlace.marketPlace}
          />
        )}
        <Grid container>
          <Grid item xs={12}>
            {this.state.showRollupFilter ? (
              <div
                style={{
                  marginTop: "3rem",
                  marginBottom: "-1rem",
                  padding: "1rem 3rem",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    color: "#73706E",
                    fontSize: "18px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "flex-start",
                    maxHeight: "30px",
                  }}
                >
                  <span
                    style={{
                      paddingRight: "1rem",
                      display: "flex",
                      alignContent: "center",
                      height: "100%",
                    }}
                  >
                    Select a performance period:{" "}
                  </span>
                  <NavDropdown
                    id="performancePeriod"
                    title={this.state.rollup.name}
                    style={{
                      margin: "1rem 0m",
                      color: "#73706E",
                      fontSize: "18px",
                      height: "100%",
                    }}
                  >
                    {timeSeriesFilter.map((item, index) => {
                      return (
                        <NavDropdown.Item
                          key={"performancePeriod" + index}
                          id={item.id}
                          onClick={(e) =>
                            this.handleRollUpDropDownSelection(item)
                          }
                        >
                          {item.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </div>
              </div>
            ) : null}
            <ChartMiddleware
              data={this.state.chartData}
              options={{
                options: this.chartOptions,
                series: this.getSeries() ?? [],
              }}
              rollup={this.state.rollup}
              context={this.props.context}
              selectedProduct={this.state.pInfo?.id ?? null}
              selectedCategory={
                this.state.pInfo
                  ? this.state.pInfo.product_category
                  : this.state.categoryId
              }
              marketPlace={this.props.marketPlace.marketPlace}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ProductChart.propTypes = {
  auth: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
};

ProductChart.defaultProps = {
  showStatsTile: true,
  context: "listing",
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
  channel: state.channel,
});

export default connect(mapStateToProps)(withRouter(ProductChart));
