import React, { Component, Fragment } from "react";
import classnames from "classnames";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FilterListIcon from "@material-ui/icons/FilterList";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  ToolTips,
  TOGGLE_FILTERS_TOOLTIP,
  RESET_FILTERS_TOOLTIP,
  PREVIOUS_PAGE_TOOLTIP,
  NEXT_PAGE_TOOLTIP,
} from "utils/hiddenToolTips";
import SearchIcon from "@material-ui/icons/Search";
import ColumnSelector from "components/core/blocks/ColumnSelector";
import AdvancedColumnSelector from "components/core/blocks/AdvancedColumnSelector";

const defaultButton = (props) => (
  <button type="button" {...props} className="-btn">
    {props.children}
  </button>
);

export default class ReactTablePagination extends Component {
  static defaultProps = {
    PreviousComponent: defaultButton,
    NextComponent: defaultButton,
    renderPageJump: ({
      onChange,
      value,
      onBlur,
      onKeyPress,
      inputType,
      pageJumpText,
    }) => (
      <div className="-pageJump">
        <input
          aria-label={pageJumpText}
          type={inputType}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      </div>
    ),
    renderCurrentPage: (page) => (
      <span className="-currentPage">{page + 1}</span>
    ),
    renderTotalPagesCount: (pages) => (
      <span className="-totalPages">{pages || 1}</span>
    ),
    renderPageSizeOptions: ({
      pageSize,
      pageSizeOptions,
      rowsSelectorText,
      onPageSizeChange,
      rowsText,
    }) => (
      <span className="select-wrap -pageSizeOptions">
        <select
          aria-label={rowsSelectorText}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          value={pageSize}
        >
          {pageSizeOptions.map((option, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={option}>
              {`${option} ${rowsText}`}
            </option>
          ))}
        </select>
      </span>
    ),
  };

  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      page: props.page,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.disableSearchFocus) {
      !this.props.hideSearch && document.querySelector(".searchInput").focus();
    }

    if (
      prevProps.page !== this.props.page ||
      prevState.page !== this.state.page
    ) {
      // this is probably safe because we only update when old/new props/state.page are different
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        page: this.props.page,
      });
    }
    /* when the last page from new props is smaller
     than the current page in the page box,
     the current page needs to be the last page. */
    if (
      this.props.pages !== prevProps.pages &&
      this.props.pages <= this.state.page
    ) {
      this.setState({
        page: this.props.pages - 1,
      });
    }
  }

  getSafePage(page) {
    if (Number.isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePage(page) {
    page = this.getSafePage(page);
    this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault();
    }
    const page = this.state.page;
    this.changePage(page === "" ? this.props.page : page);
  }

  getPageJumpProperties() {
    return {
      onKeyPress: (e) => {
        if (e.which === 13 || e.keyCode === 13) {
          this.applyPage();
        }
      },
      onBlur: this.applyPage,
      value: this.state.page === "" ? "" : this.state.page + 1,
      onChange: (e) => {
        const val = e.target.value;
        const page = val - 1;
        if (val === "") {
          return this.setState({ page: val });
        }
        this.setState({ page: this.getSafePage(page) });
      },
      inputType: this.state.page === "" ? "text" : "number",
      pageJumpText: this.props.pageJumpText,
    };
  }

  resetFilters = (e) => {
    const table = document.querySelector(".ReactTable");
    const filterRow = table?.querySelector(".-filters");
    const dropdownFilters = filterRow?.querySelectorAll("select");
    const inputFilters = filterRow?.querySelectorAll("input[type=text]");

    this.props.updateSearch && this.props.updateSearch("");

    if (this.props.setSortState) {
      this.props.setSortState();
    }

    if (inputFilters) {
      inputFilters.forEach((filter) => (filter.value = ""));
    }
    if (dropdownFilters) {
      dropdownFilters.forEach((filter) => {
        filter.selectedIndex = 0;
        filter.value = "all";
      });
    }
  };

  toggleFilter = (e) => {
    this.props.setShowFilters(!this.props.showFilters);
  };

  selectAll = () => {
    const data = this.props.data;

    if (data.length > 0) {
      const sa = [...this.props.selectedProducts];

      data.forEach((row) => {
        if (!sa.includes(row.id)) {
          sa.push(row.id);
        }
      });

      this.props.setSelectedProducts(sa);
    }
  };

  render() {
    const rowCount = this.props.data.length;

    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      renderCurrentPage,
      renderTotalPagesCount,
      renderPageSizeOptions,
      disableSearchFocus = false,
      data,
    } = this.props;

    return (
      <>
        <div
          className={classnames(className, "-pagination")}
          style={this.props.style}
        >
          <div
            className="filter-controls"
            style={{
              width: this.props.filterControlsWidth
                ? this.props.filterControlsWidth
                : "37.5%",
            }}
          >
            {!this.props.hideFilters && (
              <Fragment>
                {!this.props.hideFilterToggleIcon && (
                  <button
                    className="btn-toggle-filters"
                    style={{
                      background: "#fff",
                      color: "#000",
                      fontSize: "2rem",
                      padding: "1rem",
                      position: "relative",
                    }}
                    onClick={(e) => {
                      this.toggleFilter(e);
                    }}
                  >
                    <ToolTips
                      toolTip={TOGGLE_FILTERS_TOOLTIP}
                      position={"top"}
                    />
                    <FilterListIcon
                      className="icon"
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                    />
                  </button>
                )}
                {!this.props.hideResetFiltersIcon && (
                  <button
                    className="btn-reset-filters "
                    style={{
                      background: "#fff",
                      color: "#000",
                      fontSize: "2rem",
                      padding: "1rem",
                      position: "relative",
                    }}
                    onClick={() => {
                      this.resetFilters();
                      this.props.clearFilters();
                    }}
                  >
                    <ToolTips
                      toolTip={RESET_FILTERS_TOOLTIP}
                      position={"top"}
                    />
                    <RotateLeftIcon
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                    />
                  </button>
                )}
                {this.props.columnSelectorProps && (
                  <div style={{ padding: "1rem 1rem 1rem 0" }}>
                    <AdvancedColumnSelector
                      id="selector"
                      tableId={this.props.tableId}
                      groupMetrics={this.props.groupMetrics}
                      tableEntityType={this.props.tableEntityType}
                      {...this.props.columnSelectorProps}
                      onMetricChange={
                        this.props.columnSelectorProps.onColumnChange
                      }
                    />
                  </div>
                )}
              </Fragment>
            )}

            {!this.props.hideSearch && (
              <>
                <div
                  style={{
                    position: "relative",
                    marginTop: "4px",
                    marginRight: "auto",
                    marginBottom: "auto",
                    marginLeft:
                      this.props.displayControls === false
                        ? "-.5rem"
                        : "inherit",
                  }}
                >
                  <SearchIcon
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "8px",
                      fontSize: "2rem",
                    }}
                  />
                  <input
                    type="text"
                    className="searchInput"
                    style={{
                      textAlign: "left",
                      padding: "10px 10px 10px 35px",
                      width: this.props.searchWidth
                        ? this.props.searchWidth
                        : "400px",
                    }}
                    placeholder="Search..."
                    key={1224}
                    value={this.props.searchTerm}
                    ref={(input) => {
                      if (!disableSearchFocus && input?.value.length > 0) {
                        input.focus();
                      }
                    }}
                    onChange={(e) => {
                      this.props.updateSearch(e.target.value);

                      typeof this.props.setFilterState === "function" &&
                        this.props.setFilterState([
                          { id: "product", value: e.target.value },
                        ]);

                      if (e.target.value.trim().length < 1) {
                        this.props.clearSearchTermFilters();
                      }
                    }}
                  ></input>
                  {this.props.searchTagsInput}
                </div>
                {this.props.children && !this.props.filterWidget && (
                  <div className="w-100 d-flex">{this.props.children}</div>
                )}
              </>
            )}
          </div>
          {this.props.additionalFilters && (
            <div style={{ marginLeft: "auto" }}>
              {this.props.additionalFilters}
            </div>
          )}

          {((this.props.table === "price-optimization-overview" &&
            rowCount > 10) ||
            rowCount > 5) &&
            !this.props.singlePage && (
              <div style={{ position: "relative" }}>
                <div className="pagination-controls">
                  <button
                    style={{
                      background: "#fff",
                      color: "#000",
                      fontSize: "2rem",
                      padding: "1rem",
                      position: "relative",
                    }}
                    className="btn-round"
                    onClick={() => {
                      if (!canPrevious) return;
                      this.changePage(page - 1);
                    }}
                    disabled={!canPrevious || this.state.page < 1}
                  >
                    <ToolTips
                      toolTip={PREVIOUS_PAGE_TOOLTIP}
                      position={"top"}
                    />
                    <NavigateBeforeIcon
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                    />
                  </button>

                  <div className="-center">
                    <span className="-pageInfo">
                      {this.props.pageText} {renderCurrentPage(page)}{" "}
                      {this.props.ofText} {renderTotalPagesCount(pages)}
                    </span>
                    {showPageSizeOptions &&
                      renderPageSizeOptions({
                        pageSize,
                        rowsSelectorText: this.props.rowsSelectorText,
                        pageSizeOptions,
                        onPageSizeChange,
                        rowsText: this.props.rowsText,
                      })}
                  </div>
                  {/* <div className="-next"> */}

                  <button
                    style={{
                      background: "#fff",
                      color: "#000",
                      padding: "1rem",
                      position: "relative",
                    }}
                    className="btn-round"
                    onClick={() => {
                      if (!canNext) return;
                      this.changePage(page + 1);
                    }}
                    disabled={
                      !canNext || this.state.page >= this.props.pages - 1
                    }
                  >
                    {" "}
                    <ToolTips toolTip={NEXT_PAGE_TOOLTIP} position={"top"} />
                    <NavigateNextIcon
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                    />
                  </button>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "45px",
                    left: "50%",
                    WebkitTransform: "translateX(-50%)",
                    transform: "translateX(-50%)",
                  }}
                >
                  <p className="text-center" style={{ fontSize: "12px" }}>
                    {page * pageSize + 1}-
                    {page * pageSize + pageSize > data?.length
                      ? data?.length
                      : page * pageSize + pageSize}{" "}
                    of {data?.length} results
                  </p>
                </div>
              </div>
            )}
        </div>{" "}
        {this.props.additionalFilterRow && (
          <div
            className={classnames(className, "-pagination")}
            style={this.props.style}
          >
            {this.props.additionalFilterRow}
          </div>
        )}
        {this.props.filterWidget && (
          <div className="me-auto filter-widget-container">
            {this.props.children}
          </div>
        )}
      </>
    );
  }
}
