import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";

const CustomTag = ({
  tag,
  onUpdate = () => {},
  onClick = () => {},
  useConfirmationDialogue,
  actionType = "remove",
  showActionIcon = false,
  taggedItemDescription = "item",
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Badge
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="badge bg-trellis-mauve d-flex"
      style={{
        marginRight: "1rem",
        cursor: "pointer",
      }}
      pill
      onClick={onClick}
    >
      {tag}{" "}
      {useConfirmationDialogue ? (
        <ConfirmationModal
          title={`Confirm Tag Update`}
          confirmButtonText={`Confirm`}
          confirmationMessage={
            <>
              <p className="fs-3 w-75 mx-auto">
                Are you sure you want to remove this tag from this{" "}
                {taggedItemDescription}?
              </p>
            </>
          }
          onConfirm={() => onUpdate(tag)}
          buttonStyle={`logout_button`}
          modelButtonStyle={{ margin: 0 }}
          inlineModal={true}
          modalButtonText={
            <i
              className={`fa ${
                actionType === "add" ? "fa-plus" : "fa-close"
              } ps-1`}
              style={{ width: "10px", display: isHovered ? "inline" : "none" }}
            />
          }
        />
      ) : (
        <i
          onClick={() => onUpdate(tag)}
          className={`fa ${actionType === "add" ? "fa-plus" : "fa-close"} ps-1`}
          style={{
            width: "10px",
            position: "relative",
            display: isHovered || showActionIcon ? "inline" : "none",
          }}
        />
      )}
    </Badge>
  );
};

export default CustomTag;
