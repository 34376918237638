import React, { useMemo } from "react";
import { useMutation } from "react-query";
import api from "utils/api";
import { useLocation, useHistory } from "react-router-dom";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import { useSelector } from "react-redux";

const VerifyEmailModal = () => {
  const { user } = useSelector((state) => state);
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();

  const showEmailVerificationModal = urlParams.get("verify");

  const resendEmailVerificationEmail = useMutation({
    mutationFn: async () => {
      return await api.post("/api/verify_email/", { send: true });
    },
  });

  const verificationSuccessful =
    showEmailVerificationModal === "true" || user.email_verified_on;

  return (
    <>
      {showEmailVerificationModal && (
        <ConfirmationModal
          inlineModal={true}
          title={verificationSuccessful ? "Thank You" : "Sorry"}
          defaultShowState={true}
          confirmationMessage={
            <>
              <div className="fs-standard">
                {verificationSuccessful
                  ? "Your email has been successfully verified."
                  : "Email verification failed. Please click the button below to send a new confirmation link."}
              </div>
            </>
          }
          confirmButtonText={verificationSuccessful ? "Continue" : "Resend"}
          onConfirm={(e) => {
            urlParams.delete("verify");
            history.replace({
              search: urlParams.toString(),
            });
            if (verificationSuccessful) {
              return;
            }

            resendEmailVerificationEmail.mutate();
          }}
          onCancel={() => {
            urlParams.delete("verify");
            history.replace({
              search: urlParams.toString(),
            });
          }}
          cancelButtonStyles={{
            display: verificationSuccessful ? "none" : "inherit",
          }}
        />
      )}
    </>
  );
};

export default VerifyEmailModal;
