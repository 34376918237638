import React from "react";

export const sendToastNotification = (
  notificationRef,
  type,
  message,
  options
) => {
  let icons = {
    success: "pe-7s-check",
    warning: "pe-7s-attention",
  };

  let notificationTitles = {
    success: "Updated",
    warning: "Error",
  };

  if (notificationRef?.current) {
    notificationRef.current.addNotification({
      title: (
        <span>
          <span data-notify="icon" className={icons[type]} />{" "}
          {options?.title ?? notificationTitles[type]}
        </span>
      ),
      message: (
        <div>
          <b>{message}</b>
        </div>
      ),
      level: type,
      position: "tr",
      dismissible: "button",
    });
  }
};
