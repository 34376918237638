import React from "react";
import { useMutation } from "react-query";
import api from "utils/api";
import { useEffect } from "react";
import { useState } from "react";

const ChromeAccessToken = () => {
  const [token, setToken] = useState();
  const createToken = useMutation({
    mutationFn: async () => {
      return await api.post("/api/chrometoken/");
    },
    onSuccess: (res) => {
      setToken(res.data);
    },
  });

  useEffect(() => {
    createToken.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span id="chromeCode" style={{ display: "none" }}>
      {token?.token}
    </span>
  );
};

export default ChromeAccessToken;
