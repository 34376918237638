import React, { useMemo } from "react";
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  combineYoYData,
  getTrendColor,
} from "./utils";
import acosOrRoas from "utils/acosOrRoas";
import { Row, Col, Badge } from "react-bootstrap";
import Table from "./Table";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { formatNumber, formatPercent } from "utils/formatNumber";
import StatTile from "./StatTile";

const ProductTrends = ({
  orgName = "",
  data,
  group,
  roasPreference = false,
  marketPlace,
}) => {
  const { productData, productYoYData } = data;

  const {
    productCount,
    productsWithBuyBoxCount,
    productsOutOfStockCount,
    productsLowStockCount,
    bestsellerProductCount,
    advertisedProductCount,
  } = useMemo(() => {
    return productData?.products?.reduce(
      (acc, product) => {
        acc.productCount++;
        if (product.buy_box) {
          acc.productsWithBuyBoxCount++;
        }
        if (product.stock_status === "OUT_OF_STOCK") {
          acc.productsOutOfStockCount++;
        }
        if (product.stock_status === "LOW_STOCK") {
          acc.productsLowStockCount++;
        }
        if (product.sales_state === "BEST_SELLER") {
          acc.bestsellerProductCount++;
        }
        if (product.active_ad_count) {
          acc.advertisedProductCount++;
        }

        return acc;
      },
      {
        productCount: 0,
        productsWithBuyBoxCount: 0,
        productsOutOfStockCount: 0,
        productsLowStockCount: 0,
        bestsellerProductCount: 0,
        advertisedProductCount: 0,
      }
    );
  }, [productData]);

  const productsSortedByAdSalesDelta = useMemo(() => {
    if (productData?.products) {
      return (
        productData?.products
          ?.map((productData, index) => {
            return combineYoYData(
              productData,
              productYoYData?.data?.products?.[index]
            );
          })
          ?.filter((ad) => ad.total_sales_delta)
          ?.sort((a, b) => b.total_sales_delta - a.total_sales_delta) ??
        [] ??
        []
      );
    }
    return [];
  }, [productData, productYoYData]);

  const topTrendingProducts = useMemo(() => {
    return (
      productsSortedByAdSalesDelta
        ?.filter((product) => product.total_sales_delta > 0)
        ?.slice(0, 6) ?? []
    );
  }, [productsSortedByAdSalesDelta]);

  const downTrendingProducts = useMemo(() => {
    return (
      productsSortedByAdSalesDelta
        ?.filter((product) => product.total_sales_delta < 0)
        ?.slice(-6)
        ?.reverse() ?? []
    );
  }, [productsSortedByAdSalesDelta]);

  const productsTableColumns = useMemo(
    () => [
      {
        accessor: "product_title",
        Header: "Product",
        customCell: (product) => {
          const { product_title } = product;

          return (
            <div>
              <span className={"w-100 truncate"}>{product_title}</span>
            </div>
          );
        },
        customCompareCell: (product) => {
          const { asin, sku, sales_state, inventory_state } = product;

          return (
            <div className="d-block ps-2 pb-1">
              <span className="fs-6">
                {asin} ({sku})
                {!["NORMAL"].includes(sales_state) && (
                  <Badge className="badge bg-trellis-purple ms-3 fs-6" pill>
                    {snakeCaseToSentenceCase(sales_state)}
                  </Badge>
                )}
                {!["IN_STOCK"].includes(inventory_state) && (
                  <Badge className="badge bg-trellis-purple ms-3 fs-6" pill>
                    {snakeCaseToSentenceCase(inventory_state)}
                  </Badge>
                )}
              </span>
            </div>
          );
        },
      },
      {
        accessor: "sales",
        Header: "Sales",
        compareMetricId: "total_sales_percent",
        yoyCompareMetricId: "total_sales_percent_yoy",
      },
      {
        Header: "Ad Sales",
        accessor: "ad_sales",
        compareMetricId: "ad_sales_percent",
        yoyCompareMetricId: "ad_sales_percent_yoy",
      },
      {
        Header: "BSR",
        accessor: "last_bsr_rank",
        compareMetricId: "last_bsr_rank_delta",
        yoyCompareMetricId: "last_bsr_rank_delta_yoy",
        customCompareCell: (product) => {
          const { last_bsr_rank_delta } = product ?? {};

          return (
            <span
              style={{
                color: getTrendColor(
                  last_bsr_rank_delta,
                  "last_bsr_rank_delta"
                ),
              }}
            >
              {formatNumber(
                last_bsr_rank_delta,
                {
                  addPlus: true,
                },
                0
              )}
            </span>
          );
        },
      },
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "total_acos",

          compareMetricId: "total_acos_delta",
          yoyCompareMetricId: "total_acos_delta_yoy",
          Header: "Total ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "troas",
          compareMetricId: "total_roas_percent",
          yoyCompareMetricId: "total_roas_percent_yoy",
          Header: "Total ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "acos",

          compareMetricId: "acos_delta",
          yoyCompareMetricId: "acos_delta_yoy",
          Header: "ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "roas",
          compareMetricId: "roas_delta",
          yoyCompareMetricId: "roas_delta_yoy",
          Header: "ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
      {
        Header: "Clicks",
        accessor: "clicks",
        compareMetricId: "clicks_percent",
        yoyCompareMetricId: "clicks_percent_yoy",
      },
      {
        Header: "Impressions",
        accessor: "impressions",
        compareMetricId: "impressions_percent",
        yoyCompareMetricId: "impressions_percent_yoy",
      },
      {
        Header: "Page Views",
        accessor: "page_views",
        compareMetricId: "page_views_percent",
        yoyCompareMetricId: "page_views_percent_yoy",
      },
      {
        accessor: "org_conv_rate",
        Header: "Organic CR",
        yoyCompareMetricId: "org_conv_rate_delta_yoy",
        customCompareCell: (product) => {
          const { org_conv_rate_delta } = product ?? {};
          return (
            <span
              style={{
                color: getTrendColor(
                  org_conv_rate_delta,
                  "org_conv_rate_delta"
                ),
              }}
            >
              {formatPercent(org_conv_rate_delta, 2, true)}
            </span>
          );
        },
      },
    ],
    [roasPreference]
  );

  return (
    <div
      className={`box box-common pt-5`}
      style={{
        maxWidth: PAGE_WIDTH,
        maxHeight: PAGE_HEIGHT,
        overflowY: "hidden",
      }}
    >
      <img src={logo} className="watermark" alt="Trellis logo" />
      <Row style={{ paddingTop: "20px" }} className="px-5">
        <Col>
          <StatTile
            label={"Catalogue Size"}
            metricId={null}
            overrideValue={formatNumber(productCount)}
            showPreviousPeriod={false}
            showYoy={false}
          />
        </Col>
        <Col>
          <StatTile
            label={"% of Products Advertised"}
            metricId={null}
            overrideValue={formatPercent(
              advertisedProductCount / productCount,
              2,
              false
            )}
            showPreviousPeriod={false}
            showYoy={false}
          />
        </Col>
        <Col>
          <StatTile
            label={"Bestseller Products"}
            metricId={null}
            overrideValue={formatNumber(bestsellerProductCount)}
            showPreviousPeriod={false}
            showYoy={false}
          />
        </Col>
        <Col>
          <StatTile
            label={"Out of Stock Products"}
            metricId={null}
            overrideValue={formatNumber(productsOutOfStockCount)}
            showPreviousPeriod={false}
            showYoy={false}
          />
        </Col>
        <Col>
          <StatTile
            label={"Low Stock Products"}
            metricId={null}
            overrideValue={formatNumber(productsLowStockCount)}
            showPreviousPeriod={false}
            showYoy={false}
          />
        </Col>
        <Col>
          <StatTile
            label={"Products with Buy Box"}
            metricId={null}
            overrideValue={formatNumber(productsWithBuyBoxCount)}
            showPreviousPeriod={false}
            showYoy={false}
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"Trending products *"}
            titleColWidth={30}
            data={topTrendingProducts}
            columns={productsTableColumns}
            showPrevPeriodTrendRow={true}
            marketPlace={marketPlace}
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"Products with declining sales trends *"}
            titleColWidth={30}
            data={downTrendingProducts}
            columns={productsTableColumns}
            showPrevPeriodTrendRow={true}
            marketPlace={marketPlace}
          />
        </Col>
      </Row>

      <span className="table-annotation">
        * Sorted by greatest change in raw dollar amount of sales descending
      </span>
    </div>
  );
};

export default ProductTrends;
