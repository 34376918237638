import moment from "moment";

// Summary of how moment humanizes time spans => https://stackoverflow.com/questions/53017772/moment-js-parse-date-time-ago
const getTimeDescription = (days) => {
  let timeDescription = moment.duration(days, "days").humanize();

  if (days >= 6 && days <= 9) {
    timeDescription = "week";
  }

  if (days >= 12 && days <= 17) {
    timeDescription = "2 weeks";
  }

  if (timeDescription.startsWith("a ", 0)) {
    timeDescription = timeDescription.split("a ")[1];
  }

  timeDescription = `Every ${timeDescription}`;

  return timeDescription;
};

export default getTimeDescription;
