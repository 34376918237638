import { selectModules } from "./modules";

const DEFAULT_APPS = [
  "Advertising",
  "Merchandising",
  "Pricing",
  "Growth",
  "Admin",
  "Reports",
  "Placement",
  "Promotions",
  "Main",
  "Product",
];

export const selectApplications = (state) => {
  const modules = selectModules(state);
  const searchAnalyticsModule = modules.find(
    (m) => m.name === "SEARCH_ANALYTICS"
  );

  if (searchAnalyticsModule?.settings?.only) {
    return ["Search Analytics"];
  }

  if (searchAnalyticsModule) {
    return [...DEFAULT_APPS, "Search Analytics"];
  }

  return DEFAULT_APPS;
};
