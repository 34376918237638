import Loading from "components/core/blocks/Loading";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import React, { useMemo, useState } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { Card } from "react-bootstrap/esm";
import { getURLPrefix } from "utils/getUrlPrefix";
import Overview from "./cards/Overview";
import { DemandChartsSection } from "./cards/DemandTrend";
import ConfigurationSection from "./cards/Configuration";
import { useSelector } from "react-redux";
import { selectModules } from "redux/selectors/modules";
import PriceHistorySection from "./cards/PriceHistory";
import InlinePrograms from "components/custom/programs/InlinePrograms";
import { PricingRulesNav } from "./utils";
import PlanHeader from "./components/PlanHeader";
import checkModule from "utils/checkModule";
import ConfirmationSection from "./cards/Confirmation";

let URL_PREFIX = getURLPrefix();

const PriceSection = ({
  pricingPlan,
  marketPlace,
  isPlanLoading,
  isLoading,
  data,
}) => {
  const [tab, setTab] = useState("price_history");

  return (
    <Row>
      <Col xs={2} style={{ borderRight: "1px solid #ccc" }}>
        <div className="pt-2"></div>
        <Nav
          variant="underline"
          onSelect={(k) => {
            return setTab(k);
          }}
          activeKey={tab}
          className="flex-column text-start"
        >
          <Nav.Link eventKey="price_history" className="text-start">
            Price History
          </Nav.Link>
          <Nav.Link eventKey="ad_affect" className="text-start">
            Advertising Effectiveness
          </Nav.Link>
          <Nav.Link eventKey="price-changes" className="text-start">
            Price Changes
          </Nav.Link>
        </Nav>
      </Col>
      <Col xs={10}>
        {tab === "price_history" && (
          <>
            <Card>
              <DemandChartsSection
                pricingProduct={pricingPlan}
                marketPlace={marketPlace}
                product={null}
                isPricingProductDataLoading={isPlanLoading}
                data={data}
              />
            </Card>
            <hr />
            <PriceHistorySection
              pricingProduct={pricingPlan}
              marketPlace={marketPlace}
              isPricingProductDataLoading={isPlanLoading}
              isLoading={false}
            />
          </>
        )}
        {tab === "ad_affect" && (
          <PriceHistorySection
            pricingProduct={pricingPlan}
            marketPlace={marketPlace}
            isPricingProductDataLoading={isPlanLoading}
            isLoading={false}
            advertising={true}
          />
        )}

        {tab === "price-changes" && (
          <ConfirmationSection
            pricingPlan={pricingPlan}
            marketPlace={marketPlace}
          />
        )}
      </Col>
    </Row>
  );
};

const PricingPlanCard = ({
  planId,
  marketPlace,
  user,
  initialTab,
  hasVariation,
  hasBorder = true,
}) => {
  const [tab, setTab] = useState(initialTab ?? "overview");
  const { start, end, preStart, preEnd, group } = useDates();

  const state = useSelector((state) => state);
  const modules = selectModules(state);

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "plan_id",
  };

  const { data, isLoading: isOverviewDataLoading } = useFetch(
    [
      "pricingplan_report",
      marketPlace.marketPlace,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      preStart.format("YYYY-MM-DD"),
      preEnd.format("YYYY-MM-DD"),
      planId,
    ],
    `/api/data_report/pricingplan_report?plan_id=${planId}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: !!planId,
    }
  );

  const { data: pricingPlan, isLoading } = useFetch(
    ["pricing_plans", planId],
    `/pricing/pricingplans/${planId}/`,
    {},
    {
      select: (d) => d?.data,
      keepPreviousData: true,
      enabled: !!planId,
    }
  );

  const planInfo = useMemo(() => {
    const info = {
      confidence: null,
      enroll_date: null,
      curve_confidence: null,
      enroll_eligibility: null,
      configured_slider_value: null,
      plan_name: null,
      categoryId: null,
      image: null,
      strategy: null,
      plan_type: null,
    };

    if (pricingPlan) {
      info.confidence = pricingPlan.confidence;
      info.enroll_date = pricingPlan.enroll_date;
      info.curve_confidence = pricingPlan.curve_confidence;
      info.enroll_eligibility = pricingPlan.enroll_eligibility;
      info.configured_slider_value = pricingPlan.configured_slider_value;
      info.plan_name = pricingPlan.plan_name;
      info.categoryId = pricingPlan.category;
      info.image = `${URL_PREFIX}/api/productimage/?pricingplan=${pricingPlan.id}`;
      info.strategy = pricingPlan.strategy;
      info.plan_type = pricingPlan.plan_type;
    }

    return info;
  }, [pricingPlan]);

  return (
    <>
      {isLoading || isOverviewDataLoading ? (
        <Loading height={"300px"} fullPage={false} />
      ) : (
        <Card
          className="fs-standard mb-0"
          style={!hasBorder ? { borderRadius: "none", boxShadow: "none" } : {}}
        >
          <Card.Body>
            <Row>
              <Col xs={12} xl={8}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "120px", paddingRight: "20px" }}>
                    <div
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none",
                        flexShrink: 0,
                        width: 100,
                        height: 100,
                        marginRight: "10px",
                        background: `url(${planInfo.image}) no-repeat center / contain`,
                        borderRadius: 15,
                      }}
                      draggable="false"
                    />
                  </div>
                  <div>
                    <Row className="text-start" style={{ minWidth: "600px" }}>
                      <Col xs={12}>
                        <h3
                          style={{
                            marginBottom: "0.2rem",
                            marginTop: "0",
                            fontSize: 22,
                          }}
                        >
                          {planInfo.plan_name}
                        </h3>
                      </Col>
                      <Col xs={12} className="text-center">
                        {pricingPlan && (
                          <PlanHeader
                            pricingPlan={pricingPlan}
                            user={user}
                            marketPlace={marketPlace}
                          />
                        )}
                      </Col>
                      <Col xs={12}></Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col xs={12} xl={4}>
                <Row>
                  <Col xs={12}></Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mt-5 ph-4 mb-3 border-bottom">
                <Nav
                  variant="underline"
                  onSelect={(k) => {
                    return setTab(k);
                  }}
                  activeKey={tab}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="demand_charts">Demand Charts</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="price_history">Prices</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ad_affect">
                      Advertising Effectiveness
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="configuration">Configuration</Nav.Link>
                  </Nav.Item>
                  <PricingRulesNav
                    pricingPlan={pricingPlan}
                    hasModule={checkModule(modules, "PRICE_DEBUG")}
                  />
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {tab === "overview" && (
                  <Overview
                    pricingProduct={pricingPlan}
                    isPricingProductDataLoading={isOverviewDataLoading}
                    marketPlace={marketPlace.marketPlace}
                    data={data}
                    isLoading={isLoading}
                  />
                )}

                {tab === "configuration" && (
                  <ConfigurationSection
                    pricingPlan={pricingPlan}
                    marketPlace={marketPlace}
                    hasVariation={hasVariation}
                    modules={modules}
                  />
                )}

                {tab === "price_history" && (
                  <>
                    <PriceSection
                      pricingPlan={pricingPlan}
                      marketPlace={marketPlace}
                      isPlanLoading={isOverviewDataLoading}
                      isLoading={false}
                      data={data}
                    />
                    {/* <Card>
                      <DemandChartsSection
                        pricingProduct={pricingPlan}
                        marketPlace={marketPlace}
                        product={null}
                        isPricingProductDataLoading={isOverviewDataLoading}
                        data={data}
                      />
                    </Card>
                    <hr />
                    <PriceHistorySection
                      pricingProduct={pricingPlan}
                      marketPlace={marketPlace}
                      isPricingProductDataLoading={isOverviewDataLoading}
                      isLoading={false}
                    /> */}
                  </>
                )}
                {tab === "ad_affect" && (
                  <PriceHistorySection
                    pricingProduct={pricingPlan}
                    marketPlace={marketPlace}
                    isPricingProductDataLoading={isOverviewDataLoading}
                    isLoading={false}
                    advertising={true}
                  />
                )}

                {tab === "rules_engine" && (
                  <>
                    <InlinePrograms pricingPlan={pricingPlan} />
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default PricingPlanCard;
