import "assets/css/trellis-styles.css";
import axios from "axios";
import { LogoutButton } from "components/core/basic/Button.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import getURLPrefix from "utils/getUrlPrefix";
import TemplateSelect from "components/custom/creatives/TemplateSelect";
import { Player, BigPlayButton } from "video-react";
import { Progress } from "reactstrap";
import Carousel from "components/core/basic/Carousel";
import CenteredModal from "components/core/basic/CenteredModal";
import VideoEditor from "./VideoEditor";
import {
  getAllVideoProjects,
  uploadAsset,
  getAssetSrcUrl,
  VideoEditorProjectState,
} from "utils/assetsUtils";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import "assets/css/video-react.css";
import "assets/css/file-input.css";
import AdPlanModal from "components/core/blocks/AdPlan/AdPlanModal";
import { AD_TYPES } from "components/core/blocks/AdPlan/constants";

let URL_PREFIX = getURLPrefix();

const CONST_MAX_PREVIEW_HEIGHT = 500;
const CONST_MAX_PREVIEW_WIDTH = 800;
const CONST_VIDEO_EDITOR_TITLE = "Video Editor";

class AssetManagement extends Component {
  constructor(props) {
    super(props);
    this.clearProjectState = this.clearProjectState.bind(this);
    this.updateProjectToAssetManagement =
      this.updateProjectToAssetManagement.bind(this);

    this.state = {
      user: this.props.user,
      selectedVideo: {},
      currentVideoUrl: null,
      selectedFile: null,
      video: null,
      loaded: 0,
      videoName: "",
      marketPlace: this.props.marketPlace.marketPlace,
      assets: [],
      selectedAssetIndex: 0,
      selectedItem: null,
      projects: {},
      projectsList: [],
      selectedProjectIndex: 0,
      editProject: {},
      previewTopMargin: "1px",
      newVideoProjectState: VideoEditorProjectState.PROJ_STATE_NONE,
      videoEditorTitle: "",
      videoEditorInst: null,
    };
    this.getProjects();
    this.getAssets();
  }

  clearProjectState() {
    this.setState({
      newVideoProjectState: VideoEditorProjectState.PROJ_STATE_NONE,
    });
  }

  componentDidMount() {
    this.fetchCategories(false, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.marketPlace !== this.props.marketPlace.marketPlace) {
      this.setState(
        {
          allVideos: [],
          currentVideoUrl: null,
          selectedFile: null,
          video: null,
          loaded: 0,
          videoName: "",
          selectedItem: null,
          projects: {},
          projectsList: [],
          selectedProjectIndex: 0,
          marketPlace: this.props.marketPlace.marketPlace,
        },
        () => {
          this.getProjects();
        }
      );
    }
    if (prevState.selectedProjectId !== this.state.selectedProjectId) {
      this.getVideoShortcutStatus();
    }
  }

  setVideoEditor(instance) {
    this.setState({ videoEditorInst: instance });
  }

  setModalShow(value) {
    this.setState({ modalShow: value });
  }

  setTemplateModalShow(value) {
    this.setState({ templateModalShow: value });
  }

  closeModal() {
    this.setState({ uploadErrorMessage: "" });
    this.setState({ modalShow: false });
  }

  closeTemplateModal() {
    this.setState({ templateModalShow: false });
  }

  handleChildFormElement = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  selectedVideo(video_id) {
    if (video_id === this.state.selectedVideo.id) {
      return true;
    } else {
      return false;
    }
  }

  onVideoClick(video, index) {
    this.setState({ selectedVideo: video }, () => {
      this.displayVideo(this.state.selectedVideo.id, index);
    });
  }

  fetchVideoAccess = (id, value) => {
    this.setState({
      [id]: value,
      selectedFile: null,
    });
    axios
      .get(URL_PREFIX + "/creatives/media/video?id=" + value["value"])
      .then((res) => {
        this.setState({
          currentVideoUrl: res.data["url"],
        });
      });
  };

  displayVideo(videoId, index) {
    axios
      .get(URL_PREFIX + "/creatives/media/video?id=" + videoId)
      .then((res) => {
        let item = {
          type: "video",
          src: res.data["url"],
          name: res.data["id"],
          state: res.data["state"],
          status: res.data["status"],
        };
        this.setState({
          selectedItem: item,
          selectedProjectIndex: index,
        });
      });
  }

  fileSelectHandler = (event) => {
    this.setState({ uploadErrorMessage: "" });
    if (event.target.files.length <= 0) {
      return;
    }
    let videoName = event.target.files[0]["name"].substr(
      0,
      event.target.files[0]["name"].lastIndexOf(".")
    );
    if (this.state.projectsList.indexOf(videoName) >= 0) {
      this.setState({
        uploadErrorMessage:
          "Cannot upload video file, duplicate file name already exists.",
      });
      return;
    }

    this.setState({
      selectedFile: event.target.files[0],
      videoName: event.target.files[0]["name"].substr(
        0,
        event.target.files[0]["name"].lastIndexOf(".")
      ),
      video: null,
      currentVideoUrl: null,
      loaded: 0,
    });
  };

  uploadHandler = () => {
    const data = new FormData();
    data.append("name", this.state.videoName);
    data.append("market_place", this.state.marketPlace);
    axios
      .post(URL_PREFIX + "/creatives/media/video?upload=true", data)
      .then((res) => {
        this.getProjects();
        this.setState({
          video: res.data.video_id,
        });
        var instance = axios.create();
        delete instance.defaults.headers.common["Authorization"];
        var file = this.state.selectedFile;
        instance
          .put(res.data.url, file, {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
            },
            headers: {
              "Content-Type": "",
            },
          })
          .then((res) => {
            const beData = { id: this.state.video };
            this.fetchVideoAccess(0, { value: this.state.video });
            axios.put(
              URL_PREFIX + "/creatives/media/video?publish=true",
              beData
            );
            this.setState({ selectedFile: null, modalShow: false });
          });
      });
  };

  getProjects = () => {
    getAllVideoProjects(this.props.marketPlace.marketPlace, (data) => {
      if (data) {
        let projects = {};
        for (let proj of data) {
          if (proj["name"]) {
            projects[proj["name"]] = proj;
          }
          if (proj["project_name"]) {
            projects[proj["project_name"]] = proj;
          }
        }

        var projectsList = Object.keys(projects);

        projectsList.forEach((projectName, index) => {
          const project = projects[projectName];

          if (!project.config) {
            axios
              .get(`${URL_PREFIX}/creatives/media/video?id=${project.id}`)
              .then((res) => {
                let url = res.data.url;
                project.config = { videoSrc: url };
              });
          }
        });

        this.setState({
          projects: projects,
          projectsList: projectsList,
        });

        if (projectsList.length > 0) {
          let item = {
            type: "video",
            src: this.state.projectsList[projectsList[0]],
            //src: projects[projectsList[0]].config.videoSrc,
            name:
              projects[projectsList[0]].project_name !== null
                ? projects[projectsList[0]].project_name
                : projects[projectsList[0]].name,
          };
          this.setState({ selectedItem: item, selectedProjectIndex: 0 });

          // Load first video when component mounts
          let firstProjectId = this.state.projects[this.state.projectsList[0]]
            ? this.state.projects[this.state.projectsList[0]].id
            : "";
          this.setState({ selectedProjectId: firstProjectId });
          this.displayVideo(firstProjectId, 0);
        }
      } else {
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to get projects. If issue continues please contact Trellis."
        );
      }
    });
  };

  getVideoShortcutStatus() {
    if (
      this.state.projects[this.state.selectedItem.name].state === "published" &&
      this.state.projects[this.state.selectedItem.name].status === "Available"
    ) {
      this.setState({ videoAdShortcutDisabled: false });
    } else {
      this.setState({ videoAdShortcutDisabled: true });
    }
  }

  getAssets() {
    axios
      .get(URL_PREFIX + "/creatives/media/assets")
      .then((res) => {
        var clipAssets = res.data.filter(function (el) {
          return el.type === "video";
        });
        var imageAssets = res.data.filter(function (el) {
          return el.type === "image";
        });
        this.setState({
          assets: res.data,
          clipAssets: clipAssets,
          imageAssets: imageAssets,
        });
      })
      .catch((error) => {
        console.log("Get assets failed with error:", error);
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to get assets. If issue continues please contact Trellis."
        );
      });
  }

  fetchCategories(applyProductSearchFilter, onMount = false) {
    axios
      .get(
        URL_PREFIX +
          "/api/categorymanagement/?marketplace=" +
          this.props.marketPlace.marketPlace
      )
      .then((res) => {
        this.setState({ allCategories: res.data });
      })
      .catch((err) => {
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Something went wrong while fetching categories. Please try reloading the page."
        );
      });
  }

  // 0: id
  // 1: name
  // 2: status
  // 3: config
  updateProjectToAssetManagement(project) {
    let projects = this.state.projects;
    projects[project[1]] = {
      id: project[0],
      project_name: project[1],
      state: project[2],
      config: project[3],
    };
    let projectsList = this.state.projectsList;
    let idx = projectsList.indexOf(project[1]);
    if (idx < 0) {
      projectsList.unshift(project[1]);
      idx = 0;
    }
    let item = {
      type: "video",
      src: project[3].videoSrc,
      name: project[1],
    };
    let editProject = { name: project[1], id: project[0], config: project[3] };
    this.setState({
      projects: projects,
      projectsList: projectsList,
      selectedItem: item,
      selectedProjectIndex: idx,
      editProject: editProject,
    });
  }

  editProject(id, projectName, projectDescription, config) {
    this.setState(
      {
        editProject: {
          id: id,
          name: projectName,
          description: projectDescription,
          config: config,
        },
        templateModalShow: false,
      },
      () => {
        this.props.switchToVideoEditor();
        this.setState({
          newVideoProjectState:
            VideoEditorProjectState.PROJ_STATE_PREPARE_EDITING,
        });
      }
    );
  }

  openVideoEditorTab(open) {
    let title = open ? CONST_VIDEO_EDITOR_TITLE : "";
    this.setState({ videoEditorTitle: title });
  }

  renderProjects() {
    return (
      <div>
        <Row style={{ marginBottom: "2em" }}>
          <div className="wrap">
            {
              <LogoutButton
                style={{ float: "left" }}
                fontSize="14px"
                title={
                  <span>
                    <i className="fa fa-file-video-o" />
                    New Project
                  </span>
                }
                onClick={() => {
                  this.setTemplateModalShow(true);
                }}
              />
            }
          </div>
          <div
            className="wrap"
            style={{
              marginTop: "1%",
            }}
          >
            <div
              style={{
                marginTop: "1%",
                fontSize: "14px",
                cursor: "pointer",
                color: "#d4587f",
                textDecoration: "underline",
              }}
              onClick={() => {
                this.setState({ uploadErrorMessage: "" });
                this.setModalShow(true);
              }}
            >
              Upload own video
            </div>
          </div>
          <div>
            <CenteredModal
              show={this.state.templateModalShow}
              closeModal={this.closeTemplateModal.bind(this)}
              dialogClassName="modal-90wc"
              title="Create New Project"
              titleFontSize="20px"
              content={
                <TemplateSelect
                  {...this.props}
                  marketPlace={this.state.marketPlace}
                  projects={this.state.projects}
                  getProjects={() => {
                    return this.state.projects;
                  }}
                  projList={this.state.projList}
                  projectsList={this.state.projectsList}
                  close={() => this.setState({ templateModalShow: false })}
                  editProject={(
                    id,
                    projectName,
                    projectDescription,
                    config
                  ) => {
                    this.editProject(
                      id,
                      projectName,
                      projectDescription,
                      config
                    );
                    this.openVideoEditorTab(true);
                  }}
                />
              }
              onHide={() => {
                this.setState({ uploadErrorMessage: "" });
                this.setTemplateModalShow(false);
              }}
            />
          </div>
          <div>
            <CenteredModal
              show={this.state.modalShow}
              dialogClassName="modal-60w"
              closeModal={this.closeModal.bind(this)}
              title="Add a Video"
              titleFontSize="20px"
              content={
                <Row className="wrap">
                  <Col md={12}>
                    <Row
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px",
                      }}
                    >
                      <Col md={6} style={{ padding: "0px 0px" }}>
                        <input
                          type="file"
                          name="file"
                          accept="video/mp4"
                          onChange={this.fileSelectHandler}
                        />
                      </Col>
                      <Col md={6} style={{ padding: "0px 0px" }}>
                        {this.state.videoName !== "" &&
                        this.state.selectedFile ? (
                          <LogoutButton
                            style={{ float: "right" }}
                            fontSize="14px"
                            title={
                              <span>
                                <i className="fa fa-file-video-o" /> Upload{" "}
                              </span>
                            }
                            onClick={this.uploadHandler}
                          />
                        ) : (
                          <LogoutButton
                            style={{
                              float: "right",
                              opacity: "0.7",
                              pointerEvents: "none",
                            }}
                            fontSize="14px"
                            title={
                              <span>
                                <i className="fa fa-file-video-o" /> Upload{" "}
                              </span>
                            }
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <div
                        style={{
                          marginTop: "2rem",
                          marginLeft: "2rem",
                          marginRight: "2rem",
                        }}
                      >
                        <Progress
                          max="100"
                          color="success"
                          value={this.state.loaded}
                        >
                          {Math.round(this.state.loaded, 2)}%
                        </Progress>
                      </div>
                    </Row>{" "}
                    <Row>
                      <div style={{ color: "red", textAlign: "center" }}>
                        {this.state.uploadErrorMessage}
                      </div>
                    </Row>
                  </Col>
                </Row>
              }
              onHide={() => {
                this.setState({ uploadErrorMessage: "" });
                this.setModalShow(false);
              }}
            />
          </div>
        </Row>
        <Row
          style={{
            margin: "0px",
            border: "1px solid #eeeeee",
            height: "35vh",
            overflowY: "scroll",
            display: "block",
          }}
        >
          {this.state.projectsList.map((projectName, index) => {
            let project = this.state.projects[projectName];

            return (
              <div key={"div_render_project_" + index} className="p-0">
                {project ? (
                  <div
                    key={
                      "div_project_" + project.project_name !== null
                        ? project.project_name
                        : project.name
                    }
                    id={
                      "div_project_" + project.project_name !== null
                        ? project.project_name
                        : project.name
                    }
                    style={{
                      backgroundColor:
                        this.state.selectedProjectIndex === index
                          ? "#eeeeee"
                          : "",
                      border: "1px solid #eee",
                    }}
                  >
                    <Row
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      onClick={(e) => {
                        if (project.project_name !== null) {
                          let item = {
                            type: "video",
                            src: project.config.videoSrc,
                            name: project.project_name,
                            id: project.id,
                          };
                          this.setState({
                            selectedItem: item,
                            selectedProjectIndex: index,
                            selectedProjectId: item.id,
                          });
                        } else {
                          this.onVideoClick(project, index);

                          let item = {
                            type: "video",
                            src: project.config.videoSrc,
                            name: project.name,
                            id: project.id,
                          };
                          this.setState({
                            selectedItem: item,
                            selectedProjectIndex: index,
                            selectedProjectId: project.id,
                          });
                        }
                      }}
                    >
                      <Col
                        md={6}
                        style={{
                          paddingLeft: "2.5rem",
                          paddingTop: "0.75rem",
                          paddingBottom: "0.75rem",
                          cursor: "pointer",
                          fontSize: "15px",
                          color: "#403E3D",
                          fontWeight: "400",
                        }}
                      >
                        <div>
                          {project.project_name !== null
                            ? project.project_name.length > 22
                              ? project.project_name.substring(0, 22) + "..."
                              : project.project_name
                            : project.name.length > 22
                            ? project.name.substring(0, 22) + "..."
                            : project.name}
                        </div>
                      </Col>
                      <Col
                        md={6}
                        style={{
                          paddingTop: "0.75rem",
                          paddingRight: "3rem",
                          paddingBottom: "0.75rem",
                          cursor:
                            project.state === "wip" ? "pointer" : "default",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignContent: "center",
                        }}
                      >
                        <div>
                          {project["status"] === "Processing" ||
                          project["status"] === "PendingDeepValidation" ? (
                            <div
                              style={{
                                float: "right",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#FFBF00",
                              }}
                            >
                              {project.project_name !== null
                                ? project["state"]
                                : project["status"]}
                            </div>
                          ) : project["status"] === "Available" ||
                            (project["state"] === "published" &&
                              project["status"] !== "Failed") ? (
                            <div
                              style={{
                                float: "right",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#25B50E",
                              }}
                            >
                              {project.project_name !== null
                                ? project["state"].charAt(0).toUpperCase() +
                                  project["state"].slice(1)
                                : project["status"]}
                            </div>
                          ) : project["status"] === "Failed" ? (
                            <div
                              style={{
                                float: "right",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#D2222D",
                              }}
                            >
                              {project["status"]}
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {project.state === "wip" &&
                          project.project_name !== null ? (
                            <div
                              style={{
                                marginLeft: "3rem",
                              }}
                              onMouseOver={() => {
                                var el = document.getElementById(
                                  "pencil_edit_" + index
                                );
                                el.style.color = "#d91266";
                                el.style.fontSize = "20px";
                              }}
                              onMouseLeave={() => {
                                var el = document.getElementById(
                                  "pencil_edit_" + index
                                );
                                el.style.color = "black";
                                el.style.fontSize = "16px";
                              }}
                              onClick={() => {
                                if (project.state !== "wip") {
                                  return;
                                }
                                this.openVideoEditorTab(true);
                                this.setState(
                                  {
                                    editProject: {
                                      id: project.id,
                                      name: project.project_name,
                                      description: project.project_description,
                                      config: project.config,
                                    },
                                  },
                                  () => {
                                    this.props.switchToVideoEditor();
                                    this.setState({
                                      newVideoProjectState:
                                        VideoEditorProjectState.PROJ_STATE_PREPARE_EDITING,
                                    });
                                  }
                                );
                              }}
                              className="d-flex align-items-center"
                            >
                              <span
                                id={"pencil_edit_" + index}
                                style={{
                                  color: "black",
                                  fontSize: "16px",
                                  float: "right",
                                }}
                                className="bi bi-pencil-fill"
                                title="Edit"
                              ></span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      {project["status"] === "Failed" && project.message && (
                        <div
                          style={{
                            marginLeft: "2.5rem",
                            marginRight: "10rem",
                          }}
                        >
                          {project["status"] === "Failed" ? (
                            <p
                              style={{
                                marginTop: "1rem",
                                fontSize: "13px",
                                color: "#D2222D",
                                maxWidth: "90%",
                              }}
                            >
                              {project.message}
                            </p>
                          ) : null}
                        </div>
                      )}
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </Row>
      </div>
    );
  }

  renderAssets() {
    return (
      <div>
        <Row className="wrap" style={{ marginBottom: "2em" }}>
          <input
            accept={"video/*,image/*"}
            id={"input-new-asset-file"}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                let file = e.target.files[0];
                let type = file.type.split("/")[0];
                let assetKey = e.target.files[0].name;
                if (
                  this.state.assets.filter((asset) => asset.name === assetKey)
                    .length > 0
                ) {
                  this.props.handleNotification(
                    "tr",
                    "warning",
                    "pe-7s-attention",
                    "Failed to upload file, duplicate file name already exists in your assets library"
                  );
                  return;
                }

                this.setState({ showProgress: true });
                uploadAsset(assetKey, file, type, (assetId) => {
                  if (assetId) {
                    getAssetSrcUrl(assetId, (srcUrl) => {
                      if (srcUrl) {
                        let assets = this.state.assets;
                        assets.push({
                          name: assetKey,
                          type: type,
                          url: srcUrl,
                        });
                        this.setState({
                          showProgress: false,
                          assets: assets,
                        });
                      } else {
                        this.props.handleNotification(
                          "tr",
                          "warning",
                          "pe-7s-attention",
                          "Something went wrong while uploading assets. Please try again."
                        );
                        this.setState({
                          showProgress: false,
                        });
                      }
                    });
                  } else {
                    this.props.handleNotification(
                      "tr",
                      "warning",
                      "pe-7s-attention",
                      "Something went wrong while uploading assets. Please try again."
                    );
                    this.setState({
                      showProgress: false,
                    });
                  }
                });
              }
            }}
          />
          <label htmlFor="htmlfor-input-new-asset-file">
            <LogoutButton
              style={{ margin: "auto" }}
              fontSize="14px"
              title={
                <span>
                  <i className="fa fa-file-video-o" /> Upload New
                </span>
              }
              onClick={() => {
                let el = document.getElementById("input-new-asset-file");
                el.click();
              }}
            />
          </label>
        </Row>
        <Row
          style={{
            margin: "0px",
            border: "1px solid #eeeeee",
            height: "35vh",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.state.assets !== undefined
            ? this.state.assets.map((asset, index) => {
                return (
                  <div
                    key={"div_asset_" + asset.name}
                    id={"div_asset_" + asset.name}
                    style={{
                      backgroundColor:
                        this.state.selectedAssetIndex === index
                          ? "#eeeeee"
                          : "",
                      border: "1px solid #eee",
                    }}
                  >
                    <Row
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      onClick={(e) => {
                        let item = {
                          type: asset.type,
                          src: asset.url,
                          name: asset.name,
                        };
                        this.setState({
                          selectedItem: item,
                          selectedAssetIndex: index,
                        });
                      }}
                    >
                      <Col
                        md={6}
                        style={{
                          paddingLeft: "2.5rem",
                          paddingTop: "0.75rem",
                          paddingBottom: "0.5rem",
                          cursor: "pointer",
                          fontSize: "15px",
                          color: "#403E3D",
                          fontWeight: "400",
                        }}
                      >
                        <div>
                          {asset.name.length > 52
                            ? asset.name.substring(0, 22) + "..."
                            : asset.name}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })
            : null}
        </Row>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div
          style={{ display: this.props.showVideoEditor ? "inline" : "none" }}
        >
          <VideoEditor
            {...this.props}
            ref={this.videoEditorRef}
            newProjectState={this.state.newVideoProjectState}
            clearProjectState={this.clearProjectState}
            closeVideoEditorTab={() => {
              this.props.switchToAssetManagement();
            }}
            editProject={this.state.editProject}
            clearProject={() => {
              let editProject = {
                name: "",
                description: "",
                id: "",
                config: {},
              };
              this.setState({ editProject: editProject });
            }}
            updateProjectToAssetManagement={this.updateProjectToAssetManagement}
            assets={this.state.assets}
            setTemplateModalShow={(b) => {
              this.setTemplateModalShow(b);
            }}
          />
        </div>
        <div
          style={{
            display: this.props.showAssetManagement ? "inline" : "none",
          }}
        >
          <CenteredModal
            dialogClassName="modal-90wc"
            show={this.state.showProgress}
            title={"Uploading file..."}
            content={
              <div
                key={"modal_logo_main_spinner_asst_mgmt"}
                id={"modal_logo_main_spinner_asst_mgmt"}
                className="wrap"
                style={{ height: "8vh" }}
              >
                <img
                  key={"img_modal_logo_main_spinner_asst_mgmt"}
                  id={"img_modal_logo_main_spinner_asst_mgmt"}
                  src={logo}
                  alt="Loading..."
                  className="rotate"
                  width="80"
                  height="auto"
                />
              </div>
            }
          />
          <div>
            <Row className="wrap">
              <Col
                md={5}
                style={{
                  marginBottom: "-1.5rem",
                  paddingTop: "1.5rem",
                  height: "68vh",
                  marginRight: ".6rem",
                }}
              >
                <Carousel
                  className=""
                  border="1px solid #EEEEEE"
                  fontsize="15px"
                  height="68vh"
                  width="50%"
                  name={"catmanagement"}
                  hide={false}
                  data={[
                    ["Videos", this.renderProjects()],
                    ["Assets", this.renderAssets()],
                  ]}
                  noBoxShadow={true}
                  justify={true}
                />
              </Col>
              <Col
                md={6}
                style={{
                  marginLeft: "-1.5rem",
                  marginTop: "4.5rem",
                  height: "68vh",
                  width: "52vw",
                  border: "1px solid #EEEEEE",
                }}
              >
                {!this.state.selectedItem ? (
                  ""
                ) : this.state.selectedItem.type === "image" ? (
                  <Row className="wrap">
                    <div
                      className="wrap"
                      style={{ marginLeft: "2rem", marginRight: "2rem" }}
                    >
                      <canvas
                        id={
                          "asset_preview_canvas_" + this.state.selectedItem.name
                        }
                        key={
                          "asset_preview_canvas_" + this.state.selectedItem.name
                        }
                        width={600}
                        height={500}
                      ></canvas>
                      <img
                        key={"asset_img_preview" + new Date().getTime()}
                        src={this.state.selectedItem.src}
                        alt=""
                        style={{
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          display: "none",
                        }}
                        onLoad={(e) => {
                          const canvas = document.getElementById(
                            "asset_preview_canvas_" +
                              this.state.selectedItem.name
                          );
                          const ctx = canvas.getContext("2d");
                          let ratio =
                            Math.min(e.target.height, e.target.width) /
                            Math.max(e.target.height, e.target.width);

                          // make height no bigger than half-way between original and preview size
                          let useHeight =
                            (e.target.height + CONST_MAX_PREVIEW_HEIGHT) / 2;
                          if (useHeight > CONST_MAX_PREVIEW_HEIGHT) {
                            useHeight = CONST_MAX_PREVIEW_HEIGHT;
                          }
                          let recalcWidth = useHeight / ratio;
                          while (recalcWidth > CONST_MAX_PREVIEW_WIDTH) {
                            useHeight /= 2;
                            recalcWidth = useHeight / ratio;
                          }
                          let useImage = e.target;
                          canvas.height = useHeight;
                          canvas.width = recalcWidth;
                          let topMargin =
                            (CONST_MAX_PREVIEW_HEIGHT - useHeight) / 2;
                          this.setState({
                            previewTopMargin: topMargin + "px",
                          });
                          ctx.drawImage(
                            useImage,
                            0,
                            0,
                            e.target.width,
                            e.target.height,
                            0,
                            0,
                            recalcWidth,
                            useHeight
                          );
                        }}
                      />
                    </div>
                  </Row>
                ) : this.state.selectedItem.type === "video" ? (
                  <Row>
                    <div
                      style={{
                        paddingLeft: "3rem",
                        paddingRight: "3rem",
                        paddingTop: "5.5rem",
                      }}
                    >
                      <Player playsInline src={this.state.selectedItem.src}>
                        <BigPlayButton position="center" />
                      </Player>{" "}
                      <div style={{ marginTop: "2rem", textAlign: "center" }}>
                        {this.state.selectedProjectId &&
                          !this.state.videoAdShortcutDisabled && (
                            <AdPlanModal
                              key={this.state.selectedProjectId}
                              categories={this.state.allCategories}
                              selectedItem={this.state.selectedItem}
                              selectedVideoId={this.state.selectedProjectId}
                              isVideoShortcut={true}
                              initialAdPlan={{
                                adType: AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                                video: this.state.selectedProjectId,
                              }}
                              dashboard
                            />
                          )}
                      </div>
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          ,
        </div>
      </div>
    );
  }
}

AssetManagement.propTypes = {
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(withRouter(AssetManagement));
