import { getPlanType } from "./utils";

const PLAN_TYPE_OPTIONS = [
  {
    value: "same_strategy",
    label: "Dynamic Price",
  },
  {
    value: "rules_engine",
    label: "Rules Engine",
  },
];

const STRATEGY_OPTIONS = [
  { value: "volume", label: "Volume" },
  { value: "margin", label: "Margin" },
  { value: "balanced", label: "Balanced" },
];

const RULES_BADGE_OPTIONS = [
  { value: "CLEARANCE", label: "Clearance" },
  { value: "MARGIN_PROFILING", label: "Margin Profiling" },
  { value: "BSR", label: "BSR" },
  { value: "CUSTOM", label: "Custom" },
];

const SEGMENT_OPTIONS = [
  { value: "sales_pressure", label: "Sales Pressure" },
  { value: "profit_potential", label: "Profit Potential" },
  { value: "right_price", label: "Right Price" },
  { value: "out_of_stock", label: "Out of Stock" },
  { value: "slow_movers", label: "Slow Movers" },
  { value: "new_plan", label: "Profiling" },
];

export const PLAN_SEGMENT_FILTER = {
  label: "Segment",
  id: "segment",
  format: "select",
  selectOptions: [
    {
      label: "All Segments",
      value: "all",
      clearable: false,
    },
    ...SEGMENT_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (plan, filterValue) => {
    if (filterValue !== "all") {
      if (filterValue === "slow_movers") {
        return ["no_price", "slow_movers"].includes(plan.bucket);
      }
      return plan.bucket === filterValue;
    }
    return true;
  },
};

export const RULES_FILTER = {
  label: "Pricing Rules",
  id: "pricing_rules",
  format: "select",
  selectOptions: [
    {
      label: "All Rules",
      value: "all",
      clearable: false,
    },
    ...RULES_BADGE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (plan, filterValue) => {
    if (filterValue !== "all") {
      const type = getPlanType(plan);
      if (type !== "Rules Engine") {
        return false;
      }

      if (filterValue === "CUSTOM") {
        return plan.current_program__template_type === null;
      }

      if (filterValue === "MARGIN_PROFILING") {
        return ["MARGIN_PROFILING", "PROFILE"].includes(
          plan.current_program__template_type
        );
      }
      return plan.current_program__template_type === filterValue;
    }
    return true;
  },
};

export const PLAN_TYPE_FILTER = {
  label: "Plan Type",
  id: "plan_type",
  format: "select",
  selectOptions: [
    {
      label: "All Plan Types",
      value: "all",
      clearable: false,
    },
    ...PLAN_TYPE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (plan, filterValue) => {
    if (filterValue !== "all") {
      let type = getPlanType(plan);

      if (
        ["same_strategy", "same_type"].includes(filterValue) &&
        type === "Dynamic Price"
      ) {
        return true;
      }

      if (filterValue === "rules_engine" && type === "Rules Engine") {
        return true;
      }

      return false;
    }

    return true;
  },
};

export const PLAN_STRATEGY_FILTER = {
  label: "Strategy",
  id: "strategy",
  format: "select",
  selectOptions: [
    {
      label: "Strategy",
      value: "all",
      clearable: false,
    },
    ...STRATEGY_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (plan, filterValue) => {
    if (filterValue !== "all") {
      if (filterValue === "volume") {
        return plan.configured_slider_value < 0;
      } else if (filterValue === "margin") {
        return plan.configured_slider_value > 0;
      } else if (filterValue === "balanced") {
        return (
          plan.configured_slider_value >= 0 && plan.configured_slider_value <= 0
        );
      }
    }
    return true;
  },
};
