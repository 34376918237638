import React, { useState } from "react";
import { Box, Grid, TextField, Button, withStyles } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getAPIKeys, getURLPrefix } from "utils/getUrlPrefix";
import Cryptr from "cryptr";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CLIENT_SIDE_ENCRYPTION_KEY } from "utils/gvSecrets";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { setChannel } from "redux/actions/channel";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import setAuthToken from "utils/setAuthToken";
import {
  logoutUser,
  setCurrentUser,
  setTokens,
} from "redux/actions/authActions";
import userMarketPlace from "utils/userMarketPlace";
import clearCookies from "utils/clearCookies";
import { setMarketPlace } from "redux/actions/marketPlace";

const useStyles = (theme) => ({
  basePanel: {
    height: "100%",
    backgroundColor: "white",
    justifyContent: "flex-start",
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
      justifyContent: "center",
    },
  },
  scrollColumn: {
    // display: "flex",
    position: "relative",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
  },
  formColumn: {
    justifyContent: "center",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: "0%",
    top: "50%",
    width: "100%",
    transform: "translateY(-50%)",
    overflow: "hidden",
  },
  button: {
    width: "50%",
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    backgroundColor: "#d91366",
  },
  signupLink: {
    color: "#d91366",
    textDecoration: "underline",
  },
  welcomeText: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: "bold",
    color: theme.palette.grey[800],
    textAlign: "center",
  },
  subText: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: "bold",
    color: theme.palette.grey[500],
    textAlign: "center",
  },
  input: {
    fontSize: theme.typography.h4.fontSize,
  },
  inputlabel: {
    fontSize: theme.typography.h4.fontSize,
  },
  logo: {
    marginLeft: "-5%",
    maxWidth: "50%",
  },
});

function InviteForm(props) {
  const { classes, inviteToken } = props;
  const cryptr = new Cryptr(CLIENT_SIDE_ENCRYPTION_KEY);

  const URL_PREFIX = getURLPrefix();
  const apiKeys = getAPIKeys();
  const CLIENT_ID = apiKeys["API_AUTH_CLIENT_ID"];
  const CLIENT_SECRET = apiKeys["API_AUTH_SECRET"];

  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [passwordError, setPasswordError] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);

  const handleLogin = async () => {
    setIsLoginError(false);

    let isError = false;
    if (password.trim().length === 0) {
      setPasswordError(true);
      isError = true;
    }
    if (confirmation.trim().length === 0) {
      setConfirmationError(true);
      isError = true;
    }
    if (firstName.trim().length === 0) {
      setFirstNameError(true);
      isError = true;
    }
    if (lastName.trim().length === 0) {
      setLastNameError(true);
      isError = true;
    }

    if (password !== confirmation) {
      setPasswordError(true);
      setConfirmationError(true);
      isError = true;
    }

    if (isError) {
      return;
    }

    setConfirmationError(false);
    setPasswordError(false);
    setFirstNameError(false);
    setLastNameError(false);

    const payload = {
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      first_name: firstName,
      last_name: lastName,
      password: password,
      inviteToken: inviteToken,
    };

    axios
      .post(URL_PREFIX + "/auth/passinvite", payload)
      .then((res) => {
        const username = res.data.email_address;

        const payloadLogin = {
          grant_type: "password",
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          username: username,
          password: password,
        };
        axios
          .post(URL_PREFIX + "/auth/token", payloadLogin)
          .then((res) => {
            let access_token = res.data.access_token;
            let encrypted_refresh_token = cryptr.encrypt(
              res.data.refresh_token
            );

            refreshAmzOnboardStatus(
              updateAmzOnboardingStatus,
              access_token,
              props.setErrors
            );

            const newTenantInfo = {
              custom_api_request: "login_user_info",
              user_email: username,
            };

            axios
              .post(URL_PREFIX + "/api/authentication/", newTenantInfo)
              .then((res) => {
                let tokens = Object.assign(
                  { access_token: access_token },
                  { refresh_token: encrypted_refresh_token }
                );
                localStorage.setItem("Tokens", JSON.stringify(tokens));
                localStorage.setItem(
                  "TokenTime",
                  (Date.now() + 60 * 60 * 1000).toString()
                );
                setAuthToken(access_token);
                setCurrentUser(res.data);
                setTokens(tokens);
                setChannel("amazon");
                localStorage.setItem("channel", "amazon");
                userMarketPlace(access_token, () => {
                  if (res.data.permissions === "reports") {
                    window.location.href = "/user/report/advertisement";
                  } else {
                    window.location.href = "/user/dashboard";
                  }
                });
              });
          })
          .catch((err) => {
            sessionStorage.clear();
            localStorage.clear();
            logoutUser();
            clearCookies();
            props.setErrors(err);
            setIsLoginError(true);
          });
      })
      .catch((err) => {
        console.log(err.data);
        sessionStorage.clear();
        localStorage.clear();
        logoutUser();
        clearCookies();
        props.setErrors(err);
        setIsLoginError(true);
      });
  };

  return (
    <Grid container className={classes.basePanel} direction="row" wrap="nowrap">
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={10} md={8}>
        <PerfectScrollbar className={classes.scrollColumn}>
          <Box className={classes.formColumn}>
            <Box className={classes.welcomeText}>Welcome to Trellis</Box>
            <Box className={classes.subText}>
              Complete your invitation by confirming your password
            </Box>
            <Box>
              <TextField
                fullWidth
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.inputlabel }}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                label="First Name"
                error={firstNameError}
              />
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.inputlabel }}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                label="Last Name"
                error={lastNameError}
              />
            </Box>
            <Box mt={2} width={1}>
              <TextField
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.inputlabel }}
                label={"Password"}
                type="password"
                error={passwordError}
              />
            </Box>
            <Box mt={2} width={1}>
              <TextField
                fullWidth
                value={confirmation}
                onChange={(e) => setConfirmation(e.target.value)}
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.inputlabel }}
                label={"Password Confirmation"}
                type="password"
                error={confirmationError}
              />
            </Box>
            {isLoginError && (
              <Box mt={4} width={1}>
                <span className={classes.signupLink}>
                  There was a problem confirming your account. Please ensure
                  that your password meets the following criterias:
                </span>

                <div style={{ marginTop: "10px" }}>
                  <ul>
                    <li>- One or more uppercase characters</li>
                    <li>- One or more lowercase characters</li>
                    <li>- One or more numeric characters</li>
                    <li>- One or more special characters (e.g. !@#$%^)</li>
                  </ul>
                </div>
              </Box>
            )}

            <Box mt={5} width={1}>
              <Grid container justifyContent="center">
                <Button
                  className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge ${classes.button}`}
                  onClick={() => handleLogin()}
                >
                  Complete Signup
                </Button>
              </Grid>
            </Box>
            <Box mt={5} width={1}>
              <Grid container justifyContent="center">
                <a className={classes.signupLink} href="/signin">
                  Sign in instead
                </a>
              </Grid>
            </Box>
          </Box>
        </PerfectScrollbar>
      </Grid>
      <Grid item xs={1} md={2}></Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  setCurrentUser,
  updateAmzOnboardingStatus,
  setMarketPlace,
  setTokens,
  logoutUser,
  setErrors,
  clearErrors,
  setChannel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(InviteForm)));
