import React, { useMemo, useState } from "react";
import { useFetch } from "hooks/api";
import {
  Col,
  Container as Grid,
  Row,
  Nav,
  // Card,
  ProgressBar,
  Alert,
} from "react-bootstrap";
// import { formatNumber } from "utils/formatNumber";
import { selectModules } from "redux/selectors/modules";
import { connect } from "react-redux";
// import Metric from "components/core/blocks/SummaryMetric";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import ToolTips from "utils/toolTips";
import { LogoutButton } from "components/core/basic/Button";
import SearchTermTable from "./SearchTermTable";
import ProductsTable from "./ProductsTable";
import Insights from "./Insights";
import AddKeywordModal from "./AddKeywordModal";
import { trellisPalette } from "components/custom/analytics/palettes";
import api from "utils/api";

const Dashboard = ({ user, marketPlace, channel, modules }) => {
  const [tab, setTab] = useState("search_terms");
  const [showRefreshError, setShowRefreshError] = useState(false);
  const [showRefreshSuccess, setShowRefreshSuccess] = useState(false);
  const [showAddKWModal, setShowAddKWModal] = useState(false);

  const { isLoading, data, isSuccess } = useFetch(
    "organic_keywords",
    "/merchandise/seo/organic_keywords_by_org",
    {
      marketplace: "USA",
    },
    {
      default: [],
      select: (res) => res.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const isRefreshLoading = false;
  const refreshData = null;
  // const { isLoading: isRefreshLoading, data: refreshData } = useFetch(
  //   "refresh_ranks",
  //   "/merchandise/seo/refresh_progress",
  //   {
  //     marketplace: "USA",
  //   },
  //   {
  //     default: [],
  //     select: (res) => res.data,
  //     keepPreviousData: true,
  //     refetchOnReconnect: false,
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: false,
  //     refetchInterval: 30000,
  //   }
  // );

  // const { isLoading: dashboardLoading, data: dashboardData } = useFetch(
  //   "seo_dashboard",
  //   "/merchandise/seo/dashboard",
  //   {
  //     marketplace: "USA",
  //   },
  //   {
  //     default: [],
  //     select: (res) => res.data,
  //     keepPreviousData: true,
  //     refetchOnReconnect: false,
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: false,
  //   }
  // );

  const { isLoading: isOrgKWLoading, data: orgKWData } = useFetch(
    ["manual_keywords", marketPlace.marketPlace],
    "/merchandise/organic_keyword_org",
    {
      marketplace: marketPlace.marketPlace,
    },
    {
      default: [],
      select: (res) => res.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // const PositionsChart = () => {
  //   const data = {
  //     rank_1_to_3: dashboardData?.positions[0]?.value,
  //     rank_4_to_10: dashboardData?.positions[1]?.value,
  //     rank_11_to_25: dashboardData?.positions[2]?.value,
  //     rank_26_to_50: dashboardData?.positions[3]?.value,
  //     rank_50_plus: dashboardData?.positions[4]?.value,
  //   };

  //   const options = {
  //     chart: {
  //       type: "bar",
  //       height: 175,
  //       animation: false, // Disables animation
  //     },
  //     title: {
  //       text: null,
  //     },
  //     xAxis: {
  //       categories: ["1-3", "4-10", "11-25", "26-50", "50+"],
  //     },
  //     yAxis: {
  //       min: 0,
  //       title: {
  //         text: null,
  //       },
  //       labels: {
  //         enabled: false,
  //       },
  //     },
  //     legend: {
  //       enabled: false,
  //     },
  //     series: [
  //       {
  //         name: "Ranks",
  //         color: trellisPalette[0],
  //         animation: false, // Disables animation
  //         data: [
  //           data.rank_1_to_3,
  //           data.rank_4_to_10,
  //           data.rank_11_to_25,
  //           data.rank_26_to_50,
  //           data.rank_50_plus,
  //         ],
  //       },
  //     ],
  //   };

  //   return (
  //     <Card className="mr-4 p-4 mb-3">
  //       <h6
  //         style={{ height: "34px", fontWeight: 600 }}
  //         className={`fs-standard text-end`}
  //       >
  //         Positions
  //         <ToolTips
  //           id={"positionsSTip"}
  //           toolTip={
  //             "The frequency at which your products appear in various ranking positions"
  //           }
  //         />
  //       </h6>
  //       <HighchartsReact highcharts={Highcharts} options={options} />
  //     </Card>
  //   );
  // };

  const showManual = useMemo(() => {
    if (tab === "manual") {
      return true;
    }
    return false;
  }, [tab]);

  const refreshSearchTerms = async () => {
    try {
      await api.get("/merchandise/seo/start_refresh", {
        marketplace: marketPlace.marketPlace,
      });
      setShowRefreshError(false);
      setShowRefreshSuccess(true);
    } catch (err) {
      setShowRefreshError(true);
      setShowRefreshSuccess(false);
    }
  };

  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col md={12} className="py-3 px-5">
          <div className="py-2 fs-4 text-center">
            Enhance your SEO Performance with the Trellis Chrome
            Extension.&nbsp;&nbsp;
            <a
              href="https://chromewebstore.google.com/detail/trellis-seller-extension/mfnnikclhbphfbkojdfoeccbfpjcbbdl"
              target="_blank"
              rel="noopener noreferrer"
              title="Download the Trellis Chrome Extension"
            >
              Download it now
            </a>
            &nbsp;&nbsp;for advanced performance metrics directly on Amazon.
          </div>
        </Col>
        <Col md={12} className="pt-2 px-5 mb-4">
          {/* {!dashboardLoading && ( */}
          {true && (
            <Row className="fs-4">
              <Col xs={12} className="pt-3">
                <Row>
                  {/* <Col xs={12} md={3} className="text-center">
                    <Card className="mr-4 p-4 mb-3">
                      <Metric
                        size={"small"}
                        id={"Visibility"}
                        toolTip={
                          "The visibility metrics shows the percentage of impressions your products received for the search terms we queried on Amazon."
                        }
                        name={"Visibility"}
                        showTarget={false}
                        value={!isLoading ? data.visibility_score * 100 : 0}
                        formatter={formatNumber}
                      />
                    </Card>
                    <Card className="mr-4 p-4 mb-3">
                      <Metric
                        size={"small"}
                        id={"AvgPosition"}
                        toolTip={
                          "The average position metric shows the average position of your products in the search results for the search terms we queried on Amazon"
                        }
                        name={"Avg Position"}
                        showTarget={false}
                        value={dashboardData.avg_position}
                        formatter={formatNumber}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} md={3} className="text-center"> 
                    <PositionsChart />
                  </Col> */}
                  <Col xs={12} md={12} className="text-end">
                    <div
                      style={{
                        height: "50px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: 0,
                        }}
                      >
                        {!isRefreshLoading && (
                          <div>
                            {showRefreshError && (
                              <div className="fs-5 pb-3">
                                <Alert
                                  style={{
                                    backgroundColor: trellisPalette[3],
                                    color: "#fff",
                                  }}
                                >
                                  Refresh already in progress or refresh limit
                                  reached. You may only refresh once every 24
                                  hours.
                                </Alert>
                              </div>
                            )}

                            {showRefreshSuccess && (
                              <div className="fs-5 pb-3">
                                <Alert
                                  style={{
                                    backgroundColor: trellisPalette[3],
                                    color: "#fff",
                                  }}
                                >
                                  Refresh started successfully. Please allow a
                                  few hours for the refresh to complete.
                                </Alert>
                              </div>
                            )}

                            {refreshData &&
                              refreshData?.percentage_done > 0 && (
                                <div
                                  style={{
                                    width: "100%",
                                    marginLeft: "200px",
                                    paddingRight: "200px",
                                  }}
                                >
                                  <div className="fs-5 pb-3">
                                    Your search term refresh is&nbsp;
                                    {parseInt(
                                      refreshData?.percentage_done * 100
                                    )}
                                    % Complete
                                  </div>
                                  <div className="pb-4">
                                    <ProgressBar
                                      variant="tr-fuchsia"
                                      style={{
                                        height: "20px",
                                        fontSize: "12px",
                                      }}
                                      now={parseInt(
                                        refreshData?.percentage_done * 100
                                      )}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        )}

                        <LogoutButton
                          title={"Add Keyword"}
                          onClick={() => {
                            setShowAddKWModal(true);
                          }}
                          className="logout_button"
                          style={{ marginLeft: "5px" }}
                        />
                        <LogoutButton
                          title={"Refresh Search Terms"}
                          onClick={() => {
                            refreshSearchTerms();
                          }}
                          className="logout_button"
                          style={{ marginLeft: "5px" }}
                          disabled={data?.keywords?.length === 0}
                        />
                        <LogoutButton
                          title={"Export"}
                          onClick={() => {
                            // setShowModal(true);
                          }}
                          className="logout_button"
                          style={{ marginLeft: "5px" }}
                          disabled={data?.keywords?.length === 0}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="fs-4 pt-3">
            <Col xs={12} className="pt-3">
              <Nav
                variant="underline"
                onSelect={(k) => {
                  return setTab(k);
                }}
                activeKey={tab}
              >
                <Nav.Item>
                  <Nav.Link eventKey="search_terms">Search Terms</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="manual">Your Keywords</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="products">Products</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="insights">Insights</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="pt-3">
              {(tab === "search_terms" || tab === "manual") && (
                <SearchTermTable
                  isLoading={isLoading || isOrgKWLoading ? true : false}
                  data={data}
                  isSuccess={isSuccess}
                  marketPlace={marketPlace}
                  orgKWData={orgKWData}
                  filterOrgKWData={showManual}
                />
              )}

              {tab === "products" && (
                <ProductsTable marketPlace={marketPlace} />
              )}

              {tab === "insights" && <Insights marketPlace={marketPlace} />}
            </Col>
          </Row>
        </Col>
      </Row>

      <AddKeywordModal
        showAddKWModal={showAddKWModal}
        setShowAddKWModal={setShowAddKWModal}
        marketPlace={marketPlace}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(Dashboard);
