import React from "react";
import { Table } from "react-bootstrap";

const SponsoredBrandTable = ({ sponsoredBrands, sponsoredVideos }) => {
  return (
    <div
      style={{
        padding: "20px 10px 20px 10px",
        border: "1px solid #ccc",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopRightRadius: "5px",
        height: "400px",
        overflowY: "scroll",
      }}
    >
      <Table striped hover condensed>
        <thead>
          <tr>
            <td className="text-start">Brand</td>
            <td className="text-end">Ad Type</td>
          </tr>
        </thead>
        <tbody>
          {sponsoredBrands.map((brand, index) => {
            return (
              <tr key={index}>
                <td className="text-start">{brand.store_name}</td>
                <td className="text-end">Brand Ad</td>
              </tr>
            );
          })}
          {sponsoredVideos.map((brand, index) => {
            return (
              <tr key={index}>
                <td className="text-start">
                  {brand.products?.[0]?.title?.substring(0, 25)}
                </td>
                <td className="text-end">Video Ad</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SponsoredBrandTable;
