import React, { useState } from "react";
import { connect } from "react-redux";
import store from "redux/store";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Grid, withStyles } from "@material-ui/core";
import Card from "components/core/basic/Card.jsx";
import Switch from "react-bootstrap-switch";
import getURLPrefix from "utils/getUrlPrefix";
import { SecondaryButton } from "assets/themes/Buttons";
import { setNotificationPreferences } from "redux/actions/userActions";
import { selectModules } from "redux/selectors/modules";

let URL_PREFIX = getURLPrefix();

const useStyles = (theme) => ({
  preferenceContainer: {
    marginTop: theme.spacing(5),
  },
  preferenceTitle: {
    margin: "0px 0px",
    paddingLeft: theme.spacing(2),
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2, 2),
      textAlign: "center",
    },
  },
  preferenceDescriptionContainer: {
    margin: "0px 0px",
    height: "40px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  preferenceDescription: {
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2),
      textAlign: "center",
      margin: "auto",
    },
  },
  checkboxContainer: {
    height: "40px",
    display: "flex",
    justifyContent: "center",
  },
  switchContainer: {
    height: "40px",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  checkbox: {
    marginBottom: "0px",
    display: "flex",
    alignItems: "center",
  },
  checkboxLabel: {
    textTransform: "none",
    color: "#73706E",
    fontWeight: "700",
  },
});

const NotificationPreferences = ({
  user: {
    permissions,
    preferences,
    preferences: {
      all_notifications: prevNotificationsOn,
      ad_product_out_of_stock: prevOutOfStockNotifications,
      ad_product_low_in_stock: prevLowStockNotifications,
      product_ineligibility: prevIneligibilityNotifications,
      ad_kw_asin_notif: prevKwAsinNotifications,
      product_exceed_spend: prevAdBudgetNotifications,
    },
  },
  handleNotification,
  classes,
  history,
}) => {
  const [allNotificationsOn, setAllNotificationsOn] = useState(
    !!prevNotificationsOn
  );
  const [outOfStockNotifications, setOutOfStockNotifications] = useState(
    !!prevOutOfStockNotifications
  );
  const [lowStockNotifications, setLowStockNotifications] = useState(
    !!prevLowStockNotifications
  );
  const [ineligibilityNotifications, setIneligibilityNotifications] = useState(
    !!prevIneligibilityNotifications
  );
  const [kwAsinNotifications, setKwAsinNotifications] = useState(
    !!prevKwAsinNotifications
  );
  const [adBudgetNotifications, setAdBudgetNotifications] = useState(
    !!prevAdBudgetNotifications
  );

  const saveNotificationPreferences = async () => {
    let data = {
      all_notifications: allNotificationsOn,
      ad_product_out_of_stock: outOfStockNotifications,
      ad_product_low_in_stock: lowStockNotifications,
      product_ineligibility: ineligibilityNotifications,
      ad_kw_asin_notif: kwAsinNotifications,
      product_exceed_spend: adBudgetNotifications,
    };

    try {
      const res = await axios.put(URL_PREFIX + "/api/org_permissions/", data);

      if (res) {
        store.dispatch(setNotificationPreferences(data, preferences));

        handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Saved your preferences."
        );
      }

      permissions === "reports"
        ? history.push("/user/report/advertisement")
        : history.push("/user/dashboard");
    } catch (error) {
      handleNotification(
        "tr",
        "warning",
        "pe-7s-attention",
        "Failed to save your preferences, please retry."
      );
    }
  };

  return (
    <>
      <div className="container">
        <div>
          <div>
            <div className="text-center">
              <h4 className="fw-bold">Notification Settings</h4>
            </div>
            <Grid container item xs={12} lg={10} className="mx-auto">
              <Card
                style={{ width: "100%" }}
                content={
                  <div style={{ width: "100%" }}>
                    <div>
                      <Grid container className={classes.preferenceContainer}>
                        <Grid item xs={12}>
                          <h5 className={classes.preferenceTitle}>
                            All Notifications
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          className={classes.preferenceDescriptionContainer}
                        >
                          <div className={classes.preferenceDescription}>
                            Enable all notifications for your Trellis account
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.switchContainer}
                        >
                          <Switch
                            onText=""
                            offText=""
                            value={allNotificationsOn}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              setAllNotificationsOn(!allNotificationsOn);
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* OUT OF STOCK NOTIFICATION PREFERENCES */}
                      <Grid container className={classes.preferenceContainer}>
                        <Grid item xs={12}>
                          <h5 className={classes.preferenceTitle}>
                            Out of Stock Notifications
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          className={classes.preferenceDescriptionContainer}
                        >
                          <div className={classes.preferenceDescription}>
                            Enable out of stock notifications
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.switchContainer}
                        >
                          <Switch
                            onText=""
                            offText=""
                            value={
                              allNotificationsOn
                                ? true
                                : outOfStockNotifications
                            }
                            onColor="#FFFFFF"
                            disabled={allNotificationsOn}
                            onChange={(e) => {
                              setOutOfStockNotifications(
                                !outOfStockNotifications
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* LOW STOCK NOTIFICATION PREFERENCES */}
                      <Grid container className={classes.preferenceContainer}>
                        <Grid item xs={12}>
                          <h5 className={classes.preferenceTitle}>
                            Low Stock Notifications
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          className={classes.preferenceDescriptionContainer}
                        >
                          <div className={classes.preferenceDescription}>
                            Enable low stock notifications
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.switchContainer}
                        >
                          <Switch
                            onText=""
                            offText=""
                            value={
                              allNotificationsOn ? true : lowStockNotifications
                            }
                            disabled={allNotificationsOn}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              setLowStockNotifications(!lowStockNotifications);
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* PRODUCT ELIGIBILITY NOTIFICATION PREFERENCES */}
                      <Grid container className={classes.preferenceContainer}>
                        <Grid item xs={12}>
                          <h5 className={classes.preferenceTitle}>
                            Product Eligibility Notifications
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          className={classes.preferenceDescriptionContainer}
                        >
                          <div className={classes.preferenceDescription}>
                            Enable product eligibility notifications
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.switchContainer}
                        >
                          <Switch
                            onText=""
                            offText=""
                            value={
                              allNotificationsOn
                                ? true
                                : ineligibilityNotifications
                            }
                            disabled={allNotificationsOn}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              setIneligibilityNotifications(
                                !ineligibilityNotifications
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* ASIN AND KEYWORD SUMMARY NOTIFICATION PREFERENCES */}
                      <Grid container className={classes.preferenceContainer}>
                        <Grid item xs={12}>
                          <h5 className={classes.preferenceTitle}>
                            ASIN and Keyword Summary Notifications
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          className={classes.preferenceDescriptionContainer}
                        >
                          <div className={classes.preferenceDescription}>
                            Enable weekly ASIN and keyword summary notifications
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.switchContainer}
                        >
                          <Switch
                            onText=""
                            offText=""
                            value={
                              allNotificationsOn ? true : kwAsinNotifications
                            }
                            disabled={allNotificationsOn}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              setKwAsinNotifications(!kwAsinNotifications);
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* AD BUDGET EXCEEDED NOTIFICATION PREFERENCES */}
                      <Grid container className={classes.preferenceContainer}>
                        <Grid item xs={12}>
                          <h5 className={classes.preferenceTitle}>
                            Ad Budget Exceeded Notifications
                          </h5>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          className={classes.preferenceDescriptionContainer}
                        >
                          <div className={classes.preferenceDescription}>
                            Enable weekly ad budget exceeded notifications
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.switchContainer}
                        >
                          <Switch
                            onText=""
                            offText=""
                            value={
                              allNotificationsOn ? true : adBudgetNotifications
                            }
                            disabled={allNotificationsOn}
                            onColor="#FFFFFF"
                            onChange={(e) => {
                              setAdBudgetNotifications(!adBudgetNotifications);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                }
                ftTextCenter
                legend={
                  <SecondaryButton
                    onClick={(e) => {
                      saveNotificationPreferences();
                    }}
                    style={{ margin: "2rem auto" }}
                  >
                    Save
                  </SecondaryButton>
                }
              />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

NotificationPreferences.propTypes = {
  user: PropTypes.object.isRequired,
  modules: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(NotificationPreferences))
);
