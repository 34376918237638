import { PrimaryButton, SecondaryButton } from "components/core/basic/Button";
import React from "react";
import { Col, Row } from "react-bootstrap";

const CreateFooterActions = ({
  form,
  handleSubmit,
  onCreate,
  onCancel,
  onInvalid,
  isLoading,
  ...props
}) => {
  return (
    <Row className="mt-4">
      <Col md={12} className="d-flex justify-content-end">
        <div style={{ marginRight: "2rem" }}>
          <SecondaryButton
            nowrap
            onClick={() => {
              if (onCancel) onCancel();
            }}
          >
            Cancel
          </SecondaryButton>
        </div>
        <div>
          <PrimaryButton disabled={isLoading} onClick={onCreate} nowrap>
            Create
          </PrimaryButton>
        </div>
      </Col>
    </Row>
  );
};

export default CreateFooterActions;
