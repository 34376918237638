import React, { Component } from "react";
import { Row, Col, NavDropdown, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TrakCard from "components/custom/insights/trakGroupTile";

import axios from "axios";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import getURLPrefix from "utils/getUrlPrefix";
import { setErrors, clearErrors } from "redux/actions/errorActions";
import SingleStatsTile from "components/core/blocks/SingleStatsTile";
import "assets/css/ctable_style.css";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";

let URL_PREFIX = getURLPrefix();

class Insights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainLoading: true,
      noData: false,
      filterData: [],
      redirectCampaign: null,
      redirectAdgroup: null,
      campaign: null,
      selectedCampaign: null,
      adGroup: null,
      selectedAdGroup: null,
      selectedBucket: null,
      selectedBucketData: {},
      selectedBucketKeywords: [],
      adGroupsFilterData: [],
      ag_bids: {},
      ag_keywords: {},
      marketPlace: this.props.marketPlace.marketPlace,
    };
  }

  componentDidMount() {
    if (
      this.props.currentCampaign !== null &&
      this.props.currentAdgroup !== null &&
      (this.state.redirectCampaign !== this.props.currentCampaign ||
        this.state.redirectAdgroup !== this.props.currentAdgroup)
    ) {
      this.setState(
        {
          redirectCampaign: this.props.currentCampaign,
          redirectAdgroup: this.props.currentAdgroup,
        },
        () => {
          this.fetchCampaigns(
            this.props.currentCampaign,
            this.props.currentAdgroup
          );
        }
      );
    } else {
      this.fetchCampaigns();
    }
  }

  componentDidUpdate() {
    if (this.state.campaign !== this.state.selectedCampaign) {
      this.setState({ campaign: this.state.selectedCampaign });
    }
    if (this.state.adGroup !== this.state.selectedAdGroup) {
      this.setState({ adGroup: this.state.selectedAdGroup });
    }
    if (this.state.marketPlace !== this.props.marketPlace.marketPlace) {
      this.setState({ marketPlace: this.props.marketPlace.marketPlace }, () => {
        this.fetchCampaigns();
      });
    }
  }

  handleCampaignSelection(item) {
    this.setState(
      {
        selectedCampaign: item.campaign_name,
        adGroupsFilterData: item.ad_groups,
        adGroup: item.ad_groups[0],
        selectedAdGroup: item.ad_groups[0],
      },
      () => {
        this.fetchAdgroupData(this.state.selectedCampaign, this.state.adGroup);
      }
    );
  }

  handleAdGroupSelection(item) {
    this.setState({ selectedAdGroup: item }, () => {
      this.fetchAdgroupData(this.state.campaign, this.state.selectedAdGroup);
    });
  }

  fetchCampaigns(currentCampaign, currentAdgroup) {
    axios
      .get(
        URL_PREFIX +
          "/trellistrak/insight/?type=campaign_adgroups&market_place=" +
          this.props.marketPlace.marketPlace
      )
      .then((res) => {
        if (res.data.length > 0) {
          if (currentCampaign !== undefined && currentAdgroup !== undefined) {
            this.setState(
              {
                filterData: res.data,
                campaign: currentCampaign,
                adGroup: currentAdgroup,
                selectedCampaign: currentCampaign,
                selectedAdGroup: currentAdgroup,
                adGroupsFilterData: res.data.filter(function (e) {
                  return e["campaign_name"] === currentCampaign;
                })[0]["ad_groups"],
                mainLoading: false,
                noData: false,
              },
              () => {
                this.fetchAdgroupData(this.state.campaign, this.state.adGroup);
              }
            );
          } else {
            this.setState(
              {
                filterData: res.data,
                campaign: res.data[0]["campaign_name"],
                selectedCampaign: res.data[0]["campaign_name"],
                adGroup: res.data[0]["ad_groups"][0],
                selectedAdGroup: res.data[0]["ad_groups"][0],
                adGroupsFilterData: res.data[0]["ad_groups"],
                mainLoading: false,
                noData: false,
              },
              () => {
                this.fetchAdgroupData(this.state.campaign, this.state.adGroup);
              }
            );
          }
        } else {
          this.setState({
            filterData: [],
            campaign: null,
            selectedCampaign: null,
            adGroup: null,
            selectedAdGroup: null,
            selectedBucket: null,
            selectedBucketData: {},
            selectedBucketKeywords: [],
            adGroupsFilterData: [],
            ag_bids: {},
            ag_keywords: {},
            mainLoading: false,
            noData: true,
          });
        }
      });
  }

  fetchAdgroupData(campaign_name, adgroup_name) {
    axios
      .get(
        URL_PREFIX +
          "/trellistrak/insight/?type=keywords_quad&campaign_name=" +
          encodeURIComponent(campaign_name) +
          "&adgroup_name=" +
          encodeURIComponent(adgroup_name)
      )
      .then((res) => {
        if (Object.keys(res.data).length > 0) {
          if ("bid optimization quad" in res.data) {
            this.setState({ ag_bids: res.data["bid optimization quad"] });
          } else {
            this.setState({ ag_bids: {} });
          }
          if ("keyword opportunities quad" in res.data) {
            this.setState({
              ag_keywords: res.data["keyword opportunities quad"],
            });
          } else {
            this.setState({ ag_keywords: {} });
          }
          if (Object.keys(res.data).length > 2) {
            this.onBucketClick(Object.keys(res.data)[2]);
          } else {
            this.onBucketClick(Object.keys(res.data)[1]);
          }
        }
      });
  }

  onBucketClick(group) {
    var bucketData;
    var filteredKeywords;
    if (group === "bid optimization quad") {
      bucketData = this.state.ag_bids;
      filteredKeywords = bucketData.keywords;
    }
    if (group === "keyword opportunities quad") {
      bucketData = this.state.ag_keywords;
      filteredKeywords = bucketData.new_keywords_opportunities_details;
    }
    this.setState({
      selectedBucket: group,
      selectedBucketData: bucketData,
      selectedBucketKeywords: filteredKeywords,
    });
  }

  selectedBucket(group) {
    if (group === this.state.selectedBucket) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    var truncatedCampaignName =
      this.state.campaign > 50
        ? this.state.campaign.substring(0, 50) + "..."
        : this.state.campaign;
    var truncatedAdGroupName =
      this.state.adGroup > 50
        ? this.state.adGroup.substring(0, 50) + "..."
        : this.state.adGroup;
    var wrapper =
      this.state.selectedBucket === "bid optimization quad"
        ? "tasks-table-wrapper"
        : "tasks-table-wrapper_keywords";
    return (
      <div style={{ marginTop: "3rem", padding: "1rem 1rem" }}>
        {this.state.mainLoading ? (
          <div className="wrap" style={{ height: "100vh" }}>
            <img
              src={logo}
              alt="Loading..."
              className="rotate"
              width="80"
              height="auto"
            />
          </div>
        ) : this.state.noData ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "20%",
              paddingTop: "1rem",
              paddingBottom: "5rem",
            }}
          >
            <div
              style={{ fontSize: "26px", color: "#403E3D", fontWeight: "700" }}
            >
              Trellis Trak
            </div>
            <br />
            <div
              style={{ fontSize: "22px", color: "#403E3D", fontWeight: "400" }}
            >
              Insights has No Data Available for the Past 60 Days
            </div>
          </div>
        ) : (
          <>
            <div
              className="card"
              style={{
                height: "900px",
                marginTop: "1rem",
                marginBottom: "-1rem",
              }}
            >
              <Row
                style={{
                  marginLeft: "1px",
                  marginRight: "1px",
                  borderBottom: "1px solid #eeeeee",
                }}
              >
                <Col
                  md={4}
                  style={{ height: "200px", borderRight: "1px solid #eee" }}
                >
                  <div
                    style={{
                      paddingTop: "3rem",
                      paddingBottom: "1rem",
                      paddingLeft: "2rem",
                      paddingRight: "4rem",
                    }}
                  >
                    <h4
                      className="title"
                      style={{
                        marginTop: "1rem",
                        fontSize: "20px",
                        color: "#403E3D",
                        fontWeight: "700",
                      }}
                    >
                      Campaign
                    </h4>
                    <NavDropdown
                      id="CampaignNameDropdown"
                      title={truncatedCampaignName}
                      style={{
                        color: "#73706E",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      {this.state.filterData.map((item, index) => {
                        return (
                          <NavDropdown.Item
                            key={"CampaignName" + index}
                            id={index}
                            style={{ padding: ".5rem" }}
                            onClick={() => this.handleCampaignSelection(item)}
                          >
                            {item.campaign_name}
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                    <h4
                      className="title"
                      style={{
                        marginTop: "1rem",
                        fontSize: "20px",
                        color: "#403E3D",
                        fontWeight: "700",
                      }}
                    >
                      Ad Group
                    </h4>
                    <NavDropdown
                      id="AdGroupNameDropdown"
                      title={truncatedAdGroupName}
                      style={{
                        color: "#73706E",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      {this.state.adGroupsFilterData.map((item, index) => {
                        return (
                          <NavDropdown.Item
                            key={"AdGroupName" + index}
                            id={index}
                            style={{ padding: ".5rem" }}
                            onClick={() => this.handleAdGroupSelection(item)}
                          >
                            {item}
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                  </div>
                </Col>
                <Col md={8}>
                  <Row style={{ marginTop: "0.5rem" }}>
                    <div className="wrap">
                      <Col md={5} style={{ marginRight: "2rem" }}>
                        {"keywords" in this.state.ag_bids ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onBucketClick("bid optimization quad")
                            }
                          >
                            {" "}
                            <TrakCard
                              selected={this.selectedBucket(
                                "bid optimization quad"
                              )}
                              statsText="BID OPTIMIZATION"
                              statsValue={this.state.ag_bids.keywords.length}
                            />{" "}
                          </div>
                        ) : (
                          <div>
                            {" "}
                            <TrakCard
                              display={false}
                              statsText="BID OPTIMIZATION"
                              statsValue="-"
                            />{" "}
                          </div>
                        )}
                      </Col>
                      <Col md={5} style={{ marginLeft: "2rem" }}>
                        {"new_keywords_opportunities_details" in
                          this.state.ag_keywords &&
                        this.state.ag_keywords
                          .new_keywords_opportunities_details.length > 0 ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onBucketClick("keyword opportunities quad")
                            }
                          >
                            {" "}
                            <TrakCard
                              selected={this.selectedBucket(
                                "keyword opportunities quad"
                              )}
                              statsText="NEW KEYWORDS"
                              statsValue={
                                this.state.ag_keywords
                                  .new_keywords_opportunities_details.length
                              }
                            />{" "}
                          </div>
                        ) : (
                          <div>
                            {" "}
                            <TrakCard
                              display={false}
                              statsText="NEW KEYWORDS"
                              statsValue="0"
                            />{" "}
                          </div>
                        )}
                      </Col>
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div style={{ padding: "2rem 2rem" }}>
                    <div className="header">
                      {Object.keys(this.state.selectedBucketData).length > 0 ? (
                        <>
                          <Row>
                            <h4
                              className="title"
                              style={{
                                marginLeft: "1.5rem",
                                fontSize: "15px",
                                color: "#73706E",
                                fontWeight: "700",
                              }}
                            >
                              {this.state.selectedBucket ===
                              "bid optimization quad" ? (
                                <>
                                  <div
                                    style={{
                                      fontSize: "18px",
                                      color: "#403E3D",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Bid Optimization Performance Analysis
                                  </div>
                                  <div
                                    style={{ paddingTop: "2rem" }}
                                    className="d-flex"
                                  >
                                    <Col className="mx-4 col-xs-12 col-md">
                                      {this.state.selectedBucketData.sales !==
                                      0 ? (
                                        <SingleStatsTile
                                          statsText={"ACOS"}
                                          statsValue={
                                            parseFloat(
                                              (
                                                this.state.selectedBucketData
                                                  .acos * 100
                                              ).toFixed(0)
                                            ).toLocaleString() + " %"
                                          }
                                          valueAlign={"center"}
                                          disableWrap={true}
                                        />
                                      ) : (
                                        <SingleStatsTile
                                          statsText={"ACOS"}
                                          statsValue={"-"}
                                          valueAlign={"center"}
                                          disableWrap={true}
                                        />
                                      )}
                                    </Col>
                                    <Col className="mx-4 col-xs-12 col-md">
                                      <SingleStatsTile
                                        statsText={"ACOS SAVINGS"}
                                        statsValue={
                                          this.state.selectedBucketData.total_potential_saving.toFixed(
                                            0
                                          ) + " %"
                                        }
                                        valueAlign={"center"}
                                        disableWrap={true}
                                      />
                                    </Col>
                                    <Col className="mx-4 col-xs-12 col-md">
                                      <SingleStatsTile
                                        statsText={"SPEND"}
                                        statsValue={
                                          MARKETPLACE_CURRENCY_DENOMINATION[
                                            this.props.marketPlace.marketPlace
                                          ] +
                                          " " +
                                          parseFloat(
                                            this.state.selectedBucketData.spend.toFixed(
                                              2
                                            )
                                          ).toLocaleString()
                                        }
                                        valueAlign={"center"}
                                        disableWrap={true}
                                      />
                                    </Col>
                                    <Col className="mx-4 col-xs-12 col-md">
                                      <SingleStatsTile
                                        statsText={"SALES"}
                                        statsValue={
                                          MARKETPLACE_CURRENCY_DENOMINATION[
                                            this.props.marketPlace.marketPlace
                                          ] +
                                          " " +
                                          parseFloat(
                                            this.state.selectedBucketData.sales.toFixed(
                                              2
                                            )
                                          ).toLocaleString()
                                        }
                                        valueAlign={"center"}
                                        disableWrap={true}
                                      />
                                    </Col>
                                  </div>
                                </>
                              ) : null}
                            </h4>
                          </Row>
                          <Row style={{ paddingTop: "2rem" }}>
                            <Col md={12}>
                              {this.state.selectedBucketKeywords.length > 0 ? (
                                <>
                                  <h4
                                    className="title text-start"
                                    style={{
                                      marginBottom: "3rem",
                                      fontSize: "18px",
                                      color: "#403E3D",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {this.state.selectedBucket ===
                                    "bid optimization quad" ? (
                                      <span>
                                        Bid Adjustment Opportunities on your
                                        Keywords
                                      </span>
                                    ) : this.state.selectedBucket ===
                                      "keyword opportunities quad" ? (
                                      <span>New Keyword Opportunities</span>
                                    ) : null}
                                  </h4>
                                  <div id="content">
                                    <div id={wrapper}>
                                      <Table id="tasks">
                                        <colgroup>
                                          {this.state.selectedBucket ===
                                          "bid optimization quad" ? (
                                            <>
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                            </>
                                          ) : null}
                                          {this.state.selectedBucket ===
                                          "keyword opportunities quad" ? (
                                            <>
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                              <col style={{ width: "10%" }} />
                                            </>
                                          ) : null}
                                        </colgroup>
                                        <thead style={{ border: "hidden" }}>
                                          <tr>
                                            {this.state.selectedBucket ===
                                            "bid optimization quad" ? (
                                              <>
                                                <th className="keyword">
                                                  Keyword
                                                </th>
                                                <th
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Action
                                                </th>
                                                <th
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Current CPC
                                                </th>
                                                <th
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Recommended Bid
                                                </th>
                                                <th
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Save
                                                </th>
                                              </>
                                            ) : null}
                                            {this.state.selectedBucket ===
                                            "keyword opportunities quad" ? (
                                              <>
                                                <th className="keyword">
                                                  Keyword
                                                </th>
                                                <th
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Action
                                                </th>
                                                <th
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Recommended Bid
                                                </th>
                                                <th
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  60 Day Conversion
                                                </th>
                                              </>
                                            ) : null}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.selectedBucketKeywords.map(
                                            (keyword, index) => {
                                              return this.state
                                                .selectedBucket ===
                                                "bid optimization quad" ? (
                                                <tr key={"KeyValue" + index}>
                                                  <td>
                                                    {keyword.keyword_text}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {keyword.action}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    {MARKETPLACE_CURRENCY_DENOMINATION[
                                                      this.props.marketPlace
                                                        .marketPlace
                                                    ] +
                                                      keyword.current_cpc.toFixed(
                                                        2
                                                      )}
                                                  </td>
                                                  {keyword.recommendation !==
                                                  "N/A" ? (
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {MARKETPLACE_CURRENCY_DENOMINATION[
                                                        this.props.marketPlace
                                                          .marketPlace
                                                      ] +
                                                        keyword.avg_cpc.toFixed(
                                                          2
                                                        )}
                                                    </td>
                                                  ) : (
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {keyword.recommendation}
                                                    </td>
                                                  )}
                                                  {keyword.potential_saving !==
                                                  "N/A" ? (
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {MARKETPLACE_CURRENCY_DENOMINATION[
                                                        this.props.marketPlace
                                                          .marketPlace
                                                      ] +
                                                        keyword.potential_saving.toFixed(
                                                          2
                                                        )}
                                                    </td>
                                                  ) : (
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {keyword.potential_saving}
                                                    </td>
                                                  )}
                                                </tr>
                                              ) : this.state.selectedBucket ===
                                                "keyword opportunities quad" ? (
                                                <tr key={"KeyValue" + index}>
                                                  <td>{keyword.query}</td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {keyword.action}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {MARKETPLACE_CURRENCY_DENOMINATION[
                                                      this.props.marketPlace
                                                        .marketPlace
                                                    ] +
                                                      keyword.recommended_bid.toFixed(
                                                        2
                                                      )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {(
                                                      keyword.potential_conversion *
                                                      100
                                                    ).toFixed(0)}{" "}
                                                    %
                                                  </td>
                                                </tr>
                                              ) : null;
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{
                height: "300px",
                marginTop: "1rem",
                marginBottom: "-1rem",
              }}
            />
          </>
        )}
      </div>
    );
  }
}

Insights.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
});

const mapDispatchToProps = {
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Insights));
