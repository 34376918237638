import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import CustomHref from "components/core/blocks/CustomHref";

const AdPlanError = ({ errors, clearError }) => {
  if (!errors?.adPlanError) {
    return null;
  }
  return (
    <SweetAlert
      title={
        errors.adPlanError === "No subscription"
          ? "You don't have a plan, yet."
          : errors.adPlanError
      }
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={(e) => clearError("adPlanError")}
    >
      {errors.adPlanError === "No subscription" ? (
        <>
          Choose your plan type on the billing page.{" "}
          <Link to="/user/billing" onClick={(e) => clearError("adPlanError")}>
            <CustomHref textDecoration="none" text={"Pick Now"} />
          </Link>
          .
        </>
      ) : errors.adPlanError === "Media plan limit reached" ||
        errors.adPlanError === "Marketplace limit reached" ? (
        <>
          Upgrade your plan type on the billing page to create more media plans.{" "}
          <Link to="/user/billing" onClick={(e) => clearError("adPlanError")}>
            <CustomHref textDecoration="none" text={"Upgrade Now"} />
          </Link>
          .
        </>
      ) : (
        <>
          Upgrade your plan type on the billing page.{" "}
          <Link to="/user/billing" onClick={(e) => clearError("adPlanError")}>
            <CustomHref textDecoration="none" text={"Pick Now"} />
          </Link>
          .
        </>
      )}
    </SweetAlert>
  );
};

export default AdPlanError;
