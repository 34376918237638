import React from "react";
import { Row, Col } from "react-bootstrap";
import withMediaQuery from "hocs/withMediaQuery";
import { Link } from "react-router-dom";
import useDashboardTheme from "hooks/useDashboardTheme";

const TrellisBreadcrumb = ({ items, mediaQuery }) => {
  const { breadcrumbColor } = useDashboardTheme();

  return (
    <Row style={{ width: mediaQuery ? "100vw" : "calc(100vw - 80px)" }}>
      <Col
        xs={12}
        style={{
          borderBottom: "1px solid #dcdadd",
          backgroundColor: breadcrumbColor ?? "#f5f3f6",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingLeft: "40px",
        }}
      >
        <ol
          role="navigation"
          aria-label="breadcrumbs"
          className="breadcrumb"
          style={{ marginBottom: "0px", fontSize: "14px" }}
        >
          {items.map((itm, i) => {
            return (
              <li key={i} className="breadcrumb-item pl-2 fw-normal">
                <Link className="text-decoration-none" to={itm.href}>
                  {itm.name}
                </Link>
              </li>
            );
          })}
        </ol>
      </Col>
    </Row>
  );
};

export default withMediaQuery("(max-width:992px)")(TrellisBreadcrumb);
