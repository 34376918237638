import { AD_TYPES, GOALS } from "components/core/blocks/AdPlan/constants";

export const AD_STATUS_OPTIONS = [
  { value: 1, label: "Active" },
  { value: 0, label: "Paused" },
  { value: 2, label: "Pending" },
  { value: 10, label: "Failed" },
];

const AD_SOURCE_OPTIONS = [
  { value: "trellis", label: "Trellis" },
  { value: 25, label: "Imported" },
];

const channel = localStorage.channel ?? "amazon";

const CHANNEL_AD_TYPE_OPTIONS = {
  walmart: [
    AD_TYPES.SPONSORED_PRODUCT_WALMART,
    AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART,
    AD_TYPES.SPONSORED_BRAND_WALMART,
    AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART,
  ],
  google: [
    AD_TYPES.GOOGLE_SHOPPING_AD,
    AD_TYPES.GOOGLE_STORE_AD,
    AD_TYPES.GOOGLE_SHOPPING_AD_LOW_COHESIVE,
  ],
  amazon: [
    AD_TYPES.SPONSORED_PRODUCT,
    AD_TYPES.SPONSORED_BRAND_V2,
    AD_TYPES.SPONSORED_BRAND_VIDEO,
    AD_TYPES.SPONSORED_PRODUCT_AUTO,
    AD_TYPES.SPONSORED_DISPLAY_V2,
    AD_TYPES.CUSTOM,
  ],
};

const SCHEDULED_ACTION_STATE_OPTIONS = [
  { value: true, label: "With Scheduled Actions" },
  { value: false, label: "Without Scheduled Actions" },
];

export const AD_TYPE_FILTER =
  // Ad Type Filter
  {
    label: "Ad Type",
    id: "ad_type",
    format: "select",
    selectOptions: [
      {
        label: "All Types",
        value: "all",
      },
      ...Object.values(CHANNEL_AD_TYPE_OPTIONS[channel]),
    ],
    override: true,
    defaultValue: "all",
    operator: "=",
    filterFunction: (ad, filterValue) => {
      if (filterValue !== "all") {
        // Somewhere in processing filterValue coming back as a stringified #, cast compare value
        filterValue = parseInt(filterValue);

        let legacyTypeMatch = false;
        let ignoreBrandVideoAd = false;

        // Handle custom filter based on is_composite flag
        if (filterValue === AD_TYPES.CUSTOM.value && channel === "amazon") {
          return ad?.is_composite;
        }
        if (
          ad?.ad_type === AD_TYPES.SPONSORED_BRAND.value &&
          filterValue === AD_TYPES.SPONSORED_BRAND_V2.value
        ) {
          legacyTypeMatch = true;
        }
        if (
          ad?.ad_type === AD_TYPES.SPONSORED_BRAND_V2.value &&
          filterValue === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
          ["_SB_V_", "_SB_BV_"]?.includes(ad?.creative?.brand_ad_type)
        ) {
          legacyTypeMatch = true;
        }

        if (
          filterValue === AD_TYPES.SPONSORED_BRAND_V2.value &&
          ["_SB_V_", "_SB_BV_"]?.includes(ad?.creative?.brand_ad_type)
        ) {
          ignoreBrandVideoAd = true;
        }

        if (
          ad?.ad_type === AD_TYPES.SPONSORED_DISPLAY.value &&
          filterValue === AD_TYPES.SPONSORED_DISPLAY_V2.value
        ) {
          legacyTypeMatch = true;
        }

        return (
          (ad?.ad_type === filterValue || legacyTypeMatch) &&
          !ignoreBrandVideoAd
        );
      }
      return true;
    },
  };

export const AD_STATUS_FILTER = {
  label: "Ad Status",
  id: "ad_status",
  format: "select",
  selectOptions: [
    {
      label: "All Statuses",
      value: "all",
      clearable: false,
    },
    ...AD_STATUS_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (ad, filterValue) => {
    if (filterValue !== "all") {
      filterValue = parseInt(filterValue);

      // Below handles ad failure display taking precedence over intented status set by user
      const AD_FAILED_STATUS = 10;
      if (filterValue === AD_FAILED_STATUS) {
        return ad.ad_status === AD_FAILED_STATUS;
      }

      return ad.state === filterValue && ad.ad_status !== AD_FAILED_STATUS;
    }
    return true;
  },
};

const NTM_STATUS_OPTIONS = [
  { value: "enrolled", label: "Enrolled" },
  { value: "accept_bids", label: "Bids Accepted" },
];

export const NTM_STATUS_FILTER = {
  label: "NTM Ad Status",
  id: "ntm_ad_status",
  format: "select",
  selectOptions: [
    {
      label: "All Statuses",
      value: "all",
      clearable: false,
    },
    ...NTM_STATUS_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (ad, filterValue) => {
    if (filterValue === "enrolled") {
      return (
        ad.non_trellis_enrolled_ad === true &&
        ad.non_trellis_enrolled_ad_accept_bids === false
      );
    }
    if (filterValue === "accept_bids") {
      return ad.non_trellis_enrolled_ad_accept_bids === true;
    }
    return true;
  },
};

export const AD_SOURCE_FILTER = {
  label: "Ad Source",
  id: "goal",
  format: "select",
  selectOptions: [
    {
      label: "All Sources",
      value: "all",
      clearable: false,
    },
    ...AD_SOURCE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (ad, filterValue) => {
    if (filterValue !== "all") {
      // Below handles ad failure display taking precedence over intented status set by user
      if (filterValue === GOALS.IMPORTED_ADS.value) {
        return ad.ad_goal === filterValue;
      }

      return ad.ad_goal !== GOALS.IMPORTED_ADS.value;
    }
    return true;
  },
};

export const SCHEDULED_BUDGET_CHANGES_FILTER = {
  label: "Scheduled Actions",
  id: "scheduled_actions",
  format: "select",
  selectOptions: [
    {
      label: "All Ads",
      value: "all",
      clearable: false,
    },
    ...SCHEDULED_ACTION_STATE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "=",
  filterFunction: (ad, filterValue) => {
    if (filterValue !== "all") {
      // Below handles ad failure display taking precedence over intented status set by user
      if (filterValue) {
        return ad.scheduled_actions?.length;
      }

      return ad.scheduled_actions?.length === 0;
    }
    return true;
  },
};
