import axios from "axios";
import { getURLPrefix } from "utils/getUrlPrefix";
import { AMZ_CONNECT, AMZ_ONBOARD, PROFILE } from "../types";

const URL_PREFIX = getURLPrefix();

export const refreshAmzOnboardStatus = (
  dispatchUpdateAmzOnboardingStatus,
  accessToken,
  dispatchSetErrors,
  amzadv_code = null,
  code_callback = null
) => {
  var AuthHeader = "Bearer ".concat(accessToken);
  function refreshStatus() {
    axios
      .get(URL_PREFIX + "/amzonboarding/", {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((res) => {
        const amzOnboardingStatus = res.data;
        localStorage.setItem("AmzOnboard", JSON.stringify(amzOnboardingStatus));
        dispatchUpdateAmzOnboardingStatus(amzOnboardingStatus);
      })
      .catch((err) => {
        if (dispatchSetErrors) {
          dispatchSetErrors(err);
        }
      });
  }

  if (amzadv_code !== null) {
    axios
      .post(
        URL_PREFIX + "/api/amazonapi/",
        { amazon_authorization_code: amzadv_code },
        { params: {}, headers: { Authorization: AuthHeader } }
      )
      .then((res) => {
        if (res.status === 200) {
          refreshStatus();
          if (code_callback !== null) {
            code_callback();
          }
        }
      })
      .catch((err) => {
        if (err.response.data === "Failed to fetch tokens from amazon") {
          dispatchSetErrors({
            title: "Advertising Connect Timed Out",
            message:
              "Connection timeout while verifying your Amazon Advertising credentials. Please try again.",
          });
        } else if (err.response.data === "No profile IDs found") {
          dispatchSetErrors({
            title: "Invalid Credentials",
            message:
              "Your Amazon credentials are not associated with an Amazon Advertising Account. Please create an Amazon Advertising account to proceed. If you have recently launched your first advertising campaign, please allow up to 1 hour for your profile to be configured.",
          });
        } else {
          dispatchSetErrors({
            title: "Advertising Connect Failed",
            message:
              "Failed to get Amazon Advertising credentials. Please verify the account is set up for advertising.",
          });
        }
        refreshStatus();
      });
  } else {
    refreshStatus();
  }
};

export const initiateAmzAdvConnect = () => {
  return {
    type: AMZ_CONNECT,
    payload: true,
  };
};

export const completeAmzAdvConnect = () => {
  return {
    type: AMZ_CONNECT,
    payload: false,
  };
};

export const updateAmzOnboardingStatus = (amzOnboardingStatus) => {
  return {
    type: AMZ_ONBOARD,
    payload: amzOnboardingStatus,
  };
};

export const completeAmzProfileSelection = () => {
  return {
    type: PROFILE,
    payload: null,
  };
};
