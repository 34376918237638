import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

import { getURLPrefix } from "utils/getUrlPrefix";
import NoImage from "assets/images/utility/no_image.png";

let URL_PREFIX = getURLPrefix();

class ProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketPlace: this.props.marketPlace.marketPlace,
      productID: this.props.id,
      width: this.props.width === undefined ? "75px" : this.props.width,
      height: this.props.height === undefined ? "75px" : this.props.height,
      errorCount: 0,
    };
  }
  render() {
    return (
      <div
        id={
          this.props.divId !== undefined
            ? this.props.divId
            : "product" + this.props.id
        }
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = NoImage;
        }}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          width: this.state.width,
          height: this.state.height,
          background: `url(${
            this.props.id
              ? `${URL_PREFIX}/api/productimage/?product=${this.props.id}`
              : NoImage
          }) no-repeat center / contain, url(${NoImage}) no-repeat center / contain`,
          ...this.props.style,
        }}
        draggable="false"
      />
    );
  }
}

export const ProductImageURL = ({ url, width, height, style = null }) => {
  return (
    <div
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = NoImage;
      }}
      style={{
        userSelect: "none",
        WebkitUserSelect: "none",
        width: width,
        height: height,
        background: `url(${url}) no-repeat center / contain, url(${NoImage}) no-repeat center / contain`,
        style,
      }}
      draggable="false"
    />
  );
};

ProductImage.propTypes = {
  auth: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(withRouter(ProductImage));
