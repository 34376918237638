import React from "react";
import CenteredModal from "components/core/basic/CenteredModal";
import { AuthButton } from "components/core/basic/Button";
import { Row, Col } from "react-bootstrap";
import Checkbox from "components/core/basic/CheckBox";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { useQueryClient } from "react-query";
import api from "utils/api";

const CogsEditModal = ({
  showProductEditModal,
  setShowProductEditModal,
  productEditModalDetails,
  setProductEditModalDetails,
  marketPlace,
}) => {
  const queryClient = useQueryClient();

  const onUpdateProductDetails = async () => {
    const product = productEditModalDetails;
    await api.patch(`/merchandise/gvproducts/${product.id}/`, {
      landed_cost: parseFloat(product.landed_cost),
      shipping_cost: parseFloat(product.shipping_cost),
      vine_program_enrolled: product.vine_program_enrolled,
    });

    queryClient.invalidateQueries();

    setShowProductEditModal(false);
  };

  return (
    <CenteredModal
      show={showProductEditModal}
      closeModal={() => setShowProductEditModal(false)}
      title={"Edit Product Cost Details"}
      dialogClassName={"modal-50w"}
      style={{
        marginTop: "2rem",
      }}
    >
      <Row className="py-5">
        <Col xs={12} md={4} className="px-4">
          <FormGroupTile
            label="Landed Cost"
            type="formcontrol"
            decimalInput={true}
            stateName={"landed_cost"}
            handleChildFormElement={(key, value) => {
              setProductEditModalDetails({
                ...productEditModalDetails,
                landed_cost: value,
              });
            }}
            formControlClass="no-arrows text-center"
            defaultValue={productEditModalDetails.landed_cost}
            placeholder={`${
              MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
            }`}
          />
        </Col>
        <Col xs={12} md={4} className="px-4">
          <FormGroupTile
            label="Shipping Cost"
            type="formcontrol"
            decimalInput={true}
            stateName={"shipping_cost"}
            handleChildFormElement={(key, value) => {
              setProductEditModalDetails({
                ...productEditModalDetails,
                shipping_cost: value,
              });
            }}
            disabled={productEditModalDetails?.fulfillment_channel === "AFN"}
            formControlClass="no-arrows text-center"
            defaultValue={productEditModalDetails.shipping_cost}
            placeholder={
              productEditModalDetails?.fulfillment_channel === "AFN"
                ? "Fulfilled by Amazon"
                : `${
                    MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                  }`
            }
          />
        </Col>
        <Col xs={12} md={4} className="d-flex align-items-end pb-2 px-4">
          <Checkbox
            number={`${productEditModalDetails.id}-vineEnrolled`}
            label={"Enrolled In Vine Program"}
            checked={productEditModalDetails.vine_program_enrolled}
            disabled={false}
            onClick={(e) => {
              setProductEditModalDetails({
                ...productEditModalDetails,
                vine_program_enrolled:
                  !productEditModalDetails.vine_program_enrolled,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className="mt-5"
        >
          <AuthButton
            title={"Cancel"}
            onClick={() => setShowProductEditModal(false)}
            style={{ marginRight: "2rem" }}
            buttonStyle={"adplan_button"}
          />
          <AuthButton
            onClick={async () => {
              onUpdateProductDetails();
            }}
            buttonStyle={"adplan_button"}
            title={"Change"}
          />
        </Col>
      </Row>
    </CenteredModal>
  );
};

export default CogsEditModal;
