import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Row } from "react-bootstrap";

import ToolTips from "utils/toolTips";

class SingleStatsTile extends Component {
  render() {
    var border =
      this.props.border === "none"
        ? "none"
        : this.props.border === "disabled"
        ? "0 0 0 0 rgba(0,0,0,0.5)"
        : "0 0 0 0.2px rgba(0,0,0,0.25)";
    var valueAlign =
      this.props.valueAlign === undefined ? "center" : this.props.valueAlign;
    return (
      <div
        className={`card${this.props.disableWrap ? "" : " wrap"} ${
          border === "none"
            ? ""
            : this.props.borderClassOverrides
            ? this.props.borderClassOverrides
            : "border border-light border-1"
        }`}
        style={{
          borderRadius: "20px",
          boxShadow: border,
          // border: "1px solid #ccc",
        }}
      >
        <Row>
          <div
            className="header"
            style={{ marginTop: "1rem", marginBottom: "2rem" }}
          >
            <Typography
              className="title"
              variant="h5"
              style={{
                marginBottom: "1rem",
                textAlign: valueAlign,
                color: "#73706E",
                fontWeight: "500",
                padding: this.props.padding ? this.props.padding : "auto",
              }}
            >
              {this.props.statsText}
              {this.props.tooltipText ? (
                <ToolTips
                  toolTip={this.props.tooltipText}
                  position="top"
                  id={this.props.id}
                />
              ) : null}
            </Typography>
            <Typography
              className="title"
              variant="h3"
              style={{
                textAlign: valueAlign,
                color: "#403E3D",
                fontWeight: "300",
              }}
            >
              {this.props.statsValue}
            </Typography>
          </div>
        </Row>
      </div>
    );
  }
}

export default SingleStatsTile;
