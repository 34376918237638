import SignupLayout from "../layout/SignupLayout";

export const OnboardingRoutes = [
  {
    redirect: true,
    path: "/",
    layout: "/",
    name: "Sign Up",
    component: SignupLayout,
    application: "Admin",
  },
];
