import React, { useMemo } from "react";
import { Col } from "react-bootstrap";
import TEMPLATES from "./templates";
import { ProgramContextProvider, useProgramContext } from "./ProgramContext";

const InlineProgramForm = ({
  form,
  programTemplate,
  size = "lg",
  showHeaderFields = true,
  children,
}) => {
  const { engine } = useProgramContext();
  const TemplateComponent = useMemo(() => {
    const template = TEMPLATES.find(
      (t) => t.id === programTemplate && t.engine === engine
    );
    if (template?.defaultValues) {
      form.reset(template.defaultValues);
    }

    return template?.FormComponent || null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programTemplate]);

  let colSize = {
    xs: 12,
    lg: 10,
    xlg: 8,
  };
  if (["xs", "sm"].includes(size)) {
    colSize = {
      xs: 12,
    };
  }

  return (
    TemplateComponent && (
      <Col {...colSize}>
        <ProgramContextProvider
          engine={"pricing"}
          size={size}
          showHeaderFields={showHeaderFields}
        >
          <TemplateComponent {...form} />
          {children}
        </ProgramContextProvider>
      </Col>
    )
  );
};

export default InlineProgramForm;
