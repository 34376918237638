import React, { useCallback, useEffect, useRef, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { trellisPalette } from "./palettes";

const MAX_BRANDS = 5;

const TimelineView = ({
  selectBrand,
  timelineData,
  shelfStatsTotalData,
  share,
  homeBrand,
}) => {
  const echartRef = useRef();

  const [dates, series] = useMemo(() => {
    const key = {
      total: "share_of_shelf",
      organic: "org_share_of_shelf",
      sponsored: "share_of_voice",
    }[share];
    let dates = timelineData.map((entry) => entry.date);
    dates = [...new Set(dates)].reverse();
    const byBrand = timelineData.reduce((acc, entry) => {
      if (!acc[entry.brand]) {
        acc[entry.brand] = [];
      }
      acc[entry.brand].push(entry);
      return acc;
    }, {});
    const series = Object.entries(byBrand).map(([brand, entries]) => {
      const data = dates.map((date) => {
        const entry = entries.find((entry) => entry.date === date);
        return entry?.[key] || 0;
      });
      return { brand, data: data.map((d) => d.toFixed(2)) };
    });
    // Sort total data by share of shelf or voice and make sure the home brand is first
    const sortedTotalData = shelfStatsTotalData.sort((a, b) => {
      if (homeBrand === a.brand) return -1;
      if (homeBrand === b.brand) return 1;
      return b[key] - a[key];
    });
    const topN = sortedTotalData.slice(0, MAX_BRANDS);

    return [
      dates,
      topN.map(({ brand }) => {
        return [
          brand,
          ...(series.find(({ brand: b }) => b === brand)?.data || []),
        ];
      }),
    ];
  }, [shelfStatsTotalData, timelineData, share, homeBrand]);

  useEffect(() => {
    const echartInstance = echartRef.current?.getEchartsInstance();

    let newSeries = series.map((trend) => ({
      type: "line",
      name: trend[0],
      seriesLayoutBy: "row",
      emphasis: { focus: "series" },
      // Below option will control line breaks if no data provided for shown date
      connectNulls: false,
      data: trend.slice(1),
    }));

    echartInstance.clear();
    echartInstance.setOption({
      animation: false,
      legend: {
        show: true,
        bottom: "bottom",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#152748",
          },
        },
      },
      dataset: {
        source: ["Date"],
      },
      xAxis: { type: "category", data: dates },
      yAxis: {
        type: "value",
        name: "Share",
        position: "left",
        axisLabel: {
          formatter: "{value} %",
        },
      },
      grid: { top: "10%" },
      series: newSeries,

      color: trellisPalette,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, series, homeBrand]);

  const registerChartEvents = {
    click: useCallback((params) => {
      selectBrand(params);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  };

  return (
    <>
      <ReactEcharts
        option={{}}
        onEvents={registerChartEvents}
        style={{
          height: "470px",
          width: "100%",
          marginTop: "-70px",
        }}
        ref={echartRef}
      />
    </>
  );
};

export default TimelineView;
