import React, { useMemo, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { formatPercent } from "utils/formatNumber";
import { connect } from "react-redux";
import SummaryCard from "components/core/blocks/SummaryCard";
import Metric from "components/core/blocks/SummaryMetric";
import useSelectedMetrics from "./hooks/useSelectedMetrics";
import MultiMetricChart from "./MultiMetricChart";
import { saturateColor } from "utils/colors";
import { trellisPalette } from "../analytics/palettes";

const setShownMetrics = (key, columns) => {
  let options = localStorage.getItem("metricShowOptions");
  try {
    options = JSON.parse(options);
  } catch (e) {
    options = {};
  }
  return localStorage.setItem(
    "metricShowOptions",
    JSON.stringify({ ...options, [key]: columns })
  );
};

const getShownMetrics = (key, defaults) => {
  const options = localStorage.getItem("metricShowOptions");
  defaults = defaults || [];
  if (options) {
    try {
      return JSON.parse(options)?.[key] ?? defaults;
    } catch (e) {
      return defaults;
    }
  }
  return defaults;
};

const MetricsCard = ({
  id,
  title,
  titleElement,
  byline,
  data,
  metrics,
  initialSelectedMetric,
  children,
  small = false,
  targetLabel = "vs. Previous",
  loading = false,
  showLoadingSpinner = false,
  group = "day",
  showMetricsSelector = false,
  hideMetrics,
  defaultStateContent,
  status,
  inlineStatus,
  showAllMetrics = false,
  defaultTargetColor = false,
  selectedCategory = null,
  selectedAd = null,
  selectedProduct = null,
  datesFormatted = false,
  showAnnotations = false,
  user,
  channel,
}) => {
  const mobileStyles = useMediaQuery("(max-width:600px)");
  const { selectedMetrics, toggleMetric } = useSelectedMetrics(
    Array.isArray(initialSelectedMetric)
      ? initialSelectedMetric
      : [initialSelectedMetric],
    id
  );

  const chartMetrics = useMemo(() => {
    return metrics.reduce((acc, metric) => {
      acc.push(metric);
      if (metric?.prev_accessor) {
        acc.push({
          ...metric,
          id: `${metric.id}_prev`,
          isPrev: true,
          accessor: metric.prev_accessor,
          name: `${metric.name} (Previous)`,
          color: saturateColor(metric.color, 1.5),
        });
      }
      return acc;
    }, []);
  }, [metrics]);

  const [showMetrics, setShowMetrics] = useState(() => {
    const initial = showAllMetrics
      ? chartMetrics.map((metric) => metric.id)
      : chartMetrics
          .filter((m) => !m?.isPrev)
          .slice(0, 6)
          ?.map((metric) => metric.id);
    if (id) {
      return getShownMetrics(id, initial);
    }
    return initial;
  });

  const filteredMetrics = useMemo(() => {
    return chartMetrics?.filter(
      (metric) => showMetrics?.includes(metric.id) ?? false
    );
  }, [chartMetrics, showMetrics]);

  const targetFormatter = (v) => (v > 0 ? "+" : "") + formatPercent(v);

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  const onShowMetricsChange = (showMetrics) => {
    setShowMetrics(showMetrics);
    if (id) {
      setShownMetrics(id, showMetrics);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row
            className="flex-nowrap overflow-auto scrollbar pt-3 pb-1 mb-2 "
            hidden={hideMetrics}
          >
            {filteredMetrics
              .filter((m) => !m?.isPrev)
              .map((m) => {
                return (
                  <Col
                    xs={6}
                    lg={3}
                    xl={2}
                    key={m.id}
                    style={{
                      cursor: m.clickable === false ? "inherit" : "pointer",
                    }}
                    className="pr-4"
                    onClick={() => {
                      if (m.clickable === false) {
                        return;
                      }
                      toggleMetric(m.id);
                    }}
                  >
                    <Card className="mr-4 p-4 mb-3">
                      <Metric
                        size={"small"}
                        id={m.id}
                        toolTip={m.toolTip}
                        name={m.name}
                        value={m.overrideValue ?? m.actual}
                        target={m.target}
                        formatter={m.formatter}
                        targetFormatter={m?.targetFormatter ?? targetFormatter}
                        mobileStyles={mobileStyles}
                        showTarget={!!m.target}
                        lower={m?.lower}
                        targetColor={
                          defaultTargetColor
                            ? null
                            : m?.targetColor ??
                              ((m?.lower ? -1 * m.target : m.target) > 0
                                ? "#007000"
                                : "#d2222d")
                        }
                        legendColor={m.color}
                        noShadow={m.noShadow}
                        targetLabel={m.targetLabel || targetLabel}
                        selected={selectedMetrics.includes(m.id)}
                        orgType={user.organization_type}
                        channel={channel}
                      />
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
      <SummaryCard
        mobileStyle
        title={titleElement ? titleElement : title}
        rawTitleStyle={!!titleElement}
        byline={byline}
        status={
          <>
            {showMetricsSelector && (
              <div className="d-flex justify-content-end">
                {inlineStatus && inlineStatus}
                <Dropdown className={"noarrow"}>
                  <Dropdown.Toggle
                    variant="link"
                    className="text-decoration-none card-menu"
                    bsPrefix="link"
                    childBsPrefix="btn-link border-0 bg-white text-secondary"
                    id={`metric-selector-${title}`}
                  >
                    <i
                      className="fa fa fa-ellipsis-v"
                      style={{ fontSize: "1.5rem" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {chartMetrics
                      .filter((m) => !m?.isPrev)
                      .map((m, i) => (
                        <React.Fragment key={`${m.id}-${i}`}>
                          {m.id === "seperator" && <Dropdown.Divider />}
                          {m.id !== "seperator" && (
                            <div
                              role="menuitem"
                              className="py-1 d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <label
                                style={{
                                  marginLeft: "1rem",
                                  marginTop: "0.5rem",
                                  fontWeight: "normal",
                                  display: "flex",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{
                                    height: "unset",
                                    marginRight: "1rem",
                                  }}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    if (showMetrics.includes(m.id)) {
                                      onShowMetricsChange(
                                        showMetrics.filter((x) => {
                                          return (
                                            x !== m.id && x !== m.id + "_prev"
                                          );
                                        })
                                      );
                                    } else {
                                      toggleMetric(m.id, true);
                                      onShowMetricsChange([
                                        m.id,
                                        ...showMetrics,
                                      ]);
                                    }
                                  }}
                                  checked={showMetrics.includes(m.id)}
                                />
                                {m.name}
                              </label>
                              {m?.prev_accessor && (
                                <div
                                  style={{
                                    border: `1px solid ${trellisPalette[3]}`,
                                    borderRadius: "3px",
                                    fontSize: "1.1rem",
                                    fontStyle: "italic",
                                    fontWeight: "500",
                                    marginLeft: "0.5rem",
                                    padding: "0 0.25rem 0 0.25rem",
                                    height: "17px",
                                    backgroundColor: showMetrics.includes(
                                      m.id + "_prev"
                                    )
                                      ? trellisPalette[3] + "aa"
                                      : "#fff",
                                    color: showMetrics.includes(m.id + "_prev")
                                      ? "#fff"
                                      : trellisPalette[3],
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (showMetrics.includes(m.id + "_prev")) {
                                      onShowMetricsChange(
                                        showMetrics.filter((x) => {
                                          return x !== m.id + "_prev";
                                        })
                                      );
                                    } else {
                                      toggleMetric(m.id + "_prev", true);
                                      onShowMetricsChange([
                                        m.id + "_prev",
                                        ...showMetrics,
                                      ]);
                                    }
                                  }}
                                >
                                  vs
                                </div>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {status && status}
          </>
        }
      >
        {defaultStateContent ? (
          defaultStateContent
        ) : (
          <Row>
            {!small && (
              <Col xs={12}>
                {data && (
                  <div style={{ marginTop: mobileStyles ? "0rem" : "-3rem" }}>
                    <MultiMetricChart
                      handleLegendChange={handleLegendChange}
                      selectedMetrics={selectedMetrics}
                      selectedCategory={selectedCategory}
                      selectedAd={selectedAd}
                      selectedProduct={selectedProduct}
                      metrics={filteredMetrics}
                      data={data}
                      mobileStyles={mobileStyles}
                      size={small ? "small" : "normal"}
                      group={group}
                      loading={loading && showLoadingSpinner}
                      datesFormatted={datesFormatted}
                      showAnnotations={showAnnotations}
                    />
                  </div>
                )}
              </Col>
            )}
          </Row>
        )}
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </SummaryCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  user: state.user,
});

export default connect(mapStateToProps)(MetricsCard);
