import bottomArrows from "assets/images/background/bottom_arrows.png";
import bgImage from "assets/images/background/purple_gradient.png";
import trellislogo from "assets/images/logo/Trellis_Logo_white.png";
import status from "assets/images/onboarding/amazon_onboarding_timeline-01_spapi.jpg";
import success_tick from "assets/images/status/successful_tick_150x150.png";
import axios from "axios";
import { AuthButton } from "components/core/basic/Button";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Hidden, withStyles } from "@material-ui/core";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  initiateAmzAdvConnect,
  completeAmzAdvConnect,
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
  completeAmzProfileSelection,
} from "redux/actions/amzOnboardActions";
import { setCurrentUser } from "redux/actions/authActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { getAmazonAdvClientURL, getURLPrefix } from "utils/getUrlPrefix";
import checkOnboardRedirect from "components/custom/onboarding/OnboardUtils";
import TermsOfService from "components/custom/instructions/TermsOfService";
import SweetAlert from "react-bootstrap-sweetalert";
import HubspotCalendarModal from "../../core/blocks/HubspotCalendarModal";
import withMediaQuery from "hocs/withMediaQuery";
import OnboardingFooter from "./OnboardingFooter";

let URL_PREFIX = getURLPrefix();

const useStyles = (theme) => ({
  heading: {
    textAlign: "center",
    color: "#393939",
    fontSize: "34px",
    fontWeight: 700,
    marginTop: "5rem",
    padding: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
    },
  },
  paragraph: {
    marginTop: "2rem",
    fontSize: "20px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  successImage: {
    height: "150px",
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      height: "100px",
      width: "100px",
    },
  },
});

class AmazonAdvertising extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedAdvTokens: false,
      advOnboardCode: "",
      inProgressAdvOnboard: false,
      readingLocalStorage: false,
      userLayout: false,
      advProfileErrorMessage: "",
      regions: [],
      selectedProfile: { value: "" },
      profiles: [],
      showErrorModal: false,
    };

    this.handleCloseCalendarModal = this.handleCloseCalendarModal.bind(this);
  }

  componentDidMount() {
    if (window.location.href.includes("user")) {
      this.setState({ userLayout: true });
      if (
        localStorage.EditAmzAdv === undefined &&
        this.props.amz_onboard.amazon_advertising_access === "connected" &&
        this.props.amz_onboard.amazon_additional_information === "provided"
      ) {
        if (
          this.props.amz_onboard.amazon_spapi_access === "pending" &&
          this.props.amz_onboard.eu_amazon_spapi_access === "pending" &&
          this.props.amz_onboard.fe_amazon_spapi_access === "pending"
        ) {
          this.props.history.push("/user/amzspapi");
        } else {
          this.props.history.push("/user/amzsummary");
        }
      }
    } else {
      if (!this.state.selectedProfile["vendor"]) {
        let redirect = checkOnboardRedirect(this.props.amz_onboard);

        if (redirect !== null && redirect !== "/onboard/amzadv") {
          this.props.history.push(redirect);
        }
      }
    }

    if (localStorage.EditAmzMwsRegion) {
      localStorage.removeItem("EditAmzMwsRegion");
    }

    if (localStorage.marketPlaceSelected) {
      localStorage.removeItem("marketPlaceSelected");
      localStorage.setItem("Marketplace", "CA");
      this.clearDefaultMarketPlaces();
    }

    refreshAmzOnboardStatus(
      this.props.updateAmzOnboardingStatus,
      this.props.auth.tokens.access_token,
      this.props.setErrors
    );

    localStorage.removeItem("OnboardingPaid");
    localStorage.removeItem("OnboardingPaymentSkip");
    localStorage.removeItem("EditAmzAdv");
  }

  componentDidUpdate(previousProps) {
    if (this.state.profiles !== this.props.profiles) {
      this.setState({ profiles: this.props.profiles });
    }

    if (window.location.href.includes("user") && !this.state.userLayout) {
      this.setState({ userLayout: true });
    }

    if (this.props.errors && this.props.errors !== previousProps.errors) {
      this.setState({ showErrorModal: true });
    }
  }

  async onNext() {
    localStorage.setItem("AdvInfoSaved", "true");
    if (
      (this.props.count === 1 && this.props.profiles[1].vendor) ||
      this.state.selectedProfile["vendor"]
    ) {
      // Check vendor profile always set before fetching listings
      let profileAssignmentComplete =
        await this.assignCurrentProfileAndProceed();
      await this.fetchVendorListings();

      if (profileAssignmentComplete) {
        localStorage.removeItem("amzConnectAttempted");
        this.handleRedirectToDashboard();
      }
    }

    if (this.state.userLayout) {
      this.props.history.push("/user/amzsummary");
    } else {
      if (!this.props.isVendorProfile) {
        this.assignCurrentProfileAndProceed();
        this.props.history.push("/onboard/amzspapi");
      }
    }
  }

  async fetchVendorListings() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    axios
      .post(
        URL_PREFIX + "/api/mwsinfotask/",
        {},
        {
          params: {},
          headers: { Authorization: AuthStr },
        }
      )
      .then((res) => {
        if (
          res.data === "Processing SKU information and updated status" ||
          res.data === "No info to process"
        ) {
          refreshAmzOnboardStatus(
            this.props.updateAmzOnboardingStatus,
            this.props.auth.tokens.access_token,
            this.props.setErrors
          );
        }
      })
      .catch((err) => {});

    localStorage.removeItem("MWSCancelEdit");
    localStorage.removeItem("MWSInfoSaved");
    localStorage.removeItem("AdvCancelEdit");
    localStorage.removeItem("AdvInfoSaved");
    localStorage.removeItem("EditAmzMwsRegion");
    localStorage.removeItem("marketPlaceSelected");
  }

  clearDefaultMarketPlaces = () => {
    const AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    let currentDefaults = {};

    let data = {
      default_marketplace: currentDefaults,
    };

    axios
      .put(URL_PREFIX + "/api/userprofile/", data, {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .catch((err) => {
        console.table(err);
      });
  };

  setAmazonMWSToInProgress(cb) {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .post(
        URL_PREFIX + "/api/mwsinfo/",
        { amazon_mws_access: "in_progress" },
        {
          params: {},
          headers: { Authorization: AuthStr },
        }
      )
      .then((res) => {
        if (res.data === "Successfully marked Amazon MWS API as IN_PROGRESS.") {
          refreshAmzOnboardStatus(
            this.props.updateAmzOnboardingStatus,
            this.props.auth.tokens.access_token,
            this.props.setErrors
          );
          if (cb) {
            cb(true);
          }
        } else {
          if (cb) {
            cb(false);
          }
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false);
        }
      });
  }

  async assignCurrentProfileAndProceed() {
    var data = {};

    if (this.props.count === 1) {
      data["selected_profile"] = this.props.profiles[1].value;
      if (this.props.profiles[1].vendor) {
        data["type"] = "vendor";
        this.props.isVendor();
      } else {
        data["type"] = "seller";
      }
    } else {
      data["selected_profile"] = this.state.selectedProfile.value;
      if (this.state.selectedProfile["vendor"]) {
        data["type"] = "vendor";
        this.props.isVendor();
      } else {
        data["type"] = "seller";
      }
    }

    let res = await axios
      .post(URL_PREFIX + "/api/accounts/", data)
      .then((res) => {
        this.props.completeAmzProfileSelection(); // why do we need both here?
        this.props.completeAmzAdvConnect();
        this.props.setCurrentUser({
          current_profile: data["selected_profile"],
        });
        return true;
      });

    return res;
  }

  handleCloseCalendarModal() {
    this.setState({ openCalendarModal: false, showErrorModal: false });
  }

  handleChildFormElement = (id, value) => {
    if (id === "selectedAccount") {
      this.setState({
        selectedProfile: value,
      });
    }
  };

  onCancelEdit() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    var data = {
      stage: "adv",
      action: "cancel",
    };

    axios
      .post(URL_PREFIX + "/api/editamzonboard/", data, {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        refreshAmzOnboardStatus(
          this.props.updateAmzOnboardingStatus,
          this.props.auth.tokens.access_token,
          this.props.setErrors
        );
        localStorage.setItem("AdvCancelEdit", "true");
        if (res.status === 204) {
          if (this.state.userLayout) {
            this.props.history.push("/user/amzsummary");
          } else {
            this.props.history.push("/onboard/amzsummary");
          }
        }
      })
      .catch((err) => {});
  }

  handleRedirectToDashboard() {
    this.setOnboardingComplete((success) => {
      if (success) {
        localStorage.setItem(
          "onboardRedirectTo",
          JSON.stringify("/user/dashboard")
        );
        this.props.history.push("/user/dashboard");
      } else {
        this.setState({
          errorMessage: "Failed to reach dashboard, please contact Trellis.",
        });
      }
    });
  }

  setOnboardingComplete(cb) {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    axios
      .post(
        URL_PREFIX + "/api/mwsinfotask/",
        {},
        {
          params: {},
          headers: { Authorization: AuthStr },
        }
      )
      .then((res) => {
        if (
          res.data === "Processing SKU information and updated status" ||
          res.data === "No info to process"
        ) {
          refreshAmzOnboardStatus(
            this.props.updateAmzOnboardingStatus,
            this.props.auth.tokens.access_token,
            this.props.setErrors
          );
          if (cb) {
            cb(true);
          }
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false);
        }
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.showErrorModal && !this.state.openCalendarModal && (
          <>
            <SweetAlert
              onConfirm={() => {
                this.setState({ showErrorModal: false, errors: [] });
              }}
              title={this.props.errors[0].title}
              style={
                this.props.mediaQuery
                  ? {
                      display: "block",
                      overflowY: "auto",
                      margin: "auto",
                      paddingBottom: "2rem",
                      width: "80%",
                      minWidth: "300px",
                      left: "10%",
                      top: "25%",
                      right: "10%",
                      bottom: "25%",
                      borderRadius: "10px",
                    }
                  : {
                      display: "block",
                      overflowY: "auto",
                      margin: "auto",
                      paddingBottom: "2rem",
                      width: "40%",
                      minWidth: "380px",
                      left: "30%",
                      top: "30%",
                      right: "30%",
                      bottom: "30%",
                      borderRadius: "10px",
                    }
              }
              confirmBtnText={"OK"}
            >
              <div style={{ textAlign: "center", padding: "2rem" }}>
                <p>{this.props.errors[0].message}</p>

                <div
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#D91266",
                  }}
                  onClick={() => {
                    this.setState({
                      errors: [],
                      showErrorModal: false,
                      openCalendarModal: true,
                    });

                    this.props.clearErrors();
                  }}
                >
                  <p style={{ marginTop: "2rem", color: "#D91266" }}>
                    Need help setting up your account. Click here to book a time
                    with one of our Amazon experts.
                  </p>
                </div>
              </div>
            </SweetAlert>
          </>
        )}
        <Grid container style={{ marginLeft: "0px", marginRight: "0px" }}>
          <HubspotCalendarModal
            open={this.state.openCalendarModal}
            onClose={this.handleCloseCalendarModal}
          />
          <Grid
            item
            xs={12}
            md={10}
            style={{
              backgroundColor: "#ffffff",
              height: "100vh",
              paddingLeft: "0px",
              paddingRight: "0px",
              overflowX: "hidden",
              position: "relative",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Grid item style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.heading}>Amazon Onboarding</div>
                    </Grid>
                  </Grid>

                  {this.state.userLayout ? null : (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", marginTop: "5rem" }}
                      >
                        <img
                          src={status}
                          alt="Status"
                          width="90%"
                          style={{ maxWidth: "1100px" }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container className="text-center">
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className="text-center"
                        style={{
                          marginTop: "2rem",
                          fontSize: "26px",
                          fontWeight: 600,
                        }}
                      >
                        Connect Amazon Advertising Account
                      </Grid>
                    </Grid>

                    {!(
                      this.props.amz_onboard.amazon_advertising_access ===
                        "connected" || this.state.userLayout
                    ) ||
                    (this.state.profiles.length < 1 &&
                      localStorage.amzConnectAttempted) ? (
                      <>
                        <Grid container style={{ marginTop: "2rem" }}>
                          <Grid
                            item
                            xs={12}
                            className={`text-center ${classes.paragraph}`}
                          >
                            Start your self-driving PPC and free 60 day
                            historical analysis
                            <br />
                            by clicking the button below
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className="text-center"
                          style={{
                            margin: "5rem 1rem 0",
                            justifyContent: "center",
                          }}
                        >
                          <a href={getAmazonAdvClientURL()}>
                            <AuthButton
                              title="Connect to Amazon Advertising"
                              buttonStyle="adplan_button"
                              disabled={
                                this.props.disableConnectButton ||
                                (this.props.amz_onboard
                                  .amazon_advertising_access === "connected" &&
                                  this.state.profiles.length > 0)
                              }
                              onClick={() =>
                                localStorage.setItem(
                                  "amzConnectAttempted",
                                  true
                                )
                              }
                            />
                          </a>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container item xs={12}>
                          <div
                            className="text-center"
                            style={{
                              margin: "2rem auto 0",
                              fontSize: "20px",
                              fontWeight: 500,
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            Successfully Connected Amazon Advertising Account
                          </div>
                        </Grid>

                        <Grid container>
                          <div className="wrap" style={{ marginTop: "2rem" }}>
                            <h4 className="text-center">
                              <img
                                src={success_tick}
                                alt="Connected Advertising Account"
                                className={`center ${classes.successImage}`}
                              />
                            </h4>
                          </div>
                        </Grid>
                      </>
                    )}

                    {this.props.amz_onboard.amazon_advertising_access ===
                      "connected" && this.props.count > 1 ? (
                      <>
                        <Grid
                          container
                          className="wrap"
                          style={{ marginTop: "2rem" }}
                        >
                          <div
                            className="text-center"
                            style={{
                              marginTop: "2rem",
                              fontSize: "20px",
                              fontWeight: 500,
                            }}
                          >
                            Select a profile below
                          </div>
                        </Grid>

                        <Grid container className="wrap">
                          <Grid item xs={11} sm={4} md={3}>
                            <div
                              style={{
                                marginTop: "2rem",
                              }}
                            >
                              <FormGroupTile
                                type="select"
                                stateName={"selectedAccount"}
                                handleChildFormElement={
                                  this.handleChildFormElement
                                }
                                defaultValue={this.state.selectedProfile}
                                disabled={false}
                                options={this.props.profiles}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container className="wrap">
                          <Grid item md={8}>
                            <div
                              className="text-center"
                              style={{
                                marginTop: "1rem",
                                fontSize: "13px",
                                fontWeight: 400,
                              }}
                            >
                              More profiles can be added later on
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>

                  <Grid container>
                    <Grid
                      container
                      className="text-center"
                      style={{ marginTop: "5rem", justifyContent: "center" }}
                    >
                      {false ? (
                        <></>
                      ) : this.state.userLayout ? (
                        <AuthButton
                          title="Go to summary page"
                          buttonStyle="adplan_button"
                          onClick={(e) =>
                            this.props.history.push("/user/amzsummary")
                          }
                        />
                      ) : (
                        <AuthButton
                          title="Next"
                          buttonStyle="adplan_button"
                          disabled={
                            !(
                              this.props.amz_onboard
                                .amazon_advertising_access === "connected"
                            ) ||
                            (this.state.selectedProfile.value === "" &&
                              this.props.count > 1) ||
                            this.state.profiles.length < 1
                          }
                          onClick={(e) => this.onNext()}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
            <OnboardingFooter />
          </Grid>
          {this.state.userLayout ? null : (
            <Hidden smDown>
              <Grid
                item
                md={2}
                style={{
                  backgroundColor: "#2E0054",
                  height: "100vh",
                  overflow: "hidden",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <Grid
                  item
                  md={12}
                  style={{
                    backgroundColor: "#2E0054",
                    height: "100vh",
                    overflow: "hidden",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    position: "relative",
                  }}
                >
                  <div
                    md={12}
                    style={{
                      backgroundImage: "url(" + bgImage + ")",
                      maxWidth: "100%",
                      width: "100%",
                      height: "100vh",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      overflow: "hidden",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        style={{ textAlign: "center", marginTop: "6rem" }}
                      >
                        <img
                          src={trellislogo}
                          alt="Trellis Logo"
                          width="170px"
                          height="50px"
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{
                          left: "-50px",
                          bottom: "1rem",
                          position: "absolute",
                        }}
                      >
                        <img src={bottomArrows} alt="Bottom Arrows" />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          )}
        </Grid>
        {this.props.user.agreementStatus === null ||
        this.props.user.agreementStatus === "not_agreed" ? (
          <TermsOfService />
        ) : null}
      </>
    );
  }
}

AmazonAdvertising.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  amz_onboard: state.amz_onboard,
  errors: state.errors,
  user: state.user,
});

const mapDispatchToProps = {
  completeAmzProfileSelection,
  updateAmzOnboardingStatus,
  initiateAmzAdvConnect,
  completeAmzAdvConnect,
  setErrors,
  clearErrors,
  setCurrentUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withStyles(useStyles)(
      withMediaQuery("(max-width:1000px)")(AmazonAdvertising)
    )
  )
);
