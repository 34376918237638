import React, { useMemo } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useFetch } from "hooks/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMap from "highcharts/modules/map";
import mapDataUSA from "@highcharts/map-collection/countries/us/us-all.geo.json";
import { formatPercent, formatNumber } from "utils/formatNumber";

HighchartsMap(Highcharts);

const SalesMapUSA = ({
  start_date,
  end_date,
  marketPlace,
  productId,
  categoryId,
}) => {
  const filters = {
    start_date: start_date,
    end_date: end_date,
    mp: marketPlace.marketPlace,
  };

  if (productId) {
    filters.product_id = productId;
  }
  if (categoryId) {
    filters.category_id = categoryId;
  }

  const {
    isSuccess: mapSuccess,
    data: mapData,
    isLoading: mapIsLoading,
  } = useFetch(
    [
      "category_report",
      marketPlace.marketPlace,
      categoryId,
      productId,
      start_date,
      end_date,
    ],
    `/api/data_report/zip_code_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { mapOptions, statePercentage } = useMemo(() => {
    if (!mapSuccess || mapIsLoading) {
      return {};
    }

    let totalUnits = 0;
    for (let i = 0; i < mapData.length; i++) {
      totalUnits += mapData[i].value;
    }
    let statePercentage = [];
    for (let i = 0; i < mapData.length; i++) {
      statePercentage.push({
        label: mapData[i]["hc-key"].slice(3, 5).toUpperCase(),
        units: mapData[i].value,
        per: mapData[i].value / totalUnits,
      });
    }
    // Sort by percentage descending
    statePercentage.sort((a, b) => b.per - a.per);

    // Slice the top 10
    statePercentage = statePercentage.slice(0, 7);

    const mapOptions = {
      title: {
        text: null,
      },
      colorAxis: {
        min: 1,
        type: "logarithmic",
        minColor: "#EEEEFF",
        maxColor: "#330057",
        stops: [
          [0, "#EFEFFF"],
          [0.67, "#c43066"],
          [1, "#330057"],
        ],
      },
      series: [
        {
          data: mapData,
          mapData: mapDataUSA,
          joinBy: "hc-key",
          name: "Units Sold",
          states: {
            hover: {
              color: "#a4edba",
            },
          },
        },
      ],
    };

    return { mapOptions, statePercentage };
  }, [mapData, mapIsLoading, mapSuccess]);

  return (
    <Row>
      {mapIsLoading && (
        <Col xs={12} xl={12}>
          <div className="text-center">
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          </div>
        </Col>
      )}

      {!mapIsLoading && (
        <>
          <Col xs={12} xl={8}>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"mapChart"}
              options={mapOptions}
            />
          </Col>
          <Col xs={12} xl={4}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>State</th>
                  <th>Units</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {statePercentage.map((state) => (
                  <tr key={state.label}>
                    <td className="text-center">{state.label}</td>
                    <td className="text-center">{formatNumber(state.units)}</td>
                    <td className="text-center">{formatPercent(state.per)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </>
      )}
    </Row>
  );
};

export default SalesMapUSA;
