import { trellisPalette } from "components/custom/analytics/palettes";
import React from "react";
import getMetricSpec from "../metrics";
import { scaleLinear } from "d3-scale";
import KeywordCell from "components/custom/category/advertisements/KeywordCell";
import { METRIC_GROUPING_LOOKUP } from "../tables/metricsByEntity";

const generateRanges = (accessor, data) => {
  const numberOfRanges = 5;
  const rangeSourceData = data
    .map((row) => accessor(row))
    .filter((d) => d !== null && d !== undefined)
    .sort((a, b) => a - b);
  if (rangeSourceData.length === 0) {
    return [];
  }

  let x = scaleLinear().domain([
    rangeSourceData[0],
    rangeSourceData[rangeSourceData.length - 1],
  ]);

  const ticks = x.ticks(numberOfRanges);
  let ceiling = rangeSourceData[rangeSourceData.length - 1];
  if (Math.abs(ceiling) < 1) {
    const sigDigit = Math.pow(10, Math.ceil(-Math.log10(ceiling)));
    ceiling = Math.ceil(ceiling * sigDigit) / sigDigit;
  } else {
    ceiling = Math.ceil(ceiling);
  }
  const ret = ticks.map((tick, i) => {
    return i < ticks.length - 1 ? [tick, ticks[i + 1]] : [tick, ceiling];
  });
  return ret.map((range) => [
    ...range,
    rangeSourceData.filter((d) => d >= range[0] && d < range[1]).length,
  ]);
};

export const generateFilterMarkup = (
  filter,
  onChange,
  formatter,
  ranges,
  type = "ranges",
  options = {}
) => {
  if (type === "select") {
    const { selectOptions } = options;

    return (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{
          width: "100%",
          alignSelf: "flex-start",
        }}
        value={filter ? filter.value : "all"}
      >
        {selectOptions.map((o) => (
          <option value={o.value} key={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    );
  }
  return (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: "100%" }}
      value={filter ? filter.value : "all"}
    >
      <option value="all">Show All</option>
      {ranges &&
        ranges?.map((range) => {
          const [lowerBound, upperBound, cnt] = range;
          const key = JSON.stringify(range);
          return (
            <option key={key} value={key}>
              {formatter(lowerBound)} - {formatter(upperBound)} ({cnt})
            </option>
          );
        })}
    </select>
  );
};

const filterRanges = (filter, value) => {
  if (filter.value === "all") {
    return true;
  }
  const [start, end] = JSON.parse(filter.value);
  return value >= start && value < end;
};

const buildMetricColumn = (
  marketPlace,
  data,
  id,
  compareId,
  options,
  customFilters,
  hideCompareValue
) => {
  const { hideFilter, filterType, selectOptions } = options ?? {};
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  let compare = getMetricSpec(marketPlace, compareId, options?.compare);

  // Below looks a bit funny, but allows overrides metric definitions to be shaped the same with all option params being processed
  if (options?.metric) {
    options = { ...options, ...options.metric.options };
  }

  if (!compare.format) {
    compare = null;
  }

  const width = options?.width;

  const accessor = metric.accessor || ((d) => d[id]);
  const accessorCmp = compare
    ? compare.accessor || ((d) => d[compareId])
    : null;

  // Pending accept compare stats from sources outside of data report to match styles
  if (options?.overrideCompare) {
    const { overrideCompareValue, overrideCompareFormatter } = options;
    compare = {
      formatter: overrideCompareFormatter,
      value: overrideCompareValue,
    };
  }

  return {
    Header: options?.columnTitle || metric.name,
    id,
    accessor,
    checked: options?.checked ?? true,
    maxWidth: options?.maxWidth,
    minWidth: options?.minWidth,
    isStatic: options?.isStatic,
    format: options?.format ? options.format : metric.format,
    width,
    height: 30,
    toolTip: metric.toolTip ?? options?.toolTip,
    className: options?.className ?? "text-end",
    headerClassName: options?.className ?? "text-end",
    selectOptions: selectOptions ?? [],
    filterType: filterType,
    grouping: METRIC_GROUPING_LOOKUP[metric.id ?? id],
    accessorId: metric.accessorId,
    Cell: (props) => {
      let value;

      try {
        value = accessor(props.row._original);
      } catch (e) {
        console.error(
          "Error in accessor. You may be using the wrong ids:",
          id,
          compareId
        );
        throw e;
      }
      if (props.row._original?.isStatsLoading && value === undefined) {
        return (
          <>
            <div
              style={{
                height: "1.3rem",
                maxWidth: "50px",
                marginLeft: "auto",
                marginRight: "5px",
                backgroundColor: "#ddd",
                borderRadius: "0.5rem",
              }}
            />
            {compare && (
              <div
                style={{
                  height: "1.3rem",
                  maxWidth: "40px",
                  marginTop: "0.5rem",
                  marginLeft: "auto",
                  marginRight: "5px",
                  backgroundColor: "#ddd",
                  borderRadius: "0.5rem",
                }}
              />
            )}
          </>
        );
      }
      const fmt = options?.formatter ? options.formatter : metric.formatter;

      if (options?.overflowCell) {
        return <KeywordCell value={fmt(value)} />;
      }
      if (options?.showBlank) {
        return (
          <div>
            {fmt(value)}
            <br />
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              -
            </span>
          </div>
        );
      }

      if ((options?.showDot ?? true) && compare && !hideCompareValue) {
        const valueCmp = options?.overrideCompare
          ? typeof options?.overrideCompareAccessor === "function"
            ? options?.overrideCompareAccessor(props.row._original)
            : options?.overrideCompare
          : accessorCmp
          ? accessorCmp(props.row._original) ?? ""
          : "";
        const fmtCmp = compare.formatter;
        const isNegative = options?.isNegative ?? false;
        const noColor = options?.noColor ?? false;
        let color = "#666";
        if (!noColor && valueCmp > 0) {
          color = isNegative ? trellisPalette[17] : trellisPalette[12];
        }
        if (!noColor && valueCmp < 0) {
          color = isNegative ? trellisPalette[12] : trellisPalette[17];
        }
        return (
          <div>
            {fmt(value)}
            <br />
            <span
              style={{
                color,
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {fmtCmp(valueCmp)}
            </span>
          </div>
        );
      }
      return (
        <div
          className={
            options?.cellContainerClasses
              ? options?.cellContainerClasses
              : `d-flex justify-content-end align-items-center h-100 ${
                  options?.cellCustomClasses
                    ? options?.cellCustomClasses(id, value, props.row)
                    : ""
                }`
          }
        >
          <span>{fmt(value)}</span>
        </div>
      );
    },
    style: { height: "60px", ...(options?.additionalStyles ?? {}) },
    filtered: true,
    filterMethod: (filter, row) => {
      const value = accessor(row._original);

      if (filterType === "select") {
        if (filter.value === "all") {
          return true;
        }

        if (value === null || value === undefined) {
          return false;
        }

        // Below is a bit odd, somewhere in processing if the filter value of null is passed in, the value gets overwritten by the option label
        if (filter.value === "null") {
          return value === null;
        }

        if (!value) {
          return false;
        }

        return value?.toLowerCase() === filter.value?.toLowerCase();
      }

      return filterRanges(filter, value);
    },
    Filter: ({ filter, onChange }) => {
      if (hideFilter) {
        return null;
      }

      if (filterType === "select") {
        return generateFilterMarkup(filter, onChange, null, null, "select", {
          selectOptions: [
            { value: "all", label: "Show All" },
            ...selectOptions,
          ],
        });
      }

      const ranges = generateRanges(accessor, data ?? []);
      return generateFilterMarkup(filter, onChange, metric.formatter, ranges);
    },
  };
};

export default buildMetricColumn;
