import { useFetch } from "hooks/api";
import React, { useState } from "react";
import { connect } from "react-redux";
import CenteredModal from "../basic/CenteredModal";
import { AuthButton } from "components/core/basic/Button";
import { Row, Col } from "react-bootstrap";
import FormGroupTile from "../basic/FormGroupTile";
import { useMemo } from "react";
import api from "utils/api";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import {
  handleDefaultMarketplace,
  handleProfileChangeRedirects,
} from "utils/handleDefaultMarketplace";

import useOrgReduxState from "hooks/useOrgReduxState";

const OrgSwitcher = ({
  user,
  overrideModalButton = null,
  refetchOnOrgChange = true,
}) => {
  const mobileStyle = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [org, setSelectedOrg] = useState(() => ({
    label: user.organization_name,
    value: user.organization_id,
  }));

  const { updateOrgReduxState } = useOrgReduxState();

  const changeOrg = async (id) => {
    await api.put("/api/manage/userprofile/", {
      org: id,
    });

    if (refetchOnOrgChange) {
      updateOrgReduxState();
    }

    return;
  };

  const { data } = useFetch(
    ["manage-orgs"],
    "/api/manage/organizations/",
    { page_size: 5000 },
    { select: (d) => d.data, enabled: showModal }
  );
  const orgOptions = useMemo(() => {
    return (
      data?.results?.map((d) => ({
        value: d.id,
        label: `${d.name} (${d.id})`,
      })) ?? []
    );
  }, [data]);
  return (
    <>
      {showModal && (
        <CenteredModal
          show={showModal}
          closeModal={() => setShowModal(false)}
          title={"Change Organizations"}
          dialogClassName={mobileStyle ? "modal-90w" : "modal-50w"}
          style={{
            marginTop: "2rem",
          }}
        >
          <Row>
            <FormGroupTile
              type="select"
              multi={false}
              label={"Organizations"}
              options={orgOptions}
              defaultValue={org}
              handleChildFormElement={(_, v) => setSelectedOrg(v)}
            />
          </Row>
          <Row>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="mt-5"
            >
              <AuthButton
                title={"Cancel"}
                onClick={() => setShowModal(false)}
                style={{ marginRight: "2rem" }}
                buttonStyle={"adplan_button"}
              />
              <AuthButton
                onClick={async () => {
                  await changeOrg(org.value);
                  await handleDefaultMarketplace();
                  handleProfileChangeRedirects(history);
                  setShowModal(false);
                  window.location.reload();
                }}
                buttonStyle={"adplan_button"}
                title={"Change"}
              />
            </Col>
          </Row>
        </CenteredModal>
      )}
      <div
        style={
          overrideModalButton
            ? {}
            : {
                width: "100%",
                height: "100%",
                textAlign: "left",
              }
        }
        onClick={() => setShowModal(!showModal)}
      >
        {overrideModalButton ? overrideModalButton : "Switch Profiles"}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(OrgSwitcher);
