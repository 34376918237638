import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import { metricColors } from "components/custom/analytics/palettes";

const IMPRESSIONS_AXIS = 2;
const CLICK_AXIS = 3;
const UNITS_AXIS = 4;
const SERVING_HOURS_AXIS = 5;
const SALES_AXIS = 6;
const PERCENT_AXIS = 7;
const ROI_AXIS = 8;
const BSR_AXIS = 9;
const PRICE_AXIS = 10;
const RATINGS_AXIS = 11;
const RATINGS_TOTAL_AXIS = 12;
const SCORE_AXIS = 13;
const BID_AXIS = 14;
const RATE_AXIS = 15;
const PER_PAGE_VIEW = 16;

export const metrics = [
  {
    id: "enroll_date",
    name: "Enroll Date",
    color: metricColors.sales[0],
    accessor: (d) => d?.enroll_date,
    accessorId: "enroll_date",
    format: "date",
    axis: SALES_AXIS,
    toolTip: "The date the product was enrolled",
  },
  {
    id: "sales",
    name: "Sales",
    color: metricColors.sales[0],
    accessor: (d) => d?.total_sales,
    prev_accessor: (d) => d?.total_sales_prev,
    accessorId: "total_sales",
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total sales",
  },
  {
    id: "target_sales",
    name: "Tgt Sales",
    color: metricColors.sales[0],
    accessor: (d) => d?.target_sales,
    prev_accessor: (d) => d?.target_sales_prev,
    accessorId: "target_sales",
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total sales",
  },
  {
    id: "sales_percent",
    name: "Sales %",
    color: metricColors.sales[0],
    accessor: (d) => d?.total_sales_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "sales_delta",
    name: "Sales (+/-)",
    color: metricColors.sales[0],
    accessor: (d) => d?.total_sales_delta,
    format: "currency",
    incremental: true,
    axis: SALES_AXIS,
  },
  {
    id: "sales_expcectations",
    name: "Sales Expectations",
    color: metricColors.sales[0],
    accessor: (d) => d?.sales_expcectations,
    accessorId: "sales_expcectations",
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "Expected Sales",
  },
  {
    id: "sales_expectations_percent",
    name: "Sales %",
    color: metricColors.sales[0],
    accessor: (d) => d?.sales_expcectations_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "cost",
    name: "Cost",
    color: metricColors.cost[0],
    accessor: (d) => d?.total_cost,
    prev_accessor: (d) => d?.total_cost_prev,
    accessorId: "total_cost",
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total costs, including ad spend and discounts",
  },
  {
    id: "cost_percent",
    name: "Cost %",
    color: metricColors.cost[0],
    accessor: (d) => d?.total_cost_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "roms",
    name: "ROMS",
    color: metricColors.roms[0],
    accessor: (d) => d?.roms,
    prev_accessor: (d) => d?.roms_prev,
    format: "number",
    chartType: "line",
    axis: ROI_AXIS,
    toolTip:
      "The return on merchandising spend. The total sales divided by the combined ad spend and discounts from deals",
  },
  {
    id: "roms_delta",
    name: "ROMS %",
    color: metricColors.roms[0],
    accessor: (d) => d?.roms_delta,
    format: "number",
    incremental: true,
    chartType: "line",
    axis: ROI_AXIS,
  },
  {
    id: "units",
    name: "Units",
    color: metricColors.units[0],
    accessor: (d) => d?.total_units,
    prev_accessor: (d) => d?.total_units_prev,
    accessorId: "total_units",
    format: "number",
    axis: UNITS_AXIS,
    chartType: "column",
    toolTip: "The total units sold",
  },
  {
    id: "target_units",
    name: "Tgt Units",
    color: metricColors.units[10],
    accessor: (d) => d?.target_units,
    accessorId: "target_units",
    format: "number",
    axis: UNITS_AXIS,
    chartType: "column",
  },
  {
    id: "total_orders",
    name: "Total Orders",
    color: metricColors.units[0],
    accessor: (d) => d?.total_order_count,
    prev_accessor: (d) => d?.total_order_count_prev,
    accessorId: "total_order_count",
    format: "number",
    axis: UNITS_AXIS,
    chartType: "column",
    toolTip: "The total number of orders",
  },
  {
    id: "total_order_count_percent",
    name: "Total Orders %",
    color: metricColors.units[0],
    accessor: (d) => d?.total_order_count_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "units_expectations",
    name: "Units Expectations",
    color: metricColors.units[0],
    accessor: (d) => d?.units_expectations,
    accessorId: "units_expectations",
    format: "number",
    axis: UNITS_AXIS,
    chartType: "column",
    toolTip: "The total expected units",
  },
  {
    id: "units_percent",
    name: "Units %",
    color: metricColors.units[0],
    accessor: (d) => d?.total_units_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "units_delta",
    name: "Units (+/-)",
    color: metricColors.units[0],
    accessor: (d) => d?.total_units_delta,
    format: "number",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "units_expectations_percent",
    name: "Units %",
    color: metricColors.units[0],
    accessor: (d) => d?.units_expectations_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "margin",
    name: "Margin",
    color: metricColors.margin[0],
    accessor: (d) => d?.margin,
    prev_accessor: (d) => d?.margin_prev,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The percentage of profit after all costs",
  },
  {
    id: "margin_delta",
    name: "Margin %",
    color: metricColors.margin[0],
    accessor: (d) => d?.margin_delta,
    format: "percent",
    incremental: true,
    chartType: "line",
    axis: PERCENT_AXIS,
  },
  {
    id: "margin_wo_adspend",
    name: "Margin w/o Ad Spend",
    color: metricColors.margin[0],
    accessor: (d) => d?.margin_wo_adspend,
    rev_accessor: (d) => d?.margin_prev_prev,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The percentage of profit after all COGS",
  },
  {
    id: "target_margin",
    name: "Target Margin",
    color: metricColors.margin[0],
    accessor: (d) => d?.target_margin,
    rev_accessor: (d) => d?.target_margin,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The percentage of profit after all COGS",
  },
  {
    id: "margin_wo_adspend_delta",
    name: "Margin w/o Ad Spend %",
    color: metricColors.margin[0],
    accessor: (d) => d?.margin_wo_adspend_delta,
    format: "percent",
    incremental: true,
    chartType: "line",
    axis: PERCENT_AXIS,
  },
  {
    id: "margin_expcectations",
    name: "Margin Expectations",
    color: metricColors.sales[0],
    accessor: (d) => d?.margin_expcectations,
    accessorId: "margin_expcectations",
    format: "percent",
    axis: SALES_AXIS,
    toolTip: "Expected Sales",
  },
  {
    id: "margin_expectations_percent",
    name: "Margin %",
    color: metricColors.sales[0],
    accessor: (d) => d?.margin_expcectations_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "ad_sales",
    name: "Ad Sales",
    color: metricColors.sales[4],
    accessor: (d) => d?.ad_sales ?? 0,
    prev_accessor: (d) => d?.ad_sales_prev ?? 0,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total sales from ads",
  },
  {
    id: "ad_sales_percent",
    name: "Ad Sales %",
    color: metricColors.sales[4],
    accessor: (d) => d?.ad_sales_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "ad_sales_same_sku",
    name: "Same SKU Ad Sales",
    color: metricColors.sales[4],
    accessor: (d) => d?.ad_sales_same_sku ?? 0,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total sales from ads",
  },
  {
    id: "ad_sales_same_sku_percent",
    name: "Same SKU Ad Sales %",
    color: metricColors.sales[4],
    accessor: (d) => d?.ad_sales_same_sku_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "ad_spend",
    name: "Ad Spend",
    color: metricColors.cost[1],
    accessor: (d) => d?.cost ?? 0,
    prev_accessor: (d) => d?.cost_prev ?? 0,
    accessorId: "cost",
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total ad spend",
  },
  {
    id: "ad_spend_percent",
    name: "Ad Spend %",
    color: metricColors.cost[1],
    accessor: (d) => d?.cost_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "clicks",
    name: "Clicks",
    color: metricColors.metric1[0],
    accessor: (d) => d?.clicks ?? 0,
    prev_accessor: (d) => d?.clicks_prev ?? 0,
    chartType: "column",
    format: "number",
    axis: CLICK_AXIS,
    toolTip: "The total clicks on ads",
  },
  {
    id: "clicks_percent",
    name: "Clicks %",
    color: metricColors.metric1[0],
    accessor: (d) => d?.clicks_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "troas",
    name: "TROAS",
    color: metricColors.roms[3],
    accessor: (d) => d?.total_roas ?? 0,
    prev_accessor: (d) => d?.total_roas_prev ?? 0,
    accessorId: "total_roas",
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    toolTip: "The return on ad spend against total sales",
  },
  {
    id: "troas_delta",
    name: "TROAS Change",
    color: metricColors.roms[3],
    accessor: (d) => d?.total_roas_delta,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    incremental: true,
  },
  {
    id: "roas",
    name: "ROAS",
    color: metricColors.roms[1],
    accessor: (d) => d?.roas ?? 0,
    prev_accessor: (d) => d?.roas_prev ?? 0,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    toolTip: "The return on ad spend",
  },
  {
    id: "roas_delta",
    name: "ROAS Change",
    color: metricColors.roms[1],
    accessor: (d) => d?.roas_delta,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    incremental: true,
  },
  {
    id: "roas_same_sku",
    name: "Same SKU ROAS",
    color: metricColors.roms[1],
    accessor: (d) => d?.roas_same_sku ?? 0,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    toolTip: "The return on ad spend",
  },
  {
    id: "roas_same_sku_delta",
    name: "Same SKU ROAS Change",
    color: metricColors.roms[1],
    accessor: (d) => d?.roas_same_sku_delta,
    format: "number",
    decimalPlaces: 1,
    chartType: "line",
    axis: ROI_AXIS,
    incremental: true,
  },
  {
    id: "total_acos",
    name: "Total ACOS",
    color: metricColors.acos[1],
    accessor: (d) => d?.total_acos ?? 0,
    prev_accessor: (d) => d?.total_acos_prev ?? 0,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The return on ad spend",
    lower: true,
  },
  {
    id: "total_acos_delta",
    name: "Total ACOS Change",
    color: metricColors.acos[1],
    accessor: (d) => d?.total_acos_delta,
    format: "percent",
    decimalPlaces: 1,
    chartType: "line",
    axis: PERCENT_AXIS,
    incremental: true,
    lower: true,
  },
  {
    id: "acos",
    name: "ACOS",
    color: metricColors.acos[3],
    accessor: (d) => d?.acos ?? 0,
    prev_accessor: (d) => d?.acos_prev ?? 0,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The return on ad spend",
    lower: true,
  },
  {
    id: "acos_delta",
    name: "ACOS Change",
    color: metricColors.acos[3],
    accessor: (d) => d?.acos_delta,
    format: "percent",
    decimalPlaces: 1,
    chartType: "line",
    axis: PERCENT_AXIS,
    incremental: true,
    lower: true,
  },
  {
    id: "acos_same_sku",
    name: "Same SKU ACOS",
    color: metricColors.acos[6],
    accessor: (d) => d?.acos_same_sku ?? 0,
    prev_accessor: (d) => d?.acos_same_sku_prev ?? 0,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The return on ad spend",
    lower: true,
  },
  {
    id: "acos_same_sku_delta",
    name: "Same SKU ACOS Change",
    color: metricColors.acos[6],
    accessor: (d) => d?.acos_same_sku_delta,
    format: "percent",
    decimalPlaces: 1,
    chartType: "line",
    axis: PERCENT_AXIS,
    incremental: true,
    lower: true,
  },
  {
    id: "profit",
    name: "Profit",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit,
    prev_accessor: (d) => d?.profit_prev,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total profit after all costs",
  },
  {
    id: "profit_percent",
    name: "Profit %",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "profit_delta",
    name: "Profit (+/-)",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit_delta,
    format: "currency",
    axis: SALES_AXIS,
    incremental: true,
  },
  {
    id: "profit_wo_adspend",
    name: "Profit w/o Ad Spend",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit_wo_adspend,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The total profit after all costs",
  },
  {
    id: "profit_wo_adspend_percent",
    name: "Profit w/o Ad Spend %",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit_wo_adspend_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "profit_wo_adspend_delta",
    name: "Profit w/o Ad SPend (+/-)",
    color: metricColors.metric1[0],
    accessor: (d) => d?.profit_wo_adspend_delta,
    format: "currency",
    axis: SALES_AXIS,
    incremental: true,
  },

  {
    id: "profit_expcectations",
    name: "Profit Expectations",
    color: metricColors.sales[0],
    accessor: (d) => d?.profit_expcectations,
    accessorId: "sales_expcectations",
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "Expected Profit",
  },
  {
    id: "profit_expectations_percent",
    name: "Profit %",
    color: metricColors.sales[0],
    accessor: (d) => d?.profit_expcectations_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "price",
    name: "Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.product_price,
    prev_accessor: (d) => d?.product_price_prev,
    accessorId: "product_price",
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    toolTip: "The price of the product",
  },
  {
    id: "min_price",
    name: "Min Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.min_price,
    prev_accessor: (d) => d?.product_price,
    accessorId: "min_pirce",
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    toolTip: "The minimum price of the product",
  },
  {
    id: "max_price",
    name: "Max Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.max_price,
    prev_accessor: (d) => d?.product_price,
    accessorId: "max_price",
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    toolTip: "The minimum price of the product",
  },
  {
    id: "suggested_price",
    name: "Suggested",
    color: metricColors.metric2[0],
    accessor: (d) => d?.suggested_price,
    accessorId: "suggested_price",
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    toolTip: "The suggested price of the product",
  },
  {
    id: "product_price_prev",
    name: "Previous Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.product_price_prev,
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    toolTip: "The previous price of the product",
  },
  {
    id: "org_conv_rate",
    name: "Org Conv/Rate",
    color: metricColors.metric1[0],
    accessor: (d) => d?.org_conv_rate,
    format: "percent",
    chartType: "line",
    axis: PERCENT_AXIS,
    toolTip: "The percentage of page views that result in a sale.",
  },
  {
    id: "org_conv_rate_delta",
    name: "Org Conv/Rate Change",
    color: metricColors.metric1[0],
    accessor: (d) => d?.org_conv_rate_delta,
    format: "percent",
    chartType: "line",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "price_delta",
    name: "Price",
    color: metricColors.metric2[0],
    accessor: (d) => d?.product_price_delta,
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    incremental: true,
  },
  {
    id: "bsr",
    name: "Best Seller Rank",
    toolTip: "Sales rank, the lower is better",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank,
    prev_accessor: (d) => d?.bsr_rank_prev,
    accessorId: "bsr_rank",
    totalOverrideAccessor: (d) => d?.last_bsr_rank,
    format: "number",
    chartType: "line",
    axis: BSR_AXIS,
    lower: true,
  },
  {
    id: "bsr_percent",
    name: "Best Seller Rank %",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
    lower: true,
  },
  {
    id: "promo_sales",
    toolTip: "Total Sales from Deals",
    name: "Deals Sales",
    color: metricColors.sales[2],
    accessor: (d) => d?.promo_sales,
    prev_accessor: (d) => d?.promo_sales_prev,
    format: "currency",
    axis: SALES_AXIS,
  },
  {
    id: "promo_sales_percent",
    toolTip: "Total Sales from Deals percentage",
    name: "Deals Sales %",
    color: metricColors.sales[2],
    accessor: (d) => d?.promo_sales_percent,
    axis: PERCENT_AXIS,
    format: "percent",
    chartType: "line",
    incremental: true,
  },
  {
    id: "promo_units",
    toolTip: "Total Units sold from Deals",
    name: "Deals Units",
    color: metricColors.units[1],
    accessor: (d) => d?.promo_units,
    prev_accessor: (d) => d?.promo_units_prev,
    format: "number",
    axis: UNITS_AXIS,
  },
  {
    id: "promo_units_percent",
    toolTip: "Total Units sold from Deals percentage",
    name: "Deals Units %",
    color: metricColors.units[1],
    accessor: (d) => d?.promo_units_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    chartType: "line",
    incremental: true,
  },
  {
    id: "rods",
    toolTip: "Return on deal spend",
    name: "RODS",
    color: metricColors.roms[2],
    accessor: (d) => d?.rods,
    axis: ROI_AXIS,
    format: "number",
    chartType: "line",
  },
  {
    id: "rods_delta",
    toolTip: "Change in Return on deal spend",
    name: "RODS Change",
    color: metricColors.roms[2],
    accessor: (d) => d?.rods_delta,
    format: "number",
    axis: ROI_AXIS,
    incremental: true,
  },
  {
    id: "promo_conversion_rate",
    toolTip: "The percentage of glance views that results in a deal sale",
    name: "Deals Conversion Rate",
    color: metricColors.metric1[2],
    accessor: (d) => d?.conv,
    accessorId: "conv",
    axis: PERCENT_AXIS,
    format: "percent",
    chartType: "line",
  },
  {
    id: "promo_conversion_rate_delta",
    toolTip: "",
    name: "Deals Conversion Rate Change",
    color: metricColors.metric1[2],
    accessor: (d) => d?.conv_delta,
    prev_accessor: (d) => d?.conv_delta_prev,
    axis: PERCENT_AXIS,
    format: "percent",
    chartType: "line",
  },
  {
    id: "promo_cost",
    toolTip: "Total amount of discounts applied",
    name: "Total Discounts",
    color: metricColors.cost[2],
    accessor: (d) => d?.promo_cost,
    prev_accessor: (d) => d?.promo_cost_prev,
    format: "currency",
    axis: SALES_AXIS,
  },
  {
    id: "promo_cost_percent",
    name: "Total Discounts %",
    color: metricColors.cost[2],
    accessor: (d) => d?.promo_cost_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "cogs",
    toolTip: "Total Cost of Goods Sold",
    name: "COGS",
    color: metricColors.cost[3],
    accessor: (d) => d?.total_cogs,
    prev_accessor: (d) => d?.total_cogs_prev,
    accessorId: "total_cogs",
    format: "currency",
    axis: SALES_AXIS,
  },
  {
    id: "cogs_percent",
    name: "COGS %",
    color: metricColors.cost[3],
    accessor: (d) => d?.total_cogs_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "bsr",
    name: "Best Seller Rank",
    toolTip: "Sales rank, the lower is better",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank,
    prev_accessor: (d) => d?.bsr_rank_prev,
    accessorId: "bsr_rank",
    totalOverrideAccessor: (d) => d?.last_bsr_rank,
    format: "number",
    chartType: "line",
    axis: BSR_AXIS,
  },
  {
    id: "last_bsr_rank",
    name: "Last Best Seller Rank",
    toolTip: "The most recent sales rank, the lower is better",
    color: metricColors.metric3[0],
    accessor: (d) => d?.last_bsr_rank,
    prev_accessor: (d) => d?.last_bsr_rank_delta,
    format: "number",
    chartType: "line",
    axis: BSR_AXIS,
  },
  {
    id: "last_bsr_rank_delta",
    name: "Last Best Seller Rank Change",
    toolTip: "The most recent sales rank, the lower is better",
    color: metricColors.metric3[0],
    accessor: (d) => d?.last_bsr_rank_delta,
    format: "number",
    chartType: "line",
    axis: BSR_AXIS,
  },
  {
    id: "bsr_percent",
    name: "Best Seller Rank %",
    color: metricColors.metric3[0],
    accessor: (d) => d?.bsr_rank_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    incremental: true,
  },
  {
    id: "rating",
    name: "Rating",
    toolTip: "Average rating across all enrolled products",
    color: metricColors.metric4[0],
    accessor: (d) => d?.rating,
    totalOverrideAccessor: "last_rating",
    format: "number",
    chartType: "line",
    axis: RATINGS_AXIS,
  },
  {
    id: "last_rating",
    name: "Last Rating",
    toolTip: "The most recent average rating across all enrolled products",
    color: metricColors.metric4[0],
    accessor: (d) => d?.last_rating,
    format: "number",
    chartType: "line",
    axis: RATINGS_AXIS,
  },
  {
    id: "rating_percent",
    name: "Ratings %",
    color: metricColors.metric4[0],
    accessor: (d) => d?.last_rating_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "ratings_total",
    name: "# of Ratings",
    toolTip: "Number of ratings across all enrolled products",
    color: metricColors.metric4[4],
    accessor: (d) => d?.ratings_total,
    totalOverrideAccessor: "last_ratings_total",
    format: "number",
    chartType: "line",
    axis: RATINGS_TOTAL_AXIS,
  },
  {
    id: "last_ratings_total",
    name: "Last # of Ratings",
    toolTip: "The most recent number of ratings across all enrolled products",
    color: metricColors.metric4[0],
    accessor: (d) => d?.last_ratings_total,
    format: "number",
    chartType: "line",
    axis: RATINGS_TOTAL_AXIS,
  },
  {
    id: "ratings_total_percent",
    name: "# of Ratings %",
    color: metricColors.metric4[0],
    accessor: (d) => d?.ratings_total_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "seo_score",
    name: "Searchability",
    color: metricColors.sales[4],
    accessor: (d) => d?.seo_score,
    format: "number",
    axis: SCORE_AXIS,
    decimalPlaces: 0,
    toolTip:
      "This is a reflection of how your product will rank organically on Amazon search results and the chance of getting clicks. We take into account the title, image, price, rating and more. This score is out of 100.",
  },
  {
    id: "seo_score_percent",
    name: "Searchability %",
    color: metricColors.sales[4],
    accessor: (d) => d?.seo_score_percent,
    format: "percent",
    incremental: true,
    chartType: "line",
    axis: PERCENT_AXIS,
  },
  {
    id: "buyability_score",
    name: "Buyability",
    color: metricColors.metric1[4],
    decimalPlaces: 0,
    accessor: (d) => d?.buyability_score,
    format: "number",
    axis: SCORE_AXIS,
    toolTip:
      "This determines how likely your product is to convert when a customer is viewing your listing. We take into account the quality of your bullets points, images, ratings, reviews, and more. This score is out of 100.",
  },
  {
    id: "buyability_score_percent",
    name: "Buyability Score %",
    color: metricColors.metric1[4],
    accessor: (d) => d?.buyability_score_percent,
    format: "percent",
    incremental: true,
    chartType: "line",
    axis: PERCENT_AXIS,
  },
  {
    id: "conversion_rate",
    name: "Ad Conversion Rate",
    color: metricColors.metric2[4],
    accessor: (d) => d?.conv_rate ?? 0,
    prev_accessor: (d) => d?.conv_rate_prev ?? 0,
    accessorId: "conv_rate",
    format: "percent",
    chartType: "line",
    axis: RATE_AXIS,
    toolTip: "The percentage of advertising clicks that result in a sale.",
    decimalPlaces: 2,
  },
  {
    id: "conversion_rate_delta",
    name: "Conversion Rate Change",
    color: metricColors.metric2[4],
    accessor: (d) => d?.conv_rate_delta,
    format: "percent",
    chartType: "line",
    incremental: true,
    axis: PERCENT_AXIS,
    decimalPlaces: 1,
  },
  {
    id: "ctr",
    name: "CTR",
    color: metricColors.metric3[4],
    accessor: (d) => d?.click_thru_rate,
    prev_accessor: (d) => d?.click_thru_rate_prev,
    accessorId: "click_thru_rate",
    format: "percent",
    chartType: "line",
    axis: RATE_AXIS,
    toolTip: "The percentage of impressions that result in a click.",
    decimalPlaces: 2,
  },
  {
    id: "ctr_delta",
    name: "CTR Change",
    color: metricColors.metric3[4],
    accessor: (d) => d?.click_thru_rate_delta,
    format: "percent",
    chartType: "line",
    incremental: true,
    axis: PERCENT_AXIS,
    decimalPlaces: 2,
  },
  {
    id: "cpc",
    name: "CPC",
    color: metricColors.metric4[4],
    accessor: (d) => d?.cost_per_click ?? 0,
    prev_accessor: (d) => d?.cost_per_click_prev ?? 0,
    accessorId: "cost_per_click",
    format: "currency",
    chartType: "line",
    axis: PRICE_AXIS,
    toolTip: "The average cost per click.",
    decimalPlaces: 2,
    lower: true,
  },
  {
    id: "cpc_delta",
    name: "CPC Change",
    color: metricColors.metric4[4],
    accessor: (d) => d?.cost_per_click_delta,
    format: "percent",
    chartType: "line",
    incremental: true,
    axis: PERCENT_AXIS,
    decimalPlaces: 2,
  },
  {
    id: "impressions",
    name: "Impressions",
    color: metricColors.metric1[3],
    accessor: (d) => d?.impressions ?? 0,
    prev_accessor: (d) => d?.impressions_prev ?? 0,
    format: "number",
    chartType: "column",
    axis: IMPRESSIONS_AXIS,
    toolTip: "Advertising impressions",
  },
  {
    id: "impressions_percent",
    name: "Impressions %",
    color: metricColors.metric1[3],
    accessor: (d) => d?.impressions_percent,
    format: "percent",
    incremental: true,
    axis: PERCENT_AXIS,
  },
  {
    id: "page_views",
    name: "Page Views",
    color: metricColors.metric2[0],
    accessor: (d) => d?.page_views,
    prev_accessor: (d) => d?.page_views_prev,
    format: "number",
    axis: IMPRESSIONS_AXIS,
    toolTip: "Total page views",
  },
  {
    id: "page_views_percent",
    name: "Page Views Percent",
    color: metricColors.metric2[1],
    accessor: (d) => d?.page_views_percent,
    format: "percent",
    axis: PERCENT_AXIS,
    toolTip: "Total page views percentage",
  },
  {
    id: "match_type",
    name: "Match Type",
    color: metricColors.metric2[0],
    accessor: (d) => d?.match_type,
    format: "text",
    axis: IMPRESSIONS_AXIS,
    toolTip: "Target match type",
  },
  {
    id: "bid",
    name: "Current Bid",
    color: metricColors.sales[0],
    accessor: (d) => d?.bid,
    prev_accessor: (d) => d?.bid_prev,
    format: "currency",
    axis: BID_AXIS,
    toolTip: "Current keyword bid",
  },
  {
    id: "daily_bid",
    name: "Bid",
    color: metricColors.metric2[0],
    accessor: (d) => d?.daily_bid ?? 0,
    prev_accessor: (d) => d?.daily_bid_prev ?? 0,
    format: "currency",
    axis: PRICE_AXIS,
    toolTip: "Current keyword bid",
  },
  {
    id: "target_source",
    name: "Source",
    color: metricColors.metric4[0],
    accessor: (d) => d?.target_source,
    chartType: "column",
    format: "text",
    axis: CLICK_AXIS,
    toolTip: "The source of the advertisement target.",
  },
  {
    id: "orders",
    name: "Conversions",
    color: metricColors.metric4[4],
    accessor: (d) => d?.conversion ?? 0,
    prev_accessor: (d) => d?.conversion_prev ?? 0,
    accessorId: "conversion",
    chartType: "column",
    format: "number",
    axis: UNITS_AXIS,
    toolTip: "The total number of conversions",
  },
  {
    id: "conversion_percent",
    name: "Conversions Percent",
    color: metricColors.metric4[4],
    accessor: (d) => d?.conversion_percent ?? 0,
    accessorId: "conversion_percent",
    chartType: "column",
    format: "percent",
    axis: PERCENT_AXIS,
  },
  {
    id: "orders_percent",
    name: "Orders %",
    color: metricColors.metric4[4],
    accessor: (d) => d?.orders_percent,
    format: "percent",
    axis: PERCENT_AXIS,
  },
  {
    id: "orders_same_sku",
    name: "Same SKU Orders",
    color: metricColors.metric4[4],
    accessor: (d) => d?.conversion_same_sku,
    accessorId: "conversion_same_sku",
    chartType: "column",
    format: "number",
    axis: UNITS_AXIS,
    toolTip: "The total number of orders",
  },
  {
    id: "orders_same_sku_percent",
    name: "Same SKU Orders %",
    color: metricColors.metric4[4],
    accessor: (d) => d?.orders_same_sku_percent,
    format: "percent",
    axis: PERCENT_AXIS,
  },
  {
    id: "user_status",
    name: "Active",
    color: metricColors.metric4[0],
    accessor: (d) => d?.user_status,
    format: "text",
    axis: PERCENT_AXIS,
  },
  {
    id: "stock_status",
    name: "Stock Status",
    color: metricColors.metric4[0],
    accessor: (d) => d?.stock_status,
    format: "text",
    axis: PERCENT_AXIS,
  },
  {
    id: "serving_status",
    name: "Serving Status",
    color: metricColors.metric4[0],
    accessor: (d) => d?.serving_status,
    format: "text",
    axis: PERCENT_AXIS,
  },
  {
    // TODO: Tooltip might not be accurate description, don't merge until confirming
    id: "profit_per_page_view",
    name: "PPS",
    color: metricColors.metric4[0],
    accessor: (d) => d?.profit_per_page_view,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The average profit per page view driven by advertisement",
  },
  {
    id: "profit_per_page_view_delta",
    name: "(+/-) Profit / Session",
    color: metricColors.metric4[0],
    accessor: (d) => d?.profit_per_page_view_delta,
    prev_accessor: (d) => d?.profit_per_page_view_delta_prev,
    format: "currency",
    axis: PER_PAGE_VIEW,
  },
  {
    id: "profit_per_page_view_percent",
    name: "Profit Per Session Percent Change",
    color: metricColors.metric4[0],
    accessor: (d) => d?.profit_per_page_view_percent,
    format: "percent",
    axis: PERCENT_AXIS,
  },
  // TODO: Tooltip might not be accurate description, don't merge until confirming
  {
    id: "profit_per_page_view_wo_adspend",
    name: "Organic PPS",
    color: metricColors.metric4[0],
    accessor: (d) => d?.profit_per_page_view_wo_adspend,
    format: "currency",
    axis: SALES_AXIS,
    toolTip: "The average profit per page view",
  },
  {
    id: "profit_per_page_view_wo_adspend_delta",
    name: "Org PPS Delta",
    color: metricColors.metric4[0],
    accessor: (d) => d?.profit_per_page_view_wo_adspend_delta,
    format: "currency",
    axis: PER_PAGE_VIEW,
  },
  {
    id: "profit_per_page_view_wo_adspend_percent",
    name: "Org PPS Percent Change",
    color: metricColors.metric4[0],
    accessor: (d) => d?.profit_per_page_view_wo_adspend_percent,
    format: "percent",
    axis: PERCENT_AXIS,
  },
  {
    id: "boosted_profit",
    name: "Incremental Profit",
    color: metricColors.metric4[0],
    accessor: (d) => d?.boosted_profit,
    format: "currency",
    axis: PRICE_AXIS,
  },
  {
    id: "serving_hours",
    name: "Serving Hours",
    color: metricColors.metric2[0],
    accessor: (d) => d?.serving_hours,
    prev_accessor: (d) => d?.serving_hours_prev ?? 0,
    format: "number",
    axis: SERVING_HOURS_AXIS,
    chartType: "column",
    toolTip: "The number of hours that media plan was serving",
  },
  {
    id: "serving_hours_delta",
    name: "Serving Hours Delta",
    color: metricColors.metric2[1],
    accessor: (d) => d?.serving_hours_delta,
    format: "number",
    axis: SERVING_HOURS_AXIS,
  },
  {
    id: "budget_goal_progression",
    name: "Cumulative Budget Allocation",
    color: metricColors.sales[4],
    accessor: (d) => d?.budget_goal_progression ?? 0,
    format: "currency",
    axis: SALES_AXIS,
  },
];

export const getFormatter = (marketPlace, format, metric, options) => {
  const defaultFormatter = (v) => (v == null ? "-" : v);
  const decimalPlaces = metric?.decimalPlaces ?? 1;
  let formatter =
    {
      currency: (v) =>
        v == null
          ? "-"
          : formatCurrency(v, marketPlace?.marketPlace, false, false, true),
      number: (v) =>
        v == null || isNaN(v) ? "-" : formatNumber(v, {}, decimalPlaces),
      percent: (v) =>
        v == null || isNaN(v) ? "-" : formatPercent(v, decimalPlaces),
      text: (v) => {
        return v == null ? "-" : v;
      },
    }?.[format] ?? defaultFormatter;

  if (options?.overrideFormatter) {
    return (v) => options?.overrideFormatter(v);
  }
  if (options?.incremental ?? metric?.incremental) {
    return (v) => (v > 0 ? "+" : "") + formatter(v);
  }
  return formatter;
};

const getMetricSpec = (marketPlace, id, options) => {
  const metric = metrics.find((m) => m.id === id) || options;

  const formatter = getFormatter(
    marketPlace,
    metric?.format ?? "number",
    metric,
    options
  );
  return {
    ...metric,
    formatter,
    format: metric?.format,
    ...options,
  };
};

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = compareId
    ? getMetricSpec(marketPlace, compareId, options?.compare)
    : null;
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric?.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare ? compare.accessor(data?.total) : null;
  metric.targetFormatter = compare ? compare.formatter : null;
  if (options?.override) {
    metric.actual = options?.overrideValue ?? metric.actual;
    metric.name = options?.overrideLabel ?? metric.name;
    metric.toolTip = options?.overrideTooltip ?? metric.toolTip;
  }
  if (options?.overrideTarget) {
    metric.targetLabel = options?.overrideTargetLabel;
    metric.target = options?.overrideTargetValue;
    metric.targetFormatter = options?.overrideTargetFormatter;
    metric.lower = options?.overrideTargetLower;
  }
  return metric;
};

export default getMetricSpec;
export { buildMetric };
