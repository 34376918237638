import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation } from "react-router";
import getURLPrefix from "utils/getUrlPrefix";
import {
  CLIENT_SIDE_ENCRYPTION_KEY,
  PRODUCTION_GV_API_AUTH_CLIENT_ID,
  PRODUCTION_GV_API_AUTH_CLIENT_SECRET,
} from "utils/gvSecrets";
import setAuthToken from "utils/setAuthToken";
import { setCurrentUser, setTokens } from "redux/actions/authActions";
import Cryptr from "cryptr";
import { setChannel } from "redux/actions/channel";
import { useSelector } from "react-redux";
import DownloadPDF from "components/custom/DownloadPDF";
import OrgReport from "./OrgReport";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CenteredModal from "components/core/basic/CenteredModal";
import { Col, Row } from "react-bootstrap";
import { PrimaryButton } from "components/core/basic/Button";
import "assets/css/pdf_view_styles.css";

const PDFView = () => {
  const { search } = useLocation();

  const [loaded, setLoaded] = useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = useState(true);
  const [instructionsViewed, setInstructionsViewed] = useState(false);

  const params = new URLSearchParams(search);
  const token = params.get("token");
  const print = params.get("print");

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user_full_name } = useSelector((state) => state.user);
  const { url } = useRouteMatch();

  const loginMutation = useMutation(async (token) => {
    let authData = {
      client_id: PRODUCTION_GV_API_AUTH_CLIENT_ID,
      client_secret: PRODUCTION_GV_API_AUTH_CLIENT_SECRET,
      grant_type: "password",
      password: "password",
      username: "username",
      token,
    };
    const urlPrefix = getURLPrefix();
    let resp = await fetch(`${urlPrefix}/api/auth_otp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(authData),
    });
    if (!resp.ok) {
      throw new Error("Invalid Token");
    }
    const oAuthTokens = await resp.json();
    const cryptr = new Cryptr(CLIENT_SIDE_ENCRYPTION_KEY);
    const encrypted_refresh_token = cryptr.encrypt(oAuthTokens.refresh_token);
    resp = await fetch(`${urlPrefix}/api/userprofile/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oAuthTokens.access_token}`,
      },
    });
    if (!resp.ok) {
      throw new Error("No profile");
    }
    const currentUser = await resp.json();
    const tokens = {
      access_token: oAuthTokens.access_token,
      refresh_token: encrypted_refresh_token,
    };
    localStorage.setItem("Tokens", JSON.stringify(tokens));
    localStorage.setItem("TokenTime", (Date.now() + 60 * 60 * 1000).toString());
    const channel = currentUser.channel?.toLowerCase() ?? "amazon";
    setAuthToken(oAuthTokens.access_token);
    setCurrentUser(currentUser);
    setTokens(tokens);
    setChannel(channel);
    localStorage.setItem("channel", channel);
    setIsLoggedIn(true);
  }, {});

  useEffect(() => {
    if (token) {
      loginMutation.mutate(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (print === "true" && loaded && instructionsViewed) {
      // Hide Hubspot chat and react-query-devtools widgets in print view
      const reactQueryDevtoolsWidget = document.querySelector(
        ".ReactQueryDevtools"
      );
      const hubspotChatWidget = document.querySelector(
        "#hubspot-messages-iframe-container"
      );

      if (hubspotChatWidget) {
        hubspotChatWidget.style.visibility = "hidden";
      }

      if (reactQueryDevtoolsWidget) {
        reactQueryDevtoolsWidget.style.display = "none";
      }

      window.print();
    }
  }, [print, loaded, instructionsViewed]);

  return (
    <div>
      {showInstructionsModal && !instructionsViewed && loaded && (
        <CenteredModal
          show={showInstructionsModal}
          closeModal={() => {
            setShowInstructionsModal(false);
            setInstructionsViewed(true);
          }}
          title={"Printing Recommendations"}
          dialogClassName="modal-60w"
          content={
            <Row className="justify-content-center">
              <Col xs={12} xl={8}>
                <p>
                  For the best formatting of this report, please ensure the
                  following printing settings are configured on your computer:{" "}
                </p>
                <ul>
                  <li>The page orientation is set to landscape</li>
                  <li>Page margins are set to "None"</li>
                  <li>
                    The "Headers and footers" checkbox under printing "Options"
                    is unchecked
                  </li>
                  <li>
                    The "Background graphics" checkbox under printing "Options"
                    is checked (optional, but strongly recommended)
                  </li>
                </ul>
              </Col>
              <Col xs={12} className="text-center">
                <PrimaryButton
                  onClick={() => {
                    setShowInstructionsModal(false);
                    setInstructionsViewed(true);
                  }}
                >
                  Print PDF
                </PrimaryButton>
              </Col>
            </Row>
          }
        />
      )}
      {!print && (
        <>
          <h1>PDF View</h1>
          {!token && <p>No token found</p>}
          {isLoggedIn && <p>Logged in</p>}
          {user_full_name && <p>Welcome {user_full_name}</p>}
          {!token && !print && <DownloadPDF url="/pdf?print=true" />}
        </>
      )}
      {
        <Switch>
          <Route exact path={`${url}/report/organization/summary`}>
            <OrgReport
              onFinishedLoading={() => {
                setLoaded(true);
              }}
              isPDFView={true}
            />
          </Route>
        </Switch>
      }
    </div>
  );
};

export default PDFView;
