import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
import moment from "moment";
import { selectModules } from "redux/selectors/modules";
import SidebarLink from "./SidebarLink";

const AdvertisingSidebar = ({
  user,
  applications,
  permissions,
  additionalStyles,
  small = false,
}) => {
  additionalStyles = additionalStyles || { marginBottom: "3rem" };
  const isActive = (routeName) => {
    return document.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  if (!applications.find((m) => m === "Advertising")) {
    return null;
  }

  if (permissions === "reports") {
    return null;
  }

  let now = moment();
  let tosDate = moment(user?.tos_acceptance_date?.slice(0, 10));
  let daysSinceSignup = tosDate ? now.diff(tosDate, "days") : 100;

  const isAdvertisingDashboardActive =
    isActive("/user/advertising/category") ||
    isActive("/user/advertising/dashboard") ||
    isActive("/user/advertising/advertisement");

  return (
    <>
      <SidebarLink
        to={"/user/advertising/dashboard"}
        isActive={isAdvertisingDashboardActive}
        icon={<i className="pe-7s-speaker" />}
        small={small}
        section={"placement"}
      >
        Advertising{" "}
        {user.tier === "unknown" &&
          user.pricing_tier === "unknown" &&
          daysSinceSignup < 3 && (
            <Badge
              bsClass="badge"
              className={`badge-${
                isAdvertisingDashboardActive ? "secondary" : "primary"
              }`}
              style={{ marginLeft: "1rem" }}
              pill
            >
              NEW
            </Badge>
          )}
      </SidebarLink>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    channel: state.channel.currentChannel,
    user: state.user,
    modules: selectModules(state),
  };
};

export default connect(mapStateToProps)(withRouter(AdvertisingSidebar));
