import {
  Col,
  Form,
  Container as Grid,
  InputGroup,
  Nav,
  Row,
} from "react-bootstrap";
import SummaryCard from "components/core/blocks/SummaryCard";
import React, { useMemo, useRef } from "react";
import { useFetch } from "hooks/api";
import { useDates } from "dates/useDates";
import DatesProvider from "dates/DatesProvider";
import { useLocation } from "react-router";
import Table from "views/merchandise/tables/BulkActionsTable";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import KeywordCell from "components/custom/category/advertisements/KeywordCell";
import { useSelector } from "react-redux";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import {
  ORG_SUMMARY_REPORT_BREADCRUMB_SPEC,
  REPORT_LINKS,
} from "utils/dashboardLinks";
import MultiMetricChart from "components/custom/merchandise/MultiMetricChart";
import Loading from "components/core/blocks/Loading";
import useSelectedMetrics from "components/custom/merchandise/hooks/useSelectedMetrics";
import { buildMetric } from "./merchandise/metrics";
import acosOrRoas from "utils/acosOrRoas";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import useDebounce from "hooks/useDebounce";
import useQueryParams from "hooks/useQueryParams";
import moment from "moment";
import { formatTargetText } from "utils/formatTargetText";
import { snakeCaseToSentenceCase } from "utils/formatText";
import TrellisLink from "components/core/basic/TrellisLink";
import { getDashboardTo } from "./AdvertisingOverview";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import useSearchTermReport from "hooks/useSearchTermReport";
import CategorySelector from "components/core/blocks/CategorySelector";

const useAdvertisementReport = () => {
  const { marketPlace, channel } = useSelector((state) => state);
  const start = moment().add(30, "days").format("YYYY-MM-DD");
  const end = start;

  return useFetch(
    ["advertisementReport", start, end, marketPlace.marketPlace],
    `/api/data_report/ad_report`,
    {
      start_date: start,
      end_date: end,
      channel: channel.currentChannel,
      mp: marketPlace.marketPlace,
    },
    {
      default: [],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      select: (res) => res.data,
    }
  );
};

const useTargetReport = (
  targetIds,
  targetType,
  start,
  end,
  pre_start,
  pre_end,
  group
) => {
  const { marketPlace, channel } = useSelector((state) => state);
  return useFetch(
    [
      "targetReport",
      targetIds,
      targetType,
      start,
      end,
      pre_start,
      pre_end,
      group,
    ],
    `/api/data_report/keyword_report`,
    {
      target_ids: targetIds.join(","),
      start_date: start,
      end_date: end,
      pre_start_date: pre_start,
      pre_end_date: pre_end,
      empty: true,
      channel: channel.currentChannel,
      kw_report: false,
      target_type: targetType,
      group,
      mp: marketPlace.marketPlace,
    },
    {
      default: [],
      enabled: !!start && !!end && !!targetIds.length,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      select: (res) => res.data,
    }
  );
};

const useRelatedTerms = () => {
  const end = moment().format("YYYY-MM-DD");
  const start = moment().subtract(15, "days").format("YYYY-MM-DD");
  return useFetch(
    ["relatedTerms", start, end],
    `/api/data_report_v2/searchtermreport/stats/`,
    {
      dimensions: "query",
      filters: `date__gte:${start},date__lte:${end}`,
      sort: "-impressions",
      limit: 24,
    },
    {
      default: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      select: (res) => res.data,
    }
  );
};

const Expander = ({ dims, query, keyword_text, match_type, row }) => {
  const tableRef = useRef();
  const { marketPlace } = useSelector((state) => state);
  const { preferences } = useMerchandisePreferences();
  const [tab, setTab] = useQueryParams("etab", "chart");
  const { start, end, preStart, preEnd, group } = useDates();
  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);
  let filters = `date__gte:${start.format("YYYY-MM-DD")},date__lte:${end.format(
    "YYYY-MM-DD"
  )}`;
  if (dims === "query") {
    filters = `${filters},query:${query}`;
  } else {
    filters = `${filters},keyword_text:${keyword_text},match_type:${match_type}`;
  }

  const { data: _data, isLoading } = useFetch(
    [
      "searchTermReport",
      filters,
      group,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
    ],
    `/api/data_report_v2/searchtermreport/stats/`,
    {
      dimensions: group,
      filters,
    },
    {
      default: [],
      enabled: !!start && !!end,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      select: (res) => res.data,
    }
  );

  const data = useMemo(() => {
    return _data?.map((row) => {
      return {
        start_date: row.date,
        ...row,
      };
    });
  }, [_data]);

  const { data: targetData } = useTargetReport(
    row?._original?.adv_targets?.filter((k) => k),
    "target",
    start.format("YYYY-MM-DD"),
    end.format("YYYY-MM-DD"),
    preStart.format("YYYY-MM-DD"),
    preEnd.format("YYYY-MM-DD"),
    group
  );

  const { data: keywordData, isLoading: isKeywordLoading } = useTargetReport(
    row?._original?.adv_keywords?.filter((k) => k),
    "keyword",
    start.format("YYYY-MM-DD"),
    end.format("YYYY-MM-DD"),
    preStart.format("YYYY-MM-DD"),
    preEnd.format("YYYY-MM-DD"),
    group
  );

  const { data: advertisementData } = useAdvertisementReport();

  const metrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
      buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
      acosroas(
        buildMetric(marketPlace, data, "acos", "acos_delta"),
        buildMetric(marketPlace, data, "roas", "roas_delta")
      ),
      buildMetric(marketPlace, data, "clicks", "clicks_percent"),
      buildMetric(marketPlace, data, "impressions", "impressions_percent"),
      buildMetric(marketPlace, data, "ctr", "ctr_delta"),
      buildMetric(
        marketPlace,
        data,
        "conversion_rate",
        "conversion_rate_delta"
      ),
      buildMetric(marketPlace, data, "cpc", "cpc_delta"),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marketPlace, data]
  );

  const { selectedMetrics, toggleMetric } = useSelectedMetrics(
    ["impressions", "ad_spend", "ad_sales"],
    "searchTermReportExpander"
  );

  const columnSpecs = useMemo(() => {
    const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);
    const perfCol = acosroas(
      { key: "acos", compareKey: "acos_delta" },
      { key: "roas", compareKey: "roas_delta" }
    );
    return [
      {
        key: "ad_group_name",
        compareKey: null,
        options: {
          metric: {
            id: "ad_group_name",
            name: "Ad Group Name",
            format: "text",
          },
          hideFilter: true,
          overflowCell: true,
          additionalStyles: { paddingTop: "20px" },
          formatter: (value) => value,
        },
      },
      {
        key: "target_bid",
        compareKey: null,
        options: {
          editCell: false,
          minValue: 0,
          columnTitle: "Bid",
          className: "text-end",
          overrideKey: "manual_bid",
          strikeThroughText: (row) => {
            if (!row?._original?.state) {
              return "";
            }
            if (row._original?.state?.toLowerCase()?.indexOf("negative") > -1) {
              return "text-decoration-line-through";
            }
            return "";
          },
        },
      },
      {
        key: "target_source",
        compareKey: null,
        options: {
          hideFilter: true,
          formatter: (value) =>
            value === "GV_DISCOVERY"
              ? "initial"
              : value === "harvested"
              ? "harvesting"
              : snakeCaseToSentenceCase(value)?.toLowerCase(),
        },
      },
      {
        key: "creation_date",
        compareKey: null,
        options: {
          metric: {
            id: "creation_date",
            format: "text",
            accessor: (d) => d?.creation_date,
            name: "Created On",
          },
          formatter: (v) => v ?? "",
          hideFilter: true,
          checked: false,
        },
      },
      {
        key: "match_type",
        compareKey: null,
        options: {
          width: 150,
          hideFilter: true,
          formatter: (v) => {
            return snakeCaseToSentenceCase(v)
              ?.toLowerCase()
              .indexOf("negative") > -1
              ? snakeCaseToSentenceCase(v)
                  ?.toLowerCase()
                  ?.replace("negative", "negative ")
              : snakeCaseToSentenceCase(v)?.toLowerCase();
          },
        },
      },
      {
        key: "target_state",
        compareKey: null,
        options: {
          checked: false,
          metric: {
            id: "target_state",
            format: "text",
            accessor: (d) => d?.target_state,
            name: "State",
            options: { checked: true },
          },
          formatter: (v) => snakeCaseToSentenceCase(v)?.toLowerCase() ?? "",
          filterType: "select",
          hideFilter: true,
        },
      },
      { key: "impressions", compareKey: "impressions_percent" },
      { key: "clicks", compareKey: "clicks_percent" },
      { key: "orders", compareKey: "conversion_percent" },
      { key: "ad_spend", compareKey: "ad_spend_percent" },
      { key: "ad_sales", compareKey: "ad_sales_percent" },
      perfCol,
      { key: "cpc", compareKey: "cpc_delta" },
      { key: "conversion_rate", compareKey: "conversion_rate_delta" },
      { key: "ctr", compareKey: "ctr_delta" },
    ];
  }, [preferences]);
  const filterProps = useCustomTableFilters(
    marketPlace.marketPlace,
    "search-term-report"
  );

  const adsMapping = useMemo(() => {
    return (advertisementData?.adplans ?? []).reduce((acc, row) => {
      acc[row.ad_id.toString()] = row;
      return acc;
    }, {});
  }, [advertisementData]);

  const tableData = useMemo(() => {
    const data = tab === "target" ? targetData : keywordData;
    return (data?.keywords ?? []).map((row) => {
      const ad = adsMapping[row.advertisement_id];
      return {
        ...row,
        ad_name: ad?.ad_name,
      };
    });
  }, [keywordData, targetData, adsMapping, tab]);

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }
  return (
    <div className="bg-white fs-standard">
      <Row className="pt-3">
        <Col xs={12} md={6}>
          <h3
            className="pb-4 px-5"
            style={{
              fontSize: 22,
            }}
          >
            {query} {keyword_text} {match_type ? `(${match_type})` : ""}
          </h3>
        </Col>
        <Col xs={12} className="ph-5 mb-5 border-bottom fs-standard">
          <Nav
            variant="underline"
            onSelect={(k) => {
              return setTab(k);
            }}
            activeKey={tab}
          >
            <Nav.Item>
              <Nav.Link eventKey="chart">Chart</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="keyword">Keywords</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="target">Targets</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs={12}>
          {tab === "chart" ? (
            <MultiMetricChart
              metrics={metrics}
              selectedMetrics={selectedMetrics}
              data={data}
              group={group}
              handleLegendChange={handleLegendChange}
              datesFormatted={true}
              showAnnotations={false}
            />
          ) : (
            <Table
              marketPlace={marketPlace}
              tableRef={tableRef}
              tableId={"keyword-report"}
              isExpandable={false}
              isStatsLoading={isKeywordLoading}
              isSelectable={false}
              titleCol={{
                id: "target",
                isStatic: true,
                Header: "Target",
                accessor: "text",
                format: "text",
                style: { width: "300px" },
                width: 300,
                Cell: (props) => {
                  return (
                    <>
                      <KeywordCell
                        value={formatTargetText(props?.original?.text, {
                          ...props.original?.addtl_data,
                          marketPlace: marketPlace,
                        })}
                        columnWidth={300}
                      />
                      <br />
                      <TrellisLink
                        to={getDashboardTo({
                          type: "advertisement",
                          id: props?.original?.advertisement_id,
                        })}
                        style={{
                          textDecoration: "none",
                          fontWeight: "500",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                        }}
                      >
                        <small>{props?.original?.ad_name}</small>
                      </TrellisLink>
                    </>
                  );
                },
                Filter: ({ filter, onChange }) => null,
              }}
              data={tableData}
              columnSpecs={columnSpecs}
              getSearchCriteria={(row) => {
                return `${row?.query}`;
              }}
              tableRowDescription={"Search Terms"}
              defaultSorted={[{ id: "impressions", desc: true }]}
              isLoading={isKeywordLoading}
              bulkActionOptions={null}
              filterWidget={true}
              hideFilterToggleIcon={true}
              hideResetFiltersIcon={false}
              {...filterProps}
              handleBulkChanges={(changes) => {}}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const Dashboard = () => {
  const { start, end, preStart, preEnd } = useDates();
  const { marketPlace } = useSelector((state) => state);
  const { preferences } = useMerchandisePreferences();
  const tableRef = useRef();
  const [_query, setQuery] = useQueryParams("q", "");
  const [dims, setDims] = useQueryParams("dims", "keyword_text,match_type");
  const [category, setCategory] = useQueryParams("category", null);
  const query = useDebounce(_query, 300);

  const { data: relatedTerms } = useRelatedTerms();

  const { data: current, isLoading: isCurrentLoading } = useSearchTermReport(
    query,
    start.format("YYYY-MM-DD"),
    end.format("YYYY-MM-DD"),
    1000,
    dims,
    { category }
  );
  const { data: previous, isLoading: isPreviousLoading } = useSearchTermReport(
    query,
    preStart.format("YYYY-MM-DD"),
    preEnd.format("YYYY-MM-DD"),
    5000,
    dims,
    { category }
  );

  const data = useMemo(() => {
    if (current) {
      const selectQuery = (d) => d.query;
      const selectKeyword = (d) => `${d?.keyword_text}:${d?.match_type}`;
      const select = dims === "query" ? selectQuery : selectKeyword;
      const previousMap =
        previous?.reduce((acc, row) => {
          acc[select(row)] = row;
          return acc;
        }, {}) ?? {};
      return current.map((row) => {
        const key = select(row);
        row["key"] = key;
        const prevRow = previousMap?.[key];
        const compareFn = (key, row, prevRow) => {
          return {
            [`${key}_delta`]: row[key] - prevRow[key],
            [`${key}_percent`]:
              prevRow[key] !== 0
                ? (row[key] - prevRow[key]) / prevRow[key]
                : null,
            [`${key}_prev`]: prevRow[key],
          };
        };

        if (prevRow) {
          return {
            ...row,
            ...compareFn("impressions", row, prevRow),
            ...compareFn("clicks", row, prevRow),
            ...compareFn("ad_spend", row, prevRow),
            ...compareFn("cost", row, prevRow),
            ...compareFn("ad_sales", row, prevRow),
            ...compareFn("acos", row, prevRow),
            ...compareFn("roas", row, prevRow),
            ...compareFn("click_thru_rate", row, prevRow),
            ...compareFn("cost_per_click", row, prevRow),
            ...compareFn("conv_rate", row, prevRow),
            ...compareFn("conversion", row, prevRow),
          };
        }
        return row;
      });
    }
    return [];
  }, [current, previous, dims]);

  const columnSpecs = useMemo(() => {
    const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);
    const perfCol = acosroas(
      { key: "acos", compareKey: "acos_delta" },
      { key: "roas", compareKey: "roas_delta" }
    );
    const cols = [
      { key: "impressions", compareKey: "impressions_percent" },
      { key: "clicks", compareKey: "clicks_percent" },
      { key: "orders", compareKey: "conversion_percent" },
      { key: "ad_spend", compareKey: "ad_spend_percent" },
      { key: "ad_sales", compareKey: "ad_sales_percent" },
      perfCol,
      { key: "cpc", compareKey: "cpc_delta" },
      { key: "conversion_rate", compareKey: "conversion_rate_delta" },
      { key: "ctr", compareKey: "ctr_delta" },
      {
        key: "adv_keyword_cnt",
        options: {
          columnTitle: "Keyword Count",
          format: "number",
          formatter: (v) => v,
        },
      },
      {
        key: "adv_target_cnt",
        options: {
          columnTitle: "Target Count",
          format: "number",
          formatter: (v) => v,
        },
      },
    ];
    if (dims !== "query") {
      cols.unshift({
        key: "match_type",
        options: {
          columnTitle: "Match Type",
          format: "text",
          formatter: (v) => v,
        },
      });
    }
    return cols;
  }, [preferences, dims]);
  const filterProps = useCustomTableFilters(
    marketPlace.marketPlace,
    "search-term-report"
  );

  const hasData = isCurrentLoading || (query && data.length > 0);

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: hasData ? "3em" : "10em",
                marginBottom: "1em",
                transition: "all  0.5s",
              }}
            >
              <div style={{ width: "400px" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    style={{
                      borderTopLeftRadius: "45px",
                      borderBottomLeftRadius: "45px",
                    }}
                  >
                    <i className="fa fa-search" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setQuery(e.target.value)}
                    value={_query}
                  />
                </InputGroup>
              </div>
            </div>
            {false && (
              <>
                <div className="text-center">
                  <h5>Enter a search term to get started!</h5>
                </div>
              </>
            )}
          </Col>
          <Col xs={12} className="fs-standard">
            <Row>
              {false &&
                relatedTerms?.map((term, i) => {
                  return (
                    <Col
                      xs={12}
                      lg={4}
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1em",
                      }}
                    >
                      <span
                        style={{
                          padding: "6px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => setQuery(term.query)}
                      >
                        {term.query}
                      </span>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="ph-5 mb-5 border-bottom fs-standard">
        <Nav
          variant="underline"
          onSelect={(k) => {
            return setDims(k);
          }}
          activeKey={dims}
        >
          <Nav.Item>
            <Nav.Link eventKey="keyword_text,match_type">Keywords</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="query">Queries</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col xs={12} lg={6} xl={2}>
        <CategorySelector
          selectedCategory={category}
          setSelectedCategory={(cat) => {
            setCategory(cat);
          }}
        />
      </Col>
      <Col xs={12} lg={6} xl={10}></Col>
      <Col xs={12}>
        <Table
          hideSearchFilter
          marketPlace={marketPlace}
          tableRef={tableRef}
          tableId={"search-term-report"}
          isExpandable
          isStatsLoading={isCurrentLoading || isPreviousLoading}
          isSelectable={false}
          selectionKey={"key"}
          titleCol={{
            id: "key",
            isStatic: true,
            Header: dims === "query" ? "Query" : "Keyword",
            accessor: "key",
            format: "text",
            style: { width: "300px" },
            width: 300,
            Cell: (props) => {
              return (
                <>
                  <KeywordCell
                    value={
                      props?.original?.query || props?.original?.keyword_text
                    }
                    columnWidth={300}
                  />
                </>
              );
            },
            Filter: ({ filter, onChange }) => null,
          }}
          data={data}
          columnSpecs={columnSpecs}
          getSearchCriteria={(row) => {
            return `${row?.key}`;
          }}
          tableRowDescription={"Search Terms"}
          defaultSorted={[{ id: "impressions", desc: true }]}
          isLoading={isCurrentLoading}
          bulkActionOptions={null}
          filterWidget={true}
          hideFilterToggleIcon={true}
          hideResetFiltersIcon={false}
          {...filterProps}
          handleBulkChanges={(changes) => {}}
          ExpandedContent={({ row }) => {
            return (
              <Expander
                row={row.row}
                dims={dims}
                query={row.original.query}
                keyword_text={row.original.keyword_text}
                match_type={row.original.match_type}
                marketPlace={marketPlace}
              />
            );
          }}
        ></Table>
      </Col>
    </Row>
  );
};

const SearchTermReport = () => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const reduxState = useSelector((state) => state);
  const modules = selectModules(reduxState);

  const breadCrumbItems = [
    {
      name: "Search Term Report",
      href: "/user/report/search_term_report",
      dropdownLinkOptions: REPORT_LINKS,
      searchable: false,
      dropdownSubtitle: "Reports",
    },
  ];

  if (
    checkModule(modules, "ORG_SUMMARY_REPORT") &&
    !breadCrumbItems[0].dropdownLinkOptions?.find(
      (o) => o.name === ORG_SUMMARY_REPORT_BREADCRUMB_SPEC.name
    )
  ) {
    breadCrumbItems[0].dropdownLinkOptions.unshift(
      ORG_SUMMARY_REPORT_BREADCRUMB_SPEC
    );
  }

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Grid
        fluid
        style={{
          minHeight: "calc(100vh - 150px)",
          background: "#fefefe",
        }}
      >
        <Row>
          <Col xs={12} className="mb-4">
            <BreadcrumbDates items={breadCrumbItems} />
          </Col>
        </Row>
        <SummaryCard mobileStyle title={`Search Term Report`}>
          <Row
            style={{
              minHeight: "calc(100vh - 350px)",
            }}
          >
            <Col xs={12}>
              <Dashboard />
            </Col>
          </Row>
        </SummaryCard>
      </Grid>
    </DatesProvider>
  );
};

export default SearchTermReport;
