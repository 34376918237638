import logo from "assets/images/logo/Trellis_Logomark_color.png";
import PropTypes from "prop-types";
import React, { Component } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
  initiateAmzAdvConnect,
  completeAmzAdvConnect,
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { logoutUser } from "redux/actions/authActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { getURLPrefix } from "utils/getUrlPrefix";
import routes from "routes/routes";
import checkOnboardRedirect from "components/custom/onboarding/OnboardUtils";
import ModalAlert from "components/core/basic/ModalAlert";

let URL_PREFIX = getURLPrefix();

class Onboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mwsOrSummary: null, // wait to discover start state from back-end
      profiles: [],
      count: 0,
      isVendorProfile: false,
    };

    this.onUpdateProfile = this.onUpdateProfile.bind(this);
    this.onMwsAck = this.onMwsAck.bind(this);
    this.onMwsEdit = this.onMwsEdit.bind(this);
    this.onMwsGoBack = this.onMwsGoBack.bind(this);
    this.isVendor = this.isVendor.bind(this);

    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  componentDidMount() {
    let url = window.location.href;

    let code = null;
    if (url.includes("/onboard/amzadv?code")) {
      url = url.split("?")[1];
      code = url.split("=")[1];
      code = code.split("&")[0];
    }

    refreshAmzOnboardStatus(
      this.props.updateAmzOnboardingStatus,
      this.props.auth.tokens.access_token,
      this.props.setErrors,
      code,
      this.fetchUserProfiles
    );

    if (
      code === null &&
      this.props.amz_onboard.amazon_advertising_access !== "connected"
    ) {
      this.fetchUserProfiles();
    }

    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }

    this.pageNavigation();
  }

  componentDidUpdate() {
    if (
      window.location.href.includes("/onboard") &&
      !this.state.isVendorProfile
    ) {
      this.pageNavigation();
    }
  }

  fetchUserProfiles = () => {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/accounts/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.status === 204) {
          return;
        }

        let profiles = this.formatProfiles(res.data);

        if (
          localStorage.amzConnectAttempted &&
          res.status === 200 &&
          profiles.length < 1
        ) {
          const err = {
            title: "No Valid Profiles Retrieved",
            message:
              "Unable to find any profile types supported by Trellis. Please note that Trellis does not currently support KDP or agency account types.",
          };
          this.props.setErrors(err);

          this.setState({
            profiles: profiles,
          });

          return;
        }
        this.setState({
          profiles: profiles,
          selectedProfile: {
            value: res.data.current_profile,
            label: res.data.current_profile,
            isDisabled: false,
          },
        });
      });
  };

  formatProfiles(data) {
    if (!("seller" in data) && !("vendor" in data)) {
      return;
    }
    var formattedData = [];
    var count = 0;
    if (Object.keys(data["seller"]).length > 0) {
      formattedData.push({
        value: 0,
        label: "Seller Profiles",
        isDisabled: true,
      });
      for (var key in data["seller"]) {
        formattedData.push({ value: key, label: key, isDisabled: false });
        count += 1;
      }
    }
    if (Object.keys(data["vendor"]).length > 0) {
      formattedData.push({
        value: 0,
        label: "Vendor Profiles",
        isDisabled: true,
      });
      for (key in data["vendor"]) {
        formattedData.push({
          value: key,
          label: key,
          isDisabled: false,
          vendor: true,
        });
        count += 1;
      }
    }
    this.setState({ count: count });
    return formattedData;
  }

  onUpdateProfile() {
    // return to profile selection screen
    localStorage.setItem("EditingProfile", "true");
    this.setState({ returnToProfileSelection: true, mwsOrSummary: "" });
    this.props.history.push("/onboard/amzadv");
  }

  onMwsAck() {
    // advance to summary screen
    this.setState({ mwsOrSummary: "summary" });
  }

  onMwsEdit() {
    // go back to MWS edit screen
    this.setState({ mwsOrSummary: "mws" });
  }

  onMwsGoBack() {
    // go back to MWS Choice screen
    this.setState({ mwsOrSummary: "start" });
  }

  isVendor() {
    this.setState({ isVendorProfile: true });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/onboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(routeProps) => (
              <prop.component
                {...routeProps}
                layout={prop.layout}
                disableConnectButton={
                  this.props.amz_onboard.amazon_advertising_connecting
                }
                onUpdateProfile={this.onUpdateProfile}
                onMwsEdit={this.onMwsEdit}
                onMwsAck={this.onMwsAck}
                onMwsGoBack={this.onMwsGoBack}
                isVendor={this.isVendor}
                profiles={this.state.profiles}
                count={this.state.count}
                isVendorProfile={this.state.isVendorProfile}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  _onIdle(e) {
    this.props.logoutUser();
    const err = {
      title: "",
      message: "Your session has expired. Please login again.",
    };
    this.props.setErrors(err);
  }

  pageNavigation() {
    if (this.props.amz_onboard.amazon_spapi_access === "") {
      // still waiting to discover initial state from back-end
      return;
    }

    if (this.state.returnToProfileSelection) {
      if (!window.location.href.includes("/onboard/amzadv")) {
        this.setState({ returnToProfileSelection: false });
        this.props.history.push("/onboard/amzadv");
      }
    } else {
      let redirect = checkOnboardRedirect(
        this.props.amz_onboard,
        this.props.history.location.pathname
      );
      if (
        (redirect === null &&
          this.props.amz_onboard.listings_progress !== "pending") ||
        this.props.user.bypass_onboarding
      ) {
        // ready to advance to dashboard
        // Below is messy, but handles how the SP-API tokens must get sent from a registered URL.
        // This path redirects to the legacy SP-API view, processes tokens under a placeholder layout with a loading spinner, then returns to new onboarding flow
        if (
          this.props.user.bypass_onboarding &&
          redirect === "/onboard/amzspapi" &&
          !window.location.href?.includes("amzspapi") &&
          [
            this.props.amazon_spapi_access,
            this.props.eu_amazon_spapi_access,
            this.props.fe_amazon_spapi_access,
          ].includes("in_progress")
        ) {
          this.props.history.push("/onboard/amzspapi");
          return;
        }

        if (window.location.href.includes("amzspapi")) {
          return;
        }

        this.props.history.push("/user/dashboard");
      } else if (redirect != null && !window.location.href.includes(redirect)) {
        this.props.history.push(redirect);
      } else if (
        this.props.errors.length > 0 &&
        redirect != null &&
        window.location.href.split(redirect)[1].length > 0
      ) {
        // this is handling the special case where our attempt to connect to amazon advertising returned an error
        // in this case, we need to redirect to the amazon advertising screen without the auth code suffix
        this.props.history.push(redirect);
      }
    }
  }

  render() {
    let url = window.location.href;
    if (
      url.includes("/onboard/amzadv?code") &&
      !this.props.amz_onboard.amazon_advertising_connecting
    ) {
      //disable the connect button while the back-end processes the auth
      this.props.initiateAmzAdvConnect();
    } else if (
      url.includes("/onboard/amzadv?code") &&
      this.props.amz_onboard.amazon_advertising_connecting &&
      this.props.errors.length > 0
    ) {
      // Amazon Connect came back with an error. We'll display the error the user and re-enable the connect button
      this.props.completeAmzAdvConnect();
    }

    return (
      <>
        {this.props.auth.loading ||
        (this.props.amz_onboard.about_your_company !== "pending" &&
          this.props.amz_onboard.payment_discovery !== "pending" &&
          this.props.amz_onboard.org_status !== "onboarding" &&
          this.props.amz_onboard.amazon_advertising_access !== "pending" &&
          this.props.amz_onboard.amazon_additional_information !== "pending" &&
          (this.props.amz_onboard.amazon_mws_access === "connected" ||
            this.props.amz_onboard.amazon_mws_access === "not_available") &&
          (this.props.amz_onboard.eu_amazon_mws_access === "connected" ||
            this.props.amz_onboard.eu_amazon_mws_access === "not_available") &&
          (this.props.amz_onboard.fe_amazon_mws_access === "connected" ||
            this.props.amz_onboard.fe_amazon_mws_access === "not_available") &&
          this.props.amz_onboard.listings_progress !== "pending" &&
          this.props.amz_onboard.category_progress !== "pending" &&
          this.props.amz_onboard.stock_progress !== "pending") ? (
          <div className="wrap" style={{ height: "100vh" }}>
            <img
              src={logo}
              alt="Loading..."
              className="rotate"
              width="80"
              height="auto"
            />
          </div>
        ) : (
          <>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              element={document}
              onIdle={this.onIdle}
              debounce={250}
              timeout={6 * 60 * 60 * 1000}
            />
            <Row
              style={{
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </>
        )}
        {this.props.errors.length > 0 &&
          this.props.errors[0].title !== "Advertising Connect Failed" &&
          this.props.errors[0].title !== "Advertising Connect Timed Out" &&
          this.props.errors[0].title !== "Invalid Credentials" &&
          this.props.errors[0].title !== "Amazon SP-API Connect Failed" &&
          this.props.errors[0].title !== "No Valid Profiles Retrieved" &&
          ModalAlert({
            title:
              "title" in this.props.errors[0]
                ? this.props.errors[0].title
                : "error",
            msg: this.props.errors[0].message,
            onClick: () => {
              this.props.clearErrors();
            },
          })}
      </>
    );
  }
}

Onboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  amz_onboard: state.amz_onboard,
  user: state.user,
  errors: state.errors,
});

const mapDispatchToProps = {
  logoutUser,
  initiateAmzAdvConnect,
  completeAmzAdvConnect,
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboard);
