export const notificationTypes = [
  {
    label: "Out of Stock",
    value: "AD_PRODUCT_OUT_OF_STOCK",
  },
  {
    label: "Low Stock",
    value: "AD_PRODUCT_LOW_OF_STOCK",
  },
  {
    label: "Product Ineligibility",
    value: "AD_PRODUCT_INELIGIBLE",
  },
  {
    label: "New ASIN and Keyword",
    value: "AD_NEW_KWS_ASINS",
  },
  {
    label: "Advertisement Budget Exceeded",
    value: "AD_PRODUCT_EXCEEDS_ACOS_SPEND",
  },
];
