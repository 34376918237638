import React from "react";
import {
  InlineInputField,
  InlineSelect,
} from "components/custom/programs/fields";
import { Form } from "react-bootstrap";
import { useWatch } from "react-hook-form";
import { useProgramContext } from "./ProgramContext";
import { useSelector } from "react-redux";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
const PriceForm = ({ action, fieldName, control, formState }) => {
  const { marketPlace } = useSelector((state) => state);

  let adjustOptions = [
    {
      value: "adjust_fixed",
      label: MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace],
    },
    { value: "adjust_percent", label: "%" },
  ];

  if (action === "SET_BID") {
    adjustOptions = [
      {
        value: "adjust_fixed",
        label: MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace],
      },
    ];
  }

  return (
    <>
      {action !== "SET_PRICE" && (
        <InlineSelect
          options={adjustOptions}
          fieldName={`${fieldName}.operator`}
          control={control}
          rules={{ required: true }}
        />
      )}
      <InlineInputField
        fieldName={`${fieldName}.values.0`}
        control={control}
        rules={{ required: true }}
      />
    </>
  );
};

const ActionForm = ({ fieldName, control, formState, initialAction }) => {
  const { engine } = useProgramContext();
  const actionOptions = {
    pricing: [
      { value: "SET_PRICE", label: "Set price" },
      { value: "INCREASE_PRICE", label: "Increase price" },
      { value: "LOWER_PRICE", label: "Decrease price" },
    ],
    bidding: initialAction
      ? [
          { value: "SET_BID", label: "Set bid" },
          { value: "REVERT_TO_ALGO", label: "Use automated bid" },
        ]
      : [
          { value: "SET_BID", label: "Set bid" },
          { value: "INCREASE_BID", label: "Increase bid" },
          { value: "LOWER_BID", label: "Decrease bid" },
          { value: "PAUSE", label: "Pause keyword or target" },
        ],
    budget: [
      { value: "SET_BUDGET", label: "Set budget" },
      { value: "INCREASE_BUDGET", label: "Increase budget" },
      { value: "LOWER_BUDGET", label: "Decrease budget" },
    ],
  };
  const action = useWatch({
    control,
    name: `${fieldName}.function`,
  });

  return (
    <Form.Group>
      <div className="d-flex align-items-center">
        <InlineSelect
          options={actionOptions[engine]}
          fieldName={`${fieldName}.function`}
          control={control}
          rules={{ required: true }}
        />
        {!["RESET_ACC", "NEGATE", "PAUSE", "REVERT_TO_ALGO"].includes(
          action
        ) && (
          <PriceForm
            action={action}
            fieldName={`${fieldName}`}
            control={control}
            rules={{ required: true }}
            actionOptions={actionOptions[engine]}
          />
        )}
      </div>
    </Form.Group>
  );
};

export default ActionForm;
