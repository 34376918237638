import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import { LogoutButton } from "components/core/basic/Button.jsx";
import { AD_TYPES } from "components/core/blocks/AdPlan/constants";
import FormGroupTile from "components/core/basic/FormGroupTile";
import Checkbox from "components/core/basic/CheckBox";
import { useFetch } from "hooks/api";
import { formatPercent } from "utils/formatNumber";
import { Link } from "react-router-dom";
import { trellisPalette } from "components/custom/analytics/palettes";

const getFormattedDescription = (
  clicks,
  viewImpressions,
  conversions,
  days,
  isVCPM,
  lowercase
) => {
  let formattedSettingsDescription = `Keywords and targets will be harvested when there ${
    conversions > 1 || (!conversions && clicks > 1) ? "are" : "is"
  } at least ${conversions} conversion${conversions === 1 ? "" : "s"} and ${
    isVCPM ? viewImpressions : clicks
  } ${isVCPM ? "or less view impression" : "or less click"}${
    isVCPM && viewImpressions === 1 ? "" : "s"
  } in the previous ${days > 1 ? days : ""} day${days > 1 ? "s" : ""}`;

  if (!conversions) {
    formattedSettingsDescription = formattedSettingsDescription.replace(
      /(\d+|NaN) conversions and/g,
      ""
    );
  }

  if (!clicks) {
    formattedSettingsDescription = formattedSettingsDescription.replace(
      /and (\d+|NaN) or less clicks/g,
      ""
    );
  }

  if (!viewImpressions) {
    formattedSettingsDescription = formattedSettingsDescription.replace(
      /and (\d+|NaN) or less view impressions/g,
      ""
    );
  }

  if (lowercase) {
    formattedSettingsDescription = formattedSettingsDescription.toLowerCase();
  }

  if (!isVCPM && clicks) {
    let conversionRate = conversions / clicks;

    formattedSettingsDescription += ` (a conversion rate greater than ${formatPercent(
      conversionRate,
      2
    )}`;
  }

  // Embolden parameters in summary sentence
  formattedSettingsDescription = formattedSettingsDescription
    ?.split(" ")
    ?.map((word, i) =>
      [
        "conversions",
        "conversion",
        "clicks",
        "click",
        "view",
        "impression",
        "impressions",
        "previous",
        "day",
        "days",
        "or",
        "less",
        "greater",
        "than",
        "rate",
      ].includes(word.toLowerCase()) || !isNaN(parseInt(word)) ? (
        <strong>
          {word}
          {i === formattedSettingsDescription?.split(" ")?.length - 1
            ? ""
            : " "}
        </strong>
      ) : (
        word + " "
      )
    );

  if (!isVCPM && clicks) {
    formattedSettingsDescription.push(")");
  }

  return formattedSettingsDescription;
};

const HarvestingConfiguration = ({
  saveHarvestingSettings,
  onChange,
  disableSubmitButton = false,
  orgLayout = false,
  state = {},
}) => {
  const [showConversionErrorMessage, setShowConversionErrorMessage] =
    useState(false);

  const {
    harvestingClicks,
    harvestingConversions,
    harvestingDays,
    harvestingViewImpressions,
    manualHarvestingEnabled,
  } = state ?? {};

  const { data: orgHarvestingSettings } = useFetch(
    ["orgHarvestingSettings"],
    "/org_bid_budget_settings",
    {},
    {
      default: [],
      enabled: !orgLayout,
      refetchOnWindowFocus: false,
      select: (d) => d.data,
    }
  );

  const spacing = orgLayout ? 3 : 0;
  const textStyles = {
    color: orgLayout ? "inherit" : "#73706E",
    fontWeight: orgLayout ? "bold" : 560,
  };

  // Description variables
  const descriptionClicks =
    manualHarvestingEnabled || orgLayout
      ? harvestingClicks
      : orgHarvestingSettings?.harvesting_clicks;
  const descriptionConversions =
    manualHarvestingEnabled || orgLayout
      ? harvestingConversions
      : orgHarvestingSettings?.harvesting_conversions;
  const descriptionViewImpressions =
    manualHarvestingEnabled || orgLayout
      ? harvestingViewImpressions
      : orgHarvestingSettings?.harvesting_view_impressions;
  const descriptionDays =
    manualHarvestingEnabled || orgLayout
      ? harvestingDays
      : orgHarvestingSettings?.harvesting_days;
  const isVCPM = state.sdBidType === "vcpm";

  const formattedSettingsDescription = getFormattedDescription(
    descriptionClicks,
    descriptionViewImpressions,
    descriptionConversions,
    descriptionDays,
    isVCPM,
    false
  );

  const formattedVCPMSettingsDescription = orgLayout
    ? getFormattedDescription(
        descriptionClicks,
        descriptionViewImpressions,
        descriptionConversions,
        descriptionDays,
        true,
        true
      )
    : "";
  return (
    <Row
      className="w-100"
      style={{
        color: orgLayout ? "inherit" : "#73706E",
      }}
    >
      <Col className="p-3">
        <Row className={"pt-3"}>
          <h3
            className={`${orgLayout ? "fs-1" : "fs-2"} ps-${orgLayout ? 4 : 2}`}
            style={textStyles}
          >
            Harvesting Rules
          </h3>
          <div className={`ms-${spacing} fs-standard`}>
            <p
              className={`fs-standard ${orgLayout ? "pb-2" : "py-2"}`}
              style={{ color: textStyles.color }}
            >
              Customize criteria for keyword and target harvesting using the
              settings below.{" "}
              {orgLayout
                ? "These settings will be the default for all media plan harvesting and can be overridden on each media plan under the Advanced Targeting menu."
                : "These settings will override the defaults set in the Placement Configuration menu and will apply only to this media plan."}
            </p>
            {!orgLayout && !state.manualHarvestingEnabled && (
              <p
                className={`fs-standard py-2`}
                style={{ color: textStyles.color }}
              >
                This media plan is currently using the{" "}
                <strong>organization-level harvesting settings</strong>. To
                modify them visit the{" "}
                <Link
                  to="/user/advertising/settings"
                  style={{
                    color: trellisPalette[13],
                  }}
                  className="text-decoration-none"
                  target="_blank"
                >
                  Advertising Configuration menu
                </Link>
                . To modify harvesting settings on this media plan only, check
                the <strong>Enable Rules-Based Harvesting</strong> option below
                to enable harvesting rules fields.
              </p>
            )}
            {harvestingConversions ? (
              <p
                className="info-text d-inline-block fs-standard mt-2 p-3"
                style={{ color: textStyles.color }}
              >
                {formattedSettingsDescription}.{" "}
                {orgLayout ? (
                  <>
                    For Sponsored Display media plans where the cost type is set
                    to vCPM, {formattedVCPMSettingsDescription}.
                  </>
                ) : (
                  ""
                )}
              </p>
            ) : (
              <></>
            )}
          </div>
        </Row>
        {!orgLayout && (
          <Row className="pt-5">
            <Col xs={12} lg={9}>
              <h5 className={`m-0 ps-${spacing} text-start`} style={textStyles}>
                Enable Rules-Based Harvesting
              </h5>
              <div
                className={`ms-${spacing} d-flex align-items-center fs-standard py-4 py-lg-1`}
              ></div>
            </Col>
            <Col xs={12} lg={3}>
              <Checkbox
                inline
                number={`${state.id || "create"}-manualHarvestingEnabled`}
                label="Enable Rules-Based Harvesting"
                checked={state.manualHarvestingEnabled}
                disabled={false}
                color={state.id ? "#AAA" : null}
                onClick={(e) => {
                  onChange({
                    manualHarvestingEnabled: !state.manualHarvestingEnabled,
                  });
                }}
              />
            </Col>
          </Row>
        )}
        <Row className="pt-5">
          <Col xs={12} lg={9}>
            <h5 className={`m-0 ps-${spacing} text-start`} style={textStyles}>
              Conversions
            </h5>
            <div
              className={`ms-${spacing} d-flex align-items-center fs-standard py-4 py-lg-1`}
            >
              The minimum number of conversions to trigger harvesting.
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <FormGroupTile
              defaultValue={harvestingConversions}
              type="formcontrol"
              stateName="harvestingConversions"
              formControlType={"number"}
              disabled={!manualHarvestingEnabled && !orgLayout}
              style={{ textAlign: "center" }}
              handleChildFormElement={(key, value) => {
                if (!parseInt(value)) {
                  setShowConversionErrorMessage(true);
                } else {
                  setShowConversionErrorMessage(false);
                }

                onChange({ [key]: parseInt(value) });
              }}
            />
            {showConversionErrorMessage && (
              <small className="text-danger d-block text-center pt-2">
                At least 1 conversion is required for this field.
              </small>
            )}
          </Col>
        </Row>
        {!(
          [
            AD_TYPES.SPONSORED_DISPLAY.value,
            AD_TYPES.SPONSORED_DISPLAY_V2.value,
          ].includes(state.adType) && state.sdBidType === "vcpm"
        ) || orgLayout ? (
          <Row className="pt-5">
            <Col xs={12} lg={9}>
              <h5 className={`m-0 ps-${spacing} text-start`} style={textStyles}>
                Clicks
              </h5>
              <div
                className={`ms-${spacing} d-flex align-items-center fs-standard py-4 py-lg-1`}
              >
                The maximum number of clicks to trigger harvesting.{" "}
                {orgLayout
                  ? "This threshold will be applied to all media plans except for Sponsored Display plans where the cost type is set to vCPM, in which case the views threshold will be used."
                  : ""}{" "}
                If this field is set to 0 only conversions will be considered as
                a criteria for harvesting.
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <FormGroupTile
                defaultValue={harvestingClicks}
                type="formcontrol"
                formControlType={"number"}
                stateName="harvestingClicks"
                style={{ textAlign: "center" }}
                disabled={!manualHarvestingEnabled && !orgLayout}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: parseInt(value) })
                }
                key={1}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {([
          AD_TYPES.SPONSORED_DISPLAY.value,
          AD_TYPES.SPONSORED_DISPLAY_V2.value,
        ].includes(state.adType) &&
          state.sdBidType === "vcpm") ||
        orgLayout ? (
          <Row className="pt-5">
            <Col xs={12} lg={9}>
              <h5 className={`m-0 ps-${spacing} text-start`} style={textStyles}>
                Views
              </h5>
              <div
                className={`ms-${spacing} d-flex align-items-center fs-standard py-4 py-lg-1`}
              >
                The maximum number of viewable impressions for Sponsored Display
                media plans with their cost type set to vCPM.
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <FormGroupTile
                defaultValue={harvestingViewImpressions}
                type="formcontrol"
                stateName="harvestingViewImpressions"
                formControlType={"number"}
                disabled={!manualHarvestingEnabled && !orgLayout}
                style={{ textAlign: "center" }}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: parseInt(value) })
                }
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row className="pt-5">
          <Col xs={12} lg={9}>
            <h5 className={`m-0 ps-${spacing} text-start`} style={textStyles}>
              Number of Days
            </h5>
            <div
              className={`ms-${spacing} d-flex align-items-center fs-standard py-4 py-lg-1`}
            >
              The number of days we look back to evaluate the harvesting
              conditions.
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <FormGroupTile
              defaultValue={harvestingDays}
              type="formcontrol"
              stateName="harvestingDays"
              disabled={!manualHarvestingEnabled && !orgLayout}
              formControlType={"number"}
              style={{ textAlign: "center" }}
              handleChildFormElement={(key, value) => {
                onChange({ [key]: parseInt(value) });
              }}
            />
          </Col>
        </Row>

        {orgLayout && (
          <Row className="pt-5">
            <Col className="d-flex justify-content-center">
              <LogoutButton
                title={`Save`}
                style={{ transform: "none" }}
                disabled={disableSubmitButton || showConversionErrorMessage}
                onClick={() => saveHarvestingSettings()}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default HarvestingConfiguration;
