import React, { useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "hooks/api";
import { useDates } from "dates/useDates";
import { useMediaQuery } from "@react-hook/media-query";
import moment from "moment";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { SEARCH_TERM_REPORT_MATCH_TYPE_FILTER } from "views/advertising/targetLifecycleFilters";
import useCustomTableFilters from "hooks/useCustomTableFilters";

import { Col, Row } from "react-bootstrap";
import Table from "views/merchandise/tables/BulkActionsTable";
import FormGroupTile from "components/core/basic/FormGroupTile";
import NotificationSystem from "react-notification-system";
import KeywordCell from "components/custom/category/advertisements/KeywordCell";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import acosOrRoas from "utils/acosOrRoas";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";
import { formatNumber } from "utils/formatNumber";
import DownloadReport from "modules/perf_reports/DownloadReport";

const SearchTermReport = ({ adPlanParams, adId }) => {
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  const { marketPlace, channel } = useSelector((state) => state);
  const { start, end } = useDates();
  const smallViewportWidthTable = useMediaQuery("(max-width:1400px)");

  const isWalmartReport = channel?.currentChannel === "walmart";
  const titleColId = isWalmartReport ? "searched_keyword" : "query";

  const tableRef = useRef();
  const notificationRef = useRef();

  const tableId = "searchTermReport";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const campaigns = adPlanParams?.campaignIds ?? {};

  const { preferences } = useMerchandisePreferences();
  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  const campaignOptions = useMemo(() => {
    let options = [];

    for (const campaignType in campaigns) {
      options.push({
        label: `${snakeCaseToSentenceCase(
          campaignType?.split("_")?.[isWalmartReport ? 1 : 0]
        )} Campaign`,
        value: campaigns[campaignType],
      });
    }

    if (
      (!selectedCampaignId && options.length) ||
      !options?.map((o) => o.value)?.includes(selectedCampaignId)
    ) {
      setSelectedCampaignId(options[0]?.value);
    }
    return options;
  }, [campaigns, selectedCampaignId, isWalmartReport]);

  const startDate = moment(start).format("YYYY-MM-DD");
  const endDate = moment(end).format("YYYY-MM-DD");

  const reportEndpoint = isWalmartReport
    ? "/api/data_report_v2/walmartsearchtermreport/stats/"
    : "/api/data_report_v2/searchtermreport/stats/";
  const dimensions = isWalmartReport
    ? "searched_keyword,match_type,bidded_keyword,item_id"
    : "query,match_type,keyword_text";
  const {
    data: searchTermReportData,
    isLoading: isSearchTermReportDataLoading,
  } = useFetch(
    ["searchTermData", startDate, endDate, selectedCampaignId, adId],
    reportEndpoint,
    {
      dimensions: dimensions,
      filters: `campaign_id:${selectedCampaignId},date__gte:${startDate},date__lte:${endDate}`,
    },
    {
      default: [],
      enabled: !!selectedCampaignId,
      select: (res) => res.data,
    }
  );

  const downloadFilters = {
    start_date: startDate,
    end_date: endDate,
    campaign: selectedCampaignId,
  };

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, tableId);

  const columnSpecs = useMemo(() => {
    const columns = [
      buildTextColumn(
        isWalmartReport ? "bidded_keyword" : "keyword_text",
        "Keyword Text",
        {
          width: smallViewportWidthTable ? 200 : 300,
          hideFilter: true,
          toolTip:
            "The target or keyword that matches a user-provided search term",
        }
      ),
      buildTextColumn("match_type", "Match Type", {
        formatter: (v) => {
          return snakeCaseToSentenceCase(v)?.toLowerCase();
        },
        width: smallViewportWidthTable ? 70 : 150,
        hideFilter: true,
      }),

      { key: "clicks", compareKey: "clicks_percent" },
      { key: "ad_spend", compareKey: "ad_spend_percent" },
      { key: "impressions", compareKey: "impressions_percent" },
      { key: "ad_sales", compareKey: "ad_sales_percent" },
      acosroas(
        {
          key: "acos",
          compareKey: "acos_delta",
          options: { isNegative: true },
        },
        {
          key: "roas",
          compareKey: "roas_delta",
          options: {
            formatter: (v) => (!v ? "-" : formatNumber(v, {}, 1)),
          },
        }
      ),
      acosroas(
        {
          key: "roas",
          compareKey: "roas_delta",
          options: {
            checked: false,
            formatter: (v) => (!v ? "-" : formatNumber(v, {}, 1)),
          },
        },
        {
          key: "acos",
          compareKey: "acos_delta",
          options: { isNegative: true, checked: false },
        }
      ),
      {
        key: isWalmartReport ? "units" : "orders",
        compareKey: isWalmartReport ? "units_percent" : "orders_percent",
      },
      {
        key: "ctr",
        compareKey: "ctr_delta",
        options: {
          checked: false,
        },
      },
      {
        key: "cpc",
        compareKey: "cpc_delta",
        options: {
          checked: false,
          isNegative: true,
        },
      },
    ];

    if (selectedCampaignId === campaigns?.auto_id) {
      columns.splice(1, 1);
    }

    if (isWalmartReport) {
      columns.splice(
        2,
        0,
        buildTextColumn("item_id", "Item ID", {
          checked: false,
        })
      );
    } else {
      columns.splice(-2, 0, {
        key: "conversion_rate",
        compareKey: "conversion_rate_delta",
        options: {
          checked: false,
          width: 130,
        },
      });
    }

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignId]);

  const CampaignSelector = () => (
    <FormGroupTile
      type="select"
      multi={false}
      stateName={"campaignId"}
      handleChildFormElement={(key, value) => {
        setSelectedCampaignId(value.value);
      }}
      defaultValue={campaignOptions?.find(
        (option) => option.value === selectedCampaignId
      )}
      options={campaignOptions}
    />
  );

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col className="text-center" xs={12} lg={{ offset: 4, span: 4 }}>
            <h2
              className="fw-bold my-3"
              style={{ fontSize: "2rem", color: "#403E3D" }}
            >
              Search Term Report
            </h2>
            {smallViewportWidthTable && campaignOptions?.length > 1 ? (
              <div
                className="mx-auto mt-5"
                style={{
                  position: "relative",
                  maxWidth: "250px",
                  transform: "scale(0.8) translateY(-50%)",
                  zIndex: 1000,
                }}
              >
                <CampaignSelector />
              </div>
            ) : null}
          </Col>
        </Row>
        <div>
          <NotificationSystem ref={notificationRef} />
          <Table
            marketPlace={marketPlace}
            tableRef={tableRef}
            tableId={tableId}
            isExpandable={false}
            isSelectable={false}
            hideCompareValue={true}
            additionalFilters={
              campaignOptions?.length > 1 && !smallViewportWidthTable ? (
                <div className="searchTermReportCampaignSelector">
                  <CampaignSelector />
                </div>
              ) : null
            }
            titleCol={{
              id: titleColId,
              isStatic: true,
              Header: "Query",
              accessor: titleColId,
              format: "text",
              style: { width: smallViewportWidthTable ? "200px" : "300px" },
              width: smallViewportWidthTable ? 200 : 300,
              Cell: (props) => {
                return (
                  <>
                    <KeywordCell
                      value={props?.original?.[titleColId]}
                      columnWidth={300}
                    />
                  </>
                );
              },
              Filter: ({ filter, onChange }) => null,
            }}
            data={searchTermReportData}
            columnSpecs={columnSpecs}
            getSearchCriteria={(row) => {
              return `${row?.query} ${row.keyword_text} ${row.match_type} ${row?.searched_keyword} ${row?.bidded_keyword}`;
            }}
            tableRowDescription={"Search Terms"}
            defaultSorted={[{ id: "ad_sales", desc: true }]}
            isLoading={
              isSearchTermReportDataLoading || !campaignOptions?.length
            }
            selectionKey="target_id"
            bulkActionOptions={null}
            filterWidget={true}
            hideFilterToggleIcon={true}
            hideResetFiltersIcon={false}
            excludedFilterColumns={["match_type"]}
            {...filterProps}
            additionalFilterOptions={[SEARCH_TERM_REPORT_MATCH_TYPE_FILTER]}
            handleBulkChanges={(changes) => {}}
          />
        </div>
      </Col>
      <Col xs={12}>
        <DownloadReport
          url_path="search_term_report"
          filters={downloadFilters}
          report_dl="search_terms"
        />
      </Col>
    </Row>
  );
};

export default SearchTermReport;
