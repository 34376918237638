import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useFetch } from "hooks/api";
import { connect } from "react-redux";
import logo from "../../../assets/images/logo/Trellis_Logomark_color.png";
import EditIcon from "@material-ui/icons/Edit";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../utils/api";
import { Alert } from "@material-ui/lab";
import { Add } from "@material-ui/icons";

const normalizeChannel = (channel) => {
  switch (channel.toLowerCase()) {
    case "amazon":
      return "AMZ";
    default:
      return channel;
  }
};

const ConfirmationDialog = ({
  title,
  open,
  setOpen,
  onConfirm,
  children,
  cancelText = "No",
  confirmText = "Yes",
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          onClick={() => setOpen(false)}
          color={"secondary"}
        >
          {cancelText}
        </Button>
        <Button
          variant={"contained"}
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditCategoryDialog = ({ category, onClose, mutation }) => {
  const [newCategoryName, setNewCategoryName] = useState(category.name);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleRename = useCallback(() => {
    mutation.mutate({
      method: "patch",
      id: category.id,
      onSuccess: () => "Category has been renamed.",
      data: { name: newCategoryName },
    });
    onClose();
  }, [category.id, mutation, newCategoryName, onClose]);

  const handleDelete = useCallback(() => {
    mutation.mutate({
      method: "delete",
      id: category.id,
      onSuccess: () => "Category has been deleted.",
    });
  }, [category.id, mutation]);

  return (
    <>
      <ConfirmationDialog
        title={"Really delete?"}
        open={showConfirmation}
        setOpen={setShowConfirmation}
        confirmText={"I'm Sure"}
        cancelText={"Cancel"}
        onConfirm={handleDelete}
      >
        Are you sure you want to delete this category? All products in this
        category will be moved to the Uncategorized category.
      </ConfirmationDialog>
      <Dialog open={true} onClose={onClose}>
        <Card>
          <CardHeader title={`Edit Category - ${category.name}`} />
          <Divider />
          <CardContent>
            <TextField
              id={"category-name"}
              variant={"outlined"}
              label={"Category Name"}
              defaultValue={category.name}
              fullWidth
              autoFocus
              onInput={(e) => setNewCategoryName(e.target.value)}
            />
          </CardContent>
        </Card>
        <Button size={"large"} color={"primary"} onClick={handleRename}>
          Save
        </Button>
        <Button
          size={"large"}
          color={"secondary"}
          onClick={() => setShowConfirmation(true)}
        >
          Delete
        </Button>
      </Dialog>
    </>
  );
};

const NewCategoryDialog = ({ onClose, mutation, marketplace, channel }) => {
  const [newCategoryName, setNewCategoryName] = useState("");

  const handleRename = useCallback(() => {
    mutation.mutate({
      method: "post",
      onSuccess: () => "Category has been created.",
      data: {
        marketplace: marketplace,
        channel: channel,
        name: newCategoryName,
      },
    });
    onClose();
  }, [channel, marketplace, mutation, newCategoryName, onClose]);

  return (
    <Dialog open={true} onClose={onClose}>
      <Card>
        <CardHeader title={"New Category"} />
        <Divider />
        <CardContent>
          <TextField
            id={"category-name"}
            variant={"outlined"}
            label={"Category Name"}
            fullWidth
            autoFocus
            onInput={(e) => setNewCategoryName(e.target.value)}
          />
        </CardContent>
      </Card>
      <Button size={"large"} color={"primary"} onClick={handleRename}>
        Save
      </Button>
    </Dialog>
  );
};

/**
 * Shows a list of selectable categories.
 */
const CategorySelector = ({
  classes,
  channel,
  marketPlace,
  onCategorySelected,
  currentCategory: defaultCategory,
  allowCreation = true,
  allowEdit = true,
  showHeader = true,
  autoFocus = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [snackbar, setSnackbar] = useState(null);

  const queryClient = useQueryClient();

  const { isLoading, data } = useFetch(
    [
      "searchCategories",
      searchQuery,
      normalizeChannel(channel.currentChannel),
      marketPlace.marketPlace,
    ],
    "/api/v2/categories/",
    {
      name: searchQuery,
      channel: normalizeChannel(channel.currentChannel),
      marketplace: marketPlace.marketPlace,
    }
  );

  const categoryEditModification = useMutation(
    async (action) => {
      if (action.method === "patch") {
        return api.patch(`/api/v2/categories/${action.id}/`, action.data);
      } else if (action.method === "delete") {
        return api.remove(`/api/v2/categories/${action.id}/`);
      } else if (action.method === "post") {
        return api.post(`/api/v2/categories/`, action.data);
      } else {
        throw new Error("Action with an unknown method.");
      }
    },
    {
      onMutate: () => {
        setCategoryToEdit(null);
      },
      onSuccess: async (response, action) => {
        await queryClient.invalidateQueries("searchCategories");

        let msg;

        // See if the caller provided a success message.
        if (action.onSuccess !== undefined) {
          msg = action.onSuccess(response);
        }
        // If not, see if the response provided one.
        if (!msg && response.data["_msg"]) {
          msg = response.data["_msg"];
        }

        if (msg) {
          setSnackbar({ message: msg, severity: "success" });
        }
      },
      onError: async (error) => {
        if (error.response.data["_msg"]) {
          setSnackbar({
            message: error.response.data["_msg"],
            severity: "error",
          });
        }
      },
    }
  );

  // Reset our state if the marketplace or channel changes.
  useEffect(() => {
    setCurrentCategory(null);
    setSearchQuery("");
  }, [marketPlace, channel]);

  const doSetSelectedCategory = useCallback(
    ({ currentTarget }) => {
      const category_id = parseInt(currentTarget.dataset.categoryId);
      const name = currentTarget.dataset.categoryName;

      if (currentCategory?.id === category_id) {
        // Clicked on the already-selected category, so unset it.
        setCurrentCategory(null);
        if (onCategorySelected !== null) {
          onCategorySelected(null);
        }
      } else {
        setCurrentCategory({ id: category_id, name: name });
        if (onCategorySelected !== null) {
          onCategorySelected({ id: category_id, name: name });
        }
      }
    },
    [currentCategory, onCategorySelected]
  );

  return (
    <>
      {categoryEditModification.isLoading ? (
        <Backdrop className={classes.backdrop} open={true}>
          <div className={classes.spinnerContainer}>
            <img src={logo} alt="Loading..." className="rotate" width="128" />
            <div>Applying changes...</div>
          </div>
        </Backdrop>
      ) : null}

      {snackbar !== null ? (
        <Snackbar
          open={true}
          autoHideDuration={8000}
          onClose={() => setSnackbar(null)}
        >
          <Alert onClose={() => setSnackbar(null)} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      ) : null}

      {categoryToEdit !== null ? (
        <EditCategoryDialog
          category={categoryToEdit}
          onClose={() => setCategoryToEdit(null)}
          mutation={categoryEditModification}
          marketplace={marketPlace.marketPlace}
          channel={normalizeChannel(channel.currentChannel)}
        />
      ) : null}

      {showNewCategory ? (
        <NewCategoryDialog
          onClose={() => setShowNewCategory(false)}
          mutation={categoryEditModification}
          marketplace={marketPlace.marketPlace}
          channel={normalizeChannel(channel.currentChannel)}
        />
      ) : null}

      <Card>
        {showHeader ? (
          <>
            <CardHeader
              title={"Categories"}
              action={
                allowCreation ? (
                  <IconButton
                    onClick={() => setShowNewCategory(true)}
                    title={"Create a new category"}
                  >
                    <Add />
                  </IconButton>
                ) : null
              }
            />
            <Divider />
          </>
        ) : null}
        <CardContent>
          <TextField
            id={"category-query"}
            label={"Search Categories"}
            variant={"outlined"}
            placeholder={"Query..."}
            onInput={(e) => setSearchQuery(e.target.value)}
            fullWidth
            autoFocus={autoFocus}
            value={searchQuery}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {!isLoading ? (
            <List
              style={{
                maxHeight: 300,
                overflowY: "scroll",
              }}
            >
              {data?.data?.results.map((v) => {
                return (
                  <ListItem
                    button
                    onClick={doSetSelectedCategory}
                    data-category-id={v.id}
                    data-category-name={v.name}
                    selected={v.id === currentCategory?.id}
                    key={v.id}
                  >
                    <ListItemText primary={v.name} />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={
                          <>
                            {allowEdit && v.name !== "Uncategorized" ? (
                              <IconButton
                                onClick={() => setCategoryToEdit(v)}
                                title={"Edit"}
                              >
                                <EditIcon />
                              </IconButton>
                            ) : null}
                            <Tooltip title={"Number of products"} arrow>
                              <span>{v.total_products}</span>
                            </Tooltip>
                          </>
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div className={classes.spinnerContainer}>
              <img src={logo} alt="Loading..." className="rotate" width="80" />
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

CategorySelector.propTypes = {
  /* ID of the currently selected category. */
  currentCategory: PropTypes.shape({
    id: PropTypes.number,
  }),
  /* Callback function to call when the selected category has been changed. */
  onCategorySelected: PropTypes.func,
  classes: PropTypes.object,
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
  allowEdit: PropTypes.bool,
  allowCreation: PropTypes.bool,
  showHeader: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

CategorySelector.defaultProps = {
  currentCategory: null,
  onSelectedCategory: null,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
});

export default connect(mapStateToProps)(CategorySelector);
