import React from "react";
import { NavDropdown } from "react-bootstrap";
import {
  INVENTORY_STATE_OPTIONS,
  SALE_STATE_OPTIONS,
} from "components/custom/merchandise/constants";
import { toTitleCase } from "utils/formatText";

const LifecycleFilters = ({
  inventoryStateFilter,
  setInventoryStateFilter,
  salesStateFilter,
  setSalesStateFilter,
  enrolledFilter,
  setEnrolledFilter,
  hideSalesStateFilter,
  variantFilter,
  setVariantFilter,
  variants,
}) => {
  const inventoryOptions = [
    {
      value: "all",
      label: "All Inventory States",
    },
    ...INVENTORY_STATE_OPTIONS,
  ];

  const salesStateOptions = [
    {
      value: "all",
      label: "All Sales States",
    },
    ...SALE_STATE_OPTIONS,
  ];

  const enrollOptions = [
    {
      value: "all",
      label: "All Enrollment States",
    },
    {
      value: "enrolled",
      label: "Enrolled Only",
    },
    {
      value: "not_enrolled",
      label: "Not Enrolled Only",
    },
    {
      value: "profiling",
      label: "Price Profiling Only",
    },
    {
      value: "rejected",
      label: "Rejected",
    },
  ];

  const variantOptions = [
    {
      value: "all",
      label: "All Variants",
    },
  ];

  if (variants) {
    Object.keys(variants).forEach((variant) => {
      variantOptions.push("divider");
      const vr = variants[variant];
      for (let i = 0; i < vr.length; i++) {
        const v = vr[i];
        const key = `${variant} - ${v}`;
        variantOptions.push({
          value: key,
          label: toTitleCase(key),
        });
      }
    });
  }

  return (
    <div className="px-5 mt-2 d-flex">
      <NavDropdown
        id="DateBreadcrumbComparison"
        title={inventoryStateFilter?.label}
        className={
          hideSalesStateFilter
            ? "fs-standard d-flex justify-content-center text-center"
            : "fs-standard"
        }
        style={{ minWidth: "200px" }}
      >
        {inventoryOptions.map((option, index) => (
          <NavDropdown.Item
            key={index}
            title={inventoryStateFilter?.label}
            onClick={() => setInventoryStateFilter(option)}
          >
            {option.label}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
      {!hideSalesStateFilter && (
        <NavDropdown
          id="DateBreadcrumbComparison"
          title={salesStateFilter?.label}
          className="fs-standard"
        >
          {salesStateOptions?.map((option, index) => (
            <NavDropdown.Item
              key={index}
              onClick={() => setSalesStateFilter(option)}
            >
              {option.label}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      )}
      {enrolledFilter && (
        <NavDropdown
          id="DateBreadcrumbComparison"
          title={enrolledFilter?.label}
          className="fs-standard"
        >
          {enrollOptions?.map((option, index) => (
            <NavDropdown.Item
              key={index}
              onClick={() => setEnrolledFilter(option)}
            >
              {option.label}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      )}
      {variants && variantOptions.length > 1 && (
        <NavDropdown
          id="DateBreadcrumbComparison"
          title={variantFilter?.label}
          className="fs-standard"
        >
          {variantOptions?.map((option, index) => {
            if (option === "divider") {
              return <NavDropdown.Divider key={index} />;
            }
            return (
              <NavDropdown.Item
                key={index}
                onClick={() => setVariantFilter(option)}
              >
                {option.label}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      )}
    </div>
  );
};

export default LifecycleFilters;
