import React from "react";
import { NavLink } from "react-router-dom";
import { useDates } from "dates/useDates";
import { generateUrl } from "dates/utils";
import useDashboardTheme from "hooks/useDashboardTheme";

const SidebarLink = ({
  icon,
  children,
  isActive,
  to,
  small = false,
  section = "main",
  primarySidebarLink = false,
}) => {
  const dates = useDates();
  const url = generateUrl(to, dates);

  const { sidebarColor } = useDashboardTheme();
  const highlightLink = isActive.trim() && primarySidebarLink;

  return (
    <li
      className={`${isActive} ${highlightLink ? "highlight" : ""}`}
      style={{
        width: "100%",
        borderRadius: "5px",
        marginBottom: small ? "inherit" : "1.5rem",
      }}
    >
      <NavLink
        to={url}
        className={`${
          small ? "" : "nav-link"
        } sidebar-nav-link ${section}-section`}
        style={{
          cursor: "pointer",
          backgroundColor: highlightLink ? sidebarColor : "inherit",
          borderBottom: isActive.trim() ? `1px solid ${sidebarColor}` : "",
          borderBottomWidth: "8%",
          borderRadius: highlightLink ? "5px" : 0,
          ...(small ? {} : { marginBottom: ".5rem" }),
        }}
      >
        {small ? (
          <>
            <span className="sidebar-mini text-start ps-1">{icon}</span>
            <span className="sidebar-normal sidebar-link">{children}</span>
          </>
        ) : (
          <>
            {icon}
            <p
              style={{
                color: highlightLink ? "#FFF" : "#2e0054",
              }}
            >
              {children}
            </p>
          </>
        )}{" "}
      </NavLink>
    </li>
  );
};

export default SidebarLink;
