import React, { useMemo } from "react";
import Checkbox from "components/core/basic/CheckBox";
import ReactTable from "react-table-6";
import ReactTablePagination from "../../../custom/growth/ProductsCVTablePagination";
import FormGroupTile from "components/core/basic/FormGroupTile";
import useColumnSelector from "hooks/useColumnSelector";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import { getURLPrefix } from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

const EditCell = (props) => {
  let { id, fulfillment_channel } = props.original ?? {};

  if (props.column.format === "checkbox") {
    let { id } = props.original ?? {};

    return (
      <div className="d-flex justify-content-center">
        <Checkbox
          inline
          number={`${props.column.id}${id}PrimeEligible`}
          label={null}
          checked={props.original[props.column.id]}
          disabled={false}
          onClick={(e) => {
            props.column.handleUpdate(
              id,
              props.column.id,
              !props.original[props.column.id]
            );
          }}
        />
      </div>
    );
  }
  return (
    <>
      <FormGroupTile
        type="formcontrol"
        decimalInput={
          props.column.format === "currency" || props.column.format === "number"
        }
        stateName={props.stateName}
        handleChildFormElement={(key, value) => {
          if (
            props.column.format === "number" ||
            props.column.format === "currency"
          ) {
            if (/^\d*\.?\d*$/.test(value) || value === "") {
              props.column.handleUpdate(id, props.column.id, value);
            }
            return;
          }
          props.column.handleUpdate(id, props.column.id, value);
        }}
        disabled={
          props.column.id === "shipping_cost" && fulfillment_channel === "AFN"
        }
        formControlClass="no-arrows text-center"
        value={props.original[props.column.id]}
        placeholder={
          props.column.id === "shipping_cost" && fulfillment_channel === "AFN"
            ? "Fulfilled by Amazon"
            : props.column.format === "currency"
            ? `${MARKETPLACE_CURRENCY_DENOMINATION[props.column.marketPlace]}`
            : null
        }
      />
    </>
  );
};

const ProductDetailsDialogue = ({
  products,
  marketPlace,
  updateProductDetails,
  updateLandedCostDetails,
  landedCostDetails,
  editModal,
}) => {
  const handleProductDetailsUpdate = (id, key, value) => {
    let updatedProductDetails = products;
    updatedProductDetails.find((c) => c.id === id)[key] = value;
    updateLandedCostDetails(updatedProductDetails);
  };

  const [productColumns] = useColumnSelector(() => {
    let columns = [
      {
        id: "product",
        Header: "Product",
        width: 290,
        style: {
          textAlign: "left",
          color: "rgb(115, 112, 110)",
          cursor: "pointer",
        },
        Cell: (props) => {
          const productId = props?.original?.id;
          const image = `${URL_PREFIX}/api/productimage/?product=${productId}`;
          return (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  flexShrink: 0,
                  width: 50,
                  height: 50,
                  marginRight: "10px",
                  background: `url(${image}) no-repeat center / contain`,
                }}
                draggable="false"
              />
              <div>
                {props?.original.product_title}
                <br />
                <i
                  style={{
                    fontWeight: "light",
                    color: "#666",
                    fontSize: "1rem",
                  }}
                >
                  {props?.original.asin} ({props?.original.sku})
                </i>
              </div>
            </div>
          );
        },
        accessor: "name",
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
      {
        id: "landed_cost",
        accessor: "landed_cost",
        Header: "Landed Cost",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        width: 200,
        Cell: EditCell,
        handleUpdate: handleProductDetailsUpdate,
        format: "currency",
        marketPlace: marketPlace,
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
      {
        id: "shipping_cost",
        accessor: "shipping_cost",
        Header: "Shipping Cost",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        width: 200,
        Cell: EditCell,
        handleUpdate: handleProductDetailsUpdate,
        format: "currency",
        marketPlace: marketPlace,
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
    ];
    return columns;
  }, [landedCostDetails]);

  const filteredProducts = useMemo(() => {
    let filtered = products.filter(
      (p) => p && (!p?.landed_cost || typeof p?.landed_cost === "string")
    );
    return filtered;
  }, [products]);

  return (
    <>
      <ReactTable
        className="pr-5"
        showPaginationTop={true}
        showPaginationBottom={false}
        showPageSizeOptions={false}
        defaultPageSize={10}
        // Below is needed to eliminate the no data message that covers table headers if no data to render
        NoDataComponent={() => <></>}
        minRows={0}
        columns={productColumns}
        PaginationComponent={ReactTablePagination}
        getPaginationProps={() => ({
          clearFilters: () => {},
          hideFilters: true,
          table: "category-targetting-params",
          style: { marginBottom: "1rem" },
          carouselLayout: false,
          disableSearchFocus: true,
          searchWidth: "280px",
          hideSearch: true,
          singlePage: true,
        })}
        style={{
          borderRadius: "15px",
          border: "none",
          boxShadow: "none",
        }}
        data={filteredProducts ?? []}
        filterable={false}
        sortable={false}
      />
    </>
  );
};

export default ProductDetailsDialogue;
