export const getBadgeVariant = (kw) => {
  if (kw.cr > 0.05) {
    return "tr-success";
  } else if (kw.cr > 0.03) {
    return "tr-info";
  } else if (kw.cr > 0.01) {
    return "tr-warning";
  }
  return "tr-secondary";
};
