import React from "react";

const ExpanderCell = ({
  row,
  isExpanded,
  setIsExpanded,
  value,
  valueAccessor = "id",
  showCheckbox = false,
  selectedProducts,
  setSelectedProducts,
}) => {
  if (!value) {
    value = valueAccessor ? row._original[valueAccessor] : row._original.id;
  }

  const onCheckChange = (checked) => {
    const value = row._original.id;
    if (checked) {
      if (selectedProducts === undefined) {
        setSelectedProducts([value]);
      } else {
        setSelectedProducts([...selectedProducts, value]);
      }
    } else {
      setSelectedProducts(selectedProducts.filter((asin) => asin !== value));
    }
  };

  return (
    <div
      style={{
        cursor: "pointer",
        fontSize: "4rem",
        lineHeight: "20px",
      }}
    >
      {showCheckbox && (
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            float: "left",
            paddingLeft: "2px",
          }}
        >
          <input
            type="checkbox"
            checked={selectedProducts?.includes(row._original.id)}
            onChange={(e) => {
              onCheckChange(e.target.checked);
            }}
          />
        </div>
      )}

      {isExpanded === value ? (
        <span onClick={() => setIsExpanded(null)}>
          <i className="pe-7s-angle-down" />
        </span>
      ) : (
        <span onClick={() => setIsExpanded(value)}>
          <i className="pe-7s-angle-right" />
        </span>
      )}
    </div>
  );
};

export default ExpanderCell;
