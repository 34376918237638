import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col } from "react-bootstrap";
import { formatCurrency } from "../../utils/formatNumber";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import moment from "moment";

const PricingCharts = ({
  optData,
  marketPlace,
  isWide,
  selectedPrices,
  height,
  fromExplorer,
}) => {
  if (height === undefined) {
    height = 400;
  }
  const [demandChartOptions, setDemandChartOptions] = useState(null);
  const [period, setPeriod] = useState("daily");
  const [allOrderHistory, setAllOrderHistory] = useState(optData.order_history);

  const splitInfo = () => {
    if (period === "daily") {
      setAllOrderHistory(optData.order_history);
      return;
    }

    let dt = moment(new Date());
    dt = dt.subtract(365, "days");
    const orderHistory = {};
    let key = null;

    for (let i = 0; i < 365; i++) {
      if (period === "monthly") {
        key = dt.format("YYYY-MM");
      } else if (period === "weekly") {
        if (!key) {
          key = dt.format("YYYY-MM-DD");
        } else if (dt.isoWeekday() === 1) {
          key = dt.format("YYYY-MM-DD");
        }
      }
      if (!orderHistory[key]) {
        orderHistory[key] = {
          bsr: [],
          qty: [],
          price: [],
          profit: [],
        };
      }

      const dtKey = dt.format("YYYY-MM-DD");
      const data = optData.order_history[dtKey];

      dt = dt.add(1, "days");
      if (!data) {
        continue;
      }

      orderHistory[key].bsr.push(data.bsr);
      orderHistory[key].qty.push(data.qty);
      orderHistory[key].price.push(data.price);
      orderHistory[key].profit.push(data.profit);
    }

    Object.keys(orderHistory).forEach((key) => {
      if (orderHistory[key].bsr.length === 0) {
        orderHistory[key].bsr = 0;
        orderHistory[key].qty = 0;
        orderHistory[key].price = 0;
        orderHistory[key].profit = 0;
      } else {
        orderHistory[key].bsr =
          orderHistory[key].bsr.reduce((ps, a) => ps + a, 0) /
          orderHistory[key].bsr.length;
        orderHistory[key].qty = orderHistory[key].qty.reduce(
          (ps, a) => ps + a,
          0
        );
        orderHistory[key].price =
          orderHistory[key].price.reduce((ps, a) => ps + a, 0) /
          orderHistory[key].price.length;
        orderHistory[key].profit = orderHistory[key].profit.reduce(
          (ps, a) => ps + a,
          0
        );
      }
    });
    setAllOrderHistory(orderHistory);
  };

  useEffect(() => {
    if (optData && optData.order_history) {
      splitInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optData, period]);

  useEffect(() => {
    if (optData.order_history) {
      setDemandChartOptions(getDemandChartOptions(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrderHistory, selectedPrices]);

  const getDemandChartOptions = (hoverPrice) => {
    const cats = [];
    const demandData = [];
    const priceData = [];
    const bsrData = [];
    const profitData = [];
    const salesData = [];
    const marginData = [];
    let areaData = [];
    let maxPrice = 0.0;

    Object.keys(allOrderHistory).map((k) => {
      if (allOrderHistory[k].price > maxPrice) {
        maxPrice = allOrderHistory[k].price;
      }
      return null;
    });

    Object.keys(allOrderHistory).map((k, i) => {
      cats.push(k);
      demandData.push(allOrderHistory[k].qty);
      priceData.push(allOrderHistory[k].price);
      salesData.push(allOrderHistory[k].qty * allOrderHistory[k].price);

      if (allOrderHistory[k].profit) {
        profitData.push(allOrderHistory[k].profit);
        marginData.push(
          (allOrderHistory[k].profit / salesData[salesData.length - 1]) * 100
        );
      } else {
        marginData.push(0.0);
        profitData.push(0.0);
      }

      if (allOrderHistory[k].bsr) {
        bsrData.push(allOrderHistory[k].bsr);
      } else {
        bsrData.push(0);
      }

      if (
        selectedPrices &&
        selectedPrices.indexOf(allOrderHistory[k].price) > -1
      ) {
        areaData.push(maxPrice);
      } else {
        areaData.push(null);
      }

      return null;
    });

    const mabsr = [];
    // let maPeriod = 7;

    // if (period === "monthly") {
    //   maPeriod = 1;
    // } else if (period === "weekly") {
    //   maPeriod = 2;
    // }

    // let sumForAvgBSR = 0;
    Object.keys(allOrderHistory).map((k, i) => {
      // sumForAvgBSR += bsrData[i];
      // if (i < maPeriod) {
      //   mabsr.push(null);
      // } else {
      //   sumForAvgBSR -= bsrData[i - maPeriod];
      //   mabsr.push(sumForAvgBSR / maPeriod);
      // }
      mabsr.push(bsrData[i]);
      return null;
    });

    const options = {
      chart: {
        height: height,
        type: "column",
        zoomType: "x",
      },
      title: {
        text: null,
      },
      yAxis: [
        {
          title: {
            text: "Price",
          },
          opposite: true,
        },
        {
          title: {
            text: "Volume",
          },
        },
        {
          title: {
            text: "BSR Rank",
          },
          visible: false,
        },
        {
          title: {
            text: "Profit/Sales",
          },
          visible: false,
        },
        {
          title: {
            text: "Margin",
          },
          visible: false,
        },
      ],
      xAxis: {
        title: {
          text: "Date",
        },
        labels: {
          enabled: false,
        },
        categories: cats,
      },
      legend: {
        enabled: true,
        verticalAlign: "bottom",
      },
      series: [
        {
          name: null,
          data: areaData,
          type: "area",
          color: "#808080",
          opacity: 0.5,
          showInLegend: false,
        },

        {
          name: "Demand",
          data: demandData,
          yAxis: 1,
          type: "column",
          color: "#8a0a5c",
          visible: false,
        },
        {
          name: "Sales",
          data: salesData,
          color: "#d71166",
          yAxis: 3,
          type: "area",
          opacity: 0.5,
        },
        {
          name: "Profit",
          data: profitData,
          color: "#8a0a5c",
          yAxis: 3,
          type: "area",
          opacity: 0.5,
          visible: false,
        },
        {
          name: "Price",
          data: priceData,
          type: "line",
          color: "#d71166",
        },
        {
          name: "Organic Ranking",
          data: mabsr,
          type: "line",
          color: "#573675",
          yAxis: 2,
          visible: false,
        },
        {
          name: "Margin",
          data: marginData,
          type: "line",
          color: "#573675",
          yAxis: 4,
          visible: false,
        },
      ],
      tooltip: {
        formatter: function () {
          const idx = this.point.index;
          const html = [];
          html.push(`<div>`);
          html.push(`<div><strong>Performance on: ${this.x}</strong></div>`);
          html.push(
            `<div><strong>Price</strong>: ${formatCurrency(
              priceData[idx],
              marketPlace.marketPlace
            )}</div>`
          );
          html.push(
            `<div><strong>Sales</strong>: ${formatCurrency(
              salesData[idx],
              marketPlace.marketPlace
            )}</div>`
          );
          html.push(
            `<div><strong>Profit</strong>: ${formatCurrency(
              profitData[idx],
              marketPlace.marketPlace
            )}</div>`
          );
          html.push(
            `<div><strong>Units Sold</strong>: ${demandData[idx].toFixed(
              0
            )}</div>`
          );
          html.push(
            `<div><strong>Margin</strong>: ${marginData[idx].toFixed(0)}%</div>`
          );
          html.push(
            `<div><strong>BSR Ranking</strong>: ${
              mabsr[idx] ? mabsr[idx].toFixed(0) : 0
            }</div>`
          );

          html.push(`</div>`);

          return html.join(`<br style="padding-top: 200px"/>`);
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
              maxHeight: 1300,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };

    return options;
  };

  if (!optData.order_history) {
    return null;
  }

  const monthlyProfitOver = [];
  const monthlyProfitBest = [];
  const priceCategory = [];
  if (optData?.pricing_data) {
    optData.pricing_data
      .filter((r) => {
        return r.num_days_at_price > 0;
      })
      .map((k) => {
        if (k.best_price) {
          monthlyProfitOver.push(null);
          monthlyProfitBest.push(k.monthly_profit);
        } else {
          monthlyProfitOver.push(k.monthly_profit);
          monthlyProfitBest.push(null);
        }

        priceCategory.push(k.price);
        return null;
      });
  }

  return (
    <Row className={`pb-5`}>
      <Col xs={12} md={12} lg={12}>
        <Row className="pb-3">
          <Col xs={4}></Col>
          <Col xs={4}>
            {!fromExplorer && (
              <h6 className={`text-center pt-3`}>Pricing Trends</h6>
            )}
          </Col>
          <Col xs={4} className="text-end">
            <ButtonGroup>
              <Button
                variant={period === "monthly" ? `contained` : null}
                color={period === "monthly" ? `secondary` : null}
                onClick={() => setPeriod("monthly")}
              >
                Month
              </Button>
              <Button
                variant={period === "weekly" ? `contained` : null}
                color={period === "weekly" ? `secondary` : null}
                onClick={() => setPeriod("weekly")}
              >
                Week
              </Button>
              <Button
                variant={period === "daily" ? `contained` : null}
                color={period === "daily" ? `secondary` : null}
                onClick={() => setPeriod("daily")}
              >
                Day
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <HighchartsReact highcharts={Highcharts} options={demandChartOptions} />
      </Col>
    </Row>
  );
};

export default PricingCharts;
