import React from "react";
import { connect } from "react-redux";
import { useDates } from "dates/useDates";
import DetailedMetricRow from "components/core/blocks/DetailedMetricRow";
import ContentIcon from "assets/images/icons/product.svg";
import ThemeLine from "../ThemeLine";

const ContentCard = ({ marketPlace, category = null, metrics, data }) => {
  const { group } = useDates();

  return (
    <DetailedMetricRow
      small
      title={"Content"}
      to={`/user/content/quality/${category ? `category/${category}` : "org"}`}
      byline={
        <>
          <ThemeLine>Product</ThemeLine>
          Measure product content quality and conversion rate
        </>
      }
      data={data ?? []}
      metrics={metrics}
      group={group}
      icon={ContentIcon}
    />
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(ContentCard);
