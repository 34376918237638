import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Badge } from "react-bootstrap";
import { useFetch } from "hooks/api";
import { formatCurrency } from "utils/formatNumber";
import useColumnSelector from "hooks/useColumnSelector";
import ReactTable from "react-table-6";
import moment from "moment";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import { LogoutButton } from "components/core/basic/Button.jsx";
import Loading from "components/core/blocks/Loading";
import { Link } from "react-router-dom";
import { PRODUCT_AMAZON_LINKS } from "utils/marketplaceConstants";
import { ConfirmationContext } from "../context";
import { ProductImageURL } from "components/custom/category/listings/ProductImage";
import { cut } from "utils/formatText";

export const ConfirmationProvider = ({
  children,
  pricingProduct,
  pricingPlan,
  marketPlace,
  date,
  limit = 20,
  count = false,
  priceDiff = null,
}) => {
  const [confirmations, setConfirmations] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [search, setSearch] = useState("");

  const baseQuery = {
    ordering: "-date",
    limit: limit,
    market_place: marketPlace.marketPlace,
  };

  if (pricingProduct) {
    baseQuery.pricing_product = pricingProduct?.id;
  }
  if (pricingPlan) {
    baseQuery.plan = pricingPlan?.id;
  }
  if (date) {
    baseQuery.date = moment(date).format("YYYY-MM-DD");
  }

  if (priceDiff !== null && priceDiff === "INCREASE") {
    baseQuery.price_diff = "INCREASE";
  } else if (priceDiff !== null && priceDiff === "DECREASE") {
    baseQuery.price_diff = "DECREASE";
  } else if (priceDiff !== null && priceDiff === "SAME") {
    baseQuery.price_diff = "SAME";
  }

  const { isLoading } = useFetch(
    [
      "pricing/confirmations",
      pricingProduct,
      limit,
      marketPlace.marketPlace,
      priceDiff,
    ],
    "/pricing/confirmations",
    { ...baseQuery },
    {
      default: [],
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setConfirmations(res.data.results);
      },
    },
    [pricingProduct, marketPlace.marketPlace, date]
  );

  useEffect(() => {
    applyFilters();
  }, [confirmations, search]); // eslint-disable-line react-hooks/exhaustive-deps

  const applyFilters = () => {
    if (search?.length > 0) {
      setFilteredData(
        confirmations.filter(
          (row) =>
            row.identifiers.asin.toLowerCase().indexOf(search.toLowerCase()) >
              -1 ||
            row.identifiers.sku.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
      );
    } else {
      setFilteredData(null);
    }
  };

  return (
    <ConfirmationContext.Provider
      value={{ confirmations, filteredData, search, setSearch, isLoading }}
    >
      {children}
    </ConfirmationContext.Provider>
  );
};

const ConfirmationSection = ({
  pricingProduct,
  pricingPlan,
  marketPlace,
  date,
  user,
  limit = 20,
  priceDiff = null,
  usePricingPlan = false,
}) => {
  const [colToggle] = useState();
  // const [confirmationError, setConfirmationError] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const [verifications, setVerifications] = useState([]);
  const [showCheckModal, setShowCheckModal] = useState(false);

  const tableRef = useRef();

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let columns = [
        {
          id: "SKU",
          label: "SKU",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span style={{ position: "relative" }}>SKU</span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "left" },
          style: { textAlign: "left" },
          accessor: "pricing_product",
          width: 400,
          Cell: (props) => {
            return (
              <>
                <div class="price-log-grid-container">
                  <div
                    class="price-log-product-image"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                    }}
                  >
                    <ProductImageURL
                      url={props.original.identifiers.image_url}
                      width="50px"
                      height="50px"
                    />
                  </div>
                  <div class="price-log-product-title">
                    {!usePricingPlan && (
                      <Link
                        to={`/user/merchandising/product/${props.original.identifiers.product_id}`}
                        target="_blank"
                      >
                        {cut(props.original.identifiers.title, 50)}
                      </Link>
                    )}
                    {usePricingPlan && (
                      <Link
                        to={`/user/_pricing/plan/${props.original.identifiers.plan_id}`}
                        target="_blank"
                      >
                        {cut(props.original.identifiers.plan_name, 50)}
                      </Link>
                    )}
                  </div>
                  <div class="price-log-product-sku">
                    <span>
                      <small>
                        {props.original.identifiers.sku}
                        &nbsp;-&nbsp;
                        <a
                          href={`${
                            PRODUCT_AMAZON_LINKS[marketPlace.marketPlace]
                          }${props.original.identifiers.asin}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.original.identifiers.asin}
                        </a>
                      </small>
                    </span>
                  </div>
                </div>
              </>
            );
          },
        },
        // {
        //   id: "PricingPlan",
        //   label: "Pricing Plan",
        //   Header: (props) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         textAlign: "center",
        //       }}
        //     >
        //       <p style={{ position: "relative" }}></p>
        //       <span style={{ position: "relative", paddingRight: "1rem" }}>
        //         Pricing Plan
        //       </span>
        //     </div>
        //   ),
        //   headerClassName: "wordwrap",
        //   headerStyle: { textAlign: "center" },
        //   style: { textAlign: "center" },
        //   accessor: "pricing_plan",
        //   Cell: (props) => {
        //     return (
        //       <div>
        //         <div className="pb-3">
        //           <Link
        //             to={`/user/_pricing/plan/${props.original.identifiers.plan_id}`}
        //             target="_blank"
        //           >
        //             {props.original.identifiers.plan_name}
        //           </Link>
        //         </div>
        //         <div>&nbsp;</div>
        //       </div>
        //     );
        //   },
        // },
        {
          id: "DateChanged",
          label: "Date Changed",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ position: "relative" }}></p>
              <span style={{ position: "relative", paddingRight: "1rem" }}>
                Date Changed
              </span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "date",
          Cell: (props) => {
            return (
              <div>
                <div className="pb-3">
                  {moment(props.value).format("MMM DD YYYY")}
                </div>
                <div>&nbsp;</div>
              </div>
            );
          },
        },
        // {
        //   id: "Status",
        //   Header: (props) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         textAlign: "center",
        //       }}
        //     >
        //       <span style={{ position: "relative" }}>Status</span>
        //     </div>
        //   ),
        //   headerClassName: "wordwrap",
        //   headerStyle: { textAlign: "center" },
        //   style: { textAlign: "center" },
        //   accessor: "status",
        //   Cell: (props) => {
        //     if (
        //       props.value === "REJECTED" ||
        //       props.value === "FAILED" ||
        //       props.value === "INVALID"
        //     ) {
        //       return (
        //         <>
        //           <i className="pe-7s-attention pr-3 text-danger"></i>
        //           <span>{props.value}</span>
        //         </>
        //       );
        //     }
        //     return props.value;
        //   },
        // },
        {
          id: "Date Confirmed",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <span style={{ position: "relative" }}>Date</span>
              <span style={{ position: "relative" }}>Confirmed</span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "confirmation_date",
          Cell: (props) => {
            // if (
            //   props.value === "REJECTED" ||
            //   props.value === "FAILED" ||
            //   props.value === "INVALID"
            // ) {
            //   return (
            //     <>
            //       <i className="pe-7s-attention pr-3 text-danger"></i>
            //       <span>{props.value}</span>
            //     </>
            //   );
            // }
            // return props.value;

            return (
              <div className="pb-3">
                {props.value.substr(0, 10) === "2022-01-01" ? (
                  <span>-</span>
                ) : (
                  <span>
                    {moment(props.value).format("MMM DD YYYY, HH:mm")}
                  </span>
                )}
                <div>
                  <small>
                    {props.original.status === "REJECTED" ||
                    props.original.status === "FAILED" ||
                    props.original.status === "INVALID" ? (
                      <>
                        <i className="pe-7s-attention pr-3 text-danger"></i>
                        <span>{props.original.status}</span>
                      </>
                    ) : (
                      <span>{props.original.status}</span>
                    )}
                  </small>
                </div>
              </div>
            );
          },
        },
        {
          id: "Previous Price",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <span style={{ position: "relative" }}>Previous</span>
              <span style={{ position: "relative" }}>Price</span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "previous_price",
          Cell: (props) => {
            return props.value > 0
              ? `${formatCurrency(props.value, marketPlace)}`
              : "";
          },
        },
        {
          id: "New Price",
          Header: (props) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <span style={{ position: "relative" }}>New</span>
              <span style={{ position: "relative" }}>Price</span>
            </div>
          ),
          headerClassName: "wordwrap",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "new_price",
          Cell: (props) => {
            return props.value > 0 ? (
              <span>
                {formatCurrency(props.value, marketPlace)}

                {props.original.verifications.length > 0 && (
                  <span className="ps-4">
                    <Badge
                      className={`badge bg-trellis-purple text-end`}
                      style={{
                        borderRadius: "5px",
                      }}
                      type="button"
                      onClick={() => {
                        setShowCheckModal(true);
                        setVerifications(props.original.verifications);
                      }}
                    >
                      {props.original.verifications.length}{" "}
                    </Badge>
                  </span>
                )}
              </span>
            ) : (
              ""
            );
          },
        },
        // {
        //   id: "Is Rollback",
        //   Header: (props) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         textAlign: "center",
        //       }}
        //     >
        //       <span style={{ position: "relative" }}>Is</span>
        //       <span style={{ position: "relative" }}>Rollback</span>
        //     </div>
        //   ),
        //   headerClassName: "wordwrap",
        //   headerStyle: { textAlign: "center" },
        //   style: { textAlign: "center" },
        //   accessor: "is_rollback",
        //   Cell: (props) => {
        //     return <span>{props.value ? "Yes" : "No"}</span>;
        //   },
        // },
        // {
        //   id: "Issues",
        //   Header: (props) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         textAlign: "center",
        //       }}
        //     >
        //       <p style={{ position: "relative" }}></p>
        //       <span style={{ position: "relative", paddingRight: "1rem" }}>
        //         Issues
        //       </span>
        //     </div>
        //   ),
        //   headerClassName: "wordwrap",
        //   headerStyle: { textAlign: "center" },
        //   style: { textAlign: "center" },
        //   accessor: "issues",
        //   Cell: (props) => {
        //     if (
        //       props.value.length > 0 &&
        //       props.original.status !== "ACCEPTED"
        //     ) {
        //       return (
        //         <Badge
        //           className={`badge bg-trellis-yellow text-end`}
        //           style={{
        //             borderRadius: "5px",
        //           }}
        //           type="button"
        //           onClick={() => {
        //             setConfirmationError(props.original);
        //             setShowModal(true);
        //           }}
        //         >
        //           {props.value.length}
        //         </Badge>
        //       );
        //     }
        //     return "-";
        //   },
        // },
      ];

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle]
  );

  return (
    <ConfirmationProvider
      pricingProduct={pricingProduct}
      pricingPlan={pricingPlan}
      marketPlace={marketPlace}
      date={date}
      limit={limit}
      priceDiff={priceDiff}
    >
      <ConfirmationContext.Consumer>
        {({ confirmations, filteredData, search, setSearch, isLoading }) => {
          if (isLoading) {
            return <Loading fullPage={false} height={`300px`} />;
          }

          return (
            <>
              <div>
                {!isLoading && (
                  <>
                    <ReactTable
                      ref={tableRef}
                      data={filteredData ? filteredData : confirmations}
                      className="pricing-table"
                      PaginationComponent={(props) => {
                        return (
                          <ReactTablePagination
                            {...props}
                            table={"pricing-table"}
                            showFilters={false}
                            style={{ marginBottom: "2rem" }}
                            carouselLayout={true}
                            hideFilters={true}
                            updateSearch={setSearch}
                            clearSearchTermFilters={() => setSearch("")}
                            searchTerm={search}
                            columnSelectorProps={columnSelectorProps}
                            hideSearch={pricingProduct ? true : false}
                          />
                        );
                      }}
                      columns={columns}
                      defaultPageSize={20}
                      // Below is needed to eliminate the no data message that covers table headers if no data to render
                      NoDataComponent={() => null}
                      minRows={0}
                      showPaginationTop
                      showPaginationBottom={false}
                      filterable={false}
                    />
                  </>
                )}

                {!isLoading && confirmations.length === 0 && (
                  <div className="text-center fs-4 pt-5 pb-3">
                    No confirmations found
                  </div>
                )}
              </div>

              <Modal
                show={showCheckModal}
                size="lg"
                onHide={() => setShowCheckModal(false)}
              >
                <Modal.Header>
                  <Row>
                    <Col>
                      <span className="fs-2">Price Verification History</span>
                    </Col>
                  </Row>
                </Modal.Header>

                <Modal.Body>
                  <Row className="px-4">
                    <Col className="fs-4 p-3">
                      <Row className={`pb-2`}>
                        <Col xs={3}>
                          <strong>Verification Attempt</strong>
                        </Col>
                        <Col xs={3} className="text-center">
                          <strong>Status</strong>
                        </Col>
                        <Col xs={3} className="text-end">
                          <strong>Expected Price</strong>
                        </Col>
                        <Col xs={3} className="text-end">
                          <strong>Amazon Price</strong>
                        </Col>
                      </Row>

                      {verifications.map((verification, index) => {
                        return (
                          <Row key={index} className={`pb-2`}>
                            <Col xs={3}>
                              {moment(verification.date).format(
                                "MMM DD YYYY, HH:mm"
                              )}
                            </Col>
                            <Col xs={3} className="text-center">
                              {verification.status}
                            </Col>
                            <Col xs={3} className="text-end">
                              {formatCurrency(
                                verification.trellis_price,
                                marketPlace.marketPlace
                              )}
                            </Col>
                            <Col xs={3} className="text-end">
                              {formatCurrency(
                                verification.amazon_price,
                                marketPlace.marketPlace
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Row>
                    <Col className="text-center">
                      <LogoutButton
                        fontSize="12px"
                        key="RefreshBtn"
                        title={`Close`}
                        onClick={() => setShowCheckModal(false)}
                      />
                    </Col>
                  </Row>
                </Modal.Footer>
              </Modal>

              {/* <Modal
                show={showModal}
                size="lg"
                onHide={() => setShowModal(false)}
              >
                <Modal.Header>
                  <Modal.Title>Price Change Issues</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {confirmationError && (
                    <Row>
                      <Col className="fs-4 p-3">
                        <Row>
                          <Col xs={4}>
                            <strong>SKU</strong>
                          </Col>
                          <Col xs={4}>
                            <strong>Date Changed</strong>
                          </Col>
                          <Col xs={4}>
                            <strong>Status</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>{confirmationError.identifiers.sku}</Col>
                          <Col xs={4}>
                            {moment(confirmationError.date).format(
                              "MMM DD YYYY, HH:mm"
                            )}
                          </Col>
                          <Col xs={4}>{confirmationError.status}</Col>
                        </Row>
                        <Row className="pt-3">
                          <Col xs={4}>
                            <strong>Previous Price</strong>
                          </Col>
                          <Col xs={4}>
                            <strong>New Price</strong>
                          </Col>
                          <Col xs={4}>&nbsp;</Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            {formatCurrency(
                              confirmationError.previous_price,
                              marketPlace
                            )}
                          </Col>
                          <Col xs={4}>
                            {formatCurrency(
                              confirmationError.new_price,
                              marketPlace
                            )}
                          </Col>
                        </Row>
                        <Row className="pt-4">
                          <Col xs={12}>
                            <strong>Issues reported</strong>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          {confirmationError.issues.map((issue, i) => (
                            <Col xs={12} key={`key${i}`}>
                              {issue.message}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <Row>
                    <Col className="text-center">
                      <LogoutButton
                        fontSize="12px"
                        key="RefreshBtn"
                        title={`Close`}
                        onClick={() => setShowModal(false)}
                      />
                    </Col>
                    <Col className="text-center">
                      <LogoutButton
                        fontSize="12px"
                        key="RefreshBtn"
                        title={`Disable`}
                        onClick={() => setShowModal(false)}
                      />
                    </Col>
                  </Row>
                </Modal.Footer>
              </Modal> */}
            </>
          );
        }}
      </ConfirmationContext.Consumer>
    </ConfirmationProvider>
  );
};

export default ConfirmationSection;
