import React from "react";

const AssetLabel = ({ asset }) => {
  const { url_default, name, type } = asset;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "35px auto",
        alignContent: "center",
        borderColor: "#FF0000",
      }}
    >
      <div style={{ maxWidth: "35px", display: "flex", alignItems: "center" }}>
        {type === "VIDEO" ? (
          <video controls className="w-100">
            <source src={`${url_default}`} type="video/mp4" />
          </video>
        ) : (
          <img
            src={url_default}
            alt={name}
            style={{ height: "30px", width: "30px" }}
          />
        )}
      </div>
      <div style={{ padding: "10px" }}>
        <span style={{ marginLeft: "1.5rem", marginBottom: "-.25rem" }}>
          {name}
        </span>
      </div>
    </div>
  );
};

export default AssetLabel;
