import React from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateWalmartOnboardingStatus } from "redux/actions/walmartOnboardActions";
import api from "utils/api";

const WalmartPartnerDialogue = ({ onClick = () => {} }) => {
  const dispatch = useDispatch();
  const { walmart_onboard } = useSelector((state) => state);

  const confirmTrellisAdPartner = useMutation(
    async () => {
      return await api.post("/walmart/auth/onboarding", {
        action: "mark_advertising_partner_complete",
      });
    },
    {
      default: [],
      onSuccess: (res) => {
        dispatch(
          updateWalmartOnboardingStatus({
            ...walmart_onboard,
            partner_access_state: "connected",
          })
        );
      },
    }
  );

  return (
    <>
      <p className="fs-standard text-center">
        Visit the{" "}
        <a
          href="https://advertising.walmart.com/view/admin"
          target="_blank"
          rel="noopener noreferrer"
          className="fw-500 text-pink text-decoration-none"
        >
          Walmart Advertising Portal
        </a>{" "}
        to authorize Trellis as a partner.
      </p>
      <p className="fs-standard text-center">
        <a
          href="https://advertisinghelp.walmart.com/s/guides?article=000009334"
          target="_blank"
          rel="noopener noreferrer"
          className="fw-500 text-pink text-decoration-none"
        >
          Click here
        </a>{" "}
        for detailed instructions. Please note that it may take up to 15 minutes
        for partner access to be processed once granted.
      </p>
      <button
        className="adplan_button w-50 mx-auto no-highlight text-white my-3 fs-5"
        onClick={(e) => {
          e.preventDefault();
          confirmTrellisAdPartner.mutate();
          onClick();
        }}
      >
        Mark Complete
      </button>
    </>
  );
};

export default WalmartPartnerDialogue;
