import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import "assets/css/payment_styles.css";
import americanExpressLogo from "assets/images/logo/american_express.png";
import jcbLogo from "assets/images/logo/jcb.png";
import mastercardLogo from "assets/images/logo/mastercard.png";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import visaLogo from "assets/images/logo/visa.png";
import axios from "axios";
import { AuthButton } from "components/core/basic/Button";
import CustomHref from "components/core/blocks/CustomHref";
import React from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "react-bootstrap-switch";
import store from "redux/store";
import getURLPrefix from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

const options = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#D91266",
      letterSpacing: "1px",
      fontSize: "15px",
      fontWeight: 500,
      fontFamily: "sans-serif",
      "::placeholder": {
        color: "#c3c3c3",
      },
    },
    complete: { color: "#850066" },
    invalid: {
      color: "#ff0033",
    },
  },
};

class CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: this.props.customerId,
      paymentMethodList: [],
      selectedCard: "",
      addCard: false,
      error: {},
      nameOnCard: "",
      activeCard: "",
      billingAddress: {},
      invalidNameOnCard: false,
      cardError: false,
      cardComplete: false,
      addCardActive: false,
    };
  }

  componentDidMount() {
    if (this.props.customerId !== "") {
      this.getUserPaymentMethods();
      this.getActiveCard();
    }
  }

  componentDidUpdate() {
    if (this.state.customerId !== this.props.customerId) {
      this.setState({ customerId: this.props.customerId }, () => {
        this.getUserPaymentMethods();
        this.getActiveCard();
      });
    }
  }

  getActiveCard() {
    const AuthStr = "Bearer ".concat(store.getState().auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/billing/customer", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.data.default_payment_method !== undefined) {
          this.setState({
            activeCard: res.data.default_payment_method,
            billingAddress: res.data.billing_address,
          });
        }
      });
  }

  getUserPaymentMethods() {
    var AuthHeader = "Bearer ".concat(
      store.getState().auth.tokens.access_token
    );
    axios
      .get(
        URL_PREFIX +
          "/api/billing/paymentmethod?customerId=" +
          this.state.customerId,
        {
          params: {},
          headers: { Authorization: AuthHeader },
        }
      )
      .then((res) => {
        if (this.state.selectedCard === "") {
          this.setState({
            paymentMethodList: res.data.cards.data,
            selectedCard: res.data.cards.data[0],
          });
        } else {
          var selCard = {};
          for (let i = 0; i < res.data.cards.data.length; i++) {
            if (this.state.selectedCard.id === res.data.cards.data[i].id) {
              selCard = res.data.cards.data[i];
              break;
            }
          }
          this.setState({
            paymentMethodList: res.data.cards.data,
            selectedCard: selCard,
          });
        }
      });
  }

  updateDefaultPaymentMethod(paymentMethodId) {
    if (store.getState().amz_onboard.org_status === "payment_required") {
      this.retryInvoiceWithNewPaymentMethod({
        customerId: this.state.customerId,
        paymentMethodId: paymentMethodId,
        auth: store.getState().auth.tokens.access_token,
      });
    } else {
      var AuthHeader = "Bearer ".concat(
        store.getState().auth.tokens.access_token
      );
      var data = {
        customerId: this.state.customerId,
        paymentMethodId: paymentMethodId,
      };
      axios
        .put(URL_PREFIX + "/api/billing/paymentmethod", data, {
          params: {},
          headers: { Authorization: AuthHeader },
        })
        .then((res) => {
          this.setState(
            {
              activeCard: paymentMethodId,
            },
            () => {
              this.getUserPaymentMethods();
              this.getActiveCard();
            }
          );
        })
        .catch((err) => {
          this.setState({
            error: {
              title: "Please try again",
              message: "Failed to update your preferences.",
            },
          });
        });
    }
  }

  retryInvoiceWithNewPaymentMethod({ customerId, paymentMethodId, auth }) {
    var paymentInfo = {
      customerId: customerId,
      paymentMethodId: paymentMethodId,
    };

    const AuthHeader = "Bearer ".concat(
      store.getState().auth.tokens.access_token
    );

    return axios
      .post(URL_PREFIX + "/api/billing/retrysubscription", paymentInfo, {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((result) => {
        if (result.error) {
          this.setState({
            error: {
              title: "Improper Card Info",
              message: "Your card was declined, please try a new card.",
            },
          });
          throw result;
        }
        return result;
      })
      .then((result) => {
        if (
          result.data.subscription.status === "active" ||
          result.data.subscription.status === "trialing"
        ) {
          return {
            paymentMethodId: paymentMethodId,
            subscription: result,
          };
        }
      })
      .then(this.handleRequiresPaymentMethod)
      .catch((err) => {
        this.setState({
          error: {
            title: "Improper Card Info",
            message:
              "Your card was declined, please use a different card to pay.",
          },
        });
      });
  }

  handleRequiresPaymentMethod = ({ subscription, paymentMethodId }) => {
    var tempSubscription = subscription.data.subscription;
    if (
      tempSubscription.status !== "trialing" &&
      tempSubscription.status !== "active" &&
      tempSubscription.latest_invoice.payment_intent !== null &&
      tempSubscription.latest_invoice.payment_intent.status !== null
    ) {
      if (
        tempSubscription.latest_invoice.payment_intent.status ===
        "requires_payment_method"
      ) {
        this.setState({
          error: {
            title: "Payment Failed",
            message: "Please use a differnt card for the payment.",
          },
        });
      }
    }
    window.location.reload();
  };

  addNewCard = async (event) => {
    this.setState({ addCardActive: true });
    event.preventDefault();
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      this.setState({
        error: {
          title: "Something's not right",
          message: "Please retry after reloading/refreshing the page.",
        },
        addCardActive: false,
      });
      return;
    }
    this.createPaymentMethod(
      elements.getElement(CardElement),
      this.state.customerId,
      store.getState().auth.tokens.access_token,
      "update"
    );
  };

  createPaymentMethod(cardElement, customerId, auth, action) {
    // TODO: Should this call have stricter rules to prevent accessing it as easily?
    // my concern here is allowing the free creation of a payment Method stripe object
    const { stripe } = this.props;

    // This object needs to have the same information that is used on server when creating customer
    var billing_details = {
      address: {
        country: this.state.billingAddress.country,
        postal_code: this.state.billingAddress.postal_code,
        state: this.state.billingAddress.state,
      },
      name: this.state.nameOnCard,
    };

    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billing_details,
      })
      .then((result) => {
        if (result.error) {
          this.setState({
            error: {
              title: "Improper Card Info",
              message: result.error.message,
            },
            addCardActive: false,
          });
        } else if (action === "update") {
          this.updateSubscription({
            customerId: customerId,
            paymentMethodId: result.paymentMethod.id,
            auth: store.getState().auth.tokens.access_token,
          });
        } else {
          this.setState({ addCardActive: false });
        }
      });
  }

  updateSubscription({ customerId, paymentMethodId, auth }) {
    var paymentInfo = {
      customerId: customerId,
      paymentMethodId: paymentMethodId,
    };
    const AuthHeader = "Bearer ".concat(
      store.getState().auth.tokens.access_token
    );
    return (
      axios
        .post(URL_PREFIX + "/api/billing/paymentmethod", paymentInfo, {
          params: {},
          headers: { Authorization: AuthHeader },
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            this.setState({
              error: {
                title: "Card Error",
                message: "Please try again or try using a different card.",
              },
              addCardActive: false,
            });
            throw result;
          }
          return result;
        })
        .then((result) => {
          //IF successful maked addCard state as false and call getUserPaymentMethods function
          this.setState({ addCardActive: false });
          this.setState({ addCard: false }, () => {
            this.getUserPaymentMethods();
            this.getActiveCard();
          });
          //while processing add new card, disable the add card button
          return {
            paymentMethodId: paymentMethodId,
            subscription: result,
          };
        })
        .catch((err) => {
          this.setState({
            error: {
              title: "Card Error",
              message: "Please try again or try using a different card.",
            },
            addCardActive: false,
          });
        })
    );
  }

  render() {
    const { stripe } = this.props;
    return (
      <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Col xs={12} lg={10} className="mx-auto">
          <div className="card" style={{ paddingBottom: "30px" }}>
            <Row
              className="text-center"
              style={{
                marginBottom: "1rem",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <p
                style={{ marginTop: "2rem", fontSize: "20px", fontWeight: 600 }}
              >
                Payment Information
              </p>
            </Row>
            {this.state.addCard ? (
              <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                <Row
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  className="justify-content-center"
                >
                  <Col md={7} mdOffset={1}>
                    <Row
                      style={{
                        marginTop: "2rem",
                        marginBottom: "2rem",
                        marginLeft: "0px",
                        marginRight: "0px",
                      }}
                    >
                      <Col lg={{ span: 4, offset: 1 }}>
                        <Col md={{ span: 8, offset: 1 }}>
                          <div
                            style={{
                              marginLeft: "0rem",
                              marginTop: "2rem",
                              color: "#000000",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            Name on Card
                          </div>
                        </Col>
                        <Col md={2}>
                          {this.state.nameOnCard !== "" &&
                          this.state.invalidNameOnCard ? (
                            <i
                              style={{ marginTop: "2rem", color: "#FF0000" }}
                              className="fa fa-times"
                            />
                          ) : null}
                        </Col>
                      </Col>
                      <Col md={7}>
                        <FormControl
                          style={{
                            borderRadius: "0px",
                            border: "none",
                            borderBottom: "solid",
                            borderColor:
                              this.state.nameOnCard !== "" &&
                              this.state.invalidNameOnCard
                                ? "#FF0000"
                                : "#C8C8C8",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                          type="text"
                          placeholder="Name on Card"
                          value={this.state.nameOnCard}
                          name="name_on_card"
                          id="name_on_card"
                          onChange={(e) => {
                            var NameOnCardRex = /^[a-zA-Z_]+( [a-zA-Z_]+)*$/;
                            NameOnCardRex.test(e.target.value) === false
                              ? this.setState({
                                  nameOnCard: e.target.value,
                                  invalidNameOnCard: true,
                                })
                              : this.setState({
                                  nameOnCard: e.target.value,
                                  invalidNameOnCard: false,
                                });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                      <Col lg={{ span: 4, offset: 1 }}>
                        <Col md={{ span: 8, offset: 1 }}>
                          <div
                            style={{
                              marginLeft: "0rem",
                              marginTop: "2rem",
                              color: "#000000",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            Card Details
                          </div>
                        </Col>
                        <Col md={2}>
                          {this.state.cardError ? (
                            <i
                              style={{ color: "#FF0000", marginTop: "2rem" }}
                              className="fa fa-times"
                            />
                          ) : null}
                        </Col>
                      </Col>
                      <Col md={7}>
                        <CardElement
                          options={options}
                          onChange={(event) => {
                            if (event.complete) {
                              this.setState({ cardComplete: true });
                            } else {
                              this.setState({ cardComplete: false });
                            }
                            if (event.error) {
                              this.setState({ cardError: true });
                            } else if (event.error === undefined) {
                              this.setState({ cardError: false });
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md={4}>
                    <Row
                      style={{
                        marginTop: this.state.addCardActive ? "1rem" : "7rem",
                        marginBottom: "1rem",
                        paddingBottom: "62px",
                      }}
                      className="wrap px-5"
                    >
                      {!this.state.addCardActive ? (
                        <AuthButton
                          title="Add Card"
                          buttonStyle="adplan_button"
                          disabled={
                            !stripe ||
                            this.state.nameOnCard === "" ||
                            this.state.invalidNameOnCard ||
                            !this.state.cardComplete
                          }
                          onClick={(e) => {
                            this.addNewCard(e);
                          }}
                        />
                      ) : (
                        <div className="text-center">
                          <img
                            src={logo}
                            alt="Loading..."
                            className="rotate"
                            width="70"
                            height="auto"
                          />
                        </div>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginLeft: "3rem", marginBottom: "1rem" }}>
                  <span
                    style={{ fontSize: "14px" }}
                    onClick={(e) => this.setState({ addCard: false })}
                  >
                    {!this.state.addCardActive ? (
                      <CustomHref textDecoration="none" text={"Cancel"} />
                    ) : null}
                  </span>
                </Row>
              </Row>
            ) : (
              <Row className="justify-content-center mx-0">
                <Col md={7} mdOffset={1} style={{ marginTop: "2rem" }}>
                  {this.state.selectedCard !== undefined &&
                  this.state.selectedCard !== "" ? (
                    <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                      <Col md={6}>
                        <table className="table">
                          <tbody style={{ fontSize: "14px" }}>
                            <tr key="name_on_card">
                              <td
                                className="text-start"
                                style={{
                                  fontWeight: 500,
                                  border: "none",
                                }}
                              >
                                Name on Card:
                              </td>
                              <td style={{ border: "none" }}>
                                {this.state.selectedCard.billing_details.name}
                              </td>
                            </tr>
                            <tr key="card_number">
                              <td
                                className="text-start"
                                style={{
                                  fontWeight: 500,
                                  border: "none",
                                }}
                              >
                                Card Number:
                              </td>
                              <td style={{ border: "none" }}>
                                ending in {this.state.selectedCard.card.last4}
                              </td>
                            </tr>
                            <tr key="card_type">
                              <td
                                className="text-start"
                                style={{
                                  fontWeight: 500,
                                  border: "none",
                                }}
                              >
                                Card Type:
                              </td>
                              <td style={{ border: "none" }}>
                                {this.state.selectedCard.card.brand}
                              </td>
                            </tr>
                            <tr key="expiration_date">
                              <td
                                className="text-start"
                                style={{
                                  fontWeight: 500,
                                  border: "none",
                                }}
                              >
                                Expiration Date:
                              </td>
                              <td style={{ border: "none" }}>
                                {this.state.selectedCard.card.exp_month}/
                                {this.state.selectedCard.card.exp_year}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col
                        md={4}
                        className="text-center"
                        style={{ fontSize: "12px" }}
                        mdOffset={1}
                      >
                        <div>
                          <Row>
                            <Col md={6}>
                              <p
                                className="text-center"
                                style={{
                                  textAlign: "left",
                                  marginTop: "3rem",
                                  fontWeight: 500,
                                }}
                              >
                                Active:
                              </p>
                            </Col>
                            <Col md={6} style={{ marginTop: "3rem" }}>
                              <Switch
                                onText=""
                                offText=""
                                value={
                                  this.state.selectedCard.id ===
                                  this.state.activeCard
                                }
                                onColor="#FFFFFF"
                                disabled={
                                  this.state.selectedCard.id ===
                                  this.state.activeCard
                                }
                                onChange={(e) =>
                                  this.updateDefaultPaymentMethod(
                                    this.state.selectedCard.id
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                        {this.state.selectedCard.id ===
                        this.state.activeCard ? (
                          <p style={{ textAlign: "left", marginTop: "2rem" }}>
                            This is your default payment card for all future
                            payments.
                          </p>
                        ) : (
                          <p
                            style={{ textAlign: "left", marginTop: "2rem" }}
                          ></p>
                        )}
                      </Col>
                    </Row>
                  ) : null}
                </Col>
                <Col md={3}>
                  {this.state.paymentMethodList.length ? (
                    <>
                      <div
                        style={{
                          overflowX: "hidden",
                          overflowY: "scroll",
                          height: "200px",
                          padding: "1rem 1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        {this.state.paymentMethodList.length > 0
                          ? this.state.paymentMethodList.map(
                              (paymentMethodItem, index) => {
                                return (
                                  <div
                                    className="card text-center"
                                    key={"paymentMethod" + (index + 1)}
                                    onClick={(e) =>
                                      this.setState({
                                        selectedCard: paymentMethodItem,
                                      })
                                    }
                                    style={{
                                      boxShadow: "none",
                                      marginBottom: "0px",
                                      borderRadius: "10px",
                                      backgroundColor:
                                        this.state.selectedCard.id !==
                                          undefined &&
                                        this.state.selectedCard.id ===
                                          paymentMethodItem.id
                                          ? "#2e0054"
                                          : null,
                                    }}
                                  >
                                    <Row
                                      style={{
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                      }}
                                    >
                                      <div className="header">
                                        <h4
                                          className="title"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            color:
                                              this.state.selectedCard.id !==
                                                undefined &&
                                              this.state.selectedCard.id ===
                                                paymentMethodItem.id
                                                ? "#ffffff"
                                                : "#403E3D",
                                          }}
                                        >
                                          <div style={{ display: "contents" }}>
                                            <img
                                              src={
                                                paymentMethodItem.card.brand ===
                                                "mastercard"
                                                  ? mastercardLogo
                                                  : paymentMethodItem.card
                                                      .brand === "visa"
                                                  ? visaLogo
                                                  : paymentMethodItem.card
                                                      .brand === "amex"
                                                  ? americanExpressLogo
                                                  : paymentMethodItem.card
                                                      .brand === "jcb"
                                                  ? jcbLogo
                                                  : null
                                              }
                                              alt="Logo"
                                              width="36px"
                                              height="22.5px"
                                              style={{ float: "left" }}
                                            />
                                          </div>

                                          <div
                                            style={{
                                              textAlign: "left",
                                              float: "left",
                                              marginLeft: "3rem",
                                              marginBottom: "1rem",
                                              marginRight: "1rem",
                                            }}
                                          >
                                            **** **** ****{" "}
                                            {paymentMethodItem.card.last4}{" "}
                                          </div>

                                          <div
                                            style={{
                                              textAlign: "right",
                                              float: "right",
                                              marginBottom: "1rem",
                                            }}
                                          >
                                            {paymentMethodItem.id ===
                                            this.state.activeCard
                                              ? "active"
                                              : ""}
                                          </div>
                                        </h4>
                                      </div>
                                    </Row>
                                  </div>
                                );
                              }
                            )
                          : null}
                      </div>
                      <Row
                        style={{ marginBottom: "1rem" }}
                        className="text-center"
                      >
                        <span
                          style={{ fontSize: "14px" }}
                          onClick={(e) => this.setState({ addCard: true })}
                        >
                          <CustomHref text={"Add New Card"} />
                        </span>
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            )}
          </div>
        </Col>
        {this.state.error.title !== undefined ? (
          <SweetAlert
            title={this.state.error.title}
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={(e) => this.setState({ error: {} })}
          >
            {this.state.error.message}
          </SweetAlert>
        ) : null}
      </Row>
    );
  }
}

const PaymentCards = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CardForm
          elements={elements}
          stripe={stripe}
          customerId={props.customerId}
        />
      )}
    </ElementsConsumer>
  );
};

export default PaymentCards;
