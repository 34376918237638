import { Grid } from "@material-ui/core";
import { useFetch } from "hooks/api";
import moment from "moment";
import React, { useMemo } from "react";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import ReactTable from "react-table-6";
import { formatNumber } from "utils/formatNumber";
import { trellisPalette } from "./palettes";
import { Link } from "react-router-dom";
import compareBrands from "utils/compareBrands";
import FilterBar from "./FilterBar";
import ProductImage from "../category/listings/ProductImage";
import ReactTablePagination from "../growth/ProductsCVTablePagination";
import { useState } from "react";

const ProductRanksView = ({
  shelfStatsData,
  shelfData,
  selectedShelf,
  homeBrand,
  filterSearchId,
  selectedSearchId,
  filterRollupType,
  selectedRollupType,
  marketPlace,
  categories,
  user,
  setSelectedDateRange,
  dateRange,
  isLoading,
}) => {
  const [searchFilter, setSearchFilter] = useState();
  const shelf = shelfData.find((s) => s.id === selectedShelf);

  const { data: productData } = useFetch(
    ["products", marketPlace],
    "/api/gvads/productid/",
    {
      parent: true,
      marketplace: marketPlace,
    }
  );

  const products = useMemo(() => {
    const products = [];

    if (productData) {
      for (const product of productData.data) {
        if (product.is_parent) {
          for (const child of product.children) {
            products.push(child);
          }
        } else {
          products.push(product);
        }
      }
    }
    return products;
  }, [productData]);

  const getProduct = (asin) => {
    return products.find((p) => p.asin === asin) || { asin };
  };

  const ranks = shelfStatsData
    .filter((d) => compareBrands(d.brand, homeBrand))
    .map((d) => d.ranks.map((r) => ({ ...r, date: d.date })))
    .reduce((a, rs) => {
      rs.map((r) => {
        let data = a.find(
          (x) => x.asin === r.asin && x.search_id === r.search_id
        );
        if (!data) {
          data = {
            asin: r.asin,
            search_id: r.search_id,
            ranks: [],
          };
          a.push(data);
        }
        data.ranks.push(r);
        return a;
      });
      return a;
    }, []);

  const filteredRanks = useMemo(() => {
    let filterData = ranks.filter((p) => {
      const product = getProduct(p.asin);
      const searchString = p.asin + " " + product.product_title;
      if (searchFilter) {
        return (
          searchString?.toLowerCase().indexOf(searchFilter?.toLowerCase()) >= 0
        );
      }
      return true;
    });
    return filterData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranks, searchFilter]);

  let dates = Array.from(new Set(shelfStatsData.map((r) => r.date)));
  dates = dates.map((d) => moment(d)).sort((a, b) => a.diff(b));
  const maxDate = moment.max(dates).format("YYYY-MM-DD");
  dates = dates.map((d) => d.format("YYYY-MM-DD"));

  for (const rank of ranks) {
    rank.currentRank = rank.ranks.find((r) => r.date === maxDate);
    rank.search = shelf?.searches?.find((s) => s.id === rank.search_id) ?? {
      value: "",
    };
  }

  const defaultHeader = {
    headerClassName: "wordwrap",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  };

  const positionByDay = (ranks) => {
    const ret = dates.map((d) => {
      const positions = ranks
        .filter((r) => r.date === d)
        .map((r) => r.position);
      if (positions.length > 0) return 64 - Math.min(...positions);
      return -5;
    });
    return ret;
  };

  return (
    <Grid container style={{ marginTop: "-2rem" }}>
      <FilterBar
        shelfData={shelfData}
        filterSearchId={filterSearchId}
        selectedShelf={selectedShelf}
        selectedSearchId={selectedSearchId}
        selectedRollupType={selectedRollupType}
        filterRollupType={filterRollupType}
        setSelectedDateRange={setSelectedDateRange}
        dateRange={dateRange}
        isLoading={isLoading}
      />
      <Grid item xs={12}>
        <ReactTable
          showPaginationTop
          showPaginationBottom={false}
          defaultPageSize={10}
          className="-highlight"
          pivotBy={["asin"]}
          expanded={ranks.map((r) => true)}
          data={filteredRanks?.length ? filteredRanks : ranks}
          getPaginationProps={() => ({
            updateSearch: setSearchFilter,
            clearFilters: () => {
              setSearchFilter("");
            },
            clearSearchTermFilters: () => setSearchFilter(""),
            searchTerm: searchFilter,
          })}
          getTrProps={(state, rowInfo, instance) => {
            if (!rowInfo?.original?.asin) {
              return {
                style: {
                  fontWeight: 600,
                },
              };
            }
            return {};
          }}
          PaginationComponent={(props) => {
            return (
              <ReactTablePagination
                {...props}
                table={"-highlight"}
                showFilters={false}
                style={{ marginBottom: "2rem" }}
                carouselLayout={false}
                hideFilters={true}
                searchTerm={searchFilter}
              />
            );
          }}
          columns={[
            {
              ...defaultHeader,
              Header: "Product",
              width: 400,
              accessor: "asin",
              Expander: () => "",
              Pivot: (props) => {
                const asin = props.row.asin;
                const product = getProduct(asin);
                if (product.id) {
                  return (
                    <>
                      <div style={{ float: "left" }}>
                        <ProductImage
                          width={"50px"}
                          height={"50px"}
                          id={product.id}
                        />
                      </div>
                      <Link
                        style={{ marginLeft: "1rem" }}
                        key={"parentProductAsinLink" + product.id}
                        to={{
                          pathname: `/user/merchandising/product/${product?.id}`,
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            color: "#d91266",
                            textAlign: "center",
                            float: "center",
                            textDecoration: "underline",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                        >
                          {product.asin}
                        </span>
                        <span
                          hidden={
                            product.sku === "nil" || product.sku === undefined
                          }
                          style={{
                            fontSize: "15px",
                            textAlign: "center",
                            float: "center",
                            fontWeight: 400,
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {product.sku}
                        </span>
                      </Link>
                      <div style={{ display: "flex" }}>
                        <span
                          style={{
                            marginLeft: "1rem",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {product.product_title}
                        </span>
                      </div>
                    </>
                  );
                }
                return product.asin;
              },
            },
            {
              ...defaultHeader,
              Header: "Keyword",
              accessor: "search.value",
              Aggregated: () => "",
            },
            {
              ...defaultHeader,
              width: 100,
              Header: "Position",
              accessor: "currentRank.position",
              sortMethod: (a, b) => {
                if (a === null) return 1;
                if (b === null) return -1;
                return a - b;
              },
              Cell: (props) =>
                props.value > 0 ? `${formatNumber(props.value, null, 0)}` : "",
              aggregate: (vals) =>
                vals.filter((v) => !!v).length > 0
                  ? vals.reduce((a, v) => (!!v ? v + a : a), 0) /
                    vals.filter((v) => !!v).length
                  : null,
              Aggregated: (props) =>
                !!props.value ? `${formatNumber(props.value, null, 0)}` : "",
              style: { textAlign: "center" },
            },
            {
              ...defaultHeader,
              Header: "Trend",
              accessor: "ranks",
              sortable: false,
              Aggregated: () => "",
              Cell: (props) => {
                const data = positionByDay(props.value);
                if (data.filter((d) => !!d).length < 3) {
                  return <span style={{ color: "#666" }}>Not enough data</span>;
                }
                return (
                  <div style={{ marginTop: "-10px", marginBottom: "-10px" }}>
                    <Sparklines
                      data={data}
                      width={100}
                      height={15}
                      margin={4}
                      min={0}
                      max={64}
                    >
                      <SparklinesLine color={trellisPalette[3]} />
                      <SparklinesSpots style={{ fill: trellisPalette[1] }} />
                    </Sparklines>
                  </div>
                );
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ProductRanksView;
