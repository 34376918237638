import React, { useMemo, useState } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import CreateProgram from "./CreateProgram";
import EditProgram from "./EditProgram";
import { useFetch } from "hooks/api";
import ListPrograms from "./ListPrograms";
import { PrimaryButton } from "components/core/basic/Button";
import api from "utils/api";

const useActiveProgram = (defaultValue) => {
  const [activeProgram, setActiveProgram] = useState(defaultValue);
  return useMemo(() => {
    return [activeProgram || defaultValue, setActiveProgram];
  }, [activeProgram, defaultValue]);
};

const InlinePrograms = ({ pricingPlan }) => {
  const [currentProgram, setCurrentProgram] = useState(null);
  const [createProgram, setCreateProgram] = useState(false);
  const queryClient = useQueryClient();
  const [activeProgram, setActiveProgram] = useActiveProgram(
    pricingPlan?.current_program
  );
  const activateProgram = useMutation(
    async (programId) => {
      await api.post(`/pricing/pricingplan/programs/activate`, {
        program_id: programId === "unset" ? null : programId,
        plan_id: pricingPlan.id,
      });
    },
    {
      onMutate: (id) => {
        setActiveProgram(id);
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(["pricing_plans"]),
          queryClient.invalidateQueries(["programs"]),
        ]);
        setActiveProgram(null);
      },
      onError: (error) => {
        setActiveProgram(null);
      },
    }
  );
  const { data, isLoading } = useFetch(
    ["programs", pricingPlan.id],
    "/programs/",
    {
      pricing_plan: pricingPlan.id,
    },
    { select: (data) => data?.data?.results ?? [] }
  );
  const programsData = useMemo(() => {
    return (
      data?.map((program) => ({
        ...program,
        active: activeProgram === program.id ? "active" : "inactive",
      })) ?? []
    );
  }, [activeProgram, data]);

  return (
    <>
      <Row>
        <Col xs={12} className="fs-standard">
          <Breadcrumb>
            {(createProgram || currentProgram) && (
              <Breadcrumb.Item
                onClick={() => {
                  setCreateProgram(false);
                  setCurrentProgram(null);
                }}
              >
                Pricing Rules
              </Breadcrumb.Item>
            )}
            {createProgram && <Breadcrumb.Item active>Create</Breadcrumb.Item>}
            {currentProgram && <Breadcrumb.Item active>Edit</Breadcrumb.Item>}
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {createProgram && (
            <CreateProgram
              pricingPlanId={pricingPlan.id}
              onSuccess={() => {
                setCreateProgram(false);
                setCurrentProgram(null);
                queryClient.invalidateQueries({ queryKey: ["programs"] });
                queryClient.invalidateQueries({ queryKey: ["pricing_plans"] });
              }}
              onCancel={() => {
                setCreateProgram(false);
                setCurrentProgram(null);
              }}
            />
          )}
          {currentProgram && (
            <EditProgram
              programId={currentProgram}
              onSuccess={() => {
                setCreateProgram(false);
                setCurrentProgram(null);
                queryClient.invalidateQueries({ queryKey: ["programs"] });
              }}
              onCancel={() => {
                setCreateProgram(false);
                setCurrentProgram(false);
              }}
            />
          )}
          {!createProgram && !currentProgram && (
            <>
              <Row>
                <Col xs={12}>
                  <ListPrograms
                    isLoading={isLoading}
                    programs={programsData}
                    onClickProgram={(id) => {
                      setCurrentProgram(id);
                      setCreateProgram(false);
                    }}
                    onToggleProgram={(id, value) => {
                      if (activateProgram.isLoading) return;
                      if (value === "active") {
                        activateProgram.mutate(id);
                      } else {
                        activateProgram.mutate("unset");
                      }
                    }}
                    title={"Pricing Rules"}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} className="d-flex justify-content-start">
                  <PrimaryButton nowrap onClick={() => setCreateProgram(true)}>
                    Create
                  </PrimaryButton>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default InlinePrograms;
