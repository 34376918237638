import SellerPerformance from "./Dashboard";

export const SellerPerformanceRoutes = [
  {
    path: "/seller",
    layout: "/user",
    name: "Seller Performance",
    icon: "pe-7s-note2",
    application: "Pricing",
    component: null,
    children: [
      {
        path: "/user/seller/performance",
        component: SellerPerformance,
        exact: true,
        application: "Pricing",
      },
    ],
  },
];
