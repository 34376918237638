import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "assets/css/payment_styles.css";
import axios from "axios";
import { AuthButton } from "components/core/basic/Button";
import CustomHref from "components/core/blocks/CustomHref";
import Invoice from "components/custom/billing/Invoice";
import PaymentInformation from "components/custom/billing/PaymentInformation";
import SubscriptionPlan from "components/custom/billing/SubscriptionPlan";
import TermsOfService from "components/custom/instructions/TermsOfService";
import PropTypes from "prop-types";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { setCurrentUser } from "redux/actions/authActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { getStripePublicKey, getURLPrefix } from "utils/getUrlPrefix";
import checkModule from "utils/checkModule";
import * as Sentry from "@sentry/react";
import { selectModules } from "redux/selectors/modules";

let URL_PREFIX = getURLPrefix();
let STRIPE_PUBLIC_KEY = getStripePublicKey();

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

class Billing extends React.Component {
  constructor() {
    super();
    this.state = {
      customer: "",
      cancelModal: false,
      exitMessage: "",
      cancelTerms: false,
      subscriptionCancelled: false,
      primaryOrg: false,
      monthlyShelfFee: 0,
      currentNumOfShelves: 0,
    };
    this.closeCancelTerms = this.closeCancelTerms.bind(this);
    this.getShelfPrice = this.getShelfPrice.bind(this);
  }

  componentDidMount() {
    if (!this.props.user.organization_admin) {
      this.props.history.push("/user/dashboard");
    }

    axios.patch(URL_PREFIX + "/api/accounts/").then((res) => {
      if (res.status === 200) {
        this.props.setCurrentUser({
          current_profile: res.data.current_profile,
        });
      }
      this.setState({ primaryOrg: true });
    });

    if (
      this.props.amz_onboard.org_status === "trial_active" ||
      this.props.amz_onboard.org_status === "trial_expired" ||
      this.props.amz_onboard.org_status === "pre_trial"
    ) {
      this.props.history.push("/user/payment");
    } else {
      this.getCustomer();
      refreshAmzOnboardStatus(
        this.props.updateAmzOnboardingStatus,
        this.props.auth.tokens.access_token,
        this.props.setErrors
      );
    }

    if (checkModule(this.props.modules, "SEARCH_ANALYTICS")) {
      this.getShelfPrice();
    }
  }

  componentDidUpdate() {
    if (!this.props.user.organization_admin) {
      this.props.history.push("/user/dashboard");
    }
  }

  getCustomer() {
    const AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/billing/customer", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.data.customer !== undefined) {
          this.setState({ customer: res.data.customer });
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 400) {
          Sentry.captureException(error);
        }
      });
  }

  onCancelClick = async (event) => {
    event.preventDefault();
    axios
      .get(URL_PREFIX + "/api/billing/customer", {
        params: {},
      })
      .then((result) => {
        this.cancelSubscription(result.data.id, result.data.customer);
      })
      .catch((error) => {
        if (error?.response?.status !== 400) {
          Sentry.captureException(error);
        }
      });
  };

  cancelSubscription(subscriptionId, customerId) {
    var subscriptionInfo = {
      subscriptionId: subscriptionId,
      customerId: customerId,
      reason: "Deactivated by Trellis staff",
    };
    axios
      .delete(
        URL_PREFIX + "/api/billing/subscription",
        { data: subscriptionInfo },
        {
          params: {},
        }
      )
      .then((result) => {
        this.setState({ cancelModal: false, subscriptionCancelled: true });
      });
  }

  closeCancelTerms() {
    this.setState({ cancelTerms: false });
  }

  getShelfPrice() {
    // Get number of shelves billed for organization to pass into modal
    axios.get(URL_PREFIX + "/api/billing/digitalshelf").then((res) => {
      this.setState({
        monthlyShelfFee: res.data.price,
        currentNumOfShelves: parseInt(res.data.count),
      });
    });
  }

  render() {
    return (
      <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
        {this.state.primaryOrg ? (
          <>
            <Row
              className="text-center"
              style={{
                marginBottom: "2rem",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <h4 className="fw-bold mt-5">Your Billing Information</h4>
            </Row>

            <Row>
              <Elements stripe={stripePromise}>
                <PaymentInformation customerId={this.state.customer} />
              </Elements>
            </Row>

            <Row
              style={{
                marginTop: "2rem",
                marginLeft: "0px",
                marginRight: "0px",
              }}
              className="d-flex justify-content-center"
            >
              <Col xs={12} lg={7}>
                <Invoice customerId={this.state.customer} />
              </Col>
              <Col xs={12} lg={3} className="pr-4">
                <SubscriptionPlan
                  customerId={this.state.customer}
                  monthlyShelfFee={this.state.monthlyShelfFee}
                  currentNumOfShelves={this.state.currentNumOfShelves}
                  getShelfPrice={this.getShelfPrice}
                  subscriptionCancelled={this.state.subscriptionCancelled}
                />
              </Col>
            </Row>

            <Row
              style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Col md={8} offset={2}>
                <span
                  style={{ fontSize: "14px", float: "left" }}
                  onClick={(e) => this.setState({ cancelModal: true })}
                >
                  {this.props.amz_onboard_org_status !== "disconnected" &&
                  this.props.amz_onboard.remainder_days === null ? (
                    <CustomHref text={"Cancel Subscription"} />
                  ) : null}
                </span>
              </Col>
            </Row>
            <div style={{ width: "100%" }}>
              <Modal
                show={this.state.cancelModal}
                onHide={() => this.setState({ cancelModal: false })}
                dialogClassName="modal-50w"
                centered="true"
              >
                <Modal.Body>
                  <div style={{ padding: "1rem 2rem", fontSize: "14px" }}>
                    <Row>
                      <span
                        onClick={() => this.setState({ cancelModal: false })}
                        style={{
                          fontSize: "26px",
                          color: "lightgray",
                          fontWeight: "100",
                          float: "right",
                          cursor: "pointer",
                          textAlign: "end",
                        }}
                      >
                        &#10005;
                      </span>
                    </Row>
                    <Row>
                      <div className="header" style={{ marginBottom: "4rem" }}>
                        <h4
                          className="title text-center"
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "20px",
                            color: "#403E3D",
                            fontWeight: "700",
                          }}
                        >
                          Sorry to see you go
                          <hr />
                        </h4>
                      </div>
                    </Row>
                    <Row>
                      <Col md={8} className="mx-auto">
                        <div
                          className="text-center fs-4"
                          style={{ marginBottom: "1rem" }}
                        >
                          Thanks for using Trellis. We are sorry to hear that
                          you want to cancel.
                          <br />
                          <br />
                          Please reach out to Trellis customer success team to
                          make sure that campaigns get transitioned during the
                          cancellation process.
                        </div>
                      </Col>
                    </Row>
                    <Row
                      className="text-center"
                      style={{ marginTop: "2rem", fontWeight: "500" }}
                    >
                      <Col xs={12} md={8} className="mx-auto fs-4">
                        At sign up you agreed to our cancellation policy, click{" "}
                        <span
                          style={{
                            color: "#D91266",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.setState({ cancelTerms: true });
                          }}
                        >
                          here
                        </span>{" "}
                        to view policy agreement.
                      </Col>
                    </Row>

                    <Row>
                      <Row
                        style={{ marginTop: "4rem", marginBottom: "4rem" }}
                        className="text-center pl-5"
                      >
                        <Col xs={12} md={4} className="mx-auto">
                          {this.props.user?.is_staff ? (
                            <AuthButton
                              title="Cancel Subscription"
                              buttonStyle="adplan_button"
                              onClick={(e) => {
                                this.onCancelClick(e);
                              }}
                            />
                          ) : (
                            <>
                              {" "}
                              {this.props.amz_onboard.org_status !==
                                "disconnected" &&
                              this.props.amz_onboard.remainder_days === null ? (
                                <AuthButton
                                  title="Contact Us To Cancel"
                                  buttonStyle="adplan_button"
                                  onClick={(e) => {
                                    window.location =
                                      "mailto:support@gotrellis.com";
                                  }}
                                />
                              ) : null}
                            </>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            {this.state.cancelTerms ? (
              <TermsOfService
                justTerms="showCancellation"
                closeTerms={this.closeCancelTerms}
              />
            ) : null}
          </>
        ) : null}
      </Row>
    );
  }
}

Billing.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  amz_onboard: state.amz_onboard,
  modules: selectModules(state),
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
  setCurrentUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Billing));
