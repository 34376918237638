import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import Radio from "components/core/basic/Radio";
import { useMediaQuery } from "@react-hook/media-query";

const useStyles = (theme) => ({
  subscriptionCard: {
    borderRadius: "5px",
    marginRight: "15px",
    marginBottom: "15px",
    padding: "2rem",
    [theme.breakpoints.down("md")]: {
      marginRight: "5px",
    },
  },
  subscriptionCardTitle: {
    color: "#D91266",
    fontSize: "22px",
    fontWeight: 600,
    textAlign: "left",
    paddingLeft: "1rem",
    marginTop: "1rem",
  },
  subscriptionCardPricing: {
    textAlign: "center",
    color: "#2E0054",
    fontSize: "34px",
    fontWeight: 600,
    marginTop: "1rem",
    alignItems: "center",
  },
  subscriptionCardPricingTBD: {
    textAlign: "center",
    color: "#2E0054",
    fontSize: "22px",
    fontWeight: 600,
    marginTop: "1rem",
    paddingRight: ".5rem",
  },
  monthlySubscriptionCardPricing: {
    textAlign: "center",
    textDecoration: "line-through",
    color: "#2E0054",
    fontSize: "18px",
    fontWeight: 600,
    marginTop: "-50px",
    position: "absolute",
  },
  noStrikethrough: {
    textDecoration: "none",
  },
  cardContainer: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: "auto",
    },
  },
  centerAbsolute: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const SubscriptionCard = ({
  planName,
  planId,
  selectedSubscription,
  monthlySubscriptionPrice,
  annualSubscriptionPrice,
  monthlyChecked,
  subscriptionFeatures,
  cardImage,
  classes,
  onCardClick,
  monthlySubscriptionPeriod,
  annualDiscountPercentage,
  annualSales,
  hidden,
  core,
}) => {
  const mobileStyle = useMediaQuery("(max-width:1400px)");
  const textCenter = useMediaQuery("(max-width:1050px)");

  if (hidden) {
    return null;
  }
  const custom = planName?.includes("Custom");
  const isChromeExtensionCard = planName === "Chrome Extension";

  return (
    <>
      <Grid item xs={12} lg className="d-block m-auto w-100">
        <Grid>
          <div
            className={`card ${classes.subscriptionCard}`}
            onClick={(e) => onCardClick(e, planId)}
          >
            <Grid container item xs={12}>
              <Grid style={{ height: "15px" }} item xs={1}>
                <Grid
                  container
                  className="wrap"
                  style={{
                    marginTop: "3px",
                    marginLeft: "1rem",
                  }}
                >
                  {!isChromeExtensionCard && (
                    <Radio
                      number="3"
                      option="3"
                      name="group2"
                      checked={selectedSubscription === planId}
                      label={""}
                    />
                  )}
                </Grid>
                <Grid
                  container
                  className="wrap"
                  style={{
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                ></Grid>
              </Grid>
              <Grid container item xs={12} md={8} style={{ height: "100%" }}>
                <Grid item xs={12}>
                  <div
                    className={`${classes.subscriptionCardTitle} ${
                      textCenter ? "text-center" : "text-start"
                    } text-lg-start`}
                    style={{
                      padding: mobileStyle ? "1rem" : "0 0 0 1rem",
                    }}
                  >
                    {custom ? "Trellis Platform" : planName}
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    fontSize: "14px",
                  }}
                  className="mt-3"
                >
                  <Grid item xs={12} sm={custom ? 0 : 2}></Grid>
                  <Grid
                    item
                    xs={12}
                    md={custom ? 0 : annualSales ? 4 : 3}
                    style={{
                      padding: "0 1.3rem",
                    }}
                    className="fw-bolder"
                  >
                    {annualSales && (
                      <>
                        <span style={{ color: "#666" }}>Annual sales:</span>
                        <br />
                        {annualSales}
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={isChromeExtensionCard ? 12 : 6}
                    style={{
                      padding: "0 1.3rem",
                    }}
                  >
                    <div
                      className={`${
                        textCenter ? "text-center" : "text-start "
                      } py-5 py-lg-0 fw-bolder`}
                    >
                      <span style={{ color: "#666" }}>Features:</span>
                      <br />
                      {subscriptionFeatures}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container className="wrap">
                  {monthlySubscriptionPrice === "TBD" ? (
                    <span className={classes.subscriptionCardPricingTBD}>
                      Talk to Customer Success
                    </span>
                  ) : monthlySubscriptionPrice === "Free" ? (
                    <span className={classes.subscriptionCardPricingTBD}>
                      Free
                    </span>
                  ) : (
                    <>
                      {!monthlyChecked &&
                        monthlySubscriptionPrice !== "TBD" && (
                          <div
                            className={`${classes.monthlySubscriptionCardPricing} pb-5`}
                          >
                            {monthlySubscriptionPrice}/mo
                            <span>USD</span>
                          </div>
                        )}
                      <div
                        className={`${classes.subscriptionCardPricing}d-flex flex-column justify-content-center`}
                      >
                        <div className={`${classes.subscriptionCardPricing}`}>
                          {monthlyChecked
                            ? monthlySubscriptionPrice
                            : annualSubscriptionPrice}
                          <span style={{ fontSize: "14px" }}>/mo{"  "}</span>
                        </div>
                        <span
                          className={`${classes.subscriptionCardPricing}`}
                          style={{ fontSize: "16px" }}
                        >
                          USD{" "}
                        </span>
                        <span
                          className={`${classes.subscriptionCardPricing}`}
                          style={{ fontSize: "14px" }}
                          hidden={!core}
                        >
                          + sales tier fee
                        </span>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
};

export default withStyles(useStyles)(SubscriptionCard);
