import React from "react";
import SocialLogin from "react-social-login";
import PropTypes from "prop-types";

import "assets/css/authentication_pages.css";
import "assets/css/trellis-styles.css";
import { LoginWithAmazonButton } from "components/core/basic/MaterialButtons.jsx";
import { LoginWithAmazonLink } from "components/core/basic/MaterialButtons.jsx";

class SocialButton extends React.Component {
  static propTypes = {
    triggerLogin: PropTypes.func.isRequired,
    //triggerLogout: PropTypes.func.isRequired
  };

  render() {
    const {
      triggerLogin,
      children,
      buttonId,
      type,
      /*triggerLogout,*/ ...rest
    } = this.props;
    if (type === "link") {
      return (
        <LoginWithAmazonLink id={buttonId} onClick={triggerLogin} {...rest}>
          {children}
        </LoginWithAmazonLink>

        // <button onClick={triggerLogin} {...rest} className="amazon_button">
        //   { children }
        // </button>
      );
    } else {
      return (
        <LoginWithAmazonButton id={buttonId} onClick={triggerLogin} {...rest}>
          {children}
        </LoginWithAmazonButton>

        // <button onClick={triggerLogin} {...rest} className="amazon_button">
        //   { children }
        // </button>
      );
    }
  }
}

export default SocialLogin(SocialButton);
