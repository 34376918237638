import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import GVStatsCard from "components/core/blocks/SingleStatsTile";
import {
  SOS_TOOLTIP,
  SOS_ORGANIC_TOOLTIP,
  SOV_TOOLTIP,
  ATF_SOS_TOOLTIP,
  ATF_SOS_ORGANIC_TOOLTIP,
  ATF_SOV_TOOLTIP,
  AVERAGE_POSITION_TOOLTIP,
} from "utils/toolTips";

const AnalyticsStats = ({ state, marketPlace, gridWidth = 8 }) => {
  const [sos, setSOS] = useState(null);
  const [sos_organic, setSOSOrganic] = useState(null);
  const [sov, setSOV] = useState(null);
  const [atf_sos, setATFSOS] = useState(null);
  const [atf_sos_organic, setATFSOSOrganic] = useState(null);
  const [atf_sov, setATFSOV] = useState(null);
  const [pos, setPOS] = useState(null);

  if (!marketPlace && state.marketPlace) {
    marketPlace = state.marketPlace;
  }

  useEffect(() => {
    setSOS(state.sos_average);
    setSOSOrganic(state.sos_organic_average);
    setSOV(state.sov_average);
    setATFSOS(state.atf_sos_average);
    setATFSOSOrganic(state.atf_sos_organic_average);
    setATFSOV(state.atf_sov_average);
    setPOS(state.position_average);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const displayDynamicStats = (text, value, tooltipValue, tooltipId) => {
    if (value === 0 || !value) {
      return (
        <GVStatsCard
          statsText={text}
          statsValue={"-"}
          tooltipText={tooltipValue}
          id={tooltipId}
        />
      );
    } else {
      if (text === "Average Position") {
        return (
          <GVStatsCard
            statsText={text}
            statsValue={parseFloat(value.toFixed(0)).toLocaleString()}
            tooltipText={tooltipValue}
            id={tooltipId}
          />
        );
      } else if (
        text === "SOS" ||
        text === "SOS Organic" ||
        text === "SOV" ||
        text === "ATF SOS" ||
        text === "ATF SOS Organic" ||
        text === "ATF SOV"
      ) {
        return (
          <GVStatsCard
            statsText={text}
            statsValue={parseFloat(value.toFixed(0)).toLocaleString() + "%"}
            tooltipText={tooltipValue}
            id={tooltipId}
          />
        );
      }
    }
  };

  // TODO: Prolly want to add selectors for marketplace and category here
  return (
    <Grid container item lg={gridWidth} spacing={3} style={{ margin: "auto" }}>
      <Grid item xs={6} md={state.statCardColumns}>
        {displayDynamicStats("SOS", sos, SOS_TOOLTIP, "SOS_TOOLTIP")}
      </Grid>
      <Grid item xs={6} md={state.statCardColumns}>
        {displayDynamicStats(
          "SOS Organic",
          sos_organic,
          SOS_ORGANIC_TOOLTIP,
          "SOS_ORGANIC_TOOLTIP"
        )}
      </Grid>
      <Grid item xs={6} md={state.statCardColumns}>
        {displayDynamicStats("SOV", sov, SOV_TOOLTIP, "SOV_TOOLTIP")}
      </Grid>
      <Grid item xs={6} md={state.statCardColumns}>
        {displayDynamicStats(
          "ATF SOS",
          atf_sos,
          ATF_SOS_TOOLTIP,
          "ATF_SOS_TOOLTIP"
        )}
      </Grid>
      <Grid item xs={6} md={state.statCardColumns}>
        {displayDynamicStats(
          "ATF SOS Organic",
          atf_sos_organic,
          ATF_SOS_ORGANIC_TOOLTIP,
          "ATF_SOS_ORGANIC_TOOLTIP"
        )}
      </Grid>
      <Grid item xs={6} md={state.statCardColumns}>
        {displayDynamicStats(
          "ATF SOV",
          atf_sov,
          ATF_SOV_TOOLTIP,
          "ATF_SOV_TOOLTIP"
        )}
      </Grid>{" "}
      <Grid item xs={6} md={state.statCardColumns}>
        {displayDynamicStats(
          "Average Position",
          pos,
          AVERAGE_POSITION_TOOLTIP,
          "AVERAGE_POSITION_TOOLTIP"
        )}
      </Grid>
    </Grid>
  );
};

export default AnalyticsStats;
