import React, { Fragment, useState, useContext, useRef } from "react";
import { Grid } from "@material-ui/core";
import {
  NameField,
  CategoryField,
  AcosGoalsField,
  TagsField,
  AdTypeField,
  ProductsField,
  StoreLandingPageFields,
  VideoField,
  CreativeField,
  CreativeProductsField,
  LandingPageProductsField,
  LandingPageDetailsField,
  Creative2ProductsField,
  TargetingField,
  AsinsField,
  AdvancedTargettingButton,
  GoogleFields,
  CompositeBudgetStatusField,
  StoreSpotlightSubpageDialogue,
} from "./fields";
import AttributeSelector from "./AttributeSelector";
import AdvancedTargetingOptions from "./AdvancedTargetingOptions";
import { connect } from "react-redux";
import "assets/css/trellis-styles.css";
import FormWizard from "./FormWizard";
import FormWizardPagination from "./FormWizardPagination";
import { selectModules } from "redux/selectors/modules";
import {
  AD_TYPES,
  SINGLE_PLAN_STEPS,
  COMPOSITE_PLAN_STEPS,
  TARGET_TYPES,
  MODERATION_STATUSES,
  GOALS,
  ALGORITHM_OPTIONS,
} from "./constants";
import AdPlanGoalMenu from "./AdPlanGoalMenu";
import AdPlanSummary from "./AdPlanSummary";
import AdPlanChildren from "./AdPlanChildren";
import { AdPlanContext } from "./AdPlanContext";
import WalmartSBFields from "./WalmartSBFields";
import { Row, Col } from "react-bootstrap";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import SDTargetingOptions from "./SDTargetingOptions";
import {
  assessWalmartCreativesStep,
  assessSDCreativesStep,
  assessBiddingRulesSubstep,
  getExemptNavPaths,
} from "./AdPlanFormNavUtils";
import AssetSelector from "../AssetSelector/AssetSelector";
import AssetSummary from "../AssetSelector/AssetSummary";
import CreativePreview from "../AssetSelector/CreativePreview";
import TargetCategories from "./TargetCategories";
import CampaignNames from "./fields/CampaignNames";
import InlineBiddingPrograms from "components/custom/programs/bidding/InlineBiddingPrograms";
import NotificationSystem from "react-notification-system";
import { sendToastNotification } from "utils/sendToastNotification";

const AdPlanForm = ({
  state,
  loading,
  onChange,
  errors,
  setErrors,
  onShowChild,
  onRemoveChild,
  categories,
  products,
  dashboard = false,
  user,
  modules,
  marketPlace,
  channel,
  brandOptions,
  savedVideos,
  storeOptions,
  pageOptions,
  logoOptions,
  adId,
  isChild = false,
  parentDailyBudget,
  apiStatus,
  agreeToArchive,
  setAgreeToArchive,
  clearError,
  isModal,
  closeModal,
  inlineModal,
  onUpdate,
  onCreate,
  clearForm,
  onParentUpdate,
  addingNewChild,
  updateChild,
  classes,
  isValid,
  panelEditStep,
  onShowParent,
}) => {
  const [step, setStep] = useState(0);
  const [substep, setSubstep] = useState(0);
  const [editStep, setEditStep] = useState(panelEditStep ?? null);
  const [editSubstep, setEditSubstep] = useState(panelEditStep ? 0 : null);
  const [isComposite, setIsComposite] = useState(undefined);
  const [review, setReview] = useState(adId ?? false);
  const [fieldsToValidate, setFieldsToValidate] = useState([]);
  const [haveSkippedToAdvancedTargeting, setHaveSkippedToAdvancedTargeting] =
    useState(false);
  const [haveSkippedToNewProgramDialogue, setHaveSkippedToNewProgramDialogue] =
    useState(false);
  const [currentChild, setCurrentChild] = useState(null);

  const notificationRef = useRef();

  const {
    setChildPlan,
    savedChildPlans,
    setSavedChildPlans,
    childComponentsCompleted,
    setChildComponentsCompleted,
    resetAdPlanContext,
  } = useContext(AdPlanContext);

  let setupFields = [
    [
      <CategoryField
        categories={categories}
        state={state}
        dashboard={dashboard}
        onChange={onChange}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        review={review}
        channel={channel}
        marketPlace={marketPlace}
      />,
      <ProductsField
        state={state}
        onChange={onChange}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        products={products}
        user={user}
        channel={channel}
        review={review}
        isModal={isModal}
      />,
      <LandingPageProductsField
        state={state}
        onChange={onChange}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        adId={adId}
        user={user}
        landingPageProducts={products}
        review={review}
        isModal={isModal}
      />,
      <CreativeProductsField
        state={state}
        onChange={onChange}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        adId={adId}
        logoOptions={logoOptions}
        storePageProducts={products}
        user={user}
        review={review}
        isModal={isModal}
      />,
      <Creative2ProductsField
        state={state}
        onChange={onChange}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        adId={adId}
        creativeProducts={products}
        storePageProducts={products}
        user={user}
        logoOptions={logoOptions}
        review={review}
        isModal={isModal}
      />,
    ],
  ];

  let adTypeFields = [
    [
      <AdTypeField
        state={state}
        onChange={onChange}
        marketPlace={marketPlace}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        adId={adId}
        isChild={state.isChild}
        user={user}
        modules={modules}
        channel={channel}
        review={review}
        storeOptions={storeOptions}
        brandOptions={brandOptions}
        pageOptions={pageOptions}
        setEditStep={setEditStep}
        categories={categories}
        dashboard={dashboard}
      />,
      <GoogleFields
        state={state}
        onChange={onChange}
        marketPlace={marketPlace}
        errors={errors}
        adId={adId}
        isChild={isChild}
        user={user}
        channel={channel}
      />,
    ],
    [
      <h2
        style={{
          fontWeight: 700,
          marginTop: 0,
          fontSize: "24px",
        }}
      >
        Sponsored Brand Creatives
      </h2>,
      <p
        style={{
          fontSize: "16px",
          maxWidth: "90%",
          marginBottom: "2rem",
        }}
        translate="yes"
      >
        Select media assets for your campaign below.{" "}
        {state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
          ? "All fields are optional."
          : ""}
      </p>,
      <Col xs={12} className="pr-4">
        <CreativeField
          state={state}
          onChange={onChange}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          adId={adId}
          creativeProducts={products}
          user={user}
          logoOptions={
            state.logoDetails
              ? [state.logoDetails]?.map((logo) => ({
                  value: logo.asset_id,
                  label: logo.name,
                  url: logo.url_default,
                }))
              : []
          }
          creativesAssetSelection={true}
          review={review}
          optional={
            !adId &&
            !review &&
            state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
          }
        />
        {/* SB Video details */}
        <VideoField
          state={state}
          onChange={onChange}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          adId={adId}
          brandOptions={brandOptions}
          savedVideos={savedVideos}
          videoOptions={
            state.videoDetails
              ? [state.videoDetails]?.map((video) => ({
                  value: video.asset_id,
                  label: video.name,
                  url: video.url_default,
                }))
              : []
          }
          review={review}
        />
        {/* Sponsored Brand Creatives Asset Selector */}
        <AssetSelector
          state={state}
          products={products}
          onChange={onChange}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          adId={adId}
          customImageOptional={!state.newProductCollection}
          hidden={state.id}
          defaultPageSize={8}
          handleAssetSelection={(assetId, assetDetails, type) => {
            // Avoid conflict with SB logo treatment, need to have stored asset details for review display during creation
            let detailsKey = `${type}Details`;

            // Clear old selected creative if video or lifestyle image - only one or the other allowed in this context
            let removalKey;
            if (detailsKey === "videoDetails") {
              removalKey = "lifestyleImageDetails";
            }
            if (detailsKey === "lifestyleImageDetails") {
              removalKey = "videoDetails";
            }

            if (assetId === state.creativeAsset || assetId === null) {
              onChange({
                creativeAsset: null,
                [type]: null,
                [detailsKey]: null,
              });
            } else {
              onChange({
                creativeAsset: assetId,
                [type]: assetId,
                [detailsKey]: assetDetails,
                [removalKey]: null,
              });
            }
          }}
          selected={state.creativeAsset}
          selections={state.creativeAsset ?? []}
          allowedAssetTypes={
            state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
              ? state.storeLandingPage
                ? ["Brand Logo", "Video"]
                : ["Video"]
              : state.storeSpotlight
              ? ["Brand Logo"]
              : ["Brand Logo", "Lifestyle Image", "Product Image"]
          }
          initialTypeFilter={
            state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
              ? "VIDEO"
              : [
                  AD_TYPES.SPONSORED_BRAND.value,
                  AD_TYPES.SPONSORED_BRAND_V2.value,
                ].includes(state.adType)
              ? "LOGO"
              : "LIFESTYLE_IMAGE"
          }
          review={review}
          assetDescriptor={
            state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
              ? "Selected Video Creative"
              : `Selected Creative ${!adId && !review && "(optional)"}`
          }
          hidePreviewField={
            (state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
              !state.storeLandingPage) ||
            state.storeSpotlight
          }
          marketPlace={marketPlace}
        />
        <Row>
          <AssetSummary
            assetId={state.lifestyleImageDetails?.asset_id}
            assetDescriptor={
              state.lifestyleImageDetails?.type === "VIDEO"
                ? "Video Creative"
                : "Lifestyle Image"
            }
            hidden={!state.id}
            assetType={state.lifestyleImageDetails?.type}
            summaryContainerClasses={`pr-5 ${
              state.videoDetails?.type === "VIDEO" ? "my-0" : "my-5"
            }`}
            columnSpan={
              state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                ? state.logoDetails
                  ? 6
                  : 12
                : 6
            }
          />
          <AssetSummary
            assetId={state.logoDetails?.asset_id}
            assetDescriptor={"Brand Logo"}
            hidden={!state.id}
            assetType={state.logoDetails?.type}
            summaryContainerClasses={"pl-5 my-5"}
            columnSpan={
              state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                ? state.logoDetails
                  ? 6
                  : 12
                : 6
            }
          />
        </Row>
      </Col>,
    ],
  ];

  if (
    [
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
    ].includes(state.adType) &&
    (state.storeLandingPage || state.storeSpotlight)
  ) {
    adTypeFields.splice(1, 0, [
      <AdTypeField
        state={state}
        onChange={onChange}
        marketPlace={marketPlace}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        adId={adId}
        isChild={isChild}
        user={user}
        modules={modules}
        channel={channel}
        review={review}
        storeOptions={storeOptions}
        brandOptions={brandOptions}
        pageOptions={pageOptions}
        isSummaryCard={true}
        setEditStep={setEditStep}
        hidden={state.storeSpotlight}
      />,
      <h2
        style={{
          fontWeight: 700,
          marginTop: 0,
          fontSize: "24px",
        }}
      >
        Sponsored Brand Details
      </h2>,
      <p
        style={{ fontSize: "16px", marginBottom: "2rem", maxWidth: "90%" }}
        hidden={review}
        translate="yes"
      >
        Promote your brand and products in a customized ad at the top of related{" "}
        {channel === "walmart" ? "Walmart" : "Amazon"} product pages.
      </p>,
      <p
        style={{ fontSize: "16px", marginBottom: "2rem", maxWidth: "90%" }}
        hidden={
          review ||
          (!state.newProductCollection &&
            !(
              state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
              state.storeLandingPage
            ))
        }
        translate="yes"
      >
        To use an existing store page, select from the options below. Otherwise,
        you can add products for a new landing page later.
      </p>,

      <StoreLandingPageFields
        state={state}
        onChange={onChange}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        adId={adId}
        review={review}
        storeOptions={storeOptions}
        brandOptions={brandOptions}
        pageOptions={pageOptions}
      />,
      <StoreSpotlightSubpageDialogue
        products={products}
        state={state}
        onChange={onChange}
        marketPlace={marketPlace}
        errors={errors}
        fieldsToValidate={fieldsToValidate}
        adId={adId}
        isChild={isChild}
        user={user}
        modules={modules}
        channel={channel}
        review={review}
        storeOptions={storeOptions}
        brandOptions={brandOptions}
        pageOptions={pageOptions}
        isSummaryCard={true}
        setEditStep={setEditStep}
      />,
    ]);
  }

  const nonTrellisCampaignFlag = state.goal === GOALS.IMPORTED_ADS.value;

  let targetingFields = [];

  if (nonTrellisCampaignFlag) {
    targetingFields = [
      [
        <AdvancedTargetingOptions
          state={state}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          channel={channel}
          onChange={onChange}
          adId={adId}
          review={review || adId}
          isModal={isModal}
          modules={modules}
          targetingModalEdit={
            isModal &&
            ((state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
              step === 4) ||
              ([
                AD_TYPES.SPONSORED_BRAND_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
              ].includes(state.adType) &&
                step === 4) ||
              (state.adType === AD_TYPES.SPONSORED_BRAND.value &&
                step === 2 &&
                state.storeSpotlight) ||
              step === 3)
          }
          marketPlace={marketPlace}
        />,
      ],
    ];
  } else {
    targetingFields = [
      [
        <TargetingField
          state={state}
          onChange={onChange}
          fieldsToValidate={fieldsToValidate}
          errors={errors}
          adId={adId}
          review={review}
          channel={channel}
          isSummaryCard={state.targetType}
        />,
        <h2
          style={{
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "1rem",
            marginLeft:
              state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value ? 0 : "2rem",
            display:
              review ||
              state.adType === AD_TYPES.SPONSORED_DISPLAY.value ||
              state.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO.value ||
              state.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
                ? "none"
                : "inherit",
          }}
        >
          Targeting Parameters{" "}
          {state.targetType === TARGET_TYPES.AUTOMATIC.value && "(Optional)"}
        </h2>,
        <p
          style={{
            fontSize: "16px",
            marginBottom: "2rem",
            marginLeft: "2rem",
            maxWidth: "90%",
          }}
          translate="yes"
          hidden={
            review ||
            state.adType === AD_TYPES.SPONSORED_DISPLAY.value ||
            state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value ||
            state.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO.value ||
            state.adType === AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value
          }
        >
          If you want your targeting plan to include specific keywords
          {state.adType === AD_TYPES.SPONSORED_PRODUCT.value &&
          channel !== "walmart"
            ? " or ASINs"
            : ""}
          , add them here. You can add, change or delete them anytime.
        </p>,
        <SDTargetingOptions
          state={state}
          errors={errors}
          products={products}
          channel={channel}
          fieldsToValidate={fieldsToValidate}
          onChange={onChange}
          adId={adId}
          marketPlace={marketPlace}
          modules={modules}
          // TODO: If we want to allow editing from main review panel, just pass in review below
          review={adId && isModal && step === 3 ? false : review}
          isModal={isModal}
        />,
        <AttributeSelector
          state={state}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          channel={channel}
          onChange={onChange}
          funnelTargeting={state.targetType === TARGET_TYPES.FUNNEL.value}
          review={review || adId}
          isModal={isModal}
        />,
        <TagsField
          state={state}
          onChange={onChange}
          channel={channel}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          review={review || adId}
        />,
        <AsinsField
          state={state}
          channel={channel}
          onChange={onChange}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          review={review || adId}
        />,
        <TargetCategories
          state={state}
          review={review}
          onChange={onChange}
          marketPlace={marketPlace}
          errors={errors}
          isModal={isModal}
          channel={channel}
          products={products}
        />,
        <CampaignNames
          state={state}
          review={review}
          onChange={onChange}
          marketPlace={marketPlace}
          fieldsToValidate={fieldsToValidate}
          errors={errors}
          setErrors={setErrors}
          isModal={isModal}
          channel={channel}
          modules={modules}
        />,
        <AdvancedTargettingButton
          show={
            !adId && channel !== "google" && !review && !state.canHaveChildren
          }
          disabled={
            errors.targetType ||
            errors.filledAsins ||
            errors.filledTags ||
            (state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
              (errors.sdLookbackPeriod ||
                errors.sdAudienceTargets ||
                errors.sdBidType ||
                errors.sdCampaignType)) ||
            (state.targetType === TARGET_TYPES.MANUAL.value &&
              errors.filledTagsOrAsins) ||
            (state.targetType === TARGET_TYPES.FUNNEL.value &&
              errors.filledProductKeys) ||
            state.validatingCampaignNames?.length ||
            state.duplicateCampaignNames?.length
          }
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          onChange={onChange}
          tier={user.tier}
          skipToAdvancedMenu={() => {
            if (editSubstep !== 1) {
              setStep(
                [
                  AD_TYPES.SPONSORED_BRAND_WALMART.value,
                  AD_TYPES.SPONSORED_DISPLAY_V2.value,
                  AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                ].includes(state.adType)
                  ? 4
                  : state.storeSpotlight && !state.newLandingPage
                  ? 2
                  : 3
              );
              setEditStep(
                [
                  AD_TYPES.SPONSORED_BRAND_WALMART.value,
                  AD_TYPES.SPONSORED_DISPLAY_V2.value,
                  AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                ].includes(state.adType)
                  ? 4
                  : state.storeSpotlight && !state.newLandingPage
                  ? 2
                  : 3
              );
              setSubstep(1);
              setEditSubstep(1);
            }
          }}
        />,
      ],
      [
        <AdvancedTargetingOptions
          state={state}
          errors={errors}
          fieldsToValidate={fieldsToValidate}
          channel={channel}
          onChange={onChange}
          adId={adId}
          review={review || adId}
          isModal={isModal}
          modules={modules}
          skipToRulesForm={() => {
            const targetingStepIndex = steps.findIndex(
              (step) => step.title === "Targeting"
            );

            setStep(targetingStepIndex);
            setSubstep(2);
            setEditStep(targetingStepIndex);
          }}
          targetingModalEdit={
            isModal &&
            ((state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
              step === 4) ||
              ([
                AD_TYPES.SPONSORED_BRAND_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
              ].includes(state.adType) &&
                step === 4) ||
              ([
                AD_TYPES.SPONSORED_BRAND_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
              ].includes(state.adType) &&
                step === 4) ||
              (state.adType === AD_TYPES.SPONSORED_BRAND.value &&
                step === 2 &&
                state.storeSpotlight) ||
              step === 3)
          }
          marketPlace={marketPlace}
        />,
      ],
      [
        <div className="px-5">
          <InlineBiddingPrograms
            deferProgramCreation={true}
            hidden={review || state.goal === GOALS.CUSTOM.value}
            adPlanParams={state}
            defaultCreateProgram={true}
            modalDisplay={true}
            parentError={
              errors?.newBiddingProgram &&
              fieldsToValidate?.includes("newBiddingProgram")
                ? errors.newBiddingProgram
                : null
            }
            onCancel={() => {
              const targetingStepIndex = steps.findIndex(
                (step) => step.title === "Targeting"
              );
              setHaveSkippedToAdvancedTargeting(false);
              setStep(targetingStepIndex);
              setSubstep(1);
              setEditStep(targetingStepIndex);
              setEditSubstep(1);
            }}
            onActivate={(newProgramData) => {
              const {
                name,
                start_date,
                end_date,
                template_type,
                ui_state,
                spec,
                rules,
              } = newProgramData ?? {};

              const programDefinition = {
                name,
                start_date,
                end_date,
                template_type,
                ui_state,
                spec,
                rules,
                engine: "bidding",
              };

              const targetingStepIndex = steps.findIndex(
                (step) => step.title === "Targeting"
              );
              const advancedTargetingSubstepIndex = 1;

              onChange({
                biddingProgram: programDefinition,
                createNewProgram: false,
              });

              sendToastNotification(
                notificationRef,
                "success",
                `New program ${name} successfully added to media plan`
              );

              setEditStep(targetingStepIndex);
              setEditSubstep(advancedTargetingSubstepIndex);
              setStep(targetingStepIndex);
              setSubstep(advancedTargetingSubstepIndex);
            }}
          />
        </div>,
      ],
    ];
  }

  const renderSingleFormStep = (step, adType) => {
    const goalMenuIndex = 0;
    const adTypeIndex = 1;
    const productSelectionIndex =
      state.storeSpotlight && !state.newLandingPage ? null : 2;
    const targetingIndex = [
      AD_TYPES.SPONSORED_BRAND_WALMART.value,
      AD_TYPES.SPONSORED_DISPLAY_V2.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
    ].includes(state.adType)
      ? 4
      : state.storeSpotlight && !state.newLandingPage
      ? 2
      : 3;
    const reviewIndex = [
      AD_TYPES.SPONSORED_BRAND_WALMART.value,
      AD_TYPES.SPONSORED_DISPLAY_V2.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
    ].includes(state.adType)
      ? 5
      : state.storeSpotlight && !state.newLandingPage
      ? 3
      : 4;
    const walmartCreativesIndex = [
      AD_TYPES.SPONSORED_BRAND_WALMART.value,
      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
    ].includes(state.adType)
      ? 3
      : undefined;
    const creativesAssetSelectionIndex =
      state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value ? 3 : undefined;

    switch (step) {
      case goalMenuIndex:
        return (
          <AdPlanGoalMenu
            state={state}
            onChange={onChange}
            isComposite={isComposite}
            setIsComposite={setIsComposite}
            roas={user.preferences.acos_or_roas === "roas"}
            errors={errors}
            fieldsToValidate={fieldsToValidate}
            user={user}
            channel={channel}
            modules={modules}
            nameField={
              <NameField
                state={state}
                onChange={onChange}
                errors={errors}
                adId={adId}
                fieldsToValidate={fieldsToValidate}
                review={review}
              />
            }
            acosGoalsField={
              <AcosGoalsField
                state={state}
                onChange={onChange}
                user={user}
                marketPlace={marketPlace}
                errors={errors}
                isChild={isChild}
                adId={adId}
                parentDailyBudget={parentDailyBudget}
                roas={user.preferences.acos_or_roas === "roas"}
                channel={channel}
                fieldsToValidate={fieldsToValidate}
                review={review}
                modules={modules}
              />
            }
          />
        );
      case adTypeIndex:
        return (
          <>
            <div
              hidden={
                (substep > 0 && !state.storeLandingPage) ||
                (substep > 1 && state.storeLandingPage)
              }
            >
              <h2
                style={{
                  fontWeight: 700,
                  marginTop: 0,
                  fontSize: "24px",
                }}
              >
                Choose Ad Type
              </h2>
              <p
                style={{
                  fontSize: "16px",
                  maxWidth: "90%",
                  marginBottom: "2rem",
                }}
                translate="yes"
              >
                Showcase your brand on{" "}
                {channel === "walmart" ? "Walmart" : "Amazon"} by selecting an
                ad type. The ad type you choose will affect where and how your
                business appears on{" "}
                {channel === "walmart" ? "Walmart" : "Amazon"}.
              </p>
            </div>
            {adTypeFields[substep ?? 0]}
          </>
        );
      case productSelectionIndex:
        return (
          <>
            <h2 style={{ fontWeight: 700, marginTop: 0, fontSize: "24px" }}>
              Select your {substep === 1 ? "landing page " : null} products
            </h2>

            <p style={{ fontSize: "16px" }} translate="yes">
              Select the products that you want to advertise in this plan.
            </p>
            {setupFields[substep ?? 0]}
          </>
        );
      case targetingIndex:
        let substepTitles = [
          "Setup Targeting Plan",
          "Advanced Settings",
          "Create a New Bidding Program",
        ];
        return (
          <>
            <h2
              style={{ fontWeight: 700, marginTop: 0, fontSize: "24px" }}
              className={substep === 2 ? "px-5" : ""}
            >
              {substepTitles[substep]}
            </h2>
            <p
              style={{
                fontSize: "16px",
                marginBottom: "1rem",
                maxWidth: "90%",
              }}
              translate="yes"
              className={substep === 2 ? "d-none" : ""}
            >
              {substep === 1
                ? `We pre-selected some of your settings based on your media plan and targeting types. Modifying them is only recommended for experienced users.`
                : channel === "walmart"
                ? `Targeting determines your ad placement based on keywords and themes.`
                : `Targeting determines your ad placement based on keywords, themes, and ASINs.`}
            </p>
            {!state.targetType
              ? targetingFields[0]?.[0]
              : targetingFields[substep ?? 0]}
          </>
        );
      case reviewIndex:
        return (
          <AdPlanSummary
            setStep={setStep}
            setEditStep={setEditStep}
            setEditSubstep={setEditSubstep}
            setupFields={setupFields}
            modules={modules}
            acosGoalsField={
              <AcosGoalsField
                state={state}
                onChange={onChange}
                user={user}
                marketPlace={marketPlace}
                errors={errors}
                isChild={isChild}
                adId={adId}
                parentDailyBudget={parentDailyBudget}
                roas={user.preferences.acos_or_roas === "roas"}
                channel={channel}
                fieldsToValidate={fieldsToValidate}
                review={true}
                modules={modules}
              />
            }
            adTypeFields={adTypeFields}
            sbFields={[
              <LandingPageDetailsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                storeOptions={storeOptions}
                brandOptions={brandOptions}
                pageOptions={pageOptions}
                review={review}
                categories={categories}
                dashboard={dashboard}
                isModal={isModal}
              />,
              <StoreLandingPageFields
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                review={review}
                storeOptions={storeOptions}
                brandOptions={brandOptions}
                pageOptions={pageOptions}
                reviewChildren={
                  state.storeSpotlight ? (
                    <AssetSummary
                      assetId={state.logoDetails?.asset_id}
                      assetDescriptor={"Brand Logo"}
                      assetType={state.logoDetails?.type}
                      hidden={!state.logoDetails}
                      summaryContainerClasses={
                        state.storeSpotlight ? "pl-5 mt-3" : "pl-5 my-0"
                      }
                    />
                  ) : null
                }
              />,
              <VideoField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                brandOptions={brandOptions}
                savedVideos={savedVideos}
                videoOptions={
                  state.videoDetails
                    ? [state.videoDetails]?.map((video) => ({
                        value: video.asset_id,
                        label: video.name,
                        url: video.url_default,
                      }))
                    : []
                }
                review={review}
              />,
              <Row>
                <AssetSummary
                  assetId={
                    state.videoDetails?.asset_id ??
                    state.lifestyleImageDetails?.asset_id ??
                    state.video
                  }
                  assetDescriptor={
                    state.lifestyleImageDetails?.type === "VIDEO" ||
                    state.videoDetails?.type === "VIDEO" ||
                    state.video
                      ? "Video Creative"
                      : "Lifestyle Image"
                  }
                  assetType={
                    state.videoDetails?.type || state.video ? "VIDEO" : "IMAGE"
                  }
                  hidden={
                    !state.videoDetails &&
                    !state.lifestyleImageDetails &&
                    !state.video
                  }
                  summaryContainerClasses={`pr-5 ${
                    state.videoDetails?.type === "VIDEO" &&
                    !state.landingPageProduct?.length
                      ? "my-0"
                      : "my-5"
                  }`}
                  columnSpan={
                    state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                      ? state.logoDetails
                        ? 6
                        : 12
                      : 6
                  }
                />
                <AssetSummary
                  assetId={state.logoDetails?.asset_id}
                  assetDescriptor={"Brand Logo"}
                  assetType={state.logoDetails?.type}
                  hidden={!state.logoDetails || state.storeSpotlight}
                  summaryContainerClasses={
                    state.storeSpotlight
                      ? `pr-5 my-0`
                      : `pl-5 ${
                          state.adType ===
                            AD_TYPES.SPONSORED_BRAND_VIDEO.value &&
                          !state.landingPageProduct?.length
                            ? "my-0"
                            : "my-5"
                        }`
                  }
                />
              </Row>,
            ]}
            videoField={[
              <LandingPageProductsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                user={user}
                landingPageProducts={products}
                review={review}
                isModal={isModal}
              />,
            ]}
            productFields={[
              <ProductsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                products={products}
                user={user}
                channel={channel}
                review={review}
                isModal={isModal}
              />,
              <LandingPageProductsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                user={user}
                landingPageProducts={products}
                review={review}
                isModal={isModal}
              />,
            ]}
            storeSpotlightFields={[
              <StoreSpotlightSubpageDialogue
                products={products}
                state={state}
                onChange={onChange}
                marketPlace={marketPlace}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                isChild={isChild}
                user={user}
                modules={modules}
                channel={channel}
                review={review}
                storeOptions={storeOptions}
                brandOptions={brandOptions}
                pageOptions={pageOptions}
                isSummaryCard={true}
                setEditStep={setEditStep}
              />,
            ]}
            creativeProductFields={[
              <CreativeProductsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                logoOptions={logoOptions}
                storePageProducts={products}
                user={user}
                review={review}
                isModal={isModal}
              />,
              <Creative2ProductsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                creativeProducts={products}
                storePageProducts={products}
                user={user}
                logoOptions={logoOptions}
                review={review}
                isModal={isModal}
              />,
            ]}
            creativesAssetSelectionFields={
              <CreativeField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                adId={adId}
                optional={false}
                creativeProducts={products}
                user={user}
                logoOptions={
                  state.logoDetails
                    ? [state.logoDetails]?.map((logo) => ({
                        value: logo.asset_id,
                        label: logo.name,
                        url: logo.url_default,
                      }))
                    : []
                }
                review={review}
                creativesAssetSelection={true}
                isModal={isModal}
              />
            }
            targetingFields={targetingFields}
            state={state}
            products={products}
            onChange={onChange}
            onUpdate={onUpdate}
            errors={errors}
            fieldsToValidate={fieldsToValidate}
            adId={adId}
            isChild={isChild}
            user={user}
            channel={channel}
            review={review}
            isModal={isModal}
            editExistingPlan={false}
            categories={categories}
            marketPlace={marketPlace}
            agreeToArchive={agreeToArchive}
            setAgreeToArchive={setAgreeToArchive}
            apiStatus={apiStatus}
          />
        );
      case walmartCreativesIndex:
        return (
          <Col xs={12} lg={10}>
            <WalmartSBFields
              state={state}
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              channel={channel}
              onChange={onChange}
              adId={adId}
              review={review || adId}
              isModal={isModal}
            />
          </Col>
        );
      case creativesAssetSelectionIndex:
        return (
          <Col xs={12} className="pr-4">
            <h2
              style={{
                fontWeight: 700,
                marginTop: 0,
                fontSize: "24px",
              }}
            >
              Sponsored Display Creatives
            </h2>
            <p
              style={{
                fontSize: "16px",
                maxWidth: "90%",
                marginBottom: "2rem",
              }}
              translate="yes"
            >
              Select media assets for your campaign below. All fields are
              optional.
            </p>
            <CreativeField
              state={state}
              onChange={onChange}
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              adId={adId}
              optional={
                !state.id &&
                state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
              }
              creativeProducts={products}
              user={user}
              logoOptions={
                state.logoDetails
                  ? [state.logoDetails]?.map((logo) => ({
                      value: logo.asset_id,
                      label: logo.name,
                      url: logo.url_default,
                    }))
                  : []
              }
              review={review}
              creativesAssetSelection={true}
              isModal={isModal}
            />
            <AssetSelector
              state={state}
              products={products}
              onChange={onChange}
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              customImageOptional={!state.newProductCollection}
              adId={adId}
              onUpdate={async () => {
                if (!state.errors) {
                  await onUpdate(state, products);
                  closeModal();
                }
              }}
              closeModal={closeModal}
              hidden={
                (state.id &&
                  state.adType !== AD_TYPES.SPONSORED_DISPLAY_V2.value) ||
                (state.id &&
                  isModal &&
                  state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                  state.advModerationStatus ===
                    MODERATION_STATUSES.PENDING.value)
              }
              defaultPageSize={8}
              handleAssetSelection={(assetId, assetDetails, type) => {
                if (type === "logo") {
                  onChange({ logo: assetId });
                }
                // Avoid conflict with SB logo treatment, need to have stored asset details for review display during creation
                type += "Details";
                // Clear old selected creative if video or lifestyle image - only one or the other allowed in this context
                let removalKey;
                if (type === "videoDetails") {
                  removalKey = "lifestyleImageDetails";
                }
                if (type === "lifestyleImageDetails") {
                  removalKey = "videoDetails";
                }

                if (assetId === state.creativeAsset || assetId === null) {
                  onChange({ creativeAsset: null, [type]: null });
                } else {
                  let updatedCreativeFields = {
                    creativeAsset: assetId,
                    selectedType: type,
                    [type]: assetDetails,
                    [removalKey]: null,
                  };
                  if (type === "lifestyleImageDetails") {
                    updatedCreativeFields["sdSquareImageCroppingCoordinates"] =
                      {};
                    updatedCreativeFields[
                      "sdRectangleImageCroppingCoordinates"
                    ] = {};
                  }

                  if (type === "logoDetails") {
                    updatedCreativeFields["logo"] = assetId;
                  }
                  onChange(updatedCreativeFields);
                }
              }}
              selected={state.creativeAsset}
              selections={state.creativeAsset ?? []}
              allowedAssetTypes={[
                "Lifestyle Image",
                "Product Image",
                "Brand Logo",
                "Video",
              ]}
              review={review}
              initialTypeFilter={
                state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                  ? "VIDEO"
                  : state.adType === AD_TYPES.SPONSORED_BRAND.value
                  ? "LOGO"
                  : "LIFESTYLE_IMAGE"
              }
              marketPlace={marketPlace}
            />
            <Row>
              <AssetSummary
                assetId={state.creativeAsset ?? state.video}
                assetDescriptor={
                  state.lifestyleImageDetails?.type === "VIDEO" || state.video
                    ? "Video Creative"
                    : "Lifestyle Image"
                }
                hidden={
                  !state.id ||
                  (state.id &&
                    !state.lifestyleImageDetails &&
                    !state.creativeAsset &&
                    !state.video) ||
                  (state.id &&
                    isModal &&
                    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                    state.advModerationStatus !==
                      MODERATION_STATUSES.PENDING.value)
                }
                assetType={
                  state.videoDetails?.type || state.video ? "VIDEO" : "IMAGE"
                }
                summaryContainerClasses={`pr-5 ${
                  state.videoDetails?.type === "VIDEO"
                    ? review && !state.id
                      ? "mt-0 mb-5"
                      : "my-0"
                    : "my-5"
                }`}
                columnSpan={
                  state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                    ? state.logoDetails
                      ? 6
                      : 12
                    : 6
                }
              />
              <AssetSummary
                assetId={state.logo}
                assetDescriptor={"Brand Logo"}
                hidden={
                  !state.id ||
                  (state.id && !state.logoDetails) ||
                  (state.id &&
                    isModal &&
                    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                    state.advModerationStatus !==
                      MODERATION_STATUSES.PENDING.value)
                }
                assetType={state.logoDetails?.type}
                summaryContainerClasses={"pl-5 my-5"}
                columnSpan={
                  state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                    ? state.logoDetails
                      ? 6
                      : 12
                    : 6
                }
              />
            </Row>
            <Row
              style={{
                display:
                  state.id &&
                  state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                  !isModal
                    ? "inherit"
                    : "none",
              }}
            >
              <CreativePreview
                state={state}
                // Creative preview endpoint doesn't return HTML consistently with more than 1 ASIN, for now just include first selected
                asin={
                  products
                    ?.filter((p) => state.product?.includes(p.value))
                    ?.map((p) => p.asin)?.[0] ?? ""
                }
                marketPlace={marketPlace?.marketPlace}
                showCreativePreview={
                  state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
                }
                accordionLayout={false}
              />
            </Row>
          </Col>
        );
      default:
        return;
    }
  };

  const renderCompositeFormStep = (step, adType) => {
    const goalMenuIndex = 0;
    const productSelectionIndex = 1;
    const componentTableIndex = 2;
    const componentTargetingIndex = 3;
    const reviewIndex = 4;

    switch (step) {
      case goalMenuIndex:
        return (
          <AdPlanGoalMenu
            state={state}
            onChange={onChange}
            isComposite={isComposite}
            setIsComposite={setIsComposite}
            roas={user.preferences.acos_or_roas === "roas"}
            errors={errors}
            fieldsToValidate={fieldsToValidate}
            user={user}
            channel={channel}
            modules={modules}
            nameField={
              <NameField
                state={state}
                onChange={onChange}
                errors={errors}
                adId={adId}
                fieldsToValidate={fieldsToValidate}
                review={review}
              />
            }
            acosGoalsField={
              <AcosGoalsField
                state={state}
                onChange={onChange}
                user={user}
                marketPlace={marketPlace}
                errors={errors}
                isChild={isChild}
                adId={adId}
                parentDailyBudget={parentDailyBudget}
                roas={user.preferences.acos_or_roas === "roas"}
                channel={channel}
                fieldsToValidate={fieldsToValidate}
                review={review}
                modules={modules}
              />
            }
          />
        );
      case productSelectionIndex:
        return (
          <>
            <h2 style={{ fontWeight: 700, marginTop: 0, fontSize: "24px" }}>
              Choose Products
            </h2>

            <p style={{ fontSize: "16px" }} translate="yes">
              Select the products that you want to advertise in this plan.
            </p>

            {setupFields}
          </>
        );
      case componentTableIndex:
        return (
          <>
            {[
              <AdPlanChildren
                state={state}
                products={products.map((p) => p.value)}
                children={savedChildPlans ?? []}
                onRemove={onRemoveChild}
                adId={adId}
                marketPlace={marketPlace}
                errors={errors}
                categoryId={state.categoryId}
                inlineModal={inlineModal}
                onChange={onChange}
                fieldsToValidate={fieldsToValidate}
                modules={modules}
              />,
            ]}
          </>
        );
      case componentTargetingIndex:
        return <>{targetingFields}</>;
      case reviewIndex:
        return (
          <>
            {
              [
                <AdPlanSummary
                  setStep={setStep}
                  setEditStep={setEditStep}
                  setEditSubstep={setEditSubstep}
                  setupFields={setupFields}
                  modules={modules}
                  acosGoalsField={[
                    <AcosGoalsField
                      state={state}
                      onChange={onChange}
                      user={user}
                      marketPlace={marketPlace}
                      errors={errors}
                      isChild={isChild}
                      adId={adId}
                      parentDailyBudget={parentDailyBudget}
                      roas={user.preferences.acos_or_roas === "roas"}
                      channel={channel}
                      fieldsToValidate={fieldsToValidate}
                      review={review}
                      onShowParent={(id) => onShowParent(id)}
                      modules={modules}
                    />,
                    <CompositeBudgetStatusField
                      state={state}
                      onChange={onChange}
                      marketPlace={marketPlace}
                    />,
                  ]}
                  adTypeFields={adTypeFields}
                  productFields={[
                    <ProductsField
                      state={state}
                      onChange={onChange}
                      errors={errors}
                      fieldsToValidate={fieldsToValidate}
                      adId={adId}
                      products={products}
                      user={user}
                      channel={channel}
                      review={review}
                      isModal={isModal}
                    />,
                  ]}
                  targetingFields={targetingFields}
                  adPlanChildrenSummaryField={
                    <AdPlanChildren
                      state={state}
                      products={products.map((p) => p.value)}
                      onSelect={(i, child) => {
                        setCurrentChild({ i, child });
                      }}
                      children={state.children ?? []}
                      onShowChild={onShowChild}
                      onRemove={onRemoveChild}
                      adId={adId}
                      marketPlace={marketPlace}
                      errors={errors}
                      categoryId={state.categoryId}
                      inlineModal={inlineModal}
                      onChange={onChange}
                      fieldsToValidate={fieldsToValidate}
                      review={true}
                      user={user}
                      modules={modules}
                    />
                  }
                  state={state}
                  products={products}
                  onChange={onChange}
                  onCreate={onCreate}
                  onUpdate={onUpdate}
                  onParentUpdate={onParentUpdate}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  isChild={isChild}
                  user={user}
                  channel={channel}
                  review={review}
                  editExistingPlan={true}
                  categories={categories}
                  marketPlace={marketPlace}
                  agreeToArchive={agreeToArchive}
                  setAgreeToArchive={setAgreeToArchive}
                  apiStatus={apiStatus}
                  isModal={isModal}
                  hideStatusField={true}
                />,
              ][substep]
            }
          </>
        );
      default:
        return;
    }
  };

  if (loading) {
    return (
      <Row>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
          }}
        >
          <img src={logo} alt="Loading..." className="rotate" width="64" />
        </Col>
      </Row>
    );
  }

  let steps = isComposite ? COMPOSITE_PLAN_STEPS : SINGLE_PLAN_STEPS;

  // Update/review screen for existing ads
  return (
    <div style={{ minHeight: "700px" }}>
      <NotificationSystem ref={notificationRef} />
      <Fragment>
        {adId ? (
          <>
            <AdPlanSummary
              setStep={setStep}
              setEditStep={setEditStep}
              setEditSubstep={setEditSubstep}
              setupFields={setupFields}
              modules={modules}
              acosGoalsField={[
                <AcosGoalsField
                  state={state}
                  onChange={onChange}
                  user={user}
                  marketPlace={marketPlace}
                  errors={errors}
                  isChild={isChild}
                  adId={adId}
                  parentDailyBudget={parentDailyBudget}
                  roas={user.preferences.acos_or_roas === "roas"}
                  channel={channel}
                  fieldsToValidate={fieldsToValidate}
                  review={review}
                  onShowParent={(id) => onShowParent(id)}
                  modules={modules}
                />,
                <CompositeBudgetStatusField
                  state={state}
                  onChange={onChange}
                  marketPlace={marketPlace}
                />,
              ]}
              sbFields={[
                <LandingPageDetailsField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  storeOptions={storeOptions}
                  brandOptions={brandOptions}
                  pageOptions={pageOptions}
                  review={review}
                  categories={categories}
                  dashboard={dashboard}
                  isModal={isModal}
                />,
                <StoreLandingPageFields
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  review={review}
                  storeOptions={storeOptions}
                  brandOptions={brandOptions}
                  pageOptions={pageOptions}
                />,
                <CreativeField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  creativeProducts={products}
                  user={user}
                  logoOptions={
                    state.logoDetails
                      ? [state.logoDetails]?.map((logo) => ({
                          value: logo.asset_id,
                          label: logo.name,
                          url: logo.url_default,
                        }))
                      : []
                  }
                  review={review}
                  optional={
                    !adId &&
                    !review &&
                    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
                  }
                  isModal={isModal}
                />,
                <Row>
                  <AssetSummary
                    assetId={
                      state.videoDetails?.asset_id ??
                      state.lifestyleImageDetails?.asset_id ??
                      state.video
                    }
                    assetDescriptor={
                      state.lifestyleImageDetails?.type === "VIDEO" ||
                      state.videoDetails?.type === "VIDEO" ||
                      state.video
                        ? "Video Creative"
                        : "Lifestyle Image"
                    }
                    assetType={
                      state.lifestyleImageDetails?.type === "IMAGE"
                        ? "IMAGE"
                        : state.videoDetails || state.video
                        ? "VIDEO"
                        : null
                    }
                    hidden={!state.lifestyleImageDetails && !state.video}
                    summaryContainerClasses={`pr-5 ${
                      state.videoDetails?.type === "VIDEO"
                        ? review && !state.id
                          ? "mt-0 mb-5"
                          : "my-0"
                        : "my-5"
                    }`}
                    columnSpan={
                      state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                        ? state.logoDetails
                          ? 6
                          : 12
                        : 6
                    }
                  />
                  <AssetSummary
                    assetId={state.logoDetails?.asset_id ?? state.logo}
                    assetDescriptor={"Brand Logo"}
                    assetType={state.logoDetails?.type}
                    hidden={!state.logoDetails && !state.logo}
                    summaryContainerClasses={
                      !state.videoDetails &&
                      !state.lifestyleImageDetails &&
                      !state.video
                        ? `pr-5 ${state.storeSpotlight ? "mb-5" : "my-5"}`
                        : `pl-5 ${
                            state.videoDetails?.type === "VIDEO"
                              ? "my-0"
                              : "my-5"
                          }`
                    }
                    columnSpan={
                      state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value
                        ? state.logoDetails
                          ? 6
                          : 12
                        : 6
                    }
                  />
                </Row>,
              ]}
              videoField={[
                <VideoField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  brandOptions={brandOptions}
                  savedVideos={savedVideos}
                  review={review}
                />,
                <LandingPageProductsField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  user={user}
                  landingPageProducts={products}
                  review={review}
                  isModal={isModal}
                />,
              ]}
              adTypeFields={adTypeFields}
              productFields={[
                <ProductsField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  products={products}
                  user={user}
                  channel={channel}
                  review={review}
                  isModal={isModal}
                />,
                <LandingPageProductsField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  user={user}
                  landingPageProducts={products}
                  review={review}
                  isModal={isModal}
                />,
              ]}
              creativeProductFields={[
                <CreativeProductsField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  logoOptions={logoOptions}
                  storePageProducts={products}
                  user={user}
                  review={review}
                  isModal={isModal}
                />,
                <Creative2ProductsField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  creativeProducts={products}
                  storePageProducts={products}
                  user={user}
                  logoOptions={logoOptions}
                  review={review}
                  isModal={isModal}
                />,
              ]}
              storeSpotlightFields={[
                <StoreSpotlightSubpageDialogue
                  products={products}
                  state={state}
                  onChange={onChange}
                  marketPlace={marketPlace}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  isChild={isChild}
                  user={user}
                  modules={modules}
                  channel={channel}
                  review={review}
                  storeOptions={storeOptions}
                  brandOptions={brandOptions}
                  pageOptions={pageOptions}
                  isSummaryCard={true}
                  setEditStep={setEditStep}
                />,
              ]}
              targetingFields={targetingFields}
              adPlanChildrenSummaryField={
                <AdPlanChildren
                  state={state}
                  products={products.map((p) => p.value)}
                  onSelect={(i, child) => {
                    setCurrentChild({ i, child });
                  }}
                  children={state.children ?? []}
                  onShowChild={onShowChild}
                  onRemove={onRemoveChild}
                  adId={adId}
                  marketPlace={marketPlace}
                  errors={errors}
                  categoryId={state.categoryId}
                  inlineModal={inlineModal}
                  onChange={onChange}
                  fieldsToValidate={fieldsToValidate}
                  review={true}
                  user={user}
                  modules={modules}
                />
              }
              creativesAssetSelectionFields={
                <CreativeField
                  state={state}
                  onChange={onChange}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                  adId={adId}
                  optional={
                    !adId &&
                    !review &&
                    state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
                  }
                  creativeProducts={products}
                  user={user}
                  logoOptions={
                    state.logoDetails
                      ? [state.logoDetails]?.map((logo) => ({
                          value: logo.asset_id,
                          label: logo.name,
                          url: logo.url_default,
                        }))
                      : []
                  }
                  review={review}
                  creativesAssetSelection={true}
                  isModal={isModal}
                />
              }
              state={state}
              products={products}
              onChange={onChange}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onParentUpdate={onParentUpdate}
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              adId={adId}
              isChild={isChild}
              user={user}
              channel={channel}
              review={review}
              editExistingPlan={true}
              categories={categories}
              marketPlace={marketPlace}
              agreeToArchive={agreeToArchive}
              setAgreeToArchive={setAgreeToArchive}
              apiStatus={apiStatus}
              isModal={isModal}
            />
            {state.id && (
              <div style={{ marginTop: "3rem" }}>
                <FormWizardPagination
                  onPrevious={() => {}}
                  onPreviousText={"Cancel"}
                  onNext={async () => {
                    if (!state.errors) {
                      await onUpdate(state, products);
                      onChange({
                        filledTargetCategories:
                          state.filledTargetCategories?.map((c) => ({
                            ...c,
                            savedToServer: true,
                          })),
                      });
                    }
                  }}
                  onNextText={"Update"}
                  saveChild={false}
                  isPanelReview={true}
                  disableUpdateButton={
                    errors?.allocation ||
                    errors?.dailyBudget ||
                    errors?.targetACOS ||
                    errors?.maxACOS ||
                    (state.manualHarvestingEnabled &&
                      !state.harvestingConversions) ||
                    (state.algorithm ===
                      ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value &&
                      !state.biddingProgram)
                  }
                />
              </div>
            )}
          </>
        ) : (
          <Grid container>
            <Grid item xs={12} lg>
              <FormWizard
                compositeProgress={isComposite}
                state={state}
                products={products}
                errors={errors}
                isValid={isValid}
                isModal={isModal}
                closeModal={() => {
                  closeModal();
                  setSavedChildPlans(null);
                  setChildPlan(null);
                }}
                fieldsToValidate={fieldsToValidate}
                setFieldsToValidate={(fields) => setFieldsToValidate(fields)}
                updateStep={(newStep, newSubstep = null) => {
                  setStep(newStep);
                  setSubstep(newSubstep);

                  if (
                    newStep ===
                    steps?.filter((step) => !step.isHidden).length - 1
                  ) {
                    setReview(true);
                  } else {
                    setReview(false);
                  }
                }}
                editStep={editStep}
                setEditStep={setEditStep}
                editSubstep={editSubstep}
                formSteps={steps}
                onCreate={onCreate}
                onUpdate={onUpdate}
                clearForm={clearForm}
                onCancel={() => setIsComposite(undefined)}
                completeText={state.id ? "Update" : "Activate"}
                assessFormSteps={() => {
                  if (isComposite) {
                    return;
                  }

                  // Clear assets if user revisits ad type menu
                  if (
                    [
                      AD_TYPES.SPONSORED_BRAND.value,
                      AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                    ].includes(state.adType) &&
                    step === 1 &&
                    substep === 0 &&
                    state.creativeAsset
                  ) {
                    onChange({
                      creativeAsset: null,
                      lifestyleImageDetails: null,
                      lifestyleImage: null,
                      logo: null,
                      logoDetails: null,
                      video: null,
                      videoDetails: null,
                    });
                  }

                  const getAdTypeSteps = () => {
                    if (state.children.length > 0 && childComponentsCompleted) {
                      return setEditStep(3);
                    }

                    switch (state.adType) {
                      case AD_TYPES.SPONSORED_BRAND.value:
                        if (
                          state.newLandingPage ||
                          state.newProductCollection
                        ) {
                          steps[1].substeps =
                            SINGLE_PLAN_STEPS[1].landingPageSubsteps;
                        }
                        if (state.storeLandingPage) {
                          steps[1].substeps =
                            SINGLE_PLAN_STEPS[1].storePageSubsteps;
                        }
                        if (state.storeSpotlight) {
                          steps[1].substeps =
                            SINGLE_PLAN_STEPS[1].storeSpotlightSubsteps;
                        }
                        return;
                      case AD_TYPES.SPONSORED_BRAND_VIDEO.value:
                        if (state.storeLandingPage) {
                          steps[1].substeps =
                            SINGLE_PLAN_STEPS[1].storePageSubsteps;
                          return;
                        }
                        steps[1].substeps =
                          SINGLE_PLAN_STEPS[1].sbVideoSubsteps;

                        return;
                      default:
                        steps[1].substeps =
                          SINGLE_PLAN_STEPS[1].defaultSubsteps;

                        return;
                    }
                  };

                  const getProductSteps = () => {
                    if (
                      state.newLandingPage ||
                      state.storeLandingPage ||
                      state.newProductCollection
                    ) {
                      steps[2].substeps =
                        SINGLE_PLAN_STEPS[2].landingPageSubsteps;
                    } else {
                      if (
                        state.newLandingPage ||
                        state.storeLandingPage ||
                        state.newProductCollection
                      ) {
                        steps[2].substeps =
                          SINGLE_PLAN_STEPS[2].defaultSubsteps;
                      }
                    }

                    return;
                  };

                  const getTargetSteps = () => {
                    if (state.renderAdvancedOptions) {
                      steps[3].substeps = SINGLE_PLAN_STEPS[3].funnelSubsteps;

                      // Handle walmart SB and SD creatives extended targeting
                      if (
                        [
                          AD_TYPES.SPONSORED_BRAND_WALMART.value,
                          AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                        ].includes(state.adType) ||
                        (state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
                          state.renderAdvancedOptions)
                      ) {
                        steps[4].substeps = SINGLE_PLAN_STEPS[4].funnelSubsteps;
                      }
                      if (!haveSkippedToAdvancedTargeting) {
                        setHaveSkippedToAdvancedTargeting(true);
                        // Handle unique case for store spotlight using existing store page
                        // Becase is a hidden product selection step in this case, need to sync useFormWizard steps with override below
                        if (state.storeSpotlight && state.page?.value) {
                          return { skip: { step: 2, substep: 1 } };
                        }
                        return { skip: true };
                      }

                      if (
                        state.createNewProgram &&
                        !haveSkippedToNewProgramDialogue &&
                        substep < 2
                      ) {
                        setHaveSkippedToNewProgramDialogue(true);
                        return { skip: true };
                      }
                      return;
                    }

                    steps[3].substeps = SINGLE_PLAN_STEPS[3].defaultSubsteps;

                    return;
                  };

                  // Below checks for unique media plan form paths that require extra steps and substeps
                  assessWalmartCreativesStep(channel, state, steps);
                  assessSDCreativesStep(channel, state, steps);
                  assessBiddingRulesSubstep(channel, state, steps);

                  if (
                    state.storeSpotlight &&
                    !state.newLandingPage &&
                    state.adType === AD_TYPES.SPONSORED_BRAND.value
                  ) {
                    steps[2].isHidden = true;
                  } else {
                    steps[2].isHidden = false;
                  }
                  getAdTypeSteps();
                  getProductSteps();
                  return getTargetSteps();
                }}
                formStepDependencies={[
                  state.adType,
                  state.newLandingPage,
                  state.storeLandingPage,
                  state.newProductCollection,
                  state.targetType,
                  state.renderAdvancedOptions,
                  haveSkippedToAdvancedTargeting,
                ]}
                formToRender={
                  isComposite
                    ? renderCompositeFormStep(step)
                    : renderSingleFormStep(step)
                }
                debugChildren={{
                  currentChild: currentChild,
                  savedChildPlans: savedChildPlans,
                }}
                isChild={isChild}
                saveChild={false}
                setChildComponentsCompleted={setChildComponentsCompleted}
                savedChildPlans={savedChildPlans}
                setChildPlan={setChildPlan}
                reset={resetAdPlanContext}
                getExemptNavPaths={getExemptNavPaths}
                wizardContext={"media-plan"}
              />
            </Grid>
          </Grid>
        )}
      </Fragment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  modules: selectModules(state),
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(AdPlanForm);
