import customIcon from "assets/images/mediaplan/Custom.png";
import demandGenerationIcon from "assets/images/mediaplan/DemandGeneration.png";
import productLaunchIcon from "assets/images/mediaplan/ProductLaunch.png";
import profitIcon from "assets/images/mediaplan/Profitability.png";
import sovIcon from "assets/images/mediaplan/ShareofVoice.png";
import sponsoredProductIcon from "assets/images/mediaplan/SponsoredProduct.png";
import sponsoredProductAutoIcon from "assets/images/mediaplan/SponsoredProductAuto.png";
import sponsoredDisplayIcon from "assets/images/mediaplan/SponsoredDisplay.png";
import sponsoredBrandIcon from "assets/images/mediaplan/SponsoredBrand.png";
import sponsoredBrandVideoIcon from "assets/images/mediaplan/SponsoredBrandVideo.png";
import autoTargetingIcon from "assets/images/mediaplan/AutoTargeting.png";
import manualTargetingIcon from "assets/images/mediaplan/ManualTargeting.png";
import funnelTargetingIcon from "assets/images/mediaplan/FunnelTargeting.png";

export const MIN_BID_DEFAULT = 0.02;
export const MAX_BID_DEFAULT = 2000;

// Walmart constants
export const WALMART_MAX_BID_DEFAULT = 2;
export const WALMART_SB_MIN_BID_DEFAULT = 1;
export const WALMART_TOTAL_BUDGET_DEFAULT = 1000;
export const WALMART_SELLER_MANUAL_CAMPAIGN_MIN_BUDGET = 10;
export const WALMART_SELLER_AUTO_CAMPAIGN_MIN_BUDGET = 26;
export const WALMART_VENDOR_MANUAL_CAMPAIGN_MIN_BUDGET = 50;
export const WALMART_VENDOR_AUTO_CAMPAIGN_MIN_BUDGET = 126;

export const GOALS = {
  DEMAND_GENERATION: {
    value: 5,
    label: "Demand Generation",
    description: "Increase consumer awareness and demand for your products.",
    icon: demandGenerationIcon,
  },
  PRODUCT_LAUNCH: {
    value: 1,
    label: "Product Launch",
    description: "Make an impact with successful new product launches.",
    icon: productLaunchIcon,
  },
  OPTIMIZE_ACOS: {
    value: 2,
    label: "Optimize ACoS Profitability",
    description: "Drive profits to new heights with data-driven tools.",
    icon: profitIcon,
  },
  SOV: {
    value: 9,
    label: "Share of Voice",
    description:
      "Thrive in competitive marketplaces by outranking competition with powerful, data-driven keywords.",
    icon: sovIcon,
  },
  CUSTOM: {
    value: 15,
    label: "Segmented Strategy",
    description:
      "Create a customized strategy that best suits your unique needs. Only recommended for experienced users.",
    icon: customIcon,
  },
  IMPORTED_ADS: {
    value: 25,
    label: "Imported Ad",
    description: "",
    icon: customIcon,
  },
};

export const AD_TYPES = {
  // Amazon Ad Types
  SPONSORED_PRODUCT: {
    value: 10,
    label: "Sponsored Product",
    description:
      "Display individual product listings on Amazon product pages and related shopping results.",
    icon: sponsoredProductIcon,
  },
  SPONSORED_BRAND: {
    value: 11,
    label: "Sponsored Brand",
    description:
      "Promote your brand and products in a customized ad at the top of related Amazon product pages.",
    isDisabled: false,
    icon: sponsoredBrandIcon,
  },
  SPONSORED_BRAND_VIDEO: {
    value: 12,
    label: "Sponsored Brand: Video",
    description:
      "Engage customers with video ads that appear alongside related products.",
    isDisabled: false,
    icon: sponsoredBrandVideoIcon,
  },
  SPONSORED_PRODUCT_AUTO: {
    value: 14,
    label: "Sponsored Product: Auto",
    description:
      "Amazon will automatically calculate the best time and place to display your product ad. Recommended for PPC beginners.",
    icon: sponsoredProductAutoIcon,
  },
  SPONSORED_DISPLAY: {
    value: 15,
    label: "Sponsored Display (Legacy)",
    description:
      "Reach customers with informative and attractive ads that appear on relevant Amazon pages.",
    icon: sponsoredDisplayIcon,
  },
  CUSTOM: {
    value: 20,
    label: "Custom",
    description:
      "Create a customized strategy that best suits your unique needs. Only recommended for experienced users.",
    icon: customIcon,
  },
  SPONSORED_DISPLAY_V2: {
    value: 22,
    label: "Sponsored Display",
    description:
      "Reach customers with informative and attractive ads that appear on relevant Amazon pages.",
    icon: sponsoredDisplayIcon,
    targetType: "N/A",
  },
  SPONSORED_BRAND_V2: {
    value: 19,
    label: "Sponsored Brand",
    description:
      "Reach customers with informative and attractive ads that appear on relevant Amazon pages.",
    icon: sponsoredDisplayIcon,
  },
  SPONSORED_PRODUCT_GENERIC: {
    value: 19,
    label: "Sponsored Product: Generic",
    description:
      "Reach a wide array of shoppers, including those looking for generic products.",
    icon: sponsoredProductIcon,
  },
  // Google Ad Types
  GOOGLE_SHOPPING_AD: {
    value: 16,
    label: "Funnel",
    description:
      "Target shoppers across different stages of the purchasing journey.",
  },
  GOOGLE_STORE_AD: {
    value: 18,
    label: "Store",
    description:
      "Use all products in your store to drive traffic to your website.",
  },
  GOOGLE_SHOPPING_AD_LOW_COHESIVE: {
    value: 17,
    label: "Awareness",
    description: "Advertise an entire store with low budget.",
  },
  // Walmart Ad Types
  SPONSORED_PRODUCT_WALMART: {
    value: 20,
    label: "Sponsored Product",
    description:
      "Display individual product listings on Amazon product pages and related shopping results.",
    icon: sponsoredProductAutoIcon,
  },
  SPONSORED_PRODUCT_AUTO_WALMART: {
    value: 21,
    label: "Sponsored Product: Auto",
    description:
      "Walmart will automatically calculate the best time and place to display your product ad. Recommended for PPC beginners.",
    icon: sponsoredProductAutoIcon,
  },
  SPONSORED_BRAND_WALMART: {
    value: 23,
    label: "Sponsored Brand",
    description:
      "Promote your brand and products in a customized ad at the top of related Walmart product pages.",
    icon: sponsoredBrandIcon,
  },
  SPONSORED_BRAND_VIDEO_WALMART: {
    value: 24,
    label: "Sponsored Brand: Video",
    description:
      "Engage customers with video ads that appear alongside related products.",
    icon: sponsoredBrandVideoIcon,
  },
  NON_TRELLIS_CAMPAIGN: {
    value: 25,
    label: "Non Trellis Managed Campaign",
    description: "Third Party Campaign",
    icon: sponsoredProductIcon,
  },
};

export const VIDEO_AD_TYPES = {
  PRODUCT_COLLECTION: {
    value: "productCollection",
    label: "Product Collection",
  },
  VIDEO: {
    value: "video",
    label: "Product",
  },
  BRAND_VIDEO: {
    value: "brandVideo",
    label: "Landing Page",
  },
};

export const STATUS = {
  PAUSE: { value: 0, label: "Pause" },
  ACTIVE: { value: 1, label: "Active" },
  ARCHIVE: { value: 2, label: "Archive", isDisabled: true },
};

export const TARGET_TYPES = {
  AUTOMATIC: {
    value: "automatic",
    label: "Discovery",
    description:
      "Trellis's discovery system analyzes relevant competitors and products to produce powerful keywords and ASINs.",
    icon: autoTargetingIcon,
  },
  MANUAL: {
    value: "manual",
    label: "Manual",
    description: "Manually add keywords and ASINs to your advertisement.",
    icon: manualTargetingIcon,
  },
  FUNNEL: {
    value: "funnel",
    label: "Funnel",
    description:
      "Provide descriptions of your product attributes and automatically generate a list of keyword targets. You can also add ASIN targets manually.",
    icon: funnelTargetingIcon,
  },
};

export const ALGORITHM_OPTIONS = {
  PRODUCT_LAUNCH: { value: "v1", label: "Product Launch" },
  GROWTH_MAX: { value: "v2", label: "Growth Max" },
  ROAS_BOOST: { value: "v2.5", label: "ROAS Boost" },
  RULES_ENGINE_ONLY: { value: "rules", label: "Rules" },
};

export const SB_CAMPAIGN_GOAL_OPTIONS = {
  PAGE_VISIT: {
    value: "PAGE_VISIT",
    label: "Page Visit",
  },
  BRAND_IMPRESSION_SHARE: {
    value: "BRAND_IMPRESSION_SHARE",
    label: "Brand Impression Share",
  },
};

export const BID_STRATEGY_OPTIONS = {
  UP_DOWN: { value: "autoForSales", label: "Up / Down" },
  FIXED: { value: "manual", label: "Fixed" },
  DOWN_ONLY: { value: "legacyForSales", label: "Down Only" },
};

export const SB_BID_STRATEGY_OPTIONS = {
  MAXIMIZE_IMMEDIATE_SALES: {
    value: "MAXIMIZE_IMMEDIATE_SALES",
    label: "Maximize Sales",
  },
  MAXIMIZE_NEW_TO_BRAND_CUSTOMERS: {
    value: "MAXIMIZE_NEW_TO_BRAND_CUSTOMERS",
    label: "Maximize New-to-Brand Customers",
  },
};

export const SD_BID_TYPE_OPTIONS = {
  VCPM: { value: "vcpm", label: "vCPM - Pay by 1000 impressions" },
  CPC: { value: "cpc", label: "CPC - Pay by click" },
};

export const SD_CAMPAIGN_TYPE_OPTIONS = {
  AUDIENCE: { value: "audience", label: "Audience" },
  CONTEXTUAL: { value: "contextual", label: "Contextual" },
};

export const SINGLE_PLAN_STEPS = [
  {
    title: "Setup",
    validations: [
      "name",
      "dailyBudget",
      "totalBudget",
      "maxACOS",
      "targetACOS",
      "goal",
    ],
  },
  {
    title: "Ad Type",
    substeps: [
      {
        title: "Overview",
        validations: ["adType", "landingPage"],
      },
    ],
    defaultSubsteps: [
      {
        title: "Overview",
        validations: ["adType", "landingPage", "dailyBudget"],
      },
    ],
    sbVideoSubsteps: [
      {
        title: "Overview",
        validations: ["adType", "landingPage"],
      },
      {
        title: "Creative",
        validations: [
          "video",
          "headline",
          "logo",
          "brand",
          "lifestyleImage",
          "creativeBrandName",
        ],
      },
    ],
    landingPageSubsteps: [
      {
        title: "Overview",
        validations: ["adType", "landingPage", "brand", "categoryId"],
      },

      {
        title: "Creatives",
        validations: [
          "brand",
          "video",
          "headline",
          "logo",
          "store",
          "lifestyleImage",
          "creativeBrandName",
        ],
      },
    ],
    storePageSubsteps: [
      {
        title: "Overview",
        validations: ["adType", "landingPage", "categoryId"],
      },
      {
        title: "Landing Page",
        validations: ["brand", "store"],
      },
      {
        title: "Creatives",
        validations: [
          "logo",
          "video",
          "headline",
          "lifestyleImage",
          "creativeBrandName",
        ],
      },
    ],
    storeSpotlightSubsteps: [
      {
        title: "Overview",
        validations: ["adType", "landingPage", "categoryId"],
      },
      {
        title: "Landing Page",
        validations: ["brand", "store", "storeSpotlightSubpages"],
      },
      {
        title: "Creatives",
        validations: [
          "logo",
          "video",
          "headline",
          "lifestyleImage",
          "creativeBrandName",
        ],
      },
    ],
  },
  {
    title: "Select Products",
    substeps: [
      { title: "Select Products", validations: ["categoryId", "product"] },
    ],
    defaultSubsteps: [
      { title: "Select Products", validations: ["categoryId", "product"] },
    ],
    landingPageSubsteps: [
      {
        title: "Landing Page",
        validations: ["landingPageProduct", "creativeProduct", "product"],
      },
    ],
  },
  {
    title: "Targeting",
    substeps: [
      {
        title: "Targeting",
        validations: [
          "targetType",
          "filledTags",
          "filledAsins",
          "filledTagsOrAsins",
          "keywords",
          "filledProductBrands",
          "filledProductKeys",
          "sdCampaignType",
          "sdBidType",
          "sdLookbackPeriod",
          "sdAudienceTargets",
          "sdContextualTargets",
          "sdAdditionalCategoryTargets",
          "validatingCampaignNames",
          "duplicateCampaignNames",
          "campaignNames",
        ],
      },
    ],
    defaultSubsteps: [
      {
        title: "Targeting",
        validations: [
          "targetType",
          "filledTags",
          "filledAsins",
          "filledTagsOrAsins",
          "keywords",
          "filledProductBrands",
          "filledProductKeys",
          "sdCampaignType",
          "sdBidType",
          "sdLookbackPeriod",
          "sdAudienceTargets",
          "sdContextualTargets",
          "sdAdditionalCategoryTargets",
          "validatingCampaignNames",
          "duplicateCampaignNames",
          "campaignNames",
        ],
      },
    ],
    funnelSubsteps: [
      {
        title: "Targeting",
        validations: [
          "targetType",
          "filledTags",
          "filledAsins",
          "filledTagsOrAsins",
          "keywords",
          "filledProductBrands",
          "filledProductKeys",
          "sdCampaignType",
          "sdBidType",
          "sdLookbackPeriod",
          "sdAudienceTargets",
          "sdContextualTargets",
          "sdAdditionalCategoryTargets",
          "validatingCampaignNames",
          "duplicateCampaignNames",
          "campaignNames",
        ],
      },
      {
        title: "Advanced ",
        validations: [
          "filledNegativeKeywords",
          "filledNegativeAsins",
          "homePlacementBidMultiplier",
          "pagePlacementBidMultiplier",
          "otherPlacementBidMultiplier",
          "shopperSegmentBidMultiplier",
          "topOfSearchBidMultiplier",
          "productPageMultiplier",
          "restOfSearchMultiplier",
          "existingBiddingProgram",
        ],
      },
    ],
  },
  { title: "Review", validations: ["dailyBudget", "maxACOS", "targetACOS"] },
];

export const WALMART_CREATIVES_STEP = {
  title: "Creatives",
  validations: [
    "walmartBrandName",
    "walmartHeadline",
    "walmartClickURL",
    "walmartImage",
  ],
  placementIndex: 3,
};

export const SPONSORED_DISPLAY_CREATIVES_STEP = {
  title: "Creatives",
  validations: ["logo", "video", "headline", "lifestyleImage"],
  placementIndex: 3,
};

// Bidding rule validations happen internally using react-hook-form
export const BIDDING_RULES_SUBSTEP = {
  title: "Bidding Rules",
  validations: ["newBiddingProgram"],
};

export const COMPOSITE_PLAN_STEPS = [
  {
    title: "Setup",
    validations: ["name"],
  },
  {
    title: "Select Products",
    validations: ["categoryId", "product"],
  },
  {
    title: "Tactics",
    validations: [
      "validatingCampaignNames",
      "duplicateCampaignNames",
      "duplicateSegmentedCampaignNames",
      "campaignNames",
    ],
  },
  {
    title: "Targeting",
    validations: [
      "targetType",
      "filledTags",
      "filledAsins",
      "filledTagsOrAsins",
      "keywords",
      "filledProductBrands",
      "filledCompetitorBrands",
      "filledProductKeys",
      "brandTargetCollision",
      "sdCampaignType",
      "sdBidType",
      "sdLookbackPeriod",
      "sdAudienceTargets",
      "sdContextualTargets",
      "sdAdditionalCategoryTargets",
    ],
  },
  { title: "Review", validations: [] },
];

export const INVENTORY_STATES = {
  OVERSTOCK: {
    value: "OVERSTOCK",
    label: "Overstock",
    description: "Product is overstock.",
  },
  LOW_STOCK: {
    value: "LOW_STOCK",
    label: "Low Stock",
    description: "Product is stock is running low.",
  },
  IN_STOCK_SCARCE: {
    value: "IN_STOCK_SCARCE",
    label: "Low Stock",
    description: "Product is stock is running low.",
  },
  OUT_OF_STOCK: {
    value: "OUT_OF_STOCK",
    label: "Out of Stock",
    description: "Product is out of stock.",
  },
  IN_STOCK: {
    value: "IN_STOCK",
    label: "In Stock",
    description: "Product is in stock.",
  },
};

export const SALE_STATES = {
  BEST_SELLER: {
    value: "BEST_SELLER",
    label: "Best Seller",
    description: "Product is a best seller.",
  },
  CLEARANCE: {
    value: "CLEARANCE",
    label: "Clearance",
    description: "Product is on clearance.",
  },
  NORMAL: {
    value: "NORMAL",
    label: "Growth",
    description: "Product is not on sale.",
  },
  NEW_PRODUCT: {
    value: "NEW_PRODUCT",
    label: "New Product",
    description: "Product is new.",
  },
  INACTIVE: {
    value: "INACTIVE",
    label: "Inactive",
    description: "Product is inactive.",
  },
};

export const MODERATION_STATUSES = {
  FAILED: {
    value: 20,
    description: "Moderation rejected advertisement",
    label: "Failed",
  },
  APPROVED: {
    value: 11,
    description: "Moderation approved advertisement",
  },
  PENDING: {
    value: 22,
    description: "Moderation pending for advertisement",
    label: "Pending",
  },
};

export const AD_CREATION_FAILED_STATUS = 10;

// Creatives validations - used for tagging asset cards with eligibility status by ad type
export const CREATIVE_VALIDATIONS = {
  [AD_TYPES.SPONSORED_DISPLAY_V2.value]: {
    squareImage: { minHeight: 628, minWidth: 628 },
    lifestyleImage: { minHeight: 628, minWidth: 1200, maxFileSize: 5000000 },
    logo: { minHeight: 100, minWidth: 600, maxFileSize: 1000000 },
  },
  [AD_TYPES.SPONSORED_BRAND.value]: {
    squareImage: {},
    lifestyleImage: { minHeight: 628, minWidth: 1200, maxFileSize: 5000000 },
    logo: { minHeight: 400, minWidth: 400, maxFileSize: 1000000 },
  },
  [AD_TYPES.SPONSORED_BRAND_V2.value]: {
    squareImage: {},
    lifestyleImage: { minHeight: 628, minWidth: 1200, maxFileSize: 5000000 },
    logo: { minHeight: 400, minWidth: 400, maxFileSize: 1000000 },
  },
  [AD_TYPES.SPONSORED_BRAND_VIDEO.value]: {
    squareImage: {},
    lifestyleImage: {},
    logo: { minHeight: 400, minWidth: 400, maxFileSize: 1000000 },
  },
};

export const PRODUCT_AD_PAUSED = 3;
export const PRODUCT_AD_ENABLED = 1;

export const MONTHLY_BUDGET_MULTIPLIER = 30;

export const MAX_ACOS_NEGATION_THESHOLD_DEFAULT = 150;
export const CUMULATIVE_NEGATION_THESHOLD_DEFAULT = 125;

export const SB_CAMPAIGN_GOAL_SUPPORTED_MARKETPLACES = [
  "US",
  "USA",
  "CA",
  "UK",
  "DE",
  "FR",
  "IT",
  "ES",
  "IN",
  "JP",
];
