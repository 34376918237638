import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Badge, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";

const BISidebar = ({
  channel,
  applications,
  permissions,
  user,
  hideSearchAnalytics = false,
  title = "Marketing Intelligence",
  activeSection,
  activeColor,
}) => {
  const [openBI, setOpenBI] = useState(false);
  const { modules } = useSelector((state) => ({
    modules: selectModules(state),
  }));
  const isActive = (routeName) => {
    return document.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const activeLinkStyles = {
    borderBottom: "1px solid #2e0054",
    borderRadius: 0,
  };

  let now = moment();
  let tosDate = moment(user?.tos_acceptance_date?.slice(0, 10));
  let daysSinceSignup = tosDate ? now.diff(tosDate, "days") : 100;

  if (
    applications.length === 1 &&
    applications.find(
      (m) => m === "Search Analytics" && permissions !== "reports"
    )
  ) {
    const highlightLink = isActive("/user/searchanalytics");

    return (
      <li
        className={isActive("/user/searchanalytics")}
        style={{ width: "100%" }}
      >
        <NavLink
          to="/user/searchanalytics"
          className="nav-link"
          style={{
            marginBottom: "3rem",
            cursor: "pointer",
            backgroundColor: highlightLink ? activeColor : "inherit",
            borderBottom: highlightLink ? `1px solid ${activeColor}` : "",
            borderBottomWidth: "8%",
            borderRadius: highlightLink ? "5px" : 0,
          }}
        >
          <i
            className="fa fa-pie-chart"
            style={{
              color: highlightLink ? "#FFF" : "inherit",
            }}
          ></i>
          <p>
            Search Analytics
            {user.tier === "unknown" &&
              user.pricing_tier === "unknown" &&
              daysSinceSignup < 3 && (
                <Badge
                  bsClass="badge"
                  className={`badge-${
                    isActive("/user/searchanalytics") ? "secondary" : "primary"
                  }`}
                  style={{ marginLeft: "1rem" }}
                  pill
                >
                  NEW
                </Badge>
              )}
          </p>
        </NavLink>
      </li>
    );
  }

  return (
    <li
      className="user"
      style={{
        marginBottom: "2rem",
        marginTop: 0,
        paddingBottom: "1rem",
        borderBottom: "none",
        width: "100%",
        textDecoration: "none",
        borderRadius: "15px",
      }}
    >
      <NavLink
        to="#"
        href="#perf"
        onClick={(e) => {
          e.preventDefault();
          setOpenBI(!openBI);
        }}
        style={{
          backgroundColor: activeSection === "reports" ? "#2e0054" : "inherit",
        }}
      >
        <i
          className="pe-7s-news-paper"
          style={{ color: activeSection === "reports" ? "#FFF" : "#2e0054" }}
        ></i>
        <p style={{ color: activeSection === "reports" ? "#FFF" : "#2e0054" }}>
          {title}
          <span
            className={`caret ${openBI && `rotate-180`} dropdown-toggle`}
          ></span>
        </p>
      </NavLink>

      <Collapse in={openBI}>
        <ul className="nav">
          {permissions !== "reports" && !hideSearchAnalytics && (
            <li
              className={isActive("searchanalytics")}
              style={{ width: "100%" }}
            >
              <Link
                to="/user/searchanalytics"
                style={isActive("searchanalytics") ? activeLinkStyles : {}}
              >
                <span className="sidebar-mini">
                  <i className="pe-7s-graph" />
                </span>
                <span className="sidebar-normal sidebar-link">
                  Search Analytics{" "}
                  {user.tier === "unknown" &&
                    user.pricing_tier === "unknown" &&
                    daysSinceSignup < 3 && (
                      <Badge
                        bsClass="badge"
                        className={`badge-${
                          isActive("/user/searchanalytics")
                            ? "secondary"
                            : "primary"
                        }`}
                        style={{ marginLeft: ".5rem" }}
                        pill
                      >
                        NEW
                      </Badge>
                    )}
                </span>
              </Link>
            </li>
          )}
          {channel === "amazon" && (
            <li className={isActive("explore")} style={{ width: "100%" }}>
              <Link
                to="/user/content/explore"
                style={isActive("explore") ? activeLinkStyles : {}}
              >
                <span className="sidebar-mini">
                  <i className="fa fa-globe" />
                </span>
                <span className="sidebar-normal sidebar-link">
                  Keyword Explorer
                </span>
              </Link>
            </li>
          )}

          {permissions !== "reports" &&
            applications.find((x) => x === "Growth") &&
            ["walmart", "amazon"].includes(channel) && (
              <li
                className={isActive("purchasebehavior")}
                style={{ width: "100%" }}
              >
                <Link
                  to="/user/purchasebehavior"
                  style={
                    isActive("/user/purchasebehavior") ? activeLinkStyles : {}
                  }
                >
                  <span className="sidebar-mini">
                    <i className="pe-7s-cart" />
                  </span>
                  <span className="sidebar-normal sidebar-link">
                    Purchase Behavior
                  </span>
                </Link>
              </li>
            )}
          {applications.find((x) => x === "Advertising") && (
            <>
              <li
                className={isActive("report/product")}
                style={{ width: "100%" }}
              >
                <Link
                  to="/user/report/product"
                  style={
                    isActive("/user/report/product") ? activeLinkStyles : {}
                  }
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-archive" />
                  </span>
                  <span className="sidebar-normal sidebar-link">
                    Product Report
                  </span>
                </Link>
              </li>
              <li
                className={isActive("report/advertisement")}
                style={{ width: "100%" }}
              >
                <Link
                  to="/user/report/advertisement"
                  style={
                    isActive("/user/report/advertisement")
                      ? activeLinkStyles
                      : {}
                  }
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-adn" />
                  </span>
                  <span className="sidebar-normal sidebar-link">Ad Report</span>
                </Link>
              </li>
              {channel === "amazon" && (
                <li
                  className={isActive("report/search_term_report")}
                  style={{ width: "100%" }}
                >
                  <Link
                    to="/user/report/search_term_report"
                    style={
                      isActive("/user/report/search_term_report")
                        ? activeLinkStyles
                        : {}
                    }
                  >
                    <span className="sidebar-mini">
                      <i className="fa fa-search" />
                    </span>
                    <span className="sidebar-normal sidebar-link">
                      Search Term Report
                    </span>
                  </Link>
                </li>
              )}
              {checkModule(modules, "ORG_SUMMARY_REPORT") && (
                <li
                  className={isActive("report/organization/summary")}
                  style={{ width: "100%" }}
                >
                  <Link
                    to="/user/report/organization/summary"
                    style={
                      isActive("/user/report/organization/summary")
                        ? activeLinkStyles
                        : {}
                    }
                  >
                    <span className="sidebar-mini">
                      <i className="fa fa-list" />
                    </span>
                    <span className="sidebar-normal sidebar-link">
                      Summary Report
                    </span>
                  </Link>
                </li>
              )}
            </>
          )}
          <li className={isActive("report/export")} style={{ width: "100%" }}>
            <Link
              to="/user/report/export"
              style={isActive("/user/report/export") ? activeLinkStyles : {}}
            >
              <span className="sidebar-mini">
                <i className="fa fa-download" />
              </span>
              <span className="sidebar-normal sidebar-link">Exports</span>
            </Link>
          </li>
        </ul>
      </Collapse>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    channel: state.channel.currentChannel,
    user: state.user,
    amz_onboard: state.amz_onboard,
  };
};

export default connect(mapStateToProps)(withRouter(BISidebar));
