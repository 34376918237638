import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import CreatePlanModal from "./CreatePlanModal";
import PlanStatus from "./PlanStatus";
import PlanProductChart from "./PlanProductChart";
import SummaryCard from "components/core/blocks/SummaryCard";
import Metric from "components/core/blocks/SummaryMetric";
import RadialChart from "./RadialChart";
import { trellisPalette } from "../analytics/palettes";
import Accordion from "../../core/basic/Accordion";
import SummaryTable from "./SummaryTable";

const PlanSummary = ({ plan, planId, marketPlace, stats, category }) => {
  const isActual = ["finished", "executing"].find((s) => s === plan.state);
  stats = stats || plan.stats;
  const actual = {
    sales: stats?.actual_total_sales ?? 0,
    cost: stats?.actual_total_cost ?? 0,
    margin: stats?.actual_margin ?? 0,
    net_margin: stats?.actual_net_margin ?? 0,
    rops: stats?.rops ?? 0,
    roms: stats?.roms ?? 0,
    roas: stats?.roas ?? 0,
    profit: stats?.profit ?? 0,
    units: stats?.actual_total_units ?? 0,
  };
  const target = {
    sales: stats?.target_sales ?? 0,
    cost: stats?.target_total_cost ?? 0,
    margin: stats?.target_margin ?? 0,
    net_margin: stats?.target_net_margin ?? 0,
    rops: stats?.target_rops ?? 0,
    roms: stats?.target_roms ?? 0,
    roas: stats?.target_roas ?? 0,
    profit: stats?.target_profit ?? 0,
    units: stats?.target_total_units ?? 0,
  };
  const { sales, cost, margin, rops, roms, roas, units } = isActual
    ? actual
    : target;

  const currencyFormatter = (v) =>
    formatCurrency(v, marketPlace.marketPlace, false, false, true);

  const endDate = isActual ? plan.execution_end : plan.execution_start;
  let daysRemaining = moment(endDate).diff(moment(), "days");
  if (isActual) {
    daysRemaining += 5;
  }
  const totalLabel =
    daysRemaining >= 0
      ? {
          label: "Days Left",
          show: true,
          formatter: () => daysRemaining,
          color: "#000",
        }
      : { show: false };

  return (
    <>
      <SummaryCard
        title={plan.title + " - " + (isActual ? "Actual" : "Projected")}
        byline={
          <>
            {category ? `${category} - ` : ""}
            {moment(plan.execution_start).format("MMM Do")} to{" "}
            {moment(plan.execution_end).format("MMM Do")}
          </>
        }
        status={
          <PlanStatus
            status={plan.state}
            showLabel
            due={plan.planning_due}
            end={plan.execution_end}
            start={plan.execution_start}
          />
        }
      >
        <Row>
          <Col xs={3}>
            <RadialChart
              plan={plan}
              totalLabel={totalLabel}
              actual={actual}
              target={target}
              isActual={isActual}
              seriesOptions={[
                {
                  name: "Sales",
                  accessor: "sales",
                  color: trellisPalette[2],
                },
                {
                  name: "Cost",
                  accessor: "cost",
                  color: trellisPalette[13],
                },
                {
                  name: "Units",
                  accessor: "units",
                  color: trellisPalette[14],
                },
              ]}
            />
          </Col>
          <Col xs={8}>
            <Row>
              <Col xs={3}>
                <Metric
                  name="Sales"
                  formatter={currencyFormatter}
                  value={sales}
                  target={target.sales}
                  showTarget={isActual}
                  legendColor={trellisPalette[2]}
                />
              </Col>
              <Col xs={3}>
                <Metric
                  name="Cost"
                  formatter={currencyFormatter}
                  value={cost}
                  target={target.cost}
                  showTarget={isActual}
                  lower
                  legendColor={trellisPalette[13]}
                />
              </Col>
              <Col xs={3}>
                <Metric
                  name="Units"
                  formatter={(v) => formatNumber(v, null, 0)}
                  value={units}
                  target={target.units}
                  showTarget={isActual}
                  legendColor={trellisPalette[14]}
                />
              </Col>
              <Col xs={3}>
                <Metric
                  name="Margin"
                  formatter={(v) => formatPercent(v, 0)}
                  value={margin}
                  target={target.margin}
                  showTarget={isActual}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "4rem" }}>
              <Col xs={3}>
                <Metric
                  name="ROMS"
                  formatter={(v) => formatNumber(v, null, 0)}
                  value={roms}
                  target={target.roms}
                  showTarget={isActual}
                />
              </Col>
              <Col xs={3}>
                <Metric
                  name="ROPS"
                  formatter={(v) => formatNumber(v, null, 0)}
                  value={rops}
                  target={target.rops}
                  showTarget={isActual}
                />
              </Col>
              <Col xs={3}>
                <Metric
                  name="ROAS"
                  formatter={(v) => formatNumber(v, null, 0)}
                  value={roas}
                  target={target.roas}
                  showTarget={isActual}
                />
              </Col>
            </Row>
            <Col xs={1} />
          </Col>
        </Row>
        <Accordion title="Lifecycle">
          <SummaryTable
            isActual={isActual}
            stats={stats?.state}
            marketPlace={marketPlace}
          />
        </Accordion>
        <Accordion title="Category">
          <SummaryTable
            isActual={isActual}
            stats={stats?.categories}
            marketPlace={marketPlace}
            customTitleProp={"category_name"}
            showPagination={true}
            pageSize={10}
          />
        </Accordion>
        {plan.org_plans?.some((p) => p.plan_org_products?.length > 0) &&
          moment(plan.execution_start).isBefore(moment()) && (
            <Accordion title="Daily Performance">
              <Row>
                <Col xs={12} style={{ marginTop: "2rem" }}>
                  <PlanProductChart
                    marketPlace={marketPlace}
                    planId={planId}
                    plan={plan ?? {}}
                  />
                </Col>
              </Row>
            </Accordion>
          )}
        <Row className="d-flex justify-content-end">
          <CreatePlanModal plan={plan} />
        </Row>
      </SummaryCard>
      {/* Only render chart if products have been added and campaign start date surpassed */}
    </>
  );
};

export default PlanSummary;
