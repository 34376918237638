import React from "react";
import { Row, Col } from "react-bootstrap";

const AdvertisingWelcomePanel = () => {
  return (
    <Row className="h-50">
      <Col
        xs={12}
        lg={7}
        className="mx-auto text-center p-lg-5 pb-lg-0 mx-auto my-5"
      >
        <h1 className="font-weight-bold mt-0">
          Welcome to Trellis Advertising
        </h1>
        <p className="lead fw-normal fs-3">
          Discover the power of Trellis’ bidding algorithm by creating your
          first media plan
        </p>
      </Col>
    </Row>
  );
};

export default AdvertisingWelcomePanel;
