import React from "react";
import Tags from "components/core/blocks/Tags";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { sendToastNotification } from "utils/sendToastNotification";
import { useDates } from "dates/useDates";
import { useSelector } from "react-redux";
import CustomTag from "components/core/blocks/CustomTag";
import HiddenToolTip from "utils/hiddenToolTips";

const AdTags = ({ adPlanData, notificationRef }) => {
  const { marketPlace } = useSelector((state) => state);
  const { start, end, preStart, preEnd, group } = useDates();

  const queryClient = useQueryClient();

  const adDetailsQueryKey = [
    "ad_report",
    marketPlace.marketPlace,
    start.format("YYYY-MM-DD"),
    end.format("YYYY-MM-DD"),
    preStart.format("YYYY-MM-DD"),
    preEnd.format("YYYY-MM-DD"),
    group,
    adPlanData?.id?.toString(),
  ];

  const updateTags = useMutation(
    async (updatedTags) => {
      return await api.patch(`/ads/${adPlanData.id}/`, {
        tags: [...new Set(updatedTags)],
      });
    },
    {
      onSuccess: (res, variables) => {
        sendToastNotification(
          notificationRef,
          "success",
          `Media Plan Tags Successfully Updated`
        );
        queryClient.invalidateQueries(adDetailsQueryKey);
      },
      onError: (res, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update Media Plan Tags`
        );
        queryClient.invalidateQueries(adDetailsQueryKey);
      },
    }
  );

  return (
    <div className="py-5 text-center fs-standard d-flex align-items-center">
      {adPlanData?.tags?.map((tag, index) => (
        <CustomTag
          taggedItemDescription={"media plan"}
          tag={tag}
          key={tag}
          onUpdate={(tag) => {
            let updatedTags = adPlanData?.tags?.filter(
              (tagToDelete) => tag.toLowerCase() !== tagToDelete.toLowerCase()
            );

            updateTags.mutate(updatedTags);
          }}
        />
      ))}

      <Tags
        filledTags={adPlanData?.tags ?? []}
        saveFilledTags={async (filledTags) => updateTags.mutate(filledTags)}
        name={`Add Media Plan Tags`}
        hideTagsField={true}
        hideEditButton={true}
        altButton={
          <span
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
          >
            <HiddenToolTip
              toolTip={`Add custom media plan tags`}
              position={"top"}
              id={"inlineCustomTag"}
            />
            <i
              style={{
                fontSize: "15px",
                opacity: ".8",
              }}
              className="fa fa-plus"
            />
          </span>
        }
        altButtonContainerClasses={"mx-auto"}
      />
    </div>
  );
};

export default AdTags;
