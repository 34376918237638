import React from "react";
import ToolTips from "utils/toolTips";
import HiddenToolTip from "utils/hiddenToolTips";
import TagsInput from "react-tagsinput";
import CenteredModal from "../basic/CenteredModal";
import { useState, useRef } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { CancelButton, UpdateButton } from "./AdPlan/buttons";
import ReactTags from "react-tag-autocomplete";

const Tags = ({
  name,
  toolTip,
  toolTipId,
  toolTipPosition,
  hiddenTooltip,
  placeholder,
  disabled,
  inputDisabled = false,
  filledTags,
  saveFilledTags,
  hideTagsField,
  reviewDisplay,
  smallIcon,
  hideEditButton,
  tagClass,
  overrideButtonStyles,
  altButton,
  altButtonContainerClasses = "",
  children,
  showSuggestedTags = false,
  suggestedTags,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [editTags, setEditTags] = useState("");
  const showEditModal = () => {
    setEditTags(filledTags.join("\n"));
    setShowEdit(true);
  };
  const closeEditModal = () => {
    setShowEdit(false);
    setEditTags("");
  };
  const saveEditTags = () => {
    saveFilledTags(
      editTags
        .split(/[\n,]/)
        ?.map((s) => s.trim())
        .filter((s) => !!s)
    );
    closeEditModal();
  };

  const suggestions = suggestedTags?.map((tag) => ({ name: tag, id: tag }));

  const reactTags = useRef();

  const onDeleteTag = (tagIndex) => {
    saveFilledTags(filledTags.filter((_, i) => i !== tagIndex));
  };

  const onAddTag = (newTag) => {
    saveFilledTags([...filledTags, newTag.name]);
  };

  return reviewDisplay && filledTags?.length < 1 ? null : (
    <div>
      {!hideTagsField && name}
      {toolTip && !hiddenTooltip && (
        <ToolTips
          toolTip={toolTip}
          position={toolTipPosition ? toolTipPosition : "top"}
          id={toolTipId}
        />
      )}
      {reviewDisplay ? (
        <Grid
          container
          style={{
            maxHeight: "160px",
            display: "block",
            overflowY: "scroll",
          }}
        >
          <Grid
            item
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {filledTags?.map((tag) => (
              <div
                className="fs-standard"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: tagClass === "tag-azure" ? "#2e0054" : "#ddd",
                  color: tagClass === "tag-azure" ? "#fff" : "#777777",
                  border:
                    tagClass === "tags-azure"
                      ? "1px solid #2e0054"
                      : "1px solid #777777",
                  borderRadius: "2px",
                  margin: "0 0.5rem 0.5rem 0",
                  padding: "0.25rem 0.75rem",
                }}
              >
                {tag}
              </div>
            ))}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container>
            {!hideTagsField && (
              <Grid
                item
                xs={11}
                flexGrow={1}
                style={{
                  flexGrow: 1,
                  pointerEvents: reviewDisplay ? "none" : "inherit",
                  maxHeight: "130px",
                  overflowY: "auto",
                  paddingRight: "2rem",
                }}
              >
                {showSuggestedTags && suggestions?.length ? (
                  <ReactTags
                    ref={reactTags}
                    tags={filledTags?.map((tag) => ({ name: tag, id: tag }))}
                    suggestions={suggestions}
                    onAddition={onAddTag}
                    onDelete={onDeleteTag}
                    minQueryLength={1}
                    suggestionsTransform={(query, suggestions) => {
                      let unselectedSuggestions = suggestions?.filter(
                        (suggestion) => !filledTags?.includes(suggestion?.name)
                      );
                      if (!query.length) {
                        return unselectedSuggestions;
                      }

                      return unselectedSuggestions?.filter((suggestedTag) =>
                        suggestedTag?.name
                          ?.toLowerCase()
                          ?.includes(query.toLowerCase())
                      );
                    }}
                    tagComponent={({ tag, removeButtonText, onDelete }) => (
                      <button
                        className="react-tags__selected-tag"
                        onClick={onDelete}
                      >
                        {tag.name}
                        <span className="ps-2">✕</span>
                      </button>
                    )}
                    allowNew={true}
                    allowBackspace={true}
                  />
                ) : (
                  <TagsInput
                    value={filledTags ?? []}
                    onChange={saveFilledTags}
                    addOnBlur={true}
                    tagProps={{
                      className: `react-tagsinput-tag tag-fill ${
                        tagClass
                          ? tagClass
                          : reviewDisplay
                          ? "tag-review"
                          : "tag-azure"
                      }`,
                    }}
                    inputProps={{
                      placeholder: placeholder,
                      disabled: inputDisabled,
                    }}
                    disabled={reviewDisplay || disabled ? true : false}
                  />
                )}
              </Grid>
            )}
            {!hideEditButton && (
              <Grid item xs={1}>
                <IconButton
                  onClick={() => showEditModal()}
                  style={
                    overrideButtonStyles
                      ? { ...overrideButtonStyles }
                      : {
                          display: hiddenTooltip ? "relative" : "inherit",
                          padding: smallIcon ? "6px" : "inherit",
                          marginLeft: "1rem",
                        }
                  }
                >
                  <EditIcon />
                  {toolTip && hiddenTooltip && (
                    <HiddenToolTip
                      toolTip={toolTip}
                      position={toolTipPosition ? toolTipPosition : "top"}
                      id={toolTipId}
                    />
                  )}
                </IconButton>
              </Grid>
            )}

            {altButton && (
              <span
                className={altButtonContainerClasses}
                onClick={() => showEditModal()}
              >
                {altButton}
              </span>
            )}
          </Grid>
          <CenteredModal
            show={showEdit}
            closeModal={() => closeEditModal()}
            title={name}
            dialogClassName="modal-60w"
            content={
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={10}
                    xs={12}
                    value={editTags}
                    variant="outlined"
                    onChange={(e) => {
                      setEditTags(e.target.value);
                    }}
                  />
                  <span>Separate text using commas or new lines</span>
                </Grid>
                {children ? (
                  <Grid item xs={12}>
                    {children}
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid container item spacing={2} xs={12}>
                  <CancelButton
                    text={"Cancel"}
                    onClick={() => closeEditModal()}
                  />
                  <UpdateButton test={"Save"} onClick={() => saveEditTags()} />
                </Grid>
              </Grid>
            }
          />
        </>
      )}
    </div>
  );
};

export default Tags;
