const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function clearCookies() {
  var cookies = document.cookie.split("; ");

  if (!isLocalhost && window.location.hostname.includes(".com")) {
    for (let i = 0; i < cookies.length; i++) {
      let j = window.location.hostname.split(".com");
      while (j.length > 0) {
        let cookieBaseProd =
          encodeURIComponent(cookies[i].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          j.join(".") +
          " ;path=";
        let k = window.location.pathname.split("/");
        document.cookie = cookieBaseProd + "/";
        while (k.length > 0) {
          document.cookie = cookieBaseProd + k.join("/");
          k.pop();
        }
        j.shift();
      }
    }
  } else if (!isLocalhost && window.location.hostname.includes(".io")) {
    for (let i = 0; i < cookies.length; i++) {
      let j = window.location.hostname.split(".io");
      while (j.length > 0) {
        let cookieBaseProd =
          encodeURIComponent(cookies[i].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          j.join(".") +
          " ;path=";
        let k = window.location.pathname.split("/");
        document.cookie = cookieBaseProd + "/";
        while (k.length > 0) {
          document.cookie = cookieBaseProd + k.join("/");
          k.pop();
        }
        j.shift();
      }
    }
  } else {
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        var p = window.location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
  }
}

export default clearCookies;
