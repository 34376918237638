// Function to calculate the average of an array of numbers
export const average = (array) => {
  if (array.length === 0) return 0;
  return array.reduce((a, b) => a + b, 0) / array.length;
};

export function standardDeviation(values) {
  let mean = values.reduce((acc, val) => acc + val, 0) / values.length;
  let squareDiffs = values.map((val) => (val - mean) ** 2);
  let avgSquareDiff =
    squareDiffs.reduce((acc, val) => acc + val, 0) / values.length;
  return Math.sqrt(avgSquareDiff);
}

export function levenshteinDistance(a, b) {
  const matrix = [];

  // Initialize the matrix with 0..n across the top row and down the first column.
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Populate the matrix
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // Substitution
          Math.min(
            matrix[i][j - 1] + 1, // Insertion
            matrix[i - 1][j] + 1 // Deletion
          )
        );
      }
    }
  }

  // The Levenshtein distance is in the bottom-right corner of the matrix
  return matrix[b.length][a.length];
}
