export const PRODUCT_AMAZON_LINKS = {
  USA: "https://www.amazon.com/dp/",
  CA: "https://www.amazon.ca/dp/",
  MX: "https://www.amazon.com.mx/dp/",
  BR: "https://www.amazon.com.br/dp/",
  UK: "https://www.amazon.co.uk/dp/",
  AE: "https://www.amazon.ae/dp/",
  SA: "https://www.amazon.sa/dp/",
  AU: "https://www.amazon.com.au/dp/",
  ES: "https://www.amazon.es/dp/",
  DE: "https://www.amazon.de/dp/",
  NL: "https://www.amazon.nl/dp/",
  FR: "https://www.amazon.fr/dp/",
  IT: "https://www.amazon.it/dp/",
  IN: "https://www.amazon.in/dp/",
  JP: "https://www.amazon.co.jp/dp/",
  SG: "https://www.amazon.sg/dp/",
  SE: "https://www.amazon.se/dp/",
  PL: "https://www.amazon.pl/dp/",
  BE: "https://www.amazon.com.be/dp/",
};

export const SEARCH_AMAZON_LINKS = {
  USA: "https://www.amazon.com/s?k=",
  CA: "https://www.amazon.ca/s?k=",
  MX: "https://www.amazon.com.mx/s?k=",
  BR: "https://www.amazon.com.br/s?k=",
  UK: "https://www.amazon.co.uk/s?k=",
  AE: "https://www.amazon.ae/s?k=",
  SA: "https://www.amazon.sa/s?k=",
  AU: "https://www.amazon.com.au/s?k=",
  ES: "https://www.amazon.es/s?k=",
  DE: "https://www.amazon.de/s?k=",
  NL: "https://www.amazon.nl/s?k=",
  FR: "https://www.amazon.fr/s?k=",
  IT: "https://www.amazon.it/s?k=",
  IN: "https://www.amazon.in/s?k=",
  JP: "https://www.amazon.co.jp/s?k=",
  SG: "https://www.amazon.sg/s?k=",
  SE: "https://www.amazon.se/s?k=",
  PL: "https://www.amazon.pl/s?k=",
  BE: "https://www.amazon.com.be/s?k=",
};

export const REGION_MARKETPLACE_LIST_MAP = {
  NA: ["USA", "US", "CA", "MX", "BR"],
  EU: [
    "UK",
    "AE",
    "ES",
    "DE",
    "NL",
    "FR",
    "IT",
    "IN",
    "GR",
    "SE",
    "PL",
    "BE",
    "SA",
  ],
  FE: ["AU", "JP", "SG"],
};

export const AMAZON_REGIONS_MARKETPLACE_MAP = {
  USA: "NA",
  US: "NA",
  CA: "NA",
  MX: "NA",
  BR: "NA",
  UK: "EU",
  NL: "EU",
  ES: "EU",
  DE: "EU",
  AE: "EU",
  IT: "EU",
  IN: "EU",
  FR: "EU",
  AU: "FE",
  SG: "FE",
  JP: "FE",
  SE: "EU",
  PL: "EU",
  TR: "EU",
  BE: "EU",
  SA: "EU",
};

export const MARKETPLACE_CURRENCY_DENOMINATION = {
  USA: "$",
  CA: "$",
  MX: "$",
  BR: "R$",
  UK: "£",
  AE: "AED",
  SA: "﷼",
  AU: "$",
  ES: "€",
  DE: "€",
  NL: "€",
  SE: "kr",
  PL: "zł",
  BE: "€",
  IT: "€",
  FR: "€",
  IN: "₹",
  SG: "S$",
  JP: "¥",
};

export const MARKETPLACE_CURRENCY_CODE = {
  USA: "USD",
  CA: "CAD",
  MX: "MXN",
  BR: "BRL",
  UK: "GBP",
  AE: "AED",
  SA: "SAR",
  AU: "AUD",
  ES: "EUR",
  DE: "EUR",
  SE: "SEK",
  PL: "PLN",
  BE: "EUR",
  NL: "EUR",
  IT: "EUR",
  FR: "EUR",
  IN: "INR",
  JP: "JPY",
  SG: "SGD",
};

export const MARKETPLACE_TIMEZONE = {
  CA: "US/Pacific",
  US: "US/Pacific",
  USA: "US/Pacific",
  UK: "Europe/London",
  AE: "Asia/Dubai",
  AU: "Australia/Sydney",
  IN: "Asia/Kolkata",
  BR: "America/Sao_Paulo",
  MX: "US/Pacific",
  ES: "Europe/Paris",
  NL: "Europe/Amsterdam",
  IT: "Europe/Paris",
  FR: "Europe/Paris",
  DE: "Europe/Paris",
  SG: "Asia/Singapore",
  JP: "Asia/Tokyo",
  PL: "Europe/Warsaw",
  SE: "Europe/Stockholm",
  BE: "Europe/Brussels",
  SA: "Asia/Riyadh",
};

function toCurrency(data, marketPlace) {
  if (typeof data === "number") {
    const fixedValue = data.toFixed(2);
    let val =
      fixedValue !== "-"
        ? MARKETPLACE_CURRENCY_DENOMINATION[marketPlace] + fixedValue
        : fixedValue;

    return toComma(val);
  }
}

function toComma(val) {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export { toCurrency,  };
