import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Form } from "react-bootstrap";
import DialogueCard from "./DialogueCard";
import { useLocation, useHistory } from "react-router-dom";
import { snakeCaseToSentenceCase } from "utils/formatText";
import SkipToDashboardLink from "./SkipToDashboardLink";
import {
  getAmazonAdvClientURL,
  getAmazonSPAPIClientURL,
  getWalmartClientURL,
} from "utils/getUrlPrefix";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { useFetch } from "hooks/api";
import Select from "react-select";
import { REGION_MARKETPLACE_LIST_MAP } from "utils/marketplaceConstants";
import { setMarketPlace as setGlobalMarketPlace } from "redux/actions/marketPlace";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { setErrors } from "redux/actions/errorActions";
import WalmartAdvertisingForm from "./WalmartAdvertisingForm";
import WalmartPartnerDialogue from "./WalmartPartnerDialogue";
import WalmartMarketplaceForm from "./WalmartMarketplaceForm";
import store from "redux/store";
import { setChannel } from "redux/actions/channel";

const OAUTH_FLOW = false;

const getApiOptions = (
  channel,
  setSelectedCard,
  errorMessage,
  setErrorMessage,
  onboardingData,
  setOnboardingData
) => {
  const {
    user: { organization_type: orgType },
  } = store.getState();

  const API_OPTIONS = {
    amazon: [
      {
        value: "amazon-advertising",
        title: "Amazon Advertising API",
        description: null,
        url: getAmazonAdvClientURL(),
      },
      {
        value: "amazon-selling-partner",
        title: "Amazon Seller API",
        description: null,
        hidden: localStorage.secondaryChannel === "amazon",
      },
    ],
    walmart: [
      {
        value: "walmart-partner-verification",
        title: "Add Trellis as an Advertising Partner",
        description: "",
        children: (
          <Row
            className={`d-flex align-items-center justify-content-center bg-light
              border-start border-end border-bottom ms-0 py-4 px-4 mb-4 w-100`}
          >
            <WalmartPartnerDialogue
              onClick={() => {
                setOnboardingData({
                  ...onboardingData,
                  trellisPartnerVerified: true,
                });
                setSelectedCard(1);
              }}
            />
          </Row>
        ),
      },
      {
        value: "walmart-advertising",
        title: "Walmart Advertising API ",
        description: null,
        children: (
          <Row
            className={`d-flex align-items-center justify-content-center bg-light
              border-start border-end border-bottom ms-0 py-4 px-4 mb-4 w-100`}
          >
            <WalmartAdvertisingForm
              onSubmit={() => {
                setSelectedCard(2);
              }}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              onboardingData={onboardingData}
              setOnboardingData={setOnboardingData}
            />
          </Row>
        ),
      },
      OAUTH_FLOW
        ? {
            value: "walmart-marketplace",
            title: "Walmart Marketplace API",
            description: null,
            url: getWalmartClientURL(),
            hidden:
              orgType === "vendor" ||
              onboardingData.walmartSellerType === "vendor",
          }
        : {
            value: "walmart-marketplace",
            title: "Walmart Marketplace API",
            description: null,
            hidden:
              orgType === "vendor" ||
              onboardingData.walmartSellerType === "vendor",
            children: (
              <Row
                className={`d-flex align-items-center justify-content-center bg-light
              border-start border-end border-bottom ms-0 py-4 px-4 mb-4 w-100`}
              >
                <WalmartMarketplaceForm
                  onSubmit={() => setSelectedCard(null)}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              </Row>
            ),
          },
    ],
  };

  return API_OPTIONS[channel];
};

const PROMPTS = {
  amazon:
    "To effectively integrate your data we need access to your Amazon Advertising and Amazon Seller APIs.",
  walmart:
    "We require access to both the Walmart Marketplace API and Walmart Advertising API",
};

const APIConnections = ({ onboardingData, setOnboardingData }) => {
  const [region, setRegion] = useState(null);
  const [marketPlace, setMarketPlace] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  const {
    user: { organization_type: organizationType, organization_type: orgType },
    amz_onboard: {
      amazon_advertising_access: amazonAdvertisingConnected,
      amazon_spapi_access: naSpApiConnected,
      eu_amazon_spapi_access: euSpApiConnected,
      fe_amazon_spapi_access: feSpApiConnected,
    },
    amz_onboard,
    walmart_onboard: {
      partner_access_state: walmartAdsPartnerState,
      advertiser_id_provided: walmartAdvertiserIdProvided,
      marketplace_credentials_provided: walmartMarketplaceCredentialsProvided,
    },
    errors,
    auth,
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const url = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const searchParams = new URLSearchParams(url.search);
  const channel = searchParams.get("channel") ?? "amazon";

  if (searchParams.has("noSPAPICode") && !errorMessage) {
    setErrorMessage(
      "An error occurred connecting to the Amazon Seller API. Please try again."
    );
  }

  const { data: sellerInfo, isLoading: isSellerInfoLoading } = useFetch(
    ["sellerInfo"],
    "/api/sellerinfo/",
    {},
    {
      onSuccess: (res) => {
        // If only one applicable region, automatically set to get proper URLs for SP-API
        if (res?.account_region?.length === 1) {
          setRegion({ value: res.account_region[0] });
          setMarketPlace(res.amazon_seller_marketplaces[0]);
          setErrorMessage("");
        }
      },

      enabled: true,
      select: (res) => res.data,
      default: {},
    }
  );

  const saveSpApiAbortedStatus = useMutation({
    mutationFn: async (region) => {
      return await api.post("/api/amazonspapi/", {
        set_amazon_spapi_access_to_pending: region,
      });
    },
    onSuccess: async () => {
      localStorage.removeItem("spRegionAttempted");
    },
  });

  const saveSpApiConnectingStatus = useMutation({
    mutationFn: async (region) => {
      return await api.post("/api/amazonspapi/", {
        set_amazon_spapi_access_to_in_progress: region,
      });
    },
    onSuccess: async () => {
      const initialMarketplace = REGION_MARKETPLACE_LIST_MAP[region.value].find(
        (mp) => sellerInfo.amazon_seller_marketplaces.includes(mp)
      );
      localStorage.setItem("Marketplace", marketPlace);
      dispatch(setGlobalMarketPlace(initialMarketplace));

      const spApiURL = getAmazonSPAPIClientURL(
        organizationType === "seller",
        initialMarketplace,
        region.value,
        "onboarding"
      );
      refreshAmzOnboardStatus(
        updateAmzOnboardingStatus,
        auth.tokens.access_token,
        setErrors
      );

      window.location.href = spApiURL;
    },
  });

  const triggerAmazonOnboardingTasks = useMutation({
    mutationFn: async () => {
      return await api.post("/api/mwsinfotask/");
    },
  });

  const triggerWalmartOnboardingTasks = useMutation({
    mutationFn: async (action) => {
      await api.post("/walmart/auth/onboarding", {
        action: action,
      });
      queryClient.invalidateQueries();
    },
  });

  const handleApiTasks = async () => {
    // Revert signup page title to default
    const documentTitle = document.head.querySelector("title");
    documentTitle.innerText = "Trellis";

    const walmartSecondaryChannelOnboardingFlag =
      localStorage.secondaryChannel === "walmart" &&
      channel.currentChannel !== "walmart";

    if (walmartSecondaryChannelOnboardingFlag) {
      await triggerWalmartOnboardingTasks.mutateAsync(
        "mark_secondary_channel_onboarding_complete"
      );

      dispatch(setChannel(localStorage.secondaryChannel));

      localStorage.setItem("channel", localStorage.secondaryChannel);
      localStorage.removeItem("secondaryChannel");
    } else if (channel.currentChannel === "walmart") {
      await triggerWalmartOnboardingTasks.mutateAsync(
        "mark_onboarding_complete"
      );
    } else {
      await triggerAmazonOnboardingTasks.mutate();
    }

    if (!["active", "trial_active"].includes(amz_onboard.org_status)) {
      dispatch(
        updateAmzOnboardingStatus({ ...amz_onboard, org_status: "pre_trial" })
      );
    }

    history.push("/onboarding/v2/summary");
  };

  // Below cleans up cases where a user attempts SP auth but cancels out
  // The 'in_progress' flag is how we decide to show loading states during sign up redirects
  useEffect(() => {
    if (
      localStorage.spRegionAttempted &&
      [feSpApiConnected, naSpApiConnected, euSpApiConnected].includes(
        "in_progress"
      ) &&
      !saveSpApiAbortedStatus.isLoading &&
      !saveSpApiConnectingStatus.isLoading &&
      !isSellerInfoLoading
    ) {
      saveSpApiAbortedStatus.mutate(localStorage.spRegionAttempted);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feSpApiConnected,
    naSpApiConnected,
    euSpApiConnected,
    isSellerInfoLoading,
  ]);

  const getRegionOptions = () => {
    const regionOptions = {
      NA: {
        label: "North America",
        value: "NA",
      },
      EU: {
        label: "Europe",
        value: "EU",
      },
      FE: {
        label: "Far East",
        value: "FE",
      },
    };

    return sellerInfo?.account_region?.map((region) => regionOptions[region]);
  };

  const showSuccessIconPlaceholder =
    amazonAdvertisingConnected === "connected" &&
    ![naSpApiConnected, euSpApiConnected, feSpApiConnected]?.includes(
      "connected"
    );

  const showContinueButton = useMemo(() => {
    if (channel === "walmart") {
      return (
        walmartAdsPartnerState === "connected" &&
        walmartAdvertiserIdProvided &&
        ((orgType === "seller" && walmartMarketplaceCredentialsProvided) ||
          orgType === "vendor" ||
          onboardingData.walmartSellerType === "vendor")
      );
    }

    return (
      amazonAdvertisingConnected === "connected" &&
      [naSpApiConnected, euSpApiConnected, feSpApiConnected]?.includes(
        "connected"
      )
    );
  }, [
    channel,
    orgType,
    walmartAdsPartnerState,
    walmartAdvertiserIdProvided,
    walmartMarketplaceCredentialsProvided,
    amazonAdvertisingConnected,
    naSpApiConnected,
    euSpApiConnected,
    feSpApiConnected,
    onboardingData.walmartSellerType,
  ]);

  return (
    <Row className="my-5">
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        <h2 className="mt-5 mb-1 fw-600">
          Connect {snakeCaseToSentenceCase(channel)}
        </h2>
        <p className="fs-4">{PROMPTS[channel]}</p>
      </Col>

      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        {errorMessage ? (
          <p className="mt-5 ps-3 fs-5 invalid-feedback d-block text-center">
            {errorMessage}
          </p>
        ) : errors?.length ? (
          errors.map((error) => (
            <p className="mt-5 ps-3 fs-5 invalid-feedback d-block text-center">
              {error.message}
            </p>
          ))
        ) : (
          <></>
        )}
        {getApiOptions(
          channel,
          setSelectedCard,
          errorMessage,
          setErrorMessage,
          onboardingData,
          setOnboardingData
        )
          ?.filter((option) => !option.hidden)
          ?.map((option, index) => (
            <>
              {option.value === "amazon-selling-partner" &&
              sellerInfo?.account_region?.length > 1 ? (
                <div
                  className="w-100 fs-4 pb-4"
                  style={{
                    paddingRight: showSuccessIconPlaceholder
                      ? "60px"
                      : "inherit",
                  }}
                >
                  <Form.Label className="pb-2">
                    <span style={{ fontWeight: 600, color: "#2e0054" }}>
                      Select a Region:
                    </span>
                  </Form.Label>
                  <Select
                    value={region}
                    options={getRegionOptions()}
                    onChange={(option) => {
                      setRegion(option);
                      setErrorMessage("");
                    }}
                    classNames={{
                      control: "py-5",
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex align-items-center">
                <DialogueCard
                  contents={option}
                  justify="center"
                  additionalClasses="my-3"
                  disabled={
                    (option.value === "amazon-selling-partner" &&
                      amazonAdvertisingConnected !== "connected") ||
                    (option.value === "amazon-selling-partner" &&
                      !region?.value) ||
                    (option.value === "walmart-advertising" &&
                      walmartAdsPartnerState !== "connected") ||
                    (option.value === "walmart-marketplace" &&
                      !walmartAdvertiserIdProvided)
                  }
                  key={index}
                  index={index}
                  trackIndex={channel === "walmart"}
                  showChildren={selectedCard === index}
                  onClick={() => {
                    setSelectedCard(index === selectedCard ? null : index);

                    if (typeof option.onClick === "function") {
                      option.onClick();
                      return;
                    }

                    if (option.value === "amazon-selling-partner") {
                      localStorage.setItem("spRegionAttempted", region.value);
                      saveSpApiConnectingStatus.mutate(region?.value);
                      return;
                    }
                  }}
                  onDisabledClick={(value) => {
                    if (option.value === "amazon-selling-partner") {
                      if (amazonAdvertisingConnected !== "connected") {
                        setErrorMessage(
                          "Please connect to Amazon Advertising first by clicking the card below."
                        );
                        return;
                      }
                      if (!region?.value) {
                        setErrorMessage(
                          "Please select a region to authorize the SP-API. More regions can be authorized later."
                        );
                        return;
                      }
                    }
                  }}
                  url={option.url}
                  showSuccessIcon={
                    (option.value === "amazon-advertising" &&
                      amazonAdvertisingConnected === "connected") ||
                    (option.value === "amazon-selling-partner" &&
                      [
                        naSpApiConnected,
                        euSpApiConnected,
                        feSpApiConnected,
                      ]?.includes("connected")) ||
                    (option.value === "walmart-partner-verification" &&
                      walmartAdsPartnerState === "connected") ||
                    (option.value === "walmart-advertising" &&
                      walmartAdvertiserIdProvided) ||
                    (option.value === "walmart-marketplace" &&
                      walmartMarketplaceCredentialsProvided)
                  }
                  showSuccessIconPlaceholder={
                    (option.value !== "amazon-advertising" &&
                      showSuccessIconPlaceholder) ||
                    (option.value === "walmart-advertising" &&
                      walmartAdsPartnerState === "connected" &&
                      !walmartAdvertiserIdProvided) ||
                    (option.value === "walmart-marketplace" &&
                      walmartAdsPartnerState === "connected" &&
                      !walmartMarketplaceCredentialsProvided)
                  }
                />
              </div>
            </>
          ))}
        {showContinueButton ? (
          <div className="d-flex">
            <button
              className="adplan_button w-100 mt-3"
              onClick={async () => {
                handleApiTasks();
              }}
              style={{
                marginRight: "60px",
              }}
            >
              Continue
            </button>
          </div>
        ) : (
          <SkipToDashboardLink
            additionalStyles={{
              paddingRight:
                amazonAdvertisingConnected === "connected" ||
                walmartAdsPartnerState === "connected"
                  ? "65px"
                  : "inherit",
            }}
            onClick={() => handleApiTasks()}
          />
        )}
      </Col>
    </Row>
  );
};

export default APIConnections;
