import success_tick from "assets/images/status/successful_tick_150x150.png";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";

class PaymentProcessing extends React.Component {
  onClick() {
    refreshAmzOnboardStatus(
      this.props.updateAmzOnboardingStatus,
      this.props.auth.tokens.access_token,
      this.props.setErrors
    );

    this.props.discoveryType === "pricing"
      ? this.props.history.push({
          pathname: "/user/pricing",
          state: {
            firstVisit: true,
          },
        })
      : this.props.history.push("/user/dashboard");

    refreshAmzOnboardStatus(
      this.props.updateAmzOnboardingStatus,
      this.props.auth.tokens.access_token,
      this.props.setErrors
    );
  }

  render() {
    return (
      <SweetAlert
        title={
          this.props.subSuccessful
            ? "Subscription Successful"
            : "Processing Subscription"
        }
        confirmBtnText="Next"
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={(e) => this.onClick()}
        disabled={!this.props.subSuccessful}
      >
        {!this.props.subSuccessful ? (
          <>
            Please Wait...
            <br />
            <br />
            <br />
            We're processing your transaction. Please don't reload or refresh
            the page, this may take a few seconds.
          </>
        ) : (
          <>
            Subscription Completed! <br />
            <br />
            <img
              height="75px"
              width="75px"
              src={success_tick}
              alt="Connected Advertising Account"
              className="text-center"
            />
            <br />
            <br />A confirmation email has been sent to you.
          </>
        )}
      </SweetAlert>
    );
  }
}

PaymentProcessing.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketPlace: state.marketPlace,
  amz_onboard: state.amz_onboard,
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentProcessing));
