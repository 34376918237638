import React from "react";

const Icon = ({ path }) => (
  <i
    style={{
      display: "inline-block",
      width: "30px",
      height: "30px",
      color: "white",
      backgroundImage: `url("${path}")`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
  ></i>
);

export default Icon;
