import React from "react";
import {
  DateField,
  InlineInputField,
  InlineSelect,
} from "components/custom/programs/fields";
import { Form } from "react-bootstrap";
import { useWatch } from "react-hook-form";

const ScopeForm = ({ fieldName, control, formState }) => {
  const operatorOptions = [
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "between", label: "Between" },
  ];
  const operator = useWatch({
    control,
    name: `${fieldName}.operator`,
  });

  return (
    <Form.Group>
      <div className="d-flex align-items-center flex-wrap">
        <InlineSelect
          options={operatorOptions}
          fieldName={`${fieldName}.operator`}
          control={control}
          rules={{ required: true }}
        />
        {operator === "between" && (
          <>
            <div style={{ minWidth: "10rem" }}>
              <DateField
                fieldName={`${fieldName}.values.start_date`}
                control={control}
                rules={{ required: true }}
                placeholder={"start date"}
              />
            </div>
            <span className="mx-2">and</span>
            <div style={{ minWidth: "10rem" }}>
              <DateField
                fieldName={`${fieldName}.values.end_date`}
                control={control}
                rules={{ required: true }}
                placeholder={"end date"}
              />
            </div>
          </>
        )}
        {operator !== "between" && (
          <>
            <InlineInputField
              width={"7rem"}
              fieldName={`${fieldName}.values.days`}
              control={control}
              rules={{ required: true }}
            />
            <span className="mx-2">days</span>
          </>
        )}
      </div>
    </Form.Group>
  );
};

export default ScopeForm;
