export const buildLookupColumn = (key, colTitle, lookup, options = {}) => {
  return {
    key,
    compareKey: null,
    options: {
      metric: {
        id: key,
        format: "text",
        accessor: (d) => {
          const val = d[key];
          return lookup?.[val] || val;
        },
        options: {
          className: "d-flex align-items-center",
          cellContainerClasses: "py-3 align-items-center",
        },
        name: colTitle,
      },
      formatter: (v) => v,
      hideFilter: true,
      ...options,
    },
  };
};
