import React, { useState, useMemo, useRef, useEffect } from "react";

import useColumnSelector from "hooks/useColumnSelector";

import ReactTable from "react-table-6";
import { useSelector } from "react-redux";
import ReactTablePagination from "components/custom/growth/ProductsCVTablePagination";
import TrComponent from "components/custom/merchandise/TrComponent";
import buildMetricColumn from "../columns/buildMetricColumn";
import Loading from "components/core/blocks/Loading";
import buildEditColumn from "../columns/buildEditColumn";
import { snakeCaseToSentenceCase } from "utils/formatText";
import BulkActionButton from "components/custom/merchandise/cells/BulkActionButton";
import ExpanderCell from "components/custom/merchandise/cells/ExpanderCell";
import SelectionCell from "components/custom/merchandise/cells/SelectorCell";
import { trellisPalette } from "components/custom/analytics/palettes";
import { AuthButton } from "components/core/basic/Button";
import { CancelButton } from "components/core/blocks/AdPlan/buttons";
import NotificationSystem from "react-notification-system";
import Tags from "components/core/blocks/Tags";
import FilterWidget from "./FilterWidget";
import "assets/css/dashboard_table.css";
import useTableSettings from "hooks/useTableSettings";

import { extendAllEntityMetricOptions } from "./metricUtils";

const ExpanderCol = (isExpanded, setIsExpanded, selectionKey) => ({
  id: "selector",
  isStatic: true,
  Header: (props) => null,
  sortable: false,
  accessor: "selected",
  width: 50,
  Cell: ({ row, value }) => {
    return (
      <>
        <ExpanderCell
          row={row}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          valueAccessor={selectionKey}
        />
      </>
    );
  },
  Filter: ({ filter, onChange }) => null,
});

const SelectorCol = (selectionKey, selected, setSelected, tableRef) => ({
  id: "selector",
  isStatic: true,
  Header: (props) => {
    return (
      <div className="text-start" style={{ cursor: "pointer" }}>
        <input
          type="checkbox"
          checked={
            selected?.length > 0 &&
            tableRef.current
              ?.getResolvedState()
              ?.sortedData?.map((r) => r._original?.[selectionKey])
              ?.every((id) => selected?.includes(id))
          }
          onChange={(e) => {
            let resolvedTableData =
              tableRef.current?.getResolvedState()?.sortedData;
            let allSelected = resolvedTableData
              ?.map((r) => r._original?.[selectionKey])
              ?.every((id) => selected?.includes(id));

            if (allSelected) {
              let updatedIds = selected?.filter(
                (id) =>
                  !resolvedTableData
                    .map((r) => r._original?.[selectionKey])
                    ?.includes(id)
              );

              setSelected(updatedIds);
            } else {
              let newIds = resolvedTableData
                ?.map((r) => r._original?.[selectionKey])
                ?.filter((id) => !selected?.includes(id));

              setSelected([...selected, ...newIds]);
            }
          }}
          style={{
            marginLeft: "2px",
            accentColor: trellisPalette[2],
          }}
        />
      </div>
    );
  },
  sortable: false,
  accessor: "selected",
  width: 40,
  Cell: ({ row, value }) => (
    <SelectionCell
      row={row}
      value={row?._original?.[selectionKey]}
      showCheckbox={true}
      selected={selected}
      setSelected={setSelected}
      selectionKey={selectionKey}
      hideExpander
    />
  ),
  Filter: ({ filter, onChange }) => null,
});

const BulkActionsTable = ({
  tableRef,
  stats,
  mobileStyle,
  titleCol,
  columnSpecs,
  data,
  getSearchCriteria,
  isLoading,
  selected,
  setSelected,
  selectionKey,
  handleBulkChanges = (changes, cb) => {
    cb();
  },
  bulkActionOptions = [],
  ExpandedContent = "",
  isExpandable = true,
  isSelectable = true,
  children,
  additionalFilters,
  defaultSorted,
  isSaving = false,
  tableId,
  defaultPageSize = 10,
  clearParentFilters,
  hideFilters = false,
  hideSearchFilter = false,
  hidePagination = false,
  hideFilterToggleIcon = false,
  hideResetFiltersIcon = false,
  isStatsLoading,
  minRows = 5,
  tableRowDescription,
  additionalFilterOptions,
  filterControlsWidth,
  excludedFilterColumns,
  filterWidget = false,
  customFilters = [],
  hideCompareValue = false,
  setCustomFilters = () => {},
  formatFilterValue = (v) => {
    return v;
  },
  applyCustomFilters = (arr = []) => {
    return arr;
  },
  initialFilters,
  noDataText = "",
  leftActionButton = null,
  tableEntityType = null,
  groupMetrics = true,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [isExpanded, setIsExpanded] = useState(null);

  const [showFilters, setShowFilters] = useState(false);
  const [colToggle] = useState();

  const [changes, setChanges] = useState({});
  const [renderSave, setRenderSave] = useState(false);

  const notificationRef = useRef();

  const { marketPlace, channel, user } = useSelector((state) => state);

  useEffect(() => {
    setSearchFilter("");
    setShowFilters(false);
  }, [tableId]);

  const {
    savedTableSettings: savedTablePageSize,
    updateTableSettings: setSavedTablePageSize,
  } = useTableSettings(tableId, "tablePageSize", defaultPageSize ?? 5);

  const {
    savedTableSettings: savedTableSortMethod,
    updateTableSettings: setSavedTableSortMethod,
  } = useTableSettings(tableId, "tableSorting", defaultSorted ?? []);

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      let specifiedColumns =
        columnSpecs?.map((c) => {
          if (c.options?.editCell) {
            return buildEditColumn(
              c.options.columnTitle ?? snakeCaseToSentenceCase(c.key),
              marketPlace,
              c.key,
              {
                isStatic: c.options?.isStatic ?? false,
                format: c.options?.format ?? "currency",
                filterType: c.options?.filterType,
                selectOptions: c.options?.selectOptions,
                hideFilter: c.options?.hideFilter ?? true,
                validations: {
                  minValue: c.options?.minValue ?? 1,
                  blank: false,
                },
                toolTip: c.options?.toolTip,
                notificationRef: notificationRef,
                tabIndex: 2,
                overrideKey: c.options?.overrideKey,
                onValue: c.options?.onValue ?? 1,
                offValue: c.options?.offValue ?? 0,
                width:
                  c.options?.format === "toggle"
                    ? c.options?.width ?? 75
                    : "inherit",
                className: `${c.options?.className}` ?? "",
                headerClassName:
                  c.options?.headerClassName ?? "justify-content-end text-end",
                toggleAction: c.options?.toggleAction ?? null,
                strikeThroughText: c.options.strikeThroughText,
                checked: c.options?.checked ?? true,
                renderPlaceholderCellContent:
                  c.options?.renderPlaceholderCellContent ?? null,
              }
            );
          }

          if (c.id === "seperator") {
            return {
              Header: () => null,
              id: "seperator",
              accessor: "seperator",
              width: 10,
              sortable: false,
              filterable: false,
            };
          }

          return buildMetricColumn(
            marketPlace,
            data,
            c.key,
            c.compareKey,
            c.options,
            customFilters,
            hideCompareValue
          );
        }) ?? [];
      if (tableEntityType) {
        specifiedColumns = extendAllEntityMetricOptions(
          tableEntityType,
          data,
          customFilters,
          specifiedColumns,
          marketPlace,
          user
        );
      }

      return specifiedColumns;
    },
    [columnSpecs, stats, mobileStyle, colToggle, customFilters],
    tableId
  );
  const filteredData = useMemo(
    () => {
      return (
        applyCustomFilters(
          data
            ?.map((row) =>
              row?.[selectionKey] === isExpanded ? { ...row, isExpanded } : row
            )
            ?.map((row) => ({ ...row, isStatsLoading }))
            ?.map((p) => {
              p.handleUpdate = (row, key, value) => {
                let convertedChanges = [];
                if (key === "target_roas") {
                  p.target_roas = parseFloat(value);
                  convertedChanges = { target_acos: (1 / value) * 100 };
                }

                if (key === "min_roas") {
                  p.min_roas = parseFloat(value);
                  convertedChanges = { max_acos: (1 / value) * 100 };
                }

                setRenderSave(true);
                setChanges({
                  ...changes,
                  [row._original?.[selectionKey]]: {
                    ...(changes?.[row._original?.[selectionKey]] ?? {}),
                    [key]: value,
                    updated: true,
                    ...convertedChanges,
                  },
                });
              };

              return { ...p, ...(changes?.[p?.[selectionKey]] ?? {}) };
            })
            ?.filter((row) => {
              if (searchFilter) {
                const searchable = getSearchCriteria(row);

                if (searchFilter.indexOf(",") > -1) {
                  const matchable = searchFilter.split(",");
                  return matchable.some((searchCriteria) => {
                    return (
                      searchable
                        ?.replace(/\s/g, "")
                        ?.toLowerCase()
                        ?.indexOf(
                          searchCriteria?.replace(/\s/g, "")?.toLowerCase()
                        ) > -1 && searchCriteria?.trim()?.length
                    );
                  });
                }

                return (
                  searchable
                    ?.toLowerCase()
                    ?.indexOf(searchFilter.toLowerCase()) > -1
                );
              }
              return true;
            }),
          columns
        ) ?? []
      );
    },
    // Below linting disabled to take clearFilters in to recalcalculate data on filter reset
    // Without it, filter specs from react-table must be stored at this level and manually cleared on each reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      changes,
      stats,
      searchFilter,
      clearFilters,
      isExpanded,
      isStatsLoading,
      customFilters,
    ]
  );

  const columnOrder = columns.map((col) => col.id)?.filter((c) => !c.hidden);

  if (isLoading) {
    return <Loading height={"500px"} fullPage={false} />;
  }

  // Set up default table columns
  let defaultCols = titleCol ? [titleCol] : [];

  if (isExpandable) {
    defaultCols.splice(
      0,
      0,
      ExpanderCol(isExpanded, setIsExpanded, selectionKey)
    );
  }

  if (isSelectable) {
    defaultCols.splice(
      0,
      0,
      SelectorCol(selectionKey, selected, setSelected, tableRef)
    );
  }

  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <div className="d-flex justify-content-center justify-content-md-end pb-4">
        {leftActionButton ? leftActionButton : <></>}

        {renderSave ? (
          <div className="d-flex" style={{ marginRight: "2.5rem" }}>
            <AuthButton
              title={`Save Changes`}
              disabled={isSaving}
              buttonStyle="adplan_button"
              style={{
                fontSize: "10px",
                transform: "none",
                marginRight: "2.5rem",
              }}
              onClick={() => {
                const cb = () => {
                  setChanges({});
                };
                handleBulkChanges(changes, cb);
                setRenderSave(false);
                setSelected([]);
              }}
            />
            <CancelButton
              title={`Cancel Changes`}
              style={{
                fontSize: "10px",
                transform: "none",
              }}
              onClick={() => {
                setRenderSave(false);
                setChanges({});
              }}
              noWrap={true}
            />
          </div>
        ) : (
          <></>
        )}
        <BulkActionButton
          selected={selected}
          options={bulkActionOptions?.filter((o) => !o.hidden)}
          disabled={selected?.length === 0}
          hidden={!isSelectable}
        />
      </div>
      <ReactTable
        ref={tableRef}
        noDataText={noDataText}
        showPaginationBottom={false}
        showPaginationTop={!hidePagination}
        PaginationComponent={ReactTablePagination}
        columns={[
          ...defaultCols,
          ...columns.filter((c) => c.id !== "seperator"),
        ]}
        data={filteredData}
        defaultPageSize={savedTablePageSize || defaultPageSize}
        minRows={minRows ?? 0}
        className={`-highlight ${tableId}`}
        defaultSorted={
          savedTableSortMethod || defaultSorted || [{ id: "sales", desc: true }]
        }
        filterable={showFilters}
        state={columnOrder}
        onSortedChange={(newSorted) => {
          setSavedTableSortMethod(newSorted);
        }}
        onPageSizeChange={(pageSize) => {
          setSavedTablePageSize(pageSize);
        }}
        // Below is needed to eliminate the no data message that covers table headers if no data to render
        NoDataComponent={() => null}
        TrComponent={TrComponent}
        getTrProps={(table, row) => {
          let expandedChildren = (
            <ExpandedContent
              marketPlace={marketPlace}
              channel={channel}
              row={row}
              user={user}
            />
          );
          return { row, expandedChildren };
        }}
        getTheadFilterProps={(props) => {
          if (clearFilters) {
            props.filtered.forEach((filter, index) => {
              filter.value = "all";
            });

            setClearFilters(false);
          }
          return { style: { display: "flex" } };
        }}
        getPaginationProps={(props) => {
          return {
            updateSearch: setSearchFilter,
            hideSearch: hideSearchFilter,
            hideFilterToggleIcon: hideFilterToggleIcon,
            hideResetFiltersIcon: hideResetFiltersIcon,
            clearFilters: () => {
              setSearchFilter("");
              setCustomFilters([]);

              if (typeof clearParentFilters === "function") {
                clearParentFilters();
              }

              setClearFilters(true);
            },
            hideFilters: hideFilters,
            showFilters: showFilters,
            setShowFilters: setShowFilters,
            clearSearchTermFilters: () => setSearchFilter(""),
            searchTerm: searchFilter,
            table: "plan-products",
            tableId: tableId,
            tableEntityType: tableEntityType,
            groupMetrics: groupMetrics,
            carouselLayout: false,
            disableSearchFocus: true,
            filterControlsWidth: filterControlsWidth,
            columnSelectorProps: columnSelectorProps,
            children: filterWidget ? (
              <div className="d-flex justify-content-start w-100 h-100 align-items-center px-2">
                <FilterWidget
                  customFilters={customFilters}
                  setCustomFilters={setCustomFilters}
                  formatFilterValue={formatFilterValue}
                  tableRef={tableRef}
                  additionalFilterOptions={additionalFilterOptions}
                  excludedFilterColumns={excludedFilterColumns}
                  initialFilters={initialFilters}
                  tableId={tableId}
                />
                {children}
              </div>
            ) : (
              children
            ),
            additionalFilters: additionalFilters,
            filterWidget: filterWidget,
            style: filterWidget
              ? { marginBottom: "1rem" }
              : { marginBottom: "2rem" },
            searchTagsInput: (
              <Tags
                filledTags={searchFilter?.split(",")}
                saveFilledTags={(searches) =>
                  setSearchFilter(searches?.join(","))
                }
                name={
                  tableRowDescription
                    ? `Search for ${tableRowDescription}`
                    : "Advanced Search"
                }
                hideTagsField={true}
                hideEditButton={true}
                altButton={
                  <i
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      fontSize: "15px",
                      opacity: ".8",
                      cursor: "pointer",
                    }}
                    className="fa fa-pencil"
                  />
                }
              />
            ),
          };
        }}
      />
    </>
  );
};

export default BulkActionsTable;
