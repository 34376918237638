import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { withStyles } from "@material-ui/core";
import { formatNumber } from "utils/formatNumber";

import "assets/css/dot.css";

const useStyles = (theme) => ({
  shelfTile: {
    color: "#403E3D",
    borderColor: "#EEE",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 10%)",
    padding: "1rem 3rem",
    cursor: "pointer",
  },
  selectedShelfTile: {
    background: "#62407F",
    color: "#FFF",
  },
  keyColor: {
    color: "#73706E",
  },
  selectedKeyColor: {
    color: "#FFF",
  },
  valueColor: {
    color: "#403E3D",
  },
  selectedValueColor: {
    color: "#fff",
  },
});

const ShelfSummaryTile = (props) => {
  const { classes } = props;
  const data = props.data;

  return (
    <div
      className={`card ${props.selected && classes.selectedShelfTile} ${
        classes.shelfTile
      }`}
    >
      <Row>
        <div
          className="header"
          style={{
            paddingBottom: "0px",
          }}
        >
          <h4
            className={`title ${props.selected && classes.selectedShelfTile}`}
            style={{
              marginBottom: "2rem",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            {props.title}

            {props.numOfShelves > 0 && <>: {props.numOfShelves} Shelves</>}
            {props.data && (
              <span
                style={{
                  float: "right",
                  fontSize: "16px",
                  fontWeight: "700",
                  textAlign: "right",
                }}
                className={`${
                  props.selected ? classes.selectedKeyColor : classes.keyColor
                }`}
              >
                {Object.keys(data)[2]} <br />
                <span
                  style={{ fontSize: "16px" }}
                  className={`${
                    props.selected
                      ? classes.selectedValueColor
                      : classes.valueColor
                  }`}
                >
                  {Object.values(data)[2]}
                </span>
              </span>
            )}
          </h4>
          {props.overview ? null : (
            <h4
              className={`title ${
                props.selected ? classes.selectedKeyColor : classes.keyColor
              }`}
              style={{
                fontSize: "16px",
                fontWeight: "700",
                // Below is weird but the only override I could get working
                width: "104%",
              }}
            >
              <Row className="w-100">
                {props.pending ? (
                  <Col
                    md={12}
                    style={{
                      textAlign: "left",
                      float: "left",
                      margin: "0px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        margin: "-2rem 0 2.5rem",
                      }}
                    >
                      {Object.values(data)[0]} Search
                      {Object.values(data)[0] > 1 && "es"}
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        margin: "1rem 0",
                      }}
                    >
                      <i className="fa fa-info" /> This shelf is currently being
                      created.
                    </div>
                  </Col>
                ) : (
                  <Fragment>
                    <Col
                      md={6}
                      style={{
                        textAlign: "left",
                        float: "left",
                        margin: "0px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          margin: "-2rem 0 2.5rem",
                        }}
                        className={`${
                          props.selected
                            ? classes.selectedValueColor
                            : classes.valueColor
                        }`}
                      >
                        {Object.values(data)[0]} Search
                        {Object.values(data)[0] > 1 && "es"}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {Object.keys(data)[1]}{" "}
                        <span
                          style={{ fontSize: "16px" }}
                          className={`${
                            props.selected
                              ? classes.selectedValueColor
                              : classes.valueColor
                          }`}
                        >
                          {typeof Object.values(data)[1] === "number"
                            ? formatNumber(Object.values(data)[1], null, 2)
                            : "N/A"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      style={{
                        textAlign: "right",
                        float: "right",
                        margin: "0px",
                        fontSize: "16px",
                      }}
                    >
                      <div>{Object.keys(data)[3]}</div>
                      <div
                        className={`${
                          props.selected
                            ? classes.selectedValueColor
                            : classes.valueColor
                        }`}
                      >
                        {formatNumber(Object.values(data)[3], null, 2)}%
                      </div>
                    </Col>
                  </Fragment>
                )}
              </Row>
            </h4>
          )}
        </div>
      </Row>
    </div>
  );
};

export default withStyles(useStyles)(ShelfSummaryTile);
