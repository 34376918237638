import React, { useState, useMemo, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Links from "../components/Links";
import "../styles/signup.css";
import SignInMethod from "../components/SignInMethod";
import AboutNewUser from "../components/AboutNewUser";
import APIConnections from "../components/APIConnections";
import Channel from "../components/Channel";
import Profiles from "../components/Profiles";
import Summary from "../components/Summary";
import { useFetch } from "hooks/api";
import { useDispatch, useSelector } from "react-redux";
import SignupLoading from "./SignupLoading";
import auth from "auth";
import MARKETING_IMAGE from "assets/images/onboarding/marketingpanel.png";
import {
  completeAmzProfileSelection,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { setCurrentUser } from "redux/actions/authActions";
import { trackMixpanelEvent } from "hooks/useMixpanelTracking";
import WalmartSellerType from "../components/WalmartSellerType";
import { updateWalmartOnboardingStatus } from "redux/actions/walmartOnboardActions";
import OnboardingFooter from "components/custom/onboarding/OnboardingFooter";
import { setChannel } from "redux/actions/channel";
import api from "utils/api";

const defaultOnboardingData = {
  type: "",
  sales: "",
  channel: "",
  walmartSellerType: "",
};

const MARKETING_IMAGE_ALT = "Animated celebrations of the 4 P's";

const SignupLayout = ({ location, placeholderLayout = false }) => {
  const [onboardingData, setOnboardingData] = useState(defaultOnboardingData);

  const history = useHistory();
  const dispatch = useDispatch();
  const { amz_onboard, user, errors, channel } = useSelector((state) => state);
  const { url } = useRouteMatch();

  const debugMode = false;

  const handleTabClose = () => {
    trackMixpanelEvent("Browser Tab Closed");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useFetch(
    ["onboardingStatus"],
    "/amzonboarding/",
    {},
    {
      onSuccess: (res) => {
        dispatch(updateAmzOnboardingStatus(res.data));
      },
    }
  );

  useFetch(
    ["walmartAdsInfo"],
    `/walmart/auth/onboarding`,
    {},
    {
      // enabled: channel.currentChannel === "walmart",
      default: {},
      onSuccess: (res) => {
        dispatch(updateWalmartOnboardingStatus(res.data));
      },
    }
  );

  useFetch(
    ["userprofileData"],
    "/api/userprofile/",
    {},
    {
      onSuccess: (res) => {
        dispatch(setCurrentUser(res.data));
      },
    }
  );

  const { data: profilesData, isLoading: isProfileDataLoading } = useFetch(
    ["amazonProfiles"],
    `/api/accounts/`,
    {},
    {
      enabled: amz_onboard.amazon_advertising_access === "connected",
      select: (res) => res.data,
      default: {},
    }
  );

  const formattedProfiles = useMemo(() => {
    return [
      ...Object.keys(profilesData?.seller ?? {})?.map((seller) => ({
        label: seller,
        value: seller,
        type: "seller",
      })),
      ...Object.keys(profilesData?.vendor ?? {})?.map((vendor) => ({
        label: vendor,
        value: vendor,
        type: "vendor",
      })),
    ];
  }, [profilesData]);

  // Below is to handle switch from walmart to amazon org before calling Amazon listing discovery task
  const switchToInitialAmazonProfile = async () => {
    const amazonProfiles = await api.get("/api/organizations", {
      org_type: "amazon",
    });

    const initialAmazonProfileId = amazonProfiles?.data?.[0]?.id;

    if (initialAmazonProfileId) {
      await api.put("/api/organizations/", {
        id: initialAmazonProfileId,
      });
    }

    history.push(`${url}/summary`);
  };

  if (
    localStorage.secondaryChannel === "amazon" &&
    amz_onboard.amazon_advertising_access === "connected" &&
    channel.currentChannel !== localStorage.secondaryChannel
  ) {
    dispatch(setChannel(localStorage.secondaryChannel));
    dispatch(completeAmzProfileSelection());
    localStorage.setItem("channel", localStorage.secondaryChannel);
    localStorage.removeItem("secondaryChannel");

    switchToInitialAmazonProfile();
  }

  // Redirect to profiles dialogue here if connected but no primary profile selected
  if (
    !user.current_profile &&
    amz_onboard.amazon_advertising_access === "connected" &&
    !window.location.href.includes("profiles") &&
    formattedProfiles?.length &&
    !isProfileDataLoading
  ) {
    history.push(`${url}/profiles`);
  }

  const showLoadingState =
    placeholderLayout ||
    isProfileDataLoading ||
    (amz_onboard.amazon_advertising_access === "connected" && auth.loading) ||
    (amz_onboard.amazon_advertising_access === "connected" &&
      !formattedProfiles.length &&
      !errors?.length &&
      !user.current_profile);

  return (
    <>
      <Row className="p-0 m-0">
        <Col xs={12} lg={6} className="signup-dialogue-container">
          {showLoadingState ? (
            <SignupLoading />
          ) : (
            <Switch>
              <Route exact path={`${url}/signin`}>
                <SignInMethod
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                  registerFormData={location.state?.signUpFormData ?? {}}
                />
              </Route>
              <Route exact path={`${url}/about/type`}>
                <AboutNewUser
                  dialogue="customer_type"
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              </Route>
              <Route exact path={`${url}/about/sales`}>
                <AboutNewUser
                  dialogue="total_monthly_sales"
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              </Route>
              <Route exact path={`${url}/channel`}>
                <Channel
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              </Route>
              <Route exact path={`${url}/sellertype`}>
                <WalmartSellerType
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              </Route>
              <Route exact path={`${url}/connect`}>
                <APIConnections
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                  location={location}
                />
              </Route>
              <Route exact path={`${url}/profiles`}>
                <Profiles
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                  profiles={formattedProfiles}
                />
              </Route>
              <Route exact path={`${url}/summary`}>
                <Summary
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              </Route>
              {/* Fallback in case urls changed or potential bad state - redirect back to dashboard if logged in, otherwise sign in/sign up page */}
              <Redirect to="?onboard=v2" />
            </Switch>
          )}
        </Col>
        <Col xs={0} lg={1} style={{ position: "relative" }}>
          <OnboardingFooter />
        </Col>
        <Col
          xs={12}
          lg={5}
          className="marketing-image-container p-0 m-0 d-none d-lg-block"
        >
          <img src={MARKETING_IMAGE} alt={MARKETING_IMAGE_ALT} />
        </Col>
      </Row>
      {debugMode && <Links />}
    </>
  );
};

export default SignupLayout;
