import axios from "axios";
import { getAPIKeys, getURLPrefix } from "utils/getUrlPrefix";
import { getAuthTokens, setAuthTokens } from "./tokens";

let URL_PREFIX = getURLPrefix();
let apiKeys = getAPIKeys();
let CLIENT_ID = apiKeys["API_AUTH_CLIENT_ID"];
let CLIENT_SECRET = apiKeys["API_AUTH_SECRET"];

// Is the marker being refreshed?
let isRefreshing = false;
// Retry queue, each item will be a function to be executed
let requests = [];

axios.interceptors.response.use(
  (response) => {
    const code = response.status;
    const url = response.config.url;
    if (code === 401 && !url.includes("/auth")) {
      const config = response.config;
      if (!isRefreshing) {
        isRefreshing = true;
        const { refresh_token = null } = getAuthTokens();
        if (!refresh_token) {
          return response;
        }
        var userInfo = {
          grant_type: "refresh_token",
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          refresh_token,
        };

        return axios
          .post(URL_PREFIX + "/auth/token", userInfo)
          .then((res) => {
            const { access_token, refresh_token } = res.data;
            setAuthTokens(access_token, refresh_token);
            // token has been refreshed to retry requests from all queues
            requests.forEach((cb) => cb(access_token));
            requests = [];
            config.headers["Authorization"] = `Bearer ${access_token}`;
            return axios(config);
          })
          .catch((res) => {
            return response;
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        // token is being refreshed and a promise that resolve has not been executed is returned
        return new Promise((resolve) => {
          // Put resolve in the queue, save it in a function form, and execute it directly after token refreshes
          requests.push((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axios(config));
          });
        });
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default { setAuthTokens, getAuthTokens };
