import React, { Component } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import ToolTips from "utils/toolTips";

import axios from "axios";
import "assets/css/dot.css";
import getURLPrefix from "utils/getUrlPrefix";
import CampaignChart from "components/custom/scorecard/CampaignChart";
import SummaryStatsTile from "components/core/blocks/SummaryStatsTile";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import CustomHref from "components/core/blocks/CustomHref";
import { setErrors, clearErrors } from "redux/actions/errorActions";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";

import "assets/css/scorecard_table.css";

let URL_PREFIX = getURLPrefix();

class Scorecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trakData: [],
      mainLoading: true,
      loading: false,
      noData: false,
      chartLoading: false,
      selectedCampaign: null,
      selectedCampaignData: {},
      selectedCampaignDataExtended: {},
      topKeywordsData: [],
      worstKeywordsData: [],
      marketPlace: this.props.marketPlace.marketPlace,
    };
  }

  componentDidMount() {
    this.fetchTrakData();
  }

  componentDidUpdate() {
    if (this.state.marketPlace !== this.props.marketPlace.marketPlace) {
      this.setState({ marketPlace: this.props.marketPlace.marketPlace }, () => {
        this.fetchTrakData();
      });
    }
  }

  fetchTrakData() {
    axios
      .get(
        URL_PREFIX +
          "/api/trellistrak/?&market_place=" +
          this.props.marketPlace.marketPlace +
          "&type=campaign_list"
      )
      .then((res) => {
        if (res.data.length > 0) {
          this.setState(
            {
              noData: false,
              selectedCampaign: res.data[0]["campaign_name"],
              selectedCampaignData: res.data[0],
              trakData: res.data,
            },
            () => {
              this.fetchTrakDataExtended(res.data[0]["campaign_name"]);
              // this.fetchKeywords(res.data[0]['campaign_name'])
            }
          );
        } else {
          this.setState({
            trakData: [],
            selectedCampaign: "Not Available",
            selectedCampaignData: {},
            selectedCampaignDataExtended: {},
            topKeywordsData: [],
            worstKeywordsData: [],
            mainLoading: false,
            noData: true,
          });
        }
      });
  }

  fetchTrakDataExtended(name) {
    axios
      .get(
        URL_PREFIX +
          "/api/trellistrak/?&market_place=" +
          this.props.marketPlace.marketPlace +
          "&type=campaign_details&campaign_name=" +
          encodeURIComponent(name)
      )
      .then((res) => {
        this.setState({
          selectedCampaignDataExtended: res.data,
          mainLoading: false,
          loading: false,
        });
      });
  }

  onCampaignClick(campaignData, name) {
    this.setState(
      {
        selectedCampaign: name,
        selectedCampaignData: campaignData,
        loading: true,
        chartLoading: true,
      },
      () => {
        this.fetchTrakDataExtended(name);
        // this.fetchKeywords(name)
      }
    );
  }

  selectedCampaign(name) {
    if (name === this.state.selectedCampaign) {
      return true;
    } else {
      return false;
    }
  }

  displayPerformance(status_id, type) {
    if (status_id === 1 && (type === "campaign" || type === "adgroup")) {
      return "green_dot";
    }
    if (status_id === 0 && (type === "campaign" || type === "adgroup")) {
      return "yellow_dot";
    }
    if (status_id === -1 && (type === "campaign" || type === "adgroup")) {
      return "red_dot";
    }
    if (type === "listing") {
      return status_id.toFixed(2);
    }
  }

  displayCampaignTrendBreakdown(status_id, type) {
    if (status_id === 1) {
      if (type === "acos") {
        return (
          <span style={{ marginLeft: "4rem", fontSize: "25px" }}>
            <i
              style={{
                transform: "rotate(45deg)",
                color: "#D2222D",
                fontWeight: "bold",
                marginLeft: "1rem",
                verticalAlign: "middle",
              }}
              className="pe-7s-up-arrow"
            />
          </span>
        );
      } else {
        return (
          <span style={{ marginLeft: "1.5rem", fontSize: "25px" }}>
            <i
              style={{
                transform: "rotate(45deg)",
                color: "#25B50E",
                fontWeight: "bold",
                marginLeft: "1rem",
                verticalAlign: "middle",
              }}
              className="pe-7s-up-arrow"
            />
          </span>
        );
      }
    } else if (status_id === 0) {
      if (type === "acos") {
        return (
          <span style={{ marginLeft: "4rem", fontSize: "25px" }}>
            <i
              style={{
                color: "#FFBF00",
                fontWeight: "bold",
                marginLeft: "1rem",
                verticalAlign: "middle",
              }}
              className="pe-7s-right-arrow"
            />
          </span>
        );
      } else {
        return (
          <span style={{ marginLeft: "1.5rem", fontSize: "25px" }}>
            <i
              style={{
                color: "#FFBF00",
                fontWeight: "bold",
                marginLeft: "1rem",
                verticalAlign: "middle",
              }}
              className="pe-7s-right-arrow"
            />
          </span>
        );
      }
    } else if (status_id === -1) {
      if (type === "acos") {
        return (
          <span style={{ marginLeft: "4rem", fontSize: "25px" }}>
            <i
              style={{
                transform: "rotate(-45deg)",
                color: "#25B50E",
                fontWeight: "bold",
                marginLeft: "1rem",
                verticalAlign: "middle",
              }}
              className="pe-7s-bottom-arrow"
            />
          </span>
        );
      } else {
        return (
          <span style={{ marginLeft: "1.5rem", fontSize: "25px" }}>
            <i
              style={{
                transform: "rotate(-45deg)",
                color: "#D2222D",
                fontWeight: "bold",
                marginLeft: "1rem",
                verticalAlign: "middle",
              }}
              className="pe-7s-bottom-arrow"
            />
          </span>
        );
      }
    } else {
      return null;
    }
  }

  fetchKeywords(name) {
    var topKeywords;
    var worstKeywords;
    axios
      .get(
        URL_PREFIX +
          "/api/trellistrak/?type=keywords_piechart&campaign_name=" +
          encodeURIComponent(name)
      )
      .then((res) => {
        topKeywords = res.data["top performance"];
        worstKeywords = res.data["worst performance"].slice(0, 25);
        this.setState({
          topKeywordsData: topKeywords,
          worstKeywordsData: worstKeywords,
        });
      });
  }

  render() {
    return (
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        {this.state.mainLoading ? (
          <div className="wrap" style={{ height: "100vh" }}>
            <img
              src={logo}
              alt="Loading..."
              className="rotate"
              width="80"
              height="auto"
            />
          </div>
        ) : this.state.noData ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "20%",
              paddingTop: "1rem",
              paddingBottom: "5rem",
            }}
          >
            <div
              style={{ fontSize: "26px", color: "#403E3D", fontWeight: "700" }}
            >
              Trellis Trak
            </div>
            <br />
            <div
              style={{ fontSize: "22px", color: "#403E3D", fontWeight: "400" }}
            >
              Scorecard has No Data Available for the Past 60 Days
            </div>
          </div>
        ) : (
          <>
            <Row style={{ marginTop: "-5rem" }}>
              <Col md={12}>
                <CampaignChart
                  campaign_name={this.state.selectedCampaign}
                  loading={this.state.chartLoading}
                />
              </Col>
            </Row>

            <div
              className="card"
              style={{
                height: "1000px",
                marginTop: "1rem",
                marginBottom: "-1rem",
              }}
            >
              <Row>
                <Col
                  md={4}
                  style={{ height: "1000px", borderRight: "1px solid #eee" }}
                >
                  <div>
                    <h4
                      className="title"
                      style={{
                        padding: "2rem 4rem",
                        fontSize: "20px",
                        color: "#403E3D",
                        fontWeight: "700",
                      }}
                    >
                      Campaigns Ranked by Performance Trend
                      <hr
                        style={{ marginLeft: "-2rem", marginRight: "-3.5rem" }}
                      />
                    </h4>
                  </div>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: "900px",
                      marginTop: "-2rem",
                    }}
                  >
                    {this.state.trakData.map((campaign, index) => {
                      return (
                        <div
                          key={"RankedCampaigns" + index}
                          onClick={(e) =>
                            this.onCampaignClick(
                              campaign,
                              campaign["campaign_name"]
                            )
                          }
                          style={{
                            cursor: "pointer",
                            marginBottom: "-2rem",
                            marginLeft: "1.5rem",
                          }}
                        >
                          <SummaryStatsTile
                            selected={this.selectedCampaign(
                              campaign["campaign_name"]
                            )}
                            trak={true}
                            fontSize={"16px"}
                            cardPadding={"0.5rem 2rem"}
                            status={this.displayPerformance(
                              campaign["performance"],
                              "campaign"
                            )}
                            title={campaign["campaign_name"]}
                            data={{}}
                          />
                          <hr
                            style={{
                              marginTop: "-2.5rem",
                              marginBottom: "2.5rem",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col md={8}>
                  {Object.keys(this.state.selectedCampaignDataExtended).length >
                  0 ? (
                    this.state.loading ? (
                      <div className="wrap" style={{ height: "100vh" }}>
                        <img
                          src={logo}
                          alt="Loading..."
                          className="rotate"
                          width="80"
                          height="auto"
                        />
                      </div>
                    ) : (
                      <div style={{ height: "900px" }}>
                        <div
                          style={{
                            marginLeft: "-1.5rem",
                            marginRight: "1.5rem",
                            borderBottom: "1px solid #eee",
                          }}
                        >
                          <div
                            style={{ marginTop: "1rem", padding: "1rem 3rem" }}
                          >
                            <div
                              style={{ textAlign: "left", fontWeight: "400" }}
                            >
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: "15px",
                                  color: "#403E3D",
                                  fontWeight: "bold",
                                }}
                              >
                                Campaign Performance for{" "}
                                <span style={{ fontWeight: "400" }}>
                                  {" "}
                                  {this.state.selectedCampaign}
                                </span>{" "}
                              </span>
                            </div>
                          </div>
                          <h4
                            className="title"
                            style={{
                              padding: "2rem 2rem",
                              fontSize: "18px",
                              color: "#73706E",
                              fontWeight: "400",
                            }}
                          >
                            <Row style={{ marginLeft: "0.65rem" }}>
                              <Col md={3}>
                                ACOS{" "}
                                {this.displayCampaignTrendBreakdown(
                                  this.state.selectedCampaignData["acos_trend"],
                                  "acos"
                                )}
                              </Col>
                              {this.state.selectedCampaignDataExtended
                                .total_sales > 0 ? (
                                <Col
                                  md={2}
                                  className="text-end"
                                  style={{ color: "#403E3D" }}
                                >
                                  {parseFloat(
                                    (
                                      this.state.selectedCampaignDataExtended
                                        .acos * 100
                                    ).toFixed(0)
                                  ).toLocaleString()}{" "}
                                  %
                                </Col>
                              ) : (
                                <Col
                                  md={2}
                                  className="text-end"
                                  style={{ color: "#403E3D" }}
                                >
                                  -
                                </Col>
                              )}
                              <Col md={3} style={{ marginLeft: "5rem" }}>
                                Impressions
                              </Col>{" "}
                              <Col
                                md={2}
                                className="text-end"
                                style={{ color: "#403E3D" }}
                              >
                                {this.state.selectedCampaignDataExtended.total_impression.toLocaleString()}
                              </Col>
                            </Row>
                            <Row style={{ marginLeft: "0.65rem" }}>
                              <Col md={3}>
                                Ad Sales{" "}
                                {this.displayCampaignTrendBreakdown(
                                  this.state.selectedCampaignData["sales_trend"]
                                )}
                              </Col>
                              <Col
                                md={2}
                                className="text-end"
                                style={{ color: "#403E3D" }}
                              >
                                {MARKETPLACE_CURRENCY_DENOMINATION[
                                  this.props.marketPlace.marketPlace
                                ] +
                                  parseFloat(
                                    this.state.selectedCampaignDataExtended.total_sales.toFixed(
                                      2
                                    )
                                  ).toLocaleString()}
                              </Col>
                              <Col md={3} style={{ marginLeft: "5rem" }}>
                                Clicks
                              </Col>{" "}
                              <Col
                                md={2}
                                className="text-end"
                                style={{ color: "#403E3D" }}
                              >
                                {this.state.selectedCampaignDataExtended.total_clicks.toLocaleString()}
                              </Col>
                            </Row>
                            <Row style={{ marginLeft: "0.65rem" }}>
                              <Col md={3}>Ad Spend</Col>{" "}
                              <Col
                                md={2}
                                className="text-end"
                                style={{ color: "#403E3D" }}
                              >
                                {MARKETPLACE_CURRENCY_DENOMINATION[
                                  this.props.marketPlace.marketPlace
                                ] +
                                  parseFloat(
                                    this.state.selectedCampaignDataExtended.total_cost.toFixed(
                                      2
                                    )
                                  ).toLocaleString()}
                              </Col>
                              <Col md={3} style={{ marginLeft: "5rem" }}>
                                Products
                              </Col>{" "}
                              <Col
                                md={2}
                                className="text-end"
                                style={{ color: "#403E3D" }}
                              >
                                {this.state.selectedCampaignDataExtended.product_num.toLocaleString()}
                              </Col>
                            </Row>
                          </h4>
                        </div>
                        <div id="content">
                          <div id="tt-adgroup-table-wrapper">
                            <Table
                              id="tt-adgroup"
                              responsive
                              style={{ marginTop: "2.5rem" }}
                            >
                              <colgroup>
                                <col span="1" style={{ width: "15%" }} />
                                <col span="1" style={{ width: "10%" }} />
                                <col span="1" style={{ width: "10%" }} />
                                <col span="1" style={{ width: "10%" }} />
                                <col span="1" style={{ width: "10%" }} />
                                {/* <col span="1" style={{ width: "10%" }} /> */}
                              </colgroup>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "left" }}>
                                    Ad Group{" "}
                                    <ToolTips
                                      toolTip={
                                        "Click on an Ad Group to View Keyword Analysis"
                                      }
                                      position="top"
                                      id={"keywordRedirect"}
                                    />{" "}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Performance <br /> Trend
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Keywords with Bid Optimization Opportunity
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    New Keyword Opportunities
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Best Keyword Format
                                  </th>
                                  {/* <th style={{textAlign: "center"}}>Product Content Strength</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.selectedCampaignDataExtended[
                                  "ad_groups"
                                ].map((adgroup, index) => {
                                  return (
                                    <tr key={"extendedCampaignData" + index}>
                                      <td style={{ textAlign: "left" }}>
                                        <div
                                          onClick={(e) =>
                                            this.props.setSelectedAdgroup(
                                              this.state.selectedCampaign,
                                              adgroup.ad_group_name
                                            )
                                          }
                                        >
                                          <span>
                                            <CustomHref
                                              text={adgroup.ad_group_name}
                                              color="#d4587f"
                                            />
                                          </span>
                                        </div>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {this.displayPerformance(
                                          adgroup.performance,
                                          "adgroup"
                                        ) === "green_dot" ? (
                                          <span>
                                            <i
                                              style={{
                                                transform: "rotate(45deg)",
                                                color: "#25B50E",
                                                fontWeight: "bold",
                                                fontSize: "25px",
                                                verticalAlign: "middle",
                                              }}
                                              className="pe-7s-up-arrow"
                                            />
                                          </span>
                                        ) : this.displayPerformance(
                                            adgroup.performance,
                                            "adgroup"
                                          ) === "yellow_dot" ? (
                                          <span>
                                            <i
                                              style={{
                                                color: "#FFBF00",
                                                fontWeight: "bold",
                                                fontSize: "25px",
                                                verticalAlign: "middle",
                                              }}
                                              className="pe-7s-right-arrow"
                                            />
                                          </span>
                                        ) : this.displayPerformance(
                                            adgroup.performance,
                                            "adgroup"
                                          ) === "red_dot" ? (
                                          <span>
                                            <i
                                              style={{
                                                transform: "rotate(-45deg)",
                                                color: "#D2222D",
                                                fontWeight: "bold",
                                                fontSize: "25px",
                                                verticalAlign: "middle",
                                              }}
                                              className="pe-7s-bottom-arrow"
                                            />
                                          </span>
                                        ) : null}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {adgroup.optimization_analysis}
                                      </td>
                                      {adgroup.new_keywords_opportunity !==
                                      null ? (
                                        <td style={{ textAlign: "center" }}>
                                          {adgroup.new_keywords_opportunity}
                                        </td>
                                      ) : (
                                        <td style={{ textAlign: "center" }}>
                                          0
                                        </td>
                                      )}
                                      <td style={{ textAlign: "center" }}>
                                        {adgroup.best_keyword_format}
                                      </td>
                                      {/* <td style={{textAlign: "center"}}>{this.displayPerformance(adgroup.list_strength, "listing")} / 5</td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null}
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    );
  }
}

Scorecard.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
});

const mapDispatchToProps = {
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Scorecard));
