import React from "react";
import CenteredModal from "../basic/CenteredModal";

const HelpModal = ({ show, setShow, helpText, helpVideo }) => {
  let src;
  let title;

  // Allow video prop to be simple URL or contain more details
  if (typeof helpVideo === "object") {
    src = helpVideo.src;
    title = helpVideo.title;
  } else {
    src = helpVideo;
  }

  return (
    <CenteredModal
      show={show}
      closeModal={() => setShow(false)}
      title={"Walmart Click URLs"}
      dialogClassName={"modal-60w"}
      style={{
        marginTop: "2rem",
      }}
    >
      <p className="mt-4 w-75 mx-auto text-center">{helpText}</p>
      <div className="d-flex justify-content-center">
        <iframe width="640" height="480" src={src} title={title} />
      </div>
    </CenteredModal>
  );
};

export default HelpModal;
