import { useFetch } from "hooks/api";
import React, { useMemo } from "react";
import { Row, Col, Table } from "react-bootstrap";
import Loading from "components/core/blocks/Loading";
import {
  formatCurrency,
  formatPercent,
  formatNumber,
} from "utils/formatNumber";
import moment from "moment";

const ImpactSection = ({ pricingProduct, marketPlace }) => {
  const now = moment(moment(new Date()).format("YYYY-MM-DD")).subtract(
    1,
    "days"
  );
  const start = moment(moment(pricingProduct.enroll_date).format("YYYY-MM-DD"));
  const end = now;
  const preEnd = start.clone().subtract(1, "days");
  const preStart = preEnd.clone().subtract(end.diff(start, "days"), "days");

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: "day",
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "product_id",
  };

  const { data, isLoading } = useFetch(
    ["product", marketPlace.marketPlace, "impact"],
    `/api/data_report/product_report?product_id=${pricingProduct.product.id}`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { currentAvgPrice, previousAvgPrice } = useMemo(() => {
    if (!data) {
      return {
        currentAvgPrice: 0,
        previousAvgPrice: 0,
      };
    }

    let totalPricesPrev = 0;
    let totalPrices = 0;
    let priceCount = 0;
    for (let i = 0; i < data.date.length; i++) {
      if (data.date[i].product_price && data.date[i].product_price_prev) {
        totalPrices += data.date[i].product_price;
        totalPricesPrev += data.date[i].product_price_prev;
        priceCount++;
      }
    }

    return {
      currentAvgPrice: totalPrices / priceCount,
      previousAvgPrice: totalPricesPrev / priceCount,
    };
  }, [data]);

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <Row className="p-3">
      <Col xs={12} className="py-5">
        <Table
          borderless
          hover
          size="sm"
          className="fs-4"
          style={{
            minWidth: "900px",
            maxWidth: "1600px",
            margin: "0 auto",
          }}
        >
          <thead>
            <tr>
              <th className="text-start" style={{ width: "150px" }}>
                Period
              </th>
              <th className="text-start">Range</th>
              <th className="text-end">Avg Price</th>
              <th className="text-end">Units</th>
              <th className="text-end">Sales</th>
              <th className="text-end">Profit</th>
              <th className="text-end">Margin</th>
              <th className="text-end">BSR</th>
            </tr>
          </thead>
          {data.products.length > 0 && (
            <tbody>
              {/* Current */}
              <tr>
                <td>
                  <strong>Current</strong>
                </td>
                <td style={{ maxWidth: "150px" }}>
                  {moment(start).format("MMMM Do YYYY")} to{" "}
                  {moment(end).format("MMMM Do YYYY")}
                </td>
                <td className="text-end">
                  {formatCurrency(currentAvgPrice, marketPlace.marketPlace)}
                </td>
                <td className="text-end">{data.products[0].total_units}</td>
                <td className="text-end">
                  {formatCurrency(
                    data.products[0].total_sales,
                    marketPlace.marketPlace,
                    true,
                    false,
                    false
                  )}
                </td>
                <td className="text-end">
                  {formatCurrency(
                    data.products[0].profit,
                    marketPlace.marketPlace,
                    true,
                    false,
                    false
                  )}
                </td>
                <td className="text-end">
                  {formatPercent(data.products[0].margin, 0)}
                </td>
                <td className="text-end">
                  {formatNumber(data.products[0].bsr_rank, {}, 0)}
                </td>
              </tr>

              {/* Previous */}
              <tr style={{ borderBottom: "2px solid #000" }}>
                <td>
                  <strong>Previous</strong>
                </td>
                <td style={{ maxWidth: "150px" }}>
                  {moment(preStart).format("MMMM Do YYYY")} to{" "}
                  {moment(preEnd).format("MMMM Do YYYY")}
                </td>
                <td className="text-end">
                  {formatCurrency(previousAvgPrice, marketPlace.marketPlace)}
                </td>
                <td className="text-end">
                  {data.products[0].total_units_prev}
                </td>
                <td className="text-end">
                  {formatCurrency(
                    data.products[0].total_sales_prev,
                    marketPlace.marketPlace,
                    true,
                    false,
                    false
                  )}
                </td>
                <td className="text-end">
                  {formatCurrency(
                    data.products[0].profit_prev,
                    marketPlace.marketPlace,
                    true,
                    false,
                    false
                  )}
                </td>
                <td className="text-end">
                  {formatPercent(data.products[0].margin_prev, 0)}
                </td>
                <td className="text-end">
                  {formatNumber(data.products[0].bsr_rank_prev, {}, 0)}
                </td>
              </tr>

              {/* Delta */}
              <tr>
                <td>
                  <strong>Lifetime Impact</strong>
                </td>
                <td style={{ maxWidth: "150px" }}>&nbsp;</td>
                <td className="text-end">
                  {formatCurrency(
                    currentAvgPrice - previousAvgPrice,
                    marketPlace.marketPlace,
                    false,
                    true,
                    false
                  )}
                </td>
                <td className="text-end">
                  {formatNumber(data.products[0].total_units_delta, {
                    addPlus: true,
                  })}
                </td>
                <td className="text-end">
                  {formatCurrency(
                    data.products[0].total_sales_delta,
                    marketPlace.marketPlace,
                    true,
                    true,
                    false
                  )}
                </td>
                <td className="text-end">
                  {formatCurrency(
                    data.products[0].profit_delta,
                    marketPlace.marketPlace,
                    true,
                    true,
                    false
                  )}
                </td>
                <td className="text-end">
                  {formatPercent(data.products[0].margin_delta, 0)}
                </td>
                <td className="text-end">
                  {formatNumber(
                    data.products[0].bsr_rank_delta,
                    {
                      addPlus: true,
                    },
                    0
                  )}
                </td>
              </tr>
            </tbody>
          )}

          {data.products.length === 0 && (
            <tbody>
              {/* Current */}
              <tr>
                <td colspan="8" className="py-5 text-center">
                  <strong>
                    Dynamic Pricing Impact will be available tomorrow
                  </strong>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </Col>
    </Row>
  );
};

export default ImpactSection;
