import React, { useState, useMemo, useRef, useEffect } from "react";
import Loading from "components/core/blocks/Loading";
import { useMediaQuery } from "@react-hook/media-query";
import { Col, Row, Badge } from "react-bootstrap";
import NoImage from "assets/images/utility/no_image.png";
import { getURLPrefix } from "utils/getUrlPrefix";
import PricingPlanCard from "./PricingPlanCard";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { getPlanType, RULES_BADGE_TEXT, SEGMENT_TEXT } from "./utils";
import { setPlanValue } from "./components/SetValue";
import { useMutation, useQueryClient } from "react-query";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import TrellisLink from "components/core/basic/TrellisLink";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import { trellisPalette } from "components/custom/analytics/palettes";
import moment from "moment";
import {
  PLAN_SEGMENT_FILTER,
  PLAN_TYPE_FILTER,
  RULES_FILTER,
  PLAN_STRATEGY_FILTER,
} from "./pricingLifecycleFilters";
import { buildCustomTagsFilter } from "views/advertising/productLifecycleFilters";

let URL_PREFIX = getURLPrefix();

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const PricingPlanCell = (props) => {
  const [hasError, setHasError] = useState(false);
  const planStrategy = useMemo(() => {
    return getPlanType(props?.original);
  }, [props]);

  const image = hasError
    ? NoImage
    : `${URL_PREFIX}/api/productimage/?pricingplan=${props?.original?.id}`;

  return (
    <TrellisLink
      to={`/user/_pricing/plan/${props?.original?.id}`}
      style={{
        textDecoration: "none",
        fontWeight: "500",
        fontSize: "1.5rem",
        cursor: "pointer",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          onError={(e) => {
            setHasError(true);
          }}
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            flexShrink: 0,
            width: 50,
            height: 50,
            marginRight: "10px",
            background: `url(${image}) no-repeat center / contain`,
          }}
          draggable="false"
        />
        <Row>
          <Col xs={12} className="text-start">
            {props?.value}
          </Col>
          <Col xs={12} className="text-start">
            <Badge
              className={`badge bg-trellis-lightgrey text-end me-3`}
              style={{
                borderRadius: "5px",
              }}
            >
              <span>{planStrategy}</span>
            </Badge>

            {planStrategy === "Dynamic Price" && (
              <>
                <Badge
                  className={`badge bg-trellis-purple text-end me-3`}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  {props?.original?.enroll_date &&
                    props?.original?.configured_slider_value >= 0 &&
                    props?.original?.configured_slider_value <= 0 && (
                      <span>Balanced</span>
                    )}
                  {props?.original?.enroll_date &&
                    props?.original?.configured_slider_value < 0 && (
                      <span>Volume</span>
                    )}
                  {props?.original?.enroll_date &&
                    props?.original?.configured_slider_value > 0 && (
                      <span>Margin</span>
                    )}
                  {!props?.original?.enroll_date && <span>n/a</span>}
                </Badge>
                <Badge
                  className={`badge bg-trellis-mauve text-end me-3`}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  {SEGMENT_TEXT[props?.original?.bucket]}
                </Badge>

                {props?.original?.tags?.length > 0 && (
                  <Badge
                    className={`badge bg-trellis-mauve text-end me-3`}
                    style={{
                      borderRadius: "5px",
                    }}
                  >
                    <span>{props?.original?.tags}</span>
                  </Badge>
                )}
              </>
            )}

            {planStrategy === "Rules Engine" && (
              <Badge
                className={`badge bg-trellis-fuchsia text-end me-3`}
                style={{
                  borderRadius: "5px",
                }}
              >
                <span>
                  {props?.original?.current_program__template_type === null && (
                    <span>Custom</span>
                  )}
                  {
                    RULES_BADGE_TEXT[
                      props?.original?.current_program__template_type
                    ]
                  }
                </span>
              </Badge>
            )}
          </Col>
        </Row>
      </div>
    </TrellisLink>
  );
};

const Table = ({
  stats,
  marketPlace,
  ExpandedContent,
  user,
  setIsExpanded,
  isLoading,
  start,
  end,
  numRows = 5,
}) => {
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [disablePause, setDisablePause] = useState(false);
  const [disableResume, setDisableResume] = useState(false);
  const TABLE_ID = "pricingPlanTable";
  const tableRef = useRef();

  const queryClient = useQueryClient();

  const togglePlans = async (toggle) => {
    togglePlanStatus.mutate({
      id: selectedPlans.join(","),
      changes: toggle,
    });

    setSelectedPlans([]);
    invalidatePlans();
  };

  const invalidatePlans = () => {
    const timer = setTimeout(() => {
      queryClient.invalidateQueries("plan_report");
      queryClient.invalidateQueries("planData");
    }, 2500);

    return () => clearTimeout(timer);
  };

  const togglePlanStatus = useMutation(async (data) => {
    await setPlanValue(data.id, data.changes);
  });

  useEffect(() => {
    if (selectedPlans.length === 0) {
      setDisablePause(true);
      setDisableResume(true);
    }
    const enabledPlans = [];
    for (let i = 0; i < stats?.length; i++) {
      if (stats[i]?.status === "enabled") {
        if (selectedPlans?.includes(stats[i]?.id)) {
          enabledPlans.push(stats[i]?.id);
        }
      }
    }

    if (enabledPlans?.length === 0) {
      setDisablePause(true);
      setDisableResume(false);
    } else {
      setDisablePause(false);
      setDisableResume(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans, stats]);

  const customTags = useMemo(() => {
    const tags = stats?.map((product) => product.tags)?.flat() ?? [];
    return [...new Set(tags)];
  }, [stats]);

  const ADDITIONAL_FILTERS = [
    PLAN_SEGMENT_FILTER,
    PLAN_TYPE_FILTER,
    RULES_FILTER,
    PLAN_STRATEGY_FILTER,
    buildCustomTagsFilter(customTags),
  ];

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const fmtPrice = (price) => {
    return price
      ? formatCurrency(price, marketPlace, false, false, true)
      : "N/A";
  };

  const fmtDate = (date) => {
    return date ? moment(date).format("MM/DD/YYYY") : "N/A";
  };

  const columns = [
    {
      key: "status",
      compareKey: null,
      options: {
        editCell: true,
        format: "toggle",
        columnTitle: "Active",
        onValue: "enabled",
        offValue: "disabled",
        filterType: "select",
        selectOptions: [
          {
            value: "enabled",
            label: "Active",
          },
          {
            value: "paused",
            label: "Inactive",
          },
        ],
        toolTip: "The current status of a pricing plan",
      },
    },
    {
      key: "product_count",
      compareKey: null,
      options: {
        columnTitle: "Product Count",
        format: "number",
        toolTip: "The number of products in a pricing plan",
      },
    },
    {
      key: "enroll_date",
      compareKey: null,
      color: "red",
      options: {
        columnTitle: "Enroll Date",
        formatter: fmtDate,
        checked: false,
        color: "red",
      },
    },
    {
      key: "best_price",
      compareKey: "price_delta",
      options: {
        columnTitle: "Price",
        formatter: formatCurrency,
        format: "currency",
        toolTip: "The current price of a product",
      },
    },
    {
      key: "sales",
      compareKey: null,
      options: {
        overrideCompare: true,
        overrideCompareAccessor: (d) => {
          return { target_sales: d?.target_sales, sales: d?.total_sales };
        },
        overrideCompareFormatter: (v) => {
          const daysDiff = moment(end).diff(start, "days") + 1;

          const value = v.target_sales * daysDiff;
          let actualValue = 0;
          let diff = 0;
          if (v.sales > 0) {
            actualValue = v.sales;
            diff = actualValue / value;
          }
          let color = "#555";
          if (diff > 0) {
            if (diff >= 1.1) {
              color = trellisPalette[12];
            } else if (diff <= 0.9) {
              color = trellisPalette[17];
            }
          }
          return (
            <span style={{ color: "#555" }}>
              Target:{" "}
              <span style={{ color: color }}>
                {formatCurrency(
                  value,
                  marketPlace.marketPlace,
                  false,
                  false,
                  true
                )}
              </span>
            </span>
          );
        },
      },
    },
    {
      key: "units",
      compareKey: null,
      options: {
        targetLabel: "vs Target",
        overrideCompare: true,
        overrideCompareAccessor: (d) => {
          return { target_units: d?.target_units, units: d?.total_units };
        },
        overrideCompareFormatter: (v) => {
          const daysDiff = moment(end).diff(start, "days") + 1;
          const value = v.target_units * daysDiff;
          let actualValue = 0;
          let diff = 0;
          if (v.units > 0) {
            actualValue = v.units;
            diff = actualValue / value;
          }
          let color = "#555";
          if (diff > 0) {
            if (diff >= 1.1) {
              color = trellisPalette[12];
            } else if (diff <= 0.9) {
              color = trellisPalette[17];
            }
          }

          return (
            <span style={{ color: "#555" }}>
              Target:&nbsp;
              <span style={{ color: color }}>
                {formatNumber(value, null, 0)}
              </span>
            </span>
          );
        },
      },
    },
    {
      key: "margin_wo_adspend",
      compareKey: null,
      options: {
        formatter: formatPercent,
        targetLabel: "vs Target",
        overrideCompare: true,
        overrideCompareAccessor: (d) => {
          return {
            target_margin: d?.target_margin,
            margin: d?.margin_wo_adspend,
          };
        },
        overrideCompareFormatter: (v) => {
          let actualValue = 0;
          let diff = 0;
          let value = v.target_margin;
          if (v.margin > 0) {
            actualValue = v.margin;
            diff = actualValue / value;
          }
          let color = "#555";
          if (diff > 0) {
            if (diff <= 0.9) {
              color = trellisPalette[12];
            } else if (diff > 1) {
              color = trellisPalette[17];
            }
          }
          return (
            <span style={{ color: "#555" }}>
              Target:&nbsp;
              <span style={{ color: color }}>{formatPercent(value)}</span>
            </span>
          );
        },
      },
    },
    {
      key: "profit_wo_adspend",
      compareKey: null,
      options: {
        columnTitle: "Margin $",
        overrideCompare: true,
        overrideCompareAccessor: (d) => {
          return {
            margin: d?.target_margin,
            profit: d?.profit_wo_adspend,
            price: d?.best_price,
            units: d?.total_units,
            sales: d?.total_sales,
          };
        },
        overrideCompareFormatter: (v) => {
          let value = v?.sales * v?.margin;

          if (!value) {
            value = 0;
          }

          let actualValue = 0;
          let diff = 0;
          if (v.profit > 0 && value > 0) {
            actualValue = v.profit;
            diff = actualValue / value;
          }
          let color = "#555";
          if (diff > 0) {
            if (diff >= 1.1) {
              color = trellisPalette[12];
            } else if (diff <= 0.9) {
              color = trellisPalette[17];
            }
          }

          return (
            <span style={{ color: "#555" }}>
              Target:&nbsp;
              <span style={{ color: color }}>
                {formatCurrency(
                  value,
                  marketPlace.marketPlace,
                  false,
                  false,
                  true
                )}
              </span>
            </span>
          );
        },
      },
    },
    {
      key: "page_views",
      compareKey: "page_views_delta",
      options: { checked: false },
    },
    {
      key: "profit_per_page_view",
      compareKey: "profit_per_page_view_delta",
      options: {
        columnTitle: "Profit/Page View",
        formatter: fmtPrice,
        checked: false,
      },
    },
    {
      key: "cost",
      compareKey: "cost_percent",
      options: { checked: false },
    },
  ];

  return (
    <>
      <BulkActionsTable
        handleBulkChanges={(changes, clearChanges) => {
          queryClient.invalidateQueries("plan_report");

          const entries = Object.entries(changes);
          for (let i = 0; i < entries.length; i++) {
            const [id, changes] = entries[i];
            togglePlanStatus.mutate({
              id: id,
              changes: changes.status,
            });
          }
        }}
        tableId={TABLE_ID}
        tableRef={tableRef}
        tableEntityType={"pricingPlan"}
        titleCol={{
          id: "pricingplan",
          accessor: "plan_name",
          width: 500,
          Header: "Pricing Plan",
          isStatic: true,
          Cell: (props) => {
            return <PricingPlanCell {...props} setIsExpanded={setIsExpanded} />;
          },
          filterMethod: () => {
            return;
          },
          Filter: () => null,
        }}
        columnSpecs={columns}
        defaultPageSize={stats?.length < numRows ? stats?.length : numRows}
        minRows={stats?.length < numRows ? stats?.length : numRows}
        data={stats}
        selectionKey="plan_id"
        selected={selectedPlans}
        setSelected={setSelectedPlans}
        isExpandable
        isSelectable
        isLoading={isLoading}
        ExpandedContent={({ row }) => {
          return (
            <ExpandedContent marketPlace={marketPlace} row={row} user={user} />
          );
        }}
        bulkActionOptions={[
          {
            label: `Pause Pricing Plan${selectedPlans?.length > 1 ? "s" : ""}`,
            value: "pause",
            action: () => {
              togglePlans("disabled");
            },
            confirmationMessage: (
              <p>
                Are you sure you want to pause the{" "}
                {selectedPlans?.length > 1 ? selectedPlans?.length : ""}{" "}
                selected pricing plan{selectedPlans?.length > 1 ? "s" : ""}?
              </p>
            ),
            disabled: disablePause,
          },
          {
            label: `Enable Pricing Plan${selectedPlans?.length > 1 ? "s" : ""}`,
            value: "unpause",
            action: () => {
              togglePlans("enabled");
            },
            confirmationMessage: (
              <p>
                Are you sure you want to unpause the{" "}
                {selectedPlans?.length > 1 ? selectedPlans?.length : ""}{" "}
                selected pricing plan{selectedPlans?.length > 1 ? "s" : ""}?
              </p>
            ),
            disabled: disableResume,
          },
        ]}
        getSearchCriteria={(row) => {
          const { plan_name } = row;
          return `${plan_name}`;
        }}
        additionalFilterOptions={ADDITIONAL_FILTERS}
        tableRowDescription={"Pricing Plans"}
        filterWidget={true}
        {...filterProps}
        excludedFilterColumns={["plan_name"]}
        hideFilterToggleIcon={true}
        hideResetFiltersIcon={false}
      />
    </>
  );
};

const PricingPlanTable = ({
  category,
  marketPlace,
  date,
  user,
  planData,
  isLoading,
  isFetching,
  hasVariation,
  start,
  end,
  numRows = 5,
}) => {
  const [isExpanded, setIsExpanded] = useState(null);

  const mobileStyle = useMediaQuery("(max-width:600px)");

  const pricingPlans = planData?.plan_id;

  const ExpandedContent = ({ marketPlace, row, user }) => {
    return (
      <div style={{ backgroundColor: "#efefef" }}>
        {/* This is the content for something that's not there  */}

        <PricingPlanCard
          marketPlace={marketPlace}
          planId={row?.original?.plan_id}
          user={user}
          hasVariation={hasVariation}
        />
      </div>
    );
  };

  const { overviewMetrics } = useMemo(() => {
    const titleCol = [
      col("Pricing Plan", "pricingplan", "plan_name", {
        className: "text-start font-weight-bold",
        headerClassName: "text-start font-weight-bold",
        width: 250,
        mobileWidth: 250,
        showDot: false,
        fixedLeft: true,
        hideFilter: true,
        isStatic: true,
      }),
    ];

    return {
      overviewMetrics: {
        columns: [
          col("", "expander", "isExpanded", {
            width: 60,
            hideFilter: true,
            isStatic: true,
          }),
          ...titleCol,
        ],
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingPlans]);

  const data = useMemo(() => {
    const planData = [];

    for (let i = 0; i < pricingPlans?.length; i++) {
      const plan = pricingPlans[i];
      if (plan?.visible || true) {
        planData.push({ ...plan });
      }
    }

    return planData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingPlans]);

  if (isLoading) {
    return (
      <Row>
        <Col xs={12}>
          <Loading height={"300px"} fullPage={false} />
        </Col>
      </Row>
    );
  }

  if (data?.length === 0) {
    return (
      <Row>
        <Col xs={12}>
          <div className="text-center fs-3 pb-5">
            There are no pricing plans that match your search criteria.
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col xs={12}>
        <Table
          stats={data}
          marketPlace={marketPlace}
          mobileStyle={mobileStyle}
          columnSpecs={overviewMetrics.columns}
          ExpandedContent={ExpandedContent}
          date={date}
          category={category}
          user={user}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          pricingPlans={data}
          isLoading={isLoading}
          numRows={numRows}
          start={start}
          end={end}
        />
      </Col>
      <Col xs={12} className="text-right">
        {/* <DownloadReport
          url_path={category ? "product_report" : "category_report"}
          filters={filters}
          report_dl={category ? "product" : "category"}
        /> */}
      </Col>
    </Row>
  );
};

export default PricingPlanTable;
