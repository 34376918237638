import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { getURLPrefix } from "utils/getUrlPrefix";
import Loading from "components/core/blocks/Loading";
import { LogoutButton } from "components/core/basic/Button.jsx";
import FormGroupTile from "components/core/basic/FormGroupTile";
import useDebounce from "hooks/useDebounce";

let URL_PREFIX = getURLPrefix();

const AdSelection = ({
  asinList,
  ad,
  showAdSelection,
  setShowAdSelection,
  marketplace,
  setIsLoading,
  setMediaPlan,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [filteredAds, setFilteredAds] = useState(null);
  const [filterTerm, setFilterTerm] = useState("");
  const [selection, setSelection] = useState(null);
  const [disabledList, setDisabledList] = useState([]);

  const debounceFilter = useDebounce(filterTerm, 300);

  const fetchAds = async () => {
    const asins = asinList.join(",");
    let url = `${URL_PREFIX}/api/explorer/target_ads?marketplace=${marketplace}&asins=${asins}`;
    const res = await axios.get(url, {});

    const results = res.data.target_ads;
    if (results) {
      const dsList = [];

      results.forEach((item) => {
        if (ad !== null && item.id === ad.id) {
          dsList.push(item.id);
        }
      });

      setDisabledList(dsList);
      setData(results);
      setFilteredAds(results);
      setLoading(false);
    }
  };

  const targetPlan = async () => {
    let selectedAd = null;
    data.forEach((item) => {
      if (item.id === selection) {
        selectedAd = item;
      }
    });

    if (setMediaPlan) {
      setMediaPlan(selectedAd);
      hideModal();
      return;
    }
  };

  useEffect(() => {
    if (showAdSelection) {
      fetchAds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAdSelection]);

  useEffect(() => {
    if (data) {
      const newAds = [];
      data.forEach((item) => {
        if (item.name.toLowerCase().includes(debounceFilter.toLowerCase())) {
          newAds.push(item);
        }
      });
      setFilteredAds(newAds);
    }
  }, [debounceFilter, data]);

  const hideModal = () => {
    setShowAdSelection(false);
  };

  return (
    <Modal show={showAdSelection} onHide={() => hideModal()} size="xl  ">
      <Modal.Header closeButton className="py-0 px-5">
        <div className="fs-3 my-4 text-center">Target Pricing Plan</div>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loading text="Loading Media Plans" />}

        {!loading && (
          <>
            <Row>
              <Col className="py-3 px-3">
                <div className="pb-3">
                  <FormGroupTile
                    type="formcontrol"
                    prefix={<i className="fa fa-search" />}
                    prefixSide={"left"}
                    formControlType={"text"}
                    handleChildFormElement={(key, value) => {
                      setFilterTerm(value);
                    }}
                    onKeyUp={(e) => {}}
                    placeholder={`Filter by Media Plan Name`}
                    defaultValue={filterTerm}
                  />
                </div>

                <div
                  style={{
                    padding: "10px",
                    height: "340px",
                    overflowY: "scroll",
                  }}
                >
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="text-start">Media Plan</th>
                        <th>Add</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredAds?.length === 0 && (
                        <tr>
                          <td colSpan={2} className="text-center">
                            Currently, there are no eligible media plans
                            available for selection that meet the necessary
                            criteria. Only media plans that were create and
                            configured within the Trellis console, or are under
                            Trellis management, can be enabled for inclusion in
                            your dynamic pricing plan.
                          </td>
                        </tr>
                      )}
                      {filteredAds.map((ad, idx) => (
                        <tr key={idx}>
                          <td className="text-start">
                            {ad.name} - ({ad.id})
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              disabled={disabledList.includes(ad.id)}
                              checked={selection === ad.id}
                              onClick={() => setSelection(ad.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="py-4" style={{ width: "100%" }}>
          <Col xs={6} className="mx-auto text-start">
            <LogoutButton
              key="RefreshBtn"
              title={`Close`}
              onClick={() => hideModal()}
            />
          </Col>

          <Col xs={6} className="mx-auto text-end">
            <LogoutButton
              fontSize="12px"
              key="AddBtn"
              title={`Target`}
              onClick={() => targetPlan()}
              disabled={!selection}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AdSelection;
