import { createTheme, responsiveFontSizes } from "@material-ui/core";

let baseTheme = {
  palette: {
    type: "light",
    link: {
      main: "#D91266",
    },
    primary: {
      main: "#583376",
      dark: "#2e0055",
      light: "#967faa",
    },
    secondary: {
      //main: "#e05578",  //taken from branding guidelines
      main: "#c43066",
      dark: "#d91c60",
      // light: "#e9909c",
      light: "#EBA8E6",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 850,
      md: 1025,
      lg: 1660,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 14,
    button: {
      textTransform: "none",
    },
    h3: {
      "@media (max-width: 850px)": {
        fontSize: "2.2rem",
      },
    },
    h4: {
      fontSize: "2.2rem",
      "@media (max-width: 850px)": {
        fontSize: "1.75rem",
      },
    },
    h5: {
      fontSize: "1.95rem",
      "@media (max-width: 850px)": {
        fontSize: "1.6rem",
      },
    },
    p: {
      "@media (max-width: 850px)": {
        fontSize: "2.2rem",
      },
    },
    notificationTitle: {
      fontSize: "2rem",
    },
    notificationIcon: {
      fontSize: "3.5rem",
    },
  },
  overrides: {
    MuiSlider: {
      valueLabel: {
        color: "transparent",
        background: "transparent",
        fontSize: "1rem",
        textAlign: "center",
      },
      markLabel: {
        maxWidth: "50px",
        textAlign: "center",
        whiteSpace: "wrap",
        paddingTop: ".7rem",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 12,
      },
    },
  },
};

const theme = responsiveFontSizes(createTheme(baseTheme));
// A hack for a variant of theme with a larger base font size.
const themeLargerFont = responsiveFontSizes(
  createTheme({
    ...baseTheme,
    typography: {
      fontSize: 18,
    },
  })
);
export { theme as default, themeLargerFont };
