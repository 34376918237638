import { useHistory } from "react-router-dom";
import React from "react";
import CustomHref from "components/core/blocks/CustomHref";
import { snakeCaseToSentenceCase } from "utils/formatText";

const ConnectSecondaryChannelWidget = ({
  secondaryChannel = "walmart",
  linkToSummaryView = "",
  onClick = () => {},
}) => {
  const history = useHistory();

  return (
    <div>
      <p>
        Looking to connect your {snakeCaseToSentenceCase(secondaryChannel)}{" "}
        accounts?{" "}
        <CustomHref
          text={
            <span
              className="fs-3"
              onClick={async (e) => {
                if (onClick && typeof onClick === "function") {
                  await onClick();
                }
                if (linkToSummaryView.includes("secondaryChannel")) {
                  localStorage.setItem("secondaryChannel", secondaryChannel);
                }
                history.push(linkToSummaryView);
              }}
            >
              Click Here
            </span>
          }
        />
      </p>
    </div>
  );
};

export default ConnectSecondaryChannelWidget;
