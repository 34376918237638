import React, { useState, useRef } from "react";
import { Row, Col, Container as Grid } from "react-bootstrap";
import { useFetch } from "hooks/api";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api/index";
import { sendToastNotification } from "utils/sendToastNotification";
import Loading from "components/core/blocks/Loading";
import NotificationSystem from "react-notification-system";
import HarvestingConfiguration from "./HarvestingConfiguration";
import Card from "components/core/basic/Card.jsx";
import ProgramList from "views/programs/ProgramList";
import { selectModules } from "redux/selectors/modules";
import { useSelector } from "react-redux";
import checkModule from "utils/checkModule";
import { ProgramContextProvider } from "components/custom/programs/ProgramContext";

const OrgAdvertisingConfiguration = () => {
  const [harvestingSettings, setHarvestingSettings] = useState({});
  const [errors, setErrors] = useState({});

  const notificationRef = useRef();
  const queryClient = useQueryClient();
  const reduxState = useSelector((state) => state);
  const modules = selectModules(reduxState);

  const { isLoading: isHarvestingConfigDataLoading } = useFetch(
    ["orgHarvestingSettings"],
    "/org_bid_budget_settings",
    {},
    {
      default: [],
      enabled: true,
      refetchOnWindowFocus: false,
      select: (d) => d.data,
      onSuccess: (res) => {
        const {
          harvesting_clicks,
          harvesting_conversions,
          harvesting_days,
          harvesting_view_impressions,
        } = res;

        setHarvestingSettings({
          harvestingClicks: harvesting_clicks,
          harvestingConversions: harvesting_conversions,
          harvestingDays: harvesting_days,
          harvestingViewImpressions: harvesting_view_impressions,
        });
      },
    }
  );

  const saveHarvestingSettings = useMutation(
    async (data) => {
      await api.put(`/org_bid_budget_settings`, data);
    },
    {
      onSuccess: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "success",
          "Successfully Updated Harvesting Settings"
        );

        queryClient.invalidateQueries();
      },
      onError: () => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Harvesting Settings"
        );
      },
    }
  );

  const updateHarvestingSettings = () => {
    const {
      harvestingClicks,
      harvestingConversions,
      harvestingDays,
      harvestingViewImpressions,
    } = harvestingSettings;

    const settingsData = {
      harvesting_clicks: isNaN(Number.parseFloat(harvestingClicks))
        ? null
        : Number.parseFloat(harvestingClicks),
      harvesting_conversions: isNaN(Number.parseFloat(harvestingConversions))
        ? null
        : Number.parseFloat(harvestingConversions),
      harvesting_days: isNaN(Number.parseFloat(harvestingDays))
        ? null
        : Number.parseFloat(harvestingDays),
      harvesting_view_impressions: isNaN(
        Number.parseFloat(harvestingViewImpressions)
      )
        ? null
        : Number.parseFloat(harvestingViewImpressions),
    };

    saveHarvestingSettings.mutate(settingsData);
  };
  const handleChange = (e) => {
    if (
      Object.values({
        ...harvestingSettings,
        [e.target.name]: e.target.value,
      }).some((setting) => !setting)
    ) {
      setErrors({ harvestingRules: "All fields are required." });
    } else {
      setErrors({});
    }

    setHarvestingSettings({
      ...harvestingSettings,
      [e.target.name]: e.target.value,
    });
  };

  if (isHarvestingConfigDataLoading) {
    return <Loading fullPage={true} />;
  }

  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid>
          <Row>
            <Col xs={12} className={`text-center pb-5`}>
              <div
                style={{
                  fontSize: "26px",
                  color: "#403E3D",
                  fontWeight: "700",
                }}
              >
                Advertising - Configuration
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={{ span: 10, offset: 2 }}>
              <Card
                style={{ width: "100%" }}
                content={
                  <HarvestingConfiguration
                    handleChange={handleChange}
                    state={harvestingSettings}
                    onChange={(partialSettings) => {
                      if (
                        Object.values({
                          ...harvestingSettings,
                          ...partialSettings,
                        }).some(
                          (setting) => !setting && isNaN(parseInt(setting))
                        )
                      ) {
                        setErrors({
                          harvestingRules: "All fields are required.",
                        });
                      } else {
                        setErrors({});
                      }
                      setHarvestingSettings({
                        ...harvestingSettings,
                        ...partialSettings,
                      });
                    }}
                    saveHarvestingSettings={updateHarvestingSettings}
                    disableSubmitButton={
                      saveHarvestingSettings.isLoading ||
                      Object.keys(errors)?.length
                    }
                    orgLayout={true}
                  />
                }
              />
            </Col>
          </Row>
          {checkModule(modules, "BUDGET_RULES") ? (
            <ProgramContextProvider engine={"budget"}>
              <Row>
                <ProgramList engine={"budget"} biddingLayout={true} />
              </Row>
            </ProgramContextProvider>
          ) : (
            <></>
          )}
          {checkModule(modules, "BIDDING_RULES") ? (
            <ProgramContextProvider engine={"bidding"}>
              <Row>
                <ProgramList engine={"bidding"} biddingLayout={true} />
              </Row>
            </ProgramContextProvider>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </>
  );
};

export default OrgAdvertisingConfiguration;
