import { useState, useMemo, useEffect } from "react";

const usePagination = (data, pageSize) => {
  const [page, setPage] = useState(1);
  const lastPage = Math.ceil(data?.length / pageSize);

  // Below effect added to pair with search/other filters everytime dataset changes (is filtered at a higher level)
  useEffect(() => {
    skipToPage(1);
  }, [data]);

  const pageData = useMemo(() => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    return data?.slice(start, end);
  }, [page, data, pageSize]);

  const next = () => {
    setPage(page + 1);
  };

  const previous = () => {
    setPage(page - 1);
  };

  const skipToPage = (newPage) => {
    setPage(newPage);
  };

  const skipToFirstPage = () => {
    setPage(1);
  };

  const skipToLastPage = () => {
    setPage(lastPage);
  };

  return {
    page,
    pageData,
    lastPage,
    next,
    previous,
    skipToFirstPage,
    skipToLastPage,
    skipToPage,
  };
};

export default usePagination;
