import { AUTHENTICATION_DATA, USER_LOADING } from "../types";

import isEmpty from "is-empty";

const initial_tokens = {
  access_token: "",
  refresh_token: "",
};

const initialState = {
  isAuthenticated: false,
  tokens: initial_tokens,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_DATA:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        tokens: isEmpty(action.payload) ? initial_tokens : action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
