import React, { useRef } from "react";
import useOutsideClick from "hooks/useOutsideClick";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const getFormatFilterOptions = (format, options = {}) => {
  switch (format) {
    case "toggle":
      return [
        { value: "all", label: "All" },
        { value: "on", label: "Active" },
        { value: "off", label: "Inactive" },
      ];
    case "currency":
    case "number":
    case "percent":
      return [
        { value: ">", label: "greater than" },
        { value: "<", label: "less than" },
        { value: "≥", label: "greater than or equal to" },
        { value: "≤", label: "less than or equal to" },
        { value: "=", label: "equals" },
        { value: "-", label: "range" },
      ];
    case "date":
      return [
        { value: "-", label: "between" },
        { value: ">=", label: "since" },
        { value: "<", label: "before" },
        { value: "=", label: "on" },
      ];
    default:
      return [
        { value: "⊃", label: "contains" },
        { value: "=", label: "is" },
        { value: "≠", label: "is not" },
        { value: "^", label: "starts with" },
        { value: "$", label: "ends with" },
      ];
  }
};

const FilterForm = ({
  showForm,
  setShowForm,
  filter,
  setFilter,
  removeFilter,
  index,
  id,
}) => {
  const filterFormRef = useRef();
  const filterOperatorOptions = getFormatFilterOptions(filter.format, filter);

  const state = useSelector((state) => state);

  const {
    marketPlace: { marketPlace },
  } = state;

  useOutsideClick(filterFormRef, (target) => {
    const targetClasses = Array.from(target.classList);
    // Don't close on multi-select interactions
    const keepOpenOnFormInteraction =
      targetClasses?.some((className) =>
        [
          "filter-badge",
          "select-input__option",
          "select-input__multi-value__remove",
        ].includes(className)
      ) || target.nodeName === "svg";

    if (showForm && !keepOpenOnFormInteraction) {
      setShowForm(null);
    }
  });

  const onChange = (e) => {
    e.preventDefault();
    const updatedFilter = { ...filter, [e.target.name]: e.target.value };
    setFilter(updatedFilter);
  };

  return (
    <form
      style={{
        position: "absolute",
        top: "35px",
        width: ["variants", "date"].includes(id) ? "250px" : "200px",
        zIndex: "9000",
        left: index ? "50%" : filter.format === "date" ? "120%" : "80%",
        WebkitTransform: "translateX(-50%)",
        transform: "translateX(-50%)",
        borderRadius: "5px",
        visibility: showForm ? "visible" : "hidden",
      }}
      className="border bg-white text-muted px-3 pt-2 pb-3 fs-6 table-filter-form"
      ref={filterFormRef}
      onSubmit={(e) => {
        e.preventDefault();
        setShowForm(null);
      }}
    >
      <div
        className="d-flex justify-content-end pb-3"
        style={{
          position: "absolute",
          top: "14px",
          right: "10px",
          cursor: "pointer",
        }}
      >
        <i className="fa fa-lg fa-trash" onClick={() => removeFilter()} />
      </div>

      {!filter.override && (
        <>
          <div className="filter-form py-3 pb-3">
            <label className="w-100 text-start">{filter.label}</label>
          </div>{" "}
          <div className="filter-form pb-2">
            <FormGroupTile
              type="select"
              multi={false}
              closeOnSelect={true}
              selectClasses={"filter-widget-select"}
              handleChildFormElement={(key, value) => {
                const updatedFilter = {
                  ...filter,
                  operator: value.value,
                };
                setFilter(updatedFilter);
              }}
              defaultValue={filterOperatorOptions.find(
                (option) => option.value === filter.operator
              )}
              options={filterOperatorOptions}
            />
          </div>
        </>
      )}
      {filter.override ? (
        <>
          <div className="filter-form">
            <label className="py-3 text-start w-100">{filter.label}</label>
            <FormGroupTile
              type="select"
              multi={true}
              isClearable={!filter?.value?.includes("all")}
              selectClasses={"filter-widget-select"}
              closeOnSelect={false}
              handleChildFormElement={(key, value) => {
                let updateValues = [];
                // If option with 'all' value is selected, remove other filter selections, otherwise if anything aside from 'all' value is selected, remove all option,
                if (
                  value
                    .slice(1)
                    ?.map((v) => v.value)
                    .includes("all")
                ) {
                  updateValues = value
                    .filter((v) => v.value === "all")
                    .map((selectedFilter) => selectedFilter.value);
                } else {
                  updateValues = value
                    .filter((v) => v.value !== "all")
                    .map((selectedFilter) => selectedFilter.value);
                }
                updateValues = updateValues?.filter(
                  (value) => ![null, undefined].includes(value)
                );
                if (updateValues.length === 0) {
                  updateValues = ["all"];
                }
                const updatedFilter = {
                  ...filter,
                  value: updateValues,
                };
                setFilter(updatedFilter);
              }}
              defaultValue={filter?.selectOptions?.filter((o) =>
                filter.value.includes(o.value)
              )}
              options={filter.selectOptions}
            />
          </div>
        </>
      ) : // Range filter markup
      filter.operator === "-" && filter.format !== "date" ? (
        <div className="filter-form py-2 d-flex align-items-center">
          <span
            className={`${
              filter.format === "currency"
                ? "prefix-input-wrapper prefix-" + marketPlace.toLowerCase()
                : filter.format === "percent"
                ? "suffix-input-wrapper suffix-percent"
                : ""
            }`}
            style={{ width: "43%" }}
          >
            <input
              type={
                ["percent", "number", "currency"]?.includes(filter.format)
                  ? "number"
                  : "text"
              }
              className={`fs-6 border  ${
                filter.format === "currency"
                  ? "prefix-input"
                  : filter.format === "percent"
                  ? "suffix-input"
                  : ""
              }`}
              style={{
                textAlign: "left",
                padding: "8px",
                width: "100%",
              }}
              placeholder=""
              name="min"
              defaultValue={filter.min}
              onChange={onChange}
            />
          </span>
          <span style={{ width: "14%", textAlign: "center" }}>to</span>
          <span
            className={`${
              filter.format === "currency"
                ? "prefix-input-wrapper prefix-" + marketPlace.toLowerCase()
                : filter.format === "percent"
                ? "suffix-input-wrapper suffix-percent"
                : ""
            }`}
            style={{
              width: "43%",
            }}
          >
            <input
              type={
                ["percent", "number", "currency"]?.includes(filter.format)
                  ? "number"
                  : "text"
              }
              className={`fs-6 border ${
                filter.format === "currency"
                  ? "prefix-input"
                  : filter.format === "percent"
                  ? "suffix-input"
                  : ""
              }`}
              style={{
                textAlign: "left",
                padding: "8px",
                width: "100%",
              }}
              placeholder=""
              name="max"
              defaultValue={filter.max}
              onChange={onChange}
            />
          </span>
        </div>
      ) : filter.format === "date" ? (
        <div className="d-flex align-items-center">
          <div className={filter.operator === "-" ? "pe-3" : " w-100 pt-3"}>
            {filter.operator === "-" && (
              <div className="filter-form py-3 pb-3">
                <label className="w-100 text-start">Start</label>
              </div>
            )}
            <ReactDatePicker
              style={{ borderRadius: 0 }}
              name={filter.operator === "-" ? "min" : "value"}
              selected={
                filter.operator === "-"
                  ? filter.min
                    ? new Date(filter.min)
                    : null
                  : filter.value
                  ? new Date(filter.value)
                  : null
              }
              onChange={(date, e) => {
                e.preventDefault();
                const updateKey = filter.operator === "-" ? "min" : "value";
                const formattedDate =
                  moment(date).format("MM-DD-YYYY") === "Invalid date"
                    ? null
                    : moment(date).format("MM-DD-YYYY");
                const updatedFilter = {
                  ...filter,
                  [updateKey]: formattedDate,
                };
                setFilter(updatedFilter);
              }}
              autoComplete="off"
              popperPlacement="bottom"
            />
          </div>
          {filter.operator === "-" && (
            <div className={filter.operator === "-" ? "ps-3" : ""}>
              <div className="filter-form py-3 pb-3">
                <label className="w-100 text-start">End</label>
              </div>{" "}
              <ReactDatePicker
                style={{ borderRadius: 0 }}
                selected={filter.max ? new Date(filter.max) : null}
                onChange={(date, e) => {
                  e.preventDefault();
                  const formattedDate =
                    moment(date).format("MM-DD-YYYY") === "Invalid date"
                      ? null
                      : moment(date).format("MM-DD-YYYY");
                  const updatedFilter = {
                    ...filter,
                    max: formattedDate,
                  };
                  setFilter(updatedFilter);
                }}
                autoComplete="off"
                popperPlacement="bottom"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="filter-form py-2 d-flex align-items-center">
          <span
            className={`${
              filter.format === "currency"
                ? "prefix-input-wrapper prefix-" + marketPlace.toLowerCase()
                : filter.format === "percent"
                ? "suffix-input-wrapper suffix-percent"
                : ""
            } w-100`}
          >
            <input
              type={
                ["percent", "number", "currency"]?.includes(filter.format)
                  ? "number"
                  : "text"
              }
              className={`fs-6 border ${
                filter.format === "currency"
                  ? "prefix-input"
                  : filter.format === "percent"
                  ? "suffix-input"
                  : ""
              }`}
              style={{
                textAlign: "left",
                padding: "8px",
                width: "100%",
              }}
              placeholder=""
              name="value"
              defaultValue={filter.value}
              onChange={onChange}
            />
          </span>
        </div>
      )}
    </form>
  );
};

export default FilterForm;
