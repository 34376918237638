import React from "react";

export default () => (
  <div className="container text-center" style={{ margin: "5em" }}>
    <h3 style={{ marginBottom: "2em" }}>An error occurred.</h3>
    <a href="/" className="logout_button">
      Reload
    </a>
  </div>
);
