import React, { useMemo } from "react";
import { PAGE_HEIGHT, PAGE_WIDTH } from "./utils";
import acosOrRoas from "utils/acosOrRoas";
import { Row, Col } from "react-bootstrap";
import Table from "./Table";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import StatTile from "./StatTile";

const NewKeywords = ({
  orgName = "",
  data,
  group,
  roasPreference = false,
  marketPlace,
  channel,
}) => {
  const { newlyHarvestedKeywordData } = data;

  const overviewStatTilesData = useMemo(() => {
    const summaryData = newlyHarvestedKeywordData?.reduce(
      (acc, keyword) => {
        acc.cost += keyword.cost;
        acc.adSales += keyword.ad_sales;
        acc.count += 1;
        return acc;
      },
      { cost: 0, adSales: 0, count: 0 }
    );

    return summaryData;
  }, [newlyHarvestedKeywordData]);

  const targetsTableColumns = useMemo(
    () => [
      {
        accessor: "keyword_text",
        Header: "Keyword",
        customCell: (keyword) => {
          const { keyword_text, match_type } = keyword;

          return (
            <div>
              <span className={"w-100 truncate"}>{keyword_text}</span>
              <span className="ps-0 d-block fw-lighter fs-6">{match_type}</span>
            </div>
          );
        },
      },
      {
        Header: "Ad Sales",
        accessor: "ad_sales",
      },
      {
        Header: "Ad Spend",
        accessor: "ad_spend",
      },
      {
        accessor: "cost_per_click",
        Header: "Cost Per Click",
        customCell: (row) => {
          const { cost_per_click } = row ?? {};

          return (
            <span>
              {formatCurrency(
                cost_per_click,
                marketPlace.marketPlace,
                false,
                false
              )}
            </span>
          );
        },
      },

      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "acos",
          Header: "ACOS",
          options: {
            isNegative: true,
          },
        },
        {
          accessor: "roas",
          Header: "ROAS",
          options: {
            isNegative: true,
          },
        }
      ),
      {
        Header: "Clicks",
        accessor: "clicks",
      },
      {
        Header: "Impressions",
        accessor: "impressions",
      },
      {
        Header: "Conversions",
        accessor: "orders",
      },
    ],
    [roasPreference, marketPlace]
  );

  return (
    <div
      className={`box box-common pt-5`}
      style={{
        maxWidth: PAGE_WIDTH,
        maxHeight: PAGE_HEIGHT,
        overflowY: "hidden",
      }}
    >
      <img src={logo} className="watermark" alt="Trellis logo" />
      <Row>
        <Col xs={12}>
          <Row style={{ paddingTop: "20px" }}>
            <Col xs={3}>
              <StatTile
                label={"New Keywords"}
                metricId={null}
                overrideValue={formatNumber(overviewStatTilesData?.count, {
                  addPlus: true,
                })}
                showPreviousPeriod={false}
                showYoy={false}
                marketPlace={marketPlace.marketPlace}
              />
            </Col>
            <Col xs={3}>
              <StatTile
                label={"Ad Sales From New Keywords"}
                metricId={null}
                overrideValue={formatCurrency(
                  overviewStatTilesData?.adSales,
                  marketPlace.marketPlace,
                  true,
                  true
                )}
                showPreviousPeriod={false}
                showYoy={false}
                marketPlace={marketPlace.marketPlace}
              />
            </Col>
            <Col xs={3}>
              <StatTile
                label={"Ad Spend From New Keywords"}
                metricId={null}
                overrideValue={formatCurrency(
                  overviewStatTilesData?.cost,
                  marketPlace.marketPlace,
                  true,
                  true
                )}
                showPreviousPeriod={false}
                showYoy={false}
                marketPlace={marketPlace.marketPlace}
              />
            </Col>
            <Col xs={3}>
              <StatTile
                label={"ACOS From New Keywords"}
                metricId={null}
                overrideValue={
                  !overviewStatTilesData?.adSales
                    ? null
                    : formatPercent(
                        overviewStatTilesData?.cost /
                          overviewStatTilesData?.adSales ?? 0,
                        2,
                        false
                      )
                }
                showPreviousPeriod={false}
                showYoy={false}
                marketPlace={marketPlace.marketPlace}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <Table
            title={"New keywords *"}
            titleColWidth={30}
            data={
              newlyHarvestedKeywordData
                ?.sort((a, b) => b.ad_sales - a.ad_sales)
                ?.slice(0, 15) ?? []
            }
            columns={targetsTableColumns}
            showPrevPeriodTrendRow={false}
            marketPlace={marketPlace}
          />
        </Col>
      </Row>
      <span className="table-annotation">* Sorted by ad sales descending</span>
    </div>
  );
};

export default NewKeywords;
