import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "./Table";
import { PAGE_HEIGHT, PAGE_WIDTH } from "./utils";
import moment from "moment";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import acosOrRoas from "utils/acosOrRoas";
import { getAdTypeName } from "utils/getAdTypeName";
import { useDates } from "dates/useDates";
import StatTile from "./StatTile";
import { formatCurrency, formatNumber } from "utils/formatNumber";
import {
  AD_CREATION_FAILED_STATUS,
  MODERATION_STATUSES,
  STATUS,
} from "components/core/blocks/AdPlan/constants";

const NewMediaPlans = ({
  data,
  group,
  roasPreference = false,
  marketPlace,
  channel,
}) => {
  const { adPlanData } = data;
  let { start } = useDates();

  const newAdsData = useMemo(() => {
    if (adPlanData) {
      return (
        adPlanData
          ?.filter((ad) => {
            return moment(ad.created_datetime.slice(0, 10)).isSameOrAfter(
              moment(start.format("YYYY-MM-DD"))
            );
          })
          ?.slice(0, 18) ?? []
      );
    }
    return [];
  }, [adPlanData, start]);

  const overviewStatTileData = useMemo(() => {
    return newAdsData?.reduce(
      (acc, ad) => {
        acc.monthlyBudget +=
          ad.monthly_budget &&
          ([STATUS.ACTIVE.value].includes(ad.state) ||
            ad.ad_status === MODERATION_STATUSES.PENDING.value) &&
          ad.ad_status !== AD_CREATION_FAILED_STATUS
            ? ad.monthly_budget
            : 0;
        acc.cost += ad.cost ? ad.cost : 0;
        acc.adSales += ad.ad_sales ? ad.ad_sales : 0;
        acc.count += 1;
        return acc;
      },
      { monthlyBudget: 0, cost: 0, adSales: 0, count: 0 }
    );
  }, [newAdsData]);

  const adsTableColumns = useMemo(
    () => [
      {
        accessor: "ad_name",
        Header: "Advertisement",
        customCell: (advertisement) => {
          const { ad_name, ad_type } = advertisement;

          return (
            <div>
              <span className={"w-100 truncate pb-1"}>{ad_name}</span>
              <span className="ps-0 d-block fw-lighter fs-6">
                {getAdTypeName(ad_type, channel)}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Ad Sales",
        accessor: "ad_sales",
        compareMetricId: "ad_sales_percent",
        yoyCompareMetricId: "ad_sales_percent_yoy",
      },
      {
        Header: "Ad Spend",
        accessor: "ad_spend",
        compareMetricId: "cost_percent",
        yoyCompareMetricId: "cost_percent_yoy",
      },
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "total_acos",

          compareMetricId: "total_acos_delta",
          yoyCompareMetricId: "total_acos_delta_yoy",
          Header: "Total ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "troas",
          compareMetricId: "total_roas_percent",
          yoyCompareMetricId: "total_roas_percent_yoy",
          Header: "Total ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
      acosOrRoas(
        { acos_or_roas: roasPreference ? "roas" : "acos" },
        {
          accessor: "acos",

          compareMetricId: "acos_delta",
          yoyCompareMetricId: "acos_delta_yoy",
          Header: "ACOS",
          options: {
            isNegative: true,
            checked: false,
          },
        },
        {
          accessor: "roas",
          compareMetricId: "roas_delta",
          yoyCompareMetricId: "roas_delta_yoy",
          Header: "ROAS",
          options: {
            isNegative: true,
            checked: false,
          },
        }
      ),
      {
        Header: "Clicks",
        accessor: "clicks",
        compareMetricId: "clicks_percent",
        yoyCompareMetricId: "clicks_percent_yoy",
      },
      {
        Header: "Impressions",
        accessor: "impressions",
        compareMetricId: "impressions_percent",
        yoyCompareMetricId: "impressions_percent_yoy",
      },
      {
        Header: "Conversions",
        accessor: "orders",
        compareMetricId: "conversion_percent",
        yoyCompareMetricId: "conversion_percent_yoy",
      },
    ],
    [roasPreference, channel]
  );

  return (
    <>
      <div
        className={`box box-common pt-5`}
        style={{
          maxWidth: PAGE_WIDTH,
          maxHeight: PAGE_HEIGHT,
          overflowY: "hidden",
        }}
      >
        <img src={logo} className="watermark" alt="Trellis logo" />
        <Row style={{ paddingTop: "20px" }} className="px-5">
          <Col>
            <StatTile
              label={"New Media Plans"}
              metricId={null}
              overrideValue={formatNumber(overviewStatTileData.count, {
                addPlus: true,
              })}
              showPreviousPeriod={false}
              showYoy={false}
            />
          </Col>
          <Col>
            <StatTile
              label={"Monthly Budget"}
              metricId={null}
              overrideValue={formatCurrency(
                overviewStatTileData.monthlyBudget,
                marketPlace.marketPlace,
                true,
                true
              )}
              showPreviousPeriod={false}
              showYoy={false}
            />
          </Col>
          <Col>
            <StatTile
              label={"Ad Sales"}
              metricId="ad_sales"
              showPreviousPeriod={false}
              showYoy={false}
              overrideValue={formatCurrency(
                overviewStatTileData.adSales,
                marketPlace.marketPlace,
                true,
                true
              )}
              marketPlace={marketPlace.marketPlace}
            />
          </Col>
          <Col>
            <StatTile
              label={"Ad Spend"}
              metricId="ad_spend"
              showPreviousPeriod={false}
              showYoy={false}
              overrideValue={formatCurrency(
                overviewStatTileData.cost,
                marketPlace.marketPlace,
                true,
                true
              )}
              marketPlace={marketPlace.marketPlace}
            />
          </Col>
        </Row>

        <Row className="pt-5">
          <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
            <Table
              title={"New media plans *"}
              titleColWidth={30}
              data={newAdsData ?? []}
              columns={adsTableColumns}
              showPrevPeriodTrendRow={false}
              marketPlace={marketPlace.marketPlace}
              noDataMessage={
                "There were no media plans created during the selected period."
              }
            />
          </Col>
        </Row>
        <span className="table-annotation">
          * Sorted by ad sales descending
        </span>
      </div>
    </>
  );
};

export default NewMediaPlans;
