import React from "react";
import { Row, Col } from "react-bootstrap";
import Loading from "components/core/blocks/Loading";
import PriceDetails from "modules/pricingv2/PriceDetails";
import AdDetails from "modules/pricingv2/AdDetails";

const PriceHistorySection = ({
  pricingProduct,
  isPricingProductDataLoading,
  marketPlace,
  isLoading,
  advertising = false,
}) => {
  const channel = "AMZ";

  if (isLoading || isPricingProductDataLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <Row>
      <Col xs={12}>
        {!advertising && (
          <PriceDetails
            optData={pricingProduct}
            channel={channel}
            marketPlace={marketPlace}
          />
        )}
        {advertising && (
          <AdDetails
            optData={pricingProduct}
            channel={channel}
            marketPlace={marketPlace}
          />
        )}
      </Col>
    </Row>
  );
};

export default PriceHistorySection;
