import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  FormControl,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@material-ui/core";
import {
  Search,
  CallMade,
  ExpandMore,
  PlayArrow,
  Pause,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { useFetch } from "../../../hooks/api";
import { useTable, usePagination } from "react-table";
import { default as MTable } from "@material-ui/core/Table";
import logo from "../../../assets/images/logo/Trellis_Logomark_color.png";
import ProductImage from "../category/listings/ProductImage";
import { connect, useSelector } from "react-redux";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CategoryFilter from "./CategorySelector";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../utils/api";
import { green, orange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import useCategories from "hooks/useCategories";
import { GOALS } from "components/core/blocks/AdPlan/constants";

// This is the default, empty array passed into react-table when errors occur.
// It must exist outside the React event loop with the version of react-table
// we use, as it is checked by _identity_ rather than value, and can cause an
// infinite loop since "[]" creates a new array each time.
const DEFAULT_ARRAY = [];

const normalizeChannel = (channel) => {
  switch (channel.toLowerCase()) {
    case "amazon":
      return "AMZ";
    default:
      return channel;
  }
};

const useChipStyles = makeStyles((theme) => ({
  active: {
    background: green[100],
    color: theme.palette.getContrastText(green[100]),
    "&:hover": {
      background: green[300],
      color: theme.palette.getContrastText(green[300]),
    },
  },
  paused: {
    background: orange[100],
    color: theme.palette.getContrastText(orange[100]),
    "&:hover": {
      background: orange[300],
      color: theme.palette.getContrastText(orange[300]),
    },
  },
  icon: {
    background: "transparent",
    color: "black",
  },
}));

function MoveToDialog(props) {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleMove = useCallback(() => {
    props.actionMutation.mutate({
      name: "moveTo",
      category: selectedCategory,
      selection: props.selection,
      allSelected: props.allSelected,
    });
    props.onClose();
  }, [props, selectedCategory]);

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <div style={{ minWidth: "500px" }}>
        <CategoryFilter
          currentCategory={null}
          onCategorySelected={setSelectedCategory}
          classes={props.classes}
        />
        <Button
          size={"large"}
          color={"primary"}
          disabled={!selectedCategory}
          onClick={handleMove}
        >
          Move
        </Button>
      </div>
    </Dialog>
  );
}

function AddProductDialog(props) {
  const [sku, setSKU] = useState("");
  const [asin, setASIN] = useState("");

  const mutation = useMutation(async (data) => {
    return api.post("/api/productadmin/", data);
  });

  const saveProduct = useCallback(() => {
    mutation.mutate([
      {
        ASIN: asin,
        marketplace: props.marketPlace.marketPlace,
        SKU: sku,
        channel: props.channel.currentChannel,
        // This is a legacy kludge, we should be using category IDs.
        category: props.categoryName || "Uncategorized",
        price: "1.00",
        title: "Manually Added Product",
      },
    ]);
    props.onClose();
  }, [mutation, asin, props, sku]);

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <Card>
        <CardHeader
          title={"Add new product"}
          subheader={`Manually import a product by specifying its ${
            props.channel.currentChannel === "walmart" ? "Item ID" : "ASIN"
          } and SKU.`}
        />
        <CardContent>
          <form noValidate autoComplete={"off"}>
            <TextField
              label={"SKU"}
              variant={"outlined"}
              fullWidth
              autoFocus
              style={{
                marginBottom: 12,
              }}
              onInput={(e) => setSKU(e.target.value)}
            />
            <TextField
              label={
                props.channel.currentChannel === "walmart" ? "Item ID" : "ASIN"
              }
              variant={"outlined"}
              fullWidth
              style={{
                marginBottom: 12,
              }}
              onInput={(e) => setASIN(e.target.value)}
            />
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={saveProduct}
            >
              Save
            </Button>
          </form>
        </CardContent>
      </Card>
    </Dialog>
  );
}

function Table({
  columns,
  data,
  selectedCategory,
  classes,
  isLoading,
  isError,
  isPreviousData,
  isPaged,
  onSelectionChanged,
  onSelectAll,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination
  );

  // Tracks if the user has selected everything that matches the current
  // query, across all pages.
  const [allSelected, setAllSelected] = useState(false);
  // A list of product IDs that have been selected by the user on the current
  // page.
  const [selected, setSelected] = useState([]);

  // Is the entire page selected?
  const pageSelected = useMemo(
    () => selected.length > 0 && selected.length === rows.length && isPaged,
    [selected.length, rows.length, isPaged]
  );

  // Clear selection state when the selected category changes.
  useEffect(() => {
    setSelected([]);
  }, [selectedCategory]);

  useEffect(() => {
    if (onSelectionChanged) {
      onSelectionChanged(selected);
    }
  }, [onSelectionChanged, selected]);

  const handleChecked = useCallback(
    (productId) => {
      if (selected.includes(productId)) {
        setSelected(selected.filter((v) => v !== productId));
      } else {
        setSelected([...selected, productId]);
      }
    },
    [selected]
  );

  const handleToggleAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelected(rows.map((row) => row.values.id));
      } else {
        setSelected([]);
      }
    },
    [rows]
  );

  const handleSelectAll = useCallback(
    (e) => {
      const new_value = !allSelected;
      setAllSelected(new_value);
      if (onSelectAll) onSelectAll(new_value);
      e.preventDefault();
      return true;
    },
    [allSelected, onSelectAll]
  );

  return (
    <>
      {pageSelected || allSelected ? (
        <Alert severity={"info"}>
          {allSelected ? (
            <>
              All products matching this query selected.{" "}
              <Button color={"primary"} onClick={handleSelectAll}>
                Clear selection
              </Button>
              .
            </>
          ) : (
            <>
              All {rows.length} products on this page are selected. Select{" "}
              <Button color={"primary"} onClick={handleSelectAll}>
                all products
              </Button>
              that matched your query instead?
            </>
          )}
        </Alert>
      ) : null}
      <MTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              <TableCell padding={"checkbox"}>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={
                    selected.length > 0 && selected.length === rows.length
                  }
                  onChange={handleToggleAll}
                />
              </TableCell>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {isError || page.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5}>
                <div className={classes.spinnerContainer}>
                  {isLoading || isPreviousData ? (
                    <img
                      src={logo}
                      alt="Loading..."
                      className="rotate"
                      width="28"
                    />
                  ) : (
                    "No results found"
                  )}
                </div>
              </TableCell>
            </TableRow>
          ) : (
            page.map((row, _) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  <TableCell padding={"checkbox"}>
                    <Checkbox
                      onChange={(_) => handleChecked(row.values.id)}
                      checked={selected.includes(row.values.id)}
                    />
                  </TableCell>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </MTable>
    </>
  );
}

/**
 * Shows the contents of a selected category.
 *
 * This component supports two different views, a compact list view and possibly
 * more user-friendly grid view.
 */
const ProductListing = ({ classes, currentCategory, channel, marketPlace }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [matchOn, setMatchOn] = useState("icontains");
  const [searchFields, setSearchFields] = useState({
    sku: true,
    asin: true,
    product_title: true,
    has_an_ad: null,
  });
  // Cursor used for database pagination.
  const [cursor, setCursor] = useState(null);
  const [selection, setSelection] = useState([]);
  const [searchAsins, setSearchAsins] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [isOpenMoveToDialog, setIsOpenMoveToDialog] = useState(false);
  const [isOpenAddProductDialog, setIsOpenAddProductDialog] = useState(false);
  const debounceAsins = useDebounce(searchAsins, 300);
  const topOfListingElement = useRef(null);
  const asinSearchRef = useRef("");

  const chipStyles = useChipStyles();
  const { push } = useHistory();

  // Track walmart vendor accounts for alternate category links since merch dashboard is unsupported without total sales data
  const { user } = useSelector((state) => state);
  const orgIsWalmartVendor =
    channel.currentChannel === "walmart" && user.organization_type === "vendor";

  let { categories } = useCategories(currentCategory, "merchandising");

  // Pack all of our search filters into a consistent hash, meant to
  // be sent as query arguments to calls to /api/search/products.
  const filterArguments = useMemo(() => {
    let args = {
      query: searchQuery,
      category: currentCategory?.id,
      matchOn: matchOn,
      search_fields: Object.keys(searchFields)
        .filter((k) => searchFields[k])
        .join(","),
      channel: normalizeChannel(channel.currentChannel),
      marketplace: marketPlace.marketPlace,
      has_an_ad: searchFields.has_an_ad,
      asins: debounceAsins?.filter((n) => n)?.join(","),
    };

    return args;
  }, [
    searchQuery,
    matchOn,
    channel,
    marketPlace,
    searchFields,
    currentCategory,
    debounceAsins,
  ]);

  const queryClient = useQueryClient();

  const { isLoading, isError, isPreviousData, data } = useFetch(
    ["searchProducts", filterArguments, cursor, debounceAsins],
    "/api/v2/products/",
    {
      ...filterArguments,
      cursor: cursor,
      expand: "advertisements",
    },
    {
      retry: 0,
      keepPreviousData: true,
    }
  );

  const actionMutation = useMutation(
    async (data) => {
      return api.post("/api/v2/products/", data, filterArguments);
    },
    {
      onMutate: async () => {
        if (topOfListingElement.current) {
          topOfListingElement.current.scrollIntoView({
            behaviour: "smooth",
            block: "start",
          });
        }
      },
      onSuccess: async (res) => {
        setCursor(null);
        setSelection([]);
        setAllSelected(false);
        clearAsinsSearch();

        await queryClient.invalidateQueries("searchProducts");
        await queryClient.invalidateQueries("searchCategories");
      },
    }
  );

  // Handle moving to the next page of results.
  const handleNext = useCallback(() => {
    const next = new URL(data.data.next);
    if (!next) return;
    if (topOfListingElement.current) {
      topOfListingElement.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setCursor(next.searchParams.get("cursor"));
  }, [data]);

  // Handle moving to the previous page of results.
  const handlePrevious = useCallback(() => {
    const previous = new URL(data.data.previous);
    if (!previous) return;
    if (topOfListingElement.current) {
      topOfListingElement.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setCursor(previous.searchParams.get("cursor"));
  }, [data]);

  // If the data changed, erase the current cursor used for pagination.
  useEffect(() => {
    setCursor(null);
  }, [currentCategory, channel, marketPlace]);

  const clearAsinsSearch = () => {
    setSearchAsins(null);
    asinSearchRef.current.value = "";
  };

  const columns = useMemo(() => {
    const columns = [
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <ProductImage id={value} divId={value} width="40px" height="40px" />
          );
        },
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "ASIN",
        accessor: "asin",
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {value}
              <div>
                {row.original.advertisements
                  ?.filter(
                    (ad) =>
                      ad.ad_goal !== GOALS.IMPORTED_ADS.value ||
                      (ad.ad_goal === GOALS.IMPORTED_ADS.value &&
                        ad.non_trellis_enrolled_ad_start_date)
                  )
                  ?.map((v) => {
                    let ad_icon = null;
                    let ad_label = "Ad Active";
                    let ad_color = "primary";

                    if ([1, 22, 2].includes(v.ad_status)) {
                      ad_icon = <PlayArrow className={chipStyles.icon} />;
                      ad_color = "active";
                      ad_label = `Active`;
                    } else if (v.ad_status === 3) {
                      ad_icon = <Pause className={chipStyles.icon} />;
                      ad_color = "paused";
                      ad_label = `Paused`;
                    } else if (v.ad_status === 4) {
                      ad_icon = <Pause className={chipStyles.icon} />;
                      ad_color = "paused";
                      ad_label = "Archived";
                    } else {
                      return null;
                    }
                    return (
                      <Chip
                        key={v.id}
                        icon={ad_icon}
                        label={`${v.name} (${ad_label})`}
                        size={"small"}
                        className={`${chipStyles[ad_color]} mx-1`}
                        style={{
                          marginTop: 6,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          push(`/user/advertising/dashboard/ads/${v.id}`);
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          );
        },
      },
    ];

    if (!currentCategory) {
      columns.push({
        Header: "Category",
        accessor: "category",

        Cell: ({ value }) => {
          const productCategoryName = categories?.find(
            (category) => category.id === parseInt(value)
          )?.leaf_category_name;

          return (
            <div className="text-center">
              <Link
                to={
                  orgIsWalmartVendor
                    ? `/user/advertising/dashboard/category/${value}`
                    : `/user/merchandising/category/${value}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {productCategoryName}
              </Link>
            </div>
          );
        },
      });
    }
    return columns;
  }, [chipStyles, push, currentCategory, categories, orgIsWalmartVendor]);

  const categoryName = currentCategory ? currentCategory.name : "All Products";

  if (actionMutation.isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <div className={classes.spinnerContainer}>
          <img src={logo} alt="Loading..." className="rotate" width="128" />
          <div>Applying changes...</div>
        </div>
      </Backdrop>
    );
  }

  return (
    <Box>
      <Card ref={topOfListingElement}>
        <CardHeader
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flexGrow: 1 }}>
                Products - {categoryName}{" "}
                {currentCategory ? (
                  <Link
                    to={`/user/merchandising/category/${currentCategory?.id}`}
                    className="text-decoration-none fs-4 ms-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Category
                  </Link>
                ) : (
                  <></>
                )}
              </div>
              {channel.currentChannel === "amazon" ||
              channel.currentChannel === "walmart" ? (
                <>
                  {/*
                    <Button
                      color={"primary"}
                      onClick={() => setIsOpenAddProductDialog(true)}
                    >
                      Add new product
                    </Button>
                  */}
                  {isOpenAddProductDialog ? (
                    <AddProductDialog
                      isOpen={isOpenAddProductDialog}
                      onClose={() => setIsOpenAddProductDialog(false)}
                      marketPlace={marketPlace}
                      channel={channel}
                      categoryName={currentCategory?.name}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          }
        />
        <Divider />
        <CardContent>
          <TextField
            id={"product-query"}
            label={"Search Products"}
            variant={"outlined"}
            defaultValue={searchQuery}
            placeholder={"Query..."}
            onInput={(e) => setSearchQuery(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Grid container>
            <Accordion className={classes.fullWidth} elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={"filter-contents"}
                id={"filter-header"}
              >
                <Typography>Advanced</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    component={"fieldset"}
                    className={classes.matchOnFields}
                  >
                    <FormLabel component="legend" className={classes.legend}>
                      Match On
                    </FormLabel>
                    <RadioGroup
                      row
                      defaultValue={"icontains"}
                      onChange={(e) => setMatchOn(e.currentTarget.value)}
                    >
                      <FormControlLabel
                        value={"icontains"}
                        control={<Radio />}
                        label={"Contains"}
                      />
                      <FormControlLabel
                        value={"istartswith"}
                        control={<Radio />}
                        label={"Starts With"}
                      />
                      <FormControlLabel
                        value={"iendswith"}
                        control={<Radio />}
                        label={"Ends With"}
                      />
                      <FormControlLabel
                        value={"iregex"}
                        control={<Radio />}
                        label={"Regex"}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup row className={classes.searchFields}>
                    <FormLabel component="legend" className={classes.legend}>
                      Fields
                    </FormLabel>
                    <FormControlLabel
                      control={<Checkbox name="Title" defaultChecked />}
                      label="Title"
                      onChange={() =>
                        setSearchFields({
                          ...searchFields,
                          product_title: !searchFields.product_title,
                        })
                      }
                    />
                    <FormControlLabel
                      control={<Checkbox name="SKU" defaultChecked />}
                      label="SKU"
                      onChange={() =>
                        setSearchFields({
                          ...searchFields,
                          sku: !searchFields.sku,
                        })
                      }
                    />
                    <FormControlLabel
                      control={<Checkbox name="ASIN" defaultChecked />}
                      label={
                        channel.currentChannel === "walmart"
                          ? "Item ID"
                          : "ASIN"
                      }
                      onChange={() =>
                        setSearchFields({
                          ...searchFields,
                          asin: !searchFields.asin,
                        })
                      }
                    />
                  </FormGroup>
                </Grid>
              </AccordionDetails>
              <TextField
                id="filled-textarea"
                placeholder={"Copy and paste ASINs or SKUs"}
                label={
                  channel.currentChannel === "walmart"
                    ? "Search Item IDs"
                    : "Search ASINs"
                }
                multiline
                fullWidth
                rows={4}
                variant={"outlined"}
                onChange={(event) => {
                  let asinArray = event.target.value
                    ?.split(/[,\s\n]+/)
                    .filter((n) => n);
                  if (!asinArray || asinArray.length <= 0) {
                    clearAsinsSearch();
                  } else {
                    setSearchAsins(asinArray);
                  }
                }}
                inputRef={asinSearchRef}
              />
              <Button
                onClick={clearAsinsSearch}
                style={{
                  marginTop: "0.5rem",
                  float: "right",
                }}
                variant="outlined"
              >
                Clear
              </Button>
            </Accordion>
          </Grid>
        </CardContent>
      </Card>
      <Box my={1}>
        <Grid container>
          <Grid item xs={3} className={classes.verticalAlign}>
            <ButtonGroup disableElevation>
              <Button
                variant={"contained"}
                startIcon={<CallMade />}
                disabled={selection.length === 0}
                onClick={(_) => {
                  setIsOpenMoveToDialog(true);
                }}
              >
                Move to
              </Button>
              <MoveToDialog
                isOpen={isOpenMoveToDialog}
                classes={classes}
                onClose={() => setIsOpenMoveToDialog(false)}
                actionMutation={actionMutation}
                allSelected={allSelected}
                selection={selection}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs={9} style={{ textAlign: "right" }}>
            {isPreviousData ? (
              <img src={logo} alt="Loading..." className="rotate" width="28" />
            ) : null}
            <ToggleButtonGroup
              exclusive
              size={"small"}
              value={searchFields.has_an_ad}
              onChange={(e, newValue) =>
                setSearchFields({
                  ...searchFields,
                  has_an_ad: newValue,
                })
              }
            >
              <ToggleButton value={null}>All Products</ToggleButton>
              <ToggleButton value={true}>Has Ad</ToggleButton>
              <ToggleButton value={false}>Has No Ad</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <Table
              columns={columns}
              data={data?.data?.results || DEFAULT_ARRAY}
              selectedCategory={currentCategory}
              classes={classes}
              isLoading={isLoading}
              isError={isError}
              isPreviousData={isPreviousData}
              isPaged={data?.data?.next || data?.data?.previous}
              onSelectionChanged={setSelection}
              onSelectAll={setAllSelected}
            />
            <Box mt={3}>
              {data?.data?.next || data?.data?.previous ? (
                <>
                  <Box component="span" mr={2}>
                    <Button
                      variant={"contained"}
                      onClick={handlePrevious}
                      color={"primary"}
                      disabled={!data?.data?.previous || isPreviousData}
                    >
                      Previous
                    </Button>
                  </Box>
                  <Box component="span" mr={2}>
                    <Button
                      variant={"contained"}
                      onClick={handleNext}
                      color={"primary"}
                      disabled={!data?.data?.next || isPreviousData}
                    >
                      Next
                    </Button>
                  </Box>
                </>
              ) : null}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

ProductListing.propTypes = {
  currentCategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  showAsGrid: PropTypes.bool,
  classes: PropTypes.object,
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
});

export default connect(mapStateToProps)(ProductListing);
