import React, { useState, useMemo, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import useQueryParams from "hooks/useQueryParams";
import { useFetch } from "hooks/api";
import { useMutation, useQueryClient } from "react-query";
import { Col, Container as Grid, Row } from "react-bootstrap";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import { connect } from "react-redux";
import { trellisPalette } from "components/custom/analytics/palettes";
import Accordion from "components/core/basic/Accordion";
import { useLocation, useParams } from "react-router-dom";
import DownloadReport from "modules/perf_reports/DownloadReport";
import DatesProvider from "dates/DatesProvider";
import moment from "moment";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import { buildMetric } from "views/merchandise/metrics";
import PricingPlanTable from "./PricingPlanTable";
import useCategories from "hooks/useCategories";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import marketPlace from "redux/reducers/marketPlace";
import CategoryTable from "./components/CategoryTable";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import EmptyState from "components/custom/merchandise/EmptyState";
import ToolTips from "utils/toolTips";
import Loading from "components/core/blocks/Loading";
import ProductsTable from "components/core/blocks/ProductsTable";
import ProductCard from "modules/pricingv2/ProductCard";
import { SetValue, sendUpdates } from "./components/SetValue";
import PricingPlan from "modules/pricingv2/PricingPlanForm";
import PricingPlanChoice from "./components/PricingPlanChoice";
import PauseProducts from "./components/PauseProducts";
import { useDates } from "dates/useDates";
import Switch from "react-bootstrap-switch";

const TITLE_MAPPING = {
  right_price: "Right Price",
  sales_pressure: "Sales Pressure",
  profit_potential: "Profit Potential",
  out_of_stock: "Out of Stock",
  new_plan: "Profiling",
  no_price: "Slow Mover",
  volume: "Volume Target",
  balanced: "Balanced Target",
  margin: "Margin Target",
  clearance: "Clearance Target",
};

const TOOLTIPS = {
  right_price:
    "Products maintaining a steady price without consecutive increases or decreases are classified as Right Priced.",
  sales_pressure:
    "Products are tagged as overpriced when consecutive price decreases are observed, signaling a need to adjust to meet historical sales figures.",
  profit_potential:
    "When products exhibit back-to-back price increases over a two-week period, they are identified as having a high potential for profit",
  out_of_stock:
    "Products are considered out of stock if they have no inventory.",
  no_price:
    "Products are considered to be Slow Movers if they have not had any price changes and less than 4 sales in the last 14 days.",
  new_plan: "New Plans have been created in the last 14 days.",
  volume: "Products that you have enrolled into a Volume strategy.",
  balanced: "Products that are enrolled in a Balanced strategy.",
  margin: "Products that are enrolled in a Margin strategy.",
};

const getDashboardTo = (category = null) => {
  return category
    ? `/user/_pricing/category/${category}`
    : `/user/_pricing/dashboard`;
};

const currencyFormat = (value) => {
  return formatCurrency(value, marketPlace.marketPlace, false, false, true);
};

const noDecimarCurrencyFormat = (value) => {
  return formatNumber(value, null, 0);
};

const SegmentChart = ({ data, onClick }) => {
  const options = useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      chart: {
        type: "pie",
        style: {
          fontSize: "16px",
        },
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br>{point.percentage:.1f}%",
            distance: 20,
          },
        },
      },
      series: [
        {
          data: [
            {
              name: "Right Price",
              y: data.right_price.current.count,
              color: trellisPalette[0],
              id: "right_price",
            },
            {
              name: "Sales Pressure",
              y: data.sales_pressure.current.count,
              color: trellisPalette[1],
              id: "sales_pressure",
            },
            {
              name: "Profit Potential",
              y: data.profit_potential.current.count,
              color: trellisPalette[2],
              id: "profit_potential",
            },
            {
              name: "Out of Stock",
              y: data.out_of_stock.current.count,
              color: trellisPalette[4],
              id: "out_of_stock",
            },
            {
              name: "Slow Mover",
              y: data.no_price.current.count,
              color: trellisPalette[5],
              id: "no_price",
            },
            {
              name: "Profiling",
              y: data.new_plan.current.count,
              color: trellisPalette[5],
              id: "new_plan",
            },
          ],
        },
      ],
    };
  }, [data]);

  return (
    <span>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div
        style={{
          height: "70px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
        className={`text-center fs-5`}
      >
        &nbsp;
      </div>
    </span>
  );
};

const StrategyChart = ({ data, onClick }) => {
  const options = useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },

      plotOptions: {
        pie: {
          borderRadius: 8,
          allowPointSelect: true,
          cursor: "pointer",
          borderWidth: 2,
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br>{point.percentage:.1f}%",
            distance: 20,
          },
          // point: {
          //   events: {
          //     click: (e) => onClick(e.point.id),
          //   },
          // },
        },
      },
      series: [
        {
          data: [
            {
              name: "Volume",
              y: data.volume.current.count,
              color: trellisPalette[0],
              id: "volume",
            },
            {
              name: "Balanced",
              y: data.balanced.current.count,
              color: trellisPalette[1],
              id: "balanced",
            },
            {
              name: "Margin",
              y: data.margin.current.count,
              color: trellisPalette[2],
              id: "margin",
            },
          ],
        },
      ],
    };
  }, [data]);

  return (
    <span>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div
        style={{
          height: "70px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
        className={`text-center fs-5`}
      >
        &nbsp;
      </div>
    </span>
  );
};

const SegmentCard = ({
  segmentName,
  selectedSegment,
  small,
  statsData,
  isLoading,
  onClick,
  sparklineData,
  sparklineColor,
}) => {
  if (segmentName === "new_plan" && statsData?.current?.count === 0) {
    return null;
  }
  let showStats = true;
  if (isLoading || !statsData) {
    showStats = false;
  }

  const getDiff = (prev, formatter, reverse = false) => {
    // if (segmentName === "new_plan") {
    //   return null;
    // }
    if (!showStats) {
      return "-";
    }

    let lessColor = "rgb(240, 60, 103)";
    let greaterColor = "rgb(15, 195, 152)";

    if (reverse) {
      lessColor = "rgb(15, 195, 152)";
      greaterColor = "rgb(240, 60, 103)";
    }

    if (prev < 0) {
      return (
        <span style={{ color: lessColor }} className="fw-bold">
          {formatter(prev)}
        </span>
      );
    } else if (prev > 0) {
      return (
        <span style={{ color: greaterColor }} className="fw-bold">
          {formatter(prev)}
        </span>
      );
    }

    return (
      <span style={{ color: "rgb(102, 102, 102)" }} className="fw-bold">
        {prev}{" "}
      </span>
    );
  };

  return (
    <div
      className="mr-4 p-4 mb-3"
      style={{
        height: "125px",
        cursor: "pointer",
        boxShadow: "none",
        border: `2px solid ${
          selectedSegment === segmentName
            ? "rgb(15,195,152, 0.50)"
            : "rgb(238, 238, 238)"
        }`,
        borderRadius: "25px",
        backgroundColor:
          selectedSegment === segmentName ? "rgb(15,195,152, 0.15)" : null,
      }}
      onClick={() => onClick(segmentName)}
    >
      <Row>
        <Col xs={12} className="pb-1">
          <span className="fs-4 fw-bold">
            {TITLE_MAPPING[segmentName]}
            <ToolTips
              toolTip={TOOLTIPS[segmentName]}
              position={"top"}
              id={segmentName + "tooltip"}
            />
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="fs-5 text-center">
          # Plans
          <span className="fs-3 fw-bold d-block">
            {formatNumber(statsData?.current?.count)}
          </span>
          {sparklineData && (
            <div style={{ marginLeft: "25%" }}>
              <Sparkline data={sparklineData} color={sparklineColor} />
            </div>
          )}
        </Col>
        {!small && (
          <Col className="fs-5 text-center">
            Units
            <span className="fs-3 fw-bold d-block">
              {showStats
                ? formatNumber(statsData.current.total_units_ordered)
                : formatNumber(0)}
            </span>
            <div>
              <div>
                {getDiff(statsData?.prev?.total_units_ordered, formatNumber)}
              </div>
            </div>
          </Col>
        )}
        {!small && (
          <Col className="fs-5 text-center">
            Sales
            <span className="fs-3 fw-bold d-block">
              {showStats
                ? currencyFormat(statsData.current.total_sales)
                : currencyFormat(0)}
            </span>
            <div>{getDiff(statsData?.prev?.total_sales, currencyFormat)}</div>
          </Col>
        )}
        {!small && (
          <Col className="fs-5 text-center">
            Margin $
            <span className="fs-3 fw-bold d-block">
              {showStats
                ? currencyFormat(statsData.current.profit)
                : currencyFormat(0)}
            </span>
            <div>{getDiff(statsData?.prev?.profit, currencyFormat)}</div>
          </Col>
        )}
        <Col className="fs-5 text-center">
          Page Views
          <span className="fs-3 fw-bold d-block">
            {showStats
              ? formatNumber(
                  statsData.current.page_views,
                  marketPlace.marketPlace
                )
              : formatNumber(0, marketPlace.marketPlace)}
          </span>
          <div>{getDiff(statsData?.prev?.page_views, formatNumber)}</div>
        </Col>
        <Col className="fs-5 text-center">
          PPV
          <span className="fs-3 fw-bold d-block">
            {showStats
              ? currencyFormat(statsData.current.profit_per_page_view)
              : currencyFormat(0)}
          </span>
          <div>
            {getDiff(statsData?.prev?.profit_per_page_view, currencyFormat)}
          </div>
        </Col>
        {!small && (
          <Col className="fs-5 text-center">
            BSR
            <span className="fs-3 fw-bold d-block">
              {showStats
                ? formatNumber(statsData.current.bsr_rank, {}, 0)
                : formatNumber(0)}
            </span>
            <div>
              {getDiff(parseInt(statsData?.prev?.bsr_rank), formatNumber, true)}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

const Sparkline = ({ data, color }) => {
  const options = useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      chart: {
        type: "line",
        height: 30,
        width: 70,
        margin: [0, 0, 0, 0],
        backgroundColor: null,
        borderWidth: 0,
        style: {
          overflow: "visible",
        },
        skipClone: true,
      },
      title: {
        text: "",
      },
      xAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      yAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        headerFormat: "",
        pointFormat: "{point.y}",
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 2,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 1,
              },
            },
          },
          fillOpacity: 0.25,
        },
        column: {
          negativeColor: "#910000",
          borderColor: "silver",
        },
      },
      series: [
        {
          data: data,
          color: color,
        },
      ],
    };
  }, [data, color]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const ProductExpandedContent = ({ marketPlace, row, user }) => {
  return (
    <div style={{ backgroundColor: "#efefef" }} className="text-start">
      <ProductCard
        marketPlace={marketPlace}
        productId={row?.original?.product_id}
        sku={row?.original?.sku}
        user={user}
      />

      {/* This is the content for something that's not there  */}
    </div>
  );
};

const SegmentCards = ({
  selectedSegment,
  segmentData,
  choosePlans,
  planLoading,
  timeSeries,
  segmentLoading,
}) => {
  const [openMore, setOpenMore] = useState(false);

  return (
    <>
      <SegmentCard
        segmentName={"new_plan"}
        selectedSegment={selectedSegment}
        statsData={segmentData?.new_plan}
        isLoading={segmentLoading}
        onClick={choosePlans}
        sparklineData={!planLoading ? timeSeries[5].data : []}
        sparklineColor={!planLoading ? timeSeries[5].color : null}
      />

      <SegmentCard
        segmentName={"right_price"}
        selectedSegment={selectedSegment}
        statsData={segmentData?.right_price}
        isLoading={segmentLoading}
        onClick={choosePlans}
        sparklineData={!planLoading ? timeSeries[0].data : []}
        sparklineColor={!planLoading ? timeSeries[0].color : null}
      />
      <SegmentCard
        segmentName={"sales_pressure"}
        selectedSegment={selectedSegment}
        statsData={segmentData?.sales_pressure}
        isLoading={segmentLoading}
        onClick={choosePlans}
        sparklineData={!planLoading ? timeSeries[1].data : []}
        sparklineColor={!planLoading ? timeSeries[1].color : null}
      />
      <SegmentCard
        segmentName={"profit_potential"}
        selectedSegment={selectedSegment}
        statsData={segmentData?.profit_potential}
        isLoading={segmentLoading}
        onClick={choosePlans}
        sparklineData={!planLoading ? timeSeries[2].data : []}
        sparklineColor={!planLoading ? timeSeries[2].color : null}
      />
      {(openMore || segmentData?.new_plan?.current?.count === 0) && (
        <Row>
          <Col>
            <SegmentCard
              segmentName={"out_of_stock"}
              selectedSegment={selectedSegment}
              small
              statsData={segmentData?.out_of_stock}
              isLoading={segmentLoading}
              onClick={choosePlans}
              sparklineData={!planLoading ? timeSeries[3].data : []}
              sparklineColor={!planLoading ? timeSeries[3].color : null}
            />
          </Col>
          <Col>
            <SegmentCard
              segmentName={"no_price"}
              selectedSegment={selectedSegment}
              small
              statsData={segmentData?.no_price}
              isLoading={segmentLoading}
              onClick={choosePlans}
              sparklineData={!planLoading ? timeSeries[4].data : []}
              sparklineColor={!planLoading ? timeSeries[4].color : null}
            />
          </Col>
        </Row>
      )}
      {!openMore && segmentData?.new_plan?.current?.count > 0 && (
        <Row>
          <Col xs={12} className="text-center fs-5 pt-3">
            <span
              className="fs-normal"
              style={{ color: trellisPalette[0], cursor: "pointer" }}
              onClick={() => setOpenMore(true)}
            >
              More Segments
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

const StrategyCards = ({
  selectedTarget,
  strategyData,
  choosePlans,
  planLoading,
}) => {
  return (
    <>
      <StrategyCard
        strategyName="balanced"
        selectedTarget={selectedTarget}
        planLoading={planLoading}
        statsData={strategyData?.balanced}
        onClick={choosePlans}
      />

      <StrategyCard
        strategyName="volume"
        selectedTarget={selectedTarget}
        planLoading={planLoading}
        statsData={strategyData?.volume}
        onClick={choosePlans}
      />

      <StrategyCard
        strategyName="margin"
        selectedTarget={selectedTarget}
        planLoading={planLoading}
        statsData={strategyData?.margin}
        onClick={choosePlans}
      />

      <StrategyCard
        strategyName="clearance"
        selectedTarget={selectedTarget}
        planLoading={planLoading}
        statsData={strategyData?.clearance}
        onClick={choosePlans}
      />
      {/* <div className="pt-2 ps-3 fs-6">
        <div className="pb-2">
          <button
            className="btn-link"
            style={{ color: "#d71166", backgroundColor: "#fff" }}
            // onClick={() => getPage(nextPage)}
          >
            What are targets?
          </button>
        </div>
        <div className="pb-2">
          <button
            className="btn-link"
            style={{ color: "#d71166", backgroundColor: "#fff" }}
            // onClick={() => getPage(nextPage)}
          >
            How do I update my targets?
          </button>
        </div>
      </div> */}
    </>
  );
};

const StrategyCard = ({ strategyName, selectedTarget, statsData, onClick }) => {
  const Diff = ({ actual, target, formatter }) => {
    return (
      <span>
        target{" "}
        <span style={{ color: "rgb(102, 102, 102)" }} className="fw-bold">
          {formatter(target)}
        </span>
      </span>
    );
  };
  return (
    <div
      className="mr-4 p-4 mb-3"
      style={{
        height: "125px",
        cursor: "pointer",
        boxShadow: "none",
        border: `2px solid ${
          selectedTarget === strategyName
            ? "rgb(15,195,152, 0.50)"
            : "rgb(238, 238, 238)"
        }`,
        borderRadius: "25px",
        backgroundColor:
          selectedTarget === strategyName ? "rgb(15,195,152, 0.15)" : null,
      }}
      onClick={() => onClick(strategyName)}
    >
      <Row>
        <Col xs={12} className="pb-1">
          <span className="fs-4 fw-bold">
            {TITLE_MAPPING[strategyName]}
            <ToolTips
              toolTip={TOOLTIPS[strategyName]}
              position={"top"}
              id={strategyName + "tooltip"}
            />
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="fs-5 text-center">
          # Plans
          <span className="fs-3 fw-bold d-block">
            {formatNumber(statsData?.plan_count)}
          </span>
        </Col>
        <Col className="fs-5 text-center">
          Units
          <span className="fs-3 fw-bold d-block">
            {formatNumber(statsData?.actual_units, null, 0)}
          </span>
          <Diff
            actual={statsData?.actual_units}
            target={statsData?.target_units}
            formatter={noDecimarCurrencyFormat}
          />
        </Col>
        <Col className="fs-5 text-center">
          Sales
          <span className="fs-3 fw-bold d-block">
            {currencyFormat(statsData?.actual_sales)}
          </span>
          <Diff
            actual={statsData?.actual_sales}
            target={statsData?.target_sales}
            formatter={currencyFormat}
          />
        </Col>
        <Col className="fs-5 text-center">
          Margin
          <span className="fs-3 fw-bold d-block">
            {formatPercent(statsData?.actual_margin_percent)}
          </span>
          <Diff
            actual={statsData?.actual_margin_percent}
            target={statsData?.target_margin_percent}
            formatter={formatPercent}
          />
        </Col>
        <Col className="fs-5 text-center">
          Margin $
          <span className="fs-3 fw-bold d-block">
            {currencyFormat(statsData?.actual_profit)}
          </span>
          <Diff
            actual={statsData?.actual_profit}
            target={statsData?.target_profit}
            formatter={currencyFormat}
          />
        </Col>
      </Row>
    </div>
  );
};

const Recommendations = ({
  marketPlace,
  user,
  category,
  modules,
  recommendations,
}) => {
  const stackOptions = useMemo(() => {
    if (!recommendations) {
      return {};
    }

    return {
      chart: {
        type: "column",
      },

      title: {
        text: null,
        align: "left",
      },

      xAxis: {
        categories: ["Revenue", "Demand"],
      },

      yAxis: [
        {
          allowDecimals: false,
          min: 0,
          title: {
            text: null,
          },
        },
      ],
      tooltip: {
        format:
          "<b>{key}</b><br/>{series.name}: {y}<br/>" +
          "Total: {point.stackTotal}",
      },

      plotOptions: {
        column: {
          stacking: "percent",
        },
      },

      series: [
        {
          name: "New",
          data: [
            parseInt(recommendations?.salesDifference.toFixed(0)),
            recommendations?.unitsDifference,
          ],
          stack: "Sales",
          color: trellisPalette[2],
        },
        {
          name: "Previous",
          data: [
            parseInt(recommendations?.previousSales.toFixed(0)),
            recommendations?.previousUnits,
          ],
          stack: "Sales",
          color: trellisPalette[1],
        },
      ],
    };
  }, [recommendations]);

  const options = useMemo(() => {
    if (!recommendations) {
      return {};
    }

    return {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br>{point.percentage:.0f}",
            distance: 20,
          },
        },
      },
      series: [
        {
          data: [
            {
              name: "Price Increase",
              y: recommendations.priceIncreases,
              color: trellisPalette[0],
              id: "price_increase",
            },
            {
              name: "Price Decrease",
              y: recommendations.priceDecreases,
              color: trellisPalette[1],
              id: "price_decrease",
            },
            {
              name: "Same Price",
              y: recommendations.samePrice,
              color: trellisPalette[2],
              id: "same_price",
            },
          ],
        },
      ],
    };
  }, [recommendations]);

  return (
    <Row>
      <Col xs={12}>
        <div
          style={{
            padding: "2rem",
            border: "1px solid rgb(238, 238, 238)",
            borderRadius: "6px",
            backgroundColor: "rgb(248, 248, 248)",
            marginTop: "10px",
          }}
        >
          These are potential revenue and sales volume opportunities identified
          within this category, as forecasted by our Dynamic Pricing algorithm.
          These projections anticipate the outcomes we expect over the next 30
          days, based on the assumption that advertising expenditure and
          seasonal patterns will continue as they currently stand.
        </div>

        <Row className="pb-3 pt-3 text-center">
          <Col xs={6}>&nbsp;</Col>
          <Col xs={3}>
            <div className="fs-3 pt-3">
              <span className="fw-bold">Revenue:</span>{" "}
              {formatCurrency(
                recommendations.salesDifference,
                marketPlace.marketPlace
              )}
            </div>
          </Col>
          <Col xs={3}>
            <div className="fs-3 pt-3">
              <span className="fw-bold">Demand:</span>{" "}
              {formatNumber(recommendations.unitsDifference, { addPlus: true })}
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={6}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Col>
      <Col xs={6}>
        <div>
          <HighchartsReact highcharts={Highcharts} options={stackOptions} />
        </div>
      </Col>
    </Row>
  );
};

const Dashboard = ({ marketPlace, user, modules, category }) => {
  const mobileStyle = useMediaQuery("(max-width:600px)");
  const [selectedSegment, setSelectedSegment] = useQueryParams("segment", null);
  const [selectedTarget, setSelectedTarget] = useQueryParams("target", null);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [invalidateProductsTable, setInvalidateProductsTable] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [disablePlanAction, setDisablePlanAction] = useState(false);
  const [disablePause, setDisablePause] = useState(false);
  const [showSetValue, setShowSetValue] = useState(false);
  const [showPlanChoice, setShowPlanChoice] = useState(false);
  const [showPauseProducts, setShowPauseProducts] = useState(false);
  const [showEnroll, setShowEnroll] = useState(false);
  const [planOptions, setPlanOptions] = useState({});
  const [valueType, setValueType] = useState("min_price");
  const [viewPerformanceMetrics, setViewPerfomanceMetrics] = useState(false);
  const [metricsView, setMetricsView] = useState("segments");
  const [strategyLoaded, setStrategyLoaded] = useState(false);

  let { currentCategoryDetails: categoryDetails, categories: categoryData } =
    useCategories(category, "pricing");

  const queryClient = useQueryClient();

  const { group, start, end, preStart, preEnd } = useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    // filters: "pricing_enrolled",
    empty: true,
    group_by: "category_id",
  };

  if (category) {
    filters["group_by"] = "plan_id";
    filters["category_id"] = category;
    filters["category"] = category;
  }

  if (selectedPlans?.length > 0) filters.plan_id = selectedPlans.join(",");

  let segmentFilter = null;
  let strategyFilter = null;

  if (metricsView === "segments") {
    strategyFilter = selectedSegment;
  } else if (metricsView === "strategy") {
    segmentFilter = selectedSegment;
  }

  const {
    data: segmentData,
    isLoading: segmentLoading,
    isFetching: segmentFetching,
  } = useFetch(
    [
      "segmentData",
      marketPlace.marketPlace,
      category,
      start,
      end,
      selectedSegment,
    ],
    `/pricing/buckets`,
    {
      category: category,
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      mp: marketPlace.marketPlace,
      filter: segmentFilter,
    },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: true,
      onSuccess: () => {},
    }
  );

  const { data: targetData, isLoading: targetLoading } = useFetch(
    ["targetData", marketPlace.marketPlace, category, start, end],
    `/pricing/targets`,
    {
      category: category,
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      mp: marketPlace.marketPlace,
      filter: segmentFilter,
    },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: true,
      onSuccess: () => {},
    }
  );

  const {
    data: strategyMetrics,
    isLoading: strategyLoading,
    isFetching: strategyFetching,
  } = useFetch(
    [
      "strategyMetrics",
      marketPlace.marketPlace,
      category,
      start,
      end,
      selectedSegment,
    ],
    `/pricing/buckets`,
    {
      category: category,
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      mp: marketPlace.marketPlace,
      buckets: "strategy",
      filter: strategyFilter,
    },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled:
        !strategyLoaded ||
        [
          "right_price",
          "sales_pressure",
          "profit_potential",
          "out_of_stock",
          "no_price",
          "new_plan",
        ].includes(selectedSegment),
      onSuccess: () => {
        setStrategyLoaded(true);
      },
    }
  );

  const {
    data: planData,
    isLoading: planLoading,
    isFetching: planFetching,
  } = useFetch(
    ["planData", marketPlace.marketPlace, category, selectedPlans, start, end],
    `/api/data_report/pricingplan_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { data: productData, isLoading: isProductDataLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      category,
    ],
    "/api/data_report/product_report",
    { ...filters, group_by: "product_id", include: "pricing" },
    {
      select: (d) => d.data,
      enabled: !!category,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: () => {
        if (invalidateProductsTable) {
          invalidateProductsTable[0]();
          setInvalidateProductsTable(null);
        }
      },
    }
  );

  const { timeSeries } = useMemo(() => {
    if (!planData) {
      return {
        timeSeries: {},
      };
    }

    const timeSeries = [
      {
        name: "Right Price",
        data: [],
        color: trellisPalette[0],
      },
      {
        name: "Sales Pressure",
        data: [],
        color: trellisPalette[1],
      },
      {
        name: "Profit Potential",
        data: [],
        color: trellisPalette[2],
      },
      {
        name: "Out of Stock",
        data: [],
        color: trellisPalette[4],
      },
      {
        name: "Slow Mover",
        data: [],
        color: trellisPalette[5],
      },
      {
        name: "Profiling",
        data: [],
        color: trellisPalette[5],
      },
    ];

    for (let i = 0; i < planData?.date.length; i++) {
      const date = planData.date[i];
      timeSeries[0]["data"].push([date.name, date.right_price]);
      timeSeries[1]["data"].push([date.name, date.sales_pressure]);
      timeSeries[2]["data"].push([date.name, date.profit_potential]);
      timeSeries[3]["data"].push([date.name, date.out_of_stock]);
      timeSeries[4]["data"].push([date.name, date.no_price]);
      timeSeries[5]["data"].push([date.name, date.new_plan]);
    }

    // sort the data
    for (let i = 0; i < timeSeries.length; i++) {
      timeSeries[i].data.sort((a, b) => {
        return new Date(a[0]) - new Date(b[0]);
      });
    }

    return {
      timeSeries: timeSeries,
    };
  }, [planData]);

  useEffect(() => {
    if (
      (!selectedSegment && !selectedTarget) ||
      segmentData === undefined ||
      segmentLoading ||
      strategyMetrics === null ||
      strategyLoading
    ) {
      if (selectedPlans?.length > 0) {
        setSelectedPlans([]);
      }
      return;
    }

    if (selectedSegment) {
      if (segmentData[selectedSegment]?.current?.plans?.length === 0) {
        if (selectedPlans?.length > 0 && selectedPlans[0] === 0) {
          return;
        }
        setSelectedPlans([0]);
        return;
      }
      setSelectedPlans(segmentData[selectedSegment]?.current?.plans);
    } else if (selectedTarget) {
      setSelectedPlans(targetData[selectedTarget]?.plans);
    }
  }, [
    selectedSegment,
    segmentData,
    segmentLoading,
    selectedPlans,
    metricsView,
    strategyMetrics,
    strategyLoading,
    selectedTarget,
    targetData,
  ]);

  const breadCrumbItems = [
    {
      name: "Pricing Dashboard",
      href: getDashboardTo(),
      dropdownLinkOptions: DASHBOARD_LINKS,
      unsorted: true,
      searchable: false,
      dropdownSubtitle: "Dashboards",
    },
  ];
  if (category) {
    breadCrumbItems.push({
      name: categoryDetails?.leaf_category_name,
      href: categoryDetails.href,
      dropdownLinkOptions: categoryData,
      searchable: true,
      searchPlaceholderText: "Search categories...",
      dropdownSubtitle: "Categories",
    });
  }

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  const col = (header, type, key, options) => ({
    header,
    type,
    key,
    options,
  });
  const currencyFormatDec = (value) => {
    return formatCurrency(value, marketPlace.marketPlace, false, false, false);
  };

  const { overviewMetrics } = useMemo(() => {
    return {
      overviewMetrics: {
        columns: [
          col("Category", "category", "category_name", {
            className: "text-left font-weight-bold",
            width: 350,
            mobileWidth: 250,
            showDot: false,
            fixedLeft: true,
            hideFilter: true,
            isStatic: true,
          }),
          col("Pricing Plans", "number", "pricing_plan_cnt", {
            showDot: false,
            isStatic: false,
            checked: false,
            toolTip: "The number of pricing plans in a category",
          }),
          col("Profiling", "number", "new_plan", {
            showDot: false,
            isStatic: false,
            checked: true,
            toolTip: TOOLTIPS["new_plan"],
          }),
          col("Right Price", "number", "right_price", {
            showDot: false,
            isStatic: false,
            checked: true,
            toolTip: TOOLTIPS["right_price"],
          }),
          col("Profit Potential", "number", "profit_potential", {
            showDot: false,
            isStatic: false,
            checked: true,
            toolTip: TOOLTIPS["profit_potential"],
          }),
          col("Sales Pressure", "number", "sales_pressure", {
            showDot: false,
            isStatic: false,
            checked: true,
            toolTip: TOOLTIPS["sales_pressure"],
          }),
          col("Slow Mover", "number", "no_price", {
            showDot: false,
            isStatic: false,
            checked: true,
            toolTip: TOOLTIPS["no_price"],
          }),
          col("Out of Stock", "number", "out_of_stock", {
            showDot: false,
            isStatic: false,
            checked: true,
            toolTip: TOOLTIPS["out_of_stock"],
          }),
          col("Ad Spend", "currency", "cost", {
            hideCompare: false,
            checked: false,
          }),
          col("Units", "number", "total_units", {
            hideCompare: false,
            checked: false,
          }),
          col("Sales", "currency", "total_sales", {
            hideCompare: false,
            checked: false,
          }),
          col("Margin", "percent", "margin", {
            noColor: true,
            delta: true,
            checked: false,
          }),
          col("PPV", "currency", "profit_per_page_view", {
            checked: true,
            formatter: (p) =>
              currencyFormatDec(p.value, marketPlace.marketPlace),
            accessor: (p) => p.profit_per_page_view,
          }),
          col("Trends", "trends", "trends_score", {
            className: "text-center",
            checked: true,
          }),
        ],

        metrics: [
          buildMetric(marketPlace, planData, "price", "price_delta"),
          buildMetric(marketPlace, planData, "ad_spend", "ad_spend_percent"),
          buildMetric(marketPlace, planData, "units", "units_percent"),
          buildMetric(marketPlace, planData, "sales", "sales_percent"),
          buildMetric(marketPlace, planData, "bsr", "bsr_percent", {
            metric: {
              totalOverrideAccessor: "last_bsr_rank",
            },
          }),
          buildMetric(marketPlace, planData, "margin", "margin_delta"),
          buildMetric(
            marketPlace,
            planData,
            "margin_wo_adspend",
            "margin_wo_adspend_delta"
          ),
          buildMetric(marketPlace, planData, "profit", "profit_delta"),
          buildMetric(
            marketPlace,
            planData,
            "profit_wo_adspend",
            "profit_wo_adspend_delta"
          ),
          buildMetric(
            marketPlace,
            planData,
            "profit_per_page_view_wo_adspend",
            "profit_per_page_view_wo_adspend_percent"
          ),
          buildMetric(
            marketPlace,
            planData,
            "page_views",
            "page_views_percent"
          ),
        ],
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData]);

  const recommendations = useMemo(() => {
    if (!productData) {
      return null;
    }

    let priceIncreases = 0;
    let priceDecreases = 0;
    let samePrice = 0;
    let previousUnits = 0;
    let newUnits = 0;
    let previousSales = 0;
    let newSales = 0;
    let salesDifference = 0;
    let unitsDifference = 0;

    for (const product of productData.products) {
      if (
        product.suggested_price &&
        product.suggested_previous_sales &&
        product.suggested_new_sales
      ) {
        if (product.suggested_price > product.product_price) {
          priceIncreases += 1;
          salesDifference +=
            product.suggested_new_sales - product.suggested_previous_sales;
          unitsDifference +=
            product.cost_details.new_price_units - product.cost_details.units;

          previousUnits += product.cost_details.units;
          newUnits += product.cost_details.new_price_units;
          previousSales += product.suggested_previous_sales;
          newSales += product.suggested_new_sales;
        } else if (product.suggested_price < product.product_price) {
          priceDecreases += 1;
          salesDifference +=
            product.suggested_new_sales - product.suggested_previous_sales;
          unitsDifference +=
            product.cost_details.new_price_units - product.cost_details.units;

          previousUnits += product.cost_details.units;
          newUnits += product.cost_details.new_price_units;
          previousSales += product.suggested_previous_sales;
          newSales += product.suggested_new_sales;
        } else {
          samePrice += 1;
        }
      }
    }

    return {
      priceIncreases,
      priceDecreases,
      samePrice,
      salesDifference,
      unitsDifference,
      previousUnits,
      newUnits,
      previousSales,
      newSales,
    };
  }, [productData]);

  const choosePlans = (segment) => {
    if (segment === selectedSegment) {
      setSelectedSegment(null);
      return;
    }
    setSelectedSegment(segment);
  };

  const chooseTarget = (target) => {
    if (target === selectedTarget) {
      setSelectedTarget(null);
      return;
    }
    setSelectedTarget(target);
  };

  const updateProducts = useMutation(async (updates) => {
    await sendUpdates("product_id", updates, marketPlace, queryClient);
  });

  const onSelection = (selectedProducts) => {
    const enrolledProducts = productData?.products?.filter(
      (row) => selectedProducts.indexOf(row.id) > -1 && row.pp_enroll_date
    );

    const variationProducts = productData?.products?.filter(
      (row) =>
        selectedProducts.indexOf(row.id) > -1 &&
        row.pp_enroll_date &&
        row.variation_plan_id
    );

    if (enrolledProducts?.length === 0 && selectedProducts?.length > 0) {
      setDisablePlanAction(false);
      setDisablePause(true);
    } else {
      setDisablePlanAction(true);
      if (variationProducts?.length === 0) {
        setDisablePause(false);
      } else {
        setDisablePause(true);
      }
    }
  };

  const openCreatePlan = (product_ids) => {
    let minPrice = null;
    let maxPrice = null;
    let minPriceCheck = [];
    let maxPriceCheck = [];
    let parentASINs = [];
    let ASINs = [];

    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }

    setSelectedProducts(product_ids);

    productData.products
      .filter((p) => product_ids.includes(p.id))
      .forEach((p) => {
        if (p.parent_asin !== null) {
          parentASINs.push(p.parent_asin);
        } else {
          parentASINs.push(p.asin);
        }
        ASINs.push(p.asin);

        if (minPrice === null || p.min_price < minPrice) {
          if (p.min_price > 0) {
            minPrice = p.min_price;
          }
        }
        if (maxPrice === null || p.max_price > maxPrice) {
          if (p.max_price > 0) {
            maxPrice = p.max_price;
          }
        }

        if (p.min_price <= 0) {
          minPriceCheck.push(p.id);
        }
        if (p.max_price <= 0) {
          maxPriceCheck.push(p.id);
        }
      });

    const uniqueParents = parentASINs.filter(onlyUnique);
    const uniqueASINs = ASINs.filter(onlyUnique);
    setPlanOptions({
      minPrice: minPrice,
      maxPrice: maxPrice,
      minPriceCheck: minPriceCheck,
      maxPriceCheck: maxPriceCheck,
      uniqueParents: uniqueParents,
      uniqueASINs: uniqueASINs,
    });

    setShowEnroll(true);
  };

  const openShowPlanChoice = (product_ids) => {
    setSelectedProducts(product_ids);
    setShowPlanChoice(true);
  };

  const openPauseProducts = (product_ids) => {
    setSelectedProducts(product_ids);
    setShowPauseProducts(true);
  };

  const productById = useMemo(() => {
    const ids = {};
    if (productData?.products) {
      for (const product of productData?.products) {
        ids[product.id] = product;
      }
    }
    return ids;
  }, [productData]);

  const showEmpty = useMemo(() => {
    const totalSalesStart =
      user.total_sales_start_date?.[marketPlace.marketPlace];
    if (totalSalesStart) {
      if (moment(totalSalesStart).isBefore(moment().subtract(2, "days"))) {
        return false;
      } else {
        return "COLLECTING_DATA";
      }
    }
    return "NO_DATA";
  }, [user, marketPlace.marketPlace]);

  const showNoPlans = useMemo(() => {
    if (!segmentData) {
      return false;
    }
    let count = 0;
    const keys = Object.keys(segmentData);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      count += segmentData[key].current.count;
    }

    return count === 0;
  }, [segmentData]);

  if (showEmpty) {
    return <EmptyState type={showEmpty} modules={modules} />;
  }

  const Title = () => {
    if (metricsView === "strategy") {
      return "Targets";
    }

    return "Sales Segments";
  };

  return (
    <>
      <Grid
        fluid
        style={{
          minHeight: "calc(100vh - 150px)",
          background: "#fefefe",
        }}
      >
        <Row>
          <Col xs={12}>
            <BreadcrumbDates items={breadCrumbItems}>
              <span></span>
            </BreadcrumbDates>
          </Col>
          <Col xs={12} className="p-5">
            <Row>
              <Col>
                <MetricsCard
                  title={"Pricing Dashboard"}
                  status={
                    <div>
                      <Switch
                        onText={``}
                        offText={``}
                        value={viewPerformanceMetrics}
                        onColor="#FFFFFF"
                        onChange={(e) => {
                          setSelectedSegment(null);
                          setSelectedTarget(null);
                          setViewPerfomanceMetrics(!viewPerformanceMetrics);
                        }}
                      />
                      {viewPerformanceMetrics ? (
                        <span className="pl-3">Hide Performance Metrics</span>
                      ) : (
                        <span className="pl-3">View Performance Metrics</span>
                      )}
                    </div>
                  }
                  byline={
                    <>
                      {start.format("MMMM Do YYYY")}
                      {" - "}
                      {end.format("MMMM Do YYYY")}
                      {planFetching && (
                        <i className="fa fa-spinner fa-spin"></i>
                      )}
                    </>
                  }
                  metrics={overviewMetrics.metrics}
                  hideMetrics={false}
                  showMetricsSelector={true}
                  data={planData?.date}
                  loading={planFetching}
                  group={"day"}
                  showAnnotations={true}
                  defaultStateContent={null}
                  id={"pricingDashboardOverview"}
                  small={!viewPerformanceMetrics}
                >
                  {!showNoPlans && (
                    <Row>
                      <Col xs={6}>
                        {planLoading && (
                          <Loading fullPage={false} height={500} />
                        )}
                        {!planLoading && (
                          <>
                            <Row className="text-end">
                              <Col>
                                <div className="fs-4 fw-bold text-start">
                                  <Switch
                                    onText={``}
                                    offText={``}
                                    value={metricsView === "strategy"}
                                    onColor="#FFFFFF"
                                    onChange={(e) => {
                                      setSelectedSegment(null);
                                      setSelectedPlans([]);
                                      if (metricsView === "segments") {
                                        setMetricsView("strategy");
                                      } else {
                                        setMetricsView("segments");
                                      }
                                    }}
                                  />
                                  &nbsp;&nbsp;
                                  <Title />
                                </div>
                              </Col>
                            </Row>
                            {metricsView === "segments" && (
                              <>
                                {(segmentLoading || segmentFetching) && (
                                  <Loading fullPage={false} height={500} />
                                )}

                                {!segmentLoading && !segmentFetching && (
                                  <SegmentChart
                                    data={segmentData}
                                    onClick={choosePlans}
                                  />
                                )}
                              </>
                            )}
                            {metricsView === "strategy" && (
                              <>
                                {(strategyLoading || strategyFetching) && (
                                  <Loading fullPage={false} height={500} />
                                )}

                                {!strategyLoading && !strategyFetching && (
                                  <StrategyChart
                                    data={strategyMetrics}
                                    onClick={choosePlans}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Col>

                      {metricsView === "segments" && (
                        <Col xs={6} className="text-right">
                          <>
                            {!segmentLoading && !segmentFetching && (
                              <SegmentCards
                                selectedSegment={selectedSegment}
                                segmentData={segmentData}
                                choosePlans={choosePlans}
                                planLoading={planLoading}
                                timeSeries={timeSeries}
                                segmentLoading={segmentLoading}
                              />
                            )}

                            {(segmentLoading || segmentFetching) && (
                              <Loading fullPage={false} height={500} />
                            )}
                          </>
                        </Col>
                      )}
                      {metricsView === "strategy" && (
                        <Col xs={6} className="text-right">
                          <StrategyCards
                            selectedStragegy={selectedSegment}
                            selectedTarget={selectedTarget}
                            strategyData={targetData}
                            choosePlans={chooseTarget}
                            planLoading={targetLoading}
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                  {showNoPlans && (
                    <Row>
                      <Col className="py-5 text-center">
                        <div className="fs-3">
                          No Pricing Plans have been configured or they are
                          currently being processed. Charts will become
                          available soon.
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Col xs={12} className="px-5">
                    <Row>
                      {category && planData?.plan_id?.length > 0 && (
                        <Col xs={12}>
                          <Accordion
                            title={"Pricing Plans"}
                            initialExpanded={true}
                          >
                            <Row>
                              <Col xs={12} className="text-end pb-3">
                                <PricingPlanTable
                                  category={category}
                                  marketPlace={marketPlace}
                                  date={end}
                                  user={user}
                                  planData={planData}
                                  isLoading={planLoading}
                                  isFetching={planFetching}
                                  modules={modules}
                                  start={start}
                                  end={end}
                                />
                              </Col>
                            </Row>
                          </Accordion>
                        </Col>
                      )}
                      {category && planData?.plan_id && (
                        <Col xs={12} className="pb-3">
                          {checkModule(modules, "PRICE_DEBUG") &&
                            recommendations?.salesDifference > 0 && (
                              <Accordion
                                title={"Opportunities"}
                                initialExpanded={false}
                              >
                                <Row>
                                  <Col xs={12} className="text-start fs-4">
                                    <Recommendations
                                      marketPlace={marketPlace}
                                      user={user}
                                      category={category}
                                      modules={modules}
                                      recommendations={recommendations}
                                    />
                                  </Col>
                                </Row>
                              </Accordion>
                            )}
                          <Accordion
                            title={`Products`}
                            initialExpanded={
                              planData?.plan_id?.length === 0 ? true : false
                            }
                          >
                            <Row>
                              <Col xs={12} className="text-end pb-3">
                                <ProductsTable
                                  initialSelectedProducts={selectedProducts}
                                  onBulkChange={async (
                                    changes,
                                    clearChanges
                                  ) => {
                                    const updates = {};
                                    Object.entries(changes).forEach(
                                      ([id, changes]) => {
                                        updates[id] = Object.entries(changes)
                                          .filter(([key]) =>
                                            [
                                              "max_price",
                                              "min_price",
                                              "shipping_cost",
                                              "landed_cost",
                                            ].includes(key)
                                          )
                                          .map(([key, value]) => [
                                            key,
                                            parseFloat(value),
                                          ]);
                                      }
                                    );
                                    setInvalidateProductsTable([clearChanges]);
                                    updateProducts.mutate(updates);
                                  }}
                                  marketPlace={marketPlace}
                                  productData={productData?.products}
                                  isLoading={isProductDataLoading}
                                  ExpandedContent={ProductExpandedContent}
                                  isSaving={updateProducts.isLoading}
                                  onSelection={onSelection}
                                  cellContext={`pricing`}
                                  bulkActionOptions={[
                                    {
                                      label: "Create Pricing Plan",
                                      action: (selected) => {
                                        openCreatePlan(selected);
                                      },
                                      disabled: disablePlanAction,
                                    },
                                    {
                                      label: "Add to existing Pricing Plan",
                                      action: (selected) => {
                                        openShowPlanChoice(selected);
                                      },
                                      disabled:
                                        disablePlanAction ||
                                        !planData ||
                                        planData?.plan_id?.length === 0,
                                    },
                                    {
                                      label: "Pause Products",
                                      action: (selected) => {
                                        openPauseProducts(selected);
                                      },
                                      disabled:
                                        disablePause ||
                                        !planData ||
                                        planData?.plan_id?.length === 0,
                                    },
                                    {
                                      divider: true,
                                    },
                                    ...[
                                      "Fixed Price",
                                      "Clear Fixed Price",
                                      "Minimum Price",
                                      "Maximum Price",
                                      "Shipping Cost",
                                      "Landed Cost",
                                    ].map((label) => ({
                                      label:
                                        label === "Clear Fixed Price"
                                          ? label
                                          : `Set ${label}`,
                                      action: (selected) => {
                                        setSelectedProducts(selected);
                                        setValueType(label);
                                        setShowSetValue(true);
                                      },
                                    })),
                                  ]}
                                />
                              </Col>
                            </Row>
                          </Accordion>
                        </Col>
                      )}
                      {!category && planData && !planLoading && (
                        <Col xs={12}>
                          <Accordion
                            title={"Categories"}
                            initialExpanded={category ? false : true}
                          >
                            <Row>
                              <Col xs={12} className="text-end pb-3"></Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <CategoryTable
                                  stats={planData?.category_id}
                                  marketPlace={marketPlace}
                                  mobileStyle={mobileStyle}
                                  columnSpecs={overviewMetrics.columns}
                                  date={end}
                                  user={user}
                                  isv2={true}
                                />
                              </Col>
                              <Col xs={12} className="text-right">
                                <DownloadReport
                                  url_path={"category_report"}
                                  filters={filters}
                                  report_dl={"category"}
                                />
                              </Col>
                            </Row>
                          </Accordion>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </MetricsCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>

      <SetValue
        showSetValue={showSetValue}
        setShowSetValue={setShowSetValue}
        valueType={valueType}
        selectedProducts={selectedProducts}
        productById={productById}
        marketPlace={marketPlace}
        setSelectedProducts={setSelectedProducts}
      />

      <PricingPlan
        setShowEnroll={setShowEnroll}
        showEnroll={showEnroll}
        selectedProducts={selectedProducts}
        planOptions={planOptions}
        marketPlace={marketPlace}
        category={category}
        productById={productById}
      />

      <PricingPlanChoice
        plans={planData?.plan_id}
        setShowPlanChoice={setShowPlanChoice}
        showPlanChoice={showPlanChoice}
        productById={productById}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        marketPlace={marketPlace}
      />

      <PauseProducts
        setShowPauseProducts={setShowPauseProducts}
        showPauseProducts={showPauseProducts}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        productById={productById}
        marketPlace={marketPlace}
      />
    </>
  );
};

const PricingDashboardV2 = ({
  user,
  marketPlace,
  modules,
  location,
  amz_onboard,
}) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { category = null } = useParams();
  const DYNAMIC_PRICING_SUBSCRIPTION =
    (((user.pricing_tier && user.pricing_tier !== "unknown") ||
      location?.state?.firstVisit ||
      location?.state?.onboardingComplete) &&
      (amz_onboard.org_status === "active" ||
        amz_onboard.org_status === "trial_active" ||
        amz_onboard.org_status === "demo_org")) ||
    checkModule(modules, "PRICE_OPTIMIZATION") ||
    checkTierAccess(user.tier, "pricing", amz_onboard.org_status);

  if (!DYNAMIC_PRICING_SUBSCRIPTION) {
    return (
      <PaymentDiscovery discoveryType={"advertising"} dashboardType="pricing" />
    );
  }

  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const twoWeeksAgo = moment().subtract(14, "days").format("YYYY-MM-DD");

  return (
    <DatesProvider
      period={urlParams.get("period") || "custom"}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start") || twoWeeksAgo}
      end={urlParams.get("end") || yesterday}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        category={category}
        modules={modules}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(PricingDashboardV2);
