import { useState } from "react";

const setMetrics = (key, columns) => {
  let options = localStorage.getItem("metricOptions");
  try {
    options = JSON.parse(options);
  } catch (e) {
    options = {};
  }
  return localStorage.setItem(
    "metricOptions",
    JSON.stringify({ ...options, [key]: columns })
  );
};

const getMetrics = (key, defaults) => {
  const options = localStorage.getItem("metricOptions");
  defaults = defaults || [];
  if (options) {
    try {
      return JSON.parse(options)?.[key] ?? defaults;
    } catch (e) {
      return defaults;
    }
  }
  return defaults;
};

const useSelectedMetrics = (initialSelectedMetrics, key) => {
  const [selectedMetrics, setSelectedMetrics] = useState(() =>
    key ? getMetrics(key, initialSelectedMetrics) : initialSelectedMetrics
  );

  const onMetricChange = (changes) => {
    if (key) {
      setMetrics(key, changes);
    }
    setSelectedMetrics(changes);
  };

  const toggleMetric = (metric, force) => {
    if (force) {
      if (!selectedMetrics.includes(metric)) {
        onMetricChange([...selectedMetrics, metric]);
      }
    } else {
      if (selectedMetrics.includes(metric)) {
        onMetricChange(selectedMetrics.filter((m) => m !== metric));
      } else {
        onMetricChange([...selectedMetrics, metric]);
      }
    }
  };

  return { selectedMetrics, toggleMetric, setSelectedMetrics };
};

export default useSelectedMetrics;
