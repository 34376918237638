import React, { useState, useEffect, Fragment, useMemo } from "react";
import Carousel from "components/core/basic/Carousel";
import ShelfSummaryTile from "components/core/blocks/ShelfSummaryTile";
import { Form, FormGroup, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateAmzOnboardingStatus } from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import ShelfModal from "components/custom/analytics/ShelfModal";
import ShelfDetails from "components/custom/analytics/ShelfDetails";
import DataTable from "components/custom/analytics/DataTable";
import ShareView from "components/custom/analytics/ShareView";
//import Alerts from "components/custom/analytics/Alerts";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import { useFetch } from "hooks/api";
import { useQueryClient } from "react-query";
import PricingView from "components/custom/analytics/PricingView";
import ProductRanksView from "components/custom/analytics/ProductRanksView";
import moment from "moment";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";

const useStyles = (theme) => ({
  shelfOverview: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 1.5rem",
    },
  },
  shelfOverviewContainer: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      overflowY: "scroll",
      height: "700px",
      padding: "1rem 1rem",
      marginLeft: "1rem",
    },
  },
  shelfSummary: {
    [theme.breakpoints.down("xs")]: {
      order: 2,
      marginLeft: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "0 auto",
      padding: "0 1.5rem",
    },
  },
  shelfPerformanceContainer: {
    padding: "1rem 3rem",
    [theme.breakpoints.down("xs")]: {
      spacing: 3,
      marginLeft: "2rem",
      padding: "1rem",
    },
  },
  shelfDetailsContainer: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "1.5rem",
      padding: "1rem",
    },
  },
  mobileButtonContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      margin: "2rem auto",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      textAlign: "end",
    },
  },
  centeredMobileText: {
    marginTop: "2rem",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#73706E",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "2rem",
    },
  },
  portfolioItems: {
    padding: "1rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem !important",
    },
  },
  logo: {
    width: "220px",
    margin: "3rem",
  },
  spinner: {
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      height: "150px",
    },
  },
  createShelfTitle: {
    textAlign: "left",
    marginBottom: "2rem",
    fontSize: "20px",
    color: "#403E3D",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
});

const Analytics = (props) => {
  const [selectedShelf, setSelectedShelf] = useState(null);
  const [shelfData, setShelfData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [shelfStatsData, setShelfStatsData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredShelfData, setFilteredShelfData] = useState([]);
  const [selectedShelfSearchCount, setSelectedShelfSearchCount] = useState();
  const [numOfShelfCredits, setNumOfShelfCredits] = useState(0);
  const [numOfShelves, setNumOfShelves] = useState(0);
  const [selectedSearchId, setSelectedSearchId] = useState(null);
  const [selectedRollupType, setSelectedRollupType] = useState("page_1");
  const [dateRange, setSelectedDateRange] = useState(() => [
    moment().subtract(31, "days"),
    moment(),
  ]);
  const [start, end] = dateRange;

  const settings =
    props.user?.modules?.find((m) => m.name === "SEARCH_ANALYTICS")?.settings ??
    {};

  const queryClient = useQueryClient();

  const onShelfClick = (id) => {
    props.clearRedirect(null);
    setSelectedSearchId(null);
    setSelectedShelf(id);
  };

  const isSelectedShelf = (id) => {
    if (id === selectedShelf) {
      return true;
    } else {
      return false;
    }
  };

  // Get subscription details - cost per shelf and number of shelves subscribed for
  const { error: subscriptionDetailsError, data: subscriptionDetails } =
    useFetch(
      ["subscription"],
      "/api/billing/digitalshelf",
      {},
      { enabled: true }
    );

  // Get total number of shelves across account
  const {
    error: shelfCountError,
    data: shelfCountDetails,
    loading: isShelfCountLoading,
    refetch: refetchShelfCount,
  } = useFetch(["shelf_count"], "/api/shelves/", {});

  const {
    error: shelvesError,
    data: shelves,
    isLoading,
    refetch,
  } = useFetch(
    ["shelves", props.categoryId, props.marketplace],
    "/api/shelves/",
    {
      category: props.categoryId,
      marketplace: props.marketplace,
    }
  );

  const { error: brandsError, data: brands } = useFetch(
    ["brands", props.marketplace],
    "/api/brands/",
    { marketplace: props.marketplace }
  );

  const {
    error: shelfStatsError,
    isLoading: shelfStatsLoading,
    data: shelfStats,
  } = useFetch(
    [
      "shelf_stats",
      selectedShelf,
      selectedSearchId,
      selectedRollupType,
      props.marketplace,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
    ],
    `/api/shelf_stats/${selectedShelf}`,
    {
      search_id: selectedSearchId,
      rollup_type: selectedRollupType,
      start: start.format("YYYY-MM-DD"),
      end: end.format("YYYY-MM-DD"),
    },
    {
      enabled: !!selectedShelf,
    }
  );

  const {
    error: shelfStatsTotalError,
    isLoading: shelfStatsTotalLoading,
    data: shelfStatsTotalData,
  } = useFetch(
    [
      "shelf_stats_total",
      selectedShelf,
      selectedSearchId,
      selectedRollupType,
      props.marketplace,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
    ],
    `/api/shelf_stats/${selectedShelf}`,
    {
      search_id: selectedSearchId,
      rollup_type: selectedRollupType,
      group: "total",
      start: start.format("YYYY-MM-DD"),
      end: end.format("YYYY-MM-DD"),
    },
    {
      enabled: !!selectedShelf,
      select: (d) => d?.data || [],
      initialData: () => ({ data: [] }),
    }
  );
  const isStatsLoading = shelfStatsLoading || shelfStatsTotalLoading;

  // Retrieve updated data from the API upon shelf deletion or update so it is accurately displayed in the UI without a reload
  const updateShelfData = (type = "update", id = null) => {
    refetch();

    if (shelves?.data.results) {
      setShelfData(shelves.data.results);
    }

    if (shelvesError) {
      console.error(shelvesError);
    }

    // When shelf is deleted, will auto-select first available shelf, or display creation form in carousel if no other shelves exist
    if (type === "delete") {
      setSelectedShelf(null);

      // Update shelf count after deletion
      queryClient.invalidateQueries("count");
      refetchShelfCount();
    }

    // Upon successful creation, set selected shelf to new ID so user can see their newly added shelf
    if (
      type === "create" &&
      id !== null &&
      !isLoading &&
      !isShelfCountLoading
    ) {
      setSelectedShelf(id);

      // Update shelf count after creation
      queryClient.invalidateQueries("count");
      refetchShelfCount();
    }
  };

  useEffect(() => {
    if (shelves?.data.results) {
      setShelfData(shelves.data.results);
      if (searchFilter.length < 1) {
        setFilteredShelfData(shelves.data.results);
      }
    }

    if (brands?.data) {
      setBrandData(brands.data);
    }

    if (shelfStats?.data) {
      setShelfStatsData(shelfStats.data);
    }

    if (subscriptionDetails) {
      setNumOfShelfCredits(subscriptionDetails.data.count);
    }

    if (shelfCountDetails) {
      setNumOfShelves(shelfCountDetails.data.count);
    }

    if (shelvesError) {
      console.error(shelvesError);
    }

    if (brandsError) {
      console.error(brandsError);
    }

    if (shelfStatsError) {
      console.error(shelfStatsError);
    }

    if (shelfStatsTotalError) {
      console.error(shelfStatsTotalError);
    }

    if (subscriptionDetailsError) {
      console.error(subscriptionDetailsError);
    }

    if (shelfCountError) {
      console.error(shelfCountError);
    }

    setSelectedShelfSearchCount(
      filteredShelfData.find((shelf) => shelf.id === selectedShelf)?.searches
        ?.length
    );

    if (props.shelfRedirect) {
      setSelectedShelf(props.shelfRedirect);
    }
  }, [
    shelves,
    brands,
    brandData,
    shelfStats,
    shelvesError,
    brandsError,
    shelfStatsError,
    selectedShelf,
    shelfStatsData,
    filteredShelfData,
    searchFilter.length,
    subscriptionDetails,
    subscriptionDetailsError,
    shelfCountDetails,
    shelfCountError,
    shelfStatsTotalError,
    props.marketplace,
    props.categoryId,
    props.shelfRedirect,
  ]);

  if (selectedShelf === null && shelfData.length > 0) {
    setSelectedShelf(shelfData[0].id);
  }

  useEffect(() => {
    setSelectedShelf(shelfData[0]?.id ?? null);
  }, [props.categoryId, shelfData]);

  const filterShelves = (filterValue) => {
    setSearchFilter(filterValue);
    const filteredData = shelfData.filter(
      (shelf) =>
        shelf?.name?.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
    );
    setFilteredShelfData(filteredData);
  };

  const filterSearchId = (value) => {
    setSelectedSearchId(value);
  };

  const filterRollupType = (value) => {
    setSelectedRollupType(value);
  };

  const homeBrand = useMemo(() => {
    if ((selectedShelf, shelfData)) {
      const shelf = shelfData.find((shelf) => shelf.id === selectedShelf);
      return shelf?.brand_name ?? "";
    }
    return "";
  }, [shelfData, selectedShelf]);

  // Carousel data
  var carouselData = [
    [
      "Details",
      <ShelfDetails
        data={shelfData}
        brandData={brandData}
        selectedShelf={selectedShelf}
        categoryId={props.categoryId}
        updateShelfData={updateShelfData}
        shelfStatsData={shelfStatsData}
      />,
    ],
    [
      "Products",
      <ProductRanksView
        shelfStatsData={shelfStatsData}
        homeBrand={homeBrand}
        selectedShelf={selectedShelf}
        filterSearchId={filterSearchId}
        shelfData={shelfData}
        selectedSearchId={selectedSearchId}
        filterRollupType={filterRollupType}
        selectedRollupType={selectedRollupType}
        setSelectedDateRange={setSelectedDateRange}
        categories={props.categories}
        marketPlace={props.marketplace}
        user={props.user}
        dateRange={dateRange}
        isLoading={isStatsLoading}
      />,
    ],
    [
      "Share",
      <ShareView
        settings={settings}
        shelfStatsData={shelfStatsData}
        homeBrand={homeBrand}
        selectedShelf={selectedShelf}
        filterSearchId={filterSearchId}
        shelfData={shelfData}
        selectedSearchId={selectedSearchId}
        filterRollupType={filterRollupType}
        selectedRollupType={selectedRollupType}
        shelfStatsTotalData={shelfStatsTotalData}
        setSelectedDateRange={setSelectedDateRange}
        dateRange={dateRange}
        isLoading={isStatsLoading}
      />,
    ],
    [
      "Pricing",
      <PricingView
        shelfStatsData={shelfStatsData}
        homeBrand={homeBrand}
        selectedShelf={selectedShelf}
        filterSearchId={filterSearchId}
        shelfData={shelfData}
        selectedSearchId={selectedSearchId}
        filterRollupType={filterRollupType}
        setSelectedDateRange={setSelectedDateRange}
        shelfStatsTotalData={shelfStatsTotalData}
        selectedRollupType={selectedRollupType}
        dateRange={dateRange}
        isLoading={isStatsLoading}
      />,
    ],
    [
      "Brands",
      <DataTable
        settings={settings}
        homeBrand={homeBrand}
        shelfStatsData={shelfStatsData}
        selectedShelf={selectedShelf}
        selectedShelfSearchCount={selectedShelfSearchCount}
        filterSearchId={filterSearchId}
        shelfData={shelfData}
        selectedSearchId={selectedSearchId}
        filterRollupType={filterRollupType}
        selectedRollupType={selectedRollupType}
        shelfStatsTotalData={shelfStatsTotalData}
        setSelectedDateRange={setSelectedDateRange}
        dateRange={dateRange}
        isLoading={isStatsLoading}
      />,
    ],
    // TODO: This will be the entry point to alert
    // ["Alerts", <Alerts />],
  ];

  const { classes } = props;

  if (
    !props.user.analytics_tier ||
    ["unknown", "MANUALLY_DISABLED"].includes(props.user.analytics_tier)
  ) {
    return (
      <div style={{ marginTop: "30px" }}>
        <PaymentDiscovery discoveryType={"search-analytics"} />
      </div>
    );
  }

  return (
    <div>
      <Grid
        container
        style={{
          marginTop: "1rem",
          marginBottom: "-2rem",
          padding: "1rem 3rem",
        }}
      >
        <Grid item xs={12} sm={8}>
          <div className={classes.centeredMobileText}>
            Select one of the shelves below to view analytics
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.mobileButtonContainer}>
          <div style={{ marginTop: "1rem" }}>
            <span style={{ fontSize: "14px" }}>
              <ShelfModal
                categories={props.categories}
                categoryId={props.categoryId}
                dashboard={false}
                data={shelfData.find((shelf) => shelf.id === selectedShelf)}
                brandData={brandData}
                updateShelfData={updateShelfData}
                numOfShelfCredits={numOfShelfCredits}
                numOfShelves={numOfShelves}
                fullLayout={props.fullLayout}
                setSelectedCategory={props.setSelectedCategory}
                marketplace={props.marketplace}
              />
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3} style={{ paddingTop: "3rem" }}>
        <Grid
          item
          xs={12}
          md={4}
          style={{ margin: "auto", marginTop: "-1rem" }}
          className={classes.shelfOverview}
        >
          <div className={classes.shelfOverviewContainer}>
            <FormGroup className="mb-4">
              <InputGroup>
                <InputGroup.Text
                  style={{
                    borderTopLeftRadius: "25px",
                    borderBottomLeftRadius: "25px",
                  }}
                >
                  <i className="fa fa-search" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Search for a shelf..."
                  style={{ margin: "0px" }}
                  onChange={(e) => {
                    filterShelves(e.target.value);
                  }}
                />
              </InputGroup>
            </FormGroup>

            {filteredShelfData.length > 0 &&
              filteredShelfData.map((shelf) => {
                return (
                  <Fragment key={shelf.id}>
                    <div
                      onClick={(e) => onShelfClick(shelf.id)}
                      style={{
                        zIndex: 1,
                        position: "relative",
                        padding: "0",
                      }}
                    >
                      <ShelfSummaryTile
                        selected={isSelectedShelf(shelf.id)}
                        title={shelf.name}
                        data={{
                          "Number of Searches": shelf.searches.length,
                          "Average Rank": shelf.stats.position_avg
                            ? shelf.stats.position_avg
                            : "N/A",
                          "Home Brand": shelf.brand_name,
                          "Share of Shelf": shelf.stats.share_of_shelf,
                        }}
                        pending={shelf.stats.status}
                      />
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </Grid>
        <Grid item xs={12} md={8} className={classes.shelfSummary}>
          <div className={`${classes.productDetailsContainer}`}>
            {isLoading ? (
              <>
                <div className={`wrap ${classes.spinner}`}>
                  <img
                    src={logo}
                    alt="Loading..."
                    className="rotate"
                    width="80"
                  />
                </div>
              </>
            ) : shelfData.length < 1 ||
              (props.fullLayout &&
                props.categoryId &&
                shelfData.filter((s) => s.category === props.categoryId)
                  ?.length < 1) ? (
              <div className={`card ${classes.portfolioItems}`}>
                <div>
                  <div
                    className="header"
                    style={{ marginTop: "2rem", marginBottom: "5.5rem" }}
                  >
                    <h4
                      className={`title ${classes.createShelfTitle}`}
                      style={{ fontWeight: "700" }}
                    >
                      Create a New Shelf
                      <hr />
                    </h4>
                  </div>
                  <ShelfDetails
                    data={shelfData}
                    brandData={brandData}
                    selectedShelf={selectedShelf}
                    categoryId={props.categoryId}
                    updateShelfData={updateShelfData}
                    shelfStatsData={shelfStatsData}
                    numOfShelfCredits={numOfShelfCredits}
                    numOfShelves={numOfShelves}
                    fullLayout={props.fullLayout}
                    setSelectedCategory={props.setSelectedCategory}
                    marketplace={props.marketplace}
                  />
                </div>
              </div>
            ) : (
              <Carousel
                data={carouselData}
                width="auto"
                disabled={
                  filteredShelfData.find((shelf) => shelf.id === selectedShelf)
                    ?.stats?.status === "in_progress"
                }
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Analytics.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  user: state.user,
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(Analytics)));
