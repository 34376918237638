import { AMZ_CONNECT, AMZ_ONBOARD, PROFILE } from "../types";

export const initialState = {
  amazon_advertising_connecting: false,
  amazon_advertising_access: "",
  amazon_additional_information: "",
  amazon_mws_access: "",
  eu_amazon_mws_access: "",
  fe_amazon_mws_access: "",
  about_your_company: "",
  payment_discovery: "",
  org_status: "",
  listings_progress: "",
  category_progress: "",
  stock_progress: "",
  remainder_days: "",
  visited_category_management: false,
  loaded: false,
  amazon_spapi_access: "",
  eu_amazon_spapi_access: "",
  fe_amazon_spapi_access: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AMZ_CONNECT:
      return {
        ...state,
        amazon_advertising_connecting: action.payload, //connection in progress
        loaded: true,
      };
    case AMZ_ONBOARD:
      return {
        ...state,
        amazon_advertising_access: action.payload.amazon_advertising_access,
        amazon_additional_information:
          action.payload.amazon_additional_information,
        amazon_mws_access: action.payload.amazon_mws_access,
        eu_amazon_mws_access: action.payload.eu_amazon_mws_access,
        fe_amazon_mws_access: action.payload.fe_amazon_mws_access,
        about_your_company: action.payload.about_your_company,
        payment_discovery: action.payload.payment_discovery,
        org_status: action.payload.org_status,
        listings_progress: action.payload.listings_progress,
        category_progress: action.payload.category_progress,
        stock_progress: action.payload.stock_progress,
        remainder_days: action.payload.remainder_days,
        visited_category_management: action.payload.visited_category_management,
        loaded: true,
        amazon_spapi_access: action.payload.amazon_spapi_access,
        eu_amazon_spapi_access: action.payload.eu_amazon_spapi_access,
        fe_amazon_spapi_access: action.payload.fe_amazon_spapi_access,
      };
    case PROFILE:
      return {
        ...state,
        amazon_additional_information: true,
        amazon_advertising_connecting: false,
        loaded: true,
      };
    default:
      return state;
  }
}
