import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useFetch } from "hooks/api";
import { Typography, withStyles, Grid } from "@material-ui/core";
import NotificationItem from "components/custom/notifications/NotificationItem";
import SearchFilter from "components/custom/notifications/SearchFilter";
import { AuthButton } from "components/core/basic/Button";
import { useNotifications } from "../../src/notifications/useNotifications";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { PrimaryButton } from "../assets/themes/Buttons";
import InfiniteScrollWrapper from "./InfiniteScrollWrapper";
import { notificationTypes } from "notifications/notificationConstants";

const useStyles = (theme) => ({
  container: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4),
    overflowX: "hidden",
    justifyContent: "center",
    minHeight: "85vh",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95vw",
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      padding: 0,
      maxWidth: "93vw",
      overflowX: "hidden",
    },
  },
  mainHeader: {
    fontSize: "26px",
    color: "#403E3D",
    fontWeight: "700",
    margin: "auto",
  },
  navContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      margin: "auto",
      justifyContent: "space-around",
    },
    width: "100%",
  },
  navButton: {
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      margin: theme.spacing(2),
    },
  },
  notificationContainer: {
    display: "flex",
    flexDirection: "column",
    width: "850px",
    margin: theme.spacing(-4, "auto", "auto"),
    minHeight: "80vh",
    padding: theme.spacing(2, 4),
    [theme.breakpoints.only("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      marginLeft: theme.spacing(-1),
    },
  },
  filterContainer: {
    margin: "auto",
    width: "850px",
  },
  centerMobile: {
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, "auto"),
      padding: theme.spacing(4, "auto"),
      width: "50%",
    },
  },
  noNotificationsMessage: {
    textAlign: "center",
    margin: theme.spacing(3, "auto"),
    maxWidth: "550px",
  },
});

const NotificationsCenter = ({ classes, marketPlace }) => {
  const [selectedTab, setSelectedTab] = useState("active");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedNotificationType, setSelectedNotificationType] =
    useState("all");

  const [notificationData] = useNotifications(
    selectedCategory,
    selectedTab === "dismissed"
  );
  const queryClient = useQueryClient();

  const channel = "amazon";
  const marketplace = marketPlace.marketPlace;

  const { data: categoryData } = useFetch(
    ["categories", "marketplace", "channel"],
    "/api/gvads/categories",
    {
      marketplace,
      channel,
    }
  );

  const updateNotification = useMutation(
    async (updatedNotificationData) => {
      return await api.put(`/api/notifications/`, updatedNotificationData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("notifications");
      },
    }
  );

  const editNotification = (id, action, actionValue = null) => {
    let notificationData = {
      notification_id: id,
      notification_update_type: action,
    };

    if (action === "clicked" && actionValue) {
      notificationData["notification_action_data"] = actionValue;
    }

    updateNotification.mutate(notificationData);
  };

  const clearAll = () => {
    const notificationIds =
      selectedNotificationType === "all"
        ? notificationData?.map((n) => n.id)
        : notificationData
            ?.filter((n) => n.notification_type === selectedNotificationType)
            .map((n) => n.id);

    notificationIds.forEach((notificationId) => {
      editNotification(notificationId, "dismiss");
    });
  };

  return (
    <Grid container xs={12} spacing={4} className={classes.container}>
      <Grid item container xs={12} className={classes.header}>
        <Typography align="center" variant="h3" className={classes.mainHeader}>
          Notifications Center
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={12}
          sm={7}
          md={5}
          style={{ justifyContent: "center", textAlign: "center" }}
          className={classes.navContainer}
        >
          <Grid item>
            <AuthButton
              title="Show Active"
              buttonStyle={selectedTab === "active" ? "buttonActive" : "button"}
              onClick={(e) => {
                setSelectedTab("active");
              }}
              className={`${classes.navButton} ${
                selectedTab === "active" ? "buttonActive" : "button"
              }`}
            />
          </Grid>
          <Grid item>
            <AuthButton
              title="Show Dismissed"
              buttonStyle={
                selectedTab === "dismissed" ? "buttonActive" : "button"
              }
              onClick={(e) => {
                setSelectedTab("dismissed");
              }}
              className={`${classes.navButton} ${
                selectedTab === "dismissed" ? "buttonActive" : "button"
              }`}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={classes.filterContainer}>
        <SearchFilter
          filterType="categories"
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categoryData={categoryData?.data ?? []}
        />
        <SearchFilter
          filterType="notificationType"
          selectedNotificationType={selectedNotificationType}
          setSelectedNotificationType={setSelectedNotificationType}
        />
        <PrimaryButton
          onClick={() => {
            clearAll();
          }}
          className={classes.centerMobile}
          disabled={selectedTab === "dismissed"}
        >
          Clear All
        </PrimaryButton>
      </Grid>

      <Grid container item xs={12}>
        <Grid item className={classes.notificationContainer}>
          <InfiniteScrollWrapper
            data={
              selectedNotificationType === "all"
                ? notificationData
                    ?.filter((n) =>
                      selectedTab === "active"
                        ? !n.dismissed_on && !n.clicked_on
                        : n.dismissed_on || n.clicked_on
                    )
                    .sort(
                      (a, b) => new Date(b.created_on) - new Date(a.created_on)
                    )
                : notificationData
                    ?.filter((n) =>
                      selectedTab === "active"
                        ? !n.dismissed_on &&
                          !n.clicked_on &&
                          n.notification_type === selectedNotificationType
                        : (n.dismissed_on !== null || n.clicked_on !== null) &&
                          n.notification_type === selectedNotificationType
                    )
                    .sort(
                      (a, b) => new Date(b.created_on) - new Date(a.created_on)
                    )
            }
            Component={(props) => <NotificationItem {...props} />}
          />
          {((selectedNotificationType === "all" &&
            notificationData?.filter((n) => {
              if (selectedTab === "active") {
                return !n.dismissed_on && !n.clicked_on;
              } else {
                return n.dismissed_on || n.clicked_on;
              }
            })?.length < 1) ||
            (selectedNotificationType !== "all" &&
              notificationData?.filter((n) => {
                if (selectedTab === "active") {
                  return (
                    n.notification_type === selectedNotificationType &&
                    !n.dismissed_on &&
                    !n.clicked_on
                  );
                } else {
                  return (
                    n.notification_type === selectedNotificationType &&
                    (n.dismissed_on || n.clicked_on)
                  );
                }
              })?.length < 1)) && (
            <p className={classes.noNotificationsMessage}>
              There are currently no {selectedTab}{" "}
              {notificationTypes
                .find((t) => t.value === selectedNotificationType)
                ?.label?.toLowerCase()}{" "}
              notifications
              {selectedCategory !== "all" && " for the selected category"}.
            </p>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

NotificationsCenter.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
});

export default connect(mapStateToProps)(
  withStyles(useStyles)(NotificationsCenter)
);
