import { useFetch, useCreate, useUpdate } from "hooks/api";
import { useQueryClient } from "react-query";
import ContentEditor from "modules/product_quality/ContentEditor2";
import React, { useMemo, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Nav,
  Dropdown,
  DropdownButton,
  Badge,
  Table,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Checkbox } from "@material-ui/core";
import { PrimaryButton, LogoutButton } from "components/core/basic/Button";
import TemplateHistory from "./components/TemplateHistory";
import ProductsPreview from "./components/ProductsPreview";
import Loading from "components/core/blocks/Loading";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import TrellisBreadcrumb from "components/core/blocks/Breadcrumb";
import ConversionLegend from "./components/ConversionLegend";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import { getBadgeVariant } from "./utils";

import SideMenuBottom from "./components/SideMenuBottom";
import axios from "axios";
import { getURLPrefix } from "utils/getUrlPrefix";
import NotificationSystem from "react-notification-system";

let URL_PREFIX = getURLPrefix();

// API objects
// templateData - object from API (includes name, fields, revision)
// revision - (includes title, bullets array, keywords, description)
// fields - array of field names to update

// Frontend objects (extracted from templateData)
// templateName - name
// templateContent - revision but with flattened bullets array
// fieldsToUpdate - array of field names to update

const sections = [
  {
    label: "Product Title",
    field: "title",
  },
  {
    label: "Bullet Points",
    field: "feature_bullets_0",
  },
  {
    field: "feature_bullets_1",
  },
  {
    field: "feature_bullets_2",
  },
  {
    field: "feature_bullets_3",
  },
  {
    field: "feature_bullets_4",
  },
  {
    label: "Keywords",
    field: "generic_keywords",
  },
  {
    label: "Description",
    field: "description",
  },
];

const initialFields = sections.map(({ field }) => field);

const getRevisionFromTemplateContent = (templateContent) => ({
  title: templateContent?.title,
  feature_bullets: [
    templateContent?.feature_bullets_0,
    templateContent?.feature_bullets_1,
    templateContent?.feature_bullets_2,
    templateContent?.feature_bullets_3,
    templateContent?.feature_bullets_4,
  ],
  generic_keywords: templateContent?.generic_keywords,
  description: templateContent?.description,
});

const getTemplateContentFromRevision = (revision) => ({
  title: revision?.title,
  feature_bullets_0: revision?.feature_bullets?.[0],
  feature_bullets_1: revision?.feature_bullets?.[1],
  feature_bullets_2: revision?.feature_bullets?.[2],
  feature_bullets_3: revision?.feature_bullets?.[3],
  feature_bullets_4: revision?.feature_bullets?.[4],
  generic_keywords: revision?.generic_keywords,
  description: revision?.description,
});

const ContentSection = ({
  product,
  templateContent,
  onContentChange,
  onSelectAllFields,
  fieldsToUpdate,
  onFieldsChange,
  highlightPhrases = [],
}) => {
  const allFieldsChecked = useMemo(
    () => fieldsToUpdate?.length === sections.length,
    [fieldsToUpdate]
  );
  return (
    <>
      <Card.Title className="mb-2 d-flex align-items-center pl-2">
        <Checkbox
          checked={allFieldsChecked}
          onClick={() => onSelectAllFields(!allFieldsChecked)}
        />
        <span className="pl-1">Select the fields you wish to modify</span>
      </Card.Title>
      {sections.map(({ field, label }, index) => (
        <Row key={index} className="mb-5">
          <Col xs={12}>
            {label && (
              <Row className="pt-4">
                <Col>
                  <h4 style={{ marginTop: 0 }}>{label}</h4>
                </Col>
                {field === "title" && (
                  <Col className="pe-4" xs={5}>
                    <ConversionLegend />
                  </Col>
                )}
              </Row>
            )}
            <div>
              <Checkbox
                checked={fieldsToUpdate?.includes(field)}
                onClick={() => onFieldsChange(field)}
                className="float-start"
                style={{ marginTop: "7px", marginLeft: "5px" }}
              />
              <ContentEditor
                richText={field === "description"}
                field={field}
                fieldIndex={index}
                content={templateContent?.[field] ?? ""}
                minLength={140}
                maxLength={200}
                setContent={onContentChange}
                variations={product?.variations}
                readOnly={!fieldsToUpdate?.includes(field)}
                highlightPhrases={highlightPhrases}
              />
            </div>
          </Col>
        </Row>
      ))}
    </>
  );
};

const ProductsSection = ({
  products,
  templateContent = {},
  fieldsToUpdate = [],
}) => {
  const productsWithTemplateContent = products.map((product) => ({
    ...product,
    templateContent: {
      title: fieldsToUpdate.includes("title") ? templateContent.title : null,
      description: fieldsToUpdate.includes("description")
        ? templateContent.description
        : null,
      generic_keywords: fieldsToUpdate.includes("generic_keywords")
        ? templateContent.generic_keywords
        : null,
      feature_bullets_0: fieldsToUpdate.includes("feature_bullets_0")
        ? templateContent.feature_bullets_0
        : null,
      feature_bullets_1: fieldsToUpdate.includes("feature_bullets_1")
        ? templateContent.feature_bullets_1
        : null,
      feature_bullets_2: fieldsToUpdate.includes("feature_bullets_2")
        ? templateContent.feature_bullets_2
        : null,
      feature_bullets_3: fieldsToUpdate.includes("feature_bullets_3")
        ? templateContent.feature_bullets_3
        : null,
      feature_bullets_4: fieldsToUpdate.includes("feature_bullets_4")
        ? templateContent.feature_bullets_4
        : null,
    },
  }));

  return (
    <>
      <Card.Title className="mb-5">
        Changes will be applied to these products
      </Card.Title>
      <ProductsPreview products={productsWithTemplateContent} />
    </>
  );
};

const HistorySection = ({
  templateId,
  setTemplateContent,
  setContentChanged,
}) => {
  const onApply = (revision) => {
    setTemplateContent(getTemplateContentFromRevision(revision));
    setContentChanged(true);
  };
  return (
    <>
      <Card.Title className="mb-5">
        Choose a previous version of this template to apply
      </Card.Title>
      <TemplateHistory templateId={templateId} onApply={onApply} />
    </>
  );
};

const ContentTemplate = ({ marketPlace }) => {
  const { templateId } = useParams();
  const [_templateContent, setTemplateContent] = useState(null);
  const [tab, setTab] = useState("content");
  const [_fieldsToUpdate, setFieldsToUpdate] = useState(null);
  const [_templateName, setTemplateName] = useState(null);
  const [contentChanged, setContentChanged] = useState(false);
  const [editorMode, setEditorMode] = useState("edit");

  const [keyword, setKeyword] = useState(null);
  const [loadingSearchTerms, setLoadingSearchTerms] = useState(false);
  const [searchTerms, setSearchTerms] = useState(null);
  const [quickTerm, setQuickTerm] = useState(null);

  const notificationRef = useRef();

  const queryClient = useQueryClient();

  const { data: templateData, isLoading: isTemplateDataLoading } = useFetch(
    ["content_template", templateId],
    `/merchandise/content_templates/${templateId}/`,
    {},
    {
      select: (data) => data?.data || {},
    }
  );

  const products = templateData?.products ?? [];
  const product = products[0] ?? null;

  const category = product?.product_category ?? "";
  const { data: categoryObj } = useFetch(
    ["categories", category],
    `/api/v2/categories/${category}`,
    {},
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: !!category,
    }
  );
  const sortFn = (a, b) => {
    return b.cr - a.cr;
  };
  const seoKeywords = (templateData?.seo_keywords?.keywords ?? []).sort(sortFn);
  const seoSearchTerms = (templateData?.seo_keywords?.search_terms ?? []).sort(
    sortFn
  );
  const hiddenKeywords = templateData?.hidden_keywords ?? [];

  const getSearchTerms = async (monogram, product_id) => {
    if (loadingSearchTerms) {
      return;
    }
    setEditorMode("metrics");
    setKeyword(monogram);
    setLoadingSearchTerms(true);

    const response = await axios.get(
      `${URL_PREFIX}/merchandise/search_terms?monogram=${monogram["keyword_text"]}&product_id=${product_id}`
    );

    const sts = [];
    for (let i = 0; i < response.data.search_terms.length; i++) {
      const st = response.data.search_terms[i];

      st.ctr = 0;
      st.cr = 0;
      st.acos = 0;

      if (st.impressions > 0) {
        st.ctr = st.clicks / st.impressions;
      }
      if (st.clicks > 0) {
        st.cr = st.conversions / st.clicks;
      }
      if (st.cost > 0 && st.sales > 0) {
        st.acos = st.cost / st.sales;
      }
      sts.push(st);
    }
    sts.sort(function (a, b) {
      return b.impressions - a.impressions;
    });

    setSearchTerms(sts);
    setLoadingSearchTerms(false);
  };

  const templateContent = useMemo(() => {
    const revision = templateData?.revision?.title
      ? templateData?.revision
      : templateData?.initial_revision;
    return _templateContent || getTemplateContentFromRevision(revision);
  }, [_templateContent, templateData]);

  const initialRevision = useMemo(() => {
    return templateData?.initial_revision;
  }, [templateData]);

  const fieldsToUpdate = useMemo(() => {
    return _fieldsToUpdate || templateData?.fields || initialFields;
  }, [_fieldsToUpdate, templateData]);

  // Used to populate the keyword explorer
  const allContentElements = useMemo(() => {
    const cleanDescription = templateContent?.description?.replace(
      /<[^>]+>/g,
      ""
    );
    return {
      title: templateContent?.title,
      description: cleanDescription,
      genericKeywords: templateContent?.generic_keywords,
      featureBullet0: templateContent?.feature_bullets_0,
      featureBullet1: templateContent?.feature_bullets_1,
      featureBullet2: templateContent?.feature_bullets_2,
      featureBullet3: templateContent?.feature_bullets_3,
      featureBullet4: templateContent?.feature_bullets_4,
    };
  }, [
    templateContent.description,
    templateContent.title,
    templateContent.generic_keywords,
    templateContent.feature_bullets_0,
    templateContent.feature_bullets_1,
    templateContent.feature_bullets_2,
    templateContent.feature_bullets_3,
    templateContent.feature_bullets_4,
  ]);

  const highlightPhrases = [...seoKeywords, ...seoSearchTerms];

  const templateName = useMemo(() => {
    if (_templateName === "") return "";
    return _templateName || templateData?.name || "";
  }, [_templateName, templateData]);

  const saveDate = templateData?.save_date ?? null;

  const onContentChange = (field, value, idx) => {
    setTemplateContent({ ...templateContent, [field]: value });
    setContentChanged(true);
  };

  const onFieldsChange = (field) => {
    if (fieldsToUpdate.includes(field))
      setFieldsToUpdate(fieldsToUpdate.filter((f) => f !== field));
    else setFieldsToUpdate([...fieldsToUpdate, field]);
    setContentChanged(true);
  };
  const onSelectAllFields = (selectAll) => {
    if (selectAll) {
      setFieldsToUpdate(initialFields);
    } else {
      setFieldsToUpdate([]);
    }
    setContentChanged(true);
  };

  const { mutate: resetTemplate } = useCreate(
    `/merchandise/content_templates/${templateId}/reset/`,
    {},
    {
      onSuccess: () => {
        notificationRef.current.addNotification({
          title: (
            <span>
              <span data-notify="icon" className="pe-7s-check" /> Template Reset
            </span>
          ),
          level: "success",
          position: "tr",
          dismissible: "button",
        });
        queryClient.invalidateQueries(["content_template"]);
        setTemplateContent(getTemplateContentFromRevision(initialRevision));
        setContentChanged(false);
      },
      onError: () => {
        notificationRef.current.addNotification({
          title: (
            <span>
              <span data-notify="icon" className="pe-7s-attention" /> Error
            </span>
          ),
          message: (
            <div>
              <b>
                Failed to reset template. Please try again or contact support.
              </b>
            </div>
          ),
          level: "error",
          position: "tr",
          dismissible: "button",
        });
      },
    }
  );

  const resetTemplateForm = () => {
    setContentChanged(false);
    setTemplateContent(getTemplateContentFromRevision(initialRevision));
    setFieldsToUpdate(initialFields);
  };

  const { mutate: saveTemplate } = useCreate(
    `/merchandise/content_templates/${templateId}/save/`,
    {},
    {
      onSuccess: () => {
        notificationRef.current.addNotification({
          title: (
            <span>
              <span data-notify="icon" className="pe-7s-check" /> Template Saved
            </span>
          ),
          level: "success",
          position: "tr",
          dismissible: "button",
        });
        queryClient.invalidateQueries(["content_template"]);
        setContentChanged(false);
      },
      onError: () => {
        notificationRef.current.addNotification({
          title: (
            <span>
              <span data-notify="icon" className="pe-7s-attention" /> Error
            </span>
          ),
          message: (
            <div>
              <b>
                Failed to save template. Please try again or contact support.
              </b>
            </div>
          ),
          level: "error",
          position: "tr",
          dismissible: "button",
        });
      },
    }
  );

  const { mutate: publishTemplate, isLoading: isPublishing } = useCreate(
    `/merchandise/content_templates/${templateId}/publish/`,
    {},
    {
      onSuccess: () => {
        notificationRef.current.addNotification({
          title: (
            <span>
              <span data-notify="icon" className="pe-7s-check" />
              Template submitted for publishing
            </span>
          ),
          level: "success",
          position: "tr",
          dismissible: "button",
        });
        queryClient.invalidateQueries(["content_template"]);
      },
      onError: () => {
        notificationRef.current.addNotification({
          title: (
            <span>
              <span data-notify="icon" className="pe-7s-attention" /> Error
            </span>
          ),
          message: (
            <div>
              <b>
                Failed to submit template for publishing. Please try again or
                contact support.
              </b>
            </div>
          ),
          level: "error",
          position: "tr",
          dismissible: "button",
        });
      },
    }
  );

  const { mutate: saveTemplateName } = useUpdate(
    `/merchandise/content_templates/${templateId}/`,
    {},
    {
      onSuccess: () => {
        // toast.success("Content template name saved successfully");
        console.log("Content template name saved successfully");
      },
    }
  );

  const payload = {
    fields: fieldsToUpdate,
    revision: getRevisionFromTemplateContent(templateContent),
  };

  if (isTemplateDataLoading) {
    return <Loading height={"300px"} fullPage={true} />;
  }

  return (
    <>
      <NotificationSystem ref={notificationRef} />
      <TrellisBreadcrumb
        items={[
          { name: "Content", href: "/user/content/quality/org" },
          {
            name: `${categoryObj?.name || "Products"}`,
            href: `/user/content/quality/category/${
              product?.product_category ?? 0
            }`,
          },
          {
            name: "Edit Content Template",
            href: `/user/content/templates/${templateId}`,
          },
        ]}
      />
      <Container
        fluid
        style={{
          minHeight: "calc(100vh - 150px)",
          background: "#fefefe",
        }}
        className="mt-5"
      >
        <Row className="mt-4 fs-standard">
          <Col xs={12} md={3}>
            <Card className="p-5">
              <Card.Title>Attributes</Card.Title>
              {Object.entries(product?.variations ?? {}).map(([key, value]) => (
                <Row key={key}>
                  <Col xs={4}>
                    <strong>
                      {key.charAt(0).toUpperCase() + key.slice(1)}:
                    </strong>
                  </Col>
                  <Col xs={4}>
                    <span>{value}</span>
                  </Col>
                  <Col xs={4}>
                    <span>{"{" + key + "}"}</span>
                  </Col>
                </Row>
              ))}
              {Object.entries(product?.variations ?? {}).length === 0 && (
                <span className="text-muted">No attributes</span>
              )}
            </Card>

            <SideMenuBottom
              getSearchTerms={getSearchTerms}
              product={product}
              seoKeywords={seoKeywords}
              seoSearchTerms={seoSearchTerms}
              quickTerm={quickTerm}
              allContentElements={allContentElements}
              initialHiddenKeywords={hiddenKeywords}
            />
          </Col>

          <Col xs={12} md={9}>
            <Card className="p-5">
              {editorMode === "edit" && (
                <>
                  <Row className="mb-4">
                    <Col className="text-start">
                      <Form.Control
                        size="lg"
                        aria-label="Template Name"
                        type="text"
                        value={templateName}
                        onChange={(e) => {
                          setTemplateName(e.target.value);
                        }}
                        onBlur={() => {
                          saveTemplateName({ name: templateName });
                        }}
                        style={{
                          border: "none",
                          borderBottom: "1px solid #ccc",
                          borderRadius: "0px",
                          boxShadow: "none",
                          color: "#152748",
                          paddingLeft: "0px",
                        }}
                      />
                    </Col>
                    <Col></Col>
                    <Col className="text-end" xs={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className="pe-4">
                          {saveDate === null ? (
                            <PrimaryButton
                              onClick={() => {
                                resetTemplateForm();
                              }}
                              disabled={!contentChanged}
                            >
                              Reset
                            </PrimaryButton>
                          ) : (
                            <ConfirmationModal
                              title={`Reset Content`}
                              confirmationMessage={`You are about to reset the content for this product. This will remove all changes made to the content since the last save. Are you sure you want to continue?`}
                              onConfirm={() => {
                                resetTemplate();
                              }}
                              confirmButtonText={`Reset`}
                              modalButtonText={`Reset`}
                              disabled={saveDate === null}
                              buttonStyle={`logout_button`}
                              modelButtonStyle={{ margin: 0 }}
                            />
                          )}
                        </div>
                        <div className="pe-4">
                          <LogoutButton
                            title={`Save`}
                            disabled={!contentChanged}
                            onClick={() => {
                              saveTemplate(payload);
                            }}
                          />
                        </div>
                        <div>
                          <ConfirmationModal
                            title={`Publish Content`}
                            confirmationMessage={
                              <>
                                <div>
                                  You are about to publish the content for this
                                  product. This will make the content live on
                                  the listing page of Amazon.
                                </div>
                                <div className="pt-3">
                                  <strong>
                                    NOTE: It may take up to 24 hours for the
                                    changes to reflect on your Amazon Listing
                                    page
                                  </strong>
                                </div>
                                <div className="pt-3">
                                  Are you sure you want to continue?
                                </div>
                              </>
                            }
                            onConfirm={() => {
                              publishTemplate();
                            }}
                            confirmButtonText={`Publish`}
                            modalButtonText={`Publish`}
                            disabled={!saveDate}
                            loading={isPublishing}
                            loadingModalButtonText={`Publishing...`}
                            buttonStyle={`logout_button`}
                            modelButtonStyle={{ margin: 0 }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ph-5 mb-5 border-bottom fs-standard">
                      <Nav
                        variant="underline"
                        onSelect={(k) => {
                          return setTab(k);
                        }}
                        activeKey={tab}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="content">Content</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="products">Products</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="history">History</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {tab === "content" && (
                        <ContentSection
                          product={product}
                          templateContent={templateContent}
                          onContentChange={onContentChange}
                          onFieldsChange={onFieldsChange}
                          onSelectAllFields={onSelectAllFields}
                          fieldsToUpdate={fieldsToUpdate}
                          highlightPhrases={highlightPhrases}
                        ></ContentSection>
                      )}
                      {tab === "products" && (
                        <ProductsSection
                          products={products}
                          fieldsToUpdate={fieldsToUpdate}
                          templateContent={templateContent}
                        ></ProductsSection>
                      )}
                      {tab === "history" && (
                        <HistorySection
                          templateId={templateId}
                          setTemplateContent={setTemplateContent}
                          setContentChanged={setContentChanged}
                        ></HistorySection>
                      )}
                    </Col>
                  </Row>
                </>
              )}

              {editorMode === "metrics" && (
                <>
                  <Row>
                    <Col xs={12} className="px-5 pt-3 text-end">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                          setEditorMode("edit");
                        }}
                        aria-label="Close"
                        style={{ fontSize: "20px" }}
                      ></button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="px-5 pt-5 pb-5">
                      <h4 style={{ marginTop: 0 }}>
                        Keyword Performance{" "}
                        {keyword && (
                          <Badge bg={getBadgeVariant(keyword)}>
                            {keyword["keyword_text"]}
                          </Badge>
                        )}
                      </h4>
                      {!keyword && (
                        <Row className="fs-standard">
                          <Col className="text-center">
                            Select a keyword to view performance
                          </Col>
                        </Row>
                      )}
                      {keyword && (
                        <Row className="fs-standard">
                          <Col xs={12}>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th className="text-end">
                                    <strong>Impressions</strong>
                                  </th>
                                  <th className="text-end">
                                    <strong>Clicks</strong>
                                  </th>
                                  <th className="text-end">
                                    <strong>CTR</strong>
                                  </th>
                                  <th className="text-end">
                                    <strong>Conversion</strong>s
                                  </th>
                                  <th className="text-end">
                                    <strong>CR</strong>
                                  </th>
                                  <th className="text-end">
                                    <strong>Sales</strong>
                                  </th>
                                  <th className="text-end">
                                    <strong>ACoS</strong>
                                  </th>
                                  <th className="text-end">
                                    <strong>Cost</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-end">
                                    {keyword.impressions}
                                  </td>
                                  <td className="text-end">{keyword.clicks}</td>
                                  <td className="text-end">
                                    {(keyword.ctr * 100).toFixed(2)}%
                                  </td>
                                  <td className="text-end">
                                    {keyword.conversions}
                                  </td>
                                  <td className="text-end">
                                    {(keyword.cr * 100).toFixed(2)}%
                                  </td>
                                  <td className="text-end">
                                    {formatCurrency(
                                      keyword.sales,
                                      marketPlace.marketPlace
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {(keyword.acos * 100).toFixed(2)}%
                                  </td>
                                  <td className="text-end">
                                    {formatCurrency(
                                      keyword.cost,
                                      marketPlace.marketPlace
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="px-5 pt-2 pb-5">
                      <h4 style={{ marginTop: 0 }}>Associated Search Terms</h4>
                      {!searchTerms && !loadingSearchTerms && (
                        <Row className="fs-standard">
                          <Col className="text-center">
                            Select a keyword to view the search terms
                          </Col>
                        </Row>
                      )}
                      {loadingSearchTerms && (
                        <Row className="fs-standard">
                          <Col className="text-center">
                            Finding search terms...
                          </Col>
                        </Row>
                      )}

                      {!loadingSearchTerms && searchTerms && product && (
                        <>
                          <p>
                            Below are the search terms, that contains the
                            keyword{" "}
                            <Badge bg={getBadgeVariant(keyword)}>
                              {keyword["keyword_text"]}
                            </Badge>
                            , which have generated sales on the advertisements
                            that contain this product (
                            <strong>
                              {product?.sku} / {product?.asin}
                            </strong>
                            ).
                          </p>
                          <div
                            style={{
                              maxHeight: "500px",
                              minHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            <Table striped hover bordered>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "left" }}>
                                    <strong>Search Term</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>Impressions</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>Clicks</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>CTR</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>CONVERSIONS</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>CR</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>Sales</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>Cost</strong>
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    <strong>ACOS</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {searchTerms.map((term, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <DropdownButton
                                          title={term.keyword_text}
                                          bsPrefix="dropdown-menu-seo"
                                        >
                                          <Dropdown.Item
                                            href={`/user/content/explore#q=${term.keyword_text}`}
                                          >
                                            View Keyword Explorer
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              setQuickTerm(term.keyword_text)
                                            }
                                          >
                                            Quick Explorer
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2">
                                            Add keyword to Media Plan
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </td>
                                      <td className="text-end">
                                        {formatNumber(term.impressions)}
                                      </td>
                                      <td className="text-end">
                                        {formatNumber(term.clicks)}
                                      </td>
                                      <td className="text-end">
                                        {formatPercent(term.ctr, 2)}%
                                      </td>
                                      <td className="text-end">
                                        {formatNumber(term.conversions)}
                                      </td>
                                      <td className="text-end">
                                        {formatPercent(term.cr, 2)}%
                                      </td>
                                      <td className="text-end">
                                        {formatCurrency(
                                          term.sales,
                                          marketPlace.marketPlace
                                        )}
                                      </td>
                                      <td className="text-end">
                                        {formatCurrency(
                                          term.cost,
                                          marketPlace.marketPlace
                                        )}
                                      </td>
                                      <td className="text-end">
                                        {formatPercent(term.acos, 2)}%
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  user: state.user,
});

export default connect(mapStateToProps)(ContentTemplate);
