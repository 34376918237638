import React, { useMemo } from "react";
import { PAGE_HEIGHT, PAGE_WIDTH } from "./utils";
import { Row, Col } from "react-bootstrap";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import AdSpendGoalPieCharts from "./AdSpendGoalPieCharts";
import AdSpendProgressionChart from "./AdSpendProgressionChart";
import StatTile from "./StatTile";
import { formatNumber, formatPercent } from "utils/formatNumber";

const MediaPlanGoals = ({ data, includeNTMAds, roasPreference }) => {
  const { adData, adPlanData } = data;

  const adCountWithoutComposite = useMemo(() => {
    return adPlanData?.filter((ad) => !ad.is_composite)?.length;
  }, [adPlanData]);

  const averageAcosTargets = useMemo(
    () =>
      adPlanData?.reduce(
        (acc, ad) => {
          if (ad.is_composite) {
            return acc;
          }

          acc.maxAcos += ad.max_acos;
          acc.targetAcos += ad.target_acos;
          acc.minRoas += ad.min_roas;
          acc.targetRoas += ad.target_roas;
          return acc;
        },
        { maxAcos: 0, targetAcos: 0, minRoas: 0, targetRoas: 0 }
      ),
    [adPlanData]
  );

  const averageTargetAcos = useMemo(() => {
    return averageAcosTargets.targetAcos / adCountWithoutComposite;
  }, [averageAcosTargets, adCountWithoutComposite]);

  const averageMaxAcos = useMemo(() => {
    return averageAcosTargets.maxAcos / adCountWithoutComposite;
  }, [averageAcosTargets, adCountWithoutComposite]);

  const averageTargetRoas = useMemo(() => {
    return averageAcosTargets.targetRoas / adCountWithoutComposite;
  }, [averageAcosTargets, adCountWithoutComposite]);

  const averageMinRoas = useMemo(() => {
    return averageAcosTargets.minRoas / adCountWithoutComposite;
  }, [averageAcosTargets, adCountWithoutComposite]);

  return (
    <div
      className={`box box-common pt-5`}
      style={{
        maxWidth: PAGE_WIDTH,
        maxHeight: PAGE_HEIGHT,
        overflowY: "hidden",
      }}
    >
      <img src={logo} className="watermark" alt="Trellis logo" />
      <Row
        style={{
          padding: "20px 80px",
        }}
      >
        <Col xs={6} className="d-flex justify-content-center">
          {roasPreference ? (
            <StatTile
              label={`Average Media Plan Min ROAS ${!includeNTMAds && "*"}`}
              metricId={null}
              overrideValue={formatNumber(averageMinRoas, {}, 2)}
              showPreviousPeriod={false}
              showYoy={false}
            />
          ) : (
            <StatTile
              label={`Average Media Plan Max ACOS ${!includeNTMAds && "*"}`}
              metricId={null}
              overrideValue={formatPercent(averageMaxAcos / 100, 2)}
              showPreviousPeriod={false}
              showYoy={false}
            />
          )}
        </Col>
        <Col xs={6} className="d-flex justify-content-center">
          {roasPreference ? (
            <StatTile
              label={`Average Media Plan Target ROAS ${!includeNTMAds && "*"}`}
              metricId={null}
              overrideValue={formatNumber(averageTargetRoas, {}, 2)}
              showPreviousPeriod={false}
              showYoy={false}
            />
          ) : (
            <StatTile
              label={`Average Media Plan Target ACOS ${!includeNTMAds && "*"}`}
              metricId={null}
              overrideValue={formatPercent(averageTargetAcos / 100, 2)}
              showPreviousPeriod={false}
              showYoy={false}
            />
          )}
        </Col>
      </Row>
      <Row className="py-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <AdSpendGoalPieCharts
            adData={adPlanData}
            roasPreference={roasPreference}
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} style={{ paddingLeft: "80px", paddingRight: "80px" }}>
          <AdSpendProgressionChart adPlanData={adPlanData} adData={adData} />
        </Col>
      </Row>
      {!includeNTMAds && (
        <span className="table-annotation">
          * Across Trellis-managed media plans
        </span>
      )}
    </div>
  );
};

export default MediaPlanGoals;
