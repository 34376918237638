import React from "react";
import Checkbox from "components/core/basic/CheckBox";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { Row, Col } from "react-bootstrap";
import toggleArrayEntry from "utils/toggleArrayEntry";
import {
  AD_TYPES,
  SD_BID_TYPE_OPTIONS,
  SD_CAMPAIGN_TYPE_OPTIONS,
} from "./constants";
import ToolTips from "utils/toolTips";

const ReviewBadge = ({ text, styleOverrides }) => (
  <div
    className="fs-standard"
    style={{
      display: "flex",
      alignItems: "center",
      background: "#DDD",
      color: "#777777",
      border: "1px solid #777777",
      borderRadius: "2px",
      margin: "0 1rem 1rem 0",
      padding: ".25rem .75rem",
      ...styleOverrides,
    }}
  >
    {text}
  </div>
);

const SDTargetingOptions = ({
  state,
  errors,
  onChange,
  review,
  fieldsToValidate,
  modules,
}) => {
  const bidTypeOptions = [SD_BID_TYPE_OPTIONS.VCPM, SD_BID_TYPE_OPTIONS.CPC];

  const campaignTypeOptions = [
    SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE,
    SD_CAMPAIGN_TYPE_OPTIONS.CONTEXTUAL,
  ];

  const updateLookbackPeriod = (value) => {
    let updatedLookbackSelections = toggleArrayEntry(
      value,
      state.lookbackPeriod ?? []
    );
    onChange({
      lookbackPeriod: updatedLookbackSelections,
    });
  };

  if (state.adType !== AD_TYPES.SPONSORED_DISPLAY_V2.value) {
    return null;
  }

  return (
    <Row>
      <Col xs={12} style={{ padding: "1rem" }}>
        <Row>
          <Col xs={12} md={6} style={{ paddingRight: "3rem" }}>
            <div>
              <h3
                style={{
                  fontWeight: 560,
                  fontSize: "1.6rem",
                  color: "#73706E",
                }}
              >
                Bid Type:
                <ToolTips
                  toolTip={
                    "vCPM is the cost per one thousand viewable impressions. An advertiser is charged when their ad has been viewed by shoppers, as opposed to a CPC strategy where advertisers pay for ad clicks."
                  }
                  position="top"
                  id={"sdBidTypeTooltip"}
                />
              </h3>
            </div>
            <div>
              <FormGroupTile
                type="select"
                stateName={"sdBidType"}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: value.value })
                }
                disabled={review}
                defaultValue={bidTypeOptions.find(
                  (type) => type.value === state.sdBidType
                )}
                options={bidTypeOptions}
              />
              {errors.sdBidType && fieldsToValidate?.includes("sdBidType") && (
                <div className="mt-3">
                  <small
                    className="text-danger"
                    style={{ paddingLeft: "1rem" }}
                  >
                    {errors.sdBidType && errors.sdBidType}
                  </small>
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} md={6} style={{ paddingLeft: "3rem" }}>
            <div>
              <h3
                style={{
                  fontWeight: 560,
                  fontSize: "1.6rem",
                  color: "#73706E",
                }}
              >
                Campaign Type:{" "}
                <ToolTips
                  toolTip={
                    "Contextual selects relevant content to serve ads to, while audience targeting delivers ads based on a user's previous browsing history."
                  }
                  position="top"
                  id={"sdCampaingTypeTooltip"}
                />
              </h3>
            </div>
            <div>
              <FormGroupTile
                type="select"
                stateName={"sdCampaignType"}
                handleChildFormElement={(key, value) => {
                  onChange({
                    [key]: value.value,
                    campaignNames: {
                      [value.value === SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value
                        ? "audience_name"
                        : "contextual_name"]: null,
                    },
                  });
                }}
                disabled={review || state.id}
                defaultValue={campaignTypeOptions.find(
                  (type) => type.value === state.sdCampaignType
                )}
                options={campaignTypeOptions}
              />
              {errors.sdCampaignType &&
                fieldsToValidate?.includes("sdCampaignType") && (
                  <div className="mt-3">
                    <small
                      className="text-danger"
                      style={{ paddingLeft: "1rem" }}
                    >
                      {errors.sdCampaignType && errors.sdCampaignType}
                    </small>
                  </div>
                )}
            </div>
          </Col>
        </Row>
        {state.sdCampaignType === SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value && (
          <>
            <Row className="mt-5">
              <h3
                style={{
                  fontWeight: 560,
                  fontSize: "1.6rem",
                  color: "#73706E",
                }}
              >
                Lookback Period:{" "}
                <ToolTips
                  toolTip={
                    "Select the period of a user's shopping history used in audience targeting."
                  }
                  position="top"
                  id={"harvestInfo"}
                />
              </h3>
              <p>
                {errors.sdLookbackPeriod &&
                  fieldsToValidate?.includes("sdLookbackPeriod") && (
                    <small
                      className="text-danger"
                      style={{ paddingLeft: "1.5rem", fontSize: "12px" }}
                    >
                      {errors.sdLookbackPeriod && errors.sdLookbackPeriod}
                    </small>
                  )}
              </p>
              {review ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {state.lookbackPeriod
                    .sort((a, b) => a - b)
                    .map((period) => (
                      <ReviewBadge text={`${period} Days`} />
                    ))}
                </div>
              ) : (
                <>
                  <Col xs={12} md={4} lg={2}>
                    <Checkbox
                      inline
                      number={`lookbackPeriod7d${review ? "Review" : "Edit"}`}
                      label="7 Days"
                      checked={state.lookbackPeriod.includes(7)}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        updateLookbackPeriod(7);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <Checkbox
                      inline
                      number={`lookbackPeriod14d${review ? "Review" : "Edit"}`}
                      label="14 Days"
                      checked={state.lookbackPeriod.includes(14)}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        updateLookbackPeriod(14);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <Checkbox
                      inline
                      number={`lookbackPeriod30d${review ? "Review" : "Edit"}`}
                      label="30 Days"
                      checked={state.lookbackPeriod.includes(30)}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        updateLookbackPeriod(30);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <Checkbox
                      inline
                      number={`lookbackPeriod60d${review ? "Review" : "Edit"}`}
                      label="60 Days"
                      checked={state.lookbackPeriod.includes(60)}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        updateLookbackPeriod(60);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <Checkbox
                      inline
                      number={`lookbackPeriod90d${review ? "Review" : "Edit"}`}
                      label="90 Days"
                      checked={state.lookbackPeriod.includes(90)}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        updateLookbackPeriod(90);
                      }}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row className={`mt-${review ? "1" : "5"}`}>
              <h3
                style={{
                  fontWeight: 560,
                  fontSize: "1.6rem",
                  color: "#73706E",
                  paddingTop: "1rem",
                }}
              >
                Audience Targeting:{" "}
                <ToolTips
                  toolTip={
                    "Select how previous user interactions with listings will influence targeting."
                  }
                  position="top"
                  id={"harvestInfo"}
                />
              </h3>
              {errors.sdAudienceTargets &&
                fieldsToValidate?.includes("sdAudienceTargets") && (
                  <p>
                    <small
                      className="text-danger"
                      style={{ paddingLeft: "1.5rem", fontSize: "12px" }}
                    >
                      {errors.sdAudienceTargets && errors.sdAudienceTargets}
                    </small>
                  </p>
                )}
              {!review && (
                <>
                  <Col xs={12} md={5}>
                    <Checkbox
                      inline
                      number={`audienceViewsSimilar${
                        review ? "Review" : "Edit"
                      }`}
                      label="Views Similar Targeting"
                      checked={state.viewsSimilar}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        onChange({
                          viewsSimilar: !state.viewsSimilar,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={5}>
                    <Checkbox
                      inline
                      number={`audienceViewsExact${review ? "Review" : "Edit"}`}
                      label="Views Exact Targeting"
                      checked={state.viewsExact}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        onChange({
                          viewsExact: !state.viewsExact,
                        });
                      }}
                    />
                  </Col>
                </>
              )}
            </Row>
            {review ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {state.viewsSimilar && (
                  <ReviewBadge text={"Views Similar Targeting"} />
                )}
                {state.viewsExact && (
                  <ReviewBadge text={"Views Exact Targeting"} />
                )}
                {state.purchasesSimilar && (
                  <ReviewBadge text={"Purchases Similar Targeting"} />
                )}
                {state.purchasesExact && (
                  <ReviewBadge text={"Purchases Exact"} />
                )}

                {state.sdAmazonSuggestedCategories && (
                  <ReviewBadge text={"Amazon suggested categories"} />
                )}
              </div>
            ) : (
              <>
                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12} md={5}>
                    <Checkbox
                      inline
                      number={`audiencePurchasesSimilar${
                        review ? "Review" : "Edit"
                      }`}
                      label="Purchases Similar"
                      checked={state.purchasesSimilar}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        onChange({
                          purchasesSimilar: !state.purchasesSimilar,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={5}>
                    <Checkbox
                      inline
                      number={`audiencePurchasesExact${
                        review ? "Review" : "Edit"
                      }`}
                      label="Purchases Exact"
                      checked={state.purchasesExact}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        onChange({
                          purchasesExact: !state.purchasesExact,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12} md={5}>
                    <Checkbox
                      inline
                      number={`amazonSuggestedCategories${
                        review ? "Review" : "Edit"
                      }`}
                      label={<span>Amazon suggested categories</span>}
                      checked={state.sdAmazonSuggestedCategories}
                      disabled={review}
                      disablePointerEvents={review}
                      onClick={(e) => {
                        onChange({
                          sdAmazonSuggestedCategories:
                            !state.sdAmazonSuggestedCategories,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        {state.sdCampaignType === SD_CAMPAIGN_TYPE_OPTIONS.CONTEXTUAL.value && (
          <Row className="mt-5">
            <h3
              style={{
                fontWeight: 560,
                fontSize: "1.6rem",
                color: "#73706E",
              }}
            >
              Targeting Options:
              <ToolTips
                toolTip={
                  "Select how previous user interactions with listings will influence targeting."
                }
                position="top"
                id={"harvestInfo"}
              />
            </h3>
            {errors.sdContextualTargets &&
              fieldsToValidate?.includes("sdContextualTargets") && (
                <p>
                  <small
                    className="text-danger"
                    style={{ paddingLeft: "1.5rem", fontSize: "12px" }}
                  >
                    {errors.sdContextualTargets && errors.sdContextualTargets}
                  </small>
                </p>
              )}
            {review ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {state.sdDynamicSimilar && (
                  <ReviewBadge text={"Dynamic Similar Targeting"} />
                )}
                {state.sdAmazonSuggestedCategories && (
                  <ReviewBadge text={"Amazon suggested categories"} />
                )}
              </div>
            ) : (
              <>
                <Col xs={12} md={5}>
                  <Checkbox
                    inline
                    number={`contextualDynamicSimilar${
                      review ? "Review" : "Edit"
                    }`}
                    label="Dynamic Similar Targeting"
                    checked={state.sdDynamicSimilar}
                    disabled={review}
                    disablePointerEvents={review}
                    onClick={(e) => {
                      onChange({ sdDynamicSimilar: !state.sdDynamicSimilar });
                    }}
                  />
                </Col>
                <Col xs={12} md={5}>
                  <Checkbox
                    inline
                    number={`amazonSuggestedCategories${
                      review ? "Review" : "Edit"
                    }`}
                    label="Amazon suggested categories"
                    checked={state.sdAmazonSuggestedCategories}
                    disabled={review}
                    disablePointerEvents={review}
                    onClick={(e) => {
                      onChange({
                        sdAmazonSuggestedCategories:
                          !state.sdAmazonSuggestedCategories,
                      });
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SDTargetingOptions;
