import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Col, Container as Grid, Row, Table } from "react-bootstrap";
import { useDates } from "dates/useDates";
import { useLocation, useParams } from "react-router-dom";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import DatesProvider from "dates/DatesProvider";
import { useFetch } from "hooks/api";
import moment from "moment";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import Switch from "react-bootstrap-switch";
import { DASHBOARD_LINKS } from "utils/dashboardLinks";
import useCategories from "hooks/useCategories";
import Loading from "components/core/blocks/Loading";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useQueryParams from "hooks/useQueryParams";
import MetricsCard from "components/custom/merchandise/MetricsCard";

const Sparkline = ({ data, color }) => {
  const options = useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      chart: {
        type: "line",
        height: 30,
        width: 100,
        margin: [0, 0, 0, 0],
        backgroundColor: null,
        borderWidth: 0,
        style: {
          overflow: "visible",
        },
        skipClone: true,
      },
      title: {
        text: "",
      },
      xAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      yAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 2,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 1,
              },
            },
          },
          fillOpacity: 0.25,
        },
        column: {
          negativeColor: "#910000",
          borderColor: "silver",
        },
      },
      series: [
        {
          data: data,
          color: color,
        },
      ],
    };
  }, [data, color]);

  return (
    <div style={{ margin: "auto", width: "100px" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const Dashboard = ({ user, marketPlace, category, channel }) => {
  const [showAdSpend, setShowAdSpend] = useState(true);
  const isPricing = useQueryParams("mode", null)[0] === "pricing";
  const [operatingExpenses, setOperatingExpenses] = useState(false);
  const [showCostOfGoodsSold, setShowCostOfGoodsSold] = useState(false);
  const [showAdditionalMetrics, setShowAdditionalMetrics] = useState(false);
  const [showRevenue, setShowRevenue] = useState(false);
  const { group, start, end, preStart, preEnd } = useDates();

  const getDashboardTo = (category = null) => {
    let url = `/user/pl/report`;

    if (category) {
      url = `/user/pl/report/${category}`;
    }
    if (isPricing) {
      url += "?mode=pricing";
    }
    return url;
  };

  let { currentCategoryDetails: categoryDetails, categories: categoryData } =
    useCategories(category, "plreport");

  const currencyFormat = (value) => {
    if (value === null) {
      return "-";
    }

    return formatCurrency(value, marketPlace.marketPlace, true, false, false);
  };

  const currencyFormatWithPennies = (value) => {
    if (value === null) {
      return "-";
    }

    return formatCurrency(value, marketPlace.marketPlace, false, false, false);
  };

  const percentageFormat = (value) => {
    return formatPercent(value, 2);
  };

  const numberFormatNoDecimals = (value) => {
    return formatNumber(value, null, 0);
  };

  const breadCrumbItems = [
    {
      name: "Profit and Loss Report",
      href: getDashboardTo(),
      dropdownLinkOptions: DASHBOARD_LINKS,
      unsorted: true,
      searchable: false,
      dropdownSubtitle: "Dashboards",
    },
  ];

  breadCrumbItems.push({
    name: categoryDetails?.name ? categoryDetails?.name : "All Categories",
    href: categoryDetails?.name ? categoryDetails.href : "/user/pl/report",
    dropdownLinkOptions: categoryData,
    searchable: true,
    searchPlaceholderText: "Search categories...",
    dropdownSubtitle: "Categories",
  });

  const filters = {
    mp: marketPlace.marketPlace,
    channel,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: false,
    group_by: "org_id",
  };

  if (category) {
    filters["group_by"] = "category_id";
    filters["category_id"] = category;
    filters["category"] = category;
  }

  const { isSuccess, data, isLoading, isFetching } = useFetch(
    [
      "category_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      group,
      category,
      isPricing,
    ],
    `/api/data_report/category_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: !isPricing,
    }
  );

  const {
    data: planData,
    isLoading: planLoading,
    isFetching: planFetching,
  } = useFetch(
    ["planData", marketPlace.marketPlace, category, start, end, isPricing],
    `/api/data_report/pricingplan_report`,
    filters,
    {
      select: (d) => d.data,
      enabled: isPricing,
      keepPreviousData: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { PLData, summary, timeSeries } = useMemo(() => {
    if (!isSuccess && !isPricing) {
      return { PLData: [], summary: [] };
    }

    if (planLoading && isPricing) {
      return { PLData: [], summary: [] };
    }

    const plInfo = isPricing ? planData : data;

    const summary = [];
    for (let i = 0; i < plInfo.date.length; i++) {
      const date = plInfo.date[i];
      const totalSales = date.total_sales;
      const adSales = date.ad_sales;
      const organicSales = totalSales - adSales;
      const totalUnits = date.total_units;
      const landedCost = date.landed_cost;
      const channelFees = date.channel_fees;

      const shippingCost = date.shipping_cost;
      const fulfillmentFees = date.fulfillment_fees;
      const cogs = landedCost + channelFees + shippingCost + fulfillmentFees;
      const cogsWithAdSpend = cogs + date.cost;

      const profit = totalSales - cogs;
      const profitWithAdSpend = totalSales - cogsWithAdSpend;

      const margin = profit / totalSales;
      const marginWithAdSpend = profitWithAdSpend / totalSales;

      const acos = date.cost / date.ad_sales;
      const tacos = date.cost / totalSales;

      const profitPerPageView = profit / date.page_views;

      const pageViews = date.page_views;
      let operatingExpenses = shippingCost + fulfillmentFees;
      let cogsMetric = landedCost + channelFees;

      const cost = date.cost;

      const bsrRank = date.bsr_rank;

      if (showAdSpend) {
        operatingExpenses += date.cost;
      }

      summary.push({
        totalSales,
        landedCost,
        channelFees,
        shippingCost,
        fulfillmentFees,
        cogs,
        cogsWithAdSpend,
        profit,
        profitWithAdSpend,
        margin,
        marginWithAdSpend,
        acos,
        tacos,
        profitPerPageView,
        pageViews,
        operatingExpenses,
        cost,
        cogsMetric,
        totalUnits,
        bsrRank,
        adSales,
        organicSales,
      });
    }

    const timeSeries = {
      totalSales: [],
      landedCost: [],
      channelFees: [],
      shippingCost: [],
      fulfillmentFees: [],
      cogs: [],
      cogsWithAdSpend: [],
      profit: [],
      profitWithAdSpend: [],
      margin: [],
      marginWithAdSpend: [],
      acos: [],
      tacos: [],
      profitPerPageView: [],
      pageViews: [],
      operatingExpenses: [],
      cost: [],
      cogsMetric: [],
      totalUnits: [],
      bsrRank: [],
      adSales: [],
      organicSales: [],
    };
    for (let i = 0; i < summary.length; i++) {
      timeSeries.totalSales.push(summary[i].totalSales);
      timeSeries.landedCost.push(summary[i].landedCost);
      timeSeries.channelFees.push(summary[i].channelFees);
      timeSeries.shippingCost.push(summary[i].shippingCost);
      timeSeries.fulfillmentFees.push(summary[i].fulfillmentFees);
      timeSeries.cogs.push(summary[i].cogs);
      timeSeries.cogsWithAdSpend.push(summary[i].cogsWithAdSpend);
      timeSeries.profit.push(summary[i].profit);
      timeSeries.profitWithAdSpend.push(summary[i].profitWithAdSpend);
      timeSeries.margin.push(summary[i].margin);
      timeSeries.marginWithAdSpend.push(summary[i].marginWithAdSpend);
      timeSeries.acos.push(summary[i].acos);
      timeSeries.tacos.push(summary[i].tacos);
      timeSeries.profitPerPageView.push(summary[i].profitPerPageView);
      timeSeries.pageViews.push(summary[i].pageViews);
      timeSeries.operatingExpenses.push(summary[i].operatingExpenses);
      timeSeries.cost.push(summary[i].cost);
      timeSeries.cogsMetric.push(summary[i].cogsMetric);
      timeSeries.totalUnits.push(summary[i].totalUnits);
      timeSeries.bsrRank.push(summary[i].bsrRank);
      timeSeries.adSales.push(summary[i].adSales);
      timeSeries.organicSales.push(summary[i].organicSales);
    }

    return { PLData: plInfo.date, summary: summary, timeSeries: timeSeries };
  }, [data, isSuccess, planData, planLoading, isPricing, showAdSpend]);

  const metricData = useMemo(() => {
    if (isSuccess && !isPricing) {
      return data;
    }

    if (!planLoading && isPricing) {
      return planData;
    }

    return { date: [] };
  }, [data, isSuccess, planData, planLoading, isPricing]);

  if (isLoading || planLoading) {
    return <Loading />;
  }

  const DateFormat = ({ date }) => {
    if (group === "day") {
      return moment(date).format("DD MMM YY");
    } else if (group === "week") {
      return moment(date).format("DD MMM YY");
    } else if (group === "month") {
      return moment(date).format("MMM YY");
    } else if (group === "quarter") {
      return moment(date).format("MMM YY");
    } else if (group === "year") {
      return moment(date).format("YYYY");
    }
  };

  const PLRow = ({
    name,
    data,
    marketPlace,
    column,
    format = formatCurrency,
    addBorder,
    sparklineData,
    header = false,
    headerSet = () => {},
    headerGet = null,
  }) => {
    return (
      <tr
        style={{
          borderBottom: addBorder ? "2px solid #000" : "0px",
          backgroundColor: header ? "#efefef" : "inherit",
          cursor: header ? "pointer" : "inherit",
        }}
        onClick={() => {
          if (header && headerGet !== null) {
            headerSet(!headerGet);
          }
        }}
      >
        <td
          style={{
            width: "250px",
            lineHeight: "20px",
            fontWeight: header ? "bold" : "inherit",
          }}
        >
          <div className="d-flex justify-content-start align-items-center h-100">
            {header && (
              <i
                className={`pe-7s-angle-${headerGet ? "down" : "right"} pe-2x`}
                style={{ cursor: "pointer", fontSize: "4rem" }}
              />
            )}
            {name}
          </div>
        </td>
        <td>
          {sparklineData && <Sparkline data={sparklineData} color="#000" />}

          {!sparklineData && <span>&nbsp;</span>}
        </td>

        {!data && !column && <td colSpan={PLData.length}>&nbsp;</td>}
        {data &&
          column &&
          data.map((d, i) => (
            <td key={i} className="text-center">
              {format(d[column])}
            </td>
          ))}
      </tr>
    );
  };

  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems}>
            <span></span>
          </BreadcrumbDates>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="px-5">
          <MetricsCard
            title={"Profit and Loss Report"}
            status={<></>}
            byline={
              <>
                {start.format("MMMM Do YYYY")}
                {" - "}
                {end.format("MMMM Do YYYY")}
                {(isFetching || planFetching) && (
                  <i className="fa fa-spinner fa-spin"></i>
                )}
              </>
            }
            metrics={[]}
            hideMetrics={true}
            showMetricsSelector={false}
            data={metricData ? metricData?.date : []}
            loading={planLoading || isLoading}
            group={"day"}
            showAnnotations={true}
            defaultStateContent={null}
            id={"profitLossDashboard"}
            small={true}
          >
            <>
              <Row>
                <Col className="text-end pb-2">
                  <Switch
                    onText={``}
                    offText={``}
                    value={showAdSpend}
                    onColor="#FFFFFF"
                    onChange={(e) => {
                      setShowAdSpend(!showAdSpend);
                    }}
                  />
                  {showAdSpend ? (
                    <span className="pl-3 fs-5">Show Advertising Metrics</span>
                  ) : (
                    <span className="pl-3 fs-5">Hide Advertising Metrics</span>
                  )}
                </Col>
              </Row>

              <div className="table-responsive" style={{ overflow: "auto" }}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "200px" }}>&nbsp;</th>
                      <th style={{ width: "100px" }}>Trend</th>
                      {PLData.map((d) => (
                        <th key={d.name}>
                          <DateFormat date={d.name} />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <PLRow
                      name="Units Sold"
                      data={summary}
                      marketPlace={marketPlace}
                      column="totalUnits"
                      format={formatNumber}
                      addBorder={true}
                      sparklineData={timeSeries.totalUnits}
                    />

                    <PLRow
                      name="Revenue"
                      data={PLData}
                      marketPlace={marketPlace}
                      column="total_sales"
                      format={currencyFormat}
                      sparklineData={timeSeries.totalSales}
                      addBorder={true}
                      header={true}
                      headerSet={setShowRevenue}
                      headerGet={showRevenue}
                    />
                    {showRevenue && (
                      <>
                        <PLRow
                          name="Organic Revenue"
                          data={summary}
                          marketPlace={marketPlace}
                          column="organicSales"
                          format={currencyFormat}
                          sparklineData={timeSeries.organicSales}
                        />
                        <PLRow
                          name="Advertising Revenue"
                          data={summary}
                          marketPlace={marketPlace}
                          column="adSales"
                          addBorder={true}
                          format={currencyFormat}
                          sparklineData={timeSeries.adSales}
                        />
                      </>
                    )}

                    <PLRow
                      name="Cost of Goods Sold"
                      data={summary}
                      marketPlace={marketPlace}
                      column="cogsMetric"
                      format={currencyFormat}
                      sparklineData={timeSeries.cogsMetric}
                      addBorder={true}
                      header={true}
                      headerSet={setShowCostOfGoodsSold}
                      headerGet={showCostOfGoodsSold}
                    />
                    {showCostOfGoodsSold && (
                      <>
                        <PLRow
                          name="Landed Cost"
                          data={PLData}
                          marketPlace={marketPlace}
                          column="landed_cost"
                          format={currencyFormat}
                          sparklineData={timeSeries.landedCost}
                        />
                        <PLRow
                          name="Referral Fees"
                          data={PLData}
                          marketPlace={marketPlace}
                          column="channel_fees"
                          addBorder={true}
                          format={currencyFormat}
                          sparklineData={timeSeries.channelFees}
                        />
                      </>
                    )}

                    <PLRow
                      name="Operating Expenses"
                      data={summary}
                      marketPlace={marketPlace}
                      column="operatingExpenses"
                      format={currencyFormat}
                      sparklineData={timeSeries.operatingExpenses}
                      addBorder={true}
                      header={true}
                      headerSet={setOperatingExpenses}
                      headerGet={operatingExpenses}
                    />
                    {operatingExpenses && (
                      <>
                        <PLRow
                          name="Shipping Cost"
                          data={PLData}
                          marketPlace={marketPlace}
                          column="shipping_cost"
                          format={currencyFormat}
                          sparklineData={timeSeries.shippingCost}
                        />
                        <PLRow
                          name="Fulfillment Fees"
                          data={PLData}
                          marketPlace={marketPlace}
                          column="fulfillment_fees"
                          format={currencyFormat}
                          sparklineData={timeSeries.fulfillmentFees}
                        />
                      </>
                    )}
                    {operatingExpenses && showAdSpend && (
                      <>
                        <PLRow
                          name="Ad Spend"
                          data={PLData}
                          marketPlace={marketPlace}
                          column="cost"
                          addBorder={false}
                          format={currencyFormat}
                          sparklineData={timeSeries.cost}
                        />
                      </>
                    )}
                    <tr>
                      <th
                        colspan={PLData?.length + 2}
                        className="text-start text-uppercase"
                        style={{
                          backgroundColor: "#efefef",
                          lineHeight: "45px",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        Profit and Loss Summary
                      </th>
                    </tr>
                    <PLRow
                      name="Total COGS"
                      data={summary}
                      marketPlace={marketPlace}
                      column="cogs"
                      format={currencyFormat}
                      sparklineData={timeSeries.cogs}
                    />
                    {showAdSpend && (
                      <PLRow
                        name="Total COGS incl. Ad Spend"
                        data={summary}
                        marketPlace={marketPlace}
                        column="cogsWithAdSpend"
                        format={currencyFormat}
                        addBorder={true}
                        sparklineData={timeSeries.cogsWithAdSpend}
                      />
                    )}
                    <PLRow
                      name="Net Profit"
                      data={summary}
                      marketPlace={marketPlace}
                      column="profit"
                      format={currencyFormat}
                      sparklineData={timeSeries.profit}
                    />
                    {showAdSpend && (
                      <PLRow
                        name="Profit incl. Ad Spend"
                        data={summary}
                        marketPlace={marketPlace}
                        column="profitWithAdSpend"
                        format={currencyFormat}
                        addBorder={true}
                        sparklineData={timeSeries.profitWithAdSpend}
                      />
                    )}
                    <PLRow
                      name="Margin"
                      data={summary}
                      marketPlace={marketPlace}
                      column="margin"
                      format={percentageFormat}
                      sparklineData={timeSeries.margin}
                    />
                    {showAdSpend && (
                      <>
                        <PLRow
                          name="Margin incl. Ad Spend"
                          data={summary}
                          marketPlace={marketPlace}
                          column="marginWithAdSpend"
                          format={percentageFormat}
                          sparklineData={timeSeries.marginWithAdSpend}
                        />
                        <PLRow
                          name="ACoS"
                          data={summary}
                          marketPlace={marketPlace}
                          column="acos"
                          format={percentageFormat}
                          sparklineData={timeSeries.acos}
                        />
                        <PLRow
                          name="TACoS"
                          data={summary}
                          marketPlace={marketPlace}
                          column="tacos"
                          format={percentageFormat}
                          addBorder={true}
                          sparklineData={timeSeries.tacos}
                        />
                      </>
                    )}
                    <PLRow
                      name="Additional Metrics"
                      marketPlace={marketPlace}
                      addBorder={true}
                      header={true}
                      headerSet={setShowAdditionalMetrics}
                      headerGet={showAdditionalMetrics}
                    />
                    {showAdditionalMetrics && (
                      <>
                        <PLRow
                          name="Page Views"
                          data={summary}
                          marketPlace={marketPlace}
                          column="pageViews"
                          format={formatNumber}
                          sparklineData={timeSeries.pageViews}
                        />
                        <PLRow
                          name="Profit per Page View"
                          data={summary}
                          marketPlace={marketPlace}
                          column="profitPerPageView"
                          format={currencyFormatWithPennies}
                          sparklineData={timeSeries.profitPerPageView}
                        />
                        <PLRow
                          name="Best Seller Rank"
                          data={summary}
                          marketPlace={marketPlace}
                          column="bsrRank"
                          format={numberFormatNoDecimals}
                          sparklineData={timeSeries.bsrRank}
                        />
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          </MetricsCard>
        </Col>
      </Row>
    </Grid>
  );
};

const PLDashboard = ({ user, marketPlace, channel }) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { category = null } = useParams();

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        category={category}
        channel={channel}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  channel: state.channel.currentChannel,
});

export default connect(mapStateToProps)(PLDashboard);
