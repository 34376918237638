import axios from "axios";
import getURLPrefix from "utils/getUrlPrefix";

const URL_PREFIX = getURLPrefix();

const get = async (endpoint, params, config) => {
  if (params) {
    config = { params, ...config };
  }
  return axios.get(`${URL_PREFIX}${endpoint}`, config);
};

const post = async (endpoint, body, params) => {
  const config = params && { params };
  return axios.post(`${URL_PREFIX}${endpoint}`, body, config);
};

const put = async (endpoint, body, params) => {
  const config = params && { params };
  return axios.put(`${URL_PREFIX}${endpoint}`, body, config);
};

const patch = async (endpoint, body, params) => {
  const config = params && { params };
  return axios.patch(`${URL_PREFIX}${endpoint}`, body, config);
};

const remove = async (endpoint, params) => {
  const config = params && { params };
  return axios.delete(`${URL_PREFIX}${endpoint}`, config);
};

export default { get, post, put, remove, patch };
