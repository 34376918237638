import "assets/css/trellis-styles.css";
import "assets/css/authentication_pages.css";
import axios from "axios";
import {
  LogoutButton,
  SecondaryButton,
} from "components/core/basic/Button.jsx";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import getURLPrefix from "utils/getUrlPrefix";
import { SketchPicker } from "react-color";
import FormGroupTile from "components/core/basic/FormGroupTile";
import Floater from "react-floater";
import { Player, ControlBar, ReplayControl, BigPlayButton } from "video-react";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import Wheel from "components/core/blocks/Wheel";
import CenteredModal from "components/core/basic/CenteredModal";
import { FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FormControl,
  FormLabel as ControlLabel,
  Col,
  Modal,
  Row,
} from "react-bootstrap";
import ProgressBar from "components/core/blocks/ProgressBar";
import TemplateSelect from "components/custom/creatives/TemplateSelect";
import { Rnd } from "react-rnd";

import "video-react/dist/video-react.css"; // import css
import LoadingImage from "assets/images/utility/loading.png";
import MemoizedImageRow from "components/core/blocks/ImageRow";
import {
  getAllVideoProjects,
  uploadAsset,
  getAssetSrcUrl,
  VideoEditorProjectState,
} from "utils/assetsUtils";
import Joyride from "react-joyride";

let URL_PREFIX = getURLPrefix();

const PROJ_LAST_SAVED = "Project last saved at ";
const CLICK_TO_ENTER_TEXT = "Click to edit this text and write your message";
// const DEFAULT_WIDTH_PER_SECOND = 80; // 83;
// const DEFAULT_TOTAL_SECONDS = 20;
// const DEFAULT_IMAGE_WIDTH = 200;
// const DEFAULT_TIMELINE_IMAGE_WIDTH = 80;
// const DEFAULT_VIDEO_WIDTH = 200;
const DEFAULT_BACKGROUND_VIDEO_WIDTH = 800; // 570;
const DEFAULT_BACKGROUND_VIDEO_HEIGHT = 410;
// const DEFAULT_PREVIEW_VIDEO_WIDTH = 570;
// const DEFAULT_CAROUSEL_VIDEO_WIDTH = 400;
// const DEFAULT_ASSET_VIDEO_WIDTH = 120;
// const DEFAULT_ASSET_VIDEO_HEIGHT = 120;
const DEFAULT_ASSET_IMAGE_WIDTH = 120;
const DEFAULT_ASSET_IMAGE_HEIGHT = 120;
// const DEFAULT_ASSET_DISPLAY_VIDEO_WIDTH = 120;
const DEFAULT_ASSET_DISPLAY_IMAGE_WIDTH = 120;

const CONST_ASSET_UPLOADING_TITLE = "Uploading asset";
const CONST_ASSET_UPLOADING_MESSAGE = "Uploading...";

// const TOOLBAR_SELECT_THEME = 0;
const TOOLBAR_SELECT_BACKGROUNDS = 1;
const TOOLBAR_SELECT_IMAGES = 2;
const TOOLBAR_SELECT_TEXT = 3;
const TOOLBAR_SELECT_PREVIEW = 4;
// const TOOLBAR_SELECT_COLOR_PICKER = 5;

const styleRndTimeline = {
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderLeft: "solid 1px #ddd",
  borderRight: "solid 1px #ddd",
  borderTop: "solid 1px #ddd",
  borderBottom: "solid 1px #ddd",
  background: "#f0f0f0",
  height: "100%",
};

const CONST_TEXT_SCROLL_BACKGROUND_COLOR = "rgb(230,204,224)";
const CONST_TEXT_SCROLL_TEXT_COLOR = "#d91266";

const styleRndTextscroll3 = {
  display: "flex",
  userSelect: "none",
  borderRadius: "5px",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  borderLeft: "solid 2px #ddd",
  borderRight: "solid 2px #ddd",
  borderTop: "solid 2px #ddd",
  borderBottom: "solid 2px #ddd",
  background: CONST_TEXT_SCROLL_BACKGROUND_COLOR, // "#FF7AAB", // "white", // "#d91266", // "#778899", // "#f0f0f0",
  height: "100%",
  cursor: "pointer",
};

const styleRndTextscroll3Selected = {
  display: "flex",
  userSelect: "none",
  borderRadius: "5px",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  borderLeft: "solid 2px rgb(98, 64, 127)", // #d9126", // 6rgb(98, 64, 127)", // #00ff00",
  borderRight: "solid 2px rgb(98, 64, 127)", // #d91266", // rgb(98, 64, 127)", // #00ff00",
  borderTop: "solid 2px rgb(98, 64, 127)", //  #d91266", // rgb(98, 64, 127)", // #00ff00",
  borderBottom: "solid 2px rgb(98, 64, 127)", //  #d91266", // rgb(98, 64, 127)", // #00ff00",
  background: CONST_TEXT_SCROLL_BACKGROUND_COLOR, // "#FF7AAB", // "white", // "#d91266", // "#778899", // "#f0f0f0",
  height: "100%",
  cursor: "pointer",
};

const stylerForColorSelect = (color = "#ccc", type = "full") => ({
  alignItems: "center",
  display: "flex",
  // remove next 3 lines to display a dot instead
  backgroundColor: color,
  width: "30px", //"90%", //255,
  color: color,
  border: "solid gray 1px",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 20,
    width: 40,
  },
});

class VideoEditor extends Component {
  constructor(props) {
    super(props);

    this.localSelectionIndex = 0;

    this.state = {
      windowWidth: window.innerWidth,
      selectbarWidth: 200,
      selectbarHeight: 5,
      timelineWidth: 200,
      timelineHeight: 22,
      textscrollWidth: 200,
      textscrollHeight: 23,
      textscrollHeight3: 20,
      imagescsrollWidth: 200,
      imagescrollHeight: 42,
      backgroundscsrollWidth: 200,
      backgroundscrollHeight: 92,
      carouselVideoWidth: 450,

      template3: null,
      totalTimeSecs: 0,
      timeline: [],
      objectSelected: [0, 0],
      editText: "",
      listOfTemplates: [
        { name: "Template 123", id: 123 },
        { name: "Template Swish", id: 543 },
      ],
      videoSource: null,
      carouselIndex: 0,
      backgroundAndPreviewTabsKey: "background_tab",
      loadingThumbs: false,
      loadingBackgroundStills: false,
      loadingBackgroundThumbs: false,
      loadingBackgroundOptionsThumbs: false,
      loadingBackgroundOptionsStills: false,
      loadingAssetsThumbs: false,
      loadingConfig: true,
      delayLoading: true,
      lastBackgroundOptionsIndex: -1,
      lastBackgroundVideoIndex: -1,
      lastAssetIndex: -1,
      showProgress: false,
      resetVideoEditorDisplay: false,
      projectNameSelected: false,
      projectNameErrorMessage: "",
      projectState: VideoEditorProjectState.PROJ_STATE_SELECT_NEW_TEMPLATE, // PROJ_STATE_EDITING,
      projectLastSaved: new Date().toString().split("GMT")[0],
      publishInProgress: false,
      previewProgress: 3,
      previewProgressStarted: false,
      previewIntervalObject: null,
      previewLaunched: false,
      previewLastLaunchDate: "",
      previousPreviewLastLaunchDate: "",
      showPreviewModal: false,
      // exportProcessingStarted: false,
      showPublishModal: false,
      saveConfigDelayTimer: null,
      templateSelectedKey: "",
      projectName: "",
      projectDescription: "",
      backgroundVideoStills: {},
      backgroundThumbnails: {}, // [proj_name][index]=thumb_src
      backgroundOptionsThumbnails: {}, // [proj_name][background_key]={ color1: thumb_src, color2: thumb_src, etc }
      backgroundOptionsStills: {}, // [proj_name][background_key][color] = []
      assetLoaded: false,
      backgroundVideoPoster: {},
      toolbarSelect: TOOLBAR_SELECT_BACKGROUNDS,
      selectionIndex:
        this.state &&
        this.state.template3 &&
        this.state.template3.config &&
        this.state.template3.config.backgroundClips &&
        this.state.template3.config.backgroundClips.findIndex(
          (clip) =>
            clip.type !== "color" ||
            (clip.type === "color" &&
              clip.colorOptions &&
              clip.colorOptions.length !== 1)
        ),
      textEditSelectionIndex: -1,
      imageRowList: {}, // [projName][key] or [projName][key][color]
      assets: [],
      assetsThumbnails: {}, // [assetname] = url
      showGenericSpinnerModal: false,
      genericSpinnerModalTitle: "",
      genericSpinnerModalMessage: "",
      handleNotification: this.props.handleNotification,
      showColorPickerThemeSelector: false,
      showThemeColorPicker: false,
      showThemeColorPickerModal: false,
      showAssetsModal: false,
      steps: [
        {
          content: (
            <div>
              <h5>Welcome to Trellis Video Creation Tour.</h5>
              <p>Click Next to start the tour,</p>
              <p>you can click skip at anytime to end the tour.</p>
              <p>
                <strong>Let's get started!</strong>
              </p>
            </div>
          ),

          target: "body",
          placement: "center",
        },
        {
          target: ".timeline_help_target",
          content:
            "Select text, images or clips on the timeline to view and customize your video",
          placement: "right",
        },
        {
          target: ".left_view",
          content:
            "The left panel will contain image or video assets which you can select to customize your video. Here you can  also upload assets. Click on the timeline to indicate the image or video time slot being changed.",
        },
        {
          target: ".middle_display_help_target",
          content:
            "The main display shows images, video and text as selected in the timeline.",
          placement: "top",
        },
        {
          target: ".right_display_help_target",
          content:
            "The right display shows time start and duration of selected timeline items as well as some additional editable parameters.",
          placement: "left",
        },
        {
          target: ".theme_button_div",
          content: "Select available themes that best match your brand.",
          placement: "bottom",
        },
        {
          target: ".main-preview-button",
          content:
            "Once you've customized your theme, background, images and text you're ready to see what your video ad looks like, click this button to get your video prepared in just a few minutes or less.",
          placement: "right",
        },
        {
          target: ".main-publish-button",
          content: (
            <div>
              <p>
                Publish is where your video gets a final rendering and gets sent
                to Amazon for approval.
              </p>
              <p>
                But you don't have to stop there, create as many Video Ad
                projects as you like.
              </p>
            </div>
          ),
        },
      ],
      runTour: false,
      mainTextEditRef: null,
      showHelpTextMiddle: false,
      showHelpGetStarted: false,
      showSelectNewTemplateModal: false,
    };
    this.handleProjectStateFromExternal();

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    this.getTemplates();

    window.addEventListener("resize", this.handleWindowResize);
    if (this.state.windowWidth !== window.innerWidth) {
      this.setState({ windowWidth: window.innerWidth });
    }

    // let previewId = localStorage.getItem("previewInProgressId");
    // if(previewId) {
    // this.launchPreviewConfig(true, null, true);
    // }
  }

  restorePreview() {
    //let id = parseInt(localStorage.getItem("previewInProgressId"));
    this.launchPreviewConfig(true);
  }

  handleWindowResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  getTemplates = () => {
    axios.get(URL_PREFIX + "/creatives/media/templates").then((res) => {
      this.setState({ templates: res.data });
    });
  };

  createUserConfig(
    projectName = "",
    projectDescription = "",
    templateName = ""
  ) {
    let useProjectName = projectName;
    if (!useProjectName) {
      useProjectName = this.state.projectName;
    }
    let useProjectDescription = projectDescription;
    if (!useProjectDescription) {
      useProjectDescription = this.state.projectDescription;
    }
    let useTemplateName = templateName;
    if (!useTemplateName) {
      useTemplateName = this.state.templateSelectedKey;
    }
    const data = new FormData();
    data.append("project_name", useProjectName);
    data.append("project_description", useProjectDescription);
    data.append("market_place", this.props.marketPlace.marketPlace);

    axios
      .post(
        URL_PREFIX + "/creatives/media/video?template_name=" + useTemplateName,
        data
      )
      .then((res) => {
        // initialize video stills and thumbnails
        var stills = this.state.backgroundVideoStills;
        stills[useProjectName] = {};
        var optionsStills = this.state.backgroundOptionsStills;
        optionsStills[useProjectName] = {};
        var optionsThumbs = this.state.backgroundOptionsThumbnails;
        var backgroundThumbs = this.state.backgroundThumbnails;
        optionsThumbs[useProjectName] = {};
        backgroundThumbs[useProjectName] = [];
        var lastBackgroundVideoIndex = -1;
        var lastOptionsIndex = -1;
        for (let i = 0; i < res.data.config.backgroundClips.length; i++) {
          let clip = res.data.config.backgroundClips[i];
          if (clip.type === "video") {
            stills[useProjectName][clip.key] = [];
            optionsStills[useProjectName][clip.key] = {};
            optionsThumbs[useProjectName][clip.key] = {};
            lastBackgroundVideoIndex = i;
          }
          if (clip.videoSrcOptions && clip.videoSrcOptions.length > 0) {
            lastOptionsIndex = i;
          }
        }

        let loadingOptions = lastOptionsIndex >= 0;
        let loadingBackground = lastBackgroundVideoIndex >= 0;

        var totalTime = res.data.config.duration;
        let timeline = [];
        for (let i = 0; i < totalTime; i++) {
          timeline.push(i + 1);
        }
        this.setState(
          {
            totalTimeSecs: totalTime,
            timeline: timeline,
            // showProgress: lastBackgroundVideoIndex > -1 ? true : false,
            loadingThumbs: true,
            loadingBackgroundStills: loadingBackground,
            loadingBackgroundThumbs: loadingBackground,
            loadingBackgroundOptionsThumbs: loadingOptions,
            loadingBackgroundOptionsStills: loadingOptions,
            loadingAssetsThumbs: true,
            lastBackgroundOptionsIndex: lastOptionsIndex,
            lastAssetIndex: this.props.assets.length,
            backgroundVideoStills: stills,
            lastBackgroundVideoIndex: lastBackgroundVideoIndex,
            backgroundOptionsStills: optionsStills,
            backgroundOptionsThumbnails: optionsThumbs,
            backgroundThumbnails: backgroundThumbs,
            template3: res.data,
            projectLastSaved: new Date().toString().split("GMT")[0],
          },
          () => {
            this.initLoadThumbnails();
            // this.loadImageStills();
            this.updateProjectState(VideoEditorProjectState.PROJ_STATE_EDITING);
            if (this.props.updateProjectToAssetManagement) {
              let p = [res.data["id"], useProjectName, "wip", res.data.config];
              this.props.updateProjectToAssetManagement(p);
            }
          }
        );
      })
      .catch((e) => {
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to create a project. Check project name for duplicate"
        );
        this.updateProjectState(
          VideoEditorProjectState.PROJ_STATE_REENTER_PROJECT_NAME
        );
      });
  }

  loadExistingUserConfig(editProject) {
    this.setState({ delayLoading: true });

    let useProjectName = editProject.name;
    localStorage.setItem("videoEdit", useProjectName);

    // initialize video stills and thumbnails
    var stills = {};
    stills[useProjectName] = {};
    var optionsStills = {};
    optionsStills[useProjectName] = {};
    var optionsThumbs = {};
    var backgroundThumbs = {};
    optionsThumbs[useProjectName] = {};
    backgroundThumbs[useProjectName] = [];
    var lastVideoIndex = -1;
    var lastOptionsIndex = -1;

    for (let i = 0; i < editProject.config.backgroundClips.length; i++) {
      let clip = editProject.config.backgroundClips[i];
      if (clip.type === "video") {
        stills[useProjectName][clip.key] = [];
        optionsStills[useProjectName][clip.key] = {};
        optionsThumbs[useProjectName][clip.key] = {};
        lastVideoIndex = i;
      }
      if (clip.videoSrcOptions && clip.videoSrcOptions.length > 0) {
        lastOptionsIndex = i;
        optionsStills[useProjectName][clip.key] = {};
        optionsThumbs[useProjectName][clip.key] = {};
      }
    }

    var totalTime = editProject.config.duration;
    let timeline = [];
    for (let i = 0; i < totalTime; i++) {
      timeline.push(i + 1);
    }
    this.setState(
      {
        totalTimeSecs: totalTime,
        timeline: timeline,
        // showProgress: lastVideoIndex > -1 ? true : false,
        loadingThumbs: lastVideoIndex >= 0,
        loadingBackgroundStills: lastVideoIndex >= 0,
        loadingBackgroundThumbs: lastVideoIndex >= 0,
        loadingBackgroundOptionsThumbs: lastOptionsIndex >= 0,
        loadingBackgroundOptionsStills: lastOptionsIndex >= 0,
        loadingAssetsThumbs: true,
        lastBackgroundOptionsIndex: lastOptionsIndex,
        backgroundVideoStills: stills,
        lastBackgroundVideoIndex: lastVideoIndex,
        backgroundOptionsStills: optionsStills,
        backgroundOptionsThumbnails: optionsThumbs,
        backgroundThumbnails: backgroundThumbs,
        loadingConfig: false,
        projectLastSaved: new Date().toString().split("GMT")[0],
      },
      () => {
        this.initLoadThumbnails();
        this.loadImageStills();
        this.updateProjectState(VideoEditorProjectState.PROJ_STATE_EDITING);
        setTimeout(() => {
          this.setState({
            delayLoading: false,
            resetVideoEditorDisplay: false,
            showProgress: false,
          });
        }, 5 * 1000);
      }
    );
  }

  delaySaveConfig(secs = 10) {
    let t = this.state.saveConfigDelayTimer;
    if (t) {
      clearTimeout(t);
    }
    t = setTimeout(this.saveUserConfig(), secs * 1000);
    this.setState({ saveConfigDelayTimer: t });
  }

  saveUserConfig = (updateUrls = false, cb = null) => {
    let updateUrlsPreffix = updateUrls ? "&urls=true" : "";
    axios
      .put(
        URL_PREFIX + "/creatives/media/video?update=true" + updateUrlsPreffix,
        {
          id: this.state.template3.id,
          config: this.state.template3.config,
        }
      )
      .then((res) => {
        let templ = this.state.template3;
        if (res && res.data && res.data.config) {
          templ.config = res.data.config;
        } else if (updateUrls) {
          console.log(
            "Warning: save user config did not update Urls when requested"
          );
        }

        this.setState(
          {
            template3: templ,
            projectLastSaved: new Date().toString().split("GMT")[0],
          },
          () => {
            if (cb) {
              cb(true);
            }
          }
        );

        if (this.props.updateProjectToAssetManagement) {
          let p = [
            this.state.template3.id,
            this.state.projectName,
            "wip",
            templ.config,
          ];
          this.props.updateProjectToAssetManagement(p);
        }
      })
      .catch((e) => {
        this.setState({ showProgress: false });
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Auto save failed. Will try again in 30secs."
        );
        this.delaySaveConfig(30, true);
        if (cb) {
          cb(false);
        }
      });
  };

  launchPreviewConfig = (
    showPreviewModal = false,
    cb = null,
    restore = false
  ) => {
    if (this.state.previewLaunched) {
      this.props.handleNotification(
        "tr",
        "warning",
        "pe-7s-attention",
        "Preview already launched, and is currently in progress."
      );
      return;
    }
    this.setState(
      {
        previewProgress: 0,
        // exportProcessingStarted: false,
        showPreviewModal: showPreviewModal,
      },
      () => {
        let prevLastPreview = this.state.previewLastLaunchDate;
        let intervalObj = setInterval(() => {
          this.getPreviewStatus((success) => {
            if (cb) {
              cb(success);
            }
          });
        }, 5000);

        this.setState({
          previewLaunched: true,
          previousPreviewLastLaunchDate: prevLastPreview,
          previewLastLaunchDate: new Date().toString().split("GMT")[0],
          previewIntervalObject: intervalObj,
        });

        if (!restore) {
          localStorage.setItem("previewInProgressId", this.state.template3.id);
          axios
            .put(URL_PREFIX + "/creatives/media/video?preview=true", {
              id: this.state.template3.id,
              // config: this.state.template3.config,
            })
            .then((res) => {})
            .catch((e) => {
              clearInterval(this.state.previewIntervalObject);
              this.setState({
                previewProgress: 0,
                previewLaunched: false,
                previewLastLaunchDate: this.state.previousPreviewLastLaunchDate,
              });
              this.props.handleNotification(
                "tr",
                "warning",
                "pe-7s-attention",
                "Preview failed. Please try again. If issue continues please contact Trellis.",
                "long"
              );
              if (cb) {
                cb(false);
              }
            });
        }
      }
    );
  };

  getPreviewStatus = (cb) => {
    axios
      .get(URL_PREFIX + "/creatives/media/video?id=" + this.state.template3.id)
      .then((res) => {
        let progress = this.state.previewProgress;
        if (progress < 5) {
          progress = 2;
        }
        if (
          res.data &&
          res.data.progress &&
          res.data.progress.current &&
          res.data.progress.total
        ) {
          progress =
            (res.data.progress.current / res.data.progress.total) * 100;
        }
        let status;
        if (res.data && res.data.status) {
          status = res.data.status;
        }
        if (status === "Failed") {
          clearInterval(this.state.previewIntervalObject);
          localStorage.removeItem("previewInProgressId");
          this.setState({
            previewLaunched: false,
          });
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "Preview failed. Please try again. If issue continues please contact Trellis.",
            "long"
          );
          if (cb) {
            cb(false);
          }
          return;
        }

        // if(!this.state.exportProcessingStarted){
        //   if(status === "Exporting"){
        //     this.setState({exportProcessingStarted: true});
        //   }else{
        //     return
        //   }
        // }

        if (progress < 100 && status !== "Exported") {
          this.setState({
            previewProgress: progress,
          });
        } else {
          // 100%
          localStorage.removeItem("previewInProgressId");

          if (status !== "Exported") {
            // not done yet
            this.setState({
              previewProgress: progress,
            });
            return;
          }
          clearInterval(this.state.previewIntervalObject);
          let templ = this.state.template3;
          if (status === "Exported") {
            templ.config.videoSrc = res.data.url;
          }
          this.setState(
            {
              previewIntervalObject: null,
              previewProgress: progress,
              previewLaunched: false,
              template3: templ,
              showPreviewModal: false,
            },
            () => {
              if (status === "Exported") {
                this.loadImageStills();
                if (this.props.updateProjectToAssetManagement) {
                  let p = [
                    this.state.template3.id,
                    this.state.projectName,
                    "wip",
                    this.state.template3.config,
                  ];
                  this.props.updateProjectToAssetManagement(p);
                }
                if (cb) {
                  cb(true);
                }
              }
            }
          );
        }
      })
      .catch((e) => {
        this.setState({
          previewProgress: 0,
          previewLaunched: false,
          previewLastLaunchDate: this.state.previousPreviewLastLaunchDate,
        });
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Preview failed. Please try again. If issue continues please contact Trellis.",
          "long"
        );
      });
  };

  showGenericSpinner(show, title, msg) {
    this.setState({
      showGenericSpinnerModal: show,
      genericSpinnerModalTitle: title,
      genericSpinnerModalMessage: msg,
    });
  }

  showUploadingAssetsSpinner(show = true) {
    let title = CONST_ASSET_UPLOADING_TITLE;
    let msg = CONST_ASSET_UPLOADING_MESSAGE;
    if (!show) {
      title = "";
      msg = "";
    }
    this.showGenericSpinner(show, title, msg);
  }

  getAssets(initialLoading = false) {
    axios
      .get(URL_PREFIX + "/creatives/media/assets")
      .then((res) => {
        let lastAssetVideo = -1;
        for (let i = 0; i < res.data.length; i++) {
          let asset = res.data[i];
          if (asset.type === "video") {
            lastAssetVideo = i;
          }
        }
        let loading = this.state.loadingAssetsThumbs;
        if (lastAssetVideo <= -1) {
          loading = false;
        }
        this.setState(
          {
            assets: res.data,
            loadingAssetsThumbs: loading,
            lastAssetIndex: lastAssetVideo,
          },
          () => {
            for (let i = 0; i < res.data.length; i++) {
              let asset = res.data[i];
              if (asset.type === "video") {
                this.extractThumbnailsFromAssets(asset, i);
              }
            }
          }
        );
      })
      .catch((error) => {
        console.log("Get assets failed with error:", error);
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to get assets. If issue continues please contact Trellis.",
          "long"
        );
      });
  }

  getPreview = () => {
    axios
      .post(URL_PREFIX + "/creatives/media", this.state.template3, {})
      .then((res) => {
        this.setState({ template3: res.data }, () => {});
      });
  };

  publish = () => {
    const beData = { id: this.state.template3.id };
    axios
      .put(URL_PREFIX + "/creatives/media/video?publish=true", beData)
      .then((res) => {
        if (this.props.updateProjectToAssetManagement) {
          let p = [
            this.state.template3.id,
            this.state.projectName,
            "published",
            this.state.template3.config,
          ];
          this.props.updateProjectToAssetManagement(p);
        }

        this.setState({ showPublishModal: false }, () => {
          // close and go to asset mgr
          this.setState({ publishInProgress: false }, () => {
            this.updateProjectState(
              VideoEditorProjectState.PROJ_STATE_SELECT_NEW_TEMPLATE,
              () => this.props.closeVideoEditorTab()
            );
          });
        });
      })
      .catch((e) => {
        this.setState({
          previewProgress: 0,
          previewLaunched: false,
          previewLastLaunchDate: this.state.previousPreviewLastLaunchDate,
        });
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Publish failed. Please try again. If issue continues please contact Trellis.",
          "long"
        );
      });
  };

  updateProjectState(proposedNextStatus, cb) {
    let thecb = () => {
      if (cb) {
        cb();
      }
    };

    if (proposedNextStatus === VideoEditorProjectState.PROJ_STATE_INTERRUPT) {
      this.setState(
        { projectState: VideoEditorProjectState.PROJ_STATE_INTERRUPT },
        () => thecb()
      );
      return;
    }
    if (this.state.projectState === proposedNextStatus) {
      return;
    }

    if (
      proposedNextStatus ===
      VideoEditorProjectState.PROJ_STATE_SELECT_NEW_TEMPLATE
    ) {
      this.setState(
        {
          projectName: "",
          projectDescription: "",
          projectState: proposedNextStatus,
          toolbarSelect: TOOLBAR_SELECT_BACKGROUNDS,
          selectionIndex:
            this.state.template3 && this.state.template3.config
              ? this.state.template3.config.backgroundClips.findIndex(
                  (clip) =>
                    clip.type !== "color" ||
                    (clip.type === "color" &&
                      clip.colorOptions &&
                      clip.colorOptions.length !== 1)
                )
              : 0,
        },
        () => thecb()
      );
    } else {
      this.setState(
        {
          projectState: proposedNextStatus,
          toolbarSelect: TOOLBAR_SELECT_BACKGROUNDS,
          selectionIndex:
            this.state.template3 && this.state.template3.config
              ? this.state.template3.config.backgroundClips.findIndex(
                  (clip) =>
                    clip.type !== "color" ||
                    (clip.type === "color" &&
                      clip.colorOptions &&
                      clip.colorOptions.length !== 1)
                )
              : 0,
        },
        () => thecb()
      );
    }
    return;
  }

  calculateWidthPerSecond(totalTimeSecs) {
    let useWindowWidth = this.state.windowWidth - 180;
    let useWidthPerSecond = useWindowWidth / totalTimeSecs;
    return useWidthPerSecond;
  }

  selectEnterText(widthPx) {
    if (widthPx > 350) {
      return CLICK_TO_ENTER_TEXT;
    }
    if (widthPx > 100) {
      return "Enter text";
    }
    if (widthPx > 50) {
      return "Text";
    }
    return "T";
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  loadImageStills() {
    this.extractImageStillsFromBackgroundVideo(null, 0, false);
  }

  cloneCanvas(oldCanvas) {
    var newCanvas = document.createElement("canvas");
    var context = newCanvas.getContext("2d");
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    context.drawImage(oldCanvas, 0, 0);
    return newCanvas;
  }

  async extractImageStillsFromBackgroundVideo(
    updateBackgroundVideoSrc,
    updateBackgroundIndex,
    save = true,
    saveAssetThumb = false
  ) {
    var clip =
      this.state.template3.config.backgroundClips[updateBackgroundIndex];
    let useVideoSrc = !updateBackgroundVideoSrc
      ? clip.videoSrc
      : updateBackgroundVideoSrc;
    var templ = this.state.template3;

    var updatedBackgroundPoster;
    var updatedVideoStills = [];
    var updatedThumb;
    var updatedAssetThumb;
    let video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.onloadedmetadata = (e) => {
      video.currentTime = 0.1;
    };

    var seekTimer = null;
    const nextStill = async (video, nextTime) => {
      if (seekTimer) {
        clearTimeout(seekTimer);
        seekTimer = null;
      }
      await this.sleep(200);
      seekTimer = setTimeout(() => {
        nextStill(video, nextTime + 0.1);
      }, 12 * 1000);
      video.currentTime = nextTime;
    };

    video.onseeked = () => {
      if (seekTimer) {
        clearTimeout(seekTimer);
        seekTimer = null;
      }
      let seekCount = Math.round(video.currentTime);
      let canvas = document.createElement("canvas");
      let videoHeight = video.videoHeight; // 100;
      let videoWidth = video.videoWidth; // 100;
      let destHeight = this.state.backgroundscrollHeight;
      let ratio =
        Math.min(video.videoWidth, video.videoHeight) /
        Math.max(video.videoWidth, video.videoHeight);
      let destWidth = destHeight / ratio;
      canvas.height = destHeight;
      canvas.width = destWidth;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(
        video,
        0,
        0,
        videoWidth,
        videoHeight,
        0,
        0,
        destWidth,
        destHeight
      );

      let src = canvas.toDataURL();
      if (!updatedThumb) {
        // just 1 thumb per video
        let copy = canvas.cloneNode();
        copy.getContext("2d").drawImage(canvas, 0, 0);
        updatedThumb = copy.toDataURL();
        copy = null;
      }
      if (!updatedBackgroundPoster) {
        let clonedCanvas = this.cloneCanvas(canvas);
        updatedBackgroundPoster = clonedCanvas.toDataURL();
        clonedCanvas = null;
      }
      if (saveAssetThumb && !updatedAssetThumb) {
        let copy = canvas.cloneNode();
        copy.getContext("2d").drawImage(canvas, 0, 0);
        updatedAssetThumb = copy.toDataURL();
        copy = null;
      }
      canvas = null;

      let imgObj = document.createElement("img");
      imgObj.src = src;
      if (updatedVideoStills.length <= seekCount) {
        while (seekCount >= updatedVideoStills.length) {
          updatedVideoStills.push(imgObj);
        }
      } else {
        updatedVideoStills[seekCount] = imgObj;
      }
      if (++seekCount < Math.round(video.duration)) {
        nextStill(video, seekCount);
      } else {
        // no more stills, update main video src and stills
        if (updateBackgroundVideoSrc) {
          templ.config.backgroundClips[updateBackgroundIndex].videoSrc =
            updateBackgroundVideoSrc;
        }

        let thumbs = this.state.backgroundThumbnails;
        while (updateBackgroundIndex >= thumbs[this.state.projectName].length) {
          thumbs[this.state.projectName].push(null);
        }
        thumbs[this.state.projectName][updateBackgroundIndex] = updatedThumb;

        var allPosters = this.state.backgroundVideoPoster;
        allPosters["" + updateBackgroundIndex] = updatedBackgroundPoster;
        var allStills = this.state.backgroundVideoStills;
        allStills[this.state.projectName][clip.key] = updatedVideoStills;
        let assetsThumbs = this.state.assetsThumbnails;
        if (saveAssetThumb && updatedAssetThumb) {
          assetsThumbs[clip.videoKey] = updatedAssetThumb;
        }

        let loading = this.state.loadingBackgroundStills;
        if (
          loading &&
          this.state.lastBackgroundVideoIndex === updateBackgroundIndex
        ) {
          loading = false;
        }
        this.setState(
          {
            template3: templ,
            backgroundVideoStills: allStills,
            backgroundVideoPoster: allPosters,
            // showProgress: progress,
            loadingBackgroundStills: loading,
            backgroundThumbnails: thumbs,
            loadingBackgroundThumbs: loading,
            assetsThumbnails: assetsThumbs,
          },
          () => {
            if (save) {
              this.saveUserConfig();
            }
            if (
              updateBackgroundIndex + 1 <
              this.state.template3.config.backgroundClips.length
            ) {
              this.extractImageStillsFromBackgroundVideo(
                null,
                updateBackgroundIndex + 1,
                false
              );
            }
          }
        );
      }
    };

    video.onerror = (error) => {
      console.log("Video on error:", error);
    };
    video.src = useVideoSrc;
  }

  initLoadThumbnails() {
    for (
      let i = 0;
      i < this.state.template3.config.backgroundClips.length;
      i++
    ) {
      var clip = this.state.template3.config.backgroundClips[i];
      if (clip.type !== "video") {
        continue;
      }
      if (clip.videoSrcOptions && clip.videoSrcOptions.length > 0) {
        for (let c = 0; c < clip.videoSrcOptions.length; c++) {
          this.extractThumbnailsFromBackgroundOptions(i, c);
          this.extractImageractStillsFromBackgroundOptions(i, c);
        }
      }
    }
    this.getAssets(true);
  }

  async extractThumbnailsFromBackgroundOptions(
    updateBackgroundIndex,
    optionsIndex
  ) {
    var clip =
      this.state.template3.config.backgroundClips[updateBackgroundIndex];

    var templColor =
      this.state.template3.config.templateColorOptions[optionsIndex];

    let useVideoSrc = clip.videoSrcOptions[optionsIndex];
    let video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.onloadedmetadata = (e) => {
      video.currentTime = 0.1;
    };

    video.onseeked = () => {
      var canvas = document.createElement("canvas");
      let videoHeight = video.videoHeight; // 100;
      let videoWidth = video.videoWidth; // 100;
      let destHeight = DEFAULT_BACKGROUND_VIDEO_HEIGHT / 2;
      let ratio =
        Math.min(video.videoWidth, video.videoHeight) /
        Math.max(video.videoWidth, video.videoHeight);
      let destWidth = destHeight / ratio;
      canvas.height = destHeight;
      canvas.width = destWidth;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(
        video,
        0,
        0,
        videoWidth,
        videoHeight,
        0,
        0,
        destWidth,
        destHeight
      );
      let src = canvas.toDataURL();

      let thumbs = this.state.backgroundOptionsThumbnails;
      thumbs[this.state.projectName][clip.key][templColor] = src;

      let loading = this.state.loadingBackgroundOptionsThumbs;
      if (
        loading &&
        this.state.template3.config.backgroundClips[updateBackgroundIndex]
          .videoSrcOptions.length -
          1 ===
          optionsIndex
      ) {
        loading = false;
      }
      this.setState({
        backgroundOptionsThumbnails: thumbs,
        loadingBackgroundOptionsThumbs: loading,
      });
    };

    video.onerror = (error) => {
      console.log("Video on error:", error);
    };
    video.src = useVideoSrc;
  }

  async extractImageractStillsFromBackgroundOptions(
    updateBackgroundIndex,
    optionsIndex
  ) {
    var clip =
      this.state.template3.config.backgroundClips[updateBackgroundIndex];

    if (!clip.videoSrcOptions || clip.videoSrcOptions.length <= 0) {
      return; // nothing to do
    }

    var templColor =
      this.state.template3.config.templateColorOptions[optionsIndex];

    let useVideoSrc = clip.videoSrcOptions[optionsIndex];

    var updatedVideoStills = [];
    let video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.onloadedmetadata = (e) => {
      video.currentTime = 0.1;
    };

    var seekTimer = null;

    const nextStill = async (video, nextTime) => {
      if (seekTimer) {
        clearTimeout(seekTimer);
        seekTimer = null;
      }
      await this.sleep(200);
      seekTimer = setTimeout(() => {
        nextStill(video, nextTime + 0.1);
      }, 12 * 1000);
      video.currentTime = nextTime;
    };

    video.onseeked = () => {
      if (seekTimer) {
        clearTimeout(seekTimer);
        seekTimer = null;
      }

      let seekCount = Math.round(video.currentTime);
      var canvas = document.createElement("canvas");
      let videoHeight = video.videoHeight;
      let videoWidth = video.videoWidth;
      var ctx = canvas.getContext("2d");
      let destHeight = this.state.backgroundscrollHeight;
      let ratio =
        Math.min(video.videoWidth, video.videoHeight) /
        Math.max(video.videoWidth, video.videoHeight);
      let destWidth = destHeight / ratio;
      canvas.height = destHeight;
      canvas.width = destWidth;
      ctx.drawImage(
        video,
        0,
        0,
        videoWidth,
        videoHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
      let src = canvas.toDataURL();

      let imgObj = document.createElement("img");
      imgObj.src = src;

      if (updatedVideoStills.length <= seekCount) {
        while (seekCount >= updatedVideoStills.length) {
          updatedVideoStills.push(imgObj);
        }
      } else {
        updatedVideoStills[seekCount] = imgObj;
      }

      if (++seekCount < Math.round(video.duration)) {
        nextStill(video, seekCount);
      } else {
        var allStills = this.state.backgroundOptionsStills;
        allStills[this.state.projectName][clip.key][templColor] =
          updatedVideoStills;

        let loading = this.state.loadingBackgroundOptionsStills;
        if (
          loading &&
          this.state.lastBackgroundOptionsIndex === updateBackgroundIndex &&
          optionsIndex === clip.videoSrcOptions.length - 1
        ) {
          loading = false;
          // this.loadImageStills(); // TRY SERIAL LOAD
        }
        this.setState({
          backgroundOptionsStills: allStills,
          loadingBackgroundOptionsStills: loading,
        });
      }
    };

    video.onerror = (error) => {
      console.log("Video on error:", error);
    };
    video.src = useVideoSrc;
  }

  async extractThumbnailsFromBackgroundSrc(updateBackgroundIndex) {
    var clip =
      this.state.template3.config.backgroundClips[updateBackgroundIndex];

    let useVideoSrc = clip.videoSrc;
    let video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.onloadedmetadata = (e) => {
      video.currentTime = 0.1;
    };

    video.onseeked = () => {
      var canvas = document.createElement("canvas");
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(
        video,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      let src = canvas.toDataURL();

      let thumbs = this.state.backgroundThumbnails;
      if (updateBackgroundIndex >= thumbs[this.state.projectName].length) {
        while (updateBackgroundIndex >= thumbs[this.state.projectName].length) {
          thumbs[this.state.projectName].push(src);
        }
      } else {
        thumbs[this.state.projectName][updateBackgroundIndex] = src;
      }
      let loading = this.state.loadingBackgroundThumbs;

      if (
        loading &&
        this.state.lastBackgroundVideoIndex === updateBackgroundIndex
      ) {
        loading = false;
      }

      this.setState({
        backgroundThumbnails: thumbs,
        loadingBackgroundThumbs: loading,
      });
    };

    video.onerror = (error) => {
      console.log("Video on error:", updateBackgroundIndex, error);
    };
    video.src = useVideoSrc;
  }

  async extractThumbnailsFromAssets(asset, assetIndex, updateAssets = false) {
    let useVideoSrc = asset.url;
    let video = document.createElement("video");
    video.crossOrigin = "anonymous";
    var seekTimer = null;
    video.onloadedmetadata = (e) => {
      let assets = this.state.assets;
      assets[assetIndex]["resolution"] = [video.videoWidth, video.videoHeight];
      this.setState({ assets: assets });
      video.currentTime = 0.1;
      seekTimer = setTimeout(() => {
        video.currentTime = 0.3;
      }, 12 * 1000);
    };

    video.onseeked = () => {
      if (seekTimer) {
        clearTimeout(seekTimer);
        seekTimer = null;
      }
      var canvas = document.createElement("canvas");
      let videoHeight = video.videoHeight;
      let videoWidth = video.videoWidth;
      var ctx = canvas.getContext("2d");
      let destHeight = this.state.backgroundscrollHeight;
      let ratio =
        Math.min(video.videoWidth, video.videoHeight) /
        Math.max(video.videoWidth, video.videoHeight);
      let destWidth = destHeight / ratio;
      canvas.height = destHeight;
      canvas.width = destWidth;
      ctx.drawImage(
        video,
        0,
        0,
        videoWidth,
        videoHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
      let src = canvas.toDataURL();

      let thumbs = this.state.assetsThumbnails;
      thumbs[asset.name] = src;

      let loading = this.state.loadingAssetsThumbs;

      if (loading && this.state.lastAssetIndex === assetIndex) {
        loading = false;
      }

      this.setState({
        assetsThumbnails: thumbs,
        loadingAssetsThumbs: loading,
      });

      if (updateAssets) {
        this.setState({ assets: [...this.state.assets] });
      }
    };

    video.onerror = (error) => {
      console.log("Video on error:", asset.name, error);
    };
    video.src = useVideoSrc;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.handleProjectStateFromExternal();
  }

  handleProjectStateFromExternal() {
    if (
      !this.props.newProjectState ||
      this.props.newProjectState === VideoEditorProjectState.PROJ_STATE_NONE
    ) {
      // check if there is an editProject in local storage that should be opened
      if (localStorage.getItem("videoEdit")) {
        let findProjName = localStorage.getItem("videoEdit");
        getAllVideoProjects(this.props.marketPlace.marketPlace, (projects) => {
          if (projects && projects.length > 0) {
            let project = projects.find((p) => p.project_name === findProjName);
            let editProject = {
              name: project.project_name,
              id: project.id,
              config: project.config,
            };
            this.initializeEditing(editProject);
          }
        });
      }
      return;
    }

    let newProjectState = this.props.newProjectState;
    if (this.props.clearProjectState) {
      this.props.clearProjectState();
    }
    if (
      newProjectState === VideoEditorProjectState.PROJ_STATE_PREPARE_EDITING
    ) {
      this.initializeEditing(this.props.editProject);
    } else if (
      newProjectState === VideoEditorProjectState.PROJ_STATE_SELECT_NEW_TEMPLATE
    ) {
      this.updateProjectState(
        VideoEditorProjectState.PROJ_STATE_SELECT_NEW_TEMPLATE,
        () => {
          this.setState({ template3: {}, projectName: "" });
        }
      );
    }
  }

  initializeEditing(editProject) {
    if (
      !editProject ||
      !editProject.name ||
      editProject.name === this.state.projectName
    ) {
      return;
    }
    this.updateProjectState(
      VideoEditorProjectState.PROJ_STATE_PREPARE_EDITING,
      () => {
        let templ = this.state.template3;
        templ = !templ ? {} : templ;
        templ["id"] = editProject.id;
        templ["config"] = editProject.config;

        this.setState(
          {
            template3: templ,
            projectName: editProject.name,
            projectDescription: editProject.description,
            previewLaunched: false,
            previewProgress: 0,
            publishInProgress: false,
            showProgress: true,
            resetVideoEditorDisplay: true,
            runTour: false,
          },
          (success) => {
            this.saveUserConfig(true, () => {
              this.loadExistingUserConfig(editProject);
              this.updateProjectState(
                VideoEditorProjectState.PROJ_STATE_EDITING
              );
            });
          }
        );
      }
    );
  }

  imageSrcFromColor(color, width, height) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    ctx.beginPath();
    ctx.fillStyle = "#" + color;
    ctx.fillRect(0, 0, width, height);
    //ctx.fill();
    let src = canvas.toDataURL();
    return src;
  }

  getVideoImage(path, secs, callback) {
    var me = this;
    var video = document.getElementById("video_id");
    video.onseeked = function (e) {
      var canvas = document.getElementById("backgroundscroll_canvas_0");
      canvas.height = 100; //video.videoHeight;
      canvas.width = 100; //video.videoWidth;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      let src = canvas.toDataURL();
      callback.call(src);
    };
    video.onerror = function (error) {
      console.log("Video on error:", error);
      callback.call(me, undefined, undefined, error);
    };
    video.currentTime = secs;
  }

  renderProjectInfo() {
    return !this.state.projectName ? (
      ""
    ) : (
      <>
        <div style={{ marginTop: "1em", fontSize: 18 }}>
          <strong>{this.state.projectName.toUpperCase()}</strong>
        </div>
        <div
          style={{
            marginTop: "0.5em",
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
          }}
        ></div>
      </>
    );
  }

  renderProjectLastSaved() {
    return !this.state.projectName ? (
      ""
    ) : (
      <p
        style={{
          marginTop: "0.5em",
          fontSize: 12,
          color: "gray",
        }}
      >
        {PROJ_LAST_SAVED + this.state.projectLastSaved}
      </p>
    );
  }

  renderTopMiddleHelp() {
    return (
      <>
        <div>
          <div style={{ paddingTop: "5em" }}>This is text</div>
        </div>
      </>
    );
  }

  renderNewProject() {
    return !this.state.projectName && this.props.setTemplateModalShow ? (
      <div className="d-flex justify-content-end">
        <SecondaryButton
          style={{ marginTop: "2.3em" }}
          fontSize="14px"
          tooltip="Preview"
          noWrap={true}
          title={
            <span>
              <i className="fa fa-file-video-o" /> Create New Project{" "}
            </span>
          }
          onClick={() => {
            if (this.props.setTemplateModalShow) {
              this.props.setTemplateModalShow(true);
            }
          }}
        />
      </div>
    ) : (
      ""
    );
  }

  renderTopRight() {
    return (
      <>
        <div key="main_top_right_buttons_div">
          <Row>
            <div style={{ float: "right" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginRight: "1em",
                }}
              >
                <div style={{ flex: "1", margin: "1em" }}>
                  <SecondaryButton
                    style={{ marginTop: "1em" }}
                    fontSize="14px"
                    tooltip="View Your Ad"
                    title={
                      <span>
                        <i className="fa fa-video-camera" /> Tour{" "}
                      </span>
                    }
                    onClick={() => {
                      this.setState({ runTour: false }, () =>
                        this.setState({ runTour: true })
                      );
                    }}
                  />
                </div>
                <div
                  className="theme_button_div"
                  style={{ flex: "1", margin: "1em", userSelect: "none" }}
                >
                  <SecondaryButton
                    id="theme_secondary_button_top_right_display"
                    key="theme_secondary_button_top_right_display"
                    style={{ marginTop: "1em" }}
                    fontSize="14px"
                    title={
                      <span>
                        {this.state.template3 &&
                        this.state.template3.config &&
                        this.state.template3.config.templateColor ? (
                          <div
                            style={{
                              display: "inline",
                              float: "left",
                              marginLeft: "0.5em",
                              width: "10px",
                              height: "10px",
                              borderRadius: 22,
                              backgroundColor:
                                this.state.template3 &&
                                this.state.template3.config &&
                                this.state.template3.config.templateColor
                                  ? "#" +
                                    this.state.template3.config.templateColor
                                  : "white",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <div style={{ display: "inline", marginLeft: "1em" }}>
                          Theme{" "}
                        </div>
                      </span>
                    }
                    onClick={() => {
                      this.setState({
                        showThemeColorPicker: true,
                      });
                    }}
                  />
                </div>
                <div
                  style={{ flex: "1", margin: "1em" }}
                  className="main-preview-button"
                >
                  <SecondaryButton
                    style={{ marginTop: "1em" }}
                    fontSize="14px"
                    tooltip="View Your Ad"
                    title={
                      <span>
                        <i className="fa fa-video-camera" /> View Your Ad{" "}
                      </span>
                    }
                    onClick={() => {
                      this.setState({
                        toolbarSelect: TOOLBAR_SELECT_PREVIEW,
                      });
                    }}
                  />
                </div>
                <div
                  style={{ flex: "1", margin: "1em" }}
                  className="main-publish-button"
                >
                  <LogoutButton
                    style={{ marginTop: "1em" }}
                    fontSize="14px"
                    title={
                      <span>
                        <i className="fa fa-bookmark-o" /> Publish{" "}
                      </span>
                    }
                    onClick={() => {
                      if (
                        !this.state.template3 ||
                        !this.state.template3.config
                      ) {
                        return;
                      }

                      this.setState({ showPublishModal: true });
                    }}
                  />
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div
              style={{
                width: "457px",
                marginBottom: "1em",
                float: "right",
                marginRight: "28px",
              }}
            >
              {this.state.previewLaunched && !this.state.publishInProgress && (
                <div>
                  <ProgressBar
                    leftCount={this.state.previewProgress}
                    rightCount={100 - this.state.previewProgress}
                    innerBarPercentage={this.state.previewProgress}
                    height="20px"
                    barTextFontSize="14px"
                    centerSubtitle={
                      "Processing video " +
                      this.state.previewProgress.toFixed(0) +
                      "%"
                    }
                  />
                </div>
              )}
            </div>
          </Row>
        </div>
      </>
    );
  }

  renderBackgroundMiddle() {
    return (
      this.renderBackgroundImageMiddle() ||
      this.renderBackgroundVideoMiddle() ||
      this.renderBackgroundColorVideoMiddle()
    );
  }

  renderMiddleDisplay() {
    return (
      this.renderTextMiddle() ||
      this.renderPreviewMiddle() ||
      this.renderImageMiddle() ||
      this.renderBackgroundMiddle()
    );
  }

  renderPreviewMiddle() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_PREVIEW &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.videoSrc ? (
      <>
        {
          <>
            <div
              key={"div_row2_middle_disp_preview"}
              style={{
                verticalAlign: "middle",
              }}
              className="m-auto d-flex justify-content-center"
            >
              <Player
                id={"middle_disp_preview_video_player"}
                key={"middle_disp_preview_video_player"}
                src={this.state.template3.config.videoSrc}
                playsInline
                fluid={false}
                height={DEFAULT_BACKGROUND_VIDEO_HEIGHT}
              >
                <BigPlayButton position="center" />
                <ControlBar autoHide={false}>
                  <ReplayControl seconds={10} />
                </ControlBar>
              </Player>
            </div>
          </>
        }
      </>
    ) : (
      ""
    );
  }

  renderImageMiddle() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_IMAGES &&
      this.state.template3.config.imageForegroundClips[
        this.state.selectionIndex
      ] ? (
      <>
        <div
          id={
            "middle_image_div_img_view_canvas_" +
            this.state.template3.config.imageForegroundClips[
              this.state.selectionIndex
            ].imageKey
          }
          style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}
          className="m-auto d-flex justify-content-center"
        >
          <canvas
            id={
              "middle_img_view_canvas_" +
              this.state.template3.config.imageForegroundClips[
                this.state.selectionIndex
              ].imageKey
            }
            key={
              "middle_img_view_canvas_" +
              this.state.template3.config.imageForegroundClips[
                this.state.selectionIndex
              ].imageKey
            }
            style={{
              width: "95%",
              // maxWidth: 300px,
              height: "auto",
            }}
          ></canvas>
          <img
            id={
              "middle_image_view_" +
              this.state.template3.config.imageForegroundClips[
                this.state.selectionIndex
              ].imageKey
            }
            src={
              this.state.template3.config.imageForegroundClips[
                this.state.selectionIndex
              ].imageSrc
            }
            alt=""
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              display: "none",
            }}
            draggable="false"
            onLoad={(e) => {
              const canvas = document.getElementById(
                "middle_img_view_canvas_" +
                  this.state.template3.config.imageForegroundClips[
                    this.state.selectionIndex
                  ].imageKey
              );
              const ctx = canvas.getContext("2d");
              let ratio =
                Math.min(e.target.height, e.target.width) /
                Math.max(e.target.height, e.target.width);

              ratio = e.target.width / e.target.height;

              // make height no bigger than half-way between original and preview size
              let useHeight =
                (e.target.height + DEFAULT_BACKGROUND_VIDEO_HEIGHT) / 2;
              if (useHeight > DEFAULT_BACKGROUND_VIDEO_HEIGHT) {
                useHeight = DEFAULT_BACKGROUND_VIDEO_HEIGHT;
              }
              let recalcWidth = useHeight * ratio;
              if (e.target.height < e.target.width) {
                ratio = e.target.height / e.target.width;
                recalcWidth =
                  (e.target.width + DEFAULT_BACKGROUND_VIDEO_WIDTH) / 2;
                if (recalcWidth > DEFAULT_BACKGROUND_VIDEO_WIDTH) {
                  recalcWidth = DEFAULT_BACKGROUND_VIDEO_WIDTH;
                }
                useHeight = recalcWidth * ratio;
              }

              if (useHeight > DEFAULT_BACKGROUND_VIDEO_HEIGHT) {
                useHeight = DEFAULT_BACKGROUND_VIDEO_HEIGHT;
                recalcWidth = useHeight / ratio;
              }

              if (recalcWidth > DEFAULT_BACKGROUND_VIDEO_WIDTH) {
                recalcWidth = DEFAULT_BACKGROUND_VIDEO_WIDTH;
                useHeight = recalcWidth * ratio;
              }

              let useImage = e.target;
              canvas.height = useHeight;
              canvas.width = recalcWidth;
              ctx.drawImage(
                useImage,
                0,
                0,
                e.target.width,
                e.target.height,
                0,
                0,
                recalcWidth,
                useHeight
              );
            }}
          />
        </div>
      </>
    ) : (
      ""
    );
  }

  renderBackgroundImageMiddle() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.backgroundClips &&
      this.state.template3.config.backgroundClips.length > 0 &&
      this.state.template3.config.backgroundClips[this.state.selectionIndex]
        .type === "image" ? (
      <div
        style={{
          verticalAlign: "middle",
        }}
        className="m-auto d-flex justify-content-center"
      >
        <img
          id={"background_image_view_" + this.state.selectionIndex}
          height={DEFAULT_BACKGROUND_VIDEO_HEIGHT}
          src={
            this.state.template3.config.backgroundClips[
              this.state.selectionIndex
            ].imageSrc
          }
          alt=""
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
          }}
          draggable="false"
        />
      </div>
    ) : (
      ""
    );
  }

  renderBackgroundVideoMiddle() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.backgroundClips &&
      this.state.template3.config.backgroundClips.length > 0 &&
      this.state.template3.config.backgroundClips[this.state.selectionIndex]
        .type === "video" ? (
      <>
        <div
          style={{
            verticalAlign: "middle",
          }}
          className="m-auto d-flex justify-content-center"
        >
          <Player
            id={
              "middle_disp_background_video_player_" + this.state.selectionIndex
            }
            key={
              "middle_disp_background_video_player_" + this.state.selectionIndex
            }
            src={
              this.state.template3.config.backgroundClips[
                this.state.selectionIndex
              ].videoSrc
            }
            playsInline
            fluid={false}
            height={DEFAULT_BACKGROUND_VIDEO_HEIGHT}
          >
            <BigPlayButton position="center" />
            <ControlBar autoHide={false}>
              <ReplayControl seconds={10} />
            </ControlBar>
          </Player>
          <canvas
            id="canvas_id"
            key="canvas_id"
            style={{ display: "none" }}
          ></canvas>
        </div>
      </>
    ) : (
      ""
    );
  }

  renderBackgroundColorVideoMiddle() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.backgroundClips &&
      this.state.template3.config.backgroundClips.length > 0 &&
      this.state.template3.config.backgroundClips[this.state.selectionIndex]
        .type === "color" ? (
      <>
        <div
          style={{
            verticalAlign: "middle",
          }}
          className="m-auto d-flex justify-content-center"
        >
          <img
            id={"background_image_view_" + this.state.selectionIndex}
            height={DEFAULT_BACKGROUND_VIDEO_HEIGHT / 1.5}
            width={DEFAULT_BACKGROUND_VIDEO_WIDTH}
            src={this.imageSrcFromColor(
              this.state.template3.config.backgroundClips[
                this.state.selectionIndex
              ].color,
              DEFAULT_BACKGROUND_VIDEO_WIDTH,
              DEFAULT_BACKGROUND_VIDEO_HEIGHT
            )}
            alt=""
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
            }}
            draggable="false"
          />
        </div>
      </>
    ) : (
      ""
    );
  }

  renderTextMiddle() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_TEXT &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.textForegroundClips ? (
      <>
        <div
          className="wrap"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: this.state.template3.config.textForegroundClips[
              this.state.selectionIndex
            ].color.find((item) => item.toLowerCase() === "ffffff")
              ? "#C8C8C8"
              : "#F7F7F7",
          }}
        >
          <div
            style={{
              height: DEFAULT_BACKGROUND_VIDEO_HEIGHT + "px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {this.state.template3.config.textForegroundClips[
                this.state.selectionIndex
              ].text.map((text, ti) => {
                return this.state.textEditSelectionIndex !== ti ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        textEditSelectionIndex: ti,
                      });
                    }}
                  >
                    <p
                      key={"middle_display_p_text_" + ti}
                      style={{
                        color: this.getTextForegoundClipColor(ti)["color"],
                        fontFamily:
                          this.state.template3.config.textForegroundClips[
                            this.state.selectionIndex
                          ].font,
                        fontSize:
                          this.state.template3.config.textForegroundClips[
                            this.state.selectionIndex
                          ].size[ti] > 40
                            ? 40
                            : this.state.template3.config.textForegroundClips[
                                this.state.selectionIndex
                              ].size[ti],
                      }}
                    >
                      {text}
                    </p>
                  </div>
                ) : (
                  <FormControl
                    key={"enter_text_" + this.state.selectionIndex + ti}
                    id="edit_foreground_text"
                    inputRef={(input) => {
                      if (input) {
                        input.focus();
                      }
                    }}
                    placeholder={
                      this.state.template3.config.textForegroundClips[
                        this.state.selectionIndex
                      ].descriptionForUser
                    }
                    name={
                      this.state.template3.config.textForegroundClips[
                        this.state.selectionIndex
                      ].descriptionForUser
                    }
                    style={{
                      borderRadius: "0px",
                      borderColor: "#C8C8C8",
                      fontFamily:
                        this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].font,
                      color: "black",
                      fontSize:
                        this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].size[ti] > 40
                          ? 40
                          : this.state.template3.config.textForegroundClips[
                              this.state.selectionIndex
                            ].size[ti],
                      paddingTop: "0em",
                      paddingBottom: "0em",
                      height: "auto",
                      width:
                        Math.min(
                          25,
                          Math.max(
                            7,
                            this.state.template3.config.textForegroundClips[
                              this.state.selectionIndex
                            ].text[this.state.textEditSelectionIndex].length + 3
                          )
                        ) + "ch",
                      width3:
                        Math.round(
                          Math.min(
                            DEFAULT_BACKGROUND_VIDEO_WIDTH - 30,
                            Math.max(
                              100,
                              this.state.template3.config.textForegroundClips[
                                this.state.selectionIndex
                              ].text[this.state.textEditSelectionIndex].length *
                                (this.state.template3.config
                                  .textForegroundClips[
                                  this.state.selectionIndex
                                ].size > 40
                                  ? 40
                                  : this.state.template3.config
                                      .textForegroundClips[
                                      this.state.selectionIndex
                                    ].size)
                            )
                          ) * 0.75
                        ) + "px",
                      width_no2: "auto",
                      width_no:
                        (this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].size > 40
                          ? 40
                          : this.state.template3.config.textForegroundClips[
                              this.state.selectionIndex
                            ].size) *
                          this.state.template3.config.textForegroundClips[
                            this.state.selectionIndex
                          ].charLimit +
                        "px",
                    }}
                    value={
                      this.state.template3.config.textForegroundClips[
                        this.state.selectionIndex
                      ].text[this.state.textEditSelectionIndex]
                    }
                    onBlur={(e) => {
                      this.setState({ textEditSelectionIndex: -1 });
                    }}
                    onChange={(e) => {
                      var tmpl = this.state.template3;
                      let useValue = e.target.value.substring(
                        0,
                        this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].charLimit[ti]
                      );
                      let useSize =
                        this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].size > 40
                          ? 40
                          : this.state.template3.config.textForegroundClips[
                              this.state.selectionIndex
                            ].size;
                      let useWidth =
                        Math.round(
                          Math.min(
                            DEFAULT_BACKGROUND_VIDEO_WIDTH - 30,
                            Math.max(100, useValue.length * useSize)
                          ) * 0.8
                        ) + "px";
                      useWidth =
                        Math.min(25, Math.max(7, useValue.length + 3)) + "ch";
                      e.target.style.width = useWidth;
                      let t =
                        tmpl.config.textForegroundClips[
                          this.state.selectionIndex
                        ].text;
                      t[ti] = useValue;
                      this.setState({ template3: tmpl }, () =>
                        this.saveUserConfig()
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </>
    ) : (
      ""
    );
  }

  renderImageRight() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_IMAGES &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.imageForegroundClips &&
      this.state.template3.config.imageForegroundClips.length > 0 ? (
      <>
        <div>
          {this.state.template3.config.imageForegroundClips[
            this.state.selectionIndex
          ].timeSlots.map((time, ti) => {
            return (
              <div key={"render_img_right_div_" + time + "_" + ti}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1em",
                  }}
                >
                  <div style={{ marginRight: "2em" }}>
                    <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                      <FormLabel>
                        <h6
                          className="title"
                          style={{
                            color: "#73706E",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Start Time
                        </h6>
                      </FormLabel>
                    </div>
                    <FormControl
                      disabled
                      componentClass="textarea"
                      rows="1"
                      placeholder="Start time"
                      name="StartTime"
                      style={{
                        resize: "none",
                        borderRadius: 0,
                        width: "120px",
                      }}
                      bsClass="form-control"
                      value={
                        "0:" +
                        (Math.round(time.startTime) < 10 ? "0" : "") +
                        Math.round(time.startTime)
                      }
                      onChange={(event) => {}}
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                      <ControlLabel>
                        <h6
                          className="title"
                          style={{
                            color: "#73706E",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Duration
                        </h6>
                      </ControlLabel>
                    </div>
                    <FormControl
                      disabled
                      componentClass="textarea"
                      rows="1"
                      placeholder="Duration"
                      name="Duration"
                      style={{
                        resize: "none",
                        borderRadius: 0,
                        width: "120px",
                      }}
                      bsClass="form-control"
                      value={
                        "0:" + (time.duration < 10 ? "0" : "") + time.duration
                      }
                      onChange={(event) => {}}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    ) : (
      ""
    );
  }

  renderBackgroundRight() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.backgroundClips &&
      this.state.template3.config.backgroundClips.length > 0 ? (
      <>
        <div>
          {this.state.template3.config.backgroundClips[
            this.state.selectionIndex
          ].timeSlots.map((time, ti) => {
            return (
              <div key="background-clips-right-display-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1em",
                  }}
                >
                  <div style={{ marginRight: "2em" }}>
                    <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                      <ControlLabel>
                        <h6
                          className="title"
                          style={{
                            color: "#73706E",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Start Time
                        </h6>
                      </ControlLabel>
                    </div>
                    <FormControl
                      disabled
                      componentClass="textarea"
                      rows="1"
                      placeholder="Start time"
                      name="StartTime"
                      style={{
                        resize: "none",
                        borderRadius: 0,
                        width: "120px",
                      }}
                      bsClass="form-control"
                      value={
                        "0:" +
                        (Math.round(time.startTime) < 10 ? "0" : "") +
                        Math.round(time.startTime)
                      }
                      onChange={(event) => {}}
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                      <ControlLabel>
                        <h6
                          className="title"
                          style={{
                            color: "#73706E",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Duration
                        </h6>
                      </ControlLabel>
                    </div>
                    <FormControl
                      disabled
                      componentClass="textarea"
                      rows="1"
                      placeholder="Duration"
                      name="Duration"
                      style={{
                        resize: "none",
                        borderRadius: 0,
                        width: "120px",
                      }}
                      bsClass="form-control"
                      value={
                        "0:" +
                        (Math.round(time.duration) < 10 ? "0" : "") +
                        Math.round(time.duration)
                      }
                      onChange={(event) => {}}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    ) : (
      ""
    );
  }

  renderTextRight() {
    return this.state.toolbarSelect === TOOLBAR_SELECT_TEXT &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.textForegroundClips &&
      this.state.template3.config.textForegroundClips.length > 0 ? (
      <div>
        {this.state.template3.config.textForegroundClips.length > 0
          ? this.state.template3.config.textForegroundClips[
              this.state.selectionIndex
            ].timeSlots.map((time, ti) => {
              let useStartTime = Math.round(time.startTime);
              let useDuration = Math.round(time.duration);
              return (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1em",
                    }}
                  >
                    <div style={{ marginRight: "2em" }}>
                      <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                        <ControlLabel>
                          <h6
                            className="title"
                            style={{
                              color: "#73706E",
                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                          >
                            Start Time
                          </h6>
                        </ControlLabel>
                      </div>
                      <FormControl
                        disabled
                        componentClass="textarea"
                        rows="1"
                        placeholder="Start time"
                        name="StartTime"
                        style={{
                          resize: "none",
                          borderRadius: 0,
                          width: "120px",
                        }}
                        bsClass="form-control"
                        value={
                          "0:" + (useStartTime < 10 ? "0" : "") + useStartTime
                        }
                        onChange={(event) => {}}
                      />
                    </div>
                    <div style={{ margin: "auto" }}>
                      <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                        <ControlLabel>
                          <h6
                            className="title"
                            style={{
                              color: "#73706E",
                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                          >
                            Duration
                          </h6>
                        </ControlLabel>
                      </div>
                      <FormControl
                        disabled
                        componentClass="textarea"
                        rows="1"
                        placeholder="Duration"
                        name="Duration"
                        style={{
                          resize: "none",
                          borderRadius: 0,
                          width: "120px",
                        }}
                        bsClass="form-control"
                        value={
                          "0:" + (useDuration < 10 ? "0" : "") + useDuration
                        }
                        onChange={(event) => {}}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
        <div
          style={{
            width: "100%",
            marginBottom: "-12px",
            marginTop: "5px",
          }}
        >
          <Row className="wrap">
            <div
              style={{
                width: "90%",
                marginBottom: "-12px",
                marginTop: "5px",
              }}
              className="mt-3"
            >
              <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                <ControlLabel>
                  <h6
                    className="title"
                    style={{
                      color: "#73706E",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Font
                  </h6>
                </ControlLabel>
              </div>
              <FormGroupTile
                type="select"
                disabled={true}
                stateName={"font"}
                handleChildFormElement={(id, value) => {}}
                defaultValue={{
                  value:
                    this.state.template3.config.textForegroundClips[
                      this.state.selectionIndex
                    ].font,
                  label:
                    this.state.template3.config.textForegroundClips[
                      this.state.selectionIndex
                    ].font,
                }}
                options={[
                  {
                    value:
                      this.state.template3.config.textForegroundClips[
                        this.state.selectionIndex
                      ].font,
                    label:
                      this.state.template3.config.textForegroundClips[
                        this.state.selectionIndex
                      ].font,
                  },
                ]}
              />
            </div>
          </Row>
          <div
            style={{
              marginTop: "1.1em",
              display: "flex",
              justifyContent: "center",
            }}
            className="mt-5"
          >
            <div style={{ width: "50%", marginRight: "2em" }}>
              <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                <ControlLabel>
                  <h6
                    className="title"
                    style={{
                      color: "#73706E",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Size
                  </h6>
                </ControlLabel>
              </div>
              <FormGroupTile
                type="select"
                disabled={true}
                stateName={"size"}
                handleChildFormElement={(id, value) => {}}
                defaultValue={{
                  value:
                    this.state.textEditSelectionIndex >= 0
                      ? this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].size[this.state.textEditSelectionIndex]
                      : this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].size[0],
                  label:
                    this.state.textEditSelectionIndex >= 0
                      ? this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].size[this.state.textEditSelectionIndex]
                      : this.state.template3.config.textForegroundClips[
                          this.state.selectionIndex
                        ].size[0],
                }}
                options={[
                  {
                    value:
                      this.state.textEditSelectionIndex >= 0
                        ? this.state.template3.config.textForegroundClips[
                            this.state.selectionIndex
                          ].size[this.state.textEditSelectionIndex]
                        : this.state.template3.config.textForegroundClips[
                            this.state.selectionIndex
                          ].size[0],
                    label:
                      this.state.textEditSelectionIndex >= 0
                        ? this.state.template3.config.textForegroundClips[
                            this.state.selectionIndex
                          ].size[this.state.textEditSelectionIndex]
                        : this.state.template3.config.textForegroundClips[
                            this.state.selectionIndex
                          ].size[0],
                  },
                ]}
              />
            </div>
            <div style={{ width: "45%" }}>
              <div style={{ textAlign: "left", marginBottom: "0rem" }}>
                <ControlLabel>
                  <h6
                    className="title"
                    style={{
                      color: "#73706E",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Color
                  </h6>
                </ControlLabel>
              </div>
              <Select
                id="color_select"
                key={this.getTextForegoundClipColor()["value"]}
                isDisabled={true}
                defaultValue={this.getTextForegoundClipColor()}
                label="Single select"
                options={this.getTextForegoundClipColorOptions()}
                styles={{
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      backgroundColor: data.color,
                      color: data.color,
                      cursor: isDisabled ? "not-allowed" : "default",
                      ":active": {
                        ...styles[":active"],
                        backgroundColor: data.color,
                      },
                    };
                  },
                  singleValue: (styles, { data }) => ({
                    ...styles,
                    ...stylerForColorSelect(data.color),
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }

  renderRightDisplay() {
    return (
      this.renderBackgroundRight() ||
      this.renderImageRight() ||
      this.renderTextRight()
    );
  }

  renderTextLeft() {
    return (
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.backgroundClips &&
      this.state.template3.config.textForegroundClips &&
      this.state.toolbarSelect === TOOLBAR_SELECT_TEXT && (
        <Row
          key="text_left_view_row"
          className="wrap"
          style={{
            border: "solid #F7F7F7 1px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div>
              <div
                style={{
                  fontSize: "16px",
                }}
              >
                {
                  this.state.template3.config.textForegroundClips[
                    this.state.selectionIndex
                  ].descriptionForUser
                }
              </div>
              <div
                style={{
                  fontSize: "14px",
                  marginTop: "1em",
                }}
              >
                Edit text in the preview window.
              </div>
            </div>
          </div>
        </Row>
      )
    );
  }

  renderBackgroundOptionsThemeLeft() {
    return (
      this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS &&
      this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.backgroundClips &&
      this.state.template3.config.backgroundClips[this.state.selectionIndex]
        .type === "video" &&
      this.state.template3.config.backgroundClips[this.state.selectionIndex]
        .videoSrcOptions &&
      this.state.template3.config.backgroundClips[this.state.selectionIndex]
        .videoSrcOptions.length > 0 && (
        <div
          key="theme_button_div_left_display"
          style={{ width: "100%", height: "100%" }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            <div
              style={{
                marginLeft: "25%",
                marginRight: "25%",
                marginBottom: "2em",
                textAlign: "center",
              }}
            >
              Click the theme button to select the best match for your brand.
            </div>
            <div
              style={{
                marginLeft: "25%",
                marginRight: "25%",
                marginBottom: "2em",
              }}
            >
              <SecondaryButton
                id="theme_secondary_button_left_display"
                key="theme_secondary_button_left_display"
                style={{ marginTop: "1em" }}
                fontSize="14px"
                title={
                  <span>
                    <div
                      style={{
                        display: "inline",
                        float: "left",
                        marginLeft: "0.5em",
                        width: "10px",
                        height: "10px",
                        borderRadius: 22,
                        backgroundColor:
                          this.state.template3 &&
                          this.state.template3.config &&
                          this.state.template3.config.templateColor
                            ? "#" + this.state.template3.config.templateColor
                            : "white",
                      }}
                    />
                    <div style={{ display: "inline", marginLeft: "1em" }}>
                      Theme{" "}
                    </div>
                  </span>
                }
                onClick={() => {
                  this.setState({
                    showThemeColorPicker: true,
                  });
                }}
              />
            </div>
          </div>
        </div>
      )
    );
  }

  renderPreviewLeft() {
    return (
      this.state.toolbarSelect === TOOLBAR_SELECT_PREVIEW &&
      this.state.template3 &&
      this.state.template3.config && (
        <div
          key="preview_left_view_div"
          style={{ width: "100%", height: "100%" }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              <div
                style={{
                  marginLeft: "25%",
                  marginRight: "25%",
                  marginBottom: "2em",
                  textAlign: "center",
                }}
              >
                Click on Export Changes to update the Video Ad with your latest
                changes.
              </div>
              <div
                style={{
                  marginLeft: "25%",
                  marginRight: "25%",
                  marginBottom: "2em",
                }}
              >
                <SecondaryButton
                  fontSize="10px"
                  title={
                    <span>
                      <i className="fa fa-video-camera" /> Export Changes{" "}
                    </span>
                  }
                  onClick={() => {
                    if (this.state.previewLaunched) {
                      this.setState({ showPreviewModal: true });
                    } else {
                      this.launchPreviewConfig(true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  renderAssets() {
    return (
      <>
        {this.state.template3 &&
          this.state.template3.config &&
          this.state.template3.config.backgroundClips &&
          this.state.template3.config.imageForegroundClips &&
          (this.state.toolbarSelect === TOOLBAR_SELECT_IMAGES ||
            (this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS &&
              ((this.state.template3.config.backgroundClips[
                this.state.selectionIndex
              ].type === "video" &&
                (!this.state.template3.config.backgroundClips[
                  this.state.selectionIndex
                ].videoSrcOptions ||
                  this.state.template3.config.backgroundClips[
                    this.state.selectionIndex
                  ].videoSrcOptions.length === 0)) ||
                this.state.template3.config.backgroundClips[
                  this.state.selectionIndex
                ].type === "image"))) && (
            <div key="assets_view_div">
              <Row className="wrap" style={{ margin: "1em" }}>
                <div
                  style={{
                    fontSize: "18px",
                    color: "#73706E",
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                >
                  {(this.state.toolbarSelect === TOOLBAR_SELECT_IMAGES
                    ? "Image"
                    : this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS
                    ? this.state.template3.config.backgroundClips[
                        this.state.selectionIndex
                      ].type === "image"
                      ? "Image"
                      : this.state.template3.config.backgroundClips[
                          this.state.selectionIndex
                        ].type === "video"
                      ? "Video"
                      : ""
                    : "") + " Asset Library"}
                </div>
              </Row>
              <Row className="wrap" style={{ margin: "1em" }}>
                <input
                  accept={
                    this.state.toolbarSelect === TOOLBAR_SELECT_IMAGES
                      ? "image/*"
                      : this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS
                      ? this.state.template3.config.backgroundClips[
                          this.state.selectionIndex
                        ].type + "/*"
                      : "image/*,video/*"
                  }
                  id="input-new-asset-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      let file = e.target.files[0];
                      let assetKey = e.target.files[0].name;
                      let invalidUpload = false;
                      let invalidUploadErrorMessage = "";
                      if (
                        this.state.assets.filter(
                          (asset) => asset.name === assetKey
                        ).length > 0
                      ) {
                        invalidUpload = true;
                        invalidUploadErrorMessage =
                          "Failed to upload file, duplicate file name already exists in your assets library";
                      }

                      let imageRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
                      let videoRegex = /\.(mpeg4|mp4|mpeg4)$/i;
                      let imageFilesSupported =
                        "gif, jpg, jpeg, tiff, png, webp, bmp";
                      let videoFilesSupported = "mpeg4, mp4";

                      let fileToUploadType = "invalid";
                      if (imageRegex.test(assetKey)) {
                        fileToUploadType = "image";
                      } else if (videoRegex.test(assetKey)) {
                        fileToUploadType = "video";
                      } else {
                        invalidUpload = true;
                        invalidUploadErrorMessage = (
                          <div>
                            <div>{"File type not supported."}</div>
                            <div>
                              {"Please use types for images: " +
                                imageFilesSupported}
                            </div>
                            <div>
                              {"And for videos: " + videoFilesSupported}
                            </div>
                          </div>
                        );
                      }
                      if (invalidUpload) {
                        this.props.handleNotification(
                          "tr",
                          "warning",
                          "pe-7s-attention",
                          invalidUploadErrorMessage,
                          "medium"
                        );
                        this.showUploadingAssetsSpinner(false);
                        return;
                      }

                      this.showUploadingAssetsSpinner(true);
                      uploadAsset(
                        assetKey,
                        file,
                        fileToUploadType,
                        (assetId) => {
                          if (assetId) {
                            getAssetSrcUrl(assetId, (srcUrl) => {
                              if (srcUrl) {
                                let newAsset = {
                                  name: assetKey,
                                  type: fileToUploadType,
                                  url: srcUrl,
                                };
                                let assets = [...this.state.assets];
                                assets.unshift(newAsset);

                                this.setState(
                                  {
                                    assets: assets,
                                  },
                                  () => {
                                    if (newAsset.type === "video") {
                                      this.extractThumbnailsFromAssets(
                                        newAsset,
                                        0,
                                        true
                                      );
                                    }
                                  }
                                );
                                this.showUploadingAssetsSpinner(false);
                              } else {
                                console.log(
                                  "Upload completed, failed to get srcUrl"
                                );
                                this.props.handleNotification(
                                  "tr",
                                  "warning",
                                  "pe-7s-attention",
                                  "Something went wrong while uploading assets. Please try again."
                                );
                                this.showUploadingAssetsSpinner(false);
                              }
                            });
                          } else {
                            console.log(
                              "Upload completed, failed to get assetId"
                            );
                            this.props.handleNotification(
                              "tr",
                              "warning",
                              "pe-7s-attention",
                              "Something went wrong while uploading assets. Please try again."
                            );
                            this.showUploadingAssetsSpinner(false);
                          }
                        }
                      );
                    }
                  }}
                />
                <label htmlFor="htmlfor-input-new-asset-file">
                  <SecondaryButton
                    fontSize="10px"
                    title={
                      <span>
                        <i className="fa fa-upload" /> Upload{" "}
                      </span>
                    }
                    onClick={() => {
                      let el = document.getElementById("input-new-asset-file");
                      el.click();
                    }}
                  />
                </label>
              </Row>
              <Row
                className="wrap"
                id={
                  "div_row_img_image_assets_thumbs_" + this.state.assets.length
                }
                key={
                  "div_row_img_image_assets_thumbs_" + this.state.assets.length
                }
                style={{
                  margin: "0px",
                  // height: "20vh",
                  height: DEFAULT_BACKGROUND_VIDEO_HEIGHT - 100,
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "row",
                  flexWrap: "wrap",
                  marginTop: "3rem",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                {this.state.assets &&
                  this.state.assets.length > 0 &&
                  this.state.assets.map((asset, assetIndex) => {
                    let useImage = asset.url;
                    if (asset.type === "video") {
                      useImage = this.state.assetsThumbnails[asset.name];
                      if (!useImage) {
                        useImage = LoadingImage;
                      }
                    }
                    return (asset.type === "image" &&
                      (this.state.toolbarSelect === TOOLBAR_SELECT_IMAGES ||
                        (this.state.toolbarSelect ===
                          TOOLBAR_SELECT_BACKGROUNDS &&
                          this.state.template3.config.backgroundClips[
                            this.state.selectionIndex
                          ].type === "image"))) ||
                      (asset.type === "video" &&
                        this.state.toolbarSelect ===
                          TOOLBAR_SELECT_BACKGROUNDS &&
                        this.state.template3.config.backgroundClips[
                          this.state.selectionIndex
                        ].type === "video") ? (
                      <div
                        id={"div_img_image_assets_thumbs+" + asset.name}
                        key={"div_img_image_assets_thumbs+" + asset.name}
                        style={{
                          width: DEFAULT_ASSET_IMAGE_WIDTH,
                          height: DEFAULT_ASSET_IMAGE_HEIGHT,
                          marginLeft: "15px",
                          marginRight: "15px",
                          marginBottom: "15px",
                          border: "solid 1px gray",
                          borderRadius: "6px",
                        }}
                      >
                        <OverlayTrigger
                          key={
                            "overlay_div_img_image_assets_thumbs+" +
                            asset.name +
                            "_" +
                            assetIndex
                          }
                          overlay={
                            <Tooltip
                              className="show"
                              id={
                                "overlay_img_image_assets_thumbs+" + asset.name
                              }
                              key={
                                "overlay_img_image_assets_thumbs+" +
                                asset.name +
                                "_" +
                                assetIndex
                              }
                            >
                              {asset.name}
                            </Tooltip>
                          }
                          placement="top"
                          delayShow={300}
                          delayHide={150}
                        >
                          <img
                            key={
                              "img_image_assets_thumbs+" +
                              asset.name +
                              "_" +
                              assetIndex
                            }
                            alt=""
                            src={useImage}
                            width={DEFAULT_ASSET_DISPLAY_IMAGE_WIDTH}
                            draggable="false"
                            style={{
                              cursor: "pointer",
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              userSelect: "none",
                            }}
                            onLoad={(e) => {
                              if (
                                asset.type === "image" &&
                                !asset["resolution"]
                              ) {
                                let img = e.target;
                                let assets = this.state.assets;
                                assets[assetIndex]["resolution"] = [
                                  img.naturalWidth,
                                  img.naturalHeight,
                                ];
                                this.setState({ assets: assets });
                              }
                            }}
                            onClick={(e) => {
                              if (
                                this.state.toolbarSelect ===
                                TOOLBAR_SELECT_IMAGES
                              ) {
                                if (
                                  this.state.template3.config
                                    .imageForegroundClips[
                                    this.state.selectionIndex
                                  ].resolution &&
                                  this.state.template3.config
                                    .imageForegroundClips[
                                    this.state.selectionIndex
                                  ].resolution.length > 1 &&
                                  this.state.template3.config
                                    .imageForegroundClips[
                                    this.state.selectionIndex
                                  ].resolution[0] < asset.resolution[0] &&
                                  this.state.template3.config
                                    .imageForegroundClips[
                                    this.state.selectionIndex
                                  ].resolution[1] < asset.resolution[1]
                                ) {
                                  let errorMessage = (
                                    <div>
                                      <div>{"Asset selection failed."}</div>
                                      <div>
                                        {
                                          "Asset resolution is below required for validation."
                                        }
                                      </div>
                                      <div>
                                        {"Please use resolution " +
                                          (this.state.template3.config
                                            .imageForegroundClips[
                                            this.state.selectionIndex
                                          ].resolution[0] +
                                            " ," +
                                            this.state.template3.config
                                              .imageForegroundClips[
                                              this.state.selectionIndex
                                            ].resolution[1]) +
                                          " or better."}
                                      </div>
                                    </div>
                                  );
                                  this.props.handleNotification(
                                    "tr",
                                    "warning",
                                    "pe-7s-attention",
                                    errorMessage
                                  );
                                  return;
                                }

                                let templ = this.state.template3;
                                templ.config.imageForegroundClips[
                                  this.state.selectionIndex
                                ]["location"] = "org_content";
                                templ.config.imageForegroundClips[
                                  this.state.selectionIndex
                                ].imageKey = asset.name;
                                templ.config.imageForegroundClips[
                                  this.state.selectionIndex
                                ].imageSrc = asset.url;
                                this.setState({
                                  template3: templ,
                                });
                                this.saveUserConfig();
                              } else if (
                                this.state.toolbarSelect ===
                                TOOLBAR_SELECT_BACKGROUNDS
                              ) {
                                if (
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution &&
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution.length > 1 &&
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution[0] > asset.resolution[0] &&
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution[1] > asset.resolution[1]
                                ) {
                                  let errorMessage = (
                                    <div>
                                      <div>{"Asset selection failed."}</div>
                                      <div>
                                        {
                                          "Asset resolution is below required for validation."
                                        }
                                      </div>
                                      <div>
                                        {"Please use resolution " +
                                          (this.state.template3.config
                                            .backgroundClips[
                                            this.state.selectionIndex
                                          ].resolution[0] +
                                            " ," +
                                            this.state.template3.config
                                              .backgroundClips[
                                              this.state.selectionIndex
                                            ].resolution[1]) +
                                          " or better."}
                                      </div>
                                    </div>
                                  );
                                  this.props.handleNotification(
                                    "tr",
                                    "warning",
                                    "pe-7s-attention",
                                    errorMessage,
                                    "medium"
                                  );
                                  return;
                                }

                                if (
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution &&
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution.length > 1 &&
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution[0] < asset.resolution[0] &&
                                  this.state.template3.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].resolution[1] < asset.resolution[1]
                                ) {
                                  let errorMessage = (
                                    <div>
                                      <div>{"Asset selection failed."}</div>
                                      <div>
                                        {
                                          "Asset resolution is below required for validation."
                                        }
                                      </div>
                                      <div>
                                        {"Please use resolution " +
                                          (this.state.template3.config
                                            .backgroundClips[
                                            this.state.selectionIndex
                                          ].resolution[0] +
                                            " ," +
                                            this.state.template3.config
                                              .backgroundClips[
                                              this.state.selectionIndex
                                            ].resolution[1]) +
                                          " or better."}
                                      </div>
                                    </div>
                                  );
                                  this.props.handleNotification(
                                    "tr",
                                    "warning",
                                    "pe-7s-attention",
                                    errorMessage,
                                    "medium"
                                  );
                                  return;
                                }

                                let templ = this.state.template3;
                                templ.config.backgroundClips[
                                  this.state.selectionIndex
                                ]["location"] = "org_content";
                                if (asset.type === "video") {
                                  this.extractImageStillsFromBackgroundVideo(
                                    asset.url,
                                    this.state.selectionIndex,
                                    false
                                  );
                                  templ.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].videoSrc = asset.url;
                                  templ.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].videoKey = asset.name;
                                } else if (asset.type === "image") {
                                  templ.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].imageSrc = asset.url;
                                  templ.config.backgroundClips[
                                    this.state.selectionIndex
                                  ].imageKey = asset.name;
                                }
                                this.setState({ template3: templ }, () => {
                                  this.saveUserConfig();
                                });
                              }
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    ) : (
                      ""
                    );
                  })}
              </Row>
            </div>
          )}
      </>
    );
  }

  renderTimeline() {
    var useTimelineWidth = 0;
    var useTimelineXloc = 0;

    return this.state.timeline.map((val, i) => {
      // TIMELINE
      useTimelineXloc += useTimelineWidth;
      useTimelineWidth = this.calculateWidthPerSecond(this.state.totalTimeSecs);
      var useText = "" + val;
      return (
        <Rnd
          key={"rnd_timeline_row_" + i}
          style={styleRndTimeline}
          bounds="parent"
          disableDragging="true"
          enableResizing={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          size={{
            width: useTimelineWidth,
            height: this.state.timelineHeight,
          }}
          position={{ x: useTimelineXloc, y: 0 }}
          onDragStop={(e, d) => {}}
          onResizeStop={(e, direction, ref, delta, position) => {
            this.setState({
              timelineWidth: ref.style.width,
              timelineHeight: ref.style.height,
              ...position,
            });
          }}
          onClick={() => {}}
        >
          <div
            style={{ textAlign: "center" }}
            key={"inner_div_timeline_row_" + i}
          >
            <div
              key={"inner_2_div_timeline_row_" + i}
              style={{
                borderLeft: "1px solid gray",
                height: "10px",
                position: "absolute",
                left: "35%",
              }}
            ></div>
            <div
              key={"inner_3_div_timeline_row_" + i}
              style={{
                borderLeft: "1px solid gray",
                height: "10px",
                position: "absolute",
                left: "99%",
              }}
            ></div>
            <p
              key={"inner_p_timeline_row_" + i}
              style={{
                fontSize: "10px",
                textAlign: "center",
                marginLeft: useTimelineWidth - 30 + "px",
                marginRight: "1px",
              }}
            >
              <strong key={"strong_timeline_row_" + i}>
                {(useText.length === 1 ? "0:0" : "0:") + useText}
              </strong>
            </p>
          </div>
        </Rnd>
      );
    });
  }

  renderTextTimeline() {
    return this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.textForegroundClips &&
      this.state.template3.config.textForegroundClips.length > 0
      ? this.state.template3.config.textForegroundClips.map((clip, i) => {
          // NEW TEXT ROW
          return clip.timeSlots.map((time, t) => {
            let useWidth =
              this.calculateWidthPerSecond(this.state.totalTimeSecs) *
              time.duration;
            let useXpos =
              this.calculateWidthPerSecond(this.state.totalTimeSecs) *
              Math.round(time.startTime);
            let useFullWidth =
              this.calculateWidthPerSecond(this.state.totalTimeSecs) *
                this.state.totalTimeSecs +
              2;
            return (
              <Row
                key={"row_text_row_" + clip.key + "_" + i + "_" + t}
                style={{
                  background: "#F7F7F7",
                  width: "" + useFullWidth + "px",
                }}
              >
                <div
                  key={"row_div_text_row_" + clip.key + "_" + i}
                  style={{
                    width: "100%",
                    minHeight: "" + this.state.textscrollHeight + "px",
                    height: "auto",
                    position: "relative",
                  }}
                >
                  <div key={"div_text_row_" + clip.key + "_" + i}>
                    <Rnd
                      key={"rnd_text_row_" + clip.key + "_" + i}
                      style={
                        this.state.toolbarSelect === TOOLBAR_SELECT_TEXT &&
                        this.state.selectionIndex === i
                          ? styleRndTextscroll3Selected
                          : styleRndTextscroll3
                      }
                      bounds="parent"
                      disableDragging="true"
                      enableResizing={{
                        top: false,
                        right: false,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                      }}
                      size={{
                        width: useWidth,
                      }}
                      position={{ x: useXpos, y: 0 }}
                      onDragStop={(e, d) => {}}
                      onResizeStop={(e, direction, ref, delta, position) => {
                        this.setState({
                          textscrollWidth: ref.style.width,
                          textscrollHeight: ref.style.height,
                          ...position,
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          toolbarSelect: TOOLBAR_SELECT_TEXT,
                          selectionIndex: i,
                          textEditSelectionIndex: 0,
                        });
                      }}
                    >
                      <div className="wrap">
                        <div
                          style={{
                            color: CONST_TEXT_SCROLL_TEXT_COLOR,
                            marginLeft: "10px",
                            marginRight: "5px",
                            fontWeight: "500",
                            fontSize: "13px",
                          }}
                        >
                          {clip.text.join(" ")}
                        </div>
                      </div>
                    </Rnd>
                  </div>
                </div>
              </Row>
            );
          });
        })
      : "";
  }

  renderImagesTimeline() {
    return this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.imageForegroundClips &&
      this.state.template3.config.imageForegroundClips.length > 0
      ? this.state.template3.config.imageForegroundClips.map((clip, i) => {
          // NEW IMAGE ROW
          return clip.timeSlots.map((time, t) => {
            let useWidth =
              this.calculateWidthPerSecond(this.state.totalTimeSecs) *
              Math.round(time.duration);
            let useXpos =
              this.calculateWidthPerSecond(this.state.totalTimeSecs) *
              Math.round(time.startTime);
            let imgObj = document.createElement("img");
            imgObj.src = clip.imageSrc;
            let useImageList = [imgObj];
            let useName = clip.key + "_" + clip.imageKey;
            let suffix = clip.imageKey + "_" + i + "_" + t;
            return (
              <Row
                key={"row_image_row_"}
                style={{
                  margin: "3px",
                  background: "#F7F7F7",
                  width:
                    this.calculateWidthPerSecond(this.state.totalTimeSecs) *
                      this.state.totalTimeSecs +
                    2,
                }}
              >
                <div
                  key={"outer_div_imagerow_image_rnd_" + suffix}
                  style={{
                    width: "100%",
                    height: "" + this.state.imagescrollHeight + "px",
                    position: "relative",
                  }}
                  onClick={(index) => {
                    this.setState({
                      toolbarSelect: TOOLBAR_SELECT_IMAGES,
                      selectionIndex: i,
                    });
                  }}
                >
                  <MemoizedImageRow
                    key={"imagerow_image_rnd_" + suffix}
                    cursor="pointer"
                    useName={useName}
                    writeLoading={true}
                    useKey={"usekey_imagerow_image_rnd_" + suffix}
                    isSelected={
                      this.state.toolbarSelect === TOOLBAR_SELECT_IMAGES &&
                      this.state.selectionIndex === i
                    }
                    useWidth={useWidth}
                    useHeight={this.state.imagescrollHeight}
                    useXpos={useXpos}
                    useSrc={useImageList[0].src}
                    useImages={useImageList}
                  />
                </div>
              </Row>
            );
          });
        })
      : "";
  }

  renderBackgroundTimeline() {
    return this.state.template3 &&
      this.state.template3.config &&
      this.state.template3.config.backgroundClips &&
      this.state.template3.config.backgroundClips.length > 0 &&
      this.state.backgroundOptionsStills &&
      this.state.projectName in this.state.backgroundOptionsStills &&
      this.state.backgroundVideoStills &&
      this.state.projectName in this.state.backgroundVideoStills
      ? this.state.template3.config.backgroundClips.map((clip, i) => {
          // NEW BACKGROUND ROW
          return clip.timeSlots.map((time, t) => {
            let isSelectable = true;
            let useWidth =
              this.calculateWidthPerSecond(this.state.totalTimeSecs) *
              Math.round(time.duration);
            let useXpos =
              this.calculateWidthPerSecond(this.state.totalTimeSecs) *
              Math.round(time.startTime);
            let templColor = this.state.template3.config.templateColor;
            let useImageList;
            let useName = clip.key;
            if (clip.type === "image") {
              let imgObj = document.createElement("img");
              imgObj.src = clip.imageSrc;
              useImageList = [imgObj];
              useName = clip.key + "_" + clip.imageKey;
            } else if (clip.type === "video") {
              if (
                clip.videoSrcOptions &&
                clip.videoSrcOptions.length > 0 &&
                this.state.backgroundOptionsStills[this.state.projectName][
                  clip.key
                ][templColor] &&
                this.state.backgroundOptionsStills[this.state.projectName][
                  clip.key
                ][templColor].length > 0
              ) {
                useImageList =
                  this.state.backgroundOptionsStills[this.state.projectName][
                    clip.key
                  ][templColor];
                useName =
                  clip.key +
                  "_" +
                  templColor +
                  this.state.loadingBackgroundOptionsStills;
              } else if (
                this.state.backgroundVideoStills[this.state.projectName][
                  clip.key
                ].length > 0
              ) {
                useImageList =
                  this.state.backgroundVideoStills[this.state.projectName][
                    clip.key
                  ];
                useName =
                  clip.key +
                  "_" +
                  clip.videoKey +
                  this.state.loadingBackgroundStills;
              } else {
                let imgObj = document.createElement("img");
                imgObj.src = LoadingImage;
                useImageList = [imgObj];
                useName = clip.key + "_NoImage";
              }
            } else if (clip.type === "color") {
              let imgObj = document.createElement("img");
              imgObj.src = this.imageSrcFromColor(
                clip.color,
                DEFAULT_BACKGROUND_VIDEO_WIDTH,
                DEFAULT_BACKGROUND_VIDEO_HEIGHT
              );
              useImageList = [imgObj];
              useName = clip.key + "_" + clip.color;
              if (clip.colorOptions && clip.colorOptions.length === 1) {
                isSelectable = false;
              }
            }
            return (
              <div
                key={
                  "outer_div_imagerow_background_rnd_" +
                  i +
                  "_" +
                  t +
                  "_" +
                  useName
                }
                onClick={(index) => {
                  if (isSelectable) {
                    this.setState({
                      toolbarSelect: TOOLBAR_SELECT_BACKGROUNDS,
                      selectionIndex: i,
                    });
                  }
                }}
              >
                <MemoizedImageRow
                  key={"imagerow_background_rnd_" + i + "_" + t + useName}
                  useName={useName}
                  cursor={isSelectable ? "pointer" : "default"}
                  useKey={"usekey_imagerow_background_rnd_" + i + "_" + t}
                  isSelected={
                    this.state.toolbarSelect === TOOLBAR_SELECT_BACKGROUNDS &&
                    this.state.selectionIndex === i
                  }
                  useWidth={useWidth}
                  useHeight={this.state.backgroundscrollHeight}
                  useXpos={useXpos}
                  useSrc={useImageList[0].src}
                  useImages={useImageList}
                  onClick={(index) => {
                    if (isSelectable) {
                      this.setState({
                        toolbarSelect: TOOLBAR_SELECT_BACKGROUNDS,
                        selectionIndex: index,
                      });
                    }
                  }}
                />
              </div>
            );
          });
        })
      : "";
  }

  renderThemeModal() {
    return (
      <Modal
        style={{
          width: "350px",
          height: "550px",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        show={this.state.showThemeColorPicker}
        onHide={() => {
          this.setState({
            showThemeColorPicker: false,
          });
        }}
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-center m-auto">
            <h4
              className="mx-auto"
              style={{
                fontSize: "20px",
              }}
            >
              Select Theme
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Row>
            <div>
              {this.state.template3 &&
              this.state.template3.config &&
              this.state.template3.config.templateColorOptions &&
              this.state.template3.config.templateColorOptions.length === 1 &&
              this.state.template3.config.templateColorOptions[0].toLowerCase() ===
                "all" ? (
                <div style={{ marginLeft: "10%" }}>
                  <SketchPicker
                    color={this.state.template3.config.templateColor}
                    onChange={(color) => {
                      let templ = this.state.template3;
                      let colorHex = color.hex;
                      colorHex = colorHex.substring(colorHex.indexOf("#") + 1);
                      templ.config.templateColor = colorHex;
                      this.setState({ template3: templ }, () =>
                        this.saveUserConfig()
                      );
                    }}
                  />
                </div>
              ) : this.state.template3 &&
                this.state.template3.config &&
                this.state.template3.config.templateColorOptions &&
                this.state.template3.config.templateColorOptions.length > 1 ? (
                <>
                  <div>
                    <Wheel
                      key="theme_color_wheel"
                      xOffset={0}
                      yOffset={500}
                      selectedIndex={this.state.template3.config.templateColorOptions.indexOf(
                        this.state.template3.config.templateColor
                      )}
                      colorList={this.state.template3.config.templateColorOptions.map(
                        (color) => {
                          return "#" + color;
                        }
                      )}
                      onClick={(index) => {
                        let templ = this.state.template3;
                        for (
                          let p = 0;
                          p < templ.config.backgroundClips.length;
                          p++
                        ) {
                          if (
                            templ.config.backgroundClips[p].videoSrcOptions &&
                            templ.config.backgroundClips[p].videoSrcOptions
                              .length > index
                          ) {
                            templ.config.backgroundClips[p].videoSrc =
                              templ.config.backgroundClips[p].videoSrcOptions[
                                index
                              ];
                          }
                        }
                        templ.config.templateColor =
                          this.state.template3.config.templateColorOptions[
                            index
                          ];
                        this.saveUserConfig();
                        this.setState({ template3: templ });
                      }}
                    />
                  </div>
                </>
              ) : (
                "No colors"
              )}
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <LogoutButton
            fontSize="11px"
            title={<span>Close</span>}
            onClick={() => {
              this.setState({
                showThemeColorPicker: false,
              });
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  renderSelectNewTemplate() {
    return (
      <TemplateSelect
        marketPlace={this.state.marketPlace}
        projects={this.state.projects}
        getProjects={() => {
          return this.state.projects;
        }}
        projList={this.state.projList}
        editProject={(id, projectName, config) => {
          this.editProject(id, projectName, config);
          this.openVideoEditorTab(true);
        }}
      />
    );
  }

  renderGenericSpinnerModal() {
    return (
      <CenteredModal
        dialogClassName="modal-60w"
        show={this.state.showGenericSpinnerModal}
        title={this.state.genericSpinnerModalTitle}
        content={
          <div
            key={"generic_modal_logo_main_spinner"}
            className="wrap"
            style={{ height: "8vh" }}
            id="generic_modal_drag_container"
          >
            <img
              key={"generic_img_modal_logo_main_spinner"}
              src={logo}
              alt="Loading..."
              className="rotate"
              width="80"
              height="auto"
            />
          </div>
        }
      />
    );
  }

  renderMainSpinnerModal() {
    return (
      <Modal
        show={this.state.showProgress}
        backdrop="static"
        keyboard={false}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        onHide={() => {
          this.setState({
            showPublishModal: false,
          });
        }}
        centered
      >
        <Modal.Body>
          <div style={{ margin: "3em" }}>
            <div>
              <h4>
                <strong>Loading...</strong>
              </h4>
            </div>
            <div
              key={"modal_logo_main_spinner"}
              className="wrap"
              id="modal_drag_container"
            >
              <img
                key={"img_modal_logo_main_spinner"}
                src={logo}
                alt="Loading..."
                className="rotate"
                width="80"
                height="auto"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  renderTourModal() {
    return (
      <Joyride
        run={this.state.runTour}
        callback={() => null}
        continuous
        showProgress
        showSkipButton
        steps={this.state.steps}
        styles={{
          options: {
            width: 500,
            primaryColor: "#d91266",
            arrowColor: "#d91266",
          },
        }}
      />
    );
  }

  renderAssetsModal() {
    return (
      <Modal
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        show={this.state.showAssetsModal}
        onHide={() => {
          this.setState({
            showAssetsModal: false,
          });
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                marginTop: "1px",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Select From Uploads:
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row
            style={{
              margin: "0px",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "row",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {this.state.showAssetsModal &&
              this.state.assets &&
              this.state.assets.length > 0 &&
              this.state.assets.map((asset, i) => {
                return asset.type === this.state.assetsModalObj.type ? (
                  <div
                    id={"div_img_image_assets_thumbs+" + asset.name}
                    key={"div_img_image_assets_thumbs+" + asset.name}
                    style={{ margin: "5px" }}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          className="show"
                          id={"overlay_img_image_assets_thumbs+" + asset.name}
                          key={"overlay_img_image_assets_thumbs+" + asset.name}
                        >
                          {asset.name}
                        </Tooltip>
                      }
                      placement="top"
                      delayShow={300}
                      delayHide={150}
                    >
                      <img
                        key={"img_image_assets_thumbs+" + asset.name}
                        alt=""
                        src={
                          asset.type === "video"
                            ? this.state.assetsThumbnails[asset.name]
                            : asset.url
                        }
                        width={DEFAULT_ASSET_IMAGE_WIDTH}
                        draggable="false"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.state.assetsModalObj.onClick(
                            asset.name,
                            asset.url
                          );
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                ) : (
                  ""
                );
              })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="wrap">
            <LogoutButton
              style={{ float: "center" }}
              fontSize="14px"
              title={<span>Close</span>}
              onClick={() => {
                this.setState({
                  showAssetsModal: false,
                });
              }}
            />
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }

  renderPreviewModal() {
    return (
      <Modal
        show={this.state.showPreviewModal}
        backdrop="static"
        keyboard={false}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        onHide={() => {
          this.setState({
            showPreviewModal: false,
          });
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                marginTop: "1px",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Exporting Video
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {this.state.publishInProgress ? (
            <p style={{ marginTop: "1px", fontSize: 16 }}>
              Preview Launch is unavailable while Publish is in progress.
            </p>
          ) : (
            <div>
              <p>
                {"We're exporting your changes." +
                  "\n This window will close when" +
                  "\n processing has completed." +
                  "\n Check back in a few minutes."}
              </p>
              <ProgressBar
                leftCount={this.state.previewProgress}
                rightCount={100 - this.state.previewProgress}
                innerBarPercentage={this.state.previewProgress}
                centerSubtitle={
                  "Processing video " +
                  this.state.previewProgress.toFixed(0) +
                  "%"
                }
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  renderPublishModal() {
    return (
      <Modal
        show={this.state.showPublishModal}
        backdrop="static"
        keyboard={false}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        onHide={() => {
          this.setState({
            showPublishModal: false,
          });
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                marginTop: "1px",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Ready to publish your video?
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            {this.state.previewLaunched && !this.state.publishInProgress ? (
              <div>
                <Row className="wrap">
                  <p style={{ marginTop: "1px", fontSize: 16 }}>
                    Publish is unavailable while video export is in progress.
                  </p>
                </Row>
                <Row className="wrap">
                  <LogoutButton
                    style={{ marginTop: "1em", marginBottom: "1em" }}
                    fontSize="14px"
                    title={
                      <span>
                        <i className="fa fa-remove" /> Close{" "}
                      </span>
                    }
                    onClick={() => {
                      this.setState({ showPublishModal: false });
                    }}
                  />
                </Row>
              </div>
            ) : !this.state.publishInProgress ? (
              <div>
                <Row className="wrap">
                  <p style={{ marginTop: "1px", fontSize: 16 }}>
                    We will do a final rendering of your project and send the
                    completed video to Amazon for approval. If you agree to
                    proceed this project will be locked from any further
                    changes.
                  </p>
                </Row>
                <Row className="wrap">
                  <LogoutButton
                    style={{ marginTop: "1em", marginBottom: "1em" }}
                    fontSize="14px"
                    title={
                      <span>
                        <i className="fa fa-check" /> Proceed{" "}
                      </span>
                    }
                    onClick={() => {
                      this.setState({ publishInProgress: true }, () => {
                        this.launchPreviewConfig(false, (success) => {
                          if (success) {
                            this.publish();
                          } else {
                            this.setState({ publishInProgress: false }, () =>
                              this.props.handleNotification(
                                "tr",
                                "warning",
                                "pe-7s-attention",
                                "Publish failed. Please try again. If issue continues please contact Trellis.",
                                "long"
                              )
                            );
                          }
                        });
                      });
                    }}
                  />
                  <LogoutButton
                    style={{ marginTop: "1em" }}
                    fontSize="14px"
                    title={
                      <span>
                        <i className="fa fa-remove" /> Cancel{" "}
                      </span>
                    }
                    onClick={() => {
                      this.setState({ showPublishModal: false });
                    }}
                  />
                </Row>
              </div>
            ) : (
              <div>
                <Row className="wrap">
                  <p>{"We're preparing your video for publishing."}</p>
                </Row>
                <Row className="wrap">
                  <div
                    style={{
                      width: "80%",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    <ProgressBar
                      leftCount={this.state.previewProgress}
                      rightCount={100 - this.state.previewProgress}
                      innerBarPercentage={this.state.previewProgress}
                      centerSubtitle={
                        "Rendering video " +
                        this.state.previewProgress.toFixed(0) +
                        "%"
                      }
                    />
                  </div>
                </Row>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  renderEnterProjectInfo() {
    return (
      <Row
        className="justify-content-md-center"
        style={{
          marginLeft: "10px",
          marginRight: "0px",
          marginTop: "20px",
        }}
      >
        <div style={{ width: "95%", marginLeft: "10%", marginTop: "4em" }}>
          <Row>
            <Col md={3}>
              <div>
                <h5>Enter your project name:</h5>
              </div>
            </Col>
            <Col md={3}>
              <FormControl
                key={"enter_project_name"}
                componentClass="textarea"
                rows="1"
                placeholder={"Enter Project Name"}
                name={"Enter Project Name"}
                style={{ resize: "none", borderRadius: 22 }}
                bsClass="form-control"
                value={this.state.projectName}
                onChange={(e) => {
                  this.setState({
                    projectNameErrorMessage: "",
                    projectName: e.target.value.trim(),
                  });
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "3em" }}>
            <Col md={3}>
              <div>
                <h5>Enter your project description:</h5>
              </div>
            </Col>
            <Col md={3}>
              <FormControl
                key={"enter_project_desc"}
                componentClass="textarea"
                rows="2"
                placeholder={"Enter Project Description"}
                name={"Enter Project Description"}
                style={{ resize: "none", borderRadius: 22 }}
                bsClass="form-control"
                value={this.state.projectDescription}
                onChange={(e) => {
                  this.setState({
                    projectNameErrorMessage: "",
                    projectDescription: e.target.value,
                  });
                }}
              />
              <div
                style={{
                  marginTop: "62px",
                  marginBottom: "172px",
                  marginLeft: "10%",
                }}
              >
                <LogoutButton
                  style={{ float: "left" }}
                  fontSize="14px"
                  title={
                    <span>
                      <i className="fa fa-file-video-o" /> Start Editing{" "}
                    </span>
                  }
                  onClick={() => {
                    if (this.state.projectName === "") {
                      this.setState({
                        projectNameErrorMessage: "Please enter a Project Name",
                      });
                      return;
                    }
                    this.updateProjectState(
                      VideoEditorProjectState.PROJ_STATE_PREPARE_EDITING
                    );
                    this.createUserConfig();
                  }}
                />
                <p
                  style={{
                    clear: "both",
                    paddingTop: "2em",
                    color: "red",
                  }}
                >
                  {this.state.projectNameErrorMessage}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }

  renderSelectNewTemplateModal() {
    return (
      <CenteredModal
        show={this.state.showSelectNewTemplateModal}
        closeModal={() => this.setState({ showSelectNewTemplateModal: false })}
        dialogClassName="modal-90wc"
        title="Create New Project"
        titleFontSize="20px"
        content={
          <TemplateSelect
            marketPlace={this.state.marketPlace}
            projects={this.state.projects}
            getProjects={() => {
              return this.state.projects;
            }}
            projList={this.state.projList}
            editProject={(id, projectName, config) => {
              this.editProject(id, projectName, config);
            }}
          />
        }
        onHide={() => this.setState({ showSelectNewTemplateModal: false })}
      />
    );
  }

  FloaterContent = () => {
    return (
      <div
        style={{
          backgroundColor: "#d91266",
          maxWidth: "300px",
          padding: "3rem 2rem 2rem",
          textAlign: "center",
          borderRadius: 100,
          margin: "0 auto",
        }}
      >
        <p style={{ color: "white", fontSize: "17px" }}>
          {this.state.showHelpTextMiddle
            ? "Click on the text below to edit"
            : this.state.showHelpGetStarted
            ? "Click on text or images on the timeline"
            : "Invalid"}
        </p>
      </div>
    );
  };

  renderHelpTextMiddleModal() {
    return (
      <>
        <Floater
          component={this.FloaterContent}
          open={this.state.showHelpTextMiddle || this.state.showHelpGetStarted}
          target=".tour_button_div"
          placement="left"
          styles={{
            arrow: {
              color: "#d91266",
            },
            floater: {
              maxWidth: 500,
              width: "100%",
            },
            options: {
              zIndex: 250,
              position: true,
            },
            wrapperOptions: {
              offset: -22,
              placement: "top",
              position: true,
            },
          }}
        ></Floater>
        {this.state.showHelpTextMiddle && (
          <div // transparent overlay
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.01)",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: 100,
            }}
            onClick={() => {
              this.setState({
                showHelpTextMiddle: false,
              });
            }}
          />
        )}
      </>
    );
  }

  getTextForegoundClipColor(textIndex = -1) {
    let useTextEditIndex =
      textIndex > -1 ? textIndex : this.state.textEditSelectionIndex;
    let clip =
      this.state.template3.config.textForegroundClips[
        this.state.selectionIndex
      ];
    let rtn = "000000";
    if (
      useTextEditIndex >= 0 &&
      clip.color &&
      clip.color.length > useTextEditIndex
    ) {
      if (
        clip.color[useTextEditIndex] !== "theme" &&
        clip.color[useTextEditIndex] !== "match"
      ) {
        rtn = clip.color[useTextEditIndex];
      } else if (clip.color[useTextEditIndex] === "theme") {
        rtn = this.state.template3.config.templateColor;
      } else if (clip.color[useTextEditIndex] === "match") {
        rtn = clip.color.find((item) => item !== "theme" && item !== "match");
      }
    } else {
      let useColor;
      if (clip.color[0] === "theme") {
        useColor = this.state.template3.config.templateColor;
      } else {
        useColor = clip.color.find(
          (item) => item !== "theme" && item !== "match"
        );
      }
      rtn = useColor ? useColor : "000000";
    }

    let r = {
      value: rtn,
      label: rtn,
      color: "#" + rtn,
    };
    return r;
  }

  getTextForegoundClipColorOptions() {
    let clip =
      this.state.template3.config.textForegroundClips[
        this.state.selectionIndex
      ];
    let avail = ["000000"];
    let options = [];
    if (clip.availableColors && clip.availableColors.length > 0) {
      if (
        this.state.textEditSelectionIndex >= 0 &&
        clip.availableColors.length > this.state.textEditSelectionIndex
      ) {
        avail = clip.availableColors[this.state.textEditSelectionIndex];
      } else {
        avail = clip.availableColors.find((item) => item.length > 0);
      }
    }
    for (let c of avail) {
      options.push({
        value: c,
        label: c,
        color: "#" + c,
      });
      return options;
    }
  }

  render() {
    return this.state.resetVideoEditorDisplay ? (
      <div id="main_div" key="main_div" style={{ marginBottom: "-40px" }}>
        {this.renderMainSpinnerModal()}
      </div>
    ) : (
      <div id="main_div" key="main_div" style={{ marginBottom: "-40px" }}>
        <div id="modals">
          {this.renderThemeModal()}
          {this.renderTourModal()}
          {this.renderMainSpinnerModal()}
          {this.renderGenericSpinnerModal()}
          {this.renderAssetsModal()}
          {this.renderPublishModal()}
          {this.renderPreviewModal()}
          {this.renderSelectNewTemplateModal()}
          {this.renderHelpTextMiddleModal()}
        </div>
        <Row
          id="main_top_row1"
          key="main_top_row1"
          style={{
            height: "auto",
            backgroundColor: "#ffffff",
            marginTop: "2rem",
            borderBottom: "solid #CFCFCF 1px",
          }}
        >
          <Col
            md={3}
            id="main_row1_col1_proj_name"
            key="main_row1_col1_proj_name"
          >
            <div id="project_info">
              {this.renderProjectInfo()}
              {this.renderProjectLastSaved()}
            </div>
          </Col>
          <Col
            md={7}
            id="main_row1_col2_middle_buttons"
            key="main_row1_col2_middle_top__buttons"
            style={{ width: "30%" }}
          >
            <Col md={2} style={{ width: "30%" }}></Col>
            <Col md={2} style={{ width: "100%" }}>
              {this.renderNewProject()}
            </Col>
            <Col md={2} style={{ width: "10%" }}></Col>
          </Col>
          <Col
            md={3}
            id="main_row1_col3_far_right_buttons"
            key="main_row1_col3_far_right_buttons"
            style={{ float: "right", width: "auto", marginLeft: "auto" }}
          >
            {this.renderTopRight()}
          </Col>
        </Row>
        <Row
          id="main_row2"
          key="main_row2"
          style={{
            height: 50 + DEFAULT_BACKGROUND_VIDEO_HEIGHT + "px",
          }}
        >
          <Col // LEFT DISPLAY
            id="left_view"
            className="left_view"
            key="Assets_col"
            md={3}
            style={{
              height: 50 + DEFAULT_BACKGROUND_VIDEO_HEIGHT + "px",
              borderBottom: "solid #CFCFCF 1px",
            }}
          >
            {this.renderBackgroundOptionsThemeLeft()}
            {this.renderPreviewLeft()}
            {this.renderAssets()}
            {this.renderTextLeft()}
          </Col>
          <Col // MIDDLE DISPLAY
            id="middle_display_col"
            key="Middle_display_col"
            className="middle_display_help_target"
            md={6}
            style={{
              height: 50 + DEFAULT_BACKGROUND_VIDEO_HEIGHT + "px",
              backgroundColor: "#F7F7F7",
              borderLeft: "solid #CFCFCF 1px",
              borderRight: "solid #CFCFCF 1px",
              borderBottom: "solid #CFCFCF 1px",
            }}
          >
            <Row
              id="Middle_display_row"
              key="Middle_display_row"
              className="wrap"
              style={{
                border: "solid #F7F7F7 5px",
              }}
            >
              {this.renderMiddleDisplay()}
            </Row>
          </Col>
          <Col // RIGHT DISPLAY
            md={3}
            id="Right_display_col"
            className="right_display_help_target"
            style={{
              height: 50 + DEFAULT_BACKGROUND_VIDEO_HEIGHT + "px",
              overflowY: "auto",
              overflowX: "hidden",
              borderBottom: "solid #CFCFCF 1px",
            }}
          >
            <Col
              className="wrap d-flex"
              style={{
                border: "solid #F7F7F7 1px",
              }}
            >
              {this.renderRightDisplay()}
            </Col>
          </Col>
        </Row>
        <Row>
          <div id="timeline_help_target" />
        </Row>
        <div id="timeline_row" className="timeline_help_target">
          <Row>
            <div
              style={{
                width: "100%",
                height: "4px",
                position: "relative",
                marginRight: "-10px",
              }}
            />
          </Row>
          {!this.state.showProgress && !this.state.delayLoading && (
            <div
              id="outer_div_render_timeline"
              key="outer_div_render_timeline"
              style={{
                paddingLeft: "1em",
                paddingRight: "1em",
                paddingBottom: "1em",
                paddingTop: "0em",
              }}
            >
              <Row
                key={"outer_row_render_timeline"}
                style={{ marginTop: "1em" }}
              >
                <div style={{ padding: "2px" }} />
              </Row>
              <Row
                style={{
                  marginBottom: this.state.timelineHeight,
                  position: "relative",
                  marginLeft: "4px",
                }}
                key={"outer_row_timeline_row"}
              >
                {this.renderTimeline()}
              </Row>
              <Row style={{ marginTop: "3px" }} />
              <div
                key={"outer_outer_div_timeline_row"}
                style={{ marginLeft: "17px" }}
              >
                {this.renderTextTimeline()}
              </div>
              <Row style={{ marginTop: "3px" }} />
              <div
                key={
                  "outer_outer_div_image_timeline_row" + this.state.windowWidth
                }
                // style={{ marginLeft: "17px" }}
              >
                {this.renderImagesTimeline()}
              </div>
              <div key={"outer_outer_div_background_row_outside_row"}>
                <Row
                  style={{
                    margin: "3px",
                    background: "#F7F7F7",
                    width:
                      this.calculateWidthPerSecond(this.state.totalTimeSecs) *
                        this.state.totalTimeSecs +
                      2,
                  }}
                >
                  <div
                    key={
                      "outer_div_background_row_inside_row" +
                      this.state.windowWidth
                    }
                    style={{
                      width: "100%",
                      height: "" + this.state.backgroundscrollHeight + "px",
                      position: "relative",
                    }}
                  >
                    {this.renderBackgroundTimeline()}
                  </div>
                </Row>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(withRouter(VideoEditor));
