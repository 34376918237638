import React, { useState, useEffect } from "react";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import { useTabIndex } from "react-tabindex";
import Switch from "react-bootstrap-switch";

const formatCellValue = (value, format) => {
  if (format === "currency") {
    return parseFloat(value).toFixed(2);
  }

  if (format === "percent") {
    return parseFloat(value).toFixed(1);
  }

  return parseFloat(value).toFixed(1);
};

const EditCell = ({ row, value, column, options, marketPlace }) => {
  let formattedDefault = formatCellValue(value, options?.format);

  const [editValue, setEditValue] = useState(formattedDefault);
  const [selected, setSelected] = useState(false);

  let tabIndex = useTabIndex(options?.tabIndex);

  const { validations, format, notificationRef } = options;

  useEffect(() => {
    setEditValue(formatCellValue(value, format));
    setSelected(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const validate = (value, key) => {
    const { minValue, maxValue, blank } = validations;

    // Validation handling simple for now, if invalid value, reset to prev when input blurred
    if (value < minValue) {
      sendErrorNotification(
        `Invalid value. ${column.Header} must be at least ${
          format === "currency"
            ? MARKETPLACE_CURRENCY_DENOMINATION[
                marketPlace?.marketPlace ?? "USA"
              ]
            : ""
        }${minValue}${format === "percent" ? "%" : ""}.`
      );
      setEditValue(formatCellValue(row._original[key], format));
      return false;
    }
    if (value > maxValue) {
      sendErrorNotification(
        `Invalid value. ${column.Header} must be less than ${
          format === "currency"
            ? MARKETPLACE_CURRENCY_DENOMINATION[
                marketPlace?.marketPlace ?? "USA"
              ]
            : ""
        }${maxValue}${format === "percent" ? "%" : ""}.`
      );

      setEditValue(formatCellValue(row._original[key], format));
      return false;
    }
    if (!value && !blank) {
      sendErrorNotification(`Invalid value. ${column.Header} is required.`);
      setEditValue(formatCellValue(row._original[key], format));
      return false;
    }

    return true;
  };

  const sendErrorNotification = (message) => {
    if (!notificationRef || !notificationRef.current) {
      return;
    }
    notificationRef.current.addNotification({
      title: (
        <span>
          <span data-notify="icon" className="pe-7s-attention" /> Error
        </span>
      ),
      message: (
        <div>
          {" "}
          <b>{message}</b>{" "}
        </div>
      ),
      level: "warning",
      position: "tr",
      dismissible: "button",
    });
  };

  if (format === "toggle") {
    return (
      <div className="h-100 w-100 d-flex align-items-center justify-content-end">
        <Switch
          onText=""
          offText=""
          value={row._original[column.id] === options?.onValue}
          bsSize="small"
          onColor="#FFFFFF"
          onChange={(e) => {
            const { onValue, offValue } = options;

            let updateValue =
              row._original[column.id] === onValue ? offValue : onValue;

            if (
              options?.toggleAction &&
              typeof options?.toggleAction === "function"
            ) {
              options.toggleAction(row._original, updateValue);

              return;
            }

            row._original.handleUpdate(row, column.id, updateValue);
          }}
        />
      </div>
    );
  }

  let hidden;

  if (typeof options?.renderPlaceholderCellContent === "function") {
    hidden = options?.renderPlaceholderCellContent(row);
  }

  if (hidden) {
    return (
      <div className="d-flex align-items-center justify-content-end h-100">
        -
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center h-100 pl-3">
      <span>
        {format === "currency" &&
          MARKETPLACE_CURRENCY_DENOMINATION[marketPlace?.marketPlace ?? "USA"]}
      </span>
      <div className="h-100 d-flex align-items-center justify-content-end">
        <span
          style={{
            position: "absolute",
            marginRight: "-15px",
            marginTop: "-2px",
          }}
        >
          {format === "percent" ? "%" : ""}
        </span>

        <input
          type="number"
          className={`text-end ${
            options?.strikeThroughText ? options?.strikeThroughText(row) : ""
          }`}
          value={editValue}
          onChange={(e) => {
            setEditValue(e.target.value);
          }}
          onClick={() => {
            setSelected(true);
          }}
          tabIndex={tabIndex}
          onBlur={(e) => {
            let key = column.id;
            setSelected(false);

            let validInput = validate(editValue, key);
            if (!validInput || row._original[key] === parseFloat(editValue)) {
              return;
            }

            row._original.handleUpdate(row, key, editValue);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
          style={{
            width: "100%",
            border: "none",
            borderBottom: selected ? "1px solid #555" : "1px solid #EEF",
            backgroundColor: "transparent",
            paddingLeft: "25px",
          }}
        />
      </div>
    </div>
  );
};

export default EditCell;
