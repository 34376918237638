import React, { useState } from "react";
import { Button, Dialog, ThemeProvider, makeStyles } from "@material-ui/core";
import logo from "../../assets/images/logo/Trellis_Logomark_color.png";
import getURLPrefix from "../../utils/getUrlPrefix";
import axios from "axios";
import themeLargerFont from "../../assets/themes/TrellisTheme";
import FormGroupTile from "components/core/basic/FormGroupTile";

const useStyles = makeStyles((theme) => {
  return {
    centered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minHeight: 200,
    },
    square: {
      borderRadius: 0,
    },
    fullWidth: {
      width: "100%",
    },
    lineHeightFix: {
      lineHeight: "unset",
    },
  };
});

const WalmartMarketplaceSetup = (props) => {
  const { open, onClose, reauth } = props;
  const classes = useStyles();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState(null);

  const submitKeys = async () => {
    let URL_PREFIX = getURLPrefix();

    setShowLoading(true);
    setLoadingMsg("Submitting...");
    if (reauth) {
      await axios
        .put(`${URL_PREFIX}/walmart/auth/marketplace`, {
          client_id: clientId,
          client_secret: clientSecret,
        })
        .then((res) => {
          setLoadingMsg("");
          setShowLoading(false);
          onClose();
        })
        .catch(function (error) {
          setErrorMsg("Error while submitting keys, please try again.");
          setShowLoading(false);
        });
      return;
    }

    await axios
      .post(`${URL_PREFIX}/walmart/auth/marketplace`, {
        client_id: clientId,
        client_secret: clientSecret,
      })
      .then((res) => {
        setLoadingMsg("");
        setShowLoading(false);
        onClose();
      })
      .catch(function (error) {
        setErrorMsg("Error while submitting keys, please try again.");
        setShowLoading(false);
      });
  };

  return (
    <ThemeProvider theme={themeLargerFont}>
      <Dialog
        open={open}
        onClose={() => onClose && onClose()}
        fullWidth
        maxWidth="sm"
      >
        {showLoading ? (
          <div className={classes.centered}>
            <img src={logo} alt="Loading..." className={"rotate"} width="80" />
            {loadingMsg.length > 0 ? <div>{loadingMsg}</div> : null}
            {errorMsg !== "" ? <div>{errorMsg}</div> : null}
          </div>
        ) : (
          <div className={classes.centered} style={{ padding: "2rem" }}>
            <div style={{ minWidth: "400px" }}>
              <div style={{ paddingBottom: "1rem" }}>
                <FormGroupTile
                  type="formcontrol"
                  label={"Client ID"}
                  defaultValue={clientId}
                  formControlType={"text"}
                  stateName={"title"}
                  handleChildFormElement={(id, value) => {
                    setClientId(value);
                  }}
                  placeholder={"Enter Walmart Marketplace Client ID"}
                />
              </div>
              <div style={{ paddingBottom: "1rem" }}>
                <FormGroupTile
                  type="formcontrol"
                  label={"Client Secret"}
                  formControlType={"text"}
                  stateName={"title"}
                  defaultValue={clientSecret}
                  handleChildFormElement={(id, value) => {
                    setClientSecret(value);
                  }}
                  placeholder={"Enter Walmart Markteplace Client Secret"}
                />
              </div>
            </div>
            <Button
              variant={"contained"}
              size={"large"}
              color={"primary"}
              className={classes.square}
              onClick={() => submitKeys()}
            >
              Submit
            </Button>
          </div>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default WalmartMarketplaceSetup;
