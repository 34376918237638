import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFetch } from "hooks/api";
import { AuthButton } from "components/core/basic/Button";
import { useHistory, Redirect } from "react-router-dom";
import WalmartAdvertisingSetup from "views/integrations/WalmartAdvertisingSetup";
import WalmartMarketplaceSetup from "views/integrations/WalmartMarketplaceSetup";
import Loading from "components/core/blocks/Loading";
import ConnectSecondaryChannelWidget from "views/onboarding/components/ConnectSecondaryChannelWidget";
import { useDispatch, useSelector } from "react-redux";
import { setChannel } from "redux/actions/channel";
import AdditionalWalmartOrgModal from "./AdditionalWalmartOrgModal";
import WalmartProfilesTable from "./WalmartProfilesTable";

const WalmartSummary = () => {
  const [showWalmartAdvertiserModal, setShowWalmartAdvertiserModal] =
    useState(false);
  const [showWalmartMarketplaceModal, setShowWalmartMarketplaceModal] =
    useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user, channel } = useSelector((state) => state);

  const {
    data: walmartOnboardingData,
    isLoading: isWalmartOnboardingDataLoading,
  } = useFetch(
    ["walmartAdsInfo"],
    `/walmart/auth/onboarding`,
    {},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (res) => res.data,
    }
  );

  const { data: amazonProfiles } = useFetch(
    ["amazonProfiles"],
    `/api/accounts/`,
    {
      channel: "amazon",
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (res) => {
        const formattedSellerData = Object.keys(res.data.seller)?.map(
          (profile) => ({ profile_name: profile, type: "seller" })
        );
        const formattedVendorData = Object.keys(res.data.vendor).map(
          (profile) => ({ profile_name: profile, type: "vendor" })
        );

        return [...formattedSellerData, ...formattedVendorData];
      },
    }
  );

  if (isWalmartOnboardingDataLoading) {
    return <Loading fullPage={true} />;
  }

  if (channel.currentChannel !== "walmart") {
    return <Redirect to="/user/amzsummary" />;
  }

  return (
    <Row className="m-0" style={{ minHeight: "calc(100vh - 155px)" }}>
      <Col xs={12} lg={{ span: 10, offset: 1 }}>
        <h1
          className="text-center pt-5"
          style={{ color: "#393939", fontSize: "34px", fontWeight: 700 }}
        >
          Walmart Onboarding
        </h1>
        <p className="text-center py-3 fs-3">
          Please confirm your following information is correct
        </p>

        <Row className="border-bottom mx-5 py-4">
          <Col xs={12} lg={9}>
            <h3 className="fs-1" style={{ fontWeight: 600 }}>
              Walmart Advertising Account
            </h3>
            <Row>
              <Col xs={6} className="py-3">
                <p className="fs-3 fw-500">Region:</p>
              </Col>
              <Col xs={6} className="py-3">
                <p className="fs-3">North America</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="py-3">
                <p className="fs-3 fw-500">
                  Your Available Advertising Marketplaces:
                </p>
              </Col>
              <Col xs={6} className="py-3">
                <p className="fs-3">United States</p>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex justify-content-end align-items-center"
          >
            <AuthButton
              title={
                walmartOnboardingData?.advertiser_id_provided
                  ? "Reauthorize"
                  : "Authorize"
              }
              onClick={() => {
                if (walmartOnboardingData?.advertiser_id_provided) {
                  setShowWalmartAdvertiserModal(true);
                  return;
                }

                history.push("/onboarding/v2/sellertype");
              }}
              buttonStyle="adplan_button"
              style={{ transform: "none" }}
            />
          </Col>
        </Row>
        {user.organization_type !== "vendor" && (
          <Row className="border-bottom mx-5 py-4">
            <Col xs={12} lg={9}>
              <h3 className="fs-1" style={{ fontWeight: 600 }}>
                Walmart Marketplace Account
              </h3>
              <Row>
                <Col xs={6} className="py-3">
                  <p className="fs-3 fw-500">Status:</p>
                </Col>
                <Col xs={6} className="py-3">
                  <p className="fs-3">
                    {walmartOnboardingData?.marketplace_credentials_provided
                      ? "Connected"
                      : "Not Connected"}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              lg={3}
              className="d-flex justify-content-end align-items-center"
            >
              <AuthButton
                title={
                  walmartOnboardingData?.marketplace_credentials_provided
                    ? "Reauthorize"
                    : "Authorize"
                }
                onClick={() => {
                  if (walmartOnboardingData?.advertiser_id_provided) {
                    setShowWalmartMarketplaceModal(true);
                    return;
                  }

                  history.push("/onboarding/v2/sellertype");
                }}
                buttonStyle="adplan_button"
                style={{ transform: "none" }}
              />
            </Col>
          </Row>
        )}
        <Row className="mx-5 pt-4">
          <Col xs={12} lg={9}>
            <h3 className="fs-1" style={{ fontWeight: 600 }}>
              All Walmart Accounts
            </h3>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex justify-content-end align-items-center"
          >
            <AdditionalWalmartOrgModal />
          </Col>
        </Row>
        <Row className="border-bottom mx-5 py-4">
          <Col xs={12}>
            <WalmartProfilesTable />
          </Col>
        </Row>
        <Row className="mx-5"></Row>
        <Row className="mx-5 d-flex justify-content-end py-5">
          <Col xs={12} lg={9}>
            <ConnectSecondaryChannelWidget
              secondaryChannel={"amazon"}
              linkToSummaryView={
                amazonProfiles?.length
                  ? "/user/amzsummary"
                  : `/onboarding/v2/connect?channel=amazon&secondaryChannel=amazon`
              }
              onClick={() => {
                if (amazonProfiles?.length) {
                  dispatch(setChannel("amazon"));
                  localStorage.setItem("channel", "amazon");
                }
              }}
            />
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex justify-content-end align-items-center"
          >
            <AuthButton
              title={
                user.permissions === "reports"
                  ? "Go to Reports"
                  : "Go to Dashboard"
              }
              buttonStyle="adplan_button"
              onClick={(e) => {
                user.permissions === "reports"
                  ? history.push("/user/report/advertisement")
                  : history.push("/user/dashboard");
              }}
            />
          </Col>
        </Row>
      </Col>
      {showWalmartAdvertiserModal && (
        <WalmartAdvertisingSetup
          open={showWalmartAdvertiserModal}
          onClose={() => {
            setShowWalmartAdvertiserModal(false);
          }}
          reauth={true}
        />
      )}
      {showWalmartMarketplaceModal && (
        <WalmartMarketplaceSetup
          open={showWalmartMarketplaceModal}
          onClose={() => {
            setShowWalmartMarketplaceModal(false);
          }}
          reauth={walmartOnboardingData?.marketplace_credentials_provided}
        />
      )}
    </Row>
  );
};

export default WalmartSummary;
