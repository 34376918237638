import React from "react";
import OrgSwitcher from "components/core/blocks/OrgSwitcher";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "redux/actions/authActions";
import CustomHref from "components/core/blocks/CustomHref";

const OnboardingFooter = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div
      className="d-flex justify-content-end"
      style={{
        position: "absolute",
        bottom: "25px",
        right: "50px",
        zIndex: 9000,
      }}
    >
      {user.is_staff && (
        <OrgSwitcher
          user={user}
          overrideModalButton={
            <CustomHref
              text={
                <span className="fs-3 me-5" style={{ whiteSpace: "nowrap" }}>
                  Switch Profiles
                </span>
              }
            />
          }
        />
      )}
      <CustomHref
        text={
          <span
            className="fs-3"
            onClick={(e) => {
              e.preventDefault();
              dispatch(logoutUser());
              history.push("/");
            }}
          >
            Logout
          </span>
        }
      />
    </div>
  );
};

export default OnboardingFooter;
