export const LAUNCH_STATES = {
  PREPARING: {
    id: "1",
    value: "PREPARING",
    name: "Preparing",
    color: "#A78DC2",
  },
  INTRODUCED: {
    id: "2",
    value: "INTRODUCED",
    name: "Introduced",
    color: "#0FC398",
  },
  LAUNCHED: {
    id: "3",
    value: "LAUNCHED",
    name: "Launched",
    color: "red",
  },
};

const STEPS = [
  {
    title: "Launch Setup",
    validations: ["name"],
  },
  {
    title: "Select Products",
    validations: ["category", "products", "landedCost"],
  },
  {
    title: "Launch Goals",
    validations: ["goals", "exitCriteria", "budget", "startDate", "duration"],
  },
];

const LAUNCH_DURATION_OPTIONS = [
  { label: "30 Days", value: 30 },
  { label: "60 Days", value: 60 },
  { label: "90 Days", value: 90 },
  { label: "120 Days", value: 120 },
  { label: "150 Days", value: 150 },
  { label: "180 Days", value: 180 },
];

const EXIT_CRITERIA_MAP = [
  {
    label: "Sales",
    stateName: "sales",
    format: "currency",
    defaultChecked: true,
    defaultValue: 1000,
  },
  {
    label: "Units",
    stateName: "units",
    format: "number",
    defaultChecked: true,
    defaultValue: 100,
  },
  {
    label: "# of Ratings",
    stateName: "numOfRatings",
    format: "number",
    defaultChecked: true,
    defaultValue: 10,
  },
];

const GOALS_MAP = [
  {
    label: "Margin",
    stateName: "margin",
    format: "percent",
    defaultChecked: true,
    defaultValue: 20,
  },
  {
    label: "Max ACOS",
    stateName: "acos",
    format: "percent",
    defaultChecked: true,
    defaultValue: 40,
  },
  {
    label: "Minimum Rating",
    stateName: "minRating",
    format: "number",
    defaultChecked: true,
    defaultValue: 3.5,
  },
];

const BUDGET_MAP = [
  {
    label: "Advertising",
    stateName: "advertising",
    format: "currency",
    defaultChecked: true,
    defaultValue: 10,
  },
];

const DEFAULT_GOALS = {};
const DEFAULT_BUDGET = {};
const DEFAULT_EXIT_CRITERIA = {};

GOALS_MAP.forEach((goal) => {
  DEFAULT_GOALS[goal.stateName] = {
    checked: goal.defaultChecked,
    value: goal.defaultValue,
  };
});

BUDGET_MAP.forEach((budget) => {
  DEFAULT_BUDGET[budget.stateName] = {
    checked: budget.defaultChecked,
    value: budget.defaultValue,
  };
});

EXIT_CRITERIA_MAP.forEach((criteria) => {
  DEFAULT_EXIT_CRITERIA[criteria.stateName] = {
    checked: criteria.defaultChecked,
    value: criteria.defaultValue,
  };
});

export {
  STEPS,
  DEFAULT_GOALS,
  DEFAULT_BUDGET,
  DEFAULT_EXIT_CRITERIA,
  GOALS_MAP,
  BUDGET_MAP,
  EXIT_CRITERIA_MAP,
  LAUNCH_DURATION_OPTIONS,
};
