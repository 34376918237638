import React from "react";
import { useFetch } from "hooks/api";
import { Row, Col, Table } from "react-bootstrap";
import moment from "moment";
import Loading from "components/core/blocks/Loading";
import { formatCurrency } from "utils/formatNumber";

const BiddingProgramLog = ({
  programId,
  targetId,
  targetType = "keyword",
  marketPlace,
  title,
}) => {
  const logQueryFilters = {
    [`adv_${targetType}`]: targetId,
    only_action: true,
  };

  const { data, isLoading } = useFetch(
    ["program_logs", programId, targetId, targetType],
    `/programlogs/advertised_${targetType}s/`,
    logQueryFilters,
    { select: (data) => data?.data?.results ?? [] }
  );

  const LogEntries = ({ log }) => {
    const logMatchRegex = /checking \d+?/m;

    const entries = [];
    let foundScope = false;

    try {
      for (let i = 0; i < log.length; i++) {
        const line = log[i];
        if (line.startsWith("getting scope")) {
          if (!foundScope) {
            entries.push(
              <div className="fw-bold py-2">
                {line.replace("getting scope, operator: after ", "")}
              </div>
            );
          }
          foundScope = true;
        } else if (line.startsWith("checking rule Rule")) {
          const f = line.match(/function='([^']+)/)[1];
          const operators = line.match(/operator='([^']+)/g);
          const values = line.match(/values=\[([^\]]+)/g);

          let daysRule = `${f} by ${values[2].replace("values=[", "")}% `;
          daysRule += `if units sold ${operators[0].replace(
            "operator='",
            ""
          )} ${values[0].replace("values=[", "")} days `;
          daysRule += `${operators[2].replace("operator='", "")} `;
          daysRule += `${values[1]
            .replace("values=[", "")
            .split(",")[2]
            .trim()}`;
          entries.push(<div className={`ps-3`}>Rule: {daysRule}</div>);
        } else if (line.match(logMatchRegex)) {
          const parts = line.split("(");
          const logMatch = parts[0].trim();
          entries.push(
            <div className={`ps-3`}>
              Match on Rule: <em>{logMatch}</em>
            </div>
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
    return entries;
  };
  return (
    <>
      <Row className="pt-5">
        <Col>
          <div className="pb-3">
            <span className="fs-4 fw-bold">{title} Logs</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoading && <Loading height={"300px"} fullPage={false} />}
          {!isLoading && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Program</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((log, i) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>{moment(log.ts).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>
                          {log?.program?.name} ({log?.program?.id})
                        </td>
                        <td>
                          {log?.meta[1]?.function !== undefined ? (
                            <div>
                              <span className="fw-bold">
                                {log?.meta[1]?.function}
                              </span>
                              :&nbsp;
                              <span className="fw-light">
                                New:{" "}
                                {formatCurrency(
                                  log?.meta[1]?.next_bid,
                                  marketPlace.marketPlace
                                )}
                              </span>
                              &nbsp; - &nbsp;
                              <span className="fw-light">
                                Prev:{" "}
                                {formatCurrency(
                                  log?.meta[1]?.current_bid,
                                  marketPlace.marketPlace
                                )}
                              </span>
                            </div>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colspan={3}>
                          <LogEntries log={log?.meta[0]?.logs} />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </>
  );
};

export default BiddingProgramLog;
