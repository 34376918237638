import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Label } from "components/custom/programs/fields";
import { PrimaryButton } from "components/core/basic/Button";
import { useFieldArray } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GrDrag, GrFormClose } from "react-icons/gr";
import RuleForm from "./RuleForm";
import HeaderFields from "./HeaderFields";
import { RULE_DEFAULTS } from "./constants";
import { useProgramContext } from "./ProgramContext";
import ActionForm from "./ActionForm";
import ToolTips from "utils/toolTips";

const RulesContainer = forwardRef(({ children, ...props }, ref) => {
  return (
    <ul
      ref={ref}
      className="rules"
      style={{ paddingLeft: 0, listStyle: "none" }}
    >
      {children}
    </ul>
  );
});

const Rule = forwardRef(
  (
    {
      control,
      formState,
      setValue,
      date,
      id,
      dragHandleProps,
      snapshot,
      index,
      remove,
      ...props
    },
    ref
  ) => {
    const [expanded, setExpanded] = useState(true);
    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);
    useEffect(() => {
      if (!contentRef.current) return; // wait for the elementRef to be available
      const resizeObserver = new ResizeObserver(() => {
        setHeight(contentRef.current.clientHeight + 20);
      });
      resizeObserver.observe(contentRef.current);
      return () => resizeObserver.disconnect(); // clean up
    }, [expanded]);

    return (
      <li
        ref={ref}
        {...props}
        className={
          "rule card p-4 mb-4" + (snapshot.isDragging ? "hovering" : "")
        }
      >
        <Row>
          <Col xs={9} className="d-flex align-items-center">
            <div {...dragHandleProps}>
              <span
                style={{
                  fontSize: "14px",
                  marginRight: "1rem",
                  color: "#666",
                }}
              >
                <GrDrag />
              </span>
            </div>
            <span
              style={{
                cursor: "pointer",
                color: "#2e0054",
                fontSize: "14px",
                fontWeight: "600",
              }}
              onClick={() => setExpanded(!expanded)}
            >
              Rule {index + 1}
            </span>
          </Col>
          <Col xs={3} className="d-flex align-items-center justify-content-end">
            <span
              style={{
                cursor: "pointer",
                fontSize: "24px",
                color: "#666",
              }}
              onClick={() => remove(index)}
            >
              <GrFormClose />
            </span>
          </Col>
        </Row>
        <Row
          style={{
            maxHeight: expanded ? height : "0",
            transition: "all 0.2s ease-in-out",
            overflow: expanded ? "unset" : "hidden",
            animation: "0.2s delay-overflow",
          }}
        >
          <Col xs={12}>
            <div ref={contentRef}>
              <Row className="mt-4 fs-standard">
                <Col xs={12}>
                  <RuleForm
                    control={control}
                    formState={formState}
                    fieldName={`rules.${index}`}
                    setValue={setValue}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </li>
    );
  }
);

const ProgramsForm = ({ control, formState, getValues, setValue, unregister }) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "rules",
  });

  const { engine } = useProgramContext();

  return (
    <>
      <Row>
        <HeaderFields control={control} formState={formState} />
        <Col xs={12} className="mt-4">
          {engine === "bidding" && (
            <Row className="mb-3">
              <Col xs={12} className="fs-standard py-3">
                <strong>Initial Action:</strong>{" "}
                <ToolTips
                  toolTip={
                    "This action will be applied to bids before any of the below conditions are assessed"
                  }
                  position="top"
                  id={"initialActionTooltip"}
                />
              </Col>

              <ActionForm
                fieldName={`initial_action`}
                control={control}
                formState={formState}
                initialAction={true}
              />
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Label>Rules</Label>
            </Col>
          </Row>
          <DragDropContext
            onDragEnd={({ source, destination }) => {
              move(source.index, destination.index);
            }}
          >
            <Droppable droppableId="rules-wrapper">
              {(provided, snapshot) => (
                <RulesContainer
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {fields.map((field, index) => {
                    return (
                      <Draggable
                        draggableId={`rule-${field.id}`}
                        index={index}
                        key={field.id}
                      >
                        {(_provided, _snapshot) => (
                          <Rule
                            ref={_provided.innerRef}
                            dragHandleProps={_provided.dragHandleProps}
                            {..._provided.draggableProps}
                            snapshot={_snapshot}
                            index={index}
                            remove={remove}
                            control={control}
                            formState={formState}
                            setValue={setValue}
                            {...field}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                </RulesContainer>
              )}
            </Droppable>
          </DragDropContext>
          <Row>
            <Col xs={12} className="d-flex justify-content-end">
              {formState.errors?.rules && (
                <span className="text-danger">
                  {formState.errors?.rules?.root?.message}
                </span>
              )}
              <PrimaryButton
                nowrap
                onClick={() => {
                  append(RULE_DEFAULTS[engine]);
                }}
              >
                Add Rule
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProgramsForm;
