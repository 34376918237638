import {
  ADVERTISING,
  CONTENT,
  DEALS,
  DYNAMIC_PRICING,
  NEW_PRODUCT_LAUNCH,
  PURCHASE_BEHAVIOR,
  SEARCH_ANALYTICS,
} from "components/custom/merchandise/constants";
import checkTierAccess from "./checkTierAccess";
import store from "redux/store";

const checkApplication = (user, application) => {
  // Initial applications (no tier/subscription item required)
  const applications = [NEW_PRODUCT_LAUNCH];
  const {
    amz_onboard: { org_status },
    channel,
  } = store.getState();

  const {
    tier,
    pricing_tier,
    analytics_tier,
    content_tier,
    promotions_tier,
    pb_tier,
  } = user;

  if (
    (tier && !["unknown", "MANUALLY_DISABLED"].includes(tier)) ||
    checkTierAccess(tier, "advertising", org_status)
  ) {
    applications.push(ADVERTISING);
  }

  if (
    ((pricing_tier &&
      !["unknown", "MANUALLY_DISABLED"].includes(pricing_tier)) ||
      checkTierAccess(tier, "pricing", org_status)) &&
    channel.currentChannel !== "walmart"
  ) {
    applications.push(DYNAMIC_PRICING);
  }

  if (
    analytics_tier &&
    !["unknown", "MANUALLY_DISABLED"].includes(analytics_tier) &&
    channel.currentChannel !== "walmart"
  ) {
    applications.push(SEARCH_ANALYTICS);
  }

  if (
    ((content_tier &&
      !["unknown", "MANUALLY_DISABLED"].includes(content_tier)) ||
      checkTierAccess(tier, "content", org_status)) &&
    channel.currentChannel !== "walmart"
  ) {
    applications.push(CONTENT);
  }

  if (
    ((promotions_tier &&
      !["unknown", "MANUALLY_DISABLED"].includes(promotions_tier)) ||
      checkTierAccess(tier, "promotions", org_status)) &&
    channel.currentChannel !== "walmart"
  ) {
    applications.push(DEALS);
  }

  if (
    pb_tier &&
    !["unknown", "MANUALLY_DISABLED"].includes(pb_tier) &&
    channel.currentChannel !== "walmart"
  ) {
    applications.push(PURCHASE_BEHAVIOR);
  }

  return applications.includes(application);
};

export default checkApplication;
