import React from "react";
import { connect } from "react-redux";
import { selectModules } from "redux/selectors/modules";

const Flag = ({ feature, modules, reverse = false, children }) => {
  if (modules?.find((v) => v.name === feature)) {
    if (!reverse) {
      return <>{children}</>;
    }
  } else if (reverse) {
    return <>{children}</>;
  }
  return null;
};

const PermissionsFlag = ({ role, roles, children }) => {
  if (!(roles === role)) {
    return null;
  }

  return <>{children}</>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  modules: selectModules(state),
});

export { PermissionsFlag };
