import { AD_TYPES } from "components/core/blocks/AdPlan/constants";

export const getAdTypeName = (value, channel = "amazon") => {
  let adTypeArray = [];
  for (const type in AD_TYPES) {
    adTypeArray.push(AD_TYPES[type]);
  }

  let adTypeName = adTypeArray.find((t) => t.value === value)?.label ?? "";

  // This avoids walmart SP being labelled as Custom
  if (value === AD_TYPES.CUSTOM.value && channel === "walmart") {
    adTypeName = AD_TYPES.SPONSORED_PRODUCT.label;
  }

  return adTypeName;
};
