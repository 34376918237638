import { PrimaryButton } from "components/core/basic/Button";
import SummaryCard from "components/core/blocks/SummaryCard";
import ListPrograms from "components/custom/programs/ListPrograms";
import { useFetch } from "hooks/api";
import React from "react";
import { Col, Container as Grid, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toTitleCase } from "utils/formatText";
import Card from "components/core/basic/Card.jsx";
import { useHistory } from "react-router-dom";

const ProgramList = ({
  engine = "pricing",
  displayAllPrograms = false,
  biddingLayout = false,
}) => {
  const history = useHistory();

  const programQueryParams = {
    engine: engine,
  };

  if (displayAllPrograms) {
    delete programQueryParams["engine"];
  }

  const { data: programData, loading: isProgramDataLoading } = useFetch(
    ["programs", engine],
    "/programs",
    programQueryParams,
    {
      select: (data) => {
        return data.data?.results ?? [];
      },
    }
  );

  const LayoutWrapper = ({ children }) => (
    <>
      {biddingLayout ? (
        <Col xs={12} md={{ span: 10, offset: 2 }}>
          <Card
            style={{ width: "100%" }}
            content={children}
            title={
              <h3 className={`fs-1 fw-bold ps-4`}>
                {toTitleCase(engine)} Rules
              </h3>
            }
          />
        </Col>
      ) : (
        <Grid
          fluid
          style={{ padding: "3rem", minHeight: "calc(100vh - 150px)" }}
        >
          <SummaryCard mobileStyle title={`${toTitleCase(engine)} Rules`}>
            <Row>{children} </Row>
          </SummaryCard>
        </Grid>
      )}
    </>
  );

  return (
    <LayoutWrapper>
      <Col md={12}>
        <ListPrograms
          isLoading={isProgramDataLoading}
          programs={programData ?? []}
          onClickProgram={(id) => {
            history.push(
              ["bidding", "budget"].includes(engine)
                ? `/user/advertising/programs/edit/${id}`
                : `/user/programs/edit/${id}`
            );
          }}
          engine={engine}
          onToggleProgram={(id, value) => {
            console.log(
              `toggleProgram with id ${id} to align with value ${value}`
            );
          }}
          createButton={
            <Link
              to={
                engine === "bidding" || engine === "budget"
                  ? `/user/advertising/programs/create?engine=${engine}`
                  : "/user/programs/create"
              }
              className="text-decoration-none me-auto ps-3"
            >
              <PrimaryButton
                overrideStyles={{ transform: "none", textDecoration: "none" }}
              >
                Create New Rules
              </PrimaryButton>
            </Link>
          }
          titleColWidth={engine === "bidding" ? 350 : 250}
          title={`${toTitleCase(engine ?? "Pricing")} Rules`}
        />
      </Col>
    </LayoutWrapper>
  );
};

export default ProgramList;
