import * as yup from "yup";

const formSchema = yup.object({
  name: yup.string().required("Please enter a shelf name."),
  brand_name: yup.string().required("Please enter a brand name."),
  creationDate: yup.date().required(),
  description: yup.string(),
  category: yup.string().required("Please select a category for this shelf."),
  keywords: yup
    .array()
    .min(1, "Please select between 1 and 10 keywords for this shelf.")
    .max(10, "Please select between 1 and 10 keywords for this shelf.")
    .required("Please select between 1 and 10 keywords for this shelf."),
});

export default formSchema;
