import React, { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  withStyles,
  Slider,
} from "@material-ui/core";
import Checkbox from "components/core/basic/CheckBox";
import ToolTips from "utils/toolTips";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { ExpandMore } from "@material-ui/icons";
import theme from "assets/themes/TrellisTheme";
import {
  AD_TYPES,
  ALGORITHM_OPTIONS,
  BID_STRATEGY_OPTIONS,
  SB_BID_STRATEGY_OPTIONS,
  GOALS,
  MAX_BID_DEFAULT,
  MIN_BID_DEFAULT,
  WALMART_SB_MIN_BID_DEFAULT,
  WALMART_MAX_BID_DEFAULT,
  MAX_ACOS_NEGATION_THESHOLD_DEFAULT,
  CUMULATIVE_NEGATION_THESHOLD_DEFAULT,
  SB_CAMPAIGN_GOAL_OPTIONS,
  SB_CAMPAIGN_GOAL_SUPPORTED_MARKETPLACES,
} from "./constants";
import { NegativeKeywordsField, NegativeAsinsField } from "./fields";
import Switch from "react-bootstrap-switch";
import ConfirmationModal from "components/core/blocks/ConfirmationModal";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import checkModule from "utils/checkModule";
import SponsoredProductCampaignModifiers from "./advancedTargetingOptions/SponsoredProductCampaignModifiers";
import HarvestingConfiguration from "views/advertising/HarvestingConfiguration";
import AdPlanBiddingRules from "./AdPlanBiddingRules";

const useStyles = (theme) => ({
  menuContainer: {
    margin: theme.spacing(4, "auto"),
    padding: "0 2rem",
  },
  subtitle: {
    fontWeight: 560,
    fontSize: "1.6rem",
    color: "#73706E",
  },
  expanded: {
    minHeight: "0px !important",
  },
  inputContainer: {
    padding: theme.spacing(0, 1, 1, 0),
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  explanationText: {
    margin: theme.spacing(1, 0),
    color: "#6C757D",
    fontSize: "1.3rem",
    fontWeight: 500,
    maxWidth: "90%",
  },
  reviewExplanationText: {
    maxWidth: "100%",
  },
});

const AlgoSlider = withStyles({
  root: {
    color: "#ddd",
    opacity: "0.7",
  },
  thumb: {
    height: 22,
    width: 22,
    border: "1px solid #C53066",
    "&:focus,&:hover,&$active": {
      boxShadow: "none",
      border: "1px solid #C53066",
    },
  },
  active: {
    color: "#62407f",
  },
  track: {
    height: 10,
    borderRadius: 10,
    color: "#C53066",
  },
  rail: {
    height: 10,
    borderRadius: 24,
    opacity: 1,
  },
  mark: {
    color: "#FFF",
    zIndex: 1000,
    height: 10,
    marginLeft: 3,
    marginTop: 0,
  },
})(Slider);

const AdvancedTargetingOptions = ({
  classes,
  state,
  errors,
  channel,
  onChange,
  adId,
  review,
  isModal,
  targetingModalEdit,
  fieldsToValidate,
  marketPlace,
  modules,
  skipToRulesForm,
}) => {
  const [open, setOpen] = useState(false);

  const showAccordion =
    (review || state.id) && !targetingModalEdit && channel !== "google";

  const nonTrellisCampaignFlag = state.goal === GOALS.IMPORTED_ADS.value;

  let algorithmOptions;

  if (nonTrellisCampaignFlag) {
    algorithmOptions = [
      ALGORITHM_OPTIONS.ROAS_BOOST,
      ALGORITHM_OPTIONS.GROWTH_MAX,
    ];
  } else {
    algorithmOptions = [
      ALGORITHM_OPTIONS.ROAS_BOOST,
      ALGORITHM_OPTIONS.GROWTH_MAX,
      ALGORITHM_OPTIONS.PRODUCT_LAUNCH,
    ];
  }

  if (checkModule(modules, "BIDDING_RULES")) {
    algorithmOptions.push(ALGORITHM_OPTIONS.RULES_ENGINE_ONLY);
  }

  const campaignGoalOptions = [
    SB_CAMPAIGN_GOAL_OPTIONS.PAGE_VISIT,
    SB_CAMPAIGN_GOAL_OPTIONS.BRAND_IMPRESSION_SHARE,
  ];

  const bidStrategyOptions = [
    BID_STRATEGY_OPTIONS.UP_DOWN,
    BID_STRATEGY_OPTIONS.FIXED,
    BID_STRATEGY_OPTIONS.DOWN_ONLY,
  ];

  const sbBidStrategyOptions = [
    SB_BID_STRATEGY_OPTIONS.MAXIMIZE_IMMEDIATE_SALES,
    SB_BID_STRATEGY_OPTIONS.MAXIMIZE_NEW_TO_BRAND_CUSTOMERS,
  ];

  const renderAdvancedSettings = () => {
    if (
      channel === "google" ||
      (review && !state.id) ||
      state.canHaveChildren
    ) {
      return null;
    }

    if (nonTrellisCampaignFlag) {
      return (
        <Grid container item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <h3 className={classes.subtitle}>Optimization:</h3>
            </Grid>
            <Grid
              xs={12}
              md={5}
              className={classes.inputContainer}
              style={{
                paddingRight: theme.spacing(5),
                paddingTop: theme.spacing(1),
              }}
            >
              <FormGroupTile
                type="select"
                stateName={"algorithm"}
                handleChildFormElement={(key, value) => {
                  if (value.value === ALGORITHM_OPTIONS.ROAS_BOOST.value) {
                    onChange({ [key]: value.value, crSelectorEnabled: true });
                  } else {
                    onChange({
                      [key]: value.value,
                      crSelectorEnabled: false,
                    });
                  }
                }}
                disabled={false}
                defaultValue={algorithmOptions.find(
                  (a) => a.value === state.algorithm
                )}
                options={algorithmOptions}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <p
                className={`${classes.explanationText} ${
                  review && classes.reviewExplanationText
                }`}
              >
                Optimize profit by balancing volume and margin.
              </p>{" "}
            </Grid>
            {checkModule(modules, "BIDDING_RULES") &&
              !(review && isModal) &&
              state.algorithm === ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value && (
                <AdPlanBiddingRules
                  state={state}
                  onChange={onChange}
                  classes={classes}
                  theme={theme}
                  review={review}
                  skipToRulesForm={skipToRulesForm}
                  errors={errors}
                  fieldsToValidate={fieldsToValidate}
                />
              )}
            <Grid item container xs={12} style={{ margin: "3rem auto" }}>
              <Grid item xs={12}>
                <h3 className={classes.subtitle}>Sales Volume Control:</h3>
              </Grid>
              <Grid item xs={12} md={10} style={{ margin: "auto" }}>
                <AlgoSlider
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  min={0}
                  max={9}
                  ThumbComponent={"div"}
                  marks={[
                    { value: 0, label: "Increase Margin" },
                    { value: 1, label: "Optimal Profit" },
                    { value: 2 },
                    { value: 3 },
                    { value: 4 },
                    { value: 5 },
                    { value: 6 },
                    { value: 7 },
                    { value: 8 },
                    { value: 9, label: "Max Volume" },
                  ]}
                  defaultValue={state.salesVolumeControl}
                  valueLabelDisplay="off"
                  onChange={(e, value) => {
                    onChange({
                      salesVolumeControl: value,
                    });
                  }}
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(0),
            }}
          >
            <Grid item xs={12}>
              <h3 className={classes.subtitle}>Bid Range:</h3>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={5}
              className={classes.inputContainer}
              style={{
                paddingTop: theme.spacing(1),
              }}
            >
              <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                <label
                  style={{
                    color: "#73706E",
                    fontWeight: "600",
                    paddingBottom: ".7rem",
                  }}
                >
                  Min Bid
                </label>
                <FormGroupTile
                  type="formcontrol"
                  prefix={
                    MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                  }
                  prefixSide={"left"}
                  formControlType={"number"}
                  stateName={"minBid"}
                  handleChildFormElement={(key, value) => {
                    if (!value || !parseFloat(value)) {
                      onChange({
                        [key]: [
                          AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                          AD_TYPES.SPONSORED_BRAND_WALMART.value,
                        ].includes(state.adType)
                          ? WALMART_SB_MIN_BID_DEFAULT
                          : MIN_BID_DEFAULT,
                      });
                      return;
                    }
                    onChange({ [key]: value });
                  }}
                  defaultValue={
                    state.minBid === MIN_BID_DEFAULT ||
                    ([
                      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                      AD_TYPES.SPONSORED_BRAND_WALMART.value,
                    ].includes(state.adType) &&
                      state.minBid === WALMART_SB_MIN_BID_DEFAULT) ||
                    (![
                      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                      AD_TYPES.SPONSORED_BRAND_WALMART.value,
                    ].includes(state.adType) &&
                      state.minBid === MIN_BID_DEFAULT)
                      ? null
                      : state.minBid
                  }
                />
              </Grid>

              <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                <label
                  style={{
                    color: "#73706E",
                    fontWeight: "600",
                    paddingBottom: ".7rem",
                  }}
                >
                  Max Bid
                </label>
                <FormGroupTile
                  type="formcontrol"
                  prefix={
                    MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                  }
                  prefixSide={"left"}
                  formControlType={"number"}
                  stateName={"maxBid"}
                  handleChildFormElement={(key, value) => {
                    if (!value || !parseFloat(value)) {
                      onChange({
                        [key]:
                          channel === "walmart"
                            ? WALMART_MAX_BID_DEFAULT
                            : MAX_BID_DEFAULT,
                      });
                      return;
                    }

                    onChange({ [key]: value });
                  }}
                  defaultValue={
                    state.maxBid === MAX_BID_DEFAULT ||
                    (channel === "walmart" &&
                      state.maxBid === WALMART_MAX_BID_DEFAULT)
                      ? null
                      : state.maxBid
                  }
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={7}>
              <p
                className={`${classes.explanationText} ${
                  review && classes.reviewExplanationText
                }`}
                style={{ paddingTop: "2rem" }}
              >
                Set the minimum and maximum values for bid keywords and product
                targets.
              </p>
            </Grid>
          </Grid>
          {[
            AD_TYPES.SPONSORED_PRODUCT.value,
            AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
          ].includes(state.adType) && (
            <SponsoredProductCampaignModifiers
              state={state}
              onChange={onChange}
              classes={classes}
              errors={errors}
              fieldsToValidate={fieldsToValidate}
              review={review}
              theme={theme}
              bidStrategyOptions={bidStrategyOptions}
            />
          )}
        </Grid>
      );
    }

    return (
      <>
        <Grid container item xs={12}>
          {![
            AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
            AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
            AD_TYPES.SPONSORED_BRAND_WALMART.value,
            AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
          ].includes(state.adType) &&
            !(channel === "walmart" && state.goal === GOALS.SOV.value) && (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <h3 className={classes.subtitle}>
                    Discovery and Harvesting:
                  </h3>
                </Grid>
                <Grid xs={12} md={5}>
                  {([
                    AD_TYPES.SPONSORED_PRODUCT.value,
                    AD_TYPES.SPONSORED_BRAND.value,
                    AD_TYPES.SPONSORED_BRAND_VIDEO.value,
                  ].includes(state.adType) ||
                    (channel === "walmart" &&
                      [AD_TYPES.SPONSORED_PRODUCT_WALMART.value].includes(
                        state.adType
                      ))) && (
                    <Grid item xs={12} className={classes.checkboxContainer}>
                      <Checkbox
                        inline
                        number={`${adId || "create"}-discovery`}
                        label="Enable Discovery"
                        checked={state.keywordDiscoveryEnabled}
                        disabled={false}
                        color={adId ? "#AAA" : null}
                        onClick={(e) => {
                          if (state.id) {
                            return;
                          }
                          onChange({
                            keywordDiscoveryEnabled:
                              !state.keywordDiscoveryEnabled,
                          });
                        }}
                      />
                    </Grid>
                  )}

                  {((!state.hasParent &&
                    state.adType === AD_TYPES.SPONSORED_PRODUCT.value) ||
                    channel === "walmart") &&
                    state.goal !== GOALS.SOV.value && (
                      <>
                        <Grid
                          item
                          xs={12}
                          className={classes.checkboxContainer}
                        >
                          {state.id && state.autoCampaignEnabled ? (
                            <>
                              <ConfirmationModal
                                title={`Confirm Auto Campaign Update`}
                                confirmButtonText={`Confirm`}
                                confirmationMessage={
                                  <>
                                    <p className="fs-3 w-75 mx-auto">
                                      You are disabling the primary source of
                                      harvesting for your media plan, are you
                                      sure you want to continue? This change
                                      will not go live until you update the
                                      media plan.
                                    </p>
                                  </>
                                }
                                onConfirm={() => {
                                  onChange({
                                    autoCampaignEnabled:
                                      !state.autoCampaignEnabled,
                                  });
                                }}
                                buttonStyle={`logout_button`}
                                modelButtonStyle={{ margin: 0 }}
                                inlineModal={true}
                                modalButtonText={
                                  <Checkbox
                                    inline
                                    number={`${adId || "create"}-auto-campaign`}
                                    label="Auto Campaign"
                                    checked={state.autoCampaignEnabled}
                                    disabled={false}
                                    color={adId ? "#AAA" : null}
                                  />
                                }
                              />
                            </>
                          ) : (
                            <Checkbox
                              inline
                              number={`${adId || "create"}-auto-campaign`}
                              label="Auto Campaign"
                              checked={state.autoCampaignEnabled}
                              disabled={
                                checkModule(modules, "CAMPAIGN_NAMES") &&
                                state.campaignNames.auto_name
                              }
                              color={adId ? "#AAA" : null}
                              onClick={(e) => {
                                const updatedCampaignNames =
                                  state.campaignNames;
                                if (e.target.checked) {
                                  if (
                                    !state.campaignNames.hasOwnProperty(
                                      "auto_name"
                                    )
                                  ) {
                                    updatedCampaignNames["auto_name"] = null;
                                  }
                                } else {
                                  if (
                                    state.campaignNames.hasOwnProperty(
                                      "auto_name"
                                    )
                                  ) {
                                    delete updatedCampaignNames["auto_name"];
                                  }
                                }
                                onChange({
                                  autoCampaignEnabled:
                                    !state.autoCampaignEnabled,
                                  campaignNames: updatedCampaignNames,
                                });
                              }}
                            />
                          )}
                        </Grid>
                      </>
                    )}

                  {[
                    AD_TYPES.SPONSORED_PRODUCT.value,
                    AD_TYPES.SPONSORED_PRODUCT_WALMART.value,
                  ].includes(state.adType) && (
                    <Grid item xs={12} className={classes.checkboxContainer}>
                      <Checkbox
                        inline
                        number={`${adId || "create"}-keywordHarvesting`}
                        label="Enable Keyword Harvesting"
                        checked={state.keywordHarvestingEnabled}
                        disabled={false}
                        color={adId ? "#AAA" : null}
                        onClick={(e) => {
                          onChange({
                            keywordHarvestingEnabled:
                              !state.keywordHarvestingEnabled,
                          });
                        }}
                      />
                    </Grid>
                  )}
                  {[
                    AD_TYPES.SPONSORED_PRODUCT.value,
                    AD_TYPES.SPONSORED_DISPLAY_V2.value,
                  ].includes(state.adType) &&
                    channel !== "walmart" && (
                      <Grid item xs={12} className={classes.checkboxContainer}>
                        <Checkbox
                          inline
                          number={`${adId || "create"}-asinHarvesting`}
                          label="Enable ASIN Harvesting"
                          checked={state.asinHarvestingEnabled}
                          disabled={false}
                          color={adId ? "#AAA" : null}
                          onClick={(e) => {
                            onChange({
                              asinHarvestingEnabled:
                                !state.asinHarvestingEnabled,
                            });
                          }}
                        />
                      </Grid>
                    )}
                  {!state.hasParent && channel !== "walmart" && (
                    <Grid item xs={12} className={classes.checkboxContainer}>
                      <Checkbox
                        inline
                        number={`${adId || "create"}-trellisTrak`}
                        label="Bootstrap with historical targets"
                        checked={state.trellisTrakEnabled}
                        disabled={false}
                        color={adId ? "#AAA" : null}
                        onClick={(e) => {
                          onChange({
                            trellisTrakEnabled: !state.trellisTrakEnabled,
                          });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={7}>
                  <p
                    className={`${classes.explanationText} ${
                      review && classes.reviewExplanationText
                    }`}
                  >
                    Enable and disable automated keyword discovery and
                    harvesting.
                  </p>
                  {checkModule(modules, "CAMPAIGN_NAMES") &&
                  state.campaignNames.auto_name ? (
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                    >
                      Since a custom name was provided for the auto campaign,
                      this field is disabled. If you wish to have no auto
                      campaign for this ad, please remove the custom name.
                    </p>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            )}
          <Grid container item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <h3 className={classes.subtitle}>Optimization:</h3>
              </Grid>
              <Grid
                xs={12}
                md={5}
                className={classes.inputContainer}
                style={{
                  paddingRight: theme.spacing(5),
                  paddingTop: theme.spacing(1),
                }}
              >
                <FormGroupTile
                  type="select"
                  stateName={"algorithm"}
                  handleChildFormElement={(key, value) => {
                    if (value.value === ALGORITHM_OPTIONS.ROAS_BOOST.value) {
                      onChange({
                        [key]: value.value,
                        crSelectorEnabled: true,
                        createNewProgram: false,
                      });
                    } else {
                      onChange({
                        [key]: value.value,
                        crSelectorEnabled: false,
                        createNewProgram: false,
                      });
                    }
                  }}
                  disabled={false}
                  defaultValue={algorithmOptions.find(
                    (a) => a.value === state.algorithm
                  )}
                  options={algorithmOptions}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <p
                  className={`${classes.explanationText} ${
                    review && classes.reviewExplanationText
                  }`}
                >
                  Optimize profit by balancing volume and margin.
                </p>{" "}
              </Grid>
              {checkModule(modules, "BIDDING_RULES") &&
                state.algorithm === ALGORITHM_OPTIONS.RULES_ENGINE_ONLY.value &&
                !(review && isModal) && (
                  <AdPlanBiddingRules
                    state={state}
                    onChange={onChange}
                    classes={classes}
                    theme={theme}
                    review={review}
                    skipToRulesForm={skipToRulesForm}
                    errors={errors}
                    fieldsToValidate={fieldsToValidate}
                  />
                )}

              {state.algorithm === "v2" && (
                <Grid item container xs={12} style={{ margin: "3rem auto" }}>
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>Sales Volume Control:</h3>
                  </Grid>
                  <Grid item xs={12} md={10} style={{ margin: "auto" }}>
                    <AlgoSlider
                      aria-labelledby="discrete-slider-always"
                      step={1}
                      min={0}
                      max={9}
                      ThumbComponent={"div"}
                      marks={[
                        { value: 0, label: "Increase Margin" },
                        { value: 1, label: "Optimal Profit" },
                        { value: 2 },
                        { value: 3 },
                        { value: 4 },
                        { value: 5 },
                        { value: 6 },
                        { value: 7 },
                        { value: 8 },
                        { value: 9, label: "Max Volume" },
                      ]}
                      defaultValue={state.salesVolumeControl}
                      valueLabelDisplay="off"
                      onChange={(e, value) => {
                        onChange({
                          salesVolumeControl: value,
                        });
                      }}
                      size="medium"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {[
              AD_TYPES.SPONSORED_PRODUCT.value,
              AD_TYPES.SPONSORED_PRODUCT_WALMART.value,
              AD_TYPES.SPONSORED_BRAND.value,
              AD_TYPES.SPONSORED_BRAND_V2.value,
              AD_TYPES.SPONSORED_BRAND_VIDEO.value,
            ].includes(state.adType) && (
              <>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>Match Type:</h3>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className={classes.checkboxContainer}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-broadMatch`}
                        label="Broad"
                        checked={state.broadMatchEnabled}
                        disabled={state.initialBroadMatchEnabled}
                        onClick={(e) => {
                          onChange({
                            broadMatchEnabled: !state.broadMatchEnabled,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className={classes.checkboxContainer}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-phraseMatch`}
                        label="Phrase"
                        checked={state.phraseMatchEnabled}
                        disabled={state.initialPhraseMatchEnabled}
                        onClick={(e) => {
                          onChange({
                            phraseMatchEnabled: !state.phraseMatchEnabled,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className={classes.checkboxContainer}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-exactMatch`}
                        label="Exact"
                        checked={state.exactMatchEnabled}
                        disabled={state.initialExactMatchEnabled}
                        onClick={(e) => {
                          onChange({
                            exactMatchEnabled: !state.exactMatchEnabled,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      Choose how closely your keywords should match with
                      customer searches by selecting keyword match types.
                      Keyword match types that are not enabled will not be
                      targeted.
                    </p>
                  </Grid>
                </Grid>
              </>
            )}
            {[
              AD_TYPES.SPONSORED_PRODUCT.value,
              AD_TYPES.SPONSORED_BRAND.value,
              AD_TYPES.SPONSORED_BRAND_V2.value,
              AD_TYPES.SPONSORED_BRAND_VIDEO.value,
            ].includes(state.adType) &&
              state.broadMatchEnabled &&
              channel === "amazon" && (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>Broad Modifier:</h3>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      className={`${classes.checkboxContainer} ps-3`}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-broadMatchModifier`}
                        label="Broad Match with Modifier"
                        checked={state.broadMatchModifier}
                        disabled={state.initialBroadMatchModifier}
                        onClick={(e) => {
                          // Make sure one option always selected
                          if (
                            !state.broadMatchRegular &&
                            state.broadMatchModifier
                          ) {
                            onChange({
                              broadMatchModifier: false,
                              broadMatchRegular: true,
                            });
                          } else {
                            onChange({
                              broadMatchModifier: !state.broadMatchModifier,
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      className={`${classes.checkboxContainer} ps-3`}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-broadMatchRegular`}
                        label="Broad Match"
                        checked={state.broadMatchRegular}
                        disabled={state.initialBroadMatchRegular}
                        onClick={(e) => {
                          // Make sure one option always selected
                          if (
                            !state.broadMatchModifier &&
                            state.broadMatchRegular
                          ) {
                            onChange({
                              broadMatchRegular: false,
                              broadMatchModifier: true,
                            });
                          } else {
                            onChange({
                              broadMatchRegular: !state.broadMatchRegular,
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      Choose how broadly keyword matches can be made to reach a
                      wider audience.
                    </p>
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(0),
            }}
          >
            <Grid item xs={12}>
              <h3 className={classes.subtitle}>Bid Range:</h3>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={5}
              className={classes.inputContainer}
              style={{
                paddingTop: theme.spacing(1),
              }}
            >
              <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                <label
                  style={{
                    color: "#73706E",
                    fontWeight: "600",
                    paddingBottom: ".7rem",
                  }}
                >
                  Min Bid
                </label>
                <FormGroupTile
                  type="formcontrol"
                  prefix={
                    MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                  }
                  prefixSide={"left"}
                  formControlType={"number"}
                  stateName={"minBid"}
                  handleChildFormElement={(key, value) => {
                    if (!value || !parseFloat(value)) {
                      onChange({
                        [key]: [
                          AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                          AD_TYPES.SPONSORED_BRAND_WALMART.value,
                        ].includes(state.adType)
                          ? WALMART_SB_MIN_BID_DEFAULT
                          : MIN_BID_DEFAULT,
                      });
                      return;
                    }
                    onChange({ [key]: value });
                  }}
                  defaultValue={
                    state.minBid === MIN_BID_DEFAULT ||
                    ([
                      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                      AD_TYPES.SPONSORED_BRAND_WALMART.value,
                    ].includes(state.adType) &&
                      state.minBid === WALMART_SB_MIN_BID_DEFAULT) ||
                    (![
                      AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
                      AD_TYPES.SPONSORED_BRAND_WALMART.value,
                    ].includes(state.adType) &&
                      state.minBid === MIN_BID_DEFAULT)
                      ? null
                      : state.minBid
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                <label
                  style={{
                    color: "#73706E",
                    fontWeight: "600",
                    paddingBottom: ".7rem",
                  }}
                >
                  Max Bid
                </label>
                <FormGroupTile
                  type="formcontrol"
                  prefix={
                    MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
                  }
                  prefixSide={"left"}
                  formControlType={"number"}
                  stateName={"maxBid"}
                  handleChildFormElement={(key, value) => {
                    if (!value || !parseFloat(value)) {
                      onChange({
                        [key]:
                          channel === "walmart"
                            ? WALMART_MAX_BID_DEFAULT
                            : MAX_BID_DEFAULT,
                      });
                      return;
                    }

                    onChange({ [key]: value });
                  }}
                  defaultValue={
                    state.maxBid === MAX_BID_DEFAULT ||
                    (channel === "walmart" &&
                      state.maxBid === WALMART_MAX_BID_DEFAULT)
                      ? null
                      : state.maxBid
                  }
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={7}>
              <p
                className={`${classes.explanationText} ${
                  review && classes.reviewExplanationText
                }`}
                style={{ paddingTop: "2rem" }}
              >
                Set the minimum and maximum values for bid keywords and product
                targets.
              </p>
            </Grid>
          </Grid>
          {[
            AD_TYPES.SPONSORED_PRODUCT.value,
            AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
          ].includes(state.adType) &&
            channel === "amazon" && (
              <SponsoredProductCampaignModifiers
                state={state}
                onChange={onChange}
                classes={classes}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                review={review}
                theme={theme}
                bidStrategyOptions={bidStrategyOptions}
              />
            )}
          {channel === "amazon" &&
            state.adType === AD_TYPES.SPONSORED_PRODUCT.value &&
            state.goal !== GOALS.SOV.value &&
            !state.parent &&
            !state.canHaveChildren && (
              <Grid
                container
                item
                xs={12}
                style={{
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(0),
                }}
              >
                <Grid item xs={12}>
                  <h3 className={classes.subtitle}>Campaign Budget Split:</h3>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={5}
                  className={classes.inputContainer}
                  style={{
                    paddingTop: theme.spacing(1),
                  }}
                >
                  <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                    <label
                      style={{
                        color: "#73706E",
                        fontWeight: "600",
                        paddingBottom: ".7rem",
                      }}
                    >
                      Manual
                    </label>
                    <FormGroupTile
                      type="formcontrol"
                      prefix={"%"}
                      prefixSide={"right"}
                      formControlType={"number"}
                      stateName={"manualCampaignBudget"}
                      handleChildFormElement={(key, value) => {
                        if (value > 100 || value < 0) {
                          return;
                        }

                        onChange({
                          [key]: value,
                          autoCampaignBudget: parseInt(100 - value) ?? 0,
                        });
                      }}
                      placeholder={`Manual campaign split`}
                      defaultValue={parseInt(state.manualCampaignBudget)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                    <label
                      style={{
                        color: "#73706E",
                        fontWeight: "600",
                        paddingBottom: ".7rem",
                      }}
                    >
                      Auto
                    </label>
                    <FormGroupTile
                      type="formcontrol"
                      prefix={"%"}
                      prefixSide={"right"}
                      formControlType={"number"}
                      stateName={"autoCampaignBudget"}
                      handleChildFormElement={(key, value) => {
                        if (value > 100 || value < 0) {
                          return;
                        }

                        onChange({
                          [key]: value,
                          manualCampaignBudget: parseInt(100 - value) ?? 0,
                        });
                      }}
                      placeholder={`Auto campaign split`}
                      defaultValue={parseInt(state.autoCampaignBudget)}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} md={7}>
                  <p
                    className={`${classes.explanationText} ${
                      review && classes.reviewExplanationText
                    }`}
                    style={{ paddingTop: "2rem" }}
                  >
                    Adjust the budget split between manual and auto campaigns.
                  </p>
                </Grid>
              </Grid>
            )}
          {channel === "amazon" &&
            ([AD_TYPES.SPONSORED_PRODUCT_AUTO.value]?.includes(state.adType) ||
              ([AD_TYPES.SPONSORED_PRODUCT.value].includes(state.adType) &&
                state.autoCampaignEnabled)) && (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>
                      Automatic Keyword Negation Settings:
                    </h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                    style={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={9}
                      className="d-flex align-items-center"
                      style={{ padding: "2rem .5rem 0" }}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-autoNegationEnabled`}
                        label="Auto Negation"
                        checked={state.autoNegationEnabled}
                        disabled={false}
                        onClick={(e) => {
                          if (e.target.checked) {
                            onChange({
                              maxAcosNegationThreshold:
                                MAX_ACOS_NEGATION_THESHOLD_DEFAULT,
                              cumulativeNegationThreshold:
                                CUMULATIVE_NEGATION_THESHOLD_DEFAULT,
                              autoNegationEnabled: true,
                            });
                          } else {
                            onChange({
                              maxAcosNegationThreshold: null,
                              cumulativeNegationThreshold: null,
                              autoNegationEnabled: false,
                            });
                          }
                        }}
                      />
                    </Grid>
                    {state.autoNegationEnabled && (
                      <>
                        <Grid item xs={12} style={{ padding: "2rem 2rem 0" }}>
                          <label
                            style={{
                              color: "#73706E",
                              fontWeight: "600",
                              paddingBottom: ".7rem",
                            }}
                          >
                            Max ACOS Negation Threshold
                          </label>
                          <Grid
                            item
                            xs={12}
                            lg={6}
                            style={{ paddingRight: "2rem" }}
                          >
                            <FormGroupTile
                              type="formcontrol"
                              prefix={"%"}
                              prefixSide={"right"}
                              formControlType={"number"}
                              stateName={"maxAcosNegationThreshold"}
                              handleChildFormElement={(key, value) => {
                                if (value < 0) {
                                  return;
                                }

                                onChange({
                                  [key]: value,
                                  maxAcosNegationThreshold:
                                    parseInt(value) ?? 0,
                                });
                              }}
                              placeholder={`Negation theshold`}
                              defaultValue={parseInt(
                                state.maxAcosNegationThreshold
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ padding: "2rem 2rem 0" }}>
                          <label
                            style={{
                              color: "#73706E",
                              fontWeight: "600",
                              paddingBottom: ".7rem",
                            }}
                          >
                            Cumulative Negation Threshold
                          </label>

                          <Grid
                            item
                            xs={12}
                            lg={6}
                            style={{ paddingRight: "2rem" }}
                          >
                            <FormGroupTile
                              type="formcontrol"
                              prefix={"%"}
                              prefixSide={"right"}
                              formControlType={"number"}
                              stateName={"cumulativeNegationThreshold"}
                              handleChildFormElement={(key, value) => {
                                if (value < 0) {
                                  return;
                                }

                                onChange({
                                  [key]: value,
                                  cumulativeNegationThreshold:
                                    parseInt(value) ?? 0,
                                });
                              }}
                              placeholder={`Negation theshold`}
                              defaultValue={parseInt(
                                state.cumulativeNegationThreshold
                              )}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid xs={12} md={7} style={{ paddingRight: "5rem" }}>
                    {state.autoNegationEnabled && (
                      <>
                        <p
                          className={`${classes.explanationText} ${
                            review && classes.reviewExplanationText
                          }`}
                          style={{ paddingTop: "85px" }}
                        >
                          Keywords above{" "}
                          {(state.maxAcosNegationThreshold / 100) *
                            state.maxACOS}
                          % ACOS will be negated after they accumulate
                          sufficient spend.
                        </p>
                        <p
                          className={`${classes.explanationText} ${
                            review && classes.reviewExplanationText
                          }`}
                          style={{ paddingTop: "50px" }}
                        >
                          If the media plan ACOS is below{" "}
                          {(state.cumulativeNegationThreshold / 100) *
                            state.maxACOS}
                          % auto negation will not run.
                        </p>
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          {((state.adType === AD_TYPES.SPONSORED_BRAND.value &&
            (state.newProductCollection || state.storeSpotlight)) ||
            state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO.value) && (
            <>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <h3 className={classes.subtitle}>Amazon Options:</h3>
                  <Grid container>
                    <Grid
                      item
                      container
                      xs={12}
                      md={5}
                      className={classes.inputContainer}
                      style={{
                        paddingTop: theme.spacing(1),
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        className="d-flex align-items-center"
                        style={{ padding: "0 .5rem" }}
                      >
                        <Checkbox
                          inline
                          number={`${
                            adId || "create"
                          }-sbBidOptimizationEnabled`}
                          label="Bid Optimization"
                          checked={state.amazonSBBidOptimization}
                          disabled={false}
                          onClick={(e) => {
                            if (e.target.checked) {
                              // Clear placement multipliers when checked
                              onChange({
                                amazonSBBidOptimization:
                                  !state.amazonSBBidOptimization,
                                homePlacementBidMultiplier: 0,
                                pagePlacementBidMultiplier: 0,
                                otherPlacementBidMultiplier: 0,
                              });
                              return;
                            }
                            onChange({
                              amazonSBBidOptimization:
                                !state.amazonSBBidOptimization,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12} md={7}>
                      <p
                        className={`${classes.explanationText} ${
                          review && classes.reviewExplanationText
                        }`}
                      >
                        If selected, Amazon will automatically set bid placement
                        adjustments.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                    style={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    {/* Bid Optimization Strategy */}

                    <Grid item xs={12} style={{ padding: "0 2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Bid Strategy
                      </label>
                      <FormGroupTile
                        type="select"
                        stateName={"bidStrategy"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value.value })
                        }
                        disabled={false}
                        defaultValue={sbBidStrategyOptions.find(
                          (strategy) => strategy.value === state.bidStrategy
                        )}
                        options={sbBidStrategyOptions}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Sponsored Brand Campaign Goal Field */}
                {SB_CAMPAIGN_GOAL_SUPPORTED_MARKETPLACES.includes(
                  marketPlace.marketPlace
                ) &&
                  // Below hides goal field if product video type select, only applicable for SB and SBV ad types
                  // that link to a store landing page
                  state.videoAdType !== "video" && (
                    <Grid
                      item
                      container
                      xs={12}
                      md={5}
                      className={classes.inputContainer}
                      style={{
                        paddingTop: theme.spacing(1),
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "0 2rem" }}>
                        <label
                          style={{
                            color: "#73706E",
                            fontWeight: "600",
                            paddingBottom: ".7rem",
                          }}
                        >
                          Goal
                        </label>
                        <FormGroupTile
                          type="select"
                          stateName={"sbCampaignGoal"}
                          handleChildFormElement={(key, value) => {
                            onChange({
                              [key]: value.value,
                            });
                          }}
                          disabled={state.id}
                          defaultValue={campaignGoalOptions.find(
                            (a) => a.value === state.sbCampaignGoal
                          )}
                          options={campaignGoalOptions}
                        />
                      </Grid>
                    </Grid>
                  )}
              </Grid>
              {/* Placement Bid Multipliers */}
              {!state.amazonSBBidOptimization && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>
                      Amazon Placement Multipliers:
                    </h3>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "1rem" }}
                    >
                      Placement bid multipliers give you additional
                      opportunities to optimize your advertising campaigns.
                    </p>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                    style={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Amazon Home Page
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"homePlacementBidMultiplier"}
                        handleChildFormElement={(key, value) => {
                          onChange({ [key]: value });
                        }}
                        placeholder={`Home`}
                        defaultValue={state.homePlacementBidMultiplier}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Product Detail Page
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"pagePlacementBidMultiplier"}
                        handleChildFormElement={(key, value) => {
                          onChange({
                            [key]: value,
                          });
                        }}
                        placeholder={`Page`}
                        defaultValue={state.pagePlacementBidMultiplier}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{ padding: "2rem 2rem 0" }}
                    >
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Other Amazon Pages
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"otherPlacementBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`Other`}
                        defaultValue={state.otherPlacementBidMultiplier}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      This control allows you to set bid multipliers starting at
                      0% for home, page, and other, depending on where you want
                      your listings to most often appear.
                    </p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0 2rem" }}>
                    {errors.homePlacementBidMultiplier &&
                    fieldsToValidate?.includes("homePlacementBidMultiplier") ? (
                      <small
                        className="text-danger"
                        style={{ paddingLeft: ".8rem", paddingTop: "1rem" }}
                      >
                        {errors.homePlacementBidMultiplier}
                      </small>
                    ) : errors.pagePlacementBidMultiplier &&
                      fieldsToValidate?.includes(
                        "pagePlacementBidMultiplier"
                      ) ? (
                      <small
                        className="text-danger"
                        style={{ paddingLeft: ".8rem", paddingTop: "1rem" }}
                      >
                        {errors.pagePlacementBidMultiplier}
                      </small>
                    ) : (
                      errors.otherPlacementBidMultiplier &&
                      fieldsToValidate?.includes(
                        "otherPlacementBidMultiplier"
                      ) && (
                        <small
                          className="text-danger"
                          style={{ paddingLeft: ".8rem", paddingTop: "1rem" }}
                        >
                          {errors.otherPlacementBidMultiplier}
                        </small>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {/* Segment Bid Multipliers */}
              <Grid item xs={12}>
                <h3 className={classes.subtitle}>
                  Amazon Segment Multipliers:
                </h3>
                <p
                  className={`${classes.explanationText} ${
                    review && classes.reviewExplanationText
                  }`}
                  style={{ paddingTop: "1rem" }}
                >
                  Segment bid multipliers give you additional opportunities to
                  optimize your advertising campaigns.
                </p>
              </Grid>

              <Grid container item xs={12}>
                <Grid
                  item
                  container
                  xs={12}
                  md={5}
                  className={classes.inputContainer}
                  style={{
                    paddingTop: theme.spacing(1),
                  }}
                >
                  <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                    <label
                      style={{
                        color: "#73706E",
                        fontWeight: "600",
                        paddingBottom: ".7rem",
                      }}
                    >
                      New to Brand
                    </label>
                    <FormGroupTile
                      type="formcontrol"
                      formControlType={"number"}
                      prefix={"%"}
                      prefixSide={"right"}
                      stateName={"shopperSegmentBidMultiplier"}
                      handleChildFormElement={(key, value) => {
                        onChange({ [key]: value });
                      }}
                      placeholder={`Home`}
                      defaultValue={state.shopperSegmentBidMultiplier}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} md={7}>
                  <p
                    className={`${classes.explanationText} ${
                      review && classes.reviewExplanationText
                    }`}
                    style={{ paddingTop: "2rem" }}
                  >
                    The shopper segment multiplier gives you the opportunity to
                    target new-to-brand customers. This multiplier is
                    multiplicative with the above placement multipliers if
                    selected.
                  </p>
                </Grid>
                <Grid item xs={12} style={{ padding: "0 2rem" }}>
                  {errors.shopperSegmentBidMultiplier &&
                    fieldsToValidate?.includes(
                      "shopperSegmentBidMultiplier"
                    ) && (
                      <small
                        className="text-danger"
                        style={{ paddingLeft: ".8rem" }}
                      >
                        {errors.shopperSegmentBidMultiplier}
                      </small>
                    )}
                </Grid>
              </Grid>
            </>
          )}
          {/* 
              Walmart Specific Targeting Options
              Note that all copy below comes directly from the Walmart Advertising API Docs (not marketing) - https://advertisinghelp.walmart.com/s/guides?article=000008409
          */}
          {/* Placement Bid Multipliers */}
          {channel === "walmart" && (
            <>
              {![
                AD_TYPES.SPONSORED_BRAND_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
              ].includes(state.adType) && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>
                      Walmart Bid Multipliers:
                    </h3>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "1rem" }}
                    >
                      Bid multipliers give you additional opportunities to
                      optimize your advertising campaigns.
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>Placement Multipliers:</h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                    style={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Buy Box
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"buyBoxPlacementBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`Buy Box`}
                        defaultValue={state.buyBoxPlacementBidMultiplier}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Search In-Grid
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"searchInGridPlacementBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`Search In-Grid`}
                        defaultValue={state.searchInGridPlacementBidMultiplier}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: "2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Stock Up
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"stockUpPlacementBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`Stock Up`}
                        defaultValue={state.stockUpPlacementBidMultiplier}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: "2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Home Page
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"homePagePlacementBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`Home Page`}
                        defaultValue={state.homePagePlacementBidMultiplier}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      This control allows you to set bid modifiers starting at
                      0% for various placements, depending on your campaign
                      type.
                    </p>
                  </Grid>
                </Grid>
              )}

              {/* Platform Multipliers */}
              {![
                AD_TYPES.SPONSORED_BRAND_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
              ].includes(state.adType) && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>Platform Multipliers:</h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                    style={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Desktop
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"desktopPlatformBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`Desktop`}
                        defaultValue={state.desktopPlatformBidMultiplier}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: "0 2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        Mobile
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"mobilePlatformBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`Mobile`}
                        defaultValue={state.mobilePlatformBidMultiplier}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: "2rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                        }}
                      >
                        App
                      </label>
                      <FormGroupTile
                        type="formcontrol"
                        formControlType={"number"}
                        prefix={"%"}
                        prefixSide={"right"}
                        stateName={"appPlatformBidMultiplier"}
                        handleChildFormElement={(key, value) =>
                          onChange({ [key]: value })
                        }
                        placeholder={`App`}
                        defaultValue={state.appPlatformBidMultiplier}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      This control allows you to set bid multipliers starting at
                      0% for desktop, app and mWeb (mobile web) platforms.
                    </p>
                  </Grid>
                </Grid>
              )}
              {![
                AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
              ].includes(state.adType) && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>
                      Carousel Placement Toggles:
                    </h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                    style={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    <Grid item xs={12} lg={4} style={{ padding: "0 1rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                          display: "flex",
                        }}
                      >
                        Search
                        <ToolTips
                          toolTip={`Appears on search and browse pages.`}
                          position="top"
                          id={"searchPlacementTooltip"}
                        />
                      </label>
                      <Switch
                        onText=""
                        offText=""
                        value={state.searchCarouselCampaignPlacement}
                        onColor="#FFFFFF"
                        onChange={(e) => {
                          onChange({
                            searchCarouselCampaignPlacement:
                              !state.searchCarouselCampaignPlacement,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4} style={{ padding: "0 1rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                          display: "flex",
                        }}
                      >
                        Item
                        <ToolTips
                          toolTip={`Appears on relevant item pages.`}
                          position="top"
                          id={"itemPlacementTooltip"}
                        />
                      </label>
                      <Switch
                        onText=""
                        offText=""
                        value={state.itemCarouselCampaignPlacement}
                        onColor="#FFFFFF"
                        onChange={(e) => {
                          onChange({
                            itemCarouselCampaignPlacement:
                              !state.itemCarouselCampaignPlacement,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4} style={{ padding: "0 1rem" }}>
                      <label
                        style={{
                          color: "#73706E",
                          fontWeight: "600",
                          paddingBottom: ".7rem",
                          display: "flex",
                        }}
                      >
                        Buy Box
                        <ToolTips
                          toolTip={`Appears on relevant item pages below the "add to cart" box.`}
                          position="top"
                          id={"buyBoxPlacementTooltip"}
                        />
                      </label>
                      <Switch
                        onText=""
                        offText=""
                        value={state.buyBoxCarouselCampaignPlacement}
                        onColor="#FFFFFF"
                        onChange={(e) => {
                          onChange({
                            buyBoxCarouselCampaignPlacement:
                              !state.buyBoxCarouselCampaignPlacement,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      Specify where you want your listings to show up using
                      these placement options. By default, the Search In-grid
                      placement is toggled on and cannot be toggled off.
                    </p>
                  </Grid>
                </Grid>
              )}
              {/* Walmart Suggested Keywords */}
              {![
                AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_WALMART.value,
                AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value,
              ].includes(state.adType) && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>
                      Walmart Suggested Keywords
                    </h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                    style={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    <Grid item xs={12} className={classes.checkboxContainer}>
                      <Checkbox
                        inline
                        number={`${adId || "create"}-walmartSuggestedKeywords`}
                        label="Use Walmart Suggested Keywords"
                        checked={state.walmartSuggestedKeywordsEnabled}
                        disabled={false}
                        onClick={(e) => {
                          onChange({
                            walmartSuggestedKeywordsEnabled:
                              !state.walmartSuggestedKeywordsEnabled,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      Allow Walmart to automatically identify new keyword
                      opportunities that have the potential to help increase
                      product visibility and boost sales.
                    </p>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {/* end of Walmart-specific fields */}
          {state.adType === AD_TYPES.SPONSORED_PRODUCT.value &&
            state.hasParent && (
              <>
                {/* Negative Match Types */}
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <h3 className={classes.subtitle}>Negative Match Type:</h3>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={5}
                    className={classes.inputContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className={classes.checkboxContainer}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-negativePhraseMatch`}
                        label="Phrase"
                        checked={state.negativePhraseMatchEnabled}
                        disabled={state.initialNegativePhraseMatchEnabled}
                        onClick={(e) => {
                          onChange({
                            negativePhraseMatchEnabled:
                              !state.negativePhraseMatchEnabled,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className={classes.checkboxContainer}
                    >
                      <Checkbox
                        inline
                        number={`${adId || "create"}-negativeExactMatch`}
                        label="Exact"
                        checked={state.negativeExactMatchEnabled}
                        disabled={state.initialExactMatchEnabled}
                        onClick={(e) => {
                          onChange({
                            negativeExactMatchEnabled:
                              !state.negativeExactMatchEnabled,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <p
                      className={`${classes.explanationText} ${
                        review && classes.reviewExplanationText
                      }`}
                      style={{ paddingTop: "2rem" }}
                    >
                      Allows you to enable/disable automated discovery of
                      keywords as well as harvest keywords that match and broad
                      or phrase terms.
                    </p>
                  </Grid>
                </Grid>
              </>
            )}{" "}
          {!state.hasParent && channel !== "walmart" && (
            <Grid style={{ width: "100%" }} className="mt-5">
              <NegativeKeywordsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
              />
            </Grid>
          )}
          {!state.hasParent && channel === "amazon" && (
            <Grid style={{ width: "100%" }}>
              <NegativeAsinsField
                state={state}
                onChange={onChange}
                errors={errors}
                fieldsToValidate={fieldsToValidate}
                channel={channel}
              />
            </Grid>
          )}
          {!state.canHaveChildren &&
            (state.keywordHarvestingEnabled || state.asinHarvestingEnabled) &&
            ![
              AD_TYPES.SPONSORED_PRODUCT_AUTO.value,
              AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value,
            ].includes(state.adType) && (
              <HarvestingConfiguration
                onChange={onChange}
                errors={errors}
                adLayout={true}
                state={state}
              />
            )}
        </Grid>
      </>
    );
  };

  return (review && !state.id) || state.canHaveChildren ? null : (
    <Grid className={classes.menuContainer}>
      {showAccordion ? (
        <Accordion
          elevation={0}
          style={{
            border: open ? "1px solid #ccc" : "none",
            borderRadius: theme.spacing(1),
            padding: "1rem",
          }}
          onChange={(e, expanded) => {
            if (expanded) {
              setOpen(true);
            } else {
              setOpen(false);
            }
          }}
          disableGutters
        >
          <AccordionSummary
            aria-controls={"filter-contents"}
            id={"filter-header"}
            classes={{ expanded: classes.expanded }}
          >
            <h6
              className="title"
              style={{
                display: "flex",
                alignItems: "center",
                color: "#73706E",
                fontWeight: "700",
                fontSize: 16,
                textAlign: "right",
                padding: "0 2rem",
                minHeight: "40px",
                marginTop: "-5rem",
                background: "#FFF",
                width: "250px",
                marginLeft: "2rem",
              }}
            >
              {(channel !== "google" || (review && state.id)) && (
                <>
                  Advanced Options{" "}
                  <ExpandMore
                    style={{
                      fontSize: 32,
                      transform: open ? "rotate(180deg)" : "none",
                      cursor: "pointer",
                    }}
                  />
                </>
              )}
            </h6>
          </AccordionSummary>
          <AccordionDetails className={"mb-5"}>
            {renderAdvancedSettings()}
          </AccordionDetails>
        </Accordion>
      ) : (
        channel !== "google" && renderAdvancedSettings()
      )}
    </Grid>
  );
};

export default withStyles(useStyles)(AdvancedTargetingOptions);
