import React from "react";
import { Row } from "react-bootstrap";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import CustomHref from "components/core/blocks/CustomHref";
import { useHistory } from "react-router-dom";

const SignupLoading = () => {
  const history = useHistory();

  return (
    <Row className="d-flex justify-content-center align-items-center h-100 w-100">
      <div className="d-flex justify-content-center">
        <img src={logo} alt="" className="rotate" width="80" height="auto" />
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ position: "absolute", bottom: "30px" }}
      >
        <CustomHref
          text={
            <span
              className="fs-3"
              onClick={(e) => {
                e.preventDefault();
                history.push("/onboarding/v2/connect");
              }}
            >
              Taking too long? Continue.
            </span>
          }
        />
      </div>
    </Row>
  );
};

export default SignupLoading;
