import ProductQuality from "modules/product_quality/ProductQuality";
import OrgQuality from "modules/product_quality/OrgQuality";
import ProductSEO from "modules/product_quality/ProductSEO";
import Explore from "modules/keyword_explorer/Explore";
import ContentTemplate from "./ContentTemplate";

export const QualityRoutes = [
  {
    redirect: true,
    path: "/content/quality/product",
    layout: "/user",
    name: "Product Quality",
    icon: "pe-7s-portfolio",
    component: ProductQuality,
    application: "Product",
  },
  {
    redirect: true,
    path: "/content/quality/org",
    layout: "/user",
    name: "org Quality",
    icon: "pe-7s-portfolio",
    component: OrgQuality,
    application: "Product",
  },
  {
    redirect: true,
    path: "/content/quality/category/:category",
    layout: "/user",
    name: "org Quality",
    icon: "pe-7s-portfolio",
    component: OrgQuality,
    application: "Product",
  },
  {
    redirect: true,
    path: "/content/seo",
    layout: "/user",
    name: "Content",
    icon: "pe-7s-portfolio",
    component: ProductSEO,
    application: "Product",
  },
  {
    redirect: true,
    path: "/content/explore",
    layout: "/user",
    name: "Explore Keywords",
    icon: "pe-7s-portfolio",
    component: Explore,
    application: "Reports",
  },
  {
    redirect: true,
    path: "/content/templates/:templateId",
    layout: "/user",
    name: "Create New Content Template",
    icon: "pe-7s-portfolio",
    component: ContentTemplate,
    application: "Product",
  },
];
