import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrgSwitcher from "components/core/blocks/OrgSwitcher";
import useDashboardTheme from "hooks/useDashboardTheme";
import { FaUser } from "react-icons/fa6";

const ProductOptions = ({ user, channel }) => {
  const [openAvatar, setOpenAvatar] = useState(false);
  const { section, sidebarColor } = useDashboardTheme();
  const isActiveSection = section === "admin";

  const isActive = (routeName) => {
    return document.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <div className="user pt-5 border-bottom">
      <div className="photo">
        <FaUser style={{ fontSize: "24px", color: "#2e0054", width: "100%" }} />
      </div>
      <div className="info">
        <a
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            setOpenAvatar(!openAvatar);
          }}
        >
          <span>
            <span
              className="sidebar-link"
              style={{
                color: "#2e0054",
              }}
            >
              {user.user_full_name}
            </span>
            {user.permissions !== "reports" && (
              <b
                style={{
                  color: "#2e0054",
                }}
                className={
                  openAvatar
                    ? "caret rotate-180 dropdown-toggle"
                    : "caret nav  dropdown-toggle"
                }
              />
            )}
          </span>
        </a>
        {user.organization_admin && user.permissions !== "reports" ? (
          <Collapse in={openAvatar}>
            <ul className="nav">
              <li className="w-100">
                <Link
                  to="/user/inviteuser"
                  style={{
                    borderBottom: isActive("/user/inviteuser")
                      ? `1px solid ${sidebarColor}`
                      : "",
                    borderRadius: 0,
                  }}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-user-plus text-dark-purple" />
                  </span>
                  <span className="sidebar-normal sidebar-link text-dark-purple">
                    Invite Users
                  </span>
                </Link>
              </li>
              <li className="w-100">
                <Link
                  to="/user/refer"
                  style={{
                    borderBottom: isActive("/user/refer")
                      ? `1px solid ${sidebarColor}`
                      : "",
                    borderRadius: 0,
                  }}
                >
                  <span className="sidebar-mini">
                    <i
                      className="fa fa-gift"
                      style={{
                        color: "#2e0054",
                      }}
                    />
                  </span>
                  <span className="sidebar-normal sidebar-link text-dark-purple">
                    Refer
                  </span>
                </Link>
              </li>

              <li style={{ width: "100%" }}>
                {channel === "walmart" ? (
                  <Link
                    to="/user/walmartsummary"
                    style={{
                      borderBottom: isActive("/user/walmartsummary")
                        ? `1px solid ${sidebarColor}`
                        : "",
                      borderRadius: 0,
                    }}
                  >
                    <span className="sidebar-mini">
                      <i
                        className="fa fa-plug"
                        style={{
                          color: "#2e0054",
                        }}
                      />
                    </span>
                    <span
                      className="sidebar-normal sidebar-link"
                      style={{
                        color: "#2e0054",
                      }}
                    >
                      Walmart Connect
                    </span>
                  </Link>
                ) : (
                  <Link
                    to="/user/amzsummary"
                    style={{
                      borderBottom: isActive("/user/amzsummary")
                        ? `1px solid ${sidebarColor}`
                        : "",
                      borderRadius: 0,
                    }}
                  >
                    <span className="sidebar-mini">
                      <i
                        className="fa fa-plug"
                        style={{
                          color: "#2e0054",
                        }}
                      />
                    </span>
                    <span
                      className="sidebar-normal sidebar-link"
                      style={{
                        color: "#2e0054",
                      }}
                    >
                      Amazon Connect
                    </span>
                  </Link>
                )}
              </li>
              <li className="w-100">
                <Link
                  to="/user/billing"
                  style={{
                    borderBottom: isActive("/user/billing")
                      ? `1px solid ${sidebarColor}`
                      : "",
                    borderRadius: 0,
                  }}
                >
                  <span className="sidebar-mini">
                    <i
                      className="fa fa-credit-card"
                      style={{
                        color: "#2e0054",
                      }}
                    />
                  </span>
                  <span className="sidebar-normal sidebar-link text-dark-purple">
                    Billing
                  </span>
                </Link>
              </li>
              <li className="w-100">
                <Link
                  to="/user/preferences"
                  style={{
                    borderBottom: isActive("/user/preferences")
                      ? `1px solid ${sidebarColor}`
                      : "",
                    borderRadius: 0,
                  }}
                >
                  <span className="sidebar-mini">
                    <i
                      className="fa fa-cog"
                      style={{
                        color: "#2e0054",
                      }}
                    />
                  </span>
                  <span className="sidebar-normal sidebar-link text-dark-purple">
                    Preferences
                  </span>
                </Link>
              </li>
              {/* TODO: Deprecate this option
              (it is now located under Staff Tools, but we will keep it here for now in order to not piss off the CS team) */}
              {user.is_staff && (
                <>
                  <li className="w-100">
                    <a>
                      <span className="sidebar-mini">
                        <i
                          className="fa fa-group"
                          style={{
                            color: "#2e0054",
                          }}
                        />
                      </span>
                      <span className="sidebar-normal sidebar-link">
                        <OrgSwitcher isActiveSection={isActiveSection} />
                      </span>
                    </a>
                  </li>
                </>
              )}
            </ul>
          </Collapse>
        ) : null}
      </div>
    </div>
  );
};

export default ProductOptions;
