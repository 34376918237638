import React, { useEffect, useMemo, useRef, useState } from "react";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { ProductCell } from "views/merchandise/Dashboard";
import { selectModules } from "redux/selectors/modules";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { sendToastNotification } from "utils/sendToastNotification";
import {
  INVENTORY_STATE_FILTER,
  PRICING_PRODUCT_ENROLMENT_FILTER,
  SALES_STATE_FILTER,
  buildCustomTagsFilter,
  buildProductVariantsFilter,
} from "views/advertising/productLifecycleFilters";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import { formatCurrency } from "utils/formatNumber";

const ProductsTable = ({
  isLoading,
  productData,
  isExpandable = true,
  isSelectable = true,
  ExpandedContent,
  marketPlace,
  onBulkChange,
  bulkActionOptions,
  onSelection,
  renderProductStatusToggles,
  hideCompareValue = false,
  initialSelectedProducts = [],
  cellContext = null,
}) => {
  const TABLE_ID = "productsTable";
  const tableRef = useRef();
  const notificationRef = useRef();
  const state = useSelector((state) => state);
  const modules = selectModules(state);
  const queryClient = useQueryClient();

  const [selectedProducts, setSelectedProducts] = useState(
    initialSelectedProducts
  );

  // Below updates state when product selections cleared in parent dashboard on successful update
  useMemo(() => {
    setSelectedProducts(initialSelectedProducts);
  }, [initialSelectedProducts]);

  const variants = useMemo(() => {
    const variants = {};
    if (productData) {
      for (let i in productData) {
        const s = productData[i];
        if (!s.variations) {
          continue;
        }
        try {
          const variations = s.variations;
          if (!variations) {
            continue;
          }
          Object.keys(variations).forEach((v) => {
            if (variants[v] === undefined) {
              variants[v] = [];
            }
            if (variants[v].indexOf(variations[v]) === -1) {
              variants[v].push(variations[v]);
            }
          });
        } catch (e) {
          // Do Nothing
        }
      }
    }
    return variants;
  }, [productData]);

  useEffect(() => {
    if (onSelection) {
      onSelection(selectedProducts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts]);

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const updatePricingProductHandler = useMutation(
    async (updateData) => {
      return await api.post(`/pricing/pricing_plan_products/pause`, updateData);
    },
    {
      onSuccess: (res, variables) => {
        sendToastNotification(
          notificationRef,
          "success",
          "Product Status Successfully Updated"
        );
        setSelectedProducts([]);
        queryClient.invalidateQueries();
      },
      onError: (res, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Product Status"
        );
      },
    }
  );

  const updateProductStatus = (newStatus, productId, bulkAction = false) => {
    let productIds;

    if (bulkAction) {
      productIds = selectedProducts.join(",");
    } else {
      // Below is odd, but need to cast id as a string to avoid .split(',') failing in backend
      productIds = productId.toString();
    }

    updatePricingProductHandler.mutate({
      status: newStatus,
      marketplace: marketPlace.marketPlace,
      product_ids: productIds,
    });
  };

  const columnSpecs = [
    {
      key: "product_price",
      compareKey: "price_delta",
      options: {
        checked: true,
        isNegative: true,
        noColor: true,
        metric: {
          id: "product_price",
          format: "currency",
          accessor: (d) => d?.product_price,
          name: "Current Price",
        },
      },
    },
    {
      key: "suggested_price",
      compareKey: "price",
      options: {
        checked: true,
        isNegative: true,
        noColor: true,
        overrideCompare: true,
        overrideCompareAccessor: (d) => {
          if (d.suggested_price && d.product_price) {
            return d.suggested_price - d.product_price;
          }
          return 0;
        },
        overrideCompareFormatter: (v) =>
          formatCurrency(v, marketPlace, false, true, false),
        metric: {
          name: `Suggested ${
            MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
          }`,
        },
      },
    },
    {
      key: "sales",
      compareKey: "sales_percent",
    },
    { key: "units", compareKey: "units_percent", options: { checked: false } },
    {
      key: "org_conv_rate",
      compareKey: "org_conv_rate_delta",
      options: {
        checked: true,
        metric: {
          id: "org_conv_rate",
          name: "Conv/Rate",
          format: "percent",
        },
      },
    },
    {
      key: "bsr",
      compareKey: "bsr_percent",
      options: { checked: false },
    },
    {
      key: "page_views",
      compareKey: "page_views_percent",
      options: { checked: true },
    },
    {
      key: "profit_per_page_view_wo_adspend",
      compareKey: "profit_per_page_view_wo_adspend_delta",
      options: { checked: true },
    },
    { id: "seperator", hidden: true },
    {
      key: "min_price",
      compareKey: null,
      isStatic: true,
      options: {
        isStatic: true,
        editCell: true,
        format: "currency",
        minValue: 0,
        columnTitle: "Min",
        checked: true,
      },
    },
    {
      key: "max_price",
      compareKey: null,
      options: {
        isStatic: true,
        editCell: true,
        format: "currency",
        minValue: 0,
        columnTitle: "Max",
        checked: true,
      },
    },
    {
      key: "landed_cost",
      compareKey: null,
      options: {
        editCell: true,
        format: "currency",
        minValue: 0,
        columnTitle: "Landed",
        checked: false,
        toolTip: "The product's landed cost",
      },
    },
    {
      key: "shipping_cost",
      compareKey: null,
      options: {
        editCell: true,
        format: "currency",
        minValue: 0,
        columnTitle: "Shipping",
        checked: false,
        toolTip: "The product's shipping cost",
      },
    },
    { id: "seperator", hidden: true },
    {
      key: "profit",
      compareKey: "profit_delta",
      options: { checked: false },
    },
    {
      key: "profit_wo_adspend",
      compareKey: "profit_wo_adspend_delta",
      options: { checked: false },
    },
    {
      key: "cogs",
      compareKey: "cogs_percent",
      options: { checked: false },
    },
    {
      key: "margin",
      compareKey: "margin_delta",
      options: { checked: false },
    },
    {
      key: "margin_wo_adspend",
      compareKey: "margin_wo_adspend_delta",
      options: { checked: false },
    },
    { id: "seperator", hidden: true },
    {
      key: "ad_sales",
      compareKey: "ad_sales_percent",
      options: {
        checked: false,
      },
    },
    {
      key: "ad_spend",
      compareKey: "ad_spend_percent",
      options: {
        checked: false,
      },
    },
    {
      key: "ctr",
      compareKey: "ctr_delta",
      options: {
        checked: false,
      },
    },
    {
      key: "cpc",
      compareKey: "cpc_delta",
      options: {
        checked: false,
      },
    },
  ];

  if (renderProductStatusToggles) {
    columnSpecs.unshift({
      key: "status",
      compareKey: null,
      options: {
        editCell: true,
        hideFilter: false,
        toggleAction: (row, value) => {
          const { id: productId } = row;
          updateProductStatus(value, productId, false);
        },
        filterType: "select",
        selectOptions: [
          {
            value: "enabled",
            label: "Active",
          },
          {
            value: "paused",
            label: "Inactive",
          },
        ],
        format: "toggle",
        onValue: "enabled",
        offValue: "disabled",
        className: "justify-content-end",
        columnTitle: "Status",
      },
    });
  }

  const productVariantsFilters = useMemo(() => {
    return buildProductVariantsFilter(variants);
  }, [variants]);

  const customTags = useMemo(() => {
    return productData?.map((product) => product.tags)?.flat() ?? [];
  }, [productData]);

  const ADDITIONAL_FILTERS = [
    INVENTORY_STATE_FILTER,
    SALES_STATE_FILTER,
    PRICING_PRODUCT_ENROLMENT_FILTER,
    productVariantsFilters,
    buildCustomTagsFilter(customTags),
  ];

  return (
    <BulkActionsTable
      tableEntityType={"product"}
      tableId={TABLE_ID}
      tableRef={tableRef}
      titleCol={{
        id: "products",
        accessor: "product_title",
        className: "text-start",
        width: 500,
        Header: "Products",
        isStatic: true,
        Cell: ({ value, row }) => {
          return (
            <ProductCell
              value={value}
              row={row}
              original={{
                ...row._original,
                id: row._original?.product_id,
                cellContext,
              }}
              showAsin={true}
              modules={modules}
              showInventoryBadge={false}
              showPricingBadge={true}
              showBuyBoxBadge={true}
            />
          );
        },
        filterMethod: () => {
          return;
        },
        Filter: () => null,
      }}
      columnSpecs={columnSpecs}
      data={productData}
      selectionKey="product_id"
      selected={selectedProducts}
      setSelected={setSelectedProducts}
      isExpandable={isExpandable}
      isSelectable={isSelectable}
      isLoading={isLoading}
      ExpandedContent={ExpandedContent}
      filterWidget={true}
      hideFilterToggleIcon={true}
      hideResetFiltersIcon={false}
      hideCompareValue={hideCompareValue}
      {...filterProps}
      additionalFilterOptions={ADDITIONAL_FILTERS}
      bulkActionOptions={bulkActionOptions}
      getSearchCriteria={(row) => {
        const { product_title, sku, asin, id, tags } = row;
        return `${product_title} ${sku} ${asin} ${id} ${tags?.join(" ")}`;
      }}
      tableRowDescription={"Products"}
      handleBulkChanges={onBulkChange || (() => {})}
    />
  );
};

export default ProductsTable;
