import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import checkModule from "utils/checkModule";
import AdvertisingSidebar from "./AdvertisingSidebar";
import PricingSidebar from "modules/pricingv2/Sidebar";
import Icon from "./Icon";
import promotionsPNG from "assets/images/icons/promotion-purple.svg";
import placementPNG from "assets/images/icons/placement-purple.svg";
import pricingPNG from "assets/images/icons/pricing-purple.svg";
import productPNG from "assets/images/icons/product-purple.svg";
import promotionsWhiteIcon from "assets/images/icons/promotion-w.png";
import placementWhiteIcon from "assets/images/icons/placement-w.png";
import pricingWhiteIcon from "assets/images/icons/pricing-w.png";
import productWhiteIcon from "assets/images/icons/product-w.png";
import Section from "./Section";
import SidebarLink from "./SidebarLink";
import BISidebar from "./BISidebar";
import StaffSidebar from "./StaffSidebar";
import { FaGlobe } from "react-icons/fa";
import useDashboardTheme, {
  PLACEMENT_SIDEBAR_COLOR,
} from "hooks/useDashboardTheme";
import { useProgramContext } from "components/custom/programs/ProgramContext";

const PlacementIcon = () => <Icon path={placementPNG} />;

const PromotionsIcon = () => <Icon path={promotionsPNG} />;

const PricingIcon = () => <Icon path={pricingPNG} />;

const ProductIcon = () => <Icon path={productPNG} />;

const PlacementActiveIcon = () => <Icon path={placementWhiteIcon} />;

const PromotionsActiveIcon = () => <Icon path={promotionsWhiteIcon} />;

const ProductActiveIcon = () => <Icon path={productWhiteIcon} />;

const PricingActiveIcon = () => <Icon path={pricingWhiteIcon} />;

const DashboardIcon = () => (
  <i className="pe-7s-graph1" style={{ color: "#2e0054" }}></i>
);

const DashboardActiveIcon = () => (
  <i className="pe-7s-graph1" style={{ color: "#FFF" }}></i>
);

const MerchantSidebar = ({
  modules,
  permissions,
  channel,
  applications,
  user,
}) => {
  const isActive = (routeName) => {
    return document.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const { section, sidebarColor } = useDashboardTheme();
  const { engine } = useProgramContext();

  if (permissions === "reports") {
    return null;
  }

  return (
    <li
      className="user"
      style={{
        marginBottom: "3rem",
        marginTop: 0,
        paddingBottom: 0,
        borderBottom: "none",
        width: "100%",
        color: "#2e0054",
      }}
    >
      <ul className="nav">
        {checkModule(modules, "AGENCY_DASHBOARD") && (
          <SidebarLink
            to={"/user/agency/dashboard"}
            isActive={isActive("/user/agency/dashboard")}
            icon={<i className="fa fa-users" />}
            primarySidebarLink={true}
          >
            Agency Dashboard{" "}
          </SidebarLink>
        )}
        {checkModule(modules, "REALTIME") && (
          <Section
            name={"Dashboard"}
            Icon={DashboardIcon}
            ActiveIcon={DashboardActiveIcon}
            activeSection={section}
            activeColor={sidebarColor}
            isActiveSection={section === "main"}
          >
            <SidebarLink
              to="/user/merchandising/dashboard"
              isActive={`${isActive(
                "/user/merchandising/dashboard"
              )} ${isActive("/user/merchandising/product")} ${isActive(
                "/user/merchandising/category"
              )} ${
                document.location.pathname === "/user/dashboard" ? "active" : ""
              }`}
              icon={
                <i
                  className="pe-7s-graph1"
                  style={{
                    color: "#2e0054",
                  }}
                ></i>
              }
              small
            >
              Dashboard
            </SidebarLink>
            <SidebarLink
              to="/user/live/report"
              isActive={isActive("/user/live/report")}
              icon={<i className="pe-7s-search"></i>}
              small
            >
              Realtime
            </SidebarLink>
          </Section>
        )}
        {!checkModule(modules, "REALTIME") &&
          !(channel === "walmart" && user.organization_type === "vendor") && (
            <SidebarLink
              to="/user/merchandising/dashboard"
              isActive={`${isActive(
                "/user/merchandising/dashboard"
              )} ${isActive("/user/merchandising/product")} ${isActive(
                "/user/merchandising/category"
              )} ${
                document.location.pathname === "/user/dashboard" ? "active" : ""
              }`}
              icon={<i className="pe-7s-graph1"></i>}
              primarySidebarLink={true}
            >
              Dashboard
            </SidebarLink>
          )}

        <Section
          name={"Placement"}
          Icon={PlacementIcon}
          ActiveIcon={PlacementActiveIcon}
          activeIcon={placementWhiteIcon}
          isActiveSection={
            section === "placement" ||
            (isActive("/user/programs/") && engine === "bidding")
          }
          activeSection={section}
          activeColor={PLACEMENT_SIDEBAR_COLOR}
        >
          <AdvertisingSidebar
            modules={modules}
            channel={channel}
            applications={applications}
            permissions={permissions}
            small
            activeColor={sidebarColor}
          />
          {channel === "amazon" && (
            <>
              <SidebarLink
                to="/user/searchanalytics"
                isActive={isActive("/user/searchanalytics")}
                icon={<i className="pe-7s-search text-dark-purple" />}
                small
                section={"placement"}
              >
                Search Analytics
              </SidebarLink>
              {checkModule(modules, "ORGANIC") && (
                <SidebarLink
                  to="/user/seo/performance"
                  isActive={isActive("/user/seo/performance")}
                  icon={
                    <div className="fs-4 pt-1">
                      <FaGlobe />
                    </div>
                  }
                  small
                  section={"product"}
                >
                  <span
                    style={{
                      color: "#2e0054",
                    }}
                  >
                    SEO Performance
                  </span>
                </SidebarLink>
              )}
            </>
          )}
          <SidebarLink
            to={"/user/advertising/settings"}
            isActive={
              isActive("/user/advertising/settings") ||
              // Get program context => if ads, flag as active, do the same for pricing
              isActive("/user/programs" && engine === "bidding")
            }
            icon={<i className="fa fa-cog" />}
            small
            section={"placement"}
          >
            Configuration
          </SidebarLink>
        </Section>
        {channel === "amazon" && (
          <>
            <Section
              name={"Pricing"}
              Icon={PricingIcon}
              ActiveIcon={PricingActiveIcon}
              activeSection={section}
              activeColor={sidebarColor}
              isActiveSection={section === "pricing"}
            >
              <PricingSidebar
                modules={modules}
                applications={applications}
                permissions={permissions}
                small
              />
            </Section>
            <Section
              name={"Promotions"}
              Icon={PromotionsIcon}
              ActiveIcon={PromotionsActiveIcon}
              activeSection={section}
              activeColor={sidebarColor}
              isActiveSection={section === "promotions"}
            >
              <SidebarLink
                isActive={isActive("merchandising/promotions")}
                to="/user/merchandising/promotions"
                icon={<i className="pe-7s-ticket text-dark-purple" />}
                small
                section={"promotions"}
              >
                Deals
              </SidebarLink>
              {checkModule(modules, "MERCHANDISE_PLANS") && (
                <SidebarLink
                  isActive={isActive("merchandising/planning")}
                  to="/user/merchandising/planning"
                  icon={<i className="pe-7s-notebook text-dark-purple" />}
                  small
                  section={"promotions"}
                >
                  Promotion Planning
                </SidebarLink>
              )}
            </Section>
          </>
        )}
        <Section
          name={"Product"}
          Icon={ProductIcon}
          ActiveIcon={ProductActiveIcon}
          activeSection={section}
          activeColor={sidebarColor}
          isActiveSection={section === "product"}
        >
          {channel === "amazon" && (
            <SidebarLink
              isActive={isActive("content/quality") || isActive("content/seo")}
              to="/user/content/quality/org"
              icon={<i className="fa fa-road text-dark-purple" />}
              small
              section={"product"}
            >
              Content
            </SidebarLink>
          )}
          <SidebarLink
            isActive={isActive("productlaunch")}
            to="/user/productlaunch"
            icon={<i className="pe-7s-rocket" />}
            small
            section={"product"}
          >
            New Product Launch
          </SidebarLink>
          <SidebarLink
            isActive={isActive("categorymanagement")}
            to="/user/categorymanagement"
            icon={
              <i
                className="pe-7s-keypad"
                style={{
                  color: "#2e0054",
                }}
              />
            }
            small
            section={"product"}
          >
            Product & Categories
          </SidebarLink>
          {channel === "amazon" && (
            <SidebarLink
              isActive={isActive("creatives")}
              to="/user/creatives"
              icon={
                <i
                  className="pe-7s-film"
                  style={{
                    color: "#2e0054",
                  }}
                />
              }
              small
              section={"product"}
            >
              Videos & Assets
            </SidebarLink>
          )}
        </Section>
        <BISidebar
          title={"Reports"}
          modules={modules}
          applications={applications}
          permissions={permissions}
          hideSearchAnalytics
          activeSection={section}
          activeColor={sidebarColor}
        />
        {user.is_staff && (
          <StaffSidebar
            modules={modules}
            applications={applications}
            permissions={permissions}
            activeSection={section}
            activeColor={sidebarColor}
          />
        )}
      </ul>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    channel: state.channel.currentChannel,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(MerchantSidebar));
