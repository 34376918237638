import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Row, Col } from "react-bootstrap";

const Accordion = ({
  title,
  initialExpanded = false,
  children,
  marginTop = "3rem",
  overflow = "hidden",
  onExpanded = null,
}) => {
  const [expanded, setExpanded] = useState(initialExpanded);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);
  useEffect(() => {
    if (!contentRef.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      setHeight(contentRef.current.clientHeight + 20);
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [expanded]);

  const handleExpand = (expanded) => {
    setExpanded(expanded);
    if (typeof onExpanded === "function") {
      onExpanded(expanded);
    }
  };

  return (
    <>
      <Row>
        <Col
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop,
            cursor: "pointer",
          }}
          onClick={() => handleExpand(!expanded)}
        >
          <span
            style={{
              color: "#2e0054",
              fontSize: "14px",
              flexGrow: 0,
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              marginRight: "2rem",
              textTransform: "uppercase",
            }}
          >
            {title}
            <i
              className={expanded ? "pe-7s-angle-up" : "pe-7s-angle-down"}
              style={{ fontSize: "2rem" }}
            />
          </span>
          <div
            style={{
              flexGrow: 1,
              height: "1px",
              backgroundColor: "#eee",
            }}
          ></div>
        </Col>
      </Row>
      <Row
        style={{
          overflow: expanded ? null : overflow,
          maxHeight: expanded ? height : "0",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <Col xs={12}>
          <div ref={contentRef}>
            <div style={{ marginTop: "2rem" }}>{children}</div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Accordion;
