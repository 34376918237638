import React from "react";
import { ProgressBar } from "react-bootstrap";

const SteppedProgressBar = ({
  currentStep,
  steps,
  isLoading,
  variant = "tr-success",
}) => {
  if (isLoading) {
    const step = steps?.[Math.min(currentStep, steps.length - 1)];
    const now = Math.max((currentStep / steps.length) * 100, 10);
    return (
      <div className="my-3">
        <ProgressBar
          animated
          style={{ height: "20px", fontSize: "12px" }}
          now={now}
          variant={variant}
        />
        <span style={{ fontSize: "1.2rem" }}>{step}</span>
      </div>
    );
  }
  return null;
};

export default SteppedProgressBar;
