import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";

import Footer from "../views/Footer";
// dinamically create Info routes
import routes from "routes/routes";

class Instructions extends Component {
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      // this.props.history.push("/signin");
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.auth.isAuthenticated) {
      // this.props.history.push("/signin");
      this.props.history.push("/");
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/info") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(routeProps) => <prop.component {...routeProps} />}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
        <Row>
          <Switch>{this.getRoutes(routes)}</Switch>
        </Row>
        <Row>
          <Footer fluid />
        </Row>
      </div>
    );
  }
}

Instructions.propTypes = {
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(Instructions);
