export const groupBy = (arr, callback) => {
  return arr.reduce((acc, entry) => {
    const key = callback(entry);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(entry);
    return acc;
  }, {});
};
