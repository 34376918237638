import {
  INVENTORY_STATE_OPTIONS,
  SALE_STATE_OPTIONS,
} from "components/custom/merchandise/constants";
import { toTitleCase } from "utils/formatText";

const ENROLL_OPTIONS = [
  {
    value: "all",
    label: "All Enrollment States",
  },
  {
    value: "enrolled",
    label: "Enrolled Only",
  },
  {
    value: "not_enrolled",
    label: "Not Enrolled Only",
  },
  {
    value: "profiling",
    label: "Price Profiling Only",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
];

const PRODUCT_AD_SERVING_STATUS_OPTIONS = [
  {
    value: "AD_STATUS_LIVE",
    label: "Serving",
  },
  {
    value: "NOT_BUYABLE",
    label: "Not Buyable",
  },
  {
    value: "MISSING_DECORATION",
    label: "Missing Decoration",
  },
  {
    value: "CAMPAIGN_PAUSED",
    label: "Campaign Paused",
  },
  {
    value: "AD_POLICING_SUSPENDED",
    label: "Ad Policing Suspended",
  },
  { value: "AD_PAUSED", label: "Ad Paused" },
  { value: "null", label: "Pending" },
  { value: "INELIGIBLE", label: "Ineligible" },
  {
    value: "CAMPAIGN_OUT_OF_BUDGET",
    label: "Campaign Out of Budget",
  },
];

export const INVENTORY_STATE_FILTER = {
  label: "Inventory State",
  id: "inventory_state",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Inventory States",
    },
    ...INVENTORY_STATE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (product, filterValue) => {
    if (filterValue !== "all") {
      if (filterValue === "IN_STOCK") {
        return (
          product?.inventory_state === filterValue &&
          product?.stock_status === filterValue
        );
      }
      return (
        product?.inventory_state === filterValue ||
        product?.stock_status === filterValue ||
        (product?.stock_status === "IN_STOCK_SCARCE" &&
          filterValue === "LOW_STOCK")
      );
    }
    return true;
  },
};

export const SALES_STATE_FILTER = {
  label: "Sales State",
  id: "state",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Sales States",
    },
    ...SALE_STATE_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (product, filterValue) => {
    if (filterValue !== "all") {
      return product?.sales_state === filterValue;
    }
    return true;
  },
};

export const PRODUCT_WITH_ADS_FILTER = {
  label: "Advertising Status",
  id: "ad_cnt",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Products",
    },
    {
      value: "withActiveAds",
      label: "Active Ads",
    },
    {
      value: "withoutActiveAds",
      label: "No Active Ads",
    },
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (product, filterValue) => {
    if (filterValue === "withActiveAds") {
      return product?.ad_cnt;
    }
    if (filterValue === "withoutActiveAds") {
      return !product?.ad_cnt;
    }

    return true;
  },
};

export const PRODUCT_AD_STATUS_FILTER = {
  label: "Serving Status",
  id: "serving_status",
  format: "select",
  selectOptions: [
    {
      value: "all",
      label: "All Statuses",
    },
    ...PRODUCT_AD_SERVING_STATUS_OPTIONS,
  ],
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (product, filterValue) => {
    if (filterValue !== "all") {
      return product?.serving_status === filterValue;
    }
    return true;
  },
};
export const PRICING_PRODUCT_ENROLMENT_FILTER = {
  label: "Enrollment State",
  id: "enrolled",
  format: "select",
  selectOptions: ENROLL_OPTIONS,
  override: true,
  defaultValue: "all",
  operator: "custom",
  filterFunction: (product, filterValue) => {
    if (filterValue === "enrolled") {
      if (
        product?.pp_enroll_date === null ||
        product?.pp_enroll_date === undefined
      ) {
        return false;
      }
      return product?.pp_enroll_date !== null && product?.pp_enroll_date !== 0;
    } else if (filterValue === "not_enrolled") {
      if (
        product?.pp_enroll_date === null ||
        product?.pp_enroll_date === undefined
      ) {
        return true;
      }
      return product?.pp_enroll_date === null || product?.pp_enroll_date === 0;
    } else if (filterValue === "rejected") {
      return product?.pp_status !== "enabled";
    } else if (filterValue === "profiling") {
      return product?.pp_price_profiling !== "true";
    }
    return true;
  },
};

export const buildProductVariantsFilter = (variants) => {
  const variantOptions = [];

  if (variants) {
    Object.keys(variants).forEach((variant) => {
      const vr = variants[variant];
      for (let i = 0; i < vr.length; i++) {
        const v = vr[i];
        const key = `${variant} - ${v}`;
        variantOptions.push({
          value: key,
          label: toTitleCase(key),
        });
      }
    });
  }

  return {
    label: "Variants",
    id: "variants",
    format: "select",
    selectOptions: [
      {
        value: "all",
        label: "All Variants",
      },
      ...variantOptions.flat(),
    ],
    override: true,
    defaultValue: "all",
    operator: "custom",
    filterFunction: (product, filterValue) => {
      if (filterValue === "all") {
        return true;
      }

      if (!product?.variations) {
        return false;
      }
      const variations = product.variations;
      if (!variations) {
        return false;
      }
      const parts = filterValue?.split("-");
      const key = parts?.[0].trim().toLocaleLowerCase();
      if (!key) {
        return true;
      }
      const value = parts?.splice(1, parts.length).join("-").trim();

      if (
        variations[key] !== undefined &&
        variations[key].toString() === value.toString()
      ) {
        return true;
      }
      return false;
    },
  };
};

export const buildCustomTagsFilter = (tags) => {
  const tagOptions = [
    ...new Set(
      tags
        // Filter out potential null or empty string values
        // Some walmart products have tags set to null instead of empty array
        ?.filter((tag) => tag) ?? []
    ),
  ]?.map((tag) => ({
    value: tag.toLowerCase(),
    label: tag,
  }));

  return {
    label: "Custom Tags",
    id: "tags",
    format: "select",
    selectOptions: [
      {
        value: "all",
        label: "All Tags",
      },
      ...tagOptions.flat(),
    ],
    override: true,
    defaultValue: "all",
    operator: "custom",
    filterFunction: (entity, filterValue) => {
      if (filterValue === "all") {
        return true;
      }

      return entity?.tags
        ?.map((tag) => tag.toLowerCase())
        ?.includes(filterValue?.toLowerCase());
    },
  };
};
