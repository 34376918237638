import React from "react";
import { Badge as Label, Nav } from "react-bootstrap";

export const RULES_BADGE_TEXT = {
  CLEARANCE: "Clearance",
  MARGIN_PROFILING: "Margin Profiling",
  PROFILE: "Margin Profiling",
  BSR: "BSR",
  CUSTOM: "Custom",
};

export const SEGMENT_TEXT = {
  sales_pressure: "Sales Pressure",
  profit_potential: "Profit Potential",
  right_price: "Right Price",
  out_of_stock: "Out of Stock",
  slow_movers: "Slow Movers",
  no_price: "Slow Movers",
  new_plan: "Profiling",
};

export const getMetrics = (price, product, pricingProduct) => {
  let quantity = 0;

  if (product) {
    quantity = product.product_quantity + product.product_quantity_fba;
  }

  if (price <= 0) {
    return;
  }

  let pd = null;
  for (let i = 0; i < pricingProduct?.pricing_data.length; i++) {
    pd = pricingProduct?.pricing_data[i];
    if (pd.price === price) {
      break;
    } else if (pd.price > price) {
      pd = pricingProduct?.pricing_data[i - 1];
      break;
    }
  }

  let units = pd?.modeled_demand ?? 0;
  let sales = units * price;
  let profit = pd?.modeled_profit ?? 0;
  let margin = profit / sales;
  let turnover = quantity / units / 7;

  return {
    units,
    profit,
    sales,
    margin,
    turnover,
  };
};

export const getCurveConfidenceMofifier = (optData) => {
  if (optData.curve_confidence && optData.curve_confidence > 0.75) {
    return 1.15;
  }

  if (optData.curve_confidence && optData.curve_confidence > 0.5) {
    return 1.25;
  }

  return 1.5;
};

export const getPlanType = (pricingPlan) => {
  const types = [
    {
      value: "same_strategy",
      label: "Dynamic Price",
      description: "Same strategy for all products",
    },
    {
      value: "same_price",
      label: "Dynamic Price",
      description: "Same price for all products",
    },
    {
      value: "competitor",
      label: "Competitor",
      description: "Competitors Matcbing",
    },
    {
      value: "rules_engine",
      label: "Rules Engine",
      description: "Custom rules for the pricing plan",
    },
  ];

  if (pricingPlan.plan_type === "rules_engine") {
    if (pricingPlan.is_auto) {
      let templateType = null;
      if (pricingPlan?.current_program?.template_type) {
        templateType = pricingPlan?.current_program?.template_type;
      } else if (pricingPlan?.current_program__template_type) {
        templateType = pricingPlan?.current_program__template_type;
      }
      if (templateType != null && templateType !== "MARGIN_PROFILING") {
        return templateType;
      }

      return types[1].label;
    }
  }

  let planType = types[1];

  for (let i = 0; i < types.length; i++) {
    const pt = types[i];
    if (pt.value === pricingPlan.plan_type) {
      planType = pt;
      break;
    }
  }

  return planType.label;
};



export const PricingRulesNav = ({ pricingPlan, hasModule }) => {
  if (!hasModule && pricingPlan.plan_type === "rules_engine") {
    if (pricingPlan.is_auto) {
      return null;
    }
  }

  let name = "Pricing Rules";
  if (hasModule && pricingPlan.is_auto) {
    name = "Pricing Rules (Debug)";
  }

  return (
    <Nav.Item>
      <Nav.Link eventKey="rules_engine">{name}</Nav.Link>
    </Nav.Item>
  );
};
