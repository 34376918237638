export const KEYWORD_MATCH_TYPE_OPTIONS = [
  { id: "broad", label: "Broad", value: "broad" },
  { id: "exact", label: "Exact", value: "exact" },
  { id: "phrase", label: "Phrase", value: "phrase" },
];

export const TARGET_MATCH_TYPE_OPTIONS = [
  { id: "auto", label: "Auto", value: "auto" },
  { id: "product", label: "Product", value: "product" },
  { id: "category", label: "Category", value: "category" },
  { id: "dynamic", label: "Dynamic", value: "dynamic" },
  {
    id: "expression",
    label: "Expression",
    value: "expression",
  },
];

export const DEFAULT_CONDITIONS = {
  pricing: {
    // eslint-disable-next-line no-template-curly-in-string
    option: JSON.stringify([">", "${units}"]),
    values: [10],
  },
  bidding: {
    // eslint-disable-next-line no-template-curly-in-string
    option: JSON.stringify([">", "${conversions}"]),
    values: [10],
    scope: {
      values: {days: "7"}
    }
  },
  budget: {
    // eslint-disable-next-line no-template-curly-in-string
    option: JSON.stringify(["<", "${acos}"]),
    // eslint-disable-next-line no-template-curly-in-string
    values: ["${max_acos}"],
  },
};

export const RULE_DEFAULTS = {
  // The conditions that get pushed when appending are different from the defaults for pricing
  // Verify which one is the source of truth
  pricing: {
    scope: {
      operator: "after",
      values: { days: "7" },
    },
    condition: "all",
    conditions: [DEFAULT_CONDITIONS["pricing"]],
    actions: [
      {
        function: "INCREASE_PRICE",
        operator: "adjust_fixed",
        values: [10],
      },
    ],
  },
  bidding: {
    scope: {
      values: {
        days: "7",
      },
      operator: "after",
    },
    actions: [
      {
        function: "INCREASE_BID",
        values: [0.15],
        operator: "adjust_fixed",
      },
    ],
    condition: "all",
    conditions: [DEFAULT_CONDITIONS["bidding"]],
  },
  budget: {
    scope: {
      values: {
        days: "7",
      },
      operator: "after",
    },
    actions: [
      {
        function: "INCREASE_BUDGET",
        values: [10],
        operator: "adjust_percent",
      },
    ],
    condition: "all",
    conditions: [DEFAULT_CONDITIONS["budget"]],
  },
};

export const PROGRAM_DEFAULTS = {
  pricing: {
    rules: [
      {
        scope: {
          operator: "before",
          values: [7],
        },
        condition: "all",
        conditions: [DEFAULT_CONDITIONS["pricing"]],
        actions: [
          {
            function: "INCREASE_PRICE",
            values: { how: "adjust_percent", amount: 10 },
          },
        ],
      },
    ],
  },
  bidding: {
    name: null,
    start_date: null,
    end_date: null,
    rules: [RULE_DEFAULTS["bidding"]],
    initial_action: {
      function: "SET_BID",
      values: [0.85],
      operator: "adjust_fixed",
    },
  },
  budget: {
    name: null,
    start_date: null,
    end_date: null,
    rules: [RULE_DEFAULTS["budget"]],
  },
};

// Make global set of options
export const OPERATOR_OPTIONS = {
  number: [
    { value: ">", label: "greater than" },
    { value: "<", label: "less than" },
    { value: ">=", label: "greater than or equal to" },
    { value: "<=", label: "less than or equal to" },
    { value: "=", label: "equal to" },
    { value: "between", label: "between" },
  ],
  select: [{ value: "=", label: "equal to" }],
  "multi-select": [{ value: "=", label: "equal to" }],
};

export const NON_METRIC_FIELD_ACCESSORS = [
  "target_match_types",
  "keyword_match_types",
];

export const RELATIVE_METRIC_FIELD_ACCESSORS = [
  {
    accessor: "acos",
    options: [
      // eslint-disable-next-line no-template-curly-in-string
      { value: "${target_acos}", label: "Target ACOS" },
      // eslint-disable-next-line no-template-curly-in-string
      { value: "${max_acos}", label: "Max ACOS" },
    ],
  },
  {
    accessor: "roas",
    options: [
      // eslint-disable-next-line no-template-curly-in-string
      { value: "${target_roas}", label: "Target ROAS" },
      // eslint-disable-next-line no-template-curly-in-string
      { value: "${min_roas}", label: "Min ROAS" },
    ],
  },
];
