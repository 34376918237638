import React from "react";
import { NavDropdown } from "react-bootstrap";

class NavFilterTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue:
        this.props.defaultValue === undefined
          ? Object.keys(this.props.data)[0]
          : this.props.defaultValue,
    };
  }

  componentDidUpdate() {
    if (
      this.props.defaultValue &&
      this.state.selectedValue !== this.props.defaultValue
    ) {
      this.setState({ selectedValue: this.props.defaultValue });
    }
  }

  onNavClick = (key, id) => {
    this.setState({ selectedValue: key });
    this.props.handleChildComponent(id);
    // TODO: Below closes mobile menu on org, channel, or marketplace change. This might not be needed but seems more intuitive on change.
    // Out of scope for this MR, but maybe a context for controlling navs would be better than toggling the class below? It's hard to guess that's what
    // triggers the change unless you know where to look
    this.props.mobileSidebarItem &&
      document.documentElement.classList.toggle("nav-open");
  };

  render() {
    var fontSize =
      this.props.fontSize === undefined ? "17px" : this.props.fontSize;

    return this.props.mobileSidebarItem ? (
      <div
        style={{
          margin: "1rem 1rem",
          boxShadow: "0 0 0 0 rgba(0,0,0,0.5)",
          fontSize: fontSize,
          zIndex: 3,
          position: "relative",
          display: true ? "contents !important" : "inherit",
        }}
      >
        {/* In mobile sidebar, only show options that can be selected to avoid overflow */}
        {Object.entries(this.props.data).map(
          ([key, value]) =>
            Object.values(value)[0] === false &&
            Object.keys(value)[0]?.toLowerCase() !==
              this.state.selectedValue?.toLowerCase() &&
            key?.toLowerCase() !== this.props.defaultValue?.toLowerCase() && (
              <NavDropdown.Item
                key={key}
                disabled={Object.values(value)[0]}
                onClick={(e) => this.onNavClick(key, Object.keys(value)[0])}
                id={key}
                className={
                  this.props.mobileSidebarItem ? "text-dark-purple" : ""
                }
                style={
                  value.style
                    ? { marginTop: "1rem", fontSize: "20px" }
                    : {
                        marginTop: "15px",
                        color: "#FFF",
                      }
                }
              >
                {value.icon && value.icon}
                {key}
              </NavDropdown.Item>
            )
        )}
      </div>
    ) : (
      <NavDropdown
        title={
          <>
            {this.props.mobileSidebarItem ? (
              <>
                {this.props.data[this.state.selectedValue]?.icon}
                {this.state.selectedValue}
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    margin: ".8rem",
                  }}
                >
                  (Current)
                </span>
              </>
            ) : (
              <>
                {this.props.data[this.state.selectedValue]?.icon}
                {this.state.selectedValue}
              </>
            )}
          </>
        }
        id="basic-nav-dropdown-1"
        className={`outline ${this.props.mobileSidebarItem ? "open" : ""}`}
        style={{
          margin: "10px 3px",
          boxShadow: "0 0 0 0 rgba(0,0,0,0.5)",
          fontSize: fontSize,
          zIndex: 3,
          position: "relative",
          padding: "10px 15px",
        }}
      >
        {Object.entries(this.props.data).map(([key, value]) => (
          <NavDropdown.Item
            key={key}
            // Why don't we just have a disabled attribute on values?
            disabled={Object.values(value)[0]}
            onClick={(e) => {
              this.onNavClick(key, Object.keys(value)[0]);
            }}
            id={key}
            style={
              value.style
                ? { marginTop: "1rem", fontSize: "20px", padding: ".5rem" }
                : { padding: ".5rem" }
            }
          >
            {value.icon && value.icon}
            {key}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  }
}

export default NavFilterTile;
