import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import {
  FormGroup,
  FormLabel as ControlLabel,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";

import Card from "components/core/basic/Card.jsx";
import Button from "components/core/basic/Button.jsx";
import getURLPrefix from "utils/getUrlPrefix";

let URL_PREFIX = getURLPrefix();

class Refer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      name: "",
      referral_email: "",
      referralList: [],
      referralToken: "",
      type_email: "",
      type_emailError: null,
      type_nameError: null,
      message: "",
      errors: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.user.organization_admin) {
      this.props.history.push("/");
    }
    this.refreshReferralList();
  }

  componentDidUpdate() {
    if (!this.props.user.organization_admin) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  refreshReferralList = () => {
    const AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/refer/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        this.setState({ referralList: res.data });
      });
    axios
      .get(URL_PREFIX + "/api/refermanage/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        this.setState({ referralToken: res.data.referral_token });
      });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.type_emailError != null ||
      this.state.type_nameError != null
    ) {
      return;
    }
    const AuthHeader = "Bearer ".concat(this.props.auth.tokens.access_token);
    var referUserData = {
      custom_api_request: "refer_user",
      referral_email: this.state.referral_email,
      company: this.state.company,
      name: this.state.name,
      message: this.state.message,
    };
    axios
      .post(URL_PREFIX + "/api/refer/", referUserData, {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((res) => {
        if (res.data === "User already invited") {
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "User already invited."
          );
        } else if (res.data === "User already Available") {
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "User already available."
          );
        } else if (res.data === "User already referred") {
          this.props.handleNotification(
            "tr",
            "warning",
            "pe-7s-attention",
            "User already referred."
          );
        } else {
          this.props.handleNotification(
            "tr",
            "success",
            "pe-7s-check",
            "Referral sent!"
          );
          this.setState({
            referral_email: "",
            company: "",
            name: "",
            message: "",
          });
          this.refreshReferralList();
        }
      });
  };

  handleReferralDelete = (referralItem) => {
    var delete_referral = {
      custom_api_request: "delete_referral",
      referral_email: referralItem.referral_email,
    };
    axios
      .post(URL_PREFIX + "/api/refer/", delete_referral)
      .then((res) => {
        this.refreshReferralList();
        this.props.handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Referral deleted!"
        );
      })
      .catch((err) => {
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to delete referral, please retry."
        );
      });
  };

  handleResendReferral = (referralItem) => {
    var resend_referral = {
      custom_api_request: "resend_referral",
      referral_email: referralItem.referral_email,
    };
    axios
      .post(URL_PREFIX + "/api/refer/", resend_referral)
      .then((res) => {
        this.refreshReferralList();
        this.props.handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Referral re-sent!"
        );
      })
      .catch((err) => {
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to resend referral email, please retry."
        );
      });
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col s8 offset-s2">
            <div
              className="col s12 text-center"
              style={{ paddingLeft: "11.250px" }}
            >
              <h4 className="fw-bold">Refer to the Trellis platform</h4>
            </div>
            <Col xs={10} className="mx-auto">
              <Form horizontal>
                <Card
                  title={
                    <legend className="text-center">
                      Referral Form {"\n"}
                      <hr />
                    </legend>
                  }
                  width={"100%"}
                  content={
                    <Row>
                      <Col xs={12} lg={8} className="mx-auto">
                        <FormGroup
                          controlId="formHorizontalEmail"
                          className="d-flex align-items-center my-4"
                        >
                          <Col
                            componentClass={ControlLabel}
                            xs={4}
                            md={2}
                            className="fs-5 text-end pe-5 text-uppercase"
                          >
                            Email
                          </Col>
                          <Col xs={8} md={10}>
                            <Form.Control
                              type="email"
                              name="referral_email"
                              value={this.state.referral_email}
                              onChange={(event) => {
                                this.setState({
                                  referral_email: event.target.value,
                                });
                                var emailRex =
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                emailRex.test(event.target.value) === false
                                  ? this.setState({
                                      type_emailError: (
                                        <small className="text-danger">
                                          Email is required and format should be{" "}
                                          <i>example@trellis.io</i>.
                                        </small>
                                      ),
                                    })
                                  : this.setState({ type_emailError: null });
                              }}
                            />
                            {this.state.type_emailError}
                          </Col>
                        </FormGroup>
                        <FormGroup className="d-flex align-items-center my-4">
                          <Col
                            componentClass={ControlLabel}
                            xs={4}
                            md={2}
                            className="fs-5 text-end pe-5 text-uppercase"
                          >
                            Name
                          </Col>
                          <Col xs={8} md={10}>
                            <Form.Control
                              type="text"
                              name="name"
                              value={this.state.name}
                              onChange={(event) => {
                                this.setState({ name: event.target.value });
                                var nameRex = /^[a-zA-Z\s]{1,128}$/i;
                                nameRex.test(event.target.value) === false
                                  ? this.setState({
                                      type_nameError: (
                                        <small className="text-danger">
                                          Name is required{" "}
                                        </small>
                                      ),
                                    })
                                  : this.setState({ type_nameError: null });
                              }}
                            />
                            {this.state.type_nameError}
                          </Col>
                        </FormGroup>
                        <FormGroup className="d-flex align-items-center my-4">
                          <Col
                            componentClass={ControlLabel}
                            xs={4}
                            md={2}
                            className="fs-5 text-end pe-5 text-uppercase"
                          >
                            Company
                          </Col>
                          <Col xs={8} md={10}>
                            <Form.Control
                              type="text"
                              name="company"
                              value={this.state.company}
                              onChange={(event) => {
                                this.setState({ company: event.target.value });
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="d-flex align-items-center my-4">
                          <Col
                            componentClass={ControlLabel}
                            xs={4}
                            md={2}
                            className="fs-5 text-end pe-5 text-uppercase"
                          >
                            Token
                          </Col>
                          <Col xs={8} md={10}>
                            <Form.Control
                              type="text"
                              name="token"
                              readOnly
                              value={this.state.referralToken}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="d-flex align-items-center my-4">
                          <Col
                            componentClass={ControlLabel}
                            xs={4}
                            md={2}
                            className="fs-5 text-end pe-5 text-uppercase"
                          >
                            Message
                          </Col>
                          <Col xs={8} md={10}>
                            <Form.Control
                              as="textarea"
                              rows="4"
                              placeholder=" Share your view of Trellis"
                              name="message"
                              style={{ borderRadius: 22, fontSize: "14px" }}
                              value={this.state.message}
                              onChange={(event) => {
                                this.setState({ message: event.target.value });
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                  ftTextCenter
                  legend={
                    <Button
                      bsStyle="info"
                      className="text-center"
                      fill
                      onClick={this.onSubmit}
                    >
                      Send Referral
                    </Button>
                  }
                />
              </Form>
            </Col>
          </div>
        </div>
        <div className="row">
          <Col xs={12} md={10} className="mx-auto">
            <Card
              tableFullWidth
              width={"100%"}
              content={
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Referred Email</th>
                      <th>Status</th>
                      <th>Resend Referral</th>
                      <th className="text-end">Delete Referral</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.referralList.map((referralItem, index) => {
                      if (!referralItem.activated) {
                        return (
                          <tr key={referralItem.id}>
                            <td className="text-center fs-standard">
                              {index + 1}
                            </td>
                            <td className="text-center fs-standard">
                              {referralItem.referral_email}
                            </td>
                            <td className="text-center fs-standard">Pending</td>
                            <td className="text-center fs-standard">
                              <button
                                onClick={() =>
                                  this.handleResendReferral(referralItem)
                                }
                                className="btn waves-effect waves-light hoverable blue accent-4"
                              >
                                <i className="fa fa-paper-plane" />
                              </button>
                            </td>
                            <td className="text-center fs-standard">
                              <button
                                onClick={() =>
                                  this.handleReferralDelete(referralItem)
                                }
                                className="btn waves-effect waves-light hoverable red accent-4"
                              >
                                <i className="fa fa-user-times" />
                              </button>
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={referralItem.id}>
                            <td className="text-center fs-standard">
                              {index + 1}
                            </td>
                            <td className="text-center fs-standard">
                              {referralItem.referral_email}
                            </td>
                            <td className="text-center fs-standard">
                              Accepted
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              }
            />
          </Col>
        </div>
      </div>
    );
  }
}

Refer.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(Refer));
