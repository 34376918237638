import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Switch from "react-bootstrap-switch";

const AssetCropper = ({
  assetDetails,
  updateCropDimensions,
  validations,
  cropType,
  setCropType,
  initialCrop,
}) => {
  const [crop, setCrop] = useState(initialCrop);
  const [zoom] = useState(1);
  const [croppedDimensions, setCroppedDimensions] = useState({});

  const { url_default: assetURL } = assetDetails ?? {};

  const assetNaturalWidth = assetDetails?.file_meta_data?.width;
  const assetNaturalHeight = assetDetails?.file_meta_data?.height;

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPercent) => {
      const convertedDimensions = {
        top: Math.round(
          assetDetails?.file_meta_data?.height * (croppedAreaPercent?.y / 100)
        ),
        left: Math.round(
          assetDetails?.file_meta_data?.width * (croppedAreaPercent?.x / 100)
        ),
        width: Math.round(
          assetDetails?.file_meta_data?.width *
            (croppedAreaPercent?.width / 100)
        ),
        height: Math.round(
          assetDetails?.file_meta_data?.height *
            (croppedAreaPercent?.height / 100)
        ),
      };
      setCroppedDimensions(convertedDimensions);
      updateCropDimensions(convertedDimensions, croppedAreaPercent);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateCropDimensions, assetNaturalHeight, assetNaturalWidth]
  );

  useEffect(() => {
    setCrop(initialCrop);
  }, [cropType, initialCrop]);

  return (
    <Row>
      <Col
        xs={12}
        lg={9}
        style={{
          position: "relative",
          background: "#FFF",
        }}
        className="mx-auto"
      >
        <p
          className="pt-3 text-center mx-auto"
          style={{
            fontSize: "16px",
            maxWidth: "90%",
            marginBottom: "2rem",
          }}
          translate="yes"
        >
          Amazon requires a square version of your selected image for mobile
          devices. Please use the cropping tool below to ensure that the best
          parts of your asset are being shown. You can also optionally adjust
          the cropping of the rectangle version of your asset.
        </p>
        <div className="d-flex justify-content-end">
          <span
            className="fs-6 pr-3 my-auto font-weight-bold"
            style={{ color: "#73706E" }}
          >
            Square
          </span>
          <Switch
            onText=""
            offText=""
            value={cropType === "rectangle"}
            onColor="#FFFFFF"
            onChange={(e) => {
              setCropType((prev) =>
                prev === "square" ? "rectangle" : "square"
              );
            }}
          />
          <span
            className="fs-6 pl-3 my-auto font-weight-bold"
            style={{ color: "#73706E" }}
          >
            Rectangle
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <ReactCrop
            style={{ maxHeight: "50vh" }}
            crop={crop}
            zoom={zoom}
            ruleOfThirds
            aspect={cropType === "square" ? 1 : null}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={onCropComplete}
          >
            <img
              src={assetURL}
              alt={"Preview of cropped asset."}
              crossorigin="anonymous"
            />
          </ReactCrop>
        </div>
        <Row className="controls pt-3">
          <Col>
            {(croppedDimensions?.height < validations?.minHeight ||
              croppedDimensions?.width < validations?.minWidth) && (
              <>
                <div className="py-4 text-center">
                  <small className="text-danger">
                    The cropped asset must have dimensions of at least{" "}
                    {validations?.minWidth}px x {validations?.minHeight}px.
                  </small>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AssetCropper;
