import { getFormatter, metrics } from "../metrics";

export const buildMultiCurrencyMetricCol = (key, compareKey, options = {}) => {
  const metric = metrics.find((m) => m.id === key);

  return {
    key,
    compareKey,
    options: {
      metric: {
        ...metric,
        accessor: (d) => d,
      },
      formatter: (v) => {
        const formatter = getFormatter(
          { marketPlace: v.market_place },
          metric.format,
          metric,
          options
        );
        return formatter(metric.accessor(v));
      },
      ...options,
    },
  };
};
