import React, { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { trellisPalette } from "components/custom/analytics/palettes";
import Loading from "components/core/blocks/Loading";
import { formatCurrency } from "utils/formatNumber";

const WeeklySeasonalityChart = ({
  data,
  isLoading,
  dailyBudget,
  marketPlace,
}) => {
  const options = useMemo(() => {
    return {
      chart: {
        type: "column",
        style: {
          "font-family": "Poppins",
          "font-weight": 700,
          "font-size": "15px",
        },
        height: 330,
      },
      title: {
        text: "Category Weekday Sales Trend",
      },

      yAxis: {
        startOnTick: false,
        title: {
          text: "Sales Trend",
        },
        labels: {
          enabled: false,
        },
        enableMouseTracking: false,
      },
      xAxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let points = this.points;
          let markup = "";

          for (let index = 0; index < points.length; index++) {
            let point = points[index];
            markup +=
              '<span style="color:' +
              point.series.color +
              '">\u25CF</span> ' +
              point.series.name +
              ": " +
              Math.round(point.y) +
              "%<br/>";
          }

          markup +=
            '<span style="color:' +
            points[1]?.series?.color +
            '">\u25CF</span> Daily Budget: ' +
            formatCurrency((points[1].y / 100) * dailyBudget, marketPlace) +
            "<br/>";

          return markup;
        },
      },
      series: [
        {
          name: "Weekday Parting",
          color: trellisPalette[1],
          data: data?.map((d) => d?.norm_weekly_season),
        },
        {
          name: "Weekday Budget Modifier",
          color: trellisPalette[5],
          data: data?.map((d) => d.multiplier),
          type: "area",
          fillOpacity: 0.1,
        },
      ],
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default WeeklySeasonalityChart;
