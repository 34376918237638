import React, { useState, useEffect, useCallback } from "react";
import { connect, useStore } from "react-redux";
import PropTypes from "prop-types";
import api from "utils/api";

import { PermissionsFlag } from "components/core/basic/Flag";
import NavFilterTile from "components/core/blocks/NavFilterTile";
import { useHistory } from "react-router-dom";
import {
  handleDefaultMarketplace,
  handleProfileChangeRedirects,
} from "utils/handleDefaultMarketplace";
import { apiGet } from "hooks/api";

const groupByKey = (list, key) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );

const SellerAccount = ({ channel, auth, user, mobileSidebarItem }) => {
  const [orgs, setOrgs] = useState([]);
  const [formattedOrgs, setFormattedOrgs] = useState({});
  const history = useHistory();
  const store = useStore();

  const setOrg = useCallback(
    async (id) => {
      // Update organization user profile is associated with
      await api.put("/api/organizations/", {
        id: id,
      });

      await handleDefaultMarketplace();
      handleProfileChangeRedirects(history);
      window.location.reload();
    },
    [history]
  );

  useEffect(() => {
    async function getOrgs() {
      const response = await apiGet(
        "/api/organizations/",
        {
          org_type: channel.currentChannel,
        },
        store
      );
      // What do we want to do on errors?
      if (response.status === 200) {
        setOrgs(response.data);
        // NavFilterTiles are odd, we can't just pass components into them,
        // they expect a very specific layout of data.
        const profiles = {};
        const orgIds = [];
        const profileNames = {};
        response.data.forEach((org) => {
          if (profileNames[org.profile_name] === undefined) {
            profileNames[org.profile_name] = 1;
          } else {
            profileNames[org.profile_name] += 1;
          }
        });
        const grouped = groupByKey(response.data, "profile_type");

        for (let profile_type in grouped) {
          let group_label = () => {
            switch (profile_type) {
              case "vendor":
                return "Vendors";
              case "seller":
                return "Sellers";
              default:
                return "Other";
            }
          };

          profiles[group_label()] = { header: true, style: true };
          for (let org of grouped[profile_type]) {
            let profile_name = `${org.profile_name}`;
            if (profileNames[profile_name] > 1) {
              profile_name = `${profile_name} (${profile_type})`;
            }

            profiles[profile_name] = {
              // False means it's NOT disabled.
              [org.id]: false,
            };
            orgIds.push(org.id);
          }
        }

        if (
          orgIds.indexOf(parseInt(user.organization_id)) === -1 &&
          orgIds.length > 0
        ) {
          setOrg(orgIds[0]);
        }

        setFormattedOrgs(profiles);
      }
    }
    getOrgs();
  }, [channel, user.organization_id, setOrg, store]);

  const handleClick = useCallback(
    (id) => {
      const num_id = parseInt(id);

      for (const org of orgs) {
        if (org.id === num_id) {
          setOrg(org.id);
          break;
        }
      }
    },
    [orgs, setOrg]
  );

  return (
    <PermissionsFlag role="admin" roles={user.permissions}>
      <NavFilterTile
        defaultValue={user.current_profile}
        data={formattedOrgs}
        fontSize={"14px"}
        mobileSidebarItem={mobileSidebarItem}
        handleChildComponent={handleClick}
      />
    </PermissionsFlag>
  );
};

SellerAccount.propTypes = {
  channel: PropTypes.shape({
    currentChannel: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    current_profile: PropTypes.string.isRequired,
    permissions: PropTypes.string.isRequired,
  }),
  auth: PropTypes.object.isRequired,
  mobileSidebarItem: PropTypes.bool.isRequired,
};

SellerAccount.defaultProps = {
  mobileSidebarItem: false,
};

const mapStateToProps = (state) => ({
  channel: state.channel,
  auth: state.auth,
  user: state.user,
});

export default connect(mapStateToProps)(SellerAccount);
