import { useContext, useMemo } from "react";
import { NotificationContext } from "./NotificationContext";

export const useNotifications = (categoryId) => {
  const { notifications } = useContext(NotificationContext);
  let filteredNotifications = useMemo(() => {
    return categoryId !== "all"
      ? notifications?.filter(
          (n) => n.category_id?.toString() === categoryId?.toString()
        )
      : notifications;
  }, [notifications, categoryId]);

  return [filteredNotifications, categoryId];
};
