import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import TimelineView from "./TimelineView";
import { AuthButton } from "components/core/basic/Button";
import { Grid, withStyles } from "@material-ui/core";
import Switch from "react-bootstrap-switch";
// import Legend from "./Legend";
import { trellisPalette } from "./palettes";
import useOnScreen from "hooks/useOnScreen";
import compareBrands from "utils/compareBrands";
import FilterBar from "./FilterBar";

const MAX_BRANDS = 6;

const useStyles = (theme) => ({
  switchContainer: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "0 1rem",
    margin: "-1rem 2rem 1rem 0",
  },
  switchLabel: {
    color: "#797979",
    marginLeft: "2rem",
    textTransform: "uppercase",
    fontSize: "14px",
  },
  shareButtonContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      margin: "auto",
      justifyContent: "space-around",
    },
  },
  shareButton: {
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      margin: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      margin: "1rem",
    },
  },
});

const ShareView = ({
  classes,
  shelfStatsData,
  homeBrand,
  selectedShelf,
  shelfData,
  selectedSearchId,
  filterSearchId,
  selectedRollupType,
  filterRollupType,
  setSelectedDateRange,
  dateRange,
  isLoading,
  shelfStatsTotalData,
}) => {
  const [showShareView, setShowShareView] = useState(true);
  const [share, setShare] = useState("total");
  const [shareData, setShareData] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const containerRef = useRef();
  const isVisible = useOnScreen(containerRef);

  const echartRef = useRef();

  useEffect(() => {
    if (showShareView) {
      const processedShareData = processShareViewData();
      setShareData(processedShareData);
    } else {
      const processedShareData = processShareViewData();
      setShareData(processedShareData);
      setTimelineData(shelfStatsData);
    }

    const echartInstance = echartRef.current?.getEchartsInstance();

    if (echartInstance && isVisible) {
      echartInstance.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        name: homeBrand,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showShareView, shelfStatsData, shelfStatsTotalData, share, isVisible]);

  const processShareViewData = () => {
    const tempData = shelfStatsTotalData;

    const key = {
      total: "share_of_shelf",
      organic: "org_share_of_shelf",
      sponsored: "share_of_voice",
    }[share];

    // Retrieve share of shelf and brand name from dataset
    let sosData = tempData.map((brandData) => ({
      value: brandData[key]?.toFixed(2),
      name: brandData.brand,
    }));

    // Remove all data not needed for chart display, sort
    sosData = sosData.filter((element) => element.value > 0);
    sosData = sosData.sort((a, b) => {
      return b.value - a.value;
    });

    // Reduce to max of 10 brands, group overflow into 10th element
    if (sosData.length > MAX_BRANDS) {
      sosData = reduceShareOverflow(sosData, MAX_BRANDS);
    }

    return sosData;
  };

  // Ensure that chart data is broken into 10 categories for display - combine overflow with lowest values into 'Other' share
  const reduceShareOverflow = (data, max = 10) => {
    let otherBrandData = data.splice(max - 1);

    if (otherBrandData.find((data) => compareBrands(data.name, homeBrand))) {
      let homeBrandData = otherBrandData.find((data) =>
        compareBrands(data.name, homeBrand)
      );

      // Swap homebrand for lowest value in top 9 shareholders to ensure home brand is always visible in chart
      otherBrandData.push(data.pop());
      otherBrandData = otherBrandData.filter(
        (otherBrand) => compareBrands(otherBrand.name, homeBrand) === false
      );
      data.push(homeBrandData);
    }

    let otherBrandShare = otherBrandData.reduce(
      (otherShare, brand) => otherShare + Number.parseFloat(brand.value),
      0
    );

    data.push({ value: otherBrandShare.toFixed(2).toString(), name: "Other" });

    return data;
  };

  const chartOptions = {
    tooltip: {
      trigger: "item",
      formatter: "{b} <br/> {c}%",
      backgroundColor: "#222",
    },
    series: [
      {
        type: "pie",
        radius: "80%",
        center: ["50%", "55%"],
        selectedMode: "single",
        data: shareData,
        label: {
          position: "outer",
          fontSize: 17,
          fontFamily: "Poppins",
          formatter: "{b} \n {c}%",
          color: "#333",
          fontWeight: "400",
          margin: [20],
          textAlign: "center",
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    color: trellisPalette,
  };

  const selectBrand = (params) => {
    if (params.seriesType === "pie") {
      setSelectedBrand(params.data.name);
    }

    if (params.seriesType === "line") {
      setSelectedBrand(params.seriesName);
    }
  };

  // useCallback present to avoid rerender every time a new brand is selected
  // Without it, no animation that separates selected portion from rest of chart unless clicked a second time, post re-render)
  const registerChartEvents = {
    click: useCallback((params) => {
      selectBrand(params);
    }, []),
  };

  return (
    <Grid style={{ marginTop: "-3rem" }}>
      <div ref={containerRef}>
        <FilterBar
          shelfData={shelfData}
          filterSearchId={filterSearchId}
          selectedShelf={selectedShelf}
          selectedSearchId={selectedSearchId}
          selectedRollupType={selectedRollupType}
          filterRollupType={filterRollupType}
          setSelectedDateRange={setSelectedDateRange}
          dateRange={dateRange}
          isLoading={isLoading}
        />
        <Grid
          container
          item
          xs={12}
          className={classes.shareButtonContainer}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AuthButton
            title="Share of Shelf"
            className={`${classes.shareButton} ${
              share === "total" ? "buttonActive" : "button"
            }`}
            onClick={() => setShare("total")}
          />
          <AuthButton
            title="Share of Shelf (Organic)"
            className={`${classes.shareButton} ${
              share === "organic" ? "buttonActive" : "button"
            }`}
            onClick={() => setShare("organic")}
          />
          <AuthButton
            title="Share of Voice (Sponsored)"
            className={`${classes.shareButton} ${
              share === "sponsored" ? "buttonActive" : "button"
            }`}
            onClick={() => setShare("sponsored")}
          />
        </Grid>
        <Grid
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "1rem",
            height: "580px",
          }}
        >
          <div>
            {isVisible &&
              (showShareView ? (
                <ReactEcharts
                  option={chartOptions}
                  onEvents={registerChartEvents}
                  style={{
                    height: "530px",
                  }}
                  ref={echartRef}
                />
              ) : (
                <TimelineView
                  homeBrand={homeBrand}
                  selectBrand={selectBrand}
                  timelineData={timelineData}
                  shelfStatsData={shelfStatsData}
                  shelfStatsTotalData={shelfStatsTotalData}
                  share={share}
                  reduceShareOverflow={reduceShareOverflow}
                  showShareView={showShareView}
                  selectedShelf={selectedShelf}
                  setTimelineData={setTimelineData}
                />
              ))}
          </div>
          {/* <div>
          <Legend
            selectedBrand={selectedBrand}
            shareData={shareData}
            timelineData={timelineData}
            showSov={showSov}
          />
        </div> */}
        </Grid>
        <Grid item xs={12} className={classes.switchContainer}>
          <Switch
            onText=""
            offText=""
            value={!showShareView}
            onColor="#FFFFFF"
            onChange={() => setShowShareView(!showShareView)}
          />
          <span className={classes.switchLabel}>
            {!showShareView ? "Show Share View" : "Show Timeline View"}
          </span>
        </Grid>
      </div>
    </Grid>
  );
};

ShareView.propTypes = {
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(ShareView))
);
