import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { LogoutButton } from "components/core/basic/Button.jsx";
import FormGroupTile from "components/core/basic/FormGroupTile";

const AddCompetitor = ({
  productId,
  createCompetitor,
  setShowAddCompetitor,
  showAddCompetitor,
}) => {
  const [url, setUrl] = useState("");
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    setUrl("");
    setAdding(false);
  }, [showAddCompetitor]);

  const addCompetitorClick = async () => {
    createCompetitor(productId, url);
    setAdding(true);
  };

  const hideModal = () => {
    setShowAddCompetitor(false);
  };

  return (
    <Modal show={showAddCompetitor} onHide={() => hideModal()} size="xm">
      <Modal.Header closeButton={!adding} className="py-0 px-5">
        <div className="fs-3 my-4 text-center">Add a new competitor</div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div className="fs-4 pb-4 text-center">
              Enter the Amazon URL of the product you want to add as a
              competitor
            </div>
            <div className="fs-4 pb-4 text-left">
              <FormGroupTile
                type="formcontrol"
                prefixSide={"left"}
                formControlType={"text"}
                handleChildFormElement={(key, value) => {
                  setUrl(value);
                }}
                formControlClass="text-start"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setAdding(true);
                    addCompetitorClick();
                  }
                }}
                placeholder={`https://www.amazon.com/dp/COMPETITOR-ASIN`}
                defaultValue={url}
              />
            </div>

            {adding && (
              <div className="fs-4 py-4 text-center">
                The competitive product is being added. This may take a few
                seconds.
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="py-4" style={{ width: "100%" }}>
          <Col xs={6} className="mx-auto text-start">
            <LogoutButton
              key="RefreshBtn"
              title={`Close`}
              onClick={() => hideModal()}
              disabled={adding}
            />
          </Col>

          <Col xs={6} className="mx-auto text-end">
            <LogoutButton
              fontSize="12px"
              key="AddBtn"
              title={`Add`}
              onClick={() => {
                setAdding(true);
                addCompetitorClick();
              }}
              disabled={adding}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCompetitor;
