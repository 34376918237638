import bottomArrows from "assets/images/background/bottom_arrows.png";
import bgImage from "assets/images/background/purple_gradient.png";
import trellislogo from "assets/images/logo/Trellis_Logo_white.png";
import status from "assets/images/onboarding/amazon_onboarding_timeline-03_spapi.jpg";
import success_tick from "assets/images/status/successful_tick_150x150.png";
import caution from "assets/images/status/caution.svg";
import logo from "assets/images/logo/Trellis_Logomark_color.png";
import axios from "axios";
import { AuthButton } from "components/core/basic/Button";
import CustomHref from "components/core/blocks/CustomHref";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Hidden, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setMarketPlace } from "redux/actions/marketPlace";
import { setChannel } from "redux/actions/channel";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import getURLPrefix, {
  CONST_SPAPI_URI_EXTERNAL_REGION_DECODE,
  getAmazonSPAPIClientURL,
  getAmazonAdvClientURL,
} from "utils/getUrlPrefix";
import ModalAlert from "components/core/basic/ModalAlert";
import { REGION_MARKETPLACE_LIST_MAP } from "utils/marketplaceConstants";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import AmazonProfileSummary from "./AmazonProfileSummary";
import ConnectSecondaryChannelWidget from "views/onboarding/components/ConnectSecondaryChannelWidget";

let URL_PREFIX = getURLPrefix();

const useStyles = (theme) => ({
  mobileButtonContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      margin: "8rem 0",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      textAlign: "end",
      margin: "5rem 10rem 5rem 0",
    },
  },
  overflowHidden: {
    maxHeight: "100vh",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      overflowX: "hidden",
      overflowY: "auto",
    },
  },
});

class AmazonSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advertisingAccount: "",
      advertisingMarketplaces: "",
      sellerAccountRegion: "",
      sellerAccountRegionList: [],
      sellerAccountMarketplaceList: [],
      sellerMarketplaces: "",
      mwsAccount: "",
      mwsSellerID: "",
      mwsAuthorizationToken: "",
      euMwsSellerID: "",
      euMwsAuthorizationToken: "",
      feMwsSellerID: "",
      feMwsAuthorizationToken: "",
      finishButtonText: "Loading",
      userLayout: false,
      currentMarketplace: "",
      goToDashboardClicked: false,
      user: this.props.user,
      spapi_connecting_status: "",
      eu_spapi_connecting_status: "",
      fe_spapi_connecting_status: "",
      is_seller: true,
      walmartProfileExists: false,
    };
    this.updateSpApiStatus = this.updateSpApiStatus.bind(this);
  }

  componentDidMount() {
    if (window.location.href.includes("user")) {
      this.setState({ userLayout: true });
    }

    this.getAmazonOnboardData();
    this.fetchWalmartProfiles();

    refreshAmzOnboardStatus(
      this.props.updateAmzOnboardingStatus,
      this.props.auth.tokens.access_token,
      this.props.setErrors
    );

    if (window.location.href.includes("edit_sp_api=true")) {
      if (
        this.props.history.location.state &&
        "spapi_oauth_code" in this.props.history.location.state
      ) {
        this.setState({ mwsOrSummary: "spapi" });

        let spapi_req_state = this.props.history.location.state;
        const spapi_oauth_code = spapi_req_state["spapi_oauth_code"];
        const selling_partner_id = spapi_req_state["selling_partner_id"];
        const region_encoded = spapi_req_state["region_encoded"];
        const region = CONST_SPAPI_URI_EXTERNAL_REGION_DECODE[region_encoded];
        this.updateSpApiStatus(region, "connecting");
        this.connectSpApi(spapi_oauth_code, selling_partner_id, region);
        this.props.history.replace({ state: {} });
      } else {
        this.props.history.replace({ pathname: "/user/amzsummary", state: {} });
      }
    }
  }

  componentDidUpdate() {
    if (window.location.href.includes("user") && !this.state.userLayout) {
      this.setState({ userLayout: true });
    }

    if (!this.state.userLayout && this.state.goToDashboardClicked) {
      refreshAmzOnboardStatus(
        this.props.updateAmzOnboardingStatus,
        this.props.auth.tokens.access_token,
        this.props.setErrors
      );
    }
  }

  updateSpApiStatus(region, status, selling_partner_id = null) {
    switch (region) {
      case "NA":
        let sellerId = selling_partner_id
          ? selling_partner_id
          : this.state.mwsSellerID;
        this.setState({
          spapi_connecting_status: status,
          mwsSellerID: sellerId,
        });
        break;
      case "EU":
        let euSellerId = selling_partner_id
          ? selling_partner_id
          : this.state.euMwsSellerID;
        this.setState({
          eu_spapi_connecting_status: status,
          euMwsSellerID: euSellerId,
        });
        break;
      case "FE":
        let feSellerId = selling_partner_id
          ? selling_partner_id
          : this.state.feMwsSellerID;
        this.setState({
          fe_spapi_connecting_status: status,
          feMwsSellerID: feSellerId,
        });
        break;
      default:
        return;
    }
  }

  connectSpApi(spapi_oauth_code, selling_partner_id, region) {
    const dispatchSetError = (p) => this.props.setErrors(p);

    var AuthHeader = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .post(
        URL_PREFIX + "/api/amazonspapi/",
        {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          region: region,
        },
        { params: {}, headers: { Authorization: AuthHeader } }
      )
      .then((res) => {
        if (res.status === 200) {
          this.updateSpApiStatus(region, "connect_success", selling_partner_id);
          refreshAmzOnboardStatus(
            this.props.updateAmzOnboardingStatus,
            this.props.auth.tokens.access_token,
            this.props.setErrors
          );
        }
      })
      .catch((err) => {
        this.updateSpApiStatus(region, "connect_failed");
        dispatchSetError({
          title: "Amazon SP API Connect Failed",
          message: "Failed to get Amazon SP API credentials. Please try again.",
        });
      });
  }

  getAmazonOnboardData() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    axios
      .get(URL_PREFIX + "/api/onboardingsummary/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.data) {
          this.advertisingMarketplaces(
            res.data.amazon_profile_ids,
            res.data.amazon_seller_marketplaces
          );
          this.advertisingRegions(res.data.account_region);
          let is_seller = this.state.is_seller;
          if (
            res.data.amazon_profile_ids &&
            res.data.amazon_seller_marketplaces
          ) {
            let amazon_profile_ids = JSON.parse(res.data.amazon_profile_ids);
            let amazon_seller_marketplaces =
              res.data.amazon_seller_marketplaces;

            let marketPlaceKey = amazon_seller_marketplaces[0];
            if (marketPlaceKey === "USA") {
              marketPlaceKey = "US";
            }
            if (
              amazon_seller_marketplaces.length > 0 &&
              marketPlaceKey in amazon_profile_ids
            ) {
              is_seller =
                amazon_profile_ids[marketPlaceKey]["type"] === "seller";
            }
          }
          this.setState({
            sellerAccountRegionList: res.data.account_region,
            sellerAccountMarketplaceList: res.data.amazon_seller_marketplaces,
            mwsSellerID: res.data.amazon_mws_seller_id,
            mwsAuthorizationToken: res.data.amazon_mws_authorization_token,
            euMwsSellerID: res.data.eu_amazon_mws_seller_id,
            euMwsAuthorizationToken: res.data.eu_amazon_mws_authorization_token,
            feMwsSellerID: res.data.fe_amazon_mws_seller_id,
            feMwsAuthorizationToken: res.data.fe_amazon_mws_authorization_token,
            advertisingAccount: "connected",
            mwsAccount: "connected",
            sellerAccountAmazonProfileIds: res.data.amazon_profile_ids,
            is_seller: is_seller,
          });
        }
      });
  }

  getFirstSellerSubcribedMarketplaceInRegion(region) {
    if (REGION_MARKETPLACE_LIST_MAP[region].includes(this.props.marketPlace)) {
      return this.props.marketPlace;
    }
    switch (region) {
      case "NA":
        if (this.state.sellerAccountMarketplaceList.includes("USA"))
          return "US";
        else if (this.state.sellerAccountMarketplaceList.includes("CA"))
          return "CA";
        else if (this.state.sellerAccountMarketplaceList.includes("MX"))
          return "MX";
        else if (this.state.sellerAccountMarketplaceList.includes("BR"))
          return "BR";
        break;
      case "EU":
        if (this.state.sellerAccountMarketplaceList.includes("UK")) return "UK";
        else if (this.state.sellerAccountMarketplaceList.includes("ES"))
          return "ES";
        else if (this.state.sellerAccountMarketplaceList.includes("GB"))
          return "GB";
        else if (this.state.sellerAccountMarketplaceList.includes("FR"))
          return "FR";
        else if (this.state.sellerAccountMarketplaceList.includes("NL"))
          return "NL";
        else if (this.state.sellerAccountMarketplaceList.includes("SE"))
          return "SE";
        else if (this.state.sellerAccountMarketplaceList.includes("DE"))
          return "DE";
        else if (this.state.sellerAccountMarketplaceList.includes("IT"))
          return "IT";
        else if (this.state.sellerAccountMarketplaceList.includes("SE"))
          return "SE";
        else if (this.state.sellerAccountMarketplaceList.includes("PL"))
          return "PL";
        else if (this.state.sellerAccountMarketplaceList.includes("BE"))
          return "BE";
        else if (this.state.sellerAccountMarketplaceList.includes("EG"))
          return "EG";
        else if (this.state.sellerAccountMarketplaceList.includes("TR"))
          return "TR";
        else if (this.state.sellerAccountMarketplaceList.includes("SA"))
          return "SA";
        else if (this.state.sellerAccountMarketplaceList.includes("AE"))
          return "AE";
        else if (this.state.sellerAccountMarketplaceList.includes("IN"))
          return "IN";
        break;
      case "FE":
        if (this.state.sellerAccountMarketplaceList.includes("AU")) return "AU";
        else if (this.state.sellerAccountMarketplaceList.includes("SG"))
          return "SG";
        else if (this.state.sellerAccountMarketplaceList.includes("JP"))
          return "JP";
        break;
      default:
        return null;
    }
  }

  editAmazonMwsAccess(region) {
    localStorage.setItem("EditAmzMws", "true");
    localStorage.setItem("EditAmzMwsRegion", region);

    localStorage.removeItem("MWSCancelEdit");
    localStorage.removeItem("MWSInfoSaved");
    localStorage.removeItem("AdvCancelEdit");
    localStorage.removeItem("AdvInfoSaved");

    // Skip middle onboarding screen and go directly to Amazon MWS summary if already completed onboard
    if (this.state.userLayout) {
      this.props.history.push("/user/amzmwsconnect");
    }

    this.props.onMwsEdit();
  }

  editAmazonSpApiAccess(region) {
    let use_marketplace =
      this.getFirstSellerSubcribedMarketplaceInRegion(region);
    let is_seller =
      JSON.parse(this.state.sellerAccountAmazonProfileIds)[use_marketplace][
        "type"
      ] === "seller";

    let uri = getAmazonSPAPIClientURL(
      is_seller,
      use_marketplace,
      region,
      "summary"
    );
    window.location = uri;
  }

  fetchStuff() {
    this.setState({ goToDashboardClicked: true });
    if (this.state.userLayout) {
      this.props.history.push("/user/dashboard");
    } else {
      let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

      axios
        .post(
          URL_PREFIX + "/api/mwsinfotask/",
          {},
          {
            params: {},
            headers: { Authorization: AuthStr },
          }
        )
        .then((res) => {
          if (
            res.data === "Processing SKU information and updated status" ||
            res.data === "No info to process"
          ) {
            refreshAmzOnboardStatus(
              this.props.updateAmzOnboardingStatus,
              this.props.auth.tokens.access_token,
              this.props.setErrors
            );
          }
        })
        .catch((err) => {});
    }

    localStorage.removeItem("MWSCancelEdit");
    localStorage.removeItem("MWSInfoSaved");
    localStorage.removeItem("AdvCancelEdit");
    localStorage.removeItem("AdvInfoSaved");
    localStorage.removeItem("EditAmzMwsRegion");
  }

  fetchWalmartProfiles() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/accounts/", {
        params: {
          channel: "walmart",
        },
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        // See if at least one Walmart profile found
        let walmartProfileExists =
          Object.keys(res.data.seller)?.length ||
          Object.keys(res.data.vendor)?.length
            ? true
            : false;

        this.setState({
          walmartProfileExists: walmartProfileExists,
        });
      });
  }

  advertisingMarketplaces(advertisingMarketplaces, trellisMarketplaces) {
    if (trellisMarketplaces) {
      this.setState({
        currentMarketplace: trellisMarketplaces[0],
      });
      let trellisMarketplaceNamesArray = [];
      if (
        trellisMarketplaces.includes("USA") ||
        trellisMarketplaces.includes("US")
      ) {
        trellisMarketplaceNamesArray.push("United States");
      }
      if (trellisMarketplaces.includes("CA")) {
        trellisMarketplaceNamesArray.push("Canada");
      }
      if (trellisMarketplaces.includes("MX")) {
        trellisMarketplaceNamesArray.push("Mexico");
      }
      if (trellisMarketplaces.includes("UK")) {
        trellisMarketplaceNamesArray.push("United Kingdom");
      }
      if (trellisMarketplaces.includes("NL")) {
        trellisMarketplaceNamesArray.push("Netherlands");
      }
      if (trellisMarketplaces.includes("ES")) {
        trellisMarketplaceNamesArray.push("Spain");
      }
      if (trellisMarketplaces.includes("SE")) {
        trellisMarketplaceNamesArray.push("Sweden");
      }
      if (trellisMarketplaces.includes("BR")) {
        trellisMarketplaceNamesArray.push("Brazil");
      }
      if (trellisMarketplaces.includes("PL")) {
        trellisMarketplaceNamesArray.push("Poland");
      }
      if (trellisMarketplaces.includes("BE")) {
        trellisMarketplaceNamesArray.push("Belgium");
      }
      if (trellisMarketplaces.includes("DE")) {
        trellisMarketplaceNamesArray.push("Germany");
      }
      if (trellisMarketplaces.includes("IT")) {
        trellisMarketplaceNamesArray.push("Italy");
      }
      if (trellisMarketplaces.includes("FR")) {
        trellisMarketplaceNamesArray.push("France");
      }
      if (trellisMarketplaces.includes("IN")) {
        trellisMarketplaceNamesArray.push("India");
      }
      if (trellisMarketplaces.includes("AE")) {
        trellisMarketplaceNamesArray.push("UAE");
      }
      if (trellisMarketplaces.includes("SA")) {
        trellisMarketplaceNamesArray.push("SA");
      }
      if (trellisMarketplaces.includes("AU")) {
        trellisMarketplaceNamesArray.push("Australia");
      }
      if (trellisMarketplaces.includes("JP")) {
        trellisMarketplaceNamesArray.push("Japan");
      }
      if (trellisMarketplaces.includes("SG")) {
        trellisMarketplaceNamesArray.push("Singapore");
      }

      this.setState({
        sellerMarketplaces: trellisMarketplaceNamesArray.join(", "),
      });
    }
    if (advertisingMarketplaces) {
      let advertisingMarketplaceNamesArray = [];

      if (
        advertisingMarketplaces.includes("US") ||
        advertisingMarketplaces.includes("USA")
      ) {
        advertisingMarketplaceNamesArray.push("United States");
      }
      if (advertisingMarketplaces.includes("CA")) {
        advertisingMarketplaceNamesArray.push("Canada");
      }
      if (advertisingMarketplaces.includes("MX")) {
        advertisingMarketplaceNamesArray.push("Mexico");
      }
      if (advertisingMarketplaces.includes("BR")) {
        advertisingMarketplaceNamesArray.push("Brazil");
      }
      if (advertisingMarketplaces.includes("UK")) {
        advertisingMarketplaceNamesArray.push("United Kingdom");
      }
      if (advertisingMarketplaces.includes("DE")) {
        advertisingMarketplaceNamesArray.push("Germany");
      }
      if (advertisingMarketplaces.includes("NL")) {
        advertisingMarketplaceNamesArray.push("Netherlands");
      }
      if (advertisingMarketplaces.includes("ES")) {
        advertisingMarketplaceNamesArray.push("Spain");
      }
      if (advertisingMarketplaces.includes("SE")) {
        advertisingMarketplaceNamesArray.push("Sweden");
      }
      if (advertisingMarketplaces.includes("PL")) {
        advertisingMarketplaceNamesArray.push("Poland");
      }
      if (advertisingMarketplaces.includes("BE")) {
        advertisingMarketplaceNamesArray.push("Belgium");
      }
      if (advertisingMarketplaces.includes("IT")) {
        advertisingMarketplaceNamesArray.push("Italy");
      }
      if (advertisingMarketplaces.includes("FR")) {
        advertisingMarketplaceNamesArray.push("France");
      }
      if (advertisingMarketplaces.includes("IN")) {
        advertisingMarketplaceNamesArray.push("India");
      }
      if (advertisingMarketplaces.includes("AE")) {
        advertisingMarketplaceNamesArray.push("UAE");
      }
      if (advertisingMarketplaces.includes("SA")) {
        advertisingMarketplaceNamesArray.push("Saudi Arabia");
      }
      if (advertisingMarketplaces.includes("AU")) {
        advertisingMarketplaceNamesArray.push("Australia");
      }
      if (advertisingMarketplaces.includes("JP")) {
        advertisingMarketplaceNamesArray.push("Japan");
      }
      if (advertisingMarketplaces.includes("SG")) {
        advertisingMarketplaceNamesArray.push("Singapore");
      }

      this.setState({
        advertisingMarketplaces: advertisingMarketplaceNamesArray.join(", "),
      });
    }
  }

  advertisingRegions(regions) {
    let advertisingRegionNamesArray = [];
    if (regions.includes("NA")) {
      advertisingRegionNamesArray.push("North America");
    }
    if (regions.includes("EU")) {
      advertisingRegionNamesArray.push("Europe");
    }
    if (regions.includes("FE")) {
      advertisingRegionNamesArray.push("Far East");
    }

    this.setState({
      sellerAccountRegion: advertisingRegionNamesArray.join(", "),
    });
  }

  refreshAssets = () => {
    axios
      .post(URL_PREFIX + "/api/refreshassets/", {})
      .then((res) => {
        this.props.handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Refreshing your Amazon assets."
        );
      })
      .catch((err) =>
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to refresh Amazon assets."
        )
      );
    this.props.history.push("/user/dashboard");
  };

  refreshListings(region) {
    axios
      .post(URL_PREFIX + "/api/refreshlisting/", {
        custom_api_request: "refresh_product_listing",
        region: region,
      })
      .then((res) => {
        this.props.handleNotification(
          "tr",
          "success",
          "pe-7s-check",
          "Refreshing your Amazon product listings."
        );
      })
      .catch((err) =>
        this.props.handleNotification(
          "tr",
          "warning",
          "pe-7s-attention",
          "Failed to refresh Amazon product listings."
        )
      );
    this.props.history.push("/user/dashboard");
  }

  handleRedirectToDashboard() {
    this.setOnboardingComplete((success) => {
      if (success) {
        // Update onboard redirect link once complete upload to dashboard
        localStorage.setItem(
          "onboardRedirectTo",
          JSON.stringify("/user/dashboard")
        );
        this.props.history.push("/user/dashboard");
      } else {
        this.setState({
          errorMessage: "Failed to reach dashboard, please contact Trellis.",
        });
      }
    });
  }

  setOnboardingComplete(cb) {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    axios
      .post(
        URL_PREFIX + "/api/mwsinfotask/",
        {},
        {
          params: {},
          headers: { Authorization: AuthStr },
        }
      )
      .then((res) => {
        if (
          res.data === "Processing SKU information and updated status" ||
          res.data === "No info to process"
        ) {
          refreshAmzOnboardStatus(
            this.props.updateAmzOnboardingStatus,
            this.props.auth.tokens.access_token,
            this.props.setErrors
          );
          if (cb) {
            cb(true);
          }
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false);
        }
      });
  }

  render() {
    const { classes, channel } = this.props;

    if (channel.currentChannel === "walmart") {
      this.props.history.push("/user/walmartsummary");
    }

    return (
      <>
        <Grid container style={{ marginLeft: "0px", marginRight: "0px" }}>
          <Grid
            item
            xs={12}
            md={this.state.userLayout ? 12 : 10}
            style={{
              backgroundColor: "#ffffff",
              minHeight: "100vh",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
            className={classes.overflowHidden}
          >
            <Grid
              container
              item
              xs={12}
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={10}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "#393939",
                          fontSize: "34px",
                          fontWeight: 700,
                          marginTop: "5rem",
                        }}
                      >
                        {localStorage.channel === "amazon"
                          ? "Amazon "
                          : "Walmart "}
                        Onboarding
                      </div>
                    </Grid>
                  </Grid>
                  {this.state.userLayout ? null : (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", marginTop: "5rem" }}
                      >
                        <img
                          src={status}
                          alt="Status"
                          width="90%"
                          style={{ maxWidth: "1100px" }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        className="text-center"
                        style={{
                          marginTop: "2rem",
                          fontSize: "20px",
                          fontWeight: 300,
                        }}
                      >
                        Please confirm your following information is correct
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "5rem" }}>
                    {this.props.count > 1 && (
                      <Grid container style={{ justifyContent: "flex-end" }}>
                        <span
                          style={{
                            fontSize: "19px",
                            fontWeight: 500,
                            marginTop: "2rem",
                          }}
                          onClick={this.props.onUpdateProfile}
                        >
                          <CustomHref text={"Change Profile"} />
                        </span>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      lg={11}
                      className="text-start"
                      style={{
                        fontSize: "26px",
                        fontWeight: 600,
                      }}
                    >
                      {localStorage.channel === "amazon"
                        ? "Amazon "
                        : "Walmart "}
                      Advertising Account
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "2rem" }}>
                    <Grid
                      item
                      xs={10}
                      className="text-start"
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          Region(s):
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ fontWeight: 300 }}>
                          {this.state.sellerAccountRegion}
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: "2rem" }}>
                        <Grid item xs={12} sm={6}>
                          Your Available Advertising Marketplace(s):
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ fontWeight: 300 }}>
                          {localStorage.channel === "walmart"
                            ? this.state.sellerMarketplaces
                            : this.state.advertisingMarketplaces}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      className="text-end"
                      style={
                        this.state.userLayout &&
                        this.props.amz_onboard.amazon_advertising_access ===
                          "connected"
                          ? { marginTop: "1rem" }
                          : {}
                      }
                    >
                      <Grid
                        container
                        hidden={localStorage.channel === "walmart"}
                        className="justify-content-end"
                      >
                        <div
                          style={{
                            fontSize: "19px",
                            fontWeight: 500,
                          }}
                        >
                          <div>
                            <a href={getAmazonAdvClientURL()}>
                              <AuthButton
                                title={
                                  this.props.amz_onboard
                                    .amazon_advertising_access !== "connected"
                                    ? "Authorize"
                                    : "Reauthorize"
                                }
                                buttonStyle="adplan_button"
                                style={{ transform: "none" }}
                              />
                            </a>
                          </div>
                          <div className="mt-4">
                            <CustomHref
                              text={
                                <span
                                  onClick={() => this.refreshAssets()}
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  className="pl-1"
                                >
                                  Refresh Assets
                                </span>
                              }
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <>
                    <Grid
                      container
                      style={{ marginTop: "3rem", marginBottom: "3rem" }}
                    >
                      <Grid item md={10} className="text-center">
                        <hr />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        md={8}
                        mdOffset={1}
                        className="text-start"
                        style={{
                          fontSize: "26px",
                          fontWeight: 600,
                        }}
                      >
                        {localStorage.channel === "amazon"
                          ? "SP-API "
                          : localStorage.channel === "walmart"
                          ? "Walmart Marketplace "
                          : ""}
                        Account
                      </Grid>
                    </Grid>

                    {this.state.sellerAccountRegionList.includes("NA") ? (
                      <Grid container style={{ marginTop: "2rem" }}>
                        <Grid
                          item
                          xs={10}
                          mdOffset={1}
                          className="text-start"
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              North America Seller ID:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ fontWeight: 300 }}
                            >
                              {this.props.amz_onboard.amazon_spapi_access ===
                                "not_available" ||
                              this.props.amz_onboard.amazon_spapi_access ===
                                "pending" ? (
                                "Not available"
                              ) : this.props.user.organization_type ===
                                "seller" ? (
                                <CustomHref
                                  text={this.state.mwsSellerID}
                                  link={`https://www.amazon.com/sp?seller=${this.state.mwsSellerID}`}
                                  type="target"
                                />
                              ) : (
                                this.state.mwsSellerID
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className="text-end"
                          style={
                            this.state.userLayout &&
                            this.props.amz_onboard.amazon_spapi_access ===
                              "connected"
                              ? { marginTop: "1rem" }
                              : {}
                          }
                        >
                          <Grid
                            container
                            hidden={localStorage.channel === "walmart"}
                            className="justify-content-end"
                          >
                            <span
                              style={{
                                fontSize: "19px",
                                fontWeight: 500,
                              }}
                            >
                              {
                                this.state.spapi_global_status ===
                                "connecting" ? (
                                  <div className={`wrap ${classes.spinner}`}>
                                    <img
                                      src={logo}
                                      alt="Loading..."
                                      className="rotate"
                                      width="40"
                                    />
                                  </div>
                                ) : this.state.spapi_connecting_status ===
                                  "connect_success" ? (
                                  <img
                                    height="30px"
                                    width="30px"
                                    src={success_tick}
                                    alt="SP API Connected Successfully"
                                    className="center"
                                  />
                                ) : (
                                  <span>
                                    <span
                                      onClick={(e) =>
                                        this.editAmazonSpApiAccess("NA")
                                      }
                                    >
                                      <AuthButton
                                        title={
                                          this.props.amz_onboard
                                            .amazon_spapi_access ===
                                            "not_available" ||
                                          this.props.amz_onboard
                                            .amazon_spapi_access === "pending"
                                            ? "Authorize"
                                            : "Reauthorize"
                                        }
                                        buttonStyle="adplan_button"
                                        style={{ transform: "none" }}
                                      />
                                    </span>
                                    <span>
                                      {this.state.spapi_connecting_status ===
                                      "connect_failed" ? (
                                        <img
                                          height="25px"
                                          width="25px"
                                          src={caution}
                                          alt="SP API Connect Failed"
                                          className="center"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </span>
                                )
                                // </a>
                              }
                            </span>
                          </Grid>
                          {this.state.userLayout &&
                          this.props.user.organization_name !==
                            "WP-Unilever" ? (
                            <Grid
                              container
                              className="wrap justify-content-end"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                                className="pl-1 pb-5"
                                onClick={(e) => this.refreshListings("na")}
                              >
                                <CustomHref text={"Refresh Listings"} />
                              </span>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : null}
                    {this.state.sellerAccountRegionList.includes("EU") ? (
                      <Grid container style={{ marginTop: "2rem" }}>
                        <Grid
                          item
                          xs={10}
                          mdOffset={1}
                          className="text-start"
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              Europe Seller ID:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ fontWeight: 300 }}
                            >
                              {this.props.amz_onboard.eu_amazon_spapi_access ===
                                "not_available" ||
                              this.props.amz_onboard.eu_amazon_spapi_access ===
                                "pending" ? (
                                "Not available"
                              ) : this.props.user.organization_type ===
                                "seller" ? (
                                <CustomHref
                                  text={this.state.euMwsSellerID}
                                  link={`https://www.amazon.co.uk/sp?seller=${this.state.euMwsSellerID}`}
                                  type="target"
                                />
                              ) : (
                                this.state.euMwsSellerID
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className="text-end"
                          style={
                            this.state.userLayout &&
                            this.props.amz_onboard.eu_amazon_spapi_access ===
                              "connected"
                              ? { marginTop: "1rem" }
                              : {}
                          }
                        >
                          <Grid
                            container
                            hidden={localStorage.channel === "walmart"}
                            className="justify-content-end"
                          >
                            <span
                              style={{
                                fontSize: "19px",
                                fontWeight: 500,
                              }}
                            >
                              {
                                this.state.eu_spapi_connecting_status ===
                                "connecting" ? (
                                  <div className={`wrap ${classes.spinner}`}>
                                    <img
                                      src={logo}
                                      alt="Loading..."
                                      className="rotate"
                                      width="40"
                                    />
                                  </div>
                                ) : this.state.eu_spapi_connecting_status ===
                                  "connect_success" ? (
                                  <img
                                    height="30px"
                                    width="30px"
                                    src={success_tick}
                                    alt="SP API Connected Successfully"
                                    className="center"
                                  />
                                ) : this.state.eu_spapi_connecting_status ===
                                  "connect_failed" ? (
                                  <img
                                    height="45px"
                                    width="45px"
                                    src={caution}
                                    alt="SP API Connect Failed"
                                    className="center"
                                  />
                                ) : (
                                  <span
                                    onClick={(e) =>
                                      this.editAmazonSpApiAccess("EU")
                                    }
                                  >
                                    <AuthButton
                                      title={
                                        this.props.amz_onboard
                                          .eu_amazon_spapi_access ===
                                          "not_available" ||
                                        this.props.amz_onboard
                                          .eu_amazon_spapi_access === "pending"
                                          ? "Authorize"
                                          : "Reauthorize"
                                      }
                                      buttonStyle="adplan_button"
                                      style={{ transform: "none" }}
                                    />
                                  </span>
                                )
                                // </a>
                              }
                            </span>
                          </Grid>
                          {this.state.userLayout &&
                          this.props.user.organization_name !==
                            "WP-Unilever" ? (
                            <Grid
                              container
                              className="wrap justify-content-end"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                                className="pl-1 pb-5"
                                onClick={(e) => this.refreshListings("eu")}
                              >
                                <CustomHref text={"Refresh Listings"} />
                              </span>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : null}
                    {this.state.sellerAccountRegionList.includes("FE") ? (
                      <Grid container style={{ marginTop: "2rem" }}>
                        <Grid
                          item
                          xs={10}
                          mdOffset={1}
                          className="text-start"
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              Far East Seller ID:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ fontWeight: 300 }}
                            >
                              {this.props.amz_onboard.fe_amazon_spapi_access ===
                                "not_available" ||
                              this.props.amz_onboard.fe_amazon_spapi_access ===
                                "pending" ? (
                                "Not available"
                              ) : this.props.user.organization_type ===
                                "seller" ? (
                                <CustomHref
                                  text={this.state.feMwsSellerID}
                                  link={`https://www.amazon.com.au/sp?seller=${this.state.feMwsSellerID}`}
                                  type="target"
                                />
                              ) : (
                                this.state.feMwsSellerID
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className="text-end"
                          style={
                            this.state.userLayout &&
                            this.props.amz_onboard.fe_amazon_spapi_access ===
                              "connected"
                              ? { marginTop: "1rem" }
                              : {}
                          }
                        >
                          <Grid
                            container
                            hidden={localStorage.channel === "walmart"}
                            className="justify-content-end"
                          >
                            <span
                              style={{
                                fontSize: "19px",
                                fontWeight: 500,
                              }}
                            >
                              {
                                this.state.fe_spapi_connecting_status ===
                                "connecting" ? (
                                  <div className={`wrap ${classes.spinner}`}>
                                    <img
                                      src={logo}
                                      alt="Loading..."
                                      className="rotate"
                                      width="40"
                                    />
                                  </div>
                                ) : this.state.fe_spapi_connecting_status ===
                                  "connect_success" ? (
                                  <img
                                    height="30px"
                                    width="30px"
                                    src={success_tick}
                                    alt="SP API Connected Successfully"
                                    className="center"
                                  />
                                ) : this.state.fe_spapi_connecting_status ===
                                  "connect_failed" ? (
                                  <img
                                    height="45px"
                                    width="45px"
                                    src={caution}
                                    alt="SP API Connect Failed"
                                    className="center"
                                  />
                                ) : (
                                  <span
                                    onClick={(e) =>
                                      this.editAmazonSpApiAccess("FE")
                                    }
                                  >
                                    <AuthButton
                                      title={
                                        this.props.amz_onboard
                                          .fe_amazon_spapi_access ===
                                          "not_available" ||
                                        this.props.amz_onboard
                                          .fe_amazon_spapi_access === "pending"
                                          ? "Authorize"
                                          : "Reauthorize"
                                      }
                                      buttonStyle="adplan_button"
                                      style={{ transform: "none" }}
                                    />
                                  </span>
                                )
                                // </a>
                              }
                            </span>
                          </Grid>
                          {this.state.userLayout &&
                          this.props.user.organization_name !==
                            "WP-Unilever" ? (
                            <Grid
                              container
                              className="wrap justify-content-end"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                                className="pl-1 pb-5"
                                onClick={(e) => this.refreshListings("fe")}
                              >
                                <CustomHref text={"Refresh Listings"} />
                              </span>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : null}
                  </>

                  <Grid
                    container
                    style={{ marginTop: "3rem", marginBottom: "3rem" }}
                  >
                    <Grid item md={10} mdOffset={1} className="text-center">
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginBottom: "1rem" }}
                    className="amazon-profiles-summary"
                  >
                    <AmazonProfileSummary />
                  </Grid>
                  <Grid
                    container
                    style={{ marginBottom: "3rem" }}
                    className="d-flex"
                  >
                    <ConnectSecondaryChannelWidget
                      secondaryChannel={"walmart"}
                      linkToSummaryView={
                        this.state.walmartProfileExists
                          ? "/user/walmartsummary"
                          : `/onboarding/v2/sellertype?secondaryChannel=walmart`
                      }
                      onClick={() => {
                        if (this.state.walmartProfileExists) {
                          this.props.setChannel("walmart");
                          localStorage.setItem("channel", "walmart");
                        }
                      }}
                    />
                  </Grid>

                  <Grid container className={classes.mobileButtonContainer}>
                    {this.state.userLayout === false ? (
                      <AuthButton
                        title="Continue"
                        buttonStyle="adplan_button"
                        disabled={this.state.goToDashboardClicked}
                        onClick={(e) => {
                          this.setState({ goToDashboardClicked: true });
                          this.fetchStuff();
                          this.handleRedirectToDashboard();
                        }}
                      />
                    ) : (
                      <AuthButton
                        title={
                          this.props.user.permissions === "reports"
                            ? "Go to Reports"
                            : "Go to Dashboard"
                        }
                        buttonStyle="adplan_button"
                        disabled={this.state.goToDashboardClicked}
                        onClick={(e) => {
                          this.props.user.permissions === "reports"
                            ? this.props.history.push(
                                "/user/report/advertisement"
                              )
                            : checkModule(
                                this.props.modules,
                                "MERCHANDISE_DASHBOARD"
                              )
                            ? this.props.history.push(
                                "/user/merchandising/dashboard"
                              )
                            : this.props.history.push("/user/dashboard");
                        }}
                      />
                    )}
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
          {this.state.userLayout ? null : (
            <Hidden smDown>
              <Grid
                item
                md={2}
                style={{
                  backgroundColor: "#2E0054",
                  height: "100vh",
                  overflow: "hidden",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  position: "relative",
                }}
              >
                <div
                  md={12}
                  style={{
                    backgroundImage: "url(" + bgImage + ")",
                    maxWidth: "100%",
                    width: "100%",
                    height: "100vh",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      md={12}
                      style={{ textAlign: "center", marginTop: "6rem" }}
                    >
                      <img
                        src={trellislogo}
                        alt="Trellis Logo"
                        width="170px"
                        height="50px"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      style={{
                        left: "-50px",
                        bottom: "1rem",
                        position: "absolute",
                      }}
                    >
                      <img src={bottomArrows} alt="Bottom Arrows" />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Hidden>
          )}
        </Grid>
        {this.props.errors.length > 0 &&
          ModalAlert({
            title:
              "title" in this.props.errors[0]
                ? this.props.errors[0].title
                : "error",
            msg: this.props.errors[0].message,
            onClick: () => {
              this.props.clearErrors();
            },
          })}
      </>
    );
  }
}

AmazonSummary.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  setMarketPlace: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  marketPlace: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  amz_onboard: state.amz_onboard,
  user: state.user,
  errors: state.errors,
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setMarketPlace,
  setChannel,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(AmazonSummary)));
