import React, { useState, useMemo } from "react";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import ProductsTable from "components/core/blocks/ProductsTable";
import ProductCard from "modules/pricingv2/ProductCard";
import { useMutation, useQueryClient } from "react-query";
import { SetValue, sendUpdates } from "../components/SetValue";
import Loading from "components/core/blocks/Loading";
import PauseProducts from "../components/PauseProducts";
import checkModule from "utils/checkModule";
import { useSelector } from "react-redux";
import { selectModules } from "redux/selectors/modules";

const ExpandedContent = ({ marketPlace, row, user }) => {
  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <ProductCard
        marketPlace={marketPlace}
        productId={row?.original?.product_id}
        sku={row?.original?.sku}
        user={user}
      />

      {/* This is the content for something that's not there  */}
    </div>
  );
};

const ProductSection = ({ marketPlace, pricingPlan, user }) => {
  const [invalidateProductsTable, setInvalidateProductsTable] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [valueType, setValueType] = useState("min_price");
  const [showSetValue, setShowSetValue] = useState(false);
  const [showPauseProducts, setShowPauseProducts] = useState(false);
  const queryClient = useQueryClient();
  const state = useSelector((state) => state);
  const modules = selectModules(state);

  const updateProducts = useMutation(async (updates) => {
    await sendUpdates("product_id", updates, marketPlace, queryClient);
  });

  const openPauseProducts = (product_ids) => {
    setSelectedProducts(product_ids);
    setShowPauseProducts(true);
  };

  const { group, start, end, preStart, preEnd } = useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    group_by: "product_id",
    plan_id: pricingPlan.id,
    include: "pricing",
  };

  const { data, isLoading, isError, isSuccess } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      pricingPlan.id,
      filters.group_by,
    ],
    "/api/data_report/product_report",
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (invalidateProductsTable) {
          invalidateProductsTable[0]();
          setInvalidateProductsTable(null);
        }
      },
    }
  );

  const productData = useMemo(() => {
    if (data?.products) {
      return data.products.map((product) => ({
        ...product,
      }));
    }
    return [];
  }, [data]);

  const productById = useMemo(() => {
    const ids = {};
    if (data?.products) {
      for (const product of data?.products) {
        ids[product.product_id] = product;
      }
    }
    return ids;
  }, [data]);

  return (
    <div>
      {isLoading && (
        <div className="py-5">
          <Loading fullPage={false} />
        </div>
      )}
      {isError && <p>Error Loading Dashboard</p>}
      {isSuccess && (
        <>
          <ProductsTable
            onBulkChange={async (changes, clearChanges) => {
              const updates = {};
              Object.entries(changes).forEach(([id, changes]) => {
                updates[id] = Object.entries(changes)
                  .filter(([key]) =>
                    [
                      "max_price",
                      "min_price",
                      "shipping_cost",
                      "landed_cost",
                    ].includes(key)
                  )
                  .map(([key, value]) => [key, parseFloat(value)]);
              });
              setInvalidateProductsTable([clearChanges]);
              updateProducts.mutate(updates);
            }}
            renderProductStatusToggles={checkModule(modules, "BUY_BOX")}
            marketPlace={marketPlace}
            productData={productData}
            ExpandedContent={ExpandedContent}
            isSaving={updateProducts.isLoading}
            initialSelectedProducts={selectedProducts}
            bulkActionOptions={[
              {
                label: "Remove Products from Pricing Plan",
                action: (selected) => {
                  openPauseProducts(selected);
                },
              },
              {
                divider: true,
              },
              ...[
                "Fixed Price",
                "Clear Fixed Price",
                "Minimum Price",
                "Maximum Price",
                "Shipping Cost",
                "Landed Cost",
              ].map((label) => ({
                label: label === "Clear Fixed Price" ? label : `Set ${label}`,
                action: (selected) => {
                  setSelectedProducts(selected);
                  setValueType(label);
                  setShowSetValue(true);
                },
              })),
            ]}
          />

          <SetValue
            showSetValue={showSetValue}
            setShowSetValue={setShowSetValue}
            valueType={valueType}
            selectedProducts={selectedProducts}
            productById={productById}
            marketPlace={marketPlace}
            setSelectedProducts={setSelectedProducts}
          />

          <PauseProducts
            setShowPauseProducts={setShowPauseProducts}
            showPauseProducts={showPauseProducts}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            productById={productById}
            marketPlace={marketPlace}
            setInvalidateProductsTable={setInvalidateProductsTable}
          />
        </>
      )}
    </div>
  );
};

export default ProductSection;
