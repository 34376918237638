import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import "assets/css/dot.css";
import ProductImage from "components/custom/category/listings/ProductImage";

class SummaryStatsTile extends Component {
  render() {
    var data = this.props.data;
    var bgColor =
      this.props.trak !== undefined && this.props.selected === true
        ? "#eeeeee"
        : this.props.selected === true
        ? "#62407F"
        : null;
    var color = this.props.selected === true ? "#ffffff" : "#403E3D";
    var keyColor = this.props.selected === true ? "#ffffff" : "#73706E";
    var boxShadow =
      this.props.placeHolder !== undefined
        ? "none"
        : this.props.trak !== undefined
        ? "none"
        : null;
    var borderStyle =
      this.props.placeHolder !== undefined
        ? "dashed"
        : this.props.trak !== undefined
        ? "none   "
        : this.props.child_ad
        ? "solid"
        : null;
    var borderWidth =
      this.props.placeHolder !== undefined
        ? "4px"
        : this.props.trak !== undefined
        ? "none"
        : this.props.child_ad
        ? "1px"
        : "0px";
    var borderColor =
      this.props.placeHolder !== undefined
        ? "#EEEEEE"
        : this.props.trak !== undefined
        ? "#EEEEEE"
        : "#EEEEEE";
    var borderBottomRightRadius = this.props.bottom_child
      ? null
      : this.props.child_ad
      ? "0px"
      : null;
    var borderBottomLeftRadius = this.props.bottom_child
      ? null
      : this.props.child_ad
      ? "0px"
      : null;
    var marginLeft = this.props.child_ad ? "5%" : null;
    var weight = this.props.placeHolder !== undefined ? "150" : "700";
    var cardPadding =
      this.props.cardPadding !== undefined
        ? this.props.cardPadding
        : "1rem 3rem";
    var marginBottom = null;
    var paddingTop = null;
    var marginBottomText = this.props.child_ad ? "1rem" : "2rem";
    var acos =
      this.props.acosTitle > 0
        ? "(" +
          parseFloat(
            (this.props.acosTitle * 100)?.toFixed(0)
          ).toLocaleString() +
          "%)"
        : null;
    var roas =
      this.props.roasTitle > 0
        ? "(" +
          parseFloat(this.props.roasTitle?.toFixed(2)).toLocaleString() +
          ")"
        : null;

    return (
      <div
        className={
          this.props.child_ad
            ? this.props.bottom_child
              ? "card-no-shadow child-card bottom-child-card"
              : this.props.top_child
              ? "card-no-shadow child-card top-child-card"
              : "card-no-shadow child-card"
            : "card"
        }
        style={{
          backgroundColor: bgColor,
          padding: cardPadding,
          boxShadow: this.props.child_ad ? "none" : boxShadow,
          borderStyle: borderStyle,
          borderWidth: borderWidth,
          borderColor: borderColor,
          borderBottomLeftRadius: borderBottomLeftRadius,
          borderBottomRightRadius: borderBottomRightRadius,
          borderTopRightRadius: this.props.child_ad ? "0px" : null,
          borderTopLeftRadius: this.props.child_ad ? "0px" : null,
          marginBottom: this.props.grouped ? "0px" : null,
          marginLeft: marginLeft,
          marginTop: null,
          cursor: "pointer",
        }}
      >
        {this.props.trak === undefined ? (
          <Row>
            <div
              className="header"
              style={{
                paddingTop: paddingTop,
                paddingBottom: "0px",
                marginBottom: marginBottom,
              }}
            >
              <h4
                className={this.props.child_ad ? "title mt-1 p-1" : "title"}
                style={{
                  marginBottom: marginBottomText,
                  fontSize: "20px",
                  color: color,
                  fontWeight: weight,
                }}
              >
                {this.props.prodId === undefined ? (
                  this.props.title
                ) : (
                  <Row>
                    <Col md={1}>
                      <ProductImage id={this.props.prodId} />
                    </Col>
                    <Col md={8} style={{ marginLeft: "5rem" }}>
                      <div>{this.props.sku}</div>
                      <div style={{ fontSize: "10px" }}>
                        {this.props.title.length > 40
                          ? this.props.title.substring(0, 40) + "..."
                          : this.props.title}
                      </div>
                    </Col>
                  </Row>
                )}
                {this.props.subTitle && (
                  <>
                    <br />
                    <span
                      style={{
                        fontSize: "14px",
                        color,
                      }}
                    >
                      {this.props.subTitle}
                    </span>
                  </>
                )}
                {this.props.text !== undefined ? (
                  <Row
                    style={{
                      margin: "0px",
                      marginTop: "0.5rem",
                      marginBottom: "-1rem",
                    }}
                  >
                    <p style={{ fontSize: "14px", color: color }}>
                      {this.props.text}
                    </p>
                  </Row>
                ) : null}
                {this.props.status === "paused" ||
                this.props.parent_status === "paused" ? (
                  <span style={{ float: "right", fontSize: "14px" }}>
                    Ad Paused
                    <i
                      style={{
                        color: "#2b00ff",
                        fontWeight: "bold",
                        marginLeft: "1rem",
                        fontSize: "22px",
                        verticalAlign: "middle",
                      }}
                      className="pe-7s-close-circle"
                    />
                  </span>
                ) : this.props.status?.indexOf("creative") > -1 ? (
                  <>
                    <>
                      {this.props.status?.indexOf("success") > -1 ? (
                        <span style={{ float: "right", fontSize: "14px" }}>
                          Ad Active
                          <i
                            style={{
                              color: "#25B50E",
                              fontWeight: "bold",
                              marginLeft: "1rem",
                              fontSize: "22px",
                              verticalAlign: "middle",
                            }}
                            className="pe-7s-check"
                          />
                        </span>
                      ) : this.props.status?.indexOf("paused") > -1 ? (
                        <span style={{ float: "right", fontSize: "14px" }}>
                          Ad Paused
                          <i
                            style={{
                              color: "#2b00ff",
                              fontWeight: "bold",
                              marginLeft: "1rem",
                              fontSize: "22px",
                              verticalAlign: "middle",
                            }}
                            className="pe-7s-close-circle"
                          />
                        </span>
                      ) : this.props.status?.indexOf("pending") > -1 ? (
                        <span style={{ float: "right", fontSize: "14px" }}>
                          Ad Pending
                          <i
                            style={{
                              color: "#eb8c07",
                              fontWeight: "bold",
                              marginLeft: "1rem",
                              fontSize: "22px",
                              verticalAlign: "middle",
                            }}
                            className="pe-7s-more"
                          />
                        </span>
                      ) : this.props.status?.indexOf("failed") > -1 ? (
                        <span style={{ float: "right", fontSize: "14px" }}>
                          Ad Failed
                          <i
                            style={{
                              color: "#CF1E10",
                              fontWeight: "bold",
                              marginLeft: "1rem",
                              fontSize: "22px",
                              verticalAlign: "middle",
                            }}
                            className="pe-7s-attention"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </>

                    <br />
                    {this.props.status.indexOf("creative_failed") > -1 ? (
                      <span style={{ float: "right", fontSize: "14px" }}>
                        Creative Failed
                        <i
                          style={{
                            color: "#CF1E10",
                            fontWeight: "bold",
                            marginLeft: "1rem",
                            fontSize: "22px",
                            verticalAlign: "middle",
                          }}
                          className="pe-7s-attention"
                        />
                      </span>
                    ) : this.props.status.indexOf("creative_pending") > -1 ? (
                      <span style={{ float: "right", fontSize: "14px" }}>
                        Creative Pending
                        <i
                          style={{
                            color: "#eb8c07",
                            fontWeight: "bold",
                            marginLeft: "1rem",
                            fontSize: "22px",
                            verticalAlign: "middle",
                          }}
                          className="pe-7s-more"
                        />
                      </span>
                    ) : this.props.status.indexOf("creative_approved") > -1 ? (
                      <span style={{ float: "right", fontSize: "14px" }}>
                        Creative Approved
                        <i
                          style={{
                            color: "#25B50E",
                            fontWeight: "bold",
                            marginLeft: "1rem",
                            fontSize: "22px",
                            verticalAlign: "middle",
                          }}
                          className="pe-7s-check"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </>
                ) : this.props.status === "success" ||
                  this.props.status === "approved" ? (
                  <span style={{ float: "right", fontSize: "14px" }}>
                    Ad Active
                    <i
                      style={{
                        color: "#25B50E",
                        fontWeight: "bold",
                        marginLeft: "1rem",
                        fontSize: "22px",
                        verticalAlign: "middle",
                      }}
                      className="pe-7s-check"
                    />
                  </span>
                ) : this.props.status === "pending" ? (
                  <span style={{ float: "right", fontSize: "14px" }}>
                    Ad Pending
                    <i
                      style={{
                        color: "#eb8c07",
                        fontWeight: "bold",
                        marginLeft: "1rem",
                        fontSize: "22px",
                        verticalAlign: "middle",
                      }}
                      className="pe-7s-more"
                    />
                  </span>
                ) : this.props.status === "failed" ? (
                  <>
                    <span style={{ float: "right", fontSize: "14px" }}>
                      Ad Failed
                      <i
                        style={{
                          color: "#CF1E10",
                          fontWeight: "bold",
                          marginLeft: "1rem",
                          fontSize: "22px",
                          verticalAlign: "middle",
                        }}
                        className="pe-7s-attention"
                      />
                    </span>
                  </>
                ) : this.props.status === "rejected" ? (
                  <>
                    <span style={{ float: "right", fontSize: "14px" }}>
                      Ad Rejected
                      <i
                        style={{
                          color: "#CF1E10",
                          fontWeight: "bold",
                          marginLeft: "1rem",
                          fontSize: "22px",
                          verticalAlign: "middle",
                        }}
                        className="pe-7s-attention"
                      />
                    </span>
                    {this.props.policyViolations?.length > 0 && (
                      <span
                        className="py-3 fs-standard"
                        style={{ float: "right" }}
                      >
                        See media plan for violation details
                      </span>
                    )}
                  </>
                ) : this.props.status === "in_progress" ? (
                  <span style={{ float: "right", fontSize: "14px" }}>
                    Ad In Review
                    <i
                      style={{
                        color: "#eb8c07",
                        fontWeight: "bold",
                        marginLeft: "1rem",
                        fontSize: "22px",
                        verticalAlign: "middle",
                      }}
                      className="pe-7s-more"
                    />
                  </span>
                ) : null}
              </h4>
              {this.props.overview ? null : (
                <h4
                  className="title"
                  style={{
                    fontSize: "16px",
                    color: keyColor,
                    fontWeight: weight,
                  }}
                >
                  <Row className="w-100 gx-0">
                    <Col
                      md={6}
                      style={{
                        textAlign: "left",
                        float: "left",
                        margin: "0px",
                      }}
                    >
                      <div>{Object.keys(data)[0]}</div>
                      <div style={{ color: color }}>
                        {Object.values(data)[0]}
                      </div>
                    </Col>
                    <Col
                      md={6}
                      style={{
                        textAlign: "right",
                        float: "right",
                        margin: "0px",
                      }}
                    >
                      <div>{Object.keys(data)[1]}</div>
                      <div style={{ color: color }}>
                        {Object.values(data)[1]}{" "}
                        {this.props.showRoas ? roas : acos}
                      </div>
                    </Col>
                  </Row>
                </h4>
              )}
            </div>
          </Row>
        ) : (
          <div className="header" style={{ marginBottom: "1rem" }}>
            <h4
              className="title"
              style={{
                marginBottom: "1rem",
                fontSize: "14px",
                color: "#403E3D",
                fontWeight: weight,
              }}
            >
              <Row>
                <Col md={11}>
                  <div>{this.props.title}</div>
                </Col>
                <Col md={1} style={{ float: "right" }}>
                  {this.props.status === "green_dot" ? (
                    <span style={{ float: "right", fontSize: "25px" }}>
                      <i
                        style={{
                          transform: "rotate(45deg)",
                          color: "#25B50E",
                          fontWeight: "bold",
                          marginLeft: "1rem",
                          verticalAlign: "middle",
                        }}
                        className="pe-7s-up-arrow"
                      />
                    </span>
                  ) : this.props.status === "yellow_dot" ? (
                    <span style={{ float: "right", fontSize: "25px" }}>
                      <i
                        style={{
                          color: "#FFBF00",
                          fontWeight: "bold",
                          marginLeft: "1rem",
                          verticalAlign: "middle",
                        }}
                        className="pe-7s-right-arrow"
                      />
                    </span>
                  ) : this.props.status === "red_dot" ? (
                    <span style={{ float: "right", fontSize: "25px" }}>
                      <i
                        style={{
                          transform: "rotate(-45deg)",
                          color: "#D2222D",
                          fontWeight: "bold",
                          marginLeft: "1rem",
                          verticalAlign: "middle",
                        }}
                        className="pe-7s-bottom-arrow"
                      />
                    </span>
                  ) : null}
                </Col>
              </Row>
            </h4>
          </div>
        )}
      </div>
    );
  }
}

export default SummaryStatsTile;
