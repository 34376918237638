import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import shelfFormSchema from "./shelfFormSchema";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { FormControl, FormLabel as ControlLabel } from "react-bootstrap";
import Select from "react-select";
import Tags from "components/core/blocks/Tags";
import {
  UpdateButton,
  CancelButton,
} from "components/core/blocks/AdPlan/buttons";
import { AuthButton } from "components/core/basic/Button";
// import { useCreate, useUpdate } from "hooks/api";
import CenteredModal from "components/core/basic/CenteredModal";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { useFetch } from "hooks/api";
import getQueryValue from "utils/getQueryValue";

export const marketplaceDict = {
  CA: "Canada",
  USA: "United States",
  MX: "Mexico",
  BR: "Brazil",
  UK: "United Kingdom",
  NL: "Netherlands",
  ES: "Spain",
  IT: "Italy",
  FR: "France",
  IN: "India",
  AE: "UAE",
  DE: "Germany",
  JP: "Japan",
  SG: "Singapore",
  AU: "Australia",
  SE: "Sweden",
  PL: "Poland",
  BE: "Belgium",
  SA: "Saudi Arabia",
};

const ShelfForm = (props) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(shelfFormSchema),
    defaultValues: useMemo(() => {
      return props.data;
    }, [props]),
  });

  const [shelfId, setShelfId] = useState(null);
  const [creationDate, setCreationDate] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    getValues("category")
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [resetSelect, setResetSelect] = useState(false);
  const { data: categoryData } = useFetch(
    ["categories", props.marketPlace?.marketPlace, "amazon"],
    "/api/gvads/categories",
    {
      marketplace: props.marketPlace?.marketPlace,
      channel: "amazon",
    }
  );
  const categoryOptions = useMemo(
    () =>
      categoryData?.data?.map((c) => ({
        label: c.leaf_category_name,
        value: c.id,
      })) ?? [],
    [categoryData]
  );

  useEffect(() => {
    if (!props.create) {
      setShelfId(props.data?.id);
      setCreationDate(props.data?.created_on.substr(0, 10));
      setKeywords(
        props.data?.searches.map((search) => search.display || search.value) ??
          []
      );
      setValue("keywords", keywords);
    } else {
      reset({ name: "", description: "", brand_name: "" });
      setCreationDate(moment().format().substr(0, 10));
    }

    if (props.autoKeyword) {
      const kws = [...keywords];
      kws.push(decodeURI(props.autoKeyword));
      setKeywords(kws);
    }

    if (getQueryValue("categoryId")) {
      setValue("category", parseInt(getQueryValue("categoryId")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.shelfId, props.brandData]);

  // Shelf CRUD requests
  const queryClient = useQueryClient();

  const createShelf = useMutation(
    async (newShelf) => {
      return await api.post("/api/shelves/", newShelf);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("shelves");
        if (res.status === 201) {
          if (props.modalForm) {
            props.closeModal();
          }
          props.updateShelfData("create", res.data.id);
          if (props.fullLayout) {
            props.setSelectedCategory(selectedCategory);
          }
        }
      },
    }
  );

  const updateShelf = useMutation(
    async (updatedShelf) => {
      return await api.put(`/api/shelves/${shelfId}/`, updatedShelf);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("shelves");
        props.updateShelfData("update");

        if (res.status === 200) {
          setKeywords(res.data.searches.map((search) => search.value));
        }
      },
    }
  );

  const deleteShelf = useMutation(
    async () => {
      return await api.remove(`/api/shelves/${shelfId}/`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("shelves");
        props.updateShelfData("delete");
      },
    }
  );

  return (
    <>
      <Grid container style={{ justifyContent: "center" }}>
        {props.numOfShelfCredits <= props.numOfShelves ? (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                padding: "1rem",
                margin: "1rem auto",
                textAlign: "center",
              }}
            >
              {" "}
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  marginBottom: "2rem",
                }}
              >
                Your subscription plan does not have any more available search
                analytics shelf credits.
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  marginBottom: "3rem",
                }}
              >
                To add more, please upgrade your subscription by clicking on the
                button below.
              </p>
              <Link
                to={{
                  pathname: "/user/billing",
                  state: { showModal: "yes", numOfShelves: props.numOfShelves },
                }}
              >
                <AuthButton
                  title="Upgrade Subscription"
                  buttonStyle="adplan_button"
                />
              </Link>
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={handleSubmit()} style={{ width: "100%" }}>
            <Grid container>
              <Grid className="mb-5" container direction="row">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ padding: "0 3rem", marginBottom: "1rem" }}
                >
                  <div style={{ textAlign: "left", marginBottom: "2rem" }}>
                    <ControlLabel>
                      <h6
                        className="title"
                        style={{ color: "#73706E", fontWeight: "700" }}
                      >
                        Marketplace
                      </h6>
                    </ControlLabel>
                  </div>
                  <FormControl
                    className={"text-start"}
                    type={"formcontrol"}
                    placeholder={"Marketplace"}
                    value={marketplaceDict[props.marketPlace.marketPlace]}
                    name={"Marketplace"}
                    {...register("Marketplace")}
                    id={"Marketplace"}
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ padding: "0 3rem", marginBottom: "1rem" }}
                >
                  <div style={{ textAlign: "left", marginBottom: "2rem" }}>
                    <ControlLabel>
                      <h6
                        className="title"
                        style={{ color: "#73706E", fontWeight: "700" }}
                      >
                        Creation Date
                      </h6>
                    </ControlLabel>
                  </div>
                  <FormControl
                    className={"text-start"}
                    type={"formcontrol"}
                    placeholder={"Creation Date"}
                    value={creationDate}
                    name={"creationDate"}
                    {...register("creationDate")}
                    id={"creationDate"}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: "0 3rem", marginBottom: "1rem" }}
              >
                <div style={{ textAlign: "left", marginBottom: "2rem" }}>
                  <ControlLabel>
                    <h6
                      className="title"
                      style={{ color: "#73706E", fontWeight: "700" }}
                    >
                      Shelf Name
                    </h6>
                  </ControlLabel>
                </div>
                <Controller
                  name={"name"}
                  control={control}
                  id={"name"}
                  render={({ field, value }) => (
                    <FormControl
                      {...field}
                      className={"text-start"}
                      type={"formcontrol"}
                      placeholder={"Shelf Name"}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
                <p
                  style={{ color: "red", fontSize: "12px", padding: "1.2rem" }}
                >
                  {errors.name?.message}
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ padding: "0 3rem", marginBottom: "1rem" }}
            >
              <div style={{ textAlign: "left", marginBottom: "2rem" }}>
                <ControlLabel>
                  <h6
                    className="title"
                    style={{ color: "#73706E", fontWeight: "700" }}
                  >
                    Shelf Description
                  </h6>
                </ControlLabel>
              </div>
              <Controller
                name={"description"}
                control={control}
                render={({ field, value }) => (
                  <FormControl
                    {...field}
                    className={"text-start"}
                    type={"formcontrol"}
                    placeholder={"Shelf Description"}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                )}
              />
              <p style={{ color: "red", fontSize: "12px", padding: "1.2rem" }}>
                {errors.description?.message}
              </p>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: "0 3rem", marginBottom: "1rem" }}
              >
                <div style={{ textAlign: "left", marginBottom: "2rem" }}>
                  <ControlLabel>
                    <h6
                      className="title"
                      style={{ color: "#73706E", fontWeight: "700" }}
                    >
                      Category
                    </h6>
                  </ControlLabel>
                </div>
                <Controller
                  name={"category"}
                  control={control}
                  defaultValue={
                    props.create && !props.fullLayout
                      ? // Use URL params as fallback in case of refresh or new tab
                        props.categoryId ||
                        parseInt(getQueryValue("categoryId"))
                      : props.create
                      ? null
                      : props.categoryId
                  }
                  render={({ field, value }) => (
                    <Select
                      {...field}
                      id={"category"}
                      options={categoryOptions}
                      maxMenuHeight={600}
                      styles={{
                        multiValue: (base) => ({
                          ...base,
                          backgroundColor: this.props.disabled
                            ? "#eeeeee"
                            : "#ffffff",
                          color: "black",
                        }),
                        option: (styles, { disabled }) => {
                          return {
                            ...styles,
                            color: disabled ? "SlateGray" : "black",
                            cursor: disabled ? "not-allowed" : "default",
                            fontWeight: disabled ? 600 : "default",
                          };
                        },
                      }}
                      onChange={(e) => {
                        setResetSelect(false);
                        field.onChange(e.value);
                        setSelectedCategory(e.value);
                        setValue("category", e.value.toString());
                      }}
                      value={
                        resetSelect && !props.fullLayout && !props.categoryId
                          ? null
                          : categoryOptions.find(
                              (m) => m.value === getValues("category")
                            ) ??
                            categoryOptions.find(
                              (m) =>
                                m.value ===
                                parseInt(getQueryValue("categoryId"))
                            ) ??
                            categoryOptions.find(
                              (m) => m.value === props.categoryId
                            )
                      }
                    />
                  )}
                />
                <p
                  style={{ color: "red", fontSize: "12px", padding: "1.2rem" }}
                >
                  {errors.category?.message}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: "0 3rem", marginBottom: "1rem" }}
              >
                <div style={{ textAlign: "left", marginBottom: "2rem" }}>
                  <ControlLabel>
                    <h6
                      className="title"
                      style={{ color: "#73706E", fontWeight: "700" }}
                    >
                      Home Brand
                    </h6>
                  </ControlLabel>
                </div>
                <Controller
                  name={"brand_name"}
                  control={control}
                  id={"brand_name"}
                  render={({ field, value }) => (
                    <FormControl
                      {...field}
                      className={"text-start"}
                      type={"formcontrol"}
                      placeholder={"Brand Name"}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
                <p
                  style={{ color: "red", fontSize: "12px", padding: "1.2rem" }}
                >
                  {errors.brand_name?.message}
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ padding: "0 3rem", marginBottom: "1rem" }}
            >
              <div style={{ textAlign: "left", marginBottom: "2rem" }}>
                <ControlLabel>
                  <h6
                    className="title"
                    style={{ color: "#73706E", fontWeight: "700" }}
                  >
                    Keywords
                  </h6>
                </ControlLabel>
              </div>
              <Controller
                name="keywords"
                control={control}
                render={({ field, value }) => (
                  <Tags
                    filledTags={keywords}
                    saveFilledTags={(filledTags) => {
                      setKeywords(filledTags);
                      setValue("keywords", keywords);
                    }}
                    placeholder={"Keywords"}
                  />
                )}
              />
              <p style={{ color: "red", fontSize: "12px", padding: "1.2rem" }}>
                {errors.keywords?.message}
              </p>
            </Grid>
            <Grid
              container
              style={{ justifyContent: "flex-end" }}
              className="my-5"
            >
              <Grid container item xs={12} style={{ marginLeft: "auto" }}>
                {props.create && (
                  <CancelButton
                    text={"Cancel"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.modalForm) {
                        props.closeModal();
                      } else {
                        reset({
                          name: "",
                          description: "",
                          homeBrand: null,
                        });
                        setValue("homeBrand", undefined);
                        setResetSelect(true);
                        props.fullLayout &&
                          !props.categoryId &&
                          setSelectedCategory(undefined);
                        setKeywords([]);
                      }
                    }}
                  />
                )}

                {props.create ? (
                  <UpdateButton
                    text={"Create"}
                    onClick={() => {
                      setValue("keywords", keywords);

                      const shelfData = {
                        name: getValues("name"),
                        description: getValues("description"),
                        brand_name: getValues("brand_name"),
                        marketplace: props.marketPlace.marketPlace,
                        channel: "AMZ",
                        category: props.fullLayout
                          ? selectedCategory
                          : props.categoryId ?? selectedCategory,
                        searches: keywords.map((keyword) => ({
                          search_type: "SEARCH",
                          value: keyword,
                          display: keyword,
                        })),
                      };
                      if (keywords.length < 1 || keywords.length > 10) return;

                      createShelf.mutate(shelfData);
                    }}
                  />
                ) : (
                  <CancelButton
                    text={"Update Shelf"}
                    onClick={() => {
                      setValue("keywords", keywords);

                      const shelfData = {
                        name: getValues("name"),
                        brand_name: getValues("brand_name"),
                        description: getValues("description"),
                        marketplace: props.marketPlace.marketPlace,
                        channel: "AMZ",
                        category: props.data?.category,
                        searches: keywords.map((keyword) => ({
                          search_type: "SEARCH",
                          value: keyword,
                          display: keyword,
                        })),
                      };

                      if (keywords.length < 1 || keywords.length > 10) return;

                      updateShelf.mutate(shelfData);
                    }}
                  />
                )}

                {!props.create && (
                  <UpdateButton
                    text={"Delete Shelf"}
                    onClick={async (e) => {
                      e.preventDefault();
                      setShowConfirmModal(true);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
      <CenteredModal
        show={showConfirmModal}
        closeModal={() => setShowConfirmModal(false)}
        title="Are you sure you want to permanently delete this shelf?"
        dialogClassName="modal-60w"
        content={
          <Grid container item xs={12} style={{ padding: "50px" }}>
            <CancelButton
              text={"Cancel"}
              onClick={(e) => {
                setShowConfirmModal(false);
              }}
            />
            <UpdateButton
              text={"Delete"}
              onClick={() => {
                deleteShelf.mutate();
                setShowConfirmModal(false);
              }}
            />
          </Grid>
        }
        onHide={() => setShowConfirmModal(false)}
      />
    </>
  );
};

ShelfForm.propTypes = {
  user: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  updateShelfData: PropTypes.func.isRequired,
  marketPlace: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  channel: state.channel,
  marketPlace: state.marketPlace,
});

const mapDispatchToProps = {
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShelfForm));
