import React from "react";
import { useFetch } from "hooks/api";
import { Row } from "react-bootstrap";
import Accordion from "components/core/basic/Accordion";
import UsersTable from "components/custom/onboarding/UsersTable";
import { useSelector } from "react-redux";
import Card from "components/core/basic/Card.jsx";

const UserActivityTable = () => {
  const { user } = useSelector((state) => state);

  const { data: userData, isLoading: isUserDataLoading } = useFetch(
    ["orgUsers"],
    "/api/org_users/",
    { page_size: 5000 },
    {
      select: (d) => d.data,
      default: [],
      enabled: user.is_staff,
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  return (
    <Row>
      <Accordion title={"User Activity"}>
        <div className="py-2">
          <Card
            style={{ width: "100%" }}
            content={
              <UsersTable
                tableData={userData}
                columnsToRender={[
                  "username",
                  "email",
                  "last_login",
                  "is_staff",
                ]}
                isLoading={isUserDataLoading}
                isSelectable={false}
                defaultSorted={[{ id: "last_login", desc: true }]}
              />
            }
          />
        </div>
      </Accordion>
    </Row>
  );
};

export default UserActivityTable;
