import React from "react";
import api from "utils/api";
import { Row, Col } from "react-bootstrap";
import DialogueCard from "./DialogueCard";
import { useHistory } from "react-router-dom";
import SkipToDashboardLink from "./SkipToDashboardLink";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  completeAmzAdvConnect,
  completeAmzProfileSelection,
} from "redux/actions/amzOnboardActions";
import { setCurrentUser } from "redux/actions/authActions";

const Profiles = ({ profiles }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const savePrimaryProfile = useMutation({
    mutationFn: async (primaryProfileData) => {
      return await api.post("/api/accounts/", primaryProfileData);
    },
    onSuccess: async (res, variables) => {
      // Trigger secondary creations once profile has been assigned to primary org
      history.replace({
        search: null,
      });

      dispatch(completeAmzProfileSelection());
      dispatch(completeAmzAdvConnect());

      // Keep redux state up to date with fields updated in backend for initial visit to the dashboard and providing right redirects for SP-API auth based on org type
      dispatch(
        setCurrentUser({
          current_profile: variables.selected_profile,
          organization_type: variables.type,
        })
      );

      history.push("/onboarding/v2/connect");
    },
    onError: async (error, variables) => {
      // If user loses tokens, send back to sign in page
      if (error.response.status === 401) {
        history.push("/");
      }

      if (error.response.status === 500) {
        const res = await api.put("/api/accounts/", {
          account: variables.selected_profile,
        });

        if (res.status === 204) {
          dispatch(
            setCurrentUser({
              current_profile: variables.selected_profile,
              organization_type: variables.type,
            })
          );
          history.replace({
            search: null,
            pathname: "/onboarding/v2/connect",
          });
        }
      }
    },
  });

  if (user.current_profile) {
    history.push("/onboarding/v2/connect");
  }
  return (
    <Row className="my-5 w-100">
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        <h2 className="mt-5 mb-1 fw-600">Amazon Advertising API</h2>
        <p className="fs-4">
          We require access to both the SP-API and Amazon Advertising API
        </p>
        <h4 className="fw-500">Select Your Default Profile</h4>
      </Col>
      <Col xs={12} lg={{ offset: 2, span: 8 }}>
        {profiles.map((profile) => (
          <DialogueCard
            contents={{
              value: profile.value,
              title: profile.label,
              description: snakeCaseToSentenceCase(profile.type),
            }}
            justify="center"
            additionalClasses="my-5"
            onClick={() => {
              const primaryProfileData = {
                selected_profile: profile.value,
                type: profile.type,
              };

              savePrimaryProfile.mutate(primaryProfileData);
            }}
          />
        ))}
        <SkipToDashboardLink />
      </Col>
    </Row>
  );
};

export default Profiles;
