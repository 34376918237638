import { Grid } from "@material-ui/core";
import React from "react";
import RollupTypeFilter from "./RollupTypeFilter";
import SearchFilter from "./SearchFilter";
import DatePicker from "react-datepicker";
import FormGroupLabel from "components/core/basic/FormGroupLabel";
import moment from "moment";

const FilterBar = ({
  selectedSearchId,
  shelfData,
  selectedShelf,
  filterSearchId,
  selectedRollupType,
  filterRollupType,
  dateRange: [start, end],
  setSelectedDateRange,
  isLoading,
}) => {
  return (
    <Grid
      container
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
      spacing={2}
      alignItems="center"
    >
      <Grid item xs={12} lg={4}>
        <RollupTypeFilter
          selectedRollupType={selectedRollupType}
          filterRollupType={filterRollupType}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DatePicker
          placeholderText={"Start"}
          onChange={(d) => {
            setSelectedDateRange([moment(d), end]);
          }}
          selected={start.toDate()}
        />
        <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <FormGroupLabel label={"to"} />
        </div>
        <DatePicker
          placeholderText={"End"}
          onChange={(d) => {
            setSelectedDateRange([start, moment(d)]);
          }}
          selected={end.toDate()}
        />
      </Grid>
      <Grid container item xs={12} lg={4} justifyContent="flex-end">
        <Grid item xs={12} lg={8}>
          <SearchFilter
            shelfData={shelfData}
            filterSearchId={filterSearchId}
            selectedShelf={selectedShelf}
            selectedSearchId={selectedSearchId}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ height: "2rem" }}>
        <span>
          {isLoading && (
            <>
              <i className="fa fa-spin fa-circle-o-notch" /> Loading
            </>
          )}
        </span>
      </Grid>
    </Grid>
  );
};

export default FilterBar;
