import { generateRanges } from "utils/rangeDropdownFilter";

export const getDropdownOptions = (productData) => {
  const ranges = {};

  if (productData?.length > 0) {
    ranges.acosRanges = generateRanges(
      productData ?? [],
      5,
      "acos",
      "percentage"
    );

    ranges.roasRanges = generateRanges(productData ?? [], 5, "roas", "number");

    ranges.adSalesRanges = generateRanges(
      productData ?? [],
      5,
      "ad_sales",
      "number"
    );

    ranges.adSpendRanges = generateRanges(
      productData ?? [],
      5,
      "cost",
      "number"
    );

    ranges.totalAdSalesRanges = generateRanges(
      productData ?? [],
      5,
      "total_sales",
      "number"
    );

    ranges.totalAcosRanges = generateRanges(
      productData ?? [],
      5,
      "total_acos",
      "percentage"
    );

    ranges.totalRoasRanges = generateRanges(
      productData ?? [],
      5,
      "total_roas",
      "number"
    );

    ranges.clickRanges = generateRanges(
      productData ?? [],
      5,
      "clicks",
      "number"
    );

    ranges.impressionRanges = generateRanges(
      productData ?? [],
      5,
      "impressions",
      "number"
    );

    ranges.clickRanges = generateRanges(
      productData ?? [],
      5,
      "clicks",
      "number"
    );

    ranges.maxDiscountRanges = generateRanges(
      productData?.data?.map(
        (d) =>
          ({
            ...d.merchandise_details,
            max_discount: d.merchandise_details?.max_discount * 100,
          } ?? [])
      ),
      5,
      "max_discount",
      "number"
    );

    ranges.marginRanges = generateRanges(
      productData?.data?.map(
        (d) =>
          ({
            ...d.merchandise_details,
            margin: d.merchandise_details?.margin
              ? d.merchandise_details?.margin * 100
              : 0,
          } ?? [])
      ),
      5,
      "margin",
      "number"
    );

    ranges.minMarginRanges = generateRanges(
      productData?.data?.map(
        (d) =>
          ({
            ...d.merchandise_details,
            min_margin: d.merchandise_details?.min_margin * 100,
          } ?? [])
      ),
      5,
      "min_margin",
      "number"
    );

    ranges.inventoryRanges = generateRanges(
      productData?.data?.map((d) => d.master_product) ?? [],
      5,
      "inventory",
      "number"
    );

    ranges.monthlyUnitsSoldRanges = generateRanges(
      productData?.data?.map((d) => d.rollup_stats) ?? [],
      5,
      "monthly_total_units_ordered",
      "number"
    );

    ranges.netPriceRanges = generateRanges(
      productData?.data ?? [],
      5,
      "net_price",
      "number"
    );

    ranges.grossPriceRanges = generateRanges(
      productData ?? [],
      5,
      "gross_price",
      "number"
    );

    ranges.priceRanges = generateRanges(
      productData ?? [],
      5,
      "product_price",
      "number"
    );

    ranges.ratingsCountRanges = generateRanges(
      productData ?? [],
      5,
      "ratings_count",
      "number"
    );

    ranges.amzRatingRanges = generateRanges(
      [
        { rating: 0 },
        { rating: 1 },
        { rating: 2 },
        { rating: 3 },
        { rating: 4 },
        { rating: 5 },
      ],
      5,
      "rating",
      "number"
    );
  }

  return ranges;
};
