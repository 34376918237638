import Loading from "components/core/blocks/Loading";
import { useFetch } from "hooks/api";
import React, { useMemo } from "react";
import TEMPLATES from "./templates";
import { useForm } from "react-hook-form";
import EditFooterActions from "./EditFooterActions";
import useEditProgram from "./useEditProgram";
import { Col, Row } from "react-bootstrap";

const EditProgram = ({
  id,
  defaultValues,
  programTemplate,
  FormComponent,
  onCancel,
  onSuccess,
}) => {
  const { handleSubmit, ...form } = useForm({
    shouldUnregister: false,
    defaultValues,
  });

  const onInvalid = (errors) => console.log("errors", errors);
  const editProgram = useEditProgram({
    id,
    programType: programTemplate,
    onSuccess,
    version: programTemplate === "CUSTOM" ? 1 : 2,
  });

  return (
    <Row>
      <Col xs={12} lg={10} xlg={8}>
        <FormComponent {...form} />
        <EditFooterActions
          onEdit={handleSubmit(editProgram.mutate, onInvalid)}
          onCancel={onCancel}
          isLoading={editProgram.isLoading || form.formState.isSubmitting}
        />
      </Col>
    </Row>
  );
};

const Wrapper = ({ programId, ...props }) => {
  const { data, isFetching: isLoading } = useFetch(
    ["programs", programId],
    `/programs/${programId}/`,
    {},
    {
      select: (data) => data.data,
      staleTime: Infinity,
    }
  );

  const FormComponent = useMemo(() => {
    if (isLoading || !data?.ui_state) return null;
    const programTemplate = data?.template_type || "CUSTOM";
    const template = TEMPLATES.find((t) => t.id === programTemplate);
    return template?.FormComponent || null;
  }, [isLoading, data]);
  if (FormComponent) {
    const { id, name, start_date, end_date, ui_state, spec, template_type } =
      data;

    return (
      <EditProgram
        id={id}
        programTemplate={template_type}
        FormComponent={FormComponent}
        defaultValues={{
          name,
          start_date,
          end_date,
          ...(template_type === "CUSTOM" ? spec : ui_state),
        }}
        {...props}
      />
    );
  }
  return <Loading />;
};

export default Wrapper;
