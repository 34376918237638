import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import { snakeCaseToSentenceCase } from "utils/formatText";
import Table from "views/merchandise/tables/BulkActionsTable";
import TargetDetails from "views/advertising/TargetDetails";
import KeywordCell from "./KeywordCell";
import acosOrRoas from "utils/acosOrRoas";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import FormGroupTile from "components/core/basic/FormGroupTile";
import NotificationSystem from "react-notification-system";
import { Row, Col, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatCurrency } from "utils/formatNumber";
import { useMediaQuery } from "@react-hook/media-query";
import { useFetch } from "hooks/api";
import { formatTargetText } from "utils/formatTargetText";
import { sendToastNotification } from "utils/sendToastNotification";
import BulkAdPlanActionForm from "views/advertising/BulkAdPlanActionForm";
import moment from "moment";
import "assets/css/form-inputs.css";
import {
  KEYWORD_MATCH_TYPE_FILTER,
  TARGET_MATCH_TYPE_FILTER,
  TARGET_STATE_FILTER,
  BID_PREVIEW_WARNING_FILTER,
  getTargetBidTypeFilter,
  getTargetSourceFilter,
} from "views/advertising/targetLifecycleFilters";
import useCustomTableFilters from "hooks/useCustomTableFilters";

import { GOALS } from "components/core/blocks/AdPlan/constants";
import withAdPlan from "components/core/blocks/AdPlan/withAdPlan";
import DownloadReport from "modules/perf_reports/DownloadReport";

const TARGET_STATES = {
  PAUSED: { value: "paused", label: "Pause" },
  ENABLED: { value: "enabled", label: "Enable" },
  ARCHIVED: { value: "archived", label: "Archive" },
  NEGATED: { value: "negated", label: "Negated" },
};

const MicroStores2 = ({
  marketPlace,
  adId,
  tableId,
  targetData,
  isLoading,
  isStatsLoading,
  modules,
  adPlanData = {},
  adPlanParams = {},
  products,
  onUpdate,
  filters,
}) => {
  const [selectedTargets, setSelectedTargets] = useState([]);

  const [bidValue, setBidValue] = useState(null);
  const [minBid, setMinBid] = useState(null);
  const [maxBid, setMaxBid] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [bidBoostFormData, setBidBoostFormData] = useState({
    actionUnit: "percent",
  });

  const { preferences } = useMerchandisePreferences();
  const { channel } = useSelector((state) => state);

  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  const queryClient = useQueryClient();
  const smallViewportWidthTable = useMediaQuery("(max-width:1550px)");

  const isNTMAd = adPlanData?.ad_goal === GOALS.IMPORTED_ADS.value;
  const NTMEnrolled = adPlanData?.non_trellis_enrolled_ad;
  const NTMBidsAccepted = adPlanData?.non_trellis_enrolled_ad_accept_bids;
  const showPreviewBidColumn = isNTMAd && NTMEnrolled && !NTMBidsAccepted;

  let targetType;

  // Keywords
  switch (tableId) {
    case "keyword":
      targetType = "keyword";
      break;
    case "target":
      targetType = "target";
      break;
    case "placements":
      targetType = "locations";
      break;
    case "adItems":
      targetType = "target";
      break;
    default:
      targetType = "keyword";
      break;
  }

  const tableRef = useRef();
  const notificationRef = useRef();

  useEffect(() => {
    resetActionFormFields();
    setSelectedTargets([]);
  }, [tableId]);

  // Fetch insights data
  const { data: insightsData } = useFetch(
    ["targetInsights", adId],
    `/target_details`,
    { advertisement: adId, insights: true },
    {
      select: (res) => res.data?.insights ?? [],
      staleTime: 5 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const updateHandler = useMutation(
    async (updateData, isLast) => {
      return await api.post("/target_actions/", updateData);
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.isLast) {
          return;
        }
        // Clear form fields
        resetActionFormFields();
        setSelectedTargets([]);

        sendToastNotification(
          notificationRef,
          "success",
          `${snakeCaseToSentenceCase(tableId)}
                ${
                  variables?.data?.action === "change_state"
                    ? "States"
                    : "Bid Settings"
                } Successfully Updated`
        );

        setSelectedTargets([]);
        setBidBoostFormData({ actionUnit: "percent" });
        if (tableId === "keywords") {
          queryClient.invalidateQueries("keywords_report_meta");
        } else {
          queryClient.invalidateQueries();
        }
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update ${snakeCaseToSentenceCase(tableId)} ${
            variables?.data?.action === "change_state"
              ? "States"
              : "Bid Settings"
          }`
        );
      },
    }
  );

  const resetActionFormFields = () => {
    setBidValue(null);
    setMinBid(null);
    setMaxBid(null);
    setStartDate(null);
    setEndDate(null);
  };

  const negateTargets = () => {
    const updateData = {
      target_ids: selectedTargets,
      values: [],
      action: "negate_target",
      target_type: targetType,
      channel: channel.currentChannel ?? "amazon",
    };

    updateHandler.mutate({
      data: updateData,
      isLast: true,
    });
  };

  const updateTargetState = (newState) => {
    const updateData = {
      target_ids: selectedTargets,
      values: [newState],
      action: "change_state",
      target_type: targetType,
      channel: channel.currentChannel ?? "amazon",
    };

    updateHandler.mutate({
      data: updateData,
      isLast: true,
    });
  };

  const updateTargetBids = async (
    bulk = false,
    bulkActionType = null,
    changes
  ) => {
    let updateData = {};

    // Case of edited cell values or bulk updated with shared bid value
    if (bulk) {
      updateData = {
        target_ids: selectedTargets,
        values: [],
        action: bulkActionType,
        target_type: targetType,
        channel: channel.currentChannel ?? "amazon",
      };

      switch (bulkActionType) {
        case "set_bid_range":
          updateData.values = [parseFloat(maxBid), parseFloat(minBid)];
          break;
        case "clear_bid_range":
          updateData.action = "set_bid_range";
          updateData.values = [null, null];
          break;
        case "set_bid":
          if (!bidValue) {
            updateData.values = [null];
          } else {
            updateData.values = [parseFloat(bidValue)];
          }
          break;
        case "boost_bid":
          updateData.values = [];

          if (bidBoostFormData?.startDate) {
            updateData.budget_start_date = bidBoostFormData?.startDate;
          }
          if (bidBoostFormData?.endDate) {
            updateData.budget_end_date = bidBoostFormData?.endDate;
          }
          break;
        default:
          break;
      }

      updateHandler.mutate({
        data: updateData,
        // Single request, so mark true to launch success/failure notification
        isLast: true,
      });

      return;
    }

    const editTargetData = targetData?.keywords
      ?.filter((row) =>
        Object.keys(changes)
          ?.map((id) => parseInt(id))
          ?.includes(row.id)
      )
      ?.map((row) => {
        return { ...row, ...changes[row.id] };
      });

    for (let i = 0; i < editTargetData?.length; i++) {
      let updatedTargetData = targetData?.keywords?.find(
        (target) => target.target_id === editTargetData[i].target_id
      );

      let updateData = {};

      updateData = {
        target_ids: [updatedTargetData?.target_id],
        values: [parseFloat(editTargetData[i]?.target_bid)],
        action: "set_bid",
        target_type: targetType,
        channel: channel.currentChannel ?? "amazon",
      };

      updateHandler.mutate({
        data: updateData,
        isLast: i === editTargetData?.length - 1,
      });
    }
  };

  // Submits request to scheduling endpoint, handles success and error messages for user
  const scheduleHandler = useMutation(
    async (body) => {
      const reqData = body.data ?? {};
      return await api.post("/api/scheduledactions/", reqData);
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          `Successfully Scheduled Bid Update${
            selectedTargets?.length > 1 ? "s" : ""
          }`
        );

        setSelectedTargets([]);
        setBidBoostFormData({ actionUnit: "percent" });
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Schedule Bid Update${
            selectedTargets?.length > 1 ? "s" : ""
          }`
        );
      },
    }
  );

  // Formats data for backend, handles scheduled fixed bids and bid boosts in a loop through all selected keywords or targets
  const scheduleBidBoost = async () => {
    for (let i = 0; i < selectedTargets?.length; i++) {
      const { actionValue, actionUnit, startDate, endDate } = bidBoostFormData;

      const updateData = {
        channel: channel.currentChannel === "walmart" ? "walmart" : "AMZ",
        status: "scheduled",
        start_date: `${startDate} 00:00:00.000000`,
        end_date: `${endDate} 00:00:00.000000`,
        adv_keyword: selectedTargets[i],
        gvadvertisements_set: [],
        gvadvertisedkeyword_set:
          targetType === "keyword" && channel.currentChannel === "amazon"
            ? [selectedTargets[i]]
            : [],
        gvadvertisedtarget_set:
          targetType === "target" && channel.currentChannel === "amazon"
            ? [selectedTargets[i]]
            : [],
        walmartadgroupkeyword_set:
          targetType === "keyword" && channel.currentChannel === "walmart"
            ? [selectedTargets[i]]
            : [],
        walmartaditem_set:
          targetType === "target" && channel.currentChannel === "walmart"
            ? [selectedTargets[i]]
            : [],

        fields: [
          {
            field_name:
              actionUnit === "percent"
                ? "timebound_per_change"
                : "timebound_flat_change",

            change_type: "set_change",
            // For percent bid boost values, convert into multiplier (i.e. 50% add to original becomes 1.5)
            change_value:
              actionUnit === "percent"
                ? 1 + parseFloat(actionValue) / 100
                : parseFloat(actionValue),
          },
        ],
      };

      scheduleHandler.mutate({
        data: updateData,
        isLast: i === selectedTargets?.length - 1,
      });
    }
  };

  const deleteHandler = useMutation(
    async (data) => {
      const { id } = data;
      await api.remove(`/api/scheduledactions/${id}/`);
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          "Successfully Cancelled Scheduled Bid Boosts"
        );
        setSelectedTargets([]);
        setBidBoostFormData({ actionUnit: "percent" });
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Cancel Scheduled Bid Boosts"
        );
      },
    }
  );

  const cancelScheduledBidBoosts = async () => {
    for (let i = 0; i < selectedTargets?.length; i++) {
      const data = targetData?.keywords?.find(
        (target) => target.target_id === selectedTargets[i]
      );

      // Filter out only scheduled and active ids
      const ids = data.scheduled_actions?.map((a) => a.scheduled_action_id);

      for (let i = 0; i < ids.length; i++) {
        deleteHandler.mutate({ id: ids[i], isLast: i === ids.length - 1 });
      }
    }
  };

  const tableData = useMemo(() => {
    return (
      targetData?.keywords?.map((target) => {
        return {
          ...target,
          ...insightsData?.find(
            (insight) =>
              insight?.adv_target === target?.id ||
              insight?.adv_keyword === target?.id ||
              insight?.adv_wmadgkw === target?.id ||
              insight?.adv_wmaditem === target?.id
          ),
        };
      }) ?? []
    );
  }, [targetData, insightsData]);

  const BID_TYPE_FILTER = getTargetBidTypeFilter(tableId);
  const TARGET_SOURCE_FILTER = getTargetSourceFilter(channel.currentChannel);

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, tableId);

  let bulkActionOptions = [
    {
      label: `Set Fixed Bid`,
      action: () => updateTargetBids(true, "set_bid"),
      actionFormFields: (
        <Row className="pb-5">
          <form>
            <p className="pb-4 fs-4">
              Please enter your desired bid value for the selected{" "}
              {snakeCaseToSentenceCase(tableId).toLowerCase()}
              {selectedTargets?.length > 1 ? "s" : ""} using the field below.
            </p>
            <Row className="pt-3 d-flex justify-content-center">
              <Col xs={12} md={6} lg={4} className="px-5">
                <FormGroupTile
                  type="formcontrol"
                  formControlType={"number"}
                  handleChildFormElement={(key, value) => {
                    setBidValue(value);
                  }}
                  label={"Bid Amount"}
                  placeholder={"Enter a bid value"}
                  defaultValue={bidValue}
                />
              </Col>
            </Row>
          </form>
        </Row>
      ),
      confirmationMessage: (
        <>
          {startDate > endDate ? (
            <p className="py-2 text-danger fs-5">
              Invalid date range. Start date must come before end date.
            </p>
          ) : parseFloat(bidValue) ? (
            <p className="fs-4 py-2">
              Are you sure you want to update bids for the selected{" "}
              {snakeCaseToSentenceCase(tableId)?.toLowerCase()}
              {selectedTargets?.length > 1 ? "s" : ""} to{" "}
              {formatCurrency(bidValue, marketPlace)}?
            </p>
          ) : (
            <></>
          )}
        </>
      ),
      disableConfirmButton: !parseFloat(bidValue),
    },
    {
      label: `Clear Fixed Bid`,
      action: () => updateTargetBids(true, "set_bid"),
      confirmationMessage: (
        <p className="fs-4 py-2">
          Are you sure you want to remove fixed bids for the selected{" "}
          {snakeCaseToSentenceCase(tableId)?.toLowerCase()}
          {selectedTargets?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: `Schedule ${snakeCaseToSentenceCase(tableId)} Bid Boost${
        selectedTargets?.length > 1 ? "s" : ""
      }`,
      action: () => scheduleBidBoost(),
      confirmationMessage:
        !parseFloat(bidBoostFormData?.actionValue) ||
        !bidBoostFormData?.actionUnit ||
        !bidBoostFormData?.startDate ||
        !bidBoostFormData?.endDate ? (
          <p className="fs-4 py-2">Please fill out all required fields.</p>
        ) : (
          <p className="fs-4 py-2">
            Are you sure you want to boost bids by{" "}
            {bidBoostFormData?.actionUnit === "percent"
              ? `${bidBoostFormData?.actionValue}%`
              : formatCurrency(bidBoostFormData?.actionValue, marketPlace)}{" "}
            for the selected {snakeCaseToSentenceCase(tableId).toLowerCase()}
            {selectedTargets?.length > 1 ? "s" : ""} from{" "}
            {moment(bidBoostFormData?.startDate).format("MMMM D")} to{" "}
            {moment(bidBoostFormData?.endDate).format("MMMM D")} (
            {(moment(bidBoostFormData?.endDate).diff(
              bidBoostFormData?.startDate,
              "days"
            ) +
              1) *
              24}{" "}
            hours)?
          </p>
        ),
      actionFormFields: (
        <BulkAdPlanActionForm
          formData={bidBoostFormData}
          setFormData={setBidBoostFormData}
          actionTarget={"bid boost"}
          hideActionTypeOptions={true}
          renderDateFields={true}
        />
      ),

      disableConfirmButton:
        !parseFloat(bidBoostFormData?.actionValue) ||
        !bidBoostFormData?.actionUnit ||
        !bidBoostFormData?.startDate ||
        !bidBoostFormData?.endDate,
    },
    {
      label: `Cancel Scheduled Bid Boost${
        selectedTargets?.length > 1 ? "s" : ""
      }`,
      action: () => cancelScheduledBidBoosts(),
      confirmationMessage: (
        <p className="fs-4 py-2">
          Are you sure you want to cancel bid boosts for the selected{" "}
          {snakeCaseToSentenceCase(tableId).toLowerCase()}
          {selectedTargets?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: `Update Bid Range${selectedTargets?.length > 1 ? "s" : ""}`,
      action: () => updateTargetBids(true, "set_bid_range"),
      actionFormFields: (
        <Row className="pb-5">
          <form>
            <Row className="d-flex justify-content-center align-items-center">
              <p className="pb-4 fs-4">
                Please enter your desired bid range for the selected{" "}
                {snakeCaseToSentenceCase(tableId).toLowerCase()}
                {selectedTargets?.length > 1 ? "s" : ""} using the fields below.
              </p>
              <Col xs={12} md={6} lg={4} className="px-5">
                <FormGroupTile
                  type="formcontrol"
                  formControlType={"number"}
                  handleChildFormElement={(key, value) => {
                    setMinBid(value);
                  }}
                  label={"Min Bid"}
                  placeholder={"Enter a minimum bid"}
                  defaultValue={minBid}
                />
              </Col>
              <Col xs={12} md={6} lg={4} className="px-5">
                <FormGroupTile
                  type="formcontrol"
                  formControlType={"number"}
                  handleChildFormElement={(key, value) => {
                    setMaxBid(value);
                  }}
                  label={"Max Bid"}
                  placeholder={"Enter a maximum bid"}
                  defaultValue={maxBid}
                />
              </Col>
            </Row>
          </form>
        </Row>
      ),
      confirmationMessage: (
        <>
          {parseFloat(minBid) >= parseFloat(maxBid) ? (
            <p className="py-2 text-danger fs-5">
              Invalid bid range. Please ensure the minimum bid is a lower value
              than the maximum bid.
            </p>
          ) : parseFloat(minBid) && parseFloat(maxBid) ? (
            <p className="py-2 fs-4">
              Are you sure you want to update the bid ranges for the selected{" "}
              {snakeCaseToSentenceCase(tableId).toLowerCase()}
              {selectedTargets?.length > 1 ? "s" : ""} to between{" "}
              {formatCurrency(minBid, marketPlace)} and{" "}
              {formatCurrency(maxBid, marketPlace)}?
            </p>
          ) : (
            <></>
          )}
        </>
      ),
      disableConfirmButton: !parseFloat(minBid) || !parseFloat(maxBid),
    },
    // Clear bid range for selected targets
    {
      label: `Remove Bid Range${selectedTargets?.length > 1 ? "s" : ""}`,
      action: () => updateTargetBids(true, "clear_bid_range"),
      confirmationMessage: (
        <>
          <p className="py-2 fs-4">
            Are you sure you want to remove the bid ranges for the selected{" "}
            {snakeCaseToSentenceCase(tableId).toLowerCase()}
            {selectedTargets?.length > 1 ? "s" : ""}?
          </p>
        </>
      ),
    },
  ];

  const trellisManagedOptions = [
    {
      label: `Pause ${snakeCaseToSentenceCase(tableId)}${
        selectedTargets?.length > 1 ? "s" : ""
      }`,
      action: () => updateTargetState(TARGET_STATES.PAUSED.value),
      confirmationMessage: (
        <p>
          Are you sure you want to pause the selected{" "}
          {snakeCaseToSentenceCase(tableId).toLowerCase()}
          {selectedTargets?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: `Enable  ${snakeCaseToSentenceCase(tableId)}${
        selectedTargets?.length > 1 ? "s" : ""
      }`,
      action: () => updateTargetState(TARGET_STATES.ENABLED.value),
      confirmationMessage: (
        <p>
          Are you sure you want to enable the selected{" "}
          {snakeCaseToSentenceCase(tableId).toLowerCase()}
          {selectedTargets?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: `Archive  ${snakeCaseToSentenceCase(tableId)}${
        selectedTargets?.length > 1 ? "s" : ""
      }`,
      action: () => updateTargetState(TARGET_STATES.ARCHIVED.value),
      confirmationMessage: (
        <p>
          Are you sure you want to archive the selected{" "}
          {snakeCaseToSentenceCase(tableId).toLowerCase()}
          {selectedTargets?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      hidden: channel?.currentChannel !== "amazon",
      label: `Negate ${snakeCaseToSentenceCase(tableId)}${
        selectedTargets?.length > 1 ? "s" : ""
      }`,
      action: () => negateTargets(),
      confirmationMessage: (
        <p>
          Are you sure you want to negate the selected{" "}
          {snakeCaseToSentenceCase(tableId).toLowerCase()}
          {selectedTargets?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
  ];

  if (!isNTMAd) {
    bulkActionOptions = bulkActionOptions.concat(trellisManagedOptions);
  }

  const columnSpecs = [
    {
      key: "ad_group_name",
      compareKey: null,
      options: {
        metric: {
          id: "ad_group_name",
          name: "Ad Group Name",
          format: "text",
        },
        toolTip: `The ad group the ${tableId} belongs to`,
        hideFilter: true,
        overflowCell: true,
        additionalStyles: { paddingTop: "20px" },
        formatter: (value) => value,
        checked: isNTMAd,
      },
    },
    {
      key: "target_bid",
      compareKey: null,
      options: {
        editCell: true,
        minValue: 0,
        columnTitle: "Bid",
        className: "text-end",
        overrideKey: "manual_bid",
        strikeThroughText: (row) => {
          if (!row?._original?.state) {
            return "";
          }
          if (row._original?.state?.toLowerCase()?.indexOf("negative") > -1) {
            return "text-decoration-line-through";
          }
          return "";
        },
        toolTip: `The current bid for the advertised ${tableId}`,
      },
    },
    {
      key: "target_source",
      compareKey: null,
      options: {
        hideFilter: true,
        formatter: (value) =>
          value === "GV_DISCOVERY"
            ? "initial"
            : value === "harvested"
            ? "harvesting"
            : snakeCaseToSentenceCase(value)?.toLowerCase(),
      },
    },
    {
      key: "creation_date",
      compareKey: null,
      options: {
        metric: {
          id: "creation_date",
          format: "text",
          accessor: (d) => d?.creation_date,
          name: "Created On",
        },
        toolTip: `The ${tableId}'s creation date`,
        formatter: (v) => v ?? "",
        hideFilter: true,
        checked: false,
      },
    },
    {
      key: "match_type",
      compareKey: null,
      options: {
        width: smallViewportWidthTable ? 70 : 150,
        hideFilter: true,
        formatter: (v) => {
          return snakeCaseToSentenceCase(v)?.toLowerCase().indexOf("negative") >
            -1
            ? snakeCaseToSentenceCase(v)
                ?.toLowerCase()
                ?.replace("negative", "negative ")
            : snakeCaseToSentenceCase(v)?.toLowerCase();
        },
      },
    },
    {
      key: "target_state",
      compareKey: null,
      options: {
        checked: false,
        metric: {
          id: "target_state",
          format: "text",
          accessor: (d) => d?.target_state,
          name: "State",
          options: { checked: !smallViewportWidthTable },
        },
        toolTip: `The serving status of the advertised ${tableId}`,
        formatter: (v) => snakeCaseToSentenceCase(v)?.toLowerCase() ?? "",
        filterType: "select",
        hideFilter: true,
      },
    },
    { key: "clicks", compareKey: "clicks_percent" },
    { key: "ad_spend", compareKey: "ad_spend_percent" },
    { key: "impressions", compareKey: "impressions_percent" },
    { key: "orders", compareKey: "orders_percent" },
    { key: "ad_sales", compareKey: "ad_sales_percent" },
    acosroas(
      {
        key: "acos",
        compareKey: "acos_delta",
        options: { isNegative: true },
      },
      { key: "roas", compareKey: "roas_delta" }
    ),
    {
      key: "ctr",
      compareKey: "ctr_delta",
      options: {
        checked: false,
      },
    },
    {
      key: "conversion_rate",
      compareKey: "conversion_rate_delta",
      options: {
        checked: false,
      },
    },
    {
      key: "cpc",
      compareKey: "cpc_delta",
      options: {
        checked: false,
        isNegative: true,
      },
    },
  ];

  const previewBidColumn = {
    key: "preview_bid",
    compareKey: null,
    options: {
      columnTitle: "Bid Preview",
      className: "text-end",
      metric: {
        accessor: (d) => d,
      },
      formatter: (v) => {
        const { preview_bid, target_bid } = v;
        const tooHighWarning = preview_bid > target_bid * 1.5;
        const tooLowWarning = preview_bid < target_bid * 0.5;
        return (
          <>
            {(tooHighWarning || tooLowWarning) && (
              <OverlayTrigger
                overlay={
                  <Tooltip
                    placement="top"
                    className="in"
                    id="tooltip-top"
                    bsPrefix="tr-popover-alt bg-purple"
                  >
                    <span className="text-center mx-auto fs-5">
                      {tooHighWarning
                        ? "Bid preview is significantly higher than the current bid"
                        : "Bid preview is significantly lower than the current bid"}
                    </span>
                  </Tooltip>
                }
              >
                <span className="text-danger pr-1">
                  <i className="fa fa-exclamation-circle"></i>
                </span>
              </OverlayTrigger>
            )}
            <span>{formatCurrency(preview_bid, marketPlace)}</span>
          </>
        );
      },
    },
  };

  if (showPreviewBidColumn) {
    columnSpecs.splice(1, 0, previewBidColumn);
  }

  return (
    <div>
      <NotificationSystem ref={notificationRef} />
      <Table
        marketPlace={marketPlace}
        tableRef={tableRef}
        tableId={tableId}
        tableEntityType={"target"}
        titleCol={{
          id: "target",
          isStatic: true,
          Header: "Target",
          accessor: "text",
          style: { width: smallViewportWidthTable ? "200px" : "300px" },
          width: smallViewportWidthTable ? 200 : 300,
          Cell: (props) => {
            let {
              manual_bid,
              budget_suppression,
              ceil_bid,
              floor_bid,
              scheduled_actions,
            } = {
              ...props?.original,
            };

            return (
              <>
                <KeywordCell
                  value={formatTargetText(props?.original?.text, {
                    ...props.original?.addtl_data,
                    marketPlace: marketPlace,
                  })}
                  columnWidth={300}
                />
                <div>
                  {manual_bid ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          placement="top"
                          className="in"
                          id="tooltip-top"
                          bsPrefix="tr-popover-alt bg-purple"
                        >
                          <span className="text-center mx-auto fs-5">
                            Fixed bid manually set to{" "}
                            {formatCurrency(manual_bid, marketPlace, false)}
                          </span>
                        </Tooltip>
                      }
                    >
                      <Badge
                        className={`badge bg-trellis-mauve me-2`}
                        pill
                        style={{
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <span className="fs-6 ">Fixed Bid</span>
                      </Badge>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                  {ceil_bid || floor_bid ? (
                    <Badge
                      className={`badge bg-trellis-fuchsia me-2`}
                      pill
                      style={{
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <span className="fs-6 ">
                        Bid Range:{" "}
                        {formatCurrency(floor_bid, marketPlace, false)}-
                        {formatCurrency(ceil_bid, marketPlace, false)}
                      </span>
                    </Badge>
                  ) : (
                    <></>
                  )}
                  {scheduled_actions?.length ? (
                    scheduled_actions?.map((scheduledAction) => (
                      <Badge
                        className={`badge bg-trellis-fuchsia me-2`}
                        pill
                        style={{
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <span className="fs-6 ">
                          {scheduledAction?.scheduled_action_fields[0]
                            ?.field_name === "timebound_per_change"
                            ? `${
                                scheduledAction?.scheduled_action_fields[0]
                                  ?.change_value > 0
                                  ? "+"
                                  : "-"
                              }${parseInt(
                                scheduledAction?.scheduled_action_fields[0]
                                  ?.change_value *
                                  100 -
                                  100
                              )}%`
                            : formatCurrency(
                                scheduledAction?.scheduled_action_fields[0]
                                  ?.change_value,
                                marketPlace,
                                false,
                                true
                              )}{" "}
                          Bid Boost:{" "}
                          {moment(scheduledAction?.scheduled_action_start_date)
                            .utc()
                            .format("MMM D")}
                          -
                          {moment(scheduledAction?.scheduled_action_end_date)
                            .utc()
                            .format("MMM D")}{" "}
                        </span>
                      </Badge>
                    ))
                  ) : (
                    <></>
                  )}
                  {budget_suppression ? (
                    <Badge
                      className={`badge bg-trellis-mauve me-2`}
                      pill
                      style={{
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <span className="fs-6 ">Constrained Bid</span>
                    </Badge>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          },

          Filter: ({ filter, onChange }) => null,
        }}
        data={tableData}
        // target (keyword, etc.), target type, match type, current bid, clicks, cost, impressions, orders, sales, acos
        columnSpecs={columnSpecs}
        getSearchCriteria={(row) => {
          return formatTargetText(row?.text);
        }}
        tableRowDescription={snakeCaseToSentenceCase(targetType)}
        defaultSorted={[{ id: "target_bid", desc: true }]}
        isLoading={isLoading}
        isStatsLoading={isStatsLoading}
        selected={selectedTargets}
        setSelected={setSelectedTargets}
        selectionKey="target_id"
        resetActionFormFields={resetActionFormFields}
        bulkActionOptions={bulkActionOptions}
        filterWidget={true}
        hideFilterToggleIcon={true}
        hideResetFiltersIcon={false}
        {...filterProps}
        additionalFilterOptions={[
          ["adItems", "target"].includes(tableId)
            ? TARGET_MATCH_TYPE_FILTER
            : KEYWORD_MATCH_TYPE_FILTER,
          BID_TYPE_FILTER,
          TARGET_SOURCE_FILTER,
          TARGET_STATE_FILTER,
          BID_PREVIEW_WARNING_FILTER,
        ]}
        excludedFilterColumns={["match_type", "target_state", "target_source"]}
        handleBulkChanges={(changes) =>
          updateTargetBids(false, "update", changes)
        }
        ExpandedContent={({ row }) => {
          return (
            <TargetDetails
              row={row.row}
              marketPlace={marketPlace}
              channel={channel?.currentChannel}
              targetType={targetType}
              formatTargetText={formatTargetText}
            />
          );
        }}
        children={
          <>
            {isStatsLoading && (
              <span className="fs-standard">
                <i className="fa fa-spin fa-circle-o-notch"> </i>Loading Stats
              </span>
            )}
          </>
        }
        additionalFilters={
          showPreviewBidColumn ? (
            <button
              className="logout_button"
              style={{ transform: "none" }}
              onClick={async () => await onUpdate(adPlanParams, products)}
            >
              Refresh Bid Preview
            </button>
          ) : (
            <></>
          )
        }
      />
      <div className="text-right">
        <DownloadReport
          url_path={"keyword_report"}
          filters={{
            ...filters,
            empty: true,
            kw_report: false,
            target_type: tableId === "adItems" ? "target" : tableId,
          }}
          report_dl={"keywords"}
        />
      </div>
    </div>
  );
};

export default withAdPlan(MicroStores2);
