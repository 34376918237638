import { lightenColor } from "utils/colors";

// Trellis brand colour palette
export const trellisPalette = [
  "#C63066", // 0 https://color-hex.org/color/c63066
  "#EE548C", // 1 https://color-hex.org/color/ee548c
  "#9D7EB8", // 2 https://color-hex.org/color/9d7eb8
  "#623F7F", // 3 https://color-hex.org/color/623f7f
  "#290750", // 4 https://color-hex.org/color/290750
  "#AF4B99", // 5 https://color-hex.org/color/af4b99
  "#791864", // 6 https://color-hex.org/color/791864
  "#5B0649", // 7 https://color-hex.org/color/5b0649
  "#EE83AB", // 8 https://color-hex.org/color/ee83ab
  "#FFB0CE", // 9 https://color-hex.org/color/ffb0ce
  "#D91C60", // 10 https://color-hex.org/color/d91c60
  "#E0b7D7", // 11  https://color-hex.org/color/e0b7d7
  "#0fc398", // 12 Green, not approved https://color-hex.org/color/0fc398
  "#D91266", // 13 Fuchsia https://color-hex.org/color/d91266
  "#850066", // 14 Magenta https://color-hex.org/color/850066
  "#FF7AAB", // 15 Pink https://color-hex.org/color/ff7aab
  "#eebd22", // 16 Yellow https://color-hex.org/color/eebd22
  "#f03c67", // 17 Red
];

const createColor = (color) =>
  [1, 1.2, 1.4, 1.6, 1.8].map((percent) => lightenColor(color, percent));

export const metricColors = {
  sales: createColor(trellisPalette[3]),
  units: createColor(trellisPalette[2]),
  roms: createColor(trellisPalette[0]),
  cost: createColor(trellisPalette[1]),
  acos: createColor(trellisPalette[14]),
  margin: createColor(trellisPalette[5]),
  metric1: createColor(trellisPalette[6]),
  metric2: createColor(trellisPalette[4]),
  metric3: createColor(trellisPalette[13]),
  metric4: createColor(trellisPalette[14]),
  metric5: createColor(trellisPalette[15]),
};
