import {
  PLAN_SEGMENT_FILTER,
  PLAN_TYPE_FILTER,
  RULES_FILTER,
  PLAN_STRATEGY_FILTER,
} from "modules/pricingv2/pricingLifecycleFilters";
import {
  AD_SOURCE_FILTER,
  AD_STATUS_FILTER,
  AD_STATUS_OPTIONS,
  AD_TYPE_FILTER,
  NTM_STATUS_FILTER,
  SCHEDULED_BUDGET_CHANGES_FILTER,
} from "views/advertising/adLifecycleFilters";
import {
  ORG_CHANNEL_FILTER,
  ORG_TYPE_FILTER,
} from "views/advertising/orgLifecycleFilters";
import {
  INVENTORY_STATE_FILTER,
  PRICING_PRODUCT_ENROLMENT_FILTER,
  PRODUCT_AD_STATUS_FILTER,
  PRODUCT_WITH_ADS_FILTER,
  SALES_STATE_FILTER,
} from "views/advertising/productLifecycleFilters";
import {
  PROMOTION_STATUS_FILTER,
  PROMOTION_TYPE_FILTER,
} from "views/advertising/promotionReportFilters";
import {
  BID_PREVIEW_WARNING_FILTER,
  KEYWORD_MATCH_TYPE_FILTER,
  SEARCH_TERM_REPORT_MATCH_TYPE_FILTER,
  TARGET_MATCH_TYPE_FILTER,
  TARGET_STATE_FILTER,
} from "views/advertising/targetLifecycleFilters";

export const DEFAULT_TABLE_FILTERS = {
  advertisementsTable: [
    {
      id: "ad_status",
      label: "Ad Status",
      format: "select",
      operator: "=",
      value: [1],
      overrideAccessorId: "ad_status",
      selectOptions: [
        {
          label: "All Statuses",
          value: "all",
          clearable: false,
        },
        ...AD_STATUS_OPTIONS,
      ],
      override: true,
      percentAsDecimal: false,
    },
  ],
  productsTable: [],
  advertisingDashboard: [],
};

export const LIFECYCLE_FILTER_OPTIONS = [
  // Ad filter options
  AD_TYPE_FILTER,
  AD_STATUS_FILTER,
  NTM_STATUS_FILTER,
  AD_STATUS_FILTER,
  AD_SOURCE_FILTER,
  SCHEDULED_BUDGET_CHANGES_FILTER,
  // Target/keyword filter options
  BID_PREVIEW_WARNING_FILTER,
  KEYWORD_MATCH_TYPE_FILTER,
  SEARCH_TERM_REPORT_MATCH_TYPE_FILTER,
  TARGET_MATCH_TYPE_FILTER,
  TARGET_STATE_FILTER,
  // Product filter options
  INVENTORY_STATE_FILTER,
  SALES_STATE_FILTER,
  PRODUCT_WITH_ADS_FILTER,
  PRODUCT_AD_STATUS_FILTER,
  PRICING_PRODUCT_ENROLMENT_FILTER,
  // Org filter options
  ORG_CHANNEL_FILTER,
  ORG_TYPE_FILTER,
  // Promo report filter options
  PROMOTION_TYPE_FILTER,
  PROMOTION_STATUS_FILTER,
  // Pricing plan filter options
  PLAN_SEGMENT_FILTER,
  RULES_FILTER,
  PLAN_TYPE_FILTER,
  PLAN_STRATEGY_FILTER,
];

// Below dynamic filters can't be saved as they rely on dynamic input beyond just a given tableId
export const FILTER_IDS_BLACKLIST = [
  "variants",
  "market_place",
  "target_source",
  "bid_type",
  "agencies",
  "tags",
];
