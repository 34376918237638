import React, { useState } from "react";
import { AdPlanContext } from "./AdPlanContext";
import { connect } from "react-redux";
import { selectModules } from "redux/selectors/modules";

const AdPlanProvider = ({ children, auth: { isAuthenticated } }) => {
  const [parentPlan, setParentPlan] = useState();
  const [childPlan, setChildPlan] = useState();
  const [savedChildPlans, setSavedChildPlans] = useState([]);
  const [childComponentsCompleted, setChildComponentsCompleted] =
    useState(false);
  const [refetchOnUpdate, setRefetchOnUpdate] = useState(false);

  const resetAdPlanContext = () => {
    setParentPlan(null);
    setChildPlan(null);
    setSavedChildPlans(null);
    setChildComponentsCompleted(false);
  };

  const removeChildPlan = () => {
    setChildPlan(null);
  };

  return (
    <AdPlanContext.Provider
      value={{
        parentPlan,
        setParentPlan,
        childPlan,
        setChildPlan,
        removeChildPlan,
        savedChildPlans,
        setSavedChildPlans,
        childComponentsCompleted,
        setChildComponentsCompleted,
        resetAdPlanContext,
        refetchOnUpdate,
        setRefetchOnUpdate,
      }}
    >
      {children}
    </AdPlanContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  modules: selectModules(state),
});

export default connect(mapStateToProps)(AdPlanProvider);
