import useColumnSelector from "hooks/useColumnSelector";
import React, { useMemo } from "react";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  parseFormattedCurrency,
  parseFormattedNumber,
  parseFormattedPercentage,
} from "utils/formatNumber";
import {
  filterRanges,
  generateFilterMarkup,
  generateRanges,
} from "utils/rangeDropdownFilter";
import ReactTable from "react-table-6";
import { useState } from "react";
import ReactTablePagination from "../growth/ProductsCVTablePagination";

const col = (header, type, key, options) => ({
  header,
  type,
  key,
  options,
});

const makeColumn = (data, marketPlace) => (header, key, type, options) => {
  const defaultAccessor = (p) => p[key];
  const accessor = options?.accessor ?? defaultAccessor;
  const defaultFormat = ({ value }) => value;
  const fmt =
    options?.formatter ??
    {
      currency: ({ value }) => formatCurrency(value, marketPlace.marketPlace),
      number: ({ value }) => formatNumber(value, {}, 1),
      percent: ({ value }) => formatPercent(value),
    }?.[type] ??
    defaultFormat;
  const parser =
    {
      currency: parseFormattedCurrency,
      number: parseFormattedNumber,
      percent: parseFormattedPercentage,
    }?.[type] ?? defaultFormat;
  return {
    Header: header,
    id: key,
    accessor,
    width: options?.width ?? 100,
    className: options?.className ?? "text-end",
    headerClassName: options?.className ?? "text-end",
    Cell: fmt,
    filterMethod: (filter, row) => {
      const value = accessor(row._original);
      return filterRanges(filter, value, parser, marketPlace.marketPlace);
    },
    Filter: ({ filter, onChange }) => {
      return generateFilterMarkup(filter, onChange, key, "range", type, {
        ranges: generateRanges(
          data ?? [],
          5,
          key,
          type,
          marketPlace.marketPlace
        ),
        marketPlace: marketPlace.marketPlace,
      });
    },
  };
};

const COLUMNS = [
  col("", "text", "state", {
    className: "text-start font-weight-bold",
    width: 200,
  }),
  col("Sales", "currency", "sales"),
  col("Cost", "currency", "cost"),
  col("Units", "number", "units"),
  col("ROMS", "number", "roms"),
  col("Promo Sales", "currency", "promo_sales"),
  col("Promo Cost", "currency", "promo_cost"),
  col("Promo Units", "number", "promo_units"),
  col("ROPS", "number", "rops"),
  col("Ad Sales", "currency", "ad_sales"),
  col("Ad Spend", "currency", "ad_cost"),
  col("Ad Units", "number", "ad_units"),
  col("ROAS", "number", "roas"),
  col("Margin", "percent", "margin"),
];

const SummaryTable = ({
  isActual,
  stats,
  marketPlace,
  customTitleProp = null,
  showPagination = false,
  pageSize = 4,
}) => {
  const [searchFilter, setSearchFilter] = useState("");

  const data = useMemo(() => {
    const stateMapping = {
      NEW_PRODUCT: "New Product",
      BEST_SELLER: "Best Seller",
      CLEARANCE: "Clearance",
      NORMAL: "Everything Else",
    };
    if (!stats) return [];
    let tableData = Object.entries(stats).map(([state, s]) =>
      isActual
        ? {
            state: customTitleProp
              ? s[customTitleProp]
              : stateMapping?.[state] ?? state,
            sales: s.actual_total_sales ?? 0,
            cost: s.actual_total_cost ?? 0,
            units: s.actual_total_units ?? 0,
            roms: s.roms ?? 0,
            promo_sales: s.actual_promo_sales ?? 0,
            promo_cost: s.actual_promo_cost ?? 0,
            promo_units: s.actual_promo_units ?? 0,
            rops: s.rops ?? 0,
            ad_sales: s.actual_ad_sales ?? 0,
            ad_cost: s.actual_ad_spend ?? 0,
            ad_units: s.actual_ad_units ?? 0,
            roas: s.roas ?? 0,
            margin: s.actual_margin ?? 0,
          }
        : {
            state: customTitleProp
              ? s[customTitleProp]
              : stateMapping?.[state] ?? state,
            sales: s.target_total_sales ?? 0,
            cost: s.target_total_cost ?? 0,
            units: s.target_total_units ?? 0,
            roms: s.target_roms ?? 0,
            promo_sales: s.target_promo_sales ?? 0,
            promo_cost: s.target_promo_cost ?? 0,
            promo_units: s.target_promo_units ?? 0,
            rops: s.rops ?? 0,
            ad_sales: s.target_ad_sales ?? 0,
            ad_cost: s.target_ad_spend ?? 0,
            ad_units: s.target_ad_units ?? 0,
            roas: s.target_roas ?? 0,
            margin: s.target_margin ?? 0,
          }
    );
    if (searchFilter.length > 0) {
      tableData = tableData.filter(
        (row) =>
          row.state.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
      );
    }
    return tableData;
  }, [stats, isActual, customTitleProp, searchFilter]);
  const createColumn = makeColumn(data, marketPlace);
  const [columns] = useColumnSelector(
    () => COLUMNS.map((c) => createColumn(c.header, c.key, c.type, c.options)),
    [COLUMNS, stats]
  );
  const columnOrder = columns.map((col) => col.id);

  return (
    <ReactTable
      showPaginationBottom={false}
      showPaginationTop={showPagination}
      columns={columns}
      data={data}
      defaultPageSize={pageSize}
      className="-highlight"
      filterable={false}
      state={columnOrder}
      PaginationComponent={ReactTablePagination}
      pageSizeOptions={[10]}
      getPaginationProps={() => ({
        updateSearch: setSearchFilter,
        clearFilters: () => {
          setSearchFilter("");
        },
        hideFilters: true,
        clearSearchTermFilters: () => setSearchFilter(""),
        searchTerm: searchFilter,
        table: "plan-products",
        style: { marginBottom: "2rem" },
        carouselLayout: false,
        disableSearchFocus: true,
      })}
    />
  );
};

export default SummaryTable;
