import moment from "moment";
import { useMemo } from "react";
import { useFetch } from "./api";

const useProductReport = (keys, categoryId, filters) => {
  const dt = moment().add(50, "days").format("YYYY-MM-DD");
  const { data: productMeta, isLoading: isProductMetaLoading } = useFetch(
    ["product_report_meta", categoryId],
    `/api/data_report/product_report`,
    {
      ...filters,
      start_date: dt,
      end_date: dt,
    },
    {
      select: (d) => d.data,
      default: [],
      enabled: !!categoryId,
    }
  );

  const { data: productData, isLoading: isProductDataLoading } = useFetch(
    [...keys, "product_report", "product"],
    `/api/data_report/product_report`,
    { ...filters, empty: true },
    {
      select: (d) => d.data,
      default: [],
      enabled: !!categoryId,
    }
  );

  const data = useMemo(() => {
    const data = {};
    if (productData) {
      for (const row of productData?.products) {
        data[row.name] = row;
      }
    }
    if (productMeta) {
      return productMeta?.products?.map((meta) => ({
        ...data?.[meta.id],
        ...meta,
      }));
    }
    return [];
  }, [productData, productMeta]);

  return {
    productData: data,
    isProductLoading: isProductMetaLoading,
    isProductStatsLoading: isProductDataLoading,
  };
};

export default useProductReport;
