import { marketplaceDict } from "components/custom/analytics/ShelfForm";
import React from "react";
import { NavDropdown } from "react-bootstrap";
import Flags from "country-flag-icons/react/3x2";

const MarketplaceStatsFilter = ({
  selectedMarketplace,
  setSelectedMarketplace,
  statMarketplaceOptions,
}) => {
  let flagNationCode = selectedMarketplace;

  if (flagNationCode === "USA") {
    flagNationCode = "US";
  }

  if (flagNationCode === "UK") {
    flagNationCode = "GB";
  }
  const Flag = Flags[flagNationCode];

  return (
    <>
      <NavDropdown
        id="DateBreadcrumbComparison"
        title={
          <>
            {" "}
            <Flag style={{ width: "18px", marginRight: "8px" }} />
            {marketplaceDict[selectedMarketplace]}
          </>
        }
        className={"fs-standard d-flex justify-content-center"}
        style={{ minWidth: "180px" }}
      >
        {statMarketplaceOptions.map((option, index) => (
          <NavDropdown.Item
            key={index}
            title={marketplaceDict[option]}
            onClick={() => setSelectedMarketplace(option)}
          >
            {marketplaceDict[option]}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </>
  );
};

export default MarketplaceStatsFilter;
