import { SET_OVERRIDE_MODULE, UNSET_OVERRIDE_MODULE } from "../types";

export const setOverrideModule = (orgId, module, settings) => {
  settings = settings || {};
  return {
    type: SET_OVERRIDE_MODULE,
    payload: { orgId, module: { name: module.toUpperCase(), settings } },
  };
};

export const unsetOverrideModule = (orgId, module) => {
  return {
    type: UNSET_OVERRIDE_MODULE,
    payload: {
      orgId,
      name: module.toUpperCase(),
    },
  };
};
