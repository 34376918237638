import React, { useRef } from "react";
import { useQuery } from "react-query";
import api from "utils/api";
import { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useOnScreen from "hooks/useOnScreen";
import moment from "moment";
import Loading from "../Loading";

window.Highcharts = Highcharts;

const AdPlanServingHoursMenu = ({
  adId,
  categoryId,
  marketPlace,
  organizationType,
  adPlanData,
}) => {
  let date = new Date();
  const start = new Date(date.setDate(date.getDate() - 1))
    .toISOString()
    .slice(0, 10);
  let end = new Date(date.setDate(date.getDate() - 31))
    .toISOString()
    .slice(0, 10);

  if (moment(end).isBefore(moment(adPlanData?.created_datetime))) {
    end = moment(adPlanData?.created_datetime).format("YYYY-MM-DD");
  }

  const innerCellRef = useRef(null);

  const isVisible = useOnScreen(innerCellRef);

  const { data, isLoading } = useQuery(
    ["servingHours", adId, categoryId, marketPlace, organizationType],
    async () => {
      const res = await api.get(
        `/api/serving_hours/${adId}/?start=${start}&end=${end}`
      );
      return res.data;
    }
  );

  const options = useMemo(() => {
    let items = [];
    if (data) {
      data.forEach((row) => {
        items.push([new Date(row.date).getTime(), row.serving_hours]);
      });
    }

    const orderedServingHoursData = items.sort((a, b) => {
      return a[0] - b[0];
    });

    const series = {
      name: "Day",
      color: "#a78dc2",
      data: orderedServingHoursData,
    };

    return {
      chart: {
        type: "column",
        style: {
          "font-family": "Poppins",
          "font-weight": 700,
          "font-size": "15px",
        },
      },
      title: {
        text: "Hours Served",
      },
      yAxis: {
        max: 24,
        endOnTick: 24,
        startOnTick: false,
        tickInterval: 4,
        title: {
          text: "Hour",
        },
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%b %e",
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [series],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isVisible]);

  return (
    <div ref={innerCellRef}>
      {isLoading && <Loading height={300} fullPage={false} />}
      {data && options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default AdPlanServingHoursMenu;
