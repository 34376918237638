import React from "react";
import { useFetch } from "hooks/api";
import { Row, Col } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import useLocalStorage from "hooks/useLocalStorage";
import chromeStoreLogo from "../../assets/images/logo/chrome_store.png";
import { trellisPalette } from "./analytics/palettes";

const Banner = () => {
  const { value: isDismissed, setValue: setIsDismissed } = useLocalStorage(
    "dismiss_chrome_extension_banner",
    false
  );
  const { data } = useFetch(
    ["chrome_extension"],
    "/api/chrome_extension",
    {},
    { enabled: !isDismissed, select: (d) => d.data }
  );

  if (isDismissed) {
    return null;
  }

  if (data && data.has_extension === false) {
    return (
      <Row>
        <Col
          className="text-center p-3 fs-standard"
          style={{
            borderBottom: "1px solid #dcdadd",
            backgroundColor: trellisPalette[3],
            paddingBottom: "4px",
            paddingLeft: "40px",
            position: "relative",
            color: "white",
          }}
        >
          <a
            href="https://chromewebstore.google.com/detail/trellis-seller-extension/mfnnikclhbphfbkojdfoeccbfpjcbbdl?utm_source=portal&utm_medium=web&utm_campaign=trellis_banner"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              color: "white",
            }}
          >
            <img
              src={chromeStoreLogo}
              alt="Google Chrome Store"
              style={{ height: "40px", backgroundColor: "white" }}
            />
            <span className="pl-3">
              Checkout our Browser Extension for Amazon Sellers Today!
            </span>
          </a>
          <button
            style={{
              position: "absolute",
              right: "20px",
              top: "12px",
              border: "none",
              backgroundColor: "inherit",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsDismissed(true);
            }}
          >
            <FaTimes size={15} style={{ color: "white" }} />
          </button>
        </Col>
      </Row>
    );
  }

  return null;
};

export default Banner;
