import { CHANNEL } from "../types";

const initialState = {
  currentChannel: "None",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANNEL:
      return {
        ...state,
        currentChannel: action.payload,
      };
    default:
      return state;
  }
}
