import React, { useState, useMemo, useRef } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { useFetch } from "hooks/api";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import moment from "moment";
import WeeklySeasonalityChart from "./WeeklySeasonalityChart";
import "assets/css/form-inputs.css";
import { formatCurrency, formatPercent } from "utils/formatNumber";
import { sendToastNotification } from "utils/sendToastNotification";
import NotificationSystem from "react-notification-system";
import { useMediaQuery } from "@react-hook/media-query";

const DAYS_IN_WEEK = 7;
const DEFAULT_BUDGET_MULTIPLIER = 1;
const DEFAULT_MULTIPLIER_OBJECT = {
  0: DEFAULT_BUDGET_MULTIPLIER,
  1: DEFAULT_BUDGET_MULTIPLIER,
  2: DEFAULT_BUDGET_MULTIPLIER,
  3: DEFAULT_BUDGET_MULTIPLIER,
  4: DEFAULT_BUDGET_MULTIPLIER,
  5: DEFAULT_BUDGET_MULTIPLIER,
  6: DEFAULT_BUDGET_MULTIPLIER,
};
const SEASONALITY_MULTIPLIER_OPTIONS = {
  DEFAULT: {
    label: "Default",
    value: 0,
    description:
      "When using the default strategy, Trellis media plans move budget from common low conversion days to common high conversion days (Tuesday through Thursday, Friday through Monday, respectively). Your products sales trend may not match this pattern and the manual or auto strategy may be a better fit.",
  },
  AUTO: {
    label: "Auto",
    value: 1,
    description:
      "When using the auto strategy, Trellis uses historical sales data to determine your category's weekday sales trend and will automatically adjust the budget accordingly.",
  },
  MANUAL: {
    label: "Manual",
    value: 2,
    description:
      "The manual strategy allows you to overwrite daily budget modifiers.",
  },
};

const seasonalityMultiplierOptionsArray = Object.values(
  SEASONALITY_MULTIPLIER_OPTIONS
);

const Seasonality = ({ categoryId, adPlanData, adPlanParams, marketPlace }) => {
  const formatDefaultMultiplierValues = (multipliers, strategy) => {
    let formattedMultipliersObject = {};

    // If manual strategy selected and weekday multipliers save, use those as defaults, otherwise revert
    // to auto multipliers for presets
    if (
      !multipliers?.length ||
      strategy !== SEASONALITY_MULTIPLIER_OPTIONS.MANUAL.value
    ) {
      return {};
    }

    multipliers.forEach((multiplier) => {
      formattedMultipliersObject[multiplier.weekday] = multiplier.multiplier;
    });
    return formattedMultipliersObject;
  };

  const [seasonalCategory, setSeasonalCategory] = useState(false);
  const [multiplierStrategy, setMultiplierStrategy] = useState(
    seasonalityMultiplierOptionsArray.find(
      (o) => o.value === adPlanParams.weekPartingMethod
    ) ?? SEASONALITY_MULTIPLIER_OPTIONS.DEFAULT
  );
  const [manualWeekdayMultiplers, setManualWeekdayMultipliers] = useState(
    formatDefaultMultiplierValues(
      adPlanParams?.weekPartingMultipliers,
      adPlanParams?.weekPartingMethod
    )
  );

  const notificationRef = useRef();
  const queryClient = useQueryClient();
  const verticalLayout = useMediaQuery("(max-width:1600px)");

  const { data: categorySeasonalityData, isLoading: isSeasonalityDataLoading } =
    useFetch(
      ["seasonality", categoryId],
      `/api/seasonality`,
      { category: parseInt(categoryId) },
      {
        select: (res) => res.data?.results ?? [],
        enabled: !!categoryId,
        onSuccess: (res) => {
          if (
            res?.[0]?.weekly_seasons &&
            JSON.parse(res?.[0]?.weekly_seasons)?.some(
              (day) => day.multiplier !== DEFAULT_BUDGET_MULTIPLIER
            ) &&
            !seasonalCategory
          ) {
            setSeasonalCategory(true);
          }
        },
      }
    );

  const parsedWeeklySeasonalityData = useMemo(() => {
    if (categorySeasonalityData?.[0]?.weekly_seasons) {
      return JSON.parse(categorySeasonalityData?.[0]?.weekly_seasons)?.map(
        (day) => ({
          ...day,
          // Seasonality data treats Monday as the first weekday, versus moment treating Sunday as the first weekday
          // Below offset by 1 is so data and labels line up in chart and range slider
          formattedWeekday: moment.weekdays(day.weekday + 1),
        })
      );
    }

    return [];
  }, [categorySeasonalityData]);

  const dailyMultiplierData = useMemo(() => {
    return parsedWeeklySeasonalityData?.map((daySettings) => {
      if (
        (manualWeekdayMultiplers[daySettings.weekday] ||
          manualWeekdayMultiplers[daySettings.weekday] === 0) &&
        multiplierStrategy.value === SEASONALITY_MULTIPLIER_OPTIONS.MANUAL.value
      ) {
        return {
          ...daySettings,
          multiplier: manualWeekdayMultiplers[daySettings.weekday],
        };
      }
      return daySettings;
    });
  }, [
    parsedWeeklySeasonalityData,
    manualWeekdayMultiplers,
    multiplierStrategy,
  ]);

  const updateHandler = useMutation(
    async (body) => {
      return await api.put("/api/gvads/advproduct/", body);
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          "Updating Media Plan"
        );
        queryClient.invalidateQueries(["seasonality", categoryId]);
      },
      onError: () => {
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update Media Plan`
        );
      },
    }
  );

  const handleSubmit = () => {
    const bodyData = {
      id: adPlanData.id,
      custom_api_request: "update_advertisement",
      state: adPlanData.state,
      ad_control_fields: {
        week_parting_method: multiplierStrategy.value,
        week_parting_multipliers: dailyMultiplierData.map((day) => {
          const multiplier = [
            SEASONALITY_MULTIPLIER_OPTIONS.AUTO.value,
            SEASONALITY_MULTIPLIER_OPTIONS.MANUAL.value,
          ].includes(multiplierStrategy.value)
            ? day.multiplier
            : DEFAULT_BUDGET_MULTIPLIER;
          return { multiplier, weekday: day.weekday };
        }),
      },
    };

    updateHandler.mutate(bodyData);
  };

  const weeklyMultiplierTotal = dailyMultiplierData.reduce(
    (acc, day) => acc + day.multiplier,
    0
  );
  const calculatedWeeklyConfiguredBudget = formatCurrency(
    multiplierStrategy.value === SEASONALITY_MULTIPLIER_OPTIONS.DEFAULT.value
      ? adPlanParams?.dailyBudget * DEFAULT_BUDGET_MULTIPLIER * DAYS_IN_WEEK
      : adPlanParams?.dailyBudget * weeklyMultiplierTotal,
    marketPlace
  );

  return (
    <div>
      <NotificationSystem ref={notificationRef} />
      <Row>
        <Col xs={verticalLayout ? 12 : 3} className="p-4">
          <Row>
            <Col xs={12} className="mx-auto py-2 fs-3 px-5">
              <h5 className="fw-bold text-center pb-4 pt-1">
                Budget Modifier Strategy
              </h5>
              <Row>
                <Col xs={12} md={verticalLayout ? { span: 4, offset: 3 } : 8}>
                  <FormGroupTile
                    type="select"
                    multi={false}
                    handleChildFormElement={(key, value) => {
                      setMultiplierStrategy(value);
                    }}
                    defaultValue={multiplierStrategy}
                    options={seasonalityMultiplierOptionsArray}
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <button
                    className="adplan_button px-5"
                    onClick={() => {
                      handleSubmit();
                    }}
                    style={{ transform: "none" }}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={verticalLayout ? { span: 6, offset: 3 } : 12}>
              {!seasonalCategory && !isSeasonalityDataLoading && (
                <p className="text-center mx-auto fs-5 pt-5 px-3">
                  No seasonality has been detected for this category.
                </p>
              )}
              <p
                className={`text-center mx-auto fs-5 ${
                  seasonalCategory || isSeasonalityDataLoading ? "pt-5" : ""
                } px-3`}
              >
                {multiplierStrategy.description}
              </p>
            </Col>
          </Row>
        </Col>

        <Col xs={verticalLayout ? 12 : 9} className="py-3 px-5">
          <Row>
            <Col xs={12} className="py-3 px-5 ">
              <WeeklySeasonalityChart
                data={dailyMultiplierData?.map((d) => ({
                  ...d,
                  multiplier:
                    // Format decimal values representing percents to be human readable
                    multiplierStrategy.value ===
                    SEASONALITY_MULTIPLIER_OPTIONS.DEFAULT.value
                      ? 100
                      : d.multiplier * 100,
                }))}
                isLoading={isSeasonalityDataLoading}
                dailyBudget={adPlanParams?.dailyBudget}
                marketPlace={marketPlace}
              />
            </Col>
          </Row>
          <h5 className="fw-bold text-center pt-1">Budget Modifiers</h5>
          <Row>
            <Col xs={2} />
            <Col xs={8}>
              <p className="fs-5 text-muted text-center">
                Original weekly average budget is{" "}
                {formatCurrency(
                  adPlanParams?.dailyBudget *
                    DEFAULT_BUDGET_MULTIPLIER *
                    DAYS_IN_WEEK,
                  marketPlace
                )}
              </p>
              <p className="fs-5 text-muted text-center">
                Configured weekly average budget is{" "}
                {calculatedWeeklyConfiguredBudget}
                {multiplierStrategy.value ===
                  SEASONALITY_MULTIPLIER_OPTIONS.DEFAULT.value ||
                formatPercent(
                  Math.abs(
                    (weeklyMultiplierTotal -
                      DEFAULT_BUDGET_MULTIPLIER * DAYS_IN_WEEK) /
                      7
                  )
                ) === "0%"
                  ? ""
                  : weeklyMultiplierTotal -
                      DEFAULT_BUDGET_MULTIPLIER * DAYS_IN_WEEK >
                    0
                  ? `, over by ${formatPercent(
                      (weeklyMultiplierTotal -
                        DEFAULT_BUDGET_MULTIPLIER * DAYS_IN_WEEK) /
                        7
                    )}`
                  : weeklyMultiplierTotal -
                      DEFAULT_BUDGET_MULTIPLIER * DAYS_IN_WEEK <
                    0
                  ? `, under by ${formatPercent(
                      Math.abs(
                        weeklyMultiplierTotal -
                          DEFAULT_BUDGET_MULTIPLIER * DAYS_IN_WEEK
                      ) / 7
                    )}`
                  : ""}
              </p>
            </Col>

            <Col xs={2}>
              <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                <button
                  className="adplan_button p-3 fs-5"
                  disabled={
                    multiplierStrategy.value !==
                    SEASONALITY_MULTIPLIER_OPTIONS.MANUAL.value
                  }
                  onClick={() => {
                    setManualWeekdayMultipliers(DEFAULT_MULTIPLIER_OBJECT);
                  }}
                >
                  Reset
                </button>
              </div>
            </Col>
          </Row>
          <div
            className="p-5 d-flex justify-content-center align-items-center"
            style={{ marginTop: "80px", marginLeft: "70px", maxWidth: "100%" }}
          >
            {dailyMultiplierData?.map((day) => {
              return (
                <>
                  <div className="text-center w-100">
                    <div style={{ position: "relative" }}>
                      <div
                        className="text-center w-100"
                        style={{ position: "absolute", top: "-95px" }}
                      >
                        <Badge
                          className="badge bg-trellis-fuchsia fs-5 py-2"
                          style={{
                            borderRadius: "5px",
                          }}
                          pill
                        >
                          {formatPercent(
                            multiplierStrategy.value ===
                              SEASONALITY_MULTIPLIER_OPTIONS.DEFAULT.value
                              ? DEFAULT_BUDGET_MULTIPLIER
                              : day.multiplier
                          )}{" "}
                          -{" "}
                          {formatCurrency(
                            adPlanParams?.dailyBudget *
                              (multiplierStrategy.value ===
                              SEASONALITY_MULTIPLIER_OPTIONS.DEFAULT.value
                                ? DEFAULT_BUDGET_MULTIPLIER
                                : day.multiplier)
                          )}
                        </Badge>
                      </div>
                      <div style={{ height: "85px" }}>
                        <input
                          type="range"
                          value={
                            multiplierStrategy.value ===
                            SEASONALITY_MULTIPLIER_OPTIONS.DEFAULT.value
                              ? DEFAULT_BUDGET_MULTIPLIER
                              : day.multiplier
                          }
                          style={{
                            transform: "rotate(270deg)",
                          }}
                          data-weekday={day.weekday}
                          onChange={(e) => {
                            const weekday = e.target.dataset.weekday;
                            const updatedMultiplier = parseFloat(
                              e.target.value
                            );
                            setManualWeekdayMultipliers((prev) => ({
                              ...prev,
                              [weekday]: updatedMultiplier,
                            }));
                          }}
                          min={0}
                          max={1.5}
                          step={0.01}
                          disabled={
                            multiplierStrategy.value !==
                            SEASONALITY_MULTIPLIER_OPTIONS.MANUAL.value
                          }
                        />
                      </div>
                    </div>
                    <p className="text-center">{day.formattedWeekday}</p>
                  </div>
                </>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Seasonality;
