import React from "react";

const TrComponent = ({ className, children, expandedChildren, style, row }) => {
  return (
    <>
      <div
        role="row"
        className={`${className} rt-tr`}
        style={{ ...style, borderBottom: "1px solid #EEE" }}
      >
        {children}
      </div>
      {row && row.original.isExpanded && (
        <div style={{ backgroundColor: "#efefef", padding: "2rem" }}>
          {expandedChildren}
        </div>
      )}
    </>
  );
};

export default TrComponent;
