import Dashboard from "./Dashboard";

export const SEOPerformanceRoutes = [
  {
    path: "/seo",
    layout: "/user",
    name: "SEO Performance",
    icon: "pe-7s-note2",
    application: "Product",
    component: null,
    children: [
      {
        path: "/user/seo/performance",
        component: Dashboard,
        exact: true,
        application: "Product",
      },
    ],
  },
];
