import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { SALE_STATES } from "components/core/blocks/AdPlan/constants";
import { UpdateButton } from "components/core/blocks/AdPlan/buttons";
import { formatCurrency } from "utils/formatNumber";
import { LAUNCH_STATES } from "./constants";
import { trellisPalette } from "components/custom/analytics/palettes";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { PrimaryButton } from "assets/themes/Buttons";
import generateLink from "utils/generateLink";
import ProductImage from "components/custom/category/listings/ProductImage";
import { Link } from "react-router-dom";
import api from "utils/api";

const salesStateArray = [
  SALE_STATES.CLEARANCE,
  SALE_STATES.BEST_SELLER,
  SALE_STATES.NORMAL,
  SALE_STATES.NEW_PRODUCT,
];

const LaunchRetrospectiveDialogue = ({
  productStats,
  productDetails,
  launchData,
  onUpdateStatus,
  productId,
  productState: currentProductState,
  marketPlace,
}) => {
  const showNegativePerfOptions = false;
  const showPositivePerfOptions = false;

  const [productState, setProductState] = useState(
    currentProductState === SALE_STATES.NEW_PRODUCT.value
      ? salesStateArray.find(
          (s) => s.value === productDetails?.recommended_sales_state
        )
      : salesStateArray.find((s) => s.value === currentProductState)
  );
  const [editSalesState, setEditSalesState] = useState(false);

  let {
    id,
    asin,
    sku,
    stock_status,
    product_price,
    product_url,
    product_title,
    inventory_state,
  } = productDetails;

  if (marketPlace.marketPlace === "USA") {
    product_url = product_url?.replace("usa", "com");
  }

  const onVineEnrollment = async (id) => {
    await api.patch(`/merchandise/gvproducts/${id}/`, {
      vine_program_enrolled: true,
    });
  };

  return (
    <>
      <Row
        style={{
          maxWidth: "100%",
          margin: "auto",
        }}
        className="pb-4"
      >
        <Col xs={12}>
          {launchData?.state?.toUpperCase() ===
          LAUNCH_STATES.INTRODUCED.value ? (
            <Row style={{ padding: "1rem 5rem" }}>
              <h3
                style={{
                  fontWeight: 700,
                  margin: "2rem 0",
                  fontSize: "18px",
                }}
              >
                Product Details
              </h3>
              <Col
                xs={12}
                className="card mb-0"
                style={{ padding: "3rem 4rem 1rem" }}
              >
                <Row>
                  <Col xs={2}>
                    <ProductImage id={id} />
                  </Col>
                  <Col xs={9}>
                    <h5
                      style={{
                        fontWeight: 700,
                        marginTop: "3rem",
                        paddingLeft: "1rem",
                        fontSize: "16px",
                        color: trellisPalette[13],
                      }}
                    >
                      {product_title}
                    </h5>
                  </Col>
                </Row>
                <Row
                  style={{
                    fontSize: "16px",
                    margin: "2rem 1.5rem",
                    maxWidth: "90%",
                    color: "#73706E",
                  }}
                >
                  <Col xs={6}>
                    <span style={{ fontWeight: 600 }}>ASIN:</span>
                  </Col>
                  <Col xs={6}>{asin}</Col>
                </Row>
                <Row
                  style={{
                    fontSize: "16px",
                    margin: "2rem 1.5rem",
                    maxWidth: "90%",
                    color: "#73706E",
                  }}
                >
                  <Col xs={6}>
                    <span style={{ fontWeight: 600 }}>SKU:</span>
                  </Col>
                  <Col xs={6}>{sku}</Col>
                </Row>
                <Row
                  style={{
                    fontSize: "16px",
                    margin: "2rem 1.5rem",
                    maxWidth: "90%",
                    color: "#73706E",
                  }}
                >
                  <Col xs={6}>
                    <span style={{ fontWeight: 600 }}>Price:</span>
                  </Col>
                  <Col xs={6}>
                    {formatCurrency(product_price, marketPlace.marketPlace)}
                  </Col>
                </Row>
                {stock_status && (
                  <Row
                    style={{
                      fontSize: "16px",
                      margin: "2rem 1.5rem",
                      maxWidth: "90%",
                      color: "#73706E",
                    }}
                  >
                    <Col xs={6}>
                      <span style={{ fontWeight: 600 }}>Stock Status:</span>
                    </Col>
                    <Col xs={6}>
                      {snakeCaseToSentenceCase(stock_status ?? inventory_state)}
                    </Col>
                  </Row>
                )}
                <Row
                  style={{
                    fontSize: "16px",
                    margin: "2rem 1.5rem",
                    maxWidth: "90%",
                    color: "#73706E",
                  }}
                >
                  <Col xs={6}>
                    <span style={{ fontWeight: 600 }}>Amazon Listing:</span>
                  </Col>
                  <Col xs={6}>
                    <a
                      key={id}
                      href={product_url}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      style={{
                        color: "#d91266",
                        textAlign: "center",
                        float: "center",
                        textDecoration: "none",
                        fontWeight: 600,
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                    >
                      Visit Listing
                    </a>
                  </Col>
                </Row>
                <Row
                  className="mx-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem",
                  }}
                >
                  <PrimaryButton>
                    {generateLink(
                      "listing",
                      // What is below for?
                      {
                        ...productDetails,
                        product_category: parseInt(
                          productDetails.category_name
                        ),
                      },
                      null,
                      "sku",
                      { color: "#FFF", fontWeight: 600, fontSize: "1.3rem" },
                      true,
                      "View Details"
                    )}
                  </PrimaryButton>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12} md={6}>
                <Row style={{ padding: "1rem 5rem" }}>
                  <h3
                    style={{
                      fontWeight: 700,
                      margin: "1rem 0",
                      fontSize: "18px",
                    }}
                  >
                    Product Status
                  </h3>
                  <Col
                    xs={12}
                    className="card mb-0"
                    style={{ padding: "3rem 4rem 1rem" }}
                  >
                    {currentProductState === SALE_STATES.NEW_PRODUCT.value ||
                    editSalesState ? (
                      <>
                        <h5
                          style={{
                            fontWeight: 600,
                            marginTop: 0,
                            fontSize: "16px",
                          }}
                        >
                          Recommendations
                        </h5>
                        <p
                          style={{
                            fontSize: "16px",
                            marginBottom: "2rem",
                            maxWidth: "90%",
                            color: "#73706E",
                          }}
                        >
                          Based on performance throughout the launch, Trellis is
                          recommending this product is labelled as a
                          {/* TODO: Below will be dynamic once recommended state sorted out */}
                          <strong>
                            {" "}
                            {salesStateArray?.find(
                              (s) =>
                                s.value ===
                                productDetails?.recommended_sales_state
                            )?.label ?? "Not Found"}
                          </strong>
                          .
                        </p>
                        <Row>
                          <Col xs={6}>
                            <span
                              style={{
                                fontSize: "16px",
                                padding: "1rem 0",
                                display: "block",
                                fontWeight: 600,
                              }}
                            >
                              Product Status
                            </span>
                          </Col>
                          <Col xs={6} style={{ position: "relative" }}>
                            <Select
                              value={productState}
                              options={[
                                SALE_STATES.NORMAL,
                                SALE_STATES.BEST_SELLER,
                                SALE_STATES.CLEARANCE,
                                SALE_STATES.NEW_PRODUCT,
                              ].map((state) => ({
                                label: state.label,
                                value: state.value,
                              }))}
                              onChange={(item) => {
                                setProductState(item);
                              }}
                              getOptionValue={(option) => option}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "3rem 0 1rem",
                          }}
                        >
                          <UpdateButton
                            text={"Accept Status"}
                            buttonStyles={{
                              fontSize: "14px",
                              maxWidth: "250px",
                            }}
                            onClick={() => {
                              onUpdateStatus({
                                sales_state: productState.value,
                                id: productId,
                              });
                              setEditSalesState(false);
                            }}
                          />
                        </Row>
                      </>
                    ) : (
                      <>
                        <h5
                          style={{
                            fontWeight: 600,
                            marginTop: 0,
                            fontSize: "16px",
                          }}
                        >
                          Product Launch Outcome
                        </h5>
                        <p
                          style={{
                            fontSize: "16px",
                            marginBottom: "2rem",
                            maxWidth: "90%",
                            color: "#73706E",
                          }}
                        >
                          This product has been classified as a{" "}
                          <strong>
                            {
                              salesStateArray.find(
                                (s) => s.value === currentProductState
                              )?.label
                            }
                          </strong>{" "}
                          listing.
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            marginBottom: "2rem",
                            maxWidth: "90%",
                            color: "#73706E",
                          }}
                        >
                          <span
                            onClick={() => setEditSalesState(true)}
                            style={{
                              color: "#d91266",
                              float: "center",
                              fontWeight: "500",
                              fontSize: "1.5rem",
                              cursor: "pointer",
                            }}
                          >
                            Click here
                          </span>{" "}
                          to update this status.
                        </p>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
              {(([
                SALE_STATES.BEST_SELLER.value,
                SALE_STATES.NORMAL.value,
              ]?.includes(productDetails?.recommended_sales_state) &&
                launchData.state.toUpperCase() ===
                  LAUNCH_STATES.LAUNCHED.value) ||
                showPositivePerfOptions) && (
                <Col xs={12} md={6}>
                  <Row style={{ padding: "1rem 5rem" }}>
                    <h3
                      style={{
                        fontWeight: 700,
                        margin: "1rem 0",
                        fontSize: "18px",
                      }}
                    >
                      Increase Advertising Budget
                    </h3>
                    <Col
                      xs={12}
                      className="card mb-0"
                      style={{ padding: "3rem 4rem 1rem" }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "2rem",
                          maxWidth: "90%",
                          color: "#73706E",
                        }}
                      >
                        Based on the success of this product's launch, Trellis
                        recommends increasing the advertising budget to maximize
                        placement advertising opportunities.
                      </p>

                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "3rem 0 1rem",
                        }}
                      >
                        {generateLink(
                          "advertisement",
                          {
                            ...productDetails,
                            category: parseInt(productDetails.category_name),
                          },
                          null,
                          "asin",
                          {},
                          true,
                          <UpdateButton
                            text={"View Media Plans"}
                            buttonStyles={{
                              fontSize: "14px",
                              padding: "0 3rem",
                            }}
                          />
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          )}
          <Row>
            {(([
              SALE_STATES.BEST_SELLER.value,
              SALE_STATES.NORMAL.value,
            ]?.includes(productDetails?.recommended_sales_state) &&
              launchData.state.toUpperCase() ===
                LAUNCH_STATES.LAUNCHED.value) ||
              showPositivePerfOptions) && (
              <>
                <Col xs={12} md={6}>
                  <Row style={{ padding: "1rem 5rem" }}>
                    <h3
                      style={{
                        fontWeight: 700,
                        margin: "1rem 0",
                        fontSize: "18px",
                      }}
                    >
                      Vine Program
                    </h3>
                    <Col
                      xs={12}
                      className="card mb-0"
                      style={{ padding: "3rem 4rem 1rem" }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "2rem",
                          maxWidth: "90%",
                          color: "#73706E",
                        }}
                      >
                        Based on the success of this product's launch, Trellis
                        recommends enrolling it in the Vine program to gain more
                        reviews and boost product content.
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "2rem",
                          maxWidth: "90%",
                          color: "#73706E",
                        }}
                      >
                        <a
                          href="https://www.amazon.ca/vine/about"
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            style={{
                              color: "#d91266",
                              float: "center",
                              fontWeight: "500",
                              fontSize: "1.5rem",
                              cursor: "pointer",
                            }}
                          >
                            Click here
                          </span>
                        </a>{" "}
                        to learn more about the Vine program.
                      </p>
                      {/* https://www.amazon.ca/vine/about */}
                      <Row>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "3rem 0 1rem",
                          }}
                        >
                          <UpdateButton
                            text={"Mark Enrolled"}
                            buttonStyles={{
                              fontSize: "14px",
                              maxWidth: "250px",
                            }}
                            onClick={() => {
                              onVineEnrollment(productId);
                              setEditSalesState(false);
                            }}
                          />
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
          {/* Negative performance
              -> content optimizations
              -> pricing opportuntiies 
              -> prime badge 
          */}
          <Row>
            {(([SALE_STATES.CLEARANCE.value]?.includes(
              productDetails?.recommended_sales_state
            ) &&
              launchData.state.toUpperCase() ===
                LAUNCH_STATES.LAUNCHED.value) ||
              showNegativePerfOptions) && (
              <>
                <Col xs={12} md={6}>
                  <Row style={{ padding: "1rem 5rem" }}>
                    <h3
                      style={{
                        fontWeight: 700,
                        margin: "1rem 0",
                        fontSize: "18px",
                      }}
                    >
                      Optimize Content
                    </h3>
                    <Col
                      xs={12}
                      className="card mb-0"
                      style={{ padding: "3rem 4rem 1rem" }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "2rem",
                          maxWidth: "90%",
                          color: "#73706E",
                        }}
                      >
                        To improve product performance and increase organic
                        traffic, Trellis recommends optimizing listing content.
                      </p>
                      <Row>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "3rem 0 1rem",
                          }}
                        >
                          <Link
                            to={`/user/content/seo`}
                            style={{ textDecoration: "none" }}
                          >
                            <UpdateButton
                              text={"View Product Content"}
                              buttonStyles={{ fontSize: "14px" }}
                            />
                          </Link>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <Row style={{ padding: "1rem 5rem" }}>
                    <h3
                      style={{
                        fontWeight: 700,
                        margin: "2rem 0",
                        fontSize: "18px",
                      }}
                    >
                      Optimize Price
                    </h3>
                    <Col
                      xs={12}
                      className="card mb-0"
                      style={{ padding: "3rem 4rem 1rem" }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "2rem",
                          maxWidth: "90%",
                          color: "#73706E",
                        }}
                      >
                        Optimizing this product listing's price is recommended
                        to increase profit and/or demand.
                      </p>

                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "3rem 0 1rem",
                        }}
                      >
                        <Link
                          to={`/user/pricing`}
                          style={{ textDecoration: "none" }}
                        >
                          <UpdateButton
                            text={"View Pricing Dashboard"}
                            buttonStyles={{ fontSize: "14px" }}
                          />
                        </Link>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <Row style={{ padding: "1rem 5rem" }}>
                    <h3
                      style={{
                        fontWeight: 700,
                        margin: "2rem 0",
                        fontSize: "18px",
                      }}
                    >
                      Add Prime Badge
                    </h3>
                    <Col
                      xs={12}
                      className="card mb-0"
                      style={{ padding: "3rem 4rem 1rem" }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: "2rem",
                          maxWidth: "90%",
                          color: "#73706E",
                        }}
                      >
                        Adding the Prime badge to this listing may help increase
                        traffic. The Prime badge is automatically added to a
                        listing when a product is fulfilled by Amazon.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LaunchRetrospectiveDialogue;
