import React, { useState, useMemo } from "react";
import Checkbox from "components/core/basic/CheckBox";
import { Row, Col } from "react-bootstrap";
import toggleArrayEntry from "utils/toggleArrayEntry";
import ReactTable from "react-table-6";
import ReactTablePagination from "../../../custom/growth/ProductsCVTablePagination";
import { provisionArray } from "utils/provisonArray";
import ToolTips from "utils/hiddenToolTips";
import useDebounce from "hooks/useDebounce";
import { useFetch } from "hooks/api";
import { useMutation } from "react-query";
import api from "utils/api";
import FormGroupTile from "components/core/basic/FormGroupTile";
import useColumnSelector from "hooks/useColumnSelector";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import Loading from "../Loading";
import { AD_TYPES, SD_CAMPAIGN_TYPE_OPTIONS } from "./constants";
import logo from "assets/images/logo/Trellis_Logomark_color.png";

const EMPTY_ARRAY = [];

const EditCell = (props) => {
  const { id } = props.original ?? {};
  let defaultValue = props.original[props.column.id] ?? "";
  const disabled = props.original.savedToServer ?? false;

  if (props.column.format === "checkbox") {
    const { id } = props.original ?? {};

    return (
      <Checkbox
        inline
        number={`${props.index}${id}PrimeEligible`}
        label={null}
        checked={defaultValue}
        disabled={disabled}
        onClick={(e) => {
          props.column.handleUpdate(
            id,
            props.column.id,
            !props.original[props.column.id],
            props.index
          );
        }}
      />
    );
  }

  if (props.column.format === "select") {
    defaultValue =
      typeof props.original.brand === "object"
        ? props.original.brand
        : {
            label: props.original.brand_name,
            value: props.original.brand,
          };

    return (
      <>
        <FormGroupTile
          type="select"
          multi={false}
          stateName={props.stateName}
          handleChildFormElement={(key, value) => {
            props.column.handleUpdate(id, props.column.id, value, props.index);
          }}
          disabled={disabled}
          search={
            props.column.search?.rowIndex === props.index
              ? props.column.search?.value
              : null
          }
          setSearch={(value) => {
            props.column.setSearch({ rowIndex: props.index, value: value });
          }}
          defaultValue={defaultValue}
          isLoading={
            props.column.search?.rowIndex === props.index &&
            props.column.selectOptionsLoading
          }
          options={
            props.column.search?.rowIndex === props.index
              ? props.column.options ?? []
              : []
          }
          placeholder={props.column.searchPlaceholder ?? "Search..."}
        />
      </>
    );
  }

  return (
    <>
      <FormGroupTile
        type="formcontrol"
        decimalInput={
          props.column.format === "currency" || props.column.format === "number"
        }
        stateName={props.stateName}
        handleChildFormElement={(key, value) => {
          if (
            props.column.format === "number" ||
            props.column.format === "currency"
          ) {
            if (/^\d*\.?\d*$/.test(value) || value === "") {
              props.column.handleUpdate(
                id,
                props.column.id,
                value,
                props.index
              );
            }
            return;
          }
          props.column.handleUpdate(id, props.column.id, value, props.index);
        }}
        formControlClass="no-arrows text-center"
        defaultValue={defaultValue ?? null}
        disabled={disabled}
        placeholder={
          props.column.format === "currency"
            ? `${MARKETPLACE_CURRENCY_DENOMINATION[props.column.marketPlace]}`
            : null
        }
      />
    </>
  );
};

const TargetCategories = ({
  review = false,
  state,
  onChange,
  marketPlace,
  errors,
  isModal,
  channel,
  products,
}) => {
  const [categorySearchFilter, setCategorySearchFilter] = useState("");
  const [categoriesFromAmazon, setCategoriesFromAmazon] = useState(
    state.filledTargetCategories ?? []
  );
  const [brandSearchFilter, setBrandSearchFilter] = useState("");
  const [brandsFromAmazon, setBrandsFromAmazon] = useState([]);

  const [colToggle] = useState();

  const debouncedCategorySearch = useDebounce(categorySearchFilter, 300);
  const debouncedBrandSearch = useDebounce(brandSearchFilter, 300);

  const [recommendedCategorySearchFilter, setRecommendedCategorySearchFilter] =
    useState("");
  const [recommendedCategoriesFromAmazon, setRecommendedCategoriesFromAmazon] =
    useState([]);
  const [startsWithFilter, setStartsWithFilter] = useState();

  const getRecommendedCategoryData = useMutation(
    async (productData) => {
      return await api.post("/gvads/recommendedcategories/", productData);
    },
    {
      enabled: state.sdAmazonSuggestedCategories,
      default: [],
      onSuccess: (res) => {
        setRecommendedCategoriesFromAmazon(res.data);
      },
    }
  );

  useMemo(() => {
    if (!state.sdAmazonSuggestedCategories) {
      return;
    }
    getRecommendedCategoryData.mutate({
      // Products should be ASINs, not ids
      asins: products
        .filter((p) => state.product.includes(p.value))
        .map((p) => p.asin),
      marketplace: marketPlace?.marketPlace,
      tactic_type:
        state.sdCampaignType === SD_CAMPAIGN_TYPE_OPTIONS.AUDIENCE.value
          ? "T00030"
          : "T00020",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sdAmazonSuggestedCategories]);

  let recommendedCategoryData = useMemo(() => {
    return provisionArray(
      recommendedCategoriesFromAmazon
        ?.filter((category) =>
          recommendedCategorySearchFilter.length
            ? category.category_name
                ?.toLowerCase()
                ?.indexOf(recommendedCategorySearchFilter.toLowerCase()) > -1
            : startsWithFilter && startsWithFilter !== "all"
            ? category.category_name?.toLowerCase()[0] ===
              startsWithFilter[0].toLowerCase()
            : category
        )
        .sort((a, b) => a.category_name.localeCompare(b.category_name)),
      2
    );
  }, [
    recommendedCategoriesFromAmazon,
    recommendedCategorySearchFilter,
    startsWithFilter,
  ]);

  const { data, isLoading: isCategoryDataLoading } = useFetch(
    ["amazonCategories", "marketplace", debouncedCategorySearch],
    "/api/category/search",
    {
      category: categorySearchFilter?.length > 0 ? categorySearchFilter : null,
      marketplace: marketPlace.marketPlace,
    },
    {
      default: [],
      enabled: !!debouncedCategorySearch,
      select: (res) => res.data.results,
      onSuccess: (res) => {
        // Below retains name and id mappings for all retrieved and potentially selected categories
        // Note that set is by id, not category name, as there are cases where the same name is assigned to different category IDs

        if (
          categorySearchFilter !== "" &&
          isNaN(categorySearchFilter) !== true
        ) {
          res.push({
            name: categorySearchFilter,
            id: categorySearchFilter,
            path: "Unknown",
          });
        }

        setCategoriesFromAmazon((prevCategories) => [
          ...new Set([...prevCategories, ...res]),
        ]);
      },
    }
  );

  let categoryData = useMemo(() => {
    return data
      ? provisionArray(
          data?.sort((a, b) => a.name.localeCompare(b.name)),
          2
        )
      : [];
  }, [data]);

  const getUpdatedCategories = (id) => {
    let updatedCategorySelections = toggleArrayEntry(
      parseInt(id),
      state.filledTargetCategories.map((c) => parseInt(c?.id))
    )
      ?.map((id) => {
        return [
          ...categoriesFromAmazon,
          ...recommendedCategoriesFromAmazon?.map((c) => ({
            name: c.category_name,
            id: c.category_id,
          })),
        ].find((c) => parseInt(c.id) === parseInt(id));
      })
      ?.map((c) => {
        const otherCategoryData = state.filledTargetCategories.find(
          (category) => category?.id === c?.id
        );
        return { ...otherCategoryData, id: c.id, name: c.name, path: c.path };
      });

    return updatedCategorySelections;
  };

  const checkCategoryEditable = (id) => {
    if (
      state.id &&
      state.originalFilledTargetCategories
        .map((c) => parseInt(c.id))
        .includes(parseInt(id))
    ) {
      return false;
    }

    return true;
  };

  const handleCategoryTargetUpdate = (id, key, value, index = null) => {
    const updatedCategoryTargets = state.filledTargetCategories;
    updatedCategoryTargets.find((c, i) => {
      return c.id === id && i === index;
    })[key] = value;
    onChange({
      filledTargetCategories: updatedCategoryTargets,
    });
  };

  const getBrandSearchResults = useMutation(
    async (searchData) => {
      if (!searchData.search_value) {
        return [];
      }

      return await api.post("/api/brands/search/", searchData);
    },
    {
      select: (res) => res.data,
      enabled: true,
      default: [],
      onSuccess: (res) => {
        if (!res.data) {
          return;
        }
        const newBrandOptions = JSON.parse(res.data).map((o) => ({
          label: o.name,
          value: o.id,
        }));
        setBrandsFromAmazon(newBrandOptions);
      },
    }
  );

  useMemo(() => {
    getBrandSearchResults.mutate({
      search_value: brandSearchFilter?.value,
      marketplace: marketPlace?.marketPlace,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBrandSearch?.value]);

  const [targetColumns] = useColumnSelector(() => {
    const columns = [
      {
        id: "asin_category_same_as",
        Header: "Category",
        width: 190,
        style: {
          textAlign: "left",
          color: "rgb(115, 112, 110)",
          cursor: "pointer",
        },
        Cell: (props) => {
          const { name, path, savedToServer, id } = props.original ?? {};
          return (
            <div>
              <div className="position-relative overflow-ellipsis">
                <ToolTips toolTip={path} position={"top"} width={"100%"} />
                {name ?? id}
              </div>

              <div className="d-flex">
                {!savedToServer && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      const categoryTargets = state.filledTargetCategories;

                      categoryTargets.splice(props.index, 1);

                      onChange({ filledTargetCategories: categoryTargets });
                    }}
                    style={{
                      border: "none",
                      borderRadius: "10000000000px",
                      color: "#888",
                      background: "#FFF",
                      cursor: "pointer",
                      fontSize: "1.6rem",
                      position: "relative",
                      marginRight: "10px",
                    }}
                  >
                    <ToolTips
                      toolTip={"Remove Category Target"}
                      position={"top"}
                      width={"100%"}
                    />
                    <i className="fa fa-trash" />
                  </button>
                )}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    const categoryTargets = state.filledTargetCategories;
                    let { name, id, path, rowIndex } = props.original;

                    if (isNaN(rowIndex)) {
                      rowIndex = props.index;
                    }

                    const clonedRow = {
                      name,
                      path,
                      id,
                    };

                    categoryTargets.push(clonedRow);

                    setBrandSearchFilter((prev) => ({
                      ...prev,
                      // Below avoids search being associated with wrong target row index after cloning
                      rowIndex: rowIndex + 1,
                    }));

                    onChange({ filledTargetCategories: categoryTargets });
                  }}
                  style={{
                    border: "none",
                    borderRadius: "10000000000px",
                    color: "#888",
                    background: "#FFF",
                    cursor: "pointer",
                    fontSize: "1.6rem",
                    position: "relative",
                  }}
                >
                  <ToolTips
                    toolTip={"Copy Category Target"}
                    position={"top"}
                    width={"100%"}
                  />
                  <i className="fa fa-clone" />
                </button>
              </div>
            </div>
          );
        },
        accessor: "name",
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
      {
        id: "brand",
        accessor: "brand",
        Header: "Brand",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        width: 190,
        Cell: EditCell,
        handleUpdate: handleCategoryTargetUpdate,
        onChange: onChange,
        state: state,
        search: brandSearchFilter,
        setSearch: (value) => {
          setBrandSearchFilter(value);
        },
        searchPlaceholder: "Search Brands...",
        options: brandsFromAmazon,
        selectOptionsLoading: getBrandSearchResults.isLoading,
        format: "select",
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
        style: { overflow: "visible" },
      },
      {
        id: "asin_price_greater_than",
        accessor: "asin_price_greater_than",
        Header: "Price Greater Than",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        width: 90,
        Cell: EditCell,
        handleUpdate: handleCategoryTargetUpdate,
        format: "currency",
        marketPlace: marketPlace.marketPlace,
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
      {
        id: "asin_price_less_than",
        accessor: "asin_price_less_than",
        Header: "Price Less Than",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        width: 90,
        Cell: EditCell,
        handleUpdate: handleCategoryTargetUpdate,
        format: "currency",
        marketPlace: marketPlace.marketPlace,
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
      {
        id: "asin_review_rating_greater_than",
        accessor: "asin_review_rating_greater_than",
        Header: "Rating Greater Than",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        width: 90,
        format: "number",
        Cell: EditCell,
        handleUpdate: handleCategoryTargetUpdate,
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
      {
        id: "asin_review_rating_less_than",
        accessor: "asin_review_rating_less_than",
        Header: "Rating Less Than",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        width: 90,
        format: "number",
        Cell: EditCell,
        handleUpdate: handleCategoryTargetUpdate,
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
      {
        id: "asin_prime_eligible",
        accessor: "asin_prime_eligible",
        Header: "Prime Eligible",
        headerClassName: "wordwrap",
        headerStyle: { textAlign: "center" },
        style: { marginTop: "-3px", marginBottom: 0, paddingLeft: ".75rem" },
        width: 60,
        format: "checkbox",
        checked: ![
          AD_TYPES.SPONSORED_BRAND.value,
          AD_TYPES.SPONSORED_BRAND_V2.value,
        ].includes(state.adType),
        Cell: EditCell,
        handleUpdate: handleCategoryTargetUpdate,
        filterMethod: (filter, row) => true,
        Filter: ({ filter, onChange }) => null,
      },
    ];
    return columns;
  }, [colToggle]);

  const toggleRecommendedCategories = (category) => {
    let updatedCategories;

    if (state.filledTargetCategories?.map((c) => c.id)?.includes(category.id)) {
      updatedCategories = state.filledTargetCategories.filter(
        (c) => c.id !== category.id
      );
    } else {
      updatedCategories = [...state.filledTargetCategories, category];
    }

    return updatedCategories;
  };

  if (
    ![
      AD_TYPES.SPONSORED_PRODUCT.value,
      AD_TYPES.SPONSORED_DISPLAY_V2.value,
      AD_TYPES.SPONSORED_BRAND.value,
      AD_TYPES.SPONSORED_BRAND_V2.value,
    ].includes(state.adType) ||
    channel !== "amazon" ||
    state.parent ||
    state.canHaveChildren
  ) {
    return null;
  }

  if (!state.id && review) {
    return null;
  }

  return (
    <div
      className="my-4 w-100"
      style={{
        padding:
          state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value ? 0 : "0 2rem",
      }}
    >
      {state.sdAmazonSuggestedCategories && isModal && (
        <Row style={{ marginTop: "1rem" }}>
          <h3
            style={{
              fontWeight: 560,
              fontSize: "1.6rem",
              color: "#73706E",
              paddingTop: review ? 0 : "1rem",
            }}
          >
            Select{review ? "ed Amazon Suggested" : " Suggested"} Categories:
          </h3>

          <Col xs={12} className="pr-5" style={{ margin: "none" }}>
            <>
              {getRecommendedCategoryData.isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px",
                  }}
                >
                  <img
                    src={logo}
                    alt=""
                    className="rotate"
                    width="80"
                    height="auto"
                  />
                </div>
              ) : (
                <ReactTable
                  showPaginationTop={true}
                  showPaginationBottom={false}
                  showPageSizeOptions={false}
                  defaultPageSize={5}
                  // Below is needed to eliminate the no data message that covers table headers if no data to render
                  NoDataComponent={() => (
                    <Row>
                      <p
                        className="text-center"
                        style={{
                          color: "#73706E",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >
                        No categories found
                      </p>
                    </Row>
                  )}
                  minRows={0}
                  columns={[
                    {
                      id: "select",
                      Header: "",
                      style: {
                        textAlign: "center",
                        fontWeight: 500,
                        minHeight: "60px",
                      },
                      Cell: (props) => {
                        let categoryId = props.original?.[0]?.category_id;
                        return (
                          <Checkbox
                            inline
                            number={`amazonSuggestedCategory-${
                              1 * Math.random().toString()
                            }-${review ? "Review" : "Edit"}`}
                            checked={state.filledTargetCategories
                              ?.map((c) => c.id)
                              ?.includes(categoryId)}
                            disabled={review}
                            disablePointerEvents={review}
                            onClick={(e) => {
                              const { category_name: name, category_id: id } =
                                props.original[0];
                              let updatedCategories =
                                toggleRecommendedCategories({ id, name });

                              onChange({
                                filledTargetCategories: updatedCategories,
                              });
                            }}
                          />
                        );
                      },
                      width: 60,
                      accessor: "metric",
                      filterMethod: (filter, row) => true,
                      Filter: ({ filter, onChange }) => null,
                    },
                    {
                      id: "category",
                      Header: "",
                      style: {
                        textAlign: "left",
                        color: "rgb(115, 112, 110)",
                        cursor: "pointer",
                      },
                      Cell: (props) => {
                        let { category_name: name, category_id: id } =
                          props.original?.[0] ?? {};
                        return (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              let updatedCategories =
                                toggleRecommendedCategories({
                                  id,
                                  name,
                                });

                              onChange({
                                filledTargetCategories: updatedCategories,
                              });
                            }}
                          >
                            {name}
                          </div>
                        );
                      },
                      accessor: "[0].name",
                      filterMethod: (filter, row) => true,
                      Filter: ({ filter, onChange }) => null,
                    },
                    {
                      id: "select",
                      Header: "",
                      headerClassName: "wordwrap",
                      style: {
                        textAlign: "center",
                        fontWeight: 500,
                        minHeight: "60px",
                      },
                      Cell: (props) => {
                        let categoryId = props.original?.[1]?.category_id;
                        // In case of filtered recommendations, dont render checkbox if odd number of filter results
                        if (!categoryId) {
                          return null;
                        }
                        return (
                          <Checkbox
                            inline
                            number={`amazonSuggestedCategory-${
                              1 * Math.random().toString()
                            }-${review ? "Review" : "Edit"}`}
                            checked={state.filledTargetCategories
                              ?.map((c) => c.id)
                              ?.includes(categoryId)}
                            disabled={review}
                            disablePointerEvents={review}
                            onClick={(e) => {
                              const { category_name: name, category_id: id } =
                                props.original[1];
                              let updatedCategories =
                                toggleRecommendedCategories({
                                  id,
                                  name,
                                });

                              onChange({
                                filledTargetCategories: updatedCategories,
                              });
                            }}
                          />
                        );
                      },
                      width: 60,
                      accessor: "metric",
                      filterMethod: (filter, row) => true,
                      Filter: ({ filter, onChange }) => null,
                    },
                    {
                      id: "category",
                      Header: "",
                      headerClassName: "wordwrap",
                      style: {
                        textAlign: "left",
                        color: "rgb(115, 112, 110)",
                        cursor: "pointer",
                      },
                      Cell: (props) => {
                        let { category_name: name, category_id: id } =
                          props.original?.[1] ?? {};
                        return (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              let updatedCategories =
                                toggleRecommendedCategories({ id, name });

                              onChange({
                                filledTargetCategories: updatedCategories,
                              });
                            }}
                          >
                            {name}
                          </div>
                        );
                      },
                      accessor: "[1].name",
                      filterMethod: (filter, row) => true,
                      Filter: ({ filter, onChange }) => null,
                    },
                  ]}
                  PaginationComponent={ReactTablePagination}
                  getPaginationProps={() => ({
                    updateSearch: setRecommendedCategorySearchFilter,
                    clearFilters: () => {
                      setRecommendedCategorySearchFilter("");
                    },
                    hideFilters: true,
                    clearSearchTermFilters: () =>
                      setRecommendedCategorySearchFilter(""),
                    searchTerm: recommendedCategorySearchFilter,
                    table: "recommended-category-options",
                    style: { marginBottom: "1rem" },
                    carouselLayout: false,
                    disableSearchFocus: true,
                    searchWidth: "280px",
                    additionalFilterRow: (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            fontWeight: 400,
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#777",
                          }}
                        >
                          {[
                            "Show All",
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H",
                            "I",
                            "J",
                            "K",
                            "L",
                            "M",
                            "N",
                            "O",
                            "P",
                            "Q",
                            "R",
                            "S",
                            "T",
                            "U",
                            "V",
                            "W",
                            "X",
                            "Y",
                            "Z",
                          ].map((char) => (
                            <div
                              onClick={() =>
                                setStartsWithFilter(
                                  char === "Show All" ? "all" : char
                                )
                              }
                              style={{
                                display: categoriesFromAmazon?.some(
                                  (category) =>
                                    category.category_name?.toLowerCase()[0] ===
                                      char.toLowerCase() || char === "Show All"
                                )
                                  ? "inherit"
                                  : "none",
                                background:
                                  char === startsWithFilter ||
                                  (startsWithFilter === "all" &&
                                    char === "Show All")
                                    ? "#EEE"
                                    : "inherit",
                                padding: "2px 4px",
                                textAlign: "center",
                                borderRadius: ".4rem",
                              }}
                            >
                              {char}
                            </div>
                          ))}
                        </div>
                      </>
                    ),
                  })}
                  style={{
                    borderRadius: "15px",
                    border: "none",
                    boxShadow: "none",
                  }}
                  data={recommendedCategoryData ?? EMPTY_ARRAY}
                  filterable={false}
                  sortable={false}
                />
              )}
            </>
          </Col>
        </Row>
      )}

      {state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value &&
      !state.sdCampaignType ? (
        <></>
      ) : (
        <>
          <h3
            style={
              state.adType === AD_TYPES.SPONSORED_DISPLAY_V2.value
                ? {
                    fontWeight: 560,
                    fontSize: "1.6rem",
                    color: "#73706E",
                    paddingTop: review ? 0 : "1rem",
                  }
                : {
                    fontWeight: 700,
                    fontSize: "14px",
                    color: "#73706E",
                  }
            }
          >
            Search for Category Targets:
          </h3>
          <div className="pr-5 ">
            <ReactTable
              showPaginationTop={true}
              showPaginationBottom={false}
              showPageSizeOptions={false}
              defaultPageSize={5}
              loading={isCategoryDataLoading}
              LoadingComponent={() => {
                return isCategoryDataLoading ? (
                  <Loading fullPage={false} height={"220px"} />
                ) : (
                  <></>
                );
              }}
              // Below is needed to eliminate the no data message that covers table headers if no data to render
              NoDataComponent={() => (
                <Row>
                  <p
                    className="text-center"
                    style={{
                      color: "#73706E",
                      fontWeight: "700",
                      marginTop: "10px",
                    }}
                  >
                    {categorySearchFilter?.length && !isCategoryDataLoading
                      ? "No categories found"
                      : null}
                  </p>
                </Row>
              )}
              minRows={0}
              columns={[
                {
                  id: "select",
                  Header: "",
                  style: {
                    textAlign: "center",
                    fontWeight: 500,
                    minHeight: "60px",
                  },
                  Cell: (props) => {
                    const categoryId = parseInt(props.original?.[0]?.id);
                    return (
                      <Checkbox
                        inline
                        number={`amazonSuggestedCategory-${
                          1 * Math.random().toString()
                        }-${review ? "Review" : "Edit"}`}
                        checked={state.filledTargetCategories
                          ?.map((c) => parseInt(c.id))
                          ?.includes(parseInt(categoryId))}
                        disabled={!checkCategoryEditable(categoryId)}
                        onClick={(e) => {
                          if (!checkCategoryEditable(categoryId)) {
                            return;
                          }
                          onChange({
                            filledTargetCategories:
                              getUpdatedCategories(categoryId),
                          });
                        }}
                      />
                    );
                  },
                  width: 60,
                  accessor: "metric",
                  filterMethod: (filter, row) => true,
                  Filter: ({ filter, onChange }) => null,
                },
                {
                  id: "category",
                  Header: "",
                  style: {
                    textAlign: "left",
                    color: "rgb(115, 112, 110)",
                    cursor: "pointer",
                  },
                  Cell: (props) => {
                    const { name, id, path } = props.original?.[0] ?? {};
                    return (
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (!checkCategoryEditable(id)) {
                            return;
                          }

                          onChange({
                            filledTargetCategories: getUpdatedCategories(id),
                          });
                        }}
                      >
                        <span className="position-relative">
                          <ToolTips
                            toolTip={path}
                            position={"top"}
                            width={"100%"}
                          />
                          {name}
                        </span>
                      </div>
                    );
                  },
                  accessor: "[0].name",
                  filterMethod: (filter, row) => true,
                  Filter: ({ filter, onChange }) => null,
                },
                {
                  id: "select",
                  Header: "",
                  headerClassName: "wordwrap",
                  style: {
                    textAlign: "center",
                    fontWeight: 500,
                    minHeight: "60px",
                  },
                  Cell: (props) => {
                    const categoryId = parseInt(props.original?.[1]?.id);
                    // In case of filtered recommendations, dont render checkbox if odd number of filter results
                    if (!categoryId) {
                      return null;
                    }
                    return (
                      <Checkbox
                        inline
                        number={`amazonSuggestedCategory-${
                          1 * Math.random().toString()
                        }-${review ? "Review" : "Edit"}`}
                        checked={state.filledTargetCategories
                          ?.map((c) => parseInt(c.id))
                          ?.includes(parseInt(categoryId))}
                        disabled={!checkCategoryEditable(categoryId)}
                        onClick={(e) => {
                          if (!checkCategoryEditable(categoryId)) {
                            return;
                          }

                          onChange({
                            filledTargetCategories:
                              getUpdatedCategories(categoryId),
                          });
                        }}
                      />
                    );
                  },
                  width: 60,
                  accessor: "metric",
                  filterMethod: (filter, row) => true,
                  Filter: ({ filter, onChange }) => null,
                },
                {
                  id: "category",
                  Header: "",
                  headerClassName: "wordwrap",
                  style: {
                    textAlign: "left",
                    color: "rgb(115, 112, 110)",
                    cursor: "pointer",
                  },
                  Cell: (props) => {
                    const { name, id, path } = props.original?.[1] ?? {};
                    return (
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (!checkCategoryEditable(id)) {
                            return;
                          }

                          onChange({
                            filledTargetCategories: getUpdatedCategories(id),
                          });
                        }}
                      >
                        <span className="position-relative">
                          <ToolTips
                            toolTip={path}
                            position={"top"}
                            width={"100%"}
                          />
                          {name}
                        </span>
                      </div>
                    );
                  },
                  accessor: "[1].name",
                  filterMethod: (filter, row) => true,
                  Filter: ({ filter, onChange }) => null,
                },
              ]}
              PaginationComponent={ReactTablePagination}
              getPaginationProps={() => ({
                updateSearch: setCategorySearchFilter,
                clearFilters: () => {
                  setCategorySearchFilter("");
                },
                hideFilters: true,
                clearSearchTermFilters: () => setCategorySearchFilter(""),
                searchTerm: categorySearchFilter,
                table: "recommended-category-options",
                style: { marginBottom: "1rem" },
                carouselLayout: false,
                disableSearchFocus: true,
                searchWidth: "280px",
              })}
              style={{
                borderRadius: "15px",
                border: "none",
                boxShadow: "none",
              }}
              data={categoryData ?? EMPTY_ARRAY}
              filterable={false}
              sortable={false}
            />
          </div>
        </>
      )}
      {state.filledTargetCategories?.length > 0 && (
        <>
          {review ? (
            <h3
              style={{
                fontWeight: 560,
                fontSize: "1.6rem",
                color: "#73706E",
                marginTop: isModal ? "3rem" : 0,
              }}
            >
              Category Targets
            </h3>
          ) : (
            <h6
              className="title"
              style={{
                color: "#73706E",
                fontWeight: 560,
                fontSize: "16px",
                textTransform: "none",
                margin: "1rem 0",
              }}
            >
              Category Targets (Optional)
              <ToolTips
                toolTip={
                  "Add additional Amazon category IDs you wish to target in your advertisement"
                }
                position="top"
                id={"AD_AMAZON_CATEGORY_IDS_TAGS_TOOLTIP"}
              />
            </h6>
          )}
          <ReactTable
            className="pr-5"
            showPaginationTop={true}
            showPaginationBottom={false}
            showPageSizeOptions={false}
            // Below is needed to eliminate the no data message that covers table headers if no data to render
            NoDataComponent={() => (
              <Row>
                <p
                  className="text-center"
                  style={{
                    color: "#73706E",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                >
                  {categorySearchFilter?.length ? "No categories found" : null}
                </p>
              </Row>
            )}
            minRows={0}
            columns={targetColumns}
            PaginationComponent={ReactTablePagination}
            getPaginationProps={() => ({
              updateSearch: setCategorySearchFilter,
              clearFilters: () => {
                setCategorySearchFilter("");
              },
              hideFilters: true,
              clearSearchTermFilters: () => setCategorySearchFilter(""),
              searchTerm: categorySearchFilter,
              table: "category-targetting-params",
              style: { marginBottom: "1rem" },
              carouselLayout: false,
              disableSearchFocus: true,
              searchWidth: "280px",
              hideSearch: true,
              singlePage: true,
            })}
            getTbodyProps={() => {
              return {
                style: {
                  paddingBottom:
                    state?.filledTargetCategories?.length > 0 ? "150px" : 0,
                },
              };
            }}
            getTdProps={(state, rowInfo, column) => {
              const { id } = column;

              return {
                style: id === "brand" ? { overflow: "visible" } : {},
              };
            }}
            style={{
              borderRadius: "15px",
              border: "none",
              boxShadow: "none",
            }}
            data={state.filledTargetCategories ?? EMPTY_ARRAY}
            filterable={false}
            sortable={false}
          />
        </>
      )}
    </div>
  );
};

export default TargetCategories;
