import React, { useState } from "react";
import { Container as Grid, Tab, Tabs } from "react-bootstrap";
import "assets/css/dot.css";
const BasicCarousel = ({
  data,
  hide,
  justify = false,
  noBoxShadow,
  fontSize = "20px",
}) => {
  const [activeTab, setActiveTab] = useState(data[0][0].toLowerCase());

  return (
    <Grid fluid className={noBoxShadow ? "card-no-shadow" : "card"}>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        id="uncontrolled-tab-example"
        className="pt-5 px-5 mx-5"
        // Below handles selection on tab item click, passes eventKey value by default as event
        onSelect={(tab) => setActiveTab(tab)}
        // Attribute below events whitespace automatically for consistency
        justify={justify}
      >
        {data
          .filter((item, idx) => !hide || (hide && hide.indexOf(idx) < 0))
          .map((tab, index) => {
            return (
              <Tab
                eventKey={tab[0].toLowerCase()}
                title={
                  <span
                    style={{
                      fontSize: fontSize,
                      fontWeight: 500,
                      color: "#3E3D40",
                      whiteSpace: "nowrap",
                      width: "auto",
                    }}
                    className="my-3 rounded-top"
                  >
                    {tab[0]}
                  </span>
                }
                // Below allows empty carousel element to be added for desired layout (i.e. with justify, allows
                // option to prevent one massive nav tab)
                disabled={!tab[0]?.length}
                className="pt-6 px-5"
              >
                <div className="pt-5">{tab[1]}</div>
              </Tab>
            );
          })}
      </Tabs>
    </Grid>
  );
};

export default BasicCarousel;
