import React, { useState, useMemo, useContext, useEffect, useRef } from "react";
import { AdPlanContext } from "components/core/blocks/AdPlan/AdPlanContext";
import { useMediaQuery } from "@react-hook/media-query";
import { useFetch } from "hooks/api";
import { Col, Container as Grid, Row, Nav, Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams, useLocation, Redirect } from "react-router-dom";
import moment from "moment";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import Loading from "components/core/blocks/Loading";
import getMetricSpec from "views/merchandise/metrics";
import { selectModules } from "redux/selectors/modules";
import { snakeCaseToSentenceCase } from "utils/formatText";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import { getTabsFromAdType } from "utils/getTabs";
import AdvertisementProducts from "components/custom/category/advertisements/AdvertisementProducts";

import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import acosOrRoas from "utils/acosOrRoas";
import DetailedMetric from "components/core/blocks/DetailedMetric";

import {
  AD_TYPES,
  STATUS,
  GOALS,
  MODERATION_STATUSES,
} from "components/core/blocks/AdPlan/constants";
import useKeywordReport from "hooks/useKeywordReport";
import useCategories from "hooks/useCategories";
import useAds from "hooks/useAds";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import checkModule from "utils/checkModule";
import useAdPlanState from "components/core/blocks/AdPlan/useAdPlanState";
import NTMStatusWidget from "components/core/blocks/AdPlan/NTMStatusWidget";
import { displayAdStatus } from "utils/displayAdStatus";
import SettingsSection from "./SettingsSection";
import MicroStores2 from "components/custom/category/advertisements/MicroStores2";
import SearchTermReport from "components/core/blocks/AdPlan/SearchTermReport";
import AdTags from "./AdTags";
import NotificationSystem from "react-notification-system";

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  return metric;
};

const getDashboardTo = ({ type, id }) => {
  let to = `/user/category`;

  if (!id || type === "dashboard") {
    to = `/user/advertising/dashboard`;
  } else {
    if (type === "category") {
      to = `user/advertising/dashboard/category/${id}`;
    }
    if (type === "advertisement") {
      to = `/user/advertising/dashboard/ads/${id}`;
    }
  }

  return to;
};

const Card = ({
  title,
  start,
  end,
  data,
  isLoading,
  metrics,
  initialSelectedMetric,
  children,
  channel,
  marketPlace,
  user,
  adId,
}) => {
  const { group } = useDates();
  const notificationRef = useRef();

  const { activeAdCount } = useContext(AdPlanContext);
  const initialAdCreated = user.ad_count || activeAdCount;

  const adPlanData = data?.adplans?.[0];
  const { ad_name } = adPlanData ?? "";

  return (
    <>
      <NotificationSystem ref={notificationRef} />;
      <MetricsCard
        id={"advertisingDetails"}
        mobileStyle
        titleElement={
          <div className="d-flex">
            <h3 className="me-5">
              {ad_name} ({adId})
            </h3>
            <AdTags adPlanData={adPlanData} notificationRef={notificationRef} />
          </div>
        }
        data={data?.date}
        initialSelectedMetric={initialSelectedMetric}
        metrics={metrics}
        group={group}
        isLoading={isLoading}
        showMetricsSelector={true}
        hideMetrics={!initialAdCreated}
        datesFormatted={true}
        showAnnotations={true}
        selectedAd={adId}
        selectedCategory={adPlanData?.category}
        byline={
          initialAdCreated ? (
            <>
              {start.format("MMMM Do YYYY")}
              {" - "}
              {end.format("MMMM Do YYYY")}
              {isLoading && <i className="fa fa-spinner fa-spin"></i>}
            </>
          ) : null
        }
      >
        {children}
      </MetricsCard>
    </>
  );
};

const Dashboard = ({ marketPlace, user, category, modules, channel, adId }) => {
  const [tab, setTab] = useState("products");

  const mobileStyle = useMediaQuery("(max-width:600px)");
  const { group, date, start, end, preStart, preEnd } = useDates();
  const { showNewAdvertisingDashboard } = useMerchandisePreferences();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    channel: channel,
    empty: true,
    ad: adId,
  };

  const { data, isLoading, isError, isSuccess, isFetching } = useFetch(
    [
      "ad_report",
      marketPlace.marketPlace,
      filters.start_date,
      filters.end_date,
      filters.pre_start_date,
      filters.pre_end_date,
      group,
      adId,
    ],
    `/api/data_report/ad_report`,
    { ...filters },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      default: [],
      staleTime: 2 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  const adPlanData = data?.adplans?.[0];

  const {
    ad_type,
    category: categoryId,
    is_composite,
    days_active,
    ad_goal,
    created_datetime,
  } = adPlanData ?? "";

  const { preferences } = useMerchandisePreferences();

  const {
    adPlan: adPlanParams,
    onChange,
    products,
  } = useAdPlanState(
    {},
    marketPlace,
    adPlanData?.category,
    adPlanData?.id,
    preferences.acos_or_roas === "roas",
    user.organization_type,
    true
  );

  const { parent } = adPlanParams;

  let { currentCategoryDetails: categoryDetails, categories: categoryData } =
    useCategories(categoryId);

  let planDetailsTabs = getTabsFromAdType(
    is_composite ? AD_TYPES.CUSTOM.value : ad_type,
    channel,
    ad_goal,
    modules,
    is_composite || parent,
    categoryDetails?.leaf_category_name === "Unmanaged"
  );

  const groupedTabs = planDetailsTabs?.reduce((result, item) => {
    const group = item.group;
    result[group] = result[group] || [];
    result[group].push(item);
    return result;
  }, {});

  const baseTargetKeys = [
    marketPlace.marketPlace,
    start.format("YYYY-MM-DD"),
    end.format("YYYY-MM-DD"),
    preStart.format("YYYY-MM-DD"),
    preEnd.format("YYYY-MM-DD"),
    group,
    adId,
  ];

  const { keywordData, isKeywordDataLoading, isKeywordStatsLoading } =
    useKeywordReport(baseTargetKeys, adId, filters);

  // Target table data
  const { data: targetData, isLoading: isTargetDataLoading } = useFetch(
    [...baseTargetKeys, "keyword_report", "target"],
    `/api/data_report/keyword_report`,
    { ...filters, kw_report: false, target_type: "target" },
    {
      select: (d) => d.data,
      enabled: !!planDetailsTabs?.map((t) => t.key)?.includes("targets"),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
    }
  );

  // Placements table data
  const { data: adPlacementData, isLoading: isAdPlacementDataLoading } =
    useFetch(
      [...baseTargetKeys, "keyword_report", "placements"],
      `/api/data_report/keyword_report`,
      { ...filters, kw_report: false, target_type: "locations" },
      {
        select: (d) => d.data,
        enabled: !!planDetailsTabs?.map((t) => t.key)?.includes("placements"),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 2 * 60 * 1000,
      }
    );

  // Placements table data
  const { data: adItemData, isLoading: isAdItemDataLoading } = useFetch(
    [...baseTargetKeys, "keyword_report", "adItems"],
    `/api/data_report/keyword_report`,
    { ...filters, kw_report: false, target_type: "target" },
    {
      select: (d) => d.data,
      enabled: !!planDetailsTabs?.map((t) => t.key)?.includes("autoAds"),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
    }
  );

  // Ad insights data
  const { data: adInsightsData } = useFetch(
    ["adInsights", adId],
    `/api/insights/ads`,
    { budget_suppression: true, advertisement: adId },
    {
      select: (res) => res.data?.results ?? [],
      enabled: checkModule(modules, "MEDIA_PLAN_INSIGHTS"),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      [
        AD_TYPES.SPONSORED_BRAND.value,
        AD_TYPES.SPONSORED_BRAND_V2.value,
        AD_TYPES.SPONSORED_BRAND_VIDEO.value,
      ].includes(ad_type) &&
      tab === "products"
    ) {
      setTab("configuration");
    }
  }, [ad_type, tab]);

  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  const { overviewMetrics } = useMemo(() => {
    const roasMetrics = [buildMetric(marketPlace, data, "roas", "roas_delta")];
    const acosMetrics = [buildMetric(marketPlace, data, "acos", "acos_delta")];

    return {
      overviewMetrics: {
        columns: [],
        metrics: [
          buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
          buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
          ...acosroas(acosMetrics, roasMetrics),
          buildMetric(marketPlace, data, "clicks", "clicks_percent"),
          buildMetric(marketPlace, data, "impressions", "impressions_percent"),
          buildMetric(marketPlace, data, "ctr", "ctr_delta"),
          buildMetric(marketPlace, data, "cpc", "cpc_delta"),
          buildMetric(
            marketPlace,
            data,
            "conversion_rate",
            "conversion_rate_delta"
          ),
          buildMetric(
            marketPlace,
            data,
            "serving_hours",
            "serving_hours_delta"
          ),
        ],
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user]);

  const { currentAdDetails: currentAd, ads: categoryAds } = useAds(
    categoryId,
    adId
  );

  const breadCrumbItems = useMemo(() => {
    return [
      {
        name: "Advertising Dashboard",
        href: getDashboardTo({
          type: "dashboard",
          id: null,
        }),
        dropdownLinkOptions: DASHBOARD_LINKS,
        searchable: false,
        unsorted: true,
        dropdownSubtitle: "Dashboards",
      },
      {
        name: categoryDetails?.leaf_category_name,
        href: categoryDetails.href,
        dropdownLinkOptions: categoryData,
        searchable: true,
        searchPlaceholderText: "Search categories...",
        dropdownSubtitle: "Categories",
      },
      {
        name: currentAd?.name,
        href: currentAd?.href,
        dropdownLinkOptions: categoryAds,
        searchable: true,
        searchPlaceholderText: "Search media plans...",
        dropdownSubtitle: "Media Plans",
      },
    ];
  }, [categoryDetails, categoryData, categoryAds, currentAd]);

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  if (!showNewAdvertisingDashboard) {
    return <Redirect to={{ pathname: "/user/dashboard" }} />;
  }

  const displayAdTypeName = (value) => {
    let adTypeArray = [];

    for (const type in AD_TYPES) {
      adTypeArray.push(AD_TYPES[type]);
    }

    // Address conflict between SB v2 and old SP generic ad type values
    if (!adPlanData?.is_composite) {
      adTypeArray = adTypeArray.filter(
        (type) => type.label !== "Sponsored Product: Generic"
      );
    }

    let adTypeName = adTypeArray.find((t) => t.value === value)?.label ?? "";

    // This avoids walmart SP being labelled as Custom
    if (value === AD_TYPES.CUSTOM.value && channel === "walmart") {
      adTypeName = AD_TYPES.SPONSORED_PRODUCT.label;
    }

    return adTypeName;
  };

  const renderTabContent = (tab) => {
    switch (tab) {
      case "products":
        return (
          <>
            <AdvertisementProducts
              adPlanData={adPlanData}
              marketPlace={marketPlace}
              channel={channel}
            />
          </>
        );
      case "keywords":
        return (
          <MicroStores2
            tableId={"keyword"}
            targetData={keywordData}
            isLoading={isKeywordDataLoading}
            isStatsLoading={isKeywordStatsLoading}
            adId={adPlanData?.id}
            marketPlace={marketPlace}
            adType={adPlanData?.ad_type}
            channel={channel}
            modules={modules}
            adPlanData={adPlanData}
            adPlanParams={adPlanParams}
            products={products}
            filters={filters}
          />
        );
      case "targets":
        return (
          <MicroStores2
            tableId={"target"}
            targetData={targetData}
            isLoading={isTargetDataLoading}
            adId={adPlanData?.id}
            marketPlace={marketPlace}
            adType={adPlanData?.ad_type}
            channel={channel}
            modules={modules}
            adPlanData={adPlanData}
            adPlanParams={adPlanParams}
            products={products}
            filters={filters}
          />
        );
      case "negative-keywords":
        return (
          <MicroStores2
            tableId={"negative-keywords"}
            adId={adPlanData?.id}
            marketPlace={marketPlace}
            adType={adPlanData?.ad_type}
            channel={channel}
            modules={modules}
            adPlanData={adPlanData}
            adPlanParams={adPlanParams}
            products={products}
            filters={filters}
          />
        );
      case "placements":
        return (
          <MicroStores2
            tableId={"placements"}
            targetData={adPlacementData}
            isLoading={isAdPlacementDataLoading}
            adId={adPlanData?.id}
            marketPlace={marketPlace}
            adType={adPlanData?.ad_type}
            channel={channel}
            modules={modules}
            adPlanData={adPlanData}
            adPlanParams={adPlanParams}
            products={products}
            filters={filters}
          />
        );
      case "autoAds":
        return (
          <MicroStores2
            tableId={"adItems"}
            targetData={adItemData}
            isLoading={isAdItemDataLoading}
            adId={adPlanData?.id}
            marketPlace={marketPlace}
            adType={adPlanData?.ad_type}
            channel={channel}
            modules={modules}
            adPlanData={adPlanData}
            adPlanParams={adPlanParams}
            products={products}
            filters={filters}
          />
        );
      case "searchTermReport":
        return (
          <SearchTermReport
            marketPlace={marketPlace.marketPlace}
            adId={adPlanData?.id}
            channel={channel}
            adPlanParams={adPlanParams}
          />
        );
      case "configuration":
        return (
          <SettingsSection
            sections={groupedTabs["Configuration"]}
            categoryId={categoryId}
            marketPlace={marketPlace}
            adPlanData={adPlanData}
            adPlanParams={adPlanParams}
            channel={channel}
            user={user}
            categoryDetails={categoryDetails}
            preferences={preferences}
            keywordData={keywordData}
            targetData={targetData}
            modules={modules}
          />
        );
      default:
        return <></>;
    }
  };

  const isNTMAd = adPlanData?.ad_goal === GOALS.IMPORTED_ADS.value;
  const NTMBidsAccepted = adPlanParams?.nonTrellisEnrolledAdAcceptBids;
  const NTMStartDate = adPlanParams?.nonTrellisEnrolledAdAcceptBidsStartDate;
  const NTMDaysActive = moment().diff(moment(NTMStartDate), "days");
  return (
    <Grid
      fluid
      style={{
        minHeight: "calc(100vh - 150px)",
        background: "#fefefe",
      }}
    >
      <Row>
        <Col xs={12} className="mb-4">
          <BreadcrumbDates items={breadCrumbItems} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {isLoading && <Loading />}
          {isError && <p>Error Loading Dashboard</p>}
          {isSuccess && (
            <>
              <Row style={{ marginBottom: "1rem" }}>
                <Col xs={12}>
                  <Card
                    title={`${snakeCaseToSentenceCase(channel)} Advertising`}
                    category={category}
                    adPlanData={adPlanData}
                    channel={channel}
                    marketPlace={marketPlace}
                    mobileStyle={mobileStyle}
                    start={start}
                    end={end}
                    data={data}
                    isLoading={isFetching}
                    metrics={overviewMetrics.metrics}
                    initialSelectedMetric={["ad_sales", "ad_spend", "roas"]}
                    filters={filters}
                    date={date}
                    user={user}
                    adId={adId}
                  >
                    <Row
                      className={
                        is_composite || isNTMAd
                          ? "pt-5 d-flex mx-auto justify-content-center"
                          : "pt-5"
                      }
                    >
                      {isNTMAd && !NTMBidsAccepted && (
                        <Col xs={12} md={6} lg={2} className={`mb-4 px-2`}>
                          <DetailedMetric
                            center
                            size={"small"}
                            id={"ad_goal"}
                            name={"Ad Status"}
                            value={
                              <>
                                {displayAdStatus(
                                  adPlanData?.state,
                                  adPlanData?.ad_status
                                )}
                                <p className="fs-4 pt-1">
                                  Ad not Managed by Trellis
                                </p>
                              </>
                            }
                            formatter={(v) => v}
                            showTarget={false}
                          />
                        </Col>
                      )}
                      {isNTMAd && NTMBidsAccepted && (
                        <Col xs={12} md={6} lg={2} className={`mb-4 px-2`}>
                          <DetailedMetric
                            center
                            size={"small"}
                            id={"status"}
                            name={"Ad Status"}
                            value={`Optimized for ${NTMDaysActive} days`}
                            formatter={(v) => v}
                            showTarget={false}
                          />
                        </Col>
                      )}
                      {!isNTMAd && (
                        <Col xs={12} md={6} lg={2} className={`mb-4 px-2`}>
                          <DetailedMetric
                            center
                            size={"small"}
                            id={"status"}
                            name={"Ad Status"}
                            value={
                              <>
                                {displayAdStatus(
                                  adPlanData?.state,
                                  adPlanData?.ad_status
                                )}
                                {days_active &&
                                ![
                                  MODERATION_STATUSES.FAILED.value,
                                  MODERATION_STATUSES.PENDING.value,
                                ].includes(adPlanData?.ad_status) ? (
                                  <>
                                    {adPlanData?.state ===
                                    STATUS.ACTIVE.value ? (
                                      ` for ${days_active} days`
                                    ) : (
                                      <>
                                        <p className="fs-4 pt-1">
                                          Active for {days_active} days
                                        </p>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                                {created_datetime &&
                                !isNTMAd &&
                                adPlanData?.state === STATUS.ACTIVE.value ? (
                                  <p className="fs-4 pt-1">
                                    Created on{" "}
                                    {moment(created_datetime).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </>
                            }
                            formatter={(v) => v}
                            showTarget={false}
                          />
                        </Col>
                      )}
                      <Col xs={12} md={6} lg={2} className={`mb-4 px-2`}>
                        <DetailedMetric
                          center
                          size={"small"}
                          id={"budget"}
                          name={"Ad Type"}
                          value={displayAdTypeName(adPlanData?.ad_type)}
                          formatter={(v) => v}
                          showTarget={false}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={2} className={`mb-4 px-2`}>
                        <DetailedMetric
                          center
                          size={"small"}
                          id={"budget"}
                          name={"Monthly Budget"}
                          value={adPlanData?.monthly_budget}
                          formatter={(v) =>
                            formatCurrency(v, marketPlace.marketPlace, true)
                          }
                          showTarget={false}
                        />
                      </Col>
                      {!is_composite && !isNTMAd && (
                        <>
                          {acosroas(
                            <>
                              <Col
                                xs={12}
                                md={6}
                                lg={2}
                                className={`mb-4 px-2`}
                              >
                                <DetailedMetric
                                  center
                                  size={"small"}
                                  id={"budget"}
                                  name={"Target ACOS"}
                                  value={adPlanData?.target_acos}
                                  formatter={(v) => formatPercent(v / 100)}
                                  showTarget={false}
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={6}
                                lg={2}
                                className={`mb-4 px-2`}
                              >
                                <DetailedMetric
                                  center
                                  size={"small"}
                                  id={"budget"}
                                  name={"Max ACOS"}
                                  value={adPlanData?.max_acos}
                                  formatter={(v) => formatPercent(v / 100)}
                                  showTarget={false}
                                />
                              </Col>
                            </>,
                            <>
                              <>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={2}
                                  className={`mb-4 px-2`}
                                >
                                  <DetailedMetric
                                    center
                                    size={"small"}
                                    id={"budget"}
                                    name={"Target ROAS"}
                                    value={
                                      +(
                                        (1 / adPlanData?.target_acos) *
                                        100
                                      ).toFixed(2)
                                    }
                                    formatter={(v) => v}
                                    showTarget={false}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={2}
                                  className={`mb-4 px-2`}
                                >
                                  <DetailedMetric
                                    center
                                    size={"small"}
                                    id={"budget"}
                                    name={"Min ROAS"}
                                    value={
                                      +(
                                        (1 / adPlanData?.max_acos) *
                                        100
                                      ).toFixed(2)
                                    }
                                    formatter={(v) => v}
                                    showTarget={false}
                                  />
                                </Col>
                              </>
                            </>
                          )}
                        </>
                      )}
                      <Col xs={12} md={6} lg={2} className={`mb-4 px-2`}>
                        <DetailedMetric
                          center
                          size={"small"}
                          id={"budget"}
                          name={"Products"}
                          value={adPlanData?.product_count}
                          formatter={(v) => formatNumber(v, {}, 0)}
                          showTarget={false}
                        />
                      </Col>
                    </Row>
                    {isNTMAd &&
                      ![
                        AD_TYPES.SPONSORED_DISPLAY.value,
                        AD_TYPES.SPONSORED_DISPLAY_V2.value,
                      ]?.includes(adPlanParams?.adType) && (
                        <Row>
                          <NTMStatusWidget
                            adPlan={adPlanParams}
                            onChange={onChange}
                            products={products}
                            roas={preferences.acos_or_roas === "roas"}
                          />
                        </Row>
                      )}
                    <Row>
                      <Col
                        xs={12}
                        className="mt-5 ph-5 mb-5 border-bottom fs-standard"
                      >
                        <Nav
                          variant="underline"
                          onSelect={(selectedTab) => {
                            return setTab(selectedTab);
                          }}
                          activeKey={tab}
                        >
                          {Object.entries(groupedTabs)
                            ?.map((entry) => ({
                              label: entry[0],
                              keysToRender: entry[1],
                            }))
                            ?.sort((a, b) => {
                              if (
                                [a.label, b.label].includes("Configuration")
                              ) {
                                return a.label === "Configuration" ? 1 : -1;
                              }
                              return 0;
                            })
                            ?.map(({ keysToRender, label }) => {
                              const key =
                                keysToRender?.length === 1
                                  ? keysToRender[0]?.key
                                  : label.toLowerCase();
                              return (
                                <Nav.Item key={key}>
                                  <Nav.Link
                                    eventKey={key}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {label}
                                    {label === "Insights" &&
                                    adInsightsData?.length > 0 ? (
                                      <Badge
                                        bsClass="badge px-2"
                                        className={`badge-primary`}
                                        style={{ marginLeft: ".5rem" }}
                                        pill
                                      >
                                        {adInsightsData?.length}
                                      </Badge>
                                    ) : (
                                      ""
                                    )}
                                  </Nav.Link>
                                </Nav.Item>
                              );
                            })}
                        </Nav>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>{renderTabContent(tab)}</Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

const AdvertisementDetails = ({
  user,
  marketPlace,
  modules,
  location,
  channel,
  amz_onboard,
}) => {
  const { search } = useLocation();

  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { id: adId } = useParams();

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        adId={adId}
        modules={modules}
        channel={channel}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
  modules: selectModules(state),
  channel: state.channel.currentChannel,
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(AdvertisementDetails);
