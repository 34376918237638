export const METRIC_COLUMNS_BY_ENTITY = {
  category: [
    "ad_sales",
    "ad_spend",
    "cost",
    "roas",
    "acos",
    "clicks",
    "impressions",
    "ctr",
    "orders",
    "conversion_rate",
    "org_conv_rate",
    "cpc",
    // "serving_hours",
    "sales",
    "roms",
    "units",
    // "total_orders",
    "margin",
    "margin_wo_adspend",
    "ad_sales_same_sku",
    "troas",
    "roas_same_sku",
    "total_acos",
    "acos_same_sku",
    // "bsr",
    // "last_bsr_rank",
    // "rating",
    // "last_rating",
    // "ratings_total",
    // "last_ratings_total",
    // "page_views",
    "orders_same_sku",
  ],
  organization: [
    "ad_sales",
    "ad_spend",
    "cost",
    "roas",
    "acos",
    "clicks",
    "impressions",
    "ctr",
    "orders",
    "conversion_rate",
    "cpc",
    // "serving_hours",
    "sales",
    "roms",
    "units",
    // "total_orders",
    "margin",
    "margin_wo_adspend",
    "ad_sales_same_sku",
    "troas",
    "roas_same_sku",
    "total_acos",
    "acos_same_sku",
    "org_conv_rate",
    // "bsr",
    // "last_bsr_rank",
    // "rating",
    // "last_rating",
    // "ratings_total",
    // "last_ratings_total",
    "page_views",
    "orders_same_sku",
  ],

  mediaPlan: [
    "state",
    "daily_budget",
    "target_acos",
    "max_acos",
    "ad_sales",
    "ad_spend",
    "cost",
    "roas",
    "acos",
    "clicks",
    "impressions",
    "ctr",
    "orders",
    "conversion_rate",
    "cpc",
    "serving_hours",
    "created_datetime",
    "sales",
    "roms",
    "units",
    // "total_orders",
    // "margin",
    // "margin_wo_adspend",
    "ad_sales_same_sku",
    "troas",
    "roas_same_sku",
    "total_acos",
    "acos_same_sku",
    // "org_conv_rate",
    // "bsr",
    // "last_bsr_rank",
    // "rating",
    // "last_rating",
    // "ratings_total",
    // "last_ratings_total",
    // "page_views",
    "orders_same_sku",
  ],
  product: [
    "ad_sales",
    "ad_spend",
    "cost",
    "roas",
    "acos",
    "clicks",
    "impressions",
    "price",
    "ctr",
    "orders",
    "conversion_rate",
    "cpc",
    // "serving_hours",
    "created_datetime",
    "sales",
    "roms",
    "units",
    "total_orders",
    "margin",
    "margin_wo_adspend",
    "ad_sales_same_sku",
    "troas",
    "roas_same_sku",
    "total_acos",
    "acos_same_sku",
    "price",
    "org_conv_rate",
    "bsr",
    "last_bsr_rank",
    // TODO: these 4 would be ideal to have but seem to be blank for all products now
    // Did we lose them when rollups changed?
    // "rating",
    // "last_rating",
    // "ratings_total",
    // "last_ratings_total",
    "page_views",
    "orders_same_sku",
    "min_price", // Pricing product table only or only for pricing subscribed customers?
    "max_price", // Pricing product table only or only for pricing subscribed customers?
    "suggested_price",
    "seo_score",
    "buyability_score",
    // "stock_status",
    // "user_status",
    "profit_per_page_view", // TODO: this and below have same col header?
    "profit_per_page_view_wo_adspend",
    "profit",
    "profit_wo_adspend",
    "boosted_profit", // Pricing product table only or only for pricing subscribed customers?
  ],

  productAd: [
    "ad_sales",
    "ad_spend",
    "cost",
    "roas",
    "acos",
    "clicks",
    "impressions",
    "price",
    "ctr",
    "orders",
    "conversion_rate",
    "cpc",
    // "serving_hours",
    // "created_datetime",
    // "sales",
    // "roms",
    // "units",
    // "total_orders",
    // "margin",
    // "margin_wo_adspend",
    "ad_sales_same_sku",
    // "troas",
    "roas_same_sku",
    // "total_acos",
    "acos_same_sku",
    "price",
    // "org_conv_rate",
    // "bsr",
    // "last_bsr_rank",
    // TODO: these 4 would be ideal to have but seem to be blank for all products now
    // Did we lose them when rollups changed?
    // "rating",
    // "last_rating",
    // "ratings_total",
    // "last_ratings_total",
    // "page_views",
    "orders_same_sku",
    // "min_price", // Pricing product table only or only for pricing subscribed customers?
    // "max_price", // Pricing product table only or only for pricing subscribed customers?
    // "suggested_price",
    // "seo_score",
    // "buyability_score",
    // "stock_status",
    // "user_status",
    // "profit_per_page_view", // TODO: this and below have same col header?
    // "profit_per_page_view_wo_adspend",
    // "boosted_profit", // Pricing product table only or only for pricing subscribed customers?
  ],
  pricingPlan: [
    "state",
    // "daily_budget",
    // "ad_sales",
    // "ad_spend",
    "cost",
    "roas",
    "acos",
    "clicks",
    "impressions",
    "ctr",
    "orders",
    "conversion_rate",
    "cpc",
    "enroll_date",
    "sales",
    "target_sales",
    "roms",
    "units",
    "total_orders",
    "margin",
    "margin_wo_adspend",
    "ad_sales_same_sku",
    "troas",
    "roas_same_sku",
    "total_acos",
    "acos_same_sku",
    "profit",
    "profit_wo_adspend",
    "profit_expcectations",
    // "price", Note: already added in table, slight variance between this price field and current price so hiding for now
    // "min_price",
    // "max_price",
    // "suggested_price",
    // "product_price_prev",
    "org_conv_rate",
    // "bsr",
    // "promo_sales",
    // "promo_units",
    // "rods",
    // "promo_conversion_rate",
    // "promo_cost",
    "cogs",
    // "last_bsr_rank",
    // "rating",
    // "last_rating",
    // "ratings_total",
    // "last_ratings_total",
    "page_views",
    // "match_type",
    // "target_source",
    "orders_same_sku",
    // "serving_status",
    "profit_per_page_view",
    "profit_per_page_view_wo_adspend",
    "boosted_profit",
  ],
  target: [
    "ad_sales",
    "ad_spend",
    "cost",
    "roas",
    "acos",
    "clicks",
    "impressions",
    "ctr",
    "orders",
    "conversion_rate",
    "cpc",
    // "serving_hours",
    // "sales",
    "roms",
    // "units",
    // "total_orders",
    // "margin",
    // "margin_wo_adspend",
    // "ad_sales_same_sku",
    "troas",
    // "roas_same_sku",
    // "total_acos",
    // "acos_same_sku",
    // "org_conv_rate",
    // "bsr",
    // "last_bsr_rank",
    // "rating",
    // "last_rating",
    // "ratings_total",
    // "last_ratings_total",
    // "page_views",
    // "orders_same_sku",
  ],
};

export const COMPARE_METRIC_LOOKUP = {
  ad_sales: "ad_sales_percent",
  ad_spend: "ad_spend_percent",
  cost: "cost_percent",
  roas: "roas_delta",
  acos: "acos_delta",
  clicks: "clicks_percent",
  impressions: "impressions_percent",
  ctr: "ctr_delta",
  orders: "conversion_percent",
  conversion: "conversion_percent",
  conversion_rate: "conversion_rate_delta",
  cpc: "cpc_delta",
  serving_hours: "serving_hours_delta",
  sales: "sales_percent",
  roms: "roms_delta",
  units: "units_percent",
  total_orders: "total_order_count_percent",
  margin: "margin_delta",
  margin_wo_adspend: "margin_wo_adspend_delta",
  ad_sales_same_sku: "ad_sales_same_sku_percent",
  troas: "troas_delta",
  roas_same_sku: "roas_same_sku_delta",
  total_acos: "total_acos_delta",
  acos_same_sku: "acos_same_sku_delta",
  profit: "profit_percent",
  profit_wo_adspend: "profit_wo_adspend_percent",
  price: "price_delta",
  min_price: "price_delta",
  org_conv_rate: "org_conv_rate_delta",
  bsr: "bsr_percent",
  promo_sales: "promo_sales_percent",
  promo_units: "promo_units_percent",
  rods: "rods_delta",
  promo_conversion_rate: "promo_conversion_rate_delta",
  promo_cost: "promo_cost_percent",
  cogs: "cogs_percent",
  rating: "rating_percent",
  ratings_total: "ratings_total_percent",
  seo_score: "seo_score_percent",
  buyability_score: "buyability_score_percent",
  page_views: "page_views_percent",
  profit_per_page_view: "profit_per_page_view_percent",
  profit_per_page_view_wo_adspend: "profit_per_page_view_wo_adspend_percent",
  orders_same_sku: "orders_same_sku_percent",
};

export const PRICING_METRICS = [
  "profit",
  "profit_wo_adspend",
  "min_price",
  "max_price",
  "suggested_price",
  "profit_per_page_view",
  "profit_per_page_view_wo_adspend",
  "boosted_profit",
];

export const CONTENT_METRICS = ["seo_score", "buyability_score"];

export const PROMO_METRICS = [
  "promo_sales",
  "promo_units",
  "rods",
  "promo_conversion_rate",
  "promo_cost",
];

/*
Merchandising
Total Sales, Units, Page Views, BSR, Price

Advertising
ACOS, Ad Spend, Ad Sales, etc

Calculated
Profit, Margin, Cogs, Bid, PPS etc

Config
Landed Cost, Shipping, min, max, target ACOS, max ACOS
*/
export const METRIC_GROUPING_LOOKUP = {
  state: "configuration",
  target_acos: "configuration",
  max_acos: "configuration",
  target_roas: "configuration",
  min_roas: "configuration",
  daily_budget: "configuration",
  enroll_date: "configuration",
  created_datetime: "configuration",
  sales: "merchandising",
  total_sales: "merchandising",
  target_sales: "configuration",
  ad_group_name: "configuration",
  target_bid: "configuration",
  creation_date: "configuration",
  target_state: "configuration",
  keyword_text: "configuration",
  bidded_keyword: "configuration",
  sales_expcectations: "merchandising",
  cost: "advertising",
  total_cost: "merchandising",
  trends_score: "calculated",
  roms: "merchandising",
  units: "merchandising",
  total_units: "merchandising",
  target_units: "configuration",
  total_orders: "merchandising",
  units_expectations: "configuration",
  margin: "calculated",
  margin_wo_adspend: "calculated",
  target_margin: "configuration",
  margin_expcectations: "configuration",
  ad_sales: "advertising",
  ad_sales_same_sku: "advertising",
  ad_spend: "advertising",
  clicks: "merchandising",
  troas: "merchandising",
  total_roas: "merchandising",
  roas: "advertising",
  roas_same_sku: "advertising",
  total_acos: "merchandising",
  acos: "advertising",
  acos_same_sku: "advertising",
  profit: "calculated",
  profit_wo_adspend: "calculated",
  profit_expcectations: "calculated",
  price: "configuration",
  product_price: "configuration",
  min_price: "configuration",
  max_price: "configuration",
  suggested_price: "configuration",
  org_conv_rate: "merchandising",
  conv_rate: "advertising",
  bsr: "merchandising",
  promo_sales: "merchandising",
  promo_units: "merchandising",
  product_revenue: "advertising",
  deal_price: "configuration",
  product_units_sold: "merchandising",
  product_glance_views: "merchandising",
  "promotion_report_product.promotion_report.end_datetime": "configuration",
  "promotion_report_product.promotion_report.start_datetime": "configuration",
  promotionType: "configuration",
  status: "configuration",
  product: "configuration",
  category_name: "configuration",
  category: "configuration",
  sales_est: "calculated",
  units_est: "calculated",
  cogs_est: "calculated",
  rods: "merchandising",
  promo_conversion_rate: "merchandising",
  promo_cost: "merchandising",
  cogs: "calculated",
  last_bsr_rank: "merchandising",
  rating: "merchandising",
  last_rating: "merchandising",
  ratings_total: "merchandising",
  last_ratings_total: "merchandising",
  seo_score: "merchandising",
  buyability_score: "merchandising",
  conversion_rate: "advertising",
  ctr: "advertising",
  click_thru_rate: "advertising",
  cpc: "advertising",
  cost_per_click: "advertising",
  impressions: "merchandising",
  page_views: "merchandising",
  match_type: "configuration",
  bid: "configuration",
  daily_bid: "configuration",
  target_source: "configuration",
  orders: "advertising",
  conversion: "advertising",
  orders_same_sku: "advertising",
  user_status: "configuration",
  stock_status: "configuration",
  serving_status: "configuration",
  product_quantity: "configuration",
  product_quantity_fba: "configuration",
  profit_per_page_view: "merchandising",
  profit_per_page_view_wo_adspend: "merchandising",
  boosted_profit: "calculated",
  serving_hours: "advertising",
  pricing_plan_cnt: "configuration",
  sales_pressure: "configuration",
  profit_potential: "configuration",
  right_price: "configuration",
  new_plan: "configuration",
  no_price: "configuration",
  out_of_stock: "configuration",
  landed_cost: "configuration",
  shipping_cost: "configuration",
  product_count: "configuration",
  best_price: "configuration",
};

export const ENTITY_LABEL_MAP = {
  mediaPlan: "Media Plan",
  pricingPlan: "Pricing Plan",
  category: "Category",
  product: "Product",
  organization: "Organization",
  target: "Target",
  productAd: "Product Advertisement",
};
