import React, { useMemo } from "react";
import Chart from "./Chart";
import { SERIES_PALETTE, getCurrencyDescription } from "./utils";
import { MARKETPLACE_CURRENCY_DENOMINATION } from "utils/marketplaceConstants";
import { useSelector } from "react-redux";
import { useDates } from "dates/useDates";
import { STATUS } from "components/core/blocks/AdPlan/constants";

const accumulateByDate = (data, accessors = []) => {
  const accData = [];

  let runningTotal = new Array(accessors.length).fill(0);

  data.forEach((entry) => {
    let dailyAccStats = { date: entry.start_date };

    accessors.forEach((accessor, i) => {
      const value = entry[accessor] + runningTotal[i];
      runningTotal[i] += entry[accessor];
      dailyAccStats[accessor] = value;
    });

    accData.push(dailyAccStats);
  });

  return accData;
};

const AdSpendProgressionChart = ({ adData, adPlanData }) => {
  const { group } = useDates();
  const { marketPlace } = useSelector((state) => state);

  const overallDailyBudget = adPlanData
    ?.filter((ad) => ad.ad_status === STATUS.ACTIVE.value)
    ?.reduce((acc, ad) => {
      return acc + ad.daily_budget;
    }, 0);

  const budgetProgressionData = useMemo(() => {
    const accumulatedData = accumulateByDate(adData?.date, ["cost"]);

    return adData?.date?.map((day, index) => {
      return {
        ...day,
        budget_goal_progression: overallDailyBudget * (index + 1),
        cost: accumulatedData[index]?.cost,
        ad_sales: accumulateByDate[index]?.ad_sales,
      };
    });
  }, [adData, overallDailyBudget]);

  return (
    <Chart
      title="Media Plan Budget vs. Ad Spend Progression"
      data={budgetProgressionData}
      metrics={[
        { id: "budget_goal_progression", color: SERIES_PALETTE[2] },
        { id: "ad_spend", color: SERIES_PALETTE[4] },
      ]}
      yAxisLabels={[
        `${getCurrencyDescription(marketPlace.marketPlace)} (${
          MARKETPLACE_CURRENCY_DENOMINATION[marketPlace.marketPlace]
        })`,
      ]}
      group={group}
      height={275}
      type="area"
      marketPlace={marketPlace}
    />
  );
};

export default AdSpendProgressionChart;
