import React, { useMemo } from "react";
import { useTable } from "react-table";
import { formatPercent } from "utils/formatNumber";
import getMetricSpec from "views/merchandise/metrics";
import { getTrendColor } from "./utils";

const Table = ({
  title = "",
  columns,
  data,
  showPrevPeriodTrendRow = false,
  showYoyTrendRow = false,
  marketPlace,
  trendRowDescription = false,
  titleColWidth = 30,
  noDataMessage = "",
}) => {
  const columnDefs = useMemo(() => {
    return columns?.map((col) => {
      const metricSpec = getMetricSpec(marketPlace, col.accessor);

      if (col.customCell) {
        col.Cell = ({ row }) => {
          return col.customCell(row.original);
        };
      }

      // If metric matches, use formatter, otherwise return raw value passed
      if (metricSpec.name) {
        col.Cell = (props) => {
          return (
            <span>
              {metricSpec.formatter(metricSpec.accessor(props.row.original))}
            </span>
          );
        };

        return col;
      }

      return col;
    });
  }, [columns, marketPlace]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columnDefs,
      data,
    });

  const buildTrendRow = (
    row,
    compareAccessorKey,
    rowIndex,
    rowLabel = null,
    isLastRow = false
  ) => {
    return (
      <tr className="trend-row">
        {row.cells.map((cell, colIndex) => {
          const cellStyles = {
            textAlign: colIndex ? "center" : "left",
            width: colIndex ? `${70 / columns.length}%` : "auto",
          };

          if (typeof cell.value === "string" && rowLabel) {
            return (
              <td
                className={`text-end ${
                  isLastRow
                    ? "report-td-border-bottom"
                    : "report-td-border-none"
                }`}
                style={cellStyles}
              >
                {rowLabel}
              </td>
            );
          }

          if (
            cell.column.customCompareCell &&
            typeof cell.column.customCompareCell === "function"
          ) {
            return (
              <td
                style={{
                  ...cellStyles,
                }}
                className={`py-2 ${
                  isLastRow
                    ? "report-td-border-bottom"
                    : "report-td-border-none"
                }`}
                key={`${rowIndex}-${colIndex}-${compareAccessorKey}-cell`}
              >
                {cell.column.customCompareCell(cell.row.original)}
              </td>
            );
          }
          const compareAccessor = cell.column[compareAccessorKey];
          const compareValue = cell.row.original[compareAccessor];

          return (
            <td
              {...cell.getCellProps()}
              style={{
                ...cellStyles,
                color: getTrendColor(compareValue, cell.column.id),
              }}
              className={`py-2 ${
                isLastRow ? "report-td-border-bottom" : "report-td-border-none"
              }`}
              key={`${rowIndex}-${colIndex}-${compareAccessorKey}-cell`}
            >
              {compareValue
                ? formatPercent(compareValue, 2, true)
                : colIndex === 0
                ? ""
                : "-"}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <>
      {title && (
        <div
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            textAlign: "left",
            fontSize: "17px",
            fontWeight: "bold",
          }}
        >
          {title}{" "}
        </div>
      )}
      <table
        {...getTableProps()}
        className={`box-stats-data-table`}
        style={{ width: "100%", tableLayout: "auto" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                const { render, getHeaderProps } = column;
                return (
                  <th
                    {...getHeaderProps()}
                    style={{
                      textAlign: i ? "center" : "left",
                      width: i
                        ? `${(100 - titleColWidth) / columns.length}%`
                        : `${titleColWidth}%`,
                    }}
                  >
                    {render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && noDataMessage ? (
            <tr>
              <td
                colSpan={columns?.length}
                className="text-center text-muted"
                style={{ paddingTop: "75px", paddingBottom: "75px" }}
              >
                {noDataMessage}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, colIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          textAlign: colIndex ? "center" : "left",
                          width: colIndex
                            ? `${(100 - titleColWidth) / columns.length}%`
                            : `${titleColWidth}%`,
                        }}
                        className={`py-2 ${
                          !showPrevPeriodTrendRow && !showPrevPeriodTrendRow
                            ? "report-td-border-bottom"
                            : "report-td-border-none"
                        }`}
                        key={`${rowIndex}-${colIndex}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {showPrevPeriodTrendRow &&
                  buildTrendRow(
                    row,
                    "compareMetricId",
                    rowIndex,
                    trendRowDescription ?? "vs. Prev",
                    !showYoyTrendRow
                  )}
                {showYoyTrendRow &&
                  buildTrendRow(
                    row,
                    "yoyCompareMetricId",
                    rowIndex,
                    trendRowDescription ?? "YoY",
                    true
                  )}
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Table;
