import { useState, useEffect } from "react";

const useWizard = (steps, assessFormSteps, formStepDependencies) => {
  const [step, setStep] = useState(0);
  const [substep, setSubstep] = useState(0);
  const [highestStepVisited, setHighestStepVisited] = useState(0);
  const [highestSubstepVisited, setHighestSubstepVisited] = useState(0);
  const [skipForward, setSkipForward] = useState(false);
  const [skipBackward, setSkipBackward] = useState(false);

  useEffect(() => {
    let autoNavigate = assessFormSteps();

    if (autoNavigate?.skip) {
      if (autoNavigate?.skip?.step && autoNavigate?.skip?.substep) {
        setStep(autoNavigate?.skip.step);
        setSubstep(autoNavigate?.skip.substep);
      } else {
        setSkipForward(true);
      }
      return;
    }

    if (autoNavigate?.previous) {
      onPrevious();
      setSkipBackward(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...formStepDependencies, assessFormSteps]);

  useEffect(() => {
    if (skipForward) {
      steps?.[step].substeps?.[substep + 1] && onNext();
      setSkipForward(false);
    }

    if (skipBackward) {
      steps?.[step].substeps?.[substep - 1] && onPrevious();
      setSkipBackward(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipForward, skipBackward]);

  const onNext = () => {
    if (steps[step]?.substeps?.length - 1 > substep) {
      setSubstep(substep + 1);
      setHighestSubstepVisited(substep + 1);
    } else {
      setStep(step + 1);
      setHighestStepVisited(step + 1);
      setSubstep(0);
    }
  };

  const onPrevious = () => {
    if (substep > 0) {
      setSubstep(substep - 1);
      return;
    }

    if (step > 0) {
      setStep(step - 1);
      setSubstep(steps[step - 1]?.substeps?.length - 1 ?? 0);
      return;
    }
  };

  return {
    onNext,
    onPrevious,
    step,
    setStep,
    steps,
    substep,
    setSubstep,
    highestStepVisited,
    highestSubstepVisited,
  };
};

export default useWizard;
