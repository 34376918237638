import { trellisPalette } from "components/custom/analytics/palettes";
import { trackMixpanelEvent } from "hooks/useMixpanelTracking";
import React from "react";
import { Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { completeAmzProfileSelection } from "redux/actions/amzOnboardActions";
import store from "redux/store";
import { AMZ_ONBOARD } from "redux/types";
import api from "utils/api";

const SkipToDashboardLink = ({ additionalStyles, onClick = () => {} }) => {
  const { amz_onboard } = useSelector((state) => state);
  const setPreTrialStatus = useMutation(
    async () => {
      return await api.put(`/api/application/orgstatus/`, {
        status: "pre_trial",
      });
    },
    {
      onSuccess: (res) => {
        store.dispatch(completeAmzProfileSelection());
        // Trigger redux refresh of user profile data
        store.dispatch({
          type: AMZ_ONBOARD,
          payload: { ...amz_onboard, ...res.data },
        });
      },
    }
  );

  return (
    <Row className="text-center" style={{ ...additionalStyles }}>
      <Link
        className="fs-5 text-decoration-none fw-500"
        style={{ color: trellisPalette[13] }}
        onClick={() => {
          if (typeof onClick === "function") {
            onClick();
          }
          if (!["active", "trial_active"].includes(amz_onboard?.org_status)) {
            setPreTrialStatus.mutate();
          }

          localStorage.removeItem("redirectTo");
          trackMixpanelEvent("Click", {
            name: "Connect Later",
            element: "anchor",
          });
        }}
        to="/onboarding/v2/summary"
      >
        Connect Later
      </Link>
    </Row>
  );
};

export default SkipToDashboardLink;
