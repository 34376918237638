import React, { Component } from "react";
import { Rnd } from "react-rnd";
import LoadingImage from "assets/images/utility/no_image.png";

const styleRndBackgroundscrollSelected = {
  display: "flex",
  borderRadius: "5px",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  borderLeft: "solid 3px rgb(98, 64, 127)",
  borderRight: "solid 3px rgb(98, 64, 127)",
  borderTop: "solid 3px rgb(98, 64, 127)",
  borderBottom: "solid 3px rgb(98, 64, 127)",
  height: "100%",
};

const styleRndBackgroundscroll = {
  display: "flex",
  borderRadius: "5px",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  borderLeft: "solid 2px #ddd",
  borderRight: "solid 2px #ddd",
  borderTop: "solid 1px #ddd",
  borderBottom: "solid 1px #ddd",
  height: "100%",
};

export default class ImageRow extends Component {
  render() {
    let useCanvasId = "image_row_canvas_" + this.props.useKey;
    let images = [...this.props.useImages];
    return (
      <Rnd
        style={
          this.props.isSelected
            ? styleRndBackgroundscrollSelected
            : styleRndBackgroundscroll
        }
        bounds="parent"
        disableDragging="true"
        enableResizing={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        size={{
          width: this.props.useWidth,
          height: this.props.useHeight,
        }}
        position={{ x: this.props.useXpos, y: 0 }}
        onDragStop={(e, d) => {}}
        onResizeStop={(e, direction, ref, delta, position) => {
          this.setState({
            textscrollWidth: ref.style.width,
            textscrollHeight: ref.style.height,
            ...position,
          });
        }}
      >
        <div
          id={useCanvasId + "_div"}
          style={{ cursor: this.props.cursor ? this.props.cursor : "default" }}
        >
          <canvas
            id={useCanvasId}
            width={"" + this.props.useWidth}
            height={this.props.useHeight - 4}
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
            }}
          ></canvas>
          <img
            id={"image_row_" + this.props.useKey}
            src={LoadingImage}
            alt=" "
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              display: "none",
            }}
            draggable="false"
            onLoad={(e) => {
              if (this.props.writeLoading) {
                const canvas = document.getElementById(useCanvasId);
                const ctx = canvas.getContext("2d");
                ctx.font = "15px Arial";
                ctx.fillText("Loading...", 28, 28);
              }
            }}
          />
          <img
            id={"background_image_" + this.props.useKey}
            src={this.props.useSrc}
            alt=" "
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              display: "none",
            }}
            draggable="false"
            onLoad={(e) => {
              const canvas = document.getElementById(useCanvasId);
              const ctx = canvas.getContext("2d");

              // img	Specifies the image, canvas, or video element to use
              // sx	Optional. The x coordinate where to start clipping
              // sy	Optional. The y coordinate where to start clipping
              // swidth	Optional. The width of the clipped image
              // sheight	Optional. The height of the clipped image
              // x	The x coordinate where to place the image on the canvas
              // y	The y coordinate where to place the image on the canvas
              // width	Optional. The width of the image to use (stretch or reduce the image)
              // height	Optional. The height of the image to use (stretch or reduce the image)

              let useHeight = this.props.useHeight - 6;
              let ratio =
                Math.min(e.target.height, e.target.width) /
                Math.max(e.target.height, e.target.width);
              let recalcWidth = useHeight / ratio;
              let shrinkRatio =
                Math.min(useHeight, e.target.height) /
                Math.max(useHeight, e.target.height);
              let leftoverWidth = this.props.useWidth;
              let xStartLoc = 0;
              images.push(e.target); // append first one to the end
              let clipWidth = e.target.width;
              let prevWidth = clipWidth;
              let offsetWidth = 0;
              let useImage = e.target;
              let imageIdx = 1;
              while (leftoverWidth > 3) {
                if (recalcWidth > leftoverWidth) {
                  recalcWidth = leftoverWidth;
                  clipWidth = leftoverWidth / shrinkRatio;
                  if (clipWidth < prevWidth) {
                    offsetWidth = -6;
                  }
                  prevWidth = clipWidth;
                }
                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(
                  xStartLoc,
                  0,
                  recalcWidth + offsetWidth,
                  useHeight
                );
                ctx.drawImage(
                  useImage,
                  0,
                  0,
                  clipWidth,
                  e.target.height,
                  xStartLoc,
                  0,
                  recalcWidth + offsetWidth,
                  useHeight
                );

                xStartLoc += recalcWidth;
                leftoverWidth = this.props.useWidth - xStartLoc;

                if (images && images.length > 0) {
                  imageIdx = imageIdx >= images.length ? 0 : imageIdx;
                  useImage = images[imageIdx];
                  imageIdx++;
                }
              }
            }}
          />
        </div>
      </Rnd>
    );
  }
}
