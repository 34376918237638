import bottomArrows from "assets/images/background/bottom_arrows.png";
import bgImage from "assets/images/background/purple_gradient.png";
import trellislogo from "assets/images/logo/Trellis_Logo_white.png";
import status from "assets/images/onboarding/amazon_onboarding_timeline-02_spapi.jpg";
import success_tick from "assets/images/status/successful_tick_150x150.png";
import axios from "axios";
import { AuthButton } from "components/core/basic/Button";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Hidden, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomHref from "components/core/blocks/CustomHref";
import {
  initiateAmzAdvConnect,
  completeAmzAdvConnect,
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
  completeAmzProfileSelection,
} from "redux/actions/amzOnboardActions";
import { setCurrentUser } from "redux/actions/authActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import {
  getURLPrefix,
  CONST_SPAPI_EU_REGION_FOR_URI_EXTERNAL,
  CONST_SPAPI_FE_REGION_FOR_URI_EXTERNAL,
  CONST_SPAPI_NA_REGION_FOR_URI_EXTERNAL,
  CONST_SPAPI_URI_EXTERNAL_REGION_DECODE,
} from "utils/getUrlPrefix";
import TermsOfService from "components/custom/instructions/TermsOfService";
import SweetAlert from "react-bootstrap-sweetalert";
import SellerRegionMUI from "../navbar/SellerRegionMUI";
import HubspotCalendarModal from "../../core/blocks/HubspotCalendarModal";
import { getAmazonSPAPIClientURL } from "utils/getUrlPrefix";
import { AMAZON_REGIONS_MARKETPLACE_MAP } from "utils/marketplaceConstants";
import SignupLayout from "views/onboarding/layout/SignupLayout";
import OnboardingFooter from "./OnboardingFooter";

let URL_PREFIX = getURLPrefix();

const useStyles = (theme) => ({
  heading: {
    textAlign: "center",
    color: "#393939",
    fontSize: "34px",
    fontWeight: 700,
    marginTop: "5rem",
    padding: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
    },
  },
  paragraph: {
    marginTop: "2rem",
    fontSize: "20px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  successImage: {
    height: "150px",
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      height: "100px",
      width: "100px",
    },
  },
});

class OnboardingSpApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLayout: false,
      disableConnectSpApiButton: false,
      disableSkipToDashboard: false,
      regions: [],
      selectedProfile: { value: "" },
      showErrorModal: false,
      region: "",
      sellerMarketplaces: "",
      sellerID: "",
    };

    this.handleCloseCalendarModal = this.handleCloseCalendarModal.bind(this);
  }

  async componentDidMount() {
    await this.loadOnboardData();
    await this.fetchUserRegions();

    if (this.props.amz_onboard.org_status === "onboarding") {
      if (window.location.href.includes("user")) {
        this.props.history.push("/onboard/amzspapi");
      }
    } else {
      this.skipToDashboard();
    }
    if (
      this.props.amz_onboard.amazon_spapi_access === "connected" ||
      this.props.amz_onboard.eu_amazon_spapi_access === "connected" ||
      this.props.amz_onboard.fe_amazon_spapi_access === "connected"
    ) {
      this.skipToDashboard();
    }
    if (
      window.location.href.includes("edit_sp_api=true") &&
      (this.props.amz_onboard.amazon_spapi_access === "in_progress" ||
        this.props.amz_onboard.eu_amazon_spapi_access === "in_progress" ||
        this.props.amz_onboard.fe_amazon_spapi_access === "in_progress")
    ) {
      if (
        this.props.history.location.state &&
        "spapi_oauth_code" in this.props.history.location.state
      ) {
        this.setState({
          disableConnectSpApiButton: true,
          disableSkipToDashboard: true,
        });
        let spapi_req_state = this.props.history.location.state;
        const spapi_oauth_code = spapi_req_state["spapi_oauth_code"];
        const selling_partner_id = spapi_req_state["selling_partner_id"];
        const region_encoded = spapi_req_state["region_encoded"];

        let region = CONST_SPAPI_URI_EXTERNAL_REGION_DECODE[region_encoded];
        if (!region) {
          region = this.state.region
            ? this.state.region
            : AMAZON_REGIONS_MARKETPLACE_MAP[localStorage.MarketPlace];
        }

        this.connectSpApi(spapi_oauth_code, selling_partner_id, region);
        this.props.history.replace({ state: {} });
      } else {
        this.props.history.replace({
          pathname: this.props.user?.bypass_onboarding
            ? "/onboarding/v2/connect"
            : "/onboard/amzspapi",
          state: {},
          search: "?noSPAPICode=true",
        });
      }
    }

    refreshAmzOnboardStatus(
      this.props.updateAmzOnboardingStatus,
      this.props.auth.tokens.access_token,
      this.props.setErrors
    );
  }

  componentDidUpdate(previousProps) {
    if (this.props.errors && this.props.errors !== previousProps.errors) {
      this.setState({ showErrorModal: true });
    }
  }

  async loadOnboardData() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    await axios
      .get(URL_PREFIX + "/api/onboardingsummary/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        let seller_type = Object.values(
          JSON.parse(res.data["amazon_profile_ids"])
        )[0]["type"];
        if (seller_type === "vendor") {
          this.setState({ isVendor: true });
        }

        let region =
          res.data.account_region.length > 1 ? "" : res.data.account_region[0];
        let sellerID = res.data.amazon_mws_seller_id;

        this.setState({
          region: region,
          availableRegions: res.data.account_region,
          sellerMarketplaces: res.data.amazon_seller_marketplaces,
          sellerID: sellerID,
        });
      });
  }

  async fetchUserRegions() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    await axios
      .get(URL_PREFIX + "/api/sellerinfo/", {
        params: {},
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        if (res.data.account_region) {
          let use_region = null;
          if (res.data.account_region.length === 1) {
            use_region = res.data.account_region[0];
          }
          this.setState({
            region: use_region,
            availableRegions: res.data.account_region,
          });
        }
        if (res.data.amazon_seller_marketplaces) {
          this.setState({
            availableMarketplaces: res.data.amazon_seller_marketplaces,
          });
        }
      });
  }

  updateSpApiStatus(region, status, selling_partner_id = null) {
    switch (region) {
      case "NA":
      case CONST_SPAPI_NA_REGION_FOR_URI_EXTERNAL:
        let sellerId = selling_partner_id
          ? selling_partner_id
          : this.state.mwsSellerID;
        this.setState({
          spapi_connecting_status: status,
          mwsSellerID: sellerId,
        });
        break;
      case "EU":
      case CONST_SPAPI_EU_REGION_FOR_URI_EXTERNAL:
        let euSellerId = selling_partner_id
          ? selling_partner_id
          : this.state.euMwsSellerID;
        this.setState({
          eu_spapi_connecting_status: status,
          euMwsSellerID: euSellerId,
        });
        break;
      case "FE":
      case CONST_SPAPI_FE_REGION_FOR_URI_EXTERNAL:
        let feSellerId = selling_partner_id
          ? selling_partner_id
          : this.state.feMwsSellerID;
        this.setState({
          fe_spapi_connecting_status: status,
          feMwsSellerID: feSellerId,
        });
        break;
      default:
        return;
    }
  }

  connectSpApi(spapi_oauth_code, selling_partner_id, region) {
    const dispatchSetError = (p) => this.props.setErrors(p);

    if (!region) {
      region = localStorage.spRegionAttempted;
    }

    var AuthHeader = "Bearer ".concat(this.props.auth.tokens.access_token);

    const { bypass_onboarding } = this.props.user ?? {};
    const {
      amazon_spapi_access,
      eu_amazon_spapi_access,
      fe_amazon_spapi_access,
    } = this.props.amz_onboard ?? {};

    axios
      .post(
        URL_PREFIX + "/api/amazonspapi/",
        {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          region: region,
        },
        { params: {}, headers: { Authorization: AuthHeader } }
      )
      .then((res) => {
        if (res.status === 200) {
          if (
            bypass_onboarding &&
            [
              amazon_spapi_access,
              eu_amazon_spapi_access,
              fe_amazon_spapi_access,
            ].includes("in_progress")
          ) {
            this.props.history.push("/onboarding/v2/connect");
          }
          // this.updateSpApiStatus( region_encoded, "connect_success", selling_partner_id);
          refreshAmzOnboardStatus(
            this.props.updateAmzOnboardingStatus,
            this.props.auth.tokens.access_token,
            this.props.setErrors
          );
        }
      })
      .catch((err) => {
        // this.updateSpApiStatus(region_encoded, "connect_failed");
        console.log(err);
        if (
          bypass_onboarding &&
          [
            amazon_spapi_access,
            eu_amazon_spapi_access,
            fe_amazon_spapi_access,
          ].includes("in_progress")
        ) {
          this.props.history.push("/onboarding/v2/connect");
        }
        this.setState({
          disableConnectSpApiButton: false,
          disableSkipToDashboard: false,
        });
        dispatchSetError({
          title: "Amazon SP-API Connect Failed",
          message: "Failed to get Amazon SP-API credentials. Please try again.",
        });
      });
  }

  skipToDashboard() {
    this.setState({ skipButtonClicked: true });
    this.handleRedirectToDashboard();
  }

  handleCloseCalendarModal() {
    this.setState({ openCalendarModal: false, showErrorModal: false });
  }

  handleRedirectToDashboard() {
    this.setOnboardingComplete((success) => {
      if (success) {
        localStorage.setItem(
          "onboardRedirectTo",
          JSON.stringify("/user/dashboard")
        );
        this.props.history.push("/user/dashboard");
      } else {
        this.setState({
          errorMessage: "Failed to reach dashboard, please contact Trellis.",
        });
      }
    });
  }

  setOnboardingComplete(cb) {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);

    axios
      .post(
        URL_PREFIX + "/api/mwsinfotask/",
        {},
        {
          params: {},
          headers: { Authorization: AuthStr },
        }
      )
      .then((res) => {
        if (
          res.data === "Processing SKU information and updated status" ||
          res.data === "No info to process"
        ) {
          refreshAmzOnboardStatus(
            this.props.updateAmzOnboardingStatus,
            this.props.auth.tokens.access_token,
            this.props.setErrors
          );
          if (cb) {
            cb(true);
          }
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false);
        }
      });
  }

  getFirstSellerSubcribedMarketplaceInRegion(region) {
    switch (region) {
      case "NA":
        if (this.state.availableMarketplaces.includes("USA")) return "US";
        else if (this.state.availableMarketplaces.includes("CA")) return "CA";
        else if (this.state.availableMarketplaces.includes("MX")) return "MX";
        else if (this.state.availableMarketplaces.includes("BR")) return "BR";
        break;
      case "EU":
        if (this.state.availableMarketplaces.includes("UK")) return "UK";
        else if (this.state.availableMarketplaces.includes("ES")) return "ES";
        else if (this.state.availableMarketplaces.includes("GB")) return "GB";
        else if (this.state.availableMarketplaces.includes("FR")) return "FR";
        else if (this.state.availableMarketplaces.includes("NL")) return "NL";
        else if (this.state.availableMarketplaces.includes("DE")) return "DE";
        else if (this.state.availableMarketplaces.includes("IT")) return "IT";
        else if (this.state.availableMarketplaces.includes("SE")) return "SE";
        else if (this.state.availableMarketplaces.includes("PL")) return "PL";
        else if (this.state.availableMarketplaces.includes("BE")) return "BE";
        else if (this.state.availableMarketplaces.includes("EG")) return "EG";
        else if (this.state.availableMarketplaces.includes("TR")) return "TR";
        else if (this.state.availableMarketplaces.includes("SA")) return "SA";
        else if (this.state.availableMarketplaces.includes("AE")) return "AE";
        else if (this.state.availableMarketplaces.includes("SA")) return "SA";
        else if (this.state.availableMarketplaces.includes("IN")) return "IN";
        break;
      case "FE":
        if (this.state.availableMarketplaces.includes("AU")) return "AU";
        else if (this.state.availableMarketplaces.includes("SG")) return "SG";
        else if (this.state.availableMarketplaces.includes("JP")) return "JP";
        break;
      default:
        return null;
    }
  }

  setAmazonSpApiToInProgress(region) {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .post(
        URL_PREFIX + "/api/amazonspapi/",
        { set_amazon_spapi_access_to_in_progress: region },
        {
          params: {},
          headers: { Authorization: AuthStr },
        }
      )
      .then((res) => {
        refreshAmzOnboardStatus(
          this.props.updateAmzOnboardingStatus,
          this.props.auth.tokens.access_token,
          this.props.setErrors
        );
      })
      .catch((err) => {
        console.log("Failed to set SP-API access to in-progress");
      });
  }

  editAmazonSpApiAccess(region) {
    let use_marketplace =
      this.getFirstSellerSubcribedMarketplaceInRegion(region);
    let is_seller = !this.props.isVendorProfile;

    this.setAmazonSpApiToInProgress(region);

    let uri = getAmazonSPAPIClientURL(
      is_seller,
      use_marketplace,
      region,
      "onboarding"
    );
    window.location = uri;
  }

  assessAvailableMarketplaces = (id) => {
    this.setState({ region: id, euStatus: "", naStatus: "", feStatus: "" });
  };

  render() {
    const { classes } = this.props;
    const { bypass_onboarding } = this.props.user ?? {};

    if (bypass_onboarding) {
      return <SignupLayout placeholderLayout={true} />;
    }

    return (
      <>
        {this.state.showErrorModal && !this.state.openCalendarModal && (
          <>
            <SweetAlert
              onConfirm={() => {
                this.setState({ showErrorModal: false, errors: [] });
              }}
              title={this.props.errors[0].title}
              style={{
                display: "block",
                overflowY: "auto",
                margin: "auto",
                paddingBottom: "2rem",
                width: "40%",
                minWidth: "380px",
                left: "30%",
                top: "30%",
                right: "30%",
                bottom: "30%",
                borderRadius: "10px",
              }}
              confirmBtnText={"OK"}
            >
              <div style={{ textAlign: "center", padding: "2rem" }}>
                <p>{this.props.errors[0].message}</p>

                <div
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#D91266",
                  }}
                  onClick={() => {
                    this.setState({
                      errors: [],
                      showErrorModal: false,
                      openCalendarModal: true,
                    });

                    this.props.clearErrors();
                  }}
                >
                  <p style={{ marginTop: "2rem", color: "#D91266" }}>
                    Need help setting up your account. Click here to book a time
                    with one of our Amazon experts.
                  </p>
                </div>
              </div>
            </SweetAlert>
          </>
        )}
        <Grid container style={{ marginLeft: "0px", marginRight: "0px" }}>
          <HubspotCalendarModal
            open={this.state.openCalendarModal}
            onClose={this.handleCloseCalendarModal}
          />
          <Grid
            item
            xs={12}
            md={10}
            style={{
              backgroundColor: "#ffffff",
              height: "100vh",
              paddingLeft: "0px",
              paddingRight: "0px",
              overflowX: "hidden",
              position: "relative",
            }}
          >
            <Grid
              item
              xs={12}
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <Grid item style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.heading}>Amazon Onboarding</div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", marginTop: "5rem" }}
                    >
                      <img
                        src={status}
                        alt="Status"
                        width="90%"
                        style={{ maxWidth: "1100px" }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container className="text-center">
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className="text-center"
                        style={{
                          marginTop: "2rem",
                          fontSize: "26px",
                          fontWeight: 600,
                        }}
                      >
                        Connect Amazon SP-API Account
                      </Grid>
                    </Grid>

                    {!(
                      this.props.amz_onboard.amazon_spapi_access ===
                        "connected" ||
                      this.props.amz_onboard.eu_amazon_spapi_access ===
                        "connected" ||
                      this.props.amz_onboard.fe_amazon_spapi_access ===
                        "connected"
                    ) ? (
                      <>
                        <Grid container style={{ marginTop: "2rem" }}>
                          <Grid
                            item
                            xs={12}
                            className={`text-center ${classes.paragraph}`}
                          >
                            Enable the full Trellis Analytics experience by
                            connecting to SP-API
                          </Grid>
                        </Grid>

                        {this.state.availableRegions?.length > 1 && (
                          <Grid
                            container
                            item
                            xs={12}
                            style={{
                              justifyContent: "center",
                              marginTop: "2rem",
                            }}
                          >
                            <SellerRegionMUI
                              onboarding={true}
                              sellerRegion={this.state.region}
                              assignRegion={(id) => {
                                this.setState({
                                  testDriveErrorMessage: "",
                                  region: id,
                                });
                              }}
                              assessAvailableMarketplaces={
                                this.assessAvailableMarketplaces
                              }
                            />
                          </Grid>
                        )}

                        <Grid
                          container
                          className="text-center"
                          style={{
                            margin: "5rem 1rem 0",
                            justifyContent: "center",
                          }}
                        >
                          <AuthButton
                            title="Connect to Amazon SP-API"
                            buttonStyle="adplan_button"
                            disabled={
                              this.state.disableConnectSpApiButton ||
                              this.props.amz_onboard.amazon_spapi_access ===
                                "connected" ||
                              this.props.amz_onboard.eu_amazon_spapi_access ===
                                "connected" ||
                              this.props.amz_onboard.fe_amazon_spapi_access ===
                                "connected"
                            }
                            onClick={(e) =>
                              this.editAmazonSpApiAccess(this.state.region)
                            }
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container item xs={12}>
                          <div
                            className="text-center"
                            style={{
                              margin: "2rem auto 0",
                              fontSize: "20px",
                              fontWeight: 500,
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            Successfully Connected Amazon Seller Partner API
                          </div>
                        </Grid>

                        <Grid container>
                          <div className="wrap" style={{ marginTop: "2rem" }}>
                            <h4 className="text-center">
                              <img
                                src={success_tick}
                                alt="Connected Advertising Account"
                                className={`center ${classes.successImage}`}
                              />
                            </h4>
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container>
                    <Grid
                      container
                      className="text-center"
                      style={{ marginTop: "6rem", justifyContent: "center" }}
                    >
                      <AuthButton
                        title="Next"
                        buttonStyle="adplan_button"
                        disabled={
                          !(
                            this.props.amz_onboard.amazon_spapi_access ===
                              "connected" ||
                            this.props.amz_onboard.eu_amazon_spapi_access ===
                              "connected" ||
                            this.props.amz_onboard.fe_amazon_spapi_access ===
                              "connected"
                          )
                        }
                        onClick={(e) => this.skipToDashboard()}
                      />
                    </Grid>
                  </Grid>
                  {!(
                    this.state.disableSkipToDashboard ||
                    this.props.amz_onboard.amazon_spapi_access ===
                      "connected" ||
                    this.props.amz_onboard.eu_amazon_spapi_access ===
                      "connected" ||
                    this.props.amz_onboard.fe_amazon_spapi_access ===
                      "connected"
                  ) ? (
                    // IMPORTANT: REMOVE BLOCK BELOW TO ENFORCE SP-API CREDENTIALS IN EVERY ONBOARDING
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        justifyContent: "center",
                        marginTop: "4rem",
                      }}
                    >
                      <Grid container className="wrap">
                        <span
                          style={{
                            fontSize: "19px",
                            fontWeight: 500,
                            marginTop: "2rem",
                          }}
                          onClick={(e) => this.skipToDashboard()}
                        >
                          <CustomHref text={"Skip"} color="secondary" />
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              </Grid>
            </Grid>
            <OnboardingFooter />
          </Grid>
          <Hidden smDown>
            <Grid
              item
              md={2}
              style={{
                backgroundColor: "#2E0054",
                height: "100vh",
                overflow: "hidden",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <Grid
                item
                md={12}
                style={{
                  backgroundColor: "#2E0054",
                  height: "100vh",
                  overflow: "hidden",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  position: "relative",
                }}
              >
                <div
                  md={12}
                  style={{
                    backgroundImage: "url(" + bgImage + ")",
                    maxWidth: "100%",
                    width: "100%",
                    height: "100vh",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      md={12}
                      style={{ textAlign: "center", marginTop: "6rem" }}
                    >
                      <img
                        src={trellislogo}
                        alt="Trellis Logo"
                        width="170px"
                        height="50px"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      style={{
                        left: "-50px",
                        bottom: "1rem",
                        position: "absolute",
                      }}
                    >
                      <img src={bottomArrows} alt="Bottom Arrows" />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        {this.props.user.agreementStatus === null ||
        this.props.user.agreementStatus === "not_agreed" ? (
          <TermsOfService />
        ) : null}
      </>
    );
  }
}

OnboardingSpApi.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  amz_onboard: state.amz_onboard,
  errors: state.errors,
  user: state.user,
});

const mapDispatchToProps = {
  completeAmzProfileSelection,
  updateAmzOnboardingStatus,
  initiateAmzAdvConnect,
  completeAmzAdvConnect,
  setErrors,
  clearErrors,
  setCurrentUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(OnboardingSpApi)));
