import axios from "axios";
import clearCookies from "utils/clearCookies";
import { getAPIKeys, getURLPrefix } from "utils/getUrlPrefix";
import setAuthToken from "utils/setAuthToken";
import {
  AUTHENTICATION_DATA,
  MARKETPLACE,
  SET_CURRENT_USER,
  UNSET_CURRENT_USER,
  USER_LOADING,
  CHANNEL,
  AMZ_ONBOARD,
} from "../types";
import { initialState as initialOnboardDataState } from "redux/reducers/amzOnboardReducer";
import * as Sentry from "@sentry/react";

// Set logged in user action creator
export const setCurrentUser = (user_info) => {
  if (user_info.current_profile) {
    if (user_info.current_profile.length > 21) {
      user_info.current_profile =
        user_info.current_profile.substring(0, 18) + "...";
    }
  }
  return {
    type: SET_CURRENT_USER,
    payload: user_info,
  };
};

const unsetCurrentUser = () => {
  return {
    type: UNSET_CURRENT_USER,
    payload: null,
  };
};

// Save Tokens action creator
export const setTokens = (tokens) => {
  return {
    type: AUTHENTICATION_DATA,
    payload: tokens,
  };
};

// User loading

const apiKeys = getAPIKeys();
const CLIENT_ID = apiKeys["API_AUTH_CLIENT_ID"];
const URL_PREFIX = getURLPrefix();

// Logout
export const logoutUser = () => (dispatch) => {
  dispatch(unsetCurrentUser());
  dispatch(setTokens());
  dispatch({ type: CHANNEL, payload: null });
  dispatch({ type: USER_LOADING, payload: false });
  dispatch({ type: MARKETPLACE, payload: "None" });
  dispatch({ type: AMZ_ONBOARD, payload: initialOnboardDataState });

  var userInfo = {
    client_id: CLIENT_ID,
  };

  axios
    .post(URL_PREFIX + "/logout/", userInfo)
    .catch((err) => {
      // 401s are expected so don't log them
      if (err?.response?.status !== 401) {
        Sentry.captureException(err);
      }
    })
    .finally(() => {
      setAuthToken(false);
      sessionStorage.clear();
      let redirectItem = localStorage.getItem("redirectTo");

      const keysToKeep = [
        "Marketplace",
        "extensionSignup",
        "tableSettings",
        "columnOptions",
        "metricOptions",
        "dismiss_chrome_extension_banner",
      ];

      const mapKeysToKeep = {};

      for (const key of keysToKeep) {
        const value = localStorage.getItem(key);
        if (value && !["null", "undefined"].includes(value)) {
          mapKeysToKeep[key] = value;
        }
      }

      localStorage.clear();

      // Keep track of user's most recently visited marketplace and dashboard preferences upon logout
      for (const key of Object.keys(mapKeysToKeep)) {
        localStorage.setItem(key, mapKeysToKeep[key]);
      }

      if (redirectItem !== null) {
        localStorage.setItem("redirectTo", redirectItem);
      }
      clearCookies();
      Sentry.configureScope((scope) => scope.setUser(null));
    });
};
