import React, { useCallback, useMemo, useRef } from "react";

import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import getObjectValues from "utils/getObjectValues";
import { buildDateColumn } from "views/merchandise/columns/buildDateColumn";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";
import AnnotationCell from "./AnnotationCell";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import AnnotationExpander from "./AnnotationExpander";
import { Row } from "react-bootstrap";

const AnnotationsTable = ({
  data,
  isLoading,
  marketPlace,
  annotationType = "advertisement",
}) => {
  const tableId = `${annotationType}AnnotationsTable`;

  const tableRef = useRef();
  const filterProps = useCustomTableFilters(marketPlace, tableId);

  const annotationData = useMemo(() => {
    return (
      data
        ?.map((a) => a.annotations)
        ?.flat()
        ?.map((a) => ({ ...a, date: a.ts.slice(0, 10) }))
        ?.filter((ann) => {
          if (!annotationType) {
            return ann;
          }
          return ann[annotationType];
        }) ?? []
    );
  }, [data, annotationType]);

  const buildAnnotationColumnSpec = useCallback(
    (key, colTitle, format, statsAccessor, options = {}) => {
      return {
        key: key,
        compareKey: null,
        options: {
          metric: {
            id: key,
            format: format,
            accessor: (d) => {
              const statsKey = Object.keys(d?.stats ?? {})?.[0];
              return d?.stats?.[statsKey]?.[statsAccessor]?.after;
            },
            name: colTitle,
            overrideFormatter:
              format === "number" ? (v) => formatNumber(v, {}, 0) : null,
            options: {
              overrideCompare: true,
              overrideCompareAccessor: (d) => {
                const statsKey = Object.keys(d?.stats ?? {})?.[0];
                return d?.stats?.[statsKey]?.[statsAccessor]?.after_diff;
              },
              overrideCompareFormatter: (v) =>
                format === "percent"
                  ? formatPercent(v, 2, true)
                  : format === "currency"
                  ? formatCurrency(v, marketPlace, false, true, false)
                  : formatNumber(v, { addPlus: true }, 0),
              ...options,
            },
          },
        },
      };
    },
    [marketPlace]
  );

  const columnSpecs = useMemo(() => {
    const cols = [
      buildDateColumn("date", "Date"),
      buildAnnotationColumnSpec("acos", "Daily ACOS", "percent", "daily_acos", {
        isNegative: true,
      }),
      buildAnnotationColumnSpec(
        "ad_sales",
        "Daily Ad Sales",
        "currency",
        "daily_attributed_sales_7d"
      ),
      buildAnnotationColumnSpec(
        "ad_spend",
        "Daily Ad Spend",
        "currency",
        "daily_cost"
      ),
      buildAnnotationColumnSpec(
        "impressions",
        "Daily Impressions",
        "number",
        "daily_impressions"
      ),
      buildAnnotationColumnSpec(
        "clicks",
        "Daily Clicks",
        "number",
        "daily_clicks"
      ),
      buildAnnotationColumnSpec(
        "conversion_rate",
        "Daily Conv. Rate",
        "percent",
        "daily_cr"
      ),
      buildTextColumn("created_by", "Created By", {
        formatter: (v) => {
          if (!v) {
            return "";
          }
          const { first_name, last_name } = v;
          return `${first_name} ${last_name}`;
        },
      }),
    ];

    if (annotationType === "product") {
      cols.splice(
        1,
        0,
        buildAnnotationColumnSpec(
          "sales",
          "Daily Total Sales",
          "currency",
          "daily_total_sales"
        ),
        buildAnnotationColumnSpec(
          "page_views",
          "Daily Page Views",
          "number",
          "daily_page_views"
        ),
        buildAnnotationColumnSpec(
          "bsr_rank",
          "Daily BSR Rank",
          "number",
          "daily_bsr_rank",
          {
            isNegative: true,
          }
        )
      );
      cols.splice(-1, 1);
    }

    return cols;
  }, [annotationType, buildAnnotationColumnSpec]);

  return (
    <div>
      {annotationData?.length || isLoading ? (
        <BulkActionsTable
          marketPlace={marketPlace}
          isLoading={isLoading}
          tableRef={tableRef}
          tableId={tableId}
          noDataText={`There are no ${annotationType} annotations for the selected period`}
          titleCol={{
            id: "description",
            isStatic: true,
            Header: "Events",
            accessor: "description",
            style: { width: "450px" },
            width: 450,
            Cell: ({ original }) => {
              return <AnnotationCell row={original} />;
            },
            filterMethod: (filter, row) => {
              return;
            },
            Filter: ({ filter, onChange }) => null,
          }}
          columnSpecs={columnSpecs}
          data={annotationData ?? []}
          defaultSorted={[{ id: "date", desc: true }]}
          bulkActionOptions={[]}
          isExpandable={true}
          // Eventually if we want to support annotations beyond ads, below can be dynamic based on annotation type (ProductExpander, etc.)
          ExpandedContent={({ row }) => {
            return <AnnotationExpander row={row} />;
          }}
          hideFilterToggleIcon={true}
          filterWidget={true}
          {...filterProps}
          isSelectable={false}
          selectionKey={"id"}
          getSearchCriteria={(row) => {
            return `${getObjectValues(row)}`;
          }}
          tableRowDescription={"Annotation"}
          minRows={0}
        />
      ) : (
        <Row className="d-flex text-center align-items-center pt-5 pb-4">
          <p className="mx-auto text-muted">
            There are no {annotationType} annotations for the selected period
          </p>
        </Row>
      )}
    </div>
  );
};

export default AnnotationsTable;
