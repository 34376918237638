import React, { useCallback, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  NativeSelect,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import logo from "../../../assets/images/logo/Trellis_Logomark_color.png";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import Papa from "papaparse";
import { useMutation } from "react-query";
import api from "../../../utils/api";
import Flags from "country-flag-icons/react/3x2";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => {
  return {
    fileDrop: {
      borderRadius: 4,
      borderStyle: "dashed",
      borderColor: "#CCC",
      borderWidth: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 24,
    },
    fileHover: {
      backgroundColor: "#EFE",
      opacity: "0.4",
      cursor: "move",
    },
    previewTableContainer: {
      maxHeight: 440,
      marginTop: 8,
    },
    previewTableType: {
      minWidth: 64,
    },
    hide: {
      display: "none !important",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#FFF",
      fontWeight: "bold",
    },
    progress: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 24,
    },
  };
});

const MarketplaceMiniSelector = (props) => {
  const countries = [
    ["CA", "Canada", Flags.CA],
    ["USA", "United States", Flags.US],
    ["MX", "Mexico", Flags.MX],
    ["EU", "Europe", null],
    ["UK", "United Kingdom", Flags.UK],
    ["DE", "Germany", Flags.DE],
    ["NL", "Netherlands", Flags.NE],
    ["ES", "Span", Flags.ES],
    ["SE", "Sweden", Flags.SE],
    ["PL", "Poland", Flags.PL],
    ["AE", "UEA", Flags.AE],
    ["FR", "France", Flags.FR],
    ["IT", "Italy", Flags.IT],
    ["IN", "India", Flags.IN],
    ["FE", "Far East", null],
    ["AU", "Australia", Flags.AU],
    ["JP", "Japan", Flags.JP],
    ["SG", "Singapore", Flags.SG],
    ["BE", "Belgium", Flags.BE],
    ["SA", "Saudi Arabia", Flags.SA],
    ["BR", "Brazil", Flags.BR],
  ];

  return (
    <NativeSelect
      value={props.marketPlace}
      disabled={props.disabled}
      onChange={(e) => props.onChange(e.currentTarget.value)}
    >
      {countries.map((c) => {
        return <option value={c[0]}>{c[1]}</option>;
      })}
    </NativeSelect>
  );
};

const ImportDialog = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [parsedResults, setParsedResults] = useState(null);
  const [columnMapping, setColumnMapping] = useState({});
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadResults, setUploadResults] = useState({
    errors: [],
    count: 0,
  });
  const [makeMissingCategories, setMakeMissingCategories] = useState(true);
  const [updateExistingProducts, setUpdateExistingProducts] = useState(true);
  const [marketplace, setMarketplace] = useState("CA");
  const fileUpload = useRef(null);
  const classes = useStyles();

  const { channel } = useSelector((state) => state);

  useEffect(() => {
    setMarketplace(props.marketPlace);
  }, [props.marketPlace]);

  const uploadProduct = useMutation(
    async (action) => {
      return api.post(`/api/v2/import/`, action.data);
    },
    {
      onSuccess: async (response, action) => {
        action.onSuccess();
      },
      onError: async (error, action) => {
        action.onError(error.response.data);
      },
    }
  );

  const _onClose = useCallback(() => {
    // Disable closing the dialog if we're in the middle of the import step.
    if (activeStep !== 1) {
      props.onClose();
    }
  }, [activeStep, props]);

  const handleFile = useCallback(() => {
    if (!fileUpload.current) return;
    setIsLoading(true);

    const file = fileUpload.current.files[0];

    Papa.parse(file, {
      header: true,
      worker: true,
      preview: 25,
      error: (error) => {
        console.log(error);
      },
      complete: (results) => {
        setIsLoading(false);
        setParsedResults(results);
      },
    });
  }, []);

  // Called when a file is picked using the "Upload CSV" button.
  const onFileSelected = useCallback(
    (e) => {
      if (!e.target.files) return;
      handleFile();
    },
    [handleFile]
  );

  // Called when a file is dragged-and-dropped onto the file selector.
  // Sets the contents of the file input element, so we can access this
  // latter without running afoul of protected events.
  const onFileDropped = useCallback(
    (e) => {
      e.preventDefault();
      setIsHovered(false);
      fileUpload.current.files = e.dataTransfer.files;
      handleFile();
    },
    [handleFile]
  );

  // Called when the user hits the final "Import" button.
  const onImport = useCallback(() => {
    const file = fileUpload.current.files[0];

    setActiveStep(1);

    Papa.parse(file, {
      header: true,
      step: (row, parser) => {
        // For now, we temporarily pause parsing until the upload finishes,
        // since we don't want to flood the server.
        parser.pause();

        uploadProduct.mutate({
          data: {
            row: row.data,
            columns: columnMapping,
            import_type: props.importType,
            selected_org: props.selectedOrg,
            make_missing_categories: makeMissingCategories,
            update_existing_products: updateExistingProducts,
            marketplace: marketplace,
          },
          onSuccess: () => {
            setUploadResults((old) => {
              return {
                count: old.count + 1,
                errors: [...old.errors],
              };
            });
            parser.resume();
          },
          onError: (e) => {
            setUploadResults((old) => {
              if (old.errors.length > 50) {
                // Too many errors occurred, lets give up.
                parser.abort();
              } else {
                parser.resume();
              }

              return {
                count: old.count,
                errors: [...old.errors, { error: e, row: row }],
              };
            });
          },
        });

        setUploadProgress((old) => {
          if (!old) return 1;
          return old + 1;
        });
      },
      complete: () => {
        setUploadProgress(null);
        setActiveStep(2);
      },
    });
  }, [
    columnMapping,
    makeMissingCategories,
    marketplace,
    props.importType,
    props.selectedOrg,
    updateExistingProducts,
    uploadProduct,
  ]);

  // A list of the available column choices for the current import type.
  const availableColumns = useMemo(() => {
    if (props.importType === "master") {
      return [
        ["null", "N/A"],
        ["sku", "SKU"],
        ["title", "Title"],
        ["description", "Description"],
        ["landed_price", "Landed Price"],
        ["image_url", "Image URL"],
        ["inventory", "Inventory"],
      ];
    } else if (props.importType === "channel") {
      if (channel.currentChannel === "walmart") {
        return [
          ["null", "N/A"],
          ["channel_id", "Item ID"],
          ["sku", "SKU"],
          ["title", "Product Title"],
          ["category", "Product Category"],
          ["item_page_url", "Item Page URL"],
          ["item_image_url", "Item Image URL"],
          ["product_price", "Product Price"],
          ["primary_variant", "Primary Variant"],
          ["landed_cost", "Landed Cost"],
          ["shipping_cost", "Shipping Cost"],
          ["channel_fees", "Channel Fees"],
        ];
      }

      return [
        ["null", "N/A"],
        ["sku", "SKU"],
        ["upc", "UPC"],
        ["category", "Category"],
        ["channel_id", "Channel ID (ASIN)"],
        ["title", "Title"],
        ["channel_net_price", "Channel Net Price"],
        ["channel_gross_price", "Channel Gross Price"],
      ];
    } else if (props.importType === "pricing_bulk_upload") {
      return [
        ["null", "N/A"],
        ["sku", "SKU"],
        ["channel_id", "Channel ID (ASIN)"],
        ["landed_cost", "Landed Cost"],
        ["shipping_cost", "Shipping Cost"],
        ["fulfillment_channel", "Fulfillment Channel"],
        ["min_price", "Min Price"],
        ["max_price", "Max Price"],
        ["channel_fee_percent", "Channel Fee Percent"],
      ];
    }
  }, [props.importType, channel.currentChannel]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={_onClose}
      disableEscapeKeyDown={activeStep === 1}
      fullWidth
    >
      <DialogTitle>Import Wizard</DialogTitle>

      <Stepper activeStep={activeStep}>
        <Step key={0}>
          <StepLabel>Select File</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>Import</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel>Results</StepLabel>
        </Step>
      </Stepper>
      {props.importType === "pricing_bulk_upload" && (
        <Row>
          <Col className={`py-3 text-center`}>
            <small>
              <a href="/dynamic_pricing_template.csv">Download Template</a>
            </small>
          </Col>
        </Row>
      )}
      {activeStep === 0 ? (
        <>
          <DialogContent>
            <Box
              className={`${classes.fileDrop} ${
                isHovered ? classes.fileHover : ""
              }`}
              onDrop={onFileDropped}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // e.dataTransfer.dropEffect = 'copy';
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsHovered(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.currentTarget.contains(e.relatedTarget)) return;
                setIsHovered(false);
              }}
            >
              <p>
                Drop the file to be imported here, or click the Upload button.
              </p>
              <Button
                variant={"contained"}
                color={"primary"}
                startIcon={<CloudUpload />}
                component={"label"}
              >
                Upload CSV
                <input
                  type={"file"}
                  accept={".csv"}
                  hidden
                  className={classes.hide}
                  onChange={onFileSelected}
                  ref={fileUpload}
                />
              </Button>
            </Box>
            <List>
              {props.importType !== "pricing_bulk_upload" && (
                <>
                  <ListItem>
                    <ListItemText
                      primary={"Create Missing Categories"}
                      style={{
                        // Needed to keep the import button from overlapping
                        // text on small screens.
                        paddingRight: 100,
                      }}
                      secondary={
                        <>
                          Should we create any missing categories? If not
                          selected, products in unknown categories will be
                          skipped.
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <NativeSelect
                        onChange={(e) =>
                          setMakeMissingCategories(e.currentTarget.value)
                        }
                      >
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>
                      </NativeSelect>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={"Update Existing Products"}
                      style={{
                        // Needed to keep the import button from overlapping
                        // text on small screens.
                        paddingRight: 100,
                      }}
                      secondary={
                        <>
                          Should we update products with matching SKUs? If no,
                          products with matching SKUs will be skipped.
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <NativeSelect
                        onChange={(e) =>
                          setUpdateExistingProducts(e.currentTarget.value)
                        }
                      >
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>
                      </NativeSelect>
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              )}
              <ListItem>
                <ListItemText
                  primary={"Marketplace"}
                  style={{
                    // Needed to keep the import button from overlapping
                    // text on small screens.
                    paddingRight: 100,
                  }}
                  secondary={<>What marketplace do these products belong to?</>}
                />
                <ListItemSecondaryAction>
                  <MarketplaceMiniSelector
                    disabled
                    marketPlace={props.marketPlace}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <div style={{ textAlign: "center", padding: 24 }}>
              {isLoading ? (
                <>
                  <img
                    src={logo}
                    alt="Loading..."
                    className="rotate"
                    width="64"
                  />
                  <div>Updating preview, this may take a minute...</div>
                </>
              ) : null}
            </div>
            {parsedResults ? (
              <>
                <span>Preview</span>
                <TableContainer className={classes.previewTableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {parsedResults.meta.fields.map((column) => (
                          <TableCell key={`header-${column}`}>
                            <div>{column}</div>
                            <NativeSelect
                              fullWidth
                              className={classes.previewTableType}
                              onChange={(e) => {
                                const value = e.currentTarget?.value;
                                setColumnMapping((old) => {
                                  return {
                                    ...old,
                                    [column]: value,
                                  };
                                });
                              }}
                            >
                              {availableColumns.map((column) => {
                                return (
                                  <option key={column[0]} value={column[0]}>
                                    {column[1]}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parsedResults.data.map((row, idx) => (
                        <TableRow key={`row-${idx}`}>
                          {parsedResults.meta.fields.map((column) => (
                            <TableCell key={`row-${idx}-${column}`}>
                              {row[column]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            {channel.currentChannel === "walmart" &&
              fileUpload.current?.files &&
              !Object.values(columnMapping).includes("sku") && (
                <span className="text-danger">
                  Please map product SKUs to enable importing
                </span>
              )}
            {channel.currentChannel === "amazon" &&
              fileUpload.current?.files &&
              !Object.values(columnMapping).includes("channel_id") && (
                <span className="text-danger">
                  Please map product ASINs to enable importing
                </span>
              )}

            <Button color={"secondary"} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              disabled={
                !fileUpload.current?.files ||
                (channel.currentChannel === "walmart" &&
                  !Object.values(columnMapping).includes("sku"))
              }
              color={"primary"}
              onClick={onImport}
            >
              Import
            </Button>
          </DialogActions>
        </>
      ) : activeStep === 1 ? (
        <DialogContent>
          <div className={classes.progress}>
            <img src={logo} alt="Loading..." className="rotate" width="64" />
            <div>Uploading row {uploadProgress}...</div>
            <div>Please do not close this page until complete.</div>
          </div>
        </DialogContent>
      ) : activeStep === 2 ? (
        <>
          <DialogContent>
            <p>
              Successfully imported <strong>{uploadResults.count}</strong>{" "}
              products.
            </p>
            {uploadResults.errors.length >= 50 ? (
              <p>
                Cancelled import after more than 50 errors occurred. Please see
                below.
              </p>
            ) : null}
            <TableContainer className={classes.previewTableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadResults.errors.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2}>No errors occurred.</TableCell>
                    </TableRow>
                  ) : (
                    uploadResults.errors.map(({ error, row }) => {
                      return (
                        <TableRow>
                          <TableCell>{error?.mapped?.sku}</TableCell>
                          <TableCell>{error?.error?.msg}</TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button color={"secondary"} onClick={props.onClose}>
              Close
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

ImportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  importType: PropTypes.oneOf(["master", "channel"]),
  selectedOrg: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace.marketPlace,
});

export default connect(mapStateToProps)(ImportDialog);
