import React from "react";
import { HiExternalLink } from "react-icons/hi";
import { useSelector } from "react-redux";
import { PRODUCT_AMAZON_LINKS } from "utils/marketplaceConstants";

const ExternalProductLink = ({ asin }) => {
  const { marketPlace, channel } = useSelector((state) => state);
  let productLink;
  if (channel?.currentChannel === "amazon") {
    productLink = PRODUCT_AMAZON_LINKS?.[marketPlace.marketPlace];
    if (productLink) {
      productLink = `${productLink}${asin}`;
    }
  }
  return productLink ? (
    <a
      href={productLink}
      target="_blank"
      rel="noopener noreferrer"
      title="View on Amazon"
    >
      <HiExternalLink />
    </a>
  ) : null;
};

export default ExternalProductLink;
