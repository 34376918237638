import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useFetch } from "hooks/api";
import { formatCurrency } from "utils/formatNumber";
import { Row, Col } from "react-bootstrap";
import Loading from "components/core/blocks/Loading";

const PriceProfilingSection = ({
  pricingProduct,
  marketPlace,
  handleNotification,
  profileType = "pricing_product",
}) => {
  const [profilingData, setProfilingData] = useState([]);

  const baseQuery = {
    ordering: "expected_start_date",
    limit: "10",
  };

  if (profileType === "pricing_plan") {
    baseQuery["pricing_plan_id"] = pricingProduct.id;
  } else {
    baseQuery["pricing_product_id"] = pricingProduct.id;
  }
  const { isLoading } = useFetch(
    ["pricing/profiling", pricingProduct],
    "/pricing/profiling/",
    { ...baseQuery },
    {
      default: [],
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setProfilingData(
          res.data.results.filter((r) => r.expected_start_date !== "2000-01-01")
        );
      },
    }
  );

  if (!pricingProduct.price_profiling) {
    return null;
  }

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <Row>
      <Col xs={12}>
        {!isLoading && (
          <>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>Price</th>
                  <th style={{ textAlign: "left" }}>Expected Start Date</th>
                  <th style={{ textAlign: "left" }}>Expected End Date</th>
                  <th style={{ textAlign: "left" }}>Start Date</th>
                  <th style={{ textAlign: "left" }}>End Date</th>
                  <th className="text-center">
                    Units Sold - {profilingData.length}
                  </th>
                </tr>
              </thead>
              <tbody>
                {profilingData.length > 0 &&
                  profilingData.map((r, i) => (
                    <tr key={i}>
                      <td>{formatCurrency(r.price, marketPlace)}</td>
                      <td>
                        {r.expected_start_date === "2000-01-01"
                          ? "-"
                          : r.expected_start_date}
                      </td>
                      <td>
                        {r.expected_end_date === "2000-01-01"
                          ? "-"
                          : r.expected_end_date}
                      </td>
                      <td>
                        {r.expected_start_date === "2000-01-01"
                          ? "-"
                          : r.start_date}
                      </td>
                      <td>
                        {r.expected_start_date === "2000-01-01"
                          ? "-"
                          : r.end_date}
                      </td>
                      <td className="text-center">{r.units_sold}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        )}
      </Col>
    </Row>
  );
};

export default PriceProfilingSection;
