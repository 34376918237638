import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import SellerMarketplace from "components/custom/navbar/SellerMarketplace";
import SellerChannel from "components/custom/navbar/SellerChannel";
import SellerAccount from "components/custom/navbar/SellerAccount";
import { connect } from "react-redux";
import Flags from "country-flag-icons/react/3x2";

const MobileNavbarTiles = (props) => {
  const [openMarketplace, setOpenMarketplace] = useState(false);
  const [openChannel, setOpenChannel] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);

  const selectedMarketPlace = (marketPlaceKey) => {
    if (marketPlaceKey === "CA") {
      return "Canada";
    } else if (marketPlaceKey === "USA") {
      return "United States";
    } else if (marketPlaceKey === "MX") {
      return "Mexico";
    } else if (marketPlaceKey === "UK") {
      return "United Kingdom";
    } else if (marketPlaceKey === "DE") {
      return "Germany";
    } else if (marketPlaceKey === "AE") {
      return "UAE";
    } else if (marketPlaceKey === "AU") {
      return "Australia";
    } else if (marketPlaceKey === "ES") {
      return "Spain";
    } else if (marketPlaceKey === "IT") {
      return "Italy";
    } else if (marketPlaceKey === "FR") {
      return "France";
    } else if (marketPlaceKey === "NL") {
      return "Netherlands";
    } else if (marketPlaceKey === "SE") {
      return "Sweden";
    } else if (marketPlaceKey === "PL") {
      return "Poland";
    } else if (marketPlaceKey === "IN") {
      return "India";
    } else if (marketPlaceKey === "JP") {
      return "Japan";
    } else if (marketPlaceKey === "SG") {
      return "Singapore";
    } else if (marketPlaceKey === "BR") {
      return "Brazil";
    } else if (marketPlaceKey === "BE") {
      return "Belgium";
    } else if (marketPlaceKey === "SA") {
      return "Saudi Arabia";
    } else {
      return marketPlaceKey;
    }
  };

  // TODO: Retrieve marketplace flag here
  let flags = {
    Canada: {
      icon: <Flags.CA className="flag-icon" />,
    },
    "United States": {
      icon: <Flags.US className="flag-icon" />,
    },
    Mexico: {
      icon: <Flags.MX className="flag-icon" />,
    },
    "United Kingdom": {
      icon: <Flags.GB className="flag-icon" />,
    },
    Germany: {
      icon: <Flags.DE className="flag-icon" />,
    },
    Netherlands: {
      icon: <Flags.NL className="flag-icon" />,
    },
    Spain: {
      icon: <Flags.ES className="flag-icon" />,
    },
    Sweden: {
      icon: <Flags.SE className="flag-icon" />,
    },
    Poland: {
      icon: <Flags.PL className="flag-icon" />,
    },
    UAE: {
      icon: <Flags.AE className="flag-icon" />,
    },
    France: {
      icon: <Flags.FR className="flag-icon" />,
    },
    Italy: {
      icon: <Flags.IT className="flag-icon" />,
    },
    Belgium: {
      icon: <Flags.BE className="flag-icon" />,
    },
    India: {
      icon: <Flags.IN className="flag-icon" />,
    },
    Australia: {
      icon: <Flags.AU className="flag-icon" />,
    },
    Japan: {
      icon: <Flags.JP className="flag-icon" />,
    },
    Singapore: {
      icon: <Flags.SG className="flag-icon" />,
    },
    Brazil: {
      icon: <Flags.BR className="flag-icon" />,
    },
    "Saudi Arabia": {
      icon: <Flags.SA className="flag-icon" />,
    },
  };

  return (
    <>
      <li style={{ marginBottom: "3rem" }} className="w-100">
        <a
          className="nav-link"
          href="#perf"
          onClick={(e) => {
            e.preventDefault();
            setOpenMarketplace(!openMarketplace);
            setOpenChannel(false);
            setOpenAccount(false);
          }}
        >
          <i className="pe-7s-global"></i>
          <span className="sidebar-link">
            <p className="text-dark-purple">Marketplace</p>
            <b className={openMarketplace ? "caret rotate-180" : "caret"} />
            <p
              style={{ float: "right", margin: "-30px 1rem 0 0 " }}
              className="text-dark-purple"
            >
              {flags[selectedMarketPlace(props.marketPlace.marketPlace)]?.icon}
              {selectedMarketPlace(props.marketPlace.marketPlace)}
            </p>
          </span>
        </a>

        <Collapse in={openMarketplace}>
          <ul className="nav">
            <li style={{ paddingLeft: "5rem" }}>
              <SellerMarketplace mobileSidebarItem={true} />
            </li>
          </ul>
        </Collapse>
      </li>
      <li
        style={{
          marginBottom: "3rem",
        }}
        className="w-100"
      >
        <a
          className="nav-link"
          href="#perf"
          onClick={(e) => {
            e.preventDefault();
            setOpenChannel(!openChannel);
            setOpenMarketplace(false);
            setOpenAccount(false);
          }}
        >
          <i className="pe-7s-shopbag"></i>
          <p className="text-dark-purple">Channel</p>
          <p
            style={{ float: "right", margin: "-30px 1rem 0 0 " }}
            className="text-dark-purple"
          >
            {props.channel.currentChannel}
          </p>
        </a>

        <Collapse in={openChannel}>
          <ul className="nav">
            <li style={{ marginLeft: "5rem" }}>
              <SellerChannel mobileSidebarItem={true} />
            </li>
          </ul>
        </Collapse>
      </li>
      <li style={{ marginBottom: "3rem" }} className="w-100">
        <a
          className="nav-link"
          href="#perf"
          onClick={(e) => {
            e.preventDefault();
            setOpenAccount(!openAccount);
            setOpenMarketplace(false);
            setOpenChannel(false);
          }}
        >
          <i className="pe-7s-users"></i>
          <p className="text-dark-purple">Account</p>
          <p
            style={{ float: "right", margin: "-30px 1rem 0 0 " }}
            className="text-dark-purple"
          >
            {props.user.current_profile}
          </p>
        </a>

        <Collapse in={openAccount}>
          <ul className="nav">
            <li style={{ marginLeft: "5rem" }}>
              <SellerAccount mobileSidebarItem={true} />
            </li>
          </ul>
        </Collapse>
      </li>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  amz_onboard: state.amz_onboard,
  marketPlace: state.marketPlace,
  channel: state.channel,
});

export default connect(mapStateToProps)(MobileNavbarTiles);
