import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import useCreateProgram from "./useCreateProgram";
import { useForm } from "react-hook-form";
import InlineProgramForm from "./InlineProgramForm";
import CreateFooterActions from "./CreateFooterActions";
import SelectTemplate from "./SelectTemplate";

const CreateProgram = ({
  onSuccess,
  onCancel,
  pricingPlanId,
  overrideSize = null,
  onFormChange = () => {},
  deferProgramCreation = false,
  onSubmit,
  engine,
}) => {
  const isStaff = useSelector((state) => state.user.is_staff);

  const [programTemplate, setProgramTemplate] = useState(
    engine === "bidding" ? "CUSTOM" : null
  );

  const { handleSubmit, ...form } = useForm({
    shouldUnregister: false,
  });

  if (form.formState.isDirty) {
    onFormChange();
  }

  const onInvalid = (errors) => console.log("errors", errors);
  const createProgram = useCreateProgram({
    programType: programTemplate,
    pricingPlanId,
    onSuccess,
  });
  return (
    <Row>
      {programTemplate === null && (
        <Col xs={12}>
          <SelectTemplate isStaff={isStaff} onSelect={setProgramTemplate} />
        </Col>
      )}
      {programTemplate && (
        <InlineProgramForm
          form={form}
          programTemplate={programTemplate}
          size={overrideSize}
        >
          <CreateFooterActions
            form={form}
            onCancel={onCancel}
            onInvalid={onInvalid}
            onCreate={
              deferProgramCreation
                ? handleSubmit((formData) => {
                    // Need to pass selected template up to be processed properly
                    onSubmit(formData, programTemplate);
                  })
                : handleSubmit(createProgram.mutate, onInvalid)
            }
            isLoading={createProgram.isLoading || form.formState.isSubmitting}
          />
        </InlineProgramForm>
      )}
    </Row>
  );
};

export default CreateProgram;
