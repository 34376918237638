import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormWizard from "components/core/blocks/AdPlan/FormWizard";
import NotificationSystem from "react-notification-system";
import {
  CategoryField,
  FieldWrapper,
} from "components/core/blocks/AdPlan/fields";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { Row, Col } from "react-bootstrap";
import DetailedProductSelector from "components/core/blocks/ProductSelector/DetailedProductSelector";
import DatePicker from "react-datepicker";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import moment from "moment";
import { formatCurrency, formatPercent } from "utils/formatNumber";
import useOnScreen from "hooks/useOnScreen";
import { useMemo } from "react";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import {
  STEPS,
  DEFAULT_GOALS,
  DEFAULT_BUDGET,
  DEFAULT_EXIT_CRITERIA,
  GOALS_MAP,
  BUDGET_MAP,
  EXIT_CRITERIA_MAP,
  LAUNCH_DURATION_OPTIONS,
} from "./constants";
import { AuthButton } from "components/core/basic/Button";
import { LAUNCH_STATES } from "./constants";
import withMediaQuery from "hocs/withMediaQuery";
import { PrimaryButton } from "assets/themes/Buttons";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import ToolTips from "utils/toolTips";
import ProductDetailsDialogue from "./ProductDetailsDialogue";

const useStyles = (theme) => ({});

const ProductLaunchForm = ({
  marketPlace: { marketPlace },
  editForm,
  launchData,
  id,
  showModal,
  setShowModal,
  mediaQuery,
  modules,
}) => {
  const [formData, setFormData] = useState({
    name: editForm ? launchData?.name : "",
    products: editForm ? launchData?.products : [],
    type: "1",
    goals: editForm
      ? {
          margin: {
            value: launchData?.goal_metrics?.margin,
            checked: launchData?.goal_metrics?.margin ?? false,
          },
          acos: {
            value: launchData?.goal_metrics?.acos,
            checked: launchData?.goal_metrics?.acos ?? false,
          },
          minRating: {
            value: launchData?.goal_metrics?.min_rating,
            checked: launchData?.goal_metrics?.min_rating ?? false,
          },
        }
      : DEFAULT_GOALS,
    budget: editForm
      ? {
          advertising: {
            value:
              +(
                launchData?.advertising_budget /
                moment(launchData?.end_date).diff(
                  moment(launchData?.start_date),
                  "days"
                )
              ).toFixed(2) ?? 0,
            checked: launchData?.advertising_budget ?? false,
          },
          promos: {
            value: +(
              launchData?.promos_budget /
              moment(launchData?.end_date).diff(
                moment(launchData?.start_date),
                "days"
              )
            ).toFixed(2),
            checked: launchData?.promos_budget ?? false,
          },
        }
      : DEFAULT_BUDGET,
    exitCriteria: editForm
      ? {
          sales: {
            value: launchData?.exit_criteria?.sales,
            checked: launchData?.exit_criteria?.sales ?? false,
          },
          units: {
            value: launchData?.exit_criteria?.units,
            checked: launchData?.exit_criteria?.units ?? false,
          },
          numOfRatings: {
            value: launchData?.exit_criteria?.num_of_ratings,
            checked: launchData?.exit_criteria?.num_of_ratings ?? false,
          },
        }
      : DEFAULT_EXIT_CRITERIA,
    duration: editForm
      ? moment(launchData?.end_date).diff(
          moment(launchData?.start_date),
          "days"
        )
      : LAUNCH_DURATION_OPTIONS[1].value,
    startDate: editForm ? new Date(launchData?.start_date) : null,
    extension: 0,
  });
  const [selectedProducts, setSelectedProducts] = useState(
    editForm ? launchData?.products : []
  );
  const [landedCostDetails, setLandedCostDetails] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    editForm ? launchData?.category : null
  );

  const [fieldsToValidate, setFieldsToValidate] = useState(
    editForm ? ["startDate", "duration", "goals", "exitCriteria", "budget"] : []
  );
  const [readyForStep, setReadyForStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(true);

  const [step, setStep] = useState(0);
  const [editStep, setEditStep] = useState(null);

  const history = useHistory();
  const notificationRef = useRef();

  // Detect when markup in viewport, update wizard and step by user viewport without need for buttons
  const formProgressRef = useRef([]);
  const launchTypeRef = useRef();
  const productSelectionRef = useRef();
  const launchGoalsRef = useRef();
  // const reviewRef = useRef();
  formProgressRef.current = STEPS.map(
    (ref, index) => (formProgressRef.current[index] = React.createRef())
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const visibility = [];

  visibility[0] = useOnScreen(launchTypeRef);
  visibility[1] = useOnScreen(productSelectionRef);
  visibility[2] = useOnScreen(launchGoalsRef);
  // visibility[3] = useOnScreen(reviewRef);

  let greatestVisibleStep = useMemo(() => {
    return visibility.lastIndexOf(true);
  }, [visibility]);

  const queryClient = useQueryClient();

  const onCancel = () => {
    history.push("/user/productlaunch");
  };

  const formatLaunchData = () => {
    // SOURCE: https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case-in-javascript
    const camelToSnakeCase = (str) =>
      str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    const formatJSONBlob = (JSON) => {
      let formattedJSON = {};
      for (let key in JSON) {
        if (JSON[key]["checked"] !== false) {
          formattedJSON[camelToSnakeCase(key)] = JSON[key]["value"];
        }
      }

      return formattedJSON;
    };

    let formattedData = {
      name: formData.name,
      state: "INTRODUCED",
      progress: 0,
      budget: parseFloat(formData.budget.advertising.value * formData.duration),
      advertising_budget: parseFloat(
        formData.budget.advertising.value * formData.duration
      ),
      exit_criteria: formatJSONBlob(formData.exitCriteria),
      start_date: formData.startDate,
      end_date: new Date(
        moment(formData.startDate)
          .add(formData.duration + formData.extension, "days")
          .format("MM-DD-YYYY")
      ),
      goal_metrics: formatJSONBlob(formData.goals),
      products: editForm ? launchData?.products : formData.products,
      category: parseInt(selectedCategory),
      marketplace: marketPlace,
    };
    return formattedData;
  };

  const createLaunch = useMutation(
    async (launchData) => {
      return await api.post("/merchandise/product_launch/", launchData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["product_launch_summary"]);

        // Redirect user to newly create launch details view
        let { id } = res.data;

        history.push(`/user/productlaunch/launches/${id}`, {
          showChecklist: true,
        });
      },
      onError: (res) => {
        if (res.status === 201) {
          console.error("error creating new launch => ", res);
        }
      },
    }
  );

  const updateLaunch = useMutation(
    async (launchData) => {
      return await api.put(`/merchandise/product_launch/${id}/`, launchData);
    },
    {
      onSuccess: (res) => {
        typeof setShowModal === "function" && setShowModal(false);
        queryClient.invalidateQueries();
      },
      onError: (res) => {
        if (res.status === 201) {
          console.error("error creating new launch => ", res);
        }
      },
    }
  );

  const onCreate = () => {
    let formattedData = formatLaunchData();

    // TODO: useMutation didn't work for loop below, revisit
    landedCostDetails.forEach((product) => {
      api.patch(`/merchandise/gvproducts/${product.id}/`, {
        landed_cost: parseFloat(product.landed_cost),
        shipping_cost: parseFloat(product.shipping_cost),
        vine_program_enrolled: product.vine_program_enrolled,
      });
    });

    createLaunch.mutate(formattedData);
  };

  const onUpdate = () => {
    let formattedData = formatLaunchData();
    updateLaunch.mutate(formattedData);
    // Triggers updates in charts and stat layout on updated metrics
    queryClient.invalidateQueries();
  };

  const renderCogsDialogue = useMemo(() => {
    return selectedProducts?.some(
      (p) => !p?.landed_cost || typeof p?.landed_cost === "string"
    );
  }, [selectedProducts]);

  // Validate on data change, constantly check if ready to proceed in form workflow
  useEffect(() => {
    (() => {
      const errors = {};

      const check = (condition, key, msg, type = "error", showAll = false) => {
        if (condition) {
          setIsValid(false);

          errors[key] = msg;
        }
      };

      check(
        formData.name?.length < 1 || !formData.name,
        "name",
        "A launch name is required"
      );
      check(!formData?.type, "type", "Launch type is required");
      check(!selectedCategory, "category", "Category is required");
      check(
        (formData?.products?.length < 1 || !formData?.products) && !editForm,
        "products",
        "At least 1 product is required"
      );
      check(
        !Object.values(formData?.goals)?.some(
          (goal) => goal.checked && goal.value
        ),
        "goals",
        "At least 1 launch goal is required"
      );
      check(
        !Object.values(formData?.goals)
          ?.filter((goal) => goal.checked)
          .every((goal) => goal.value),
        "goals",
        "All selected goal fields must have a value"
      );
      check(
        !Object.values(formData?.exitCriteria)?.some(
          (criteria) => criteria.checked && criteria.value
        ),
        "exitCriteria",
        "At least 1 exit criteria is required"
      );
      check(
        !Object.values(formData?.exitCriteria)
          ?.filter((criteria) => criteria.checked)
          .every((criteria) => criteria.value),
        "exitCriteria",
        "All selected exit criteria fields must have a value"
      );
      check(
        !Object.values(formData?.budget)?.some(
          (budgetField) => budgetField.checked && budgetField.value
        ),
        "budget",
        "At least 1 budget field is required"
      );
      check(
        !Object.values(formData?.budget)
          ?.filter((budget) => budget.checked)
          .every((budget) => budget.value),
        "budget",
        "All selected budget fields must have a value"
      );
      check(!formData?.startDate, "startDate", "Start date is required");
      check(!formData?.duration, "duration", "Launch duration is required");

      setErrors(errors);
    })();
  }, [
    step,
    formData,
    fieldsToValidate,
    selectedCategory,
    editForm,
    selectedProducts,
    landedCostDetails,
  ]);

  // Clear product selections when category changed
  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, products: [] }));
    setSelectedProducts([]);
  }, [selectedCategory]);

  const renderValidationMessage = (stateName) => {
    if (errors[stateName] && fieldsToValidate?.includes(stateName)) {
      return (
        <p>
          <small className="text-danger">{errors[stateName]}</small>
        </p>
      );
    }
  };

  const generateFields = (fieldMap, formGroup, review = false) =>
    fieldMap.map((goal, i) => {
      if (review && !formData[formGroup][goal.stateName]?.checked) return null;

      return (
        <Col xs={12} md={editForm ? (formGroup === "budget" ? 8 : 4) : 3}>
          <Row style={{ padding: editForm ? "1rem 0 0" : "2rem 0 0" }}>
            <Col xs={formGroup === "budget" ? 7 : 12}>
              <p
                style={{
                  // marginLeft: review ? "1.5rem" : "2rem",
                  fontSize: "16px",
                  display: "block",
                  fontWeight: 500,
                }}
              >
                {formGroup === "budget" && "Daily "}
                {goal.label}
              </p>
            </Col>
            <Col
              xs={4}
              className={formGroup === "budget" ? "" : "mt-4"}
              hidden={formGroup !== "budget" || review}
            >
              <Switch
                onText=""
                offText=""
                value={
                  formData[formGroup][goal.stateName]?.checked ??
                  goal.defaultChecked
                }
                onColor="#FFFFFF"
                onChange={(e) => {
                  let updatedGoals = {
                    ...formData[formGroup],
                    [goal.stateName]: {
                      ...formData[formGroup][goal.stateName],
                      checked: !formData[formGroup][goal.stateName]?.checked,
                    },
                  };
                  setFormData((prevData) => ({
                    ...prevData,
                    [formGroup]: updatedGoals,
                  }));
                }}
              />
            </Col>
          </Row>
          {(formGroup !== "budget" ||
            formData[formGroup]?.[goal.stateName]?.["checked"]) && (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "2rem",
                marginRight: "1rem",
                marginTop:
                  editForm && formGroup === "budget" ? "-15px" : "inherit",
              }}
            >
              {!review && formGroup !== "budget" && (
                <Col xs={4} className="mt-4">
                  <Switch
                    onText=""
                    offText=""
                    value={
                      formData[formGroup][goal.stateName]?.checked ??
                      goal.defaultChecked
                    }
                    onColor="#FFFFFF"
                    onChange={(e) => {
                      let updatedGoals = {
                        ...formData[formGroup],
                        [goal.stateName]: {
                          ...formData[formGroup][goal.stateName],
                          checked:
                            !formData[formGroup][goal.stateName]?.checked,
                        },
                      };
                      setFormData((prevData) => ({
                        ...prevData,
                        [formGroup]: updatedGoals,
                      }));
                    }}
                  />
                </Col>
              )}
              <Col
                xs={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
                hidden={
                  formGroup === "budget" &&
                  !formData[formGroup]?.[goal.stateName]?.["checked"]
                }
              >
                {review ? (
                  <p style={{ paddingTop: "1rem" }}>
                    {goal.format === "currency"
                      ? formatCurrency(
                          formData[formGroup]?.[goal.stateName]?.["value"],
                          marketPlace.marketPlace,
                          true
                        ) ?? "-"
                      : goal.format === "percent"
                      ? formatPercent(
                          parseFloat(
                            formData[formGroup]?.[goal.stateName]?.["value"]
                          ) / 100
                        ) ?? "-"
                      : formData[formGroup]?.[goal.stateName]?.["value"] ?? "-"}
                  </p>
                ) : (
                  <FormGroupTile
                    type="formcontrol"
                    formControlType={"number"}
                    label={undefined}
                    defaultValue={
                      formData[formGroup][goal.stateName]?.["value"] ??
                      undefined
                    }
                    prefix={
                      goal.format === "currency"
                        ? "$"
                        : goal.format === "percentage"
                        ? "%"
                        : null
                    }
                    prefixSide={
                      goal.format === "currency"
                        ? "left"
                        : goal.format === "percentage"
                        ? "right"
                        : null
                    }
                    handleChildFormElement={(key, value) => {
                      let updatedGoals =
                        formGroup === "budget"
                          ? {
                              ...formData[formGroup],
                              [goal.stateName]: {
                                ...formData[formGroup][goal.stateName],
                                value: value,
                              },
                            }
                          : {
                              ...formData[formGroup],
                              [goal.stateName]: {
                                ...formData[formGroup][goal.stateName],
                                value: value,
                              },
                            };
                      setFormData((prevData) => ({
                        ...prevData,
                        [formGroup]: updatedGoals,
                      }));
                    }}
                    stateName={[formGroup][goal]}
                    placeholder={`Enter Goal`}
                    style={{ marginTop: "15px" }}
                    hidden={
                      formGroup === "budget" &&
                      !formData[formGroup]?.[goal.stateName]?.["checked"]
                    }
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>
      );
    });

  const renderFormStep = (step) => {
    const launchTypeIndex = 0;
    const productSelectionIndex = 1;
    const launchGoalsIndex = 2;

    return (
      <div ref={launchTypeRef}>
        {readyForStep >= launchTypeIndex && (
          <div style={{ margin: mediaQuery ? "75px 35px" : "inherit" }}>
            <Row style={{ marginBottom: "75px" }}>
              <Col
                xs={12}
                md={{ span: 8, offset: 1 }}
                lg={{ span: 6, offset: 1 }}
              >
                <h2 style={{ fontWeight: 700, marginTop: 0, fontSize: "24px" }}>
                  1. Launch Setup
                </h2>
                <span
                  style={{
                    fontSize: "20px",
                    padding: "1rem 0",
                    display: "block",
                    fontWeight: 500,
                  }}
                >
                  Name your product launch
                </span>
                <FieldWrapper
                  displayError={
                    Object.keys(errors)?.includes("name") &&
                    fieldsToValidate?.includes("name")
                  }
                >
                  <div style={{ maxWidth: mediaQuery ? "100%" : "70%" }}>
                    <FormGroupTile
                      formControlClass="text-start"
                      type="formcontrol"
                      formControlType={"text"}
                      handleChildFormElement={(key, value) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          [key]: value,
                        }))
                      }
                      defaultValue={formData.name}
                      stateName={"name"}
                      placeholder={"Plan Name"}
                      disabled={false}
                      autoFocus={true}
                      style={{ border: "1px solid #BBB" }}
                    />
                    {renderValidationMessage("name")}
                  </div>
                </FieldWrapper>
              </Col>
            </Row>
          </div>
        )}

        <div
          ref={productSelectionRef}
          id={"productSelectStep"}
          style={{
            display: readyForStep < productSelectionIndex ? "none" : "inherit",
            minHeight: "75vh",
            margin: mediaQuery ? "75px 35px" : "75px auto",
          }}
        >
          {readyForStep >= productSelectionIndex && (
            <>
              <Row>
                <Col
                  xs={12}
                  md={{ span: 8, offset: 1 }}
                  lg={{ span: 6, offset: 1 }}
                >
                  <h2
                    style={{
                      fontWeight: 700,
                      marginTop: 0,
                      fontSize: "24px",
                    }}
                  >
                    2. Select your products
                  </h2>
                  <FieldWrapper
                    displayError={
                      Object.keys(errors).includes("category") &&
                      fieldsToValidate?.includes("category")
                    }
                    styles={{ marginTop: "1rem" }}
                  >
                    <div style={{ maxWidth: mediaQuery ? "100%" : "70%" }}>
                      <CategoryField
                        // The formatting below is a bit confusing, but to leverage the existing category search need to use nested marketPlace object
                        marketPlace={{ marketPlace: marketPlace }}
                        channel={"amazon"}
                        state={{ categoryId: selectedCategory }}
                        errors={{}}
                        fieldsToValidate={[]}
                        dashboard={true}
                        onChange={(key, value) => {
                          setSelectedCategory(key.categoryId);
                        }}
                      />
                    </div>
                    {renderValidationMessage("category")}
                  </FieldWrapper>
                </Col>
              </Row>
              <Row style={{ marginBottom: "3rem" }}>
                <Col xs={12} lg={{ span: 9, offset: 1 }}>
                  {selectedCategory && renderValidationMessage("products")}
                  <DetailedProductSelector
                    selectorId="npl"
                    allowSelectAll={true}
                    saveProductSelections={async (products, productDetails) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        products,
                      }));
                      setSelectedProducts(productDetails);
                    }}
                    isCreativeProductSelector={false}
                    isLandingPageProductSelector={false}
                    review={step === 3}
                    isModal={false}
                    renderInModal={false}
                    processTableData={(filteredData, productData) => {
                      return filteredData
                        ? filteredData
                        : productData
                            ?.map((p) => (p.is_parent ? p.children : p))
                            .flat() ?? [];
                    }}
                    mediaPlanSelector={false}
                    handleProductSelection={(
                      selectedProducts,
                      productData,
                      filteredData,
                      setSelectedProducts,
                      searchFilter,
                      resolvedTableData,
                      filtered
                    ) => {
                      if (filtered.length > 0) {
                        let combined = [
                          ...selectedProducts,
                          ...resolvedTableData?.map((row) => row._original?.id),
                        ];

                        // Remove visible products if all filtered are currently selected
                        if (
                          resolvedTableData
                            ?.map((row) => row._original?.id)
                            ?.every((id) => selectedProducts?.includes(id))
                        ) {
                          setSelectedProducts(
                            selectedProducts.filter(
                              (id) =>
                                !resolvedTableData
                                  ?.map((row) => row._original?.id)
                                  ?.find((row) => row === id) ?? []
                            )
                          );
                        } else {
                          setSelectedProducts([...new Set(combined)] ?? []);
                        }
                      } else if (searchFilter.length > 0) {
                        let combined = [
                          ...selectedProducts,
                          ...filteredData.map((p) => p.id),
                        ];
                        if (
                          filteredData
                            ?.map((row) => row?.id)
                            ?.every((id) => selectedProducts?.includes(id))
                        ) {
                          setSelectedProducts(
                            selectedProducts.filter(
                              (id) =>
                                !filteredData
                                  ?.map((row) => row?.id)
                                  ?.find((row) => row === id) ?? []
                            )
                          );
                        } else {
                          setSelectedProducts([...new Set(combined)]);
                        }
                      } else {
                        selectedProducts?.length ===
                        productData
                          ?.map((p) => (p.is_parent ? p.children : p))
                          .flat()?.length
                          ? setSelectedProducts([])
                          : setSelectedProducts(
                              productData
                                ?.map((p) => (p.is_parent ? p.children : p))
                                .flat()
                                ?.map((p) => p.id) ?? []
                            );
                      }
                      return;
                    }}
                    assessChecked={(
                      selectedProducts,
                      productData,
                      filteredData,
                      assessSelectAllCheck = false
                    ) => {
                      if (assessSelectAllCheck) {
                        return (
                          selectedProducts?.length >=
                          productData
                            ?.map((p) => (p.is_parent ? p.children : p))
                            .flat()?.length
                        );
                      }
                      return;
                    }}
                    columnsToRender={[
                      "select",
                      "title",
                      "margin",
                      "adSales",
                      "adSpend",
                      "createdOn",
                      { id: "inventoryState", checked: false },
                      { id: "salesState", checked: false },
                    ]}
                    additionalQueryParams={{ merchandise: true }}
                    hideClearAll={true}
                    hideSelectAll={false}
                    selectedCategory={selectedCategory}
                    getPreselectedProducts={() => formData?.products ?? []}
                    hideTagsInput={true}
                    hideLifecycleFilters={true}
                  />
                </Col>
              </Row>
              {renderCogsDialogue ? (
                <Row>
                  <Col xs={12} lg={{ span: 9, offset: 1 }}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: 500,
                        margin: "1rem 0",
                        padding: "1rem 1rem 1rem 0",
                      }}
                    >
                      Landed Cost
                    </span>
                    <p
                      style={{
                        fontSize: "16px",
                        marginBottom: "2rem",
                        maxWidth: mediaQuery ? "100%" : "90%",
                      }}
                    >
                      Please provide the landed cost for the following products
                      to track profit margins throughout the product launch.{" "}
                    </p>
                    <small>
                      * If no landed and shipping costs are provided for the
                      selected products, default values will be used to
                      calculate margin.
                    </small>
                    <ProductDetailsDialogue
                      products={selectedProducts}
                      updateLandedCostDetails={setLandedCostDetails}
                      landedCostDetails={landedCostDetails}
                      marketPlace={marketPlace}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </div>
        <div
          ref={launchGoalsRef}
          style={{
            display: readyForStep < launchGoalsIndex ? "none" : "inherit",
            minHeight: "70vh",
            margin: mediaQuery ? "75px 35px" : "25px auto",
          }}
        >
          {readyForStep >= launchGoalsIndex && (
            <>
              <>
                <Row>
                  <Col xs={12} md={{ span: 8, offset: 1 }}>
                    <h2
                      style={{
                        fontWeight: 700,
                        marginTop: 0,
                        fontSize: "24px",
                      }}
                    >
                      3. Launch Goals
                    </h2>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12} md={{ span: 8, offset: 1 }}>
                    <span
                      style={{
                        fontSize: "20px",
                        padding: "1rem 0",
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      Launch Schedule
                    </span>{" "}
                    <p
                      style={{
                        fontSize: "16px",
                        marginBottom: "2rem",
                        maxWidth: mediaQuery ? "100%" : "90%",
                      }}
                    >
                      Choose the start date and duration of your new product
                      launch. These fields can be updated later.
                    </p>
                    {renderValidationMessage("startDate")}
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 12, offset: 1 }}>
                    <Row style={{ margin: "2rem 0" }}>
                      <Col xs={12} md={3}>
                        <Row>
                          <Col xs={12}>
                            <span
                              style={{
                                fontSize: "16px",
                                padding: "1rem 0",
                                display: "block",
                                fontWeight: 500,
                              }}
                            >
                              Start Date
                            </span>
                          </Col>
                          <Col xs={12}>
                            <DatePicker
                              selected={formData?.startDate}
                              onChange={(date) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  startDate: date,
                                }))
                              }
                              style={{ width: "100%" }}
                              onClick={(e) => e.preventDefault}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={3}>
                        <Row>
                          <Col xs={12}>
                            <span
                              style={{
                                fontSize: "16px",
                                padding: "1rem 0",
                                display: "block",
                                fontWeight: 500,
                              }}
                            >
                              Duration
                            </span>
                          </Col>
                          <Col xs={12}>
                            <Row style={{ paddingLeft: "1rem" }}>
                              <Select
                                options={LAUNCH_DURATION_OPTIONS}
                                value={LAUNCH_DURATION_OPTIONS.find(
                                  (d) => d.value === formData.duration
                                )}
                                onChange={(d) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    duration: d.value,
                                  }))
                                }
                              />
                              {renderValidationMessage("duration")}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row>
                  <Col xs={12} md={{ span: 8, offset: 1 }}>
                    <span
                      style={{
                        fontSize: "20px",
                        padding: "1rem 0",
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      Budget
                    </span>{" "}
                    <p
                      style={{
                        fontSize: "16px",
                        marginBottom: "2rem",
                        maxWidth: mediaQuery ? "100%" : "90%",
                      }}
                    >
                      Select your advertising{" "}
                      {checkModule(modules, "MERCHANDISE_PLANS")
                        ? "and promotions "
                        : ""}
                      budget during the launch. These values will be used to
                      help you quickly set up media plans
                      {checkModule(modules, "MERCHANDISE_PLANS")
                        ? " and promotions"
                        : ""}
                      .
                    </p>
                    {renderValidationMessage("budget")}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={{ span: 12, offset: 1 }}>
                    <Row>{generateFields(BUDGET_MAP, "budget")}</Row>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row>
                  <Col xs={12} md={{ span: 8, offset: 1 }}>
                    <span
                      style={{
                        fontSize: "20px",
                        padding: "1rem 0",
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      Exit Criteria
                    </span>{" "}
                    <p
                      style={{
                        fontSize: "16px",
                        marginBottom: "2rem",
                        maxWidth: mediaQuery ? "100%" : "90%",
                      }}
                    >
                      Select which metrics you want to use to track the progress
                      of your launch.
                    </p>{" "}
                    {renderValidationMessage("exitCriteria")}
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 12, offset: 1 }}>
                    <Row>
                      {generateFields(
                        EXIT_CRITERIA_MAP.slice(0, 3),
                        "exitCriteria"
                      )}
                    </Row>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row>
                  <Col xs={12} md={{ span: 8, offset: 1 }}>
                    <span
                      style={{
                        fontSize: "20px",
                        padding: "1rem 0",
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      Goals
                    </span>
                    <p
                      style={{
                        fontSize: "16px",
                        marginBottom: "2rem",
                        maxWidth: mediaQuery ? "100%" : "90%",
                      }}
                    >
                      Set launch goals that you wish to achieve throughout the
                      product launch. These will be used to recommend a product
                      categorization once the launch is complete.
                    </p>

                    {renderValidationMessage("goals")}
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col xs={12} md={{ span: 12, offset: 1 }}>
                    <Row>{generateFields(GOALS_MAP.slice(0, 3), "goals")}</Row>
                  </Col>
                </Row>
              </>

              <Row>
                <Col
                  xs={12}
                  md={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                  }}
                >
                  <AuthButton
                    onClick={() => onCreate()}
                    title="Create Launch"
                    buttonStyle="adplan_button"
                    disabled={Object.keys(errors)?.length}
                    id="submit-button"
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <NotificationSystem ref={notificationRef} />
      {editForm ? (
        <>
          <div style={{ visibility: "none" }}>
            {/* <div ref={reviewRef}></div> */}
            <div ref={launchGoalsRef} />
            <div ref={launchTypeRef} />
            <div ref={productSelectionRef} />
          </div>
          <Row>
            {launchData?.state.toLowerCase() ===
            LAUNCH_STATES.LAUNCHED.value?.toLowerCase() ? (
              <Row style={{ display: "flex" }}>
                <Col xs={12} style={{ margin: "auto", textAlign: "center" }}>
                  <p>
                    Please select a duration below to extend your launch by.
                    After extending, you may edit your launch goals.
                  </p>

                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "2rem auto",
                    }}
                  >
                    <Col xs={12} md={8}>
                      <span
                        style={{
                          fontSize: "16px",
                          padding: "1rem 0",
                          display: "block",
                          fontWeight: 500,
                          marginRight: "auto",
                          textAlign: "left",
                        }}
                      >
                        Extend By
                      </span>
                      <Select
                        options={LAUNCH_DURATION_OPTIONS}
                        value={LAUNCH_DURATION_OPTIONS.find(
                          (d) =>
                            d.value === formData.extension ??
                            LAUNCH_DURATION_OPTIONS[0]
                        )}
                        onChange={(d) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            extension: d.value,
                          }))
                        }
                      />{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "40px auto 20px",
                      }}
                    >
                      <AuthButton
                        onClick={() => onUpdate()}
                        title="Extend Launch"
                        buttonStyle="adplan_button"
                        disabled={Object.keys(errors).length}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                <Row className="mt-4">
                  <Col xs={12} md={6} className="pr-5">
                    <Row>
                      <Col xs={12}>
                        <span
                          style={{
                            fontSize: "20px",
                            paddingBottom: "1rem",
                            display: "block",
                            fontWeight: 500,
                          }}
                        >
                          Launch Schedule
                          <ToolTips
                            toolTip={
                              "Choose the start date and duration of your new product launch. These fields can be updated later."
                            }
                            position="top"
                            id={"launchSchedule"}
                          />
                        </span>

                        {renderValidationMessage("startDate")}
                      </Col>
                      <Col xs={6}>
                        <Row>
                          <Col xs={12}>
                            <span
                              style={{
                                fontSize: "16px",
                                padding: "1rem 0",
                                display: "block",
                                fontWeight: 500,
                              }}
                            >
                              Start Date
                            </span>
                          </Col>
                          <Col xs={12}>
                            <DatePicker
                              selected={formData?.startDate}
                              onChange={(date) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  startDate: date,
                                }))
                              }
                              minDate={new Date(moment().format("MM-DD-YYYY"))}
                              style={{ width: "100%" }}
                              onClick={(e) => e.preventDefault}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={12}>
                            <span
                              style={{
                                fontSize: "16px",
                                padding: "1rem 0",
                                display: "block",
                                fontWeight: 500,
                              }}
                            >
                              Duration
                            </span>
                          </Col>
                          <Col xs={12}>
                            <Row style={{ paddingLeft: "1rem" }}>
                              <Select
                                options={LAUNCH_DURATION_OPTIONS}
                                value={LAUNCH_DURATION_OPTIONS.find(
                                  (d) => d.value === formData.duration
                                )}
                                onChange={(d) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    duration: d.value,
                                  }))
                                }
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className="pl-5">
                    <span
                      style={{
                        fontSize: "20px",
                        paddingBottom: "1rem",
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      Budget
                      <ToolTips
                        toolTip={
                          checkModule(modules, "MERCHANDISE_PLANS")
                            ? `Select your advertising and promotion budget during the launch. These values will be used to help you quickly set up new media plans and promotions.`
                            : `Select your advertising budget during the launch. These values will be used to help you quickly set up new media plans.`
                        }
                        position="top"
                        id={"launchSchedule"}
                      />
                    </span>

                    {renderValidationMessage("budget")}
                    <Row>
                      {generateFields(BUDGET_MAP.slice(0, 3), "budget")}
                    </Row>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Col xs={12}>
                  <span
                    style={{
                      fontSize: "20px",
                      paddingBottom: "1rem",
                      display: "block",
                      fontWeight: 500,
                    }}
                  >
                    Exit Criteria{" "}
                    <ToolTips
                      toolTip={
                        "Select which metrics you want to use to track the progressof your launch."
                      }
                      position="top"
                      id={"launchSchedule"}
                    />
                  </span>
                  {renderValidationMessage("exitCriteria")}
                  <Row>
                    {generateFields(
                      EXIT_CRITERIA_MAP.slice(0, 3),
                      "exitCriteria"
                    )}
                  </Row>
                </Col>
                <hr className="my-5" />
                <Col xs={12}>
                  <span
                    style={{
                      fontSize: "20px",
                      paddingBottom: "1rem",
                      display: "block",
                      fontWeight: 500,
                    }}
                  >
                    Launch Goals{" "}
                    <ToolTips
                      toolTip={
                        "Set launch goals that you wish to achieve throughout the product launch. These will be used to recommend a product categorization once the launch is complete."
                      }
                      position="top"
                      id={"launchSchedule"}
                    />
                  </span>
                  {renderValidationMessage("goals")}
                  <Row>{generateFields(GOALS_MAP.slice(0, 3), "goals")}</Row>
                </Col>

                <Row>
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "40px auto 20px",
                    }}
                  >
                    <AuthButton
                      onClick={() => onUpdate()}
                      title="Update Launch"
                      buttonStyle="adplan_button"
                      disabled={Object.keys(errors).length}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </>
      ) : (
        <FormWizard
          errors={errors}
          isValid={true}
          isModal={false}
          closeModal={() => {}}
          fieldsToValidate={fieldsToValidate}
          setFieldsToValidate={(fields) => setFieldsToValidate(fields)}
          setReadyForStep={setReadyForStep}
          updateStep={(newStep, newSubstep = null) => {
            setStep(newStep);
            return newStep;
          }}
          editStep={editStep}
          setEditStep={setEditStep}
          formSteps={STEPS}
          onCreate={onCreate}
          onUpdate={onUpdate}
          clearForm={() => {
            setFormData({
              goals: {},
              budget: {},
              exitCriteria: {},
            });
            setSelectedCategory(undefined);
            setSelectedProducts([]);
          }}
          onCancel={onCancel}
          showCancelButton={true}
          completeText={"Activate"}
          assessFormSteps={() => {}}
          formStepDependencies={[]}
          formToRender={<>{renderFormStep(step)}</>}
          reset={() => {
            setFormData({
              goals: {},
              budget: {},
              exitCriteria: {},
            });
            setSelectedCategory(undefined);
            setSelectedProducts([]);
          }}
          // Below for syncing latest visible step with form wizard
          autoExpandOn={true}
          autoExpandStep={greatestVisibleStep}
          formProgressRef={formProgressRef}
          formProgressRefs={[
            launchTypeRef,
            productSelectionRef,
            launchGoalsRef,
            // reviewRef,
          ]}
          onExit={() => {
            history.push("/user/productlaunch");
          }}
          hidePreviousButtonVisibility={!step}
          exitButton={
            <PrimaryButton
              onClick={(e) => {
                e.preventDefault();
                history.push("/user/productlaunch");
              }}
              style={{
                width: "70%",
              }}
            >
              Exit
            </PrimaryButton>
          }
          wizardPaginationVerticalOffset={"-100px"}
        />
      )}
    </>
  );
};

ProductLaunchForm.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  modules: selectModules(state),
});

export default withMediaQuery("(max-width:600px)")(
  connect(mapStateToProps)(withStyles(useStyles)(ProductLaunchForm))
);
