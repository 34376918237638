import React, { useMemo, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useFetch } from "hooks/api";
import { Col, Container as Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DownloadReport from "modules/perf_reports/DownloadReport";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import Loading from "components/core/blocks/Loading";
import getMetricSpec from "views/merchandise/metrics";
import { selectModules } from "redux/selectors/modules";
import MetricsCard from "components/custom/merchandise/MetricsCard";
import withAdPlan from "components/core/blocks/AdPlan/withAdPlan";
import NotificationSystem from "react-notification-system";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import acosOrRoas from "utils/acosOrRoas";
import {
  ORG_SUMMARY_REPORT_BREADCRUMB_SPEC,
  REPORT_LINKS,
} from "utils/dashboardLinks";
import TrellisLink from "components/core/basic/TrellisLink";
import ReactTable from "react-table-6";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatNumber";
import checkModule from "utils/checkModule";

const buildMetric = (marketPlace, data, id, compareId, options) => {
  const metric = getMetricSpec(marketPlace, id, options?.metric);
  const compare = getMetricSpec(marketPlace, compareId, options?.compare);
  if (metric.totalOverrideAccessor) {
    const totalMetric = getMetricSpec(
      marketPlace,
      metric.totalOverrideAccessor
    );
    metric.overrideValue = totalMetric.accessor(data?.total);
  }
  metric.actual = metric.accessor(data?.total);
  metric.target = compare.accessor(data?.total);
  metric.targetFormatter = compare.formatter;
  return metric;
};

const ItemLink = ({ id, children }) => {
  const to = `/user/advertising/dashboard/ads/${id}?tab=details`;

  return (
    <TrellisLink
      to={to}
      style={{
        textDecoration: "none",
        fontWeight: "500",
        fontSize: "1.5rem",
        cursor: "pointer",
      }}
    >
      {children}
    </TrellisLink>
  );
};

const Table = ({ rows, marketPlace, isLoading, tableRef, preferences }) => {
  const transformedRows = useMemo(() => {
    return (
      rows?.map((p) => {
        p.target_roas = +((1 / p["target_acos"]) * 100).toFixed(2);
        p.min_roas = +((1 / p["max_acos"]) * 100).toFixed(2);

        return { ...p };
      }) ?? []
    );
  }, [rows]);

  const columns = useMemo(
    () => [
      {
        Header: "Advertisement",
        width: 200,
        accessor: "ad_name",
        headerStyle: { textAlign: "left" },
        style: { textAlign: "left" },
        Cell: (props) => (
          <ItemLink id={props?.original?.ad_id}>{props.value}</ItemLink>
        ),
      },
      {
        Header: "Ad Sales",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "ad_sales",
        Cell: (props) => formatCurrency(props.value, marketPlace.marketPlace),
      },
      {
        Header: "Ad Spend",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "cost",
        Cell: (props) => formatCurrency(props.value, marketPlace.marketPlace),
      },
      acosOrRoas(
        {
          Header: "ACOS",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "acos",
          Cell: (props) => `${formatPercent(props.values, 2)}%`,
        },
        {
          Header: "ROAS",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          accessor: "roas",
          Cell: (props) => `${formatNumber(props.value, 2)}`,
        }
      ),
      {
        Header: "Ad Units",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "conversion",
        Cell: (props) => formatNumber(props.value),
      },
      {
        Header: "Impressions",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "impressions",
        Cell: (props) => formatNumber(props.value),
      },
      {
        Header: "Clicks",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "clicks",
        Cell: (props) => formatNumber(props.value),
      },
      {
        Header: "CTR",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "click_thru_rate",
        Cell: (props) => `${formatPercent(props.value, 2)}`,
      },
      {
        Header: "CR",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        accessor: "conv_rate",
        Cell: (props) => `${formatPercent(props.value, 2)}`,
      },
    ],
    [marketPlace.marketPlace]
  );

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }
  return (
    <>
      <ReactTable
        tableRef={tableRef}
        tableId="advertisement"
        columns={columns}
        minRows={0}
        defaultPageSize={100}
        data={transformedRows}
        defaultSorted={[{ id: "ad_sales", desc: true }]}
        isLoading={isLoading}
      />
    </>
  );
};

const Dashboard = ({ marketPlace, user, modules, channel }) => {
  const notificationRef = useRef();
  const { preferences } = useMerchandisePreferences();

  const mobileStyle = useMediaQuery("(max-width:600px)");

  const { group, start, end, preStart, preEnd } = useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    channel,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    group_by: "ad_id",
    empty: false,
  };

  const { data, isLoading, isSuccess, isError, isFetching } = useFetch(
    ["ad_report", marketPlace.marketPlace, start, end, preStart, preEnd, group],
    `/api/data_report/ad_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      default: [],
    }
  );

  const tableRef = useRef();

  const { overviewMetrics } = useMemo(() => {
    const acosOrRoas = user?.preferences?.acos_or_roas ?? "acos";
    const roasMetrics = [
      buildMetric(marketPlace, data, "roas", "roas_delta"),
      buildMetric(marketPlace, data, "troas", "troas_delta"),
    ];
    const acosMetrics = [
      buildMetric(marketPlace, data, "acos", "acos_delta"),
      buildMetric(marketPlace, data, "total_acos", "total_acos_delta"),
    ];
    return {
      overviewMetrics: {
        metrics: [
          buildMetric(marketPlace, data, "sales", "sales_percent"),
          buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
          buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
          ...(acosOrRoas === "acos" ? acosMetrics : roasMetrics),
          ...(acosOrRoas === "acos" ? roasMetrics : acosMetrics),
          buildMetric(marketPlace, data, "clicks", "clicks_percent"),
          buildMetric(marketPlace, data, "impressions", "impressions_percent"),
        ],
      },
    };
  }, [data, marketPlace, user.preferences.acos_or_roas]);

  const breadCrumbItems = [
    {
      name: "Advertisement Report",
      href: "/user/report/advertisement",
      dropdownLinkOptions: REPORT_LINKS,
      searchable: false,
      dropdownSubtitle: "Reports",
    },
  ];

  if (
    checkModule(modules, "ORG_SUMMARY_REPORT") &&
    !breadCrumbItems[0].dropdownLinkOptions?.find(
      (o) => o.name === ORG_SUMMARY_REPORT_BREADCRUMB_SPEC.name
    )
  ) {
    breadCrumbItems[0].dropdownLinkOptions.unshift(
      ORG_SUMMARY_REPORT_BREADCRUMB_SPEC
    );
  }

  const rows = useMemo(() => {
    return (
      data?.adplans.map((adplan) => ({
        ...adplan,
        id: adplan.ad_id,
      })) ?? []
    );
  }, [data]);

  return (
    <>
      <NotificationSystem ref={notificationRef} />

      <Grid
        fluid
        style={{
          background: "#fefefe",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        <Row>
          <Col xs={12} className="mb-4">
            <BreadcrumbDates items={breadCrumbItems} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {isLoading && <Loading />}
            {isError && <p>Error Loading Dashboard</p>}
            {isSuccess && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col xs={12}>
                    <MetricsCard
                      id={"advertingOverview"}
                      title={"Performance Overview"}
                      mobileStyle={mobileStyle}
                      data={data?.date}
                      isLoading={isFetching}
                      metrics={overviewMetrics.metrics}
                      showMetricsSelector
                      initialSelectedMetric={["ad_sales", "ad_spend", "roas"]}
                      group={group}
                      showAnnotations={true}
                      byline={
                        <>
                          {start.format("MMMM Do YYYY")}
                          {" - "}
                          {end.format("MMMM Do YYYY")}
                          {isLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                        </>
                      }
                    >
                      <Row>
                        <Col
                          xs={12}
                          className="d-flex justify-content-end mb-4"
                        >
                          <DownloadReport
                            url_path={"ad_report"}
                            filters={{ ...filters, empty: false }}
                            report_dl={"ads"}
                          />
                        </Col>
                      </Row>
                      <Col xs={12}>
                        <Table
                          tableRef={tableRef}
                          rows={rows}
                          marketPlace={marketPlace}
                          isLoading={isLoading}
                          modules={modules}
                          preferences={preferences}
                        />
                      </Col>
                    </MetricsCard>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
};

const AdvertisingReport = ({ user, marketPlace, modules, channel }) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <Dashboard
        user={user}
        marketPlace={marketPlace}
        modules={modules}
        channel={channel}
      />
    </DatesProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  errors: state.errors,
  marketPlace: state.marketPlace,
  modules: selectModules(state),
  channel: state.channel.currentChannel,
});

export default connect(mapStateToProps)(withAdPlan(AdvertisingReport));
