import NavFilterTile from "components/core/blocks/NavFilterTile";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { setChannel } from "redux/actions/channel";
import { setMarketPlace } from "redux/actions/marketPlace";
import { selectModules } from "redux/selectors/modules";
import axios from "axios";
import { getURLPrefix } from "utils/getUrlPrefix";
// import { withRouter } from "react-router-dom";

let URL_PREFIX = getURLPrefix();

const data = {
  Amazon: { amazon: false },
  Google: { google: true },
  Walmart: { walmart: true },
  Facebook: { facebook: true },
};

class SellerChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownSelectedChannel: null,
      selectedChannelID: null,
      data: data,
    };
  }

  fetchWalmartProfiles() {
    let AuthStr = "Bearer ".concat(this.props.auth.tokens.access_token);
    axios
      .get(URL_PREFIX + "/api/accounts/", {
        params: {
          channel: "walmart",
        },
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        // See if at least one Walmart profile found
        let walmartProfileExists =
          Object.keys(res.data.seller)?.length ||
          Object.keys(res.data.vendor)?.length;

        this.setState(
          {
            walmartProfileAvailable: walmartProfileExists,
          },
          () => {
            if (walmartProfileExists) {
              this.setState({
                data: {
                  ...this.state.data,
                  // Below is a bit confusing, but setting walmart to false enables it in the channel dropdown
                  Walmart: { walmart: !walmartProfileExists },
                },
              });
            }
          }
        );
      });
  }

  switchChannel = async (id, channelClick = false) => {
    let selectedChannelName = null;
    if (id === "google") {
      selectedChannelName = "Google";
    } else if (id === "amazon") {
      selectedChannelName = "Amazon";
    } else if (id === "walmart") {
      selectedChannelName = "Walmart";
    }

    await this.setState({
      dropDownSelectedChannel: selectedChannelName,
      selectedChannelID: id,
    });

    if (channelClick) {
      this.props.setChannel(id);
      localStorage.setItem("channel", id);
      window.location.reload();
    }
  };

  componentDidMount() {
    let id = this.props.channel.currentChannel;

    const channels = {
      Amazon: { amazon: false },
      Walmart: { walmart: true },
      // Facebook: { FB: true },
    };

    this.setState({
      data: channels,
    });
    this.switchChannel(id);
    this.fetchWalmartProfiles();
  }

  componentDidUpdate() {
    if (this.props.channel.currentChannel !== this.state.selectedChannelID) {
      let id = this.props.channel.currentChannel;
      this.switchChannel(id);
    }
  }

  onChannelClick = (id) => {
    this.switchChannel(id, true);
  };

  render() {
    return (
      <NavFilterTile
        defaultValue={
          this.state.dropDownSelectedChannel
            ? this.state.dropDownSelectedChannel
            : undefined
        }
        handleChildComponent={this.onChannelClick}
        data={this.state.data}
        fontSize="14px"
        mobileSidebarItem={this.props.mobileSidebarItem}
      />
    );
  }
}

// export default withRouter(SellerChannels);
SellerChannels.propTypes = {
  setChannel: PropTypes.func.isRequired,
  marketPlace: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  channel: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  auth: state.auth,
  errors: state.errors,
  channel: state.channel,
  modules: selectModules(state),
});

const mapDispatchToProps = {
  setChannel,
  setMarketPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerChannels);
