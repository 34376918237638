export const selectModules = (state) => {
  const userModules = state.user.modules ?? [];
  const orgId = state.user.organization_id;
  if (!orgId) {
    return [];
  }
  const overrideModules = state.overrideModules?.[orgId] ?? [];

  return [
    ...userModules?.filter(
      (m) => !overrideModules.find((o) => o.name === m.name)
    ),
    ...overrideModules,
  ];
};
