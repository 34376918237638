import React, { useState, useEffect } from "react";

import { Badge } from "react-bootstrap";
import SearchBar from "components/core/blocks/SearchBar2";
import { useFetch } from "hooks/api";
import { useSelector } from "react-redux";
import Loading from "components/core/blocks/Loading";
import { PermissionsFlag } from "components/core/basic/Flag";
import CenteredModal from "components/core/basic/CenteredModal";
import SearchResult from "./SearchResult";
import { useLocation } from "react-router";
import InfiniteScrollWrapper from "views/InfiniteScrollWrapper";
import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";

const TOP_RESULTS_COUNT = 100;

const RESULT_GROUPS = [
  { value: "categories", label: "Categories" },
  { value: "ads", label: "Media Plans" },
  { value: "products", label: "Products" },
  { value: "pricing_plans", label: "Pricing Plans" },
  {
    value: "digital_shelves",
    label: "Digital Shelves",
  },
  {
    value: "new_product_launches",
    label: "Product Launches",
  },
  { value: "product_content", label: "Product Content" },
  { value: "product_content_templates", label: "Content Templates" },
];

const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResultModal, setShowSearchResultModal] = useState(false);
  const [resultFilter, setResultFilter] = useState("top");

  const location = useLocation();

  const state = useSelector((state) => state);

  const {
    user,
    marketPlace: { marketPlace },
    amz_onboard,
    channel: { currentChannel: channel },
  } = state;

  const modules = selectModules(state);

  // TODO: Need to consolidate these to one place - ideally in DashboardContext coming in future MRs
  const ANALYTICS_SUBSCRIPTION =
    user.analytics_tier &&
    !["unknown", "MANUALLY_DISABLED"].includes(user.analytics_tier);

  const ADVERTISING_SUBSCRIPTION =
    user.tier &&
    !["unknown", "MANUALLY_DISABLED"].includes(user.tier) &&
    (["active", "trial_active", "demo_org"].includes(amz_onboard.org_status) ||
      channel === "walmart");

  const DYNAMIC_PRICING_SUBSCRIPTION =
    (user.pricing_tier &&
      user.pricing_tier !== "unknown" &&
      ["active", "trial_active", "demo_org"].includes(
        amz_onboard.org_status
      )) ||
    checkModule(modules, "PRICE_OPTIMIZATION") ||
    checkTierAccess(user.tier, "pricing", amz_onboard.org_status);

  const CONTENT_SUBSCRIPTION =
    ((((user.content_tier && user.content_tier !== "unknown") ||
      location?.state?.firstVisit ||
      location?.state?.onboardingComplete) &&
      ["active", "trial_active", "demo_org"].includes(
        amz_onboard.org_status
      )) ||
      checkModule(modules, "PRODUCT_SEO") ||
      checkTierAccess(user.tier, "content", amz_onboard.org_status)) &&
    user.content_tier !== "MANUALLY_DISABLED";

  const userApplicationAccess = {
    categories: true,
    ads: ADVERTISING_SUBSCRIPTION,
    products: true,
    new_product_launches: true,
    pricing_plans: DYNAMIC_PRICING_SUBSCRIPTION,
    digital_shelves: ANALYTICS_SUBSCRIPTION,
    product_content: CONTENT_SUBSCRIPTION,
    product_content_templates: CONTENT_SUBSCRIPTION,
  };

  useEffect(() => {
    if (searchQuery && !showSearchResultModal) {
      setShowSearchResultModal(true);
    }
    setResultFilter("top");
  }, [searchQuery, showSearchResultModal]);

  useEffect(() => {
    setShowSearchResultModal(false);
    setSearchQuery("");
  }, [location.pathname]);

  const { data: searchResults, isLoading: searchResultsLoading } = useFetch(
    [searchQuery],
    "/search/",
    {
      marketplace: marketPlace,
      searchquery: searchQuery.trim(),
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      default: [],
      enabled: searchQuery?.length ? true : false,
      select: (res) => {
        const results = res.data.results ?? {};

        results.categories = results?.categories?.map((category) => ({
          ...category,
          type: "category",
        }));
        results.ads = results.ads?.map((ad) => ({
          ...ad,
          type: "ad",
        }));
        results.products = results.products?.map((product) => ({
          ...product,
          type: "product",
        }));
        results.pricing_plans = results.pricing_plans?.map((plan) => ({
          ...plan,
          type: "pricingPlan",
        }));
        results.digital_shelves = results.digital_shelves?.map((shelf) => ({
          ...shelf,
          type: "digitalShelf",
        }));
        results.new_product_launches = results.new_product_launches?.map(
          (launch) => ({
            ...launch,
            type: "productLaunch",
          })
        );
        results.product_content_templates =
          results.product_content_templates?.map((template) => ({
            ...template,
            type: "productContentTemplate",
          }));
        results.product_content = results.product_content?.map((seo) => ({
          ...seo,
          type: "productContent",
        }));
        return results;
      },
    }
  );

  const topResults = searchResults
    ? Object.values(searchResults)
        ?.flat()

        ?.filter((result) => result?.type)
        ?.sort((a, b) => {
          if (!a.search_rank) {
            a.search_rank = 0;
          }

          if (!b.search_rank) {
            b.search_rank = 0;
          }

          return b.search_rank - a.search_rank;
        })
        ?.slice(0, TOP_RESULTS_COUNT)
    : [];

  const resultsContainerScroll = true;

  return (
    <>
      <PermissionsFlag role="admin" roles={user.permissions}>
        <SearchBar
          handleSearchQuery={setSearchQuery}
          defaultQueryValue={searchQuery}
          pathname={location?.pathname}
        />
        <div>
          <CenteredModal
            searchModal={true}
            show={showSearchResultModal}
            onHide={() => setShowSearchResultModal(false)}
            closeModal={() => {
              setShowSearchResultModal(false);
              setSearchQuery("");
            }}
            modalBodyClasses={"search-results-modal"}
            content={
              <div>
                <>
                  <div className="mx-auto w-75">
                    <SearchBar
                      handleSearchQuery={setSearchQuery}
                      defaultQueryValue={searchQuery}
                      pathname={location?.pathname}
                      resultModalSearchbar={true}
                    />
                    <h3
                      className="fs-3 text-center mt-5"
                      style={{ fontWeight: 600 }}
                    >
                      Showing Results for "{searchQuery}"
                    </h3>

                    <div className="d-flex flex-wrap justify-content-center py-4">
                      <Badge
                        bsClass="badge"
                        className={`${
                          resultFilter === "top"
                            ? "bg-trellis-purple"
                            : "bg-trellis-lightgrey border "
                        } m-2 fs-5 px-4`}
                        style={{ cursor: "pointer", userApplicationAccess }}
                        pill
                        onClick={() => {
                          setResultFilter("top");
                        }}
                      >
                        Top Results{" "}
                        {topResults?.length
                          ? `- ${topResults?.length}`
                          : searchResultsLoading
                          ? ""
                          : "- 0"}
                      </Badge>
                      {RESULT_GROUPS?.map((group) => {
                        const { value, label } = group;

                        return (
                          <Badge
                            key={value}
                            bsClass="badge"
                            className={`${
                              resultFilter === value
                                ? "bg-trellis-purple"
                                : "bg-trellis-lightgrey border"
                            } m-2 fs-5 px-4`}
                            style={{
                              cursor: "pointer",
                              display: userApplicationAccess[value]
                                ? "inherit"
                                : "none",
                            }}
                            pill
                            onClick={() => {
                              setResultFilter(value);
                            }}
                          >
                            {label}{" "}
                            {searchResults?.[value] &&
                              `- ${searchResults?.[value]?.length}`}
                          </Badge>
                        );
                      })}
                    </div>
                  </div>
                  <div className="text-decoration-none">
                    {searchResultsLoading ? (
                      <Loading />
                    ) : (
                      <>
                        {resultFilter === "top" ? (
                          <>
                            {" "}
                            <h3
                              className="fs-3 ps-5"
                              style={{ fontWeight: 600 }}
                            >
                              Top Results
                            </h3>
                            <ul
                              style={
                                resultsContainerScroll
                                  ? {
                                      overflowY: "scroll",
                                      maxHeight: "calc(90vh - 320px)",
                                    }
                                  : {}
                              }
                            >
                              {topResults?.map((result) => {
                                const org = searchResults?.orgs?.find(
                                  (org) => org.id === result?.org
                                );

                                return (
                                  <SearchResult
                                    data={{ ...result, org }}
                                    closeModal={() => {
                                      setShowSearchResultModal(false);
                                      setSearchQuery("");
                                    }}
                                  />
                                );
                              })}
                            </ul>
                          </>
                        ) : (
                          <>
                            {" "}
                            <h3
                              className="fs-3 ps-5"
                              style={{ fontWeight: 600 }}
                            >
                              {
                                RESULT_GROUPS?.find(
                                  (group) => group.value === resultFilter
                                )?.label
                              }
                            </h3>
                            <ul
                              style={
                                resultsContainerScroll
                                  ? {
                                      overflowY: "scroll",
                                      maxHeight: "calc(90vh - 320px)",
                                    }
                                  : {}
                              }
                            >
                              <InfiniteScrollWrapper
                                Component={(props) => (
                                  <SearchResult
                                    {...props}
                                    closeModal={() => {
                                      setShowSearchResultModal(false);
                                      setSearchQuery("");
                                    }}
                                  />
                                )}
                                data={
                                  searchResults?.[resultFilter]?.map(
                                    (result) => {
                                      const org = searchResults?.orgs?.find(
                                        (org) => org.id === result?.org
                                      );
                                      return { ...result, org };
                                    }
                                  ) ?? []
                                }
                                dropdownLayout={true}
                              />
                            </ul>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              </div>
            }
          />
        </div>
      </PermissionsFlag>
    </>
  );
};

export default Search;
