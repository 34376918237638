import React, { useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import Checkbox from "components/core/basic/CheckBox";
import { MAX_ACOS_TOOLTIP, MAX_ROAS_TOOLTIP } from "utils/toolTips";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { UpdateButton } from "./buttons";
import useAdPlanState from "./useAdPlanState";
import withAdPlan from "./withAdPlan";

const useStyles = (theme) => ({
  container: {
    padding: theme.spacing(2, 4),
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  heading: {
    fontSize: "2rem",
    color: "#403E3D",
    fontWeight: "700",
    margin: "1rem auto",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const AdPlanNotificationMenu = ({
  classes,
  adId,
  roas,
  onCancel,
  marketPlace,
  categoryId,
  organizationType,
  onUpdate,
}) => {
  const { adPlan, onChange, products } = useAdPlanState(
    {},
    marketPlace.marketPlace,
    categoryId,
    adId,
    roas,
    organizationType
  );
  const [errors, setErrors] = useState([]);

  const onSubmit = async () => {
    if (errors.length > 0) {
      return;
    }
    await onUpdate(adPlan, products);
  };

  return (
    <Grid container item xs={12} className={classes.container}>
      <Grid item xs={12} style={{ justifyContent: "center" }}>
        <h2 className={classes.heading}>Manage Media Plan Notifications</h2>
      </Grid>
      <Grid
        container
        item
        xs={12}
        lg={8}
        spacing={4}
        style={{ margin: "auto" }}
      >
        <Grid
          item
          xs={12}
          md={4}
          className={classes.checkboxContainer}
          style={{ paddingTop: "70px" }}
        >
          <Checkbox
            inline
            number={`${adId}-`}
            label="Product Alert"
            checked={adPlan.notifications?.productAlertEnabled}
            disabled={false}
            onClick={(e) => {
              onChange({
                notifications: {
                  ...adPlan.notifications,
                  productAlertEnabled:
                    !adPlan.notifications?.productAlertEnabled,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroupTile
            type="formcontrol"
            label={roas ? "Min ROAS" : "Max ACOS"}
            formControlType={"number"}
            prefix={"%"}
            prefixSide={"right"}
            toolTip={roas ? MAX_ROAS_TOOLTIP : MAX_ACOS_TOOLTIP}
            stateName={roas ? "maxROAS" : "maxACOS"}
            handleChildFormElement={(key, value) => {
              onChange({
                notifications: { ...adPlan.notifications, maxAcos: value },
              });
            }}
            placeholder={`Enter ${roas ? "Min ROAS" : "Max ACoS (%)"}`}
            defaultValue={
              roas
                ? // TODO: figure out how values for ROAS will be passed to backend => do math here when setting state, or just take care of it in serializer/view once passed
                  adPlan.notifications.minROAS
                : adPlan?.notifications?.maxAcos
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroupTile
            type="formcontrol"
            label={"Max Spend"}
            formControlType={"number"}
            toolTip={
              "Enter a limit to the amount spent on an advertised product that, if exceeded, will trigger an alert."
            }
            stateName={"maxSpend"}
            handleChildFormElement={(key, value) => {
              if (!Number.parseInt(value) || Number.parseInt(value) < 0) {
                setErrors([...errors, "Please enter a positive integer."]);
              } else {
                setErrors([]);
              }
              onChange({
                notifications: { ...adPlan.notifications, maxSpend: value },
              });
            }}
            placeholder={`Enter Max Spend`}
            defaultValue={adPlan?.notifications?.maxSpend}
          />
        </Grid>
        <Grid container item xs={12} className={classes.buttonContainer}>
          <UpdateButton onClick={onSubmit} text={"Update"} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(withAdPlan(AdPlanNotificationMenu));
