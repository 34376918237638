import React, { useState, useRef, useMemo } from "react";
import "assets/css/dashboard_table.css";
import "assets/css/pricing_table_overrides.css";
import { Row, Col } from "react-bootstrap";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Switch from "react-bootstrap-switch";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { formatCurrency } from "utils/formatNumber";

const useStyles = (theme) => ({
  preferenceContainer: {
    padding: theme.spacing(2),
  },
  preferenceTitle: {
    margin: "0px 0px",
    paddingLeft: theme.spacing(2),
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2, 2),
      textAlign: "center",
    },
  },
  preferenceDescription: {
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2),
      textAlign: "center",
      margin: "auto",
    },
  },
});

const PriceDetails = ({ optData, channel, marketPlace }) => {
  const [daysWithPrice, setDaysWithPrice] = useState(
    optData?.pricing_org?.days_with_price
  );

  const TABLE_ID = "priceHistoryDetailsTable";
  const tableRef = useRef();

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const tableData = useMemo(() => {
    return (
      optData?.pricing_data
        ?.filter(
          (r) =>
            r.num_days_at_price !== 0 && r.num_days_at_price >= daysWithPrice
        )
        ?.map((d) => ({ ...d, total_cogs: d.cogs })) ?? []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optData, daysWithPrice]);

  return (
    <>
      <Row>
        <Col className="text-end pr-3 pb-2">
          <Switch
            onText={``}
            offText={``}
            value={daysWithPrice !== 0}
            onColor="#FFFFFF"
            onChange={(e) => {
              if (daysWithPrice === 0) {
                setDaysWithPrice(optData.pricing_org.days_with_price - 1);
              } else {
                setDaysWithPrice(0);
              }
            }}
          />
          {daysWithPrice !== 0 ? (
            <span className="pl-3">
              Show prices with less than {optData.pricing_org.days_with_price}{" "}
              days of data
            </span>
          ) : (
            <span className="pl-3">
              Hide prices with less than {optData.pricing_org.days_with_price}{" "}
              days of data
            </span>
          )}
        </Col>
      </Row>
      <BulkActionsTable
        tableId={TABLE_ID}
        tableRef={tableRef}
        filterWidget={true}
        hideFilterToggleIcon={true}
        {...filterProps}
        columnSpecs={[
          {
            key: "product_price",
            compareKey: null,
            options: {
              className: "d-flex text-start align-items-center ps-3",
              metric: {
                id: "price",
                format: "currency",
                accessorId: "price",
                accessor: (d) => {
                  return d?.price;
                },
                name: "Price",
                width: "100px",
              },
            },
          },
          {
            key: "num_days_at_price",
            compareKey: null,
            options: {
              metric: {
                id: "num_days_at_price",
                format: "number",
                accessor: (d) => {
                  return d?.num_days_at_price;
                },
                name: "Days at Price",
              },
            },
          },
          {
            key: "qty_sold",
            compareKey: null,
            options: {
              metric: {
                id: "qty_sold",
                format: "number",
                accessor: (d) => {
                  return d?.qty_sold;
                },
                name: "Quantity Sold",
              },
            },
          },
          {
            key: "avg_daily_sales",
            compareKey: null,
            options: {
              metric: {
                id: "avg_daily_sales",
                format: "number",
                accessor: (d) => {
                  return d?.avg_daily_sales;
                },
                name: "Daily Units",
                decimalPlaces: 2,
              },
            },
          },
          {
            key: "avg_monthly_sales",
            compareKey: null,
            options: {
              metric: {
                id: "avg_mnthly_sales",
                format: "number",
                accessor: (d) => {
                  return d?.avg_monthly_sales;
                },
                name: "Monthly Units",
                decimalPlaces: 2,
              },
            },
          },
          {
            key: "cogs",
            compareKey: null,
            options: {
              metric: {
                id: "cogs",
                format: "currency",
                accessor: (d) => {
                  return d?.cogs;
                },
                name: "COGS",
              },
            },
          },
          {
            key: "org_profit",
            compareKey: null,
            options: {
              metric: {
                id: "org_profit",
                format: "currency",
                accessor: (d) => {
                  return d?.org_profit;
                },
                formatter: (v) => formatCurrency(v, marketPlace.marketPlace),
                name: "Margin",
              },
            },
          },
          {
            key: "monthly_profit",
            compareKey: null,
            options: {
              metric: {
                id: "monthly_profit",
                format: "currency",

                accessor: (d) => {
                  return d?.monthly_profit;
                },
                name: "Monthly Profit",
              },
            },
          },
          {
            key: "vat",
            compareKey: null,
            options: {
              checked: false,
              metric: {
                id: "vat",
                format: "currency",

                accessor: (d) => {
                  return d?.vat;
                },
                name: "VAT",
                checked: false,
              },
            },
          },
          {
            key: "landed_cogs",
            compareKey: null,
            options: {
              checked: false,
              metric: {
                id: "landed_cogs",
                format: "currency",
                accessor: (d) => {
                  return d?.landed_cogs;
                },
                name: "Landed Cost",
              },
            },
          },
          {
            key: "ship_cost",
            compareKey: null,
            options: {
              checked: false,
              metric: {
                id: "ship_cost",
                format: "currency",
                accessor: (d) => {
                  return d?.ship_cost;
                },
                name: "Shipping Cost",
              },
            },
          },
          {
            key: "closing_fees",
            compareKey: null,
            options: {
              checked: false,
              metric: {
                id: "closing_fees",
                format: "currency",
                accessor: (d) => {
                  return d?.closing_fees;
                },
                name: "Closing Fee",
              },
            },
          },
          {
            key: "referral_fees",
            compareKey: null,
            options: {
              checked: false,
              metric: {
                id: "referral_fees",
                format: "currency",
                accessor: (d) => {
                  return d?.referral_fees;
                },
                name: "Referral Fee",
              },
            },
          },
        ]}
        data={tableData}
        className="pricing-table"
        selectionKey="price"
        isExpandable={false}
        isSelectable={false}
        hideSearchFilter={true}
        defaultPageSize={10}
        tableRowDescription={"Price"}
        groupMetrics={false}
      />
    </>
  );
};

PriceDetails.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(useStyles)(PriceDetails))
);
