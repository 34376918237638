import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import Loading from "components/core/blocks/Loading";
import { useDates } from "dates/useDates";
import Metric from "components/core/blocks/SummaryMetric";
import MultiMetricChart from "components/custom/merchandise/MultiMetricChart";
import useSelectedMetrics from "components/custom/merchandise/hooks/useSelectedMetrics";
import { buildMetric } from "views/merchandise/metrics";

const Overview = ({
  isPricingProductDataLoading,
  marketPlace,
  data,
  isLoading,
}) => {
  const { selectedMetrics, toggleMetric } = useSelectedMetrics([
    "price",
    "units",
    "margin",
  ]);
  const { group } = useDates();

  const metrics = useMemo(
    () => [
      buildMetric(
        marketPlace,
        data,
        "profit_wo_adspend",
        "profit_wo_adspend_percent"
      ),
      buildMetric(marketPlace, data, "price", "price_delta"),
      buildMetric(marketPlace, data, "min_price", "price_delta"),
      buildMetric(marketPlace, data, "units", "units_percent"),
      buildMetric(
        marketPlace,
        data,
        "margin_wo_adspend",
        "margin_wo_adspend_delta"
      ),
    ],
    [marketPlace, data]
  );

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  if (isLoading || isPricingProductDataLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <>
      <Row>
        <Col xs={8}>
          <MultiMetricChart
            metrics={metrics}
            selectedMetrics={selectedMetrics}
            data={data.date}
            group={group}
            handleLegendChange={handleLegendChange}
          />
        </Col>
        <Col xs={4} className="pt-5">
          <Row>
            {metrics.map(
              (m) =>
                m.id !== "min_price" && (
                  <Col
                    key={m.id}
                    xs={6}
                    style={{ marginBottom: "3rem" }}
                    onClick={() => {
                      toggleMetric(m.id);
                    }}
                  >
                    <Metric
                      id={m.id}
                      toolTip={m.toolTip}
                      name={m.name}
                      value={m.overrideValue ?? m.actual}
                      target={m.target}
                      formatter={m.formatter}
                      targetFormatter={m.targetFormatter}
                      showTarget
                      targetColor={m.target > 0 ? "#007000" : "#d2222d"}
                      legendColor={m.color}
                      targetLabel={"vs. Previous"}
                      size="small"
                      selected={selectedMetrics.includes(m.id)}
                    />
                  </Col>
                )
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Overview;
