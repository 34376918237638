import * as React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Button, ButtonGroup, withStyles } from "@material-ui/core";

const useStyles = (theme) => ({
  buttonSplit: {
    borderRadius: "22px",
    fontFamily: "Poppins",
  },

  test: {
    textTransform: "uppercase",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "12px",
    letterSpacing: "2.5px",
    width: "200px",
  },

  dropdown: {
    display: "flex",
    paddingTop: "6px",
    paddingBottom: "6px",
    whiteSpace: "nowrap",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
});

const options = ["Create Media Plan", "Bulk Upload"];

function SplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    props.onClick(options[selectedIndex]);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const { classes } = props;

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        color="secondary"
        style={{ position: "relative" }}
        className={classes.buttonSplit}
      >
        <Button onClick={handleClick} className={classes.test}>
          {options[selectedIndex]}
        </Button>
        <Button
          size="large"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          &#9660;
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "1000" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      className={classes.dropdown}
                    >
                      <text style={{ fontFamily: "Poppins", fontSize: "12px" }}>
                        {option}
                      </text>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default withStyles(useStyles)(SplitButton);
