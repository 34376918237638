import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import ReactTable from "react-table-6";
import CenteredModal from "components/core/basic/CenteredModal";

import "assets/css/dashboard_table.css";
import ProductSelectionTablePagination from "./ProductSelectionTablePagination";
import "assets/css/dashboard_table.css";
import { CancelButton, UpdateButton } from "../AdPlan/buttons";
import {
  AD_ASIN_SKU_TAGLIST_TOOLTIP,
  AD_ITEM_ID_SKU_TAGLIST_TOOLTIP,
} from "utils/hiddenToolTips";
import Tags from "../Tags";
import { AuthButton } from "components/core/basic/Button";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { getColumns } from "./getColumns";
import useColumnSelector from "hooks/useColumnSelector";
import { getDropdownOptions } from "./getFilters";
import SearchIcon from "@material-ui/icons/Search";
import LifecycleFilters from "views/merchandise/LifecycleFilters";
import moment from "moment";
import ToolTips from "utils/toolTips";
import useProductReport from "hooks/useProductReport";
import Switch from "react-bootstrap-switch";

const useStyles = (theme) => ({
  container: {
    minHeight: "30vh",
  },
  iconButton: {
    color: "#FFF",
    background: "#8F8F8F",
    position: "absolute",
    zIndex: 500,
    right: "10px",
    border: "none",
    borderRadius: ".3rem",
    padding: ".2rem",
    marginLeft: "1rem",
  },
  tableHeader: {
    fontSize: "2.2rem",
    color: "#403E3D",
    fontWeight: "700",
    margin: "1rem 1.5rem 3rem 0",
    textAlign: "center",
  },
});

const DetailedProductSelector = ({
  selectorId,
  selectedCategory,
  classes,
  user,
  marketPlace,
  channel,
  isModal,
  renderInModal = true,
  review,
  mediaPlanSelector = true,
  columnsToRender = [],
  getPreselectedProducts = () => [],
  updateSelectedProducts = () => {},
  updateSelectedProductDependencies = [],
  processTableData = (filteredData, productData) =>
    filteredData ? filteredData : productData ?? [],
  reprocessDataDependencies = [],
  disableRowCheckbox = () => false,
  assessChecked = (selectedProducts, productData) => {
    return selectedProducts?.length >= productData?.length;
  },
  hideClearAll = false,
  hideSelectAll = false,
  saveProductSelections = (selectedProducts) => {},
  handleProductSelection = () => {},
  onCancel = () => {},
  // Data for product channel select filter
  orgPlansOptions = [],
  additionalQueryParams = {},
  applySearchFilters = (setFilteredData, productData, searchFilter) => {
    setFilteredData(
      productData
        ?.map((p) => (p.is_parent ? p.children : p))
        ?.flat()
        ?.filter((row) => {
          return (
            row?.asin?.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 ||
            row?.sku?.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 ||
            row?.product_title
              ?.toLowerCase()
              .indexOf(searchFilter.toLowerCase()) > -1
          );
        })
    );
    return;
  },
  renderTable = true,
  tableSubtitle = "Select Products",
  disableSelectAllCheckbox = () => {},
  hideTagsInput = false,
  inventoryStateFilter,
  setInventoryStateFilter,
  salesStateFilter,
  setSalesStateFilter,
  walmartPrimaryVariantFilter,
  setWalmartPrimaryVariantFilter,
  disablePrimaryVariantFilter = false,
  hideLifecycleFilters,
  singleProductSelection,
  fetchAllProducts = false,
}) => {
  const [filteredData, setFilteredData] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const [filterState, setFilterState] = useState([]);
  const [colToggle] = useState();

  const [showExpanded, setShowExpanded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(
    getPreselectedProducts()
  );
  const [selectedOrg, setSelectedOrg] = useState(
    parseInt(user.organization_id)
  );

  const [initialProducts, setInitialProducts] = useState([]);
  // Table ref to get filtered data back for select all/clear functionality based on what's currently visible to user
  const tableRef = useRef();

  let tableState = tableRef.current?.getResolvedState();
  let resolvedTableData = tableState?.sortedData;

  let filters = {
    mp: marketPlace.marketPlace,
    channel: channel.currentChannel,
    date_group: "day",
    empty: true,
    group_by: "product_id",
    start_date: moment().subtract(31, "days").format("YYYY-MM-DD"),
    end_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
  };

  // TODO: Eventually will use org id to get all products in selector for merch
  if (selectedOrg) {
    filters = { ...filters, org: selectedOrg };
  }

  if (selectedCategory && !fetchAllProducts) {
    filters = {
      ...filters,
      category: selectedCategory,
    };
  }

  const { productData } = useProductReport(
    [selectedCategory, selectedOrg],
    selectedCategory,
    filters
  );

  useEffect(() => {
    setSelectedProducts(updateSelectedProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...updateSelectedProductDependencies]);

  const [columns, columnSelectorProps] = useColumnSelector(
    () => {
      return getColumns(
        searchFilter,
        filtered,
        disableSelectAllCheckbox,
        selectedProducts,
        setSelectedProducts,
        productData,
        filteredData,
        resolvedTableData,
        assessChecked,
        handleProductSelection,
        disableRowCheckbox,
        user,
        marketPlace,
        getDropdownOptions,
        columnsToRender,
        colToggle,
        mediaPlanSelector,
        review,
        initialProducts,
        hideClearAll,
        hideSelectAll,
        selectorId,
        singleProductSelection,
        inventoryStateFilter,
        tableRef
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colToggle, productData]
  );

  useEffect(() => {
    setSelectedProducts(getPreselectedProducts());
    setInitialProducts(getPreselectedProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  useEffect(() => {
    let productDetails = selectedProducts?.map((id) =>
      data.find((d) => d.id === id)
    );

    if (mediaPlanSelector || selectorId === "npl") {
      saveProductSelections(selectedProducts, productDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts?.length]);

  const applyFilters = () => {
    if (
      searchFilter.length > 0 ||
      inventoryStateFilter?.value !== "all" ||
      salesStateFilter?.value !== "all" ||
      walmartPrimaryVariantFilter
    ) {
      applySearchFilters(setFilteredData, productData, searchFilter);
    } else {
      setFilteredData(null);
    }
  };

  const clearSearchTermFilters = () => {
    let currentFilters = filtered;
    let updatedFilters = currentFilters.filter(
      (filter) => filter.id !== "keyword"
    );

    setFiltered(updatedFilters);
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productData,
    searchFilter,
    inventoryStateFilter,
    salesStateFilter,
    walmartPrimaryVariantFilter,
  ]);

  const data = useMemo(() => {
    return processTableData(filteredData, productData, selectedProducts) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, productData, ...reprocessDataDependencies]);

  const renderSearchbar = () => {
    return (
      <div
        style={{
          position: "relative",
          marginLeft: "auto",
        }}
        id="react-table-searchbar"
      >
        <SearchIcon
          style={{
            position: "absolute",
            left: "10px",
            top: "11px",
            fontSize: "2rem",
          }}
        />
        <input
          type="text"
          className="searchInputField"
          style={{
            textAlign: "left",
            padding: "10px 10px 10px 35px",
            minWidth: selectorId === "merch-planning" ? "342px" : "300px",
            border: "1px solid #E3E3E3",
            borderRadius: "5px",
            fontSize: "1.25rem",
          }}
          placeholder="Search Products"
          key={1224}
          value={searchFilter}
          onChange={(e) => {
            setSearchFilter(e.target.value);
            setFilterState([{ id: "product", value: e.target.value }]);

            if (e.target.value.trim().length < 1) {
              clearSearchTermFilters();
            }
          }}
        ></input>
      </div>
    );
  };
  return (
    <>
      {renderInModal === false ? (
        <>
          <Grid container style={{ position: "relative" }}>
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                {!renderTable ? null : !selectedCategory ? (
                  <p className="my-4">
                    Please select a category to enable product selection.
                  </p>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "45px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2rem 1rem 1rem 0",
                        }}
                      >
                        <span style={{ fontSize: "20px", fontWeight: 500 }}>
                          {tableSubtitle}{" "}
                          <ToolTips toolTip="Showing product performance over the past 30 days" />
                        </span>
                      </div>
                      {renderSearchbar()}
                      {!review && !hideTagsInput && productData?.length ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <Tags
                            name={
                              <h6
                                className="title"
                                style={{
                                  color: "#73706E",
                                  fontWeight: "700",
                                }}
                              >
                                Add products by ASIN or SKU
                              </h6>
                            }
                            filledTags={
                              productData
                                ?.filter((o) => {
                                  return selectedProducts?.indexOf(o.id) > -1;
                                })
                                ?.map((v) => {
                                  return user?.productIdentifier === "sku"
                                    ? v.sku
                                    : v.asin;
                                }) ?? []
                            }
                            saveFilledTags={(productList) => {
                              productList = productList.map((p) =>
                                p.trim().toLowerCase()
                              );
                              const selectedProducts = productData
                                ?.filter((p) => {
                                  return (
                                    productList?.indexOf(
                                      p.asin?.toLowerCase()
                                    ) > -1 ||
                                    productList?.indexOf(p.sku?.toLowerCase()) >
                                      -1
                                  );
                                })
                                .map((p) => p.id);
                              setSelectedProducts(selectedProducts);
                            }}
                            placeholder={``}
                            hideTagsField={true}
                            user={user}
                            toolTip={
                              channel.currentChannel === "walmart"
                                ? AD_ITEM_ID_SKU_TAGLIST_TOOLTIP
                                : AD_ASIN_SKU_TAGLIST_TOOLTIP
                            }
                            toolTipId={
                              channel.currentChannel === "walmart"
                                ? "AD_ITEM_ID_SKU_TAGLIST_TOOLTIP"
                                : "AD_ASIN_SKU_TAGLIST_TOOLTIP"
                            }
                            hiddenTooltip={true}
                            toolTipPosition={"left"}
                            overrideButtonStyles={{
                              display: "relative",
                              margin: "0 .25rem 0 1.8rem",
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <ReactTable
                      ref={tableRef}
                      data={data}
                      PaginationComponent={(props) => {
                        return (
                          <ProductSelectionTablePagination
                            {...props}
                            updateSearch={setSearchFilter}
                            clearFilters={() => {
                              setFiltered([]);
                              setClearFilters(true);
                            }}
                            clearSearchTermFilters={clearSearchTermFilters}
                            searchTerm={searchFilter}
                            setFilterState={setFilterState}
                            filteredState={filtered}
                            table={"detailed-product-selection"}
                            setShowFilters={setShowFilters}
                            showFilters={showFilters}
                            style={{ marginBottom: "2rem" }}
                            clearSelectedProducts={() =>
                              setSelectedProducts([])
                            }
                            selectedProductCount={selectedProducts?.length ?? 0}
                            isModal={isModal}
                            hideFilters={isModal && review}
                            hideClearAll={hideClearAll}
                            hideSelectAll={hideSelectAll}
                            columnSelectorProps={columnSelectorProps}
                            selectorId={selectorId}
                            children={
                              <>
                                {!review && !hideLifecycleFilters && (
                                  <LifecycleFilters
                                    salesStateFilter={salesStateFilter}
                                    setSalesStateFilter={setSalesStateFilter}
                                    inventoryStateFilter={inventoryStateFilter}
                                    setInventoryStateFilter={
                                      setInventoryStateFilter
                                    }
                                    hideSalesStateFilter={true}
                                  />
                                )}
                                {selectorId === "mediaPlan" &&
                                  channel.currentChannel === "walmart" && (
                                    <div className="fs-6 d-flex align-items-center pt-3">
                                      <span className="me-3">All Products</span>
                                      <Switch
                                        onText=""
                                        offText=""
                                        value={walmartPrimaryVariantFilter}
                                        onColor="#FFFFFF"
                                        onChange={(e) => {
                                          setWalmartPrimaryVariantFilter(
                                            !walmartPrimaryVariantFilter
                                          );
                                        }}
                                        disabled={disablePrimaryVariantFilter}
                                      />
                                      <span className="ms-3">
                                        Primary Variants
                                      </span>
                                    </div>
                                  )}
                              </>
                            }
                          />
                        );
                      }}
                      onFilteredChange={(filtered) => {
                        setFiltered(filtered);
                      }}
                      getTheadFilterProps={(props) => {
                        if (clearFilters) {
                          props.filtered.forEach((filter, index) => {
                            filter.value = "all";
                          });
                          setClearFilters(false);
                        }
                        return { style: { display: "flex" } };
                      }}
                      filterable={showFilters}
                      columns={columns}
                      defaultPageSize={5}
                      // Below is needed to eliminate the no data message that covers table headers if no data to render
                      NoDataComponent={() => null}
                      minRows={0}
                      showPaginationTop
                      showPaginationBottom={false}
                      style={{
                        fontSize: review ? "1.25rem" : "inherit",
                        marginTop: "-40px",
                      }}
                      defaultSorted={
                        selectorId === "npl"
                          ? [
                              {
                                id: "createdOn",
                                desc: false,
                              },
                            ]
                          : [
                              {
                                id: "totalSales",
                                desc: true,
                              },
                            ]
                      }
                    />
                  </>
                )}
              </Grid>
            </Grid>
            {renderInModal && (
              <Grid container item xs={12}>
                <CancelButton
                  text={"Cancel"}
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel(setSelectedProducts);
                    setShowExpanded(false);
                  }}
                />
                <UpdateButton
                  text={"Save"}
                  onClick={(e) => {
                    e.preventDefault();
                    saveProductSelections(selectedProducts);
                    setShowExpanded(false);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      ) : /**
       * EXPANDED MODAL VIEW STARTS HERE
       */
      showExpanded ? (
        <CenteredModal
          show={showExpanded}
          closeModal={() => setShowExpanded(false)}
          title={"Select Products"}
          content={
            <>
              <Grid container style={{ position: "relative" }}>
                <Grid container className={classes.container}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        padding: "1rem",
                        marginTop: "-64px",
                        marginBottom: "5px",
                      }}
                    >
                      <div style={{ marginRight: "auto" }}>
                        {renderSearchbar()}
                      </div>
                    </div>
                    <ReactTable
                      ref={tableRef}
                      data={data}
                      PaginationComponent={(props) => {
                        return (
                          <ProductSelectionTablePagination
                            {...props}
                            selectorId={selectorId}
                            updateSearch={setSearchFilter}
                            clearFilters={() => {
                              setFiltered([]);
                              setClearFilters(true);
                            }}
                            clearSearchTermFilters={clearSearchTermFilters}
                            searchTerm={searchFilter}
                            setFilterState={setFilterState}
                            filteredState={filtered}
                            table={"detailed-product-selection"}
                            setShowFilters={setShowFilters}
                            showFilters={showFilters}
                            style={{ marginBottom: "2rem" }}
                            clearSelectedProducts={() =>
                              setSelectedProducts([])
                            }
                            selectedProductCount={selectedProducts?.length ?? 0}
                            isModal={isModal}
                            hideClearAll={hideClearAll}
                            hideSelectAll={hideSelectAll}
                            additionalFilters={[
                              <div
                                style={{
                                  minWidth: "180px",
                                  position: "absolute",
                                  left: "170px",
                                  top: "-3px",
                                }}
                              >
                                <FormGroupTile
                                  type="select"
                                  multi={false}
                                  stateName={"selectedOrg"}
                                  handleChildFormElement={(key, value) =>
                                    setSelectedOrg(value.value)
                                  }
                                  defaultValue={orgPlansOptions.find(
                                    (opt) => opt.value === selectedOrg
                                  )}
                                  options={orgPlansOptions}
                                />
                              </div>,
                            ]}
                            columnSelectorProps={columnSelectorProps}
                          />
                        );
                      }}
                      onFilteredChange={(filtered) => {
                        setFiltered(filtered);
                      }}
                      getTheadFilterProps={(props) => {
                        if (clearFilters) {
                          props.filtered.forEach((filter, index) => {
                            filter.value = "all";
                          });
                          setClearFilters(false);
                        }
                        return { style: { display: "flex" } };
                      }}
                      filterable={showFilters}
                      columns={columns}
                      defaultPageSize={5}
                      // Below is needed to eliminate the no data message that covers table headers if no data to render
                      NoDataComponent={() => null}
                      minRows={0}
                      showPaginationTop
                      showPaginationBottom={false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ margin: "2rem auto 1rem" }}
                >
                  <CancelButton
                    text={"Cancel"}
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel(setSelectedProducts);
                      setShowExpanded(false);
                    }}
                  />
                  <UpdateButton
                    text={"Save"}
                    onClick={(e) => {
                      e.preventDefault();
                      saveProductSelections(selectedProducts);
                      setShowExpanded(false);
                    }}
                  />
                </Grid>
              </Grid>
            </>
          }
        />
      ) : (
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          <AuthButton
            className="logout_button"
            onClick={() => setShowExpanded(true)}
            title="Select Products"
          />
        </div>
      )}
    </>
  );
};

DetailedProductSelector.propTypes = {
  marketPlace: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferences: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  user: state.user,
  preferences: state.user.preferences,
  channel: state.channel,
});

export default connect(mapStateToProps)(
  withStyles(useStyles)(DetailedProductSelector)
);
