// import PricingDashboard from "./Dashboard";
import PricinghDashboardV2 from "./DashboardV2";
import PricingPlanPage from "./PricingPlan";
import Settings from "./Settings";
import PriceChanges from "./PriceChanges";
import BuyBoxReport from "./BuyBoxReport";

export const PricingV2Routes = [
  {
    path: "/_pricing",
    layout: "/user",
    name: "Pricing Dashboard",
    icon: "pe-7s-note2",
    application: "Pricing",
    component: null,
    children: [
      {
        path: `/user/_pricing/dashboard`,
        component: PricinghDashboardV2,
        exact: true,
        application: "Pricing",
      },
      {
        path: "/user/_pricing/category/:category/",
        component: PricinghDashboardV2,
        exact: true,
        application: "Pricing",
      },
      {
        path: "/user/_pricing/plan/:plan",
        component: PricingPlanPage,
        exact: true,
        application: "Pricing",
      },
      {
        path: "/user/_pricing/configuration",
        component: Settings,
        exact: true,
        application: "Pricing",
      },
      {
        path: "/user/_pricing/changes",
        component: PriceChanges,
        exact: true,
        application: "Pricing",
      },
      {
        path: "/user/_pricing/buybox",
        component: BuyBoxReport,
        exact: true,
        application: "Pricing",
      },
    ],
  },
];
