import React from "react";
import { FormLabel } from "react-bootstrap";
import ToolTips from "utils/toolTips";

const FormGroupLabel = ({ label, toolTip, titleCase, className }) => (
  <FormLabel>
    <h6
      className={`title ${className ? className : ""}`}
      style={{
        color: "#73706E",
        fontWeight: "700",
        textTransform: titleCase ? "none" : "inherit",
      }}
    >
      {label}
      {toolTip && (
        <ToolTips
          toolTip={toolTip}
          position="top"
          id={Object.keys(toolTip)[0]}
        />
      )}
    </h6>
  </FormLabel>
);

export default FormGroupLabel;
