import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDates } from "dates/useDates";
import { generateUrl } from "dates/utils";
import ToolTips from "utils/hiddenToolTips";

const TitleLink = ({ to, children, title, ...rest }) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const dates = useDates();
  const url = generateUrl(to, dates);

  const cellRef = useRef();

  const containerRef = useCallback(
    (node) => {
      if (node !== null) {
        setIsTruncated(
          node.parentElement.scrollWidth > node.parentElement.clientWidth
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children]
  );

  return (
    <Link
      to={url}
      {...rest}
      ref={containerRef}
      data-tip={title}
      data-for={title}
    >
      <h2
        ref={cellRef}
        className="py-0 my-0"
        style={{
          fontWeight: 500,
          fontSize: "1.5rem",
          cursor: "pointer",
          overflow: isTruncated ? "hidden" : "inherit",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {isTruncated ? <ToolTips id={title} position={"top"} /> : <></>}
        {title}
      </h2>
    </Link>
  );
};

export default TitleLink;
