import React, { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { buildDateColumn } from "views/merchandise/columns/buildDateColumn";
import { buildLookupColumn } from "views/merchandise/columns/buildLookupColumn";
import { buildTextColumn } from "views/merchandise/columns/buildTextColumn";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import api from "utils/api";

const ListPrograms = ({
  programs,
  isLoading,
  onClickProgram,
  onToggleProgram,
  engine = "pricing",
  createButton = null,
  title,
  titleColWidth = 250,
}) => {
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const queryClient = useQueryClient();
  const deleteProgram = useMutation(async (ids) => {
    await Promise.all(
      ids.map(async (id) => api.remove(`/api/programs/${id}/`))
    );
    queryClient.invalidateQueries("programs");
  });
  const { marketPlace } = useSelector((state) => state);
  const tableRef = useRef();
  const columnSpecs = [
    {
      key: "active",
      compareKey: null,
      options: {
        editCell: true,
        hideFilter: false,
        toggleAction: (obj, value) => {
          onToggleProgram(obj.id, value);
        },
        filterType: "select",
        selectOptions: [
          {
            value: "active",
            label: "Active",
          },
          {
            value: "active",
            label: "Inactive",
          },
        ],
        format: "toggle",
        onValue: "active",
        offValue: "inactive",
        className: "justify-content-end",
        columnTitle: "Active",
      },
    },
    buildLookupColumn("status", "Status", {
      scheduled: "Scheduled",
      active: "Running",
      paused: "Paused",
      finished: "Finished",
      cancelled: "Cancelled",
      error: "Error",
    }),
    buildDateColumn("start_date", "Start Date"),
    buildDateColumn("end_date", "End Date"),
    buildDateColumn("last_run", "Last Run"),
    buildDateColumn("last_action", "Last Action"),
    buildTextColumn("run_cnt", "# Runs"),
  ];

  if (engine === "bidding") {
    columnSpecs.shift();
  }

  return (
    <BulkActionsTable
      noDataText={`Create a ${engine} rule to get started`}
      marketPlace={marketPlace}
      tableRef={tableRef}
      tableId={`${engine}ProgramsTable`}
      titleCol={{
        id: "name",
        isStatic: true,
        Header: title,
        accessor: "name",
        style: { width: "150px" },
        width: titleColWidth,
        Cell: ({ value, row }) => {
          return (
            <>
              <div
                className="d-flex align-items-center overflow-ellipsis"
                style={{
                  cursor: "pointer",
                  color: "#2e0054",
                  fontWeight: 500,
                  fontSize: "1.5rem",
                }}
                onClick={() => {
                  if (onClickProgram) onClickProgram(row._original.id);
                }}
              >
                {value}
              </div>
            </>
          );
        },
        filterMethod: (filter, row) => {
          return;
        },
        Filter: ({ filter, onChange }) => null,
      }}
      columnSpecs={columnSpecs}
      data={programs}
      bulkActionOptions={[
        {
          label: `Delete ${title}`,
          value: "delete",
          action: () => {
            deleteProgram.mutate(selectedPrograms);
          },
          confirmationMessage: (
            <p>
              Are you sure you want to delete the{" "}
              {selectedPrograms?.length > 1 ? selectedPrograms?.length : ""}{" "}
              selected program{selectedPrograms?.length > 1 ? "s" : ""}?
            </p>
          ),
        },
      ]}
      hideFilterToggleIcon={true}
      selected={selectedPrograms}
      setSelected={setSelectedPrograms}
      isSelectable={true}
      isExpandable={false}
      selectionKey={"id"}
      defaultSorted={[{ id: "name", desc: false }]}
      getSearchCriteria={(row) => {
        const { name } = row;
        return `${name}`;
      }}
      tableRowDescription={title}
      minRows={5}
      isLoading={isLoading}
      leftActionButton={createButton}
    />
  );
};

export default ListPrograms;
