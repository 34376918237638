import React from "react";
import Select from "react-select";

const SearchFilter = ({
  selectedSearchId,
  shelfData,
  selectedShelf,
  filterSearchId,
}) => {
  const shelf = shelfData.find((s) => s.id === selectedShelf);
  const defaultOptions = [
    {
      label: "All Keywords",
      value: null,
    },
  ];
  const options = defaultOptions.concat(
    shelf?.searches?.map((s) => ({
      label: s.display || s.value,
      value: s.id,
    })) ?? []
  );
  return (
    <Select
      options={options}
      value={options.find((o) => o.value === selectedSearchId) || options[0]}
      onChange={(o) => filterSearchId(o.value)}
    />
  );
};

export default SearchFilter;
