import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import "assets/css/payment_styles.css";
import poweredByStripe from "assets/images/logo/powered_by_stripe@3x.png";
import axios from "axios";
import { AuthButton } from "components/core/basic/Button";
import postalCodes from "postal-codes-js";
import React from "react";
import { FormLabel as ControlLabel, FormControl } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import store from "redux/store";
import getURLPrefix from "utils/getUrlPrefix";
import { SUBSCRIPTION_PLANS_UPPERCASE } from "utils/onboardingConstants";
import PaymentProcessing from "./PaymentProcessing";
import { trackGaEvent } from "utils/trackGaEvent";
import { trackMixpanelEvent } from "hooks/useMixpanelTracking";

let URL_PREFIX = getURLPrefix();

const subscriptionPlans = SUBSCRIPTION_PLANS_UPPERCASE;

const options = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#D91266",
      letterSpacing: "1px",
      fontSize: "15px",
      fontWeight: 500,
      fontFamily: "sans-serif",
      "::placeholder": {
        color: "#c3c3c3",
      },
    },
    complete: { color: "#850066" },
    invalid: {
      color: "#ff0033",
    },
  },
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionPlanName: "",
      subscriptionPlan: null,
      payAmount: "",
      payPlan: "",
      payFrequency: "",
      nameOnCard: "",
      invalidNameOnCard: false,
      customer: null,
      country: "",
      region: "",
      postalCode: "",
      validPostalCode: true,
      error: {},
      processingPayment: false,
      subSuccessful: false,
      cardError: false,
      cardComplete: false,
      cardEmpty: true,
    };
  }

  componentDidMount() {
    this.getSubscriptionPlan();
  }

  selectCountry(val) {
    this.setState({ country: val }, () => {
      if (this.state.postalCode !== "") {
        this.verifyPostalCode(this.state.postalCode);
      }
    });
    this.props.setCountry(val);
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  getSubscriptionPlan() {
    const AuthHeader = "Bearer ".concat(
      store.getState().auth.tokens.access_token
    );
    axios
      .get(URL_PREFIX + "/api/aboutyourcompany", {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((result) => {
        var plan =
          this.props.discoveryType === "pricing"
            ? result.data.pricing_tier.split("__")
            : this.props.discoveryType === "search-analytics"
            ? result.data.analytics_tier.split("__")
            : this.props.discoveryType === "content"
            ? result.data.content_tier.split("__")
            : this.props.discoveryType === "advertising"
            ? result.data.tier.split("__")
            : null;

        if (!plan) {
          this.setState({ tierlessDiscovery: true });
          return;
        }
        if (plan[1] === "ANNUAL") {
          this.setState({
            subscriptionPlan: subscriptionPlans[plan[0]],
            subscriptionPlanName: plan[0],
            payFrequency: plan[1],
            payAmount: subscriptionPlans[plan[0]].annual["pay"],
            payPlan: subscriptionPlans[plan[0]].annual["plan"],
          });
        } else if (plan[1] === "MONTHLY") {
          this.setState({
            subscriptionPlan: subscriptionPlans[plan[0]],
            subscriptionPlanName: plan[0],
            payFrequency: plan[1],
            payAmount: subscriptionPlans[plan[0]].monthly["pay"],
            payPlan: subscriptionPlans[plan[0]].monthly["plan"],
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: {
            title: "Sorry, failed to fetch your existing subscription plan.",
            message: "Please reload/refresh the page.",
          },
        });
      });
  }

  verifyPostalCode(postalCode) {
    if (postalCodes.validate(this.state.country, postalCode) === true) {
      this.setState({ postalCode: postalCode, validPostalCode: true });
    } else {
      this.setState({ postalCode: postalCode, validPostalCode: false });
    }
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const { stripe, elements } = this.props;

    this.setState({ processingPayment: true });

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      this.setState({
        error: {
          title: "Something's not right",
          message: "Please retry after reloading/refreshing the page.",
        },
      });
    }

    this.createCustomer();
  };

  createCustomer = () => {
    const AuthHeader = "Bearer ".concat(
      store.getState().auth.tokens.access_token
    );

    var billingData = {
      country: this.state.country,
      state: this.state.region,
      postal_code: this.state.postalCode,
      line1: this.state.nameOnCard,
      tier: this.state.payPlan,
    };

    axios
      .post(URL_PREFIX + "/api/billing/customer", billingData, {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((result) => {
        this.setState({ customer: result.data.customer }, () => {
          if (localStorage.latestInvoiceId) {
            this.createPaymentMethod(
              this.props.elements.getElement(CardElement),
              this.state.customer,
              store.getState().auth.tokens.access_token,
              "retry"
            );
          } else {
            this.createPaymentMethod(
              this.props.elements.getElement(CardElement),
              this.state.customer,
              store.getState().auth.tokens.access_token,
              "create"
            );
          }
        });
      })
      .catch((err) => {
        this.setState({
          error: {
            title: "Sorry, something went wrong while charging your card",
            message: "Please try again.",
          },
        });
      });
  };

  createPaymentMethod(cardElement, customerId, auth, action) {
    const { stripe } = this.props;
    var billing_details = {
      address: {
        country: this.state.country,
        postal_code: this.state.postalCode,
        state: this.state.region,
      },
      name: this.state.nameOnCard,
    };

    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billing_details,
      })
      .then((result) => {
        if (result.error) {
          this.setState({
            error: {
              title: "Improper Card Info",
              message: result.error.message,
            },
          });
        } else if (action === "create") {
          this.createSubscription({
            customerId: customerId,
            paymentMethodId: result.paymentMethod.id,
            auth: store.getState().auth.tokens.access_token,
            promoCode: this.props.promoCode,
          });
        } else if (action === "retry") {
          this.retryInvoiceWithNewPaymentMethod({
            customerId: customerId,
            paymentMethodId: result.paymentMethod.id,
            auth: store.getState().auth.tokens.access_token,
          });
        }
      });
  }

  createSubscription({ customerId, paymentMethodId, auth, promoCode }) {
    var paymentInfo = {
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      initialSubscriptionType: this.props.discoveryType,
      promoCode: this.props.promoCode,
    };

    const AuthHeader = "Bearer ".concat(
      store.getState().auth.tokens.access_token
    );

    axios
      .post(URL_PREFIX + "/api/billing/subscription", paymentInfo, {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((result) => {
        // The card had an error when trying to attach it to a customer.
        if (result.error) {
          this.setState({
            error: { title: "Card Declined", message: "Not sure" },
          });
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the addional details we need.
      .then((result) => {
        if (result.data.status === "User already subscribed.") {
          this.setState({
            error: {
              title: "User Already Subscribed",
              message: "Already subscribed",
            },
          });
          throw new Error("User already subscribed.");
        }

        if (result.data.status === "Subscription in retry state.") {
          localStorage.setItem("latestInvoiceId", result.data.invoice.id);
          localStorage.setItem(
            "latestInvoicePaymentIntentStatus",
            result.data.invoice.payment_intent.status
          );

          this.retryInvoiceWithNewPaymentMethod({
            customerId: customerId,
            paymentMethodId: paymentMethodId,
            auth: store.getState().auth.tokens.access_token,
          });

          return {
            paymentMethodId: "break",
            subscription: "break",
          };
        }

        if (
          result.data.subscription.status === "active" ||
          result.data.subscription.status === "trialing"
        ) {
          trackGaEvent(
            localStorage.extensionSignup
              ? "Extension User Converted to Paying Customer"
              : "App User Converted to Paying Customer",
            "Signup"
          );
          trackMixpanelEvent("Click", {
            name: "Subscribe Now",
            element: "button",
            extension_user: localStorage.extensionSignup ? true : false,
          });

          if (localStorage.extensionSignup) {
            localStorage.removeItem("extensionSignup");
          }

          this.setState({ subSuccessful: true });
        }

        return {
          paymentMethodId: paymentMethodId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      // .then(this.handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(this.handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .catch((err) => {
        this.setState({
          error: {
            title:
              "Sorry, something went wrong while trying to charge your card",
            message: "Please try again",
          },
        });
      });
  }

  handleRequiresPaymentMethod = ({ subscription, paymentMethodId }) => {
    if (!(subscription === "break" && paymentMethodId === "break")) {
      var tempSubscription = subscription.data.subscription;
      if (
        tempSubscription.latest_invoice.payment_intent !== null &&
        tempSubscription.latest_invoice.payment_intent.status !== null
      ) {
        if (
          tempSubscription.latest_invoice.payment_intent.status ===
          "requires_payment_method"
        ) {
          localStorage.setItem(
            "latestInvoiceId",
            tempSubscription.latest_invoice.id
          );
          localStorage.setItem(
            "latestInvoicePaymentIntentStatus",
            tempSubscription.latest_invoice.payment_intent.status
          );
          this.setState({
            error: {
              title: "Payment Failed",
              message: "Please try a new payment method.",
            },
          });
        }
      }
    }
  };

  retryInvoiceWithNewPaymentMethod({ customerId, paymentMethodId, auth }) {
    var paymentInfo = {
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      invoiceId: localStorage.getItem("latestInvoiceId"),
    };

    localStorage.removeItem("latestInvoiceId");
    localStorage.removeItem("latestInvoicePaymentIntentStatus");

    const AuthHeader = "Bearer ".concat(
      store.getState().auth.tokens.access_token
    );

    return axios
      .post(URL_PREFIX + "/api/billing/retrysubscription", paymentInfo, {
        params: {},
        headers: { Authorization: AuthHeader },
      })
      .then((result) => {
        if (result.error) {
          this.setState({
            error: {
              title: "Improper Card Info",
              message:
                "Your card was declined, please use a different card to pay.",
            },
          });
          throw result;
        }
        return result;
      })
      .then((result) => {
        if (result.data.status === "User already subscribed.") {
          this.setState({
            error: {
              title: "User Already Subscribed",
              message: "Already subscribed",
            },
          });
          throw new Error("User already subscribed.");
        }

        if (
          result.data.subscription.status === "active" ||
          result.data.subscription.status === "trialing"
        ) {
          this.setState({ subSuccessful: true });
        }

        return {
          paymentMethodId: paymentMethodId,
          subscription: result,
        };
      })
      .then(this.handleRequiresPaymentMethod)
      .catch((err) => {
        this.setState({
          error: {
            title: "Improper Card Info",
            message:
              "Your card was declined, please use a different card to pay.",
          },
        });
      });
  }

  onSubscriptionComplete() {
    this.setState({ subSuccessful: true });
  }

  render() {
    const { stripe } = this.props;
    const { country, region } = this.state;
    return (
      <>
        <Grid container>
          <Grid item xs={1} sm={2} />
          <Grid item xs={10} sm={8}>
            <div
              style={{
                textAlign: "left",
                color: "#393939",
                fontSize: "34px",
                fontWeight: 700,
                marginTop: this.props.discoveryType ? "10rem" : "5rem",
              }}
            >
              Payment Details
            </div>
          </Grid>
        </Grid>

        <form onSubmit={this.handleSubmit}>
          <Grid container>
            <Grid item xs={1} sm={2} />
            <Grid item xs={10} sm={8}>
              <div
                style={{
                  textAlign: "left",
                  color: "#393939",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginTop: "1rem",
                }}
              >
                {this.state.tierlessDiscovery ? (
                  <>
                    No charges will be made until you have selected a
                    subscription tier.
                    <br />
                  </>
                ) : !this.state.payPlan.includes("CORPORATE") ? (
                  <>
                    You'll pay $
                    {isNaN(parseFloat(this.state.payAmount))
                      ? "0"
                      : parseFloat(this.state.payAmount).toFixed(2)}
                    /{this.state.payFrequency === "ANNUAL" ? "year" : "month"}.
                  </>
                ) : (
                  <>
                    Your payment plan will be determined after speaking with a
                    member of the Trellis team.
                    <br />
                  </>
                )}
                <br />
                No contract, cancel anytime.
              </div>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "6rem" }}>
            <Grid item xs={1} sm={2} />
            <Grid item xs={10} sm={8}>
              {this.state.nameOnCard ? (
                <ControlLabel
                  style={{
                    marginLeft: "1rem",
                    color: "#C8C8C8",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Name on Card
                </ControlLabel>
              ) : (
                <ControlLabel> </ControlLabel>
              )}
              {this.state.nameOnCard !== "" && this.state.invalidNameOnCard ? (
                <i style={{ color: "#FF0000" }} className="fa fa-times" />
              ) : null}
              <FormControl
                style={{
                  borderRadius: "0px",
                  border: "none",
                  borderBottom: "solid",
                  borderColor:
                    this.state.nameOnCard !== "" && this.state.invalidNameOnCard
                      ? "#FF0000"
                      : "#C8C8C8",
                  fontSize: "15px",
                  fontWeight: 500,
                  width: "100%",
                  maxWidth: "100%",
                }}
                type="text"
                placeholder="Name on Card"
                value={this.state.nameOnCard}
                name="name_on_card"
                id="name_on_card"
                onChange={(e) => {
                  var NameOnCardRex = /^[a-zA-Z_]+( [a-zA-Z_]+)*$/;
                  NameOnCardRex.test(e.target.value) === false
                    ? this.setState({
                        nameOnCard: e.target.value,
                        invalidNameOnCard: true,
                      })
                    : this.setState({
                        nameOnCard: e.target.value,
                        invalidNameOnCard: false,
                      });
                }}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "4rem", marginBottom: "-20px" }}>
            <Grid item xs={1} sm={2} />
            <Grid item xs={10} sm={8}>
              {!this.state.cardEmpty ? (
                <label
                  style={{
                    marginLeft: "1rem",
                    color: "#C8C8C8",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Card Details
                </label>
              ) : (
                <label> </label>
              )}
              {this.state.cardError ? (
                <i style={{ color: "#FF0000" }} className="fa fa-times" />
              ) : null}
              <CardElement
                options={options}
                onChange={(event) => {
                  if (event.complete) {
                    this.setState({ cardComplete: true });
                  } else {
                    this.setState({ cardComplete: false });
                  }

                  if (event.empty) {
                    this.setState({ cardEmpty: true });
                  } else {
                    this.setState({ cardEmpty: false });
                  }

                  if (event.error) {
                    this.setState({ cardError: true });
                  } else if (event.error === undefined) {
                    this.setState({ cardError: false });
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            style={{ marginLeft: "0px", marginRight: "0px", marginTop: "4rem" }}
          >
            <Grid container>
              <Grid item xs={1} sm={2} />
              <Grid
                item
                xs={10}
                sm={8}
                style={{
                  color: "#C8C8C8",
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                {country ? (
                  <ControlLabel
                    style={{ marginLeft: "1rem", color: "#C8C8C8" }}
                  >
                    Country
                  </ControlLabel>
                ) : (
                  <ControlLabel> </ControlLabel>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1} sm={2} />
              <Grid
                item
                xs={10}
                sm={8}
                style={{
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                <CountryDropdown
                  value={country}
                  valueType="short"
                  defaultOptionLabel="Country"
                  className="payment_select"
                  onChange={(val) => this.selectCountry(val)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ marginLeft: "0px", marginRight: "0px", marginTop: "4rem" }}
          >
            <Grid container>
              <Grid item xs={1} sm={2} />
              <Grid
                item
                xs={10}
                sm={8}
                style={{
                  color: "#C8C8C8",
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                {region ? (
                  <ControlLabel
                    style={{ marginLeft: "1rem", color: "#C8C8C8" }}
                  >
                    State/Province
                  </ControlLabel>
                ) : (
                  <ControlLabel> </ControlLabel>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1} sm={2} />
              <Grid
                item
                xs={10}
                sm={8}
                style={{
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                <RegionDropdown
                  country={country}
                  countryValueType="short"
                  defaultOptionLabel="State/Province"
                  value={region}
                  className="payment_select"
                  blankOptionLabel="State/Province"
                  onChange={(val) => this.selectRegion(val)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "4rem" }}>
            <Grid item xs={1} sm={2} />
            <Grid item xs={10} sm={8}>
              {this.state.postalCode ? (
                <ControlLabel
                  style={{
                    marginLeft: "1rem",
                    color: "#C8C8C8",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Postal Code
                </ControlLabel>
              ) : (
                <ControlLabel> </ControlLabel>
              )}
              {!this.state.validPostalCode ? (
                <i style={{ color: "#FF0000" }} className="fa fa-times" />
              ) : null}
              <FormControl
                style={{
                  borderRadius: "0px",
                  border: "none",
                  borderBottom: "solid",
                  borderColor: this.state.validPostalCode
                    ? "#C8C8C8"
                    : "#ff0033",
                  fontSize: "15px",
                  fontWeight: 500,
                  width: "100%",
                  maxWidth: "100%",
                }}
                type="text"
                placeholder="Postal Code"
                value={this.state.postalCode}
                name="postal_code"
                id="postal_code"
                onChange={(e) => this.verifyPostalCode(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "6rem" }}>
            <Grid item xs={1} sm={2} />
            <Grid item xs={10} sm={8}>
              {this.state.processingPayment ? (
                <AuthButton
                  title={
                    <span>
                      <i className="fa fa-spin fa-circle-o-notch" /> Processing
                      your payment
                    </span>
                  }
                  buttonStyle="adplan_button"
                  disabled={true}
                  style={{ width: "100%", maxWidth: "100%" }}
                />
              ) : (
                <AuthButton
                  title={
                    <span>
                      Subscribe Now{" "}
                      {this.state.subscriptionPlanName !== "CORPORATE" &&
                        !this.state.tierlessDiscovery && (
                          <>
                            ($
                            {parseFloat(this.state.payAmount).toFixed(2)})
                          </>
                        )}
                    </span>
                  }
                  buttonStyle="adplan_button"
                  disabled={
                    !stripe ||
                    this.state.nameOnCard === "" ||
                    this.state.invalidNameOnCard ||
                    this.state.country === "" ||
                    this.state.region === "" ||
                    this.state.postalCode === "" ||
                    !this.state.validPostalCode ||
                    !this.state.cardComplete
                  }
                  style={{ width: "100%", maxWidth: "100%" }}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            container
            style={{ marginTop: "2rem", justifyContent: "center" }}
            className="text-center"
          >
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="119px"
                height="26px"
                src={poweredByStripe}
                alt="Stripe"
                style={{}}
              />
            </a>
          </Grid>
        </form>
        {this.state.error.title !== undefined ? (
          <SweetAlert
            title={this.state.error.title}
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={(e) =>
              this.setState({ error: {}, processingPayment: false })
            }
          >
            {this.state.error.message}
          </SweetAlert>
        ) : null}
        {this.state.processingPayment &&
        this.state.error.title === undefined ? (
          <PaymentProcessing
            subSuccessful={this.state.subSuccessful}
            error={this.state.error}
            discoveryType={this.props.discoveryType}
          />
        ) : null}
      </>
    );
  }
}

// TODO: Revisit and refactor above to functional component => it's very confusing having one class and one functional in the same file
const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm
          elements={elements}
          stripe={stripe}
          discoveryType={props.discoveryType}
          promoCode={props.promoCode}
          setCountry={props.setCountry}
        />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;
