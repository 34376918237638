import {
  UPDATE_ALLOW_MWS_ORDERS,
  UPDATE_PRODUCT_IDENTIFIER,
  DEFAULT_MARKETPLACE,
  UPDATE_USER_PREFERENCES,
  UPDATE_SUBSCRIPTION_TIER,
  UPDATE_BYPASS_ONBOARDING,
} from "../types";

export const setAllowMwsOrders = (allowMwsOrders) => {
  return {
    type: UPDATE_ALLOW_MWS_ORDERS,
    payload: allowMwsOrders,
  };
};

export const setProductIndentifier = (productIndentifier) => {
  return {
    type: UPDATE_PRODUCT_IDENTIFIER,
    payload: productIndentifier,
  };
};

export const setDefaultMarketPlace = (newDefaultMarketPlace) => {
  return {
    type: DEFAULT_MARKETPLACE,
    payload: newDefaultMarketPlace,
  };
};

export const setPerformanceMetric = (metric, preferences) => {
  return {
    type: UPDATE_USER_PREFERENCES,
    payload: { ...preferences, acos_or_roas: metric },
  };
};

export const setNotificationPreferences = (updatedPreferences, preferences) => {
  return {
    type: UPDATE_USER_PREFERENCES,
    payload: { ...preferences, ...updatedPreferences },
  };
};

export const setMerchandiseDashboard = (merchandise_dashboard, preferences) => {
  return {
    type: UPDATE_USER_PREFERENCES,
    payload: { ...preferences, merchandise_dashboard },
  };
};

export const setSubscriptionTiers = (updatedSubscription) => {
  return {
    type: UPDATE_SUBSCRIPTION_TIER,
    payload: { ...updatedSubscription },
  };
};

export const setBypassOnboarding = (bypass_onboarding) => {
  return {
    type: UPDATE_BYPASS_ONBOARDING,
    payload: { bypass_onboarding },
  };
};
