import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import OrgSwitcher from "components/core/blocks/OrgSwitcher";
import SidebarLink from "./SidebarLink";
import checkModule from "utils/checkModule";

const StaffSidebar = ({ modules, activeSection }) => {
  const [openStaff, setOpenStaff] = useState(false);

  const isActive = (routeName) => {
    return document.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const activeLinkStyles = {
    borderBottom: "1px solid #2e0054",
    borderRadius: 0,
  };

  return (
    <li
      className="user w-100"
      style={{
        marginBottom: "2rem",
        marginTop: 0,
        paddingBottom: 0,
        borderBottom: "none",
      }}
    >
      <NavLink
        to="#"
        href="#perf"
        onClick={(e) => {
          e.preventDefault();
          setOpenStaff(!openStaff);
        }}
        style={{
          backgroundColor: activeSection === "staff" ? "#2e0054" : "inherit",
        }}
      >
        <i
          className="pe-7s-keypad"
          style={{
            color: activeSection === "staff" ? "#FFF" : "#2e0054",
          }}
        ></i>
        <p
          className="w-100"
          style={{
            color: activeSection === "staff" ? "#FFF" : "#2e0054",
            width: "100%",
          }}
        >
          Staff Tools
          <span
            className={`caret ${openStaff && `rotate-180`}  dropdown-toggle`}
          />
        </p>
      </NavLink>
      <Collapse in={openStaff}>
        <ul className="nav">
          <SidebarLink
            small
            to={"/user/staff/dashboard"}
            isActive={isActive("/user/staff/dashboard")}
            icon={<i className="fa fa-list" />}
          >
            All Customers
          </SidebarLink>
          <li className="w-100">
            <a>
              <span className="sidebar-mini">
                <i className="fa fa-group" />
              </span>
              <span className="sidebar-normal sidebar-link">
                <OrgSwitcher />
              </span>
            </a>
          </li>
          <li className={`w-100 ${isActive("/user/applications")}`}>
            <Link
              to="/user/applications"
              style={isActive("/user/applications") ? activeLinkStyles : {}}
            >
              <span className="sidebar-mini">
                <i className="fa fa-key" />
              </span>
              <span className="sidebar-normal sidebar-link">
                Manage Applications
              </span>
            </Link>
          </li>
          {checkModule(modules, "AGENCY_MANAGEMENT_VIEW") && (
            <li className={`w-100 ${isActive("/user/agencyusers")}`}>
              <Link
                to="/user/agencyusers"
                style={isActive("/user/agencyusers") ? activeLinkStyles : {}}
              >
                <span className="sidebar-mini">
                  <i className="fa fa-sitemap" />
                </span>
                <span className="sidebar-normal sidebar-link">
                  Agency Management
                </span>
              </Link>
            </li>
          )}
        </ul>
      </Collapse>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    channel: state.channel.currentChannel,
  };
};

export default connect(mapStateToProps)(withRouter(StaffSidebar));
