import { ButtonGroup, Button } from "@material-ui/core";
import React from "react";

const RollupTypeFilter = ({ selectedRollupType, filterRollupType }) => {
  return (
    <ButtonGroup style={{ marginRight: "auto" }}>
      <Button
        variant={selectedRollupType === "page_1" ? `contained` : null}
        color={selectedRollupType === "page_1" ? `secondary` : null}
        onClick={() => filterRollupType("page_1")}
      >
        Page 1
      </Button>
      <Button
        variant={selectedRollupType === "above_fold" ? `contained` : null}
        color={selectedRollupType === "above_fold" ? `secondary` : null}
        onClick={() => filterRollupType("above_fold")}
      >
        Above Fold
      </Button>
    </ButtonGroup>
  );
};

export default RollupTypeFilter;
