import React, { useState, useRef, useMemo } from "react";
import { useFetch } from "hooks/api";
import { useMutation, useQueryClient } from "react-query";
import api from "utils/api";
import NotificationSystem from "react-notification-system";
import { Col, Row } from "react-bootstrap";
import { useDates } from "dates/useDates";
import ProductCard from "components/custom/merchandise/ProductCard";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import { ProductCell } from "views/merchandise/Dashboard";
import acosOrRoas from "utils/acosOrRoas";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import { sendToastNotification } from "utils/sendToastNotification";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import {
  INVENTORY_STATE_FILTER,
  SALES_STATE_FILTER,
  PRODUCT_WITH_ADS_FILTER,
  buildCustomTagsFilter,
} from "./productLifecycleFilters";
import BulkTagsForm from "components/core/blocks/BulkTagsForm";

const ExpandedContent = ({ marketPlace, row, user, channel }) => {
  return (
    <div style={{ backgroundColor: "#efefef" }} className="text-start">
      <ProductCard
        marketPlace={marketPlace}
        productId={row?.original?.product_id}
        user={user}
        channel={channel?.currentChannel}
        initialTab="advertising"
        hideTabNav={true}
      />
    </div>
  );
};

const CategoryProducts = ({
  categoryId,
  marketPlace,
  channel,
  user,
  smallViewportWidthTable,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filledTags, setFilledTags] = useState([]);

  const TABLE_ID = "productsTable";
  const tableRef = useRef();
  const notificationRef = useRef();

  const queryClient = useQueryClient();

  const { group, start, end, preStart, preEnd } = useDates();
  const { preferences } = useMerchandisePreferences();

  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  const filters = {
    mp: marketPlace.marketPlace,
    channel: channel,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    group_by: "product_id",
    category: categoryId,
  };

  const { data: productData, isLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      categoryId,
    ],
    "/api/data_report/product_report",
    { ...filters, group_by: "product_id" },
    {
      select: (d) => d.data,
      enabled: !!categoryId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const customTags = useMemo(() => {
    return (
      [
        ...new Set(
          productData?.products?.map((product) => product.tags)?.flat()
        ),
      ] ?? []
    );
  }, [productData]);

  const updateHandler = useMutation(
    async (body) => {
      let reqData = body.data ?? {};

      return await api.put(`/api/gvads/productid/`, reqData);
    },
    {
      onSuccess: () => {
        sendToastNotification(
          notificationRef,
          "success",
          "Product Ad Status Successfully Updated"
        );
        setSelectedProducts([]);
        queryClient.invalidateQueries();
      },
      onError: (data, variables) => {
        sendToastNotification(
          notificationRef,
          "warning",
          "Failed to Update Product Ad Status"
        );
        setSelectedProducts([]);
        queryClient.invalidateQueries();
      },
    }
  );

  const updateTags = useMutation(
    async (updateData) => {
      const { product_id, tags } = updateData;

      return await api.patch(`/api/gvads/productid/${product_id}`, { tags });
    },
    {
      onSuccess: (res, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "success",
          `Product Tags Successfully Updated`
        );

        queryClient.invalidateQueries([
          "product_report",
          marketPlace.marketPlace,
          start,
          end,
          preStart,
          preEnd,
          categoryId,
        ]);
        setSelectedProducts([]);
        setFilledTags([]);
      },
      onError: (res, variables) => {
        if (!variables.isLast) {
          return;
        }
        sendToastNotification(
          notificationRef,
          "warning",
          `Failed to Update Product Tags`
        );
      },
    }
  );

  const updateProductAdStatus = (newStatus) => {
    const productData = {
      product_ids: selectedProducts,
      status: newStatus,
    };

    updateHandler.mutate({
      data: productData,
    });
  };

  const updateProductTags = (type = "add") => {
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = productData?.products?.find(
        (product) => product.id === selectedProducts[i]
      );
      const updatedTags =
        type === "remove"
          ? product?.tags?.filter((tag) => !filledTags.includes(tag)) ?? []
          : [...(product?.tags ?? []), ...filledTags];

      updateTags.mutate({
        product_id: product.id,
        tags: updatedTags,
        isLast: i === selectedProducts?.length - 1,
      });
    }
  };

  let bulkActionOptions = [
    {
      label: "Pause Product Advertisements",
      action: () => {
        updateProductAdStatus("paused");
      },
      confirmationMessage: (
        <p>
          Are you sure you want to pause all product advertisements for the
          selected product{selectedProducts?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: "Enable Product Advertisements",
      action: () => {
        updateProductAdStatus("enabled");
      },
      confirmationMessage: (
        <p>
          Are you sure you want to enable all product advertisements for the
          selected product{selectedProducts?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: "Add Product Tags",
      action: () => {
        updateProductTags();
      },
      actionFormFields: (
        <BulkTagsForm
          filledTags={filledTags}
          setFilledTags={setFilledTags}
          selected={selectedProducts}
          suggestedTags={customTags}
          taggedItemDescription={"product"}
          actionType={"add"}
        />
      ),
      confirmationMessage: (
        <p>
          Are you sure you want to add{" "}
          {filledTags?.length > 1 ? "these tags" : "this tag"} from the selected
          product
          {selectedProducts?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
    {
      label: "Remove Product Tags",
      action: () => {
        updateProductTags("remove");
      },
      actionFormFields: (
        <BulkTagsForm
          filledTags={filledTags}
          setFilledTags={setFilledTags}
          selected={selectedProducts}
          suggestedTags={customTags}
          taggedItemDescription={"product"}
          actionType={"remove"}
        />
      ),
      confirmationMessage: (
        <p>
          Are you sure you want to remove{" "}
          {filledTags?.length > 1 ? "these tags" : "this tag"} from the selected
          product
          {selectedProducts?.length > 1 ? "s" : ""}?
        </p>
      ),
    },
  ];

  const ADDITIONAL_FILTERS = [
    INVENTORY_STATE_FILTER,
    SALES_STATE_FILTER,
    PRODUCT_WITH_ADS_FILTER,
    buildCustomTagsFilter(customTags),
  ];

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  return (
    <Row>
      <Col xs={12} className="text-end pb-3">
        <NotificationSystem ref={notificationRef} />
        <BulkActionsTable
          tableId={TABLE_ID}
          tableRef={tableRef}
          tableEntityType={"product"}
          titleCol={{
            id: "products",
            accessor: "product_title",
            headerClassName: "d-flex",
            className: "text-start mr-auto",
            style: { width: smallViewportWidthTable ? "250px" : "350px" },
            width: smallViewportWidthTable ? 250 : 350,
            Header: <span className="text-start">Products</span>,
            isStatic: true,
            Cell: ({ value, row }) => {
              return (
                <ProductCell
                  value={value}
                  row={row}
                  original={{
                    ...row._original,
                    id: row._original?.product_id,
                    cellContext: "advertising",
                  }}
                  showAsin={true}
                  showInventoryBadge={true}
                />
              );
            },
            filterMethod: () => {
              return;
            },
            Filter: () => null,
          }}
          filterWidget={true}
          hideFilterToggleIcon={true}
          {...filterProps}
          additionalFilterOptions={ADDITIONAL_FILTERS}
          ExpandedContent={ExpandedContent}
          columnSpecs={[
            {
              key: "product_price",
              compareKey: null,
              options: {
                metric: {
                  id: "product_price",
                  format: "currency",
                  accessor: (d) => d?.product_price,
                  name: "Current Price",
                },
              },
            },
            {
              key: "sales",
              compareKey: "sales_percent",
            },
            {
              key: "units",
              compareKey: "units_percent",
            },
            acosroas(
              {
                key: "acos",
                compareKey: "acos_delta",
                options: {
                  isNegative: true,
                },
              },
              { key: "roas", compareKey: "roas_delta" }
            ),

            acosroas(
              {
                key: "acos_same_sku",
                compareKey: "acos_same_sku_delta",
                options: {
                  isNegative: true,
                  checked: false,
                },
              },
              {
                key: "roas_same_sku",
                compareKey: "roas_same_sku_delta",
                options: { checked: false },
              }
            ),
            acosroas(
              {
                key: "total_acos",
                compareKey: "total_acos_delta",
                options: {
                  isNegative: true,
                  checked: false,
                },
              },
              {
                key: "troas",
                compareKey: "troas_delta",
                options: {
                  checked: true,
                },
              }
            ),
            {
              key: "ad_sales",
              compareKey: "ad_sales_percent",
            },
            {
              key: "ad_sales_same_sku",
              compareKey: "ad_sales_same_sku_percent",
              options: { checked: false },
            },
            { key: "ad_spend", compareKey: "ad_spend_percent" },
            { key: "impressions", compareKey: "impressions_percent" },
            { key: "clicks", compareKey: "clicks_percent" },
            {
              key: "ctr",
              compareKey: "ctr_delta",
              options: { checked: false },
            },
            {
              key: "conversion_rate",
              compareKey: "conversion_rate_delta",
              options: { checked: false },
            },
            {
              key: "orders",
              compareKey: "orders_percent",
              options: { checked: false },
            },
            {
              key: "cpc",
              compareKey: "cpc_delta",
              options: { checked: false, isNegative: true },
            },
            {
              key: "last_bsr_rank",
              compareKey: "last_bsr_rank_delta",
              options: { checked: false, isNegative: true },
            },
          ]}
          user={user}
          data={productData?.products}
          selectionKey="product_id"
          selected={selectedProducts}
          setSelected={setSelectedProducts}
          isExpandable={true}
          isSelectable={true}
          isLoading={isLoading}
          bulkActionOptions={bulkActionOptions}
          getSearchCriteria={(row) => {
            const { product_title, sku, asin, id, tags } = row;

            return `${product_title} ${sku} ${asin} ${id} ${tags?.join(" ")}`;
          }}
          defaultPageSize={10}
          tableRowDescription={"Products"}
        />
      </Col>
    </Row>
  );
};

export default CategoryProducts;
