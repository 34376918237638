import { MARKETPLACE_CURRENCY_CODE } from "utils/marketplaceConstants";
import { AD_TYPES, GOALS } from "components/core/blocks/AdPlan/constants";
import { trellisPalette } from "../analytics/palettes";

export const combineYoYData = (periodData, prevYearData) => {
  const combinedData = { ...periodData };

  for (const key in prevYearData) {
    if (
      key.includes("percent") ||
      key.includes("delta") ||
      key.includes("prev")
    ) {
      combinedData[`${key}_yoy`] = prevYearData[key];
    }
  }

  return combinedData;
};

export const SERIES_PALETTE = [
  "#573675",
  "#5B4B85",
  "#E2D3F2",
  "#2b1750",
  "#7769a1",
];

export const PAGE_WIDTH = "1552px";
export const PAGE_HEIGHT = "1182px";

export const STEPS_BY_GROUP = {
  day: 8,
  week: 4,
  month: 2,
};

const POSITIVE_TREND_COLOR = "#487052";
const NEGATIVE_TREND_COLOR = "#8D0801";

export const getTrendColor = (value, metricId) => {
  metricId = metricId.toLowerCase();

  if (
    ["acos", "cpc", "cost_per_click", "bsr"].some((reverseTrendMetric) =>
      metricId.includes(reverseTrendMetric)
    )
  ) {
    return value > 0
      ? NEGATIVE_TREND_COLOR
      : value < 0
      ? POSITIVE_TREND_COLOR
      : "#152748";
  }

  return value > 0
    ? POSITIVE_TREND_COLOR
    : value < 0
    ? NEGATIVE_TREND_COLOR
    : "#152748";
};

// Returns a description of the corresponding currency symbol (i.e. dollars, pounds, yen)
export const getCurrencyDescription = (marketPlace) => {
  const currency = MARKETPLACE_CURRENCY_CODE[marketPlace ?? "USA"];

  if (marketPlace === "UK") {
    marketPlace = "GB";
  }

  let formattedCurrency = new Intl.NumberFormat(marketPlace, {
    style: "currency",
    currency: currency,
    currencyDisplay: "name",
  }).format(1);

  formattedCurrency = formattedCurrency
    ?.split(" ")
    ?.filter((sub) => !parseFloat(sub))
    ?.join(" ");

  return formattedCurrency;
};

export const filterNTMAdsNotEnrolled = (ads) => {
  let trellisManagedAds = ads?.filter((ad) => {
    const { ad_goal, non_trellis_enrolled_ad_accept_bids } = ad;

    return (
      (ad_goal === GOALS.IMPORTED_ADS.value &&
        non_trellis_enrolled_ad_accept_bids) ||
      ad_goal !== GOALS.IMPORTED_ADS.value
    );
  });

  return trellisManagedAds;
};

export const adTypeColorMap = {
  // Amazon Ad Types
  [AD_TYPES.SPONSORED_PRODUCT.value]: trellisPalette[3],
  [AD_TYPES.SPONSORED_PRODUCT_AUTO.value]: trellisPalette[2],
  [AD_TYPES.SPONSORED_BRAND.value]: trellisPalette[1],
  [AD_TYPES.SPONSORED_BRAND_V2.value]: trellisPalette[1],
  [AD_TYPES.SPONSORED_BRAND_VIDEO.value]: trellisPalette[14],
  [AD_TYPES.SPONSORED_DISPLAY.value]: trellisPalette[5],
  [AD_TYPES.SPONSORED_DISPLAY_V2.value]: trellisPalette[5],
  // Walmart Ad Types
  [AD_TYPES.SPONSORED_PRODUCT_WALMART.value]: trellisPalette[3],
  [AD_TYPES.SPONSORED_PRODUCT_AUTO_WALMART.value]: trellisPalette[2],
  [AD_TYPES.SPONSORED_BRAND_WALMART.value]: trellisPalette[1],
  [AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value]: trellisPalette[14],
};

export const GOAL_COLORS = [
  trellisPalette[1],
  trellisPalette[2],
  trellisPalette[3],
];

export const PAGE_INDEXES = {
  OVERVIEW_PAGE_INDEX: 0,
  IMPACTFUL_UPATES_PAGE_INDEX: 1,
  NEW_MEDIA_PLANS_PAGE_INDEX: 2,
  MEDIA_PLAN_TRENDS_PAGE_INDEX: 3,
  NEW_KEYWORDS_PAGE_INDEX: 4,
  TOP_KEYWORDS_PAGE_INDEX: 5,
  MEDIA_PLAN_TARGETS_PAGE_INDEX: 6,
  PRODUCT_TRENDS_PAGE_INDEX: 7,
  TOP_PRODUCTS_PAGE_INDEX: 8,
};
