import { PrimaryButton, SecondaryButton } from "components/core/basic/Button";
import React from "react";
import { Col, Row } from "react-bootstrap";

const EditFooterActions = ({ onEdit, onCancel, isLoading }) => {
  return (
    <Row className="mt-4">
      <Col md={12} className="d-flex justify-content-end">
        <div style={{ marginRight: "2rem" }}>
          <SecondaryButton nowrap onClick={onCancel}>
            Cancel
          </SecondaryButton>
        </div>
        <div>
          <PrimaryButton disabled={isLoading} onClick={onEdit} nowrap>
            Update
          </PrimaryButton>
        </div>
      </Col>
    </Row>
  );
};

export default EditFooterActions;
