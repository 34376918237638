import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import bgImage from "assets/images/background/purple_gradient.png";
import CustomHref from "components/core/blocks/CustomHref";
import PaymentDetailsForm from "components/custom/billing/PaymentDetailsForm";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Hidden, withStyles } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  refreshAmzOnboardStatus,
  updateAmzOnboardingStatus,
} from "redux/actions/amzOnboardActions";
import { clearErrors, setErrors } from "redux/actions/errorActions";
import { getStripePublicKey } from "utils/getUrlPrefix";
import SubscriptionCard from "./SubscriptionCard";
import { formatCurrency } from "utils/formatNumber";

let STRIPE_PUBLIC_KEY = getStripePublicKey();

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const useStyles = (theme) => ({
  rightImageContainer: {
    backgroundImage: "url(" + bgImage + ")",
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
  },
});

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLayout: false,
    };
  }

  componentDidMount() {
    refreshAmzOnboardStatus(
      this.props.updateAmzOnboardingStatus,
      this.props.auth.tokens.access_token,
      this.props.setErrors
    );
  }

  componentDidUpdate() {
    if (window.location.href.includes("user") && !this.state.userLayout) {
      this.setState({ userLayout: true });
    }
  }

  setCountry = (country) => {
    this.setState({ cardCountry: country });
  };

  render() {
    const { planDetails, monthlyChecked } = this.props.location?.state ?? {};
    return (
      <Grid
        container
        style={{ marginLeft: "0px", marginRight: "0px" }}
        translate="no"
      >
        <Grid
          item
          xs={12}
          md={7}
          // mdOffset={this.state.userLayout ? 3 : 0}
          style={{
            backgroundColor: "#ffffff",
            paddingLeft: "0px",
            paddingRight: "0px",
            overflowX: "hidden",
          }}
        >
          <Row md={12} style={{ marginLeft: "0px", marginRight: "0px" }}>
            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Elements stripe={stripePromise}>
                <PaymentDetailsForm
                  discoveryType={this.props.location?.state?.discoveryType}
                  promoCode={this.props.location?.state?.promoCode}
                  setCountry={this.setCountry}
                />
              </Elements>
            </Col>
          </Row>
          {this.state.userLayout ? (
            <Row
              style={{
                marginTop: "4rem",
                marginBottom: "2rem",
                marginLeft: "2rem",
                marginRight: "0px",
              }}
            >
              <Col md={11} mdOffset={1}>
                <span
                  style={{ fontSize: "14px", float: "left" }}
                  onClick={(e) => {
                    this.props.location?.state?.discoveryType ===
                    "search-analytics"
                      ? this.props.history.push("/user/searchanalytics")
                      : this.props.location?.state?.discoveryType === "pricing"
                      ? this.props.history.push("/user/pricing")
                      : this.props.history.push("/user/dashboard");
                  }}
                >
                  <CustomHref text={"Change Subscription"} color={"#c8c8c8"} />
                </span>
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                marginTop: "5rem",
                marginBottom: "2rem",
                marginLeft: "2rem",
                marginRight: "0px",
              }}
            >
              <Col md={11} mdOffset={1}>
                <span
                  style={{ fontSize: "14px", float: "left" }}
                  onClick={(e) => {
                    this.props.history.push("/onboard/plans");
                  }}
                >
                  <CustomHref text={"Back"} color={"#c8c8c8"} />
                </span>
              </Col>
            </Row>
          )}
        </Grid>
        {this.props.location?.state?.discoveryType && (
          <Hidden smDown>
            <Grid
              item
              md={5}
              style={{
                height: "100vh",
                overflow: "hidden",
                paddingLeft: "1rem",
                paddingRight: "3rem",
              }}
              translate="yes"
            >
              <Row md={12} style={{ marginLeft: "0px", marginRight: "0px" }}>
                <Col
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginTop: "30vh",
                  }}
                >
                  <SubscriptionCard
                    planId={planDetails?.displayName}
                    planName={planDetails?.displayName}
                    core={true}
                    monthlySubscriptionPrice={
                      planDetails?.monthly?.pay
                        ? `$${planDetails?.monthly.pay}`
                        : planDetails?.displayName === "Custom"
                        ? "TBD"
                        : "Free"
                    }
                    annualSubscriptionPrice={
                      planDetails?.monthly?.pay
                        ? `${formatCurrency(
                            planDetails?.monthly.pay * 0.9,
                            "USA",
                            true
                          )}`
                        : planDetails?.displayName === "Custom"
                        ? "TBD"
                        : "Free"
                    }
                    monthlyChecked={monthlyChecked}
                    subscriptionFeatures={
                      <>
                        {typeof planDetails?.features === "object"
                          ? planDetails?.features.map((feature) => (
                              <>
                                <span>{feature}</span>
                                <br />
                              </>
                            ))
                          : planDetails?.features}
                      </>
                    }
                    selectedSubscription={planDetails?.displayName}
                    annualSales={
                      planDetails?.annualSales &&
                      planDetails?.annualSales !== "n/a"
                        ? planDetails?.annualSales
                        : null
                    }
                  />
                  <Row
                    md={12}
                    style={{
                      margin: "1rem 2rem",
                      borderTop: "2px solid #DDD",
                      marginTop: "50px",
                      paddingTop: "2rem",
                    }}
                  >
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: "3rem",
                      }}
                    >
                      <h5 style={{ fontWeight: "500", color: "#777" }}>
                        Subtotal
                      </h5>
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: "4rem",
                        textAlign: "right",
                      }}
                    >
                      <h5 style={{ fontWeight: "500", color: "#777" }}>
                        {monthlyChecked
                          ? planDetails?.annual?.pay
                            ? `${formatCurrency(
                                planDetails?.monthly.pay,
                                "USA",
                                false
                              )} USD`
                            : planDetails?.monthly.pay === 0
                            ? "Free"
                            : "TBD"
                          : planDetails?.annual?.pay
                          ? `${formatCurrency(
                              planDetails?.annual.pay,
                              "USA",
                              false
                            )} USD`
                          : planDetails?.annual.pay === 0
                          ? "Free"
                          : "TBD"}
                      </h5>
                    </Col>
                  </Row>
                  {this.state.cardCountry === "CA" && (
                    <Row md={12} style={{ margin: "0 2rem" }}>
                      <Col
                        xs={6}
                        style={{
                          paddingLeft: "1rem",
                          paddingRight: "3rem",
                        }}
                      >
                        <h5 style={{ fontWeight: "500", color: "#777" }}>
                          Taxes
                        </h5>
                      </Col>
                      <Col
                        xs={6}
                        style={{
                          paddingLeft: "1rem",
                          paddingRight: "4rem",
                          textAlign: "right",
                        }}
                      >
                        <h5 style={{ fontWeight: "500", color: "#777" }}>
                          {" "}
                          {monthlyChecked
                            ? planDetails?.annual?.pay
                              ? `${formatCurrency(
                                  planDetails?.monthly.pay * 0.13,
                                  "USA",
                                  false
                                )} USD`
                              : planDetails?.monthly.pay === 0
                              ? "Free"
                              : "TBD"
                            : planDetails?.annual?.pay
                            ? `${formatCurrency(
                                planDetails?.annual.pay * 0.13,
                                "USA",
                                false
                              )} USD`
                            : planDetails?.annual.pay === 0
                            ? "Free"
                            : "TBD"}
                        </h5>
                      </Col>
                    </Row>
                  )}
                  <Row
                    md={12}
                    style={{
                      margin: "1rem 2rem",
                      borderTop: "2px solid #DDD",
                      paddingTop: "2rem",
                    }}
                  >
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: "3rem",
                      }}
                    >
                      <h5 style={{ fontWeight: "500", color: "#777" }}>
                        Total
                      </h5>
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: "4rem",
                        textAlign: "right",
                      }}
                    >
                      <h5 style={{ fontWeight: "500", color: "#777" }}>
                        {monthlyChecked
                          ? planDetails?.annual?.pay
                            ? `${formatCurrency(
                                planDetails?.monthly.pay *
                                  (this.state.cardCountry === "CA" ? 1.13 : 1),
                                "USA",
                                false
                              )} USD`
                            : planDetails?.monthly.pay === 0
                            ? "Free"
                            : "TBD"
                          : planDetails?.annual?.pay
                          ? `${formatCurrency(
                              planDetails?.annual.pay *
                                (this.state.cardCountry === "CA" ? 1.13 : 1),
                              "USA",
                              false
                            )} USD`
                          : planDetails?.annual.pay
                          ? "Free"
                          : "TBD"}
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </Hidden>
        )}
        {/* )} */}
      </Grid>
    );
  }
}

PaymentDetails.propTypes = {
  updateAmzOnboardingStatus: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  amz_onboard: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  amz_onboard: state.amz_onboard,
  user: state.user,
});

const mapDispatchToProps = {
  updateAmzOnboardingStatus,
  setErrors,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PaymentDetails)));
