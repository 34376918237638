import { LogoutButton } from "components/core/basic/Button";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { trellisPalette } from "components/custom/analytics/palettes";
import { useFetch } from "hooks/api";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { uploadAsset, getAssetSrcUrl } from "utils/assetsUtils";
import ToolTips from "utils/toolTips";
import AssetSummary from "../AssetSelector/AssetSummary";
import CustomHref from "../CustomHref";
import HelpModal from "../HelpModal";
import Loading from "../Loading";
import AdPlanModerationWarnings from "./AdPlanModerationWarnings";
import { AD_TYPES, MODERATION_STATUSES } from "./constants";

const WalmartSBFields = ({
  state,
  errors,
  onChange,
  adId,
  review,
  isModal,
  fieldsToValidate,
}) => {
  const [assetURL, setAssetURL] = useState(state.walmartImage ?? undefined);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [assetDimensions, setAssetDimensions] = useState(undefined);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const assetDimensionsRef = useRef();

  const allowCreativesEdit =
    state.advModerationStatus === MODERATION_STATUSES.FAILED.value ||
    (!state.id && !review);

  useFetch(
    "assets",
    "/creatives/media/assets",
    {},
    {
      onSuccess: (res) => {
        let creativeAsset = res.data.find(
          (a) => a.id === state?.walmartAssetId
        );

        let captionAsset = res.data.find(
          (a) => a.id === state?.walmartCaptionAssetId
        );

        if (creativeAsset) {
          setAssetURL(creativeAsset.url);
          onChange({ walmartAssetId: state.walmartAssetId });
        }

        if (captionAsset) {
          onChange({
            walmartCaptionURL: captionAsset?.url,
            walmartCaptionFileName: captionAsset?.name,
          });
        }
      },

      enabled: !!state.walmartAssetId,
    }
  );

  useEffect(() => {
    if (fileUploadError) {
      onChange({
        walmartVideo: null,
        walmartAssetId: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadError]);

  const validateVideoMetadata = () => {
    if (
      assetDimensionsRef?.current?.duration < 5 ||
      assetDimensionsRef.current?.duration > 31
    ) {
      setFileUploadError(
        "The uploaded video must be between 5 and 30 seconds long."
      );
      return;
    }

    if (
      assetDimensionsRef.current.videoHeight < 1080 ||
      assetDimensionsRef.current.videoHeight > 2160
    ) {
      setFileUploadError(
        "The uploaded video must be between 1080 and 2160px wide"
      );
      return;
    }

    if (
      assetDimensionsRef.current.videoWidth < 1920 ||
      assetDimensionsRef.current.videoWidth > 3840
    ) {
      setFileUploadError(
        "The uploaded video must be between 1920 and 3840px in height"
      );
      return;
    }

    // toFixed() needed for comparison to ever be true for aspect ratio
    if (
      (
        assetDimensionsRef.current.videoWidth /
        assetDimensionsRef.current.videoHeight
      ).toFixed(2) !== (16 / 9).toFixed(2)
    ) {
      setFileUploadError("The uploaded video have an aspect ratio of 16:9");
      return;
    }
  };

  return (
    <div>
      {!adId && (
        <>
          <h2
            style={{
              fontWeight: 700,
              marginTop: 0,
              fontSize: "24px",
            }}
          >
            Sponsored Brand Details
          </h2>

          <p
            style={{ fontSize: "16px", marginBottom: "2rem", maxWidth: "90%" }}
            hidden={review}
            translate="yes"
          >
            Promote your brand and products in a customized ad at the top of
            related Walmart product pages.
          </p>
        </>
      )}
      {adId && state.policyViolations?.length > 0 && (
        <AdPlanModerationWarnings violations={state.policyViolations} />
      )}
      {state.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value && (
        <>
          <Row style={{ marginTop: "2rem" }}>
            <Col xs={12} lg={6} className="pr-5">
              <FormGroupTile
                type="formcontrol"
                label={"Brand Name"}
                formControlType={"string"}
                stateName={"walmartBrandName"}
                labelClassName={"fs-standard"}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: value })
                }
                disabled={
                  ((adId !== undefined || state.id !== undefined) &&
                    !allowCreativesEdit) ||
                  (state.id && isModal && !allowCreativesEdit)
                }
                placeholder={"Brand Name"}
                defaultValue={state.walmartBrandName}
              />
              <div className="text-start pt-3">
                {errors.walmartBrandName &&
                  (review ||
                    fieldsToValidate?.includes("walmartBrandName")) && (
                    <small
                      className="text-danger"
                      style={{
                        paddingLeft: ".8rem",
                        display: "block",
                        paddingBottom: "1rem",
                      }}
                    >
                      {errors.walmartBrandName}
                    </small>
                  )}
              </div>
            </Col>
            <Col xs={12} lg={6} className="pl-5">
              <FormGroupTile
                type="formcontrol"
                stateName={"walmartHeadline"}
                formControlType={"string"}
                labelClassName={"fs-standard"}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: value })
                }
                disabled={
                  ((adId !== undefined || state.id !== undefined) &&
                    !allowCreativesEdit) ||
                  (state.id && isModal && !allowCreativesEdit)
                }
                label={"Headline"}
                placeholder={"Headline"}
                defaultValue={state.walmartHeadline}
                // formControlClass={"text-left"}
              />
              <div className="text-start pt-3">
                {errors.walmartHeadline &&
                  (review || fieldsToValidate?.includes("walmartHeadline")) && (
                    <small
                      className="text-danger"
                      style={{
                        paddingLeft: ".8rem",
                        paddingBottom: "1rem",
                        display: "block",
                      }}
                    >
                      {errors.walmartHeadline}
                    </small>
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-4">
              <FormGroupTile
                type="formcontrol"
                stateName={"walmartClickURL"}
                formControlType={"string"}
                labelClassName={"fs-standard"}
                handleChildFormElement={(key, value) =>
                  onChange({ [key]: value })
                }
                disabled={
                  ((adId !== undefined || state.id !== undefined) &&
                    !allowCreativesEdit) ||
                  (state.id && isModal && !allowCreativesEdit)
                }
                label={
                  <div
                    onClick={() => setShowHelpModal(true)}
                    style={{
                      color: "#73706E",
                      fontWeight: "700",
                      textTransform: "none",
                      marginRight: "auto",
                      cursor: "pointer",
                    }}
                    className="fs-standard"
                  >
                    Brand Store URL
                    <ToolTips
                      toolTip={"Click here for more information"}
                      position="top"
                      className="fs-standard"
                    />
                  </div>
                }
                placeholder={"Click URL"}
                defaultValue={state.walmartClickURL}
                // formControlClass={"text-left"}
              />
              <div className="text-start pt-3">
                {errors.walmartClickURL &&
                  (review || fieldsToValidate?.includes("walmartClickURL")) && (
                    <small
                      className="text-danger"
                      style={{
                        paddingLeft: ".8rem",
                        paddingBottom: "1rem",
                        display: "block",
                      }}
                    >
                      {errors.walmartClickURL}
                    </small>
                  )}
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row
        className={
          review
            ? "pt-2"
            : state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value
            ? "pt-0"
            : "pt-5"
        }
      >
        <Col
          xs={12}
          lg={8}
          className={`mt-4 d-flex flex-column ${
            assetURL ? "justify-content-center" : ""
          } ${isModal ? "align-items-center" : "align-items-start"}`}
        >
          {state.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value && (
            <>
              <p
                style={{
                  color: "#73706E",
                  fontWeight: "700",
                  textTransform: "none",
                  marginRight: "auto",
                }}
                className="fs-standard"
              >
                Brand Logo
              </p>
              {assetDimensions?.height !== 180 &&
                assetDimensions?.width !== 300 &&
                allowCreativesEdit &&
                assetURL && (
                  <div className="text-start w-100">
                    <small
                      className="text-danger mr-auto"
                      style={{
                        paddingLeft: ".8rem",
                        paddingBottom: "1rem",
                        display: "block",
                      }}
                    >
                      Warning: Walmart recommends brand logo images to be 300px
                      wide and 180px tall.
                    </small>
                  </div>
                )}
            </>
          )}
          {state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value &&
            !review && (
              <>
                <p
                  style={{
                    color: "#73706E",
                    fontWeight: "700",
                    textTransform: "none",
                    marginRight: "auto",
                    display: state.id && isModal ? "none" : "inherit",
                  }}
                  className="fs-standard"
                >
                  Brand Video
                </p>
              </>
            )}

          {errors.walmartImage &&
            (review || fieldsToValidate?.includes("walmartImage")) && (
              <div className="me-auto">
                <small
                  className="text-danger"
                  style={{
                    paddingLeft: ".8rem",
                    paddingBottom: "1rem",
                    display: "block",
                  }}
                >
                  {errors.walmartImage}
                </small>
              </div>
            )}
          {!fileUploadError && (assetURL || state.creativeAsset) ? (
            <>
              {state.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value && (
                <img
                  src={assetURL ?? state.creativeAsset}
                  ref={assetDimensionsRef}
                  className="mt-4"
                  style={{
                    height: "300px",
                  }}
                  alt={"A preview of the provided URL."}
                  onLoad={() => {
                    // Natural height and width measures uploaded files actual dimensions, not what's displayed in preview
                    setAssetDimensions({
                      height: assetDimensionsRef.current.naturalHeight,
                      width: assetDimensionsRef.current.naturalWidth,
                    });
                  }}
                />
              )}
            </>
          ) : (
            <p
              className="text-danger"
              style={{
                paddingLeft: ".8rem",
                paddingBotton: "1rem",
                display: "block",
                fontSize: "12px",
                marginRight: "auto",
              }}
            >
              {fileUploadError}
            </p>
          )}
        </Col>

        {allowCreativesEdit && (
          <Col
            xs={12}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              minHeight:
                state.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value
                  ? "400px"
                  : "none",
              marginBottom:
                state.id && state.policyViolations?.length > 0
                  ? "-25px"
                  : "inherit",
            }}
            className={
              state.adId && state.policyViolations?.length > 0 ? "mt-3" : ""
            }
          >
            {state.adType === AD_TYPES.SPONSORED_BRAND_WALMART.value && (
              <input
                accept={"image/png"}
                id={"input-new-asset-file"}
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    let file = e.target.files[0];
                    let type = file.type.split("/")[0];
                    let assetKey = e.target.files[0].name;
                    let imageSize = e.target.files[0]?.size;
                    let imageType = e.target.files[0]?.type;

                    // Validate file size
                    if (imageSize > 200000) {
                      setFileUploadError(
                        "Selected image too large. Please ensure the image is less than 200kb in size."
                      );
                      return;
                    } else {
                      setFileUploadError(null);
                    }

                    // Validate image type
                    if (imageType !== "image/png") {
                      setFileUploadError(
                        "Please upload only images with .png file types in accordance with Walmart guidelines."
                      );
                      return;
                    }
                    uploadAsset(assetKey, file, type, async (assetId) => {
                      if (assetId) {
                        onChange({ walmartAssetId: assetId });
                        await getAssetSrcUrl(assetId, (srcUrl) => {
                          if (srcUrl) {
                            setAssetURL(srcUrl);
                            onChange({
                              walmartImage: srcUrl,
                              walmartAssetId: assetId,
                            });
                          } else {
                            setFileUploadError(
                              "Error uploading image. Please try again."
                            );
                          }
                        });
                      }
                    });
                    setLoading(false);
                  }
                }}
              />
            )}
            {state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value && (
              <input
                accept={"video/*"}
                id={"input-new-asset-file"}
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    let file = e.target.files[0];
                    let type = file.type.split("/")[0];
                    let assetKey = e.target.files[0].name;
                    let videoSize = e.target.files[0]?.size;
                    let videoType = e.target.files[0]?.name?.split(".")?.[1];

                    // Validate file size - max 500mb
                    if (videoSize > 500000000) {
                      setFileUploadError(
                        "Selected video too large. Please ensure the video is less than 500MB in size."
                      );
                      return;
                    } else {
                      setFileUploadError(null);
                    }

                    // Validate video type
                    if (!["mp4", "mov"].includes(videoType)) {
                      setFileUploadError(
                        "Please upload only videos with .mp4 or .mov file types in accordance with Walmart guidelines."
                      );
                      return;
                    }

                    setLoading(true);

                    uploadAsset(assetKey, file, type, async (assetId) => {
                      if (assetId) {
                        onChange({ walmartAssetId: assetId });
                        await getAssetSrcUrl(assetId, (srcUrl) => {
                          if (srcUrl) {
                            setAssetURL(srcUrl);
                            onChange({
                              walmartVideo: srcUrl,
                              walmartAssetId: assetId,
                            });
                          } else {
                            setFileUploadError(
                              "Error uploading uploading video. Please try again."
                            );
                          }
                        });
                        setLoading(false);
                      }
                    });
                  }
                }}
              />
            )}
            {(!state.id ||
              (state.id &&
                state.advModerationStatus ===
                  MODERATION_STATUSES.FAILED.value)) && (
              <label htmlFor="htmlfor-input-new-asset-file">
                <LogoutButton
                  style={{ float: "left" }}
                  fontSize="14px"
                  disabled={loading}
                  title={
                    <span>
                      <i className="fa fa-file-video-o" /> Upload New Asset
                    </span>
                  }
                  onClick={() => {
                    let el = document.getElementById("input-new-asset-file");
                    el.click();
                  }}
                />
              </label>
            )}
          </Col>
        )}

        {(state.id || review) &&
          state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value && (
            <Row>
              <AssetSummary
                assetURL={assetURL}
                assetDescriptor={"Brand Video"}
                assetType={"VIDEO"}
                columnSpan={12}
              />
            </Row>
          )}
        {state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value &&
          !fileUploadError &&
          state.walmartVideo &&
          !review && (
            <div>
              <Col xs={12}>
                <video
                  ref={assetDimensionsRef}
                  className="mt-5 w-100"
                  controls
                  src={assetURL ?? state.creativeAsset}
                  alt={"A preview of the provided URL."}
                  onLoadedMetadata={(e) => {
                    // Natural height and width measures uploaded files actual dimensions, not what's displayed in preview
                    setAssetDimensions({
                      height: assetDimensionsRef.current.videoHeight,
                      width: assetDimensionsRef.current.videoWidth,
                    });
                    validateVideoMetadata();
                  }}
                />
              </Col>
              <Row className="mt-5" hidden={review}>
                <Col xs={12} md={8} className="d-flex align-items-end">
                  <p
                    style={{
                      color: "#73706E",
                      fontWeight: "700",
                      textTransform: "none",
                      marginRight: "auto",
                    }}
                    className="fs-standard"
                  >
                    Video Captions (optional)
                  </p>
                </Col>
                <Col xs={12} md={4} className="d-flex justify-content-end mt-5">
                  <label htmlFor="htmlfor-input-new-caption-file">
                    <LogoutButton
                      style={{ float: "left" }}
                      fontSize="14px"
                      disabled={loading}
                      title={
                        <span>
                          <i className="fa fa-file-video-o" /> Upload Captions
                        </span>
                      }
                      onClick={() => {
                        let el = document.getElementById(
                          "input-new-caption-file"
                        );
                        el.click();
                      }}
                    />
                  </label>
                </Col>
              </Row>
            </div>
          )}
        {/* Captions Review */}
        {state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value &&
          !fileUploadError &&
          (state.walmartCaptionAssetId || state.walmartCaptionURL) && (
            <Col xs={12} className="mt-4">
              <p
                style={{
                  color: "#73706E",
                  fontWeight: "700",
                  fontSize: "15px",
                  textTransform: "none",
                  marginRight: "auto",
                }}
                hidden={!review}
              >
                Video Captions
              </p>
              {(state.walmartCaptionAssetId || state.walmartCaptionURL) && (
                <Row>
                  <Col xs={12} md={6} className="mt-2 mb-4 pr-3">
                    <FormGroupTile
                      type="formcontrol"
                      defaultValue={state.walmartCaptionFileName}
                      disabled
                    />
                    <p
                      style={{
                        color: "#73706E",
                        textTransform: "none",
                        marginRight: "auto",
                      }}
                      className="fs-standard py-3 text-center"
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.walmartCaptionURL}
                        className="text-decoration-none"
                        style={{ color: trellisPalette[13] }}
                      >
                        Click here
                      </a>{" "}
                      to view uploaded captions .
                    </p>
                  </Col>
                </Row>
              )}
            </Col>
          )}
        {state.adType === AD_TYPES.SPONSORED_BRAND_VIDEO_WALMART.value && (
          <>
            <input
              accept={".vtt,.srt"}
              id={"input-new-caption-file"}
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  let file = e.target.files[0];
                  let type = file.type.split("/")[0];
                  let assetKey = e.target.files[0].name;

                  // Validate video type
                  if (
                    file?.name?.indexOf(".srt") === -1 &&
                    file?.name?.indexOf(".vtt") === -1
                  ) {
                    setFileUploadError(
                      "Please upload only caption files with .srt or .vtt types in accordance with Walmart guidelines."
                    );
                    return;
                  }

                  setLoading(true);

                  uploadAsset(assetKey, file, type, async (assetId) => {
                    if (assetId) {
                      onChange({ walmartCaptionAssetId: assetId });
                      await getAssetSrcUrl(assetId, (srcUrl) => {
                        if (srcUrl) {
                          setAssetURL(srcUrl);
                          onChange({
                            walmartCaptionFile: srcUrl,
                            walmartCaptionAssetId: assetId,
                            walmartCaptionFileName: file?.name ?? "",
                          });
                        } else {
                          setFileUploadError(
                            "Error uploading uploading video captions. Please try again."
                          );
                        }
                      });
                      setLoading(false);
                    }
                  });
                }
              }}
            />
          </>
        )}
        {loading && (
          <Row>
            <Col xs={12}>
              <h4 className="text-center fw-bold">
                Upload in progress. Please wait.
              </h4>
              <Loading height={"150px"} fullPage={false} />
            </Col>
          </Row>
        )}
      </Row>
      <HelpModal
        show={showHelpModal}
        setShow={setShowHelpModal}
        helpText={
          <>
            If you need help setting up your campaign headline, image, or click
            URL, please refer to{" "}
            <a
              href={
                "https://advertisinghelp.walmart.com/s/guides?channel=Search%20Brand%20Amplifier&article=000010511"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <CustomHref textDecoration="none" text={"this article"} />
            </a>
            , or follow the video below.
          </>
        }
        helpVideo={{
          src: "https://player.vimeo.com/video/764752885?h=c9ac49b20a",
          title:
            "Sponsored Brands Campaign Setup: Step 1 Creating a New Campaign",
        }}
      />
    </div>
  );
};

export default WalmartSBFields;
