import React from "react";
import ProductLaunchForm from "components/custom/merchandise/launch/ProductLaunchForm";
import withMediaQuery from "hocs/withMediaQuery";

const NewLaunch = ({ mediaQuery }) => {
  return (
    <>
      <div>
        <h2
          style={{
            fontSize: "26px",
            color: "#403E3D",
            fontWeight: "700",
            margin: "5rem auto 3rem",
            padding: "1rem auto",
            marginLeft: mediaQuery ? "2rem" : "390px",
          }}
        >
          New Product Launch
        </h2>

        <ProductLaunchForm />
      </div>
    </>
  );
};

export default withMediaQuery("(max-width:600px)")(NewLaunch);
