import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { useDates } from "dates/useDates";
import { useFetch } from "hooks/api";
import useSelectedMetrics from "components/custom/merchandise/hooks/useSelectedMetrics";
import MultiMetricChart from "components/custom/merchandise/MultiMetricChart";
import Loading from "components/core/blocks/Loading";
import { buildMetric } from "views/merchandise/metrics";
import DetailedMetric from "components/core/blocks/DetailedMetric";
import useMerchandisePreferences from "hooks/useMerchandisePreferences";
import acosOrRoas from "utils/acosOrRoas";
import moment from "moment";
import { getAdTypeName } from "utils/getAdTypeName";
import { useSelector } from "react-redux";

const AdvertisementExpander = ({
  row,
  channel,
  overrideFilters = {},
  highlightDate = null,
}) => {
  const { marketPlace } = useSelector((state) => state);
  const { preferences } = useMerchandisePreferences();
  const acosroas = (acos, roas) => acosOrRoas(preferences, acos, roas);

  let { name, ad_id: adId, ad_type: adType } = row._original ?? {};

  const { group, period, compare, date, start, end, preStart, preEnd } =
    useDates();

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    ad: adId,
    channel: channel,
    ...overrideFilters,
  };

  const { data, isLoading } = useFetch(
    ["ad_report", start, end, preStart, preEnd, group, adId],
    `/api/data_report/ad_report`,
    filters,
    {
      select: (d) => d.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!adId,
    }
  );

  const { selectedMetrics, toggleMetric } = useSelectedMetrics(
    ["ad_sales", "ad_spend", "ctr"],
    "advertisementExpander"
  );

  const metrics = useMemo(
    () => [
      buildMetric(marketPlace, data, "ad_sales", "ad_sales_percent"),
      buildMetric(marketPlace, data, "ad_spend", "ad_spend_percent"),
      acosroas(
        buildMetric(marketPlace, data, "acos", "acos_delta"),
        buildMetric(marketPlace, data, "roas", "roas_delta")
      ),
      buildMetric(marketPlace, data, "clicks", "clicks_percent"),
      buildMetric(marketPlace, data, "impressions", "impressions_percent"),
      buildMetric(marketPlace, data, "ctr", "ctr_delta"),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marketPlace, data]
  );

  const handleLegendChange = (e) => {
    const id = e.target?.options?.custom?.id;
    if (id) toggleMetric(id);
    return false;
  };

  const dateFilterWithoutToday =
    data?.date?.filter((d) => moment().format("YYYY-MM-DD") !== d.start_date) ??
    [];

  if (isLoading) {
    return <Loading height={"300px"} fullPage={false} />;
  }

  return (
    <div className="bg-white">
      <Row className="pt-3">
        <Col xs={12} md={6}>
          <h3
            className="pb-4 px-5"
            style={{
              fontSize: 22,
            }}
          >
            {name} - {getAdTypeName(adType, channel)}
          </h3>
        </Col>
        <Col xs={12}>
          <MultiMetricChart
            metrics={metrics}
            selectedMetrics={selectedMetrics}
            data={dateFilterWithoutToday}
            group={group}
            handleLegendChange={handleLegendChange}
            datesFormatted={true}
            showAnnotations={false}
            highlightDate={highlightDate}
          />
        </Col>
        <Col xs={12}>
          <Row className="pt-4">
            {metrics?.slice(0, 6)?.map((m, i) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  lg={2}
                  key={m.id}
                  className={`mb-4 ${i % 2 === 0 ? "pl-2" : "pr-2"}`}
                >
                  <div className="">
                    <div>
                      <DetailedMetric
                        center
                        size={"small"}
                        id={m.id}
                        toolTip={m.toolTip}
                        name={m.name}
                        value={m.overrideValue ?? m.actual}
                        target={m.target}
                        formatter={m.formatter}
                        targetFormatter={m?.targetFormatter}
                        showTarget={false}
                        targetLabel={m?.targetLabel}
                        targetColor={
                          m?.targetColor ??
                          (m.target > 0 ? "#007000" : "#d2222d")
                        }
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AdvertisementExpander;
