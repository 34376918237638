import React, { useState } from "react";
import { Row, Col, NavDropdown, InputGroup, Form } from "react-bootstrap";
import withMediaQuery from "hocs/withMediaQuery";
import { COMPARES, GROUPS } from "dates/constants";
import { useDates } from "dates/useDates";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { ButtonGroup, Button } from "@material-ui/core";
import TrellisLink from "components/core/basic/TrellisLink";
import ThemeLine from "components/custom/merchandise/ThemeLine";
import useDashboardTheme, { SIDEBAR_COLORS } from "hooks/useDashboardTheme";
import placementIcon from "assets/images/icons/placement-w.png";
import pricingIcon from "assets/images/icons/pricing-w.png";
import productIcon from "assets/images/icons/product-w.png";
import promoIcon from "assets/images/icons/promotion-w.png";
import reportsIcon from "assets/images/icons/marketplace_intelligence.svg";

const Icon = ({ section }) => {
  let color = "#523673";
  let iconURL = placementIcon;

  if (section === "placement") {
    color = SIDEBAR_COLORS[section];
    iconURL = placementIcon;
  }
  if (section === "pricing") {
    color = SIDEBAR_COLORS[section];
    iconURL = pricingIcon;
  }

  if (section === "promotions") {
    color = SIDEBAR_COLORS[section];
    iconURL = promoIcon;
  }

  if (section === "product") {
    color = SIDEBAR_COLORS[section];
    iconURL = productIcon;
  }

  if (section === "reports") {
    iconURL = reportsIcon;
  }

  return (
    <div
      style={{
        width: "30px",
        height: "30px",
        backgroundColor: color,
        borderRadius: "100%",
      }}
      className="d-flex align-items-center justify-content-center me-3"
    >
      {iconURL && (
        <img
          src={iconURL}
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "100%",
            // Changes svg color
            color: "#FFF",
          }}
          alt={`Trellis ${section} icon`}
        />
      )}
    </div>
  );
};

const BreadcrumbDates = ({
  items,
  hideCompare = false,
  hideRange = false,
  children,
  groupByOnly,
  hideDateSelector,
  hideGroupBy = false,
}) => {
  const {
    period,
    compare,
    group,
    end,
    start,
    setDate,
    setGroup,
    preStart,
    preEnd,
  } = useDates();
  const selectedGroup = GROUPS.find((g) => g.id === group);
  const [search, setSearch] = useState("");

  const { breadcrumbColor, section } = useDashboardTheme();

  const MAX_BREADCRUMB_DROPDOWN_ITEMS = 200;

  return (
    <Row>
      <Col
        style={{
          borderBottom: "1px solid #dcdadd",
          backgroundColor: breadcrumbColor ?? "#f5f3f6",
          paddingBottom: "4px",
          paddingLeft: "40px",
        }}
      >
        <Row>
          <Col xs={12} lg={hideDateSelector ? 12 : 8}>
            <ol
              role="navigation"
              aria-label="breadcrumbs"
              className="breadcrumb d-flex align-items-center"
              style={{
                marginBottom: "0px",
                fontSize: "14px",
                margin: "revert",
              }}
            >
              <Icon section={section} />
              {items?.map((item, i) => {
                return (
                  <li key={i} className="breadcrumb-item pl-2 fw-normal">
                    {item.dropdownLinkOptions ? (
                      <NavDropdown
                        key={i}
                        id="DateBreadcrumbComparison"
                        renderMenuOnMount={true}
                        // TODO: Maybe below is better left out so search is persistent - get feedback of preference
                        onMouseLeave={() => setSearch("")}
                        className="fs-standard noarrow dropdown mx-0"
                        title={
                          <div
                            style={{ whiteSpace: "normal", lineHeight: "12px" }}
                          >
                            <TrellisLink
                              className="text-decoration-none "
                              to={item?.href ?? "#"}
                            >
                              {item.name}
                            </TrellisLink>
                          </div>
                        }
                      >
                        {item.searchable && (
                          <div className="px-3 py-2">
                            <InputGroup className="mb-3">
                              <InputGroup.Text
                                style={{
                                  borderTopLeftRadius: "45px",
                                  borderBottomLeftRadius: "45px",
                                }}
                              >
                                <i className="fa fa-search" />
                              </InputGroup.Text>
                              <Form.Control
                                placeholder={
                                  item?.searchPlaceholderText
                                    ? item.searchPlaceholderText
                                    : "Search..."
                                }
                                aria-label="Search Term"
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                                value={search}
                              />
                            </InputGroup>
                          </div>
                        )}
                        {item.dropdownSubtitle && (
                          <h3
                            className="m-0 p-2 px-3 fs-3"
                            style={{
                              color: "#2e0054",
                              fontWeight: 600,
                            }}
                          >
                            {item.dropdownSubtitle}
                          </h3>
                        )}
                        <div
                          style={{
                            width: "300px",
                            maxHeight: "500px",
                            overflowY: "auto",
                          }}
                        >
                          {item.dropdownLinkOptions
                            ?.filter((nestedLink) => {
                              if (search) {
                                return (
                                  `${nestedLink.name} ${nestedLink.href}`
                                    .toLowerCase()
                                    ?.indexOf(search.toLowerCase()) > -1
                                );
                              }

                              return true;
                            })
                            ?.sort((a, b) => {
                              if (item?.unsorted) {
                                return 0;
                              }

                              return a.name?.toLowerCase() <
                                b.name?.toLowerCase()
                                ? -1
                                : a.name?.toLowerCase() > b.name?.toLowerCase()
                                ? 1
                                : 0;
                            })
                            ?.splice(0, MAX_BREADCRUMB_DROPDOWN_ITEMS)
                            ?.map((nestedLink) => (
                              <div
                                className="d-flex flex-column py-2"
                                key={`${nestedLink.name}-${
                                  nestedLink?.href ?? "#"
                                }`}
                              >
                                <TrellisLink
                                  className={`text-decoration-none px-3`}
                                  to={nestedLink?.href ?? "#"}
                                  style={{
                                    fontWeight:
                                      nestedLink.name === item.name
                                        ? 600
                                        : "inherit",
                                  }}
                                >
                                  {nestedLink.altDisplayMarkup
                                    ? nestedLink.altDisplayMarkup
                                    : nestedLink.name}
                                  {nestedLink?.subTitle ? (
                                    <ThemeLine
                                      additionalStyles={{ fontSize: "10px" }}
                                    >
                                      {nestedLink?.subTitle}
                                    </ThemeLine>
                                  ) : (
                                    ""
                                  )}
                                </TrellisLink>
                              </div>
                            ))}
                        </div>
                      </NavDropdown>
                    ) : (
                      <>
                        <TrellisLink
                          className="text-decoration-none "
                          to={item.href}
                        >
                          {item.name}
                        </TrellisLink>
                      </>
                    )}
                  </li>
                );
              })}
            </ol>
          </Col>
          {!hideDateSelector ? (
            <Col
              xs={12}
              lg={4}
              className="d-flex align-items-center justify-content-end"
            >
              <div
                className="nav"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "3rem",
                  height: "36px",
                  alignItems: "center",
                }}
              >
                <NavDropdown
                  id="DateBreadcrumbComparison"
                  title={
                    <div style={{ whiteSpace: "normal", lineHeight: "12px" }}>
                      <strong>
                        {moment(start).format("MMM D 'YY")} -{" "}
                        {moment(end).format("MMM D 'YY")}
                      </strong>
                      <br />
                      <small>
                        by {GROUPS.find((g) => group === g.id)?.name}{" "}
                        {hideCompare
                          ? null
                          : `vs. ${
                              COMPARES.find((c) => compare === c.id)?.name
                            }`}
                      </small>
                    </div>
                  }
                  className="fs-standard noarrow date-breadcrumb-dropdown"
                >
                  <div style={{ width: "380px" }}>
                    <Row className="m-2">
                      {!hideGroupBy && (
                        <Col
                          xs={12}
                          className={
                            groupByOnly
                              ? " d-flex justify-content-between align-items-center"
                              : "mb-4 d-flex justify-content-between"
                          }
                        >
                          <div>Group by:</div>
                          <ButtonGroup>
                            {GROUPS.map((g) => {
                              return (
                                <Button
                                  style={{ fontSize: "1.2rem" }}
                                  key={g.id}
                                  variant={group === g.id ? "contained" : null}
                                  color={group === g.id ? "secondary" : null}
                                  onClick={() => {
                                    setGroup(g.id, start, end, compare);
                                  }}
                                >
                                  {g.name}
                                </Button>
                              );
                            })}
                          </ButtonGroup>
                        </Col>
                      )}
                      {!groupByOnly && (
                        <>
                          <Col xs={12} className="mb-4">
                            {!hideRange && (
                              <Row>
                                <Col xs={12}>Range:</Col>
                                <Col xs={12} className="text-center">
                                  <ButtonGroup>
                                    {selectedGroup.periods.map((p) => (
                                      <Button
                                        style={{ fontSize: "1.2rem" }}
                                        key={p.id}
                                        variant={
                                          period === p.id ? "contained" : null
                                        }
                                        color={
                                          period === p.id ? "secondary" : null
                                        }
                                        onClick={() =>
                                          setDate(
                                            start,
                                            end,
                                            group,
                                            p.id,
                                            compare
                                          )
                                        }
                                      >
                                        {p.name}
                                      </Button>
                                    ))}
                                  </ButtonGroup>
                                </Col>
                              </Row>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <Row>
                              <Col xs={12}>Start:</Col>
                              <Col xs={12}>
                                <ReactDatePicker
                                  disabled={period !== "custom"}
                                  selected={start.toDate()}
                                  onChange={(d) =>
                                    setDate(
                                      moment(d),
                                      end,
                                      group,
                                      period,
                                      compare
                                    )
                                  }
                                  maxDate={moment().subtract(1, "day").toDate()}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} className="mb-4">
                            <Row>
                              <Col xs={12}>End:</Col>
                              <Col xs={12}>
                                <ReactDatePicker
                                  disabled={period !== "custom"}
                                  selected={end.toDate()}
                                  onChange={(d) =>
                                    setDate(
                                      start,
                                      moment(d),
                                      group,
                                      period,
                                      compare
                                    )
                                  }
                                  maxDate={moment().toDate()}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}
                      {!hideCompare && (
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              className="d-flex justify-content-between"
                            >
                              <div>Compare Against:</div>
                              <ButtonGroup>
                                {COMPARES.map((c) => (
                                  <Button
                                    style={{ fontSize: "1.2rem" }}
                                    key={c.id}
                                    variant={
                                      compare === c.id ? "contained" : null
                                    }
                                    color={
                                      compare === c.id ? "secondary" : null
                                    }
                                    onClick={() =>
                                      setDate(start, end, group, period, c.id)
                                    }
                                  >
                                    {c.name}
                                  </Button>
                                ))}
                              </ButtonGroup>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col xs={12} style={{ textAlign: "right" }}>
                              {moment(preStart).format("MMM D 'YY")} -{" "}
                              {moment(preEnd).format("MMM D 'YY")}
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </div>
                </NavDropdown>
                {children}
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default withMediaQuery("(max-width:992px)")(BreadcrumbDates);
