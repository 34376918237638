import React, { useState, useRef, useMemo } from "react";
import { useFetch } from "hooks/api";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/core/blocks/Loading";
import { Row, Col, Container as Grid, Badge } from "react-bootstrap";

import Accordion from "components/core/basic/Accordion";

import { selectModules } from "redux/selectors/modules";
import checkModule from "utils/checkModule";
import checkTierAccess from "utils/checkTierAccess";
import SEOChart from "./components/SEOScoreChart";
import BuyabilityChart from "./components/BuyabilityScoreChart";

import NoImage from "assets/images/utility/no_image.png";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getURLPrefix } from "utils/getUrlPrefix";
import { TooltipBuyability, TooltipSearchability } from "./components/Tooltips";
import PaymentDiscovery from "components/custom/onboarding/subscription/PaymentDiscovery";
import BreadcrumbDates from "components/core/blocks/BreadcrumbDates";
import DatesProvider from "dates/DatesProvider";
import { useDates } from "dates/useDates";
import useCategories from "hooks/useCategories";
import { buildMetric } from "views/merchandise/metrics";
import MetricsCard from "components/custom/merchandise/MetricsCard";

import {
  INVENTORY_STATES,
  SALE_STATES,
} from "components/core/blocks/AdPlan/constants";
import useLifecycleFilter from "hooks/useLifecycleFilter";
import { useMutation } from "react-query";
import api from "utils/api";
import TemplatesSection from "./components/TemplatesSection";
import BulkActionsTable from "views/merchandise/tables/BulkActionsTable";
import CategoriesTable from "components/core/blocks/CategoriesTable";
import TrellisLink from "components/core/basic/TrellisLink";
import {
  AGENCY_DASHBOARD_BREADCRUMB_SPEC,
  DASHBOARD_LINKS,
} from "utils/dashboardLinks";
import { toTitleCase } from "utils/formatText";
import useCustomTableFilters from "hooks/useCustomTableFilters";
import {
  INVENTORY_STATE_FILTER,
  SALES_STATE_FILTER,
  buildCustomTagsFilter,
  buildProductVariantsFilter,
} from "views/advertising/productLifecycleFilters";

const URL_PREFIX = getURLPrefix();

const getDashboardTo = () => {
  return "/user/content/quality/org";
};

const OrgQuality = ({
  marketPlace,
  channel,
  modules,
  user,
  amz_onboard,
  location,
  category,
}) => {
  const history = useHistory();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [onboardingStep, setOnboardingStep] = useState(
    location?.state?.firstVisit ? 1 : null
  );
  const TABLE_ID = "orgQualityTable";
  const tableRef = useRef();
  const { group, start, end, preStart, preEnd } = useDates();

  useMemo(() => {
    if (location?.state?.firstVisit && onboardingStep < 1) {
      setOnboardingStep(1);
    }
  }, [location.state, onboardingStep]);

  const createTemplate = useMutation(
    async (productIds) => {
      const ch = channel.currentChannel;
      return await api.post("/merchandise/content_templates/", {
        product_ids: productIds,
        market_place: marketPlace.marketPlace,
        channel: ch === "amazon" ? "AMZ" : ch,
      });
    },
    {
      onSuccess: (res) => {
        if (res.status === 201) {
          history.push(`/user/content/templates/${res.data.id}`);
        }
      },
    }
  );

  const {
    isLoading: qualityDataLoading,
    data: orgData,
    isFetching,
  } = useFetch(
    [
      "productQualityOrgs",
      start,
      end,
      group,
      preStart,
      preEnd,
      channel,
      category,
    ],
    `/merchandise/content_report/`,
    {
      channel: "AMZ",
      market_place: marketPlace.marketPlace,
      group_by: group,
      start: start.format("YYYY-MM-DD"),
      end: end.format("YYYY-MM-DD"),
      pre_end: preEnd.format("YYYY-MM-DD"),
      pre_start: preStart.format("YYYY-MM-DD"),
      category: category,
    },
    { select: (d) => d.data }
  );

  const filters = {
    mp: marketPlace.marketPlace,
    date_group: group,
    start_date: start.format("YYYY-MM-DD"),
    end_date: end.format("YYYY-MM-DD"),
    pre_start_date: preStart.format("YYYY-MM-DD"),
    pre_end_date: preEnd.format("YYYY-MM-DD"),
    empty: true,
    group_by: "category_id",
  };
  if (category) {
    filters["category"] = category;
  }

  const { data: categoryData, isLoading: categoryDataLoading } = useFetch(
    [
      "category_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      group,
      category,
    ],
    `/api/data_report/category_report`,
    { ...filters, group_by: "category_id", empty: !category },
    {
      select: (d) => d.data,
      keepPreviousData: true,
    }
  );

  const { data: productData, isLoading: productDataLoading } = useFetch(
    [
      "product_report",
      marketPlace.marketPlace,
      start,
      end,
      preStart,
      preEnd,
      group,
      category,
    ],
    `/api/data_report/product_report`,
    { ...filters, group_by: "product_id", category },
    {
      select: (d) => d.data,
      keepPreviousData: true,
      enabled: !!category,
    }
  );

  let { currentCategoryDetails: categoryDetails, categories: categoryNavData } =
    useCategories(category, "content");

  const isLoading = useMemo(
    () => qualityDataLoading && (categoryDataLoading || productDataLoading),
    [qualityDataLoading, categoryDataLoading, productDataLoading]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const categories = categoryData?.categories ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const products = productData?.products ?? [];
  const searchFilteredData = useMemo(() => {
    const data = (category ? products : categories) ?? [];
    return data
      .filter((row) => {
        if (searchFilter) {
          const key = category
            ? `${row.product_title} ${row.asin} ${row.sku}`
            : row.category_name;
          if (key.toLowerCase().includes(searchFilter.toLowerCase())) {
            return true;
          }
          return false;
        }
        return true;
      })
      .sort((a, b) => b.seo_score_avg - a.seo_score_avg);
  }, [category, products, categories, searchFilter]);

  const { filteredData: tableData } = useLifecycleFilter(searchFilteredData);

  const buckets = useMemo(() => {
    const buckets = [];
    if (orgData?.total) {
      for (let i = 0; i < 4; i++) {
        buckets.push({
          seo_count: orgData.total?.[`seo_count_${i}_avg`],
          buyability_count: orgData.total?.[`buyability_count_${i}_avg`],
          cr: orgData.total?.[`cr_${i}_avg`],
          ctr: orgData.total?.[`ctr_${i}_avg`],
        });
      }
    }
    return buckets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgData]);

  const metrics = useMemo(() => {
    return [
      buildMetric(
        marketPlace.marketPlace,
        categoryData,
        "sales",
        "sales_percent"
      ),
      buildMetric(
        marketPlace.marketPlace,
        categoryData,
        "units",
        "units_percent"
      ),
      buildMetric(
        marketPlace.marketPlace,
        categoryData,
        "seo_score",
        "seo_score_percent"
      ),
      buildMetric(marketPlace.marketPlace, categoryData, "ctr", "ctr_delta"),
      buildMetric(
        marketPlace.marketPlace,
        categoryData,
        "buyability_score",
        "buyability_score_percent"
      ),
      buildMetric(
        marketPlace.marketPlace,
        categoryData,
        "conversion_rate",
        "conversion_rate_delta"
      ),
      //buildMetric(
      //  marketPlace.marketPlace,
      //  categoryData,
      //  "page_views",
      //  "page_views_percent"
      //),
    ];
  }, [marketPlace, categoryData]);

  const titleColumn = category
    ? {
        id: "product",
        Header: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div style={{ position: "relative" }}>Product</div>
          </div>
        ),
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center", border: "none" },
        accessor: "product_title",
        Filter: () => {},
        filterMethod: () => {},
        Cell: (props) => {
          const [hasError, setHasError] = useState(false);
          let {
            product_id,
            product_title,
            asin,
            sku,
            sales_state: salesState,
            stock_status: inventoryState,
            inventory_state,
            tags,
          } = props.original;
          if (inventoryState === "IN_STOCK") {
            inventoryState = props.original?.stock_status;
          }

          if (inventoryState === "IN_STOCK_SCARCE") {
            inventoryState = "LOW_STOCK";
          }
          if (inventory_state === "OVERSTOCK") {
            inventoryState = "OVERSTOCK";
          }
          const image = hasError
            ? NoImage
            : `${URL_PREFIX}/api/productimage/?product=${product_id}`;
          return (
            <TrellisLink
              to={`/user/content/seo?id=${product_id}`}
              style={{
                textDecoration: "none",
                fontWeight: "500",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontWeight: "500",
                  fontSize: "1.5rem",
                }}
              >
                <div
                  onError={(e) => {
                    setHasError(true);
                  }}
                  style={{
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    flexShrink: 0,
                    width: 50,
                    height: 50,
                    marginRight: "10px",
                    background: `url(${image}) no-repeat center / contain`,
                  }}
                  draggable="false"
                />
                <div
                  className={"fs-standard text-underline-hover"}
                  style={{ textAlign: "left" }}
                >
                  <strong className={"text-underline-hover"}>
                    {product_title}
                  </strong>
                  <br />
                  <i
                    className="pr-3"
                    style={{
                      fontWeight: "light",
                      color: "#666",
                      fontSize: "1rem",
                    }}
                  >
                    {asin} ({sku})
                  </i>
                  {inventoryState !== "IN_STOCK" && (
                    <Badge
                      className="badge bg-trellis-purple"
                      pill
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      {INVENTORY_STATES[inventoryState]?.label}
                    </Badge>
                  )}
                  {salesState !== "NORMAL" && (
                    <Badge
                      className="badge bg-trellis-fuchsia"
                      pill
                      style={{
                        borderRadius: "5px",
                        marginLeft: "1rem",
                      }}
                    >
                      {SALE_STATES[salesState]?.label}
                    </Badge>
                  )}
                  {tags?.map((tag, index) => (
                    <Badge
                      bsClass="badge"
                      className="bg-trellis-mauve"
                      style={{
                        marginLeft: "1rem",
                        cursor: "pointer",
                      }}
                      pill
                      key={index}
                    >
                      {toTitleCase(tag)}
                    </Badge>
                  ))}
                </div>
              </div>
            </TrellisLink>
          );
        },
        width: 400,
      }
    : {
        id: "category",
        Header: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div style={{ position: "relative" }}>
              {category ? "Product" : "Category"}
            </div>
          </div>
        ),
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center", border: "none" },
        accessor: "category_name",
        Filter: () => {},
        filterMethod: () => {},
        Cell: (props) => {
          const [hasError, setHasError] = useState(false);
          const { category_id } = props.original;
          const image = hasError
            ? NoImage
            : `${URL_PREFIX}/api/productimage/?category=${category_id}`;

          return (
            <TrellisLink
              to={`/user/content/quality/category/${category_id}`}
              style={{
                textDecoration: "none",
                fontWeight: "500",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  onError={(e) => {
                    setHasError(true);
                  }}
                  style={{
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    flexShrink: 0,
                    width: 50,
                    height: 50,
                    marginRight: "10px",
                    background: `url(${image}) no-repeat center / contain`,
                  }}
                  draggable="false"
                />
                <div className={"fs-standard text-underline-hover"}>
                  <strong className={"text-underline-hover"}>
                    {props?.value}
                  </strong>
                </div>
              </div>
            </TrellisLink>
          );
        },
        width: 400,
      };

  const columns = [
    {
      key: "sales",
      compareKey: "sales_percent",
    },
    {
      key: "units",
      compareKey: "units_percent",
    },
    {
      key: "seo_score",
      compareKey: "seo_score_percent",
    },
    {
      key: "buyability_score",
      compareKey: "buyability_score_percent",
    },
    {
      key: "ctr",
      compareKey: "ctr_delta",
    },
    {
      key: "conversion_rate",
      compareKey: "conversion_rate_delta",
    },
  ];

  const variants = useMemo(() => {
    const variants = {};

    if (!productData?.products) {
      return {};
    }
    productData.products.forEach((product) => {
      let variant = product?.variations;
      variant = variant ? variant : {};
      Object.entries(variant || {}).forEach(([key, value]) => {
        if (!variants?.[key]) {
          variants[key] = new Set();
        }
        variants[key].add(value);
      });
    });
    Object.entries(variants).forEach(([key, value]) => {
      variants[key] = Array.from(value).sort();
    });
    return variants;
  }, [productData]);

  const customTags = useMemo(() => {
    return productData?.products?.map((product) => product.tags)?.flat() ?? [];
  }, [productData]);

  const ADDITIONAL_FILTERS = [
    INVENTORY_STATE_FILTER,
    SALES_STATE_FILTER,
    buildProductVariantsFilter(variants),
    buildCustomTagsFilter(customTags),
  ];

  const filterProps = useCustomTableFilters(marketPlace.marketPlace, TABLE_ID);

  const CONTENT_SUBSCRIPTION =
    ((((user.content_tier && user.content_tier !== "unknown") ||
      location?.state?.firstVisit ||
      location?.state?.onboardingComplete) &&
      (amz_onboard.org_status === "active" ||
        amz_onboard.org_status === "trial_active" ||
        amz_onboard.org_status === "demo_org")) ||
      checkModule(modules, "PRODUCT_SEO") ||
      checkTierAccess(user.tier, "content", amz_onboard.org_status)) &&
    user.content_tier !== "MANUALLY_DISABLED";

  if (!CONTENT_SUBSCRIPTION) {
    return (
      <PaymentDiscovery discoveryType={"advertising"} dashboardType="content" />
    );
  }

  if (isLoading) {
    return <Loading text={"Loading Content Dashboard"} />;
  }

  if (!CONTENT_SUBSCRIPTION) {
    return (
      <div className="main-content" style={{ backgroundColor: "#FFF" }}>
        <Grid fluid>You need to buy this thing to use it.</Grid>
      </div>
    );
  }

  const breadCrumbItems = [
    {
      name: "Content Dashboard",
      href: getDashboardTo(),
      dropdownLinkOptions: DASHBOARD_LINKS,
      unsorted: true,
      searchable: false,
      dropdownSubtitle: "Dashboards",
    },
  ];

  if (category) {
    breadCrumbItems.push({
      name: categoryDetails?.leaf_category_name,
      href: categoryDetails.href,
      dropdownLinkOptions: categoryNavData,
      searchable: true,
      searchPlaceholderText: "Search categories...",
      dropdownSubtitle: "Categories",
    });
  }

  if (checkModule(modules, "AGENCY_DASHBOARD")) {
    breadCrumbItems.unshift(AGENCY_DASHBOARD_BREADCRUMB_SPEC);
  }

  const bulkActionOptions = [
    {
      label: "Create Template",
      action: () => {
        createTemplate.mutate(selectedProducts);
      },
      disabled: createTemplate.isLoading,
    },
  ];

  return (
    <>
      <Grid
        fluid
        style={{
          minHeight: "calc(100vh - 150px)",
          background: "#fefefe",
        }}
      >
        <Row>
          <Col xs={12} className="mb-4">
            <BreadcrumbDates items={breadCrumbItems} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-4">
            <MetricsCard
              title={"Content"}
              byline={
                <>
                  {start.format("MMMM Do YYYY")}
                  {" - "}
                  {end.format("MMMM Do YYYY")}
                  {isFetching && <i className="fa fa-spinner fa-spin"></i>}
                </>
              }
              metrics={metrics}
              data={categoryData?.date}
              initialSelectedMetric={["sales", "seo_score", "buyability_score"]}
              loading={isFetching}
              group={group}
              showAnnotations={true}
              showMetricsSelector
              id={"contentDashboard"}
            >
              <Row>
                <Col xs={12} md={6}>
                  <div className="text-center">
                    <h4>
                      Searchability <TooltipSearchability />
                    </h4>
                  </div>
                  <SEOChart seoData={buckets} />
                </Col>
                <Col xs={12} md={6}>
                  <div className="text-center">
                    <h4>
                      Buyability <TooltipBuyability />{" "}
                    </h4>
                  </div>
                  <BuyabilityChart buyabilityData={buckets} />
                </Col>
              </Row>

              <Row className="pt-5 fs-standard">
                <Col xs={12} className="text-end">
                  <Accordion
                    title={category ? "Products" : "Categories"}
                    initialExpanded
                  >
                    {category ? (
                      <BulkActionsTable
                        tableId={TABLE_ID}
                        tableRef={tableRef}
                        titleCol={titleColumn}
                        columnSpecs={columns}
                        tableEntityType={category ? "product" : "category"}
                        data={tableData}
                        selectionKey="product_id"
                        selected={selectedProducts}
                        setSelected={setSelectedProducts}
                        isExpandable={false}
                        isLoading={isLoading}
                        bulkActionOptions={bulkActionOptions}
                        getSearchCriteria={(row) => {
                          const { product_title, sku, asin, tags } = row;
                          return `${product_title} ${sku} ${asin} ${tags?.join(
                            " "
                          )}`;
                        }}
                        tableRowDescription={"Products"}
                        filterWidget={true}
                        {...filterProps}
                        additionalFilterOptions={ADDITIONAL_FILTERS}
                        excludedFilterColumns={["serving_status"]}
                        hideFilterToggleIcon={true}
                        hideResetFiltersIcon={false}
                      />
                    ) : (
                      <CategoriesTable
                        tableRef={tableRef}
                        tableData={tableData}
                        marketPlace={marketPlace}
                        setSearchFilter={setSearchFilter}
                        searchFilter={searchFilter}
                      />
                    )}
                  </Accordion>
                </Col>
              </Row>
              {!category && (
                <Row className="pt-5 fs-standard">
                  <Col xs={12} className="text-end">
                    <TemplatesSection marketPlace={marketPlace} />
                  </Col>
                </Row>
              )}
            </MetricsCard>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

const OrgQualityDashboard = (props) => {
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const { category = null } = useParams();

  return (
    <DatesProvider
      period={urlParams.get("period")}
      group={urlParams.get("group")}
      compare={urlParams.get("compare")}
      start={urlParams.get("start")}
      end={urlParams.get("end")}
    >
      <OrgQuality {...props} category={category} />
    </DatesProvider>
  );
};

OrgQuality.propTypes = {
  marketPlace: PropTypes.shape({
    marketPlace: PropTypes.string,
  }),
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
  amz_onboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  marketPlace: state.marketPlace,
  channel: state.channel,
  modules: selectModules(state),
  user: state.user,
  amz_onboard: state.amz_onboard,
});

export default connect(mapStateToProps)(OrgQualityDashboard);
