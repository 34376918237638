import React from "react";
import { snakeCaseToSentenceCase } from "utils/formatText";
import { OPERATOR_OPTIONS } from "./constants";
import { formatCurrency, formatPercent } from "utils/formatNumber";
import { listFormatter } from "utils/listFormatter";

const pluralBlacklist = ["acos", "roas"];

export const summarizeProgram = (
  scope,
  conditions,
  logicalOperator,
  actions
) => {
  console.log({ scope, conditions, logicalOperator, actions });
  let summary = "When ";

  let matchTypeConditions = [];

  for (let i = 0; i < conditions.length; i++) {
    let condition = conditions[i];
    let { option, values, scope: conditionScope = null } = condition;
    let [operator, metric] = JSON.parse(option);
    let value = condition.values[0];

    metric = metric.replace(/(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9]+$)/g, "");

    if (["keyword_match_types", "target_match_types"].includes(metric)) {
      matchTypeConditions.push(condition);
      continue;
    }

    let conditionScopeText = '';
    if(conditionScope) {
      const days = conditionScope?.values?.days;
      if(days) {
        const dayText = parseInt(days) > 1 ? 'days' : 'day'
        conditionScopeText = `over ${days} ${dayText} `;
      }
    }

    let linkingVerb = "is";
    if (
      metric[metric.length - 1] === "s" &&
      !pluralBlacklist.includes(metric)
    ) {
      linkingVerb = "are";
    }

    operator = OPERATOR_OPTIONS["number"]?.find(
      (o) => o.value === operator
    )?.label;

    const logicalOperatorDescription = logicalOperator === "any" ? "or" : "and";

    if (operator === "between") {
      summary += `${metric} ${conditionScopeText}${linkingVerb} ${operator} ${values[0]} and ${
        values[1]
      }${
        i === conditions.length - 1
          ? ""
          : i === conditions.length - 2
          ? ` ${logicalOperatorDescription} `
          : ", "
      } `;
    } else {
      summary += `${metric} ${conditionScopeText}${linkingVerb} ${operator} ${value}${
        i === conditions.length - 2 ? ` ${logicalOperatorDescription} ` : ", "
      } `;
    }
  }

  for (let i = 0; i < matchTypeConditions.length; i++) {
    let condition = matchTypeConditions[i];
    let { option } = condition;
    let [operator, metric] = JSON.parse(option);
    let matchTypes = condition[metric]?.flat();
    metric = metric.replace(/(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9]+$)/g, "");
    if(matchTypes?.filter(m => !!m).length === 0) {
      continue;
    }

    if (i === 0) {
      summary = summary.replace("then", "");
    }

    // Remove "and" before describing match type conditions
    summary = summary.slice(0, summary.lastIndexOf("and"));

    summary += ` for ${metric?.split("_")?.[0] ?? "N/A"}s with ${listFormatter.format(
      matchTypes
    )} match types${i < matchTypeConditions.length - 1 ? " or " : " "}`;
  }

  for (let i = 0; i < actions.length; i++) {
    let action = actions[i];
    let { function: actionToPerform, values, operator } = action;
    let changeType = "by";

    if (actionToPerform.toLowerCase().includes("set")) {
      changeType = "to";
    }

    if (actionToPerform.toLowerCase().includes("pause")) {
      actionToPerform = "pause keyword or target";
      changeType = "";
    }

    summary += `${snakeCaseToSentenceCase(
      actionToPerform
    ).toLowerCase()} ${changeType} ${
      !changeType
        ? ""
        : operator === "adjust_fixed"
        ? formatCurrency(values[0])
        : formatPercent(values[0])
    }${
      i === actions.length - 1 ? "" : i === actions.length - 2 ? " and " : ", "
    } `;
  }
  summary = summary
    .replaceAll("acos", "ACOS")
    .replaceAll("roas", "ROAS")
    .replaceAll("conv_rate", "conversion rate")
    .replaceAll("_", " ");

  summary += ".";

  return summary;
};

const METRICS = [
  "targets",
  "keywords",
  "conversions",
  "max",
  "min",
  "target",
  "acos",
  "roas",
  "ad",
  "spend",
  "conversion",
  "rate",
  "click-through-rate",
  "clicks",
  "bid",
  "impressions",
  "auto",
  "product",
  "category",
  "dynamic",
  "expression",
  "broad",
  "exact",
  "phrase",
  "keyword",
  "target",
];

const OPERATORS = ["greater", "less", "than", "or", "equal", "to", "between"];
const ACTIONS = ["increase", "decrease", "set", "pause"];

export const boldKeywords = (str = "", wordsToEmbolden = null) => {
  // If empty list or not formatted as an array, default to metrics and operators
  if (!wordsToEmbolden || typeof wordsToEmbolden !== "object") {
    wordsToEmbolden = [...METRICS, ...OPERATORS, ...ACTIONS];
  }

  // Embolden parameters in summary sentence
  let formatted = str?.split(" ")?.map((word, i) => {
    if (
      wordsToEmbolden?.includes(word.toLowerCase()) ||
      wordsToEmbolden?.includes(word.slice(0, -1).toLowerCase()) ||
      !isNaN(parseInt(word)) ||
      !isNaN(parseInt(word.slice(1)))
    ) {
      return (
        <strong>
          {word}
          {i === str?.split(" ")?.length - 1 ? "" : " "}
        </strong>
      );
    } else {
      return word + " ";
    }
  });

  return formatted;
};
