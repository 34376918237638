import React, { useCallback, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core";
import { PROD_GOOGLE_API_CLIENT_ID } from "../../utils/gvSecrets";
import { GoogleLogin } from "react-google-login";
import logo from "../../assets/images/logo/Trellis_Logomark_color.png";
import getURLPrefix from "../../utils/getUrlPrefix";
import axios from "axios";
import { Check } from "@material-ui/icons";
import themeLargerFont from "../../assets/themes/TrellisTheme";
import * as Sentry from "@sentry/react";

const useStyles = makeStyles((theme) => {
  return {
    centered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minHeight: 200,
    },
    square: {
      borderRadius: 0,
    },
    fullWidth: {
      width: "100%",
    },
    lineHeightFix: {
      lineHeight: "unset",
    },
  };
});

const LoginPanel = (props) => {
  const { setActiveStep, setAuthResults, setShowLoading, setLoadingMsg } =
    props;

  const handleRequest = useCallback(() => {
    setShowLoading(true);
  }, [setShowLoading]);

  const handleLoginSuccess = useCallback(
    async (googleData) => {
      let URL_PREFIX = getURLPrefix();
      setLoadingMsg("Loading connected accounts...");

      await axios
        .get(`${URL_PREFIX}/google/info`, {
          params: {
            code: googleData.code,
          },
        })
        .then((res) => {
          if (res.status !== 200) {
            // TODO: Error handling, most likely getting here via a timeout.
            setActiveStep(0);
          } else {
            setActiveStep(1);
            setAuthResults(res.data);
          }
          setShowLoading(false);
          setLoadingMsg("");
        });
    },
    [setActiveStep, setAuthResults, setLoadingMsg, setShowLoading]
  );

  const handleLoginError = useCallback(
    (response) => {
      setShowLoading(false);
      setLoadingMsg("");
      Sentry.captureMessage(response.details + response.error);
    },
    [setLoadingMsg, setShowLoading]
  );

  return (
    <GoogleLogin
      clientId={PROD_GOOGLE_API_CLIENT_ID}
      cookiePolicy={"single_host_origin"}
      // Needs to be consent to ensure Google _always_ returns a refresh token.
      prompt={"consent"}
      // An accessType of offline + a response type of code is required to get an authorization
      // token that can be exchanged for a refresh token.
      accessType={"offline"}
      responseType={"code"}
      // Has no documentation, but is called when the signin "starts" (the popup opens)
      onRequest={handleRequest}
      onFailure={handleLoginError}
      onSuccess={handleLoginSuccess}
      // This magic value, "postmessage", tells Google we're not really using a redirectUri
      // but will validate on the server with a POST. It is very poorly documented.
      redirectUri={"postmessage"}
      scope={
        // Basic profile scopes are implicit, you do not need to include.
        "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/content"
      }
    />
  );
};

const SelectAdAccountPanel = ({
  account,
  setSelectedAccounts,
  selectedAccounts,
}) => {
  const handleToggle = useCallback(
    (who_id) => {
      setSelectedAccounts((prev) => {
        if (prev.has(who_id)) {
          const tmp = new Map([...prev]);
          tmp.delete(who_id);
          return tmp;
        } else {
          return new Map([...prev, [who_id, []]]);
        }
      });
    },
    [setSelectedAccounts]
  );

  return (
    <List
      subheader={
        <ListSubheader component={"div"}>
          Select the Google Ads accounts you'd like to import.
        </ListSubheader>
      }
    >
      {account.imported.map((entry) => {
        return (
          <ListItem key={entry.who_id}>
            <ListItemText
              primary={entry.who || entry.who_id}
              // If you want to show _how_ we're getting to this account.
              // Doesn't really make sense to always show since we don't return
              // duplicates (currently).
              // secondary={`accessible via ${entry.parent}`}
            />
            <ListItemSecondaryAction>
              <Checkbox
                edge={"end"}
                onChange={() => handleToggle(entry.who_id)}
                checked={selectedAccounts.has(entry.who_id)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

const SelectMerchantAccountPanel = (props) => {
  const { account, selectedAccounts, setSelectedAccounts, classes } = props;

  // Oh JavaScript, this is much simpler than it looks. selectedAccounts
  // is just a simple:
  // {<ad_account_id>: [<merchant_id_1>, <merchant_id_2>, ...], ...}
  // Toggling a checkbox just adds or removes a merchant_id from the list.
  const handleToggle = useCallback(
    (ad_id, merchant_id) => {
      setSelectedAccounts((prev) => {
        if (prev.get(ad_id).includes(merchant_id)) {
          return new Map([
            ...prev,
            [ad_id, prev.get(ad_id).filter((v) => v !== merchant_id)],
          ]);
        } else {
          return new Map([...prev, [ad_id, [...prev.get(ad_id), merchant_id]]]);
        }
      });
    },
    [setSelectedAccounts]
  );

  return (
    <List
      subheader={
        <ListSubheader component={"div"}>
          Select the Google Merchant Center accounts you'd like to import.
        </ListSubheader>
      }
    >
      {account.imported.map((entry) => {
        if (!selectedAccounts.has(entry.who_id)) {
          return null;
        }
        return (
          <ListItem key={entry.who_id}>
            <List
              className={classes.fullWidth}
              subheader={
                <ListSubheader>{entry.who || entry.who_id}</ListSubheader>
              }
            >
              {entry.merchants.map((merchant) => {
                return (
                  <ListItem key={merchant.who_id}>
                    <ListItemText primary={merchant.who} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge={"end"}
                        onChange={() =>
                          handleToggle(entry.who_id, merchant.who_id)
                        }
                        checked={selectedAccounts
                          .get(entry.who_id)
                          .includes(merchant.who_id)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </ListItem>
        );
      })}
    </List>
  );
};

/**
 * Provides an interactive prompt for connecting to and configuring
 * Google Shopping integrations.
 */
const GoogleSetupV2 = (props) => {
  const { open, onClose } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [authResults, setAuthResults] = useState({});
  const [selectedAccounts, setSelectedAccounts] = useState(new Map());
  const [importedAccounts, setImportedAccounts] = useState([]);

  const handleImport = useCallback(async () => {
    let URL_PREFIX = getURLPrefix();

    setShowLoading(true);
    setLoadingMsg("Importing...");

    await axios
      .post(`${URL_PREFIX}/google/info`, {
        common: authResults["common"],
        accounts: Array.from(selectedAccounts.entries()),
      })
      .then((res) => {
        setImportedAccounts(res.data);
        setActiveStep(3);
        setLoadingMsg("");
        setShowLoading(false);
      });
  }, [authResults, selectedAccounts]);

  return (
    <ThemeProvider theme={themeLargerFont}>
      <Dialog open={open} onClose={() => onClose && onClose()}>
        <Stepper alternativeLabel activeStep={activeStep}>
          <Step key={0}>
            <StepLabel>Connect</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>Link Ads Account</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>Link Merchant Center</StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel>Done</StepLabel>
          </Step>
        </Stepper>
        {showLoading ? (
          <div className={classes.centered}>
            <img src={logo} alt="Loading..." className={"rotate"} width="80" />
            {loadingMsg.length > 0 ? <div>{loadingMsg}</div> : null}
          </div>
        ) : activeStep === 0 ? (
          <div className={classes.centered}>
            <LoginPanel
              setShowLoading={setShowLoading}
              setActiveStep={setActiveStep}
              setAuthResults={setAuthResults}
              setLoadingMsg={setLoadingMsg}
            />
          </div>
        ) : activeStep === 1 ? (
          <>
            <SelectAdAccountPanel
              account={authResults}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={setSelectedAccounts}
            />
            <Button
              variant={"contained"}
              size={"large"}
              color={"primary"}
              disabled={selectedAccounts.length === 0}
              onClick={() => setActiveStep(2)}
              className={classes.square}
            >
              Next
            </Button>
          </>
        ) : activeStep === 2 ? (
          <>
            <SelectMerchantAccountPanel
              account={authResults}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={setSelectedAccounts}
              classes={classes}
            />
            <Button
              variant={"contained"}
              size={"large"}
              color={"primary"}
              disabled={selectedAccounts.length === 0}
              onClick={handleImport}
              className={classes.square}
            >
              Import
            </Button>
            <Button
              variant={"contained"}
              size={"large"}
              color={"secondary"}
              onClick={() => setActiveStep(1)}
              className={classes.square}
            >
              Back
            </Button>
          </>
        ) : activeStep === 3 ? (
          <>
            <List
              subheader={
                <ListSubheader
                  component={"div"}
                  className={classes.lineHeightFix}
                >
                  Accounts have been imported and products are now loading. You
                  may not see products until this completes.
                </ListSubheader>
              }
            >
              {importedAccounts.map((entry) => {
                return (
                  <ListItem key={entry}>
                    <ListItemText primary={entry} />
                    <ListItemSecondaryAction>
                      <Check />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            <Button
              variant={"contained"}
              size={"large"}
              color={"primary"}
              onClick={onClose}
              className={classes.square}
            >
              Close
            </Button>
          </>
        ) : null}
      </Dialog>
    </ThemeProvider>
  );
};

export default GoogleSetupV2;
