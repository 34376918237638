import { BSRForm } from "./pricing/BSR";
import { ClearanceForm } from "./pricing/Clearance";
import { CUSTOM_DEFAULT_VALUES, ProgramsForm } from "./pricing/Custom";
import { ProfileForm } from "./pricing/Profiling";
import { MarginProfilingForm } from "./pricing/MarginProfiling";
import { NPIBiddingForm } from "./bidding/NPI";
import { GrowthBiddingForm } from "./bidding/Growth";
import { PROGRAM_DEFAULTS } from "../constants";

const TEMPLATES = [
  {
    id: "MARGIN_PROFILING",
    name: "Margin Profiling",
    engine: "pricing",
    description:
      "Experiment with incremental pricing increases to find the optimal balance between profit margin and sales volume. Adjust downwards if a set price adversely impacts sales.",
    FormComponent: MarginProfilingForm,
  },
  {
    id: "CLEARANCE",
    engine: "pricing",
    name: "Clearance",
    description:
      "Clear excess inventory while gradually increase margin. Start by setting a low price and gradually increase it to improve margins. If a particular price has slowed sales too much, lower the price.",
    FormComponent: ClearanceForm,
  },
  {
    id: "BSR",
    name: "Best Seller Rank",
    engine: "pricing",
    description:
      "Maintain your best seller rank. Set a desired BSR Range. If the BSR is within range, increase price to improve margin. If BSR goes above the range, adjust the lower the price to increase sales.",
    enabled: false,
    FormComponent: BSRForm,
  },
  {
    id: "PROFILE",
    name: "Profile",
    engine: "pricing",

    description:
      "Test the market at different price points. Start by setting a low price and gradually increase it. If a particular price has slowed sales too much, lower the price.",
    FormComponent: ProfileForm,
  },
  // {
  //   id: "REFERENCE_PRICE",
  //   name: "Reference Price",
  // engine: "pricing",
  //   description: "Reset your reference price",
  //   enabled: false,
  // },
  // {
  //   id: "MAINTAIN_STOCK",
  //   name: "Maintain Stock",
  //   description: "Lower volumes to maintain stock levels",
  //   enabled: false,
  // },
  {
    id: "CUSTOM",
    name: "Custom",
    engine: "pricing",
    description: "Define your own pricing rules",
    defaultValues: CUSTOM_DEFAULT_VALUES,
    FormComponent: ProgramsForm,
  },
  // New budget placeholder templates
  {
    id: "CUSTOM",
    name: "Custom",
    engine: "budget",
    description: "Define your own budget rules",
    defaultValues: PROGRAM_DEFAULTS["budget"],
    FormComponent: ProgramsForm,
  },
  // New bidding placeholder templates
  {
    id: "CUSTOM",
    name: "Custom",
    engine: "bidding",
    description: "Define your own bidding rules",
    defaultValues: PROGRAM_DEFAULTS["bidding"],
    FormComponent: ProgramsForm,
  },
  /*
  {
    id: "GROWTH",
    engine: "bidding",
    name: "Growth",
    description:
      "Aggressively increase and decrease bids as conversions fluctuate.",
    FormComponent: GrowthBiddingForm,
  },
  {
    id: "NPI",
    engine: "bidding",
    name: "New Product Introduction",
    description:
      "Ensure early sales by boosting bids by a set amount, then gradually reducing them once certain sales goals are reached.",
    FormComponent: NPIBiddingForm,
  },
  */
];

export default TEMPLATES;
