import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useFetch } from "hooks/api";
import Loading from "components/core/blocks/Loading";
import { formatNumber } from "utils/formatNumber";
import FormGroupTile from "components/core/basic/FormGroupTile";
import { LogoutButton } from "components/core/basic/Button.jsx";
import getURLPrefix from "utils/getUrlPrefix";
import axios from "axios";

let URL_PREFIX = getURLPrefix();

const SliderRecuctionSecton = ({
  pricingPlan,
  pricingProduct,
  marketPlace,
}) => {
  const [sliderValue, setSliderValue] = useState(0);

  let url = "/pricing/sliderreduction";
  let setUrl = "/pricing/setslidervalue";
  let id = null;
  let sliderChangeDate = null;
  if (pricingPlan) {
    url += `?pricing_plan_id=${pricingPlan.id}`;
    setUrl += `?pricing_plan_id=${pricingPlan.id}`;
    id = pricingPlan.id;
    sliderChangeDate = pricingPlan.slider_change_date;
  } else if (pricingProduct) {
    url += `?pricing_product_id=${pricingProduct.id}`;
    setUrl += `?pricing_product_id=${pricingProduct.id}`;
    id = pricingProduct.id;
    sliderChangeDate = pricingProduct.slider_change_date;
  }

  const { data: sliderReduction, isLoading: sliderReductionLoading } = useFetch(
    ["pricing_plans_reduction", id],
    url,
    {},
    {
      select: (d) => d?.data,
      keepPreviousData: true,
    }
  );

  const saveSliderValue = async () => {
    setUrl += `&slider_value=${sliderValue}`;

    axios.get(URL_PREFIX + setUrl).then((res) => {
      alert("Slider has been set and optimizer is running");
    });
  };

  useEffect(() => {
    if (!sliderReductionLoading && sliderReduction.data) {
      setSliderValue(sliderReduction.data.slider_value);
    }
  }, [sliderReductionLoading, sliderReduction]);

  if (sliderReductionLoading) {
    return <Loading fullPage={false} height={`300px`} />;
  }

  return (
    <>
      <Row className="p-3">
        <Col xs={12} className="text-start">
          {!sliderReduction.data && (
            <div className="text-center">
              <strong>
                No debug data since this is not a Same Strategyr Dynamic Pricing
                Plan
              </strong>
            </div>
          )}

          {sliderReduction.data && (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>Bucket Size (Days)</th>
                    <th>Current Bucket</th>
                    <th>Threshold</th>
                    <th>Last 3 Buckets</th>
                    <th>Recommendations</th>
                    <th>Modifier</th>
                    <th>Slider Value</th>
                    <th>Price</th>
                    <th>Change Date</th>
                  </tr>
                </thead>
                <tbody>
                  {sliderReduction.data &&
                    sliderReduction.data.current_bucket && (
                      <tr>
                        <td className="text-center">
                          {sliderReduction.data.days}
                        </td>
                        <td className="text-center">
                          {formatNumber(sliderReduction.data.current_bucket[0])}
                        </td>
                        <td className="text-center">
                          {sliderReduction.data.threshold}
                        </td>
                        <td className="text-center">
                          {sliderReduction.data.threshold_bucket.join(", ")}
                        </td>
                        <td className="text-center">
                          {sliderReduction.data.status}
                        </td>
                        <td className="text-center">
                          {sliderReduction.data.modifier}
                        </td>
                        <td className="text-center">
                          <FormGroupTile
                            formControlClass="text-center"
                            type="formcontrol"
                            formControlType={"text"}
                            prefixSide={"left"}
                            handleChildFormElement={(key, value) => {
                              setSliderValue(value);
                            }}
                            placeholder={`New Slider Value`}
                            defaultValue={sliderValue}
                          />
                        </td>
                        <td className="text-center">
                          {sliderReduction.data.price}
                        </td>
                        <td className="text-center">
                          {sliderChangeDate ? sliderChangeDate : ""}
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>

              <Row>
                <Col xs={12} className="text-end">
                  <div className="fs-4 pt-3 pb-3">
                    <LogoutButton
                      style={{ margin: "1rem" }}
                      fontSize="12px"
                      title={"Push New Slider Value"}
                      onClick={saveSliderValue}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SliderRecuctionSecton;
