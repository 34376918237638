import React, { Component } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
// used to make this component's props into classes
// import cx from "classnames";

import "assets/css/authentication_pages.css";

export class AuthButton extends Component {
  render() {
    const { buttonStyle, title, ...rest } = this.props;
    return (
      <button className={buttonStyle} {...rest}>
        {" "}
        {title}{" "}
      </button>
    );
  }
}

export class LogoutButton extends Component {
  render() {
    return (
      <button
        style={{ fontSize: this.props.fontSize, ...this.props.overrideStyles }}
        className={`${this.props.nowrap ? "" : "wrap"} logout_button`}
        {...this.props}
      >
        {" "}
        {this.props.title}{" "}
      </button>
    );
  }
}

export const PrimaryButton = ({ children, nowrap, noShadow, ...props }) => (
  <button
    style={{ fontSize: props.fontSize, ...props.overrideStyles }}
    className={`${nowrap ? "" : "wrap"} logout_button ${
      noShadow ? "no-shadow" : ""
    }`}
    {...props}
  >
    {children}
  </button>
);

export const LinkButton = ({ onClick, children, selected }) => {
  return (
    <span
      style={{
        marginBottom: "1rem",
        textDecoration: "underline",
        color: selected ? "#62407f" : "#D91266",
        cursor: "pointer",
        fontWeight: "600",
      }}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export class SecondaryButton extends Component {
  render() {
    return (
      <button
        title={this.props.tooltip}
        style={{ fontSize: this.props.fontSize }}
        className={
          this.props.noWrap || this.props.nowrap
            ? "secondary_button"
            : "wrap secondary_button"
        }
        {...this.props}
      >
        {" "}
        {this.props.title || this.props.children}{" "}
      </button>
    );
  }
}

class BasicButton extends Component {
  render() {
    const {
      fill,
      simple,
      pullRight,
      block,
      wd,
      round,
      icon,
      neutral,
      twitter,
      facebook,
      google,
      linkedin,
      pinterest,
      youtube,
      tumblr,
      github,
      behance,
      dribbble,
      reddit,
      stumbleupon,
      ...rest
    } = this.props;

    // const btnClasses = cx({
    //   "btn-fill": fill,
    //   "btn-simple": simple,
    //   "pull-right": pullRight,
    //   "btn-block": block,
    //   "btn-wd": wd,
    //   "btn-round": round,
    //   "btn-icon": icon,
    //   "btn-neutral": neutral,
    //   "btn-social btn-twitter": twitter,
    //   "btn-social btn-facebook": facebook,
    //   "btn-social btn-google": google,
    //   "btn-social btn-linkedin": linkedin,
    //   "btn-social btn-pinterest": pinterest,
    //   "btn-social btn-youtube": youtube,
    //   "btn-social btn-tumblr": tumblr,
    //   "btn-social btn-github": github,
    //   "btn-social btn-behance": behance,
    //   "btn-social btn-dribbble": dribbble,
    //   "btn-social btn-reddit": reddit,
    //   "btn-social btn-stumbleupon": stumbleupon
    // });

    return <Button className="btn" {...rest} />;
  }
}

BasicButton.propTypes = {
  fill: PropTypes.bool,
  simple: PropTypes.bool,
  pullRight: PropTypes.bool,
  block: PropTypes.bool,
  wd: PropTypes.bool,
  round: PropTypes.bool,
  icon: PropTypes.bool,
  neutral: PropTypes.bool,
  twitter: PropTypes.bool,
  facebook: PropTypes.bool,
  google: PropTypes.bool,
  linkedin: PropTypes.bool,
  pinterest: PropTypes.bool,
  youtube: PropTypes.bool,
  tumblr: PropTypes.bool,
  github: PropTypes.bool,
  behance: PropTypes.bool,
  dribbble: PropTypes.bool,
  reddit: PropTypes.bool,
  stumbleupon: PropTypes.bool,
};

export default BasicButton;
