import React, { useRef, useState, useCallback, useEffect } from "react";
import useScrollableData from "../hooks/useScrollableData";

const InfiniteScrollWrapper = ({ Component, data }) => {
  const [page, setPage] = useState(-1);
  const [hasMore, setHasMore] = useState(false);
  let pageSize = 10;

  let renderData = useScrollableData(data, page, pageSize);

  const observer = useRef();

  useEffect(() => {
    setPage(0);
  }, []);

  useEffect(() => {
    if (renderData?.length >= data?.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [renderData, data]);

  const lastRenderComponentRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [hasMore]
  );

  return (
    <>
      <div>
        {renderData.length > 0 &&
          renderData.map((data, index) => {
            if (renderData.length === index + 1) {
              return (
                <div ref={lastRenderComponentRef} key={index}>
                  <>
                    <Component data={data} />
                    {index === renderData?.length - 1 && hasMore ? (
                      <div className="d-flex justify-content-center">
                        <i className="fa fa-spinner fa-spin fa-2x" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              );
            } else {
              return <Component data={data} key={index} />;
            }
          })}
      </div>
    </>
  );
};

export default InfiniteScrollWrapper;
