import CenteredModal from "components/core/basic/CenteredModal";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AdPlanForm from "./AdPlanForm";
import useAdPlanState from "./useAdPlanState";
import withAdPlan from "./withAdPlan";
import AdPlanError from "./AdPlanError";

const AdPlanChild = (props) => {
  const {
    adPlan,
    errors,
    isValid,
    onChange,
    products,
    brandOptions,
    savedVideos,
    storeOptions,
    logoOptions,
    pageOptions,
  } = useAdPlanState(
    { ...(props.initialChild ?? {}), canHaveChildren: false, hasParent: true },
    props.marketPlace.marketPlace,
    props.categoryId,
    null,
    props.roas,
    props.user.organization_type
  );
  return (
    <>
      <CenteredModal
        show={props.show}
        closeModal={() => props.onCancel()}
        title="Create a new Media Plan"
        content={
          props.loading ? (
            "Loading..."
          ) : (
            <>
              <AdPlanForm
                onChange={onChange}
                state={adPlan}
                errors={errors}
                isValid={isValid}
                categories={props.categories}
                dashboard={false}
                user={props.user}
                marketPlace={props.marketPlace.marketPlace}
                channel={localStorage.channel}
                products={products}
                savedVideos={savedVideos}
                brandOptions={brandOptions}
                storeOptions={storeOptions}
                logoOptions={logoOptions}
                pageOptions={pageOptions}
                isChild
                parentDailyBudget={props.parentDailyBudget}
              />
            </>
          )
        }
      />
    </>
  );
};

const AdPlanPanel = (props) => {
  const {
    adPlan,
    errors,
    isValid,
    onChange,
    clear,
    updateChild,
    removeChild,
    products,
    brandOptions,
    savedVideos,
    storeOptions,
    logoOptions,
    pageOptions,
    loading,
    agreeToArchive,
    setAgreeToArchive,
    apiStatus,
  } = useAdPlanState(
    {},
    props.marketPlace.marketPlace,
    props.categoryId,
    props.adId,
    props.roas,
    props.user.organization_type,
    true
  );
  const [currentChild, setCurrentChild] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);

  useEffect(() => {
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categoryId]);

  return (
    <>
      {}
      {!!currentChild && (
        <AdPlanChild
          {...props}
          show={!!currentChild}
          onSave={(child) => {
            updateChild(child, currentChild.i);
            setCurrentChild(null);
          }}
          onCancel={() => {
            setCurrentChild(null);
          }}
          initialChild={currentChild.child || { categoryId: props.categoryId }}
          parentDailyBudget={adPlan.dailyBudget}
        />
      )}
      <AdPlanForm
        loading={loading}
        adId={props.adId}
        onChange={onChange}
        state={adPlan}
        errors={{ ...props.errors, ...errors }}
        isValid={isValid}
        onShowChild={(i, child) => {
          if (props.onChildClick) {
            props.onChildClick(child);
          } else {
            setCurrentChild({ i, child });
          }
        }}
        onRemoveChild={(i) => removeChild(i)}
        categories={props.categories}
        products={products}
        savedVideos={savedVideos}
        brandOptions={brandOptions}
        storeOptions={storeOptions}
        logoOptions={logoOptions}
        pageOptions={pageOptions}
        dashboard={props.dashboard}
        user={props.user}
        marketPlace={props.marketPlace.marketPlace}
        channel={localStorage.channel}
        isChild={adPlan.parent}
        agreeToArchive={agreeToArchive}
        setAgreeToArchive={setAgreeToArchive}
        apiStatus={apiStatus}
        clearError={props.clearError}
        parentDailyBudget={props.parentDailyBudget}
        onUpdate={props.onUpdate}
        onCreate={props.onCreate}
        onParentUpdate={props.onParentUpdate}
        review={props.review}
        onShowParent={props.onShowParent}
      />
      <div
        style={{
          display: "flex",
          marginTop: "3em",
          marginBottom: "2em",
        }}
      >
        <AdPlanError
          errors={subscriptionError}
          clearError={() => setSubscriptionError(null)}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  onboardStatus: state.amz_onboard.org_status,
});

export default connect(mapStateToProps)(withAdPlan(AdPlanPanel));
