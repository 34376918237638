import React, { useCallback } from "react";
import {
  Button,
  FormLabel as ControlLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    // The addon has some wacky adjacent effect going on when the input
    // box is selected. !important required here, sadly.
    addon: {
      backgroundColor: "#FFFFFF !important",
      borderTop: "1px solid #e3e3e3 !important",
      borderRight: "1px solid #e3e3e3 !important",
      borderBottom: "1px solid #e3e3e3 !important",
    },
  };
});

const ShopifySetup = (props) => {
  const classes = useStyles();

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const shopName = document.getElementById("shop-selector");
      if (shopName === null) {
        return false;
      }
      window.location = props.oauthUrl.replace("$$", shopName.value);
      return false;
    },
    [props.oauthUrl]
  );

  return (
    <Modal show={props.is_visible} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Connect With Shopify</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <p>
            This will link your currently selected organization,{" "}
            <strong>{props.user.organization_name}</strong>
            &nbsp;(on {props.channel.currentChannel}) with a Shopify store.
          </p>
          <FormGroup>
            <ControlLabel>Shop Name</ControlLabel>
            <InputGroup>
              <FormControl
                type={"text"}
                id={"shop-selector"}
                placeholder={"my-shop"}
              />
              <InputGroup.Addon className={classes.addon} hasValidation={false}>
                .myshopify.com
              </InputGroup.Addon>
            </InputGroup>
            <FormControl.Feedback />
            <span className="text-muted">
              Enter the subdomain for your Shopify shop.
            </span>
            <Button type={"submit"}>Connect With Shopify</Button>
          </FormGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

ShopifySetup.propTypes = {
  // Initial visibility state
  is_visible: PropTypes.bool.isRequired,
  // Called when the Modal should be hidden.
  onHide: PropTypes.func.isRequired,
  // Called when setup is completed by the user.
  onSetupComplete: PropTypes.func,
  // The URL to call when the user clicks to login. The URl should
  // contain a "$$" where the shop name should be substituted.
  oauthUrl: PropTypes.string,
  // The selected channel (Google, Amazon, etc)
  channel: PropTypes.shape({
    currentChannel: PropTypes.string,
  }),
  // The currently authenticated user.
  user: PropTypes.shape({
    organization_name: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  return {
    channel: state.channel,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(ShopifySetup));
