import React, { useState, useEffect } from "react";
import { Table, InputGroup, Form, Row, Col } from "react-bootstrap";
import useDebounce from "hooks/useDebounce";
import { formatCurrency, formatNumber } from "utils/formatNumber";

const BrandsTable = ({ popularBrands, complete, marketPlace }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [terms, setTerms] = useState(popularBrands);
  const debounceSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (debounceSearchTerm) {
      const filteredTerms = popularBrands.filter((term) => {
        return term[0].toLowerCase().includes(debounceSearchTerm.toLowerCase());
      });
      setTerms(filteredTerms);
    } else {
      setTerms(popularBrands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchTerm]);

  return (
    <div
      style={{
        padding: complete ? "20px 10px 20px 10px" : "0px",
        border: complete ? "1px solid #ccc" : "0px",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopRightRadius: "5px",
        height: "400px",
        overflowY: "scroll",
      }}
    >
      <Row>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text
              style={{
                borderTopLeftRadius: "45px",
                borderBottomLeftRadius: "45px",
              }}
            >
              <i className="fa fa-search" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Filter Brands"
              aria-label="Search Term"
              onKeyUp={(e) => {
                setSearchTerm(e.target.value);
              }}
              defaultValue={searchTerm}
            />
          </InputGroup>
        </Col>
      </Row>
      <Table striped hover>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>Brand</th>
            {complete && (
              <>
                <th style={{ textAlign: "right" }}>Promo</th>
                <th style={{ textAlign: "right" }}>Ad</th>
                <th style={{ textAlign: "right" }}>Coupon</th>
              </>
            )}
            <th style={{ textAlign: "right" }}>Est. Units</th>
            {complete && <th style={{ textAlign: "right" }}>Est. Sales</th>}
            <th style={{ textAlign: "right" }}>Cnt</th>
          </tr>
        </thead>
        <tbody>
          {terms.map((brand, index) => {
            return (
              <tr key={index}>
                <td className="text-start" style={{ maxWidth: "100px" }}>
                  {brand[0]}
                </td>
                {complete && (
                  <>
                    <td className="text-end">{brand[1]["promo"]}</td>
                    <td className="text-end">{brand[1]["ads"]}</td>
                    <td className="text-end">{brand[1]["coupon"]}</td>
                  </>
                )}
                <td className="text-end">
                  {formatNumber(brand[1]["estimated_units"], {}, 0)}
                </td>
                {complete && (
                  <td className="text-end">
                    {formatCurrency(
                      brand[1]["estimated_sales"],
                      marketPlace.marketPlace
                    )}
                  </td>
                )}
                <td className="text-end">{brand[1]["total"]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default BrandsTable;
