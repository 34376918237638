import React, { useState } from "react";

const EditCell = ({ row, value, column }) => {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingLeft: "2px",
        paddingRight: "2px",
        borderBottom: isFocus
          ? "2px solid rgb(46, 0, 84)"
          : "2px solid rgba(46, 0, 84, 0.25)",
      }}
    >
      <span>{column.prefix}</span>
      <span style={{ display: "block", width: "100%" }}>
        <input
          type="text"
          onChange={(e) => {
            column.onChange(row, e.target.value);
          }}
          disabled={column.disabled}
          value={column.stateKeyFn(row) ?? value}
          style={{
            backgroundColor: "transparent",
            width: "100%",
            border: "none",
            outline: "none",
            textAlign: column.textAlign || "left",
          }}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      </span>
      <span>{column.suffix}</span>
    </div>
  );
};

export default EditCell;
